define("securitytrax-ember-ui/services/tax", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    stAjax: Ember.inject.service(),
    sessionData: Ember.inject.service(),

    getTaxRates(address) {
      return this.getTaxRatesTask.perform(address);
    },

    getTaxRatesTask: (0, _emberConcurrency.task)(function* (address) {
      var tokenData = yield this.sessionData.getTokenData();
      var companyId = tokenData.sub.company_id;
      var url = "/companies/".concat(companyId, "/actions/calculate_invoice_billable_class_tax_rate");
      var data = {
        data: {
          attributes: address,
          type: 'company_action'
        }
      };
      var response = yield this.stAjax.request(this.stAjax.getUrl(url), {
        method: 'POST',
        data: data
      });
      return response.data;
    })
  });

  _exports.default = _default;
});