define("securitytrax-ember-ui/components/html/st-native-select/select-option/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qIEJAxR4",
    "block": "{\"symbols\":[\"@value\",\"@currentValue\",\"@label\"],\"statements\":[[10,\"option\"],[15,2,[32,1]],[15,\"selected\",[30,[36,1],[[30,[36,0],[[32,2],[32,1]],null],true,false],null]],[12],[1,[32,3]],[13]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/st-native-select/select-option/template.hbs"
    }
  });

  _exports.default = _default;
});