define("securitytrax-ember-ui/models/company-license-transaction", ["exports", "securitytrax-ember-ui/models/base_models/company-license-transaction"], function (_exports, _companyLicenseTransaction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _companyLicenseTransaction.default.extend({});

  _exports.default = _default;
});