define("securitytrax-ember-ui/customers/information/manage-brinks-home-security/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iZymnhg1",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modals/sub-route-modals/manage-brinks\",[],[[\"@customer\",\"@customerSystemInformation\",\"@monitoringCompany\",\"@parentRoute\",\"@transitionId\"],[[34,0,[\"customer\"]],[34,0,[\"customerSystemInformation\"]],[34,0,[\"monitoringCompany\"]],[34,0,[\"parentRoute\"]],[34,0,[\"transitionId\"]]]],null],[2,\"\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"model\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/customers/information/manage-brinks-home-security/template.hbs"
    }
  });

  _exports.default = _default;
});