define("securitytrax-ember-ui/validations/central-station/api-create", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation", "lodash"], function (_exports, _validate, _baseValidation, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var noValidate = _lodash.default.get(data, 'extraValidationData.noValidate', false);

      var validateObject = {
        accountNumber: {
          presence: () => {
            if (noValidate && noValidate.includes('api-accountNumber')) {
              return false;
            } else return this.customMessageOrDefault(data, 'api-accountNumber');
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      return this.outputFormatting(validationData, 'api');
    }

  });

  _exports.default = _default;
});