define("securitytrax-ember-ui/components/pages/administration/human-resources/permissions/group-details/grants-policies/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HfZxo2pk",
    "block": "{\"symbols\":[\"multiStepForm\"],\"statements\":[[6,[37,3],null,[[\"formSteps\",\"formOriginalModels\",\"currentStepNameKey\",\"formStarted\"],[[35,2],true,\"grantsPolicies\",true]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],[[\"model\",\"multiStepForm\"],[[35,0],[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"component\",\"grantsPoliciesStep\",\"multi-step-form\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/administration/human-resources/permissions/group-details/grants-policies/template.hbs"
    }
  });

  _exports.default = _default;
});