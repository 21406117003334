define("securitytrax-ember-ui/mixed-in-classes/format-appointments-mixin", ["exports", "@glimmer/component", "ember-concurrency", "moment"], function (_exports, _component, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var FormatAppointmentsMixin = (_dec = Ember.inject.service, _dec2 = (0, _emberConcurrency.task)(function* (multiStepForm, customer) {
    yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);
    var calendarEvents = yield customer.get('calendarEvents');
    var formattedCalendarEvents = [];
    var promiseArray = [];

    for (var i = 0; i < calendarEvents.get('length'); i++) {
      var event = calendarEvents.objectAt(i);
      promiseArray.push((0, _emberConcurrency.hash)({
        user: event.get('calendarUser'),
        calendarEventType: event.get('calendarEventType'),
        calendarEvnetTypeName: event.get('calendarEventType.name')
      }));
    }

    var hashedResults = yield (0, _emberConcurrency.all)(promiseArray);
    hashedResults.forEach((hashedData, i) => {
      var event = calendarEvents.objectAt(i);

      if (hashedData.calendarEventType && hashedData.calendarEventType.get('calendarCategory') === 'tech_schedule') {
        var calendarEventName = hashedData.calendarEvnetTypeName;
        var calendarEventDate = (0, _moment.default)(event.get('startTime')).format('M/DD/YYYY h:mm A');
        var userName = hashedData.user ? ' | ' + hashedData.user.get('flname') : '';
        event.set('userDateTime', "".concat(calendarEventName, " ").concat(calendarEventDate, " ").concat(userName));
        formattedCalendarEvents.push(event);
      }
    });
    return formattedCalendarEvents;
  }), (_class = (_temp = class FormatAppointmentsMixin extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "grants", _descriptor, this);

      _initializerDefineProperty(this, "formattedAppointmentsTask", _descriptor2, this);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "formattedAppointmentsTask", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = FormatAppointmentsMixin;
});