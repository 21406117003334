define("securitytrax-ember-ui/components/multi-step-form/forms/work-orders/add-appointment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d1kX7uqq",
    "block": "{\"symbols\":[\"&attrs\",\"@multiStepForm\",\"@customer\",\"@userType\",\"@users\"],\"statements\":[[11,\"div\"],[17,1],[12],[2,\"\\n    \"],[8,\"multi-step-form/progress-bar\",[],[[\"@config\",\"@modalButtons\",\"@formClass\"],[[34,0],[34,1,[\"currentStepData\",\"buttons\"]],\"no-horizontal-scroll\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"calendar-glimmer/calendar-scheduling\",[],[[\"@multiStepForm\",\"@customer\",\"@afterScheduleAppointment\",\"@popoverContainer\",\"@userType\",\"@users\"],[[32,2],[32,3],[30,[36,2],[[32,0],\"afterScheduleAppointment\"],null],\".md-dialog-content\",[32,4],[32,5]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"progressBarConfig\",\"multiStepForm\",\"action\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/work-orders/add-appointment/template.hbs"
    }
  });

  _exports.default = _default;
});