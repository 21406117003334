define("securitytrax-ember-ui/validations/central-station/cms/cms-customer-order", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validationObject = {
        site_name: {
          presence: this.customMessageOrDefault(data, 'siteName')
        },
        site_number: {
          presence: () => {
            if (data.extraValidationData.isCreate === true) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'siteNumber');
            }
          }
        },
        cs_number: {
          presence: () => {
            if (data.extraValidationData.isCreate === true) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'accountNumber');
            }
          }
        },
        system_number: {
          presence: () => {
            if (data.extraValidationData.isCreate === true) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'systemNumber');
            }
          }
        },
        site_type_id: {
          presence: this.customMessageOrDefault(data, 'systemTypeId')
        },
        system_type_id: {
          presence: this.customMessageOrDefault(data, 'systemTypeId')
        },
        address: {
          presence: this.customMessageOrDefault(data, 'address')
        },
        site_location_id: {
          presence: this.customMessageOrDefault(data, 'siteLocation')
        }
      };
      var validateData = (0, _validate.default)(this.objectToJson(this), validationObject, {
        fullMessages: this.prependProperty(data)
      });
      return this.outputFormatting(validateData, 'cmsCustomerOrder');
    }

  });

  _exports.default = _default;
});