define("securitytrax-ember-ui/components/pages/accounting/component", ["exports", "securitytrax-ember-ui/mixins/table-column-sort", "ember-concurrency"], function (_exports, _tableColumnSort, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableColumnSort.default, {
    grants: Ember.inject.service(),
    enhancedFeatures: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    financingEnabledForCompany: false,
    initDataTask: (0, _emberConcurrency.task)(function* () {
      // BOWLES TODO: Pull feature company enabled value direct from session data with TRAX-12015
      var companyPublicInfo = yield this.sessionData.getPublicCompanyInfo();
      this.financingEnabledForCompany = companyPublicInfo && companyPublicInfo.ui_feature_flags.consumer_financing && companyPublicInfo.ui_feature_flags.consumer_financing.enabled;
    }).on('init'),
    accountingCards: Ember.computed(function () {
      var grants = this.grants;
      return [{
        'cardComponent': 'customer/dashboard/invoices-card',
        'display': grants.getIndividualGrant('customer_invoices.view'),
        'titleKey': 'pages.customers.dashboard.invoices_card.title',
        'grants': ['customer_invoices.view'],
        'hideLink': true
      }, {
        'cardComponent': 'customer/dashboard/quotes-card',
        'display': grants.getIndividualGrant('customer_invoices.view'),
        'titleKey': 'pages.customers.dashboard.quotes_card.title',
        'grants': ['customer_invoices.view'],
        'hideLink': true
      }, {
        'cardComponent': 'customer/dashboard/funding-entries-card',
        'display': grants.getIndividualGrant('fundings.view'),
        'titleKey': 'pages.customers.dashboard.funding_entries_card.title',
        'grants': ['fundings.view'],
        'hideLink': true
      }, {
        'cardComponent': 'customer/dashboard/customer-profit-card',
        'display': grants.getIndividualGrant('customer_dashboard_cards_profit_and_losses.view') && grants.getIndividualGrant('customer_payable_payments.view') && grants.getIndividualGrant('fundings.view'),
        'titleKey': 'pages.customers.dashboard.customer_profit_card.title',
        'grants': ['customer_dashboard_cards_profit_and_losses.view', 'customer_payable_payments.view', 'fundings.view'],
        'hideLink': true
      }, {
        'cardComponent': 'customer/dashboard/payable-entries-card',
        'display': grants.getIndividualGrant('customer_payables.view'),
        'titleKey': 'pages.customers.dashboard.payable_entries_card.title',
        'grants': ['customer_payables.view'],
        'hideLink': true
      }, {
        'cardComponent': 'customer/dashboard/payables-summary-card',
        'display': grants.getIndividualGrant('customer_dashboard_cards_payable_summaries.view'),
        'titleKey': 'pages.customers.dashboard.payables_summary_card.title',
        'grants': ['customer_dashboard_cards_payable_summaries.view'],
        'hideLink': true
      }];
    }),
    hasEnhancedInvoicesFeature: Ember.computed(function () {
      return this.enhancedFeatures.hasFeature('enhancedInvoices');
    }),
    hasFinancingFeature: Ember.computed(function () {
      return this.enhancedFeatures.hasFeature('consumerFinancing');
    })
  });

  _exports.default = _default;
});