define("securitytrax-ember-ui/components/customer/at-risk-entries/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/table-column-sort", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _emberConcurrency, _tableColumnSort, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableColumnSort.default, {
    atRiskEntry: undefined,
    atRiskEntryFormMode: 'edit',
    atRiskEntryFormStarted: false,
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    homeDashboard: Ember.inject.service('events/home-dashboard'),

    init() {
      this._super(...arguments);

      this.homeDashboard.on('refresh-at-risk-entries', this, '_callback');
      (0, _objHelpers.initializeState)(this, {
        stSortableColumnOptions: [{
          name: this.intl.t('pages.customers.at_risk_entries.assigned_to') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'assigned_user',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('pages.customers.at_risk_entries.assigned_to') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-assigned_user',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('common.columns.created_date') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'created_at',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.created_date') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-created_at',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('common.columns.status') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'is_resolved',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.status') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-is_resolved',
          chevronDirection: 'down'
        }]
      });
    },

    willDestroy() {
      this.homeDashboard.off('refresh-at-risk-entries', this, '_callback');
    },

    _callback() {
      Ember.notifyPropertyChange(this, 'atRiskItems');
    },

    atRiskItems: Ember.computed('customer.customerAtRisks.{[],@each.isClosed,@each.assignedUser}', 'stSortString', function () {
      return this.atRiskItemsTask.perform();
    }),
    atRiskItemsTask: (0, _emberConcurrency.task)(function* () {
      var customerAtRisks = yield this.store.query('customer-at-risk', {
        filter: 'customer = ' + this.customer.get('id') + " and is_closed = ".concat(this.isClosed),
        sort: this.stSortString ? this.stSortString : 'created_at',
        include: 'assigned_user,customer_at_risk_reason,customer,customer_at_risk_reason,customer_at_risk_reason_cause,created_by_user',
        limit: this.limit,
        page: this.page,
        count: 1
      });
      return customerAtRisks;
    }),
    actions: {
      transitionToSubroute(path) {
        this.router.transitionTo('customers.at-risk-entries.edit-create-at-risk', path);
      },

      sortList(column, ascending) {
        this.sortPaginatedList(column, ascending);
      },

      mobileSortList(column) {
        this.sortMobilePaginatedList(column.value);
      }

    }
  });

  _exports.default = _default;
});