define("securitytrax-ember-ui/components/multi-step-form/forms/calendar-event/event-details/component", ["exports", "@glimmer/component", "ember-concurrency", "securitytrax-ember-ui/utils/obj-helpers", "securitytrax-ember-ui/mixed-in-functions/duration-options", "moment"], function (_exports, _component, _emberConcurrency, _objHelpers, _durationOptions, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CalendarEventDetails = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = (0, _emberConcurrency.task)(function* () {
    (0, _objHelpers.initializeState)(this, {
      calendarEventDetailTypeOptions: [],
      calendarEventTypeIncludeCategories: {
        'tech_schedule': this.args.userType === 'tech',
        'sale_schedule': this.args.userType === 'sales_rep'
      }
    });

    if (this.args.userType === 'sales_rep') {
      var filteredCalendarEventTypes = yield this.calendarEventTypes;
      Ember.set(this.args.calendarEvent, 'calendarEventType', filteredCalendarEventTypes[0]);
    }

    this.appointmentDuration = this.args.calendarEvent.duration;
    this.users = yield this.usersTask.perform();
    this.appointmentDuration = this.args.calendarEvent.duration;

    if (this.args.calendarEvent.calendarEventType) {
      this.displayTechOptions = this.args.calendarEvent.calendarEventType.calendarCategory === 'tech_schedule' && !this.args.isDuplicate && !this.args.isReschedule;
    }

    var hashData = yield (0, _emberConcurrency.hash)({
      users: this.usersTask.perform(),
      calendarEventType: this.args.calendarEvent.calendarEventType,
      appointmentTypeDefaults: this.sessionData.getCompanyDefaults('appointmentTypeDefault')
    });
    Ember.setProperties(this, {
      users: hashData.users,
      appointmentTypeDefaults: hashData.appointmentTypeDefaults
    });

    if (hashData.calendarEventType && hashData.calendarEventType.id) {
      yield this.setupCalendarEventDetailTypeOptions.perform(this.args.calendarEvent.get('calendarEventType.id'));
    }

    yield (0, _emberConcurrency.waitForProperty)(this.AppointmentTimeService, 'startTime', time => time !== undefined);
    this.defaultStartTime = this.AppointmentTimeService.startTime;
    this.defaultEndTime = (0, _moment.default)(this.AppointmentTimeService.endTime).subtract(30, 'minutes');
  }), _dec15 = (0, _emberConcurrency.task)(function* () {
    return yield this.store.findAll('dryRunReason');
  }), _dec16 = Ember.computed('args.calendarEvent.startTime'), _dec17 = Ember.computed('args.calendarEvent.duration'), _dec18 = (0, _emberConcurrency.task)(function* () {
    var calendarEventTypes = yield this.store.findAll('calendar-event-type');
    var filteredCalendarEventTypes = [];
    var calendarEventTypeIncludeCategories = this.calendarEventTypeIncludeCategories;
    calendarEventTypes.forEach(calendarEventType => {
      if (calendarEventType.get('status') === 'active') {
        if (calendarEventTypeIncludeCategories[calendarEventType.get('calendarCategory')]) {
          if (calendarEventType.nameAsKey != 'timeoff') {
            filteredCalendarEventTypes.push(calendarEventType);
          }
        }
      }
    });
    return filteredCalendarEventTypes;
  }), _dec19 = (0, _emberConcurrency.task)(function* () {
    //findAll only returns first 100
    var roleFilter = '';

    if (this.args.userType === 'tech') {
      roleFilter = ' and technician = true';
    } else if (this.args.userType === 'sales_rep') {
      roleFilter = ' and sales_rep = true';
    }

    var allCalendarUsers = yield this.store.query('calendarUser', {
      filter: 'active = true' + roleFilter,
      limit: 0,
      sort: 'lname, fname',
      include: 'roles'
    });
    return allCalendarUsers.toArray();
  }), _dec20 = (0, _emberConcurrency.task)(function* (calendarEventTypeId, setAppointmentTypeDefault = false) {
    this.calendarEventDetailTypeOptions = [];
    var asyncData = yield (0, _emberConcurrency.hash)({
      selectedCalendarEventType: this.store.findRecord('calendarEventType', calendarEventTypeId),
      company: this.sessionData.getCompany()
    });

    if (setAppointmentTypeDefault && (this.args.formMode !== 'create' || this.args.newCalendarEvent && this.args.newCalendarEvent.get('profile.responseResourceType') !== 'calendar_slots')) {
      var defaultDuration = Number(this.appointmentTypeDefaults.get(asyncData.selectedCalendarEventType.get('name').toLowerCase() + 'AppointmentDuration'));

      if (defaultDuration) {
        this.appointmentDuration = defaultDuration;
        this.args.onUpdateDuration(defaultDuration);
      }
    }

    var asyncData2 = yield (0, _emberConcurrency.hash)({
      listOfCalendarEventDetailTypes: asyncData.selectedCalendarEventType.get('calendarEventDetailTypes'),
      calendarEventDetailTypeDefaults: asyncData.company.get('calendarEventDetailTypeDefaults')
    });
    this.calendarEventDetailTypeOptions = asyncData2.listOfCalendarEventDetailTypes;
    var defaultCalendarEventDetailTypeCamelKey = asyncData.selectedCalendarEventType.get('nameAsKeyCamel') + 'SubtypeId';
    var defaultCalendarEventDetailTypeId = asyncData2.calendarEventDetailTypeDefaults[defaultCalendarEventDetailTypeCamelKey];

    if (defaultCalendarEventDetailTypeId) {
      var defaultCalendarEventDetailType = yield this.store.findRecord('calendarEventDetailType', defaultCalendarEventDetailTypeId);
      Ember.set(this.args.calendarEvent, 'calendarEventDetailType', defaultCalendarEventDetailType);
    }
  }), _dec21 = (0, _emberConcurrency.task)(function* () {
    var calendarEventTypeId = this.args.calendarEvent.get('calendarEventType.id');

    if (calendarEventTypeId) {
      yield this.setupCalendarEventDetailTypeOptions.perform(calendarEventTypeId, true);
    }

    this.args.buttons();
  }), _dec22 = (0, _emberConcurrency.task)(function* () {
    var calendarEventDetailType = yield this.args.calendarEvent.get('calendarEventDetailType');
    var defaultDuration = calendarEventDetailType ? calendarEventDetailType.get('duration') : null;

    if (defaultDuration && (this.args.formMode !== 'create' || this.args.newCalendarEvent && this.args.newCalendarEvent.get('profile.responseResourceType') !== 'calendar_slots')) {
      this.appointmentDuration = defaultDuration;
      this.args.onUpdateDuration(defaultDuration);
    }
  }), _dec23 = Ember._action, _dec24 = Ember._action, _dec25 = Ember._action, (_class = (_temp = class CalendarEventDetails extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "sessionData", _descriptor2, this);

      _initializerDefineProperty(this, "AppointmentTimeService", _descriptor3, this);

      _initializerDefineProperty(this, "calendarEventTypeIncludeCategories", _descriptor4, this);

      _initializerDefineProperty(this, "calendarEventDetailTypeOptions", _descriptor5, this);

      _initializerDefineProperty(this, "showDurationWarning", _descriptor6, this);

      _initializerDefineProperty(this, "users", _descriptor7, this);

      _initializerDefineProperty(this, "defaultStartTime", _descriptor8, this);

      _initializerDefineProperty(this, "defaultEndTime", _descriptor9, this);

      _initializerDefineProperty(this, "appointmentDuration", _descriptor10, this);

      _initializerDefineProperty(this, "displayTechOptions", _descriptor11, this);

      _initializerDefineProperty(this, "durationChangedToEOD", _descriptor12, this);

      _initializerDefineProperty(this, "appointmentTypeDefaults", _descriptor13, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor14, this);

      _initializerDefineProperty(this, "dryRunReasonsTask", _descriptor15, this);

      _initializerDefineProperty(this, "calendarEventTypesTask", _descriptor16, this);

      _initializerDefineProperty(this, "usersTask", _descriptor17, this);

      _initializerDefineProperty(this, "setupCalendarEventDetailTypeOptions", _descriptor18, this);

      _initializerDefineProperty(this, "updateCalendarEventDetailTypeOptionsTask", _descriptor19, this);

      _initializerDefineProperty(this, "updateDurationTask", _descriptor20, this);

      this.initDataTask.perform();
    }

    get dryRunReasons() {
      return this.dryRunReasonsTask.perform();
    }

    get durationOptions() {
      return (0, _durationOptions.default)(this.args.calendarEvent.startTime);
    }

    get copiedCalculatedEndtime() {
      if (this.args.calendarEvent) {
        var startTime = this.args.calendarEvent.startTime;
        var duration = this.args.calendarEvent.duration;

        if (startTime && duration) {
          var clonedStartTime = (0, _moment.default)(startTime).clone();

          if (duration) {
            clonedStartTime = clonedStartTime.add(duration, 'seconds');
          }

          return clonedStartTime;
        }
      }

      return null;
    }

    get calendarEventTypes() {
      return this.calendarEventTypesTask.perform();
    }

    updateDuration() {
      this.updateDurationTask.perform();
    }

    showDurationWarningCheck() {
      if (this.args.newCalendarEvent && this.args.newCalendarEvent.get('profile.responseResourceType') !== 'calendar_slots') {
        this.showDurationWarning = true;
      } else {
        this.showDurationWarning = false;
      }
    }

    startTimeChange() {
      var durationOptions = this.durationOptions;
      var startTime = this.args.calendarEvent.startTime;
      var endOfDay = (0, _moment.default)(startTime).endOf('day');
      var difAppointmentStartEOD = Math.abs(_moment.default.duration((0, _moment.default)(startTime).diff(endOfDay)).asSeconds());

      if (difAppointmentStartEOD < this.args.calendarEvent.duration) {
        //need to change duration and throw warning. 
        this.durationChangedToEOD = true;
        this.args.onUpdateDuration(durationOptions[durationOptions.length - 1].value);
      } else {
        this.durationChangedToEOD = false;
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "AppointmentTimeService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "calendarEventTypeIncludeCategories", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "calendarEventDetailTypeOptions", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "showDurationWarning", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "users", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "defaultStartTime", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "defaultEndTime", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "appointmentDuration", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "displayTechOptions", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "durationChangedToEOD", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "appointmentTypeDefaults", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "dryRunReasonsTask", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "durationOptions", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "durationOptions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "copiedCalculatedEndtime", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "copiedCalculatedEndtime"), _class.prototype), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "calendarEventTypesTask", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "usersTask", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "setupCalendarEventDetailTypeOptions", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "updateCalendarEventDetailTypeOptionsTask", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "updateDurationTask", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateDuration", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "updateDuration"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showDurationWarningCheck", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "showDurationWarningCheck"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startTimeChange", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "startTimeChange"), _class.prototype)), _class));
  _exports.default = CalendarEventDetails;
});