define("securitytrax-ember-ui/administration/company-settings/equipment/equipment-vendors/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GG3GBYFJ",
    "block": "{\"symbols\":[],\"statements\":[[8,\"pages/administration/company-settings/equipment/equipment-vendors\",[],[[\"@breadCrumbs\",\"@breadCrumbData\"],[[34,0],[34,1]]],null]],\"hasEval\":false,\"upvars\":[\"breadCrumbs\",\"breadCrumbData\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/administration/company-settings/equipment/equipment-vendors/template.hbs"
    }
  });

  _exports.default = _default;
});