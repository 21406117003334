define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/contacts/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZmVO5uQp",
    "block": "{\"symbols\":[\"data\",\"@multiStepForm\",\"@currFormName\"],\"statements\":[[8,\"multi-step-form/progress-bar\",[],[[\"@config\",\"@modalButtons\",\"@loadedNamespaces\"],[[32,2,[\"progressBarConfig\"]],[32,2,[\"currentStepData\",\"buttons\"]],[30,[36,0],[[32,3],\"step5\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"html/st-await\",[],[[\"@useSpinner\",\"@promises\",\"@loadedNamespace\",\"@size\"],[true,[30,[36,2],null,[[\"customerAbortCode\"],[[35,1]]]],[30,[36,0],[[35,3],\"step5\"],null],\"large\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"customer/emergency-contact-list\",[],[[\"@formMode\",\"@sectionDivider\",\"@customer\",\"@st-card-class\",\"@header\",\"@isASG\"],[[34,4],true,[34,5],\"emergency-contacts asg\",[30,[36,7],[[35,6],\"common.nav.create.customer.sections.emergencyContactData\",\"pages.customers.emergency_contacts.title\"],null],true]],null],[2,\"\\n\\n        \"],[8,\"html/section-divider\",[],[[\"@title\"],[[30,[36,8],[\"pages.customers.system.centralStation.label\"],null]]],null],[2,\"\\n        \"],[10,\"div\"],[14,0,\"grid-x grid-padding-x\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"cell small-6 medium-3\"],[12],[2,\"\\n                \"],[8,\"multi-step-form/switchable-inputs/text-input\",[],[[\"@formMode\",\"@label\",\"@value\"],[\"edit\",[30,[36,8],[\"pages.customers.information.customer_system_information.abortCode.label\"],null],[34,9,[\"abortCode\"]]]],null],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"customerAbortCode\",\"hash\",\"currFormName\",\"edit\",\"newCustomer\",\"isASG\",\"if\",\"t\",\"customerAbortCodeModel\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/contacts/template.hbs"
    }
  });

  _exports.default = _default;
});