define("securitytrax-ember-ui/components/multi-step-form/switchable-inputs/select-input/component", ["exports", "@ember-data/model", "securitytrax-ember-ui/mixins/switchable-input", "ember-concurrency", "securitytrax-ember-ui/utils/obj-helpers", "lodash"], function (_exports, _model, _switchableInput, _emberConcurrency, _objHelpers, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableInput.default, {
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    searchField: Ember.computed.alias('labelProperty'),
    searchPlaceholder: '',
    filteredOptions: undefined,
    isOpen: false,
    canOpen: undefined,
    checkActive: true,
    placeholder: '',
    valueChange: '',
    hidePlaceHolder: false,
    searchEnabled: true,
    valueProperty: 'id',
    labelProperty: 'name',
    sortBy: undefined,
    allowClear: true,
    multiple: false,
    verticalPosition: 'auto',
    bindValueAsObject: true,
    noSort: false,
    cacheSelected: false,
    filterCacheKey: null,
    listCacheKey: null,
    multiSelectSelected: null,
    multiSelectSelectedParsed: null,
    value: undefined,
    selectInputInitHasRun: undefined,
    autofocus: true,

    init() {
      var _this$sortBy;

      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        filteredOptions: [],
        canOpen: true,
        selectInputInitHasRun: false,
        sortBy: (_this$sortBy = this.sortBy) !== null && _this$sortBy !== void 0 ? _this$sortBy : this.labelProperty
      });

      if (this.cacheSelected && this.multiple) {
        var cachedMultiSelectFilter = this.getCachedMultiSelectFilter();
        Ember.set(this, 'multiSelectSelected', cachedMultiSelectFilter);
        Ember.set(this, 'multiSelectSelectedParsed', cachedMultiSelectFilter.map(this.valueProperty));
      }
    },

    observeOptions: Ember.observer('options', 'options.@each', 'options.length', function () {
      return this.initData.perform();
    }),
    initData: (0, _emberConcurrency.task)(function* () {
      if (this.isFilter && this.selectInputInitHasRun === false) {
        var defaultValue = null;

        if (this.defaultValue != null) {
          defaultValue = {
            value: this.defaultValue,
            callback: defaultValue => {
              this.setSelectValueForFilter(defaultValue, true);
            }
          };
        }

        var filterData = this.stFiltersService.registerFilter(this.stFilterGroupKey, this.stFilterKey, ['selectedValue'], defaultValue);

        if (filterData['selectedValue']) {
          this.setSelectValueForFilter(filterData['selectedValue'].fieldValue);
        } else {
          if (this.defaultValue != null) {
            this.setSelectValueForFilter(this.defaultValue, true);
          } else {
            Ember.run.later(() => {
              this.stFiltersService.setAsLoaded(this.stFilterGroupKey, this.stFilterKey);
            });
          }
        }

        Ember.set(this, 'selectInputInitHasRun', true);
      }

      var newOptions = [];
      var optionsList = yield this.options;

      if (!this.checkActive) {
        this.set('filteredOptions', optionsList);
        return;
      }

      try {
        if (optionsList) {
          var value = yield this.value;
          var valueProperty = this.valueProperty;
          var labelProperty = this.labelProperty;
          newOptions = optionsList.filter(currOption => {
            // if selected value is in unfiltered list then keep in in the list
            // filter list by active true/false IF there is an active property
            var selectedInUnfiltered = value && Ember.get(value, 'id') == Ember.get(currOption, 'id');

            if (!selectedInUnfiltered && Ember.get(currOption, 'active') != null && !Ember.get(currOption, 'active')) {
              return false;
            }

            return true;
          });

          if (this.noSort === false) {
            newOptions = newOptions.sortBy(this.sortBy);
          } // if select was not in the unfiltered list then we need to go get it


          var firstObject = optionsList.get('firstObject');
          var dsModelName = optionsList.get('length') > 0 && firstObject.constructor && firstObject.constructor.modelName ? firstObject.constructor.modelName.camelize() : null;
          var modelName = this.extraOptionsType || dsModelName;

          if (modelName && value && Ember.get(value, 'id') && !newOptions.findBy('id', Ember.get(value, 'id'))) {
            try {
              var objectToAdd = yield this.store.findRecord(modelName, Ember.get(value, 'id'));
              var plainObjectToAdd = {};
              plainObjectToAdd[valueProperty] = Ember.get(objectToAdd, valueProperty);
              plainObjectToAdd[labelProperty] = Ember.get(objectToAdd, labelProperty);
              newOptions.pushObject(plainObjectToAdd);
            } catch (error) {
              (false && Ember.warn('select component continue 1...' + error, {
                id: 'multiStepForm.switchableInputs.selectInput.id'
              }));
            }
          }

          try {
            this.set('filteredOptions', newOptions);
          } catch (errors) {
            this.set('filteredOptions', optionsList);
            (false && Ember.warn('select component continue 2 ...' + errors, {
              id: 'multiStepForm.switchableInputs.selectInputs.selectInput.filteredOptions'
            }));
          }
        } else {
          this.set('filteredOptions', optionsList);
        }
      } catch (error) {
        this.set('filteredOptions', optionsList);
        (false && Ember.warn('error in select component init' + error, {
          id: 'multiStepForm.switchableInputs.selectInputs.selectInput.init'
        }));
      }
    }).on('init'),

    setSelectValueForFilter(newValue, doUpdateAlso = false) {
      var optionsListNotYielded = this.options;
      var newValueFound = null;

      if (newValue.id && optionsListNotYielded.findBy) {
        newValueFound = optionsListNotYielded.findBy('id', newValue.id);
      }

      Ember.run.later(() => {
        if (newValueFound != null) {
          Ember.set(this, 'value', newValueFound);

          if (this.onInit) {
            this.onInit(newValueFound);
          }
        } else {
          Ember.set(this, 'value', newValue);

          if (this.onInit) {
            this.onInit(newValue);
          }
        }

        if (doUpdateAlso) {
          this.updateFilterData();
        } else {
          this.stFiltersService.setAsLoaded(this.stFilterGroupKey, this.stFilterKey);
        }
      });
    },

    valueAttr: Ember.computed('value', 'valueProperty', function () {
      var getValue = this.getValue(this.value);
      return getValue;
    }),
    selected: Ember.computed('valueAttr', 'filteredOptions', function () {
      if (this.filteredOptions && this.filteredOptions instanceof Array && this.filteredOptions.length > 0 && this.valueAttr && this.options[1]) {
        var valueProperty = this.valueProperty;
        var expectedType = typeof this.filteredOptions[0][valueProperty];
        var passedType = typeof this.valueAttr;

        if (expectedType !== passedType && passedType !== typeof this.options[1][valueProperty]) {
          throw new Error("Type mismatch for power select options. ".concat(valueProperty, " in options array is a ").concat(expectedType, ". You passed a ").concat(passedType, "."));
        }
      }

      var selected = this.filteredOptions ? this.filteredOptions.findBy(this.valueProperty, this.valueAttr) : null;
      return selected;
    }),
    selectedArray: Ember.computed('multiSelectSelected', function () {
      return this.multiSelectSelected ? this.multiSelectSelected instanceof Array ? this.multiSelectSelected : [this.multiSelectSelected] : null;
    }),

    bindValue(newValue) {
      if (newValue === null) {
        Ember.set(this.selected, 'disabledText', undefined);
        Ember.set(this.selected, 'disabledTextClass', undefined);
        Ember.set(this.selected, 'disabled', false);
      }

      if (typeof newValue === 'object' && newValue instanceof _model.default && this.bindValueAsObject) {
        this.set('value', newValue);
      } else {
        this.set('value', this.getValue(newValue));
      }

      this.updateFilterData();

      if (this.onChange) {
        return this.onChange.call(this, newValue);
      }
    },

    //make sure we have the right value
    getValue(value) {
      var valueProperty = this.valueProperty;

      if (typeof value === 'object' && value instanceof _model.default && this.bindValueAsObject) {
        return this.get("value.".concat(valueProperty));
      } else if (value && typeof value === 'object') {
        return value[valueProperty];
      }

      return value;
    },

    getCachedMultiSelectFilter() {
      if (!this.filterCacheKey) {
        throw new Error("filterCacheKey is required to cache selected options.");
      }

      if (!this.listCacheKey) {
        throw new Error("listCacheKey is required to cache selected options.");
      }

      var localFilter = this.session.get('data.' + this.listCacheKey);

      if (localFilter !== null && typeof localFilter !== 'undefined' && Object.keys(JSON.parse(localFilter)).length > 0) {
        var parsedFilter = JSON.parse(localFilter);

        if (parsedFilter[this.filterCacheKey]) {
          var optionIds = parsedFilter[this.filterCacheKey];
          return this.options.filter(option => {
            return optionIds.includes('\'' + option.id + '\'');
          });
        }

        return null;
      }
    },

    updateFilterData() {
      if (this.isFilter) {
        if (this.value != null) {
          var actualValue = this.value;

          if (this.valueProperty != null) {
            var actualValueGet = Ember.get(this.value, this.valueProperty);

            if (actualValueGet != null) {
              actualValue = actualValueGet;
            }
          }

          var filterValue = this.stFilterValueAsString ? "'".concat(actualValue, "'") : actualValue;
          var filter = this.stFiltersService.getActualFilterOrUseCallback(this.stFilterAttribute, this.value, "".concat(this.stFilterAttribute, " = ").concat(filterValue));
          this.stFiltersService.saveFilter(this.stFilterGroupKey, this.stFilterKey, 'selectedValue', filter, this.value, this.stFilterDoNotCache, this.overrideFilterKey);
        } else {
          this.stFiltersService.clearFilter(this.stFilterGroupKey, this.stFilterKey, 'selectedValue');
        }
      }
    },

    actions: {
      setMultiSelectSelected(multiSelectSelectedValue) {
        Ember.set(this, 'multiSelectSelected', multiSelectSelectedValue);
        Ember.set(this, 'multiSelectSelectedParsed', _lodash.default.map(multiSelectSelectedValue, this.valueProperty));

        if (this.onChange) {
          return this.onChange.call(this, newValue);
        }
      },

      onOpen() {
        if (this.canOpen) {
          if (typeof this.onOpen === 'function') {
            this.onOpen();
          }

          this.isOpen = true;
          return true;
        }

        return false;
      },

      onClose() {
        this.isOpen = false;

        if (typeof this.onClose === 'function') {
          this.onClose();
        }

        return true;
      }

    }
  });

  _exports.default = _default;
});