define("securitytrax-ember-ui/home/tech-today/appointment/manage-brinks-home-security/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a4lMlB23",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"modals/sub-route-modals/manage-brinks\",[],[[\"@customer\",\"@customerSystemInformation\",\"@monitoringCompany\",\"@parentRoute\",\"@transitionId\"],[[32,1,[\"customer\"]],[32,1,[\"customerSystemInformation\"]],[32,1,[\"monitoringCompany\"]],[32,1,[\"parentRoute\"]],[32,1,[\"transitionId\"]]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/home/tech-today/appointment/manage-brinks-home-security/template.hbs"
    }
  });

  _exports.default = _default;
});