define("securitytrax-ember-ui/models/equipment-to-recipient", ["exports", "securitytrax-ember-ui/models/base_models/equipment-to-recipient"], function (_exports, _equipmentToRecipient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _equipmentToRecipient.default.extend({});

  _exports.default = _default;
});