define("securitytrax-ember-ui/leads/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J+npt2d2",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"page-content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"scroll-page\"],[12],[2,\"\\n        \"],[1,[30,[36,0],null,[[\"entity\"],[\"lead\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"entity-search-list\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/leads/index/template.hbs"
    }
  });

  _exports.default = _default;
});