define("securitytrax-ember-ui/components/modals/sub-route-modals/manage-guardian/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var GuardianAddAgency = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.hash)({
      customer: this.args.customer,
      monitoringCompany: this.args.monitoringCompany,
      monitoringCompanyType: this.args.monitoringCompany.get('monitoringCompanyType')
    });
    this.formMode = this.args.customerSystemInformation.get('centralStationId') ? 'edit' : 'create';
    this.formStarted = true;
  }), _dec8 = Ember._action, _dec9 = Ember._action, (_class = (_temp = class GuardianAddAgency extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "grants", _descriptor2, this);

      _initializerDefineProperty(this, "centralStationService", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "formStarted", _descriptor5, this);

      _initializerDefineProperty(this, "formMode", _descriptor6, this);

      _initializerDefineProperty(this, "initData", _descriptor7, this);

      this.initData.perform();
    }

    get formOriginalModel() {
      var grants = this.grants;
      return Ember.Object.create({
        customer: {
          model: this.args.customer,
          copyRelationMap: {
            customerSystemInformation: {
              doCopy: grants.getIndividualGrant('customer_system_information.view'),
              doValidate: grants.getIndividualGrant('customer_system_information.view'),
              doSave: grants.getIndividualGrant('customer_system_information.modify'),
              relationships: {
                customerAbortCode: {
                  doCopy: grants.getIndividualGrant('customer_abort_codes.view'),
                  doValidate: false,
                  doSave: grants.getIndividualGrant('customer_abort_codes.modify')
                }
              }
            },
            customerEquipment: {
              readOnly: true,
              relationships: {
                equipmentStatus: {
                  readOnly: true
                }
              }
            }
          }
        }
      });
    }

    get formSteps() {
      var titleData = {
        monitorTypeName: this.args.monitoringCompany.get('monitoringCompanyType.name'),
        customerName: this.args.customer.get('flname'),
        id: this.args.customer.get('id')
      };
      return Ember.Object.create({
        general: Ember.Object.create({
          stepNameKey: 'general',
          title: this.formMode == 'create' ? this.intl.t('pages.customers.system.order.stages_create_title', titleData) : this.intl.t('pages.customers.system.order.title', titleData),
          stepComponentPath: 'multi-step-form/forms/system/guardian/general',
          progressBarTitle: this.intl.t('pages.customers.system.order.general')
        }),
        uploadFile: Ember.Object.create({
          stepNameKey: 'uploadFile',
          title: this.intl.t('pages.customers.system.order.title', titleData),
          stepComponentPath: 'multi-step-form/forms/system/guardian/upload-file',
          progressBarTitle: this.intl.t('pages.customers.system.order.upload_file')
        })
      });
    }

    transitionBackToParentRoute() {
      this.router.transitionTo(this.args.parentRoute, this.args.transitionId);
    }

    afterCreate() {
      this.formMode = 'edit';
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "formStarted", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "formMode", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'edit';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "initData", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "transitionBackToParentRoute", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "transitionBackToParentRoute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterCreate", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "afterCreate"), _class.prototype)), _class));
  _exports.default = GuardianAddAgency;
});