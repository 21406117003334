define("securitytrax-ember-ui/components/adt-pre-check-tooltip/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _emberConcurrency, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['pre-check-tooltip-wrapper'],
    loadedNamespace: undefined,
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),

    submitAdtPreCheck() {},

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        loadedNamespace: 'global'
      });
    },

    monitoringCompanyHasNeededGrants: Ember.computed('grants.userGrants', function () {
      return this.monitoringCompanyHasNeededGrantsTask.unlinked().perform();
    }),
    monitoringCompanyHasNeededGrantsTask: (0, _emberConcurrency.task)(function* () {
      var grants = this.grants;
      var grantsData = yield (0, _emberConcurrency.hash)({
        customerModify: grants.getIndividualGrant('customers.modify'),
        orderInformationModify: grants.getIndividualGrant('customer_order_information.modify'),
        systemInformationModify: grants.getIndividualGrant('customer_system_information.modify'),
        agencyContactsView: grants.getIndividualGrant('agency_contacts.view'),
        agencyContactsModify: grants.getIndividualGrant('agency_contacts.modify'),
        customerContactsCreate: grants.getIndividualGrant('customer_contacts.create'),
        customerContactsModify: grants.getIndividualGrant('customer_contacts.modify'),
        customerBillingsCreate: grants.getIndividualGrant('customer_billings.create'),
        customerBillingsModify: grants.getIndividualGrant('customer_billings.modify'),
        customerEquipmentView: grants.getIndividualGrant('customer_equipment.view')
      });
      var hasGrants = grantsData.customerModify && grantsData.orderInformationModify && grantsData.systemInformationModify && grantsData.agencyContactsView && grantsData.agencyContactsModify && grantsData.customerContactsCreate && grantsData.customerContactsModify && grantsData.customerBillingsCreate && grantsData.customerBillingsModify && grantsData.customerEquipmentView;
      return hasGrants;
    }),
    monitoringCompany: Ember.computed(function () {
      return this.getMonitoringCompany.perform();
    }),
    getMonitoringCompany: (0, _emberConcurrency.task)(function* () {
      if (this.customerOrderInformation) {
        // Used for when Lead converts to Customer
        return yield this.customerOrderInformation.get('monitoringCompany');
      }

      var customerOrderInformation = this.customer.get('isLead') ? yield this.get('customer.leadInformation') : yield this.get('customer.customerOrderInformation');
      return yield customerOrderInformation.get('monitoringCompany');
    }),
    customerAdtIsConnected: Ember.computed(function () {
      return this.adtIsConnectedTask.unlinked().perform();
    }),
    adtIsConnectedTask: (0, _emberConcurrency.task)(function* () {
      try {
        var monitoringCompany = yield this.monitoringCompany;
        var monitoringCompanyType = yield monitoringCompany.get('monitoringCompanyType');
        return monitoringCompany.get('isConnected') && monitoringCompanyType && monitoringCompanyType.get('id') === '1';
      } catch (e) {// do nothing
      }

      return false;
    }),
    adtIsEnabled: Ember.computed(function () {
      return this.adtIsEnabledTask.unlinked().perform();
    }),
    adtIsEnabledTask: (0, _emberConcurrency.task)(function* () {
      try {
        var monitoringCompany = yield this.monitoringCompany;
        var monitoringCompanyConfig = monitoringCompany && this.grants.getIndividualGrant('monitoring_company_configs.manage') ? yield monitoringCompany.get('monitoringCompanyConfig') : null;

        if (monitoringCompanyConfig) {
          return monitoringCompanyConfig.enabled;
        }
      } catch (e) {// do nothing
      }

      return false;
    }),
    showAdtPrecheck: Ember.computed(function () {
      return this.showAdtPrecheckTask.perform();
    }),
    showAdtPrecheckTask: (0, _emberConcurrency.task)(function* () {
      var adtHashData = yield (0, _emberConcurrency.hash)({
        monitoringCompanyHasNeededGrants: this.monitoringCompanyHasNeededGrants,
        adtIsEnabled: this.adtIsEnabled,
        adtIsConnected: this.customerAdtIsConnected
      });
      return adtHashData.monitoringCompanyHasNeededGrants && adtHashData.adtIsEnabled && adtHashData.adtIsConnected;
    }),
    popperOptions: Ember.computed(function () {
      return {
        modifiers: {
          preventOverflow: {
            escapeWithReference: false
          }
        }
      };
    }),
    dataContent: Ember.computed('preCheckStatus', function () {
      return this.dataContentTask.perform();
    }),
    dataContentTask: (0, _emberConcurrency.task)(function* () {
      var dataContent = '';
      var resPreCheckStatus = yield this.preCheckStatus;

      if (resPreCheckStatus === 'halt') {
        dataContent = this.preCheckStatusMessage;
      } else if (resPreCheckStatus === 'proceed' || resPreCheckStatus === 'review_required' || resPreCheckStatus === 'validation_failed') {
        dataContent = yield this.parseData(this.get('adtPreCheck.pre_check_data'));
      }

      return dataContent;
    }),
    preCheckStatus: Ember.computed('adtPreCheck.status.code', function () {
      return this.preCheckStatusTask.perform();
    }),
    preCheckStatusTask: (0, _emberConcurrency.task)(function* () {
      var resAdtPreCheck = yield this.adtPreCheck;
      var status = typeof resAdtPreCheck === 'undefined' || resAdtPreCheck === null || typeof resAdtPreCheck.get('status') === 'undefined' ? 'none' : resAdtPreCheck.get('status').code;
      return status;
    }),
    adtPreCheckButtonClass: Ember.computed('adtPreCheck.status.code', function () {
      var adt = this.adtPreCheck;
      var buttonClass = 'pre-check-btn ';

      if (adt) {
        switch (adt.get('status.code')) {
          case 'proceed':
            buttonClass += 'pre-check-success';
            break;

          case 'validation_failed':
            buttonClass += 'pre-check-validation-failed';
            break;

          case 'review_required':
            buttonClass += 'pre-check-review';
            break;

          case 'halt':
            buttonClass += 'pre-check-error';
            break;
        }
      } else {
        buttonClass += 'pre-check-none';
      }

      return buttonClass;
    }),
    preCheckStatusMessage: Ember.computed('adtPreCheck.status.message', function () {
      return this.preCheckStatusMessageTask.perform();
    }),
    preCheckStatusMessageTask: (0, _emberConcurrency.task)(function* () {
      var resAdtPreCheck = yield this.adtPreCheck;
      return typeof resAdtPreCheck === 'undefined' || resAdtPreCheck === null || typeof resAdtPreCheck.get('status') === 'undefined' ? '' : resAdtPreCheck.get('status').message;
    }),
    precheckHasErrors: Ember.computed('adtPreCheck.errors.length', function () {
      return this.precheckHasErrorsTask.perform();
    }),
    precheckHasErrorsTask: (0, _emberConcurrency.task)(function* () {
      var resAdtPreCheck = yield this.adtPreCheck;
      return typeof resAdtPreCheck === 'undefined' || resAdtPreCheck === null || typeof resAdtPreCheck.get('errors') === 'undefined' ? false : resAdtPreCheck.get('errors').length > 0;
    }),

    parseData(adtData) {
      return this.parseDataTask.perform(adtData);
    },

    parseDataTask: (0, _emberConcurrency.task)(function* (adtData) {
      if (!adtData) {
        return;
      }

      var resAdtData = yield adtData;
      var guardChargeAmount = ('$' + Number(resAdtData.guardChargeAmount || 0.00).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')).replace('$-', '-$');
      var translation = 'pages.customers.adt_pre_check.required'; // sometimes required comes back as May Be, so replace space

      var guardRequired = this.intl.t("".concat(translation).concat(resAdtData.guardRequired));
      var policePermitRequired = this.intl.t("".concat(translation).concat(resAdtData.policePermitRequired));
      var firePermitRequired = this.intl.t("".concat(translation).concat(resAdtData.filePermitRequired));
      var medicalPermitRequired = this.intl.t("".concat(translation).concat(resAdtData.medicalPermitRequired));
      var parsedData = 'Guard Price: ' + guardChargeAmount + '<br>' + 'Guard Required: ' + guardRequired + '<br>' + 'Police Permit Required: ' + policePermitRequired + '<br>' + 'Fire Permit Required: ' + firePermitRequired + '<br>' + 'Medical Permit Required: ' + medicalPermitRequired + '<br>' + 'OrderID: ' + resAdtData.orderID;

      if (resAdtData.napeMatchedSiteNumber !== '') {
        parsedData += '<br>' + this.intl.t('pages.customers.adt_pre_check.nape_matched_site_number') + ': ' + resAdtData.napeMatchedSiteNumber;
      }

      return parsedData;
    })
  });

  _exports.default = _default;
});