define("securitytrax-ember-ui/customers/equipment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ak9z32Ig",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[6,[37,1],[[30,[36,0],[\"customer_equipment.view\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"customer-equipment-page\"],[12],[2,\"\\n        \"],[8,\"customer/information/customer-equipment\",[],[[\"@customer\",\"@model\"],[[32,1,[\"customer\"]],[32,1]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"customer/user-defined-fields\",[],[[\"@customer\",\"@model\",\"@udfContext\"],[[34,2,[\"customer\"]],[34,2],\"customers_equipment\"]],null],[2,\"\\n\\n\"],[1,[30,[36,4],[[30,[36,3],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"has-grants\",\"if\",\"model\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/customers/equipment/template.hbs"
    }
  });

  _exports.default = _default;
});