define("securitytrax-ember-ui/components/multi-step-form/forms/administration/content-management/edit-customer-notes-contact-setting/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZLAvFNwT",
    "block": "{\"symbols\":[\"@multiStepForm\",\"@formMode\",\"@settingType\"],\"statements\":[[11,\"div\"],[4,[38,0],[[32,0,[\"renderButtons\"]]],null],[12],[2,\"\\n    \"],[8,\"html/st-await\",[],[[\"@useSpinner\",\"@booleanLoaded\"],[true,[32,1,[\"finishedCopyingModels\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[14,0,\"grid-x grid-padding-x\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"cell small-12 medium-6\"],[12],[2,\"\\n                \"],[8,\"multi-step-form/switchable-inputs/text-input\",[],[[\"@formMode\",\"@value\",\"@label\",\"@errors\",\"@required\",\"@validationKey\"],[[32,2],[30,[36,1],[[30,[36,1],[[32,1,[\"formCopiedModels\"]],[32,3]],null],\"model.name\"],null],[30,[36,2],[\"common.columns.name\"],null],[32,1,[\"validationErrors\"]],true,[30,[36,3],[[32,3],\"-name\"],null]]],null],[2,\"\\n            \"],[13],[2,\"\\n\\n            \"],[10,\"div\"],[14,0,\"cell small-12 medium-6\"],[12],[2,\"\\n                \"],[8,\"multi-step-form/switchable-inputs/checkbox-input\",[],[[\"@formMode\",\"@value\",\"@label\",\"@toggleSwitch\"],[[32,2],[30,[36,1],[[30,[36,1],[[32,1,[\"formCopiedModels\"]],[32,3]],null],\"model.active\"],null],[30,[36,2],[\"common.status.active\"],null],true]],null],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"modals/confirm-popup\",[],[[\"@onConfirm\",\"@confirmMessage\",\"@confirmBtnText\",\"@confirmButtonClassNamesString\",\"@manuallyOpen\",\"@_dialogOpen\",\"@modalNamespace\"],[[30,[36,4],[[32,0,[\"deleteCustomerNotesContactSetting\"]]],null],[30,[36,2],[\"common.confirm.really_delete\"],null],[30,[36,2],[\"common.actions.delete\"],null],\"st-btn-warn\",true,[32,0,[\"confirmDeleteModalOpen\"]],\"modal\"]],null]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"get\",\"t\",\"concat\",\"perform\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/content-management/edit-customer-notes-contact-setting/template.hbs"
    }
  });

  _exports.default = _default;
});