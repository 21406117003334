define("securitytrax-ember-ui/home/tech-today/appointment/manage-adc/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-concurrency", "securitytrax-ember-ui/mixins/subroute-modal"], function (_exports, _authenticatedRouteMixin, _emberConcurrency, _subrouteModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _subrouteModal.default, {
    store: Ember.inject.service(),
    grants: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      var customerId = this.paramsFor('customers').id;
      return this.findGrantsTask.perform(['customer_system_information.view', 'customers.manage_interactive_services_provider_integration'], 'all', '/customers/' + customerId + '/information');
    },

    model() {
      return this.setupModelTask.perform();
    },

    setupModelTask: (0, _emberConcurrency.task)(function* () {
      var parentModel = yield this.modelFor(this.parentRoute);
      var hashData;
      var interactiveServicesProvider;

      try {
        hashData = yield (0, _emberConcurrency.hash)({
          customer: parentModel.appointment.get('customer'),
          customerOrderInformation: parentModel.appointment.get('customer.customerOrderInformation'),
          customerSystemInformation: parentModel.appointment.get('customer.customerSystemInformation'),
          monitoringCompany: parentModel.appointment.get('customer.customerOrderInformation.monitoringCompany')
        });
        interactiveServicesProvider = yield hashData.customerOrderInformation.get('interactiveServicesProvider');
      } catch (error) {
        (false && Ember.warn('failed to get adt monitoring company' + error, {
          id: 'customers.information.manageAdt'
        }));
      }

      return (0, _emberConcurrency.hash)({
        customer: hashData.customer,
        customerSystemInformation: hashData.customerSystemInformation,
        customerOrderInformation: hashData.customerOrderInformation,
        interactiveServicesProvider,
        parentRoute: this.transitionRoute,
        transitionId: parentModel.appointment.get('id')
      });
    })
  });

  _exports.default = _default;
});