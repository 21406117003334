define("securitytrax-ember-ui/helpers/charge-calculator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.chargeCalculator = chargeCalculator;
  _exports.default = void 0;

  function chargeCalculator(params
  /*, hash*/
  ) {
    var amount = params[0];

    if (+amount === 0) {
      return "$0";
    } else if (+amount < 1) {
      return "".concat(amount.replace(/^[0.]*/, ''), "\xA2");
    } else {
      return amount ? "$".concat(amount.replace('.00', '')) : '';
    }
  }

  var _default = Ember.Helper.helper(chargeCalculator);

  _exports.default = _default;
});