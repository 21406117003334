define("securitytrax-ember-ui/components/multi-step-form/forms/system/affiliated-monitoring/tests/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _switchableFormStep, _emberConcurrency, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    centralStationService: Ember.inject.service(),
    addTestFormStarted: false,
    minutes: undefined,
    hours: undefined,
    comment: undefined,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        minutes: 0,
        hours: 2,
        comment: null
      });
    },

    disabledProgressBarSteps: Ember.computed(function () {
      return [];
    }),
    hiddenProgressBarSteps: Ember.computed(function () {
      return [];
    }),
    customerTests: Ember.computed(function () {
      return this.getCustomerTests.perform();
    }),
    getCustomerTests: (0, _emberConcurrency.task)(function* () {
      var tests = yield this.centralStationService.getData({
        model: this.customer,
        method: 'affiliated_monitoring__getTests'
      });
      return tests.tests;
    }),
    addTestButton: Ember.computed(function () {
      var buttons = [{
        text: this.intl.t('common.actions.save'),
        classNamesString: 'st-btn st-btn-primary save-test',
        click: buttonPromise => {
          return this.addTest.perform(buttonPromise);
        }
      }];
      return buttons;
    }),
    minutesDropdownOptions: Ember.computed(function () {
      var minutes = [];

      for (var i = 0; i <= 59; i++) {
        minutes.push({
          id: i,
          value: i
        });
      }

      return minutes;
    }),
    hoursDropdownOptions: Ember.computed(function () {
      var hours = [];

      for (var i = 0; i <= 48; i++) {
        hours.push({
          id: i,
          value: i
        });
      }

      return hours;
    }),
    timeToMinutes: Ember.computed('hours', 'minutes', function () {
      var timeToMinutes = this.hours * 60 + this.minutes;
      return timeToMinutes.toString();
    }),
    addTest: (0, _emberConcurrency.task)(function* (buttonPromise) {
      try {
        yield this.centralStationService.postData({
          model: this.customer,
          method: 'affiliated_monitoring__createTest',
          payload: {
            minutes: this.timeToMinutes,
            comment: this.comment
          }
        });
        buttonPromise.then(() => {
          this.actions.resetDropdowns.call(this);
          this.notifyPropertyChange('customerTests');
          this.set('addTestFormStarted', false);
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'addTestModal'
        });
        throw err;
      }
    }),
    deleteTest: (0, _emberConcurrency.task)(function* (row, buttonPromise) {
      try {
        yield this.centralStationService.postData({
          model: this.customer,
          method: 'affiliated_monitoring__deleteTests',
          payload: {
            tests: [{
              seq_no: row.seq_no.toString()
            }]
          }
        });
        buttonPromise.then(() => {
          this.stNotificationManager.addSuccessKey('common.notifications.delete_successful', 'modal');
          this.notifyPropertyChange('customerTests');
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
        throw err;
      }
    }),
    actions: {
      resetDropdowns() {
        Ember.setProperties(this, {
          minutes: 0,
          hours: 2,
          comment: null
        });
      }

    }
  });

  _exports.default = _default;
});