define("securitytrax-ember-ui/components/homepage/dashboard/at-risk-entries-for-today/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var AtRiskEntriesForToday = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('events/home-dashboard'), _dec4 = (0, _emberConcurrency.task)(function* () {
    var url = yield this.stAjax.getUrl('/dashboard_cards/at_risk_entries_for_today');
    var focus = typeof this.args.config.card_focus === 'object' ? this.args.config.card_focus.value : this.args.config.card_focus;
    return (this.args.useDefaultData ? this.defaultData : yield this.stAjax.request(url, {
      method: 'GET',
      data: {
        focus_type: focus,
        start_day: this.args.config.date_range ? this.args.config.date_range.start_day : '***today***',
        end_day: this.args.config.date_range ? this.args.config.date_range.end_day : '***today***'
      }
    })).data;
  }), _dec5 = (0, _emberConcurrency.task)(function* () {
    var url = yield this.stAjax.getUrl('/dashboard_cards/recent_at_risk_entries');
    var focus = typeof this.args.config.card_focus === 'object' ? this.args.config.card_focus.value : this.args.config.card_focus;
    return (this.args.useDefaultData ? this.defaultData : yield this.stAjax.request(url, {
      method: 'GET',
      data: {
        focus_type: focus
      }
    })).data;
  }), (_class = (_temp = class AtRiskEntriesForToday extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "stAjax", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "homeDashboard", _descriptor3, this);

      _initializerDefineProperty(this, "todaysAtRiskEntriesTask", _descriptor4, this);

      _initializerDefineProperty(this, "recentAtRiskEntriesTask", _descriptor5, this);

      this.homeDashboard.on('refresh-at-risk-entries', this, '_eventCallback');
    }

    willDestroy() {
      this.homeDashboard.off('refresh-at-risk-entries', this, '_eventCallback');
    }

    _eventCallback() {
      Ember.notifyPropertyChange(this, 'atRiskEntries');
    }

    get entriesToUse() {
      return this.args.config.entriesToUse;
    }

    get emptyListText() {
      return this.entriesToUse === 'today' ? this.intl.t('pages.home.no_at_risks_today') : this.entriesToUse === 'recent' ? this.intl.t('pages.home.no_assigned_at_risk_items') : '';
    }

    get defaultData() {
      return {
        data: [{
          'id': '1',
          'customer_at_risk_reason': 'Too Expensive',
          'customer_name': 'Mollie Robins',
          'last_contact': '3',
          'next_contact': 'Today'
        }, {
          'id': '2',
          'customer_at_risk_reason': 'Does Not Use System',
          'customer_name': 'Monserrat Schuppe',
          'last_contact': '14',
          'next_contact': 'Today'
        }, {
          'id': '3',
          'customer_at_risk_reason': 'Wants To Cancel',
          'customer_name': 'Emily Spencer',
          'last_contact': '1',
          'next_contact': 'Today'
        }]
      };
    }

    get atRiskEntries() {
      if (!this.entriesToUse) {
        throw new Error('You must specify what at risk entries you would like to see.');
      }

      if (this.entriesToUse !== 'today' && this.entriesToUse !== 'recent') {
        throw new Error('Entries to use must be one of \'today\' or \'recent\'.');
      }

      return this.entriesToUse === 'today' ? this.todaysAtRiskEntries : this.recentAtRiskEntries;
    }

    get todaysAtRiskEntries() {
      return this.todaysAtRiskEntriesTask.perform();
    }

    get recentAtRiskEntries() {
      return this.recentAtRiskEntriesTask.perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "stAjax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "homeDashboard", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "todaysAtRiskEntriesTask", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "recentAtRiskEntriesTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AtRiskEntriesForToday;
});