define("securitytrax-ember-ui/components/multi-step-form/forms/work-orders/send-work-order/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/work-orders/work-orders-base", "ember-concurrency"], function (_exports, _workOrdersBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var SendWorkOrder = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = (0, _emberConcurrency.task)(function* () {
    var options = [];
    var calendarEvents = this.multiStepForm.formOriginalModelsGetVal('workOrder.model.calendarEvents');
    var promiseArray = [];
    calendarEvents.forEach(calendarEvent => {
      promiseArray.push(this.addOptionTask.perform(calendarEvent, options));
    });
    yield (0, _emberConcurrency.all)(promiseArray);

    if (calendarEvents.length == 1) {
      this.selectedEvent = calendarEvents.objectAt(0).id;
      this.currentCalendarEvent = calendarEvents.objectAt(0);
    }

    return options;
  }), _dec5 = (0, _emberConcurrency.task)(function* (calendarEvent, optionsArray) {
    var name = yield calendarEvent.appointmentDropdownFormatTask.perform();
    optionsArray.push({
      name,
      id: calendarEvent.id
    });
  }), _dec6 = Ember._action, (_class = (_temp = class SendWorkOrder extends _workOrdersBase.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "selectedEvent", _descriptor2, this);

      _initializerDefineProperty(this, "currentCalendarEvent", _descriptor3, this);

      _initializerDefineProperty(this, "appointmentOptionsTask", _descriptor4, this);

      _initializerDefineProperty(this, "addOptionTask", _descriptor5, this);
    }

    get appointmentOptions() {
      return this.appointmentOptionsTask.perform();
    }

    get hiddenProgressBarSteps() {
      return [];
    }

    get disabledProgressBarSteps() {
      return [];
    }

    get extraValidationData() {
      return {
        appointment: true
      };
    }

    get validationModelData() {
      return {
        appointment: this.selectedEvent
      };
    }

    get sendData() {
      return {
        calendarEventId: this.selectedEvent
      };
    }

    updateSelectedAppointment() {
      var calendarEvents = this.multiStepForm.formOriginalModelsGetVal('workOrder.model.calendarEvents');
      this.currentCalendarEvent = calendarEvents.findBy('id', this.selectedEvent);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selectedEvent", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentCalendarEvent", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "appointmentOptionsTask", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "addOptionTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateSelectedAppointment", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "updateSelectedAppointment"), _class.prototype)), _class));
  _exports.default = SendWorkOrder;
});