define("securitytrax-ember-ui/services/scroller", ["exports", "jquery", "ember-scroll-to/services/scroller"], function (_exports, _jquery, _scroller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _scroller.default.extend({
    scrollable: Ember.computed(function () {
      //if a modal is present, use the modal container for scroll positioning
      if ((0, _jquery.default)('.md-dialog-container')[0]) {
        if ((0, _jquery.default)('.md-dialog-container .form-content')[0]) {
          return (0, _jquery.default)('.form-content');
        } else {
          return (0, _jquery.default)('.md-dialog-container');
        }
      } else {
        return (0, _jquery.default)('html, body');
      }
    })
  });

  _exports.default = _default;
});