define("securitytrax-ember-ui/components/multi-step-form/forms/work-orders/add-duplicate-appointment/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/work-orders/work-orders-base", "securitytrax-ember-ui/mixins/switchable-form-step"], function (_exports, _workOrdersBase, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _workOrdersBase.default.extend(_switchableFormStep.default, {
    didInsertElement() {
      this._super(...arguments);

      if (this.needsDuplicateCancelButton) {
        this.multiStepForm.registerStepData('calendarEventSingleStep::duplicateCalendarEventWithScheduler', 'buttons', [{
          text: this.intl.t('common.actions.cancel'),
          classNamesString: 'st-btn st-btn-secondary',
          click: () => {
            this.multiStepForm.currentStepNameKeySetVal('calendarEventSingleStep::editCalendarEvent');
          }
        }]);
      }
    },

    hiddenProgressBarSteps: Ember.computed(function () {
      return ['getStarted'];
    }),
    disabledProgressBarSteps: Ember.computed(function () {
      return [];
    }),
    actions: {
      afterScheduleAppointment() {
        this.multiStepForm.formStartedSetVal(false);
      }

    }
  });

  _exports.default = _default;
});