define("securitytrax-ember-ui/administration/company-settings/system/form-defaults/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _authenticatedRouteMixin, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _routePageviewLogging.default, {
    intl: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform();
    },

    setupController(controller) {
      this._super(...arguments);

      var breadCrumbs = [{
        name: this.intl.t('pages.administration.company_settings.global_settings.form_defaults.title'),
        last: true,
        previousLink: 'administration.company-settings',
        link: 'administration.company-settings.system.form-defaults'
      }];
      var breadCrumbData = {
        title: this.intl.t('pages.administration.company_settings.title'),
        titleLink: 'administration.company-settings'
      };
      controller.set('breadCrumbs', breadCrumbs);
      controller.set('breadCrumbData', breadCrumbData);
    }

  });

  _exports.default = _default;
});