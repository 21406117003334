define("securitytrax-ember-ui/components/multi-step-form/forms/user-actions/delete-user/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/switchable-form-step"], function (_exports, _emberConcurrency, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    orgTree: Ember.inject.service(),
    deleteComplete: true,
    resultsLoaded: true,

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('modalButtons', [{
        text: this.intl.t('common.actions.delete'),
        click: buttonPromise => {
          return this.deleteUserTask.perform(buttonPromise);
        },
        classNamesString: 'st-btn st-btn-warn st-modal__button--delete'
      }]);
    },

    deleteUserTask: (0, _emberConcurrency.task)(function* (buttonPromise) {
      try {
        this.set('deleteComplete', false);
        var modalParentUser = yield this.modalParentUser;
        var modalParentUserParentId = modalParentUser.get('directoryUserParent.id');
        modalParentUser.destroyRecordWithRollbackTask.perform(); //notify parent list that it has new users

        /*   this.set('newGroupUsers', newUsers); */

        buttonPromise.then(() => {
          this.set('addUsersFormStarted', false);
          this.set('deleteComplete', true);
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
          this.changeRouteTask.perform(modalParentUserParentId);
        });
      } catch (err) {
        this.set('addUsersFormStarted', false);
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
        (false && Ember.warn('Org Chart delete user error' + err, {
          id: 'multiStepForm.forms.user-actions.deleteUser.deleteUserTask'
        }));
        throw err;
      }
    }),
    changeRouteTask: (0, _emberConcurrency.task)(function* (newId) {
      var userHierarchyTree = yield this.get('orgTree.userHierarchyTree');

      if (newId) {
        this.router.transitionTo('/administration/org-chart/' + userHierarchyTree.get('id') + '/user/' + newId);
      } else {
        this.router.transitionTo('/administration/org-chart/' + userHierarchyTree.get('id'));
      }
    })
  });

  _exports.default = _default;
});