define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/order/component", ["exports", "securitytrax-ember-ui/mixins/apply-order-template", "lodash", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/customer-base", "securitytrax-ember-ui/mixins/set-user", "securitytrax-ember-ui/classes/user-defined-fields"], function (_exports, _applyOrderTemplate, _lodash, _emberConcurrency, _customerBase, _setUser, _userDefinedFields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerBase.default.extend(_applyOrderTemplate.default, _setUser.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    userService: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    leadCompanyId: Ember.computed('multiStepForm.formCopiedModels.customer.model.leadSourceInformation.leadCompany', function () {
      return _lodash.default.get(this.multiStepForm, 'formCopiedModels.customer.model.leadSourceInformation.leadCompany.id', null);
    }),
    leadCompanies: Ember.computed(function () {
      return this.leadCompaniesTask.perform();
    }),
    leadCompaniesTask: (0, _emberConcurrency.task)(function* () {
      return this.grants.getIndividualGrant('lead_companies.view') ? yield this.store.findAll('leadCompany') : null;
    }),
    //filter campaigns based on lead company
    leadCompanyCampaigns: Ember.computed('leadCompanyId', function () {
      return this.leadCompanyCampaignsTask.perform();
    }),
    leadCompanyCampaignsTask: (0, _emberConcurrency.task)(function* () {
      if (this.leadCompanyId > 0) {
        var leadCompanies = yield this.leadCompanies;
        var leadCompany = leadCompanies.findBy('id', this.leadCompanyId);
        return yield leadCompany.get('leadCompanyCampaigns');
      }

      return [];
    }),
    terms: Ember.computed(function () {
      return this.termsTask.perform();
    }),
    termsTask: (0, _emberConcurrency.task)(function* () {
      return yield this.store.findAll('contractTerm');
    }),
    createRecordOptionsMonths: Ember.computed(function () {
      return {
        months: 0,
        active: true
      };
    }),
    selectedTemplateId: null,
    selectedTemplatePackageId: null,
    selectedTerm: null,
    //hide fields if empty
    sourceCampaignDisabled: Ember.computed.empty('leadCompanyId'),
    templateChoices: Ember.computed(function () {
      return this.initFormData.perform();
    }),
    initFormData: (0, _emberConcurrency.task)(function* () {
      var udfs = _lodash.default.get(this.workflowConfig, "udfFields.".concat(this.multiStepForm.currentStepNameKey, ".udfs"), []);

      this.UdfClass = new _userDefinedFields.default({
        store: this.store,
        intl: this.intl,
        udfIDs: udfs,
        model: this.multiStepForm.formOriginalModelsGetVal('customer.model')
      });
      var hashedData = yield (0, _emberConcurrency.hash)({
        loggedInUser: this.sessionData.getUser(),
        udfTask: this.UdfClass.udfListTask.perform(),
        waitFinishCopyModels: (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true)
      });
      var customer = this.multiStepForm.formCopiedModelsGetVal('customer.model');
      var hashData = yield (0, _emberConcurrency.hash)({
        orderTemplates: this.store.findAll('orderTemplate', {
          include: 'default_packages.package_equipment,default_mmrs,default_monitoring_plan,default_monitoring_company,default_customer_warranty,default_contract_term,default_interactive_services_provider,default_activation_fee'
        }),
        leadCompanies: this.leadCompanies,
        activateFees: this.store.findAll('activationFee'),
        contractTerms: this.store.findAll('contractTerm'),
        monitoringPlans: this.store.query('monitoringPlan', {
          filter: 'company_config=true',
          limit: 0
        }),
        customerWarranties: this.store.findAll('customerWarranty'),
        interactiveServicesProviders: this.store.findAll('interactiveServicesProvider'),
        packages: this.store.findAll('package'),
        equipment: this.store.findAll('equipment'),
        equipmentItems: this.store.findAll('equipmentItem'),
        allSalesReps: this.userService.getUsersList('sales_rep', customer),
        loggedInUserIsSalesRep: hashedData.loggedInUser.get('isSalesRep')
      });

      if (this.multiStepForm.formCopiedModelsGetVal('customer.model.customerOrderInformation.repUser') != null) {
        Ember.set(this, 'salesRep', this.multiStepForm.formCopiedModelsGetVal('customer.model.customerOrderInformation.repUser'));
        Ember.set(this, 'equipmentSalesPerson', this.multiStepForm.formCopiedModelsGetVal('customer.model.customerOrderInformation.repUser'));
      } else if (hashData.loggedInUserIsSalesRep) {
        Ember.set(this, 'salesRep', hashedData.loggedInUser);
        Ember.set(this, 'equipmentSalesPerson', hashedData.loggedInUser);
      }

      Ember.setProperties(this, hashData);
      Ember.set(this, 'newLineItems', Ember.A([]));
      this.newLineItems.pushObject(Ember.Object.create({
        quantity: 1,
        itemCharge: 0
      }));
      return yield this.orderTemplateChoices;
    }),

    didInsertElement() {
      this._super(...arguments);

      this.setupStep3Buttons();
    },

    setupStep3Buttons: function () {
      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('step3', 'buttons', [{
        text: this.intl.t('common.actions.back'),
        classNamesString: 'st-btn st-btn-secondary',
        click: () => {
          multiStepForm.currentStepNameKeySetVal('step2');
          multiStepForm.progressBarConfig.activeIndex = 1;
        }
      }, {
        text: this.intl.t('common.actions.saveAndContinue'),
        classNamesString: 'st-btn st-btn-primary customer-information-save-button',
        positionOptions: 'up left',
        click: buttonPromise => {
          return this.applyOrderTemplateTask.perform(multiStepForm, buttonPromise);
        },
        additionalClickActions: [{
          label: this.intl.t('common.actions.deleteCustomer'),
          action: () => {
            this.openConfirmDelete('step3');
          },
          classNamesString: 'dropdown-delete-customer'
        }, {
          label: this.intl.t('common.actions.saveAndClose'),
          action: buttonPromise => {
            return this.applyOrderTemplateTask.perform(multiStepForm, buttonPromise, true);
          },
          classNamesString: 'dropdown-save-close'
        }]
      }]);
    },
    applyOrderTemplateTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise, close = false) {
      var formattedObjects = Ember.A();
      var noValidate = ['customer-zip', 'customer-address1', 'orderInformation-salesRep'];
      var additionalEquipment = [];
      noValidate.push('orderInformation-customerCancelDate', 'leadSourceInformation-leadCompany');
      multiStepForm.formCopiedModelsSetVal('customer.model.customerOrderInformation.customerCancelDate', null);
      multiStepForm.formCopiedModelsSetVal('customer.model.customerOrderInformation.repUser', this.salesRep);
      multiStepForm.formCopiedModelsSetVal('customer.model.customerOrderInformation.contractTerm', this.selectedTerm);
      yield multiStepForm.validateSyncSaveAll({
        extraValidationData: {
          noValidate: noValidate
        }
      });

      if (!this.showMmrSelectList) {
        noValidate.push('orderTemplate-mmr');
      }

      if (!this.selectedTemplatePackageId && this.newLineItems.length === 1) {
        noValidate.push('orderTemplate-soldBy');
      }

      formattedObjects.pushObject(Ember.Object.create({
        model: {
          mmr: this.get('selectedMmr.id'),
          soldBy: this.equipmentSalesPerson
        },
        extraValidationData: {
          noValidate: noValidate
        },
        filePath: 'order-templates/apply-order-template'
      }));

      if (this.UdfClass) {
        this.UdfClass.validateUdfs(formattedObjects);
      }

      yield multiStepForm.validateList.perform(formattedObjects);

      try {
        if (this.newLineItems.length > 1) {
          var formattedEquipmentObjects = Ember.A();
          this.newLineItems.pop();

          if (this.equipmentSalesPerson && this.equipmentSalesPerson.id !== null) {
            yield this.setUser.perform(this, 'salesPerson', this.equipmentSalesPerson.id);
          }

          this.newLineItems.forEach((item, index) => {
            if (this.salesPerson !== null) {
              Ember.set(item, 'user', this.salesPerson);
            }

            additionalEquipment.push({
              id: item.get('equipment.id'),
              type: 'equipment',
              item_charge: item.get('itemCharge'),
              quantity: item.get('quantity') // wiretype needed?

            });
            delete item['id'];
            formattedEquipmentObjects.pushObject(Ember.Object.create({
              modelName: 'customerEquipment',
              model: item,
              extraValidationData: {
                noValidate: ['workOrderAction', 'equipmentStatus', 'zoneNumber', 'zone'],
                overrideValidationKey: index + '-customerEquipmentLineItem'
              }
            }));
          });
          this.newLineItems.pushObject(Ember.Object.create({
            quantity: 1,
            itemCharge: 0
          }));

          if (this.selectedTemplateId) {
            yield multiStepForm.validateList.perform(formattedEquipmentObjects, {
              notificationNamespace: 'modal'
            });
          } else {
            var equipmentToCreate = yield multiStepForm.validateCreateList.perform(formattedEquipmentObjects, {
              notificationNamespace: 'modal'
            });
            var saveArray = Ember.A();
            var equipmentStatus = yield this.store.findRecord('equipmentStatus', 1);
            equipmentToCreate['customerEquipment'].forEach(customerEquipment => {
              for (var i = 0; i < customerEquipment.data.quantity; i++) {
                var newCustomerEquipment = this.store.createRecord('customerEquipment', customerEquipment.data);
                Ember.set(newCustomerEquipment, 'customer', this.newCustomer);
                Ember.set(newCustomerEquipment, 'quantity', 1);
                Ember.set(newCustomerEquipment, 'equipmentStatus', equipmentStatus);
                saveArray.pushObject(customerEquipment.saveWithRollbackAndNotification(newCustomerEquipment));
              }
            });
            yield (0, _emberConcurrency.all)(saveArray);
          }
        }

        if (this.selectedTemplateId) {
          yield this.applyOrderTemplateMixinTask.perform(multiStepForm.formOriginalModelsGetVal('customer.model.customerOrderInformation.id'), additionalEquipment);
        }

        if (this.UdfClass) {
          yield this.UdfClass.saveUdfsTask.perform(this.newCustomer);
        }
      } catch (err) {
        this.stNotificationManager.addError(this.intl.t('common.notifications.save_failed'), 'createCustomerModal');
        throw new Error(err);
      }

      var customerEquipment = yield multiStepForm.formOriginalModelsGetVal('customer.model.customerEquipment');
      yield customerEquipment.reload();
      buttonPromise.then(() => {
        multiStepForm.formOriginalModelsGetVal('customer.model.invoices').then(invoices => {
          invoices.reload();
        });
        multiStepForm.formOriginalModelsGetVal('customer.model.quotes').then(quotes => {
          quotes.reload();
        });
        multiStepForm.formOriginalModelsGetVal('customer.model.customerOrderInformation').then(orderInfoModel => {
          orderInfoModel.reload();
        });
      });

      if (close) {
        Ember.set(this, 'formMode', 'create');
        this.closeForm(multiStepForm, multiStepForm.formOriginalModelsGetVal('customer.model'));
      } else if (this.isASG || this.isLS) {
        this.advanceForm(multiStepForm, 'step4', 3);
      } else {
        this.advanceForm(multiStepForm, 'paymentMethod', 3);
      }
    }),
    actions: {
      newModelCreated(modelName, value) {
        if (modelName === 'contractTerm') {
          notifyPropertyChange(this, 'terms');
          Ember.set(this, 'selectedTerm', value);
        }
      }

    }
  });

  _exports.default = _default;
});