define("securitytrax-ember-ui/components/customer/dashboard/payables-summary-card/status-legend/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y5yif30m",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[30,[36,4],[[35,2],0],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"cell small-shrink grid-x align-middle status-legend\"],[12],[2,\"\\n        \"],[10,\"div\"],[15,0,[30,[36,1],[\"cell shrink legend-color \",[35,0]],null]],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cell shrink count\"],[12],[2,\"\\n            \"],[1,[34,2]],[2,\" \"],[1,[34,3]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"statusColor\",\"concat\",\"status_count\",\"status\",\"gt\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/customer/dashboard/payables-summary-card/status-legend/template.hbs"
    }
  });

  _exports.default = _default;
});