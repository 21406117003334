define("securitytrax-ember-ui/models/report-object", ["exports", "securitytrax-ember-ui/models/base_models/report-object", "lodash"], function (_exports, _reportObject, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _reportObject.default.extend({
    grants: Ember.inject.service(),
    reportObjectHasGrant: function (originalGrant) {
      var originalGrantSplit = originalGrant.split('.');

      if (originalGrantSplit[1] === 'view') {
        throw '!!there is a problem in reportObjectHasGrant 1!!';
      }

      var grantToCheck;

      switch (originalGrantSplit[0]) {
        case 'reports':
          grantToCheck = _lodash.default.get(this, 'responseMeta.resource_type', false);
          break;

        case 'run_reports':
          grantToCheck = _lodash.default.get(this, 'responseMeta.run_resource_type', false);
          break;

        case 'edit_data_reports':
          grantToCheck = _lodash.default.get(this, 'responseMeta.edit_resource_type', false);
          break;

        default:
          // means a bad grant was passed in
          throw '!!there is a problem in reportObjectHasGrant 2!!';
      }

      if (grantToCheck === false) {
        return true;
      }

      var fullGrantToCheck = [grantToCheck, originalGrantSplit[1]].join('.');
      return this.grants.getIndividualGrant(fullGrantToCheck);
    }
  });

  _exports.default = _default;
});