define("securitytrax-ember-ui/components/multi-step-form/forms/demo/demo-paper-wizard/step-3/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/demo/demo-paper-wizard/demo-paper-wizard-base"], function (_exports, _demoPaperWizardBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _demoPaperWizardBase.default.extend({
    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('step3', 'buttons', [{
        text: 'Prev',
        click: function () {
          multiStepForm.currentStepNameKeySetVal('step2');
        },
        classNamesString: 'st-btn btn-secondary'
      }, {
        text: 'Save & Next',
        click: function () {
          multiStepForm.syncCopiedToOriginalModelByKeyName.perform('customer');
          multiStepForm.syncCopiedToOriginalModelByKeyName.perform('equipment');
          multiStepForm.deepSaveOriginalModelByKeyName.perform('customer');
          multiStepForm.deepSaveOriginalModelByKeyName.perform('equipment');
          multiStepForm.currentStepNameKeySetVal('step4');
        },
        classNamesString: 'st-btn st-btn-primary'
      }]);
    }

  });

  _exports.default = _default;
});