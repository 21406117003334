define("securitytrax-ember-ui/components/lists/sortable-column/mobile/component", ["exports", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    screen: Ember.inject.service(),
    options: undefined,
    selected: undefined,
    isOpen: undefined,
    horizontalPosition: undefined,
    renderInPlace: false,
    classNames: ['sorting-grouping-div'],

    init() {
      this._super(...arguments);

      if (typeof this.updateMobileListAction === 'undefined') {
        throw new Error('You must pass in the update Mobile List Action');
      }

      (0, _objHelpers.initializeState)(this, {
        updateMobileListAction: () => {},
        isOpen: false,
        horizontalPosition: 'right'
      });

      if (typeof this.options === 'undefined') {
        throw new Error('You must pass in some options to sort the list by, or remove the mobile list {name, value, asc}');
      }
    },

    actions: {
      sortTheList(newVal) {
        this.updateMobileListAction(newVal);
      }

    }
  });

  _exports.default = _default;
});