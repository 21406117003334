define("securitytrax-ember-ui/validations/test-copy-has-many", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate() {
      var validationData = (0, _validate.default)(this.objectToJson(this), {
        city: {
          presence: {
            allowEmpty: false
          }
        }
      });
      return this.outputFormatting(validationData, 'test-copy-has-many');
    }

  });

  _exports.default = _default;
});