define("securitytrax-ember-ui/mixed-in-functions/duration-options", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = durationOptions;

  function durationOptions(startTime, asString = false) {
    var durations = [];

    var halfHour = _moment.default.duration(30, 'minutes');

    var endOfDay = (0, _moment.default)(startTime).set({
      'hour': 23,
      'minute': 30,
      'second': 0
    });
    var difAppointmentStartEOD = Math.abs(_moment.default.duration((0, _moment.default)(startTime).diff(endOfDay)).asMinutes());
    var numIterations = Math.floor(difAppointmentStartEOD / 30);

    for (var i = 0; i < numIterations; i++) {
      var hour = halfHour.hours();
      var minutes = halfHour.minutes();
      durations.push(Ember.Object.create({
        name: "".concat(hour, " hour ").concat(minutes, " minutes"),
        value: asString ? String(Number.parseInt(halfHour.as('seconds'))) : Number.parseInt(halfHour.as('seconds'))
      }));
      halfHour.add(30, 'minutes');
    }

    return durations;
  }
});