define("securitytrax-ember-ui/classes/equipment-sold-by-options", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EquipmentSoldByOptions = (_dec = (0, _emberConcurrency.task)(function* () {
    var reformatted = [];
    var reps = yield this.customer.invokeAction('get_sorted_special_sold_by_list', {
      verb: 'POST',
      data: {
        data: {}
      }
    });
    reps.responsePayload.data.forEach(rep => {
      var specialUsersTranslation = '';

      if (rep.attributes.relations_to_customer) {
        rep.attributes.relations_to_customer.forEach((relation, index) => {
          if (index === 0) {
            specialUsersTranslation += ' (';
          }

          specialUsersTranslation += this.intl.t("pages.customers.equipment.rep_options.".concat(relation));

          if (index === rep.attributes.relations_to_customer.length - 1) {
            specialUsersTranslation += ')';
          } else {
            specialUsersTranslation += ', ';
          }
        });
      }

      reformatted.push({
        id: rep.id,
        name: rep.attributes.lname + ', ' + rep.attributes.fname + specialUsersTranslation
      });
    });
    return reformatted;
  }), (_class = (_temp = class EquipmentSoldByOptions {
    constructor(args) {
      _initializerDefineProperty(this, "soldByOptionsTask", _descriptor, this);

      Ember.setProperties(this, args);
    }

    get soldByOptions() {
      return this.soldByOptionsTask.unlinked().perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "soldByOptionsTask", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = EquipmentSoldByOptions;
});