define("securitytrax-ember-ui/components/nav/top-nav/nav/component", ["exports", "jquery", "ember-concurrency", "securitytrax-ember-ui/helpers/feature-is-enabled", "securitytrax-ember-ui/mixins/user-notifications-mixin"], function (_exports, _jquery, _emberConcurrency, _featureIsEnabled, _userNotificationsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_userNotificationsMixin.default, {
    dynamicFormChooser: Ember.inject.service('events/dynamic-form-chooser'),
    grants: Ember.inject.service(),
    profile: Ember.inject.service(),
    screen: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    store: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    intl: Ember.inject.service(),
    isEmulatedUser: undefined,
    rootURL: undefined,
    calculatedBaseURL: undefined,
    hideSearch: true,
    hideCreate: true,
    expandNav: false,
    hideUser: true,
    hideNotifications: true,
    mainExpanded: false,
    dynamicCreateStarted: false,
    isSuperUser: false,

    init() {
      this._super(...arguments);

      this.setPropertiesFromSession();
      var {
        rootURL
      } = Ember.getOwner(this).lookup('router:main');
      var calculatedBaseURL = "".concat(window.location.protocol, "//").concat(window.location.hostname);

      if (window.location.port != '80') {
        calculatedBaseURL += ":".concat(window.location.port);
      }

      calculatedBaseURL += rootURL;
      this.set('calculatedBaseURL', calculatedBaseURL);
    },

    showGeneralNav: Ember.computed(function () {
      return (0, _featureIsEnabled.featureIsEnabled)('orgChart');
    }),
    showCompanyNav: Ember.computed('isSuperUser', 'grants.userGrants', function () {
      var grants = this.grants;

      if (grants.hasAtLeastOneReportsRunGrant() && (0, _featureIsEnabled.featureIsEnabled)('reportsTab') || grants.getIndividualGrant('customer_tasks.view') && (0, _featureIsEnabled.featureIsEnabled)('allTasksTab') || grants.getIndividualGrant('calendar_events.view') && (0, _featureIsEnabled.featureIsEnabled)('calendarTab') || (0, _featureIsEnabled.featureIsEnabled)('inventory') || (0, _featureIsEnabled.featureIsEnabled)('companyAccounting') && this.isSuperUser) {
        return true;
      }

      return false;
    }),
    showAdminNav: Ember.computed('canViewCompanySettings', 'canViewHumanResources', function () {
      return this.canViewCompanySettings || this.canViewHumanResources;
    }),
    // :( TODO: this hack is used to determine if we need to set height for flex-y and also if we need to grow column width
    // if you add links to company nav, will need to update this.
    companyNavNumItems: Ember.computed('isSuperUser', 'grants.userGrants', function () {
      return this.companyNavNumItemsTask.perform();
    }),
    companyNavNumItemsTask: (0, _emberConcurrency.task)(function* () {
      var navItemsUserCanSee = 0; //number that all users can view by default

      var grants = this.grants;

      if ((yield grants.hasAtLeastOneReportsRunGrantAsync.perform()) && (0, _featureIsEnabled.featureIsEnabled)('reportsTab')) {
        navItemsUserCanSee++;
      }

      if ((yield grants.get('getIndividualGrantAsync').perform('customer_tasks.view')) && (0, _featureIsEnabled.featureIsEnabled)('allTasksTab')) {
        navItemsUserCanSee++;
      }

      if ((yield grants.get('getIndividualGrantAsync').perform('calendar_events.view')) && (0, _featureIsEnabled.featureIsEnabled)('calendarTab')) {
        navItemsUserCanSee++;
      }

      if (yield grants.get('getIndividualGrantAsync').perform('work_orders.view')) {
        navItemsUserCanSee++;
      }

      if (yield grants.get('getIndividualGrantAsync').perform('customer_at_risks.view')) {
        navItemsUserCanSee++;
      }

      if ((0, _featureIsEnabled.featureIsEnabled)('inventory')) {
        navItemsUserCanSee++;
      } //TODO this eventually won't be securitytrax super user


      if ((0, _featureIsEnabled.featureIsEnabled)('companyAccounting') && this.isSuperUser) {
        navItemsUserCanSee++;
      }

      return navItemsUserCanSee;
    }).restartable(),
    // TODO: will nav ever grow past 4 columns?
    subNavsColumnClass: Ember.computed('showGeneralNav', 'showCompanyNav', 'showAdminNav', 'companyNavNumItems', function () {
      return this.subNavsColumnClassTask.perform();
    }),
    subNavsColumnClassTask: (0, _emberConcurrency.task)(function* () {
      var numColumns = 0;
      var columnClass = '';
      var companyNavNumItems = yield this.companyNavNumItems;

      if (this.showGeneralNav) {
        numColumns++;
      }

      if (this.showCompanyNav) {
        numColumns++;

        if (companyNavNumItems > 4) {
          numColumns++;
        }
      }

      if (this.showAdminNav) {
        numColumns++;
      }

      switch (numColumns) {
        case 1:
          columnClass = 'one-column';
          break;

        case 2:
          columnClass = 'two-columns';
          break;

        case 3:
          columnClass = 'three-columns';
          break;

        case 4:
          columnClass = 'four-columns';
          break;
      }

      return columnClass;
    }).restartable(),
    companyInfo: Ember.computed(function () {
      return this.sessionData.getPublicCompanyInfo();
    }),
    stUserCheckTask: (0, _emberConcurrency.task)(function* () {
      this.set('isSuperUser', yield this.sessionData.isSuperUser);
    }),
    openDynamicFormChooserTask: (0, _emberConcurrency.task)(function* (workflowType) {
      var workFlows = yield this.store.findAll('workflowConfig', {
        include: 'workflow'
      });
      var activeWorkFlows = workFlows.filter(workFlow => {
        return workFlow.get('active') && workFlow.get('workflow.workflowType') === workflowType;
      });

      if (activeWorkFlows.length === 0) {
        this.stNotificationManager.addWarning(this.intl.t('common.nav.create.no_active_workflows_available'));
        return;
      } //skip dynamic form chooser if only one workflow is active


      if (activeWorkFlows.length === 1) {
        this.dynamicFormChooser.trigger('open-dynamic-form', {
          id: activeWorkFlows[0].get('workflow.id').dasherize()
        });
      } else {
        this.dynamicFormChooser.trigger('open-dynamic-form-chooser', workflowType);
      }
    }),

    setPropertiesFromSession() {
      Ember.RSVP.resolve(this.get('sessionData.isEmulatedUser')).then(emulated => {
        this.set('isEmulatedUser', emulated);
      });
      this.stUserCheckTask.perform();
    },

    resetMenus() {
      this.set('expandNav', false);
    },

    closeNavs() {
      this.set('expandNav', false);
      this.set('mainExpanded', false);
      this.set('subExpanded', false);
    },

    actions: {
      openInNewTab(path) {
        var pathReplaceDots = path.replace(/\./g, '/');
        window.open("".concat(this.calculatedBaseURL).concat(pathReplaceDots), '_blank');
      },

      //We are doing the following action to hide the dropdown a link is clicked on.
      //We have to set a timeout to reset the css display value. This way the on hover will work again
      hideNavDropdown(target) {
        (0, _jquery.default)(target).css('display', 'none');
        Ember.run.later(function () {
          //by setting the display to empty, it reverts to it's parent display setting. That allows the on hover to work again
          (0, _jquery.default)(target).css('display', '');
        }, 500);
      },

      changeRoute(route) {
        this.router.transitionTo(route);
      },

      routeToOrgChart(route) {
        this.router.transitionTo(route, {
          'chart_id': 1
        });
      },

      logout() {
        var pathname = window.location.pathname;
        var url = pathname.split('/').slice(2).join('/');
        localStorage.setItem('storedURL', url);
        this.session.invalidate();
      },

      stopEmulation() {
        this.sessionData.getTokenData().then(token => {
          this.get('sessionData.emulateUserTask').perform(token.sub.original_user_id);
        });
      },

      toggleNavigation(menu) {
        if (menu !== 'search') {
          this.set('hideSearch', true);
          this.notifyPropertyChange('hideSearch');
        }

        if (menu !== 'company') {
          this.set('mainExpanded', false);
        }

        if (menu !== 'notifications') {
          this.set('hideNotifications', true);
          this.notifyPropertyChange('hideNotifications');
        }

        if (menu !== 'create') {
          this.set('hideCreate', true);
          this.notifyPropertyChange('hideCreate');
        }

        if (menu !== 'user') {
          this.set('hideUser', true);
          this.notifyPropertyChange('hideUser');
        }

        switch (menu) {
          case 'alarm':
            break;

          case 'notifications':
            this.toggleProperty('hideNotifications');
            break;

          case 'user':
            this.toggleProperty('hideUser');
            break;

          case 'company':
            this.toggleProperty('mainExpanded');
            break;

          case 'search':
            this.toggleProperty('hideSearch');
            break;

          case 'create':
            this.toggleProperty('hideCreate');
            break;

          default:
            this.closeNavs();
            break;
        }
      },

      hideUserNotifications() {
        this.set('hideNotifications', true);
      }

    }
  });

  _exports.default = _default;
});