define("securitytrax-ember-ui/components/multi-step-form/switchable-inputs/checkbox-input/component", ["exports", "securitytrax-ember-ui/mixins/switchable-input", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _switchableInput, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableInput.default, {
    indeterminate: false,
    toggleSwitch: true,
    checkboxOptions: undefined,
    value: false,
    className: undefined,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        checkboxOptions: {},
        className: ''
      });

      if (this.isFilter) {
        var defaultValue = null;

        if (this.defaultValue != null) {
          defaultValue = {
            value: this.defaultValue,
            callback: defaultValue => {
              Ember.run.later(() => {
                Ember.set(this, 'value', defaultValue);

                if (this.onInit) {
                  this.onInit(defaultValue);
                }

                this.updateFilterData();
                this.stFiltersService.setAsLoaded(this.stFilterGroupKey, this.stFilterKey);
              });
            }
          };
        }

        var filterData = this.stFiltersService.registerFilter(this.stFilterGroupKey, this.stFilterKey, ['checkboxValue'], defaultValue);

        if (filterData['checkboxValue']) {
          Ember.run.later(() => {
            Ember.set(this, 'value', filterData['checkboxValue'].fieldValue);
            this.stFiltersService.setAsLoaded(this.stFilterGroupKey, this.stFilterKey);
          });
        } else {
          if (this.onInit) {
            if (this.defaultValue != null) {
              Ember.run.later(() => {
                Ember.set(this, 'value', this.defaultValue);
                this.onInit(this.defaultValue);
                this.updateFilterData();
              });
            } else {
              Ember.run.later(() => {
                this.stFiltersService.setAsLoaded(this.stFilterGroupKey, this.stFilterKey);
              });
            }
          } else {
            Ember.run.later(() => {
              this.stFiltersService.setAsLoaded(this.stFilterGroupKey, this.stFilterKey);
            });
          }
        }
      }
    },

    updateFilterData() {
      if (this.isFilter) {
        if (this.value != null && this.value === true) {
          var filter = "".concat(this.stFilterAttribute, " = ").concat(this.value);
          this.stFiltersService.saveFilter(this.stFilterGroupKey, this.stFilterKey, 'checkboxValue', this.stFiltersService.getActualFilterOrUseCallback(this.stFilterAttribute, this.value, filter), this.value, this.stFilterDoNotCache, this.overrideFilterKey);
        } else {
          this.stFiltersService.clearFilter(this.stFilterGroupKey, this.stFilterKey, 'checkboxValue');
        }
      }
    },

    actions: {
      toggleCheckbox() {
        this.toggleProperty('value');
      },

      changeCheckbox() {
        this.updateFilterData();

        if (this.onChange) {
          this.onChange(this.value);
        }
      }

    }
  });

  _exports.default = _default;
});