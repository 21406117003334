define("securitytrax-ember-ui/customers/history/route", ["exports", "securitytrax-ember-ui/customers/base-route", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _baseRoute, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Customer history route
   *
   * @class Route
   * @mixes AuthenticatedRouteMixin
   * @extends Ember.Route
   * @memberof customers.history
   */
  var _default = _baseRoute.default.extend(_routePageviewLogging.default, {
    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform();
    },

    model() {
      var {
        customer
      } = this._super(...arguments);

      return Ember.RSVP.hash({
        customer
      });
    }

  });

  _exports.default = _default;
});