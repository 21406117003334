define("securitytrax-ember-ui/demo/wysiwyg/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.set('options', {
        menubar: false,
        plugins: 'code textcolor link image',
        toolbar1: 'code | bold italic underline strikethrough | forecolor backcolor | link image',
        toolbar2: 'styleselect fontselect fontsizeselect'
      });
    }

  });
  var _default = Controller;
  _exports.default = _default;
});