define("securitytrax-ember-ui/components/lead/leads-view/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DVXISloU",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[30,[36,8],[\"leads.view_tag\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"lead-tags \",[34,4]]]],[12],[2,\"\\n        \"],[8,\"html/st-tags\",[],[[\"@title\",\"@selectedTags\",\"@parentModel\",\"@tagObjectContext\",\"@isFulfilled\",\"@toggleTagView\",\"@deleteFromName\"],[[30,[36,5],[\"pages.tags.applyLeadTag\"],null],[34,0,[\"lead\",\"tags\"]],[34,0,[\"lead\"]],\"lead\",[34,0,[\"lead\",\"tags\",\"isFulfilled\"]],[30,[36,7],[[32,0],[35,6],\"tagViewCollapsed\"],null],[34,0,[\"lead\",\"flname\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"lead/lead-information\",[],[[\"@model\"],[[34,0]]],null],[2,\"\\n\\n\"],[6,[37,3],[[30,[36,10],[[35,9]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[35,1],\"create\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"lead/lead-central-station\",[],[[\"@lead\"],[[34,0,[\"lead\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,3],[[30,[36,11],[[30,[36,8],[\"lead_information.view\"],null],[30,[36,8],[\"lead_contacts.view\"],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"lead/lead-details\",[],[[\"@model\"],[[34,0]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"formMode\",\"not-eq\",\"if\",\"editLeadFormMode\",\"t\",\"toggleProperty\",\"action\",\"has-grants\",\"isAdtCompany\",\"await\",\"or\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/lead/leads-view/template.hbs"
    }
  });

  _exports.default = _default;
});