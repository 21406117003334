define("securitytrax-ember-ui/components/admin/user-profile/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var userProfile = Ember.Component.extend({});
  userProfile.reopenClass({
    positionalParams: ['user']
  });
  var _default = userProfile;
  _exports.default = _default;
});