define("securitytrax-ember-ui/helpers/de-snake", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deSnake = deSnake;
  _exports.default = void 0;

  function deSnake(input) {
    return _lodash.default.startCase(_lodash.default.replace(input, '_', ' '));
  }

  var _default = Ember.Helper.helper(deSnake);

  _exports.default = _default;
});