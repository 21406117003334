define("securitytrax-ember-ui/administration/company-settings/integrations/payment-processing/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/has-page-title", "securitytrax-ember-ui/mixins/route-pageview-logging", "ember-concurrency"], function (_exports, _authenticatedRouteMixin, _hasPageTitle, _routePageviewLogging, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _hasPageTitle.default, _routePageviewLogging.default, {
    intl: Ember.inject.service(),
    sessionData: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['processing_gateways.view'], 'all', '/administration/company-settings');
    },

    model() {
      return this.setupModelTask.perform();
    },

    setupModelTask: (0, _emberConcurrency.task)(function* () {
      try {
        var processingGateway = yield this.sessionData.getCompanyPaymentGateway();
        return {
          processingGateway: processingGateway || null
        };
      } catch (err) {
        return {
          processingGateway: null
        };
      }
    }),

    setupController(controller) {
      this._super(...arguments);

      var breadCrumbs = [{
        name: this.intl.t('pages.administration.integrations.payment_processing.title'),
        last: true,
        previousLink: 'administration.company-settings',
        link: 'administration.company-settings.integrations.payment-processing'
      }];
      var breadCrumbData = {
        title: this.intl.t('pages.administration.company_settings.title'),
        titleLink: 'administration.company-settings'
      };
      controller.set('breadCrumbs', breadCrumbs);
      controller.set('breadCrumbData', breadCrumbData);
    },

    getPageTitle() {
      return this.intl.t('pages.administration.integrations.payment_processing.title');
    },

    getRouteName() {
      return "pages.administration.nav.company-settings";
    }

  });

  _exports.default = _default;
});