define("securitytrax-ember-ui/services/session-data", ["exports", "ember-concurrency", "securitytrax-ember-ui/utils/debug", "@sentry/browser", "lodash", "securitytrax-ember-ui/config/environment"], function (_exports, _emberConcurrency, _debug, Sentry, _lodash, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    stAjax: Ember.inject.service(),
    profile: Ember.inject.service(),
    intl: Ember.inject.service(),
    stMulticallService: Ember.inject.service(),
    needsToSignTermsAndConditions: false,
    locale: null,
    initialTokenDataTask: (0, _emberConcurrency.task)(function* () {
      var token = yield this.getTokenData();

      if (Object.keys(token).length > 0 && token.sub) {
        this.user_id = token.sub.user_id;
      }

      if (token.fpc == true) {
        this.session.trigger('forcePasswordUpdate');
      }
    }),

    init() {
      this._super(...arguments); //initially set the known user_id for comparison later


      this.initialTokenDataTask.perform();
      this.needsToSignTermsAndConditionsTask.perform(); //invoked when any data (even non-authentication data) is modified by an external entity (ie: another tab)

      this.get('session.store').on('sessionDataUpdated', () => {
        if (this.get('session.isAuthenticated')) {
          this.getTokenData().then(token => {
            if (token.fpc == true) {
              this.session.trigger('forcePasswordUpdate');
            }

            if (this.user_id && token && token.sub && this.user_id !== token.sub.user_id) {
              this.session.trigger('emulationSucceeded');
            }

            if (Object.keys(token).length > 0 && token.sub) {
              this.user_id = token.sub.user_id;
            }
          }).catch(err => {
            if (!(0, _emberConcurrency.didCancel)(err)) {
              throw err;
            }
          });
        }
      }); //listen for emulation events to reload the page

      this.session.on('emulationSucceeded', () => {
        //ensure ajax headers, grants etc are reloaded
        if (!(0, _debug.isTestEnvironment)()) {
          window.location = this.profile.getFullIndexUri(); //reset application
        }
      });
    },

    getTokenData() {
      return this.getTokenDataTask.perform();
    },

    getTokenDataTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.waitForProperty)(this, 'session.isAuthenticated', true);
      var data = yield this.get('session.store').restore();
      var authenticator = Ember.getOwner(this).lookup('authenticator:jwt');
      var token = {};

      if (data && data.authenticated && data.authenticated.token) {
        token = authenticator.getTokenData(data.authenticated.token);
      }

      return token;
    }),

    /**
     * See if logged in user needs to sign terms and conditions
     */
    needsToSignTermsAndConditionsTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.waitForProperty)(this, 'session.isAuthenticated', true);
      var data = yield this.get('session.store').restore();

      var message = _lodash.default.get(data, 'authenticated.meta.message', false);

      if (message && message === 'We do not have a record of you signing the terms and conditions.') {
        this.needsToSignTermsAndConditions = true;
      } else {
        this.needsToSignTermsAndConditions = false;
      }
    }),

    /**
     * Retrieve the logged in user for the current session
     *
     */
    getUser() {
      return this.getUserTask.unlinked().perform();
    },

    getUserTask: (0, _emberConcurrency.task)(function* () {
      var notInMirage = _environment.default['ember-cli-mirage']['enabled'] === false;

      if (notInMirage) {
        yield this.stMulticallService.waitForMulticallByKeyAndEventname('headerCombined', 'finishedRequest', 'user');
      }

      var user = null;
      var token = yield this.getTokenData();

      if (notInMirage) {
        var userMulticall = yield this.stMulticallService.grabMulticallDataByKeyAndSubkey('headerCombined', 'user', 'finishedRequest');
        user = userMulticall.actualResponse;

        if (user.get('locale') && !this.locale) {
          // If locale hasn't been set before, set it now
          this.set('locale', user.get('locale'));
          this.intl.setLocale([user.get('locale'), 'en-US']);
        }
      } else {
        if (Object.keys(token).length > 0 && !token.fpc && token.sub) {
          user = yield this.store.peekThenFindRecord('user', token.sub.user_id);

          if (user.get('locale') && !this.locale) {
            // If locale hasn't been set before, set it now
            this.set('locale', user.get('locale'));
            this.intl.setLocale([user.get('locale'), 'en-US']);
          }
        }
      }

      return user;
    }),

    get isSuperUser() {
      return this.isSuperUserTask.perform();
    },

    isSuperUserTask: (0, _emberConcurrency.task)(function* () {
      var user = yield this.getUser();
      return user && user.get('isSecurityTraxSuperUser');
    }),

    /**
     * User Emulation
     */
    isEmulatedUser: Ember.computed('session.session.authenticated.token', function () {
      return this.getTokenData().then(token => {
        return new Ember.RSVP.Promise(resolve => {
          var isEmulated = Object.keys(token).length > 0 && token.sub && token.sub.original_user_id > 0 && token.sub.user_id != token.sub.original_user_id;
          resolve(isEmulated);
        });
      }).catch(err => {
        if (!(0, _emberConcurrency.didCancel)(err)) {
          throw err;
        }
      });
    }),
    isEmulatedByUserId: Ember.computed('session.session.authenticated.token', function () {
      return this.getTokenData().then(token => {
        return new Ember.RSVP.Promise(resolve => {
          var originalUserId = null;

          if (Object.keys(token).length > 0 && token.sub.original_user_id > 0 && token.sub.user_id != token.sub.original_user_id) {
            originalUserId = token.sub.original_user_id;
          }

          resolve(originalUserId);
        });
      }).catch(err => {
        if (!(0, _emberConcurrency.didCancel)(err)) {
          throw err;
        }
      });
    }),
    emulateUserTask: (0, _emberConcurrency.task)(function* (userId) {
      var token = yield this.getTokenData();

      if (token && token.sub && token.sub.user_id == userId) {
        return;
      }

      var response = yield this.stAjax.request(this.stAjax.getUrl("/emulate"), {
        method: 'POST',
        data: {
          emulation_user_id: userId
        }
      });

      if (token.sub.original_user_id && token.sub.original_user_id == userId) {
        Sentry.configureScope(scope => {
          scope.setUser({
            emulated_user_id: null
          });
        });
      } else {
        Sentry.configureScope(scope => {
          scope.setUser({
            emulated_user_id: userId
          });
        });
      } //persist token


      var authenticator = yield Ember.getOwner(this).lookup('authenticator:jwt');
      var responseData = yield authenticator.handleAuthResponse(response);
      authenticator.trigger('sessionDataUpdated', responseData);
      this.session.trigger('emulationSucceeded');
    }),

    /**
     * Retrieve the company model for the current session
     *
     */
    getCompany() {
      return this.getTokenData().then(token => {
        var company = null;

        if (Object.keys(token).length > 0) {
          company = this.store.peekThenFindRecord('company', token.sub.company_id);
        }

        return company;
      }).catch(err => {
        if (!(0, _emberConcurrency.didCancel)(err)) {
          throw err;
        }
      });
    },

    /**
     * decide if company is an adt company
     *
     */
    companyHasSpecificMonitorTask: (0, _emberConcurrency.task)(function* (monitor = 'ADT') {
      try {
        var companyData = yield this.getCompany();
        var hasAdtMonitoringCompany = false;

        if (companyData.get('responseMeta')) {
          hasAdtMonitoringCompany = _lodash.default.get(companyData.get('responseMeta'), 'monitor_integrations.' + monitor, false);
        }

        return hasAdtMonitoringCompany;
      } catch (error) {
        (false && Ember.warn(error + 'companyHasSpecificMonitorTask failed', {
          id: 'services.sessionData'
        }));
      }
    }),

    /**
     * Retrieve the company settings
     *
     */
    getCompanySettings() {
      return this.get('session.store').restore().then(data => {
        var authenticator = Ember.getOwner(this).lookup('authenticator:jwt');

        if (data.authenticated) {
          return authenticator.getTokenData(data.authenticated.token);
        }
      }).then(() => {
        return this.store.peekThenFindRecord('globalSetting', 1);
      }).catch(err => {
        if (!(0, _emberConcurrency.didCancel)(err)) {
          throw err;
        }
      });
    },

    /**
     * Retrieve the company invoice settings
     *
     */
    getCompanyInvoiceSettings() {
      return this.get('session.store').restore().then(data => {
        var authenticator = Ember.getOwner(this).lookup('authenticator:jwt');
        return authenticator.getTokenData(data.authenticated.token);
      }).then(() => {
        return this.store.peekThenFindRecord('globalSettingsInvoiceSetting', 1);
      }).catch(err => {
        if (!(0, _emberConcurrency.didCancel)(err)) {
          throw err;
        }
      });
    },

    /**
     * Retrieve the company recurring invoice settings
     *
     */
    getCompanyRecurringInvoiceSettings() {
      return this.get('session.store').restore().then(data => {
        var authenticator = Ember.getOwner(this).lookup('authenticator:jwt');
        return authenticator.getTokenData(data.authenticated.token);
      }).then(() => {
        return this.store.peekThenFindRecord('globalSettingsRecurringInvoiceSetting', 1);
      }).catch(err => {
        if (!(0, _emberConcurrency.didCancel)(err)) {
          throw err;
        }
      });
    },

    getCompanyPaymentGateway() {
      return this.get('session.store').restore().then(data => {
        var authenticator = Ember.getOwner(this).lookup('authenticator:jwt');
        return authenticator.getTokenData(data.authenticated.token);
      }).then(() => {
        return this.store.peekThenFindRecord('processingGateway', 1);
      });
    },

    getGlobalTaxExemptions() {
      return this.get('session.store').restore().then(data => {
        var authenticator = Ember.getOwner(this).lookup('authenticator:jwt');
        return authenticator.getTokenData(data.authenticated.token);
      }).then(() => {
        return this.store.peekThenFindRecord('salesTaxExemptionSetting', 1);
      });
    },

    getCompanyDefaults(key) {
      return this.get('session.store').restore().then(data => {
        var authenticator = Ember.getOwner(this).lookup('authenticator:jwt');
        return authenticator.getTokenData(data.authenticated.token);
      }).then(data => {
        return this.store.peekThenFindRecord(key, data.sub.company_id);
      });
    },

    getPublicCompanyInfo() {
      return this.getPublicCompanyInfoTask.perform();
    },

    getPublicCompanyInfoTask: (0, _emberConcurrency.task)(function* () {
      var notInMirage = _environment.default['ember-cli-mirage']['enabled'] === false;

      if (notInMirage && this.session.isAuthenticated) {
        yield this.stMulticallService.waitForMulticallByKeyAndEventname('headerCombined', 'finishedRequest', 'company-public-info');
      }

      if (!this.companyPublicInfo) {
        this.stAjax.setHeaders(null);
        var companyInfo;

        if (notInMirage && this.session.isAuthenticated) {
          var companyInfoMulticall = yield this.stMulticallService.grabMulticallDataByKeyAndSubkey('headerCombined', 'company-public-info', 'finishedRequest');
          companyInfo = companyInfoMulticall.actualResponse;
        } else {
          companyInfo = yield this.stAjax.request('company-public-info', {
            method: 'GET'
          });
        }

        this.set('companyPublicInfo', companyInfo);
      }

      return this.companyPublicInfo;
    }).enqueue().maxConcurrency(1)
  });

  _exports.default = _default;
});