define("securitytrax-ember-ui/components/calendar-glimmer/calendar-map/layer-group-schedule/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tMtZYdPF",
    "block": "{\"symbols\":[\"layer\",\"@newEventCustomer\"],\"statements\":[[8,\"layer-group\",[],[[\"@name\",\"@baselayer\",\"@default\"],[[30,[36,0],[[32,2,[\"customer\",\"name\"]],\" (\",[32,2,[\"customer\",\"id\"]],\")\"],null],false,true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"calendar-glimmer/calendar-map/icon\",[],[[\"@layers\",\"@currGeoPoint\",\"@zIndexOffset\",\"@opacity\"],[[32,1],[32,2,[\"location\"]],[32,0,[\"zIndex\"]],[32,0,[\"opacity\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"]],\"hasEval\":false,\"upvars\":[\"concat\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/calendar-glimmer/calendar-map/layer-group-schedule/template.hbs"
    }
  });

  _exports.default = _default;
});