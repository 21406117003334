define("securitytrax-ember-ui/components/multi-step-form/forms/administration/organization/edit-office-location/inventory/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/administration/organization/edit-office-location/base-component", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency"], function (_exports, _baseComponent, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    enhancedFeatures: Ember.inject.service(),
    premiumFeatures: Ember.computed(function () {
      return this.enhancedFeatures.premiumFeatures;
    }),
    inventoryRecipientTemplates: Ember.computed(function () {
      return this.inventoryRecipientTemplatesTask.perform();
    }),
    inventoryRecipientTemplatesTask: (0, _emberConcurrency.task)(function* () {
      var hasEnhancedInventory = yield this.enhancedFeatures.hasFeature('inventoryExtendedFunctionality');
      return hasEnhancedInventory ? yield this.store.findAll('equipmentToRecipientTemplate') : [];
    }),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      Ember.set(this, 'inventoryRecipient', this.multiStepForm.formCopiedModelsGetVal('location.model.inventoryRecipient'));

      if (this.formMode !== 'view') {
        multiStepForm.registerStepData('inventory', 'buttons', [{
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveOfficeLocation.perform(multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary office-location-save-button'
        }]);
      }
    }

  });

  _exports.default = _default;
});