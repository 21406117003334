define("securitytrax-ember-ui/components/html/input-checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7rMr8KEx",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[10,\"input\"],[15,\"data-indeterminate\",[34,0]],[15,3,[34,1]],[15,\"checked\",[34,2]],[15,\"disabled\",[34,3]],[15,\"readonly\",[34,3]],[15,\"indeterminate\",[34,0]],[15,\"onclick\",[30,[36,4],[[32,0],\"toggle\"],null]],[15,\"bubbles\",[34,5]],[14,4,\"checkbox\"],[12],[13],[2,\"\\n\\n\"],[11,\"div\"],[24,0,\"ui-input checkbox-custom\"],[4,[38,4],[[32,0],\"toggle\"],null],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"indeterminate\",\"name\",\"checked\",\"disabled\",\"action\",\"bubbles\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/input-checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});