define("securitytrax-ember-ui/components/modals/st-paper/paper-dialog-container/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PaperDialogContainer
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    classNames: ['md-dialog-container'],

    mouseDown(ev) {
      this._sourceEl = ev.target;
    },

    outsideClicked: () => {},

    mouseUp(ev) {
      if (this._sourceEl === this.element && ev.target === this.element) {
        ev.stopPropagation();
        ev.preventDefault();
        this.outsideClicked();
      }
    }

  });

  _exports.default = _default;
});