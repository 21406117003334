define("securitytrax-ember-ui/services/version", ["exports", "securitytrax-ember-ui/config/environment", "securitytrax-ember-ui/utils/interval", "@sentry/browser"], function (_exports, _environment, _interval, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var enabled = window && window.location && window.location.reload;

  var _default = Ember.Service.extend(Ember.Evented, {
    stAjax: Ember.inject.service(),
    ajax: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    version: undefined,
    versionconflict: false,

    init() {
      this._super(...arguments); // Window reloading won't work


      if (typeof enabled === 'undefined' || window.Cypress) {
        return;
      } // If check version is enabled, setup a reset if versions dont match


      if (_environment.default['securitytrax-ember-ui']['version-check']['frequency'] > 0) {
        this.on('versionconflict', () => {
          this.set('versionconflict', true); //this.resetApp(); //popup the version-check modal via component instead
        });
      }

      this.setupTimer(); // Store current version on init (app is loaded) so we can check later.

      this.checkVersion();
    },

    setupTimer: function () {
      if (_environment.default['securitytrax-ember-ui']['version-check']['frequency'] > 0) {
        var timer = new _interval.default();
        timer.setContext(this);
        timer.setFunction(this.checkVersion);
        timer.setInterval(_environment.default['securitytrax-ember-ui']['version-check']['frequency']);
        timer.start(); //setInterval(() => this.checkVersion(), ENV['securitytrax-ember-ui']['version-check']['frequency']);
      }
    },
    checkVersion: function () {
      if (_environment.default['securitytrax-ember-ui']['version-check']['frequency'] > 0) {
        var gitCommitId = _environment.default['securitytrax-ember-ui']['version-check']['version'];
        var getOptions = {
          method: 'GET',
          cache: false,
          //if false, appends ?_= random hash so file doesn't get cached.
          dataType: 'text'
        };
        var requestUrl = this.get('stAjax.host') + '/version.txt'; //use default AJAX instead of ST-AJAX so we don't do OPTIONS request

        this.ajax.request(requestUrl, getOptions).then(currentVersion => {
          if (this.version === undefined) {
            var newVersion = gitCommitId ? gitCommitId : currentVersion;
            this.set('version', newVersion.trim());
          } else if (this.version != currentVersion.trim()) {
            this.trigger('versionconflict');
          }
        }).catch(error => {
          (false && Ember.warn('failed to get current version of app' + error, {
            id: 'services.version'
          }));
        });
      }
    },
    versionChangedObserver: Ember.observer('version', function () {
      Sentry.configureScope(scope => {
        scope.setTag('frontend_version', this.version);
      });
    }),
    // Reload the app to get latest code
    resetApp: function () {
      window.location.reload();
    }
  });

  _exports.default = _default;
});