define("securitytrax-ember-ui/components/pages/accounting/invoices/invoices/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/table-column-sort", "securitytrax-ember-ui/utils/obj-helpers", "moment"], function (_exports, _emberConcurrency, _tableColumnSort, _objHelpers, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableColumnSort.default, {
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    tagName: '',
    totalsPastDue: null,
    hideAgingBalance: false,
    hideTotalPastDue: false,
    hideTotalOutstanding: false,
    hidePaidColumn: false,
    hideOutstandingColumn: false,
    stSortCurrentSortColumn: 'id',
    stSortAscending: false,
    outstandingColumnClass: 'small-6 medium-3 large-2',
    recurringIDColumnClass: 'small-6 medium-3 large-1',
    termColumnClass: 'small-6 medium-3 large-1',
    outstandingHeaderClass: 'large-2',
    recurringIDHeaderClass: 'large-1',
    termHeaderClass: 'large-1',
    initTask: (0, _emberConcurrency.task)(function* () {
      var company = yield this.sessionData.getCompany();
      Ember.set(this, 'hideAgingBalance', company.get('responseMeta.ui_conditional_features.hidden_elements.customers.accounting.invoices.aging_balance'));
      Ember.set(this, 'hideTotalPastDue', company.get('responseMeta.ui_conditional_features.hidden_elements.customers.accounting.invoices.total_past_due'));
      Ember.set(this, 'hideTotalOutstanding', company.get('responseMeta.ui_conditional_features.hidden_elements.customers.accounting.invoices.totals.outstanding'));
      Ember.set(this, 'hidePaidColumn', company.get('responseMeta.ui_conditional_features.hidden_elements.customers.accounting.invoices.columns.paid'));
      Ember.set(this, 'hideOutstandingColumn', company.get('responseMeta.ui_conditional_features.hidden_elements.customers.accounting.invoices.columns.outstanding'));
      Ember.set(this, 'stSortString', '-id');

      if (this.hidePaidColumn && this.hideOutstandingColumn) {
        Ember.set(this, 'termHeaderClass', 'large-2');
        Ember.set(this, 'termColumnClass', 'small-6 medium-3 large-2');
        Ember.set(this, 'recurringIDHeaderClass', 'large-2');
        Ember.set(this, 'recurringIDColumnClass', 'small-6 medium-3 large-2');
      } else if (this.hidePaidColumn || this.hideOutstandingColumn) {
        Ember.set(this, 'termHeaderClass', 'large-2');
        Ember.set(this, 'termColumnClass', 'small-6 medium-3 large-2');
      } else {
        Ember.set(this, 'outstandingHeaderClass', 'large-1');
        Ember.set(this, 'outstandingColumnClass', 'small-6 medium-3 large-1');
      }

      (0, _objHelpers.initializeState)(this, {
        stSortableColumnOptions: [{
          name: this.intl.t('common.columns.id') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'id',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.id') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-id',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('common.columns.due_date') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'due_date',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.due_date') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-due_date',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('common.columns.sale_date') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'sale_date',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.sale_date') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-sale_date',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('common.columns.paid') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'is_paid',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.paid') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-is_paid',
          chevronDirection: 'down'
        }]
      });
    }).on('init'),
    invoiceItems: Ember.computed('model.customer.invoices', 'stSortString', function () {
      return this.invoiceItemsTask.perform();
    }),
    invoiceItemsTask: (0, _emberConcurrency.task)(function* () {
      var invoices = yield this.store.query('invoice', {
        filter: ['customer = \'' + this.model.customer.get('id') + '\'', 'invoice_type = \'invoice\''],
        sort: this.stSortString && this.stSortString !== 'is_paid' && this.stSortString !== '-is_paid' ? "".concat(this.stSortString, ",id") : 'id',
        include: 'tags',
        limit: 0
      });

      if (this.stSortString === 'is_paid' || this.stSortString === '-is_paid') {
        invoices = this.sortEmberDataCollection(invoices, 'isPaid', this.stSortString === 'is_paid');
      }

      return invoices;
    }),
    hasInvoices: Ember.computed('model.customer.invoices.[]', function () {
      return this.model.customer.invoices.length;
    }),
    totalAmount: Ember.computed('model.customer.{invoices,invoices.@each.responseMeta}', function () {
      var customerInvoices = this.model.customer.invoices;
      var total_amount = 0;
      customerInvoices.forEach(invoice => {
        total_amount += invoice.responseMeta.total_amount;
      });
      return total_amount;
    }),
    totalOutstanding: Ember.computed('model.customer.{invoices,invoices.@each.responseMeta}', function () {
      var customerInvoices = this.model.customer.invoices;
      var total_outstanding = 0;
      customerInvoices.forEach(invoice => {
        total_outstanding += invoice.responseMeta.due_amount;
      });
      return total_outstanding;
    }),
    totalPastDue: Ember.computed('model.customer.{invoices,invoices.@each.responseMeta}', function () {
      var customerInvoices = this.model.customer.invoices;
      var total = 0;
      var today = (0, _moment.default)();
      customerInvoices.forEach(invoice => {
        var dueDate = invoice.dueDate;

        if (today.isAfter(dueDate)) {
          total += invoice.responseMeta.due_amount;
        }
      });
      return total;
    }),
    totalPastDue1: Ember.computed('model.customer.{invoices,invoices.@each.responseMeta}', function () {
      var customerInvoices = this.model.customer.invoices;
      var total = 0;
      var today = (0, _moment.default)();
      var withinDays30 = (0, _moment.default)().subtract(30, 'days');
      customerInvoices.forEach(invoice => {
        var dueDate = invoice.dueDate;

        if (today.isAfter(dueDate) && withinDays30.isSameOrBefore(dueDate)) {
          total += invoice.responseMeta.due_amount;
        }
      });
      return total;
    }),
    totalPastDue31: Ember.computed('model.customer.{invoices,invoices.@each.responseMeta}', function () {
      var customerInvoices = this.model.customer.invoices;
      var total = 0;
      var withinDays30 = (0, _moment.default)().subtract(30, 'days');
      var withinDays60 = (0, _moment.default)().subtract(60, 'days');
      customerInvoices.forEach(invoice => {
        var dueDate = invoice.dueDate;

        if (withinDays30.isAfter(dueDate) && withinDays60.isSameOrBefore(dueDate)) {
          total += invoice.responseMeta.due_amount;
        }
      });
      return total;
    }),
    totalPastDue61: Ember.computed('model.customer.{invoices,invoices.@each.responseMeta}', function () {
      var customerInvoices = this.model.customer.invoices;
      var total = 0;
      var withinDays60 = (0, _moment.default)().subtract(60, 'days');
      var withinDays90 = (0, _moment.default)().subtract(90, 'days');
      customerInvoices.forEach(invoice => {
        var dueDate = invoice.dueDate;

        if (withinDays60.isAfter(dueDate) && withinDays90.isSameOrBefore(dueDate)) {
          total += invoice.responseMeta.due_amount;
        }
      });
      return total;
    }),
    totalPastDue91: Ember.computed('model.customer.{invoices,invoices.@each.responseMeta}', function () {
      var customerInvoices = this.model.customer.invoices;
      var total = 0;
      var withinDays90 = (0, _moment.default)().subtract(90, 'days');
      customerInvoices.forEach(invoice => {
        var dueDate = invoice.dueDate;

        if (withinDays90.isAfter(dueDate)) {
          total += invoice.responseMeta.due_amount;
        }
      });
      return total;
    }),
    actions: {
      transitionToSubroute(path) {
        this.router.transitionTo('customers.accounting.invoices-invoices.edit-create-invoice', path);
      },

      sortList(column, ascending) {
        this.sortPaginatedList(column, ascending);
      },

      mobileSortList(column) {
        this.sortMobilePaginatedList(column.value);
      }

    }
  });

  _exports.default = _default;
});