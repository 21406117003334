define("securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/re-usable-forms/user-agreement/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class UserAgreement extends _component.default {}

  _exports.default = UserAgreement;
});