define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/forms/adt/payment-method/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/customer-base", "securitytrax-ember-ui/classes/user-defined-fields", "lodash"], function (_exports, _emberConcurrency, _customerBase, _userDefinedFields, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerBase.default.extend({
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionData: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      if (this.isASG || this.isLS) {
        this.setupASGButtons();
      } else {
        this.setupADTButton();
      }
    },

    initData: (0, _emberConcurrency.task)(function* () {
      (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true);

      var udfs = _lodash.default.get(this.workflowConfig, "udfFields.".concat(this.multiStepForm.currentStepNameKey, ".udfs"), []);

      this.UdfClass = new _userDefinedFields.default({
        store: this.store,
        intl: this.intl,
        udfIDs: udfs,
        model: this.newCustomer
      });
      yield this.UdfClass.udfListTask.perform();
    }),
    setupADTButton: function () {
      this.multiStepForm.registerStepData('paymentMethod', 'buttons', [{
        text: this.intl.t('common.actions.back'),
        classNamesString: 'st-btn st-btn-secondary',
        click: () => {
          this.multiStepForm.currentStepNameKeySetVal('step3');
          this.multiStepForm.progressBarConfig.activeIndex = 2;
        }
      }, {
        text: this.intl.t('common.actions.saveAndContinue'),
        classNamesString: 'st-btn st-btn-primary customer-payment-method-save-button',
        positionOptions: 'up left',
        click: buttonPromise => {
          return this.saveCustomer.perform(this.multiStepForm, buttonPromise);
        },
        additionalClickActions: [{
          label: this.intl.t('common.actions.deleteCustomer'),
          action: () => {
            this.set('formMode', 'create');
            this.openConfirmDelete('paymentMethod');
          },
          classNamesString: 'dropdown-delete-customer'
        }, {
          label: this.intl.t('common.actions.saveAndClose'),
          action: buttonPromise => {
            var close = true;
            return this.saveCustomer.perform(this.multiStepForm, buttonPromise, close);
          },
          classNamesString: 'dropdown-save-close'
        }]
      }]);
    },
    setupASGButtons: function () {
      this.initData.perform();
      this.multiStepForm.registerStepData('step6', 'buttons', [{
        text: this.intl.t('common.actions.back'),
        classNamesString: 'st-btn st-btn-secondary',
        click: () => {
          this.multiStepForm.currentStepNameKeySetVal('step5');
          this.multiStepForm.progressBarConfig.activeIndex = 4;
        }
      }, {
        text: this.intl.t('common.actions.saveAndContinue'),
        classNamesString: 'st-btn st-btn-primary customer-payment-method-save-button',
        positionOptions: 'up left',
        click: buttonPromise => {
          return this.saveCustomer.perform(this.multiStepForm, buttonPromise);
        },
        additionalClickActions: [{
          label: this.intl.t('common.actions.deleteCustomer'),
          action: () => {
            this.openConfirmDelete('step6');
          },
          classNamesString: 'dropdown-delete-customer'
        }, {
          label: this.intl.t('common.actions.saveAndClose'),
          action: buttonPromise => {
            var close = true;
            return this.saveCustomer.perform(this.multiStepForm, buttonPromise, close);
          },
          classNamesString: 'dropdown-save-close'
        }]
      }]);
    },
    saveCustomer: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise, close = false) {
      if (this.UdfClass) {
        var formattedObjects = Ember.A();
        this.UdfClass.validateUdfs(formattedObjects);
        yield multiStepForm.validateList.perform(formattedObjects);
        yield this.UdfClass.saveUdfsTask.perform(this.newCustomer);
      }

      buttonPromise.then(() => {
        if (close) {
          this.set('formMode', 'create');
          this.closeForm(multiStepForm, multiStepForm.formOriginalModelsGetVal('customer.model'));
        } else if (this.isASG || this.isLS) {
          this.advanceForm(multiStepForm, 'step7', 6);
        } else {
          this.advanceForm(multiStepForm, 'workOrder::createWorkOrder', 4);
        }
      });
    })
  });

  _exports.default = _default;
});