define("securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/greensky--dead/activation/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/create-financing-base", "ember-concurrency"], function (_exports, _createFinancingBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CreateFinancingActivation = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = (0, _emberConcurrency.task)(function* () {
    try {
      yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
      var application = this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model');
      var questions = yield this.stAjax.request('/consumer_financing_applications/' + application.get('id') + '/actions/consumer_finance_application_invoke_generic', {
        method: 'POST',
        data: {
          data: {
            method: 'greensky__getQuestions',
            payload: {
              'greensky__getQuestionsRequest': {}
            }
          }
        }
      });

      if (questions.data.messages && questions.data.messages.length > 0) {
        this.stNotificationManager.addInfoMessages(questions.responsePayload.data.messages, 'modal');
      }

      questions.data.payload.greensky__getQuestionsResponse.questions.quiz.question.forEach(question => {
        question.choice.forEach(choice => {
          choice['checked'] = false;
        });
      });
      this.quizId = questions.data.payload.greensky__getQuestionsResponse.questions.quiz.quiz_id;
      this.transactionId = questions.data.payload.greensky__getQuestionsResponse.questions.transaction_id;
      return questions.data.payload.greensky__getQuestionsResponse.questions.quiz.question;
    } catch (err) {
      this.hasErrors = true;
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      return [];
    }
  }), _dec10 = (0, _emberConcurrency.task)(function* () {
    var answers = [];
    var questions = yield this.questions;
    questions.forEach(question => {
      var question_id = question.question_id;
      var choice_id = null;

      for (var i = 0; i < question.choice.length; i++) {
        var choice = question.choice[i];

        if (choice.checked) {
          choice_id = choice.choice_id;
          break;
        }
      }

      answers.push({
        question_id,
        choice_id
      });
    });
    return answers;
  }), _dec11 = (0, _emberConcurrency.task)(function* () {
    var answersStatus;
    var application = multiStepForm.get('formCopiedModels.consumerFinancingApplication.model');

    try {
      var sendAnswersResponse = yield this.stAjax.request('/consumer_financing_applications/' + application.get('id') + '/actions/consumer_finance_application_invoke_generic', {
        method: 'POST',
        data: {
          data: {
            method: 'greensky__sendAnswers',
            payload: {
              'greensky__sendAnswersRequest': {
                quiz: {
                  quiz_id: this.quizId,
                  answer: yield this.answers
                },
                transaction_id: this.transactionId
              }
            }
          }
        }
      });

      if (sendAnswersResponse.data.messages && sendAnswersResponse.data.messages.length > 0) {
        this.stNotificationManager.addInfoMessages(sendAnswersResponse.responsePayload.data.messages, 'modal');
      }

      answersStatus = sendAnswersResponse.data.payload.greensky__sendAnswersResponse.answer.status;

      if (sendAnswersResponse.data.payload.greensky__sendAnswersResponse.answer.quiz) {
        this.incorrectAnswer = true;
        this.questions = sendAnswersResponse.data.payload.greensky__sendAnswersResponse.answer.quiz.question;
      }
    } catch (err) {
      this.hasErrors = true;
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }

    buttonPromise.then(() => {
      if (answersStatus === 'pass') {
        multiStepForm.set('currentStepNameKey', 'addPayment');
      } else if (answersStatus === 'fail') {
        this.hasErrors = true;
      }
    });
  }), _dec12 = Ember._action, _dec13 = Ember._action, (_class = (_temp = class CreateFinancingActivation extends _createFinancingBase.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "grants", _descriptor4, this);

      _initializerDefineProperty(this, "quizId", _descriptor5, this);

      _initializerDefineProperty(this, "incorrectAnswer", _descriptor6, this);

      _initializerDefineProperty(this, "buttonDisabled", _descriptor7, this);

      _initializerDefineProperty(this, "transactionId", _descriptor8, this);

      _initializerDefineProperty(this, "questionsTask", _descriptor9, this);

      _initializerDefineProperty(this, "answersTask", _descriptor10, this);

      _initializerDefineProperty(this, "sendAnswers", _descriptor11, this);
    }

    // Figure different way to do this
    // buttonDisabledObserver: observer('buttonDisabled', function () {
    //     this.registerButtons();
    // }),
    get questions() {
      return this.questionsTask.perform();
    }

    get answers() {
      return this.answersTask.perform();
    }

    buttons() {
      this.args.multiStepForm.registerStepData("activation", 'buttons', [{
        text: this.intl.t('common.actions.continue'),
        click: buttonPromise => {
          return this.sendAnswers.perform(this.args.multiStepForm, buttonPromise);
        },
        disabled: this.buttonDisabled,
        classNamesString: 'st-btn st-btn-primary'
      }]);
    }

    uncheckAllOthersAndReregisterButtons(question, answer) {
      question.choice.forEach(choice => {
        if (choice.choice_id !== answer.choice_id) {
          Ember.set(choice, 'checked', false);
        }
      });
      this.buttonDisabled = false;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "quizId", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "incorrectAnswer", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "buttonDisabled", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "transactionId", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "questionsTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "answersTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "sendAnswers", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "buttons", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "buttons"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "uncheckAllOthersAndReregisterButtons", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "uncheckAllOthersAndReregisterButtons"), _class.prototype)), _class));
  _exports.default = CreateFinancingActivation;
});