define("securitytrax-ember-ui/components/html/multi-select/building-blocks/single-input-create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wagJJKXs",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"single-input-create-wrapper \",[34,13]]]],[12],[2,\"\\n\"],[6,[37,8],[[35,14]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[11,\"div\"],[24,0,\"create-new-item-option\"],[4,[38,0],[[32,0],\"toggleForm\"],null],[12],[1,[34,12]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"h5\"],[14,0,\"single-input-title\"],[12],[1,[34,7]],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"single-input-create\"],[12],[2,\"\\n\"],[6,[37,8],[[35,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                \"],[8,\"multi-step-form/switchable-inputs/masked-input\",[],[[\"@formMode\",\"@value\",\"@onChange\",\"@mask\",\"@onEnter\"],[\"edit\",[34,3],[30,[36,0],[[32,0],[30,[36,4],[[35,3]],null]],null],[34,6],[30,[36,0],[[32,0],\"buttonClickAction\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"                \"],[1,[30,[36,5],null,[[\"on-change\",\"value\",\"placeholder\",\"type\",\"name\",\"class\",\"enter\"],[[30,[36,0],[[32,0],[30,[36,4],[[35,3]],null]],null],[35,3],[35,2],[35,1],\"createValue\",\"create-input\",[30,[36,0],[[32,0],\"buttonClickAction\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"            \"],[8,\"html/st-button\",[[24,0,\"st-btn st-btn-primary submit-button\"],[24,5,\"aspect-ratio: 1;\"]],[[\"@disabled\",\"@icon\",\"@iconOnly\",\"@clickAction\",\"@bubblesUp\"],[[34,9],\"icn-check\",true,[30,[36,0],[[32,0],\"buttonClickAction\"],null],[34,10]]],null],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"h5\"],[14,0,\"single-input-error-message\"],[12],[1,[34,11]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"type\",\"placeholder\",\"createValue\",\"mut\",\"input\",\"maskInput\",\"inputLabel\",\"if\",\"submitDisabled\",\"bubblesUp\",\"errorMessage\",\"optionLabel\",\"formState\",\"hideFormInput\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/multi-select/building-blocks/single-input-create/template.hbs"
    }
  });

  _exports.default = _default;
});