define("securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-at-risks/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    quickAdd: Ember.inject.service(),
    atRiskEntry: null,
    customer: null,
    initData: (0, _emberConcurrency.task)(function* () {
      if (this.customerAtRiskId !== 'create') {
        var _this$customer;

        this.set('atRiskEntry', yield this.store.findRecord('customerAtRisk', this.customerAtRiskId, {
          include: 'customer'
        }));
        this.set('customer', (_this$customer = this.customer) !== null && _this$customer !== void 0 ? _this$customer : this.get('atRiskEntry.customer'));
      }

      this.set('atRiskEntryFormStarted', true);
    }).on('init'),
    atRiskReasons: Ember.computed(function () {
      return this.store.findAll('customerAtRiskReason');
    }),
    atRiskReasonCauses: Ember.computed(function () {
      return this.store.findAll('customerAtRiskReasonCause');
    }),
    atRiskResolutions: Ember.computed(function () {
      return this.store.findAll('customerAtRiskResolution');
    }),
    atRiskCreditReasons: Ember.computed(function () {
      return this.store.findAll('customerAtRiskCreditReason');
    }),
    atRiskEntryFormMode: Ember.computed('atRiskEntry', function () {
      if (this.grants.getIndividualGrant('customer_at_risks.create') && !this.atRiskEntry) {
        return 'create';
      } else if (this.grants.getIndividualGrant('customer_at_risks.modify') && this.atRiskEntry) {
        return 'edit';
      } else {
        return 'view';
      }
    }),
    atRiskEntryFormSteps: Ember.computed('atRiskEntry', 'atRiskEntryFormMode', function () {
      var titleTranslation = this.atRiskEntryFormMode === 'create' ? 'pages.customers.at_risk_entries.new_entry' : 'pages.customers.at_risk_entries.edit';
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.intl.t(titleTranslation, {
            name: this.get('customer.flname'),
            id: this.get('atRiskEntry.id')
          }),
          stepComponentPath: 'multi-step-form/forms/at-risk-entries/edit-at-risk-entry'
        })
      });
    }),
    atRiskEntryFormOriginalModels: Ember.computed('atRiskEntry', function () {
      return Ember.Object.create({
        customerAtRisk: {
          model: this.atRiskEntry,
          copyRelationMap: {
            customerAtRiskReason: {
              readOnly: true
            },
            customerAtRiskReasonCause: {
              readOnly: true
            },
            customerAtRiskResolution: {
              readOnly: true
            },
            customerAtRiskCreditReason: {
              readOnly: true
            },
            assignedUser: {
              readOnly: true
            },
            saveUser: {
              readOnly: true
            },
            customerAtRiskNotes: {
              readOnly: true,
              relationships: {
                createdByUser: {
                  readOnly: true
                }
              }
            }
          }
        }
      });
    }),
    actions: {
      transitionBackToParentRoute() {
        var transitionToRoute = this.quickAdd.closeRoute || this.parentRoute;

        if (transitionToRoute.includes('customer')) {
          this.router.transitionTo(transitionToRoute, this.get('customer.id'));
        } else {
          this.router.transitionTo(transitionToRoute);
        }
      }

    }
  });

  _exports.default = _default;
});