define("securitytrax-ember-ui/components/lead/lead-central-station/component", ["exports", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    leadCentralStationFormMode: undefined,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        leadCentralStationFormMode: 'view'
      });
      Ember.defineProperty(this, 'leadFormOriginalModels', Ember.computed('lead', 'grants.userGrants', this.store.getFullComputedWatcherStringByModelName('customer', {
        actualVariableName: 'lead'
      }), function () {
        var grants = this.grants;
        return Ember.Object.create({
          customer: {
            model: this.lead ? this.lead : null,
            copyRelationMap: {
              leadInformation: {
                doCopy: grants.getIndividualGrant('lead_information.view'),
                doValidate: grants.getIndividualGrant('lead_information.view'),
                doSave: grants.getIndividualGrant('lead_information.modify'),
                relationships: {
                  leadStatus: {
                    readOnly: true
                  },
                  monitoringCompany: {
                    doValidate: false,
                    doSave: false
                  },
                  dealerNumber: {
                    doValidate: false,
                    doSave: false
                  }
                }
              }
            }
          }
        });
      }));
    },

    leadCentralStationForm: Ember.computed(function () {
      return Ember.Object.create({
        leadCentralStation: Ember.Object.create({
          stepNameKey: 'leadCentralStation',
          title: this.intl.t('common.nav.create.customer.sections.precheck'),
          //this.intl.t('pages.leads.central_station'),
          stepComponentPath: 'multi-step-form/forms/leads/central-station'
        })
      });
    })
  });

  _exports.default = _default;
});