define("securitytrax-ember-ui/customers/view/edit-create-funding/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1OfVSO5k",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modals/sub-route-modals/edit-create-funding\",[],[[\"@customer\",\"@fundingId\",\"@parentRoute\"],[[34,0,[\"customer\"]],[34,0,[\"fundingId\"]],[34,0,[\"parentRoute\"]]]],null]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/customers/view/edit-create-funding/template.hbs"
    }
  });

  _exports.default = _default;
});