define("securitytrax-ember-ui/components/multi-step-form/forms/leads/lead-base", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency"], function (_exports, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    entityListService: Ember.inject.service('events/entity-list'),
    stNotificationManager: Ember.inject.service(),
    grants: Ember.inject.service(),
    recentSearchesService: Ember.inject.service(),

    closeForm(multiStepForm, customer) {
      if (this.isNewRecord) {
        multiStepForm.formStartedSetVal(false);
        this.transitionToCustomer(customer);
      } else {
        this.set('createLeadFormStarted', false);
        this.set('formMode', 'view');
      }

      this.entityListService.trigger('reload-list');
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
    },

    advanceForm(multiStepForm, stepKey, stepIndex, customer, skip = false) {
      if (stepIndex == 1 && !skip) {
        var successKey = customer ? 'common.notifications.save_successful' : 'common.nav.create.customer.success';
        var grantsService = this.grants;

        if (grantsService.getIndividualGrant('customer_credit_checks.create') && grantsService.getIndividualGrant('customer_credit_checks.view')) {
          multiStepForm.currentStepNameKeySetVal('step2');
          multiStepForm.progressBarConfig.activeIndex = 1;
        } else if (grantsService.getIndividualGrant('leads.convert_to_customer')) {
          multiStepForm.currentStepNameKeySetVal('step3');
          multiStepForm.progressBarConfig.activeIndex = 2;
        } else {
          this.closeForm(multiStepForm, customer);
        }

        this.stNotificationManager.addSuccessKey(successKey, 'modal');
      } else {
        multiStepForm.currentStepNameKeySetVal(stepKey);
        multiStepForm.progressBarConfig.activeIndex = stepIndex;
        Ember.set(multiStepForm.progressBarConfig.items.objectAt(stepIndex - 1), 'isComplete', true);

        if (!skip) {
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'createCustomerModal');
        }
      }
    },

    deleteLead: (0, _emberConcurrency.task)(function* (buttonPromise) {
      var lead = this.multiStepForm.formOriginalModels.customer.model;
      yield lead.destroyRecordWithRollbackTask.perform();
      this.router.transitionTo('leads');
      buttonPromise.then(() => {
        this.recentSearchesService.removeRecents('recentLeads', [lead.get('id')]);
        this.stNotificationManager.addSuccessKey('common.notifications.customer.deleted');
      });
    })
  });

  _exports.default = _default;
});