define("securitytrax-ember-ui/helpers/is-equal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isEqual = isEqual;
  _exports.default = void 0;

  function isEqual([leftside, rightside]) {
    return leftside === rightside;
  }

  var _default = Ember.Helper.helper(isEqual);

  _exports.default = _default;
});