define("securitytrax-ember-ui/components/customer/dashboard/credit-grade-card/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CreditGradeCard = (_dec = Ember.inject.service('grants'), _dec2 = Ember.inject.service('intl'), _dec3 = Ember._tracked, _dec4 = (0, _emberConcurrency.task)(function* () {
    this.creditGradeData = (yield this.args.customer.invokeAction('dashboard_cards/credit_score', {
      includeActionsString: false,
      verb: 'GET'
    })).responsePayload.data;
  }), _dec5 = Ember._action, _dec6 = Ember._action, (_class = (_temp = class CreditGradeCard extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "grants", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "creditGradeData", _descriptor3, this);

      _initializerDefineProperty(this, "creditGradeTask", _descriptor4, this);

      if (!this.args.useDefaultData) {
        this.creditGradeTask.perform();
      } else {
        this.creditGradeData = this.defaultData;
      }
    }

    get defaultData() {
      return {
        credit_grade: 'B-',
        credit_score: '650',
        number_of_credit_checks: 1,
        creditChecks: [{
          credit_bureau: 'Test',
          date: '2020-01-10T11:00:00-06:00'
        }]
      };
    }

    get gradeStyle() {
      if (!this.creditGradeData.credit_score) {
        return 'credit-score-card__credit-grade--no-score';
      }

      return '';
    }

    get showCreditScore() {
      return this.args.useDefaultData || this.grants.getIndividualGrant('customer_credit_checks.view');
    }

    showDropdown(dropdown) {
      if (this.creditGradeData.credit_score !== '' && this.creditGradeData.number_of_credit_checks > 0) {
        dropdown.actions.open();
      }
    }

    hideDropdown(dropdown) {
      dropdown.actions.close();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "creditGradeData", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "creditGradeTask", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showDropdown", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "showDropdown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hideDropdown", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "hideDropdown"), _class.prototype)), _class));
  _exports.default = CreditGradeCard;
});