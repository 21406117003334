define("securitytrax-ember-ui/mixins/copyable", ["exports", "@ember-data/model", "lodash", "ember-concurrency", "securitytrax-ember-ui/utils/obj-helpers", "ember-inflector"], function (_exports, _model, _lodash, _emberConcurrency, _objHelpers, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global require */
  var _default = Ember.Mixin.create({
    stNotificationManager: Ember.inject.service(),
    intl: Ember.inject.service(),
    copyable: true,
    deepCopy: function (options) {
      var compiledOptions = _lodash.default.merge({
        relationshipMap: {},
        isCreate: false,
        copied: {}
      }, options);

      try {
        return this.deepCopyTask.unlinked().perform(compiledOptions);
      } catch (error) {
        (false && Ember.warn('deepCopyTask failed - your formOriginalModels is probably setup incorrectly - possibly related to grants 1', {
          id: 'mixins.copyable.deepCopy'
        }));
      }
    },
    deepCopyTask: (0, _emberConcurrency.task)(function* (compiledOptions) {
      try {
        var model = this.constructor;
        var modelName = model.modelName || model.typeKey;
        var id = modelName + '--' + this.id; // check if this  model has been copied already - if so then just resolve the promise

        if (compiledOptions.copied.hasOwnProperty(id)) {
          return compiledOptions.copied[id];
        }

        var copyObj;

        try {
          var validationMixin = require('securitytrax-ember-ui/validations/' + this.constructor.modelName)['default'];

          copyObj = validationMixin;
        } catch (reason) {
          copyObj = Ember.Object.extend({
            validate: () => {
              // warn('The validate function was not defined for ' + this.constructor.modelName);
              return {};
            }
          });
        }

        var copy = copyObj.create({});
        compiledOptions.copied[id] = copy;
        var promiseQueue = [];
        model.eachAttribute(attr => {
          var att = this.get(attr);

          if ((0, _objHelpers.isObject)(att)) {
            if (att != null && '_isAMomentObject' in att && att._isAMomentObject) {
              copy.set(attr, att.clone());
            } else {
              var newObject = _lodash.default.cloneDeep(att);

              copy.set(attr, newObject);
            }
          } else {
            if (att != null) {
              copy.set(attr, att);
            }
          }
        });
        copy.set('enumLists', typeof this.enumLists === 'function' ? this.enumLists() : this.enumLists);

        if (this.id) {
          copy.set('id', this.id);
        }

        if (compiledOptions.relationshipMap.relationships) {
          model.eachRelationship((relName, meta) => {
            promiseQueue.push(this.deepCopyRecurseTask.unlinked().perform(relName, meta, compiledOptions, modelName, copy));
          });
        }

        yield Ember.RSVP.all(promiseQueue);
        return copy;
      } catch (error) {
        (false && Ember.warn('deepCopyTask failed - your formOriginalModels is probably setup incorrectly - possibly related to grants 2', {
          id: 'mixins.copyable.deepCopyTask'
        }));
      }
    }),
    deepCopyRecurseTask: (0, _emberConcurrency.task)(function* (relName, meta, compiledOptions, modelName, copy) {
      try {
        var passedOptions = compiledOptions.relationshipMap.relationships[relName]; //do we have access to view this relationship data?

        var doCopy = _lodash.default.get(passedOptions, 'doCopy', true);

        var proceed = yield this.shouldProceed.unlinked().perform(compiledOptions.relationshipMap, relName, doCopy);

        if (!proceed) {
          return false;
        }

        var currRelation = yield this.get(relName);

        if (meta.kind === 'belongsTo') {
          var obj = currRelation;

          if (compiledOptions.isCreate) {
            obj = this.store.createRecord(meta.type.camelize());
          }

          if (obj) {
            if (obj.get('copyable')) {
              var objCopy = yield obj.deepCopy({
                relationshipMap: passedOptions,
                isCreate: compiledOptions.isCreate,
                copied: compiledOptions.copied,
                intlService: compiledOptions.intlService
              });
              copy.set(relName, objCopy);

              if (compiledOptions.isCreate) {
                try {
                  obj.rollbackAttributes();
                } catch (error) {
                  (false && Ember.warn('error in rollback attributes: ' + error, {
                    id: 'mixins.copyable.deepCopyRecurseTask'
                  }));
                }
              }
            } else {
              throw new Error('All things being copied must extend copyable 1');
            }
          } else {
            copy.set(relName, obj);
          }
        } else if (meta.kind === 'hasMany') {
          if (compiledOptions.isCreate) {// warn('The copy function does not support create for hasMany 1');
          }

          var array = currRelation;
          var copies = [];

          for (var x = 0; x < array.get('length'); x++) {
            var _obj = array.objectAt(x);

            if (_obj.get('copyable')) {
              copies.push(_obj.deepCopy({
                relationshipMap: passedOptions,
                isCreate: compiledOptions.isCreate,
                copied: compiledOptions.copied,
                intlService: compiledOptions.intlService
              }));
            } else {
              throw new Error('All things being copied must extend copyable 2');
            }
          }

          var theCopies = yield Ember.RSVP.all(copies);
          copy.set(relName, Ember.A([]));
          copy.get(relName).setObjects(theCopies);
        }
      } catch (error) {
        (false && Ember.warn('deepCopyRecurseTask failed - your formOriginalModels is probably setup incorrectly - possibly related to grants 3', {
          id: 'mixins.copyable.deepCopyRecurse3'
        }));
      }
    }),
    deepSync: function (options = {}) {
      var compiledOptions = _lodash.default.merge({
        relationshipMap: {},
        copiedObject: {},
        deepSync: true,
        readOnly: false,
        isCreate: false
      }, options);

      return this.deepSyncTask.unlinked().perform(compiledOptions);
    },
    deepSyncTask: (0, _emberConcurrency.task)(function* (compiledOptions) {
      try {
        if (typeof compiledOptions.copiedObject != 'object' || this.isEmpty(compiledOptions.copiedObject)) {
          return null;
        } else if (compiledOptions.readOnly) {
          return this;
        } else if (compiledOptions.copiedObject.get('id') != this.id) {
          //if we are changing the linked relationship (such as an input select) as opposed to setting and syncing individual fields in the relationship
          return compiledOptions.copiedObject;
        } else {
          var model = this.constructor;
          model.eachAttribute(attr => {
            if (attr == 'id') {
              return;
            }

            if (compiledOptions.copiedObject.get(attr) == null) {
              this.set(attr, null);
            } else {
              this.set(attr, compiledOptions.copiedObject.get(attr));
            }
          });
          var promiseQueue = [];

          if (compiledOptions.deepSync && compiledOptions.relationshipMap.relationships) {
            model.eachRelationship((relName, meta) => {
              promiseQueue.push(this.recurseSyncTask.unlinked().perform(relName, meta, compiledOptions));
            });
          }

          compiledOptions.originalOptions.onBeforeSync(compiledOptions);
          yield Ember.RSVP.all(promiseQueue);
          compiledOptions.originalOptions.onAfterSync(compiledOptions);
          return this;
        }
      } catch (err) {
        (false && Ember.warn('deepSyncTask failed - your formOriginalModels is probably setup incorrectly - possibly related to grants 3', {
          id: 'mixins.copyable.deepSync'
        }));
      }
    }),
    recurseSyncTask: (0, _emberConcurrency.task)(function* (relName, meta, compiledOptions) {
      try {
        var passedOptions = compiledOptions.relationshipMap.relationships[relName];

        var doSync = _lodash.default.get(passedOptions, 'doCopy', true); //sync not necessary if can't copy. If copied, sync necessary


        var proceed = yield this.shouldProceed.unlinked().perform(compiledOptions.relationshipMap, relName, doSync);

        if (!proceed) {
          return;
        }

        var currRelation = yield this.get(relName);

        if (meta.kind === 'belongsTo') {
          var syncedObject = this.syncSingleObjectTask.unlinked().perform(currRelation, compiledOptions.copiedObject, passedOptions, relName, compiledOptions.deepSync, compiledOptions);

          if (syncedObject) {
            return syncedObject;
          }
        } else if (meta.kind === 'hasMany') {
          return this.syncMultipleObjectsTask.unlinked().perform(compiledOptions.copiedObject, passedOptions, relName, compiledOptions.deepSync, currRelation, compiledOptions);
        } else {
          /// this code does not currently run so throwing an error if it does
          throw new Error('Sync for non promises is not turned on');
        }
      } catch (err) {
        (false && Ember.warn('recurseSyncTask failed - your formOriginalModels is probably setup incorrectly - possibly related to grants 4', {
          id: 'mixins.copyable.recurseSync'
        }));
        return;
      }
    }),
    syncMultipleObjectsTask: (0, _emberConcurrency.task)(function* (copiedObject, passedOptions, relName, deepSync, currRelation, options) {
      try {
        var newObjects = [];

        var syncRelationship = _lodash.default.get(passedOptions, 'doSave', true);

        if (copiedObject.get(relName) != null) {
          var syncedObjects = [];

          for (var x = 0; x < copiedObject.get(relName).get('length'); x++) {
            var copied = copiedObject.get(relName).objectAt(x);
            var currentHasMany = currRelation.findBy('id', copied.get('id'));

            if (currentHasMany) {
              //default to not save the relationship model attrs, just the relationship on the parent
              if (passedOptions.readOnly || !syncRelationship) {
                syncedObjects.push(currentHasMany);
              } else {
                syncedObjects.push(currentHasMany.deepSync({
                  relationshipMap: passedOptions,
                  copiedObject: copied,
                  deepSync: deepSync,
                  readOnly: options.readOnly || !syncRelationship,
                  isCreate: options.isCreate,
                  originalOptions: options.originalOptions
                }));
              }
            } else {
              if (!(copied instanceof _model.default)) {
                var obj = this.store.createRecord((0, _emberInflector.singularize)(Ember.String.dasherize(relName)));

                if (!passedOptions.readOnly || syncRelationship) {
                  obj.deepSync({
                    relationshipMap: passedOptions,
                    copiedObject: copied,
                    deepSync: deepSync,
                    readOnly: options.readOnly || !syncRelationship,
                    isCreate: options.isCreate,
                    originalOptions: options.originalOptions
                  });
                }

                copied = obj;
              }

              newObjects.push(copied);
            }
          }

          var tasks = yield (0, _emberConcurrency.hash)({
            copies: Ember.RSVP.all(syncedObjects),
            theNewObjects: Ember.RSVP.all(newObjects),
            hasManyObjects: this.get(relName)
          });
          var removedObjects = tasks.hasManyObjects.filter(object => {
            try {
              return tasks.copies.findBy('id', object.get('id')) ? false : true;
            } catch (err) {
              return false;
            }
          });
          tasks.hasManyObjects.removeObjects(removedObjects);
          tasks.hasManyObjects.addObjects(tasks.theNewObjects);
        }
      } catch (err) {
        (false && Ember.warn('syncMultipleObjectsTask failed - your formOriginalModels is probably setup incorrectly - possibly related to grants 5', {
          id: 'mixins.copyable.syncMultiple'
        }));
        (false && Ember.warn(err, {
          id: 'mixins.copyable.syncMultiple'
        }));
      }
    }),
    syncSingleObjectTask: (0, _emberConcurrency.task)(function* (obj, copiedObject, passedOptions, relName, deepSync, options) {
      if (obj) {
        if (obj.get('copyable')) {
          if (copiedObject.get(relName)) {
            var syncRelationship = _lodash.default.get(passedOptions, 'doSave', true); ////////////////////////////////////////////////////////////////////////////////////////
            // If they have this relationship set as readOnly, we will stop right here before we recurse on the sync
            // for sync we still need to set the option though (for syncing the parent object)


            if (passedOptions.readOnly || !syncRelationship) {
              if (typeof copiedObject.get(relName) != 'object' || this.isEmpty(copiedObject.get(relName))) {
                this.set(relName, null);
              } else if (copiedObject.get(relName) instanceof _model.default) {
                this.set(relName, copiedObject.get(relName));
              }
            } else {
              var syncedObj = yield obj.deepSync({
                relationshipMap: passedOptions,
                copiedObject: copiedObject.get(relName),
                deepSync: deepSync,
                readOnly: options.readOnly || !syncRelationship,
                isCreate: options.isCreate,
                originalOptions: options.originalOptions
              });
              this.set(relName, syncedObj);
            }
          } else {
            this.set(relName, null);
          }
        } else {
          throw new Error('All things being synced must extend copyable 1');
        }
      } else {
        this.set(relName, copiedObject.get(relName));
      }

      return null;
    }),
    deepSave: function (options = {}) {
      var compiledOptions = _lodash.default.merge({
        relationshipMap: {},
        copiedObject: null,
        copiedObjectBackup: null,
        deepSave: true,
        readOnly: false,
        isCreate: false,
        doSave: true
      }, options);

      return this.deepSaveTask.unlinked().perform(compiledOptions);
    },
    deepSaveTask: (0, _emberConcurrency.task)(function* (compiledOptions) {
      if (compiledOptions.readOnly) {
        return this;
      } else {
        var model = this.constructor;
        var promiseQueue = [];

        if (compiledOptions.deepSave && compiledOptions.relationshipMap.relationships) {
          model.eachRelationship((relName, meta) => {
            promiseQueue.push(this.recurseSaveTask.unlinked().perform(relName, meta, compiledOptions));
          });
        }

        try {
          yield Ember.RSVP.all(promiseQueue);
        } catch (reason) {
          try {
            yield this.rollbackByCopyDiff.unlinked().perform(compiledOptions);
          } catch (err) {
            // throw an error here because it should never happen
            throw err;
          }

          throw reason;
        }

        try {
          if (this.isDeleted) {
            // trying to save something that's deleted will somehow fake work, so gotta throw to stop that
            throw new Error(this.intl.t('common.notifications.save_deleted_error'));
          }

          if (!compiledOptions.skipTopLevelModel) {
            compiledOptions.originalOptions.onBeforeSave(compiledOptions);
            var saveResult = yield this.save();
            compiledOptions.originalOptions.onAfterSave(_lodash.default.merge({
              saved: saveResult
            }, compiledOptions));

            if (_lodash.default.get(saveResult, 'responseMeta.error_messages.length', 0) > 0) {
              this.stNotificationManager.addWarningMessages(saveResult.responseMeta.error_messages, {
                notificationNamespace: compiledOptions.successNotificationNamespace
              });
            }
          }
        } catch (reason) {
          try {
            yield this.rollbackByCopyDiff.unlinked().perform(compiledOptions);
          } catch (err) {
            // throw an error here because it should never happen
            throw err;
          }

          throw reason;
        }

        return this;
      }
    }),
    recurseSaveTask: (0, _emberConcurrency.task)(function* (relName, meta, compiledOptions) {
      ////////////////////////////////////////////////////////////////////////////////////////
      // unless told to save this relationship model, we will stop right here for deepSave
      //TODO: default should be false, but until readOnly is removed needs to be true
      var saveRelationship = _lodash.default.get(compiledOptions.relationshipMap.relationships[relName], 'doSave', true);

      var proceed = yield this.shouldProceed.unlinked().perform(compiledOptions.relationshipMap, relName, saveRelationship);

      if (!proceed) {
        return;
      }

      var copiedObjectValue = compiledOptions.copiedObject ? compiledOptions.copiedObject.get(relName) : null;
      var copiedObjectBackupValue = compiledOptions.copiedObjectBackup ? compiledOptions.copiedObjectBackup.get(relName) : null; ////////////////////////////////////////////////////////////////////////////////////////
      // If they have this relationship set as readOnly, we will stop right here for deepSave

      if (compiledOptions.relationshipMap.relationships[relName].readOnly) {
        return;
      }

      var currRelation = yield this.get(relName);
      var passedOptions = compiledOptions.relationshipMap.relationships[relName];

      if (meta.kind === 'belongsTo') {
        if (currRelation) {
          if (currRelation.get('copyable')) {
            var savedObj = yield currRelation.deepSave({
              relationshipMap: passedOptions,
              copiedObject: copiedObjectValue,
              copiedObjectBackup: copiedObjectBackupValue,
              deepSave: compiledOptions.deepSave,
              readOnly: compiledOptions.readOnly,
              skipTopLevelModel: false,
              isCreate: compiledOptions.isCreate,
              successNotificationNamespace: compiledOptions.successNotificationNamespace,
              originalOptions: compiledOptions.originalOptions
            });
            this.set(relName, savedObj);
          } else {
            throw new Error('All things being deepSaved must extend copyable 1');
          }
        }
      } else if (meta.kind === 'hasMany') {
        var resolvedSaves = [];

        for (var i = 0; i < currRelation.get('length'); i++) {
          var obj = currRelation.objectAt(i);
          resolvedSaves.push(obj.deepSave({
            relationshipMap: passedOptions,
            copiedObject: copiedObjectValue,
            copiedObjectBackup: copiedObjectBackupValue,
            deepSave: compiledOptions.deepSave,
            readOnly: compiledOptions.readOnly,
            skipTopLevelModel: false,
            isCreate: compiledOptions.isCreate,
            successNotificationNamespace: compiledOptions.successNotificationNamespace,
            originalOptions: compiledOptions.originalOptions
          }));
        }

        yield Ember.RSVP.all(resolvedSaves);
      }
    }),
    rollbackByCopyDiff: (0, _emberConcurrency.task)(function* (options) {
      // if backup is not set we can't do rollback at all
      if (options.copiedObjectBackup == null) {
        return;
      }

      if (options.copiedObject == null) {
        // backup is set, but copy is not - need to just use the backup to sync to original
        options.copiedObjectBackup.set('id', this.id);
        yield this.deepSync({
          relationshipMap: options.relationshipMap,
          copiedObject: options.copiedObjectBackup,
          originalOptions: options.originalOptions
        });
        return;
      } else {
        // need to do a diff and rollback only what was changed
        try {
          var diffToSync = Ember.Object.create({});
          this.constructor.eachAttribute(attr => {
            if (!_lodash.default.isEqual(options.copiedObject.get(attr), options.copiedObjectBackup.get(attr))) {
              if (options.copiedObjectBackup.get(attr) == null) {
                this.set(attr, null);
              } else {
                this.set(attr, options.copiedObjectBackup.get(attr));
              }
            }
          });
          var recordsToSetPromiseArr = [];
          this.constructor.eachRelationship((relName, meta) => {
            if (meta.kind == 'belongsTo') {
              if (options.copiedObject.get(relName + '.id') !== options.copiedObjectBackup.get(relName + '.id')) {
                if (options.copiedObjectBackup.get(relName + '.id') != null) {
                  recordsToSetPromiseArr.push(this.rollbackByCopyDiffRecordsToSet.unlinked().perform(meta, options, relName));
                } else {
                  diffToSync.set(relName, null);
                }
              }
            }
          });
          var recordsToSetArr = yield Ember.RSVP.all(recordsToSetPromiseArr);
          recordsToSetArr.forEach(recordsToSetData => {
            this.set(recordsToSetData.relName, recordsToSetData.record);
          });
        } catch (e) {
          (false && Ember.warn('rollback by copy failed', {
            id: 'mixins.copyable.rollback'
          }));
        }
      }
    }),
    rollbackByCopyDiffRecordsToSet: (0, _emberConcurrency.task)(function* (meta, options, relName) {
      try {
        var fulfilledRecord = yield this.store.findRecord(meta.type.camelize(), options.copiedObjectBackup.get(relName + '.id'));
        return {
          relName: relName,
          record: fulfilledRecord
        };
      } catch (err) {
        throw {
          relName: relName,
          err: err
        };
      }
    }),
    deepValidate: function (toValidate, extraOptions = {}) {
      var compiledOptions = _lodash.default.merge({
        relationshipMap: {},
        deepValidate: true,
        readOnly: false,
        isCreate: false,
        extraValidationData: {}
      }, extraOptions);

      return this.deepValidateTask.unlinked().perform(toValidate, compiledOptions);
    },
    deepValidateTask: (0, _emberConcurrency.task)(function* (toValidate, compiledOptions) {
      if (compiledOptions.readOnly) {
        return [];
      } else {
        var model = this.constructor;
        var promiseQueue = [];

        if (compiledOptions.deepValidate && compiledOptions.relationshipMap.relationships) {
          model.eachRelationship((relName, meta) => {
            promiseQueue.push(this.deepValidateRecurseTask.unlinked().perform(toValidate, relName, meta, compiledOptions));
          });
        }

        compiledOptions.originalOptions.onBeforeValidate(compiledOptions);
        var childrenData = yield Ember.RSVP.all(promiseQueue);
        var currModelErrorsArr = !compiledOptions.skipTopLevelModel ? yield Ember.RSVP.Promise.resolve(toValidate.validate(compiledOptions)) : {};

        if (currModelErrorsArr && 'validations' in currModelErrorsArr) {
          (false && Ember.warn('You tried to use old validations in deepValidate - which is not supported', {
            id: 'mixins.copyable.deepValidate'
          }));
          currModelErrorsArr = [];
        }

        if (childrenData.length > 0) {
          childrenData.forEach(currChild => {
            if (Array.isArray(currChild)) {
              // for hasMany children
              currChild.forEach(currChildChild => {
                Object.assign(currModelErrorsArr, currChildChild);
              });
            } else {
              // for belongs to children
              Object.assign(currModelErrorsArr, currChild);
            }
          });
        }

        compiledOptions.originalOptions.onAfterValidate(compiledOptions);
        return currModelErrorsArr;
      }
    }),
    deepValidateRecurseTask: (0, _emberConcurrency.task)(function* (toValidate, relName, meta, compiledOptions) {
      //TODO: default should be false, but until readOnly functionality removed default to true
      var validateRelationship = _lodash.default.get(compiledOptions.relationshipMap.relationships[relName], 'doValidate', true);

      var proceed = yield this.shouldProceed.unlinked().perform(compiledOptions.relationshipMap, relName, validateRelationship);

      if (!proceed) {
        return;
      } ////////////////////////////////////////////////////////////////////////////////////////
      // If they have this relationship set as readOnly, we will stop right here for deepValidate


      if (compiledOptions.relationshipMap.relationships[relName].readOnly) {
        return;
      }

      var passedOptions = compiledOptions.relationshipMap.relationships[relName];
      var currRelation = yield this.get(relName);

      if (meta.kind === 'belongsTo') {
        if (compiledOptions.isCreate) {
          currRelation = this.store.createRecord(meta.type.camelize());
        }

        if (currRelation) {
          if (currRelation.get('copyable')) {
            var toValidateRel = toValidate.get(relName);

            if (toValidateRel) {
              var deepValPromise = currRelation.deepValidate(toValidateRel, {
                relationshipMap: passedOptions,
                deepValidate: compiledOptions.deepValidate,
                readOnly: compiledOptions.readOnly,
                skipTopLevelModel: false,
                isCreate: compiledOptions.isCreate,
                extraValidationData: compiledOptions.extraValidationData,
                intlService: this.intl,
                originalOptions: compiledOptions.originalOptions
              });
              deepValPromise.then(() => {
                if (compiledOptions.isCreate) {
                  currRelation.rollbackAttributes();
                }
              });
              return deepValPromise;
            }
          } else {
            throw new Error('All things being deepValidated must extend copyable 1');
          }
        } else {
          return false; // means nothing to validate because relation was disconnected
        }
      } else if (meta.kind === 'hasMany') {
        var relatedModels = toValidate.get(relName);
        var resolvedValidates = [];

        for (var q = 0; q < currRelation.get('length'); q++) {
          var obj = currRelation.objectAt(q);

          var _toValidateRel = relatedModels.findBy('id', obj.get('id'));

          if (_toValidateRel) {
            resolvedValidates.push(obj.deepValidate(_toValidateRel, {
              relationshipMap: passedOptions,
              deepValidate: compiledOptions.deepValidate,
              readOnly: compiledOptions.readOnly,
              skipTopLevelModel: false,
              isCreate: compiledOptions.isCreate,
              extraValidationData: compiledOptions.extraValidationData,
              intlService: this.intl,
              originalOptions: compiledOptions.originalOptions
            }));
          }
        }

        return Ember.RSVP.all(resolvedValidates);
      }
    }),
    deepCreate: function (copiedModel, copiedModelBackup, extraOptions) {
      var compiledOptions = _lodash.default.merge({
        deepCreate: true,
        notificationNamespace: 'global',
        deepSaveFailNotificationMessage: '',
        relationshipMap: {}
      }, extraOptions);

      return this.deepCreateTask.unlinked().perform(copiedModel, copiedModelBackup, compiledOptions);
    },
    deepCreateTask: (0, _emberConcurrency.task)(function* (copiedModel, copiedModelBackup, compiledOptions) {
      var model = this.constructor;
      var promiseQueue = [];

      if (compiledOptions.deepCreate && compiledOptions.relationshipMap.relationships) {
        model.eachRelationship((relName, meta) => {
          promiseQueue.push(this.deepCreateRecurseTask.unlinked().perform(copiedModel, copiedModelBackup, relName, meta, compiledOptions));
        });
      }

      var childrenData;

      try {
        childrenData = yield Ember.RSVP.all(promiseQueue);
      } catch (reason) {
        throw reason;
      }

      var currModelCreatedObj = {};
      currModelCreatedObj[this.constructor.modelName.camelize()] = {
        data: copiedModel,
        // jQuery.extend(true, {}, copiedModel), //this.objectToJson(copiedModel),
        saveWithRollbackAndNotification: (toSave, notificationNamespace) => {
          compiledOptions.notificationNamespace = notificationNamespace ? notificationNamespace : compiledOptions.notificationNamespace;
          return this.saveWithRollbackAndNotificationTask.unlinked().perform(toSave, compiledOptions);
        }
      };

      if (childrenData.length > 0) {
        childrenData.forEach(currChild => {
          Object.assign(currModelCreatedObj, currChild);
        });
      }

      return currModelCreatedObj;
    }),
    saveWithRollbackAndNotificationTask: (0, _emberConcurrency.task)(function* (toSave, compiledOptions) {
      try {
        compiledOptions.onBeforeSave(compiledOptions);
        var wasSaved = yield toSave.save();
        compiledOptions.onAfterSave(_lodash.default.merge({
          saved: wasSaved
        }, compiledOptions));
        return wasSaved;
      } catch (error) {
        this.stNotificationManager.addErrorMessages(error, {
          fallbackMessage: compiledOptions.deepSaveFailNotificationMessage,
          notificationNamespace: compiledOptions.notificationNamespace
        });
        toSave.rollbackAttributes();
        throw error;
      }
    }),
    deepCreateRecurseTask: (0, _emberConcurrency.task)(function* (copiedModel, copiedModelBackup, relName, meta, compiledOptions) {
      ////////////////////////////////////////////////////////////////////////////////////////
      // unless told to create this relationship model, we will stop right here for deepCreate
      //TODO: should default to false, but until readOnly functionality removed default to true
      var saveRelationship = _lodash.default.get(compiledOptions.relationshipMap.relationships[relName], 'doSave', true);

      var proceed = yield this.shouldProceed.unlinked().perform(compiledOptions.relationshipMap, relName, saveRelationship);

      if (!proceed) {
        return;
      }

      var newRelationshipMap = compiledOptions.relationshipMap.relationships[relName];
      yield this.get(relName);

      if (meta.kind === 'belongsTo') {
        var obj = this.store.createRecord(meta.type.camelize(), this.objectToJson(copiedModel));

        if (obj.get('copyable')) {
          var toCreateRel = copiedModel.get(relName);
          var toCreateRelBackup = copiedModelBackup.get(relName);
          var deepCreatePromise = obj.deepCreate(toCreateRel, toCreateRelBackup, {
            deepCreate: compiledOptions.deepCreate,
            notificationNamespace: compiledOptions.notificationNamespace,
            deepSaveFailNotificationMessage: compiledOptions.deepSaveFailNotificationMessage,
            relationshipMap: newRelationshipMap,
            onAfterSave: compiledOptions.onAfterSave,
            onBeforeSave: compiledOptions.onBeforeSave,
            onBeforeValidate: compiledOptions.onBeforeValidate,
            onAfterValidate: compiledOptions.onAfterValidate,
            onBeforeSync: compiledOptions.onBeforeSync,
            onAfterSync: compiledOptions.onAfterSync
          });
          deepCreatePromise.then(() => {
            if (relName != 'calendarEventDetailType') {
              // DREW TODO - this is a hack, but need to talk to Travis about it
              obj.rollbackAttributes();
            }
          });
          return deepCreatePromise;
        } else {
          throw new Error('All things being deepCreated must extend copyable ');
        }
      }
    }),
    shouldProceed: (0, _emberConcurrency.task)(function* (relationshipMap, relName, hasGrant = true) {
      if (!relationshipMap.relationships[relName] || !hasGrant) {
        return false;
      } ////////////////////////////////////////////////////////////////////////////////////////
      // check to see that the passed in data is actually a relation on the model
      // also just grabbing the relation


      try {
        yield this.get(relName);
      } catch (err) {
        (false && Ember.warn('Proceed failed - your formOriginalModels is probably setup incorrectly - possibly related to grants 10', {
          id: 'mixins.copyable.shouldProceed'
        }));
        return false;
      }

      return true;
    }),
    destroyRecordWithRollbackTask: (0, _emberConcurrency.task)(function* () {
      try {
        var wasDestroyed = yield this.destroyRecord();
        return wasDestroyed;
      } catch (error) {
        this.rollbackAttributes();
        throw error;
      }
    }),

    isEmpty(obj) {
      if (typeof obj != 'object') {
        return false;
      }

      for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
      }

      return true;
    },

    objectToJson(object) {
      // console.log('objectToJson', object);
      // return $.extend(true, {}, object);
      return object; // const result = {};
      // for (const key in $.extend(true, {}, object)) {
      //     // Skip these
      //     if (key === 'isInstance' ||
      //         key === 'isDestroyed' ||
      //         key === 'mergedProperties' ||
      //         key === 'isDestroying' ||
      //         key === 'concatenatedProperties' ||
      //         typeof this[key] === 'function')
      //     {
      //         continue;
      //     }
      //     result[key] = object[key];
      // }
      // return result;
    }

  });

  _exports.default = _default;
});