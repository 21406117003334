define("securitytrax-ember-ui/models/base_models/parent-entity", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    fname: (0, _model.attr)('string', {
      'readOnly': false
    }),
    lname: (0, _model.attr)('string', {
      'readOnly': false
    }),
    phone1: (0, _model.attr)('string', {
      'readOnly': false
    }),
    phone2: (0, _model.attr)('string', {
      'readOnly': false
    }),
    phone3: (0, _model.attr)('string', {
      'readOnly': false
    }),
    phone4: (0, _model.attr)('string', {
      'readOnly': false
    }),
    address1: (0, _model.attr)('string', {
      'readOnly': false
    }),
    address2: (0, _model.attr)('string', {
      'readOnly': false
    }),
    city: (0, _model.attr)('string', {
      'readOnly': false
    }),
    state: (0, _model.attr)('string', {
      'readOnly': false
    }),
    zip: (0, _model.attr)('string', {
      'readOnly': false
    }),
    repId: (0, _model.attr)('number', {
      'readOnly': false
    }),
    status: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['active', 'deleted']
    }),
    contactTime: (0, _model.attr)('string', {
      'readOnly': false
    }),
    employeeIdentificationNumber: (0, _model.attr)('string', {
      'readOnly': false
    }),
    email: (0, _model.attr)('string', {
      'readOnly': false
    }),
    businessName: (0, _model.attr)('string', {
      'readOnly': false
    }),
    businessContact: (0, _model.attr)('string', {
      'readOnly': false
    }),
    accountType: (0, _model.attr)('string', {
      'readOnly': false
    }),
    locationId: (0, _model.attr)('number', {
      'readOnly': false
    }),
    timezone: (0, _model.attr)('string', {
      'readOnly': false
    }),
    latitude: (0, _model.attr)('number', {
      'readOnly': false
    }),
    longitude: (0, _model.attr)('number', {
      'readOnly': false
    }),
    parentEntityBillingDefaultId: (0, _model.attr)('number', {
      'readOnly': false
    }),
    lang: (0, _model.attr)('string', {
      'readOnly': false
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    customer: (0, _model.hasMany)('customer', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    parentEntityInvoiceStatements: (0, _model.hasMany)('parent-entity-invoice-statement', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});