define("securitytrax-ember-ui/components/customer/emergency-contact-list/component", ["exports", "@glimmer/component", "ember-concurrency", "securitytrax-ember-ui/utils/obj-helpers", "securitytrax-ember-ui/mixed-in-classes/emergency-contacts-mixin"], function (_exports, _component, _emberConcurrency, _objHelpers, _emergencyContactsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EmergencyContactList = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service('events/transitionEvent'), _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = (0, _emberConcurrency.task)(function* () {
    var customer = yield this.args.customer;
    var customerContacts = this.grants.getIndividualGrant('customer_contacts.view') ? yield customer.get('customerContacts') : [];
    this.emergencyContactsClass.contactsFormStarted = false;
    this.customerContactsList = customerContacts.sortBy('sort');
  }), _dec9 = (0, _emberConcurrency.task)(function* (buttonAnimationPromise) {
    var customer = this.args.customer;
    var customerContacts = customer.get('customerContacts').sortBy('sort');
    var savedContacts = [];
    customerContacts.forEach((item, index) => {
      item.set('sort', index + 1);
      savedContacts.push(item.save());
    });
    yield (0, _emberConcurrency.all)(savedContacts);
    buttonAnimationPromise.then(() => {
      this.sortingMode = false;
    });
    return true;
  }), _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, (_class = (_temp = class EmergencyContactList extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "grants", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "transitionEvent", _descriptor5, this);

      _defineProperty(this, "header", 'pages.customers.emergency_contacts.title');

      _initializerDefineProperty(this, "customerContactsList", _descriptor6, this);

      _initializerDefineProperty(this, "sortingMode", _descriptor7, this);

      _defineProperty(this, "titleClass", 'st-card__divider-cell st-card__divider-title-cell');

      _initializerDefineProperty(this, "initFormTask", _descriptor8, this);

      _initializerDefineProperty(this, "saveSortingChangesTask", _descriptor9, this);

      this.emergencyContactsClass = new _emergencyContactsMixin.default(...arguments);

      if (this.args.isASG) {
        this.titleClass = 'st-card__divider-title-cell';
      }

      (0, _objHelpers.initializeState)(this, {
        sortingMode: false
      });
      window.addEventListener('touchmove', function () {
        return;
      }, {
        passive: false
      });
      this.transitionEvent.on('refresh-customer-contacts', this, '_refreshEventCallback');
      this.initFormTask.perform();
    }

    willDestroy() {
      this.transitionEvent.off('refresh-customer-contacts', this, '_refreshEventCallback');
      window.removeEventListener('touchmove', function () {
        return;
      });
    }

    _refreshEventCallback() {
      return this.initFormTask.perform();
    }

    get customerContactSortingClass() {
      if (this.sortingMode === true) {
        return 'actionable-row pointer';
      } else {
        return '';
      }
    }

    sortEndAction() {
      var customerContacts = this.customerContactsList;
      customerContacts.forEach((item, index) => {
        item.set('sort', index + 1);
      });
    }

    initSortMode() {
      var customerContacts = this.args.customer.get('customerContacts').sortBy('sort');
      customerContacts.forEach(item => {
        item.set('originalIndex', item.get('sort'));
        item.set('expanded', false);
      });
      this.sortingMode = true;
    }

    cancelSort() {
      var customerContacts = this.args.customer.get('customerContacts').sortBy('originalIndex');
      customerContacts.forEach(item => {
        item.set('sort', item.get('originalIndex'));
      });
      this.customerContactsList = customerContacts;
      this.sortingMode = false;
    }

    sortingComplete() {}

    saveSortingChanges(buttonAnimationPromise) {
      return this.saveSortingChangesTask.perform(buttonAnimationPromise);
    }

    expandToggle(row) {
      if (row.get('expanded')) {
        row.set('expanded', false);
      } else {
        row.set('expanded', true);
      }
    }

    refreshCustomerContacts() {
      return this.initFormTask.perform();
    }

    startAddEdit(path) {
      if (this.args.isASG) {
        this.emergencyContactsClass.customerContact = path === 'create' ? null : path;
        this.emergencyContactsClass.contactsFormMode = path === 'create' ? 'create' : this.grants.getIndividualGrant('customer_contacts.modify') ? 'edit' : 'view';
        this.emergencyContactsClass.contactsFormStarted = this.sortingMode ? false : true;
      } else {
        this.router.transitionTo(this.router.currentRoute.parent.name + '.edit-create-customer-contact', path.id || path);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "transitionEvent", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "customerContactsList", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "sortingMode", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "initFormTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "saveSortingChangesTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "sortEndAction", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "sortEndAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initSortMode", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "initSortMode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelSort", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "cancelSort"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortingComplete", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "sortingComplete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveSortingChanges", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "saveSortingChanges"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "expandToggle", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "expandToggle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refreshCustomerContacts", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "refreshCustomerContacts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startAddEdit", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "startAddEdit"), _class.prototype)), _class));
  _exports.default = EmergencyContactList;
});