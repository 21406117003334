define("securitytrax-ember-ui/components/multi-step-form/forms/administration/content-management/edit-work-order-template/component", ["exports", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "ember-concurrency", "lodash"], function (_exports, _component, _switchableFormStep, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EditWorkOrderTemplate = (_dec = Ember.inject.service('intl'), _dec2 = Ember.inject.service('userService'), _dec3 = Ember.inject.service('stNotificationManager'), _dec4 = Ember.inject.service('store'), _dec5 = Ember.inject.service('grants'), _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = (0, _emberConcurrency.task)(function* () {
    var multiStepForm = this.args.multiStepForm;
    yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);
    var tempFieldDefaults = yield multiStepForm.formCopiedModelsGetVal('template.model.templateFieldDefaults').sortBy('templateTypeColumn.id');

    var fieldDefaults = _lodash.default.sortBy(tempFieldDefaults, [function (o) {
      return Number(o.get('templateTypeColumn.id'));
    }]);

    for (var i = 0; i <= this.args.templateTypeColumns.length - 1; i++) {
      var templateDefault = yield fieldDefaults.objectAt(i);
      var templateTypeColumn = templateDefault ? yield templateDefault.get('templateTypeColumn') : null;
      var expectedColumn = this.args.templateTypeColumns.objectAt(i);

      if (templateTypeColumn === null || templateTypeColumn.get('id') !== expectedColumn.get('id')) {
        fieldDefaults.splice(i, 0, Ember.Object.create({
          value: null,
          active: true,
          templateTypeColumn: expectedColumn
        }));
      }
    }

    if (this.SwitchableFormStep.isNewRecord) {
      fieldDefaults.splice(0, 1, {
        value: '2',
        active: true,
        templateTypeColumn: this.args.templateTypeColumns.findBy('columnName', 'note_type')
      });
      multiStepForm.formCopiedModelsSetVal('template.model.active', true);
    }

    var assignedOptions = yield this.getOptionsByColumnNameTask.perform('assigned_user');
    var users = (yield this.userService.getUsersList('work_order_assignee')).sortBy('name');
    assignedOptions[assignedOptions.length - 1]['header'] = true;
    this.fieldDefaults = fieldDefaults;
    this.assignedToOptions = assignedOptions.concat(users.toArray());
    this.equipmentOptions = yield this.getOptionsByColumnNameTask.perform('equipment');
  }), _dec12 = Ember._action, _dec13 = (0, _emberConcurrency.task)(function* (columnName) {
    var templateTypeColumn = yield this.args.templateTypeColumns.findBy('columnName', columnName);
    var options = Ember.Object.create(templateTypeColumn ? templateTypeColumn.get('options') : []);
    var returnOptions = [];
    var values = Object.values(options);
    Object.keys(options).forEach((key, index) => {
      returnOptions.push(Ember.Object.create({
        name: key,
        id: values[index]
      }));
    });
    return returnOptions;
  }), _dec14 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    if (this.SwitchableFormStep.isNewRecord) {
      var templateFieldDefaults = this.fieldDefaults;
      var formattedObjects = [];
      templateFieldDefaults.forEach(item => {
        if (item.value) {
          formattedObjects.push(Ember.Object.create({
            modelName: 'templateFieldDefault',
            model: item
          }));
        }
      });
      formattedObjects.push(Ember.Object.create({
        modelName: 'template',
        model: multiStepForm.formCopiedModelsGetVal('template.model')
      }));
      var toCreateObjects = yield multiStepForm.validateCreateList.perform(formattedObjects, {
        notificationNamespace: 'modal'
      });
      toCreateObjects.template[0].data.templateType = yield this.args.templateTypes.findBy('id', '1');
      var workOrderTemplate = this.store.createRecord('template', toCreateObjects.template[0].data);
      var createdWorkOrderTemplate = yield toCreateObjects.template[0].saveWithRollbackAndNotification(workOrderTemplate, 'modal');

      if (toCreateObjects.templateFieldDefault) {
        toCreateObjects.templateFieldDefault.forEach(item => {
          var newFieldDefault = this.store.createRecord('templateFieldDefault', {
            value: item.data.value,
            templateTypeColumn: item.data.templateTypeColumn,
            template: createdWorkOrderTemplate
          });
          toCreateObjects.template[0].saveWithRollbackAndNotification(newFieldDefault, 'modal');
        });
      }
    } else {
      var fieldDefaults = [];
      this.fieldDefaults.forEach(fieldDefault => {
        fieldDefaults.push(fieldDefault);
      });
      multiStepForm.formCopiedModelsSetVal('template.model.templateFieldDefaults', fieldDefaults);
      yield multiStepForm.validateSyncSaveAll({
        notificationNamespace: 'modal'
      });
    }

    this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
    buttonPromise.then(() => {
      multiStepForm.formStartedSetVal(false);
    });
  }), _dec15 = (0, _emberConcurrency.task)(function* () {
    var workOrderTemplate = this.args.multiStepForm.formOriginalModelsGetVal('template.model');

    try {
      yield workOrderTemplate.destroyRecordWithRollbackTask.perform();
      this.args.multiStepForm.onDelete();
      this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
      this.args.multiStepForm.formStartedSetVal(false);
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'confirmDeleteModal'
      });
      throw err;
    }
  }), (_class = (_temp = class EditWorkOrderTemplate extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "userService", _descriptor2, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "grants", _descriptor5, this);

      _initializerDefineProperty(this, "confirmDeleteModalOpen", _descriptor6, this);

      _initializerDefineProperty(this, "loaded", _descriptor7, this);

      _initializerDefineProperty(this, "equipmentOptions", _descriptor8, this);

      _initializerDefineProperty(this, "assignedToOptions", _descriptor9, this);

      _initializerDefineProperty(this, "fieldDefaults", _descriptor10, this);

      _initializerDefineProperty(this, "initFormData", _descriptor11, this);

      _initializerDefineProperty(this, "getOptionsByColumnNameTask", _descriptor12, this);

      _initializerDefineProperty(this, "saveWorkOrderTemplate", _descriptor13, this);

      _initializerDefineProperty(this, "deleteWorkOrderTemplate", _descriptor14, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.initFormData.perform();
    }

    renderButtons() {
      var multiStepForm = this.args.multiStepForm;
      var buttons = [];

      if (this.args.formMode !== 'view') {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveWorkOrderTemplate.perform(multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary work-order-template-save-button'
        });
      }

      if (!this.SwitchableFormStep.isNewRecord && this.grants.getIndividualGrant('templates.delete')) {
        buttons.push({
          text: this.intl.t('common.actions.delete'),
          click: () => {
            this.confirmDeleteModalOpen = true;
          },
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
        });
      }

      multiStepForm.registerStepData('step1', 'buttons', buttons);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "userService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "confirmDeleteModalOpen", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "loaded", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "equipmentOptions", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "assignedToOptions", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "fieldDefaults", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "initFormData", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "renderButtons", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "renderButtons"), _class.prototype), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "getOptionsByColumnNameTask", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "saveWorkOrderTemplate", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "deleteWorkOrderTemplate", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = EditWorkOrderTemplate;
});