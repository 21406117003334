define("securitytrax-ember-ui/components/multi-step-form/forms/system/telus/create-account/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A3x6meNq",
    "block": "{\"symbols\":[\"data\",\"@customer\",\"@formMode\"],\"statements\":[[11,\"div\"],[4,[38,0],[[32,0,[\"renderButtons\"]]],null],[12],[2,\"\\n    \"],[8,\"html/st-await\",[],[[\"@useSpinner\",\"@booleanLoaded\",\"@size\"],[true,[32,0,[\"initData\",\"isIdle\"]],\"large\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"html/section-divider\",[],[[\"@title\"],[[30,[36,1],[\"pages.customers.system.interactiveServicesProvider.customerInformation.title\"],null]]],null],[2,\"\\n        \"],[10,\"div\"],[14,0,\"grid-x grid-padding-x align-stretch\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"cell small-12 medium-6 large-3\"],[12],[2,\"\\n                \"],[8,\"multi-step-form/switchable-inputs/text-input\",[],[[\"@formMode\",\"@value\",\"@label\"],[\"view\",[30,[36,3],[[30,[36,2],[[32,2,[\"accountType\"]],\"Residential\"],null],[32,2,[\"flname\"]],[32,2,[\"businessName\"]]],null],[30,[36,1],[\"common.columns.name\"],null]]],null],[2,\"\\n            \"],[13],[2,\"\\n\\n            \"],[10,\"div\"],[14,0,\"cell small-12 medium-6 large-3\"],[12],[2,\"\\n                \"],[8,\"multi-step-form/switchable-inputs/select-input\",[],[[\"@options\",\"@formMode\",\"@value\",\"@label\",\"@allowClear\"],[[32,0,[\"dealerTypes\"]],[32,3],[32,0,[\"dealerType\"]],[30,[36,1],[\"pages.customers.system.telus.general.dealer_type\"],null],false]],null],[2,\"\\n            \"],[13],[2,\"\\n\\n            \"],[10,\"div\"],[14,0,\"cell small-12 medium-6 large-3\"],[12],[2,\"\\n                \"],[8,\"multi-step-form/switchable-inputs/text-input\",[],[[\"@formMode\",\"@value\",\"@label\"],[\"view\",[32,2,[\"accountType\"]],[30,[36,1],[\"pages.customers.system.telus.general.subscriber_type\"],null]]],null],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"t\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/system/telus/create-account/template.hbs"
    }
  });

  _exports.default = _default;
});