define("securitytrax-ember-ui/helpers/load-relationship-with-query-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    stQueryParams: Ember.inject.service('st-query-params'),

    compute(args) {
      var [data] = args;
      var callbackFunction = this.stQueryParams.loadRelationshipWithQueryParams(data);
      return callbackFunction;
    }

  });

  _exports.default = _default;
});