define("securitytrax-ember-ui/models/agency-contact", ["exports", "securitytrax-ember-ui/models/base_models/agency-contact"], function (_exports, _agencyContact) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _agencyContact.default.extend({});

  _exports.default = _default;
});