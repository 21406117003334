define("securitytrax-ember-ui/customers/information/manage-alula/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-concurrency", "securitytrax-ember-ui/mixins/subroute-modal"], function (_exports, _authenticatedRouteMixin, _emberConcurrency, _subrouteModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _subrouteModal.default, {
    store: Ember.inject.service(),
    grants: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      var customerId = this.paramsFor('customers').id;
      return this.findGrantsTask.perform(['customer_system_information.view', 'customers.manage_interactive_services_provider_integration'], 'all', '/customers/' + customerId + '/information');
    },

    model() {
      return this.setupModelTask.perform();
    },

    setupModelTask: (0, _emberConcurrency.task)(function* () {
      var parentModel = yield this.modelFor(this.parentRoute);
      var grants = this.grants;
      var interactiveServicesProvider;

      try {
        var orderInfoGrant = grants.getIndividualGrant('customer_order_information.view');
        var customer = parentModel.customer;

        if (orderInfoGrant) {
          try {
            var customerOrderInfo = yield this.store.findRecord('customerOrderInformation', customer.get('id'));
            interactiveServicesProvider = yield customerOrderInfo.get('interactiveServicesProvider');
          } catch (e) {//
          }
        }
      } catch (error) {
        (false && Ember.warn('failed to get interactive services provider' + error, {
          id: 'customers.information.manageAlula.setupModelTask'
        }));
      }

      return (0, _emberConcurrency.hash)({
        customer: parentModel.customer,
        customerSystemInformation: parentModel.customerSystemInformation,
        customerOrderInformation: parentModel.customerOrderInformation,
        interactiveServicesProvider: interactiveServicesProvider,
        parentRoute: this.transitionRoute,
        transitionId: parentModel.customer.get('id')
      });
    })
  });

  _exports.default = _default;
});