define("securitytrax-ember-ui/components/nav/top-nav/primary-nav-sub/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    screen: Ember.inject.service(),
    tagName: '',
    side: 'bottom'
  });

  _exports.default = _default;
});