define("securitytrax-ember-ui/components/multi-step-form/switchable-inputs/textarea-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0nKaoaKg",
    "block": "{\"symbols\":[\"withHash\"],\"statements\":[[6,[37,21],null,[[\"label\",\"required\",\"errors\"],[[35,20],[35,19],[35,18]]],[[\"default\"],[{\"statements\":[[6,[37,17],[[30,[36,16],null,[[\"inline-textarea-input\"],[[30,[36,15],[\"forms/inline-edit\"],[[\"value\",\"onSave\",\"field\"],[[35,11],[35,2],\"textarea\"]]]]]]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[35,14]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,12],null,[[\"value\",\"type\",\"name\",\"disabled\",\"readonly\",\"cols\",\"rows\",\"wrap\",\"spellcheck\"],[[35,11],[35,10],[35,9],true,true,[35,6],[35,5],[35,4],[35,3]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[35,13]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,12],null,[[\"value\",\"type\",\"name\",\"disabled\",\"readonly\",\"cols\",\"rows\",\"wrap\",\"spellcheck\",\"onChange\"],[[35,11],[35,10],[35,9],[35,8],[35,7],[35,6],[35,5],[35,4],[35,3],[35,2]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[32,1,[\"inline-textarea-input\"]]],[2,\"\\n        \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"showInlineEdit\",\"if\",\"onChange\",\"spellcheck\",\"wrap\",\"rows\",\"cols\",\"readonly\",\"disabled\",\"name\",\"type\",\"value\",\"textarea\",\"showEdit\",\"showView\",\"component\",\"hash\",\"with\",\"fieldErrors\",\"required\",\"label\",\"html/input-wrapper\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/switchable-inputs/textarea-input/template.hbs"
    }
  });

  _exports.default = _default;
});