define("securitytrax-ember-ui/components/multi-step-form/forms/administration/equipment/edit-package/component", ["exports", "ember-concurrency", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "securitytrax-ember-ui/mixed-in-classes/row-list"], function (_exports, _emberConcurrency, _component, _switchableFormStep, _rowList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EditPackage = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
    var lineItems = yield this.args.multiStepForm.formCopiedModelsGetVal('package.model.packageEquipment');
    this.RowList.listArray = lineItems;
    this.RowList.addRow();
  }), _dec8 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    var formattedPackageEquipmentObjects = this.RowList.prepForValidate('packageEquipment');
    formattedPackageEquipmentObjects.pushObject(Ember.Object.create({
      modelName: 'package',
      model: multiStepForm.formCopiedModels.package.model
    }));

    if (formattedPackageEquipmentObjects.length === 1) {
      //Package has no equipment, so stop save
      this.stNotificationManager.addErrorMessages(this.intl.t('common.validations.packageNeedsEquipment'), {
        notificationNamespace: 'modal'
      });
      throw new Error();
    }

    try {
      if (this.SwitchableFormStep.isNewRecord) {
        //validate line items first, then set copied model with ember data
        var packageEquipment = [];
        var toCreateObjects = yield multiStepForm.validateCreateList.perform(formattedPackageEquipmentObjects, {
          notificationNamespace: 'modal'
        });
        toCreateObjects.packageEquipment.forEach(item => {
          var newPackageEquipmentItem = this.store.createRecord('packageEquipment', {
            itemCharge: item.data.itemCharge || 0,
            quantity: item.data.quantity || 0,
            equipment: item.data.equipment,
            equipmentWireType: item.data.equipmentWireType
          });
          packageEquipment.push(newPackageEquipmentItem);
        });
        var newPackage = this.store.createRecord('package', {
          name: toCreateObjects.package[0].data.name,
          description: toCreateObjects.package[0].data.description,
          packageEquipment
        });
        yield toCreateObjects.package[0].saveWithRollbackAndNotification(newPackage, 'modal');
      } else {
        var _packageEquipment = [];
        yield multiStepForm.validateCreateList.perform(formattedPackageEquipmentObjects, {
          notificationNamespace: 'modal'
        });
        this.RowList.trueListArray.forEach(item => {
          if (!item.get('id')) {
            var newPackageEquipmentItem = this.store.createRecord('packageEquipment', {
              itemCharge: item.itemCharge || 0,
              quantity: item.quantity || 0,
              equipment: item.equipment,
              equipmentWireType: item.equipmentWireType
            });

            _packageEquipment.push(newPackageEquipmentItem);
          } else {
            _packageEquipment.push(item);
          }
        });
        var options = {
          relationships: {
            packageEquipment: {
              relationships: {
                equipment: {},
                equipmentWireType: {}
              }
            }
          }
        };
        Ember.set(multiStepForm, 'formCopiedModels.package.model.packageEquipment', _packageEquipment);
        var pkg = yield multiStepForm.formOriginalModelsGetVal('package.model').deepSync({
          relationshipMap: options,
          copiedObject: multiStepForm.formCopiedModelsGetVal('package.model'),
          originalOptions: {
            onBeforeSync: multiStepForm.onBeforeSync,
            onAfterSync: multiStepForm.onAfterSync
          }
        });
        yield pkg.save();
        multiStepForm.onAfterSave();
      }
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }

    this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
    buttonPromise.then(() => {
      multiStepForm.formStartedSetVal(false);
    });
  }), _dec9 = (0, _emberConcurrency.task)(function* (row) {
    yield this.RowList.deleteRow.perform(row, 'packageEquipment', true);
    this.packageEquipmentDeleteId = false;
    this.args.multiStepForm.onDelete();
    this.args.multiStepForm.formCopiedModelsSetVal('package.model.packageEquipment', this.RowList.listArray);
  }), _dec10 = (0, _emberConcurrency.task)(function* () {
    var packageToDelete = this.args.multiStepForm.formOriginalModelsGetVal('package.model');

    try {
      yield packageToDelete.destroyRecordWithRollbackTask.perform();
      this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
      this.args.multiStepForm.onDelete();
      this.args.multiStepForm.formStartedSetVal(false);
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'confirmDeleteModal'
      });
      throw err;
    }
  }), _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, (_class = (_temp = class EditPackage extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "grants", _descriptor4, this);

      _initializerDefineProperty(this, "confirmDeleteModalOpen", _descriptor5, this);

      _initializerDefineProperty(this, "packageEquipmentDeleteId", _descriptor6, this);

      _initializerDefineProperty(this, "initFormData", _descriptor7, this);

      _initializerDefineProperty(this, "saveEquipmentPackage", _descriptor8, this);

      _initializerDefineProperty(this, "deletePackageEquipmentTask", _descriptor9, this);

      _initializerDefineProperty(this, "deletePackage", _descriptor10, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.RowList = new _rowList.default(...arguments);
      this.initFormData.perform();
    }

    get totalQuantity() {
      var lineItems = this.RowList.listArray;
      var totalQuantity = lineItems.reduce((newTotalQuantity, equipmentItem) => {
        return newTotalQuantity += +(equipmentItem.get('quantity') || 0);
      }, 0);
      return totalQuantity;
    }

    get totalCharge() {
      var lineItems = this.RowList.listArray;
      var totalCharge = lineItems.reduce((newTotalCharge, equipmentItem) => {
        return newTotalCharge += +(equipmentItem.get('itemCharge') * +equipmentItem.get('quantity')) || 0;
      }, 0);
      return parseFloat(totalCharge).toFixed(2);
    }

    deletePackageEquipment(row) {
      this.deletePackageEquipmentTask.perform(row);
    }

    updateEquipmentItemsList(index) {
      var lineItems = this.RowList.listArray;
      var lineItem = lineItems[index];
      lineItem.set('itemCharge', lineItem.get('equipment.value'));
      lineItem.set('quantity', 0);
      this.RowList.updateRow(lineItem, index);
    }

    buttons() {
      var buttons = [];

      if (this.formMode !== 'view') {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveEquipmentPackage.perform(this.args.multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary package-save-button'
        });
      }

      if (!this.SwitchableFormStep.isNewRecord && this.grants.getIndividualGrant('packages.delete')) {
        buttons.push({
          text: this.intl.t('common.actions.delete'),
          click: () => {
            this.confirmDeleteModalOpen = true;
          },
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
        });
      }

      this.args.multiStepForm.registerStepData('step1', 'buttons', buttons);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "confirmDeleteModalOpen", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "packageEquipmentDeleteId", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "initFormData", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "saveEquipmentPackage", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "deletePackageEquipmentTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "deletePackage", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "deletePackageEquipment", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "deletePackageEquipment"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateEquipmentItemsList", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "updateEquipmentItemsList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buttons", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "buttons"), _class.prototype)), _class));
  _exports.default = EditPackage;
});