define("securitytrax-ember-ui/components/modals/sub-route-modals/manage-adc/component", ["exports", "securitytrax-ember-ui/mixins/forms/adc", "ember-concurrency"], function (_exports, _adc, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Component.extend(_adc.default, {
    intl: Ember.inject.service(),
    adcSerialNumber: undefined,
    terminated: false,
    interactiveServicesProviderManageFormStarted: false,
    interactiveServicesProviderManageFormMode: 'create',
    shellResponse: undefined,
    initFormData: (0, _emberConcurrency.task)(function* () {
      yield this.adcCustomerInformation;
      this.set('interactiveServicesProviderManageFormMode', this.get('customerSystemInformation.interactiveServicesId') ? 'edit' : 'create');
      this.set('interactiveServicesProviderManageFormStarted', true);
    }).on('init'),
    ispType: Ember.computed('adcSerialNumber', function () {
      return this.adcSerialNumber ? 'customer' : 'commitment';
    }),
    interactiveServicesProviderManageFormSteps: Ember.computed('customer.{flname,customerOrderInformation.interactiveServicesProvider.name}', 'interactiveServicesProviderManageFormMode', function () {
      var titleData = {
        ispName: this.get('customer.customerOrderInformation.interactiveServicesProvider.name'),
        customerName: this.get('customer.flname'),
        id: this.get('customer.id')
      };
      var titlePath = 'pages.customers.system.interactiveServicesProvider.' + this.interactiveServicesProviderManageFormMode + '_title';
      var firstStepComponentPath = this.interactiveServicesProviderManageFormMode == 'create' ? 'create-account' : 'customer-information';
      var intl = this.intl;
      var title = intl.t(titlePath, titleData);
      return Ember.Object.create({
        customerInformation: Ember.Object.create({
          progressBarTitle: intl.t('pages.customers.system.interactiveServicesProvider.wizardSteps.customerInformation'),
          stepComponentPath: 'multi-step-form/forms/customer-interactive-services-provider-information/adc/' + firstStepComponentPath,
          stepNameKey: 'customerInformation',
          title: title
        }),
        equipment: Ember.Object.create({
          progressBarTitle: intl.t('pages.customers.system.interactiveServicesProvider.wizardSteps.equipment'),
          stepComponentPath: 'multi-step-form/forms/customer-interactive-services-provider-information/adc/equipment',
          stepNameKey: 'equipment',
          title: title
        }),
        servicePlan: Ember.Object.create({
          progressBarTitle: intl.t('pages.customers.system.interactiveServicesProvider.wizardSteps.servicePlan'),
          stepComponentPath: 'multi-step-form/forms/customer-interactive-services-provider-information/adc/service-plan',
          stepNameKey: 'servicePlan',
          title: title
        }),
        monitoringSettings: Ember.Object.create({
          progressBarTitle: intl.t('pages.customers.system.interactiveServicesProvider.wizardSteps.monitoringSettings'),
          stepComponentPath: 'multi-step-form/forms/customer-interactive-services-provider-information/adc/monitoring-settings',
          stepNameKey: 'monitoringSettings',
          title: title
        }),
        loginInformation: Ember.Object.create({
          progressBarTitle: intl.t('pages.customers.system.interactiveServicesProvider.wizardSteps.loginInformation'),
          stepComponentPath: 'multi-step-form/forms/customer-interactive-services-provider-information/adc/login-information',
          stepNameKey: 'loginInformation',
          title: title
        }),
        history: Ember.Object.create({
          progressBarTitle: intl.t('pages.customers.system.interactiveServicesProvider.wizardSteps.history'),
          stepComponentPath: 'multi-step-form/forms/customer-interactive-services-provider-information/adc/history',
          stepNameKey: 'history',
          title: title
        }),
        activateCommitment: Ember.Object.create({
          progressBarTitle: intl.t('pages.customers.system.interactiveServicesProvider.wizardSteps.activateCommitment'),
          stepComponentPath: 'multi-step-form/forms/customer-interactive-services-provider-information/adc/activate-commitment',
          stepNameKey: 'activateCommitment',
          title: title
        })
      });
    }),
    ispFormClass: Ember.computed('interactiveServicesProviderManageFormMode', function () {
      return this.interactiveServicesProviderManageFormMode == 'create' ? 'pad-content' : '';
    }),
    actions: {
      transitionBackToParentRoute() {
        this.router.transitionTo(this.parentRoute, this.transitionId);
      }

    }
  });
  var _default = Controller;
  _exports.default = _default;
});