define("securitytrax-ember-ui/demo/notifications/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    stNotificationManager: Ember.inject.service(),
    intl: Ember.inject.service(),
    showInlineWarning: true,
    messages: undefined,

    init() {
      this._super(...arguments);

      this.set('messages', ['Here is a message', 'Another message', 'Should be scrollable', 'Now you see me', 'Encore', 'Last']);
    },

    actions: {
      generateInfoNotification() {
        this.stNotificationManager.addInfo('Info Notification');
      },

      generateSuccessNotification() {
        this.stNotificationManager.addSuccess('Success Notification');
      },

      generateWarningNotification() {
        this.stNotificationManager.addWarning('Warning Notification: This warning has been substantially increased in length. The reason for this incredibly verbose and lengthy text is to force the notification onto multiple lines so that you can see how that scenario is displayed.');
      },

      generateErrorNotification() {
        this.stNotificationManager.addError('Error Notification');
      },

      generateErrorWithLink() {
        this.stNotificationManager.addError(this.intl.t('common.securitytrax_feedback', {
          link: 'https://www.securitytrax.com/v3-lrb-client-input/'
        }));
      }

    }
  });

  _exports.default = _default;
});