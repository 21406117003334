define("securitytrax-ember-ui/models/recurring-invoice", ["exports", "securitytrax-ember-ui/models/base_models/recurring-invoice", "moment"], function (_exports, _recurringInvoice, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _recurringInvoice.default.extend({
    signedAmount: Ember.computed('responseMeta.total_amount', function () {
      return this.get('responseMeta.total_amount');
    }),
    uniqueTypeAndId: Ember.computed('id', function () {
      return this.id + '_recurringInvoice';
    }),
    stopped: Ember.computed('numTimesToRecur', function () {
      var today = (0, _moment.default)();
      var lastDay = (0, _moment.default)(this.lastDay).add(7, 'days');
      var numTimesToRecur = this.numTimesToRecur;

      if (numTimesToRecur === 0 || today.isAfter(lastDay)) {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});