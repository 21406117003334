define("securitytrax-ember-ui/services/months", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * List of months for dropdowns
   *
   * @class Months
   * @memberof services
   * @extends Ember.Service
   */
  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    monthOptions: Ember.computed(function () {
      return [{
        name: this.intl.t('common.monthNames.01'),
        value: '01'
      }, {
        name: this.intl.t('common.monthNames.02'),
        value: '02'
      }, {
        name: this.intl.t('common.monthNames.03'),
        value: '03'
      }, {
        name: this.intl.t('common.monthNames.04'),
        value: '04'
      }, {
        name: this.intl.t('common.monthNames.05'),
        value: '05'
      }, {
        name: this.intl.t('common.monthNames.06'),
        value: '06'
      }, {
        name: this.intl.t('common.monthNames.07'),
        value: '07'
      }, {
        name: this.intl.t('common.monthNames.08'),
        value: '08'
      }, {
        name: this.intl.t('common.monthNames.09'),
        value: '09'
      }, {
        name: this.intl.t('common.monthNames.10'),
        value: '10'
      }, {
        name: this.intl.t('common.monthNames.11'),
        value: '11'
      }, {
        name: this.intl.t('common.monthNames.12'),
        value: '12'
      }];
    })
  });

  _exports.default = _default;
});