define("securitytrax-ember-ui/services/global-events", ["exports", "securitytrax-ember-ui/config/environment", "ember-concurrency", "@sentry/browser"], function (_exports, _environment, _emberConcurrency, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    session: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    grants: Ember.inject.service(),
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      if (_environment.default['securitytrax-ember-ui']['pendo']['enabled'] === true && !window.Cypress) {
        // Pendo analytics and customizations
        this.initializePendoTask.perform().then(() => {
          (false && Ember.warn('pendo initialized', {
            id: 'app.services.globalEvents.appRefreshed'
          })); //pendo.validateInstall();
        }).catch(err => {
          Sentry.captureMessage('Unable to initialize Pendo during app refresh: ' + err);
          (false && Ember.warn('failed to init Pendo during app refresh:' + err, {
            id: 'app.services.globalEvents.init'
          }));
        });
      }
    },

    // https://help.pendo.io/resources/support-library/api/index.html?bash#agent-api
    initializePendoTask: (0, _emberConcurrency.task)(function* () {
      if (_environment.default['securitytrax-ember-ui']['pendo']['enabled'] !== true) {
        throw new Error('pendo is not enabled');
      }

      yield (0, _emberConcurrency.waitForProperty)(window, 'pendo', () => {
        return window.pendo;
      }); // pendo has already initialized
      // crazy stuff here: pendo isReady is only a function after it has been initialized or validated

      if (typeof window.pendo.isReady == 'function' && window.pendo.isReady()) {
        yield;
        return;
      }

      var user;

      try {
        user = yield this.sessionData.getUser();
      } catch (e) {
        e; //
      }

      if (user) {
        if (this.grants.getIndividualGrant('groups.view')) {
          this.store.findAll('group'); //Getting all groups here to avoid a bug with user groups not populating
        }

        var group;

        try {
          group = this.grants.getIndividualGrant('groups.view') ? yield user.get('group') : null;
        } catch (err) {
          group = null; //should never happen
        }

        var hashData = yield (0, _emberConcurrency.hash)({
          roles: group ? group.get('roles') : [],
          company: this.sessionData.getCompany(),
          monitoringCompanies: this.store.findAll('monitoringCompany', {
            include: 'monitoring_company_type'
          }),
          interactiveServicesProviders: this.store.findAll('interactiveServicesProvider', {
            include: 'interactive_services_provider_type'
          })
        });
        var rolesList = hashData.roles.map(role => role.get('name'));
        var monitoringTypesArray = yield this.uniqueArrayTask.perform(hashData.monitoringCompanies, 'monitoringCompanyType', 'id', 'int');
        var interactiveServicesProviderTypesArray = yield this.uniqueArrayTask.perform(hashData.interactiveServicesProviders, 'interactiveServicesProviderType', 'id', 'int');
        var allGrants = this.grants;
        yield (0, _emberConcurrency.waitForProperty)(allGrants, 'loaded', true);
        pendo.initialize({
          visitor: {
            id: _environment.default['securitytrax-ember-ui']['profile'] + '-' + user.get('id'),
            // Needs to be globally unique
            email: user.get('email'),
            roles: rolesList,
            //rolesList.join(','),
            can_view_admin: this.canViewAdmin(allGrants),
            can_emulate: yield user.get('userCanEmulate'),
            emulated_by: yield this.get('sessionData.isEmulatedByUserId') //grants: JSON.stringify(allGrants.userGrants)

          },
          account: {
            id: _environment.default['securitytrax-ember-ui']['profile'],
            // company.profile_name
            name: hashData.company.get('name'),
            // company.name
            monitors: monitoringTypesArray,
            interactive_service_providers: interactiveServicesProviderTypesArray
          }
        });
      }
    }).drop().maxConcurrency(1),
    uniqueArrayTask: (0, _emberConcurrency.task)(function* (list, relationship, prop, type = 'string') {
      var childArray = []; //make sure the list promise is done, and convert to array

      var parent = yield list.toArray();

      for (var i = 0; i < parent.length; i++) {
        //yield the relationship
        var childRelationship = yield parent[i].get(relationship);

        if (childRelationship) {
          childArray.push(childRelationship);
        }
      } //return a new unique set based on the prop


      return [...new Set(childArray.map(item => {
        return type === 'int' ? Number(item[prop]) : item[prop];
      }))];
    }),

    canViewAdmin(grants) {
      //TODO TRAX-4915 what other grants to check - should correspond to grants required to go to admin routes
      return 'userGrants' in grants && 'user_admin_settings' in grants.userGrants ? grants.userGrants.user_admin_settings.view : false;
    }

  });

  _exports.default = _default;
});