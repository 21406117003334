define("securitytrax-ember-ui/helpers/st-data-list-fast-at-list-end", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute(args) {
      var [options] = args;
      return options.index + 1 === options.length;
    }

  });

  _exports.default = _default;
});