define("securitytrax-ember-ui/components/multi-step-form/forms/administration/equipment/add-equipment-chooser/component", ["exports", "securitytrax-ember-ui/utils/obj-helpers", "securitytrax-ember-ui/mixins/switchable-form-step"], function (_exports, _objHelpers, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    screen: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    equipmentAddOptions: undefined,

    init() {
      this._super(...arguments);

      var intl = this.intl;
      (0, _objHelpers.initializeState)(this, {
        equipmentAddOptions: [{
          packageId: 'manual',
          packageName: intl.t('pages.administration.company_settings.equipment.equipment.add_manual')
        }, {
          packageId: 'masterList',
          packageName: intl.t('pages.administration.company_settings.equipment.equipment.add_master_list')
        }]
      });
    },

    didRender() {
      this._super(...arguments);

      this.resetPackageSelectHeight();
    },

    //make sure all package select items are of the same height, if screen size changes
    screenChangeObserver: Ember.observer('screen.width', function () {
      this.resetPackageSelectHeight();
    }),

    resetPackageSelectHeight() {
      var maxHeight = 34;
      var screenWidth = this.get('screen.width');
      var selector = '.package-select .individual-package';

      if (screenWidth) {
        Ember.run.next(() => {
          var elements = document.querySelectorAll(selector);

          for (var i = 0; i < elements.length; i++) {
            elements[i].removeAttribute('style'); //if we are resetting the height

            maxHeight = elements[i].offsetHeight > maxHeight ? elements[i].offsetHeight : maxHeight;
          }

          elements.forEach(el => {
            el.style.height = Number(maxHeight) + 'px';
          });
        });
      }
    },

    actions: {
      setCurrentStep(key) {
        var multiStepForm = this.multiStepForm;
        multiStepForm.currentStepNameKeySetVal(key);
      }

    }
  });

  _exports.default = _default;
});