define("securitytrax-ember-ui/components/multi-step-form/forms/customer-central-station-information/monitoring-companies/monitoring-company-type-3/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer-central-station-information/base-monitoring-company/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var BrinksMonitoringCompanyType = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service('events/brinks-econtract-events'), _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);

    try {
      var eContractMeta = yield this.centralStationService.getData({
        model: this.args.customer,
        method: 'brinks__getCustomerEContractMetadata'
      });

      if (eContractMeta.customer_e_contract_metadata.contract_id) {
        this.contractId = eContractMeta.customer_e_contract_metadata.contract_id;
      } else {
        this.contractId = null;
      }
    } catch (err) {
      (false && Ember.warn('failure in cs card init: ' + err, {
        id: 'customers.information.centralStation.brinks'
      }));
    }
  }), _dec10 = (0, _emberConcurrency.task)(function* (input) {
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
    var monitorTypeId = this.args.multiStepForm.formCopiedModelsGetVal('customerOrderInformation.model.monitoringCompany.monitoringCompanyType.id');
    this.router.transitionTo('customers.information.manage-' + this.subrouteMapping.monitorMapping.findBy('id', monitorTypeId).name, {
      queryParams: {
        [input]: true
      }
    });
  }), _dec11 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    try {
      if (!this.args.multiStepForm.formCopiedModels.customerSystemInformation.model.tempTwoWayDeviceId || !this.args.multiStepForm.formCopiedModels.customerSystemInformation.model.accountNum) {
        throw new Error(this.intl.t('pages.customers.system.order.csAndTwoWayRequired'));
      } else {
        yield this.centralStationService.postData({
          model: this.args.customer,
          method: 'brinks__addTwoWayDeviceId',
          payload: {
            two_way_device_id: this.args.multiStepForm.formCopiedModels.customerSystemInformation.model.tempTwoWayDeviceId
          }
        });
      }

      buttonPromise.then(() => {
        this.twoWayDeviceModalOpen = false;
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        delete this.args.multiStepForm.formCopiedModels.customerSystemInformation.model.tempTwoWayDeviceId;
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), _dec12 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    try {
      yield this.centralStationService.postData({
        model: this.args.customer,
        method: 'brinks__unlinkEContract'
      });
      buttonPromise.then(() => {
        this.unlinkContractFormStarted = false;
        this.stNotificationManager.addSuccessKey('common.notifications.unlink_successful');
        this.initEContractTask.perform();
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'unlinkEcontract'
      });
      throw err;
    }
  }), _dec13 = (0, _emberConcurrency.task)(function* () {
    var preShellErrors = [];
    var customerSystemInformation = yield this.args.customer.get('customerSystemInformation');

    try {
      if (!customerSystemInformation.get('accountNum')) {
        preShellErrors.push(this.intl.t('pages.customers.system.order.shelling_requirement', {
          field: this.intl.t('common.columns.account_num'),
          monitoringCompany: this.args.multiStepForm.formCopiedModelsGetVal('customerOrderInformation.model.monitoringCompany.monitoringCompanyType.name')
        }));
      }

      if (!customerSystemInformation.get('receiverNum')) {
        preShellErrors.push(this.intl.t('pages.customers.system.order.shelling_requirement', {
          field: this.intl.t('pages.customers.information.customer_system_information.receiverNum.label'),
          monitoringCompany: this.args.multiStepForm.formCopiedModelsGetVal('customerOrderInformation.model.monitoringCompany.monitoringCompanyType.name')
        }));
      }

      if (preShellErrors.length > 0) {
        var errorNotificationMessage = this.stNotificationManager.fullMessage(preShellErrors);
        this.stNotificationManager.addError(errorNotificationMessage);
        throw new Error('preShell missing info');
      } else {
        this.router.transitionTo('customers.information.manage-' + this.subrouteMapping.monitorMapping.findBy('id', '3').name);
      }
    } catch (error) {
      (false && Ember.warn('failed to startBrinksForm' + error, {
        id: 'multiStepForm.forms.customerCentralStationInformation.monitoringCompanies.monitoringCompanyType3'
      }));
    }
  }), (_class = (_temp = class BrinksMonitoringCompanyType extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "centralStationService", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);

      _initializerDefineProperty(this, "subrouteMapping", _descriptor5, this);

      _initializerDefineProperty(this, "brinksEcontractEvents", _descriptor6, this);

      _initializerDefineProperty(this, "hasEContract", _descriptor7, this);

      _initializerDefineProperty(this, "twoWayDeviceModalOpen", _descriptor8, this);

      _initializerDefineProperty(this, "initEContractTask", _descriptor9, this);

      _initializerDefineProperty(this, "toSpecificFormStep", _descriptor10, this);

      _initializerDefineProperty(this, "addTwoWayDeviceId", _descriptor11, this);

      _initializerDefineProperty(this, "unlinkEcontract", _descriptor12, this);

      _initializerDefineProperty(this, "startIntegrationForm", _descriptor13, this);

      this.brinksEcontractEvents.on('reload-cs-card', this, '_eventCallback');
      this.initEContractTask.perform();
    }

    willDestroy() {
      this.brinksEcontractEvents.off('reload-cs-card', this, '_eventCallback');
    }

    _eventCallback() {
      this.initEContractTask.perform();
    }

    get monitoringCompanyHasNeededGrants() {
      return true;
    }

    get twoWayDeviceButtons() {
      return [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.addTwoWayDeviceId.perform(buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary'
      }];
    }

    get unlinkEContractButton() {
      return [{
        text: this.intl.t('common.actions.unlink'),
        click: buttonPromise => {
          return this.unlinkEcontract.perform(buttonPromise);
        },
        classNamesString: 'st-btn st-btn-warn'
      }];
    }

    get shellAccountAdditionalClickActions() {
      var actions = [];
      actions.push({
        label: this.intl.t('pages.customers.system.order.addTwoWayDevice'),
        action: () => {
          this.twoWayDeviceModalOpen = true;
        }
      }, {
        label: this.intl.t('pages.customers.system.order.viewEventHistories'),
        action: () => {
          return this.toSpecificFormStep.perform('toHistory');
        }
      });

      if (this.contractId) {
        actions.push({
          label: this.intl.t('pages.customers.system.order.unlinkEcontract'),
          action: () => {
            this.unlinkContractFormStarted = true;
          }
        });
      } else {
        actions.push({
          label: this.intl.t('pages.customers.system.order.createEcontract'),
          action: () => {
            return this.toSpecificFormStep.perform('toContract');
          }
        });
      }

      return actions;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "subrouteMapping", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "brinksEcontractEvents", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "hasEContract", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "twoWayDeviceModalOpen", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "initEContractTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "toSpecificFormStep", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "addTwoWayDeviceId", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "unlinkEcontract", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "startIntegrationForm", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BrinksMonitoringCompanyType;
});