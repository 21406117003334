define("securitytrax-ember-ui/mixins/forms/calendar-event-reusable", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   *
   * @mixin ListMixin
   * @extends Ember.Mixin
   */
  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    calculatedEndtime: Ember.computed('startTime', 'duration', function () {
      var startTime = this.startTime;
      var duration = this.duration;
      var clonedStartTime = (0, _moment.default)(startTime).clone();

      if (duration) {
        clonedStartTime = clonedStartTime.add(duration, 'seconds');
      }

      if (clonedStartTime.isAfter((0, _moment.default)(this.startTime).endOf('day'))) {
        clonedStartTime = (0, _moment.default)(this.startTime).endOf('day');
      }

      return clonedStartTime;
    }),

    /**
     * Used for calculating ladder-effect.
     * It gives true if event should be on lower level than this event
     * In other words, it's start is inside the current event timespan
     **/
    isSuperposedWithEvent(event) {
      var startTime = (0, _moment.default)(this.startTime);
      var endTimeCalculated = startTime.clone().add(this.duration, 'seconds');
      var eventStartTime = _moment.default.isMoment(event.get('startTime')) ? event.get('startTime') : (0, _moment.default)(event.get('startTime'));
      return eventStartTime.isBetween(startTime, endTimeCalculated, null, '[)');
    },

    mergedStyleColor: Ember.computed('calendarEventType.styleColor', 'calendarEventDetailType.styleColor', 'calendarEventDetailType', 'calendarEventType', function () {
      var styleColor;

      if (this.hasCalendarEventDetailType) {
        styleColor = this.get('calendarEventDetailType.styleColor');
      } else {
        styleColor = this.get('calendarEventType.styleColor');
      }

      return styleColor;
    }),
    hasCalendarEventDetailType: Ember.computed('calendarEventDetailType.{id,color}', function () {
      return this.get('calendarEventDetailType.id') != null && this.get('calendarEventDetailType.color') != null;
    }),
    scheduledDateDisplay: Ember.computed('startTime', function () {
      var today = (0, _moment.default)();
      var intl = this.intl;
      var startTime = (0, _moment.default)(this.startTime).format('ddd, MMMM D');
      var yesterday = today.subtract(1, 'days').format('ddd, MMMM D');
      var tomorrow = today.add(1, 'days').format('ddd, MMMM D');

      if (startTime === today.format('ddd, MMMM D')) {
        return intl.t('pages.home.today');
      } else if (startTime === tomorrow) {
        return intl.t('pages.home.tomorrow');
      } else if (startTime === yesterday) {
        return intl.t('pages.home.yesterday');
      }

      return startTime ? startTime : '';
    }),
    startTimeDisplay: Ember.computed('startTime', function () {
      var startTime = (0, _moment.default)(this.startTime);
      return startTime ? startTime.toString() : '';
    }),
    startTimeDisplayDropdowns: Ember.computed('startTime', function () {
      var startTime = (0, _moment.default)(this.startTime);
      return startTime ? startTime.format('M/D/YYYY h:mm a') : '';
    }),

    /**
     * Resize event changing start time.
     *
     * @param newStartTime (moment) The new start
     * @returns {boolean} Will return false if the new start is equal to the end or after it
     */
    resizeByStart(newStartTime) {
      return this.resizeByStartTask.perform(newStartTime);
    },

    resizeByStartTask: (0, _emberConcurrency.task)(function* (newStartTime) {
      try {
        var oldStart = yield (0, _moment.default)(this.startTime);
        newStartTime.set('day', oldStart.get('day'));
        var newDuration = oldStart.diff(newStartTime, 'seconds') + this.duration;

        if (newDuration <= 0) {
          return false;
        }

        this.set('startTime', newStartTime);
        this.set('duration', newDuration);
        return true;
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err);
        return false;
      }
    }),

    /**
     * Resize event changing end time.
     *
     * @param newEndTime (moment) The new end time
     * @returns {boolean} Will return false if the new end is equal to the start or before it
     */
    resizeByEnd(newEndTime) {
      return this.resizeByEndTask.perform(newEndTime);
    },

    resizeByEndTask: (0, _emberConcurrency.task)(function* (newEndTime) {
      try {
        var startTime = yield (0, _moment.default)(this.startTime);

        if (newEndTime.isAfter((0, _moment.default)(startTime).endOf('day'))) {
          newEndTime = (0, _moment.default)(startTime).endOf('day');
        } else {
          newEndTime.set('day', startTime.get('day'));
        }

        var newDuration = newEndTime.diff(startTime, 'seconds');

        if (newDuration <= 0) {
          return false;
        }

        var overflow = newDuration % 1800;

        if (overflow > 0) {
          newDuration = newDuration + (1800 - overflow);
        }

        this.set('duration', newDuration);
        return true;
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err);
        return false;
      }
    }),

    moveEvent(newUser, newStartTime, oldUser) {
      return this.moveEventTask.perform(newUser, newStartTime, oldUser);
    },

    moveEventTask: (0, _emberConcurrency.task)(function* (newUser, newStartTime, oldUser) {
      try {
        if (oldUser.get('id') !== newUser.get('id')) {
          var oldCalendarEvents = yield oldUser.get('calendarEvents');
          var calendarEvents = yield newUser.get('calendarEvents');
          yield oldCalendarEvents.removeObject(this);
          yield calendarEvents.pushObject(this);
          this.set('calendarUser', yield this.store.findRecord('calendarUser', newUser.get('id')));
        }

        this.set('startTime', newStartTime);
      } catch (err) {
        this.stNotificationManager.addErrorMessages(this.intl.t('pages.calendar.move_event_error') + err, {
          notificationNamespace: context.multiStepForm ? 'modal2' : 'global'
        });
      }
    }),

    cycleStatus() {
      var status = this.status;

      if (status === 'to_be_confirmed') {
        this.set('status', 'confirmed');
      } else if (status === 'confirmed') {
        this.set('status', 'canceled');
      } else if (status === 'canceled') {
        this.set('status', 'to_be_confirmed');
      } else {
        this.set('status', 'to_be_confirmed');
      }
    }

  });

  _exports.default = _default;
});