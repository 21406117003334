define("securitytrax-ember-ui/components/pages/administration/company-settings/integrations/accounting-providers-sync/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var AccountingProvidersSync = (_dec = Ember.inject.service(), _dec2 = Ember.inject.service(), _dec3 = Ember.inject.service(), _dec4 = Ember.inject.service(), _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember.computed(), _dec8 = (0, _emberConcurrency.task)(function* () {
    this.currAccountingProvider = yield this.quickbooksService.currAccountingProviderTask.perform();
    return Ember.Object.create({
      quickbooksIntegration: {
        model: this.currAccountingProvider,
        doSave: false,
        copyRelationMap: {
          quickbooksIntegrationConfig: {
            doCopy: true,
            doValidate: false,
            doSave: true
          }
        }
      }
    });
  }), _dec9 = Ember._action, (_class = (_temp = class AccountingProvidersSync extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "grants", _descriptor3, this);

      _initializerDefineProperty(this, "quickbooksService", _descriptor4, this);

      _initializerDefineProperty(this, "currAccountingProvider", _descriptor5, this);

      _initializerDefineProperty(this, "accountingProviderFormMode", _descriptor6, this);

      _initializerDefineProperty(this, "accountingProviderFormOriginalModelsTask", _descriptor7, this);
    }

    get accountingProvidersFormSteps() {
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.intl.t('pages.administration.integrations.payment_processing.gateway_title'),
          stepComponentPath: 'multi-step-form/forms/administration/integrations/accounting-providers/edit-create'
        })
      });
    }

    get accountingProviderFormOriginalModels() {
      return this.accountingProviderFormOriginalModelsTask.perform();
    }

    setFormMode(formMode) {
      Ember.set(this, 'accountingProviderFormMode', formMode);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "quickbooksService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "currAccountingProvider", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "accountingProviderFormMode", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'view';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "accountingProviderFormOriginalModels", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "accountingProviderFormOriginalModels"), _class.prototype), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "accountingProviderFormOriginalModelsTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setFormMode", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "setFormMode"), _class.prototype)), _class));
  _exports.default = AccountingProvidersSync;
});