define("securitytrax-ember-ui/services/quickbooks-service", ["exports", "ember-concurrency", "lodash"], function (_exports, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var QuickbooksService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = (0, _emberConcurrency.task)(function* (currAccountingProvider) {
    return yield currAccountingProvider.invokeAction('reset_auto_credit_flag', {
      verb: 'POST',
      data: {
        data: {}
      }
    });
  }), _dec5 = (0, _emberConcurrency.task)(function* () {
    var allQuickbooksIntegration = this.grants.getIndividualGrant('quickbooks_integrations.view') ? yield this.store.findAll('quickbooksIntegration') : [];

    if (allQuickbooksIntegration.length > 0) {
      for (var index = 0; index < allQuickbooksIntegration.length; index++) {
        var element = allQuickbooksIntegration.objectAt(index);

        if (element.id) {
          return element;
        }
      }
    }

    return null;
  }), _dec6 = (0, _emberConcurrency.task)(function* (currAccountingProvider) {
    var quickbooksFeatureEnabled = yield this.enhancedFeatures.hasFeature('quickbooks');

    if (quickbooksFeatureEnabled) {
      if (!currAccountingProvider) {
        currAccountingProvider = yield this.currAccountingProviderTask.perform();
      }

      if (currAccountingProvider) {
        var accountingProviderStatus = yield currAccountingProvider.invokeAction('check_status', {
          verb: 'GET'
        });
        return _lodash.default.get(accountingProviderStatus, 'responsePayload.meta.token_valid', false);
      }
    }

    return false;
  }), _dec7 = (0, _emberConcurrency.task)(function* (currAccountingProvider) {
    var mappingDefault = {
      income: '',
      payment: '',
      refund: ''
    };

    if (currAccountingProvider) {
      var accountMappingData = yield currAccountingProvider.invokeAction('get_account_mapping', {
        verb: 'GET'
      });
      return _lodash.default.get(accountMappingData, 'responsePayload.data', mappingDefault);
    }

    return mappingDefault;
  }), _dec8 = (0, _emberConcurrency.task)(function* (currAccountingProvider, accountMappingData) {
    if (currAccountingProvider && accountMappingData) {
      yield currAccountingProvider.invokeAction('set_account_mapping', {
        verb: 'POST',
        data: {
          data: accountMappingData
        }
      });
    }
  }), _dec9 = (0, _emberConcurrency.task)(function* (currAccountingProvider) {
    if (currAccountingProvider) {
      var accountData = yield currAccountingProvider.invokeAction('get_accounts', {
        verb: 'GET'
      });
      return _lodash.default.get(accountData, 'responsePayload.data', []);
    }

    return [];
  }), _dec10 = (0, _emberConcurrency.task)(function* () {
    var quickbooksIntegrations = this.grants.getIndividualGrant('quickbooks_integrations.view') ? yield this.store.findAll('quickbooksIntegration') : [];

    if (!this.quickbooksId) {
      for (var x = 0; x < quickbooksIntegrations.length; x++) {
        var currQuickbooksIntegration = quickbooksIntegrations.objectAt(x);
        var quickbooksIntegrationConfig = yield currQuickbooksIntegration.quickbooksIntegrationConfig;

        if (quickbooksIntegrationConfig.active === true) {
          this.quickbooksId = currQuickbooksIntegration.id;
        }
      }
    }

    if (this.quickbooksId) {
      return yield this.store.findRecord('quickbooksIntegration', this.quickbooksId);
    }

    return null;
  }), (_class = (_temp = class QuickbooksService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "grants", _descriptor2, this);

      _initializerDefineProperty(this, "enhancedFeatures", _descriptor3, this);

      _initializerDefineProperty(this, "resetAutoCreditFlag", _descriptor4, this);

      _initializerDefineProperty(this, "currAccountingProviderTask", _descriptor5, this);

      _initializerDefineProperty(this, "quickbooksIsConnected", _descriptor6, this);

      _initializerDefineProperty(this, "quickbooksAccountMapping", _descriptor7, this);

      _initializerDefineProperty(this, "quickbooksSetAccountMapping", _descriptor8, this);

      _initializerDefineProperty(this, "quickbooksAccounts", _descriptor9, this);

      _initializerDefineProperty(this, "findQuickbooksIntegrationTask", _descriptor10, this);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "enhancedFeatures", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "resetAutoCreditFlag", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "currAccountingProviderTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "quickbooksIsConnected", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "quickbooksAccountMapping", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "quickbooksSetAccountMapping", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "quickbooksAccounts", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "findQuickbooksIntegrationTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = QuickbooksService;
});