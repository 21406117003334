define("securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-customer-equipment/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EditCustomerEquipment = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service('events/transitionEvent'), _dec7 = Ember.inject.service('events/tech-today'), _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = (0, _emberConcurrency.task)(function* () {
    if (this.args.customerEquipmentId !== 'create') {
      this.customerEquipment = yield this.store.findRecord('customerEquipment', this.args.customerEquipmentId);
      this.customer = this.args.customer ? this.args.customer : yield this.customerEquipment.get('customer');
    } else {
      this.customerEquipmentFirstStep = 'addEquipment';
      this.customer = this.args.customer;
    }

    this.customerEquipmentFormStarted = true;

    if (this.grants.getIndividualGrant('customer_equipment.create') && !this.customerEquipment) {
      this.customerEquipmentFormMode = 'create';
    } else if (this.grants.getIndividualGrant('customer_equipment.modify') && this.customerEquipment) {
      this.customerEquipmentFormMode = 'edit';
    } else {
      this.customerEquipmentFormMode = 'view';
    }
  }), _dec14 = Ember._action, _dec15 = Ember._action, (_class = (_temp = class EditCustomerEquipment extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "grants", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "quickAdd", _descriptor5, this);

      _initializerDefineProperty(this, "transitionEvent", _descriptor6, this);

      _initializerDefineProperty(this, "techToday", _descriptor7, this);

      _initializerDefineProperty(this, "customerEquipment", _descriptor8, this);

      _initializerDefineProperty(this, "customer", _descriptor9, this);

      _initializerDefineProperty(this, "customerEquipmentFormStarted", _descriptor10, this);

      _initializerDefineProperty(this, "customerEquipmentFormMode", _descriptor11, this);

      _initializerDefineProperty(this, "customerEquipmentFirstStep", _descriptor12, this);

      _initializerDefineProperty(this, "initData", _descriptor13, this);

      this.techToday.on('refresh-work-order-equipment', this, '_refreshEventCallback');
      this.initData.perform();
    }

    willDestroy() {
      this.techToday.off('refresh-work-order-equipment', this, '_refreshEventCallback');
    }

    _refreshEventCallback() {
      this.transitionBackToParentRoute(true);
    }

    get customerEquipmentFormSteps() {
      return Ember.Object.create({
        addEquipment: Ember.Object.create({
          stepNameKey: 'addEquipment',
          title: this.intl.t('pages.customers.equipment.modal_title.create', {
            name: this.args.customer.get('flname')
          }),
          stepComponentPath: 'multi-step-form/forms/customer-equipment/add-equipment'
        }),
        details: Ember.Object.create({
          stepNameKey: 'details',
          title: this.intl.t('pages.customers.equipment.modal_title.edit', {
            name: this.args.customer.get('flname'),
            id: this.customerEquipment ? this.customerEquipment.get('id') : null
          }),
          stepComponentPath: 'multi-step-form/forms/customer-equipment/details'
        }),
        equipmentMatch: Ember.Object.create({
          stepNameKey: 'equipmentMatch',
          title: this.intl.t('pages.customers.equipment.matchTitleSingle', {
            id: this.customerEquipment ? this.customerEquipment.get('id') : null
          }),
          stepComponentPath: 'multi-step-form/forms/customer-equipment/match-equipment'
        })
      });
    }

    get customerEquipmentFormOriginalModels() {
      var grants = this.grants;
      return Ember.Object.create({
        customerEquipment: {
          model: this.customerEquipment,
          copyRelationMap: {
            equipment: {
              doCopy: grants.getIndividualGrant('customer_equipment.view'),
              doValidate: false,
              doSave: false
            },
            equipmentStatus: {
              doValidate: false,
              doSave: false
            },
            equipmentWireType: {
              doValidate: false,
              doSave: false
            },
            installCalendarEvent: {
              doValidate: false,
              doSave: false
            },
            uninstallCalendarEvent: {
              doValidate: false,
              doSave: false
            },
            installInventoryTechUser: {
              doCopy: true,
              doValidate: false,
              doSave: false
            },
            uninstallInventoryTechUser: {
              doCopy: true,
              doValidate: false,
              doSave: false
            },
            customerSystemInformation: {
              doCopy: grants.getIndividualGrant('customer_system_information.view')
            },
            user: {
              doCopy: true,
              doValidate: false,
              doSave: false
            }
          }
        }
      });
    }

    onAfterSave() {
      if (!this.args.workOrder) {
        this.transitionBackToParentRoute(true);
      }
    }

    transitionBackToParentRoute(refresh = true) {
      var transitionToRoute = this.quickAdd.closeRoute || this.args.parentRoute;

      if (transitionToRoute.includes('customer')) {
        this.router.transitionTo(transitionToRoute, this.customer.id);
      } else {
        this.router.transitionTo(transitionToRoute);
      }

      if (refresh) {
        this.transitionEvent.trigger('refresh-customer-equipment');
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "quickAdd", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "transitionEvent", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "techToday", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "customerEquipment", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "customer", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "customerEquipmentFormStarted", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "customerEquipmentFormMode", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'view';
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "customerEquipmentFirstStep", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'details';
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "initData", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onAfterSave", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "onAfterSave"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transitionBackToParentRoute", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "transitionBackToParentRoute"), _class.prototype)), _class));
  _exports.default = EditCustomerEquipment;
});