define("securitytrax-ember-ui/components/nav/top-nav/component", ["exports", "securitytrax-ember-ui/utils/obj-helpers", "ember-concurrency"], function (_exports, _objHelpers, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['top-nav-container'],
    company: undefined,
    createCustomerFormStarted: false,
    grants: Ember.inject.service('grants'),
    intl: Ember.inject.service(),
    screen: Ember.inject.service(),
    session: Ember.inject.service('session'),
    sessionData: Ember.inject.service('sessionData'),
    user: undefined,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        company: {},
        user: {}
      });
      this.setPropertiesFromSession();
    },

    canViewCompanySettings: Ember.computed('grants.userGrants', function () {
      var grantsService = this.grants; // Can we get this list of resource types from BE?

      var companySettingsViewImpliedGrants = ['locations', 'user_hierarchy_titles', 'equipment', 'activation_fees', 'contract_terms', 'customer_cancel_reasons', 'mmrs', 'customer_warranties', 'order_templates', 'calendar_event_tags', 'customer_note_tags', 'customer_tags', 'lead_company_campaign_tags', 'lead_company_tags', 'location_tags', 'customer_task_tags', 'user_tags', 'work_order_tags', 'equipment_tags', 'invoice_tags', 'equipment_purchase_order_tags', 'equipment_purchase_requisition_tags', 'equipment_return_merchandis_aurthorization_tags', 'equipment_spillage_tags', 'customer_payable_tags', 'interactive_services_providers', 'customer_information_configs', 'customer_contact_defaults_configs', 'customer_duplicate_configs', 'sales_tax_exemption_settings', 'equipment_kits', 'packages', 'equipment_vendors', 'consumer_financing_lenders', 'credit_bureaus', 'electronic_document_providers', 'monitoring_companies', 'account_classes', 'customer_at_risk_credit_reasons', 'customer_at_risk_credit_reason_causes', 'customer_at_risk_resolutions', 'customer_at_risk_credit_reasons', 'calendar_event_detail_types', 'communication_reasons', 'communication_dispositions', 'dealer_numbers', 'equipment_return_reasons', 'funder_reasons', 'invoice_billable_items', 'lead_statuses', 'messages', 'customer_payment_types', 'templates', 'dry_run_reasons', 'work_order_types', 'workflow_configs', 'payroll_role_functions', 'payroll_role_function_levels', 'payroll_columns', 'payroll_column_calculations', 'notification_subscriptions'];

      if (grantsService.getIndividualGrant('global_settings.view') || grantsService.getIndividualGrant('lead_companies.view') || grantsService.getIndividualGrant('processing_gateways.view') || grantsService.getIndividualGrant('funders.view') || grantsService.getIndividualGrant('funding_transaction_types.view')) {
        return true;
      }

      for (var i = 0; i < companySettingsViewImpliedGrants.length; i++) {
        if (grantsService.getIndividualGrant(companySettingsViewImpliedGrants[i] + '.modify') || grantsService.getIndividualGrant(companySettingsViewImpliedGrants[i] + '.create') || grantsService.getIndividualGrant(companySettingsViewImpliedGrants[i] + '.manage')) {
          return true;
        }
      }

      return false;
    }),
    canViewHumanResources: Ember.computed('grants.userGrants', function () {
      var grantsService = this.grants;
      var humanResourcesGrants = ['users', 'groups', 'payroll_role_functions'];

      for (var i = 0; i < humanResourcesGrants.length; i++) {
        if (grantsService.getIndividualGrant(humanResourcesGrants[i] + '.view')) {
          return true;
        }
      }

      return false;
    }),
    viewMode: Ember.computed('screen', function () {
      return this.get('screen.mobileAndTablet') === true ? 'mobile' : 'desktop';
    }),

    setPropertiesFromSession() {
      Ember.RSVP.resolve(this.sessionData.getCompany()).then(company => {
        this.set('company', company);
      });
      Ember.RSVP.resolve(this.sessionData.getUser()).then(user => {
        this.set('user', user);
      }).catch(err => {
        if (!(0, _emberConcurrency.didCancel)(err)) {
          throw err;
        }
      });
    }

  });

  _exports.default = _default;
});