define("securitytrax-ember-ui/models/customer-billing", ["exports", "securitytrax-ember-ui/models/base_models/customer-billing", "securitytrax-ember-ui/utils/card-types", "ember-concurrency"], function (_exports, _customerBilling, _cardTypes, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerBilling.default.extend({
    intl: Ember.inject.service(),
    billCcNumberLastFour: Ember.computed('billCcNumber', function () {
      return this.billCcNumber.substr(this.billCcNumber.length - 4);
    }),
    icon: Ember.computed('billType.id', 'billCcType', function () {
      if (this.get('billType.isCreditCard')) {
        var cardType = this.cardType;

        if (cardType) {
          return "cc-".concat(cardType);
        }

        return 'credit-card';
      } else if (this.get('billType.isBankAccount')) {
        return 'bank';
      }

      return 'envelope';
    }),
    title: Ember.computed('titleValues', 'billType.code', function () {
      return this.titleTask.perform();
    }),
    titleTask: (0, _emberConcurrency.task)(function* () {
      var intl = this.intl;
      var resolvedBillType = yield this.billType;
      var billTypeCode = resolvedBillType ? resolvedBillType.get('code') : null;
      var descriptionKey = "pages.customers.accounting.customer_billing.description.".concat(billTypeCode);

      if (intl.exists(descriptionKey)) {
        var rTitleValues = this.titleValues;
        return intl.t(descriptionKey, rTitleValues);
      }

      return '...';
    }),
    titleValues: Ember.computed('billCcNumberLastFour', 'billCcExpiration', 'billBankName', 'billBankType', 'lastFourBillBankNumber', 'billCcType', 'billType.id', function () {
      if (this.get('billType.isCreditCard')) {
        var lastFour = '';

        if (this.billCcNumber && this.billCcNumberLastFour) {
          lastFour = this.billCcNumberLastFour;
        }

        return {
          method: this.billCcType,
          last_four: lastFour,
          expiration: this.displayBillCcExpiration
        };
      } else if (this.get('billType.isBankAccount')) {
        return {
          method: (this.billBankName ? this.billBankName : '') + ' ' + this.billBankType,
          last_four: this.lastFourBillBankNumber
        };
      }

      return {};
    }),
    billCcExpirationYear: Ember.computed('billCcExpirationSmallYear', {
      get() {
        var expireSmallYear = this.billCcExpirationSmallYear;

        if (!Ember.isEmpty(expireSmallYear)) {
          return expireSmallYear;
        }

        return '';
      },

      set(key, value) {
        if (this.billCcExpiration) {
          var expireMonth = this.billCcExpiration.slice(0, 2);
          var expirationSmallYear = Ember.isEmpty(value) ? '' : value.length > 2 ? value.slice(2, 4) : value;
          this.set('billCcExpiration', "".concat(expireMonth).concat(expirationSmallYear));
        }

        return value;
      }

    }),
    billCcExpirationSmallYear: Ember.computed('billCcExpiration', function () {
      var expire = this.billCcExpiration;

      if (!Ember.isEmpty(expire)) {
        return expire.slice(2, 4);
      }

      return '';
    }),
    billCcExpirationMonth: Ember.computed('billCcExpiration', {
      get() {
        var expire = this.billCcExpiration;

        if (!Ember.isEmpty(expire)) {
          return expire.slice(0, 2);
        }

        return '';
      },

      set(key, value) {
        var expireSmallYear = this.billCcExpirationSmallYear;
        var expireMonth = Ember.isEmpty(value) ? '' : value.length > 2 ? value.slice(2, 4) : value;
        this.set('billCcExpiration', "".concat(expireMonth).concat(expireSmallYear));
        return value;
      }

    }),
    cardType: Ember.computed('billCcType', {
      get() {
        return _cardTypes.default.fromAPI(this.billCcType);
      },

      set(key, value) {
        this.set('billCcType', _cardTypes.default.fromUI(value));
        return value;
      }

    }),
    displayBillCcExpiration: Ember.computed('billCcExpirationMonth', 'billCcExpirationSmallYear', function () {
      return this.billCcExpirationMonth + '/' + this.billCcExpirationSmallYear;
    }),
    lastFourBillBankNumber: Ember.computed('billBankNumber', function () {
      var bankNumber = this.billBankNumber || '';

      if (bankNumber) {
        return bankNumber.toString().slice(-4);
      } else return '';
    }),
    isCustomerDefault: Ember.computed('customer.defaultCustomerBilling', function () {
      return this.id === this.get('customer.defaultCustomerBilling.id');
    }),
    isCustomerDefaultIcon: Ember.computed('customer.defaultCustomerBilling', function () {
      return this.isCustomerDefault ? 'icn-circle-check' : '';
    })
  });

  _exports.default = _default;
});