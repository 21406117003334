define("securitytrax-ember-ui/models/base_models/monitoring-company-config", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    usernameId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    automaticSyncingDisabled: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    additionalDefaultSiteOptions: (0, _model.attr)('json', {
      'readOnly': false
    }),
    additionalDefaultSystemOptions: (0, _model.attr)('json', {
      'readOnly': false
    }),
    availableEContractTemplates: (0, _model.attr)('json', {
      'readOnly': false
    }),
    cacheEnabled: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    commonFundingEnforceEcontractId: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    confirmNewPassword: (0, _model.attr)('string', {
      'readOnly': false
    }),
    corpacctServcoNo: (0, _model.attr)('string', {
      'readOnly': false
    }),
    createTransmitterForTwoWay: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    customEventIdMappings: (0, _model.attr)('json', {
      'readOnly': false
    }),
    customSystemTypes: (0, _model.attr)('json', {
      'readOnly': false
    }),
    dealerNumber: (0, _model.attr)('string', {
      'readOnly': false
    }),
    dealerPasscode: (0, _model.attr)('string', {
      'readOnly': false
    }),
    dealerWebNonSystemUserPassword: (0, _model.attr)('string', {
      'readOnly': false
    }),
    dealerWebNonSystemUserUsername: (0, _model.attr)('string', {
      'readOnly': false
    }),
    defaultAutoDraftEquipmentCost: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    defaultCommissioningStatus: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['Active', 'Deactivated', 'Inactive', 'Pending']
    }),
    defaultCustomerInformation: (0, _model.attr)('json', {
      'readOnly': false
    }),
    defaultDealerNumber: (0, _model.attr)('string', {
      'readOnly': false
    }),
    defaultEquipmentPanel: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['2GIG ADC', 'AD LYNX', 'AD LYNX PLUS', 'AD LYNX PLUS GSM', 'AD LYNX PLUS+DSC CEL', 'AD LYNX TOUCH', 'AD LYNX TOUCH GSM', 'AD LYNX+DSC CELL', 'AD V15P', 'AD V15P+DSC CELL', 'AD V20P', 'AD V20P+DSC CELL', 'AD V250FBP', 'AD V250FBP+DSC CELL', 'AD V50+DSC CELL', 'AD V50P', 'ADT AIO COMMAND ADC', 'ADT HYBRD COMMND ADC', 'DIY IMPASSA CELL-H', 'DIY IMPASSA CELL-R', 'DSC 1555', 'DSC 1555+CELL', 'DSC 1555MX', 'DSC 1616', 'DSC 1616+CELL', 'DSC 1832', 'DSC 1832+CELL', 'DSC 1864', 'DSC 1864+CELL', 'DSC 9047', 'DSC 9047+CELL', 'DSC 9057 IMPASSA', 'DSC 9057 IMPASSA CEL', 'DSC 9155', 'DSC 9155+CELL', 'DSC TOUCH 9067 ADC', 'DSC1555MX+CELL', 'GE CON 3', 'GE CON 3+DSC CELL', 'GE CON 4', 'GE CON 4+DSC CELL', 'GE CON EX', 'GE CON EX+DSC CELL', 'GE SIMON 3', 'GE SIMON 3+DSC CELL', 'GE SIMON XT', 'GE SIMON XT+DSC CELL', 'IMPASSA ADC', 'QOLSYS 319P ADC', 'QOLSYS 345P ADC', 'QOLSYS 433P ADC', 'SW3000', 'SW3000+DSC CELL', 'TOTAL SECURITY (TS)']
    }),
    defaultLeadSource: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['AD EXPO', 'AD LENDTR', 'AAA', 'AARP', 'AARPMC', 'AD ABILIZ', 'AD ALSTATE', 'AD FARM B', 'AD HOMELNK', 'AD QUINSTR', 'AD RESIWEB', 'AD TRANSFR', 'ALLSTATE1', 'ALLSTATE2', 'AOL', 'BEAZER', 'BILLSTF', 'CENDANT', 'CHILDSN', 'EXPO', 'EXT', 'AD DIRECTV', 'FINOPT', 'HRBLOCK', 'HOMEBAS', 'HMEDPT', 'NAREB', 'NPWS', 'AD NAREB', 'QUINSTREET', 'RNGRDR1', 'RNGRDR2', 'RD VNTR', 'AD RESALE', 'REWARDS', 'SELFGEN', 'TFBF', 'TNFB', 'TELMKT', 'USAA', 'WALMART', 'WAVE3', 'XYLO', 'SIGN', 'YELLOW', 'ZARING']
    }),
    defaultRadioType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['DSC', 'TG', 'HW', 'ADC']
    }),
    development: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    deviceTypeWhitelist: (0, _model.attr)('json', {
      'readOnly': false
    }),
    listenInDeviceTypeWhitelist: (0, _model.attr)('json', {
      'readOnly': false
    }),
    doNotSendAccountCellPhoneNumberAsCallToVerifyPhoneTwo: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    eContractIncludeAllEquipment: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    enabled: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    environment: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['Development', 'Pre Production', 'Production']
    }),
    equipmentMappings: (0, _model.attr)('json', {
      'readOnly': false
    }),
    equipmentPanelBlacklist: (0, _model.attr)('json', {
      'readOnly': false
    }),
    equipmentProductCodes: (0, _model.attr)('json', {
      'readOnly': false
    }),
    errorEmailAddresses: (0, _model.attr)('json', {
      'readOnly': false
    }),
    holdsPreventEContractCreation: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    installServcoNo: (0, _model.attr)('string', {
      'readOnly': false
    }),
    keyCode: (0, _model.attr)('string', {
      'readOnly': false
    }),
    leadSourceBlacklist: (0, _model.attr)('json', {
      'readOnly': false
    }),
    newPassword: (0, _model.attr)('string', {
      'readOnly': false
    }),
    panelTypeWhitelist: (0, _model.attr)('json', {
      'readOnly': false
    }),
    partnerId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    passphrase: (0, _model.attr)('string', {
      'readOnly': false
    }),
    password: (0, _model.attr)('string', {
      'readOnly': false
    }),
    paymentReceivedFillsSopDepositAmountWithActivationFee: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    preCheckOrderEnabled: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    preCheckOrderExecuteStyle: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['manual', 'automatic', 'automatic-pre-existing-check']
    }),
    receiverLineDesignationWhitelist: (0, _model.attr)('json', {
      'readOnly': false
    }),
    safelinkDefaultRenewalTerm: (0, _model.attr)('string', {
      'readOnly': false
    }),
    safelinkDefaultTransmissionFormat: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['Telco', 'GSM', 'Internet']
    }),
    safelinkPassword: (0, _model.attr)('string', {
      'readOnly': false
    }),
    safelinkUsername: (0, _model.attr)('string', {
      'readOnly': false
    }),
    sendInstallDateAsEstimatedCompletionDateIfPossible: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    sendMonthlyMonitoringChargeAsTotalMmr: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    sendTechnicianAsSalesRepIfPossible: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    siteGroupLinks: (0, _model.attr)('json', {
      'readOnly': false
    }),
    siteGroupNum: (0, _model.attr)('string', {
      'readOnly': false
    }),
    suppressEquipmentWarnings: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    templates: (0, _model.attr)('json', {
      'readOnly': false
    }),
    transmitterTypeWhitelist: (0, _model.attr)('json', {
      'readOnly': false
    }),
    username: (0, _model.attr)('string', {
      'readOnly': false
    })
  });

  _exports.default = _default;
});