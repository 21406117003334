define("securitytrax-ember-ui/components/calendar-glimmer/calendar-helpers/calendar-page/calendar-page-user", ["exports", "moment", "securitytrax-ember-ui/components/calendar-glimmer/calendar-helpers/calendar-page/calendar-page", "ember-concurrency", "securitytrax-ember-ui/utils/calendar-hours", "securitytrax-ember-ui/components/calendar-glimmer/calendar-helpers/calendar-page/calendar-row"], function (_exports, _moment, _calendarPage, _emberConcurrency, _calendarHours, _calendarRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CalendarPageUser = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = (0, _emberConcurrency.task)(function* () {
    if (this.user) {
      this.isTech = yield this.user.isTech;
      this.isSales = yield this.user.isSalesRep;
    }
  }), _dec8 = (0, _emberConcurrency.task)(function* () {
    if (this.user) {
      this.calendarEvents = yield this.user.calendarEvents.toArray();
    }

    if (!this.hours) {
      var dates = this.selectedDates.toArray();
      var minStartTime = (0, _moment.default)(dates[0].date, 'MM/DD/YYYY').hour(8).startOf('hour');
      var maxEndTime = (0, _moment.default)(dates[0].date, 'MM/DD/YYYY').hour(20).startOf('hour');
      var halfHours = yield (0, _calendarHours.default)(minStartTime, maxEndTime);
      this.hours = halfHours;
    }
  }), _dec9 = (0, _emberConcurrency.task)(function* () {
    var startDate = undefined;
    var endDate = undefined;
    var dates = this.selectedDates.toArray();

    if (dates) {
      startDate = dates.firstObject.date;
      endDate = dates.lastObject.date;
    }

    var startOfDay = startDate ? (0, _moment.default)(startDate, 'MM/DD/YYYY').startOf('day') : (0, _moment.default)().startOf('day');
    var endOfDay = endDate ? (0, _moment.default)(endDate, 'MM/DD/YYYY').endOf('day') : (0, _moment.default)().endOf('day');
    var calUser = yield this.user.id;
    var userCat = this.userType === 'sales_rep' ? 'and calendar_category = \'sale_schedule\'' : 'and calendar_category = \'tech_schedule\'';

    if (this.userType === 'all') {
      userCat = '';
    }

    var userFilter = '';

    if (calUser === 'inactive') {
      userFilter = ' and calendar_user.active = false';
    } else {
      userFilter = ' and calendar_user = \'' + calUser + '\'';
    }

    var defaultFilter = '(start_time >= \'' + startOfDay.format('YYYY-MM-DDTHH:mm:ss') + '\') and (start_time <= \'' + endOfDay.format('YYYY-MM-DDTHH:mm:ss') + '\')' + userCat + userFilter;
    var calendarEvents = yield this.store.query('calendarEvent', {
      filter: defaultFilter
      /*  + (this.get('filters.length') > 0 ? (' and ' + (this.filters.join(' and '))) : ''), */
      ,
      include: 'calendar_user,calendar_event_detail_type,calendar_event_type.calendar_event_detail_types,customer,work_order',
      sort: 'start_time, duration'
    });

    if (!this.hours) {
      var minStartTime = (0, _moment.default)(startDate, 'MM/DD/YYYY').hour(this.startTime.hour()).startOf('hour');
      var maxEndTime = (0, _moment.default)(startDate, 'MM/DD/YYYY').hour(this.endTime.hour()).startOf('hour');

      if (calendarEvents.meta.min_datetime && minStartTime > (0, _moment.default)(calendarEvents.meta.min_datetime).startOf('hour')) {
        minStartTime = (0, _moment.default)(calendarEvents.meta.min_datetime).startOf('hour');
      }

      if (calendarEvents.meta.max_datetime && maxEndTime < (0, _moment.default)(calendarEvents.meta.max_datetime).add(1, 'hour').startOf('hour')) {
        var maxEndTimeTemp = (0, _moment.default)(calendarEvents.meta.max_datetime).add(1, 'hour').startOf('hour');

        if (maxEndTimeTemp.isSameOrBefore(maxEndTime.endOf('day'))) {
          maxEndTime = new _moment.default(minStartTime).set('hour', maxEndTimeTemp.hour());
        } else {
          maxEndTime = new _moment.default(minStartTime).add(1, 'day').startOf('day');
        }
      }

      var halfHours = (0, _calendarHours.default)(minStartTime, maxEndTime);
      this.hours = halfHours;
    }

    this.calendarEvents = calendarEvents.toArray();
  }), _dec10 = (0, _emberConcurrency.task)(function* () {
    if (this.didUpdate) {
      yield this.getCalendarEventsAndHours.perform();
    }

    var rows = [];
    var calendarEventsByRow = yield this.calendarEventsByRow;
    var dates = this.selectedDates.toArray();

    if (dates) {
      if (dates.length > 0) {
        dates.forEach(date => {
          var user = this.user;
          var dateId = (0, _moment.default)(date.date).format('M-D-YYYY');
          var userEvents = calendarEventsByRow[dateId] || [];
          var row = new _calendarRow.default({
            id: dateId,
            user: user,
            isSalesRep: this.isSales,
            isTech: this.isTech,
            type: 'user',
            date: date,
            calendarPage: this,
            calendarEvents: userEvents
          });
          rows.push(row);
        });
      }
    }

    this.rows = rows;
    this.didUpdate = false;
    return this.rows;
  }), _dec11 = (0, _emberConcurrency.task)(function* () {
    try {
      var byDateCalendarEvents = [];
      yield this.getCalendarEventsAndHours.perform();
      var calendarEvents = this.calendarEvents;
      calendarEvents.forEach(currEvent => {
        var currEventID = (0, _moment.default)(currEvent.startTime).format('M-D-YYYY');

        if (byDateCalendarEvents[currEventID] == undefined) {
          byDateCalendarEvents[currEventID] = [];
        }

        byDateCalendarEvents[currEventID].pushObject(currEvent);
      });
      return byDateCalendarEvents;
    } catch (error) {
      (false && Ember.warn('calendarEventsByDateTask failed ' + error, {
        id: 'calendar.calendarGrid.calendarEventsByDateTask'
      }));
    }
  }), (_class = (_temp = class CalendarPageUser extends _calendarPage.default {
    constructor(routeArguments, requiredArguments) {
      super(...arguments);

      _defineProperty(this, "user", void 0);

      _defineProperty(this, "startTime", void 0);

      _defineProperty(this, "endTime", void 0);

      _initializerDefineProperty(this, "dates", _descriptor, this);

      _initializerDefineProperty(this, "calendarData", _descriptor2, this);

      _defineProperty(this, "CalendarDatePickerService", null);

      _initializerDefineProperty(this, "userType", _descriptor3, this);

      _initializerDefineProperty(this, "isTech", _descriptor4, this);

      _initializerDefineProperty(this, "isSales", _descriptor5, this);

      _initializerDefineProperty(this, "maxIndex", _descriptor6, this);

      _initializerDefineProperty(this, "userTypeTask", _descriptor7, this);

      _initializerDefineProperty(this, "getHoursTask", _descriptor8, this);

      _initializerDefineProperty(this, "getCalendarEventsAndHours", _descriptor9, this);

      _initializerDefineProperty(this, "getPageRowsTask", _descriptor10, this);

      _initializerDefineProperty(this, "calendarEventsByRowTask", _descriptor11, this);

      if (requiredArguments && requiredArguments.user) {
        this.user = requiredArguments.user;
        this.type = 'user';
        this.id = requiredArguments.user.id;
      }

      if (requiredArguments && requiredArguments.CalendarDatePickerService) {
        this.CalendarDatePickerService = requiredArguments.CalendarDatePickerService;
      }

      if (requiredArguments && requiredArguments.startTime) {
        this.startTime = requiredArguments.startTime;
      }

      if (requiredArguments && requiredArguments.endTime) {
        this.endTime = requiredArguments.endTime;
      }

      if (requiredArguments && requiredArguments.store) {
        this.store = requiredArguments.store;
      }

      if (requiredArguments && requiredArguments.calendarData) {
        this.calendarData = requiredArguments.calendarData;
      }

      this.didUpdate = false;
      this.userTypeTask.perform();
    }

    get selectedDates() {
      this.CalendarDatePickerService.sortDates();
      return this.CalendarDatePickerService.selectedDates;
    }

    get pageRows() {
      return this.getPageRowsTask.unlinked().perform();
    }

    get calendarEventsByRow() {
      return this.calendarEventsByRowTask.perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dates", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "calendarData", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "userType", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isTech", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isSales", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "maxIndex", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 20;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "userTypeTask", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "getHoursTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "getCalendarEventsAndHours", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "getPageRowsTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "calendarEventsByRowTask", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CalendarPageUser;
});