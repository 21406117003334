define("securitytrax-ember-ui/components/pages/administration/company-settings/global-settings/notifications/component", ["exports", "securitytrax-ember-ui/components/pages/administration/company-settings/global-settings/component", "securitytrax-ember-ui/mixins/notifications"], function (_exports, _component, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_notifications.default, {
    tagName: '',
    grants: Ember.inject.service(),
    groupedNotifications: Ember.computed('model.notifications', function () {
      var groupedArray = {};
      var notifications = this.get('model.notifications');
      notifications.uniqBy('category').map(notification => {
        var name = notification.get('category').replace(/[_,-]/g, ' ').replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toLowerCase() + txt.substr(1).toLowerCase();
        });
        groupedArray[name] = {
          items: notifications.filterBy('category', notification.get('category'))
        };
      });
      return groupedArray;
    }),
    techNotificationFormOriginalModels: Ember.computed('company', 'grants.userGrants', function () {
      return Ember.Object.create({
        company: {
          model: this.company,
          copyRelationMap: {
            notificationSubscriptions: {
              doCopy: true,
              relationships: {
                notification: {
                  readOnly: true
                }
              }
            }
          }
        }
      });
    }),
    salesNotificationFormOriginalModels: Ember.computed('company', 'grants.userGrants', function () {
      return Ember.Object.create({
        company: {
          model: this.company,
          copyRelationMap: {
            notificationSubscriptions: {
              doCopy: true,
              relationships: {
                notification: {
                  readOnly: true
                }
              }
            }
          }
        }
      });
    }),
    userNotificationFormOriginalModels: Ember.computed('company', 'grants.userGrants', function () {
      return Ember.Object.create({
        company: {
          model: this.company,
          copyRelationMap: {
            notificationSubscriptions: {
              doCopy: true,
              relationships: {
                notification: {
                  readOnly: true
                }
              }
            }
          }
        }
      });
    }),
    inventoryNotificationFormOriginalModels: Ember.computed('company', 'grants.userGrants', function () {
      return Ember.Object.create({
        company: {
          model: this.company,
          copyRelationMap: {
            notificationSubscriptions: {
              doCopy: true,
              relationships: {
                notification: {
                  readOnly: true
                }
              }
            }
          }
        }
      });
    })
  });

  _exports.default = _default;
});