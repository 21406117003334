define("securitytrax-ember-ui/components/calendar-glimmer/calendar-grid/component", ["exports", "@glimmer/component", "moment", "ember-concurrency", "jquery", "lodash", "securitytrax-ember-ui/mixins/forms/calendar-event-reusable"], function (_exports, _component, _moment, _emberConcurrency, _jquery, _lodash, _calendarEventReusable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CalendarGrid = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service('events/calendar-events'), _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._action, _dec15 = Ember.computed('args.currDuration'), _dec16 = Ember.computed('CalendarDatePickerService.{selectedDates},calendarPage.{rows,pendingAddCalendarEvent}'), _dec17 = (0, _emberConcurrency.task)(function* () {
    var calendarPage = this.args.calendarPage;
    calendarPage.userType = this.args.userType;
    var rows = yield calendarPage.pageRows;
    this.spinner = false;
    return rows;
  }), _dec18 = Ember.computed('CalendarDatePickerService.{selectedDates},calendarPage.{rows,pendingAddCalendarEvent}'), _dec19 = (0, _emberConcurrency.task)(function* () {
    var theGrid = document.querySelector("#calendar-main-".concat(this.args.gridIndex));

    if (theGrid) {
      yield (0, _emberConcurrency.timeout)(500);
      theGrid.scrollLeft = this.currentScrollPos.left;
    }
  }), _dec20 = (0, _emberConcurrency.task)(function* () {
    var calendarPage = this.args.calendarPage;
    calendarPage.userType = this.args.userType;
    yield calendarPage.getCalendarEventsAndHours.perform();
    var calendarEvents = calendarPage.calendarEvents;
    this.spinner = false;
    return calendarEvents;
  }), _dec21 = Ember._action, _dec22 = Ember._action, _dec23 = Ember._action, _dec24 = Ember._action, _dec25 = Ember._action, _dec26 = Ember._action, _dec27 = Ember._action, _dec28 = Ember._action, _dec29 = Ember._action, _dec30 = Ember._action, _dec31 = Ember._action, (_class = (_temp = class CalendarGrid extends _component.default {
    /**
     * The milliseconds of 30 minutes. Used to calculate column to print and width to select
     */
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "calPointer", _descriptor, this);

      _initializerDefineProperty(this, "screen", _descriptor2, this);

      _initializerDefineProperty(this, "CalendarDragDropService", _descriptor3, this);

      _initializerDefineProperty(this, "CalendarDataService", _descriptor4, this);

      _initializerDefineProperty(this, "CalendarEventsService", _descriptor5, this);

      _initializerDefineProperty(this, "inViewport", _descriptor6, this);

      _initializerDefineProperty(this, "maxIndex", _descriptor7, this);

      _initializerDefineProperty(this, "isFixed", _descriptor8, this);

      _initializerDefineProperty(this, "spinner", _descriptor9, this);

      _initializerDefineProperty(this, "dragActive", _descriptor10, this);

      _initializerDefineProperty(this, "firstHour", _descriptor11, this);

      _initializerDefineProperty(this, "_userType", _descriptor12, this);

      _initializerDefineProperty(this, "currentScrollPos", _descriptor13, this);

      _defineProperty(this, "allowDragAppointment", undefined);

      _defineProperty(this, "allowResizeAppointment", undefined);

      _defineProperty(this, "calendarDate", undefined);

      _defineProperty(this, "calendarEventModelId", null);

      _defineProperty(this, "viewScroller", null);

      _defineProperty(this, "currentDate", null);

      _defineProperty(this, "confirmMoveUserDialogOpen", undefined);

      _defineProperty(this, "offsetLeft", 0);

      _defineProperty(this, "mainHeaderStart", 0);

      _defineProperty(this, "showSummary", false);

      _defineProperty(this, "baseGridMillis", _moment.default.duration(30, 'minutes').asMilliseconds());

      _defineProperty(this, "rowHours", undefined);

      _initializerDefineProperty(this, "getRowsTask", _descriptor14, this);

      _initializerDefineProperty(this, "setScrollTask", _descriptor15, this);

      _initializerDefineProperty(this, "getAppointmentsTask", _descriptor16, this);

      this.allowDragAppointment = true;
      this.allowResizeAppointment = this.args.allowResizeAppointment;
      this.args.calendarPage.pendingAddCalendarEvent = undefined;
      this.args.calendarPage.showUsers(this.args.newUsers);
      this.args.calendarPage.userType = this.args.userType;
      this.CalendarEventsService.on('refresh-page', this, '_refreshPageCallback');
      this.CalendarEventsService.on('refresh-user-list', this, '_updateUserList');
      this.CalendarEventsService.on('calendar-event-saved', this, '_calendarEventSavedCallback');
      this.CalendarEventsService.on('selectDate', this, '_selectDateCallback');
      this.CalendarEventsService.on('deselectDate', this, '_deselectDateCallback');
      this.CalendarEventsService.on('resetDatePicker', this, '_resetStateDatepicker');
      this.CalendarEventsService.on('loadRows', this, '_loadRows');
      this.CalendarEventsService.on('cancel-add', this, '_cancelAdd'); //set the currentSrollPos

      this.currentScrollPos = Ember.Object.create({
        top: 0,
        left: 0,
        x: 0,
        y: 0
      });
    }

    get gridClasses() {
      if (this.calPointer.clickToScroll) {
        return 'calendar__page--mouseIsDown';
      }

      return '';
    }

    willDestroy() {
      this.args.calendarPage.didUpdate = true;
      this.args.calendarPage.pendingAddCalendarEvent = undefined;
      this.CalendarEventsService.off('loadRows', this, '_loadRows');
      this.CalendarEventsService.off('selectDate', this, '_selectDateCallback');
      this.CalendarEventsService.off('deselectDate', this, '_deselectDateCallback');
      this.CalendarEventsService.off('resetDatePicker', this, '_resetStateDatepicker');
      this.CalendarEventsService.off('refresh-page', this, '_refreshPageCallback');
      this.CalendarEventsService.off('cancel-add', this, '_cancelAdd');
      this.CalendarEventsService.off('refresh-user-list', this, '_updateUserList');
      this.unbindScroll();
      this.CalendarDataService.resetMaxIndex();
      this.args.calendarPage.resetMaxIndex();
    }

    _selectDateCallback() {
      if (this.args.isUser) {
        Ember.notifyPropertyChange(this, 'rows');
      }
    }

    _deselectDateCallback() {
      if (this.args.isUser) {
        Ember.notifyPropertyChange(this, 'rows');
      }
    }

    _resetStateDatepicker() {
      if (this.args.isUser) {
        Ember.notifyPropertyChange(this, 'rows');
      }
    }

    _cancelAdd() {
      this.cancelScheduleAppointment();
    }

    _loadRows(id) {
      if (id == this.args.calendarPage.id) {
        this.spinner = true;
        Ember.notifyPropertyChange(this, 'rows');
      }
    }

    didExitViewport() {
      this.isFixed = true;
    }

    didEnterViewport() {
      this.isFixed = false;
    }

    didEnterViewportUser() {
      this.CalendarDataService.incrementMaxIndex();
    }

    bindHorizontalScrolling() {
      Ember.set(this.calPointer, 'mouseDown', false);

      var _this = this;

      var theGrid = document.querySelector("#calendar-main-".concat(this.args.gridIndex));

      var horScroll = function () {
        return _this._onScrollHorizontal(theGrid);
      };

      (0, _jquery.default)("#calendar-main-".concat(this.args.gridIndex)).bind('scroll', _lodash.default.throttle(horScroll, 10));
      this.setScrollTask.perform();
    }

    unbindScroll() {
      (0, _jquery.default)("#calendar-main-".concat(this.args.gridIndex)).unbind('scroll');
    }

    _onScrollHorizontal(theGrid) {
      this.CalendarEventsService.trigger('scrollHorizontal', this.args.gridIndex, theGrid.scrollLeft);
    }

    _refreshPageCallback(pageId) {
      if (pageId) {
        if (pageId === this.args.calendarPage.id) {
          Ember.notifyPropertyChange(this, 'rows');
        }
      } else {
        this.args.calendarPage.didUpdate = true;

        if (this.args.showListView) {
          Ember.notifyPropertyChange(this, 'appointments');
        } else {
          Ember.notifyPropertyChange(this, 'rows');
        }
      }
    }

    _updateUserList() {
      this.args.calendarPage.showUsers(this.args.newUsers);
      this.CalendarEventsService.trigger('refresh-page');
      this.args.calendarPage.didUpdate = true;
    }

    _calendarEventSavedCallback() {
      this.args.calendarPage.didUpdate = true;
      Ember.notifyPropertyChange(this, 'rows');
    }

    get classNames() {
      if (this.dragActive) {
        return 'dragActive';
      }

      return '';
    }

    get calendarEvents() {
      return this.calendarEventsTask.perform();
    }

    get canAddAppointment() {
      if (this.args.allowAddAppointment === false) {
        return 'no-add-appointment';
      } else {
        return '';
      }
    }

    get calculatedHoverWidth() {
      var hoverWidth = this.args.currDuration / 60 / 60 * 2 - 1;
      return hoverWidth;
    }

    get rows() {
      return this.getRowsTask.perform();
    }

    get appointments() {
      return this.getAppointmentsTask.perform();
    }

    openScheduleAppointmentModal() {
      var pendingCalendarEvent = this.args.calendarPage.pendingAddCalendarEvent;
      this.args.onAddNewCalendarEvent(pendingCalendarEvent);
    }

    cancelScheduleAppointment() {
      if (this.args.calendarPage.pendingAddCalendarEvent) {
        this.args.calendarPage.removePendingCalendarEvent();
      }
    }

    mouseUp() {
      this.calPointer.handleDrag = false;
    }

    showSummary() {
      if (this.showSummary) {
        this.showSummary = false;
      } else {
        this.showSummary = true;
      }
    }

    startAddingNewCalendarEvent(currIndex, hourData, user) {
      if (this.args.allowAddAppointment) {
        var duration = this.args.currDuration || 7200;
        var calendarEventReusable = Ember.Object.extend(_calendarEventReusable.default); //MATT CAL TODO Change from mixin

        var calendarDate = (0, _moment.default)(this.args.calendarPage.date);

        if (calendarDate && hourData) {
          hourData.momentTime.set({
            year: calendarDate.year(),
            month: calendarDate.month(),
            date: calendarDate.date()
          });
        } else {
          calendarDate.hour(8);
          calendarDate.minute(0);
          calendarDate.second(0);
        }

        var newCalendarEvent = calendarEventReusable.create({
          calendarCategory: this.args.userType === 'sales_rep' ? 'sale_schedule' : 'tech_schedule',
          duration: duration,
          startTime: hourData ? hourData.momentTime : calendarDate,
          status: 'confirmed',
          calendarUser: user,
          isPendingAdd: true,
          calendarEventDetailType: this.args.currCalendarEventDetailType,
          calendarEventType: this.args.currCalendarEventType
        });

        if (this.selectedSchedulingProfile) {
          newCalendarEvent.profile = this.selectedSchedulingProfile;
        }

        this.args.calendarPage.addPendingAddCalendarEvent.perform(newCalendarEvent);
      }
    }

    gridInserted(element) {
      Ember.set(this, 'mouseDown', false);
      this.bindIntersectionObserverUserGroups(element);
    }

    bindIntersectionObserverUserGroups(element) {
      if (this.args.gridIndex == this.CalendarDataService.maxIndex - 1) {
        var viewportTolerance = {
          bottom: 300
        };
        var intersectionThreshold = [0, 1.0];
        var scrollableArea = this.args.scrollContext;
        var {
          onEnter
        } = this.inViewport.watchElement(element, {
          viewportTolerance,
          intersectionThreshold,
          scrollableArea
        });
        onEnter(this.didEnterViewportUser.bind(this));
      }
    }

    bindIntersectionObserver(element) {
      var viewportTolerance = {
        top: -1,
        left: 0,
        bottom: 0,
        right: 0
      };
      var intersectionThreshold = [0, 1.0];
      var scrollableArea = this.args.scrollContainer;
      var {
        onEnter,
        onExit
      } = this.inViewport.watchElement(element, {
        viewportTolerance,
        intersectionThreshold,
        scrollableArea
      });
      onEnter(this.didEnterViewport.bind(this));
      onExit(this.didExitViewport.bind(this));
    }

    gridMouseDownHandler(e) {
      if (!this.calPointer.handleDrag || !this.calPointer.appointmentDrag) {
        Ember.set(this.calPointer, 'clickToScroll', true);
        var theGrid = document.querySelector("#calendar-main-".concat(this.args.gridIndex));

        if (theGrid) {
          this.currentScrollPos.left = theGrid.scrollLeft;
        }

        this.currentScrollPos.x = e.clientX;
      }
    }

    gridMouseUpHandler() {
      Ember.set(this.calPointer, 'clickToScroll', false);
    }

    gridMouseMoveHandler(e) {
      if (this.calPointer.handleDrag || this.calPointer.appointmentDrag) {
        Ember.set(this.calPointer, 'clickToScroll', false);
      }

      if (this.calPointer.clickToScroll) {
        var theGrid = document.querySelector("#calendar-main-".concat(this.args.gridIndex));

        if (theGrid) {
          var dx = e.clientX - this.currentScrollPos.x;
          theGrid.scrollLeft = this.currentScrollPos.left - dx;
        }
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "calPointer", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "screen", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "CalendarDragDropService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "CalendarDataService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "CalendarEventsService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "inViewport", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "maxIndex", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 20;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isFixed", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "spinner", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "dragActive", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "firstHour", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "_userType", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "currentScrollPos", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "bindHorizontalScrolling", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "bindHorizontalScrolling"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "calculatedHoverWidth", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "calculatedHoverWidth"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "rows", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "rows"), _class.prototype), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "getRowsTask", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "appointments", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "appointments"), _class.prototype), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "setScrollTask", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "getAppointmentsTask", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "openScheduleAppointmentModal", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "openScheduleAppointmentModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelScheduleAppointment", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "cancelScheduleAppointment"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mouseUp", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "mouseUp"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showSummary", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "showSummary"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startAddingNewCalendarEvent", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "startAddingNewCalendarEvent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "gridInserted", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "gridInserted"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "bindIntersectionObserverUserGroups", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "bindIntersectionObserverUserGroups"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "bindIntersectionObserver", [_dec28], Object.getOwnPropertyDescriptor(_class.prototype, "bindIntersectionObserver"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "gridMouseDownHandler", [_dec29], Object.getOwnPropertyDescriptor(_class.prototype, "gridMouseDownHandler"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "gridMouseUpHandler", [_dec30], Object.getOwnPropertyDescriptor(_class.prototype, "gridMouseUpHandler"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "gridMouseMoveHandler", [_dec31], Object.getOwnPropertyDescriptor(_class.prototype, "gridMouseMoveHandler"), _class.prototype)), _class));
  _exports.default = CalendarGrid;
});