define("securitytrax-ember-ui/components/multi-step-form/forms/administration/system/form-defaults/customer-duplicate-defaults/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CustomerDuplicateDefaults = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
    ['customerInformation', 'personalInformation', 'contactInformation', 'saleInformation', 'centralStation', 'customerInstallation', 'contacts'].forEach(section => {
      this.updateIndeterminate(section);
    });
  }), _dec16 = (0, _emberConcurrency.task)(function* () {
    try {
      ['customerInformation', 'contactInformation', 'saleInformation', 'centralStation', 'customerInstallation', 'contacts'].forEach(section => {
        this.updateIndeterminate(section, true);
      });
      yield this.args.multiStepForm.resetForm(false);
      yield this.args.setFormMode('view');
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err);
    }
  }).drop(), _dec17 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    yield this.args.multiStepForm.validateSyncSaveAll();
    buttonPromise.then(() => {
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
      this.args.setFormMode('view');
    });
  }), _dec18 = Ember._action, _dec19 = Ember._action, (_class = (_temp = class CustomerDuplicateDefaults extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _defineProperty(this, "customerInformationDuplicateDefaults", undefined);

      _defineProperty(this, "contactInformationDuplicateDefaults", undefined);

      _defineProperty(this, "saleInformationDuplicateDefaults", undefined);

      _defineProperty(this, "customerInstallationDuplicateDefaults", undefined);

      _defineProperty(this, "centralStationDuplicateDefaults", undefined);

      _defineProperty(this, "contactsStationDuplicateDefaults", undefined);

      _initializerDefineProperty(this, "customerInformationSelectAll", _descriptor3, this);

      _initializerDefineProperty(this, "contactInformationSelectAll", _descriptor4, this);

      _initializerDefineProperty(this, "saleInformationSelectAll", _descriptor5, this);

      _initializerDefineProperty(this, "centralStationSelectAll", _descriptor6, this);

      _initializerDefineProperty(this, "customerInstallationSelectAll", _descriptor7, this);

      _initializerDefineProperty(this, "contactsSelectAll", _descriptor8, this);

      _initializerDefineProperty(this, "customerInformationIndeterminate", _descriptor9, this);

      _initializerDefineProperty(this, "contactInformationIndeterminate", _descriptor10, this);

      _initializerDefineProperty(this, "saleInformationIndeterminate", _descriptor11, this);

      _initializerDefineProperty(this, "centralStationIndeterminate", _descriptor12, this);

      _initializerDefineProperty(this, "customerInstallationIndeterminate", _descriptor13, this);

      _initializerDefineProperty(this, "contactsIndeterminate", _descriptor14, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor15, this);

      _initializerDefineProperty(this, "cancelTask", _descriptor16, this);

      _initializerDefineProperty(this, "saveDefaultsTask", _descriptor17, this);

      this.initDataTask.perform();
      this.customerInformationDuplicateDefaults = ['officeLocation', 'accountType', 'businessName', 'businessContact', 'accountClass'];
      this.personalInformationDuplicateDefaults = ['spouseName', 'primaryLanguage', 'birthdate', 'socialSecurityNumber', 'creditScore', 'creditTrackingNumber'];
      this.contactInformationDuplicateDefaults = ['address', 'apartmentSuite', 'city', 'state', 'zipCode', 'county', 'crossStreet', 'homeOwnership', 'primaryPhone', 'cellPhone', 'workPhone', 'otherPhone', 'email', 'bestTimeToContact'];
      this.saleInformationDuplicateDefaults = ['source', 'sourceCampaign', 'salesRepresentative', 'altSalesRepresentative', 'saleDate', 'monitoringPlan', 'monthlyCharge', 'activationFee', 'contractTerm', 'warrantyTerm', 'guardResponse', 'guardResponseFee', 'invoicing', 'rebateCheckAmount', 'affiliate', 'affiliateMemberNumber', 'accountIsCancelled', 'cancelDate', 'cancelReason'];
      this.centralStationDuplicateDefaults = ['monitoredBy', 'accountNumber', 'dealerNumber', 'confirmationNumber', 'abortCode', 'twoWayVoice', 'receiverNumber', 'customerNumber', 'serialMan'];
      this.customerInstallationDuplicateDefaults = ['isInstalled'];
      this.contactsDuplicateDefaults = ['emergencyContacts', 'agencyContacts'];
    }

    selectAll(section, value) {
      Ember.set(this, section + 'Indeterminate', false);
      Ember.get(this, section + 'DuplicateDefaults').forEach(field => {
        Ember.set(this.args.multiStepForm.formCopiedModels.customerDuplicateDefault.model, field, !value);
      });
    }

    updateIndeterminate(section, useOriginalModel = false) {
      var sectionArray = Ember.get(this, section + 'DuplicateDefaults');
      var model = useOriginalModel ? this.args.multiStepForm.formOriginalModels.customerDuplicateDefault.model : this.args.multiStepForm.formCopiedModels.customerDuplicateDefault.model;
      var unCheckedFields = sectionArray.filter(field => {
        return !Ember.get(model, field);
      });
      Ember.set(this, section + 'SelectAll', unCheckedFields.length === 0);
      Ember.set(this, section + 'Indeterminate', unCheckedFields.length > 0 && unCheckedFields.length !== sectionArray.length);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "customerInformationSelectAll", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "contactInformationSelectAll", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "saleInformationSelectAll", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "centralStationSelectAll", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "customerInstallationSelectAll", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "contactsSelectAll", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "customerInformationIndeterminate", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "contactInformationIndeterminate", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "saleInformationIndeterminate", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "centralStationIndeterminate", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "customerInstallationIndeterminate", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "contactsIndeterminate", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "cancelTask", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "saveDefaultsTask", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "selectAll", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "selectAll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateIndeterminate", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "updateIndeterminate"), _class.prototype)), _class));
  _exports.default = CustomerDuplicateDefaults;
});