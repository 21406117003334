define("securitytrax-ember-ui/models/customer-payable", ["exports", "securitytrax-ember-ui/models/base_models/customer-payable", "ember-concurrency", "moment"], function (_exports, _customerPayable, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerPayable.default.extend({
    entity: Ember.computed('customerEntity', 'userEntity', 'leadCompanyEntity', 'leadCompanyCampaignEntity', 'governmentRegulationEntity', function () {
      return this.entityTask.perform();
    }),
    entityTask: (0, _emberConcurrency.task)(function* () {
      var entityData = yield (0, _emberConcurrency.hash)({
        customerEntity: this.customerEntity,
        userEntity: this.userEntity,
        leadCompanyEntity: this.leadCompanyEntity,
        leadCompanyCampaignEntity: this.leadCompanyCampaignEntity,
        governmentRegulationEntity: this.governmentRegulationEntity
      });
      return entityData.customerEntity || entityData.userEntity || entityData.leadCompanyEntity || entityData.leadCompanyCampaignEntity || entityData.governmentRegulationEntity;
    }),
    entityName: Ember.computed('entity', function () {
      return this.entityNameTask.perform();
    }),
    entityNameTask: (0, _emberConcurrency.task)(function* () {
      var entity = yield this.entity;
      return entity ? entity.get('name') : '';
    }),
    payeeName: Ember.computed('payOnCustomer', 'payOnTechSchedule', 'payOnCalendarEvent', function () {
      return this.payeeNameTask.perform();
    }),
    payeeNameTask: (0, _emberConcurrency.task)(function* () {
      var hashedData = yield (0, _emberConcurrency.hash)({
        payOnCalendarEvent: this.payOnCalendarEvent,
        entityName: this.entityName
      });

      if (hashedData.payOnCalendarEvent) {
        yield hashedData.payOnCalendarEvent.get('calendarUser');
      }

      return hashedData.payOnCalendarEvent ? hashedData.payOnCalendarEvent.get('payeeDisplayName') : hashedData.entityName;
    }),
    jobDescription: Ember.computed('customer', 'payOnCustomer', 'payOnTechSchedule', function () {
      var techSchedule = this.payOnTechSchedule;
      var customer = this.payOnCustomer;
      var payOn = techSchedule.get('id') ? techSchedule.get('calendarEventType.name') + ' on ' + techSchedule.get('startTimeDisplayDropdowns') : 'Sale on ' + (0, _moment.default)(customer.get('saleDate')).format('MM/DD/YYYY h:mm a');
      return 'Customer ' + this.customer.get('flname') + ' - ' + payOn;
    }),
    amountPaid: Ember.computed('customerPayablePayments.@each.amount', function () {
      return this.amountPaidTask.perform();
    }),
    amountPaidTask: (0, _emberConcurrency.task)(function* () {
      var payments = yield this.customerPayablePayments;
      var amount = 0.00;
      payments.forEach(payment => {
        amount += Number(payment.get('amount'));
      });
      return amount;
    }),
    outstandingAmount: Ember.computed('amount', 'amountPaid', function () {
      return this.outstandingAmountTask.perform();
    }),
    outstandingAmountTask: (0, _emberConcurrency.task)(function* () {
      var amountPaid = yield this.amountPaid;
      return this.amount - amountPaid;
    }),
    payablePaymentStatusClass: Ember.computed(function () {
      return this.payablePaymentStatusClassTask.perform();
    }),
    payablePaymentStatusClassTask: (0, _emberConcurrency.task)(function* () {
      var payrollPaymentStatus = yield this.payrollPaymentStatus;
      return Ember.String.dasherize(payrollPaymentStatus.get('name').toLowerCase());
    })
  });

  _exports.default = _default;
});