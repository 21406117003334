define("securitytrax-ember-ui/components/multi-step-form/forms/invoices/reusable-forms/invoice-line-items/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var InvoiceLineItems = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = (0, _emberConcurrency.task)(function* () {
    Ember.set(this, 'lastIndex', this.args.RowList.listArray.length - 1);

    if (this.args.lockedState) {
      this.lockedState = this.args.lockedState;
    }

    yield this.detailBillableItemsTask.perform();
  }), _dec12 = (0, _emberConcurrency.task)(function* () {
    var promiseArray = [];
    this.args.invoiceBillableItems.forEach(billableItem => {
      promiseArray.push(this.grabBillableItemDataTask.perform(billableItem));
    });
    yield (0, _emberConcurrency.all)(promiseArray);
  }), _dec13 = (0, _emberConcurrency.task)(function* (billableItem) {
    var equipment = yield billableItem.get('equipment');
    var code = billableItem.code;
    var description = billableItem.description;
    var model = this.intl.t('pages.administration.content_management.invoice_billable_item.model') + (equipment && equipment.model ? equipment.model : '--');
    var sku = this.intl.t('pages.administration.content_management.invoice_billable_item.sku') + (equipment && equipment.sku ? equipment.sku : '--');
    var mfg = this.intl.t('pages.administration.content_management.invoice_billable_item.mfg') + (equipment && equipment.manufacturer ? equipment.manufacturer : '--');
    var detailString = this.intl.t('pages.administration.content_management.invoice_billable_item.detailedDescription', {
      code,
      description,
      model,
      sku,
      mfg
    });
    this.detailedBillableItems.push({
      billableItem,
      id: billableItem.get('id'),
      details: detailString,
      code,
      description,
      model,
      sku,
      mfg,
      selected: 'false',
      current: 'false',
      active: billableItem.get('active')
    });
  }), _dec14 = (0, _emberConcurrency.task)(function* (row) {
    yield this.args.RowList.deleteRow.perform(row, 'invoiceLineItem', true);
    Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.invoiceLineItems', this.args.RowList.listArray);
    this.lineItemDeleteId = false;
    Ember.set(this, 'lastIndex', this.args.RowList.listArray.length - 1);
  }), _dec15 = (0, _emberConcurrency.task)(function* (lineItem, index) {
    var hashedData = yield (0, _emberConcurrency.hash)({
      itemClass: lineItem['invoiceBillableItem'] ? lineItem['invoiceBillableItem'].get('invoiceBillableItemClass') : null,
      globalTaxExemptions: this.sessionData.getGlobalTaxExemptions()
    });
    var invoiceBillableItemClass = lineItem ? lineItem.get('invoiceBillableItem.invoiceBillableItemClass.name') : null;
    var isTaxExempt = this.args.customer ? hashedData.globalTaxExemptions[invoiceBillableItemClass] && hashedData.globalTaxExemptions[invoiceBillableItemClass].includes(this.args.customer.get('state')) : false;
    var tax;

    if (this.args.taxRates) {
      tax = !(lineItem.get('invoiceBillableItem.isTaxable') == 0 || lineItem.get('invoiceBillableItem.isTaxable') === null && isTaxExempt) && hashedData.itemClass ? this.args.taxRates.findBy('id', hashedData.itemClass.get('id')) : null;
    } else {
      tax = null;
    }

    var grossAmount = lineItem['invoiceBillableItem'] ? lineItem['invoiceBillableItem'].get('defaultUnitPrice') : 0.00;
    var salesTaxAmount = tax && tax.tax_rate ? tax.tax_rate / 100 * grossAmount : 0.00;
    Ember.set(lineItem, 'quantity', 1);
    Ember.set(lineItem, 'description', lineItem['invoiceBillableItem'] ? lineItem['invoiceBillableItem'].get('description') : '');
    Ember.set(lineItem, 'unitPrice', grossAmount);
    Ember.set(lineItem, 'grossAmount', grossAmount);
    Ember.set(lineItem, 'salesTaxRate', tax && tax.tax_rate ? tax.tax_rate : 0.00000);
    Ember.set(lineItem, 'salesTaxAmount', salesTaxAmount);
    Ember.set(lineItem, 'totalAmount', Number(grossAmount) + Number(salesTaxAmount));
    Ember.set(lineItem, 'isNotTaxable', lineItem.get('invoiceBillableItem.isTaxable') === 0 || lineItem.get('invoiceBillableItem.isTaxable') === null && isTaxExempt);
    this.args.RowList.updateRow(lineItem, index);
    Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.invoiceLineItems', this.args.RowList.listArray);
    Ember.set(this, 'lastIndex', this.args.RowList.listArray.length - 1);
  }), _dec16 = (0, _emberConcurrency.task)(function* (itemCode, index) {
    var lineItems = this.args.RowList.listArray;
    var lineItem;

    try {
      var lineItemArray = yield this.invoiceService.getConnectedItemsTask.perform(this.args.customer.get('id'), itemCode);

      if (lineItemArray.length > 0) {
        lineItem = lineItemArray[0];
        var billableItem = yield this.store.findRecord('invoiceBillableItem', lineItem.relationships.invoice_billable_item.data.id);
        billableItem.defaultUnitPrice = lineItem.attributes.unit_price;
        billableItem.isTaxable = lineItem.attributes.sales_tax_rate;
        lineItem['invoiceBillableItem'] = billableItem;
        yield this.updateLineItemTask.perform(lineItem, index);
      } else {
        // Default
        lineItem = lineItems[index];
        yield this.updateLineItemTask.perform(lineItem, index);
      }
    } catch (err) {
      if (itemCode === 'activation_fee') {
        this.stNotificationManager.addErrorMessages(this.intl.t('pages.customers.accounting.invoices.actv_fee_already_used'), {
          notificationNamespace: 'modal'
        });
      } else {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
      }

      lineItem = lineItems[index];
      Ember.set(lineItem, 'invoiceBillableItem', null);
      Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.invoiceLineItems', this.args.RowList.listArray);
      throw err;
    }
  }), _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, (_class = (_temp = class InvoiceLineItems extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "sessionData", _descriptor, this);

      _initializerDefineProperty(this, "invoiceService", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor4, this);

      _initializerDefineProperty(this, "intl", _descriptor5, this);

      _initializerDefineProperty(this, "screen", _descriptor6, this);

      _initializerDefineProperty(this, "lineItemDeleteId", _descriptor7, this);

      _initializerDefineProperty(this, "lastIndex", _descriptor8, this);

      _initializerDefineProperty(this, "lockedState", _descriptor9, this);

      _initializerDefineProperty(this, "detailedBillableItems", _descriptor10, this);

      _initializerDefineProperty(this, "initData", _descriptor11, this);

      _initializerDefineProperty(this, "detailBillableItemsTask", _descriptor12, this);

      _initializerDefineProperty(this, "grabBillableItemDataTask", _descriptor13, this);

      _initializerDefineProperty(this, "deleteLineItemTask", _descriptor14, this);

      _initializerDefineProperty(this, "updateLineItemTask", _descriptor15, this);

      _initializerDefineProperty(this, "addMMR_ActFeeTask", _descriptor16, this);

      this.initData.perform();
    }

    deleteLineItem(row) {
      this.deleteLineItemTask.perform(row);
    }

    handleEnter(select, e) {
      if (e.keyCode === 13) {
        if (select.searchText && select.highlighted) {
          select.actions.choose(select.highlighted.billableItem);
        } else if (select.isOpen) {
          return false;
        }
      }
    }

    updateLineItem(index) {
      var lineItems = this.args.RowList.listArray;
      var lineItem = lineItems[index];
      var itemCode = lineItem['invoiceBillableItem'] ? lineItem['invoiceBillableItem'].code : null;

      if (itemCode === 'MMR') {
        this.addMMR_ActFeeTask.perform('mmr', index);
      } else if (itemCode === 'ACTV-FEE') {
        this.addMMR_ActFeeTask.perform('activation_fee', index);
      } else {
        this.updateLineItemTask.perform(lineItem, index);
      }
    }

    editLineItem(row, index) {
      var lineItems = this.args.RowList.listArray;
      var lineItem = lineItems[index];
      var quantity = 1;

      if (Number(row.quantity) !== 0) {
        var absQuantity = Math.abs(Number(row.quantity));
        quantity = row.invoiceBillableItem.entityType === 'equipment' || absQuantity % 1 == 0 ? absQuantity.toFixed(0) : absQuantity.toFixed(2);
      }

      var newGrossAmount = quantity * Number(row.unitPrice);
      var salesTaxAmount = Number(row.salesTaxRate) / 100 * newGrossAmount;
      Ember.set(lineItem, 'quantity', quantity);
      Ember.set(lineItem, 'unitPrice', row.unitPrice);
      Ember.set(lineItem, 'salesTaxRate', row.salesTaxRate);
      Ember.set(lineItem, 'grossAmount', newGrossAmount);
      Ember.set(lineItem, 'salesTaxAmount', salesTaxAmount);
      Ember.set(lineItem, 'totalAmount', newGrossAmount + salesTaxAmount);
      this.args.RowList.updateRow(lineItem, index);
      Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.invoiceLineItems', this.args.RowList.listArray);
      Ember.notifyPropertyChange(this.args.RowList, 'listArray');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "invoiceService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "screen", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "lineItemDeleteId", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "lastIndex", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "lockedState", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'Open';
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "detailedBillableItems", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "initData", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "detailBillableItemsTask", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "grabBillableItemDataTask", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "deleteLineItemTask", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "updateLineItemTask", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "addMMR_ActFeeTask", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "deleteLineItem", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "deleteLineItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleEnter", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "handleEnter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateLineItem", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "updateLineItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editLineItem", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "editLineItem"), _class.prototype)), _class));
  _exports.default = InvoiceLineItems;
});