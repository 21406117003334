define("securitytrax-ember-ui/instance-initializers/background-services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    // this automatically instantiates (ie: inovkes init) on the service
    application.lookup('service:st-websocket');
    application.lookup('service:user-notifications-service');
    application.lookup('service:global-events');
    application.lookup('service:recent-searches-service');
  }

  var _default = {
    name: 'background-services-instance-initializer',
    initialize
  };
  _exports.default = _default;
});