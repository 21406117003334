define("securitytrax-ember-ui/components/multi-step-form/forms/order-template/component", ["exports", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "securitytrax-ember-ui/mixed-in-classes/set-user", "securitytrax-ember-ui/mixed-in-classes/apply-order-template", "ember-concurrency", "lodash"], function (_exports, _component, _switchableFormStep, _setUser, _applyOrderTemplate, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var OrderTemplate = (_dec = Ember.inject.service('intl'), _dec2 = Ember.inject.service('stNotificationManager'), _dec3 = Ember.inject.service('stAjax'), _dec4 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    var formattedObjects = Ember.A();
    var noValidate = [];

    if (!this.ApplyOrderTemplate.showMmrSelectList) {
      noValidate.push('orderTemplate-mmr');
    }

    if (!this.ApplyOrderTemplate.selectedTemplatePackageId) {
      noValidate.push('orderTemplate-soldBy');
    }

    formattedObjects.pushObject(Ember.Object.create({
      model: {
        mmr: _lodash.default.get(this, 'ApplyOrderTemplate.selectedMmr.id', null),
        soldBy: yield this.ApplyOrderTemplate.equipmentSalesPerson
      },
      extraValidationData: {
        noValidate: noValidate
      },
      filePath: 'order-templates/apply-order-template'
    }));
    yield multiStepForm.validateList.perform(formattedObjects);
    yield this.ApplyOrderTemplate.applyOrderTemplateMixinTask.perform(multiStepForm.formOriginalModels.customerOrderInformation.model.id);
    yield multiStepForm.syncAllCopiedToOriginal.perform();
    buttonPromise.then(() => {
      multiStepForm.formOriginalModelsGetVal('customerOrderInformation.model.customer.invoices').then(invoices => {
        invoices.reload();
      });
      multiStepForm.formOriginalModelsGetVal('customerOrderInformation.model.customer.quotes').then(quotes => {
        quotes.reload();
      });

      if (this.args.refreshModels) {
        this.args.refreshModels();
      }

      this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
      multiStepForm.formStartedSetVal(false);
    });
  }), _dec5 = Ember._action, (_class = (_temp = class OrderTemplate extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "stAjax", _descriptor3, this);

      _initializerDefineProperty(this, "applyOrderTemplateTask", _descriptor4, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.ApplyOrderTemplate = new _applyOrderTemplate.default(...arguments);
      this.SetUser = new _setUser.default(...arguments);
    }

    renderButtons() {
      this.args.multiStepForm.registerStepData('step1', 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.applyOrderTemplateTask.perform(this.args.multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary save'
      }]);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stAjax", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "applyOrderTemplateTask", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "renderButtons", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "renderButtons"), _class.prototype)), _class));
  _exports.default = OrderTemplate;
});