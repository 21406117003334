define("securitytrax-ember-ui/components/homepage/dashboard/sales-today/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2X42AZDy",
    "block": "{\"symbols\":[\"data\"],\"statements\":[[8,\"html/st-await\",[],[[\"@useSpinner\",\"@promises\"],[true,[30,[36,1],null,[[\"salesData\"],[[35,0]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"html/st-card/body\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[14,0,\"st-card__cell\"],[12],[2,\"\\n            \"],[8,\"graphs/partial-donut\",[],[[\"@graphData\",\"@totalCountLabel\",\"@totalCount\",\"@showMainLabel\",\"@showLegend\"],[[34,2],[30,[36,3],[\"pages.home.total_sales\"],null],[32,1,[\"resolvedPromises\",\"salesData\",\"total\",\"count\"]],true,true]],null],[2,\"\\n\\n            \"],[10,\"div\"],[14,0,\"cell small-12 grid-x grid-padding-x stats-main\"],[12],[2,\"\\n                \"],[10,\"div\"],[14,0,\"cell small-6 stat\"],[12],[2,\"\\n                    \"],[10,\"div\"],[14,0,\"stat-count\"],[12],[2,\"$\"],[1,[32,1,[\"resolvedPromises\",\"salesData\",\"total\",\"mmr_average\"]]],[13],[2,\"\\n                    \"],[10,\"div\"],[12],[1,[30,[36,3],[\"pages.home.average_mmr\"],null]],[13],[2,\"\\n                \"],[13],[2,\"\\n                \"],[10,\"div\"],[14,0,\"cell small-6 stat\"],[12],[2,\"\\n                    \"],[10,\"div\"],[14,0,\"stat-count\"],[12],[1,[32,1,[\"resolvedPromises\",\"salesData\",\"total\",\"installed\"]]],[2,\" \"],[1,[30,[36,3],[\"pages.home.of\"],null]],[2,\" \"],[1,[32,1,[\"resolvedPromises\",\"salesData\",\"total\",\"count\"]]],[13],[2,\"\\n                    \"],[10,\"div\"],[12],[1,[30,[36,3],[\"pages.home.installed\"],null]],[13],[2,\"\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"salesData\",\"hash\",\"graphData\",\"t\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/homepage/dashboard/sales-today/template.hbs"
    }
  });

  _exports.default = _default;
});