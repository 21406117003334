define("securitytrax-ember-ui/components/calendar-glimmer/calendar-month-view/mobile/component", ["exports", "@glimmer/component", "moment"], function (_exports, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CalendarMonthViewMobile = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = (_temp = class CalendarMonthViewMobile extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "showSummary", _descriptor, this);

      _initializerDefineProperty(this, "curDay", _descriptor2, this);

      _defineProperty(this, "_selectedDay", null);

      this.today = (0, _moment.default)().format('D MMMM YYYY');
      this._selectedDay = this.args.selectedDay;
    }

    get monthAndYear() {
      return this.args.calendarDate.format('MMM YYYY');
    }

    _scrollToDate(date, force = false) {
      var dayRoot = document.getElementById("calendar-month__mobile-day-".concat(date.format('D-MMMM-YYYY')));

      if (dayRoot) {
        if (force || Math.max(document.body.scrollTop, document.documentElement.scrollTop) > dayRoot.offsetTop - 65) {
          document.body.scrollTop = dayRoot.offsetTop - 75;
          document.documentElement.scrollTop = dayRoot.offsetTop - 75;
        }
      }
    }

    showSummaryAction() {
      this.showSummary = !this.showSummary;
    }

    toggleDay(day) {
      if (day.dayTotal == 0) return;

      if (day === this.curDay) {
        this.scrollToCurDay();
        this.curDay = null;
      } else {
        this.curDay = day;
      }
    }

    scrollToCurDay() {
      if (this.curDay) {
        this._scrollToDate(this.curDay.date);
      }
    }

    calendarSelectDay(date) {
      this.curDay = null;
      this.args.updateStartDate(date);
      this._selectedDay = this.args.selectedDay;
      this.scrollToDateAfterCurDayClosed = true;

      this._scrollToDate(this.args.selectedDay.date, true);
    }

    scrollToDate() {
      if (this._selectedDay) {
        this._scrollToDate(this.args.selectedDay.date, true);

        this._selectedDay = null;
      }
    }

    goToDayView(day) {
      this.args.updateStartDate({
        moment: day.date
      });
      this.args.updateViewType('days');
    }

    curDayClosed() {
      if (this.scrollToDateAfterCurDayClosed) {
        this.scrollToDateAfterCurDayClosed = false;

        this._scrollToDate(this.args.selectedDay.date, true);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "showSummary", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "curDay", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "showSummaryAction", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "showSummaryAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleDay", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "toggleDay"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "scrollToCurDay", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "scrollToCurDay"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "calendarSelectDay", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "calendarSelectDay"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "scrollToDate", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "scrollToDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goToDayView", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "goToDayView"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "curDayClosed", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "curDayClosed"), _class.prototype)), _class));
  _exports.default = CalendarMonthViewMobile;
});