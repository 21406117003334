define("securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-payment-method/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    quickAdd: Ember.inject.service(),
    paymentMethodEvents: Ember.inject.service('events/payment-method-events'),
    customerBilling: null,
    paymentMethodFormStarted: false,
    defaultCustomerBilling: null,
    paymentMethodDeleteId: false,
    initData: (0, _emberConcurrency.task)(function* () {
      if (this.paymentMethodId !== 'create') {
        Ember.set(this, 'customerBilling', yield this.store.findRecord('customerBilling', this.paymentMethodId));
        Ember.set(this, 'customer', this.customer ? this.customer : yield this.get('customerBilling.customer'));
      }

      Ember.set(this, 'billTypes', yield this.store.findAll('billingType'));
      Ember.set(this, 'paymentMethodFormStarted', true);
    }).on('init'),
    formMode: Ember.computed(function () {
      if (this.grants.getIndividualGrant('customer_billings.create') && this.paymentMethodId === 'create') {
        return 'create';
      } else if (this.grants.getIndividualGrant('customer_billings.modify') && this.paymentMethodId !== 'create') {
        return 'edit';
      } else {
        return 'view';
      }
    }),
    paymentMethodFormOriginalModels: Ember.computed('customerBilling', 'grants.userGrants', function () {
      return Ember.Object.create({
        customerBilling: {
          model: this.customerBilling,
          copyRelationMap: {
            customerBillingBillCcNumber: {
              doCopy: this.grants.getIndividualGrant('customer_billings.view_partial_cc'),
              doSync: this.grants.getIndividualGrant('customer_billings.view_partial_cc'),
              doValidate: false,
              doSave: false
            },
            billType: {
              doValidate: false,
              doSave: false
            },
            billCycle: {
              doValidate: false,
              doSave: false
            },
            customerBillingBillBankNumbers: {
              doCopy: this.grants.getIndividualGrant('customer_billing_bill_bank_numbers.view_partial_bank_number'),
              doSync: this.grants.getIndividualGrant('customer_billing_bill_bank_numbers.view_partial_bank_number'),
              doValidate: false,
              doSave: false
            }
          }
        }
      });
    }),
    paymentMethodForm: Ember.computed('formMode', 'customerBilling', function () {
      return Ember.Object.create({
        paymentMethodFormStep: Ember.Object.create({
          stepNameKey: 'paymentMethodFormStep',
          title: this.intl.t("pages.customers.accounting.customer_billing.".concat(this.formMode), {
            name: this.customer.get('flname'),
            id: this.customerBilling ? this.customerBilling.get('id') : null
          }),
          stepComponentPath: 'multi-step-form/forms/accounting/edit-create-payment-method'
        })
      });
    }),
    actions: {
      transitionBackToParentRoute() {
        var transitionToRoute = this.quickAdd.closeRoute || this.parentRoute;

        if (transitionToRoute.includes('customer')) {
          this.router.transitionTo(transitionToRoute, this.get('customer.id'));
        } else {
          this.router.transitionTo(transitionToRoute);
        }
      },

      deletePaymentMethod(method) {
        method.destroyRecordWithRollbackTask.perform().then(() => {
          this.set('paymentMethodDeleteId', false);
        });
      },

      refreshModel() {
        this.paymentMethodEvents.trigger('refresh-list');
      }

    }
  });

  _exports.default = _default;
});