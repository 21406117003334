define("securitytrax-ember-ui/mixins/forms/customer-lead-shared", ["exports", "ember-concurrency", "moment", "securitytrax-ember-ui/mixins/switchable-form-step", "jquery", "validate.js"], function (_exports, _emberConcurrency, _moment, _switchableFormStep, _jquery, _validate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This is mixin is used in both customer and lead forms
   *
   * @mixin CustomerLeadMixin
   * @extends Ember.Mixin
   */
  var _default = Ember.Mixin.create(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    permissionsService: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    usStates: Ember.inject.service(),
    switchableActionsClass: '',
    records: undefined,
    checkForDuplicates: true,

    init() {
      this._super(...arguments);

      this.set('records', []);
    },

    get scrollContainer() {
      if ((0, _jquery.default)('.md-dialog-container')[0]) {
        if ((0, _jquery.default)('.md-dialog-container .form-content')[0]) {
          return '.form-content';
        } else if ((0, _jquery.default)('.md-dialog-container .md-dialog-content')[0]) {
          return '.md-dialog-content';
        } else {
          return '.md-dialog-container';
        }
      } else {
        return 'html, body';
      }
    },

    creditCheckFormStep: Ember.computed(function () {
      var intl = this.intl;
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: intl.t('common.nav.create.customer.sections.qualify'),
          stepComponentPath: 'multi-step-form/forms-dynamic/forms/customer-information/qualify'
        })
      });
    }),
    creditCheckFormOriginalModels: Ember.computed(function () {
      return Ember.Object.create({
        customerCreditCheck: {
          model: null,
          copyRelationMap: {
            creditBureau: {
              doCopy: true,
              doValidate: false,
              doSave: false
            }
          }
        }
      });
    }),
    isAdtCompany: Ember.computed(function () {
      return this.get('sessionData.companyHasSpecificMonitorTask').perform();
    }),
    maskedBirthdate: Ember.computed('multiStepForm.formCopiedModels.customer.model.customerBirthdate', function () {
      return this.multiStepForm.formCopiedModelsGetVal('customer.model.responseMeta.masked_birthdate');
    }),
    ssnMaskedValue: Ember.computed('multiStepForm.formCopiedModels.customer.model.customerSsn.ssn', function () {
      return this.multiStepForm.formCopiedModelsGetVal('customer.model.responseMeta.masked_ssn');
    }),
    canViewAndEditBirthdate: Ember.computed('entity', 'formMode', 'grants.userGrants', function () {
      if (this.grants.getIndividualGrant(this.entity + '_birthdates.modify') && this.grants.getIndividualGrant(this.entity + '_birthdates.view') || this.formMode === 'create' || this.multiStepForm.formCopiedModelsGetVal('customer.model.responseMeta.masked_birthdate') === null) {
        return true;
      } else {
        return false;
      }
    }),
    canViewNotEditBirthdate: Ember.computed('entity', 'formMode', 'grants.userGrants', function () {
      if (this.grants.getIndividualGrant(this.entity + '_birthdates.view')) {
        return true;
      } else {
        return false;
      }
    }),
    canViewAndEditSsn: Ember.computed('entity', 'formMode', 'grants.userGrants', function () {
      if (this.grants.getIndividualGrant(this.entity + '_ssns.modify') && this.grants.getIndividualGrant(this.entity + '_ssns.view') || this.formMode === 'create' || this.multiStepForm.formCopiedModelsGetVal('customer.model.responseMeta.masked_ssn') === '') {
        return true;
      } else {
        return false;
      }
    }),
    canViewNotEditSsn: Ember.computed('entity', 'formMode', 'grants.userGrants', function () {
      if (this.grants.getIndividualGrant(this.entity + '_ssns.view')) {
        return true;
      } else {
        return false;
      }
    }),
    accountTypeOptions: Ember.computed.map('accountTypes', function (key) {
      return {
        value: key,
        name: this.intl.t("common.customer.account_types.".concat(key))
      };
    }),
    homeOwnershipOptions: Ember.computed.map('homeOwnerships', function (key) {
      return {
        value: key,
        name: this.intl.t("common.customer.home_ownerships.".concat(key))
      };
    }),
    isCreditReadonly: Ember.computed('grants.userGrants', function () {
      return !this.grants.getIndividualGrant('customer_credit_checks.modify') || this.multiStepForm.formCopiedModels.customer.model.creditTracking == undefined || (0, _validate.isEmpty)('multiStepForm.formCopiedModels.customer.model.creditTracking');
    }),
    monitoringCompany: Ember.computed('customer.customerOrderInformation', 'grants.userGrants', function () {
      var orderInfoGrant = this.grants.getIndividualGrant('customer_order_information.view');
      return orderInfoGrant ? this.get('customer.customerOrderInformation.monitoringCompany') : null;
    }),
    localTime: Ember.computed('customer.timezone', 'lead.timezone', function () {
      var timeZone = this.get(this.entity + '.timezone');

      if (timeZone) {
        return (0, _moment.default)().tz(timeZone).format('h:mm A') + " (".concat(timeZone, ")");
      }

      return '';
    }),
    adtMonitoringCompanies: Ember.computed('monitoringCompanies.@each.monitoringCompanyType', function () {
      var adtCompanies = Ember.A([]);

      if (this.monitoringCompanies) {
        this.monitoringCompanies.forEach(company => {
          if (company.get('monitoringCompanyType.id') == 1) {
            adtCompanies.pushObject(company);
          }
        });
      }

      return adtCompanies;
    }),
    showAdtPreCheck: Ember.computed('monitoringCompany', 'grants.userGrants', function () {
      if (!this.grants.getIndividualGrant('customers.manage_central_station_integration')) {
        return false;
      }

      var active = false;
      var monitorTypeId = null;

      if (this.monitoringCompany) {
        active = this.get('monitoringCompany.active');
        monitorTypeId = this.get('monitoringCompany.monitoringCompanyType.id');
      }

      if (active === true && monitorTypeId == 1) {
        return true;
      }

      return false;
    }),
    submitAdtPreCheck: (0, _emberConcurrency.task)(function* (customer = null, notificationNamespace = 'global') {
      try {
        if (customer === null) {
          customer = this.customer;
        }

        var result = yield customer.executeAdtPreCheck(notificationNamespace);
        this.notifyPropertyChange('adtPreCheckData');
        return result;
      } catch (err) {
        (false && Ember.warn('submit precheck failed' + err, {
          id: 'multiStepForm.forms.editCreateCustomer.submitAdtPrecheck'
        })); // do we want to halt the process?
        //throw err;
      }
    }),
    //
    populateCityStateCountyTask: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = this.multiStepForm;
      var rawZip = this.multiStepForm.formCopiedModelsGetVal('customer.model.zip');
      var zip = rawZip;

      if (zip) {
        zip = rawZip.toUpperCase();
        multiStepForm.formCopiedModelsSetVal('customer.model.zip', zip);
      }

      var infoFromZip;

      if (zip && /\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d/.test(zip)) {
        var company = yield this.sessionData.getCompany();

        try {
          infoFromZip = yield company.invokeAction('get_preferred_zip_code_data', {
            verb: 'GET',
            data: {
              zip_code: zip
            }
          });
        } catch (error) {
          (false && Ember.warn('failed to get zip code preferences' + error, {
            id: 'multiStepForm.forms.editCreateCustomer.information'
          }));
        }

        if (infoFromZip) {
          infoFromZip = infoFromZip['responsePayload']['data']['get_preferred_zip_code_data_response']['result'];

          if (infoFromZip) {
            multiStepForm.formCopiedModelsSetVal('customer.model.city', infoFromZip['city']);
            multiStepForm.formCopiedModelsSetVal('customer.model.state', infoFromZip['state']);
            multiStepForm.formCopiedModelsSetVal('customer.model.county', infoFromZip['county']);
          }
        }
      }
    }),
    adtPreCheckData: Ember.computed(function () {
      return this.adtPreCheckDataTask.perform();
    }),
    adtPreCheckDataTask: (0, _emberConcurrency.task)(function* () {
      var model = this.model;
      var customerOrderInformationPermission = this.grants.getIndividualGrant('customer_order_information.view');
      var csIntegrationPermission = this.grants.getIndividualGrant('customers.manage_central_station_integration');
      var preCheck = null;

      if (model.customerOrderInformation) {
        var monitoringCompany = model.customerOrderInformation.get('monitoringCompany');
        var monitoringCompanyType = monitoringCompany.get('monitoringCompanyType');

        if (monitoringCompanyType && monitoringCompanyType && monitoringCompanyType.get('id') === '1') {
          if (customerOrderInformationPermission && csIntegrationPermission) {
            preCheck = yield this.centralStationService.getData({
              model: model.customer,
              method: 'adt__getPreCheckOrderResult',
              responseKey: 'pre_check_order_result',
              returnAsEmberObject: true
            });
          }
        }
      }

      return preCheck;
    }),
    getUserAndSet: (0, _emberConcurrency.task)(function* (property, userId) {
      var uid = userId;

      if (uid === null) {
        return null;
      }

      if (typeof uid === 'object') {
        uid = uid.id;
      }

      var theUser = yield this.store.peekThenFindRecord('user', uid);
      yield this.set(property, theUser);
    }),

    openConfirmDelete(stepKey) {
      var deletingData = {
        currentStep: stepKey,
        customerId: this.newCustomer.get('id'),
        customerName: this.newCustomer.get('flname')
      };
      this.multiStepForm.registerStepData('confirmDeleteCustomer', 'currentDeletingCustomer', deletingData);
      this.multiStepForm.currentStepNameKeySetVal('confirmDeleteCustomer');
    }

  });

  _exports.default = _default;
});