define("securitytrax-ember-ui/my-account/integrations/adc/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var ADC = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = (0, _emberConcurrency.task)(function* () {
    var user = this.args.user || (yield this.sessionData.getUser());
    var integrationsList = yield user.invokeAction('get_authentication_info', {
      verb: 'GET'
    });
    var integrations = integrationsList.responsePayload.data;

    for (var i = 0; i < integrations.length; i++) {
      var integration = yield this.store.findRecord('interactiveServicesProvider', integrations[i].relationships.interactive_services_provider.data.id);
      integrations[i].relationships.interactive_services_provider.data.name = integration.get('name');
    }

    this.integrationsList = integrations;
  }), _dec10 = Ember._action, (_class = (_temp = class ADC extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "stAjax", _descriptor3, this);

      _initializerDefineProperty(this, "sessionData", _descriptor4, this);

      _initializerDefineProperty(this, "adcService", _descriptor5, this);

      _initializerDefineProperty(this, "adcServiceFormStarted", _descriptor6, this);

      _initializerDefineProperty(this, "adcServiceFormMode", _descriptor7, this);

      _initializerDefineProperty(this, "user", _descriptor8, this);

      _defineProperty(this, "integrationsList", undefined);

      _initializerDefineProperty(this, "initDataTask", _descriptor9, this);

      this.initDataTask.perform();
    }

    get adcIntegrationsFormSteps() {
      var intl = this.intl;
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: intl.t('pages.users.my_account.integrations.adc.edit_title', {
            name: this.adcService.relationships.interactive_services_provider.data.name
          }),
          stepComponentPath: 'multi-step-form/forms/my-account/integrations/edit-adc'
        })
      });
    }

    get adcIntegrationsFormOriginalModels() {
      return Ember.Object.create({
        interactiveServicesProvider: {
          model: null
        }
      });
    }

    refreshList() {
      this.notifyPropertyChange('adcIntegrations');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stAjax", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "adcService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "adcServiceFormStarted", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "adcServiceFormMode", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'edit';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "user", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "refreshList", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "refreshList"), _class.prototype)), _class));
  _exports.default = ADC;
});