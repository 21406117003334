define("securitytrax-ember-ui/validations/customer-accounting-information", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation", "lodash"], function (_exports, _validate, _baseValidation, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      _validate.default.validators.emailList = function (value) {
        var emails = value.split(',').map(email => email.trim());
        var invalidEmails = [];
        emails.forEach(email => {
          if (!email.trim().match(/.*@.*\..*/)) {
            invalidEmails.push(email);
          }
        });

        if (invalidEmails.length > 0) {
          return data.intlService.t('common.validations.emailList', {
            'invalidEmails': invalidEmails.join(', ')
          });
        }

        return null;
      };

      var noValidate = _lodash.default.get(data, 'extraValidationData.noValidate', false);

      var validateObject = {
        firstName: {
          presence: () => {
            if (noValidate && noValidate.includes('customerAccountingInformation-firstName')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'customerAccountingInformation-firstName');
            }
          }
        },
        lastName: {
          presence: () => {
            if (noValidate && noValidate.includes('customerAccountingInformation-lastName')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'customerAccountingInformation-lastName');
            }
          }
        },
        emails: {
          presence: () => {
            if (noValidate && noValidate.includes('customerAccountingInformation-emails')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'customerAccountingInformation-emails');
            }
          },
          emailList: () => {
            if (this.emails) {
              return this.customMessageOrDefault(data, 'customerAccountingInformation-emails');
            } else {
              return false;
            }
          }
        },
        address1: {
          presence: () => {
            if (noValidate && noValidate.includes('customerAccountingInformation-address1')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'customerAccountingInformation-address1');
            }
          }
        },
        city: {
          presence: () => {
            if (noValidate && noValidate.includes('customerAccountingInformation-city')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'customerAccountingInformation-city');
            }
          }
        },
        state: {
          presence: () => {
            if (noValidate && noValidate.includes('customerAccountingInformation-state')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'customerAccountingInformation-state');
            }
          }
        },
        zip: {
          presence: () => {
            if (noValidate && noValidate.includes('customerAccountingInformation-zip')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'customerAccountingInformation-zip');
            }
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject, {
        fullMessages: this.prependProperty(data)
      });
      var modelName = data.extraValidationData && data.extraValidationData.hasOwnProperty('overrideValidationKey') ? data.extraValidationData.overrideValidationKey : 'customerAccountingInformation';
      return this.outputFormatting(validationData, modelName);
    }

  });

  _exports.default = _default;
});