define("securitytrax-ember-ui/components/multi-step-form/forms/system/bold/tests/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms/system/bold/bold-base"], function (_exports, _emberConcurrency, _boldBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _boldBase.default.extend({
    centralStationService: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    intl: Ember.inject.service(),
    addTestModalOpen: false,
    hours: null,
    minutes: null,
    description: null,
    accountTests: Ember.computed(function () {
      return this.getAccountTests.perform();
    }),
    getAccountTests: (0, _emberConcurrency.task)(function* () {
      var tests = yield this.centralStationService.getData({
        model: this.customer,
        method: 'bold__getAccountTestEntries'
      });
      return tests.account_test_entries;
    }),
    clearAccountTestEntries: (0, _emberConcurrency.task)(function* (buttonPromise) {
      try {
        yield this.centralStationService.postData({
          model: this.customer,
          method: 'bold__clearAccountTestEntries'
        });
        buttonPromise.then(() => {
          this.notifyPropertyChange('accountTests');
          this.stNotificationManager.addSuccessKey('pages.customers.system.bold.clear_successful', 'modal');
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
        throw err;
      }
    }),
    addTest: (0, _emberConcurrency.task)(function* (buttonPromise) {
      var formattedObjects = [];
      formattedObjects.pushObject(Ember.Object.create({
        model: {
          hours: this.hours,
          minutes: this.minutes,
          description: this.description
        },
        filePath: 'central-station/bold-test-create'
      }));
      yield this.multiStepForm.validateList.perform(formattedObjects, {
        notificationNamespace: 'addTestModal'
      });

      try {
        yield this.centralStationService.postData({
          model: this.customer,
          method: 'bold__createAccountTestEntry',
          payload: {
            hours: this.hours,
            minutes: this.minutes,
            description: this.description
          }
        });
        buttonPromise.then(() => {
          this.actions.resetDropdowns.call(this);
          this.notifyPropertyChange('accountTests');
          this.set('addTestModalOpen', false);
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'addTestModal'
        });
        throw err;
      }
    }),
    addTestButton: Ember.computed(function () {
      var buttons = [{
        text: this.intl.t('common.actions.save'),
        classNamesString: 'st-btn st-btn-primary save-test',
        click: buttonPromise => {
          return this.addTest.perform(buttonPromise);
        }
      }];
      return buttons;
    }),
    actions: {
      resetDropdowns() {
        Ember.setProperties(this, {
          minutes: null,
          hours: null,
          description: null
        });
      }

    }
  });

  _exports.default = _default;
});