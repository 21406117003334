define("securitytrax-ember-ui/my-inventory/purchase-orders/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6LX6mEtH",
    "block": "{\"symbols\":[],\"statements\":[[8,\"inventory/purchase-orders\",[],[[\"@user\",\"@vendors\",\"@storedFilterKey\"],[[34,0,[\"user\"]],[34,0,[\"vendors\"]],\"purchaseOrderListFilter\"]],null]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/my-inventory/purchase-orders/template.hbs"
    }
  });

  _exports.default = _default;
});