define("securitytrax-ember-ui/leads/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/helpers/feature-is-enabled", "securitytrax-ember-ui/mixins/route-pageview-logging", "securitytrax-ember-ui/mixins/scroll-to-top"], function (_exports, _authenticatedRouteMixin, _featureIsEnabled, _routePageviewLogging, _scrollToTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _routePageviewLogging.default, _scrollToTop.default, {
    grants: Ember.inject.service(),
    pageTitle: Ember.inject.service(),
    router: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      if (!(0, _featureIsEnabled.featureIsEnabled)('leadsTab')) {
        this.transitionTo('/');
      }

      return this.findGrantsTask.perform(['leads.view']);
    },

    actions: {
      error(error, transition) {
        (false && Ember.warn('error in leads index route:' + error + ' - transition: ' + transition, {
          id: 'leads.index'
        }));

        if (error.status === '403') {
          this.replaceWith('home');
        } else {
          // Let the route above this handle the error.
          return true;
        }
      }

    }
  });

  _exports.default = _default;
});