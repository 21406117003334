define("securitytrax-ember-ui/models/base_models/government-license", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    governmentLicenseType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['tech', 'sales', 'tech_sales', 'qualifier']
    }),
    objectType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['user', 'location', 'company']
    }),
    name: (0, _model.attr)('string', {
      'readOnly': false
    }),
    mailingAddress: (0, _model.attr)('string', {
      'readOnly': false
    }),
    governingBody: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['country', 'state', 'city', 'county']
    }),
    country: (0, _model.attr)('string', {
      'readOnly': false
    }),
    state: (0, _model.attr)('string', {
      'readOnly': false
    }),
    county: (0, _model.attr)('string', {
      'readOnly': false
    }),
    city: (0, _model.attr)('string', {
      'readOnly': false
    }),
    agency: (0, _model.attr)('string', {
      'readOnly': false
    }),
    url: (0, _model.attr)('string', {
      'readOnly': false
    }),
    contactName: (0, _model.attr)('string', {
      'readOnly': false
    }),
    contactPhone: (0, _model.attr)('string', {
      'readOnly': false
    }),
    contactFaxNumber: (0, _model.attr)('string', {
      'readOnly': false
    }),
    contactEmail: (0, _model.attr)('string', {
      'readOnly': false
    }),
    submissionType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['mail', 'online', 'in_person']
    }),
    submissionApplicationDeadlineWindow: (0, _model.attr)('number', {
      'readOnly': false
    }),
    term: (0, _model.attr)('number', {
      'readOnly': false
    }),
    termPeriod: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['days', 'months', 'years']
    }),
    isCeuHoursRequired: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    ceuHours: (0, _model.attr)('number', {
      'readOnly': false
    }),
    applicationFee: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    activationFee: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    activationDeadlineWindow: (0, _model.attr)('number', {
      'readOnly': false
    }),
    isTrainingRequired: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    isPhotoRequired: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    isFingerprintRequired: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    notes: (0, _model.attr)('string', {
      'readOnly': false
    }),
    renewalInfo: (0, _model.attr)('string', {
      'readOnly': false
    }),
    status: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['active', 'deleted']
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    })
  });

  _exports.default = _default;
});