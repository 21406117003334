define("securitytrax-ember-ui/components/multi-step-form/forms/customer/edit-customer-funding/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "securitytrax-ember-ui/mixins/set-user", "ember-concurrency", "securitytrax-ember-ui/utils/obj-helpers", "securitytrax-ember-ui/mixins/table-column-sort", "moment"], function (_exports, _switchableFormStep, _setUser, _emberConcurrency, _objHelpers, _tableColumnSort, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, _setUser.default, _tableColumnSort.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    confirmDeleteModalOpen: false,
    initFormTask: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = this.multiStepForm;
      yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);

      if (this.isNewRecord) {
        var entryTypeOptions = yield this.fundingEntryTypeOptions;
        multiStepForm.formCopiedModelsSetVal('funding.model.fundingType', entryTypeOptions.find(entryType => {
          return entryType.id === '1';
        }));
      }

      (0, _objHelpers.initializeState)(this, {
        stSortCurrentSortColumn: 'id',
        stSortAscending: false
      });
    }).on('init'),
    customerFundingStatusProgression: Ember.computed('currCustomerFunding.updatedAt', function () {
      return this.customerFundingStatusProgressionTask.perform();
    }),
    customerFundingStatusProgressionTask: (0, _emberConcurrency.task)(function* () {
      var hashedData = yield (0, _emberConcurrency.hash)({
        statusProgression: this.get('currCustomerFunding.responseMeta.status_progression'),
        recentFundingProgressStatusLogsById: this.recentFundingProgressStatusLogsById
      });

      if (!hashedData.statusProgression) {
        return {};
      }

      var processedStatusProgression = {};
      var statusProgressionKeys = Object.keys(hashedData.statusProgression);

      for (var index = 0; index < statusProgressionKeys.length; index++) {
        var currStatusProgressionKey = statusProgressionKeys[index];

        if (!processedStatusProgression[currStatusProgressionKey]) {
          processedStatusProgression[currStatusProgressionKey] = {};
        }

        processedStatusProgression[currStatusProgressionKey]['key'] = currStatusProgressionKey;
        processedStatusProgression[currStatusProgressionKey]['status'] = hashedData.statusProgression[currStatusProgressionKey];
        var currStatusLog = hashedData.recentFundingProgressStatusLogsById[currStatusProgressionKey];
        processedStatusProgression[currStatusProgressionKey]['progressStateString'] = '';

        if (currStatusLog) {
          var updatedAt = currStatusLog.get('updatedAt');

          if (updatedAt) {
            processedStatusProgression[currStatusProgressionKey]['updatedAtStr'] += (0, _moment.default)(updatedAt).format('M/D/YYYY h:mm A');
            processedStatusProgression[currStatusProgressionKey]['progressStateString'] += (0, _moment.default)(updatedAt).format('M/D/YYYY h:mm A');
          }

          processedStatusProgression[currStatusProgressionKey]['updatedByUser'] = currStatusLog.get('updatedByUser.name');
          processedStatusProgression[currStatusProgressionKey]['progressStateString'] += '<br/>' + currStatusLog.get('updatedByUser.name');
        }
      }

      return processedStatusProgression;
    }),
    funders: Ember.computed(function () {
      return this.fundersTask.perform();
    }),
    fundersTask: (0, _emberConcurrency.task)(function* () {
      if (this.grants.getIndividualGrant('funders.view')) {
        var funders = yield this.store.findAll('funder');
        return funders;
      }

      return [];
    }),
    fundingProgressStatuses: Ember.computed(function () {
      return this.fundingProgressStatusesTask.perform();
    }),
    fundingProgressStatusesTask: (0, _emberConcurrency.task)(function* () {
      var funderProgressStatuses = yield this.store.query('fundingProgressStatus', {
        sort: 'sort'
      });
      return funderProgressStatuses;
    }),
    fundingOutcomes: Ember.computed(function () {
      return this.store.query('fundingOutcome', {
        sort: 'sort'
      });
    }),
    funderReasons: Ember.computed(function () {
      return this.store.findAll('funderReason');
    }),
    sortedFundingTransactions: Ember.computed('currCustomerFunding.fundingTransactions.[]', 'stSortCurrentSortColumn', 'stSortAscending', function () {
      return this.sortedFundingTransactionsTask.perform();
    }),
    sortedFundingTransactionsTask: (0, _emberConcurrency.task)(function* () {
      var fundingTransactions = yield this.get('currCustomerFunding.fundingTransactions');
      var fundingTransactionsSorted = this.sortEmberDataCollection(fundingTransactions, this.stSortCurrentSortColumn, this.stSortAscending);
      return fundingTransactionsSorted;
    }),
    recentFundingProgressStatusLogsById: Ember.computed(function () {
      return this.recentFundingProgressStatusLogsByIdTask.perform();
    }),
    recentFundingProgressStatusLogsByIdTask: (0, _emberConcurrency.task)(function* () {
      if (!this.currCustomerFunding) {
        return {};
      }

      var fundingProgressStatusLogs = yield this.currCustomerFunding.get('fundingProgressStatusLogs');
      var latestStatusLogs = {};
      fundingProgressStatusLogs.forEach(currStatusLog => {
        if (latestStatusLogs[currStatusLog.get('fundingProgressStatus.id')]) {
          if ((0, _moment.default)(latestStatusLogs[currStatusLog.get('fundingProgressStatus.id')].get('updatedAt')).unix() < (0, _moment.default)(currStatusLog.get('updatedAt')).unix()) {
            latestStatusLogs[currStatusLog.get('fundingProgressStatus.id')] = currStatusLog;
          }
        } else {
          latestStatusLogs[currStatusLog.get('fundingProgressStatus.id')] = currStatusLog;
        }
      });
      return latestStatusLogs;
    }),
    fundingEntryTypeOptions: Ember.computed(function () {
      return this.store.findAll('fundingType');
    }),

    didInsertElement() {
      this._super(...arguments);

      this.insertButtonsTask.perform();
    },

    insertButtonsTask: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = yield this.multiStepForm;
      var buttons = [];

      if (this.formMode !== 'view') {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveCustomerFunding.perform(multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary customer-funding-save-button'
        });
      }

      if (this.grants.getIndividualGrant('fundings.delete') && !this.isNewRecord) {
        buttons.push({
          text: this.intl.t('common.actions.delete'),
          click: () => {
            this.set('confirmDeleteModalOpen', true);
          },
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
        });
      }

      multiStepForm.registerStepData('step1', 'buttons', buttons);
    }),
    saveCustomerFunding: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var startedAsNewRecord = this.isNewRecord;
      var savedFunding;

      if (startedAsNewRecord) {
        var toCreateObjects = yield multiStepForm.validateCreateAll({
          notificationNamespace: 'modal'
        });
        var fundingProgressStatusPending = yield this.store.findRecord('fundingProgressStatus', 'pending');
        Ember.set(toCreateObjects.funding.data, 'customer', this.customer);
        Ember.set(toCreateObjects.funding.data, 'fundingProgressStatus', fundingProgressStatusPending);
        var funding = this.store.createRecord('funding', toCreateObjects.funding.data);
        savedFunding = yield toCreateObjects.funding.saveWithRollbackAndNotification(funding, 'modal');
      } else {
        savedFunding = yield multiStepForm.validateSyncSaveAll({
          notificationNamespace: 'modal'
        });
      }

      buttonPromise.then(() => {
        if (startedAsNewRecord) {
          Ember.set(this, 'formMode', 'edit');
          Ember.set(this, 'currCustomerFunding', savedFunding);
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful', {
            notificationNamespace: 'modal'
          });
          this.insertButtonsTask.perform();
          multiStepForm.currentStepData.set('title', this.intl.t('common.forms.descriptive_title_edit_with_object_name', {
            name: this.intl.t('pages.administration.content_management.funding.title'),
            objectName: this.customer.get('flname'),
            id: this.currCustomerFunding.get('id')
          }));
          this.router.transitionTo("/customers/".concat(this.customer.id, "/accounting/funding/").concat(savedFunding.id, "/edit-create-funding"));
        } else if (this.transitionBackToParent && typeof this.transitionBackToParent === 'function') {
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful', {
            notificationNamespace: 'global'
          });
          this.transitionBackToParent();
        }
      });
    }),

    /****************************************
     * start customer funding transactions
     */
    currCustomerFundingTransaction: null,
    currCustomerFundingTransactionFormMode: 'edit',
    currCustomerFundingTransactionFormStarted: false,
    customerFundingTransactionFormSteps: Ember.computed('currCustomerFundingTransactionFormMode', 'currCustomerFundingTransaction', function () {
      var title;

      if (this.currCustomerFundingTransactionFormMode === 'create') {
        title = this.intl.t('common.forms.descriptive_title_create', {
          name: this.intl.t('pages.administration.content_management.funding.transaction')
        });
      } else {
        title = this.intl.t('common.forms.descriptive_title_view', {
          name: this.intl.t('common.columns.transaction'),
          id: this.get('currCustomerFundingTransaction.id')
        });
      }

      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: title,
          stepComponentPath: 'multi-step-form/forms/customer/edit-customer-funding/edit-customer-funding-transaction'
        })
      });
    }),
    customerFundingTransactionFormOriginalModels: Ember.computed('currCustomerFundingTransaction', 'grants.userGrants', function () {
      return Ember.Object.create({
        fundingTransaction: {
          model: this.currCustomerFundingTransaction,
          copyRelationMap: {
            fundingTransactionType: {
              doValidate: false,
              doSave: false
            }
          }
        }
      });
    }),

    /* end customer funding transactions */
    deleteModel: (0, _emberConcurrency.task)(function* () {
      try {
        var multiStepForm = this.multiStepForm;
        var entryToDelete = multiStepForm.formOriginalModelsGetVal('funding.model');
        yield entryToDelete.destroyRecordWithRollbackTask.perform();
        multiStepForm.formStartedSetVal(false);

        if (this.transitionBackToParent && typeof this.transitionBackToParent === 'function') {
          this.transitionBackToParent();
        }

        this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
        throw err;
      }
    }),
    actions: {
      //action sent to sortable-column component
      sortList(column, ascending) {
        this.sortList(column, ascending); // this.notifyPropertyChange('adminEquipmentData');
      },

      mobileSortList(column) {
        this.sortMobilePaginatedList(column.value); // this.notifyPropertyChange('adminEquipmentData');
      }

    }
  });

  _exports.default = _default;
});