define("securitytrax-ember-ui/components/multi-step-form/switchable-inputs/select-input/select-input-create/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _temp;

  function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

  function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var SelectInputAdd = (_dec = Ember.inject.service(), _dec2 = Ember.inject.service(), _dec3 = Ember._action, (_class = (_temp = class SelectInputAdd extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);
    }

    get optionLabel() {
      var _this$args$optionLabe;

      return (_this$args$optionLabe = this.args.optionLabel) !== null && _this$args$optionLabe !== void 0 ? _this$args$optionLabe : this.intl.t('common.actions.create_new_entity', {
        entity: this.args.createLabelName
      });
    }

    get inputLabel() {
      var _this$args$inputLabel;

      return (_this$args$inputLabel = this.args.inputLabel) !== null && _this$args$inputLabel !== void 0 ? _this$args$inputLabel : this.intl.t('common.actions.create_and_apply_new_entity', {
        entity: this.args.createLabelName
      });
    } // Have to use async action because task does not work for some reason


    createNewEntity(value) {
      var _this = this;

      return _asyncToGenerator(function* () {
        try {
          _this.args.createRecordOptions[_this.args.createValueProperty] = value;

          var newEntity = _this.store.createRecord(_this.args.modelName, _this.args.createRecordOptions);

          yield newEntity.save();

          _this.args.onCreate(_this.args.modelName, newEntity);
        } catch (err) {
          Ember.set(_this, 'errorMessage', err);
          return false;
        }
      })();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "createNewEntity", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "createNewEntity"), _class.prototype)), _class));
  _exports.default = SelectInputAdd;
});