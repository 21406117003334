define("securitytrax-ember-ui/administration/company-settings/integrations/consumer-financing/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/has-page-title", "securitytrax-ember-ui/mixins/route-pageview-logging", "securitytrax-ember-ui/helpers/feature-is-enabled", "ember-concurrency"], function (_exports, _authenticatedRouteMixin, _hasPageTitle, _routePageviewLogging, _featureIsEnabled, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _hasPageTitle.default, _routePageviewLogging.default, {
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    sessionData: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.beforeModelTask.perform();
    },

    beforeModelTask: (0, _emberConcurrency.task)(function* () {
      // BOWLES TODO: Pull feature company enabled value direct from session data with TRAX-12015
      var companyPublicInfo = yield this.sessionData.getPublicCompanyInfo();
      var financingEnabledForCompany = companyPublicInfo && companyPublicInfo.ui_feature_flags.consumer_financing && companyPublicInfo.ui_feature_flags.consumer_financing.enabled;

      if (!(0, _featureIsEnabled.featureIsEnabled)(['consumerFinancing', financingEnabledForCompany])) {
        this.transitionTo('/administration/company-settings');
        this.stNotificationManager.addWarning(this.intl.t('common.notifications.no_permission'));
      }

      return this.findGrantsTask.perform([], 'all', 'administration.company-settings', ['consumerFinancing']);
    }),

    setupController(controller) {
      this._super(...arguments);

      var breadCrumbs = [{
        name: this.intl.t('pages.customers.accounting.financing.consumer_financing'),
        last: true,
        previousLink: 'administration.company-settings',
        link: 'administration.company-settings.content-management.consumer-financing-lenders'
      }];
      var breadCrumbData = {
        title: this.intl.t('pages.administration.company_settings.title'),
        titleLink: 'administration.company-settings'
      };
      controller.set('breadCrumbs', breadCrumbs);
      controller.set('breadCrumbData', breadCrumbData);
    },

    getPageTitle() {
      return this.intl.t('pages.customers.accounting.financing.consumer_financing');
    },

    getRouteName() {
      return "pages.administration.nav.company-settings";
    }

  });

  _exports.default = _default;
});