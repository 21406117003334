define("securitytrax-ember-ui/components/multi-step-form/forms/system/guardian/add-agency/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var GuardianAddAgency = (_dec = Ember.inject.service('intl'), _dec2 = Ember.inject.service('stNotificationManager'), _dec3 = Ember.inject.service('centralStationService'), _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = (0, _emberConcurrency.task)(function* () {
    this.agencyTypes = yield this.getAgencyTypes.perform();
  }), _dec11 = (0, _emberConcurrency.task)(function* () {
    var usedAgencyTypes = this.args.customerAgencies.map(agency => {
      return agency.agency_type;
    });
    var agencyTypes = yield this.centralStationService.getData({
      model: this.args.monitoringCompany,
      method: 'guardian_protection_services__getAgencyTypes',
      payload: {
        agency_number: this.agency_type,
        zip_code: this.agency_zip
      },
      responseKey: 'agency_types'
    });
    return agencyTypes.filter(type => {
      return !usedAgencyTypes.includes(type.id);
    });
  }), _dec12 = (0, _emberConcurrency.task)(function* () {
    try {
      if (!this.agency_type && !this.agency_zip) {
        throw this.intl.t('pages.administration.integrations.monitoringCompanies.integrations.cms.agencies.select_filter');
      }

      this.agencyResultsReady = false;
      var agenciesList = yield this.centralStationService.getData({
        model: this.args.monitoringCompany,
        method: 'guardian_protection_services__getAgenciesByZipCode',
        payload: {
          agency_type: {
            id: this.agency_type
          },
          zip_code: this.agency_zip
        },
        responseKey: 'agencies'
      });
      this.agenciesList = agenciesList;
      this.agencyResultsReady = true;
      this.showAgencyList = true;
    } catch (err) {
      this.agenciesList = [];
      this.agencyResultsReady = true;
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'addAgencyModal'
      });
    }
  }), _dec13 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var messages = [];
    var agenciesToAdd = this.agenciesList.filter(agency => {
      return agency.selected === true;
    });

    try {
      var response = yield this.centralStationService.postData({
        model: this.args.customer,
        method: 'guardian_protection_services__addAgencyToCustomer',
        payload: {
          id: agenciesToAdd[0].id,
          name: agenciesToAdd[0].name,
          agency_type: {
            id: this.agency_type
          }
        }
      });
      messages = messages.concat(response.responsePayload.data.messages);
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccess(this.intl.t('common.notifications.save_successful'), 'modal');

        if (this.args.updateAgencies) {
          this.args.updateAgencies();
        }

        if (messages.length > 0) {
          this.stNotificationManager.addWarningMessages(messages, {
            notificationNamespace: 'modal'
          });
        }
      });
    } catch (err) {
      this.agencyResultsReady = true;
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'addAgencyModal'
      });
      throw err;
    }
  }), _dec14 = Ember._action, (_class = (_temp = class GuardianAddAgency extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "centralStationService", _descriptor3, this);

      _initializerDefineProperty(this, "agenciesList", _descriptor4, this);

      _initializerDefineProperty(this, "agencyResultsReady", _descriptor5, this);

      _initializerDefineProperty(this, "showAgencyList", _descriptor6, this);

      _initializerDefineProperty(this, "agency_type", _descriptor7, this);

      _initializerDefineProperty(this, "agency_zip", _descriptor8, this);

      _initializerDefineProperty(this, "agencyTypes", _descriptor9, this);

      _initializerDefineProperty(this, "initData", _descriptor10, this);

      _initializerDefineProperty(this, "getAgencyTypes", _descriptor11, this);

      _initializerDefineProperty(this, "searchAgenciesTask", _descriptor12, this);

      _initializerDefineProperty(this, "addAgencies", _descriptor13, this);

      this.initData.perform();
      this.args.buttons([{
        text: this.intl.t('common.actions.save'),
        classNamesString: 'st-btn st-btn-primary',
        click: buttonPromise => {
          return this.addAgencies.perform(buttonPromise);
        }
      }]);
    }

    toggleAgencies(row) {
      this.agenciesList.forEach(agency => {
        if (agency.id !== row.id) {
          Ember.set(agency, 'selected', false);
        }
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "agenciesList", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "agencyResultsReady", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "showAgencyList", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "agency_type", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "agency_zip", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "agencyTypes", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "initData", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "getAgencyTypes", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "searchAgenciesTask", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "addAgencies", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleAgencies", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "toggleAgencies"), _class.prototype)), _class));
  _exports.default = GuardianAddAgency;
});