define("securitytrax-ember-ui/components/multi-step-form/forms/administration/system/global-settings/edit-notifications/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _emberConcurrency, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    stNotificationManager: Ember.inject.service('stNotificationManager'),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    screen: Ember.inject.service(),
    collapsed: false,
    newSubscriptions: undefined,
    deleteSubscriptions: undefined,
    notificationToSubscriptionExtraData: undefined,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        newSubscriptions: Ember.Object.create({}),
        deleteSubscriptions: Ember.Object.create({}),
        notificationToSubscriptionExtraData: Ember.Object.create({})
      });
    },

    collapseIconName: Ember.computed('collapsed', function () {
      return this.collapsed ? 'icn-chevron-down' : 'icn-chevron-up';
    }),
    cancelTask: (0, _emberConcurrency.task)(function* () {
      try {
        yield (0, _emberConcurrency.all)([this.multiStepForm.resetForm(), Ember.set(this, 'formMode', 'view'), Ember.set(this, 'newSubscriptions', Ember.Object.create({})), Ember.set(this, 'deleteSubscriptions', Ember.Object.create({})), Ember.set(this, 'notificationToSubscriptionExtraData', Ember.Object.create({}))]);
        this.stNotificationManager.addSuccessKey('common.notifications.generic_cancel');
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err);
      }
    }).drop(),
    saveNotificationSettingsTask: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.multiStepForm.validateSyncSaveAll({
          doSetupCopyModelsAfterSave: false,
          extraValidationData: {
            noValidate: ['group']
          }
        });
        yield (0, _emberConcurrency.all)([this.saveNewSubscriptionsTask.perform(), this.deleteSubscriptionsTask.perform()]);
        this.multiStepForm.resetForm(false);
        Ember.set(this, 'formMode', 'view');
        Ember.set(this, 'newSubscriptions', Ember.Object.create({}));
        Ember.set(this, 'deleteSubscriptions', Ember.Object.create({}));
        Ember.set(this, 'notificationToSubscriptionExtraData', Ember.Object.create({}));
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
      } catch (err) {
        throw new Error(err);
      }
    }).restartable(),
    saveNewSubscriptionsTask: (0, _emberConcurrency.task)(function* () {
      var store = this.store;
      var data = this.notificationToSubscriptionExtraData;
      var newSubscriptions = this.newSubscriptions;
      var objectType = this.objectType;
      var allSaves = Object.keys(newSubscriptions).map(key => {
        var subscription = newSubscriptions.get(key);
        var extraData = data.get(subscription.notification.id);
        var theSubscription = {
          objectType,
          objectToNotificationType: subscription.type,
          notification: subscription.notification,
          sendEmail: extraData != null ? extraData.sendEmail : false,
          sendSms: extraData != null ? extraData.sendSms : false,
          user: objectType === 'user' ? this.multiStepForm.formOriginalModelsGetVal('user.model') : null
        };

        if (objectType === 'company') {
          theSubscription['company'] = this.multiStepForm.formOriginalModelsGetVal('company.model');
        }

        var newSubscription = store.createRecord('notificationSubscription', theSubscription);
        return newSubscription.save();
      });
      yield (0, _emberConcurrency.all)(allSaves);
    }),
    deleteSubscriptionsTask: (0, _emberConcurrency.task)(function* () {
      var deleteSubscriptions = this.deleteSubscriptions;
      var store = this.store;
      var allDeletes = Object.keys(deleteSubscriptions).map(key => {
        var subscription = deleteSubscriptions.get(key);
        return store.findRecord('notificationSubscription', subscription.id).then(deleteSubscription => {
          return deleteSubscription.destroyRecordWithRollbackTask.perform();
        });
      });
      yield (0, _emberConcurrency.all)(allDeletes);
    }),
    collatedSubscriptions: Ember.computed('multiStepForm.formCopiedModels.{company,user}', function () {
      var collatedSubscriptions = Ember.Object.create({});
      var objectType = this.objectType;
      var subscriptions = this.get("multiStepForm.formCopiedModels.".concat(objectType, ".model.notificationSubscriptions"));
      subscriptions.forEach(function (subscription) {
        if (subscription.notification) {
          Ember.set(collatedSubscriptions, subscription.notification.id, subscription);
        }
      });
      return collatedSubscriptions;
    }),
    subscriptionToNotifications: Ember.computed('collatedSubscriptions', function () {
      var groupedNotifications = Ember.A();
      var notifications = this.notifications;
      var collatedSubscriptions = this.collatedSubscriptions;
      var companySubscriptions = this.companySubscriptions;
      Object.keys(notifications).forEach(key => {
        var subscriptionToNotifications = Ember.A();
        notifications[key].forEach(function (currNotification) {
          var companySubscription = companySubscriptions ? companySubscriptions.find(curSubscription => curSubscription.get('notification.id') === currNotification.id) : null;
          subscriptionToNotifications.pushObject(Ember.Object.create({
            notification: currNotification,
            subscription: collatedSubscriptions.get(currNotification.id) || companySubscription
          }));
        });
        groupedNotifications.pushObject(Ember.Object.create({
          key,
          items: subscriptionToNotifications
        }));
      });
      return groupedNotifications;
    }),
    actions: {
      toggleCollapsed() {
        this.toggleProperty('collapsed');
      },

      updateSubscriptions(objectToNotification, subscriptionType) {
        var type = this.objectType === 'company' ? subscriptionType : objectToNotification.subscription && objectToNotification.subscription.get('objectToNotificationType') === 'subscribe' ? 'unsubscribe' : 'subscribe';

        if (!objectToNotification.subscription) {
          Ember.set(objectToNotification, 'subscription', Ember.Object.create({
            objectToNotificationType: type,
            sendSms: false,
            sendEmail: false
          }));
          Ember.set(this.newSubscriptions, objectToNotification.notification.id, Ember.Object.create({
            notification: objectToNotification.notification,
            type: type
          }));
          return;
        }

        if (this.objectType === 'user') {
          objectToNotification.subscription.setProperties({
            objectToNotificationType: type,
            sendSms: false,
            sendEmail: false
          });

          if (this.newSubscriptions.get(objectToNotification.notification.id)) {
            this.newSubscriptions[objectToNotification.notification.id].type = type;
          }
        } else {
          if (type === objectToNotification.subscription.objectToNotificationType) {
            objectToNotification.subscription.setProperties({
              delete: true
            });

            if (objectToNotification.subscription.id) {
              Ember.set(this.deleteSubscriptions, objectToNotification.notification.id, objectToNotification.subscription);
            } else if (this.newSubscriptions.get(objectToNotification.notification.id)) {
              delete this.newSubscriptions[objectToNotification.notification.id];
            }
          } else {
            if (!objectToNotification.subscription.id) {
              Ember.set(this.newSubscriptions, objectToNotification.notification.id, Ember.Object.create({
                notification: objectToNotification.notification,
                type: type
              }));
            } else if (this.deleteSubscriptions.get(objectToNotification.notification.id)) {
              delete this.deleteSubscriptions[objectToNotification.notification.id];
            }

            objectToNotification.subscription.setProperties({
              objectToNotificationType: type,
              sendSms: false,
              sendEmail: false
            });
          }
        }
      },

      updateSubscriptionData(objectToNotification, sendType) {
        var objectType = this.objectType;
        var data = this.notificationToSubscriptionExtraData;
        var subscriptionData = data.get(objectToNotification.notification.id);
        var newSubscriptions = this.newSubscriptions;

        if (objectType === 'user' && objectToNotification.subscription && objectToNotification.subscription.get('objectType') === 'company' && !newSubscriptions.get(objectToNotification.notification.id)) {
          Ember.set(newSubscriptions, objectToNotification.notification.id, Ember.Object.create({
            notification: objectToNotification.notification,
            type: objectToNotification.subscription.get('objectToNotificationType')
          }));
        }

        if (!subscriptionData) {
          if (objectToNotification.subscription && objectToNotification.subscription.id) {
            var existingObjectNotificationData = {
              sendEmail: objectToNotification.subscription.get('sendEmail'),
              sendSms: objectToNotification.subscription.get('sendSms')
            };
            existingObjectNotificationData[sendType] = !existingObjectNotificationData[sendType];
            objectToNotification.subscription.setProperties(existingObjectNotificationData);

            if (objectType === 'user' && objectToNotification.subscription && objectToNotification.subscription.get('objectType') === 'company') {
              Ember.set(data, objectToNotification.notification.id, Ember.Object.create(existingObjectNotificationData));
            }
          } else {
            var newObjectNotificationData = {
              sendEmail: false,
              sendSms: false
            };
            newObjectNotificationData[sendType] = !newObjectNotificationData[sendType];
            objectToNotification.subscription.setProperties(newObjectNotificationData);
            Ember.set(data, objectToNotification.notification.id, Ember.Object.create(newObjectNotificationData));
          }
        } else {
          var existingSubscriptionData = {
            sendEmail: subscriptionData.sendEmail,
            sendSms: subscriptionData.sendSms
          };
          existingSubscriptionData[sendType] = !existingSubscriptionData[sendType];
          objectToNotification.subscription.setProperties(existingSubscriptionData);
          subscriptionData.setProperties(existingSubscriptionData);
        }
      }

    }
  });

  _exports.default = _default;
});