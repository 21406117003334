define("securitytrax-ember-ui/components/multi-step-form/forms/administration/system/upload-tool/get-started/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3fN14df9",
    "block": "{\"symbols\":[\"data\",\"&attrs\",\"@bulkActionId\",\"@updateBulkActionId\"],\"statements\":[[8,\"html/st-await\",[],[[\"@useSpinner\",\"@promises\"],[true,[30,[36,1],null,[[\"actionOptions\"],[[35,0]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[11,\"div\"],[17,2],[12],[2,\"\\n        \"],[8,\"multi-step-form/progress-bar\",[],[[\"@config\",\"@modalButtons\"],[[34,2],[34,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"div\"],[14,0,\"grid-x grid-padding-x grid-padding-y\"],[12],[2,\"\\n                \"],[10,\"div\"],[14,0,\"cell small-12 upload-prompt\"],[12],[2,\"\\n                    \"],[1,[30,[36,4],[\"pages.administration.system.uploadTool.steps.getStarted.prompt\"],null]],[2,\"\\n                \"],[13],[2,\"\\n                \"],[10,\"div\"],[14,0,\"cell small-12\"],[12],[2,\"\\n                    \"],[8,\"html/package-select/package-layouts/default-layout\",[],[[\"@packageList\",\"@selectedId\",\"@labelProperty\",\"@canDeselect\",\"@additionalPackageTitleClasses\",\"@updateSelected\"],[[32,1,[\"resolvedPromises\",\"actionOptions\"]],[32,3],\"packageName\",false,\"package-title__large-title\",[32,4]]],null],[2,\"\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"actionOptions\",\"hash\",\"progressBarConfig\",\"buttons\",\"t\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/system/upload-tool/get-started/template.hbs"
    }
  });

  _exports.default = _default;
});