define("securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-timeoff/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var UserTimeOff = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service('events/home-dashboard'), _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = (0, _emberConcurrency.task)(function* () {
    if (this.args.model.appointmentId !== 'create') {
      this.calendarEvent = yield this.store.findRecord('calendarEvent', this.args.model.appointmentId);

      if (this.calendarEvent && this.calendarEvent.responseMeta.assigned_calendar_user) {
        this.timeOffCalendarUser = yield this.store.findRecord('calendarUser', this.calendarEvent.responseMeta.assigned_calendar_user.id);
        this.timeOffUser = yield this.timeOffCalendarUser.get('user');
      }
    }

    if (!this.timeOffCalendarUser) {
      this.timeOffCalendarUser = this.args.model.calendarUser;
    }

    if (!this.timeOffUser) {
      this.timeOffUser = this.args.model.user;
    }

    this.formStarted = true;
  }), _dec12 = Ember._action, _dec13 = Ember._action, (_class = (_temp = class UserTimeOff extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "grants", _descriptor4, this);

      _initializerDefineProperty(this, "homeDashboard", _descriptor5, this);

      _initializerDefineProperty(this, "editingTimeoffCalendarEvent", _descriptor6, this);

      _initializerDefineProperty(this, "formMode", _descriptor7, this);

      _initializerDefineProperty(this, "formStarted", _descriptor8, this);

      _initializerDefineProperty(this, "timeOffUser", _descriptor9, this);

      _initializerDefineProperty(this, "timeOffCalendarUser", _descriptor10, this);

      _initializerDefineProperty(this, "initData", _descriptor11, this);

      this.initData.perform();
    }

    get timeOffFormSteps() {
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.intl.t('pages.users.time_off.edit_title', {
            user_name: this.timeOffUser.get('fname') + ' ' + this.timeOffUser.get('lname')
          }),
          stepComponentPath: 'multi-step-form/forms/administration/users/time-off'
        })
      });
    }

    get timeOffFormOriginalModels() {
      return Ember.Object.create({
        calendarEvent: {
          model: this.calendarEvent
        }
      });
    }

    transitionBackToParentRoute() {
      this.router.transitionTo(this.args.model.parentRoute);
    }

    updateAppointmentsList() {
      this.homeDashboard.trigger('refresh-appointments');
      this.transitionBackToParentRoute();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "homeDashboard", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "editingTimeoffCalendarEvent", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "formMode", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'view';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "formStarted", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "timeOffUser", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "timeOffCalendarUser", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "initData", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "transitionBackToParentRoute", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "transitionBackToParentRoute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateAppointmentsList", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "updateAppointmentsList"), _class.prototype)), _class));
  _exports.default = UserTimeOff;
});