define("securitytrax-ember-ui/mixins/has-page-title", ["exports", "securitytrax-ember-ui/utils/debug"], function (_exports, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @classdesc
   * Augments consuming routes with code for updating the page title from model contents.
   *
   * @mixin HasPageTitle
   * @memberof mixins
   */
  var _default = Ember.Mixin.create(
  /** @lends mixins.HasPageTitle */
  {
    pageTitle: Ember.inject.service(),

    /**
     * Returns the computed page title.
     *
     * @param {Object} model
     *
     * @returns {String}
     * @abstract
     */
    getPageTitle(model) {
      // eslint-disable-line no-unused-vars
      (0, _debug.logMissingAbstractMember)(this, 'getPageTitle');
    },

    /**
     * Returns the title route name.
     *
     * @returns {String}
     * @abstract
     */
    getRouteName() {
      (0, _debug.logMissingAbstractMember)(this, 'getRouteName');
    },

    /**
     * Updates title.
     *
     * @ignore
     */
    afterModel(model) {
      this.pageTitle.setPageTitle(this.getPageTitle(model), this.getRouteName());
      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});