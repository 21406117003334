define("securitytrax-ember-ui/mixins/list/list-sort-mixin", ["exports", "securitytrax-ember-ui/utils/sort-compare"], function (_exports, _sortCompare) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Adds ability for different list funcationality
   *
   * @mixin ListSort
   * @extends Ember.Mixin
   */
  var _default = Ember.Mixin.create({
    sort(list, attr, direction) {
      attr = typeof attr !== 'undefined' ? attr : 'id';
      direction = typeof direction !== 'undefined' ? direction : 'desc';

      if (list.length > 1) {
        var returnList = list.sort(this.compareFunction(attr, direction));
        return returnList;
      }

      return list;
    },

    compareFunction(key, order = 'asc') {
      return function (a, b) {
        var comparison = (0, _sortCompare.sortCompareFunction)(a.get(key), b.get(key));
        return order == 'desc' ? comparison * -1 : comparison;
      };
    }

  });

  _exports.default = _default;
});