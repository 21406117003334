define("securitytrax-ember-ui/components/calendar-glimmer/calendar-helpers/calendar-page/calendar-row", ["exports", "moment", "ember-concurrency", "lodash"], function (_exports, _moment, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CalendarRow = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = (0, _emberConcurrency.task)(function* (eventId) {
    if (this.calendarEvents !== undefined) {
      this.calendarEvents = yield _lodash.default.remove(this.calendarEvents.toArray(), function (event) {
        if (event.id != eventId) {
          return event;
        }
      });
    }

    Ember.notifyPropertyChange(this, 'calendarEvents');
  }), _dec11 = Ember.computed('calendarEvents.{length,@each.startTime}'), _dec12 = (0, _emberConcurrency.task)(function* () {
    var currCalendarEvents = yield this.calendarEvents;

    if (currCalendarEvents) {
      return currCalendarEvents.sort(function (a, b) {
        var startTimeA = _moment.default.isMoment(a.get('startTime')) ? a.get('startTime') : (0, _moment.default)(a.get('startTime'));
        var startTimeB = _moment.default.isMoment(b.get('startTime')) ? b.get('startTime') : (0, _moment.default)(b.get('startTime'));
        return startTimeA.unix() <= startTimeB.unix() ? -1 : 1;
      });
    }

    return [];
  }), _dec13 = Ember.computed('sortedCalendarEvents', 'sortedCalendarEvents.@each.duration'), _dec14 = (0, _emberConcurrency.task)(function* () {
    var totalRows = 0;
    var calendarEventRows = [];

    if (this.calendarEvents) {
      var lastHour = this.calendarPage.hours[this.calendarPage.hours.length - 1] ? this.calendarPage.hours[this.calendarPage.hours.length - 1] : (0, _moment.default)({
        hour: 20
      });
      var firstHour = this.calendarPage.hours[0] ? this.calendarPage.hours[0] : (0, _moment.default)({
        hour: 8
      });
      var sortedCalendarEvents = yield this.sortedCalendarEvents;
      var filteredCalendarEvents = yield sortedCalendarEvents.filter(event => {
        var startTime = (0, _moment.default)(event.startTime);

        if (startTime.day === (0, _moment.default)(this.calendarPage.date).day) {
          return event;
        }
      });

      var _loop = function (i) {
        var calendarEvent = filteredCalendarEvents.objectAt(i);
        var startTime = (0, _moment.default)(calendarEvent.get('startTime'));

        if (startTime) {
          lastHour.set({
            year: startTime.year(),
            month: startTime.month(),
            date: startTime.date()
          });
        }

        firstHour.set({
          year: startTime.year(),
          month: startTime.month(),
          date: startTime.date()
        }); // check to see if the calendar event goes over the end of the day or the start of the day

        var calculatedEndtime = calendarEvent.get('calculatedEndtime');
        calendarEvent.set('calculatedEndtimeResolved', calculatedEndtime);
        var eventDuration = calendarEvent.get('duration');
        calendarEvent.set('calculatedDuration', eventDuration);
        var maxRow = 0;
        calendarEventRows.forEach((row, level) => {
          row.forEach(calendarEventRow => {
            if (calendarEventRow.isSuperposedWithEvent(calendarEvent)) {
              maxRow = level + 1;
            }
          });
        });

        if (maxRow >= calendarEventRows.length) {
          calendarEventRows[maxRow] = [];
        }

        calendarEventRows[maxRow].push(calendarEvent);

        if (maxRow > totalRows) {
          totalRows = maxRow;
        }
      };

      for (var i = 0; i < filteredCalendarEvents.get('length'); i++) {
        _loop(i);
      }
    }

    this.totalRows = totalRows + 1;
    this.rowsHeightClass = 'total-rows-' + (totalRows + 1);
    return calendarEventRows;
  }), (_class = (_temp = class CalendarRow {
    constructor(requiredArguments) {
      _defineProperty(this, "id", null);

      _defineProperty(this, "type", void 0);

      _defineProperty(this, "user", void 0);

      _defineProperty(this, "date", void 0);

      _defineProperty(this, "isActive", true);

      _defineProperty(this, "rowCells", []);

      _initializerDefineProperty(this, "didUpdate", _descriptor, this);

      _initializerDefineProperty(this, "calendarEvents", _descriptor2, this);

      _initializerDefineProperty(this, "calendarEventArray", _descriptor3, this);

      _initializerDefineProperty(this, "calendarPage", _descriptor4, this);

      _initializerDefineProperty(this, "rowsHeightClass", _descriptor5, this);

      _initializerDefineProperty(this, "totalRows", _descriptor6, this);

      _initializerDefineProperty(this, "isTech", _descriptor7, this);

      _initializerDefineProperty(this, "isSalesRep", _descriptor8, this);

      _initializerDefineProperty(this, "isSelectedForMap", _descriptor9, this);

      _initializerDefineProperty(this, "removeCalendarEventTask", _descriptor10, this);

      _initializerDefineProperty(this, "sortedCalendarEventsTask", _descriptor11, this);

      _initializerDefineProperty(this, "calendarEventRowsTask", _descriptor12, this);

      if (requiredArguments && requiredArguments.user) {
        this.user = requiredArguments.user;
      } else {
        this.user = 'inactive';
        this.isActive = false;
      }

      if (requiredArguments && requiredArguments.date) {
        this.date = requiredArguments.date;
      }

      if (requiredArguments && requiredArguments.type) {
        this.type = requiredArguments.type;

        if (this.type === 'date') {
          if (this.isActive) {
            this.id = this.user.get('id');
          } else {
            this.id = 'inactive';
          }
        } else {
          this.id = (0, _moment.default)(requiredArguments.date.date).format('M-D-YYYY');
        }
      }

      if (requiredArguments && requiredArguments.calendarPage) {
        this.calendarPage = requiredArguments.calendarPage;
      }

      if (requiredArguments && requiredArguments.isTech) {
        this.isTech = requiredArguments.isTech;
      }

      if (requiredArguments && requiredArguments.isSalesRep) {
        this.isSalesRep = requiredArguments.isSalesRep;
      }

      if (requiredArguments && requiredArguments.calendarEvents) {
        this.calendarEvents = requiredArguments.calendarEvents;
      }

      if (requiredArguments && requiredArguments.rowCells) {
        this.rowCells = _lodash.default.cloneDeep(requiredArguments.rowCells);
      }

      if (requiredArguments && requiredArguments.isSelectedForMap) {
        this.isSelectedForMap = requiredArguments.isSelectedForMap;
      }

      this.didUpdate = false;
    }

    addCalendarEvent(theEvent) {
      this.calendarEvents.push(theEvent);
      Ember.notifyPropertyChange(this, 'calendarEvents');
    }

    removeCalendarEvent(eventId) {
      return this.removeCalendarEventTask.perform(eventId);
    }

    get sortedCalendarEvents() {
      return this.sortedCalendarEventsTask.perform();
    }

    get calendarEventRows() {
      return this.calendarEventRowsTask.perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "didUpdate", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "calendarEvents", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "calendarEventArray", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "calendarPage", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "rowsHeightClass", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "totalRows", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isTech", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isSalesRep", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isSelectedForMap", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "removeCalendarEventTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "sortedCalendarEvents", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "sortedCalendarEvents"), _class.prototype), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "sortedCalendarEventsTask", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "calendarEventRows", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "calendarEventRows"), _class.prototype), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "calendarEventRowsTask", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CalendarRow;
});