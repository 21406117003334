define("securitytrax-ember-ui/validations/central-station/stages-modify-address", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateObject = {
        city: {
          presence: () => {
            return this.customMessageOrDefault(data, 'stages-city');
          }
        },
        state: {
          presence: () => {
            return this.customMessageOrDefault(data, 'stages-state');
          }
        },
        address: {
          presence: () => {
            return this.customMessageOrDefault(data, 'stages-address');
          }
        },
        zip: {
          presence: () => {
            return this.customMessageOrDefault(data, 'stages-zip');
          }
        },
        county: {
          presence: () => {
            return this.customMessageOrDefault(data, 'stages-county');
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      return this.outputFormatting(validationData, 'stages');
    }

  });

  _exports.default = _default;
});