define("securitytrax-ember-ui/modifiers/zipmod", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

  function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

  var _default = (0, _emberModifier.modifier)(function zipmod(element, data
  /*, params, hash*/
  ) {
    function populateCityStateCounty() {
      populateCityStateCountyTask();
    }

    function populateCityStateCountyTask() {
      return _populateCityStateCountyTask.apply(this, arguments);
    }

    function _populateCityStateCountyTask() {
      _populateCityStateCountyTask = _asyncToGenerator(function* () {
        Ember.set(data[0], 'countyDisable', true);
        var model = data[1];
        var zipString = data[2];
        var cityString = data[3];
        var stateString = data[4];
        var countyString = data[5];

        if (!model) {
          return;
        }

        var zip = Ember.get(model, zipString);

        if (zip) {
          var validZip = false;

          if (zip.length >= 5) {
            var us = new RegExp('^\\d{5}(-{0,1}\\d{4})?$'); //test us zip codes

            var ca = new RegExp(/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/); // tests CA zip codes

            zip = zip.toUpperCase();

            if (zip.length === 5) {
              validZip = us.test(zip); //We only accept the 5 digit version though we could make it 10
            } else if (zip.length > 5) {
              var tempZip = zip;

              if (ca.test(tempZip)) {
                tempZip = tempZip.replace(/\s+|-/g, ''); //sometimes they will input with a space or - this removes both and then we update below 

                if (tempZip.length === 6) {
                  zip = tempZip.substring(0, 3) + ' ' + tempZip.substring(3, tempZip.length); //masks the ca zip to "XXX XXX"

                  validZip = true;
                }
              }
            }

            Ember.set(model, zipString, zip);

            if (validZip) {
              //only call when we have a valid zip
              var company = yield data[0].sessionData.getCompany();

              try {
                var infoFromZip = yield company.invokeAction('get_preferred_zip_code_data', {
                  verb: 'GET',
                  data: {
                    zip_code: zip
                  }
                });
                var infoFromZipData = infoFromZip['responsePayload']['data']['get_preferred_zip_code_data_response']['result'];

                if (infoFromZipData) {
                  Ember.set(model, cityString, infoFromZipData['city']);
                  Ember.set(model, stateString, infoFromZipData['state']);
                  Ember.set(model, countyString, infoFromZipData['county']);
                }
              } catch (error) {
                (false && Ember.warn('failed to get zip code preferences' + error, {
                  id: 'multiStepForm.forms.editCreateCustomer.information'
                }));
              }
            }

            Ember.set(data[0], 'countyDisable', false);
          }
        }
      });
      return _populateCityStateCountyTask.apply(this, arguments);
    }

    element.addEventListener('keyup', populateCityStateCounty);
  });

  _exports.default = _default;
});