define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/customer-create-chooser/create-form-configs/reliant-sales-1/component", ["exports", "securitytrax-ember-ui/mixins/customer-create-chooser"], function (_exports, _customerCreateChooser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_customerCreateChooser.default, {
    createCustomerFormSteps: Ember.computed('newCustomerName', 'grants.userGrants', function () {
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.newCustomer ? this.newCustomerName : this.intl.t('pages.leads.title_create'),
          stepComponentPath: 'multi-step-form/forms/leads/information',
          progressBarTitle: this.intl.t('common.nav.create.lead.label')
        }),
        step2: Ember.Object.create({
          stepNameKey: 'step2',
          title: this.newCustomerName,
          stepComponentPath: 'multi-step-form/forms-dynamic/generic-steps/customer-create/nrg/confirm-customer',
          progressBarTitle: this.intl.t('common.nav.create.customer.sections.confirm_customer')
        }),
        step3: Ember.Object.create({
          stepNameKey: 'step3',
          title: this.newCustomerName,
          stepComponentPath: 'multi-step-form/forms-dynamic/generic-steps/customer-create/nrg/call-back',
          progressBarTitle: this.intl.t('common.nav.create.customer.sections.callBack')
        }),
        step4: Ember.Object.create({
          stepNameKey: 'step4',
          title: this.newCustomerName,
          stepComponentPath: 'multi-step-form/forms-dynamic/generic-steps/customer-create/qualify',
          progressBarTitle: this.intl.t('common.nav.create.customer.sections.preApproval'),
          hidden: !this.grants.getIndividualGrant('customer_credit_checks.create')
        }),
        step5: Ember.Object.create({
          stepNameKey: 'step5',
          title: this.newCustomerName,
          stepComponentPath: 'multi-step-form/forms-dynamic/generic-steps/customer-create/nrg/quote',
          progressBarTitle: this.intl.t('common.nav.create.customer.sections.quote')
        }),
        step6: Ember.Object.create({
          stepNameKey: 'step6',
          title: this.newCustomerName,
          stepComponentPath: 'multi-step-form/forms-dynamic/generic-steps/customer-create/nrg/view-schedule-availability',
          progressBarTitle: this.intl.t('common.nav.create.customer.sections.view_schedule_availability')
        }),
        step7: Ember.Object.create({
          stepNameKey: 'step7',
          title: this.newCustomerName,
          stepComponentPath: 'multi-step-form/forms-dynamic/generic-steps/customer-create/qualify',
          progressBarTitle: this.intl.t('common.nav.create.customer.sections.external_credit_check')
        }),
        step8: Ember.Object.create({
          stepNameKey: 'step8',
          title: this.newCustomerName,
          stepComponentPath: 'multi-step-form/forms/leads/convert-customer',
          progressBarTitle: this.intl.t('pages.leads.convert_to_customer')
        }),
        step9: Ember.Object.create({
          stepNameKey: 'step9',
          title: this.newCustomerName,
          stepComponentPath: 'multi-step-form/forms-dynamic/generic-steps/customer-create/nrg/schedule-work-order',
          progressBarTitle: this.intl.t('common.nav.create.customer.sections.schedule_work_order')
        }),
        step10: Ember.Object.create({
          stepNameKey: 'step10',
          title: this.newCustomerName,
          stepComponentPath: 'multi-step-form/forms-dynamic/generic-steps/customer-create/nrg/autopay-setup',
          progressBarTitle: this.intl.t('common.nav.create.customer.sections.autopay_setup')
        }),
        step11: Ember.Object.create({
          stepNameKey: 'step11',
          title: this.newCustomerName,
          stepComponentPath: 'multi-step-form/forms-dynamic/generic-steps/customer-create/nrg/econtract',
          progressBarTitle: this.intl.t('common.nav.create.customer.sections.econtract')
        }),
        // Might be added later
        // step12: EmberObject.create({
        //     stepNameKey: 'step12',
        //     title: this.newCustomerName,
        //     stepComponentPath: 'multi-step-form/forms-dynamic/generic-steps/customer-create/confirm-docusign-signature',
        //     progressBarTitle: this.intl.t('common.nav.create.customer.sections.confirm_docusign_signature')
        // }),
        confirmDeleteCustomer: Ember.Object.create({
          stepNameKey: 'confirmDeleteCustomer',
          title: this.newCustomerName,
          stepComponentPath: 'multi-step-form/forms-dynamic/generic-steps/customer-create/confirm-delete-customer',
          hidden: true
        })
      });
    })
  });

  _exports.default = _default;
});