define("securitytrax-ember-ui/components/dashboard-config/canvas/section/component", ["exports", "securitytrax-ember-ui/mixins/sortable-list-mixin"], function (_exports, _sortableListMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_sortableListMixin.default, {
    classNames: ['dashboard-config__section', 'dashboard-config__section--canvas'],
    grants: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    homeDashboard: Ember.inject.service('events/home-dashboard'),
    sectionTitle: '',
    cards: undefined,

    init() {
      this._super(...arguments);

      this.cards.forEach(card => {
        var _card$config$grant;

        card.config.titleKey = this.editDashboard.availableCards[card.card].config.titleKey;
        card.config.hasRoles = this.editDashboard._checkCardRoles(card.config, this.roles);
        var cardFocus = card.config.card_focus && typeof card.config.card_focus === 'object' ? card.config.card_focus.value : card.config.card_focus;
        card.config.grant = (_card$config$grant = card.config.grant) !== null && _card$config$grant !== void 0 ? _card$config$grant : this.editDashboard.availableCards[card.card].config.card_focus_options[cardFocus];
        card.config.passADCCheck = this.editDashboard._checkCardProviderType(card, this.ispProvTypeId);
      });
    },

    actions: {
      sortEndAction() {
        this.cards.forEach((card, index) => {
          Ember.set(card, 'order', index);
        });
      },

      deleteCard(card) {
        var canvasCards = this.editDashboard.dashboardCards;
        var cardData = canvasCards[0].cardData;
        var page = cardData.pages[1];
        var section = page.sections['sectionless'];
        var newCards = section.cards.filter(canvasCard => {
          return canvasCard.order !== card.order;
        });
        newCards.forEach((card, index) => {
          Ember.set(card, 'order', index);
        });
        Ember.set(section, 'cards', newCards);
        this.homeDashboard.trigger('dashboard-config-update', this.editDashboard.dashboardLocation);
      }

    }
  });

  _exports.default = _default;
});