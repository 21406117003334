define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/nrg/autopay-setup/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms/leads/lead-base", "securitytrax-ember-ui/classes/user-defined-fields"], function (_exports, _emberConcurrency, _leadBase, _userDefinedFields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var AutopaySetup = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = (0, _emberConcurrency.task)(function* () {
    this.UdfClass = new _userDefinedFields.default({
      store: this.store,
      intl: this.intl,
      udfIDs: this.udfIDs,
      model: this.newCustomer
    });
    yield this.UdfClass.udfListTask.perform();
    yield this.configTask.perform();
  }), _dec8 = (0, _emberConcurrency.task)(function* () {
    var workflow = yield this.store.findRecord('workflow', 'reliant_sales_1', {
      include: 'workflowConfig'
    });
    var workflowConfigs = yield workflow.get('workflowConfig');
    var workflowConfig = yield workflowConfigs.objectAt(0);
    this.configData = workflowConfig.config;
  }), _dec9 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise, close = false) {
    try {
      var formattedObjects = Ember.A();
      this.UdfClass.validateUdfs(formattedObjects);
      yield multiStepForm.validateList.perform(formattedObjects, {
        notificationNamespace: 'modal'
      });
      this.UdfClass.saveUdfsTask.perform(this.newCustomer);
      buttonPromise.then(() => {
        if (close) {
          this.set('formMode', 'create');
          this.closeForm(multiStepForm, this.customer);
        } else {
          this.advanceForm(multiStepForm, 'step11', 10);
        }
      });
    } catch (error) {
      this.stNotificationManager.addErrorMessages(error, {
        notificationNamespace: 'modal'
      });
      throw error;
    }
  }), _dec10 = Ember._action, _dec11 = Ember._action, (_class = (_temp = class AutopaySetup extends _leadBase.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor4, this);

      _initializerDefineProperty(this, "configData", _descriptor5, this);

      _initializerDefineProperty(this, "UdfClass", _descriptor6, this);

      _defineProperty(this, "udfIDs", [{
        id: 42,
        required: false
      }]);

      _initializerDefineProperty(this, "initTask", _descriptor7, this);

      _initializerDefineProperty(this, "configTask", _descriptor8, this);

      _initializerDefineProperty(this, "saveAutopaySetupTask", _descriptor9, this);
    }

    setupButtons() {
      this.initTask.perform();
      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('step10', 'buttons', [{
        text: this.intl.t('common.actions.back'),
        classNamesString: 'st-btn st-btn-secondary',
        click: () => {
          multiStepForm.currentStepNameKeySetVal('step9');
          multiStepForm.progressBarConfig.activeIndex = 8;
        }
      }, {
        text: this.intl.t('common.actions.skip'),
        classNamesString: 'st-btn st-btn-secondary',
        click: () => {
          this.advanceForm(multiStepForm, 'step11', 10);
        }
      }, {
        text: this.intl.t('common.actions.saveAndContinue'),
        classNamesString: 'st-btn st-btn-primary lead-to-customer-button',
        click: buttonPromise => {
          return this.saveAutopaySetupTask.perform(multiStepForm, buttonPromise);
        },
        positionOptions: 'up left',
        additionalClickActions: [{
          label: this.intl.t('common.actions.saveAndClose'),
          action: buttonPromise => {
            return this.saveAutopaySetupTask.perform(multiStepForm, buttonPromise, true);
          },
          classNamesString: 'dropdown-save-close'
        }]
      }]);
    }

    openNRGTab() {
      window.open(this.configData.step_data.step6.nrg_autopay_button_href, '_blank');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "configData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "UdfClass", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "initTask", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "configTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "saveAutopaySetupTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupButtons", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "setupButtons"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openNRGTab", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "openNRGTab"), _class.prototype)), _class));
  _exports.default = AutopaySetup;
});