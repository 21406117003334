define("securitytrax-ember-ui/models/customer-contact-udf", ["exports", "securitytrax-ember-ui/models/base_models/customer-contact-udf"], function (_exports, _customerContactUdf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerContactUdf.default.extend({});

  _exports.default = _default;
});