define("securitytrax-ember-ui/components/multi-step-form/forms/administration/payables/line-item-details/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "@glimmer/component"], function (_exports, _emberConcurrency, _switchableFormStep, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EditLineItemDetails = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
    var calculationMethod = this.args.multiStepForm.formCopiedModelsGetVal('payrollColumn.model.payrollColumnCalculation');
    this.calculationType = calculationMethod ? calculationMethod.get('excelFunction') ? 'excelFunction' : 'flatRate' : 'custom';
  }), _dec13 = (0, _emberConcurrency.task)(function* () {
    var excelFunctionEnabled = yield this.enhancedFeatures.hasFeature('enhancedPayables');
    var options = [{
      name: this.intl.t('pages.administration.human_resources.flat_rate'),
      value: 'flatRate'
    }, {
      name: this.intl.t('common.status.custom'),
      value: 'custom'
    }];

    if (excelFunctionEnabled) {
      options.push({
        name: this.intl.t('pages.administration.human_resources.formula'),
        value: 'excelFunction'
      });
    }

    return options;
  }), _dec14 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    yield this.args.multiStepForm.validateList.perform([Ember.Object.create({
      filePath: 'payroll-column',
      model: {
        name: this.duplicateName
      },
      extraValidationData: {
        overrideValidationKey: 'duplicate-payrollColumn'
      }
    })]);

    try {
      var newlySavedLineItem = (yield this.args.multiStepForm.formOriginalModelsGetVal('payrollColumn.model').invokeAction('duplicate', {
        verb: 'POST',
        processResponse: true,
        data: {
          data: {
            attributes: {
              name: this.duplicateName,
              about: this.duplicateDescription
            }
          }
        }
      })).models[0];
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccess(this.intl.t('common.notifications.save_successful'));
        multiStepForm.formStartedSetVal(false);
        this.showDuplicateLineItemModal = false;
        this.addLineItem = newlySavedLineItem;
        this.router.transitionTo('administration.company-settings.system.payables.function-details.level-details.line-item-details', newlySavedLineItem.get('id'));
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      (false && Ember.warn('HR duplicate line Item error' + err, {
        id: 'multiStepForm.forms.administration.payables.duplicateLineItem'
      }));
      throw err;
    }
  }), _dec15 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    if (this.calculationType === 'flatRate') {
      this.args.multiStepForm.formCopiedModelsSetVal('payrollColumn.model.payrollColumnCalculation.flatRate', this.args.multiStepForm.formCopiedModelsGetVal('payrollColumn.model.payrollColumnCalculation.flatRate') || 0.00);
      this.args.multiStepForm.formCopiedModelsSetVal('payrollColumn.model.payrollColumnCalculation.excelFunction', null);
    } else if (this.calculationType === 'excelFunction') {
      this.args.multiStepForm.formCopiedModelsSetVal('payrollColumn.model.payrollColumnCalculation.flatRate', null);

      if (this.args.multiStepForm.formCopiedModels.payrollColumn.model.payrollColumnCalculation.excelFunction) {
        if (this.args.multiStepForm.formCopiedModels.payrollColumn.model.payrollColumnCalculation.excelFunction.trim() == '=') {
          this.args.multiStepForm.formCopiedModelsSetVal('payrollColumn.model.payrollColumnCalculation.excelFunction', null);
        } else if (this.args.multiStepForm.formCopiedModels.payrollColumn.model.payrollColumnCalculation.excelFunctionVal) {
          try {
            var dummyModel = this.store.createRecord('payrollColumnCalculation');
            var returnData = yield dummyModel.invokeAction('validate_payroll_column_calculation', {
              verb: 'POST',
              data: {
                data: {
                  attributes: {
                    formula: this.args.multiStepForm.formCopiedModels.payrollColumn.model.payrollColumnCalculation.excelFunctionVal
                  }
                }
              }
            });

            if (typeof returnData.responsePayload.data.result !== 'number') {
              this.warningModalOpen = true;
            }
          } catch (err) {
            this.warningModalOpen = true;
          }
        }
      }
    }

    if (!this.warningModalOpen) {
      yield this.saveColumn.perform(buttonPromise);
    }
  }), _dec16 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    yield this.args.multiStepForm.validateSyncSaveAll({
      extraValidationData: {
        noValidate: this.noValidateArray
      }
    });
    buttonPromise.then(() => {
      this.stNotificationManager.addSuccess(this.intl.t('common.notifications.save_successful'));
    });
  }), _dec17 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var columnToDelete = this.args.multiStepForm.formOriginalModelsGetVal('payrollColumn.model');

    try {
      yield columnToDelete.destroyRecordWithRollbackTask.perform();
      buttonPromise.then(() => {
        this.args.multiStepForm.setupCopyModels.perform();
        this.args.multiStepForm.validationErrors = {};
        this.args.payrollRoleFunctionLevel.reload();
        this.router.transitionTo('administration.company-settings.system.payables.function-details.level-details', this.args.payrollRoleFunctionLevel.get('id'));
        this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      (false && Ember.warn('HR delete column error' + err, {
        id: 'multiStepForm.forms.administration.permissions.deleteColumn'
      }));
      columnToDelete.rollbackAttributes();
      throw err;
    }
  }), _dec18 = Ember._action, (_class = (_temp = class EditLineItemDetails extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "sessionData", _descriptor5, this);

      _initializerDefineProperty(this, "enhancedFeatures", _descriptor6, this);

      _initializerDefineProperty(this, "calculationType", _descriptor7, this);

      _initializerDefineProperty(this, "warningModalOpen", _descriptor8, this);

      _initializerDefineProperty(this, "showDuplicateLineItemModal", _descriptor9, this);

      _initializerDefineProperty(this, "duplicateName", _descriptor10, this);

      _initializerDefineProperty(this, "duplicateDescription", _descriptor11, this);

      _initializerDefineProperty(this, "initFormTask", _descriptor12, this);

      _initializerDefineProperty(this, "getCalculationTypeOptionsTask", _descriptor13, this);

      _initializerDefineProperty(this, "duplicateLineItem", _descriptor14, this);

      _initializerDefineProperty(this, "editColumnTask", _descriptor15, this);

      _initializerDefineProperty(this, "saveColumn", _descriptor16, this);

      _initializerDefineProperty(this, "deleteColumn", _descriptor17, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.initFormTask.perform();
    }

    get calculationTypeOptions() {
      return this.getCalculationTypeOptionsTask.perform();
    }

    get calculationTypeComponentPath() {
      return 'multi-step-form/forms/administration/payables/line-item-details/line-item-calculation-options/' + Ember.String.dasherize(this.calculationType);
    }

    get noValidateArray() {
      if (this.calculationType !== 'excelFunction') {
        return ['payrollColumnCalculation-excelFunction'];
      }

      return [];
    }

    get duplicateLineItemButtons() {
      return [{
        text: this.intl.t('pages.administration.human_resources.duplicate_line_item'),
        click: buttonPromise => {
          return this.duplicateLineItem.perform(this.args.multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary line-item-save-button'
      }];
    }

    cancel() {
      var multiStepForm = this.args.multiStepForm;
      this.args.multiStepForm.setupCopyModels.perform();
      multiStepForm.validationErrors = {};
      this.router.transitionTo('administration.company-settings.system.payables.function-details.level-details', this.args.payrollRoleFunctionLevel.get('id'));
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "enhancedFeatures", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "calculationType", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "warningModalOpen", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "showDuplicateLineItemModal", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "duplicateName", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "duplicateDescription", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "initFormTask", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "getCalculationTypeOptionsTask", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "duplicateLineItem", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "editColumnTask", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "saveColumn", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "deleteColumn", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype)), _class));
  _exports.default = EditLineItemDetails;
});