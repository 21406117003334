define("securitytrax-ember-ui/mixed-in-functions/checkbox-list-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.checkAll = checkAll;
  _exports.uncheckAll = uncheckAll;

  function checkAll(list) {
    var itemList = Ember.A([]);
    list.forEach(item => {
      itemList.push(item);
      Ember.set(item, 'isChecked', true);
    });
    return itemList;
  }

  function uncheckAll(list) {
    list.forEach(item => {
      Ember.set(item, 'isChecked', false);
    });
    return list;
  }
});