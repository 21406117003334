define("securitytrax-ember-ui/components/customer/information/customer-installation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gWWciCLU",
    "block": "{\"symbols\":[\"data\",\"multiStepForm\",\"MultiStepFormComponent\"],\"statements\":[[6,[37,13],[[30,[36,12],[\"customer_system_information.view\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"html/st-card\",[[24,0,\"customer-installation\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"html/st-await\",[],[[\"@useSpinner\",\"@promises\"],[true,[30,[36,7],null,[[\"customer\"],[[35,0]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"multi-step-form\",[],[[\"@formSteps\",\"@formOriginalModels\",\"@formStarted\"],[[34,8],[34,9],true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,11],[[30,[36,10],[[32,2,[\"currentStepData\",\"stepComponentPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                    \"],[8,[32,3],[[24,0,\"customer-installation-form\"]],[[\"@multiStepForm\",\"@customer\",\"@formMode\",\"@calendarEvents\",\"@title\",\"@isCheckedOut\",\"@setFormMode\",\"@showEditButton\"],[[32,2],[34,0],[34,1],[34,2],[30,[36,3],[\"pages.customers.information.customer_system_information.title\"],null],[34,4],[30,[36,5],[[32,0],\"setFormMode\"],null],[34,6]]],null],[2,\"\\n\"]],\"parameters\":[3]}]]],[2,\"            \"]],\"parameters\":[2]}]]],[2,\"\\n        \"]],\"parameters\":[1]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"customer\",\"formMode\",\"installCalendarEvents\",\"t\",\"isCheckedOut\",\"action\",\"showEditButton\",\"hash\",\"customerSystemInformationStep\",\"customerSystemInformationFormOriginalModel\",\"component\",\"let\",\"has-grants\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/customer/information/customer-installation/template.hbs"
    }
  });

  _exports.default = _default;
});