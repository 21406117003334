define("securitytrax-ember-ui/components/pages/accounting/payments/financing/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Financing extends _component.default {}

  _exports.default = Financing;
});