define("securitytrax-ember-ui/validations/payroll-role-function", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateObject = {
        payrollRole: {
          presence: this.customMessageOrDefault(data, 'payrollRole')
        },
        payrollListMethod: {
          presence: {
            message() {
              var clone = 'extraValidationData' in data && data.extraValidationData.clone;
              return '^' + data.intlService.t('common.validations.presence', {
                propertyLabel: data.intlService.t(clone ? "pages.administration.human_resources.existing_function" : "pages.administration.human_resources.payee")
              });
            }

          }
        },
        name: {
          presence: {
            allowEmpty: false
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject, {
        fullMessages: this.prependProperty(data)
      });
      var modelName = data.extraValidationData && data.extraValidationData.hasOwnProperty('overrideValidationKey') ? data.extraValidationData.overrideValidationKey : 'payrollRoleFunction';
      return this.outputFormatting(validationData, modelName);
    }

  });

  _exports.default = _default;
});