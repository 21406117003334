define("securitytrax-ember-ui/models/group", ["exports", "securitytrax-ember-ui/models/base_models/group"], function (_exports, _group) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _group.default.extend({
    commaSeparatedRoles: Ember.computed('roles.{[],@each}', function () {
      return this.roles.toArray().map(role => {
        return role.get('name');
      }).join(', ');
    })
  });

  _exports.default = _default;
});