define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/monitoring-companies/integrations/cms-integration/component", ["exports", "ember-concurrency", "lodash", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "securitytrax-ember-ui/mixed-in-classes/monitoring-company-connection", "securitytrax-ember-ui/mixed-in-classes/row-list", "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/monitoring-companies/integrations/integrations-base"], function (_exports, _emberConcurrency, _lodash, _switchableFormStep, _monitoringCompanyConnection, _rowList, _integrationsBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CmsIntegration = (_dec = Ember.inject.service('centralStationService'), _dec2 = Ember.inject.service('intl'), _dec3 = Ember.inject.service('stNotificationManager'), _dec4 = Ember.inject.service('store'), _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = (0, _emberConcurrency.task)(function* () {
    try {
      this.formReady = false;
      yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
      var config = this.args.multiStepForm.formCopiedModelsGetVal('monitoringCompany.model.monitoringCompanyConfig');
      this.buttons(this.args.multiStepForm);

      if (config && config.get('enabled') === true) {
        var monitoringCompany = this.args.multiStepForm.formOriginalModelsGetVal('monitoringCompany.model');
        var centralStationService = this.centralStationService;
        var defaultData = yield (0, _emberConcurrency.hash)({
          defaultEventIds: centralStationService.getData({
            model: monitoringCompany,
            method: 'cms__getDefaultEventIds',
            responseKey: 'default_event_ids'
          }),
          defaultSystemTypes: centralStationService.getData({
            model: monitoringCompany,
            method: 'cms__getDefaultSystemTypes',
            responseKey: 'default_system_types'
          })
        });
        this.defaultEventIds = defaultData.defaultEventIds; //see if there are existing zone event overrides

        var clonedEventIds = _lodash.default.cloneDeep(defaultData.defaultEventIds);

        if (config.get('customEventIdMappings')) {
          clonedEventIds.forEach(eventId => {
            if (config.get('customEventIdMappings')[eventId.id]) {
              eventId['event_id'] = config.get('customEventIdMappings')[eventId.id]['event_id'];
            }
          });
        }

        this.eventIds = clonedEventIds; //see if there are existing system types saved

        var newSystemTypes = defaultData.defaultSystemTypes;

        if (config.get('customSystemTypes')) {
          var existingSystemTypes = [];

          for (var prop in config.get('customSystemTypes')) {
            if (config.get('customSystemTypes').hasOwnProperty(prop)) {
              existingSystemTypes.push({
                id: prop,
                name: config.get('customSystemTypes')[prop]
              });
            }
          }

          newSystemTypes = existingSystemTypes;
        }

        this.RowList.listArray = newSystemTypes;
        this.RowList.addRow();
      }

      this.formReady = true;
    } catch (error) {
      this.formReady = true;
      (false && Ember.warn('cms integration setup error' + error, {
        id: 'admin.integrations.monitoringCompanies.cms.initFormData'
      }));
      throw error && error.errors ? error.errors[0].detail : error;
    }
  }), _dec9 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    try {
      this.formReady = false;
      var multiStepForm = this.args.multiStepForm;
      var overrideConfig = Ember.Object.create({
        monitoringCompany: {
          model: this.monitoringCompany,
          copyRelationMap: {
            monitoringCompanyType: {
              readOnly: true
            },
            monitoringCompanyConfig: {}
          }
        }
      });
      var config = multiStepForm.formCopiedModelsGetVal('monitoringCompany.model.monitoringCompanyConfig'); //merge zone event ids  and system types with expected format for config

      var defaultEventIds = this.defaultEventIds;
      var eventIds = this.eventIds;

      var overrideEventIds = _lodash.default.differenceWith(eventIds, defaultEventIds, _lodash.default.isEqual);

      var systemTypes = this.RowList.listArray; //system types

      var newSystemTypes = {};
      systemTypes.forEach(systemType => {
        if (systemType.id) {
          newSystemTypes[systemType.id] = systemType.name;
        }
      });
      Ember.set(config, 'customSystemTypes', newSystemTypes); //event ids

      if (overrideEventIds && overrideEventIds.length > 0) {
        var newEventMappings = {};
        overrideEventIds.forEach(event => {
          newEventMappings[event.id] = {
            'event_id': event.event_id
          };
        });
        Ember.set(config, 'customEventIdMappings', newEventMappings);
      }

      multiStepForm.formCopiedModelsSetVal('monitoringCompany.model.monitoringCompanyConfig', config);
      yield multiStepForm.validateSyncSaveAll({
        overrideOriginalModelsConfig: overrideConfig,
        notificationNamespace: 'modal',
        successNotificationNamespace: 'modal',
        extraValidationData: {
          noValidate: ['monitoringCompanyConfig-username', 'monitoringCompanyConfig-password', 'monitoringCompanyConfig-newPassword', 'monitoringCompanyConfig-confirmNewPassword', 'monitoringCompanyConfig-keyCode', 'monitoringCompanyConfig-usernameId', 'monitoringCompanyConfig-dealerNumber', 'monitoringCompanyConfig-partnerId']
        }
      });
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
        this.initFormData.perform();
      });
    } catch (error) {
      this.formReady = true;
      (false && Ember.warn('cms save monitoring company config error' + error, {
        id: 'admin.integrations.monitoringCompanies.cms.save'
      }));
      throw error && error.errors ? error.errors[0].detail : error;
    }
  }), _dec10 = Ember._action, (_class = (_temp = class CmsIntegration extends _integrationsBase.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "centralStationService", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "formReady", _descriptor5, this);

      _initializerDefineProperty(this, "defaultEventIds", _descriptor6, this);

      _initializerDefineProperty(this, "eventIds", _descriptor7, this);

      _initializerDefineProperty(this, "initFormData", _descriptor8, this);

      _initializerDefineProperty(this, "saveMonitoringCompanyConfig", _descriptor9, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.MonitoringCompanyConnection = new _monitoringCompanyConnection.default(...arguments);
      this.RowList = new _rowList.default(...arguments);
      this.initFormData.perform();
      Ember.run.later(() => {
        this.buttons(this.args.multiStepForm);
      });
    }

    updateSystemTypes(systemType, index) {
      this.RowList.updateRow(systemType, index, false);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "formReady", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "defaultEventIds", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "eventIds", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "initFormData", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "saveMonitoringCompanyConfig", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateSystemTypes", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "updateSystemTypes"), _class.prototype)), _class));
  _exports.default = CmsIntegration;
});