define("securitytrax-ember-ui/components/pages/app-login-callback/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sm4F44x/",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"login-outer row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"login-inner col col-6 col-md-9 col-offset-3 col-sm-12\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"callback-text\"],[12],[1,[30,[36,0],[\"pages.login.redirect\"],null]],[13],[2,\"\\n        \"],[8,\"spinner-svg\",[],[[\"@spinning\",\"@size\"],[true,\"large\"]],null],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/app-login-callback/template.hbs"
    }
  });

  _exports.default = _default;
});