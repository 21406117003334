define("securitytrax-ember-ui/models/activation-fee", ["exports", "securitytrax-ember-ui/models/base_models/activation-fee"], function (_exports, _activationFee) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * ActivationFee record model
   *
   * @class ActivationFee
   * @extends model.BaseActivationFee
   * @memberof models
   */
  var _default = _activationFee.default.extend(
  /** @lends models.BaseActivationFee# */
  {
    amountDisplay: Ember.computed('amount', function () {
      return ('$' + Number(this.amount || 0.00).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')).replace('$-', '-$');
    }),
    amountNumeric: Ember.computed('amount', function () {
      return Number(this.amount);
    })
  });

  _exports.default = _default;
});