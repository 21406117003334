define("securitytrax-ember-ui/helpers/remove-spaces", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.removeSpaces = removeSpaces;
  _exports.default = void 0;

  function removeSpaces(stringWithSpaces) {
    var newString = stringWithSpaces;

    if (Array.isArray(stringWithSpaces)) {
      newString = stringWithSpaces.toString();
    }

    var noStrings = newString.replace(/ /g, '');
    return new Ember.String.htmlSafe(noStrings);
  }

  var _default = Ember.Helper.helper(removeSpaces);

  _exports.default = _default;
});