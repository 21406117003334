define("securitytrax-ember-ui/components/multi-step-form/forms/notes/base-notes", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "securitytrax-ember-ui/mixins/set-user", "moment", "securitytrax-ember-ui/utils/obj-helpers", "ember-concurrency", "lodash"], function (_exports, _switchableFormStep, _setUser, _moment, _objHelpers, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, _setUser.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    grants: Ember.inject.service(),
    homeDashboard: Ember.inject.service('events/home-dashboard'),
    confirmDeleteModalOpen: false,
    contactAttempted: 'no',
    communicationReason: null,
    communicationDisposition: null,
    customerCommunication: null,
    isCallback: false,
    contactAttemptedTypes: undefined,
    initFormData: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true);
      (0, _objHelpers.initializeState)(this, {
        contactAttemptedTypes: [{
          key: 'no',
          customerCommunicationType: null
        }, {
          key: 'inbound-call',
          customerCommunicationType: 'phone',
          direction: 'i'
        }, {
          key: 'outbound-call',
          customerCommunicationType: 'phone',
          direction: 'o'
        }, {
          key: 'inbound-written',
          customerCommunicationType: 'written',
          direction: 'i'
        }, {
          key: 'outbound-written',
          customerCommunicationType: 'written',
          direction: 'o'
        }]
      }); /////////////////////////////////////////////////////////////////////////////
      // get the latest customerCommunications for the note to prepopulate
      // contactAttempted, communicationReason, communicationDisposition on edit

      if (!this.isNewRecord && this.modelName === 'customerNote' && (yield this.grants.get('getIndividualGrantAsync').perform('customer_communications.view'))) {
        var lastCustomerCommunication = yield this.multiStepForm.formOriginalModelsGetVal("".concat(this.modelName, ".model.customerCommunications.lastObject"));

        if (lastCustomerCommunication) {
          var direction = _lodash.default.get(lastCustomerCommunication, 'direction', false);

          var customerCommunicationType = _lodash.default.get(lastCustomerCommunication, 'customerCommunicationType', false);

          if (direction && customerCommunicationType) {
            var contactAttemptedKey = '';

            if (direction === 'i') {
              contactAttemptedKey = 'inbound';
            } else {
              contactAttemptedKey = 'outbound';
            }

            if (customerCommunicationType === 'phone') {
              contactAttemptedKey += '-call';
            } else {
              contactAttemptedKey += '-written';
            }

            this.set('contactAttempted', contactAttemptedKey);
          }

          var communicationHashData = yield (0, _emberConcurrency.hash)({
            communicationReason: lastCustomerCommunication.communicationReason,
            communicationDisposition: lastCustomerCommunication.communicationDisposition,
            isCallback: lastCustomerCommunication.isCallback
          });

          if (communicationHashData.communicationReason) {
            this.set('communicationReason', communicationHashData.communicationReason);
          }

          if (communicationHashData.communicationDisposition) {
            this.set('communicationDisposition', communicationHashData.communicationDisposition);
          }

          if (communicationHashData.isCallback) {
            this.set('isCallback', communicationHashData.isCallback);
          }
        }
      }

      if (!this.isNewRecord) {
        var createdAt = this.multiStepForm.formOriginalModelsGetVal("".concat(this.modelName, ".model.createdAt"));
        var createdByUser = this.multiStepForm.formOriginalModelsGetVal("".concat(this.modelName, ".model.createdByUser")).get('flname');
        var langKey = this.modelName == 'customerNote' ? 'pages.customers.notes.footerText' : 'pages.customers.tasks.footerText';
        this.multiStepForm.registerStepData("".concat(this.modelName, "SingleStep"), 'footerText', this.intl.t(langKey, {
          name: createdByUser,
          date: (0, _moment.default)(createdAt).format('M/D/YYYY'),
          time: (0, _moment.default)(createdAt).format('h:mm a')
        }));
      }
    }).on('init'),

    didInsertElement() {
      this._super(...arguments);

      this.multiStepForm.registerStepData("".concat(this.modelName, "SingleStep"), 'buttons', this.stepButtons);
    },

    stepButtons: Ember.computed(function () {
      var buttonChoices = [];

      if (this.formMode !== 'view') {
        buttonChoices.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveNoteOrTaskTask.perform(this.multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary save modal-action-submit'
        });
      }

      if (!this.isNewRecord && (this.modelName === 'customerNote' && this.grants.getIndividualGrant('customer_notes.delete') || this.modelName === 'customerTask' && this.grants.getIndividualGrant('customer_tasks.delete'))) {
        buttonChoices.push({
          text: this.intl.t('common.actions.delete'),
          classNamesString: 'st-btn st-btn warning-red delete',
          isDelete: true,
          click: () => {
            this.set('confirmDeleteModalOpen', true);
          }
        });
      }

      return buttonChoices;
    }),
    saveNoteOrTaskTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var extraValidationData = {};
      var extraValidations = ['contactAttempted', 'communicationReason', 'communicationDisposition'];
      extraValidations.forEach(toValidate => {
        if (this.get(toValidate)) {
          extraValidationData[toValidate] = this.get(toValidate);
        }
      });
      extraValidationData['noValidate'] = ['customerTask-workOrderType'];

      if (this.isNewRecord) {
        var toCreateObjects = yield multiStepForm.validateCreateAll({
          extraValidationData: extraValidationData
        });
        toCreateObjects[this.modelName]['data']['customer'] = this.customer;
        var theModel = this.store.createRecord(this.modelName, toCreateObjects[this.modelName].data);
        var newModel = yield toCreateObjects[this.modelName].saveWithRollbackAndNotification(theModel);
        this.saveNewCommunicationTask.perform(newModel);
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        buttonPromise.then(() => {
          multiStepForm.formStartedSetVal(false);

          if (this.transitionBackToParent && typeof this.transitionBackToParent === 'function') {
            this.transitionBackToParent();
          }
        });
        return true;
      } else {
        var validatePromise = yield multiStepForm.validateSyncSaveAll({
          deepValidate: true,
          deepSync: true,
          deepSave: true,
          extraValidationData: extraValidationData
        });
        yield this.saveNewCommunicationTask.perform(this.get("multiStepForm.formOriginalModels.".concat(this.modelName, ".model")));
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        buttonPromise.then(() => {
          multiStepForm.formStartedSetVal(false);
          this.homeDashboard.trigger('refresh-tasks-due-today');

          if (this.transitionBackToParent && typeof this.transitionBackToParent === 'function') {
            this.transitionBackToParent();
          }
        });
        return validatePromise;
      }
    }),
    saveNewCommunicationTask: (0, _emberConcurrency.task)(function* (model) {
      if (this.contactAttempted && this.contactAttempted !== 'no') {
        var {
          customerCommunicationType,
          direction
        } = this.contactAttemptedTypes.findBy('key', this.contactAttempted);
        var communicationDetails = {
          customer: this.customer,
          customerCommunicationType: customerCommunicationType,
          direction: direction,
          communicationReason: this.communicationReason,
          communicationDisposition: this.communicationDisposition,
          isCallback: this.isCallback
        };
        communicationDetails[this.modelName] = model;
        var communication = this.store.createRecord('customerCommunication', communicationDetails);
        yield communication.save();
        this.set('customerCommunication', communication);
        this.get("multiStepForm.formCopiedModels.".concat(this.modelName, ".model.customerCommunications")).addObject(communication);
      } else if (this.contactAttempted && this.contactAttempted === 'no') {
        var communications = yield this.multiStepForm.formOriginalModelsGetVal("".concat(this.modelName, ".model.customerCommunications"));
        var toDelete = [];

        if (communications) {
          communications.forEach(communication => {
            toDelete.push(communication.destroyRecordWithRollbackTask.perform());
          });
          yield (0, _emberConcurrency.all)(toDelete);
        }
      }

      return true;
    }),
    deleteNoteTask: (0, _emberConcurrency.task)(function* () {
      try {
        var deleteItem = this.multiStepForm.formOriginalModelsGetVal("".concat(this.modelName, ".model"));
        yield deleteItem.destroyRecordWithRollbackTask.perform();
        this.notifyPropertyChange("customer.".concat(this.modelName, "s"));
        this.multiStepForm.formStartedSetVal(false);
        this.homeDashboard.trigger('refresh-tasks-due-today');

        if (this.transitionBackToParent && typeof this.transitionBackToParent === 'function') {
          this.transitionBackToParent();
        }

        this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
      } catch (err) {
        (false && Ember.warn('deleteNoteTask failed: ' + err));
      }
    }),

    /**
     * Build options as expected by {{multi-step-form.switchable-inputs.select-input}} component
     *
     * @return {Object[]}
     */
    contactAttemptedOptions: Ember.computed('contactAttemptedTypes', function () {
      var baseKey = 'pages.customers.notes.contactAttempted.options';
      return this.contactAttemptedTypes.map(({
        key
      }) => {
        return {
          value: key,
          name: this.intl.t("".concat(baseKey, ".").concat(key))
        };
      });
    }),
    showCommunicationOptions: Ember.computed('contactAttempted', function () {
      var contactAttempted = this.contactAttempted;

      if (!contactAttempted || contactAttempted === '0' || contactAttempted === 'no') {
        return false;
      } else {
        return true;
      }
    }),

    /**
     * Return the option key corresponding to a communication model
     *
     * @param {model.CustomerCommunication} communication The communication on the note model
     * @returns {String} A key matching the communication record.
     */
    getCommunicationOption(communication) {
      var options = this.contactAttemptedTypes;
      var selectedOption = !communication ? options.findBy('customerCommunicationType', null) : options.find(option => {
        return option.customerCommunicationType === communication.get('customerCommunicationType') && option.direction === communication.get('direction');
      });
      return selectedOption.key;
    }

  });

  _exports.default = _default;
});