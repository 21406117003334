define("securitytrax-ember-ui/models/employee-directory", ["exports", "securitytrax-ember-ui/models/base_models/employee-directory"], function (_exports, _employeeDirectory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _employeeDirectory.default.extend({});

  _exports.default = _default;
});