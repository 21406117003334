define("securitytrax-ember-ui/components/reports/report-filter/component", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import lodash from 'lodash';
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    screen: Ember.inject.service(),
    showPasswordModal: false,
    appliedFilters: Ember.computed('reportFilters.attributes.applied', function () {
      return this.reportFilters.filter(reportFilter => {
        return reportFilter.attributes.applied;
      }).length;
    }),
    showReportFiltersTask: (0, _emberConcurrency.task)(function* () {
      yield this.refreshReport.perform();
      this.reportFilters.forEach(filter => {
        if (filter.attributes.main_input_data_type === 'select' && filter.attributes.second_input_data_type === 'date' && filter.attributes.main_input_required) {
          Ember.set(filter.attributes, 'main_input', filter.attributes.main_input_list_options.sortBy('option')[0].value);
        }
      });
    }).on('init'),
    actions: {
      runAndExport(report, background) {
        return this.exportTask.perform(report, this.reportFilters, null, background);
      },

      runReport(report) {
        return this.showReportAction(report, this.reportFilters);
      },

      clearFilters() {
        this.set('showReport', false);
        this.reportFilters.forEach(filter => {
          Ember.set(filter.attributes, 'main_input', filter.attributes.main_input_data_type === 'select' && filter.attributes.second_input_data_type === 'date' && filter.attributes.main_input_required ? filter.attributes.main_input_list_options.sortBy('option')[0].value : null);
          Ember.set(filter.attributes, 'second_input', null);
          Ember.set(filter.attributes, 'third_input', null);
          Ember.set(filter.attributes, 'applied', false);
          Ember.set(filter.attributes, 'datetimeFirst', null);
          Ember.set(filter.attributes, 'datetimeTo', null);
        });
        var originalFilters = this.reportFilters.filter(filter => {
          return !filter.attributes.is_duplicate;
        });
        this.set('reportFilters', originalFilters);
      },

      addDatetimeFromTo(datetimeFrom, datetimeTo, filter) {
        filter.attributes.main_input = datetimeFrom ? (0, _moment.default)(datetimeFrom).format('MM/DD/YYYY hh:mm a') : null;
        filter.attributes.second_input = datetimeTo ? (0, _moment.default)(datetimeTo).format('MM/DD/YYYY hh:mm a') : null;
        this.send('updateFilter', filter);
      },

      addDatetime(datetime, filter) {
        filter.attributes.main_input = (0, _moment.default)(datetime).format('MM/DD/YYYY hh:mm a');
        this.send('updateFilter', filter);
      },

      updateFilter(filter) {
        if (!filter.attributes.applied) {
          filter.attributes['applied'] = true;
          this.notifyPropertyChange('appliedFilters');
        } else if (!filter.attributes.second_input && !filter.attributes.third_input && (!filter.attributes.main_input || Array.isArray(filter.attributes.main_input) && filter.attributes.main_input.length === 0)) {
          filter.attributes['applied'] = false;
          this.notifyPropertyChange('appliedFilters');
        }
      },

      removeFilter(filter) {
        if (filter.attributes.applied) {
          filter.attributes['applied'] = false;
          this.notifyPropertyChange('appliedFilters');
        }
      },

      duplicateFilter(filter) {
        var attributes = filter.attributes;
        var reportFilters = this.reportFilters;

        if (!attributes.was_duplicated && !this.get('report.responseMeta.editable')) {
          var duplicate = {
            id: filter.id,
            type: 'report_filters',
            attributes: {
              is_duplicate: true,
              main_input: null,
              main_input_data_format: attributes.main_input_data_format,
              main_input_data_type: attributes.main_input_data_type,
              main_input_label: attributes.main_input_label,
              main_input_list_options: attributes.main_input_list_options,
              main_input_name: attributes.main_input_name,
              main_input_required: attributes.main_input_required,
              name: attributes.name,
              second_input: null,
              second_input_data_format: attributes.second_input_data_format,
              second_input_data_type: attributes.second_input_data_type,
              second_input_label: attributes.second_input_label,
              second_input_list_options: attributes.second_input_list_options,
              second_input_name: attributes.second_input_name,
              second_input_required: attributes.second_input_required,
              third_input: null,
              third_input_data_format: attributes.third_input_data_format,
              third_input_data_type: attributes.third_input_data_type,
              third_input_label: attributes.third_input_label,
              third_input_list_options: attributes.third_input_list_options,
              third_input_name: attributes.third_input_name,
              third_input_required: attributes.second_input_required
            }
          };
          attributes['was_duplicated'] = true;
          reportFilters.push(duplicate);
        }

        attributes['applied'] = true;
        Ember.set(this, 'reportFilters', reportFilters);
        this.notifyPropertyChange('reportFilters');
        this.notifyPropertyChange('appliedFilters');
      }

    }
  });

  _exports.default = _default;
});