define("securitytrax-ember-ui/components/multi-step-form/forms/user-actions/delete-user/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JUnlSTtJ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"cell small-12\"],[12],[2,\"\\n    \"],[1,[30,[36,2],[[35,1,[\"user\",\"flname\"]],[30,[36,0],[\"features.org-chart.modals.delete_sub_title\"],null]],null]],[2,\"    \\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"modalParentUser\",\"concat\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/user-actions/delete-user/template.hbs"
    }
  });

  _exports.default = _default;
});