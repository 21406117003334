define("securitytrax-ember-ui/mixins/tech-today", ["exports", "ember-concurrency", "lodash"], function (_exports, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global L */
  var _default = Ember.Mixin.create({
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    calendarEventTypesSummary: Ember.computed(function () {
      return this.calendarEventTypesSummaryTask.perform();
    }),
    calendarEventTypesSummaryTask: (0, _emberConcurrency.task)(function* () {
      var calendarEventsSorted = yield this.calendarEventsSorted;
      var summary = {
        install: 0,
        service: 0,
        removal: 0
      };

      if (calendarEventsSorted.length > 0) {
        calendarEventsSorted.forEach(event => {
          switch (event.get('calendarEventType.id')) {
            case '{"calendar_category":"tech_schedule","id":"1"}':
              //Install
              summary.install++;
              break;

            case '{"calendar_category":"tech_schedule","id":"2"}':
              //Service
              summary.service++;
              break;

            case '{"calendar_category":"tech_schedule","id":"3"}':
              //Removal
              summary.removal++;
              break;

            default:
              '';
          }
        });
      }

      return summary;
    }),
    calendarEventsSorted: Ember.computed('model.calendarEventFilter', function () {
      return this.calendarEventsSortedTask.perform();
    }),
    calendarEventsSortedTask: (0, _emberConcurrency.task)(function* () {
      try {
        var calendarEventFilter = this.get('model.calendarEventFilter');
        var calendarEvents = yield this.store.query('calendar-event', {
          filter: calendarEventFilter,
          include: 'customer,work_order.customer_equipment.equipment,work_order.work_order_equipment.customer_equipment,work_order.work_order_equipment.work_order_action,calendar_event_type',
          sort: 'start_time'
        });
        var eventsWithoutTimeoff = [];

        for (var index = 0; index < calendarEvents.get('length'); index++) {
          var currCalendarEvent = calendarEvents.objectAt(index);
          var hashData = yield (0, _emberConcurrency.hash)({
            type: currCalendarEvent.get('calendarEventType'),
            appointmentStatusData: currCalendarEvent.get('appointmentStatusData')
          });
          var grayClass = yield this.grayClassTask.perform(hashData.appointmentStatusData);
          currCalendarEvent.set('grayClass', grayClass);
          eventsWithoutTimeoff.pushObject(currCalendarEvent);
        }

        return eventsWithoutTimeoff;
      } catch (error) {
        (false && Ember.warn('calendarEventsSortedTask ' + error, {
          id: 'techToday.calendarEventsSortedTask'
        }));
      }
    }),
    items: Ember.computed('calendarEventsSorted', function () {
      return this.equipmentItemsTask.perform();
    }),
    equipmentItemsTask: (0, _emberConcurrency.task)(function* () {
      try {
        var equipmentItems = [];
        var sortedCalendarEvents = yield this.calendarEventsSorted;

        for (var index = 0; index < sortedCalendarEvents.length; index++) {
          var currCalendarEvent = sortedCalendarEvents[index];
          var workOrder = this.grants.getIndividualGrant('work_orders.view') ? yield currCalendarEvent.get('workOrder') : null; ////////////////////////////////////////////////////////////////////////////
          // group workOrderEquipment by install action and equipment type

          if (workOrder) {
            var workOrderEquipment = yield workOrder.get('workOrderEquipment');
            var equipmentGroupedByAction = {};

            for (var l = 0; l < workOrderEquipment.get('length'); l++) {
              var currTaskEquipment = workOrderEquipment.objectAt(l);
              var workOrderAction = yield currTaskEquipment.get('workOrderAction');
              var workOrderActionId = workOrderAction.get('id');
              var currCustomerEquipment = currTaskEquipment.get('customerEquipment');

              if (!equipmentGroupedByAction[workOrderActionId]) {
                equipmentGroupedByAction[workOrderActionId] = {
                  items: [],
                  workOrderAction: workOrderAction,
                  checkAll: false,
                  indeterminate: false
                };
              }

              equipmentGroupedByAction[workOrderActionId]['items'].push(currCustomerEquipment);
            }

            var equipmentGroupedByActionKeys = Object.keys(equipmentGroupedByAction);

            for (var x = 0; x < equipmentGroupedByActionKeys.length; x++) {
              var currKey = equipmentGroupedByActionKeys[x];
              var element = equipmentGroupedByAction[currKey];
              var groupedItems = yield this.groupedItemsTask.perform(element.items);
              element.groupedItems = groupedItems;
            }

            currCalendarEvent.set('equipmentGroupedByAction', equipmentGroupedByAction);
            currCalendarEvent.set('equipmentGroupedByActionLength', equipmentGroupedByActionKeys.length); ///////////////////////////////////////////////////////////////////////////
            // combine all equipment items

            var customerEquipment = yield workOrder.get('customerEquipment');

            for (var q = 0; q < customerEquipment.get('length'); q++) {
              var _currCustomerEquipment = customerEquipment.objectAt(q);

              equipmentItems.push(_currCustomerEquipment);
            }
          }
        }

        return equipmentItems;
      } catch (error) {
        (false && Ember.warn('equipmentItemsTask ' + error, {
          id: 'techToday.equipmentItemsTask'
        }));
      }
    }),
    calendarEventSortedGeoPoints: Ember.computed('calendarEventsSorted', function () {
      return this.calendarEventSortedGeoPointsTask.perform();
    }),
    calendarEventSortedGeoPointsTask: (0, _emberConcurrency.task)(function* () {
      try {
        var geoPoints = [];
        var sortedCalendarEvents = yield this.calendarEventsSorted;

        for (var index = 0; index < sortedCalendarEvents.length; index++) {
          var element = sortedCalendarEvents[index];
          var customer = yield element.get('customer');

          if (customer && !customer.get('hasGeolocationDisabled')) {
            var geoPoint = yield customer.get('responseMeta.geopoint');
            geoPoints.push(L.point(geoPoint.latitude, geoPoint.longitude));
          }
        }

        return geoPoints;
      } catch (error) {
        (false && Ember.warn('calendarEventSortedGeoPointsDataTask ' + error, {
          id: 'techToday.calendarEventSortedGeoPointsDataTask'
        }));
      }
    }),
    calendarEventSortedGeoPointsData: Ember.computed('calendarEventsSorted', function () {
      return this.calendarEventSortedGeoPointsDataTask.perform();
    }),
    calendarEventSortedGeoPointsDataTask: (0, _emberConcurrency.task)(function* () {
      try {
        var geoPoints = [];
        var sortedCalendarEvents = yield this.calendarEventsSorted;

        for (var index = 0; index < sortedCalendarEvents.length; index++) {
          var element = sortedCalendarEvents[index];
          var customer = yield element.get('customer');

          if (customer && !customer.get('hasGeolocationDisabled')) {
            var geoPoint = yield customer.get('responseMeta.geopoint');
            geoPoints.push({
              geoPoint: L.point(geoPoint.latitude, geoPoint.longitude),
              calendarEvent: element,
              index
            });
          }
        }

        return geoPoints;
      } catch (error) {
        (false && Ember.warn('calendarEventSortedGeoPointsDataTask ' + error, {
          id: 'techToday.calendarEventSortedGeoPointsDataTask'
        }));
      }
    }),
    calendarEventSortedGeoPointBounds: Ember.computed('calendarEventSortedGeoPoints', function () {
      return this.calendarEventSortedGeoPointBoundsTask.perform();
    }),
    calendarEventSortedGeoPointBoundsTask: (0, _emberConcurrency.task)(function* () {
      try {
        var geoPoints = [];
        var calendarEventSortedGeoPoints = yield this.calendarEventSortedGeoPoints;

        if (!calendarEventSortedGeoPoints || calendarEventSortedGeoPoints.length <= 0) {
          geoPoints.push(L.point(0, 0));
        }

        var bounds = yield L.bounds(calendarEventSortedGeoPoints && calendarEventSortedGeoPoints.length > 0 ? calendarEventSortedGeoPoints : geoPoints);
        var bottomLeft = bounds.getBottomLeft();
        var topRight = bounds.getTopRight();
        var corner1 = L.latLng(bottomLeft.x, bottomLeft.y);
        var corner2 = L.latLng(topRight.x, topRight.y);
        var cornerBounds = L.latLngBounds(corner1, corner2);
        return cornerBounds;
      } catch (error) {
        (false && Ember.warn('calendarEventSortedGeoPointBoundsTask ' + error, {
          id: 'techToday.calendarEventSortedGeoPointBoundsTask'
        }));
      }
    }),
    calendarEventSortedGeoPointPolyline: Ember.computed('calendarEventSortedGeoPoints', function () {
      return this.calendarEventSortedGeoPointPolylineTask.perform();
    }),
    calendarEventSortedGeoPointPolylineTask: (0, _emberConcurrency.task)(function* () {
      try {
        var calendarEventSortedGeoPoints = yield this.calendarEventSortedGeoPoints;

        if (!calendarEventSortedGeoPoints || calendarEventSortedGeoPoints.length <= 0) {
          return false;
        }

        var polylineLocations = calendarEventSortedGeoPoints.map(point => ({
          lat: point.x,
          lng: point.y
        }));
        return polylineLocations;
      } catch (error) {
        (false && Ember.warn('calendarEventSortedGeoPointPolylineTask ' + error, {
          id: 'techToday.calendarEventSortedGeoPointPolylineTask'
        }));
      }
    }),
    grayClassTask: (0, _emberConcurrency.task)(function* (appointmentStatusData) {
      try {
        var resolvedAppointmentStatusData = yield appointmentStatusData;

        var checkedOut = _lodash.default.get(resolvedAppointmentStatusData, 'check_out', false);

        if (checkedOut && checkedOut > 0) {
          return 'checked-out-gray';
        }

        return '';
      } catch (error) {
        (false && Ember.warn('grayClassTask ' + error, {
          id: 'techToday.grayClassTask'
        }));
      }
    })
  });

  _exports.default = _default;
});