define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/electronic-document-providers/integrations/docusign/edit-envelope/documents/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _switchableFormStep, _emberConcurrency, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    store: Ember.inject.service(),
    docusignDocuments: null,
    documentsLoaded: false,
    docusignDocumentDeleteId: false,
    sortingMode: undefined,
    formStarted: false,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        sortingMode: false
      });
      window.addEventListener('touchmove', function () {
        return;
      }, {
        passive: false
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      window.removeEventListener('touchmove', function () {
        return;
      });
    },

    setupFormData: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true);
      var docusignDocuments = yield this.multiStepForm.formCopiedModelsGetVal('docusignEnvelope.model.docusignDocuments'); //dont include the certificate document or any documents that dont have an id in the list

      var filteredDocs = docusignDocuments ? docusignDocuments.filter(doc => {
        return doc.documentId;
      }) : [];
      this.set('docusignDocuments', filteredDocs.sortBy('order'));
      this.set('documentsLoaded', true);
    }).on('init'),
    createDocusignDocumentFormSteps: Ember.computed(function () {
      return Ember.Object.create({
        addDocusignDocument: Ember.Object.create({
          stepNameKey: 'addDocusignDocument',
          title: this.intl.t('pages.administration.integrations.electronic_document_providers.docusign.add_document') + this.get('customer.flname'),
          stepComponentPath: 'multi-step-form/forms/administration/integrations/electronic-document-providers/integrations/docusign/edit-envelope/documents/add-document'
        })
      });
    }),
    saveSortingChangesTask: (0, _emberConcurrency.task)(function* () {
      var docusignDocuments = this.multiStepForm.formOriginalModelsGetVal('docusignEnvelope.model.docusignDocuments');
      var savePromises = [];
      this.docusignDocuments.forEach((doc, index) => {
        var realDoc = docusignDocuments.findBy('documentId', doc.documentId);
        realDoc.set('order', index + 1);
        savePromises.push(realDoc.save());
      });

      try {
        yield (0, _emberConcurrency.all)(savePromises);
      } catch (error) {
        this.stNotificationManager.addErrorMessages(error, {
          notificationNamespace: 'modal'
        });
      }

      this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
      this.set('sortingMode', false);
    }),
    deleteDocumentTask: (0, _emberConcurrency.task)(function* (row) {
      var docusignDocuments = this.docusignDocuments;
      var documentToDelete = yield this.store.findRecord('docusignDocument', row.get('id'));
      yield documentToDelete.destroyRecordWithRollbackTask.perform();
      this.set('docusignDocumentDeleteId', false);
      this.set('documentsLoaded', false);
      this.set('docusignDocuments', yield docusignDocuments.filter(docs => {
        return docs.id != row.get('id');
      }));
      this.set('documentsLoaded', true);
    }),
    addedDocumentTask: (0, _emberConcurrency.task)(function* ()
    /*newDocument*/
    {
      this.set('documentsLoaded', false);
      var envelope = this.multiStepForm.formOriginalModelsGetVal('docusignEnvelope.model');
      yield envelope.hasMany('docusignDocuments').reload();
      yield this.multiStepForm.setupCopyModels.perform();
      this.setupFormData.perform();
    }),
    actions: {
      sortEndAction() {
        var docusignDocuments = this.docusignDocuments;
        docusignDocuments.forEach((item, index) => {
          item.set('order', index + 1);
        });
      },

      initSortMode() {
        var docusignDocuments = this.docusignDocuments.sortBy('order');
        docusignDocuments.forEach(item => {
          item.set('originalIndex', item.get('order'));
          item.set('expanded', false);
        });
        this.set('sortingMode', true);
      },

      cancelSort() {
        this.setupFormData.perform().then(() => {
          this.set('sortingMode', false);
        });
      },

      sortingComplete() {},

      saveSortingChanges(buttonAnimationPromise) {
        return this.saveSortingChangesTask.perform(buttonAnimationPromise);
      },

      refreshDocumentsSection()
      /*newDocument*/
      {
        this.addedDocumentTask.perform();
      }

    }
  });

  _exports.default = _default;
});