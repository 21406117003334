define("securitytrax-ember-ui/components/calendar-glimmer/calendar-event/dry-run-reasons/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/cn+BMJt",
    "block": "{\"symbols\":[\"@calendarEventNameSpace\",\"@dryRunReasons\"],\"statements\":[[2,\" \"],[8,\"html/st-await\",[],[[\"@loadedNamespace\",\"@useSpinner\",\"@booleanLoaded\"],[[32,1],true,[30,[36,0],[[32,0,[\"loadDryRunReason\",\"isIdle\"]],[32,0,[\"saveDryRunReason\",\"isIdle\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"multi-step-form/switchable-inputs/select-input\",[],[[\"@valueProperty\",\"@options\",\"@formMode\",\"@value\",\"@renderInPlaceOverride\",\"@class\",\"@onChange\"],[\"id\",[32,2],[32,0,[\"formMode\"]],[32,0,[\"dryRunReason\"]],false,\"select-input--full-width\",[30,[36,2],[[35,1]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"and\",\"saveDryRunReason\",\"perform\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/calendar-glimmer/calendar-event/dry-run-reasons/template.hbs"
    }
  });

  _exports.default = _default;
});