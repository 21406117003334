define("securitytrax-ember-ui/components/html/super-admin-console/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    stAjax: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    mappedResourceTypeGrants: Ember.computed(function () {
      return this.mappedResourceTypeGrantsTask.perform();
    }),
    mappedResourceTypeGrantsTask: (0, _emberConcurrency.task)(function* () {
      var resourceTypeGrants = yield this.store.query('resourceTypeGrant', {
        limit: 0,
        include: 'resource_type'
      });
      var mappedResourceTypeGrants = {};

      for (var index = 0; index < resourceTypeGrants.length; index++) {
        var currResourceTypeGrant = resourceTypeGrants.objectAt(index);
        var currResourceType = yield currResourceTypeGrant.resourceType;
        mappedResourceTypeGrants[currResourceType.id + '/' + currResourceTypeGrant.key] = {
          resourceTypeGrant: currResourceTypeGrant,
          resourceType: currResourceType
        };
      }

      return mappedResourceTypeGrants;
    }),
    loggedInUserGroup: Ember.computed(function () {
      return this.loggedInUserGroupTask.perform();
    }),
    loggedInUserGroupTask: (0, _emberConcurrency.task)(function* () {
      var loggedInUser = yield this.sessionData.getUser();
      var loggedInUserGroup = yield loggedInUser.group;
      return loggedInUserGroup;
    }),
    addRemoveResourceTypeGrantsTask: (0, _emberConcurrency.task)(function* (currGrantAccessedNamespace, currGrantAccessedKey, currGrantAccessedCount, doingAnAdd) {
      if (currGrantAccessedCount.changeInSession) {
        return;
      }

      Ember.set(this, "grants.grantsAccessedSinceLastClear.".concat(currGrantAccessedNamespace, ".counts.").concat(currGrantAccessedKey, ".currentlySpinning"), true);
      this.notifyPropertyChange('grants.grantsAccessedSinceLastClear');
      var loggedInUserGroup = yield this.loggedInUserGroup;
      var mappedResourceTypeGrants = yield this.mappedResourceTypeGrants;
      var allSelectedGrants = loggedInUserGroup.resourceTypeGrants.map(x => x.id);

      if (doingAnAdd) {
        allSelectedGrants.pushObject(mappedResourceTypeGrants[currGrantAccessedKey]['resourceTypeGrant'].id);
      } else {
        allSelectedGrants.removeObject(mappedResourceTypeGrants[currGrantAccessedKey]['resourceTypeGrant'].id);
      }

      yield loggedInUserGroup.updateRelationship('PATCH', 'resource_type_grants', allSelectedGrants, 'resource_type_grants');
      yield loggedInUserGroup.reload();
      Ember.set(this, "grants.grantsAccessedSinceLastClear.".concat(currGrantAccessedNamespace, ".counts.").concat(currGrantAccessedKey, ".currentlySpinning"), false);
      Ember.set(this, "grants.grantsAccessedSinceLastClear.".concat(currGrantAccessedNamespace, ".counts.").concat(currGrantAccessedKey, ".changeInSession"), true);
      this.notifyPropertyChange('grants.grantsAccessedSinceLastClear');
    })
  });

  _exports.default = _default;
});