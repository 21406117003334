define("securitytrax-ember-ui/components/multi-step-form/forms/work-orders/add-duplicate-appointment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "70x1J8I6",
    "block": "{\"symbols\":[\"@multiStepForm\",\"@customer\",\"@transitionBackToParent\",\"@users\",\"@workOrder\"],\"statements\":[[8,\"calendar-glimmer/calendar-scheduling\",[],[[\"@multiStepForm\",\"@customer\",\"@afterScheduleAppointment\",\"@popoverContainer\",\"@duplicateAppointment\",\"@transitionBackToParent\",\"@isReschedule\",\"@users\",\"@scrollContext\",\"@currFormName\",\"@workOrder\"],[[32,1],[32,2],[30,[36,0],[[32,0],\"afterScheduleAppointment\"],null],\".md-dialog-content\",[32,1,[\"formOriginalModels\",\"calendarEvent\",\"model\"]],[32,3],[32,1,[\"isReschedule\"]],[32,4],\".md-dialog-container .no-horizontal-scroll .st-modal__body-content\",[32,1,[\"currentStepNameKey\"]],[32,5]]],null],[2,\"      \\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/work-orders/add-duplicate-appointment/template.hbs"
    }
  });

  _exports.default = _default;
});