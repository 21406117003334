define("securitytrax-ember-ui/components/multi-step-form/forms/administration/permissions/add-groups/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fPTF5rSw",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"grid-x\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cell\"],[12],[2,\"\\n        \"],[1,[30,[36,6],null,[[\"formMode\",\"value\",\"label\",\"errors\",\"required\",\"validationKey\"],[[35,5],[35,0,[\"formCopiedModels\",\"group\",\"model\",\"name\"]],[30,[36,2],[\"common.columns.name\"],null],[35,0,[\"validationErrors\"]],true,\"group-name\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,8],[[30,[36,7],[[35,0,[\"currentStepNameKey\"]],\"duplicateGroup\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"cell\"],[12],[2,\"\\n            \"],[1,[30,[36,4],null,[[\"name\",\"formMode\",\"labelProperty\",\"options\",\"valueProperty\",\"disabled\",\"label\",\"allowClear\",\"value\",\"errors\",\"required\",\"validationKey\",\"renderInPlaceOverride\"],[\"existingGroup\",\"edit\",\"name\",[35,3],\"id\",false,[30,[36,2],[\"pages.administration.human_resources.existing_group\"],null],false,[35,1],[35,0,[\"validationErrors\"]],true,\"group-existingGroup\",false]]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"multiStepForm\",\"groupToClone\",\"t\",\"allGroups\",\"multi-step-form/switchable-inputs/select-input\",\"formMode\",\"multi-step-form/switchable-inputs/text-input\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/permissions/add-groups/template.hbs"
    }
  });

  _exports.default = _default;
});