define("securitytrax-ember-ui/components/html/file-upload/component", ["exports", "ember-cli-dropzonejs/components/drop-zone", "jquery"], function (_exports, _dropZone, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dropZone.default.extend({
    stAjax: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    intl: Ember.inject.service(),
    namespace: 'global',
    paramName: 'objectFile',
    queueCount: 0,
    afterFileUpload: () => {},
    additionalFields: () => {},

    init() {
      Ember.setProperties(this, {
        classNames: [],
        // this is needed because classNames cannot be overridden by extending component,
        totalUploadProgress: 0,
        showTotalProgress: false,
        showActionsToolbar: false,
        headers: {
          'Accept': 'multipart/form-data',
          'X-Requested-With': null,
          'Cache-Control': null,
          'Authorization': 'Bearer ' + this.stAjax.getAuthToken(),
          'X-SecurityTrax-IntegratorKey': this.stAjax.getIntegratorKey()
        }
      });

      this._super(...arguments);
    },

    createDropzone() {
      var self = this;
      var previewNode = (0, _jquery.default)('.template').get(0);
      previewNode.id = '';
      var previewTemplate = previewNode.parentNode.innerHTML;
      previewNode.parentNode.removeChild(previewNode); // setup additional defaults just for us

      if (this.dropzoneOptions.thumbnailWidth == null) {
        this.dropzoneOptions.thumbnailWidth = 80;
      }

      if (this.dropzoneOptions.thumbnailHeight == null) {
        this.dropzoneOptions.thumbnailHeight = 80;
      }

      if (typeof this.dropzoneOptions.parallelUploads === 'undefined') {
        this.dropzoneOptions.parallelUploads = 20;
      }

      if (typeof this.dropzoneOptions.previewTemplate === 'undefined') {
        this.dropzoneOptions.previewTemplate = previewTemplate;
      }

      if (typeof this.dropzoneOptions.autoQueue === 'undefined') {
        this.dropzoneOptions.autoQueue = false;
      }

      if (typeof this.dropzoneOptions.previewsContainer === 'undefined') {
        this.dropzoneOptions.previewsContainer = '#' + this.elementId + ' .previews';
      }

      var myDropzone = new Dropzone((0, _jquery.default)('.dropzone').get(0), this.dropzoneOptions);
      var onFileAdded = this.onFileAdded;
      var onFileRemoved = this.onFileRemoved;
      myDropzone.on('maxfilesexceeded', function (file) {
        this.removeAllFiles();
        self.set('queueCount', 0);
        this.addFile(file);
      });
      myDropzone.on('addedfile', function (file) {
        self.set('totalUploadProgress', 0);
        self.set('showActionsToolbar', true);
        self.set('showTotalProgress', false);
        self.incrementProperty('queueCount');

        if (typeof onFileAdded === 'function') {
          onFileAdded(file);
        } // Create the remove button


        var removeButton = Dropzone.createElement('<svg role="img" class="icon action-icon"><use href="/assets/icons.svg#icn-close"></use></svg>'); // Listen to the click event

        removeButton.addEventListener('click', e => {
          // Make sure the button click doesn't submit the form:
          e.preventDefault();
          e.stopPropagation(); // Remove the file preview.

          self.decrementProperty('queueCount');
          this.removeFile(file);

          if (typeof onFileRemoved === 'function') {
            onFileRemoved(file);
          }
        }); // Add the button to the file preview element.

        file.previewElement.querySelector('div.actions').appendChild(removeButton);
      }); // Update the total progress bar

      myDropzone.on('totaluploadprogress', function (progress) {
        self.set('totalUploadProgress', progress);
      });
      myDropzone.on('error', (file, error, xhr) => {
        // eslint-disable-line no-unused-vars
        // on failure from BE and xhr failure
        this.stNotificationManager.addErrorMessages(error, {
          notificationNamespace: self.namespace
        });
      });
      myDropzone.on('sending', function (file, xhr, formData) {
        // eslint-disable-line no-unused-vars
        self.get('additionalFields')(...arguments); // Show the total progress bar when upload starts

        self.set('showActionsToolbar', true);
        self.set('showTotalProgress', true);
      }); // change upload progress back to 0%

      myDropzone.on('queuecomplete', function (progress) {
        // eslint-disable-line no-unused-vars
        self.set('totalUploadProgress', 0);
        self.set('showTotalProgress', false);
        self.set('showActionsToolbar', false);
        myDropzone.removeAllFiles(true);
        self.set('queueCount', 0);
      });
      myDropzone.on('success', function () {
        self.get('afterFileUpload')(...arguments);
      });
      this.set('myDropzone', myDropzone);

      if (typeof this.onDropzoneCreated === 'function') {
        this.onDropzoneCreated(this);
      }
    },

    actions: {
      startUpload() {
        var myDropzone = this.myDropzone;

        if (this.uploadAction) {
          this.uploadAction(this);
        } else {
          myDropzone.enqueueFiles(myDropzone.getFilesWithStatus(Dropzone.ADDED));
        }
      },

      cancelUpload() {
        var myDropzone = this.myDropzone;
        this.set('totalUploadProgress', 0);
        this.set('showTotalProgress', false);
        this.set('showActionsToolbar', false);
        myDropzone.removeAllFiles(true);
        this.set('queueCount', 0);
      }

    }
  });

  _exports.default = _default;
});