define("securitytrax-ember-ui/models/order-templates-field-default", ["exports", "securitytrax-ember-ui/models/base_models/order-templates-field-default"], function (_exports, _orderTemplatesFieldDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _orderTemplatesFieldDefault.default.extend({});

  _exports.default = _default;
});