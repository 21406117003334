define("securitytrax-ember-ui/components/multi-step-form/forms/system/adt/steps/dealer-added-funding-blockers/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/system/adt/adt-form-base", "ember-concurrency"], function (_exports, _adtFormBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adtFormBase.default.extend({
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    centralStationService: Ember.inject.service(),
    nextBlockerAction: undefined,
    openCloseFundingBlocker: false,

    didInsertElement() {
      this._super(...arguments);

      this.multiStepForm.registerStepData('dealerAddedFundingBlockers', 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.manipulateFundingBlocker.perform(buttonPromise);
        },
        classNamesString: 'st-btn-primary'
      }]);
    },

    dealerAddedFundingBlockers: Ember.computed(function () {
      return this.getDealerAddedFundingBlockersTask.perform();
    }),
    getDealerAddedFundingBlockersTask: (0, _emberConcurrency.task)(function* () {
      try {
        var fundingBlockers = yield this.centralStationService.getData({
          model: yield this.customer,
          method: 'adt__getDealerAddedFundingBlockerData'
        });
        Ember.set(this, 'nextBlockerAction', fundingBlockers.dealer_added_funding_blocker_data.has_open_dealer_added_funding_blocker ? this.intl.t('common.actions.close').capitalize() : this.intl.t('common.status.open'));
        return fundingBlockers.dealer_added_funding_blocker_data.dealer_added_funding_blockers;
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'adtMegaFormModal'
        });
        return [];
      }
    }),
    manipulateFundingBlocker: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.centralStationService.getData({
          model: yield this.customer,
          method: 'adt__manipulateDealerAddedFundingBlocker',
          payload: {
            action: this.nextBlockerAction
          }
        });
        Ember.set(this, 'openCloseFundingBlocker', false);
        this.notifyPropertyChange('dealerAddedFundingBlockers');
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'adtMegaFormModal'
        });
        throw err;
      }
    })
  });

  _exports.default = _default;
});