define("securitytrax-ember-ui/helpers/hyphenate", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hyphenate = hyphenate;
  _exports.default = void 0;

  function hyphenate(input) {
    return _lodash.default.replace(input, / /g, '-').toLowerCase();
  }

  var _default = Ember.Helper.helper(hyphenate);

  _exports.default = _default;
});