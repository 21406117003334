define("securitytrax-ember-ui/components/multi-step-form/forms/system/cops/create-edit-account/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency", "securitytrax-ember-ui/utils/obj-helpers", "lodash", "moment", "securitytrax-ember-ui/mixins/customer-integrations-mixin"], function (_exports, _switchableFormStep, _emberConcurrency, _objHelpers, _lodash, _moment, _customerIntegrationsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, _customerIntegrationsMixin.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    centralStationService: Ember.inject.service(),
    accountNumber: undefined,
    licenseNumber: undefined,
    licenseExpiration: undefined,
    panelType: undefined,
    ulCert: undefined,
    dealerNotes: undefined,
    templateNumber: undefined,
    customerPasscode: undefined,
    addAccountAuthorityFormOpen: false,
    progressBarHidden: Ember.computed('isNewRecord', function () {
      return this.isNewRecord;
    }),
    disabledProgressBarSteps: Ember.computed(function () {
      return [];
    }),
    hiddenProgressBarSteps: Ember.computed(function () {
      return [];
    }),

    didInsertElement() {
      this._super(...arguments);

      this.registerButtons();
    },

    buttonObserver: Ember.observer('multiStepForm.formSteps', function () {
      this.registerButtons();
    }),
    licenseExpirationFormatted: Ember.computed('licenseExpiration', function () {
      if (this.licenseExpiration) {
        return (0, _moment.default)(this.licenseExpiration).format('MM/DD/YYYY').toString();
      } else {
        return null;
      }
    }),
    copsCustomerInformation: Ember.computed('formMode', function () {
      return this.getCopsCustomerInformation.perform();
    }),
    getCopsCustomerInformation: (0, _emberConcurrency.task)(function* () {
      if (this.formMode !== 'create') {
        try {
          var customerInfo = yield (0, _emberConcurrency.hash)({
            basicCustomerInfo: this.centralStationService.getData({
              model: this.customer,
              method: 'cops__getAccountBasicInformation'
            }),
            customerPasscode: this.centralStationService.getData({
              model: this.customer,
              method: 'cops__getAccountPasscodes'
            }),
            customerZones: this.centralStationService.getData({
              model: this.customer,
              method: 'cops__getAccountZoneUserInformation'
            })
          });
          return customerInfo;
        } catch (err) {
          this.stNotificationManager.addErrorMessages(err, 'modal');
        }
      } else {
        return null;
      }
    }),
    copsTemplates: Ember.computed(function () {
      return this.getCopsTemplates.perform();
    }),
    getCopsTemplates: (0, _emberConcurrency.task)(function* () {
      var templates = yield this.centralStationService.getData({
        model: this.monitoringCompany,
        method: 'cops__getDealerTemplates'
      });
      return templates.dealer_templates;
    }),
    customerAuthorities: Ember.computed(function () {
      if (this.formMode === 'create') {
        return this.tempCustomerAuthorities;
      } else {
        return this.getCustomerAuthorities.perform();
      }
    }),
    getCustomerAuthorities: (0, _emberConcurrency.task)(function* () {
      var authorities = yield this.centralStationService.getData({
        model: this.customer,
        method: 'cops__getAccountAuthorityInformation'
      });
      var compiledAuthorities = [];
      authorities.account_authority_information.forEach(type => {
        if (type.numbers[0]) {
          for (var i = 0; i < type.numbers.length; i++) {
            compiledAuthorities.push({
              type: type.authority_type,
              typeFormatted: _lodash.default.upperFirst(type.authority_type),
              number: type.numbers[i]
            });
          }
        }
      });
      return compiledAuthorities;
    }),
    customerZones: Ember.computed('copsCustomerInformation', function () {
      return this.getCustomerZonesTask.perform();
    }),
    getCustomerZonesTask: (0, _emberConcurrency.task)(function* () {
      var customerEquipment = this.multiStepForm.formCopiedModels.customer.model.customerEquipment;
      var customerZones = [];
      var customerZoneIds = [];
      var zonesObj = {};
      customerEquipment.forEach(equipmentPiece => {
        if (equipmentPiece.zoneNumber && equipmentPiece.equipmentStatus.name === 'Installed' && !customerZoneIds.includes(equipmentPiece.zoneNumber)) {
          customerZones.push({
            label: equipmentPiece.zoneNumber,
            description: equipmentPiece.zone
          });
          customerZoneIds.push(equipmentPiece.zoneNumber);
        }
      });

      if (this.formMode === 'create') {
        zonesObj.zonesInSecurityTrax = customerZones;
        zonesObj.zonesNotInSecurityTrax = [];
        return zonesObj;
      } else {
        var info = yield this.copsCustomerInformation;
        var zonesNotInSecurityTrax = [];
        var zonesInSecurityTrax = [];

        if (info && info.customerZones && info.customerZones.account_zone_user_information) {
          info.customerZones.account_zone_user_information.forEach(equipmentPiece => {
            if (!customerZoneIds.includes(equipmentPiece.label)) {
              zonesNotInSecurityTrax.push(equipmentPiece);
            } else {
              zonesInSecurityTrax.push(equipmentPiece);
            }
          });
        }

        zonesObj.zonesNotInSecurityTrax = zonesNotInSecurityTrax;
        zonesObj.zonesInSecurityTrax = zonesInSecurityTrax;
        return zonesObj;
      }
    }),
    initFormTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true);

      if (this.formMode === 'create') {
        (0, _objHelpers.initializeState)(this, {
          accountNumber: this.multiStepForm.formCopiedModels.customer.model.customerSystemInformation.accountNum,
          licenseNumber: null,
          licenseExpiration: null,
          panelType: null,
          ulCert: null,
          dealerNotes: null,
          templateNumber: null,
          customerPasscode: this.multiStepForm.formCopiedModels.customer.model.customerSystemInformation.customerAbortCode.abortCode,
          tempCustomerAuthorities: []
        });
      } else {
        var copsCustomerInformation = yield this.copsCustomerInformation;
        var basicInfo = copsCustomerInformation && copsCustomerInformation.basicCustomerInfo ? copsCustomerInformation.basicCustomerInfo.account_basic_information : null;
        (0, _objHelpers.initializeState)(this, {
          accountNumber: this.multiStepForm.formCopiedModels.customer.model.customerSystemInformation.accountNum,
          licenseNumber: basicInfo ? basicInfo.license_number : null,
          licenseExpiration: basicInfo ? basicInfo.license_expiration_date : null,
          panelType: basicInfo ? basicInfo.panel_type : null,
          ulCert: basicInfo ? basicInfo.ul_cert : null,
          dealerNotes: basicInfo ? basicInfo.for_dealer_use : null,
          templateNumber: basicInfo ? basicInfo.template_number : null,
          customerPasscode: copsCustomerInformation && copsCustomerInformation.customerPasscode && copsCustomerInformation.customerPasscode.account_passcodes ? copsCustomerInformation.customerPasscode.account_passcodes[0].passcode : null
        });
        this.multiStepForm.formCopiedModelsSetVal('customer.model.customerSystemInformation.customerAbortCode.abortCode', this.customerPasscode);
      }
    }).on('init'),
    saveCreateAccountTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var formattedObjects = Ember.A();
      formattedObjects.pushObject(Ember.Object.create({
        model: {
          accountNumber: multiStepForm.formCopiedModels.customer.model.customerSystemInformation.accountNum,
          passcode: multiStepForm.formCopiedModels.customer.model.customerSystemInformation.customerAbortCode.abortCode,
          templateNumber: this.templateNumber
        },
        filePath: 'central-station/cops-create'
      }));
      yield multiStepForm.validateList.perform(formattedObjects);
      yield multiStepForm.validateSyncSaveAll({
        extraValidationData: {
          noValidate: ['location']
        }
      });

      try {
        yield this.centralStationService.postData({
          model: this.customer,
          method: 'cops__saveAccount',
          payload: {
            account_basic_information: {
              'template_number': this.templateNumber,
              'license_number': this.licenseNumber,
              'license_expiration_date': this.licenseExpirationFormatted,
              'panel_type': this.panelType,
              'ul_cert': this.ulCert,
              'for_dealer_use': this.dealerNotes
            }
          }
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, 'modal');
        throw err;
      }

      try {
        if (this.formMode === 'create') {
          var promiseArray = [];
          this.tempCustomerAuthorities.forEach(auth => {
            promiseArray.push(this.centralStationService.postData({
              model: this.customer,
              method: 'cops__addAccountAuthorityInformation',
              payload: {
                type: auth.type,
                number: auth.number.number
              }
            }));
          });
          yield (0, _emberConcurrency.all)(promiseArray);
        }
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, 'modal');
      }

      buttonPromise.then(() => {
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
        this.set('formMode', 'edit');
        this.store.findRecord('customerSystemInformation', this.get('customer.id')).then(systemInfo => {
          systemInfo.reload();
        });
      });
    }),
    deleteAuthority: (0, _emberConcurrency.task)(function* (authority, buttonPromise) {
      try {
        if (this.formMode === 'create') {
          this.tempCustomerAuthorities = this.tempCustomerAuthorities.filter(auth => !(auth.type == authority.type && auth.number.number == authority.number.number));
        } else {
          yield this.centralStationService.postData({
            model: this.customer,
            method: 'cops__removeAccountAuthorityInformation',
            payload: {
              type: authority.type,
              number: authority.number.number
            }
          });
        }

        buttonPromise.then(() => {
          this.set('authorityDeleteId', null);
          this.notifyPropertyChange('customerAuthorities');
          this.stNotificationManager.addSuccessKey('common.notifications.delete_successful', 'modal');
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, 'modal');
        throw err;
      }
    }),

    registerButtons() {
      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('general', 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.saveCreateAccountTask.perform(multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary affiliated-create-account-button'
      }]);
    },

    actions: {
      refreshAuthorities() {
        this.notifyPropertyChange('customerAuthorities');
      }

    }
  });

  _exports.default = _default;
});