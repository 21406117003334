define("securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/adc/monitoring-settings/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/adc/adc-base", "ember-concurrency", "lodash"], function (_exports, _adcBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adcBase.default.extend({
    intl: Ember.inject.service(),
    ispService: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('monitoringSettings', 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.updateMonitoringSettings.perform(multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary monitoring-settings-save-button'
      }]);
    },

    forwardingOptionChoices: Ember.computed(function () {
      var intl = this.intl;
      return [Ember.Object.create({
        packageName: intl.t('pages.customers.system.interactiveServicesProvider.monitoringSettings.always'),
        packageId: 'Always'
      }), Ember.Object.create({
        packageName: intl.t('pages.customers.system.interactiveServicesProvider.monitoringSettings.onlyIfPhoneFails'),
        packageId: 'OnlyIfPhoneFails'
      }), Ember.Object.create({
        packageName: intl.t('pages.customers.system.interactiveServicesProvider.monitoringSettings.never'),
        packageId: 'Never'
      })];
    }),
    currentEventsToForward: Ember.computed('customerInformation.central_station_info.event_groups_to_forward.[]', function () {
      return this.currentEventToForwardTask.perform();
    }),
    currentEventToForwardTask: (0, _emberConcurrency.task)(function* () {
      var customerInformation = yield this.customerInformation;

      if (!customerInformation.central_station_info.event_groups_to_forward) {
        _lodash.default.set(this.customerInformation, 'central_station_info.event_groups_to_forward', []);
      }

      return customerInformation.central_station_info.event_groups_to_forward;
    }),
    eventsToForward: Ember.computed('customerInformation.central_station_info.event_groups_to_forward.[]', function () {
      return this.eventsToForwardTask.perform();
    }),
    eventsToForwardTask: (0, _emberConcurrency.task)(function* () {
      var customerInformation = yield this.customerInformation;
      var arr = [];
      var groups = customerInformation.central_station_info.event_groups_to_forward;

      if (groups) {
        groups.forEach(group => {
          arr[group] = true;
        });
      }

      return arr;
    }),
    updateMonitoringSettings: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var info = yield this.customerInformation;
      var currentEventsToForward = yield this.currentEventsToForward;
      var eventsToForward = {};
      currentEventsToForward.forEach(event => {
        eventsToForward[event] = true;
      });
      var attributes = {
        forwarding_option: info && info.central_station_info && info.central_station_info.forwarding_option ? info.central_station_info.forwarding_option : 'Never',
        phone_line_present: info && info.central_station_info && info.central_station_info.phone_line_present ? info.central_station_info.phone_line_present : false,
        event_groups_to_forward: eventsToForward
      };

      try {
        yield this.ispService.get('invokeIspActionTask').perform({
          attributes,
          model: this.customer,
          action: 'alarm_dot_com__updateCSInfo'
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
        throw err;
      }

      buttonPromise.then(() => {
        multiStepForm.currentStepNameKeySetVal('customerInformation');
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
      });
    }),
    updateEventGroupsToForwardTask: (0, _emberConcurrency.task)(function* (eventToForward) {
      var eventsToForward = yield this.currentEventsToForward;

      if (!eventsToForward.includes(eventToForward)) {
        eventsToForward.pushObject(eventToForward);
      } else {
        eventsToForward.removeObject(eventToForward);
      }
    })
  });

  _exports.default = _default;
});