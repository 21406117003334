define("securitytrax-ember-ui/components/admin/account-management/invoices-card/component", ["exports", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/table-column-sort", "ember-concurrency"], function (_exports, _component, _tableColumnSort, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var InvoicesCard = (_dec = Ember.inject.service('intl'), _dec2 = Ember.inject.service('store'), _dec3 = Ember.inject.service('stNotificationManager'), _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember.computed, _dec8 = (0, _emberConcurrency.task)(function* () {
    var invoices = yield this.store.findAll('accountInvoice');

    if (this.TableColumnSort.stSortString === 'dueDate') {
      return invoices.sortBy('dueDate');
    } else {
      return invoices.sortBy('dueDate').reverse();
    }
  }), _dec9 = (0, _emberConcurrency.task)(function* (row) {
    try {
      var url = yield row.invokeAction('download_invoice', {
        verb: 'post'
      }); // download instead of opening in a new window. download link will have attachment headers

      window.open(url.responsePayload.data.meta.download_uri, '_blank');
    } catch (error) {
      (false && Ember.warn('failed to download file' + error, {
        id: 'account.invoices'
      }));
      this.stNotificationManager.addError(this.intl.t('common.notifications.generic_failure', {
        'action': 'download'
      }));
    }
  }), _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, (_class = (_temp = class InvoicesCard extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor3, this);

      _initializerDefineProperty(this, "currentInvoice", _descriptor4, this);

      _initializerDefineProperty(this, "payInvoiceFormStarted", _descriptor5, this);

      _initializerDefineProperty(this, "payInvoiceFormMode", _descriptor6, this);

      _initializerDefineProperty(this, "getInvoicesTask", _descriptor7, this);

      _initializerDefineProperty(this, "downloadTask", _descriptor8, this);

      var sortableColumns = [{
        name: this.intl.t('pages.administration.account_management.invoices.invoice_date'),
        value: 'dueDate'
      }];
      this.TableColumnSort = new _tableColumnSort.default(...arguments, sortableColumns);
      this.TableColumnSort.sortList('dueDate', false);
    }

    get invoices() {
      return this.getInvoicesTask.perform();
    }

    get payInvoiceFormOriginalModels() {
      return Ember.Object.create({
        accountBilling: {
          model: this.accountBilling
        }
      });
    }

    get payInvoiceForm() {
      var invoiceDate = {};
      var descriptionKey = 'pages.administration.account_management.invoices.pay';

      if (this.currentInvoice) {
        invoiceDate.invoiceDate = this.currentInvoice.dueDate;
      }

      var intl = this.intl;
      return Ember.Object.create({
        paymentMethodFormStep: Ember.Object.create({
          stepNameKey: 'payInvoiceFormStep',
          title: intl.t(descriptionKey),
          stepComponentPath: 'multi-step-form/forms/administration/account-management/invoices'
        })
      });
    }

    download(invoice) {
      return this.downloadTask.perform(invoice);
    }

    pay(invoice) {
      this.currentInvoice = invoice;
      this.payInvoiceFormStarted = true;
    }

    sortList(column, ascending) {
      this.TableColumnSort.sortList(column, ascending);
      Ember.notifyPropertyChange(this, 'invoices');
    }

    mobileSortList(column) {
      var ascending = !column.value.includes('-');
      var col = column.value.replace('-', '');
      this.TableColumnSort.sortList(col, ascending);
      Ember.notifyPropertyChange(this, 'invoices');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentInvoice", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "payInvoiceFormStarted", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "payInvoiceFormMode", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'create';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "invoices", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "invoices"), _class.prototype), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "getInvoicesTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "downloadTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "download", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "download"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "pay", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "pay"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortList", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "sortList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mobileSortList", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "mobileSortList"), _class.prototype)), _class));
  _exports.default = InvoicesCard;
});