define("securitytrax-ember-ui/dialer-customer-return/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/has-page-title", "securitytrax-ember-ui/mixins/scroll-to-top"], function (_exports, _authenticatedRouteMixin, _hasPageTitle, _scrollToTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _hasPageTitle.default, _scrollToTop.default, {
    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['customers.view']);
    },

    model(params) {
      var paramsObject = {};
      var urlParams = new URLSearchParams(window.location.search);

      if (urlParams.has('id')) {
        var subroute = urlParams.has('_subroute') ? urlParams.get('_subroute') : 'view';
        this.transitionTo('customers.' + subroute, urlParams.get('id'));
      }

      urlParams.forEach((param, key) => {
        paramsObject[key] = param;
      });
      return Object.assign(paramsObject, params);
    },

    getPageTitle() {
      return this.intl.t('pages.dialer_customer_return.title');
    },

    getRouteName() {
      return "pages.dialer_customer_return.title";
    }

  });

  _exports.default = _default;
});