define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/electronic-document-providers/integrations/docusign/edit-envelope/documents/add-document/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gR9lAS9q",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,13],null,[[\"useSpinner\",\"booleanLoaded\",\"size\"],[true,[35,3,[\"finishedCopyingModels\"]],\"medium\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"grid-x grid-padding-x align-center-middle\"],[15,5,[30,[36,9],[\"height: 100%;\"],null]],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cell small-12 medium-6\"],[12],[2,\"\\n            \"],[1,[30,[36,8],null,[[\"formMode\",\"label\",\"name\",\"options\",\"validationKey\",\"errors\",\"value\",\"valueProperty\",\"renderInPlaceOverride\",\"onChange\"],[[35,7],[30,[36,6],[\"pages.administration.integrations.electronic_document_providers.docusign.add_document_template\"],null],\"template\",[35,11],\"docusignDocument-template\",[35,3,[\"validationErrors\"]],[35,10],\"templateId\",false,[30,[36,2],[[32,0],[30,[36,1],[[35,10]],null]],null]]]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cell small-12 medium-6\"],[12],[2,\"\\n\"],[6,[37,13],null,[[\"useSpinner\",\"booleanLoaded\",\"size\"],[true,[35,12],\"small\"]],[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[30,[36,8],null,[[\"formMode\",\"label\",\"name\",\"options\",\"disabled\",\"validationKey\",\"errors\",\"value\",\"valueProperty\",\"renderInPlaceOverride\",\"onChange\"],[[35,7],[30,[36,6],[\"pages.administration.integrations.electronic_document_providers.docusign.add_document_name\"],null],\"document\",[35,5],[35,4],\"docusignDocument-template\",[35,3,[\"validationErrors\"]],[35,0],\"id\",false,[30,[36,2],[[32,0],[30,[36,1],[[35,0]],null]],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"document\",\"mut\",\"action\",\"multiStepForm\",\"documentsReadOnly\",\"documents\",\"t\",\"formMode\",\"multi-step-form/switchable-inputs/select-input\",\"html-safe\",\"template\",\"templates\",\"documentsLoaded\",\"html/st-await\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/electronic-document-providers/integrations/docusign/edit-envelope/documents/add-document/template.hbs"
    }
  });

  _exports.default = _default;
});