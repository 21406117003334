define("securitytrax-ember-ui/components/multi-step-form/forms/administration/equipment/add-equipment-master-list/review/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LQyvGnvc",
    "block": "{\"symbols\":[\"equipment\"],\"statements\":[[6,[37,1],null,[[\"label\"],[[30,[36,0],[\"pages.administration.company_settings.equipment.equipment.selected_items_add\"],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"\\n\\n\"],[10,\"ul\"],[14,0,\"review-list equipment-to-add-list\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[14,0,\"equpment-name\"],[12],[2,\"\\n        \"],[1,[32,1,[\"name\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"form-group\",\"addArray\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/equipment/add-equipment-master-list/review/template.hbs"
    }
  });

  _exports.default = _default;
});