define("securitytrax-ember-ui/components/multi-step-form/forms/customer-equipment/bulk-edit/component", ["exports", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "securitytrax-ember-ui/mixed-in-classes/set-user", "ember-concurrency"], function (_exports, _component, _switchableFormStep, _setUser, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CustomerEquipmentBulkEdit = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = (0, _emberConcurrency.task)(function* () {
    var uniqueTechs = [];
    var hashedData = yield (0, _emberConcurrency.hash)({
      finishedCopyingModels: (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true),
      equipmentWireTypes: this.store.findAll('equipmentWireType'),
      equipmentStatus: this.store.findAll('equipmentStatus'),
      technicianList: this.userService.getUsersList('tech', this.args.customer),
      salesReps: this.userService.getUsersList('sales_rep', this.args.customer)
    });
    hashedData.technicianList = hashedData.technicianList.toArray();
    hashedData.technicianList.forEach(tech => {
      if (!hashedData.salesReps.toArray().findBy('id', tech.id)) {
        uniqueTechs.push(tech);
      }
    });
    Ember.setProperties(this, hashedData);
    this.soldByOptions = hashedData.salesReps.toArray().concat(uniqueTechs);
    this.fields = [{
      type: '',
      value: null,
      name: ''
    }];
    this.technicianList.unshift(Ember.Object.create({
      id: null,
      name: 'None'
    }));
  }), _dec12 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var promiseArray = [];
    this.args.bulkEquipmentEditList.forEach(equipmentToUnlink => {
      if (equipmentToUnlink.interactiveServicesId) {
        promiseArray.push(equipmentToUnlink.invokeAction('unlink_isp_equipment', {
          verb: 'POST',
          data: {
            data: {}
          }
        }));
      }
    });

    try {
      yield (0, _emberConcurrency.all)(promiseArray);
      buttonPromise.then(() => {
        this.args.multiStepForm.onAfterSave();
        this.args.multiStepForm.formStartedSetVal(false);
        this.stNotificationManager.addSuccessKey('pages.customers.equipment.unlinkSuccess');
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
    }
  }), _dec13 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    var firstField = this.fields.objectAt(0);
    var noValidate = ['equipment', 'workOrderAction', 'zoneNumber', 'zone', 'equipmentStatus'];
    var formattedObjects = [];

    if (Ember.get(firstField, 'name') === '') {
      var validationErrors = {
        '0-customerEquipmentField': [{
          message: this.intl.t('pages.customers.equipment.bulkEditNotSelectedFailure')
        }]
      };
      multiStepForm.validationErrors = validationErrors;
      return;
    }

    var properties = this.fields.reduce((reducedProperties, field) => {
      if (Ember.get(field, 'name') !== '') {
        if (Ember.get(field, 'name') === 'isPaidFor') {
          reducedProperties[Ember.get(field, 'name')] = Ember.get(field, 'value.value');
        } else {
          reducedProperties[Ember.get(field, 'name')] = Ember.get(field, 'value');
        }
      }

      return reducedProperties;
    }, {});
    var equipmentValidationModel = {
      user: properties['soldBy'] ? properties['soldBy'].name : null
    };

    if (properties['soldBy'] !== undefined) {
      formattedObjects.push(Ember.Object.create({
        model: equipmentValidationModel,
        extraValidationData: {
          noValidate: noValidate,
          skipQuantity: true
        },
        filePath: 'customer-equipment'
      }));
      yield multiStepForm.validateList.perform(formattedObjects, {
        notificationNamespace: 'modal'
      });
    }

    if (properties.itemCharge === null) {
      properties.itemCharge = 0.00;
    }

    var attributes = {};

    if (properties.isPaidFor !== undefined) {
      attributes.is_paid_for = properties.isPaidFor;
    }

    if (properties.equipmentWireType) {
      attributes.equipment_wire_type_id = properties.equipmentWireType.id;
    }

    if (properties.equipmentStatus) {
      attributes.equipment_status_id = properties.equipmentStatus.id;
    }

    if (properties.installTech) {
      attributes.install_inventory_tech_id = properties.installTech.id;
    }

    if (properties.removeTech) {
      attributes.uninstall_inventory_tech_id = properties.removeTech.id;
    }

    if (properties.soldBy) {
      attributes.sold_by = properties.soldBy.id;
    }

    if (properties.installCalendarEvent) {
      attributes.install_tech_schedule_id = properties.installCalendarEvent.id;
    }

    if (properties.uninstallCalendarEvent) {
      attributes.uninstall_tech_schedule_id = properties.uninstallCalendarEvent.id;
    }

    var ids = [];

    for (var x = 0; x < this.args.bulkEquipmentEditList.get('length'); x++) {
      this.args.bulkEquipmentEditList[x].setProperties(properties);
      ids.push(this.args.bulkEquipmentEditList[x].get('id'));
    }

    try {
      yield this.args.bulkEquipmentEditList[0].invokeAction('bulk_edit', {
        verb: 'POST',
        data: {
          data: {
            attributes: attributes,
            ids: ids
          }
        },
        excludeModelID: true
      });
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccess(this.intl.t('common.notifications.bulk_save_successful', {
          'num': this.args.bulkEquipmentEditList.length
        }));
        multiStepForm.onAfterSave();
        multiStepForm.formStartedSetVal(false);
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
    }
  }), _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, (_class = (_temp = class CustomerEquipmentBulkEdit extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "grants", _descriptor4, this);

      _initializerDefineProperty(this, "userService", _descriptor5, this);

      _initializerDefineProperty(this, "fields", _descriptor6, this);

      _initializerDefineProperty(this, "confirmDeleteModalOpen", _descriptor7, this);

      _initializerDefineProperty(this, "confirmUnlinkModalOpen", _descriptor8, this);

      _initializerDefineProperty(this, "disabled", _descriptor9, this);

      _initializerDefineProperty(this, "soldByOptions", _descriptor10, this);

      _initializerDefineProperty(this, "initData", _descriptor11, this);

      _initializerDefineProperty(this, "bulkUnlink", _descriptor12, this);

      _initializerDefineProperty(this, "saveBulkEdit", _descriptor13, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.SetUser = new _setUser.default(...arguments);
      var buttonChoices = [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.saveBulkEdit.perform(this.args.multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary save'
      }];

      if (this.grants.getIndividualGrant('customer_equipment.delete')) {
        buttonChoices.push({
          text: this.intl.t('common.actions.delete'),
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete',
          click: () => {
            this.confirmDeleteModalOpen = true;
          }
        });
      }

      var allEquipmentLinked = true;

      for (var i = 0; i < this.args.bulkEquipmentEditList.length; i++) {
        if (!this.args.bulkEquipmentEditList[i].interactiveServicesId) {
          allEquipmentLinked = false;
          break;
        }
      }

      if (!this.args.noAdcIntegration && allEquipmentLinked) {
        buttonChoices.unshift({
          text: this.intl.t('pages.customers.equipment.unlink'),
          classNamesString: 'st-btn st-btn-secondary',
          click: () => {
            this.confirmUnlinkModalOpen = true;
          }
        });
      }

      this.args.multiStepForm.registerStepData('bulkEdit', 'buttons', buttonChoices);
      this.initData.perform();
    }

    get paidForOptions() {
      return [{
        name: this.intl.t('pages.customers.equipment.paid'),
        value: true
      }, {
        name: this.intl.t('pages.customers.equipment.notPaid'),
        value: false
      }];
    }

    setField(field, index) {
      this.fields[index] = field;
      Ember.notifyPropertyChange(this, 'fields');
    }

    addRow() {
      if (!this.disabled) {
        this.fields.pushObject({
          type: '',
          value: null,
          name: ''
        });
        this.fields.forEach(field => {
          if (field.name === 'itemCharge') {
            Ember.set(field, 'needsWarning', true);
          }
        });
        this.disabled = this.fields.length === 9; // Driven by editableFields getter in app/components/multi-step-form/forms/customer-equipment/bulk-edit-item/component.js
      }
    }

    setFieldValue(index, fieldValue) {
      var field = this.fields.objectAt(index);
      Ember.set(field, 'value', fieldValue);
    }

    removeIfCleared(index) {
      if (this.fields.length > 1) {
        this.fields.removeObject(this.fields.objectAt(index));
        this.fields.forEach(field => {
          if (field.name === 'itemCharge') {
            Ember.set(field, 'needsWarning', true);
          }
        });
      }
    }

    updateIsWarning(field, value) {
      Ember.set(field, 'needsWarning', value);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "userService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "fields", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "confirmDeleteModalOpen", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "confirmUnlinkModalOpen", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "disabled", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "soldByOptions", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "initData", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "bulkUnlink", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "saveBulkEdit", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setField", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "setField"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addRow", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "addRow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setFieldValue", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "setFieldValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeIfCleared", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "removeIfCleared"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateIsWarning", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "updateIsWarning"), _class.prototype)), _class));
  _exports.default = CustomerEquipmentBulkEdit;
});