define("securitytrax-ember-ui/my-account/base-route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/has-page-title", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _authenticatedRouteMixin, _hasPageTitle, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _hasPageTitle.default, {
    model() {
      return {
        user: this.modelFor('my-account')
      };
    },

    getPageTitle(model) {
      return (0, _objHelpers.getObjProperties)(model.user, 'fname', 'lname').join(' ');
    },

    getRouteName() {
      var route = this.routeName.split('.').pop();
      return "pages.users.".concat(route.replace('-', '_'), ".title");
    }

  });

  _exports.default = _default;
});