define("securitytrax-ember-ui/components/multi-step-form/forms/administration/users/my-inventory/purchase-orders/review-pr/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b1vNGOYP",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"cell grid-x\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"small-12 medium-12 large-12\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"notification-manager display-inline no-group\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"notification warning no-group\"],[12],[2,\"\\n                \"],[10,\"div\"],[14,0,\"before\"],[12],[2,\"\\n                    \"],[1,[30,[36,0],[\"icn-warning\"],[[\"class\"],[\"notification-icon\"]]]],[2,\"\\n                \"],[13],[2,\"\\n                \"],[1,[30,[36,1],[\"pages.administration.company_settings.inventory.purchase_requisitions.confirm_submit\"],null]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,4],null,[[\"pendingEquipmentLineItems\",\"showExtended\"],[[35,3],[35,2]]]]]],\"hasEval\":false,\"upvars\":[\"svg-icon\",\"t\",\"showExtended\",\"pendingEquipmentLineItems\",\"inventory/review-items\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/users/my-inventory/purchase-orders/review-pr/template.hbs"
    }
  });

  _exports.default = _default;
});