define("securitytrax-ember-ui/components/nav/top-nav/nav/nav-create/component", ["exports", "securitytrax-ember-ui/components/nav/top-nav/nav/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    screen: Ember.inject.service(),
    tagName: '',
    createLeadStarted: false,
    createEdit: 'create',
    model: null,
    expandedDrawer: Ember.computed('hideCreate', function () {
      if (this.hideCreate) {
        return '';
      } else {
        return 'expanded';
      }
    }),
    actions: {
      closeCreate() {
        this.set('hideCreate', true);
      }

    }
  });

  _exports.default = _default;
});