define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/interactive-services-providers/adc/import/import-users/filter-options/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/interactive-services-providers/adc/import/import-users/import-users-base"], function (_exports, _emberConcurrency, _importUsersBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _importUsersBase.default.extend({
    intl: Ember.inject.service(),
    ispService: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData("filterOptions", 'buttons', [{
        text: this.intl.t('common.actions.back'),
        classNamesString: 'st-btn st-btn-secondary',
        click: () => {
          multiStepForm.currentStepNameKeySetVal('gettingStarted');
        }
      }, {
        text: this.intl.t('common.actions.next'),
        click: () =>
        /*buttonPromise*/
        {
          multiStepForm.currentStepNameKeySetVal('importOptions');
        },
        classNamesString: 'st-btn st-btn-primary'
      }]);
    },

    adcRoles: Ember.computed('interactiveServicesProvider.id', 'connected', function () {
      return this.adcRolesTask.perform();
    }),
    adcRolesTask: (0, _emberConcurrency.task)(function* () {
      var roles = [];

      try {
        var returnData = yield this.ispService.get('invokeIspActionTask').perform({
          action: 'alarm_dot_com__getRepRoles',
          model: this.interactiveServicesProvider,
          attributes: {}
        });

        if (returnData && returnData.rep_roles) {
          returnData.rep_roles.forEach(currRole => {
            roles.push({
              name: currRole.role_name,
              value: currRole.role_id
            });
          });
        }
      } catch (e) {
        this.stNotificationManager.addWarningKey('pages.customers.system.interactiveServicesProvider.errorRetrievingRoles', 'modal');
        throw e;
      }

      return roles;
    })
  });

  _exports.default = _default;
});