define("securitytrax-ember-ui/models/directory-user", ["exports", "securitytrax-ember-ui/models/base_models/directory-user"], function (_exports, _directoryUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _directoryUser.default.extend({
    lfname: Ember.computed('fname', 'lname', function () {
      return "".concat(this.lname || '', ", ").concat(this.fname || '');
    }),
    flname: Ember.computed('fname', 'lname', function () {
      return "".concat(this.fname || '', ", ").concat(this.lname || '');
    }),
    avatar: Ember.computed('responseMeta.profile_image_url', function () {
      return Ember.isEmpty(this.get('responseMeta.profile_image_url')) || this.get('responseMeta.default_profile_image') ? undefined : this.get('responseMeta.profile_image_url');
    })
  });

  _exports.default = _default;
});