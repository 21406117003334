define("securitytrax-ember-ui/mixins/user-reusable", ["exports", "moment", "ember-concurrency"], function (_exports, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    userService: Ember.inject.service(),
    grants: Ember.inject.service(),
    calendarEventsForGrid: null,
    hasEventOverLine: false,
    name: Ember.computed.alias('lfname'),
    isSalesRep: Ember.computed('roles.{[],@each}', function () {
      return this.isSalesRepTask.perform();
    }),
    isSalesRepTask: (0, _emberConcurrency.task)(function* () {
      var keys = yield this.getUserRoleKeysTask.perform();
      return keys.some(key => {
        return key === 'sales_rep';
      });
    }),
    isTech: Ember.computed('roles.{[],@each}', function () {
      return this.isTechTask.perform();
    }),
    isTechTask: (0, _emberConcurrency.task)(function* () {
      var keys = yield this.getUserRoleKeysTask.perform();
      return keys.some(key => {
        return key === 'tech';
      });
    }),
    isTechManager: Ember.computed(function () {
      return this.isTechManagerTask.perform();
    }),
    isTechManagerTask: (0, _emberConcurrency.task)(function* () {
      var children = yield this.getAllHierarchyChildrenTask.perform();
      return children.length > 0;
    }),
    getAllHierarchyChildrenTask: (0, _emberConcurrency.task)(function* () {
      if (this.grants.getIndividualGrant('user_hierarchies.view')) {
        var childHierarchyPromises = [];
        var hierarchies = yield this.userHierarchies;
        hierarchies.forEach(hierarchy => {
          childHierarchyPromises.push(hierarchy.get('children'));
        });
        return yield (0, _emberConcurrency.all)(childHierarchyPromises);
      }

      return [];
    }),
    getAllChildrenTask: (0, _emberConcurrency.task)(function* () {
      var childPromises = [];
      var allChildHierarchyArrays = yield this.getAllHierarchyChildrenTask.perform();
      allChildHierarchyArrays.forEach(childHierarchyArray => {
        childHierarchyArray.forEach(childHierarchy => {
          childPromises.push(childHierarchy.get('user'));
        });
      });
      return yield (0, _emberConcurrency.all)(childPromises);
    }),
    getUserRoleKeysTask: (0, _emberConcurrency.task)(function* () {
      var roles = yield this.roles;
      return roles.map(item => {
        return item.get('key');
      });
    }),

    get flname() {
      return this.fname + ' ' + this.lname;
    },

    lfname: Ember.computed('fname', 'lname', function () {
      return this.lname + ', ' + this.fname;
    }),
    initials: Ember.computed('fname', 'lname', function () {
      return this.fname[0] + this.lname[0];
    }),
    lfnameid: Ember.computed('fname', 'lname', function () {
      return this.lname + ', ' + this.fname + ' (ID: ' + this.id + ')';
    }),
    citystate: Ember.computed('city', 'state', function () {
      return this.city + ', ' + this.state;
    }),
    avatar: Ember.computed('responseMeta.profile_image_url', function () {
      return Ember.isEmpty(this.get('responseMeta.profile_image_url')) || this.get('responseMeta.default_profile_image') ? undefined : this.get('responseMeta.profile_image_url');
    }),
    sortedCalendarEvents: Ember.computed('calendarEventsForGrid.{length,@each.startTime}', 'calendarEvents.{length,@each.startTime}', function () {
      return this.sortedCalendarEventsTask.perform();
    }),
    sortedCalendarEventsTask: (0, _emberConcurrency.task)(function* () {
      var currCalendarEvents = yield this.calendarEventsForGrid;

      if (currCalendarEvents) {
        return currCalendarEvents.sort(function (a, b) {
          var startTimeA = _moment.default.isMoment(a.get('startTime')) ? a.get('startTime') : (0, _moment.default)(a.get('startTime'));
          var startTimeB = _moment.default.isMoment(b.get('startTime')) ? b.get('startTime') : (0, _moment.default)(b.get('startTime'));

          if (startTimeA.unix() === startTimeB.unix()) {
            return 0;
          }

          return startTimeA.unix() < startTimeB.unix() ? -1 : 1;
        });
      }

      return [];
    }),

    get noAvatarHtml() {
      return '<img class="profile-img img-circle" src=' + this.userService.defaultProfileImageSrc + ' alt="" />';
    },

    get initialAvatarHtml() {
      return "<div class='img-circle profile-img profile-img--initials'>".concat(this.initials, "</div>");
    }

  });

  _exports.default = _default;
});