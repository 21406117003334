define("securitytrax-ember-ui/demo/power-select/controller", ["exports", "fetch", "ember-concurrency"], function (_exports, _fetch, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var cakes = [{
    id: 1,
    name: 'Angel cake'
  }, {
    id: 2,
    name: 'Bananna cake'
  }, {
    id: 3,
    name: 'Battenberg cake'
  }, {
    id: 4,
    name: 'Cheesecake'
  }, {
    id: 5,
    name: 'Chocolate cake'
  }, {
    id: 6,
    name: 'Carrot cake'
  }, {
    id: 7,
    name: 'Coffee cake'
  }, {
    id: 8,
    name: 'Fruit cake'
  }, {
    id: 9,
    name: 'Madeira cake'
  }, {
    id: 10,
    name: 'Marble cake'
  }, {
    id: 11,
    name: 'Lemon cake'
  }, {
    id: 11,
    name: 'Upside-down cake'
  }];
  var puddings = [{
    id: 1,
    name: 'Bread & butter pudding'
  }, {
    id: 2,
    name: 'Crème caramel'
  }, {
    id: 3,
    name: 'Eton mess'
  }, {
    id: 4,
    name: 'Figgy pudding'
  }, {
    id: 5,
    name: 'Jam Roly-Poly'
  }, {
    id: 6,
    name: 'Malvern pudding'
  }, {
    id: 7,
    name: 'Panna cotta'
  }, {
    id: 8,
    name: 'Rice pudding'
  }, {
    id: 9,
    name: 'Spotted dick'
  }, {
    id: 10,
    name: 'Sticky toffee pudding'
  }, {
    id: 11,
    name: 'Treacle sponge pudding'
  }, {
    id: 12,
    name: 'Trifle'
  }]; // https://ember-power-select.com/docs

  var Controller = Ember.Controller.extend({
    store: Ember.inject.service(),
    selectedDessert: null,
    selectableCakes: cakes,
    selectablePuddings: puddings,
    page: 1,
    billableOptions: Ember.computed('model.invoiceBillableItems', function () {
      var options = [];
      this.get('model.invoiceBillableItems').forEach(option => {
        options.pushObject(option);
      });
      return options;
    }),
    newBillableOptions: Ember.computed('page', function () {
      return this.newBillableOptionsTask.perform();
    }),
    newBillableOptionsTask: (0, _emberConcurrency.task)(function* () {
      return yield this.store.query('invoiceBillableItem', {
        limit: 10,
        page: this.page
      });
    }),
    singleStepForm: Ember.computed(function () {
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: 'power select demo',
          stepComponentPath: 'multi-step-form/forms/demo/demo-power-select'
        })
      });
    }),
    customerOrderInformationFormOriginalModels: Ember.computed('model.customerOrderInformation', function () {
      return Ember.Object.create({
        customerOrderInformation: {
          model: this.get('model.customerOrderInformation'),
          copyRelationMap: {
            discountAffiliate: {
              readOnly: true
            },
            customer: {
              readOnly: true,
              relationships: {
                leadSourceInformation: {
                  readOnly: true,
                  relationships: {
                    leadCompany: {
                      readOnly: true
                    }
                  }
                }
              }
            }
          }
        }
      });
    }),
    formStarted: true,
    searchTask: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(1500); //dont send query unless no activity for a sec

      var url = "https://api.github.com/search/repositories?q=".concat(term);
      return (0, _fetch.default)(url).then(resp => resp.json()).then(json => json.items);
    }),
    infiniteSearch: (0, _emberConcurrency.task)(function* (term) {
      yield (0, _emberConcurrency.timeout)(1500);

      if (term.length === 0) {
        return [];
      } else {
        return this.store.query('invoiceBillableItem', {
          filter: 'code ~ ' + '\'%' + term + '%\''
        });
      }
    }),
    loadMore: (0, _emberConcurrency.task)(function* (term) {
      if (!term) {
        this.set('page', this.page + 1);
        return yield this.newBillableOptions;
      }
    }),
    actions: {
      resetPageNumber() {
        this.set('page', 1);
      },

      selectDessert(dessert) {
        this.selectedDessert = dessert;
      }

    }
  });
  var _default = Controller;
  _exports.default = _default;
});