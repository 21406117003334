define("securitytrax-ember-ui/components/multi-step-form/forms/customer-order-information/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "securitytrax-ember-ui/mixins/set-user", "ember-concurrency", "moment", "ember-inflector"], function (_exports, _switchableFormStep, _setUser, _emberConcurrency, _moment, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, _setUser.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    screen: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    //init some fields that can be mut'ed for show/hide
    leadCompany: Ember.computed.alias('multiStepForm.formCopiedModels.customerOrderInformation.model.customer.leadSourceInformation.leadCompany'),
    monitoredBy: Ember.computed.alias('multiStepForm.formCopiedModels.customerOrderInformation.model.monitoringCompany'),
    interactiveServicesProvider: Ember.computed.alias('multiStepForm.formCopiedModels.customerOrderInformation.model.interactiveServicesProvider'),
    //hide fields if empty
    sourceCampaignDisabled: Ember.computed.empty('leadCompany'),
    interactiveServicesPackageDisabled: Ember.computed.empty('interactiveServicesProvider'),
    //filter select lists based on another field
    leadCompanyCampaigns: Ember.computed('leadCompany', function () {
      return this.leadCompanyCampaignsTask.perform();
    }),
    leadCompanyCampaignsTask: (0, _emberConcurrency.task)(function* () {
      if (!this.multiStepForm.formCopiedModelsGetVal('customerOrderInformation.model.customer.leadSourceInformation.leadCompany')) {
        return [];
      }

      var leadCompanies = yield this.leadCompanies;

      if (leadCompanies) {
        var leadCompany = leadCompanies.filterBy('id', this.leadCompany.id);
        var campaigns = leadCompany.length > 0 ? yield leadCompany[0].get('leadCompanyCampaigns') : [];
        return campaigns;
      }

      return null;
    }),
    isMonitored: Ember.computed('model.customerOrderInformation.monitoringCompany', function () {
      return this.getMonitoringCompanyTask.perform();
    }),
    getMonitoringCompanyTask: (0, _emberConcurrency.task)(function* () {
      if (yield this.get('model.customerOrderInformation.monitoringCompany')) {
        return true;
      } else {
        return false;
      }
    }),
    monitoringPlans: Ember.computed('isMonitored', function () {
      //plan options should be blank when disabled
      if (this.isMonitored === false) {
        return [];
      }

      return this.get('model.monitoringPlans');
    }),
    customerIsCancelled: Ember.computed('multiStepForm.formCopiedModels.customerOrderInformation.model', function () {
      if (this.multiStepForm.formCopiedModelsGetVal('customerOrderInformation.model.customerIsCancelled')) {
        return true;
      }

      return false;
    }),
    cancelationDetails: Ember.computed('multiStepForm.formCopiedModels.customerOrderInformation.model.{customerCancelDate,customerCancelReason}', function () {
      var customerCancelDate = (0, _moment.default)(this.multiStepForm.formCopiedModelsGetVal('customerOrderInformation.model.customerCancelDate')).format('MM/DD/YYYY');

      if (this.multiStepForm.formCopiedModelsGetVal('customerOrderInformation.model.customerCancelReason')) {
        return "".concat(customerCancelDate, " (").concat(this.multiStepForm.formCopiedModelsGetVal('customerOrderInformation.model.customerCancelReason.name'), ")");
      } else {
        return "".concat(customerCancelDate);
      }
    }),
    leadCompanies: Ember.computed(function () {
      return this.getLeadCompaniesTask.perform();
    }),
    getLeadCompaniesTask: (0, _emberConcurrency.task)(function* () {
      var leadCompanies = this.grants.getIndividualGrant('lead_companies.view') ? yield this.store.findAll('leadCompany') : null;
      return leadCompanies;
    }),
    discountAffiliates: Ember.computed(function () {
      return this.getSalesInfoTask.perform('discountAffiliate');
    }),
    activationFees: Ember.computed(function () {
      return this.getSalesInfoTask.perform('activationFee');
    }),
    mmrs: Ember.computed(function () {
      return this.store.query('mmr', {
        limit: 0,
        sort: 'amount'
      });
    }),
    createRecordOptionsAmount: Ember.computed(function () {
      return {
        amount: 0,
        active: true
      };
    }),
    createRecordOptionsMonths: Ember.computed(function () {
      return {
        months: 0,
        active: true
      };
    }),
    contractTerms: Ember.computed(function () {
      return this.getSalesInfoTask.perform('contractTerm');
    }),
    customerWarranties: Ember.computed(function () {
      return this.getSalesInfoTask.perform('customerWarranty');
    }),
    getSalesInfoTask: (0, _emberConcurrency.task)(function* (model) {
      var storeInfo = yield this.store.findAll(model);
      return storeInfo;
    }),
    leadInformation: Ember.computed('multiStepForm.formOriginalModels.customerOrderInformation.model.customer.id', 'grants.userGrants', function () {
      var customer = this.multiStepForm.formOriginalModelsGetVal('customerOrderInformation.model.customer');

      if (this.grants.getIndividualGrant('customer_lead_information.view') && customer) {
        return customer.get('leadInformation');
      } else {
        return null;
      }
    }),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('step1', 'buttons', [{
        text: this.intl.t('common.actions.cancel'),
        click: () => {
          this.multiStepForm.setupCopyModels.perform(); //refresh copied models instead of rolling everything back

          this.multiStepForm.resetForm(false); //reset form and all validation errors

          this.send('changeFormMode', 'view');
        },
        classNamesString: 'st-btn st-btn-secondary cancel-customer-order-information-form'
      }, {
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.saveCustomerOrderInformationTask.perform(buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary submit-customer-order-information-form'
      }]);
    },

    saveCustomerOrderInformationTask: (0, _emberConcurrency.task)(function* (buttonPromise) {
      try {
        var multiStepForm = this.multiStepForm;
        yield multiStepForm.validateSyncSaveAll({
          extraValidationData: {
            noValidate: ['location', 'orderInformation-salesRep', 'orderInformation-customerCancelDate', 'leadInformation-leadStatus', 'customer-zip', 'customer-address1']
          }
        });
        buttonPromise.then(() => {
          this.get('model.customer.repUser').reload();
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
          this.send('changeFormMode', 'view');
        });
      } catch (e) {
        (false && Ember.warn('Failed to save order information' + e, {
          id: 'multiStepForm.forms.customerOrderInformation'
        }));
        throw e;
      }
    }),
    actions: {
      openApplyTemplateModal() {
        if (this.openApplyTemplateModal) {
          this.openApplyTemplateModal();
        }
      },

      openCancelAccountModal() {
        if (this.openCancelAccountModal) {
          this.openCancelAccountModal();
        }
      },

      newModelCreated(modelName, value) {
        this.notifyPropertyChange((0, _emberInflector.pluralize)(modelName));
        this.multiStepForm.formCopiedModelsSetVal("customerOrderInformation.model.".concat(modelName), value);
      }

    }
  });

  _exports.default = _default;
});