define("securitytrax-ember-ui/components/customer/dashboard/invoices-card/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var InvoicesCard = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = (0, _emberConcurrency.task)(function* () {
    var unsortedInvoices = yield this.args.customer.get('invoices');
    this.invoices = unsortedInvoices.toArray().sort((a, b) => b.saleDate - a.saleDate);
  }), _dec7 = Ember._action, (_class = (_temp = class InvoicesCard extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "grants", _descriptor2, this);

      _initializerDefineProperty(this, "screen", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "invoices", _descriptor5, this);

      _initializerDefineProperty(this, "invoicesTask", _descriptor6, this);

      if (!this.args.useDefaultData) {
        this.invoicesTask.perform();
      } else {
        this.invoices = this.defaultData;
      }
    }

    get defaultData() {
      return [{
        id: '1',
        saleDate: '2020-09-26T11:33:16-06:00',
        responseMeta: {
          due_amount: '122.36'
        },
        paidStatus: {
          statusClass: 'overdue',
          statusString: 'Overdue'
        }
      }, {
        id: '2',
        saleDate: '2020-10-12T13:33:16-06:00',
        responseMeta: {
          due_amount: '2365.99'
        },
        paidStatus: {
          statusClass: 'due:-2021-01-06',
          statusString: 'DUE: 2021-01-06'
        }
      }];
    }

    transitionToSubroute(path) {
      if (this.router.currentRoute.name.includes('accounting')) {
        this.router.transitionTo('customers.accounting.edit-create-invoice', path);
      } else {
        this.router.transitionTo('customers.view.edit-create-invoice', path);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "screen", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "invoices", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "invoicesTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "transitionToSubroute", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToSubroute"), _class.prototype)), _class));
  _exports.default = InvoicesCard;
});