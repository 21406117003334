define("securitytrax-ember-ui/helpers/join", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.join = join;
  _exports.default = void 0;

  function join(args) {
    if (Array.isArray(args[0])) {
      if (args[1]) {
        return args[0].join(args[1]);
      }

      return args[0].join();
    }

    return null;
  }

  var _default = Ember.Helper.helper(join);

  _exports.default = _default;
});