define("securitytrax-ember-ui/components/lists/data-list/column-formatters/memo/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    screen: Ember.inject.service(),
    isExpanded: false,
    canToggleIcon: Ember.computed('canToggle', function () {
      var canToggle = this.canToggle;
      return canToggle != undefined ? canToggle : true;
    }),
    isTruncated: Ember.computed('value', function () {
      var numChars = this.truncateAfterWithDefault;
      return this.value && typeof this.value === 'string' ? this.value.length > numChars : false;
    }),
    truncateAfterWithDefault: Ember.computed('truncateAfter', function () {
      var numChars = this.truncateAfter;
      return numChars ? numChars : 100;
    }),
    popperText: Ember.computed('value', function () {
      return this.value.replaceAll('\n', '</br>');
    }),
    popperOptions: Ember.computed(function () {
      return {
        modifiers: {
          preventOverflow: {
            escapeWithReference: false
          }
        }
      };
    }),
    truncatedText: Ember.computed('value', function () {
      return _lodash.default.truncate(this.value, {
        length: this.truncateAfterWithDefault,
        separator: ' ',
        omission: this.canToggleIcon === true ? ' ' : ''
      });
    })
  });

  _exports.default = _default;
});