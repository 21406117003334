define("securitytrax-ember-ui/components/multi-step-form/forms/administration/inventory/equipment-transfer/review/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4mw5vLEQ",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"pendingEquipmentLineItems\"],[[35,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"pendingEquipmentLineItems\",\"inventory/review-items\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/inventory/equipment-transfer/review/template.hbs"
    }
  });

  _exports.default = _default;
});