define("securitytrax-ember-ui/utils/partitions", ["exports", "securitytrax-ember-ui/utils/doors", "securitytrax-ember-ui/enums/MainArmingState", "securitytrax-ember-ui/enums/SecondaryArmingState", "securitytrax-ember-ui/utils/i18n"], function (_exports, _doors, _MainArmingState, _SecondaryArmingState, _i18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getPartitionStateCSS = getPartitionStateCSS;
  _exports.getPartitionStateText = getPartitionStateText;
  _exports.getPartitionLabel = getPartitionLabel;

  /**
   * Utility helper module for partition/systems.
   * @module
   */

  /**
   * Returns the appropriate CSS class to show the current partition state.
   *
   * @param {number} state The partition state.
   * @param {number} [armingOptions=0] The arming options to represent.
   *
   * @returns {String}
   */
  function getPartitionStateCSS(state, armingOptions = 0) {
    // Is arming state disarmed?
    if (state === _MainArmingState.Disarmed) {
      // Make sure arming options are zero.
      armingOptions = 0;
    } // Was the night arming options requested?


    if ((_SecondaryArmingState.NightArming & armingOptions) > 0) {
      // Return armed night state.
      return 'armed-night';
    } // Return computed state.


    return {
      [_MainArmingState.Unknown]: 'unknown',
      [_MainArmingState.Disarmed]: 'disarmed',
      [_MainArmingState.ArmedAway]: 'armed-away',
      [_MainArmingState.ArmedStay]: 'armed-stay'
    }[state];
  }
  /**
   * Returns the indicated state in text form.
   *
   * @param {number} state The state to represent.
   * @param {number} [armingOptions=0] The arming options to represent.
   *
   * @returns {String}
   */


  function getPartitionStateText(state, armingOptions = 0) {
    return (0, _i18n.getTranslation)("home.partitions.".concat(getPartitionStateCSS(state, armingOptions).camelize()));
  }
  /**
   * Creates and returns a partition label configuration object based on the passed state.
   *
   * @param {models.devices.Partition} model The partition model.
   *
   * @returns {SimpleLabelType}
   * @static
   */


  function getPartitionLabel(model) {
    // Get partition state information.
    var {
      description,
      state,
      armingOptions
    } = model; // Return label.

    return (0, _doors.getStaticLabel)(description, getPartitionStateText(state, armingOptions), getPartitionStateCSS(state, armingOptions));
  }
});