define("securitytrax-ember-ui/models/consumer-financing-lender-type", ["exports", "securitytrax-ember-ui/models/base_models/consumer-financing-lender-type"], function (_exports, _consumerFinancingLenderType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _consumerFinancingLenderType.default.extend({
    get key() {
      switch (this.id) {
        case '1':
          return 'smart_home_pay';

        case '2':
          return 'da_aquafinance';

        case '3':
          return 'da_pinnacle';

        case '4':
          return 'smart_home_pay_los';
      }

      return '';
    }

  });

  _exports.default = _default;
});