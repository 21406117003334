define("securitytrax-ember-ui/models/base_models/user-hr-setting", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    legalName: (0, _model.attr)('string', {
      'readOnly': false
    }),
    notes: (0, _model.attr)('string', {
      'readOnly': false
    }),
    ssn: (0, _model.attr)('string', {
      'readOnly': false
    }),
    hireDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    startDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    transferDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    lastDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    rehireDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    leaveReason: (0, _model.attr)('string', {
      'readOnly': false
    }),
    payrollIdentifier: (0, _model.attr)('string', {
      'readOnly': false
    }),
    hierarchyTitleDesignation: (0, _model.attr)('string', {
      'readOnly': false
    }),
    birthdate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    recruitedByUser: (0, _model.belongsTo)('user', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    userHierarchyTitle: (0, _model.belongsTo)('user-hierarchy-title', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    user: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': 'userHrSetting'
    })
  });

  _exports.default = _default;
});