define("securitytrax-ember-ui/home/base-route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/has-page-title"], function (_exports, _authenticatedRouteMixin, _hasPageTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _hasPageTitle.default, {
    getPageTitle() {
      return this.intl.t('common.nav.home');
    },

    getRouteName() {
      var route = this.routeName.split('.').pop();
      return "pages.home.".concat(route.replace('-', '_'));
    },

    homeController: Ember.computed(function () {
      return this.controllerFor('home');
    }),

    setupBreadCrumbs() {
      this.homeController.set('breadCrumbs', null);
      this.homeController.set('breadCrumbData', null);
    },

    actions: {
      didTransition() {
        this.setupBreadCrumbs();
      }

    }
  });

  _exports.default = _default;
});