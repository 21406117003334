define("securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/dealer-ally/decision/pending-declined/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "++Y6cGmp",
    "block": "{\"symbols\":[\"@application\",\"@state\"],\"statements\":[[10,\"div\"],[14,0,\"grid-x grid-padding-x grid-padding-y align-center-middle align-center decision\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cell decision__primary-text\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,2],\"pending\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,0],[\"pages.customers.accounting.financing.application_pending\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[1,[30,[36,0],[\"pages.customers.accounting.financing.thanks_for_application\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cell decision__secondary-text\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,2],\"pending\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,0],[\"pages.customers.accounting.financing.application_contact\"],[[\"lender\"],[[32,1,[\"consumerFinancingLender\",\"name\"]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[1,[30,[36,0],[\"pages.customers.accounting.financing.unable_to_approve\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/dealer-ally/decision/pending-declined/template.hbs"
    }
  });

  _exports.default = _default;
});