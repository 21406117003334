define("securitytrax-ember-ui/users/roles/route", ["exports", "securitytrax-ember-ui/users/base-route", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _baseRoute, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_routePageviewLogging.default, {
    grants: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      var userId = this.paramsFor('users').id;
      return this.findGrantsTask.perform(['user_tech_settings.view', 'user_rep_numbers.view'], 'one', "/users/".concat(userId, "/information"));
    },

    model() {
      var {
        user
      } = this._super(...arguments);

      this.determineRoleAndRedirectTask.perform(user);
      return Ember.RSVP.hash({
        user,
        userTechSetting: this.grants.getIndividualGrant('user_tech_settings.view') ? user.get('userTechSetting') : null
      });
    }

  });

  _exports.default = _default;
});