define("securitytrax-ember-ui/components/html/icons/icon-hamburger/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    collapsed: false,
    tagName: '',
    isCollapsed: Ember.computed('collapsed', function () {
      if (this.collapsed) {
        return '';
      } else {
        return 'collapsed';
      }
    })
  });

  _exports.default = _default;
});