define("securitytrax-ember-ui/mixins/subroute-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    init() {
      this._super(...arguments);
    },

    modelId: undefined,
    parentRoute: Ember.computed('transitionRoute', function () {
      return this.transitionRoute.replace('.', '/');
    }),
    transitionRoute: Ember.computed(function () {
      return this.fullRouteName.substr(0, this.fullRouteName.lastIndexOf('.'));
    })
  });

  _exports.default = _default;
});