define("securitytrax-ember-ui/administration/inventory/spillage/route", ["exports", "securitytrax-ember-ui/administration/inventory/base-route", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _baseRoute, _authenticatedRouteMixin, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_authenticatedRouteMixin.default, _routePageviewLogging.default, {
    sessionData: Ember.inject.service('sessionData'),
    user: undefined,

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['equipment_spillage.view', 'equipment_spillage.computed.company_inventory'], 'all');
    },

    model() {
      return this.sessionData.getUser();
    },

    setupController(controller, model) {
      this._super(...arguments);

      controller.set('user', model);
    }

  });

  _exports.default = _default;
});