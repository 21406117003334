define("securitytrax-ember-ui/components/multi-step-form/forms/customer-equipment/add-equipment/copy-from-invoice/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer-equipment/customer-equipment-base", "ember-concurrency", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _customerEquipmentBase, _emberConcurrency, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerEquipmentBase.default.extend({
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    selectedInvoices: null,
    workOrderAction: null,
    initTask: (0, _emberConcurrency.task)(function* () {
      yield this.initBaseData.perform();
      (0, _objHelpers.initializeState)(this, {
        selectedInvoices: []
      });
      this.set('newLineItems', Ember.A([]));
      this.newLineItems.pushObject(Ember.Object.create({
        quantity: 1,
        itemCharge: 0
      }));
    }).on('init'),
    invoices: Ember.computed('customer.invoices.[]', function () {
      return this.customer.get('invoices');
    }),
    invoiceEquipmentList: Ember.computed('invoices.[]', 'customerEquipment', function () {
      return this.invoiceEquipmentListTask.perform();
    }),
    invoiceEquipmentListTask: (0, _emberConcurrency.task)(function* () {
      var _this = this;

      var invoiceToEquipmentCount = [];
      var hashedData = yield (0, _emberConcurrency.hash)({
        invoices: this.invoices,
        invoiceLineItemsMap: this.mapInvoiceLineItemsTask.perform(),
        invoiceBillableItems: this.store.query('invoiceBillableItem', {
          limit: 0
        })
      });

      var _loop = function (i) {
        var currInvoice = hashedData.invoices.objectAt(i);
        var lineItems = hashedData.invoiceLineItemsMap[currInvoice.get('id')];
        var currInvoiceLineItemsList = {};

        for (var j = 0; j < lineItems.get('length'); j++) {
          var lineItem = lineItems.objectAt(j);
          var billableItem = lineItem.get('invoiceBillableItem');
          var equipment = billableItem ? billableItem.get('equipment') : null;

          if (!lineItem.get('comboId') && equipment && equipment.get('name')) {
            if (!currInvoiceLineItemsList[equipment.get('name')]) {
              currInvoiceLineItemsList[equipment.get('name')] = +lineItem.get('quantity');
            } else {
              currInvoiceLineItemsList[equipment.get('name')] += +lineItem.get('quantity');
            }
          }
        }

        var packageContent = Object.keys(currInvoiceLineItemsList).map(equipmentName => {
          return {
            name: equipmentName,
            quantity: currInvoiceLineItemsList[equipmentName]
          };
        });

        if (packageContent.length > 0) {
          invoiceToEquipmentCount.push({
            packageName: _this.intl.t('pages.customers.order.generic_invoice_titles.invoice') + ' ' + currInvoice.get('id'),
            packageId: currInvoice.get('id'),
            packageContent
          });
        }
      };

      for (var i = 0; i < hashedData.invoices.get('length'); i++) {
        _loop(i);
      }

      return invoiceToEquipmentCount;
    }),
    mapInvoiceLineItemsTask: (0, _emberConcurrency.task)(function* () {
      var invoices = yield this.invoices;
      var theMap = {};
      var allLineItemGets = [];
      invoices.forEach(invoice => {
        allLineItemGets.push(this.addLineItemsToMapTask.perform(theMap, invoice));
      });
      yield (0, _emberConcurrency.all)(allLineItemGets);
      return theMap;
    }),
    addLineItemsToMapTask: (0, _emberConcurrency.task)(function* (theMap, invoice) {
      var lineItems = yield invoice.get('invoiceLineItems');
      theMap[invoice.get('id')] = lineItems;
    }),

    saveCustomerEquipment(multiStepForm, buttonPromise) {
      return this.createFromInvoicesTask.perform(multiStepForm, buttonPromise);
    },

    createFromInvoicesTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var formattedObjects = Ember.A();
      formattedObjects.pushObject(Ember.Object.create({
        model: {
          user: multiStepForm.formCopiedModelsGetVal('customerEquipment.model.user'),
          workOrderAction: this.workOrderAction
        },
        filePath: 'customer-equipment/add-from-invoices',
        extraValidationData: {
          noValidate: this.workOrder ? ['zoneNumber', 'zone'] : ['workOrderAction', 'zoneNumber', 'zone']
        }
      }));
      var hashedData = yield (0, _emberConcurrency.hash)({
        systemInfo: this.grants.getIndividualGrant('customer_system_information.view') ? this.customer.get('customerSystemInformation') : null,
        validateList: multiStepForm.validateList.perform(formattedObjects)
      });

      if (this.selectedInvoices && this.selectedInvoices.length > 0) {
        try {
          var allSavedEquipment = yield (0, _emberConcurrency.hash)({
            savedFromInvoices: hashedData.systemInfo.invokeAction('sync_from_invoices', {
              verb: 'POST',
              data: {
                data: {
                  invoice_ids: this.selectedInvoices,
                  sold_by_user_id: multiStepForm.formCopiedModelsGetVal('customerEquipment.model.user.id')
                }
              }
            }),
            additionalSaved: this.newLineItems.get('length') > 1 ? this.createFromManualMultipleTask.perform(multiStepForm, buttonPromise) : null
          });

          if (this.workOrder) {
            var allSaves = [];

            if (allSavedEquipment.savedFromInvoices.responsePayload.included) {
              allSavedEquipment.savedFromInvoices.responsePayload.included.forEach(ce => {
                allSaves.push(this.getCeAndCreateWorkOrderEquipment.perform(ce));
              });
            }

            yield (0, _emberConcurrency.all)(allSaves);
          }
        } catch (err) {
          this.stNotificationManager.addErrorMessages(err, {
            notificationNamespace: 'modal'
          });
          throw err;
        }

        buttonPromise.then(() => {
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
          this.get('customer.customerEquipment').reload();
          this.get('customer.invoices').reload();
          multiStepForm.onAfterSave();

          if (this.workOrder) {
            this.techToday.trigger('refresh-work-order-equipment');
          }
        }, {}, 'Resolve button promise on adding equipment from invoice(s)');
      } else {
        this.stNotificationManager.addErrorKey('pages.customers.order.invoices_required', 'modal');
        throw '';
      }
    }),
    getCeAndCreateWorkOrderEquipment: (0, _emberConcurrency.task)(function* (ce) {
      var customerEquipment = yield this.store.findRecord('customerEquipment', ce.id);
      yield this.createWorkOrderEquipment.perform(customerEquipment);
    })
  });

  _exports.default = _default;
});