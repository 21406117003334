define("securitytrax-ember-ui/administration/company-settings/integrations/partner-companies/company/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SrIq1ocD",
    "block": "{\"symbols\":[],\"statements\":[[8,\"pages/administration/company-settings/integrations/partner-companies/company\",[],[[\"@leadCompany\",\"@tags\",\"@model\",\"@breadCrumbs\",\"@breadCrumbData\"],[[34,0,[\"leadCompany\"]],[34,0,[\"tags\"]],[34,0],[34,1],[34,2]]],null]],\"hasEval\":false,\"upvars\":[\"model\",\"breadCrumbs\",\"breadCrumbData\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/administration/company-settings/integrations/partner-companies/company/template.hbs"
    }
  });

  _exports.default = _default;
});