define("securitytrax-ember-ui/administration/company-settings/system/payables/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9hrXPZMQ",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[8,\"pages/administration/human-resources/payables\",[],[[\"@payrollFunctions\",\"@payrollRoles\",\"@model\",\"@breadCrumbs\",\"@breadCrumbData\"],[[34,0,[\"payrollFunctions\"]],[34,0,[\"payrollRoles\"]],[34,0],[34,1],[34,2]]],null]],\"hasEval\":false,\"upvars\":[\"model\",\"breadCrumbs\",\"breadCrumbData\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/administration/company-settings/system/payables/template.hbs"
    }
  });

  _exports.default = _default;
});