define("securitytrax-ember-ui/components/multi-step-form/switchable-inputs/jump-counter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zHyuwsUi",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,\"div\"],[16,0,[30,[36,2],[[30,[36,1],[[35,0],\"disabled\",\"pointer\"],null],\" clickable-icon icon\"],null]],[4,[38,4],[[32,0],\"updateValue\",[35,3],\"down\"],null],[12],[2,\"\\n    \"],[1,[30,[36,5],[\"icn-arrow-down\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[18,1,null],[2,\"\\n\"],[11,\"div\"],[16,0,[30,[36,2],[[30,[36,1],[[35,0],\"disabled\",\"pointer\"],null],\" clickable-icon icon\"],null]],[4,[38,4],[[32,0],\"updateValue\",[35,3],\"up\"],null],[12],[2,\"\\n    \"],[1,[30,[36,5],[\"icn-arrow-up\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"showView\",\"if\",\"concat\",\"row\",\"action\",\"svg-icon\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/switchable-inputs/jump-counter/template.hbs"
    }
  });

  _exports.default = _default;
});