define("securitytrax-ember-ui/components/pages/administration/inventory/inventory-rma/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    grants: Ember.inject.service(),
    inventoryService: Ember.inject.service(),
    stFiltersService: Ember.inject.service(),
    tagsService: Ember.inject.service(),
    screen: Ember.inject.service(),
    rmaDeleteId: null,
    limit: 50,
    page: 1,
    createRmaFormMode: 'edit',
    createRmaFormStarted: false,
    rma: undefined,
    rmaListStatus: undefined,
    rmaReceivedStatus: undefined,
    rmaListCount: 0,
    showNotification: true,
    rmaVendor: undefined,
    createdDateFrom: undefined,
    createdDateTo: undefined,
    rmaRecipient: undefined,
    tagFilter: undefined,

    init() {
      this._super(...arguments);

      Ember.set(this, 'tagFilter', this.tagsService.tagFilter());
      this.initTask.perform();
    },

    initTask: (0, _emberConcurrency.task)(function* () {
      Ember.set(this, 'tagsOptions', yield this.tagsService.tagsOptionsTask.perform('pending_equipment'));
    }),

    get rmaListDefault() {
      return this.statusOptions.filter(status => {
        return status.value === 'open';
      });
    },

    rmaNotice: Ember.computed(function () {
      return this.intl.t('common.filters.default_filters_applied') + ' ' + this.intl.t('pages.administration.company_settings.inventory.rma.description', {
        link: 'https://answers.securitytrax.com/Support/Company_and_Admin/Inventory_Management/Return_Merchandise_Authorization_(RMA)'
      });
    }),
    receivedStatusOptions: Ember.computed(function () {
      var intl = this.intl;
      return [{
        value: 'none',
        name: intl.t('pages.users.my_inventory.purchase_orders.status.none')
      }, {
        value: 'partially',
        name: intl.t('pages.users.my_inventory.purchase_orders.status.partially')
      }, {
        value: 'full',
        name: intl.t('pages.users.my_inventory.purchase_orders.status.in_full')
      }];
    }),
    statusOptions: Ember.computed(function () {
      var intl = this.intl;
      return [{
        value: 'open',
        name: intl.t('pages.users.my_inventory.spillage.open')
      }, {
        value: 'closed',
        name: intl.t('pages.users.my_inventory.spillage.closed')
      }];
    }),
    recipientOptions: Ember.computed(function () {
      return this.inventoryService.get('getInventoryRecipients').perform();
    }),
    vendors: Ember.computed(function () {
      return this.getVendorsTask.perform();
    }),
    getVendorsTask: (0, _emberConcurrency.task)(function* () {
      var vendors = yield this.store.findAll('equipmentVendor', {
        include: 'equipment_vendor_config'
      });
      return vendors.sortBy('name');
    }),
    formOriginalModels: Ember.computed('rma', 'grants.userGrants', function () {
      return Ember.Object.create({
        equipmentReturnMerchandiseAuthorization: {
          model: this.rma,
          copyRelationMap: {
            user: {
              doCopy: true,
              doValidate: false,
              doSave: false
            },
            location: {
              readOnly: true
            },
            createdByUser: {
              doCopy: true,
              doValidate: false,
              doSave: false
            },
            equipmentVendor: {
              readOnly: true
            },
            pendingEquipmentLineItems: {
              relationships: {
                equipment: {
                  readOnly: true,
                  relationships: {
                    equipmentItem: {
                      readOnly: true,
                      relationships: {
                        equipmentSubType: {
                          readOnly: true,
                          relationships: {
                            equipmentType: {
                              readOnly: true
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
    }),
    rmaList: Ember.computed(function () {
      return this.getRmaListTask.perform();
    }),
    getRmaListTask: (0, _emberConcurrency.task)(function* () {
      var newFilters = yield this.stFiltersService.getFiltersByGroupKey('rmaList');
      var queryParams = {
        filter: newFilters,
        sort: '-id',
        limit: this.limit,
        page: this.page,
        include: 'user,location,pending_equipment_line_items.equipment.equipment_type,equipment_vendor,created_by_user'
      };

      if (this.page == 1) {
        queryParams['count'] = 1;
      }

      var rmaList = yield this.store.query('equipmentReturnMerchandiseAuthorization', queryParams);

      if (this.page == 1) {
        this.set('rmaListCount', rmaList.meta.record_count);
      }

      return rmaList;
    }).restartable().maxConcurrency(1),
    formSteps: Ember.computed('createRmaFormMode', 'rma.{id}', function () {
      var recipient = '';

      if (this.get('rma.user.flname')) {
        recipient = this.get('rma.user.flname');
      } else {
        recipient = this.get('rma.location.name');
      }

      var titleData = {
        id: this.get('rma.id'),
        userName: recipient,
        vendor: this.get('rma.equipmentVendor.name')
      };
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.intl.t(this.createRmaFormMode === 'create' ? 'pages.administration.company_settings.inventory.rma.create_title' : 'features.inventory.rma.edit_title', titleData),
          stepComponentPath: 'multi-step-form/forms/administration/inventory/edit-create-rma/' + (this.rma != undefined ? 'edit-rma' : 'create-rma')
        })
      });
    }),
    actions: {
      getRmaList() {
        Ember.notifyPropertyChange(this, 'rmaList');
      },

      clearFilter() {
        Ember.setProperties(this, {
          rmaListStatus: this.rmaListDefault,
          rmaReceivedStatus: undefined,
          rmaVendor: undefined,
          createdDateFrom: undefined,
          createdDateTo: undefined,
          rmaRecipient: undefined,
          rmaListLoaded: false,
          tagFilter: this.tagsService.tagFilter()
        });
        Ember.notifyPropertyChange(this, 'rmaList');
      },

      filterSpillage() {
        this.set('page', 1);
        Ember.notifyPropertyChange(this, 'rmaList');
      },

      editAction(row) {
        if (this.grants.getIndividualGrant('equipment_return_merchandise_authorizations.modify')) {
          this.set('createRmaFormMode', 'edit');
        }

        this.set('rma', row);
        this.set('createRmaFormStarted', true);
      },

      addAction() {
        this.set('rma', null);
        this.set('createRmaFormStarted', true);
        this.set('createRmaFormMode', 'create');
      },

      refreshModel() {
        Ember.notifyPropertyChange(this, 'rmaList');
      }

    }
  });

  _exports.default = _default;
});