define("securitytrax-ember-ui/validations/electronic-document-provider-config", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation", "lodash"], function (_exports, _validate, _baseValidation, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateObject = {};
      var outputFormat = '';

      switch (_lodash.default.get(data, 'extraValidationData.eDocProviderType', '')) {
        case 'DocuSign':
          outputFormat = 'docusignSettings';
          validateObject = {
            environment: {
              presence: this.customMessageOrDefault(data, 'docusignSettings-environment')
            },
            serviceName: {
              presence: () => {
                if (_lodash.default.get(data, 'extraValidationData.noValidate', []).includes('serviceName')) {
                  return false;
                } else {
                  return this.customMessageOrDefault(data, 'docusignSettings-serviceName');
                }
              }
            },
            serviceUsername: {
              presence: () => {
                if (_lodash.default.get(data, 'extraValidationData.noValidate', []).includes('serviceUsername')) {
                  return false;
                } else {
                  return this.checkValidateCustomMessageDefault(data, 'docusignSettings-serviceUsername');
                }
              }
            },
            serviceEmail: {
              presence: () => {
                if (_lodash.default.get(data, 'extraValidationData.noValidate', []).includes('serviceEmail')) {
                  return false;
                } else {
                  return this.checkValidateCustomMessageDefault(data, 'docusignSettings-serviceEmail');
                }
              }
            },
            accountId: {
              presence: () => {
                if (_lodash.default.get(data, 'extraValidationData.noValidate', []).includes('accountId')) {
                  return false;
                } else {
                  return this.customMessageOrDefault(data, 'docusignSettings-accountId');
                }
              }
            },
            apiAccountId: {
              presence: () => {
                if (_lodash.default.get(data, 'extraValidationData.noValidate', []).includes('apiAccountId')) {
                  return false;
                } else {
                  return this.customMessageOrDefault(data, 'docusignSettings-apiAccountId');
                }
              }
            }
          };

          if (this.email && !_lodash.default.get(data, 'extraValidationData.noValidate', []).includes('serviceEmail')) {
            validateObject['serviceEmail'] = {
              email: this.customMessageOrDefault(data, 'docusignSettings-serviceEmail')
            };
          }

          break;

        case 'PandaDoc':
          outputFormat = 'pandadocSettings';
          validateObject = {
            environment: {
              presence: this.customMessageOrDefault(data, 'pandadocSettings-environment')
            },
            apiKey: {
              presence: () => {
                if (_lodash.default.get(data, 'extraValidationData.noValidate', []).includes('apiKey')) {
                  return false;
                } else {
                  return this.customMessageOrDefault(data, 'pandadocSettings-apiKey');
                }
              }
            }
          };
          break;
      }

      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      return this.outputFormatting(validationData, outputFormat);
    }

  });

  _exports.default = _default;
});