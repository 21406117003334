define("securitytrax-ember-ui/helpers/has-grants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Allows checking a single grant, or multiple, separated by space
   * @param {*} params
   */
  var _default = Ember.Helper.extend({
    grants: Ember.inject.service(),
    grantsChanged: Ember.observer('grants.revision', function () {
      this.recompute();
    }),

    compute(params
    /*, hash*/
    ) {
      // handle the case where nothing is passed to has-grants helper, which should be never.
      if (!params[0]) {
        return true;
      }

      return params.every(individualGrant => {
        return individualGrant.split(',').every(grant => {
          // Handle case where grants passed in as csv
          return this.grants.getIndividualGrant(grant);
        });
      });
    }

  });

  _exports.default = _default;
});