define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/interactive-services-providers/adc/import/import-users/import-options/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/interactive-services-providers/adc/import/import-users/import-users-base", "lodash"], function (_exports, _emberConcurrency, _importUsersBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { set } from '@ember/object';
  var _default = _importUsersBase.default.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    tagsService: Ember.inject.service(),
    ispService: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData("importOptions", 'buttons', [{
        text: this.intl.t('common.actions.back'),
        classNamesString: 'st-btn st-btn-secondary',
        click: () => {
          multiStepForm.currentStepNameKeySetVal('filterOptions');
        }
      }, {
        text: this.intl.t('pages.administration.integrations.interactiveServicesProviders.imports.customerImport.runImport'),
        click: buttonPromise => {
          return this.doImportTask.perform(buttonPromise, multiStepForm);
        },
        classNamesString: 'st-btn st-btn-primary'
      }]);
    },

    doImportTask: (0, _emberConcurrency.task)(function* (buttonPromise, multiStepForm) {
      var groupId = _lodash.default.get(this.importAdcUsersJson, 'group.id', null);

      var formattedObjects = [];
      formattedObjects.pushObject(Ember.Object.create({
        model: {
          permissionGroup: groupId
        },
        filePath: 'interactive-services-provider/adc-import/adc-user-import'
      }));
      yield multiStepForm.validateList.perform(formattedObjects);
      var tagIdsArr = this.importAdcUsersJson.userTags.map(currTag => {
        return {
          id: +currTag.id
        };
      });
      var userImportAttributes = {
        active: this.importAdcUsersJson.Active,
        can_login: this.importAdcUsersJson.CanLogin,
        tag_ids: this.importAdcUsersJson.userTags.map(tag => {
          return {
            id: +tag.id
          };
        }),
        filters: {
          support_id: this.importAdcUsersJson.supportIds.split(',').map(currId => {
            return {
              id: +currId
            };
          }),
          role_id: this.importAdcUsersJson.roleIds.map(currRole => {
            return {
              id: currRole.value
            };
          }),
          rep_id: this.importAdcUsersJson.subDealerIds.map(currSubDealer => {
            return {
              id: currSubDealer.value
            };
          })
        }
      };

      if (groupId) {
        Ember.set(userImportAttributes, 'pemrissions_group_id', groupId);
      }

      if (tagIdsArr.length > 0) {
        Ember.set(userImportAttributes, 'tag_ids', tagIdsArr);
      }

      var requestData = {
        action: 'alarm_dot_com__importUsers',
        model: this.interactiveServicesProvider,
        attributes: userImportAttributes
      };

      try {
        yield this.ispService.get('invokeIspActionTask').perform(requestData);
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err);
        throw err;
      }

      buttonPromise.then(() => {
        multiStepForm.currentStepNameKeySetVal('importSummary');
      });
    }),
    permissionsGroups: Ember.computed(function () {
      return this.store.findAll('group');
    }),
    userTagsOptions: Ember.computed(function () {
      return this.get('tagsService.getTagsByObjectType').perform('user');
    })
  });

  _exports.default = _default;
});