define("securitytrax-ember-ui/components/pages/users/user-roles/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uT1Pi+Cl",
    "block": "{\"symbols\":[\"data\",\"multiStepForm\",\"multiStepForm\"],\"statements\":[[8,\"html/st-await\",[],[[\"@useSpinner\",\"@promises\"],[true,[30,[36,9],null,[[\"isTech\",\"isSalesRep\"],[[35,8],[35,7]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,12],[[30,[36,11],[[32,1,[\"resolvedPromises\",\"isTech\"]],[30,[36,10],[\"user_tech_settings.view\"],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,4],null,[[\"formSteps\",\"formOriginalModels\",\"formStarted\"],[[35,6],[35,5],true]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,1],[[32,3,[\"currentStepData\",\"stepComponentPath\"]]],[[\"multiStepForm\",\"class\",\"user\"],[[32,3],\"user-tech-settings-form\",[35,0,[\"user\"]]]]]],[2,\"\\n\"]],\"parameters\":[3]}]]]],\"parameters\":[]}]]],[6,[37,12],[[30,[36,11],[[32,1,[\"resolvedPromises\",\"isSalesRep\"]],[30,[36,10],[\"user_rep_numbers.view\"],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,4],null,[[\"formSteps\",\"formOriginalModels\",\"formStarted\"],[[35,3],[35,2],true]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,1],[[32,2,[\"currentStepData\",\"stepComponentPath\"]]],[[\"multiStepForm\",\"class\",\"user\"],[[32,2],\"user-rep-numbers-form\",[35,0,[\"user\"]]]]]],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"component\",\"formOriginalModels\",\"repNumberSteps\",\"multi-step-form\",\"techSettingsFormOriginalModels\",\"techSettingsSteps\",\"isSalesRep\",\"isTech\",\"hash\",\"has-grants\",\"and\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/users/user-roles/template.hbs"
    }
  });

  _exports.default = _default;
});