define("securitytrax-ember-ui/models/calendar-event-detail-type", ["exports", "securitytrax-ember-ui/models/base_models/calendar-event-detail-type", "moment"], function (_exports, _calendarEventDetailType, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _calendarEventDetailType.default.extend({
    // appointmentStyleColor: computed('color', 'calendarEventType.color', function () {
    //     const color = this.get('color');
    //     const appointmentColor = this.get('calendarEventType.color');
    //     return htmlSafe(`
    //         background-color: ${color};
    //         border-color: ${appointmentColor};`
    //     );
    // }),
    styleColor: Ember.computed('color', function () {
      return Ember.String.htmlSafe("background-color: ".concat(this.color));
    }),
    durationDisplay: Ember.computed('duration', function () {
      var durationMoment = _moment.default.duration().add(this.duration, 'seconds');

      return "".concat(durationMoment.hours(), " H ").concat(durationMoment.minutes(), " M");
    }),
    calculatedStyleColor: Ember.computed('calculatedColor', function () {
      return Ember.String.htmlSafe("background-color: ".concat(this.calculatedColor, ";"));
    }),
    calculatedColor: Ember.computed('color', 'calendarEventType.calculatedColor', function () {
      var color = this.color;

      if (color) {
        return color;
      }

      var calendarEventColor = this.get('calendarEventType.calculatedColor');

      if (calendarEventColor) {
        return calendarEventColor;
      }

      return false;
    }),
    displayName: Ember.computed('calendarEventType.name', 'name', function () {
      return this.get('calendarEventType.name') + ' - ' + this.name;
    })
  });

  _exports.default = _default;
});