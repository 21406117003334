define("securitytrax-ember-ui/home/at-risk/edit-at-risk/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NPTXFSh7",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"customerAtRiskId\",\"parentRoute\"],[[35,0,[\"atRiskId\"]],[35,0,[\"parentRoute\"]]]]]]],\"hasEval\":false,\"upvars\":[\"model\",\"modals/sub-route-modals/edit-create-at-risks\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/home/at-risk/edit-at-risk/template.hbs"
    }
  });

  _exports.default = _default;
});