define("securitytrax-ember-ui/components/nav/top-nav/nav/main-nav-middle/component", ["exports", "securitytrax-ember-ui/components/nav/top-nav/nav/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    screen: Ember.inject.service(),
    tagName: ''
  });

  _exports.default = _default;
});