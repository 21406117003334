define("securitytrax-ember-ui/services/store", ["exports", "@ember-data/store", "ember-concurrency", "lodash", "securitytrax-ember-ui/config/environment"], function (_exports, _store, _emberConcurrency, _lodash, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _store.default.extend({
    stDataCaching: Ember.inject.service(),
    session: Ember.inject.service(),
    stMulticallService: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.set('findAllMeta', {});
    },

    loadOrReloadModelHasMany(data) {
      var hasManyData = data.model.hasMany(data.relName);
      var reloadPromise;

      if (hasManyData.hasManyRelationship.relationshipIsEmpty) {
        reloadPromise = hasManyData.load();
      } else {
        reloadPromise = hasManyData.reload();
      }

      return reloadPromise;
    },

    getAttributeKeysByModelName(modelName, attributesToIgnore = {}) {
      var newModel = this.createRecord(modelName);
      var attrString = '';
      newModel.eachAttribute(attr => {
        if (!attr.endsWith('Meta') && !attributesToIgnore[attr]) {
          attrString += "".concat(attr, ",");
        }
      });
      newModel.deleteRecord();
      return attrString.slice(0, -1);
    },

    getRelationshipKeysByModelName(modelName, relationshipsToIgnore = {}) {
      var newModel = this.createRecord(modelName);
      var relationshipString = '';
      newModel.eachRelationship((relName, meta) => {
        if (meta.kind === 'belongsTo' && !relationshipsToIgnore[relName]) {
          relationshipString += "".concat(relName, ",");
        }
      });
      newModel.deleteRecord();
      return relationshipString.slice(0, -1);
    },

    getFullComputedWatcherStringByModelName(modelName, extraOptions = {}) {
      var mergedExtraOptions = _lodash.default.merge({
        prefix: '',
        actualVariableName: '',
        attributesToIgnore: {},
        relationshipsToIgnore: {}
      }, extraOptions);

      var prefixActualVariableName = modelName;

      if (mergedExtraOptions.actualVariableName) {
        prefixActualVariableName = mergedExtraOptions.actualVariableName;
      }

      return "".concat(mergedExtraOptions.prefix).concat(prefixActualVariableName, ".{").concat(this.getAttributeKeysByModelName(modelName, mergedExtraOptions.attributesToIgnore), ",").concat(this.getRelationshipKeysByModelName(modelName, mergedExtraOptions.relationshipsToIgnore), "}");
    },

    query(modelName, params, extraOptions) {
      // window.localStorage.clear();
      // window.localStorage.setItem('stDataCaching_cacheAllQueries', true);
      var mergedExtraOptions = _lodash.default.merge({
        cacheResponse: false,
        cacheForMilliseconds: 0,
        // 0 means don't limit
        cacheForNumAccessed: 0,
        // 0 means don't limit
        forceCacheReload: false,
        useLocalStorage: false,
        adapterOptions: {
          multicallKey: null
        }
      }, extraOptions); // if (window.localStorage.getItem('stDataCaching_cacheAllQueries')) {
      //     mergedExtraOptions = lodash.merge({
      //         cacheResponse: true,
      //         cacheForMilliseconds: 300000,
      //         cacheForNumAccessed: 0,
      //         forceCacheReload: false,
      //         useLocalStorage: true,
      //         adapterOptions: {
      //             multicallKey: null
      //         } 
      //     }, extraOptions);
      // }
      // default query options


      var queryParamsMerged = _lodash.default.merge({
        limit: 0
      }, params); // alphabetize option keys so can be used as a cache key


      var compiledQueryParams = {};
      (0, _lodash.default)(queryParamsMerged).keys().sort().each(function (key) {
        compiledQueryParams[key] = queryParamsMerged[key];
      }); // get the query params string

      var qp = Object.keys(compiledQueryParams).map(key => "".concat(key, "=").concat(compiledQueryParams[key])).join('&'); //////////////////////////////////////////////////////////////////////
      // check to see if the data is cached already or need to be cleared

      var cacheKey = "query_cache_".concat(modelName, "_").concat(qp);

      if (mergedExtraOptions.forceCacheReload) {
        this.stDataCaching.clearCache(cacheKey, mergedExtraOptions);
      }

      var cachedData = false;

      if (mergedExtraOptions.cacheResponse) {
        cachedData = this.stDataCaching.getCache(cacheKey, mergedExtraOptions);

        if (cachedData) {
          // console.log('using cache!! 2', cachedData);
          return cachedData;
        }
      }

      var queryData = this._super(modelName, params, mergedExtraOptions);

      if (mergedExtraOptions.cacheResponse) {
        this.stDataCaching.setCache(cacheKey, queryData, mergedExtraOptions);
      }

      return queryData;
    },

    /**
     * findAll ask the adapter's findAll method to find the records for the
     * given type, and return a promise that will be resolved once the server
     * returns the values. The promise will resolve into all records of this
     * type present in the store, even if the server only returns a subset of
     * them.
     *
     * @param {*} modelName
     * @param {*} options
     */
    findAll(modelName, options) {
      options;
      var findAllMeta = this.findAllMeta || {};
      var modelNameCamelized = Ember.String.camelize(modelName);
      findAllMeta[modelNameCamelized] = findAllMeta[modelNameCamelized] || {};

      if (findAllMeta[modelNameCamelized]['accessed']) {
        // check to see if the findRecord will be cached and this request is also a multicall so need to put cached data into multicall
        var multicallOptions = options ? _lodash.default.get(options, 'adapterOptions.multicallOptions', null) : null; // put the found data into the result set for multi if this is a multicall

        if (_environment.default['ember-cli-mirage']['enabled'] === false && multicallOptions) {
          // adding data
          this.stMulticallService.getMulticallData(multicallOptions, {
            url: '',
            verb: '',
            body: {}
          }, this.peekAll(modelName, options));
        }
      }

      var data = this._super(...arguments);

      findAllMeta[modelNameCamelized]['accessed'] = true;
      return data;
    },

    setFindAllAccessedWithArray(modelNames) {
      var findAllMeta = this.findAllMeta || {};
      Object.keys(modelNames).forEach(modelName => {
        findAllMeta[modelName] = findAllMeta[modelName] || {};
        findAllMeta[modelName]['accessed'] = modelNames[modelName];
      });
    },

    /**
     *
     * @param {*} modelName
     */
    // DREW TODO - possible need to get rid of this after the ember upgrade
    // unloadAll(modelName) {
    //     const data = this._super(...arguments);
    //     if (isEmpty(modelName)) {
    //         this.set('findAllMeta', {});
    //     } else {
    //         const findAllMeta = this.get('findAllMeta') || {};
    //         delete findAllMeta[modelName];
    //     }
    //     return data;
    // },

    /**
     *
     * @param {*} owner
     * @param {*} link
     * @param {*} relationship
     */
    findBelongsTo(owner, link, relationship) {
      var promise = this._super(owner, link, relationship);

      promise.then(() => {
        var relation = owner._record.belongsTo(relationship.key).belongsToRelationship;

        if (relation.linkPromise) {
          relation.linkPromise.then(function (internalModel) {
            /**
             * these 3 are potentially dangerous, but this behavior seems to align better with documentation
             * https://github.com/emberjs/data/blob/v3.4.4/addon/-private/system/relationships/state/relationship.js
             */
            relation.setHasAnyRelationshipData(true);
            relation.setRelationshipIsStale(true);
            relation.addCanonicalInternalModels(internalModel);

            owner._record.trigger('findBelongsTo', relationship);
          });
        }
      }, () => {}).catch(() => {// move along
      });
      return promise;
    },

    /**
     * This ensure that reloads that only send back links for relationships
     * are updated with the model as well
     *
     * @param {*} model
     */
    hardReload(model) {
      model.unloadRecord();
      return this.findRecord(model._internalModel.modelName, model.id);
    },

    /**
     * Unload record from store while also destroying reference to the id
     *
     * @param {*} model
     */
    forceUnloadRecord(model) {
      return this.forceUnloadRecordTask.perform(model);
    },

    forceUnloadRecordTask: (0, _emberConcurrency.task)(function* (model) {
      // disconnect relations from the other side first
      // (only disconnects 1 to 1 relations for now)
      var relations = [];
      model.constructor.eachRelationship((relName, meta) => {
        relations.push({
          relName: relName,
          meta: meta
        });
      });

      for (var i = 0; i < relations.length; i++) {
        var {
          relName,
          meta
        } = relations[i];
        relName;

        if (meta.isRelationship && meta.kind == 'belongsTo') {
          yield* function* () {
            var currRel = yield model.get(meta.key);
            var childRelations = [];
            currRel.constructor.eachRelationship((parentRelName, parentMeta) => {
              childRelations.push({
                parentRelName: parentRelName,
                parentMeta: parentMeta
              });
            });

            for (var q = 0; q < childRelations.length; q++) {
              var {
                parentRelName,
                parentMeta
              } = childRelations[q];
              parentRelName;

              if (parentMeta.isRelationship && parentMeta.kind == 'belongsTo' && parentMeta.type == model.constructor.modelName.dasherize()) {
                var childRelAgain = yield currRel.get(parentMeta.key);

                if (childRelAgain.get('id') == model.get('id')) {
                  currRel.set(parentMeta.key, null);
                }
              }
            }
          }();
        }
      }

      var id = model.get('id');
      var type = model.constructor.modelName.dasherize();
      model.unloadRecord();
      delete this._identityMap._map[type]._idToModel[id];
    }),

    /**
     *
     * @param {*} modelName
     * @param {*} id
     * @param {*} options
     */
    findRecord(modelName, id, options) {
      // check to see if the findRecord will be cached and this request is also a multicall so need to put cached data into multicall
      var peekRecordData = this.peekRecord(modelName, id);

      if (peekRecordData) {
        var multicallOptions = _lodash.default.get(options, 'adapterOptions.multicallOptions', null); // put the found data into the result set for multi if this is a multicall


        if (_environment.default['environment'] != 'test' && multicallOptions) {
          // adding data
          this.stMulticallService.getMulticallData(multicallOptions, {
            url: '',
            verb: '',
            body: {}
          }, peekRecordData);
        }
      } // Because ember is stupid in prod: https://github.com/emberjs/data/issues/5958


      if (id === undefined || id === null || !['number', 'string'].includes(typeof id) || ('' + id).length === 0) {
        return new Promise((resolve, reject) => {
          reject(new Error('Assertion Failed: `id` passed to `findRecord()` has to be non-empty string or number'));
        });
      }

      var promise = this._super(modelName, id, options);

      return promise;
    },

    /**
     * peekRecord but return promise
     *
     * @param {*} modelName
     * @param {*} id
     */
    peekRecordAsPromise(modelName, id) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        if (this.peekRecord(modelName, id)) {
          resolve(this.peekRecord(modelName, id));
        } else {
          reject();
        }
      });
    },

    /**
     * findRecord but attempt a peek first preventing a reload
     *
     * @param {*} modelName
     * @param {*} id
     * @param {*} options
     */
    peekThenFindRecord(modelName, id, options) {
      var peekRecordData = this.peekRecord(modelName, id);

      if (peekRecordData) {
        var multicallOptions = _lodash.default.get(options, 'adapterOptions.multicallOptions', null); // put the found data into the result set for multi if this is a multicall


        if (_environment.default['environment'] != 'test' && multicallOptions) {
          this.stMulticallService.getMulticallData(multicallOptions, {
            url: '',
            verb: '',
            body: {}
          }, peekRecordData);
        }

        return this.peekRecordAsPromise(modelName, id);
      } else {
        return this.findRecord(modelName, id, options);
      }
    },

    /**
     * get all DS.Model types
     */
    getAllModelTypes() {
      return Ember.getOwner(this).lookup('data-adapter:main').getModelTypes();
    },

    /**
     * get all DS.Model names
     */
    getAllModelTypeNames() {
      return Ember.getOwner(this).lookup('data-adapter:main').getModelTypes().map(type => type.name);
    },

    findByGuid(type, guid) {
      return this.peekAll(type).find(function (item) {
        return Ember.guidFor(item) == guid;
      });
    },

    findByClientId(type, clientId) {
      return this.peekAll(type).find(function (item) {
        return item._internalModel.clientId == clientId;
      });
    }

  });

  _exports.default = _default;
});