define("securitytrax-ember-ui/helpers/starts-with", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.startsWith = startsWith;
  _exports.default = void 0;

  function startsWith([originalString, searchString, position]) {
    position = position || 0;

    if (typeof originalString !== 'string' || typeof searchString !== 'string') {
      (false && Ember.warn('originalString and searchString are expected to be strings', {
        id: 'helpers/starts-with'
      }));
      return false;
    }

    return originalString.substr(position, searchString.length) === searchString;
  }

  var _default = Ember.Helper.helper(startsWith);

  _exports.default = _default;
});