define("securitytrax-ember-ui/helpers/st-time-epoch", ["exports", "securitytrax-ember-ui/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.checkStEpoch = checkStEpoch;
  _exports.default = void 0;

  function checkStEpoch(preEpochMoment, postEpochMoment) {
    return _environment.default['securitytrax-ember-ui']['st-time-epoch'] && _environment.default['securitytrax-ember-ui']['st-time-epoch'] > preEpochMoment ? preEpochMoment : postEpochMoment;
  }

  var _default = Ember.Helper.helper(checkStEpoch);

  _exports.default = _default;
});