define("securitytrax-ember-ui/components/multi-step-form/forms/administration/payables/line-item-details/line-item-calculation-options/flat-rate/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['cell small-12 medium-4 large-3']
  });

  _exports.default = _default;
});