define("securitytrax-ember-ui/mixins/credit-card-mixin", ["exports", "ember-credit-cards/utils/cards"], function (_exports, _cards) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    creditCardTypes: Ember.inject.service(),
    usStates: Ember.inject.service(),
    months: Ember.inject.service(),
    monthsList: Ember.computed.alias('months.monthOptions'),
    expirationYearUsesFullYear: false,
    creditCardExpirationYears: Ember.computed('expirationYear', function () {
      var d = new Date(),
          currentYear = d.getFullYear(),
          years = [],
          billCcExpirationYear = this.expirationYear;

      if (!Ember.isEmpty(billCcExpirationYear) && billCcExpirationYear < currentYear) {
        years.push({
          name: billCcExpirationYear,
          value: billCcExpirationYear
        });
      }

      for (var i = currentYear; i <= currentYear + 10; i++) {
        years.push({
          name: i.toString(),
          value: this.expirationYearUsesFullYear ? i.toString() : i.toString().substring(2, 4)
        });
      }

      return years;
    }),
    expirationMonthYear: Ember.computed('billing.{billCcExpirationMonth,billCcExpirationYear}', function () {
      var month = this.billing.billCcExpirationMonth;
      var year = this.billing.billCcExpirationYear;
      var monthYear = month && year ? month.concat(year) : null;
      this.set('billing.billCcExpiration', monthYear);
      return monthYear;
    }),
    cardType: Ember.computed('billing.billCcNumber', function () {
      if (!this.get('isNewRecord')) {
        return this.get('billing.billCcType').toLowerCase();
      }

      var number = this.get('billing.billCcNumber');

      var card = _cards.default.fromNumber(number);

      if (card) {
        var billCcType = this.creditCardTypes.cardTypes[card.type];
        this.set('billing.billCcType', billCcType);
        return card.type;
      }

      return 'no-card-type';
    })
  });

  _exports.default = _default;
});