define("securitytrax-ember-ui/components/html/st-button/component", ["exports", "securitytrax-ember-ui/utils/debug", "jquery", "securitytrax-ember-ui/utils/obj-helpers", "uuid", "lodash"], function (_exports, _debug, _jquery, _objHelpers, _uuid, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Mapping of input sizes to partial class names.
   *
   * @private
   * @type {Object<String, String>}
   * @memberof Button
   */
  var sizeMap = {
    'x-small': 'x-sm',
    'small': 'sm',
    'large': 'lg',
    'x-large': 'x-lg'
  };
  /**
   * Possible defined promise states.
   *
   * @type {string}
   * @ignore
   */

  var SUCCESS = 'success',
      PENDING = 'pending',
      ERROR = 'error';
  /**
   * Colors used for asynchronous button states.
   *
   * <strong>Note:</strong> This is done via JS because CSS was unreliable due to the fact that the developer might have more specific selectors.
   *
   * @type {String}
   * @private
   * @ignore
   */

  var STATE_COLORS = {
    [SUCCESS]: '#00A216',
    [ERROR]: '#DB2A17',
    [PENDING]: '' // removed so won't always be blue when pending

  };
  /**
   * Duration of the svg fade animation.
   *
   * @type {number}
   * @private
   * @ignore
   */

  var FADE_ANIMATION_DURATION = 300; // 300

  /**
   * The duration between when the promise is resolved and the success callback method is called.
   *
   * <strong>Note:</strong> this is also the length of time before the button changes from success back to its normal state.
   *
   * @type {number}
   *
   * @private
   * @ignore
   */

  var SUCCESS_CALLBACK_DELAY = 700; // 700

  /**
   * Duration of time before the buttons changes from error state back to its normal state.
   *
   * @type {number}
   *
   * @private
   * @ignore
   */

  var FAILURE_ANIMATION_DURATION = 3000; // 3000

  /**
   * Observes the passed promise to animate the button.
   *
   * @param {Promise} promise The promise to observe.
   *
   * @private
   * @instance
   * @memberof components.Button
   */

  function observePromise(promise) {
    var animationDelay = SUCCESS_CALLBACK_DELAY;

    var fnUpdateStateClass = state => {
      // Is the button still active?
      if (this && !this.isDestroyed) {
        // Update promise state class.
        this.set('promiseStateClass', state);
      }
    }; // Update CSS class to pending.


    fnUpdateStateClass(PENDING);
    promise.then(() => {
      // Promise fulfilled.
      fnUpdateStateClass(SUCCESS);
    }).catch(() => {
      // Promise rejected.
      fnUpdateStateClass(ERROR); // Set button animation length to be longer for errors because they are likely staying on the same page.

      animationDelay = FAILURE_ANIMATION_DURATION;
    }).finally(() => {
      // Reset button to original state (undefined) after a certain time period.
      if ((0, _debug.isTestEnvironment)()) {
        fnUpdateStateClass(undefined);
      } else {
        Ember.run.later(() => {
          fnUpdateStateClass(undefined);
        }, animationDelay);
      }
    });
  } //endregion

  /**
   * Button component for use in all ADC applications.
   *
   * @class Button
   * @extends Ember.Component
   * @memberof components
   */


  var Button = Ember.Component.extend(
  /** @lends components.Button# **/
  {
    tagName: 'div',
    btnClass: '',

    /**
     * What ARIA role should be used?
     *
     * @type {String}
     */
    ariaRole: 'button',

    /**
     * Text to be shown inside the button.
     *
     * <strong>Note:</strong> If both text and responsiveText are defined, the responsiveText takes precedence on smaller responsive screens.
     *
     * @type {String|undefined}
     */
    text: undefined,

    /**
     * Is the button disabled?
     *
     * @type {boolean}
     */
    disabled: undefined,
    stAwaitDisabled: undefined,
    classNames: ['st-btn'],
    classNameBindings: ['sizeClass', 'section:btn-section', 'primary:st-btn-primary', 'safe:btn-safe', 'danger:btn-danger', 'icon:btn-icon', 'noBackground:no-bg', 'iconOnly', 'promiseStateClass', 'selected', 'hasAdditionalClickActions:drop-down-click-actions'],
    attributeBindings: ['ariaRole:role', 'ariaLabel:aria-label', 'disabledString:aria-disabled', 'disabledBoolean:disabled', 'type', 'title'],
    loadedNamespace: undefined,
    isSaving: undefined,
    buttonUuid: undefined,
    stAwaitDisableSources: undefined,
    hideOnDisabled: undefined,
    stAwait: Ember.inject.service('st-await'),

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        loadedNamespace: 'global',
        disabled: false,
        stAwaitDisabled: false,
        isSaving: false,
        buttonUuid: (0, _uuid.v4)(),
        hideOnDisabled: false,
        stAwaitDisableSources: {
          'save': true,
          'stAwait': true
        }
      }); /////////////////////////////////////////////////////////////////////////
      // see if we should disable the button because of fully loaded logic

      var stAwait = this.stAwait;
      var fullyLoadedState = stAwait.getCurrentFullyLoadedStateByNamespace({
        loadedNamespace: this.loadedNamespace
      });

      this._setStAwaitDisabled(fullyLoadedState);

      stAwait.on('st-await-loaded-state-by-namespace-' + this.loadedNamespace, this, '_onLoadedStateChange');
    },

    willDestroyElement() {
      this._super(...arguments);

      this.stAwait.off('st-await-loaded-state-by-namespace-' + this.loadedNamespace, this, '_onLoadedStateChange');

      if (this.isSaving) {
        // if the button being destroyed is currently saving then need to enable everything - just in case
        this._setIsSaving(false);

        this.set('stAwaitDisabled', false);
      }
    },

    _onLoadedStateChange(fullyLoadedState) {
      // only disable and enable if this is not the button that caused the disable (ie isSaving)
      if (!this.isSaving) {
        Ember.run.next(() => {
          if (this && !this.isDestroyed) {
            this._setStAwaitDisabled(fullyLoadedState);
          }
        });
      }
    },

    _setStAwaitDisabled(fullyLoadedState) {
      var disableValue = !fullyLoadedState.fullyLoaded;
      var waitingSource = fullyLoadedState.waitingSource; // only disable if allowed to disable based on this buttons settings - always enable just in case

      if (disableValue === true && _lodash.default.get(this, 'stAwaitDisableSources.' + waitingSource, false)) {
        this.set('stAwaitDisabled', true);
      } else if (disableValue === false) {
        this.set('stAwaitDisabled', false);
      }
    },

    _setIsSaving(isSaving) {
      try {
        if (this && !this.isDestroyed) {
          this.set('isSaving', isSaving);
        }
      } catch (e) {
        /* this is ok */
      }

      this.stAwait.setSavedState(this.buttonUuid, {
        loadedNamespace: this.loadedNamespace,
        isSaving: isSaving
      });
    },

    didReceiveAttrs() {
      // Ensure they are using on-click attribute instead of click.
      // The async button needs to call the built-in Ember click() method first. This allows the button to pass in the delayedHandler()
      // method to the async task, which allows the promise to complete after the button's animation completes.. This could be changed if click()
      // could be overridden, but currently we do not know a way.
      var iconOnly = this.getAttr('iconOnly'),
          text = this.getAttr('text'); // Text should always be defined for an iconOnly button because it is used for accessibility purposes.

      if (iconOnly && !(text && text.length)) {
        (false && Ember.warn('An "iconOnly" button should have the text property defined.', {
          id: 'html.stButton.didReceiveAttrs'
        }));
      }

      return this._super(...arguments);
    },

    /**
     * String version of the disabled property
     * Ember v2.7.0 had a regression where boolean attributeBindings are not outputted properly
     *   see - https://github.com/emberjs/ember.js/issues/14024
     * If fixed, we can remove this and update attributeBindings
     *
     * @function
     * @returns {String}
     */
    disabledString: Ember.computed('disabled', 'stAwaitDisabled', function () {
      // Convert any falsey values to false
      return String(!!(this.disabled || this.stAwaitDisabled));
    }),
    disabledBoolean: Ember.computed('disabled', 'stAwaitDisabled', function () {
      return this.disabled || this.stAwaitDisabled;
    }),
    disabledCssClass: Ember.computed('disabled', 'stAwaitDisabled', function () {
      var cssClass = '';

      if (this.disabled || this.stAwaitDisabled) {
        cssClass = 'disabled';
      }

      return cssClass;
    }),

    /**
     * Should the btn-section class be applied?
     *
     * @type {boolean}
     */
    section: false,

    /**
     * Should the btn-primary class be applied?
     *
     * @type {boolean}
     */
    primary: false,

    /**
     * Should the btn-safe class be applied?
     *
     * @type {boolean}
     */
    safe: false,

    /**
     * Should the btn-danger class be applied?
     *
     * @type {boolean}
     */
    danger: false,

    /**
     * Button size
     *
     * @type {String|undefined}
     */
    size: undefined,

    /**
     * Determines if the button is selected (i.e. its state is "on").
     *
     * @type {boolean}
     */
    selected: false,

    /**
     * Location for error tooltip.
     *
     * <strong>Note:</strong> Overrides HasErrorTooltip property.
     *
     * @override
     * @type {String}
     */
    errorTooltipPlace: 'bottom',

    /**
     * Returns the class size to be applied based on the passed in size.
     *
     * @function
     * @returns {String}
     */
    sizeClass: Ember.computed('size', function () {
      var classSize = sizeMap[this.size];
      return classSize && "btn-".concat(classSize) || '';
    }).readOnly(),

    /**
     * Title of the button.
     *
     * Note: Appears as a tooltip.
     *
     * @function
     * @returns {String|undefined}
     */
    title: Ember.computed('iconOnly', 'text', function () {
      var {
        iconOnly,
        text
      } = this;
      return iconOnly ? text : undefined;
    }),
    type: null,
    //endregion
    //region Icon Button

    /**
     * More descriptive button text to be displayed when responsive buttons take up the full width of the screen.
     *
     * @type {String|undefined}
     */
    responsiveText: undefined,

    /**
     * Determines the ARIA label to use for accessibility purposes.
     *
     * @function
     * @returns {String}
     */
    ariaLabel: Ember.computed('iconOnly', 'text', 'responsiveText', function () {
      var {
        iconOnly,
        text,
        responsiveText
      } = this;
      return iconOnly ? text : responsiveText;
    }),

    /**
     * Name of the icon to be displayed within the button.
     *
     * @type {String|undefined}
     */
    icon: undefined,

    /**
     * Determines if only an icon should be displayed or if text can accompany the icon in the button.
     *
     * Note: This allows developers to specify text for accessibility purposes without having it appear in the button.
     *
     * @type {boolean}
     */
    iconOnly: false,

    /**
     * Should the background of the button be transparent?
     *
     * Note: this is ignored if there is text specified for the button.
     *
     * @type {boolean}
     */
    noBackground: false,
    //endregion
    //region Asynchronous Button

    /**
     * String representation of the state of the promise in an async task.
     *
     * The promise can be in one of the following states:
     *
     * <ul>
     *     <li><strong>Settled</strong>: not waiting for async task (i.e. undefined)</li>
     *     <li><strong>Pending</strong>: waiting for fulfilled or rejected status</li>
     *     <li><strong>Fulfilled</strong>: the async task succeeded</li>
     *     <li><strong>Rejected</strong>: the async task failed</li>
     * </ul>
     *
     * @type {String|undefined}
     */
    promiseStateClass: undefined,

    /**
     * Is the promise in pending state?
     *
     * <strong>Note:</strong> This property is used in the handlebars to show the spinning svg icon.
     *
     * @function
     * @returns {boolean}
     */
    isPromisePending: Ember.computed('promiseStateClass', function () {
      return this.promiseStateClass === PENDING;
    }),

    /**
     * Is the promise in a fulfilled/success state?
     *
     * <strong>Note:</strong> This property is used in the handlebars to show the check svg icon.
     *
     * @function
     * @returns {boolean}
     */
    isPromiseFulfilled: Ember.computed('promiseStateClass', function () {
      return this.promiseStateClass === SUCCESS;
    }),

    /**
     * Is the promise in a rejected/error state?
     *
     * <strong>Note:</strong> This property is used in the handlebars to show the exclamation svg icon.
     *
     * @function
     * @returns {boolean}
     */
    isPromiseRejected: Ember.computed('promiseStateClass', function () {
      return this.promiseStateClass === ERROR;
    }),

    /**
     * Returns the button color based on promise state.
     *
     * @function
     * @returns {String}
     */
    asyncButtonColor: Ember.computed('promiseStateClass', function () {
      var color;

      if (!this.noFeedback) {
        color = STATE_COLORS[this.promiseStateClass];
      }

      return color ? Ember.String.htmlSafe("background-color: ".concat(color, ";")) : undefined;
    }),

    /**
     * Animates the button to show the new promise state.
     *
     * @function
     */
    didUpdate() {
      (0, _jquery.default)('svg.btn-async-icon').animate({
        opacity: 0
      }, FADE_ANIMATION_DURATION);
      return this._super(...arguments);
    },

    positionOptions: 'down left',
    additionalClickActions: null,
    hasAdditionalClickActions: Ember.computed('additionalClickActions', function () {
      return !!this.additionalClickActions;
    }),
    clickActionsOpen: false,
    actions: {
      /**
       * Called when the button component is clicked.
       *
       * Checks the attributes to see if there is an on-click method that should be called. If there is an on-click method, it calls that
       * method with a callback helper as an argument (linkPromise) to allow our button to know when the promise has been settled.
       * The on-click method (if asynchronous) should define a promise and call the helper method passed to link the promise to the button.
       */
      clickButton(clickAction, ...args) {
        var {
          disabled,
          stAwaitDisabled,
          readonly,
          promiseStateClass
        } = this; // Is the button disabled, readonly or in the middle of an async task?

        if (disabled || stAwaitDisabled || readonly || promiseStateClass !== undefined) {
          // Do nothing, but return false to stop action bubbling.
          return false;
        } // Was an on-click method defined in the handlebars?


        var onClickMethod = clickAction;

        if (typeof onClickMethod === 'function') {
          // set this button to saving so it doesn't disable itself below
          this._setIsSaving(true);

          var delayedResolver; // This resolves the delayed promise after the animation finishes.

          var delayedHandler = new Ember.RSVP.Promise(resolve => {
            delayedResolver = resolve;
          }, 'Delay handler for the st-button component'); // The button's action should return a promise, so we can animate the button's state.

          var promise = onClickMethod(delayedHandler, args); // Is the returned value a promise?

          if (promise && promise.then) {
            // Observe the promise in order to animate the button.
            if (!(0, _debug.isTestEnvironment)()) {
              observePromise.call(this, promise);
            } // Animation finished.


            promise.then(() => {
              // Resolve the delayed promise, in case the developer defined their own actions to be taken.
              Ember.run.later(() => {
                delayedResolver();

                this._setIsSaving(false);
              }, SUCCESS_CALLBACK_DELAY); // TODO: This promise does occasionaly fail. Not sure if it is related to other rejections that are okay - validations errors
              // it is being filtered in the /app/app.js file
            }, {}, 'ST Button').catch(() =>
            /*err*/
            {
              // catching promise failures so errors don't show in console from the button
              // errors will still show up if not caught in the action handler
              this._setIsSaving(false);
            });
          } else {
            // need to resolve either way
            delayedResolver();

            this._setIsSaving(false);
          }
        }
      },

      toggleAdditionalActionsOpen() {
        this.toggleProperty('clickActionsOpen');

        if (this.onClickAdditionalActionsButton) {
          this.onClickAdditionalActionsButton();
        }
      }

    }
  });
  var _default = Button;
  _exports.default = _default;
});