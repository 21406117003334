define("securitytrax-ember-ui/components/multi-step-form/forms/system/adt/shared/personal-guarantee-address/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    usStates: Ember.inject.service(),
    sessionData: Ember.inject.service()
  });

  _exports.default = _default;
});