define("securitytrax-ember-ui/models/customer-file", ["exports", "securitytrax-ember-ui/models/base_models/customer-file"], function (_exports, _customerFile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerFile.default.extend({
    iconName: Ember.computed('createdAt', function () {
      //eslint-disable-line no-undef
      var icon = 'icn-file';
      var recentlyUploaded = moment(this.createdAt).isSame(moment(), 'day'); //eslint-disable-line no-undef

      if (recentlyUploaded) {
        icon = 'icn-circle-check-blue';
      }

      return icon;
    })
  });

  _exports.default = _default;
});