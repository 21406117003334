define("securitytrax-ember-ui/components/multi-step-form/forms/customer-equipment/customer-equipment-base", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "securitytrax-ember-ui/mixins/set-user", "ember-concurrency", "securitytrax-ember-ui/mixins/equipment-sold-by-options", "securitytrax-ember-ui/classes/user-defined-fields"], function (_exports, _switchableFormStep, _setUser, _emberConcurrency, _equipmentSoldByOptions, _userDefinedFields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, _setUser.default, _equipmentSoldByOptions.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    techToday: Ember.inject.service('events/tech-today'),
    userService: Ember.inject.service(),
    confirmDeleteModalOpen: false,
    confirmUnlinkModalOpen: false,
    newLineItems: null,
    workOrderAction: null,
    initBaseData: (0, _emberConcurrency.task)(function* () {
      this.UdfClass = new _userDefinedFields.default({
        store: this.store,
        intl: this.intl,
        model: !this.isNewRecord ? this.multiStepForm.formOriginalModelsGetVal('customerEquipment.model') : null
      });
      var hashedData = yield (0, _emberConcurrency.hash)({
        finishedCopyingModels: (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true),
        repUser: this.customer.get('repUser'),
        UdfClassTask: this.UdfClass.populateSectionDataTask.perform('customer_equipment_customer_equipment'),
        soldByList: this.soldByOptions
      });

      if (!this.multiStepForm.formCopiedModelsGetVal('customerEquipment.model.user.id') > 0) {
        if (hashedData.repUser && hashedData.soldByList.findBy('id', hashedData.repUser.get('id'))) {
          yield this.setUser.perform(this.multiStepForm.formCopiedModelsGetVal('customerEquipment.model'), 'user', hashedData.soldByList.findBy('id', hashedData.repUser.get('id')));
        }
      }

      Ember.setProperties(this, hashedData);
    }),
    equipmentStatuses: Ember.computed(function () {
      return this.store.findAll('equipmentStatus');
    }),
    workOrderActions: Ember.computed(function () {
      return this.store.findAll('workOrderAction');
    }),

    didInsertElement() {
      this._super(...arguments);

      this.renderButtonsTask.perform();
    },

    renderButtonsTask: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = this.multiStepForm;
      yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);
      var buttonChoices = [];

      if (this.formMode !== 'view') {
        buttonChoices.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveCustomerEquipment(multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary save'
        });
      }

      if (!this.isNewRecord && this.grants.getIndividualGrant('customer_equipment.delete')) {
        buttonChoices.push({
          text: this.intl.t('common.actions.delete'),
          click: () => {
            this.set('confirmDeleteModalOpen', true);
          },
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
        });
      }

      multiStepForm.registerStepData('addEquipment', 'buttons', buttonChoices);

      if (!this.noAdcIntegration && !this.isNewRecord && !this.multiStepForm.formCopiedModelsGetVal('customerEquipment.model.interactiveServicesId')) {
        buttonChoices.unshift({
          text: this.intl.t('pages.customers.equipment.link'),
          click: () => {
            multiStepForm.currentStepNameKeySetVal('equipmentMatch');
          },
          classNamesString: 'st-btn st-btn-secondary'
        });
      } else if (!this.noAdcIntegration && !this.isNewRecord && this.multiStepForm.formCopiedModelsGetVal('customerEquipment.model.interactiveServicesId')) {
        buttonChoices.unshift({
          text: this.intl.t('pages.customers.equipment.unlink'),
          click: () => {
            this.set('confirmUnlinkModalOpen', true);
          },
          classNamesString: 'st-btn st-btn-secondary'
        });
      }

      multiStepForm.registerStepData('details', 'buttons', buttonChoices);
    }),
    unlinkCustomerEquipmentTask: (0, _emberConcurrency.task)(function* (buttonPromise) {
      try {
        yield this.multiStepForm.formOriginalModelsGetVal('customerEquipment.model').invokeAction('unlink_isp_equipment', {
          verb: 'POST',
          data: {
            data: {}
          }
        });
        buttonPromise.then(() => {
          this.multiStepForm.onAfterSave();
          this.multiStepForm.formStartedSetVal(false);
          this.stNotificationManager.addSuccessKey('pages.customers.equipment.unlinkSuccess');
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
      }
    }),
    deleteCustomerEquipmentTask: (0, _emberConcurrency.task)(function* (buttonPromise) {
      var equipmentId = this.multiStepForm.formCopiedModelsGetVal('customerEquipment.model.id');
      var equipmentToDelete = yield this.store.findRecord('customerEquipment', equipmentId);
      yield equipmentToDelete.destroyRecordWithRollbackTask.perform();
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');

        if (this.afterEquipmentDelete) {
          this.afterEquipmentDelete();
        }

        if (this.workOrder) {
          this.techToday.trigger('refresh-work-order-equipment');
        }
      });
    }),
    createFromManual: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var noValidate = ['group'];

      if (!this.workOrder) {
        noValidate.push('workOrderAction');
      }

      if (!this.monitoringCompanyType || this.monitoringCompanyType.get('id') !== '27') {
        noValidate = noValidate.concat(['zone', 'zoneNumber']);
      }

      var formattedObjects = Ember.A();

      if (this.UdfClass) {
        this.UdfClass.validateUdfs(formattedObjects);
      }

      if (this.isNewRecord) {
        multiStepForm.formCopiedModelsSetVal('customerEquipment.model.quantity', 1);
        multiStepForm.formCopiedModelsSetVal('customerEquipment.model.customer', this.customer);
        multiStepForm.formCopiedModelsSetVal('customerEquipment.model.workOrderAction', this.workOrderAction);
        yield multiStepForm.validateList.perform(formattedObjects);
        var toCreateObjects = yield multiStepForm.validateCreateAll({
          deepValidate: false,
          deepCreate: false,
          notificationNamespace: 'modal',
          extraValidationData: {
            noValidate
          }
        });
        var customerEquipment = this.store.createRecord('customerEquipment', toCreateObjects.customerEquipment.data);
        var savedCustomerEquipment = yield toCreateObjects.customerEquipment.saveWithRollbackAndNotification(customerEquipment);

        if (this.workOrder) {
          yield this.createWorkOrderEquipment.perform(savedCustomerEquipment);
        }
      } else {
        yield multiStepForm.validateList.perform(formattedObjects);
        yield multiStepForm.validateSyncSaveAll({
          deepSave: false,
          extraValidationData: {
            noValidate
          }
        });
      }

      if (this.UdfClass) {
        yield this.UdfClass.saveUdfsTask.perform(this.multiStepForm.formOriginalModelsGetVal('customerEquipment.model'));
      }

      this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
      this.get('customer.customerEquipment').reload();
      buttonPromise.then(() => {
        if (this.workOrder) {
          this.techToday.trigger('refresh-work-order-equipment');
        }
      });
    }),
    createFromManualMultipleTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var formattedObjects = Ember.A();
      var newLineItems = this.newLineItems;
      var user = this.multiStepForm.formCopiedModelsGetVal('customerEquipment.model.user');
      var equipmentStatus = this.defaultEquipmentStatus;
      var workOrder = this.workOrder; // I hate doing this, but done because it's accessed in forEach and `this` isn't defined there.

      var workOrderAction = this.workOrderAction; // I hate doing this, but done because it's accessed in forEach and `this` isn't defined there.
      // Format list of new line-items for validation

      if (newLineItems.length > 1) {
        newLineItems.pop();
      }

      newLineItems.forEach(function (item, index) {
        if (user && user.get('id') !== null) {
          item.set('user', user);
          item.set('workOrderAction', workOrder);
        }

        if (workOrderAction && workOrderAction.get('id') !== null) {
          item.set('workOrderAction', workOrderAction);
        }

        delete item['id'];
        formattedObjects.pushObject(Ember.Object.create({
          modelName: 'customerEquipment',
          model: item,
          extraValidationData: {
            noValidate: workOrder ? ['equipmentStatus', 'zoneNumber', 'zone'] : ['workOrderAction', 'equipmentStatus', 'zoneNumber', 'zone'],
            overrideValidationKey: index + '-customerEquipmentLineItem'
          }
        }));
      });
      var toCreateObjects = yield this.multiStepForm.validateCreateList.perform(formattedObjects, {
        notificationNamespace: 'modal'
      });
      var saveArray = Ember.A();
      toCreateObjects['customerEquipment'].forEach(customerEquipment => {
        for (var i = 0; i < customerEquipment.data.quantity; i++) {
          var newCustomerEquipment = this.store.createRecord('customerEquipment', customerEquipment.data);
          newCustomerEquipment.set('customer', this.customer);
          newCustomerEquipment.set('quantity', 1);
          newCustomerEquipment.set('equipmentStatus', equipmentStatus);
          saveArray.pushObject(customerEquipment.saveWithRollbackAndNotification(newCustomerEquipment));
        }
      });
      var allSaved = yield (0, _emberConcurrency.all)(saveArray);

      if (workOrder) {
        var allWorkOrderEquipmentSaves = [];
        allSaved.forEach(ce => {
          allWorkOrderEquipmentSaves.push(this.createWorkOrderEquipment.perform(ce));
        });
        yield (0, _emberConcurrency.all)(allWorkOrderEquipmentSaves);
      }

      if (buttonPromise) {
        buttonPromise.then(() => {
          this.get('customer.customerEquipment').reload();

          if (this.workOrder) {
            this.techToday.trigger('refresh-work-order-equipment');
          }
        });
      }

      return allSaved;
    }),
    createWorkOrderEquipment: (0, _emberConcurrency.task)(function* (customerEquipment) {
      var workOrderEquipment = this.store.createRecord('workOrderEquipment', {
        workOrder: this.workOrder,
        customerEquipment: customerEquipment,
        workOrderAction: this.workOrderAction
      });
      return yield workOrderEquipment.save();
    })
  });

  _exports.default = _default;
});