define("securitytrax-ember-ui/components/html/svg-components/credit-score-scale-circle/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * credit score scale
   *
   * @class Component
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    tagName: 'g',
    sliderOffset: 0,

    mouseEnter() {
      this.dropdown.actions.open();
    },

    mouseLeave() {
      this.dropdown.actions.close();
    }

  });

  _exports.default = _default;
});