define("securitytrax-ember-ui/components/dashboard-config/canvas/add-dashboard-card/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency"], function (_exports, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    store: Ember.inject.service(),
    homeDashboard: Ember.inject.service('events/home-dashboard'),
    grants: Ember.inject.service(),
    cardFocus: undefined,
    dateRange: undefined,
    addToSection: undefined,
    page: 1,

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      var config = this.editDashboard.availableCards[this.dashboardCard].config;
      Ember.set(this, 'dateRange', this.dateRangeOptions ? this.dateRangeOptions.filter(option => {
        return config.default_date_range === option.relative_date;
      })[0] : null);
      Ember.set(this, 'cardFocus', this.cardFocusOptions ? this.cardFocusOptions[0] : null);
      multiStepForm.registerStepData('step1', 'buttons', [{
        text: this.intl.t('pages.home.add_card'),
        click: buttonPromise => {
          return this.saveCardToConfig.perform(multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary save-dashboard-card'
      }]);
    },

    get cardFocusOptions() {
      return this.editDashboard.getCardFocusOptions(this.dashboardCard, this.grants);
    },

    get dateRangeOptions() {
      var config = this.editDashboard.availableCards[this.dashboardCard].config;
      var allOptions = this.editDashboard.availableRelativeDates;

      if (config.date_range_options === 'all') {
        return allOptions;
      } else if (config.date_range_options !== 'none') {
        return allOptions.filter(option => {
          return config.date_range_options.includes(option.relative_date);
        });
      }

      return null;
    },

    componentToUse: Ember.computed('dashboardCard', function () {
      var cardLocation = this.editDashboard.availableCards[this.dashboardCard].config.cardLocationOverride || this.editDashboard.componentPrefix;
      return cardLocation + (this.editDashboard.availableCards[this.dashboardCard].config.cardNameOverride || this.dashboardCard);
    }),
    saveCardToConfig: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var cardData;
      var cardFocus = this.cardFocus && typeof this.cardFocus === 'object' ? this.cardFocus.value : this.cardFocus;
      var formattedObjects = [];
      formattedObjects.pushObject(Ember.Object.create({
        model: {
          cardFocus: cardFocus,
          dateRange: this.dateRange
        },
        filePath: 'dashboard-cards/dashboard-config-card'
      }));
      var addToSection = this.addToSection || 'sectionless';
      var addConfig = {
        'card_focus': cardFocus,
        'date_range': this.dateRange,
        'titleKey': this.editDashboard.availableCards[this.dashboardCard].config.titleKeyOverride || this.editDashboard.titleKeyPrefix + this.dashboardCard.replace(/-/g, '_') + this.editDashboard.titleKeySuffix
      };

      if (this.editDashboard.dashboardCards.get('length') === 0) {
        cardData = {
          pages: {
            [this.page]: {
              sections: {
                sectionless: {
                  order: 0,
                  cards: [{
                    order: 0,
                    'card': this.dashboardCard,
                    'config': Object.assign(this.editDashboard.availableCards[this.dashboardCard].config, addConfig),
                    display: true
                  }]
                }
              }
            }
          }
        };
        yield this.editDashboard.dashboardCards.push({
          cardData
        });
      } else {
        cardData = this.editDashboard.dashboardCards[0].cardData;
        var page = cardData.pages[1];

        if (addToSection in page.sections) {
          var section = page.sections[addToSection];
          var cardOrder = section.cards.length;
          section.cards[cardOrder] = {
            order: cardOrder,
            'card': this.dashboardCard,
            'config': Object.assign(this.editDashboard.availableCards[this.dashboardCard].config, addConfig),
            display: true
          };
          this.editDashboard.dashboardCards[0].cardData = cardData;
        } else {
          var sectionOrder = Object.keys(page.sections).length;
          page.sections[addToSection] = {
            order: sectionOrder,
            cards: [{
              order: 0,
              card: this.dashboardCard,
              config: Object.assign(this.editDashboard.availableCards[this.dashboardCard].config, addConfig),
              display: true
            }]
          };
          this.editDashboard.dashboardCards[0].set('cardData', cardData);
        }
      }

      buttonPromise.then(() => {
        multiStepForm.formStartedSetVal(false);
        this.homeDashboard.trigger('dashboard-config-update', this.editDashboard.dashboardLocation);
      });
    })
  });

  _exports.default = _default;
});