define("securitytrax-ember-ui/components/multi-step-form/forms/system/adt/shared/account-data/component", ["exports", "securitytrax-ember-ui/mixins/set-user", "ember-concurrency"], function (_exports, _setUser, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_setUser.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    centralStationService: Ember.inject.service(),
    userService: Ember.inject.service(),
    usesTracking: Ember.computed('multiStepForm.formCopiedModels.customer.model.creditTracking', function () {
      return this.usesTrackingTask.perform();
    }),
    usesTrackingTask: (0, _emberConcurrency.task)(function* () {
      try {
        var multiStepForm = this.multiStepForm;
        yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);
        var usesTracking = false;
        var creditTracking = yield this.multiStepForm.formCopiedModelsGetVal('customer.model.creditTracking');

        if (creditTracking && creditTracking.length <= 5) {
          usesTracking = true;
        }

        return usesTracking;
      } catch (error) {
        (false && Ember.warn('uses tracking task failed' + error, {
          id: 'multiStepForm.forms.system.adt.accountData'
        }));
      }
    }).on('init'),
    paperContractReasons: Ember.computed(function () {
      return this.paperContractReasonsTask.perform();
    }),
    paperContractReasonsTask: (0, _emberConcurrency.task)(function* () {
      var paperContractReasons = yield this.centralStationService.getData({
        model: yield this.monitoringCompany,
        method: 'adt__getPaperContractReasons'
      });
      return paperContractReasons.paper_contract_reasons;
    }),
    salesReps: Ember.computed(function () {
      return this.salesRepTask.perform();
    }),
    salesRepTask: (0, _emberConcurrency.task)(function* () {
      var customer = this.multiStepForm.formCopiedModelsGetVal('customer.model');
      return yield this.userService.getUsersList('sales_rep', customer);
    }),
    accountData: Ember.computed(function () {
      return this.setupFormDataTask.perform();
    }),
    setupFormDataTask: (0, _emberConcurrency.task)(function* () {
      var centralStationService = this.centralStationService;
      var multiStepForm = this.multiStepForm;
      yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);
      var store = this.store; //const adtOrder = yield this.get('adtOrder');

      var monitoringCompany = yield this.monitoringCompany;
      var hashData = yield (0, _emberConcurrency.hash)({
        leadSources: centralStationService.getData({
          model: monitoringCompany,
          method: 'adt__getLeadSources',
          //by default this filters blacklisted items
          responseKey: 'lead_sources'
        }),
        contractTerms: store.findAll('contractTerm'),
        adtMonitoringCompanyHouseAccountReason: centralStationService.getData({
          model: monitoringCompany,
          method: 'adt__getHouseAccountReasons',
          responseKey: 'house_account_reasons'
        })
      });
      return {
        leadSourceOptions: hashData.leadSources,
        contractTermOptions: hashData.contractTerms,
        adtMonitoringCompanyHouseAccountReasonOptions: hashData.adtMonitoringCompanyHouseAccountReason,
        accountTypeOptions: [{
          value: 'Residential',
          name: this.intl.t("common.customer.account_types.Residential")
        }, {
          value: 'Commercial',
          name: this.intl.t("common.customer.account_types.Commercial")
        }]
      };
    })
  });

  _exports.default = _default;
});