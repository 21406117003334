define("securitytrax-ember-ui/components/calendar-glimmer/calendar-scheduling/schedulers/tech-availability/component", ["exports", "securitytrax-ember-ui/components/calendar-glimmer/calendar-scheduling/base-scheduler"], function (_exports, _baseScheduler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TechAvailability extends _baseScheduler.default {}

  _exports.default = TechAvailability;
});