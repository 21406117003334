define("securitytrax-ember-ui/components/html/notifications/st-app-notification/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TRANSITION_FAST = '300';
  /**
   * @classdesc An individual notification that is displayed within the notification manager.
   *
   * @todo Implement action buttons within the notification. This might cause the close 'x' button to not appear?
   *
   * @class AppNotification
   * @extends Ember.Component
   * @memberof components.notification-manager
   */

  var _default = Ember.Component.extend(
  /** @lends components.notification-manager.AppNotification# **/
  {
    classNames: ['notification'],
    classNameBindings: ['type', 'animationCss', 'notification.cssClass'],

    init() {
      this._super(...arguments);

      var fnSetAnimationCss = className => {
        if (this && !this.isDestroyed) {
          this.set('animationCss', className);
        }
      };

      fnSetAnimationCss('');
      var autoCloseDuration = this.autoCloseDuration;
      Ember.run.later(() => {
        fnSetAnimationCss('in');
      }); // Does the notification type have an auto-close duration?

      if (autoCloseDuration) {
        // Set a schedule to automatically close the notification after a length of time.
        Ember.run.later(() => {
          fnSetAnimationCss('');
        }, autoCloseDuration);
      }
    },

    /**
     * Observes the 'in' CSS class for the notification. If it was removed from the class list, the notification is removed from the array.
     *
     * @function
     */
    inObserver: Ember.observer('animationCss', function () {
      if (!this.animationCss) {
        Ember.run.once(this, this.closeNotificationIfNotDestroyed);
      }
    }),

    closeNotificationIfNotDestroyed() {
      Ember.run.later(() => {
        if (this && !this.isDestroyed) {
          this.send('closeNotification', this.notification);
        }
      }, TRANSITION_FAST);
    },

    /**
     * The notification object associated with an instance of this component.
     *
     * @type {AppNotificationType}
     */
    notification: undefined,

    /**
     * The text portion of the notification.
     *
     * @function
     * @returns {String}
     */
    text: Ember.computed.alias('notification.text'),

    /**
     * The type of notification to be displayed (e.g. info, success, etc.)
     *
     * @function
     * @returns {String}
     */
    type: Ember.computed.alias('notification.type'),

    /**
     * The name of the SVG icon to be displayed with the notification.
     *
     * @function
     * @returns {String}
     */
    icon: Ember.computed.alias('notification.icon'),

    /**
     * Collection of buttons to show in the notification
     *
     * @function
     * @returns
     */
    buttons: Ember.computed.alias('notification.buttons'),

    /**
     * The amount of time (in ms) the notification should stay open before automatically closing itself.
     *
     * @function
     * @returns {number|boolean}
     */
    autoCloseDuration: Ember.computed.alias('notification.autoCloseDuration'),

    /**
     * The CSS class string applied to the notification (used for animations).
     *
     * @type {String}
     */
    animationCss: '',

    /** @ignore **/
    actions:
    /** @lends components.notification-manager.AppNotification# **/
    {
      /**
       * Button inside notification was clicked
       *
       * @param {Function} fn - Function passed in from the button
       */
      buttonClicked(fn) {
        if (fn() !== false) {
          // Close notification
          if (this && !this.isDestroyed) {
            this.send('closeNotification', this.notification);
          }
        }
      }

    }
  });
  /**
   * The object form of a notification button.
   *
   * @typedef {{
   *      cssClass: String,
   *      text: String,
   *      icon: String,
   *      iconOnly: boolean,
   *      noBackground: boolean,
   *      action: Function
   * }} AppNotificationButtonType
   *
   * @property {String} cssClass - Css class to be added to the button.
   * @property {String} text - Text for the button.
   * @property {String} icon - Icon to be shown in the button.
   * @property {boolean} iconOnly - Is the button only an icon without any text to be shown?
   * @property {boolean} noBackground - Should the button have no background?
   * @property {Function} action - Function to execute when button is clicked
   */

  /**
   * The object form of a notification.
   *
   * @typedef {{
   *      text: String,
   *      type: String,
   *      icon: String,
   *      autoCloseDuration: number|boolean,
   *      buttons: Array<AppNotificationButtonType>,
   *      cssClass: String
   * }} AppNotificationType
   *
   * @property {String} text - The notification text.
   * @property {String} type - The notification type.
   * @property {String} icon - The name of the icon the notification should display.
   * @property {number|boolean} autoCloseDuration - The amount of time before the notification closes itself (in ms).
   * @property {Array<AppNotificationButtonType>} buttons - Buttons to show in the notification
   * @property {String} cssClass - Css class to add to the notification
   */


  _exports.default = _default;
});