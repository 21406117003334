define("securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/interactive-services-providers/interactive-services-provider-type-default/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/base-interactive-services-provider/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({});

  _exports.default = _default;
});