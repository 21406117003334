define("securitytrax-ember-ui/components/pages/reports/component", ["exports", "securitytrax-ember-ui/mixins/sortable-list-mixin"], function (_exports, _sortableListMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_sortableListMixin.default, {
    showNav: true
  });

  _exports.default = _default;
});