define("securitytrax-ember-ui/models/user-stat", ["exports", "securitytrax-ember-ui/models/base_models/user-stat"], function (_exports, _userStat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _userStat.default.extend({});

  _exports.default = _default;
});