define("securitytrax-ember-ui/components/pages/all-quickbooks-ledger/component", ["exports", "@glimmer/component", "ember-concurrency", "lodash", "securitytrax-ember-ui/mixed-in-classes/table-column-sort", "securitytrax-ember-ui/utils/obj-helpers", "securitytrax-ember-ui/helpers/de-snake"], function (_exports, _component, _emberConcurrency, _lodash, _tableColumnSort, _objHelpers, _deSnake) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var AllQuickbooksLedger = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._tracked, _dec21 = (0, _emberConcurrency.task)(function* () {
    try {
      var count;

      if (this.page == 1) {
        count = 1;
      }

      var isConnected = yield this.quickbooksService.quickbooksIsConnected.perform();
      Ember.set(this, 'quickbooksConnected', isConnected);
      this.currAccountingProvider = yield this.quickbooksService.currAccountingProviderTask.perform();
      var newFilters = yield this.stFiltersService.getFiltersByGroupKey('allQuickbooksLedger');
      var quickbooksLedger = yield this.store.query('syncObjectToProvider', {
        sort: this.TableColumnSort.stSortString,
        filter: newFilters,
        limit: this.limit,
        page: this.page,
        count: count,
        include: 'invoice,invoice.customer,customer_payment,customer_payment.customer,customer'
      });
      var possible_sync_statuses = Ember.A();

      if (quickbooksLedger.length > 0) {
        possible_sync_statuses = _lodash.default.get(quickbooksLedger.objectAt(0), 'responseMeta.possible_sync_statuses', Ember.A());
      }

      var syncStatusesData = possible_sync_statuses.map(currValue => {
        return {
          id: currValue,
          name: (0, _deSnake.deSnake)(currValue).replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3')
        };
      });

      if (possible_sync_statuses && possible_sync_statuses.length > 0) {
        Ember.set(this, 'possible_sync_statuses', syncStatusesData);
      }

      if (this.page == 1) {
        Ember.set(this, 'recordCount', _lodash.default.get(quickbooksLedger, 'meta.record_count', 0));
      }

      return quickbooksLedger;
    } catch (error) {
      (false && Ember.warn('failed in quickbooksLedgerTask 76: ' + error, {
        id: 'company.accounting.quickbooksLedger'
      }));
    }
  }), _dec22 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var syncNowResults = yield this.currAccountingProvider.invokeAction('trigger_sync', {
      verb: 'POST',
      data: {
        data: {
          force: true
        }
      }
    });

    var syncMsg = _lodash.default.get(syncNowResults, 'responsePayload.data.msg', '');

    buttonPromise.then(() => {
      if (syncMsg === 'Task has been queued') {
        this.stNotificationManager.addSuccessKey('pages.administration.integrations.accountingProvidersSync.sync_successful');
      } else {
        this.stNotificationManager.addErrorKey('pages.administration.integrations.accountingProvidersSync.sync_failed');
      }
    });
  }), _dec23 = Ember._action, _dec24 = Ember._action, _dec25 = Ember._action, _dec26 = Ember._action, _dec27 = Ember._action, _dec28 = Ember._action, (_class = (_temp = class AllQuickbooksLedger extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "grants", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      _initializerDefineProperty(this, "router", _descriptor5, this);

      _initializerDefineProperty(this, "quickbooksService", _descriptor6, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor7, this);

      _initializerDefineProperty(this, "stFiltersService", _descriptor8, this);

      _initializerDefineProperty(this, "limit", _descriptor9, this);

      _initializerDefineProperty(this, "page", _descriptor10, this);

      _initializerDefineProperty(this, "createdDateFrom", _descriptor11, this);

      _initializerDefineProperty(this, "createdDateTo", _descriptor12, this);

      _initializerDefineProperty(this, "dueDate", _descriptor13, this);

      _initializerDefineProperty(this, "isPaid", _descriptor14, this);

      _initializerDefineProperty(this, "customerName", _descriptor15, this);

      _initializerDefineProperty(this, "recordCount", _descriptor16, this);

      _initializerDefineProperty(this, "possible_sync_statuses", _descriptor17, this);

      _initializerDefineProperty(this, "currAccountingProvider", _descriptor18, this);

      _initializerDefineProperty(this, "quickbooksConnected", _descriptor19, this);

      _initializerDefineProperty(this, "appliedFiltersCount", _descriptor20, this);

      _defineProperty(this, "enumLists", undefined);

      _defineProperty(this, "selectedEntityType", undefined);

      _defineProperty(this, "selectedSyncStatus", undefined);

      _initializerDefineProperty(this, "quickbooksLedgerTask", _descriptor21, this);

      _initializerDefineProperty(this, "quickbooksSyncNow", _descriptor22, this);

      this.TableColumnSort = new _tableColumnSort.default(...arguments);
      (0, _objHelpers.initializeState)(this, {
        stSortableColumnOptions: [{
          name: this.intl.t('common.columns.sale_date') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'sale_date',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.sale_date') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-sale_date',
          chevronDirection: 'down'
        }]
      });
      this.TableColumnSort.stSortableColumnOptions = this.stSortableColumnOptions;
      this.TableColumnSort.stSortAscending = false;
      this.TableColumnSort.stSortCurrentSortColumn = 'sale_date';
      var dummyModel = this.store.createRecord('syncObjectToProvider');
      Ember.set(this, 'enumLists', dummyModel.enumLists());
      dummyModel.rollbackAttributes();
    }

    get quickbooksLedger() {
      return this.quickbooksLedgerTask.perform();
    }

    resetAutoCreditFlag() {
      return this.quickbooksService.resetAutoCreditFlag.perform(this.currAccountingProvider);
    }

    updateQuickbooksList() {
      Ember.notifyPropertyChange(this, 'quickbooksLedger');
    }

    filterSearch() {
      Ember.set(this, 'page', 1);
      Ember.notifyPropertyChange(this, 'quickbooksLedger');
    }

    clearInvoicesFilter() {
      Ember.setProperties(this, {
        createdDateFrom: null,
        createdDateTo: null,
        selectedEntityType: null,
        selectedSyncStatus: null,
        page: 1
      });
      Ember.notifyPropertyChange(this, 'quickbooksLedger');
    }

    sortList(column, ascending) {
      this.TableColumnSort.sortPaginatedList(column, ascending);
      Ember.notifyPropertyChange(this, 'quickbooksLedger');
    }

    mobileSortList(column) {
      this.TableColumnSort.sortMobilePaginatedList(column.value);
      Ember.set(this, 'stSortCurrentSortColumn', null);
      Ember.set(this, 'stSortAscending', null);
      Ember.notifyPropertyChange(this, 'quickbooksLedger');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "quickbooksService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "stFiltersService", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "limit", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 50;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "createdDateFrom", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "createdDateTo", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "dueDate", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "isPaid", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "customerName", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "recordCount", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "possible_sync_statuses", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "currAccountingProvider", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "quickbooksConnected", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "appliedFiltersCount", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "quickbooksLedgerTask", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "quickbooksSyncNow", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "resetAutoCreditFlag", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "resetAutoCreditFlag"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateQuickbooksList", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "updateQuickbooksList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterSearch", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "filterSearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearInvoicesFilter", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "clearInvoicesFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortList", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "sortList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mobileSortList", [_dec28], Object.getOwnPropertyDescriptor(_class.prototype, "mobileSortList"), _class.prototype)), _class));
  _exports.default = AllQuickbooksLedger;
});