define("securitytrax-ember-ui/components/version-check/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    version: Ember.inject.service(),
    intl: Ember.inject.service(),
    dialogActive: false,
    updatedVersion: null,
    activityButtons: Ember.computed(function () {
      return [{
        classNamesString: 'st-btn warning-red st-btn--updates-dialog',
        text: this.intl.t('common.version.later'),
        isDelete: true,
        click: () => {
          this.version.set('versionconflict', false);
        }
      }, {
        classNamesString: 'st-btn st-btn-primary st-btn--updates-dialog',
        text: this.intl.t('common.version.get'),
        click: () => {
          this.version.resetApp();
        }
      }];
    }),
    versionConflictChanged: Ember.observer('version.versionconflict', function () {
      this.set('updatedVersion', this.get('version.version'));

      if (this.dialogActive) {
        this.set('dialogActive', false);
      } else {
        this.set('dialogActive', true);
      }
    })
  });

  _exports.default = _default;
});