define("securitytrax-ember-ui/validations/central-station/shell-brinks", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateObject = {
        siteType: {
          presence: () => {
            return this.customMessageOrDefault(data, 'brinks-siteType');
          }
        },
        systemType: {
          presence: () => {
            return this.customMessageOrDefault(data, 'brinks-systemType');
          }
        },
        csNumber: {
          presence: () => {
            return this.customMessageOrDefault(data, 'brinks-csNumber');
          }
        },
        receiverPhone: {
          presence: () => {
            return this.customMessageOrDefault(data, 'brinks-receiverPhone');
          }
        },
        sitePhone: {
          presence: () => {
            return this.customMessageOrDefault(data, 'brinks-sitePhone');
          }
        },
        panelLocation: {
          presence: () => {
            return this.customMessageOrDefault(data, 'brinks-panelLocation');
          }
        },
        panelCode: {
          presence: () => {
            return this.customMessageOrDefault(data, 'brinks-panelCode');
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      return this.outputFormatting(validationData, 'brinks');
    }

  });

  _exports.default = _default;
});