define("securitytrax-ember-ui/services/st-data-caching", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import jsonDecycle from 'json-decycle';
  // jsonDecycle.Map = Map;
  // jsonDecycle.WeakMap = WeakMap;
  // jsonDecycle.WeakSet = WeakSet;
  // jsonDecycle.Set = Set;
  // exports.Map = Map
  // exports.WeakMap = WeakMap
  // exports.WeakSet = WeakSet
  // exports.Set = Set

  /**
   * @param {any} value 
   */
  var isObject = value => typeof value === 'object' && value != null && !(value instanceof Boolean) && !(value instanceof Date) && !(value instanceof Number) && !(value instanceof RegExp) && !(value instanceof String);
  /**
   * @param {string[]} parts 
   */


  var toPointer = parts => '#' + parts.map(part => String(part).replace(/~/g, '~0').replace(/\//g, '~1')).join('/');
  /**
   * @returns (key: string | symbol, value: any) => any
   */


  var decycle = () => {
    var paths = new WeakMap(); //   console.log('made it this far?? 1');

    /**
     * @param {string | symbol} key
     * @param {any} value
     * @this object
     */

    return function replacer(key, value) {
      // console.log('made it this far?? 2');
      if (key !== '$ref' && isObject(value)) {
        // console.log('made it this far?? 3');
        var seen = paths.has(value);

        if (seen) {
          // console.log('made it this far?? 4');
          return {
            $ref: toPointer(paths.get(value))
          };
        } else {
          var _paths$get;

          // console.log('made it this far?? 5');
          paths.set(value, [...((_paths$get = paths.get(this)) !== null && _paths$get !== void 0 ? _paths$get : []), key]);
        }
      } // console.log('made it this far?? 7', value);


      return value;
    };
  };
  /**
   * @returns (key: string | symbol, value: any) => any
   */
  // function retrocycle() {
  //   const parents = new exports.WeakMap();
  //   const keys = new exports.WeakMap();
  //   const refs = new exports.Set();
  //   /**
  //    * @param {{ $ref: string }} ref
  //    * @this object
  //    */
  //   function dereference(ref) {
  //     const parts = ref.$ref.slice(1).split('/')
  //     let key, parent, value = this
  //     for (var i = 0; i < parts.length; i++) {
  //       key = parts[i].replace(/~1/g, '/').replace(/~0/g, '~')
  //       value = value[key]
  //     }
  //     parent = parents.get(ref)
  //     parent[keys.get(ref)] = value
  //   }
  //   /**
  //    * @param {string | symbol} key
  //    * @param {any} value
  //    * @this object
  //    */
  //   return function reviver(key, value) {
  //     if (key === '$ref') {
  //       refs.add(this)
  //     } else
  //       if (isObject(value)) {
  //         var isRoot = key === '' && Object.keys(this).length === 1
  //         if (isRoot) {
  //           refs.forEach(dereference, this)
  //         } else {
  //           parents.set(value, this)
  //           keys.set(value, key)
  //         }
  //       }
  //     return value
  //   }
  // }

  /**
   * @param {{ parse: typeof JSON.parse, stringify: typeof JSON.stringify }} JSON
   */


  JSON => {
    return Object.defineProperties(JSON, {
      decycle: {
        /**
         * @param {any} object
         * @param {string | number} space
         * @returns string
         */
        value: (object, space) => JSON.stringify(object, decycle(), space)
      } // retrocycle: {
      //   /**
      //    * @param {string} s 
      //    * @returns any
      //    */
      //   value: (s) => JSON.parse(s, retrocycle())
      // }

    });
  };

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    stJson: Ember.inject.service(),
    store: Ember.inject.service(),
    theCacheData: undefined,

    init() {
      this._super(...arguments);

      this.theCacheData = {};
    },

    setCache(cacheKey, cacheData, cacheParameters) {
      var mergedParameters = _lodash.default.merge({
        cacheForMilliseconds: 0,
        // 0 means cache forever
        cacheForNumAccessed: 0,
        // 0 means cache forever
        useLocalStorage: false
      }, cacheParameters); // you can't store something that's a promise because when it comes back it won't work (after page refresh)


      Ember.RSVP.Promise.resolve(cacheData).then(cacheDataValue => {
        var cacheJson = {
          cacheData: cacheDataValue,
          cacheParameters: mergedParameters,
          accessedCount: 0,
          lastAccessedTimestamp: Date.now(),
          cachedAtTimestamp: Date.now()
        };

        if (mergedParameters.useLocalStorage) {
          // need to loop through all records, and serialize to base attributes
          var recordArr = []; // need to go through relationships as well

          var _loop = function (index) {
            var element = cacheDataValue.objectAt(index);
            var elementAttributes = {};
            element.eachAttribute(attribute => elementAttributes[attribute] = element.get(attribute));
            recordArr.push({
              modelName: element.constructor.modelName,
              attributes: elementAttributes,
              id: element.get('id')
            });
          };

          for (var index = 0; index < cacheDataValue.length; index++) {
            _loop(index);
          }

          cacheJson.cacheData = recordArr;
          var cacheString = this.stJson.stringify(cacheJson);
          window.localStorage.setItem(cacheKey, cacheString);
        } else {
          this.theCacheData[cacheKey] = cacheJson;
        }
      });
    },

    clearCache(cacheKey, cacheParameters) {
      var mergedParameters = _lodash.default.merge({
        cacheForMilliseconds: 0,
        // 0 means cache forever
        cacheForNumAccessed: 0,
        // 0 means cache forever
        useLocalStorage: false
      }, cacheParameters);

      if (mergedParameters.useLocalStorage) {
        window.localStorage.removeItem(cacheKey);
      } else {
        delete this.theCacheData[cacheKey];
      }
    },

    getCache(cacheKey, cacheParameters) {
      var mergedParameters = _lodash.default.merge({
        cacheForMilliseconds: 0,
        // 0 means cache forever
        cacheForNumAccessed: 0,
        // 0 means cache forever
        useLocalStorage: false
      }, cacheParameters);

      if (!mergedParameters.useLocalStorage && this.theCacheData[cacheKey] || mergedParameters.useLocalStorage && window.localStorage.getItem(cacheKey)) {
        var cacheData;

        if (mergedParameters.useLocalStorage) {
          return false; // TODO: Figure out how to shove data back into Ember Data Objects the way templates expect as if from the store
          // const cacheDataString = window.localStorage.getItem(cacheKey);
          // cacheData = this.stJson.parse(cacheDataString);
          // const recordsArr = A();
          // for (let i = 0; i < cacheData.cacheData.length; i++) {
          //   const elementJSON = cacheData.cacheData[i];
          //   let record = this.store.peekRecord(elementJSON.modelName, elementJSON.id);
          //   if (record === null) {
          //     record = this.store.createRecord(elementJSON.modelName);
          //     record.set('id', elementJSON.id);
          //   }
          //   record._internalModel.__recordData.__data = elementJSON.attributes;
          //   recordsArr.push(record);
          // }
          // cacheData.cacheData = recordsArr;
        } else {
          cacheData = this.theCacheData[cacheKey];
        } // update the cached access data


        var accessedCount = cacheData.accessedCount;
        var lastAccessedTimestamp = cacheData.lastAccessedTimestamp;
        cacheData.accessedCount = cacheData.accessedCount + 1;
        cacheData.lastAccessedTimestamp = Date.now();
        this.theCacheData[cacheKey] = cacheData; // see if too much time has passed for the cache

        var timeSinceLastAccessed = Date.now() - lastAccessedTimestamp;

        if (cacheData.cacheParameters.cacheForMilliseconds > 0 && timeSinceLastAccessed >= cacheData.cacheParameters.cacheForMilliseconds) {
          return false;
        } // see if the cache has been accessed too many times


        if (cacheData.cacheParameters.cacheForNumAccessed > 0 && accessedCount >= cacheData.cacheParameters.cacheForNumAccessed) {
          return false;
        }

        return cacheData.cacheData;
      }

      return false;
    }

  });

  _exports.default = _default;
});