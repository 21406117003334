define("securitytrax-ember-ui/models/equipment-vendor", ["exports", "securitytrax-ember-ui/models/base_models/equipment-vendor", "ember-concurrency"], function (_exports, _equipmentVendor, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _equipmentVendor.default.extend({
    processingMethods: Ember.computed('additionalInformation', function () {
      return this.processingMethodsTask.perform();
    }),
    processingMethodsTask: (0, _emberConcurrency.task)(function* () {
      var additionalInformation = yield this.get('additionalInformation');
      var processing = additionalInformation && additionalInformation.processing ? additionalInformation.processing : [];
      processing.forEach(process => {
        if (process.value === 100) {
          process.name = this.intl.t('pages.users.my_inventory.purchase_orders.electronic');
        }
      });
      return processing;
    }),

    get additionalInformation() {
      return this.addtionalInformationTask.perform();
    },

    addtionalInformationTask: (0, _emberConcurrency.task)(function* () {
      var config = yield this.get('equipmentVendorConfig');
      return config && config.get('connector') ? (yield this.invokeAction('additional_information', {
        verb: 'GET'
      })).responsePayload.data : null;
    })
  });

  _exports.default = _default;
});