define("securitytrax-ember-ui/components/multi-step-form/forms/invoices/base", ["exports", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "ember-concurrency", "securitytrax-ember-ui/utils/debug", "lodash"], function (_exports, _component, _switchableFormStep, _emberConcurrency, _debug, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var InvoicesBase = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = (0, _emberConcurrency.task)(function* () {
    var customerAccountingInfo = yield this.args.customer.get('customerAccountingInformation');
    var invoiceBillingInformation = customerAccountingInfo.filterBy('useType', this.args.multiStepForm.formCopiedModelsGetVal('invoice.model.invoiceType'))[0];
    var defaultBillingInformation = customerAccountingInfo.filterBy('useType', 'default')[0];

    if (invoiceBillingInformation && !invoiceBillingInformation.get('usePrimary')) {
      return invoiceBillingInformation.get('emails');
    } else if (!invoiceBillingInformation && defaultBillingInformation && !defaultBillingInformation.get('usePrimary')) {
      return defaultBillingInformation.get('emails');
    }

    return this.args.customer.get('email');
  }), _dec12 = (0, _emberConcurrency.task)(function* () {
    var choices = Ember.A([]);
    var customerBillingUsesArray = Ember.A([]);
    var customerBillingsList = this.grants.getIndividualGrant('customer_billings.view') ? yield this.args.customer.get('customerBillings') : [];
    customerBillingsList.forEach(customerBilling => {
      if (customerBilling.get('billType.id') !== '3') {
        customerBillingUsesArray.pushObject(this.billUsesDataTask.perform(customerBilling));
      }
    });
    var currCustomerBillingUsesArray = yield (0, _emberConcurrency.all)(customerBillingUsesArray);
    currCustomerBillingUsesArray.forEach(customerBillingObject => {
      var userDescription = '';
      var currCustomerBillingUseLength = customerBillingObject.currCustomerBillingUses.length;
      customerBillingObject.currCustomerBillingUses.forEach((currUses, index) => {
        userDescription += currUses.get('name');

        if (index != currCustomerBillingUseLength - 1) {
          userDescription += ', ';
        }
      });
      choices.pushObject(Ember.Object.create({
        packageName: customerBillingObject.customerBilling.get('title'),
        packageId: customerBillingObject.customerBilling.get('id'),
        packageContent: [{
          label: this.intl.t('common.columns.default'),
          description: customerBillingObject.customerBilling.get('isCustomerDefault') ? this.intl.t('common.status.yes') : this.intl.t('common.status.no')
        }, {
          label: this.intl.t('common.columns.creation_date'),
          description: moment(customerBillingObject.customerBilling.get('createdAt')).format('MM/DD/YYYY')
        }, {
          label: this.intl.t('common.columns.uses'),
          description: userDescription
        }, {
          label: this.intl.t('pages.customers.accounting.customer_billing.billStartDate.label'),
          description: customerBillingObject.customerBilling.get('billStartDate') ? moment(customerBillingObject.customerBilling.get('billStartDate')).format('MM/DD/YYYY') : null
        }, {
          label: this.intl.t('pages.customers.accounting.customer_billing.billDate'),
          description: customerBillingObject.customerBilling.get('billDayOfMonth')
        }, {
          label: this.intl.t('common.columns.note'),
          description: customerBillingObject.customerBilling.get('billComment')
        }]
      }));
    });
    return choices;
  }), _dec13 = (0, _emberConcurrency.task)(function* (customerBilling) {
    var currCustomerBillingUses = yield customerBilling.get('customerBillingUses');
    return {
      customerBilling,
      currCustomerBillingUses
    };
  }), _dec14 = (0, _emberConcurrency.task)(function* () {
    return yield this.store.query('invoiceBillableItem', {
      limit: 0,
      include: 'equipment'
    });
  }), _dec15 = (0, _emberConcurrency.task)(function* () {
    var customerAddress = this.args.customer.getProperties(['address1', 'address2', 'city', 'state', 'zip']);

    if (!customerAddress.zip) {
      return null;
    } else {
      return yield this.tax.getTaxRates(customerAddress);
    }
  }), _dec16 = (0, _emberConcurrency.task)(function* (buttonPromise, context = 'modal') {
    var formattedObjects = [];
    var addressString = yield this.emailAddresses;
    var noValidate = [];

    if (addressString !== null) {
      noValidate.push('recipients');
    } else {
      noValidate.push('defaultToAddress');
    }

    formattedObjects.pushObject(Ember.Object.create({
      model: {
        defaultToAddress: [addressString]
      },
      extraValidationData: {
        noValidate
      },
      filePath: 'send'
    }));
    yield this.args.multiStepForm.validateList.perform(formattedObjects);

    try {
      var email_to_unique = _lodash.default.uniq(addressString.replace(/\s+/g, '').split(','));

      yield this.sendPreviewEmail.sendEmail(this.invoiceToPreviewOrSend, {
        subject: this.invoiceEmailSubject,
        message: this.messageToSend,
        meta: {
          ignore_entity_email_address: false
        }
      }, 'invoices_action');
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccess(this.intl.t('common.notifications.send_message_successful', {
          email_to: email_to_unique.toString().replace(',', ', ')
        }), context);
      });
    } catch (error) {
      this.stNotificationManager.addErrorMessages(error, {
        notificationNamespace: context
      });
      throw error;
    }
  }), _dec17 = (0, _emberConcurrency.task)(function* () {
    try {
      var invoice = this.invoiceToPreviewOrSend;
      var previewData = yield this.sendPreviewEmail.getPreview(invoice);

      if (!((0, _debug.isTestEnvironment)() || (0, _debug.isCypressTestEnvironment)())) {
        window.location.assign(previewData.url);
      }
    } catch (error) {
      this.stNotificationManager.addErrorMessages(error, {
        notificationNamespace: 'global'
      });
    }
  }), _dec18 = (0, _emberConcurrency.task)(function* () {
    return yield this.store.findAll('customerPaymentType');
  }), (_class = (_temp = class InvoicesBase extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "tax", _descriptor2, this);

      _initializerDefineProperty(this, "invoiceService", _descriptor3, this);

      _initializerDefineProperty(this, "sendPreviewEmail", _descriptor4, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor5, this);

      _initializerDefineProperty(this, "invoiceBillableItems", _descriptor6, this);

      _initializerDefineProperty(this, "taxRates", _descriptor7, this);

      _initializerDefineProperty(this, "invoiceEmailSubject", _descriptor8, this);

      _initializerDefineProperty(this, "messageToSend", _descriptor9, this);

      _initializerDefineProperty(this, "invoiceToPreviewOrSend", _descriptor10, this);

      _initializerDefineProperty(this, "emailAddressesTask", _descriptor11, this);

      _initializerDefineProperty(this, "customerBillingChoicesTask", _descriptor12, this);

      _initializerDefineProperty(this, "billUsesDataTask", _descriptor13, this);

      _initializerDefineProperty(this, "invoiceBillableItemsTask", _descriptor14, this);

      _initializerDefineProperty(this, "taxRatesTask", _descriptor15, this);

      _initializerDefineProperty(this, "sendInvoiceTask", _descriptor16, this);

      _initializerDefineProperty(this, "showPreviewTask", _descriptor17, this);

      _initializerDefineProperty(this, "customerPaymentTypesTask", _descriptor18, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments, {
        disabledProgressBarSteps: this.disabledProgressBarSteps,
        hiddenProgressBarSteps: this.hiddenProgressBarSteps
      });
      this.SwitchableFormStep.progressBarHidden = this.args.progressBarHidden || false;
    }

    get emailAddresses() {
      return this.emailAddressesTask.perform();
    }

    get customerBillingChoices() {
      return this.customerBillingChoicesTask.perform();
    }

    get customerPaymentTypes() {
      return this.customerPaymentTypesTask.perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "tax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "invoiceService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "sendPreviewEmail", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "invoiceBillableItems", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "taxRates", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "invoiceEmailSubject", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "messageToSend", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "invoiceToPreviewOrSend", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "emailAddressesTask", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "customerBillingChoicesTask", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "billUsesDataTask", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "invoiceBillableItemsTask", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "taxRatesTask", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "sendInvoiceTask", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "showPreviewTask", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "customerPaymentTypesTask", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = InvoicesBase;
});