define("securitytrax-ember-ui/components/multi-step-form/forms/administration/users/permissions/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/administration/users/base-users", "ember-concurrency"], function (_exports, _baseUsers, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseUsers.default.extend({
    intl: Ember.inject.service(),
    formMode: 'view',
    cancelTask: (0, _emberConcurrency.task)(function* () {
      yield this.multiStepForm.resetForm(false);
      yield this.set('formMode', 'view');
    }),
    saveGroupTask: (0, _emberConcurrency.task)(function* () {
      yield this.multiStepForm.validateSyncSaveAll({
        extraValidationData: {
          noValidate: ['username', 'fname', 'lname', 'email', 'password']
        }
      });
      this.set('formMode', 'view');
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
    }).restartable(),
    descriptionObject: Ember.computed('multiStepForm.formCopiedModels.user.model.group.roles', function () {
      var group = this.multiStepForm.formCopiedModelsGetVal('user.model.group');
      var roles = group ? group.get('roles') : undefined;
      var packageContent = [];
      var descriptionObject;

      if (roles) {
        roles.forEach(role => {
          packageContent.push({
            name: role.get('name')
          });
        });
        descriptionObject = Ember.A([Ember.Object.create({
          packageName: this.intl.t('pages.users.permissions.group_roles'),
          packageId: group.get('id'),
          packageContent
        })]);
      }

      return descriptionObject;
    })
  });

  _exports.default = _default;
});