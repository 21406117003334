define("securitytrax-ember-ui/components/pages/users/login-information/component", ["exports", "moment", "ember-concurrency"], function (_exports, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    viewReset: Ember.computed('grants.userGrants', 'user.responseMeta.locked_out', function () {
      return this.grants.getIndividualGrant('users.unlock_users_login') && this.user.responseMeta.locked_out;
    }),
    loginInfoForm: Ember.computed(function () {
      return Ember.Object.create({
        loginInfo: Ember.Object.create({
          stepNameKey: 'loginInfo',
          title: 'login information',
          stepComponentPath: 'multi-step-form/forms/administration/users/login-info'
        })
      });
    }),
    passwordResetForm: Ember.computed(function () {
      return Ember.Object.create({
        passwordReset: Ember.Object.create({
          stepNameKey: 'passwordReset',
          title: this.intl.t('pages.users.login_information.change_password'),
          stepComponentPath: 'multi-step-form/forms/administration/users/password-reset'
        })
      });
    }),
    formOriginalModels: Ember.computed('model.user', function () {
      return Ember.Object.create({
        user: {
          model: this.get('model.user'),
          copyRelationMap: {//
          }
        }
      });
    }),
    passwordFormOriginalModels: Ember.computed('model.user', 'grants.userGrants', function () {
      var grants = this.grants;
      return Ember.Object.create({
        user: {
          model: this.get('model.user'),
          copyRelationMap: {
            userPasswordSetting: {
              doCopy: grants.getIndividualGrant('user_password_settings.view'),
              doValidate: grants.getIndividualGrant('user_password_settings.view'),
              doSave: grants.getIndividualGrant('user_password_settings.modify')
            }
          }
        }
      });
    }),

    get passwordExpires() {
      return this.passwordExpiresTask.perform();
    },

    passwordExpiresTask: (0, _emberConcurrency.task)(function* () {
      var globalSettings = yield this.sessionData.getCompanySettings();
      var lastChange = this.get('model.userPasswordSetting.lastPasswordChangeTime');
      var changePasswordDays = globalSettings.minDaysBeforePasswordChange;
      var expires = changePasswordDays ? (0, _moment.default)(lastChange).add(changePasswordDays, 'days').format('MM/DD/YYYY h:m a') : this.intl.t('pages.users.login_information.never_expires');
      return expires;
    }),
    resetUserTask: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.user.invokeAction('unlock_users_login', {
          verb: 'POST',
          data: {
            data: {}
          }
        });
        this.user.reload();
        this.stNotificationManager.addSuccessKey('common.notifications.user_reset_successful');
      } catch (error) {
        this.stNotificationManager.addErrorMessages(error);
      }
    })
  });

  _exports.default = _default;
});