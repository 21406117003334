define("securitytrax-ember-ui/components/pages/administration/company-settings/equipment/equipment-kits/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    stFiltersService: Ember.inject.service(),
    showEquipmentDeleteId: false,
    equipmentKit: null,
    equipmentKitFormStarted: false,
    equipmentKitFormMode: 'edit',
    page: 1,
    limit: 50,
    equipmentKitListCount: 0,
    equipmentKitStatus: undefined,
    equipmentKitFormSteps: Ember.computed('equipmentKitFormMode', 'equipmentKit', function () {
      var translationKey = this.equipmentKitFormMode === 'create' ? 'pages.administration.equipment.equipmentKits.title_create' : 'common.forms.descriptive_title_view';
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.intl.t(translationKey, {
            id: this.get('equipmentKit.id'),
            name: this.get('equipmentKit.name')
          }),
          stepComponentPath: 'multi-step-form/forms/administration/equipment/edit-equipment-kit'
        })
      });
    }),
    equipmentKitFormOriginalModels: Ember.computed('equipmentKit', function () {
      return Ember.Object.create({
        equipmentKit: {
          model: this.equipmentKit,
          copyRelationMap: {
            defaultEquipmentVendor: {
              readOnly: true
            },
            equipmentWireType: {
              readOnly: true
            },
            equipmentKitItems: {
              relationships: {
                equipment: {
                  doCopy: true,
                  doSave: false
                }
              }
            }
          }
        }
      });
    }),
    equipmentKits: Ember.computed(function () {
      return this.getEquipmentKitsTask.perform();
    }),
    getEquipmentKitsTask: (0, _emberConcurrency.task)(function* () {
      var newFilters = yield this.stFiltersService.getFiltersByGroupKey('companyEquipmentKits');
      var equipmentKits = yield this.store.query('equipmentKit', {
        filter: newFilters,
        limit: this.limit,
        page: this.page,
        include: 'equipment_manufacturer,equipment_wire_type,default_equipment_vendor,equipment_kit_items.equipment',
        count: 1
      });
      Ember.set(this, 'equipmentKitListCount', equipmentKits.meta.record_count);
      return equipmentKits;
    }),
    actions: {
      deleteEquipmentKit(equipmentKit) {
        equipmentKit.destroyRecordWithRollbackTask.perform().then(() => {
          Ember.set(this, 'showEquipmentDeleteId', false);
        });
      },

      updateList() {
        Ember.notifyPropertyChange(this, 'equipmentKits');
      },

      filterSearch(setPage = true) {
        if (setPage) {
          Ember.set(this, 'page', 1);
        }

        Ember.notifyPropertyChange(this, 'equipmentKits');
      },

      clearFilters() {
        Ember.setProperties(this, {
          page: 1,
          equipmentKitStatus: undefined
        });
        Ember.notifyPropertyChange(this, 'equipmentKits');
        Ember.notifyPropertyChange(this, 'page');
      }

    }
  });

  _exports.default = _default;
});