define("securitytrax-ember-ui/components/pages/customers/customer-appointments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CbpKIKWK",
    "block": "{\"symbols\":[\"data\"],\"statements\":[[8,\"html/st-await\",[],[[\"@booleanLoaded\"],[[32,0,[\"groupCustomerCalendarEventsTask\",\"isIdle\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"customer/appointments/appointments-list\",[],[[\"@calendarEvents\",\"@showCheckAvailability\",\"@customer\"],[[32,0,[\"techAppointments\"]],true,[34,0]]],null],[2,\"\\n\\n    \"],[8,\"customer/appointments/appointments-list\",[],[[\"@calendarEvents\",\"@showAdd\",\"@customer\",\"@type\"],[[32,0,[\"salesAppointments\"]],true,[34,0],\"sales\"]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"customer\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/customers/customer-appointments/template.hbs"
    }
  });

  _exports.default = _default;
});