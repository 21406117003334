define("securitytrax-ember-ui/components/modals/sub-route-modals/manage-api/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XIAxN8ot",
    "block": "{\"symbols\":[\"multiStepForm\"],\"statements\":[[6,[37,12],null,[[\"class\",\"formSteps\",\"currentStepNameKey\",\"formOriginalModels\",\"formStarted\",\"changeFormStarted\"],[\"row\",[35,11],\"general\",[35,10],[35,8],[30,[36,0],[[32,0],[30,[36,9],[[35,8]],null]],null]]],[[\"default\"],[{\"statements\":[[6,[37,7],null,[[\"modalButtons\",\"title\",\"classNames\",\"dialogOpen\",\"hideTopRightCloseButton\",\"hasProgressBar\",\"fullscreen\",\"onClose\",\"multiStepForm\"],[[32,1,[\"currentStepData\",\"buttons\"]],[32,1,[\"currentStepData\",\"title\"]],\"adt-modal st-modal--full\",[32,1,[\"formStarted\"]],[32,1,[\"currentStepData\",\"hideTopRightCloseButton\"]],[30,[36,6],[[35,4],\"create\"],null],true,[30,[36,0],[[32,0],\"transitionBackToParentRoute\"],null],[32,1]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,5],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],[[\"multiStepForm\",\"formMode\",\"customer\",\"monitoringCompany\",\"class\",\"customerSystemInformation\",\"transitionBackToParent\"],[[32,1],[35,4],[35,3],[35,2],\"form-step-full-height\",[35,1],[30,[36,0],[[32,0],\"transitionBackToParentRoute\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"customerSystemInformation\",\"monitoringCompany\",\"customer\",\"formMode\",\"component\",\"not-eq\",\"modals/st-paper\",\"formStarted\",\"mut\",\"formOriginalModel\",\"formSteps\",\"multi-step-form\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/modals/sub-route-modals/manage-api/template.hbs"
    }
  });

  _exports.default = _default;
});