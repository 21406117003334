define("securitytrax-ember-ui/components/multi-step-form/forms/administration/system/global-settings/edit-accounting-company-information/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    stNotificationManager: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    usStates: Ember.inject.service(),
    store: Ember.inject.service(),
    cancelTask: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.multiStepForm.resetForm(false);
        yield this.set('formMode', 'view');
        this.stNotificationManager.addSuccessKey('common.notifications.generic_cancel');
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err);
      }
    }).drop(),
    saveAccountingCompanyInfoTask: (0, _emberConcurrency.task)(function* () {
      if (this.multiStepForm.formOriginalModelsGetVal('pdfSetting.isCreate')) {
        var toCreateObjects = yield this.multiStepForm.validateCreateAll();
        toCreateObjects.pdfSetting.data['settingsType'] = 'default';
        var newPdfCompanyDefault = this.store.createRecord('pdfSetting', toCreateObjects.pdfSetting.data);
        yield toCreateObjects.pdfSetting.saveWithRollbackAndNotification(newPdfCompanyDefault);
      } else {
        yield this.multiStepForm.validateSyncSaveAll();
      }

      this.set('formMode', 'view');
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
    }).restartable()
  });

  _exports.default = _default;
});