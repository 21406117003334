define("securitytrax-ember-ui/components/multi-step-form/forms/administration/inventory/equipment-transfer/review/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    admin: false,

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      var buttons = [];

      if (this.admin) {
        buttons.push({
          text: this.intl.t('common.actions.saveAndAccept'),
          click: buttonPromise => {
            return this.save.perform(buttonPromise, true);
          },
          classNamesString: 'st-btn btn-secondary transfer-save-accept-button'
        });
      }

      buttons.push({
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.save.perform(buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary transfer-save-button'
      });
      multiStepForm.registerStepData("step1", 'buttons', buttons);
    }

  });

  _exports.default = _default;
});