define("securitytrax-ember-ui/components/multi-step-form/forms/administration/payables/function-details/function-details-level/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qYq9oW99",
    "block": "{\"symbols\":[\"@id\"],\"statements\":[[2,\" \"],[8,\"html/st-await\",[],[[\"@useSpinner\",\"@size\",\"@booleanLoaded\"],[true,\"x-small\",[34,0,[\"isIdle\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"lists/data-list/column-formatters/memo\",[],[[\"@canToggle\",\"@value\",\"@truncateAfter\",\"@hoverToExpand\",\"@classIndex\"],[false,[32,0,[\"lineItems\"]],50,true,[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"initDataTask\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/payables/function-details/function-details-level/template.hbs"
    }
  });

  _exports.default = _default;
});