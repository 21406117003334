define("securitytrax-ember-ui/mixins/leaflet-map", ["exports", "securitytrax-ember-ui/config/environment", "securitytrax-ember-ui/utils/debug"], function (_exports, _environment, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global L */
  var _default = Ember.Mixin.create({
    zoom: '16',
    lat: '0',
    lng: '0',
    ico: L.divIcon({
      iconSize: [50, 50],
      iconAnchor: [25, 83],
      popupAnchor: [0, -83],
      className: 'map-pin'
    }),
    app_id: _environment.default['securitytrax-ember-ui']['leaflet-map']['app_id'],
    app_code: _environment.default['securitytrax-ember-ui']['leaflet-map']['app_code'],
    provider: _environment.default['securitytrax-ember-ui']['leaflet-map']['provider'],
    isTestEnvironment: Ember.computed(function () {
      return (0, _debug.isTestEnvironment)();
    })
  });

  _exports.default = _default;
});