define("securitytrax-ember-ui/components/multi-step-form/forms/customer-central-station-information/monitoring-companies/monitoring-company-type-27/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer-central-station-information/base-monitoring-company/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var TelusMonitoringCompanyType = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = (0, _emberConcurrency.task)(function* () {
    var mc = yield this.args.monitoringCompany;
    var preShellErrors = [];

    try {
      var creds = yield this.centralStationService.getData({
        model: mc,
        method: 'adt_canada__getUserSessionCredentials',
        payload: {
          user_id: null
        }
      });

      if (!creds.credentials.username || !creds.credentials.password_has_saved_value) {
        preShellErrors.push(this.intl.t('pages.customers.system.telus.user_creds_not_set'));
      }

      if (preShellErrors.length > 0) {
        var errorNotificationMessage = this.stNotificationManager.fullMessage(preShellErrors);
        this.stNotificationManager.addError(errorNotificationMessage);
        throw new Error('preShell missing info');
      } else {
        this.router.transitionTo('customers.information.manage-' + this.subrouteMapping.monitorMapping.findBy('id', '27').name);
      }
    } catch (error) {
      (false && Ember.warn('failed to startTelusForm' + error, {
        id: 'multiStepForm.forms.customerCentralStationInformation.monitoringCompanies.monitoringCompanyType27'
      }));
    }
  }), (_class = (_temp = class TelusMonitoringCompanyType extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "centralStationService", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "startIntegrationForm", _descriptor4, this);
    }

    get monitoringCompanyHasNeededGrants() {
      return true;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "startIntegrationForm", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = TelusMonitoringCompanyType;
});