define("securitytrax-ember-ui/administration/company-settings/system/form-defaults/customer/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/has-page-title", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _authenticatedRouteMixin, _hasPageTitle, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _hasPageTitle.default, _routePageviewLogging.default, {
    intl: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform();
    },

    getPageTitle() {
      return this.intl.t('pages.administration.company_settings.global_settings.form_defaults.title');
    },

    getRouteName() {
      return "pages.administration.nav.company-settings";
    }

  });

  _exports.default = _default;
});