define("securitytrax-ember-ui/services/org-tree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    focusedUser: null,
    userHierarchyTree: null,
    showUnassignedLink: true,
    currentUser: null,
    theCompany: null,

    resetTree(userHierarchyTree, theCompany, currentUser) {
      this.set('userHierarchyTree', userHierarchyTree);
      this.set('theCompany', theCompany);
      this.set('currentUser', currentUser);
      this.set('focusedUser', currentUser);
      this.set('showUnassignedLink', true);
    },

    setFocusedUser(focusedUser) {
      this.set('focusedUser', focusedUser);
    },

    setCurrentUser(currentUser) {
      this.set('currentUser', currentUser);
      this.set('focusedUser', currentUser);
    }

  });

  _exports.default = _default;
});