define("securitytrax-ember-ui/customer-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gcn9+j9N",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"title\"],[[30,[36,0],[\"common.nav.customers\"],null]]]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"page-content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"scroll-page\"],[12],[2,\"\\n        \"],[1,[30,[36,3],null,[[\"locations\"],[[35,2,[\"officeLocations\"]]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"nav/top-nav-sub\",\"model\",\"customer-search-list\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/customer-list/template.hbs"
    }
  });

  _exports.default = _default;
});