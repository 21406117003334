define("securitytrax-ember-ui/home/tech-today/controller", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Controller
   * @extends Ember.Controller
   * @memberof customers
   */
  var Controller = Ember.Controller.extend({
    navigation: Ember.inject.service(),
    queryParams: ['date'],
    date: undefined,
    calendarDate: Ember.computed({
      get(key) {
        key;

        if (this.date) {
          return (0, _moment.default)(this.date);
        } else {
          return (0, _moment.default)();
        }
      },

      set(key, value) {
        key;
        this.set('date', value ? value.format('MM/DD/YYYY') : null);
        return value;
      }

    }),
    selectedUserId: Ember.computed({
      get(key) {
        key;
        return this.user_id;
      },

      set(key, value) {
        key;
        this.set('user_id', value);
        return value;
      }

    })
  });
  var _default = Controller;
  _exports.default = _default;
});