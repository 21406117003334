define("securitytrax-ember-ui/validations/pending-equipment-line-item", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateObject = {
        equipmentReturnReason: () => {
          if (data.extraValidationData.skip) {
            return {};
          }

          return {
            presence: this.customMessageOrDefault(data, 'equipmentReturnReason')
          };
        },
        equipment: () => {
          return {
            presence: this.customMessageOrDefault(data, 'equipment')
          };
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject, {
        fullMessages: this.prependProperty(data)
      });
      var modelName = data.extraValidationData && data.extraValidationData.hasOwnProperty('overrideValidationKey') ? data.extraValidationData.overrideValidationKey : 'pendingEquipmentLineItem';
      return this.outputFormatting(validationData, modelName);
    }

  });

  _exports.default = _default;
});