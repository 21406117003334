define("securitytrax-ember-ui/components/calendar-glimmer/calendar-month-view/component", ["exports", "@glimmer/component", "moment", "ember-concurrency", "lodash"], function (_exports, _component, _moment, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CalendarMonthView = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('events/calendar-events'), _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = (0, _emberConcurrency.task)(function* () {
    yield this._getAppointmentsTask.perform();
  }), _dec20 = (0, _emberConcurrency.task)(function* () {
    var startOfDay = (0, _moment.default)(this.selectedDay.date, 'MM/DD/YYYY').startOf('day');
    var endOfDay = (0, _moment.default)(this.selectedDay.date, 'MM/DD/YYYY').endOf('day');
    var defaultFilter = '(start_time >= \'' + startOfDay.format('YYYY-MM-DDTHH:mm:ss') + '\') and (start_time <= \'' + endOfDay.format('YYYY-MM-DDTHH:mm:ss') + '\') ';
    var calendarEvents = yield this.store.query('calendarEvent', {
      filter: defaultFilter,
      include: 'calendar_user.user,calendar_user,calendar_event_detail_type,calendar_event_type.calendar_event_detail_types,customer,work_order,dry_run_reason',
      sort: 'start_time, duration'
    });
    var monthViewFilters = yield this.stFiltersService.getFiltersByGroupKey('calendarMonthView');
    var hiddenTypes = this.filterValues(monthViewFilters);
    var appointmentsList = calendarEvents.toArray();
    this.appointmentsList = appointmentsList.filter(appointment => {
      var key = _lodash.default.snakeCase(appointment.calendarEventType.get('name'));

      return !hiddenTypes.includes(key);
    });
  }), _dec21 = (0, _emberConcurrency.task)(function* () {
    var monthViewFilters = yield this.stFiltersService.getFiltersByGroupKey('calendarMonthView');
    this.hiddenTypes = this.filterValues(monthViewFilters);
    var company = yield this.sessionData.getCompany();
    var response = yield company.invokeAction('get_calendar_month_data', {
      verb: 'POST',
      data: {
        data: {
          month: this.calendarDate.format('MM').toString(),
          year: this.calendarDate.format('YYYY').toString()
        }
      }
    });
    var dateData = response.responsePayload.data;
    this.populateSummaryData(dateData);
    this._weeks = this.generateWeeksData(dateData);
    return this._weeks;
  }), _dec22 = (0, _emberConcurrency.task)(function* () {
    var company = yield this.sessionData.getCompany();
    var response = yield company.invokeAction('get_calendar_month_data', {
      verb: 'POST',
      data: {
        data: {
          month: this.calendarDate.format('MM').toString(),
          year: this.calendarDate.format('YYYY').toString()
        }
      }
    });
    var dateData = response.responsePayload.data;
    this.populateSummaryData(dateData);
    var updatedWeeks = this.generateWeeksData(dateData);
    yield this._weeks;

    this._weeks.forEach((week, weekI) => {
      week.days.forEach((day, dayI) => {
        var updatedDayData = updatedWeeks[weekI].days[dayI];
        Ember.setProperties(day, updatedDayData);
      });
    });
  }), _dec23 = Ember._action, _dec24 = Ember._action, _dec25 = Ember._action, _dec26 = Ember._action, _dec27 = Ember._action, _dec28 = Ember._action, _dec29 = Ember._action, _dec30 = Ember._action, (_class = (_temp = class CalendarMonthView extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "sessionData", _descriptor, this);

      _initializerDefineProperty(this, "CalendarDatePickerService", _descriptor2, this);

      _initializerDefineProperty(this, "CalendarEventsService", _descriptor3, this);

      _initializerDefineProperty(this, "CalendarDataService", _descriptor4, this);

      _initializerDefineProperty(this, "store", _descriptor5, this);

      _initializerDefineProperty(this, "screen", _descriptor6, this);

      _initializerDefineProperty(this, "intl", _descriptor7, this);

      _initializerDefineProperty(this, "stFiltersService", _descriptor8, this);

      _initializerDefineProperty(this, "selectedDay", _descriptor9, this);

      _initializerDefineProperty(this, "appointmentsList", _descriptor10, this);

      _initializerDefineProperty(this, "_weeks", _descriptor11, this);

      _initializerDefineProperty(this, "calendarDate", _descriptor12, this);

      _initializerDefineProperty(this, "height", _descriptor13, this);

      _initializerDefineProperty(this, "summaryData", _descriptor14, this);

      _initializerDefineProperty(this, "curSubType", _descriptor15, this);

      _initializerDefineProperty(this, "hiddenAppointmentTypes", _descriptor16, this);

      _initializerDefineProperty(this, "_hiddenAppointmentTypes", _descriptor17, this);

      _initializerDefineProperty(this, "weeksSpinner", _descriptor18, this);

      _initializerDefineProperty(this, "getAppointmentsTask", _descriptor19, this);

      _initializerDefineProperty(this, "_getAppointmentsTask", _descriptor20, this);

      _initializerDefineProperty(this, "getWeeksTask", _descriptor21, this);

      _initializerDefineProperty(this, "updateWeeksTask", _descriptor22, this);

      if (this.CalendarDatePickerService.selectedDates && this.CalendarDatePickerService.selectedDates.length > 0) {
        this.selectedDay = Ember.Object.create({
          day: this.CalendarDatePickerService.selectedDates[0].date.date(),
          date: this.CalendarDatePickerService.selectedDates[0].date
        });
      } else {
        this.selectedDay = Ember.Object.create({
          day: (0, _moment.default)().date(),
          date: (0, _moment.default)()
        });
      }

      this.calendarDate = (0, _moment.default)(this.selectedDay.date).startOf('month');
      this.weeksSpinner = true;
      this.getWeeksTask.perform();
      this.getAppointmentsTask.perform();
      this.CalendarEventsService.on('refresh-page', this, '_refreshPage');
      this.CalendarEventsService.on('websocket-refresh', this, '_websocketRefresh');

      this._setInterval();
    }

    willDestroy() {
      this.CalendarEventsService.on('refresh-page', this, '_refreshPage');
      this.CalendarEventsService.on('websocket-refresh', this, '_websocketRefresh');
      clearInterval(this.puller);
    }

    _setInterval() {
      this.puller = setInterval(() => {
        this._silentRefreshPage();
      }, 300000);
    }

    _refreshPage() {
      this.updateDates();
    }

    _websocketRefresh() {
      Ember.run.debounce(this, this._silentRefresh, 500);
    }

    _silentRefresh() {
      if (this.puller) {
        clearInterval(this.puller);
      }

      this._setInterval();

      this._silentRefreshPage();
    }

    _silentRefreshPage() {
      this._getAppointmentsTask.perform();

      this.updateWeeksTask.perform();
    }

    eventColorByTypeName(name) {
      switch (name) {
        case 'install':
          return '#00a216';

        case 'service':
          return '#3d49d1';

        case 'removal':
          return '#db2a17';

        case 'time_off':
          return '#000';

        case 'sales':
          return '#ec720b';

        default:
          return '';
      }
    }

    get appointmentTypeOptions() {
      return [{
        id: 'install',
        name: this.intl.t('pages.calendar.colorKey.install')
      }, {
        id: 'service',
        name: this.intl.t('pages.calendar.colorKey.service')
      }, {
        id: 'sales',
        name: this.intl.t('pages.calendar.colorKey.sales')
      }, {
        id: 'removal',
        name: this.intl.t('pages.calendar.colorKey.removal')
      }, {
        id: 'time_off',
        name: this.intl.t('pages.calendar.colorKey.time_off')
      }];
    }

    filterValues(filterData) {
      var hiddenAppointmentTypes = filterData && filterData.length > 0 ? filterData[0].substring(filterData[0].indexOf('[') + 1, filterData[0].length - 1).replaceAll('\'', '').split(',') : [];

      if (hiddenAppointmentTypes.includes('sales')) {
        hiddenAppointmentTypes.push('sale');
      }

      this.CalendarDataService.hiddenAppointmentTypes = hiddenAppointmentTypes;
      return hiddenAppointmentTypes;
    }

    get weeks() {
      return this.getWeeksTask.perform();
    }

    populateSummaryData(dateData) {
      this.summaryData = Ember.Object.create({
        total: dateData.total,
        typeTotals: Ember.Object.create({})
      });
      Object.entries(dateData.type_totals).forEach(([key, value]) => {
        if (value.total > 0 && !this.hiddenTypes.includes(key)) {
          this.summaryData.typeTotals[key] = Ember.Object.create({
            count: value.total,
            calendarEventType: {
              name: this.intl.t('pages.calendar.colorKey.' + key),
              styleColor: "background-color: ".concat(this.eventColorByTypeName(key), ";")
            },
            calendarEventDetailTypeList: {}
          });

          for (var [subkey, subvalue] of Object.entries(value)) {
            if (subkey !== 'total') {
              this.summaryData.typeTotals[key].calendarEventDetailTypeList[subkey] = {
                count: subvalue.count,
                calendarEventDetailType: {
                  name: subvalue.name,
                  styleColor: "background-color: ".concat(subvalue.color, ";")
                }
              };
            }
          }
        } else {
          this.summaryData.total -= value.total;
        }
      });
    }

    generateWeeksData(dateData) {
      var _this = this;

      var firstDay = (0, _moment.default)(this.calendarDate).startOf('month');
      var lastDay = (0, _moment.default)(this.calendarDate).endOf('month');
      var days = Ember.A([]);

      for (var _i = 0; _i < firstDay.day(); _i++) {
        // pad with empty days to fill first week of month until first day of month
        days.pushObject({
          isCurrentMonth: false
        });
      }

      var day = (0, _moment.default)(firstDay);

      var _loop = function () {
        var copy = (0, _moment.default)(day);
        var dayData = {};
        var dayTotal = 0;

        if (dateData[copy.format('YYYY-MM-DD')]) {
          dayData = dateData[copy.format('YYYY-MM-DD')];

          _this.hiddenTypes.forEach(typeToHide => {
            dayData[typeToHide] = 0;
          });

          dayTotal = Object.values(dayData).reduce((total, value) => total + value);
        }

        days.pushObject(Ember.Object.create({
          isCurrentMonth: true,
          date: copy,
          day: copy.date(),
          totalsData: dayData,
          dayTotal
        }));
        day.add(1, 'd');
      };

      while (day.isSameOrBefore(lastDay)) {
        _loop();
      }

      for (var _i2 = lastDay.day(); _i2 < 6; _i2++) {
        // pad with empty days to fill last row of month
        days.pushObject({
          isCurrentMonth: false
        });
      }

      var weeks = Ember.A([]);
      var i = 0;

      while (days[i]) {
        weeks.pushObject(Ember.Object.create({
          days: Ember.A(days.slice(i, i + 7))
        }));
        i += 7;
      }

      return Ember.A(weeks);
    }

    selectDay(day) {
      this.selectedDay = Ember.Object.create(day);
      this.calendarDate = (0, _moment.default)(day.date);
      this.CalendarDatePickerService.selectedDates = Ember.A([Ember.Object.create({
        date: day.date,
        selected: true
      })]);
      this.CalendarDataService.calendarDatePages = Ember.A();
      this.getAppointmentsTask.perform();
      Ember.notifyPropertyChange(this, 'selectedDay');
      Ember.notifyPropertyChange(this, 'calendarDate');
    }

    updateStartDate(date) {
      var calendarDate = (0, _moment.default)(this.calendarDate);
      this.selectDay(Ember.Object.create({
        date: date.moment,
        day: date.moment.date()
      }));

      if (calendarDate.month() != date.moment.month() || calendarDate.year() != date.moment.year()) {
        Ember.notifyPropertyChange(this, 'weeks');
      }
    }

    navPrevMonth() {
      this.calendarDate = (0, _moment.default)(this.calendarDate).subtract(1, 'months');
      Ember.notifyPropertyChange(this, 'weeks');
    }

    navNextMonth() {
      this.calendarDate = (0, _moment.default)(this.calendarDate).add(1, 'months');
      Ember.notifyPropertyChange(this, 'weeks');
    }

    toggleDisplay(node) {
      if (node.calendarEventType.name == this.curSubType) {
        this.curSubType = '';
      } else {
        this.curSubType = node.calendarEventType.name;
      }
    }

    updateDates() {
      this.getAppointmentsTask.perform();
      Ember.notifyPropertyChange(this, 'weeks');
    }

    filterMonthView() {
      this.CalendarDataService.calendarDatePages = Ember.A();
      this.updateDates();
    }

    clearFilter() {
      this.hiddenAppointmentTypes = [];
      this.CalendarDataService.calendarDatePages = Ember.A();
      this.updateDates();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "CalendarDatePickerService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "CalendarEventsService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "CalendarDataService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "screen", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "stFiltersService", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "selectedDay", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "appointmentsList", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "_weeks", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "calendarDate", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "height", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "summaryData", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "curSubType", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "hiddenAppointmentTypes", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "_hiddenAppointmentTypes", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "weeksSpinner", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "getAppointmentsTask", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "_getAppointmentsTask", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "getWeeksTask", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "updateWeeksTask", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "selectDay", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "selectDay"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateStartDate", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "updateStartDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "navPrevMonth", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "navPrevMonth"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "navNextMonth", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "navNextMonth"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleDisplay", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "toggleDisplay"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateDates", [_dec28], Object.getOwnPropertyDescriptor(_class.prototype, "updateDates"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterMonthView", [_dec29], Object.getOwnPropertyDescriptor(_class.prototype, "filterMonthView"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilter", [_dec30], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilter"), _class.prototype)), _class));
  _exports.default = CalendarMonthView;
});