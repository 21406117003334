define("securitytrax-ember-ui/administration/company-settings/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    pageTitle: Ember.inject.service(),
    intl: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform();
    },

    setupController(controller) {
      this._super(...arguments);

      var breadCrumbs = [{
        name: this.intl.t('pages.administration.company_settings.title'),
        last: true,
        link: 'company-settings',
        showArrow: false
      }];
      var breadCrumbData = {
        //titleLink: 'administration',
        hide: true
      };
      controller.set('breadCrumbs', breadCrumbs);
      controller.set('breadCrumbData', breadCrumbData);
    },

    afterModel: function () {
      this.pageTitle.setPageTitle(null, 'pages.administration.company_settings.title');
    }
  });

  _exports.default = _default;
});