define("securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/adc/service-plan/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/adc/adc-base", "ember-concurrency"], function (_exports, _adcBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adcBase.default.extend({
    ispService: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('servicePlan', 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.updatePlan.perform(multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary service-plan-save-button'
      }]);
    },

    updatePlan: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var attributes = this.attributes;
      var customerInformation = yield this.customerInformation;
      attributes['new_package_id'] = customerInformation.service_plan_info.package_id;
      var selectedPackage = (yield this.adcPackages).find(adcPackage => {
        return adcPackage.id === customerInformation.service_plan_info.package_id;
      });

      if (attributes['destinationBranchId']) {
        yield this.updateAccount.perform(multiStepForm, buttonPromise, 'alarm_dot_com__updateCustomerBranch', {
          destination_branch_id: attributes['destinationBranchId']
        }, {
          branch_id: attributes['destinationBranchId']
        }, ['adc-packageId'], 'interactive-services-provider/adc-service-plan');
        delete attributes['destinationBranchId'];
      }

      attributes['add_on_features'] = [];
      customerInformation.service_plan_info.addons.forEach(addon => {
        if (!selectedPackage.included_features || selectedPackage.included_features.findIndex(includedAddon => includedAddon.feature === addon) === -1) {
          attributes['add_on_features'].push({
            [addon]: true
          });
        }
      });
      yield this.updateAccount.perform(multiStepForm, buttonPromise, 'alarm_dot_com__changeServicePlan', attributes, {
        packageId: attributes['new_package_id']
      }, [], 'interactive-services-provider/adc-service-plan');
      buttonPromise.then(() => {
        this.notifyPropertyChange('customerInformation');
        multiStepForm.currentStepNameKeySetVal('customerInformation');
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
      });
    })
  });

  _exports.default = _default;
});