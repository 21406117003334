define("securitytrax-ember-ui/components/multi-step-form/forms/notes/task-template-select/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/notes/base-notes", "ember-concurrency", "lodash"], function (_exports, _baseNotes, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseNotes.default.extend({
    intl: Ember.inject.service(),
    currentTaskId: null,
    templateOptions: null,
    loaded: false,
    userService: Ember.inject.service(),
    userList: null,

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      var modelName = this.modelName;
      var buttonChoices = [{
        text: this.intl.t('common.actions.skip'),
        click: () => {
          multiStepForm.currentStepNameKeySetVal('customerTaskSingleStep');
        },
        classNamesString: 'st-btn st-btn-secondary'
      }, {
        text: this.intl.t('common.actions.start'),
        click: buttonPromise => {
          buttonPromise.then(() => {
            this.set('selectedTemplate', this.currentTaskId);
            multiStepForm.currentStepNameKeySetVal('customerTaskSingleStep');
          });
        },
        classNamesString: 'st-btn st-btn-primary create-form-chooser-start'
      }];
      multiStepForm.registerStepData("".concat(modelName, "Chooser"), 'buttons', buttonChoices);
    },

    activeTemplatesTask: (0, _emberConcurrency.task)(function* () {
      var intl = this.intl;
      var taskHashData = yield (0, _emberConcurrency.hash)({
        salesList: this.userService.getUsersList('sales_rep'),
        techList: this.userService.getUsersList('tech'),
        taskTemplates: this.taskTemplates,
        taskTypes: this.noteTypes
      }); //Set User List

      var combinedList = taskHashData.salesList.concat(taskHashData.techList);
      this.set('userList', combinedList);
      var sortedTemplates = [];

      for (var i = 0; i < taskHashData.taskTemplates.get('length'); i++) {
        var taskTypeName = null;
        var currentTemplate = taskHashData.taskTemplates.objectAt(i); // NOTE: templateFieldDefaults is included at least on customer tasks, but leaving yield here in case it needed elsewhere

        var fieldDefaults = yield currentTemplate.get('sortedTemplateFieldDefaults');
        var assignedUserOptions = fieldDefaults[1].get('templateTypeColumn.options');
        var priorityOptions = fieldDefaults[3].get('templateTypeColumn.options'); // NOTE: this list is cached and so only yields the first time

        var assignedUser = yield this.userList.findBy('id', fieldDefaults[1].get('value')); //Get Task Type

        if (fieldDefaults[0].get('value')) {
          var tempTaskStep = taskHashData.taskTypes.findBy('id', fieldDefaults[0].get('value'));
          taskTypeName = tempTaskStep.get('name');
        }

        sortedTemplates.push({
          packageName: currentTemplate.get('name'),
          packageId: currentTemplate.get('id'),
          packageContent: [{
            label: intl.t('pages.customers.notes.priority'),
            description: fieldDefaults[3].get('value') ? _lodash.default.invert(priorityOptions)[fieldDefaults[3].get('value')] : ''
          }, {
            label: intl.t('pages.administration.content_management.task_templates.subject'),
            description: fieldDefaults[4].get('value') ? fieldDefaults[4].get('value') : ''
          }, {
            label: intl.t('pages.administration.content_management.task_templates.type'),
            description: taskTypeName ? taskTypeName : ''
          }, {
            label: intl.t('pages.administration.content_management.task_templates.assigned_to'),
            description: _lodash.default.invert(assignedUserOptions)[fieldDefaults[1].get('value')] || (assignedUser ? assignedUser.name : '')
          }]
        });
      }

      this.set('templateOptions', sortedTemplates);
      this.set('loaded', true);
    }).on('init')
  });

  _exports.default = _default;
});