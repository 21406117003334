define("securitytrax-ember-ui/components/multi-step-form/forms/tech-today/check-out/component", ["exports", "@glimmer/component", "ember-concurrency", "lodash"], function (_exports, _component, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CheckOut = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('events/tech-today'), _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = (0, _emberConcurrency.task)(function* () {
    var hashedData = yield (0, _emberConcurrency.hash)({
      dryRunReasons: this.store.findAll('dryRunReason'),
      currCalendarEvent: this.args.currCalendarEvent,
      finishedCopyingModels: (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true)
    });
    Ember.setProperties(this, hashedData);
  }), _dec10 = (0, _emberConcurrency.task)(function* (buttonPromise, multiStepForm) {
    try {
      var browserLocationData = yield this.browserGeoLocationService.get('getBrowserLocation');

      var latitude = _lodash.default.get(browserLocationData, 'coords.latitude', false);

      var longitude = _lodash.default.get(browserLocationData, 'coords.longitude', false);

      var checkOutData = {
        data: {}
      };

      if (browserLocationData && latitude && longitude) {
        checkOutData['data']['latitude'] = latitude;
        checkOutData['data']['longitude'] = longitude;
      }

      if (this.args.appointmentIncomplete) {
        this.currCalendarEvent.set('dryRunReason', this.dryRunReason);
        yield this.currCalendarEvent.save();
      }

      yield this.currCalendarEvent.invokeAction('check_out', {
        verb: 'POST',
        data: checkOutData
      });
      buttonPromise.then(() => {
        multiStepForm.formStartedSetVal(false);
        this.techToday.trigger('reset-checked-in-appointment-data', this.currCalendarEvent);
      });
    } catch (error) {
      (false && Ember.warn(error, {
        id: 'techToday.checkOutAppointmentTask'
      }));
    }
  }), _dec11 = (0, _emberConcurrency.task)(function* (buttonPromise, multiStepForm) {
    try {
      yield this.currCalendarEvent.invokeAction('reset_check_in', {
        verb: 'POST',
        data: {
          data: {
            timestamp: '0'
          }
        }
      }); // TURN THIS BACK ON WHEN BE BUG FIXED
      // const appointmentStatusOutData = yield currCalendarEvent.invokeAction('reset_check_out', {
      //     verb: 'POST',
      //     data: {
      //         data: {
      //             timestamp: '0'
      //         }
      //     }
      // });

      buttonPromise.then(() => {
        multiStepForm.formStartedSetVal(false);
        this.techToday.trigger('reset-checked-in-appointment-data', this.currCalendarEvent);
      });
    } catch (error) {
      (false && Ember.warn(error, {
        id: 'techToday.checkOutAppointmentTask'
      }));
    }
  }), _dec12 = Ember._action, (_class = (_temp = class CheckOut extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "techToday", _descriptor3, this);

      _initializerDefineProperty(this, "browserGeoLocationService", _descriptor4, this);

      _initializerDefineProperty(this, "screen", _descriptor5, this);

      _initializerDefineProperty(this, "dryRunReason", _descriptor6, this);

      _initializerDefineProperty(this, "dryRunReasons", _descriptor7, this);

      _initializerDefineProperty(this, "currCalendarEvent", _descriptor8, this);

      _initializerDefineProperty(this, "initFormData", _descriptor9, this);

      _initializerDefineProperty(this, "checkOutAppointmentTask", _descriptor10, this);

      _initializerDefineProperty(this, "resetCheckOutInAppointmentTask", _descriptor11, this);

      this.initFormData.perform();
    }

    buttons() {
      this.args.multiStepForm.registerStepData('checkOut', 'buttons', [{
        text: this.intl.t('pages.customers.techToday.checkOut'),
        click: buttonPromise => {
          return this.checkOutAppointmentTask.perform(buttonPromise, this.args.multiStepForm);
        },
        classNamesString: 'st-btn st-btn-primary checkout'
      }]);

      if (this.args.appointmentIncomplete) {
        this.args.multiStepForm.registerStepData('checkOut', 'title', this.intl.t('pages.customers.techToday.checkoutIncomplete'));
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "techToday", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "browserGeoLocationService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "screen", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "dryRunReason", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "dryRunReasons", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "currCalendarEvent", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "initFormData", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "checkOutAppointmentTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "resetCheckOutInAppointmentTask", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "buttons", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "buttons"), _class.prototype)), _class));
  _exports.default = CheckOut;
});