define("securitytrax-ember-ui/accounting/funding-entries/edit-funding/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ImXHlRls",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"fundingId\",\"parentRoute\",\"preSelectedInvoiceType\"],[[35,0,[\"customerFundingId\"]],[35,0,[\"parentRoute\"]],\"detail\"]]]]],\"hasEval\":false,\"upvars\":[\"model\",\"modals/sub-route-modals/edit-create-funding\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/accounting/funding-entries/edit-funding/template.hbs"
    }
  });

  _exports.default = _default;
});