define("securitytrax-ember-ui/helpers/st-timer-end", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    stTimers: Ember.inject.service(),

    compute(params
    /*, hash*/
    ) {
      var timerKey = params[0];
      var extraComment = '';

      if (params[1]) {
        extraComment = params[1];
      }

      this.stTimers.endTimer(timerKey, extraComment);
    }

  });

  _exports.default = _default;
});