define("securitytrax-ember-ui/administration/human-resources/permissions/group-details/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/route-pageview-logging", "securitytrax-ember-ui/mixins/has-page-title", "ember-concurrency"], function (_exports, _authenticatedRouteMixin, _routePageviewLogging, _hasPageTitle, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _routePageviewLogging.default, _hasPageTitle.default, {
    _model: undefined,

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['groups.manage_permissions', 'groups.modify'], 'one');
    },

    model({
      id
    }) {
      return this.setupModelTask.perform(id);
    },

    setupModelTask: (0, _emberConcurrency.task)(function* (id) {
      try {
        var model = yield this.store.findRecord('group', id);
        this.set('_model', model);
        return model;
      } catch (err) {
        this.transitionTo('administration.human-resources.permissions');
      }
    }),
    parentController: Ember.computed(function () {
      return this.controllerFor('administration.human-resources');
    }),
    groupsController: Ember.computed(function () {
      return this.controllerFor('administration.human-resources.permissions');
    }),

    setupController(controller, model) {
      this._super(controller, model);

      this.set('_model', model);
      this.setupBreadCrumbs(model);
    },

    getPageTitle() {
      return this.intl.t('pages.administration.human_resources.groups');
    },

    getRouteName() {
      return "pages.administration.nav.users-permissions";
    },

    setupBreadCrumbs(model) {
      var breadCrumbs = [{
        name: this.intl.t('pages.administration.human_resources.groups'),
        last: false,
        previousLink: 'administration.human-resources.user-list',
        link: 'administration.human-resources.permissions'
      }, {
        name: model.get('name'),
        last: true,
        previousLink: 'administration.human-resources.permissions',
        link: 'administration.human-resources.permissions.group-details'
      }];
      var breadCrumbData = {
        title: this.intl.t('pages.administration.human_resources.title'),
        titleLink: 'administration.human-resources.user-list',
        hide: false
      };
      this.parentController.set('breadCrumbs', breadCrumbs);
      this.parentController.set('breadCrumbData', breadCrumbData);
    },

    actions: {
      didTransition() {
        var model = this._model;

        if (model) {
          this.setupBreadCrumbs(model);
        }
      }

    }
  });

  _exports.default = _default;
});