define("securitytrax-ember-ui/components/modals/sub-route-modals/manage-telus/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var ManageTelus = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = (0, _emberConcurrency.task)(function* () {
    this.formMode = this.args.customerSystemInformation.get('centralStationId') ? 'edit' : 'create';
    this.formStarted = true;
    var titleData = {
      monitorTypeName: this.args.customer.get('customerOrderInformation.monitoringCompany.monitoringCompanyType.name'),
      customerName: this.args.customer.get('flname'),
      id: this.args.customer.get('id')
    };
    this.title = this.formMode == 'create' ? this.intl.t('pages.customers.system.telus.create_title', titleData) : this.intl.t('pages.customers.system.telus.edit_title', titleData);

    if (this.formMode !== 'create') {
      yield this.initTelusManageData.perform();
    }
  }), _dec13 = (0, _emberConcurrency.task)(function* () {
    var hashedData = yield (0, _emberConcurrency.hash)({
      customerState: yield this.centralStationService.postData({
        model: this.args.customer,
        rawResponse: false,
        method: 'adt_canada__getCustomerState'
      }),
      installations: yield this.centralStationService.postData({
        model: this.args.customer,
        rawResponse: false,
        method: 'adt_canada__getInstallations'
      })
    });
    this.installationInfo = hashedData.installations.installations[0];
    this.customerState = hashedData.customerState;
  }), _dec14 = (0, _emberConcurrency.task)(function* () {
    yield this.initTelusManageData.perform();
    this.title = this.intl.t('pages.customers.system.telus.edit_title', {
      monitorTypeName: this.args.customer.get('customerOrderInformation.monitoringCompany.monitoringCompanyType.name'),
      customerName: this.args.customer.get('flname'),
      id: this.args.customer.get('id')
    });
    this.formMode = 'edit';
    this.store.findRecord('customerSystemInformation', this.args.customer.get('id')).then(systemInfo => {
      systemInfo.reload();
    });
  }), _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, (_class = (_temp = class ManageTelus extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "grants", _descriptor2, this);

      _initializerDefineProperty(this, "centralStationService", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "store", _descriptor5, this);

      _initializerDefineProperty(this, "formStarted", _descriptor6, this);

      _initializerDefineProperty(this, "formMode", _descriptor7, this);

      _initializerDefineProperty(this, "firstStep", _descriptor8, this);

      _initializerDefineProperty(this, "customerState", _descriptor9, this);

      _initializerDefineProperty(this, "installationInfo", _descriptor10, this);

      _initializerDefineProperty(this, "title", _descriptor11, this);

      _initializerDefineProperty(this, "initData", _descriptor12, this);

      _initializerDefineProperty(this, "initTelusManageData", _descriptor13, this);

      _initializerDefineProperty(this, "transitionToManage", _descriptor14, this);

      this.initData.perform();
    }

    get formOriginalModel() {
      var grants = this.grants;
      return Ember.Object.create({
        customer: {
          model: this.args.customer,
          copyRelationMap: {
            customerSystemInformation: {
              doCopy: grants.getIndividualGrant('customer_system_information.view'),
              doValidate: grants.getIndividualGrant('customer_system_information.view'),
              doSave: grants.getIndividualGrant('customer_system_information.modify'),
              relationships: {
                customerAbortCode: {
                  doCopy: grants.getIndividualGrant('customer_abort_codes.view'),
                  doValidate: grants.getIndividualGrant('customer_abort_codes.view'),
                  doSave: grants.getIndividualGrant('customer_abort_codes.modify')
                }
              }
            }
          }
        }
      });
    }

    get formSteps() {
      var intl = this.intl;
      var firstStepComponentPath = this.formMode == 'create' ? 'create-account' : 'general';
      return Ember.Object.create({
        general: Ember.Object.create({
          stepNameKey: 'general',
          title: this.title,
          stepComponentPath: 'multi-step-form/forms/system/telus/' + firstStepComponentPath,
          progressBarTitle: intl.t('pages.customers.system.order.general')
        }),
        contract: Ember.Object.create({
          stepNameKey: 'contract',
          title: this.title,
          stepComponentPath: 'multi-step-form/forms/system/telus/contract',
          progressBarTitle: intl.t('pages.customers.system.telus.contract.title')
        }),
        testMode: Ember.Object.create({
          stepNameKey: 'testMode',
          title: this.title,
          stepComponentPath: 'multi-step-form/forms/system/telus/test-mode',
          progressBarTitle: intl.t('pages.customers.system.telus.test_mode.title')
        }),
        alarmLogs: Ember.Object.create({
          stepNameKey: 'alarmLogs',
          title: this.title,
          stepComponentPath: 'multi-step-form/forms/system/telus/alarm-logs',
          progressBarTitle: intl.t('pages.customers.system.telus.logs.title')
        })
      });
    }

    transitionBackToParentRoute() {
      this.router.transitionTo(this.args.parentRoute, this.args.transitionId);
    }

    updateInstallInfo(installInfo) {
      this.installationInfo = installInfo;
    }

    updateCustomerState(customerState) {
      this.customerState = customerState;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "formStarted", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "formMode", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'edit';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "firstStep", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'general';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "customerState", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "installationInfo", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "title", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "initData", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "initTelusManageData", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "transitionToManage", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "transitionBackToParentRoute", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "transitionBackToParentRoute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateInstallInfo", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "updateInstallInfo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateCustomerState", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "updateCustomerState"), _class.prototype)), _class));
  _exports.default = ManageTelus;
});