define("securitytrax-ember-ui/components/customer/dashboard/risk-score-card/component", ["exports", "@glimmer/component", "ember-concurrency", "moment"], function (_exports, _component, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var RiskScoreCard = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = (0, _emberConcurrency.task)(function* () {
    if (this.args.useDefaultData) {
      this.riskScores = this.defaultData;
    } else {
      try {
        var customerRiskScores = yield this.ispService.get('invokeIspActionTask').perform({
          model: this.args.customer,
          action: 'alarm_dot_com__getCustomerRiskScoreData',
          attributes: {}
        });
        this.riskScores = customerRiskScores.customer_risk_score_data.customer_risk_scores[0].risk_score_details_list;
      } catch (error) {
        (false && Ember.warn(error, {
          id: 'app.component.customer.dashboard.risk-score-card.risk-score-graph'
        }));
        return;
      }
    }

    if (this.riskScores.length < 6) {
      this.riskScores.reverse(); // so we can easily push empty ones on the end if length < 6

      while (this.riskScores.length < 6) {
        var prevMonthId = this.riskScores[this.riskScores.length - 1].month_id;
        var monthId = prevMonthId % 100 % 12 == 1 ? prevMonthId - 89 : prevMonthId - 1;
        this.riskScores.push({
          month_id: monthId,
          risk_score: 0,
          risk_type: 'none'
        });
      }

      this.riskScores.reverse();
    } else {
      this.riskScores = this.riskScores.slice(-6);
    }

    var lastMonthWholeScore = 2;
    this.riskScores.forEach(riskScore => {
      riskScore.monthInitial = (0, _moment.default)(riskScore.month_id.toString()).format('MMMM').slice(0, 1);
      riskScore.wholeScore = Number(riskScore.risk_score) < .02 ? 2 : Math.min(.20, Number(riskScore.risk_score)) * 500;
      riskScore.height = 'height: ' + riskScore.wholeScore + 'px;';
      riskScore.barClass = riskScore.risk_type.toLowerCase();
      this.lastMonthBarClass = riskScore.barClass;
      lastMonthWholeScore = riskScore.wholeScore;
    });
    this.scoreLevelOffset = 'margin-top: ' + (100 - lastMonthWholeScore) + 'px;';
  }), (_class = (_temp = class RiskScoreCard extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "ispService", _descriptor2, this);

      _defineProperty(this, "lastMonthBarClass", 'none');

      _initializerDefineProperty(this, "riskScoreTask", _descriptor3, this);

      this.riskScoreTask.perform();
    }

    get defaultData() {
      return [{
        month_id: 202109,
        risk_score: 0.0340,
        risk_type: 'Low'
      }, {
        month_id: 202110,
        risk_score: 0.0490,
        risk_type: 'Medium'
      }, {
        month_id: 202111,
        risk_score: 0.0590,
        risk_type: 'High'
      }, {
        month_id: 202112,
        risk_score: 0.0600,
        risk_type: 'Critical'
      }, {
        month_id: 202201,
        risk_score: 0.2000,
        risk_type: 'Critical'
      }, {
        month_id: 202202,
        risk_score: 0.1000,
        risk_type: 'Critical'
      }];
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ispService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "riskScoreTask", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = RiskScoreCard;
});