define("securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/interactive-services-providers/interactive-services-provider-type-default/toolbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gcQC/9aZ",
    "block": "{\"symbols\":[\"@multiStepForm\",\"@customer\"],\"statements\":[[8,\"html/st-await\",[],[[\"@booleanLoaded\"],[[34,0,[\"finishedCopyingModels\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"toolbox-menu__sub-menu-title\"],[12],[2,\"\\n        \"],[10,\"div\"],[12],[1,[30,[36,1],[\"pages.home.toolbox.isp.title\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"nav/top-nav-sub/tech-toolbox/btn-none-selected\",[],[[\"@title\",\"@buttonText\",\"@customer\"],[[30,[36,1],[\"pages.home.toolbox.isp.title\"],null],[32,1,[\"formCopiedModels\",\"customerOrderInformation\",\"model\",\"interactiveServicesProvider\",\"name\"]],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"multiStepForm\",\"t\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/interactive-services-providers/interactive-services-provider-type-default/toolbox/template.hbs"
    }
  });

  _exports.default = _default;
});