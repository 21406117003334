define("securitytrax-ember-ui/my-inventory/purchase-requisitions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EmvE49Cn",
    "block": "{\"symbols\":[],\"statements\":[[8,\"inventory/purchase-requisitions\",[],[[\"@user\",\"@showAdd\"],[[34,0,[\"user\"]],true]],null]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/my-inventory/purchase-requisitions/template.hbs"
    }
  });

  _exports.default = _default;
});