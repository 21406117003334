define("securitytrax-ember-ui/customers/work-orders/edit-create-work-order/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F17kHusm",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modals/sub-route-modals/edit-create-work-orders\",[],[[\"@customer\",\"@workOrderId\",\"@parentRoute\",\"@users\"],[[34,0,[\"customer\"]],[34,0,[\"workOrderId\"]],[34,0,[\"parentRoute\"]],[34,0,[\"users\"]]]],null],[2,\"\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"model\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/customers/work-orders/edit-create-work-order/template.hbs"
    }
  });

  _exports.default = _default;
});