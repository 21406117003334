define("securitytrax-ember-ui/components/multi-step-form/forms/administration/users/my-inventory/spillage/review/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NySE6JKI",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"grid-x grid-padding-x\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cell large-12\"],[12],[2,\"\\n        \"],[1,[30,[36,3],null,[[\"name\",\"formMode\",\"label\",\"value\"],[\"vendor\",[35,2],[30,[36,1],[\"pages.administration.company_settings.inventory.spillage.comments\"],null],[35,0,[\"comments\"]]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,5],null,[[\"pendingEquipmentLineItems\"],[[35,4]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"spillage\",\"t\",\"formMode\",\"multi-step-form/switchable-inputs/text-input\",\"pendingEquipmentLineItems\",\"inventory/review-spillage\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/users/my-inventory/spillage/review/template.hbs"
    }
  });

  _exports.default = _default;
});