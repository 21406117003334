define("securitytrax-ember-ui/components/multi-step-form/switchable-inputs/select-input/select-input-create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3DXD5PtT",
    "block": "{\"symbols\":[\"@placeholder\",\"@inputType\",\"@maskedInput\",\"@bubblesUp\"],\"statements\":[[10,\"div\"],[14,0,\"dropdown-add-section-line\"],[12],[13],[2,\"\\n\\n\"],[8,\"html/multi-select/building-blocks/single-input-create\",[],[[\"@createAction\",\"@optionLabel\",\"@inputLabel\",\"@placeholder\",\"@type\",\"@maskInput\",\"@errorMessage\",\"@bubblesUp\"],[[30,[36,0],[[32,0],\"createNewEntity\"],null],[34,1],[34,2],[32,1],[32,2],[32,3],[34,3],[32,4]]],null]],\"hasEval\":false,\"upvars\":[\"action\",\"optionLabel\",\"inputLabel\",\"errorMessage\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/switchable-inputs/select-input/select-input-create/template.hbs"
    }
  });

  _exports.default = _default;
});