define("securitytrax-ember-ui/home/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Controller
   * @extends Ember.Controller
   * @memberof customers
   */
  var Controller = Ember.Controller.extend({
    navigation: Ember.inject.service(),
    actions: {
      toggleSettings() {
        this.model.editDashboard.toggleConfig();
      }

    }
  });
  var _default = Controller;
  _exports.default = _default;
});