define("securitytrax-ember-ui/transforms/moment-date-time", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * API is ISO
   * model is moment object
   */
  var _default = _emberData.default.DateTransform.extend({
    deserialize(serialized) {
      if (serialized) {
        return (0, _moment.default)(serialized).format(); //only using moment object has issues in other timezones due to _d value
        //to see issue: set computer to different timezone, test task due date time.
        //return moment(serialized); 
      }

      return null;
    },

    serialize(deserialized) {
      if (deserialized) {
        //return deserialized.format('YYYY-MM-DD HH:mm:ss');
        return _moment.default.tz(deserialized, _moment.default.tz.guess()).format(); //BE will take the full time with timezone and save accordingly.
      }

      return null;
    }

  });

  _exports.default = _default;
});