define("securitytrax-ember-ui/components/pages/all-tasks/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/customer-task-mixin", "securitytrax-ember-ui/utils/obj-helpers", "securitytrax-ember-ui/mixins/table-column-sort", "securitytrax-ember-ui/classes/misc-input-list"], function (_exports, _emberConcurrency, _customerTaskMixin, _objHelpers, _tableColumnSort, _miscInputList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_customerTaskMixin.default, _tableColumnSort.default, {
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    tagsService: Ember.inject.service(),
    session: Ember.inject.service(),
    stAjax: Ember.inject.service(),
    homeDashboard: Ember.inject.service('events/home-dashboard'),
    userService: Ember.inject.service(),
    stFiltersService: Ember.inject.service(),
    screen: Ember.inject.service(),
    createdDateFrom: undefined,
    createdDateTo: undefined,
    dueDateFrom: undefined,
    dueDateTo: undefined,
    tagName: '',
    limit: 50,
    page: 1,
    tasksListCount: 0,
    sortedCustomerTasks: undefined,
    assignedUsers: undefined,
    createdByUsers: undefined,
    noteTypes: undefined,
    addCompleted: false,
    miscFilters: undefined,
    tagFilter: undefined,

    init() {
      this._super(...arguments);

      this.homeDashboard.on('refresh-tasks-due-today', this, '_eventCallback');
      (0, _objHelpers.initializeState)(this, {
        miscFilters: new _miscInputList.default(),
        tagFilter: this.tagsService.tagFilter(),
        stSortableColumnOptions: [{
          name: this.intl.t('common.columns.id') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'id',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.id') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-id',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('common.columns.assignedTo') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'assigned_user',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.assignedTo') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-assigned_user',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('common.columns.due_date') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'due_date',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.due_date') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-due_date',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('common.columns.created_date') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'created_at',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.created_date') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-created_at',
          chevronDirection: 'down'
        }]
      });
      this.sortPaginatedList('created_at', false);

      try {
        this.miscFilters.addMiscInput();
      } catch (error) {
        this.set('miscFilters', new _miscInputList.default());
        this.miscFilters.addMiscInput();
      }

      this.initTask.perform();
    },

    willDestroy() {
      this.homeDashboard.off('refresh-tasks-due-today', this, '_eventCallback');
    },

    _eventCallback() {
      Ember.notifyPropertyChange(this, 'groupedTasks');
    },

    initTask: (0, _emberConcurrency.task)(function* () {
      Ember.set(this, 'tagsOptions', yield this.tagsService.tagsOptionsTask.perform('customer_note', 'customer_note_task'));
    }),
    sortedUsers: Ember.computed('model.users', function () {
      var users = this.get('model.users');

      if (users) {
        return users.sortBy('lfname');
      }

      return [];
    }),
    userOptions: Ember.computed(function () {
      return this.store.query('user', {
        limit: 0
      });
    }),
    assignedToUserOptions: Ember.computed(function () {
      return this.assignedToUserOptionsTask.perform();
    }),
    assignedToUserOptionsTask: (0, _emberConcurrency.task)(function* () {
      return yield this.userService.getUsersList('task_assignee');
    }),
    sortedNoteTypes: Ember.computed('model.noteTypes', function () {
      var noteTypes = this.get('model.noteTypes');

      if (noteTypes) {
        return noteTypes.sortBy('name');
      }

      return [];
    }),
    miscFilterOperands: Ember.computed(function () {
      var intl = this.intl;
      return [{
        option: intl.t('common.filters.id'),
        value: 'id'
      }, {
        option: intl.t('common.filters.subject'),
        value: 'subject'
      }];
    }),
    miscFilterOperators: Ember.computed(function () {
      var intl = this.intl;
      return [{
        option: intl.t('common.filters.equals'),
        value: '='
      }, {
        option: intl.t('common.filters.starts_with'),
        value: '~%'
      }, {
        option: intl.t('common.filters.contains'),
        value: '%~%'
      }];
    }),
    groupedTasksList: Ember.computed(function () {
      return this.setTaskListTask.perform();
    }),
    setTaskListTask: (0, _emberConcurrency.task)(function* () {
      var newFilters = yield this.stFiltersService.getFiltersByGroupKey('allTasksListFilter', ['is_closed = false']);
      var queryParams = {
        filter: newFilters,
        sort: this.stSortString ? this.stSortString + ',-priority' : '-priority,due_date',
        include: 'customer.customer_system_information.install_calendar_event.calendar_user.user,assigned_user,tags,customer_communications,customer.lead_source_information.lead_company,customer.customer_order_information.interactive_services_provider.interactive_services_provider_type,customer.customer_order_information.monitoring_company.monitoring_company_type',
        limit: this.limit,
        page: this.page
      };

      if (this.page == 1) {
        this.getCount.perform(newFilters);
      }

      var list = yield this.store.query('customerTask', queryParams);
      list.forEach(task => {
        if (task.get('priority') === null) {
          task.set('priority', 0);
        }
      });
      Ember.set(this, 'sortedCustomerTasks', list);
      Ember.set(this, 'groupedTasks', yield this.groupedTasksTask.perform());
      return this.groupedTasks;
    }).restartable().maxConcurrency(1),
    getCount: (0, _emberConcurrency.task)(function* (filters) {
      try {
        var returnData = yield this.stAjax.request(this.stAjax.getUrl('/aggregate/customer_tasks?filter=' + filters.join(' and ')), {
          method: 'GET'
        });
        this.set('tasksListCount', returnData.data[0].attributes.calculated);
      } catch (err) {//Do nothing
      }
    }),
    actions: {
      taskSearch() {
        this.set('page', 1);
        Ember.notifyPropertyChange(this, 'groupedTasksList');
      },

      getTasks() {
        Ember.notifyPropertyChange(this, 'groupedTasksList');
      },

      clearTaskSearch() {
        Ember.setProperties(this, {
          page: 1,
          createdDateFrom: undefined,
          createdDateTo: undefined,
          dueDateFrom: undefined,
          dueDateTo: undefined,
          assignedUsers: undefined,
          createdByUsers: undefined,
          noteTypes: undefined,
          tagFilter: this.tagsService.tagFilter(),
          'miscFilters.miscInputs': [{
            operand: undefined,
            operator: undefined,
            value: undefined
          }]
        });
        Ember.notifyPropertyChange(this, 'groupedTasksList');
      },

      transitionToSubroute(path) {
        this.router.transitionTo('all-tasks.edit-task', path);
      },

      sortList(column, ascending) {
        this.sortPaginatedList(column, ascending);
        Ember.notifyPropertyChange(this, 'groupedTasksList');
      },

      mobileSortList(column) {
        this.sortMobilePaginatedList(column.value);
        Ember.notifyPropertyChange(this, 'groupedTasksList');
      }

    }
  });

  _exports.default = _default;
});