define("securitytrax-ember-ui/components/multi-step-form/forms/system/affiliated-monitoring/create-edit-account/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency", "securitytrax-ember-ui/utils/obj-helpers", "securitytrax-ember-ui/mixins/customer-integrations-mixin"], function (_exports, _switchableFormStep, _emberConcurrency, _objHelpers, _customerIntegrationsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, _customerIntegrationsMixin.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    centralStationService: Ember.inject.service(),
    customerTemplate: undefined,
    devicePhone: undefined,
    accountNumber: undefined,
    showUnlinkConfirmModal: false,
    showActivationConfirmModal: false,
    progressBarHidden: Ember.computed('isNewRecord', function () {
      return this.isNewRecord;
    }),
    disabledProgressBarSteps: Ember.computed(function () {
      return [];
    }),
    hiddenProgressBarSteps: Ember.computed(function () {
      return [];
    }),
    activationButtonProps: Ember.computed('affiliatedCustomerInformation', function () {
      return this.getActivationButtonProps.perform();
    }),
    getActivationButtonProps: (0, _emberConcurrency.task)(function* () {
      var customerInfo = yield this.affiliatedCustomerInformation;

      if (customerInfo) {
        if (customerInfo.status === 'I') {
          return {
            text: this.intl.t('common.actions.activate'),
            classes: 'st-btn st-btn-primary activate-account',
            confirmString: this.intl.t('pages.customers.system.affiliated.activate_confirm')
          };
        } else {
          return {
            text: this.intl.t('common.actions.deactivate'),
            classes: 'st-btn warning-red activate-account',
            confirmString: this.intl.t('pages.customers.system.affiliated.deactivate_confirm')
          };
        }
      }
    }),
    buttonObserver: Ember.observer('multiStepForm.formSteps', function () {
      this.registerButtons();
    }),

    didInsertElement() {
      this._super(...arguments);

      this.registerButtons();
    },

    initFormTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true);

      if (this.formMode === 'create') {
        (0, _objHelpers.initializeState)(this, {
          customerTemplate: null,
          devicePhone: null,
          accountNumber: this.multiStepForm.formCopiedModels.customer.model.customerSystemInformation.accountNum
        });
      } else {
        var affiliatedCustomerInformation = yield this.affiliatedCustomerInformation;
        (0, _objHelpers.initializeState)(this, {
          customerTemplate: affiliatedCustomerInformation ? affiliatedCustomerInformation.template : null,
          devicePhone: affiliatedCustomerInformation ? affiliatedCustomerInformation.device_phone : null,
          accountNumber: affiliatedCustomerInformation ? affiliatedCustomerInformation.cs_number : null
        });
      }
    }).on('init'),
    affiliatedCustomerInformation: Ember.computed('formMode', function () {
      return this.getAffiliatedCustomerInformation.perform();
    }),
    getAffiliatedCustomerInformation: (0, _emberConcurrency.task)(function* () {
      if (this.formMode !== 'create') {
        try {
          var customerInfo = yield this.centralStationService.getData({
            model: this.customer,
            method: 'affiliated_monitoring__getAccount'
          });
          return customerInfo.account;
        } catch (err) {
          this.stNotificationManager.addErrorMessages(err, 'modal');
        }
      } else {
        return null;
      }
    }),
    customerZones: Ember.computed('affiliatedCustomerInformation', function () {
      return this.getCustomerZonesTask.perform();
    }),
    getCustomerZonesTask: (0, _emberConcurrency.task)(function* () {
      var customerEquipment = this.multiStepForm.formCopiedModels.customer.model.customerEquipment;
      var customerZones = [];
      var customerZoneIds = [];
      var zonesObj = {};
      customerEquipment.forEach(equipmentPiece => {
        if (equipmentPiece.zoneNumber && equipmentPiece.equipmentStatus.name === 'Installed' && !customerZoneIds.includes(equipmentPiece.zoneNumber)) {
          customerZones.push({
            zone_id: equipmentPiece.zoneNumber,
            description: equipmentPiece.zone
          });
          customerZoneIds.push(equipmentPiece.zoneNumber);
        }
      });

      if (this.formMode === 'create') {
        zonesObj.zonesInSecurityTrax = customerZones;
        zonesObj.zonesNotInSecurityTrax = [];
        return zonesObj;
      } else {
        var info = yield this.affiliatedCustomerInformation;
        var zonesNotInSecurityTrax = [];
        var zonesInSecurityTrax = [];
        info.zones.forEach(equipmentPiece => {
          if (!customerZoneIds.includes(equipmentPiece.zone_id)) {
            zonesNotInSecurityTrax.push(equipmentPiece);
          } else {
            zonesInSecurityTrax.push(equipmentPiece);
          }
        });
        zonesObj.zonesNotInSecurityTrax = zonesNotInSecurityTrax;
        zonesObj.zonesInSecurityTrax = zonesInSecurityTrax;
        return zonesObj;
      }
    }),
    affiliatedTemplateOptions: Ember.computed(function () {
      return this.affiliatedTemplateOptionsTask.perform();
    }),
    affiliatedTemplateOptionsTask: (0, _emberConcurrency.task)(function* () {
      var templates = [];
      var config = yield this.monitoringCompany.get('monitoringCompanyConfig');

      for (var [key, value] of Object.entries(config.templates)) {
        templates.push(Ember.Object.create({
          id: key,
          name: value
        }));
      }

      if (this.formMode !== 'create') {
        yield this.affiliatedCustomerInformation;
        var currentTemplate = this.customerTemplate;

        if (currentTemplate !== null && !templates.find(arrayTemplate => {
          return arrayTemplate.id === currentTemplate;
        })) {
          templates.push(Ember.Object.create({
            id: currentTemplate,
            name: currentTemplate
          }));
        }
      }

      return templates;
    }),
    saveCreateAccountTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var formattedObjects = Ember.A();
      formattedObjects.pushObject(Ember.Object.create({
        model: {
          csNumber: multiStepForm.formCopiedModels.customer.model.customerSystemInformation.accountNum
        },
        filePath: 'central-station/affiliated-monitoring-create'
      }));
      yield multiStepForm.validateList.perform(formattedObjects);
      yield multiStepForm.validateSyncSaveAll({
        extraValidationData: {
          noValidate: ['location']
        }
      });

      try {
        yield this.centralStationService.postData({
          model: this.customer,
          method: 'affiliated_monitoring__saveAccount',
          payload: {
            'customer__template': this.customerTemplate,
            'device_phone': this.devicePhone
          }
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, 'modal');
        throw err;
      }

      buttonPromise.then(() => {
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
        this.set('formMode', 'edit');
        this.store.findRecord('customerSystemInformation', this.get('customer.id')).then(systemInfo => {
          systemInfo.reload();
        });
      });
    }),
    activateOrDeactivateAccount: (0, _emberConcurrency.task)(function* (buttonPromise) {
      var customerInfo = yield this.affiliatedCustomerInformation;

      try {
        if (customerInfo.status === 'I') {
          yield this.centralStationService.postData({
            model: this.customer,
            method: 'affiliated_monitoring__activateAccount'
          });
        } else {
          yield this.centralStationService.postData({
            model: this.customer,
            method: 'affiliated_monitoring__deactivateAccount'
          });
        }

        buttonPromise.then(() => {
          this.notifyPropertyChange('affiliatedCustomerInformation');
          this.stNotificationManager.addSuccessKey('Account updated successfully');
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'activate-confirm-modal'
        });
        throw err;
      }
    }),

    registerButtons() {
      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('general', 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.saveCreateAccountTask.perform(multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary affiliated-create-account-button'
      }]);
    }

  });

  _exports.default = _default;
});