define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/electronic-document-providers/integrations/docusign/edit-envelope/recipients/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency"], function (_exports, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    store: Ember.inject.service(),
    stAjax: Ember.inject.service(),
    mapWindow: Ember.inject.service(),
    docusignRecipient: null,
    docusignRecipientDeleteId: null,
    docusignStatus: null,
    roleName: null,
    recipientsLoaded: false,
    formStarted: false,
    pinNote: false,
    setupFormData: (0, _emberConcurrency.task)(function* () {
      if (this.get('envelope.envelopeId') && this.hasEvent) {
        yield this.envelope.hasMany('docusignRecipients').reload();
        yield this.multiStepForm.setupCopyModels.perform();
        this.set('hasEvent', false);
      }

      yield (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true);
      var docusignRecipients = this.multiStepForm.formCopiedModelsGetVal('docusignEnvelope.model.docusignRecipients');
      var status = this.multiStepForm.formCopiedModelsGetVal('docusignEnvelope.model.status');
      this.set('docusignStatus', status);
      this.set('docusignRecipients', docusignRecipients);
      this.set('recipientsLoaded', true);
    }).on('init'),
    editRecipientFormSteps: Ember.computed('roleName', function () {
      return Ember.Object.create({
        editDocusignRecipient: Ember.Object.create({
          stepNameKey: 'editDocusignRecipient',
          title: this.roleName,
          stepComponentPath: 'multi-step-form/forms/administration/integrations/electronic-document-providers/integrations/docusign/edit-envelope/recipients/edit-recipient'
        })
      });
    }),
    envelopeStatusObserver: Ember.observer('multiStepForm.formCopiedModels.docusignEnvelope.model.status', function () {
      if (this.multiStepForm.formCopiedModelsGetVal('docusignEnvelope.model.status') && this.docusignStatus != this.multiStepForm.formCopiedModelsGetVal('docusignEnvelope.model.status')) {
        this.refreshRecipients.perform();
      }
    }),
    formOriginalModels: Ember.computed('docusignRecipient', function () {
      return Ember.Object.create({
        docusignRecipient: {
          model: this.docusignRecipient
        }
      });
    }),
    resendRecipientTask: (0, _emberConcurrency.task)(function* (row) {
      var realRow = yield this.store.findRecord('docusignRecipient', row.id);

      try {
        yield realRow.invokeAction('resend', {
          verb: 'POST',
          data: {
            data: {
              type: 'docusign_recipients_action'
            }
          }
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
      }
    }),
    signHereTask: (0, _emberConcurrency.task)(function* (row) {
      try {
        if (row && row.embedded) {
          var embeddedUrl = yield this.stAjax.request(row.responseMeta.actions.get_embedded_url.url, {
            method: 'POST',
            data: {
              data: {
                attributes: {
                  return_url: window.location.protocol + '//' + window.location.host + window.location.pathname + '/?envelopeId=' + row.envelopeId //dont want params

                },
                type: 'docusign_recipients_action'
              }
            }
          }).catch(e => {
            if (e && e.payload && e.payload.error && e.payload.error.pin_message) {
              row.set('pinNote', e.payload.error.pin_message);
            }
          });

          if (embeddedUrl && embeddedUrl.data && embeddedUrl.data.signer_url) {
            window.location = embeddedUrl.data.signer_url;
          } else {
            this.stNotificationManager.addError(this.intl.t('pages.administration.integrations.electronic_document_providers.docusign.embedded_url_missing'), 'modal');
          }
        }
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
        throw err;
      }
    }),
    editDocusignRecipientTask: (0, _emberConcurrency.task)(function* (row) {
      this.set('roleName', row.roleName);
      var docusignRecipient = yield this.store.peekRecord('docusignRecipient', row.id);
      this.set('docusignRecipient', docusignRecipient);

      if (row.pinNote) {
        this.set('pinNote', row.pinNote);
      }

      this.set('formStarted', true);
    }),
    refreshRecipients: (0, _emberConcurrency.task)(function* (checkCanSend = false) {
      this.set('recipientsLoaded', false);
      yield this.envelope.hasMany('docusignRecipients').reload();
      yield this.multiStepForm.setupCopyModels.perform();
      this.setupFormData.perform();

      if (checkCanSend && this.checkCanSend) {
        this.checkCanSend();
      }
    }),
    deleteDocusignRecipientTask: (0, _emberConcurrency.task)(function* (row) {
      this.set('recipientsLoaded', false);
      var recipientToDelete = yield this.store.peekRecord('docusignRecipient', row.get('id'));
      yield recipientToDelete.destroyRecordWithRollbackTask.perform();
      this.set('docusignRecipientDeleteId', false);
      yield this.refreshRecipients.perform();
      this.set('recipientsLoaded', true);
      this.updateFormOriginalModels();
    }),
    actions: {
      afterRecipientEdit() {
        this.refreshRecipients.perform(true);
      },

      openMapWindow(lat, lon) {
        this.mapWindow.openWindow({
          latitude: Number(lat),
          longitude: Number(lon),
          label: 'Signing Location'
        });
      }

    }
  });

  _exports.default = _default;
});