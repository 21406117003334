define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/electronic-document-providers/integrations/docusign/edit-envelope/recipients/edit-recipient/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency"], function (_exports, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    store: Ember.inject.service(),
    mapWindow: Ember.inject.service(),
    recipientTypes: null,
    formModeCompleted: 'edit',
    setupForm: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true);
      var status = this.multiStepForm.formOriginalModelsGetVal('docusignRecipient.model.status');
      var buttonChoices = [];

      if (status != 'completed') {
        buttonChoices.addObject({
          text: this.intl.t('common.actions.save'),
          classNamesString: 'st-btn st-btn-primary envelope-save-button',
          click: buttonPromise => {
            return this.editRecipient.perform(this.multiStepForm, buttonPromise);
          }
        });
      } else {
        this.set('formModeCompleted', 'view');
      }

      this.multiStepForm.registerStepData('editDocusignRecipient', 'buttons', buttonChoices);
      var recipientMetaTypes = yield this.multiStepForm.formOriginalModelsGetVal('docusignRecipient.model.responseMeta.allowed_recipient_types');
      var types = [];
      yield recipientMetaTypes.forEach(type => {
        types.pushObject({
          id: type,
          name: type
        });
      });
      this.set('recipientTypes', types);
    }).on('init'),
    editRecipient: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      try {
        yield multiStepForm.validateSyncSaveAll();
        buttonPromise.then(() => {
          if (this.afterRecipientEdit) {
            this.afterRecipientEdit();
          }

          multiStepForm.formStartedSetVal(false);
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
        });
      } catch (e) {
        this.stNotificationManager.addErrorMessages(e, {
          notificationNamespace: 'modal2'
        });
        throw e;
      }
    }),
    actions: {
      openMapWindow(lat, lon) {
        this.mapWindow.openWindow({
          latitude: Number(lat),
          longitude: Number(lon),
          label: 'Signing Location'
        });
      }

    }
  });

  _exports.default = _default;
});