define("securitytrax-ember-ui/mixins/grouped-line-items-rma", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixed-in-functions/grouped-property-sections"], function (_exports, _emberConcurrency, _groupedPropertySections) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    groupedItems: Ember.computed('items.[]', function () {
      return this.groupedItemsTask.perform(this.items);
    }),
    groupedItemsTask: (0, _emberConcurrency.task)(function* (items) {
      var resolvedItems = yield items;

      if (resolvedItems) {
        var childTasks = [];

        if (resolvedItems) {
          resolvedItems.map(item => {
            childTasks.push(this.mapItemsTask.perform(item));
          });
        }

        var allLineItems = yield (0, _emberConcurrency.all)(childTasks);
        return allLineItems.uniqBy('type.id').map(({
          type
        }) => {
          return Ember.Object.create({
            name: type.get('name'),
            icon: type.get('icon'),
            sections: (0, _groupedPropertySections.default)(),
            items: allLineItems.filterBy('type.id', type.id).mapBy('equipment')
          });
        });
      } else {
        throw new Error('You must have items set for grouped equipment');
      }
    }),
    mapItemsTask: (0, _emberConcurrency.task)(function* (item) {
      var equipment = item.constructor.modelName === 'equipment' ? item : yield item.get('equipment');
      var equipmentItem = equipment ? yield equipment.get('equipmentItem') : null;
      var subType = equipmentItem ? yield equipmentItem.get('equipmentSubType') : null;
      var equipmentType = subType ? yield subType.get('equipmentType') : null;
      var equipmentTypeOther = Ember.Object.create({
        id: 0,
        name: this.intl.t('pages.customers.equipment.columns.other'),
        icon: 'icn-folder-open',
        equipment: equipmentItem
      });
      return {
        equipment: item,
        type: equipmentType ? equipmentType : equipmentTypeOther
      };
    })
  });

  _exports.default = _default;
});