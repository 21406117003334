define("securitytrax-ember-ui/components/customer/accounting/payment-methods-list-add-edit/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    grants: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    customerBilling: null,
    paymentMethodFormMode: 'create',
    paymentMethodFormStarted: false,
    defaultCustomerBilling: null,
    customerBillings: Ember.computed('customer.{customerBillings.@each,defaultCustomerBilling}', function () {
      return this.getCustomerBillingsTask.perform();
    }),
    getCustomerBillingsTask: (0, _emberConcurrency.task)(function* () {
      if (this.grants.getIndividualGrant('customer_billings.view')) {
        var customerBillings = yield this.get('customer.customerBillings');
        customerBillings.forEach(billing => {
          if (billing.isCustomerDefault) {
            Ember.set(this, 'defaultCustomerBilling', billing);
          }
        });

        if (this.detailedView) {
          return this.defaultCustomerBilling ? [this.defaultCustomerBilling] : [];
        } else {
          return customerBillings;
        }
      }

      return null;
    }),
    billTypes: Ember.computed(function () {
      return this.getBillTypesTask.perform();
    }),
    getBillTypesTask: (0, _emberConcurrency.task)(function* () {
      return yield this.store.findAll('billingType');
    }),
    paymentMethodFormOriginalModels: Ember.computed('customerBilling', 'grants.userGrants', function () {
      return Ember.Object.create({
        customerBilling: {
          model: this.customerBilling,
          copyRelationMap: {
            customerBillingBillCcNumber: {
              doCopy: this.grants.getIndividualGrant('customer_billings.view_partial_cc'),
              doSync: this.grants.getIndividualGrant('customer_billings.view_partial_cc'),
              doValidate: false,
              doSave: false
            },
            billType: {
              doValidate: false,
              doSave: false
            },
            customerBillingBillBankNumbers: {
              doCopy: this.grants.getIndividualGrant('customer_billing_bill_bank_numbers.view_partial_bank_number'),
              doSync: this.grants.getIndividualGrant('customer_billing_bill_bank_numbers.view_partial_bank_number'),
              doValidate: false,
              doSave: false
            }
          }
        }
      });
    }),
    paymentMethodForm: Ember.computed('paymentMethodFormMode', 'customerBilling', function () {
      return Ember.Object.create({
        paymentMethodFormStep: Ember.Object.create({
          stepNameKey: 'paymentMethodFormStep',
          title: this.intl.t("pages.customers.accounting.customer_billing.".concat(this.paymentMethodFormMode), {
            name: this.customer.get('flname'),
            id: this.customerBilling ? this.customerBilling.get('id') : null
          }),
          stepComponentPath: 'multi-step-form/forms/accounting/edit-create-payment-method'
        })
      });
    }),
    paymentMethodDeleteId: false,
    actions: {
      deletePaymentMethod(method) {
        method.destroyRecordWithRollbackTask.perform().then(() => {
          this.set('paymentMethodDeleteId', false);
        });
      }

    }
  });

  _exports.default = _default;
});