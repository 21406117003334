define("securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/interactive-services-providers/interactive-services-provider-type-1/toolbox/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/interactive-services-providers/interactive-services-provider-type-1/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Toolbox extends _component.default {}

  _exports.default = Toolbox;
});