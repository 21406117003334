define("securitytrax-ember-ui/models/base_models/calendar-events-on-site-info", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    enRoute: (0, _model.attr)('number', {
      'readOnly': false
    }),
    checkIn: (0, _model.attr)('number', {
      'readOnly': false
    }),
    checkOut: (0, _model.attr)('number', {
      'readOnly': false
    }),
    duration: (0, _model.attr)('number', {
      'readOnly': false
    }),
    onTimeArrival: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    onTimeDepart: (0, _model.attr)('boolean', {
      'readOnly': false
    })
  });

  _exports.default = _default;
});