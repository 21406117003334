define("securitytrax-ember-ui/components/inventory/physical-inventory/component", ["exports", "@glimmer/component", "ember-concurrency", "securitytrax-ember-ui/mixed-in-classes/grouped-equipment", "jquery"], function (_exports, _component, _emberConcurrency, _groupedEquipment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var PhysicalInventory = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = (0, _emberConcurrency.task)(function* () {
    var equipmentList = (yield this.store.query('equipment', {
      include: 'equipment_type',
      limit: 0,
      filter: 'active=true'
    })).sortBy('name');
    var inventoryToDisplay = [];
    equipmentList.forEach(function (equipmentItem) {
      var inventoryItem = Ember.Object.create({
        'equipment': equipmentItem,
        'id': equipmentItem.get('id'),
        'quantity': 0,
        'updateCount': false
      });
      inventoryToDisplay.push(inventoryItem);
    });
    this.inventoryList = inventoryToDisplay;
    this.items = inventoryToDisplay;
    this.filterItems.perform();
  }), _dec12 = (0, _emberConcurrency.task)(function* () {
    try {
      var inventory = this.items.filter(item => {
        return item.updateCount;
      });
      var inventoryToSubmit = [];
      var user = yield this.args.user;
      inventory.forEach(function (equipmentItem) {
        var inventoryItem = Ember.Object.create({
          'equipment_id': equipmentItem.get('id'),
          'quantity': equipmentItem.get('quantity') ? Number(equipmentItem.get('quantity')) : 0
        });
        inventoryToSubmit.push(inventoryItem);
      });
      yield user.invokeAction('perform_physical_inventory', {
        verb: 'POST',
        data: {
          data: inventoryToSubmit
        }
      });
      this.clearInventory();
      this.openModal = false;
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'global');
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, 'modal');
      throw err;
    }
  }), _dec13 = (0, _emberConcurrency.task)(function* () {
    Ember.setProperties(this, {
      filterValue: ''
    });
    this.items = this.inventoryList;
    yield this.inventoryListTask.perform();
  }), _dec14 = (0, _emberConcurrency.task)(function* () {
    var newFilters = yield this.stFiltersService.getFiltersByGroupKeyAsObject('physicalInventory');
    var inventoryList = this.inventoryList;

    if (this.selectedResource) {
      if (this.selectedResource === 2) {
        this.items = inventoryList.filter(item => {
          return item.on_hand > 0;
        });
      } else {
        this.items = inventoryList;
      }
    }

    if (newFilters['filterValue']) {
      this.items = this.items.filter(el => el.get('equipment.name').toLowerCase().indexOf(newFilters['filterValue'].toLowerCase()) > -1);
    }

    this.groupedItems = yield this.GroupedEquipment.groupedItemsTask.perform(this.items);
  }), _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, (_class = (_temp = class PhysicalInventory extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "stFiltersService", _descriptor4, this);

      _initializerDefineProperty(this, "filterValue", _descriptor5, this);

      _initializerDefineProperty(this, "openModal", _descriptor6, this);

      _initializerDefineProperty(this, "inventoryList", _descriptor7, this);

      _initializerDefineProperty(this, "items", _descriptor8, this);

      _initializerDefineProperty(this, "selectAllRows", _descriptor9, this);

      _initializerDefineProperty(this, "groupedItems", _descriptor10, this);

      _initializerDefineProperty(this, "inventoryListTask", _descriptor11, this);

      _initializerDefineProperty(this, "submitPhysicalInventoryTask", _descriptor12, this);

      _initializerDefineProperty(this, "clearFilter", _descriptor13, this);

      _initializerDefineProperty(this, "filterItems", _descriptor14, this);

      this.GroupedEquipment = new _groupedEquipment.default(...arguments);
      this.inventoryListTask.perform();
    }

    get resourceOptions() {
      return [{
        value: 1,
        name: this.intl.t('pages.users.my_inventory.equipment_inventory.filters.all')
      }, {
        value: 2,
        name: this.intl.t('pages.users.my_inventory.equipment_inventory.filters.on_hand')
      }];
    }

    get selectedResource() {
      return this.resourceOptions[0];
    }

    get reviewButtons() {
      return [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.submitPhysicalInventoryTask.perform(buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary inventory-submit-button'
      }];
    }

    clearInventory() {
      var equipmentList = this.items;
      this.selectAllRows = false;
      equipmentList.forEach(function (equipmentItem) {
        equipmentItem.set('updateCount', false);
        equipmentItem.set('quantity', 0);
      });
    }

    updateCounts(row, checkbox) {
      if (checkbox) {
        if (row.updateCount) {
          var input = (0, _jquery.default)('[data-test-equipment-item=' + row.id + '] .number-input__input');
          input.focus();
          input.select();
        } else {
          if (row.quantity > 0) {
            row.set('quantity', 0);
          }
        }
      } else {
        if (!row.updateCount) {
          row.set('updateCount', true);
        }
      }
    }

    selectAll() {
      var toggleVal = this.selectAllRows;
      this.items.forEach(function (value) {
        if (!toggleVal) {
          value.set('quantity', 0);
        }

        value.set('updateCount', toggleVal);
      });
    }

    cancelInventory() {
      this.clearInventory();
    }

    startReview() {
      this.openModal = true;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stFiltersService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "filterValue", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "openModal", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "inventoryList", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "items", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "selectAllRows", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "groupedItems", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "inventoryListTask", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "submitPhysicalInventoryTask", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "clearFilter", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "filterItems", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateCounts", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "updateCounts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectAll", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "selectAll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelInventory", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "cancelInventory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startReview", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "startReview"), _class.prototype)), _class));
  _exports.default = PhysicalInventory;
});