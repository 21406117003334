define("securitytrax-ember-ui/components/multi-step-form/switchable-inputs/number-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xTtodYuS",
    "block": "{\"symbols\":[],\"statements\":[[8,\"html/input-wrapper\",[[24,0,\"number-input-wrap\"]],[[\"@label\",\"@required\",\"@errors\"],[[34,0],[34,1],[34,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"number-input__button icon-only minus st-btn btn-icon ember-view\"],[15,\"onclick\",[30,[36,3],[[32,0],\"updateValue\",-1],null]],[12],[2,\"\\n        \"],[10,\"div\"],[15,0,[30,[36,6],[\"main-button\",[30,[36,5],[[35,4],\" disabled\",\"\"],null]],null]],[12],[1,[30,[36,7],[\"icn-circle-dash\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[1,[30,[36,13],null,[[\"value\",\"type\",\"name\",\"min\",\"max\",\"disabled\",\"required\",\"readonly\",\"class\",\"focusOut\"],[[35,12],\"number\",[35,11],[35,10],[35,9],[35,4],[35,1],false,\"number-input__input\",[35,8]]]]],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"number-input__button icon-only minus st-btn btn-icon ember-view\"],[15,\"onclick\",[30,[36,3],[[32,0],\"updateValue\",1],null]],[12],[2,\"\\n        \"],[10,\"div\"],[15,0,[30,[36,6],[\"main-button\",[30,[36,5],[[35,4],\" disabled\",\"\"],null]],null]],[12],[1,[30,[36,7],[\"icn-circle-add\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"label\",\"required\",\"fieldErrors\",\"action\",\"showView\",\"if\",\"concat\",\"svg-icon\",\"numberChanged\",\"max\",\"min\",\"name\",\"value\",\"input\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/switchable-inputs/number-input/template.hbs"
    }
  });

  _exports.default = _default;
});