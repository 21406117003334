define("securitytrax-ember-ui/components/multi-step-form/forms/administration/account-management/add-on-confirmation/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency"], function (_exports, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    enhancedFeatures: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    paymentMethod: null,
    confirmDeleteModalOpen: false,
    text: '',

    didInsertElement() {
      this._super(...arguments);

      this.set('text', this.intl.t("pages.administration.account_management.add_ons.".concat(this.currentAddOn, ".confirmation_message")));
      var multiStepForm = this.multiStepForm;
      var buttons = [];
      buttons.push({
        text: 'confirm',
        click: buttonPromise => {
          return this.addOnTask.perform(buttonPromise, multiStepForm);
        },
        classNamesString: 'st-btn st-btn-primary'
      });
      multiStepForm.registerStepData('addOnFormStep', 'buttons', buttons);

      if (this.currentState) {
        this.set('text', this.intl.t("pages.administration.account_management.add_ons.".concat(this.currentAddOn, ".disable_confirmation_message")));
        multiStepForm.registerStepData('addOnFormStep', 'title', this.intl.t("pages.administration.account_management.add_ons.".concat(this.currentAddOn, ".disable_confirmation")));
      }
    },

    addOnTask: (0, _emberConcurrency.task)(function* (buttonPromise, multiStepForm) {
      var newState = !this.currentState;
      var premiumFeatures = yield this.premiumFeatures;

      switch (this.currentAddOn) {
        case 'inventory_plus':
          premiumFeatures.set('inventoryExtendedFunctionality', newState);
          break;

        case 'payables_plus':
          premiumFeatures.set('enhancedPayables', newState);
          break;

        case 'invoicing_plus':
          premiumFeatures.set('enhancedInvoices', newState);
          break;

        case 'messages_plus':
          premiumFeatures.set('sms.enabled', newState);
          break;

        case 'eDoc_plus':
          premiumFeatures.set('edoc', newState);
          break;

        case 'finance_plus':
          premiumFeatures.set('consumerFinancing', newState);
          break;

        case 'quickbooks':
          premiumFeatures.set('quickbooks', newState);
          break;

        default:
          break;
      }

      yield premiumFeatures.save();
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
      buttonPromise.then(() => {
        multiStepForm.formStartedSetVal(false);
      });
    })
  });

  _exports.default = _default;
});