define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/monitoring-companies/integrations/api-integration/component", ["exports", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "securitytrax-ember-ui/mixed-in-classes/monitoring-company-connection", "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/monitoring-companies/integrations/integrations-base"], function (_exports, _switchableFormStep, _monitoringCompanyConnection, _integrationsBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ApiIntegration extends _integrationsBase.default {
    constructor() {
      super(...arguments);
      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.MonitoringCompanyConnection = new _monitoringCompanyConnection.default(...arguments);
      Ember.run.later(() => {
        this.buttons(this.args.multiStepForm);
      });
    }

    get computedPassphrase() {
      var multiStepForm = this.args.multiStepForm;

      if (multiStepForm.formCopiedModels.monitoringCompany.model.monitoringCompanyConfig.responseMeta.attribute_has_saved_value.passphrase) {
        return '********';
      } else {
        return null;
      }
    }

  }

  _exports.default = ApiIntegration;
});