define("securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/create-financing-base", ["exports", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "securitytrax-ember-ui/mixed-in-classes/row-list"], function (_exports, _component, _switchableFormStep, _rowList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CreateFinancingBase = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, (_class = (_temp = class CreateFinancingBase extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "stAjax", _descriptor, this);

      _initializerDefineProperty(this, "hasErrors", _descriptor2, this);

      _initializerDefineProperty(this, "hasCoapplicant", _descriptor3, this);

      _initializerDefineProperty(this, "applicantRowList", _descriptor4, this);

      _initializerDefineProperty(this, "coapplicantRowList", _descriptor5, this);

      this.applicantRowList = new _rowList.default(...arguments);
      this.coapplicantRowList = new _rowList.default(...arguments);
      this.SwitchableFormStep = new _switchableFormStep.default(...arguments, {
        disabledProgressBarSteps: this.disabledProgressBarSteps,
        hiddenProgressBarSteps: this.hiddenProgressBarSteps
      });
      this.SwitchableFormStep.progressBarHidden = false;
    }

    get disabledProgressBarSteps() {
      return [];
    }

    get hiddenProgressBarSteps() {
      return [];
    }

    get progressBarConfig() {
      var items = [];
      var multiStepForm = this.args.multiStepForm;
      var formSteps = this.args.multiStepForm.formSteps;
      var currentStep = this.args.multiStepForm.currentStepNameKey;
      var foundCurrStep = false;
      Object.keys(formSteps).forEach(key => {
        var isHidden = this.hiddenProgressBarSteps.includes(key) || formSteps[key].granted != null && !formSteps[key].granted;
        var isComplete = formSteps[key].stepNameKey === currentStep ? false : true;

        if (foundCurrStep) {
          isComplete = false;
        }

        if (currentStep == formSteps[key].stepNameKey) {
          foundCurrStep = true;
        }

        items.push({
          title: formSteps[key].progressBarTitle,
          stepNameKey: formSteps[key].stepNameKey,
          isClickable: false,
          hidden: isHidden,
          isComplete: isComplete,

          clickAction(item) {
            multiStepForm.set('currentStepNameKey', item.stepNameKey);
          }

        });
      });
      return {
        hideProgressBar: this.progressBarHidden,
        activeIndex: items.findIndex(item => item.stepNameKey === currentStep),
        viewTemplate: 'default-view',
        items: items
      };
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "stAjax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "hasErrors", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hasCoapplicant", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "applicantRowList", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "coapplicantRowList", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  })), _class));
  _exports.default = CreateFinancingBase;
});