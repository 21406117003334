define("securitytrax-ember-ui/models/adt-monitoring-company-equipment-product-code", ["exports", "securitytrax-ember-ui/models/base_models/adt-monitoring-company-equipment-product-code"], function (_exports, _adtMonitoringCompanyEquipmentProductCode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adtMonitoringCompanyEquipmentProductCode.default.extend({});

  _exports.default = _default;
});