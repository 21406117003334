define("securitytrax-ember-ui/components/modals/sub-route-modals/manage-cms/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Component.extend({
    formStarted: false,
    formMode: 'edit',
    firstStep: 'general',
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    centralStationService: Ember.inject.service(),
    initData: (0, _emberConcurrency.task)(function* () {
      this.set('formMode', (yield this.get('customerSystemInformation.centralStationId')) ? 'edit' : 'create');
      this.set('formStarted', true);
    }).on('init'),
    title: Ember.computed('customer.{id,flname,customerOrderInformation.monitoringCompany.monitoringCompanyType.name}', 'formMode', function () {
      var titleData = {
        customerName: this.get('customer.flname'),
        id: this.get('customer.id')
      };
      return this.formMode === 'create' ? this.intl.t('pages.administration.integrations.monitoringCompanies.integrations.cms.general.create_title', titleData) : this.intl.t('pages.administration.integrations.monitoringCompanies.integrations.cms.general.general_title', titleData);
    }),
    formOriginalModel: Ember.computed('grants.userGrants', 'customer', function () {
      var grants = this.grants;
      return Ember.Object.create({
        customer: {
          model: this.customer,
          copyRelationMap: {
            customerSystemInformation: {
              doCopy: grants.getIndividualGrant('customer_system_information.view'),
              doValidate: grants.getIndividualGrant('customer_system_information.view'),
              doSave: grants.getIndividualGrant('customer_system_information.modify')
            }
          }
        }
      });
    }),
    formSteps: Ember.computed('formMode', 'title', function () {
      var intl = this.intl;
      return Ember.Object.create({
        general: Ember.Object.create({
          stepNameKey: 'general',
          title: this.title,
          stepComponentPath: 'multi-step-form/forms/system/cms/general',
          progressBarTitle: intl.t('pages.customers.system.order.general')
        }),
        agencies: Ember.Object.create({
          stepNameKey: 'agencies',
          title: this.title,
          stepComponentPath: 'multi-step-form/forms/system/cms/agencies',
          progressBarTitle: intl.t('pages.administration.integrations.monitoringCompanies.integrations.cms.agencies.nav_title')
        }),
        dispatch: Ember.Object.create({
          stepNameKey: 'dispatch',
          title: this.title,
          stepComponentPath: 'multi-step-form/forms/system/cms/dispatch',
          progressBarTitle: intl.t('pages.administration.integrations.monitoringCompanies.integrations.cms.dispatch.title')
        }),
        history: Ember.Object.create({
          stepNameKey: 'history',
          title: this.title,
          stepComponentPath: 'multi-step-form/forms/system/cms/history',
          progressBarTitle: intl.t('pages.customers.system.order.event_history')
        })
      });
    }),
    actions: {
      transitionBackToParentRoute() {
        this.router.transitionTo(this.parentRoute, this.transitionId);
      }

    }
  });
  var _default = Controller;
  _exports.default = _default;
});