define("securitytrax-ember-ui/models/base_models/sync-object-to-provider", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    entityType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['customer', 'invoice', 'invoice_billable_item', 'customer_payment', 'applied_credit']
    }),
    toReview: (0, _model.attr)('boolean', {
      'readOnly': true
    }),
    toSync: (0, _model.attr)('boolean', {
      'readOnly': true
    }),
    syncReason: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['create', 'update', 'delete']
    }),
    toSyncSetAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    lastSyncedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    lastSyncedMethod: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['create', 'update', 'delete']
    }),
    ignored: (0, _model.attr)('boolean', {
      'readOnly': true
    }),
    ignoreReason: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['manual', 'multi-tax-lines', 'delete-failed']
    }),
    ignoredAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    failedSync: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    failedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    failedMessage: (0, _model.attr)('string', {
      'readOnly': false
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    toSyncSetByUser: (0, _model.belongsTo)('user', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    ignoredByUser: (0, _model.belongsTo)('user', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    quickbooksIntegration: (0, _model.belongsTo)('quickbooks-integration', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    invoice: (0, _model.belongsTo)('invoice', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    customer: (0, _model.belongsTo)('customer', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    invoiceBillableItem: (0, _model.belongsTo)('invoice-billable-item', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    customerPayment: (0, _model.belongsTo)('customer-payment', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    appliedCredit: (0, _model.belongsTo)('customer-invoice-payment', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});