define("securitytrax-ember-ui/administration/inventory/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Rga9vObD",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,5],null,[[\"title\",\"breadCrumbs\",\"breadCrumbData\",\"expandSideNav\",\"sideNavExpand\",\"mobileHamburgerVisible\"],[[30,[36,4],[\"pages.administration.inventory.company_inventory.title\"],null],[35,3],[35,2],[30,[36,1],[[32,0],\"toggleLeft\"],[[\"target\"],[[35,0]]]],[35,0,[\"isLeftOpen\"]],true]]]],[2,\"\\n\\n\"],[1,[34,6]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"page-content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"scroll-page\"],[12],[2,\"\\n        \"],[1,[30,[36,8],[[30,[36,7],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"navigation\",\"action\",\"breadCrumbData\",\"breadCrumbs\",\"t\",\"nav/top-nav-sub\",\"admin/left-nav-menu\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/administration/inventory/template.hbs"
    }
  });

  _exports.default = _default;
});