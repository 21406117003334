define("securitytrax-ember-ui/administration/inventory/purchase-requisitions/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller.extend({
    showPendingPurchaseOrders: false,
    pendingPurchaseOrders: null
  });
  var _default = Controller;
  _exports.default = _default;
});