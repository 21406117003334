define("securitytrax-ember-ui/components/html/notifications/global-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xDaKEoTQ",
    "block": "{\"symbols\":[\"data\"],\"statements\":[[8,\"html/st-await\",[],[[\"@booleanLoaded\",\"@promises\"],[[32,0,[\"companyMessagesTask\",\"isIdle\"]],[30,[36,4],null,[[\"pubCompany\"],[[35,3]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,5],[[32,1,[\"resolvedPromises\",\"pubCompany\",\"securitytrax_global_message\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"st-global-message\"],[12],[2,\"\\n            \"],[10,\"div\"],[12],[2,\"\\n                \"],[1,[30,[36,0],[\"icn-announcement\"],null]],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"st-message\"],[12],[2,\"\\n                \"],[10,\"h3\"],[14,0,\"st-message__title\"],[12],[1,[30,[36,2],[\"common.nav.securitytrax_system_message\"],null]],[13],[2,\"\\n                \"],[2,[32,1,[\"resolvedPromises\",\"pubCompany\",\"securitytrax_global_message\"]]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,5],[[32,0,[\"displayCompanyMessage\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"global-message\"],[12],[2,\"\\n            \"],[10,\"div\"],[12],[2,\"\\n                \"],[1,[30,[36,0],[\"icn-announcement\"],null]],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"custom-message\"],[12],[2,\"\\n                \"],[2,[32,1,[\"resolvedPromises\",\"pubCompany\",\"company_global_message\"]]],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"div\"],[12],[2,\"\\n                \"],[1,[30,[36,0],[\"icn-close\"],[[\"click\"],[[30,[36,1],[[32,0],\"closeDialog\"],null]]]]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"svg-icon\",\"action\",\"t\",\"pubCompany\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/notifications/global-message/template.hbs"
    }
  });

  _exports.default = _default;
});