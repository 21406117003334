define("securitytrax-ember-ui/not-found/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _authenticatedRouteMixin, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _routePageviewLogging.default, {
    //change the bad url to /not-found
    redirect: function () {
      var url = this._router.location.formatURL('/not-found');

      if (window.location.pathname !== url) {
        this.transitionTo('/not-found');
      }
    },

    setupController(controller) {
      this._super(...arguments);

      controller.set('path', window.location.pathname);
    }

  });

  _exports.default = _default;
});