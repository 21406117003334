define("securitytrax-ember-ui/components/html/st-color-formatter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SDweIh3c",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,0,[30,[36,0],[\"color-format-bold color-format-number \",[32,0,[\"color\"]]],null]],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"concat\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/st-color-formatter/template.hbs"
    }
  });

  _exports.default = _default;
});