define("securitytrax-ember-ui/administration/human-resources/user-list/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/scroll-to-top", "securitytrax-ember-ui/mixins/route-pageview-logging", "securitytrax-ember-ui/mixins/has-page-title"], function (_exports, _authenticatedRouteMixin, _scrollToTop, _routePageviewLogging, _hasPageTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

  function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _routePageviewLogging.default, _hasPageTitle.default, _scrollToTop.default, {
    intl: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['users.view', 'groups.view'], 'one');
    },

    model: function () {
      var _ref = _asyncToGenerator(function* () {
        var store = this.store;
        var grants = this.grants;
        return Ember.RSVP.hash({
          mobileCarriers: store.findAll('mobileCarrier'),
          groups: (yield grants.get('getIndividualGrantAsync').perform('groups.view')) ? store.query('group', {
            limit: 0
          }) : []
        });
      });

      return function model() {
        return _ref.apply(this, arguments);
      };
    }(),
    parentController: Ember.computed(function () {
      return this.controllerFor('administration.human-resources');
    }),

    setupController() {
      this._super(...arguments);

      var breadCrumbs = [{
        name: this.intl.t('pages.administration.human_resources.user_list'),
        last: true,
        previousLink: 'administration',
        link: 'user-list',
        showArrow: false
      }];
      var breadCrumbData = {
        title: this.intl.t('pages.administration.human_resources.title'),
        titleLink: 'administration.human-resources.user-list',
        hide: false
      };
      this.parentController.set('breadCrumbs', breadCrumbs);
      this.parentController.set('breadCrumbData', breadCrumbData);
    },

    getPageTitle() {
      return this.intl.t('pages.administration.human_resources.user_list');
    },

    getRouteName() {
      return "pages.administration.nav.users-permissions";
    }

  });

  _exports.default = _default;
});