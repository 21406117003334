define("securitytrax-ember-ui/components/modals/manage-connection/component", ["exports", "@glimmer/component", "ember-concurrency", "tracked-toolbox", "lodash"], function (_exports, _component, _emberConcurrency, _trackedToolbox, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var ManageConnection = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = (0, _trackedToolbox.localCopy)('args.onAfterSuccessfulConnection', () => {
    return () => {
      (false && Ember.warn('No onAfterSuccessfulConnection was passed into manage-connection', {
        id: 'manage-connection.onAfterSuccessfulConnection'
      }));
    };
  }), _dec6 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var noValidateArray = ['accountNumberBlock-startNumber', 'accountNumberBlock-receiverNumber', 'accountNumberBlock-monitoringCompany'];

    if (!this.args.updatePassword) {
      noValidateArray.push('monitoringCompanyConfig-newPassword', 'monitoringCompanyConfig-confirmNewPassword');
    }

    if (this.args.usesKeyCode) {
      noValidateArray.push('monitoringCompanyConfig-password');

      if (_lodash.default.get(this.args.multiStepForm, 'formCopiedModels.monitoringCompany.model.monitoringCompanyConfig.responseMeta.attribute_has_saved_value.key_code', false)) {
        noValidateArray.push('monitoringCompanyConfig-keyCode');
      }
    } else {
      noValidateArray.push('monitoringCompanyConfig-keyCode');
    }

    if (this.args.usesUserNameId) {
      noValidateArray.push('monitoringCompanyConfig-username');
    } else {
      noValidateArray.push('monitoringCompanyConfig-usernameId');
    }

    if (this.args.isADT || this.args.isCops) {
      noValidateArray.push('monitoringCompanyConfig-password', 'monitoringCompanyConfig-username');
    }

    if (!this.args.isCops) {
      noValidateArray.push('monitoringCompanyConfig-dealerNumber');
    }

    if (this.args.isApi) {
      noValidateArray.push('monitoringCompanyConfig-password', 'monitoringCompanyConfig-username');
    }

    if (!this.args.isTelus) {
      noValidateArray.push('monitoringCompanyConfig-partnerId');
    }

    if (_lodash.default.get(this.args.multiStepForm, 'formCopiedModels.monitoringCompany.model.monitoringCompanyConfig.responseMeta.attribute_has_saved_value.password', false)) {
      noValidateArray.push('monitoringCompanyConfig-password');
    }

    var overrideConfig = Ember.Object.create({
      monitoringCompany: {
        model: this.args.monitoringCompany,
        copyRelationMap: {
          monitoringCompanyType: {
            readOnly: true
          },
          monitoringCompanyConfig: {}
        }
      }
    });
    yield this.args.multiStepForm.validateSyncSaveAll({
      extraValidationData: {
        noValidate: noValidateArray
      },
      overrideOriginalModelsConfig: overrideConfig,
      notificationNamespace: 'manageConnectionModal',
      successNotificationNamespace: 'modal'
    });
    buttonPromise.then(() => {
      this.args.changeFormStarted();

      if (this.args.multiStepForm.formOriginalModelsGetVal('monitoringCompany.model.isConnected')) {
        this.onAfterSuccessfulConnection();
      }

      this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
    });
  }), (_class = (_temp = class ManageConnection extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "sessionData", _descriptor3, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor4, this);

      _initializerDefineProperty(this, "onAfterSuccessfulConnection", _descriptor5, this);

      _initializerDefineProperty(this, "manageConnectionTask", _descriptor6, this);
    }

    get manageConnectionTitle() {
      return this.args.updatePassword ? this.intl.t('pages.administration.integrations.monitoringCompanies.integrations.change_password') : this.intl.t('pages.administration.integrations.monitoringCompanies.integrations.manage_connection_title', {
        name: this.args.multiStepForm.formCopiedModels.monitoringCompany.model.name
      });
    }

    get formButtons() {
      var buttons = [{
        text: this.intl.t('common.actions.save'),
        classNamesString: 'st-btn st-btn-primary save-connection',
        click: buttonPromise => {
          return this.manageConnectionTask.perform(buttonPromise);
        }
      }];
      return buttons;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "onAfterSuccessfulConnection", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "manageConnectionTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ManageConnection;
});