define("securitytrax-ember-ui/components/multi-step-form/forms/payment-processing/component", ["exports", "ember-concurrency", "lodash"], function (_exports, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    passwordMaskInputEvent: Ember.inject.service('events/password-mask-input-event'),
    passwordDisplay: null,

    init() {
      this._super(...arguments);

      this.initFormData.perform();
    },

    didInsertElement() {
      this._super(...arguments);

      this.multiStepForm.registerStepData('step1', 'buttons', [{
        text: this.intl.t('common.actions.cancel'),
        click: () => {
          if (_lodash.default.get(this.multiStepForm, 'formCopiedModels.processingGateway.model.responseMeta.password_exists', false)) {
            Ember.set(this, 'passwordDisplay', 'defaultP@$$w0rd');
          } else {
            Ember.set(this, 'passwordDisplay', null);
          }

          this.passwordMaskInputEvent.trigger('update-password');
          this.multiStepForm.setupCopyModels.perform();
          this.set('formMode', 'view');
          this.stNotificationManager.addSuccessKey('common.notifications.generic_cancel');
        },
        classNamesString: 'st-btn btn-secondary partner-company-cancel-button'
      }, {
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.saveFormTask.perform(this.multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary partner-company-save-button'
      }]);
    },

    initFormData: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true);
    }),
    saveFormTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      if (multiStepForm.formOriginalModels.processingGateway.isCreate) {
        var toCreateObjects = yield multiStepForm.validateCreateAll();
        var processingGateway = this.store.createRecord('processingGateway', toCreateObjects.processingGateway.data);
        yield toCreateObjects.processingGateway.saveWithRollbackAndNotification(processingGateway);
      } else {
        yield multiStepForm.validateSyncSaveAll();
      }

      buttonPromise.then(() => {
        this.set('formMode', 'view');
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
      });
    })
  });

  _exports.default = _default;
});