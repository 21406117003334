define("securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-invoice/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EditCreateInvoice = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = (0, _emberConcurrency.task)(function* () {
    if (this.args.invoiceId) {
      var hashedData = yield (0, _emberConcurrency.hash)({
        customer: this.args.customer,
        workOrder: this.args.workOrder
      });
      this.invoiceType = this.args.invoiceType;
      this.invoiceFormMode = this.args.invoiceFormMode || 'edit';
      this.invoiceFirstStep = this.args.invoiceFirstStep || 'gettingStarted';
      Ember.setProperties(this, hashedData);

      if (this.args.invoiceId !== 'create') {
        this.invoice = yield this.store.findRecord(this.args.invoiceType === 'recurringInvoice' ? this.args.invoiceType : 'invoice', this.args.invoiceId);

        if (!this.customer) {
          this.customer = yield this.invoice.get('customer');
        }

        this.invoiceFormMode = this.args.invoiceFormMode || (this.grants.getIndividualGrant(this.invoiceType !== 'recurring_invoice' ? 'customer_' + this.invoiceType + 's.modify' : 'recurring_invoices.modify') ? 'edit' : 'view');
        this.invoiceFirstStep = this.args.invoiceFirstStep || 'details';
      }

      this.invoiceFormStarted = true;
    }
  }), _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, (_class = (_temp = class EditCreateInvoice extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "grants", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "sessionData", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "router", _descriptor5, this);

      _initializerDefineProperty(this, "quickAdd", _descriptor6, this);

      _initializerDefineProperty(this, "invoice", _descriptor7, this);

      _initializerDefineProperty(this, "customer", _descriptor8, this);

      _initializerDefineProperty(this, "workOrder", _descriptor9, this);

      _initializerDefineProperty(this, "invoiceType", _descriptor10, this);

      _initializerDefineProperty(this, "invoiceFirstStep", _descriptor11, this);

      _initializerDefineProperty(this, "invoiceFormMode", _descriptor12, this);

      _initializerDefineProperty(this, "customerPayment", _descriptor13, this);

      _initializerDefineProperty(this, "initData", _descriptor14, this);

      this.initData.perform();
    }

    get invoiceFormOriginalModels() {
      var grants = this.grants;
      var isCreate = this.invoice == null;
      return Ember.Object.create({
        invoice: {
          model: this.invoice,
          copyRelationMap: {
            invoiceLineItems: {
              relationships: {
                invoiceBillableItem: {
                  doSave: grants.getIndividualGrant('invoice_billable_items.modify'),
                  relationships: {
                    invoiceBillableItemClass: {
                      doCopy: true
                    }
                  }
                }
              }
            },
            invoiceTerm: {},
            customerInvoicePayments: {
              relationships: {
                customerPayment: {
                  doCopy: grants.getIndividualGrant('customer_payments.view'),
                  doValidate: grants.getIndividualGrant('customer_payments.view'),
                  doSave: isCreate ? grants.getIndividualGrant('customer_payments.create') : grants.getIndividualGrant('customer_payments.modify'),
                  relationships: {
                    customerPaymentType: {
                      doValidate: false,
                      doSave: false
                    },
                    customerBilling: {
                      doCopy: grants.getIndividualGrant('customer_billings.view'),
                      doValidate: grants.getIndividualGrant('customer_billings.view'),
                      doSave: isCreate ? grants.getIndividualGrant('customer_billings.create') : grants.getIndividualGrant('customer_billings.modify')
                    }
                  }
                }
              }
            }
          }
        },
        customerPayment: {
          model: this.customerPayment,
          copyRelationMap: {
            customerPaymentType: {
              doValidate: false,
              doSave: false
            },
            customerBilling: {
              doCopy: grants.getIndividualGrant('customer_billings.view'),
              doValidate: grants.getIndividualGrant('customer_billings.view'),
              doSave: isCreate ? grants.getIndividualGrant('customer_billings.create') : grants.getIndividualGrant('customer_billings.modify')
            }
          }
        },
        customerBilling: {
          model: null,
          copyRelationMap: {
            billType: {
              doValidate: false,
              doSave: false
            }
          }
        },
        customerInvoicePayment: {
          copyRelationMap: {
            invoice: {
              doCopy: grants.getIndividualGrant('customer_invoices.view'),
              doValidate: grants.getIndividualGrant('customer_invoices.view'),
              doSave: isCreate ? grants.getIndividualGrant('customer_invoices.create') : grants.getIndividualGrant('customer_invoices.modify')
            },
            customerPayment: {
              doCopy: grants.getIndividualGrant('customer_payments.view'),
              doValidate: grants.getIndividualGrant('customer_payments.view'),
              doSave: isCreate ? grants.getIndividualGrant('customer_payments.create') : grants.getIndividualGrant('customer_payments.modify')
            }
          }
        }
      });
    }

    get invoiceFormSteps() {
      var title = this.args.invoiceId !== 'create' ? this.intl.t("pages.customers.accounting.invoices.modalTitle.invoice", {
        id: this.args.invoiceId,
        name: this.customer.get('flname')
      }) : this.intl.t('pages.customers.order.invoice', {
        name: this.customer.get('flname')
      });
      return Ember.Object.create({
        gettingStarted: Ember.Object.create({
          stepNameKey: 'gettingStarted',
          title,
          progressBarTitle: this.intl.t('common.status.getStarted'),
          stepComponentPath: 'multi-step-form/forms/invoices/invoice/get-started'
        }),
        details: Ember.Object.create({
          stepNameKey: 'details',
          title,
          progressBarTitle: this.intl.t('common.columns.details'),
          stepComponentPath: 'multi-step-form/forms/invoices/invoice/details'
        }),
        addPayment: Ember.Object.create({
          stepNameKey: 'addPayment',
          title,
          progressBarTitle: this.intl.t('common.columns.addPayment'),
          stepComponentPath: 'multi-step-form/forms/invoices/invoice/add-payment',
          granted: this.grants.getIndividualGrant('customer_payments.create')
        }),
        listPayments: Ember.Object.create({
          stepNameKey: 'listPayments',
          title,
          progressBarTitle: this.intl.t('common.columns.listPayment'),
          stepComponentPath: 'multi-step-form/forms/invoices/invoice/payments',
          granted: this.grants.getIndividualGrant('customer_payments.view'),
          subSteps: Ember.Object.create({
            payments: Ember.Object.create({
              stepNameKey: 'listPayments',
              title,
              progressBarTitle: this.intl.t('common.columns.listPayment'),
              stepComponentPath: 'multi-step-form/forms/invoices/invoice/payments',
              granted: this.grants.getIndividualGrant('customer_payments.view')
            }),
            confirmRefundVoid: Ember.Object.create({
              stepNameKey: 'confirmRefundVoid',
              title,
              progressBarTitle: 'refund or void - hidden',
              stepComponentPath: 'multi-step-form/forms/invoices/invoice/payments/confirm-refund-void',
              granted: true
            }),
            viewProcessingInformation: Ember.Object.create({
              stepNameKey: 'viewProcessingInformation',
              title,
              progressBarTitle: 'view processing info - this is hidden',
              stepComponentPath: 'multi-step-form/forms/invoices/invoice/payments/view-processing-info',
              granted: true
            }),
            editManualPayment: Ember.Object.create({
              stepNameKey: 'editManualPayment',
              title: this.intl.t('pages.customers.accounting.customer_payments.processingInformation.modalTitle', {
                id: this.args.customerPayment ? this.args.customerPayment.get('id') : null,
                name: this.customer.get('flname')
              }),
              progressBarTitle: 'Edit Manual Payment - hidden',
              stepComponentPath: 'multi-step-form/forms/invoices/invoice/payments/edit-manual-payment'
            })
          })
        }),
        send: Ember.Object.create({
          stepNameKey: 'send',
          title,
          progressBarTitle: this.intl.t('common.columns.send'),
          stepComponentPath: 'multi-step-form/forms/invoices/send-invoice',
          granted: true
        })
      });
    }

    get recurringInvoiceFormSteps() {
      var title = this.args.invoiceId !== 'create' ? this.intl.t("pages.customers.accounting.invoices.modalTitle.recurring_invoice", {
        id: this.args.invoiceId,
        name: this.customer.get('flname')
      }) : this.intl.t('pages.customers.order.recurring_invoice', {
        name: this.customer.get('flname')
      });
      return Ember.Object.create({
        gettingStarted: Ember.Object.create({
          stepNameKey: 'gettingStarted',
          title,
          progressBarTitle: this.intl.t('common.status.getStarted'),
          stepComponentPath: 'multi-step-form/forms/invoices/recurring-invoice/get-started'
        }),
        details: Ember.Object.create({
          stepNameKey: 'details',
          title,
          progressBarTitle: this.intl.t('common.columns.details'),
          stepComponentPath: 'multi-step-form/forms/invoices/recurring-invoice/details'
        }),
        history: Ember.Object.create({
          stepNameKey: 'history',
          title,
          progressBarTitle: this.intl.t('pages.customers.accounting.invoices.history'),
          stepComponentPath: 'multi-step-form/forms/invoices/recurring-invoice/history'
        }),
        duplicate: Ember.Object.create({
          stepNameKey: 'duplicate',
          title: "Duplicate ".concat(title),
          progressBarTitle: '',
          stepComponentPath: 'multi-step-form/forms/invoices/recurring-invoice/duplicate'
        })
      });
    }

    get quoteFormSteps() {
      var title = this.args.invoiceId !== 'create' ? this.intl.t("pages.customers.accounting.invoices.modalTitle.quote", {
        id: this.args.invoiceId,
        name: this.customer.get('flname')
      }) : this.intl.t('pages.customers.order.quote', {
        name: this.customer.get('flname')
      });
      return Ember.Object.create({
        gettingStarted: Ember.Object.create({
          stepNameKey: 'gettingStarted',
          title,
          progressBarTitle: this.intl.t('common.status.getStarted'),
          stepComponentPath: 'multi-step-form/forms/invoices/quote/get-started'
        }),
        details: Ember.Object.create({
          stepNameKey: 'details',
          title,
          progressBarTitle: this.intl.t('common.columns.details'),
          stepComponentPath: 'multi-step-form/forms/invoices/quote/details'
        }),
        send: Ember.Object.create({
          stepNameKey: 'send',
          title,
          progressBarTitle: this.intl.t('common.columns.send'),
          stepComponentPath: 'multi-step-form/forms/invoices/send-invoice',
          granted: true
        })
      });
    }

    get refundReceiptFormSteps() {
      var title = this.args.invoiceId !== 'create' ? this.intl.t("pages.customers.accounting.invoices.modalTitle.refund_receipt", {
        id: this.args.invoiceId,
        name: this.customer.get('flname')
      }) : this.intl.t('pages.customers.order.refund_receipt', {
        name: this.customer.get('flname')
      });
      return Ember.Object.create({
        gettingStarted: Ember.Object.create({
          stepNameKey: 'gettingStarted',
          title,
          progressBarTitle: this.intl.t('common.status.getStarted'),
          stepComponentPath: 'multi-step-form/forms/invoices/refund-receipt/get-started'
        }),
        details: Ember.Object.create({
          stepNameKey: 'details',
          title,
          progressBarTitle: this.intl.t('common.columns.details'),
          stepComponentPath: 'multi-step-form/forms/invoices/refund-receipt/details'
        }),
        addPayment: Ember.Object.create({
          stepNameKey: 'addPayment',
          title,
          progressBarTitle: this.intl.t('common.columns.refundPayment'),
          stepComponentPath: 'multi-step-form/forms/invoices/refund-receipt/refund-payment',
          granted: this.grants.getIndividualGrant('customer_payments.create')
        }),
        listPayments: Ember.Object.create({
          stepNameKey: 'listPayments',
          title,
          progressBarTitle: this.intl.t('common.columns.listPayment'),
          stepComponentPath: 'multi-step-form/forms/invoices/refund-receipt/payments',
          granted: this.grants.getIndividualGrant('customer_payments.view'),
          subSteps: Ember.Object.create({
            payments: Ember.Object.create({
              stepNameKey: 'listPayments',
              title,
              progressBarTitle: this.intl.t('common.columns.listPayment'),
              stepComponentPath: 'multi-step-form/forms/invoices/refund-receipt/payments',
              granted: this.grants.getIndividualGrant('customer_payments.view')
            }),
            confirmRefundVoid: Ember.Object.create({
              stepNameKey: 'confirmRefundVoid',
              title,
              progressBarTitle: 'refund or void - hidden',
              stepComponentPath: 'multi-step-form/forms/invoices/refund-receipt/payments/confirm-refund-void',
              granted: true
            }),
            viewProcessingInformation: Ember.Object.create({
              stepNameKey: 'viewProcessingInformation',
              title: this.intl.t('pages.customers.accounting.customer_payments.processingInformation.modalTitle', {
                id: this.args.customerPayment ? this.args.customerPayment.get('id') : null,
                name: this.customer.get('flname')
              }),
              progressBarTitle: 'view processing info - this is hidden',
              stepComponentPath: 'multi-step-form/forms/invoices/refund-receipt/payments/view-processing-info',
              granted: true
            })
          })
        }),
        send: Ember.Object.create({
          stepNameKey: 'send',
          title,
          progressBarTitle: this.intl.t('common.columns.send'),
          stepComponentPath: 'multi-step-form/forms/invoices/send-invoice',
          granted: true
        })
      });
    }

    get creditMemoFormSteps() {
      var title = this.args.invoiceId !== 'create' ? this.intl.t("pages.customers.accounting.invoices.modalTitle.credit_memo", {
        id: this.args.invoiceId,
        name: this.customer.get('flname')
      }) : this.intl.t('pages.customers.order.credit_memo', {
        name: this.customer.get('flname')
      });
      return Ember.Object.create({
        gettingStarted: Ember.Object.create({
          stepNameKey: 'gettingStarted',
          title,
          progressBarTitle: this.intl.t('common.status.getStarted'),
          stepComponentPath: 'multi-step-form/forms/invoices/credit-memo/get-started'
        }),
        details: Ember.Object.create({
          stepNameKey: 'details',
          title,
          progressBarTitle: this.intl.t('common.columns.details'),
          stepComponentPath: 'multi-step-form/forms/invoices/credit-memo/details'
        }),
        applyToInvoice: Ember.Object.create({
          stepNameKey: 'applyToInvoice',
          title,
          progressBarTitle: this.intl.t('common.columns.apply_to_invoice'),
          stepComponentPath: 'multi-step-form/forms/invoices/credit-memo/apply-to-invoice',
          granted: true
        }),
        send: Ember.Object.create({
          stepNameKey: 'send',
          title,
          progressBarTitle: this.intl.t('common.columns.send'),
          stepComponentPath: 'multi-step-form/forms/invoices/send-invoice',
          granted: true
        })
      });
    }

    get formSteps() {
      return Ember.get(this, this.invoiceType + 'FormSteps');
    }

    transitionToParentRoute() {
      var transitionToRoute = this.quickAdd.closeRoute || this.args.parentRoute;

      if (transitionToRoute.includes('customer') || transitionToRoute.includes('lead')) {
        this.router.transitionTo(transitionToRoute, this.customer.get('id'));
      } else {
        this.router.transitionTo(transitionToRoute);
      }
    }

    refreshList(options) {
      if (options.isCreate) {
        this.invoice = options.saved;
        var invoiceType = Ember.String.underscore(this.invoiceType);
        this.invoiceFormMode = this.grants.getIndividualGrant(invoiceType !== 'recurring_invoice' ? "customer_".concat(invoiceType, "s.modify") : 'recurring_invoices.modify') ? 'edit' : 'view';
        this.customer.get(this.invoiceType + 's').reload();
      }
    }

    updateInvoice(invoice) {
      this.invoice = invoice;
    }

    closeInvoiceForm(multiStepForm) {
      multiStepForm.setupCopyModels.unlinked().perform();
      this.transitionToParentRoute();
    }

    transitionBackToParentRoute() {
      this.transitionToParentRoute();
    }

    setCustomerPayment(customerPayment) {
      this.customerPayment = customerPayment;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "quickAdd", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "invoice", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "customer", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "workOrder", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "invoiceType", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "invoiceFirstStep", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'gettingStarted';
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "invoiceFormMode", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'edit';
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "customerPayment", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "initData", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "refreshList", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "refreshList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateInvoice", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "updateInvoice"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeInvoiceForm", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "closeInvoiceForm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transitionBackToParentRoute", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "transitionBackToParentRoute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setCustomerPayment", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "setCustomerPayment"), _class.prototype)), _class));
  _exports.default = EditCreateInvoice;
});