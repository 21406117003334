define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/monitoring-companies/integrations/affiliated-integration/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/monitoring-companies/integrations/integrations-base", "ember-concurrency", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "securitytrax-ember-ui/mixed-in-classes/monitoring-company-connection"], function (_exports, _integrationsBase, _emberConcurrency, _switchableFormStep, _monitoringCompanyConnection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var AffiliatedIntegration = (_dec = Ember.inject.service('centralStationService'), _dec2 = Ember.inject.service('intl'), _dec3 = Ember.inject.service('stNotificationManager'), _dec4 = Ember.inject.service('store'), _dec5 = Ember.inject.service('sessionData'), _dec6 = Ember._tracked, _dec7 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
    var templates = [];
    var config = this.args.multiStepForm.formCopiedModelsGetVal('monitoringCompany.model.monitoringCompanyConfig');

    if (config && config.get('templates')) {
      templates = Object.keys(config.get('templates')).map(key => {
        return {
          identifier: key,
          name: config.get('templates')[key]
        };
      });
    }

    templates.push({
      identifier: null,
      name: null
    });
    this.templates = templates;
  }), _dec8 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var multiStepForm = this.args.multiStepForm;
    var overrideConfig = Ember.Object.create({
      monitoringCompany: {
        model: this.monitoringCompany,
        copyRelationMap: {
          monitoringCompanyType: {
            readOnly: true
          },
          monitoringCompanyConfig: {}
        }
      }
    });
    Ember.set(this.args.multiStepForm, 'formCopiedModels.monitoringCompany.model.monitoringCompanyConfig.templates', this.convertTemplatesToConfigFormat(this.templates));
    yield multiStepForm.validateSyncSaveAll({
      extraValidationData: {
        noValidate: ['accountNumberBlock-startNumber', 'accountNumberBlock-receiverNumber', 'accountNumberBlock-monitoringCompany', 'monitoringCompanyConfig-newPassword', 'monitoringCompanyConfig-confirmNewPassword', 'monitoringCompanyConfig-username', 'monitoringCompanyConfig-password', 'monitoringCompanyConfig-keyCode', 'monitoringCompanyConfig-usernameId', 'monitoringCompanyConfig-dealerNumber', 'monitoringCompanyConfig-partnerId']
      },
      overrideOriginalModelsConfig: overrideConfig,
      notificationNamespace: 'modal'
    });
    buttonPromise.then(() => {
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
    });
  }), _dec9 = Ember._action, _dec10 = Ember._action, (_class = (_temp = class AffiliatedIntegration extends _integrationsBase.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "centralStationService", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "sessionData", _descriptor5, this);

      _initializerDefineProperty(this, "templates", _descriptor6, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor7, this);

      _initializerDefineProperty(this, "saveMonitoringCompanyConfig", _descriptor8, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.MonitoringCompanyConnection = new _monitoringCompanyConnection.default(...arguments);
      this.initDataTask.perform();
      Ember.run.later(() => {
        this.buttons(this.args.multiStepForm);
      });
    }

    convertTemplatesToConfigFormat(templates) {
      var convertedTemplates = {};
      templates.forEach(template => {
        if (template.identifier && template.name) {
          convertedTemplates[template.identifier] = template.name;
        }
      });
      return convertedTemplates;
    }

    deleteTemplateLineItem(index) {
      var templates = this.templates;
      templates.removeAt(index);
      this.templateDeleteId = false;
    }

    updateTemplate(index, row) {
      var templates = this.templates;
      templates.replace(index, 1, [row]);

      if (templates.length - 1 === index && row.identifier && row.name) {
        templates.push({
          identifier: null,
          name: null
        });
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "templates", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "saveMonitoringCompanyConfig", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "deleteTemplateLineItem", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "deleteTemplateLineItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateTemplate", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "updateTemplate"), _class.prototype)), _class));
  _exports.default = AffiliatedIntegration;
});