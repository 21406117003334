define("securitytrax-ember-ui/leads/files/upload-file/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5PJP6pyv",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modals/sub-route-modals/upload-file\",[],[[\"@customer\",\"@parentRoute\"],[[34,0,[\"customer\"]],[34,0,[\"parentRoute\"]]]],null]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/leads/files/upload-file/template.hbs"
    }
  });

  _exports.default = _default;
});