define("securitytrax-ember-ui/components/multi-step-form/forms/administration/system/tags/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency"], function (_exports, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    tagsService: Ember.inject.service(),
    confirmDeleteModalOpen: false,
    objectTypes: Ember.computed('grants.userGrants', function () {
      var types = this.multiStepForm.formCopiedModelsGetVal('tag.model.enumLists.objectTypeOptions');

      if (this.formMode === 'view') {
        return types;
      } else {
        var filteredTypes = types.filter(type => {
          return type.id === this.multiStepForm.formOriginalModelsGetVal('tag.model.objectType') || this.tagsService.isGranted(type.id, null, 'create');
        });
        filteredTypes.map(type => {
          type.name = this.intl.exists("pages.administration.system.tags.tagTypes.".concat(type.id)) ? this.intl.t("pages.administration.system.tags.tagTypes.".concat(type.id)) : type.name;
        });
        return filteredTypes;
      }
    }),
    objectContexts: Ember.computed('multiStepForm.formCopiedModels.tag.model.objectType', function () {
      return this.multiStepForm.formCopiedModelsGetVal('tag.model.enumLists.objectContextOptions').filter(context => {
        var objectType = this.multiStepForm.formCopiedModelsGetVal('tag.model.objectType');

        if (objectType === 'customer') {
          return context.id === 'customer' || context.id === 'lead';
        }

        return context.id === this.multiStepForm.formOriginalModelsGetVal('tag.model.objectContext') || context.id.includes(objectType) && this.grants.getIndividualGrant(this.tagsService.grantKey(objectType, context.id) + '.create') || context.id === 'none';
      });
    }),
    initFormData: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = this.multiStepForm;
      yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);

      if (this.isNewRecord) {
        this.multiStepForm.formCopiedModelsSetVal('tag.model.color', '#000000');
        this.multiStepForm.formCopiedModelsSetVal('tag.model.active', 'true');
      }
    }).on('init'),

    didInsertElement() {
      this._super(...arguments);

      var buttonChoices = [];
      var tag = this.multiStepForm.formOriginalModelsGetVal('tag.model');

      if (this.formMode !== 'view') {
        buttonChoices.push({
          text: this.intl.t('common.actions.save'),
          classNamesString: 'st-btn st-btn-primary tag-save-button',
          click: buttonPromise => {
            return this.saveTag.perform(this.multiStepForm, buttonPromise);
          }
        });
      }

      if (!this.isNewRecord && this.tagsService.isGranted(tag.get('objectType'), tag.get('objectContext'), 'delete')) {
        buttonChoices.push({
          text: this.intl.t('common.actions.delete'),
          click: () => {
            this.set('confirmDeleteModalOpen', true);
          },
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
        });
      }

      this.multiStepForm.registerStepData('step1', 'buttons', buttonChoices);
    },

    saveTag: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      if (this.isNewRecord) {
        var toCreateObjects = yield multiStepForm.validateCreateAll();
        var tag = this.store.createRecord('tag', toCreateObjects.tag.data);
        yield toCreateObjects.tag.saveWithRollbackAndNotification(tag, 'modal');
      } else {
        yield multiStepForm.validateSyncSaveAll();
      }

      this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
      buttonPromise.then(() => {
        multiStepForm.formStartedSetVal(false);
      });
    }),
    deleteTag: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = this.multiStepForm;
      var tag = multiStepForm.formOriginalModelsGetVal('tag.model');

      try {
        yield tag.destroyRecordWithRollbackTask.perform();
        this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
        multiStepForm.formStartedSetVal(false);
        multiStepForm.onDelete();
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'confirmDeleteModal'
        });
        throw err;
      }
    }),
    actions: {
      updateContext() {
        if (this.multiStepForm.formCopiedModels.tag.model.objectType === 'tech_schedule' || this.multiStepForm.formCopiedModels.tag.model.objectType === 'pending_equipment') {
          this.multiStepForm.formCopiedModelsSetVal('tag.model.objectContext', 'none');
        } else {
          this.multiStepForm.formCopiedModelsSetVal('tag.model.objectContext', null);
        }
      }

    }
  });

  _exports.default = _default;
});