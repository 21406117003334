define("securitytrax-ember-ui/validations/central-station/cops-place-on-test", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation", "lodash"], function (_exports, _validate, _baseValidation, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var noValidate = _lodash.default.get(data, 'extraValidationData.noValidate', false);

      var validateObject = {
        entireSystemOnTest: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('cops-entireSystemOnTest')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'cops-entireSystemOnTest');
            }
          }
        },
        hoursOnTest: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('cops-hoursOnTest')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'cops-hoursOnTest');
            }
          }
        },
        minutesOnTest: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('cops-minutesOnTest')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'cops-minutesOnTest');
            }
          }
        },
        currentOrDelayed: {
          presence: () => {
            return this.customMessageOrDefault(data, 'cops-currentOrDelayed');
          }
        },
        startDate: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('cops-startDate')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'cops-startDate');
            }
          }
        },
        startTime: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('cops-startTime')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'cops-startTime');
            }
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      return this.outputFormatting(validationData, 'cops');
    }

  });

  _exports.default = _default;
});