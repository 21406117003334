define("securitytrax-ember-ui/components/multi-step-form/forms/administration/inventory/spillage/edit-locked-spillage/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/switchable-form-step", "moment", "securitytrax-ember-ui/mixed-in-functions/grouped-property-sections"], function (_exports, _emberConcurrency, _switchableFormStep, _moment, _groupedPropertySections) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    inventoryService: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    sessionData: Ember.inject.service('sessionData'),
    spillageEquipmentLineItems: null,
    equipment: null,
    spillageEquipmentLineItemDeleteId: false,
    relatedDocuments: null,
    equipmentReturnReason: null,
    recipients: null,
    recipient: null,
    dataLoaded: false,
    lineItemsToCreate: null,
    formStarted: false,
    rma: false,
    completedStatuses: null,
    confirmModalOpen: false,
    newStatus: undefined,
    initFormData: (0, _emberConcurrency.task)(function* () {
      var buttons = [];
      var hashedData = yield (0, _emberConcurrency.hash)({
        waitForFinishedCopyModels: (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true),
        equipment: yield this.store.query('equipment', {
          limit: 0,
          sort: 'name',
          filter: 'active=true'
        }),
        equipmentReturnReason: this.store.findAll('equipmentReturnReason')
      });
      var copieModelsHashedData = yield (0, _emberConcurrency.hash)({
        lineItems: this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model.pendingEquipmentLineItems'),
        createdByUser: this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model.createdByUser')
      });

      if (!this.isNewRecord) {
        var relatedDocuments = yield this.store.query('equipmentReturnMerchandiseAuthorization', {
          filter: 'parent_equipment_spillage = ' + this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model.id'),
          include: 'user,location,created_by_user,equipment_vendor,pending_equipment_line_items.equipment.equipment_item'
        });

        if (!Ember.isEmpty(relatedDocuments)) {
          this.set('rma', true);
        }

        this.set('relatedDocuments', relatedDocuments ? relatedDocuments : []);
      }

      if (this.grants.getIndividualGrant('equipment_spillage.modify')) {
        if (!this.rma && this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model.status') != 'closed') {
          buttons.push({
            text: this.intl.t('pages.users.my_inventory.spillage.convert_rma'),
            click: () => {
              this.set('newStatus', 'convert');
              this.set('confirmModalOpen', true);
            },
            classNamesString: 'st-btn btn-secondary spillage-convert-button'
          });
        }

        if (this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model.status') != 'closed') {
          buttons.push({
            text: this.intl.t('common.actions.saveAndClose'),
            click: () => {
              this.set('newStatus', 'close');
              this.set('confirmModalOpen', true);
            },
            classNamesString: 'st-btn st-btn-secondary spillage-save-close-button'
          }, {
            text: this.intl.t('common.actions.save'),
            click: buttonPromise => {
              return this.saveSpillageData.perform(this.multiStepForm, buttonPromise);
            },
            classNamesString: 'st-btn st-btn-primary spillage-save-button'
          });
        }
      }

      copieModelsHashedData.lineItems.forEach(item => {
        item.set('totalCost', +item.get('cost') * +item.get('quantity'));
      });
      this.multiStepForm.registerStepData("step1", 'buttons', buttons);
      var status = this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model.status');
      var createdBy = copieModelsHashedData.createdByUser && copieModelsHashedData.createdByUser.get('id') > 0 ? copieModelsHashedData.createdByUser.get('fname') + ' ' + copieModelsHashedData.createdByUser.get('lname') : '';
      this.set('completedStatuses', {
        'open': {
          'closed': true,
          'secondary_text': (0, _moment.default)(this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model.createdAt')).format('MM/DD/YYYY h:mm a') + '<br>' + createdBy
        },
        'submitted': {
          'closed': status !== 'open',
          'secondary_text': ''
        },
        'closed': {
          'closed': status === 'closed',
          'secondary_text': ''
        }
      });
      this.set('spillageEquipmentLineItems', copieModelsHashedData.lineItems);
      this.set('equipmentReturnReason', hashedData.equipmentReturnReason);
      this.set('equipment', hashedData.equipment);
      var copiedModel = this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model');
      this.set('recipient', this.spillage ? copiedModel.get('recipient') : copiedModel.get('user') ? copiedModel.get('user') : copiedModel.get('location'));
      this.set('dataLoaded', true);
    }).on('init'),
    groupedRma: Ember.computed('multiStepForm.formCopiedModels.equipmentSpillage.model.pendingEquipmentLineItems.[]', 'relatedDocuments', function () {
      return this.groupedRmaTask.perform('rmaGroups');
    }),
    noRma: Ember.computed('multiStepForm.formCopiedModels.equipmentSpillage.model.pendingEquipmentLineItems.[]', 'relatedDocuments', function () {
      return this.groupedRmaTask.perform('noRmaGroup');
    }),
    groupedRmaTask: (0, _emberConcurrency.task)(function* (groupType) {
      var childTasks = [];
      var childRma = this.relatedDocuments;
      var lineItems = this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model.pendingEquipmentLineItems');

      if (childRma) {
        for (var i = 0; i < childRma.get('length'); i++) {
          var rma = childRma.objectAt(i);
          var childLineItems = rma.get('pendingEquipmentLineItems');

          for (var j = 0; j < childLineItems.get('length'); j++) {
            var childLineItem = childLineItems.objectAt(j);
            var childLineItemParent = childLineItem ? childLineItem.get('parentPendingEquipmentLineItem') : null;
            var parentLineItem = childLineItemParent ? lineItems.findBy('id', childLineItemParent.get('id')) : null;

            if (parentLineItem) {
              parentLineItem.set('childRma', childLineItem ? childLineItem.get('equipmentReturnMerchandiseAuthorization') : null);
            }
          }
        }
      }

      if (lineItems) {
        (0, _emberConcurrency.all)(lineItems.map(item => {
          if (item.get('childRma') && groupType === 'rmaGroups') {
            childTasks.push(this.mapRmaTask.perform(item));
          } else if (!item.get('childRma') && groupType !== 'rmaGroups') {
            childTasks.push(this.mapRmaTask.perform(item));
          }
        }));
      }

      var allLineItems = yield (0, _emberConcurrency.all)(childTasks);
      var rmaGroups = allLineItems.uniqBy('rma.id').map(({
        rma
      }) => {
        if (rma.get('id') > 0) {
          return Ember.Object.create({
            name: this.intl.t('features.inventory.rma.rma_group_title', {
              id: rma.get('id'),
              vendor: rma.get('equipmentVendor.name')
            }),
            icon: 'icn-redo',
            sections: (0, _groupedPropertySections.default)(),
            items: allLineItems.filterBy('rma.id', rma.id).mapBy('equipment'),
            status: rma.get('isFulfilled') ? this.intl.t('pages.administration.company_settings.inventory.purchase_requisitions.fulfilled') : rma.get('status'),
            statusIcon: rma.get('isFulfilled') ? 'icn-circle-check' : 'icn-issue'
          });
        }
      });
      var noRmaGroup = allLineItems.uniqBy('rma.id').map(({
        rma
      }) => {
        if (rma.get('id') === 0) {
          return Ember.Object.create({
            name: rma.get('name'),
            icon: rma.get('icon'),
            sections: (0, _groupedPropertySections.default)(),
            items: allLineItems.filterBy('rma.id', rma.id).mapBy('equipment'),
            status: '',
            statusIcon: ''
          });
        }
      });
      return groupType === 'rmaGroups' ? rmaGroups : noRmaGroup;
    }),
    mapRmaTask: (0, _emberConcurrency.task)(function* (item) {
      var equipmentRma = yield item.get('childRma');
      var equipment = item.equipment;
      var equipmentItem = equipment ? equipment.get('equipmentItem') : null;
      var equipmentRmaNone = Ember.Object.create({
        id: 0,
        name: this.intl.t('pages.administration.company_settings.inventory.spillage.no_rma'),
        icon: 'icn-access-none',
        equipment: equipmentItem
      });
      return {
        equipment: item,
        rma: equipmentRma ? equipmentRma : equipmentRmaNone
      };
    }),
    closeSpillageTask: (0, _emberConcurrency.task)(function* (buttonPromise) {
      try {
        yield this.saveLineItemsTask.perform(this.multiStepForm);
        var spillage = this.multiStepForm.formOriginalModelsGetVal('equipmentSpillage.model');
        yield spillage.invokeAction('set_status_closed', {
          verb: 'POST'
        });
        buttonPromise.then(() => {
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
          this.set('pendingRMA');
          this.multiStepForm.formStartedSetVal(false);

          if (this.refreshModel) {
            this.refreshModel();
          }
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal2'
        });
        throw err;
      }
    }),
    saveSpillageData: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      yield this.saveLineItemsTask.perform(multiStepForm);
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        multiStepForm.formStartedSetVal(false);

        if (this.refreshModel) {
          this.refreshModel();
        }
      });
    }),
    saveLineItemsTask: (0, _emberConcurrency.task)(function* (multiStepForm) {
      yield multiStepForm.validateSyncSaveAll({
        notificationNamespace: 'modal',
        extraValidationData: {
          noValidate: this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model.user.id') ? ['equipmentSpillage-location'] : ['equipmentSpillage-user']
        }
      });
    }),
    convertRMATask: (0, _emberConcurrency.task)(function* (buttonPromise) {
      try {
        var store = this.store;
        var spillage = this.multiStepForm.formOriginalModelsGetVal('equipmentSpillage.model');
        var pendingEquipmentLineItems = yield this.spillageEquipmentLineItems;
        var lineItems = [];
        pendingEquipmentLineItems.forEach(item => {
          var lineItem = {
            pending_equipment_line_item_id: item.get('id'),
            equipment_vendor_id: item.get('responseMeta.default_vendor.id') || item.get('responseMeta.default_vendor')
          };

          if (item.get('responseMeta.default_vendor') !== null) {
            lineItems.push(lineItem);
          }
        });

        if (lineItems.get('length') === 0) {
          throw this.intl.t('pages.administration.company_settings.inventory.purchase_requisitions.no_vendors_set');
        }

        var hashedData = yield (0, _emberConcurrency.hash)({
          equipmentVendors: store.findAll('equipmentVendor'),
          technicians: store.findAll('user', {
            filter: 'user_inventory_setting.inventory_recipient = true  and user_admin_setting.active = true',
            sort: 'lname,fname'
          }),
          locations: store.findAll('location', {
            filter: 'inventory_recipient = true  and active = true'
          }),
          pendingRMA: spillage.invokeAction('get_conversion_data', {
            verb: 'POST',
            data: {
              data: {
                attributes: {
                  pending_equipment_line_item_data: lineItems
                }
              }
            }
          })
        });
        hashedData.pendingRMA.responsePayload.data.forEach(rma => {
          var type = rma.recipient_type;
          var recipient = (type === 'location' ? hashedData.locations : hashedData.technicians).find(recipient => recipient.get('id') == rma.recipient_id);
          var vendor = hashedData.equipmentVendors.find(equipmentVendor => equipmentVendor.get('id') == rma.vendor_id);

          if (vendor && vendor.get('id') > 0) {
            var _type = rma.recipient_type;
            rma['recipient'] = recipient;
            rma['removed'] = false;
            rma['equipmentVendor'] = vendor;
            rma['type'] = _type;
            rma['parent'] = spillage;
            rma['title'] = this.intl.t('pages.administration.company_settings.inventory.company_replenishment.pending_rma_title', {
              name: recipient.get('flname') || recipient.get('name'),
              vendor: vendor.get('name')
            });
          }
        });
        this.set('pendingRMA', this.pendingRMA ? this.pendingRMA.concat(hashedData.pendingRMA.responsePayload.data) : hashedData.pendingRMA.responsePayload.data);
        yield spillage.invokeAction('set_status_closed', {
          verb: 'POST'
        });
        buttonPromise.then(() => {
          this.set('showPendingRMA', true);
          this.multiStepForm.formStartedSetVal(false);

          if (this.refreshModel) {
            this.refreshModel();
          }
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal2'
        });
        throw err;
      }
    })
  });

  _exports.default = _default;
});