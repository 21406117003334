define("securitytrax-ember-ui/components/pages/administration/company-settings/integrations/monitoring-company-list/component", ["exports", "@glimmer/component", "ember-concurrency", "securitytrax-ember-ui/helpers/feature-is-enabled"], function (_exports, _component, _emberConcurrency, _featureIsEnabled) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var MonitoringCompanyList = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = (0, _emberConcurrency.task)(function* () {
    var newFilters = yield this.stFiltersService.getFiltersByGroupKey('companyMcs');
    var mcs = yield this.store.query('monitoringCompany', {
      filter: newFilters,
      sort: 'id',
      include: 'monitoring_company_type',
      limit: this.limit,
      page: this.page,
      count: 1
    });
    Ember.set(this, 'recordCount', mcs.meta.record_count);
    return mcs;
  }), _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, (_class = (_temp = class MonitoringCompanyList extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "grants", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "stFiltersService", _descriptor4, this);

      _initializerDefineProperty(this, "mcStatus", _descriptor5, this);

      _initializerDefineProperty(this, "recordCount", _descriptor6, this);

      _initializerDefineProperty(this, "page", _descriptor7, this);

      _initializerDefineProperty(this, "limit", _descriptor8, this);

      _initializerDefineProperty(this, "monitoringCompany", _descriptor9, this);

      _initializerDefineProperty(this, "accountNumberBlock", _descriptor10, this);

      _initializerDefineProperty(this, "monitoringCompanyFormStarted", _descriptor11, this);

      _initializerDefineProperty(this, "monitoringCompanyFormMode", _descriptor12, this);

      _initializerDefineProperty(this, "modalTitleInfo", _descriptor13, this);

      _initializerDefineProperty(this, "getMonitoringCompanies", _descriptor14, this);

      this.modalTitleInfo = Ember.Object.create({
        name: '',
        id: ''
      });
    }

    get monitoringFullScreen() {
      var hasGrant = this.grants.getIndividualGrant('monitoring_company_configs.manage');
      return this.monitoringCompanyFormMode !== 'create' && hasGrant;
    }

    get monitoringCompanyFormSteps() {
      var viewOrAdd = this.monitoringCompanyFormMode === 'create' ? 'create' : 'view';
      var title = this.intl.t("common.forms.descriptive_title_".concat(viewOrAdd), {
        name: this.monitoringCompanyFormMode === 'create' ? this.intl.t('pages.administration.integrations.monitoringCompanies.label') : this.modalTitleInfo.name,
        id: this.modalTitleInfo.id
      });
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title,
          stepComponentPath: 'multi-step-form/forms/administration/integrations/monitoring-companies/create',
          progressBarTitle: this.intl.t("pages.administration.integrations.monitoringCompanies.progress_bar_create")
        }),
        integration: Ember.Object.create({
          stepNameKey: 'integration',
          title,
          stepComponentPath: 'multi-step-form/forms/administration/integrations/monitoring-companies/integrations',
          progressBarTitle: this.intl.t("pages.administration.integrations.monitoringCompanies.progress_bar_integration"),
          granted: Ember.getOwner(this).hasRegistration('component:' + this.integrationFormComponent + '/component')
        }),
        accountNumberBlocks: Ember.Object.create({
          stepNameKey: 'accountNumberBlocks',
          title,
          stepComponentPath: 'multi-step-form/forms/administration/integrations/monitoring-companies/account-number-blocks',
          progressBarTitle: this.intl.t("pages.administration.integrations.monitoringCompanies.progress_bar_account_number_blocks")
        })
      });
    }

    get monitoringCompanyFormOriginalModels() {
      return Ember.Object.create({
        monitoringCompany: {
          model: this.monitoringCompany,
          copyRelationMap: {
            monitoringCompanyType: {
              readOnly: true
            },
            monitoringCompanyConfig: {}
          }
        },
        accountNumberBlock: {
          model: this.accountNumberBlock,
          copyRelationMap: {
            monitoringCompany: {
              readOnly: true
            }
          }
        }
      });
    }

    get monitoringCompanyClass() {
      var monitoringCompanyName = this.monitoringCompany ? this.monitoringCompany.get('name') : '';
      return monitoringCompanyName ? 'monitoring-company-' + Ember.String.dasherize(monitoringCompanyName) : '';
    }

    get integrationFormComponent() {
      var monitoringCompanyId = this.monitoringCompany ? this.monitoringCompany.get('monitoringCompanyType.id') : '';
      var formComponent = 'multi-step-form/forms/administration/integrations/monitoring-companies/integrations/';

      switch (Number(monitoringCompanyId)) {
        case 1:
          formComponent += 'adt-integration';
          break;

        case 3:
          formComponent += 'brinks-integration';
          break;

        case 24:
          formComponent += 'cms-integration';
          break;

        case 2:
        case 8:
        case 11:
        case 28:
        case 41:
          //All American Monitoring
          formComponent += 'stages-integration';
          break;

        case 12:
        case 13:
        case 18:
        case 36:
        case 39: //Alarm Central

        case 42:
          //ESC Central
          formComponent += 'bold-integration';
          break;

        case 25:
          formComponent += 'cops-integration';
          break;

        case 17:
          formComponent += 'affiliated-integration';
          break;

        case 37:
          formComponent += 'api-integration';
          break;

        case 14:
          formComponent += 'guardian-protection-services-integration';
          break;

        case 27:
          formComponent += (0, _featureIsEnabled.featureIsEnabled)('telusIntegration') ? 'telus-integration' : '';
          break;
      }

      return formComponent;
    }

    get monitoringCompanies() {
      return this.getMonitoringCompanies.perform();
    }

    afterCreate(options) {
      if (options.isCreate) {
        this.monitoringCompany = options.saved;
        this.monitoringCompanyFormMode = 'edit';
        this.updateModalTitle();
      }

      Ember.notifyPropertyChange(this, 'monitoringCompanies');
    }

    resetFormSteps(multiStepForm, registerButtons) {
      multiStepForm.currentStepNameKeySetVal('step1');

      if (registerButtons && typeof registerButtons === 'function') {
        registerButtons();
      }
    }

    updateModalTitle() {
      this.modalTitleInfo.name = this.monitoringCompany ? this.monitoringCompany.name : null;
      this.modalTitleInfo.id = this.monitoringCompany ? this.monitoringCompany.id : null;
    }

    filterSearch(setPage = true) {
      if (setPage) {
        Ember.set(this, 'page', 1);
      }

      Ember.notifyPropertyChange(this, 'monitoringCompanies');
    }

    clearFilters() {
      Ember.setProperties(this, {
        page: 1,
        mcStatus: undefined
      });
      Ember.notifyPropertyChange(this, 'monitoringCompanies');
      Ember.notifyPropertyChange(this, 'page');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stFiltersService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "mcStatus", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "recordCount", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "limit", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 50;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "monitoringCompany", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "accountNumberBlock", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "monitoringCompanyFormStarted", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "monitoringCompanyFormMode", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'edit';
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "modalTitleInfo", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "getMonitoringCompanies", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "afterCreate", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "afterCreate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetFormSteps", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "resetFormSteps"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateModalTitle", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "updateModalTitle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterSearch", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "filterSearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilters", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilters"), _class.prototype)), _class));
  _exports.default = MonitoringCompanyList;
});