define("securitytrax-ember-ui/components/customer/information/customer-equipment/component", ["exports", "@glimmer/component", "ember-concurrency", "securitytrax-ember-ui/mixed-in-classes/grouped-equipment", "securitytrax-ember-ui/mixed-in-functions/grouped-property-sections", "securitytrax-ember-ui/mixed-in-functions/checkbox-list-mixin", "securitytrax-ember-ui/utils/obj-helpers", "securitytrax-ember-ui/classes/equipment-sold-by-options", "securitytrax-ember-ui/mixed-in-classes/table-column-sort"], function (_exports, _component, _emberConcurrency, _groupedEquipment, _groupedPropertySections, _checkboxListMixin, _objHelpers, _equipmentSoldByOptions, _tableColumnSort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _dec40, _dec41, _dec42, _dec43, _dec44, _dec45, _dec46, _dec47, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CustomerEquipment = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember.inject.service('events/transitionEvent'), _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._tracked, _dec21 = Ember._tracked, _dec22 = Ember._tracked, _dec23 = Ember._tracked, _dec24 = Ember._tracked, _dec25 = Ember._tracked, _dec26 = Ember._tracked, _dec27 = Ember._tracked, _dec28 = Ember._tracked, _dec29 = Ember._tracked, _dec30 = Ember._tracked, _dec31 = Ember._tracked, _dec32 = Ember._tracked, _dec33 = (0, _emberConcurrency.task)(function* () {
    var showRemoved = this.showRemoved;
    var childTasks = [];
    var sortedCustomerEquipment = [];

    if (this.ascending === null) {
      sortedCustomerEquipment = this.args.model.customerEquipment.sortBy('id');
    } else {
      //handle sorting
      sortedCustomerEquipment = this.TableColumnSort.sortEmberDataCollection(this.args.model.customerEquipment, this.column, this.ascending);
    }

    if (sortedCustomerEquipment) {
      (0, _emberConcurrency.all)(sortedCustomerEquipment.map(ce => {
        if (ce.get('equipmentStatus.id') == 3) {
          // Removed
          if (showRemoved) {
            childTasks.push(this.GroupedEquipment.mapItemsTask.perform(ce));
          }
        } else {
          childTasks.push(this.GroupedEquipment.mapItemsTask.perform(ce));
        }
      }));
    }

    var updatedAdcUnlinkedEquipmentList = [];
    var hashedData = yield (0, _emberConcurrency.hash)({
      allCustomerEquipment: (0, _emberConcurrency.all)(childTasks),
      orderInfo: this.grants.getIndividualGrant('customer_order_information.view') ? this.args.customer.get('customerOrderInformation') : null
    });
    var tempTotal = 0;
    hashedData.allCustomerEquipment.forEach(equipment => {
      tempTotal += Number(equipment.equipment.itemCharge);
    });
    this.totalAmount = tempTotal; // Check to see if customer has an ISP

    var isp = hashedData.orderInfo ? yield hashedData.orderInfo.get('interactiveServicesProvider') : null;

    if (isp && isp.get('interactiveServicesProviderType.id') == 1 && isp.get('isConnected')) {
      // Get and map ADC equipment
      try {
        var adcEquipmentList;

        if (!this.equipmentListFromAdc) {
          adcEquipmentList = yield this.ispService.get('invokeIspActionTask').perform({
            model: this.args.customer,
            action: 'alarm_dot_com__getFullEquipmentList',
            attributes: {}
          });
          this.equipmentListFromAdc = adcEquipmentList;
        } else {
          adcEquipmentList = this.equipmentListFromAdc;
        }

        if (adcEquipmentList && adcEquipmentList.equipment_list) {
          var mappedAdcEquipmentList = adcEquipmentList.equipment_list.map(device => {
            var icon;
            var category;
            var color;

            if (device.z_wave_manufacturer) {
              category = 'ZWave';
              icon = 'icn-zwave';
              color = 'background-color: #5b3d8c';
            } else if (device.device_type === 'Camera') {
              category = 'Camera';
              icon = 'icn-feature-video';
              color = 'background-color: #003865';
            } else if (device.device_type === 'Partition') {
              category = 'Panel';
              icon = 'icn-panel';
              color = 'background-color: #2071dd';
            } else {
              category = 'Sensor';
              icon = 'icn-sensor-closed';
              color = 'background-color: #22a1c1';
            }

            return Ember.Object.create({
              id: device.device_id,
              deviceName: device.website_device_name,
              macAddress: device.mac_address,
              installDate: device.install_date,
              deviceType: device.device_type,
              status: device.status[0],
              group: device.group,
              monitoredForNormalActivity: device.monitored_for_normal_activity,
              maintainDate: device.maintain_date,
              isChecked: undefined,
              icon: icon,
              category: category,
              color: color
            });
          }); // Match ADC equipment to ST equipment

          hashedData.allCustomerEquipment.forEach((equipment, index) => {
            if (equipment.equipment.interactiveServicesId) {
              var matchedIndex = mappedAdcEquipmentList.findIndex(adcEquipment => {
                return adcEquipment.id == equipment.equipment.interactiveServicesId;
              });

              if (matchedIndex !== -1) {
                Ember.set(hashedData.allCustomerEquipment[index], 'equipment.adcMatchedEquipment', mappedAdcEquipmentList[matchedIndex]);
                mappedAdcEquipmentList.splice(matchedIndex, 1);
              }
            } else {
              Ember.set(hashedData.allCustomerEquipment[index], 'equipment.adcMatchedEquipment', null);
            }
          });

          if (this.ascending === null) {
            updatedAdcUnlinkedEquipmentList = mappedAdcEquipmentList.sortBy('id');
          } else {
            // Handle sorting
            updatedAdcUnlinkedEquipmentList = this.TableColumnSort.sortEmberDataCollection(mappedAdcEquipmentList, this.column, this.ascending);
          }
        }
      } catch (err) {
        this.noAdcIntegration = true;
      }
    } else {
      this.noAdcIntegration = true;
    }

    var finalCustomerEquipment = hashedData.allCustomerEquipment.uniqBy('type.id').map(({
      type
    }) => {
      return Ember.Object.create({
        name: type.get('name'),
        icon: type.get('icon'),
        color: "background-color: ".concat(type.get('color')),
        sections: (0, _groupedPropertySections.default)(),
        items: hashedData.allCustomerEquipment.filterBy('type.id', type.id).mapBy('equipment'),
        collapsable: true,
        collapsed: false,
        collapseIconName: 'icn-chevron-down'
      });
    });
    var finalAdcUnlinkedEquipmentList = updatedAdcUnlinkedEquipmentList.uniqBy('category').map(item => {
      return Ember.Object.create({
        name: item.category,
        icon: item.icon,
        color: item.color,
        items: updatedAdcUnlinkedEquipmentList.filterBy('category', item.category),
        collapsable: true,
        collapsed: false,
        collapseIconName: 'icn-chevron-down'
      });
    });
    var unlinkedAdcEquipmentCount = 0;
    finalAdcUnlinkedEquipmentList.forEach(group => {
      unlinkedAdcEquipmentCount += group.items.length;
    });
    this.unlinkedAdcEquipmentCount = unlinkedAdcEquipmentCount;
    return Ember.Object.create({
      customerEquipment: finalCustomerEquipment,
      adcUnlinkedEquipment: finalAdcUnlinkedEquipmentList
    });
  }), _dec34 = (0, _emberConcurrency.task)(function* (adcEditList, groupedItems) {
    var equipmentGroupedItems = groupedItems ? yield groupedItems : yield this.groupedItems;
    var groups;

    if (adcEditList.length === 0) {
      groups = equipmentGroupedItems.adcUnlinkedEquipment;
    } else {
      groups = equipmentGroupedItems.customerEquipment;
    }

    for (var i = 0; i < groups.length; i++) {
      groups[i].items.forEach(equipmentItem => {
        if (equipmentItem.get('isChecked') === true) {
          equipmentItem.set('isChecked', false);
        }
      });
    }
  }), _dec35 = (0, _emberConcurrency.task)(function* (context, buttonPromise) {
    var promiseArray = [];
    this.bulkEquipmentEditList.forEach(equipmentToDelete => {
      promiseArray.push(equipmentToDelete.destroyRecordWithRollbackTask.perform());
    });

    try {
      yield (0, _emberConcurrency.all)(promiseArray);
      buttonPromise.then(() => {
        if (context.multiStepForm || context.args.multiStepForm) {
          var multiStepForm = context.multiStepForm || context.args.multiStepForm;
          multiStepForm.onAfterSave();
          multiStepForm.formStartedSetVal(false);
        } else {
          this.afterEquipmentMatchSave();
        }

        this.stNotificationManager.addSuccessKey('pages.customers.equipment.bulkDeleteSuccess');
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(this.intl.t('pages.customers.equipment.bulkDeleteFailure') + err, {
        notificationNamespace: context.multiStepForm ? 'modal2' : 'global'
      });
      throw err;
    }
  }), _dec36 = (0, _emberConcurrency.task)(function* () {
    var customerEquipment = this.args.model.customerEquipment;
    this.isEquipmentCheckAll = false;
    this.isAdcEquipmentCheckAll = false;
    this.stEquipmentIndeterminate = false;
    this.adcEquipmentIndeterminate = false;
    (0, _checkboxListMixin.uncheckAll)(customerEquipment);
    this.bulkEquipmentEditList = Ember.A([]);
    this.filteredBulkEquipmentEditList = Ember.A([]);
    this.adcEquipmentEditList = Ember.A([]);
    this.disabledList = null;
    this.customerEquipmentFormStarted = false;
    yield this.updateIsClicked.perform([]);
    yield this.args.model.customerEquipment.reload();
    Ember.notifyPropertyChange(this, 'groupedItems');
  }), _dec37 = Ember._action, _dec38 = Ember._action, _dec39 = Ember._action, _dec40 = Ember._action, _dec41 = Ember._action, _dec42 = Ember._action, _dec43 = Ember._action, _dec44 = Ember._action, _dec45 = Ember._action, _dec46 = Ember._action, _dec47 = Ember._action, (_class = (_temp = class CustomerEquipment extends _component.default {
    // equipment match stuff

    /* Equipment form data */

    /* Equipment bulk editform data */
    // @tracked adcColumn = null;
    // @tracked adcAscending = null;
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "grants", _descriptor3, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor4, this);

      _initializerDefineProperty(this, "ispService", _descriptor5, this);

      _initializerDefineProperty(this, "screen", _descriptor6, this);

      _initializerDefineProperty(this, "userService", _descriptor7, this);

      _initializerDefineProperty(this, "router", _descriptor8, this);

      _initializerDefineProperty(this, "transitionEvent", _descriptor9, this);

      _defineProperty(this, "equipmentListFromAdc", undefined);

      _initializerDefineProperty(this, "isEquipmentCheckAll", _descriptor10, this);

      _initializerDefineProperty(this, "isAdcEquipmentCheckAll", _descriptor11, this);

      _initializerDefineProperty(this, "showRemoved", _descriptor12, this);

      _initializerDefineProperty(this, "bulkEquipmentEditList", _descriptor13, this);

      _initializerDefineProperty(this, "filteredBulkEquipmentEditList", _descriptor14, this);

      _initializerDefineProperty(this, "adcEquipmentEditList", _descriptor15, this);

      _initializerDefineProperty(this, "noAdcIntegration", _descriptor16, this);

      _initializerDefineProperty(this, "disabledList", _descriptor17, this);

      _initializerDefineProperty(this, "adcEquipmentFormStarted", _descriptor18, this);

      _initializerDefineProperty(this, "adcEquipmentInformation", _descriptor19, this);

      _initializerDefineProperty(this, "stEquipmentIndeterminate", _descriptor20, this);

      _initializerDefineProperty(this, "adcEquipmentIndeterminate", _descriptor21, this);

      _initializerDefineProperty(this, "unlinkedAdcEquipmentCount", _descriptor22, this);

      _initializerDefineProperty(this, "equipmentMatchFormStarted", _descriptor23, this);

      _initializerDefineProperty(this, "customerMatchEquipmentModel", _descriptor24, this);

      _initializerDefineProperty(this, "equipmentMatchTitleCount", _descriptor25, this);

      _initializerDefineProperty(this, "customerEquipmentModel", _descriptor26, this);

      _initializerDefineProperty(this, "customerEquipmentFormStarted", _descriptor27, this);

      _initializerDefineProperty(this, "customerEquipmentFirstStep", _descriptor28, this);

      _initializerDefineProperty(this, "customerEquipmentFormMode", _descriptor29, this);

      _initializerDefineProperty(this, "customerEquipmentBulkEditFormStarted", _descriptor30, this);

      _initializerDefineProperty(this, "column", _descriptor31, this);

      _initializerDefineProperty(this, "ascending", _descriptor32, this);

      _initializerDefineProperty(this, "groupedItemsTask", _descriptor33, this);

      _initializerDefineProperty(this, "updateIsClicked", _descriptor34, this);

      _initializerDefineProperty(this, "bulkDelete", _descriptor35, this);

      _initializerDefineProperty(this, "afterEquipmentMatchSaveTask", _descriptor36, this);

      this.TableColumnSort = new _tableColumnSort.default(...arguments); // this.AdcTableColumnSort = new TableColumnSort(...arguments);

      var customerEquipment = this.args.model.customerEquipment;
      customerEquipment.forEach(equipment => {
        equipment.set('isChecked', false);
      });
      (0, _objHelpers.initializeState)(this, {
        bulkEquipmentEditList: Ember.A([]),
        filteredBulkEquipmentEditList: Ember.A([]),
        adcEquipmentEditList: Ember.A([]),
        stSortableColumnOptions: [{
          name: this.intl.t('common.columns.id') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'id',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.id') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-id',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('pages.customers.equipment.columns.zone') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'zoneTitle',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('pages.customers.equipment.columns.zone') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-zoneTitle',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('common.columns.name') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'equipment.name',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.name') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-equipment.name',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('common.columns.status') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'equipmentStatus.name',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.status') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-equipmentStatus.name',
          chevronDirection: 'down'
        }],
        adcSortableColumnOptions: [{
          name: this.intl.t('common.columns.id') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'id',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.id') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-id',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('common.columns.name') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'deviceName',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.name') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-deviceName',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('pages.customers.equipment.deviceType') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'deviceType',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('pages.customers.equipment.deviceType') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-deviceType',
          chevronDirection: 'down'
        }]
      });
      this.GroupedEquipment = new _groupedEquipment.default(...arguments);
      this.EquipmentSoldByOptions = new _equipmentSoldByOptions.default({
        intl: this.intl,
        customer: this.args.customer
      });
      this.transitionEvent.on('refresh-customer-equipment', this, '_refreshEventCallback');
    }

    willDestroy() {
      this.transitionEvent.off('refresh-customer-equipment', this, '_refreshEventCallback');
    }

    _refreshEventCallback() {
      return this.afterEquipmentMatchSaveTask.perform();
    }

    get equipmentStatusRules() {
      return [{
        dataPoint: 1,
        color: 'color-format-number__gray'
      }, {
        dataPoint: 2,
        color: 'color-format-number__accent_green'
      }, {
        dataPoint: 3,
        color: 'color-format-number__accent_red'
      }];
    }

    get bulkActionCloseButton() {
      var buttonChoices = [{
        text: this.intl.t('common.actions.ok'),
        click: () => {
          this.bulkEditEmptyModalStarted = false;
        },
        classNamesString: 'st-btn st-btn-primary confirm-modal-confirm-button'
      }];
      return buttonChoices;
    }

    get customerEquipmentModelToArray() {
      return [this.customerEquipmentModel];
    }

    get adcEquipmentToArray() {
      return [this.adcEquipmentInformation];
    }

    get groupedItems() {
      return this.groupedItemsTask.perform();
    }

    get equipmentMatchFormSteps() {
      return Ember.Object.create({
        equipmentMatch: Ember.Object.create({
          stepNameKey: 'equipmentMatch',
          title: this.intl.t('pages.customers.equipment.matchTitle', {
            numSelected: this.equipmentMatchTitleCount
          }),
          stepComponentPath: 'multi-step-form/forms/customer-equipment/match-equipment'
        })
      });
    }

    get equipmentMatchFormOriginalModels() {
      return Ember.Object.create({
        customerEquipment: {
          model: this.customerMatchEquipmentModel,
          copyRelationMap: {
            user: {
              doCopy: true,
              doValidate: false,
              doSave: false
            },
            equipment: {
              doCopy: this.grants.getIndividualGrant('customer_equipment.view'),
              doValidate: false,
              doSave: false
            },
            equipmentStatus: {
              doValidate: false,
              doSave: false
            }
          }
        }
      });
    }

    get customerEquipmentBulkEditFormSteps() {
      return Ember.Object.create({
        bulkEdit: Ember.Object.create({
          stepNameKey: 'bulkEdit',
          title: this.intl.t('pages.customers.equipment.bulkEditTitle', {
            count: this.stOrAdcEquipmentListLength
          }),
          stepComponentPath: 'multi-step-form/forms/customer-equipment/bulk-edit'
        })
      });
    }

    get customerEquipmentBulkEditFormOriginalModels() {
      return Ember.Object.create({
        customerEquipment: {
          model: this.customerEquipmentModel,
          copyRelationMap: {
            user: {
              doValidate: false,
              doSave: false
            }
          }
        }
      });
    }

    get adcEquipmentFormSteps() {
      return Ember.Object.create({
        details: Ember.Object.create({
          stepNameKey: 'details',
          title: this.intl.t('pages.customers.equipment.adcEquipmentTitle', {
            name: this.adcEquipmentInformation ? this.adcEquipmentInformation.deviceName : null,
            id: this.adcEquipmentInformation ? this.adcEquipmentInformation.id : null
          }),
          stepComponentPath: 'multi-step-form/forms/customer-equipment/adc-equipment'
        }),
        equipmentMatch: Ember.Object.create({
          stepNameKey: 'equipmentMatch',
          title: this.intl.t('pages.customers.equipment.matchTitleSingle', {
            id: this.adcEquipmentInformation ? this.adcEquipmentInformation.id : null
          }),
          stepComponentPath: 'multi-step-form/forms/customer-equipment/match-equipment'
        })
      });
    }

    get adcEquipmentFormOriginalModels() {
      return Ember.Object.create({
        customerEquipment: {
          model: null,
          copyRelationMap: {
            user: {
              doValidate: false,
              doSave: false
            }
          }
        }
      });
    }

    get customerEquipmentFormSteps() {
      var intl = this.intl;
      return Ember.Object.create({
        addEquipment: Ember.Object.create({
          stepNameKey: 'addEquipment',
          title: intl.t('pages.customers.equipment.modal_title.create', {
            name: this.args.customer.get('flname')
          }),
          stepComponentPath: 'multi-step-form/forms/customer-equipment/add-equipment'
        }),
        details: Ember.Object.create({
          stepNameKey: 'details',
          title: intl.t('pages.customers.equipment.modal_title.edit', {
            name: this.args.customer.get('flname'),
            id: this.customerEquipmentModel ? this.customerEquipmentModel.get('id') : null
          }),
          stepComponentPath: 'multi-step-form/forms/customer-equipment/details'
        }),
        equipmentMatch: Ember.Object.create({
          stepNameKey: 'equipmentMatch',
          title: intl.t('pages.customers.equipment.matchTitleSingle', {
            id: this.customerEquipmentModel ? this.customerEquipmentModel.get('id') : null
          }),
          stepComponentPath: 'multi-step-form/forms/customer-equipment/match-equipment'
        })
      });
    }

    get customerEquipmentFormOriginalModels() {
      var grants = this.grants;
      return Ember.Object.create({
        customerEquipment: {
          model: this.customerEquipmentModel,
          copyRelationMap: {
            equipment: {
              doCopy: grants.getIndividualGrant('customer_equipment.view'),
              doValidate: false,
              doSave: false
            },
            equipmentStatus: {
              doValidate: false,
              doSave: false
            },
            equipmentWireType: {
              doValidate: false,
              doSave: false
            },
            installCalendarEvent: {
              doValidate: false,
              doSave: false
            },
            uninstallCalendarEvent: {
              doValidate: false,
              doSave: false
            },
            installInventoryTechUser: {
              doCopy: true,
              doValidate: false,
              doSave: false
            },
            uninstallInventoryTechUser: {
              doCopy: true,
              doValidate: false,
              doSave: false
            },
            customerSystemInformation: {
              doCopy: grants.getIndividualGrant('customer_system_information.view')
            },
            user: {
              doCopy: true,
              doValidate: false,
              doSave: false
            }
          }
        }
      });
    }

    get equipmentWasFilteredOutOfBulkEditList() {
      return this.bulkEquipmentEditList.length > this.filteredBulkEquipmentEditList.length;
    }

    get stOrAdcEquipmentListLength() {
      if (this.bulkEquipmentEditList.length) {
        return this.bulkEquipmentEditList.length;
      } else if (this.adcEquipmentEditList.length) {
        return this.adcEquipmentEditList.length;
      } else {
        return 0;
      }
    }

    updateMatchTitle() {
      this.equipmentMatchTitleCount = this.bulkEquipmentEditList.length > 0 ? this.filteredBulkEquipmentEditList.length : this.stOrAdcEquipmentListLength;
    }

    startCustomerEquipmentEditOrBulkEdit(singleItem) {
      if (this.bulkEquipmentEditList.length <= 1 || singleItem) {
        var equipmentItem = singleItem ? singleItem : this.bulkEquipmentEditList.objectAt(0);

        if (this.args.isASG) {
          this.customerEquipmentFirstStep = 'details';
          this.customerEquipmentModel = equipmentItem;
          this.customerEquipmentFormMode = this.grants.getIndividualGrant('customer_equipment.modify') ? 'edit' : 'view';
          this.openModalIfAllowed('stEquipment');
        } else {
          this.router.transitionTo('customers.equipment.edit-create-customer-equipment', equipmentItem.get('id'));
        }
      } else {
        this.customerEquipmentModel = this.bulkEquipmentEditList.objectAt(0);
        this.customerEquipmentBulkEditFormStarted = true;
      }
    }

    equipmentChecked(listToEdit, equipmentRow) {
      var bulkList = this[listToEdit];
      var equipmentIndex = bulkList.indexOf(equipmentRow.get('id'));
      var isChecked = equipmentRow.get('isChecked') ? false : true;

      if (isChecked) {
        if (equipmentIndex === -1) {
          bulkList.pushObject(equipmentRow);
          equipmentRow.set('isChecked', true);
        }
      } else {
        bulkList.removeObject(equipmentRow);
        equipmentRow.set('isChecked', false);
      }

      this[listToEdit] = bulkList;
      this.filteredBulkEquipmentEditList = this.bulkEquipmentEditList.filter(equipment => {
        return !equipment.interactiveServicesId;
      });

      if (listToEdit === 'adcEquipmentEditList') {
        this.disabledList = 'stEquipment';
        this.adcEquipmentIndeterminate = this.unlinkedAdcEquipmentCount > this[listToEdit].length && this[listToEdit].length > 0;
        this.isAdcEquipmentCheckAll = this.unlinkedAdcEquipmentCount === this[listToEdit].length && this[listToEdit].length > 0;
      } else {
        this.disabledList = 'adcEquipment';
        this.stEquipmentIndeterminate = this.args.model.customer.customerEquipment.length > this[listToEdit].length && this[listToEdit].length > 0;
        this.isEquipmentCheckAll = this.args.model.customer.customerEquipment.length === this[listToEdit].length && this[listToEdit].length > 0;
      }

      if (this.stOrAdcEquipmentListLength === 0) {
        this.disabledList = null;
      }
    }

    equipmentCheckAll() {
      var customerEquipment = this.args.model.customer.customerEquipment;
      this.isEquipmentCheckAll = !this.isEquipmentCheckAll;
      var isChecked = this.isEquipmentCheckAll;

      if (isChecked) {
        var list = (0, _checkboxListMixin.checkAll)(customerEquipment);
        this.bulkEquipmentEditList = list;
        this.disabledList = 'adcEquipment';
      } else {
        (0, _checkboxListMixin.uncheckAll)(customerEquipment);
        this.bulkEquipmentEditList = Ember.A([]);
        this.disabledList = null;
      }

      this.stEquipmentIndeterminate = false;
      this.filteredBulkEquipmentEditList = this.bulkEquipmentEditList.filter(equipment => {
        return !equipment.interactiveServicesId;
      });
    }

    adcEquipmentCheckAll(equipmentGroups) {
      var ungroupedArray = [];
      equipmentGroups.forEach(group => {
        group.items.forEach(equipmentItem => {
          ungroupedArray.push(equipmentItem);
        });
      });
      this.isAdcEquipmentCheckAll = !this.isAdcEquipmentCheckAll;
      var isChecked = this.isAdcEquipmentCheckAll;

      if (isChecked) {
        var list = (0, _checkboxListMixin.checkAll)(ungroupedArray);
        this.adcEquipmentEditList = list;
        this.disabledList = 'stEquipment';
      } else {
        (0, _checkboxListMixin.uncheckAll)(ungroupedArray);
        this.adcEquipmentEditList = Ember.A([]);
        this.disabledList = null;
      }

      this.adcEquipmentIndeterminate = false;
    }

    openModalIfAllowed(clickedList) {
      if (this.disabledList != clickedList) {
        if (clickedList === 'stEquipment') {
          this.customerEquipmentFormStarted = true;
        } else {
          this.adcEquipmentFormStarted = true;
        }
      }
    }

    afterEquipmentMatchSave() {
      this.afterEquipmentMatchSaveTask.perform();
    }

    afterEquipmentDelete() {
      this.afterEquipmentMatchSaveTask.perform();
    }

    openEquipmentMatchModal() {
      if (this.equipmentWasFilteredOutOfBulkEditList && this.filteredBulkEquipmentEditList.length === 0) {
        this.stNotificationManager.addErrorMessages(this.intl.t('pages.customers.equipment.onlyMatchedEquipmentErrorMessage'), {
          notificationNamespace: 'global'
        });
      } else {
        this.updateMatchTitle();
        this.equipmentMatchFormStarted = true;
      }
    }

    sortList(column, ascending) {
      this.TableColumnSort.sortList(column, ascending);
      Ember.setProperties(this, {
        column,
        ascending
      });
      Ember.notifyPropertyChange(this, 'groupedItems');
    }

    mobileSortList(column) {
      this.TableColumnSort.sortMobileList(column);
      Ember.setProperties(this, {
        column: this.TableColumnSort.stSortCurrentSortColumn,
        ascending: this.TableColumnSort.stSortAscending
      });
      Ember.notifyPropertyChange(this, 'groupedItems');
    }

    startEquipmentAdd() {
      if (this.args.isASG) {
        this.customerEquipmentFirstStep = 'addEquipment';
        this.customerEquipmentModel = null;
        this.customerEquipmentFormStarted = true;
        this.customerEquipmentFormMode = 'create';
      } else {
        this.router.transitionTo('customers.equipment.edit-create-customer-equipment', 'create');
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "ispService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "screen", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "userService", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "transitionEvent", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isEquipmentCheckAll", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isAdcEquipmentCheckAll", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "showRemoved", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "bulkEquipmentEditList", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "filteredBulkEquipmentEditList", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "adcEquipmentEditList", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "noAdcIntegration", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "disabledList", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "adcEquipmentFormStarted", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "adcEquipmentInformation", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "stEquipmentIndeterminate", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "adcEquipmentIndeterminate", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "unlinkedAdcEquipmentCount", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "equipmentMatchFormStarted", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "customerMatchEquipmentModel", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "equipmentMatchTitleCount", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "customerEquipmentModel", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "customerEquipmentFormStarted", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "customerEquipmentFirstStep", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'details';
    }
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "customerEquipmentFormMode", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'create';
    }
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "customerEquipmentBulkEditFormStarted", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "column", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "ascending", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "groupedItemsTask", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "updateIsClicked", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "bulkDelete", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor36 = _applyDecoratedDescriptor(_class.prototype, "afterEquipmentMatchSaveTask", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "startCustomerEquipmentEditOrBulkEdit", [_dec37], Object.getOwnPropertyDescriptor(_class.prototype, "startCustomerEquipmentEditOrBulkEdit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "equipmentChecked", [_dec38], Object.getOwnPropertyDescriptor(_class.prototype, "equipmentChecked"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "equipmentCheckAll", [_dec39], Object.getOwnPropertyDescriptor(_class.prototype, "equipmentCheckAll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "adcEquipmentCheckAll", [_dec40], Object.getOwnPropertyDescriptor(_class.prototype, "adcEquipmentCheckAll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openModalIfAllowed", [_dec41], Object.getOwnPropertyDescriptor(_class.prototype, "openModalIfAllowed"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterEquipmentMatchSave", [_dec42], Object.getOwnPropertyDescriptor(_class.prototype, "afterEquipmentMatchSave"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterEquipmentDelete", [_dec43], Object.getOwnPropertyDescriptor(_class.prototype, "afterEquipmentDelete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openEquipmentMatchModal", [_dec44], Object.getOwnPropertyDescriptor(_class.prototype, "openEquipmentMatchModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortList", [_dec45], Object.getOwnPropertyDescriptor(_class.prototype, "sortList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mobileSortList", [_dec46], Object.getOwnPropertyDescriptor(_class.prototype, "mobileSortList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startEquipmentAdd", [_dec47], Object.getOwnPropertyDescriptor(_class.prototype, "startEquipmentAdd"), _class.prototype)), _class));
  _exports.default = CustomerEquipment;
});