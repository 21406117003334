define("securitytrax-ember-ui/models/docusign-account", ["exports", "securitytrax-ember-ui/models/base_models/docusign-account"], function (_exports, _docusignAccount) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _docusignAccount.default.extend({});

  _exports.default = _default;
});