define("securitytrax-ember-ui/mixins/forms/customer-billing-reusable", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This is mixin is used in both the accounting customerbilling edit and the invoice customer billing create
   *
   * @mixin ListMixin
   * @extends Ember.Mixin
   */
  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    billType: null,
    customerBillingValidationMessages: Ember.computed('billType', function () {
      var requiredProperties = ['billAddress1', 'billCity', 'billState', 'billZip'];
      var validationsObject = {
        billBankRouteInvalid: this.intl.t("pages.customers.accounting.customer_billing.billBankRoute.validateMessage"),
        billZipInvalid: this.intl.t("pages.customers.accounting.customer_billing.billZip.validateMessage"),
        customerBilling: this.intl.t("pages.customers.accounting.customer_billing.validations.select_payment_method"),
        amount: this.intl.t('pages.customers.accounting.customer_payments.amount.validateMessage'),
        receiptEmails: this.intl.t('pages.customers.accounting.customer_billing.validations.receipt_email'),
        receiptEmailInvalid: this.intl.t('pages.customers.accounting.customer_billing.validations.invalid_email')
      };

      if (this.billType === 'Credit Card' || this.multiStepForm.formCopiedModelsGetVal('customerBilling.model.billType') === 'Credit Card') {
        requiredProperties.push('billCcName', 'billCcExpirationMonth', 'billCcExpirationYear');
        validationsObject['customerBilling-billCcNumber'] = this.intl.t('pages.customers.accounting.customer_billing.billCcNumber.validateMessage');
      } else if (this.billType === 'Bank Account' || this.multiStepForm.formCopiedModelsGetVal('customerBilling.model.billType') === 'Bank Account') {
        requiredProperties.push('billBankName', 'billBankRoute', 'billBankType', 'billBankNumber');
      }

      requiredProperties.forEach(item => {
        validationsObject[item] = this.intl.t('common.validations.presence', {
          propertyLabel: this.intl.t("pages.customers.accounting.customer_billing.".concat(item, ".label"))
        });
      });
      return validationsObject;
    }),
    paymentMethodChoices: Ember.computed(function () {
      var billTypes = this.billTypes;
      var choices = Ember.A([]);

      if (billTypes) {
        billTypes.forEach(billType => {
          var translatedName;

          switch (billType.get('name')) {
            case 'Credit Card':
              translatedName = this.intl.t('common.finance.creditCard');
              break;

            case 'Bank Account':
              translatedName = this.intl.t('common.finance.bankAccount');
              break;

            case 'Manual Billing':
              translatedName = this.intl.t('common.finance.manualBilling');
              break;

            default:
              translatedName = this.intl.t('common.finance.manualBilling');
              break;
          }

          choices.pushObject(Ember.Object.create({
            packageName: translatedName,
            packageId: billType.get('id')
          }));
        });
      }

      return choices;
    }),
    getBillingTypeModel: (0, _emberConcurrency.task)(function* (chosenBillType) {
      var billingTypeToConnect;

      if (chosenBillType === 'Credit Card') {
        billingTypeToConnect = yield this.store.findRecord('billingType', 1);
      } else if (chosenBillType === 'Bank Account') {
        billingTypeToConnect = yield this.store.findRecord('billingType', 2);
      } else if (chosenBillType === 'Manual Billing') {
        billingTypeToConnect = yield this.store.findRecord('billingType', 3);
      }

      return billingTypeToConnect;
    }),
    dynamicPaymentMethodForm: Ember.computed('selectedPaymentMethod', 'formMode', function () {
      var templatePath = 'multi-step-form/forms/accounting/edit-create-payment-method/method-layouts/credit-card';
      var billTypes = this.billTypes;
      var currentBillTypeName = this.setBillType(billTypes, this.selectedPaymentMethod);

      if (currentBillTypeName === 'Credit Card') {//multiStepForm.formCopiedModels.paymentMethod.model.type = 'creditCard';
      } else if (currentBillTypeName === 'Bank Account') {
        templatePath = 'multi-step-form/forms/accounting/edit-create-payment-method/method-layouts/bank-account';
      } else if (currentBillTypeName === 'Manual Billing') {
        templatePath = 'multi-step-form/forms/accounting/edit-create-payment-method/method-layouts/manual';
      }

      return templatePath;
    }),

    setBillType(billTypes, selectedId) {
      var currentBillType = this.formMode === 'create' || !this.multiStepForm.formCopiedModelsGetVal('customerBilling.model.id') ? billTypes.findBy('id', selectedId) : this.multiStepForm.formCopiedModelsGetVal('customerBilling.model.billType');
      var currentBillTypeName = currentBillType.get('name');
      this.set('billType', currentBillTypeName);

      if (this.formMode === 'create' || !this.multiStepForm.formCopiedModelsGetVal('customerBilling.model.billType')) {
        this.multiStepForm.formCopiedModelsSetVal('customerBilling.model.billType', currentBillType);
      }

      return currentBillTypeName;
    }

  });

  _exports.default = _default;
});