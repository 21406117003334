define("securitytrax-ember-ui/components/forms/inline-edit/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['inline-edit'],
    classNameBindings: ['isEditing', 'field', 'validationError:invalid', 'disabled:inline-disabled'],

    init() {
      this._super(...arguments);

      this.set('oldValue', this.value);
    },

    isEditing: false,
    disabled: false,
    required: false,
    field: 'text',
    saveLabel: 'Save',
    fieldWidth: null,
    valueProperty: 'value',
    labelProperty: 'name',
    valueIsEmpty: Ember.computed.empty('value'),
    oldValue: undefined,
    readonly: Ember.computed.not('isEditing'),

    /**
     * True if there is an active validation error that
     * should be displayed
     *
     * @type {Boolean}
     */
    validationError: Ember.computed.notEmpty('validationErrorMessage'),

    /**
     * Message to display if there is a validation error
     *
     * @type {String}
     */
    validationErrorMessage: '',

    /**
     * Default validation hook implementation that always resolves.
     *
     * Override this to provide a validation method for the field.
     *
     * @returns {Ember.RSVP.Promise} A promise that when resolved, the field
     * will be considered valid, and when rejected, invalid. No parameters.
     */
    onValidate() {
      return Ember.RSVP.resolve();
    },

    click(e) {
      var disabled = this.disabled;

      if (disabled) {
        return false;
      }

      var isEditing = this.isEditing;
      var editor = (0, _jquery.default)(this.element);
      var target = (0, _jquery.default)(e.target);
      var isInside = editor.is(target) || editor.has(target).length > 0;

      if (isInside && !isEditing) {
        if (this.showEditButton) {
          return;
        }

        var width = Ember.String.htmlSafe('width: ' + (editor.width() + 2) + 'px');
        Ember.run(this, function () {
          this.set('fieldWidth', width);
        });
        this.send('startEditing', e);
      } else if (!isInside && isEditing) {
        this.send('cancel');
      }
    },

    keyUp(e) {
      var isEditing = this.isEditing;
      var isEnter = e.which === 13 || e.keyCode === 13;
      var isEsc = e.which === 27 || e.keyCode === 27;

      if (!isEditing) {
        return;
      }

      if (isEnter) {
        this.send('save');
      } else if (isEsc) {
        this.send('cancel');
      }
    },

    _focusOnInput() {
      Ember.run.next(() => {
        (0, _jquery.default)('.inline-edit-input').focus();
      });
    },

    willDestroyElement() {
      (0, _jquery.default)(document).off('click', this._handleClick);
      (0, _jquery.default)(this.element).off('keyup', '.inline-edit-input', this._handleKeyup);
    },

    close() {
      Ember.set(this, 'isEditing', false);
      Ember.set(this, 'validationErrorMessage', '');
    },

    onSave: () => {},
    onClose: () => {},
    actions: {
      save() {
        var value = this.value; // Only save upon successful completion of
        // the validation promise, otherwise do nothing
        // and rely on client code to display the
        // validation error

        this.onValidate(value).then(() => {
          this.onSave(value);
          Ember.run(this, function () {
            this.close();
          });
        }).catch(errorMessage => {
          this.set('validationErrorMessage', errorMessage);
        });
      },

      startEditing(e) {
        e.stopPropagation();
        Ember.run(this, function () {
          Ember.set(this, 'isEditing', true);
        });

        this._focusOnInput();
      },

      cancel() {
        this.onClose(this.oldValue);
        Ember.run(this, function () {
          Ember.set(this, 'value', this.oldValue);
          this.close();
        });
      }

    }
  });

  _exports.default = _default;
});