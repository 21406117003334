define("securitytrax-ember-ui/components/multi-step-form/forms/system/cms/history/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/system/cms/cms-base", "ember-concurrency", "moment"], function (_exports, _cmsBase, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _cmsBase.default.extend({
    centralStationService: Ember.inject.service(),
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    cmsStepReady: true,
    eventHistory: false,
    formMode: 'edit',
    historyReady: false,
    progressBarHidden: false,
    eventHistoryColumns: Ember.computed(function () {
      var intl = this.intl;
      var tRoot = 'pages.administration.integrations.monitoringCompanies.integrations.cms.history.';
      return [{
        name: intl.t(tRoot + 'additional_info'),
        valuePath: 'additional_info'
      }, {
        name: intl.t(tRoot + 'alarm_incident_number'),
        valuePath: 'alarm_incident_number'
      }, {
        name: intl.t(tRoot + 'comment'),
        valuePath: 'comment'
      }, {
        name: intl.t(tRoot + 'cs_event_number'),
        valuePath: 'cs_event_number'
      }, {
        name: intl.t(tRoot + 'cs_number'),
        valuePath: 'cs_number'
      }, {
        name: intl.t(tRoot + 'error_message'),
        valuePath: 'error_message'
      }, {
        name: intl.t(tRoot + 'event_class'),
        valuePath: 'event_class'
      }, {
        name: intl.t(tRoot + 'event_date'),
        valuePath: 'event_date'
      }, {
        name: intl.t(tRoot + 'event_description'),
        valuePath: 'event_description'
      }, {
        name: intl.t(tRoot + 'event_id'),
        valuePath: 'event_id'
      }, {
        name: intl.t(tRoot + 'event_report'),
        valuePath: 'event_report'
      }, {
        name: intl.t(tRoot + 'operator'),
        valuePath: 'operator'
      }, {
        name: intl.t(tRoot + 'operator_dispatch_id'),
        valuePath: 'operator_dispatch_id'
      }, {
        name: intl.t(tRoot + 'pascom'),
        valuePath: 'pascom'
      }, {
        name: intl.t(tRoot + 'phone'),
        valuePath: 'phone'
      }, {
        name: intl.t(tRoot + 'scheduled_date'),
        valuePath: 'scheduled_date'
      }, {
        name: intl.t(tRoot + 'sequence_number'),
        valuePath: 'sequence_number'
      }, {
        name: intl.t(tRoot + 'server_id'),
        valuePath: 'server_id'
      }, {
        name: intl.t(tRoot + 'site_name'),
        valuePath: 'site_name'
      }, {
        name: intl.t(tRoot + 'udf1'),
        valuePath: 'udf1'
      }, {
        name: intl.t(tRoot + 'udf2'),
        valuePath: 'udf2'
      }, {
        name: intl.t(tRoot + 'udf3'),
        valuePath: 'udf3'
      }, {
        name: intl.t(tRoot + 'udf4'),
        valuePath: 'udf4'
      }, {
        name: intl.t(tRoot + 'user_id'),
        valuePath: 'user_id'
      }, {
        name: intl.t(tRoot + 'user_name'),
        valuePath: 'user_name'
      }, {
        name: intl.t(tRoot + 'zone_id'),
        valuePath: 'zone_id'
      }];
    }),
    eventHistoryTask: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('historyReady', false);
        this.set('formMode', 'view');
        var startDate = this.start_date ? this.start_date.format('MM/DD/YYYY') : null;
        var startTime = this.start_time ? this.start_time.format('HH:mm') : '00:00';
        var endDate = this.end_date ? this.end_date.format('MM/DD/YYYY') : (0, _moment.default)().format('MM/DD/YYYY');
        var endTime = this.end_time ? this.end_time.format('HH:mm') : '23:59';
        var startString = startDate ? startDate + ' ' + startTime : null;
        var startDateTime = startString ? (0, _moment.default)(startString, 'MM/DD/YYYY HH:mm') : null;
        var endString = endDate + ' ' + endTime;
        var endDateTime = (0, _moment.default)(endString, 'MM/DD/YYYY HH:mm');
        var history = yield this.centralStationService.getData({
          model: this.customer,
          method: 'cms__getEventHistoryList',
          payload: {
            start_date: startDateTime,
            end_date: endDateTime
          },
          responseKey: 'event_history_list'
        });
        this.set('eventHistoryRows', history);
        this.set('formMode', 'edit');
        this.set('historyReady', true);
        this.set('eventHistory', true);
      } catch (err) {
        this.set('formMode', 'edit');
        this.set('eventHistoryRows', []);
        this.set('historyReady', true);
        this.set('eventHistory', true);
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'cmsModal'
        });
        (false && Ember.warn('cms event history error' + err, {
          id: 'multiStepForm.forms.system.cms.history'
        }));
      }
    })
  });

  _exports.default = _default;
});