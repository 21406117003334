define("securitytrax-ember-ui/components/html/st-thumbnail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5ImKMxAB",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"st-thumbnail--default\"],[12],[2,\"\\n        \"],[1,[34,2]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"img\"],[15,\"src\",[30,[36,1],[[35,0],[35,0],[32,0,[\"userService\",\"defaultProfileImageSrc\"]]],null]],[14,\"alt\",\"\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"thumbnail\",\"if\",\"shortTitle\",\"showShortTitle\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/st-thumbnail/template.hbs"
    }
  });

  _exports.default = _default;
});