define("securitytrax-ember-ui/components/graphs/partial-donut/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yKqNedL/",
    "block": "{\"symbols\":[\"legendItem\",\"@graphData\",\"@totalCountLabel\",\"@showMainLabel\",\"@showLegend\"],\"statements\":[[10,\"div\"],[14,0,\"grid-x align-center partial-donut-main\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cell small-6\"],[12],[2,\"\\n        \"],[11,\"svg\"],[24,\"width\",\"130\"],[24,\"height\",\"130\"],[4,[38,4],[[32,0,[\"insertDonut\"]]],null],[12],[2,\"\\n\"],[6,[37,5],[[32,4]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"text\"],[14,\"x\",\"65\"],[14,\"y\",\"65\"],[14,\"text-anchor\",\"middle\"],[14,0,\"total-count\"],[12],[1,[32,0,[\"totalCount\"]]],[13],[2,\"\\n                \"],[10,\"text\"],[14,\"x\",\"65\"],[14,\"y\",\"80\"],[14,\"text-anchor\",\"middle\"],[12],[1,[32,3]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,5],[[32,5]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"cell small-6 partial-donut-legend\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"div\"],[14,0,\"grid-x legend-item\"],[12],[2,\"\\n                    \"],[10,\"div\"],[15,5,[30,[36,1],[[30,[36,0],[\"background: \",[32,1,[\"color\"]],\";\"],null]],null]],[14,0,\"cell small-1 color\"],[12],[2,\"\\n                    \"],[13],[10,\"span\"],[14,0,\"cell small-1 category-count\"],[12],[1,[32,1,[\"value\"]]],[13],[2,\"\\n                    \"],[10,\"div\"],[14,0,\"cell small-10 text-align-left\"],[12],[1,[32,1,[\"label\"]]],[13],[2,\"\\n                \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"concat\",\"html-safe\",\"-track-array\",\"each\",\"did-insert\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/graphs/partial-donut/template.hbs"
    }
  });

  _exports.default = _default;
});