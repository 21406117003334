define("securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/dealer-ally/decision/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/create-financing-base", "ember-concurrency"], function (_exports, _createFinancingBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var DealerAllyCreateFinancingDecision = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = (0, _emberConcurrency.task)(function* () {
    try {
      yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
      var application = yield this.store.findRecord('consumerFinancingApplication', this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.id'));
      this.offers = application.get('applicationState') === 'approved' ? (yield application.invokeAction('get_offers', {
        verb: 'POST',
        data: {
          data: {}
        }
      })).responsePayload.data.lender_response : [];
      var choices = Ember.A([]);

      for (var i = 0; i < this.offers.get('length'); i++) {
        var currOffer = this.offers.objectAt(i);
        choices.pushObject(Ember.Object.create({
          packageName: currOffer.Name,
          packageId: currOffer.PromotionId,
          packageContent: [{
            label: this.intl.t('pages.customers.accounting.financing.apr'),
            description: currOffer.Apr + '%'
          }, {
            label: this.intl.t('pages.customers.accounting.financing.term'),
            description: currOffer.Terms + ' ' + this.intl.t('common.columns.months')
          }, {
            label: this.intl.t('pages.customers.accounting.financing.estimated_payment'),
            description: ('$' + Number(currOffer.EstimatedMonthlyPayment || 0.00).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')).replace('$-', '-$')
          }, {
            label: this.intl.t('pages.customers.accounting.financing.promotion_id'),
            description: currOffer.PromotionId
          }]
        }));
      }

      this.selectedOfferId = choices[0] ? choices[0].packageId : null;
      this.offerChoices = choices;
      this.buttons(this.offers);

      if (this.args.applicationError) {
        this.stNotificationManager.addError(this.intl.t('pages.customers.accounting.financing.application_error', {
          lender: application.get('consumerFinancingLender.consumerFinancingLenderType.name'),
          phone: '(888) 326-0301'
        }), 'modal');
      }
    } catch (err) {
      this.offers = [];
      this.offerChoices = Ember.A();
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
    }
  }), _dec8 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    try {
      var offer = this.offers.findBy('PromotionId', this.selectedOfferId);
      var application = multiStepForm.formOriginalModelsGetVal('consumerFinancingApplication.model');
      Ember.set(application, 'lenderOfferId', this.selectedOfferId);
      yield application.invokeAction('set_offer', {
        verb: 'POST',
        data: {
          data: {
            offer_info: {
              promotion_id: offer.PromotionId,
              closed_end_payment_rate_id: offer.ClosedEndPaymentRateId,
              payment_factor: offer.PaymentFactor,
              promo_code: offer.PromoCode,
              terms: offer.Terms,
              apr: offer.Apr
            }
          }
        }
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }

    buttonPromise.then(() => {
      multiStepForm.currentStepNameKeySetVal('contract');
    });
  }), _dec9 = Ember._action, (_class = (_temp = class DealerAllyCreateFinancingDecision extends _createFinancingBase.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "selectedOfferId", _descriptor4, this);

      _initializerDefineProperty(this, "offerChoices", _descriptor5, this);

      _initializerDefineProperty(this, "offers", _descriptor6, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor7, this);

      _initializerDefineProperty(this, "acceptOffer", _descriptor8, this);

      this.initDataTask.perform();
    }

    buttons(offers = undefined) {
      var buttons = [];
      buttons.push({
        text: this.intl.t(offers.length > 0 ? 'pages.customers.accounting.financing.set_promotion' : 'common.actions.done'),
        click: buttonPromise => {
          return offers.length > 0 ? this.acceptOffer.perform(this.args.multiStepForm, buttonPromise) : this.args.multiStepForm.formStartedSetVal(false);
        },
        classNamesString: 'st-btn st-btn-primary'
      });
      this.args.multiStepForm.registerStepData("decision", 'buttons', buttons);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectedOfferId", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "offerChoices", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "offers", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "acceptOffer", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "buttons", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "buttons"), _class.prototype)), _class));
  _exports.default = DealerAllyCreateFinancingDecision;
});