define("securitytrax-ember-ui/customers/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Controller
   * @extends Ember.Controller
   * @memberof customers
   */
  var Controller = Ember.Controller.extend(
  /** @lends customers.information.Controller# */
  {
    navigation: Ember.inject.service(),
    grants: Ember.inject.service(),
    tagViewCollapsed: true,
    customer: Ember.computed.alias('model.customer'),
    breadCrumbs: undefined,
    initializeNav: Ember.on('init', function () {
      this.navigation;
    }),
    canViewNotesAndTasks: Ember.computed('grants.userGrants', function () {
      var grantsService = this.grants;
      return grantsService.getIndividualGrant('customer_notes.view') || grantsService.getIndividualGrant('customer_tasks.view');
    }),
    canViewAccounting: Ember.computed('grants.userGrants', function () {
      var grantsService = this.grants;
      return grantsService.getIndividualGrant('customer_billings.view') || grantsService.getIndividualGrant('customer_invoices.view') || grantsService.getIndividualGrant('recurring_invoices.view') || grantsService.getIndividualGrant('customer_quotes.view') || grantsService.getIndividualGrant('customer_credit_memos.view') || grantsService.getIndividualGrant('customer_payments.view') || grantsService.getIndividualGrant('customer_refund_receipts.view') || grantsService.getIndividualGrant('invoice_statements.view') || grantsService.getIndividualGrant('customer_payables.view') || grantsService.getIndividualGrant('fundings.view');
    }),
    canViewOrder: Ember.computed('grants.userGrants', function () {
      var grantsService = this.grants;
      return grantsService.getIndividualGrant('customer_order_information.view') || grantsService.getIndividualGrant('customer_invoices.view') || grantsService.getIndividualGrant('customer_quotes.view') || grantsService.getIndividualGrant('customer_credit_memos.view') || grantsService.getIndividualGrant('recurring_invoices.view') || grantsService.getIndividualGrant('customer_equipment.view');
    }),
    canViewSystem: Ember.computed('grants.userGrants', function () {
      var grantsService = this.grants;
      return grantsService.getIndividualGrant('customer_system_information.view') || grantsService.getIndividualGrant('customer_equipment.view');
    }),
    canViewContacts: Ember.computed('grants.userGrants', function () {
      var grantsService = this.grants;
      return grantsService.getIndividualGrant('customer_contacts.view') || grantsService.getIndividualGrant('agency_contacts.view');
    }),
    actions: {
      toggleTagView() {
        this.toggleProperty('tagViewCollapsed');
      },

      toggleSettings() {
        this.model.editDashboard.toggleConfig();
      }

    }
  });
  var _default = Controller;
  _exports.default = _default;
});