define("securitytrax-ember-ui/components/multi-step-form/forms/system/telus/general/edit-contact/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EditContact = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = (0, _emberConcurrency.task)(function* () {
    var stContacts = yield this.args.customer.get('customerContacts');
    this.stContact = stContacts.findBy('centralStationId', this.contact.id) || {};
  }), _dec11 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var formattedObjects = Ember.A();
    var contactToUpdate = this.contact;
    formattedObjects.pushObject(Ember.Object.create({
      notificationNamespace: 'adtMegaFormModal',
      model: {
        name: this.contact.person.name,
        phone1: this.stContact.phone1,
        about: this.stContact.about
      },
      filePath: 'customer-contact',
      extraValidationData: {
        adtValidation: true
      }
    }));
    yield this.args.multiStepForm.validateList.perform(formattedObjects);

    try {
      if (this.args.formMode === 'create') {
        var customerContact = this.store.createRecord('customerContact', {
          customer: this.args.customer,
          name: this.contact.person.name,
          phone1: this.stContact.phone1,
          enhancedVerification: this.contact.person.is_ecv,
          about: this.stContact.about,
          passcode: this.stContact.passcode
        });
        var savedContact = yield customerContact.save();
        var installations = (yield this.centralStationService.postData({
          model: this.args.customer,
          rawResponse: false,
          method: 'adt_canada__getInstallations',
          payload: {
            ignore_cache: true
          }
        })).installations; //Try to leverage this to refresh contacts list

        if (savedContact.get('centralStationId')) {
          contactToUpdate = installations[0].contact_collection.find(contact => {
            return contact.id === savedContact.get('centralStationId');
          });
          this.contact.person.callable_entity.phone_numbers[0] = contactToUpdate.person.callable_entity.phone_numbers[0];
        } else {
          this.stNotificationManager.addWarning(this.intl.t('pages.customers.system.telus.general.contact.sync_error'), 'modal');
        }
      } else {
        this.stContact.set('enhancedVerification', this.contact.person.is_ecv);
        this.stContact.set('name', this.contact.person.name);
        yield this.stContact.save();
      }

      var phoneNumbers = [];

      if (this.contact.person.callable_entity.phone_numbers[1].phone_num) {
        var phoneType = this.contact.person.callable_entity.phone_numbers[1].phone_type;
        Ember.set(this.contact.person.callable_entity.phone_numbers[1], 'phone_type', {
          id: phoneType.id || phoneType
        });
        phoneNumbers.push(this.contact.person.callable_entity.phone_numbers[1]);
      }

      if (this.contact.person.callable_entity.phone_numbers[2].phone_num) {
        var _phoneType = this.contact.person.callable_entity.phone_numbers[2].phone_type;
        Ember.set(this.contact.person.callable_entity.phone_numbers[2], 'phone_type', {
          id: _phoneType.id || _phoneType
        });
        phoneNumbers.push(this.contact.person.callable_entity.phone_numbers[2]);
      }

      yield this.centralStationService.postData({
        model: this.args.customer,
        rawResponse: false,
        method: 'adt_canada__updateCallListItems',
        payload: {
          'call_list_items': [{
            'id': contactToUpdate.id,
            'person': {
              'item_type': 'InstallationContact',
              'user_panel_position': this.contact.person.user_panel_position,
              'callable_entity': {
                'phone_numbers': phoneNumbers
              }
            },
            'authority_level': {
              'id': this.contact.authority_level.id || this.contact.authority_level
            }
          }]
        }
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'telusContactModal'
      });
      throw err;
    }

    buttonPromise.then(() => {
      this.args.multiStepForm.formStartedSetVal(false);
      this.args.multiStepForm.onAfterSave();
    });
  }), _dec12 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    try {
      yield this.stContact.destroyRecordWithRollbackTask.perform();
      buttonPromise.then(() => {
        this.confirmDeleteModalOpen = false;
        this.args.multiStepForm.formStartedSetVal(false);
        this.args.multiStepForm.onAfterSave();
        this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
      });
    } catch (err) {
      (false && Ember.warn('deleteContact task failed' + err, {
        id: 'telus.contacts.delete'
      }));
      throw err;
    }
  }), _dec13 = Ember._action, _dec14 = Ember._action, (_class = (_temp = class EditContact extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "centralStationService", _descriptor2, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "emergencyContacts", _descriptor5, this);

      _initializerDefineProperty(this, "grants", _descriptor6, this);

      _initializerDefineProperty(this, "contact", _descriptor7, this);

      _initializerDefineProperty(this, "stContact", _descriptor8, this);

      _initializerDefineProperty(this, "confirmDeleteModalOpen", _descriptor9, this);

      _initializerDefineProperty(this, "initFormData", _descriptor10, this);

      _initializerDefineProperty(this, "addContact", _descriptor11, this);

      _initializerDefineProperty(this, "deleteContact", _descriptor12, this);

      this.contact = this.args.contact || {
        authority_level: '00000000-0000-0000-0000-000000000000',
        person: {
          is_ecv: true,
          callable_entity: {
            phone_numbers: [{
              sequence_num: 1,
              phone_ext: '111'
            }, {
              sequence_num: 2,
              phone_ext: '111'
            }, {
              sequence_num: 3,
              phone_ext: '111'
            }]
          }
        }
      };
      this.contact.person['name'] = this.args.contact ? this.contact.person.first_name + ' ' + this.contact.person.last_name : undefined;

      for (var i = this.contact.person.callable_entity.phone_numbers.length; i < 3; i++) {
        this.contact.person.callable_entity.phone_numbers.push({
          sequence_num: (i + 1).toString(),
          phone_ext: '111'
        });
      }

      this.initFormData.perform();
    }

    get phoneTypeList() {
      return this.emergencyContacts.phoneTypeList();
    }

    setupButtons() {
      var buttons = [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.addContact.perform(buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary'
      }];

      if (this.args.formMode !== 'create' && this.grants.getIndividualGrant('customer_contacts.delete')) {
        buttons.push({
          text: this.intl.t('common.actions.delete'),
          click: () => {
            this.confirmDeleteModalOpen = true;
          },
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
        });
      }

      this.args.multiStepForm.registerStepData('step1', 'buttons', buttons);
    }

    defaultPhoneType(index) {
      this.contact.person.callable_entity.phone_numbers[index].phone_type = this.contact.person.callable_entity.phone_numbers[index].phone_type || '00000000-0000-0000-0000-000000000000';
      Ember.notifyPropertyChange(this, 'contact');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "emergencyContacts", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "contact", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "stContact", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "confirmDeleteModalOpen", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "initFormData", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "addContact", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "deleteContact", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupButtons", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "setupButtons"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "defaultPhoneType", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "defaultPhoneType"), _class.prototype)), _class));
  _exports.default = EditContact;
});