define("securitytrax-ember-ui/models/lead-status", ["exports", "securitytrax-ember-ui/models/base_models/lead-status"], function (_exports, _leadStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _leadStatus.default.extend({
    statusColor: Ember.computed('color', function () {
      return Ember.String.htmlSafe('background-color: ' + this.color);
    }),
    cssName: Ember.computed('name', function () {
      return Ember.String.dasherize(this.name);
    })
  });

  _exports.default = _default;
});