define("securitytrax-ember-ui/components/customer/dashboard/adc-best-practices-card/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var AdcBestPracticesCard = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = (0, _emberConcurrency.task)(function* () {
    var orderInfo = this.grants.getIndividualGrant('customer_order_information.view') ? yield this.args.customer.get('customerOrderInformation') : null;
    var isp = orderInfo ? yield orderInfo.get('interactiveServicesProvider') : null;

    if (isp && isp.get('isConnected')) {
      try {
        this.bestPractices = yield this.ispService.get('invokeIspActionTask').perform({
          model: this.args.customer,
          action: 'alarm_dot_com__getCustomerBestPractices',
          attributes: {}
        });
      } catch (err) {
        this.custNotFound = err.errors[0].detail === 'Customer ID ' + (yield this.args.customer.get('customerSystemInformation.interactiveServicesId')) + ' not found';
        this.genericFailure, true;
      }
    } else {
      this.adcConnectionFailed = true;
    }
  }), (_class = (_temp = class AdcBestPracticesCard extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "ispService", _descriptor2, this);

      _initializerDefineProperty(this, "grants", _descriptor3, this);

      _initializerDefineProperty(this, "adcConnectionFailed", _descriptor4, this);

      _initializerDefineProperty(this, "custNotFound", _descriptor5, this);

      _initializerDefineProperty(this, "genericFailure", _descriptor6, this);

      _initializerDefineProperty(this, "bestPractices", _descriptor7, this);

      _initializerDefineProperty(this, "bestPracticesTask", _descriptor8, this);

      if (!this.args.useDefaultData) {
        this.bestPracticesTask.perform();
      } else {
        this.bestPractices = this.defaultData;
      }
    }

    get defaultData() {
      return {
        customer_best_practices: {
          email_verified: true,
          arming_reminder: true,
          mobile_contact: true,
          advanced_device: true,
          geo_device: true,
          rule_or_schedule: true
        }
      };
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ispService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "adcConnectionFailed", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "custNotFound", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "genericFailure", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "bestPractices", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "bestPracticesTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AdcBestPracticesCard;
});