define("securitytrax-ember-ui/all-work-orders/edit-work-order/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sxLyC1J3",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"workOrderId\",\"parentRoute\"],[[35,0,[\"workOrderId\"]],[35,0,[\"parentRoute\"]]]]]],[2,\"\\n\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"model\",\"modals/sub-route-modals/edit-create-work-orders\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/all-work-orders/edit-work-order/template.hbs"
    }
  });

  _exports.default = _default;
});