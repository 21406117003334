define("securitytrax-ember-ui/administration/human-resources/permissions/group-details/grants-policies/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/route-pageview-logging", "securitytrax-ember-ui/mixins/scroll-to-top", "securitytrax-ember-ui/mixins/has-page-title", "ember-concurrency"], function (_exports, _authenticatedRouteMixin, _routePageviewLogging, _scrollToTop, _hasPageTitle, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _routePageviewLogging.default, _hasPageTitle.default, _scrollToTop.default, {
    permissionsService: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['groups.manage_permissions', 'groups.modify'], 'one');
    },

    model(args) {
      return this.setupModelTask.perform(args);
    },

    setupModelTask: (0, _emberConcurrency.task)(function* (args) {
      var group = yield this.store.findRecord('group', args.id, {
        include: 'group_resource_type_policies,resource_type_grants'
      });
      var resourceTypeList = yield this.permissionsService.get('getParsedResourceTypeList').perform(group);
      return (0, _emberConcurrency.hash)({
        allGrantsPolicies: resourceTypeList,
        resourceGrantsPolicies: resourceTypeList[args.sectionkey],
        group: group
      });
    }),
    parentController: Ember.computed(function () {
      return this.controllerFor('administration.human-resources');
    }),

    setupController(controller, model) {
      this._super(controller, model);

      var breadCrumbs = [{
        name: this.intl.t('pages.administration.human_resources.groups'),
        last: false,
        previousLink: 'administration.human-resources.user-list',
        link: 'administration.human-resources.permissions'
      }, {
        name: model.group.get('name'),
        last: false,
        previousLink: 'administration.human-resources.permissions',
        link: 'administration.human-resources.permissions.group-details',
        linkArg: model.group.get('id')
      }, {
        name: model.resourceGrantsPolicies.sectionName,
        last: true,
        previousLink: 'administration.human-resources.permissions.group-details',
        previousLinkArg: model.group.get('id'),
        link: 'administration.human-resources.permissions.group-details.grants-policies'
      }];
      var breadCrumbData = {
        title: this.intl.t('pages.administration.human_resources.title'),
        titleLink: 'administration.human-resources.user-list',
        hide: false
      };
      this.parentController.set('breadCrumbs', breadCrumbs);
      this.parentController.set('breadCrumbData', breadCrumbData);
    },

    getPageTitle() {
      return this.intl.t('pages.administration.human_resources.groups');
    },

    getRouteName() {
      return "pages.administration.nav.users-permissions";
    }

  });

  _exports.default = _default;
});