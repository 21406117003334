define("securitytrax-ember-ui/components/html/package-select/package-layouts/btn-layout/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VerVWMCp",
    "block": "{\"symbols\":[\"theButton\",\"index\"],\"statements\":[[10,\"div\"],[14,0,\"btn-group\"],[14,\"data-toggle\",\"buttons-radio\"],[12],[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[30,[36,7],[[35,6]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"button\"],[15,\"disabled\",[30,[36,1],[[32,1,[\"disabled\"]],[35,0]],null]],[15,0,[31,[\"btn \",[30,[36,4],[[30,[36,3],[[32,2],[35,2]],null],\"btn-primary\"],null]]]],[15,\"onclick\",[30,[36,5],[[32,0],\"selectPackage\",[32,2]],null]],[14,4,\"button\"],[12],[2,\"\\n            \"],[1,[32,1]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"disabled\",\"or\",\"activeIndex\",\"is-equal\",\"if\",\"action\",\"buttonList\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/package-select/package-layouts/btn-layout/template.hbs"
    }
  });

  _exports.default = _default;
});