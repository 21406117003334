define("securitytrax-ember-ui/utils/card-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var cardTypesMap = {
    mastercard: 'MasterCard',
    amex: 'American Express',
    discover: 'Discover',
    visa: 'Visa'
  };
  var _default = {
    /**
     * Returns a UI card type code for the specified API card type code
     *
     * @param  {String} code the api card type code e.g. American Express
     * @return {String} the ui code
     */
    fromAPI(code) {
      return Object.keys(cardTypesMap).find(key => cardTypesMap[key] === code);
    },

    /**
     * Returns a API card type code for the specified UI card type code
     *
     * @param  {String} code the ui card type code e.g. amex
     * @return {String} the api code
     */
    fromUI(code) {
      return Ember.getWithDefault(cardTypesMap, code, 'Other');
    }

  };
  _exports.default = _default;
});