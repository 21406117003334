define("securitytrax-ember-ui/customers/view/route", ["exports", "securitytrax-ember-ui/customers/base-route", "ember-concurrency", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _baseRoute, _emberConcurrency, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @classdesc
   * Customer view route.
   *
   * @class Route
   * @extends customers.BaseRoute
   * @memberof customers.view
   */
  var _default = _baseRoute.default.extend(_routePageviewLogging.default, {
    userService: Ember.inject.service(),
    grants: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform();
    },

    model: function () {
      var {
        customer,
        tags
      } = this._super(...arguments);

      return this.setupModelTask.perform(customer, tags);
    },
    setupModelTask: (0, _emberConcurrency.task)(function* (customer, tags) {
      var editDashboard = yield this.modelFor('customers').editDashboard;
      var userViewPermission = this.grants.getIndividualGrant('users.view');
      var users = [];
      var techs = [];

      if (this.grants.getIndividualGrant('customer_order_information.view')) {
        customer.get('customerOrderInformation').reload();
      }

      if (userViewPermission) {
        var hashedData = yield Ember.RSVP.hash({
          techs: this.userService.getUsersList('tech', customer),
          users: this.store.query('user', {
            filter: 'user_admin_setting.active = true'
          })
        });
        users = hashedData.users;
        techs = hashedData.techs.filter(tech => {
          return tech.active;
        });
      }

      return Ember.RSVP.hash({
        customer,
        tags,
        editDashboard,
        taskTypes: this.store.findAll('taskType'),
        noteTypes: this.store.findAll('noteType'),
        taskUsersList: techs,
        communicationDispositions: this.store.findAll('communicationDisposition'),
        communicationReasons: this.store.findAll('communicationReason'),
        users
      });
    })
  });

  _exports.default = _default;
});