define("securitytrax-ember-ui/customers/accounting/edit-create-payable/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3zONbNyf",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modals/sub-route-modals/edit-create-payables\",[],[[\"@customer\",\"@payableId\",\"@parentRoute\"],[[34,0,[\"customer\"]],[34,0,[\"payableId\"]],[34,0,[\"parentRoute\"]]]],null]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/customers/accounting/edit-create-payable/template.hbs"
    }
  });

  _exports.default = _default;
});