define("securitytrax-ember-ui/components/svg-icon/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SvgComponent = Ember.Component.extend({
    tagName: 'svg',
    attributeBindings: ['role', 'xmlns'],
    xmlns: 'http://www.w3.org/2000/svg',
    role: 'img',
    classNameBindings: ['defaultClassName', 'iconClassName'],
    defaultClassName: 'icon',
    iconClassName: Ember.computed('iconName', function () {
      return 'svg-icon-' + this.iconName;
    })
  });
  SvgComponent.reopenClass({
    positionalParams: ['iconName']
  });
  var _default = SvgComponent;
  _exports.default = _default;
});