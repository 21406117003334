define("securitytrax-ember-ui/demo/grants/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-concurrency", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _authenticatedRouteMixin, _emberConcurrency, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _routePageviewLogging.default, {
    grants: Ember.inject.service(),
    model: function () {
      return this.setupModelTask.perform();
    },
    setupModelTask: (0, _emberConcurrency.task)(function* () {
      return yield Ember.RSVP.hash({
        customer: this.store.findRecord('customer', 1)
      });
    })
  });

  _exports.default = _default;
});