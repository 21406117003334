define("securitytrax-ember-ui/demo/glimmer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "otc/LkUi",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],null,null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h1\"],[14,0,\"card-title\"],[12],[2,\"Showcase for glimmer component\"],[13],[2,\"\\n    \"],[8,\"double-it\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"pages/demo-page\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/demo/glimmer/template.hbs"
    }
  });

  _exports.default = _default;
});