define("securitytrax-ember-ui/validations/interactive-services-provider-config", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateObject = {
        username: {
          presence: () => {
            return this.customMessageOrDefault(data, 'interactiveServicesProviderConfig-username');
          }
        },
        password: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('interactiveServicesProviderConfig-password')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'interactiveServicesProviderConfig-password');
            }
          }
        },
        referralLeadCompanyId: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('interactiveServicesProviderConfig-referralLeadCompanyId')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'interactiveServicesProviderConfig-referralLeadCompanyId');
            }
          }
        },
        dealerWebsiteLeadCompanyId: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('interactiveServicesProviderConfig-dealerWebsiteLeadCompanyId')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'interactiveServicesProviderConfig-dealerWebsiteLeadCompanyId');
            }
          }
        },
        selfCreatedLeadCompanyId: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('interactiveServicesProviderConfig-selfCreatedLeadCompanyId')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'interactiveServicesProviderConfig-selfCreatedLeadCompanyId');
            }
          }
        },
        referAFriendLeadCompanyId: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('interactiveServicesProviderConfig-referAFriendLeadCompanyId')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'interactiveServicesProviderConfig-referAFriendLeadCompanyId');
            }
          }
        },
        serviceRequestServiceTechId: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('interactiveServicesProviderConfig-serviceRequestServiceTechId')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'interactiveServicesProviderConfig-serviceRequestServiceTechId');
            }
          }
        },
        serviceRequestUpsellTechId: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('interactiveServicesProviderConfig-serviceRequestUpsellTechId')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'interactiveServicesProviderConfig-serviceRequestUpsellTechId');
            }
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      return this.outputFormatting(validationData, 'interactiveServicesProviderConfig');
    }

  });

  _exports.default = _default;
});