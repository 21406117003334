define("securitytrax-ember-ui/components/inventory/review-spillage/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    totalExtended: Ember.computed('pendingEquipmentLineItems.@each.extended', function () {
      var total = 0;

      if (this.pendingEquipmentLineItems) {
        this.pendingEquipmentLineItems.forEach(pendingEquipmentLineItem => {
          total += pendingEquipmentLineItem.get('quantity') ? parseFloat(pendingEquipmentLineItem.get('quantity')) : 0;
        });
      }

      return total;
    })
  });

  _exports.default = _default;
});