define("securitytrax-ember-ui/components/html/icons/icon-hamburger/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LMSwunY0",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"hamburger-container \",[34,1],\" \",[34,0]]]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"bar1\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"bar2\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"bar3\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"isCollapsed\",\"class\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/icons/icon-hamburger/template.hbs"
    }
  });

  _exports.default = _default;
});