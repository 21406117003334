define("securitytrax-ember-ui/components/html/st-tags/component", ["exports", "jquery", "ember-concurrency"], function (_exports, _jquery, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @classdesc
   * Generic tags component.
   *
   * @class TagList
   * @extends Ember.Component
   * @memberof components.html
   */
  var _default = Ember.Component.extend({
    classNames: ['tag-list'],
    classNameBindings: ['collapsed'],
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    tagsService: Ember.inject.service(),
    selectedTags: null,
    isFulfilled: false,
    parentModel: null,
    tagObjectType: 'customer',
    tagObjectContext: null,
    title: '',
    showTagsList: true,
    showAddModal: true,
    collapsed: true,
    showExpandButton: false,
    tagsColumns: undefined,
    isOpen: false,
    tagsFiltered: Ember.computed('tags.[]', 'selectedTagsIds.[]', function () {
      var tags = this.tags;

      if (tags) {
        if (this.appointmentType) {
          return tags.filter(tag => {
            return (tag.get('active') || this.selectedTagIds.includes(tag.get('id'))) && (tag.objectContext === "tech_schedule_".concat(this.appointmentType.toLowerCase()) || tag.objectContext === 'none' || !tag.objectContext);
          });
        } else {
          return tags.filter(tag => {
            return tag.get('active') || this.selectedTagIds.includes(tag.get('id'));
          });
        }
      }

      return this.tags;
    }),
    selectedTagIds: Ember.computed('selectedTagsFiltered.[]', function () {
      return this.selectedTagsFiltered.map(tag => {
        return tag.get('id');
      });
    }),
    selectedTagsFiltered: Ember.computed('selectedTags.[]', function () {
      return this.selectedTags.toArray();
    }),
    initTask: (0, _emberConcurrency.task)(function* () {
      this.set('tagsColumns', [{
        propertyName: 'color',
        columnFormatter: 'color-icon'
      }, {
        propertyName: 'name'
      }]);
      this.set('tagsRecordArray', yield this.store.peekAll('tag'));
      (0, _jquery.default)(window).on('resize', Ember.run.bind(this, this.calculateExpandButton));
    }).on('init'),

    willDestroyElement() {
      this._super(...arguments);

      (0, _jquery.default)(window).off('resize');
    },

    didInsertElement() {
      this.calculateExpandButton();
    },

    calculateExpandButton() {
      var showExpandButton = (0, _jquery.default)('.tags').prop('scrollHeight') > (0, _jquery.default)('.tags-wrapper').height();
      this.set('showExpandButton', showExpandButton);

      if (!showExpandButton) {
        this.set('collapsed', true);
      }
    },

    get grantKey() {
      if (this.tagObjectType === 'invoice' && this.tagObjectContext) {
        return this.tagObjectContext.replace('invoice', 'customer') + 's';
      }

      return this.tagsService.grantKey(this.tagObjectType, this.tagObjectContext, '');
    },

    get createGrantKey() {
      return this.tagsService.grantKey(this.tagObjectType, this.tagObjectContext);
    },

    get dynamicClass() {
      var applyGrantClass = this.grants.getIndividualGrant(this.grantKey + '.apply_tag') ? 'can-apply-tag' : 'cannot-apply-tag';
      var deleteGrantClass = this.grants.getIndividualGrant(this.grantKey + '.remove_tag') ? 'can-remove-tag' : 'cannot-remove-tag';
      return 'tag-btn-wrapper' + ' ' + applyGrantClass + ' ' + deleteGrantClass;
    },

    tagsRecordArrayObserver: Ember.observer('tagsRecordArray.[]', function () {
      Ember.run.later(() => {
        try {
          this.refreshTagsTask.perform();
        } catch (err) {//
        }
      });
    }),
    showExpandButtonObserver: Ember.observer('selectedTags.[]', function () {
      this.set('collapsed', true);
      Ember.run.later(() => {
        // This needs to be done inside later. The observer appears to fire before the DOM has updated, so the heights of the <div> elements won't be accurate yet
        try {
          // This needs to be inside try/catch for tests to not break
          this.calculateExpandButton();
        } catch (err) {//
        }
      });
    }),

    /**
     * Indicates that the customer tags data is loading.
     *
     * @function
     * @returns {boolean}
     */
    isTagsDataLoading: Ember.computed.equal('isFulfilled', false),
    selectTagTask: (0, _emberConcurrency.task)(function* (clickedTag, isSelected) {
      var genericTags = this.selectedTags;
      var method = 'POST';

      try {
        var canDelete = this.grants.getIndividualGrant(this.grantKey + '.remove_tag');
        var canApply = this.grants.getIndividualGrant(this.grantKey + '.apply_tag');

        if (isSelected) {
          if (canDelete) {
            genericTags.removeObject(clickedTag);
            method = 'DELETE';
            this.notifyPropertyChange('tagsFiltered');
          } else {
            throw 'incorrect permission';
          }
        } else {
          if (canApply) {
            genericTags.pushObject(clickedTag);
          } else {
            throw 'incorrect permission';
          }
        }

        var parentModel = yield this.parentModel;
        yield parentModel.updateRelationship(method, 'tags', [clickedTag]);
      } catch (err) {
        if (isSelected) {
          genericTags.pushObject(clickedTag);
        } else {
          genericTags.removeObject(clickedTag);
          method = 'DELETE';
        }

        throw err;
      }
    }),
    deleteTagTask: (0, _emberConcurrency.task)(function* (tag) {
      var canDelete = this.grants.getIndividualGrant(this.grantKey + '.remove_tag');

      if (canDelete) {
        this.selectedTags.removeObject(tag);
        tag.set('animation', 'fade');
        yield this.parentModel.updateRelationship('DELETE', 'tags', [tag]);
        tag.set('animation', '');
      }
    }),
    createTagTask: (0, _emberConcurrency.task)(function* (name) {
      if (name.length <= 255) {
        var store = this.store;
        var createOptions = {
          name: name,
          active: true,
          objectType: this.tagObjectType
        };
        Ember.set(createOptions, 'objectContext', this.tagObjectContext);
        var newTag = store.createRecord('tag', createOptions);
        var canApply = this.grants.getIndividualGrant(this.grantKey + '.apply_tag');
        var savedTag = yield newTag.save();
        yield this.refreshTagsTask.perform();

        if (canApply) {
          yield this.parentModel.updateRelationship('POST', 'tags', [savedTag]);
          var selectedTags = yield this.selectedTags;
          selectedTags.pushObject(savedTag);
          selectedTags.reload();
        }
      } else {
        this.stNotificationManager.addError(this.intl.t('pages.administration.system.tags.new_tag_too_large'));
      }
    }),
    refreshTagsTask: (0, _emberConcurrency.task)(function* () {
      var resolvedTags = yield this.get('tagsService.getTagsByObjectType').perform(this.tagObjectType, this.tagObjectContext);
      this.set('tags', resolvedTags);
    }),

    /** @ignore */
    actions:
    /** @lends components.html.TagList# */
    {
      /**
       * Deletes the indicated tag.
       *
       * @param {Object} tag The tag to delete.
       */
      deleteTag(tag) {
        return this.deleteTagTask.perform(tag);
      },

      selectTag(clickedTag, isSelected) {
        return this.selectTagTask.unlinked().perform(clickedTag, isSelected);
      },

      toggleCollapsed() {
        this.toggleProperty('collapsed');
      },

      createTag(value) {
        this.createTagTask.unlinked().perform(value);
      }

    }
  });

  _exports.default = _default;
});