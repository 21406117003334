define("securitytrax-ember-ui/models/base_models/user-tech-setting", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    techComments: (0, _model.attr)('string', {
      'readOnly': false
    }),
    techStartAddress: (0, _model.attr)('string', {
      'readOnly': false
    }),
    techStartCity: (0, _model.attr)('string', {
      'readOnly': false
    }),
    techStartState: (0, _model.attr)('string', {
      'readOnly': false
    }),
    techStartZip: (0, _model.attr)('string', {
      'readOnly': false
    }),
    techStartLatitude: (0, _model.attr)('number', {
      'readOnly': false
    }),
    techStartLongitude: (0, _model.attr)('number', {
      'readOnly': false
    }),
    techCoverageRadius: (0, _model.attr)('number', {
      'readOnly': false
    }),
    user: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});