define("securitytrax-ember-ui/models/funding", ["exports", "securitytrax-ember-ui/models/base_models/funding", "ember-concurrency"], function (_exports, _funding, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _funding.default.extend({
    amountReceived: Ember.computed('fundingTransactions.@each.amount', function () {
      return this.amountReceivedTask.perform();
    }),
    amountReceivedTask: (0, _emberConcurrency.task)(function* () {
      try {
        var fundingTransactions = yield this.fundingTransactions;
        var total = 0;
        fundingTransactions.forEach(currItem => {
          total += parseFloat(currItem.amount);
        });
        return total;
      } catch (e) {// console.log('e', e);
      }
    }),
    diffFromExpected: Ember.computed('amountReceived', 'amountExpected', function () {
      return this.diffFromExpectedTask.perform();
    }),
    diffFromExpectedTask: (0, _emberConcurrency.task)(function* () {
      try {
        var amountReceived = yield this.amountReceived;

        if (this.amountExpected == null) {
          return 0;
        }

        return this.amountExpected - amountReceived;
      } catch (e) {// console.log('e', e);
      }
    })
  });

  _exports.default = _default;
});