define("securitytrax-ember-ui/components/customer/information/customer-installation/component", ["exports", "securitytrax-ember-ui/mixins/sortable-list-mixin", "securitytrax-ember-ui/mixins/checkbox-list-mixin", "ember-concurrency"], function (_exports, _sortableListMixin, _checkboxListMixin, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_sortableListMixin.default, _checkboxListMixin.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    customerSystemInformation: null,
    formMode: 'view',
    isCheckedOut: false,
    showEditButton: true,

    init() {
      this._super(...arguments);

      Ember.defineProperty(this, 'customerSystemInformationFormOriginalModel', Ember.computed('customer.customerSystemInformation', this.store.getFullComputedWatcherStringByModelName('customerSystemInformation'), this.store.getFullComputedWatcherStringByModelName('calendarEvent'), function () {
        return this.customerSystemInformationFormOriginalModelTask.perform();
      }));
      Ember.setProperties(this, {
        customerSystemInformationProgressBarConfig: {
          hideProgressBar: true
        }
      });
    },

    /* System Information form data */
    customerSystemInformationStep: Ember.computed(function () {
      return Ember.Object.create({
        systemInformationForm: Ember.Object.create({
          stepNameKey: 'systemInformationForm',
          title: '',
          stepComponentPath: 'multi-step-form/forms/edit-customer-system-information'
        })
      });
    }),
    customerSystemInformationFormOriginalModelTask: (0, _emberConcurrency.task)(function* () {
      this.set('customerSystemInformation', yield this.get('customer.customerSystemInformation'));
      return Ember.Object.create({
        customerSystemInformation: {
          model: this.customerSystemInformation,
          copyRelationMap: {
            installCalendarEvent: {
              doCopy: this.grants.getIndividualGrant('calendar_events.view'),
              doValidate: this.grants.getIndividualGrant('calendar_events.view'),
              doSave: this.grants.getIndividualGrant('calendar_events.modify')
            }
          }
        }
      });
    }),
    installCalendarEvents: Ember.computed('customer.id', function () {
      return this.store.query('calendarEvent', {
        filter: '(customer = ' + this.customer.get('id') + ' and calendar_category = \'tech_schedule\')',
        include: 'calendar_user,customer,calendar_event_type',
        sort: 'start_time, duration'
      });
    }),
    actions: {
      setFormMode(formMode) {
        Ember.set(this, 'formMode', formMode);
      }

    }
  });

  _exports.default = _default;
});