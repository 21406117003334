define("securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/adc/adc-base", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/switchable-form-step", "securitytrax-ember-ui/mixins/forms/adc", "securitytrax-ember-ui/utils/obj-helpers", "lodash"], function (_exports, _emberConcurrency, _switchableFormStep, _adc, _objHelpers, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, _adc.default, {
    intl: Ember.inject.service(),
    ispService: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    isp: Ember.inject.service('events/isp'),
    attributes: undefined,
    addOns: undefined,
    confirmAdcActionFormStarted: false,
    swapModemFormStarted: false,
    applyPanelTemplateFormStarted: false,
    enableDisableLockoutFormStarted: false,
    lockoutEnabled: false,
    hiddenProgressBarSteps: Ember.computed('customerStatus', function () {
      return this.customerStatus === 'commitment' ? ['equipment', 'monitoringSettings', 'loginInformation', 'history'] : ['activateCommitment'];
    }),
    disabledProgressBarSteps: Ember.computed(function () {
      return [];
    }),

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        attributes: {}
      });
    },

    confirmAdcActionFormSteps: Ember.computed(function () {
      return Ember.Object.create({
        confirmAdcActionSingleStep: Ember.Object.create({
          stepNameKey: 'confirmAdcActionSingleStep',
          title: 'appointment title',
          stepComponentPath: 'multi-step-form/forms/customer-interactive-services-provider-information/adc/re-usable-forms/confirm-adc-action'
        })
      });
    }),
    swapModemFormSteps: Ember.computed(function () {
      return Ember.Object.create({
        swapModemSingleStep: Ember.Object.create({
          stepNameKey: 'swapModemSingleStep',
          title: this.intl.t('pages.customers.system.interactiveServicesProvider.swapModem.title'),
          stepComponentPath: 'multi-step-form/forms/customer-interactive-services-provider-information/adc/swap-modem'
        })
      });
    }),
    applyPanelTemplateFormSteps: Ember.computed(function () {
      return Ember.Object.create({
        applyPanelTemplateSingleStep: Ember.Object.create({
          stepNameKey: 'applyPanelTemplateSingleStep',
          title: this.intl.t('pages.customers.system.interactiveServicesProvider.applyPanelTemplate.title'),
          stepComponentPath: 'multi-step-form/forms/customer-interactive-services-provider-information/adc/apply-panel-template'
        })
      });
    }),
    enableDisableLockoutFormSteps: Ember.computed(function () {
      return Ember.Object.create({
        enableDisableLockoutStep: Ember.Object.create({
          stepNameKey: 'enableDisableLockoutStep',
          stepComponentPath: 'multi-step-form/forms/customer-interactive-services-provider-information/adc/enable-disable-lockout'
        })
      });
    }),
    enableDisableLockoutTitle: Ember.computed('lockoutEnabled', function () {
      return this.lockoutEnabled ? this.intl.t('pages.customers.system.interactiveServicesProvider.enableDisableLockout.disableLockout.title') : this.intl.t('pages.customers.system.interactiveServicesProvider.enableDisableLockout.enableLockout.title');
    }),
    adcPanelTypes: Ember.computed('customer.customerOrderInformation.interactiveServicesProvider.id', function () {
      return this.adcPanelTypesTask.perform();
    }),
    adcPanelTypesTask: (0, _emberConcurrency.task)(function* () {
      return yield this.ispService.get('getIspListTask').perform({
        ispId: this.get('customer.customerOrderInformation.interactiveServicesProvider.id'),
        ispType: 'adc',
        list: 'customer_panel_type_options'
      });
    }),
    centralStationForwardingOptions: Ember.computed('customer.customerOrderInformation.interactiveServicesProvider.id', function () {
      return this.centralStationForwardingOptionsTask.perform();
    }),
    centralStationForwardingOptionsTask: (0, _emberConcurrency.task)(function* () {
      return yield this.ispService.get('getIspListTask').perform({
        ispId: this.get('customer.customerOrderInformation.interactiveServicesProvider.id'),
        ispType: 'adc',
        list: 'customer_central_station_forwarding_options'
      });
    }),
    adcPackages: Ember.computed('customer.customerOrderInformation.interactiveServicesProvider.id', 'customerAccountType', function () {
      return this.adcPackagesTask.perform();
    }),
    //Change this too?
    adcPackagesTask: (0, _emberConcurrency.task)(function* () {
      var packages = yield this.ispService.get('invokeIspActionTask').perform({
        attributes: {
          account_type: this.customerAccountType
        },
        action: 'alarm_dot_com__getCustomerPackageOptions',
        model: this.customer
      });
      return packages ? packages.customer_package_options : [];
    }),
    packageTemplateOptions: Ember.computed(function () {
      return this.packageTemplateOptionsTask.perform();
    }),
    packageTemplateOptionsTask: (0, _emberConcurrency.task)(function* () {
      var templates = yield this.ispService.get('invokeIspActionTask').perform({
        action: 'alarm_dot_com__getPackageTemplates',
        model: this.customer,
        attributes: {}
      });
      return templates ? templates.package_templates : [];
    }),
    ignoreLowCoverageOptions: Ember.computed('customer.customerOrderInformation.interactiveServicesProvider.id', function () {
      return this.ignoreLowCoverageOptionsTask.perform();
    }),
    ignoreLowCoverageOptionsTask: (0, _emberConcurrency.task)(function* () {
      return yield this.ispService.get('getIspListTask').perform({
        ispId: this.get('customer.customerOrderInformation.interactiveServicesProvider.id'),
        ispType: 'adc',
        list: 'customer_ignore_low_coverage_errors_options'
      });
    }),
    phoneLinePresentOptions: Ember.computed('customer.customerOrderInformation.interactiveServicesProvider.id', function () {
      return this.phoneLinePresentOptionsTask.perform();
    }),
    phoneLinePresentOptionsTask: (0, _emberConcurrency.task)(function* () {
      return yield this.ispService.get('getIspListTask').perform({
        ispId: this.get('customer.customerOrderInformation.interactiveServicesProvider.id'),
        ispType: 'adc',
        list: 'customer_phone_line_present_options'
      });
    }),
    adcPropertyTypes: Ember.computed('customer.customerOrderInformation.interactiveServicesProvider.id', function () {
      return this.adcPropertyTypesTask.perform();
    }),
    adcPropertyTypesTask: (0, _emberConcurrency.task)(function* () {
      return yield this.ispService.get('getIspListTask').perform({
        ispId: this.get('customer.customerOrderInformation.interactiveServicesProvider.id'),
        ispType: 'adc',
        list: 'customer_property_type_options'
      });
    }),
    adcPanelTemplates: Ember.computed('customer.customerOrderInformation.interactiveServicesProvider.id', function () {
      return this.adcPanelTemplatesTask.perform();
    }),
    adcPanelTemplatesTask: (0, _emberConcurrency.task)(function* () {
      try {
        var templates = [];
        var returnData = yield this.ispService.get('invokeIspActionTask').perform({
          action: 'alarm_dot_com__getPanelTemplateIds',
          model: yield this.get('customer.customerOrderInformation.interactiveServicesProvider'),
          attributes: {}
        });

        if (returnData && returnData.panel_template_ids) {
          returnData.panel_template_ids.forEach(template => {
            templates.push({
              name: template.description,
              value: template.template_id
            });
          });
        }

        return templates;
      } catch (err) {
        this.set('hasPanelTemplates', false);
      }
    }),
    setLockoutEnabledTask: (0, _emberConcurrency.task)(function* () {
      try {
        var response = yield this.ispService.get('invokeIspActionTask').perform({
          action: 'alarm_dot_com__getInteractiveLockoutStatus',
          model: this.customer,
          attributes: {}
        });
        Ember.set(this, 'lockoutEnabled', response ? response.is_interactive_lockout_enabled : false);
        Ember.notifyPropertyChange(this, 'lockoutEnabled');
      } catch (err) {// do nothing
      }
    }),
    updateAccount: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise, action, attributes = {}, model, noValidate = [], file, notificationNamespace = 'modal') {
      var formattedObjects = Ember.A();
      formattedObjects.pushObject(Ember.Object.create({
        model,
        extraValidationData: {
          noValidate: noValidate
        },
        filePath: file
      }));
      yield multiStepForm.validateList.perform(formattedObjects);

      try {
        yield this.ispService.get('invokeIspActionTask').perform({
          attributes: attributes,
          action: action,
          model: this.customer
        });
        this.isp.trigger('refresh-adc-customer-information');
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace
        });
        throw new Error(err);
      }
    }),
    actions: {
      updateDropdownAttribute(property, value) {
        if (value && value.value) {
          this.attributes[property] = value.value === 'true' || value.value === 'false' ? value.value === 'true' : value.value;
        } else {
          delete this.attributes[property];
        }
      },

      updateMaskedDropdownAttribute(property, value) {
        this.attributes[property] = value ? 'YES' : 'NO';
      },

      updateAttribute(property, event) {
        if (_lodash.default.get(event, 'target.value', false)) {
          this.attributes[property] = event.target.value;
        } else {
          delete this.attributes[property];
        }
      }

    }
  });

  _exports.default = _default;
});