define("securitytrax-ember-ui/reports/note-ticket-reports/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z2ODIlyv",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"model\"],[[35,0]]]]]],\"hasEval\":false,\"upvars\":[\"model\",\"pages/reports/note-reports\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/reports/note-ticket-reports/template.hbs"
    }
  });

  _exports.default = _default;
});