define("securitytrax-ember-ui/components/pages/accounting/payments/payments/component", ["exports", "securitytrax-ember-ui/mixins/table-column-sort", "ember-concurrency"], function (_exports, _tableColumnSort, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableColumnSort.default, {
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    saleTypes: Ember.inject.service(),
    enhancedFeatures: Ember.inject.service(),
    invoicePaymentsEvent: Ember.inject.service('events/invoice-payments-events'),

    init() {
      this._super(...arguments);

      this.invoicePaymentsEvent.on('reload-list', this, '_callback');
    },

    willDestroy() {
      this.invoicePaymentsEvent.off('reload-list', this, '_callback');
    },

    _callback() {
      Ember.notifyPropertyChange(this, 'customerPayments');
    },

    tagName: '',
    customerPayments: Ember.computed('model.customer.customerPayments', function () {
      return this.getCustomerPayments.perform();
    }),
    getCustomerPayments: (0, _emberConcurrency.task)(function* () {
      var customerPayments = yield this.model.customer.get('customerPayments').toArray().reverse();
      var promiseArray = [];
      customerPayments.forEach(payment => {
        promiseArray.push(payment.get('customerBilling').then(billing => billing ? billing.get('title') : null));
      });
      var resolvedPromiseArray = yield (0, _emberConcurrency.all)(promiseArray);
      customerPayments.forEach((payment, index) => {
        payment.set('_resolvedTitle', resolvedPromiseArray[index] ? resolvedPromiseArray[index] : payment.customerBillingOrPaymentTypeLabel);
      });
      return customerPayments;
    }),
    actions: {
      transitionToPaymentSubroute(path) {
        this.router.transitionTo('customers.accounting.payments-payments.edit-create-payment', path);
      }

    }
  });

  _exports.default = _default;
});