define("securitytrax-ember-ui/components/calendar-glimmer/calendar-map/layer-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QfIXervx",
    "block": "{\"symbols\":[\"layer\",\"mappedEvent\",\"index\",\"@user\",\"@userIndex\"],\"statements\":[[8,\"layer-group\",[],[[\"@name\",\"@baselayer\",\"@default\"],[[30,[36,1],[\"<span style=\\\"background-color:\",[32,4,[\"color\"]],\"; height:10px;width:10px;display:inline-block; margin-right: 5px;\\\"></span>\",[32,4,[\"user\",\"flname\"]],\" (\",[32,4,[\"user\",\"id\"]],\")\"],null],false,true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \\n\"],[6,[37,2],[[32,4,[\"polylineLocations\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"polyline-layer\",[],[[\"@locations\",\"@color\",\"@weight\",\"@className\",\"@zIndexOffset\",\"@opacity\",\"@onClick\"],[[32,4,[\"polylineLocations\"]],[32,4,[\"color\"]],\"8\",[30,[36,1],[\"calendar__polyline polyline-\",[32,5]],null],[32,0,[\"zIndex\"]],[32,0,[\"opacity\"]],[30,[36,0],[[32,0],\"polylineMouseover\"],null]]],null],[2,\"\\n            \\n            \"],[8,\"polyline-layer\",[],[[\"@locations\",\"@color\",\"@dashArray\",\"@weight\",\"@zIndexOffset\"],[[32,4,[\"polylineLocations\"]],\"#ffffff\",\"3\",\"2\",[32,0,[\"zIndex\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,4,[\"mappedEvents\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"calendar-glimmer/calendar-map/icon\",[],[[\"@layers\",\"@currGeoPoint\",\"@calendarEvent\",\"@avatar\",\"@user\",\"@color\",\"@index\",\"@onMouseoverAction\",\"@onMouseoutAction\",\"@zIndexOffset\",\"@opacity\"],[[32,1],[32,2,[\"geoPoint\"]],[32,2,[\"calendarEvent\"]],[32,4,[\"user\",\"avatar\"]],[32,4,[\"user\"]],[32,4,[\"color\"]],[32,3],[30,[36,0],[[32,0],\"polylineMouseover\"],null],[30,[36,0],[[32,0],\"polylineMouseout\"],null],[32,0,[\"zIndex\"]],[32,0,[\"opacity\"]]]],null],[2,\"\\n\"]],\"parameters\":[2,3]}]]]],\"parameters\":[1]}]]],[2,\"  \"]],\"hasEval\":false,\"upvars\":[\"action\",\"concat\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/calendar-glimmer/calendar-map/layer-group/template.hbs"
    }
  });

  _exports.default = _default;
});