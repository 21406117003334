define("securitytrax-ember-ui/models/global-settings-recurring-invoice-setting", ["exports", "securitytrax-ember-ui/models/base_models/global-settings-recurring-invoice-setting"], function (_exports, _globalSettingsRecurringInvoiceSetting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _globalSettingsRecurringInvoiceSetting.default.extend({});

  _exports.default = _default;
});