define("securitytrax-ember-ui/models/base_models/base/model", ["exports", "@ember-data/model", "moment", "securitytrax-ember-ui/mixins/copyable", "lodash", "securitytrax-ember-ui/helpers/de-snake"], function (_exports, _model, _moment, _copyable, _lodash, _deSnake) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Base model.
   *
   * @class BaseModel
   * @extends DS.Model
   * @memberof models
   */
  var _default = _model.default.extend(_copyable.default,
  /** @lends models.BaseModel# */
  {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    attributeEnums: (0, _model.attr)(),
    responseMeta: (0, _model.attr)(),
    requestMeta: (0, _model.attr)(),
    globalRequestMeta: (0, _model.attr)(),

    // set: function (keyName, value) {
    //     keyName, value;
    //     //console.log(this.modelName, keyName, value);
    //     /*
    //     const eventName = '_set' + keyName;
    //     const model = this._internalModel._record;
    //     //only trigger if listeners exist
    //     if (this.has(eventName)) {
    //         console.log('setty set set');
    //         this.trigger('_set' + keyName, value);
    //     }
    //     */
    //     return this._super(...arguments);
    // },
    validate() {// just a dummy function so all models have a "validate" function if non is defined
    },

    getDate: function (date, format, error = '') {
      var date_checked = typeof date === 'undefined' ? 'NO DATE' : date; //If it is a date, we need set it to the correct format. Moment.js requires the us to pass the date format and by setting the date value this way, we can get it.

      if (date_checked !== 'NO DATE') {
        try {
          date_checked = new Date(date).toISOString();
        } catch (err) {
          date_checked = 'NO DATE';
        }
      }

      return (0, _moment.default)(date_checked, 'YYYY-MM-DDTHH:mm:ss.sssZ').isValid() ? (0, _moment.default)(date_checked, 'YYYY-MM-DDTHH:mm:ss.sssZ').tz('America/Denver').format(format) : error;
    },

    updateRelationship(action, relationship, relatedData, relationType = null) {
      var adapter = this.store.adapterFor(this.constructor.modelName);
      return adapter.updateRelationship(action, this, relationship, relatedData, relationType || relationship);
    },

    /**
     * only here as a helper, note that the response promise
     * will NOT return the same instance (js) as `this`
     */
    hardReload() {
      return this.store.hardReload(this);
    },

    save() {
      var {
        constructor
      } = this;
      var newEmbeddedModels = [];
      var updatedEmbeddedModels = []; // get stEmbedded records before save
      // TODO: make this recursive
      // TODO: the list of members in hasMany scenario seems to be old + new, need to properly cope

      constructor.eachRelationship((key, relationship) => {
        if (relationship.options.stEmbedded === true) {
          if (relationship.kind === 'belongsTo') {
            var relationshipReference = this.belongsTo(key);
            var {
              inverseRecordData = {}
            } = relationshipReference.belongsToRelationship;

            if (!('inverseRecordData' in relationshipReference.belongsToRelationship)) {
              throw new Error('missing inverseRecordData property');
            }

            if (!inverseRecordData) {
              return;
            }

            if (!('_isNew' in inverseRecordData)) {
              throw new Error('missing _isNew property');
            }

            if (inverseRecordData._isNew) {
              newEmbeddedModels.push({
                recordData: inverseRecordData,
                relationship: relationship,
                reference: relationshipReference
              });
            } else {
              updatedEmbeddedModels.push({
                recordData: inverseRecordData,
                relationship: relationship,
                reference: relationshipReference
              });
            }
          } else {
            var _relationshipReference = this.hasMany(key),
                rel = _relationshipReference.hasManyRelationship,
                {
              members: {
                list
              }
            } = rel;

            for (var i = 0; i < list.length; i++) {
              if (!list[i]) {
                return;
              }

              if (!('_isNew' in list[i])) {
                throw new Error('missing _isNew property');
              }

              if (list[i]._isNew) {
                newEmbeddedModels.push({
                  recordData: list[i],
                  relationship: relationship,
                  reference: _relationshipReference
                });
              } else {
                updatedEmbeddedModels.push({
                  recordData: list[i],
                  relationship: relationship,
                  reference: _relationshipReference
                });
              }
            }
          }
        }
      });

      var promise = this._super(...arguments); // clean up stEmbedded records after save


      var embeddedCleanupError = false;
      promise.then(() => {
        embeddedCleanupError = true; // deal with new models (dangling)

        for (var i = 0; i < newEmbeddedModels.length; i++) {
          var {
            recordData,
            relationship
          } = newEmbeddedModels[i];

          if (recordData._isNew) {
            try {
              if (!('clientId' in recordData)) {
                throw new Error('missing clientId property');
              }

              var imodel = this.store.findByClientId(relationship.meta.type, recordData.clientId);

              if (!imodel) {
                throw new Error('could not find model by clientId 1');
              }

              imodel.rollbackAttributes();
              imodel.unloadRecord();
            } catch (e) {
              throw e;
            }
          }
        } // deal with updated models
        // for (let i = 0; i < updatedEmbeddedModels.length; i++) {
        //     try {
        //         const { recordData } = updatedEmbeddedModels[i];
        //         const imodel = this.store.peekRecord(
        //             camelize(recordData.modelName),
        //             recordData.id
        //         );
        //         if (!imodel) {
        //             console.log('recordData', updatedEmbeddedModels, camelize(recordData.modelName), recordData.id, imodel);
        //             throw new Error('could not find model by id 2');
        //         }
        //         try {
        //             imodel.forceCleanState();
        //         } catch (e) {
        //             throw e;
        //         }
        //     } catch (e) {
        //         throw e;
        //     }
        // }
        // TODO: this is super hacky and logic will likely need to be buried
        // much deeper if it's even possible


        if (newEmbeddedModels.length > 0) {
          this.reload();
        }
      }).catch(e => {
        // ignore errors *NOT* related to the code in the .then()
        // ie: 403s etc
        if (embeddedCleanupError) {
          throw e;
        }
      });
      return promise;
    },

    /**
     * Invoke an action on the model
     *
     * options keys include:
     * - verb: http verb as a string GET, POST, etc
     * - data: request data
     * - processResponse: if true, the response data will be 'pushed' into the store
     * - processResponseRecordArray: if true, produce a DS.RecordArray and set the recordArray property of the result
     * - reloadModel: if true the model will be reloaded
     *
     * @param {*} action
     * @param {*} options
     */
    invokeAction(action, options = {}) {
      var adapter = this.store.adapterFor(this.constructor.modelName);
      return adapter._invokeModelAction(this, action, options);
    },

    enumLists() {
      var attributeEnums = {};
      var attributes = Ember.get(this.constructor, 'attributes');

      for (var [index, currAttribute] of attributes.entries()) {
        if (index === 'responseMeta' || index === 'requestMeta' || index === 'globalRequestMeta' || index === 'status') {
          continue;
        }

        var enumValues = _lodash.default.get(currAttribute, 'options.enumValues', false);

        if (!enumValues) {
          continue;
        }

        attributeEnums[index + 'Options'] = enumValues.map(currValue => {
          return {
            id: currValue,
            name: (0, _deSnake.deSnake)(currValue).replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3')
          };
        });
      }

      return attributeEnums;
    },

    /**
     * Forces the model to a clean state *in it's current state* (ie: without doing a rollback)
     *
     * https://stackoverflow.com/questions/13342250/how-to-manually-set-an-object-state-to-clean-saved-using-ember-data/23344215
     * https://discuss.emberjs.com/t/how-to-mark-dirty-records-as-clean-after-custom-server-request/8835
     * http://www.kaspertidemann.com/how-to-manually-change-the-state-of-a-dirtied-ember-data-object/
     *
     */
    forceCleanState() {
      // being very defensive in case ember-data changes internal structure
      if (!('_internalModel' in this)) {
        throw new Error('missing _internalModel property');
      }

      if (!('_recordData' in this._internalModel)) {
        throw new Error('missing _recordData property');
      }

      if (!('_attributes' in this._internalModel._recordData)) {
        throw new Error('missing _attributes property');
      } // Clear changed attributes list


      this._internalModel._recordData._attributes = {}; // Trigger transition to 'loaded.saved' state

      this.send('pushedData');
    },

    /**
     * Produces data that can be sent to store.push(normalizedPayload)
     * NOTE: it goes through the serializer and thus uses dirty logic to only
     * serialize attributes that have changed etc
     *
     * @param {*} options
     */
    getNormalizedPayload(options = {}) {
      if (!('_internalModel' in this)) {
        throw new Error('missing _internalModel property');
      }

      if (!('includeId' in options)) {
        options.includeId = true;
      }

      var serialized = this.serialize(options);
      var serializer = this.store.serializerFor(this._internalModel.modelName);

      var normalizedPayload = serializer._normalizeDocumentHelper(serialized);

      return normalizedPayload;
    }

  });

  _exports.default = _default;
});