define("securitytrax-ember-ui/components/inventory/purchase-orders/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    inventoryService: Ember.inject.service(),
    stFiltersService: Ember.inject.service(),
    tagsService: Ember.inject.service(),
    screen: Ember.inject.service(),
    limit: 50,
    page: 1,
    purchaseOrderListCount: 0,
    purchaseOrderStatus: undefined,
    purchaseOrderReceivedStatus: undefined,
    purchaseOrderVendor: undefined,
    createdDateFrom: undefined,
    createdDateTo: undefined,
    purchaseOrderRecipient: undefined,
    purchaseOrderId: undefined,
    purchaseOrderFormStarted: false,
    purchaseOrderFormMode: 'view',
    purchaseOrder: null,
    showNotification: true,
    tagFilter: undefined,

    init() {
      this._super(...arguments);

      Ember.set(this, 'tagFilter', this.tagsService.tagFilter());
      this.initTask.perform();
    },

    initTask: (0, _emberConcurrency.task)(function* () {
      Ember.set(this, 'tagsOptions', yield this.tagsService.tagsOptionsTask.perform('pending_equipment'));
    }),
    posNotice: Ember.computed(function () {
      return this.intl.t('pages.administration.company_settings.inventory.purchase_orders.description', {
        link: 'https://answers.securitytrax.com/Support/Company_and_Admin/Inventory_Management/Purchase_Orders_(POs)'
      });
    }),
    receivedStatusOptions: Ember.computed(function () {
      var intl = this.intl;
      return [{
        value: 'none',
        name: intl.t('pages.users.my_inventory.purchase_orders.status.none')
      }, {
        value: 'partially',
        name: intl.t('pages.users.my_inventory.purchase_orders.status.partially')
      }, {
        value: 'full',
        name: intl.t('pages.users.my_inventory.purchase_orders.status.in_full')
      }];
    }),
    statusOptions: Ember.computed(function () {
      var intl = this.intl;
      return [{
        value: 'open',
        name: intl.t('pages.users.my_inventory.purchase_orders.open')
      }, {
        value: 'closed',
        name: intl.t('pages.users.my_inventory.purchase_orders.closed')
      }];
    }),
    recipientOptions: Ember.computed(function () {
      return this.inventoryService.get('getInventoryRecipients').perform();
    }),
    vendors: Ember.computed(function () {
      return this.getVendorsTask.perform();
    }),
    getVendorsTask: (0, _emberConcurrency.task)(function* () {
      var vendors = yield this.store.findAll('equipmentVendor', {
        include: 'equipment_vendor_config'
      });
      return vendors.sortBy('name');
    }),
    purchaseOrders: Ember.computed(function () {
      return this.getPurchaseOrdersTask.perform();
    }),
    getPurchaseOrdersTask: (0, _emberConcurrency.task)(function* () {
      var defaultFilter = this.user ? ['user = ' + this.get('user.id')] : [];
      var newFilters = yield this.stFiltersService.getFiltersByGroupKey(this.storedFilterKey, defaultFilter);
      var queryParams = {
        filter: newFilters,
        sort: '-id',
        include: 'equipment_vendor,pending_equipment_line_items.equipment.equipment_type,user,location,created_by_user,tags',
        limit: this.limit,
        page: this.page
      };

      if (this.page == 1) {
        queryParams['count'] = 1;
      }

      var pos = yield this.store.query('equipmentPurchaseOrder', queryParams);

      if (this.page == 1) {
        this.set('purchaseOrderListCount', pos.meta.record_count);
      }

      return pos;
    }).restartable().maxConcurrency(1),
    purchaseOrderForm: Ember.computed('purchaseOrderFormMode', 'purchaseOrder', function () {
      var translationKey = this.purchaseOrderFormMode === 'create' ? 'pages.users.my_inventory.purchase_orders.create_title' : 'pages.users.my_inventory.purchase_orders.edit_title';
      var titleData = {
        id: this.get('purchaseOrder.id'),
        userName: this.get('purchaseOrder.user.id') ? this.get('purchaseOrder.user.flname') : this.get('purchaseOrder.location.name'),
        vendorName: this.get('purchaseOrder.equipmentVendor.name')
      };
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.intl.t(translationKey, titleData),
          stepComponentPath: 'multi-step-form/forms/administration/users/my-inventory/purchase-orders/' + (this.purchaseOrderFormMode === 'create' ? 'create' : 'edit') + '-purchase-order'
        })
      });
    }),
    formOriginalModels: Ember.computed('purchaseOrder', 'grants.userGrants', function () {
      return Ember.Object.create({
        equipmentPurchaseOrder: {
          model: this.purchaseOrder,
          copyRelationMap: {
            user: {
              doCopy: true,
              doValidate: false,
              doSave: false
            },
            location: {
              readOnly: true
            },
            createdByUser: {
              doCopy: true,
              doValidate: false,
              doSave: false
            },
            equipmentVendor: {
              relationships: {
                equipmentVendorConfig: {
                  doCopy: this.grants.getIndividualGrant('equipment_vendors.manage_connector'),
                  doSync: this.grants.getIndividualGrant('equipment_vendors.manage_connector'),
                  doValidate: false,
                  doSave: false
                }
              }
            },
            pendingEquipmentLineItems: {
              doValidate: false,
              relationships: {
                equipmentKit: {
                  readOnly: true
                },
                equipment: {
                  readOnly: true,
                  relationships: {
                    equipmentType: {
                      readOnly: true
                    }
                  }
                }
              }
            }
          }
        }
      });
    }),
    actions: {
      //action sent to pagination component
      getPurchaseOrders() {
        Ember.notifyPropertyChange(this, 'purchaseOrders');
        this.getPurchaseOrdersTask.perform();
      },

      clearFilter() {
        Ember.setProperties(this, {
          purchaseOrderStatus: undefined,
          purchaseOrderReceivedStatus: undefined,
          purchaseOrderVendor: undefined,
          createdDateFrom: undefined,
          createdDateTo: undefined,
          purchaseOrdersLoaded: false,
          purchaseOrderRecipient: undefined,
          purchaseOrderId: undefined,
          tagFilter: this.tagsService.tagFilter()
        });
        Ember.notifyPropertyChange(this, 'purchaseOrders');
      },

      filterPurchaseOrders() {
        this.set('page', 1);
        Ember.notifyPropertyChange(this, 'purchaseOrders');
      },

      editAction(row) {
        this.set('purchaseOrderFormMode', this.grants.getIndividualGrant('equipment_purchase_orders.modify') ? 'edit' : 'view');
        this.set('purchaseOrder', row);
        this.set('purchaseOrderFormStarted', true);
      },

      refreshModel() {
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        Ember.notifyPropertyChange(this, 'purchaseOrders');
      }

    }
  });

  _exports.default = _default;
});