define("securitytrax-ember-ui/classes/edit-dashboard", ["exports", "ember-concurrency", "lodash", "securitytrax-ember-ui/config/environment"], function (_exports, _emberConcurrency, _lodash, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EditDashboard = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = (0, _emberConcurrency.task)(function* () {
    var addedCards = [];
    var cards = [];
    var sections = yield this.sectionsTask.perform();
    Object.keys(sections).forEach(section => {
      cards = cards.concat(sections[section].cards.map(card => {
        return card.card;
      }));
    });
    Object.keys(this.availableCards).forEach(card => {
      if (cards.includes(card)) {
        addedCards.push(card);
      }
    });
    return addedCards;
  }), _dec5 = (0, _emberConcurrency.task)(function* () {
    if (this.dashboardCards) {
      if (this.dashboardCards.objectAt(0)) {
        var cardData = yield this.dashboardCards.objectAt(0).cardData;
        var page = cardData.pages[1];
        return page.sections;
      }
    }

    return {};
  }), _dec6 = (0, _emberConcurrency.task)(function* (calledFromDashboard) {
    var systemInfo = calledFromDashboard.grants.getIndividualGrant('customer_system_information.view') ? yield this.customer.get('customerSystemInformation') : null;
    var isp = systemInfo ? yield systemInfo.get('interactiveServicesId') : null;
    var orderInfo = calledFromDashboard.grants.getIndividualGrant('customer_order_information.view') ? yield this.customer.get('customerOrderInformation') : null;
    var ispInfo = orderInfo ? yield orderInfo.get('interactiveServicesProvider') : null;
    return isp && ispInfo && ispInfo.get('isConnected');
  }), _dec7 = (0, _emberConcurrency.task)(function* (calledFromDashboard) {
    var orderInfo = calledFromDashboard.grants.getIndividualGrant('customer_order_information.view') && this.customer ? yield this.customer.get('customerOrderInformation') : null;
    var ispInfo = orderInfo ? yield orderInfo.get('interactiveServicesProvider') : null;
    var ispTypeInfo = ispInfo ? yield ispInfo.get('interactiveServicesProviderType') : null;
    return ispTypeInfo ? ispTypeInfo.id : null;
  }), _dec8 = (0, _emberConcurrency.task)(function* (calledFromDashboard, args = {
    roles: []
  }) {
    var cards = [];
    var user = yield calledFromDashboard.sessionData.getUser();
    var hashedData = yield (0, _emberConcurrency.hash)({
      emberCards: user.get('emberCards'),
      roles: args.roles,
      ispConnected: this.customer ? this.ispConnectedTask.perform(calledFromDashboard) : false,
      ispProvTypeId: this.customer ? this.ispProviderTypeIdTask.perform(calledFromDashboard) : null,
      showRiskScore: this.customer ? this.customer.showRiskScoreTask.perform() : false
    });
    var canvasCards = hashedData.emberCards.filter(card => {
      return card.get('location') == this.dashboardLocation;
    });
    var cardData = Ember.Object.create({
      pages: {
        1: {
          sections: {
            sectionless: {
              order: 0,
              cards: this.defaultCards.filter(card => {
                if (card.config.grant) {
                  return true;
                }

                var cardFocusOptions = Object.entries(this.availableCards[card.card].config.card_focus_options);

                for (var i = 0; i < cardFocusOptions.length; i++) {
                  if (calledFromDashboard.grants.getIndividualGrant(cardFocusOptions[i][1])) {
                    card.config.card_focus = cardFocusOptions[i][0];
                    card.config.grant = cardFocusOptions[i][1];
                    return true;
                  }
                }

                return false;
              })
            }
          }
        }
      }
    });

    if (canvasCards.length === 0) {
      var newCard = calledFromDashboard.store.createRecord('emberCard', {
        location: this.dashboardLocation,
        cardData: cardData,
        user: user
      });
      var savedCard = yield newCard.save();
      Ember.set(this, 'dashboardCards', [savedCard]);
      canvasCards = this.dashboardCards;
      calledFromDashboard.homeDashboard.trigger('dashboard-config-update', this.dashboardLocation);
    }

    canvasCards.objectAt(0).get('cardData').pages[1].sections.sectionless.cards.forEach(cardData => {
      try {
        this._updateConfigDiscrepancies(cardData, ['link', 'grant', 'role', 'roleCheckType', 'cardLocationOverride', 'card_focus_options', 'hideISPNotConnected', 'titleKey']);

        var hasGrants = this._checkCardGrants(cardData, calledFromDashboard);

        var hasRole = this._checkCardRoles(cardData.config, hashedData.roles);

        var ADCCheck = this._checkCardProviderType(cardData, hashedData.ispProvTypeId);

        if (ADCCheck && hasGrants && hasRole && !(cardData.config.hideISPNotConnected && !hashedData.ispConnected) && !(cardData.card === 'risk-score-card' && !hashedData.showRiskScore)) {
          cards.push({
            'order': cardData.order,
            'card': cardData.card,
            'display': true,
            'config': cardData.config,
            'dateRange': cardData.config.date_range ? cardData.config.date_range.short_name || cardData.config.date_range.name : null
          });
        }
      } catch (err) {
        (false && Ember.warn(err, {
          id: 'edit-dashboard'
        }));
      }
    });
    return cards.sortBy('order');
  }), _dec9 = (0, _emberConcurrency.task)(function* (calledFromDashboard, card, buttonPromise) {
    var user = yield calledFromDashboard.sessionData.getUser(); // PERF TODO: Includes somehow for emberCards?

    var emberCards = yield user.get('emberCards');
    var canvasCards = emberCards.filter(card => {
      return card.get('location') == this.dashboardLocation;
    });
    var cardData = canvasCards.objectAt(0).get('cardData');
    Ember.set(canvasCards[0].cardData.pages[1].sections['sectionless'].cards[card.order], 'config', card.config);
    canvasCards[0].set('cardData', cardData);
    yield canvasCards[0].save();
    Ember.set(this.dashboardCards[0].cardData.pages[1].sections['sectionless'].cards[card.order], 'config', card.config);
    buttonPromise.then(() => {
      Ember.notifyPropertyChange(calledFromDashboard, 'homeCards');
      calledFromDashboard.homeDashboard.trigger('dashboard-config-update', this.dashboardLocation);
    });
  }), _dec10 = Ember._action, (_class = (_temp = class EditDashboard {
    constructor(routeArguments, optionalArguments) {
      _initializerDefineProperty(this, "isEdit", _descriptor, this);

      _initializerDefineProperty(this, "showConfig", _descriptor2, this);

      _initializerDefineProperty(this, "dashboardCards", _descriptor3, this);

      _defineProperty(this, "titleKeySuffix", '');

      _initializerDefineProperty(this, "addedCardsTask", _descriptor4, this);

      _initializerDefineProperty(this, "sectionsTask", _descriptor5, this);

      _initializerDefineProperty(this, "ispConnectedTask", _descriptor6, this);

      _initializerDefineProperty(this, "ispProviderTypeIdTask", _descriptor7, this);

      _initializerDefineProperty(this, "getEmberCardsTask", _descriptor8, this);

      _initializerDefineProperty(this, "updateCardTask", _descriptor9, this);

      Ember.setProperties(this, optionalArguments);
    }

    get addedCards() {
      return [];
    }

    get availableRelativeDates() {
      return [{
        name: this.intl.t('features.dasboard_card_relative_dates.today'),
        start_day: '***today***',
        relative_date: 'today'
      }, {
        name: this.intl.t('features.dasboard_card_relative_dates.tomorrow'),
        start_day: '***tomorrow***',
        end_day: '***tomorrow***',
        relative_date: 'tomorrow'
      }, {
        name: this.intl.t('features.dasboard_card_relative_dates.yesterday'),
        start_day: '***yesterday***',
        end_day: '***yesterday***',
        relative_date: 'yesterday'
      }, {
        name: this.intl.t('features.dasboard_card_relative_dates.this_week'),
        short_name: this.intl.t('features.dasboard_card_relative_dates.this_week_short'),
        start_day: '***start_of_week***',
        end_day: '***end_of_week***',
        relative_date: 'this_week'
      }, {
        name: this.intl.t('features.dasboard_card_relative_dates.this_work_week'),
        short_name: this.intl.t('features.dasboard_card_relative_dates.this_work_week_short'),
        start_day: '***start_of_work_week***',
        end_day: '***end_of_work_week***',
        relative_date: 'this_work_week'
      }, {
        name: this.intl.t('features.dasboard_card_relative_dates.last_week'),
        short_name: this.intl.t('features.dasboard_card_relative_dates.last_week_short'),
        start_day: '***start_of_last_week***',
        end_day: '***end_of_last_week***',
        relative_date: 'last_week'
      }, {
        name: this.intl.t('features.dasboard_card_relative_dates.last_work_week'),
        short_name: this.intl.t('features.dasboard_card_relative_dates.last_work_week_short'),
        start_day: '***start_of_last_work_week***',
        end_day: '***end_of_last_work_week***',
        relative_date: 'last_work_week'
      }, {
        name: this.intl.t('features.dasboard_card_relative_dates.next_week'),
        short_name: this.intl.t('features.dasboard_card_relative_dates.next_week_short'),
        start_day: '***start_of_next_week***',
        end_day: '***end_of_next_week***',
        relative_date: 'next_week'
      }, {
        name: this.intl.t('features.dasboard_card_relative_dates.next_work_week'),
        short_name: this.intl.t('features.dasboard_card_relative_dates.next_work_week_short'),
        start_day: '***start_of_next_work_week***',
        end_day: '***end_of_next_work_week***',
        relative_date: 'next_work_week'
      }, {
        name: this.intl.t('features.dasboard_card_relative_dates.this_month'),
        start_day: '***start_of_month***',
        end_day: '***end_of_month***',
        relative_date: 'this_month'
      }, {
        name: this.intl.t('features.dasboard_card_relative_dates.last_month'),
        tart_day: '***start_last_month***',
        end_day: '***end_of_last_month***',
        relative_date: 'last_month'
      }, {
        name: this.intl.t('features.dasboard_card_relative_dates.this_quarter'),
        start_day: '***start_of_this_quarter***',
        end_day: '***end_of_this_quarter***',
        relative_date: 'this_quarter'
      }, {
        name: this.intl.t('features.dasboard_card_relative_dates.last_quarter'),
        start_day: '***start_of_last_quarter***',
        end_day: '***end_of_last_quarter***',
        relative_date: 'last_quarter'
      }, {
        name: this.intl.t('features.dasboard_card_relative_dates.this_year'),
        start_day: '***start_of_this_year***',
        end_day: '***end_of_this_year***',
        relative_date: 'this_year'
      }, {
        name: this.intl.t('features.dasboard_card_relative_dates.last_year'),
        start_day: '***start_of_last_year***',
        end_day: '***end_of_last_year***',
        relative_date: 'last_year'
      }, {
        name: this.intl.t('features.dasboard_card_relative_dates.last_thirty'),
        start_day: '***l30***',
        relative_date: 'l30'
      }, {
        name: this.intl.t('features.dasboard_card_relative_dates.last_sixty'),
        start_day: '***l60***',
        relative_date: 'l60'
      }, {
        name: this.intl.t('features.dasboard_card_relative_dates.last_ninety'),
        start_day: '***l90***',
        relative_date: 'l90'
      }, {
        name: this.intl.t('features.dasboard_card_relative_dates.last_one_eighty'),
        start_day: '***l180***',
        relative_date: 'l180'
      }, {
        name: this.intl.t('features.dasboard_card_relative_dates.last_three_sixty_five'),
        start_day: '***l365***',
        relative_date: 'l365'
      }];
    }

    _checkCardProviderType(card, ispProvTypeId) {
      if (_environment.default.environment == 'test') {
        // mirage
        return true;
      }

      if (card.card === 'trouble-conditions-card' || card.card === 'adc-best-practices-card') {
        return ispProvTypeId == 1;
      }

      return true;
    }

    _checkCardGrants(card, calledFromDashboard) {
      var cardGrants = [];

      if (this.availableCards[card.card].config.card_focus_options !== 'none') {
        var focus = typeof card.config.card_focus === 'object' ? card.config.card_focus.value : card.config.card_focus;
        cardGrants = this.availableCards[card.card].config.card_focus_options[focus].split(',');
      } else {
        cardGrants = card.config.grant.split(',');
      }

      var hasGrants = true;

      for (var i = 0; i < cardGrants.length; i++) {
        if (!calledFromDashboard.grants.getIndividualGrant(cardGrants[i].trim())) {
          hasGrants = false;
          break;
        }
      }

      return hasGrants;
    }

    _checkCardRoles(cardConfig, userRoles) {
      if (!cardConfig.role) {
        return true;
      }

      if (cardConfig.role === 'any') {
        return Object.values(userRoles).some(role => role);
      } else {
        if (cardConfig.roleCheckType === 'or') {
          return cardConfig.role.split(',').some(role => userRoles[role]);
        } else {
          return cardConfig.role.split(',').every(role => userRoles[role]);
        }
      }
    }

    _updateConfigDiscrepancies(cardData, keys) {
      var configCards = this.availableCards;
      keys.forEach(key => {
        if (cardData.config[key] !== configCards[cardData.card].config[key]) {
          Ember.set(cardData.config, key, configCards[cardData.card].config[key]);
        }
      }); // check if date_range is still available

      var availableDateRanges = _lodash.default.get(configCards[cardData.card].config, 'date_range_options', null);

      var savedDateRange = _lodash.default.get(cardData.config, 'date_range.relative_date', null);

      if (savedDateRange && availableDateRanges === 'all' || Array.isArray(availableDateRanges) && availableDateRanges.includes(savedDateRange)) {
        return;
      } else if (Array.isArray(availableDateRanges) && availableDateRanges.length > 0) {
        var defaultDateRange = configCards[cardData.card].config.default_date_range;

        if (defaultDateRange && availableDateRanges.includes(defaultDateRange.relative_date)) {
          Ember.set(cardData.config, 'date_range', this.availableRelativeDates.findBy('relative_date', defaultDateRange));
        } else {
          Ember.set(cardData.config, 'date_range', this.availableRelativeDates.findBy('relative_date', availableDateRanges[0]));
        }
      } else {
        Ember.set(cardData.config, 'date_range', null);
      }
    }

    getCardFocusOptions(cardName, grants) {
      var config = this.availableCards[cardName].config;
      if (config.card_focus_options === 'none') return [];
      var allOptions = {
        'company': {
          name: this.intl.t('features.dashboard_card_categories.company'),
          value: 'company'
        },
        'me': {
          name: this.intl.t('features.dashboard_card_categories.me'),
          value: 'me'
        }
      };
      var focusOptions = [];
      Object.keys(config.card_focus_options).forEach(option => {
        var cardGrants = config.card_focus_options[option].split(',');
        var canUse = true;

        for (var i = 0; i < cardGrants.length; i++) {
          if (!grants.getIndividualGrant(cardGrants[i])) {
            canUse = false;
            break;
          }
        }

        if (canUse) {
          focusOptions.push(allOptions[option]);
        }
      });
      return focusOptions;
    }

    getAvailableGalleryCardsList(grants, roles = null, showRiskScore = false, ispProvTypeId = null) {
      var canUseCards = [];
      var availableCards = this.availableCards;
      Object.keys(availableCards).forEach(key => {
        var config = availableCards[key].config;
        var hasGrant = false;

        if (!config.grant) {
          var focusOptionEntries = Object.entries(config.card_focus_options);

          for (var i = 0; i < focusOptionEntries.length; i++) {
            var focusOptionEntry = focusOptionEntries[i];
            var cardGrants = focusOptionEntry[1].split(',');

            if (grants.hasAllGrants(cardGrants)) {
              hasGrant = true;
              break;
            }
          }
        } else {
          hasGrant = grants.hasAllGrants(config.grant.split(','));
        }

        var hasRole = this._checkCardRoles(config, roles);

        var ADCCheck = this._checkCardProviderType(availableCards[key], ispProvTypeId);

        var riskScoreCheck = !(key === 'risk-score-card' && !showRiskScore);

        if (ADCCheck && hasGrant && hasRole && riskScoreCheck) {
          canUseCards.push(key);
        }
      });
      return canUseCards;
    }

    toggleConfig() {
      this.showConfig = !this.showConfig;
    }

    toggleEdit() {
      this.isEdit = !this.isEdit;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isEdit", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "showConfig", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dashboardCards", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "addedCardsTask", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sectionsTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "ispConnectedTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "ispProviderTypeIdTask", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "getEmberCardsTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "updateCardTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleEdit", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "toggleEdit"), _class.prototype)), _class));
  _exports.default = EditDashboard;
});