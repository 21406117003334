define("securitytrax-ember-ui/components/modals/manage-isp-connection/component", ["exports", "ember-concurrency", "lodash"], function (_exports, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    manageConnectionTitle: Ember.computed('multiStepForm.formCopiedModels.interactiveServicesProvider.model.name', function () {
      return this.intl.t('pages.administration.integrations.monitoringCompanies.integrations.manage_connection_title', {
        name: this.multiStepForm.formCopiedModels.interactiveServicesProvider.model.name
      });
    }),
    formButtons: Ember.computed(function () {
      var buttons = [{
        text: this.intl.t('common.actions.save'),
        classNamesString: 'st-btn st-btn-primary save-connection',
        click: buttonPromise => {
          return this.manageConnectionTask.perform(buttonPromise);
        }
      }];
      return buttons;
    }),
    manageConnectionTask: (0, _emberConcurrency.task)(function* (buttonPromise) {
      var multiStepForm = this.multiStepForm;
      var noValidateArray = ['interactiveServicesProviderConfig-dealerWebsiteLeadCompanyId', 'interactiveServicesProviderConfig-referAFriendLeadCompanyId', 'interactiveServicesProviderConfig-referralLeadCompanyId', 'interactiveServicesProviderConfig-selfCreatedLeadCompanyId', 'interactiveServicesProviderConfig-serviceRequestServiceTechId', 'interactiveServicesProviderConfig-serviceRequestUpsellTechId'];

      if (_lodash.default.get(multiStepForm, 'formCopiedModels.interactiveServicesProvider.model.interactiveServicesProviderConfig.responseMeta.attribute_has_saved_value.password', false)) {
        noValidateArray.push('interactiveServicesProviderConfig-password');
      }

      var overrideConfig = Ember.Object.create({
        interactiveServicesProvider: {
          model: this.interactiveServicesProvider,
          copyRelationMap: {
            interactiveServicesProviderType: {
              readOnly: true
            },
            interactiveServicesProviderConfig: {}
          }
        }
      });
      yield multiStepForm.validateSyncSaveAll({
        overrideOriginalModelsConfig: overrideConfig,
        extraValidationData: {
          noValidate: noValidateArray
        },
        notificationNamespace: 'manageConnectionModal',
        successNotificationNamespace: 'modal'
      });
      buttonPromise.then(() => {
        this.set('formStarted', false);
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
      });
    })
  });

  _exports.default = _default;
});