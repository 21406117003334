define("securitytrax-ember-ui/accounting/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Controller
   * @extends Ember.Controller
   * @memberof administration.human-resources
   */
  var Controller = Ember.Controller.extend(
  /** @lends administration.human-resources.Controller# */
  {
    navigation: Ember.inject.service(),
    quickbooksService: Ember.inject.service(),
    quickbooksIsConnected: undefined,
    initializeNav: Ember.on('init', function () {
      this.navigation;
      Ember.set(this, 'quickbooksIsConnected', this.quickbooksService.quickbooksIsConnected.perform());
    })
  });
  var _default = Controller;
  _exports.default = _default;
});