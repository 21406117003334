define("securitytrax-ember-ui/models/disclosure-data", ["exports", "securitytrax-ember-ui/models/base_models/disclosure-data"], function (_exports, _disclosureData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _disclosureData.default.extend({});

  _exports.default = _default;
});