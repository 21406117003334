define("securitytrax-ember-ui/validations/customer-contact", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation", "lodash"], function (_exports, _validate, _baseValidation, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var conditionalValidation = _lodash.default.get(data, 'extraValidationData.conditionalValidation', false);

      var validateObject = {
        name: {
          presence: {
            allowEmpty: false
          }
        },
        about: {
          presence: {
            allowEmpty: false
          }
        }
      };
      var isAdt = data.extraValidationData && 'adtValidation' in data.extraValidationData && data.extraValidationData.adtValidation === true;

      if (conditionalValidation && conditionalValidation.includes('customerContact-phone1') || !conditionalValidation) {
        validateObject.phone1 = {
          presence: this.customMessageOrDefault(data, 'phone1'),
          format: {
            pattern: isAdt ? /[1-9][0-9]{9}/ : /\d{9,15}/,
            //allow 9 for international, up to 15 since db accepts 15, //allow 9 for international, up to 15 since db accepts 15
            message: this.customMessage(data, 'phoneInvalid')
          }
        };
      } else if (conditionalValidation && conditionalValidation.includes('customerContact-pin')) {
        validateObject.phone1 = {
          presence: this.customMessageOrDefault(data, 'noPin')
        };
      } // example of how to use intl in a validation mixin
      // console.log('datatest', data.intlService.t('pages.administration.content_management.activation_fee.title'), this.customMessage(data, 'nameInvalid'));


      if (isAdt) {
        validateObject['name']['format'] = {
          pattern: /([a-zA-Z])+\s([a-zA-Z])+/,
          message: this.customMessage(data, 'nameInvalid')
        };
      }

      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);

      if (validationData && validationData['about']) {
        validationData['about'] = validationData['about'].map(message => {
          return message.replace(/About/, 'Phone type');
        });
      }

      if (validationData && validationData['phone1']) {
        validationData['phone1'] = validationData['phone1'].map(message => {
          return message.replace(/Phone1/, '');
        });
      }

      return this.outputFormatting(validationData, 'customerContact');
    }

  });

  _exports.default = _default;
});