define("securitytrax-ember-ui/components/pages/demo-page/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pQK2u6gG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[34,2]],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"page-content cell auto demo-page\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"scroll-page\"],[12],[2,\"\\n\"],[6,[37,6],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,1],null,null,[[\"default\"],[{\"statements\":[[6,[37,0],null,null,[[\"default\"],[{\"statements\":[[2,\"                    \"],[10,\"div\"],[14,0,\"st-card__cell\"],[12],[2,\"\\n                        \"],[18,1,null],[2,\"\\n                    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"html/st-card/body\",\"html/st-card\",\"nav/left-navs/demo-left-nav\",\"hideNav\",\"unless\",\"includeCard\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/demo-page/template.hbs"
    }
  });

  _exports.default = _default;
});