define("securitytrax-ember-ui/my-account/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Controller
   * @extends Ember.Controller
   * @memberof customers
   */
  var Controller = Ember.Controller.extend(
  /** @lends customers.information.Controller# */
  {
    sessionData: Ember.inject.service(),
    navigation: Ember.inject.service(),
    initializeNav: Ember.on('init', function () {
      this.navigation;
    }),
    isTechOrSalesRep: Ember.computed('user.roles.{[],@each}', function () {
      return this.isTechOrSalesRepTask.perform();
    }),
    isTechOrSalesRepTask: (0, _emberConcurrency.task)(function* () {
      var user = yield this.user;
      var isSalesRep = yield user.isSalesRep;
      var isTech = yield user.isTech;
      return isSalesRep || isTech;
    }),
    isSalesRep: Ember.computed('user.roles', function () {
      return this.isSalesRepTask.perform();
    }),
    isSalesRepTask: (0, _emberConcurrency.task)(function* () {
      var user = yield this.user;
      var isSalesRep = yield user.isSalesRep;
      return isSalesRep;
    })
  });
  var _default = Controller;
  _exports.default = _default;
});