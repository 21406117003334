define("securitytrax-ember-ui/models/base_models/docusign-account", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    currentPlanId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    planName: (0, _model.attr)('string', {
      'readOnly': false
    }),
    planStartDate: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    planEndDate: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    billingPeriodStartDate: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    billingPeriodEndDate: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    billingPeriodEnvelopesSent: (0, _model.attr)('number', {
      'readOnly': false
    }),
    billingPeriodEnvelopesAllowed: (0, _model.attr)('string', {
      'readOnly': false
    }),
    billingPeriodDaysRemaining: (0, _model.attr)('number', {
      'readOnly': false
    }),
    canUpgrade: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    canCancelRenewal: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    envelopeSendingBlocked: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    envelopeUnitPrice: (0, _model.attr)('number', {
      'readOnly': false
    }),
    suspensionStatus: (0, _model.attr)('string', {
      'readOnly': false
    }),
    accountName: (0, _model.attr)('string', {
      'readOnly': false
    }),
    connectPermission: (0, _model.attr)('string', {
      'readOnly': false
    }),
    docuSignLandingUrl: (0, _model.attr)('string', {
      'readOnly': false
    }),
    distributorCode: (0, _model.attr)('string', {
      'readOnly': false
    }),
    accountIdGuid: (0, _model.attr)('string', {
      'readOnly': false
    }),
    externalAccountId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    currencyCode: (0, _model.attr)('string', {
      'readOnly': false
    }),
    forgottenPasswordQuestionsCount: (0, _model.attr)('number', {
      'readOnly': false
    }),
    paymentMethod: (0, _model.attr)('string', {
      'readOnly': false
    }),
    createdDate: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    isDowngrade: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    billingProfile: (0, _model.attr)('string', {
      'readOnly': false
    }),
    allowTransactionRooms: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    accountOwner: (0, _model.attr)('string', {
      'readOnly': false
    }),
    docusignCustomFields: (0, _model.hasMany)('docusign-custom-field', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    docusignTemplates: (0, _model.hasMany)('docusign-template', {
      'readOnly': true,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});