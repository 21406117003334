define("securitytrax-ember-ui/home/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/has-page-title", "securitytrax-ember-ui/mixins/scroll-to-top", "ember-concurrency", "securitytrax-ember-ui/config/environment", "securitytrax-ember-ui/classes/edit-home-dashboard"], function (_exports, _authenticatedRouteMixin, _hasPageTitle, _scrollToTop, _emberConcurrency, _environment, _editHomeDashboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _hasPageTitle.default, _scrollToTop.default, {
    sessionData: Ember.inject.service(),
    intl: Ember.inject.service(),
    homeDashboard: Ember.inject.service('events/home-dashboard'),
    grants: Ember.inject.service(),
    editDashboard: undefined,

    beforeModel() {
      this._super(...arguments);

      this.set('editDashboard', new _editHomeDashboard.default(...arguments, {
        intl: this.intl
      }));
      return this.findGrantsTask.perform();
    },

    model() {
      return this.getModelData.perform();
    },

    getModelData: (0, _emberConcurrency.task)(function* () {
      var parsedRoles = {};
      var loggedInUser = null;
      var cardCount = 1;

      try {
        loggedInUser = yield this.sessionData.getUser();
        var roles = yield loggedInUser.get('roles');

        for (var index = 0; index < roles.get('length'); index++) {
          var role = roles.objectAt(index);
          parsedRoles[role.get('key')] = true;
        }

        parsedRoles['tech_manager'] = yield loggedInUser.isTechManager;

        if (_environment.default.environment != 'test') {
          // Real land, issues with mirage
          cardCount = this.editDashboard.getAvailableGalleryCardsList(this.grants, parsedRoles).length;
        }
      } catch (e) {
        (false && Ember.warn(e, {
          id: 'app.home.getModelData'
        }));
      }

      return (0, _emberConcurrency.hash)({
        loggedInUser: loggedInUser,
        roles: parsedRoles,
        editDashboard: this.editDashboard,
        cardCount
      });
    }),

    getPageTitle() {
      return '';
    },

    getRouteName() {
      return 'common.nav.home';
    }

  });

  _exports.default = _default;
});