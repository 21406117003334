define("securitytrax-ember-ui/components/pages/administration/inventory/inventory-ledger/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    inventoryService: Ember.inject.service(),
    stFiltersService: Ember.inject.service(),
    // Filters
    createdDateFrom: undefined,
    createdDateTo: undefined,
    status: undefined,
    equipmentItem: undefined,
    recipient: undefined,
    class: undefined,
    targetClass: undefined,
    sourceClass: undefined,
    targetRecipient: undefined,
    source: undefined,
    limit: 50,
    page: 1,
    inventoryLedgerListCount: 0,
    equipment: undefined,
    classOptions: undefined,
    recipientOptions: undefined,
    initDataTask: (0, _emberConcurrency.task)(function* () {
      var hashedData = yield (0, _emberConcurrency.hash)({
        equipment: this.store.query('equipment', {
          limit: 0,
          sort: 'name',
          filter: 'active=true'
        }),
        recipientOptions: this.inventoryService.get('getInventoryRecipients').perform(),
        classOptions: this.inventoryService.get('getInventorySinkClasses').perform()
      });
      Ember.setProperties(this, hashedData);
    }).on('init'),
    statusOptions: Ember.computed(function () {
      var intl = this.intl;
      return [{
        value: 'complete',
        name: intl.t('common.status.complete')
      }, {
        value: 'pending',
        name: intl.t('common.status.pending')
      }];
    }),
    inventoryLedger: Ember.computed(function () {
      return this.getInventoryLedgerTask.perform();
    }),
    getInventoryLedgerTask: (0, _emberConcurrency.task)(function* () {
      var newFilters = yield this.stFiltersService.getFiltersByGroupKey('inventoryLedgerListFilter');
      var queryParams = {
        filter: newFilters,
        sort: '-id',
        include: 'equipment',
        limit: this.limit,
        page: this.page
      };

      if (this.page == 1) {
        queryParams['count'] = 1;
      }

      var ledger = yield this.store.query('equipmentLedger', queryParams);

      if (this.page == 1) {
        this.set('inventoryLedgerListCount', ledger.meta.record_count);
      }

      return ledger;
    }).restartable().maxConcurrency(1),
    actions: {
      clearFilter() {
        Ember.setProperties(this, {
          createdDateFrom: undefined,
          createdDateTo: undefined,
          status: undefined,
          equipmentItem: undefined,
          recipient: undefined,
          class: undefined,
          targetClass: undefined,
          sourceClass: undefined,
          targetRecipient: undefined,
          source: undefined
        });
        Ember.notifyPropertyChange(this, 'inventoryLedger');
      },

      filterInventoryLedger() {
        this.set('page', 1);
        Ember.notifyPropertyChange(this, 'inventoryLedger');
      },

      getInventoryLedger() {
        Ember.notifyPropertyChange(this, 'inventoryLedger');
      },

      filter_sourceOrTargetClass() {
        if (this.class) {
          return "(source_class = ".concat(this.class, " or target_class = ").concat(this.class, ")");
        }

        return false;
      },

      filter_sourceOrTargetRecipient() {
        if (this.recipient) {
          return this.recipient.constructor.modelName === 'inventory-user' ? "(target_user = ".concat(this.recipient.get('id'), " or source_user = ").concat(this.recipient.get('id'), ")") : "(target_location = ".concat(this.recipient.get('id'), " or source_location = ").concat(this.recipient.get('id'), ")");
        }

        return false;
      }

    }
  });

  _exports.default = _default;
});