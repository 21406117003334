define("securitytrax-ember-ui/home/view/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wo0KG+15",
    "block": "{\"symbols\":[],\"statements\":[[8,\"pages/home/home-view\",[],[[\"@user\",\"@roles\",\"@editDashboard\"],[[34,0,[\"user\"]],[34,0,[\"roles\"]],[34,0,[\"editDashboard\"]]]],null],[2,\"\\n\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,2],[[35,0,[\"cardCount\"]],0],null],[30,[36,1],[\"homeDashboardConfig\"],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"dashboard-config\",[],[[\"@roles\",\"@editDashboard\"],[[34,0,[\"roles\"]],[34,0,[\"editDashboard\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,6],[[30,[36,5],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"model\",\"feature-is-enabled\",\"gt\",\"and\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/home/view/template.hbs"
    }
  });

  _exports.default = _default;
});