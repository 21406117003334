define("securitytrax-ember-ui/models/task-type", ["exports", "securitytrax-ember-ui/models/base_models/task-type"], function (_exports, _taskType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _taskType.default.extend({});

  _exports.default = _default;
});