define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/forms/customer-information/qualify/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/qualify/component", "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/qualify/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _template.default,
    currFormName: 'customerInformation',
    setupStep2Buttons: function () {
      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('step1', 'buttons', [{
        text: this.intl.t('common.actions.close'),
        classNamesString: 'st-btn st-btn-secondary',
        click: () => {
          multiStepForm.formStartedSetVal(false);
        }
      }]);
    }
  });

  _exports.default = _default;
});