define("securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-customer-equipment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9IM6Q2eZ",
    "block": "{\"symbols\":[\"multiStepForm\",\"AddEquipmentForm\",\"@workOrder\"],\"statements\":[[8,\"html/st-await\",[],[[\"@useSpinner\",\"@booleanLoaded\"],[false,[34,0,[\"isIdle\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"multi-step-form\",[],[[\"@formSteps\",\"@currentStepNameKey\",\"@changeCurrentStepNameKey\",\"@formOriginalModels\",\"@onAfterSave\",\"@formStarted\",\"@changeFormStarted\"],[[32,0,[\"customerEquipmentFormSteps\"]],[32,0,[\"customerEquipmentFirstStep\"]],[30,[36,2],[[32,0],[30,[36,1],[[32,0,[\"customerEquipmentFirstStep\"]]],null]],null],[32,0,[\"customerEquipmentFormOriginalModels\"]],[30,[36,2],[[32,0],\"onAfterSave\"],null],[32,0,[\"customerEquipmentFormStarted\"]],[30,[36,2],[[32,0],[30,[36,1],[[32,0,[\"customerEquipmentFormStarted\"]]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"modals/st-paper\",[],[[\"@modalButtons\",\"@title\",\"@classNames\",\"@dialogOpen\",\"@onClose\",\"@multiStepForm\"],[[32,1,[\"currentStepData\",\"buttons\"]],[32,1,[\"currentStepData\",\"title\"]],\"st-modal--full equipment-modal\",[32,1,[\"formStarted\"]],[30,[36,2],[[32,0],\"transitionBackToParentRoute\",false],null],[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[8,[32,2],[],[[\"@multiStepForm\",\"@formMode\",\"@customer\",\"@workOrder\",\"@afterEquipmentDelete\"],[[32,1],[32,0,[\"customerEquipmentFormMode\"]],[32,0,[\"customer\"]],[32,3],[32,0,[\"transitionBackToParentRoute\"]]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"initData\",\"mut\",\"action\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-customer-equipment/template.hbs"
    }
  });

  _exports.default = _default;
});