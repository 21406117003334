define("securitytrax-ember-ui/components/org-chart/st-leaf/user-leaf/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    orgTree: Ember.inject.service(),
    router: Ember.inject.service(),
    screen: Ember.inject.service(),
    classNames: ['st-user-leaf'],
    showStem: true,
    mediaOrientation: 'st-media-object--portrait',
    tabindex: 0,
    attributeBindings: ['tabindex'],
    isCurrentUser: false,
    showInfoModal: false,

    init() {
      this._super(...arguments);

      this.getUserTitleTask.perform();
    },

    willUpdate() {
      this._super(...arguments);
    },

    leafExtension: Ember.computed('isCurrentUser', function () {
      return this.isCurrentUser ? 'st-user-leaf__stem--connector' : '';
    }),

    click() {},

    userHiearchyChildrenCount: Ember.computed('user.children', function () {
      return this.getUserHiearchyChildrenCountTask.perform();
    }),
    getUserHiearchyChildrenCountTask: (0, _emberConcurrency.task)(function* () {
      var user = yield this.user;

      if (user) {
        var userHierarchyChildren = yield user.get('children');

        if (userHierarchyChildren) {
          return userHierarchyChildren.length;
        }

        return null;
      }
    }),
    userTitle: Ember.computed(function () {
      return this.getUserTitleTask.perform();
    }),
    getUserTitleTask: (0, _emberConcurrency.task)(function* () {
      var user = yield this.user;

      if (user) {
        var userHierarchyTitle = yield user.get('userHierarchyTitle');

        if (userHierarchyTitle) {
          return yield userHierarchyTitle.get('name');
        }

        return null;
      }
    }),
    changeRouteTask: (0, _emberConcurrency.task)(function* () {
      var userHierarchyTree = yield this.get('orgTree.userHierarchyTree');
      var user = JSON.parse(this.get('user.id'));

      if (this.isCompany) {
        this.router.transitionTo('/administration/org-chart/' + userHierarchyTree.get('id'));
      } else {
        this.router.transitionTo('/administration/org-chart/' + userHierarchyTree.get('id') + '/user/' + user.user_id);
      }
    }),
    actions: {
      dropDownClick(dropdown) {
        dropdown.actions.open();
        return false;
      },

      cardClick() {
        var orgTree = this.orgTree;
        orgTree.set('focusedUser', this.user);

        if (this.get('screen.isMobile')) {
          this.set('showInfoModal', true);
        }

        return false;
      },

      pillClick() {
        if (!this.isCurrentUser) {
          this.changeRouteTask.perform();
          return false;
        }
      },

      closeDialog() {
        this.set('showInfoModal', false);
      }

    }
  });

  _exports.default = _default;
});