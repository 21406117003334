define("securitytrax-ember-ui/components/multi-step-form/forms/administration/users/create/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/administration/users/base-users", "ember-concurrency"], function (_exports, _baseUsers, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseUsers.default.extend({
    intl: Ember.inject.service(),
    password: '',
    passwordVerify: '',
    generatePassword: true,
    initFormData: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = this.multiStepForm;
      yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);

      if (this.isNewRecord) {
        // toggles on by default
        var toggleArray = ['userAdminSetting.active', 'userAdminSetting.login', 'userAdminSetting.includeInDirectory', 'userPreference.emailReminders', 'userPreference.restoreSession'];
        toggleArray.forEach(toggle => {
          multiStepForm.formCopiedModelsSetVal("user.model.".concat(toggle), true);
        });
      }
    }).on('init'),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;

      if (this.isNewRecord) {
        multiStepForm.registerStepData("step1", 'buttons', [{
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            var noValidateArray = []; //['group'];

            if (this.generatePassword) {
              noValidateArray.push('password');
            }

            return this.saveUserDataTask.perform(multiStepForm, buttonPromise, noValidateArray);
          },
          classNamesString: 'st-btn st-btn-primary user-save-button'
        }]);
      }
    }

  });

  _exports.default = _default;
});