define("securitytrax-ember-ui/models/lead-information-default", ["exports", "securitytrax-ember-ui/models/base_models/lead-information-default"], function (_exports, _leadInformationDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _leadInformationDefault.default.extend({});

  _exports.default = _default;
});