define("securitytrax-ember-ui/administration/company-settings/system/global-settings/inventory/route", ["exports", "securitytrax-ember-ui/administration/company-settings/system/global-settings/base-route", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _baseRoute, _authenticatedRouteMixin, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_authenticatedRouteMixin.default, _routePageviewLogging.default, {
    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['user_inventory_settings.view']);
    }

  });

  _exports.default = _default;
});