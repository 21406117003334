define("securitytrax-ember-ui/validations/global-setting", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateObject = {
        emailSalesScheduleAppointments: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('globalSetting-emailSalesScheduleAppointments')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'globalSetting-emailSalesScheduleAppointments');
            }
          }
        },
        salesScheduleTemplateMessage: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('globalSetting-salesScheduleTemplateMessage')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'globalSetting-salesScheduleTemplateMessage');
            }
          }
        },
        emailTechScheduleAppointments: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('globalSetting-emailTechScheduleAppointments')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'globalSetting-emailTechScheduleAppointments');
            }
          }
        },
        techScheduleTemplateMessage: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('globalSetting-techScheduleTemplateMessage')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'globalSetting-techScheduleTemplateMessage');
            }
          }
        },
        calendarEventSchedulerStartTime: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('globalSetting-calendarEventSchedulerStartTime')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'globalSetting-calendarEventSchedulerStartTime');
            }
          }
        },
        calendarEventSchedulerDurationSeconds: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('globalSetting-calendarEventSchedulerDurationSeconds')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'globalSetting-calendarEventSchedulerDurationSeconds');
            }
          },
          numericality: {
            greaterThanOrEqualTo: 21600,
            message: function ()
            /*value, attribute, validatorOptions, attributes, globalOptions*/
            {
              return '^' + data.intlService.t('pages.administration.company_settings.global_settings.calendar.calendar_start_time_validation_message');
            }
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);

      if (validationData && validationData['emailTechScheduleAppointments']) {
        validationData['emailTechScheduleAppointments'] = validationData['emailTechScheduleAppointments'].map(message => {
          return message.replace(/Email/, 'When to send');
        });
      }

      if (validationData && validationData['emailSalesScheduleAppointments']) {
        validationData['emailSalesScheduleAppointments'] = validationData['emailSalesScheduleAppointments'].map(message => {
          return message.replace(/Email/, 'When to send');
        });
      }

      return this.outputFormatting(validationData, 'globalSetting');
    }

  });

  _exports.default = _default;
});