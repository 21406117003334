define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/interactive-services-providers/integrations/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6MfOpMgT",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,8],null,[[\"config\",\"modalButtons\"],[[35,7],[35,2,[\"currentStepData\",\"buttons\"]]]],[[\"default\"],[{\"statements\":[[6,[37,6],null,[[\"useSpinner\",\"booleanLoaded\"],[true,[35,2,[\"finishedCopyingModels\"]]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,5],[[35,4]],[[\"formMode\",\"multiStepForm\",\"interactiveServicesProvider\",\"validateCredentialsTask\"],[[35,3],[35,2],[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"validateCredentialsTask\",\"interactiveServicesProvider\",\"multiStepForm\",\"formMode\",\"formComponent\",\"component\",\"html/st-await\",\"progressBarConfig\",\"multi-step-form/progress-bar\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/interactive-services-providers/integrations/template.hbs"
    }
  });

  _exports.default = _default;
});