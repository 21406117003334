define("securitytrax-ember-ui/components/inventory/admin-perform-inventory/component", ["exports", "@glimmer/component", "ember-concurrency", "moment", "securitytrax-ember-ui/mixed-in-classes/grouped-equipment", "jquery"], function (_exports, _component, _emberConcurrency, _moment, _groupedEquipment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var AdminPerformInventory = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = (0, _emberConcurrency.task)(function* () {
    try {
      var intl = this.intl;
      var equipmentList = yield this.store.query('equipment', {
        limit: 0,
        include: 'equipment_type',
        filter: 'active=true'
      });

      if (this.selectedUser) {
        var inventoryList = yield this.selectedUser.invokeAction('get_admin_equipment_inventory', {
          verb: 'GET'
        });
        var inventoryToDisplay = [];
        var equipmentItem = null;
        inventoryList.responsePayload.data.forEach(function (value) {
          equipmentItem = equipmentList.find(function (item) {
            if (item.id === value.equipment_id) {
              return item;
            }
          });

          if (equipmentItem) {
            var previousInventoryDate = value.previous_inventory_date ? _moment.default.tz(_moment.default.unix(value.previous_inventory_date), 'America/Denver') : null; //TODO-Timezone  This is a hack to force time into mountain

            var base = value.base_quantity;
            var baseIsNull = false;

            if (base == null) {
              baseIsNull = true;
              base = intl.t('pages.administration.inventory.perform_physical_inventory.not_set');
            }

            var minQuantity = value.min_quantity;
            var minQuantityIsNull = false;

            if (minQuantity == null) {
              minQuantityIsNull = true;
              minQuantity = intl.t('pages.administration.inventory.perform_physical_inventory.not_set');
            }

            var valueOfItems = value.on_hand_quantity * equipmentItem.get('cost');
            var inventoryItem = Ember.Object.create({
              'equipment': equipmentItem,
              'value': valueOfItems,
              'id': value.equipment_id,
              'on_hand': value.on_hand_quantity,
              'on_order': value.on_order_quantity,
              'base': base,
              'base_null': baseIsNull,
              'quantity': 0,
              'on_transfer_quantity': value.on_transfer_quantity,
              'min_quantity': minQuantity,
              'min_null': minQuantityIsNull,
              'previous_inventory_quantity': value.previous_inventory_quantity,
              'previous_inventory_date': previousInventoryDate,
              'pre_allocated_quantity': value.pre_allocated_quantity,
              'difference': value.difference,
              'updateCount': false
            });
            inventoryToDisplay.push(inventoryItem);
          }
        });
        this.inventoryList = inventoryToDisplay;
        this.items = inventoryToDisplay;
      }
    } catch (err) {
      this.items = [];
      this.stNotificationManager.addError('Error setting up inventory list');
    }
  }), _dec18 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var inventory = this.items.filter(item => {
      return item.updateCount;
    });
    var inventoryToSubmit = [];
    var user = yield this.selectedUser;
    inventory.forEach(function (equipmentItem) {
      var inventoryItem = Ember.Object.create({
        'equipment_id': equipmentItem.get('id'),
        'quantity': equipmentItem.get('quantity') ? Number(equipmentItem.get('quantity')) : 0
      });
      inventoryToSubmit.push(inventoryItem);
    });
    yield user.invokeAction('perform_admin_physical_inventory', {
      verb: 'POST',
      data: {
        data: inventoryToSubmit
      }
    });
    buttonPromise.then(() => {
      this.openModal = false;
      this.selectAllRows = false;
      this.getUser = false;
      this.selectedUser = null;
      this.hideSubmit = true;
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'global');
      this.inventoryListTask.perform();
    });
  }), _dec19 = (0, _emberConcurrency.task)(function* () {
    Ember.setProperties(this, {
      filterBase: false,
      filterQuantity: false,
      equipmentName: undefined
    });
    this.items = this.inventoryList;
    return yield this.filterListTask.perform();
  }), _dec20 = (0, _emberConcurrency.task)(function* () {
    this.items.forEach(function (value) {
      value.set('quantity', 0);
      value.set('updateCount', false);
    });
    var newFilters = yield this.stFiltersService.getFiltersByGroupKeyAsObject('inventoryAdminPerformInventory');
    var inventoryToDisplay = this.inventoryList;

    if (newFilters['filterBase']) {
      inventoryToDisplay = inventoryToDisplay.filter(item => {
        return item.base > 0;
      });
    }

    if (newFilters['filterQuantity']) {
      inventoryToDisplay = inventoryToDisplay.filter(item => {
        return item.on_hand > 0;
      });
    }

    if (newFilters['equipmentName'] && newFilters['equipmentName'].length > 0) {
      inventoryToDisplay = inventoryToDisplay.filter(item => {
        return item.equipment.get('name').toLowerCase().includes(newFilters['equipmentName'].toLowerCase());
      });
    }

    this.items = inventoryToDisplay;
  }), _dec21 = Ember._action, _dec22 = Ember._action, _dec23 = Ember._action, _dec24 = Ember._action, _dec25 = Ember._action, _dec26 = Ember._action, (_class = (_temp = class AdminPerformInventory extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "inventoryService", _descriptor3, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor4, this);

      _initializerDefineProperty(this, "stFiltersService", _descriptor5, this);

      _initializerDefineProperty(this, "inventoryList", _descriptor6, this);

      _initializerDefineProperty(this, "getUser", _descriptor7, this);

      _initializerDefineProperty(this, "selectedUser", _descriptor8, this);

      _initializerDefineProperty(this, "selectAllRows", _descriptor9, this);

      _initializerDefineProperty(this, "items", _descriptor10, this);

      _initializerDefineProperty(this, "showNotification", _descriptor11, this);

      _initializerDefineProperty(this, "hideSubmit", _descriptor12, this);

      _initializerDefineProperty(this, "openModal", _descriptor13, this);

      _initializerDefineProperty(this, "filterBase", _descriptor14, this);

      _initializerDefineProperty(this, "filterQuantity", _descriptor15, this);

      _initializerDefineProperty(this, "equipmentName", _descriptor16, this);

      _initializerDefineProperty(this, "inventoryListTask", _descriptor17, this);

      _initializerDefineProperty(this, "submitPhysicalInventoryTask", _descriptor18, this);

      _initializerDefineProperty(this, "clearFilter", _descriptor19, this);

      _initializerDefineProperty(this, "filterListTask", _descriptor20, this);

      this.GroupedEquipment = new _groupedEquipment.default(...arguments);
    }

    get physicalInventoryNotice() {
      return this.intl.t('pages.administration.company_settings.inventory.physical_inventory.description', {
        link: 'https://answers.securitytrax.com/Support/Company_and_Admin/Inventory_Management/Physical_Inventory'
      });
    }

    get groupedItems() {
      return this.GroupedEquipment.groupedItemsTask.perform(this.items);
    }

    get totalOnHand() {
      var totalOnHand = 0;
      this.items.forEach(function (value) {
        totalOnHand = totalOnHand + parseInt(value.on_hand);
      });
      return totalOnHand;
    }

    get totalValue() {
      var totalValue = 0;
      this.items.forEach(function (value) {
        totalValue = totalValue + parseFloat(value.value);
      });
      return totalValue;
    }

    get totalOnOrder() {
      var totalOnOrder = 0;
      this.items.forEach(function (value) {
        totalOnOrder = totalOnOrder + parseFloat(value.on_order);
      });
      return totalOnOrder;
    }

    get totalOnTransfer() {
      var totalOnTransfer = 0;
      this.items.forEach(function (value) {
        totalOnTransfer = totalOnTransfer + parseInt(value.on_transfer_quantity);
      });
      return totalOnTransfer;
    }

    get totalPreallocated() {
      var totalPreallocated = 0;
      this.items.forEach(function (value) {
        totalPreallocated = totalPreallocated + parseInt(value.pre_allocated_quantity);
      });
      return totalPreallocated;
    }

    get totalDifference() {
      var totalDifference = 0;
      this.items.forEach(function (value) {
        totalDifference = totalDifference + parseInt(value.difference);
      });
      return totalDifference;
    }

    get users() {
      return this.inventoryService.get('getInventoryRecipients').perform();
    }

    get reviewButtons() {
      return [{
        text: this.intl.t('common.actions.cancel'),
        click: () => {
          this.openModal = false;
        },
        classNamesString: 'st-btn btn-secondary inventory-cancel-button'
      }, {
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.submitPhysicalInventoryTask.perform(buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary inventory-submit-button'
      }];
    }

    getInventory() {
      if (this.selectedUser) {
        this.items = null;
        this.inventoryList = null;
        this.getUser = true;
        this.inventoryListTask.perform();
      }

      return 0;
    }

    updateCounts(row, checkbox) {
      if (checkbox) {
        if (row.updateCount) {
          var input = (0, _jquery.default)('[data-test-equipment-item=' + row.id + '] .number-input__input');
          input.focus();
          input.select();
        } else {
          if (row.quantity > 0) {
            row.set('quantity', 0);
          }
        }
      } else {
        if (!row.updateCount && row.quantity > 0) {
          row.set('updateCount', true);
        }
      }
    }

    selectAll() {
      var toggleVal = this.selectAllRows;
      this.items.forEach(function (value) {
        if (!toggleVal) {
          value.set('quantity', 0);
        }

        value.set('updateCount', toggleVal);
      });
    }

    cancelInventory() {
      this.filterBase = false;
      this.filterQuantity = false;
      this.selectAllRows = false;
      var equipmentList = this.items;
      equipmentList.forEach(function (equipmentItem) {
        equipmentItem.set('updateCount', false);
        equipmentItem.set('quantity', 0);
      });
      this.items = this.inventoryList;
    }

    startReview() {
      this.openModal = true;
    }

    toggleCollapsed(group) {
      group.set('collapsed', !group.collapsed);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "inventoryService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "stFiltersService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "inventoryList", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "getUser", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "selectedUser", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "selectAllRows", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "items", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "showNotification", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "hideSubmit", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "openModal", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "filterBase", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "filterQuantity", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "equipmentName", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "inventoryListTask", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "submitPhysicalInventoryTask", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "clearFilter", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "filterListTask", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getInventory", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "getInventory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateCounts", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "updateCounts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectAll", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "selectAll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelInventory", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "cancelInventory"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startReview", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "startReview"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleCollapsed", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "toggleCollapsed"), _class.prototype)), _class));
  _exports.default = AdminPerformInventory;
});