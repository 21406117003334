define("securitytrax-ember-ui/components/pages/administration/company-settings/content-management/dealer-numbers/component", ["exports", "@glimmer/component", "ember-concurrency", "securitytrax-ember-ui/mixed-in-classes/table-column-sort"], function (_exports, _component, _emberConcurrency, _tableColumnSort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var DealerNumbers = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember.computed, _dec12 = (0, _emberConcurrency.task)(function* () {
    var newFilters = yield this.stFiltersService.getFiltersByGroupKey('companyDealerNumbers');
    var dealerNumbers = yield this.store.query('dealerNumber', {
      filter: newFilters,
      limit: this.limit,
      page: this.page,
      sort: this.TableColumnSort.stSortString,
      count: 1
    });
    Ember.set(this, 'totalCount', dealerNumbers.meta.record_count);
    return dealerNumbers;
  }), _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, (_class = (_temp = class DealerNumbers extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "stFiltersService", _descriptor3, this);

      _initializerDefineProperty(this, "dealerNumber", _descriptor4, this);

      _initializerDefineProperty(this, "dealerNumberFormStarted", _descriptor5, this);

      _initializerDefineProperty(this, "dealerNumberFormMode", _descriptor6, this);

      _initializerDefineProperty(this, "limit", _descriptor7, this);

      _initializerDefineProperty(this, "page", _descriptor8, this);

      _initializerDefineProperty(this, "totalCount", _descriptor9, this);

      _initializerDefineProperty(this, "dealerNumberStatus", _descriptor10, this);

      _initializerDefineProperty(this, "getDealerNumbersTask", _descriptor11, this);

      var sortableColumns = [{
        name: this.intl.t('common.columns.name'),
        value: 'name'
      }, {
        name: this.intl.t('common.columns.id'),
        value: 'id'
      }, {
        name: this.intl.t('common.columns.number'),
        value: 'number'
      }];
      this.TableColumnSort = new _tableColumnSort.default(...arguments, sortableColumns);
    }

    get dealerNumbers() {
      return this.getDealerNumbersTask.perform();
    }

    get dealerNumberFormSteps() {
      var viewOrAdd = this.dealerNumberFormMode === 'create' ? 'create' : 'view';
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.intl.t("common.forms.descriptive_title_".concat(viewOrAdd), {
            id: this.dealerNumber ? this.dealerNumber.get('id') : null,
            name: this.dealerNumber ? this.dealerNumber.get('name') : this.intl.t('common.columns.dealer_number')
          }),
          stepComponentPath: 'multi-step-form/forms/administration/content-management/edit-dealer-number'
        })
      });
    }

    get dealerNumberFormOriginalModels() {
      return Ember.Object.create({
        dealerNumber: {
          model: this.dealerNumber
        }
      });
    }

    sortList(column, ascending) {
      this.TableColumnSort.sortPaginatedList(column, ascending);
      Ember.notifyPropertyChange(this, 'dealerNumbers');
    }

    mobileSortList(column) {
      this.TableColumnSort.sortMobilePaginatedList(column.value);
      Ember.notifyPropertyChange(this, 'dealerNumbers');
    }

    filterSearch(setPage = true) {
      if (setPage) {
        Ember.set(this, 'page', 1);
      }

      Ember.notifyPropertyChange(this, 'dealerNumbers');
    }

    clearFilters() {
      Ember.setProperties(this, {
        page: 1,
        dealerNumberStatus: undefined
      });
      Ember.notifyPropertyChange(this, 'dealerNumbers');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stFiltersService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dealerNumber", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "dealerNumberFormStarted", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "dealerNumberFormMode", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'edit';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "limit", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 50;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "totalCount", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "dealerNumberStatus", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "dealerNumbers", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "dealerNumbers"), _class.prototype), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "getDealerNumbersTask", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "sortList", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "sortList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mobileSortList", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "mobileSortList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterSearch", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "filterSearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilters", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilters"), _class.prototype)), _class));
  _exports.default = DealerNumbers;
});