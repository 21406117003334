define("securitytrax-ember-ui/components/multi-step-form/forms/invoices/recurring-invoice/duplicate/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/invoices/recurring-invoice/details/component", "ember-concurrency", "moment"], function (_exports, _component, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var RecurringInvoiceDuplicate = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var originalId = this.args.multiStepForm.formOriginalModelsGetVal('invoice.model.id');
    yield this.saveInvoiceTask.perform(buttonPromise, true, true);
    this.stNotificationManager.addSuccessKey('common.notifications.duplication_successful', 'global');

    if (this.deleteOriginal) {
      try {
        yield (0, _emberConcurrency.timeout)(500);
        var invoice = yield this.store.findRecord('recurringInvoice', originalId, {
          reload: true
        });
        yield invoice.destroyRecord();
        this.stNotificationManager.addSuccessKey('common.notifications.original_deleted');
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'model'
        });
        throw err;
      }
    }

    this.closeForm();
  }), (_class = (_temp = class RecurringInvoiceDuplicate extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "grants", _descriptor4, this);

      _initializerDefineProperty(this, "deleteOriginal", _descriptor5, this);

      _initializerDefineProperty(this, "duplicateRecurringInvoiceTask", _descriptor6, this);

      this.SwitchableFormStep.progressBarHidden = true;
      var buttons = [];
      buttons.push({
        text: this.intl.t('common.actions.cancel'),
        classNamesString: 'st-btn st-btn-secondary',
        click: () => {
          this.args.multiStepForm.setupCopyModels.perform();
          Ember.set(this.args.multiStepForm, 'currentStepNameKey', 'details');
        }
      });
      buttons.push({
        text: this.intl.t('common.actions.duplicate'),
        classNamesString: 'st-btn st-btn-primary',
        click: buttonPromise => {
          return this.duplicateRecurringInvoiceTask.perform(buttonPromise);
        }
      });
      this.args.multiStepForm.registerStepData('duplicate', 'buttons', buttons);
      this.lockedState = 'Open';

      if (this.args.multiStepForm.formCopiedModels.invoice.model.saleDate.isBefore((0, _moment.default)())) {
        Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.saleDate', (0, _moment.default)().add(1, 'days').startOf('day'));
        Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.nextDate', (0, _moment.default)().startOf('day'));
      }

      this.args.multiStepForm.formCopiedModels.invoice.model.invoiceLineItems.forEach(lineItem => {
        Ember.set(lineItem, 'id', null);
      });
      Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.id', null);
      Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.status', 'active');
    }

    get hiddenProgressBarSteps() {
      return [];
    }

    get disabledProgressBarSteps() {
      return [];
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "deleteOriginal", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "duplicateRecurringInvoiceTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = RecurringInvoiceDuplicate;
});