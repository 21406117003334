define("securitytrax-ember-ui/validations/customer-credit-check", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validationData;

      if (data.extraValidationData.runCredit === true) {
        validationData = (0, _validate.default)(this.objectToJson(this), {
          creditBureau: {
            presence: this.customMessageOrDefault(data, 'customerCreditCheck-creditBureau')
          },
          overrideReason: {
            presence: () => {
              if (data.extraValidationData.brinksOverride) {
                return this.customMessageOrDefault(data, 'customerCreditCheck-overrideReason');
              } else {
                return false;
              }
            }
          }
        }, {
          fullMessages: this.prependProperty(data)
        });
      } else if (data.extraValidationData.runCredit === false) {
        validationData = (0, _validate.default)(this.objectToJson(this), {
          score: {
            presence: this.customMessageOrDefault(data, 'customerCreditCheck-score'),
            format: {
              pattern: /\d{1,5}/
            }
          },
          manualBureau: {
            presence: this.customMessageOrDefault(data, 'customerCreditCheck-manualBureau')
          }
        }, {
          fullMessages: this.prependProperty(data)
        });
      }

      return this.outputFormatting(validationData, 'customerCreditCheck');
    }

  });

  _exports.default = _default;
});