define("securitytrax-ember-ui/customers/equipment/route", ["exports", "securitytrax-ember-ui/customers/base-route", "ember-concurrency", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _baseRoute, _emberConcurrency, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_routePageviewLogging.default, {
    grants: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    userService: Ember.inject.service(),
    quickAdd: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['customer_equipment.view']);
    },

    model: function () {
      var {
        customer
      } = this._super(...arguments);

      return this.setupModelTask.perform(customer);
    },
    setupModelTask: (0, _emberConcurrency.task)(function* (customer) {
      var grants = this.grants;
      var store = this.store;
      var customerSystemInfo = null;

      try {
        customerSystemInfo = (yield grants.get('getIndividualGrantAsync').perform('customer_system_information.view')) ? yield store.findRecord('customerSystemInformation', customer.get('id')) : null;
      } catch (e) {// Do nothing
      }

      return Ember.RSVP.hash({
        customer,
        customerEquipment: customer.get('customerEquipment'),
        // PERF TODO: Need to be able to includes here or something with that likeness
        customerSystemInformation: customerSystemInfo,
        equipment: store.query('equipment', {
          include: 'equipment_type',
          limit: 0,
          sort: 'name'
        }),
        equipmentStatus: store.findAll('equipmentStatus')
      });
    }),
    customerController: Ember.computed(function () {
      return this.controllerFor('customers');
    }),

    setupBreadCrumbs() {
      var breadCrumbs = [{
        name: this.intl.t('pages.customers.equipment.title'),
        previousLink: 'customers',
        last: true
      }];
      var breadCrumbData = {
        titleLink: 'customers.view',
        hide: false
      };
      this.customerController.set('breadCrumbs', breadCrumbs);
      this.customerController.set('breadCrumbData', breadCrumbData);
    },

    actions: {
      didTransition() {
        this.setupBreadCrumbs();
        this.quickAdd.set('showQuickAdd', true);
        this.quickAdd.set('page', 'customer');
      }

    }
  });

  _exports.default = _default;
});