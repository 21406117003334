define("securitytrax-ember-ui/mixins/format-appointment-mixin", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    grants: Ember.inject.service(),
    formattedAppointments: Ember.computed('customer.calendarEvents', function () {
      return this.formattedAppointmentsTask.perform();
    }),
    formattedAppointmentsTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true);
      var calendarEvents = yield this.customer.get('calendarEvents');
      var formattedCalendarEvents = [];

      for (var i = 0; i < calendarEvents.get('length'); i++) {
        var event = calendarEvents.objectAt(i);
        var hashedData = yield (0, _emberConcurrency.hash)({
          user: yield event.get('calendarUser'),
          calendarEventType: yield event.get('calendarEventType')
        });

        if (hashedData.calendarEventType && hashedData.calendarEventType.get('calendarCategory') === 'tech_schedule') {
          var calendarEventName = yield hashedData.calendarEventType.get('name');
          var calendarEventDate = (0, _moment.default)(event.get('startTime')).format('M/DD/YYYY h:mm A');
          var userName = hashedData.user ? ' | ' + hashedData.user.get('flname') : '';
          event.set('userDateTime', "".concat(calendarEventName, " ").concat(calendarEventDate, " ").concat(userName));
          formattedCalendarEvents.push(event);
        }
      }

      return formattedCalendarEvents;
    })
  });

  _exports.default = _default;
});