define("securitytrax-ember-ui/components/multi-step-form/forms/administration/content-management/edit-customer-workflows/component", ["exports", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "ember-concurrency", "lodash"], function (_exports, _component, _switchableFormStep, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EditCustomerWorkflows = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service('events/dynamic-form-chooser'), _dec5 = Ember.inject.service('events/workflows-event'), _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = (0, _emberConcurrency.task)(function* () {
    this.getActiveWorkFlowsTask.perform();

    if (this.SwitchableFormStep.isNewRecord) {
      var multiStepForm = this.args.multiStepForm;
      yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);
      multiStepForm.formCopiedModelsSetVal('workFlow.model.active', true);
    } else {
      yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
      this.leadConvert = _lodash.default.get(this.args.multiStepForm, 'formCopiedModels.workFlow.model.config.lead_to_customer', false);
    }
  }), _dec11 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    if (this.SwitchableFormStep.isNewRecord) {
      var toCreateObjects = yield multiStepForm.validateCreateAll();
      var workFlow = this.store.createRecord('workFlow', toCreateObjects.workflow.data);
      yield toCreateObjects.workFlow.saveWithRollbackAndNotification(workFlow);
    } else {
      yield multiStepForm.validateSyncSaveAll();
    }

    buttonPromise.then(() => {
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
      multiStepForm.formStartedSetVal(false);
      this.workflowsEvent.trigger('refresh-list');
      this.dynamicFormChooser.trigger('setup-dynamic-form-chooser');
    });
  }), _dec12 = (0, _emberConcurrency.task)(function* () {
    var workFlows = yield this.store.findAll('workflowConfig', {
      include: 'workflow'
    });
    var workFlowConfig = workFlows.findBy('id', this.args.multiStepForm.formOriginalModelsGetVal('workFlow.model.id'));
    var activeWorkFlows = workFlows.filter(workFlow => {
      return workFlow.get('active') && workFlow.get('workflow.workflowType') === workFlowConfig.get('workflow.workflowType');
    });

    if (activeWorkFlows.length === 1) {
      this.toggleDisabled = true;
    }
  }).on('init'), _dec13 = Ember._action, _dec14 = Ember._action, (_class = (_temp = class EditCustomerWorkflows extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "dynamicFormChooser", _descriptor4, this);

      _initializerDefineProperty(this, "workflowsEvent", _descriptor5, this);

      _initializerDefineProperty(this, "overrideName", _descriptor6, this);

      _initializerDefineProperty(this, "leadConvert", _descriptor7, this);

      _initializerDefineProperty(this, "toggleDisabled", _descriptor8, this);

      _initializerDefineProperty(this, "hasNotToggled", _descriptor9, this);

      _initializerDefineProperty(this, "initFormData", _descriptor10, this);

      _initializerDefineProperty(this, "saveWorkFlow", _descriptor11, this);

      _initializerDefineProperty(this, "getActiveWorkFlowsTask", _descriptor12, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.initFormData.perform();
      Ember.run.later(() => {
        this.renderButtons();
      });
    }

    renderButtons() {
      var multiStepForm = this.args.multiStepForm;
      var buttons = [];

      if (this.args.formMode !== 'view') {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveWorkFlow.perform(multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary customer-cancel-reason-save-button'
        });
      }

      multiStepForm.registerStepData('step1', 'buttons', buttons);
    }

    toggleOverride() {
      this.overrideName = !this.overrideName;
    }

    toggleLeadConvert() {
      _lodash.default.set(this.args.multiStepForm, 'formCopiedModels.workFlow.model.config.lead_to_customer', this.leadConvert);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dynamicFormChooser", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "workflowsEvent", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "overrideName", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "leadConvert", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "toggleDisabled", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "hasNotToggled", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "initFormData", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "saveWorkFlow", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "getActiveWorkFlowsTask", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleOverride", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "toggleOverride"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleLeadConvert", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "toggleLeadConvert"), _class.prototype)), _class));
  _exports.default = EditCustomerWorkflows;
});