define("securitytrax-ember-ui/components/multi-step-form/progress-bar/component", ["exports", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    globallyDisabled: undefined,
    loadedNamespace: undefined,
    stAwait: Ember.inject.service('st-await'),

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        loadedNamespace: 'global',
        globallyDisabled: false
      }); /////////////////////////////////////////////////////////////////////////
      // see if we should disable the side nav because of fully loaded logic

      var stAwait = this.stAwait;
      var fullyLoadedState = stAwait.getCurrentFullyLoadedStateByNamespace({
        loadedNamespace: this.loadedNamespace
      });

      if (this && !this.isDestroyed) {
        this.set('globallyDisabled', !fullyLoadedState.fullyLoaded);
      }

      stAwait.on('st-await-loaded-state-by-namespace-' + this.loadedNamespace, this, '_onLoadedStateChange');
    },

    willDestroyElement() {
      this._super(...arguments);

      this.stAwait.off('st-await-loaded-state-by-namespace-' + this.loadedNamespace, this, '_onLoadedStateChange');
    },

    _onLoadedStateChange(loadedData) {
      Ember.run.next(() => {
        if (this && !this.isDestroyed) {
          this.set('globallyDisabled', !loadedData.fullyLoaded);
        }
      });
    },

    viewComponentPath: Ember.computed('multiStepForm', function () {
      return 'multi-step-form/progress-bar/views/' + this.get('config.viewTemplate');
    })
  });

  _exports.default = _default;
});