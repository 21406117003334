define("securitytrax-ember-ui/accounting/invoices/edit-invoice/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/subroute-modal", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _authenticatedRouteMixin, _subrouteModal, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _subrouteModal.default, _routePageviewLogging.default, {
    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['customer_invoices.view']);
    },

    model({
      iid
    }) {
      return Ember.RSVP.hash({
        customerInvoiceId: iid,
        parentRoute: this.transitionRoute
      });
    }

  });

  _exports.default = _default;
});