define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/nrg/autopay-setup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7F+aSe40",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[4,[38,0],[[32,0,[\"setupButtons\"]]],null],[12],[2,\"\\n    \"],[11,\"div\"],[17,1],[12],[2,\"\\n        \"],[8,\"multi-step-form/progress-bar\",[],[[\"@config\",\"@modalButtons\",\"@loadedNamespaces\"],[[34,1,[\"progressBarConfig\"]],[34,1,[\"currentStepData\",\"buttons\"]],[30,[36,3],[[35,2],\"step10\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"html/st-await\",[],[[\"@booleanLoaded\",\"@useSpinner\",\"@size\"],[[34,4,[\"isIdle\"]],true,\"medium\"]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[10,\"div\"],[14,0,\"st-card__cell\"],[12],[2,\"\\n                    \"],[10,\"div\"],[14,0,\"nrg-instruction\"],[12],[2,\"\\n                        \"],[2,[30,[36,5],[\"pages.customers.nrg.autopay_setup.instruction\"],null]],[2,\"\\n                    \"],[13],[2,\"\\n                    \"],[10,\"div\"],[14,0,\"grid-x grid-padding-x\"],[12],[2,\"\\n                        \"],[10,\"span\"],[14,0,\"cell nrg-button\"],[12],[2,\"\\n                            \"],[8,\"html/st-button\",[[24,0,\"st-btn st-btn-primary\"]],[[\"@clickAction\",\"@text\",\"@icon\"],[[30,[36,6],[[32,0],\"openNRGTab\"],null],[30,[36,5],[\"pages.customers.nrg.autopay_setup.button\"],null],\"icn-new-window\"]],null],[2,\"\\n                        \"],[13],[2,\"\\n                    \"],[13],[2,\"\\n                    \"],[8,\"multi-step-form/forms/user-defined-fields/udf-list\",[],[[\"@adminUdfObjs\",\"@formMode\",\"@multiStepForm\",\"@sectionTitle\"],[[34,7,[\"udfData\"]],[34,8],[34,1],[30,[36,5],[\"pages.customers.user-defined-fields.title\"],null]]],null],[2,\"\\n                \"],[13],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"multiStepForm\",\"currFormName\",\"concat\",\"initTask\",\"t\",\"action\",\"UdfClass\",\"formMode\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/nrg/autopay-setup/template.hbs"
    }
  });

  _exports.default = _default;
});