define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/edit-create-credit-bureau/trans-union-canada/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    canadianLanguages: null,

    init() {
      this._super(...arguments);

      var canadianLanguages = [{
        value: 'English',
        name: 'English'
      }, {
        value: 'French',
        name: 'French'
      }];
      this.set('canadianLanguages', canadianLanguages);
    }

  });

  _exports.default = _default;
});