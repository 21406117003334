define("securitytrax-ember-ui/models/equipment-return-merchandise-authorization", ["exports", "securitytrax-ember-ui/models/base_models/equipment-return-merchandise-authorization", "securitytrax-ember-ui/mixins/pending-equipment-reusable"], function (_exports, _equipmentReturnMerchandiseAuthorization, _pendingEquipmentReusable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _equipmentReturnMerchandiseAuthorization.default.extend(_pendingEquipmentReusable.default, {});

  _exports.default = _default;
});