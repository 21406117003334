define("securitytrax-ember-ui/models/base_models/parent-entity-payment", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    paymentDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    clearedDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    postedDate: (0, _model.attr)('date-only', {
      'readOnly': true
    }),
    desiredPostingDate: (0, _model.attr)('date-only', {
      'readOnly': true
    }),
    emailWhenProcessed: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    receiptEmails: (0, _model.attr)('string', {
      'readOnly': false
    }),
    expectedClearingDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    amount: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    tax: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    notes: (0, _model.attr)('string', {
      'readOnly': false
    }),
    cleared: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    status: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['active', 'deleted']
    }),
    parentEntityBillingId: (0, _model.attr)('number', {
      'readOnly': false
    }),
    transactionId: (0, _model.attr)('number-string', {
      'readOnly': true
    }),
    authcode: (0, _model.attr)('string', {
      'readOnly': false
    }),
    checkNumber: (0, _model.attr)('string', {
      'readOnly': false
    }),
    saleType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['sale', 'credit', 'refund']
    }),
    externalSource: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['payment_portal', 'adc_partner_services']
    }),
    isRefunded: (0, _model.attr)('boolean', {
      'readOnly': true
    }),
    isVoided: (0, _model.attr)('boolean', {
      'readOnly': true
    }),
    processedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    processingStatus: (0, _model.attr)('string', {
      'readOnly': false
    }),
    processingStatusSubmitted: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    parentEntity: (0, _model.belongsTo)('parent-entity', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    parentEntityPaymentType: (0, _model.belongsTo)('parent-entity-payment-type', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    processedByUser: (0, _model.belongsTo)('user', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});