define("securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/dealer-ally/review/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/base-components/review/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var DealerAllyCreateFinancingReview = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
    var application = this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model');
    this.args.multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.salesTaxRate', Number(application.get('salesTax')) / Number(application.get('sellingPrice')) * 100);
  }), _dec4 = (0, _emberConcurrency.task)(function* (modelsList) {
    var approvedList = yield [];

    for (var index = 0; index < modelsList.length; index++) {
      var currApplication = modelsList.objectAt(index);

      if (currApplication.get('id') === this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.id')) {
        if (currApplication.get('applicationState') === 'approved') {
          approvedList.push(currApplication);
        } else {
          var getStatusResult = (yield currApplication.invokeAction('get_status', {
            // This should be okay because should only ever happen once 
            verb: 'POST',
            data: {
              data: {}
            }
          })).responsePayload.data;

          if (getStatusResult.get_application_status.Status === 'FilledOut' || getStatusResult.get_application_status.Status === 'Processing') {
            this.processing = true; // If we get here we need to ensure that the tab that didn't submit application gets waiting modal
          } else if (getStatusResult.get_application_status.Status === 'Error') {
            this.args.multiStepForm.formStartedSetVal(false);
            this.stNotificationManager.addError(this.intl.t('pages.customers.accounting.financing.application_error', {
              lender: currApplication.get('consumerFinancingLender.consumerFinancingLenderType.name'),
              phone: '(888) 326-0301'
            }));
          }
        }
      }
    }

    return approvedList;
  }), _dec5 = (0, _emberConcurrency.task)(function* (data) {
    if (data) {
      this.processing = false;
      this.args.multiStepForm.currentStepNameKeySetVal('decision');
    } else {
      var currApplication = this.args.multiStepForm.formOriginalModelsGetVal('consumerFinancingApplication.model');
      var getStatusResult = (yield currApplication.invokeAction('get_status', {
        verb: 'POST',
        data: {
          data: {}
        }
      })).responsePayload.data; // Fallback for if we receive no message over the websocket about an update to the application

      if (getStatusResult.get_application_status.Status !== 'Processing' && getStatusResult.get_application_status.Status !== 'Error') {
        yield currApplication.reload();
        this.processing = false;
        this.args.multiStepForm.currentStepNameKeySetVal('decision');
      } else if (getStatusResult.get_application_status.Status === 'Error') {
        this.args.multiStepForm.formStartedSetVal(false);
        this.stNotificationManager.addError(this.intl.t('pages.customers.accounting.financing.application_error', {
          lender: currApplication.get('consumerFinancingLender.consumerFinancingLenderType.name'),
          phone: '(888) 326-0301'
        }));
      } else {
        this.stNotificationManager.addSuccessKey('pages.customers.accounting.financing.application_still_processing');
        this.args.multiStepForm.formStartedSetVal(false);
      }
    }

    yield this.args.multiStepForm.setupCopyModels.perform();
    this.timer.stop();
  }), (_class = (_temp = class DealerAllyCreateFinancingReview extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor3, this);

      _initializerDefineProperty(this, "nextStepTask", _descriptor4, this);

      _initializerDefineProperty(this, "callbackTask", _descriptor5, this);

      this.initDataTask.perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "nextStepTask", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "callbackTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = DealerAllyCreateFinancingReview;
});