define("securitytrax-ember-ui/validations/base-validation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    validate() {
      throw new Error('You must override the validate function');
    },

    prependProperty: function (data) {
      return !('validationMessages' in data.extraValidationData);
    },
    customMessage: function (data, propertyName) {
      if ('extraValidationData' in data && 'validationMessages' in data.extraValidationData && propertyName in data.extraValidationData.validationMessages) {
        return data.extraValidationData.validationMessages[propertyName];
      } else {
        return null;
      }
    },
    checkValidateCustomMessageDefault: function (data, propertyName) {
      if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes(propertyName)) {
        return false;
      } else {
        return this.customMessageOrDefault(data, propertyName);
      }
    },
    //TODO: allowEmpty is hacky here as it is applying to validators that are not 'presence' as well
    customMessageOrDefault: function (data, propertyName) {
      if ('extraValidationData' in data && 'validationMessages' in data.extraValidationData && propertyName in data.extraValidationData.validationMessages) {
        return {
          message: data.extraValidationData.validationMessages[propertyName],
          allowEmpty: false
        };
      } else {
        return {
          allowEmpty: false
        };
      }
    },

    objectToJson(object) {
      var result = {};

      for (var key in $.extend(true, {}, object)) {
        // Skip these
        if (key === 'isInstance' || key === 'isDestroyed' || key === 'mergedProperties' || key === 'isDestroying' || key === 'concatenatedProperties' || typeof this[key] === 'function') {
          continue;
        }

        result[key] = object[key];
      }

      this.emptyObjectsToNull(result);
      return result;
    },

    emptyObjectsToNull(object) {
      Object.keys(object).forEach(currChildKey => {
        if (this.isEmpty(object[currChildKey])) {
          object[currChildKey] = null;
        }
      });
      return object;
    },

    isEmpty(obj) {
      if (typeof obj != 'object') {
        return false;
      }

      for (var key in obj) {
        if (key !== 'enumLists' && obj.hasOwnProperty(key)) return false;
      }

      return true;
    },

    outputFormatting: function (validationData, modelName) {
      if (!validationData) {
        return {};
      }

      var formattedValidations = {};
      Object.keys(validationData).forEach(key => {
        validationData[key].forEach((arrKey, arrIndex) => {
          if (!formattedValidations[modelName + '-' + key]) {
            formattedValidations[modelName + '-' + key] = [];
          }

          formattedValidations[modelName + '-' + key][arrIndex] = {
            message: validationData[key][arrIndex]
          };
        });
      });
      return formattedValidations;
    }
  });

  _exports.default = _default;
});