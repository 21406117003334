define("securitytrax-ember-ui/utils/strings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.endsWith = endsWith;
  _exports.beginsWith = beginsWith;
  _exports.camelAllCapsToSnake = camelAllCapsToSnake;
  _exports.snakeToCamelCase = snakeToCamelCase;
  _exports.ucwords = ucwords;
  _exports.nl2br = nl2br;

  /**
   * String utility module.
   * @module
   */

  /**
   * Tests whether the supplied string ends with the supplied pattern.
   *
   * @param {String} string The string to test.
   * @param {String} pattern The pattern to test for.
   *
   * @returns {boolean}
   */
  function endsWith(string, pattern) {
    return new RegExp("".concat(pattern, "$")).test(string);
  }
  /**
   * Tests whether the supplied string begins with the supplied pattern.
   *
   * @param {String} string The string to test.
   * @param {String} pattern The pattern to test for.
   *
   * @returns {boolean}
   */


  function beginsWith(string, pattern) {
    return new RegExp("^".concat(pattern)).test(string);
  }
  /**
   * Convert camel-cased strings to snake-cased.
   * Account for *all* caps ie: 'mmrALaCarte' -> mmr_a_la_carte instead of mmr_ala_carte
   *
   * @param {*} string
   */


  function camelAllCapsToSnake(string) {
    var upperChars = string.match(/([A-Z])/g);

    if (!upperChars) {
      return string;
    }

    var str = string.toString();

    for (var i = 0, n = upperChars.length; i < n; i++) {
      str = str.replace(new RegExp(upperChars[i]), '_' + upperChars[i].toLowerCase());
    }

    if (str.slice(0, 1) === '_') {
      str = str.slice(1);
    }

    return str;
  }

  function snakeToCamelCase(stringToConvert) {
    return stringToConvert.replace(/_\w/g, m => m[1].toUpperCase());
  }

  function ucwords(stringToConvert, splitChar) {
    var arr = stringToConvert.split(splitChar);
    arr.forEach((substr, index) => {
      arr[index] = substr.capitalize();
    });
    return arr.join(' ');
  }
  /**
   *
   * @param {*} str
   * @param {*} is_xhtml
   */


  function nl2br(str, is_xhtml) {
    // http://kevin.vanzonneveld.net
    // +   original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // +   improved by: Philip Peterson
    // +   improved by: Onno Marsman
    // +   improved by: Atli Þór
    // +   bugfixed by: Onno Marsman
    // +      input by: Brett Zamir (http://brett-zamir.me)
    // +   bugfixed by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
    // +   improved by: Brett Zamir (http://brett-zamir.me)
    // +   improved by: Maximusya
    // *     example 1: nl2br('Kevin\nvan\nZonneveld');
    // *     returns 1: 'Kevin<br />\nvan<br />\nZonneveld'
    // *     example 2: nl2br("\nOne\nTwo\n\nThree\n", false);
    // *     returns 2: '<br>\nOne<br>\nTwo<br>\n<br>\nThree<br>\n'
    // *     example 3: nl2br("\nOne\nTwo\n\nThree\n", true);
    // *     returns 3: '<br />\nOne<br />\nTwo<br />\n<br />\nThree<br />\n'
    var breakTag = is_xhtml || typeof is_xhtml === 'undefined' ? '<br ' + '/>' : '<br>'; // Adjust comment to avoid issue on phpjs.org display

    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
  }
});