define("securitytrax-ember-ui/components/calendar-glimmer/calendar-grid/calendar-list-view/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2U+2TfpM",
    "block": "{\"symbols\":[\"row\",\"index\",\"@userType\",\"@showListView\",\"@showInactiveUsers\",\"@gridIndex\"],\"statements\":[[8,\"html/st-await\",[],[[\"@loadedNamespace\",\"@useSpinner\",\"@booleanLoaded\"],[\"calendar-grid\",true,[34,0,[\"isIdle\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"css-grid-list__container\"],[12],[2,\" \\n        \"],[11,\"div\"],[24,0,\"css-grid-list__scroll-area\"],[16,1,[31,[\"calendar-main-\",[32,6]]]],[4,[38,1],[[32,0,[\"bindHorizontalScrolling\"]]],null],[12],[2,\"\\n    \\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"calendarEventRows\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[8,\"calendar-glimmer/calendar-grid/calendar-grid-row-list-view\",[],[[\"@calendarEvent\",\"@userType\",\"@dryRunReasons\",\"@showListView\",\"@showInactiveUsers\"],[[32,1],[32,3],[32,0,[\"dryRunReasons\"]],[32,4],[32,5]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"css-grid-list__fade--right\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"calendarEventsTask\",\"did-insert\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/calendar-glimmer/calendar-grid/calendar-list-view/template.hbs"
    }
  });

  _exports.default = _default;
});