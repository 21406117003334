define("securitytrax-ember-ui/components/calendar-glimmer/calendar-header/calendar-header-time-cell/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CalendarHeaderTimeRow extends _component.default {
    get timeGridStyles() {
      var indexOffset = this.args.colIndex + 2;
      return Ember.String.htmlSafe('grid-column: ' + indexOffset + ' / span 1;  grid-row: 1 / 2;');
    }

  }

  _exports.default = CalendarHeaderTimeRow;
});