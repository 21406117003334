define("securitytrax-ember-ui/components/html/notification-badge/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userNotificationsService: Ember.inject.service(),
    tagName: '',
    count: null,
    includePlus: null,
    hidden: false,
    isHidden: Ember.computed('hidden', function () {
      if (!this.hidden) {
        return '';
      } else {
        return 'hidden';
      }
    }),
    xPositioning: Ember.computed('count', function () {
      var count = this.count;
      var xPositioning;

      if (count < 10) {
        xPositioning = 0;
      } else if (count < 100) {
        xPositioning = 3;
      } else if (count < 1000) {
        xPositioning = 8;
      } else {
        xPositioning = 18;
      }

      if (this.get('userNotificationsService.maxLimitReceived')) {
        this.set('includePlus', true);
        xPositioning = xPositioning + 4;
      }

      return "badge-position-".concat(xPositioning);
    })
  });

  _exports.default = _default;
});