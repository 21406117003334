define("securitytrax-ember-ui/components/customer/dashboard/customer-profit-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qj1WT2eI",
    "block": "{\"symbols\":[\"currProfitData\"],\"statements\":[[8,\"html/st-card/body\",[[24,0,\"customer-profit-card\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"html/st-await\",[],[[\"@useSpinner\",\"@booleanLoaded\"],[true,[34,0,[\"isIdle\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"customer/dashboard/customer-profit-card/profit-progress-bar\",[],[[\"@currProfitData\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"profitLossDataTask\",\"profitLossData\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/customer/dashboard/customer-profit-card/template.hbs"
    }
  });

  _exports.default = _default;
});