define("securitytrax-ember-ui/components/nav/left-navs/global-settings-left-nav/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qdc+Q7rR",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[8,\"nav/left-nav-link\",[[24,0,\"global-setting-company-link\"]],[[\"@route\"],[\"administration.company-settings.system.global-settings.company\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"icn-group\"],null]],[2,\"\\n    \"],[1,[30,[36,1],[\"common.nav.company\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"nav/left-nav-link\",[[24,0,\"global-setting-notifications-link\"]],[[\"@route\"],[\"administration.company-settings.system.global-settings.notifications\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"icn-notifications\"],null]],[2,\"\\n    \"],[1,[30,[36,1],[\"pages.administration.company_settings.global_settings.notifications.title\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"nav/left-nav-link\",[[24,0,\"global-setting-email-link\"]],[[\"@route\"],[\"administration.company-settings.system.global-settings.email\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"icn-email\"],null]],[2,\"\\n    \"],[1,[30,[36,1],[\"pages.administration.company_settings.global_settings.email.title\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"nav/left-nav-link\",[[24,0,\"global-setting-accounting-link\"]],[[\"@route\"],[\"administration.company-settings.system.global-settings.accounting\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"icn-monies\"],null]],[2,\"\\n    \"],[1,[30,[36,1],[\"pages.administration.company_settings.global_settings.accounting.title\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"svg-icon\",\"t\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/nav/left-navs/global-settings-left-nav/template.hbs"
    }
  });

  _exports.default = _default;
});