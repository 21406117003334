define("securitytrax-ember-ui/components/multi-step-form/forms/invoices/refund-receipt/payments/view-processing-info/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/invoices/base", "moment"], function (_exports, _base, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var ViewPaymentProcessingInfo = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, (_class = (_temp = class ViewPaymentProcessingInfo extends _base.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "paymentService", _descriptor, this);

      _initializerDefineProperty(this, "grants", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);
    }

    get hiddenProgressBarSteps() {
      return ['gettingStarted'];
    }

    get disabledProgressBarSteps() {
      return [];
    }

    get statusUpdated() {
      return (0, _moment.default)(this.args.multiStepForm.currentStepData.get('currentPayment.processingStatusSubmitted')).format('MM/DD/YYYY h:mm A zz');
    }

    setupButtons() {
      var buttonText;
      var buttons = [];
      var currentPayment = this.args.multiStepForm.currentStepData.get('currentPayment');

      if (currentPayment.processingStatus && currentPayment.processingStatus.toLowerCase() === 'complete' && this.grants.getIndividualGrant('customer_payments.refund') && !currentPayment.responseMeta.has_refund_reciept) {
        buttonText = this.intl.t('common.actions.refund');
      } else if (currentPayment.processingStatus && currentPayment.processingStatus.replace(/\s+/g, '').toLowerCase() === 'pendingsettlement' && this.grants.getIndividualGrant('customer_payments.void')) {
        buttonText = this.intl.t('common.actions.void');
      }

      if (buttonText) {
        buttons.push({
          text: buttonText,
          classNamesString: 'st-btn st-btn-warn',
          isDelete: true,
          click: () => {
            this.args.multiStepForm.registerStepData(this.args.multiStepForm.currentStepNameKey.replace('viewProcessingInformation', 'confirmRefundVoid'), 'currentVoidingOrRefundingCustomerPayment', this.args.multiStepForm.currentStepData.get('currentPayment'));
            this.args.multiStepForm.currentStepNameKeySetVal(this.args.multiStepForm.currentStepNameKey.replace('viewProcessingInformation', 'confirmRefundVoid'));
          }
        });
      }

      if (!this.args.singlePage) {
        buttons.push({
          text: this.intl.t('common.actions.back'),
          classNamesString: 'st-btn st-btn-secondary',
          click: () => {
            this.args.multiStepForm.currentStepNameKeySetVal('listPayments::payments');
          }
        });
      }

      this.args.multiStepForm.registerStepData(this.args.multiStepForm.currentStepNameKey, 'buttons', buttons);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "paymentService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupButtons", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "setupButtons"), _class.prototype)), _class));
  _exports.default = ViewPaymentProcessingInfo;
});