define("securitytrax-ember-ui/components/customer/information/central-station-information/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JaNr16kL",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,8],[[30,[36,7],[\"customer_order_information.view\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,6],null,[[\"callbackEditAdtOrderFormStarted\",\"customer\",\"customerSystemInformation\",\"customerOrderInformation\",\"toolbox\",\"subroutePath\",\"closeAction\"],[[35,5],[35,4],[35,3,[\"customerSystemInformation\"]],[35,3,[\"customerOrderInformation\"]],[35,2],[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"closeAction\",\"subroutePath\",\"toolbox\",\"model\",\"customer\",\"callbackEditAdtOrderFormStarted\",\"multi-step-form/forms/customer-central-station-information\",\"has-grants\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/customer/information/central-station-information/template.hbs"
    }
  });

  _exports.default = _default;
});