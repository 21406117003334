define("securitytrax-ember-ui/components/html/drag-over-scroll/component", ["exports", "@glimmer/component", "jquery", "lodash"], function (_exports, _component, _jquery, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var DragOverScroll = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = (_temp = class DragOverScroll extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "scrollContainer", null);

      _defineProperty(this, "horizontalScrollContainer", null);

      _initializerDefineProperty(this, "isDragging", _descriptor, this);

      _initializerDefineProperty(this, "scrollTop", _descriptor2, this);

      _initializerDefineProperty(this, "scrollLeft", _descriptor3, this);

      _initializerDefineProperty(this, "showLeftClass", _descriptor4, this);

      _initializerDefineProperty(this, "showTopClass", _descriptor5, this);

      _initializerDefineProperty(this, "showBottomClass", _descriptor6, this);

      _initializerDefineProperty(this, "showRightClass", _descriptor7, this);

      if (this.args.scrollContainer) {
        this.scrollContainer = this.args.scrollContainer;
      }

      if (this.args.horizontalScrollContainer) {
        this.horizontalScrollContainer = this.args.horizontalScrollContainer;
      } else {
        this.horizontalScrollContainer = this.args.scrollContainer;
      }

      this.scrollTop = (0, _jquery.default)(this.args.scrollContainer).scrollTop();
      this.scrollLeft = (0, _jquery.default)(this.scrollContainer).scrollLeft();
    }

    willDestroy() {
      this.unbindScroll();
    }

    unbindScroll() {
      (0, _jquery.default)(this.scrollContainer).unbind('scroll');
      (0, _jquery.default)(this.horizontalScrollContainer).unbind('scroll');
    }

    bindHorizontalScrolling() {
      var _this = this;

      var horScroll = function () {
        return _this._onHorScroll();
      };

      (0, _jquery.default)(this.horizontalScrollContainer).bind('scroll', _lodash.default.throttle(horScroll, 2));
    }

    _onHorScroll() {
      this.checkClasses();
    }

    bindScrolling() {
      var _this = this;

      var scroll = function () {
        return _this._onScroll();
      };

      (0, _jquery.default)(this.scrollContainer).bind('scroll', _lodash.default.throttle(scroll, 2));
    }

    _onScroll() {
      this.checkClasses();
    }

    checkClasses() {
      if (this.isDragging) {
        this.showTopClass = this.scrollTop > 0 ? 'dragArea__top--show' : 'dragArea__top--hide';
        var scrollContainer = (0, _jquery.default)(this.scrollContainer)[0];
        this.showBottomClass = this.scrollTop + (0, _jquery.default)(this.scrollContainer).innerHeight() >= scrollContainer.scrollHeight ? 'dragArea__bottom--hide' : 'dragArea__bottom--show';
        var horizontalScrollContainer = (0, _jquery.default)(this.horizontalScrollContainer).each;
        this.showLeftClass = this.scrollLeft > 0 ? 'dragArea__left--show' : 'dragArea__left--hide';
        this.showRightClass = this.scrollLeft + (0, _jquery.default)(this.horizontalScrollContainer).innerWidth() >= horizontalScrollContainer.scrollWidth ? 'dragArea__right--hide' : 'dragArea__right--show';
      } else {
        this.showTopClass = 'dragArea__top--hide';
        this.showBottomClass = 'dragArea__bottom--hide';
        this.showLeftClass = 'dragArea__left--hide';
        this.showRightClass = 'dragArea__right--hide';
      }
    }

    get draggingClass() {
      return this.isDragging ? 'dragArea--active' : '';
    }

    dragOverScroll(direction) {
      var scrollContainer = this.scrollContainer;
      var horizontalScrollContainer = this.horizontalScrollContainer;
      this.scrollTop = (0, _jquery.default)(scrollContainer).scrollTop();
      this.scrollLeft = (0, _jquery.default)(horizontalScrollContainer).scrollLeft();
      var scrollLeft = (0, _jquery.default)(horizontalScrollContainer).scrollLeft();
      this.checkClasses();

      if (direction === 'right') {
        scrollLeft += 15;
        (0, _jquery.default)(horizontalScrollContainer).scrollLeft(scrollLeft);
      } else if (direction === 'left') {
        (0, _jquery.default)(horizontalScrollContainer).scrollLeft(this.scrollLeft - 15);
      } else if (direction === 'bottom') {
        (0, _jquery.default)(scrollContainer).scrollTop((0, _jquery.default)(scrollContainer).scrollTop() + 15);
      } else if (direction === 'top') {
        (0, _jquery.default)(scrollContainer).scrollTop((0, _jquery.default)(scrollContainer).scrollTop() - 15);
      }
    }

    dragStarted() {
      this.isDragging = true;
      this.checkClasses();
    }

    dragEnded() {
      this.isDragging = false;
      this.checkClasses();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isDragging", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "scrollTop", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "scrollLeft", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showLeftClass", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showTopClass", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "showBottomClass", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "showRightClass", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "bindHorizontalScrolling", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "bindHorizontalScrolling"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "bindScrolling", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "bindScrolling"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragOverScroll", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "dragOverScroll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragStarted", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "dragStarted"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragEnded", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "dragEnded"), _class.prototype)), _class));
  _exports.default = DragOverScroll;
});