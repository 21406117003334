define("securitytrax-ember-ui/models/invoice", ["exports", "securitytrax-ember-ui/models/base_models/invoice", "moment"], function (_exports, _invoice, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _invoice.default.extend({
    status: Ember.computed.alias('responseMeta.status'),
    isPaid: Ember.computed('responseMeta', function () {
      var isPaid = this.get('responseMeta.due_amount') <= 0;
      var key = isPaid ? 'common.status.yes' : 'common.status.no';
      return this.intl.t(key);
    }),
    paidStatus: Ember.computed('responseMeta', function () {
      var status = this.get('responseMeta.status');
      return {
        'statusString': this.intl.t('pages.customers.accounting.invoices.due') + ': ' + (0, _moment.default)(this.dueDate).format('MM/DD/YYYY'),
        'statusClass': Ember.String.dasherize(status)
      };
    }),
    uniqueTypeAndId: Ember.computed('id', function () {
      return this.id + '_invoice';
    }),
    signedAmount: Ember.computed('invoiceType', 'responseMeta.total_amount', function () {
      if (this.invoiceType === 'refund_receipt') {
        return -this.get('responseMeta.total_amount');
      }

      return this.get('responseMeta.total_amount');
    }),

    get pastDue() {
      var today = (0, _moment.default)();

      if (this.dueDate < today && this.responseMeta.due_amount > 0) {
        return 'invoices--past-due';
      } else {
        return '';
      }
    }
    /** HAS MANY **/

    /** HAS MANY **/

    /** BELONGS TO **/

    /** BELONGS TO **/


  });

  _exports.default = _default;
});