define("securitytrax-ember-ui/components/multi-step-form/forms/administration/organization/edit-office-location/base-component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/switchable-form-step"], function (_exports, _emberConcurrency, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    titleKey: 'pages.administration.organization.officeLocations.title',
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    usStates: Ember.inject.service(),
    inventoryRecipient: false,
    disabledProgressBarSteps: Ember.computed(function () {
      return [];
    }),
    hiddenProgressBarSteps: Ember.computed('showInventorySection', function () {
      var formSteps = this.multiStepForm.formSteps;
      var hiddenSteps = [];

      if (formSteps.get('equipmentDetails.hidden')) {
        hiddenSteps.push('equipmentDetails');
      }

      if (formSteps.get('vendorDetails.hidden')) {
        hiddenSteps.push('vendorDetails');
      }

      return this.showInventorySection ? hiddenSteps : ['equipmentDetails', 'vendorDetails'];
    }),
    showInventorySection: Ember.computed('multiStepForm.formCopiedModels.location.model.inventoryRecipient', function () {
      return this.multiStepForm.formCopiedModelsGetVal('location.model.inventoryRecipient');
    }),
    saveOfficeLocation: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      try {
        var validationObject = {
          deepValidate: false,
          deepCreate: false,
          extraValidationData: this.showInventorySection && multiStepForm.currentStepNameKey === 'inventory' ? [] : {
            noValidate: ['location-shippingContact', 'location-shippingStreet', 'location-shippingCity', 'location-shippingState', 'location-shippingZip']
          },
          notificationNamespace: 'modal'
        };
        multiStepForm.formCopiedModelsSetVal('location.model.inventoryRecipient', this.inventoryRecipient);

        if (this.isNewRecord) {
          var toCreateObjects = yield multiStepForm.validateCreateAll(validationObject);
          var location = this.store.createRecord('location', toCreateObjects.location.data);
          yield toCreateObjects.location.saveWithRollbackAndNotification(location, 'modal');
        } else {
          yield multiStepForm.validateSyncSaveAll(validationObject, {
            notificationNamespace: 'modal'
          });
        }

        buttonPromise.then(() => {
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');

          if (this.isNewRecord) {
            multiStepForm.formStartedSetVal(false);
          }
        });
      } catch (e) {
        (false && Ember.warn('failed to save office location' + e, {
          id: 'multiStepForms.forms.administration.organization.editOfficeLocation'
        }));
        throw e;
      }
    })
  });

  _exports.default = _default;
});