define("securitytrax-ember-ui/components/nav/base-left-nav/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/suHJUJY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"tertiary-nav cell \",[30,[36,1],[[35,0],\"expand\"],null]]]],[12],[2,\"\\n    \"],[10,\"div\"],[14,1,\"nav-main\"],[15,0,[30,[36,3],[\"nav-main \",[30,[36,1],[[35,2],\"nav-disabled\",\"nav-enabled\"],null]],null]],[12],[2,\"\\n        \"],[18,1,[[30,[36,4],null,[[\"navGloballyDisabled\"],[[35,2]]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[15,0,[31,[\"overlay \",[30,[36,1],[[35,0],\"expand\"],null]]]],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"expand\",\"if\",\"navGloballyDisabled\",\"concat\",\"hash\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/nav/base-left-nav/template.hbs"
    }
  });

  _exports.default = _default;
});