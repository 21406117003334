define("securitytrax-ember-ui/mixins/equipment-sold-by-options", ["exports", "securitytrax-ember-ui/classes/equipment-sold-by-options"], function (_exports, _equipmentSoldByOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    init() {
      this._super(...arguments);

      this.EquipmentSoldByOptions = new _equipmentSoldByOptions.default({
        intl: this.intl,
        customer: this.customer
      });
    },

    get soldByOptions() {
      return this.EquipmentSoldByOptions.soldByOptionsTask.unlinked().perform();
    }

  });

  _exports.default = _default;
});