define("securitytrax-ember-ui/administration/mobile-navigation/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller.extend({
    actions: {
      changeRoute(route) {
        this.transitionToRoute(route);
      }

    }
  });
  var _default = Controller;
  _exports.default = _default;
});