define("securitytrax-ember-ui/components/pages/all-work-orders/component", ["exports", "ember-concurrency", "lodash", "securitytrax-ember-ui/utils/obj-helpers", "securitytrax-ember-ui/mixins/table-column-sort", "securitytrax-ember-ui/classes/misc-input-list", "securitytrax-ember-ui/config/environment"], function (_exports, _emberConcurrency, _lodash, _objHelpers, _tableColumnSort, _miscInputList, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableColumnSort.default, {
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    tagsService: Ember.inject.service(),
    userService: Ember.inject.service(),
    workOrderEvent: Ember.inject.service('events/work-order-event'),
    stFiltersService: Ember.inject.service(),
    screen: Ember.inject.service(),
    stMulticallService: Ember.inject.service(),
    limit: 50,
    page: 1,
    createdDateFrom: undefined,
    createdDateTo: undefined,
    workOrderTypeArray: undefined,
    workOrderIsOpen: undefined,
    assignedUsers: undefined,
    miscFilters: undefined,
    recordCount: undefined,
    subject: undefined,
    tagFilter: undefined,
    classNames: ['page-content'],

    init() {
      this._super(...arguments);

      this.workOrderEvent.on('refresh-my-work-orders', this, '_callback');
      (0, _objHelpers.initializeState)(this, {
        miscFilters: new _miscInputList.default(),
        tagFilter: this.tagsService.tagFilter(),
        stSortableColumnOptions: [{
          name: this.intl.t('common.columns.id') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'id',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.id') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-id',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('common.columns.due_date') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'due_date',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.due_date') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-due_date',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('common.columns.created_date') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'created_at',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.created_date') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-created_at',
          chevronDirection: 'down'
        }]
      });
      this.sortPaginatedList('created_at', false);

      try {
        this.miscFilters.addMiscInput();
      } catch (error) {
        this.set('miscFilters', new _miscInputList.default());
        this.miscFilters.addMiscInput();
      }

      this.initTask.perform();
    },

    willDestroy() {
      this.workOrderEvent.off('refresh-my-work-orders', this, '_callback');
    },

    _callback() {
      Ember.notifyPropertyChange(this, 'workOrdersData');
    },

    initTask: (0, _emberConcurrency.task)(function* () {
      Ember.set(this, 'tagsOptions', yield this.tagsService.tagsOptionsTask.perform('customer_note', 'customer_note_work_order'));
    }),
    useMultiCall: Ember.computed(function () {
      return _environment.default['environment'] != 'test';
    }),
    sortedUsers: Ember.computed(function () {
      return this.userService.getUsersList('work_order_assignee');
    }),
    workOrderTypes: Ember.computed(function () {
      return this.workOrderTypesTask.perform();
    }),
    workOrderTypesTask: (0, _emberConcurrency.task)(function* () {
      return yield this.store.findAll('workOrderType');
    }),
    miscFilterOperands: Ember.computed(function () {
      var intl = this.intl;
      return [{
        option: intl.t('common.filters.id'),
        value: 'id'
      }];
    }),
    miscFilterOperators: Ember.computed(function () {
      var intl = this.intl;
      return [{
        option: intl.t('common.filters.equals'),
        value: '='
      }, {
        option: intl.t('common.filters.starts_with'),
        value: '~%'
      }, {
        option: intl.t('common.filters.contains'),
        value: '%~%'
      }];
    }),
    workOrdersData: Ember.computed(function () {
      return this.workOrdersDataTask.perform();
    }),
    workOrdersDataTask: (0, _emberConcurrency.task)(function* () {
      var newFilters = yield this.stFiltersService.getFiltersByGroupKey('allWorkOrdersListFilter');
      var queryParams = {
        filter: newFilters,
        include: 'customer,work_order_equipment,tags,assigned_user',
        sort: this.stSortString ? this.stSortString : 'due_date',
        limit: this.limit,
        page: this.page
      };

      if (this.page == 1) {
        queryParams['count'] = 1;
      }

      var workOrders = yield this.store.query('workOrder', queryParams);

      if (this.page == 1) {
        this.set('recordCount', _lodash.default.get(workOrders, 'meta.record_count', 0));
      }

      return {
        workOrders,
        record_count: this.recordCount
      };
    }),
    actions: {
      updateWorkOrders() {
        Ember.notifyPropertyChange(this, 'workOrdersData');
      },

      filterSearch() {
        this.set('page', 1);
        Ember.notifyPropertyChange(this, 'workOrdersData');
      },

      clearFilterSearch() {
        Ember.setProperties(this, {
          page: 1,
          createdDateFrom: undefined,
          createdDateTo: undefined,
          workOrderTypeArray: undefined,
          workOrderIsOpen: undefined,
          assignedUsers: undefined,
          subject: undefined,
          dueDateFrom: undefined,
          dueDateTo: undefined,
          customerName: undefined,
          tagFilter: this.tagsService.tagFilter(),
          'miscFilters.miscInputs': [{
            operand: undefined,
            operator: undefined,
            value: undefined
          }]
        });
        Ember.notifyPropertyChange(this, 'workOrdersData');
        Ember.notifyPropertyChange(this, 'page');
      },

      transitionToSubroute(path) {
        this.router.transitionTo('all-work-orders.edit-work-order', path);
      },

      sortList(column, ascending) {
        this.sortPaginatedList(column, ascending);
        Ember.notifyPropertyChange(this, 'workOrdersData');
      },

      mobileSortList(column) {
        this.sortMobilePaginatedList(column.value);
        Ember.notifyPropertyChange(this, 'workOrdersData');
      }

    }
  });

  _exports.default = _default;
});