define("securitytrax-ember-ui/components/multi-step-form/forms/administration/account-management/payment-method/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency"], function (_exports, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    confirmDeleteModalOpen: false,

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      var buttons = [];

      if (this.formMode !== 'view') {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.editCreatePaymentMethodSaveTask.perform(buttonPromise, multiStepForm);
          },
          classNamesString: 'st-btn st-btn-primary'
        });
      }

      if (!this.isNewRecord && this.grants.getIndividualGrant('customer_billings.delete') && !multiStepForm.formOriginalModelsGetVal('accountBilling.model.isAccountDefault')) {
        buttons.push({
          text: this.intl.t('common.actions.delete'),
          click: () => {
            this.set('confirmDeleteModalOpen', true);
          },
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
        });
      }

      multiStepForm.registerStepData('paymentMethodFormStep', 'buttons', buttons);
    },

    deleteCustomerBilling: (0, _emberConcurrency.task)(function* (accountBilling, buttonPromise) {
      try {
        var multiStepForm = this.multiStepForm;
        yield accountBilling.destroyRecordWithRollbackTask.perform();
        buttonPromise.then(() => {
          multiStepForm.formStartedSetVal(false);
          this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
        });
      } catch (err) {
        (false && Ember.warn('deleteCustomerBilling task failed' + err, {
          id: 'accounting.customer_billing_delete'
        }));
      }
    }),
    editCreatePaymentMethodSaveTask: (0, _emberConcurrency.task)(function* (buttonPromise, multiStepForm) {
      var accountBilling = this.accountBilling;
      var isAccountDefault = multiStepForm.formCopiedModelsGetVal('accountBilling.model.isAccountDefault'); //create

      if (this.isNewRecord && !multiStepForm.formCopiedModelsGetVal('accountBilling.model.id')) {
        var toCreateObjects = yield multiStepForm.validateCreateAll({
          overrideOriginalModelsConfig: Ember.Object.create({
            accountBilling: {
              model: null
            }
          })
        });

        var _accountBilling = this.store.createRecord('accountBilling', toCreateObjects.accountBilling.data);

        var savedAccountBilling = yield toCreateObjects.accountBilling.saveWithRollbackAndNotification(_accountBilling, 'billingModal');
        yield savedAccountBilling.save();

        if (isAccountDefault) {
          yield savedAccountBilling.invokeAction('make_account_default', {
            verb: 'post'
          });
          yield this.store.findAll('accountBilling', {
            reload: true
          });
        }

        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'billingModal');
        buttonPromise.then(() => {
          multiStepForm.formStartedSetVal(false);
        });
      } else {
        //edit existing
        var validateConfig = {
          accountBilling: {
            model: null
          }
        };

        if (isAccountDefault) {
          yield accountBilling.invokeAction('make_account_default', {
            verb: 'post'
          });
          yield this.store.findAll('accountBilling', {
            reload: true
          });
        }

        yield multiStepForm.validateSyncSaveAll({
          deepSave: false,
          overrideOriginalModelsConfig: validateConfig,
          notificationNamespace: 'billingModal'
        });
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        buttonPromise.then(() => {
          multiStepForm.formStartedSetVal(false);
        });
      }
    })
  });

  _exports.default = _default;
});