define("securitytrax-ember-ui/services/colors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Colors extends Ember.Service {
    get colors() {
      return ['#db2a17', //'red'
      '#db7226', //'orange'
      '#efe401', //'yellow'
      '#00a216', //'green'
      '#3d49d1', //'blue'
      '#8b2cce', //'violet'
      '#d7254a', //'magenta-red'
      '#e89d00', //'orange-yellow'
      '#4db71a', // 'lime'
      '#00a5a1', //'green-cyan'
      '#6429ce', //'blue-violet'
      '#bb3eb6', //'violet-magenta'
      '#00a4cd', //'cyan'
      '#3c86db', //'cyan-blue'
      '#d72573', //'magenta'
      '#4a90e2' //'light-blue'
      ];
    }

    generateColors(length) {
      var returnColors = [];
      var numLoops = Math.floor(length / this.colors.length) + (length % this.colors.length > 0 ? 1 : 0);

      for (var x = 0; x < numLoops; x++) {
        var magnitude = x * (x % 2 == 0 ? -20 : 20);

        for (var y = 0; y < this.colors.length; y++) {
          returnColors.push(this.newShade(this.colors[y], magnitude));
        }
      }

      return returnColors;
    }

    newShade(hexColor, magnitude) {
      if (magnitude != 0) {
        var usePound = false;

        if (hexColor[0] == '#') {
          hexColor = hexColor.slice(1);
          usePound = true;
        }

        var num = parseInt(hexColor, 16);
        var r = (num >> 16) + magnitude;
        if (r > 255) r = 255;else if (r < 0) r = 0;
        var b = (num >> 8 & 0x00FF) + magnitude;
        if (b > 255) b = 255;else if (b < 0) b = 0;
        var g = (num & 0x0000FF) + magnitude;
        if (g > 255) g = 255;else if (g < 0) g = 0;
        return (usePound ? '#' : '') + String('000000' + (g | b << 8 | r << 16).toString(16)).slice(-6);
      }

      return hexColor;
    }

  }

  _exports.default = Colors;
});