define("securitytrax-ember-ui/components/multi-step-form/forms/system/brinks/create-econtract/component", ["exports", "@glimmer/component", "ember-concurrency", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "moment", "lodash"], function (_exports, _component, _emberConcurrency, _switchableFormStep, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CreateEcontract = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service('events/brinks-econtract-events'), _dec8 = Ember.inject.service, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = (0, _emberConcurrency.task)(function* () {
    this.contractType = this.args.customer.accountType;
    var hashedData = yield (0, _emberConcurrency.hash)({
      econtractMeta: this.centralStationService.getData({
        model: this.args.customer,
        method: 'brinks__getCustomerEContractMetadata'
      }),
      econtractFormInfo: this.centralStationService.getData({
        model: this.args.customer,
        method: 'brinks__getEContractFormData'
      }),
      company: this.sessionData.getCompany()
    });
    this.companyName = hashedData.company.name;
    this.econtractFormInfo = hashedData.econtractFormInfo.e_contract_form_data;

    if (hashedData.econtractMeta.customer_e_contract_metadata.attachments.length > 0) {
      hashedData.econtractMeta.customer_e_contract_metadata.attachments.forEach(attachment => {
        var match = this.econtractFormInfo.attachment_types.find(type => {
          return type.id === attachment.attachment_type_id;
        });

        if (match) {
          match.attachmentName = this.intl.t('pages.customers.system.order.previouslyUploaded');
          match.attachment_id = attachment.attachment_id;
        }
      });
    }

    this.personalSigningTypeOptions = hashedData.econtractFormInfo.e_contract_form_data.signing_types.filter(signingType => {
      return signingType.id !== 'None';
    }); //prefill some stuff

    var billingObject = this.econtractFormInfo.billing_address;

    if (billingObject && billingObject.bill_address_1 && billingObject.bill_city && billingObject.bill_state && billingObject.bill_zip) {
      this.formattedBillingAddress = "".concat(billingObject.bill_address_1).concat(billingObject.bill_address_2 ? ' ' + billingObject.bill_address_2 : '', ", ").concat(billingObject.bill_city, ", ").concat(billingObject.bill_state, " ").concat(billingObject.bill_zip);
    } else {
      this.formattedBillingAddress = null;
    }

    if (this.econtractFormInfo.payment_methods.length === 1) {
      this.initialPaymentId = this.econtractFormInfo.payment_methods[0].id;
      this.monthlyPaymentId = this.econtractFormInfo.payment_methods[0].id;
    }

    this.signingType = 'Embedded';
    this.secondarySigningType = 'None';
    this.guardAddendumRequired = false;
    this.personalGuaranteeRequired = false;
    this.contractForMonitoringQuestion = false;
    this.homeOwnerQuestion = false;
    this.newConstructionQuestion = false;
    this.contractualObligationQuestion = false;
    this.cancelQuestion = false;

    if (this.contractType === 'Residential') {
      this.extendedServiceOption = false;
    }

    if (this.customerInCanadianProvince) {
      this.canadaQuestion = false;
    }

    this.billCounty = this.econtractFormInfo.billing_address.bill_county;
    this.secondaryFirstName = this.args.customer.spouse;
  }), _dec20 = (0, _emberConcurrency.task)(function* (row, click) {
    try {
      Ember.set(row, 'uploading', true);
      var file = document.getElementById("".concat(row.id)).files[0];

      if (file) {
        var serializePromise = new Promise((resolve, reject) => {
          var reader = new FileReader();

          reader.onload = () => resolve(reader.result);

          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
        var base64Result = yield serializePromise;
        var trimmedResult = base64Result.substring(base64Result.indexOf('base64,') + 7);
        var returnValue = yield this.centralStationService.postData({
          model: this.args.customer,
          method: 'brinks__uploadEContractAttachment',
          payload: {
            file_contents_base_64_encoded: trimmedResult,
            attachment_type_id: row.id
          }
        });
        Ember.set(row, 'attachment_id', returnValue.responsePayload.data.payload.brinks__uploadEContractAttachmentResponse.e_contract_attachment_id);
        Ember.set(row, 'attachment_type_id', row.id);
        Ember.set(row, 'attachmentName', file.name);
        Ember.set(row, 'uploading', false); // allows for additional uploads from same input if they decide to delete or something

        click.target.value = null;
      } else {
        Ember.set(row, 'serializedValue', null);
        Ember.set(row, 'attachmentName', null);
        Ember.set(row, 'uploading', false);
      }
    } catch (err) {
      Ember.set(row, 'uploading', false);
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
    }
  }), _dec21 = (0, _emberConcurrency.task)(function* (row, buttonPromise) {
    try {
      yield this.centralStationService.postData({
        model: this.args.customer,
        method: 'brinks__deleteEContractAttachment',
        payload: {
          attachment_id: row.attachment_id
        }
      });
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccessKey('common.notifications.delete_successful', 'modal');
        this.fileDeleteId = null;
        Ember.set(row, 'attachmentName', null);
        Ember.set(row, 'attachment_id', null);
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), _dec22 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    var noValidateArray = [];

    if (!this.secondarySigningType || this.secondarySigningType === 'None') {
      noValidateArray.push('brinks-firstName', 'brinks-lastName', 'brinks-email', 'brinks-phoneNumber', 'brinks-birthDate', 'brinks-ssnOrTin');
    }

    if (this.contractType === 'Residential') {
      noValidateArray.push('brinks-companyType');
    }

    if (this.contractType === 'Commercial') {
      noValidateArray.push('brinks-extendedService');
    }

    if (!this.customerInCanadianProvince) {
      noValidateArray.push('brinks-familiarization');
    }

    if (!this.personalGuaranteeRequired) {
      noValidateArray.push('brinks-address1', 'brinks-city', 'brinks-state', 'brinks-zip', 'brinks-title');
    }

    var formattedObjects = [];
    formattedObjects.pushObject(Ember.Object.create({
      model: {
        signingType: this.signingType,
        companyType: this.companyType,
        equipmentAlarmNetwork: this.equipmentAlarmNetwork,
        installationStart: this.econtractFormInfo.install_appointment_data.start_date_time,
        installationFinish: this.econtractFormInfo.install_appointment_data.end_date_time,
        billCounty: this.billCounty,
        paymentInitial: this.initialPaymentId,
        paymentMonthly: this.monthlyPaymentId,
        paymentEffectiveDate: this.paymentEffectiveDate,
        secondarySignerSigningType: this.secondarySigningType,
        firstName: this.secondaryFirstName,
        lastName: this.secondaryLastName,
        email: this.secondaryEmail,
        phoneNumber: this.secondaryPhone,
        birthDate: this.secondaryBirthdate,
        ssnOrTin: this.secondarySsn,
        extendedService: this.extendedServiceOption,
        contractMonitoring: this.contractForMonitoringQuestion,
        homeowner: this.homeOwnerQuestion,
        newContruction: this.newConstructionQuestion,
        contractualObligation: this.contractualObligationQuestion,
        cancelUnderstanding: this.cancelQuestion,
        familiarization: this.canadaQuestion,
        address1: this.guaranteeHomeAddress1,
        city: this.guaranteeHomeCity,
        state: this.guaranteeHomeState,
        zip: this.guaranteeHomeZip,
        title: this.guaranteeTitle
      },
      extraValidationData: {
        noValidate: noValidateArray
      },
      filePath: 'central-station/e-contract-brinks'
    }));
    yield multiStepForm.validateList.perform(formattedObjects);

    try {
      var contractPayload = {
        primary_signing_type: this.signingType,
        months_paid_up_front: this.monthsPaidUpFront,
        promotion_months: this.promoMonths,
        company_type: this.companyType,
        guard_addendum_required: this.guardAddendumRequired,
        installation_work_description: this.installationWorkDescription,
        premise_gate_code: this.premiseGateCode,
        dealer_redirection_url: window.location.protocol + '//' + window.location.host + window.location.pathname,
        bill_address_county: this.billCounty,
        equipment_alarm_network: this.equipmentAlarmNetwork,
        equipment_other_amount: this.equipmentOtherAmount,
        equipment_permit_amount: this.equipmentPermitAmount,
        equipment_tax_amount: this.equipmentTaxAmount,
        insurance_personal_injury_amount: this.insurancePersonalInjuryAmount,
        insurance_personal_damage_amount: this.insurancePropertyDamageAmount,
        payment_effective_date: (0, _moment.default)(this.paymentEffectiveDate).format('MM/DD/YYYY'),
        payment_extended_service_option: this.extendedServiceOption,
        payment_initial_customer_billing_id: this.initialPaymentId,
        payment_monthly_customer_billing_id: this.monthlyPaymentId,
        secondary_signing_type: this.secondarySigningType,
        secondary_birth_date: (0, _moment.default)(this.secondaryBirthdate).format('MM/DD/YYYY'),
        secondary_email: this.secondaryEmail,
        secondary_first_name: this.secondaryFirstName,
        secondary_last_name: this.secondaryLastName,
        secondary_phone: this.secondaryPhone,
        secondary_tax_id_number: this.secondarySsn,
        survey_cancelling_service: this.cancelQuestion,
        survey_confirm_contract_length: this.contractForMonitoringQuestion,
        survey_familiarization_period: this.canadaQuestion,
        survey_homeowner: this.homeOwnerQuestion,
        survey_new_construction: this.newConstructionQuestion,
        survey_under_contract: this.contractualObligationQuestion,
        personal_guarantee_home_address_1: this.guaranteeHomeAddress1,
        personal_guarantee_home_address_2: this.guaranteeHomeAddress2,
        personal_guarantee_home_city: this.guaranteeHomeCity,
        personal_guarantee_home_state: this.guaranteeHomeState,
        personal_guarantee_home_zip: this.guaranteeHomeZip,
        personal_guarantee_title: this.guaranteeTitle,
        personal_guarantee_required: this.personalGuaranteeRequired
      };
      var response = yield this.centralStationService.postData({
        model: this.args.customer,
        method: 'brinks__createContract',
        payload: contractPayload
      });
      buttonPromise.then(() => {
        if (_lodash.default.get(response, 'responsePayload.data.payload.brinks__createContractResponse.urls', false)) {
          if (response.responsePayload.data.payload.brinks__createContractResponse.urls.length > 0) {
            this.brinksEcontractEvents.trigger('reload-cs-card'); // Not sure best way to do this to avoid popup blockers and crap like that?

            window.location.replace(response.responsePayload.data.payload.brinks__createContractResponse.urls[0].url);
          }
        } else {
          this.brinksEcontractEvents.trigger('reload-cs-card');
        }

        this.args.setHasProgressBar(true);
        this.args.multiStepForm.currentStepNameKeySetVal('account');
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), _dec23 = Ember._action, (_class = (_temp = class CreateEcontract extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "centralStationService", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "sessionData", _descriptor3, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor4, this);

      _initializerDefineProperty(this, "usStates", _descriptor5, this);

      _initializerDefineProperty(this, "router", _descriptor6, this);

      _initializerDefineProperty(this, "brinksEcontractEvents", _descriptor7, this);

      _initializerDefineProperty(this, "yesOrNoOptionsService", _descriptor8, this);

      _defineProperty(this, "contractType", undefined);

      _initializerDefineProperty(this, "disabledProgressBarSteps", _descriptor9, this);

      _initializerDefineProperty(this, "hiddenProgressBarSteps", _descriptor10, this);

      _initializerDefineProperty(this, "progressBarHidden", _descriptor11, this);

      _initializerDefineProperty(this, "equipmentOtherAmount", _descriptor12, this);

      _initializerDefineProperty(this, "equipmentPermitAmount", _descriptor13, this);

      _initializerDefineProperty(this, "equipmentTaxAmount", _descriptor14, this);

      _initializerDefineProperty(this, "monthsPaidUpFront", _descriptor15, this);

      _initializerDefineProperty(this, "promoMonths", _descriptor16, this);

      _initializerDefineProperty(this, "personalGuaranteeRequired", _descriptor17, this);

      _initializerDefineProperty(this, "fileDeleteId", _descriptor18, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor19, this);

      _initializerDefineProperty(this, "serializeDocument", _descriptor20, this);

      _initializerDefineProperty(this, "deleteAttachment", _descriptor21, this);

      _initializerDefineProperty(this, "saveEcontract", _descriptor22, this);

      this.disabledProgressBarSteps = [];
      this.hiddenProgressBarSteps = [];
      this.progressBarHidden = true;
      this.SwitchableFormStep = new _switchableFormStep.default(...arguments, {
        progressBarHidden: this.progressBarHidden,
        disabledProgressBarSteps: this.disabledProgressBarSteps,
        hiddenProgressBarSteps: this.hiddenProgressBarSteps
      });
      this.initDataTask.perform();
    }

    get trueOrFalseOptions() {
      return this.yesOrNoOptionsService.getYesOrNoOptions();
    }

    get equipmentTotal() {
      return Number(this.econtractFormInfo.equipment_subtotal_amount) + Number(this.equipmentOtherAmount) + Number(this.equipmentPermitAmount) + Number(this.equipmentTaxAmount);
    }

    get billStartDate() {
      if (this.econtractFormInfo.install_appointment_data.end_date_time) {
        return (0, _moment.default)(this.econtractFormInfo.install_appointment_data.end_date_time, 'MM/DD/YYYY hh:mm A').add(Number(this.monthsPaidUpFront) + Number(this.promoMonths), 'months').format('ddd MMM D YYYY');
      } else {
        return null;
      }
    }

    get customerInCanadianProvince() {
      var arrayOfCanadianProvinceAbbreviations = ['NL', 'PE', 'NS', 'NB', 'QC', 'ON', 'MB', 'SK', 'AB', 'BC', 'YT', 'NT', 'NU'];

      if (arrayOfCanadianProvinceAbbreviations.findIndex(province => {
        return province === this.args.customer.state;
      }) !== -1) {
        return true;
      } else {
        return false;
      }
    }

    renderButtons() {
      var buttons = [];

      if (_lodash.default.get(this, 'router.currentRoute.parent.queryParams.toContract', false) === false) {
        buttons.push({
          text: this.intl.t('common.actions.back'),
          classNamesString: 'st-btn st-btn-secondary',
          click: () => {
            this.args.setHasProgressBar(true);
            this.args.multiStepForm.currentStepNameKeySetVal('account');
          }
        });
      }

      if (this.args.formMode !== 'view') {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveEcontract.perform(this.args.multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary'
        });
      }

      this.args.multiStepForm.registerStepData('createEcontract', 'buttons', buttons);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "usStates", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "brinksEcontractEvents", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "yesOrNoOptionsService", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "disabledProgressBarSteps", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "hiddenProgressBarSteps", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "progressBarHidden", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "equipmentOtherAmount", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "equipmentPermitAmount", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "equipmentTaxAmount", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "monthsPaidUpFront", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "promoMonths", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "personalGuaranteeRequired", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "fileDeleteId", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "serializeDocument", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "deleteAttachment", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "saveEcontract", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "renderButtons", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "renderButtons"), _class.prototype)), _class));
  _exports.default = CreateEcontract;
});