define("securitytrax-ember-ui/components/pages/administration/company-settings/content-management/scheduling-profiles/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var schedulingProfiles = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember.computed, _dec13 = (0, _emberConcurrency.task)(function* () {
    var newFilters = yield this.stFiltersService.getFiltersByGroupKey('companySchedulingProfiles', ['profile_type=\'tech\'']);
    var schedulingProfiles = yield this.store.query('schedulingProfile', {
      filter: newFilters,
      limit: this.limit,
      page: this.page,
      count: 1
    });
    var foundAnActiveCount = 0;

    for (var index = 0; index < schedulingProfiles.length; index++) {
      var element = schedulingProfiles.objectAt(index);

      if (element.active === true) {
        foundAnActiveCount++;

        if (foundAnActiveCount >= 2) {
          break;
        }
      }
    }

    Ember.set(this, 'schedulingProfilesAtLeastTwoActive', foundAnActiveCount >= 2);
    Ember.set(this, 'totalCount', schedulingProfiles.meta.record_count);
    return schedulingProfiles;
  }), _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, (_class = (_temp = class schedulingProfiles extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "stFiltersService", _descriptor3, this);

      _initializerDefineProperty(this, "schedulingProfile", _descriptor4, this);

      _initializerDefineProperty(this, "schedulingProfileFormStarted", _descriptor5, this);

      _initializerDefineProperty(this, "schedulingProfileFormMode", _descriptor6, this);

      _initializerDefineProperty(this, "stepTitle", _descriptor7, this);

      _initializerDefineProperty(this, "limit", _descriptor8, this);

      _initializerDefineProperty(this, "page", _descriptor9, this);

      _initializerDefineProperty(this, "totalCount", _descriptor10, this);

      _initializerDefineProperty(this, "schedulingProfileStatus", _descriptor11, this);

      _initializerDefineProperty(this, "schedulingProfilesTask", _descriptor12, this);
    }

    get schedulingProfiles() {
      return this.schedulingProfilesTask.perform();
    }

    get schedulingProfileFormSteps() {
      var intl = this.intl;
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: intl.t("common.forms.descriptive_title_".concat(this.schedulingProfileFormMode), {
            name: this.schedulingProfileFormMode === 'create' ? intl.t('pages.administration.content_management.scheduling_profiles.label') : this.stepTitle.name,
            id: this.stepTitle.id
          }),
          stepComponentPath: 'multi-step-form/forms/administration/content-management/edit-scheduling-profile'
        })
      });
    }

    get schedulingProfileFormOriginalModels() {
      return Ember.Object.create({
        schedulingProfile: {
          model: this.schedulingProfile
        }
      });
    }

    setTitle(row) {
      this.stepTitle = Ember.Object.create({
        name: row.get('name'),
        id: row.get('id')
      });
    }

    filterSearch(setPage = true) {
      if (setPage) {
        Ember.set(this, 'page', 1);
      }

      Ember.notifyPropertyChange(this, 'schedulingProfiles');
    }

    clearFilters() {
      Ember.setProperties(this, {
        page: 1,
        schedulingProfileStatus: undefined
      });
      Ember.notifyPropertyChange(this, 'schedulingProfiles');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stFiltersService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "schedulingProfile", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "schedulingProfileFormStarted", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "schedulingProfileFormMode", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'edit';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "stepTitle", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.Object.create({
        name: null,
        id: null
      });
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "limit", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 50;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "totalCount", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "schedulingProfileStatus", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "schedulingProfiles", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "schedulingProfiles"), _class.prototype), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "schedulingProfilesTask", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setTitle", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "setTitle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterSearch", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "filterSearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilters", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilters"), _class.prototype)), _class));
  _exports.default = schedulingProfiles;
});