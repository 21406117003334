define("securitytrax-ember-ui/mixed-in-classes/apply-order-template", ["exports", "@glimmer/component", "ember-concurrency", "lodash"], function (_exports, _component, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var ApplyOrderTemplate = (_dec = Ember.inject.service('grants'), _dec2 = Ember.inject.service('stAjax'), _dec3 = Ember.inject.service('intl'), _dec4 = Ember.inject.service('store'), _dec5 = Ember.inject.service('stNotificationManager'), _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = (0, _emberConcurrency.task)(function* () {
    yield this.orderTemplateChoices; //ensure order template choices on load

    this.selectedTemplateId = this.args.orderTemplates.get('firstObject.id');
    this.mmrsTask.perform();
  }), _dec14 = (0, _emberConcurrency.task)(function* (customerOrderInformationId) {
    var selectedPackages = Ember.A();

    var soldById = _lodash.default.get(this, 'equipmentSalesPerson.id', null);

    var meta = {};
    var soldByObject = null;

    if (this.selectedTemplatePackageId) {
      if (parseInt(soldById) > 0) {
        soldByObject = Ember.Object.create({
          id: soldById,
          type: 'users'
        });
      }

      selectedPackages.pushObject({
        'id': this.selectedTemplatePackageId,
        'type': 'packages'
      });
    }

    if (this.invoiceButtonId !== null && this.invoiceButtonId >= 0) {
      meta = {
        'invoice_type': this.buttonChoices[this.invoiceButtonId].toLowerCase().replace(/ /g, '_')
      };
    }

    var postObject = {
      verb: 'POST',
      dataType: 'json',
      data: {
        data: {
          relationships: {
            order_template: {
              id: this.selectedTemplateId,
              type: 'order_templates'
            },
            sold_by: soldByObject,
            packages: selectedPackages //array of packages

          },
          type: 'customer_order_information_action',
          attributes: {
            action: 'customer_order_information_action'
          },
          meta: meta
        }
      }
    };

    if (this.selectedMmr && this.selectedMmr.get('id')) {
      postObject.data.data.relationships.mmr = {
        id: this.selectedMmr.get('id'),
        type: 'mmrs'
      };
    }

    var customerOrderInfo = yield this.store.findRecord('customerOrderInformation', customerOrderInformationId);

    try {
      yield customerOrderInfo.invokeAction('apply_order_template', postObject);
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), _dec15 = (0, _emberConcurrency.task)(function* () {
    var selectedMmr = null;

    if (this.args.orderTemplates) {
      var orderTemplate = this.args.orderTemplates.findBy('id', this.selectedTemplateId);

      if (orderTemplate) {
        var mmrs = yield orderTemplate.get('defaultMmrs');

        if (typeof mmrs !== 'undefined') {
          this.templateMmrs = mmrs.sortBy('amountNumeric');
          this.showMmrSelectList = mmrs.length > 1;

          if (mmrs.length === 1) {
            selectedMmr = mmrs.get('firstObject');
          }
        }
      } else {
        this.showMmrSelectList = false;
      }
    }

    this.selectedMmr = selectedMmr;
  }), _dec16 = (0, _emberConcurrency.task)(function* () {
    var choices = Ember.A([]);
    var templates = yield this.args.orderTemplates;

    if (templates && templates.get('length') > 0) {
      for (var i = 0; i < templates.get('length'); i++) {
        var template = templates.objectAt(i);

        if (template.get('active')) {
          var promisesArray = [template.get('defaultMmrs'), template.get('defaultActivationFee'), template.get('defaultContractTerm'), template.get('defaultCustomerWarranty'), template.get('defaultMonitoringCompany'), template.get('defaultMonitoringPlan'), template.get('defaultInteractiveServicesProvider')];
          yield Ember.RSVP.all(promisesArray);
          var mmrs = [];

          if (template.get('defaultMmrs')) {
            mmrs = template.get('defaultMmrs').sortBy('amountNumeric').map(mmr => {
              return mmr.get('amountDisplay');
            });
          }

          choices.pushObject(Ember.Object.create({
            packageName: template.get('name'),
            packageId: template.get('id'),
            packageContent: [{
              label: this.intl.t('pages.customers.order.mmr.label'),
              description: mmrs.join(', ')
            }, {
              label: this.intl.t('pages.customers.order.activationFee.label'),
              description: template.get('defaultActivationFee.amountDisplay')
            }, {
              label: this.intl.t('pages.customers.order.contractTerm.label'),
              description: template.get('defaultContractTerm.description')
            }, {
              label: this.intl.t('pages.customers.order.customerWarranty.label'),
              description: template.get('defaultCustomerWarranty.displayValue')
            }, {
              label: this.intl.t('pages.customers.order.monitoringCompany.label'),
              description: template.get('defaultMonitoringCompany.name')
            }, {
              label: this.intl.t('pages.customers.order.monitoringPlan.label'),
              description: template.get('defaultMonitoringPlan.name')
            }, {
              label: this.intl.t('pages.customers.order.interactiveServicesProvider.label'),
              description: template.get('defaultInteractiveServicesProvider.name')
            }]
          }));
        }
      }
    }

    return choices;
  }), _dec17 = (0, _emberConcurrency.task)(function* () {
    var packageToEquipmentCount = Ember.A([]);
    var selectedTemplate = this.selectedTemplate.get('firstObject');

    if (selectedTemplate) {
      //TODO: this shouldn't be necessary. its only necessary because Mirage isn't reloading data
      //also see mirage application serializer: //alwaysIncludeLinkageData: true, reloads data, but messes up many-to-many such as packages
      var stepsArray = ['activationFee', 'contractTerm', 'customerWarranty', 'monitoringCompany', 'monitoringPlan', 'interactiveServicesProvider'];
      stepsArray.forEach(name => {
        this[this.copiedModelPrefix + '.' + name] = selectedTemplate.get('default' + name.capitalize());
      }); //end TODO

      var packages = yield selectedTemplate.get('defaultPackages');

      var _loop = function* (i) {
        var currPackage = packages.objectAt(i);
        var pkEquipmentList = yield currPackage.get('packageEquipment');
        var promisesArray = [];
        pkEquipmentList.forEach(currPkEquipment => {
          promisesArray.push(currPkEquipment.get('equipment'));
        });
        yield Ember.RSVP.all(promisesArray);
        var currPkEquipmentList = {};
        pkEquipmentList.forEach(currPkEquipment => {
          if (!currPkEquipmentList[currPkEquipment.get('equipment.name')]) {
            currPkEquipmentList[currPkEquipment.get('equipment.name')] = currPkEquipment.get('quantity');
          } else {
            currPkEquipmentList[currPkEquipment.get('equipment.name')] += currPkEquipment.get('quantity');
          }
        });
        var currPkEquipmentListEmberArr = Object.keys(currPkEquipmentList).map(equipmentName => {
          return Ember.Object.create({
            name: equipmentName,
            quantity: currPkEquipmentList[equipmentName]
          });
        });
        packageToEquipmentCount.pushObject(Ember.Object.create({
          packageName: currPackage.get('name'),
          packageId: currPackage.get('id'),
          packageContent: currPkEquipmentListEmberArr
        }));
      };

      for (var i = 0; i < packages.get('length'); i++) {
        yield* _loop(i);
      }
    }

    return packageToEquipmentCount;
  }), (_class = (_temp = class ApplyOrderTemplate extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "grants", _descriptor, this);

      _initializerDefineProperty(this, "stAjax", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor5, this);

      _initializerDefineProperty(this, "showMmrSelectList", _descriptor6, this);

      _initializerDefineProperty(this, "templateMmrs", _descriptor7, this);

      _initializerDefineProperty(this, "selectedTemplatePackageId", _descriptor8, this);

      _initializerDefineProperty(this, "invoiceButtonId", _descriptor9, this);

      _initializerDefineProperty(this, "equipmentSalesPerson", _descriptor10, this);

      _initializerDefineProperty(this, "selectedTemplateId", _descriptor11, this);

      _initializerDefineProperty(this, "selectedMmr", _descriptor12, this);

      _initializerDefineProperty(this, "initFormTask", _descriptor13, this);

      _initializerDefineProperty(this, "applyOrderTemplateMixinTask", _descriptor14, this);

      _initializerDefineProperty(this, "mmrsTask", _descriptor15, this);

      _initializerDefineProperty(this, "orderTemplateChoicesTask", _descriptor16, this);

      _initializerDefineProperty(this, "packageChoicesTask", _descriptor17, this);

      this.initFormTask.perform();
    }

    get copiedModelPrefix() {
      return this.args.multiStepForm.formCopiedModelsGetVal('customerOrderInformation') ? this.args.multiStepForm.formCopiedModelsGetVal('customerOrderInformation.model') : this.args.multiStepForm.formCopiedModelsGetVal('customer.model.customerOrderInformation');
    }

    get buttonChoices() {
      var selectedTemplate = this.selectedTemplate.get('firstObject');
      var choices = [];
      var grants = this.grants;
      var intl = this.intl;

      if (selectedTemplate) {
        if (selectedTemplate.get('allowInvoice') && grants.getIndividualGrant('customer_invoices.create')) {
          choices.push(intl.t('pages.customers.order.generic_invoice_titles.invoice'));
        }

        if (selectedTemplate.get('allowQuote') && grants.getIndividualGrant('customer_quotes.create')) {
          choices.push(intl.t('pages.customers.order.generic_invoice_titles.quote'));
        }
      }

      return choices;
    }

    get salesPerson() {
      return this.copiedModelPrefix + '.repUser.id';
    }

    get selectedTemplate() {
      return this.args.orderTemplates ? this.args.orderTemplates.filterBy('id', this.selectedTemplateId) : null;
    }
    /**
     * setup the package selection for the Order Templates
     */


    get orderTemplateChoices() {
      return this.orderTemplateChoicesTask.perform();
    }

    /**
     * setup the choices for the packages which depend on selected order template
     * this also sets up copied models for the order information page, if they save will sync
     */
    get packageChoices() {
      return this.packageChoicesTask.perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stAjax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "showMmrSelectList", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "templateMmrs", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "selectedTemplatePackageId", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "invoiceButtonId", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "equipmentSalesPerson", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "selectedTemplateId", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "selectedMmr", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "initFormTask", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "applyOrderTemplateMixinTask", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "mmrsTask", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "orderTemplateChoicesTask", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "packageChoicesTask", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ApplyOrderTemplate;
});