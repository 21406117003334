define("securitytrax-ember-ui/initializers/mobile-drag-drop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /* global MobileDragDrop */
  function initialize()
  /* application */
  {
    var isApple = ['iPhone', 'iPad', 'iPod'].includes(navigator.platform);
    var isSamsung = navigator.userAgent.includes('SamsungBrowser');

    if (typeof MobileDragDrop !== 'undefined' && (isApple || isSamsung)) {
      MobileDragDrop.polyfill({
        holdToDrag: 1
      });
    }
  }

  var _default = {
    name: 'mobile-drag-drop',
    initialize
  };
  _exports.default = _default;
});