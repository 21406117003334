define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/partner-companies/create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XMN1TmGP",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],null,[[\"useSpinner\",\"booleanLoaded\"],[true,[35,0,[\"finishedCopyingModels\"]]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"multiStepForm\",\"formMode\"],[[35,0],\"create\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"multiStepForm\",\"multi-step-form/forms/administration/integrations/partner-companies/company\",\"html/st-await\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/partner-companies/create/template.hbs"
    }
  });

  _exports.default = _default;
});