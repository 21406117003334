define("securitytrax-ember-ui/components/html/multi-select/building-blocks/select-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    hasColor: false,
    startIndex: undefined,
    endIndex: undefined,
    bumpIndex: undefined,
    page: 1,
    limit: 25,

    init() {
      this._super(...arguments);
    },

    filterObserver: Ember.observer('filterValue', function () {
      this.set('page', 1);
    }),
    theList: Ember.computed('listData', 'listData.{[],@each.multiSelectComponentItemIsSelected}', 'page', function () {
      this.set('fullListLength', this.listData.length);

      if (this.separatedList) {
        var selectedList = [];
        var unselectedList = [];

        for (var i = 0; i < this.listData.length; i++) {
          if (this.listData[i].multiSelectComponentItemIsSelected) {
            selectedList.push(this.listData[i]);
          } else {
            unselectedList.push(this.listData[i]);
          }
        }

        var lowerLimit = (this.page - 1) * this.limit;
        var paginatedList = unselectedList.slice(lowerLimit, lowerLimit + this.limit);
        return {
          selectedList,
          paginatedList
        };
      } else {
        var _lowerLimit = (this.page - 1) * this.limit;

        var _paginatedList = this.listData.slice(_lowerLimit, _lowerLimit + this.limit);

        var _selectedList = [];
        return {
          paginatedList: _paginatedList,
          selectedList: _selectedList
        };
      }
    }),
    actions: {
      updateSelectListOptions() {//needed just to pass into pagination
      },

      click(row) {
        if (Ember.get(row, 'value') === 'all') {
          this.clickAllAction.call(this);
        } else {
          this.rowClickAction.call(this, row);
        }
      }

    }
  });

  _exports.default = _default;
});