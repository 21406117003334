define("securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/base-components/loan-details/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/create-financing-base", "ember-concurrency"], function (_exports, _createFinancingBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var BaseCreateFinancingLoanDetails = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    var application = multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model'); // BOWLES TODO: If we add more lender types we need a switch or something on the high level model for noValidate

    var baseDealerAllyNoValidate = ['consumerFinancingApplication-creditAmountRequested'];
    var baseAlarmNoValidate = ['consumerFinancingApplication-completionDate', 'consumerFinancingApplication-contractState', 'consumerFinancingApplication-productOrService', 'consumerFinancingApplication-salesTaxRate', 'consumerFinancingApplication-sellingPrice'];
    var noValidate = this.args.lenderTypeKey && this.args.lenderTypeKey === 'smart_home_pay' ? baseAlarmNoValidate : application.salesTaxIncluded ? baseDealerAllyNoValidate : baseDealerAllyNoValidate.concat(['consumerFinancingApplication-salesTaxRate']);

    if (!application.get('id')) {
      var formattedObjects = [];
      formattedObjects.push(Ember.Object.create({
        model: application,
        modelName: 'consumerFinancingApplication',
        filePath: 'consumer-financing-application',
        extraValidationData: {
          noValidate
        }
      }));
      yield multiStepForm.validateList.perform(formattedObjects);

      try {
        var hashedData = yield (0, _emberConcurrency.hash)({
          lender: this.store.findRecord('consumerFinancingLender', this.args.lenderId),
          applicant: this.store.findRecord('consumerFinancingApplicant', application.get('consumerFinancingApplicant.id')),
          coapplicant: application.get('consumerFinancingCoapplicant.id') > 0 ? this.store.findRecord('consumerFinancingApplicant', application.get('consumerFinancingCoapplicant.id')) : null
        });
        multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.consumerFinancingLender', hashedData.lender);
        multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.consumerFinancingApplicant', hashedData.applicant);
        multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.consumerFinancingCoapplicant', hashedData.coapplicant);
        var theApplication = this.store.createRecord('consumerFinancingApplication', application);
        var savedApplication = yield theApplication.save();
        multiStepForm.formOriginalModelsSetVal('consumerFinancingApplication.model', savedApplication);
        multiStepForm.setupCopyModels.perform();
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
        throw err;
      }

      this.args.customer.get('consumerFinancingApplications').reload();
    } else {
      yield multiStepForm.validateSyncSaveAll({
        overrideOriginalModelsConfig: Ember.Object.create({
          consumerFinancingApplication: {
            model: multiStepForm.formOriginalModelsGetVal('consumerFinancingApplication.model')
          }
        }),
        extraValidationData: {
          noValidate
        },
        notificationNamespace: 'modal'
      });
    }

    buttonPromise.then(() => {
      multiStepForm.currentStepNameKeySetVal('review');
    });
  }), _dec6 = Ember._action, (_class = (_temp = class BaseCreateFinancingLoanDetails extends _createFinancingBase.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "disclosureAgreed", _descriptor4, this);

      _initializerDefineProperty(this, "saveConsumerFinancingApplication", _descriptor5, this);
    }

    buttons() {
      this.args.multiStepForm.registerStepData("loanDetails", 'buttons', [{
        text: this.intl.t('common.actions.back'),
        classNamesString: 'st-btn st-btn-secondary',
        click: () => {
          this.args.multiStepForm.currentStepNameKeySetVal('applicants');
        },
        isDelete: true
      }, {
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.saveConsumerFinancingApplication.perform(this.args.multiStepForm, buttonPromise);
        },
        disabled: !this.disclosureAgreed,
        classNamesString: 'st-btn st-btn-primary save-application'
      }]);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "disclosureAgreed", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "saveConsumerFinancingApplication", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "buttons", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "buttons"), _class.prototype)), _class));
  _exports.default = BaseCreateFinancingLoanDetails;
});