define("securitytrax-ember-ui/all-tasks/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/has-page-title", "securitytrax-ember-ui/mixins/route-pageview-logging", "ember-concurrency", "securitytrax-ember-ui/mixins/scroll-to-top"], function (_exports, _authenticatedRouteMixin, _hasPageTitle, _routePageviewLogging, _emberConcurrency, _scrollToTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _hasPageTitle.default, _routePageviewLogging.default, _scrollToTop.default, {
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    tagsService: Ember.inject.service(),
    store: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['customer_tasks.view']);
    },

    model: function () {
      return this.setupModel.perform();
    },
    setupModel: (0, _emberConcurrency.task)(function* () {
      return Ember.RSVP.hash({
        tags: yield this.get('tagsService.getTagsByObjectType').perform('customer_note'),
        noteTypes: this.store.findAll('noteType'),
        communicationDispositions: this.store.findAll('communicationDisposition'),
        communicationReasons: this.store.findAll('communicationReason')
      });
    }),

    getPageTitle() {
      return '';
    },

    getRouteName() {
      return "common.nav.tasks";
    }

  });

  _exports.default = _default;
});