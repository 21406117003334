define("securitytrax-ember-ui/models/base_models/payroll-role-function", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    columnsDerivedFrom: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['function', 'level']
    }),
    name: (0, _model.attr)('string', {
      'readOnly': false
    }),
    about: (0, _model.attr)('string', {
      'readOnly': false
    }),
    active: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    status: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['active', 'deleted']
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    payrollListMethod: (0, _model.belongsTo)('payroll-list-method', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    payrollRole: (0, _model.belongsTo)('payroll-role', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    payrollRoleFunctionPayrollColumns: (0, _model.hasMany)('payroll-column', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    payrollRoleFunctionLevels: (0, _model.hasMany)('payroll-role-function-level', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    payrollFunctionMethodParameters: (0, _model.hasMany)('payroll-function-method-parameter', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});