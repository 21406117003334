define("securitytrax-ember-ui/components/customer/recent-uploads/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XzejT0p6",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[14,0,\"card-title\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"pages.customers.dashboard.recent_uploads.title\"],null]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"ul\"],[14,0,\"list\"],[12],[2,\"\\n    \"],[10,\"li\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"icn-file\"],null]],[2,\"\\n        Contract PDF\\n        \"],[10,\"span\"],[14,0,\"status\"],[12],[2,\"5/25/16\"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"li\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"icn-file\"],null]],[2,\"\\n        image\\n        \"],[10,\"span\"],[14,0,\"status\"],[12],[2,\"5/25/16\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"svg-icon\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/customer/recent-uploads/template.hbs"
    }
  });

  _exports.default = _default;
});