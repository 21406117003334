define("securitytrax-ember-ui/demo/dashboard-config/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller.extend({
    showConfig: false
  });
  var _default = Controller;
  _exports.default = _default;
});