define("securitytrax-ember-ui/all-appointments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I/8EW80e",
    "block": "{\"symbols\":[],\"statements\":[[8,\"pages/all-appointments\",[],[[\"@tags\"],[[34,0,[\"tags\"]]]],null],[2,\"\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"model\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/all-appointments/template.hbs"
    }
  });

  _exports.default = _default;
});