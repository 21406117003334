define("securitytrax-ember-ui/customers/information/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({//Controller file exists just for the ADT mega form setup (app/customers/information/manage-adt/manage-account)
    // Uses this file to set some properties for the form
  });

  _exports.default = _default;
});