define("securitytrax-ember-ui/services/page-title", ["exports", "securitytrax-ember-ui/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),

    setPageTitle(title, route) {
      if (!title) {
        title = '';
      }

      var separator = _environment.default.titleSeparator ? _environment.default.titleSeparator : '';
      var firstSeparator = title ? separator : '';
      (0, _jquery.default)(document).attr('title', title.concat(firstSeparator, this.intl.t(route, {
        separator
      }), separator, this.intl.t('common.securitytrax')));
    }

  });

  _exports.default = _default;
});