define("securitytrax-ember-ui/components/customer/contract-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TX/dzlTp",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[14,0,\"card-title\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"pages.customers.dashboard.contract_info.title\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"card-data row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col col-4 col-md-12\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"card-data-header\"],[12],[2,\"\\n            $50\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"card-data-value\"],[12],[2,\"\\n            Monthly Rate\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col col-4 col-md-12\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"card-data-header\"],[12],[2,\"\\n            1yr 2mo\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"card-data-value\"],[12],[2,\"\\n            Contract Term\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col col-4 col-md-12\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"card-data-header\"],[12],[2,\"\\n            3/14/2014\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"card-data-value\"],[12],[2,\"\\n            Start Date\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/customer/contract-info/template.hbs"
    }
  });

  _exports.default = _default;
});