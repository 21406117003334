define("securitytrax-ember-ui/components/html/user-search/component", ["exports", "@glimmer/component", "ember-concurrency", "lodash", "jquery"], function (_exports, _component, _emberConcurrency, _lodash, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var UserSearch = (_dec = Ember.inject.service('intl'), _dec2 = Ember.inject.service('router'), _dec3 = Ember.inject.service('orgTree'), _dec4 = Ember.inject.service('stNotificationManager'), _dec5 = Ember.inject.service('store'), _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = (0, _emberConcurrency.task)(function* () {
    this.searchUsers;
    yield (0, _emberConcurrency.timeout)(500); //make sure they are done typing before searching

    var newSearchResults = yield this.getSearchResults.perform();
    Ember.run.next(() => {
      (0, _jquery.default)('.user-search__trigger').focus();
    });
    return newSearchResults;
  }).restartable(), _dec9 = (0, _emberConcurrency.task)(function* () {
    try {
      var fullList = yield this.allUsersList;

      if (this.searchUsers) {
        var searchValueLower = this.searchUsers.toLowerCase();

        var newSearchResults = _lodash.default.filter(fullList, function (user) {
          return user.get('fname').toLowerCase().includes(searchValueLower) || user.get('lname').toLowerCase().includes(searchValueLower) || user.get('flname').toLowerCase().includes(searchValueLower) || user.get('lfname').toLowerCase().includes(searchValueLower) || user.get('id').toLowerCase().includes(searchValueLower);
        });

        return newSearchResults;
      } else {
        return fullList;
      }
    } catch (response) {
      this.stNotificationManager.addErrorMessages(response);
    }
  }).restartable().maxConcurrency(1), _dec10 = (0, _emberConcurrency.task)(function* () {
    return (yield this.args.directoryUsers).toArray();
  }).drop(), _dec11 = Ember._action, (_class = (_temp = class UserSearch extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "orgTree", _descriptor3, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor4, this);

      _initializerDefineProperty(this, "store", _descriptor5, this);

      _initializerDefineProperty(this, "searchUsers", _descriptor6, this);

      _initializerDefineProperty(this, "allUsersList", _descriptor7, this);

      _initializerDefineProperty(this, "updateSearchTask", _descriptor8, this);

      _initializerDefineProperty(this, "getSearchResults", _descriptor9, this);

      _initializerDefineProperty(this, "findUsers", _descriptor10, this);

      this.allUsersList = this.findUsers.unlinked().perform();
    }

    get searchResults() {
      return this.updateSearchTask.perform();
    }

    selection(user, dropdown) {
      var router = this.router;
      var orgTree = this.orgTree;
      dropdown.actions.close();
      router.transitionTo('/administration/org-chart/' + orgTree.get('userHierarchyTree.id') + '/user/' + user.get('id'));
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "orgTree", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "searchUsers", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "allUsersList", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "updateSearchTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "getSearchResults", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "findUsers", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "selection", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "selection"), _class.prototype)), _class));
  /*  performSearch(searchQuery) {
        if (this.get('selectedResource').name == 'Search for') {
           this.set('selectedResource', {value: 1, name: this.get('intl').t('common.actions.searchOptions.resources.customer')});
           this.set('selectedProperty', {value: 2, name: this.get('intl').t('common.actions.searchOptions.properties.name')});
       }
        this.set('searchString', searchQuery);
       return this.get('searchTask').perform(searchQuery);
   },
    disableKeys(options, e) {//eslint-disable-line no-unused-vars
       if(this.get('searchTask.isRunning') && e.keyCode === 13) {
           return false;
       }
   },
    goToResource(item) {
       const resource = this.get('selectedResource');
       switch(resource.value) {
           default:
           case 1: //customer
               this.get('router').transitionTo(`customers.information`, item.id);
               break;
           case 2: //user
               this.get('router').transitionTo(`users.information`, item.id);
               break;
       }
   }*/

  _exports.default = UserSearch;
});