define("securitytrax-ember-ui/components/multi-step-form/forms/payables/edit-create-payable/payable-details/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['isOpen:is-open:is-closed'],
    isOpen: false,
    inReview: false,
    chevronIcon: Ember.computed('isOpen', function () {
      return this.isOpen ? 'icn-chevron-up' : 'icn-chevron-down';
    }),

    get pendingPayableAmount() {
      var total = 0.00;
      this.pendingPayable.customerPayableLineItems.forEach(column => {
        total += +column.get('amount');
      });
      return total;
    },

    actions: {
      toggleOpen() {
        if (!this.inReview) {
          this.toggleProperty('isOpen');
        }
      }

    }
  });

  _exports.default = _default;
});