define("securitytrax-ember-ui/components/multi-step-form/forms/payables/edit-create-payable/payments/component", ["exports", "securitytrax-ember-ui/mixins/table-column-sort", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _tableColumnSort, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableColumnSort.default, {
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    customerPayablePayment: null,
    customerPayablePaymentFormStarted: false,
    customerPayablePaymentFormMode: 'view',
    stSortCurrentSortColumn: 'id',
    stSortAscending: false,

    init() {
      this._super(...arguments);

      var intl = this.intl;
      (0, _objHelpers.initializeState)(this, {
        stSortableColumnOptions: [{
          name: intl.t('common.columns.id') + ' ' + intl.t('common.sorting.ascending'),
          value: 'id',
          chevronDirection: 'up'
        }, {
          name: intl.t('common.columns.id') + ' ' + intl.t('common.sorting.descending'),
          value: '-id',
          chevronDirection: 'down'
        }]
      });
    },

    totalOwed: Ember.computed('lineItems.@each.amount', function () {
      var amount = 0.00;
      this.lineItems.forEach(item => {
        amount += Number(item.get('amount'));
      });
      return amount;
    }),
    totalPaid: Ember.computed('payments.@each.amount', function () {
      var customerPayablePayments = this.payments || [];
      var total = 0.00;
      customerPayablePayments.forEach(payment => {
        total += Number(payment.get('amount'));
      });
      return total;
    }),
    totalDue: Ember.computed('totalOwed', 'totalPaid', function () {
      return this.totalOwed - this.totalPaid;
    }),
    customerPayablePayments: Ember.computed('stSortCurrentSortColumn', 'stSortAscending', function () {
      var payments = this.payments || [];

      if (this.stSortAscending) {
        return payments.sortBy('stSortCurrentSortColumn');
      } else {
        return payments.sortBy('stSortCurrentSortColumn').reverse();
      }
    }),
    formSteps: Ember.computed('grants.userGrants', 'customerPayablePayment', function () {
      var intl = this.intl;
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.customerPayablePayment ? intl.t('common.forms.descriptive_title_view', {
            name: this.intl.t('common.columns.transaction'),
            id: this.get('customerPayablePayment.id')
          }) : intl.t('pages.customers.accounting.payables.new_transaction'),
          stepComponentPath: 'multi-step-form/forms/payables/edit-create-payment'
        })
      });
    }),
    formOriginalModels: Ember.computed('customerPayablePayment', function () {
      return Ember.Object.create({
        customerPayablePayment: {
          model: this.customerPayablePayment
        }
      });
    }),
    actions: {
      createPayment() {
        this.set('customerPayablePaymentFormMode', 'create');
        this.set('customerPayablePayment', null);
        this.notifyPropertyChange('customerPayablePayment');
        this.set('customerPayablePaymentFormStarted', true);
      },

      editPayment(payment) {
        if (this.grants.getIndividualGrant('customer_payable_payments.modify') && !this.myAccount) {
          this.set('customerPayablePaymentFormMode', 'edit');
        }

        this.set('customerPayablePayment', payment);
        this.set('customerPayablePaymentFormStarted', true);
      },

      refreshModel() {
        this.notifyPropertyChange('customerPayablePayments');

        if (this.registerButtons) {
          this.registerButtons();
        }
      },

      //action sent to sortable-column component
      sortList(column, ascending) {
        this.sortPaginatedList(column, ascending, 'recordsLoaded');
      },

      mobileSortList(column) {
        this.sortMobilePaginatedList(column.value, 'recordsLoaded');
        this.set('stSortAscending', !this.stSortString.includes('-'));
        this.set('stSortCurrentSortColumn', this.stSortString.replace('-', ''));
      }

    }
  });

  _exports.default = _default;
});