define("securitytrax-ember-ui/components/multi-step-form/forms/customer-equipment/details/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer-equipment/customer-equipment-base", "ember-concurrency", "securitytrax-ember-ui/mixins/format-appointment-mixin"], function (_exports, _customerEquipmentBase, _emberConcurrency, _formatAppointmentMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerEquipmentBase.default.extend(_formatAppointmentMixin.default, {
    grants: Ember.inject.service(),
    store: Ember.inject.service(),
    screen: Ember.inject.service(),
    selectedAddOption: 1,
    monitoringCompanyType: undefined,

    equipmentMatcher(equipment, term) {
      return "".concat(equipment.name, " ").concat(equipment.description, " ").concat(equipment.model, " ").concat(equipment.sku, " ").concat(equipment.manufacturer).toLowerCase().indexOf(term);
    },

    initTask: (0, _emberConcurrency.task)(function* () {
      yield this.initBaseData.perform();

      if (this.isNewRecord) {
        var equipmentStatuses = yield this.equipmentStatuses;

        if (this.monitoringCompanyType && this.monitoringCompanyType.get('id') === '27') {
          var equipmentStatus = equipmentStatuses.findBy('id', '2');
          this.multiStepForm.formCopiedModelsSetVal('customerEquipment.model.equipmentStatus', equipmentStatus);
        } else {
          var _equipmentStatus = equipmentStatuses.findBy('id', '1');

          this.multiStepForm.formCopiedModelsSetVal('customerEquipment.model.equipmentStatus', _equipmentStatus);
        }
      }
    }).on('init'),
    workOrderActions: Ember.computed(function () {
      return this.store.findAll('workOrderAction');
    }),
    equipment: Ember.computed(function () {
      return this.store.query('equipment', {
        limit: 0
      });
    }),
    technicianList: Ember.computed(function () {
      return this.userService.getUsersList('tech', this.customer);
    }),
    equipmentWireTypes: Ember.computed(function () {
      return this.store.findAll('equipmentWireType');
    }),
    calendarEvents: Ember.computed(function () {
      return this.getCalendarEventsTask.perform();
    }),
    getCalendarEventsTask: (0, _emberConcurrency.task)(function* () {
      var grants = this.grants;
      var store = this.store;
      var customer = this.customer;
      return (yield grants.get('getIndividualGrantAsync').perform('calendar_events.view')) ? store.query('calendarEvent', {
        filter: '(customer = ' + customer.get('id') + ')',
        include: 'calendar_user,customer,calendar_event_type'
      }) : [];
    }),
    isRemovedStatus: Ember.computed('multiStepForm.formCopiedModels.customerEquipment.model.equipmentStatus', function () {
      var status = this.multiStepForm.formCopiedModelsGetVal('customerEquipment.model.equipmentStatus.id');
      return Number(status) === 3; // true if status=Removed (3)
    }),

    saveCustomerEquipment(multiStepForm, buttonPromise) {
      return this.createFromManual.perform(multiStepForm, buttonPromise);
    },

    setItemDefaults: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = this.multiStepForm;
      multiStepForm.formCopiedModelsSetVal('customerEquipment.model.itemCharge', this.multiStepForm.formCopiedModelsGetVal('customerEquipment.model.equipment.value'));
      multiStepForm.formCopiedModelsSetVal('customerEquipment.model.equipmentWireType', yield this.multiStepForm.formCopiedModelsGetVal('customerEquipment.model.equipment.equipmentWireType'));
    }),
    actions: {
      defaultTech(calendarEvent, eventType) {
        var multiStepForm = this.multiStepForm;

        if (calendarEvent) {
          multiStepForm.formCopiedModelsSetVal("customerEquipment.model.".concat(eventType), calendarEvent.get('calendarUser.id'));
          this.setUser.perform(this.multiStepForm.formCopiedModelsGetVal('customerEquipment.model'), eventType, calendarEvent.get('calendarUser.id'));
        }
      }

    }
  });

  _exports.default = _default;
});