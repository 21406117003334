define("securitytrax-ember-ui/components/multi-step-form/forms/administration/permissions/add-group-users/review/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/switchable-form-step", "securitytrax-ember-ui/components/multi-step-form/forms/administration/permissions/permissions-base"], function (_exports, _emberConcurrency, _switchableFormStep, _permissionsBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _permissionsBase.default.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('review', 'buttons', [{
        text: this.intl.t('common.actions.back'),
        click: () => {
          multiStepForm.currentStepNameKeySetVal('step1');
        },
        classNamesString: 'st-btn st-btn-secondary cancel-group-users-button'
      }, {
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.addGroupUsersTask.perform(buttonPromise, multiStepForm);
        },
        classNamesString: 'st-btn st-btn-primary save-group-users-button'
      }]);
    },

    addGroupUsersTask: (0, _emberConcurrency.task)(function* (buttonPromise, multiStepForm) {
      try {
        var originalGroup = yield this.addEditGroup;
        var newUsers = this.addArray.map(a => a.user);
        var usersToSave = [];
        newUsers.forEach(user => {
          user.set('group', originalGroup);
          usersToSave.push(user.save());
        });
        yield (0, _emberConcurrency.all)(usersToSave); //notify parent list that it has new users

        this.set('newGroupUsers', newUsers);
        buttonPromise.then(() => {
          this.set('addArray', []);
          multiStepForm.formStartedSetVal(false);
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
        (false && Ember.warn('HR add users to group error' + err, {
          id: 'multiStepForm.forms.administration.permissions.addGroupUsers.addGroupUsersTask'
        }));
        throw err;
      }
    })
  });

  _exports.default = _default;
});