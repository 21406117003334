define("securitytrax-ember-ui/components/pages/customer-dashboard/component", ["exports", "securitytrax-ember-ui/mixins/sortable-list-mixin", "securitytrax-ember-ui/mixins/property-saver-mixin"], function (_exports, _sortableListMixin, _propertySaverMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_propertySaverMixin.default, _sortableListMixin.default, {
    sessionData: Ember.inject.service(),
    grants: Ember.inject.service(),
    homeDashboard: Ember.inject.service('events/home-dashboard'),
    user: undefined,
    deckOfCards: null,
    hover: false,

    init() {
      this._super(...arguments);

      this.homeDashboard.on('dashboard-config-saved', this, '_eventCallback');
    },

    willDestroy() {
      this.homeDashboard.off('dashboard-config-saved', this, '_eventCallback');
    },

    _eventCallback(dashboardLocation) {
      if (dashboardLocation === 'customer_dashboard') {
        this.notifyPropertyChange('dashboardCards');
      }
    },

    get dashboardCards() {
      return this.model.editDashboard.getEmberCardsTask.perform(this);
    }

  });

  _exports.default = _default;
});