define("securitytrax-ember-ui/components/pages/administration/company-settings/integrations/payment-processing/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    paymentProcessingForm: Ember.computed(function () {
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.intl.t('pages.administration.integrations.payment_processing.gateway_title'),
          stepComponentPath: 'multi-step-form/forms/payment-processing'
        })
      });
    }),
    paymentProcessingFormOriginalModels: Ember.computed('model.processingGateway', function () {
      return Ember.Object.create({
        processingGateway: {
          model: this.get('model.processingGateway')
        }
      });
    })
  });

  _exports.default = _default;
});