define("securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/greensky--dead/agreement/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/create-financing-base", "ember-concurrency"], function (_exports, _createFinancingBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // Deprecated for DealerAlly, may come back for some other lender.
  var CreateFinancingAgreement = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = (0, _emberConcurrency.task)(function* () {
    if (!this.SwitchableFormStep.isNewRecord) {
      try {
        var application = this.args.multiStepForm.formOriginalModelsGetVal('consumerFinancingApplication.model');
        var loanAgreement = yield application.invokeAction('consumer_finance_application_invoke_generic', {
          verb: 'POST',
          data: {
            data: {
              method: 'greensky__getLoanAgreement',
              payload: {
                'greensky__getLoanAgreementRequest': {
                  client_ip: yield this.clientIp
                }
              }
            }
          }
        });
        this.args.setAttribute('offerDetails', loanAgreement.responsePayload.data.payload.greensky__getLoanAgreementResponse.loan_agreement);

        if (loanAgreement.responsePayload.data.messages && loanAgreement.responsePayload.data.messages.length > 0) {
          this.stNotificationManager.addInfoMessages(loanAgreement.responsePayload.data.messages, 'modal');
        }
      } catch (err) {
        this.hasErrors = true;
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
      }
    }
  }), _dec7 = (0, _emberConcurrency.task)(function* () {
    yield this.getLoanAgreementTask.perform();
    buttonPromise.then(() => {
      this.loanDocumentFormStarted = true;
    });
  }), _dec8 = Ember._action, (_class = (_temp = class CreateFinancingAgreement extends _createFinancingBase.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "grants", _descriptor4, this);

      _initializerDefineProperty(this, "loanDocumentFormStarted", _descriptor5, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor6, this);

      _initializerDefineProperty(this, "viewLoanAgreement", _descriptor7, this);

      this.initDataTask.perform();
    }

    get loanDetails() {
      return [{
        name: this.intl.t('pages.customers.accounting.financing.application_id'),
        value: this.args.offerDetails.application_id
      }, {
        name: this.intl.t('pages.customers.accounting.financing.borrower_name'),
        value: this.args.offerDetails.applicant_name
      }, {
        name: this.intl.t('pages.customers.accounting.financing.lender'),
        value: this.args.offerDetails.lender
      }, {
        name: this.intl.t('pages.customers.accounting.financing.amount_financed'),
        value: this.args.offerDetails.amount_financed
      }, {
        name: this.intl.t('pages.customers.accounting.financing.interest_rate'),
        value: this.args.offerDetails.apr
      }, {
        name: this.intl.t('pages.customers.accounting.financing.post_promo_loan_term'),
        value: this.args.offerDetails.monthly_payment
      }, {
        name: this.intl.t('pages.customers.accounting.financing.promotion_details'),
        value: this.args.offerDetails.promotion_details
      }, {
        name: this.intl.t('pages.customers.accounting.financing.loan_type'),
        value: this.args.offerDetails.credit_product
      }];
    }

    get loanDocumentFormSteps() {
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.intl.t('pages.customers.accounting.financing.loan_agreement'),
          stepComponentPath: 'multi-step-form/forms/customer/create-financing/agreement/loan-document'
        })
      });
    }

    buttons() {
      this.args.multiStepForm.registerStepData("agreement", 'buttons', [{
        text: this.intl.t('pages.customers.accounting.financing.view_loan_agreement'),
        click: buttonPromise => {
          return this.viewLoanAgreement.perform(buttonPromise);
        },
        isDelete: true,
        classNamesString: 'st-btn st-btn-green'
      }, {
        text: this.intl.t('common.actions.activate'),
        click: () => {
          multiStepForm.set('currentStepNameKey', 'activation');
        },
        classNamesString: 'st-btn st-btn-primary'
      }]);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "loanDocumentFormStarted", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "viewLoanAgreement", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "buttons", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "buttons"), _class.prototype)), _class));
  _exports.default = CreateFinancingAgreement;
});