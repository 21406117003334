define("securitytrax-ember-ui/services/st-data-list-fast", ["exports", "lodash", "jquery"], function (_exports, _lodash, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    multicallDataObject: undefined,

    init() {
      this._super(...arguments);

      this.multicallDataObject = {};
    },

    watchRowToggleButtons(options, callback) {
      var compiledOptions = _lodash.default.merge({
        toggleOnClass: '',
        toggleOffClass: ''
      }, options);

      if (!compiledOptions.rowsSelector || !compiledOptions.toggleName) {
        throw 'you must pass in rowsSelector and toggleName';
      }

      (0, _jquery.default)("body ".concat(compiledOptions.rowsSelector)).each(function () {
        var theRow = (0, _jquery.default)(this);
        var rowData = theRow.data();

        if (!rowData['listFastRowData']) {
          throw 'you must have [data-list-fast-row-data] on each list item';
        }

        var rowToggleInput = (0, _jquery.default)("[data-list-fast-toggle-name=\"".concat(compiledOptions.toggleName, "\"]"), this);

        if (rowToggleInput.length != 1) {
          throw 'you must name your toggle inputs with selector [data-list-fast-toggle-name="toggleName"]';
        }

        rowToggleInput.on('click', function (event) {
          var target = (0, _jquery.default)(event.target);
          var input = target.parent().find('input');
          var targetIsNowChecked = !input.prop('checked');
          input.prop('checked', targetIsNowChecked);

          if (targetIsNowChecked) {
            theRow.addClass(compiledOptions.toggleOffClass);
            theRow.removeClass(compiledOptions.toggleOnClass); // also enable all inputs in row

            (0, _jquery.default)('.masked-input input, .text-input input', theRow).prop('disabled', false);
          } else {
            theRow.addClass(compiledOptions.toggleOnClass);
            theRow.removeClass(compiledOptions.toggleOffClass); // also disable all inputs in row

            (0, _jquery.default)('.masked-input input, .text-input input', theRow).prop('disabled', true);
          }

          callback({
            rowData: rowData['listFastRowData'],
            jQueryToggleContext: this,
            jQueryRowContext: theRow,
            event: event
          });
        });
      });
    },

    toggleRowToggleButtons(options) {
      var compiledOptions = _lodash.default.merge({
        toggleOnClass: '',
        toggleOffClass: '',
        toggleOn: true
      }, options);

      if (!compiledOptions.rowsSelector || !compiledOptions.toggleName) {
        throw 'you must pass in rowsSelector and toggleName';
      }

      (0, _jquery.default)("body ".concat(compiledOptions.rowsSelector)).each(function () {
        var theRow = (0, _jquery.default)(this);
        var rowData = theRow.data();

        if (!rowData['listFastRowData']) {
          throw 'you must have [data-list-fast-row-data] on each list item';
        }

        var rowToggleInput = (0, _jquery.default)("[data-list-fast-toggle-name=\"".concat(compiledOptions.toggleName, "\"]"), this);

        if (rowToggleInput.length != 1) {
          throw 'you must name your toggle inputs with selector [data-list-fast-toggle-name="toggleName"]';
        }

        var input = rowToggleInput.parent().find('input');

        if (compiledOptions.toggleOn) {
          if (input.prop('checked') === false) {
            rowToggleInput.trigger('click');
          }
        } else {
          if (input.prop('checked') === true) {
            rowToggleInput.trigger('click');
          }
        }
      });
    },

    watchRowTextInput(options, callback) {
      var compiledOptions = _lodash.default.merge({// option1Default: '',
      }, options);

      if (!compiledOptions.rowsSelector || !compiledOptions.inputName) {
        throw 'you must pass in rowsSelector and inputName';
      }

      (0, _jquery.default)("body ".concat(compiledOptions.rowsSelector)).each(function () {
        var theRow = (0, _jquery.default)(this);
        var rowData = theRow.data();

        if (!rowData['listFastRowData']) {
          throw 'you must have [data-list-fast-row-data] on each list item';
        }

        var rowInput = (0, _jquery.default)("[data-list-fast-input-name=\"".concat(compiledOptions.inputName, "\"]"), this);

        if (rowInput.length != 1) {
          throw 'you must name your text inputs with selector [data-list-fast-input-name="inputName"]';
        }

        rowInput.on('change', function (event) {
          callback({
            rowData: rowData['listFastRowData'],
            newInputValue: (0, _jquery.default)(this).val(),
            jQueryToggleContext: this,
            jQueryRowContext: theRow,
            event: event
          });
        });
      });
    },

    watchRowSelectInput(options, callback) {
      var compiledOptions = _lodash.default.merge({// option1Default: '',
      }, options);

      if (!compiledOptions.rowsSelector || !compiledOptions.inputName) {
        throw 'you must pass in rowsSelector and inputName';
      }

      (0, _jquery.default)("body ".concat(compiledOptions.rowsSelector)).each(function () {
        var theRow = (0, _jquery.default)(this);
        var rowData = theRow.data();

        if (!rowData['listFastRowData']) {
          throw 'you must have [data-list-fast-row-data] on each list item';
        }

        var rowInput = (0, _jquery.default)("[data-list-fast-select-name=\"".concat(compiledOptions.inputName, "\"]"), this);

        if (rowInput.length != 1) {
          throw 'you must name your select inputs with selector [data-list-fast-select-name="inputName"]';
        }

        rowInput.on('change', function (event) {
          callback({
            rowData: rowData['listFastRowData'],
            newInputValue: (0, _jquery.default)(this).val(),
            jQueryToggleContext: this,
            jQueryRowContext: theRow,
            event: event
          });
        });
      });
    },

    getTextInputHtml(name, component, inputType) {
      return "\n            <span class=\"cell-content\">\n                <div data-test-".concat(name, " class=\"switchable-input ").concat(component, " form-mode-view greyable-row-item\">\n                    <input\n                        data-list-fast-input-name=\"").concat(name, "\"\n\n                        spellcheck=\"false\"\n                        autocapitalize=\"none\"\n                        autocorrect=\"off\"\n                        autocomplete=\"off\"\n\n                        class=\"").concat(component, "\"\n                        type=").concat(inputType, "\n                    >\n                </div>\n            </span>\n        ");
    },

    getToggleHtml(name) {
      return "\n            <span class=\"cell-content greyable-row-item\">\n                <div class=\"switchable-input checkbox-input\">\n                    <div data-test-".concat(name, " class=\"st-toggle\">\n                        <input \n                            type=\"checkbox\"\n                        >\n                        <span\n                            data-list-fast-toggle-name=\"").concat(name, "\"\n                            class=\"slider round\"\n                        />\n                    </div>\n                </div>\n            </span>\n        ");
    },

    getSelectInputHtml(name, optionsHtml) {
      return "\n            <span class=\"cell-content\">\n                <select \n                    data-test-".concat(name, "\n                    data-list-fast-select-name=\"").concat(name, "\"\n                >\n                    ").concat(optionsHtml, "\n                </select>\n            </span>\n        ");
    },

    getTextareaInputHtml(name, rows) {
      return "\n            <span class=\"cell-content\">\n                <textarea\n                    data-list-fast-input-name=\"".concat(name, "\"\n                    rows=").concat(rows, "\n                    spellcheck=\"false\"\n                    class=\"ember-text-area ember-view\"\n                >\n                </textarea>\n            </span>\n        ");
    }

  });

  _exports.default = _default;
});