define("securitytrax-ember-ui/components/inventory/purchase-requisitions/component", ["exports", "ember-concurrency", "lodash"], function (_exports, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    inventoryService: Ember.inject.service(),
    stFiltersService: Ember.inject.service(),
    tagsService: Ember.inject.service(),
    screen: Ember.inject.service(),
    prLimit: 50,
    prPage: 1,
    purchaseRequisitionStatus: undefined,
    purchaseRequisitionFulfilledStatus: undefined,
    createdDateFrom: undefined,
    createdDateTo: undefined,
    purchaseRequisitionRecipient: undefined,
    purchaseRequisitionFormStarted: false,
    purchaseRequisitionFormMode: 'view',
    purchaseRequisition: null,
    admin: false,
    showNotification: true,
    appliedFiltersCount: 0,
    tagFilter: undefined,

    init() {
      this._super(...arguments);

      Ember.set(this, 'tagFilter', this.tagsService.tagFilter());
      this.initTask.perform();
    },

    initTask: (0, _emberConcurrency.task)(function* () {
      Ember.set(this, 'tagsOptions', yield this.tagsService.tagsOptionsTask.perform('pending_equipment'));
    }),

    get filterStorageKey() {
      return this.admin ? 'adminPurchaseRequisitionsList' : 'purchaseRequisitionsList';
    },

    get prListDefault() {
      return this.statusOptions.filter(status => {
        return this.admin ? status.value === 'submitted' : status.value === 'open';
      });
    },

    prsNotice: Ember.computed(function () {
      return this.admin ? this.intl.t('common.filters.default_filters_applied') + ' ' + this.intl.t('pages.administration.company_settings.inventory.purchase_requisitions.description', {
        link: 'https://answers.securitytrax.com/Support/Company_and_Admin/Inventory_Management/Purchase_Requisitions'
      }) : this.intl.t('common.filters.default_filters_applied');
    }),
    fulfilledStatusOptions: Ember.computed(function () {
      var intl = this.intl;
      return [{
        value: 'none',
        name: intl.t('pages.users.my_inventory.purchase_orders.status.none')
      }, {
        value: 'partially',
        name: intl.t('pages.users.my_inventory.purchase_orders.status.partially')
      }, {
        value: 'full',
        name: intl.t('pages.users.my_inventory.purchase_orders.status.in_full')
      }];
    }),
    statusOptions: Ember.computed(function () {
      var intl = this.intl;
      var options = [{
        value: 'submitted',
        name: intl.t('pages.users.my_inventory.purchase_orders.submitted')
      }, {
        value: 'closed',
        name: intl.t('pages.users.my_inventory.purchase_orders.closed')
      }];

      if (this.admin === false) {
        options.push({
          value: 'open',
          name: intl.t('common.status.open')
        });
      }

      return options;
    }),
    recipientOptions: Ember.computed(function () {
      return this.inventoryService.get('getInventoryRecipients').perform();
    }),
    purchaseRequisitionData: Ember.computed(function () {
      return this.getPurchaseRequisitionsTask.perform();
    }),
    getPurchaseRequisitionsTask: (0, _emberConcurrency.task)(function* () {
      var defaultFilter = !this.admin ? ['user = ' + this.user.get('id')] : ["status != 'open'"];
      var newFilters = yield this.stFiltersService.getFiltersByGroupKey(this.filterStorageKey, defaultFilter);
      var queryParams = {
        filter: newFilters,
        sort: '-id',
        limit: this.prLimit,
        page: this.prPage,
        include: 'user,location,equipment_vendor,pending_equipment_line_items,tags',
        count: 1
      };
      var prs = yield this.store.query('equipmentPurchaseRequisition', queryParams);
      return {
        purchaseRequisitions: prs,
        record_count: _lodash.default.get(prs, 'meta.record_count', 0)
      };
    }),
    purchaseRequisitionForm: Ember.computed('purchaseRequisitionFormMode', 'purchaseRequisition.{id,user.flname}', function () {
      var titleData = {
        id: this.get('purchaseRequisition.id'),
        userName: this.get('purchaseRequisition.user.id') ? this.get('purchaseRequisition.user.flname') : this.get('purchaseRequisition.location.name')
      };
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.intl.t('pages.users.my_inventory.purchase_orders.' + this.purchaseRequisitionFormMode + '_pr_title', titleData),
          stepComponentPath: 'multi-step-form/forms/administration/users/my-inventory/purchase-orders/edit-create-purchase-requisition'
        })
      });
    }),
    prFormOriginalModels: Ember.computed('purchaseRequisition', 'grants.userGrants', function () {
      return Ember.Object.create({
        equipmentPurchaseRequisition: {
          model: this.purchaseRequisition,
          copyRelationMap: {
            user: {
              doCopy: true,
              doValidate: false,
              doSave: false
            },
            location: {
              readOnly: true
            },
            createdByUser: {
              doCopy: true,
              doValidate: false,
              doSave: false
            },
            updatedByUser: {
              doCopy: true,
              doValidate: false,
              doSave: false
            },
            pendingEquipmentLineItems: {
              doValidate: false,
              relationships: {
                equipment: {
                  doCopy: true,
                  doSave: false
                }
              }
            }
          }
        }
      });
    }),
    pendingPoSavedObserver: Ember.observer('pendingPurchaseOrderSaved', function () {
      this.set('pendingPurchaseOderSaved', false);
      Ember.notifyPropertyChange(this, 'purchaseRequisitionData');
    }),
    actions: {
      editPurchaseRequisitionAction(row) {
        if (this.grants.getIndividualGrant('equipment_purchase_requisitions.modify')) {
          this.set('purchaseRequisitionFormMode', 'edit');
        }

        this.set('purchaseRequisition', row);
        this.set('purchaseRequisitionFormStarted', true);
      },

      updatePurchaseRequisitions() {
        this.notifyPropertyChange('purchaseRequisitionData');
      },

      filterSearch() {
        this.set('prPage', 1);
        this.notifyPropertyChange('purchaseRequisitionData');
      },

      clearFilter() {
        Ember.setProperties(this, {
          purchaseRequisitionStatus: this.prListDefault,
          purchaseRequisitionFulfilledStatus: undefined,
          createdDateFrom: undefined,
          createdDateTo: undefined,
          purchaseRequisitionRecipient: undefined,
          purchaseRequisitionsLoaded: false,
          tagFilter: this.tagsService.tagFilter()
        });
        this.notifyPropertyChange('purchaseRequisitionData');
        this.notifyPropertyChange('prPage');
      }

    }
  });

  _exports.default = _default;
});