define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/edit-create-credit-bureau/adt/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    monitoringCompanies: Ember.computed(function () {
      return this.store.query('monitoringCompany', {
        filter: 'monitoring_company_type=1'
      });
    })
  });

  _exports.default = _default;
});