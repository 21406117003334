define("securitytrax-ember-ui/mixins/forms/adc-receiver-numbers", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ispService: Ember.inject.service(),
    grants: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    adcReceiverNumbers: Ember.computed('customer.customerOrderInformation.interactiveServicesProvider.id', 'grants.userGrants', function () {
      return this.get('customer.customerOrderInformation.interactiveServicesProvider.id') && this.grants.getIndividualGrant('interactive_services_providers.manage_interactive_services_provider_integration') ? this.adcReceiverNumbersTask.perform() : [];
    }),
    adcReceiverNumbersTask: (0, _emberConcurrency.task)(function* () {
      var receiverNumbers = [];
      var isp = yield this.get('customer.customerOrderInformation.interactiveServicesProvider');
      var ispType = yield isp.get('interactiveServicesProviderType');

      if (isp && ispType && ispType.get('name') === 'Alarm.com' && isp.get('isConnected')) {
        try {
          var adcReceiverNumbers = yield this.ispService.get('invokeIspActionTask').perform({
            action: 'alarm_dot_com__getCSPhoneNumbers',
            model: isp,
            attributes: {}
          });

          if (adcReceiverNumbers && adcReceiverNumbers.cs_phone_numbers) {
            adcReceiverNumbers.cs_phone_numbers.forEach(phone => {
              receiverNumbers.push({
                label: phone.cs_phone_key + ' (' + phone.phone_desc + ')',
                value: phone.cs_phone_key,
                action: () => {
                  this.set('receiverNumber', phone.cs_phone_key);
                },
                classNamesString: 'update-adc-receiver-number'
              });
            });
          }
        } catch (err) {
          this.stNotificationManager.addWarningKey('pages.customers.system.interactiveServicesProvider.error_retrieving_receiver_numbers', 'modal');
        }
      }

      return receiverNumbers;
    }),
    toggleReceiverNumbersMenuTask: (0, _emberConcurrency.task)(function* () {
      var receiverNumbers = yield this.adcReceiverNumbers;

      if (receiverNumbers.get('length') === 1) {
        this.set('receiverNumber', receiverNumbers[0].value);
      }
    })
  });

  _exports.default = _default;
});