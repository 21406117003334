define("securitytrax-ember-ui/components/graphs/progress-circle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/qF+rqyu",
    "block": "{\"symbols\":[],\"statements\":[[10,\"svg\"],[14,\"viewBox\",\"0 0 36 36\"],[15,0,[31,[\"circular-chart \",[34,4]]]],[12],[2,\"\\n    \"],[10,\"path\"],[14,0,\"circle-bg\"],[14,\"d\",\"M18 2.0845\\n        a 15.9155 15.9155 0 0 1 0 31.831\\n        a 15.9155 15.9155 0 0 1 0 -31.831\"],[12],[13],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,5],[[35,0],0],null],[30,[36,5],[[35,1],0],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"path\"],[14,0,\"circle\"],[15,\"stroke\",[34,2]],[15,\"stroke-dasharray\",[31,[[34,3],\", 100\"]]],[14,\"d\",\"M18 2.0845\\n        a 15.9155 15.9155 0 0 1 0 31.831\\n        a 15.9155 15.9155 0 0 1 0 -31.831\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,9],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"text\"],[14,\"x\",\"18\"],[14,\"y\",\"20.35\"],[14,0,\"category-count\"],[12],[1,[34,0]],[2,\"/\"],[1,[34,1]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"completedCount\",\"totalCount\",\"computedBarColor\",\"progressBar\",\"additionalClasses\",\"eq\",\"or\",\"unless\",\"showCount\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/graphs/progress-circle/template.hbs"
    }
  });

  _exports.default = _default;
});