define("securitytrax-ember-ui/login/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller.extend({
    queryParams: ['error'],
    error: null,
    showError: Ember.computed('error', function () {
      var errorMessage = this.error;
      this.set('error', null); // This removes the error query param from the url so user does not see it

      return errorMessage;
    })
  });
  var _default = Controller;
  _exports.default = _default;
});