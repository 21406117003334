define("securitytrax-ember-ui/components/customer/information/customer-information/component", ["exports", "securitytrax-ember-ui/mixins/property-saver-mixin", "securitytrax-ember-ui/mixins/sortable-list-mixin"], function (_exports, _propertySaverMixin, _sortableListMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_propertySaverMixin.default, _sortableListMixin.default, {
    grants: Ember.inject.service(),
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      Ember.setProperties(this, {
        informationProgressBarConfig: {
          hideProgressBar: true
        }
      });
      Ember.defineProperty(this, 'formOriginalModels', Ember.computed('grants.userGrants', this.store.getFullComputedWatcherStringByModelName('customer'), function () {
        var grants = this.grants;
        return Ember.Object.create({
          customer: {
            model: this.get('model.customer'),
            copyRelationMap: {
              customerBirthdate: {
                doCopy: grants.getIndividualGrant('customer_birthdates.view') || this.get('model.customer.responseMeta.masked_birthdate') === null,
                doValidate: true,
                doSave: grants.getIndividualGrant('customer_birthdates.modify') || this.get('model.customer.responseMeta.masked_birthdate') === null
              },
              customerCreditCheck: {
                readOnly: true,
                doCopy: grants.getIndividualGrant('customer_credit_checks.view'),
                doValidate: grants.getIndividualGrant('customer_credit_checks.view'),
                doSave: grants.getIndividualGrant('customer_credit_checks.modify')
              },
              customerCreditChecks: {
                readOnly: true,
                doCopy: grants.getIndividualGrant('customer_credit_checks.view'),
                doValidate: grants.getIndividualGrant('customer_credit_checks.view'),
                doSave: grants.getIndividualGrant('customer_credit_checks.modify')
              },
              location: {
                readOnly: true
              },
              accountClass: {
                readOnly: true
              },
              customerSsn: {
                doCopy: grants.getIndividualGrant('customer_ssns.view') || this.get('model.customer.responseMeta.masked_ssn') === '',
                doValidate: grants.getIndividualGrant('customer_ssns.view'),
                doSave: grants.getIndividualGrant('customer_ssns.modify') || this.get('model.customer.responseMeta.masked_ssn') === ''
              }
            }
          }
        });
      }));
    },

    singleStepForm: Ember.computed(function () {
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: 'does not matter',
          stepComponentPath: 'multi-step-form/forms-dynamic/forms/customer-information/information'
        })
      });
    }),
    actions: {
      reloadFormModel() {
        this.notifyPropertyChange('formOriginalModels');
      }

    }
  });

  _exports.default = _default;
});