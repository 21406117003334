define("securitytrax-ember-ui/components/multi-step-form/forms/administration/payables/line-item-details/line-item-calculation-options/flat-rate/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VSn7Nwt+",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],null,[[\"formMode\",\"value\",\"label\",\"mask\"],[[35,2],[35,1,[\"formCopiedModels\",\"payrollColumn\",\"model\",\"payrollColumnCalculation\",\"flatRate\"]],[30,[36,0],[\"common.columns.amount\"],null],\"currency\"]]]]],\"hasEval\":false,\"upvars\":[\"t\",\"multiStepForm\",\"formMode\",\"multi-step-form/switchable-inputs/masked-input\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/payables/line-item-details/line-item-calculation-options/flat-rate/template.hbs"
    }
  });

  _exports.default = _default;
});