define("securitytrax-ember-ui/administration/inventory/physical-inventory/route", ["exports", "securitytrax-ember-ui/administration/inventory/base-route", "securitytrax-ember-ui/mixins/route-pageview-logging", "securitytrax-ember-ui/mixins/scroll-to-top"], function (_exports, _baseRoute, _routePageviewLogging, _scrollToTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_routePageviewLogging.default, _scrollToTop.default, {
    pageTitle: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['equipment_ledgers.perform_physical_inventory', 'equipment_ledgers.view_on_hand', 'equipment_ledgers.computed.company_inventory'], 'all');
    }

  });

  _exports.default = _default;
});