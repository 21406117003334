define("securitytrax-ember-ui/components/files/component", ["exports", "securitytrax-ember-ui/mixins/property-saver-mixin", "ember-concurrency"], function (_exports, _propertySaverMixin, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_propertySaverMixin.default, {
    stNotificationManager: Ember.inject.service(),
    stAjax: Ember.inject.service(),
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    fileExtensionRegex: /\.[^/.]+$/,
    showDeleteId: false,
    viewPermission: Ember.computed(function () {
      return this.grants.getIndividualGrant("".concat(this.modelToSave, "_files.view"));
    }),
    modifyPermission: Ember.computed(function () {
      return this.grants.getIndividualGrant("".concat(this.modelToSave, "_files.modify"));
    }),
    deletePermission: Ember.computed(function () {
      return this.grants.getIndividualGrant("".concat(this.modelToSave, "_files.delete"));
    }),
    createPermission: Ember.computed(function () {
      return this.grants.getIndividualGrant("".concat(this.modelToSave, "_files.create"));
    }),
    modelPath: Ember.computed(function () {
      return "model.".concat(this.modelToSave, ".").concat(this.modelToSave, "Files");
    }),
    genericFiles: Ember.computed(function () {
      return "".concat(this.modelToSave, "Files");
    }),
    fileListData: Ember.computed(function () {
      return this.fileListDataTask.perform();
    }),
    fileListDataTask: (0, _emberConcurrency.task)(function* () {
      return yield this.get("model.".concat(this.genericFiles));
    }),
    apiEndpoint: Ember.computed(function () {
      return this.stAjax.apiUrl("/".concat(this.modelToSave, "_files"));
    }),
    downloadTask: (0, _emberConcurrency.task)(function* (row) {
      try {
        var downloadUrl = "/".concat(this.modelToSave, "_files/").concat(row.id, "/download");
        row.set('iconName', 'icn-download');
        var result = yield this.stAjax.request(this.stAjax.getUrl(downloadUrl));
        var realDownloadUrl = result.data.meta.download_uri;

        if (realDownloadUrl.startsWith('/') && !realDownloadUrl.startsWith('//') && !realDownloadUrl.startsWith('http')) {
          realDownloadUrl = this.stAjax.get('host') + realDownloadUrl;
        } // download instead of opening in a new window. download link will have attachment headers


        window.location.assign(realDownloadUrl);
      } catch (error) {
        (false && Ember.warn('failed to download file' + error, {
          id: 'files.downloadTask'
        }));
        this.stNotificationManager.addError(this.intl.t('common.notifications.generic_failure', {
          'action': 'download'
        }));
      }
    }),
    previewTask: (0, _emberConcurrency.task)(function* (row) {
      try {
        var downloadUrl = "/".concat(this.modelToSave, "_files/").concat(row.id, "/preview");
        row.set('iconName', 'icn-new-window');
        var result = yield this.stAjax.request(this.stAjax.getUrl(downloadUrl));
        var realDownloadUrl = result.data.meta.preview_uri;

        if (realDownloadUrl.startsWith('/') && !realDownloadUrl.startsWith('//') && !realDownloadUrl.startsWith('http')) {
          realDownloadUrl = this.stAjax.get('host') + realDownloadUrl;
        }

        window.open(realDownloadUrl, '_blank');
      } catch (error) {
        (false && Ember.warn('failed to download file' + error, {
          id: 'files.downloadTask'
        }));
        this.stNotificationManager.addError(this.intl.t('common.notifications.generic_failure', {
          'action': 'preview'
        }));
      }
    }),
    // PERF TODO: Any way to post multiple in one call or something for when we updload multiple at a time?
    fileUploadedTask: (0, _emberConcurrency.task)(function* (file) {
      var response = JSON.parse(file.xhr.responseText);
      var newFile = {
        id: response.data.id,
        name: response.data.attributes.file.replace(this.fileExtensionRegex, ''),
        file: response.data.attributes.file
      };
      newFile[this.modelToSave] = this.get("model.".concat(this.modelToSave));

      try {
        yield (0, _emberConcurrency.hash)({
          createRecord: this.store.createRecord("".concat(this.modelToSave, "File"), newFile),
          findRecord: this.store.findRecord("".concat(this.modelToSave, "File"), newFile.id),
          // Ensures the record shown is "clean" for patch and delete
          reloadFilesRelation: this.get("model.".concat(this.genericFiles)).reload()
        });
        this.stNotificationManager.addSuccess(this.intl.t('common.notifications.generic_success', {
          'action': 'Upload'
        }));
      } catch (err) {
        (false && Ember.warn('failed in upload save' + err, {
          id: 'files.fileUploadedTask'
        }));
        this.stNotificationManager.addErrorMessages(err);
        throw err;
      }
    }),
    deleteFileTask: (0, _emberConcurrency.task)(function* (row) {
      var fileDelete = this.get(this.modelPath).findBy('id', row.get('id'));
      yield fileDelete.destroyRecordWithRollbackTask.perform();
      this.get(this.modelPath).reload();
    }),
    actions: {
      additionalFields(file, xhr, formData) {
        formData.append('file', file.name);
        formData.append('name', file.name.replace(this.fileExtensionRegex, ''));
        formData.append('object_id', this.get("model.".concat(this.modelToSave)).id);
      },

      downloadAction(row) {
        return this.downloadTask.perform(row);
      },

      previewAction(row) {
        return this.previewTask.perform(row);
      },

      startUpload() {
        this.router.transitionTo('customers.files.upload-file');
      }

    }
  });

  _exports.default = _default;
});