define("securitytrax-ember-ui/administration/company-settings/organization/office-locations/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OoGZGpmG",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"breadCrumbs\",\"breadCrumbData\"],[[35,1],[35,0]]]]]],\"hasEval\":false,\"upvars\":[\"breadCrumbData\",\"breadCrumbs\",\"pages/administration/company-settings/organization/office-locations\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/administration/company-settings/organization/office-locations/template.hbs"
    }
  });

  _exports.default = _default;
});