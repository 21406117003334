define("securitytrax-ember-ui/components/dashboard-config/card-wrapper/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CardWrapper = (_dec = Ember.inject.service(), _dec2 = Ember.inject.service(), _dec3 = Ember._action, (_class = (_temp = class CardWrapper extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "grants", _descriptor2, this);
    }

    get cardTitle() {
      return this.args.cardTitle || 'Config';
    }

    get cardFocusOptions() {
      var card = this.args.card ? this.args.editDashboard.availableCards[this.args.card.card] : null;
      var config = card ? card.config : null;

      if (config) {
        return this.args.editDashboard.getCardFocusOptions(this.args.card.card, this.grants);
      }

      return [];
    }

    get dateRangeOptions() {
      var card = this.args.card ? this.args.editDashboard.availableCards[this.args.card.card] : null;
      var config = card ? card.config : null;

      if (config) {
        var allOptions = this.args.editDashboard.availableRelativeDates;

        if (config.date_range_options === 'all') {
          return allOptions;
        } else if (config.date_range_options !== 'none') {
          return allOptions.filter(option => {
            return config.date_range_options.includes(option.relative_date);
          });
        }
      }

      return null;
    }

    changeRoute(route) {
      this.router.transitionTo(route);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "changeRoute", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "changeRoute"), _class.prototype)), _class));
  _exports.default = CardWrapper;
});