define("securitytrax-ember-ui/models/customer-payable-line-item", ["exports", "securitytrax-ember-ui/models/base_models/customer-payable-line-item"], function (_exports, _customerPayableLineItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerPayableLineItem.default.extend({});

  _exports.default = _default;
});