define("securitytrax-ember-ui/models/base_models/customer-equipment", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    quantity: (0, _model.attr)('number', {
      'readOnly': true
    }),
    itemCharge: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'defaultValue': 0,
      'precision': 2
    }),
    isPaidFor: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    equipmentPoints: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    equipmentPar: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    equipmentCost: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    equipmentInstallationCost: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    equipmentValue: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    equipmentDeviceCount: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    equipmentTechBonus: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    serialNumber: (0, _model.attr)('string', {
      'readOnly': false
    }),
    wirelessTransmitterId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    zone: (0, _model.attr)('string', {
      'readOnly': false
    }),
    zoneNumber: (0, _model.attr)('string', {
      'readOnly': false
    }),
    notes: (0, _model.attr)('string', {
      'readOnly': false
    }),
    status: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['active', 'deleted']
    }),
    centralStationId: (0, _model.attr)('string', {
      'readOnly': true
    }),
    interactiveServicesId: (0, _model.attr)('string', {
      'readOnly': true
    }),
    accountingSent: (0, _model.attr)('boolean', {
      'readOnly': true
    }),
    invoiceLineItemId: (0, _model.attr)('number', {
      'readOnly': true
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    customer: (0, _model.belongsTo)('customer', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    equipment: (0, _model.belongsTo)('equipment', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    user: (0, _model.belongsTo)('user', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    installInventoryTechUser: (0, _model.belongsTo)('user', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    uninstallInventoryTechUser: (0, _model.belongsTo)('user', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    techUser: (0, _model.belongsTo)('user', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    equipmentStatus: (0, _model.belongsTo)('equipment-status', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    equipmentWireType: (0, _model.belongsTo)('equipment-wire-type', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    installCalendarEvent: (0, _model.belongsTo)('calendar-event', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    uninstallCalendarEvent: (0, _model.belongsTo)('calendar-event', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    tags: (0, _model.hasMany)('tag', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    udfs: (0, _model.hasMany)('udf', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});