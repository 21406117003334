define("securitytrax-ember-ui/components/calendar-glimmer/calendar-map/component", ["exports", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/leaflet-map", "lodash", "ember-concurrency"], function (_exports, _component, _leafletMap, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /* global L */
  var CalendarView = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service('events/calendar-events'), _dec5 = (0, _emberConcurrency.task)(function* () {
    Ember.set(this, 'calendarEventsSorted', Ember.A());
    Ember.set(this, 'unMappedEvents', Ember.A());
    Ember.set(this, 'users', Ember.A());
    yield this.getEventsTask.perform();
    this.usersArgs.forEach(user => {
      this.users.push(Ember.Object.create({
        user: user,
        mappedEvents: [],
        unMappedEvents: [],
        polylineLocations: null,
        color: null
      }));
    });
    yield this.newEventCustomerTask();
    yield this.addUserStartTask();
    yield this.calendarEventsSortedTask.perform();
    var colors = this.colors.generateColors(this.users.length);

    for (var index = 0; index < this.users.length; index++) {
      var user = this.users.objectAt(index);

      if (user) {
        user.color = colors[index];
        user.polylineLocations = this.calendarEventSortedGeoPointPolylineTask(user);
      }
    }

    yield this.calendarEventSortedGeoPointBoundsTask.perform();
  }), _dec6 = (0, _emberConcurrency.task)(function* () {
    var params = this.args.params;
    var date = moment();
    var userParam = '';

    if (params.date && params.users) {
      if (params.date == 'today') {
        date = moment();
      } else if (params.date == 'tomorrow') {
        date = moment().add(1, 'd');
      } else if (params.date == 'yesterday') {
        date = moment().subtract(1, 'd');
      } else {
        date = moment(params.date, 'MM-DD-YYYY');
      }

      userParam = params.users;
    }

    var startOfDay = moment(date).startOf('day');
    var endOfDay = moment(date).endOf('day');
    var userCat = '';
    var userQuery = '';
    var filter = [];
    var activeString = 'active = true';

    if (userParam.length > 0) {
      filter.push(activeString + ' AND user.id = [' + userParam + ']');
      userQuery = ' and calendar_user.id = [' + userParam + ']';
    } else {
      filter.push(activeString);
    }

    var users = yield this.store.query('calendarUser', {
      filter: filter
      /*  + (this.get('filters.length') > 0 ? (' and ' + (this.filters.join(' and '))) : ''), */
      ,
      include: 'roles,user',
      sort: 'lname'
    });
    var defaultFilter = '(start_time >= \'' + startOfDay.format('YYYY-MM-DDTHH:mm:ss') + '\') and (start_time <= \'' + endOfDay.format('YYYY-MM-DDTHH:mm:ss') + '\') ' + userCat + userQuery;
    var calendarEvents = yield this.store.query('calendarEvent', {
      filter: defaultFilter,
      include: 'calendar_user.user,calendar_user,calendar_event_detail_type,calendar_event_type.calendar_event_detail_types,customer,work_order,dry_run_reason',
      sort: 'calendar_user, start_time'
    });
    Ember.set(this, 'calendarEvents', calendarEvents);
    Ember.set(this, 'usersArgs', users);
    Ember.set(this, 'date', date);
  }), _dec7 = (0, _emberConcurrency.task)(function* () {
    try {
      var calendarEvents = yield this.calendarEvents;

      for (var index = 0; index < calendarEvents.get('length'); index++) {
        var currCalendarEvent = calendarEvents.objectAt(index);

        for (var x = 0; x < this.users.length; x++) {
          var user = this.users.objectAt(x);

          if (user.user.id === currCalendarEvent.get('calendarUser.id')) {
            var customer = currCalendarEvent.get('customer');

            if (customer && !customer.get('hasGeolocationDisabled')) {
              var geoPoint = customer.get('responseMeta.geopoint');
              user.mappedEvents.push(Ember.Object.create({
                calendarEvent: currCalendarEvent,
                geoPoint: L.point(geoPoint.latitude, geoPoint.longitude)
              }));
            } else {
              this.unMappedEvents.pushObject(currCalendarEvent);
            }
          }
        }

        this.calendarEventsSorted.pushObject(currCalendarEvent);
      }
    } catch (error) {
      (false && Ember.warn('calendarEventsSortedTask ' + error, {
        id: 'calendarMap.calendarEventsSortedTask'
      }));
    }
  }), _dec8 = (0, _emberConcurrency.task)(function* () {
    try {
      var geoPoints = [];
      var sortedCalendarEvents = yield this.calendarEventsSorted;

      for (var index = 0; index < sortedCalendarEvents.length; index++) {
        var element = sortedCalendarEvents[index];
        var userAvatar = element.get('calendarUser.avatar');
        var customer = element.get('customer');

        if (customer && !customer.get('hasGeolocationDisabled')) {
          var geoPoint = customer.get('responseMeta.geopoint');
          geoPoints.push({
            geoPoint: L.point(geoPoint.latitude, geoPoint.longitude),
            calendarEvent: element,
            userAvatar: userAvatar,
            index
          });
        }
      }

      return geoPoints;
    } catch (error) {
      (false && Ember.warn('calendarEventSortedGeoPointsDataTask ' + error, {
        id: 'calendarMap.calendarEventSortedGeoPointsDataTask'
      }));
    }
  }), _dec9 = (0, _emberConcurrency.task)(function* () {
    try {
      var geoPoints = [];
      var calendarEventSortedGeoPoints = yield this.calendarEventSortedGeoPoints;

      if (this.usersStartLocations.length > 0) {
        calendarEventSortedGeoPoints = _lodash.default.concat(calendarEventSortedGeoPoints, this.usersStartLocations);
      }

      if (this.newEventCustomer) {
        calendarEventSortedGeoPoints = _lodash.default.concat(calendarEventSortedGeoPoints, this.newEventCustomer.location);
      }

      if (!calendarEventSortedGeoPoints || calendarEventSortedGeoPoints.length <= 0) {
        geoPoints.push(L.point(0, 0));
      }

      var bounds = yield L.bounds(calendarEventSortedGeoPoints && calendarEventSortedGeoPoints.length > 0 ? calendarEventSortedGeoPoints : geoPoints);
      var bottomLeft = bounds.getBottomLeft();
      var topRight = bounds.getTopRight();
      var corner1 = L.latLng(bottomLeft.x, bottomLeft.y);
      var corner2 = L.latLng(topRight.x, topRight.y);
      var cornerBounds = L.latLngBounds(corner1, corner2);
      Ember.set(this, 'calendarEventSortedGeoPointBounds', cornerBounds);
    } catch (error) {
      (false && Ember.warn('calendarEventSortedGeoPointBoundsTask ' + error, {
        id: 'calendarMap.calendarEventSortedGeoPointBoundsTask'
      }));
    }
  }), _dec10 = Ember._action, _dec11 = Ember._action, (_class = (_temp = class CalendarView extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "screen", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "colors", _descriptor3, this);

      _initializerDefineProperty(this, "CalendarEventsService", _descriptor4, this);

      _defineProperty(this, "leafletMap", null);

      _defineProperty(this, "users", Ember.A());

      _defineProperty(this, "usersArgs", Ember.A());

      _defineProperty(this, "calendarEvents", Ember.A());

      _defineProperty(this, "calendarEventsSorted", Ember.A());

      _defineProperty(this, "usersStartLocations", Ember.A());

      _defineProperty(this, "unMappedEvents", Ember.A());

      _defineProperty(this, "newEventCustomer", null);

      _defineProperty(this, "showList", false);

      _initializerDefineProperty(this, "initDataTask", _descriptor5, this);

      _initializerDefineProperty(this, "getEventsTask", _descriptor6, this);

      _initializerDefineProperty(this, "calendarEventsSortedTask", _descriptor7, this);

      _initializerDefineProperty(this, "calendarEventSortedGeoPointsDataTask", _descriptor8, this);

      _initializerDefineProperty(this, "calendarEventSortedGeoPointBoundsTask", _descriptor9, this);

      this.leafletMap = new _leafletMap.default(...arguments);
      this.initDataTask.perform();

      if (this.screen.isDesktop) {
        Ember.set(this, 'showList', true);
      }

      this.CalendarEventsService.on('redrawTheMap', this, '_redrawTheMap');
    }

    willDestroy() {
      this.CalendarEventsService.off('redrawTheMap', this, '_redrawTheMap');
    }

    _redrawTheMap() {
      this.initDataTask.perform();
    }

    addUserStartTask() {
      for (var x = 0; x < this.users.length; x++) {
        var user = this.users.objectAt(x);

        if (user.user.techStartLatitude && user.user.techStartLongitude) {
          var startEvent = Ember.Object.create({
            calendarEvent: null,
            geoPoint: L.point(user.user.techStartLatitude, user.user.techStartLongitude)
          });
          user.mappedEvents.push(startEvent);
          this.usersStartLocations.pushObject(startEvent.geoPoint);
        }
      }
    }

    get calendarEventSortedGeoPoints() {
      var geoPoints = [];

      try {
        var sortedCalendarEvents = this.calendarEventsSorted;

        for (var index = 0; index < sortedCalendarEvents.length; index++) {
          var element = sortedCalendarEvents[index];
          var customer = element.get('customer');

          if (customer && !customer.get('hasGeolocationDisabled')) {
            var geoPoint = customer.get('responseMeta.geopoint');
            geoPoints.push(L.point(geoPoint.latitude, geoPoint.longitude));
          }
        }
      } catch (error) {
        (false && Ember.warn('calendarEventSortedGeoPointsDataTask ' + error, {
          id: 'calendarMap.calendarEventSortedGeoPointsDataTask'
        }));
      }

      return geoPoints;
    }

    get calendarEventSortedGeoPointsData() {
      return this.calendarEventSortedGeoPointsDataTask.perform();
    }

    newEventCustomerTask() {
      var customer = this.args.params.customer;

      if (customer && !customer.get('hasGeolocationDisabled')) {
        var geoPoint = customer.get('responseMeta.geopoint');
        var location = L.point(geoPoint.latitude, geoPoint.longitude);
        Ember.set(this, 'newEventCustomer', Ember.Object.create({
          customer: customer,
          location: location
        }));
      }

      return true;
    }

    calendarEventSortedGeoPointPolylineTask(user) {
      try {
        var calendarEventSortedGeoPoints = [];
        user.mappedEvents.forEach(object => {
          calendarEventSortedGeoPoints.push(object.geoPoint);
        });

        if (!calendarEventSortedGeoPoints || calendarEventSortedGeoPoints.length <= 0) {
          return false;
        }

        var polylineLocations = calendarEventSortedGeoPoints.map(point => ({
          lat: point.x,
          lng: point.y
        }));
        return polylineLocations;
      } catch (error) {
        (false && Ember.warn('calendarEventSortedGeoPointPolylineTask ' + error, {
          id: 'calendarMap.calendarEventSortedGeoPointPolylineTask'
        }));
      }
    }

    toggleList() {
      Ember.set(this, 'showList', true);
    }

    toggleMap() {
      Ember.set(this, 'showList', false);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "screen", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "colors", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "CalendarEventsService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "getEventsTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "calendarEventsSortedTask", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "calendarEventSortedGeoPointsDataTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "calendarEventSortedGeoPointBoundsTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleList", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "toggleList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleMap", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "toggleMap"), _class.prototype)), _class));
  _exports.default = CalendarView;
});