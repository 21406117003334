define("securitytrax-ember-ui/my-account/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    redirect() {
      // Redirect to appropriate sub-route.
      this.transitionTo('/my-account/information');
    }

  });

  _exports.default = _default;
});