define("securitytrax-ember-ui/components/org-chart/user-chain/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    orgTree: Ember.inject.service('orgTree'),
    store: Ember.inject.service(),
    screen: Ember.inject.service(),
    classNames: ['org-chart__chain-view-inner'],
    mediaOrientation: 'st-media-object--portrait',
    unassignedClass: Ember.computed('screen', 'orgTree.showUnassignedLink', function () {
      if (this.get('screen.isMobile') && !this.get('orgTree.showUnassignedLink')) {
        return 'uncat-link__cheveron--down';
      }

      return '';
    }),
    userHierarchy: Ember.computed('orgTree.focusedUser', function () {
      return this.get('orgTree.focusedUser');
    }),
    company: Ember.computed(function () {
      return this.get('orgTree.theCompany');
    }),
    parent: Ember.computed('orgTree.focusedUser', function () {
      if (this.userHierarchy) {
        return this.getParentTask.perform(this.userHierarchy);
      }

      return null;
    }),
    getParentTask: (0, _emberConcurrency.task)(function* (userHierarchy) {
      var parent = yield userHierarchy.get('parent');
      return parent;
    }),
    grandParents: Ember.computed('orgTree.focusedUser', function () {
      if (this.userHierarchy) {
        return this.getGrandParentsTask.perform(this.userHierarchy);
      }

      return null;
    }),
    getGrandParentsTask: (0, _emberConcurrency.task)(function* (userHierarchy) {
      var hashData = yield (0, _emberConcurrency.hash)({
        directoryUserGrandparents: userHierarchy.get('directoryUserGrandparents'),
        userHierarchyTree: this.get('orgTree.userHierarchyTree')
      });

      if (hashData.directoryUserGrandparents) {
        var grandParentsPromises = Ember.A();

        for (var x = 0; x < hashData.directoryUserGrandparents.length; x++) {
          var grandparent = hashData.directoryUserGrandparents.objectAt(x);
          grandParentsPromises.push(this.store.findRecord('userHierarchy', JSON.stringify({
            user_hierarchy_tree_id: hashData.userHierarchyTree.get('id'),
            user_id: grandparent.get('id')
          })));
        }

        var grandParents = yield (0, _emberConcurrency.all)(grandParentsPromises);
        return grandParents;
      } else {
        return null;
      }
    })
  });

  _exports.default = _default;
});