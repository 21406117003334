define("securitytrax-ember-ui/components/calendar-glimmer/calendar-grid/calendar-grid-row-title-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ApozQAb0",
    "block": "{\"symbols\":[\"@row\",\"@allowAddAppointment\",\"@startAddingNewCalendarEvent\",\"@openScheduleAppointmentModal\",\"@isScheduler\",\"@userType\",\"@isUser\"],\"statements\":[[2,\" \"],[10,\"div\"],[14,0,\"grid__row-title\"],[15,5,[32,0,[\"gridRowStyles\"]]],[12],[2,\"\\n\"],[6,[37,0],[[32,7]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"date-display\"],[12],[2,\"\\n            \"],[8,\"html/st-moment-format\",[],[[\"@date\",\"@outputFormat\"],[[32,1,[\"date\",\"date\"]],\"dddd, MMMM D\"]],null],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[32,1,[\"isActive\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"date-display\"],[12],[2,\"\\n            \"],[1,[30,[36,1],[\"pages.calendar.inactive_users\"],null]],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,5]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[8,\"calendar-glimmer/mobile-add-appointment\",[],[[\"@user\",\"@allowAddAppointment\",\"@startAddingNewCalendarEvent\",\"@openScheduleAppointmentModal\"],[[32,1,[\"user\"]],[32,2],[32,3],[32,4]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"                \"],[8,\"profile/profile-badge\",[],[[\"@user\",\"@calendarUser\",\"@userType\",\"@tooltipSide\",\"@class\"],[[32,1,[\"user\",\"user\"]],[32,1,[\"user\"]],[32,6],\"bottom\",\"name-container\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"t\",\"unless\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/calendar-glimmer/calendar-grid/calendar-grid-row-title-list/template.hbs"
    }
  });

  _exports.default = _default;
});