define("securitytrax-ember-ui/components/counter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Mxvr3MNs",
    "block": "{\"symbols\":[],\"statements\":[[10,\"p\"],[12],[1,[32,0,[\"count\"]]],[13],[2,\"\\n\"],[10,\"p\"],[12],[2,\"× \"],[1,[32,0,[\"args\",\"DoubleItObject\",\"multiple\"]]],[13],[2,\"\\n\"],[10,\"p\"],[12],[2,\"= \"],[1,[32,0,[\"total\"]]],[13],[2,\"\\n\\n\"],[11,\"button\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"change\"]],1],null]],null],[12],[2,\"+1\"],[13],[2,\"\\n\"],[11,\"button\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"change\"]],-1],null]],null],[12],[2,\"-1\"],[13],[2,\"\\n\\n\"],[11,\"button\"],[24,4,\"button\"],[4,[38,1],[\"click\",[32,0,[\"double\"]]],null],[12],[2,\"Double It\"],[13]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/counter/template.hbs"
    }
  });

  _exports.default = _default;
});