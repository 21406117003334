define("securitytrax-ember-ui/components/multi-step-form/forms/demo/demo-modal-create/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency"], function (_exports, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    store: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    emergencyContacts: Ember.inject.service(),
    list: undefined,
    phoneTypeList: Ember.computed(function () {
      return this.emergencyContacts.phoneTypeList();
    }),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('step1', 'buttons', [{
        text: 'Cancel',
        click: () => {
          multiStepForm.formCopiedModelsSetVal('customerContact.model.globalRequestMeta', null);
          multiStepForm.formStartedSetVal(false);
          this.router.transitionTo(this.parentRoute);
        },
        classNamesString: 'st-btn st-btn-primary'
      }, {
        text: 'Save',
        click: buttonPromise => {
          return this.demoCreateSaveTask.perform(buttonPromise, multiStepForm);
        },
        positionOptions: 'up left',
        additionalClickActions: [{
          label: 'Save and Fail!',
          action: buttonPromise => {
            return this.demoCreateSaveTask.perform(buttonPromise, multiStepForm, true);
          },
          classNamesString: 'demo-modal-dropdown-save-fail'
        }, {
          label: 'Fail Validate List',
          action: () => {
            return this.failValidateList.perform(multiStepForm);
          },
          classNamesString: 'demo-modal-dropdown-fail-validate-list'
        }],
        classNamesString: 'st-btn st-btn-primary emergency-contact-save-button'
      }]);
    },

    demoCreateSaveTask: (0, _emberConcurrency.task)(function* (buttonPromise, multiStepForm, forceFail = false) {
      if (forceFail) {
        multiStepForm.formCopiedModelsSetVal('customerContact.model.globalRequestMeta', {
          'forceRequestResponse': {
            httpCode: 422,
            // responseHeaders: {}, // not needed for this, but is an option
            responseBody: {
              errors: [{
                status: '422',
                detail: 'Bad thing #1'
              }, {
                status: '422',
                detail: 'Bad thing #2'
              }]
            }
          }
        });
      } else {
        multiStepForm.formCopiedModelsSetVal('customerContact.model.globalRequestMeta', null);
      }

      if (this.isNewRecord) {
        var toCreateObjects = yield multiStepForm.validateCreateAll({
          notificationNamespace: 'modal'
        });
        Ember.set(toCreateObjects.customerContact.data, 'customer', this.customer);
        var contacts = yield this.get('customer.customerContacts');
        Ember.set(toCreateObjects.customerContact.data, 'sort', contacts.length + 1);
        var customerContact = this.store.createRecord('customerContact', toCreateObjects.customerContact.data);
        yield toCreateObjects.customerContact.saveWithRollbackAndNotification(customerContact);
      } else {
        yield multiStepForm.validateSyncSaveAll();
      }

      multiStepForm.formStartedSetVal(false);
      this.router.transitionTo(this.parentRoute);
    }),
    failValidateList: (0, _emberConcurrency.task)(function* (multiStepForm) {
      var formattedObjects = Ember.A();
      formattedObjects.pushObject(Ember.Object.create({
        modelName: 'customerContact',
        model: multiStepForm.formCopiedModelsGetVal('customerContact.model'),
        extraValidationData: {}
      }));
      formattedObjects.pushObject(Ember.Object.create({
        model: {
          list: this.list
        },
        filePath: 'tests/test-no-model',
        extraValidationData: {}
      }));
      yield multiStepForm.validateList.perform(formattedObjects, {
        notificationNamespace: 'modal'
      });
    }),
    actions: {
      updatePhoneType(type) {
        this.multiStepForm.formCopiedModelsSetVal('customerContact.model.about', type.value);
      }

    }
  });

  _exports.default = _default;
});