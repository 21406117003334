define("securitytrax-ember-ui/components/multi-step-form/forms/accounting/edit-create-payment-method/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WA3WynBv",
    "block": "{\"symbols\":[],\"statements\":[[8,\"html/st-await\",[],[[\"@useSpinner\",\"@booleanLoaded\",\"@size\"],[true,[34,0,[\"isIdle\"]],\"small\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"multi-step-form/forms/accounting/edit-create-payment-method/reusable-layout\",[],[[\"@paymentMethodChoices\",\"@selectedPaymentMethod\",\"@formMode\",\"@dynamicPaymentMethodForm\",\"@multiStepForm\",\"@customer\",\"@billCc\",\"@billingCycleOptions\"],[[34,1],[34,2],[34,3],[34,4],[34,5],[34,6],[34,7],[34,8]]],null],[2,\"\\n    \\n    \"],[8,\"modals/confirm-popup\",[],[[\"@onConfirm\",\"@confirmMessage\",\"@confirmBtnText\",\"@confirmButtonClassNamesString\",\"@manuallyOpen\",\"@_dialogOpen\",\"@modalNamespace\"],[[30,[36,11],[[35,10],[35,9]],null],[30,[36,12],[\"common.confirm.really_delete\"],null],[30,[36,12],[\"common.actions.delete\"],null],\"st-btn-warn\",true,[34,13],\"invoiceConfirmDelete\"]],null],[2,\"\\n\\n    \"],[8,\"modals/confirm-delete-replace\",[],[[\"@modalOpen\",\"@multiStepForm\",\"@customer\",\"@title\",\"@customerBilling\",\"@deleteCustomerBilling\",\"@modalNamespace\",\"@relatedRecurringInvoices\"],[[34,14],[34,5],[34,6],[34,15],[34,9],[34,10],\"invoiceConfirmDelete\",[34,16]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"initDataTask\",\"paymentMethodChoices\",\"selectedPaymentMethod\",\"formMode\",\"dynamicPaymentMethodForm\",\"multiStepForm\",\"customer\",\"billCc\",\"billingCycleOptions\",\"customerBilling\",\"deleteCustomerBilling\",\"perform\",\"t\",\"confirmDeleteModalOpen\",\"confirmDeleteWithReplacementModalOpen\",\"confirmDeleteReplaceTitle\",\"matchingRecurringInvoices\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/accounting/edit-create-payment-method/template.hbs"
    }
  });

  _exports.default = _default;
});