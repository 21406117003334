define("securitytrax-ember-ui/demo/query-params/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller.extend({
    intl: Ember.inject.service(),
    stTimers: Ember.inject.service(),
    stQueryParams: Ember.inject.service('st-query-params'),

    init() {
      this._super(...arguments);

      this.queryParamsTask.perform();
    },

    queryParamsTask: (0, _emberConcurrency.task)(function* () {
      try {
        // grab all customers just to have a findAll example with a limit: 0 that works
        var customers = yield this.store.findAll('customer');
        Ember.set(this, 'customers', customers);
        yield this.store.query('customer', {
          // filter: filter,
          // sort: (this.stSortString) ? this.stSortString : '-id',
          include: 'location',
          limit: 66 // page: this.page

        }, {
          cacheResponse: true,
          cacheForMilliseconds: 4000,
          // 0 means don't limit
          cacheForNumAccessed: 2,
          // 0 means don't limit
          forceCacheReload: false,
          useLocalStorage: false
        }); // console.log('customersFromQuery', customersFromQuery);

        yield (0, _emberConcurrency.timeout)(5000);
        yield this.store.query('customer', {
          // filter: filter,
          // sort: (this.stSortString) ? this.stSortString : '-id',
          include: 'location',
          limit: 66 // page: this.page

        }, {
          cacheResponse: true,
          cacheForMilliseconds: 4000,
          // 0 means don't limit
          cacheForNumAccessed: 2,
          // 0 means don't limit
          forceCacheReload: false,
          useLocalStorage: false
        }); // console.log('customersFromQueryAgain', customersFromQueryAgain);

        this.stTimers.startTimer('customersQuery', 'start');
        yield this.store.query('customer', {
          // filter: filter,
          // sort: (this.stSortString) ? this.stSortString : '-id',
          include: 'location',
          limit: 66 // page: this.page

        }, {
          cacheResponse: true,
          cacheForMilliseconds: 4000,
          // 0 means don't limit
          cacheForNumAccessed: 2,
          // 0 means don't limit
          forceCacheReload: false,
          useLocalStorage: false
        });
        this.stTimers.endTimer('customersQuery', 'third'); // console.log('customersFromQueryAgainAgain', customersFromQueryAgainAgain);

        var customer = yield this.store.findRecord('customer', 1);
        Ember.set(this, 'customer', customer);
        var customerContacts = yield this.stQueryParams.loadRelationshipWithQueryParams({
          model: customer,
          relName: 'customerContacts',
          useCallback: false,
          options: {
            params: {
              limit: 3,
              include: 'created_by_user'
            },
            extraOptions: {
              cacheResponse: true,
              cacheForMilliseconds: 100000,
              // 0 means don't limit
              cacheForNumAccessed: 100000,
              // 0 means don't limit
              forceCacheReload: false,
              useLocalStorage: false
            }
          }
        });
        Ember.set(this, 'customerContacts', customerContacts); // yield customer.get('customerContacts');
        // const customerContacts = yield customer.get('customerContacts');
        // console.log('customerContacts', customerContacts);
      } catch (e) {// console.log('err', e);
      }
    }),
    reDoQueryParamsTask: (0, _emberConcurrency.task)(function* () {
      try {
        var hasManyCustomerContacts = this.customer.hasMany('customerContacts');
        yield hasManyCustomerContacts.reload({
          adapterOptions: {
            relQueryParamsData: {
              params: {
                limit: 0
              }
            }
          }
        }); // const changedStuff = yield hasManyCustomerContacts.reload();
        // console.log('redoing the params', changedStuff);
      } catch (e) {// console.log('err', e);
      }
    })
  });
  var _default = Controller;
  _exports.default = _default;
});