define("securitytrax-ember-ui/models/base_models/account-invoice", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    accountId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    billForAccountId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    invoiceTermId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    saleDate: (0, _model.attr)('string', {
      'readOnly': false
    }),
    dueDate: (0, _model.attr)('string', {
      'readOnly': false
    }),
    uncollectableDate: (0, _model.attr)('string', {
      'readOnly': false
    }),
    status: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['open', 'closed']
    }),
    pdfGeneratedAt: (0, _model.attr)('string', {
      'readOnly': false
    }),
    paymentMethod: (0, _model.attr)('string', {
      'readOnly': false
    }),
    notes: (0, _model.attr)('string', {
      'readOnly': false
    }),
    invoiceCollectionId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    salesTaxAmount: (0, _model.attr)('string', {
      'readOnly': false
    }),
    currency: (0, _model.attr)('string', {
      'readOnly': false
    }),
    deletedAt: (0, _model.attr)('string', {
      'readOnly': false
    }),
    createdAt: (0, _model.attr)('string', {
      'readOnly': false
    }),
    updatedAt: (0, _model.attr)('string', {
      'readOnly': false
    }),
    isPaid: (0, _model.attr)('string', {
      'readOnly': false
    }),
    isSent: (0, _model.attr)('string', {
      'readOnly': false
    }),
    total: (0, _model.attr)('string', {
      'readOnly': false
    }),
    totalDue: (0, _model.attr)('string', {
      'readOnly': false
    }),
    termName: (0, _model.attr)('string', {
      'readOnly': false
    })
  });

  _exports.default = _default;
});