define("securitytrax-ember-ui/components/calendar-glimmer/calendar-header/calendar-header-time-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TLeWL8jv",
    "block": "{\"symbols\":[\"hour\",\"index\",\"@isUser\",\"@headerClasses\",\"@isScheduler\",\"@hours\"],\"statements\":[[2,\" \"],[10,\"div\"],[15,0,[31,[\"grid__row calendar__header-row--grid \",[32,4]]]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"calendar-header__name-cell calendar-header__name-cell--grid grid__row-title\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[32,5],[32,3]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[\"calendarMapView\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"div\"],[14,0,\"calendar__map-checkbox-wrapper\"],[12],[2,\"\\n                    \"],[8,\"html/input-checkbox\",[],[[\"@toggleSwitch\",\"@checked\",\"@disabled\",\"@toggle\",\"@indeterminate\",\"@bubbles\"],[false,[32,0,[\"isSelectedForMap\"]],[30,[36,0],[[32,0,[\"canSelectForMap\"]]],null],[30,[36,1],[[32,0],[32,0,[\"toggleSelectAll\"]]],null],[32,0,[\"isIndeterminate\"]],false]],null],[2,\"\\n                \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"        \"],[10,\"div\"],[14,0,\" name-container__calendar  name-container__calendar--header\"],[12],[2,\"\\n            \"],[1,[34,6]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[30,[36,7],[[32,6]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"calendar-glimmer/calendar-header/calendar-header-time-cell\",[],[[\"@colIndex\",\"@hour\",\"@isUser\"],[[32,2],[32,1],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"not\",\"action\",\"feature-is-enabled\",\"if\",\"or\",\"unless\",\"yAxisTitle\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/calendar-glimmer/calendar-header/calendar-header-time-row/template.hbs"
    }
  });

  _exports.default = _default;
});