define("securitytrax-ember-ui/models/base_models/customer-communication", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    direction: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['i', 'o']
    }),
    isCallback: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    status: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['active', 'deleted']
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    customerCommunicationType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['phone', 'written']
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    customer: (0, _model.belongsTo)('customer', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    customerNote: (0, _model.belongsTo)('customer-note', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': 'customerCommunications'
    }),
    workOrder: (0, _model.belongsTo)('work-order', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    customerTask: (0, _model.belongsTo)('customer-task', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': 'customerCommunications'
    }),
    communicationReason: (0, _model.belongsTo)('communication-reason', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    communicationDisposition: (0, _model.belongsTo)('communication-disposition', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    user: (0, _model.belongsTo)('user', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});