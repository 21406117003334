define("securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/dealer-ally/loan-details/details/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CreateFinancingDetails = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = (0, _emberConcurrency.task)(function* () {
    var hashedData = yield (0, _emberConcurrency.hash)({
      waitForFinishedCopyModels: (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true),
      products: this.getProductsTask.perform(),
      taxRates: this.getTaxRates.perform()
    });
    var application = this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model');
    var tax = hashedData.taxRates ? hashedData.taxRates.findBy('id', '2') : 0.00; // Product

    this.defaultTax = tax && tax.tax_rate ? tax.tax_rate : 0.00000;

    if (!this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.id') > 0) {
      var langValues = this.filteredLangs.map(lang => {
        return lang.value;
      });
      var currentLang = application.get('contractLanguage') || this.args.customer.get('lang');
      this.args.multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.salesTax', application.get('salesTax') || 0.00);
      this.args.multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.downPayment', application.get('downPayment') || 0.00);
      this.args.multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.sellingPrice', application.get('sellingPrice') || 0.00);
      this.args.multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.amountFinanced', application.get('amountFinanced') || 0.00);
      this.args.multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.contractState', application.get('contractState') || this.args.customer.get('state'));
      this.args.multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.contractLanguage', langValues.includes(currentLang) ? currentLang : null);
    } else {
      this.args.multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.amountFinanced', Number(application.get('sellingPrice')) + Number(application.get('salesTax')) - Number(application.get('downPayment')));
    }

    var amountFinanced = Number(application.get('sellingPrice')) - Number(application.get('downPayment'));
    this.args.multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.salesTaxRate', Number(application.get('salesTax')) / amountFinanced * 100);
  }), _dec7 = (0, _emberConcurrency.task)(function* () {
    var customerAddress = this.args.customer.getProperties(['address1', 'address2', 'city', 'state', 'zip']);

    if (!customerAddress.zip || !customerAddress) {
      return null;
    } else {
      return yield this.tax.getTaxRates(customerAddress);
    }
  }), _dec8 = (0, _emberConcurrency.task)(function* () {
    // Use BE endpoint when it's done
    this.products = yield [{
      name: 'MARINE',
      value: 'MARINE'
    }, {
      name: 'ALARM',
      value: 'ALARM'
    }, {
      name: 'SMARTHOME',
      value: 'SMARTHOME'
    }];
  }), _dec9 = Ember._action, _dec10 = Ember._action, (_class = (_temp = class CreateFinancingDetails extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "usStates", _descriptor, this);

      _initializerDefineProperty(this, "langs", _descriptor2, this);

      _initializerDefineProperty(this, "tax", _descriptor3, this);

      _initializerDefineProperty(this, "products", _descriptor4, this);

      _initializerDefineProperty(this, "defaultTax", _descriptor5, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor6, this);

      _initializerDefineProperty(this, "getTaxRates", _descriptor7, this);

      _initializerDefineProperty(this, "getProductsTask", _descriptor8, this);

      this.products = [];
      this.initDataTask.perform();
    }

    get filteredLangs() {
      return this.langs.prioritizedLangsList.filter(lang => {
        return lang.value !== 'fr';
      });
    }

    getSalesTaxRate() {
      var application = this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model');
      return application.get('salesTaxIncluded') ? application.get('salesTaxRate') || this.defaultTax : 0.00;
    }

    getSalesTax() {
      var application = this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model');
      return (Number(application.get('sellingPrice')) - Number(application.get('downPayment'))) * (this.getSalesTaxRate() / 100);
    }

    toggleSalesTax() {
      var application = this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model');

      if (application.get('salesTaxIncluded')) {
        this.args.multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.salesTaxRate', this.defaultTax);
      }

      this.updateAmountFinanced();
    }

    updateAmountFinanced() {
      var application = this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model');
      var salesTax = this.getSalesTax();
      this.args.multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.amountFinanced', Number(application.get('sellingPrice')) + salesTax - Number(application.get('downPayment')));

      if (application.get('salesTaxIncluded')) {
        this.args.multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.salesTaxRate', this.getSalesTaxRate());
        this.args.multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.salesTax', salesTax);
      } else {
        this.args.multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.salesTax', 0.00);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "usStates", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "langs", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tax", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "products", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "defaultTax", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0.00;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "getTaxRates", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "getProductsTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleSalesTax", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "toggleSalesTax"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateAmountFinanced", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "updateAmountFinanced"), _class.prototype)), _class));
  _exports.default = CreateFinancingDetails;
});