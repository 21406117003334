define("securitytrax-ember-ui/components/nav/top-nav/primary-nav-sub/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wAdsA7Tq",
    "block": "{\"symbols\":[\"popover\",\"&default\"],\"statements\":[[8,\"ember-popover\",[],[[\"@tooltipClass\",\"@event\",\"@side\"],[[30,[36,1],[\"st-ember-popover st-ember-popover__top-nav-popover \",[35,0]],null],\"click\",[34,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[15,\"onclick\",[30,[36,3],[[32,0],\"hide\"],[[\"target\"],[[32,1]]]]],[12],[2,\"\\n        \"],[18,2,null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"class\",\"concat\",\"side\",\"action\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/nav/top-nav/primary-nav-sub/template.hbs"
    }
  });

  _exports.default = _default;
});