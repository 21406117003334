define("securitytrax-ember-ui/validations/customer-birthdate", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation", "lodash", "moment"], function (_exports, _validate, _baseValidation, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      _validate.default.validators.birthDateInAgeRange = function (value, options, key, attributes) {
        var theDatetime = (0, _moment.default)(attributes[key]);
        var yearsAgo120 = (0, _moment.default)().subtract(120, 'years');
        var yearsAgo15 = (0, _moment.default)().subtract(15, 'years');

        if (yearsAgo120.isSameOrAfter(theDatetime) || yearsAgo15.isSameOrBefore(theDatetime)) {
          return options.message;
        }

        return null;
      };

      var adtValidate = _lodash.default.get(data, 'extraValidationData.newPreCheck', false);

      var validateObject = {
        birthdate: {
          presence: () => {
            if (adtValidate) {
              return this.customMessageOrDefault(data, 'birthdate');
            } else {
              return false;
            }
          },
          birthDateInAgeRange: () => {
            if (this.birthdate) {
              return {
                message: function ()
                /*value, attribute, validatorOptions, attributes, globalOptions*/
                {
                  return '^' + data.intlService.t('common.validations.oldBirthday');
                }
              };
            } else {
              return true;
            }
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      return this.outputFormatting(validationData, 'customerBirthdate');
    }

  });

  _exports.default = _default;
});