define("securitytrax-ember-ui/components/pages/administration/company-settings/integrations/partner-companies/campaigns/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    stFiltersService: Ember.inject.service(),
    store: Ember.inject.service(),
    campaignFormMode: 'view',
    campaignFormStarted: undefined,
    campaignModel: undefined,
    campaignsStatus: undefined,
    recordCount: 0,
    page: 1,
    limit: 0,
    campaignFormSteps: Ember.computed('campaignFormMode', 'campaignModel.id', function () {
      var _this$get;

      var viewOrAdd = this.campaignFormMode === 'create' ? 'create' : 'view';
      return Ember.Object.create({
        campaign: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.intl.t("common.forms.descriptive_title_".concat(viewOrAdd), {
            id: this.get('campaignModel.id'),
            name: (_this$get = this.get('campaignModel.name')) !== null && _this$get !== void 0 ? _this$get : this.intl.t('pages.administration.integrations.leadCompanies.campaigns.view')
          }),
          stepComponentPath: 'multi-step-form/forms/administration/integrations/partner-companies/campaigns'
        })
      });
    }),
    campaignFormOriginalModels: Ember.computed('campaignModel', function () {
      return Ember.Object.create({
        leadCompanyCampaign: {
          model: this.campaignModel
        }
      });
    }),
    campaigns: Ember.computed(function () {
      return this.getCampaignsTask.perform();
    }),
    getCampaignsTask: (0, _emberConcurrency.task)(function* () {
      var leadCompanyId = this.model.leadCompany.get('id');
      var newFilters = yield this.stFiltersService.getFiltersByGroupKey('companyCampaigns', ["lead_company=".concat(leadCompanyId)]);
      var campaigns = yield this.store.query('leadCompanyCampaign', {
        filter: newFilters,
        sort: 'id',
        limit: this.limit,
        page: this.page,
        count: 1
      });
      Ember.set(this, 'recordCount', campaigns.meta.record_count);
      return campaigns;
    }),
    actions: {
      viewCampaign(row) {
        if (this.grants.getIndividualGrant('lead_company_campaigns.modify')) {
          Ember.set(this, 'campaignFormMode', 'edit');
        }

        Ember.set(this, 'campaignModel', row);
        Ember.set(this, 'campaignFormStarted', true);
      },

      filterSearch() {
        Ember.set(this, 'page', 1);
        Ember.notifyPropertyChange(this, 'campaigns');
      },

      clearFilters() {
        Ember.setProperties(this, {
          page: 1,
          campaignsStatus: undefined
        });
        Ember.notifyPropertyChange(this, 'campaigns');
        Ember.notifyPropertyChange(this, 'page');
      }

    }
  });

  _exports.default = _default;
});