define("securitytrax-ember-ui/users/roles/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xBV2TFP7",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"user\",\"userTechSetting\",\"model\"],[[35,0,[\"user\"]],[35,0,[\"userTechSetting\"]],[35,0]]]]]],\"hasEval\":false,\"upvars\":[\"model\",\"pages/users/user-roles\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/users/roles/template.hbs"
    }
  });

  _exports.default = _default;
});