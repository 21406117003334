define("securitytrax-ember-ui/customers/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/helpers/feature-is-enabled"], function (_exports, _authenticatedRouteMixin, _featureIsEnabled) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    redirect({
      customer
    }) {
      // Redirect to appropriate sub-route.
      this.transitionTo("customers.".concat((0, _featureIsEnabled.featureIsEnabled)('customerDashboard') ? 'view' : 'view'), customer.id);
    }

  });

  _exports.default = _default;
});