define("securitytrax-ember-ui/demo/st-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GBq2/9J8",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,12],null,[[\"includeCard\"],[false]],[[\"default\"],[{\"statements\":[[2,\"    \\n\"],[6,[37,11],null,null,[[\"default\"],[{\"statements\":[[6,[37,9],null,null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"st-card__divider-cell st-card__divider-title-cell\"],[12],[2,\"\\n            \"],[10,\"h1\"],[14,0,\"st-card__title\"],[12],[2,\"Sortable High Performing Table via ST-TABLE\"],[13],[2,\" \\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,10],null,null,[[\"default\"],[{\"statements\":[[6,[37,8],null,[[\"useSpinner\",\"booleanLoaded\"],[true,[35,7]]],[[\"default\"],[{\"statements\":[[6,[37,6],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,4],null,[[\"columns\",\"rows\",\"sorts\"],[[35,3],[35,2],[35,1]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"                    \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"emptyListText\",\"sorts\",\"result_set\",\"columns\",\"components/html/st-table\",\"resultsLoaded\",\"if\",\"doneLoading\",\"html/st-await\",\"html/st-card/divider\",\"html/st-card/body\",\"html/st-card\",\"pages/demo-page\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/demo/st-table/template.hbs"
    }
  });

  _exports.default = _default;
});