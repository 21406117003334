define("securitytrax-ember-ui/components/multi-step-form/switchable-inputs/wysiwyg-input/component", ["exports", "securitytrax-ember-ui/mixins/switchable-input"], function (_exports, _switchableInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableInput.default, {
    height: 350,

    init() {
      this._super(...arguments);

      this.set('options', {
        theme: 'silver',
        menubar: false,
        plugins: 'code link image paste',
        contextmenu: false,
        content_style: 'body {font-size: 14px !important}',
        toolbar1: 'code | bold italic underline strikethrough | forecolor backcolor | link image',
        toolbar2: 'styleselect fontselect fontsizeselect',
        height: this.height,
        resize: 'both',
        force_br_newlines: false,
        force_p_newlines: false,
        forced_root_block: ''
      });
    }

  });

  _exports.default = _default;
});