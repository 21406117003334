define("securitytrax-ember-ui/components/pages/administration/human-resources/payables/function-details/level-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AGa2UjKR",
    "block": "{\"symbols\":[\"multiStepForm\",\"MultiStepFormComponent\",\"@model\"],\"statements\":[[8,\"multi-step-form\",[],[[\"@formSteps\",\"@formOriginalModels\",\"@usesModal\",\"@currentStepNameKey\",\"@formStarted\"],[[34,1],[34,2],false,\"editLevel\",true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,[32,2],[],[[\"@addEditLevel\",\"@multiStepForm\",\"@formMode\",\"@payrollRoleFunction\"],[[32,3,[\"payrollRoleFunctionLevel\"]],[32,1],[34,0],[32,3,[\"payrollRoleFunction\"]]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"levelFormMode\",\"addEditLevelSteps\",\"formOriginalModels\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/administration/human-resources/payables/function-details/level-details/template.hbs"
    }
  });

  _exports.default = _default;
});