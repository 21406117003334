define("securitytrax-ember-ui/models/invoice-billable-item", ["exports", "securitytrax-ember-ui/models/base_models/invoice-billable-item", "ember-concurrency"], function (_exports, _invoiceBillableItem, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _invoiceBillableItem.default.extend({
    sessionData: Ember.inject.service(),
    isNotTaxableTask: (0, _emberConcurrency.task)(function* (customer) {
      var isTaxExempt = yield this.isTaxExemptTask.perform(customer);
      return this.isTaxable === 0 || this.isTaxable === null && isTaxExempt;
    }),
    isTaxExemptTask: (0, _emberConcurrency.task)(function* (customer) {
      var hashedData = yield (0, _emberConcurrency.hash)({
        itemClass: this.get('invoiceBillableItemClass'),
        globalTaxExemptions: this.sessionData.getGlobalTaxExemptions()
      });
      return hashedData.globalTaxExemptions[hashedData.itemClass.get('name')] && hashedData.globalTaxExemptions[hashedData.itemClass.get('name')].includes(customer.get('state'));
    }),
    taxTask: (0, _emberConcurrency.task)(function* (customer, taxRates) {
      var hashedData = yield (0, _emberConcurrency.hash)({
        itemClass: this.get('invoiceBillableItemClass'),
        isNotTaxable: this.isNotTaxableTask.perform(customer)
      });
      return !hashedData.isNotTaxable && taxRates ? taxRates.findBy('id', hashedData.itemClass.get('id')) : null;
    })
  });

  _exports.default = _default;
});