define("securitytrax-ember-ui/components/customer/agency-contact-list/component", ["exports", "securitytrax-ember-ui/mixins/property-saver-mixin"], function (_exports, _propertySaverMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_propertySaverMixin.default, {
    typeToIcon: undefined,
    intl: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      this.set('typeToIcon', {
        police: 'icn-police',
        fire: 'icn-fire',
        medical: 'icn-medical',
        guard: 'icn-system',
        electrical: 'icn-lightning'
      });
    }

  });

  _exports.default = _default;
});