define("securitytrax-ember-ui/components/nav/top-nav/nav/nav-user/component", ["exports", "securitytrax-ember-ui/components/nav/top-nav/nav/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    screen: Ember.inject.service(),
    expandedDrawer: Ember.computed('hideUser', function () {
      if (this.hideUser) {
        return '';
      } else {
        return 'expanded';
      }
    }),
    actions: {
      closeUser() {
        this.set('hideUser', true);
      }

    }
  });

  _exports.default = _default;
});