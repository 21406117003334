define("securitytrax-ember-ui/services/tags-service", ["exports", "ember-concurrency", "ember-inflector"], function (_exports, _emberConcurrency, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    getTagsByObjectType: (0, _emberConcurrency.task)(function* (object_type, object_context = null) {
      try {
        var tags = yield this.store.findAll('tag');
        var matchingContexts = ['none', null, object_context]; //////////////////////////////////////////////////
        // filter tags by object_type

        var filteredTags = tags;

        if (object_type) {
          filteredTags = Ember.A([]);

          for (var i = 0; i < tags.get('length'); i++) {
            var currTag = tags.objectAt(i);

            if (currTag.get('objectType') == object_type && (object_context ? matchingContexts.includes(currTag.get('objectContext')) : true)) {
              filteredTags.pushObject(currTag);
            }
          }
        } //////////////////////////////////////////////////
        // sort tags by name ascending


        var sortedAndFilteredTags = this.sortTagList(filteredTags);
        return sortedAndFilteredTags;
      } catch (error) {
        (false && Ember.warn('getTagsByObjectType failed' + error, {
          id: 'services.tagsService'
        }));
      }
    }),
    sortTagList: tags => {
      if (!Array.isArray(tags)) {
        return [];
      }

      return tags.sort(function (a, b) {
        if (a.get('name') === b.get('name')) {
          return 0;
        }

        return a.get('name') < b.get('name') ? -1 : 1;
      });
    },

    grantKey(objectType, objectContext, tagSuffix = '_tag') {
      switch (objectType) {
        case 'tech_schedule':
        case 'sale_schedule':
          return (0, _emberInflector.pluralize)('calendar_event' + tagSuffix);

        case 'payroll_to_customer':
          return (0, _emberInflector.pluralize)('customer_payable' + tagSuffix);

        case 'customer_note':
          switch (objectContext) {
            case 'customer_note_task':
              return (0, _emberInflector.pluralize)('customer_task' + tagSuffix);

            case 'customer_note_work_order':
              return (0, _emberInflector.pluralize)('work_order' + tagSuffix);
          }

          return objectContext ? (0, _emberInflector.pluralize)(objectContext.replace('customer_note', 'customer') + tagSuffix) : (0, _emberInflector.pluralize)('customer_note' + tagSuffix);

        case 'pending_equipment':
          switch (objectContext) {
            case 'pending_equipment_po':
              return (0, _emberInflector.pluralize)('equipment_purchase_order' + tagSuffix);

            case 'pending_equipment_pr':
              return (0, _emberInflector.pluralize)('equipment_purchase_requisition' + tagSuffix);

            case 'pending_equipment_spillage':
              return (0, _emberInflector.pluralize)('equipment_spillage' + tagSuffix);

            case 'pending_equipment_rma':
              return (0, _emberInflector.pluralize)('equipment_return_merchandise_authorization' + tagSuffix);

            default:
              return null;
          }

        default:
          return (0, _emberInflector.pluralize)(objectType + '' + tagSuffix);
      }
    },

    isGranted(type, context, grantName) {
      var grantKey = this.grantKey(type, context);

      if (grantKey === null) {
        switch (type) {
          case 'pending_equipment':
            return this.grants.getIndividualGrant('equipment_purchase_order_tags.' + grantName) || this.grants.getIndividualGrant('equipment_purchase_requisition_tags.' + grantName) || this.grants.getIndividualGrant('pending_equipment_spillage.' + grantName) || this.grants.getIndividualGrant('equipment_return_merchandise_authorization_tags.' + grantName);

          case 'customer_note':
            return this.grants.getIndividualGrant('customer_note_tags.' + grantName) || this.grants.getIndividualGrant('customer_task_tags.' + grantName) || this.grants.getIndividualGrant('work_order_tags.' + grantName);
        }
      }

      return this.grants.getIndividualGrant(grantKey + '.' + grantName);
    },

    tagFilter() {
      return {
        operand: undefined,
        operator: undefined
      };
    },

    tagFilterOperands: Ember.computed(function () {
      var intl = this.intl;
      return [{
        option: intl.t('common.filters.tags_filter.does_not_have_all'),
        value: 'does_not_have_all_tags'
      }, {
        option: intl.t('common.filters.tags_filter.does_not_have_any'),
        value: 'does_not_have_any_tags'
      }, {
        option: intl.t('common.filters.tags_filter.has_all'),
        value: 'has_all_tags'
      }, {
        option: intl.t('common.filters.tags_filter.has_any'),
        value: 'has_any_tags'
      }];
    }),
    tagsOptionsTask: (0, _emberConcurrency.task)(function* (objectType, objectContext = null) {
      var tags = yield this.getTagsByObjectType.perform(objectType, objectContext);
      return tags.map(tag => {
        return {
          option: tag.get('name'),
          value: tag.get('id')
        };
      });
    })
  });

  _exports.default = _default;
});