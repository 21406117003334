define("securitytrax-ember-ui/components/calendar-glimmer/calendar-map/map-toggle/component", ["exports", "@glimmer/component", "lodash"], function (_exports, _component, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CalendarEventBody = (_dec = Ember.inject.service(), _dec2 = Ember.inject.service('events/calendar-events'), _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, (_class = (_temp = class CalendarEventBody extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "calendarMapUserSelectService", _descriptor, this);

      _initializerDefineProperty(this, "CalendarEventsService", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "mapButtonDisabled", _descriptor4, this);

      this.CalendarEventsService.on('selectAllMap', this, '_isButtonDisabled');
      this.CalendarEventsService.on('selectSingleMap', this, '_isButtonDisabled');

      this._isButtonDisabled();
    }

    _isButtonDisabled() {
      if (this.calendarMapUserSelectService.selectedUsers && this.calendarMapUserSelectService.selectedUsers.length > 0) {
        (0, _lodash.set)(this, 'mapButtonDisabled', false);
      } else {
        (0, _lodash.set)(this, 'mapButtonDisabled', true);
      }
    }

    willDestroy() {
      //there will be listeners
      if (!this.args.isUser && !this.args.isScheduler) {
        this.CalendarEventsService.off('selectAllMap', this, '_isButtonDisabled');
        this.CalendarEventsService.off('selectSingleMap', this, '_isButtonDisabled');
      }
    }

    goToMap() {
      var selectedUsers = [];
      var selectedDate = moment();

      if (this.calendarMapUserSelectService.selectedUsers && this.calendarMapUserSelectService.selectedUsers.length > 0) {
        selectedUsers = this.calendarMapUserSelectService.selectedUsers;
      }

      if (this.calendarMapUserSelectService.dateSelected) {
        selectedDate = this.calendarMapUserSelectService.dateSelected;
      }

      if (selectedUsers.length > 0) {
        this.router.transitionTo('calendar.map-view', {
          queryParams: {
            date: selectedDate.format('MM-DD-YYYY'),
            users: selectedUsers.join(',')
          }
        });
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "calendarMapUserSelectService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "CalendarEventsService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "mapButtonDisabled", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "goToMap", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "goToMap"), _class.prototype)), _class));
  _exports.default = CalendarEventBody;
});