define("securitytrax-ember-ui/components/pages/administration/company-settings/global-settings/inventory/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rH4wYEDq",
    "block": "{\"symbols\":[\"multiStepForm\"],\"statements\":[[6,[37,5],[[30,[36,4],[\"user_inventory_settings.view\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],null,[[\"formSteps\",\"formOriginalModels\",\"formStarted\"],[[35,2],[35,1],true]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],[[\"multiStepForm\",\"class\",\"formMode\"],[[32,1],\"global-inventory-form\",\"view\"]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"formOriginalModels\",\"globalInventorySettings\",\"multi-step-form\",\"has-grants\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/administration/company-settings/global-settings/inventory/template.hbs"
    }
  });

  _exports.default = _default;
});