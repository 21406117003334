define("securitytrax-ember-ui/components/multi-step-form/forms/payables/bulk-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Pq8cZ27O",
    "block": "{\"symbols\":[\"@multiStepForm\",\"@bulkEditList\"],\"statements\":[[8,\"html/st-await\",[],[[\"@useSpinner\",\"@booleanLoaded\"],[true,[34,0,[\"isIdle\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"bulk-edit/bulk-edit-options\",[],[[\"@editableFields\",\"@modelToEdit\",\"@onSave\",\"@multiStepForm\"],[[32,0,[\"editableFields\"]],\"customer-payable\",[30,[36,1],[[32,0],\"onSave\"],null],[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"modals/confirm-popup\",[],[[\"@onConfirm\",\"@confirmMessage\",\"@confirmTitleText\",\"@confirmBtnText\",\"@confirmButtonClassNamesString\",\"@manuallyOpen\",\"@_dialogOpen\"],[[30,[36,3],[[35,2]],null],[30,[36,4],[\"pages.accounting.payable_entries.confirm_bulk_edit\"],[[\"count\"],[[32,2,[\"length\"]]]]],[30,[36,4],[\"pages.accounting.payable_entries.confirm_bulk_edit_title\"],[[\"count\"],[[32,2,[\"length\"]]]]],[30,[36,4],[\"common.actions.confirm\"],null],\"st-btn-primary\",true,[34,5]]],null]],\"hasEval\":false,\"upvars\":[\"initData\",\"action\",\"bulkEditSave\",\"perform\",\"t\",\"reviewModalOpen\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/payables/bulk-edit/template.hbs"
    }
  });

  _exports.default = _default;
});