define("securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/adc/activate-commitment/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/adc/adc-base", "ember-concurrency"], function (_exports, _adcBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adcBase.default.extend({
    intl: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('activateCommitment', 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.activateCommitment.perform(multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary service-plan-save-button'
      }]);
    },

    activateCommitment: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var attributes = this.attributes;
      yield this.updateAccount.perform(multiStepForm, buttonPromise, 'alarm_dot_com__activateCustomerCommitment', attributes, {
        modemSerialNumber: attributes['modem_serial_number'],
        panelType: attributes['panel_type'],
        ignoreLowCoverageErrors: attributes['ignore_low_coverage_errors']
      }, [], 'interactive-services-provider/adc-activate-commitment');
      buttonPromise.then(() => {
        this.set('customerStatus', 'customer');
        multiStepForm.currentStepNameKeySetVal('customerInformation');
      });
    })
  });

  _exports.default = _default;
});