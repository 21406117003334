define("securitytrax-ember-ui/components/html/st-map/component", ["exports", "ember-leaflet/components/leaflet-map", "securitytrax-ember-ui/mixins/leaflet-map"], function (_exports, _leafletMap, _leafletMap2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global L */
  //eslint-disable-line
  var _default = _leafletMap.default.extend(_leafletMap2.default, {
    classNames: ['st-map'],
    mapLoaded: false,
    onLoading: null,
    actions: {
      onLoading() {
        this.set('mapLoaded', false);
      },

      onLoad() {
        this.set('mapLoaded', true);
      }

    }
  });

  _exports.default = _default;
});