define("securitytrax-ember-ui/components/multi-step-form/forms/system/stages/general/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms/system/stages/stages-base"], function (_exports, _emberConcurrency, _stagesBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var General = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = (0, _emberConcurrency.task)(function* () {
    var hashedData = yield (0, _emberConcurrency.hash)({
      contacts: this.contactsTask.perform(),
      zones: this.zonesTask.perform()
    });
    Ember.setProperties(this, hashedData);
  }), _dec7 = (0, _emberConcurrency.task)(function* () {
    var contacts = [];

    try {
      contacts = yield this.centralStationService.getData({
        model: this.args.customer,
        method: 'stages__getSiteContactsList'
      });
      contacts = contacts.site_contacts_list;
    } catch (err) {
      var message = this.intl.t('pages.customers.system.order.stages_retrieve_error', {
        data: this.intl.t('pages.customers.nav.contacts').toLowerCase()
      });
      this.stNotificationManager.addWarning(message + err, 'modal');
    }

    return contacts;
  }), _dec8 = (0, _emberConcurrency.task)(function* () {
    var zones = [];

    try {
      zones = yield this.centralStationService.getData({
        model: this.args.customer,
        method: 'stages__getSiteDeviceConfigList'
      });
      zones = zones.site_device_config_list;
    } catch (err) {
      var message = this.intl.t('pages.customers.system.order.stages_retrieve_error', {
        data: this.intl.t('pages.customers.system.order.zones').toLowerCase()
      });
      this.stNotificationManager.addWarning(message + err, 'modal');
    }

    return zones;
  }), _dec9 = (0, _emberConcurrency.task)(function* () {
    try {
      var hashData = yield (0, _emberConcurrency.hash)({
        customer: this.args.customer,
        customerSystemInformation: this.grants.getIndividualGrant('customer_system_information.view') ? this.args.customer.get('customerSystemInformation') : null
      });

      try {
        yield hashData.customerSystemInformation.invokeAction('unlink_cs_account', {
          verb: 'POST',
          data: {
            data: {
              type: 'customer_system_information_action'
            }
          }
        });
        yield (0, _emberConcurrency.all)([hashData.customer.reload(), hashData.customerSystemInformation.reload()]);
      } catch (response) {
        this.stNotificationManager.addErrorMessages(response, {
          notificationNamespace: 'stagesGeneralForm'
        });
      }

      this.showUnlinkConfirmModal = false;
      this.args.multiStepForm.formStartedSetVal(false);

      if (this.args.transitionBackToParent && typeof this.args.transitionBackToParent === 'function') {
        this.args.transitionBackToParent();
      }

      this.stNotificationManager.addSuccess(this.intl.t('common.notifications.unlink_successful'));
    } catch (error) {
      (false && Ember.warn(error, {
        id: 'multiStepForm.forms.system.stages.general'
      }));
    }
  }), _dec10 = Ember._action, (_class = (_temp = class General extends _stagesBase.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "centralStationService", _descriptor, this);

      _initializerDefineProperty(this, "grants", _descriptor2, this);

      _initializerDefineProperty(this, "showUnlinkConfirmModal", _descriptor3, this);

      _initializerDefineProperty(this, "contacts", _descriptor4, this);

      _initializerDefineProperty(this, "zones", _descriptor5, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor6, this);

      _initializerDefineProperty(this, "contactsTask", _descriptor7, this);

      _initializerDefineProperty(this, "zonesTask", _descriptor8, this);

      _initializerDefineProperty(this, "unlinkAccount", _descriptor9, this);

      this.initDataTask.perform();
    }

    confirmUnlinkAccount() {
      this.showUnlinkConfirmModal = true;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showUnlinkConfirmModal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "contacts", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "zones", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "contactsTask", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "zonesTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "unlinkAccount", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "confirmUnlinkAccount", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "confirmUnlinkAccount"), _class.prototype)), _class));
  _exports.default = General;
});