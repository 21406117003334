define("securitytrax-ember-ui/services/calendar-data-service", ["exports", "moment", "ember-concurrency", "securitytrax-ember-ui/components/calendar-glimmer/calendar-helpers/calendar-page/calendar-page-date", "securitytrax-ember-ui/components/calendar-glimmer/calendar-helpers/calendar-page/calendar-page-user", "lodash"], function (_exports, _moment, _emberConcurrency, _calendarPageDate, _calendarPageUser, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * Service to handle the state of calandar's data.
   *
   * @class CalandarData
   * @extends Ember.Service
    * @memberof services
    *
    */
  var CalendarData = (_dec = Ember.inject.service('events/calendar-events'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._tracked, _dec21 = Ember._tracked, _dec22 = (0, _emberConcurrency.task)(function* () {
    var temp = [];
    var pageArray = yield this.calendarUserPageArray;
    yield pageArray.forEach(page => {
      if (this.userType === 'sales_rep') {
        if (page.isSales) {
          temp.push(page);
        }
      } else if (this.userType === 'tech_schedule' || this.userType === 'tech') {
        if (page.isTech) {
          temp.push(page);
        }
      } else {
        temp.push(page);
      }
    });
    var pages = yield this.showUserPagesTask.perform(temp);
    this.calendarUserPageMaxIndex = temp.length;
    return pages;
  }), _dec23 = (0, _emberConcurrency.task)(function* () {
    var canViewOtherUsers = yield this.grants.getIndividualGrant('calendar_users.view');
    var users;

    if (canViewOtherUsers) {
      //findAll only returns first 100
      var allCalendarUsers = yield this.store.query('calendarUser', {
        filter: 'active = true',
        //cant currently filter on roles, a hasMany
        limit: 0,
        sort: 'lname, fname',
        include: 'roles,user,calendarEvents'
      });
      users = allCalendarUsers;
    } else {
      var loggedInUser = yield this.sessionData.getUser();
      users = [loggedInUser];
    }

    return users;
  }), _dec24 = (0, _emberConcurrency.task)(function* () {
    var retVal = yield this.CalendarDatesService.sort(function (a, b) {
      return a.date - b.date;
    });
    return retVal;
  }), _dec25 = (0, _emberConcurrency.task)(function* () {
    if (this.users) {
      this.users.push(Ember.Object.create({
        'id': 'inactive',
        'name': this.intl.t('pages.calendar.inactive_users')
      }));
      var userPagesToComplete = Ember.A();

      for (var x = 0; x < this.users.length; x++) {
        var user = this.users.objectAt(x);
        userPagesToComplete.push(this.addUserPage(user));
      }

      yield (0, _emberConcurrency.all)(userPagesToComplete);
    }

    return this.calendarUserPageArray;
  }), _dec26 = (0, _emberConcurrency.task)(function* (user) {
    var calendarPage = yield new _calendarPageUser.default(...arguments, {
      user: user,
      CalendarDatePickerService: this.CalendarDatePickerService,
      startTime: this.defaultStartTime,
      endTime: this.defaultEndTime,
      calendarData: this,
      store: this.store
    });
    this.calendarUserPages.push(calendarPage);
    this.CalendarEventsService.trigger('showPages');
  }), _dec27 = (0, _emberConcurrency.task)(function* (users) {
    yield (0, _emberConcurrency.waitForProperty)(this.AppointmentTimeService, 'getGlobalSettingTimes.isIdle');

    if (users) {
      this.users = users;
    } else {
      this.users = yield this.calendarDataUsersTask.perform();
    }

    this.newUsers = this.users;
    return true;
  }), _dec28 = (0, _emberConcurrency.task)(function* (temp) {
    var calendarUserPages = temp;

    if (this.newUsers && this.newUsers.length > 0) {
      var pagesToReturn = Ember.A();
      var users = this.newUsers.map(user => {
        if (user.active) {
          return user.id;
        }
      });
      calendarUserPages.forEach(page => {
        page.hidden = true;
      });

      for (var x = 0; x < calendarUserPages.length; x++) {
        var page = calendarUserPages.objectAt(x);

        for (var j = 0; j < users.length; j++) {
          var user = users.objectAt(j);

          if (user == page.id) {
            page.hidden = false;
            pagesToReturn.push(page);
          }
        }

        if (page.id == 'inactive') {
          pagesToReturn.push(page);
          page.hidden = !this.showInactiveUsers;
        }
      }

      return pagesToReturn;
    } else {
      calendarUserPages.forEach(page => {
        if (page.id == 'inactive') {
          page.hidden = !this.showInactiveUsers;
        } else {
          page.hidden = false;
        }
      });
    }

    return calendarUserPages;
  }), (_class = (_temp = class CalendarData extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "CalendarEventsService", _descriptor, this);

      _initializerDefineProperty(this, "CalendarDatePickerService", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "grants", _descriptor4, this);

      _initializerDefineProperty(this, "sessionData", _descriptor5, this);

      _initializerDefineProperty(this, "AppointmentTimeService", _descriptor6, this);

      _initializerDefineProperty(this, "intl", _descriptor7, this);

      _initializerDefineProperty(this, "type", _descriptor8, this);

      _initializerDefineProperty(this, "dates", _descriptor9, this);

      _initializerDefineProperty(this, "calendarDatePages", _descriptor10, this);

      _initializerDefineProperty(this, "calendarUserPages", _descriptor11, this);

      _initializerDefineProperty(this, "pendingAddCalendarEvent", _descriptor12, this);

      _initializerDefineProperty(this, "users", _descriptor13, this);

      _initializerDefineProperty(this, "userListType", _descriptor14, this);

      _initializerDefineProperty(this, "maxIndex", _descriptor15, this);

      _initializerDefineProperty(this, "calendarUserPageMaxIndex", _descriptor16, this);

      _initializerDefineProperty(this, "userType", _descriptor17, this);

      _initializerDefineProperty(this, "showInactiveUsers", _descriptor18, this);

      _initializerDefineProperty(this, "newUsers", _descriptor19, this);

      _initializerDefineProperty(this, "defaultStartTime", _descriptor20, this);

      _initializerDefineProperty(this, "defaultEndTime", _descriptor21, this);

      _initializerDefineProperty(this, "calendarUserPageDisplayArrayTask", _descriptor22, this);

      _initializerDefineProperty(this, "calendarDataUsersTask", _descriptor23, this);

      _initializerDefineProperty(this, "sortDatesTask", _descriptor24, this);

      _initializerDefineProperty(this, "addUserPagesTask", _descriptor25, this);

      _initializerDefineProperty(this, "addUserPageTask", _descriptor26, this);

      _initializerDefineProperty(this, "initCalendarTask", _descriptor27, this);

      _initializerDefineProperty(this, "showUserPagesTask", _descriptor28, this);
    }

    get calendarPages() {
      if (this.type === 'user') {
        return this.calendarUserPageArray;
      }

      return this.calendarDatePageArray;
    }

    get calendarDatePageArray() {
      return this.calendarDatePages;
    }

    get calendarUserPageArray() {
      if (this.calendarUserPages.length !== this.users.length) {
        return this.addUserPagesTask.perform();
      }

      return this.calendarUserPages;
    }

    addMapUser(userId) {
      var addUser = true;
      this.mapUsers.forEach(user => {
        if (user == userId) {
          addUser = false;
        }
      });

      if (addUser) {
        this.mapUsers.push(userId);
      }
    }

    removeMapUser(userId) {
      if (this.mapUsers.length > 0) {
        var tempUsers = _lodash.default.remove(this.mapUsers, function (user) {
          return user != userId;
        });

        this.mapUsers = tempUsers;
      }

      if (this.mapUsers.length == 0) {
        this.mapDate = undefined;
      }
    }

    get calendarUserPageDisplayArray() {
      return this.calendarUserPageDisplayArrayTask.perform();
    }

    incrementMaxIndex() {
      if (this.maxIndex + 10 < this.calendarUserPageMaxIndex) {
        this.maxIndex += 10;
      } else {
        this.maxIndex = this.calendarUserPageMaxIndex;
      }
    }

    resetMaxIndex() {
      this.maxIndex = 10;
    }

    findCalendarPageById(id) {
      var calendarPages = this.calendarPages;
      return calendarPages.find(calendarPage => calendarPage.id === id);
    }

    initCalendar(users) {
      return this.initCalendarTask.perform(users);
    }

    addUserPage(user) {
      this.addUserPageTask.perform(user);
    }

    hideDate(date) {
      var dateIndex = this.findDate(date);

      if (dateIndex >= 0) {
        this.calendarDatePageArray.objectAt(dateIndex).hideDate();
      }

      this.CalendarEventsService.trigger('showPages');
    }

    hideAll() {
      this.calendarDatePageArray.forEach(function (value) {
        value.hideDate();
      });
    }

    findDate(date) {
      var retValue = -1;
      this.calendarDatePageArray.forEach(function (value, i) {
        if (value.id === (0, _moment.default)(date.date).format('M-D-YYYY')) {
          retValue = i;
        }
      });
      return retValue;
    }

    addDatePage(date) {
      var dateIndex = this.findDate(date);

      if (dateIndex >= 0) {
        this.calendarDatePageArray.objectAt(dateIndex).showDate();
      } else {
        var calendarPage = new _calendarPageDate.default(...arguments, {
          date: date,
          startTime: this.defaultStartTime,
          endTime: this.defaultEndTime,
          users: this.users,
          store: this.store,
          CalendarEventsService: this.CalendarEventsService,
          userType: this.userType
        });
        calendarPage.hiddenAppointmentTypes = this.hiddenAppointmentTypes;
        this.calendarDatePageArray.push(calendarPage);
        this.calendarDatePageArray.sort(function (a, b) {
          return a.date - b.date;
        });
        this.notifyPropertyChange('calendarDatePageArray');
        return calendarPage;
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "CalendarEventsService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "CalendarDatePickerService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "AppointmentTimeService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "dates", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "calendarDatePages", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "calendarUserPages", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "pendingAddCalendarEvent", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "users", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "userListType", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'all';
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "maxIndex", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 10;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "calendarUserPageMaxIndex", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "userType", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "showInactiveUsers", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "newUsers", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "defaultStartTime", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "defaultEndTime", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "calendarUserPageDisplayArrayTask", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "calendarDataUsersTask", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "sortDatesTask", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "addUserPagesTask", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "addUserPageTask", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "initCalendarTask", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "showUserPagesTask", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CalendarData;
});