define("securitytrax-ember-ui/administration/human-resources/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/has-page-title"], function (_exports, _authenticatedRouteMixin, _hasPageTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _hasPageTitle.default, {
    intl: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform();
    },

    setupController(controller) {
      this._super(...arguments);

      var breadCrumbs = [{
        name: this.intl.t('pages.administration.human_resources.title'),
        last: true,
        previousLink: 'administration',
        link: 'user-list'
      }];
      var breadCrumbData = {
        //titleLink: 'administration',
        hide: true
      };
      controller.set('breadCrumbs', breadCrumbs);
      controller.set('breadCrumbData', breadCrumbData);
    },

    getPageTitle() {
      return '';
    },

    getRouteName() {
      return "pages.administration.nav.".concat(this.routeName.split('.').pop());
    }

  });

  _exports.default = _default;
});