define("securitytrax-ember-ui/components/multi-step-form/forms/administration/users/my-inventory/purchase-orders/review/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    grants: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      var connector = this.grants.getIndividualGrant('equipment_vendors.manage_connector') ? this.purchaseOrder.equipmentVendor.get('equipmentVendorConfig.connector') : null;
      var electronicallySubmitted = this.purchaseOrder.processingMethodId === 100;
      multiStepForm.registerStepData("step1", 'buttons', [{
        text: electronicallySubmitted && connector && connector !== 'HvInventory_VendorConnector_Manual' ? this.intl.t('common.actions.submit') : this.intl.t('common.actions.save'),
        click: buttonPromise => {
          if (this.pendingPurchaseOrder) {
            return this.pendingPurchaseOrderSaveTask.perform(buttonPromise, this.purchaseOrder, 'reviewPendingPurchaseOrderForm', this.pendingEquipmentLineItems);
          } else {
            return this.savePurchaseOrderTask.perform(multiStepForm, buttonPromise);
          }
        },
        classNamesString: 'st-btn st-btn-primary po-save-button'
      }]);
    },

    savePurchaseOrderTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      try {
        var lineItems = [];
        var store = this.store;
        this.pendingEquipmentLineItems.forEach(item => {
          var newLineItemObject = {
            cost: Number(item.cost) || 0,
            quantity: item.quantity || 0,
            fulfilledQuantity: 0
          };

          if (item.equipment.constructor.modelName === 'equipment') {
            newLineItemObject.equipment = item.equipment;
          } else {
            newLineItemObject.equipmentKit = item.equipment;
          }

          var newPendingEquipmentLineItem = store.createRecord('pendingEquipmentLineItem', newLineItemObject);
          lineItems.push(newPendingEquipmentLineItem);
        });
        var purchaseOrder = this.purchaseOrder;
        var toCreatePurchaseOrder = store.createRecord('equipmentPurchaseOrder', {
          pendingEquipmentType: 'po',
          user: this.recipient.constructor.modelName.camelize() === 'inventoryUser' ? yield this.recipient.get('user') : null,
          location: this.recipient.constructor.modelName.camelize() === 'location' ? this.recipient : null,
          catalyst: 'manual',
          equipmentVendor: purchaseOrder.get('equipmentVendor'),
          pendingEquipmentLineItems: lineItems,
          comments: purchaseOrder.get('comments'),
          processingMethodId: purchaseOrder.get('processingMethodId'),
          retrievalMethodId: purchaseOrder.get('retrievalMethodId'),
          branchNumber: purchaseOrder.get('branchNumber')
        });
        yield toCreatePurchaseOrder.save();
        buttonPromise.then(() => {
          multiStepForm.formStartedSetVal(false);
          multiStepForm.onAfterSave();
        });
      } catch (e) {
        this.stNotificationManager.addErrorMessages(e, {
          notificationNamespace: 'modal2'
        });
        throw e;
      }
    })
  });

  _exports.default = _default;
});