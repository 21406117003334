define("securitytrax-ember-ui/models/base_models/package-equipment", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    quantity: (0, _model.attr)('number', {
      'readOnly': false
    }),
    itemCharge: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    active: (0, _model.attr)('boolean', {
      'readOnly': true
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    package: (0, _model.belongsTo)('package', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    equipment: (0, _model.belongsTo)('equipment', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    equipmentWireType: (0, _model.belongsTo)('equipment-wire-type', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});