define("securitytrax-ember-ui/components/lead/lead-appointments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Co35JMN9",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"calendarEvents\",\"showAdd\",\"type\",\"customer\"],[[35,0,[\"calendarEvents\"]],true,\"sales\",[35,0]]]]]],\"hasEval\":false,\"upvars\":[\"lead\",\"customer/appointments/appointments-list\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/lead/lead-appointments/template.hbs"
    }
  });

  _exports.default = _default;
});