define("securitytrax-ember-ui/components/multi-step-form/forms/system/adt/steps/econtracts/contract-list/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    stAjax: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    centralStationService: Ember.inject.service(),
    contractsLoaded: false,
    eContractList: undefined,
    voidContractId: false,
    //NOTE: this CANNOT use the relationship from customer, or watch the relationship: 'customer.adtCustomerEcontracts.@each.{id,envelopeStatus}'
    eContractListObserver: Ember.observer('adtData.adtCustomerOrderStatus.status_code', function () {
      return this.eContractListTask.perform();
    }),
    eContractListTask: (0, _emberConcurrency.task)(function* () {
      var grants = yield this.grants;
      var canViewEContracts = grants.getIndividualGrant('customers.manage_central_station_integration');
      var eContracts = [];
      yield this.set('contractsLoaded', false); //required for when refreshing contracts

      var centralStationService = this.centralStationService;

      if (canViewEContracts) {
        eContracts = yield centralStationService.getData({
          model: this.customer,
          method: 'adt__getEnvelopes',
          payload: {
            embedded_signing_callback_url: window.location.href + '/manage-account'
          },
          responseKey: 'envelopes'
        });
      }

      this.set('eContractList', eContracts);
      this.set('contractsLoaded', true);
    }).on('init'),
    voidTask: (0, _emberConcurrency.task)(function* (eContractId) {
      var messages = [];
      var centralStationService = this.centralStationService;

      try {
        var response = yield centralStationService.postData({
          model: this.customer,
          method: 'adt__voidEnvelope',
          payload: {
            envelope_id: eContractId
          }
        });
        messages = messages.concat(response.responsePayload.data.messages);
        this.notifyPropertyChange('adtData.adtCustomerOrderStatus.statusCode');
        this.setupTask.perform();
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'adtMegaFormModal');
        this.set('voidContractId', false);

        if (messages.length > 0) {
          this.stNotificationManager.addWarningMessages(messages, {
            notificationNamespace: 'adtMegaFormModal'
          });
        }
      } catch (response) {
        this.stNotificationManager.addErrorMessages(response, {
          notificationNamespace: 'adtMegaFormModal'
        });
      }
    }),
    actions: {
      void(eContractId) {
        return this.voidTask.perform(eContractId);
      }

    }
  });

  _exports.default = _default;
});