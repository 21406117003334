define("securitytrax-ember-ui/mixins/forms/adc_import", ["exports", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    currentInteractiveServicesProvider: undefined,
    importCustomersFormMode: undefined,
    importCustomersFormStarted: undefined,
    importAdcCustomersJson: undefined,
    importUsersFormMode: undefined,
    importUsersFormStarted: undefined,
    importAdcUsersJson: undefined,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        importCustomersFormMode: 'edit',
        importCustomersFormStarted: false,
        importAdcCustomersJson: {
          customerIds: '',
          subDealerIds: [],
          officeLocation: null,
          customerTags: [],
          setDefaultRep: false,
          defaultRep: null
        },
        importUsersFormMode: 'edit',
        importUsersFormStarted: false,
        importAdcUsersJson: {
          supportIds: '',
          roleIds: [],
          // need to get this list still
          subDealerIds: [],
          // RepId to alarm.com
          Active: true,
          CanLogin: false,
          userTags: [],
          group: null
        }
      });
    },

    importCustomersFormSteps: Ember.computed(function () {
      var intl = this.intl;
      var title = intl.t('pages.administration.integrations.interactiveServicesProviders.imports.customerImport.modalTitle');
      return Ember.Object.create({
        gettingStarted: Ember.Object.create({
          stepNameKey: 'gettingStarted',
          title: title,
          progressBarTitle: intl.t('pages.administration.integrations.interactiveServicesProviders.imports.customerImport.getStarted'),
          stepComponentPath: 'multi-step-form/forms/administration/integrations/interactive-services-providers/adc/import/import-customers/getting-started'
        }),
        filterOptions: Ember.Object.create({
          stepNameKey: 'filterOptions',
          title: title,
          progressBarTitle: intl.t('pages.administration.integrations.interactiveServicesProviders.imports.customerImport.filterOptions'),
          stepComponentPath: 'multi-step-form/forms/administration/integrations/interactive-services-providers/adc/import/import-customers/filter-options'
        }),
        importOptions: Ember.Object.create({
          stepNameKey: 'importOptions',
          title: title,
          progressBarTitle: intl.t('pages.administration.integrations.interactiveServicesProviders.imports.customerImport.importOptions'),
          stepComponentPath: 'multi-step-form/forms/administration/integrations/interactive-services-providers/adc/import/import-customers/import-options'
        }),
        importSummary: Ember.Object.create({
          stepNameKey: 'importSummary',
          title: title,
          progressBarTitle: intl.t('pages.administration.integrations.interactiveServicesProviders.imports.customerImport.importSummary'),
          stepComponentPath: 'multi-step-form/forms/administration/integrations/interactive-services-providers/adc/import/import-customers/import-summary'
        })
      });
    }),
    importUsersFormSteps: Ember.computed(function () {
      var intl = this.intl;
      var title = intl.t('pages.administration.integrations.interactiveServicesProviders.imports.userImport.modalTitle');
      return Ember.Object.create({
        gettingStarted: Ember.Object.create({
          stepNameKey: 'gettingStarted',
          title: title,
          progressBarTitle: intl.t('pages.administration.integrations.interactiveServicesProviders.imports.userImport.getStarted'),
          stepComponentPath: 'multi-step-form/forms/administration/integrations/interactive-services-providers/adc/import/import-users/getting-started'
        }),
        filterOptions: Ember.Object.create({
          stepNameKey: 'filterOptions',
          title: title,
          progressBarTitle: intl.t('pages.administration.integrations.interactiveServicesProviders.imports.userImport.filterOptions'),
          stepComponentPath: 'multi-step-form/forms/administration/integrations/interactive-services-providers/adc/import/import-users/filter-options'
        }),
        importOptions: Ember.Object.create({
          stepNameKey: 'importOptions',
          title: title,
          progressBarTitle: intl.t('pages.administration.integrations.interactiveServicesProviders.imports.userImport.importOptions'),
          stepComponentPath: 'multi-step-form/forms/administration/integrations/interactive-services-providers/adc/import/import-users/import-options'
        }),
        importSummary: Ember.Object.create({
          stepNameKey: 'importSummary',
          title: title,
          progressBarTitle: intl.t('pages.administration.integrations.interactiveServicesProviders.imports.userImport.importSummary'),
          stepComponentPath: 'multi-step-form/forms/administration/integrations/interactive-services-providers/adc/import/import-users/import-summary'
        })
      });
    }),
    importCustomersFormOriginalModels: Ember.computed('company', function () {
      return Ember.Object.create({
        company: {
          model: null,
          copyRelationMap: {//
          }
        }
      });
    }),
    importUsersFormOriginalModels: Ember.computed('company', function () {
      return Ember.Object.create({
        company: {
          model: null,
          copyRelationMap: {//
          }
        }
      });
    })
  });

  _exports.default = _default;
});