define("securitytrax-ember-ui/components/inventory/pending-purchase-orders/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var PendingPurchaseOrders = (_dec = Ember.inject.service, _dec2 = Ember.computed('args.pendingPurchaseOrders.length', 'args.pendingPurchaseOrders.@each.{created,removed}'), (_class = (_temp = class PendingPurchaseOrders extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);
    }

    get truePendingPurchaseOrderLength() {
      var length = 0;

      if (this.args.pendingPurchaseOrders) {
        this.args.pendingPurchaseOrders.forEach(po => {
          if (!po.created && !po.removed && po.equipmentVendor) {
            length++;
          }
        });
      }

      return length;
    }

    get pendingPOsInfoMessage() {
      return this.intl.t('pages.administration.company_settings.inventory.purchase_requisitions.pending_purchase_orders_info', {
        quantity: this.truePendingPurchaseOrderLength
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "truePendingPurchaseOrderLength", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "truePendingPurchaseOrderLength"), _class.prototype)), _class));
  _exports.default = PendingPurchaseOrders;
});