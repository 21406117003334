define("securitytrax-ember-ui/components/multi-step-form/forms/administration/payables/function-details/component", ["exports", "ember-concurrency", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "securitytrax-ember-ui/utils/strings"], function (_exports, _emberConcurrency, _component, _switchableFormStep, _strings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EditFunctionDetails = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = (0, _emberConcurrency.task)(function* () {
    var levels = yield this.args.addEditFunction.get('payrollRoleFunctionLevels');
    var lineItemCount = this.args.addEditFunction.get('columnsDerivedFrom') === 'function' ? levels.objectAt(0).get('payrollColumnsDerived.length') : 0;

    if (this.args.addEditFunction.get('columnsDerivedFrom') !== 'function') {
      levels.forEach(level => {
        lineItemCount += level.get('payrollColumns.length');
      });
    }

    return [{
      'delete_warning_type': 'levels',
      'count': levels.length
    }, {
      'delete_warning_type': 'line_items',
      'count': lineItemCount
    }];
  }), _dec14 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    yield this.args.multiStepForm.validateList.perform([Ember.Object.create({
      filePath: 'payroll-role-function',
      model: {
        name: this.duplicateName,
        payrollListMethod: this.args.addEditFunction.get('payrollListMethod'),
        payrollRole: this.args.addEditFunction.get('payrollRole')
      },
      extraValidationData: {
        overrideValidationKey: 'duplicate-payrollRoleFunction'
      }
    })]);

    try {
      var newlySavedFunction = (yield this.args.addEditFunction.invokeAction('duplicate', {
        verb: 'POST',
        processResponse: true,
        data: {
          data: {
            attributes: {
              name: this.duplicateName,
              about: this.duplicateDescription
            }
          }
        }
      })).models[0];
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccess(this.intl.t('common.notifications.save_successful'));
        multiStepForm.formStartedSetVal(false);
        this.showDuplicateFunctionModal = false;
        this.router.transitionTo('administration.company-settings.system.payables.function-details', newlySavedFunction.get('id'));
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      (false && Ember.warn('HR duplicate function error' + err, {
        id: 'multiStepForm.forms.administration.payables.duplicateFunction'
      }));
      throw err;
    }
  }), _dec15 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var multiStepForm = this.args.multiStepForm;
    yield multiStepForm.validateSyncSaveAll({
      deepValidate: false
    });
    buttonPromise.then(() => {
      this.stNotificationManager.addSuccess(this.intl.t('common.notifications.save_successful'));
    });
  }), _dec16 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    try {
      var multiStepForm = this.args.multiStepForm;
      var functionToDelete = multiStepForm.formOriginalModelsGetVal('payrollRoleFunction.model');
      yield functionToDelete.destroyRecordWithRollbackTask.perform();
      buttonPromise.then(() => {
        Ember.notifyPropertyChange(this, 'functions');
        this.args.multiStepForm.setupCopyModels.perform();
        this.args.multiStepForm.validationErrors = {};
        this.router.transitionTo('administration.company-settings.system.payables');
        this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'payrollRoleFunctionConfirmDelete'
      });
      (false && Ember.warn('HR delete function error' + err, {
        id: 'multiStepForm.forms.administration.permissions.deleteFunction'
      }));
      throw err;
    }
  }), _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, (_class = (_temp = class EditFunctionDetails extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "showDuplicateFunctionModal", _descriptor5, this);

      _initializerDefineProperty(this, "addLevel", _descriptor6, this);

      _initializerDefineProperty(this, "addLevelFormStarted", _descriptor7, this);

      _initializerDefineProperty(this, "addLevelStepName", _descriptor8, this);

      _initializerDefineProperty(this, "levelFormMode", _descriptor9, this);

      _initializerDefineProperty(this, "duplicateName", _descriptor10, this);

      _initializerDefineProperty(this, "duplicateDescription", _descriptor11, this);

      _initializerDefineProperty(this, "deleteOpen", _descriptor12, this);

      _initializerDefineProperty(this, "relatedObjectsTask", _descriptor13, this);

      _initializerDefineProperty(this, "duplicateFunction", _descriptor14, this);

      _initializerDefineProperty(this, "editFunctionTask", _descriptor15, this);

      _initializerDefineProperty(this, "deleteFunction", _descriptor16, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments, {
        disabledProgressBarSteps: [],
        hiddenProgressBarSteps: []
      });
      this.SwitchableFormStep.progressBarHidden = true;
    }

    get descriptionObject() {
      var listMethod = this.args.multiStepForm.formCopiedModelsGetVal('payrollRoleFunction.model.payrollListMethod');

      if (listMethod && listMethod.get('id') > 0) {
        return Ember.A([Ember.Object.create({
          packageName: listMethod.get('name'),
          packageId: listMethod.get('id'),
          packageContent: [{
            label: this.intl.t('common.columns.description'),
            description: listMethod.get('name')
          }, {
            label: this.intl.t('pages.administration.human_resources.payee_type'),
            description: (0, _strings.ucwords)(listMethod.get('entityType'), '_')
          }, {
            label: this.intl.t('pages.administration.human_resources.information_from'),
            description: (0, _strings.ucwords)(listMethod.get('payOnObject'), '_')
          }]
        })]);
      }

      return null;
    }

    get payrollListMethods() {
      var role = this.args.multiStepForm.formOriginalModelsGetVal('payrollRoleFunction.model.payrollRole');
      var listMethod = this.args.multiStepForm.formCopiedModelsGetVal('payrollRoleFunction.model.payrollListMethod');
      return role && role.get('payrollListMethods') && listMethod ? role.get('payrollListMethods').filterBy('entityType', listMethod.get('entityType')).filterBy('payOnObject', listMethod.get('payOnObject')) : [];
    }

    get duplicateFunctionButtons() {
      var multiStepForm = this.args.multiStepForm;
      return [{
        text: this.intl.t('pages.administration.human_resources.duplicate_job_function'),
        click: buttonPromise => {
          return this.duplicateFunction.perform(multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary function-save-button'
      }];
    }

    get progressBarConfig() {
      var items = [];
      var formSteps = this.addLevelSteps;
      Object.keys(formSteps).forEach(function (key) {
        items.push({
          title: formSteps[key].progressBarTitle,
          stepNameKey: formSteps[key].stepNameKey,
          isClickable: false,
          hidden: formSteps[key].hidden,
          isComplete: formSteps[key].isComplete
        });
      });
      return {
        activeIndex: this.addLevel ? 1 : 0,
        viewTemplate: 'default-view',
        items: items
      };
    }

    get addLevelSteps() {
      var intl = this.intl;
      return Ember.Object.create({
        addLevel: Ember.Object.create({
          stepNameKey: 'addLevel',
          title: intl.t('pages.administration.human_resources.new_level'),
          progressBarTitle: intl.t('pages.customers.accounting.payables.level'),
          stepComponentPath: 'multi-step-form/forms/administration/payables/add-levels'
        }),
        addLineItem: Ember.Object.create({
          stepNameKey: 'addLineItem',
          title: intl.t('pages.administration.human_resources.new_line_item'),
          progressBarTitle: intl.t('pages.customers.accounting.payables.line_item'),
          stepComponentPath: 'multi-step-form/forms/administration/payables/add-line-items'
        })
      });
    }

    get formOriginalModels() {
      return Ember.Object.create({
        payrollRoleFunctionLevel: {
          model: this.addLevel,
          copyRelationMap: {
            payrollRoleFunction: {
              readOnly: true
            }
          }
        },
        payrollColumn: {
          model: null,
          requiresOverride: true,
          copyRelationMap: {
            payrollRoleFunctionLevel: {
              readOnly: true
            }
          }
        }
      });
    }

    get relatedObjects() {
      return this.SwitchableFormStep.isNewRecord ? [] : this.relatedObjectsTask.perform();
    }

    cancel() {
      this.args.multiStepForm.setupCopyModels.perform();
      this.args.multiStepForm.validationErrors = {};
      this.router.transitionTo('administration.company-settings.system.payables');
    }

    transitionToFunctionLevel(level) {
      this.router.transitionTo('administration.company-settings.system.payables.function-details.level-details', level.get('id'));
    }

    onAfterSave(saved, toUpdate) {
      Ember.set(this, toUpdate, saved);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showDuplicateFunctionModal", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "addLevel", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "addLevelFormStarted", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "addLevelStepName", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'addLevel';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "levelFormMode", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'create';
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "duplicateName", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "duplicateDescription", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "deleteOpen", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "relatedObjectsTask", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "duplicateFunction", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "editFunctionTask", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "deleteFunction", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transitionToFunctionLevel", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToFunctionLevel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onAfterSave", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "onAfterSave"), _class.prototype)), _class));
  _exports.default = EditFunctionDetails;
});