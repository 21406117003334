define("securitytrax-ember-ui/components/modals/confirm-popup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SDlxwqhK",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,0,[32,0,[\"class\"]]],[12],[2,\"\\n\"],[6,[37,8],null,[[\"modalButtons\",\"title\",\"dialogOpen\",\"classNames\",\"modalNamespace\",\"cancelDialogAction\"],[[35,7],[35,6],[35,5],\"st-modal--small no-min-height\",[35,4],[35,3]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[15,0,[31,[\"grid-x grid-padding-x align-center-middle \",[34,0]]]],[12],[2,\"\\n            \"],[10,\"div\"],[15,0,[31,[\"cell small-11 align-self-middle \",[34,1]]]],[12],[2,\"\\n                \"],[2,[34,2]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[18,1,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"messageContainerClasses\",\"messageClasses\",\"confirmMessage\",\"onCancel\",\"modalNamespace\",\"_dialogOpen\",\"confirmTitleText\",\"confirmButtons\",\"modals/st-paper\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/modals/confirm-popup/template.hbs"
    }
  });

  _exports.default = _default;
});