define("securitytrax-ember-ui/transforms/moment-date-time-no-timezone", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * API is ISO
   * model is moment object
   */
  var _default = _emberData.default.DateTransform.extend({
    deserialize(serialized) {
      if (serialized) {
        var timezone = _moment.default.tz.guess(); //console.warn('CALENDAR STARTTIME INCOMING (should show correct time and users timezone)>> ', moment.tz(serialized, 'YYYY-MM-DD HH:mm:ss', timezone).format());


        return _moment.default.tz(serialized, 'YYYY-MM-DD HH:mm:ss', timezone).format();
      }

      return null;
    },

    serialize(deserialized) {
      if (deserialized) {
        if (_moment.default.isMoment(deserialized)) {
          deserialized = deserialized.format('YYYY-MM-DD HH:mm:ss'); //get rid of timezone in moment object
        } // TODO: This is a TIMEZONE CAST, not CONVERT > necessary for V2 compatibility
        // i.e. if selected 10am EST, send 10am MTN, if 10am CST, send 10am MTN. if 10am MTN, send 10am MTN
        //console.warn('CALENDAR STARTTIME OUTGOING (correct time but with offset of -6, not users timeszone offset)>> ', moment.tz(deserialized, 'YYYY-MM-DD HH:mm:ss', 'America/Denver').format());


        return _moment.default.tz(deserialized, 'YYYY-MM-DD HH:mm:ss', 'America/Denver').format();
      }

      return null;
    }

  });

  _exports.default = _default;
});