define("securitytrax-ember-ui/components/multi-step-form/forms/demo/demo-wizard/demo-wizard-base", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step"], function (_exports, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {});

  _exports.default = _default;
});