define("securitytrax-ember-ui/components/multi-step-form/forms/administration/account-management/add-on-confirmation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "30hBcrQT",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"grid-x grid-padding-x\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cell small-12\"],[12],[2,\"\\n       \"],[10,\"p\"],[12],[1,[34,0]],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n   \"]],\"hasEval\":false,\"upvars\":[\"text\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/account-management/add-on-confirmation/template.hbs"
    }
  });

  _exports.default = _default;
});