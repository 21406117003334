define("securitytrax-ember-ui/components/multi-step-form/switchable-inputs/datetime-range-input/component", ["exports", "securitytrax-ember-ui/mixins/switchable-input", "moment", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _switchableInput, _moment, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Contextual component utilizing the following:
   * Basic Dropdown https://ember-basic-dropdown.com/docs/
   * Ember Power Calendar https://ember-power-calendar.com/docs/
   * Input masking https://github.com/RobinHerbots/Inputmask
   */
  var _default = Ember.Component.extend(_switchableInput.default, {
    format: undefined,
    locale: 'en',
    firstDay: null,
    showDaysAround: true,
    //shows days of surrounding months
    disabledDates: null,
    //array of dates to not allow selection
    showYearNav: false,
    showDatePicker: true,
    labelProperty: 'option',
    valueProperty: 'value',
    hasSelect: false,
    rangeInitiallySelected: false,
    minRange: 0,
    ampm: 'AM',
    dateEvent: Ember.inject.service('events/date'),
    isOpen: false,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        format: 'MM/DD/YYYY hh:mm a'
      });

      if (this.fromDate) {
        Ember.set(this, 'fromDate', (0, _moment.default)(this.fromDate, 'MM/DD/YYYY').format(this.format));
      }

      if (this.toDate) {
        Ember.set(this, 'toDate', (0, _moment.default)(this.toDate, 'MM/DD/YYYY').format(this.format));
      }
    },

    momentValue: Ember.computed('value', function () {
      return (0, _moment.default)(this.value, 'MM/DD/YYYY').format(this.format);
    }),
    placeholder: Ember.computed(function () {
      return 'mm/dd/yyyy hh:mm am - mm/dd/yyyy hh:mm am';
    }),
    valueFormatted: Ember.computed('fromDate', 'toDate', function () {
      if (this.fromDate || this.toDate) {
        return (this.fromDate ? (0, _moment.default)(this.fromDate, 'MM/DD/YYYY').format(this.format) : '') + ' - ' + (this.toDate ? (0, _moment.default)(this.toDate, 'MM/DD/YYYY').format(this.format) : '');
      } else {
        return null;
      }
    }),
    actions: {
      clearToFrom(dropdown, event) {
        Ember.set(this, 'fromDate', null);
        Ember.set(this, 'toDate', null);
        this.dateEvent.trigger('clear-date');

        if (event) {
          event.stopPropagation();
        }

        if (this.isOpen) {
          dropdown.actions.toggle();
        }

        if (this.onChange) {
          return this.onChange();
        }
      },

      dateChanged() {
        if (this.fromDate && this.toDate && this.toDate.isBefore(this.fromDate)) {
          var tempFromDate = this.fromDate;
          Ember.set(this, 'fromDate', this.toDate);
          Ember.set(this, 'toDate', tempFromDate);
        }

        if (this.onChange) {
          return this.onChange();
        }
      },

      openDropdown() {
        Ember.set(this, 'isOpen', true);

        if (this.openAction && typeof this.openAction === 'function') {
          this.openAction();
        }
      },

      closeDropdown() {
        Ember.set(this, 'isOpen', false);

        if (this.closeAction && typeof this.closeAction === 'function') {
          this.closeAction();
        }
      }

    }
  });

  _exports.default = _default;
});