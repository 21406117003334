define("securitytrax-ember-ui/services/yes-or-no-options-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),

    getYesOrNoOptions() {
      return [{
        name: this.intl.t('common.status.yes'),
        value: true
      }, {
        name: this.intl.t('common.status.no'),
        value: false
      }];
    }

  });

  _exports.default = _default;
});