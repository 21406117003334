define("securitytrax-ember-ui/components/multi-step-form/forms/administration/permissions/group-resource-type/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/administration/permissions/permissions-base", "ember-concurrency"], function (_exports, _permissionsBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _permissionsBase.default.extend({
    intl: Ember.inject.service(),
    screen: Ember.inject.service(),
    packageSelectEvent: Ember.inject.service('events/package-select-event'),
    permissionsService: Ember.inject.service(),
    groupingDivOpen: false,
    showDeletePoliciesWarning: false,

    init() {
      this._super(...arguments);

      this.setupPills.perform();
    },

    titleSeparator: Ember.computed(function () {
      return '<div class="breadcrumb-separator modal-title-separator"></div>';
    }),
    // Make sure all package select items are of the same height, per grouping div
    groupingDivObserver: Ember.observer('screen.width', 'groupingDivOpen', function (evt) {
      var maxHeight = 34;
      var screenWidth = this.get('screen.width');
      var selector = '#' + evt.elementId + ' .package-select .cell .individual-package';
      var groupingDivOpen = this.groupingDivOpen;

      if (groupingDivOpen && screenWidth) {
        Ember.run.next(() => {
          var elements = document.querySelectorAll(selector);

          for (var i = 0; i < elements.length; i++) {
            elements[i].removeAttribute('style'); // If we are resetting the height

            maxHeight = elements[i].offsetHeight > maxHeight ? elements[i].offsetHeight : maxHeight;
          }

          elements.forEach(el => {
            el.style.height = Number(maxHeight) + 'px';
          });
        });
      }
    }),
    resourceName: Ember.computed('row', function () {
      return this.row.resourceType.name;
    }),
    resourceDescription: Ember.computed('row', function () {
      var resourceId = this.get('row.resourceTypeId');
      var desc = null;
      var translationKeyExists = this.intl.exists("resource_types.".concat(resourceId, ".description"));

      if (translationKeyExists) {
        desc = this.intl.t("resource_types.".concat(resourceId, ".description"));
      }

      return desc;
    }),
    setBulkAndIndeterminate: (0, _emberConcurrency.task)(function* (group, resourceType) {
      try {
        var selected = group.resourceGrantsSelected;
        resourceType = resourceType ? resourceType : yield group.resourceType;
        var total = group.resourceGrants.toArray();

        if (selected.length === total.length) {
          Ember.set(group, 'indeterminate', false);
          Ember.set(resourceType, 'bulkCheck', true);
        } else if (selected.length === 0) {
          Ember.set(group, 'indeterminate', false);
          Ember.set(resourceType, 'bulkCheck', false);
        } else {
          Ember.set(resourceType, 'bulkCheck', false);
          Ember.set(group, 'indeterminate', true);
        }

        this.updateTotalCheckedTask.perform();
      } catch (err) {
        (false && Ember.warn('failed to set checkbox and indeterminate values: ' + err, {
          id: 'forms.administration.permissions.group-resource-type.setBulkAndIndeterminate'
        }));
      }
    }),
    setupPills: (0, _emberConcurrency.task)(function* () {
      var groupResourceTypePolicies = yield this.get('permissionsService.getGroupPolicyList').perform(this.group);
      var row = this.row;
      var filteredGroupResourceTypePolicies = groupResourceTypePolicies.filter(function (policy) {
        return policy.get('deletedAt') == null && policy.get('resourceTypePolicy.resourceTypeId') == row.resourceType.id;
      });
      this.pills = [];
      var policiesCount = filteredGroupResourceTypePolicies.length;
      var policiesText = policiesCount > 1 ? this.intl.t('pages.users.human_resources.policies_applied', {
        totalPoliciesSet: policiesCount
      }) : this.intl.t('pages.users.human_resources.policy_applied');

      if (policiesCount > 0) {
        this.pills.push({
          pillText: policiesText,
          pillColor: 'background-color: #22A1C1'
        });
      }

      this.pills.push({
        pillText: this.intl.t('pages.users.human_resources.grants_applied', {
          totalChecked: this.row.ogSelected.length,
          totalItems: this.row.resourceGrants.get('length')
        }),
        pillColor: 'background-color: #003865'
      });
    }),
    actions: {
      updateSelectedGrants(group, selectedGrants, grantChanged) {
        var grantAdded = selectedGrants.includes(grantChanged);

        if (grantAdded) {
          if (!group.addedGrants.includes(grantChanged)) {
            group.addedGrants.push(grantChanged);
          }

          if (group.removedGrants.includes(grantChanged)) {
            group.removedGrants.splice(group.removedGrants.indexOf(grantChanged), 1);
          }
        } else {
          if (!group.removedGrants.includes(grantChanged) && this.row.ogSelected.includes(grantChanged)) {
            group.removedGrants.push(grantChanged);
          }

          if (group.addedGrants.includes(grantChanged)) {
            group.addedGrants.splice(group.addedGrants.indexOf(grantChanged), 1);
          }
        }

        this.notifyPropertyChange('row');
        this.setBulkAndIndeterminate.perform(group);
      },

      bulkAddOrRemovePackageSelect(group, resourceType) {
        if (resourceType.get('bulkCheck')) {
          var resourceGrants = group.resourceGrants.toArray();
          var selectedArray = resourceGrants.map(item => {
            return item.id;
          });
          Ember.set(group, 'resourceGrantsSelected', selectedArray);
          Ember.set(group, 'addedGrants', selectedArray);
          Ember.set(group, 'removedGrants', []);
        } else {
          Ember.set(group, 'removedGrants', this.row.ogSelected);
          Ember.set(group, 'addedGrants', []);
          Ember.set(group, 'resourceGrantsSelected', []);
        }

        this.packageSelectEvent.trigger('update-selected-array');
        this.setBulkAndIndeterminate.perform(group, resourceType);
      }

    }
  });

  _exports.default = _default;
});