define("securitytrax-ember-ui/services/timezones", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * List of US and Canada Timezones from https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
   *
   * @class Timezones
   * @memberof services
   * @extends Ember.Service
   */
  var _default = Ember.Service.extend({
    init() {
      this._super(...arguments); // this.set('all', [
      //     { value: 'US/Alaska', name: 'US/Alaska' },
      //     { value: 'US/Aleutian', name: 'US/Aleutian' },
      //     { value: 'US/Arizona', name: 'US/Arizona' },
      //     { value: 'US/Central', name: 'US/Central' },
      //     { value: 'US/Eastern', name: 'US/Eastern' },
      //     { value: 'US/East-Indiana', name: 'US/East-Indiana' },
      //     { value: 'US/Hawaii', name: 'US/Hawaii' },
      //     { value: 'US/Indiana-Starke', name: 'US/Indiana-Starke' },
      //     { value: 'US/Michigan', name: 'US/Michigan' },
      //     { value: 'US/Mountain', name: 'US/Mountain' },
      //     { value: 'US/Pacific', name: 'US/Pacific' },
      //     { value: 'US/Pacific-New', name: 'US/Pacific-New' },
      //     { value: 'US/Samoa', name: 'US/Samoa' },
      //     { value: 'Canada/Atlantic', name: 'Canada/Atlantic' },
      //     { value: 'Canada/Central', name: 'Canada/Central' },
      //     { value: 'Canada/Eastern', name: 'Canada/Eastern' },
      //     { value: 'Canada/Mountain', name: 'Canada/Mountain' },
      //     { value: 'Canada/Newfoundland', name: 'Canada/Newfoundland' },
      //     { value: 'Canada/Pacific', name: 'Canada/Pacific' },
      //     { value: 'Canada/Saskatchewan', name: 'Canada/Saskatchewan' },
      //     { value: 'Canada/Yukon', name: 'Canada/Yukon' }
      // ]);
      // Only the compacted list for MVP


      this.set('all', [{
        value: 'US/Alaska',
        name: 'US/Alaska'
      }, {
        value: 'US/Arizona',
        name: 'US/Arizona'
      }, {
        value: 'US/Central',
        name: 'US/Central'
      }, {
        value: 'US/East-Indiana',
        name: 'US/East-Indiana'
      }, {
        value: 'US/Eastern',
        name: 'US/Eastern'
      }, {
        value: 'US/Hawaii',
        name: 'US/Hawaii'
      }, {
        value: 'US/Mountain',
        name: 'US/Mountain'
      }, {
        value: 'US/Pacific',
        name: 'US/Pacific'
      }, {
        value: 'Africa/Cairo',
        name: 'Africa/Cairo'
      }, {
        value: 'Africa/Monrovia',
        name: 'Africa/Monrovia'
      }, {
        value: 'Europe/Athens',
        name: 'Europe/Athens'
      }, {
        value: 'Europe/Berlin',
        name: 'Europe/Berlin'
      }, {
        value: 'Europe/London',
        name: 'Europe/London'
      }, {
        value: 'Europe/Moscow',
        name: 'Europe/Moscow'
      }, {
        value: 'PR/Atlantic',
        name: 'PR/Atlantic'
      }]);
    }

  });

  _exports.default = _default;
});