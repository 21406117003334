define("securitytrax-ember-ui/components/html/notifications/st-app-notification/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vBBKTehh",
    "block": "{\"symbols\":[\"theButton\"],\"statements\":[[10,\"div\"],[14,0,\"before\"],[12],[2,\"\\n    \"],[1,[30,[36,6],[[35,5]],[[\"class\"],[\"notification-icon\"]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[34,7]],[2,\"\\n\\n\"],[6,[37,8],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"buttons\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,1],null,[[\"class\",\"icon\",\"iconOnly\",\"text\",\"noBackground\",\"clickAction\"],[[32,1,[\"cssClass\"]],[32,1,[\"icon\"]],[32,1,[\"iconOnly\"]],[32,1,[\"text\"]],[32,1,[\"noBackground\"]],[30,[36,0],[[32,0],\"buttonClicked\",[32,1,[\"action\"]]],null]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,1],null,[[\"class\",\"icon\",\"iconOnly\",\"text\",\"noBackground\",\"clickAction\"],[\"close-notification\",\"icn-close\",true,\"Close\",true,[30,[36,0],[[32,0],\"closeNotification\",[35,10]],[[\"target\"],[[35,9]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"html/st-button\",\"buttons\",\"-track-array\",\"each\",\"icon\",\"svg-icon\",\"text\",\"if\",\"target\",\"notification\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/notifications/st-app-notification/template.hbs"
    }
  });

  _exports.default = _default;
});