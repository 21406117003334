define("securitytrax-ember-ui/helpers/priority-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(priority) {
      if (typeof priority === 'object') {
        priority = priority[0];
      }

      if (typeof priority === 'number') {
        priority = priority.toString();
      }

      if (priority === '3') {
        return this.intl.t('pages.administration.all_tasks.priority_high');
      } else if (priority === '2') {
        return this.intl.t('pages.administration.all_tasks.priority_med');
      } else if (priority === '1') {
        return this.intl.t('pages.administration.all_tasks.priority_low');
      } else {
        return this.intl.t('pages.administration.all_tasks.priority_none');
      }
    }

  });

  _exports.default = _default;
});