define("securitytrax-ember-ui/components/multi-step-form/forms/system/telus/general/edit-subject/component", ["exports", "ember-concurrency", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step"], function (_exports, _emberConcurrency, _component, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EditSubject = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    if (this.addresses.length === 1) {
      this.stNotificationManager.addError(this.intl.t('pages.customers.system.telus.general.subject.address_required'), 'telusSubjectModal');
      throw new Error();
    } else {
      var formattedObjects = Ember.A();
      var subject = {
        _account_type: this.customer.accountType,
        subject_first_name: this.customer.fname,
        subject_middle_name: this.customer.mname,
        subject_last_name: this.customer.lname,
        subject_name_suffix_code: this.customer.suffix,
        date_of_birth: this.customer.birthdate,
        occupation: this.customer.occupation,
        employer: this.customer.employer,
        account_number: this.customer.accountNum,
        spouse_subject_id: this.customer.spouseSubject,
        firm_name: this.customer.businessName,
        addresses: [],
        phones: []
      };
      formattedObjects.pushObject(Ember.Object.create({
        model: subject,
        filePath: 'central-station/telus-subject',
        extraValidationData: {
          noValidate: subject._account_type === 'Residential' ? ['telusSubject-firmName'] : ['telusSubject-fname', 'telusSubject-lname']
        }
      }));
      this.addresses.forEach((address, index) => {
        if (index < this.addresses.length - 1) {
          subject.addresses.push(address);
          address['street_name_and_app'] = address.street;
          address['postal_code'] = address.postal_code.toUpperCase();
          formattedObjects.pushObject(Ember.Object.create({
            model: address,
            filePath: 'central-station/telus-subject-address',
            extraValidationData: {
              overrideValidationKey: index + '-telusSubjectAddress'
            }
          }));
        }
      });
      this.phoneNumbers.forEach((phoneNumber, index) => {
        if (index < this.phoneNumbers.length - 1) {
          phoneNumber['area_code'] = phoneNumber['number'].substring(0, 3);
          phoneNumber['number'] = phoneNumber['number'].replace(phoneNumber['area_code'], '');
          subject.phones.push(phoneNumber);
          formattedObjects.pushObject(Ember.Object.create({
            model: phoneNumber,
            filePath: 'central-station/telus-subject-phone-number',
            extraValidationData: {
              overrideValidationKey: index + '-telusSubjectPhoneNumber'
            }
          }));
        }
      });
      yield this.args.multiStepForm.validateList.perform(formattedObjects);

      try {
        yield this.centralStationService.postData({
          model: this.args.customer,
          rawResponse: false,
          method: 'adt_canada__upsertCreditVerificationSubject',
          payload: subject
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'telusSubjectModal'
        });
        throw err;
      }

      buttonPromise.then(() => {
        this.args.multiStepForm.formStartedSetVal(false);

        if (typeof this.args.refreshSubjects === 'function') {
          this.args.refreshSubjects(this.args.subjects.length === 0);
        }
      });
    }
  }), _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, (_class = (_temp = class EditSubject extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "centralStationService", _descriptor2, this);

      _initializerDefineProperty(this, "usStates", _descriptor3, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor4, this);

      _initializerDefineProperty(this, "customer", _descriptor5, this);

      _initializerDefineProperty(this, "addresses", _descriptor6, this);

      _initializerDefineProperty(this, "phoneNumbers", _descriptor7, this);

      _initializerDefineProperty(this, "addressDeleteId", _descriptor8, this);

      _initializerDefineProperty(this, "phoneNumberDeleteId", _descriptor9, this);

      _initializerDefineProperty(this, "spouseOptions", _descriptor10, this);

      _initializerDefineProperty(this, "addSubject", _descriptor11, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.spouseOptions = [];

      if (this.args.subjects.length === 0) {
        var civic = this.args.customer.address1.split(' ')[0];
        this.phoneNumbers.pushObject(Ember.Object.create({
          telephone_type: 'H',
          number: this.args.customer.primaryPhone,
          newId: '1'
        }));
        this.addresses.pushObject(Ember.Object.create({
          address_type_code: 'CURR',
          civic_number: civic,
          street: this.args.customer.address1.replace(civic + ' ', ''),
          suite: this.args.customer.address2,
          city: this.args.customer.city,
          province_code: this.args.customer.state,
          postal_code: this.args.customer.zip,
          newId: '1'
        }));
        this.customer = Ember.Object.create({
          accountType: this.args.customer.accountType,
          birthdate: this.args.customer.get('customerBirthdate.birthdate'),
          accountNum: this.args.customer.get('customerSystemInformation.accountNum'),
          fname: this.args.customer.fname,
          lname: this.args.customer.lname,
          businessName: this.args.customer.businessName
        });
      } else {
        this.customer = Ember.Object.create({
          accountType: this.args.customer.accountType,
          birthdate: null,
          accountNum: null
        });
        this.args.subjects.forEach(subject => {
          this.spouseOptions.push({
            id: subject.subject_id,
            name: subject.subject_first_name + ' ' + subject.subject_last_name
          });
        });
      }

      this.addresses.pushObject(Ember.Object.create({}));
      this.phoneNumbers.pushObject(Ember.Object.create({}));
    }

    setupButtons() {
      var buttons = [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.addSubject.perform(buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary'
      }];
      this.args.multiStepForm.registerStepData('step1', 'buttons', buttons);
    }

    addContactInfo(row, index, list) {
      if (index === list.length - 1) {
        row.set('newId', index + 1);
        list.pushObject(Ember.Object.create({}));
      }
    }

    deleteContactInfo(row, list) {
      var theList = Ember.get(this, list);
      var newList = theList.filter(item => {
        return row != item;
      });
      Ember.set(this, list, newList);
      this.phoneNumberDeleteId = null;
      this.addressDeleteId = null;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "usStates", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "customer", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "addresses", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "phoneNumbers", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "addressDeleteId", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "phoneNumberDeleteId", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "spouseOptions", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "addSubject", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupButtons", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "setupButtons"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addContactInfo", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "addContactInfo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteContactInfo", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "deleteContactInfo"), _class.prototype)), _class));
  _exports.default = EditSubject;
});