define("securitytrax-ember-ui/components/calendar-glimmer/calendar-grid/calendar-grid-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0CrHo3Gk",
    "block": "{\"symbols\":[\"@cellIndex\",\"@rowIndex\",\"@gridIndex\",\"@hourData\",\"@row\",\"@startAddingNewCalendarEvent\"],\"statements\":[[2,\"\\n\"],[10,\"div\"],[15,1,[31,[[30,[36,0],[[32,3],\"-\",[32,2],\"-\",[32,1]],null]]]],[15,\"rowIndex\",[32,2]],[15,5,[32,0,[\"gridStyle\"]]],[15,0,[30,[36,0],[\"grid-row__item calendar__drop-cell \",[32,4,[\"class\"]],\" calendar__drop-cell-index--\",[32,1],\" \",[32,0,[\"timeSlotClass\"]],\" \",[32,3],\"-\",[32,2],\"-\",[32,1],\" \",[32,0,[\"cellClasses\"]]],null]],[15,\"onclick\",[30,[36,1],[[32,0],[32,6],[32,1],[32,4],[32,5]],null]],[15,\"onmouseover\",[30,[36,1],[[32,0],[32,0,[\"mouseOverCell\"]]],null]],[15,\"onmouseout\",[30,[36,1],[[32,0],[32,0,[\"mouseOutCell\"]]],null]],[15,\"ondragover\",[30,[36,1],[[32,0],[32,0,[\"CalendarDragDropService\",\"dragOver\"]],[32,5]],null]],[15,\"ondragleave\",[30,[36,1],[[32,0],[32,0,[\"CalendarDragDropService\",\"dragLeave\"]],[32,5]],null]],[15,\"ondrop\",[30,[36,1],[[32,0],[32,0,[\"CalendarDragDropService\",\"drop\"]],[32,5],[32,4,[\"momentTime\"]]],null]],[12],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"concat\",\"action\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/calendar-glimmer/calendar-grid/calendar-grid-cell/template.hbs"
    }
  });

  _exports.default = _default;
});