define("securitytrax-ember-ui/customers/messages/create-message/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/subroute-modal", "securitytrax-ember-ui/mixins/route-pageview-logging", "ember-concurrency"], function (_exports, _authenticatedRouteMixin, _subrouteModal, _routePageviewLogging, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _subrouteModal.default, _routePageviewLogging.default, {
    grants: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['customer_messages.create']);
    },

    model({
      mid
    }) {
      this.set('modelId', mid);
      return this.setupModelTask.perform();
    },

    setupModelTask: (0, _emberConcurrency.task)(function* () {
      var parentModel = yield this.modelFor(this.parentRoute);
      return Ember.RSVP.hash({
        customer: parentModel.customer,
        messageId: this.modelId,
        parentRoute: this.transitionRoute,
        transitionId: parentModel.customer.get('id')
      });
    })
  });

  _exports.default = _default;
});