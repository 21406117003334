define("securitytrax-ember-ui/services/enhanced-features", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Grants service so we can check permissions on routes/properties/functionality
   * @class enhancedFeatures
   * @extends Ember.Service
   * @memberof services
   */
  var enhancedFeatures = Ember.Service.extend({
    stAjax: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    premiumFeatures: Ember.computed(function () {
      return this.getEnhancedFeatures.perform();
    }),
    getEnhancedFeatures: (0, _emberConcurrency.task)(function* () {
      var premiumFeatures = yield this.store.findAll('premiumFeature');

      if (premiumFeatures.length > 0) {
        return premiumFeatures.objectAt(0);
      } else {
        return null;
      }
    }),

    hasFeature(feature) {
      return this.hasFeatureTask.perform(feature);
    },

    hasFeatureTask: (0, _emberConcurrency.task)(function* (feature) {
      var premiumFeatures = yield this.premiumFeatures;
      var retVal = false;

      if (premiumFeatures) {
        switch (feature) {
          case 'inventoryExtendedFunctionality':
            retVal = premiumFeatures.inventoryExtendedFunctionality;
            break;

          case 'enhancedPayables':
            retVal = premiumFeatures.enhancedPayables;
            break;

          case 'enhancedInvoices':
            retVal = premiumFeatures.enhancedInvoices;
            break;

          case 'sms':
            retVal = premiumFeatures.sms.enabled;
            break;

          case 'edoc':
            retVal = premiumFeatures.edoc;
            break;

          case 'consumerFinancing':
            retVal = premiumFeatures.consumerFinancing;
            break;

          case 'quickbooks':
            retVal = premiumFeatures.quickbooks;
            break;

          default:
            retVal = false;
            break;
        }
      }

      return retVal;
    })
  });
  var _default = enhancedFeatures;
  _exports.default = _default;
});