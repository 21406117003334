define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/qualify/component", ["exports", "securitytrax-ember-ui/utils/obj-helpers", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/customer-base", "lodash"], function (_exports, _objHelpers, _emberConcurrency, _customerBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerBase.default.extend({
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    store: Ember.inject.service(),
    usStates: Ember.inject.service(),
    creditWindow: Ember.inject.service(),
    grants: Ember.inject.service(),
    centralStationService: Ember.inject.service(),
    stAjax: Ember.inject.service(),
    creditBureauOptions: undefined,
    previousAddresses: undefined,
    usePreviousAddresses: false,
    intlBase: 'pages.customers.credit_checks.',
    pin: '',
    showDeleteId: false,
    selectedId: 1,
    monitoringCompanies: undefined,
    sepData: undefined,
    brinksData: undefined,
    hideAdtVerifyButton: false,
    // nrgUri: undefined,
    customer: undefined,
    creditCheckList: undefined,
    runCreditOptions: undefined,
    customerHasAdtConnection: undefined,
    stepName: 'step2',
    qualifyInstructions: '',
    creditBureau: undefined,
    manualBureau: undefined,
    initDataTask: (0, _emberConcurrency.task)(function* () {
      if (this.newCustomer) {
        this.customer = yield this.newCustomer;
      }

      var hashedData = yield (0, _emberConcurrency.hash)({
        monitoringCompanies: this.store.findAll('monitoringCompany'),
        monitoringCompanyTypes: this.store.findAll('monitoringCompanyType'),
        company: this.sessionData.getCompany(),
        waitForFinishedCopyModels: (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true),
        creditBureaus: this.store.findAll('creditBureau', {
          include: 'credit_bureau_type'
        }),
        runCreditOptions: this.creditOptionsTask.perform(),
        customerHasAdtConnection: this.customerHasAdtConnectionTask.perform()
      }); // this.nrgUri = hashedData.company.get('responseMeta.ui_conditional_features.reliant_run_credit_uri');

      this.monitoringCompanies = hashedData.monitoringCompanies;
      this.creditBureauOptions = hashedData.creditBureaus;

      if (this.creditBureauOptions.toArray().some(creditBureau => {
        return creditBureau.get('creditBureauType.id') == 11;
      })) {
        this.runCreditOptions = hashedData.runCreditOptions;
      } else {
        this.runCreditOptions = hashedData.runCreditOptions.filter(creditOption => {
          return creditOption.packageName != 'Manual Entry';
        });
      }

      this.customerHasAdtConnection = hashedData.customerHasAdtConnection; // if (this.nrg) {
      //     this.setupNRGButtons();
      //     if (this.multiStepForm.progressBarConfig.activeIndex === 3) {
      //         set(this, 'stepName', 'step4');
      //         set(this, 'qualifyInstructions', this.intl.t('pages.customers.nrg.preApproval.instruction'));
      //     } else {
      //         set(this, 'stepName', 'step7');
      //         set(this, 'qualifyInstructions', this.intl.t('pages.customers.nrg.externalCreditCheck.instruction'));
      //     }
      // } else 

      if (this.isASG) {
        this.setupASGButtons();
      } else {
        this.setupStep2Buttons();
      }

      (0, _objHelpers.initializeState)(this, {
        previousAddresses: [],
        monitoringCompanies: [],
        sepData: {},
        brinksData: {}
      });
      this.setupForm();
    }).on('init'),
    livingOptions: Ember.computed(function () {
      return [{
        name: this.intl.t('common.status.yes'),
        value: 'yes'
      }, {
        name: this.intl.t('common.status.no'),
        value: 'no'
      }];
    }),
    loanTypeOptions: Ember.computed(function () {
      return [{
        name: this.intl.t('pages.customers.credit_checks.sep.ACH'),
        value: 'ACH'
      }, {
        name: this.intl.t('pages.customers.credit_checks.sep.creditCard'),
        value: 'CreditCard'
      }, {
        name: this.intl.t('pages.customers.credit_checks.sep.debitCard'),
        value: 'DebitCard'
      }];
    }),
    creditBureaus: Ember.computed('creditBureauOptions.[]', function () {
      var options = [];

      if (this.creditBureauOptions) {
        options = this.creditBureauOptions.filter(creditBureau => {
          return creditBureau.get('creditBureauType.id') != 11;
        });
      }

      return options;
    }),
    manualBureaus: Ember.computed('creditBureauOptions.[]', function () {
      var options = [];

      if (this.creditBureauOptions) {
        options = this.creditBureauOptions.filter(creditBureau => {
          return creditBureau.get('creditBureauType.id') == 11;
        });
      }

      if (options.length === 1) {
        this.set('manualBureau', options[0]);
      }

      return options;
    }),
    runCredit: Ember.computed('selectedId', function () {
      return this.selectedId === 1;
    }),
    verifyWithAdt: Ember.computed('selectedId', function () {
      return this.selectedId === 3;
    }),
    creditButtonText: Ember.computed('runCredit', function () {
      // if (this.nrgUri) {
      //     return this.intl.t('pages.customers.nrg.preApproval.button');
      // } else {
      return this.runCredit ? this.intl.t(this.intlBase + 'run_credit') : this.intl.t(this.intlBase + 'save_credit'); // }
    }),
    qualifyCustomer: Ember.computed(function () {
      return this.customer ? this.customer : this.multiStepForm.formOriginalModelsGetVal('customer.model');
    }),
    customerCreditChecks: Ember.computed('qualifyCustomer.customerCreditChecks.[]', function () {
      var customerCreditChecks = this.qualifyCustomer.get('customerCreditChecks').toArray();
      var actualCreditChecks = customerCreditChecks.filter(creditCheck => {
        return creditCheck.get('id') != null;
      });
      this.set('creditCheckList', actualCreditChecks); // if (this.nrg) {
      //     this.setupNRGButtons();
      // }

      return actualCreditChecks;
    }),
    validationMessages: Ember.computed(function () {
      return {
        'customerCreditCheck-creditBureau': this.intl.t(this.intlBase + 'creditBureau.validateMessage'),
        'customerCreditCheck-manualBureau': this.intl.t(this.intlBase + 'manualBureau.validateMessage'),
        'customerCreditCheck-overrideReason': this.intl.t(this.intlBase + 'brinksOverrideReason.validateMessage')
      };
    }),

    setupForm() {
      this.set('previousAddresses', []);
      this.set('pin', '');
      this.insertEmptyAddress();
      this.set('usePreviousAddresses', false);
      this.set('sepData', {});
      this.set('brinksData', {});
      this.set('creditBureau', undefined), this.set('manualBureau', undefined), this.multiStepForm.setupCopyModels.perform();
    },

    setupStep2Buttons: function () {
      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('step2', 'buttons', [{
        text: this.intl.t('common.actions.back'),
        classNamesString: 'st-btn st-btn-secondary',
        click: () => {
          multiStepForm.currentStepNameKeySetVal('step1');
          multiStepForm.progressBarConfig.activeIndex = 0;
        }
      }, {
        text: this.intl.t('common.actions.saveAndContinue'),
        classNamesString: 'st-btn st-btn-primary',
        click: buttonPromise => {
          return this.saveInfo.perform(multiStepForm, buttonPromise);
        },
        positionOptions: 'up left',
        additionalClickActions: [{
          label: this.intl.t('common.actions.deleteCustomer'),
          action: () => {
            this.openConfirmDelete('step2');
          },
          classNamesString: 'dropdown-delete-customer'
        }, {
          label: this.intl.t('common.actions.saveAndClose'),
          action: buttonPromise => {
            return this.saveInfo.perform(multiStepForm, buttonPromise, true);
          },
          classNamesString: 'dropdown-save-close'
        }]
      }]);
    },
    setupASGButtons: function () {
      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('step2', 'buttons', [{
        text: this.intl.t('common.actions.back'),
        classNamesString: 'st-btn st-btn-secondary',
        click: () => {
          multiStepForm.currentStepNameKeySetVal('step1');
          multiStepForm.progressBarConfig.activeIndex = 0;
        }
      }, {
        text: this.intl.t('common.actions.skip'),
        classNamesString: 'st-btn st-btn-secondary',
        click: () => {
          this.advanceForm(multiStepForm, 'step3', 2);
        }
      }, {
        text: this.intl.t('common.actions.saveAndContinue'),
        classNamesString: 'st-btn st-btn-primary',
        click: buttonPromise => {
          return this.saveInfo.perform(multiStepForm, buttonPromise);
        },
        positionOptions: 'up left',
        additionalClickActions: [{
          label: this.intl.t('common.actions.deleteCustomer'),
          action: () => {
            this.openConfirmDelete('step2');
          },
          classNamesString: 'dropdown-delete-customer'
        }, {
          label: this.intl.t('common.actions.saveAndClose'),
          action: buttonPromise => {
            return this.saveInfo.perform(multiStepForm, buttonPromise, true);
          },
          classNamesString: 'dropdown-save-close'
        }]
      }]);
    },
    // setupNRGButtons: function() {
    //     const multiStepForm = this.multiStepForm;
    //     multiStepForm.registerStepData('step4', 'buttons', [{
    //             text: this.intl.t('common.actions.back'),
    //             classNamesString: 'st-btn st-btn-secondary',
    //             click: () => {
    //                 multiStepForm.currentStepNameKeySetVal('step3');
    //                 multiStepForm.progressBarConfig.activeIndex = 2;
    //             }
    //         },
    //         {
    //             text: this.intl.t('common.actions.skip'),
    //             classNamesString: 'st-btn st-btn-secondary',
    //             click: () => {
    //                 this.advanceForm(multiStepForm, 'step5', 4);
    //             }
    //         },
    //         {
    //             text: this.intl.t('common.actions.next'),
    //             classNamesString: 'st-btn st-btn-' + (this.creditCheckList ? (this.creditCheckList.length > 0 ? 'primary' : 'secondary') : 'secondary'),
    //             disabled: (this.creditCheckList ? this.creditCheckList.length < 1 : true),
    //             click: () => {
    //                 this.advanceForm(multiStepForm, 'step5', 4);
    //             }
    //         }
    //     ]);
    //     multiStepForm.registerStepData('step7', 'buttons', [{
    //             text: this.intl.t('common.actions.back'),
    //             classNamesString: 'st-btn st-btn-secondary',
    //             click: () => {
    //                 multiStepForm.currentStepNameKeySetVal('step6');
    //                 multiStepForm.progressBarConfig.activeIndex = 5;
    //             }
    //         },
    //         {
    //             text: this.intl.t('common.actions.skip'),
    //             classNamesString: 'st-btn st-btn-secondary',
    //             disabled: (this.creditCheckList ? !this.creditCheckList.some(creditCheck => {
    //                 return parseInt(creditCheck.score, 10) === 1 ||
    //                 (parseInt(creditCheck.score, 10) >= 3 && parseInt(creditCheck.score, 10) <= 7);
    //             }) : true),
    //             click: () => {
    //                 this.advanceForm(multiStepForm, 'step8', 7);
    //             }
    //         },
    //         {
    //             text: this.intl.t('common.actions.next'),
    //             classNamesString: 'st-btn st-btn-' + (this.creditCheckList ? (this.creditCheckList.length > 0  ? 'primary' : 'secondary') : 'secondary'),
    //             disabled: (this.creditCheckList ? this.creditCheckList.length < 1 : true),
    //             click: () => {
    //                 this.advanceForm(multiStepForm, 'step8', 7);
    //             }
    //         }
    //     ]);
    // },
    saveInfo: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise, close = false) {
      yield multiStepForm.validateSyncSaveAll({
        extraValidationData: {
          noValidate: this.customer.get('isLead') ? ['saleDate', 'location', 'customer-zip', 'customer-address1', 'leadInformation-monitoringCompany'] : ['orderInformation-salesRep', 'orderInformation-customerCancelDate', 'leadSourceInformation-leadCompany', 'customer-zip', 'customer-address1']
        }
      });
      buttonPromise.then(() => {
        if (close) {
          this.set('formMode', 'create');
          this.closeForm(multiStepForm, this.customer);
        } else if (this.isASG && !this.grants.getIndividualGrant('customer_equipment.view')) {
          this.advanceForm(multiStepForm, 'step4', 3);
        } else {
          this.advanceForm(multiStepForm, 'step3', 2);
        }
      });
    }),

    insertEmptyAddress() {
      this.previousAddresses.pushObject({
        'address': '',
        'address2': '',
        'city': '',
        'state': '',
        'zip': ''
      });
    },

    showAdtPreCheck: Ember.computed('customer', function () {
      return !!this.get('customer.adtPreCheck') && this.newCustomer;
    }),
    creditOptionsTask: (0, _emberConcurrency.task)(function* () {
      var creditOptions = [Ember.Object.create({
        packageName: this.intl.t(this.intlBase + 'run_credit'),
        packageId: 1
      }), Ember.Object.create({
        packageName: this.intl.t(this.intlBase + 'manual_entry'),
        packageId: 2
      })];

      if ((yield this.isAdtCompany) && (yield this.customerHasAdtConnection) && !this.customer.isLead) {
        creditOptions.push(Ember.Object.create({
          packageName: this.intl.t('pages.customers.credit_checks.verify_adt'),
          packageId: 3
        }));
      }

      return creditOptions;
    }),
    getAdtCreditScore: (0, _emberConcurrency.task)(function* (buttonPromise) {
      try {
        var response = yield this.centralStationService.getData({
          model: this.customer,
          method: 'adt__queryAdtForApplicationResult',
          rawResponse: true,
          payload: {
            application_id: this.multiStepForm.formCopiedModels.customerCreditCheck.model.transactionId,
            ignore_cache: true
          }
        });

        if (_lodash.default.get(response, 'responsePayload.data.messages', []).length > 0) {
          var _this$modalNamespace;

          this.stNotificationManager.addWarningMessages(response.responsePayload.data.messages, {
            notificationNamespace: (_this$modalNamespace = this.modalNamespace) !== null && _this$modalNamespace !== void 0 ? _this$modalNamespace : 'modal'
          });
        }

        this.multiStepForm.formCopiedModelsSetVal('customerCreditCheck.model.score', response.responsePayload.data.payload.adt__queryAdtForApplicationResultResponse.beacon_score);
        buttonPromise.then(() => {
          this.set('hideAdtVerifyButton', true);
        });
      } catch (err) {
        var _this$modalNamespace2;

        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: (_this$modalNamespace2 = this.modalNamespace) !== null && _this$modalNamespace2 !== void 0 ? _this$modalNamespace2 : 'modal'
        });
        throw err;
      }
    }),
    creditMetaData: false,
    saveCreditScore: (0, _emberConcurrency.task)(function* (buttonPromise) {
      // if (this.nrgUri) {
      //     const splitAddress1 = this.qualifyCustomer.get('address1') ? this.qualifyCustomer.get('address1').split(' ') : [''];
      //     const udfs = yield this.qualifyCustomer.get('udfs');
      //     const caNumber = udfs.findBy('id', JSON.stringify({ object_id: this.qualifyCustomer.get('id'), udf_id: '10' }));
      //     let streetName = '';
      //     splitAddress1.forEach((text, index) => {
      //         if (index > 0) {
      //             streetName += text + (index === splitAddress1.length - 1 ? '' : ' ');
      //         }
      //     });
      //     let prevNodeData;
      //     const fname = document.createElement('input');
      //     fname.type = 'hidden';
      //     fname.name = 'FName';
      //     fname.value = this.qualifyCustomer.get('fname');
      //     prevNodeData = document.forms['nrgCreditForm'].querySelector('input[name="FName"]');
      //     prevNodeData ? document.forms['nrgCreditForm'].replaceChild(fname, prevNodeData) :
      //         document.forms['nrgCreditForm'].appendChild(fname);
      //     const lname = document.createElement('input');
      //     lname.type = 'hidden';
      //     lname.name = 'LName';
      //     lname.value = this.qualifyCustomer.get('lname');
      //     prevNodeData = document.forms['nrgCreditForm'].querySelector('input[name="LName"]');
      //     prevNodeData ? document.forms['nrgCreditForm'].replaceChild(lname, prevNodeData) :
      //         document.forms['nrgCreditForm'].appendChild(lname);
      //     const streetNum = document.createElement('input');
      //     streetNum.type = 'hidden';
      //     streetNum.name = 'StreetNum';
      //     streetNum.value = splitAddress1[0];
      //     prevNodeData = document.forms['nrgCreditForm'].querySelector('input[name="StreetNum"]');
      //     prevNodeData ? document.forms['nrgCreditForm'].replaceChild(streetNum, prevNodeData) :
      //         document.forms['nrgCreditForm'].appendChild(streetNum);
      //     const streetNameInput = document.createElement('input');
      //     streetNameInput.type = 'hidden';
      //     streetNameInput.name = 'StreetName';
      //     streetNameInput.value = streetName;
      //     prevNodeData = document.forms['nrgCreditForm'].querySelector('input[name="StreetName"]');
      //     prevNodeData ? document.forms['nrgCreditForm'].replaceChild(streetNameInput, prevNodeData) :
      //         document.forms['nrgCreditForm'].appendChild(streetNameInput);
      //     const city = document.createElement('input');
      //     city.type = 'hidden';
      //     city.name = 'City';
      //     city.value = this.qualifyCustomer.get('city');
      //     prevNodeData = document.forms['nrgCreditForm'].querySelector('input[name="City"]');
      //     prevNodeData ? document.forms['nrgCreditForm'].replaceChild(city, prevNodeData) :
      //         document.forms['nrgCreditForm'].appendChild(city);
      //     const state = document.createElement('input');
      //     state.type = 'hidden';
      //     state.name = 'State';
      //     state.value = this.qualifyCustomer.get('state');
      //     prevNodeData = document.forms['nrgCreditForm'].querySelector('input[name="State"]');
      //     prevNodeData ? document.forms['nrgCreditForm'].replaceChild(state, prevNodeData) :
      //         document.forms['nrgCreditForm'].appendChild(state);
      //     const zip = document.createElement('input');
      //     zip.type = 'hidden';
      //     zip.name = 'ZipCode';
      //     zip.value = this.qualifyCustomer.get('zip').slice(0, 5);
      //     prevNodeData = document.forms['nrgCreditForm'].querySelector('input[name="ZipCode"]');
      //     prevNodeData ? document.forms['nrgCreditForm'].replaceChild(zip, prevNodeData) :
      //         document.forms['nrgCreditForm'].appendChild(zip);
      //     const phone = document.createElement('input');
      //     phone.type = 'hidden';
      //     phone.name = 'phNumber';
      //     phone.value = this.qualifyCustomer.get('primaryPhone');
      //     prevNodeData = document.forms['nrgCreditForm'].querySelector('input[name="phNumber"]');
      //     prevNodeData ? document.forms['nrgCreditForm'].replaceChild(phone, prevNodeData) :
      //         document.forms['nrgCreditForm'].appendChild(phone);
      //     const email = document.createElement('input');
      //     email.type = 'hidden';
      //     email.name = 'emailAdress';
      //     email.value = this.qualifyCustomer.get('email');
      //     prevNodeData = document.forms['nrgCreditForm'].querySelector('input[name="emailAdress"]');
      //     prevNodeData ? document.forms['nrgCreditForm'].replaceChild(email, prevNodeData) :
      //         document.forms['nrgCreditForm'].appendChild(email);
      //     const stId = document.createElement('input');
      //     stId.type = 'hidden';
      //     stId.name = 'straxLeadID';
      //     stId.value = this.qualifyCustomer.get('id');
      //     prevNodeData = document.forms['nrgCreditForm'].querySelector('input[name="straxLeadID"]');
      //     prevNodeData ? document.forms['nrgCreditForm'].replaceChild(stId, prevNodeData) :
      //         document.forms['nrgCreditForm'].appendChild(stId);
      //     const lang = document.createElement('input');
      //     lang.type = 'hidden';
      //     lang.name = 'prefLang';
      //     lang.value = this.qualifyCustomer.get('lang');
      //     prevNodeData = document.forms['nrgCreditForm'].querySelector('input[name="prefLang"]');
      //     prevNodeData ? document.forms['nrgCreditForm'].replaceChild(lang, prevNodeData) :
      //         document.forms['nrgCreditForm'].appendChild(lang);
      //     const aptNum = document.createElement('input');
      //     aptNum.type = 'hidden';
      //     aptNum.name = 'aptNum';
      //     aptNum.value = this.qualifyCustomer.get('address2');
      //     prevNodeData = document.forms['nrgCreditForm'].querySelector('input[name="aptNum"]');
      //     prevNodeData ? document.forms['nrgCreditForm'].replaceChild(aptNum, prevNodeData) :
      //         document.forms['nrgCreditForm'].appendChild(aptNum);
      //     const accountType = document.createElement('input');
      //     accountType.type = 'hidden';
      //     accountType.name = 'accountType';
      //     accountType.value = this.qualifyCustomer.get('accountType');
      //     prevNodeData = document.forms['nrgCreditForm'].querySelector('input[name="accountType"]');
      //     prevNodeData ? document.forms['nrgCreditForm'].replaceChild(accountType, prevNodeData) :
      //         document.forms['nrgCreditForm'].appendChild(accountType);
      //     if (this.qualifyCustomer.get('accountType') === 'Commercial') {
      //         const businessName = document.createElement('input');
      //         businessName.type = 'hidden';
      //         businessName.name = 'businessName';
      //         businessName.value = this.qualifyCustomer.get('businessName');
      //         prevNodeData = document.forms['nrgCreditForm'].querySelector('input[name="businessName"]');
      //         prevNodeData ? document.forms['nrgCreditForm'].replaceChild(businessName, prevNodeData) :
      //             document.forms['nrgCreditForm'].appendChild(businessName);
      //     }
      //     if (caNumber) {
      //         const caNumberInput = document.createElement('input');
      //         caNumberInput.type = 'hidden';
      //         caNumberInput.name = 'caNumber';
      //         caNumberInput.value = caNumber.value;
      //         prevNodeData = document.forms['nrgCreditForm'].querySelector('input[name="caNumber"]');
      //         prevNodeData ? document.forms['nrgCreditForm'].replaceChild(caNumberInput, prevNodeData) :
      //             document.forms['nrgCreditForm'].appendChild(caNumberInput);
      //     }
      //     document.nrgCreditForm.submit();
      // } else {
      this.set('creditMetaData', false);
      var multiStepForm = this.multiStepForm;
      var creditCheckConfig = Ember.Object.create({
        customerCreditCheck: {
          model: null,
          copyRelationMap: {
            creditBureau: {
              readOnly: true
            },
            manualBureau: {
              readOnly: true
            }
          }
        }
      });
      var checkBrinksReason = this.creditBureau && this.creditBureau.creditBureauType.get('id') === '7' && this.brinksData.run_credit_override;

      if (this.runCredit) {
        multiStepForm.formCopiedModelsSetVal('customerCreditCheck.model.score', null);
        multiStepForm.formCopiedModelsSetVal('customerCreditCheck.model.transactionId', null);
        multiStepForm.formCopiedModelsSetVal('customerCreditCheck.model.creditBureau', this.creditBureau);

        if (checkBrinksReason) {
          multiStepForm.formCopiedModelsSetVal('customerCreditCheck.model.overrideReason', this.brinksData.credit_override_reason);
        } else {
          this.brinksData.credit_override_reason = null;
        }
      } else {
        multiStepForm.formCopiedModelsSetVal('customerCreditCheck.model.manualBureau', this.manualBureau);
        multiStepForm.formCopiedModelsSetVal('customerCreditCheck.model.creditBureau', this.manualBureau);
      }

      var toCreateObjects = yield multiStepForm.validateCreateAll({
        deepCreate: false,
        deepValidate: false,
        overrideOriginalModelsConfig: creditCheckConfig,
        extraValidationData: {
          validationMessages: this.validationMessages,
          runCredit: this.runCredit,
          brinksOverride: checkBrinksReason
        }
      });

      try {
        if (this.runCredit) {
          var theCreditBureau = yield this.creditBureaus.findBy('id', toCreateObjects.customerCreditCheck.data.creditBureau.id);
          Ember.set(toCreateObjects.customerCreditCheck.data, 'creditBureau', theCreditBureau);
        }

        Ember.set(toCreateObjects.customerCreditCheck.data, 'customer', this.qualifyCustomer);
        var customerCreditCheck = this.store.createRecord('customerCreditCheck', toCreateObjects.customerCreditCheck.data);

        if (this.runCredit) {
          var addresses = this.previousAddresses;
          var pin = this.pin;
          var requestMeta = {
            pin
          }; // If Monitronics (Brinks)

          if (this.multiStepForm.formCopiedModels.customerCreditCheck.model.creditBureau.creditBureauType.get('id') === '7') {
            this.brinksData.run_credit_override ? this.brinksData.run_credit_override = 1 : this.brinksData.run_credit_override = 0;
            requestMeta['brinks_options'] = this.brinksData;
          }

          if (this.sepData && this.multiStepForm.formCopiedModels.customerCreditCheck.model.creditBureau.id === '23') {
            requestMeta['sep_options'] = this.sepData;
          }

          if (this.usePreviousAddresses && !Ember.isEmpty(addresses)) {
            requestMeta['addresses'] = addresses;
          }

          customerCreditCheck.set('requestMeta', requestMeta);
        }

        var newCreditCheck;

        try {
          newCreditCheck = yield customerCreditCheck.save();
        } catch (err) {
          customerCreditCheck.rollbackAttributes();
          throw err;
        }

        if (newCreditCheck && newCreditCheck.get('responseMeta')) {
          this.set('creditMetaData', newCreditCheck.get('responseMeta'));
        }

        if (this.grants.getIndividualGrant('customers.modify')) {
          var customer = this.customer;
          var resCustomerCreditChecks = yield customer.get('customerCreditChecks');

          if (this.grants.getIndividualGrant('customers.modify') && resCustomerCreditChecks.length == 1) {
            // only connect the new credit check as the current credit check if it's the only one
            customer.set('customerCreditCheck', customerCreditCheck);

            try {
              yield customer.save();
              buttonPromise.then(() => {
                this.setupForm();
              });
            } catch (e) {
              customer.rollbackAttributes();
              customerCreditCheck.rollbackAttributes();
            }
          } else {
            buttonPromise.then(() => {
              this.setupForm();
            });
          }
        }

        Ember.notifyPropertyChange(this, 'customerCreditChecks'); // }
      } catch (e) {
        var _this$modalNamespace3;

        (false && Ember.warn('saveCreditScore failed ' + e, {
          id: 'app.qualify_form.saveCreditScore'
        }));
        this.stNotificationManager.addErrorMessages(e, {
          notificationNamespace: (_this$modalNamespace3 = this.modalNamespace) !== null && _this$modalNamespace3 !== void 0 ? _this$modalNamespace3 : 'modal'
        });
        throw e;
      }
    }),
    updateCustomerCreditCheckTask: (0, _emberConcurrency.task)(function* (creditCheck) {
      var customer = yield this.customer;
      var unapply = yield creditCheck.get('isApplied');
      customer.set('customerCreditCheck', unapply ? null : creditCheck);
      yield customer.save();
      this.multiStepForm.setupCopyModels.perform();
    }),
    monitoringCompany: Ember.computed(function () {
      return this.getMonitoringCompany.perform();
    }),
    getMonitoringCompany: (0, _emberConcurrency.task)(function* () {
      var customerOrderInformation = this.customer.get('isLead') ? yield this.get('customer.leadInformation') : yield this.get('customer.customerOrderInformation');
      return yield customerOrderInformation.get('monitoringCompany');
    }),
    customerAdtIsConnected: Ember.computed(function () {
      return this.adtIsConnectedTask.unlinked().perform();
    }),
    adtIsConnectedTask: (0, _emberConcurrency.task)(function* () {
      try {
        var monitoringCompany = yield this.monitoringCompany;
        var monitoringCompanyType = yield monitoringCompany.get('monitoringCompanyType');
        return monitoringCompany.get('isConnected') && monitoringCompanyType && monitoringCompanyType.get('id') === '1';
      } catch (e) {// do nothing
      }

      return false;
    }),
    adtIsEnabled: Ember.computed(function () {
      return this.adtIsEnabledTask.unlinked().perform();
    }),
    adtIsEnabledTask: (0, _emberConcurrency.task)(function* () {
      try {
        var monitoringCompany = yield this.monitoringCompany;
        var monitoringCompanyConfig = yield monitoringCompany.get('monitoringCompanyConfig');

        if (monitoringCompanyConfig) {
          return monitoringCompanyConfig.enabled;
        }
      } catch (e) {// do nothing
      }

      return false;
    }),
    customerHasAdtConnectionTask: (0, _emberConcurrency.task)(function* () {
      var adtHashData = yield (0, _emberConcurrency.hash)({
        adtIsEnabled: this.adtIsEnabled,
        adtIsConnected: this.customerAdtIsConnected
      });
      return adtHashData.adtIsEnabled && adtHashData.adtIsConnected;
    }),
    actions: {
      updateAddressItem(address) {
        if (address === this.get('previousAddresses.lastObject')) {
          this.insertEmptyAddress();
        }
      },

      deleteAddress(address) {
        this.previousAddresses.removeObject(address);
      },

      submitAdtPreCheck(customer) {
        return customer.executeAdtPreCheck('modal');
      },

      openCreditWindow(row) {
        this.creditWindow.openWindow(row.id);
      },

      deleteCreditCheck(creditCheck) {
        creditCheck.destroyRecordWithRollbackTask.perform().then(() => {
          var _this$modalNamespace4;

          this.stNotificationManager.addSuccess(this.intl.t('pages.customers.credit_checks.creditRemoved'), (_this$modalNamespace4 = this.modalNamespace) !== null && _this$modalNamespace4 !== void 0 ? _this$modalNamespace4 : 'modal');
          Ember.notifyPropertyChange(this, 'customerCreditChecks');
          this.set('showDeleteId', false);
        });
      },

      refreshCreditScores() {
        this.qualifyCustomer.customerCreditChecks.reload();
        this.qualifyCustomer.reload();
        Ember.notifyPropertyChange(this, 'customerCreditChecks');
      }

    }
  });

  _exports.default = _default;
});