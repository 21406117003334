define("securitytrax-ember-ui/components/customer/dashboard/payables-summary-card/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var PayablesSummaryCard = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = (0, _emberConcurrency.task)(function* () {
    var progressBarWidth = 1;
    var payablesSummary = (yield this.args.customer.invokeAction('dashboard_cards/payable_summaries', {
      includeActionsString: false,
      verb: 'GET'
    })).responsePayload.data;
    var totalProgressBarWidth = payablesSummary.total_to_be_paid > payablesSummary.total_paid ? payablesSummary.total_to_be_paid : payablesSummary.total_paid;
    Object.keys(payablesSummary).forEach(key => {
      var value = payablesSummary[key];

      if (key.includes('_paid') && !key.includes('total') && Number(value) > progressBarWidth) {
        progressBarWidth = Number(value);
      }
    });
    payablesSummary['type_sales_to_be_paid_width'] = payablesSummary.type_sales_to_be_paid / progressBarWidth * 100 > 0 ? payablesSummary.type_sales_to_be_paid / progressBarWidth * 100 + '%' : '1px';
    payablesSummary['type_technician_to_be_paid_width'] = payablesSummary.type_technician_to_be_paid / progressBarWidth * 100 > 0 ? payablesSummary.type_technician_to_be_paid / progressBarWidth * 100 + '%' : '1px';
    payablesSummary['type_other_to_be_paid_width'] = payablesSummary.type_other_to_be_paid / progressBarWidth * 100 > 0 ? payablesSummary.type_other_to_be_paid / progressBarWidth * 100 + '%' : '1px';
    payablesSummary['total_to_be_paid_width'] = payablesSummary.total_to_be_paid / totalProgressBarWidth * 100 > 0 ? payablesSummary.total_to_be_paid / totalProgressBarWidth * 100 + '%' : '1px';
    payablesSummary['type_sales_paid_width'] = payablesSummary.type_sales_paid / progressBarWidth * 100 > 0 ? payablesSummary.type_sales_paid / progressBarWidth * 100 + '%' : '1px';
    payablesSummary['type_technician_paid_width'] = payablesSummary.type_technician_paid / progressBarWidth * 100 > 0 ? payablesSummary.type_technician_paid / progressBarWidth * 100 + '%' : '1px';
    payablesSummary['type_other_paid_width'] = payablesSummary.type_other_paid / progressBarWidth * 100 > 0 ? payablesSummary.type_other_paid / progressBarWidth * 100 + '%' : '1px';
    payablesSummary['total_paid_width'] = payablesSummary.total_paid / totalProgressBarWidth * 100 > 0 ? payablesSummary.total_paid / totalProgressBarWidth * 100 + '%' : '1px';
    payablesSummary['payment_status_approved_width'] = payablesSummary.total > 0 ? payablesSummary.payment_status_approved_count / payablesSummary.total * 100 : 0;
    payablesSummary['payment_status_denied_width'] = payablesSummary.total > 0 ? payablesSummary.payment_status_denied_count / payablesSummary.total * 100 : 0;
    payablesSummary['payment_status_paid_in_full_width'] = payablesSummary.total > 0 ? payablesSummary.payment_status_paid_in_full_count / payablesSummary.total * 100 : 0;
    payablesSummary['payment_status_partially_paid_width'] = payablesSummary.total > 0 ? payablesSummary.payment_status_partially_paid_count / payablesSummary.total * 100 : 0;
    payablesSummary['payment_status_pending_width'] = payablesSummary.total > 0 ? payablesSummary.payment_status_pending_count / payablesSummary.total * 100 : 0;
    payablesSummary['payment_status_requires_review_width'] = payablesSummary.total > 0 ? payablesSummary.payment_status_requires_review_count / payablesSummary.total * 100 : 0;
    this.payablesSummary = payablesSummary;
  }), (_class = (_temp = class PayablesSummaryCard extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "grants", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "payablesSummary", _descriptor4, this);

      _initializerDefineProperty(this, "payablesSummaryTask", _descriptor5, this);

      if (!this.args.useDefaultData) {
        this.payablesSummaryTask.perform();
      } else {
        this.payablesSummary = this.defaultData;
      }
    }

    get defaultData() {
      return {
        type_sales_paid_width: '50%',
        type_sales_to_be_paid_width: '100%',
        type_sales_paid: '1000',
        type_sales_to_be_paid: '2000',
        type_tecnician_paid_width: '56.25%%',
        type_technician_to_be_paid_width: '50%',
        type_technician_paid: '1125',
        type_tecnician_to_be_paid: '1000',
        type_other_paid_width: '25%',
        type_other_to_be_paid_width: '25%',
        type_other_paid: '500',
        type_other_to_be_paid: '500',
        total_paid_width: '75%',
        total_to_be_paid_width: '100%',
        total_paid: '2625',
        total_to_be_paid: '3500',
        payment_status_approved_width: '50',
        payment_status_denied_width: '0',
        payment_status_paid_in_full_width: '37.5',
        payment_status_partially_paid_width: '0',
        payment_status_pending_width: '12.5',
        payment_status_requires_review_width: '0',
        payment_status_approved_count: '4',
        payment_status_denied_count: '0',
        payment_status_paid_in_full_count: '3',
        payment_status_partially_paid_count: '0',
        payment_status_pending_count: '1',
        payment_status_requires_review_count: '0'
      };
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "payablesSummary", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "payablesSummaryTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PayablesSummaryCard;
});