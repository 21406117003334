define("securitytrax-ember-ui/utils/sort-compare", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sortCompareFunction = sortCompareFunction;

  /**
   * Utility helper module for sorting
   * @module
   */
  //import { warn } from '@ember/debug';

  /**
   * Sorting compare function
   *
   * attempt to cast value as a number instead of string
   * @param {a, b} 2 values to compare.
   *
   * @returns {int} 1=a, 0=same -1=b.
   */
  function sortCompareFunction(a, b) {
    var isNumber = function (n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    };

    if (a === null) {
      return 1;
    }

    if (b === null) {
      return -1;
    }

    if (isNumber(a) && isNumber(b)) {
      if (parseInt(a, 10) === parseInt(b, 10)) {
        return 0;
      }

      return parseInt(a, 10) > parseInt(b, 10) ? 1 : -1;
    }

    if (isNumber(a)) {
      return -1;
    }

    if (isNumber(b)) {
      return 1;
    }

    if (a === b) {
      return 0;
    }

    if ((0, _moment.default)(a).isValid() && (0, _moment.default)(b).isValid()) {
      return (0, _moment.default)(a).isAfter(b) ? 1 : -1;
    }

    return a > b ? 1 : -1;
  }
});