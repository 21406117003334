define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/electronic-document-providers/integrations/docusign/settings/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/electronic-document-providers/integrations/shared/base", "ember-concurrency"], function (_exports, _base, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var DocusignSettings = (_dec = Ember._tracked, _dec2 = (0, _emberConcurrency.task)(function* () {
    this.config = yield this.args.multiStepForm.formOriginalModelsGetVal('electronicDocumentProvider.model.electronicDocumentProviderConfig');

    if (!this.args.multiStepForm.formCopiedModels.electronicDocumentProvider.model.electronicDocumentProviderConfig.environment) {
      this.args.multiStepForm.formCopiedModels.electronicDocumentProvider.model.electronicDocumentProviderConfig.environment = 'development';
    }

    var provisionedAtJson = this.args.multiStepForm.formCopiedModelsGetVal('electronicDocumentProvider.model.electronicDocumentProviderConfig.provisionedAt');

    if (provisionedAtJson) {
      if (provisionedAtJson.development) {
        this.provisionedAtDevelopment = moment(provisionedAtJson.development, ['YYYY-MM-DD', 'YYYY/MM/DD']).format('MM/DD/YYYY');
      }

      if (provisionedAtJson.production) {
        this.provisionedAtProduction = moment(provisionedAtJson.production, ['YYYY-MM-DD', 'YYYY/MM/DD']).format('MM/DD/YYYY');
      }
    }

    if (this.router.currentRoute.queryParams.error) {
      this.oauthError = 'Unable to authorize SecurityTrax via OAuth<br><br>' + this.router.currentRoute.queryParams.error + '<br><br>' + this.router.currentRoute.queryParams.error_message;
    } else {
      this.args.multiStepForm.registerStepData('integration', 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.saveDocusignSettings.perform(this.args.multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary electronic-document-provider-save-button'
      }]);
    }

    if (this.router.currentRoute.queryParams.code && this.router.currentRoute.queryParams.state) {
      try {
        yield this.config.invokeAction('complete_oauth_linking_process', {
          verb: 'POST',
          data: {
            data: {
              attributes: {
                code: this.router.currentRoute.queryParams.code,
                state: this.router.currentRoute.queryParams.state
              }
            }
          }
        });
        yield this.args.multiStepForm.formOriginalModelsGetVal('electronicDocumentProvider.model.electronicDocumentProviderConfig').reload();
        yield this.args.multiStepForm.setupCopyModels.unlinked().perform();
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
      }
    }
  }), _dec3 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    var noValidate = ['serviceName', 'serviceEmail', 'accountId', 'apiAccountId', 'serviceUsername'];

    if (this.config.responseMeta.oauth_authorization_completed || this.config.responseMeta.attribute_has_saved_value.service_password && this.args.multiStepForm.formOriginalModelsGetVal('electronicDocumentProvider.model.electronicDocumentProviderConfig.serviceUsername')) {
      if (this.config.responseMeta.oauth_authorization_completed) {
        noValidate = ['serviceUsername'];
      } else {
        noValidate = [];
      }
    }

    var electronicDocumentProviderConfig = yield multiStepForm.formOriginalModelsGetVal('electronicDocumentProvider.model.electronicDocumentProviderConfig');
    yield this.saveEDocSettings.perform(multiStepForm, noValidate);

    if (this.config.responseMeta.oauth_authorization_completed || this.config.responseMeta.attribute_has_saved_value.service_password && this.args.multiStepForm.formOriginalModelsGetVal('electronicDocumentProvider.model.electronicDocumentProviderConfig.serviceUsername')) {
      electronicDocumentProviderConfig.invokeAction('validate_credentials', {
        verb: 'POST',
        data: {
          data: {
            type: 'docusign_settings_action'
          }
        }
      }).then(validateCreds => {
        if (validateCreds && validateCreds.responsePayload.data.code == 422) {
          this.stNotificationManager.addErrorMessages(validateCreds.responsePayload.data.message.failure_docusign_connection, {
            notificationNamespace: 'modal'
          });
        } else {
          buttonPromise.then(() => {
            this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
            this.stNotificationManager.addSuccessKey('pages.administration.integrations.electronic_document_providers.docusign.successful_connection', 'modal');
          });
        }
      }).catch(error => {
        (false && Ember.warn(error, {
          id: 'multiStepForm.forms.administration.integrations.docusign.settings.saveDocusignSettings'
        }));
        this.stNotificationManager.addErrorMessages(error, {
          notificationNamespace: 'modal'
        });
      });
    }
  }), _dec4 = (0, _emberConcurrency.task)(function* () {
    try {
      var oauthUrl = yield this.config.invokeAction('generate_docusign_oauth_url', {
        verb: 'POST',
        data: {
          data: {
            attributes: {
              redirect_uri: window.location.href
            }
          }
        }
      });
      window.open(oauthUrl.responsePayload.data.meta.docusign_oauth_url, '_self');
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), (_class = (_temp = class DocusignSettings extends _base.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "oauthError", _descriptor, this);

      _initializerDefineProperty(this, "initTask", _descriptor2, this);

      _initializerDefineProperty(this, "saveDocusignSettings", _descriptor3, this);

      _initializerDefineProperty(this, "linkViaOauth", _descriptor4, this);

      this.initTask.perform();
    }

    get environments() {
      return [{
        id: 'development',
        name: this.intl.t('pages.administration.integrations.electronic_document_providers.docusign.development')
      }, {
        id: 'production',
        name: this.intl.t('pages.administration.integrations.electronic_document_providers.docusign.production')
      }, {
        id: 'production_canada',
        name: this.intl.t('pages.administration.integrations.electronic_document_providers.docusign.production_canada')
      }];
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "oauthError", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "initTask", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "saveDocusignSettings", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "linkViaOauth", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = DocusignSettings;
});