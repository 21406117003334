define("securitytrax-ember-ui/users/base-route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-concurrency", "securitytrax-ember-ui/mixins/has-page-title", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _authenticatedRouteMixin, _emberConcurrency, _hasPageTitle, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _hasPageTitle.default, {
    intl: Ember.inject.service(),

    model() {
      return {
        user: this.modelFor('users')
      };
    },

    hasTechOrSalesRole: Ember.computed(function () {
      return this.hasTechOrSalesRoleTask.perform();
    }),
    hasTechOrSalesRoleTask: (0, _emberConcurrency.task)(function* () {
      var user = this.modelFor('users');
      var isSalesRep = yield user.isSalesRep;
      var isTech = yield user.isTech;
      return isSalesRep || isTech;
    }),
    determineRoleAndRedirectTask: (0, _emberConcurrency.task)(function* () {
      var hasTechOrSalesRole = yield this.hasTechOrSalesRole;
      this.generalPermissionsTask.perform(hasTechOrSalesRole, this.intl.t('common.notifications.not_sales_or_tech'));
    }).drop().maxConcurrency(1),

    getPageTitle(model) {
      return (0, _objHelpers.getObjProperties)(model.user, 'fname', 'lname').join(' ');
    },

    getRouteName() {
      var route = this.routeName.split('.').pop();
      return "pages.users.".concat(route.replace('-', '_'), ".title");
    }

  });

  _exports.default = _default;
});