define("securitytrax-ember-ui/models/base_models/payment-gateway-transaction", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    objectType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['customer', 'parententity']
    }),
    success: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    amount: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    transactionId: (0, _model.attr)('number-string', {
      'readOnly': false
    }),
    processingStatus: (0, _model.attr)('string', {
      'readOnly': false
    }),
    processingStatusUpdatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    authCode: (0, _model.attr)('string', {
      'readOnly': false
    }),
    saleType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['sale', 'credit', 'refund']
    }),
    externalSource: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['payment_portal', 'adc_partner_services']
    }),
    isRefunded: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    isVoided: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    meta: (0, _model.attr)('string', {
      'readOnly': false
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    customer: (0, _model.belongsTo)('customer', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    customerPayment: (0, _model.belongsTo)('customer-payment', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    customerBilling: (0, _model.belongsTo)('customer-billing', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    invoice: (0, _model.belongsTo)('customer-invoice', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});