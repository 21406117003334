define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/customer-create-chooser/create-form-configs/duplicate-customer/component", ["exports", "securitytrax-ember-ui/mixins/customer-create-chooser"], function (_exports, _customerCreateChooser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_customerCreateChooser.default, {
    init() {
      this._super(...arguments);

      Ember.set(this, 'newCustomer', null);
    },

    createCustomerFormSteps: Ember.computed('customer.id', 'grants.userGrants', function () {
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.intl.t('common.nav.create.customer.duplicate.title', {
            name: this.customer.get('flname')
          }),
          stepComponentPath: 'multi-step-form/forms-dynamic/forms/duplicate'
        })
      });
    })
  });

  _exports.default = _default;
});