define("securitytrax-ember-ui/components/pages/customers/customer-notes/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/customer-task-mixin", "securitytrax-ember-ui/mixins/table-column-sort", "securitytrax-ember-ui/utils/obj-helpers", "ember-inflector"], function (_exports, _emberConcurrency, _customerTaskMixin, _tableColumnSort, _objHelpers, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_customerTaskMixin.default, _tableColumnSort.default, {
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    stQueryParams: Ember.inject.service(),
    tagName: '',
    sortedTasksCount: 0,
    customerNoteDeleteId: null,
    noteDeleteTypeId: null,
    sortedCustomerTasks: undefined,
    addCompleted: true,
    groupedTasks: undefined,
    customerNotesList: undefined,
    groupedTasksReady: false,
    customerNotesReady: false,

    init() {
      this._super(...arguments);

      var intl = this.intl;
      (0, _objHelpers.initializeState)(this, {
        sortTaskOptions: [{
          name: intl.t('common.columns.ascending') + ' ' + intl.t('pages.customers.notes.priority'),
          value: 'priority',
          asc: false
        }, {
          name: intl.t('common.columns.descending') + ' ' + intl.t('pages.customers.notes.priority'),
          value: 'priority',
          asc: true
        }, {
          name: intl.t('common.columns.ascending') + ' ' + intl.t('common.columns.due_date'),
          value: 'dueDate',
          asc: true
        }, {
          name: intl.t('common.columns.descending') + ' ' + intl.t('common.columns.due_date'),
          value: 'dueDate',
          asc: false
        }, {
          name: intl.t('common.columns.ascending') + ' ' + intl.t('pages.customers.notes.assignedTo'),
          value: 'assignedUser.fname',
          asc: true
        }, {
          name: intl.t('common.columns.descending') + ' ' + intl.t('pages.customers.notes.assignedTo'),
          value: 'assignedUser.fname',
          asc: false
        }],
        sortNoteOptions: [{
          name: intl.t('common.columns.ascending') + ' ' + intl.t('common.columns.creation_date'),
          value: 'createdAt',
          asc: true
        }, {
          name: intl.t('common.columns.descending') + ' ' + intl.t('common.columns.creation_date'),
          value: 'createdAt',
          asc: false
        }, {
          name: intl.t('common.columns.ascending') + ' ' + intl.t('common.columns.created_by'),
          value: 'createdByUser.fname',
          asc: true
        }, {
          name: intl.t('common.columns.descending') + ' ' + intl.t('common.columns.created_by'),
          value: 'createdByUser.fname',
          asc: false
        }, {
          name: intl.t('common.columns.ascending') + ' ' + intl.t('pages.customers.notes.noteType.label'),
          value: 'noteType.name',
          asc: true
        }, {
          name: intl.t('common.columns.descending') + ' ' + intl.t('pages.customers.notes.noteType.label'),
          value: 'noteType.name',
          asc: false
        }]
      });
    },

    groupedTasksChangedObserver: Ember.observer('customer.customerTasks', 'customer.customerTasks.[]', 'customer.customerTasks.@each.{isClosed,priority,dueDate,assignedUser}', function () {
      return this.sortingTasksTask.perform(this.stSortCurrentSortColumn, this.stSortAscending);
    }),
    notesChangedObserver: Ember.observer('customer.customerNotes', 'customer.customerNotes.@each.{createdAt,noteType,subject}', function () {
      return this.sortCustomerNotesList.perform(this.stSortCurrentSortColumn, this.stSortAscending);
    }),
    sortingTasksTask: (0, _emberConcurrency.task)(function* (column = null, ascending = null) {
      var customer = yield this.customer;
      var customerTasks = customer && this.grants.getIndividualGrant('customer_tasks.view') ? yield this.stQueryParams.loadRelationshipWithQueryParams({
        model: customer,
        relName: 'customerTasks',
        useCallback: false,
        options: {
          params: {
            limit: 0
          }
        }
      }) : [];
      var sortedCustomerTasks;

      if (ascending === null) {
        //default sort
        sortedCustomerTasks = customerTasks.sortBy('priority').reverse();
      } else {
        //handle sorting
        sortedCustomerTasks = this.sortEmberDataCollection(customerTasks, column, ascending);
      }

      this.set('sortedCustomerTasks', sortedCustomerTasks);
      this.set('groupedTasks', yield this.groupedTasksTask.perform());
      this.set('groupedTasksReady', true);
    }).on('init').drop(),
    sortCustomerNotesList: (0, _emberConcurrency.task)(function* (column = null, ascending = null) {
      var notesList = [];
      var customer = yield this.customer;
      var customerNotes = customer && this.grants.getIndividualGrant('customer_notes.view') ? yield this.stQueryParams.loadRelationshipWithQueryParams({
        model: customer,
        relName: 'customerNotes',
        useCallback: false,
        options: {
          params: {
            limit: 0,
            include: 'note_type'
          }
        }
      }) : [];

      if (ascending === null) {
        notesList = customerNotes.sortBy('createdAt').reverse();
      } else {
        //handle sorting
        notesList = this.sortEmberDataCollection(customerNotes, column, ascending);
      }

      this.set('customerNotesList', notesList);
      this.set('customerNotesReady', true);
    }).on('init').drop(),
    sortAppointmentNotesList: (0, _emberConcurrency.task)(function* (column = null, ascending = null) {
      var notesList = [];
      var customer = yield this.customer;
      var calendarEvents = customer && this.grants.getIndividualGrant('calendar_events.view') ? yield customer.get('calendarEvents') : [];

      if (column === null) {
        this.sortList('startTime', false);
        notesList = this.sortEmberDataCollection(calendarEvents, 'startTime', false);
      } else {
        //handle sorting
        notesList = this.sortEmberDataCollection(calendarEvents, column, ascending);
      }

      Ember.set(this, 'appointmentNotesList', notesList.filter(appointment => {
        return appointment.get('calendarCategory') === 'tech_schedule';
      }));
    }).on('init').drop(),
    actions: {
      refreshModel(modelName) {
        this.get("customer.".concat((0, _emberInflector.pluralize)(modelName))).reload();
      },

      transitionToSubroute(modelType, path) {
        var parentPath = this.customer.get('recordType');
        this.router.transitionTo("".concat(parentPath, "s.notes.edit-create-") + modelType, path);
      },

      transitionToAppointment(path) {
        this.router.transitionTo("customers.appointments.edit-create-appointment", path);
      },

      //action sent to sortable-column component
      sortTasks(column, ascending) {
        this.sortList(column, ascending);
        this.sortingTasksTask.perform(column, ascending);
      },

      sortNotes(column, ascending) {
        this.sortList(column, ascending);
        this.sortCustomerNotesList.perform(column, ascending);
      },

      sortAppointmentNotes(column, ascending) {
        this.sortList(column, ascending);
        this.sortAppointmentNotesList.perform(column, ascending);
      },

      mobileSortTasks(sortColumn) {
        this.sortingTasksTask.perform(sortColumn.value, sortColumn.asc);
      },

      mobileSortNotes(sortColumn) {
        this.sortCustomerNotesList.perform(sortColumn.value, sortColumn.asc);
      },

      mobileSortAppointmentNotes(sortColumn) {
        this.sortAppointmentNotesList.perform(sortColumn.value, sortColumn.asc);
      }

    }
  });

  _exports.default = _default;
});