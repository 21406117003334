define("securitytrax-ember-ui/models/base_models/invoice", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    poNumber: (0, _model.attr)('string', {
      'readOnly': false
    }),
    entityType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['customer', 'lead_company']
    }),
    saleDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    dueDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    internalNote: (0, _model.attr)('string', {
      'readOnly': false
    }),
    externalNote: (0, _model.attr)('string', {
      'readOnly': false
    }),
    status: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['active', 'deleted', 'recurring']
    }),
    mailingAddress: (0, _model.attr)('string', {
      'readOnly': false
    }),
    mailingName: (0, _model.attr)('string', {
      'readOnly': false
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    invoiceType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['invoice', 'quote', 'refund_receipt', 'credit_memo']
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    invoiceTerm: (0, _model.belongsTo)('invoice-term', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    recurringInvoice: (0, _model.belongsTo)('recurring-invoice', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    soldByUser: (0, _model.belongsTo)('user', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    leadCompany: (0, _model.belongsTo)('lead-company', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    creditMemoPayment: (0, _model.belongsTo)('customer-payment', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    customer: (0, _model.belongsTo)('customer', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    tags: (0, _model.hasMany)('tag', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    invoiceLineItems: (0, _model.hasMany)('invoice-line-item', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'serialize': true,
      'stEmbedded': true
    }),
    customerPayments: (0, _model.hasMany)('customer-payment', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    customerInvoicePayments: (0, _model.hasMany)('customer-invoice-payment', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    invoiceEmails: (0, _model.hasMany)('invoice-email', {
      'readOnly': true,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});