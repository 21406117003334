define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/electronic-document-providers/integrations/docusign/edit-envelope/documents/add-document/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency"], function (_exports, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    stAjax: Ember.inject.service(),
    documentsReadOnly: true,
    template: null,
    documents: null,
    documentsLoaded: true,
    templateChangedObserver: Ember.observer('template', function () {
      this.setupDocumentsTask.perform();
    }),
    setupDocumentsTask: (0, _emberConcurrency.task)(function* () {
      this.set('documentsReadOnly', true);
      this.set('documentsLoaded', false);
      var template = this.template;
      var documents = template ? yield template.get('docusignDocuments') : [];
      this.set('documents', documents);
      this.set('documentsReadOnly', false);
      this.set('documentsLoaded', true);
    }),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      var buttonChoices = [{
        text: this.intl.t('common.actions.cancel'),
        classNamesString: 'st-btn st-btn close-button',
        click: () => {
          multiStepForm.resetForm(false);
          multiStepForm.formStartedSetVal(false);
        }
      }, {
        text: this.intl.t('common.actions.save'),
        classNamesString: 'st-btn st-btn-primary envelope-save-button',
        click: buttonPromise => {
          return this.addDocusignDocumentToEnvelope.perform(multiStepForm, buttonPromise);
        }
      }];
      multiStepForm.registerStepData('addDocusignDocument', 'buttons', buttonChoices);
    },

    addDocusignDocumentToEnvelope: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      try {
        var envelopeId = this.get('envelope.id'); //this.get('envelope.envelopeId');

        var documentId = this.get('document.id');
        var postObject = {
          method: 'POST',
          dataType: 'json',
          data: {
            data: {
              type: 'docusign_documents',
              id: documentId
            }
          }
        }; //const newDocument =

        yield this.stAjax.request(this.stAjax.getUrl("/docusign_envelopes/".concat(envelopeId, "/relationships/docusign_documents")), postObject).catch(errors => {
          errors['errors'] = errors && errors.payload ? errors.payload.errors : [];
          this.stNotificationManager.addErrorMessages(errors, {
            notificationNamespace: 'modal2'
          });
          throw errors;
        });
        buttonPromise.then(() => {
          multiStepForm.formStartedSetVal(false);
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');

          if (this.refreshDocumentsSection) {
            this.refreshDocumentsSection();
          }
        });
      } catch (error) {
        throw error;
      }
    })
  });

  _exports.default = _default;
});