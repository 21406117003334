define("securitytrax-ember-ui/components/multi-step-form/forms/cancel-account/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency"], function (_exports, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    confirmCancelReversalModalOpen: false,

    didInsertElement() {
      this._super(...arguments);

      this.buildButtonsTask.perform();
    },

    customerCancelAmount: Ember.computed(function () {
      return this.getCustomerCancelAmount.perform();
    }),
    getCustomerCancelAmount: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true);

      var _customerOrderInformation = yield this.store.find('customerOrderInformation', this.multiStepForm.formCopiedModels.customerOrderInformation.model.id);

      if (_customerOrderInformation && _customerOrderInformation.responseMeta) {
        return _customerOrderInformation.responseMeta.calculated_cancellation_fee_amount;
      } else {
        return null;
      }
    }),
    customerCancelReasons: Ember.computed(function () {
      return this.getCustomerCancelReasonsTask.perform();
    }),
    getCustomerCancelReasonsTask: (0, _emberConcurrency.task)(function* () {
      return yield this.store.findAll('customerCancelReason');
    }),
    buildButtonsTask: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = this.multiStepForm;
      yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);
      var intl = this.intl;
      var buttons = [];

      if (this.multiStepForm.formCopiedModelsGetVal('customerOrderInformation.model.customerIsCancelled')) {
        buttons.push({
          text: intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.cancelAccountTask.perform(multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary save'
        }, {
          text: intl.t('common.actions.reverseCancelation'),
          click: () => {
            this.set('confirmCancelReversalModalOpen', true);
          },
          isDelete: true,
          //To put the button on the left side of modal
          classNamesString: 'st-btn st-btn-secondary reverse-cancelation'
        });
      } else {
        buttons.push({
          text: intl.t('common.actions.confirmCancelation'),
          click: buttonPromise => {
            return this.cancelAccountTask.perform(multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary cancel-customer'
        });
      }

      multiStepForm.registerStepData('step1', 'buttons', buttons);
    }),
    reverseAccountCancelationTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      try {
        multiStepForm.formCopiedModelsSetVal('customerOrderInformation.model.customerIsCancelled', false);
        multiStepForm.formCopiedModelsSetVal('customerOrderInformation.model.customerCancelReason', null);
        multiStepForm.formCopiedModelsSetVal('customerOrderInformation.model.customerCancelDate', null);
        yield multiStepForm.validateSyncSaveAll({
          extraValidationData: {
            noValidate: ['orderInformation-salesRep', 'orderInformation-customerCancelDate']
          }
        });
        buttonPromise.then(() => {
          if (this.refreshModels) {
            this.refreshModels();
          }

          this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
          multiStepForm.formStartedSetVal(false);
        });
      } catch (err) {
        (false && Ember.warn('Reverse account cancel failed: ' + err, {
          id: 'customers.information.cancelAccount.reverse'
        }));
        throw err;
      }
    }),
    cancelAccountTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      try {
        multiStepForm.formCopiedModelsSetVal('customerOrderInformation.model.customerIsCancelled', true);
        yield multiStepForm.validateSyncSaveAll({
          extraValidationData: {
            noValidate: ['orderInformation-salesRep']
          }
        });
        buttonPromise.then(() => {
          if (this.refreshModels) {
            this.refreshModels();
          }

          this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
          multiStepForm.formStartedSetVal(false);
        });
      } catch (err) {
        (false && Ember.warn('Cancel account failed: ' + err, {
          id: 'customer.information.cancelAccount.cancel'
        }));
        throw err;
      }
    })
  });

  _exports.default = _default;
});