define("securitytrax-ember-ui/models/global-settings-docusign-setting", ["exports", "securitytrax-ember-ui/models/base_models/global-settings-docusign-setting"], function (_exports, _globalSettingsDocusignSetting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _globalSettingsDocusignSetting.default.extend({});

  _exports.default = _default;
});