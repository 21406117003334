define("securitytrax-ember-ui/components/lists/list-pagination/component", ["exports", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    intl: Ember.inject.service(),
    limit: undefined,
    listCount: undefined,
    // listData: undefined,
    name: undefined,
    page: undefined,
    showLimit: undefined,
    updateList: undefined,
    tempDealWithMasterList: undefined,
    // this is so can refactor later DREW TODO
    displayResultsText: true,
    pageLimitObserver: Ember.observer('limit', function () {
      this.updateList({
        page: this.page,
        limit: this.limit
      });
    }),
    pageCount: Ember.computed('listCount', 'limit', function () {
      var listCount = Number(this.listCount);
      var limit = Number(this.limit);
      return Math.ceil(listCount / limit);
    }),
    firstIndexOnPage: Ember.computed('page', 'limit', function () {
      return this.page * this.limit - this.limit + 1;
    }),
    lastIndexOnPage: Ember.computed('page', 'limit', 'listCount', function () {
      var lastIndex = this.page * this.limit;

      if (lastIndex > this.listCount) {
        return this.listCount;
      }

      return lastIndex;
    }),
    displayingResultsText: Ember.computed('firstIndexOnPage', 'lastIndexOnPage', 'listCount', function () {
      return this.intl.t('common.pagination.results_from_to', {
        firstIndexOnPage: this.firstIndexOnPage,
        lastIndexOnPage: this.lastIndexOnPage,
        listCount: this.listCount
      });
    }),
    showPages: Ember.computed('page', 'pageCount', function () {
      var currPage = this.page;
      var pageCount = this.pageCount;
      var pages = [{
        name: 1,
        //always show first page
        link: 1,
        class: currPage == 1 ? 'page-link current' : 'page-link'
      }]; //1 ... 5 6 (7) 8 9 ... 12

      if (currPage > 4) {
        pages.addObject({
          name: '...',
          link: currPage - 3
        });
      }

      for (var i = currPage - 2; i <= currPage + 2; i++) {
        if (i > 1 && i < pageCount) {
          pages.addObject({
            name: i,
            link: i,
            class: i == currPage ? 'page-link current' : 'page-link'
          });
        }
      }

      if (currPage < pageCount - 4) {
        pages.addObject({
          name: '...',
          link: currPage + 3
        });
      } //always show last page


      pages.addObject({
        name: pageCount,
        link: pageCount,
        class: currPage == pageCount ? 'page-link current' : 'page-link'
      });
      return pages;
    }),
    disableNext: Ember.computed('limit', 'listCount', 'page', function () {
      var showNext = true;
      var remainder = Number(this.listCount) - Number(this.page) * Number(this.limit);

      if (remainder > 0) {
        showNext = false;
      }

      return showNext;
    }),
    disablePrevious: Ember.computed('listCount', 'page', 'limit', function () {
      var showPrevious = true;

      if (this.page > 1 && this.listCount > 0) {
        showPrevious = false;
      }

      return showPrevious;
    }),

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        // listData: [],
        showLimit: false,
        page: 1,
        tempDealWithMasterList: true
      });

      if (typeof this.listCount === 'undefined') {
        throw new Error('You must pass in listCount to pagination');
      } // if (typeof this.listData === 'undefined') {
      //     throw new Error('You must pass in listData to pagination');
      // }


      if (typeof this.name === 'undefined') {
        throw new Error('You must pass in a name to pagination');
      }

      if (typeof this.updateList === 'undefined') {
        throw new Error('You must pass in an updateList function');
      }

      if (typeof this.page === 'undefined') {
        throw new Error('You must pass in an page value');
      }

      if (typeof this.limit === 'undefined') {
        throw new Error('You must pass in a limit value');
      } // const page = this.get('session.data.pagination.' + this.name + '.page');
      // const limit = this.get('session.data.pagination.' + this.name + '.limit');
      // if (typeof page !== 'undefined' && page !== null) {
      //     this.set('page', page);
      // }
      // if (typeof limit !== 'undefined' && limit !== null) {
      //     this.set('limit', limit);
      // }


      if (this.tempDealWithMasterList) {
        this.updateList({
          page: this.page,
          limit: this.limit
        });
      }
    },

    storeSessionData(data) {
      var name = this.name;

      if (typeof this.session.get('data.pagination') !== 'undefined') {
        if (typeof this.session.get('data.pagination.' + name) !== 'undefined') {
          data = Object.assign(this.get('session.data.pagination.' + name), data);
        }

        this.get('session.data.pagination').set(this.name, data);
      } else {
        var paginationData = [];
        paginationData[this.name] = data;
        this.session.set('data.pagination', paginationData);
      }
    },

    actions: {
      updatePage(direction, disabled) {
        if (!disabled) {
          var page = Number(this.page);

          if (direction === 'next') {
            page++; //+= Number(this.get('limit'));
          } else if (direction === 'previous') {
            page--; // -= Number(this.get('limit'));

            if (page < 1) {
              page = 1;
            }
          }

          this.set('page', page);
          this.storeSessionData({
            page: page
          });
          this.updateList({
            page: page,
            limit: this.limit,
            paginationPageUpdate: true
          }, true);
        }
      },

      setPage(pageNum) {
        this.set('page', pageNum);
        this.storeSessionData({
          page: pageNum
        });
        this.updateList({
          page: pageNum,
          limit: this.limit,
          paginationPageUpdate: true
        }, true);
      }

    }
  });

  _exports.default = _default;
});