define("securitytrax-ember-ui/administration/inventory/purchase-requisitions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cmF44roX",
    "block": "{\"symbols\":[],\"statements\":[[8,\"inventory/purchase-requisitions\",[],[[\"@admin\",\"@showAdd\",\"@pendingPurchaseOrders\",\"@showPendingPurchaseOrders\",\"@pendingPurchaseOrderSaved\"],[true,false,[34,0],[34,2],[34,3]]],null],[2,\"\\n\\n\"],[6,[37,4],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"inventory/pending-purchase-orders\",[],[[\"@pendingPurchaseOrders\",\"@allowEdit\",\"@emptyMessage\"],[[34,0],true,[30,[36,1],[\"pages.administration.company_settings.inventory.purchase_requisitions.no_purchase_order_generated\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"pendingPurchaseOrders\",\"t\",\"showPendingPurchaseOrders\",\"pendingPurchaseOrderSaved\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/administration/inventory/purchase-requisitions/template.hbs"
    }
  });

  _exports.default = _default;
});