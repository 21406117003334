define("securitytrax-ember-ui/demo/copy-model/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _authenticatedRouteMixin, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _routePageviewLogging.default, {
    model() {
      return Ember.RSVP.hash({
        customerEquipment: this.store.findRecord('customerEquipment', 1),
        customer: this.store.findRecord('customer', 1)
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      var map = {
        relationships: {
          customerContacts: {}
        }
      };
      model.customer.deepCopy({
        relationshipMap: map
      }).then(copy => {
        controller.set('copiedCustomerModel', copy);
      });
      model.customer.get('adtPreCheck').then(adtPreCheck => {
        return adtPreCheck;
      }, () => {
        return null;
      });
    }

  });

  _exports.default = _default;
});