define("securitytrax-ember-ui/demo/status-timeline/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ozyZVKOp",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],null,null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h1\"],[14,0,\"card-title\"],[12],[2,\"Status Timeline\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"status-timeline__wrapper\"],[12],[2,\"\\n        \"],[1,[30,[36,0],null,[[\"closed\",\"primaryText\",\"secondaryText\"],[true,\"Open\",\"1/20/19 by Smith, Dave\"]]]],[2,\"\\n        \"],[1,[30,[36,0],null,[[\"closed\",\"primaryText\",\"secondaryText\"],[true,\"Submitted\",\"1/23/19 by Smith, Dave\"]]]],[2,\"\\n        \"],[1,[30,[36,0],null,[[\"closed\",\"primaryText\"],[false,\"closed\"]]]],[2,\"\\n    \"],[13],[2,\"\\n   \\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"html/status-timeline-node\",\"pages/demo-page\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/demo/status-timeline/template.hbs"
    }
  });

  _exports.default = _default;
});