define("securitytrax-ember-ui/components/pages/users/user-permissions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "agr2wM2/",
    "block": "{\"symbols\":[\"multiStepForm\",\"multiStepForm\"],\"statements\":[[6,[37,4],null,[[\"formSteps\",\"formOriginalModels\",\"formStarted\"],[[35,3],[35,2],true]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],[[32,2,[\"currentStepData\",\"stepComponentPath\"]]],[[\"multiStepForm\",\"class\",\"user\",\"groupOptions\"],[[32,2],\"user-group-form\",[35,0,[\"user\"]],[35,0,[\"groupOptions\"]]]]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\n\"],[6,[37,4],null,[[\"formSteps\",\"formOriginalModels\",\"formStarted\"],[[35,6],[35,5],true]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],[[\"multiStepForm\",\"class\",\"user\",\"addEditGroup\",\"resourceTypes\"],[[32,1],\"user-permissions-form\",[35,0,[\"user\"]],[35,0,[\"user\",\"group\"]],[35,0,[\"resourceTypes\"]]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"component\",\"formOriginalModels\",\"groupPermissionsForm\",\"multi-step-form\",\"userPermissionsFormOriginalModels\",\"userPermissionsStep\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/users/user-permissions/template.hbs"
    }
  });

  _exports.default = _default;
});