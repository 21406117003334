define("securitytrax-ember-ui/components/pages/administration/human-resources/permissions/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var GroupsCard = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = (0, _emberConcurrency.task)(function* () {
    var queryParams = {
      sort: 'name',
      include: 'roles',
      limit: this.limit,
      page: this.page
    };

    if (this.page == 1) {
      queryParams['count'] = 1;
    }

    var groups = yield this.store.query('group', queryParams);
    this.groups = groups;

    if (this.page == 1) {
      this.groupListCount = groups.meta.record_count;
    }
  }).restartable().maxConcurrency(1), _dec14 = Ember._action, _dec15 = Ember._action, (_class = (_temp = class GroupsCard extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "grants", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "limit", _descriptor5, this);

      _initializerDefineProperty(this, "page", _descriptor6, this);

      _initializerDefineProperty(this, "groupListCount", _descriptor7, this);

      _initializerDefineProperty(this, "addGroup", _descriptor8, this);

      _initializerDefineProperty(this, "addGroupFormStarted", _descriptor9, this);

      _initializerDefineProperty(this, "addGroupStepName", _descriptor10, this);

      _initializerDefineProperty(this, "groupFormMode", _descriptor11, this);

      _initializerDefineProperty(this, "groups", _descriptor12, this);

      _initializerDefineProperty(this, "getGroupsTask", _descriptor13, this);

      this.getGroupsTask.perform();
    }

    get currentRoute() {
      var route = this.router;
      return route.currentRouteName;
    }

    get additionalClickActions() {
      var intl = this.intl;
      return [{
        label: intl.t('pages.administration.human_resources.new_group_scratch'),
        action: () => {
          this.addGroupStepName = 'addGroup';
          this.addGroup = null;
          this.groupFormMode = 'create';
          this.addGroupFormStarted = true;
          this.clickActionsOpen = false;
        }
      }, {
        label: intl.t('pages.administration.human_resources.new_group_existing'),
        action: () => {
          this.addGroupStepName = 'duplicateGroup';
          this.addGroup = null;
          this.groupFormMode = 'create';
          this.addGroupFormStarted = true;
        }
      }];
    }

    get addGroupSteps() {
      var intl = this.intl;
      return Ember.Object.create({
        addGroup: Ember.Object.create({
          stepNameKey: 'addGroup',
          title: intl.t('pages.administration.human_resources.new_permissions_group'),
          stepComponentPath: 'multi-step-form/forms/administration/permissions/add-groups'
        }),
        duplicateGroup: Ember.Object.create({
          stepNameKey: 'duplicateGroup',
          title: intl.t('pages.administration.human_resources.new_group_existing'),
          stepComponentPath: 'multi-step-form/forms/administration/permissions/add-groups'
        })
      });
    }

    get formOriginalModels() {
      return Ember.Object.create({
        group: {
          model: this.addGroup
        }
      });
    }

    transitionToGroup(group) {
      this.router.transitionTo('administration.human-resources.permissions.group-details', group);
    }

    getGroups(options) {
      if (options.isCreate || options.paginationPageUpdate) {
        this.getGroupsTask.perform();
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "limit", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 50;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "groupListCount", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "addGroup", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "addGroupFormStarted", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "addGroupStepName", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'addGroup';
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "groupFormMode", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'view';
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "groups", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "getGroupsTask", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "transitionToGroup", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToGroup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getGroups", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "getGroups"), _class.prototype)), _class));
  _exports.default = GroupsCard;
});