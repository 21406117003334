define("securitytrax-ember-ui/components/html/error/input-message/component", ["exports", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    extraClasses: '',
    showErrors: Ember.computed.notEmpty('errors'),
    tabindex: -1,
    //-1 to be able to focus() via script. set to 0 for user to tab to it

    /**
     * A list of error messages
     *
     * @type {Object[]}
     */
    errors: undefined,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        errors: []
      });
    }

  });

  _exports.default = _default;
});