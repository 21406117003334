define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/equipment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LUXypwC8",
    "block": "{\"symbols\":[\"data\",\"@multiStepForm\",\"@currFormName\",\"@isLS\"],\"statements\":[[8,\"multi-step-form/progress-bar\",[],[[\"@config\",\"@modalButtons\",\"@loadedNamespaces\"],[[32,2,[\"progressBarConfig\"]],[32,2,[\"currentStepData\",\"buttons\"]],[30,[36,0],[[32,3],\"step3\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"html/st-await\",[],[[\"@useSpinner\",\"@promises\",\"@loadedNamespace\",\"@size\"],[true,[30,[36,2],null,[[\"modelData\"],[[35,1]]]],[30,[36,0],[[35,3],\"step3\"],null],\"large\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[14,0,\"card customer-equipment-page\"],[12],[2,\"\\n            \"],[8,\"customer/information/customer-equipment\",[],[[\"@customer\",\"@model\",\"@st-card-class\",\"@isASG\",\"@isLS\"],[[34,4],[32,1,[\"resolvedPromises\",\"modelData\"]],\"asg\",true,[32,4]]],null],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"modelData\",\"hash\",\"currFormName\",\"newCustomer\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/equipment/template.hbs"
    }
  });

  _exports.default = _default;
});