define("securitytrax-ember-ui/models/pending-equipment-line-item", ["exports", "securitytrax-ember-ui/models/base_models/pending-equipment-line-item"], function (_exports, _pendingEquipmentLineItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _pendingEquipmentLineItem.default.extend({
    intl: Ember.inject.service(),
    inventorySinkNameDescription: Ember.computed('equipmentPurchaseOrder.pendingEquipmentType', 'id', function () {
      return 'PO ' + this.get('equipmentPurchaseOrder.id') + '.' + this.id + ' (' + this.intl.t('pages.administration.company_settings.inventory.pending_equipment.title') + ')';
    })
  });

  _exports.default = _default;
});