define("securitytrax-ember-ui/mixins/jquery-sort-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    init() {
      this._super(...arguments);

      this.set('jquery_ui_options', $.extend(true, this._jquery_ui_defaults, this.jquery_ui_options));
    },

    _jquery_ui_defaults: {},
    jquery_ui_options: {}
  });

  _exports.default = _default;
});