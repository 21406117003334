define("securitytrax-ember-ui/components/lead/leads-view/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionData: Ember.inject.service(),
    isAdtCompany: Ember.computed(function () {
      return this.get('sessionData.companyHasSpecificMonitorTask').perform();
    })
  });

  _exports.default = _default;
});