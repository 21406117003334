define("securitytrax-ember-ui/models/base_models/resource-type", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    name: (0, _model.attr)('string', {
      'readOnly': false
    }),
    description: (0, _model.attr)('string', {
      'readOnly': false
    }),
    computedGrant: (0, _model.attr)('string', {
      'readOnly': false
    }),
    sectionKey: (0, _model.attr)('string', {
      'readOnly': false
    }),
    bypassDefaultLimit: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    grantlessView: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    defaultDeny: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    defaultSortOrder: (0, _model.attr)('string', {
      'readOnly': false
    }),
    enabled: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    parentResourceType: (0, _model.belongsTo)('resource-type', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': 'childResourceTypes'
    }),
    resourceTypeGrants: (0, _model.hasMany)('resource-type-grant', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    resourceTypePolicies: (0, _model.hasMany)('resource-type-policy', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    childResourceTypes: (0, _model.hasMany)('resource-type', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': 'parentResourceType'
    }),
    userPolicyOverrides: (0, _model.hasMany)('user-policy-override', {
      'readOnly': true,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});