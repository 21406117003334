define("securitytrax-ember-ui/components/multi-step-form/forms/payables/bulk-create-transactions/component", ["exports", "@glimmer/component", "ember-concurrency", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step"], function (_exports, _component, _emberConcurrency, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var BulkCreatePayableTransactions = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var successNotifications = [];
    var errorNotifications = [];
    yield this.args.multiStepForm.validateList.perform([Ember.Object.create({
      modelName: 'customerPayablePayment',
      model: this.args.multiStepForm.formCopiedModelsGetVal('customerPayablePayment.model')
    })]); ///////////////////////////////////////////////////////////////////////////////
    // do payable multicall

    var multicallSubkeys = {};
    var waitForPayablesOutstandingAmounts = [];

    for (var index = 0; index < this.args.bulkEditList.length; index++) {
      var payable = this.args.bulkEditList.objectAt(index);
      waitForPayablesOutstandingAmounts.push(payable.get('outstandingAmount'));
      var multicallSubkey = "".concat(index, "_payable_bulk_create_transaction_subkey");
      multicallSubkeys[multicallSubkey] = true;
    }

    var allOutstandingAmounts = yield (0, _emberConcurrency.all)(waitForPayablesOutstandingAmounts); // this is so don't have to yield on payable.outstandingAmount in createPaymentTask below
    // this.stTimers.startTimer('payableBulk', 'start');

    var multicallKey = 'payable_bulk_create_transaction';
    yield this.stMulticallService.setupMulticall({
      multicallKey: multicallKey,
      multicallSubkeys: multicallSubkeys
    }, () => {
      for (var _index = 0; _index < this.args.bulkEditList.length; _index++) {
        var _payable = this.args.bulkEditList.objectAt(_index);

        var _multicallSubkey = "".concat(_index, "_payable_bulk_create_transaction_subkey");

        this.createPaymentMulticall(_payable, allOutstandingAmounts[_index], {
          multicallKey,
          multicallSubkey: _multicallSubkey
        }, successNotifications, errorNotifications);
      }
    }); // const allCreates = [];
    // for (let index = 0; index < this.args.bulkEditList.length; index++) {
    //     const payable = this.args.bulkEditList.objectAt(index);
    //     allCreates.push(this.createPaymentTask.perform(payable, successNotifications, errorNotifications));
    // }
    // yield all(allCreates);
    // this.stTimers.endTimer('payableBulk', 'end');

    buttonPromise.then(() => {
      this.args.afterBulkCreate();

      if (successNotifications.length > 0) {
        this.stNotificationManager.addSuccess(this.intl.t('common.notifications.bulk_save_successful', {
          'num': successNotifications.length
        }));
      }

      if (errorNotifications.length > 0) {
        this.stNotificationManager.addError(this.intl.t('common.notifications.bulk_generic_failure', {
          'num': errorNotifications.length
        }));
      }

      this.args.multiStepForm.formStartedSetVal(false);
    });
  }), (_class = (_temp = class BulkCreatePayableTransactions extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor3, this);

      _initializerDefineProperty(this, "stMulticallService", _descriptor4, this);

      _initializerDefineProperty(this, "stTimers", _descriptor5, this);

      _initializerDefineProperty(this, "reviewModalOpen", _descriptor6, this);

      _initializerDefineProperty(this, "bulkCreateTransactions", _descriptor7, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);

      if (this.args.formMode !== 'view') {
        this.args.multiStepForm.registerStepData('step1', 'buttons', [{
          text: this.intl.t('common.actions.save'),
          click: () => {
            this.reviewModalOpen = true;
          },
          classNamesString: 'st-btn st-btn-primary save-transactions'
        }]);
      }
    }

    // @(task(function *(payable, successNotifications, errorNotifications) {
    //     try {
    //         const payment = this.args.multiStepForm.formCopiedModelsGetVal('customerPayablePayment.model');
    //         const payablePayment = this.store.createRecord('customerPayablePayment', {
    //             customerPayable: payable,
    //             paymentDate: payment.get('paymentDate'),
    //             amount: yield payable.get('outstandingAmount'),
    //             notes: payment.get('notes'),
    //             reference: payment.get('reference')
    //         });
    //         yield payablePayment.save();
    //         successNotifications.push('Payable Saved');
    //     } catch (err) {
    //         payable.rollbackAttributes();
    //         errorNotifications.push('Payable Failed To Saved');
    //     }
    // })) createPaymentTask;
    createPaymentMulticall(payable, outstandingAmount, multicallOptions, successNotifications, errorNotifications) {
      try {
        var payment = this.args.multiStepForm.formCopiedModelsGetVal('customerPayablePayment.model');
        var payablePayment = this.store.createRecord('customerPayablePayment', {
          customerPayable: payable,
          paymentDate: payment.get('paymentDate'),
          amount: outstandingAmount,
          notes: payment.get('notes'),
          reference: payment.get('reference')
        });
        this.stMulticallService.registerRequest(multicallOptions, payablePayment.save({
          adapterOptions: {
            multicallOptions: multicallOptions
          }
        }));
        successNotifications.push('Payable Saved');
      } catch (err) {
        payable.rollbackAttributes();
        errorNotifications.push('Payable Failed To Saved');
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stMulticallService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "stTimers", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "reviewModalOpen", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "bulkCreateTransactions", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BulkCreatePayableTransactions;
});