define("securitytrax-ember-ui/validations/invoice-line-item", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      _validate.default.validators.positiveQuantity = function (value, options) {
        if (value <= 0) {
          return options.message;
        }

        return null;
      };

      _validate.default.validators.maxAmountCheck = function (value, options, key, attributes) {
        if (Number(attributes.totalAmount) > 99999.99) {
          return options.message;
        }

        return null;
      };

      var validateFieldsObject = {
        quantity: {
          presence: {
            allowEmpty: false
          },
          positiveQuantity: {
            message: function () {
              return '^' + data.intlService.t('pages.customers.accounting.invoices.quantityPositive');
            }
          }
        },
        invoiceBillableItem: {
          presence: {
            allowEmpty: false
          }
        },
        unitPrice: {
          presence: {
            allowEmpty: false
          },
          maxAmountCheck: {
            message: function () {
              return '^' + data.intlService.t('pages.customers.accounting.invoices.maxAmountWarning');
            }
          }
        }
      };

      if (this.type === 'invoice') {
        validateFieldsObject.invoiceTerm = {
          presence: {
            allowEmpty: false
          }
        };
      }

      var validationData = (0, _validate.default)(this.objectToJson(this), validateFieldsObject);
      var modelName = data.extraValidationData && data.extraValidationData.hasOwnProperty('overrideValidationKey') ? data.extraValidationData.overrideValidationKey : 'invoiceLineItem';
      return this.outputFormatting(validationData, modelName);
    }

  });

  _exports.default = _default;
});