define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/confirm-delete-customer/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/customer-base"], function (_exports, _customerBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerBase.default.extend({
    currentStep: '',
    customerId: null,
    customerName: null,
    customer: null,
    store: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      this.set('showTopRightCloseButton', false);
      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('confirmDeleteCustomer', 'buttons', []);
      multiStepForm.registerStepData('confirmDeleteCustomer', 'hideTopRightCloseButton', true);
      this.set('currentStep', multiStepForm.currentStepData.get('currentDeletingCustomer.currentStep'));
      this.set('customerId', multiStepForm.currentStepData.get('currentDeletingCustomer.customerId'));
      this.set('customerName', multiStepForm.currentStepData.get('currentDeletingCustomer.customerName'));
    },

    actions: {
      cancel() {
        this.set('formMode', 'create');
        this.set('showTopRightCloseButton', true);
        this.multiStepForm.currentStepNameKeySetVal(this.currentStep);
      },

      deleteCustomer(buttonPromise) {
        this.set('formMode', 'create');
        return this.deleteCustomer.perform(this.customerId, buttonPromise);
      }

    }
  });

  _exports.default = _default;
});