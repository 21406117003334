define("securitytrax-ember-ui/models/adc-list", ["exports", "securitytrax-ember-ui/models/base_models/adc-list"], function (_exports, _adcList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adcList.default.extend({});

  _exports.default = _default;
});