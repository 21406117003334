define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/edit-create-credit-bureau/hart/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    bureaus: null,
    productOptions: null,

    init() {
      this._super(...arguments);

      var options = [{
        name: 'CREDIT',
        value: 'CREDIT'
      }, {
        name: 'BISLRPT',
        value: 'BISLRPT'
      }, {
        name: 'SCORE REPORT',
        value: 'SCORE REPORT'
      }, {
        name: 'SOFTPULL',
        value: 'SOFTPULL'
      }];
      var bureaus = [{
        name: 'Experian',
        value: 'XPN'
      }, {
        name: 'TransUnion',
        value: 'TU'
      }, {
        name: 'Equifax',
        value: 'EFX'
      }];
      this.set('bureaus', bureaus);
      this.set('productOptions', options);
    }

  });

  _exports.default = _default;
});