define("securitytrax-ember-ui/administration/company-settings/integrations/partner-companies/customers/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o7PLGgkC",
    "block": "{\"symbols\":[],\"statements\":[[8,\"pages/administration/company-settings/integrations/partner-companies/customers\",[],[[\"@leadCompany\",\"@tags\",\"@identityDeliveryTypes\",\"@locations\",\"@messages\",\"@model\",\"@breadCrumbs\",\"@breadCrumbData\"],[[34,0,[\"leadCompany\"]],[34,0,[\"tags\"]],[34,0,[\"identityDeliveryTypes\"]],[34,0,[\"locations\"]],[34,0,[\"messages\"]],[34,0],[34,1],[34,2]]],null]],\"hasEval\":false,\"upvars\":[\"model\",\"breadCrumbs\",\"breadCrumbData\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/administration/company-settings/integrations/partner-companies/customers/template.hbs"
    }
  });

  _exports.default = _default;
});