define("securitytrax-ember-ui/components/pages/administration/company-settings/integrations/accounting-providers-sync/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oAcfXAmD",
    "block": "{\"symbols\":[\"multiStepForm\",\"DynamicForm\"],\"statements\":[[1,[30,[36,2],null,[[\"title\",\"breadCrumbs\",\"breadCrumbData\"],[[30,[36,1],[\"pages.administration.integrations.payment_processing.title\"],null],[32,0,[\"args\",\"breadCrumbs\"]],[32,0,[\"args\",\"breadCrumbData\"]]]]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"page-content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"scroll-page\"],[12],[2,\"\\n        \"],[8,\"multi-step-form\",[],[[\"@formSteps\",\"@formOriginalModels\",\"@formStarted\"],[[32,0,[\"accountingProvidersFormSteps\"]],[32,0,[\"accountingProviderFormOriginalModels\"]],true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[8,[32,2],[[24,0,\"edit-accounting-provider st-modal__body--progress-bar\"]],[[\"@multiStepForm\",\"@formMode\",\"@currAccountingProvider\",\"@setFormMode\"],[[32,1],[32,0,[\"accountingProviderFormMode\"]],[32,0,[\"currAccountingProvider\"]],[30,[36,0],[[32,0],\"setFormMode\"],null]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"        \"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"nav/top-nav-sub\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/administration/company-settings/integrations/accounting-providers-sync/template.hbs"
    }
  });

  _exports.default = _default;
});