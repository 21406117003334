define("securitytrax-ember-ui/calendar/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JM7VEfmr",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"calendar-glimmer\",[],[[\"@users\",\"@calendarEvents\",\"@calendarEventTypes\",\"@allowDragAppointment\",\"@allowResizeAppointment\",\"@allowAddAppointment\",\"@allowEventTooltip\",\"@userType\",\"@isPrimaryCal\",\"@isCompanyCalendar\",\"@model\"],[[32,1,[\"users\"]],[32,1,[\"calendarEvents\"]],[32,1,[\"calendarEventTypes\"]],true,true,false,true,\"tech_schedule\",true,true,[32,1]]],null],[2,\"\\n\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/calendar/index/template.hbs"
    }
  });

  _exports.default = _default;
});