define("securitytrax-ember-ui/components/multi-step-form/forms/work-orders/details/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/work-orders/work-orders-base", "securitytrax-ember-ui/mixins/switchable-form-step"], function (_exports, _workOrdersBase, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _workOrdersBase.default.extend(_switchableFormStep.default, {
    hiddenProgressBarSteps: Ember.computed(function () {
      return ['getStarted'];
    }),
    hideAddEvent: false,
    disabledProgressBarSteps: Ember.computed('formMode', function () {
      var disabledSteps = [];

      if (this.formMode === 'create') {
        disabledSteps = ['addAppointment', 'appointments', 'send', 'generatePOs'];
      }

      return disabledSteps;
    })
  });

  _exports.default = _default;
});