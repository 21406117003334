define("securitytrax-ember-ui/models/user-resource-type-policy", ["exports", "securitytrax-ember-ui/models/base_models/user-resource-type-policy"], function (_exports, _userResourceTypePolicy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _userResourceTypePolicy.default.extend({});

  _exports.default = _default;
});