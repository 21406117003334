define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/forms/adt/schedule/appointments-list/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/utils/obj-helpers", "securitytrax-ember-ui/mixins/table-column-sort", "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/customer-base"], function (_exports, _emberConcurrency, _objHelpers, _tableColumnSort, _customerBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerBase.default.extend(_tableColumnSort.default, {
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    modal: Ember.inject.service(),
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    tagName: '',
    modelsLoaded: false,
    showCheckAvailability: false,
    showAdd: false,
    workOrderView: false,

    init() {
      this._super(...arguments);

      var intl = this.intl;
      (0, _objHelpers.initializeState)(this, {
        sortAppointmentOptions: [{
          name: intl.t('common.columns.ascending') + ' ' + intl.t('common.columns.type'),
          value: 'calendarEventType.name',
          asc: true
        }, {
          name: intl.t('common.columns.descending') + ' ' + intl.t('common.columns.type'),
          value: 'calendarEventType.name',
          asc: false
        }, {
          name: intl.t('common.columns.ascending') + ' ' + intl.t('common.columns.name'),
          value: 'user.lname',
          asc: true
        }, {
          name: intl.t('common.columns.descending') + ' ' + intl.t('common.columns.name'),
          value: 'user.lname',
          asc: false
        }, {
          name: intl.t('common.columns.ascending') + ' ' + intl.t('common.columns.date'),
          value: 'startTime',
          asc: true
        }, {
          name: intl.t('common.columns.descending') + ' ' + intl.t('common.columns.date'),
          value: 'startTime',
          asc: false
        }, {
          name: intl.t('common.columns.ascending') + ' ' + intl.t('common.columns.date_added'),
          value: 'createdAt',
          asc: true
        }, {
          name: intl.t('common.columns.descending') + ' ' + intl.t('common.columns.date_added'),
          value: 'createdAt',
          asc: false
        }]
      });
    },

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('schedule::scheduleCalendarEvent', 'buttons', [{
        text: this.intl.t('common.actions.back'),
        classNamesString: 'st-btn st-btn-secondary',
        click: () => {
          return this.goBackTask.perform(multiStepForm);
        }
      }, {
        text: this.intl.t('common.actions.saveAndContinue'),
        classNamesString: 'st-btn st-btn-primary customer-information-save-button',
        positionOptions: 'up left',
        click: buttonPromise => {
          return this.saveCustomerTask.perform(multiStepForm, buttonPromise);
        },
        additionalClickActions: [{
          label: this.intl.t('common.actions.deleteCustomer'),
          action: () => {
            this.set('formMode', 'create');
            this.openConfirmDelete('schedule');
          },
          classNamesString: 'dropdown-delete-customer'
        }, {
          label: this.intl.t('common.actions.saveAndClose'),
          action: buttonPromise => {
            var close = true;
            return this.saveCustomerTask.perform(multiStepForm, buttonPromise, close);
          },
          classNamesString: 'dropdown-save-close'
        }]
      }]);
    },

    goBackTask: (0, _emberConcurrency.task)(function* (multiStepForm) {
      var workOrders = yield this.newCustomer.workOrders;

      if (workOrders.length == 0) {
        Ember.set(multiStepForm, 'currentStepNameKey', 'workOrder::createWorkOrder');
        multiStepForm.progressBarConfig.activeIndex--;
      } else {
        Ember.set(multiStepForm, 'currentStepNameKey', 'workOrder::listWorkOrders');
        multiStepForm.progressBarConfig.activeIndex--;
      }
    }),
    saveCustomerTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise, close = false) {
      yield;
      buttonPromise.then(() => {
        if (close) {
          this.set('formMode', 'create');
          this.closeForm(multiStepForm, multiStepForm.formOriginalModelsGetVal('customer.model'));
        } else {
          this.advanceForm(multiStepForm, 'nextStep', multiStepForm.progressBarConfig.activeIndex + 1);
        }
      });
    }),
    eventsChangedObserver: Ember.observer('newCustomer.calendarEvents.[]', function () {
      return this.sortCustomerCalendarEvents.perform(this.stSortCurrentSortColumn, this.stSortAscending);
    }),
    sortCustomerCalendarEvents: (0, _emberConcurrency.task)(function* (column = null, ascending = null) {
      var eventsList = [];
      var events = yield this.newCustomer.calendarEvents;

      if (ascending === null) {
        eventsList = events.sortBy('startTime');
      } else {
        //handle sorting
        eventsList = this.sortEmberDataCollection(events, column, ascending);
      }

      this.set('customerCalendarEvents', eventsList);
    }).on('init').drop(),
    actions: {
      sortAppointments(column, ascending) {
        this.sortList(column, ascending);
        this.sortCustomerCalendarEvents.perform(column, ascending);
      },

      mobileSortAppointments(sortColumn) {
        this.sortCustomerCalendarEvents.perform(sortColumn.value, sortColumn.asc);
      },

      goToStep(currentStepNameKey) {
        Ember.set(this.multiStepForm, 'currentStepNameKey', currentStepNameKey);
      }

    }
  });

  _exports.default = _default;
});