define("securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B1ahqLCg",
    "block": "{\"symbols\":[\"multiStepForm\",\"MultiStepFormComponent\",\"@customer\"],\"statements\":[[8,\"html/st-await\",[],[[\"@booleanLoaded\"],[[34,1,[\"isIdle\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"multi-step-form\",[],[[\"@formSteps\",\"@formOriginalModels\",\"@usesModal\",\"@currentStepNameKey\",\"@onAfterSave\",\"@formStarted\",\"@changeFormStarted\"],[[34,2],[34,3],true,\"step1\",[30,[36,4],[[32,0],\"transitionBackToParentRoute\"],null],[34,5],[30,[36,4],[[32,0],[30,[36,6],[[35,5]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"modals/st-paper\",[],[[\"@modalButtons\",\"@title\",\"@classNames\",\"@dialogOpen\",\"@onClose\",\"@multiStepForm\"],[[32,1,[\"currentStepData\",\"buttons\"]],[32,1,[\"currentStepData\",\"title\"]],\"st-modal--full st-modal--min-height-40\",[32,1,[\"formStarted\"]],[30,[36,4],[[32,0],\"transitionBackToParentRoute\"],null],[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[8,[32,2],[[24,0,\"create-customer-message\"]],[[\"@multiStepForm\",\"@formMode\",\"@customer\"],[[32,1],[34,0],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"customerMessageFormMode\",\"initData\",\"customerMessageFormSteps\",\"customerMessageFormOriginalModels\",\"action\",\"customerMessageFormStarted\",\"mut\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-message/template.hbs"
    }
  });

  _exports.default = _default;
});