define("securitytrax-ember-ui/models/customer-credit-check", ["exports", "securitytrax-ember-ui/models/base_models/customer-credit-check", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _customerCreditCheck, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Returns information about current credit check status.
   *
   * @returns {{msg: String, isPending: boolean}}
   *
   * @private
   * @instance
   */
  function checkStatus() {
    var score = this.score,
        isPending = score === 'pending';
    return {
      msg: isPending ? this.intl.t('common.status.pending') : score,
      isPending
    };
  }

  var _default = _customerCreditCheck.default.extend({
    displayScore: Ember.computed('score', function () {
      return checkStatus.call(this).msg;
    }),
    reportDate: Ember.computed('createdAt', 'score', function () {
      // Is request pending?
      var {
        isPending,
        msg
      } = checkStatus.call(this);

      if (isPending) {
        // Return status message.
        return msg;
      }

      var createdAt = this.createdAt;

      if (createdAt) {
        return this.getDate(createdAt, 'MM/DD/YYYY');
      }

      return createdAt;
    }),

    /**
     * Indicates this credit check is applied to it's customer.
     *
     * @function
     * @returns {boolean}
     */
    isApplied: Ember.computed('id', 'customer.customerCreditCheck.id', function () {
      var [id, tracking] = (0, _objHelpers.getObjProperties)(this, 'id', 'customer.customerCreditCheck.id');
      return id === tracking;
    }),

    /**
     * An icon representing the credit check report.
     *
     * @type {String}
     */
    icon: 'icn-file'
  });

  _exports.default = _default;
});