define("securitytrax-ember-ui/reports/my-reports/controller", ["exports", "securitytrax-ember-ui/reports/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import { computed } from '@ember/object';
  var Controller = _controller.default.extend({//TODO: get reports saved to user. 
  });

  var _default = Controller;
  _exports.default = _default;
});