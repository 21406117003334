define("securitytrax-ember-ui/components/customer/appointments/appointments-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y/XCn36z",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,11],[[35,10]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,9],null,[[\"class\"],[[35,8]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,7],null,[[\"calendarEvents\",\"showCheckAvailability\",\"showAdd\",\"workOrderView\",\"renderSortInPlace\",\"customer\",\"type\"],[[35,6],[35,5],[35,4],[35,3],[35,2],[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,7],null,[[\"calendarEvents\",\"showCheckAvailability\",\"showAdd\",\"workOrderView\",\"renderSortInPlace\",\"customer\",\"type\"],[[35,6],[35,5],[35,4],[35,3],[35,2],[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"type\",\"customer\",\"renderSortInPlace\",\"workOrderView\",\"showAdd\",\"showCheckAvailability\",\"calendarEvents\",\"customer/appointments/appointments-list/actual-list\",\"additionalCardClass\",\"html/st-card\",\"showCard\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/customer/appointments/appointments-list/template.hbs"
    }
  });

  _exports.default = _default;
});