define("securitytrax-ember-ui/models/base_models/equipment-ledger", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    sourceClass: (0, _model.attr)('number', {
      'readOnly': false
    }),
    sourceId: (0, _model.attr)('number', {
      'readOnly': false
    }),
    targetClass: (0, _model.attr)('number', {
      'readOnly': false
    }),
    targetId: (0, _model.attr)('number', {
      'readOnly': false
    }),
    cost: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    quantity: (0, _model.attr)('number', {
      'readOnly': false
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    status: (0, _model.attr)('string', {
      'readOnly': false
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    equipment: (0, _model.belongsTo)('equipment', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    targetUser: (0, _model.belongsTo)('user', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    sourceUser: (0, _model.belongsTo)('user', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    targetLocation: (0, _model.belongsTo)('location', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    sourceLocation: (0, _model.belongsTo)('location', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});