define("securitytrax-ember-ui/components/select-box/options", ["exports", "@zestia/ember-select-box/components/select-box/options/index"], function (_exports, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _index.default;
    }
  });
});