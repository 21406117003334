define("securitytrax-ember-ui/components/customer/files/edocs/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    enhancedFeatures: Ember.inject.service(),
    hasEdocFeature: Ember.computed(function () {
      return this.hasFeatureTask.perform();
    }),
    hasFeatureTask: (0, _emberConcurrency.task)(function* () {
      var isEnabled = yield this.enhancedFeatures.hasFeature('edoc');
      return isEnabled;
    })
  });

  _exports.default = _default;
});