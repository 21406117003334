define("securitytrax-ember-ui/components/pages/customers/customer-appointments/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    stQueryParams: Ember.inject.service(),
    calendarEventsService: Ember.inject.service('events/calendar-events'),
    salesAppointments: undefined,
    techAppointments: undefined,

    init() {
      this._super(...arguments);

      this.calendarEventsService.on('refresh-customer-calendar-events', this, '_refreshCalendarEvents');
      this.groupCustomerCalendarEventsTask.perform();
    },

    willDestroy() {
      this.calendarEventsService.off('refresh-customer-calendar-events', this, '_refreshCalendarEvents');
    },

    _refreshCalendarEvents() {
      this.groupCustomerCalendarEventsTask.perform();
    },

    groupCustomerCalendarEventsTask: (0, _emberConcurrency.task)(function* () {
      var appointments = yield this.stQueryParams.loadRelationshipWithQueryParams({
        model: this.customer,
        relName: 'calendarEvents',
        useCallback: false,
        options: {
          params: {
            limit: 0,
            include: 'calendar_user'
          }
        }
      });
      Ember.setProperties(this, {
        salesAppointments: appointments.filterBy('calendarCategory', 'sale_schedule'),
        techAppointments: appointments.filterBy('calendarCategory', 'tech_schedule')
      });
    })
  });

  _exports.default = _default;
});