define("securitytrax-ember-ui/components/pages/users/user-roles/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    techSettingsFormOriginalModels: Ember.computed('model.userTechSetting', function () {
      return Ember.Object.create({
        userTechSetting: {
          model: this.get('model.userTechSetting')
        }
      });
    }),
    techSettingsSteps: Ember.computed('grants.userGrants', function () {
      var intl = this.intl;
      var canViewPermissions = true; //this.get('grants').getIndividualGrant('users.manage_permissions');

      return Ember.Object.create({
        editTechSettings: Ember.Object.create({
          stepNameKey: 'editTechSettings',
          title: intl.t('pages.users.roles.edit_tech_settings'),
          stepComponentPath: 'multi-step-form/forms/administration/users/roles/tech-settings',
          granted: canViewPermissions
        })
      });
    }),
    formOriginalModels: Ember.computed('model.userTechSetting', function () {
      return Ember.Object.create({
        user: {
          model: this.get('model.user')
        }
      });
    }),
    repNumberSteps: Ember.computed('grants.userGrants', function () {
      var intl = this.intl;
      var canViewPermissions = true;
      return Ember.Object.create({
        editTechSettings: Ember.Object.create({
          stepNameKey: 'editRepNumbers',
          title: intl.t('pages.users.roles.rep_numbers'),
          stepComponentPath: 'multi-step-form/forms/administration/users/roles/rep-numbers',
          granted: canViewPermissions
        })
      });
    }),
    isTech: Ember.computed('user.roles', function () {
      return this.isTechTask.perform();
    }),
    isTechTask: (0, _emberConcurrency.task)(function* () {
      var user = yield this.user;
      var isTech = yield user.isTech;
      return isTech;
    }),
    isSalesRep: Ember.computed('user.roles', function () {
      return this.isSalesRepTask.perform();
    }),
    isSalesRepTask: (0, _emberConcurrency.task)(function* () {
      var user = yield this.user;
      var isSalesRep = yield user.isSalesRep;
      return isSalesRep;
    })
  });

  _exports.default = _default;
});