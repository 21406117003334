define("securitytrax-ember-ui/models/tech-schedule-assignment-run-log", ["exports", "securitytrax-ember-ui/models/base_models/tech-schedule-assignment-run-log"], function (_exports, _techScheduleAssignmentRunLog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _techScheduleAssignmentRunLog.default.extend({});

  _exports.default = _default;
});