define("securitytrax-ember-ui/components/calendar-glimmer/calendar-summary/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CalendarSummary = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember.inject.service('events/calendar-events'), _dec4 = Ember.inject.service('screen'), _dec5 = Ember.computed('calendarEvents.@each.calendarEventDetailType'), _dec6 = (0, _emberConcurrency.task)(function* () {
    var calEventsSummaryArray = [];
    var calendarEvents = null;
    var calendarEventsSummaryCount = 0;

    if (this.args.techToday) {
      calendarEvents = yield this.args.calendarEvents;
    } else {
      calendarEvents = yield this.args.calendarDate.calendarEvents;
    }

    if (calendarEvents) {
      for (var index = 0; index < calendarEvents.length; index++) {
        var event = calendarEvents.objectAt(index);

        if (yield event.calendarUser) {
          calendarEventsSummaryCount++;
          var eventType = JSON.stringify(yield event.calendarEventType.get('id'));
          var eventDetailType = yield event.calendarEventDetailType;

          if (calEventsSummaryArray[eventType] === undefined) {
            var calendarEventType = Ember.Object.create({
              calendarEventType: yield event.calendarEventType,
              count: 1,
              calendarEventDetailTypeList: [],
              showSubtypes: false
            });
            calEventsSummaryArray[eventType] = calendarEventType;
          } else {
            calEventsSummaryArray[eventType].count++;
          }

          if (eventDetailType) {
            var name = eventDetailType.name.replace(/\s/g, '');

            if (calEventsSummaryArray[eventType].calendarEventDetailTypeList[name] === undefined) {
              var calendarEventDetailType = Ember.Object.create({
                calendarEventDetailType: eventDetailType,
                count: 1
              });
              calEventsSummaryArray[eventType].calendarEventDetailTypeList[name] = calendarEventDetailType;
            } else {
              calEventsSummaryArray[eventType].calendarEventDetailTypeList[name].count++;
            }
          }
        }
      }
    }

    this.calEventsSummaryArray = calEventsSummaryArray;
    return calendarEventsSummaryCount;
  }), _dec7 = Ember._action, _dec8 = Ember._action, (_class = (_temp = class CalendarSummary extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "calEventsSummaryArray", _descriptor, this);

      _initializerDefineProperty(this, "calendarEvents", _descriptor2, this);

      _initializerDefineProperty(this, "CalendarEventsService", _descriptor3, this);

      _initializerDefineProperty(this, "screen", _descriptor4, this);

      _initializerDefineProperty(this, "calEventsArrayTask", _descriptor5, this);

      this.CalendarEventsService.on('refresh-page', this, '_refreshPageCallback');
      this.CalendarEventsService.on('events-loaded', this, '_eventsLoadedCallback');
    }

    willDestroy() {
      this.CalendarEventsService.off('refresh-page', this, '_refreshPageCallback');
      this.CalendarEventsService.off('events-loaded', this, '_eventsLoadedCallback');
    }

    _refreshPageCallback() {
      Ember.notifyPropertyChange(this, 'calendarEvents');
    }

    _eventsLoadedCallback(pageId) {
      if (pageId === this.args.calendarDate.id) {
        Ember.notifyPropertyChange(this, 'calendarEvents');
      }
    }

    get calEventsArray() {
      return this.calEventsArrayTask.perform();
    }

    showSummary() {
      if (typeof this.args.showSummaryAction === 'function') {
        this.args.showSummaryAction();
      }
    }

    toggleDisplay(node) {
      if (node.showSubtypes) {
        node.set('showSubtypes', false);
      } else {
        this.calEventsSummaryArray.forEach(node => {
          node.set('showSubtypes', false);
        });
        node.set('showSubtypes', true);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "calEventsSummaryArray", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "calendarEvents", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "CalendarEventsService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "screen", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "calEventsArray", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "calEventsArray"), _class.prototype), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "calEventsArrayTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showSummary", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "showSummary"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleDisplay", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "toggleDisplay"), _class.prototype)), _class));
  _exports.default = CalendarSummary;
});