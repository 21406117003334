define("securitytrax-ember-ui/models/base_models/customer-equipment-tag", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    name: (0, _model.attr)('string', {
      'readOnly': false
    }),
    objectType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['customer', 'user', 'customer_note', 'lead_company', 'lead_company_campaign', 'tech_schedule', 'sale_schedule', 'location', 'equipment', 'invoice', 'pending_equipment', 'payroll_to_customer', 'customer_equipment']
    }),
    objectContext: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['none', 'tech_schedule_install', 'tech_schedule_service', 'tech_schedule_removal', 'pending_equipment_po', 'pending_equipment_spillage', 'pending_equipment_rma', 'pending_equipment_pr', 'customer_note_work_order', 'customer_note_task', 'customer_note_note', 'invoice_invoice', 'invoice_quote', 'invoice_credit_memo', 'invoice_refund_receipt']
    }),
    description: (0, _model.attr)('string', {
      'readOnly': false
    }),
    color: (0, _model.attr)('string', {
      'readOnly': false
    }),
    isSystem: (0, _model.attr)('boolean', {
      'readOnly': true
    }),
    isProtected: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    active: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    status: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['active', 'deleted']
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    })
  });

  _exports.default = _default;
});