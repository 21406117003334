define("securitytrax-ember-ui/models/base_models/resource-type-policy", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    resourceTypeId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    name: (0, _model.attr)('string', {
      'readOnly': false
    }),
    policyKey: (0, _model.attr)('string', {
      'readOnly': false
    }),
    dataType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['COMPLEX', 'DATE', 'DATETIME', 'NUMERIC', 'PARENT', 'RELATION', 'STRING', 'TIMESTAMP', 'ENUM', 'FOREIGN_KEY']
    }),
    parentResourceTypeId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    enabled: (0, _model.attr)('boolean', {
      'readOnly': false
    })
  });

  _exports.default = _default;
});