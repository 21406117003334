define("securitytrax-ember-ui/components/modals/st-paper/component", ["exports", "jquery", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _jquery, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Dialog based on ember-paper dialog (google material design)
   */
  var _default = Ember.Component.extend(
  /** @lends components.modals.Dialog.prototype **/
  {
    loadedNamespace: undefined,
    stNotificationManager: Ember.inject.service(),

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        loadedNamespace: 'global'
      });
    },

    didInsertElement() {
      this._super(...arguments); // don't want this to fire for a closed modal on the page (modal-ception)


      if (this.dialogOpen) {
        this._setBodyClasses();
      }
    },

    didDestroyElement() {
      this._super(...arguments); // ensure dialogOpen is false when destroying modal


      this.set('dialogOpen', false);

      this._setBodyClasses();
    },

    // observer is a failsafe for modalception scenarios
    dialogOpenedObserver: Ember.observer('dialogOpen', function () {
      this._setBodyClasses();
    }),

    _setBodyClasses() {
      if (this.dialogOpen) {
        if ((0, _jquery.default)('body').hasClass('modal-open')) {
          (0, _jquery.default)('body').addClass('modal-ception');
        } else {
          (0, _jquery.default)(window).data('windowScrollTop', document.body.scrollTop > 0 ? document.body.scrollTop : document.documentElement.scrollTop);
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
          (0, _jquery.default)('html').addClass('modal-open');
          (0, _jquery.default)('body').addClass('modal-open');
        }
      } else {
        if ((0, _jquery.default)('body').hasClass('modal-ception')) {
          (0, _jquery.default)('body').removeClass('modal-ception');
        } else {
          (0, _jquery.default)('html').removeClass('modal-open');
          (0, _jquery.default)('body').removeClass('modal-open');
          var scrollBackto = (0, _jquery.default)(window).data('windowScrollTop');

          if (scrollBackto !== undefined) {
            document.body.scrollTop = scrollBackto;
            document.documentElement.scrollTop = scrollBackto;
          }

          (0, _jquery.default)(window).removeData('windowScrollTop');
        }
      }
    },

    modalNamespace: 'modal',
    fullscreen: false,
    hasProgressBar: false,
    modalButtons: undefined,
    classNames: undefined,
    noButtonsClass: Ember.computed('modalButtons', function () {
      if (this.modalButtons === undefined || this.modalButtons.length === 0) {
        return ' st-modal--no-footer';
      } else {
        return '';
      }
    }),
    footerTextClass: Ember.computed('footerText', function () {
      if (this.footerText === undefined || this.footerText === '') {
        return '';
      } else {
        return ' st-modal--hasFooterText';
      }
    }),
    hideTopRightCloseButton: false,
    hideTopRightCloseButtonComputed: Ember.computed('hideTopRightCloseButton', function () {
      if (typeof this.hideTopRightCloseButton == 'undefined') {
        return false;
      }

      return this.hideTopRightCloseButton;
    }),
    multiStepForm: null,
    //if in a wizard, pass multiStepForm, - only used for multistep form reset on close - not required
    _stepStarted: Ember.computed('multiStepForm._stepStarted', function () {
      var multiStepForm = this.multiStepForm;

      if (multiStepForm) {
        return multiStepForm._stepStarted;
      }

      return true;
    }),
    onClose: null,
    //optional onClose action to pass in
    title: '',
    footerText: undefined,
    actions: {
      closeDialog(result) {
        var multiStepForm = this.multiStepForm;

        if (multiStepForm) {
          multiStepForm.resetForm();
        }

        if (Ember.isEqual(result, 'save')) {//
        }

        if (this.onClose) {
          this.onClose.call(this);
        }

        this.set('dialogOpen', false);

        if (this.cancelDialogAction) {
          this.cancelDialogAction();
        }
      }

    }
  });

  _exports.default = _default;
});