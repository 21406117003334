define("securitytrax-ember-ui/components/multi-step-form/forms/administration/system/global-settings/edit-email-settings/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    stNotificationManager: Ember.inject.service('stNotificationManager'),
    intl: Ember.inject.service(),
    usStates: Ember.inject.service(),
    emailTechReminders: false,
    emailSalesReminders: false,

    init() {
      this._super(...arguments);
    },

    initFormData: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = this.multiStepForm;
      yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);

      if (multiStepForm.formCopiedModels.globalSetting.model.emailSalesScheduleAppointments && multiStepForm.formCopiedModels.globalSetting.model.salesScheduleTemplateMessage) {
        this.set('emailSalesReminders', true);
      }

      if (multiStepForm.formCopiedModels.globalSetting.model.emailTechScheduleAppointments && multiStepForm.formCopiedModels.globalSetting.model.techScheduleTemplateMessage) {
        this.set('emailTechReminders', true);
      }
    }).on('init'),
    sendWhenOptions: Ember.computed(function () {
      return [{
        name: 'Same Day',
        value: 1
      }, {
        name: 'Day Before',
        value: 2
      }, {
        name: '2 Days Before',
        value: 3
      }, {
        name: '3 Days Before',
        value: 4
      }];
    }),
    cancelTask: (0, _emberConcurrency.task)(function* () {
      try {
        var multiStepForm = this.multiStepForm;
        multiStepForm.formCopiedModels.globalSetting.model.emailSalesScheduleAppointments && multiStepForm.formCopiedModels.globalSetting.model.salesScheduleTemplateMessage ? this.set('emailSalesReminders', true) : this.set('emailSalesReminders', false);
        multiStepForm.formCopiedModels.globalSetting.model.emailSalesScheduleAppointments && multiStepForm.formCopiedModels.globalSetting.model.salesScheduleTemplateMessage ? this.set('emailTechReminders', true) : this.set('emailTechReminders', false);
        yield this.multiStepForm.resetForm(false);
        yield this.set('formMode', 'view');
        this.stNotificationManager.addSuccessKey('common.notifications.generic_cancel');
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err);
      }
    }).drop(),
    saveEmailSettingsTask: (0, _emberConcurrency.task)(function* () {
      var salesReminderNoValidation = !this.emailSalesReminders ? ['globalSetting-emailSalesScheduleAppointments', 'globalSetting-salesScheduleTemplateMessage', 'globalSetting-calendarEventSchedulerStartTime', 'globalSetting-calendarEventSchedulerDurationSeconds'] : ['company-name', 'globalSetting-calendarEventSchedulerStartTime', 'globalSetting-calendarEventSchedulerDurationSeconds'];
      var techReminderNoValidation = !this.emailTechReminders ? ['globalSetting-emailTechScheduleAppointments', 'globalSetting-techScheduleTemplateMessage', 'globalSetting-calendarEventSchedulerStartTime', 'globalSetting-calendarEventSchedulerDurationSeconds'] : ['globalSetting-calendarEventSchedulerStartTime', 'globalSetting-calendarEventSchedulerDurationSeconds'];
      var validationObject = {
        deepValidate: false,
        extraValidationData: {
          noValidate: salesReminderNoValidation.concat(techReminderNoValidation)
        }
      };

      if (!this.emailTechReminders) {
        this.multiStepForm.formCopiedModelsSetVal('globalSetting.model.emailTechScheduleAppointments', 0);
        this.multiStepForm.formCopiedModelsSetVal('globalSetting.model.techScheduleTemplateMessage', null);
      }

      if (!this.emailSalesReminders) {
        this.multiStepForm.formCopiedModelsSetVal('globalSetting.model.emailSalesScheduleAppointments', 0);
        this.multiStepForm.formCopiedModelsSetVal('globalSetting.model.salesScheduleTemplateMessage', null);
      }

      try {
        yield this.multiStepForm.validateSyncSaveAll(validationObject);
        this.set('formMode', 'view');
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
      } catch (err) {
        throw new Error(err);
      }
    }).restartable()
  });

  _exports.default = _default;
});