define("securitytrax-ember-ui/components/multi-step-form/forms/administration/inventory/equipment-transfer/create-transfer/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    inventoryService: Ember.inject.service(),
    stFiltersService: Ember.inject.service(),
    pendingEquipmentLineItems: null,
    lineItemsToDisplay: null,
    lineItemsToTransfer: null,
    recipients: null,
    selectedSourceRecipient: null,
    selectedTargetRecipient: null,
    formStarted: false,
    filterQuantity: false,
    equipmentName: null,
    formMode: 'view',
    totalQuantity: Ember.computed('lineItemsToDisplay.@each.quantity', function () {
      var total = 0;

      if (this.lineItemsToDisplay) {
        this.lineItemsToDisplay.forEach(item => {
          total += Number(item.get('quantity'));
        });
      }

      return total;
    }),
    formSteps: Ember.computed('formMode', 'selectedSourceRecipient', 'selectedTargetRecipient', function () {
      var intl = this.intl;
      var source = this.selectedSourceRecipient;
      var sourceName = source ? source.constructor.modelName.camelize() === 'location' ? source.get('name') : source.get('flname') : '';
      var target = this.selectedTargetRecipient;
      var targetName = target ? target.constructor.modelName.camelize() === 'location' ? target.get('name') : target.get('flname') : '';
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: intl.t("pages.administration.company_settings.inventory.equipment_transfer.review_title", {
            source: sourceName,
            target: targetName
          }),
          stepComponentPath: 'multi-step-form/forms/administration/inventory/equipment-transfer/review'
        })
      });
    }),
    totalQuantityObserver: Ember.observer('totalQuantity', function () {
      if (this.totalQuantity > 0) {
        this.registerButtons();
      } else {
        this.registerButtons(true);
      }
    }),
    selectedSourceOrTargetRecipientChangedObserver: Ember.observer('selectedSourceRecipient', 'selectedTargetRecipient', function () {
      var multiStepForm = this.multiStepForm;
      var translationKey = 'pages.administration.company_settings.inventory.equipment_transfer.create_title' + (this.get('selectedSourceRecipient.id') && this.get('selectedTargetRecipient.id') ? '_extended' : '');
      var titleData = {
        source: this.get('selectedSourceRecipient.flname') || this.get('selectedSourceRecipient.name'),
        target: this.get('selectedTargetRecipient.flname') || this.get('selectedTargetRecipient.name')
      };
      multiStepForm.registerStepData("step1", 'title', this.intl.t(translationKey, titleData));
    }),
    initFormData: (0, _emberConcurrency.task)(function* () {
      this.set('recipients', yield this.inventoryService.get('getInventoryRecipients').perform());
      this.registerButtons(true);
    }).on('init'),
    openReviewModal: (0, _emberConcurrency.task)(function* (multiStepForm) {
      var formattedObjects = [];
      var lineItemsToTransfer = [];
      formattedObjects.push(Ember.Object.create({
        model: {
          source: this.selectedSourceRecipient,
          target: this.selectedTargetRecipient
        },
        filePath: 'inventory/equipment-transfer'
      }));
      yield multiStepForm.validateList.perform(formattedObjects);
      this.lineItemsToDisplay.forEach(lineItem => {
        if (lineItem.quantity > 0) {
          lineItemsToTransfer.push(lineItem);
        }
      });
      this.set('lineItemsToTransfer', lineItemsToTransfer);
      this.set('formStarted', true);
    }),
    getOutboundTransferDataTask: (0, _emberConcurrency.task)(function* () {
      this.set('lineItemsToDisplay', null);

      try {
        if (this.selectedSourceRecipient && this.selectedTargetRecipient) {
          var lineItems = [];
          var hashedData = yield (0, _emberConcurrency.hash)({
            equipmentItems: this.equipment,
            company: this.sessionData.getCompany()
          });
          var data = yield hashedData.company.invokeAction('get_admin_equipment_transfer_data', {
            verb: 'GET',
            data: {
              target_recipient_id: this.get('selectedTargetRecipient.id'),
              target_recipient_type: this.selectedTargetRecipient.constructor.modelName === 'inventory-user' ? 'user' : 'location',
              source_recipient_id: this.get('selectedSourceRecipient.id'),
              source_recipient_type: this.selectedSourceRecipient.constructor.modelName === 'inventory-user' ? 'user' : 'location'
            }
          });
          var sourceValues = data.responsePayload.data.source;
          var targetValues = data.responsePayload.data.target;

          for (var i = 0; i < sourceValues.length; i++) {
            var equipmentItem = hashedData.equipmentItems.findBy('id', sourceValues[i].equipment_id);
            var lineItem = Ember.Object.create({
              equipment: equipmentItem,
              quantity: 0,
              transferable: +sourceValues[i].on_hand_quantity + (+sourceValues[i].on_transfer_quantity < 0 ? +sourceValues[i].on_transfer_quantity : 0) + (+sourceValues[i].pre_allocated_quantity < 0 ? +sourceValues[i].pre_allocated_quantity : 0),
              available: +targetValues[i].on_hand_quantity + (+targetValues[i].on_transfer_quantity < 0 ? +targetValues[i].on_transfer_quantity : 0) + (+targetValues[i].pre_allocated_quantity < 0 ? +targetValues[i].pre_allocated_quantity : 0),
              sourceOnHand: sourceValues[i].on_hand_quantity,
              sourceOnTransfer: sourceValues[i].on_transfer_quantity,
              sourcePreallocated: sourceValues[i].pre_allocated_quantity,
              sourceOnOrder: sourceValues[i].on_order_quantity,
              targetOnHand: targetValues[i].on_hand_quantity,
              targetOnTransfer: targetValues[i].on_transfer_quantity,
              targetPreallocated: targetValues[i].pre_allocated_quantity,
              targetOnOrder: targetValues[i].on_order_quantity
            });
            lineItems.push(lineItem);
          }

          this.set('pendingEquipmentLineItems', lineItems);
          this.set('lineItemsToDisplay', lineItems.filter(item => {
            return item.sourceOnHand > 0;
          }));
        }
      } catch (e) {
        this.stNotificationManager.addErrorMessages(e, {
          notificationNamespace: 'modal'
        });
        this.set('selectedTargetRecipient', null);
      }
    }),
    saveTransfersTask: (0, _emberConcurrency.task)(function* (buttonPromise, accept = false) {
      try {
        var transfers = [];
        var company = yield this.sessionData.getCompany();
        this.lineItemsToTransfer.forEach(lineItem => {
          transfers.push({
            equipment_id: lineItem.equipment.id,
            source_recipient_id: this.get('selectedSourceRecipient.id'),
            source_recipient_type: this.selectedSourceRecipient.constructor.modelName === 'inventory-user' ? 'user' : 'location',
            target_recipient_id: this.get('selectedTargetRecipient.id'),
            target_recipient_type: this.selectedTargetRecipient.constructor.modelName === 'inventory-user' ? 'user' : 'location',
            quantity: +lineItem.quantity
          });
        });
        var newTransfer = yield company.invokeAction('perform_admin_equipment_transfer', {
          verb: 'POST',
          data: {
            data: {
              attributes: {
                status: accept ? 'complete' : 'pending',
                transfer_data: transfers
              }
            }
          }
        });
        buttonPromise.then(() => {
          this.multiStepForm.formStartedSetVal(false);

          if (this.selectedSourceRecipient.constructor.modelName === 'inventory-user') {
            this.get('selectedSourceRecipient.user.sourceEquipmentTransfers').reload();
          } else {
            this.get('selectedSourceRecipient.sourceEquipmentTransfers').reload();
          }

          if (this.selectedTargetRecipient.constructor.modelName === 'inventory-user') {
            this.get('selectedTargetRecipient.user.targetEquipmentTransfers').reload();
          } else {
            this.get('selectedTargetRecipient.targetEquipmentTransfers').reload();
          }

          this.multiStepForm.onAfterSave();
        });
        return newTransfer;
      } catch (e) {
        this.stNotificationManager.addErrorMessages(e, {
          notificationNamespace: 'modal2'
        });
        throw e;
      }
    }),

    registerButtons(disabled = false) {
      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData("step1", 'buttons', [{
        text: this.intl.t('pages.users.my_inventory.physical_inventory.review'),
        click: () => {
          return this.openReviewModal.perform(multiStepForm);
        },
        disabled,
        classNamesString: 'st-btn st-btn-primary transfer-review-button'
      }]);
    },

    filter: (0, _emberConcurrency.task)(function* () {
      var newFilters = yield this.stFiltersService.getFiltersByGroupKeyAsObject('inventoryCreateTransfer');
      var lineItemsToDisplay = this.pendingEquipmentLineItems;

      if (newFilters['filterQuantity']) {
        lineItemsToDisplay = lineItemsToDisplay.filter(item => {
          return item.sourceOnHand > 0;
        });
      }

      if (newFilters['equipmentName']) {
        lineItemsToDisplay = lineItemsToDisplay.filter(item => {
          return item.equipment.name.toUpperCase().includes(newFilters['equipmentName'].toUpperCase());
        });
      }

      this.set('lineItemsToDisplay', lineItemsToDisplay);
    }),
    actions: {
      updateQuantityByMax(row) {
        if (isNaN(Number(row.quantity)) || row.quantity < 0) {
          row.set('quantity', 0);
        } else if (row.quantity > row.transferable) {
          row.set('quantity', row.transferable);
        }
      },

      clearFilter() {
        Ember.setProperties(this, {
          filterQuantity: true,
          equipmentName: null
        });
        this.set('lineItemsToDisplay', this.pendingEquipmentLineItems.filter(item => {
          return item.sourceOnHand > 0;
        }));
      }

    }
  });

  _exports.default = _default;
});