define("securitytrax-ember-ui/home/tech-today/route", ["exports", "securitytrax-ember-ui/home/base-route", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/scroll-to-top", "ember-concurrency", "moment"], function (_exports, _baseRoute, _authenticatedRouteMixin, _scrollToTop, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_authenticatedRouteMixin.default, _scrollToTop.default, {
    sessionData: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    user: undefined,
    queryParams: {
      date: {
        refreshModel: true
      },
      user_id: {
        refreshModel: true
      }
    },

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['calendar_events.view', 'customer_equipment.view'], 'all');
    },

    model(params) {
      return this.getModelData.perform(params);
    },

    getModelData: (0, _emberConcurrency.task)(function* (params) {
      var loggedInUser = yield this.modelFor('home').loggedInUser;
      var userId = params.user_id || loggedInUser.get('id');
      this.user = yield this.store.findRecord('user', userId);
      yield this.store.query('equipmentItem', {
        limit: 0,
        include: 'equipment_sub_type.equipment_type'
      });

      try {
        var startOfDay = params.date ? (0, _moment.default)(params.date).startOf('day') : (0, _moment.default)().startOf('day');
        var endOfDay = params.date ? (0, _moment.default)(params.date).endOf('day') : (0, _moment.default)().endOf('day');
        var calendarEventFilter = '(start_time >= \'' + startOfDay.format('YYYY-MM-DDTHH:mm:ss') + '\') and (start_time <= \'' + endOfDay.format('YYYY-MM-DDTHH:mm:ss') + '\') and (calendar_user.id = \'' + userId + '\') and (calendar_event_type.name != \'Time Off\') and (calendar_category = \'tech_schedule\')';
        return (0, _emberConcurrency.hash)({
          calendarEventFilter: calendarEventFilter,
          isManager: loggedInUser.get('id') !== userId,
          loggedInUser
        });
      } catch (e) {
        (false && Ember.warn(e, {
          id: 'app.index.getModelData'
        }));
      }

      return (0, _emberConcurrency.hash)({
        user: loggedInUser
      });
    }),

    setupBreadCrumbs() {
      var name = this.user.get('lfname');
      var subName = '';

      if (this.controller.model.loggedInUser.get('id') !== this.user.get('id')) {
        subName = " for <span class=\"breadcrumb--red\">".concat(name, "</span>");
      }

      var breadCrumbs = [{
        name: this.intl.t('pages.home.tech_today') + subName,
        previousLink: 'home.view',
        last: true
      }];
      var breadCrumbData = {
        titleLink: 'home.view',
        hide: false
      };
      this.homeController.set('breadCrumbs', breadCrumbs);
      this.homeController.set('breadCrumbData', breadCrumbData);
    },

    actions: {
      didTransition() {
        this.setupBreadCrumbs();
      }

    }
  });

  _exports.default = _default;
});