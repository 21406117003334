define("securitytrax-ember-ui/utils/garages", ["exports", "securitytrax-ember-ui/utils/doors", "securitytrax-ember-ui/enums/GarageDoorStatus", "securitytrax-ember-ui/utils/i18n"], function (_exports, _doors, _GarageDoorStatus, _i18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getGarageStateCSS = getGarageStateCSS;
  _exports.getGarageStateText = getGarageStateText;
  _exports.getGarageLabel = getGarageLabel;

  /**
   * Utility helper module for garage doors.
   * @module
   */

  /**
   * Returns the appropriate CSS class to show the current garage state.
   *
   * @param {number} state The state to show.
   * @param {boolean} [flipState=false] If true we should return the CSS for the opposite state (does not apply to "Unknown" state).
   *
   * @returns {String}
   */
  function getGarageStateCSS(state, flipState = false) {
    return (0, _doors.getDoorStateCSS)(state, [_GarageDoorStatus.Closed, _GarageDoorStatus.Open], ['closed', 'open'], flipState);
  }
  /**
   * Returns the indicated state in text form.
   *
   * @param {number} state The state to represent.
   *
   * @returns {String}
   */


  function getGarageStateText(state) {
    return (0, _i18n.getTranslation)("home.garage.".concat(getGarageStateCSS(state)));
  }
  /**
   * Creates and returns a garage label configuration object based on the passed device model.
   *
   * @param {models.devices.Garage} model The garage model.
   *
   * @returns {SimpleLabelType}
   * @static
   */


  function getGarageLabel(model) {
    var state = model.get('state');
    return (0, _doors.getStaticLabel)(model.get('description'), getGarageStateText(state), getGarageStateCSS(state));
  }
});