define("securitytrax-ember-ui/models/base_models/template-type-column", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    name: (0, _model.attr)('string', {
      'readOnly': false
    }),
    columnName: (0, _model.attr)('string', {
      'readOnly': false
    }),
    columnType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['static', 'static_enum', 'relation', 'computed']
    }),
    displayMethod: (0, _model.attr)('string', {
      'readOnly': false
    }),
    options: (0, _model.attr)('json', {
      'readOnly': false
    }),
    active: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    templateType: (0, _model.belongsTo)('template-type', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    resourceType: (0, _model.belongsTo)('resource-type', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    templateFieldDefaults: (0, _model.hasMany)('template-field-default', {
      'readOnly': true,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});