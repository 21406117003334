define("securitytrax-ember-ui/components/multi-step-form/forms/demo/demo-progress-bar/step-2/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/demo/demo-progress-bar/demo-progress-bar-base"], function (_exports, _demoProgressBarBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _demoProgressBarBase.default.extend({
    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('step2', 'buttons', [{
        text: 'Previous',
        click: function () {
          multiStepForm.currentStepNameKeySetVal('step1');
          multiStepForm.progressBarConfig.activeIndex = 0;
        },
        classNamesString: 'st-btn st-btn-secondary'
      }, {
        text: 'Skip',
        click: function () {
          var item = multiStepForm.progressBarConfig.items.objectAt(1);
          var skippedItem = multiStepForm.progressBarConfig.items.objectAt(2);
          multiStepForm.currentStepNameKeySetVal('step4');
          multiStepForm.progressBarConfig.activeIndex = 3;
          Ember.set(item, 'isComplete', true);
          Ember.set(skippedItem, 'isComplete', true);
        },
        classNamesString: 'st-btn st-btn-secondary skip'
      }, {
        text: 'Next',
        click: function () {
          var item = multiStepForm.progressBarConfig.items.objectAt(1);
          multiStepForm.currentStepNameKeySetVal('step3');
          multiStepForm.progressBarConfig.activeIndex = 2;
          Ember.set(item, 'isComplete', true);
        },
        classNamesString: 'st-btn st-btn-primary'
      }]);
      multiStepForm.modalOpen = true;
    }

  });

  _exports.default = _default;
});