define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/monitoring-companies/account-number-blocks/edit/component", ["exports", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "ember-concurrency"], function (_exports, _component, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EditAccountNumberBlock = (_dec = Ember.inject.service('intl'), _dec2 = Ember.inject.service('store'), _dec3 = Ember.inject.service('stNotificationManager'), _dec4 = (0, _emberConcurrency.task)(function* () {
    var multiStepForm = this.args.multiStepForm;
    yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);
    multiStepForm.formCopiedModelsSetVal('accountNumberBlock.model.monitoringCompany', this.args.monitoringCompany);

    if (this.SwitchableFormStep.isNewRecord) {
      multiStepForm.formCopiedModelsSetVal('accountNumberBlock.model.active', true);
    }
  }), _dec5 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    if (this.SwitchableFormStep.isNewRecord) {
      var toCreateObjects = yield multiStepForm.validateCreateAll();
      var accountNumberBlock = this.store.createRecord('accountNumberBlock', toCreateObjects.accountNumberBlock.data);
      yield toCreateObjects.accountNumberBlock.saveWithRollbackAndNotification(accountNumberBlock, 'modal2');
    } else {
      yield multiStepForm.validateSyncSaveAll({
        notificationNamespace: 'modal2'
      });
    }

    buttonPromise.then(() => {
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
      multiStepForm.formStartedSetVal(false);
    });
  }), (_class = (_temp = class EditAccountNumberBlock extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor3, this);

      _defineProperty(this, "overrideFormMode", 'create');

      _initializerDefineProperty(this, "initFormData", _descriptor4, this);

      _initializerDefineProperty(this, "saveAccountNumberBlock", _descriptor5, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.initFormData.perform();
      Ember.run.later(() => {
        this.buttons();
      });
    }

    get flagOptions() {
      return [{
        name: this.intl.t('pages.administration.integrations.monitoringCompanies.accountNumberBlocks.service_two_way_voice_true'),
        value: 'service_two_way_voice_true'
      }, {
        name: this.intl.t('pages.administration.integrations.monitoringCompanies.accountNumberBlocks.service_two_way_voice_false'),
        value: 'service_two_way_voice_false'
      }];
    }

    buttons() {
      var multiStepForm = this.args.multiStepForm;
      multiStepForm.registerStepData("step1", 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.saveAccountNumberBlock.perform(multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary account-number-blocks-save-button'
      }]);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "initFormData", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "saveAccountNumberBlock", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = EditAccountNumberBlock;
});