define("securitytrax-ember-ui/components/multi-step-form/forms/administration/permissions/edit-policies/component", ["exports", "securitytrax-ember-ui/mixins/forms/resource-type-policies-reusable", "securitytrax-ember-ui/mixins/switchable-form-step", "lodash", "ember-concurrency"], function (_exports, _resourceTypePoliciesReusable, _switchableFormStep, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, _resourceTypePoliciesReusable.default, {
    intl: Ember.inject.service(),
    permissionsService: Ember.inject.service(),
    screen: Ember.inject.service(),
    modelName: 'group',
    resourceTypePolicyName: 'groupResourceTypePolicy',
    isMobile: Ember.computed('screen.isMobile', function () {
      return this.get('screen.isMobile');
    }),
    resourceTypePoliciesTranslated: Ember.computed('resourceTypePolicies', function () {
      var resourceTypePolicies = this.resourceTypePolicies;
      return resourceTypePolicies.map(item => {
        if ('nameTranslated' in item) {
          // Already has the translated name
          return item;
        }

        item['nameTranslated'] = this.intl.t('policies.' + item.get('policyKey') + '.name');
        return item;
      });
    }),
    groupResourceTypePolicies: Ember.computed('group', function () {
      return this.get('permissionsService.getGroupPolicyList').perform(this.group);
    }),
    policiesList: Ember.computed('resourceTypePolicies', function () {
      return this.getPoliciesList.perform();
    }),
    getPoliciesList: (0, _emberConcurrency.task)(function* () {
      var allPromises = [];
      var hashedData = yield (0, _emberConcurrency.hash)({
        groupResourceTypePolicies: this.groupResourceTypePolicies,
        resourceType: this.currentResource
      });
      var filteredGroupResourceTypePolicies = hashedData.groupResourceTypePolicies.filter(function (policy) {
        return policy.get('deletedAt') == null && policy.get('resourceTypePolicy.resourceTypeId') == hashedData.resourceType.id;
      });
      filteredGroupResourceTypePolicies.forEach(item => {
        allPromises.push(this.setupPolicyOptions.perform(item));
      });
      yield (0, _emberConcurrency.all)(allPromises);
      this.set('listArray', filteredGroupResourceTypePolicies);
      this.emptyListItem();
      return filteredGroupResourceTypePolicies;
    }),
    setupPolicyOptions: (0, _emberConcurrency.task)(function* (item) {
      var resourceTypePolicyMeta = item.get('resourceTypePolicy.responseMeta');
      item.set('deletingClass', '');
      item.set('operatorsList', this.setupOperatorsList(_lodash.default.get(resourceTypePolicyMeta, 'allowed_operators', [])));
      var possibleOptions = resourceTypePolicyMeta.has_selection_list ? yield this.getSelectionListTask.perform(yield item.get('resourceTypePolicy')) : []; // PERF TODO: Just yield in getSelectionListTask?

      possibleOptions.forEach(option => {
        if (option.translation_key) {
          Ember.set(option, 'name', this.intl.t('policy_selections.' + option.translation_key + '.name'));
        }
      });
      item.set('conditionRightOptionsList', possibleOptions);

      if (item.get('operator') == 'IN') {
        var conditionRightValue = item.get('conditionRight').value;
        var selectedOptions = possibleOptions.filter(option => {
          return _lodash.default.includes(conditionRightValue, option.value);
        });
        item.set('multipleSelected', selectedOptions);
      }
    })
  });

  _exports.default = _default;
});