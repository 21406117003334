define("securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-notes/notes/component", ["exports", "securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-notes/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Controller = _baseComponent.default.extend({
    customerNote: null,
    customer: null,
    grants: Ember.inject.service(),
    initData: (0, _emberConcurrency.task)(function* () {
      if (this.noteId !== 'create') {
        this.set('customerNote', yield this.store.findRecord('customerNote', this.noteId));
        this.set('customer', this.customer ? this.customer : this.get('customerNote.customer'));
      }

      this.set('formStarted', true);
    }).on('init'),
    customerNoteFormMode: Ember.computed('customerNote', function () {
      if (this.grants.getIndividualGrant('customer_notes.create') && !this.customerNote) {
        return 'create';
      } else if (this.grants.getIndividualGrant('customer_notes.modify') && this.customerNote) {
        return 'edit';
      } else {
        return 'view';
      }
    }),
    customerNoteFormSteps: Ember.computed('customerNote', function () {
      var intl = this.intl;
      var customerNote = this.customerNote;
      return Ember.Object.create({
        customerNoteSingleStep: Ember.Object.create({
          stepNameKey: 'customerNoteSingleStep',
          title: customerNote ? intl.t('pages.customers.notes.edit', {
            id: customerNote.id,
            name: this.customer.get('flname')
          }) : intl.t('pages.customers.notes.add', {
            name: this.customer.get('flname')
          }),
          stepComponentPath: 'multi-step-form/forms/notes/notes'
        })
      });
    }),
    customerNoteFormOriginalModels: Ember.computed('customerNote', function () {
      var grants = this.grants;
      var isCreate = this.customerNote == null;
      return Ember.Object.create({
        customerNote: {
          model: this.customerNote,
          copyRelationMap: {
            noteType: {
              doSave: false
            },
            createdByUser: {
              doCopy: true,
              doValidate: false,
              doSave: false
            },
            customerCommunications: {
              doCopy: grants.getIndividualGrant('customer_communications.view'),
              doValidate: grants.getIndividualGrant('customer_communications.view'),
              doSave: isCreate ? grants.getIndividualGrant('customer_communications.create') : grants.getIndividualGrant('customer_communications.modify')
            }
          }
        }
      });
    }),
    actions: {
      refreshModel() {
        this.get("customer.customerNotes").reload();
      }

    }
  });

  var _default = Controller;
  _exports.default = _default;
});