define("securitytrax-ember-ui/models/customer-order-information", ["exports", "securitytrax-ember-ui/models/base_models/customer-order-information"], function (_exports, _customerOrderInformation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerOrderInformation.default.extend({
    /** BELONGS TO **/
  });

  _exports.default = _default;
});