define("securitytrax-ember-ui/components/pages/my-account/roles/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kRFD6LH6",
    "block": "{\"symbols\":[\"data\",\"multiStepForm\",\"MultiStepFormComponent\",\"@user\"],\"statements\":[[8,\"html/st-await\",[],[[\"@useSpinner\",\"@promises\"],[true,[30,[36,5],null,[[\"isSalesRep\"],[[35,4]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,6],[[32,1,[\"resolvedPromises\",\"isSalesRep\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"multi-step-form\",[],[[\"@formSteps\",\"@formOriginalModels\",\"@formStarted\"],[[34,0],[34,1],true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,2,[\"currentStepData\",\"stepComponentPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[8,[32,3],[[24,0,\"user-rep-numbers-form\"]],[[\"@multiStepForm\",\"@user\"],[[32,2],[32,4]]],null],[2,\"\\n\"]],\"parameters\":[3]}]]],[2,\"        \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"repNumberSteps\",\"formOriginalModels\",\"component\",\"let\",\"isSalesRep\",\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/my-account/roles/template.hbs"
    }
  });

  _exports.default = _default;
});