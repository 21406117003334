define("securitytrax-ember-ui/components/html/notifications/global-message/component", ["exports", "ember-concurrency", "lodash"], function (_exports, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionData: Ember.inject.service(),
    stModelWatcher: Ember.inject.service(),
    pubCompany: null,
    displayCompanyMessage: false,

    init() {
      this._super(...arguments);

      this.companyMessagesTask.perform();
      this.stModelWatcher.registerModelWatcher('company', 'update', 'watch-global-messages', this.watchGlobalMessagesTask, this, '_updateGlobalMessages');
      this.stModelWatcher.on('reload-global-messages', this, '_reloadGlobalMessages');
    },

    willDestroy() {
      this.stModelWatcher.deregisterModelWatcher('company', 'update', 'watch-global-messages', this, '_updateGlobalMessages');
      this.stModelWatcher.off('reload-global-messages', this, '_reloadGlobalMessages');
    },

    watchGlobalMessagesTask: (0, _emberConcurrency.task)(function* (modelsList) {
      var pubCompany = yield this.pubCompany;
      var approvedList = [];

      if (pubCompany) {
        for (var index = 0; index < modelsList.length; index++) {
          var currCompany = modelsList.objectAt(index);

          if (currCompany.globalMessage != pubCompany.company_global_message) {
            approvedList.push(currCompany);
          }
        }
      }

      return approvedList;
    }),

    _updateGlobalMessages(data) {
      this.companyMessagesTask.perform(_lodash.default.get(data, 'approvedModelsList[0].globalMessage', null));
    },

    _reloadGlobalMessages(globalMessage) {
      this.companyMessagesTask.perform(globalMessage);
    },

    companyMessagesTask: (0, _emberConcurrency.task)(function* (overrideMessage) {
      if (this.pubCompany == null) {
        this.pubCompany = this.sessionData.getPublicCompanyInfo();
      }

      var pubCompany = yield this.pubCompany;

      if (overrideMessage !== undefined) {
        // NOTE: null will work because null wipes out the value as opposed to undefined
        pubCompany.company_global_message = overrideMessage;
      }

      Ember.set(this, 'displayCompanyMessage', pubCompany.company_global_message !== null);
    }),
    actions: {
      closeDialog() {
        this.displayCompanyMessage = false;
        Ember.notifyPropertyChange(this, 'displayCompanyMessage');
      }

    }
  });

  _exports.default = _default;
});