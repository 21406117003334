define("securitytrax-ember-ui/demo/date-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sggDOEEN",
    "block": "{\"symbols\":[\"multiStepForm\",\"currForm\"],\"statements\":[[6,[37,5],null,null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"multi-step-form\",[],[[\"@formSteps\",\"@formOriginalModels\",\"@formStarted\"],[[34,1],[34,2],true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,[32,2],[],[[\"@multiStepForm\",\"@model\"],[[32,1],[34,0]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"demoForm\",\"demoFormOriginalModels\",\"component\",\"let\",\"pages/demo-page\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/demo/date-picker/template.hbs"
    }
  });

  _exports.default = _default;
});