define("securitytrax-ember-ui/all-work-orders/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/has-page-title", "securitytrax-ember-ui/mixins/route-pageview-logging", "securitytrax-ember-ui/mixins/scroll-to-top"], function (_exports, _authenticatedRouteMixin, _hasPageTitle, _routePageviewLogging, _scrollToTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _hasPageTitle.default, _routePageviewLogging.default, _scrollToTop.default, {
    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['work_orders.view']);
    },

    getPageTitle() {
      return '';
    },

    getRouteName() {
      return "common.nav.allWorkOrders";
    }

  });

  _exports.default = _default;
});