define("securitytrax-ember-ui/components/multi-step-form/forms/leads/details/component", ["exports", "securitytrax-ember-ui/utils/obj-helpers", "ember-concurrency", "securitytrax-ember-ui/mixins/forms/customer-lead-shared"], function (_exports, _objHelpers, _emberConcurrency, _customerLeadShared) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_customerLeadShared.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    leadStatusEvents: Ember.inject.service('events/lead-status-events'),
    router: Ember.inject.service(),
    transitionEvent: Ember.inject.service('events/transitionEvent'),
    dynamicFormChooser: Ember.inject.service('events/dynamic-form-chooser'),
    addLeadInteractionFormStarted: undefined,
    entity: undefined,
    changeLeadStatusFormStarted: undefined,
    convertToCustomerFormStarted: undefined,
    currentLeadContact: undefined,
    leadContactsUpdateProperty: undefined,
    hasConvertOptions: undefined,
    convertAdditionalActions: undefined,

    init() {
      this._super(...arguments);

      this.transitionEvent.on('refresh-lead-interactions', this, '_refreshHistoryList');
      (0, _objHelpers.initializeState)(this, {
        addLeadInteractionFormStarted: false,
        changeLeadStatusFormStarted: false,
        convertToCustomerFormStarted: false,
        currentLeadContact: null,
        leadContactsUpdateProperty: null,
        entity: 'lead'
      });
      this.initTask.perform();
    },

    initTask: (0, _emberConcurrency.task)(function* () {
      var completedWorkFlows = [];
      var workFlows = yield this.store.findAll('workflowConfig', {
        include: 'workflow'
      });
      var activeWorkFlows = workFlows.filter(workFlow => {
        return workFlow.get('active');
      });

      for (var i = 0; i < activeWorkFlows.length; i++) {
        var activeWorkflow = activeWorkFlows.objectAt(i).get('workflow');
        var config = activeWorkFlows.objectAt(i).get('config');

        if (config && config.lead_to_customer) {
          completedWorkFlows.push({
            packageName: activeWorkFlows.objectAt(i).get('name'),
            packageId: activeWorkflow.get('id'),
            packageContent: [{
              name: activeWorkFlows.objectAt(i).get('description')
            }]
          });
        }
      }

      if (completedWorkFlows.length > 0) {
        Ember.set(this, 'currentCreateId', completedWorkFlows[0].packageId);
        Ember.set(this, 'hasConvertOptions', true);
        Ember.set(this, 'workflowOptions', completedWorkFlows);
        Ember.set(this, 'convertAdditionalActions', [{
          label: this.intl.t('pages.leads.convert_with_workflow'),
          action: () => {
            Ember.set(this, 'showWorkflows', true);
            this.send('openConvertLeadForm');
          },
          classNamesString: ''
        }]);
      }

      return completedWorkFlows;
    }),

    willDestroy() {
      this.transitionEvent.off('refresh-lead-interactions', this, '_refreshHistoryList');
    },

    _refreshHistoryList() {
      this.notifyPropertyChange('leadContactsUpdateProperty');
      this.notifyPropertyChange('addInteractionFormModels');
    },

    addInteractionFormSteps: Ember.computed(function () {
      return Ember.Object.create({
        interaction: Ember.Object.create({
          stepNameKey: 'interaction',
          title: this.intl.t('pages.leads.add_interaction'),
          stepComponentPath: 'multi-step-form/forms/leads/add-interaction'
        })
      });
    }),
    changeStatusFormSteps: Ember.computed(function () {
      return Ember.Object.create({
        changeStatus: Ember.Object.create({
          stepNameKey: 'changeStatus',
          title: this.intl.t('pages.leads.change_lead_status'),
          stepComponentPath: 'multi-step-form/forms/leads/change-status'
        })
      });
    }),
    convertToCustomerFormSteps: Ember.computed(function () {
      return Ember.Object.create({
        convert: Ember.Object.create({
          stepNameKey: 'convert',
          title: this.intl.t('pages.leads.convert_to_customer_name', {
            name: this.multiStepForm.formOriginalModelsGetVal('customer.model.flname')
          }),
          stepComponentPath: 'multi-step-form/forms/leads/convert-customer'
        })
      });
    }),
    progressBar: Ember.computed('convertToCustomerFormSteps', function () {
      var items = [];
      var formSteps = this.convertToCustomerFormSteps;
      Object.keys(formSteps).forEach(function (key) {
        items.push({
          title: formSteps[key].progressBarTitle,
          stepNameKey: formSteps[key].stepNameKey,
          isClickable: false,
          hidden: formSteps[key].hidden,
          isComplete: formSteps[key].isComplete
        });
      });
      return {
        activeIndex: 0,
        viewTemplate: 'default-view',
        items: items,
        hideProgressBar: true
      };
    }),
    addInteractionFormModels: Ember.computed('currentLeadContact', 'grants.userGrants', function () {
      return Ember.Object.create({
        leadContact: {
          model: this.currentLeadContact ? this.currentLeadContact : null
        }
      });
    }),
    leadContacts: Ember.computed('leadContactsUpdateProperty', function () {
      return this.leadContactsTask.perform();
    }),
    leadContactsTask: (0, _emberConcurrency.task)(function* () {
      var hasGrants = yield this.grants.get('getIndividualGrantAsync').perform('lead_contacts.view');

      if (hasGrants) {
        return yield this.store.query('leadContact', {
          filter: 'customer=' + this.multiStepForm.formOriginalModels.customer.model.id,
          sort: '-id',
          include: 'user'
        });
      }

      return [];
    }),
    cancelTask: (0, _emberConcurrency.task)(function* (buttonPromise) {
      var multiStepForm = this.multiStepForm;
      yield multiStepForm.setupCopyModels.perform();
      buttonPromise.then(() => {
        this.set('formMode', 'view');
        this.stNotificationManager.addSuccessKey('common.status.canceled');
      });
    }),
    saveDetailsTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);
      yield multiStepForm.validateSyncSaveAll({
        extraValidationData: {
          noValidate: ['location', 'saleDate', 'customer-zip', 'customer-address1', 'leadInformation-monitoringCompany']
        }
      });
      buttonPromise.then(() => {
        this.leadStatusEvents.trigger('redoModels');
        this.set('formMode', 'view');
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
      });
    }),
    actions: {
      saveDetails(buttonPromise) {
        var multiStepForm = this.multiStepForm;
        return this.saveDetailsTask.perform(multiStepForm, buttonPromise);
      },

      updateHistoryList() {
        this._refreshHistoryList();
      },

      transitionToSubroute(path) {
        this.router.transitionTo('leads.information.edit-create-interaction', path);
      },

      openChangeStatusForm() {
        Ember.set(this, 'changeLeadStatusFormStarted', true);
      },

      openConvertLeadForm() {
        Ember.set(this, 'convertToCustomerFormStarted', true);
      }

    }
  });

  _exports.default = _default;
});