define("securitytrax-ember-ui/validations/customer", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateObject = {
        fname: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('fname')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'fname');
            }
          }
        },
        lname: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('lname')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'lname');
            }
          }
        },
        city: {
          presence: this.customMessageOrDefault(data, 'city')
        },
        state: {
          presence: this.customMessageOrDefault(data, 'state')
        },
        primaryPhone: {
          presence: this.customMessageOrDefault(data, 'primaryPhone'),
          format: {
            pattern: /\d{9,15}/,
            //allow 9 for international, up to 15 since db accepts 15
            message: this.customMessage(data, 'phoneInvalid')
          }
        },
        location: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('location')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'location');
            }
          }
        },
        saleDate: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('saleDate')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'saleDate');
            }
          }
        },
        address1: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('customer-address1')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'address1');
            }
          }
        },
        zip: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('customer-zip')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'zip');
            }
          },
          format: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('customer-zip')) {
              return false;
            } else {
              return {
                pattern: /\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d/,
                message: this.customMessage(data, 'zipInvalid')
              };
            }
          }
        }
      }; //ADT validation

      if ('extraValidationData' in data && 'adtValidation' in data.extraValidationData) {
        if (!('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('customer-address1'))) {
          validateObject['address1'] = {
            presence: this.customMessageOrDefault(data, 'address1')
          };
        }

        if (!('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('businessName'))) {
          validateObject['businessName'] = {
            presence: this.customMessageOrDefault(data, 'businessName')
          };
        }

        if (!('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('businessContact'))) {
          validateObject['businessContact'] = {
            presence: this.customMessageOrDefault(data, 'businessContact')
          };
        }
      } //validations for demos


      if ('extraValidationData' in data && 'demo' in data.extraValidationData) {
        validateObject['originalInstallStartTime'] = {
          presence: this.customMessageOrDefault(data, 'originalInstallStartTime')
        };
      } //validations for pre-check


      if ('extraValidationData' in data && 'newPreCheck' in data.extraValidationData && data.extraValidationData.newPreCheck === true) {
        validateObject['county'] = {
          presence: this.customMessageOrDefault(data, 'county')
        };
        validateObject['accountType'] = {
          presence: this.customMessageOrDefault(data, 'accountType')
        };
        validateObject['email'] = {
          presence: this.customMessageOrDefault(data, 'email')
        };
      }

      if ('extraValidationData' in data && 'isASG' in data.extraValidationData && data.extraValidationData.isASG === true) {
        validateObject['accountType'] = {
          presence: this.customMessageOrDefault(data, 'accountType')
        };
      } //if they include an email, make sure its a valid email format


      if (this.email) {
        validateObject['email'] = {
          email: this.customMessageOrDefault(data, 'email')
        };
      }

      if (this.cellPhone) {
        validateObject['cellPhone'] = {
          format: {
            pattern: /\d{9,15}/,
            //allow 9 for international, up to 15 since db accepts 15
            message: this.customMessage(data, 'phoneInvalid')
          }
        };
      }

      if (this.workPhone) {
        validateObject['workPhone'] = {
          format: {
            pattern: /\d{9,15}/,
            //allow 9 for international, up to 15 since db accepts 15
            message: this.customMessage(data, 'phoneInvalid')
          }
        };
      }

      if (this.otherPhone) {
        validateObject['otherPhone'] = {
          format: {
            pattern: /\d{9,15}/,
            //allow 9 for international, up to 15 since db accepts 15
            message: this.customMessage(data, 'phoneInvalid')
          }
        };
      }

      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject, {
        fullMessages: this.prependProperty(data)
      });
      return this.outputFormatting(validationData, 'customer');
    }

  });

  _exports.default = _default;
});