define("securitytrax-ember-ui/helpers/time-options", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.timeOptions = timeOptions;
  _exports.default = void 0;

  function timeOptions([minuteIncrement = 15, startTime, endTime, timeValueFormat, timeDisplayFormat]) {
    var options = [];
    var start = startTime ? (0, _moment.default)(startTime, timeValueFormat) : (0, _moment.default)().startOf('day');
    var end = endTime ? (0, _moment.default)(endTime, timeValueFormat) : (0, _moment.default)().endOf('day');

    while (start <= end) {
      var timeOption = (0, _moment.default)(start).format(timeDisplayFormat);
      options.push(Ember.Object.create({
        name: "".concat(timeOption),
        value: (0, _moment.default)(start).format(timeValueFormat)
      }));
      start.add(minuteIncrement, 'minutes');
    }

    return options;
  }

  var _default = Ember.Helper.helper(timeOptions);

  _exports.default = _default;
});