define("securitytrax-ember-ui/models/base_models/parent-entity-invoice-statements-recurring", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    nextDate: (0, _model.attr)('date-only', {
      'readOnly': true
    }),
    lastDate: (0, _model.attr)('date-only', {
      'readOnly': true
    }),
    recurringIntervalNumber: (0, _model.attr)('number', {
      'readOnly': false
    }),
    recurringIntervalPeriod: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['days', 'weeks', 'months', 'years']
    }),
    numTimesToRecur: (0, _model.attr)('number', {
      'readOnly': false
    }),
    status: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['active', 'deleted']
    }),
    sendOnGeneration: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    sendOnAttemptedProcessing: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    recurringEmail: (0, _model.attr)('string', {
      'readOnly': false
    }),
    defaultMessage: (0, _model.attr)('string', {
      'readOnly': false
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    parentEntity: (0, _model.belongsTo)('parent-entity', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    parentEntityInvoiceStatement: (0, _model.belongsTo)('parent-entity-invoice-statement', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    parentEntityBilling: (0, _model.belongsTo)('parent-entity-billing', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});