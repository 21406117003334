define("securitytrax-ember-ui/components/multi-step-form/forms/administration/content-management/edit-invoice-billable-item/component", ["exports", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "ember-concurrency"], function (_exports, _component, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EditInvoiceBillableItem = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = (0, _emberConcurrency.task)(function* () {
    var multiStepForm = this.args.multiStepForm;
    yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);
    this.invoiceBillabeItemClasses = yield this.store.findAll('invoiceBillableItemClass');

    if (this.SwitchableFormStep.isNewRecord) {
      multiStepForm.formCopiedModelsSetVal('invoiceBillableItem.model.active', true);
    } else {
      var isTaxable = multiStepForm.formCopiedModelsGetVal('invoiceBillableItem.model.isTaxable');
      multiStepForm.formCopiedModelsSetVal('invoiceBillableItem.model.isTaxable', isTaxable == undefined ? null : isTaxable);
    }
  }), _dec8 = Ember._action, _dec9 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    var validationObject = {
      deepValidate: false,
      deepCreate: false
    };

    if (this.SwitchableFormStep.isNewRecord) {
      multiStepForm.formCopiedModelsSetVal('invoiceBillableItem.model.defaultUnitPrice', multiStepForm.formCopiedModelsGetVal('invoiceBillableItem.model.defaultUnitPrice') || 0.00);
      var toCreateObjects = yield multiStepForm.validateCreateAll(validationObject);
      var invoiceBillableItem = this.store.createRecord('invoiceBillableItem', toCreateObjects.invoiceBillableItem.data);
      yield toCreateObjects.invoiceBillableItem.saveWithRollbackAndNotification(invoiceBillableItem);
    } else {
      yield multiStepForm.validateSyncSaveAll(validationObject);
    }

    buttonPromise.then(() => {
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
      multiStepForm.formStartedSetVal(false);
    });
  }), _dec10 = (0, _emberConcurrency.task)(function* () {
    var invoiceBillableItem = this.args.multiStepForm.formOriginalModelsGetVal('invoiceBillableItem.model');

    try {
      yield invoiceBillableItem.destroyRecordWithRollbackTask.perform();
      this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
      this.args.multiStepForm.onDelete();
      this.args.multiStepForm.formStartedSetVal(false);
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'confirmDeleteModal'
      });
      throw err;
    }
  }), (_class = (_temp = class EditInvoiceBillableItem extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "grants", _descriptor4, this);

      _initializerDefineProperty(this, "confirmDeleteModalOpen", _descriptor5, this);

      _initializerDefineProperty(this, "invoiceBillabeItemClasses", _descriptor6, this);

      _initializerDefineProperty(this, "initFormData", _descriptor7, this);

      _initializerDefineProperty(this, "saveInvoiceBillableItem", _descriptor8, this);

      _initializerDefineProperty(this, "deleteInvoiceBillableItem", _descriptor9, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.initFormData.perform();
    }

    get taxableOptions() {
      var intl = this.intl;
      return [{
        name: intl.t('pages.administration.content_management.invoice_billable_item.ifNotExempt'),
        value: null
      }, {
        name: intl.t('pages.administration.content_management.invoice_billable_item.always'),
        value: 1
      }, {
        name: intl.t('pages.administration.content_management.invoice_billable_item.never'),
        value: 0
      }];
    }

    get equipmentDetails() {
      return [Ember.Object.create({
        packageName: this.intl.t('common.forms.descriptive_title_view', {
          name: this.args.multiStepForm.formCopiedModelsGetVal('invoiceBillableItem.model.equipment.name'),
          id: this.args.multiStepForm.formCopiedModelsGetVal('invoiceBillableItem.model.equipment.id')
        }),
        packageId: this.args.multiStepForm.formCopiedModelsGetVal('invoiceBillableItem.model.equipment.id'),
        packageContent: [{
          label: this.intl.t('pages.administration.content_management.invoice_billable_item.retailPrice'),
          description: "$".concat(this.args.multiStepForm.formCopiedModelsGetVal('invoiceBillableItem.model.equipment.value'))
        }, {
          label: this.intl.t('pages.administration.content_management.invoice_billable_item.description'),
          description: this.args.multiStepForm.formCopiedModelsGetVal('invoiceBillableItem.model.equipment.description')
        }]
      })];
    }

    renderButtons() {
      var multiStepForm = this.args.multiStepForm;
      var buttons = [];

      if (this.args.formMode !== 'view') {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveInvoiceBillableItem.perform(multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary invoice-billable-item-save-button'
        });
      }

      if (!this.SwitchableFormStep.isNewRecord && this.grants.getIndividualGrant('invoice_billable_items.delete')) {
        buttons.push({
          text: this.intl.t('common.actions.delete'),
          click: () => {
            this.confirmDeleteModalOpen = true;
          },
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
        });
      }

      multiStepForm.registerStepData('step1', 'buttons', buttons);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "confirmDeleteModalOpen", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "invoiceBillabeItemClasses", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "initFormData", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "renderButtons", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "renderButtons"), _class.prototype), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "saveInvoiceBillableItem", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "deleteInvoiceBillableItem", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = EditInvoiceBillableItem;
});