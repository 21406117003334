define("securitytrax-ember-ui/components/inventory/review-items/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    totalExtended: Ember.computed('pendingEquipmentLineItems.@each.extended', function () {
      var total = 0.00;

      if (this.pendingEquipmentLineItems) {
        this.pendingEquipmentLineItems.forEach(pendingEquipmentLineItem => {
          total += pendingEquipmentLineItem.get('extended') ? parseFloat(pendingEquipmentLineItem.get('extended')) : 0.00;
        });
      }

      return total;
    }),
    totalQuantity: Ember.computed('pendingEquipmentLineItems.@each.quantity', function () {
      var total = 0;
      this.pendingEquipmentLineItems.forEach(item => {
        total += item.get('quantity') ? Number(item.get('quantity')) : 0;
      });
      return total;
    })
  });

  _exports.default = _default;
});