define("securitytrax-ember-ui/components/multi-step-form/forms/administration/users/roles/rep-numbers/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/administration/users/base-users"], function (_exports, _baseUsers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var RepNumbers = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, (_class = (_temp = class RepNumbers extends _baseUsers.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "grants", _descriptor, this);

      _initializerDefineProperty(this, "repNumberFormStarted", _descriptor2, this);

      _initializerDefineProperty(this, "repNumber", _descriptor3, this);

      _initializerDefineProperty(this, "repNumberFormMode", _descriptor4, this);

      _initializerDefineProperty(this, "repNumbersFormSteps", _descriptor5, this);
    }

    get repNumbersFormOriginalModels() {
      return Ember.Object.create({
        userRepNumber: {
          model: this.repNumber,
          copyRelationMap: {
            customerAtRisk: {
              readOnly: true
            }
          }
        }
      });
    }

    openRepNumber(repNumber, formMode) {
      var titleTranslation = formMode === 'create' ? 'pages.users.roles.rep_number_add' : 'common.forms.descriptive_title_edit';
      this.repNumber = repNumber;
      this.repNumberFormMode = formMode === 'edit' && !this.grants.getIndividualGrant('user_rep_numbers.modify') ? 'view' : formMode;
      this.repNumbersFormSteps = Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.intl.t(titleTranslation, {
            name: this.intl.t('pages.users.roles.rep_number_number'),
            id: this.repNumber ? this.repNumber.get('id') : null
          }),
          stepComponentPath: 'multi-step-form/forms/administration/users/roles/rep-numbers/edit-create-rep-number'
        })
      });
      this.repNumberFormStarted = true;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "repNumberFormStarted", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "repNumber", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "repNumberFormMode", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'edit';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "repNumbersFormSteps", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "openRepNumber", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "openRepNumber"), _class.prototype)), _class));
  _exports.default = RepNumbers;
});