define("securitytrax-ember-ui/validations/customer-abort-code", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateObject = {
        abortCode: {
          presence: () => {
            if ('extraValidationData' in data && data.extraValidationData && (data.extraValidationData.validateAbortCode == true || data.extraValidationData.conditionalValidate == true)) {
              return this.customMessageOrDefault(data, 'personalIdentificationCode');
            } else {
              return false;
            }
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject, {
        fullMessages: this.prependProperty(data)
      });
      return this.outputFormatting(validationData, 'customerAbortCode');
    }

  });

  _exports.default = _default;
});