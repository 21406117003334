define("securitytrax-ember-ui/services/invoice-service", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Service to get un invoiced equipment
   *
   * @class InvoiceService
   * @extends Ember.Service
   * @memberof services
   * @mixes Ember.Evented
   */
  var _default = Ember.Service.extend({
    stAjax: Ember.inject.service(),
    getConnectedItemsTask: (0, _emberConcurrency.task)(function* (customerId, type) {
      var formattedConnectedItems = [];
      var url = "/invoices/connected_line_items/customer/".concat(customerId, "/").concat(type);
      var connectedLineItems = (yield this.stAjax.request(this.stAjax.getUrl(url), {
        method: 'GET'
      })).data;

      if (connectedLineItems) {
        connectedLineItems.forEach(item => {
          formattedConnectedItems.push(Ember.Object.create(item));
        });
      }

      return formattedConnectedItems;
    })
  });

  _exports.default = _default;
});