define("securitytrax-ember-ui/components/multi-step-form/forms/notes/task-template-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Fd3Cur90",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],null,[[\"useSpinner\",\"booleanLoaded\"],[true,[35,4]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"grid-x grid-padding-x grid-padding-y\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cell small-12\"],[12],[2,\"\\n            \"],[8,\"html/package-select/package-layouts/default-layout\",[[24,0,\"package-select-default cell small-12\"]],[[\"@isLargeLabel\",\"@packageList\",\"@selectedId\",\"@labelProperty\",\"@valueProperty\",\"@canDeselect\",\"@updateSelected\"],[true,[34,0],[34,1],\"label\",\"description\",true,[30,[36,3],[[32,0],[30,[36,2],[[35,1]],null]],null]]],null],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"templateOptions\",\"currentTaskId\",\"mut\",\"action\",\"loaded\",\"html/st-await\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/notes/task-template-select/template.hbs"
    }
  });

  _exports.default = _default;
});