define("securitytrax-ember-ui/models/base_models/payroll-list-method", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    name: (0, _model.attr)('string', {
      'readOnly': false
    }),
    method: (0, _model.attr)('string', {
      'readOnly': false
    }),
    description: (0, _model.attr)('string', {
      'readOnly': false
    }),
    parameterType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['int']
    }),
    parameterModel: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['LeadCompany', 'PayrollRoleFunction', 'PayrollRoleFunctionLevel']
    }),
    parameterSupport: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['single', 'many', 'none']
    }),
    entityType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['user', 'lead_company', 'customer', 'lead_company_campaign', 'government_regulation']
    }),
    payOnObject: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['customer', 'tech_schedule', 'calendar_event']
    }),
    compatibility: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['v2', 'v3', 'all']
    }),
    active: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    payrollRole: (0, _model.belongsTo)('payroll-role', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    payrollRoleFunctions: (0, _model.hasMany)('payroll-role-function', {
      'readOnly': true,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});