define("securitytrax-ember-ui/components/multi-step-form/forms/invoices/recurring-invoice/history/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/invoices/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RecurringInvoiceHistory extends _base.default {
    get hiddenProgressBarSteps() {
      return ['gettingStarted', 'confirmRefundVoid', 'viewProcessingInformation', 'editManualPayment', 'duplicate'];
    }

    get disabledProgressBarSteps() {
      return this.args.formMode === 'create' ? ['addPayment', 'listPayments', 'sendInvoice'] : [];
    }

  }

  _exports.default = RecurringInvoiceHistory;
});