define("securitytrax-ember-ui/components/calendar-glimmer/calendar-map/map-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "keVih+ej",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"cell shrink generate-map-cell\"],[12],[2,\"\\n    \"],[8,\"html/st-button\",[[24,0,\"st-btn st-btn-primary\"]],[[\"@text\",\"@clickAction\",\"@disabled\"],[[30,[36,0],[\"pages.calendar.generate_map\"],null],[30,[36,1],[[32,0],\"goToMap\"],null],[32,0,[\"mapButtonDisabled\"]]]],null],[2,\"  \\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"action\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/calendar-glimmer/calendar-map/map-toggle/template.hbs"
    }
  });

  _exports.default = _default;
});