define("securitytrax-ember-ui/customers/appointments/route", ["exports", "securitytrax-ember-ui/customers/base-route", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _baseRoute, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_routePageviewLogging.default, {
    grants: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['calendar_events.view']);
    },

    model() {
      var {
        customer
      } = this._super(...arguments);

      return Ember.RSVP.hash({
        customer,
        users: this.store.query('user', {
          filter: 'technician = true'
        })
      });
    }

  });

  _exports.default = _default;
});