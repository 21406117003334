define("securitytrax-ember-ui/components/multi-step-form/forms/administration/equipment/add-equipment-master-list/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/switchable-form-step", "lodash", "securitytrax-ember-ui/utils/obj-helpers", "securitytrax-ember-ui/mixins/table-column-sort", "jquery"], function (_exports, _emberConcurrency, _switchableFormStep, _lodash, _objHelpers, _tableColumnSort, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, _tableColumnSort.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    stFiltersService: Ember.inject.service(),
    filterGroupKeyStr: 'equipmentMasterList',
    allTypeOptions: undefined,
    companyEquipmentArray: null,
    disabledClass: true,
    equipmentFormMode: 'edit',
    equipmentListCount: 0,
    filterApplied: false,
    limit: 10,
    listLoaded: false,
    page: 1,
    resultsLoaded: true,
    appliedFiltersCount: 0,
    requestNewItemFormStarted: false,
    initTask: (0, _emberConcurrency.task)(function* () {
      var hashData = yield (0, _emberConcurrency.hash)({
        allTypeOptions: this.store.findAll('equipmentType'),
        allSubTypeOptions: this.store.findAll('equipmentSubType')
      });
      Ember.setProperties(this, hashData);
    }).on('init'),

    didInsertElement() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        addArray: [],
        disabledClass: true,
        equipmentItems: [],
        filterApplied: false,
        type: null,
        subtype: null,
        companyEquipmentArray: []
      });
      this.setupButtons();
    },

    setupButtons() {
      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('masterList', 'buttons', [{
        text: this.addLabel,
        click: () => {
          multiStepForm.currentStepNameKeySetVal('reviewEquipment');
        },
        disabled: this.disabledClass,
        classNamesString: 'st-btn st-btn-primary save-review-equipment-button'
      }]);
    },

    labelObserver: Ember.observer('addLabel', function () {
      this.setupButtons();
    }),
    addLabel: Ember.computed('addArray.[]', function () {
      var addArray = this.addArray;

      if (addArray.length > 0) {
        this.set('disabledClass', false);
      } else {
        this.set('disabledClass', true);
      }

      return addArray ? this.intl.t('pages.administration.company_settings.equipment.equipment.add_num_items', {
        num: addArray.length
      }) : this.intl.t('pages.administration.company_settings.equipment.equipment.title_create');
    }),
    equipmentList: Ember.computed(function () {
      return this.getEquipmentItems.perform();
    }),
    getEquipmentItems: (0, _emberConcurrency.task)(function* () {
      var newFilters = yield this.stFiltersService.getFiltersByGroupKey(this.filterGroupKeyStr);
      var queryParams = {
        sort: this.stSortString ? this.stSortString : 'name',
        limit: 0,
        //this.get('limit'), //make sure we get all, could be > 100
        //page: this.get('page'), //bring back if we do pagination
        include: 'equipment_sub_type.equipment_type,equipment_manufacturer'
      };

      if (newFilters.length > 0) {
        queryParams['filter'] = newFilters;
      }

      if (this.page == 1) {
        queryParams['count'] = 1;
      } // PERF TODO: BE should probably give us an endpoint to hit to get all equipment items not linked to equipment or add an equipment relation to equipmentItem


      var hashedData = yield (0, _emberConcurrency.hash)({
        equipment: this.store.query('equipmentItem', queryParams),
        companyEquipment: this.store.query('equipment', {
          limit: 0,
          filter: 'equipment_item != null'
        })
      });
      var companyEquipmentMapped = hashedData.companyEquipment.map(item => {
        return item.get('equipmentItem.id');
      });
      this.set('companyEquipmentArray', companyEquipmentMapped); //if we decide to show mapped items in list

      var count = hashedData.equipment.meta.record_count;
      var filteredEquipment = hashedData.equipment.toArray().filter(item => {
        var notThere = companyEquipmentMapped.indexOf(item.get('id')) === -1;

        if (notThere) {
          count--;
        }

        return notThere;
      });

      if (this.page == 1) {
        this.set('equipmentListCount', count);
      }

      return filteredEquipment;
    }).restartable().maxConcurrency(1),
    allSubTypeOptionsComputed: Ember.computed('type', function () {
      if (this.type) {
        return this.type.get('equipmentSubTypes');
      }

      return this.allSubTypeOptions;
    }),
    actions: {
      sortList(column, ascending) {
        this.sortPaginatedList(column, ascending, 'listLoaded');
        Ember.notifyPropertyChange(this, 'equipmentList');
      },

      addEquipmentToArray(equipmentItem) {
        var addArray = this.addArray;

        if (!_lodash.default.find(addArray, {
          id: equipmentItem.id
        })) {
          equipmentItem.set('inAddArray', true);
          addArray.push(equipmentItem);
          this.set('addArray', addArray);
          Ember.notifyPropertyChange(this, 'addArray');
        }

        (0, _jquery.default)('.addremove__removable-container').scrollTop((0, _jquery.default)('.addremove__removable-container')[0].scrollHeight);
      },

      removeEquipmentFromArray(equipmentItem) {
        equipmentItem.set('inAddArray', false);
        var addArray = this.addArray;
        var resetAddArray = addArray.filter(arrayItem => {
          return arrayItem.id != equipmentItem.get('id');
        });
        this.set('addArray', resetAddArray);
      },

      deleteEquipment(equipment) {
        equipment.destroyRecordWithRollbackTask.perform().then(() => {
          this.set('showEquipmentDeleteId', false);
        });
      },

      getEquipment()
      /*data = null*/
      {
        Ember.notifyPropertyChange(this, 'equipmentList');
      },

      clearFilter() {
        Ember.setProperties(this, {
          equipmentName: null,
          equipmentModel: null,
          type: undefined,
          subtype: undefined,
          page: 1,
          limit: this.limit
        });
        Ember.notifyPropertyChange(this, 'equipmentList');
      },

      //setup subType filter
      setupSubTypeOptions(type) {
        Ember.set(this, 'type', type);
        Ember.set(this, 'subtype', null);
        this.stFiltersService.clearFilter(this.filterGroupKeyStr, 'equipmentSubtype', 'selectedValue');
      },

      // NOTE: 
      //   returning a string means use that string as the filter
      //   returning an array of strings means to add all the strings
      //   returning false means not to add the filter at all and clear it if it exists
      filterTypeIsNull() {
        if (this.type) {
          Ember.set(this, 'type', this.type);
          Ember.set(this, 'subtype', null);
          var subTypes = this.allSubTypeOptionsComputed.map(subtype => {
            return subtype.get('id');
          });
          return 'equipment_sub_type = [' + subTypes.join(',') + ']';
        }

        return false; // if no matches
      },

      overrideSubtype() {
        if (this.subtype) {
          return 'equipment_sub_type = ' + this.subtype.get('id');
        }

        return false;
      }

    }
  });

  _exports.default = _default;
});