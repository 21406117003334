define("securitytrax-ember-ui/components/dashboard-config/gallery/section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QpWPTipt",
    "block": "{\"symbols\":[\"multiStepForm\",\"Form\",\"cardData\",\"cardName\",\"@editDashboard\",\"@customer\"],\"statements\":[[10,\"div\"],[14,0,\"dashboard-config__section dashboard-config__section--gallery\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[35,3]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"dashboard-config/gallery/dashboard-card\",[],[[\"@cardToAdd\",\"@addFormStarted\",\"@cardName\",\"@titleOverride\",\"@cardData\",\"@editDashboard\",\"@customer\"],[[34,0],[34,2],[32,4],[32,3,[\"config\",\"titleOverride\"]],[32,3],[32,5],[32,6]]],null],[2,\"\\n\"]],\"parameters\":[3,4]}]]],[2,\"\\n    \"],[8,\"multi-step-form\",[],[[\"@formSteps\",\"@usesModel\",\"@formStarted\",\"@changeFormStarted\"],[[34,6],true,[34,2],[30,[36,8],[[32,0],[30,[36,7],[[35,2]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"modals/st-paper\",[],[[\"@modalButtons\",\"@title\",\"@classNames\",\"@dialogOpen\",\"@modalNamespace\",\"@multiStepForm\"],[[32,1,[\"currentStepData\",\"buttons\"]],[32,1,[\"currentStepData\",\"title\"]],\"st-modal--small add-dashboard-card-form\",[32,1,[\"formStarted\"]],\"addDashboardCardModal\",[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,10],[[30,[36,9],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[8,[32,2],[],[[\"@multiStepForm\",\"@formMode\",\"@dashboardCard\",\"@cardData\",\"@editDashboard\",\"@customer\"],[[32,1],\"create\",[34,0],[34,1],[32,5],[32,6]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"cardToAdd\",\"cardData\",\"addFormStarted\",\"sortedCards\",\"-each-in\",\"each\",\"addFormSteps\",\"mut\",\"action\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/dashboard-config/gallery/section/template.hbs"
    }
  });

  _exports.default = _default;
});