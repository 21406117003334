define("securitytrax-ember-ui/components/multi-step-form/forms/leads/convert-customer/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms/leads/lead-base", "securitytrax-ember-ui/mixins/forms/customer-lead-shared", "securitytrax-ember-ui/mixins/set-user"], function (_exports, _emberConcurrency, _leadBase, _customerLeadShared, _setUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _leadBase.default.extend(_setUser.default, _customerLeadShared.default, {
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    userService: Ember.inject.service(),
    grants: Ember.inject.service(),
    dynamicFormChooser: Ember.inject.service('events/dynamic-form-chooser'),

    didInsertElement() {
      this._super(...arguments); // if (this.nrg) {
      //     this.setupNRGButtons();
      //     if (this.newCustomer.recordType === 'customer') {
      //         this.loadConvertDataTask.perform();
      //     }
      // } else {


      this.setupButtons(); // }
    },

    loadConvertDataTask: (0, _emberConcurrency.task)(function* () {
      var hashedData = yield (0, _emberConcurrency.hash)({
        waitForFinishedCopyModels: (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true),
        location: this.newCustomer.get('location'),
        repUser: this.newCustomer.get('repUser')
      });
      this.multiStepForm.formCopiedModelsSetVal('customer.model.location', hashedData.location);
      this.multiStepForm.formCopiedModelsSetVal('customer.model.repUser', hashedData.repUser);
    }),
    // setupNRGButtons: function () {
    //     const multiStepForm = this.multiStepForm;
    //     const buttons = [];
    //     buttons.push({
    //         text: this.intl.t('common.actions.back'),
    //         classNamesString: 'st-btn st-btn-secondary',
    //         click: () => {
    //             multiStepForm.currentStepNameKeySetVal('step7');
    //             multiStepForm.progressBarConfig.activeIndex = 6;
    //         }
    //     });
    //     if (multiStepForm.formCopiedModels.customer.model.recordType === 'lead') {
    //         buttons.push({
    //             text: this.intl.t('common.actions.saveAndContinue'),
    //             classNamesString: 'st-btn st-btn-primary lead-to-customer-button',
    //             click: (buttonPromise) => {
    //                 return this.convertToCustomerTask.perform(multiStepForm, buttonPromise);
    //             },
    //             positionOptions: 'up left',
    //             additionalClickActions: [{
    //                     label: this.intl.t('common.actions.saveAndClose'),
    //                     action: (buttonPromise) => {
    //                         return this.saveConfirmCustomerTask.perform(multiStepForm, buttonPromise, true);
    //                     },
    //                     classNamesString: 'dropdown-save-close'
    //                 }
    //         ]});
    //     } else {
    //         buttons.push({
    //             text: this.intl.t('common.actions.skip'),
    //             classNamesString: 'st-btn st-btn-secondary',
    //             click: () => {
    //                 this.advanceForm(multiStepForm, 'step9', 8);
    //             }
    //         });
    //     }
    //     multiStepForm.registerStepData('step8', 'buttons', buttons);
    // },
    setupButtons: function () {
      var multiStepForm = this.multiStepForm;

      if (this.formMode != 'view') {
        if (this.isNewRecord) {
          multiStepForm.registerStepData('step3', 'buttons', [{
            text: this.intl.t('common.actions.back'),
            classNamesString: 'st-btn st-btn-secondary',
            click: () => {
              Ember.set(multiStepForm, 'currentStepNameKey', 'step2');
              Ember.set(multiStepForm, 'progressBarConfig.activeIndex', 1);
            }
          }, {
            text: this.intl.t('common.actions.save'),
            classNamesString: 'st-btn st-btn-primary lead-to-customer-button',
            click: buttonPromise => {
              return this.convertToCustomerTask.perform(multiStepForm, buttonPromise);
            },
            positionOptions: 'up left',
            additionalClickActions: [{
              label: this.intl.t('common.actions.deleteCustomer'),
              action: () => {
                this.openConfirmDelete('step3');
              },
              classNamesString: 'dropdown-delete-customer'
            }, {
              label: this.intl.t('common.actions.saveAndClose'),
              action: buttonPromise => {
                return this.convertToCustomerTask.perform(multiStepForm, buttonPromise, true);
              },
              classNamesString: 'dropdown-save-close'
            }]
          }]);
        } else {
          multiStepForm.registerStepData('convert', 'buttons', [{
            text: this.intl.t('pages.leads.convert'),
            classNamesString: 'st-btn st-btn-primary lead-to-customer-button',
            click: buttonPromise => {
              return this.convertToCustomerTask.perform(multiStepForm, buttonPromise);
            }
          }]);
        }
      }
    },

    willDestroy() {
      var multiStepForm = this.multiStepForm;
      multiStepForm.setupCopyModels.perform();
    },

    locations: Ember.computed(function () {
      return this.store.findAll('location');
    }),
    repUsers: Ember.computed(function () {
      return this.userService.getUsersList('sales_rep', this.lead);
    }),
    convertToCustomerTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      try {
        var lead = this.newCustomer || this.lead;
        var rep = yield multiStepForm.formCopiedModels.customer.model.repUser;
        var location = yield multiStepForm.formCopiedModels.customer.model.location; //validate

        yield multiStepForm.validateList.perform([Ember.Object.create({
          model: {
            location
          },
          filePath: 'leads/convert-to-customer'
        })]);
        var convertData = {
          location_id: location.id
        };

        if (rep) {
          convertData['rep_user_id'] = rep.id;
        }

        yield lead.invokeAction('convert_to_customer', {
          verb: 'POST',
          data: {
            data: convertData
          }
        });
        buttonPromise.then(() => {
          if (this.showWorkflows) {
            lead.set('recordType', 'customer');
            this.dynamicFormChooser.trigger('open-dynamic-form', {
              id: this.currentCreateId,
              customer: lead
            });
            lead.get('location').reload();
          }

          if (this.grants.getIndividualGrant('customer_system_information.view')) {
            lead.get('customerSystemInformation').reload();
          }

          if (this.grants.getIndividualGrant('customer_order_information.view')) {
            lead.get('customerOrderInformation').reload();
          }

          multiStepForm.formStartedSetVal(false);
          this.router.transitionTo('customers.information', lead.id);
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        });
      } catch (error) {
        (false && Ember.warn('error in convert lead to customer task: ' + error, {
          id: 'multiStepForm.forms.leads.convertLead'
        }));
        this.stNotificationManager.addErrorMessages(error, {
          notificationNamespace: this.lead ? 'convertToCustomerModal' : 'createLeadModal',
          showFallbackMessage: false
        });
        throw error;
      }
    })
  });

  _exports.default = _default;
});