define("securitytrax-ember-ui/components/version-check/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gDhVW7W2",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,1]],null,[[\"default\"],[{\"statements\":[[6,[37,3],null,[[\"modalButtons\",\"dialogOpen\",\"hideTopRightCloseButton\",\"clickOutsideToClose\",\"classNames\",\"title\"],[[35,2],[35,1],true,true,\"st-modal--small session-inactive-dialog\",[30,[36,0],[\"common.version.version-update\"],null]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"grid-x grid-padding-x\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"cell small-12\"],[12],[2,\"\\n                \"],[10,\"p\"],[12],[1,[30,[36,0],[\"common.version.version-conflict\"],null]],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"dialogActive\",\"activityButtons\",\"modals/st-paper\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/version-check/template.hbs"
    }
  });

  _exports.default = _default;
});