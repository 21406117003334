define("securitytrax-ember-ui/models/customer-billing-bill-bank-number", ["exports", "securitytrax-ember-ui/models/base_models/customer-billing-bill-bank-number"], function (_exports, _customerBillingBillBankNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerBillingBillBankNumber.default.extend({});

  _exports.default = _default;
});