define("securitytrax-ember-ui/components/multi-step-form/forms/administration/account-management/payment-method/credit-card/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency", "securitytrax-ember-ui/mixins/credit-card-mixin"], function (_exports, _switchableFormStep, _emberConcurrency, _creditCardMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, _creditCardMixin.default, {
    tagName: '',
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    billCcExpirationYear: Ember.computed.alias('multiStepForm.formCopiedModels.accountBilling.model.cardExpireYear'),
    creditCardExpirationYears: Ember.computed('expirationYear', function () {
      var d = new Date(),
          currentYear = d.getFullYear(),
          years = [],
          expirationYear = this.multiStepForm.formOriginalModelsGetVal('accountBilling.model.cardExpireYear');

      if (!Ember.isEmpty(expirationYear) && expirationYear < currentYear) {
        years.push({
          name: expirationYear,
          value: expirationYear
        });
      }

      for (var i = currentYear; i <= currentYear + 10; i++) {
        years.push({
          name: i.toString(),
          value: i.toString()
        });
      }

      return years;
    }),
    initFormData: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true);
      var doubleDigitizedMonth;
      var startingMonth = this.multiStepForm.formCopiedModelsGetVal('accountBilling.model.cardExpireMonth');

      if (startingMonth && startingMonth.length === 1) {
        var startingString = '0';
        doubleDigitizedMonth = startingString.concat(startingMonth);
      } else {
        doubleDigitizedMonth = startingMonth;
      }

      this.multiStepForm.formCopiedModelsSetVal('accountBilling.model.cardExpireMonth', doubleDigitizedMonth);
    }).on('init')
  });

  _exports.default = _default;
});