define("securitytrax-ember-ui/components/multi-step-form/forms/administration/users/base-users", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "securitytrax-ember-ui/mixins/set-user", "ember-concurrency"], function (_exports, _switchableFormStep, _setUser, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, _setUser.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    grants: Ember.inject.service(),
    informationFormMode: 'view',
    settingsFormMode: 'view',
    loginInfoFormMode: 'view',
    humanResourcesFormMode: 'view',
    inventoryFormMode: 'view',

    didInsertElement() {
      this._super(...arguments);

      if (this.isNewRecord) {
        this.set('informationFormMode', 'create');
        this.set('settingsFormMode', 'create');
        this.set('loginInfoFormMode', 'create');
      }
    },

    saveUserDataTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise, noValidateArray = []) {
      if (this.isNewRecord) {
        multiStepForm.formCopiedModels.user.model.password = this.password;
        multiStepForm.formCopiedModels.user.model.passwordVerify = this.passwordVerify;
        var toCreateObjects = yield multiStepForm.validateCreateAll({
          extraValidationData: {
            noValidate: noValidateArray
          },
          notificationNamespace: 'modal'
        });
        var theModel = this.store.createRecord('user', toCreateObjects.user.data);

        if (this.password) {
          theModel.set('requestMeta', {
            password: this.password
          });
          theModel['password'] = this.password;
        }

        var newModel = yield toCreateObjects.user.saveWithRollbackAndNotification(theModel, 'modal');
        var newUserBelongsToData = yield (0, _emberConcurrency.hash)({
          userAdminSetting: newModel.userAdminSetting,
          userPreference: newModel.userPreference
        });
        newUserBelongsToData.userAdminSetting.setProperties({
          active: toCreateObjects.userAdminSetting.data.active,
          login: toCreateObjects.userAdminSetting.data.login
        });
        newUserBelongsToData.userPreference.setProperties({
          emailReminders: toCreateObjects.userPreference.data.emailReminders,
          restoreSession: toCreateObjects.userPreference.data.restoreSession
        });
        yield (0, _emberConcurrency.all)([newUserBelongsToData.userAdminSetting.save(), newUserBelongsToData.userPreference.save()]);
        buttonPromise.then(() => {
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
          multiStepForm.formStartedSetVal(false);
        });
      } else {
        var promises = [];

        if (multiStepForm.formCopiedModels.user.model.reportsToChanged) {
          promises.push(this.updateReportsTo.perform());
        }

        promises.push(multiStepForm.validateSyncSaveAll({
          extraValidationData: {
            noValidate: noValidateArray
          }
        }));
        yield (0, _emberConcurrency.all)(promises);
        buttonPromise.then(() => {
          this.notifyPropertyChange('orgChartUsers');
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
          this.set(multiStepForm.currentStepNameKey + 'FormMode', 'view');
        });
      }
    }),
    updateReportsTo: (0, _emberConcurrency.task)(function* () {
      var userHierarchyTree = yield this.store.findRecord('userHierarchyTree', 1);

      if (this.userNotInOrgTree) {
        var userHierarchy = yield this.store.createRecord('userHierarchy', {
          user: this.multiStepForm.formOriginalModels.user.model,
          userHierarchyTree: userHierarchyTree,
          parent: this.orgChartParent
        });
        yield userHierarchy.save();
        this.set('initialOrgChartParent', this.orgChartParent ? this.orgChartParent.user ? yield this.orgChartParent.get('user') : this.orgChartParent : null);
        this.set('orgChartParent', this.orgChartParent ? this.orgChartParent.user ? yield this.orgChartParent.get('user') : this.orgChartParent : null);
      } else {
        try {
          if (!this.orgChartParent) {
            var hierarchyToRemove = yield this.store.findRecord('userHierarchy', "{\"user_hierarchy_tree_id\":\"1\",\"user_id\":".concat(this.multiStepForm.formOriginalModels.user.model.id, "}"));

            if (hierarchyToRemove) {
              yield hierarchyToRemove.destroyRecordWithRollbackTask.perform();
            }
          } else {
            yield userHierarchyTree.invokeAction('move_node', {
              verb: 'POST',
              data: {
                'data': {
                  'relationships': {
                    'user_hierarchy': {
                      'data': {
                        'type': 'user_hierarchies',
                        'id': {
                          'user_hierarchy_tree_id': '1',
                          'user_id': this.multiStepForm.formCopiedModelsGetVal('user.model.id')
                        }
                      }
                    },
                    'new_parent_user_hierarchy': {
                      'data': {
                        'type': 'user_hierarchies',
                        'id': {
                          'user_hierarchy_tree_id': userHierarchyTree.get('id'),
                          'user_id': this.orgChartParent.get('id')
                        }
                      }
                    }
                  }
                }
              }
            });
          }

          this.set('initialOrgChartParent', this.orgChartParent ? this.orgChartParent.user ? yield this.orgChartParent.get('user') : this.orgChartParent : null);
          this.set('orgChartParent', this.orgChartParent ? this.orgChartParent.user ? yield this.orgChartParent.get('user') : this.orgChartParent : null);
        } catch (err) {
          this.stNotificationManager.addErrorMessages(err);
          throw err;
        }
      }
    }),
    actions: {
      disablePassword() {
        if (this.generatePassword) {
          this.set('password', '');
          this.set('passwordVerify', '');
        }
      }

    }
  });

  _exports.default = _default;
});