define("securitytrax-ember-ui/components/multi-step-form/forms/administration/payables/level-details/add-assignee/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/switchable-form-step", "lodash", "jquery"], function (_exports, _emberConcurrency, _switchableFormStep, _lodash, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    allUsersList: undefined,
    promiseArray: undefined,
    resultsLoaded: true,

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('addAssignee', 'buttons', [{
        text: this.intl.t('common.actions.review'),
        click: () => {
          multiStepForm.currentStepNameKeySetVal('review');
        },
        classNamesString: 'st-btn st-btn-primary save-review-group-users-button'
      }]);
    },

    searchResults: Ember.computed(function () {
      return this.getSearchResults.perform();
    }),
    updatedSearchString: Ember.observer('searchUsers', function () {
      return this.updateSearchTask.perform();
    }),
    updateSearchTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(500); //make sure they are done typing before searching

      var newSearchResults = yield this.getSearchResults.perform();
      this.set('searchResults', newSearchResults);
      Ember.run.next(() => {
        (0, _jquery.default)('.permissions__add-group-user-search input').focus();
      });
    }).restartable(),
    removeUser: (0, _emberConcurrency.task)(function* (user) {
      yield this.removeUserFromArray(user);
      var fullList = this.allUsersList ? yield this.allUsersList : yield this.findActiveUsers.perform();

      for (var x = 0; x < fullList.length; x++) {
        var aUser = fullList[x];

        if (user.user.id === aUser.user.id) {
          Ember.set(aUser, 'inAddArray', false);
        }
      }
    }),
    findActiveUsers: (0, _emberConcurrency.task)(function* () {
      var levelUsers = yield this.store.query('user', {
        filter: 'user_admin_setting.active = true',
        include: 'payable_payee_levels',
        sort: 'lname',
        limit: 0
      });
      this.allUsersList = [];
      this.promiseArray = [];
      var payrollRoleFunction = this.payrollRoleFunction;
      var payrollRoleFunctionLevelsMap = payrollRoleFunction.payrollRoleFunctionLevels.map(item => {
        return item.id;
      });
      levelUsers.toArray().forEach(user => {
        var listUserObject = {
          user,
          inAddArray: false
        };
        this.allUsersList.push(listUserObject);
        this.promiseArray.push(this.currentLevelName.perform(listUserObject, payrollRoleFunctionLevelsMap));
      });
      yield (0, _emberConcurrency.all)(this.promiseArray);
      return this.allUsersList;
    }).drop(),
    currentLevelName: (0, _emberConcurrency.task)(function* (listUserObject, payrollRoleFunctionLevelsMap) {
      var userLevels = yield listUserObject.user.payablePayeeLevels.toArray();

      var _loop = function (j) {
        var level = userLevels[j];
        payrollRoleFunctionLevelsMap.forEach(levelId => {
          if (levelId === level.get('payrollRoleFunctionLevel.id')) {
            listUserObject.currentLevel = level;
          }
        });
      };

      for (var j = 0; j < userLevels.length; j++) {
        _loop(j);
      }
    }),
    getSearchResults: (0, _emberConcurrency.task)(function* () {
      var searchValue = this.searchUsers;
      var addArray = this.addArray;
      var fullList = this.allUsersList ? yield this.allUsersList : yield this.findActiveUsers.perform();

      for (var x = 0; x < fullList.length; x++) {
        var user = fullList[x];

        for (var y = 0; y < addArray.length; y++) {
          if (user.user.id === addArray[y].user.id) {
            Ember.set(user, 'inAddArray', true);
          }
        }
      }

      if (searchValue) {
        searchValue = searchValue.toLowerCase();
        var newSearchResults = yield _lodash.default.filter(fullList, function (user) {
          return user.user.get('fname').toLowerCase().includes(searchValue) || user.user.get('lname').toLowerCase().includes(searchValue) || user.user.get('flname').toLowerCase().includes(searchValue) || user.user.get('lfname').toLowerCase().includes(searchValue);
        });
        return newSearchResults;
      } else {
        return fullList;
      }
    }).restartable().maxConcurrency(1)
  });

  _exports.default = _default;
});