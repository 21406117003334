define("securitytrax-ember-ui/components/multi-step-form/forms/system/adt/steps/edit-create-order-information/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/system/adt/adt-form-base", "ember-concurrency", "securitytrax-ember-ui/mixins/forms/adt", "lodash", "moment"], function (_exports, _adtFormBase, _emberConcurrency, _adt, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adtFormBase.default.extend(_adt.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    grants: Ember.inject.service(),
    store: Ember.inject.service(),
    centralStationService: Ember.inject.service(),
    adtStepReady: true,
    refreshAdtFormAndDiscrepencies: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = this.multiStepForm;
      multiStepForm.setupCopyModels.perform();
      this.notifyPropertyChange('discrepancies');
      yield (0, _emberConcurrency.all)([this.discrepancies, (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true)]);
    }),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('orderInformation', 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.saveAdtMegaForm.perform(multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn-primary adt-save-mega-form-button'
      }]);
    },

    saveAdtMegaForm: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var hashData;
      this.set('adtStepReady', false);

      try {
        var grants = yield this.grants;
        hashData = yield (0, _emberConcurrency.hash)({
          customer: this.customer,
          adtOrder: this.get('adtData.adtCustomerOrder'),
          adtData: this.adtData
        });

        if (this.multiStepForm.formCopiedModelsGetVal('customer.model.creditTracking')) {
          Ember.set(hashData.adtOrder, 'creditTracking', this.multiStepForm.formCopiedModelsGetVal('customer.model.creditTracking'));
        } ///////////////////////////
        // validate adtData


        var noValidateArray = ['fname', 'lname', 'primaryPhone', 'city', 'location', 'saleDate', 'orderInformation-salesRep', 'orderInformation-customerCancelDate', 'businessName'];

        if (!hashData.adtOrder.house_account_suppress_qsp) {
          noValidateArray.push('houseAccountReason');
        }

        if (this.formMode !== 'create') {
          noValidateArray.push('creditTracking');
        }

        var creditTracking = yield this.multiStepForm.formCopiedModelsGetVal('customer.model.creditTracking');

        if (!creditTracking || creditTracking && creditTracking.length >= 5) {
          noValidateArray.push('trackingPin');
        }

        var formattedObjects = Ember.A();
        formattedObjects.pushObject(Ember.Object.create({
          notificationNamespace: 'adtMegaFormModal',
          model: hashData.adtOrder,
          filePath: 'central-station/adt/adt-customer-order',
          extraValidationData: {
            adtValidation: true,
            multiStepForm: multiStepForm,
            noValidate: noValidateArray
          }
        }));
        yield multiStepForm.validateList.perform(formattedObjects);
        var customerNoValidateArray = ['fname', 'lname', 'primaryPhone', 'city', 'location', 'saleDate', 'orderInformation-salesRep', 'orderInformation-customerCancelDate'];

        if (this.multiStepForm.formCopiedModels.customer.model.accountType !== 'Commercial') {
          customerNoValidateArray.push('businessName');
          customerNoValidateArray.push('businessContact');
        }

        yield multiStepForm.validateSyncSaveAll({
          doSetupCopyModelsAfterSave: false,
          notificationNamespace: 'adtMegaFormModal',
          extraValidationData: {
            adtValidation: true,
            validateAbortCode: true,
            noValidate: customerNoValidateArray,
            validationMessages: {
              'personalIdentificationCode': this.intl.t('pages.customers.system.order.personal_id_code_validation'),
              'orderInformation-monitoringPlan': this.intl.t('pages.customers.system.order.service_package_validation'),
              'orderInformation-mmr': this.intl.t('pages.customers.system.order.mmr'),
              'orderInformation-contractTerm': this.intl.t('common.validations.presence', {
                propertyLabel: this.intl.t("pages.customers.system.order.contract_term")
              }),
              'accountType': this.intl.t('common.validations.presence', {
                propertyLabel: this.intl.t("common.nav.create.customer.accountType.label")
              }),
              'address1': this.intl.t('common.validations.presence', {
                propertyLabel: this.intl.t("common.nav.create.customer.address1.label")
              }),
              'city': this.intl.t('common.validations.presence', {
                propertyLabel: this.intl.t("common.nav.create.customer.city.label")
              }),
              'county': this.intl.t('common.validations.presence', {
                propertyLabel: this.intl.t("common.nav.create.customer.county.label")
              }),
              'email': this.intl.t('common.validations.presence', {
                propertyLabel: this.intl.t("common.nav.create.customer.email.label")
              }),
              'primaryPhone': this.intl.t('common.validations.presence', {
                propertyLabel: this.intl.t("common.nav.create.customer.primaryPhone.label")
              }),
              'state': this.intl.t('common.validations.presence', {
                propertyLabel: this.intl.t("common.nav.create.customer.state.label")
              }),
              'businessName': this.intl.t('common.validations.presence', {
                propertyLabel: this.intl.t('common.nav.create.customer.businessName.label')
              }),
              'businessContact': this.intl.t('common.validations.presence', {
                propertyLabel: this.intl.t('common.nav.create.customer.businessContact.label')
              })
            }
          },
          overrideOriginalModelsConfig: Ember.Object.create({
            customer: {
              doCopy: grants.getIndividualGrant('customers.view'),
              doValidate: grants.getIndividualGrant('customers.view'),
              doSave: grants.getIndividualGrant('customers.view'),
              copyRelationMap: {
                agencyContacts: {
                  doCopy: grants.getIndividualGrant('agency_contacts.view'),
                  doValidate: grants.getIndividualGrant('agency_contacts.modify'),
                  doSave: grants.getIndividualGrant('agency_contacts.modify')
                },
                defaultCustomerBilling: {
                  doCopy: grants.getIndividualGrant('customer_billings.view'),
                  doValidate: false,
                  doSave: grants.getIndividualGrant('customer_billings.view'),
                  relationships: {
                    billCycle: {
                      readOnly: true
                    }
                  }
                },
                customerAbortCode: {
                  doCopy: grants.getIndividualGrant('customer_abort_codes.view'),
                  doValidate: grants.getIndividualGrant('customer_abort_codes.view'),
                  doSave: grants.getIndividualGrant('customer_abort_codes.view')
                },
                customerSystemInformation: {
                  doCopy: grants.getIndividualGrant('customer_system_information.view'),
                  doValidate: grants.getIndividualGrant('customer_system_information.view'),
                  doSave: grants.getIndividualGrant('customer_system_information.view')
                },
                customerBillings: {
                  readOnly: true,
                  doCopy: grants.getIndividualGrant('customer_billings.view'),
                  relationships: {
                    customerBillingUses: {
                      readOnly: true
                    }
                  }
                },
                customerOrderInformation: {
                  doCopy: grants.getIndividualGrant('customer_order_information.view'),
                  doValidate: grants.getIndividualGrant('customer_order_information.view'),
                  doSave: grants.getIndividualGrant('customer_order_information.view'),
                  relationships: {
                    contractTerm: {
                      readOnly: true
                    },
                    activationFee: {
                      readOnly: true
                    },
                    monitoringPlan: {
                      readOnly: true
                    },
                    mmr: {
                      readOnly: true
                    },
                    repUser: {
                      readOnly: true
                    }
                  }
                }
              }
            }
          })
        });
      } catch (err) {
        this.set('adtStepReady', true);
        throw err;
      }

      try {
        var centralStationService = this.centralStationService;

        var estimated_completion_date = _lodash.default.get(hashData.adtOrder, 'ny.estimated_completion_date', false);

        var messages = [];
        var estimated_completion_date_str = '';

        if (estimated_completion_date) {
          estimated_completion_date_str = (0, _moment.default)(estimated_completion_date).format('YYYY-MM-DD');
        }

        var response = yield centralStationService.postData({
          model: hashData.customer,
          method: 'adt__saveCreateUpdateOrderData',
          payload: {
            e_contract_bypass: hashData.adtOrder.e_contract_bypass,
            secondary_site_number: hashData.adtOrder.secondary_site_number,
            house_account_reason: hashData.adtOrder.house_account_reason,
            house_account_suppress_qsp: hashData.adtOrder.house_account_suppress_qsp,
            tracking_pin: hashData.adtOrder.tracking_pin,
            credit_holder_authentication_number: hashData.adtOrder.credit_holder_authentication_number,
            overwrite_credit_holder_name: hashData.adtOrder.overwrite_credit_holder_name,
            lead_source: hashData.adtOrder.lead_source,
            affinity_member_number: hashData.adtOrder.affinity_member_number,
            credit_holder_physical_address_data: {
              chpa_address_line_1: _lodash.default.get(hashData.adtOrder, 'credit_holder_physical_address_data.chpa_address_line_1', ''),
              chpa_address_line_2: _lodash.default.get(hashData.adtOrder, 'credit_holder_physical_address_data.chpa_address_line_2', ''),
              chpa_city: _lodash.default.get(hashData.adtOrder, 'credit_holder_physical_address_data.chpa_city', ''),
              chpa_state: _lodash.default.get(hashData.adtOrder, 'credit_holder_physical_address_data.chpa_state', ''),
              chpa_zip_five_or_nine: _lodash.default.get(hashData.adtOrder, 'credit_holder_physical_address_data.chpa_zip_five_or_nine', '')
            },
            auto_draft_equipment_cost: hashData.adtOrder.auto_draft_equipment_cost,
            equipment_panel: hashData.adtOrder.equipment_panel,
            radio_primary: hashData.adtOrder.radio_primary,
            radio_type: hashData.adtOrder.radio_type,
            install_type: hashData.adtOrder.install_type,
            sop_deposit_amount: hashData.adtOrder.sop_deposit_amount,
            estimated_completion_date: hashData.adtOrder.estimated_completion_date,
            add_on_services: hashData.adtOrder.add_on_services,
            ny: {
              estimated_completion_date: estimated_completion_date_str,
              // "string" (date field)
              deposit_account: _lodash.default.get(hashData.adtOrder, 'ny.deposit_account', ''),
              // "string" (free form)
              down_payment: _lodash.default.get(hashData.adtOrder, 'ny.down_payment', ''),
              // "string" (dollar amount)
              amount_due: _lodash.default.get(hashData.adtOrder, 'ny.amount_due', '') // "string" (dollar amount)

            },
            system_move: hashData.adtOrder.system_move,
            system_move_order_id: hashData.adtOrder.system_move_order_id
          }
        });
        messages = messages.concat(response.responsePayload.data.messages);
        yield this.refreshCustomerAdtStatus.perform(hashData.customer, hashData.adtData);
        yield this.get('customer.customerContacts').reload(); //need to update customerContacts on success too, or they don't show up

        yield this.get('customer.customerSystemInformation').reload(); ///////////////////////////////////////////////
        // need to re-get the discrepencies

        yield this.refreshAdtFormAndDiscrepencies.perform();
        buttonPromise.then(() => {
          if (this.isNewRecord) {
            this.stNotificationManager.addSuccess(this.intl.t('common.notifications.save_successful'));
            this.set('formMode', 'edit');
            multiStepForm.setupCopyModels.perform();
          } else {
            this.stNotificationManager.addSuccess(this.intl.t('common.notifications.save_successful'), 'adtMegaFormModal');
            multiStepForm.setupCopyModels.perform();
          }

          if (messages.length > 0) {
            this.stNotificationManager.addWarningMessages(messages, {
              notificationNamespace: 'adtMegaFormModal'
            });
          }

          this.set('adtStepReady', true);
        });
      } catch (err) {
        yield this.get('customer.customerContacts').reload(); //make sure any changes to customer contacts that were saved are reflected in mega form.

        this.set('adtStepReady', true);
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'adtMegaFormModal'
        });
        (false && Ember.warn('mega form save error' + err, {
          id: 'multiStepForm.forms.system.adt.editCreateOrderInformation.save'
        }));
        throw new Error(err);
      }

      this.set('adtStepReady', true);
    })
  });

  _exports.default = _default;
});