define("securitytrax-ember-ui/demo/st-await/controller", ["exports", "ember-concurrency", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _emberConcurrency, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller.extend({
    store: Ember.inject.service(),
    stAwait: Ember.inject.service(),
    namespace1Name: undefined,
    namespace1Fullyloaded: undefined,
    namespace1Haserror: undefined,
    namespace3Boolean: false,
    namespace4Boolean: false,
    namespace5Boolean: false,
    waitingNamespace1_1: false,
    waitingNamespace1_2: false,
    waitingNamespace1_3: false,
    waitingNamespace1_4: false,
    waitingNamespace2_1: false,
    waitingNamespace2_2: false,
    waitingNamespace3_1: false,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        namespace1Name: 'demoNamespace1',
        namespace1Fullyloaded: false,
        namespace1Haserror: false
      });
      this.stAwait.on('st-await-loaded-state-by-namespace-' + this.namespace1Name, this, '_onLoadedStateChange');
    },

    willDestroyElement() {
      this._super(...arguments);

      this.stAwait.off('st-await-loaded-state-by-namespace-' + this.namespace1Name, this, '_onLoadedStateChange');
    },

    _onLoadedStateChange(loadedData) {
      Ember.run.next(() => {
        this.set('namespace1Fullyloaded', loadedData.fullyLoaded);
        this.set('namespace1Haserror', loadedData.hasError);
      });
    },

    waitingNamespace1_1_computed: Ember.computed(function () {
      return this.waitNamespaceTask.perform('waitingNamespace1_1');
    }),
    waitingNamespace1_2_computed: Ember.computed(function () {
      return this.waitNamespaceTask.perform('waitingNamespace1_2');
    }),
    waitingNamespace1_3_computed: Ember.computed(function () {
      return this.waitNamespaceTask.perform('waitingNamespace1_3');
    }),
    waitingNamespace1_4_computed: Ember.computed(function () {
      return this.waitNamespaceTask.perform('waitingNamespace1_4');
    }),
    waitingNamespace2_1_computed: Ember.computed(function () {
      return this.waitNamespaceTask.perform('waitingNamespace2_1');
    }),
    waitingNamespace2_2_computed: Ember.computed(function () {
      return this.waitNamespaceTask.perform('waitingNamespace2_2');
    }),
    waitingNamespace3_1_computed: Ember.computed(function () {
      return this.waitNamespaceTask.perform('waitingNamespace3_1');
    }),
    waitNamespaceTask: (0, _emberConcurrency.task)(function* (waitingNamespace) {
      yield (0, _emberConcurrency.waitForProperty)(this, waitingNamespace, true);
      return waitingNamespace + ' resolved!';
    }),
    resolvePromiseWDelay: (0, _emberConcurrency.task)(function* (promiseName, buttonPromise) {
      yield this.timeoutTask.perform();
      buttonPromise.then(() => {
        this.set(promiseName, true);
      });
    }),
    resetPromiseWDelay: (0, _emberConcurrency.task)(function* (promiseName, buttonPromise) {
      yield this.timeoutTask.perform();
      buttonPromise.then(() => {
        this.set(promiseName, false);
        this.notifyPropertyChange(promiseName + '_computed');
      });
    }),
    cancelPromiseWDelay: (0, _emberConcurrency.task)(function* (promiseName, buttonPromise) {
      yield this.timeoutTask.perform();
      buttonPromise.then(() => {
        this.get(promiseName + '_computed').cancel();
      });
    }),
    setBooleanWDelay: (0, _emberConcurrency.task)(function* (booleanName, value, buttonPromise) {
      yield this.timeoutTask.perform();
      buttonPromise.then(() => {
        this.set(booleanName, value);
      });
    }),
    timeoutTask: (0, _emberConcurrency.task)(function* (waitTime = 3000) {
      yield new Ember.RSVP.Promise(fulfill => {
        setTimeout(function () {
          fulfill();
        }, waitTime);
      });
    }),
    actions: {
      resolvePromise(promiseName, buttonPromise) {
        return this.resolvePromiseWDelay.perform(promiseName, buttonPromise);
      },

      resetPromise(promiseName, buttonPromise) {
        return this.resetPromiseWDelay.perform(promiseName, buttonPromise);
      },

      cancelPromise(promiseName, buttonPromise) {
        return this.cancelPromiseWDelay.perform(promiseName, buttonPromise);
      },

      setBoolean(booleanName, value, buttonPromise) {
        return this.setBooleanWDelay.perform(booleanName, value, buttonPromise);
      }

    }
  });
  var _default = Controller;
  _exports.default = _default;
});