define("securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/re-usable-forms/user-agreement/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AXrLb1hr",
    "block": "{\"symbols\":[\"@userAgreed\",\"@onUserAgreed\",\"@agreementText\"],\"statements\":[[10,\"div\"],[14,0,\"grid-x grid-padding-x grid-padding-y user-agreement\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cell agreement\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"agreement__checkbox\"],[12],[2,\"\\n            \"],[8,\"multi-step-form/switchable-inputs/checkbox-input\",[],[[\"@formMode\",\"@value\",\"@toggleSwitch\",\"@onChange\"],[\"edit\",[32,1],false,[32,2]]],null],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[12],[2,\"\\n            \"],[2,[32,3]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/re-usable-forms/user-agreement/template.hbs"
    }
  });

  _exports.default = _default;
});