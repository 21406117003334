define("securitytrax-ember-ui/components/lists/data-list/list-item/component", ["exports", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['actionClass', 'wrapClass'],

    /**
     * Setting the class name for the list item if there is an action passed in
     *
     * @function
     * @returns {String}
     */
    actionClass: Ember.computed('clickAction', function () {
      var classWrapper = '';

      if (this.clickActionPassed === true) {
        classWrapper = 'actionable-row pointer';
      }

      return classWrapper;
    }),
    labelClass: undefined,

    /**
     * If there is a click action passed in, this will set to false and prohibit actions above to fire
     *
     * @function
     * @returns {Boolean}
     */
    bubbles: Ember.computed('clickAction', 'bubblesUp', function () {
      if (this.clickActionPassed === true || this.bubblesUp === false) {
        return false;
      } else {
        return true;
      }
    }),

    click() {
      if (!document.getSelection().toString()) {
        this.clickAction();
        return this.bubbles;
      }
    },

    /**
     * If you don't want actions to buble up to a list item above this, set this to false 
     *
     * @type {Boolean}
     * @default true
     */
    bubblesUp: undefined,

    /**
     * If an action needs to happen when clicking on this list item, it will be passed in through here
     *
     * @function
     * @returns {String}
     */
    clickAction: undefined,

    /**
     * This is the boolean set on init that shows if a clickAction was passed in.
     *
     * @type {Boolean}
     * @default ""
     */
    clickActionPassed: undefined,

    /**
     * This is bound to the class. This will change the css depending on what they want to use it for in the list
     *
     * @function
     * @returns {String}
     */
    wrapClass: Ember.computed('wrap', function () {
      var wrapClass;

      switch (this.wrap) {
        case 'body-row':
          wrapClass = 'd-l-row align-justify grid-x';
          break;

        case 'body-item':
          wrapClass = 'cell d-l-body-item';
          break;

        case 'group-item':
          wrapClass = 'd-l-body-item d-l-group-item';
          break;

        case 'group-header':
          wrapClass = 'd-l-row d-l-group-header grid-x align-justify';
          break;
      }

      return wrapClass;
    }),

    init() {
      this._super(...arguments);

      var initializeObject = {};

      if (typeof this.bubblesUp === 'undefined') {
        initializeObject['bubblesUp'] = true;
      }

      if (typeof this.clickAction === 'undefined') {
        this.set('clickActionPassed', false);

        initializeObject['clickAction'] = function () {};
      } else {
        this.set('clickActionPassed', true);
      }

      (0, _objHelpers.initializeState)(this, initializeObject);
    },

    didReceiveAttrs() {
      this._super(...arguments);

      if (this.wrap === 'body-item' && !this.label && !this.noLabel) {
        throw new Error('You must pass a label into a data list body item.');
      }
    }

  });

  _exports.default = _default;
});