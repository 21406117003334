define("securitytrax-ember-ui/models/scheduling-profile-default", ["exports", "securitytrax-ember-ui/models/base_models/scheduling-profile-default"], function (_exports, _schedulingProfileDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _schedulingProfileDefault.default.extend({});

  _exports.default = _default;
});