define("securitytrax-ember-ui/login/route", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin", "ember-concurrency", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _unauthenticatedRouteMixin, _emberConcurrency, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, _routePageviewLogging.default, {
    stAjax: Ember.inject.service(),
    sessionData: Ember.inject.service(),

    setupController(controller) {
      this._super(...arguments);

      this.companyNameTask.perform(controller);
    },

    companyNameTask: (0, _emberConcurrency.task)(function* (controller) {
      var pubCompany = yield this.sessionData.getPublicCompanyInfo();
      controller.set('companyName', pubCompany.name);
      controller.set('authData', pubCompany.identity_provider_authentication_data);
    })
  });

  _exports.default = _default;
});