define("securitytrax-ember-ui/models/customer-funding", ["exports", "securitytrax-ember-ui/models/base_models/customer-funding"], function (_exports, _customerFunding) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerFunding.default.extend({
    fundGrandTotal: Ember.computed('fundAmount', 'fundAmountHold', function () {
      var total = Number(this.fundAmount) + Number(this.fundAmountHold);
      return total.toFixed(2);
    })
  });

  _exports.default = _default;
});