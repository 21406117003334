define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/forms/adt/work-order/edit-work-order/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2LPz7Nmy",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[17,1],[12],[2,\"\\n    \"],[8,\"multi-step-form/progress-bar\",[],[[\"@config\",\"@modalButtons\",\"@loadedNamespaces\"],[[34,2,[\"progressBarConfig\"]],[34,2,[\"currentStepData\",\"buttons\"]],[30,[36,4],[[35,3],\"step5\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"html/st-await\",[],[[\"@useSpinner\",\"@size\",\"@loadedNamespace\",\"@promises\"],[true,\"large\",[30,[36,4],[[35,3],\"step5\"],null],[30,[36,6],null,[[\"setupWorkOrderStep\"],[[35,5]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[35,3],\"customerCreateWizard\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[8,\"customer/warnings-card\",[[24,0,\"customer-warnings--margin-bottom\"]],[[\"@customer\",\"@showWarnings\"],[[34,0],[34,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n            \"],[8,\"customer/work-orders\",[],[[\"@multiStepForm\",\"@formMode\",\"@customerEquipment\",\"@isNewRecord\",\"@customer\",\"@doneAction\",\"@stepButtons\",\"@singleStep\",\"@onInit\"],[[34,2],\"edit\",[34,9],false,[34,0],[30,[36,11],[[35,10]],null],[34,12],\"workOrder::editWorkOrder\",[30,[36,13],[[32,0],\"afterWorkOrderSetup\"],null]]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"newCustomer\",\"showWarnings\",\"multiStepForm\",\"currFormName\",\"concat\",\"setupWorkOrderStep\",\"hash\",\"eq\",\"if\",\"customerEquipment\",\"afterWorkOrderSavedTask\",\"perform\",\"stepButtons\",\"action\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/forms/adt/work-order/edit-work-order/template.hbs"
    }
  });

  _exports.default = _default;
});