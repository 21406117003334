define("securitytrax-ember-ui/validations/recurring-invoice", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation", "moment", "lodash"], function (_exports, _validate, _baseValidation, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _baseValidation.default.extend({
    validate(data) {
      var noValidate = _lodash.default.get(data, 'extraValidationData.noValidate', false);

      _validate.default.validators.dateIsTomorrowOrAfter = function (value, options, key, attributes) {
        var theDatetime = (0, _moment.default)(attributes[key]);
        var tomorrow = (0, _moment.default)().add(1, 'day').startOf('day');

        if (!theDatetime.isSameOrAfter(tomorrow) && !(noValidate && noValidate.includes('invoice-saleDate-inPast'))) {
          return options.message;
        }

        return null;
      };

      _validate.default.validators.emailList = function (value) {
        var invalidEmails = value.filter(email => {
          if (!email.match(/.*@.*\..*/)) {
            return true;
          }
        });

        if (invalidEmails.length > 0) {
          return data.intlService.t('common.validations.emailList', {
            'invalidEmails': invalidEmails.join(', ')
          });
        }

        return null;
      };

      _validate.default.validators.numTimesToRecurPositive = function (value, options) {
        if (value < 1) {
          return options.message;
        }

        return null;
      };

      var validateFieldsObject = {
        entityType: {
          presence: {
            allowEmpty: false
          }
        },
        invoiceType: {
          presence: {
            allowEmpty: false
          }
        },
        saleDate: {
          presence: {
            allowEmpty: false
          },
          dateIsTomorrowOrAfter: () => {
            if (noValidate && noValidate.includes('invoice-saleDate')) {
              return false;
            } else {
              return {
                message: function ()
                /*value, attribute, validatorOptions, attributes, globalOptions*/
                {
                  return '^' + data.intlService.t('common.validations.inTheFuture', {
                    propertyLabel: data.intlService.t("pages.customers.accounting.invoices.startDate")
                  });
                }
              };
            }
          }
        },
        invoiceTerm: {
          presence: {
            allowEmpty: false
          }
        },
        recurringIntervalNumber: {
          presence: {
            allowEmpty: false
          }
        },
        recurringIntervalPeriod: {
          presence: {
            allowEmpty: false
          }
        },
        invoiceLineItems: {
          presence: this.customMessageOrDefault(data, 'lineItems')
        },
        numTimesToRecur: {
          presence: () => {
            if (noValidate && noValidate.includes('invoice-numTimesToRecur')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'invoice-numTimesToRecur');
            }
          },
          numTimesToRecurPositive: () => {
            if (noValidate && noValidate.includes('invoice-numTimesToRecur')) {
              return false;
            } else {
              return {
                message: function ()
                /*value, attribute, validatorOptions, attributes, globalOptions*/
                {
                  return '^' + data.intlService.t('pages.customers.accounting.invoices.numTimesToRecurPositive');
                }
              };
            }
          }
        }
      };

      if (this.recurringEmail) {
        validateFieldsObject['recurringEmail'] = {
          emailList: this.customMessageOrDefault(data, 'invoice-recurringEmail')
        };
      }

      var jsonObject = this.objectToJson(this);

      var copiedObject = _objectSpread({}, jsonObject);

      if (data && data.extraValidationData && data.extraValidationData.newLineItems) {
        copiedObject.invoiceLineItems = [...jsonObject.invoiceLineItems, ...data.extraValidationData.newLineItems];
      }

      var validationData = (0, _validate.default)(copiedObject, validateFieldsObject);
      return this.outputFormatting(validationData, 'invoice');
    }

  });

  _exports.default = _default;
});