define("securitytrax-ember-ui/components/multi-step-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Agl0ar/P",
    "block": "{\"symbols\":[\"data\",\"data\",\"data\",\"&default\",\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[30,[36,2],[\"multi-step-form\",[32,0,[\"args\",\"classNames\"]]],null]],[17,5],[12],[2,\"\\n\"],[6,[37,3],[[32,0,[\"formStarted\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,1],null,[[\"promises\"],[[30,[36,0],null,[[\"_currentStepData\"],[[32,0,[\"_currentStepData\"]]]]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[3]}]]],[2,\"\\n\"],[6,[37,1],null,[[\"promises\"],[[30,[36,0],null,[[\"updatedOriginalModels\"],[[32,0,[\"updatedOriginalModels\"]]]]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[2]}]]],[2,\"\\n\"],[6,[37,1],null,[[\"promises\"],[[30,[36,0],null,[[\"formSetup\"],[[32,0,[\"formSetup\"]]]]]]],[[\"default\"],[{\"statements\":[],\"parameters\":[1]}]]],[2,\"\\n        \"],[18,4,[[32,0]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"html/st-await\",\"concat\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/template.hbs"
    }
  });

  _exports.default = _default;
});