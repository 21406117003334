define("securitytrax-ember-ui/components/multi-step-form/forms/administration/account-management/invoices/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "moment", "ember-concurrency"], function (_exports, _switchableFormStep, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    selectedId: undefined,

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      var buttons = [];
      buttons.push({
        text: 'confirm',
        click: buttonPromise => {
          return this.payInvoiceTask.perform(buttonPromise, multiStepForm);
        },
        classNamesString: 'st-btn st-btn-primary'
      });
      multiStepForm.registerStepData('paymentMethodFormStep', 'buttons', buttons);
      var invoiceDate = {};
      var descriptionKey = 'pages.administration.account_management.invoices.pay_modal_title';

      if (this.currentInvoice) {
        invoiceDate.invoiceDate = (0, _moment.default)(this.currentInvoice.dueDate).format('MMMM D, YYYY');
      }

      multiStepForm.registerStepData('paymentMethodFormStep', 'title', this.intl.t(descriptionKey, invoiceDate));
    },

    initFormTask: (0, _emberConcurrency.task)(function* () {
      var paymentList = yield this.store.findAll('accountBilling');
      var defaultPaymentArray = paymentList.filter(payment => {
        return payment.get('isAccountDefault');
      });
      this.set('selectedId', defaultPaymentArray[0].get('id'));
    }).on('init'),
    payInvoiceTask: (0, _emberConcurrency.task)(function* (buttonPromise) {
      try {
        yield this.currentInvoice.invokeAction('pay_invoice', {
          verb: 'post',
          data: {
            data: {
              account_billing_id: this.selectedId
            }
          }
        });
        yield this.store.findAll('accountInvoice', {
          reload: true
        });
        buttonPromise.then(() => {
          this.multiStepForm.formStartedSetVal(false);
          this.stNotificationManager.addSuccessKey('common.notifications.payment_successful');
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'invoiceModal'
        });
        throw err;
      }
    }),
    paymentListTask: (0, _emberConcurrency.task)(function* () {
      var paymentList = yield this.store.findAll('accountBilling');
      var packageArray = [];
      paymentList.forEach(payment => {
        packageArray.push(Ember.Object.create({
          packageName: payment.get('title'),
          packageId: payment.get('id'),
          packageContent: [{
            label: this.intl.t('common.columns.default'),
            description: payment.get('isAccountDefault') ? this.intl.t('common.status.yes') : this.intl.t('common.status.no')
          }]
        }));
      });
      return packageArray;
    }),
    paymentList: Ember.computed(function () {
      return this.paymentListTask.perform();
    })
  });

  _exports.default = _default;
});