define("securitytrax-ember-ui/customers/work-orders/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v1KFpxZ4",
    "block": "{\"symbols\":[],\"statements\":[[8,\"pages/customers/customer-work-orders\",[],[[\"@model\"],[[30,[36,1],null,[[\"customer\",\"tags\",\"customerEquipment\",\"calendarEventTypes\",\"calendarEventDetailTypes\",\"workOrderActions\"],[[35,0,[\"customer\"]],[35,0,[\"tags\"]],[35,0,[\"customerEquipment\"]],[35,0,[\"CEData\",\"calendarEventTypes\",\"actualResponse\"]],[35,0,[\"CEData\",\"calendarEventDetailTypes\",\"actualResponse\"]],[35,0,[\"CEData\",\"workOrderActions\",\"actualResponse\"]]]]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"hash\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/customers/work-orders/template.hbs"
    }
  });

  _exports.default = _default;
});