define("securitytrax-ember-ui/services/inventory-service", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    stAjax: Ember.inject.service(),
    grants: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    getInventoryRecipients: (0, _emberConcurrency.task)(function* (additionalUserQuery = '', additionalLocationQuery = '') {
      var users = [];
      var grants = yield this.grants;
      var canViewUsers = yield grants.get('getIndividualGrantAsync').perform('inventory_users.view');
      var locations = yield this.store.query('location', {
        filter: 'inventory_recipient = true' + additionalLocationQuery,
        sort: 'name',
        include: 'equipment_vendor_to_recipients.equipment_vendor',
        limit: 0
      });

      if (canViewUsers) {
        users = yield this.store.query('inventoryUser', {
          filter: 'inventory_recipient = true' + additionalUserQuery,
          sort: 'lname,fname',
          include: 'equipment_vendor_to_recipients.equipment_vendor',
          limit: 0
        });
      }

      return users.toArray().concat(locations.toArray());
    }),
    getConnectorOptionsTask: (0, _emberConcurrency.task)(function* () {
      var company = yield this.sessionData.getCompany();
      var connectors = yield company.invokeAction('get_registered_vendor_connectors');
      return connectors.responsePayload.data;
    }),
    getInventorySinkClasses: (0, _emberConcurrency.task)(function* () {
      var company = yield this.sessionData.getCompany();
      var classes = yield company.invokeAction('get_sink_classes', {
        method: 'GET'
      });
      return classes.responsePayload.data;
    }),
    getInventoryEquipmentTask: (0, _emberConcurrency.task)(function* (include = '', sort = 'name', filter = ['active=true']) {
      var store = this.store;
      var hashData = yield (0, _emberConcurrency.hash)({
        equipment: store.query('equipment', {
          limit: 0,
          filter,
          include,
          sort
        }),
        equipmentKits: store.findAll('equipmentKit', {
          include
        })
      });
      var activeKits = hashData.equipmentKits.filter(kit => {
        return kit.get('active');
      });
      var returnData = hashData.equipment.toArray().concat(activeKits.toArray());
      return returnData;
    })
  });

  _exports.default = _default;
});