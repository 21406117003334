define("securitytrax-ember-ui/components/pages/home/at-risk/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixed-in-functions/grouped-property-sections", "lodash", "securitytrax-ember-ui/mixins/table-column-sort", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _emberConcurrency, _groupedPropertySections, _lodash, _tableColumnSort, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableColumnSort.default, {
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    session: Ember.inject.service(),
    stFiltersService: Ember.inject.service(),
    homeDashboard: Ember.inject.service('events/home-dashboard'),
    tagName: '',
    limit: 50,
    page: 1,
    showNotification: true,
    isResolved: undefined,
    isClosed: undefined,
    appliedFiltersCount: 0,
    recordCount: 0,

    init() {
      this._super(...arguments);

      this.homeDashboard.on('refresh-at-risk-entries', this, '_eventCallback');
      (0, _objHelpers.initializeState)(this, {
        stSortableColumnOptions: [{
          name: this.intl.t('common.columns.status') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'is_resolved',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.status') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-is_resolved',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('common.columns.created_date') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'created_at',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.created_date') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-created_at',
          chevronDirection: 'down'
        }]
      });
    },

    willDestroy() {
      this.homeDashboard.off('refresh-at-risk-entries', this, '_eventCallback');
    },

    _eventCallback() {
      Ember.notifyPropertyChange(this, 'myAtRisksData');
    },

    trueFalseOptions: Ember.computed(function () {
      return [{
        value: true,
        name: this.intl.t('common.confirm.true')
      }, {
        value: false,
        name: this.intl.t('common.confirm.false')
      }];
    }),
    myAtRisksData: Ember.computed('stSortString', function () {
      return this.myAtRisksTask.perform();
    }),
    myAtRisksTask: (0, _emberConcurrency.task)(function* () {
      var newFilters = yield this.stFiltersService.getFiltersByGroupKey('homeAtRisk', ['assigned_user = ' + this.get('model.user.id'), "(closed_at >= '".concat(moment().subtract(7, 'days').format('YYYY-MM-DD'), "' or closed_at = null)")]);
      var queryParams = {
        filter: newFilters,
        sort: this.stSortString ? this.stSortString : 'follow_up_date',
        include: 'customer,customer_at_risk_reason',
        limit: this.limit,
        page: this.page
      };

      if (this.page == 1) {
        queryParams['count'] = 1;
      }

      var atRisks = yield this.store.query('customerAtRisk', queryParams);
      Ember.set(this, 'recordCount', _lodash.default.get(atRisks, 'meta.record_count', this.recordCount));
      return {
        atRisks: Ember.A([Ember.Object.create({
          name: this.intl.t('pages.customers.at_risk_entries.open'),
          collapsable: true,
          collapsed: false,
          collapseIconName: 'icn-chevron-up',
          icon: 'icn-circle-o',
          sections: (0, _groupedPropertySections.default)(),
          items: atRisks.filter(atRiskItem => {
            return !atRiskItem.get('isClosed');
          })
        }), Ember.Object.create({
          name: this.intl.t('pages.home.recently_closed'),
          collapsable: true,
          collapsed: false,
          collapseIconName: 'icn-chevron-up',
          icon: 'icn-circle-check',
          sections: (0, _groupedPropertySections.default)(),
          items: atRisks.filter(atRiskItem => {
            return atRiskItem.get('isClosed');
          })
        })]),
        record_count: this.recordCount
      };
    }),
    actions: {
      transitionToSubroute(path) {
        this.router.transitionTo('home.at-risk.edit-at-risk', path);
      },

      updateMyAtRisks() {
        Ember.notifyPropertyChange(this, 'myAtRisksData');
      },

      sortList(column, ascending) {
        this.sortPaginatedList(column, ascending);
      },

      mobileSortList(column) {
        this.sortMobilePaginatedList(column.value);
      },

      filterSearch() {
        this.set('page', 1);
        Ember.notifyPropertyChange(this, 'myAtRisksData');
      },

      clearFilterSearch() {
        Ember.setProperties(this, {
          page: 1,
          isClosed: undefined,
          isResolved: undefined
        });
        Ember.notifyPropertyChange(this, 'myAtRisksData');
        Ember.notifyPropertyChange(this, 'page');
      }

    }
  });

  _exports.default = _default;
});