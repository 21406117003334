define("securitytrax-ember-ui/models/base_models/customer", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    fname: (0, _model.attr)('string', {
      'readOnly': false
    }),
    lname: (0, _model.attr)('string', {
      'readOnly': false
    }),
    spouse: (0, _model.attr)('string', {
      'readOnly': false
    }),
    address1: (0, _model.attr)('string', {
      'readOnly': false
    }),
    address2: (0, _model.attr)('string', {
      'readOnly': false
    }),
    city: (0, _model.attr)('string', {
      'readOnly': false
    }),
    state: (0, _model.attr)('string', {
      'readOnly': false
    }),
    zip: (0, _model.attr)('string', {
      'readOnly': false
    }),
    created: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    parentEntityId: (0, _model.attr)('number', {
      'readOnly': false
    }),
    billWithParentEntity: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    status: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['active', 'deleted']
    }),
    saleDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    originalInstallStartTime: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    contactTime: (0, _model.attr)('string', {
      'readOnly': false
    }),
    crossStreet: (0, _model.attr)('string', {
      'readOnly': false
    }),
    creditTracking: (0, _model.attr)('string', {
      'readOnly': false
    }),
    county: (0, _model.attr)('string', {
      'readOnly': false
    }),
    email: (0, _model.attr)('string', {
      'readOnly': false
    }),
    selltoCompanyUniqueId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    businessName: (0, _model.attr)('string', {
      'readOnly': false
    }),
    businessContact: (0, _model.attr)('string', {
      'readOnly': false
    }),
    accountType: (0, _model.attr)('string', {
      'readOnly': false
    }),
    paymentReceived: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    serviceMaintenance: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    servicePulse: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    serviceTellular: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    serviceAarp: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    fundNocDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    isStarred: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    timezone: (0, _model.attr)('string', {
      'readOnly': false
    }),
    latitude: (0, _model.attr)('number', {
      'readOnly': false
    }),
    longitude: (0, _model.attr)('number', {
      'readOnly': false
    }),
    adcLeadId: (0, _model.attr)('number', {
      'readOnly': false
    }),
    lang: (0, _model.attr)('string', {
      'readOnly': false
    }),
    adtNapeSerialNumber: (0, _model.attr)('number', {
      'readOnly': false
    }),
    analyticsType: (0, _model.attr)('string', {
      'readOnly': false
    }),
    analyticsId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    recordType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['customer', 'lead']
    }),
    homeOwnership: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['own', 'rent', 'lease', 'other', 'model']
    }),
    primaryPhone: (0, _model.attr)('string', {
      'readOnly': false
    }),
    workPhone: (0, _model.attr)('string', {
      'readOnly': false
    }),
    cellPhone: (0, _model.attr)('string', {
      'readOnly': false
    }),
    otherPhone: (0, _model.attr)('string', {
      'readOnly': false
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    customerSystemInformation: (0, _model.belongsTo)('customer-system-information', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'stEmbedded': 'create'
    }),
    customerOrderInformation: (0, _model.belongsTo)('customer-order-information', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'stEmbedded': 'create'
    }),
    leadInformation: (0, _model.belongsTo)('lead-information', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'stEmbedded': 'create'
    }),
    leadSourceInformation: (0, _model.belongsTo)('lead-source-information', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'stEmbedded': 'create'
    }),
    customerSsn: (0, _model.belongsTo)('customer-ssn', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'stEmbedded': 'create'
    }),
    customerAbortCode: (0, _model.belongsTo)('customer-abort-code', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    customerBirthdate: (0, _model.belongsTo)('customer-birthdate', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'stEmbedded': 'create'
    }),
    repUser: (0, _model.belongsTo)('user', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    customerLeadCompany: (0, _model.belongsTo)('customer-lead-company', {
      'readOnly': false,
      'readOnlyUpdate': true
    }),
    leadCompany: (0, _model.belongsTo)('lead-company', {
      'readOnly': false,
      'readOnlyUpdate': true
    }),
    selltoLeadCompany: (0, _model.belongsTo)('customer-lead-company', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    location: (0, _model.belongsTo)('location', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    adtMonitoringSystemType: (0, _model.belongsTo)('adt-monitoring-system-type', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    customerCreditCheck: (0, _model.belongsTo)('customer-credit-check', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    duplicateOfCustomer: (0, _model.belongsTo)('customer', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    defaultCustomerBilling: (0, _model.belongsTo)('customer-billing', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    accountClass: (0, _model.belongsTo)('account-class', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    leadDisposition: (0, _model.belongsTo)('lead-disposition', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    warnings: (0, _model.belongsTo)('object-warning', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    agencyContacts: (0, _model.hasMany)('agency-contact', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    tags: (0, _model.hasMany)('tag', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    fundings: (0, _model.hasMany)('funding', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    customerAccountingInformation: (0, _model.hasMany)('customer-accounting-information', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    customerPayables: (0, _model.hasMany)('customer-payable', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': 'customer'
    }),
    payablePayeeLevels: (0, _model.hasMany)('payable-payee-level', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    customerContacts: (0, _model.hasMany)('customer-contact', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    leadContacts: (0, _model.hasMany)('lead-contact', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    customerCreditChecks: (0, _model.hasMany)('customer-credit-check', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': 'customer'
    }),
    customerNotes: (0, _model.hasMany)('customer-note', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    workOrders: (0, _model.hasMany)('work-order', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    customerTasks: (0, _model.hasMany)('customer-task', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    customerBillings: (0, _model.hasMany)('customer-billing', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': 'customer'
    }),
    customerMessages: (0, _model.hasMany)('customer-message', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    calendarEvents: (0, _model.hasMany)('calendar-event', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    customerFiles: (0, _model.hasMany)('customer-file', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    leadFiles: (0, _model.hasMany)('lead-file', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    customerEquipment: (0, _model.hasMany)('customer-equipment', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    customerPayments: (0, _model.hasMany)('customer-payment', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    invoices: (0, _model.hasMany)('invoice', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    invoiceStatements: (0, _model.hasMany)('invoice-statement', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    refundReceipts: (0, _model.hasMany)('invoice', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    creditMemos: (0, _model.hasMany)('invoice', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    leadMessages: (0, _model.hasMany)('lead-message', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    quotes: (0, _model.hasMany)('invoice', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    customerAtRisks: (0, _model.hasMany)('customer-at-risk', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': 'customer'
    }),
    governmentRegulations: (0, _model.hasMany)('government-regulation', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    recurringInvoices: (0, _model.hasMany)('recurring-invoice', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    logs: (0, _model.hasMany)('log', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    electronicDocumentEnvelopes: (0, _model.hasMany)('electronic-document-envelope', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    messageLogs: (0, _model.hasMany)('message-log', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    udfs: (0, _model.hasMany)('udf', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    consumerFinancingApplicants: (0, _model.hasMany)('consumer-financing-applicant', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    consumerFinancingApplications: (0, _model.hasMany)('consumer-financing-application', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    coapplicantConsumerFinancingApplications: (0, _model.hasMany)('consumer-financing-application', {
      'readOnly': true,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});