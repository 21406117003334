define("securitytrax-ember-ui/components/multi-step-form/forms/system/telus/base", ["exports", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "ember-concurrency"], function (_exports, _component, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var TelusBase = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = (0, _emberConcurrency.task)(function* (ignoreCache = false) {
    var subjects = yield this.centralStationService.postData({
      model: this.args.customer,
      rawResponse: false,
      method: 'adt_canada__getCreditVerificationSubjectsForCustomer',
      payload: {
        ignore_cache: ignoreCache
      }
    });
    return subjects.credit_verification_subjects;
  }), _dec5 = (0, _emberConcurrency.task)(function* () {
    try {
      var theTrueLists = [];
      var lists = yield this.centralStationService.postData({
        model: this.args.monitoringCompany,
        method: 'adt_canada__getDealerLoadableLists',
        rawResponse: false
      });
      lists.dealer_loadable_lists.forEach(list => {
        theTrueLists[list.type] = list.items;
      });
      this.loadableLists = theTrueLists;
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
    }
  }), _dec6 = (0, _emberConcurrency.task)(function* (checkFirst = false) {
    this.subjects = yield this.subjectsTask.perform(true);
    this.subjects.forEach(subject => {
      if (checkFirst) {
        Ember.set(subject, 'checked', true);
        this.subjectsChecked = true;
      }

      this.subjectIds[subject.subject_id] = subject.subject_first_name + ' ' + subject.subject_last_name;
    });
  }), (_class = (_temp = class TelusBase extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor, this);

      _initializerDefineProperty(this, "subjects", _descriptor2, this);

      _initializerDefineProperty(this, "loadableLists", _descriptor3, this);

      _initializerDefineProperty(this, "subjectsTask", _descriptor4, this);

      _initializerDefineProperty(this, "getLoadableListsTask", _descriptor5, this);

      _initializerDefineProperty(this, "refreshSubjectsTask", _descriptor6, this);

      this.getLoadableListsTask.perform();
      this.SwitchableFormStep = new _switchableFormStep.default(...arguments, {
        disabledProgressBarSteps: this.disabledProgressBarSteps,
        hiddenProgressBarSteps: this.hiddenProgressBarSteps
      });
      this.SwitchableFormStep.progressBarHidden = this.args.progressBarHidden || false;
    }

    get hiddenProgressBarSteps() {
      var hiddenSteps = [];

      if (this.args.customerState.customer_state.code === 'CreditCheckRequired') {
        hiddenSteps = hiddenSteps.concat(['contract', 'testMode', 'alarmLogs']);
      } else if (this.args.installationInfo.number === '') {
        hiddenSteps = hiddenSteps.concat(['testMode', 'alarmLogs']);
      }

      return hiddenSteps;
    }

    get disabledProgressBarSteps() {
      return [];
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "subjects", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loadableLists", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "subjectsTask", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "getLoadableListsTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "refreshSubjectsTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = TelusBase;
});