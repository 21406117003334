define("securitytrax-ember-ui/customers/accounting/payments-financing/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/route-pageview-logging", "ember-concurrency", "securitytrax-ember-ui/helpers/feature-is-enabled"], function (_exports, _authenticatedRouteMixin, _routePageviewLogging, _emberConcurrency, _featureIsEnabled) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _routePageviewLogging.default, {
    grants: Ember.inject.service(),
    sessionData: Ember.inject.service('sessionData'),
    stNotificationManager: Ember.inject.service(),
    intl: Ember.inject.service(),
    quickAdd: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      this.beforeModelTask.perform();
    },

    beforeModelTask: (0, _emberConcurrency.task)(function* () {
      // BOWLES TODO: Pull feature company enabled value direct from session data with TRAX-12015
      var companyPublicInfo = yield this.sessionData.getPublicCompanyInfo();
      var financingEnabledForCompany = companyPublicInfo && companyPublicInfo.ui_feature_flags.consumer_financing && companyPublicInfo.ui_feature_flags.consumer_financing.enabled;

      if (!(0, _featureIsEnabled.featureIsEnabled)(['consumerFinancing', financingEnabledForCompany])) {
        this.transitionTo('/');
        this.stNotificationManager.addWarning(this.intl.t('common.notifications.no_permission'));
      }

      return this.findGrantsTask.perform(['consumer_financing_applications.view'], 'all', 'customers.accounting', ['consumerFinancing']);
    }),

    model() {
      var {
        customer
      } = this._super(...arguments);

      return this.setupModelTask.perform(customer);
    },

    setupModelTask: (0, _emberConcurrency.task)(function* (customer) {
      return Ember.RSVP.hash({
        customer: yield customer
      });
    }),
    customerController: Ember.computed(function () {
      return this.controllerFor('customers');
    }),

    setupBreadCrumbs() {
      var breadCrumbs = [{
        name: this.intl.t('pages.accounting.title'),
        link: 'customers.accounting'
      }, {
        name: this.intl.t('pages.customers.accounting.financing.title'),
        previousLink: 'customers.accounting',
        last: true
      }];
      var breadCrumbData = {
        titleLink: 'customers.accounting',
        hide: false
      };
      this.customerController.set('breadCrumbs', breadCrumbs);
      this.customerController.set('breadCrumbData', breadCrumbData);
    },

    actions: {
      didTransition() {
        this.setupBreadCrumbs();
        this.quickAdd.set('showQuickAdd', true);
        this.quickAdd.set('page', 'customer');
      }

    }
  });

  _exports.default = _default;
});