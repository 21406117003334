define("securitytrax-ember-ui/services/map-window", ["exports", "lodash", "jquery", "ember-inflector"], function (_exports, _lodash, _jquery, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  window.openWindowJquery = function (context) {
    var jqueryContext = (0, _jquery.default)(context);
    var latitude = jqueryContext.data('latitude');
    var longitude = jqueryContext.data('longitude');
    var label = jqueryContext.data('label');

    if (latitude && longitude) {
      window.openWindow({
        latitude,
        longitude,
        label
      });
    } else {
      var objectId = jqueryContext.data('object_id');
      var resourceType = (0, _emberInflector.pluralize)(jqueryContext.data('resource_type'));
      this.stAjax.request(this.stAjax.getUrl("/".concat(resourceType, "/").concat(objectId, "/actions/geocode_address")), {
        method: 'POST',
        data: {
          data: {}
        }
      }).then(data => {
        window.openWindow({
          latitude: data.data.latitude,
          longitude: data.data.longitude,
          label
        });
      });
    }
  };

  window.openWindow = function (options = {}) {
    var compiledOptions = _lodash.default.merge({
      label: null
    }, options); // const {rootURL} = Ember.getOwner(this).lookup('router:main');
    // DREW TODO - need to use browser/os checking code here to determine where how to open the map
    // window.open(`${rootURL}map/${customerId}`, 'targetWindow', 'toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=500, height=500');


    if (compiledOptions.label != null) {
      compiledOptions.label = encodeURIComponent(compiledOptions.label);
    }

    var protocol = 'https';
    /* if we're on iOS, open in Apple Maps */

    if (navigator.platform.indexOf('iPhone') != -1 || navigator.platform.indexOf('iPod') != -1 || navigator.platform.indexOf('iPad') != -1) {
      protocol = 'maps';
    }

    window.open("".concat(protocol, "://maps.google.com/maps?daddr=").concat(compiledOptions.latitude, ",").concat(compiledOptions.longitude, "&amp;ll="), 'targetWindow');
  };

  var _default = Ember.Service.extend({
    stAjax: Ember.inject.service(),

    init() {
      this._super(...arguments);

      window.stAjax = this.stAjax;
    },

    openWindow(options = {}) {
      return window.openWindow(options);
    }

  });

  _exports.default = _default;
});