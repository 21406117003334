define("securitytrax-ember-ui/components/html/notifications/st-notification-manager/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d0neJiIm",
    "block": "{\"symbols\":[\"notification\"],\"statements\":[[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[30,[36,3],[[35,0],[32,1,[\"namespace\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,2],null,[[\"showNotification\",\"namespace\",\"notification\",\"target\"],[[35,1],[35,0],[32,1],[32,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"namespace\",\"showNotification\",\"html/notifications/st-app-notification\",\"eq\",\"if\",\"notifications\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/notifications/st-notification-manager/template.hbs"
    }
  });

  _exports.default = _default;
});