define("securitytrax-ember-ui/my-account/notifications/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qe6021Na",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"notifications\",\"companySubscriptions\",\"user\",\"model\"],[[35,0,[\"notifications\"]],[35,0,[\"companySubscriptions\"]],[35,0,[\"user\"]],[35,0]]]]]],\"hasEval\":false,\"upvars\":[\"model\",\"pages/my-account/notifications\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/my-account/notifications/template.hbs"
    }
  });

  _exports.default = _default;
});