define("securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/adc/re-usable-forms/panel-equipment/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    csTwoWayVoice: Ember.computed('customerInformation.central_station_info.forwarding_option', function () {
      return this.get('customerInformation.central_station_info.forwarding_option') ? 'Enabled' : 'Disabled';
    }),
    twoWayVoice: Ember.computed('customerInformation.modem_info.network', function () {
      return this.get('customerInformation.modem_info.network') ? 'Enabled' : 'Disabled';
    }),
    phoneLinePresent: Ember.computed('customerInformation.central_station_info.phone_line_present', function () {
      return this.get('customerInformation.central_station_info.phone_line_present') ? 'Present' : 'Not Present';
    }),
    eventGroupsToForward: Ember.computed('customerInformation.central_station_info.event_groups_to_forward', function () {
      var eventGroups = this.get('customerInformation.central_station_info.event_groups_to_forward');
      var formattedGroups = [];

      if (eventGroups && eventGroups.length > 0) {
        eventGroups.forEach(eventGroup => {
          formattedGroups.pushObject(eventGroup.replace(/([A-Z])/g, ' $1').trim());
        });
        return formattedGroups;
      }

      return ['Not Set'];
    })
  });

  _exports.default = _default;
});