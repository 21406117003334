define("securitytrax-ember-ui/models/billing-type", ["exports", "securitytrax-ember-ui/models/base_models/billing-type"], function (_exports, _billingType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _billingType.default.extend({
    isCreditCard: Ember.computed.equal('id', '1'),
    isBankAccount: Ember.computed.equal('id', '2'),
    isManualBilling: Ember.computed.equal('id', '3'),
    code: Ember.computed('name', function () {
      return this.name.underscore();
    })
  });

  _exports.default = _default;
});