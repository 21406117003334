define("securitytrax-ember-ui/customer-list/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/route-pageview-logging", "securitytrax-ember-ui/mixins/scroll-to-top"], function (_exports, _authenticatedRouteMixin, _routePageviewLogging, _scrollToTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _routePageviewLogging.default, _scrollToTop.default, {
    pageTitle: Ember.inject.service(),
    grants: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['customers.view']);
    },

    model() {
      return Ember.RSVP.hash({
        officeLocations: this.store.findAll('location')
      });
    },

    afterModel: function () {
      this.pageTitle.setPageTitle(null, 'common.nav.customers');
    }
  });

  _exports.default = _default;
});