define("securitytrax-ember-ui/components/pages/reports/calendar-event-reports/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eC3o8fod",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"page-content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n\"],[6,[37,4],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[8,\"reports/report-category\",[],[[\"@filter\",\"@categoryTitle\",\"@reportCategories\"],[[30,[36,2],[\"report_category = \",[35,1]],null],[30,[36,0],[\"pages.reports.nav.calendar_event-reports\"],null],[34,3,[\"reportCategories\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[10,\"h4\"],[12],[2,\"\\n                \"],[1,[30,[36,0],[\"common.actions.searchOptions.noMatches\"],null]],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"reportCategoryId\",\"concat\",\"model\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/reports/calendar-event-reports/template.hbs"
    }
  });

  _exports.default = _default;
});