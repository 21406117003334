define("securitytrax-ember-ui/components/html/st-map/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "darL0g5Q",
    "block": "{\"symbols\":[\"data\",\"&default\"],\"statements\":[[1,[30,[36,4],null,[[\"provider\",\"app_id\",\"app_code\",\"onLoading\",\"onLoad\"],[[35,3],[35,2],[35,1],[30,[36,0],[[32,0],\"onLoading\"],null],[30,[36,0],[[32,0],\"onLoad\"],null]]]]],[2,\"\\n\\n\"],[6,[37,6],null,[[\"useSpinner\",\"size\",\"booleanLoaded\"],[true,\"large\",[35,5]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[18,2,null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"app_code\",\"app_id\",\"provider\",\"tile-layer\",\"mapLoaded\",\"html/st-await\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/st-map/template.hbs"
    }
  });

  _exports.default = _default;
});