define("securitytrax-ember-ui/administration/account-management/add-ons/route", ["exports", "securitytrax-ember-ui/administration/account-management/base-route", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _baseRoute, _authenticatedRouteMixin, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_authenticatedRouteMixin.default, _routePageviewLogging.default, {
    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['companies.view_account'], 'all');
    }

  });

  _exports.default = _default;
});