define("securitytrax-ember-ui/components/multi-step-form/forms/work-orders/appointments/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/work-orders/work-orders-base", "securitytrax-ember-ui/mixins/switchable-form-step"], function (_exports, _workOrdersBase, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _workOrdersBase.default.extend(_switchableFormStep.default, {
    hiddenProgressBarSteps: Ember.computed(function () {
      return ['getStarted'];
    }),
    disabledProgressBarSteps: Ember.computed(function () {
      return [];
    })
  });

  _exports.default = _default;
});