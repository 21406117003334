define("securitytrax-ember-ui/services/st-json", ["exports", "lodash", "moment"], function (_exports, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),

    /**
     * Build JSON object from toJSON method below and return string representation
     * 
     * @param {*} object 
     * @param {*} options 
     * @returns {String}
     * 
     * options keys include:
     * - replacer: function to be used in toJSON()
     * - max_depth: Integer to be used in toJSON()
     * 
     */
    stringify(object, options = {}) {
      var json;

      if (typeof object.toJSON === 'function') {
        json = object.toJSON(options);
      } else {
        json = this.toJSON(object, options);
      }

      return JSON.stringify(json);
    },

    /**
     * Recursively traverses object and replaces circular definitions with string path references
     * 
     * @param {*} object 
     * @param {*} options 
     * @returns {Object}
     * 
     * options keys include:
     * - replacer: function(key, value) called for each value in JSON Object to specify if and how the value should be replaced
     * - max_depth: Integer, determines how many relationships deep to traverse, defaults to 2, -1 is full depth
     */
    toJSON(object, options = {}) {
      var objects = new WeakMap(); // object to path mappings

      options = _lodash.default.merge({
        max_depth: 2
      }, options);
      return function derez(key, value, path, relationship_depth) {
        // The derez function recurses through the object, producing the deep copy.
        var old_path; // The path of an earlier occurance of value

        var new_object; // The new object or array
        // If a replacer function was provided, then call it to get a replacement value.

        if (typeof options.replacer === 'function') {
          value = options.replacer(key, value);
        } // typeof null === 'object', so go on if this value is really an object but not
        // one of the builtin objects.


        if (typeof value === 'object' && value !== null && !(value instanceof Boolean) && !(value instanceof Date) && !(value instanceof Number) && !(value instanceof RegExp) && !(value instanceof String)) {
          // If the value is an object or array, look to see if we have already
          // encountered it. If so, return a {'$ref':PATH} object. This uses an
          // ES6 WeakMap.
          old_path = objects.get(value);

          if (old_path !== undefined) {
            return {
              $ref: old_path
            };
          } // Otherwise, accumulate the unique value and its path.


          objects.set(value, path); // If it is an array, replicate the array.

          if (Array.isArray(value)) {
            new_object = [];
            value.forEach(function (element, i) {
              new_object[i] = derez(key, element, path + '[' + i + ']', relationship_depth);
            });
          } else if (_moment.default.isMoment(value)) {
            return {
              _isMoment: true,
              _momentValue: value.toJSON()
            };
          } else {
            // If it is an object, replicate the object.
            new_object = {};
            Object.keys(value).forEach(function (name) {
              new_object[name] = derez(name, value[name], path + '[' + JSON.stringify(name) + ']', relationship_depth);
            });
          }

          return new_object;
        }

        return value;
      }('root', object, '$', 0);
    },

    /**
     * Take string produced by st-json stringify convert to JSON Object then circular references restored
     * 
     * @param {String} jsonStr 
     * @param {*} options 
     * @returns {Object}
     * 
     * options keys include:
     * - reviver: function to be used in fromJSON()
     * 
     */
    parse(jsonStr, options = {}) {
      return this.fromJSON(JSON.parse(jsonStr), options);
    },

    /**
     * Take string produced by st-json stringify convert to JSON Object then circular references restored
     * 
     * @param {String} jsonStr 
     * @param {*} options 
     * @returns {Object}
     * 
     * options keys include:
     * - reviver: function(key, value) called for each value in JSON Object to specify if and how it should be formatted
     * 
     */
    fromJSON($, options = {}) {
      var px = /^\$(?:\[(?:\d+|"(?:[^\\"0000-001f]|\\(?:[\\"/bfnrt]|u[0-9a-zA-Z]{4}))*")\])*$/;

      (function rez(key, value) {
        // The rez function walks recursively through the object looking for $ref
        // properties. When it finds one that has a value that is a path, then it
        // replaces the $ref object with a reference to the value that is found by
        // the path.
        if (typeof options.reviver === 'function') {
          value = options.reviver(key, value);
        }

        if (value && typeof value === 'object') {
          if (Array.isArray(value)) {
            value.forEach(function (element, i) {
              if (typeof element === 'object' && element !== null) {
                var path = element.$ref;

                if (typeof path === 'string' && px.test(path)) {
                  value[i] = eval(path);
                } else {
                  rez(key, element);
                }
              }
            });
          } else if (value._isMoment && value._momentValue) {
            value = (0, _moment.default)(value._momentValue);
          } else {
            Object.keys(value).forEach(function (name) {
              var item = value[name];

              if (typeof item === 'object' && item !== null) {
                var path = item.$ref;

                if (typeof path === 'string' && px.test(path)) {
                  value[name] = eval(path);
                } else {
                  rez(name, item);
                }
              }
            });
          }
        }
      })('root', $);

      return $;
    }

  });

  _exports.default = _default;
});