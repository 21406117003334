define("securitytrax-ember-ui/services/appointment-time-service", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * Service to handle the state of calandar's data.
   *
   * @class AppointmentTimeService
   * @extends Ember.Service
    * @memberof services
    *
    */
  var AppointmentTimeService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service('events/calendar-events'), _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = (0, _emberConcurrency.task)(function* () {
    var globalSettings = yield this.sessionData.getCompanySettings();

    if (globalSettings && globalSettings.calendarEventSchedulerStartTime) {
      this.startTime = (0, _moment.default)(globalSettings.calendarEventSchedulerStartTime, 'hh:mm A');
    } else {
      this.startTime = (0, _moment.default)('08:00 A', 'hh:mm A');
    }

    if (globalSettings && globalSettings.calendarEventSchedulerDurationSeconds) {
      this.duration = globalSettings.calendarEventSchedulerDurationSeconds;
      var tempEndTime = (0, _moment.default)(this.startTime);
      this.endTime = tempEndTime.add(globalSettings.calendarEventSchedulerDurationSeconds, 'seconds');
    } else {
      this.endTime = (0, _moment.default)('09:00 P', 'hh:mm A');
      this.duration = 46800;
    }

    Ember.set(this.calendarDataService, 'defaultStartTime', this.startTime);
    Ember.set(this.calendarDataService, 'defaultEndTime', this.endTime);

    if (this.refreshCalPages) {
      this.calendarEventsService.trigger('update-default-start-end-times', this.startTime, this.endTime);
      this.refreshCalPages = false;
    }
  }), (_class = (_temp = class AppointmentTimeService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "sessionData", _descriptor, this);

      _initializerDefineProperty(this, "grants", _descriptor2, this);

      _initializerDefineProperty(this, "calendarDataService", _descriptor3, this);

      _initializerDefineProperty(this, "calendarEventsService", _descriptor4, this);

      _initializerDefineProperty(this, "startTime", _descriptor5, this);

      _initializerDefineProperty(this, "endTime", _descriptor6, this);

      _initializerDefineProperty(this, "duration", _descriptor7, this);

      _initializerDefineProperty(this, "refreshCalPages", _descriptor8, this);

      _initializerDefineProperty(this, "getGlobalSettingTimes", _descriptor9, this);

      this.calendarEventsService.on('update-calendar-event-scheduler-settings', this, '_updateCalendarEventSchedulerSettings');
      this.getGlobalSettingTimes.perform();
    }

    _updateCalendarEventSchedulerSettings() {
      this.refreshCalPages = true;
      this.getGlobalSettingTimes.perform();
    }

    willDestroy() {
      this.calendarEventsService.off('update-calendar-event-scheduler-settings', this, '_updateCalendarEventSchedulerSettings');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "calendarDataService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "calendarEventsService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "startTime", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "endTime", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "duration", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "refreshCalPages", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "getGlobalSettingTimes", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AppointmentTimeService;
});