define("securitytrax-ember-ui/components/calendar-glimmer/calendar-map/layer-group/component", ["exports", "@glimmer/component", "lodash"], function (_exports, _component, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CalendarMapLayerGroup = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service('events/calendar-events'), _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = (_temp = class CalendarMapLayerGroup extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "mapWindow", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "grants", _descriptor4, this);

      _initializerDefineProperty(this, "CalendarEventsService", _descriptor5, this);

      _initializerDefineProperty(this, "opacity", _descriptor6, this);

      _initializerDefineProperty(this, "zIndex", _descriptor7, this);

      this.CalendarEventsService.on('selectLayerMap', this, '_selectLayerMap');
    }

    _selectLayerMap(userId) {
      if (this.args.user.user.id !== userId) {
        this.deselectLayer();
      } else {
        this.selectLayer();
      }
    }

    willDestroy() {
      this.CalendarEventsService.off('selectLayerMap', this, '_selectLayerMap');
    }

    selectLayer() {
      (0, _lodash.set)(this, 'opacity', '1');
      (0, _lodash.set)(this, 'zIndex', 700);
    }

    deselectLayer() {
      (0, _lodash.set)(this, 'opacity', '0.4');
      (0, _lodash.set)(this, 'zIndex', 1);
    }

    toggleEvent() {
      this.eventToggle = !this.eventToggle;
    }

    polylineMouseover() {
      this.CalendarEventsService.trigger('selectLayerMap', this.args.user.user.id);
    }

    polylineMouseout() {
      this.deselectLayer();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "mapWindow", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "CalendarEventsService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "opacity", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '0.4';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "zIndex", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleEvent", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "toggleEvent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "polylineMouseover", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "polylineMouseover"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "polylineMouseout", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "polylineMouseout"), _class.prototype)), _class));
  _exports.default = CalendarMapLayerGroup;
});