define("securitytrax-ember-ui/models/resource-type-grant", ["exports", "securitytrax-ember-ui/models/base_models/resource-type-grant"], function (_exports, _resourceTypeGrant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resourceTypeGrant.default.extend({});

  _exports.default = _default;
});