define("securitytrax-ember-ui/components/org-chart/user-tree/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/list/list-sort-mixin"], function (_exports, _emberConcurrency, _listSortMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_listSortMixin.default, {
    sessionData: Ember.inject.service('sessionData'),
    orgTree: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service('store'),
    tagName: '',
    showActionModal: false,
    wellReady: false,
    modalClass: 'st-modal--no-min-height',
    currentUserHierarchy: null,
    actionModalType: 'delete',
    recomputeWell: false,
    modalComponent: '',
    modalTitle: '',
    mediaOrientation: 'st-media-object--portrait',
    modalParentUser: null,
    routeUsers: null,
    wellUsers: null,

    init() {
      this._super(...arguments);
    },

    willUpdate() {
      this._super(...arguments);

      var orgTree = this.orgTree;
      orgTree.set('showUnassignedLink', true);
    },

    getCurrentUserTask: (0, _emberConcurrency.task)(function* (id) {
      return yield this.store.findRecord('userHierarchy', id).catch(() => {});
    }),
    company: Ember.computed('orgTree.theCompany', function () {
      return this.get('orgTree.theCompany');
    }),
    userHierarchyTree: Ember.computed('orgTree.userHierarchyTree', function () {
      return this.get('orgTree.userHierarchyTree');
    }),
    parent: Ember.computed('currentUserHierarchy', function () {
      return this.getParentTask.perform();
    }),
    getParentTask: (0, _emberConcurrency.task)(function* () {
      var user = yield this.currentUserHierarchy;

      if (user) {
        return yield user.get('directoryUserParent');
      }

      return null;
    }),
    grandParents: Ember.computed('currentUserHierarchy', function () {
      return this.getGrandParentsTask.perform();
    }),
    getGrandParentsTask: (0, _emberConcurrency.task)(function* () {
      var user = yield this.user;

      if (user) {
        yield (0, _emberConcurrency.waitForProperty)(this, 'userHierarchy', userHierarchy => userHierarchy !== null);
        return yield this.userHierarchy.get('directoryUserGrandparents');
      }
    }),
    getWellUsers: Ember.computed('currentUserHierarchy', 'showActionModal', function () {
      if (this.showActionModal) {
        return this.wellUsers;
      } else {
        return this.getWellTask.perform();
      }
    }),
    getWellTask: (0, _emberConcurrency.task)(function* () {
      var currentUserHierarchy = yield this.currentUserHierarchy;

      if (currentUserHierarchy) {
        var promiseArray = [];

        for (var i = 0; i < this.currentUserHierarchy.responseMeta.children_counts.length; i++) {
          var childObj = this.currentUserHierarchy.responseMeta.children_counts[i];
          promiseArray.push(this.getChildTask.perform(childObj.child_user_id));
        }

        var children = yield (0, _emberConcurrency.all)(promiseArray);
        var returnVal = this.sort(children.toArray(), 'user.lname', 'asec');
        this.set('wellUsers', returnVal);
        return returnVal;
      } else {
        if (this.company) {
          var routeUsers = yield this.getRouteUsers.perform();

          if (routeUsers) {
            var userHierarchies = routeUsers.toArray();
            var wellUsers = Ember.A();

            for (var x = 0; x < userHierarchies.length; x++) {
              var userHierarchy = userHierarchies[x];
              var parentId = userHierarchy.belongsTo('parent').id();

              if (!parentId) {
                wellUsers.push(userHierarchy);
              }
            }

            var retVal = this.sort(wellUsers, 'user.lname', 'asec');
            this.set('wellUsers', retVal);
            return retVal;
          }
        }
      }

      return true;
    }),
    getChildTask: (0, _emberConcurrency.task)(function* (childID) {
      var child = yield this.store.findRecord('userHierarchy', JSON.stringify({
        user_hierarchy_tree_id: this.model.userHierarchyTree.get('id'),
        user_id: childID
      }));
      return child;
    }),
    getRouteUsers: (0, _emberConcurrency.task)(function* () {
      var routeUsers = yield this.store.query('userHierarchy', {
        include: 'user,parent,children,user_hierarchy_title'
      });
      return routeUsers;
    }),
    actions: {
      dropdownAction(user, modalType) {
        this.set('modalParentUser', user);
        this.set('actionModalType', modalType);

        if (modalType === 'add') {
          this.set('modalComponent', 'multi-step-form/forms/user-actions/add-user');
          this.set('modalTitle', this.intl.t('features.org-chart.modals.add_title')); //this.set('modalClass', 'add-users-modal st-modal--small');

          this.set('modalClass', 'st-modal--small org-chart__modal st-modal--no-min-height');
        } else if (modalType === 'delete') {
          this.set('modalTitle', this.intl.t('features.org-chart.modals.delete_title'));
          this.set('modalComponent', 'multi-step-form/forms/user-actions/delete-user');
          this.set('modalClass', 'st-modal--small org-chart__modal st-modal--no-min-height');
        } else if (modalType === 'swap') {
          this.set('modalTitle', this.intl.t('features.org-chart.modals.swap_title'));
          this.set('modalComponent', 'multi-step-form/forms/user-actions/swap-user');
          this.set('modalClass', 'st-modal--small org-chart__modal st-modal--no-min-height');
        } else if (modalType === 'move') {
          this.set('modalTitle', this.intl.t('features.org-chart.modals.move_title'));
          this.set('modalComponent', 'multi-step-form/forms/user-actions/move-user');
          this.set('modalClass', 'st-modal--small org-chart__modal st-modal--no-min-height');
        }

        this.set('showActionModal', true);
      }

    }
  });

  _exports.default = _default;
});