define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/monitoring-companies/integrations/integrations-base", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var IntegrationsBase = (_dec = Ember.inject.service('sessionData'), _dec2 = Ember.inject.service('intl'), _dec3 = Ember.inject.service('stNotificationManager'), _dec4 = Ember.inject.service('grants'), _dec5 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var multiStepForm = this.args.multiStepForm;

    if (this.mcSpecificAdditionalSaveActions) {
      this.mcSpecificAdditionalSaveActions();
    }

    var overrideConfig = Ember.Object.create({
      monitoringCompany: {
        model: this.args.monitoringCompany,
        copyRelationMap: {
          monitoringCompanyType: {
            readOnly: true
          },
          monitoringCompanyConfig: {}
        }
      }
    });
    yield multiStepForm.validateSyncSaveAll({
      extraValidationData: {
        noValidate: ['accountNumberBlock-startNumber', 'accountNumberBlock-receiverNumber', 'accountNumberBlock-monitoringCompany', 'monitoringCompanyConfig-newPassword', 'monitoringCompanyConfig-confirmNewPassword', 'monitoringCompanyConfig-username', 'monitoringCompanyConfig-password', 'monitoringCompanyConfig-keyCode', 'monitoringCompanyConfig-usernameId', 'monitoringCompanyConfig-dealerNumber', 'monitoringCompanyConfig-partnerId']
      },
      overrideOriginalModelsConfig: overrideConfig,
      notificationNamespace: 'modal',
      successNotificationNamespace: 'modal'
    });
    buttonPromise.then(() => {
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
    });
  }), _dec6 = (0, _emberConcurrency.task)(function* (buttonPromise, afterValidateTask = undefined) {
    try {
      var monitoringCompany = this.args.multiStepForm.formOriginalModelsGetVal('monitoringCompany.model');
      var response = yield monitoringCompany.invokeAction('validate_credentials', {
        verb: 'POST',
        processResponse: true,
        data: {
          data: {}
        }
      });

      if (afterValidateTask && typeof afterValidateTask.perform === 'function') {
        afterValidateTask.perform();
      }

      if (response.responsePayload.data.attributes.credentials_valid === false) {
        throw new Error(this.intl.t('pages.administration.integrations.monitoringCompanies.integrations.invalidCredentials'));
      }

      buttonPromise.then(() => {
        this.stNotificationManager.addSuccessKey('pages.administration.integrations.monitoringCompanies.integrations.validate_credentials_success', 'modal');
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, 'modal');
      throw err;
    }
  }), _dec7 = Ember._action, (_class = (_temp = class IntegrationsBase extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "sessionData", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor3, this);

      _initializerDefineProperty(this, "grants", _descriptor4, this);

      _initializerDefineProperty(this, "saveMonitoringCompanyConfig", _descriptor5, this);

      _initializerDefineProperty(this, "validateCredentialsTask", _descriptor6, this);
    }

    buttons() {
      this.args.multiStepForm.registerStepData("integration", 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.saveMonitoringCompanyConfig.perform(buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary monitoring-companies-save-button'
      }]);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "saveMonitoringCompanyConfig", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "validateCredentialsTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "buttons", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "buttons"), _class.prototype)), _class));
  _exports.default = IntegrationsBase;
});