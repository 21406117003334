define("securitytrax-ember-ui/components/multi-step-form/forms/invoices/invoice/add-payment/component", ["exports", "moment", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms/invoices/base"], function (_exports, _moment, _emberConcurrency, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var InvoiceAddPayments = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = (0, _emberConcurrency.task)(function* () {
    var hashedData = yield (0, _emberConcurrency.hash)({
      waitForFinishedCopyingModels: (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true),
      customerAccountingInformation: this.args.customer.get('customerAccountingInformation'),
      customerBillingChoices: this.customerBillingChoices
    });
    this.selectedPaymentMethod = this.paymentMethodChoices.length > 0 ? this.paymentMethodChoices[0].packageId : null;
    Ember.set(this, 'processNow', this.grants.getIndividualGrant('customer_payments.process'));
    Ember.set(this.args.multiStepForm, 'formCopiedModels.customerPayment.model.amount', this.args.multiStepForm.formOriginalModelsGetVal('invoice.model.responseMeta.due_amount'));
    Ember.set(this.args.multiStepForm, 'formCopiedModels.customerPayment.model.tax', 0);
    Ember.set(this.args.multiStepForm, 'formCopiedModels.customerPayment.model.paymentDate', (0, _moment.default)().tz('America/Denver').format('MM/DD/YYYY'));
    this.setReceiptEmails(hashedData.customerAccountingInformation);

    if (this.grants.getIndividualGrant('customer_billings.view') && hashedData.customerBillingChoices.length) {
      Ember.set(this, 'selectOrCreatePaymentId', 'select_payment_method');
    } else {
      Ember.set(this, 'selectOrCreatePaymentId', 'create_payment_method');
    }
  }), _dec15 = (0, _emberConcurrency.task)(function* (customerBilling) {
    var currCustomerBillingUses = yield customerBilling.get('customerBillingUses');
    return {
      customerBilling,
      currCustomerBillingUses
    };
  }), _dec16 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var overrideOriginalModelsConfig = Ember.Object.create({
      customerPayment: {
        model: null
      },
      customerInvoicePayment: {
        model: null
      }
    });
    var noValidateArray = this.args.multiStepForm.formCopiedModelsGetVal('customerBilling.model.billAddressSame') || this.args.multiStepForm.formCopiedModelsGetVal('customerBilling.model.requestMeta.use_default') ? ['customerBilling-billAddress1', 'customerBilling-billCity', 'customerBilling-billState', 'customerBilling-billZip'] : [];

    if (!this.processNow || !this.args.multiStepForm.formCopiedModels.customerPayment.model.emailWhenProcessed) {
      noValidateArray.push('customerPayment-receiptEmails');
    }

    var customerBillings = yield this.args.customer.get('customerBillings');

    if (this.selectOrCreatePaymentId === 'select_payment_method' && this.processNow) {
      var selectedPaymentMethod = customerBillings.findBy('id', this.selectedPaymentMethodId);
      Ember.set(this.args.multiStepForm, 'formCopiedModels.customerPayment.model.customerBilling', selectedPaymentMethod);
    } else if (this.selectOrCreatePaymentId === 'create_payment_method' && this.processNow) {
      overrideOriginalModelsConfig = Ember.Object.create({
        customerPayment: {
          model: null
        },
        customerInvoicePayment: {
          model: null
        },
        customerBilling: {
          model: null
        }
      });
    }

    var billType = yield this.store.findRecord('billingType', this.selectedPaymentMethod);
    var toCreateObjects = yield this.args.multiStepForm.validateCreateAll({
      isCreate: false,
      overrideOriginalModelsConfig,
      extraValidationData: {
        noValidate: noValidateArray,
        selectOrCreatePaymentId: customerBillings.get('length') > 0 ? this.selectOrCreatePaymentId : 'create_payment_method',
        processNow: this.processNow,
        billType: billType.get('name'),
        invoiceDueAmount: this.args.multiStepForm.formOriginalModelsGetVal('invoice.model.responseMeta.due_amount')
      },
      notificationNamespace: 'modal'
    });

    if (this.selectOrCreatePaymentId === 'create_payment_method' && this.processNow) {
      //Create Billing
      Ember.set(toCreateObjects.customerBilling.data, 'billType', billType);
      Ember.set(toCreateObjects.customerBilling.data, 'customer', this.args.customer);
      var customerBilling = this.store.createRecord('customerBilling', toCreateObjects.customerBilling.data);
      var savedCustomerBilling = yield toCreateObjects.customerBilling.saveWithRollbackAndNotification(customerBilling);
      Ember.set(toCreateObjects.customerPayment.data, 'customerBilling', savedCustomerBilling);
    } //Create customer payment


    Ember.set(toCreateObjects.customerPayment.data, 'customer', this.args.customer);
    var customerPayment = this.store.createRecord('customerPayment', toCreateObjects.customerPayment.data);
    yield toCreateObjects.customerPayment.saveWithRollbackAndNotification(customerPayment);

    if (this.args.multiStepForm.formOriginalModelsGetVal('invoice.model.id')) {
      //Associated created payment with invoice
      Ember.set(toCreateObjects.customerInvoicePayment.data, 'customerPayment', customerPayment);
      Ember.set(toCreateObjects.customerInvoicePayment.data, 'amountUsed', customerPayment.get('amount'));
      Ember.set(toCreateObjects.customerInvoicePayment.data, 'invoice', this.args.multiStepForm.formOriginalModelsGetVal('invoice.model'));
      var customerInvoicePayment = this.store.createRecord('customerInvoicePayment', toCreateObjects.customerInvoicePayment.data);
      yield toCreateObjects.customerInvoicePayment.saveWithRollbackAndNotification(customerInvoicePayment);
      this.args.multiStepForm.formOriginalModelsGetVal('invoice.model.customerInvoicePayments').reload();
      this.args.multiStepForm.formOriginalModelsGetVal('invoice.model').reload();
    }

    buttonPromise.then(() => {
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
      this.args.multiStepForm.formOriginalModelsGetVal('invoice.model.id') ? this.args.multiStepForm.currentStepNameKeySetVal('listPayments') : this.args.transitionBackToParent();
    });
  }), _dec17 = Ember._action, (_class = (_temp = class InvoiceAddPayments extends _base.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "grants", _descriptor3, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor4, this);

      _initializerDefineProperty(this, "sessionData", _descriptor5, this);

      _initializerDefineProperty(this, "invoiceService", _descriptor6, this);

      _initializerDefineProperty(this, "amountDueIsGreaterThanZero", _descriptor7, this);

      _initializerDefineProperty(this, "cvv", _descriptor8, this);

      _initializerDefineProperty(this, "receiptEmails", _descriptor9, this);

      _initializerDefineProperty(this, "processNow", _descriptor10, this);

      _initializerDefineProperty(this, "selectOrCreatePaymentId", _descriptor11, this);

      _initializerDefineProperty(this, "selectedPaymentMethod", _descriptor12, this);

      _initializerDefineProperty(this, "selectedPaymentMethodId", _descriptor13, this);

      _initializerDefineProperty(this, "initFormTask", _descriptor14, this);

      _initializerDefineProperty(this, "billUsesDataTask", _descriptor15, this);

      _initializerDefineProperty(this, "addPaymentTask", _descriptor16, this);

      this.initFormTask.perform();
    }

    get hiddenProgressBarSteps() {
      return ['confirmRefundVoid', 'gettingStarted', 'viewProcessingInformation', 'editManualPayment'];
    }

    get disabledProgressBarSteps() {
      return [];
    }

    setReceiptEmails(customerAccountingInformation) {
      var invoiceInformation = customerAccountingInformation.filterBy('useType', 'invoice')[0];
      var defaultInformation = customerAccountingInformation.filterBy('useType', 'default')[0];
      var toAddresses; // Use invoice billing info emails, then try default billing info emails, then just get customer email

      if (invoiceInformation) {
        toAddresses = invoiceInformation.usePrimary ? this.args.customer.get('email') : invoiceInformation.emails;
      } else if (defaultInformation) {
        toAddresses = defaultInformation.usePrimary ? this.args.customer.get('email') : defaultInformation.emails;
      } else {
        toAddresses = this.args.customer.get('email');
      }

      this.receiptEmails = toAddresses;
    }

    get dynamicPaymentMethodForm() {
      return this.selectedPaymentMethod === '1' ? 'multi-step-form/forms/accounting/edit-create-payment-method/method-layouts/credit-card' : 'multi-step-form/forms/accounting/edit-create-payment-method/method-layouts/bank-account';
    }

    get paymentMethodChoices() {
      return Ember.A([Ember.Object.create({
        packageName: this.intl.t('common.finance.creditCard'),
        packageId: '1'
      }), Ember.Object.create({
        packageName: this.intl.t('common.finance.bankAccount'),
        packageId: '2'
      })]);
    }

    get selectOrCreatePaymentChoices() {
      var choices = Ember.A([]);

      if (this.grants.getIndividualGrant('customer_billings.view')) {
        choices.pushObject(Ember.Object.create({
          packageName: this.intl.t('pages.customers.accounting.customer_billing.choose_payment_method'),
          packageId: 'select_payment_method'
        }));
      }

      if (this.grants.getIndividualGrant('customer_billings.create')) {
        choices.pushObject(Ember.Object.create({
          packageName: this.intl.t('pages.customers.accounting.customer_billing.create_payment_method'),
          packageId: 'create_payment_method'
        }));
      }

      return choices;
    }

    setupButtons() {
      var buttons = [];

      if (this.args.multiStepForm.formOriginalModelsGetVal('invoice.model.responseMeta.due_amount') > 0 || this.SwitchableFormStep.isNewRecord) {
        if (this.formMode !== 'view') {
          buttons.push({
            text: this.intl.t('common.actions.save'),
            click: buttonPromise => {
              return this.addPaymentTask.perform(buttonPromise);
            },
            classNamesString: 'st-btn st-btn-primary'
          });
        }
      }

      this.args.multiStepForm.registerStepData('addPayment', 'buttons', buttons);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "invoiceService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "amountDueIsGreaterThanZero", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "cvv", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "receiptEmails", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "processNow", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "selectOrCreatePaymentId", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "selectedPaymentMethod", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "selectedPaymentMethodId", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "initFormTask", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "billUsesDataTask", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "addPaymentTask", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupButtons", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "setupButtons"), _class.prototype)), _class));
  _exports.default = InvoiceAddPayments;
});