define("securitytrax-ember-ui/components/modals/brinks-add-two-way-device/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jmDS8JGl",
    "block": "{\"symbols\":[\"@multiStepForm\"],\"statements\":[[8,\"html/st-await\",[],[[\"@useSpinner\",\"@booleanLoaded\"],[true,[34,0,[\"isIdle\"]]]],[[\"default\"],[{\"statements\":[[2,\"   \\n    \"],[10,\"div\"],[14,0,\"grid-x grid-padding-x\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cell small-12 large-6\"],[12],[2,\"\\n            \"],[8,\"multi-step-form/switchable-inputs/text-input\",[],[[\"@value\",\"@label\",\"@required\",\"@formMode\"],[[32,1,[\"formCopiedModels\",\"customerSystemInformation\",\"model\",\"accountNum\"]],[30,[36,1],[\"pages.customers.system.order.csNumber\"],null],true,\"view\"]],null],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cell small-12 large-6\"],[12],[2,\"\\n            \"],[8,\"multi-step-form/switchable-inputs/select-input\",[],[[\"@options\",\"@value\",\"@valueProperty\",\"@label\",\"@label\",\"@required\",\"@formMode\",\"@renderInPlaceOverride\"],[[32,0,[\"listOptions\"]],[32,1,[\"formCopiedModels\",\"customerSystemInformation\",\"model\",\"tempTwoWayDeviceId\"]],\"id\",\"name\",[30,[36,1],[\"pages.customers.system.order.twoWayDeviceId\"],null],true,\"edit\",false]],null],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"initDataTask\",\"t\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/modals/brinks-add-two-way-device/template.hbs"
    }
  });

  _exports.default = _default;
});