define("securitytrax-ember-ui/models/base_models/report-object", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    name: (0, _model.attr)('string', {
      'readOnly': true
    }),
    compatibility: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['v2', 'v3', 'all']
    }),
    status: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['active', 'deleted']
    }),
    reportObjectColumns: (0, _model.hasMany)('report-object-column', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    reportOuterloops: (0, _model.hasMany)('report-outerloop', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    reportObjectFilters: (0, _model.hasMany)('report-object-filter', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    reportObjectMassEditColumns: (0, _model.hasMany)('report-object-mass-edit-column', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});