define("securitytrax-ember-ui/components/modals/cops-add-account-authority/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    centralStationService: Ember.inject.service(),
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    type: null,
    zip: null,
    authorities: null,
    searchLoaded: true,
    searchAttempted: false,
    addAuthorityConfirmModalOpen: false,
    typeOptions: Ember.computed(function () {
      return this.getTypeOptions.perform();
    }),
    getTypeOptions: (0, _emberConcurrency.task)(function* () {
      var authorities = yield this.centralStationService.getData({
        model: this.monitoringCompany,
        method: 'cops__getAuthorityTypes'
      });
      return authorities.authority_types;
    }),
    searchForAuthorities: (0, _emberConcurrency.task)(function* () {
      var formattedObjects = [];
      formattedObjects.pushObject(Ember.Object.create({
        model: {
          type: this.type,
          zip: this.zip
        },
        filePath: 'central-station/add-authority',
        extraValidationData: {
          noValidate: ['authority-searchField']
        }
      }));
      yield this.multiStepForm.validateList.perform(formattedObjects);

      try {
        this.set('searchLoaded', false);
        var authorities = yield this.centralStationService.getData({
          model: this.customer,
          method: 'cops__authorityLookup',
          payload: {
            type: this.type,
            zip: this.zip
          }
        });
        this.set('searchLoaded', true);
        authorities.authorities.forEach(testing => {
          testing.selected = false;
        });
        this.set('authorities', authorities.authorities);
        this.set('searchAttempted', true);
        return authorities.authorities;
      } catch (err) {
        this.set('searchLoaded', true);
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'addAuthorityModal'
        });
        throw err;
      }
    }),
    addAuthorityButton: Ember.computed(function () {
      return [{
        text: this.intl.t('common.actions.save'),
        click: () => {
          this.set('addAuthorityConfirmModalOpen', true);
        },
        classNamesString: 'st-btn st-btn-primary'
      }];
    }),
    addAuthority: (0, _emberConcurrency.task)(function* (buttonPromise) {
      var authority = this.authorities.find(authorityOption => {
        return authorityOption.selected === true;
      });

      try {
        if (!authority || !this.type) {
          throw new Error(this.intl.t('pages.customers.system.generic_integration.select_authority_message'));
        }

        if (this.formMode === 'create') {
          this.tempCustomerAuthorities.push({
            type: this.type,
            typeFormatted: authority.name,
            number: authority
          });
        } else {
          yield this.centralStationService.postData({
            model: this.customer,
            method: 'cops__addAccountAuthorityInformation',
            payload: {
              type: this.type,
              number: authority.number
            }
          });
        }

        buttonPromise.then(() => {
          this.refreshAuthorities();
          this.set('dialogOpen', false);
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
          Ember.setProperties(this, {
            zip: null,
            type: null,
            authorities: null,
            searchAttempted: false
          });

          if (this.formMode !== 'create') {
            this.customer.hasMany('agencyContacts').reload();
          }
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'addAuthorityConfirm'
        });
        throw err;
      }
    }),
    actions: {
      toggleAuthorities(row) {
        this.authorities.forEach(authority => {
          if (authority.number !== row.number) {
            Ember.set(authority, 'selected', false);
          }
        });
      }

    }
  });

  _exports.default = _default;
});