define("securitytrax-ember-ui/mixins/property-saver-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Provides an action that takes care of updating a record
   * property, save and show a success message.
   *
   * Recommended use is for controllers and not components to keep it DDAU.
   *
   * @class PropertySaverMixin
   * @extends Ember.Mixin
   * @memberof mixins
   */
  var _default = Ember.Mixin.create(
  /** @lends mixins.PropertySaverMixin.prototype */
  {
    stNotificationManager: Ember.inject.service(),

    /**
     * Update a record property, save and display a success notification.
     *
     * @param {DS.Record} record The model to update
     * @param {String} propertyName The property to update
     * @param {Object} newValue The new value to set
     */
    saveProperty(record, propertyName, newValue) {
      record.set(propertyName, newValue);
      record.save().then(() => {
        this.stNotificationManager.addSuccessSave();
      });
    },

    actions: {
      saveProperty(record, propertyName, newValue) {
        this.saveProperty(record, propertyName, newValue);
      },

      savePropertyObject(list, findByProperty, record, propertyName, newId) {
        var newValue = list.findBy(findByProperty, newId);
        this.saveProperty(record, propertyName, newValue);
      }

    }
  });

  _exports.default = _default;
});