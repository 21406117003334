define("securitytrax-ember-ui/helpers/concat-arr", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.concatArr = concatArr;
  _exports.default = void 0;

  function concatArr(args) {
    var myArray = [...args]; // need a copy because args is read only

    if (myArray.length == 0) {
      return [];
    }

    var baseArray = myArray.shift();

    if (myArray) {
      return _lodash.default.concat(baseArray, ...myArray);
    }

    return baseArray;
  }

  var _default = Ember.Helper.helper(concatArr);

  _exports.default = _default;
});