define("securitytrax-ember-ui/calendar/map-view/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/subroute-modal", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _authenticatedRouteMixin, _subrouteModal, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _subrouteModal.default, _routePageviewLogging.default, {
    grants: Ember.inject.service(),
    queryParams: {
      date: {
        refreshModel: true
      },
      users: {
        refreshModel: true
      }
    },

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform();
    },

    afterModel() {
      this._super(...arguments);
    },

    model(params) {
      return Ember.RSVP.hash({
        params,
        parentRoute: this.transitionRoute
      });
    },

    setupController(controller) {
      this._super(...arguments);

      var breadCrumbs = [{
        name: this.intl.t('pages.customers.nav.map'),
        last: true,
        previousLink: 'calendar',
        link: 'map-view'
      }];
      var breadCrumbData = {
        title: this.intl.t('common.nav.calendar'),
        titleLink: 'calendar'
      };
      controller.set('breadCrumbs', breadCrumbs);
      controller.set('breadCrumbData', breadCrumbData);
    }

  });

  _exports.default = _default;
});