define("securitytrax-ember-ui/models/base_models/zip-code", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    city: (0, _model.attr)('string', {
      'readOnly': false
    }),
    state: (0, _model.attr)('string', {
      'readOnly': false
    }),
    zipCode: (0, _model.attr)('string', {
      'readOnly': false
    }),
    country: (0, _model.attr)('string', {
      'readOnly': false
    }),
    zipCodeType: (0, _model.attr)('string', {
      'readOnly': false
    }),
    preferred: (0, _model.attr)('string', {
      'readOnly': false
    }),
    areaCode: (0, _model.attr)('string', {
      'readOnly': false
    }),
    countyFips: (0, _model.attr)('string', {
      'readOnly': false
    }),
    county: (0, _model.attr)('string', {
      'readOnly': false
    }),
    timeZone: (0, _model.attr)('string', {
      'readOnly': false
    }),
    dst: (0, _model.attr)('string', {
      'readOnly': false
    }),
    latitude: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 4
    }),
    longitude: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 4
    }),
    msa: (0, _model.attr)('number', {
      'readOnly': false
    }),
    pmsa: (0, _model.attr)('number', {
      'readOnly': false
    }),
    cityAbbreviation: (0, _model.attr)('string', {
      'readOnly': false
    }),
    marketArea: (0, _model.attr)('number', {
      'readOnly': false
    }),
    cbsa: (0, _model.attr)('number', {
      'readOnly': false
    }),
    division: (0, _model.attr)('number', {
      'readOnly': false
    }),
    population: (0, _model.attr)('number', {
      'readOnly': false
    }),
    timeZoneName: (0, _model.attr)('string', {
      'readOnly': false
    })
  });

  _exports.default = _default;
});