define("securitytrax-ember-ui/customers/information/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JB2w+V+j",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"customer-information-page\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[\"customers.view\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"customer/information/customer-information\",[],[[\"@customer\",\"@model\"],[[34,0,[\"customer\"]],[34,0]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[\"customer_order_information.view\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"customer/information/sales-information\",[],[[\"@customer\",\"@model\"],[[34,0,[\"customer\"]],[34,0]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"customer/information/central-station-information\",[],[[\"@customer\",\"@model\",\"@subroutePath\"],[[34,0,[\"customer\"]],[34,0],\"customers.information\"]],null],[2,\"\\n\\n    \"],[8,\"customer/information/interactive-services-provider-information\",[],[[\"@customer\",\"@model\",\"@subroutePath\"],[[34,0,[\"customer\"]],[34,0],\"customers.information\"]],null],[2,\"\\n\\n    \"],[8,\"customer/information/customer-installation\",[],[[\"@customer\"],[[34,0,[\"customer\"]]]],null],[2,\"\\n\\n    \"],[8,\"customer/user-defined-fields\",[],[[\"@customer\",\"@model\",\"@udfContext\"],[[34,0,[\"customer\"]],[34,0],\"customers_customer\"]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,4],[[30,[36,3],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"model\",\"has-grants\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/customers/information/template.hbs"
    }
  });

  _exports.default = _default;
});