define("securitytrax-ember-ui/users/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Controller
   * @extends Ember.Controller
   * @memberof users
   */
  var Controller = Ember.Controller.extend(
  /** @lends users.Controller# */
  {
    navigation: Ember.inject.service(),
    hasTechOrSalesRole: false,
    hasTechRole: false,
    initializeNav: Ember.on('init', function () {
      this.navigation;
    }),
    isTechOrSalesRep: Ember.computed('user.roles.{[],@each}', function () {
      return this.isTechOrSalesRepTask.perform();
    }),
    isTechOrSalesRepTask: (0, _emberConcurrency.task)(function* () {
      var user = yield this.user;
      var isSalesRep = yield user.isSalesRep;
      var isTech = yield user.isTech;
      return isSalesRep || isTech;
    }),
    isTech: Ember.computed('user.roles.{[],@each}', function () {
      return this.isTechTask.perform();
    }),
    isTechTask: (0, _emberConcurrency.task)(function* () {
      var user = yield this.user;
      var isTech = yield user.isTech;
      return isTech;
    })
  });
  var _default = Controller;
  _exports.default = _default;
});