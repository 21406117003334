define("securitytrax-ember-ui/components/html/progress-meter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rLXUspOE",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[30,[36,4],[\"progress-meter\",[30,[36,3],[[30,[36,2],[[35,1],[35,0]],null],\" complete\",\"\"],null]],null]],[12],[2,\"\\n    \"],[10,\"progress\"],[15,\"max\",[31,[[34,0]]]],[15,2,[31,[[34,1]]]],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"theMax\",\"value\",\"gte\",\"if\",\"concat\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/progress-meter/template.hbs"
    }
  });

  _exports.default = _default;
});