define("securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/greensky--dead/agreement/loan-document/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QKYRdYTk",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"pdf\"],[[35,0]]]]]],\"hasEval\":false,\"upvars\":[\"loanDocument\",\"pdf-js\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/greensky--dead/agreement/loan-document/template.hbs"
    }
  });

  _exports.default = _default;
});