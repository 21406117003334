define("securitytrax-ember-ui/components/multi-step-form/forms/demo/demo-wizard/step-4/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/demo/demo-wizard/demo-wizard-base", "jquery"], function (_exports, _demoWizardBase, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _demoWizardBase.default.extend({
    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('step4', 'buttons', [{
        text: 'Prev',
        click: function () {
          multiStepForm.currentStepNameKeySetVal('step3');
        }
      }, {
        text: 'Close',
        click: function () {
          (0, _jquery.default)(this).dialog('close');
        }
      }]);
    }

  });

  _exports.default = _default;
});