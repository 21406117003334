define("securitytrax-ember-ui/components/pages/users/user-permissions/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    groupPermissionsForm: Ember.computed(function () {
      return Ember.Object.create({
        groupPermission: Ember.Object.create({
          stepNameKey: 'groupPermission',
          title: this.intl.t('pages.users.permissions.select_group'),
          stepComponentPath: 'multi-step-form/forms/administration/users/permissions'
        })
      });
    }),
    formOriginalModels: Ember.computed('model.user', function () {
      return Ember.Object.create({
        user: {
          model: this.get('model.user'),
          copyRelationMap: {
            group: {
              readOnly: true,
              relationships: {
                roles: {
                  readOnly: true
                }
              }
            }
          }
        }
      });
    }),
    userPermissionsStep: Ember.computed('grants.userGrants', function () {
      var intl = this.intl;
      var canViewPermissions = this.grants.getIndividualGrant('users.manage_permissions');
      return Ember.Object.create({
        editPermissions: Ember.Object.create({
          stepNameKey: 'editPermissions',
          title: intl.t('pages.users.human_resources.add_permissions_group'),
          stepComponentPath: 'multi-step-form/forms/administration/users/permissions/user-permissions',
          granted: canViewPermissions
        })
      });
    }),
    userPermissionsFormOriginalModels: Ember.computed('model.user', function () {
      return Ember.Object.create({
        user: {
          model: this.get('model.user'),
          copyRelationMap: {
            group: {
              readOnly: true,
              relationships: {
                resourceTypeGrants: {
                  readOnly: true
                },
                userResourceTypePolicies: {
                  relationships: {
                    resourceTypePolicy: {
                      readOnly: true
                    }
                  }
                }
              }
            }
          }
        }
      });
    })
  });

  _exports.default = _default;
});