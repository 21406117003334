define("securitytrax-ember-ui/components/html/st-pill/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H5zKdfso",
    "block": "{\"symbols\":[\"@svgIconClasses\",\"@onClickAction\",\"@svgIcon\",\"@pillColor\",\"@pillText\"],\"statements\":[[10,\"span\"],[14,0,\"st-pill__pill\"],[15,5,[30,[36,1],[[32,4]],null]],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"pill-text\"],[12],[1,[32,5]],[13],[2,\"\\n\"],[6,[37,2],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"button\"],[15,0,[31,[\"st-pill__svg-icon \",[32,1]]]],[15,\"onclick\",[32,2]],[14,\"tabindex\",\"0\"],[12],[2,\"\\n            \"],[1,[30,[36,0],[[32,3]],[[\"class\"],[\"pill\"]]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"svg-icon\",\"html-safe\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/st-pill/template.hbs"
    }
  });

  _exports.default = _default;
});