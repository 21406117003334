define("securitytrax-ember-ui/customers/work-orders/controller", ["exports", "securitytrax-ember-ui/mixins/table-column-sort", "securitytrax-ember-ui/utils/obj-helpers", "ember-concurrency"], function (_exports, _tableColumnSort, _objHelpers, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Customer work orders controller
   *
   * @class Controller
   * @extends Ember.Controller
   * @memberof customers.workOrders
   */
  var Controller = Ember.Controller.extend(_tableColumnSort.default,
  /** @lends customers.workOrders.Controller# */
  {
    intl: Ember.inject.service(),
    workOrderEvent: Ember.inject.service('events/work-order-event'),

    init() {
      this._super(...arguments);

      this.workOrderEvent.on('refresh-my-work-orders', this, '_callback');
      (0, _objHelpers.initializeState)(this, {
        stSortableColumnOptions: [{
          name: this.intl.t('common.columns.id') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'id',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.id') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-id',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('common.columns.due_date') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'due_date',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.due_date') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-due_date',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('common.columns.created_date') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'created_at',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.created_date') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-created_at',
          chevronDirection: 'down'
        }]
      });
    },

    willDestroy() {
      this.workOrderEvent.off('refresh-my-work-orders', this, '_callback');
    },

    _callback() {
      Ember.notifyPropertyChange(this, 'myWorkOrdersData');
    },

    myWorkOrdersData: Ember.computed('stSortString', function () {
      return this.myWorkOrdersTask.perform();
    }),
    myWorkOrdersTask: (0, _emberConcurrency.task)(function* () {
      return yield this.store.query('workOrder', {
        filter: 'customer = ' + this.model.customer.get('id'),
        sort: this.stSortString ? this.stSortString : 'due_date',
        include: 'customer,customer.customer_equipment,work_order_type,calendar_events.user,calendar_events.calendar_event_type,calendar_events.calendar_event_detail_type,work_order_equipment'
      });
    }),
    actions: {
      transitionToSubroute(path) {
        this.transitionToRoute('customers.work-orders.edit-create-work-order', path);
      },

      sortList(column, ascending) {
        this.sortPaginatedList(column, ascending);
      },

      mobileSortList(column) {
        this.sortMobilePaginatedList(column.value);
      }

    }
  });
  var _default = Controller;
  _exports.default = _default;
});