define("securitytrax-ember-ui/models/base_models/location", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    name: (0, _model.attr)('string', {
      'readOnly': false
    }),
    picture: (0, _model.attr)('string', {
      'readOnly': false
    }),
    address1: (0, _model.attr)('string', {
      'readOnly': false
    }),
    address2: (0, _model.attr)('string', {
      'readOnly': false
    }),
    city: (0, _model.attr)('string', {
      'readOnly': false
    }),
    state: (0, _model.attr)('string', {
      'readOnly': false
    }),
    zip: (0, _model.attr)('string', {
      'readOnly': false
    }),
    shippingContact: (0, _model.attr)('string', {
      'readOnly': false
    }),
    shippingStreet: (0, _model.attr)('string', {
      'readOnly': false
    }),
    shippingCity: (0, _model.attr)('string', {
      'readOnly': false
    }),
    shippingState: (0, _model.attr)('string', {
      'readOnly': false
    }),
    shippingZip: (0, _model.attr)('string', {
      'readOnly': false
    }),
    techScheduleNotes: (0, _model.attr)('string', {
      'readOnly': false
    }),
    remoteTechScheduleNotes: (0, _model.attr)('string', {
      'readOnly': false
    }),
    techScheduleDefaultDuration: (0, _model.attr)('number', {
      'readOnly': false
    }),
    saleScheduleDefaultDuration: (0, _model.attr)('number', {
      'readOnly': false
    }),
    remoteSchedulingMinLeadTime: (0, _model.attr)('number', {
      'readOnly': false
    }),
    active: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    status: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['active', 'deleted']
    }),
    inventoryRecipient: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    inventoryAutoReplenish: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    inventoryBaseCalculatorMultiplier: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    phone: (0, _model.attr)('string', {
      'readOnly': false
    }),
    fax: (0, _model.attr)('string', {
      'readOnly': false
    }),
    email: (0, _model.attr)('string', {
      'readOnly': false
    }),
    stagesSiteGroup: (0, _model.attr)('string', {
      'readOnly': false
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    timeZone: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['US\/Hawaii', 'US\/Alaska', 'US\/Pacific', 'US\/Arizona', 'US\/Mountain', 'US\/Central', 'US\/Eastern', 'US\/East-Indiana', 'Africa\/Monrovia', 'Europe\/London', 'Europe\/Berlin', 'Africa\/Cairo', 'Europe\/Athens', 'Europe\/Moscow', 'PR\/Atlantic']
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    dealerNumber: (0, _model.belongsTo)('dealer-number', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    inventoryRecipientTemplate: (0, _model.belongsTo)('equipment-to-recipient-template', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    tags: (0, _model.hasMany)('tag', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    equipmentVendorToRecipients: (0, _model.hasMany)('equipment-vendor-to-recipient', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    targetEquipmentTransfers: (0, _model.hasMany)('equipment-transfer', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': 'targetLocation'
    }),
    sourceEquipmentTransfers: (0, _model.hasMany)('equipment-transfer', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': 'sourceLocation'
    }),
    userToLocations: (0, _model.hasMany)('user-to-location', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    locationLicenses: (0, _model.hasMany)('location-license', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    udfs: (0, _model.hasMany)('udf', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});