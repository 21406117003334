define("securitytrax-ember-ui/helpers/report-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reportLinkMapping = reportLinkMapping;
  _exports.default = void 0;

  function reportLinkMapping(resourceType, parentResource) {
    switch (resourceType) {
      case 'customer_invoices':
        return parentResource ? parentResource + '.accounting.invoices-invoices.edit-create-invoice' : 'accounting.invoices.edit-invoice';

      case 'customer_payables':
        return parentResource ? parentResource + '.accounting.payments-payables' : 'accounting.payable-entries.edit-payable';

      case 'customer_payments':
        return parentResource ? parentResource + '.accounting.payments-payments' : 'accounting.payments.edit-payment';

      default:
        return resourceType;
    }
  }

  var _default = Ember.Helper.helper(reportLinkMapping);

  _exports.default = _default;
});