define("securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/greensky--dead/agreement/loan-document/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Deprecated for DealerAlly, may come back for some other lender.
  var _default = Ember.Component.extend({
    classNames: ['loan-document__container']
  });

  _exports.default = _default;
});