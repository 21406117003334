define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/edit-create-credit-bureau/safe/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4Qgbkw0q",
    "block": "{\"symbols\":[],\"statements\":[[8,\"multi-step-form/forms/administration/integrations/edit-create-credit-bureau/hart\",[],[[\"@multiStepForm\",\"@formMode\"],[[34,0],[34,1]]],null]],\"hasEval\":false,\"upvars\":[\"multiStepForm\",\"formMode\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/edit-create-credit-bureau/safe/template.hbs"
    }
  });

  _exports.default = _default;
});