define("securitytrax-ember-ui/components/customer/dashboard/quotes-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IqtcJQzu",
    "block": "{\"symbols\":[\"@useDefaultData\",\"@customer\"],\"statements\":[[8,\"lead/dashboard/cards/quotes-card\",[],[[\"@useDefaultData\",\"@customer\"],[[32,1],[32,2]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/customer/dashboard/quotes-card/template.hbs"
    }
  });

  _exports.default = _default;
});