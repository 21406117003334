define("securitytrax-ember-ui/components/multi-step-form/forms/invoices/credit-memo/apply-to-invoice/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/invoices/base", "ember-concurrency"], function (_exports, _base, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _temp, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _class3, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _temp2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var Entry = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, (_class = (_temp = class Entry {
    constructor(invoice) {
      _initializerDefineProperty(this, "invoiceID", _descriptor, this);

      _initializerDefineProperty(this, "invoice", _descriptor2, this);

      _initializerDefineProperty(this, "dueAmount", _descriptor3, this);

      _initializerDefineProperty(this, "isChecked", _descriptor4, this);

      _initializerDefineProperty(this, "paymentAmount", _descriptor5, this);

      _initializerDefineProperty(this, "savedPaymentAmount", _descriptor6, this);

      _initializerDefineProperty(this, "invoicePaymentRecord", _descriptor7, this);

      _initializerDefineProperty(this, "appliedPreviously", _descriptor8, this);

      _initializerDefineProperty(this, "dirty", _descriptor9, this);

      this.invoiceID = invoice.get('id');
      this.invoice = invoice;
      this.dueAmount = invoice.responseMeta.due_amount;
      this.isChecked = false;
      this.paymentAmount = 0;
      this.savedPaymentAmount = 0;
      this.appliedPreviously = false;
      this.dirty = false;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "invoiceID", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "invoice", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dueAmount", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isChecked", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "paymentAmount", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "savedPaymentAmount", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "invoicePaymentRecord", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "appliedPreviously", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "dirty", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  var CreditApplyToInvoice = (_dec10 = Ember.inject.service, _dec11 = Ember.inject.service, _dec12 = Ember.inject.service, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember.computed('appliedAmount', 'totalAmount'), _dec16 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
    this.args.multiStepForm.registerStepData('applyToInvoice', 'buttons', [{
      text: this.intl.t('common.actions.save'),
      click: buttonPromise => {
        return this.applyCustomerInvoicePayments.perform(buttonPromise);
      },
      classNamesString: 'st-btn st-btn-primary'
    }]);
    var invoice = yield this.args.multiStepForm.formOriginalModelsGetVal('invoice.model');
    var creditMemoPayment = yield invoice.get('creditMemoPayment'); // PERF TODO: includes on relationship call so these can be loaded in store on fetch of customer's credit memos

    Ember.set(this, 'creditMemoPayment', creditMemoPayment);
    yield this.getCustomerInvoicePayments.perform();
  }), _dec17 = (0, _emberConcurrency.task)(function* () {
    var invoices = yield this.args.customer.get('invoices');
    invoices.forEach(invoice => {
      this.entries[invoice.get('id')] = new Entry(invoice);
    });
    var creditMemoPaymentID = this.creditMemoPayment.get('id');
    var customerPayment = yield this.store.findRecord('customerPayment', creditMemoPaymentID, {
      include: 'customer_invoice_payments'
    });

    if (customerPayment) {
      var customerInvoicePayments = yield customerPayment.get('customerInvoicePayments');
      customerInvoicePayments.forEach(invoicePayment => {
        var invoiceID = JSON.parse(invoicePayment.id).invoice_id;
        var entry = this.entries[invoiceID];

        if (entry) {
          entry.isChecked = true;
          entry.paymentAmount = invoicePayment.amountUsed;
          entry.savedPaymentAmount = entry.paymentAmount;
          entry.invoicePaymentRecord = invoicePayment;
          entry.appliedPreviously = true;
        }
      });
    }
  }), _dec18 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var promiseArray = Ember.A();
    var newCustomerInvoicePayment;

    try {
      Object.values(this.entries).forEach(entry => {
        if (entry.dirty) {
          var paymentAmount = Number(entry.paymentAmount);

          if (entry.appliedPreviously) {
            // Edit previously applied record
            var invoicePaymentRecord = entry.invoicePaymentRecord;

            if (entry.isChecked) {
              // Update amount
              Ember.set(invoicePaymentRecord, 'amountUsed', paymentAmount);
              promiseArray.push(invoicePaymentRecord.save());
            } else {
              // Remove connection to invoice
              promiseArray.push(invoicePaymentRecord.destroyRecordWithRollbackTask.perform());
            }
          } else if (entry.isChecked) {
            // New record if has value
            newCustomerInvoicePayment = this.store.createRecord('customerInvoicePayment', {
              amountUsed: paymentAmount,
              invoice: entry.invoice,
              customerPayment: this.creditMemoPayment
            });
            promiseArray.push(newCustomerInvoicePayment.save());
          }
        }
      });
      yield (0, _emberConcurrency.all)(promiseArray);
      this.args.customer.invoices.reload();
      this.args.multiStepForm.formOriginalModelsGetVal('invoice.model').reload();
      this.creditMemoPayment.reload();
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });

      if (newCustomerInvoicePayment) {
        newCustomerInvoicePayment.rollbackAttributes();
      }

      throw err;
    }
  }), _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, (_class3 = (_temp2 = class CreditApplyToInvoice extends _base.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor10, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor11, this);

      _initializerDefineProperty(this, "intl", _descriptor12, this);

      _initializerDefineProperty(this, "isInvoiceToggleAll", _descriptor13, this);

      _initializerDefineProperty(this, "toggleAllClicked", _descriptor14, this);

      _defineProperty(this, "entries", {});

      _initializerDefineProperty(this, "initFormData", _descriptor15, this);

      _initializerDefineProperty(this, "getCustomerInvoicePayments", _descriptor16, this);

      _initializerDefineProperty(this, "applyCustomerInvoicePayments", _descriptor17, this);

      this.initFormData.perform();
    }

    get hiddenProgressBarSteps() {
      var hiddenArray = ['addPayment', 'listPayments', 'confirmRefundVoid', 'gettingStarted', 'viewProcessingInformation', 'history', 'editManualPayment'];
      return hiddenArray;
    }

    get disabledProgressBarSteps() {
      return [];
    }

    get totalAmount() {
      return parseFloat(Number(this.args.multiStepForm.formOriginalModelsGetVal('invoice.model.responseMeta.total_amount'))).toFixed(2);
    }

    get appliedAmount() {
      var appliedTotal = 0;
      Object.values(this.entries).forEach(entry => {
        if (entry.isChecked) {
          appliedTotal += Number(entry.paymentAmount);
        }
      });
      return appliedTotal;
    }

    get unallocatedAmount() {
      return Number(this.totalAmount) - Number(this.appliedAmount);
    }

    set unallocatedAmount(value) {
      return this._unallocatedAmount = value;
    }

    setPayment(entry, desiredAmount) {
      entry.dirty = true;
      entry.paymentAmount = 0;
      var maxDueAmount = Number(entry.dueAmount) + Number(entry.savedPaymentAmount);
      Ember.notifyPropertyChange(this, 'appliedAmount');

      if (!entry.isChecked || desiredAmount == 0) {
        entry.isChecked = false;
        entry.dueAmount = maxDueAmount;
        entry.savedPaymentAmount = 0;
      } else {
        var newPaymentAmount = desiredAmount;

        if (newPaymentAmount > maxDueAmount) {
          newPaymentAmount = maxDueAmount;
        }

        if (newPaymentAmount > this.unallocatedAmount) {
          newPaymentAmount = this.unallocatedAmount;
        }

        entry.paymentAmount = newPaymentAmount;
        entry.dueAmount = maxDueAmount - Number(entry.paymentAmount);
        entry.savedPaymentAmount = entry.paymentAmount;
        Ember.notifyPropertyChange(this, 'appliedAmount');
      }

      this.checkToggleAll();
    }

    checkToggleAll() {
      var allToggled = true;
      Object.values(this.entries).forEach(entry => {
        if (!entry.isChecked) {
          allToggled = false;
        }
      });
      Ember.set(this, 'isInvoiceToggleAll', this.toggleAllClicked ? this.isInvoiceToggleAll : allToggled);
      this.toggleAllClicked = false;
    }

    invoiceToggleAll() {
      var isToggled = this.isInvoiceToggleAll;
      this.toggleAllClicked = true;
      Object.values(this.entries).forEach(entry => {
        if (!isToggled && entry.isChecked) {
          // Remove all funds from checked row items
          this.setPayment(entry, 0);
        } else if (isToggled && !entry.isChecked && this.unallocatedAmount > 0 && entry.dueAmount > 0) {
          // Add funds to all unchecked rows if there are funds and the row dueAmount > 0
          entry.isChecked = true;
          this.setPayment(entry, Number(entry.paymentAmount) + Number(entry.dueAmount));
        }
      });
    }

    itemChecked(entry) {
      if (entry.isChecked && (this.unallocatedAmount === 0 || entry.dueAmount === 0)) {
        // Try to toggle line to ON but there are no more funds!
        // Deny
        entry.isChecked = false;
        return;
      }

      this.setPayment(entry, Number(entry.paymentAmount) + Number(entry.dueAmount));
    }

    updatePaymentAmount(entry) {
      this.setPayment(entry, Number(entry.paymentAmount));
    }

  }, _temp2), (_descriptor10 = _applyDecoratedDescriptor(_class3.prototype, "store", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class3.prototype, "stNotificationManager", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class3.prototype, "intl", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class3.prototype, "isInvoiceToggleAll", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class3.prototype, "toggleAllClicked", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class3.prototype, "unallocatedAmount", [_dec15], Object.getOwnPropertyDescriptor(_class3.prototype, "unallocatedAmount"), _class3.prototype), _descriptor15 = _applyDecoratedDescriptor(_class3.prototype, "initFormData", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class3.prototype, "getCustomerInvoicePayments", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class3.prototype, "applyCustomerInvoicePayments", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class3.prototype, "invoiceToggleAll", [_dec19], Object.getOwnPropertyDescriptor(_class3.prototype, "invoiceToggleAll"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "itemChecked", [_dec20], Object.getOwnPropertyDescriptor(_class3.prototype, "itemChecked"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "updatePaymentAmount", [_dec21], Object.getOwnPropertyDescriptor(_class3.prototype, "updatePaymentAmount"), _class3.prototype)), _class3));
  _exports.default = CreditApplyToInvoice;
});