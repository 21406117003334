define("securitytrax-ember-ui/components/multi-step-form/forms/administration/system/global-settings/edit-inventory/component", ["exports", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "ember-concurrency", "lodash"], function (_exports, _component, _switchableFormStep, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EditInventorySettingsClass = (_dec = Ember.inject.service('stNotificationManager'), _dec2 = Ember.inject.service('sessionData'), _dec3 = Ember.inject.service('grants'), _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = (0, _emberConcurrency.task)(function* () {
    this.company = yield this.sessionData.getCompany();
    var inventoryRequests = yield (0, _emberConcurrency.hash)({
      spillage_deducts_onhand: this.company.invokeAction('get_spillage_deducts_onhand', {
        verb: 'GET'
      }),
      rma_deducts_onhand: this.company.invokeAction('get_rma_deducts_onhand', {
        verb: 'GET'
      })
    });
    this.inventoryData = {
      spillage_deducts_onhand: _lodash.default.get(inventoryRequests, 'spillage_deducts_onhand.responsePayload.data.spillage_deducts_onhand', false),
      rma_deducts_onhand: _lodash.default.get(inventoryRequests, 'rma_deducts_onhand.responsePayload.data.rma_deducts_onhand', false)
    };
    this.inventoryDataCopy = _lodash.default.cloneDeep(this.inventoryData);
  }), _dec9 = (0, _emberConcurrency.task)(function* () {
    try {
      this.inventoryData = _lodash.default.cloneDeep(this.inventoryDataCopy);
      Ember.notifyPropertyChange(this, 'inventoryData');
      Ember.notifyPropertyChange(this.inventoryData, 'spillage_deducts_onhand');
      Ember.notifyPropertyChange(this.inventoryData, 'rma_deducts_onhand');
      yield Ember.set(this, 'formMode', 'view');
      this.stNotificationManager.addSuccessKey('common.notifications.generic_cancel');
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err);
    }
  }).drop(), _dec10 = (0, _emberConcurrency.task)(function* () {
    this.company = yield this.sessionData.getCompany();

    try {
      // console.log('saving this.inventoryData', this.inventoryData);
      // DREW TODO: the below code reproduces a BE race condition if you try and switch both at the same time
      // commenting for now so that I can bring it back later to help the BE reproduce the issue
      // const results = yield hash({
      //     set_spillage_deducts_onhand: this.company.invokeAction('set_spillage_deducts_onhand', {
      //         verb: 'POST',
      //         data: {
      //             data: {
      //                 spillage_deducts_onhand: this.inventoryData.spillage_deducts_onhand
      //             }
      //         }
      //     }),
      //     set_rma_deducts_onhand: this.company.invokeAction('set_rma_deducts_onhand', {
      //         verb: 'POST',
      //         data: {
      //             data: {
      //                 rma_deducts_onhand: this.inventoryData.rma_deducts_onhand
      //             }
      //         }
      //     }),
      // });
      // const resultsCompiled = {
      //     spillage_deducts_onhand: lodash.get(results, 'set_spillage_deducts_onhand.responsePayload.data.spillage_deducts_onhand', false),
      //     rma_deducts_onhand: lodash.get(results, 'set_rma_deducts_onhand.responsePayload.data.rma_deducts_onhand', false),
      // };
      // console.log('resultsCompiled', resultsCompiled);
      yield this.company.invokeAction('set_spillage_deducts_onhand', {
        verb: 'POST',
        data: {
          data: {
            spillage_deducts_onhand: this.inventoryData.spillage_deducts_onhand
          }
        }
      });
      yield this.company.invokeAction('set_rma_deducts_onhand', {
        verb: 'POST',
        data: {
          data: {
            rma_deducts_onhand: this.inventoryData.rma_deducts_onhand
          }
        }
      });
      this.inventoryDataCopy = _lodash.default.cloneDeep(this.inventoryData);
      Ember.set(this, 'formMode', 'view');
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
    } catch (e) {
      this.stNotificationManager.addErrorMessages(e);
    }
  }).restartable(), (_class = (_temp = class EditInventorySettingsClass extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor, this);

      _initializerDefineProperty(this, "sessionData", _descriptor2, this);

      _initializerDefineProperty(this, "grants", _descriptor3, this);

      _initializerDefineProperty(this, "company", _descriptor4, this);

      _initializerDefineProperty(this, "inventoryData", _descriptor5, this);

      _initializerDefineProperty(this, "inventoryDataCopy", _descriptor6, this);

      _initializerDefineProperty(this, "formMode", _descriptor7, this);

      _initializerDefineProperty(this, "initTask", _descriptor8, this);

      _initializerDefineProperty(this, "cancelTask", _descriptor9, this);

      _initializerDefineProperty(this, "saveInventorySettingsTask", _descriptor10, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.initTask.perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "company", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "inventoryData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "inventoryDataCopy", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "formMode", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'view';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "initTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "cancelTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "saveInventorySettingsTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = EditInventorySettingsClass;
});