define("securitytrax-ember-ui/components/html/st-await/component", ["exports", "securitytrax-ember-ui/utils/obj-helpers", "ember-concurrency", "uuid"], function (_exports, _objHelpers, _emberConcurrency, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    _loaded: undefined,
    _resolvedPromises: undefined,
    _awaitPromise: undefined,
    _error: undefined,
    _hasError: undefined,
    promises: null,
    booleanLoaded: null,
    stayLoaded: null,
    spinnerClass: undefined,
    size: undefined,
    useSpinner: undefined,
    useSelfSpinner: undefined,
    loadedNamespace: undefined,
    awaitUuid: undefined,
    onloadCallback: undefined,
    stAwait: Ember.inject.service(),

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        _loaded: false,
        _resolvedPromises: {},
        _awaitPromise: {
          state: 'nostate'
        },
        _error: 'no error',
        _hasError: false,
        spinnerClass: null,
        size: 'small',
        useSpinner: false,
        useSelfSpinner: false,
        loadedNamespace: 'global',
        awaitUuid: (0, _uuid.v4)()
      });
    },

    willDestroyElement() {
      this.stAwait.deregisterAwait(this.awaitUuid, {
        loadedNamespace: this.loadedNamespace
      });
    },

    didReceiveAttrs() {
      this._super(...arguments);

      if (this.promises == null && this.booleanLoaded == null || this.promises === undefined || this.booleanLoaded === undefined) {
        // DREW TODO - eventually this should throw an exception
        (false && Ember.warn('you are using st-await incorrectly!', {
          id: 'components.html.st-await.didReceiveAttrs'
        }));
      }

      if (this.promises === null) {
        Ember.set(this, 'promises', {});
      }

      if (this.booleanLoaded === undefined) {
        Ember.set(this, 'booleanLoaded', false);
      } else if (this.booleanLoaded === null) {
        Ember.set(this, 'booleanLoaded', true);
      } else {
        (0, _objHelpers.initializeState)(this, {
          booleanLoaded: true
        });
      }

      if (this.get('_awaitPromise.state') !== 'nostate') {
        this._awaitPromise.cancel();
      }

      var _awaitPromise = this.watchPromisesTask.perform();

      Ember.set(this, '_awaitPromise', _awaitPromise);
      this.stAwait.registerAwait(this.awaitUuid, {
        loadedNamespace: this.loadedNamespace,
        awaitPromise: _awaitPromise
      });
    },

    watchPromisesTask: (0, _emberConcurrency.task)(function* () {
      try {
        Ember.set(this, '_loaded', false);
        yield (0, _emberConcurrency.waitForProperty)(this, 'booleanLoaded', true);

        var _resolvedPromises = yield Ember.RSVP.hash(this.promises);

        Ember.set(this, '_resolvedPromises', _resolvedPromises);
        Ember.set(this, '_loaded', true);

        if (this.onloadCallback) {
          this.onloadCallback();
        }
      } catch (error) {
        (false && Ember.warn('st-await promise failed', {
          id: 'components.html.st-await.watchPromisesTask'
        }));
        (false && Ember.warn(error, {
          id: 'components.html.st-await.watchPromisesTask'
        }));
        Ember.set(this, '_hasError', true);
        Ember.set(this, '_error', error);
        Ember.set(this, '_loaded', true);

        if (this.onloadCallback) {
          this.onloadCallback();
        }

        throw error;
      }
    })
  });

  _exports.default = _default;
});