define("securitytrax-ember-ui/components/multi-step-form/progress-bar/views/default-view/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    collapsed: true,
    isCollapsed: Ember.computed('collapsed', function () {
      return !this.collapsed;
    }),
    _clickAction: Ember.observer('config.items.[].clickAction', function () {
      this.setClickActions();
    }),

    init() {
      this._super(...arguments);

      this.setClickActions();
    },

    didInsertElement: function () {},
    setClickActions: function () {
      this.get('config.items').forEach(item => {
        if (!item.clickAction) {
          item.clickAction = function () {};
        }
      });
    },
    actions: {
      itemClass(index) {
        var activeIndex = this.get('config.activeIndex');
        return activeIndex == index ? 'selected' : '';
      },

      triggerNavBar() {
        this.toggleProperty('collapsed');
      }

    }
  });

  _exports.default = _default;
});