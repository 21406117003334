define("securitytrax-ember-ui/utils/animations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.INACTIVE = _exports.IN = _exports.OUT = _exports.ACTIVE = void 0;

  /**
   * Utility helper module for icon animation states.
   * @module
   */

  /**
   * Animation is active.
   *
   * @type {number}
   */
  var ACTIVE = 1;
  /**
   * Animation is exiting.
   *
   * @type {number}
   */

  _exports.ACTIVE = ACTIVE;
  var OUT = 2;
  /**
   * Animation is entering.
   *
   * @type {number}
   */

  _exports.OUT = OUT;
  var IN = 3;
  /**
   * Animation is inactive.
   *
   * @type {number}
   */

  _exports.IN = IN;
  var INACTIVE = 4;
  _exports.INACTIVE = INACTIVE;
});