define("securitytrax-ember-ui/components/pages/my-account/login-information/component", ["exports", "moment", "ember-concurrency"], function (_exports, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    passwordResetForm: Ember.computed(function () {
      return Ember.Object.create({
        passwordReset: Ember.Object.create({
          stepNameKey: 'passwordReset',
          title: this.intl.t('pages.users.login_information.change_password'),
          stepComponentPath: 'multi-step-form/forms/administration/users/my-account/password-reset'
        })
      });
    }),
    passwordFormOriginalModels: Ember.computed('model.user', function () {
      return Ember.Object.create({
        user: {
          model: this.get('model.user'),
          copyRelationMap: {
            userPasswordSetting: {
              doCopy: true,
              doValidate: true,
              doSave: true
            }
          }
        }
      });
    }),

    get passwordExpires() {
      return this.passwordExpiresTask.perform();
    },

    passwordExpiresTask: (0, _emberConcurrency.task)(function* () {
      var globalSettings = yield this.sessionData.getCompanySettings();
      var lastChange = this.get('model.userPasswordSetting.lastPasswordChangeTime');
      var changePasswordDays = globalSettings.minDaysBeforePasswordChange;
      var expires = changePasswordDays ? (0, _moment.default)(lastChange).add(changePasswordDays, 'days').format('MM/DD/YYYY h:m a') : this.intl.t('pages.users.login_information.never_expires');
      return expires;
    })
  });

  _exports.default = _default;
});