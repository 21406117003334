define("securitytrax-ember-ui/models/base_models/notification-subscription", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    objectType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['user', 'company']
    }),
    sendEmail: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    sendSms: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    objectToNotificationType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['subscribe', 'unsubscribe']
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    user: (0, _model.belongsTo)('user', {
      'readOnly': false,
      'readOnlyUpdate': true
    }),
    company: (0, _model.belongsTo)('company', {
      'readOnly': false,
      'readOnlyUpdate': true
    }),
    notification: (0, _model.belongsTo)('notification', {
      'readOnly': false,
      'readOnlyUpdate': true
    })
  });

  _exports.default = _default;
});