define("securitytrax-ember-ui/components/multi-step-form/forms/administration/content-management/edit-invoice-quote-template/component", ["exports", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "ember-concurrency", "securitytrax-ember-ui/mixed-in-classes/row-list"], function (_exports, _component, _switchableFormStep, _emberConcurrency, _rowList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EditInvoiceQuoteTemplate = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
    this.detailedBillableItems = yield this.detailBillableItemsTask.perform();
    var listArray = [];
    this.fieldDefaults = Ember.Object.create({
      expires: null,
      internal_note: null,
      external_note: null
    });

    if (this.SwitchableFormStep.isNewRecord) {
      this.args.multiStepForm.formCopiedModelsSetVal('template.model.active', true);
    } else {
      var template = this.args.multiStepForm.formCopiedModelsGetVal('template.model');
      var templateFieldDefaults = yield template.get('templateFieldDefaults');
      templateFieldDefaults.forEach(fieldDefault => {
        var typeColumn = fieldDefault.get('templateTypeColumn');
        this.fieldDefaults[typeColumn.get('columnName')] = fieldDefault.get('value');
      });
      var lineItemDefaults = templateFieldDefaults.filterBy('templateTypeColumn.columnName', 'invoice_line_items');

      if (lineItemDefaults) {
        lineItemDefaults.forEach(fieldDefault => {
          var item = Ember.Object.create(JSON.parse(fieldDefault.get('value')));
          item['id'] = fieldDefault.get('id');
          listArray.push(item);
        });
      }
    }

    Ember.set(this.RowList, 'listArray', listArray.sortBy('sort'));

    if ((this.RowList.listIsEmpty || this.RowList.listArray[this.RowList.listArray.length - 1]['quantity']) && (!this.args.workOrder || !this.SwitchableFormStep.isNewRecord)) {
      this.RowList.addRow();
    }
  }), _dec16 = (0, _emberConcurrency.task)(function* () {
    var promiseArray = [];
    var billableItems = yield this.store.query('invoiceBillableItem', {
      limit: 0,
      include: 'equipment'
    });
    billableItems.forEach(billableItem => {
      promiseArray.push(this.grabBillableItemDataTask.perform(billableItem));
    });
    return yield (0, _emberConcurrency.all)(promiseArray);
  }), _dec17 = (0, _emberConcurrency.task)(function* (billableItem) {
    var equipment = yield billableItem.get('equipment');
    var code = billableItem.code;
    var description = billableItem.description;
    var model = this.intl.t('pages.administration.content_management.invoice_billable_item.model') + (equipment && equipment.model ? equipment.model : '--');
    var sku = this.intl.t('pages.administration.content_management.invoice_billable_item.sku') + (equipment && equipment.sku ? equipment.sku : '--');
    var mfg = this.intl.t('pages.administration.content_management.invoice_billable_item.mfg') + (equipment && equipment.manufacturer ? equipment.manufacturer : '--');
    var detailString = this.intl.t('pages.administration.content_management.invoice_billable_item.detailedDescription', {
      code,
      description,
      model,
      sku,
      mfg
    });
    return {
      billableItem,
      id: billableItem.get('id'),
      details: detailString,
      code,
      description,
      model,
      sku,
      mfg,
      selected: 'false',
      current: 'false',
      active: billableItem.get('active')
    };
  }), _dec18 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    var formattedObjects = [];

    if (this.SwitchableFormStep.isNewRecord) {
      Object.keys(this.fieldDefaults).forEach(key => {
        if (this.fieldDefaults[key]) {
          formattedObjects.push(this.newFieldDefault(key, this.fieldDefaults[key]));
        }
      });
      this.RowList.trueListArray.forEach((lineItem, index) => {
        formattedObjects.push(this.newLineItemFieldDefault(lineItem, index));
      });
      formattedObjects.push(Ember.Object.create({
        modelName: 'template',
        model: multiStepForm.formCopiedModelsGetVal('template.model')
      }));
      var toCreateObjects = yield multiStepForm.validateCreateList.perform(formattedObjects, {
        notifcationNamespace: 'modal'
      });
      toCreateObjects.template[0].data.templateType = yield this.args.templateTypes.findBy('id', '3');
      var templateToCreate = this.store.createRecord('template', toCreateObjects.template[0].data);
      var createdTemplate = yield toCreateObjects.template[0].saveWithRollbackAndNotification(templateToCreate, 'modal');

      if (toCreateObjects.templateFieldDefault) {
        toCreateObjects.templateFieldDefault.forEach(item => {
          var newFieldDefault = this.store.createRecord('templateFieldDefault', {
            value: item.data.value,
            templateTypeColumn: item.data.templateTypeColumn,
            template: createdTemplate
          });
          toCreateObjects.template[0].saveWithRollbackAndNotification(newFieldDefault, 'modal');
        });
      }
    } else {
      Object.keys(this.fieldDefaults).forEach(key => {
        if (this.fieldDefaults[key]) {
          var existingDefault = multiStepForm.formCopiedModelsGetVal('template.model.templateFieldDefaults').findBy('templateTypeColumn.columnName', key);

          if (existingDefault) {
            existingDefault.set('value', this.fieldDefaults[key]);
          } else {
            formattedObjects.push(this.newFieldDefault(key, this.fieldDefaults[key]));
          }
        }
      });
      this.RowList.trueListArray.forEach((lineItem, index) => {
        if (lineItem.id.includes('new')) {
          formattedObjects.push(this.newLineItemFieldDefault(lineItem, index));
        } else {
          var existingDefault = multiStepForm.formCopiedModelsGetVal('template.model.templateFieldDefaults').findBy('id', lineItem.id);
          existingDefault.set('value', JSON.stringify({
            invoice_billable_item_id: lineItem.invoice_billable_item_id,
            quantity: lineItem.quantity,
            unit_price: lineItem.unit_price,
            sort: index
          }));
        }
      });
      yield multiStepForm.validateSyncSaveAll({
        notifcationNamespace: 'modal'
      });
      this.RowList.itemsToDeleteList.forEach(lineItemId => {
        var existingDefault = multiStepForm.formOriginalModelsGetVal('template.model.templateFieldDefaults').findBy('id', lineItemId);
        existingDefault.destroyRecordWithRollbackTask.perform();
      });

      if (formattedObjects.length > 0) {
        var _toCreateObjects = yield multiStepForm.validateCreateList.perform(formattedObjects, {
          notifcationNamespace: 'modal'
        });

        _toCreateObjects.templateFieldDefault.forEach(item => {
          var newFieldDefault = this.store.createRecord('templateFieldDefault', {
            value: item.data.value,
            templateTypeColumn: item.data.templateTypeColumn,
            template: multiStepForm.formOriginalModelsGetVal('template.model')
          });
          item.saveWithRollbackAndNotification(newFieldDefault, 'modal');
        });
      }
    }

    this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
    buttonPromise.then(() => {
      multiStepForm.formStartedSetVal(false);
    });
  }), _dec19 = (0, _emberConcurrency.task)(function* () {
    var templateToDelete = this.args.multiStepForm.formOriginalModelsGetVal('template.model');

    try {
      yield templateToDelete.destroyRecordWithRollbackTask.perform();
      this.args.multiStepForm.onDelete();
      this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
      this.args.multiStepForm.formStartedSetVal(false);
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'confirmDeleteModal'
      });
      throw err;
    }
  }), _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, _dec23 = Ember._action, (_class = (_temp = class EditInvoiceQuoteTemplate extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor3, this);

      _initializerDefineProperty(this, "userService", _descriptor4, this);

      _initializerDefineProperty(this, "grants", _descriptor5, this);

      _initializerDefineProperty(this, "screen", _descriptor6, this);

      _initializerDefineProperty(this, "confirmDeleteModalOpen", _descriptor7, this);

      _initializerDefineProperty(this, "priorityOptions", _descriptor8, this);

      _initializerDefineProperty(this, "fieldDefaults", _descriptor9, this);

      _initializerDefineProperty(this, "assignedUsers", _descriptor10, this);

      _initializerDefineProperty(this, "userList", _descriptor11, this);

      _initializerDefineProperty(this, "invoiceBillableItems", _descriptor12, this);

      _initializerDefineProperty(this, "fieldDefaults", _descriptor13, this);

      _initializerDefineProperty(this, "detailedBillableItems", _descriptor14, this);

      _initializerDefineProperty(this, "initFormData", _descriptor15, this);

      _initializerDefineProperty(this, "detailBillableItemsTask", _descriptor16, this);

      _initializerDefineProperty(this, "grabBillableItemDataTask", _descriptor17, this);

      _initializerDefineProperty(this, "saveInvoiceQuoteTemplate", _descriptor18, this);

      _initializerDefineProperty(this, "deleteInvoiceQuoteTemplate", _descriptor19, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.RowList = new _rowList.default(...arguments);
      this.initFormData.perform();
    }

    get expireOptions() {
      var options = [];
      var expiresColumn = this.args.templateTypeColumns.findBy('columnName', 'expires');
      Object.keys(expiresColumn.get('options')).forEach(key => {
        options.push({
          value: key,
          label: expiresColumn.get('options')[key].toString()
        });
      });
      return options;
    }

    newLineItemFieldDefault(lineItem, index) {
      return this.newFieldDefault('invoice_line_items', JSON.stringify({
        invoice_billable_item_id: lineItem.invoice_billable_item_id,
        quantity: lineItem.quantity,
        unit_price: lineItem.unit_price,
        sort: index
      }));
    }

    newFieldDefault(key, value) {
      var templateTypeColumn = this.args.templateTypeColumns.findBy('columnName', key);
      return Ember.Object.create({
        modelName: 'templateFieldDefault',
        model: {
          value,
          templateTypeColumn,
          active: true
        }
      });
    }

    renderButtons() {
      var buttons = [];

      if (this.args.formMode !== 'view') {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveInvoiceQuoteTemplate.perform(this.args.multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary invoice-quote-template-save-button'
        });
      }

      if (!this.SwitchableFormStep.isNewRecord && this.grants.getIndividualGrant('templates.delete')) {
        buttons.push({
          text: this.intl.t('common.actions.delete'),
          click: () => {
            this.confirmDeleteModalOpen = true;
          },
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
        });
      }

      this.args.multiStepForm.registerStepData('step1', 'buttons', buttons);
    }

    updateLineItem(index) {
      var lineItems = this.RowList.listArray;
      var lineItem = lineItems[index];
      Ember.set(lineItem, 'quantity', 1);
      this.RowList.updateRow(lineItem, index);
      Ember.set(this, 'lastIndex', this.RowList.listArray.length - 1);
    }

    editLineItem(row, index) {
      var lineItems = this.RowList.listArray;
      var lineItem = lineItems[index];
      var quantity = 1;

      if (Number(row.quantity) !== 0) {
        var absQuantity = Math.abs(Number(row.quantity));
        quantity = row.invoice_billable_item_id.entityType === 'equipment' || absQuantity % 1 == 0 ? absQuantity.toFixed(0) : absQuantity.toFixed(2);
      }

      Ember.set(lineItem, 'quantity', quantity);
      Ember.set(lineItem, 'unitPrice', row.unitPrice);
      this.RowList.updateRow(lineItem, index);
      Ember.notifyPropertyChange(this.RowList, 'listArray');
    }

    handleEnter(select, e) {
      if (e.keyCode === 13) {
        if (select.searchText && select.highlighted) {
          select.actions.choose(select.highlighted.billableItem);
        } else if (select.isOpen) {
          return false;
        }
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "userService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "screen", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "confirmDeleteModalOpen", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "priorityOptions", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "fieldDefaults", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "assignedUsers", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "userList", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "invoiceBillableItems", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "fieldDefaults", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "detailedBillableItems", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "initFormData", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "detailBillableItemsTask", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "grabBillableItemDataTask", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "saveInvoiceQuoteTemplate", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "deleteInvoiceQuoteTemplate", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "renderButtons", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "renderButtons"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateLineItem", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "updateLineItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editLineItem", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "editLineItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleEnter", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "handleEnter"), _class.prototype)), _class));
  _exports.default = EditInvoiceQuoteTemplate;
});