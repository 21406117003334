define("securitytrax-ember-ui/components/multi-step-form/forms/system/adt/steps/linked-orders/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/system/adt/adt-form-base", "ember-concurrency"], function (_exports, _adtFormBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adtFormBase.default.extend({
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    centralStationService: Ember.inject.service(),
    cancelLinkedOrderModalOpen: false,
    linkedOrders: Ember.computed(function () {
      return this.getLinkedOrdersTask.perform();
    }),
    getLinkedOrdersTask: (0, _emberConcurrency.task)(function* () {
      try {
        var linkedOrders = yield this.centralStationService.getData({
          model: yield this.customer,
          method: 'adt__getLinkedOrders'
        });
        return linkedOrders.linked_orders;
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'adtMegaFormModal'
        });
        return [];
      }
    }),
    cancelLinkedOrder: (0, _emberConcurrency.task)(function* (order) {
      try {
        yield this.centralStationService.getData({
          model: yield this.customer,
          method: 'adt__cancelLinkedOrder',
          payload: {
            order_id: order.order_id
          }
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'cancelLinkedOrder'
        });
        throw err;
      }
    })
  });

  _exports.default = _default;
});