define("securitytrax-ember-ui/components/customer/appointments/appointments-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    additionalCardClass: '',
    showCard: true,
    type: 'tech'
  });

  _exports.default = _default;
});