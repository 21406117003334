define("securitytrax-ember-ui/components/pages/customer/messages/component", ["exports", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/table-column-sort", "ember-concurrency", "lodash", "ember-inflector"], function (_exports, _component, _tableColumnSort, _emberConcurrency, _lodash, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CustomerMessagesTab = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service('events/transitionEvent'), _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember.computed, _dec16 = (0, _emberConcurrency.task)(function* () {
    var sortedMessages = [];
    var messages;

    try {
      var _this$MessagesTableCo, _this$MessagesTableCo2;

      messages = yield this.stQueryParams.loadRelationshipWithQueryParams({
        model: this.args.model.customer,
        relName: this.args.customerOrLead === 'lead' ? 'leadMessages' : 'customerMessages',
        useCallback: false,
        options: {
          params: {
            limit: this.messagesLimit,
            page: this.messagesPage,
            include: 'message_log',
            //sort: this.MessagesTableColumnSort.stSortString, // Is sort supported on rel calls?
            count: 1
          }
        }
      });
      sortedMessages = this.MessagesTableColumnSort.sortEmberDataCollection(messages, (_this$MessagesTableCo = this.MessagesTableColumnSort.stSortCurrentSortColumn) !== null && _this$MessagesTableCo !== void 0 ? _this$MessagesTableCo : 'id', (_this$MessagesTableCo2 = this.MessagesTableColumnSort.stSortAscending) !== null && _this$MessagesTableCo2 !== void 0 ? _this$MessagesTableCo2 : false);
    } catch (err) {// Do nothing cause we're just ensuring we don't error out here until BE occassional 404 is fixed
    }

    Ember.set(this, 'messagesTotalCount', messages && messages.length > 0 ? messages.meta.record_count : 0);
    return sortedMessages;
  }), _dec17 = Ember.computed, _dec18 = (0, _emberConcurrency.task)(function* () {
    var sortedAccountingMessages = [];
    var accountingMessages;
    var accountingTypes = ['\'invoice_statements\''];
    var invoiceEnums = this.store.createRecord('invoice').enumLists();
    invoiceEnums.invoiceTypeOptions.forEach(invoiceType => {
      accountingTypes.push('\'' + invoiceType.id + '\'');
    });

    try {
      var _this$AccountingMessa, _this$AccountingMessa2;

      accountingMessages = yield this.store.query('message-log', {
        filter: 'customer=\'' + this.args.model.customer.get('id') + '\' and resource_type_id = [' + accountingTypes.join(',') + ']',
        limit: this.accountingMessagesLimit,
        page: this.accountingMessagesPage,
        //sort: this.AccountingMessagesTableColumnSort.stSortString, // Is sort supported on rel calls?
        count: 1
      });
      sortedAccountingMessages = this.MessagesTableColumnSort.sortEmberDataCollection(accountingMessages, (_this$AccountingMessa = this.AccountingMessagesTableColumnSort.stSortCurrentSortColumn) !== null && _this$AccountingMessa !== void 0 ? _this$AccountingMessa : 'id', (_this$AccountingMessa2 = this.AccountingMessagesTableColumnSort.stSortAscending) !== null && _this$AccountingMessa2 !== void 0 ? _this$AccountingMessa2 : false);
    } catch (err) {// Do nothing cause we're just ensuring we don't error out here until BE merges TRAX-11913
    }

    Ember.set(this, 'accountingMessagesTotalCount', accountingMessages && accountingMessages.length > 0 ? accountingMessages.meta.record_count : 0);
    return sortedAccountingMessages;
  }), _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, _dec23 = Ember._action, _dec24 = Ember._action, _dec25 = Ember._action, _dec26 = Ember._action, (_class = (_temp = class CustomerMessagesTab extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "stQueryParams", _descriptor4, this);

      _initializerDefineProperty(this, "transitionEvent", _descriptor5, this);

      _initializerDefineProperty(this, "customerMessage", _descriptor6, this);

      _initializerDefineProperty(this, "customerMessageFormStarted", _descriptor7, this);

      _initializerDefineProperty(this, "customerMessageFormMode", _descriptor8, this);

      _initializerDefineProperty(this, "messagesLimit", _descriptor9, this);

      _initializerDefineProperty(this, "messagesPage", _descriptor10, this);

      _initializerDefineProperty(this, "messagesTotalCount", _descriptor11, this);

      _initializerDefineProperty(this, "accountingMessagesLimit", _descriptor12, this);

      _initializerDefineProperty(this, "accountingMessagesPage", _descriptor13, this);

      _initializerDefineProperty(this, "accountingMessagesTotalCount", _descriptor14, this);

      _initializerDefineProperty(this, "getCustomerMessagesTask", _descriptor15, this);

      _initializerDefineProperty(this, "getAccountingMessagesTask", _descriptor16, this);

      this.transitionEvent.on('refresh-customer-messages', this, '_refreshList');
      this.MessagesTableColumnSort = new _tableColumnSort.default(...arguments, [{
        name: this.intl.t('common.columns.id'),
        value: 'id'
      }, {
        name: this.intl.t('pages.customers.messages.list_headers.date_sent'),
        value: 'createdAt'
      }, {
        name: this.intl.t('pages.customers.messages.list_headers.template'),
        value: 'templateMessage.name'
      }, {
        name: this.intl.t('pages.customers.messages.list_headers.delivery_type'),
        value: 'templateMessage.deliveryProtocol'
      }]);
      this.AccountingMessagesTableColumnSort = new _tableColumnSort.default(...arguments, [{
        name: this.intl.t('common.columns.id'),
        value: 'id'
      }, {
        name: this.intl.t('pages.customers.messages.list_headers.date_sent'),
        value: 'createdAt'
      }, {
        name: this.intl.t('pages.customers.messages.list_headers.message_item'),
        value: 'messageItemDisplay'
      }, {
        name: this.intl.t('pages.customers.messages.list_headers.delivery_type'),
        value: 'resourceTypeInstanceIdDisplay'
      }]);
    }

    willDestroy() {
      this.transitionEvent.off('refresh-customer-messages', this, '_refreshList');
    }

    _refreshList() {
      var messageType = this.args.customerOrLead === 'lead' ? 'leadMessages' : 'customerMessages';
      this.args.model.customer.hasMany("".concat(messageType)).reload().then(() => {
        Ember.notifyPropertyChange(this, 'customerMessages');
      });
    }

    get customerMessages() {
      return this.getCustomerMessagesTask.perform();
    }

    get accountingMessages() {
      return this.getAccountingMessagesTask.perform();
    }

    sortList(column, ascending) {
      this.MessagesTableColumnSort.sortPaginatedList(column, ascending);
      Ember.notifyPropertyChange(this, 'customerMessages');
    }

    sortAccountingMessagesList(column, ascending) {
      this.AccountingMessagesTableColumnSort.sortPaginatedList(column, ascending);
      Ember.notifyPropertyChange(this, 'accountingMessages');
    }

    mobileSortList(sortColumn) {
      this.MessagesTableColumnSort.sortMobilePaginatedList(sortColumn.value);
      var ascending = !_lodash.default.startsWith(sortColumn.value, '-');
      Ember.setProperties(this.MessagesTableColumnSort, {
        stSortCurrentSortColumn: ascending ? sortColumn.value : sortColumn.value.slice(1),
        stSortAscending: ascending
      });
      Ember.notifyPropertyChange(this, 'customerMessages');
    }

    mobileSortAccountingMessagesList(sortColumn) {
      this.MessagesTableColumnSort.sortMobilePaginatedList(sortColumn.value);
      var ascending = !_lodash.default.startsWith(sortColumn.value, '-');
      Ember.setProperties(this.AccountingMessagesTableColumnSort, {
        stSortCurrentSortColumn: ascending ? sortColumn.value : sortColumn.value.slice(1),
        stSortAscending: ascending
      });
      Ember.notifyPropertyChange(this, 'accountingMessages');
    }

    refreshList() {
      this._refreshList();
    }

    transitionToSubroute(path) {
      this.router.transitionTo((0, _emberInflector.pluralize)(this.args.customerOrLead) + '.messages.create-message', path);
    }

    messagesFilterSearch(setPage = true) {
      if (setPage) {
        Ember.set(this, 'page', 1);
      }

      Ember.notifyPropertyChange(this, 'customerMessages');
    }

    accountingMessagesFilterSearch(setPage = true) {
      if (setPage) {
        Ember.set(this, 'page', 1);
      }

      Ember.notifyPropertyChange(this, 'accountingMessages');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stQueryParams", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "transitionEvent", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "customerMessage", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "customerMessageFormStarted", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "customerMessageFormMode", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'edit';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "messagesLimit", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 25;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "messagesPage", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "messagesTotalCount", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "accountingMessagesLimit", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 25;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "accountingMessagesPage", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "accountingMessagesTotalCount", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "customerMessages", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "customerMessages"), _class.prototype), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "getCustomerMessagesTask", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "accountingMessages", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "accountingMessages"), _class.prototype), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "getAccountingMessagesTask", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "sortList", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "sortList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortAccountingMessagesList", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "sortAccountingMessagesList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mobileSortList", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "mobileSortList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mobileSortAccountingMessagesList", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "mobileSortAccountingMessagesList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refreshList", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "refreshList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transitionToSubroute", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToSubroute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "messagesFilterSearch", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "messagesFilterSearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "accountingMessagesFilterSearch", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "accountingMessagesFilterSearch"), _class.prototype)), _class));
  _exports.default = CustomerMessagesTab;
});