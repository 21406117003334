define("securitytrax-ember-ui/models/base_models/lead-information", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    leadCreated: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    leadLastAssigned: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    leadLastRequested: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    leadRequestedCount: (0, _model.attr)('number', {
      'readOnly': true
    }),
    leadPurchasePrice: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    leadSalePrice: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    leadComments: (0, _model.attr)('string', {
      'readOnly': false
    }),
    leadSourceInfo: (0, _model.attr)('string', {
      'readOnly': false
    }),
    leadCallbackDate: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    leadIsStarred: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    leadCallcenterRep: (0, _model.attr)('string', {
      'readOnly': false
    }),
    leadCallcenterNotes: (0, _model.attr)('string', {
      'readOnly': false
    }),
    leadPromoCode: (0, _model.attr)('string', {
      'readOnly': false
    }),
    leadCustom1: (0, _model.attr)('string', {
      'readOnly': false
    }),
    leadCustom2: (0, _model.attr)('string', {
      'readOnly': false
    }),
    leadCustom3: (0, _model.attr)('string', {
      'readOnly': false
    }),
    leadCustom4: (0, _model.attr)('string', {
      'readOnly': false
    }),
    customer: (0, _model.belongsTo)('customer', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    leadRepUser: (0, _model.belongsTo)('user', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    dealerNumber: (0, _model.belongsTo)('dealer-number', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    monitoringCompany: (0, _model.belongsTo)('monitoring-company', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    leadStatus: (0, _model.belongsTo)('lead-status', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});