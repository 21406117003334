define("securitytrax-ember-ui/models/payable-payee-level", ["exports", "securitytrax-ember-ui/models/base_models/payable-payee-level", "ember-concurrency"], function (_exports, _payablePayeeLevel, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _payablePayeeLevel.default.extend({
    payee: Ember.computed('governmentRegulation', 'customer', 'leadCompanyCampaign', 'leadCompany', 'user', function () {
      return this.payeeTask.perform();
    }),
    payeeTask: (0, _emberConcurrency.task)(function* () {
      var payeeData = yield (0, _emberConcurrency.hash)({
        customer: this.customer,
        user: this.user,
        leadCompany: this.leadCompany,
        leadCompanyCampaign: this.leadCompanyCampaign,
        governmentRegulation: this.governmentRegulation
      });
      var entity = payeeData.customer || payeeData.user || payeeData.leadCompany || payeeData.leadCompanyCampaign || payeeData.governmentRegulation;
      return entity ? entity.get('name') : '';
    })
  });

  _exports.default = _default;
});