define("securitytrax-ember-ui/services/user-has-checked-in-appointment-service", ["exports", "securitytrax-ember-ui/utils/interval", "ember-concurrency", "securitytrax-ember-ui/config/environment"], function (_exports, _interval, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

  function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

  var _default = Ember.Service.extend({
    techToday: Ember.inject.service('events/tech-today'),
    sessionData: Ember.inject.service(),
    interval: undefined,
    user: undefined,
    onTechAtWorkPage: false,

    init() {
      this._super(...arguments);

      this.techToday.on('reset-checked-in-appointment-data', this, '_eventCallback');
      this.interval = _interval.default.create();
      this.interval.setContext(this);
      this.interval.setFunction(this.updateElapsedTimeSinceCheckIn);
      this.initDataTask.perform();
    },

    initDataTask: (0, _emberConcurrency.task)(function* () {
      var user = yield this.sessionData.getUser();
      this.set('user', user);
    }),

    _eventCallback(calendarEvent) {
      Ember.notifyPropertyChange(calendarEvent, 'appointmentStatusData');
      Ember.run.later(() => {
        Ember.notifyPropertyChange(calendarEvent, 'elapsedTimeSinceCheckin');
        Ember.run.later(() => {
          Ember.notifyPropertyChange(this.user, 'checkedInCalendarEvent');
        });
      });
    },

    updateElapsedTimeSinceCheckIn() {
      var _this = this;

      return _asyncToGenerator(function* () {
        var calendarEvent = yield _this.user.get('checkedInCalendarEvent');

        if (calendarEvent) {
          Ember.notifyPropertyChange(calendarEvent, 'elapsedTimeSinceCheckin');
        }
      })();
    },

    userHasCheckedInAppointmentObserver: Ember.observer('user.checkedInCalendarEvent', function () {
      this.userHasCheckedInAppointmentObserverTask.perform();
    }),
    userHasCheckedInAppointmentObserverTask: (0, _emberConcurrency.task)(function* () {
      var checkedInCalendarEvent = yield this.user.get('checkedInCalendarEvent');

      if (_environment.default.environment != 'test' && !_environment.default['securitytrax-ember-ui']['api-e2e-test-config']['local-tests']['runTests'] && !_environment.default['securitytrax-ember-ui']['api-e2e-test-config']['ci-tests']['runTests']) {
        if (checkedInCalendarEvent) {
          this.interval.setInterval(60000);
          this.interval.start();
        } else {
          this.interval.stop();
        }
      }
    })
  });

  _exports.default = _default;
});