define("securitytrax-ember-ui/mixins/user-notifications-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    userNotificationsService: Ember.inject.service(),
    store: Ember.inject.service(),
    allLoaded: true,
    userNotifications: Ember.computed.alias('userNotificationsService.sortedUserNotificationsList'),
    userNotificationsUnreadLength: Ember.computed.alias('userNotificationsService.userNotificationsUnreadLength')
  });

  _exports.default = _default;
});