define("securitytrax-ember-ui/models/credit-bureau-type", ["exports", "securitytrax-ember-ui/models/base_models/credit-bureau-type"], function (_exports, _creditBureauType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _creditBureauType.default.extend({});

  _exports.default = _default;
});