define("securitytrax-ember-ui/validations/interactive-services-provider/alula-create", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      _validate.default.validators.confirmPassword = function (value, options) {
        if (value && value !== options.password) {
          return options.message;
        }

        return null;
      };

      var validateObject = {
        fname: {
          presence: () => {
            return this.customMessageOrDefault(data, 'alula-fname');
          }
        },
        lname: {
          presence: () => {
            return this.customMessageOrDefault(data, 'alula-lname');
          }
        },
        primaryPhone: {
          presence: () => {
            return this.customMessageOrDefault(data, 'alula-primaryPhone');
          }
        },
        zip: {
          presence: () => {
            return this.customMessageOrDefault(data, 'alula-zip');
          }
        },
        timezone: {
          presence: () => {
            return this.customMessageOrDefault(data, 'alula-timezone');
          }
        },
        username: {
          presence: () => {
            return this.customMessageOrDefault(data, 'alula-username');
          }
        },
        password: {
          presence: () => {
            return this.customMessageOrDefault(data, 'alula-password');
          }
        },
        passwordConfirmation: {
          presence: () => {
            return this.customMessageOrDefault(data, 'alula-passwordConfirmation');
          },
          confirmPassword: {
            message: function () {
              return '^' + data.intlService.t('pages.customers.system.interactiveServicesProvider.customerInformation.passwordConfirmation');
            },
            password: this.password
          }
        }
      };

      if (this.email) {
        validateObject['email'] = {
          email: this.customMessageOrDefault(data, 'alula-email')
        };
      }

      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      return this.outputFormatting(validationData, 'alula');
    }

  });

  _exports.default = _default;
});