define("securitytrax-ember-ui/components/multi-step-form/forms/tech-today/notes/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/set-user", "securitytrax-ember-ui/mixins/switchable-form-step"], function (_exports, _emberConcurrency, _setUser, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, _setUser.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    showEditButton: true,
    cancelTask: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.multiStepForm.resetForm(false);
        yield this.set('formMode', 'view');
        this.stNotificationManager.addSuccessKey('common.notifications.generic_cancel');
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err);
      }
    }).drop(),
    saveNotesTask: (0, _emberConcurrency.task)(function* () {
      yield this.multiStepForm.validateSyncSaveAll({
        deepSave: false,
        deepValidate: false,
        extraValidationData: {
          skipValidation: true
        }
      });
      this.multiStepForm.resetForm(false);
      this.set('formMode', 'view');
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
    })
  });

  _exports.default = _default;
});