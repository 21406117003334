define("securitytrax-ember-ui/models/docusign-custom-field", ["exports", "securitytrax-ember-ui/models/base_models/docusign-custom-field"], function (_exports, _docusignCustomField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _docusignCustomField.default.extend({});

  _exports.default = _default;
});