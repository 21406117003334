define("securitytrax-ember-ui/components/multi-step-form/forms/customer-equipment/add-equipment/multiple-items/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer-equipment/customer-equipment-base", "ember-concurrency"], function (_exports, _customerEquipmentBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerEquipmentBase.default.extend({
    initTask: (0, _emberConcurrency.task)(function* () {
      yield this.initBaseData.perform();
      this.set('newLineItems', Ember.A([]));
      this.newLineItems.pushObject(Ember.Object.create({
        quantity: 1,
        itemCharge: 0
      }));
    }).on('init'),

    saveCustomerEquipment(multiStepForm, buttonPromise) {
      return this.createFromManualMultipleTask.perform(multiStepForm, buttonPromise);
    }

  });

  _exports.default = _default;
});