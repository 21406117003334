define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/interactive-services-providers/adc/interactive-services-provider-base", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency"], function (_exports, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    ispService: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    saveInteractiveServicesProviderConfig: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var noValidate = ['interactiveServicesProviderConfig-password'];
      var config = this.multiStepForm.formCopiedModelsGetVal('interactiveServicesProvider.model.interactiveServicesProviderConfig');

      if (!config.get('adcLeads') || multiStepForm.currentStepNameKey !== 'leads') {
        noValidate = noValidate.concat(['interactiveServicesProviderConfig-referralLeadCompanyId', 'interactiveServicesProviderConfig-dealerWebsiteLeadCompanyId', 'interactiveServicesProviderConfig-selfCreatedLeadCompanyId', 'interactiveServicesProviderConfig-referAFriendLeadCompanyId']);
      }

      if (!config.get('adcCustServiceSchedules') || multiStepForm.currentStepNameKey !== 'tasks') {
        noValidate = noValidate.concat(['interactiveServicesProviderConfig-serviceRequestServiceTechId']);
      }

      if (!config.get('adcCustUpsellSchedules') || multiStepForm.currentStepNameKey !== 'tasks') {
        noValidate = noValidate.concat(['interactiveServicesProviderConfig-serviceRequestUpsellTechId']);
      }

      var validationObject = {
        deepValidate: true,
        deepCreate: false,
        extraValidationData: {
          noValidate
        },
        notificationNamespace: 'modal',
        successNotificationNamespace: 'modal'
      };
      yield multiStepForm.validateSyncSaveAll(validationObject);
      buttonPromise.then(() => {
        multiStepForm.setupCopyModels.perform();
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
      });
    })
  });

  _exports.default = _default;
});