define("securitytrax-ember-ui/components/pages/administration/human-resources/payables/function-card/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var FunctionCard = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = (0, _emberConcurrency.task)(function* () {
    var listMethods = yield this.store.findAll('payrollListMethod');
    return listMethods.filterBy('payrollRole.id', this.args.payrollRoleId).sortBy('name');
  }), _dec18 = Ember.computed, _dec19 = (0, _emberConcurrency.task)(function* () {
    var newFilters = yield this.stFiltersService.getFiltersByGroupKey(this.args.name + 'PayrollFunctions'
    /*['payroll_role=\'' + this.args.payrollRoleId + '\'']*/
    ); // Uncoment after BE TRAX-11783 is done

    var functions = (yield this.store.query('payrollRoleFunction', {
      filter: newFilters,
      include: 'payroll_role,payroll_list_method,payroll_role_function_levels.payroll_columns',
      limit: this.limit,
      page: this.page,
      count: 1
    })).filterBy('payrollRole.id', this.args.payrollRoleId);
    Ember.set(this, 'totalCount', functions.length);
    return functions;
  }), _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, _dec23 = Ember._action, (_class = (_temp = class FunctionCard extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "grants", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "stFiltersService", _descriptor5, this);

      _initializerDefineProperty(this, "addFunction", _descriptor6, this);

      _initializerDefineProperty(this, "addLevel", _descriptor7, this);

      _initializerDefineProperty(this, "addLineItem", _descriptor8, this);

      _initializerDefineProperty(this, "addFunctionFormStarted", _descriptor9, this);

      _initializerDefineProperty(this, "addFunctionStepName", _descriptor10, this);

      _initializerDefineProperty(this, "functionFormMode", _descriptor11, this);

      _initializerDefineProperty(this, "limit", _descriptor12, this);

      _initializerDefineProperty(this, "page", _descriptor13, this);

      _initializerDefineProperty(this, "totalCount", _descriptor14, this);

      _initializerDefineProperty(this, "payrollFunctionStatus", _descriptor15, this);

      _initializerDefineProperty(this, "payrollListMethods", _descriptor16, this);

      _initializerDefineProperty(this, "payrollListMethodsSortedTask", _descriptor17, this);

      _initializerDefineProperty(this, "getJobFunctionsTask", _descriptor18, this);
    }

    get additionalClickActions() {
      return [{
        label: this.intl.t('pages.administration.human_resources.new_function_scratch'),
        action: () => {
          this.addFunctionStepName = 'addFunction::newFunction';
          this.addFunction = null;
          this.functionFormMode = 'create';
          this.addFunctionFormStarted = true;
          this.clickActionsOpen = false;
        }
      }, {
        label: this.intl.t('pages.administration.human_resources.new_function_existing'),
        action: () => {
          this.addFunctionStepName = 'addFunction::duplicateFunction';
          this.addFunction = null;
          this.functionFormMode = 'create';
          this.addFunctionFormStarted = true;
        }
      }];
    }

    get progressBarConfig() {
      var items = [];
      var formSteps = this.addFunctionSteps;
      Object.keys(formSteps).forEach(function (key) {
        items.push({
          title: formSteps[key].progressBarTitle,
          stepNameKey: formSteps[key].stepNameKey,
          isClickable: false,
          hidden: formSteps[key].hidden,
          isComplete: formSteps[key].isComplete
        });
      });
      return {
        activeIndex: this.addFunction ? 1 : 0,
        viewTemplate: 'default-view',
        items: items,
        hideProgressBar: this.addFunctionStepName === 'addFunction::duplicateFunction'
      };
    }

    get addFunctionSteps() {
      return Ember.Object.create({
        addFunction: Ember.Object.create({
          stepNameKey: 'addFunction',
          title: this.intl.t('pages.administration.human_resources.new_user_job_function'),
          progressBarTitle: this.intl.t('pages.users.payables.job_function'),
          stepComponentPath: 'multi-step-form/forms/administration/payables/add-functions',
          subSteps: Ember.Object.create({
            newFunction: Ember.Object.create({
              stepNameKey: 'newFunction',
              title: this.intl.t('pages.administration.human_resources.new_user_job_function'),
              stepComponentPath: 'multi-step-form/forms/administration/payables/add-functions'
            }),
            duplicateFunction: Ember.Object.create({
              stepNameKey: 'duplicateFunction',
              title: this.intl.t('pages.administration.human_resources.create_function_existing'),
              stepComponentPath: 'multi-step-form/forms/administration/payables/add-functions'
            })
          })
        }),
        addLevel: Ember.Object.create({
          stepNameKey: 'addLevel',
          title: this.intl.t('pages.administration.human_resources.new_level'),
          progressBarTitle: this.intl.t('pages.customers.accounting.payables.level'),
          stepComponentPath: 'multi-step-form/forms/administration/payables/add-levels'
        }),
        addLineItem: Ember.Object.create({
          stepNameKey: 'addLineItem',
          title: this.intl.t('pages.administration.human_resources.new_line_item'),
          progressBarTitle: this.intl.t('pages.customers.accounting.payables.line_item'),
          stepComponentPath: 'multi-step-form/forms/administration/payables/add-line-items'
        })
      });
    }

    get formOriginalModels() {
      return Ember.Object.create({
        payrollRoleFunction: {
          model: this.addFunction,
          copyRelationMap: {
            payrollRole: {
              readOnly: true
            },
            payrollListMethod: {
              readOnly: true
            }
          }
        },
        payrollRoleFunctionLevel: {
          model: this.addLevel,
          requiresOverride: true,
          copyRelationMap: {
            payrollRoleFunction: {
              readOnly: true
            }
          }
        },
        payrollColumn: {
          model: null,
          requiresOverride: true,
          copyRelationMap: {
            payrollRoleFunctionLevel: {
              readOnly: true
            }
          }
        }
      });
    }

    get payrollListMethodsSorted() {
      return this.payrollListMethodsSortedTask.perform();
    }

    get jobFunctions() {
      return this.getJobFunctionsTask.perform();
    }

    transitionToFunction(func) {
      this.router.transitionTo('administration.company-settings.system.payables.function-details', func);
    }

    onAfterSave(saved, toUpdate) {
      Ember.set(this, toUpdate, saved);
    }

    clearFilter() {
      Ember.setProperties(this, {
        page: 1,
        payrollFunctionStatus: undefined,
        payrollListMethods: undefined
      });
      Ember.notifyPropertyChange(this, 'jobFunctions');
    }

    filterSearch() {
      Ember.set(this, 'page', 1);
      Ember.notifyPropertyChange(this, 'jobFunctions');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "stFiltersService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "addFunction", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "addLevel", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "addLineItem", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "addFunctionFormStarted", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "addFunctionStepName", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'addFunction::newFunction';
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "functionFormMode", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'view';
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "limit", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 50;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "totalCount", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "payrollFunctionStatus", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "payrollListMethods", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "payrollListMethodsSortedTask", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "jobFunctions", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "jobFunctions"), _class.prototype), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "getJobFunctionsTask", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "transitionToFunction", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToFunction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onAfterSave", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "onAfterSave"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilter", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterSearch", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "filterSearch"), _class.prototype)), _class));
  _exports.default = FunctionCard;
});