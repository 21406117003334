define("securitytrax-ember-ui/components/calendar-glimmer/calendar-event/event-status/component", ["exports", "@glimmer/component", "moment", "ember-concurrency", "securitytrax-ember-ui/config/environment", "securitytrax-ember-ui/helpers/st-time-epoch"], function (_exports, _component, _moment, _emberConcurrency, _environment, _stTimeEpoch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EventStatus = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('events/calendar-events'), _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = (0, _emberConcurrency.task)(function* () {
    // TODO check for grant if no grant return view 
    if (this.grants.getIndividualGrant('calendar_events.modify_on_site_info')) {
      // if grant check if check out if yes check that there is a check in 
      var status = yield this.args.status;

      if (this.args.statusType === 'departure' && status) {
        var check_in = status.check_in_datetime;

        if (check_in) {
          this.formMode = 'edit';
        } else {
          this.formMode = 'view';
        }
      } else if (this.args.statusType === 'en_route') {
        this.formMode = 'view';
      } else {
        this.formMode = 'edit';
      }
    } else {
      this.formMode = 'view';
    }
  }), _dec7 = (0, _emberConcurrency.task)(function* () {
    var status = yield this.args.status;

    if (status) {
      if (this.args.statusType === 'departure') {
        if (status.check_out && status.check_out_datetime) {
          this.statusTime = (0, _stTimeEpoch.checkStEpoch)(_moment.default.unix(status.check_out), (0, _moment.default)(status.check_out_datetime));
        }
      } else if (this.args.statusType === 'arrival') {
        if (status.check_in && status.check_in_datetime) {
          this.statusTime = (0, _stTimeEpoch.checkStEpoch)(_moment.default.unix(status.check_in), (0, _moment.default)(status.check_in_datetime));
        }
      } else {
        if (status.en_route && status.en_route_datetime) {
          this.statusTime = (0, _stTimeEpoch.checkStEpoch)(_moment.default.unix(status.en_route), (0, _moment.default)(status.en_route_datetime));
        }
      }
    }
  }), _dec8 = (0, _emberConcurrency.task)(function* (time, statusToUpdate) {
    try {
      if (statusToUpdate) {
        var dateToUpdate;

        if (_environment.default['securitytrax-ember-ui']['st-time-epoch'] > (0, _moment.default)(time).unix()) {
          dateToUpdate = {
            data: {
              timestamp: (0, _moment.default)(time).unix()
            }
          };
        } else {
          dateToUpdate = {
            data: {
              datetime: (0, _moment.default)(time).format('YYYY-MM-DD HH:mm:ss')
            }
          };
        }

        yield this.args.calendarEvent.invokeAction("reset_".concat(statusToUpdate), {
          verb: 'POST',
          data: dateToUpdate
        });
      }
    } catch (error) {
      (false && Ember.warn(error, {
        id: 'Updating check out failed'
      }));
      throw error;
    }

    this.CalendarEventsService.trigger('updateEventOnSite', this.args.calendarEvent);
  }), _dec9 = Ember._action, (_class = (_temp = class EventStatus extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "CalendarDataService", _descriptor, this);

      _initializerDefineProperty(this, "CalendarEventsService", _descriptor2, this);

      _initializerDefineProperty(this, "grants", _descriptor3, this);

      _initializerDefineProperty(this, "statusTime", _descriptor4, this);

      _initializerDefineProperty(this, "formMode", _descriptor5, this);

      _initializerDefineProperty(this, "formModeTask", _descriptor6, this);

      _initializerDefineProperty(this, "getStatusTimeTask", _descriptor7, this);

      _initializerDefineProperty(this, "updateStatusTask", _descriptor8, this);

      this.appointmentTimeService;
      this.getStatusTimeTask.perform();
      this.formModeTask.perform();
    }

    _updateEventOnSite(eventID) {
      if (this.args.calendarEvent.id === eventID) {
        this.getStatusTimeTask.perform();
        this.formModeTask.perform();
      }
    }

    get endTime() {
      var endTime = (0, _moment.default)(this.args.calendarEvent.calculatedEndtime).clone();
      endTime.set({
        'hour': 20,
        'minute': 30
      });

      if ((0, _moment.default)(this.args.calendarEvent.calculatedEndtime).isSameOrAfter(endTime)) {
        return _moment.default.min((0, _moment.default)(this.args.calendarEvent.calculatedEndtime).add(2, 'hours'), (0, _moment.default)(this.args.calendarEvent.calculatedEndtime).endOf('day')).format('HH');
      }

      return '20:30';
    }

    get startTime() {
      return this.CalendarDataService.defaultStartTime.format('HH');
    }

    get eventStartTime() {
      return (0, _moment.default)(this.args.calendarEvent.startTime);
    }

    changeAction() {
      var time = this.statusTime.set({
        'year': this.eventStartTime.get('year'),
        'month': this.eventStartTime.get('month'),
        'date': this.eventStartTime.get('date')
      });

      if (this.args.statusType === 'departure') {
        this.updateStatusTask.perform(time, 'check_out');
      } else if (this.args.statusType === 'arrival') {
        this.updateStatusTask.perform(time, 'check_in');
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "CalendarDataService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "CalendarEventsService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "statusTime", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "formMode", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'edit';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "formModeTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "getStatusTimeTask", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "updateStatusTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "changeAction", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "changeAction"), _class.prototype)), _class));
  _exports.default = EventStatus;
});