define("securitytrax-ember-ui/components/pages/administration/company-settings/integrations/credit-bureaus/component", ["exports", "ember-concurrency", "lodash"], function (_exports, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    stAjax: Ember.inject.service(),
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    bureausEnabled: false,
    creditBureau: null,
    formStarted: false,
    formMode: 'view',
    limit: 50,
    page: 1,
    recordCount: 0,
    initTask: (0, _emberConcurrency.task)(function* () {
      try {
        var response = yield this.stAjax.request(this.stAjax.getUrl('/credit_bureaus/actions/get_credit_bureaus_settings'), {
          method: 'GET'
        });
        Ember.set(this, 'bureausEnabled', _lodash.default.get(response, 'data.enabled', false));
        this.setEnabledOrDisabledProperties();
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err);
      }
    }).on('init'),
    creditBureausData: Ember.computed(function () {
      return this.creditBureausTask.perform();
    }),
    creditBureausTask: (0, _emberConcurrency.task)(function* () {
      var queryParams = {
        limit: this.limit,
        page: this.page
      };

      if (this.page == 1) {
        queryParams['count'] = 1;
      }

      var bureaus = yield this.store.query('creditBureau', queryParams);

      if (this.page == 1) {
        Ember.set(this, 'recordCount', _lodash.default.get(bureaus, 'meta.record_count', 0));
      }

      return {
        creditBureaus: bureaus,
        record_count: this.recordCount
      };
    }),
    formSteps: Ember.computed('formMode', 'creditBureau', function () {
      var editOrCreate = this.formMode === 'create' ? 'create' : 'view';
      var title = this.intl.t("common.forms.descriptive_title_".concat(editOrCreate), {
        name: this.formMode === 'create' ? this.intl.t('pages.customers.credit_checks.creditBureau.label') : this.get('creditBureau.name'),
        id: this.get('creditBureau.id')
      });
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title,
          stepComponentPath: 'multi-step-form/forms/administration/integrations/edit-create-credit-bureau'
        })
      });
    }),
    formOriginalModels: Ember.computed('creditBureau', function () {
      var grants = this.grants;
      return Ember.Object.create({
        creditBureau: {
          model: this.creditBureau,
          copyRelationMap: {
            creditBureauType: {
              readOnly: true
            },
            creditBureauConfig: {
              doCopy: grants.getIndividualGrant('credit_bureau_configs.manage'),
              doValidate: grants.getIndividualGrant('credit_bureau_configs.manage'),
              doSave: grants.getIndividualGrant('credit_bureau_configs.manage')
            }
          }
        }
      });
    }),

    setEnabledOrDisabledProperties() {
      if (this.bureausEnabled) {
        Ember.setProperties(this, {
          bureauButtonClasses: 'st-btn st-btn-warn',
          bureauButtonText: this.intl.t('common.actions.disable'),
          creditBureauNotification: this.intl.t('pages.administration.integrations.credit_bureaus.enabledOrDisabledNotification', {
            currentState: this.intl.t('common.status.enabled'),
            alternateState: _lodash.default.lowerCase(this.intl.t('common.actions.disable')),
            buttonState: this.bureauButtonText,
            spanClass: 'enabled'
          })
        });
      } else {
        Ember.setProperties(this, {
          bureauButtonClasses: 'st-btn st-btn-primary',
          bureauButtonText: this.intl.t('common.actions.enable'),
          creditBureauNotification: this.intl.t('pages.administration.integrations.credit_bureaus.enabledOrDisabledNotification', {
            currentState: this.intl.t('common.status.disabled'),
            alternateState: _lodash.default.lowerCase(this.intl.t('common.actions.enable')),
            buttonState: this.bureauButtonText,
            spanClass: 'disabled'
          })
        });
      }

      var buttons = [{
        text: this.bureausEnabled ? this.intl.t('common.actions.disable') : this.intl.t('common.actions.enable'),
        classNamesString: this.bureauButtonClasses,
        click: buttonPromise => {
          return this.enableOrDisableCreditBureaus.perform(buttonPromise);
        }
      }];
      Ember.set(this, 'enableOrDisableModalButton', buttons);
      Ember.set(this, 'enableorDisableModalTitle', this.intl.t('pages.administration.integrations.credit_bureaus.enableOrDisableTitle', {
        action: this.bureausEnabled ? this.intl.t('common.actions.disable') : this.intl.t('common.actions.enable')
      }));
      Ember.set(this, 'enableOrDisableModalText', this.bureausEnabled ? this.intl.t('pages.administration.integrations.credit_bureaus.disableMessage') : this.intl.t('pages.administration.integrations.credit_bureaus.enableMessage'));
    },

    enableOrDisableCreditBureaus: (0, _emberConcurrency.task)(function* (buttonPromise) {
      try {
        var response = yield this.stAjax.request(this.stAjax.getUrl('/credit_bureaus/actions/set_credit_bureaus_enabled'), {
          method: 'POST',
          data: {
            data: {
              enabled: this.bureausEnabled ? false : true
            }
          }
        });
        buttonPromise.then(() => {
          Ember.set(this, 'bureausEnabled', response.data.enabled);
          this.setEnabledOrDisabledProperties();
          Ember.set(this, 'enableOrDisableCreditBureausFormStarted', false);
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
      }
    }),
    actions: {
      editCreditBureau(row) {
        if (this.grants.getIndividualGrant('credit_bureaus.modify')) {
          Ember.set(this, 'formMode', 'edit');
        }

        Ember.set(this, 'creditBureau', row);
        Ember.set(this, 'formStarted', true);
      },

      updateCreditBureausData() {
        Ember.notifyPropertyChange(this, 'creditBureausData');
      }

    }
  });

  _exports.default = _default;
});