define("securitytrax-ember-ui/leads/accounting/edit-create-quote/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/subroute-modal", "securitytrax-ember-ui/mixins/route-pageview-logging", "ember-concurrency"], function (_exports, _authenticatedRouteMixin, _subrouteModal, _routePageviewLogging, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _subrouteModal.default, _routePageviewLogging.default, {
    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['lead_quotes.view']);
    },

    model(params) {
      return this.setupModelTask.perform(params);
    },

    setupModelTask: (0, _emberConcurrency.task)(function* (params) {
      var parentModel = yield this.modelFor(this.parentRoute);
      return Ember.RSVP.hash({
        customer: parentModel.customer,
        customerInvoiceId: params.qid,
        parentRoute: this.transitionRoute
      });
    })
  });

  _exports.default = _default;
});