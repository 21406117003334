define("securitytrax-ember-ui/components/multi-step-form/forms/system/cms/general/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/system/cms/cms-base", "lodash", "moment", "ember-concurrency"], function (_exports, _cmsBase, _lodash, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _cmsBase.default.extend({
    centralStationService: Ember.inject.service(),
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    onOffReady: true,
    showOnOffCloseButton: true,
    onOffChoices: Ember.computed('onOffChoice', function () {
      var intl = this.intl;
      var choices = Ember.A([{
        packageName: intl.t('pages.administration.integrations.monitoringCompanies.integrations.cms.general.on_test'),
        packageId: 'on_test'
      }, {
        packageName: intl.t('pages.administration.integrations.monitoringCompanies.integrations.cms.general.off_test'),
        packageId: 'off_test'
      }]);
      return choices;
    }),
    onOffButtons: Ember.computed(function () {
      return [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.submitOnOffTest.perform(this.multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary'
      }];
    }),
    setupFormTask: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('cmsStepReady', false);
        this.set('progressBarHidden', this.formMode === 'create');
        this.set('isNewRecord', this.formMode === 'create');
        var centralStationService = this.centralStationService;
        var monitoringCompany = this.monitoringCompany;
        var customer = yield this.customer;
        var customerEquipment = yield customer.get('customerEquipment');
        var cmsData = yield (0, _emberConcurrency.hash)({
          atiHours: centralStationService.getData({
            model: monitoringCompany,
            method: 'cms__getAtiHours',
            responseKey: 'ati_hours'
          }),
          atiMinutes: centralStationService.getData({
            model: monitoringCompany,
            method: 'cms__getAtiMinutes',
            responseKey: 'ati_minutes'
          }),
          atiLateEventIds: centralStationService.getData({
            model: monitoringCompany,
            method: 'cms__getAtiLateEventIds',
            responseKey: 'ati_late_event_ids'
          }),
          atiOptions: centralStationService.getData({
            model: monitoringCompany,
            method: 'cms__getAtiOptions',
            responseKey: 'ati_options'
          }),
          companySpecifiedEventIds: centralStationService.getData({
            model: monitoringCompany,
            method: 'cms__getCompanySpecifiedEventIds',
            responseKey: 'company_specified_event_ids'
          }),
          companySpecifiedSystemTypes: centralStationService.getData({
            model: monitoringCompany,
            method: 'cms__getCompanySpecifiedSystemTypes',
            responseKey: 'company_specified_system_types'
          }),
          // defaultEventIds: centralStationService.getData({
          //     model: monitoringCompany,
          //     method: 'cms__getDefaultEventIds',
          //     responseKey: 'default_event_ids'
          // }),
          defaultSystemTypes: centralStationService.getData({
            model: monitoringCompany,
            method: 'cms__getDefaultSystemTypes',
            responseKey: 'default_system_types'
          }),
          siteLocations: centralStationService.getData({
            model: monitoringCompany,
            method: 'cms__getSiteLocations',
            responseKey: 'site_locations'
          }),
          siteTypes: centralStationService.getData({
            model: monitoringCompany,
            method: 'cms__getSiteTypeIds',
            responseKey: 'site_type_ids'
          }),
          site_detail: {}
        }); //setup system types dropdown

        if (!cmsData.companySpecifiedSystemTypes) {
          cmsData['companySpecifiedSystemTypes'] = cmsData.defaultSystemTypes;
        }

        this.set('zones', customerEquipment.filter(equipment => {
          return equipment.get('zoneNumber') && equipment.get('equipmentStatus.name') === 'Installed';
        }).map(equip => {
          return {
            zone_id: equip.get('zoneNumber'),
            zone_name: equip.get('zone'),
            customer_equipment_id: equip.get('id'),
            event_id: null,
            user_defined_event_id: ''
          };
        }));

        if (this.formMode === 'edit') {
          try {
            var information = yield (0, _emberConcurrency.hash)({
              siteDetail: this.centralStationService.getData({
                model: customer,
                method: 'cms__getSiteDetail',
                responseKey: 'site_detail'
              }),
              zoneList: centralStationService.getData({
                model: customer,
                method: 'cms__getZoneList',
                responseKey: 'zone_list'
              })
            });
            information.zoneList.forEach(zone => {
              zone.event_id = cmsData.companySpecifiedEventIds.find(event => {
                return event.event_id === zone.event_id;
              });
              zone.user_defined_event_id = zone.event_id.event_id;
            });
            this.set('zones', _lodash.default.merge(this.zones, information.zoneList));
            cmsData['site_detail'] = information.siteDetail; //TODO BP: convince team we should allow editing individual address fields, not a combined field

            cmsData['site_detail']['address'] = cmsData['site_detail']['site_address_1'] + ' ' + cmsData['site_detail']['site_city_name'] + ' ' + cmsData['site_detail']['site_state_id'] + ' ' + cmsData['site_detail']['site_zip_code'];
            cmsData['site_detail']['site_type_id'] = cmsData['site_detail']['site_type_id'].trim();
            cmsData['site_detail']['system_type_id'] = cmsData['site_detail']['system_type_id'].trim();
          } catch (err) {
            var message = this.intl.t('pages.customers.system.order.stages_retrieve_error', {
              data: this.intl.t('pages.customers.system.order.customer_site_information')
            });
            this.stNotificationManager.addWarning(message + err, 'cmsModal');
            this.stNotificationManager.addWarningMessages(err.errors, {
              notificationNamespace: 'cmsModal'
            });
            cmsData['site_detail'] = {};
            (false && Ember.warn('cms base init error:' + err, {
              id: 'multiStepForm.forms.system.cms'
            }));
          }
        } else if (this.formMode === 'create') {
          //TODO BP: eventually we should allow editing address fields, not a combined view only field
          var customerHash = yield (0, _emberConcurrency.hash)({
            flname: customer.get('flname'),
            address1: customer.get('address1'),
            address2: customer.get('address2'),
            city: customer.get('city'),
            state: customer.get('state'),
            zip: customer.get('zip')
          });
          cmsData['site_detail']['site_name'] = customerHash.flname;
          cmsData['site_detail']['site_address_1'] = customerHash.address1 ? customerHash.address1 : '';
          cmsData['site_detail']['site_address_2'] = customerHash.address2 ? customerHash.address2 : '';
          cmsData['site_detail']['site_city_name'] = customerHash.city ? customerHash.city : '';
          cmsData['site_detail']['site_state_id'] = customerHash.state ? customerHash.state : '';
          cmsData['site_detail']['site_location_id'] = customerHash.state ? customerHash.state : '';
          cmsData['site_detail']['site_zip_code'] = customerHash.zip ? customerHash.zip : '';
          cmsData['site_detail']['address'] = cmsData['site_detail']['site_address_1'] + ' ' + cmsData['site_detail']['site_city_name'] + ', ' + cmsData['site_detail']['site_state_id'] + ' ' + cmsData['site_detail']['site_zip_code'];
        }

        this.set('cmsData', cmsData);
        var grants = this.grants; //show view mode if they dont have manage grant

        if (grants.getIndividualGrant('customers.manage_central_station_integration') === false) {
          this.set('formMode', 'view');
        } else {
          this.setupButtons();
        }

        this.set('cmsStepReady', true);
      } catch (error) {
        this.set('cmsStepReady', true);
        this.stNotificationManager.addErrorMessages(error, {
          notificationNamespace: 'cmsModal'
        });
        (false && Ember.warn('cms form init error' + error, {
          id: 'multiStepForm.forms.system.cms.init'
        }));
      }
    }).on('init'),
    setupButtons: function () {
      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('general', 'buttons', [{
        text: this.intl.t('common.actions.save'),
        classNamesString: 'st-btn st-btn-primary',
        click: buttonPromise => {
          return this.saveCMS.perform(multiStepForm, buttonPromise);
        }
      }]);
    },
    submitOnOffTest: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var messages = [];

      try {
        this.set('onOffReady', false);
        this.set('hideOnOffCloseButton', true);
        var customer = this.customer;
        var centralStationService = this.centralStationService;
        var onOff = this.selectedOnOff;

        try {
          var formattedObjects = Ember.A();
          formattedObjects.pushObject(Ember.Object.create({
            notificationNamespace: 'onOffModal',
            model: {
              on_off: onOff,
              expire_date: this.expireDate,
              expire_time: this.expireTime,
              instructions: this.instructions
            },
            filePath: 'central-station/cms/cms-on-off'
          }));
          yield multiStepForm.validateList.perform(formattedObjects);
        } catch (err) {
          (false && Ember.warn('cms on/off validation error' + err, {
            id: 'multiStepForm.forms.system.cms.submitOnOffTest.validation'
          }));
          throw '';
        }

        var payloadObject = {
          test_status_id: onOff
        };

        if (onOff === 'on_test') {
          var expireString = this.expireDate.format('MM/DD/YYYY') + ' ' + this.expireTime.format('HH:mm');
          var expireTime = (0, _moment.default)(expireString, 'MM/DD/YYYY HH:mm');
          payloadObject['expire_date_time'] = expireTime;
          payloadObject['comment'] = this.instructions;
        }

        var response = yield centralStationService.postData({
          model: customer,
          method: 'cms__setSystemTest',
          payload: payloadObject
        });
        messages = messages.concat(response.responsePayload.data.messages);
        buttonPromise.then(() => {
          this.stNotificationManager.addSuccess(this.intl.t('common.notifications.save_successful'), 'cmsModal');
          this.set('showOnOffModal', false);
          this.set('onOffReady', true);
          this.set('hideOnOffCloseButton', false);

          if (messages.length > 0) {
            this.stNotificationManager.addWarningMessages(messages, {
              notificationNamespace: 'cmsModal'
            });
          }
        });
      } catch (err) {
        this.set('onOffReady', true);
        this.set('hideOnOffCloseButton', false);

        if (err !== '') {
          this.stNotificationManager.addErrorMessages(err, {
            notificationNamespace: 'onOffModal'
          });
        }

        (false && Ember.warn('cms on/off test error' + err, {
          id: 'multiStepForm.forms.system.cms.submitOnOffTest'
        }));
        throw err;
      }
    }),
    saveCMS: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var messages = [];

      try {
        this.set('cmsStepReady', false);
        var centralStationService = this.centralStationService;
        var customer = this.customer; // validate cmsData frontEnd

        try {
          var formattedObjects = Ember.A();
          formattedObjects.pushObject(Ember.Object.create({
            notificationNamespace: 'cmsModal',
            model: this.get('cmsData.site_detail'),
            filePath: 'central-station/cms/cms-customer-order',
            extraValidationData: {
              isCreate: this.isNewRecord
            }
          }));
          yield multiStepForm.validateList.perform(formattedObjects);
        } catch (err) {
          throw ''; //throw empty string to show generic notification
        } //TODO BP: DO WE NEED TO VALIDATE SYNC SAVE? IF we allow name/address saving...
        //Name, Address in different formats then in trax, contacts saves separately...
        // yield multiStepForm.validateSyncSaveAll({
        //     notificationNamespace: 'cmsModal',
        //     extraValidationData: {
        //         adtValidation: true,
        //         noValidate: ['fname', 'lname', 'primaryPhone', 'city', 'location', 'saleDate', 'trackingPin', 'orderInformation-salesRep']
        //     }
        // });


        var cmsMethod = this.formMode === 'create' ? 'cms__createAccount' : 'cms__updateAccount';
        var postCmsData = this.get('cmsData.site_detail');
        var response = yield centralStationService.postData({
          model: customer,
          method: cmsMethod,
          payload: {
            customer__site_type_id: _lodash.default.get(postCmsData, 'site_type_id', ''),
            customer__system_type_id: _lodash.default.get(postCmsData, 'system_type_id', ''),
            customer__site_location_id: _lodash.default.get(postCmsData, 'site_location_id', ''),
            customer__panel_description: _lodash.default.get(postCmsData, 'panel_description', ''),
            customer__ati_option: _lodash.default.get(postCmsData, 'ati_option', ''),
            customer__ati_hours: _lodash.default.get(postCmsData, 'ati_hour', ''),
            customer__ati_minutes: _lodash.default.get(postCmsData, 'ati_minute', ''),
            customer__ati_late_event_id: _lodash.default.get(postCmsData, 'ati_late_event_id', ''),
            customer__account_number_used_to_verify_false_alarms: _lodash.default.get(postCmsData, 'account_number_used_to_verify_false_alarms', ''),
            zone_data: this.zones.map(zone => {
              return {
                customer_equipment_id: zone.customer_equipment_id,
                user_defined_event_id: zone.user_defined_event_id
              };
            })
          }
        });
        messages = messages.concat(response.responsePayload.data.messages);
        buttonPromise.then(() => {
          if (this.isNewRecord) {
            this.stNotificationManager.addSuccess(this.intl.t('common.notifications.save_successful'));
            multiStepForm.formStartedSetVal(false);
            this.customerSystemInformation.reload();

            if (this.transitionBackToParent && typeof this.transitionBackToParent === 'function') {
              this.transitionBackToParent();
            }
          } else {
            this.stNotificationManager.addSuccess(this.intl.t('common.notifications.save_successful'), 'cmsModal');
            multiStepForm.setupCopyModels.perform();
          }

          if (messages.length > 0) {
            this.stNotificationManager.addWarningMessages(messages, {
              notificationNamespace: 'cmsModal'
            });
          }

          this.set('cmsStepReady', true);
        });
      } catch (err) {
        this.set('cmsStepReady', true);
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'cmsModal'
        });
        (false && Ember.warn('cms shell/update form save error' + err, {
          id: 'multiStepForm.forms.system.cms.saveCMS'
        }));
        throw err;
      }
    }),
    unlinkCMS: (0, _emberConcurrency.task)(function* () {
      try {
        var hashData = yield (0, _emberConcurrency.hash)({
          customer: this.customer,
          customerSystemInformation: this.get('customer.customerSystemInformation')
        });

        try {
          yield hashData.customerSystemInformation.invokeAction('unlink_cs_account', {
            verb: 'POST',
            data: {
              data: {
                type: 'customer_system_information_action'
              }
            }
          });
        } catch (response) {
          this.stNotificationManager.addErrorMessages(response);
        }

        this.set('showUnlinkConfirmModal', false);
        this.multiStepForm.formStartedSetVal(false);
        this.customerSystemInformation.reload();

        if (this.transitionBackToParent && typeof this.transitionBackToParent === 'function') {
          this.transitionBackToParent();
        }

        this.stNotificationManager.addSuccess(this.intl.t('common.notifications.unlink_successful'));
      } catch (error) {
        (false && Ember.warn(error, {
          id: 'multiStepForm.forms.system.cms.general.unlinkCMS'
        }));
        throw error;
      }
    }),
    actions: {
      setEventId(row, value) {
        Ember.set(row, 'user_defined_event_id', value.event_id);
      },

      onOffTest() {//TODO BP. coming soon, TRAX-5336
      },

      confirmUnlinkAccount() {
        this.set('showUnlinkConfirmModal', true);
      },

      unlinkConfirmed() {
        return this.unlinkCMS.perform();
      }

    }
  });

  _exports.default = _default;
});