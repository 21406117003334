define("securitytrax-ember-ui/helpers/json", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.json = json;
  _exports.default = void 0;

  function json(params) {
    return params[1] ? JSON.stringify(params[0]) : JSON.parse(params[0]);
  }

  var _default = Ember.Helper.helper(json);

  _exports.default = _default;
});