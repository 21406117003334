define("securitytrax-ember-ui/components/multi-step-form/forms/administration/system/form-defaults/lead-information-defaults/component", ["exports", "@glimmer/component", "ember-concurrency", "securitytrax-ember-ui/config/environment"], function (_exports, _component, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var LeadInformationDefaults = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
    this.leadCompanies = yield this.store.findAll('leadCompany', {
      include: 'leadCompanyCampaigns'
    });
    var defaultSources = this.args.multiStepForm.formCopiedModelsGetVal('leadInformationDefault.model.responseMeta.attribute_options.sources');

    for (var i = 0; i < defaultSources.length; i++) {
      var source = defaultSources[i];
      var leadCompanyObj = this.leadCompanies.findBy('name', source.value);

      if (leadCompanyObj) {
        this.companyMap[source.id] = leadCompanyObj;
      }
    }
  }), _dec5 = (0, _emberConcurrency.task)(function* () {
    try {
      yield this.args.multiStepForm.resetForm(false);
      yield this.args.setFormMode('view');
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err);
    }
  }).drop(), _dec6 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    yield this.args.multiStepForm.validateSyncSaveAll();
    buttonPromise.then(() => {
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
      this.args.setFormMode('view');
    });
  }), _dec7 = Ember.computed('args.multiStepForm.formCopiedModels.leadInformationDefault.model.source'), _dec8 = (0, _emberConcurrency.task)(function* () {
    var source = this.args.multiStepForm.formCopiedModelsGetVal('leadInformationDefault.model.source');

    if (!source) {
      return [];
    }

    var leadCompanyObj = this.companyMap[source];

    if (leadCompanyObj) {
      if (this.campaignMap[leadCompanyObj.get('name')]) {
        return this.campaignMap[leadCompanyObj.get('name')];
      }

      var campaigns = yield leadCompanyObj.get('leadCompanyCampaigns');
      var trueCampaigns = [];

      for (var i = 0; i < campaigns.length; i++) {
        var campaign = campaigns.objectAt(i);
        var trueCampaign = this.args.multiStepForm.formCopiedModelsGetVal('leadInformationDefault.model.responseMeta.attribute_options.source_campaigns').findBy('value', campaign.get('name'));

        if (trueCampaign) {
          trueCampaigns.push(trueCampaign);
        }
      }

      this.campaignMap[leadCompanyObj.get('name')] = trueCampaigns;
      return trueCampaigns;
    }

    return [];
  }), (_class = (_temp = class LeadInformationDefaults extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "campaignMap", _descriptor3, this);

      _defineProperty(this, "companyMap", {});

      _initializerDefineProperty(this, "initDataTask", _descriptor4, this);

      _initializerDefineProperty(this, "cancelTask", _descriptor5, this);

      _initializerDefineProperty(this, "saveDefaultsTask", _descriptor6, this);

      _initializerDefineProperty(this, "leadCompanyCampaignsTask", _descriptor7, this);

      this.initDataTask.perform();
    }

    //filter select lists based on another field
    get leadCompanyCampaigns() {
      if (_environment.default.environment != 'test') {
        // Real land
        return this.leadCompanyCampaignsTask.perform();
      } else {
        // Mirage
        return this.args.multiStepForm.formCopiedModelsGetVal('leadInformationDefault.model.responseMeta.attribute_options.source_campaigns');
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "campaignMap", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "cancelTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "saveDefaultsTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "leadCompanyCampaigns", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "leadCompanyCampaigns"), _class.prototype), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "leadCompanyCampaignsTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = LeadInformationDefaults;
});