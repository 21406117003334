define("securitytrax-ember-ui/components/multi-step-form/forms/administration/payables/add-line-items/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/switchable-form-step"], function (_exports, _emberConcurrency, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    initData: (0, _emberConcurrency.task)(function* (doSetupCopyModels = false) {
      if (doSetupCopyModels) {
        yield this.multiStepForm.setupCopyModels.unlinked().perform();
      }

      yield (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true);

      if (this.isNewRecord) {
        this.multiStepForm.formCopiedModelsSetVal('payrollColumn.model.active', true);
      }
    }).on('init'),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;

      if (this.formMode !== 'view') {
        var stepName = multiStepForm.currentStepNameKey;
        multiStepForm.registerStepData(stepName, 'buttons', [{
          text: this.intl.t('pages.administration.human_resources.add_line_item'),
          icon: 'icn-add',
          click: buttonPromise => {
            return this.saveNewLineItem.perform(buttonPromise);
          },
          classNamesString: 'st-btn st-btn-secondary line-item-add-button'
        }, {
          text: this.intl.t('common.actions.create'),
          click: buttonPromise => {
            return this.saveNewLineItem.perform(buttonPromise, true);
          },
          classNamesString: 'st-btn st-btn-primary line-item-save-button'
        }]);
      }
    },

    disabledProgressBarSteps: Ember.computed(function () {
      return [];
    }),
    hiddenProgressBarSteps: Ember.computed(function () {
      return [];
    }),
    saveNewLineItem: (0, _emberConcurrency.task)(function* (buttonPromise, close = false) {
      this.multiStepForm.formCopiedModelsSetVal('payrollColumn.model.payrollRoleFunctionLevel', this.payrollRoleFunctionLevel);
      var validationObject = {
        notificationNamespace: 'modal',
        overrideOriginalModelsConfig: Ember.Object.create({
          payrollColumn: {
            model: null,
            requiresOverride: true,
            copyRelationMap: {
              payrollRoleFunctionLevel: {
                readOnly: true
              }
            }
          }
        })
      };
      var toCreateObjects = yield this.multiStepForm.validateCreateAll(validationObject);
      toCreateObjects.payrollColumn.data.sort = this.lineItems && Array.isArray(this.lineItems) ? this.lineItems.get('length') + 1 : 1;
      var newLineItem = this.store.createRecord('payrollColumn', toCreateObjects.payrollColumn.data);
      var newlySavedLineItem = yield toCreateObjects.payrollColumn.saveWithRollbackAndNotification(newLineItem, 'modal');
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccess(this.intl.t('common.notifications.save_successful'), close ? 'global' : 'modal');

        if (close) {
          this.multiStepForm.formStartedSetVal(false);
          this.payrollRoleFunctionLevel.reload();

          if (this.multiStepForm.formOriginalModelsGetVal('payrollRoleFunction')) {
            this.router.transitionTo('administration.company-settings.system.payables.function-details', this.payrollRoleFunction.get('id'));
          } else if (this.multiStepForm.formOriginalModelsGetVal('payrollRoleFunctionLevel')) {
            this.router.transitionTo('administration.company-settings.system.payables.function-details.level-details', this.payrollRoleFunctionLevel.get('id'));
          } else {
            this.router.transitionTo('administration.company-settings.system.payables.function-details.level-details.line-item-details', newlySavedLineItem.get('id'));
          }
        } else {
          this.initData.perform(true);
        }
      });
    })
  });

  _exports.default = _default;
});