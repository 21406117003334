define("securitytrax-ember-ui/components/multi-step-form/forms/change-my-password/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/utils/debug", "securitytrax-ember-ui/config/environment"], function (_exports, _emberConcurrency, _debug, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(Ember.Evented, {
    session: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    profile: Ember.inject.service(),
    stAjax: Ember.inject.service(),
    newPassword: null,
    newPasswordConfirm: null,
    password: null,
    actions: {
      updatePassword() {
        this.updatePasswordTask.perform();
      }

    },
    updatePasswordTask: (0, _emberConcurrency.task)(function* () {
      if (this.newPassword != this.newPasswordConfirm) {
        this.stNotificationManager.addError(this.intl.t('pages.login.new_password_mismatch'), 'modal');
        throw new Error(this.intl.t('pages.login.new_password_mismatch'));
      }

      var postObject = {
        method: 'POST',
        dataType: 'json',
        data: {
          data: {
            type: 'user_password_settings_action',
            attributes: {
              current_password: this.password,
              password: this.newPassword
            }
          }
        }
      };
      var token = yield this.sessionData.getTokenData();
      var userId = token && token.sub ? token.sub.user_id : null;
      var response = yield this.stAjax.request(this.stAjax.getUrl("/user_password_settings/".concat(userId, "/actions/change_my_password")), postObject).catch(errors => {
        var errorsArray = errors && errors.payload ? errors.payload.errors : [];
        errorsArray.forEach(error => {
          this.stNotificationManager.addError(error.detail, 'modal');
        });
      });

      if (response) {
        this.resetTokenAndRefreshTask.perform(response);
      }
    }).drop().maxConcurrency(1),
    resetTokenAndRefreshTask: (0, _emberConcurrency.task)(function* (response) {
      try {
        var newSession = yield this.get('session.session.content');
        Ember.set(newSession['authenticated'], 'token', response.data.meta.token);
        Ember.set(newSession['authenticated'], 'meta', null);
        yield this.get('session.store').persist(newSession);
        yield this.stAjax.resetHeaders(); // ensure ajax headers, grants etc are reloaded

        if (!(0, _debug.isTestEnvironment)()) {
          window.location = this.profile.getFullIndexUri(); // reset application
        }

        if (_environment.default['securitytrax-ember-ui']['api-e2e-test-config']['local-tests']['runTests'] || _environment.default['securitytrax-ember-ui']['api-e2e-test-config']['ci-tests']['runTests']) {
          this.session.set('forcePasswordUpdate', false);
          this.grants.set('currentlyRunningGetCurrentUserGrants', yield this.grants.getCurrentUserGrants.perform(false) // we pass in false so say don't use the multicall data in this case so that we get actual new grant data based on the new user
          );
          this.session.trigger('afterForcePasswordChange');
        }
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
      }
    }),
    logoutTask: (0, _emberConcurrency.task)(function* () {
      yield this.session.invalidate();
    })
  });

  _exports.default = _default;
});