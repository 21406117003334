define("securitytrax-ember-ui/components/pages/administration/company-settings/integrations/partner-companies/company/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zXSETYjC",
    "block": "{\"symbols\":[\"multiStepForm\",\"MultiStepFormComponent\",\"@model\"],\"statements\":[[6,[37,3],[[30,[36,2],[\"lead_companies.view_tag\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[12],[2,\"\\n        \"],[8,\"html/st-tags\",[],[[\"@tagObjectType\",\"@title\",\"@selectedTags\",\"@parentModel\",\"@isFulfilled\",\"@toggleTagView\",\"@deleteFromName\"],[\"lead_company\",[30,[36,0],[\"pages.administration.integrations.leadCompanies.tags.apply\"],null],[32,3,[\"leadCompany\",\"tags\"]],[32,3,[\"leadCompany\"]],[32,3,[\"leadCompany\",\"tags\",\"isFulfilled\"]],[30,[36,1],[[32,0],\"toggleTagView\"],null],[32,3,[\"parterCompany\",\"name\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"multi-step-form\",[],[[\"@formSteps\",\"@formOriginalModels\",\"@formStarted\"],[[34,4],[34,5],true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,[32,2],[[24,0,\"partner-company-company-form\"]],[[\"@multiStepForm\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"has-grants\",\"if\",\"companyFormSteps\",\"companyFormOriginalModels\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/administration/company-settings/integrations/partner-companies/company/template.hbs"
    }
  });

  _exports.default = _default;
});