define("securitytrax-ember-ui/validations/policies", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateObject = {
        policy: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('policies-policy')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'policies-policy');
            }
          }
        },
        operator: {
          presence: () => {
            var operatorDisabled = this.item.get('deleting') || this.item.get('resourceTypePolicy.dataType') == 'COMPLEX';

            if (operatorDisabled || 'extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('policies-operator')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'policies-operator');
            }
          }
        },
        conditionRight: {
          presence: () => {
            var conditionRightDisabled = false;

            switch (this.item.get('resourceTypePolicy.dataType')) {
              case 'ENUM':
              case 'FOREIGN_KEY':
              case 'DATE':
              case 'NUMERIC':
                if (this.item.get('deleting')) {
                  conditionRightDisabled = true;
                }

                break;

              default:
                if (this.item.get('deleting') || this.item.get('resourceTypePolicy.dataType') == 'COMPLEX') {
                  conditionRightDisabled = true;
                }

            }

            if (conditionRightDisabled || 'extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('policies-conditionRight')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'policies-conditionRight');
            }
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject, {
        fullMessages: this.prependProperty(data)
      });
      var modelName = data.extraValidationData && data.extraValidationData.hasOwnProperty('overrideValidationKey') ? data.extraValidationData.overrideValidationKey : 'policies';
      return this.outputFormatting(validationData, modelName);
    }

  });

  _exports.default = _default;
});