define("securitytrax-ember-ui/mixins/translate3d-mixin", ["exports", "ember-css-transitions/mixins/transition-mixin", "jquery"], function (_exports, _transitionMixin, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Translate3dMixin
   * @extends Ember.Mixin
   */
  var _default = Ember.Mixin.create({
    constants: Ember.inject.service(),
    attributeBindings: ['translateStyle:style'],
    classNameBindings: ['transformIn:md-transition-in'],
    fromStyle: Ember.computed('defaultedOpenFrom', function () {
      return this.toTransformCss(this.calculateZoomToOrigin(this.element, this.defaultedOpenFrom));
    }),
    centerStyle: Ember.computed(function () {
      return this.toTransformCss('');
    }),
    translateStyle: Ember.computed('fromStyle', 'centerStyle', 'transformStyleApply', function () {
      if (this.transformStyleApply === 'from') {
        return Ember.String.htmlSafe(this.fromStyle);
      } else if (this.transformStyleApply === 'main') {
        return Ember.String.htmlSafe(this.centerStyle);
      } else {
        return Ember.String.htmlSafe('');
      }
    }),

    onTranslateFromEnd() {},

    onTranslateToEnd() {},

    didInsertElement() {
      this._super(...arguments);

      Ember.run.schedule('afterRender', () => {
        // Set translate3d style to start at the `from` origin
        this.set('transformStyleApply', 'from'); // Wait while CSS takes affect
        // Set the `main` styles and run the transition-in styles

        (0, _transitionMixin.nextTick)().then(() => {
          if (this && this.isDestroyed) {
            return;
          }

          Ember.run.later(() => {
            if (this && !this.isDestroying && !this.isDestroyed) {
              this.onTranslateFromEnd();
            }
          }, (0, _transitionMixin.computeTimeout)(this.element) || 0);

          if (this && !this.isDestroying && !this.isDestroyed) {
            this.set('transformStyleApply', 'main');
            this.set('transformIn', true);
          }
        });
      });
    },

    /**
     * Specific reversal of the request translate animation above...
     *
     * @protected
     */
    willDestroyElement() {
      this._super(...arguments);

      var containerClone = (0, _jquery.default)(this.element).parent().clone();
      var dialogClone = containerClone.find('md-dialog');
      (0, _jquery.default)(this.defaultedParent).parent().append(containerClone);
      var toStyle = this.toTransformCss(this.calculateZoomToOrigin(this.element, this.defaultedCloseTo));
      (0, _transitionMixin.nextTick)().then(() => {
        dialogClone.removeClass('md-transition-in');
        dialogClone.addClass('md-transition-out');
        dialogClone.attr('style', toStyle);
        (0, _transitionMixin.nextTick)().then(() => {
          Ember.run.later(() => {
            containerClone.remove();
            this.onTranslateToEnd((0, _jquery.default)(this.origin));
          }, (0, _transitionMixin.computeTimeout)(dialogClone.get(0)) || 0);
        });
      });
    },

    /**
     * Calculate the zoom transform from dialog to origin.
     *
     * We use this to set the dialog position immediately;
     * then the md-transition-in actually translates back to
     * `translate3d(0,0,0) scale(1.0)`...
     *
     * NOTE: all values are rounded to the nearest integer
     *
     * @public
     */
    calculateZoomToOrigin(element, originator) {
      var zoomStyle;

      if (originator) {
        originator = (0, _jquery.default)(originator).get(0);
        var originBnds = this.copyRect(originator.getBoundingClientRect());
        var dialogRect = this.copyRect(element.getBoundingClientRect());
        var dialogCenterPt = this.centerPointFor(dialogRect);
        var originCenterPt = this.centerPointFor(originBnds);
        zoomStyle = {
          centerX: originCenterPt.x - dialogCenterPt.x,
          centerY: originCenterPt.y - dialogCenterPt.y,
          scaleX: Math.min(0.5, originBnds.width / dialogRect.width),
          scaleY: Math.min(0.5, originBnds.height / dialogRect.height)
        };
      } else {
        zoomStyle = {
          centerX: 0,
          centerY: 0,
          scaleX: 0.5,
          scaleY: 0.5
        };
      }

      return "translate3d(".concat(zoomStyle.centerX, "px, ").concat(zoomStyle.centerY, "px, 0 ) scale(").concat(zoomStyle.scaleX, ", ").concat(zoomStyle.scaleY, ")");
    },

    /**
     * Convert the translate CSS value to key/value pair(s).
     *
     * @public
     */
    toTransformCss(transform, addTransition) {
      var styles = '';
      this.constants.get('CSS').TRANSFORM.split(' ').forEach(key => {
        styles += "".concat(key, ":").concat(transform, ";");
      });

      if (addTransition) {
        styles += 'transform: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) !important;';
      }

      return styles;
    },

    /**
     * Clone the Rect and calculate the height/width if needed
     *
     * @public
     */
    copyRect(source, destination) {
      if (!source) {
        return null;
      }

      destination = destination || {};
      'left top right bottom width height'.split(' ').forEach(key => {
        destination[key] = Math.round(source[key]);
      });
      destination.width = destination.width || destination.right - destination.left;
      destination.height = destination.height || destination.bottom - destination.top;
      return destination;
    },

    /**
     * Calculate ClientRect of element; return null if hidden or zero size
     *
     * @public
     */
    clientRect(element) {
      var bounds = (0, _jquery.default)(element)[0].getBoundingClientRect(); // If the event origin element has zero size, it has probably been hidden.

      return bounds && bounds.width > 0 && bounds.height > 0 ? this.copyRect(bounds) : null;
    },

    /**
     * Calculate 'rounded' center point of Rect
     *
     * @public
     */
    centerPointFor(targetRect) {
      return {
        x: Math.round(targetRect.left + targetRect.width / 2),
        y: Math.round(targetRect.top + targetRect.height / 2)
      };
    }

  });

  _exports.default = _default;
});