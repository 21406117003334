define("securitytrax-ember-ui/components/multi-step-form/forms/work-orders/work-orders-base", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency", "securitytrax-ember-ui/mixed-in-functions/grouped-property-sections"], function (_exports, _switchableFormStep, _emberConcurrency, _groupedPropertySections) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    store: Ember.inject.service(),
    calendarEventFormOriginalModels: Ember.computed('calendarEvent', 'grants.userGrants', function () {
      return Ember.Object.create({
        calendarEvent: {
          model: this.calendarEvent,
          copyRelationMap: {
            calendarEventType: {
              doValidate: false,
              doSave: false
            },
            calendarEventDetailType: {
              doCopy: true,
              doValidate: false,
              doSave: false
            },
            calendarUser: {
              doCopy: true,
              doValidate: false,
              doSave: false
            }
          }
        }
      });
    }),
    calendarEventFormSteps: Ember.computed('calendarEvent', function () {
      var intl = this.intl;
      return Ember.Object.create({
        calendarEventSingleStep: Ember.Object.create({
          stepNameKey: 'calendarEventSingleStep',
          title: intl.t('pages.customers.appointments.title'),
          stepComponentPath: 'multi-step-form/forms/calendar-event'
        })
      });
    }),
    groupedEquipment: Ember.computed('customerEquipment.[]', 'multiStepForm.formCopiedModels.workOrder.model.workOrderEquipment.[]', function () {
      return this.groupedEquipmentTask.perform();
    }),
    groupedEquipmentTask: (0, _emberConcurrency.task)(function* () {
      var grants = yield this.grants;
      var childTasks = [];
      var customerTasksViewGrant = yield grants.get('getIndividualGrantAsync').perform('work_orders.view');
      var customerEquipment = customerTasksViewGrant ? yield this.customerEquipment : null;
      var workOrderEquipments = yield this.multiStepForm.formCopiedModelsGetVal('workOrder.model.workOrderEquipment');

      if (customerEquipment) {
        yield this.store.findAll('equipment', {
          include: 'equipment_type'
        });
        customerEquipment.map(ce => {
          childTasks.push(this.mapCustomerEquipment.perform(ce, workOrderEquipments));
        });
      }

      var allCustomerEquipment = yield (0, _emberConcurrency.all)(childTasks);

      if (allCustomerEquipment.length === 0) {
        return allCustomerEquipment;
      }

      return allCustomerEquipment.uniqBy('type.id').map(({
        type
      }) => {
        var items = allCustomerEquipment.filterBy('type.id', type.id).mapBy('equipment');
        return Ember.Object.create({
          name: type.get('name'),
          icon: type.get('icon'),
          color: "background-color: ".concat(type.get('color')),
          sections: (0, _groupedPropertySections.default)(),
          items: items,
          itemsWithTasks: items.filterBy('copiedWorkOrderEquipment.id'),
          collapsable: true,
          collapsed: false,
          collapseIconName: 'icn-chevron-down'
        });
      });
    }),
    mapCustomerEquipment: (0, _emberConcurrency.task)(function* (ce, workOrderEquipments) {
      var hashedData = yield (0, _emberConcurrency.hash)({
        ceId: ce.get('id'),
        equipment: ce.get('equipment'),
        equipmentStatus: ce.get('equipmentStatus'),
        zoneTitle: ce.get('zoneTitle')
      });
      var equipmentName = hashedData.equipment ? yield hashedData.equipment.get('name') : '';
      var equipmentType = hashedData.equipment ? yield hashedData.equipment.get('equipmentType') : null;
      var selectedButton = null;
      var copiedWorkOrderEquipment = null;

      if (!this.isNewRecord) {
        var foundRecord = false;

        if (workOrderEquipments) {
          workOrderEquipments.forEach(cte => {
            if (!foundRecord && cte.get('customerEquipment.id') === hashedData.ceId) {
              copiedWorkOrderEquipment = cte;
              foundRecord = true;
            }
          });
        }

        if (foundRecord) {
          /** workOrderAction ID values:
           *
           * 1 = Install
           * 2 = Service
           * 3 = Remove
           */
          var workOrderActionId = yield copiedWorkOrderEquipment.get('workOrderAction.id');

          switch (workOrderActionId) {
            case '1':
              selectedButton = 0;
              break;

            case '2':
              selectedButton = 2;
              break;

            case '3':
              selectedButton = 1;
              break;
          }
        }
      }

      var equipmentTypeOther = Ember.Object.create({
        id: 0,
        name: this.intl.t('pages.customers.equipment.columns.equipment'),
        // Use this for now until we support global equipment
        icon: 'icn-folder-open',
        color: '#22a1c1'
      });
      var equipmentStatusId = hashedData.equipmentStatus ? hashedData.equipmentStatus.id : 1; //fallback in the event there is no status defined

      var equipmentObject = Ember.Object.create({
        customerEquipment: ce,
        copiedWorkOrderEquipment: copiedWorkOrderEquipment,
        name: equipmentName,
        zoneTitle: hashedData.zoneTitle,
        selectedButton: selectedButton,
        complexButtonChoices: [
        /** equipmentStatus ID values:
         *
         * 1 = Not Installed
         * 2 = Installed
         * 3 = Removed
         */
        {
          label: this.intl.t('pages.customers.workOrders.details.install'),
          disabled: equipmentStatusId === '2' // Installed

        }, {
          label: this.intl.t('pages.customers.workOrders.details.remove'),
          disabled: equipmentStatusId === '3' || equipmentStatusId === '1' // Removed or Not Installed

        }, {
          label: this.intl.t('pages.customers.workOrders.details.service'),
          disabled: equipmentStatusId === '3' || equipmentStatusId === '1' // Removed or Not Installed

        }]
      });
      return {
        equipment: equipmentObject,
        type: equipmentType ? equipmentType : equipmentTypeOther
      };
    })
  });

  _exports.default = _default;
});