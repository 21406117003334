define("securitytrax-ember-ui/components/nav/top-nav-sub/quick-add/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var QuickAdd = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, (_class = (_temp = class QuickAdd extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "quickAdd", _descriptor2, this);

      _initializerDefineProperty(this, "grants", _descriptor3, this);

      _initializerDefineProperty(this, "quickAddFormStarted", _descriptor4, this);
    }

    get quickAddOptions() {
      return {
        pages: {
          customer: {
            sections: {
              customer: {
                granted: this.hasAnyGrantPair(['customer_task', 'customer_files', 'customer_at_risks', 'customer_messages', 'customer_notes'], ['create', 'view']),
                items: [{
                  id: 'task',
                  link: 'customers.notes.edit-create-task',
                  granted: this.hasAllGrants(['customer_tasks.create', 'customer_tasks.view'])
                }, {
                  id: 'note',
                  link: 'customers.notes.edit-create-note',
                  granted: this.hasAllGrants(['customer_notes.create', 'customer_notes.view'])
                }, {
                  id: 'file',
                  link: 'customers.files.upload-file',
                  granted: this.hasAllGrants(['customer_files.create', 'customer_files.view'])
                }, {
                  id: 'message',
                  link: 'customers.messages.create-message',
                  granted: this.hasAllGrants(['customer_messages.create', 'customer_messages.view'])
                }, {
                  id: 'at_risk_entry',
                  link: 'customers.at-risk-entries.edit-create-at-risk',
                  granted: this.hasAllGrants(['customer_at_risks.create', 'customer_at_risks.view'])
                }]
              },
              accounting: {
                granted: this.hasAnyGrantPair(['customer_credit_memos', 'fundings', 'customer_invoices', 'customer_quotes', 'customer_refund_receipts', 'customer_billings'], ['create', 'view']) || this.hasAllGrants(['customer_payables.create', 'customer_payables.view']),
                items: [{
                  id: 'invoice',
                  link: 'customers.accounting.invoices-invoices.edit-create-invoice',
                  granted: this.hasAllGrants(['customer_invoices.create', 'customer_invoices.view'])
                }, {
                  id: 'quote',
                  link: 'customers.accounting.invoices-quotes.edit-create-quote',
                  granted: this.hasAllGrants(['customer_quotes.create', 'customer_quotes.view'])
                }, {
                  id: 'credit_memo',
                  link: 'customers.accounting.invoices-credit-memos.edit-create-credit-memo',
                  granted: this.hasAllGrants(['customer_credit_memos.create', 'customer_credit_memos.view'])
                }, {
                  id: 'refund_receipt',
                  link: 'customers.accounting.invoices-refund-receipts.edit-create-refund-receipt',
                  granted: this.hasAllGrants(['customer_refund_receipts.create', 'customer_refund_receipts.view'])
                }, {
                  id: 'payment_method',
                  link: 'customers.accounting.billing-payment-methods.edit-create-payment-method',
                  granted: this.hasAllGrants(['customer_billings.create', 'customer_billings.view'])
                }, {
                  id: 'funding',
                  link: 'customers.accounting.payments-funding.edit-create-funding',
                  granted: this.hasAllGrants(['fundings.create', 'fundings.view'])
                }, {
                  id: 'payables',
                  link: 'customers.accounting.payments-payables.edit-create-payable',
                  granted: this.hasAllGrants(['customer_payables.create', 'customer_payables.view'])
                }]
              },
              work_order: {
                granted: this.hasAnyGrantPair(['work_orders', 'customer_equipment'], ['create', 'view']),
                items: [{
                  id: 'customer_equipment',
                  link: 'customers.equipment.edit-create-customer-equipment',
                  granted: this.hasAllGrants(['customer_equipment.create', 'customer_equipment.view'])
                }, {
                  id: 'work_order',
                  link: 'customers.work-orders.edit-create-work-order',
                  granted: this.hasAllGrants(['work_orders.create', 'work_orders.view'])
                }]
              },
              other: {
                granted: this.hasAnyGrantPair(['customer_contacts'], ['create', 'view']),
                items: [{
                  id: 'emergency_contact',
                  link: 'customers.contacts.edit-create-customer-contact',
                  granted: this.hasAllGrants(['customer_contacts.create', 'customer_contacts.view'])
                }]
              }
            }
          },
          lead: {
            sections: {
              lead: {
                granted: this.hasAnyGrantPair(['lead_files', 'calendar_events', 'lead_contacts', 'lead_notes', 'lead_messages'], ['create', 'view']),
                items: [{
                  id: 'interaction',
                  link: 'leads.information.edit-create-interaction',
                  granted: this.hasAllGrants(['lead_contacts.create', 'lead_contacts.view'])
                }, {
                  id: 'note',
                  link: 'leads.notes.edit-create-note',
                  granted: this.hasAllGrants(['lead_notes.create', 'lead_notes.view'])
                }, {
                  id: 'appointment',
                  link: 'leads.appointments.edit-create-appointment',
                  granted: this.hasAllGrants(['calendar_events.create', 'calendar_events.view'])
                }, {
                  id: 'message',
                  link: 'leads.messages.create-message',
                  granted: this.hasAllGrants(['lead_messages.create', 'lead_messages.view'])
                }, {
                  id: 'file',
                  link: 'leads.files.upload-file',
                  granted: this.hasAllGrants(['lead_files.create', 'lead_files.view'])
                }]
              },
              accounting: {
                granted: this.hasAnyGrantPair(['lead_quotes'], ['create', 'view']),
                items: [{
                  id: 'quote',
                  link: 'leads.accounting.edit-create-quote',
                  granted: this.hasAllGrants(['lead_quotes.create', 'lead_quotes.view'])
                }]
              }
            }
          }
        }
      };
    }

    hasAnyGrantPair(resourceTypes, grants) {
      var granted = false;

      for (var i = 0; i < resourceTypes.length; i++) {
        var grantsToCheck = [];

        for (var j = 0; j < grants.length; j++) {
          grantsToCheck.push(resourceTypes[i] + '.' + grants[j]);
        }

        if (!granted) {
          granted = this.hasAllGrants(grantsToCheck);
        } else {
          break;
        }
      }

      return granted;
    }

    hasAnyGrant(grants) {
      var granted = false;

      for (var i = 0; i < grants.length; i++) {
        if (!granted) {
          granted = this.grants.getIndividualGrant(grants[i]);
        } else {
          break;
        }
      }

      return granted;
    }

    hasAllGrants(grants) {
      var granted = true;

      for (var i = 0; i < grants.length; i++) {
        if (!this.grants.getIndividualGrant(grants[i])) {
          granted = false;
          break;
        }
      }

      return granted;
    }

    transitionToLink(link) {
      this.quickAddFormStarted = false; // BOWLES TODO: Quick add shouldn't need to know about the object id, but leads is done in such a way that it's required

      this.router.transitionTo(link, this.args.model.customer.get('id'), 'create');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "quickAdd", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "quickAddFormStarted", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "transitionToLink", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToLink"), _class.prototype)), _class));
  _exports.default = QuickAdd;
});