define("securitytrax-ember-ui/components/pages/administration/human-resources/user-list/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/sortable-list-mixin", "securitytrax-ember-ui/utils/obj-helpers", "securitytrax-ember-ui/classes/misc-input-list"], function (_exports, _emberConcurrency, _sortableListMixin, _objHelpers, _miscInputList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_sortableListMixin.default, {
    tagName: '',
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    session: Ember.inject.service(),
    stFiltersService: Ember.inject.service(),
    tagsService: Ember.inject.service(),
    screen: Ember.inject.service(),
    limit: 50,
    page: 1,
    userListCount: 0,
    addUserFormStarted: false,
    userIsActive: undefined,
    userCanLogin: undefined,
    userGroups: undefined,
    miscFilters: undefined,
    appliedFiltersCount: 0,
    filterGroupKeyStr: 'userList',
    tagFilter: undefined,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        miscFilters: new _miscInputList.default(),
        tagFilter: this.tagsService.tagFilter()
      });

      try {
        this.miscFilters.addMiscInput();
      } catch (err) {
        this.set('miscFilters', new _miscInputList.default());
        this.miscFilters.addMiscInput();
      }

      this.initTask.perform();
    },

    initTask: (0, _emberConcurrency.task)(function* () {
      Ember.set(this, 'tagsOptions', yield this.tagsService.tagsOptionsTask.perform('user'));
    }),
    miscFilterOperands: Ember.computed(function () {
      var intl = this.intl;
      return [{
        option: intl.t('common.filters.fname'),
        value: 'fname'
      }, {
        option: intl.t('common.filters.lname'),
        value: 'lname'
      }, {
        option: intl.t('common.filters.username'),
        value: 'username'
      }, {
        option: intl.t('common.columns.email'),
        value: 'email'
      }, {
        option: intl.t('common.columns.id'),
        value: 'id'
      }];
    }),
    miscFilterOperators: Ember.computed(function () {
      var intl = this.intl;
      return [{
        option: intl.t('common.filters.equals'),
        value: '='
      }, {
        option: intl.t('common.filters.starts_with'),
        value: '~%'
      }, {
        option: intl.t('common.filters.contains'),
        value: '%~%'
      }];
    }),
    activeOptions: Ember.computed(function () {
      var intl = this.intl;
      return [{
        value: true,
        name: intl.t('common.columns.active')
      }, {
        value: false,
        name: intl.t('common.status.inactive')
      }];
    }),
    canLoginOptions: Ember.computed(function () {
      var intl = this.intl;
      return [{
        value: true,
        name: intl.t('pages.users.account_information.login')
      }, {
        value: false,
        name: intl.t('pages.users.cannot_login')
      }];
    }),
    groupOptions: Ember.computed('model.groups', function () {
      return this.groupOptionsTask.perform();
    }),
    groupOptionsTask: (0, _emberConcurrency.task)(function* () {
      var options = yield this.get('model.groups');
      var groups = [{
        id: null,
        name: this.intl.t('pages.users.empty_group')
      }];

      if (options.get('length') > 0) {
        groups = options.sortBy('name').toArray();
        groups.unshift({
          id: null,
          name: this.intl.t('pages.users.empty_group')
        });
      }

      return groups;
    }),
    users: Ember.computed(function () {
      return this.getUsersTask.perform();
    }),
    getUsersTask: (0, _emberConcurrency.task)(function* () {
      var grants = yield this.grants;
      var include = 'user_admin_setting,user_inventory_setting.equipment_vendor_to_recipients,user_hr_setting,user_hr_setting.user_hierarchy_title';

      if (grants.getIndividualGrant('groups.view')) {
        include = 'user_admin_setting,user_inventory_setting.equipment_vendor_to_recipients,user_hr_setting,user_hr_setting.user_hierarchy_title,group';
      }

      var newFilters = yield this.stFiltersService.getFiltersByGroupKey(this.filterGroupKeyStr);
      var queryParams = {
        filter: newFilters,
        sort: 'lname,fname',
        include,
        limit: this.limit,
        page: this.page
      };

      if (this.page == 1) {
        queryParams['count'] = 1;
      }

      var users = yield this.store.query('user', queryParams);

      if (this.page == 1) {
        this.set('userListCount', users.meta.record_count);
      }

      return users;
    }).restartable().maxConcurrency(1),
    addUserForm: Ember.computed(function () {
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.intl.t('pages.users.user.new'),
          stepComponentPath: 'multi-step-form/forms/administration/users/create'
        })
      });
    }),
    formOriginalModels: Ember.computed('grants.userGrants', function () {
      var grants = this.grants;
      return Ember.Object.create({
        user: {
          model: null,
          copyRelationMap: {
            group: {
              doCopy: grants.getIndividualGrant('groups.view'),
              doValidate: false,
              doSave: false
            },
            userAdminSetting: {
              doCopy: grants.getIndividualGrant('user_admin_settings.view'),
              doValidate: grants.getIndividualGrant('user_admin_settings.view'),
              doSave: grants.getIndividualGrant('user_admin_settings.modify')
            },
            mobileCarriers: {
              readOnly: true
            },
            userPreference: {
              doCopy: grants.getIndividualGrant('user_preferences.view'),
              doValidate: grants.getIndividualGrant('user_preferences.view'),
              doSave: grants.getIndividualGrant('user_preferences.modify')
            },
            userPasswordSetting: {
              doCopy: grants.getIndividualGrant('user_password_settings.view'),
              doValidate: grants.getIndividualGrant('user_password_settings.view'),
              doSave: grants.getIndividualGrant('user_password_settings.modify')
            }
          }
        }
      });
    }),
    actions: {
      changeRoute(user) {
        this.router.transitionTo("users.information", user.id);
      },

      //action sent to pagination component
      getUsers(options) {
        if (options.isCreate || options.paginationPageUpdate) {
          Ember.notifyPropertyChange(this, 'users');
        }
      },

      clearFilter() {
        Ember.setProperties(this, {
          userIsActive: undefined,
          userCanLogin: undefined,
          userGroups: undefined,
          tagFilter: this.tagsService.tagFilter(),
          'miscFilters.miscInputs': [{
            operand: undefined,
            operator: undefined,
            value: undefined
          }]
        });
        Ember.notifyPropertyChange(this, 'users');
      },

      filterUsers() {
        this.set('page', 1);
        Ember.notifyPropertyChange(this, 'users');
      }

    }
  });

  _exports.default = _default;
});