define("securitytrax-ember-ui/components/modals/sub-route-modals/manage-alula/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Component.extend({
    intl: Ember.inject.service(),
    interactiveServicesProviderManageFormStarted: false,
    interactiveServicesProviderManageFormMode: 'create',
    shellResponse: undefined,
    initFormData: (0, _emberConcurrency.task)(function* () {
      yield;
      this.set('interactiveServicesProviderManageFormMode', this.get('customerSystemInformation.interactiveServicesId') ? 'edit' : 'create');
      this.set('interactiveServicesProviderManageFormStarted', true);
    }).on('init'),
    interactiveServicesProviderManageFormSteps: Ember.computed('customer.{flname,customerOrderInformation.interactiveServicesProvider.name}', 'interactiveServicesProviderManageFormMode', function () {
      var titleData = {
        ispName: this.get('customer.customerOrderInformation.interactiveServicesProvider.name'),
        customerName: this.get('customer.flname'),
        id: this.get('customer.id')
      };
      var titlePath = 'pages.customers.system.interactiveServicesProvider.create_title';
      var title = this.intl.t(titlePath, titleData);
      return Ember.Object.create({
        createCustomerAccount: Ember.Object.create({
          progressBarTitle: 'Create Account',
          stepComponentPath: 'multi-step-form/forms/customer-interactive-services-provider-information/alula/create-account',
          stepNameKey: 'createCustomerAccount',
          title: title
        })
      });
    }),
    ispFormClass: Ember.computed('interactiveServicesProviderManageFormMode', function () {
      return this.interactiveServicesProviderManageFormMode == 'create' ? 'pad-content' : '';
    }),
    actions: {
      transitionBackToParentRoute() {
        this.router.transitionTo(this.parentRoute, this.transitionId);
      }

    }
  });
  var _default = Controller;
  _exports.default = _default;
});