define("securitytrax-ember-ui/components/multi-step-form/switchable-inputs/st-multi-select/st-multi-selected/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GJt/EknZ",
    "block": "{\"symbols\":[\"selectedValue\",\"index\",\"@selectItem\",\"&attrs\",\"@selectedValues\"],\"statements\":[[11,\"div\"],[17,4],[24,0,\"st-multi-selected\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"st-multi-selected__selected-options\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"selected-options__selected-option\"],[12],[2,\"\\n                \"],[10,\"span\"],[14,0,\"selected-options__selected-option--remove\"],[15,\"onclick\",[30,[36,0],[[32,0],[32,3],[32,1],true],null]],[12],[2,\"×\"],[13],[2,\"\\n                \"],[10,\"span\"],[12],[1,[32,1,[\"name\"]]],[13],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/switchable-inputs/st-multi-select/st-multi-selected/template.hbs"
    }
  });

  _exports.default = _default;
});