define("securitytrax-ember-ui/validations/central-station/adt/adt-customer-order", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation", "lodash"], function (_exports, _validate, _baseValidation, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var toValidateJson = this.objectToJson(this);
      var validationObject = {
        lead_source: {
          presence: this.customMessageOrDefault(data, 'adtMonitoringCompanyLeadSource')
        },
        equipment_panel: {
          presence: this.customMessageOrDefault(data, 'adtMonitoringCompanyEquipmentPanel')
        },
        install_type: {
          presence: this.customMessageOrDefault(data, 'adtMonitoringCompanyInstallType')
        },
        creditTracking: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('creditTracking')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'creditTracking');
            }
          }
        }
      };

      var accountType = _lodash.default.get(data, 'extraValidationData.multiStepForm.formCopiedModels.customer.model.accountType', '');

      if (accountType === 'Commercial') {
        toValidateJson.chpa_address_line_1 = _lodash.default.get(toValidateJson, 'credit_holder_physical_address_data.chpa_address_line_1', '');
        toValidateJson.chpa_city = _lodash.default.get(toValidateJson, 'credit_holder_physical_address_data.chpa_city', '');
        toValidateJson.chpa_state = _lodash.default.get(toValidateJson, 'credit_holder_physical_address_data.chpa_state', '');
        toValidateJson.chpa_zip_five_or_nine = _lodash.default.get(toValidateJson, 'credit_holder_physical_address_data.chpa_zip_five_or_nine', '');
        validationObject.chpa_address_line_1 = {
          presence: this.customMessageOrDefault(data, 'address1')
        };
        validationObject.chpa_city = {
          presence: this.customMessageOrDefault(data, 'city')
        };
        validationObject.chpa_state = {
          presence: this.customMessageOrDefault(data, 'state')
        };
        validationObject.chpa_zip_five_or_nine = {
          presence: this.customMessageOrDefault(data, 'zip'),
          format: {
            pattern: /\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d/,
            message: this.customMessage(data, 'zipInvalid')
          }
        };
      }

      if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && !data.extraValidationData.noValidate.includes('trackingPin')) {
        validationObject['tracking_pin'] = {
          presence: this.customMessageOrDefault(data, 'trackingPin')
        };
      }

      if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && !data.extraValidationData.noValidate.includes('houseAccountReason')) {
        validationObject['house_account_reason'] = {
          presence: this.customMessageOrDefault(data, 'houseAccountReason')
        };
      }

      var validateData = (0, _validate.default)(toValidateJson, validationObject, {
        fullMessages: this.prependProperty(data)
      });
      return this.outputFormatting(validateData, 'adtCustomerOrder');
    }

  });

  _exports.default = _default;
});