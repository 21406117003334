define("securitytrax-ember-ui/models/sync-object-to-provider", ["exports", "securitytrax-ember-ui/models/base_models/sync-object-to-provider", "ember-concurrency"], function (_exports, _syncObjectToProvider, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function toCapitalizedWords(name) {
    var words = name.match(/[A-Za-z][a-z]*/g) || [];
    return words.map(capitalize).join(' ');
  }

  function capitalize(word) {
    return word.charAt(0).toUpperCase() + word.substring(1);
  }

  var _default = _syncObjectToProvider.default.extend({
    syncObjectData: Ember.computed('invoice', 'customerPayment', 'customer', 'invoiceBillableItem', 'appliedCredit', function () {
      return this.syncObjectDataTask.perform();
    }),
    syncObjectDataTask: (0, _emberConcurrency.task)(function* () {
      var returnData = {
        type: null,
        customer: null,
        amount: null,
        id: null
      };

      if (this.entityType == 'invoice') {
        var invoice = yield this.invoice; // type

        if (invoice) {
          switch (invoice.invoiceType) {
            case 'invoice':
              returnData.type = 'Invoice';
              break;

            case 'refund_receipt':
              returnData.type = 'Refund Receipt';
              break;

            case 'credit_memo':
              returnData.type = 'Credit Memo';
              break;
          }
        } // customer name


        var customer = invoice ? yield invoice.customer : null;
        returnData.customerName = customer ? customer.lfname : ''; // customer

        returnData.customer = customer; // amount

        returnData.amount = invoice ? "$".concat(invoice.signedAmount) : 0; // id

        returnData.id = invoice ? invoice.id : null;
      } else if (this.entityType == 'customer_payment') {
        var customerPayment = yield this.customerPayment; // type

        returnData.type = 'Payment'; // customer name

        var _customer = yield customerPayment.customer;

        returnData.customerName = _customer ? _customer.lfname : ''; // customer

        returnData.customer = _customer; // amount

        returnData.amount = customerPayment.amountDisplay; // id

        returnData.id = customerPayment.id;
      } else if (this.entityType == 'customer') {
        var _customer2 = yield this.customer; // type


        returnData.type = 'Customer'; // customer name

        returnData.customerName = _customer2 ? _customer2.lfname : ''; // customer

        returnData.customer = _customer2; // amount

        returnData.amount = ''; // id

        returnData.id = _customer2.id;
      } else if (this.entityType == 'invoice_billable_item') {
        var invoiceBillableItem = yield this.invoiceBillableItem; // type

        returnData.type = 'Invoice Billable Item'; // amount

        returnData.amount = ''; // id

        returnData.id = invoiceBillableItem.id;
      } else if (this.entityType == 'applied_credit') {
        var appliedCredit = yield this.appliedCredit;

        var _invoice = yield appliedCredit.get('invoice');

        var _customer3 = _invoice ? yield _invoice.get('customer') : null; // type


        returnData.type = 'Applied Credit'; // customer name

        returnData.customerName = _customer3 ? _customer3.lfname : ''; // customer

        returnData.customer = _customer3; // amount

        returnData.amount = appliedCredit.amountUsed; // id

        returnData.id = appliedCredit.id;
      }

      return returnData;
    }),
    entityTypeDisplay: Ember.computed('entityType', function () {
      return toCapitalizedWords(this.entityType);
    })
  });

  _exports.default = _default;
});