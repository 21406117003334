define("securitytrax-ember-ui/components/dashboard-config/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var DashboardConfig = (_dec = Ember.inject.service('grants'), _dec2 = Ember.inject.service('sessionData'), _dec3 = Ember.inject.service('events/home-dashboard'), _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = (0, _emberConcurrency.task)(function* () {
    var systemInfo = this.grants.getIndividualGrant('customer_system_information.view') && this.args.customer ? yield this.args.customer.get('customerSystemInformation') : null;
    var isp = systemInfo ? yield systemInfo.get('interactiveServicesId') : null;
    var orderInfo = this.grants.getIndividualGrant('customer_order_information.view') && this.args.customer ? yield this.args.customer.get('customerOrderInformation') : null;
    var ispInfo = orderInfo ? yield orderInfo.get('interactiveServicesProvider') : null;
    return isp && ispInfo && ispInfo.get('isConnected');
  }), _dec7 = (0, _emberConcurrency.task)(function* () {
    var orderInfo = this.grants.getIndividualGrant('customer_order_information.view') && this.args.customer ? yield this.args.customer.get('customerOrderInformation') : null;
    var ispInfo = orderInfo ? yield orderInfo.get('interactiveServicesProvider') : null;
    var ispTypeInfo = ispInfo ? yield ispInfo.get('interactiveServicesProviderType') : null;
    return ispTypeInfo ? ispTypeInfo.id : null;
  }), _dec8 = (0, _emberConcurrency.task)(function* () {
    var cards = [];
    var hashedData = yield (0, _emberConcurrency.hash)({
      user: this.sessionData.getUser(),
      // PERF TODO: Includes somehow for emberCards?
      roles: this.args.roles,
      ispConnected: this.args.customer ? this.ispConnectedTask.perform() : false,
      ispProvTypeId: this.customer ? this.ispProviderTypeIdTask.perform() : null
    });
    var emberCards = hashedData.user ? yield hashedData.user.get('emberCards') : [];
    var canvasCards = emberCards.filter(card => {
      return card.get('location') == this.args.editDashboard.dashboardLocation;
    });
    Ember.set(this, 'userCards', emberCards);

    try {
      var copyCardsSectionsArr = Ember.A();
      Object.values(canvasCards).forEach(canvasCard => {
        var pages = canvasCard.cardData.pages;
        Object.values(pages).forEach(page => {
          Object.values(page.sections).forEach(section => {
            section.cards.forEach(cardData => {
              this.args.editDashboard._updateConfigDiscrepancies(cardData, ['grant', 'role', 'roleCheckType', 'cardLocationOverride', 'card_focus_options', 'hideISPNotConnected', 'titleKey']);

              var hasRole = this.args.editDashboard._checkCardRoles(cardData.config, hashedData.roles);

              var ADCCheck = this.args.editDashboard._checkCardProviderType(cardData, hashedData.ispProvTypeId);

              cards.push({
                'order': cardData.order,
                'card': cardData.card,
                'display': this.grants.getIndividualGrant(cardData.config.grant) && ADCCheck && hasRole && !(cardData.config.hideISPNotConnected && !hashedData.ispConnected),
                'config': cardData.config
              });
            });
          });
        });
        copyCardsSectionsArr.push(this.stJson.parse(this.stJson.stringify(canvasCard)));
      });
      Ember.set(this.args.editDashboard, 'dashboardCards', copyCardsSectionsArr);
    } catch (err) {
      Ember.set(this.args.editDashboard, 'dashboardCards', []);
    }
  }), _dec9 = Ember._action, (_class = (_temp = class DashboardConfig extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "grants", _descriptor, this);

      _initializerDefineProperty(this, "sessionData", _descriptor2, this);

      _initializerDefineProperty(this, "homeDashboard", _descriptor3, this);

      _initializerDefineProperty(this, "stJson", _descriptor4, this);

      _initializerDefineProperty(this, "userCards", _descriptor5, this);

      _initializerDefineProperty(this, "ispConnectedTask", _descriptor6, this);

      _initializerDefineProperty(this, "ispProviderTypeIdTask", _descriptor7, this);

      _initializerDefineProperty(this, "deckOfCardsTask", _descriptor8, this);

      this.homeDashboard.on('dashboard-config-rollback', this, '_eventCallback');

      if (!this.args.editDashboard) {
        return Error('You must pass in editDashboard to dashboardConfig');
      }

      this.deckOfCardsTask.perform();
    }

    willDestroy() {
      this.homeDashboard.off('dashboard-config-rollback', this, '_eventCallback');
    }

    _eventCallback(dashboardLocation) {
      if (dashboardLocation === this.args.editDashboard.dashboardLocation) {
        this.deckOfCardsTask.perform();
      }
    }

    get showConfig() {
      return this.args.editDashboard.showConfig;
    }

    get ispConnected() {
      return this.ispConnectedTask.perform();
    }

    get showRiskScore() {
      if (this.args.customer) {
        return this.args.customer.showRiskScoreTask.perform();
      }

      return false;
    }

    get ispProvTypeId() {
      return this.ispProviderTypeIdTask.perform();
    }

    toggleConfig() {
      this.args.editDashboard.toggleConfig();
      this.deckOfCardsTask.perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "homeDashboard", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stJson", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "userCards", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "ispConnectedTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "ispProviderTypeIdTask", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "deckOfCardsTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleConfig", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "toggleConfig"), _class.prototype)), _class));
  _exports.default = DashboardConfig;
});