define("securitytrax-ember-ui/models/base_models/tech-schedule-assignment-run-log", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    calendarCategory: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['tech_schedule', 'sale_schedule']
    }),
    longitude: (0, _model.attr)('number', {
      'readOnly': false
    }),
    latitude: (0, _model.attr)('number', {
      'readOnly': false
    }),
    startTime: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    endTime: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    userMiles: (0, _model.attr)('number', {
      'readOnly': false
    }),
    userMilesFromStart: (0, _model.attr)('number', {
      'readOnly': false
    }),
    userRadius: (0, _model.attr)('number', {
      'readOnly': false
    }),
    assignmentCode: (0, _model.attr)('number', {
      'readOnly': false
    }),
    closestAvailableTechMiles: (0, _model.attr)('number', {
      'readOnly': false
    }),
    closestAvailableTechMilesFromStart: (0, _model.attr)('number', {
      'readOnly': false
    }),
    closestAvailableTechRadius: (0, _model.attr)('number', {
      'readOnly': false
    }),
    message: (0, _model.attr)('string', {
      'readOnly': false
    }),
    info: (0, _model.attr)('string', {
      'readOnly': false
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    techScheduleAssignmentRun: (0, _model.belongsTo)('tech-schedule-assignment-run', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    user: (0, _model.belongsTo)('user', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    closestAvailableTechUser: (0, _model.belongsTo)('user', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    calendarEvent: (0, _model.belongsTo)('calendar-event', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});