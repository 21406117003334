define("securitytrax-ember-ui/models/tech-schedule-udf", ["exports", "securitytrax-ember-ui/models/base_models/tech-schedule-udf"], function (_exports, _techScheduleUdf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _techScheduleUdf.default.extend({});

  _exports.default = _default;
});