define("securitytrax-ember-ui/components/multi-step-form/forms/administration/users/password-reset/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/administration/users/base-users"], function (_exports, _baseUsers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseUsers.default.extend({
    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;

      if (!this.isNewRecord) {
        multiStepForm.registerStepData("passwordReset", 'buttons', [{
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.sendPasswordReset(multiStepForm, buttonPromise).catch(error => {
              this.stNotificationManager.addErrorMessages(error, {
                notificationNamespace: 'modal'
              });
            });
          },
          classNamesString: 'st-btn st-btn-primary user-save-button'
        }]);
      }
    },

    generatePassword: false,
    password: '',
    passwordConfirm: '',

    sendPasswordReset(multiStepForm, buttonPromise) {
      var noValidateArray = ['username', 'fname', 'lname', 'email', 'group'];

      if (this.generatePassword) {
        noValidateArray.push('password');
      } //push passwords onto multiStepForm.formCopiedModels.user.model


      multiStepForm.formCopiedModels.user.model.password = this.password;
      multiStepForm.formCopiedModels.user.model.passwordVerify = this.passwordVerify;
      var validatePromise = multiStepForm.deepValidateAll.perform({
        extraValidationData: {
          validationMessages: {
            passwordEqual: this.intl.t('pages.users.validation_messages.password_equal'),
            passwordLength: this.intl.t('pages.users.validation_messages.password_length')
          },
          noValidate: noValidateArray
        }
      });
      return validatePromise.then(() => {
        return new Ember.RSVP.Promise((resolve, reject) => {
          var user = this.user;
          user.get('userPasswordSetting').then(userPasswordSetting => {
            var attributes = this.password ? {
              password: this.password
            } : null;
            userPasswordSetting.invokeAction('change_password', {
              verb: 'POST',
              data: {
                data: {
                  attributes: attributes,
                  type: 'user_password_settings_action'
                }
              },
              processResponse: true
            }).then(() => {
              this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
              resolve(true);
              buttonPromise.then(() => {
                multiStepForm.formStartedSetVal(false);
              });
            }).catch(err => {
              reject(err);
            });
          });
        });
      });
    }

  });

  _exports.default = _default;
});