define("securitytrax-ember-ui/components/multi-step-form/forms/system/cms/dispatch/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/system/cms/cms-base", "ember-concurrency", "moment", "securitytrax-ember-ui/mixins/list/row-list"], function (_exports, _cmsBase, _emberConcurrency, _moment, _rowList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _cmsBase.default.extend(_rowList.default, {
    centralStationService: Ember.inject.service(),
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    progressBarHidden: false,

    /**
     * INIT
     * get current dispatch instructions, setup list
     * */
    setupFormTask: (0, _emberConcurrency.task)(function* () {
      try {
        var centralStationService = this.centralStationService;
        this.set('cmsStepReady', false);
        var lineItems = yield centralStationService.getData({
          model: this.customer,
          method: 'cms__getSiteGeneralDispatchInstructions',
          responseKey: 'site_general_dispatch_instructions'
        });

        if (!lineItems) {
          lineItems = [];
        }

        this.set('listArray', lineItems);
        this.addRow();
        this.set('cmsStepReady', true);
      } catch (err) {
        this.set('cmsStepReady', true);
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
        (false && Ember.warn('cms dispatch instructions init error' + err, {
          id: 'multiStepForm.forms.system.cms.dispatch.init'
        }));
      }
    }).on('init'),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('dispatch', 'buttons', [{
        text: this.intl.t('common.actions.save'),
        classNamesString: 'st-btn st-btn-primary',
        click: buttonPromise => {
          return this.modifyDispatchInstructions.perform(multiStepForm, buttonPromise);
        }
      }]);
    },

    modifyDispatchInstructions: (0, _emberConcurrency.task)(function* () {
      var messages = [];

      try {
        this.set('cmsStepReady', false);
        var centralStationService = this.centralStationService;
        var dispatchInstructions = this.trueListArray; // validate dispatch instructions frontEnd

        var multiStepForm = this.multiStepForm;
        var formattedObjects = this.prepForValidate('cmsInstructions', false, 'central-station/cms/cms-dispatch-instructions', {
          expireDate: this.intl.t('pages.administration.integrations.monitoringCompanies.integrations.cms.dispatch.expire_date_required')
        });
        yield multiStepForm.validateList.perform(formattedObjects); //format the objects to be expected key/values

        var allInstructionRequests = dispatchInstructions.map(row => {
          return {
            effective_date_time: row.effective_date ? (0, _moment.default)(row.effective_date, 'MM/DD/YYYY').format('MM/DD/YYYY') : (0, _moment.default)().format('MM/DD/YYYY'),
            expire_date_time: row.expire_date ? (0, _moment.default)(row.expire_date, 'MM/DD/YYYY').format('MM/DD/YYYY') : null,
            is_permanent: row.is_permanent ? row.is_permanent : 'false',
            instructions: row.instructions,
            delete: row.delete
          };
        });
        var response = yield centralStationService.postData({
          model: this.customer,
          method: 'cms__modifySiteGeneralDispatchInstructions',
          payload: {
            general_dispatch_instructions: allInstructionRequests
          }
        });
        messages = messages.concat(response.responsePayload.data.messages);
        this.stNotificationManager.addSuccess(this.intl.t('pages.administration.integrations.monitoringCompanies.integrations.cms.dispatch.instructions_saved'), 'modal');
        yield this.setupFormTask.perform();

        if (messages.length > 0) {
          this.stNotificationManager.addWarningMessages(messages, {
            notificationNamespace: 'modal'
          });
        }
      } catch (err) {
        this.set('cmsStepReady', true);
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
        (false && Ember.warn('cms dispatch instructions save error' + err, {
          id: 'multiStepForm.forms.system.cms.dispatch.modifyDispatchInstructions'
        }));
        throw err;
      }
    }),
    actions: {
      updateDispatchList(index) {
        var lineItems = this.listArray;
        var lineItem = lineItems[index];
        this.updateRow(lineItem, index);
      },

      isPermanentAction(row) {
        if (row.is_permanent === true) {
          Ember.set(row, 'expire_date', null);
        }
      }

    }
  });

  _exports.default = _default;
});