define("securitytrax-ember-ui/components/pages/administration/inventory/company-replenishment/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    grants: Ember.inject.service(),
    tagName: '',
    type: null,
    targets: null,
    showPendingPurchaseOrders: false,
    showNotification: true,
    pendingPurchaseOrders: null,
    optionsFormMode: 'edit',
    hideSubmit: true,
    replenishmentNotice: Ember.computed(function () {
      return this.intl.t('pages.administration.company_settings.inventory.company_replenishment.description', {
        link: 'https://answers.securitytrax.com/Support/Company_and_Admin/Inventory_Management/Replenishment'
      });
    }),
    typeOptions: Ember.computed(function () {
      var intl = this.intl;
      return [{
        value: 'company',
        name: intl.t('pages.administration.integrations.leadCompanies.nav.company')
      }, {
        value: 'locations',
        name: intl.t('pages.administration.company_settings.inventory.company_replenishment.locations')
      }, //{value: 'hierarchy', name: intl.t('pages.administration.company_settings.inventory.company_replenishment.hierarchy')}, // TODO TRAX-11108: this is for the hierarchy based replenishment.
      {
        value: 'technicians',
        name: intl.t('common.nav.users')
      }];
    }),
    showTarget: Ember.computed('type', function () {
      var type = this.type;
      this.set('hideSubmit', type !== 'company');
      return type === 'locations' || type === 'hierarchy' || type === 'technicians';
    }),
    targetOptions: Ember.computed('type', function () {
      var type = this.type;
      return type === 'locations' || type === 'hierarchy' || type === 'technicians' ? this.get('model.' + type) : [];
    }),
    initDataTask: (0, _emberConcurrency.task)(function* () {
      var hierarchy = [];
      var hashedData = yield (0, _emberConcurrency.hash)({
        technicians: this.grants.getIndividualGrant('inventory_users.view') ? yield this.store.query('inventoryUser', {
          filter: 'inventory_recipient = true and inventory_auto_replenish = true and user.user_admin_setting.active = true',
          sort: 'lname,fname'
        }) : [],
        locations: this.store.query('location', {
          filter: 'inventory_recipient = true and active = true'
        }),
        vendors: this.store.findAll('equipmentVendor', {
          include: 'equipment_vendor_config'
        }),
        vendorToRecipients: this.grants.getIndividualGrant('equipment_vendor_to_recipients.view') ? this.store.query('equipmentVendorToRecipient', {
          limit: 0
        }) : [],
        equipmentToVendors: this.store.query('equipmentToVendor', {
          limit: 0
        }),
        equipment: this.store.query('equipment', {
          limit: 0,
          sort: 'name',
          filter: 'active=true',
          include: 'equipment_item,equipment_item.equipment_sub_type,equipment_type'
        })
      }); // TODO TRAX-11108: this is for the hierarchy based replenishment.
      // technicians.forEach(tech => {
      //     let parentList = tech.get('hierarchyParentsByUserHierarchyTreeId').perform(1);
      //     if (empty(parentList)) {
      //         parentList = [];
      //     }
      //
      //     parentList.forEach(parent => {
      //         const key = parent.get('flname');
      //         if (!key in hierarchy) {
      //             hierarchy.push(parent)
      //         }
      //     });
      // });

      this.set('model', {
        technicians: hashedData.technicians,
        locations: hashedData.locations,
        hierarchy,
        vendors: hashedData.vendors,
        vendorToRecipients: hashedData.vendorToRecipients,
        equipment: hashedData.equipment,
        equipmentToVendors: hashedData.equipmentToVendors
      });
    }).on('init'),
    replenishInventoryTask: (0, _emberConcurrency.task)(function* () {
      try {
        var type = this.type;
        var company = yield this.sessionData.getCompany();
        var pendingPurchaseOrders = (yield company.invokeAction('company_replenishment', {
          verb: 'POST',
          data: {
            data: {
              replenishment_type: type === 'technicians' ? 'users' : type,
              recipient_ids: type === 'company' ? [] : this.targets.map(target => {
                return target.get('id');
              })
            }
          }
        })).responsePayload.data.filter(po => {
          var recipient = this.get('model.' + (po.recipient_type === 'location' ? 'locations' : 'technicians')).find(recipient => recipient.get('id') === po.recipient_id);
          var vendor = this.get('model.vendors').find(equipmentVendor => equipmentVendor.get('id') === po.vendor_id);
          var vendorToRecipient = this.get('model.vendorToRecipients').find(vendorToRecipient => vendorToRecipient.get('id') == JSON.stringify({
            recipient_class: po.type === 'locations' ? '200' : '100',
            recipient_id: po.recipient_id,
            vendor_id: po.vendor_id
          }));

          if (recipient != undefined) {
            po['retrievalMethodId'] = vendorToRecipient ? vendorToRecipient.get('defaultRetrievalMethodId') : null;
            po['processingMethodId'] = vendorToRecipient ? vendorToRecipient.get('defaultProcessingMethodId') : null;
            po['branchNumber'] = vendorToRecipient && vendorToRecipient.get('defaultProcessingMethodId') === 100 ? vendorToRecipient.get('branchNumber') : null;
            po['recipient'] = recipient;
            po['equipmentVendor'] = vendor;
            po['type'] = type;
            po['created'] = false;
            po['title'] = this.intl.t('pages.administration.company_settings.inventory.company_replenishment.pending_purchase_order_title', {
              name: recipient ? po.recipient_type === 'location' ? recipient.get('name') : recipient.get('flname') : '',
              vendor: vendor ? vendor.get('name') : ''
            });
            return true;
          }

          return false;
        });
        this.set('hideSubmit', true);
        this.set('optionsFormMode', 'view');
        this.set('pendingPurchaseOrders', pendingPurchaseOrders.sort(function (a, b) {
          if (a.recipient.get('name') > b.recipient.get('name')) return 1;
          if (a.recipient.get('name') < b.recipient.get('name')) return -1;
          if (a.equipmentVendor.get('name') > b.equipmentVendor.get('name')) return 1;
          if (a.equipmentVendor.get('name') < b.equipmentVendor.get('name')) return -1;
        }));
        this.set('showPendingPurchaseOrders', true);
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err);
        throw err;
      }
    })
  });

  _exports.default = _default;
});