define("securitytrax-ember-ui/components/multi-step-form/forms/work-orders/appointments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lPMHyPZ7",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[2,\"\\n\\n\"],[11,\"div\"],[17,1],[12],[2,\"\\n    \"],[8,\"multi-step-form/progress-bar\",[],[[\"@config\",\"@modalButtons\"],[[34,0],[34,1,[\"currentStepData\",\"buttons\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"html/st-await\",[],[[\"@useSpinner\",\"@booleanLoaded\"],[true,[34,1,[\"finishedCopyingModels\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"customer/appointments/appointments-list\",[],[[\"@calendarEvents\",\"@workOrderView\",\"@additionalCardClass\",\"@renderSortInPlace\",\"@customer\"],[[30,[36,3],[[30,[36,2],null,[[\"model\",\"relName\",\"useCallback\",\"options\"],[[35,1,[\"formOriginalModels\",\"workOrder\",\"model\"]],\"calendarEvents\",false,[30,[36,2],null,[[\"params\"],[[30,[36,2],null,[[\"limit\",\"include\"],[0,\"calendar_user\"]]]]]]]]]],null],true,\"st-card--no-border\",true,[34,4]]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"progressBarConfig\",\"multiStepForm\",\"hash\",\"load-relationship-with-query-params\",\"customer\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/work-orders/appointments/template.hbs"
    }
  });

  _exports.default = _default;
});