define("securitytrax-ember-ui/components/multi-step-form/forms/administration/users/human-resources/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/administration/users/base-users", "ember-concurrency", "lodash"], function (_exports, _baseUsers, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseUsers.default.extend({
    store: Ember.inject.service(),
    copiedHrSetting: Ember.computed.alias('multiStepForm.formCopiedModels.user.model.userHrSetting'),
    orgChartParent: null,
    initialOrgChartParent: null,
    userNotInOrgTree: false,
    allDirectoryUsersCached: null,

    init() {
      this._super(...arguments);

      return this.initTask.perform();
    },

    initTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels');
      var currentUser = yield this.store.findRecord('directoryUser', this.multiStepForm.formOriginalModels.user.model.id);
      var orgChartParent = currentUser ? yield currentUser.get('orgChartParent') : null;
      this.set('orgChartParent', orgChartParent);
      this.set('initialOrgChartParent', orgChartParent);
    }),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;

      if (!this.isNewRecord) {
        multiStepForm.registerStepData("humanResources", 'buttons', [{
          text: this.intl.t('common.actions.cancel'),
          click: () => {
            multiStepForm.resetForm(false);
            this.set('orgChartParent', this.initialOrgChartParent);
            this.set('humanResourcesFormMode', 'view');
          },
          classNamesString: 'st-btn btn-secondary user-cancel-button'
        }, {
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            var noValidateArray = ['group'];
            return this.saveUserDataTask.perform(multiStepForm, buttonPromise, noValidateArray);
          },
          classNamesString: 'st-btn st-btn-primary user-save-button'
        }]);
      }
    },

    users: Ember.computed(function () {
      return this.store.query('user', {
        filter: 'user_admin_setting.active=true',
        limit: 0
      });
    }),
    orgChartUsers: Ember.computed(function () {
      return this.getOrgChartUsers.perform();
    }),
    getOrgChartUsers: (0, _emberConcurrency.task)(function* () {
      this.set('userNotInOrgTree', false);
      var tree = yield this.store.findRecord('userHierarchyTree', 1);
      var allDirectoryUsers = this.allDirectoryUsersCached ? this.allDirectoryUsersCached : yield this.store.query('directoryUser', {
        limit: 0
      });

      if (!this.allDirectoryUsersCached) {
        this.set('allDirectoryUsersCached', allDirectoryUsers);
      }

      try {
        //gets the users that are currently in the main company org chart but not in their current hierarchy
        var isUserInTreeCheck = yield this.store.query('userHierarchy', {
          filter: "user_hierarchy_tree_id = 1 and user_id = '".concat(this.multiStepForm.formOriginalModels.user.model.id, "'")
        });

        if (isUserInTreeCheck.length > 0) {
          var usersThatAreNotDescendantsAndAreNotUncategorized = yield tree.invokeAction('get_users_not_in_a_users_hierarchy', {
            verb: 'POST',
            data: {
              'data': {
                'relationships': {
                  'user_hierarchy': {
                    'data': {
                      'type': 'user_hierarchies',
                      'id': {
                        user_hierarchy_tree_id: '1',
                        user_id: "".concat(this.multiStepForm.formOriginalModels.user.model.id)
                      }
                    }
                  }
                }
              }
            }
          });

          var users = _lodash.default.intersectionBy(allDirectoryUsers.toArray(), usersThatAreNotDescendantsAndAreNotUncategorized.responsePayload.data, 'id');

          if (this.orgChartParent) {
            var currentParent;

            if (this.orgChartParent.get('user')) {
              currentParent = (yield this.store.findRecord('userHierarchy'), this.orgChartParent.id);
            } else {
              currentParent = this.allDirectoryUsersCached.find(user => {
                return user.id === this.orgChartParent.id;
              });
            }

            users.push(currentParent);
          }

          return users;
        } else {
          //gets all the hierarchy users from the current hierarchy tree (1)
          this.set('userNotInOrgTree', true);

          if (tree) {
            var userHierarchyUsers = yield this.store.query('userHierarchy', {
              include: 'user'
            });

            var _users = userHierarchyUsers.filter(userHierarchy => {
              var userId = JSON.parse(userHierarchy.get('id'));
              userHierarchy.set('lfname', userHierarchy.get('user.lfname'));
              return userId && userId.user_hierarchy_tree_id == tree.get('id');
            });

            return _users;
          }
        }
      } catch (err) {
        (false && Ember.warn('something went wrong getting orgChartUsers: ' + err, {
          id: 'human-resources/getOrgCharUsers'
        }));
        return [];
      }
    })
  });

  _exports.default = _default;
});