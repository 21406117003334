define("securitytrax-ember-ui/services/locales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * List of most used Locales from https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
   *
   * @class Locales
   * @memberof services
   * @extends Ember.Service
   */
  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),

    all() {
      return [{
        value: 'en-US',
        name: this.intl.t('common.language-array.en')
      }, {
        value: 'fr-CA',
        name: this.intl.t('common.language-array.fr')
      }, {
        value: 'es-US',
        name: this.intl.t('common.language-array.es')
      }];
    }

  });

  _exports.default = _default;
});