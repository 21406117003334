define("securitytrax-ember-ui/components/html/st-duplicates/component", ["exports", "@glimmer/component", "jquery"], function (_exports, _component, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class StDuplicates extends _component.default {
    constructor() {
      super(...arguments);
      (0, _jquery.default)(this.args.scrollContainer).scrollTop(0);
    }

  }

  _exports.default = StDuplicates;
});