define("securitytrax-ember-ui/components/pages/demo-page/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    includeCard: true,
    hideNav: false
  });

  _exports.default = _default;
});