define("securitytrax-ember-ui/models/payroll-role-function", ["exports", "securitytrax-ember-ui/models/base_models/payroll-role-function", "ember-concurrency"], function (_exports, _payrollRoleFunction, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _payrollRoleFunction.default.extend({
    display: Ember.computed('payrollRole', 'name', function () {
      var payrollRole = this.payrollRole;
      return payrollRole.get('name') + ' | ' + this.name;
    }),
    displayPromise: Ember.computed('payrollRole', 'name', function () {
      return this.displayPromiseTask.perform();
    }),
    displayPromiseTask: (0, _emberConcurrency.task)(function* () {
      var payrollRole = yield this.payrollRole;
      return payrollRole.get('name') + ' | ' + this.name;
    })
  });

  _exports.default = _default;
});