define("securitytrax-ember-ui/components/pages/administration/inventory/inventory-spillage/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    grants: Ember.inject.service(),
    inventoryService: Ember.inject.service(),
    stFiltersService: Ember.inject.service(),
    tagsService: Ember.inject.service(),
    screen: Ember.inject.service(),
    tagName: '',
    pendingRMASaved: false,
    showPendingRMA: false,
    pendingRMA: null,
    rmaDeleteId: null,
    limit: 50,
    page: 1,
    createSpillageFormMode: 'edit',
    createSpillageFormStarted: false,
    spillageReport: undefined,
    spillageListStatus: undefined,
    spillageReceivedStatus: undefined,
    spillageListCount: 0,
    spillage: undefined,
    createdDateFrom: undefined,
    createdDateTo: undefined,
    recipient: undefined,
    showNotification: true,
    appliedFiltersCount: 0,
    tagFilter: undefined,

    init() {
      this._super(...arguments);

      Ember.set(this, 'tagFilter', this.tagsService.tagFilter());
      this.initTask.perform();
    },

    initTask: (0, _emberConcurrency.task)(function* () {
      Ember.set(this, 'tagsOptions', yield this.tagsService.tagsOptionsTask.perform('pending_equipment'));
    }),
    pendingRMAsInfoMessage: Ember.computed('pendingRMA.length', 'pendingRMA.@each.{created,removed}', function () {
      var length = 0;

      if (this.pendingRMA) {
        this.pendingRMA.forEach(rma => {
          if (!rma.created && !rma.removed) {
            length++;
          }
        });
      }

      return this.intl.t('pages.administration.company_settings.inventory.spillage.pending_rmas_info', {
        quantity: length
      });
    }),
    spillageNotice: Ember.computed(function () {
      return this.intl.t('common.filters.default_filters_applied') + ' ' + this.intl.t('pages.administration.company_settings.inventory.spillage.description', {
        link: 'https://answers.securitytrax.com/Support/Company_and_Admin/Inventory_Management/Spillage_Reports'
      });
    }),
    receivedStatusOptions: Ember.computed(function () {
      var intl = this.intl;
      return [{
        value: 'none',
        name: intl.t('pages.users.my_inventory.purchase_orders.status.none')
      }, {
        value: 'partially',
        name: intl.t('pages.users.my_inventory.purchase_orders.status.partially')
      }, {
        value: 'full',
        name: intl.t('pages.users.my_inventory.purchase_orders.status.in_full')
      }];
    }),
    statusOptions: Ember.computed(function () {
      var intl = this.intl;
      return [{
        value: 'open',
        name: intl.t('pages.users.my_inventory.spillage.open')
      }, {
        value: 'locked',
        name: intl.t('pages.users.my_inventory.spillage.locked')
      }, {
        value: 'closed',
        name: intl.t('pages.users.my_inventory.spillage.closed')
      }];
    }),
    recipientOptions: Ember.computed(function () {
      return this.inventoryService.get('getInventoryRecipients').perform();
    }),
    spillageListDefault: Ember.computed('statusOptions', function () {
      var spillageListDefault = this.statusOptions.filter(status => {
        return status.value === 'open' || status.value === 'locked';
      });
      return spillageListDefault;
    }),
    createSpillageFormOriginalModels: Ember.computed('spillageReport', function () {
      return Ember.Object.create({
        equipmentSpillage: {
          model: this.spillageReport,
          copyRelationMap: {
            user: {
              doCopy: true,
              doValidate: false,
              doSave: false
            },
            location: {
              readOnly: true
            },
            createdByUser: {
              doCopy: true,
              doValidate: false,
              doSave: false
            },
            updatedByUser: {
              doCopy: true,
              doValidate: false,
              doSave: false
            },
            pendingEquipmentLineItems: {
              relationships: {
                equipment: {
                  doCopy: true,
                  doSave: false
                },
                equipmentReturnReason: {
                  doCopy: true,
                  doSave: false
                }
              }
            }
          }
        }
      });
    }),
    spillageList: Ember.computed(function () {
      return this.getSpillageListTask.perform();
    }),
    getSpillageListTask: (0, _emberConcurrency.task)(function* () {
      var newFilters = yield this.stFiltersService.getFiltersByGroupKey('companyInventorySpillage');
      var queryParams = {
        filter: newFilters,
        sort: '-id',
        limit: this.limit,
        page: this.page,
        include: 'user,location,pending_equipment_line_items.equipment_return_reason,pending_equipment_line_items.equipment,equipment_vendor'
      };

      if (this.page == 1) {
        queryParams['count'] = 1;
      }

      var spillageList = yield this.store.query('equipmentSpillage', queryParams);

      if (this.page == 1) {
        this.set('spillageListCount', spillageList.meta.record_count);
      }

      return spillageList;
    }).restartable().maxConcurrency(1),
    formSteps: Ember.computed('createSpillageFormMode', 'spillageReport.{id,status}', function () {
      var recipient = '';

      if (this.get('spillageReport.user.flname')) {
        recipient = this.get('spillageReport.user.flname');
      } else {
        recipient = this.get('spillageReport.location.name');
      }

      var titleData = {
        id: this.get('spillageReport.id'),
        userName: recipient
      };
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.intl.t("pages.users.my_inventory.spillage.".concat(this.createSpillageFormMode, "_title"), titleData),
          stepComponentPath: 'multi-step-form/forms/administration/inventory/spillage/' + (this.spillageReport ? this.spillageReport.get('status') === 'open' ? 'edit-spillage' : 'edit-locked-spillage' : 'create-spillage')
        })
      });
    }),
    actions: {
      //action sent to pagination component
      getSpillageList() {
        Ember.notifyPropertyChange(this, 'spillageList');
      },

      clearFilter() {
        Ember.setProperties(this, {
          spillageListStatus: this.spillageListDefault,
          spillageReceivedStatus: undefined,
          createdDateFrom: undefined,
          createdDateTo: undefined,
          recipient: undefined,
          tagFilter: this.tagsService.tagFilter()
        });
        Ember.notifyPropertyChange(this, 'spillageList');
      },

      filterSpillage() {
        this.set('page', 1);
        Ember.notifyPropertyChange(this, 'spillageList');
      },

      editAction(row) {
        this.set('spillageReport', row);
        this.set('createSpillageFormStarted', true);
        this.set('createSpillageFormMode', this.grants.getIndividualGrant('equipment_spillage.modify') ? 'edit' : 'view');
      },

      addAction() {
        this.set('spillageReport', null);
        this.set('createSpillageFormStarted', true);
        this.set('createSpillageFormMode', 'create');
      },

      refreshModel() {
        Ember.notifyPropertyChange(this, 'spillageList');
      }

    }
  });

  _exports.default = _default;
});