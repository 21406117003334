define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/monitoring-companies/integrations/adt-integration/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/monitoring-companies/integrations/integrations-base", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "securitytrax-ember-ui/mixed-in-classes/monitoring-company-connection", "ember-concurrency", "lodash"], function (_exports, _integrationsBase, _switchableFormStep, _monitoringCompanyConnection, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _dec40, _dec41, _dec42, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _descriptor38, _descriptor39, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var AdtIntegration = (_dec = Ember.inject.service('intl'), _dec2 = Ember.inject.service('stNotificationManager'), _dec3 = Ember.inject.service('centralStationService'), _dec4 = Ember.inject.service('store'), _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._tracked, _dec21 = Ember._tracked, _dec22 = Ember._tracked, _dec23 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
    var config = this.args.multiStepForm.formCopiedModelsGetVal('monitoringCompany.model.monitoringCompanyConfig');
    var availableContractTemplates = [];
    this.productionEnvironment = config['environment'] === 'Production';
    this.equipment = yield this.store.query('equipment', {
      limit: 0,
      sort: 'name',
      filter: 'active=true'
    });
    var hashedData = yield (0, _emberConcurrency.hash)({
      radioTypes: this.radioTypesTask.perform(),
      leadSources: this.leadSourcesTask.perform(),
      equipmentPanels: this.equipmentPanelsTask.perform(),
      productCodes: this.productCodesTask.perform()
    });

    if (config.get('availableEContractTemplates')) {
      availableContractTemplates = Object.keys(config.get('availableEContractTemplates')).map(identifier => {
        return {
          identifier: identifier,
          name: config.get('availableEContractTemplates')[identifier]
        };
      });
    }

    if (this.args.multiStepForm.formCopiedModelsGetVal('monitoringCompany.model.paymentReceivedFillsSopDepositAmountWithActivationFee') === null) {
      this.args.multiStepForm.formCopiedModelsSetVal('monitoringCompany.model.paymentReceivedFillsSopDepositAmountWithActivationFee', true);
    }

    availableContractTemplates.push({
      identifier: null,
      name: null
    });
    this.availableContractTemplates = availableContractTemplates;
    Ember.setProperties(this, hashedData);
    var hashedData2 = yield (0, _emberConcurrency.hash)({
      equipmentProductCodes: this.equipmentProductCodesTask.perform(),
      leadSourceList: this.leadSourceListTask.perform(),
      equipmentPanelList: this.equipmentPanelListTask.perform()
    });
    Ember.setProperties(this, hashedData2);
    var hashedData3 = yield (0, _emberConcurrency.hash)({
      checkEquipmentPanelBlacklistSelectAllIndetermanant: this.equipmentPanelBlacklistSelectAllIndetermanantTask.perform(),
      checkLeadSourceListSelectAllIndeterminate: this.leadSourceListSelectAllIndetermanantTask.perform(),
      equipmentPanelApprovedList: this.equipmentPanelApprovedListTask.perform(),
      leadSourceApprovedList: this.leadSourceApprovedListTask.perform()
    });
    Ember.setProperties(this, hashedData3);
    var equipmentProductCodeAssociationEquipmentItemIds = this.equipmentProductCodes.map(association => {
      return association.equipment ? association.equipment.get('id') : null;
    });
    this.filteredEquipment = this.equipment.filter(item => {
      return !equipmentProductCodeAssociationEquipmentItemIds.includes(item.get('id'));
    });
  }), _dec24 = (0, _emberConcurrency.task)(function* () {
    var radioTypeList = [];
    var radioTypes = yield this.centralStationService.getData({
      model: this.args.multiStepForm.formOriginalModelsGetVal('monitoringCompany.model'),
      method: 'adt__getRadioTypes',
      responseKey: 'radio_types'
    });

    if (radioTypes !== null) {
      radioTypeList = radioTypes.map(radioType => {
        return {
          name: radioType.name,
          id: radioType.id
        };
      });
    }

    return radioTypeList;
  }), _dec25 = (0, _emberConcurrency.task)(function* () {
    return yield this.centralStationService.getData({
      model: this.args.multiStepForm.formOriginalModelsGetVal('monitoringCompany.model'),
      method: 'adt__getLeadSources',
      payload: {
        full_list: true
      },
      responseKey: 'lead_sources'
    });
  }), _dec26 = (0, _emberConcurrency.task)(function* () {
    return yield this.centralStationService.getData({
      model: this.args.multiStepForm.formOriginalModelsGetVal('monitoringCompany.model'),
      method: 'adt__getEquipmentPanels',
      payload: {
        full_list: true
      },
      responseKey: 'equipment_panels'
    });
  }), _dec27 = (0, _emberConcurrency.task)(function* () {
    return yield this.centralStationService.getData({
      model: this.args.multiStepForm.formOriginalModelsGetVal('monitoringCompany.model'),
      method: 'adt__getProductCodes',
      responseKey: 'product_codes'
    });
  }), _dec28 = (0, _emberConcurrency.task)(function* () {
    var equipment = this.equipment;
    var config = this.args.multiStepForm.formCopiedModelsGetVal('monitoringCompany.model.monitoringCompanyConfig');
    var productCodes = yield this.productCodes;
    var equipmentProductCodes = [];
    var associatedCodes = [{
      equipment: null,
      productCode: null
    }];

    if (config.get('equipmentProductCodes')) {
      equipmentProductCodes = Object.keys(config['equipmentProductCodes']).map(productId => {
        return {
          productId: productId,
          productCode: config.get('equipmentProductCodes')[productId]
        };
      });
    }

    if (equipmentProductCodes !== null) {
      associatedCodes = equipmentProductCodes.map(associatedCode => {
        return {
          equipment: equipment !== null ? equipment.findBy('id', associatedCode.productId) : null,
          productCode: productCodes !== null ? productCodes.findBy('id', associatedCode.productCode) : null
        };
      });
      associatedCodes.push({
        equipment: null,
        productCode: null
      });
    }

    return associatedCodes;
  }), _dec29 = (0, _emberConcurrency.task)(function* () {
    var equipmentPanels = yield this.equipmentPanels;
    var approvedPanels = [];
    var blackListedArray = this.args.multiStepForm.formCopiedModelsGetVal('monitoringCompany.model.monitoringCompanyConfig.equipmentPanelBlacklist');
    var blackListed;

    if (blackListedArray === null || typeof blackListedArray === 'undefined' || !Array.isArray(blackListedArray)) {
      blackListed = {};
    } else {
      blackListed = blackListedArray.reduce((a, b) => (a[b] = true, a), {});
    }

    if (equipmentPanels !== null) {
      approvedPanels = equipmentPanels.reduce((approvedList, panel) => {
        if (blackListed[panel.name] == undefined || blackListed[panel.name] == false) {
          approvedList.push({
            name: panel.name,
            id: panel.name
          });
        }

        return approvedList;
      }, []);
    }

    return approvedPanels;
  }), _dec30 = (0, _emberConcurrency.task)(function* () {
    var equipmentPanels = yield this.equipmentPanels;
    var config = this.args.multiStepForm.formCopiedModelsGetVal('monitoringCompany.model.monitoringCompanyConfig');
    var equipmentPanelList = [];

    if (equipmentPanels !== null) {
      equipmentPanelList = equipmentPanels.map(equipmentPanel => {
        var panel = {
          blackListed: false,
          id: equipmentPanel.id,
          inputName: equipmentPanel.id.replace(/[^a-zA-Z0-9]/g, ''),
          name: equipmentPanel.name
        };
        var equipmentPanelBlacklist = config.get('equipmentPanelBlacklist');

        if (equipmentPanelBlacklist == undefined || !Array.isArray(equipmentPanelBlacklist)) {
          Ember.set(config, 'equipmentPanelBlacklist', []);
        }

        var blackListed = config.get('equipmentPanelBlacklist').reduce((a, b) => (a[b] = true, a), {});

        if (blackListed[equipmentPanel.id] === true) {
          panel.blackListed = true;
        }

        return panel;
      });
    }

    return equipmentPanelList;
  }), _dec31 = (0, _emberConcurrency.task)(function* () {
    var leadSources = yield this.leadSources;
    var approved = [];
    var blackListedArray = this.args.multiStepForm.formCopiedModelsGetVal('monitoringCompany.model.monitoringCompanyConfig.leadSourceBlacklist');
    var blackListed;

    if (blackListedArray === null || typeof blackListedArray === 'undefined' || !Array.isArray(blackListedArray)) {
      blackListed = {};
    } else {
      blackListed = blackListedArray.reduce((a, b) => (a[b] = true, a), {});
    }

    if (leadSources !== null) {
      approved = leadSources.reduce((approvedList, leadSource) => {
        if (blackListed[leadSource.id] == undefined || blackListed[leadSource.id] == false) {
          approvedList.push({
            name: leadSource.name,
            id: leadSource.id
          });
        }

        return approvedList;
      }, []);
    }

    return approved;
  }), _dec32 = (0, _emberConcurrency.task)(function* () {
    var leadSources = yield this.leadSources;
    var config = this.args.multiStepForm.formCopiedModelsGetVal('monitoringCompany.model.monitoringCompanyConfig');
    var leadSourceList = [];

    if (leadSources !== null) {
      leadSourceList = leadSources.map(leadSource => {
        var source = {
          blackListed: false,
          id: leadSource.id,
          inputName: leadSource.id.replace(/[^a-zA-Z0-9]/g, ''),
          name: leadSource.name
        };
        var leadSourceBlacklist = config.get('leadSourceBlacklist');

        if (leadSourceBlacklist == undefined || !Array.isArray(leadSourceBlacklist)) {
          Ember.set(config, 'leadSourceBlacklist', []);
        }

        var blackListed = config.get('leadSourceBlacklist').reduce((a, b) => (a[b] = true, a), {});

        if (blackListed[source.id] === true) {
          source.blackListed = true;
        }

        return source;
      });
    }

    return leadSourceList;
  }), _dec33 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var formattedObjects = [];
    var config = this.args.multiStepForm.formCopiedModelsGetVal('monitoringCompany.model.monitoringCompanyConfig');
    var isEnabled = this.args.multiStepForm.formOriginalModelsGetVal('monitoringCompany.model.monitoringCompanyConfig.enabled');
    var overrideConfig = Ember.Object.create({
      monitoringCompany: {
        model: this.monitoringCompany,
        copyRelationMap: {
          monitoringCompanyType: {
            readOnly: true
          },
          monitoringCompanyConfig: {}
        }
      }
    });
    Ember.set(config, 'environment', this.productionEnvironment ? 'Production' : 'Development');

    if (isEnabled) {
      //these require the integration to be enabled in order to use central_station_invoke_generic
      var hashData = yield (0, _emberConcurrency.hash)({
        productCodes: this.equipmentProductCodes,
        panels: this.equipmentPanelList,
        leadSources: this.leadSourceList
      });
      hashData.productCodes.forEach((code, index) => {
        if (index < hashData.productCodes.length - 1) {
          formattedObjects.pushObject(Ember.Object.create({
            model: code,
            filePath: 'central-station/adt/adt-product-code-association',
            extraValidationData: {
              overrideValidationKey: index + '-adtProductCodeAssociation'
            }
          }));
        }
      });
      yield this.args.multiStepForm.validateList.perform(formattedObjects);
      Ember.set(config, 'availableEContractTemplates', this.transformAvailableContractTemplates(this.availableContractTemplates));
      Ember.set(config, 'equipmentProductCodes', this.transformProductCodes(hashData.productCodes));
      Ember.set(config, 'equipmentPanelBlacklist', this.transformBlacklist(hashData.panels));
      Ember.set(config, 'leadSourceBlacklist', this.transformBlacklist(hashData.leadSources));
    }

    yield this.args.multiStepForm.validateSyncSaveAll({
      extraValidationData: {
        noValidate: ['accountNumberBlock-startNumber', 'accountNumberBlock-receiverNumber', 'accountNumberBlock-monitoringCompany', 'monitoringCompanyConfig-newPassword', 'monitoringCompanyConfig-confirmNewPassword', 'monitoringCompanyConfig-username', 'monitoringCompanyConfig-password', 'monitoringCompanyConfig-keyCode', 'monitoringCompanyConfig-usernameId', 'monitoringCompanyConfig-dealerNumber', 'monitoringCompanyConfig-partnerId']
      },
      overrideOriginalModelsConfig: overrideConfig,
      notificationNamespace: 'modal',
      successNotificationNamespace: 'modal'
    });

    if (config.get('availableEContractTemplates')) {
      this.availableContractTemplates = Object.keys(config.get('availableEContractTemplates')).map(identifier => {
        return {
          identifier: identifier,
          name: config.get('availableEContractTemplates')[identifier]
        };
      });
      this.availableContractTemplates.push({
        identifier: null,
        name: null
      });
    }

    buttonPromise.then(() => {
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
      Ember.notifyPropertyChange(this, 'isEnabled');
    });
  }), _dec34 = (0, _emberConcurrency.task)(function* (index, row) {
    var equipmentProductCodes = yield this.equipmentProductCodes;
    equipmentProductCodes.replace(index, 1, [row]);
    this.updateFilteredList(equipmentProductCodes);

    if (equipmentProductCodes.length - 1 === index) {
      equipmentProductCodes.push({
        equipment: null,
        productCode: null
      });
    }
  }), _dec35 = (0, _emberConcurrency.task)(function* (index) {
    var equipmentProductCodes = yield this.equipmentProductCodes;
    equipmentProductCodes.removeAt(index);
    this.updateFilteredList(equipmentProductCodes);
    Ember.set(this, 'equipmentProductCodeDeleteId', false);
  }), _dec36 = (0, _emberConcurrency.task)(function* () {
    var indeterminate = false;
    var equipmentPanels = yield this.equipmentPanels;

    var equipmentPanelBlacklist = _lodash.default.filter(yield this.equipmentPanelList, function (currPanel) {
      return currPanel.blackListed;
    });

    if (equipmentPanelBlacklist.length === equipmentPanels.length) {
      this.equipmentPanelsAllSelected = true;
    } else if (equipmentPanelBlacklist.length === 0) {
      this.equipmentPanelsAllSelected = false;
    } else if (equipmentPanelBlacklist.length > 0 && equipmentPanelBlacklist.length !== equipmentPanels.length) {
      this.equipmentPanelsAllSelected = false;
      indeterminate = true;
    }

    this.equipmentPanelBlacklistSelectAllIndetermanant = indeterminate;
  }), _dec37 = (0, _emberConcurrency.task)(function* () {
    var indeterminate = false;
    var leadSources = yield this.leadSources;

    var leadSourcesBlacklist = _lodash.default.filter(yield this.leadSourceList, function (currPanel) {
      return currPanel.blackListed;
    });

    if (leadSourcesBlacklist.length === leadSources.length) {
      this.leadSourcesAllSelected = true;
    } else if (leadSourcesBlacklist.length === 0) {
      this.leadSourcesAllSelected = false;
    } else if (leadSourcesBlacklist.length > 0 && leadSourcesBlacklist.length !== leadSources.length) {
      this.leadSourcesAllSelected = false;
      indeterminate = true;
    }

    this.leadSourceListSelectAllIndetermanant = indeterminate;
  }), _dec38 = (0, _emberConcurrency.task)(function* () {
    var config = this.args.multiStepForm.formCopiedModelsGetVal('monitoringCompany.model.monitoringCompanyConfig');

    if (this.equipmentPanelsAllSelected) {
      var equipmentPanels = yield this.equipmentPanels;
      var blackList = equipmentPanels.map(item => {
        return item.id;
      });
      Ember.set(config, 'equipmentPanelBlacklist', blackList);
    } else {
      Ember.set(config, 'equipmentPanelBlacklist', []);
    }

    this.equipmentPanelList = yield this.equipmentPanelListTask.perform();
    yield this.equipmentPanelBlacklistSelectAllIndetermanantTask.perform();
  }), _dec39 = (0, _emberConcurrency.task)(function* () {
    var config = this.args.multiStepForm.formCopiedModelsGetVal('monitoringCompany.model.monitoringCompanyConfig');

    if (this.leadSourcesAllSelected) {
      var leadSources = yield this.leadSources;
      var blackList = leadSources.map(item => {
        return item.id;
      });
      Ember.set(config, 'leadSourceBlacklist', blackList);
    } else {
      Ember.set(config, 'leadSourceBlacklist', []);
    }

    this.leadSourceList = yield this.leadSourceListTask.perform();
    yield this.leadSourceListSelectAllIndetermanantTask.perform();
  }), _dec40 = Ember._action, _dec41 = Ember._action, _dec42 = Ember._action, (_class = (_temp = class AdtIntegration extends _integrationsBase.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "centralStationService", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "equipmentProductCodeDeleteId", _descriptor5, this);

      _initializerDefineProperty(this, "equipment", _descriptor6, this);

      _initializerDefineProperty(this, "productionEnvironment", _descriptor7, this);

      _initializerDefineProperty(this, "equipmentProductCode", _descriptor8, this);

      _initializerDefineProperty(this, "availableContractTemplates", _descriptor9, this);

      _initializerDefineProperty(this, "equipmentPanelsAllSelected", _descriptor10, this);

      _initializerDefineProperty(this, "equipmentPanelBlacklistSelectAllIndetermanant", _descriptor11, this);

      _initializerDefineProperty(this, "leadSourceListSelectAllIndetermanant", _descriptor12, this);

      _initializerDefineProperty(this, "leadSourcesAllSelected", _descriptor13, this);

      _initializerDefineProperty(this, "equipmentPanelList", _descriptor14, this);

      _initializerDefineProperty(this, "leadSourceList", _descriptor15, this);

      _initializerDefineProperty(this, "radioTypes", _descriptor16, this);

      _initializerDefineProperty(this, "leadSources", _descriptor17, this);

      _initializerDefineProperty(this, "equipmentPanels", _descriptor18, this);

      _initializerDefineProperty(this, "productCodes", _descriptor19, this);

      _initializerDefineProperty(this, "equipmentPanelApprovedList", _descriptor20, this);

      _initializerDefineProperty(this, "leadSourceApprovedList", _descriptor21, this);

      _initializerDefineProperty(this, "filteredEquipment", _descriptor22, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor23, this);

      _initializerDefineProperty(this, "radioTypesTask", _descriptor24, this);

      _initializerDefineProperty(this, "leadSourcesTask", _descriptor25, this);

      _initializerDefineProperty(this, "equipmentPanelsTask", _descriptor26, this);

      _initializerDefineProperty(this, "productCodesTask", _descriptor27, this);

      _initializerDefineProperty(this, "equipmentProductCodesTask", _descriptor28, this);

      _initializerDefineProperty(this, "equipmentPanelApprovedListTask", _descriptor29, this);

      _initializerDefineProperty(this, "equipmentPanelListTask", _descriptor30, this);

      _initializerDefineProperty(this, "leadSourceApprovedListTask", _descriptor31, this);

      _initializerDefineProperty(this, "leadSourceListTask", _descriptor32, this);

      _initializerDefineProperty(this, "saveMonitoringCompanyConfig", _descriptor33, this);

      _initializerDefineProperty(this, "updateProductCode", _descriptor34, this);

      _initializerDefineProperty(this, "deleteEquipmentProductCode", _descriptor35, this);

      _initializerDefineProperty(this, "equipmentPanelBlacklistSelectAllIndetermanantTask", _descriptor36, this);

      _initializerDefineProperty(this, "leadSourceListSelectAllIndetermanantTask", _descriptor37, this);

      _initializerDefineProperty(this, "bulkAddOrRemovePanelBlacklistSelectAllTask", _descriptor38, this);

      _initializerDefineProperty(this, "bulkAddOrRemoveLeadSourceBlacklistSelectAllTask", _descriptor39, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.MonitoringCompanyConnection = new _monitoringCompanyConnection.default(...arguments);
      this.initDataTask.perform();
      Ember.run.later(() => {
        this.buttons(this.args.multiStepForm);
      });
    }

    get computedNonWebPassword() {
      var multiStepForm = this.args.multiStepForm;

      if (multiStepForm.formCopiedModelsGetVal('monitoringCompany.model.monitoringCompanyConfig.responseMeta.attribute_has_saved_value.dealer_web_non_system_user_password')) {
        return '********';
      } else {
        return null;
      }
    }

    get computedNonWebUserName() {
      return this.args.multiStepForm.formOriginalModelsGetVal('monitoringCompany.model.monitoringCompanyConfig.dealerWebNonSystemUserUsername');
    }

    get isEnabled() {
      var multiStepForm = this.args.multiStepForm;
      return multiStepForm.formOriginalModelsGetVal('monitoringCompany.model.monitoringCompanyConfig.enabled');
    }

    get preCheckOrderStyles() {
      return [{
        name: this.intl.t('pages.customers.system.order.automatic'),
        id: 'automatic'
      }, {
        name: this.intl.t('pages.customers.system.order.manual'),
        id: 'manual'
      }, {
        name: this.intl.t('pages.customers.system.order.with_existing_check'),
        id: 'automatic-pre-existing-check'
      }];
    }

    updateFilteredList(equipmentProductCodes) {
      var equipmentProductCodeAssociationEquipmentItemIds = equipmentProductCodes.map(association => {
        return association.equipment ? association.equipment.get('id') : null;
      });
      this.filteredEquipment = this.equipment.filter(item => {
        return !equipmentProductCodeAssociationEquipmentItemIds.includes(item.get('id'));
      });
    }

    transformAvailableContractTemplates(availableContractTemplates) {
      var templates = {};
      availableContractTemplates.forEach(template => {
        if (template.identifier && template.name) {
          templates[template.identifier] = template.name;
        }
      });
      return templates;
    }

    transformProductCodes(equipmentProductCodes) {
      var codes = {};
      equipmentProductCodes.forEach(equipmentProductCode => {
        if (equipmentProductCode.equipment && equipmentProductCode.productCode) {
          codes[equipmentProductCode.equipment.id] = equipmentProductCode.productCode.id || equipmentProductCode.productCode;
        }
      });
      return codes;
    }

    transformBlacklist(blacklist) {
      var items = [];
      blacklist.forEach(item => {
        if (item.blackListed) {
          items.push(item.id);
        }
      });
      return items;
    }

    deleteAvailableContractTemplate(index) {
      var availableContractTemplates = this.availableContractTemplates;
      availableContractTemplates.removeAt(index);
      Ember.set(this, 'availableContractTemplateDeleteId', false);
    }

    updateContract(index, row) {
      var availableContractTemplates = this.availableContractTemplates;
      availableContractTemplates.replace(index, 1, [row]);

      if (availableContractTemplates.length - 1 === index && !Ember.isEmpty(row.name)) {
        availableContractTemplates.push({
          identifier: null,
          name: null
        });
      }
    }

    notifyPropertyChange(property) {
      Ember.notifyPropertyChange(this, property);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "equipmentProductCodeDeleteId", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "equipment", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "productionEnvironment", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "equipmentProductCode", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "availableContractTemplates", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "equipmentPanelsAllSelected", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "equipmentPanelBlacklistSelectAllIndetermanant", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "leadSourceListSelectAllIndetermanant", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "leadSourcesAllSelected", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "equipmentPanelList", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "leadSourceList", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "radioTypes", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "leadSources", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "equipmentPanels", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "productCodes", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "equipmentPanelApprovedList", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "leadSourceApprovedList", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "filteredEquipment", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "radioTypesTask", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "leadSourcesTask", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "equipmentPanelsTask", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "productCodesTask", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "equipmentProductCodesTask", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "equipmentPanelApprovedListTask", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "equipmentPanelListTask", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "leadSourceApprovedListTask", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "leadSourceListTask", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "saveMonitoringCompanyConfig", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "updateProductCode", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "deleteEquipmentProductCode", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor36 = _applyDecoratedDescriptor(_class.prototype, "equipmentPanelBlacklistSelectAllIndetermanantTask", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor37 = _applyDecoratedDescriptor(_class.prototype, "leadSourceListSelectAllIndetermanantTask", [_dec37], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor38 = _applyDecoratedDescriptor(_class.prototype, "bulkAddOrRemovePanelBlacklistSelectAllTask", [_dec38], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor39 = _applyDecoratedDescriptor(_class.prototype, "bulkAddOrRemoveLeadSourceBlacklistSelectAllTask", [_dec39], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "deleteAvailableContractTemplate", [_dec40], Object.getOwnPropertyDescriptor(_class.prototype, "deleteAvailableContractTemplate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateContract", [_dec41], Object.getOwnPropertyDescriptor(_class.prototype, "updateContract"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "notifyPropertyChange", [_dec42], Object.getOwnPropertyDescriptor(_class.prototype, "notifyPropertyChange"), _class.prototype)), _class));
  _exports.default = AdtIntegration;
});