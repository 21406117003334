define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/equipment/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/customer-base", "ember-concurrency"], function (_exports, _customerBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var Equipment = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true);
    this.setupButtons();
    return Ember.RSVP.hash({
      customer: this.newCustomer,
      customerEquipment: this.newCustomer.get('customerEquipment'),
      customerSystemInformation: this.grants.getIndividualGrant('customer_system_information.view') ? this.newCustomer.get('customerSystemInformation') : null,
      equipment: this.store.query('equipment', {
        include: 'equipment_type',
        limit: 0,
        sort: 'name'
      }),
      equipmentStatus: this.store.findAll('equipmentStatus')
    });
  }), (_class = (_temp = class Equipment extends _customerBase.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "grants", _descriptor3, this);

      _initializerDefineProperty(this, "modelDataTask", _descriptor4, this);
    }

    get modelData() {
      return this.modelDataTask.perform();
    }

    setupButtons() {
      this.multiStepForm.registerStepData('step4', 'buttons', [{
        text: this.intl.t('common.actions.back'),
        classNamesString: 'st-btn st-btn-secondary',
        click: () => {
          this.multiStepForm.currentStepNameKeySetVal('step3');
          this.multiStepForm.progressBarConfig.activeIndex = 2;
        }
      }, {
        text: this.intl.t('common.actions.saveAndContinue'),
        classNamesString: 'st-btn st-btn-primary',
        click: () => {
          if (this.grants.getIndividualGrant('customer_contacts.view')) {
            this.advanceForm(this.multiStepForm, 'step5', 4);
          } else {
            this.advanceForm(this.multiStepForm, 'step6', 5);
          }
        },
        positionOptions: 'up left',
        additionalClickActions: [{
          label: this.intl.t('common.actions.deleteCustomer'),
          action: () => {
            this.openConfirmDelete('step4');
          },
          classNamesString: 'dropdown-delete-customer'
        }, {
          label: this.intl.t('common.actions.saveAndClose'),
          action: () => {
            this.closeForm(this.multiStepForm, this.newCustomer);
          },
          classNamesString: 'dropdown-save-close'
        }]
      }]);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "modelDataTask", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Equipment;
});