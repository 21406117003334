define("securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/adc/create-account/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/adc/adc-base", "ember-concurrency", "securitytrax-ember-ui/mixins/forms/adc-receiver-numbers"], function (_exports, _adcBase, _emberConcurrency, _adcReceiverNumbers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adcBase.default.extend(_adcReceiverNumbers.default, {
    store: Ember.inject.service(),
    customerOrCommitment: 'customer',
    adcEvents: Ember.inject.service('events/adc-events'),
    receiverNumber: undefined,
    customerNotifications: undefined,
    phoneLinePresent: false,

    get accountTypeChoices() {
      return [Ember.Object.create({
        packageName: 'Customer',
        packageId: 'customer'
      }), Ember.Object.create({
        packageName: 'Commitment',
        packageId: 'commitment'
      })];
    },

    builderProgramOptions: Ember.computed(function () {
      return this.builderProgramOptionsTask.perform();
    }),
    builderProgramOptionsTask: (0, _emberConcurrency.task)(function* () {
      var programs = [];
      var ispConfig = yield this.ispConfig;

      try {
        if (ispConfig && ispConfig.get('serviceRequestBuilderProgram')) {
          var builderPrograms = yield this.ispService.get('invokeIspActionTask').perform({
            action: 'alarm_dot_com__getBuilderPrograms',
            model: yield this.get('customer.customerOrderInformation.interactiveServicesProvider'),
            attributes: {}
          });

          if (builderPrograms && builderPrograms.builder_programs) {
            builderPrograms.builder_programs.forEach(program => {
              programs.push({
                name: program.program_name,
                value: program.program_id
              });
            });
          }
        }
      } catch (err) {
        this.stNotificationManager.addWarningKey('pages.customers.system.interactiveServicesProvider.error_retrieving_builder_programs', 'modal');
      }

      return programs;
    }),

    didInsertElement() {
      this._super(...arguments);

      this.actions.updateMaskedDropdownAttribute.call(this, 'phone_line_present', false);
      this.multiStepForm.registerStepData('customerInformation', 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.createAccount.perform(this.multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary adc-create-account-button'
      }]);
    },

    ispConfig: Ember.computed(function () {
      return this.ispConfigTask.perform();
    }),
    ispConfigTask: (0, _emberConcurrency.task)(function* () {
      return this.grants.getIndividualGrant('interactive_services_provider_configs.manage') ? yield this.get('customer.customerOrderInformation.interactiveServicesProvider.interactiveServicesProviderConfig') : null;
    }),
    installerCodeShowing: Ember.computed('panelType', function () {
      var panelsWithInstallerCodes = ['SEMVista', 'SEMPowerSeries', 'SEMLynx'];
      var includedPanel = panelsWithInstallerCodes.includes(this.panelType);

      if (!includedPanel) {
        this.set('installerCode', null);
        this.actions.updateAttribute.call(this, 'installer_code', false);
      }

      return includedPanel;
    }),
    receiverNumberHidden: Ember.computed('customerAccountType', 'centralStationForwarding', function () {
      if (this.customerAccountType === 'EnergyAutomation' || this.centralStationForwarding === 'Never') {
        this.set('receiverNumber', this.systemInfo.receiverNum);
        return true;
      }

      return false;
    }),
    phoneLinePresentHidden: Ember.computed('panelType', 'centralStationForwarding', function () {
      var panelsToHidePhoneLine = ['Iotega', 'IQPanel2', 'IQPanel', 'SEMPowerSeries'];

      if (panelsToHidePhoneLine.includes(this.panelType) || this.centralStationForwarding === 'Never') {
        this.set('phoneLinePresent', false);
        this.actions.updateMaskedDropdownAttribute.call(this, 'phone_line_present', false);
        return true;
      }

      return false;
    }),
    filteredCentralStationForwardingOptions: Ember.computed('panelType', 'accountType', function () {
      return this.filterCentralStationForwardingOptions.perform();
    }),
    filterCentralStationForwardingOptions: (0, _emberConcurrency.task)(function* () {
      var centralStationForwardingOptions = yield this.centralStationForwardingOptions;

      if (this.phoneLinePresentHidden) {
        this.set('centralStationForwarding', this.customerAccountType === 'EnergyAutomation' ? 'Never' : this.centralStationForwarding);
        return centralStationForwardingOptions.filter(option => {
          return option.value !== 'OnlyIfPhoneFails';
        });
      } else {
        return centralStationForwardingOptions;
      }
    }),
    accountTypeOptions: Ember.computed(function () {
      return this.accountTypeOptionsTask.perform();
    }),
    accountTypeOptionsTask: (0, _emberConcurrency.task)(function* () {
      return yield this.ispService.get('getIspListTask').perform({
        ispId: this.get('customer.customerOrderInformation.interactiveServicesProvider.id'),
        ispType: 'adc',
        list: 'get_account_types'
      });
    }),
    installTypeOptions: Ember.computed(function () {
      return this.installTypeOptionsTask.perform();
    }),
    installTypeOptionsTask: (0, _emberConcurrency.task)(function* () {
      return yield this.ispService.get('getIspListTask').perform({
        ispId: this.get('customer.customerOrderInformation.interactiveServicesProvider.id'),
        ispType: 'adc',
        list: 'get_install_types'
      });
    }),
    networkOptions: Ember.computed(function () {
      return this.networkOptionsTask.perform();
    }),
    networkOptionsTask: (0, _emberConcurrency.task)(function* () {
      return yield this.ispService.get('getIspListTask').perform({
        ispId: this.get('customer.customerOrderInformation.interactiveServicesProvider.id'),
        ispType: 'adc',
        list: 'get_expected_networks'
      });
    }),
    communicationPathwayOptions: Ember.computed(function () {
      return this.communicationPathwayOptionsTask.perform();
    }),
    communicationPathwayOptionsTask: (0, _emberConcurrency.task)(function* () {
      var pathwayOptions = yield this.ispService.get('getIspListTask').perform({
        ispId: this.get('customer.customerOrderInformation.interactiveServicesProvider.id'),
        ispType: 'adc',
        list: 'get_communication_pathways'
      });
      return pathwayOptions;
    }),
    createAccount: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var formattedObjects = Ember.A();
      var attributes = this.attributes;
      this.customerOrCommitment;
      attributes['package_id'] = this.package;
      var selectedPackage = (yield this.adcPackages).find(adcPackage => {
        return adcPackage.id === this.package;
      });
      var noValidate = [];

      if (this.customerOrCommitment === 'commitment') {
        noValidate.push('adc-modemSerialNumber');
      }

      if (this.customerAccountType === 'Video') {
        noValidate.push('adc-receiverNumber', 'adc-modemSerialNumber', 'adc-panelType', 'adc-centralStationForwarding');
      }

      if (this.receiverNumberHidden) {
        noValidate.push('adc-receiverNumber');
      }

      if (!this.installerCodeShowing) {
        noValidate.push('adc-installerCode');
      }

      formattedObjects.pushObject(Ember.Object.create({
        model: {
          accountType: this.customerAccountType,
          modemSerialNumber: attributes['modem_serial_number'],
          panelType: attributes['panel_type'],
          centralStationForwarding: attributes['central_station_forwarding_option'],
          packageId: attributes['package_id'],
          propertyType: attributes['property_type'],
          receiverNumber: this.receiverNumber,
          installerCode: attributes['installer_code'],
          installType: attributes['install_type'],
          network: attributes['network'],
          communicationPathway: attributes['communication_pathway']
        },
        extraValidationData: {
          noValidate: noValidate,
          notificationNamespace: 'modal'
        },
        filePath: 'interactive-services-provider/adc-create'
      }));
      attributes['add_on_features'] = [];

      if (this.addOns) {
        this.addOns.forEach(addon => {
          if (!selectedPackage.included_features || selectedPackage.included_features.findIndex(includedAddon => includedAddon.feature === addon) === -1) {
            attributes['add_on_features'].push({
              [addon]: true
            });
          }
        });
      }

      yield multiStepForm.validateList.perform(formattedObjects);
      this.systemInfo.receiverNum = this.receiverNumber;

      try {
        yield this.systemInfo.save();
        var response = yield this.ispService.get('invokeIspActionTask').perform({
          attributes,
          action: 'alarm_dot_com__create' + this.customerOrCommitment.charAt(0).toUpperCase() + this.customerOrCommitment.slice(1),
          model: this.customer
        });
        this.set('serialNumber', this.modem);

        if (response) {
          this.set('shellResponse', this.customerOrCommitment === 'customer' ? response.customer_created : response.commitment_created);
        }
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
        throw err;
      }

      buttonPromise.then(() => {
        this.set('formMode', 'edit');
        this.systemInfo.reload();
      });
    }),
    updatePackageAndAddonsFromTemplateTask: (0, _emberConcurrency.task)(function* (template) {
      if (template) {
        var packages = yield this.adcPackages;
        this.set('package', template.base_package.package_id);
        this.actions.updateDropdownAttribute.call(this, 'package_id', packages.findBy('id', template.base_package.package_id));
        this.adcEvents.trigger('update-selected-video-package', template.base_package.package_id, template.template_id);
        this.adcEvents.trigger('update-addons', 'packageId', template.base_package.package_id);
      } else {
        this.set('package', null);
        this.actions.updateDropdownAttribute.call(this, 'package_id', null);
        this.adcEvents.trigger('update-selected-video-package', null);
        this.adcEvents.trigger('update-addons', 'packageId', null);
      }
    }),
    actions: {
      updateFields() {
        this.set('attributes', {
          'branch_id': this.attributes['branch_id'],
          'property_type': this.attributes['property_type'],
          'install_type': this.attributes['install_type'],
          'template': this.attributes['template'],
          'ignore_low_coverage_errors': this.attributes['ignore_low_coverage_errors'],
          'builder_program_id': this.attributes['builder_program_id'],
          'central_station_forwarding_option': this.customerAccountType === 'Video' ? 'Never' : null,
          'panel_type': this.customerAccountType === 'Video' ? 'NoPanel' : null,
          'phone_line_present': this.customerAccountType === 'Video' ? 'false' : null,
          'customer_notifications': this.customerAccountType === 'Security' ? true : false
        });

        if (this.customerAccountType === 'EnergyAutomation') {
          this.set('centralStationForwarding', 'Never');
          this.actions.updateDropdownAttribute.call(this, 'central_station_forwarding_option', {
            value: 'Never'
          });
        } else {
          this.set('centralStationForwarding', null);
        }

        this.set('phoneLinePresent', false);
        this.set('customerNotifications', this.attributes['customer_notifications']);
        this.actions.updateMaskedDropdownAttribute.call(this, 'phone_line_present', false);
        this.set('package', null);
        this.set('network', 'NotSet');
        this.set('modem', null);
        this.set('panelType', null);
        this.set('receiverNumber', this.systemInfo.receiverNum);
      },

      updateVideoPackage() {
        this.adcEvents.trigger('update-selected-video-package', this.package);
      },

      updateAddons(property, id) {
        this.adcEvents.trigger('update-addons', property, id);
      }

    }
  });

  _exports.default = _default;
});