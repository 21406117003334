define("securitytrax-ember-ui/components/multi-step-form/forms/administration/account-management/invoices/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j5o/QfQK",
    "block": "{\"symbols\":[\"data\"],\"statements\":[[6,[37,8],null,[[\"useSpinner\",\"size\",\"promises\"],[true,\"x-small\",[30,[36,7],null,[[\"paymentList\"],[[35,6]]]]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"grid-x grid-padding-x grid-padding-y\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cell\"],[12],[2,\"\\n            \"],[1,[30,[36,0],[\"pages.administration.account_management.invoices.pay_amount_due\"],null]],[2,\"\\n            \"],[1,[30,[36,2],[[35,1,[\"totalDue\"]]],[[\"style\",\"currency\",\"fallback\"],[\"currency\",[30,[36,0],[\"common.currency\"],null],[30,[36,0],[\"common.zero_currency\"],null]]]]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"grid-x grid-padding-x\"],[12],[2,\"\\n        \"],[8,\"html/package-select/package-layouts/default-layout\",[[24,0,\"cell small-12\"]],[[\"@isLargeLabel\",\"@valueProperty\",\"@labelProperty\",\"@packageList\",\"@selectedId\",\"@columnClasses\",\"@updateSelected\",\"@canDeselect\"],[true,\"description\",\"label\",[32,1,[\"resolvedPromises\",\"paymentList\"]],[34,3],\"cell shrink\",[30,[36,5],[[32,0],[30,[36,4],[[35,3]],null]],null],false]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"currentInvoice\",\"format-number\",\"selectedId\",\"mut\",\"action\",\"paymentList\",\"hash\",\"html/st-await\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/account-management/invoices/template.hbs"
    }
  });

  _exports.default = _default;
});