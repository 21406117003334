define("securitytrax-ember-ui/components/multi-step-form/switchable-inputs/add-remove-multiple-select/component", ["exports", "securitytrax-ember-ui/mixins/switchable-input", "ember-concurrency"], function (_exports, _switchableInput, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableInput.default, {
    computedValue: Ember.computed('value.[]', function () {
      return this.value;
    }),
    defaultValuesObserver: Ember.observer('options', function () {
      this.updateDefaultValues.perform();
    }),
    updateDefaultValues: (0, _emberConcurrency.task)(function* () {
      var options = yield this.options;
      var updatedValue = [];
      options.rowData.forEach(option => {
        if (option.inAddArray) {
          updatedValue.push(option);
        }
      });
      this.set('value', updatedValue);
    }).on('init'),
    actions: {
      updateSelectedArray() {
        this.notifyPropertyChange('computedValue');
      },

      removeToItem(item) {
        item.set('inAddArray', false);
        var addArray = this.value;
        var resetAddArray = addArray.filter(arrayItem => {
          return arrayItem.columnData[0] != item.columnData[0];
        });
        this.set('value', resetAddArray);
        this.notifyPropertyChange('computedValue');
      }

    }
  });

  _exports.default = _default;
});