define("securitytrax-ember-ui/components/multi-step-form/forms/system/stages/manage-chat/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/system/stages/stages-base", "ember-concurrency"], function (_exports, _stagesBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var ManageChat = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = (0, _emberConcurrency.task)(function* () {
    var hashedData = yield (0, _emberConcurrency.hash)({
      chatEnabledDeviceTypes: this.chatEnabledDeviceTypesTask.perform(),
      chatEnabled: this.chatEnabledTask.perform()
    });
    Ember.setProperties(this, hashedData);
  }), _dec8 = (0, _emberConcurrency.task)(function* () {
    try {
      var deviceTypes = yield this.centralStationService.getData({
        model: this.args.customer,
        method: 'stages__getChatEnabledDeviceTypes'
      });
      return deviceTypes.chat_enabled_device_types;
    } catch (err) {
      var message = this.intl.t('pages.customers.system.order.stages_retrieve_error', {
        data: this.intl.t('pages.customers.system.order.chat_enabled_device_types')
      });
      this.stNotificationManager.addWarning(message + err, 'modal');
    }
  }), _dec9 = (0, _emberConcurrency.task)(function* () {
    var chatEnabled = false;

    try {
      chatEnabled = yield this.centralStationService.getData({
        model: this.args.customer,
        method: 'stages__getChatEnabled'
      });
      this.manageChatLabel = chatEnabled.chat_enabled ? this.intl.t('pages.customers.system.order.disable') : this.intl.t('pages.customers.system.order.enable');
      this.manageChatSectionLabel = chatEnabled.chat_enabled ? this.intl.t('pages.customers.system.order.disable_chat') : this.intl.t('pages.customers.system.order.enable_chat');
      chatEnabled = chatEnabled.chat_enabled;
    } catch (err) {
      this.manageChatLabel = this.intl.t('pages.customers.system.order.enable');
      this.manageChatSectionLabel = this.intl.t('pages.customers.system.order.enable_chat');
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
    }

    return chatEnabled;
  }), _dec10 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    if (this.updateChat) {
      var messages = [];

      try {
        var chatEnabled = yield this.chatEnabled;
        var response = yield this.centralStationService.postData({
          model: this.args.customer,
          method: 'stages__modifyChat',
          payload: {
            'action': chatEnabled ? 'disable' : 'enable',
            'device_type': this.siteInformation.device_type
          }
        });
        messages = messages.concat(response.responsePayload.data.messages);
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
        throw err;
      }

      buttonPromise.then(() => {
        multiStepForm.currentStepNameKeySetVal('general');
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');

        if (messages.length > 0) {
          this.stNotificationManager.addWarningMessages(messages, {
            notificationNamespace: 'modal'
          });
        }
      });
    } else {
      this.stNotificationManager.addError(this.intl.t('pages.customers.system.order.enable_chat_error'), 'modal');
    }
  }), _dec11 = Ember._action, (_class = (_temp = class ManageChat extends _stagesBase.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "centralStationService", _descriptor, this);

      _initializerDefineProperty(this, "updateChat", _descriptor2, this);

      _initializerDefineProperty(this, "manageChatLabel", _descriptor3, this);

      _initializerDefineProperty(this, "manageChatSectionLabel", _descriptor4, this);

      _initializerDefineProperty(this, "chatEnabledDeviceTypes", _descriptor5, this);

      _initializerDefineProperty(this, "chatEnabled", _descriptor6, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor7, this);

      _initializerDefineProperty(this, "chatEnabledDeviceTypesTask", _descriptor8, this);

      _initializerDefineProperty(this, "chatEnabledTask", _descriptor9, this);

      _initializerDefineProperty(this, "manageChatTask", _descriptor10, this);

      this.initDataTask.perform();
    }

    buttons() {
      this.args.multiStepForm.registerStepData('manageChat', 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.manageChatTask.perform(this.args.multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary adc-create-account-button'
      }]);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "updateChat", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "manageChatLabel", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "manageChatSectionLabel", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "chatEnabledDeviceTypes", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "chatEnabled", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "chatEnabledDeviceTypesTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "chatEnabledTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "manageChatTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "buttons", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "buttons"), _class.prototype)), _class));
  _exports.default = ManageChat;
});