define("securitytrax-ember-ui/services/st-notification-manager", ["exports", "securitytrax-ember-ui/config/environment", "securitytrax-ember-ui/services/st-adc-notification-manager", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _environment, _stAdcNotificationManager, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Service that adds support to translations over the
   * ADC notification manager.
   *
   * @class StNotificationManager
   * @extends Ember.Service
   * @memberof services
   */
  var _default = Ember.Service.extend(
  /** @lends services.StNotificationManager.prototype */
  {
    stAdcNotificationManager: Ember.inject.service(),
    intl: Ember.inject.service(),

    init() {
      this._super(...arguments); // Hack on notification manager to avoid blocking tests until the notification closes.
      // Setting to false causes the notification to just remain open


      if (_environment.default.environment === 'test') {
        _stAdcNotificationManager.SUCCESS.autoCloseDuration = false;
        _stAdcNotificationManager.INFO.autoCloseDuration = false;
        _stAdcNotificationManager.WARNING.autoCloseDuration = false;
        _stAdcNotificationManager.ERROR.autoCloseDuration = false;
      }
    },

    addSuccessSave(namespace = 'global') {
      this.addSuccessKey('common.notifications.save_successful', namespace);
    },

    addSuccessKey(key, namespace = 'global') {
      this.addSuccess(this.intl.t(key), namespace);
    },

    addErrorKey(key, namespace = 'global') {
      this.addError(this.intl.t(key), namespace);
    },

    addWarningKey(key, namespace = 'global') {
      this.addWarning(this.intl.t(key), namespace);
    },

    addInfoKey(key, namespace = 'global') {
      this.addInfo(this.intl.t(key), namespace);
    },

    // delegate methods
    addSuccess(text, namespace = 'global') {
      this.stAdcNotificationManager.addSuccess(text, namespace);
    },

    addError(text, namespace = 'global') {
      this.stAdcNotificationManager.addError(text, namespace);
    },

    addWarning(text, namespace = 'global') {
      this.stAdcNotificationManager.addWarning(text, namespace);
    },

    addInfo(text, namespace = 'global') {
      this.stAdcNotificationManager.addInfo(text, namespace);
    },

    addErrorMessages(error, options = {}) {
      if (error && error.errors && error.errors[0].detail && error.errors[0].detail.indexOf('"records":') >= 0) {
        return JSON.parse(error.errors[0].detail);
      } else {
        var notificationNamespace = options && (0, _objHelpers.isObject)(options) && 'notificationNamespace' in options ? options.notificationNamespace : options && !(0, _objHelpers.isObject)(options) ? options : 'global';
        var fallbackMessage = options && (0, _objHelpers.isObject)(options) && 'fallbackMessage' in options ? options.fallbackMessage : this.intl.t('common.notifications.generic_catchall_failure');
        var messagePrefix = options && (0, _objHelpers.isObject)(options) && 'messagePrefix' in options ? options.messagePrefix : null;

        if (error) {
          if (error.hasOwnProperty('errors') && error.errors[0].status == '500' && error.errors[0].detail === '[object Object]') {
            this.addError(this.intl.t('common.notifications.generic_catchall_failure_500'), notificationNamespace);
          } else if (error.hasOwnProperty('errors')) {
            var errorNotificationMessage = this.fullMessage(error.errors, messagePrefix);
            this.addError(errorNotificationMessage, notificationNamespace);
          } else if (!(0, _objHelpers.isObject)(error)) {
            this.addError(error, notificationNamespace);
          } else if ((0, _objHelpers.isObject)(error) && error.hasOwnProperty('message')) {
            this.addError(error.message, notificationNamespace);
          } else if (options && (0, _objHelpers.isObject)(options) && 'showFallbackMessage' in options && options.showFallbackMessage === false) {//do nothing if there aren't actual error messages and they dont want to see the generic failure
          } else {
            this.addError(fallbackMessage, notificationNamespace);
          }
        } else if (options && (0, _objHelpers.isObject)(options) && 'showFallbackMessage' in options && options.showFallbackMessage === false) {//do nothing if they dont want to see fallback message
        } else {
          this.addError(fallbackMessage, notificationNamespace);
        }
      }
    },

    addInfoMessages(messages, notificationNamespace = 'global') {
      var notificationMessage = this.fullMessage(messages);
      this.addInfo(notificationMessage, notificationNamespace);
    },

    addWarningMessages(messages, options = {}) {
      var notificationNamespace = options && (0, _objHelpers.isObject)(options) && 'notificationNamespace' in options ? options.notificationNamespace : options && !(0, _objHelpers.isObject)(options) ? options : 'global';
      var fallbackMessage = options && (0, _objHelpers.isObject)(options) && 'fallbackMessage' in options ? options.fallbackMessage : this.intl.t('common.notifications.generic_catchall_warning');
      var messagePrefix = options && (0, _objHelpers.isObject)(options) && 'messagePrefix' in options ? options.messagePrefix : null;

      if (messages) {
        if ((0, _objHelpers.isObject)(messages) && messages.hasOwnProperty('errors')) {
          var errorNotificationMessage = this.fullMessage(messages.errors, messagePrefix);
          this.addWarning(errorNotificationMessage, notificationNamespace);
        } else if (!(0, _objHelpers.isObject)(messages)) {
          this.addWarning(messages, notificationNamespace);
        } else if ((0, _objHelpers.isObject)(messages) && messages.hasOwnProperty('message')) {
          this.addWarning(messages.message, notificationNamespace);
        } else if ((0, _objHelpers.isObject)(messages) && messages[0]) {
          var _errorNotificationMessage = this.fullMessage(messages);

          this.addWarning(_errorNotificationMessage, notificationNamespace);
        } else if (options && (0, _objHelpers.isObject)(options) && 'showFallbackMessage' in options && options.showFallbackMessage === false) {//do nothing if there aren't actual warning messages and they dont want to see the generic failure
        } else {
          this.addWarning(fallbackMessage, notificationNamespace);
        }
      } else if (options && (0, _objHelpers.isObject)(options) && 'showFallbackMessage' in options && options.showFallbackMessage === false) {//do nothing if they dont want to see fallback message
      } else {
        this.addWarning(fallbackMessage, notificationNamespace);
      }
    },

    fullMessage(messages, messagePrefix = null) {
      if (messages.length === 1) {
        var errorNotificationMessage = messagePrefix ? "<div><span>".concat(messagePrefix, "</span>") : '';
        messages.forEach(currError => {
          if (currError.detail == '[object Object]') {
            currError.detail = this.intl.t('common.notifications.generic_catchall_failure_500');
          }

          errorNotificationMessage += (0, _objHelpers.isObject)(currError) && 'detail' in currError ? '<span>' + currError.detail + '</span>' : (0, _objHelpers.isObject)(currError) ? '<span>' + currError.message + '</span>' : '<span>' + currError + '</span>';
        });
        return errorNotificationMessage += messagePrefix ? '</div>' : '';
      } else {
        var _errorNotificationMessage2 = messagePrefix ? "<div><div>".concat(messagePrefix, "</div> <ul>") : '<ul>';

        messages.forEach(currError => {
          if (currError.detail == '[object Object]') {
            currError.detail = this.intl.t('common.notifications.generic_catchall_failure_500');
          }

          _errorNotificationMessage2 += (0, _objHelpers.isObject)(currError) && 'detail' in currError ? '<li>' + currError.detail + '</li>' : (0, _objHelpers.isObject)(currError) ? '<li>' + currError.message + '</li>' : '<li>' + currError + '</li>';
        });
        return _errorNotificationMessage2 += messagePrefix ? '</ul></div>' : '</ul>';
      }
    },

    actions: {
      addSuccessKey(key, namespace = 'global') {
        this.addSuccessKey(key, namespace);
      },

      addErrorKey(key, namespace = 'global') {
        this.addErrorKey(key, namespace);
      },

      addWarningKey(key, namespace = 'global') {
        this.addWarningKey(key, namespace);
      },

      addInfoKey(key, namespace = 'global') {
        this.addInfoKey(key, namespace);
      },

      //delegate actions
      addSuccess(text, namespace = 'global') {
        this.addSuccess(text, namespace);
      },

      addError(text, namespace = 'global') {
        this.addError(text, namespace);
      },

      addWarning(text, namespace = 'global') {
        this.addWarning(text, namespace);
      },

      addInfo(text, namespace = 'global') {
        this.addInfo(text, namespace);
      }

    }
  });

  _exports.default = _default;
});