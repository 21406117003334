define("securitytrax-ember-ui/models/message", ["exports", "securitytrax-ember-ui/models/base_models/message"], function (_exports, _message) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _message.default.extend({
    title: Ember.computed('messageType', 'name', function () {
      var messageType = '';

      switch (this.messageType) {
        case 'email':
          messageType = 'Email';
          break;

        case 'sms':
          messageType = 'SMS';
          break;
      }

      return "".concat(messageType, " | ").concat(this.name || '');
    }),
    typeDisplay: Ember.computed('messageType', function () {
      var messageType = '';

      switch (this.messageType) {
        case 'email':
          messageType = 'Email';
          break;

        case 'sms':
          messageType = 'SMS';
          break;
      }

      return messageType;
    }),
    templateNameWithDeliveryType: Ember.computed('messageType', 'name', function () {
      var deliveryProtocol;

      if (this.deliveryProtocol === 'email') {
        deliveryProtocol = 'Email';
      } else if (this.deliveryProtocol === 'sms') {
        deliveryProtocol = 'SMS';
      }

      return "".concat(this.name || '', " (").concat(deliveryProtocol, ")");
    })
  });

  _exports.default = _default;
});