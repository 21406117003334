define("securitytrax-ember-ui/components/calendar-glimmer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z9gJ0VxS",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[6,[37,5],[[30,[36,4],[[35,3],\"days\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"calendar-glimmer/calendar-view\",[],[[\"@users\",\"@calendarEvents\",\"@calendarEventTypes\",\"@calendarCardAdditionalClass\",\"@allowDragAppointment\",\"@allowResizeAppointment\",\"@allowAddAppointment\",\"@allowEventTooltip\",\"@userType\",\"@isPrimaryCal\",\"@isCompanyCalendar\",\"@updateViewType\"],[[32,1,[\"users\"]],[32,1,[\"calendarEvents\"]],[32,1,[\"calendarEventTypes\"]],[30,[36,2],[[35,1,[\"isMobile\"]],\"st-card--calendar\"],null],true,true,false,true,\"tech_schedule\",true,true,[30,[36,0],[[32,0],\"updateViewType\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"calendar-glimmer/calendar-month-view\",[],[[\"@updateViewType\",\"@model\"],[[30,[36,0],[[32,0],\"updateViewType\"],null],[32,1]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"screen\",\"unless\",\"viewType\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/calendar-glimmer/template.hbs"
    }
  });

  _exports.default = _default;
});