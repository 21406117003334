define("securitytrax-ember-ui/mixins/grouped-equipment", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixed-in-functions/grouped-property-sections", "lodash"], function (_exports, _emberConcurrency, _groupedPropertySections, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionData: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.initMixinDataTask.perform();
    },

    initMixinDataTask: (0, _emberConcurrency.task)(function* () {
      var mixinEquipmentTypes = yield this.store.query('equipmentType', {
        sort: 'name'
      });
      this.set('mixinEquipmentTypes', mixinEquipmentTypes);
      mixinEquipmentTypes.forEach((type, index) => {
        type.set('sort', index++);
      });
    }),
    groupedItems: Ember.computed('items.[]', function () {
      return this.groupedItemsTask.perform(this.items);
    }),
    groupedItemsTask: (0, _emberConcurrency.task)(function* (items, collapsed = false, sort = 'equipment.name') {
      try {
        var resolvedItems = (yield items).sortBy(sort);

        if (resolvedItems) {
          var childTasks = [];

          if (resolvedItems) {
            resolvedItems.map(item => {
              childTasks.push(this.mapItemsTask.perform(item));
            });
          }

          var allLineItems = yield (0, _emberConcurrency.all)(childTasks);
          return allLineItems.uniqBy('type.id').sortBy('type.sort').map(({
            type
          }) => {
            return Ember.Object.create({
              name: type.get('name'),
              icon: type.get('icon'),
              color: "background-color: ".concat(type.get('color')),
              sections: (0, _groupedPropertySections.default)(),
              items: allLineItems.filterBy('type.id', type.id).mapBy('equipment'),
              collapsable: true,
              collapsed,
              collapseIconName: 'icn-chevron-down'
            });
          });
        } else {
          throw new Error('You must have items set for grouped equipment');
        }
      } catch (error) {
        (false && Ember.warn('groupedItemsTask ' + error, {
          id: 'mixins.grouped-equipment.groupedItemsTask'
        }));
      }
    }),
    groupedItemsByQuantity: Ember.computed('items.[]', function () {
      return this.groupedItemsByQuantityTask.perform(this.items);
    }),
    groupedItemsByQuantityTask: (0, _emberConcurrency.task)(function* (items, collapsed = false, sort = 'equipment.name') {
      try {
        var resolvedItems = (yield items).sortBy(sort);

        if (resolvedItems) {
          var childTasks = [];

          if (resolvedItems) {
            resolvedItems.map(item => {
              childTasks.push(this.mapItemsTask.perform(item));
            });
          }

          var allLineItems = yield (0, _emberConcurrency.all)(childTasks);
          var user = yield this.selectedUserId ? this.store.findRecord('user', this.selectedUserId) : this.sessionData.getUser();
          var action = this.selectedUserId ? 'get_admin_equipment_inventory' : 'get_equipment_inventory'; // TODO make recipient for on hand counts more dynamic and possible make doing on hand counts conditional

          var onHandCounts = user && (this.selectedUserId && this.grants.getIndividualGrant('equipment_ledgers.view_on_hand') || !this.selectedUserId) ? (yield user.invokeAction(action, {
            verb: 'GET'
          })).responsePayload.data : [];
          return allLineItems.uniqBy('type.id').sortBy('type.sort').map(({
            type
          }) => {
            var filterBy = allLineItems.filterBy('type.id', type.id);
            var mapBy = filterBy.mapBy('equipment');
            var groupedByEquipmentId = {};
            mapBy.forEach(curr => {
              if (!groupedByEquipmentId[curr.get('equipment.id')]) {
                groupedByEquipmentId[curr.get('equipment.id')] = {
                  count: 1,
                  item: curr,
                  onHand: typeof onHandCounts.findBy === 'function' ? onHandCounts.findBy('equipment_id', curr.get('equipment.id')) : 0
                };
              } else {
                groupedByEquipmentId[curr.get('equipment.id')]['count']++;
              }
            });

            var groupedByEquipmentIdValues = _lodash.default.values(groupedByEquipmentId);

            return Ember.Object.create({
              name: type.get('name'),
              icon: type.get('icon'),
              color: "background-color: ".concat(type.get('color')),
              sections: (0, _groupedPropertySections.default)(),
              items: groupedByEquipmentIdValues,
              collapsable: true,
              collapsed,
              collapseIconName: 'icn-chevron-down'
            });
          });
        } else {
          throw new Error('You must have items set for grouped equipment');
        }
      } catch (error) {
        (false && Ember.warn('groupedItemsTask ' + error, {
          id: 'mixins.grouped-equipment.groupedItemsTask'
        }));
      }
    }),
    mapItemsTask: (0, _emberConcurrency.task)(function* (item) {
      try {
        var yieldedItem = yield item;
        yield (0, _emberConcurrency.waitForProperty)(this.initMixinDataTask, 'isIdle', true);
        var equipment = yieldedItem.constructor.modelName === 'equipment' ? yieldedItem : yield yieldedItem.get('equipment');
        var equipmentType = equipment ? yield equipment.get('equipmentType') : null;
        var equipmentTypeOther = Ember.Object.create({
          id: 0,
          sort: this.mixinEquipmentTypes.length,
          name: this.intl.t('pages.customers.equipment.columns.other'),
          icon: 'icn-folder-open',
          color: '#22a1c1'
        });
        return {
          equipment: yieldedItem,
          type: equipmentType ? equipmentType : equipmentTypeOther
        };
      } catch (error) {
        (false && Ember.warn('mapItemsTask ' + error, {
          id: 'mixins.grouped-equipment.mapItemsTask'
        }));
      }
    })
  });

  _exports.default = _default;
});