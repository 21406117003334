define("securitytrax-ember-ui/models/user-admin-setting", ["exports", "securitytrax-ember-ui/models/base_models/user-admin-setting"], function (_exports, _userAdminSetting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _userAdminSetting.default.extend({
    activeIcon: Ember.computed('active', function () {
      return this.active ? 'icn-check-green' : 'icn-close-red';
    }),
    loginIcon: Ember.computed('login', function () {
      return this.login ? 'icn-check-green' : 'icn-close-red';
    })
  });

  _exports.default = _default;
});