define("securitytrax-ember-ui/models/user-rep-number", ["exports", "securitytrax-ember-ui/models/base_models/user-rep-number", "ember-concurrency"], function (_exports, _userRepNumber, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _userRepNumber.default.extend({
    usStates: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    locationOrState: Ember.computed('domain', 'identifier', function () {
      if (this.domain === 'location_id') {
        return this.findLocationTask.perform();
      } else {
        if (this.identifier === 'All') {
          return this.intl.t('pages.users.roles.all_states');
        }

        var state = this.usStates.all.find(state => state.value === this.identifier);
        return state ? state.name : null;
      }
    }),
    findLocationTask: (0, _emberConcurrency.task)(function* () {
      if (this.identifier !== 'All') {
        var location = yield this.store.findRecord('location', this.identifier);
        return location ? location.get('name') : null;
      }

      return this.intl.t('pages.users.roles.all_locations');
    })
  });

  _exports.default = _default;
});