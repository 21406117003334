define("securitytrax-ember-ui/models/funding-transaction-type", ["exports", "securitytrax-ember-ui/models/base_models/funding-transaction-type"], function (_exports, _fundingTransactionType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fundingTransactionType.default.extend({});

  _exports.default = _default;
});