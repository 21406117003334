define("securitytrax-ember-ui/mixins/route-pageview-logging", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import * as Sentry from '@sentry/browser';
  // import ENV from 'securitytrax-ember-ui/config/environment';
  var _default = Ember.Mixin.create({
    actions:
    /** @lends application.Route.prototype */
    {// didTransition() {
      //     if (ENV.environment != 'test' && ENV.environment != 'development') {
      //         Sentry.withScope(scope => {
      //             scope.setTag('source', 'routing');
      //             scope.setLevel('info');
      //             Sentry.captureEvent({
      //                 message: this.fullRouteName
      //             });
      //         });
      //     }
      // },
    }
  });

  _exports.default = _default;
});