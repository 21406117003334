define("securitytrax-ember-ui/models/customer-at-risk-reason", ["exports", "securitytrax-ember-ui/models/base_models/customer-at-risk-reason"], function (_exports, _customerAtRiskReason) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerAtRiskReason.default.extend({});

  _exports.default = _default;
});