define("securitytrax-ember-ui/models/payroll-function-method-parameter", ["exports", "securitytrax-ember-ui/models/base_models/payroll-function-method-parameter"], function (_exports, _payrollFunctionMethodParameter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _payrollFunctionMethodParameter.default.extend({});

  _exports.default = _default;
});