define("securitytrax-ember-ui/components/multi-step-form/forms/user-actions/add-to-user/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/switchable-form-step"], function (_exports, _emberConcurrency, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    orgTree: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    hierarchyUsers: Ember.computed(function () {
      return this.getUsersTask.perform();
    }),
    selectedUser: null,
    resultsLoaded: true,

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('modalButtons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.addUserTask.perform(buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary'
      }]);
    },

    getUsersTask: (0, _emberConcurrency.task)(function* () {
      var orgTree = this.orgTree;
      var userHierarchyTree = yield orgTree.get('userHierarchyTree');

      if (userHierarchyTree) {
        var userHierarchyUsers = yield this.store.findAll('userHierarchy', {
          include: 'user,directory_user'
        });
        var users = userHierarchyUsers.filter(user => {
          var userId = JSON.parse(user.get('id'));
          return userId && userId.user_hierarchy_tree_id == userHierarchyTree.get('id');
        });
        return users;
      }
    }),
    addUserTask: (0, _emberConcurrency.task)(function* (buttonPromise) {
      var orgTree = this.orgTree;
      var userHierarchyTree = yield orgTree.get('userHierarchyTree');

      try {
        this.set('saveComplete', false);
        var store = this.store;
        var selectedUser = yield this.selectedUser;
        var user = yield this.get('modalParentUser.user');
        var userHierarchy = yield store.createRecord('userHierarchy', {
          user: user,
          userHierarchyTree: userHierarchyTree,
          parent: selectedUser
        });
        yield userHierarchy.save(); //notify parent list that it has new users

        /*   this.set('newGroupUsers', newUsers); */

        buttonPromise.then(() => {
          this.set('addUsersFormStarted', false);
          this.set('wellUpdated', true);
          this.set('saveComplete', true);
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
          this.changeRouteTask.perform();
        });
      } catch (err) {
        this.set('addUsersFormStarted', false);
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
        (false && Ember.warn('Org Chart add users to user error' + err, {
          id: 'multiStepForm.forms.administration.permissions.addUsers.addUsersTask'
        }));
        throw err;
      }
    }),
    changeRouteTask: (0, _emberConcurrency.task)(function* () {
      var userHierarchyTree = yield this.get('orgTree.userHierarchyTree');
      var user = JSON.parse(this.get('selectedUser.id'));
      this.router.transitionTo('/administration/org-chart/' + userHierarchyTree.get('id') + '/user/' + user.user_id);
    }),
    actions: {}
  });

  _exports.default = _default;
});