define("securitytrax-ember-ui/components/multi-step-form/switchable-inputs/date-range-input/component", ["exports", "securitytrax-ember-ui/mixins/switchable-input", "moment", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _switchableInput, _moment, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Contextual component utilizing the following:
   * Basic Dropdown https://ember-basic-dropdown.com/docs/
   * Ember Power Calendar https://ember-power-calendar.com/docs/
   * Input masking https://github.com/RobinHerbots/Inputmask
   */
  var _default = Ember.Component.extend(_switchableInput.default, {
    format: undefined,
    locale: 'en',
    firstDay: null,
    showDaysAround: true,
    //shows days of surrounding months
    disabledDates: null,
    //array of dates to not allow selection
    showYearNav: false,
    showDatePicker: true,
    labelProperty: 'option',
    valueProperty: 'value',
    hasSelect: false,
    rangeInitiallySelected: false,
    minRange: 0,
    fromCenter: undefined,
    toCenter: undefined,
    fromDate: undefined,
    toDate: undefined,
    allowClear: true,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        format: 'MM/DD/YYYY'
      });

      if (this.isFilter) {
        var filterData = this.stFiltersService.registerFilter(this.stFilterGroupKey, this.stFilterKey, ['fromDate', 'toDate']);

        if (filterData['fromDate']) {
          var newFromDate = (0, _moment.default)(filterData['fromDate']['fieldValue'], ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD', 'YYYY/MM/DD', 'MM/DD/YYYY']).startOf('day');
          Ember.set(this, 'fromDate', newFromDate);

          if (this.onUpdateFromDate) {
            Ember.run.later(() => {
              this.onUpdateFromDate(newFromDate);

              if (!filterData['toDate']) {
                this.stFiltersService.setAsLoaded(this.stFilterGroupKey, this.stFilterKey);
              }
            });
          }
        } else {
          Ember.run.later(() => {
            if (!filterData['toDate']) {
              this.stFiltersService.setAsLoaded(this.stFilterGroupKey, this.stFilterKey);
            }
          });
        }

        if (filterData['toDate']) {
          var newToDate = (0, _moment.default)(filterData['toDate']['fieldValue'], ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD', 'YYYY/MM/DD', 'MM/DD/YYYY']).endOf('day');
          Ember.set(this, 'toDate', newToDate);

          if (this.onUpdateToDate) {
            Ember.run.later(() => {
              this.onUpdateToDate(newToDate);
              this.stFiltersService.setAsLoaded(this.stFilterGroupKey, this.stFilterKey);
            });
          }
        } else {
          Ember.run.later(() => {
            this.stFiltersService.setAsLoaded(this.stFilterGroupKey, this.stFilterKey);
          });
        }
      }

      if (this.fromDate && !_moment.default.isMoment(this.fromDate)) {
        Ember.set(this, 'fromDate', (0, _moment.default)(this.fromDate, ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD', 'YYYY/MM/DD', 'MM/DD/YYYY'])).startOf('day');
      }

      if (this.toDate && !_moment.default.isMoment(this.toDate)) {
        Ember.set(this, 'toDate', (0, _moment.default)(this.toDate, ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD', 'YYYY/MM/DD', 'MM/DD/YYYY']).endOf('day'));
      }

      this.setupFromAndToCenters();
    },

    setupFromAndToCenters() {
      this.setupFromCenter();
      this.setupToCenter();
    },

    setupFromCenter() {
      var fromCenter = this.fromDate ? (0, _moment.default)(this.fromDate, ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD', 'YYYY/MM/DD', 'MM/DD/YYYY']) : (0, _moment.default)();

      if (this.toDate && this.toDate.month() < fromCenter.month()) {
        fromCenter = (0, _moment.default)(this.toDate, ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD', 'YYYY/MM/DD', 'MM/DD/YYYY']).subtract(1, 'month');
      }

      Ember.set(this, 'fromCenter', fromCenter);
    },

    setupToCenter() {
      var toCenter = this.toDate ? (0, _moment.default)(this.toDate, ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD', 'YYYY/MM/DD', 'MM/DD/YYYY']) : (0, _moment.default)().add(1, 'month');

      if (this.fromDate && this.fromDate.month() >= toCenter.month()) {
        toCenter = (0, _moment.default)(this.fromDate, ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD', 'YYYY/MM/DD', 'MM/DD/YYYY']).add(1, 'month');
      }

      Ember.set(this, 'toCenter', toCenter);
    },

    range: Ember.computed('fromDate', 'toDate', function () {
      return {
        end: this.toDate,
        start: this.fromDate
      };
    }),
    selectedRangeClass: Ember.computed('range', function () {
      if (this.get('range.end')) {
        return ' range-selected';
      }

      return '';
    }),
    momentValue: Ember.computed('value', function () {
      return (0, _moment.default)(this.value, ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD', 'YYYY/MM/DD', 'MM/DD/YYYY']);
    }),
    placeholder: Ember.computed(function () {
      return 'mm/dd/yyyy - mm/dd/yyyy';
    }),
    valueFormatted: Ember.computed('fromDate', 'toDate', function () {
      var from = this.fromDate;
      var to = this.toDate;

      if (from || to) {
        return (from ? (0, _moment.default)(from, ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD', 'YYYY/MM/DD', 'MM/DD/YYYY']).format(this.format) : '') + ' - ' + (to ? (0, _moment.default)(to, ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD', 'YYYY/MM/DD', 'MM/DD/YYYY']).format(this.format) : '');
      } else {
        return null;
      }
    }),

    updateFilterData() {
      if (this.isFilter) {
        if (this.fromDate) {
          var newFromDate = (0, _moment.default)(this.fromDate, 'MM/DD/YYYY').startOf('day').format('YYYY-MM-DDTHH:mm:ss');
          this.stFiltersService.saveFilter(this.stFilterGroupKey, this.stFilterKey, 'fromDate', this.stFiltersService.getActualFilterOrUseCallback(this.stFilterAttribute, newFromDate, "".concat(this.stFilterAttribute, " >= '").concat(newFromDate, "'")), newFromDate, this.stFilterDoNotCache, this.stFilterFromAttribute);
        } else {
          this.stFiltersService.clearFilter(this.stFilterGroupKey, this.stFilterKey, 'fromDate');
        }

        if (this.toDate) {
          var newToDate = (0, _moment.default)(this.toDate, 'MM/DD/YYYY').endOf('day').format('YYYY-MM-DDTHH:mm:ss');
          this.stFiltersService.saveFilter(this.stFilterGroupKey, this.stFilterKey, 'toDate', this.stFiltersService.getActualFilterOrUseCallback(this.stFilterAttribute, newToDate, "".concat(this.stFilterAttribute, " <= '").concat(newToDate, "'")), newToDate, this.stFilterDoNotCache, this.stFilterToAttribute);
        } else {
          this.stFiltersService.clearFilter(this.stFilterGroupKey, this.stFilterKey, 'toDate');
        }
      }
    },

    actions: {
      updateTo() {
        // only doing this check because worried it might break things other than filters
        if (this.isFilter) {
          this.notifyPropertyChange('toDate');
          this.setupFromAndToCenters();
          this.updateFilterData();
        }

        if (this.onChange) {
          this.onChange();
        }
      },

      updateFrom() {
        // only doing this check because worried it might break things other than filters
        if (this.isFilter) {
          this.notifyPropertyChange('fromDate');
          this.setupFromAndToCenters();
          this.updateFilterData();
        }

        if (this.onChange) {
          this.onChange();
        }
      },

      updateToFrom(range) {
        Ember.set(this, 'fromDate', range.moment.start);
        Ember.set(this, 'toDate', range.moment.end ? (0, _moment.default)(range.moment.end, ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD', 'YYYY/MM/DD', 'MM/DD/YYYY']).endOf('day') : null);
        this.notifyPropertyChange('fromDate');
        this.notifyPropertyChange('toDate');
        this.setupFromAndToCenters();
        this.updateFilterData();

        if (this.onChange) {
          this.onChange();
        }
      },

      clearToFrom() {
        Ember.set(this, 'fromDate', null);
        Ember.set(this, 'toDate', null);
        this.setupFromAndToCenters();
        this.updateFilterData();

        if (this.onChange) {
          this.onChange();
        }
      },

      moveCenter(calendar, toUpdateCenter, amount, by) {
        calendar.actions.moveCenter(amount, by);
        var to = this.toCenter;
        var from = this.fromCenter; // check to see if we have bumped into the to or from and so need to bump it as well

        if (from.month() === to.month() && from.year() === to.year()) {
          if (toUpdateCenter === 'toCenter') {
            Ember.set(this, 'toCenter', (0, _moment.default)(from, ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD', 'YYYY/MM/DD', 'MM/DD/YYYY']).add(amount, by));
          } else if (toUpdateCenter === 'fromCenter') {
            Ember.set(this, 'fromCenter', (0, _moment.default)(to, ['YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD', 'YYYY/MM/DD', 'MM/DD/YYYY']).add(amount, by));
          }
        }
      },

      closeDropdown() {
        if (this.closeAction && typeof this.closeAction === 'function') {
          this.closeAction();
        }
      },

      openDropdown() {
        if (this.openAction && typeof this.openAction === 'function') {
          this.openAction();
        }
      }

    }
  });

  _exports.default = _default;
});