define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/consumer-financing-lenders/smart-home-pay-los/integration/component", ["exports", "@glimmer/component", "ember-concurrency", "lodash"], function (_exports, _component, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var DealerAllyIntegration = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);

    if (_lodash.default.get(this.args.multiStepForm, 'formCopiedModels.consumerFinancingLender.model.consumerFinancingLenderConfig.responseMeta.attribute_has_saved_value.shp_los_secret', false) && _lodash.default.get(this.args.multiStepForm, 'formCopiedModels.consumerFinancingLender.model.consumerFinancingLenderConfig.responseMeta.attribute_has_saved_value.shp_los_bearer_token', false)) {
      this.disableConnectionTest = false;
    }

    this.renderButtons();
  }), _dec11 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var noValidate = ['consumerFinancingLenderConfig-username', 'consumerFinancingLenderConfig-password', 'consumerFinancingLenderConfig-apiKey', 'consumerFinancingLenderConfig-merchantUid'];

    if (_lodash.default.get(this.args.multiStepForm, 'formCopiedModels.consumerFinancingLender.model.consumerFinancingLenderConfig.responseMeta.attribute_has_saved_value.shp_los_secret', false)) {
      noValidate.push('consumerFinancingLenderConfig-webhookSecret');
    }

    if (_lodash.default.get(this.args.multiStepForm, 'formCopiedModels.consumerFinancingLender.model.consumerFinancingLenderConfig.responseMeta.attribute_has_saved_value.shp_los_bearer_token', false)) {
      noValidate.push('consumerFinancingLenderConfig-apiToken');
    }

    yield this.args.multiStepForm.validateSyncSaveAll({
      extraValidationData: {
        noValidate
      },
      notificationNamespace: 'modal',
      successNotificationNamespace: 'modal'
    });
    buttonPromise.then(() => {
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
      this.initDataTask.perform();
    });
  }), _dec12 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
    var lender = yield this.store.findRecord('consumerFinancingLender', this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingLender.model.id'));

    try {
      yield lender.invokeAction('test_connection', {
        verb: 'POST',
        data: {
          data: {}
        }
      });
      this.stNotificationManager.addSuccess(this.intl.t('pages.customers.accounting.financing.successfully_connected'), 'modal');
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
    }
  }), _dec13 = Ember._action, (_class = (_temp = class DealerAllyIntegration extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor3, this);

      _initializerDefineProperty(this, "sessionData", _descriptor4, this);

      _initializerDefineProperty(this, "manageConnectionFormStarted", _descriptor5, this);

      _initializerDefineProperty(this, "formMode", _descriptor6, this);

      _initializerDefineProperty(this, "apiTokenDisplay", _descriptor7, this);

      _initializerDefineProperty(this, "webhookSecretDisplay", _descriptor8, this);

      _initializerDefineProperty(this, "disableConnectionTest", _descriptor9, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor10, this);

      _initializerDefineProperty(this, "saveLenderConfigTask", _descriptor11, this);

      _initializerDefineProperty(this, "testConnectionTask", _descriptor12, this);

      this.initDataTask.perform();
    }

    renderButtons() {
      var buttons = [];
      buttons.push({
        text: this.intl.t('pages.customers.accounting.financing.test_connection'),
        click: () => {
          this.testConnectionTask.perform();
        },
        disabled: this.disableConnectionTest,
        classNamesString: 'st-btn st-btn-primary consumer-financing-settings-save-button'
      });

      if (this.args.formMode !== 'view') {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveLenderConfigTask.perform(buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary consumer-financing-settings-save-button'
        });
      }

      this.args.multiStepForm.registerStepData('integration', 'buttons', buttons);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "manageConnectionFormStarted", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "formMode", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'view';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "apiTokenDisplay", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "webhookSecretDisplay", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "disableConnectionTest", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "saveLenderConfigTask", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "testConnectionTask", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "renderButtons", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "renderButtons"), _class.prototype)), _class));
  _exports.default = DealerAllyIntegration;
});