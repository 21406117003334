define("securitytrax-ember-ui/components/multi-step-form/forms/leads/information/component", ["exports", "securitytrax-ember-ui/utils/debug", "securitytrax-ember-ui/utils/obj-helpers", "ember-concurrency", "lodash", "securitytrax-ember-ui/components/multi-step-form/forms/leads/lead-base", "securitytrax-ember-ui/mixins/forms/customer-lead-shared", "securitytrax-ember-ui/mixins/set-user"], function (_exports, _debug, _objHelpers, _emberConcurrency, _lodash, _leadBase, _customerLeadShared, _setUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _leadBase.default.extend(_customerLeadShared.default, _setUser.default, {
    intl: Ember.inject.service(),
    langs: Ember.inject.service(),
    mapWindow: Ember.inject.service(),
    router: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    leadStatusEvents: Ember.inject.service('events/lead-status-events'),
    leadStatuses: Ember.inject.service(),
    userService: Ember.inject.service(),
    accountTypes: undefined,
    creditCheckFormStarted: false,
    creditChecksProgressBarConfig: undefined,
    dealerNumbers: undefined,
    entity: undefined,
    homeOwnerships: undefined,
    locations: undefined,
    monitoringCompanies: undefined,
    newPreCheck: undefined,
    sourceOptions: undefined,
    confirmDeleteModalOpen: false,
    countyDisable: false,
    repUsers: undefined,
    // nrg: false,
    initDataTask: (0, _emberConcurrency.task)(function* () {
      // try {
      //     const nrgWorkflow = yield this.store.find('workflow', 'reliant_sales_1');
      //     if (nrgWorkflow) {
      //         set(this, 'nrg', true);
      //     }
      // } catch(err) {
      //     set(this, 'nrg', false);
      // }
      yield (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true);
      (0, _objHelpers.initializeState)(this, {
        creditChecksProgressBarConfig: {
          hideProgressBar: true
        },
        homeOwnerships: ['own', 'rent', 'lease', 'other'],
        accountTypes: ['Residential', 'Commercial'],
        monitoringCompanies: [],
        newPreCheck: true,
        sourceOptions: [],
        entity: 'lead'
      });

      if (this.isNewRecord) {
        var hashedData = yield (0, _emberConcurrency.hash)({
          loggedInUser: this.sessionData.getUser(),
          repUsers: this.userService.getUsersList('lead_rep', this.newCustomer)
        });

        if (!hashedData.repUsers.findBy('id', hashedData.loggedInUser.get('id'))) {
          hashedData.repUsers.pushObject(hashedData.loggedInUser);
        }

        Ember.setProperties(this, hashedData);
      }

      if (this.formMode != 'create' || this.isAdtCompany == false || this.isNewRecord && this.newCustomer != null && this.multiStepForm.formCopiedModels.customer.model.leadInformation.monitoringCompany == null) {
        this.set('newPreCheck', false);
      }
    }).on('init'),

    didInsertElement() {
      this._super(...arguments);

      this.setupButtons(); // Create interval function to keep customer local time up to date

      var interval = 5000;

      var fn = () => {
        if (this && !this.isDestroyed && !(0, _debug.isTestEnvironment)()) {
          this.notifyPropertyChange('localTime');
          this.set('timer', Ember.run.later(fn, interval));
        }
      };

      fn(); // Initiate interval.
    },

    setupButtons: function () {
      var multiStepForm = this.multiStepForm;
      multiStepForm.progressBarConfig.activeIndex = 0;
      var buttons = []; // if (this.nrg && this.newCustomer) {
      //     buttons.push({
      //         text: this.intl.t('common.actions.skip'),
      //         classNamesString: 'st-btn st-btn-secondary',
      //         click: () => {
      //             this.advanceForm(multiStepForm, 'step2', 1, null, true);
      //         }
      //     });
      // }

      if (multiStepForm.formSteps.step2) {
        buttons.push({
          text: this.intl.t('common.actions.saveAndContinue'),
          classNamesString: 'st-btn st-btn-primary lead-information-save-button',
          click: buttonPromise => {
            return this.saveLeadTask.perform(multiStepForm, buttonPromise, false);
          },
          positionOptions: 'up left',
          additionalClickActions: [{
            label: this.intl.t('common.actions.saveAndClose'),
            action: buttonPromise => {
              return this.saveLeadTask.perform(multiStepForm, buttonPromise);
            },
            classNamesString: 'dropdown-save-close'
          }]
        });
      } else {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          classNamesString: 'st-btn st-btn-primary lead-information-save-button',
          click: buttonPromise => {
            return this.saveLeadTask.perform(multiStepForm, buttonPromise);
          }
        });
      }

      multiStepForm.registerStepData('step1', 'buttons', buttons);
    },
    setupDataPromises: Ember.computed(function () {
      return this.setupDataPromisesTask.perform();
    }),
    setupDataPromisesTask: (0, _emberConcurrency.task)(function* () {
      var dataHash = yield (0, _emberConcurrency.hash)({
        monitoringCompanyTypes: this.store.findAll('monitoringCompanyType'),
        dealerNumbers: this.store.findAll('dealerNumber'),
        sourceOptions: this.grants.getIndividualGrant('lead_companies.view') ? this.store.findAll('leadCompany') : [],
        loggedInUser: this.sessionData.getUser()
      });
      var monitoringCompanies = yield this.store.findAll('monitoringCompany'); //do after getting monitoring company types

      this.set('monitoringCompanies', monitoringCompanies);
      this.set('dealerNumbers', dataHash.dealerNumbers);
      this.set('sourceOptions', dataHash.sourceOptions);
      this.set('loggedInUser', dataHash.loggedInUser);

      if (this.isNewRecord) {
        var hashedData = yield (0, _emberConcurrency.hash)({
          leadInfoDefaultOptions: this.sessionData.getCompanyDefaults('leadInformationDefault'),
          leadDetailsDefaultOptions: this.sessionData.getCompanyDefaults('leadDetailsDefault'),
          leadStatuses: this.leadStatusOptions
        });

        if (hashedData.leadDetailsDefaultOptions || hashedData.leadInfoDefaultOptions) {
          this.multiStepForm.formCopiedModelsSetVal('customer.model.leadSourceInformation.leadCompany', dataHash.sourceOptions.findBy('id', hashedData.leadInfoDefaultOptions.source));
          var sourceOptions = yield this.sourceCampaignOptions;
          this.multiStepForm.formCopiedModelsSetVal('customer.model.leadSourceInformation.leadCompanyCampaign', sourceOptions.findBy('id', hashedData.leadInfoDefaultOptions.sourceCampaign));
          this.multiStepForm.formCopiedModelsSetVal('customer.model.lang', hashedData.leadInfoDefaultOptions.primaryLanguage);
          this.multiStepForm.formCopiedModelsSetVal('customer.model.state', hashedData.leadInfoDefaultOptions.state);
          this.multiStepForm.formCopiedModelsSetVal('customer.model.leadInformation.leadStatus', hashedData.leadStatuses.findBy('id', hashedData.leadDetailsDefaultOptions.status));
          yield this.setUser.perform(this.multiStepForm.formCopiedModels.customer.model.leadInformation, 'leadRepUser', dataHash.loggedInUser.id);
          this.multiStepForm.formCopiedModelsSetVal('customer.model.accountType', hashedData.leadInfoDefaultOptions.accountType);
        }
      }
    }),
    //filter campaigns based on lead company
    leadCompanyId: Ember.computed('multiStepForm.formCopiedModels.customer.model.leadSourceInformation.leadCompany', function () {
      return _lodash.default.get(this.multiStepForm, 'formCopiedModels.customer.model.leadSourceInformation.leadCompany.id', null);
    }),
    leadStatusOptions: Ember.computed('formMode', function () {
      return this.leadStatusOptionsForCreate.perform();
    }),
    leadStatusOptionsForCreate: (0, _emberConcurrency.task)(function* () {
      try {
        var blacklistedIds = ['1', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19'];
        var leadStatuses = yield this.leadStatuses.getLeadStatusOptionsTask.perform(blacklistedIds);
        leadStatuses.forEach(leadStatus => {
          leadStatus.set('createLabel', leadStatus.name);
        });
        return leadStatuses;
      } catch (err) {
        (false && Ember.warn('Error in leads information grab lead statuses' + err, {
          id: 'leads.information'
        }));
      }
    }),
    sourceCampaignOptions: Ember.computed('leadCompanyId', function () {
      return this.leadCompanyCampaignsTask.perform();
    }),
    leadCompanyCampaignsTask: (0, _emberConcurrency.task)(function* () {
      if (this.leadCompanyId > 0) {
        var leadCompany = yield this.store.findRecord('leadCompany', this.leadCompanyId);
        return yield leadCompany.get('leadCompanyCampaigns');
      }

      return [];
    }),
    validationMessages: Ember.computed('newPreCheck', function () {
      var requiredProperties = ['location', 'fname', 'lname', 'city', 'state', 'primaryPhone'];
      var validationsObject = {};

      if (this.isNewRecord && this.newPreCheck && this.isAdtCompany) {
        requiredProperties.push('address1', 'county', 'zip', 'accountType', 'customerBirthdate', 'customerSsn', 'email');
        validationsObject['orderInformation-monitoringCompany'] = this.intl.t('pages.customers.order.monitoringCompany.validateMessage');
      }

      requiredProperties.forEach(item => {
        validationsObject[item] = this.intl.t('common.validations.presence', {
          propertyLabel: this.intl.t("common.nav.create.customer.".concat(item, ".label"))
        });
      });
      validationsObject['customerBirthdate-birthdate'] = this.intl.t('common.nav.create.customer.location.validateMessage');
      validationsObject['email'] = this.intl.t("common.nav.create.customer.email.validateMessage");
      validationsObject['phoneInvalid'] = this.intl.t('common.nav.create.customer.primaryPhone.validateMessage');
      validationsObject['zipInvalid'] = this.intl.t('common.nav.create.customer.zip.validateMessage');
      validationsObject['businessName'] = this.intl.t('common.nav.create.customer.businessName.validation');
      return validationsObject;
    }),
    // TODO: there is alot of shared code here with customer, we should try to consolidate.
    saveLeadTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise, close = true) {
      try {
        yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);
        var formattedObjects = Ember.A(); // if (this.nrg) {
        //     this.customerUdfList.validateUdfs(formattedObjects);
        // }
        /////////////////////////////////////////////////////////////////////////////////////
        // be sure customer ssn dashes are stripped before saving

        var customerSsn = _lodash.default.get(multiStepForm, 'formCopiedModels.customer.model.customerSsn.ssn', null);

        if (customerSsn != null && typeof customerSsn === 'string') {
          Ember.set(multiStepForm, 'formCopiedModels.customer.model.customerSsn.ssn', customerSsn.replace(/-/g, ''));
        }

        var customer;
        this.set('newPreCheck', this.adtMonitoringCompanies.length ? this.newPreCheck : false);
        var customerWithUpdatedResponseMeta;
        var isAdtCompany = yield this.isAdtCompany;
        var noValidateArray = ['location', 'saleDate', 'orderInformation-customerCancelDate', 'orderInformation-monitoringCompany', //not required for leads
        'activationFee-amount', 'mmr-amount', 'contractTerm-months', 'customerCancelReason-name', 'interactiveServicesProvider-name', 'customerWarranty-name', 'customerWarranty-duration', 'dealerNumber-number', 'dealerNumber-name', 'funder-name', 'leadCompany-name', 'orderInformation-salesRep', 'location-shippingCity', 'location-shippingContact', 'location-shippingState', 'location-shippingStreet', 'location-shippingZip', 'leadStatus-name'];

        if (!isAdtCompany || !this.newPreCheck) {
          noValidateArray.push('customerBirthdate');
          noValidateArray.push('customerSsn');
          noValidateArray.push('leadInformation-monitoringCompany');
          noValidateArray.push('customer-zip');
          noValidateArray.push('customer-address1');
        }

        if (!isAdtCompany || this.multiStepForm.formCopiedModels.customer.model.accountType !== 'Commercial') {
          noValidateArray.push('businessName');
          noValidateArray.push('businessContact');
        }

        formattedObjects.pushObject(Ember.Object.create({
          modelName: 'customer',
          model: multiStepForm.formCopiedModelsGetVal('customer.model'),
          extraValidationData: {
            adtValidation: true,
            newPreCheck: this.newPreCheck && isAdtCompany,
            validationMessages: this.validationMessages,
            noValidate: noValidateArray
          },
          notificationNamespace: 'createLeadModal'
        }));
        formattedObjects.pushObject(Ember.Object.create({
          modelName: 'leadInformation',
          model: multiStepForm.formCopiedModelsGetVal('customer.model.leadInformation'),
          extraValidationData: {
            newPreCheck: this.newPreCheck && isAdtCompany,
            validationMessages: this.validationMessages,
            noValidate: noValidateArray
          },
          notificationNamespace: 'createLeadModal'
        }));
        formattedObjects.pushObject(Ember.Object.create({
          modelName: 'leadSourceInformation',
          model: multiStepForm.formCopiedModelsGetVal('customer.model.leadSourceInformation'),
          extraValidationData: {
            newPreCheck: this.newPreCheck && isAdtCompany,
            validationMessages: this.validationMessages,
            noValidate: noValidateArray
          },
          notificationNamespace: 'createLeadModal'
        })); // if (!this.nrg) {

        formattedObjects.pushObject(Ember.Object.create({
          modelName: 'customerBirthdate',
          model: multiStepForm.formCopiedModelsGetVal('customer.model.customerBirthdate'),
          extraValidationData: {
            newPreCheck: this.newPreCheck && isAdtCompany,
            validationMessages: this.validationMessages,
            noValidate: noValidateArray
          },
          notificationNamespace: 'createLeadModal'
        }));
        formattedObjects.pushObject(Ember.Object.create({
          modelName: 'customerSsn',
          model: multiStepForm.formCopiedModelsGetVal('customer.model.customerSsn'),
          extraValidationData: {
            newPreCheck: this.newPreCheck && isAdtCompany,
            validationMessages: this.validationMessages,
            noValidate: noValidateArray
          },
          notificationNamespace: 'createLeadModal'
        })); // }

        if (this.isNewRecord && !this.newCustomer) {
          this.multiStepForm.formCopiedModelsSetVal('customer.model.recordType', 'lead');
          this.multiStepForm.formCopiedModelsSetVal('customer.model.leadCompany', this.multiStepForm.formCopiedModelsGetVal('customer.model.leadSourceInformation.leadCompany'));
          var toCreateObjects = yield multiStepForm.validateCreateList.perform(formattedObjects, {
            notificationNamespace: 'modal'
          }); // leadCompany and leadStatus required to create a lead.

          customer = this.store.createRecord('customer', toCreateObjects.customer[0].data);
          var leadInformation = this.store.createRecord('leadInformation', toCreateObjects.leadInformation[0].data);
          var leadSourceInformation = this.store.createRecord('leadSourceInformation', toCreateObjects.leadSourceInformation[0].data); // if (!this.nrg) {

          var customerBirthdate = this.store.createRecord('customerBirthdate', toCreateObjects.customerBirthdate[0].data);

          var _customerSsn = this.store.createRecord('customerSsn', toCreateObjects.customerSsn[0].data);

          customer.set('customerSsn', _customerSsn);
          customer.set('customerBirthdate', customerBirthdate); // }

          customer.set('leadInformation', leadInformation);
          customer.set('leadSourceInformation', leadSourceInformation);
          customer.set('requestMeta', {
            check_for_duplicates: this.checkForDuplicates
          });
          yield toCreateObjects.customer[0].saveWithRollbackAndNotification(customer);
        } else {
          yield multiStepForm.validateList.perform(formattedObjects, {
            notificationNamespace: 'createLeadModal'
          });
          yield multiStepForm.validateSyncSaveAll({
            notificationNamespace: 'createLeadModal',
            skipValidation: true
          });
          customer = yield this.store.findRecord('customer', this.multiStepForm.formCopiedModelsGetVal('customer.model.id'));
        }

        if (this.newPreCheck && isAdtCompany) {
          yield this.submitAdtPreCheck.perform(customer, 'global'); // createLeadModal if we halt the process...
        }

        if (this.isNewRecord && !this.newCustomer) {
          customerWithUpdatedResponseMeta = yield this.store.findRecord('customer', customer.get('id'));
        } // if (this.nrg) {
        //     this.customerUdfList.saveUdfsTask.perform(customer);
        // }


        buttonPromise.then(() => {
          this.checkForDuplicates = false;
          customer.set('requestMeta', {
            check_for_duplicates: false
          });
          this.leadStatusEvents.trigger('redoModels');

          if (close) {
            this.closeForm(multiStepForm, customer);
          } else {
            if (this.isNewRecord && !this.newCustomer) {
              this.set('newCustomer', customerWithUpdatedResponseMeta);
            }

            this.advanceForm(multiStepForm, 'step2', 1, customer);
          }
        });
      } catch (error) {
        (false && Ember.warn('error in saveLeadTask: ' + error, {
          id: 'multiStepForm.forms.leads.information.createLead'
        }));
        var jsonError = this.stNotificationManager.addErrorMessages(error, {
          notificationNamespace: 'createLeadModal',
          showFallbackMessage: false
        });
        this.set('records', jsonError['records']);
        this.checkForDuplicates = false;
        notifyPropertyChange(this, 'records');
        throw error;
      }
    }),
    defaultLeadRepTask: (0, _emberConcurrency.task)(function* () {
      if (this.multiStepForm.formCopiedModels.customer.model.leadInformation.leadStatus.id == '4') {
        yield this.setUser.perform(this.multiStepForm.formCopiedModels.customer.model.leadInformation, 'leadRepUser', this.loggedInUser.id);
      } else {
        this.multiStepForm.formCopiedModelsSetVal('customer.model.leadInformation.leadRepUser', null);
      }
    }),
    actions: {
      saveLead(buttonPromise) {
        var multiStepForm = this.multiStepForm;
        return this.saveLeadTask.perform(multiStepForm, buttonPromise);
      },

      cancel() {
        this.set('formMode', 'view');
        this.multiStepForm.resetForm(false);
      },

      openQualifyForm() {
        Ember.set(this, 'creditCheckFormStarted', true);
      }

    }
  });

  _exports.default = _default;
});