define("securitytrax-ember-ui/components/html/section-divider/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rGO5THQ9",
    "block": "{\"symbols\":[\"@recordCount\",\"&attrs\",\"@style\",\"@title\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"grid-x grid-padding-y grid-padding-x row\"],[17,2],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cell shrink\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"section-title\"],[15,5,[30,[36,0],[[32,3]],null]],[12],[2,\"\\n            \"],[1,[32,4]],[2,\" \"],[6,[37,2],[[30,[36,1],[[32,1],0],null]],null,[[\"default\"],[{\"statements\":[[10,\"span\"],[14,0,\"record-count\"],[12],[1,[32,1]],[13]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cell auto\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"section-line\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[18,5,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"html-safe\",\"gt\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/section-divider/template.hbs"
    }
  });

  _exports.default = _default;
});