define("securitytrax-ember-ui/components/multi-step-form/forms/administration/users/information/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/administration/users/base-users", "ember-concurrency"], function (_exports, _baseUsers, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseUsers.default.extend({
    timezones: Ember.inject.service(),
    locales: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    profile: Ember.inject.service(),
    grants: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    recentSearchesService: Ember.inject.service(),
    loggedInUser: undefined,
    canModifyAccountInformation: Ember.computed('grants.userGrants', function () {
      return this.grants.getIndividualGrant('user_admin_settings.modify') && this.grants.getIndividualGrant('users.modify');
    }),
    copiedUserName: Ember.computed('multiStepForm.formCopiedModels.user', function () {
      return this.multiStepForm.formCopiedModelsGetVal('user.model.fname') + ' ' + this.multiStepForm.formCopiedModelsGetVal('user.model.lname');
    }),
    initFormData: (0, _emberConcurrency.task)(function* () {
      var loggedIn = yield this.sessionData.getUser();
      this.set('loggedInUser', loggedIn);
    }).on('init'),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;

      if (!this.isNewRecord) {
        multiStepForm.registerStepData("information", 'buttons', [{
          text: this.intl.t('common.actions.cancel'),
          click: () => {
            multiStepForm.resetForm(false);
            this.set('informationFormMode', 'view');
          },
          classNamesString: 'st-btn btn-secondary user-cancel-button'
        }, {
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            var noValidateArray = ['username', 'password', 'group'];
            return this.saveUserDataTask.perform(multiStepForm, buttonPromise, noValidateArray);
          },
          classNamesString: 'st-btn st-btn-primary user-save-button'
        }]);
      }
    },

    timeZoneList: Ember.computed(function () {
      return this.timezones.all;
    }),
    localesList: Ember.computed(function () {
      return this.locales.all();
    }),
    emulateUserTask: (0, _emberConcurrency.task)(function* (userId) {
      yield this.get('sessionData.emulateUserTask').perform(userId);
    }),
    deleteUser: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.multiStepForm.formOriginalModelsGetVal('user.model').destroyRecordWithRollbackTask.perform();
        this.router.transitionTo('administration.human-resources.user-list');
        this.recentSearchesService.removeRecents('recentUsers', [this.multiStepForm.formOriginalModelsGetVal('user.model.id')]);
        this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'userConfirmDelete'
        });
        throw err;
      }
    })
  });

  _exports.default = _default;
});