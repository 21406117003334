define("securitytrax-ember-ui/helpers/ucwords", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ucwords = ucwords;
  _exports.default = void 0;

  function ucwords([str]) {
    if (str) {
      return (str + '').replace(/^([a-z\u00E0-\u00FC])|\s+([a-z\u00E0-\u00FC])/g, function ($1) {
        return $1.toUpperCase();
      });
    }
  }

  var _default = Ember.Helper.helper(ucwords);

  _exports.default = _default;
});