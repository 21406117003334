define("securitytrax-ember-ui/components/multi-step-form/forms/system/adt/shared/discrepancies/manage-discrepancies/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('manageDiscrepancies', 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.resolveDiscrepanciesTask.perform(multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary discrepancies-save-button'
      }]);
    },

    discrepancyName: Ember.computed('discrepancies', function () {
      var discrepancies = this.discrepancies;
      var discrepancyName = discrepancies.filter(item => {
        return item.category === 'account' && item.field === 'name';
      });

      if (discrepancyName.length > 0) {
        return discrepancyName;
      }

      return false;
    }),
    selectedPackageName: Ember.computed(function () {
      var choices = this.packageSelectChoicesName;
      return choices[0].packageId;
    }),
    packageSelectChoicesName: Ember.computed('discrepancyName', function () {
      var discrepancyName = this.discrepancyName ? this.discrepancyName[0] : null;
      var choices = Ember.A([{
        packageName: this.intl.t('common.columns.name'),
        packageContent: [{
          description: discrepancyName ? discrepancyName.local_value : ''
        }],
        packageId: 'st'
      }, {
        packageName: this.intl.t('common.columns.name'),
        packageContent: [{
          description: discrepancyName ? discrepancyName.remote_value : ''
        }],
        packageId: 'adt'
      }]);
      return choices;
    }),
    discrepancyAddress: Ember.computed('discrepancies', function () {
      var discrepancies = this.discrepancies;
      return discrepancies.some(item => {
        return item.category === 'account__address';
      });
    }),
    selectedPackageAddress: Ember.computed(function () {
      var choices = this.packageSelectChoicesAddress;
      return choices[0].packageId;
    }),
    packageSelectChoicesAddress: Ember.computed(function () {
      var stContent = Ember.A();
      var adtContent = Ember.A();
      var addr1 = this.discrepancies.find(discrepancy => discrepancy.field.toLowerCase() == 'address1');
      this.set('discrepancy_address1', addr1);
      var addr2 = this.discrepancies.find(discrepancy => discrepancy.field.toLowerCase() == 'address2');
      this.set('discrepancy_address2', addr2);
      var city = this.discrepancies.find(discrepancy => discrepancy.field.toLowerCase() == 'city');
      this.set('discrepancy_city', city);
      var state = this.discrepancies.find(discrepancy => discrepancy.field.toLowerCase() == 'state');
      this.set('discrepancy_state', state);
      var county = this.discrepancies.find(discrepancy => discrepancy.field.toLowerCase() == 'county');
      this.set('discrepancy_county', county);
      var zip = this.discrepancies.find(discrepancy => discrepancy.field.toLowerCase() == 'zip');
      this.set('discrepancy_zip', zip);

      if (addr1) {
        stContent.pushObject({
          label: this.intl.t('common.address.addr1'),
          description: addr1.local_value
        });
        adtContent.pushObject({
          label: this.intl.t('common.address.addr1'),
          description: addr1.remote_value
        });
      }

      if (addr2) {
        stContent.pushObject({
          label: this.intl.t('common.address.addr2'),
          description: addr2.local_value
        });
        adtContent.pushObject({
          label: this.intl.t('common.address.addr2'),
          description: addr2.local_value
        });
      }

      if (city) {
        stContent.pushObject({
          label: this.intl.t('common.address.city'),
          description: city.local_value
        });
        adtContent.pushObject({
          label: this.intl.t('common.address.city'),
          description: city.remote_value
        });
      }

      if (state) {
        stContent.pushObject({
          label: this.intl.t('common.address.state'),
          description: state.local_value
        });
        adtContent.pushObject({
          label: this.intl.t('common.address.state'),
          description: state.remote_value
        });
      }

      if (county) {
        stContent.pushObject({
          label: this.intl.t('common.address.county'),
          description: county.local_value
        });
        adtContent.pushObject({
          label: this.intl.t('common.address.county'),
          description: county.remote_value
        });
      }

      if (zip) {
        stContent.pushObject({
          label: this.intl.t('common.address.zipCode'),
          description: zip.local_value
        });
        adtContent.pushObject({
          label: this.intl.t('common.address.zipCode'),
          description: zip.remote_value
        });
      }

      var choices = Ember.A([{
        packageName: this.intl.t('common.address.label'),
        packageContent: stContent,
        packageId: 'st'
      }, {
        packageName: this.intl.t('common.address.label'),
        packageContent: adtContent,
        packageId: 'adt'
      }]);
      return choices;
    }),
    resolveDiscrepanciesTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      try {
        var customerModel = yield this.customer;
        var selectedName = this.selectedPackageName;
        var selectedAddress = this.selectedPackageAddress;

        if (selectedName == 'adt') {
          var discrepancyName = this.discrepancyName[0];
          var name = discrepancyName.remote_value.split(' ');
          customerModel.set('fname', name[0]);
          customerModel.set('lname', name[name.length - 1]);
        }

        if (selectedAddress == 'adt') {
          var addrArray = ['address1', 'address2', 'city', 'state', 'county', 'zip'];
          addrArray.forEach(addressItem => {
            var discrepancyString = 'discrepancy_' + addressItem;
            var adtAddressItem = this.get(discrepancyString);

            if (adtAddressItem) {
              if (addressItem === 'zip') {
                customerModel.set(addressItem, adtAddressItem.remote_value.substring(0, 5));
              } else {
                customerModel.set(addressItem, adtAddressItem.remote_value);
              }
            }
          });
        }

        if (selectedAddress == 'adt' || selectedName == 'adt') {
          yield customerModel.save();
        }

        buttonPromise.then(() => {
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'adtMegaFormModal');
          multiStepForm.formStartedSetVal(false);

          if (this.transitionBackToParent && typeof this.transitionBackToParent === 'function') {
            this.transitionBackToParent();
          }

          this.refreshAdtFormAndDiscrepencies.call();
        });
      } catch (response) {
        this.stNotificationManager.addErrorMessages(response, {
          notificationNamespace: 'modal2'
        });
      }
    })
  });

  _exports.default = _default;
});