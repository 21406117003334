define("securitytrax-ember-ui/components/lists/overlays/delete-confirm/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zq2peX+g",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,9],[[35,8]],null,[[\"default\"],[{\"statements\":[[6,[37,7],null,[[\"onClickOutside\",\"class\"],[[30,[36,3],[[32,0],[35,6]],null],\"delete-confirm\"]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"delete-confirm-outer\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"delete-confirm-inner\"],[12],[2,\"\\n                \"],[10,\"span\"],[14,0,\"message\"],[12],[1,[30,[36,1],[[35,0]],null]],[13],[2,\"\\n                \"],[10,\"span\"],[12],[2,\"\\n                    \"],[1,[30,[36,5],null,[[\"class\",\"text\",\"clickAction\"],[\"st-btn warning-red\",[30,[36,1],[[35,4]],null],[30,[36,3],[[32,0],[35,2]],null]]]]],[2,\"\\n                \"],[13],[2,\"\\n                \"],[10,\"span\"],[12],[2,\"\\n                    \"],[1,[30,[36,5],null,[[\"class\",\"text\",\"clickAction\"],[\"st-btn\",[30,[36,1],[\"common.actions.cancel\"],null],[30,[36,3],[[32,0],[35,6]],null]]]]],[2,\"\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"deleteMessageKey\",\"t\",\"onDelete\",\"action\",\"deleteButtonKey\",\"html/st-button\",\"onCancel\",\"click-outside\",\"showOverlay\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/lists/overlays/delete-confirm/template.hbs"
    }
  });

  _exports.default = _default;
});