define("securitytrax-ember-ui/components/html/status-timeline-node/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    closed: false,
    primaryText: '',
    secondaryText: '',
    tagName: '',
    primaryTextClass: Ember.computed('closed', function () {
      if (!this.closed) {
        return 'event-container__text--primary-closed';
      } else {
        return '';
      }
    })
  });

  _exports.default = _default;
});