define("securitytrax-ember-ui/components/html/input-checkbox/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['checkboxClassName'],
    checkboxClassName: 'checkbox',
    onChange: '',
    name: '',
    checked: false,
    disabled: null,
    readonly: null,
    indeterminate: false,

    toggle() {},

    actions: {
      toggle(event) {
        if (!this.disabled) {
          this.toggle();
          var onChange = this.onChange;

          if (onChange) {
            onChange.call(this, this.checked, event);
          }
        }

        if (event) {
          event.stopPropagation();
        } else {
          return false;
        }
      }

    }
  });

  _exports.default = _default;
});