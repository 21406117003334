define("securitytrax-ember-ui/demo/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YW+W8kQZ",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,0],null,null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"center\"],[12],[2,\"This area is for showing components and how they can be integrated into Ember\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"pages/demo-page\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/demo/index/template.hbs"
    }
  });

  _exports.default = _default;
});