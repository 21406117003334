define("securitytrax-ember-ui/components/multi-step-form/forms/administration/permissions/permissions-base", ["exports", "lodash", "ember-concurrency"], function (_exports, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    resourceType: undefined,
    grant: undefined,
    attribute: undefined,
    hasRows: false,
    getResourceTypeAttributesList: (0, _emberConcurrency.task)(function* (resourceType) {
      var introspection;
      var attributes = {};

      try {
        introspection = yield resourceType.invokeAction('introspect', {
          verb: 'GET',
          data: {
            data: {}
          }
        });
      } catch (err) {// Do Nothing
      }

      var attributesList = {
        attributes: [],
        metaAttributes: []
      };

      if (introspection && 'attributes' in introspection.responsePayload.data && introspection.responsePayload.data.attributes != null) {
        Object.keys(introspection.responsePayload.data.attributes).forEach(function (key) {
          attributesList.attributes.push(key);
        });
      }

      if (introspection && 'meta' in introspection.responsePayload.data && introspection.responsePayload.data.meta != null) {
        Object.keys(introspection.responsePayload.data.meta).forEach(function (key) {
          attributesList.metaAttributes.push(key);
        });
      }

      Ember.set(resourceType, 'attributes', attributesList);
      attributes[resourceType.get('id')] = attributesList;
      return attributes;
    }),
    actions: {
      bulkAddOrRemoveGroup(group, event, triggeredBySection = false, passedFromSection = false) {
        group.items.forEach(resource => {
          var resourceType = resource.resourceType;

          if (triggeredBySection) {
            resourceType.set('bulkCheck', passedFromSection);
          } else {
            resourceType.set('bulkCheck', event.target.checked);
          }

          this.get('permissionsService.bulkAddOrRemoveResourceTypeGrants').perform(resourceType, false);
        });

        if (triggeredBySection) {
          Ember.set(group, 'bulkEdit', passedFromSection); // If section says true, group must be true
        }
      },

      bulkAddOrRemoveSection(sectionLevel, isChecked) {
        Ember.set(sectionLevel, 'indeterminate', false);

        if (sectionLevel.totalChecked !== 0) {
          isChecked = true;
          Ember.set(sectionLevel, 'bulkSelect', false);
        }

        var items = _lodash.default.get(sectionLevel, 'items', []);

        items.forEach(group => {
          Ember.set(group, 'bulkCheck', !isChecked);
          this.send('bulkAddOrRemoveGroup', group, null, true, !isChecked);
        });
      }

    }
  });

  _exports.default = _default;
});