define("securitytrax-ember-ui/customers/at-risk-entries/edit-create-at-risk/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SxTvXiWq",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"customer\",\"customerAtRiskId\",\"parentRoute\",\"customerAtRisks\"],[[35,0,[\"customer\"]],[35,0,[\"atRiskId\"]],[35,0,[\"parentRoute\"]],[35,0,[\"customerAtRisks\"]]]]]]],\"hasEval\":false,\"upvars\":[\"model\",\"modals/sub-route-modals/edit-create-at-risks\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/customers/at-risk-entries/edit-create-at-risk/template.hbs"
    }
  });

  _exports.default = _default;
});