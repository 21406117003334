define("securitytrax-ember-ui/components/modals/st-paper/paper-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AuxTPLYH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,20],null,[[\"to\"],[[35,19]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,16],null,[[\"locked-open\",\"opaque\",\"fixed\",\"class\",\"onClick\"],[[35,15],[35,14],[30,[36,13],[[35,12],true],null],\"md-dialog-backdrop\",[30,[36,11],[[32,0],\"outsideClicked\"],null]]]]],[2,\"\\n\"],[6,[37,18],null,[[\"class\",\"outsideClicked\"],[[30,[36,9],[[30,[36,8],[[35,17]],null]],null],[30,[36,11],[[32,0],\"outsideClicked\"],null]]],[[\"default\"],[{\"statements\":[[6,[37,10],null,[[\"class\",\"origin\",\"defaultedParent\",\"defaultedOpenFrom\",\"defaultedCloseTo\",\"fullscreen\",\"hasProgressBar\",\"focusOnOpen\"],[[30,[36,9],[[30,[36,8],[[35,7]],null]],null],[35,6],[35,5],[35,4],[35,3],[35,2],[35,1],[35,0]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"focusOnOpen\",\"hasProgressBar\",\"fullscreen\",\"defaultedCloseTo\",\"defaultedOpenFrom\",\"defaultedParent\",\"origin\",\"class\",\"readonly\",\"concat\",\"modals/st-paper/paper-dialog-inner\",\"action\",\"parent\",\"unless\",\"opaque\",\"isLockedOpen\",\"modals/st-paper/paper-backdrop\",\"dialogContainerClass\",\"modals/st-paper/paper-dialog-container\",\"destinationId\",\"ember-wormhole\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/modals/st-paper/paper-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});