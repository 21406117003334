define("securitytrax-ember-ui/administration/company-settings/integrations/accounting-providers-sync/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DSa2uqIp",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[8,\"pages/administration/company-settings/integrations/accounting-providers-sync\",[],[[\"@breadCrumbs\",\"@breadCrumbData\"],[[32,0,[\"breadCrumbs\"]],[32,0,[\"breadCrumbData\"]]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/administration/company-settings/integrations/accounting-providers-sync/template.hbs"
    }
  });

  _exports.default = _default;
});