define("securitytrax-ember-ui/models/base_models/customer-information-default", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    accountClass: (0, _model.attr)('string', {
      'readOnly': false
    }),
    accountType: (0, _model.attr)('string', {
      'readOnly': false
    }),
    officeLocation: (0, _model.attr)('string', {
      'readOnly': false
    }),
    primaryLanguage: (0, _model.attr)('string', {
      'readOnly': false
    }),
    state: (0, _model.attr)('string', {
      'readOnly': false
    })
  });

  _exports.default = _default;
});