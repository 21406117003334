define("securitytrax-ember-ui/administration/inventory/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Controller
   * @extends Ember.Controller
   * @memberof administration.company-settings
   */
  var Controller = Ember.Controller.extend(
  /** @lends administration.inventory.Controller# */
  {
    navigation: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    initializeNavTask: (0, _emberConcurrency.task)(function* () {
      var loggedInUser = yield this.sessionData.getUser();
      this.set('loggedInUser', loggedInUser);
      yield this.navigation;
    }).on('init')
  });
  var _default = Controller;
  _exports.default = _default;
});