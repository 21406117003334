define("securitytrax-ember-ui/components/html/st-native-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dQFCEBEU",
    "block": "{\"symbols\":[\"&attrs\",\"@onChange\",\"@value\",\"&default\"],\"statements\":[[11,\"select\"],[24,0,\"...\"],[17,1],[4,[38,1],[\"change\",[30,[36,0],[\"target.value\",[32,2]],null]],null],[12],[2,\"\\n    \"],[18,4,[[30,[36,3],null,[[\"option\"],[[30,[36,2],[\"html/st-native-select/select-option\"],[[\"currentValue\"],[[32,3]]]]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"pick\",\"on\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/st-native-select/template.hbs"
    }
  });

  _exports.default = _default;
});