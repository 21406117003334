define("securitytrax-ember-ui/models/equipment-wire-type", ["exports", "securitytrax-ember-ui/models/base_models/equipment-wire-type"], function (_exports, _equipmentWireType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _equipmentWireType.default.extend({});

  _exports.default = _default;
});