define("securitytrax-ember-ui/models/funder-adt-class-level-row", ["exports", "securitytrax-ember-ui/models/base_models/funder-adt-class-level-row"], function (_exports, _funderAdtClassLevelRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _funderAdtClassLevelRow.default.extend({});

  _exports.default = _default;
});