define("securitytrax-ember-ui/customers/notes/edit-create-note/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-concurrency", "securitytrax-ember-ui/mixins/subroute-modal", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _authenticatedRouteMixin, _emberConcurrency, _subrouteModal, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _subrouteModal.default, _routePageviewLogging.default, {
    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['customer_notes.view']);
    },

    model({
      nid
    }) {
      this.set('modelId', nid);
      return this.setupModelTask.perform();
    },

    setupModelTask: (0, _emberConcurrency.task)(function* () {
      var parentModel = yield this.modelFor(this.parentRoute);
      return Ember.RSVP.hash({
        customer: parentModel.customer,
        customerNoteId: this.modelId,
        parentRoute: this.transitionRoute
      });
    })
  });

  _exports.default = _default;
});