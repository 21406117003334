define("securitytrax-ember-ui/components/multi-step-form/forms/administration/users/my-inventory/spillage/edit-spillage/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/switchable-form-step", "securitytrax-ember-ui/mixins/list/row-list", "moment"], function (_exports, _emberConcurrency, _switchableFormStep, _rowList, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, _rowList.default, {
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    store: Ember.inject.service(),
    inventoryService: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    sessionData: Ember.inject.service('sessionData'),
    spillageEquipmentLineItems: null,
    equipment: null,
    spillageEquipmentLineItemDeleteId: false,
    relatedDocuments: null,
    equipmentReturnReason: null,
    recipients: null,
    recipient: null,
    dataLoaded: false,
    lineItemsToCreate: null,
    formStarted: false,
    recipientSelected: false,
    completedStatuses: null,
    totalItemCount: 0,
    formSteps: Ember.computed('formMode', 'multiStepForm.formCopiedModels.equipmentPurchaseOrder.model.equipmentVendor.id', 'recipient.id', function () {
      var intl = this.intl;
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: intl.t("pages.administration.company_settings.inventory.spillage.review_title", {
            recipient: this.get('recipient.fname') ? this.get('recipient.fname') + ' ' + this.get('recipient.lname') : this.get('recipient.name')
          }),
          stepComponentPath: 'multi-step-form/forms/administration/users/my-inventory/spillage/review'
        })
      });
    }),
    setCompletedStatuses: (0, _emberConcurrency.task)(function* () {
      var status = this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model.status');
      var createdByUser = yield this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model.createdByUser');
      var createdBy = createdByUser && createdByUser.get('id') > 0(createdByUser.get('fname') + ' ' + createdByUser.get('lname'));
      return {
        'open': {
          'closed': true,
          'secondary_text': (0, _moment.default)(this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model.createdAt')).format('MM/DD/YYYY h:mm a') + '<br>' + createdBy
        },
        'submitted': {
          'closed': status !== 'open',
          'secondary_text': ''
        },
        'closed': {
          'closed': status === 'closed',
          'secondary_text': ''
        }
      };
    }),
    getLineItemCount: (0, _emberConcurrency.task)(function* () {
      var lineItems = yield this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model.pendingEquipmentLineItems');
      var count = 0;
      lineItems.forEach(item => {
        if (item.quantity) {
          count += parseInt(item.quantity);
        }
      });
      this.set('totalItemCount', count);
    }),
    initFormData: (0, _emberConcurrency.task)(function* () {
      var hashedData = yield (0, _emberConcurrency.hash)({
        waitForFinishedCopyModels: (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true),
        recipients: this.inventoryService.get('getInventoryRecipients').perform(),
        equipmentReturnReason: this.store.findAll('equipmentReturnReason'),
        equipment: yield this.getUserEquipment.perform()
      });
      var lineItems = yield this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model.pendingEquipmentLineItems');
      var status = this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model.status');
      var createdByUser = this.multiStepForm.formOriginalModelsGetVal('equipmentSpillage.model.createdByUser');
      this.set('completedStatuses', {
        'open': {
          'closed': true,
          'secondary_text': (0, _moment.default)(this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model.createdAt')).format('MM/DD/YYYY h:mm a') + '<br>' + (createdByUser && createdByUser.get('id') > 0 ? createdByUser.get('fname') + ' ' + createdByUser.get('lname') : '')
        },
        'submitted': {
          'closed': status !== 'open',
          'secondary_text': ''
        },
        'closed': {
          'closed': status === 'closed',
          'secondary_text': ''
        }
      });
      yield this.getLineItemCount.perform();
      this.set('spillageEquipmentLineItems', lineItems);
      this.spillageEquipmentLineItems.forEach(item => {
        item.set('receivedQty', 0);
        item.set('difference', item.get('quantity') - item.get('fulfilledQuantity'));
        item.set('absoluteValueDifference', Math.abs(item.get('difference')));
      });
      this.set('listArray', lineItems);
      this.listArray.forEach(item => {
        item.set('hasBeenSaved', true);
      });
      var copiedModel = this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model');
      this.set('recipient', this.spillage ? copiedModel.get('recipient') : copiedModel.get('user') ? copiedModel.get('user') : copiedModel.get('location'));

      if (copiedModel.get('status') === 'open') {
        this.addRow();
      }

      if (copiedModel.get('status') === 'open' && this.grants.getIndividualGrant('equipment_spillage.modify')) {
        this.multiStepForm.registerStepData("step1", 'buttons', [{
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveSpillageData.perform(this.multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary po-review-save-button'
        }]);
      }

      this.set('recipients', hashedData.recipients);
      this.set('equipmentReturnReason', hashedData.equipmentReturnReason);
      this.set('equipment', hashedData.equipment);
      this.set('dataLoaded', true);
    }).on('init'),
    getUserEquipment: (0, _emberConcurrency.task)(function* () {
      var hashedData = yield (0, _emberConcurrency.hash)({
        equipmentInventory: this.user.invokeAction('get_equipment_inventory', {
          verb: 'GET'
        }),
        companyEquipment: this.store.query('equipment', {
          limit: 0,
          sort: 'name',
          filter: 'active=true'
        })
      });
      var userEquipment = hashedData.equipmentInventory.responsePayload.data.filter(item => {
        return item.on_hand_quantity > 0;
      });
      var returnEquipment = Ember.A();

      var _loop = function (x) {
        var userItem = userEquipment.objectAt(x);
        var pushItem = hashedData.companyEquipment.find(piece => piece.get('id') == userItem.equipment_id);
        pushItem.set('userItemInfo', userItem);

        if (pushItem) {
          returnEquipment.push(pushItem);
        }
      };

      for (var x = 0; x < userEquipment.length; x++) {
        _loop(x);
      }

      return returnEquipment;
    }),
    deleteSpillageLineItemTask: (0, _emberConcurrency.task)(function* (row) {
      yield this.deleteRow.perform(row, 'pendingEquipmentLineItem');
      this.multiStepForm.formCopiedModelsSetVal('equipmentSpillage.model.pendingEquipmentLineItems', this.listArray);
      this.set('spillageEquipmentLineItemDeleteId', false);
    }),
    saveSpillageData: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      this.prepForValidate('pendingEquipmentLineItem');
      multiStepForm.formCopiedModelsSetVal('equipmentSpillage.model.pendingEquipmentLineItems', this.trueListArray);
      yield multiStepForm.validateSyncSaveAll({
        notificationNamespace: 'modal',
        extraValidationData: {
          noValidate: this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model.user.id') ? ['equipmentSpillage-location'] : ['equipmentSpillage-user']
        }
      });
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        multiStepForm.formStartedSetVal(false);
      });
    }),
    actions: {
      deleteSpillageEquipmentLineItem(row) {
        this.deleteSpillageLineItemTask.perform(row);
      },

      updateEquipmentItemsList(row, index) {
        var lineItem = row;

        if (row.get('equipment')) {
          if (row.get('quantity') > row.get('equipment').userItemInfo.on_hand_quantity) {
            row.set('quantity', row.get('equipment').userItemInfo.on_hand_quantity);
          }
        }

        lineItem.set('comments', row.get('comments'));
        lineItem.set('fulfilledQuantity', row.get('fulfilledQuantity') || 0);
        lineItem.set('cost', row.get('equipment.cost'));
        lineItem.set('quantity', row.get('quantity') > 0 ? row.get('quantity') : 1);
        lineItem.set('returnReason', row.get('equipmentReturnReason'));
        this.updateRow(row, index);
        this.multiStepForm.formCopiedModelsSetVal('equipmentSpillage.model.pendingEquipmentLineItems', this.listArray);
        this.getLineItemCount.perform();
      }

    }
  });

  _exports.default = _default;
});