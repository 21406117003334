define("securitytrax-ember-ui/components/pages/customers/customer-contacts/component", ["exports", "securitytrax-ember-ui/mixins/sortable-list-mixin", "securitytrax-ember-ui/mixins/property-saver-mixin"], function (_exports, _sortableListMixin, _propertySaverMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_sortableListMixin.default, _propertySaverMixin.default, {
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    langs: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    grants: Ember.inject.service(),
    tagName: '',
    formMode: 'edit',
    agencyEditDisabled: Ember.computed('grants.userGrants', function () {
      var grants = this.grants;
      return !grants.getIndividualGrant('agency_contacts.modify');
    })
  });

  _exports.default = _default;
});