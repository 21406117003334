define("securitytrax-ember-ui/models/base_models/docusign-custom-field", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    accountId: (0, _model.attr)('number', {
      'readOnly': false
    }),
    fieldId: (0, _model.attr)('number', {
      'readOnly': false
    }),
    envelopeId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    show: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    required: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    customFieldType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['textCustomFields', 'listCustomFields']
    }),
    name: (0, _model.attr)('string', {
      'readOnly': false
    }),
    value: (0, _model.attr)('string', {
      'readOnly': false
    }),
    listItems: (0, _model.attr)('json', {
      'readOnly': false
    }),
    docusignEnvelope: (0, _model.belongsTo)('docusign-envelope', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});