define("securitytrax-ember-ui/models/base_models/company-license-transaction", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    issueDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    term: (0, _model.attr)('number', {
      'readOnly': false
    }),
    termPeriod: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['days', 'months', 'years']
    }),
    expirationDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    isPhotoReceived: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    isPhotoSubmitted: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    isFingerprintReceived: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    isFingerprintSubmitted: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    fingerprintFeePaidDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    fingerprintFee: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    isNewBadgeIssued: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    trainingDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    isTrainingComplete: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    ceuHours: (0, _model.attr)('number', {
      'readOnly': false
    }),
    ceuHoursCompleted: (0, _model.attr)('number', {
      'readOnly': false
    }),
    ceuDueDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    applicationReceivedDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    applicationSubmittedDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    applicationFeePaid: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    applicationFeePaidDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    activationFeePaid: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    activationFeePaidDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    renewalDeadline: (0, _model.attr)('number', {
      'readOnly': false
    }),
    renewalDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    notes: (0, _model.attr)('string', {
      'readOnly': false
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    governmentLicenseStatus: (0, _model.belongsTo)('government-license-status', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    companyLicense: (0, _model.belongsTo)('company-license', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});