define("securitytrax-ember-ui/models/customer-task", ["exports", "securitytrax-ember-ui/models/base_models/customer-task", "moment"], function (_exports, _customerTask, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerTask.default.extend({
    intl: Ember.inject.service(),

    /**
     * Holds the UI selection that is later transformed into a communication
     * record. Living in the model for integration with the validations system.
     *
     * @type {String}
     **/
    title: Ember.computed('subject', 'noteType', function () {
      return "".concat(this.get('noteType.name'), " | ").concat(this.subject || '');
    }),
    titleAndType: Ember.computed('subject', 'noteType', function () {
      return "".concat(this.get('noteType.name'), " | ").concat(this.subject || '', " | ").concat(this.intl.t('common.columns.task'));
    }),
    isHighPriority: Ember.computed('priority', function () {
      return this.priority === 3;
    }),
    isOverdue: Ember.computed('dueDate', function () {
      if (this.dueDate) {
        var dueDate = _moment.default.isMoment(this.dueDate) ? this.dueDate.endOf('day') : (0, _moment.default)(this.dueDate).endOf('day');
        return (0, _moment.default)().isAfter(dueDate);
      }

      return false;
    }),
    isDueLaterToday: Ember.computed('dueDate', 'isOverdue', function () {
      if (this.dueDate) {
        var dueDate = _moment.default.isMoment(this.dueDate) ? this.dueDate.endOf('day') : (0, _moment.default)(this.dueDate).endOf('day');
        return !this.isOverdue && dueDate.isSame((0, _moment.default)(), 'day');
      }

      return false;
    }),
    priorityStatus: Ember.computed('priority', function () {
      var priority = this.priority;

      if (priority === 3) {
        return this.intl.t('pages.administration.all_tasks.priority_high');
      } else if (priority === 2) {
        return this.intl.t('pages.administration.all_tasks.priority_med');
      } else if (priority === 1) {
        return this.intl.t('pages.administration.all_tasks.priority_low');
      } else {
        return this.intl.t('pages.administration.all_tasks.priority_none');
      }
    }),
    taskStatusIcon: Ember.computed('isOverdue', 'isClosed', function () {
      if (this.isClosed) {
        return 'icn-circle-check';
      } else if (this.isOverdue) {
        return 'icn-overdue';
      }

      return 'icn-in-progress';
    }),
    calculatedColor: Ember.computed('priority', function () {
      var priority = this.priority;

      switch (priority) {
        case 3:
          return '#d92c29';

        case 2:
          return '#f58220';

        case 1:
          return '#26b844';

        default:
          return '#999';
      }
    })
  });

  _exports.default = _default;
});