define("securitytrax-ember-ui/components/multi-step-form/forms/administration/users/payee-assignments/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency", "securitytrax-ember-ui/utils/strings"], function (_exports, _switchableFormStep, _emberConcurrency, _strings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    payrollRoleId: null,
    payrollRole: null,
    payrollRoleFunction: null,
    confirmDeleteModalOpen: false,
    selectedLevelId: Ember.computed.alias('multiStepForm.formCopiedModels.payablePayeeLevel.model.payrollRoleFunctionLevel.id'),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    payrollRoles: Ember.computed(function () {
      return this.store.findAll('payrollRole', {
        include: 'payroll_list_methods.payroll_role_functions.payroll_role_function_levels.payroll_column'
      });
    }),
    payrollRoleOptions: Ember.computed(function () {
      return this.payrollRoleOptionsTask.perform();
    }),
    payrollRoleOptionsTask: (0, _emberConcurrency.task)(function* () {
      var payrollRoleOptions = yield this.payrollRoles;
      var choices = Ember.A([]);

      for (var i = 0; i < payrollRoleOptions.get('length'); i++) {
        var payrollRole = payrollRoleOptions.objectAt(i);

        if (!this.object.excludedPayrollRoleIds.includes(payrollRole.get('id'))) {
          choices.pushObject(Ember.Object.create({
            packageName: payrollRole.get('name'),
            packageId: payrollRole.get('id')
          }));
        }
      }

      if (!this.payrollRoleId && this.isNewRecord) {
        this.set('payrollRoleId', choices[0].get('packageId'));
      }

      return choices;
    }),
    payrollRoleFunctions: Ember.computed('payrollRoleId', function () {
      this.set('payrollRoleFunction', this.payrollRoleId ? null : this.payrollRoleFunction);
      var functionOptions = [];

      if (this.payrollRoleId > 0 || this.payrollRole) {
        functionOptions = this.availablePayrollRoleFunctions.filterBy('payrollRole.id', this.payrollRoleId);

        if (!this.isNewRecord) {
          functionOptions.push(this.payrollRoleFunction);
        }
      }

      return functionOptions;
    }),
    payrollRoleFunctionLevelOptions: Ember.computed('payrollRoleFunction', function () {
      return this.payrollRoleFunctionLevelOptionsTask.perform();
    }),
    payrollRoleFunctionLevelOptionsTask: (0, _emberConcurrency.task)(function* () {
      var _this = this;

      var choices = Ember.A();
      var levels = yield this.get('payrollRoleFunction.payrollRoleFunctionLevels');

      if (this.payrollRoleFunction && levels.get('length') > 0) {
        for (var i = 0; i < levels.get('length'); i++) {
          var level = levels.objectAt(i);

          if (level.get('id') === this.selectedLevelId || level.get('active')) {
            yield* function* () {
              var lineItems = yield level.get('payrollColumnsDerived'); // TODO: Fix yield in for

              var packageContent = [];
              lineItems.forEach(lineItem => {
                if (lineItem.get('active')) {
                  packageContent.pushObject({
                    label: lineItem.get('name'),
                    description: lineItem.get('about')
                  });
                }
              });
              choices.pushObject(Ember.Object.create({
                packageName: level.get('name'),
                subTitle: _this.intl.t('pages.users.human_resources.payee_assignments.line_items'),
                packageId: level.get('id'),
                packageContent
              }));
            }();
          }
        }
      }

      if (choices.get('length') > 0 && !this.selectedLevelId) {
        this.set('selectedLevelId', choices[0].get('packageId'));
        this.registerButtons();
      }

      return choices;
    }),
    initData: Ember.computed(function () {
      return this.initDataTask.perform();
    }),
    initDataTask: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = this.multiStepForm;
      yield (0, _emberConcurrency.hash)({
        listMethods: this.store.findAll('payrollListMethod', {
          include: 'payroll_role_functions'
        }),
        waitForFinishedCopyModels: (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true)
      });

      if (!this.isNewRecord) {
        var functionLevel = yield this.store.findRecord('payrollRoleFunctionLevel', this.selectedLevelId);
        var payrollFunction = yield functionLevel.get('payrollRoleFunction');
        var payrollRole = yield payrollFunction.get('payrollRole');
        this.set('payrollRole', payrollRole);
        this.set('payrollRoleFunction', payrollFunction);
        yield this.payrollRoleOptions;
        yield this.payrollRoleFunctions;
        yield this.payrollRoleFunctionLevelOptions;
      }

      this.registerButtons(this.isNewRecord);
    }),
    savePayeeAssignment: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var level = yield this.store.findRecord('payrollRoleFunctionLevel', this.selectedLevelId);
      var formattedObjects = [];
      formattedObjects.pushObject(Ember.Object.create({
        model: {
          payrollRoleFunction: this.payrollRoleFunction,
          payrollRoleFunctionLevel: level
        },
        filePath: 'payables/payee-assignment'
      }));
      yield multiStepForm.validateList.perform(formattedObjects);
      multiStepForm.formCopiedModelsSetVal('payablePayeeLevel.model.payrollRoleFunctionLevel', level);
      multiStepForm.formCopiedModelsSetVal('payablePayeeLevel.model.' + (0, _strings.snakeToCamelCase)(this.object.entityType), this.object);

      if (this.isNewRecord) {
        var toCreateObjects = yield multiStepForm.validateCreateAll();
        var levelAssignment = this.store.createRecord('payablePayeeLevel', toCreateObjects.payablePayeeLevel.data);
        yield toCreateObjects.payablePayeeLevel.saveWithRollbackAndNotification(levelAssignment, 'modal');
      } else {
        yield multiStepForm.validateSyncSaveAll({
          notificationNamespace: 'modal'
        });
      }

      buttonPromise.then(() => {
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        multiStepForm.formStartedSetVal(false);

        if (this.refreshList) {
          this.refreshList();
        }
      });
    }),
    deletePayeeAssignment: (0, _emberConcurrency.task)(function* (buttonPromise) {
      try {
        var payeeAssignment = this.get("multiStepForm.formOriginalModels.payablePayeeLevel.model");
        yield payeeAssignment.destroyRecordWithRollbackTask.perform();
        buttonPromise.then(() => {
          this.multiStepForm.formStartedSetVal(false);
          this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');

          if (this.refreshList) {
            this.refreshList();
          }
        });
      } catch (err) {
        (false && Ember.warn('error in deletePayeeAssignment: ' + err));
        throw err;
      }
    }),

    registerButtons(disabled = false) {
      var multiStepForm = this.multiStepForm;
      var buttons = [];

      if (this.formMode !== 'view') {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.savePayeeAssignment.perform(multiStepForm, buttonPromise);
          },
          disabled,
          classNamesString: 'st-btn st-btn-primary payee-assignment-save-button'
        });
      }

      if (!this.isNewRecord && this.grants.getIndividualGrant('payable_payee_levels.delete')) {
        buttons.push({
          text: this.intl.t('common.actions.delete'),
          click: () => {
            this.set('confirmDeleteModalOpen', true);
          },
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
        });
      }

      multiStepForm.registerStepData('step1', 'buttons', buttons);
    }

  });

  _exports.default = _default;
});