define("securitytrax-ember-ui/components/multi-step-form/forms/administration/users/permissions/edit-policies/component", ["exports", "securitytrax-ember-ui/mixins/forms/resource-type-policies-reusable", "securitytrax-ember-ui/mixins/switchable-form-step", "lodash", "ember-concurrency"], function (_exports, _resourceTypePoliciesReusable, _switchableFormStep, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_resourceTypePoliciesReusable.default, _switchableFormStep.default, {
    store: Ember.inject.service(),
    modelName: 'user',
    resourceTypePolicyName: 'userResourceTypePolicy',
    userPoliciesOverrideToggle: Ember.computed('existingUserPolicyOverride', function () {
      var existingOverride = this.existingUserPolicyOverride;

      if (existingOverride) {
        return true;
      }

      return false;
    }),
    resourceTypePoliciesList: Ember.computed('multiStepForm.formCopiedModels.user.model.userResourceTypePolicies', function () {
      return this.resourceTypePoliciesListTask.perform();
    }),
    resourceTypePoliciesListTask: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = this.multiStepForm;
      yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels');
      var userResourceTypePolicies = this.multiStepForm.formCopiedModelsGetVal('user.model.userResourceTypePolicies');
      var resourceType = this.resource;
      var allPromises = [];
      var filteredUserResourceTypePolicies = userResourceTypePolicies ? userResourceTypePolicies.filter(function (policy) {
        return policy.get('deletedAt') == null && policy.get('resourceTypePolicy.resourceTypeId') == resourceType.get('id');
      }) : [];
      filteredUserResourceTypePolicies.forEach(item => {
        allPromises.push(this.setupPolicyOptions.perform(item));
      });
      yield (0, _emberConcurrency.all)(allPromises);
      this.set('listArray', filteredUserResourceTypePolicies);
      this.emptyListItem();
      return filteredUserResourceTypePolicies;
    }),
    setupPolicyOptions: (0, _emberConcurrency.task)(function* (item) {
      var resourceTypePolicyMeta = item.get('resourceTypePolicy.responseMeta');
      var trueItem = yield this.store.findRecord('resourceTypePolicy', item.get('resourceTypePolicy.id'));
      item.set('deletingClass', '');
      item.set('operatorsList', this.setupOperatorsList(_lodash.default.get(resourceTypePolicyMeta, 'allowed_operators', [])));
      var possibleOptions = resourceTypePolicyMeta.has_selection_list ? yield this.getSelectionListTask.perform(trueItem) : [];
      possibleOptions.forEach(option => {
        if (option.translation_key) {
          Ember.set(option, 'name', this.intl.t('policy_selections.' + option.translation_key + '.name'));
        }
      });
      item.set('conditionRightOptionsList', possibleOptions);

      if (item.get('operator') == 'IN') {
        var conditionRightValue = item.get('conditionRight').value;
        var selectedOptions = possibleOptions.filter(option => {
          return _lodash.default.includes(conditionRightValue, option.value);
        });
        item.set('multipleSelected', selectedOptions);
      }
    })
  });

  _exports.default = _default;
});