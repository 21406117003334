define("securitytrax-ember-ui/components/calendar-glimmer/calendar-event/event-status-pill/component", ["exports", "@glimmer/component", "moment", "ember-concurrency"], function (_exports, _component, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EventStatus = (_dec = Ember.inject.service(), _dec2 = Ember.inject.service('events/calendar-events'), _dec3 = Ember.computed('args.appointmentStatusData.check_out'), _dec4 = Ember.computed('args.appointmentStatusData.check_in'), _dec5 = Ember.computed('calendarEvent'), _dec6 = (0, _emberConcurrency.task)(function* () {
    var appointmentTimeStarted = this.appointmentTimeStarted;
    var appointmentEndTimePassed = this.appointmentEndTimePassed;
    var calendarEventStatusObject = Ember.Object.create({
      hidePill: false
    });
    var appointmentStatusData = yield this.args.appointmentStatusData;

    if (appointmentStatusData) {
      if (!appointmentTimeStarted) {
        //before appointment
        //not started
        //on the way -display status no pill
        if (appointmentStatusData.check_out) {
          if (appointmentStatusData.on_time_depart) {
            calendarEventStatusObject.colorClass = 'event-status__glance--green';
            calendarEventStatusObject.icon = 'icn-check';
            calendarEventStatusObject.text = this.intl.t('pages.calendar.done');
          } else {
            calendarEventStatusObject.colorClass = 'event-status__glance--yellow';
            calendarEventStatusObject.icon = 'icn-check';
            calendarEventStatusObject.text = this.intl.t('pages.calendar.done');
          }
        } else if (appointmentStatusData.check_in) {
          if (appointmentStatusData.on_time_arrival) {
            calendarEventStatusObject.colorClass = 'event-status__glance--green';
            calendarEventStatusObject.icon = 'icn-overdue';
            calendarEventStatusObject.text = this.intl.t('pages.calendar.on_time');
          } else {
            calendarEventStatusObject.colorClass = 'event-status__glance--yellow';
            calendarEventStatusObject.icon = 'icn-overdue';
            calendarEventStatusObject.text = this.intl.t('pages.calendar.late');
          }
        } else {
          calendarEventStatusObject.hidePill = true;
        }
      } else if (appointmentTimeStarted && !appointmentEndTimePassed) {
        //during appointment window
        //not started-late-not on way -red pill with X late
        //arrived on time - green clock - on time
        //arrived late - yellow clock - late
        if (appointmentStatusData.check_in) {
          if (appointmentStatusData.on_time_arrival) {
            calendarEventStatusObject.colorClass = 'event-status__glance--green';
            calendarEventStatusObject.icon = 'icn-overdue';
            calendarEventStatusObject.text = this.intl.t('pages.calendar.on_time');
          } else {
            calendarEventStatusObject.colorClass = 'event-status__glance--yellow';
            calendarEventStatusObject.icon = 'icn-overdue';
            calendarEventStatusObject.text = this.intl.t('pages.calendar.late');
          }
        } else {
          calendarEventStatusObject.colorClass = 'event-status__glance--red';
          calendarEventStatusObject.icon = 'icn-close';
          calendarEventStatusObject.text = this.intl.t('pages.calendar.late');
        }
      } else if (appointmentEndTimePassed) {
        // after appointment window
        //checked out on time - green - check done
        //checked out Late - yellow -check Done
        //not Checked out - RED - X LATE
        //not Checked out/in - Red X LATE 
        if (appointmentStatusData.check_out) {
          if (appointmentStatusData.on_time_depart) {
            calendarEventStatusObject.colorClass = 'event-status__glance--green';
            calendarEventStatusObject.icon = 'icn-check';
            calendarEventStatusObject.text = this.intl.t('pages.calendar.done');
          } else {
            calendarEventStatusObject.colorClass = 'event-status__glance--yellow';
            calendarEventStatusObject.icon = 'icn-check';
            calendarEventStatusObject.text = this.intl.t('pages.calendar.done');
          }
        } else {
          calendarEventStatusObject.colorClass = 'event-status__glance--red';
          calendarEventStatusObject.icon = 'icn-close';
          calendarEventStatusObject.text = this.intl.t('pages.calendar.late');
        }
      }
    }

    return calendarEventStatusObject; //appointment time has started and tech is late
    //appointment time has started and  durration has passed. return X 
  }), (_class = (_temp = class EventStatus extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "CalendarEventsService", _descriptor2, this);

      _initializerDefineProperty(this, "calendarEventStatusObjectTask", _descriptor3, this);
    }

    get appointmentTimeStarted() {
      var currentTime = (0, _moment.default)();
      var calendarEventStartTime = this.args.calendarEvent.startTime;

      if (currentTime.isSameOrAfter(calendarEventStartTime)) {
        return true;
      }

      return false;
    }

    get appointmentEndTimePassed() {
      var currentTime = (0, _moment.default)();
      var calendarEventEndTime = (0, _moment.default)(this.args.calendarEvent.startTime).add(this.args.calendarEvent.duration, 's');

      if (currentTime.isSameOrAfter(calendarEventEndTime)) {
        return true;
      }

      return false;
    }

    get checkOutTime() {
      if (this.args.appointmentStatusData) {
        return _moment.default.unix(this.args.appointmentStatusData.check_out);
      }

      return null;
    }

    get checkInTime() {
      if (this.args.appointmentStatusData) {
        return _moment.default.unix(this.args.appointmentStatusData.check_in);
      }

      return null;
    }

    get appointmentStatusDataObject() {
      return this.calendarEventStatusObjectTask.perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "CalendarEventsService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "checkOutTime", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "checkOutTime"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkInTime", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "checkInTime"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "appointmentStatusDataObject", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "appointmentStatusDataObject"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "calendarEventStatusObjectTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = EventStatus;
});