define("securitytrax-ember-ui/components/calendar-glimmer/calendar-scheduling/base-scheduler", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var BaseScheduler = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = (0, _emberConcurrency.task)(function* () {
    var unfilteredProfiles = yield this.store.findAll('schedulingProfile');
    var customerIsLead = (yield this.args.customer.get('isLead')) || this.args.userType === 'sales_rep';
    var returnArr = unfilteredProfiles.filter(profile => {
      if (profile.active === false) {
        return false;
      }

      if (this.args.formMode == 'view' || this.args.currFormName == 'customerCreateWizard') {
        if (profile.responseResourceType != 'historical') {
          return customerIsLead ? profile.profileType === 'sales' : profile.profileType === 'tech';
        }
      } else {
        return customerIsLead ? profile.profileType === 'sales' : profile.profileType === 'tech';
      }
    });
    return returnArr;
  }), _dec6 = (0, _emberConcurrency.task)(function* () {
    if (!this.selectedSchedulingProfileId) {
      var hashedData = yield (0, _emberConcurrency.hash)({
        choices: this.schedulingProfileChoices,
        schedulingProfileDefaults: this.sessionData.getCompanyDefaults('schedulingProfileDefault')
      });

      if (hashedData.choices) {
        var defaultChoice = hashedData.choices.findBy('packageId', hashedData.schedulingProfileDefaults.get('schedulingProfileId'));
        var choice = hashedData.choices.length > 0 ? defaultChoice ? defaultChoice.packageId : hashedData.choices[0].packageId : null;
        return choice;
      }

      return null;
    }

    return null;
  }), _dec7 = (0, _emberConcurrency.task)(function* () {
    var schedulingProfiles = yield this.schedulingProfilesTask.perform();
    return schedulingProfiles.findBy('id', yield this.selectedSchedulingProfileId);
  }), _dec8 = (0, _emberConcurrency.task)(function* () {
    try {
      var schedulingProfiles = yield this.schedulingProfilesTask.perform();
      var choices = Ember.A([]);

      for (var i = 0; i < schedulingProfiles.get('length'); i++) {
        var currSchedulingProfile = schedulingProfiles.objectAt(i);

        if (this.args.currFormName !== 'duplicateCalendarEventWithScheduler' && this.args.currFormName !== 'customerCreateWizard' || currSchedulingProfile.get('responseResourceType') !== 'historical') {
          choices.pushObject(Ember.Object.create({
            packageName: currSchedulingProfile.get('name'),
            packageId: currSchedulingProfile.get('id'),
            responseResourceType: currSchedulingProfile.get('responseResourceType')
          }));
        }
      }

      return choices;
    } catch (error) {
      (false && Ember.warn('failed to get schedulingProfileChoicesTask' + error, {
        id: 'calendar.calendarScheduling.schedulingProfileChoicesTask'
      }));
    }
  }), (_class = (_temp = class BaseScheduler extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "sessionData", _descriptor2, this);

      _initializerDefineProperty(this, "failedToLoad", _descriptor3, this);

      _initializerDefineProperty(this, "selectedSchedulingProfileId", _descriptor4, this);

      _initializerDefineProperty(this, "schedulingProfilesTask", _descriptor5, this);

      _initializerDefineProperty(this, "selectedSchedulingProfileIdTask", _descriptor6, this);

      _initializerDefineProperty(this, "selectedSchedulingProfileTask", _descriptor7, this);

      _initializerDefineProperty(this, "schedulingProfileChoicesTask", _descriptor8, this);

      var zip = this.args.customer.get('zip');

      if (!zip) {
        this.failedToLoad = true;
      }

      this.selectedSchedulingProfileId = this.selectedSchedulingProfileIdTask.perform();
    }

    get userType() {
      if (this.args.userType) {
        return this.args.userType;
      }

      return 'tech';
    }

    get selectedSchedulingProfile() {
      if (this.selectedSchedulingProfileId) {
        return this.selectedSchedulingProfileTask.perform();
      }

      return null;
    }

    get schedulingProfileChoices() {
      return this.schedulingProfileChoicesTask.perform();
    }

    get scrollContext() {
      var _this$args$scrollCont;

      return (_this$args$scrollCont = this.args.scrollContext) !== null && _this$args$scrollCont !== void 0 ? _this$args$scrollCont : '.form-content.no-horizontal-scroll';
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "failedToLoad", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectedSchedulingProfileId", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "schedulingProfilesTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "selectedSchedulingProfileIdTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "selectedSchedulingProfileTask", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "schedulingProfileChoicesTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BaseScheduler;
});