define("securitytrax-ember-ui/mixed-in-classes/credit-card-mixin", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CreditCardMixin = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, (_class = (_temp = class CreditCardMixin extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "creditCardTypes", _descriptor, this);

      _initializerDefineProperty(this, "usStates", _descriptor2, this);

      _initializerDefineProperty(this, "months", _descriptor3, this);

      _initializerDefineProperty(this, "expirationYearUsesFullYear", _descriptor4, this);
    }

    creditCardExpirationYears() {
      var d = new Date(),
          currentYear = d.getFullYear(),
          years = [],
          billCcExpirationYear = this.expirationYear;

      if (!isEmpty(billCcExpirationYear) && billCcExpirationYear < currentYear) {
        years.push({
          name: billCcExpirationYear,
          value: billCcExpirationYear
        });
      }

      for (var i = currentYear; i <= currentYear + 10; i++) {
        years.push({
          name: i.toString(),
          value: this.expirationYearUsesFullYear ? i.toString() : i.toString().substring(2, 4)
        });
      }

      return years;
    }

    expirationMonthYear() {
      var month = this.billing.billCcExpirationMonth;
      var year = this.billing.billCcExpirationYear;
      var monthYear = month && year ? month.concat(year) : null;
      Ember.set(this.billing, 'billCcExpiration', monthYear);
      return monthYear;
    }

    cardType() {
      var number = this.billing.get('billCcNumber');
      var card = Cards.fromNumber(number);

      if (card) {
        var billCcType = this.creditCardTypes.cardTypes[card.type];
        Ember.set(this.billing, 'billCcType', billCcType);
        return card.type;
      }

      return 'no-card-type';
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "creditCardTypes", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "usStates", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "months", [services], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "expirationYearUsesFullYear", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class));
  _exports.default = CreditCardMixin;
});