define("securitytrax-ember-ui/components/dashboard-config/live/dashboard-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9ITt0LAQ",
    "block": "{\"symbols\":[\"Component\"],\"statements\":[[10,\"div\"],[15,0,[30,[36,2],[[35,1],\"-card cell dashboard-card--padding\"],null]],[12],[2,\"\\n    \"],[8,\"dashboard-config/card-wrapper\",[[24,0,\"grid-y\"]],[[\"@cardTitle\"],[[34,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[35,4]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,[32,1],[],[[\"@card\"],[[34,0]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"card\",\"cardName\",\"concat\",\"cardTitle\",\"componentToUse\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/dashboard-config/live/dashboard-card/template.hbs"
    }
  });

  _exports.default = _default;
});