define("securitytrax-ember-ui/mixins/components/account-status", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global L */
  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    installationStatus: Ember.computed(function () {
      return this.installationStatusTask.perform();
    }),
    installationStatusTask: (0, _emberConcurrency.task)(function* () {
      var sysinfo = yield this.get('customer.customerSystemInformation');
      var isInstalled = sysinfo ? yield sysinfo.get('isInstalled') : false;
      var isScheduled = sysinfo ? yield sysinfo.get('installCalendarEvent') : false;
      var isCancelled = yield this.customer.get('isCancelled');

      if (isCancelled) {
        return {
          'class': 'cancelled',
          'text': this.intl.t('common.status.canceled')
        };
      } else if (isInstalled) {
        return {
          'class': 'installed',
          'text': this.intl.t('pages.customers.dashboard.account_status.installed')
        };
      } else if (!isInstalled && isScheduled) {
        return {
          'class': 'scheduled',
          'text': this.intl.t('pages.customers.dashboard.account_status.scheduled')
        };
      } else if (!isInstalled && !isScheduled) {
        return {
          'class': 'not-scheduled',
          'text': this.intl.t('pages.customers.dashboard.account_status.not_scheduled')
        };
      } else {
        // no way to ever get this case - DREW TODO - talk to Boo about this
        return {
          'class': 'not-installed',
          'text': this.intl.t('pages.customers.dashboard.account_status.not_installed')
        };
      }
    }),
    centralStationIcon: Ember.computed('customer.customerOrderInformation.monitoringCompany.monitoringCompanyType.name', function () {
      return this.centralStationIconTask.perform();
    }),
    centralStationIconTask: (0, _emberConcurrency.task)(function* () {
      try {
        var customerOrderInformation = yield this.get('customer.customerOrderInformation');
        var monitoringCompany = yield customerOrderInformation.get('monitoringCompany');
        var monitoringCompanyType = yield monitoringCompany.get('monitoringCompanyType');
        var icon = yield monitoringCompanyType.get('logo');
        return icon;
      } catch (err) {
        return '';
      }
    }),
    centralStationStatus: Ember.computed('customer.customerOrderInformation.monitoringCompany.status', function () {
      return this.centralStationStatusTask.perform();
    }),
    centralStationStatusTask: (0, _emberConcurrency.task)(function* () {
      try {
        var customerOrderInformation = yield this.get('customer.customerOrderInformation');
        var monitoringCompany = yield customerOrderInformation.get('monitoringCompany');
        var status = yield monitoringCompany.get('status');

        if (status == 'active') {
          return this.intl.t('common.status.enabled');
        } else {
          return this.intl.t('common.status.notEnabled');
        }
      } catch (err) {
        return this.intl.t('common.status.notEnabled');
      }
    }),
    interactiveStatus: Ember.computed('customer.customerOrderInformation.interactiveServicesProvider.status', function () {
      try {
        if (this.get('customer.customerOrderInformation.interactiveServicesProvider.status') == 'active') {
          return this.intl.t('common.status.enabled');
        } else {
          return this.intl.t('common.status.notEnabled');
        }
      } catch (err) {
        return this.intl.t('common.status.notEnabled');
      }
    }),
    interactiveIcon: Ember.computed('customer.customerOrderInformation.interactiveServicesProvider.interactiveServicesProviderType.id', function () {
      return this.interactiveIconTask.perform();
    }),
    interactiveIconTask: (0, _emberConcurrency.task)(function* () {
      try {
        var interactiveService = yield this.get('customer.customerOrderInformation.interactiveServicesProvider.interactiveServicesProviderType.id');

        if (interactiveService == 1) {
          return 'adc_icon';
        } else if (interactiveService == 2) {
          return 'icn-securenet';
        } else {
          return '';
        }
      } catch (err) {
        return '';
      }
    }),
    salesRep: Ember.computed('customer.customerOrderInformation.repUser', function () {
      return this.get('customer.customerOrderInformation.repUser.flname');
    }),
    installer: Ember.computed('customer.customerSystemInformation.installCalendarEvent.calendarUser', function () {
      return this.installerTask.perform();
    }),
    installerTask: (0, _emberConcurrency.task)(function* () {
      try {
        var installer = yield this.get('customer.customerSystemInformation.installCalendarEvent.calendarUser');

        if (installer) {
          return installer.get('flname');
        } else {
          return 'None';
        }
      } catch (err) {
        return '';
      }
    }),
    installerAvatar: Ember.computed(function () {
      return this.installerAvatarTask.perform();
    }),
    installerAvatarTask: (0, _emberConcurrency.task)(function* () {
      try {
        var avatar = yield this.get('customer.customerSystemInformation.installCalendarEvent.calendarUser.avatar');
        return avatar;
      } catch (err) {
        return '';
      }
    }),
    markerLocation: Ember.computed('lat', 'lng', function () {
      return [this.lat, this.lng];
    }),

    init() {
      this._super(...arguments);

      if (this.get('customer.responseMeta.geopoint.latitude') && this.get('customer.responseMeta.geopoint.longitude')) {
        this.set('lat', this.get('customer.responseMeta.geopoint.latitude'));
        this.set('lng', this.get('customer.responseMeta.geopoint.longitude'));
      }
    },

    locations: Ember.A([]),
    lat: '39.9644136',
    lng: '-75.180083619',
    ico: L.divIcon({
      iconSize: [50, 50],
      iconAnchor: [25, 83],
      popupAnchor: [0, -83],
      className: 'map-pin'
    })
  });

  _exports.default = _default;
});