define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/interactive-services-providers/alula/integration/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/interactive-services-providers/alula/interactive-services-provider-base", "ember-concurrency", "lodash"], function (_exports, _interactiveServicesProviderBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _interactiveServicesProviderBase.default.extend({
    intl: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    manageConnectionFormStarted: false,
    initFormData: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true);
    }).on('init'),

    didInsertElement() {
      this._super(...arguments);

      this.set('environmentOptions', [{
        name: this.intl.t('pages.administration.integrations.interactiveServicesProviders.integrations.production'),
        value: 'production'
      }, {
        name: this.intl.t('pages.administration.integrations.interactiveServicesProviders.integrations.development'),
        value: 'development'
      }, {
        name: this.intl.t('pages.administration.integrations.interactiveServicesProviders.integrations.test'),
        value: 'test'
      }]);
      this.multiStepForm.registerStepData("integration", 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.saveInteractiveServicesProviderConfig.perform(this.multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary interactive-services-providers-save-button'
      }]);
    },

    computedUserName: Ember.computed('multiStepForm.formOriginalModels.interactiveServicesProvider.model.interactiveServicesProviderConfig.username', function () {
      return this.multiStepForm.formOriginalModelsGetVal('interactiveServicesProvider.model.interactiveServicesProviderConfig.username');
    }),
    computedPassword: Ember.computed('multiStepForm.formCopiedModels.interactiveServicesProvider.model.interactiveServicesProviderConfig.responseMeta.attribute_has_saved_value.password', function () {
      var multiStepForm = this.multiStepForm;

      if (_lodash.default.get(multiStepForm, 'formCopiedModels.interactiveServicesProvider.model.interactiveServicesProviderConfig.responseMeta.attribute_has_saved_value.password', false)) {
        return '********';
      } else {
        return null;
      }
    }),
    accountStatusDescription: Ember.computed('multiStepForm.formOriginalModels.interactiveServicesProvider.model.isConnected', function () {
      var intl = this.intl;
      var isp = this.multiStepForm.formOriginalModelsGetVal('interactiveServicesProvider.model');
      return isp && isp.isConnected ? intl.t('pages.administration.integrations.interactiveServicesProviders.integrations.connectedDescription', {
        ispType: 'Alula'
      }) : intl.t('pages.administration.integrations.interactiveServicesProviders.integrations.disconnectedDescription', {
        ispType: 'Alula'
      });
    })
  });

  _exports.default = _default;
});