define("securitytrax-ember-ui/helpers/my-hash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.myHash = myHash;
  _exports.default = void 0;

  // Drop-in replacement on hash template helper, as it has a bug in this kind of usage:
  // https://github.com/emberjs/ember.js/issues/14738
  function myHash(params, hash) {
    return Ember.assign({}, hash);
  }

  var _default = Ember.Helper.helper(myHash);

  _exports.default = _default;
});