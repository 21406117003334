define("securitytrax-ember-ui/components/calendar-glimmer/calendar-scheduling/component", ["exports", "ember-concurrency", "moment", "securitytrax-ember-ui/components/calendar-glimmer/calendar-scheduling/base-scheduler"], function (_exports, _emberConcurrency, _moment, _baseScheduler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CalendarScheduling = (_dec = Ember.inject.service(), _dec2 = Ember.inject.service(), _dec3 = Ember.inject.service(), _dec4 = Ember.inject.service('events/calendar-events'), _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember.computed('calendarEvent'), _dec11 = Ember.computed('calendarEvent'), _dec12 = (0, _emberConcurrency.task)(function* () {
    var isDesktop = this.screen.get('isDesktop');
    var selectedSchedulingProfile = yield this.selectedSchedulingProfile;
    var isBasicForm = (isDesktop || selectedSchedulingProfile.get('responseResourceType') == 'calendar_slots') && selectedSchedulingProfile.get('responseResourceType') != 'historical';
    return isBasicForm;
  }), _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, (_class = (_temp = class CalendarScheduling extends _baseScheduler.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "grants", _descriptor, this);

      _initializerDefineProperty(this, "screen", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "CalendarEventsService", _descriptor4, this);

      _initializerDefineProperty(this, "calendarEvent", _descriptor5, this);

      _initializerDefineProperty(this, "calendarEventFormStarted", _descriptor6, this);

      _initializerDefineProperty(this, "newCalendarEvent", _descriptor7, this);

      _initializerDefineProperty(this, "calendarEventFormMode", _descriptor8, this);

      _initializerDefineProperty(this, "addCalendarEventFormStarted", _descriptor9, this);

      _initializerDefineProperty(this, "basicFormModeTask", _descriptor10, this);

      this.addCalendarEventFormStarted = false;
      this.workOrder = this.args.multiStepForm.formOriginalModels.workOrder ? this.args.multiStepForm.formOriginalModels.workOrder.model : this.args.workOrder;

      if (this.args.isReschedule) {
        this.calendarEvent = this.args.duplicateAppointment;
      }
    }

    get calendarEventFormOriginalModels() {
      return Ember.Object.create({
        calendarEvent: {
          model: this.calendarEvent,
          copyRelationMap: {
            calendarEventType: {
              doValidate: false,
              doSave: false
            },
            calendarEventDetailType: {
              doCopy: true,
              doValidate: false,
              doSave: false
            },
            calendarUser: {
              doCopy: this.grants.getIndividualGrant('calendar_users.view'),
              doValidate: false,
              doSave: false
            },
            workOrder: {
              doCopy: this.duplicateAppointment,
              doValidate: false,
              doSave: false
            }
          }
        }
      });
    }

    get calendarEventFormSteps() {
      var intl = this.intl;
      return Ember.Object.create({
        calendarEventSingleStep: Ember.Object.create({
          stepNameKey: 'calendarEventSingleStep',
          title: this.args.isReschedule ? intl.t('pages.customers.appointments.reschedule', {
            date: (0, _moment.default)(this.calendarEvent.startTime).format('MM/DD/YYYY'),
            type: this.calendarEvent.calendarEventType.get('name'),
            name: this.args.customer.get('flname')
          }) : intl.t('pages.customers.appointments.create', {
            name: this.args.customer.get('flname')
          }),
          stepComponentPath: 'multi-step-form/forms/calendar-event'
        })
      });
    }

    get basicFormMode() {
      return this.basicFormModeTask.perform();
    }

    setUpNewEvent(newCalendarEvent, addCalendarEventFormStarted) {
      this.newCalendarEvent = newCalendarEvent;

      if (!this.args.isReschedule) {
        this.calendarEvent = null;
        this.calendarEventFormMode = 'create';
      }

      this.addCalendarEventFormStarted = addCalendarEventFormStarted;
    }

    calendarSaved(model) {
      this.CalendarEventsService.trigger('calendar-event-saved', model.saved);
    }

    closeModal() {
      this.addCalendarEventFormStarted = false;
      this.CalendarEventsService.trigger('cancel-add');
    }

    onNewCalendarEvent(pendingCalendarEvent) {
      this.newCalendarEvent = pendingCalendarEvent;

      if (!this.args.isReschedule) {
        this.calendarEvent = null;
        this.calendarEventFormMode = 'create';
      }

      this.addCalendarEventFormStarted = true;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "screen", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "CalendarEventsService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "calendarEvent", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "calendarEventFormStarted", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "newCalendarEvent", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "calendarEventFormMode", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'create';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "addCalendarEventFormStarted", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "calendarEventFormOriginalModels", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "calendarEventFormOriginalModels"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "calendarEventFormSteps", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "calendarEventFormSteps"), _class.prototype), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "basicFormModeTask", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setUpNewEvent", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "setUpNewEvent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "calendarSaved", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "calendarSaved"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onNewCalendarEvent", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "onNewCalendarEvent"), _class.prototype)), _class));
  _exports.default = CalendarScheduling;
});