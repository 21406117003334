define("securitytrax-ember-ui/components/modals/sub-route-modals/check-availibility/component", ["exports", "@glimmer/component", "ember-concurrency", "moment"], function (_exports, _component, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CheckAvailability = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = (0, _emberConcurrency.task)(function* () {
    if (this.args.calendarEventId !== 'create') {
      this.calendarEvent = yield this.store.findRecord('calendarEvent', this.args.calendarEventId);
      this.calendarEventTitle = {
        type: this.calendarEvent.get('calendarEventType.name'),
        date: (0, _moment.default)(this.calendarEvent.get('startTime')).format('MM/DD/YYYY')
      };
      this.customer = this.args.customer ? this.args.customer : yield this.calendarEvent.get('customer');
    } else {
      this.customer = this.args.customer;
    }

    this.formStarted = true;
  }), _dec8 = Ember._action, (_class = (_temp = class CheckAvailability extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "calendarEvent", _descriptor4, this);

      _initializerDefineProperty(this, "customer", _descriptor5, this);

      _initializerDefineProperty(this, "formStarted", _descriptor6, this);

      _initializerDefineProperty(this, "initData", _descriptor7, this);

      this.initData.perform();
    }

    get userType() {
      if (this.calendarEvent) {
        return this.calendarEvent.calendarCategory === 'sale_schedule' ? 'sales_rep' : 'tech';
      }

      return this.args.userType || 'tech';
    }

    get calendarEventTypeIncludeCategories() {
      return {
        'tech_schedule': this.userType === 'tech',
        'sale_schedule': this.userType === 'sales_rep'
      };
    }

    get calendarEventFormSteps() {
      var intl = this.intl;
      return Ember.Object.create({
        calendarEventSingleStep: Ember.Object.create({
          stepNameKey: 'calendarEventSingleStep',
          title: intl.t('pages.customers.appointments.title_check_tech_availabilty', {
            customerName: this.customer.flname
          }),
          stepComponentPath: 'calendar-glimmer/calendar-scheduling/schedulers/tech-availability'
        })
      });
    }

    get calendarEventFormOriginalModels() {
      return Ember.Object.create({
        calendarEvent: {
          model: null
        }
      });
    }

    transitionBackToParentRoute() {
      if (this.args.parentRoute.includes('work-orders')) {
        this.router.transitionTo(this.args.parentRoute, this.args.workOrderId);
      } else if (this.args.parentRoute.includes('customer') || this.args.parentRoute.includes('lead')) {
        this.router.transitionTo(this.args.parentRoute, this.customer.id);
      } else {
        this.router.transitionTo(this.args.parentRoute);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "calendarEvent", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "customer", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "formStarted", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "initData", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "transitionBackToParentRoute", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "transitionBackToParentRoute"), _class.prototype)), _class));
  _exports.default = CheckAvailability;
});