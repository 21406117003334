define("securitytrax-ember-ui/components/lists/data-list/list-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WNZcbZwg",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[10,\"span\"],[15,0,[31,[\"label \",[34,0]]]],[12],[2,\" \"],[1,[34,1]],[2,\" \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"labelClass\",\"label\",\"noLabel\",\"unless\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/lists/data-list/list-item/template.hbs"
    }
  });

  _exports.default = _default;
});