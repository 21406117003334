define("securitytrax-ember-ui/demo/copy-model/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      Ember.setProperties(this, {
        copiedCustomerModel: [],
        customerEquipmentRelationMap: {
          relationships: {
            equipment: {
              relationships: {
                equipmentWireType: {}
              }
            }
          }
        },
        customerRelationMap: {
          relationships: {
            customerContacts: {}
          }
        }
      });
    },

    copiedModel: () => {},
    store: Ember.inject.service(),
    customerContacts: Ember.computed.alias('copiedCustomerModel.customerContacts'),
    actions: {
      copyModel() {
        Ember.RSVP.Promise.resolve(this.get('model.customerEquipment')).then(model => {
          model.deepCopy({
            relationshipMap: this.customerEquipmentRelationMap
          }).then(copy => {
            this.set('copiedModelData', copy.get('equipment.name'));
          });
        });
      },

      syncModel() {
        Ember.RSVP.Promise.resolve(this.get('model.customer')).then(customer => {
          Ember.RSVP.Promise.resolve(customer.get('customerContacts')).then(() => {
            customer.deepSync({
              relationshipMap: this.customerRelationMap,
              copiedObject: this.copiedCustomerModel
            }).then(() => {});
          });
        });
      },

      addCustomerContact() {
        this.customerContacts.addObject(Ember.Object.create({
          name: 'New Contact'
        }));
      }

    }
  });
  var _default = Controller;
  _exports.default = _default;
});