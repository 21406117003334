define("securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/adc/login-information/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/adc/adc-base", "ember-concurrency"], function (_exports, _adcBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adcBase.default.extend({
    intl: Ember.inject.service(),
    ispService: Ember.inject.service(),
    updateLogin: false,

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('loginInformation', 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.updateLoginInformation.perform(multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary login-information-save-button'
      }]);
    },

    allLogins: Ember.computed('customer', function () {
      return this.allLoginsTask.perform();
    }),
    allLoginsTask: (0, _emberConcurrency.task)(function* () {
      var logins = yield this.ispService.get('invokeIspActionTask').perform({
        model: this.customer,
        action: 'alarm_dot_com__getAllLogins',
        attributes: {}
      });
      return logins ? logins.all_logins : [];
    }),
    updateLoginInformation: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      if (this.updateLogin) {
        yield this.updateAccount.perform(multiStepForm, buttonPromise, 'alarm_dot_com__updateCustomerLogin', {
          new_login: this.desiredLoginName
        }, {
          loginName: this.desiredLoginName
        }, [], 'interactive-services-provider/adc-login-information');
      }

      if (this.emailWelcomeLetter) {
        this.sendWelcomeLetterTask.perform();
      }

      try {
        if (this.resetPassword) {
          yield this.ispService.get('invokeIspActionTask').perform({
            model: this.customer,
            action: 'alarm_dot_com__resetCustomerPassword',
            attributes: {}
          });
        }
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
        throw err;
      }

      buttonPromise.then(() => {
        multiStepForm.currentStepNameKeySetVal('customerInformation');
        this.set('customerInformation.login_name', this.desiredLoginName);
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
      });
    })
  });

  _exports.default = _default;
});