define("securitytrax-ember-ui/helpers/hyperlink", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hyperlink = hyperlink;
  _exports.default = void 0;

  function hyperlink(input) {
    var content = input[0]; // BOWLES TODO: Uncomment after BE %% fix.

    /*if(content.content_type.toLowerCase() === 'customer_note') {
        return `customers.notes.edit-create-task`;
    } else */

    if (content.content_type.toLowerCase() === 'customer') {
      return "customers";
    } else if (content.content_type.toLowerCase() === 'user') {
      return "users.information";
    }
  }

  var _default = Ember.Helper.helper(hyperlink);

  _exports.default = _default;
});