define("securitytrax-ember-ui/users/time-off/route", ["exports", "securitytrax-ember-ui/users/base-route", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _baseRoute, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_routePageviewLogging.default, {
    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['calendar_events.view']);
    },

    model(params) {
      var {
        user
      } = this._super(...arguments);

      this.determineRoleAndRedirectTask.perform(user);
      var calendarUser = this.store.findRecord('calendarUser', user.get('id'));
      return Ember.RSVP.hash({
        user,
        calendarUser,
        calendarEventTypes: this.store.findAll('calendarEventType'),
        params
      });
    }

  });

  _exports.default = _default;
});