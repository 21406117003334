define("securitytrax-ember-ui/validations/central-station/cops-create", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateObject = {
        accountNumber: {
          presence: () => {
            return this.customMessageOrDefault(data, 'cops-accountNumber');
          }
        },
        passcode: {
          presence: () => {
            return this.customMessageOrDefault(data, 'cops-passcode');
          }
        },
        templateNumber: {
          presence: () => {
            return this.customMessageOrDefault(data, 'cops-templateNumber');
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      return this.outputFormatting(validationData, 'cops');
    }

  });

  _exports.default = _default;
});