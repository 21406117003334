define("securitytrax-ember-ui/components/multi-step-form/forms/reports/edit-report/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/reports/reports-base", "ember-concurrency", "lodash"], function (_exports, _reportsBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _reportsBase.default.extend({
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    stAjax: Ember.inject.service(),
    screen: Ember.inject.service(),
    confirmDeleteReportModalOpen: false,
    filtersLoaded: false,
    reportFiltersList: undefined,
    reportColumnsList: undefined,
    reportToMassEditColumnsList: undefined,
    groupByOptions: undefined,
    originalReportToFilters: undefined,
    originalReportToColumns: undefined,
    originalReportToMassEditColumns: undefined,
    reportObjectFilterOptions: undefined,
    reportObjectColumnOptions: undefined,
    reportObjectMassEditColumnOptions: undefined,

    init() {
      this._super(...arguments);

      window.addEventListener('touchmove', function () {
        return;
      }, {
        passive: false
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      window.removeEventListener('touchmove', function () {
        return;
      });
    },

    setupFormTask: (0, _emberConcurrency.task)(function* () {
      // in case they reset, do the following inits
      this.set('filtersLoaded', false);
      this.set('reportFiltersList', null);
      this.set('reportColumnsList', null);
      this.set('reportToMassEditColumnsList', null);
      yield this.multiStepForm.setupCopyModels.perform(); //in the event we came from add

      var report = this.multiStepForm.formOriginalModelsGetVal('report.model');
      this.set('report', report);
      var reportObject = report ? yield report.get('reportObject') : null;
      this.set('reportObject', reportObject);
      var hashedReportData = yield (0, _emberConcurrency.hash)({
        reportCategory: report ? report.get('reportCategory') : null,
        reportObjectFilters: reportObject ? reportObject.get('reportObjectFilters') : [],
        reportObjectColumns: reportObject ? reportObject.get('reportObjectColumns') : [],
        groupByOptions: reportObject ? reportObject.get('reportOuterloops') : [],
        reportToFilters: report ? report.get('reportToFilters') : null,
        reportToColumns: report ? report.get('reportToColumns') : null,
        reportToMassEditColumns: report ? report.get('reportToMassEditColumns') : null
      });
      this.set('reportCategory', hashedReportData.reportCategory);
      var hashedPivotData = yield (0, _emberConcurrency.hash)({
        reportFiltersList: this.setupPivotListTask.perform(hashedReportData.reportToFilters, 'reportToFilter', 'reportObjectFilter'),
        reportColumnsList: this.setupPivotListTask.perform(hashedReportData.reportToColumns, 'reportToColumn', 'reportObjectColumn'),
        reportToMassEditColumnsList: this.setupPivotListTask.perform(hashedReportData.reportToMassEditColumns, 'reportToMassEditColumn', 'reportObjectMassEditColumn')
      });
      this.set('reportFiltersList', hashedPivotData.reportFiltersList);
      this.set('reportColumnsList', hashedPivotData.reportColumnsList);
      this.set('reportToMassEditColumnsList', hashedPivotData.reportToMassEditColumnsList);
      this.set('originalReportToFilters', hashedReportData.reportToFilters);
      this.set('originalReportToColumns', hashedReportData.reportToColumns);
      this.set('originalReportToMassEditColumns', hashedReportData.reportToMassEditColumns);
      this.set('reportObjectFilterOptions', hashedReportData.reportObjectFilters.sortBy('name'));
      this.set('reportObjectColumnOptions', hashedReportData.reportObjectColumns.sortBy('name'));
      this.set('groupByOptions', hashedReportData.groupByOptions);
      this.set('filtersLoaded', true);
    }).on('init'),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      var intl = this.intl;
      var setupTask = this.setupFormTask;

      if (multiStepForm.formOriginalModels.report.model) {
        multiStepForm.registerStepData('editReport', 'title', intl.t("pages.reports.edit_report") + ' ' + multiStepForm.formOriginalModels.report.model.get('name') + ' (ID: ' + multiStepForm.formOriginalModels.report.model.id + ')');
      }

      var buttonChoices = [{
        // Reset Button
        text: intl.t('common.actions.reset'),
        click: function () {
          multiStepForm.setupCopyModels.perform();
          multiStepForm.validationErrors = {};
          setupTask.perform();
        },
        classNamesString: 'st-btn btn-secondary cancel'
      }, {
        // Save Button
        text: intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.editReportTask.perform(multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary save'
      }];

      if (!this.reportObject || this.reportObject && this.reportObject.reportObjectHasGrant('reports.delete')) {
        buttonChoices.unshift({
          // Delete Button
          text: this.intl.t('common.actions.delete'),
          classNamesString: 'st-btn st-btn warning-red delete',
          isDelete: true,
          click: () => {
            this.set('confirmDeleteReportModalOpen', true);
          }
        });
      }

      multiStepForm.registerStepData('editReport', 'buttons', buttonChoices);
    },

    filteredReportObjectMassEditColumnOptions: Ember.computed('reportObject.reportObjectMassEditColumns.[]', 'reportToMassEditColumnsList.[]', function () {
      return this.filteredReportObjectMassEditColumnOptionsTask.perform();
    }),
    filteredReportObjectMassEditColumnOptionsTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.waitForProperty)(this, 'filtersLoaded', true);
      var reportObjectMassEditColumns = this.reportObject ? yield this.reportObject.get('reportObjectMassEditColumns') : [];
      var alreadyUsedIds = {};

      for (var index = 0; index < this.reportToMassEditColumnsList.length; index++) {
        var element = this.reportToMassEditColumnsList.objectAt(index);
        var relId = element.get('reportObjectMassEditColumn.id');

        if (relId) {
          alreadyUsedIds[relId] = true;
        }
      }

      for (var _index = 0; _index < reportObjectMassEditColumns.length; _index++) {
        var _element = reportObjectMassEditColumns.objectAt(_index);

        if (alreadyUsedIds[_element.id]) {
          _element.set('disabled', true);
        } else {
          _element.set('disabled', false);
        }
      }

      return reportObjectMassEditColumns.sortBy('name');
    }),

    /**
     * Setup a list of filters or columns based on the pivotTable that is sorted, and displays the object name.
     *
     * @param {List} pivotTableList The list of all pivot table records tied to this report
     * @param {String} pivotTableName The name of the pivot table
     * @param {String} relName The relation to the actual object
     */
    setupPivotListTask: (0, _emberConcurrency.task)(function* (pivotTableList, pivotTableName, relName) {
      var sortedList = [];

      if (pivotTableList) {
        pivotTableList = _lodash.default.sortBy(pivotTableList.toArray(), [function (o) {
          return Number(o.get('sort'));
        }]);
        var relObjects = [];

        for (var i = 0; i < pivotTableList.length; i++) {
          var obj = pivotTableList.objectAt(i);
          relObjects.push(obj.get(relName));
        }

        var foundRelObjects = yield (0, _emberConcurrency.all)(relObjects); // PERF TODO - only needed because can't do includes on rel calls

        for (var _i = 0; _i < pivotTableList.length; _i++) {
          var _obj = pivotTableList.objectAt(_i);

          var relObject = foundRelObjects[_i];

          _obj.set('name', relObject.get('name'));

          _obj.set('isEditable', false);

          if (relName === 'reportObjectColumn') {
            _obj.set('summable', relObject.get('summable'));

            _obj.set('editable', relObject.get('editable'));
          }

          sortedList.pushObject(_obj);
        }
      }

      var blankPivot = this.store.createRecord(pivotTableName);
      blankPivot.set('isEditable', true);
      sortedList.pushObject(blankPivot);
      return sortedList;
    }),
    editReportTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      try {
        var upsertListsHash = yield (0, _emberConcurrency.hash)({
          filters: this.reportFiltersList,
          columns: this.reportColumnsList,
          bulks: this.reportToMassEditColumnsList
        });
        var reportModel = multiStepForm.formOriginalModelsGetVal('report.model');
        yield (0, _emberConcurrency.hash)({
          upsertAndDeleteFiltersTask: this.genericPivotUpsertTask.perform(reportModel, upsertListsHash.filters, this.originalReportToFilters, 'reportObjectFilter', (currItem, index) => {
            return this.store.createRecord('reportToFilter', {
              report: reportModel,
              reportObjectFilter: currItem.get('reportObjectFilter'),
              sort: index,
              modifiedRecord: true
            });
          }),
          upsertAndDeleteColumnsTask: this.genericPivotUpsertTask.perform(reportModel, upsertListsHash.columns, this.originalReportToColumns, 'reportObjectColumn', (currItem, index) => {
            return this.store.createRecord('reportToColumn', {
              report: reportModel,
              reportObjectColumn: currItem.get('reportObjectColumn'),
              sort: index,
              allowSum: currItem.get('allowSum'),
              allowUpdate: currItem.get('allowUpdate'),
              modifiedRecord: true
            });
          }),
          upsertAndDeleteBulksTask: this.genericPivotUpsertTask.perform(reportModel, upsertListsHash.bulks, this.originalReportToMassEditColumns, 'reportObjectMassEditColumn', (currItem, index) => {
            return this.store.createRecord('reportToMassEditColumn', {
              report: reportModel,
              reportObjectMassEditColumn: currItem.get('reportObjectMassEditColumn'),
              sort: index,
              modifiedRecord: true
            });
          })
        });
        yield (0, _emberConcurrency.all)([reportModel.reportObjectMassEditColumns.reload(), reportModel.reportObjectColumns.reload(), reportModel.reportObjectFilters.reload()]); /// TODO: future refactor? Sending all the filters and columns at once instead of individually
        /// Can't currently do this due to columns like Sort, AllowSum, AllowUpdate only on pivot tables
        // const data = filters.filter(each => {
        //     return (each.get('name') !== null && each.get('name') !== undefined);
        // });
        // const postObject = {
        //     method: 'POST',
        //     data: {
        //         data
        //     }
        // };
        // yield this.get('stAjax').request(this.get('stAjax')
        //     .getUrl(`/reports/${reportId}/relationships/report_object_filters`), postObject)
        //     .catch((errors) => {
        //         errors['errors'] = errors.payload.errors;
        //         this.get('stNotificationManager').addErrorMessages(errors, { notificationNamespace: 'modal' });
        //     });

        if (!this.multiStepForm.formCopiedModelsGetVal('report.model.reportCategory')) {
          multiStepForm.formCopiedModelsSetVal('report.model.reportCategory', this.reportCategory);
        }

        if (!this.multiStepForm.formCopiedModelsGetVal('report.model.reportObject')) {
          multiStepForm.formCopiedModelsSetVal('report.model.reportObject', this.reportObject);
        }

        yield multiStepForm.validateSyncSaveAll({
          notificationNamespace: 'modal'
        });
        buttonPromise.then(() => {
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
          multiStepForm.formStartedSetVal(false);

          if (this.isNewRecord) {
            this.router.transitionTo('reports.view', this.report);
          }
        });
      } catch (err) {
        (false && Ember.warn('failed to save report:' + err, {
          id: 'multiStepForm.forms.reports.editReport.editReportTask'
        }));
        throw err;
      }
    }),
    genericPivotUpsertTask: (0, _emberConcurrency.task)(function* (reportModel, newList, originalList, manyToManyModelName, newRecordCallback) {
      var allSavesDeletes = [];
      newList.forEach((currItem, index) => {
        // updates
        if (currItem.get('id') !== null) {
          currItem.set('sort', index);
          currItem.set('report', reportModel);
          currItem.set('modifiedRecord', true);
          allSavesDeletes.push(currItem.save());
        } else if (currItem.get(manyToManyModelName + '.id') != null) {
          // new, but not the blank row
          var pivot = newRecordCallback(currItem, index);
          allSavesDeletes.push(pivot.save());
        }
      }); // now original list only contains records that have been removed and modifed, so destroy unmodified records and reset modified state on modified records

      originalList.forEach((currItem, index) => {
        index;

        if (!currItem.get('modifiedRecord')) {
          allSavesDeletes.push(currItem.destroyRecordWithRollbackTask.perform());
        } else {
          currItem.set('modifiedRecord', false); // Reset state
        }
      });
      yield (0, _emberConcurrency.all)(allSavesDeletes);
    }),
    deleteReport: (0, _emberConcurrency.task)(function* () {
      try {
        var multiStepForm = this.multiStepForm;
        var report = yield multiStepForm.formOriginalModelsGetVal('report.model');
        yield report.destroyRecordWithRollbackTask.perform();
        multiStepForm.formStartedSetVal(false);

        if (!this.isNewRecord) {
          this.router.transitionTo(this.reportCategory.get('route'));
        } else {
          this.refreshReports.perform();
        }

        this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
      } catch (err) {
        (false && Ember.warn('failed to delete report:' + err, {
          id: 'multiStepForm.forms.reports.editReport.deleteReport'
        }));
        throw err;
      }
    }),

    setupList(listName, modelName, relName, index, value) {
      var theList = this.get(listName);

      if (value === null) {
        // remove the item from the array (to be deleted on save)
        var theListArray = theList.toArray();
        theListArray.splice(index, 1);
        theList = theListArray;
      } else {
        // add the item to the array (to be created later)
        var newRecord = this.store.createRecord(modelName);
        newRecord.set(relName, value);
        newRecord.set('isEditable', true);
        theList[index] = newRecord;
        var listLength = theList.length;
        var lastObject = theList[Number(listLength) - 1]; // if last row is not blank, ensure there is an "add row"

        if (Object.keys(lastObject).length !== 0) {
          var blankRow = this.store.createRecord(modelName);
          blankRow.set('isEditable', true);
          theList.pushObject(blankRow);
        }
      }

      this.set(listName, theList);
    },

    actions: {
      addFilter(index, value) {
        if (index === this.reportFiltersList.length - 1 || value === null) {
          this.setupList('reportFiltersList', 'reportToFilter', 'reportObjectFilter', index, value);
        }
      },

      addColumn(index, value) {
        if (index === this.reportColumnsList.length - 1 || value === null) {
          this.setupList('reportColumnsList', 'reportToColumn', 'reportObjectColumn', index, value);
        }
      },

      addBulk(index, value) {
        if (index === this.reportToMassEditColumnsList.length - 1 || value === null) {
          this.setupList('reportToMassEditColumnsList', 'reportToMassEditColumn', 'reportObjectMassEditColumn', index, value);
        }
      }

    }
  });

  _exports.default = _default;
});