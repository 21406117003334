define("securitytrax-ember-ui/components/multi-step-form/forms/invoices/recurring-invoice/details/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/invoices/base-step-components/details/base", "ember-concurrency", "moment", "lodash"], function (_exports, _base, _emberConcurrency, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var RecurringInvoiceDetails = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = (0, _emberConcurrency.task)(function* () {
    yield this.initBaseData.perform();

    if (this.SwitchableFormStep.isNewRecord) {
      var response = yield this.stAjax.request(this.stAjax.getUrl('/global_settings_invoice_settings/1'), {
        method: 'GET'
      });
      var offset = response && response.data.attributes.default_start_date_days_in_future ? response.data.attributes.default_start_date_days_in_future : 0;
      Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.invoiceType', 'recurring_invoice');
      Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.taxOverride', false);
      Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.saleDate', (0, _moment.default)().add(offset ? offset : 1, 'days'));
      Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.recurringIntervalNumber', 1);
      Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.recurringIntervalPeriod', 'months');
    } else {
      if (this.args.multiStepForm.formCopiedModelsGetVal('invoice.model.saleDate').isSameOrBefore((0, _moment.default)())) {
        Ember.set(this, 'lockedState', 'Locked');
      }

      if (this.args.multiStepForm.formOriginalModelsGetVal('invoice.model.customerBilling')) {
        Ember.set(this, 'selectedPaymentMethodId', this.args.multiStepForm.formOriginalModelsGetVal('invoice.model.customerBilling.id'));
      }
    }

    var timesToRecur = this.args.multiStepForm.formCopiedModelsGetVal('invoice.model.numTimesToRecur');

    if (!timesToRecur || timesToRecur < 1) {
      if (timesToRecur === 0) {
        Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.numTimesToRecur', timesToRecur);
        Ember.set(this, 'lockedState', 'Closed');
        Ember.set(this.args, 'formMode', 'view');
      } else {
        Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.numTimesToRecur', '');
      }

      Ember.set(this, 'recurUntilStopped', true);
    } else {
      Ember.set(this, 'recurUntilStopped', false);
      Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.numTimesToRecur', timesToRecur);

      if ((0, _moment.default)().isAfter((0, _moment.default)(this.args.multiStepForm.formCopiedModelsGetVal('invoice.model.lastDay')).add(7, 'days'))) {
        Ember.set(this, 'lockedState', 'Closed');
        Ember.set(this.args, 'formMode', 'view');
      }
    }
  }), _dec8 = (0, _emberConcurrency.task)(function* () {
    return yield this.store.findAll('billingType');
  }), _dec9 = (0, _emberConcurrency.task)(function* () {
    var promiseQueue = [];
    this.RowList.listArray.forEach(lineItem => {
      if (lineItem.invoiceBillableItem) {
        promiseQueue.push(this.resetTax.perform(lineItem));
      }
    });
    yield (0, _emberConcurrency.all)(promiseQueue);
  }), _dec10 = (0, _emberConcurrency.task)(function* (lineItem) {
    if (!lineItem.isNotTaxable || lineItem.isNotTaxable !== true) {
      var hashedData = yield (0, _emberConcurrency.hash)({
        itemClass: lineItem['invoiceBillableItem'] ? lineItem['invoiceBillableItem'].get('invoiceBillableItemClass') : null,
        globalTaxExemptions: this.sessionData.getGlobalTaxExemptions()
      });
      var invoiceBillableItemClass = lineItem ? lineItem.get('invoiceBillableItem.invoiceBillableItemClass.name') : null;
      var isTaxExempt = hashedData.globalTaxExemptions[invoiceBillableItemClass] && hashedData.globalTaxExemptions[invoiceBillableItemClass].includes(this.args.customer.get('state'));
      var tax;
      var taxRates = yield this.taxRates;

      if (taxRates) {
        tax = !(lineItem.get('invoiceBillableItem.isTaxable') == 0 || lineItem.get('invoiceBillableItem.isTaxable') === null && isTaxExempt) && hashedData.itemClass ? taxRates.findBy('id', hashedData.itemClass.get('id')) : null;
      } else {
        tax = null;
      }

      if (hashedData.itemClass && taxRates) {
        var taxRate = tax && tax.tax_rate ? tax.tax_rate : 0;
        var salesTaxAmount = taxRate ? parseFloat((lineItem.get('grossAmount') * (tax.tax_rate / 100)).toFixed(5)) : 0;
        var totalAmount = Number(lineItem.get('grossAmount')) + Number(salesTaxAmount);
        lineItem.set('salesTaxRate', taxRate);
        lineItem.set('salesTaxAmount', salesTaxAmount);
        lineItem.set('totalAmount', totalAmount);
      } else {
        throw new Error('tax rate or item class not set when it should set');
      }
    }
  }), _dec11 = Ember._action, (_class = (_temp = class RecurringInvoiceDetails extends _base.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stAjax", _descriptor2, this);

      _initializerDefineProperty(this, "lockedState", _descriptor3, this);

      _initializerDefineProperty(this, "recurUntilStopped", _descriptor4, this);

      _initializerDefineProperty(this, "stopRecurrence", _descriptor5, this);

      _initializerDefineProperty(this, "paymentMethodFormStarted", _descriptor6, this);

      _defineProperty(this, "recurNumberOptions", [{
        value: 1
      }, {
        value: 2
      }, {
        value: 3
      }, {
        value: 4
      }, {
        value: 5
      }, {
        value: 6
      }, {
        value: 7
      }, {
        value: 8
      }, {
        value: 9
      }, {
        value: 10
      }, {
        value: 11
      }, {
        value: 12
      }, {
        value: 13
      }, {
        value: 14
      }, {
        value: 15
      }, {
        value: 16
      }, {
        value: 17
      }, {
        value: 18
      }, {
        value: 19
      }, {
        value: 20
      }, {
        value: 21
      }, {
        value: 22
      }, {
        value: 23
      }, {
        value: 24
      }]);

      _initializerDefineProperty(this, "initFormData", _descriptor7, this);

      _initializerDefineProperty(this, "getBillTypesTask", _descriptor8, this);

      _initializerDefineProperty(this, "resetLineItemTaxesTask", _descriptor9, this);

      _initializerDefineProperty(this, "resetTax", _descriptor10, this);

      this.initFormData.perform();
    }

    get paymentMethodFormOriginalModels() {
      return Ember.Object.create({
        customerBilling: {
          model: null,
          copyRelationMap: {
            customerBillingBillCcNumber: {
              doCopy: this.grants.getIndividualGrant('customer_billings.view_partial_cc'),
              doSync: this.grants.getIndividualGrant('customer_billings.view_partial_cc'),
              doValidate: false,
              doSave: false
            },
            billType: {
              doValidate: false,
              doSave: false
            },
            customerBillingBillBankNumbers: {
              doCopy: this.grants.getIndividualGrant('customer_billing_bill_bank_numbers.view_partial_bank_number'),
              doSync: this.grants.getIndividualGrant('customer_billing_bill_bank_numbers.view_partial_bank_number'),
              doValidate: false,
              doSave: false
            }
          }
        }
      });
    }

    get paymentMethodForm() {
      return Ember.Object.create({
        paymentMethodFormStep: Ember.Object.create({
          stepNameKey: 'paymentMethodFormStep',
          title: this.intl.t("pages.customers.accounting.customer_billing.create", {
            name: this.args.customer.get('flname'),
            id: null
          }),
          stepComponentPath: 'multi-step-form/forms/accounting/edit-create-payment-method'
        })
      });
    }

    get resolvedBillTypes() {
      return this.getBillTypesTask.perform();
    }

    get recurIntervalOptions() {
      var translationString = 'pages.customers.accounting.invoices.recurringIntervalOptions.';
      return [{
        value: 'days',
        label: this.intl.t(translationString + 'days')
      }, {
        value: 'weeks',
        label: this.intl.t(translationString + 'weeks')
      }, {
        value: 'months',
        label: this.intl.t(translationString + 'months')
      }, {
        value: 'years',
        label: this.intl.t(translationString + 'years')
      }];
    } // commenting for now in case there is some reason this code really needed to be here because it seems a bit sus
    // get nextInvoiceDate() {
    //     const todaysDate = moment();
    //     const startDate = moment(this.args.multiStepForm.formCopiedModelsGetVal('invoice.model.saleDate'));
    //     const intervalNumber = this.args.multiStepForm.formCopiedModelsGetVal('invoice.model.recurringIntervalNumber');
    //     const intervalPeriod = this.args.multiStepForm.formCopiedModelsGetVal('invoice.model.recurringIntervalPeriod');
    //     const stopRecurrence = this.stopRecurrence;
    //     const lockedState = this.lockedState;
    //     if (stopRecurrence || lockedState === 'Closed') {
    //         return this.intl.t('pages.customers.accounting.invoices.stopped');
    //     }
    //     let nextDate = startDate;
    //     while (todaysDate.isAfter(nextDate)) {
    //         nextDate = nextDate.add(intervalNumber, intervalPeriod);
    //     }
    //     return nextDate.format('MM/DD/YYYY');
    // }


    get nextGenerateDate() {
      return _lodash.default.get(this.args.multiStepForm.formCopiedModels, 'invoice.model.responseMeta.next_invoice_generate_date', this.args.multiStepForm.formCopiedModelsGetVal('invoice.model.nextDate'));
    }

    get nextSaleDate() {
      return _lodash.default.get(this.args.multiStepForm.formCopiedModels, 'invoice.model.responseMeta.next_invoice_sale_date', this.args.multiStepForm.formCopiedModelsGetVal('invoice.model.saleDate'));
    }

    get lastInvoiceDate() {
      var startDate = this.args.multiStepForm.formCopiedModelsGetVal('invoice.model.saleDate');
      var intervalNumber = this.args.multiStepForm.formCopiedModelsGetVal('invoice.model.recurringIntervalNumber');
      var intervalPeriod = this.args.multiStepForm.formCopiedModelsGetVal('invoice.model.recurringIntervalPeriod');
      var numTimes = this.args.multiStepForm.formCopiedModelsGetVal('invoice.model.numTimesToRecur');
      var recurUntilStopped = this.recurUntilStopped;
      var stopRecurrence = this.stopRecurrence;
      var lockedState = this.lockedState;

      if (stopRecurrence || lockedState === 'Closed') {
        return this.intl.t('pages.customers.accounting.invoices.stopped');
      }

      if (recurUntilStopped) {
        return this.intl.t('pages.customers.accounting.invoices.recurUntilStopped');
      }

      return intervalNumber * numTimes >= 1 ? (0, _moment.default)(startDate, 'MM/DD/YYYY').add(intervalNumber * (numTimes - 1), intervalPeriod).format('MM/DD/YYYY') : null;
    }

    updateBillingChoices() {
      Ember.notifyPropertyChange(this, 'customerBillingChoices');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stAjax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "lockedState", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'Open';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "recurUntilStopped", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "stopRecurrence", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "paymentMethodFormStarted", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "initFormData", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "getBillTypesTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "resetLineItemTaxesTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "resetTax", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateBillingChoices", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "updateBillingChoices"), _class.prototype)), _class));
  _exports.default = RecurringInvoiceDetails;
});