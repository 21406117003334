define("securitytrax-ember-ui/models/base_models/lead-company", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    name: (0, _model.attr)('string', {
      'readOnly': false
    }),
    contactPerson: (0, _model.attr)('string', {
      'readOnly': false
    }),
    phone: (0, _model.attr)('string', {
      'readOnly': false
    }),
    address: (0, _model.attr)('string', {
      'readOnly': false
    }),
    email: (0, _model.attr)('string', {
      'readOnly': false
    }),
    notes: (0, _model.attr)('string', {
      'readOnly': false
    }),
    isBuyer: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    isSeller: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    isSubDealer: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    subDealerNote: (0, _model.attr)('string', {
      'readOnly': false
    }),
    leadPurchaseNotificationEmail: (0, _model.attr)('string', {
      'readOnly': false
    }),
    forceLeadDefaultTypeId: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    custIsBuyer: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    custIsSeller: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    allowLogin: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    username: (0, _model.attr)('string', {
      'readOnly': false
    }),
    password: (0, _model.attr)('string', {
      'readOnly': false
    }),
    allowUpdates: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    status: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['active', 'deleted']
    }),
    leadPostUrl: (0, _model.attr)('string', {
      'readOnly': false
    }),
    customerPostUrl: (0, _model.attr)('string', {
      'readOnly': false
    }),
    leadPostUrlSourceId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    customerPostUrlSourceId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    customerPurchaseNotificationEmail: (0, _model.attr)('string', {
      'readOnly': false
    }),
    customerSaleNotificationEmail: (0, _model.attr)('string', {
      'readOnly': false
    }),
    customerReturnNotificationEmail: (0, _model.attr)('string', {
      'readOnly': false
    }),
    leadReturnNotificationEmail: (0, _model.attr)('string', {
      'readOnly': false
    }),
    returnCallcenterComments: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    apiIncomingCapable: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    apiIncomingKey: (0, _model.attr)('string', {
      'readOnly': false
    }),
    apiIncomingCalls: (0, _model.attr)('string', {
      'readOnly': false
    }),
    apiOutgoingCapable: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    apiOutgoingKey: (0, _model.attr)('string', {
      'readOnly': false
    }),
    apiOutgoingUsername: (0, _model.attr)('string', {
      'readOnly': false
    }),
    apiOutgoingPassword: (0, _model.attr)('string', {
      'readOnly': false
    }),
    remoteScheduleUrl: (0, _model.attr)('string', {
      'readOnly': false
    }),
    leadQueuePriority: (0, _model.attr)('number', {
      'readOnly': false
    }),
    publicGpgKey: (0, _model.attr)('string', {
      'readOnly': false
    }),
    techScheduleView: (0, _model.attr)('number', {
      'readOnly': false
    }),
    techScheduleViewOptions: (0, _model.attr)('string', {
      'readOnly': false
    }),
    techScheduleViewDatastore: (0, _model.attr)('number', {
      'readOnly': false
    }),
    techScheduleViewDatastoreOptions: (0, _model.attr)('string', {
      'readOnly': false
    }),
    sendBillingInfo: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    customerLeadCompany: (0, _model.belongsTo)('customer-lead-company', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    defaultLeadStatus: (0, _model.belongsTo)('lead-status', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    leadIdentityDeliveryType: (0, _model.belongsTo)('identity-delivery-type', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    customerIdentityDeliveryType: (0, _model.belongsTo)('identity-delivery-type', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    defaultCustomerLocation: (0, _model.belongsTo)('location', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    leadAutoresponseMessage: (0, _model.belongsTo)('message', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    customerAutoresponseMessage: (0, _model.belongsTo)('message', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    lastAutoassignedLeadRepUser: (0, _model.belongsTo)('user', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    files: (0, _model.hasMany)('lead-company-file', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    tags: (0, _model.hasMany)('tag', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    invoices: (0, _model.hasMany)('invoice', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    credits: (0, _model.hasMany)('invoice', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    quotes: (0, _model.hasMany)('invoice', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    leadCompanyCampaigns: (0, _model.hasMany)('lead-company-campaign', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': 'leadCompany'
    }),
    payablePayeeLevels: (0, _model.hasMany)('payable-payee-level', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    udfs: (0, _model.hasMany)('udf', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});