define("securitytrax-ember-ui/components/lists/data-list/column-formatters/actionable-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iDrPRWJ1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\"],[6,[37,6],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[11,\"span\"],[24,0,\"pointer clickable-icon\"],[4,[38,5],[[32,0],[35,4]],null],[12],[1,[30,[36,3],[[35,2]],[[\"class\"],[[30,[36,1],[\"action-icon \",[35,0]],null]]]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"pointer clickable-icon\"],[12],[1,[30,[36,3],[[35,2]],[[\"class\"],[[30,[36,1],[\"action-icon \",[35,0]],null]]]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"iconClass\",\"concat\",\"iconName\",\"svg-icon\",\"clickAction\",\"action\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/lists/data-list/column-formatters/actionable-icon/template.hbs"
    }
  });

  _exports.default = _default;
});