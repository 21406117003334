define("securitytrax-ember-ui/models/customer-at-risk-note", ["exports", "securitytrax-ember-ui/models/base_models/customer-at-risk-note"], function (_exports, _customerAtRiskNote) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerAtRiskNote.default.extend({});

  _exports.default = _default;
});