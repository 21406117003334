define("securitytrax-ember-ui/home/view/edit-payable/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wAGZWDzX",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"payableId\",\"parentRoute\",\"myAccount\"],[[35,0,[\"payableId\"]],[35,0,[\"parentRoute\"]],true]]]]],\"hasEval\":false,\"upvars\":[\"model\",\"modals/sub-route-modals/edit-create-payables\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/home/view/edit-payable/template.hbs"
    }
  });

  _exports.default = _default;
});