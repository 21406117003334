define("securitytrax-ember-ui/models/dealer-number", ["exports", "securitytrax-ember-ui/models/base_models/dealer-number"], function (_exports, _dealerNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _dealerNumber.default.extend({
    displayName: Ember.computed('name', 'number', function () {
      if (this.name && this.number) {
        return this.name + ' (' + this.number + ')';
      } else {
        return null;
      }
    })
  });

  _exports.default = _default;
});