define("securitytrax-ember-ui/components/multi-step-form/forms/invoices/invoice/get-started/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/invoices/base-step-components/get-started/base", "ember-concurrency"], function (_exports, _base, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var InvoiceGetStarted = (_dec = (0, _emberConcurrency.task)(function* () {
    var hashedData1 = yield (0, _emberConcurrency.hash)({
      initBaseData: this.initBaseData.perform(),
      orderInformation: this.grants.getIndividualGrant('customer_order_information.view') ? this.args.customer.get('customerOrderInformation') : null
    });
    var hashedData2 = yield (0, _emberConcurrency.hash)({
      finishedCopyingModels: (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true),
      mmr: hashedData1.orderInformation ? hashedData1.orderInformation.get('mmr') : null,
      activationFee: hashedData1.orderInformation ? hashedData1.orderInformation.get('activationFee') : null
    });

    if (hashedData2.mmr && hashedData2.mmr.get('amount') > 0) {
      this.mmr = true;
    }
  }), (_class = (_temp = class InvoiceGetStarted extends _base.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "initFormData", _descriptor, this);

      this.initFormData.perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "initFormData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = InvoiceGetStarted;
});