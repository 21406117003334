define("securitytrax-ember-ui/helpers/phone-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.phoneFormat = phoneFormat;
  _exports.default = void 0;

  function phoneFormat(input) {
    var phoneNumber = input[0];

    if (phoneNumber) {
      if (phoneNumber.length === 10) {
        var split = phoneNumber.split('');
        split.splice(3, 0, '-');
        split.splice(7, 0, '-');
        var newPhone = split.join('');
        return newPhone;
      } else if (phoneNumber.length === 11) {
        var _split = phoneNumber.split('');

        _split.splice(1, 0, '-');

        _split.splice(5, 0, '-');

        _split.splice(9, 0, '-');

        var _newPhone = _split.join('');

        return _newPhone;
      } else {
        return phoneNumber;
      }
    }
  }

  var _default = Ember.Helper.helper(phoneFormat);

  _exports.default = _default;
});