define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/forms/adt/schedule/edit-appointments/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "securitytrax-ember-ui/mixins/set-user", "ember-concurrency", "securitytrax-ember-ui/utils/obj-helpers", "securitytrax-ember-ui/mixins/forms/duration-options"], function (_exports, _switchableFormStep, _setUser, _emberConcurrency, _objHelpers, _durationOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, _setUser.default, _durationOptions.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    userService: Ember.inject.service(),
    screen: Ember.inject.service(),
    grants: Ember.inject.service(),
    homeDashboard: Ember.inject.service('events/home-dashboard'),
    defaultStartTime: null,
    defaultEndTime: null,
    confirmDeleteModalOpen: false,
    basicFormMode: false,
    calendarEventTypeIncludeCategories: undefined,
    calendarEventDetailTypeOptions: undefined,
    userType: Ember.computed('editCalendarEvent.calendarCategory', function () {
      if (this.editCalendarEvent) {
        return this.get('editCalendarEvent.calendarCategory') === 'sale_schedule' ? 'sales_rep' : 'tech';
      }

      return 'tech';
    }),

    init() {
      this._super();

      (0, _objHelpers.initializeState)(this, {
        calendarEventDetailTypeOptions: [],
        calendarEventTypeIncludeCategories: {
          'tech_schedule': this.userType === 'tech',
          'sale_schedule': this.userType === 'sales_rep'
        }
      });
    },

    initFormData: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = this.multiStepForm;
      yield multiStepForm.setupCopyModels.perform();
      yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);
    }).on('init'),
    calendarEventDetailTypes: Ember.computed(function () {
      return this.store.findAll('calendarEventDetailType');
    }),
    calendarEventTypes: Ember.computed(function () {
      return this.calendarEventTypesTask.perform();
    }),
    calendarEventTypesTask: (0, _emberConcurrency.task)(function* () {
      var calendarEventTypes = yield this.store.findAll('calendar-event-type');
      var filteredCalendarEventTypes = [];
      var calendarEventTypeIncludeCategories = this.calendarEventTypeIncludeCategories;
      calendarEventTypes.forEach(calendarEventType => {
        if (calendarEventType.get('status') === 'active') {
          if (calendarEventTypeIncludeCategories[calendarEventType.get('calendarCategory')] && calendarEventType.get('id') !== JSON.stringify({
            calendar_category: 'tech_schedule',
            id: '100'
          }) && calendarEventType.get('id') !== JSON.stringify({
            calendar_category: 'sale_schedule',
            id: '100'
          })) {
            filteredCalendarEventTypes.push(calendarEventType);
          }
        }
      });
      return filteredCalendarEventTypes;
    }),
    users: Ember.computed(function () {
      return this.usersTask.perform();
    }),
    usersTask: (0, _emberConcurrency.task)(function* () {
      // findAll only returns first 100
      var allCalendarUsers = yield this.store.query('calendarUser', {
        // filter: //cant currently filter on roles, a hasMany
        limit: 0,
        sort: 'lname, fname',
        include: 'roles'
      });
      var allCalendarUsersArray = allCalendarUsers.toArray();
      var filteredUsers = [];

      for (var i = 0; i < allCalendarUsersArray.length; i++) {
        var userRoles = yield allCalendarUsersArray[i].get('roles');
        var isProperUser = userRoles.toArray().some(role => role.get('key') === this.userType);

        if (isProperUser) {
          filteredUsers.push(allCalendarUsersArray[i]);
        }
      }

      return filteredUsers;
    }),
    calendarEventDetailTypeOptionsWatch: Ember.observer('multiStepForm.formCopiedModels.calendarEvent.model.calendarEventType.id', function () {
      var calendarEventId = this.multiStepForm.formCopiedModelsGetVal('calendarEvent.model.calendarEventType.id');

      if (calendarEventId) {
        this.setupCalendarEventDetailTypeOptions.perform(calendarEventId);
      }
    }),
    setupCalendarEventDetailTypeOptions: (0, _emberConcurrency.task)(function* (calendarEventId) {
      this.set('calendarEventDetailTypeOptions', []);

      if (this.formMode === 'create') {
        this.multiStepForm.formCopiedModelsSetVal('calendarEvent.model.calendarEventDetailType', null);
      }

      var asyncData = yield Ember.RSVP.hash({
        selectedCalendarEventType: this.store.findRecord('calendarEventType', calendarEventId),
        company: this.sessionData.getCompany()
      });
      var asyncData2 = yield Ember.RSVP.hash({
        listOfCalendarEventDetailTypes: asyncData.selectedCalendarEventType.get('calendarEventDetailTypes'),
        calendarEventDetailTypeDefaults: asyncData.company.get('calendarEventDetailTypeDefaults')
      });
      this.set('calendarEventDetailTypeOptions', asyncData2.listOfCalendarEventDetailTypes);
      var defaultCalendarEventDetailTypeCamelKey = asyncData.selectedCalendarEventType.get('nameAsKeyCamel') + 'SubtypeId';
      var defaultCalendarEventDetailTypeId = asyncData2.calendarEventDetailTypeDefaults[defaultCalendarEventDetailTypeCamelKey];

      if (defaultCalendarEventDetailTypeId) {
        var defaultCalendarEventDetailType = yield this.store.findRecord('calendarEventDetailType', defaultCalendarEventDetailTypeId);
        this.multiStepForm.formCopiedModelsSetVal('calendarEvent.model.calendarEventDetailType', defaultCalendarEventDetailType);
      }
    }),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      var buttonChoices = [];
      buttonChoices.push({
        text: this.intl.t('common.actions.cancel'),
        classNamesString: 'st-btn st-btn-secondary',
        click: () => {
          Ember.set(this.multiStepForm, 'currentStepNameKey', 'schedule::listCalendarEvents');
        }
      });
      buttonChoices.push({
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.saveCalendarEvent.perform(multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary save-calendar-event'
      });
      multiStepForm.registerStepData('schedule::editCalendarEvent', 'buttons', buttonChoices);
    },

    saveCalendarEvent: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      try {
        var validationObject = {
          notificationNamespace: 'work-order-edit-appointment',
          extraValidationData: {
            noValidate: (yield this.basicFormMode) ? ['calendarEvent-calendarUser'] : []
          },
          overrideOriginalModelsConfig: Ember.Object.create({
            calendarEvent: {
              copyRelationMap: {
                calendarEventType: {
                  doValidate: false,
                  doSave: false
                },
                calendarEventDetailType: {
                  doValidate: false,
                  doSave: false
                },
                calendarUser: {
                  doValidate: false,
                  doSave: false
                }
              }
            }
          })
        };
        yield multiStepForm.validateSyncSaveAll(validationObject);
        buttonPromise.then(() => {
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
          this.newCustomer.get('customerSystemInformation.installCalendarEvent').reload();
          this.homeDashboard.trigger('refresh-appointments');
          this.homeDashboard.trigger('refresh-work-orders-due-today');
          Ember.set(this.multiStepForm, 'currentStepNameKey', 'schedule::listCalendarEvents');
        });
      } catch (error) {
        (false && Ember.warn('Failed in saveCalendarEvent' + error, {
          id: 'multiStepForm.forms.calendarEvent'
        }));
        throw error;
      }
    }),
    actions: {
      updateStartTime() {
        var calendarEventDetailType = this.multiStepForm.formCopiedModelsGetVal('calendarEvent.model.calendarEventDetailType');
        var defaultDuration = calendarEventDetailType.get('duration');

        if (defaultDuration && this.editCalendarEvent && this.get('editCalendarEvent.profile.responseResourceType') !== 'calendar_slots') {
          this.multiStepForm.formCopiedModelsSetVal('calendarEvent.model.duration', defaultDuration);
        }
      }

    }
  });

  _exports.default = _default;
});