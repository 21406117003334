define("securitytrax-ember-ui/components/pages/company-accounting/funding-entries/component", ["exports", "@glimmer/component", "ember-concurrency", "securitytrax-ember-ui/mixed-in-classes/table-column-sort", "lodash", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _component, _emberConcurrency, _tableColumnSort, _lodash, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var AllFundingEntries = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._tracked, _dec21 = Ember._tracked, _dec22 = Ember._tracked, _dec23 = Ember._tracked, _dec24 = Ember._tracked, _dec25 = Ember._tracked, _dec26 = Ember._tracked, _dec27 = (0, _emberConcurrency.task)(function* () {
    var hashedData = yield (0, _emberConcurrency.hash)({
      tagsOptions: this.tagsService.tagsOptionsTask.perform('customer'),
      fundingEntryTypeOptions: this.store.findAll('fundingType'),
      funderOptions: this.grants.getIndividualGrant('funders.view') ? this.store.findAll('funder') : [],
      fundingStatuses: this.store.findAll('fundingProgressStatus'),
      fundingOutcomes: this.store.findAll('fundingOutcome')
    });
    Ember.set(this, 'tagsOptions', hashedData.tagsOptions);
    Ember.set(this, 'fundingEntryTypeOptions', hashedData.fundingEntryTypeOptions);
    Ember.set(this, 'funderOptions', hashedData.funderOptions);
    Ember.set(this, 'fundingStatusesSorted', hashedData.fundingStatuses.sortBy('name'));
    Ember.set(this, 'fundingOutcomesSorted', hashedData.fundingOutcomes.sortBy('name'));
    Ember.notifyPropertyChange(this, 'fundingData');
  }), _dec28 = Ember.computed, _dec29 = (0, _emberConcurrency.task)(function* () {
    var newFilters = yield this.stFiltersService.getFiltersByGroupKey('fundingEntries');
    var fundingEntries = yield this.store.query('funding', {
      filter: newFilters,
      sort: this.TableColumnSort.stSortString ? this.TableColumnSort.stSortString : '-id',
      include: 'customer,funder,funding_progress_status,funding_outcome,funding_transactions,funding_type',
      limit: this.limit,
      page: this.page,
      count: 1
    });
    return {
      fundingEntries,
      record_count: _lodash.default.get(fundingEntries, 'meta.record_count', 0)
    };
  }), _dec30 = Ember._action, _dec31 = Ember._action, _dec32 = Ember._action, _dec33 = Ember._action, _dec34 = Ember._action, _dec35 = Ember._action, (_class = (_temp = class AllFundingEntries extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "grants", _descriptor4, this);

      _initializerDefineProperty(this, "stFiltersService", _descriptor5, this);

      _initializerDefineProperty(this, "tagsService", _descriptor6, this);

      _initializerDefineProperty(this, "router", _descriptor7, this);

      _initializerDefineProperty(this, "screen", _descriptor8, this);

      _initializerDefineProperty(this, "limit", _descriptor9, this);

      _initializerDefineProperty(this, "page", _descriptor10, this);

      _initializerDefineProperty(this, "createdDateFrom", _descriptor11, this);

      _initializerDefineProperty(this, "createdDateTo", _descriptor12, this);

      _initializerDefineProperty(this, "isStatuses", _descriptor13, this);

      _initializerDefineProperty(this, "notStatuses", _descriptor14, this);

      _initializerDefineProperty(this, "fundingOutcomes", _descriptor15, this);

      _initializerDefineProperty(this, "funders", _descriptor16, this);

      _initializerDefineProperty(this, "entryTypes", _descriptor17, this);

      _initializerDefineProperty(this, "expectedDateFrom", _descriptor18, this);

      _initializerDefineProperty(this, "expectedDateTo", _descriptor19, this);

      _initializerDefineProperty(this, "appliedFiltersCount", _descriptor20, this);

      _initializerDefineProperty(this, "outstandingAmountMiscFilter", _descriptor21, this);

      _initializerDefineProperty(this, "tagFilter", _descriptor22, this);

      _initializerDefineProperty(this, "fundingEntryTypeOptions", _descriptor23, this);

      _initializerDefineProperty(this, "funderOptions", _descriptor24, this);

      _initializerDefineProperty(this, "fundingStatusesSorted", _descriptor25, this);

      _initializerDefineProperty(this, "fundingOutcomesSorted", _descriptor26, this);

      _initializerDefineProperty(this, "initFilterDataTask", _descriptor27, this);

      _initializerDefineProperty(this, "fundingDataTask", _descriptor28, this);

      this.TableColumnSort = new _tableColumnSort.default(...arguments);
      (0, _objHelpers.initializeState)(this, {
        tagFilter: this.tagsService.tagFilter(),
        outstandingAmountMiscFilter: {
          operator: null,
          value: null
        },
        stSortableColumnOptions: [{
          name: this.intl.t('common.columns.id') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'id',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.id') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-id',
          chevronDirection: 'down'
        }]
      });
      this.TableColumnSort.stSortableColumnOptions = this.stSortableColumnOptions;
      this.TableColumnSort.sortPaginatedList('id', false);
      this.initFilterDataTask.perform();
    }

    get fundingData() {
      return this.fundingDataTask.perform();
    }

    transitionToSubroute(id) {
      this.router.transitionTo('accounting.funding-entries.edit-funding', id);
    } //action sent to sortable-column component


    sortList(column, ascending) {
      this.TableColumnSort.sortPaginatedList(column, ascending);
      Ember.notifyPropertyChange(this, 'fundingData');
    }

    mobileSortList(column) {
      this.TableColumnSort.sortMobilePaginatedList(column.value);
      Ember.notifyPropertyChange(this, 'fundingData');
    }

    updateFundingEntries() {
      Ember.notifyPropertyChange(this, 'fundingData');
    }

    filterSearch() {
      Ember.set(this, 'page', 1);
      Ember.notifyPropertyChange(this, 'fundingData');
    }

    clearFilterSearch() {
      Ember.setProperties(this, {
        page: 1,
        createdDateFrom: undefined,
        createdDateTo: undefined,
        isStatuses: undefined,
        notStatuses: undefined,
        fundingOutcomes: false,
        funders: undefined,
        entryTypes: undefined,
        expectedDateFrom: undefined,
        expectedDateTo: undefined,
        tagFilter: this.tagsService.tagFilter(),
        outstandingAmountMiscFilter: {
          operator: null,
          value: null
        }
      });
      Ember.notifyPropertyChange(this, 'fundingData');
      Ember.notifyPropertyChange(this, 'page');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "stFiltersService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "tagsService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "screen", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "limit", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 50;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "createdDateFrom", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "createdDateTo", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "isStatuses", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "notStatuses", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "fundingOutcomes", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "funders", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "entryTypes", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "expectedDateFrom", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "expectedDateTo", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "appliedFiltersCount", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "outstandingAmountMiscFilter", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "tagFilter", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "fundingEntryTypeOptions", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "funderOptions", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "fundingStatusesSorted", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "fundingOutcomesSorted", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "initFilterDataTask", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fundingData", [_dec28], Object.getOwnPropertyDescriptor(_class.prototype, "fundingData"), _class.prototype), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "fundingDataTask", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "transitionToSubroute", [_dec30], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToSubroute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortList", [_dec31], Object.getOwnPropertyDescriptor(_class.prototype, "sortList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mobileSortList", [_dec32], Object.getOwnPropertyDescriptor(_class.prototype, "mobileSortList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateFundingEntries", [_dec33], Object.getOwnPropertyDescriptor(_class.prototype, "updateFundingEntries"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterSearch", [_dec34], Object.getOwnPropertyDescriptor(_class.prototype, "filterSearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilterSearch", [_dec35], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilterSearch"), _class.prototype)), _class));
  _exports.default = AllFundingEntries;
});