define("securitytrax-ember-ui/components/multi-step-form/forms/administration/equipment/equipment-forms/accounting-information/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xn+I32h5",
    "block": "{\"symbols\":[\"@formMode\",\"@copyRetailValue\"],\"statements\":[[8,\"html/section-divider\",[],[[\"@title\"],[[30,[36,0],[\"pages.administration.company_settings.equipment.equipment.accountingHeader\"],null]]],null],[2,\"\\n\"],[10,\"div\"],[14,0,\"grid-x grid-padding-x align-stretch\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cell small-12 medium-6 large-3\"],[12],[2,\"\\n        \"],[8,\"multi-step-form/switchable-inputs/masked-input\",[],[[\"@formMode\",\"@value\",\"@label\",\"@mask\"],[[34,1],[34,2,[\"value\"]],[30,[36,0],[\"pages.administration.company_settings.equipment.equipment.retailValue\"],null],\"currency\"]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cell small-12 medium-6 large-3\"],[12],[2,\"\\n        \"],[8,\"multi-step-form/switchable-inputs/checkbox-input\",[],[[\"@formMode\",\"@value\",\"@label\",\"@toggleSwitch\"],[[32,1],[32,2],[30,[36,0],[\"pages.users.inventory.copy_retail_value\"],null],true]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cell small-12 medium-6 large-3\"],[12],[2,\"\\n        \"],[8,\"multi-step-form/switchable-inputs/masked-input\",[],[[\"@formMode\",\"@value\",\"@label\",\"@mask\"],[[34,1],[34,2,[\"cost\"]],[30,[36,0],[\"pages.administration.company_settings.equipment.equipment.unitCost\"],null],\"currency\"]],null],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"formMode\",\"model\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/equipment/equipment-forms/accounting-information/template.hbs"
    }
  });

  _exports.default = _default;
});