define("securitytrax-ember-ui/components/multi-step-form/forms/administration/users/my-inventory/transfers/create-outbound-transfer/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/list/row-list"], function (_exports, _emberConcurrency, _rowList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_rowList.default, {
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    inventoryService: Ember.inject.service(),
    pendingEquipmentLineItemDeleteId: false,
    selectedRecipient: null,
    transferableAmounts: null,
    formStarted: false,
    formMode: 'view',
    totalQuantity: Ember.computed('trueListArray.@each.quantity', function () {
      var total = 0;
      this.trueListArray.forEach(item => {
        total += item.get('quantity') ? item.get('quantity') : 0;
      });
      return total;
    }),
    recipients: Ember.computed(function () {
      var inventoryService = this.inventoryService;
      return inventoryService.get('getInventoryRecipients').perform(' and id != ' + this.get('user.id'));
    }),
    formSteps: Ember.computed('formMode', 'selectedRecipient', function () {
      var intl = this.intl;
      var target = this.selectedRecipient;
      var targetName = target ? target.constructor.modelName.camelize() === 'location' ? target.get('name') : target.get('flname') : '';
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: intl.t("pages.users.my_inventory.transfers.review_title", {
            target: targetName
          }),
          stepComponentPath: 'multi-step-form/forms/administration/inventory/equipment-transfer/review'
        })
      });
    }),
    totalQuantityObserver: Ember.observer('totalQuantity', function () {
      if (this.totalQuantity > 0) {
        this.registerButtons();
      } else {
        this.registerButtons(true);
      }
    }),
    selectedRecipientChangedObserver: Ember.observer('selectedRecipient', function () {
      var multiStepForm = this.multiStepForm;
      var translationKey = 'pages.users.my_inventory.transfers.create_outbound_transfer_title' + (this.get('selectedRecipient.id') ? '_extended' : '');
      var titleData = {
        target: this.get('selectedRecipient.flname') || this.get('selectedRecipient.name')
      };
      multiStepForm.registerStepData("step1", 'title', this.intl.t(translationKey, titleData));
    }),

    didInsertElement() {
      this._super(...arguments);

      this.registerButtons(true);
    },

    getOutboundTransferDataTask: (0, _emberConcurrency.task)(function* () {
      if (this.selectedRecipient) {
        var equipment = [];
        var transferableAmounts = [];
        var equipmentItems = yield this.equipment;
        var data = yield this.user.invokeAction('get_outbound_transfer_data', {
          verb: 'GET',
          data: {
            target_recipient_id: this.get('selectedRecipient.id'),
            target_recipient_type: this.selectedRecipient.constructor.modelName === 'inventory-user' ? 'user' : 'location'
          }
        });
        var sourceValues = data.responsePayload.data.source;

        for (var i = 0; i < sourceValues.length; i++) {
          if (sourceValues[i].on_hand_quantity > 0) {
            var equipmentItem = equipmentItems.findBy('id', sourceValues[i].equipment_id);
            equipment.push(equipmentItem);
            transferableAmounts[sourceValues[i].equipment_id] = +sourceValues[i].on_hand_quantity + (+sourceValues[i].on_transfer_quantity < 0 ? +sourceValues[i].on_transfer_quantity : 0) + (+sourceValues[i].pre_allocated_quantity < 0 ? +sourceValues[i].pre_allocated_quantity : 0);
          }
        }

        this.set('equipment', equipment);
        this.set('transferableAmounts', transferableAmounts);

        if (this.formMode === 'create') {
          this.addRow();
        }
      }
    }),
    openReviewModal: (0, _emberConcurrency.task)(function* (multiStepForm) {
      var formattedObjects = [];
      formattedObjects.push(Ember.Object.create({
        model: {
          source: this.get('user.id'),
          target: this.selectedRecipient
        },
        filePath: 'inventory/equipment-transfer'
      }));
      yield multiStepForm.validateList.perform(formattedObjects);
      this.set('formStarted', true);
    }),
    saveTransfersTask: (0, _emberConcurrency.task)(function* (buttonPromise) {
      var transfers = [];
      this.listArray.forEach(lineItem => {
        if (lineItem.id) {
          transfers.push({
            equipment_id: lineItem.equipment.id,
            target_recipient_id: this.get('selectedRecipient.id'),
            target_recipient_type: this.selectedRecipient.constructor.modelName === 'inventory-user' ? 'user' : 'location',
            quantity: +lineItem.quantity
          });
        }
      });
      yield this.user.invokeAction('perform_outbound_transfer', {
        verb: 'POST',
        data: {
          data: {
            attributes: {
              transfer_data: transfers
            }
          }
        }
      });
      buttonPromise.then(() => {
        this.multiStepForm.onAfterSave();
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        this.multiStepForm.formStartedSetVal(false);
      });
    }),
    deletePendingEquipmentLineItemTask: (0, _emberConcurrency.task)(function* (row) {
      yield this.deleteRow.perform(row, 'pendingEquipmentLineItem');
      this.set('pendingEquipmentLineItemDeleteId', false);
    }),

    registerButtons(disabled = false) {
      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData("step1", 'buttons', [{
        text: this.intl.t('pages.users.my_inventory.physical_inventory.review'),
        click: () => {
          return this.openReviewModal.perform(multiStepForm);
        },
        disabled,
        classNamesString: 'st-btn st-btn-primary transfer-review-button'
      }]);
    },

    actions: {
      updateEquipmentItemsList(row, index) {
        var lineItems = this.listArray;
        var lineItem = lineItems[index];
        lineItem.set('transferable', row.equipment ? this.transferableAmounts[row.equipment.id] : 0);
        lineItem.set('quantity', row.get('quantity') > row.get('transferable') ? row.get('transferable') : row.get('quantity'));
        this.updateRow(lineItem, index);
      },

      updateTransferable(row, event) {
        if (event.target.value > row.get('transferable')) {
          row.set('quantity', row.get('transferable') > 0 ? row.get('transferable') : 0);
        }
      }

    }
  });

  _exports.default = _default;
});