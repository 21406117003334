define("securitytrax-ember-ui/components/modals/st-paper/paper-backdrop/component", ["exports", "ember-css-transitions/mixins/transition-mixin"], function (_exports, _transitionMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PaperBackdrop
   * @extends Ember.Component
   * @uses TransitionMixin
   */
  var _default = Ember.Component.extend(_transitionMixin.default, {
    tagName: 'md-backdrop',
    classNames: ['md-default-theme'],
    classNameBindings: ['opaque:md-opaque', 'isLockedOpen:md-locked-open'],
    attributeBindings: ['backdropStyle:style'],
    // TransitionMixin:
    transitionName: 'ng',
    shouldTransition: Ember.computed.bool('opaque'),
    backdropStyle: Ember.computed('fixed', function () {
      return this.fixed ? Ember.String.htmlSafe('position:fixed;') : null;
    }),

    addDestroyedElementClone(original, clone) {
      try {
        original.parent().append(clone);
      } catch (error) {//SWALLOW ERROR console.warn({error});
      }
    },

    onClick: () => {},

    sendClickAction(e) {
      e.preventDefault();
      this.onClick(e);
    },

    click(e) {
      this.sendClickAction(e);
    },

    // needed for iOS
    // iOS doesn't trigger a click event on normal divs
    // unless we use `cursor: pointer` css
    touchEnd(e) {
      this.sendClickAction(e);
    }

  });

  _exports.default = _default;
});