define("securitytrax-ember-ui/components/multi-step-form/forms/administration/organization/edit-office-location/general/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/switchable-form-step", "securitytrax-ember-ui/components/multi-step-form/forms/administration/organization/edit-office-location/base-component"], function (_exports, _emberConcurrency, _switchableFormStep, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend(_switchableFormStep.default, {
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    timezones: Ember.inject.service(),
    grants: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    confirmDeleteModalOpen: false,
    progressBarHidden: Ember.computed('isNewRecord', function () {
      return this.isNewRecord;
    }),
    timeZoneList: Ember.computed(function () {
      return this.timezones.all;
    }),
    dealerNumbers: Ember.computed(function () {
      return this.store.findAll('dealerNumber');
    }),
    initFormData: (0, _emberConcurrency.task)(function* () {
      if (this.isNewRecord) {
        var multiStepForm = this.multiStepForm;
        yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);
        this.multiStepForm.formCopiedModelsSetVal('location.model.active', true);
      }
    }).on('init'),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      var buttons = [];

      if (this.formMode !== 'view') {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveOfficeLocation.perform(multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary office-location-save-button'
        });

        if (!this.isNewRecord && this.grants.getIndividualGrant('locations.delete')) {
          buttons.push({
            text: this.intl.t('common.actions.delete'),
            click: () => {
              this.set('confirmDeleteModalOpen', true);
            },
            isDelete: true,
            classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
          });
        }
      }

      multiStepForm.registerStepData('general', 'buttons', buttons);
    },

    deleteOfficeLocation: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = this.multiStepForm;
      var location = multiStepForm.formOriginalModelsGetVal('location.model');

      try {
        yield location.destroyRecordWithRollbackTask.perform();
        this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
        multiStepForm.formStartedSetVal(false);
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'confirmDeleteModal'
        });
        throw err;
      }
    })
  });

  _exports.default = _default;
});