define("securitytrax-ember-ui/components/multi-step-form/forms/calendar-event/component", ["exports", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/set-user", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "ember-concurrency", "moment"], function (_exports, _component, _setUser, _switchableFormStep, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EditCalendarEvent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service('events/home-dashboard'), _dec7 = Ember.inject.service('events/calendar-events'), _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = (0, _emberConcurrency.task)(function* () {
    var multiStepForm = this.args.multiStepForm;
    var hashedData = yield (0, _emberConcurrency.hash)({
      waitForFinishedCopyingModels: (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true),
      calendarEventDetailTypes: this.store.findAll('calendarEventDetailType')
    });
    Ember.setProperties(this, hashedData); // add stuff to copy models by default here

    var newCalendarEvent = this.args.newCalendarEvent;

    if (newCalendarEvent) {
      if (this.screen.get('isMobile')) {
        this.defaultStartTime = '8:00';
        this.defaultEndTime = '20:00';
      }

      multiStepForm.formCopiedModelsGetVal('calendarEvent.model').setProperties({
        calendarCategory: newCalendarEvent.calendarCategory,
        startTime: newCalendarEvent.startTime,
        duration: newCalendarEvent.duration,
        isPendingAdd: false,
        workOrder: this.args.workOrder
      });

      if (newCalendarEvent.calendarUser) {
        multiStepForm.formCopiedModelsGetVal('calendarEvent.model').setProperties({
          calendarUser: newCalendarEvent.calendarUser
        });
      }

      if (newCalendarEvent.calendarEventType) {
        multiStepForm.formCopiedModelsGetVal('calendarEvent.model').setProperties({
          calendarEventType: newCalendarEvent.calendarEventType
        });
      }

      if (newCalendarEvent.calendarEventDetailType) {
        multiStepForm.formCopiedModelsGetVal('calendarEvent.model').setProperties({
          calendarEventDetailType: newCalendarEvent.calendarEventDetailType
        });
      }

      if (newCalendarEvent.profile) {
        var profile = yield newCalendarEvent.profile;
        multiStepForm.formCopiedModelsGetVal('calendarEvent.model').setProperties({
          requestMeta: {
            scheduling_profile_id: profile.get('id')
          }
        });
      }

      if (this.args.duplicateAppointment) {
        multiStepForm.formCopiedModelsSetVal('calendarEvent.model.notes', this.args.duplicateAppointment.get('notes'));
        multiStepForm.formCopiedModelsSetVal('calendarEvent.model.workOrder', yield this.args.duplicateAppointment.get('workOrder'));

        if (!multiStepForm.formCopiedModelsGetVal('calendarEvent.model.calendarEventType.name')) {
          multiStepForm.formCopiedModelsSetVal('calendarEvent.model.calendarEventType', yield this.args.duplicateAppointment.get('calendarEventType'));
        }

        if (!multiStepForm.formCopiedModelsGetVal('calendarEvent.model.calendarEventDetailType.name')) {
          multiStepForm.formCopiedModelsSetVal('calendarEvent.model.calendarEventDetailType', yield this.args.duplicateAppointment.get('calendarEventDetailType'));
        }
      }
    }

    if (!newCalendarEvent) {
      var formCopiedModels = multiStepForm.formCopiedModels;

      if (formCopiedModels) {
        var calendarEvent = multiStepForm.formCopiedModelsGetVal('calendarEvent');

        if (calendarEvent) {
          var model = multiStepForm.formCopiedModelsGetVal('calendarEvent.model');
          var id = JSON.parse(model.id);
          var startTime = multiStepForm.formCopiedModelsGetVal('calendarEvent.model.startTime');
          var calendarEventType = multiStepForm.formCopiedModelsGetVal('calendarEvent.model.calendarEventType');

          if (calendarEventType && this.args.customer) {
            var type = calendarEventType.name;
            var title = this.intl.t('common.columns.appointment_title', {
              type: type ? type : '',
              start: startTime ? (0, _moment.default)(startTime).format('l') : '',
              id: id.id ? id.id : id ? id : '',
              name: this.args.customer.get('flname')
            });
            multiStepForm.registerStepData(multiStepForm.currentStepNameKey, 'title', title);
          }
        }
      }
    }
  }), _dec12 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    try {
      var savedCalendarEvent;
      var validationObject = {
        notificationNamespace: 'work-order-edit-appointment',
        extraValidationData: {
          noValidate: (yield this.args.basicFormMode) ? ['calendarEvent-calendarUser'] : []
        }
      };

      if (this.SwitchableFormStep.isNewRecord) {
        var toCreateObjects = yield multiStepForm.validateCreateAll(validationObject);

        if (multiStepForm.formCopiedModelsGetVal('calendarEvent.model.calendarUser.id') > 0) {
          var calendarUser = yield this.store.findRecord('calendarUser', multiStepForm.formCopiedModelsGetVal('calendarEvent.model.calendarUser.id'));
          Ember.set(toCreateObjects.calendarEvent.data, 'calendarUser', calendarUser);
        }

        Ember.set(toCreateObjects.calendarEvent.data, 'customer', this.args.customer);
        var calendarEvent = this.store.createRecord('calendarEvent', toCreateObjects.calendarEvent.data);
        savedCalendarEvent = yield toCreateObjects.calendarEvent.saveWithRollbackAndNotification(calendarEvent);
      } else {
        yield multiStepForm.validateSyncSaveAll(validationObject);
        savedCalendarEvent = multiStepForm.formOriginalModelsGetVal('calendarEvent.model');
      }

      buttonPromise.then(() => {
        multiStepForm.formStartedSetVal(false);
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');

        if (this.args.customer && !this.args.customer.get('isLead')) {
          this.args.customer.get('customerSystemInformation.installCalendarEvent').reload();
        }

        this.homeDashboard.trigger('refresh-appointments');
        this.homeDashboard.trigger('refresh-work-orders-due-today');

        if (this.SwitchableFormStep.isNewRecord || this.args.isReschedule) {
          this.args.onAppointmentCreate({
            savedCalendarEvent,
            goToGeneratePOs: this.goToGeneratePOs
          });
        }

        if (this.redrawGrid) {
          this.redrawGrid.perform();
        }

        this.CalendarEventsService.trigger('redrawTheMap');
        this.CalendarEventsService.trigger('refresh-customer-calendar-events');

        if (this.args.transitionBackToParent && typeof this.args.transitionBackToParent === 'function') {
          this.args.transitionBackToParent();
        }
      });
    } catch (error) {
      (false && Ember.warn('Failed in saveCalendarEvent' + error, {
        id: 'multiStepForm.forms.calendarEvent'
      }));
      this.stNotificationManager.addErrorMessages(error, {
        notificationNamespace: 'modal2'
      });
      throw error;
    }
  }), _dec13 = (0, _emberConcurrency.task)(function* () {
    try {
      var multiStepForm = this.args.multiStepForm;
      var deleteEvent = multiStepForm.formOriginalModelsGetVal('calendarEvent.model');
      var workOrder = yield multiStepForm.formOriginalModelsGetVal('calendarEvent.model.workOrder');
      yield deleteEvent.destroyRecordWithRollbackTask.perform();
      this.stNotificationManager.addSuccessKey('common.notifications.delete_successful', this.displayNotificationsGlobally ? 'global' : 'modal');
      multiStepForm.formStartedSetVal(false);
      this.homeDashboard.trigger('refresh-appointments');
      this.homeDashboard.trigger('refresh-work-orders-due-today');
      this.CalendarEventsService.trigger('refresh-customer-calendar-events');

      if (workOrder) {
        workOrder.reload();
      }

      if (this.args.transitionBackToParent && typeof this.args.transitionBackToParent === 'function') {
        this.args.transitionBackToParent();
      }
    } catch (err) {
      stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal2'
      });
    }
  }), _dec14 = Ember._action, _dec15 = Ember._action, (_class = (_temp = class EditCalendarEvent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor3, this);

      _initializerDefineProperty(this, "screen", _descriptor4, this);

      _initializerDefineProperty(this, "grants", _descriptor5, this);

      _initializerDefineProperty(this, "homeDashboard", _descriptor6, this);

      _initializerDefineProperty(this, "CalendarEventsService", _descriptor7, this);

      _initializerDefineProperty(this, "confirmDeleteModalOpen", _descriptor8, this);

      _initializerDefineProperty(this, "calendarEventDetailTypes", _descriptor9, this);

      _initializerDefineProperty(this, "goToGeneratePOs", _descriptor10, this);

      _defineProperty(this, "defaultStartTime", null);

      _defineProperty(this, "defaultEndTime", null);

      _initializerDefineProperty(this, "initFormData", _descriptor11, this);

      _initializerDefineProperty(this, "saveCalendarEvent", _descriptor12, this);

      _initializerDefineProperty(this, "deleteCalendarEvent", _descriptor13, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.SetUser = new _setUser.default(...arguments);
      this.initFormData.perform();
    }

    get duplicateAdditionalActions() {
      return [{
        label: this.intl.t('pages.calendar.duplicate_with_scheduler'),
        action: () => {
          this.args.multiStepForm.currentStepNameKeySetVal('calendarEventSingleStep::duplicateCalendarEventWithScheduler');
        },
        classNamesString: ''
      }];
    }

    buttons() {
      var multiStepForm = this.args.multiStepForm;
      var buttons = [];

      if (this.args.formMode !== 'view') {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveCalendarEvent.perform(multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary save-calendar-event'
        });
      }

      if (!this.SwitchableFormStep.isNewRecord && this.grants.getIndividualGrant('calendar_events.delete') && !this.args.isReschedule) {
        buttons.push({
          text: this.intl.t('common.actions.delete'),
          click: () => {
            this.confirmDeleteModalOpen = true;
          },
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
        });
      }

      var calendarEventTypeName = multiStepForm.formCopiedModelsGetVal('calendarEvent.model.calendarEventType.name') || (this.args.newCalendarEvent && this.args.newCalendarEvent.calendarEventType ? this.args.newCalendarEvent.calendarEventType.get('name') : null);

      if (!this.args.hideGeneratePOs && this.grants.getIndividualGrant('equipment_purchase_orders.create') && calendarEventTypeName === 'Install') {
        buttons[0].positionOptions = 'up left';
        buttons[0].additionalClickActions = [{
          label: this.intl.t('common.actions.saveAndGeneratePOs'),
          action: buttonPromise => {
            this.goToGeneratePOs = true;
            return this.saveCalendarEvent.perform(multiStepForm, buttonPromise);
          },
          classNamesString: 'dropdown-save-generate-po'
        }];
      }

      multiStepForm.registerStepData(multiStepForm.currentStepNameKey, 'buttons', buttons);
    }

    updateDuration(duration) {
      this.args.multiStepForm.formCopiedModelsSetVal('calendarEvent.model.duration', duration);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "screen", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "homeDashboard", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "CalendarEventsService", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "confirmDeleteModalOpen", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "calendarEventDetailTypes", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "goToGeneratePOs", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "initFormData", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "saveCalendarEvent", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "deleteCalendarEvent", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "buttons", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "buttons"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateDuration", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "updateDuration"), _class.prototype)), _class));
  _exports.default = EditCalendarEvent;
});