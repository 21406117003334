define("securitytrax-ember-ui/models/interactive-services-provider-config", ["exports", "securitytrax-ember-ui/models/base_models/interactive-services-provider-config"], function (_exports, _interactiveServicesProviderConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _interactiveServicesProviderConfig.default.extend({});

  _exports.default = _default;
});