define("securitytrax-ember-ui/components/nav/top-nav/nav-search/component", ["exports", "@glimmer/component", "ember-concurrency", "ember-inflector", "lodash"], function (_exports, _component, _emberConcurrency, _emberInflector, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var NavSearch = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember.computed('recentSearchesService.{recentCustomers,recentLeads,recentUsers}'), _dec15 = (0, _emberConcurrency.task)(function* () {
    if (this.globalSearchString) {
      Ember.set(this, 'recentResourceString', null);
      var results;
      var retVal;

      if (this.loadingPage && !this.searchingActive) {
        if (this.page > this.prevPage) {
          //load next page
          results = yield this.searchTask.perform(this.globalSearchString, this.pageSize, this.page + 1);
          retVal = this.prevResults.concat(results);

          if (this.page > 2) {
            retVal.splice(0, results.length);
          }
        } else if (this.page < this.prevPage) {
          //load previous page
          results = yield this.searchTask.perform(this.globalSearchString, this.pageSize, this.page - 1);
          retVal = results.concat(this.prevResults);
          retVal.splice(retVal.length - results.length, results.length);
        }
      } else {
        results = yield this.searchTask.perform(this.globalSearchString, this.pageSize * 2, 1); //load initial search results

        retVal = results;
      }

      this.numLoaded = results.length;

      if (results.length < this.pageSize) {
        Ember.set(this, 'finalPage', this.page);
      }

      Ember.set(this, 'prevResults', retVal);
      return retVal;
    } else {
      var hashedData = yield (0, _emberConcurrency.hash)({
        recent_customers: this.recentSearchesService.recentCustomers,
        recent_leads: this.recentSearchesService.recentLeads,
        recent_users: this.recentSearchesService.recentUsers
      });
      Ember.set(this, 'recentResourceString', this.intl.t("pages.customers.recent_".concat(this.selectedResource.value, "s")));
      Ember.set(this, 'scrollText', '');
      return Ember.get(hashedData, "recent_".concat(this.selectedResource.value, "s"));
    }
  }), _dec16 = Ember._action, _dec17 = (0, _emberConcurrency.task)(function* () {
    if (this.globalSearchString && (!this.optionsToAllowImmediateSearch.includes(this.selectedProperty.value) && this.globalSearchString.length > 1 || this.optionsToAllowImmediateSearch.includes(this.selectedProperty.value))) {
      yield (0, _emberConcurrency.timeout)(600); //make sure they are done typing before searching

      this.initializeNewSearch();
      Ember.notifyPropertyChange(this, 'searchResults'); //this calls searchResults()
    }
  }).restartable(), _dec18 = (0, _emberConcurrency.task)(function* (searchQuery, limit, page) {
    var selectedProperty = this.selectedProperty;
    searchQuery = searchQuery.replaceAll('\'', '\\\''); //Remove for now per cclark, BE likely going to handle this. (in Morgan Freeman voice) BE never did handle it. So here we are. 

    if (searchQuery && (!this.optionsToAllowImmediateSearch.includes(selectedProperty.value) && searchQuery.length > 1 || this.optionsToAllowImmediateSearch.includes(selectedProperty.value))) {
      var optionsArray = [];

      try {
        var store = this.store;
        var resource = this.selectedResource;
        var searchCriteria = '';

        if (selectedProperty.value === 'all') {
          var options = this.propertyOptions;

          for (var i = 0; i < options.length; i++) {
            if (!this.optionsToAllowImmediateSearch.includes(options[i].value) || !!/^\d+$/.test(searchQuery)) {
              var criteriaPart = this.buildSearchCriteria(options[i], searchQuery, resource, false);

              if (criteriaPart) {
                if (searchCriteria != '') {
                  searchCriteria += ' or ' + criteriaPart;
                } else {
                  searchCriteria += criteriaPart;
                }
              }
            }
          }
        } else {
          searchCriteria = this.buildSearchCriteria(selectedProperty, searchQuery, resource);
        }

        var modelType;

        switch (resource.value) {
          case 'customer':
          case 'lead':
            modelType = 'customer';
            searchCriteria += " and record_type = '".concat(resource.value, "'");
            break;

          case 'user':
            modelType = 'user';
            break;
        }

        var searchResults = yield store.query(modelType, {
          filter: searchCriteria,
          include: modelType === 'customer' ? 'customer_order_information' : '',
          limit: limit,
          page: page
        });
        searchResults.forEach(option => {
          optionsArray.push({
            id: option.get('id'),
            globalSearchFirstLine: option.get('globalSearchFirstLine'),
            globalSearchSecondLine: option.get('globalSearchSecondLine'),
            avatar: option.get('avatar'),
            noAvatarHtml: option.get('noAvatarHtml'),
            isCancelled: option.get('isCancelled'),
            secondaryId: selectedProperty.value === 'invoice_id' ? searchQuery : null
          });
        });
      } catch (err) {
        Ember.set(this, 'searchingActive', false);
      }

      var returnVal = optionsArray.sortBy('globalSearchFirstLine');

      if (this.searchingActive) {
        Ember.set(this, 'searchingActive', false);
      }

      return returnVal;
    } else {
      return null;
    }
  }), _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, _dec23 = Ember._action, _dec24 = Ember._action, (_class = (_temp = class NavSearch extends _component.default {
    //Determines whether spinner is active
    //Determines whether search results and spinner space are visible
    constructor() {
      super(...arguments); //TODO: selectedProperty and resource can come from local storage

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "screen", _descriptor2, this);

      _initializerDefineProperty(this, "stAjax", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "stFormat", _descriptor5, this);

      _initializerDefineProperty(this, "grants", _descriptor6, this);

      _initializerDefineProperty(this, "recentSearchesService", _descriptor7, this);

      _initializerDefineProperty(this, "router", _descriptor8, this);

      _initializerDefineProperty(this, "selectedResource", _descriptor9, this);

      _initializerDefineProperty(this, "selectedProperty", _descriptor10, this);

      _initializerDefineProperty(this, "searchingActive", _descriptor11, this);

      _initializerDefineProperty(this, "loadingPage", _descriptor12, this);

      _initializerDefineProperty(this, "scrollText", _descriptor13, this);

      _defineProperty(this, "searchOpen", void 0);

      _defineProperty(this, "globalSearchString", '');

      _defineProperty(this, "prevResults", []);

      _defineProperty(this, "page", 1);

      _defineProperty(this, "prevPage", 1);

      _defineProperty(this, "finalPage", Infinity);

      _defineProperty(this, "pageSize", 25);

      _defineProperty(this, "previousScroll", 0);

      _defineProperty(this, "optionsToAllowImmediateSearch", ['id', 'invoice_id', 'isp_id']);

      _defineProperty(this, "recentResourceString", null);

      _initializerDefineProperty(this, "searchResultsTask", _descriptor14, this);

      _initializerDefineProperty(this, "updateSearchTask", _descriptor15, this);

      _initializerDefineProperty(this, "searchTask", _descriptor16, this);

      Ember.set(this, 'selectedProperty', {
        value: 'name',
        name: this.intl.t('common.actions.searchOptions.properties.name')
      });
      Ember.set(this, 'selectedResource', this.resourceOptions[0]);
      Ember.set(this, 'recentResourceString', this.intl.t('pages.customers.recent_customers'));
    }

    get searchPlaceHolder() {
      var resource = this.selectedResource.name;

      if (resource === 'Search for') {
        return this.intl.t('pages.customers.search_default');
      }

      return this.intl.t('common.actions.search') + ' ' + this.intl.t('common.nav.' + (0, _emberInflector.pluralize)(resource).toLowerCase());
    }

    get resourceOptions() {
      var options = [];

      if (this.grants.getIndividualGrant('customers.view')) {
        options.push({
          value: 'customer',
          name: this.intl.t('common.actions.searchOptions.resources.customer')
        });
      }

      if (this.grants.getIndividualGrant('users.view')) {
        options.push({
          value: 'user',
          name: this.intl.t('common.actions.searchOptions.resources.user')
        });
      }

      if (this.grants.getIndividualGrant('leads.view')) {
        options.push({
          value: 'lead',
          name: this.intl.t('common.actions.searchOptions.resources.lead')
        });
      }

      return options;
    }

    get propertyOptions() {
      var options = [{
        value: 'all',
        sort: 0,
        name: this.intl.t('common.actions.searchOptions.properties.all')
      }, {
        value: 'id',
        sort: 1,
        name: this.intl.t('common.actions.searchOptions.properties.id')
      }, {
        value: 'name',
        sort: 3,
        name: this.intl.t('common.actions.searchOptions.properties.name')
      }, {
        value: 'email',
        sort: 6,
        name: this.intl.t('common.actions.searchOptions.properties.email')
      }, {
        value: 'address',
        sort: 9,
        name: this.intl.t('common.columns.address')
      }];

      if (this.selectedResource.value === 'customer' || this.selectedResource.value === 'lead') {
        options.pushObject({
          value: 'phone',
          sort: 4,
          name: this.intl.t('common.actions.searchOptions.properties.phone')
        });
      }

      if (this.selectedResource.value === 'customer') {
        options.pushObject({
          value: 'account_number',
          sort: 2,
          name: this.intl.t('common.actions.searchOptions.properties.accountNumber')
        });
        options.pushObject({
          value: 'emergency_contact_phone',
          sort: 7,
          name: this.intl.t('common.actions.searchOptions.properties.emergencyContactPhone')
        });
        options.pushObject({
          value: 'invoice_id',
          sort: 8,
          name: this.intl.t('common.actions.searchOptions.properties.invoiceId')
        });
        options.pushObject({
          value: 'isp_id',
          sort: 10,
          name: this.intl.t('common.actions.searchOptions.properties.ispId')
        });
      }

      if (this.selectedResource.value === 'user') {
        options.pushObject({
          value: 'username',
          sort: 5,
          name: this.intl.t('common.actions.searchOptions.properties.username')
        });
      } // toggle the 'searchBy' to default property (name) if user changes 'searchFor' to something not compatible


      if (this.selectedResource.value === 'customer' && this.selectedProperty.value === 'username') {
        Ember.set(this, 'selectedProperty', {
          value: 'name',
          name: this.intl.t('common.actions.searchOptions.properties.name')
        });
      }

      if (this.selectedResource.value === 'user' && (this.selectedProperty.value === 'phone' || this.selectedProperty.value === 'account_number')) {
        Ember.set(this, 'selectedProperty', {
          value: 'name',
          name: this.intl.t('common.actions.searchOptions.properties.name')
        });
      }

      if (this.selectedResource.value === 'lead' && (this.selectedProperty.value === 'account_number' || this.selectedProperty.value === 'username')) {
        Ember.set(this, 'selectedProperty', {
          value: 'name',
          name: this.intl.t('common.actions.searchOptions.properties.name')
        });
      }

      return _lodash.default.orderBy(options, 'sort');
    }

    get expandedDrawer() {
      if (this.args.hideSearch) {
        Ember.set(this, 'searchOpen', false);
        return '';
      } else {
        Ember.set(this, 'searchOpen', true);
        return 'expanded';
      }
    }

    get linkUri() {
      if (this.selectedResource.value === 'lead') {
        return 'leads.dashboard';
      }

      if (this.selectedResource.value === 'customer') {
        return 'customers';
      }

      if (this.selectedResource.value === 'user') {
        return 'users';
      }

      return null;
    }

    get searchResults() {
      this.filterSearchString();
      return this.searchResultsTask.perform();
    }

    setScrollText() {
      var searchResultsLength = this.prevResults.length;
      var text = null;

      if (searchResultsLength > 0) {
        text = this.intl.t('pages.customers.search_results', {
          count: searchResultsLength
        });

        if (this.loadingPage) {
          text += '. ' + this.intl.t('pages.customers.search_results_loading');
        } else if (searchResultsLength > 1 && this.page < this.finalPage) {
          text += '. ' + this.intl.t('pages.customers.search_results_scroll');
        }

        Ember.set(this, 'scrollText', text);
      }
    }

    updatedSearchString() {
      //globalSearchString
      this.filterSearchString();

      if (this.globalSearchString && this.globalSearchString != this.oldSearchString) {
        Ember.set(this, 'oldSearchString', this.globalSearchString);
        Ember.set(this, 'searchOpen', true);
        return this.updateSearchTask.perform();
      } else if (this.globalSearchString != this.oldSearchString) {
        Ember.set(this, 'oldSearchString', this.globalSearchString);
        this.initializeNewSearch();
        Ember.notifyPropertyChange(this, 'searchResults');
        Ember.set(this, 'searchingActive', false);
      }
    }

    filterSearchString() {
      if (this.globalSearchString && this.optionsToAllowImmediateSearch.includes(this.selectedProperty.value)) {
        Ember.set(this, 'globalSearchString', this.globalSearchString.replace(/\D/g, ''));
      }
    }

    buildSearchCriteria(selectedProperty, searchQuery, resource, updateGlobalSearch = true) {
      var searchCriteria = '';

      switch (selectedProperty.value) {
        case 'id':
          searchCriteria = '(id = ' + searchQuery + ')';
          break;

        case 'account_number':
          searchCriteria = "(customer_system_information.account_num = '".concat(searchQuery, "')");
          break;

        case 'name':
          if (resource.value === 'customer' || resource.value === 'lead') {
            searchCriteria = '((name ~ ' + '\'%' + searchQuery + '%\')' + ' or ' + '(business_name ~ ' + '\'%' + searchQuery + '%\'))';
          } else {
            searchCriteria = '(name ~ ' + '\'%' + searchQuery + '%\')';
          }

          break;

        case 'phone':
          searchQuery = this.phoneAlphaToNumeric(searchQuery, updateGlobalSearch);

          if (searchQuery.length !== 0) {
            searchCriteria = '(phone ~ ' + '\'%' + searchQuery + '%\')';
          }

          break;

        case 'username':
          searchCriteria = '(username ~ ' + '\'%' + searchQuery + '%\')';
          break;

        case 'email':
          searchCriteria = '(email ~ ' + '\'%' + searchQuery + '%\')';
          break;

        case 'emergency_contact_phone':
          searchQuery = this.phoneAlphaToNumeric(searchQuery, updateGlobalSearch);

          if (searchQuery.length !== 0) {
            searchCriteria = "(customer_contact_phone ~ '%".concat(searchQuery, "%')");
          }

          break;

        case 'invoice_id':
          searchCriteria = "(invoice_id = ".concat(searchQuery, ")");
          break;

        case 'address':
          if (resource.value === 'customer' || resource.value === 'lead') {
            searchCriteria = '((address1 ~ ' + '\'%' + searchQuery + '%\')' + ' or ' + '(address2 ~ ' + '\'%' + searchQuery + '%\'))';
          } else {
            searchCriteria = '(address ~ ' + '\'%' + searchQuery + '%\')';
          }

          break;

        case 'isp_id':
          searchCriteria = "(customer_system_information.interactive_services_id = ".concat(searchQuery, ")");
          break;
      }

      return searchCriteria;
    } // allow them to put in strings such as 833-GET-TRAX and convert to phone number


    phoneAlphaToNumeric(phoneString, updateGlobalSearch = true) {
      var alphaNumberMap = ['abc', 'def', 'ghi', 'jkl', 'mno', 'pqrs', 'tuv', 'wxyz'];
      var phoneNumber = phoneString.toLowerCase();
      var mappedArray = [];
      var newString = phoneNumber.replace(/\D/g, '').substr(0, 11);

      if (this.selectedProperty.value !== 'all') {
        for (var i = 0; i < phoneNumber.length; i++) {
          for (var j = 0; j < alphaNumberMap.length; j++) {
            if (alphaNumberMap[j] && alphaNumberMap[j].indexOf(phoneNumber[i]) > -1) {
              mappedArray.push(j + 2); // 0 and 1 don't have letters

              break;
            }
          }

          mappedArray.push(phoneNumber[i]);
        }

        newString = mappedArray.join('').replace(/\D/g, '').substr(0, 11);
      } // replace all non numeric chars, and only take first 11 digits


      if (newString.length > 1) {
        if (updateGlobalSearch) {
          Ember.set(this, 'globalSearchString', this.stFormat.phoneNumber(newString));
        }
      } else {
        newString = '';
      }

      return newString;
    }

    initializeNewSearch() {
      Ember.set(this, 'searchingActive', true);
      Ember.set(this, 'loadingPage', false);
      Ember.set(this, 'page', 1);
      Ember.set(this, 'prevPage', 1);
      Ember.set(this, 'prevResults', []);
      Ember.set(this, 'finalPage', Infinity);
    } //handle search results being scrolled


    searchResultsScrolled(event) {
      var target = event.target;
      var nextPagePoint = target.clientHeight + (target.scrollHeight - target.clientHeight) * .30;
      var prevPagePoint = target.clientHeight + (target.scrollHeight - target.clientHeight) * .70;
      var scrollPosition = target.scrollHeight - target.scrollTop;

      if (!this.loadingPage && !this.searchingActive && !this.recentResourceString) {
        if (scrollPosition < this.prevScroll && scrollPosition < nextPagePoint && this.page != this.finalPage) {
          Ember.set(this, 'loadingPage', true);
          this.setScrollText();
          this.prevPage = this.page;
          this.page++;
          Ember.notifyPropertyChange(this, 'searchResults'); //this calls searchResults()
        } else if (scrollPosition > this.prevScroll && scrollPosition > prevPagePoint && this.page > 2) {
          Ember.set(this, 'loadingPage', true);
          this.setScrollText();
          this.prevPage = this.page;
          this.page--;
          Ember.notifyPropertyChange(this, 'searchResults'); //this calls searchResults()
        }
      }

      Ember.set(this, 'prevScroll', scrollPosition);
    } //callback function called once a new page of results load


    pageLoaded() {
      if (this.page > 2) {
        var scrollElement = document.getElementsByClassName('cell small-12 search-results-area')[0];
        var scrollDelta = scrollElement.scrollHeight / this.prevResults.length * this.numLoaded;

        if (this.prevPage < this.page) {
          scrollElement.scrollTop -= scrollDelta;
        } else if (this.prevPage > this.page) {
          scrollElement.scrollTop += scrollDelta;
        }
      }

      Ember.set(this, 'loadingPage', false);
      this.setScrollText();
    }

    closeSearch() {
      if (!this.args.hideSearch) {
        Ember.set(this, 'searchOpen', false);
        this.args.toggleSearch('search');
      }
    }

    goToResource(item) {
      var resource = this.selectedResource;

      switch (resource.value) {
        default:
        case 'customer':
          if (this.selectedProperty.value === 'invoice_id' && item.secondaryId) {
            this.router.transitionTo('customers.accounting.invoices-invoices.edit-create-invoice', item.id, item.secondaryId);
          } else {
            this.router.transitionTo("customers.view", item.secondaryId ? item.secondaryId : item.id);
          }

          break;

        case 'user':
          this.router.transitionTo("users.information", item.id);
          break;
      }
    }

    propertySelected() {
      this.initializeNewSearch();
      Ember.notifyPropertyChange(this, 'searchResults'); //this calls searchResults()
    }

    focusInput(element) {
      element.querySelector('input').focus();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "screen", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stAjax", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "stFormat", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "recentSearchesService", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "selectedResource", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "selectedProperty", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "searchingActive", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "loadingPage", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "scrollText", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "searchResults", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "searchResults"), _class.prototype), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "searchResultsTask", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updatedSearchString", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "updatedSearchString"), _class.prototype), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "updateSearchTask", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "searchTask", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "searchResultsScrolled", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "searchResultsScrolled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "pageLoaded", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "pageLoaded"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeSearch", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "closeSearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "goToResource", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "goToResource"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "propertySelected", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "propertySelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "focusInput", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "focusInput"), _class.prototype)), _class));
  _exports.default = NavSearch;
});