define("securitytrax-ember-ui/classes/edit-home-dashboard", ["exports", "securitytrax-ember-ui/classes/edit-dashboard"], function (_exports, _editDashboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EditHomeDashboard = (_dec = Ember._tracked, (_class = (_temp = class EditHomeDashboard extends _editDashboard.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "dashboardLocation", 'home_dashboard');

      _defineProperty(this, "titleKeyPrefix", 'pages.home.');

      _defineProperty(this, "componentPrefix", 'components/homepage/dashboard/');

      _initializerDefineProperty(this, "intl", _descriptor, this);
    }

    get defaultCards() {
      return [{
        'display': true,
        'card': 'sales-today',
        'order': 0,
        'config': {
          'grant': 'dashboard_cards_sales_numbers.view_company',
          'titleKey': 'pages.home.sales_today',
          'card_focus': 'company'
        }
      }, {
        'display': true,
        'card': 'admin-tech-appointments-today',
        'order': 1,
        'config': {
          'grant': 'dashboard_cards_tech_schedule_appointments.view_company',
          'titleKey': 'pages.home.admin_tech_appointments_today',
          'card_focus': 'company',
          'date_range': {
            name: this.intl.t('features.dasboard_card_relative_dates.today'),
            start_day: '***today***',
            relative_date: 'today'
          }
        }
      }, {
        'display': true,
        'card': 'admin-top-sales-reps-today',
        'order': 2,
        'config': {
          'grant': 'dashboard_cards_top_sales_reps.view_company',
          'titleKey': 'pages.home.admin_top_sales_reps_today',
          'card_focus': 'company',
          'date_range': {
            name: this.intl.t('features.dasboard_card_relative_dates.today'),
            start_day: '***today***',
            relative_date: 'today'
          }
        }
      }, {
        'display': true,
        'card': 'open-funding-entries',
        'order': 3,
        'config': {
          'grant': 'dashboard_cards_open_funding_entries.view_company',
          'role': '',
          'titleKey': 'pages.home.open_funding_entries',
          'card_focus': 'company'
        }
      }, {
        'display': true,
        'card': 'my-day',
        'order': 4,
        'config': {
          'grant': 'dashboard_cards_my_day.view_me',
          'role': 'sales_rep,tech,task_assignee,at_risk_assignee,work_order_assignee',
          'roleCheckType': 'or',
          'titleKey': 'pages.home.my_day',
          'card_focus': 'me'
        }
      }, {
        'display': true,
        'card': 'appointments-today',
        'order': 5,
        'config': {
          'grant': 'dashboard_cards_appointments_for_today.view_me',
          'titleKey': 'pages.home.appointments_today',
          'card_focus': 'me',
          'date_range': {
            name: this.intl.t('features.dasboard_card_relative_dates.today'),
            start_day: '***today***',
            relative_date: 'today'
          }
        }
      }, {
        'display': true,
        'card': 'tasks-due-today',
        'order': 6,
        'config': {
          'grant': 'dashboard_cards_tasks_due_today.view_me',
          'role': 'task_assignee',
          'titleKey': 'pages.home.tasks_due_today',
          'card_focus': 'me',
          'date_range': {
            name: this.intl.t('features.dasboard_card_relative_dates.today'),
            start_day: '***today***',
            relative_date: 'today'
          }
        }
      }, {
        'display': true,
        'card': 'work-orders-due-today',
        'order': 7,
        'config': {
          'grant': 'dashboard_cards_work_orders_due_today.view_me',
          'role': 'work_order_assignee',
          'titleKey': 'pages.home.work_orders_due_today',
          'card_focus': 'me',
          'date_range': {
            name: this.intl.t('features.dasboard_card_relative_dates.today'),
            start_day: '***today***',
            relative_date: 'today'
          }
        }
      }, {
        'display': true,
        'card': 'at-risk-entries-for-today',
        'order': 8,
        'config': {
          'grant': 'dashboard_cards_at_risk_entries_for_today.view_me',
          'role': 'at_risk_assignee',
          'entriesToUse': 'today',
          'titleKey': 'pages.home.at_risk_entries_for_today',
          'card_focus': 'me',
          'date_range': {
            name: this.intl.t('features.dasboard_card_relative_dates.today'),
            start_day: '***today***',
            relative_date: 'today'
          }
        }
      }, {
        'display': true,
        'card': 'at-risk-entries-for-today',
        'order': 9,
        'config': {
          'grant': 'dashboard_cards_recent_at_risk_entries.view_me',
          'role': 'at_risk_assignee',
          'entriesToUse': 'recent',
          'titleKey': 'pages.home.recent_at_risk_entries',
          'card_focus': 'me'
        }
      }, {
        'display': true,
        'card': 'tasks-card',
        'order': 10,
        'config': {
          'grant': 'dashboard_cards_home_tasks.view_me,customer_tasks.view',
          'role': 'task_assignee',
          'cardLocationOverride': 'components/customer/dashboard/',
          'fromDashboard': 'home',
          'titleKey': 'pages.home.tasks_summary_card',
          'card_focus': 'me',
          'link': 'home.tasks'
        }
      }, {
        'display': true,
        'card': 'work-orders',
        'order': 11,
        'config': {
          'grant': 'dashboard_cards_work_orders.view_me',
          'role': 'work_order_assignee',
          'titleKey': 'pages.home.work_orders_summary',
          'card_focus': 'me',
          'link': 'home.work-orders'
        }
      }, {
        'display': true,
        'card': 'at-risk-entries',
        'order': 12,
        'config': {
          'grant': 'dashboard_cards_at_risk_entries.view_me',
          'role': 'at_risk_assignee',
          'titleKey': 'pages.home.at_risk_entries_summary',
          'card_focus': 'me',
          'link': 'home.at-risk'
        }
      }, {
        'display': true,
        'card': 'average-monthly-charge',
        'order': 13,
        'config': {
          'grant': 'dashboard_cards_average_monthly_charge.view_company',
          'titleKey': 'pages.home.average_monthly_charge',
          'card_focus': 'company',
          'date_range': {
            name: this.intl.t('features.dasboard_card_relative_dates.today'),
            start_day: '***today***',
            relative_date: 'today'
          }
        }
      }, {
        'display': true,
        'card': 'my-payable-entries',
        'order': 14,
        'config': {
          'grant': 'dashboard_cards_payable_entries.view_me',
          'titleKey': 'pages.home.my_payable_entries',
          'card_focus': 'me',
          'date_range': {
            name: this.intl.t('features.dasboard_card_relative_dates.today'),
            start_day: '***today***',
            relative_date: 'today'
          },
          'link': 'my-account.payables'
        }
      }, {
        'display': true,
        'card': 'sales',
        'order': 15,
        'config': {
          'grant': 'dashboard_cards_sales.view_company',
          'titleKey': 'pages.home.sales',
          'card_focus': 'me',
          'date_range': {
            name: this.intl.t('features.dasboard_card_relative_dates.today'),
            start_day: '***today***',
            relative_date: 'today'
          }
        }
      }];
    }

    get availableCards() {
      return {
        'sales-today': {
          'enabled': true,
          'category': '',
          'card': 'sales-today',
          'config': {
            'titleKey': 'pages.home.sales_today',
            'role': '',
            'card_focus': 'company',
            'card_focus_options': {
              'company': 'dashboard_cards_sales_numbers.view_company'
            },
            'date_range_options': 'none'
          }
        },
        'admin-tech-appointments-today': {
          'enabled': true,
          'category': '',
          'card': 'admin-tech-appointments-today',
          'config': {
            'titleKey': 'pages.home.admin_tech_appointments_today',
            'role': '',
            'card_focus': 'company',
            'default_date_range': 'today',
            'card_focus_options': {
              'company': 'dashboard_cards_tech_schedule_appointments.view_company',
              'me': 'dashboard_cards_tech_schedule_appointments.view_me'
            },
            'date_range_options': 'all'
          }
        },
        'admin-top-sales-reps-today': {
          'enabled': true,
          'category': '',
          'card': 'admin-top-sales-reps-today',
          'config': {
            'titleKey': 'pages.home.admin_top_sales_reps_today',
            'role': '',
            'card_focus': 'company',
            'default_date_range': 'today',
            'card_focus_options': {
              'company': 'dashboard_cards_top_sales_reps.view_company'
            },
            'date_range_options': ['today', 'yesterday', 'this_week', 'this_work_week', 'last_week', 'last_work_week', 'this_month', 'last_month', 'this_quarter', 'last_quarter', 'this_year', 'last_year', 'l30', 'l60', 'l90', 'l180', 'l365']
          }
        },
        'open-funding-entries': {
          'enabled': true,
          'category': '',
          'card': 'open-funding-entries',
          'config': {
            'titleKey': 'pages.home.open_funding_entries',
            'role': '',
            'card_focus': 'company',
            'card_focus_options': {
              'company': 'dashboard_cards_open_funding_entries.view_company'
            },
            'date_range_options': 'none'
          }
        },
        'my-day': {
          'enabled': true,
          'category': '',
          'card': 'my-day',
          'config': {
            'titleKey': 'pages.home.my_day',
            'role': 'sales_rep,tech,task_assignee,at_risk_assignee,work_order_assignee',
            'roleCheckType': 'or',
            'card_focus': 'me',
            'card_focus_options': {
              'me': 'dashboard_cards_my_day.view_me'
            },
            'date_range_options': ['today']
          }
        },
        'my-payable-entries': {
          'enabled': true,
          'category': '',
          'card': 'my-payable-entries',
          'config': {
            'titleKey': 'pages.home.my_payable_entries',
            'role': '',
            'card_focus': 'me',
            'default_date_range': 'today',
            'card_focus_options': {
              'me': 'dashboard_cards_payable_entries.view_me',
              'company': 'dashboard_cards_payable_entries.view_company'
            },
            'date_range_options': ['today', 'yesterday', 'this_week', 'last_week', 'this_month', 'last_month', 'this_quarter', 'last_quarter', 'this_year', 'last_year', 'l30', 'l60', 'l90', 'l180', 'l365'],
            'link': 'my-account.payables'
          }
        },
        'appointments-today': {
          'enabled': true,
          'category': '',
          'card': 'appointments-today',
          'config': {
            'titleKey': 'pages.home.appointments_today',
            'role': '',
            'card_focus': 'me',
            'default_date_range': 'today',
            'card_focus_options': {
              'me': 'dashboard_cards_appointments_for_today.view_me',
              'company': 'dashboard_cards_appointments_for_today.view_company'
            },
            'date_range_options': ['today', 'tomorrow', 'this_week', 'next_week', 'this_work_week', 'next_work_week', 'this_month', 'this_quarter', 'this_year']
          }
        },
        'tasks-due-today': {
          'enabled': true,
          'category': '',
          'card': 'tasks-due-today',
          'config': {
            'titleKey': 'pages.home.tasks_due_today',
            'role': 'task_assignee',
            'card_focus': 'me',
            'default_date_range': 'today',
            'card_focus_options': {
              'me': 'dashboard_cards_tasks_due_today.view_me',
              'company': 'dashboard_cards_tasks_due_today.view_company'
            },
            'date_range_options': ['today', 'tomorrow', 'this_week', 'next_week', 'this_work_week', 'next_work_week', 'this_month', 'this_quarter', 'this_year']
          }
        },
        'work-orders-due-today': {
          'enabled': true,
          'category': '',
          'card': 'work-orders-due-today',
          'config': {
            'titleKey': 'pages.home.work_orders_due_today',
            'role': 'work_order_assignee',
            'card_focus': 'me',
            'default_date_range': 'today',
            'card_focus_options': {
              'me': 'dashboard_cards_work_orders_due_today.view_me',
              'company': 'dashboard_cards_work_orders_due_today.view_company'
            },
            'date_range_options': ['today', 'tomorrow', 'this_week', 'next_week', 'this_work_week', 'next_work_week', 'this_month', 'this_quarter', 'this_year']
          }
        },
        'at-risk-entries-for-today': {
          'enabled': true,
          'category': '',
          'card': 'at-risk-entries-for-today',
          'config': {
            'titleKey': 'pages.home.at_risk_entries_for_today',
            'role': 'at_risk_assignee',
            'entriesToUse': 'today',
            'card_focus': 'me',
            'default_date_range': 'today',
            'card_focus_options': {
              'me': 'dashboard_cards_at_risk_entries_for_today.view_me',
              'company': 'dashboard_cards_at_risk_entries_for_today.view_company'
            },
            'date_range_options': ['today', 'tomorrow', 'this_week', 'last_week', 'next_week']
          }
        },
        'recent-at-risk-entries': {
          'enabled': true,
          'category': '',
          'card': 'at-risk-entries-for-today',
          'config': {
            'titleKey': 'pages.home.recent_at_risk_entries',
            'role': 'at_risk_assignee',
            'entriesToUse': 'recent',
            'cardNameOverride': 'at-risk-entries-for-today',
            'card_focus': 'me',
            'titleKeyOverride': 'pages.home.recent_at_risk_entries',
            'card_focus_options': {
              'me': 'dashboard_cards_recent_at_risk_entries.view_me',
              'company': 'dashboard_cards_recent_at_risk_entries.view_company'
            },
            'date_range_options': 'none'
          }
        },
        'tasks-card': {
          'enabled': true,
          'category': '',
          'card': 'tasks-card',
          'config': {
            'titleKey': 'pages.home.tasks_summary_card',
            'role': 'task_assignee',
            'fromDashboard': 'home',
            'cardLocationOverride': 'components/customer/dashboard/',
            'card_focus': 'me',
            'link': 'home.tasks',
            'card_focus_options': {
              'me': 'dashboard_cards_home_tasks.view_me,customer_tasks.view',
              'company': 'dashboard_cards_home_tasks.view_company,customer_tasks.view'
            },
            'date_range_options': 'none'
          }
        },
        'work-orders': {
          'enabled': true,
          'category': '',
          'card': 'work-orders',
          'config': {
            'titleKey': 'pages.home.work_orders_summary',
            'role': 'work_order_assignee',
            'card_focus': 'me',
            'link': 'home.work-orders',
            'card_focus_options': {
              'me': 'dashboard_cards_work_orders.view_me',
              'company': 'dashboard_cards_work_orders.view_company'
            },
            'date_range_options': 'none'
          }
        },
        'at-risk-entries': {
          'enabled': true,
          'category': '',
          'card': 'at-risk-entries',
          'config': {
            'titleKey': 'pages.home.at_risk_entries_summary',
            'role': 'at_risk_assignee',
            'card_focus': 'me',
            'link': 'home.at-risk',
            'card_focus_options': {
              'me': 'dashboard_cards_at_risk_entries.view_me',
              'company': 'dashboard_cards_at_risk_entries.view_company'
            },
            'date_range_options': 'none'
          }
        },
        'average-monthly-charge': {
          'enabled': true,
          'category': '',
          'card': 'average-monthly-charge',
          'config': {
            'titleKey': 'pages.home.average_monthly_charge',
            'role': '',
            'card_focus': 'company',
            'default_date_range': 'today',
            'card_focus_options': {
              'company': 'dashboard_cards_average_monthly_charge.view_company',
              'me': 'dashboard_cards_average_monthly_charge.view_me'
            },
            'card_focus_editable': true,
            'date_range_options': ['today', 'yesterday', 'this_week', 'this_work_week', 'last_week', 'last_work_week', 'this_month', 'last_month', 'this_quarter', 'last_quarter', 'this_year', 'last_year', 'l30', 'l60', 'l90', 'l180', 'l365']
          }
        },
        'sales': {
          'enabled': true,
          'category': '',
          'card': 'sales',
          'config': {
            'titleKey': 'pages.home.sales',
            'role': '',
            'card_focus': 'company',
            'default_date_range': 'today',
            'card_focus_options': {
              'company': 'dashboard_cards_sales.view_company',
              'me': 'dashboard_cards_sales.view_me'
            },
            'date_range_options': ['today', 'yesterday', 'this_week', 'this_work_week', 'last_week', 'last_work_week', 'this_month', 'last_month', 'this_quarter', 'last_quarter', 'this_year', 'last_year', 'l30', 'l60', 'l90', 'l180', 'l365']
          }
        }
      };
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  })), _class));
  _exports.default = EditHomeDashboard;
});