define("securitytrax-ember-ui/services/st-filters-service", ["exports", "securitytrax-ember-ui/utils/obj-helpers", "ember-concurrency", "lodash", "securitytrax-ember-ui/classes/misc-input-list", "securitytrax-ember-ui/classes/user-defined-fields"], function (_exports, _objHelpers, _emberConcurrency, _lodash, _miscInputList, _userDefinedFields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var StFiltersService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = (0, _emberConcurrency.task)(function* (stFilterGroupKey, defaultFilter = [], returnFieldValues = false, keysToIgnore = {}) {
    var setupStFilterServiceTimeout = setTimeout(() => {
      if (this.filterGroups[stFilterGroupKey]['fullyLoaded'] === false) {
        throw new Error("setupStFilterServiceTimeout timed out because filters were not setup correctly. ".concat(stFilterGroupKey, " Check that you don't have an st-await around just your filters"));
      }
    }, 10000); // this sets a timeout for if not all the expected filters were initiated

    yield (0, _emberConcurrency.waitForProperty)(this.filterGroups[stFilterGroupKey], 'fullyLoaded', true);
    clearTimeout(setupStFilterServiceTimeout);
    var filter = defaultFilter;
    var filterObject = {};
    defaultFilter.forEach(defFilter => {
      var dataPair = defFilter.split('=');
      filterObject[dataPair[0].trim()] = dataPair[1].trim().replaceAll('\'', '');
    });

    if (this.filterGroups[stFilterGroupKey]) {
      var stFilterGroupsArr = Object.keys(this.filterGroups[stFilterGroupKey]['filters']);
      var udfIDArray = [];
      var orFilter = [];

      for (var index = 0; index < stFilterGroupsArr.length; index++) {
        var stFilterKey = stFilterGroupsArr[index];
        var currFilter = this.filterGroups[stFilterGroupKey]['filters'][stFilterKey];
        var actualFiltersArr = Object.keys(currFilter['actualFilters']);

        for (var subIndex = 0; subIndex < actualFiltersArr.length; subIndex++) {
          var actualFilterKey = actualFiltersArr[subIndex];

          if (currFilter['actualFilters'][actualFilterKey] != null) {
            var actualFilter = currFilter['actualFilters'][actualFilterKey]['actualFilter'];
            var filterKey = currFilter['actualFilters'][actualFilterKey]['overrideFilterKey'] || stFilterKey;

            if (!keysToIgnore[filterKey]) {
              filterObject[filterKey] = currFilter['actualFilters'][actualFilterKey]['fieldValue'];
            }

            if (Array.isArray(actualFilter)) {
              for (var q = 0; q < actualFilter.length; q++) {
                filter.push(actualFilter[q]);
              }
            } else if (typeof actualFilter == 'object') {
              // udfID,
              // orFilter
              if (actualFilter['udfID']) {
                udfIDArray.push(actualFilter['udfID']);
                orFilter.push(actualFilter['orFilter']);
              }
            } else {
              if (!keysToIgnore[filterKey]) {
                filter.push(actualFilter);
              }
            }
          }
        }
      }

      if (udfIDArray.length > 0 && orFilter.length > 0) {
        // Must have udf_ids as filter param
        // Must have values as 'OR' param
        filter.push('udf_id = [' + udfIDArray.join(',') + ']');
        filter.push('( ' + orFilter.join(' or ') + ' )');
      }
    }

    this.recalculateFilterGroupCounts(stFilterGroupKey);

    if (returnFieldValues) {
      return filterObject;
    } else {
      return filter;
    }
  }), (_class = (_temp = class StFiltersService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "filterGroups", _descriptor4, this);

      _initializerDefineProperty(this, "filterGroupCounts", _descriptor5, this);

      _initializerDefineProperty(this, "filterGroupResetable", _descriptor6, this);

      _initializerDefineProperty(this, "getFiltersByGroupKeyInternal", _descriptor7, this);

      _defineProperty(this, "customFilters", {
        'filter_isOrIsNotNull': function (data) {
          if (data.value != null) {
            var operator = data.value ? '!=' : '=';
            return "".concat(data.stFilterAttribute, " ").concat(operator, " null");
          }

          return false;
        },
        'filter_notExactCheck': function (data) {
          if (data.value != null) {
            return "".concat(data.stFilterAttribute, " ~ '%").concat(data.value, "%'");
          }

          return false;
        },
        'filter_customerName': function (data) {
          if (data.value) {
            return "(customer.business_name ~ '%".concat(data.value, "%' or customer.name ~ '%").concat(data.value, "%')");
          }

          return false;
        },
        'filter_installStatus': function (data) {
          if (data.value != undefined) {
            var prefix = '';

            if (data.asRelationship) {
              prefix = 'customer.';
            }

            switch (data.value) {
              case 1:
                return "".concat(prefix, "customer_system_information.is_installed = true");

              case 2:
                // used to be this - but not using anymore as it should be like below
                // return `${prefix}customer_system_information.is_installed = false and ${prefix}customer_system_information.install_calendar_event.start_time > '0000-00-00'`;
                return new Array("".concat(prefix, "customer_system_information.is_installed = false"), "".concat(prefix, "customer_system_information.install_calendar_event.start_time > '0000-00-00'"));

              case 3:
                return "".concat(prefix, "customer_system_information.install_calendar_event = [0,null]");

              case 4:
                return "".concat(prefix, "customer_system_information.is_installed != true");
            }
          }

          return false;
        },
        'filter_miscFilterGeneric': function (data) {
          var miscFilter = data.miscFilter;

          if (miscFilter.operand && miscFilter.operator && miscFilter.value) {
            var operator = miscFilter.operator.includes('~') ? '~' : '=';
            var value = miscFilter.operator === '~%' ? '\'' + miscFilter.value + '%\'' : miscFilter.operator === '%~%' ? '\'%' + miscFilter.value + '%\'' : '\'' + miscFilter.value + '\'';
            var filter = miscFilter.operand + operator + value;
            return filter;
          }

          return false;
        },
        'filter_miscFilterSimpleCompare': function (data) {
          if (data.miscFilter && data.miscFilter.operator != null) {
            var value = data.miscFilter.value || '0.00';
            return "".concat(data.stFilterAttribute, " ").concat(data.miscFilter.operator, " ").concat(value);
          }

          return false;
        },
        'filter_miscUdfFilter': function (data) {
          if (data.miscUdfFilter) {
            var returnData = data.UdfClass.formatUdfFilterStrings(data);
            return returnData;
          }

          return false;
        },
        'filter_miscTags': function (data) {
          if (data.tagFilter.operator && data.tagFilter.operator.length > 0) {
            var tagIds = data.tagFilter.operator.map(tag => {
              return tag.value;
            });
            var operand = data.model ? data.model + '.' + data.tagFilter.operand : data.tagFilter.operand;
            return operand + ' = [' + tagIds.join(',') + ']';
          }

          return false;
        },
        'filter_inventoryRecipient': function (data) {
          if (data.value) {
            if (Array.isArray(data.value)) {
              if (data.value.length > 0) {
                var userIds = data.value.filter(recipient => {
                  return recipient.constructor.modelName === 'inventory-user';
                }).map(user => {
                  return user.get('id');
                });
                var locationIds = data.value.filter(recipient => {
                  return recipient.constructor.modelName === 'location';
                }).map(location => {
                  return location.get('id');
                });

                if (userIds.length > 0) {
                  var filterOption = "".concat(data.userTypeAttribute, " = [") + userIds.join(',') + ']';

                  if (locationIds.length > 0) {
                    filterOption = '(' + filterOption + " or ".concat(data.locationTypeAttribute, " = [") + locationIds.join(',') + '])';
                  }

                  return filterOption;
                }

                if (userIds.length === 0 && locationIds.length > 0) {
                  return 'location = [' + locationIds.join(',') + ']';
                }
              }

              return false;
            } else {
              return (data.value.constructor.modelName === 'inventory-user' ? "".concat(data.userTypeAttribute, " = ") : "".concat(data.locationTypeAttribute, " = ")) + data.value.get('id');
            }
          }

          return false;
        },
        'filter_compositeKey': function (data) {
          if (data.value) {
            if (Array.isArray(data.value)) {
              var firstKeys = [];
              var secondKeys = [];
              data.value.forEach(value => {
                var parsedId = JSON.parse(value.id);

                if (!firstKeys.includes("'".concat(parsedId[data.firstKey], "'"))) {
                  firstKeys.push("'".concat(parsedId[data.firstKey], "'"));
                }

                if (!secondKeys.includes("'".concat(parsedId[data.secondKey], "'"))) {
                  secondKeys.push("'".concat(parsedId[data.secondKey], "'"));
                }
              });
              return "(".concat(data.filterAttribute, ".").concat(data.firstKey, " = [").concat(firstKeys, "] and ").concat(data.filterAttribute, ".").concat(data.secondKey, " = [").concat(secondKeys, "])");
            } else {
              var parsedId = JSON.parse(data.value.id);
              return "".concat(data.filterAttribute, ".").concat(data.firstKey, " = ").concat(parsedId[data.firstKey], " and ").concat(data.filterAttribute, ".").concat(data.secondKey, " = ").concat(parsedId[data.secondKey]);
            }
          }

          return false;
        }
      });

      this.UdfClass = new _userDefinedFields.default({
        store: this.store,
        model: null,
        intl: this.intl
      });
      (0, _objHelpers.initializeState)(this, {
        filterGroups: Ember.Object.create(),
        filterGroupCounts: Ember.Object.create(),
        filterGroupResetable: Ember.Object.create()
      });
    }

    setupInitialDataByGroupAndFilterKey(stFilterGroupKey, stFilterKey) {
      if (!this.filterGroups[stFilterGroupKey]) {
        this.filterGroups[stFilterGroupKey] = Ember.Object.create({
          'filters': Ember.Object.create(),
          'fullyLoaded': false
        });
        Ember.run.later(() => {
          Ember.set(this.filterGroupCounts, stFilterGroupKey, 0);
          Ember.set(this.filterGroupResetable, stFilterGroupKey, false);
        });
      }

      if (!this.filterGroups[stFilterGroupKey]['filters'][stFilterKey]) {
        this.filterGroups[stFilterGroupKey]['filters'][stFilterKey] = Ember.Object.create({
          'actualFilters': Ember.Object.create()
        });
      }
    }

    registerFilter(stFilterGroupKey, stFilterKey, actualFilterKeys, defaultValue = null) {
      this.setupInitialDataByGroupAndFilterKey(stFilterGroupKey, stFilterKey); ////////////////////////////////////////////////////////////////
      // setup initial filter data

      var returnData = {};
      Ember.set(this.filterGroups[stFilterGroupKey], 'fullyLoaded', false);

      for (var index = 0; index < actualFilterKeys.length; index++) {
        var currActualFilterKey = actualFilterKeys[index]; // put empty data in so we know how many to expect in total

        this.filterGroups[stFilterGroupKey]['filters'][stFilterKey]['actualFilters'][currActualFilterKey] = null;
        this.filterGroups[stFilterGroupKey]['filters'][stFilterKey]['defaultValue'] = null;
        Ember.set(this.filterGroups[stFilterGroupKey]['filters'][stFilterKey], 'loaded', false);

        if (defaultValue != null && defaultValue.value != null && defaultValue.callback) {
          this.filterGroups[stFilterGroupKey]['filters'][stFilterKey]['defaultValue'] = defaultValue;
        }

        var actualFilterData = this.checkForSessionDataAndPopulate(stFilterGroupKey, stFilterKey, currActualFilterKey);

        if (actualFilterData) {
          returnData[currActualFilterKey] = actualFilterData;
        }
      }

      return returnData;
    }

    checkForSessionDataAndPopulate(stFilterGroupKey, stFilterKey, currActualFilterKey) {
      this.setupInitialDataByGroupAndFilterKey(stFilterGroupKey, stFilterKey); // see if data is stored in the session for this data point

      var key = "data.".concat(this.buildSessionSaveKey(stFilterGroupKey, stFilterKey, currActualFilterKey));
      var sessionActualFilterData = this.session.get(key);

      if (sessionActualFilterData != null) {
        var actualFilterData = null;

        try {
          actualFilterData = JSON.parse(sessionActualFilterData);
          this.filterGroups[stFilterGroupKey]['filters'][stFilterKey]['actualFilters'][currActualFilterKey] = actualFilterData;
          return actualFilterData;
        } catch (e) {// means the json failed to parse so do nothing with the cache
        }
      }

      return false;
    }

    recalculateFilterGroupCounts(stFilterGroupKey) {
      var totalCount = 0;
      var foundFilterNotAtDefaultState = false;
      var stFilterGroupsArr = Object.keys(this.filterGroups[stFilterGroupKey]['filters']);

      for (var index = 0; index < stFilterGroupsArr.length; index++) {
        var stFilterKey = stFilterGroupsArr[index];
        var currFilter = this.filterGroups[stFilterGroupKey]['filters'][stFilterKey];
        var actualFiltersArr = Object.keys(currFilter['actualFilters']);
        var foundANotNull = false;

        for (var subIndex = 0; subIndex < actualFiltersArr.length; subIndex++) {
          var actualFilterKey = actualFiltersArr[subIndex];

          if (currFilter['actualFilters'][actualFilterKey] != null) {
            foundANotNull = true;
            break;
          }
        }

        if (foundANotNull) {
          totalCount++;
        } // figure out if filter is resetable (ie - not at the default state)


        if (currFilter['defaultValue']) {
          for (var _subIndex = 0; _subIndex < actualFiltersArr.length; _subIndex++) {
            var _actualFilterKey = actualFiltersArr[_subIndex];
            var defaultValue = currFilter['defaultValue'].value != null ? currFilter['defaultValue'].value : currFilter['defaultValue'];
            var fieldValue = currFilter['actualFilters'][_actualFilterKey] != null ? currFilter['actualFilters'][_actualFilterKey]['fieldValue'] : null;

            if (fieldValue == null || fieldValue != defaultValue) {
              foundFilterNotAtDefaultState = true;
              break;
            }
          }
        } else if (foundANotNull) {
          foundFilterNotAtDefaultState = true;
        }
      }

      if (this.filterGroupCounts[stFilterGroupKey] != totalCount) {
        Ember.run.later(() => {
          Ember.set(this.filterGroupCounts, stFilterGroupKey, totalCount);
          Ember.notifyPropertyChange(this.filterGroupCounts, stFilterGroupKey);
        });
      }

      Ember.run.later(() => {
        Ember.set(this.filterGroupResetable, stFilterGroupKey, foundFilterNotAtDefaultState);
        Ember.notifyPropertyChange(this.filterGroupResetable, stFilterGroupKey);
      });
    }

    saveFilter(stFilterGroupKey, stFilterKey, actualFilterKey, actualFilter, fieldValue, doNotCache = false, overrideFilterKey = undefined) {
      this.setupInitialDataByGroupAndFilterKey(stFilterGroupKey, stFilterKey); // means we don't actually save the filter and should clear it ....

      if (actualFilter === false) {
        this.clearFilter(stFilterGroupKey, stFilterKey, actualFilterKey);
        return;
      }

      if (stFilterGroupKey == null || stFilterKey == null || actualFilterKey == null || actualFilter == null || fieldValue == null) {
        throw 'error in saveFilter - stFilterGroupKey == null || stFilterKey == null || actualFilterKey == null || actualFilter == null || fieldValue == null';
      }

      var fieldValueJson;

      if (fieldValue.toJSON) {
        fieldValueJson = fieldValue.toJSON();
        fieldValueJson.id = fieldValue.id;
      } else {
        fieldValueJson = fieldValue;
      }

      var data = {
        actualFilter: actualFilter,
        fieldValue: fieldValueJson,
        overrideFilterKey: overrideFilterKey
      };
      this.filterGroups[stFilterGroupKey]['filters'][stFilterKey]['actualFilters'][actualFilterKey] = data;

      if (doNotCache === false) {
        this.session.set("data.".concat(this.buildSessionSaveKey(stFilterGroupKey, stFilterKey, actualFilterKey)), JSON.stringify(data));
      }

      this.setAsLoaded(stFilterGroupKey, stFilterKey);
    }

    clearFilter(stFilterGroupKey, stFilterKey, actualFilterKey) {
      this.session.set("data.".concat(this.buildSessionSaveKey(stFilterGroupKey, stFilterKey, actualFilterKey)), null);
      this.filterGroups[stFilterGroupKey]['filters'][stFilterKey]['actualFilters'][actualFilterKey] = null;
    }

    clearFiltersByGroupKey(stFilterGroupKey) {
      var callbackFunction = () => {
        if (this.filterGroups[stFilterGroupKey]) {
          Ember.set(this.filterGroups[stFilterGroupKey], 'fullyLoaded', false);
          var stFilterGroupsArr = Object.keys(this.filterGroups[stFilterGroupKey]['filters']);

          for (var index = 0; index < stFilterGroupsArr.length; index++) {
            var stFilterKey = stFilterGroupsArr[index];
            Ember.set(this.filterGroups[stFilterGroupKey]['filters'][stFilterKey], 'loaded', false);
          }

          for (var _index = 0; _index < stFilterGroupsArr.length; _index++) {
            var _stFilterKey = stFilterGroupsArr[_index];
            var currFilter = this.filterGroups[stFilterGroupKey]['filters'][_stFilterKey];
            var actualFiltersArr = Object.keys(currFilter['actualFilters']);

            for (var subIndex = 0; subIndex < actualFiltersArr.length; subIndex++) {
              var actualFilterKey = actualFiltersArr[subIndex];
              this.clearFilter(stFilterGroupKey, _stFilterKey, actualFilterKey);
            }

            if (currFilter['defaultValue'] != null && currFilter['defaultValue'].value != null && currFilter['defaultValue'].callback) {
              currFilter['defaultValue'].callback(currFilter['defaultValue'].value);
            } else {
              this.setAsLoaded(stFilterGroupKey, _stFilterKey);
            }
          }
        }
      };

      return callbackFunction;
    }

    getActualFilterOrUseCallback(stFilterAttribute, value, actualFilterString) {
      var actualFilter = false;

      if (stFilterAttribute instanceof Function) {
        actualFilter = stFilterAttribute(value);
      } else if (stFilterAttribute !== false) {
        actualFilter = actualFilterString;
      }

      return actualFilter;
    }

    powerSelectSave(context, stFilterOptions) {
      var stFilterOptionsMerged = _lodash.default.merge({
        valueProperty: 'id',
        powerSelectDoOrNull: true,
        stFilterDoNotCache: false
      }, stFilterOptions);

      var callbackFunction = selectedOptions => {
        Ember.set(context, stFilterOptionsMerged.powerSelectFieldKey, selectedOptions);
        var powerSelectValuesIn = selectedOptions.map(powerSelectData => {
          var currPowerSelectValue = Ember.get(powerSelectData, stFilterOptionsMerged.valueProperty);

          if (stFilterOptionsMerged.valueProperty == 'id' && Number.isInteger(currPowerSelectValue)) {
            return currPowerSelectValue ? "".concat(currPowerSelectValue) : null;
          } else {
            return currPowerSelectValue ? "'".concat(currPowerSelectValue, "'") : null;
          }
        });
        var theFilter = '';
        var nullIndex = powerSelectValuesIn.indexOf(null);

        if (nullIndex === -1) {
          nullIndex = powerSelectValuesIn.indexOf('null');
        }

        if (stFilterOptionsMerged.powerSelectInOrOut == null || stFilterOptionsMerged.powerSelectInOrOut == 'IN') {
          if (nullIndex !== -1) {
            powerSelectValuesIn.splice(nullIndex, 1);

            if (powerSelectValuesIn.length > 0) {
              theFilter = "".concat(stFilterOptionsMerged.stFilterAttribute, " = [").concat(powerSelectValuesIn.join(','), "]");

              if (stFilterOptionsMerged.powerSelectDoOrNull) {
                theFilter += " or ".concat(stFilterOptionsMerged.stFilterAttribute, " = null");
              }
            } else {
              theFilter = "".concat(stFilterOptionsMerged.stFilterAttribute, " = null");
            }
          } else {
            theFilter = "".concat(stFilterOptionsMerged.stFilterAttribute, " = [").concat(powerSelectValuesIn.join(','), "]");
          }
        } else if (stFilterOptionsMerged.powerSelectInOrOut == 'OUT') {
          if (nullIndex === -1) {
            theFilter = "".concat(stFilterOptionsMerged.stFilterAttribute, " != [").concat(powerSelectValuesIn.join(','), "]");

            if (stFilterOptionsMerged.powerSelectDoOrNull) {
              theFilter += " or ".concat(stFilterOptionsMerged.stFilterAttribute, " = null");
            }
          } else {
            powerSelectValuesIn.splice(nullIndex, 1);

            if (powerSelectValuesIn.length > 0) {
              theFilter = "".concat(stFilterOptionsMerged.stFilterAttribute, " != [").concat(powerSelectValuesIn.join(','), "]");

              if (stFilterOptionsMerged.powerSelectDoOrNull) {
                theFilter += " or ".concat(stFilterOptionsMerged.stFilterAttribute, " != null");
              }
            } else {
              theFilter = "".concat(stFilterOptionsMerged.stFilterAttribute, " != null");
            }
          }
        }

        if (selectedOptions.length > 0) {
          // convert list to json
          var convertedListJson = [];

          for (var index = 0; index < selectedOptions.length; index++) {
            var currOption = selectedOptions.objectAt(index);
            var fieldValueJson = void 0;

            if (currOption.toJSON) {
              fieldValueJson = currOption.toJSON();
              fieldValueJson.id = currOption.id;
            } else {
              fieldValueJson = currOption;
            }

            convertedListJson.push(fieldValueJson);
          }

          this.saveFilter(stFilterOptionsMerged.stFilterGroupKey, stFilterOptionsMerged.stFilterKey, stFilterOptionsMerged.stFilterActualFilterKey, this.getActualFilterOrUseCallback(stFilterOptionsMerged.stFilterAttribute, convertedListJson, theFilter), convertedListJson, stFilterOptionsMerged.stFilterDoNotCache, stFilterOptionsMerged.overrideFilterKey);
        } else {
          this.clearFilter(stFilterOptionsMerged.stFilterGroupKey, stFilterOptionsMerged.stFilterKey, stFilterOptionsMerged.stFilterActualFilterKey);
        }
      };

      return callbackFunction;
    }

    buildSessionSaveKey(stFilterGroupKey, stFilterKey, actualFilterKey) {
      return "".concat(stFilterGroupKey, "::").concat(stFilterKey, "::").concat(actualFilterKey);
    }

    getFiltersByGroupKey(stFilterGroupKey, defaultFilter = [], keysToIgnore = {}) {
      return this.getFiltersByGroupKeyInternal.perform(stFilterGroupKey, defaultFilter, false, keysToIgnore);
    }

    getFiltersByGroupKeyAsObject(stFilterGroupKey, defaultFilter = [], keysToIgnore = {}) {
      return this.getFiltersByGroupKeyInternal.perform(stFilterGroupKey, defaultFilter, true, keysToIgnore);
    }

    setAsLoaded(stFilterGroupKey, stFilterKey) {
      if (!this.filterGroups[stFilterGroupKey] || !this.filterGroups[stFilterGroupKey]['filters'][stFilterKey]) {
        throw error('using setAsLoadedFalse wrong');
      }

      Ember.set(this.filterGroups[stFilterGroupKey]['filters'][stFilterKey], 'loaded', true);
      var stFilterGroupsArr = Object.keys(this.filterGroups[stFilterGroupKey]['filters']);
      var fullyLoaded = true;

      for (var index = 0; index < stFilterGroupsArr.length; index++) {
        var _stFilterKey2 = stFilterGroupsArr[index];
        var currFilter = this.filterGroups[stFilterGroupKey]['filters'][_stFilterKey2];

        if (currFilter['loaded'] === false) {
          fullyLoaded = false;
        }
      }

      if (fullyLoaded) {
        if (!this.filterGroups[stFilterGroupKey]['fullyLoaded']) {
          this.recalculateFilterGroupCounts(stFilterGroupKey);
        }

        Ember.set(this.filterGroups[stFilterGroupKey], 'fullyLoaded', true);
      }
    }

    loadMiscFiltersListByGroupKeyFilterKey(stFilterGroupKey, stFilterKey) {
      var miscFilters = new _miscInputList.default();
      var miscInputs = Ember.A();

      for (var index = 0; index < 100; index++) {
        // only check up to 100 indexs
        var actualFilterData = this.checkForSessionDataAndPopulate(stFilterGroupKey, "".concat(stFilterKey).concat(index), 'miscFilterValue');

        if (actualFilterData) {
          miscInputs.push(actualFilterData.fieldValue);
        } else {
          break;
        }
      }

      miscFilters.miscInputs = miscInputs;
      miscFilters.addMiscInput();
      return miscFilters;
    }

    testCompareFilters(oldFilters, newFilters) {
      var oldFiltersSorted = _lodash.default.sortBy(oldFilters);

      var newFiltersSorted = _lodash.default.sortBy(newFilters);

      var doesNotMatch = [];

      for (var index = 0; index < oldFiltersSorted.length; index++) {
        var currOldFilter = oldFiltersSorted[index];
        var currNewFilter = newFiltersSorted[index];

        if (currOldFilter !== currNewFilter) {
          doesNotMatch.push({
            currOldFilter: currOldFilter,
            currNewFilter: currNewFilter
          });
        }
      }

      if (doesNotMatch.length === 0) {
        console.log('ALL MATCH testCompareFilters', newFiltersSorted, oldFiltersSorted); // eslint-disable-line
      } else {
        console.log('DOES NOT MATCH testCompareFilters', doesNotMatch); // eslint-disable-line

        console.log('oldFilters', oldFiltersSorted); // eslint-disable-line

        console.log('newFilters', newFiltersSorted); // eslint-disable-line
      }
    } /////////////////////////////////////////
    // Reusable option lists


    get activeOptions() {
      return [{
        name: this.intl.t('common.status.active'),
        value: 'true'
      }, {
        name: this.intl.t('common.status.inactive'),
        value: 'false'
      }];
    }

    get yesNoOptions() {
      return [{
        name: this.intl.t('common.status.yes'),
        value: true
      }, {
        name: this.intl.t('common.status.no'),
        value: false
      }];
    }

    get basicMiscFilterOperators() {
      return [{
        option: this.intl.t('common.filters.equals'),
        value: '='
      }, {
        option: this.intl.t('common.filters.not_equal'),
        value: '!='
      }, {
        option: this.intl.t('common.filters.greater_than'),
        value: '>'
      }, {
        option: this.intl.t('common.filters.less_than'),
        value: '<'
      }];
    } ///////////////////////////////////////////////////////////////////////////////////////////////
    // CUSTOM REUSABLE FILTER CALLBACKS FOR UNIQUE FILTER SITUATIONS
    // NOTE: 
    //   returning a string means use that string as the filter
    //   returning an array of strings means to add all the strings
    //   returning false means not to add the filter at all and clear it if it exists


  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "filterGroups", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "filterGroupCounts", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "filterGroupResetable", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "getFiltersByGroupKeyInternal", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = StFiltersService;
});