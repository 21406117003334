define("securitytrax-ember-ui/models/package", ["exports", "securitytrax-ember-ui/models/base_models/package"], function (_exports, _package) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _package.default.extend({
    totalItems: Ember.computed('packageEquipment.length', function () {
      return this.packageEquipment.get('length');
    }),
    totalQuantity: Ember.computed('packageEquipment.@each.quantity', function () {
      return this.packageEquipment.reduce((newTotalQuantity, equipmentItem) => {
        return newTotalQuantity += +equipmentItem.get('quantity');
      }, 0);
    }),
    totalItemCharge: Ember.computed('packageEquipment.@each.itemCharge', function () {
      return this.packageEquipment.reduce((newTotalCharge, equipmentItem) => {
        return newTotalCharge += +equipmentItem.get('itemCharge') * +equipmentItem.get('quantity');
      }, 0);
    }),
    totalCost: Ember.computed('packageEquipment.@each.equipment', function () {
      return this.packageEquipment.reduce((newTotalCharge, equipmentItem) => {
        return newTotalCharge += +equipmentItem.get('equipment.cost') * +equipmentItem.get('quantity');
      }, 0);
    })
  });

  _exports.default = _default;
});