define("securitytrax-ember-ui/components/customer/dashboard/plans-services/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ShxXopeP",
    "block": "{\"symbols\":[\"data\"],\"statements\":[[8,\"html/st-await\",[],[[\"@useSpinner\",\"@booleanLoaded\"],[true,[34,0,[\"isIdle\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"html/st-card/body\",[[24,0,\"st-card__body--full-height\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[14,0,\"st-card__cell plans-services-card__mmr-wrapper\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"plans-services-card__mmr-badge\"],[12],[2,\"\\n                \"],[10,\"div\"],[14,0,\"plans-services-card__icon-wrap\"],[12],[2,\"\\n                    \"],[10,\"div\"],[14,0,\"plans-services-card__mmr\"],[12],[2,\"$\"],[1,[35,1,[\"mmr\",\"amount\"]]],[13],[2,\"\\n                \"],[13],[2,\"\\n\\n                \"],[10,\"div\"],[14,0,\"plans-services-card__price-wrap\"],[12],[2,\"\\n                    \"],[10,\"div\"],[14,0,\"plans-services-card__icon-sync\"],[12],[1,[30,[36,2],[\"icn-sync\"],null]],[13],[2,\"\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"st-card__cell plans-services-card__plan-wrapper\"],[12],[2,\"\\n            \"],[1,[35,1,[\"monitoringPlan\",\"name\"]]],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"plansServicesTask\",\"plansServices\",\"svg-icon\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/customer/dashboard/plans-services/template.hbs"
    }
  });

  _exports.default = _default;
});