define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/interactive-services-providers/adc/leads/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/interactive-services-providers/adc/interactive-services-provider-base", "ember-concurrency"], function (_exports, _interactiveServicesProviderBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _interactiveServicesProviderBase.default.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    disabledProgressBarSteps: Ember.computed(function () {
      return [];
    }),
    hiddenProgressBarSteps: Ember.computed(function () {
      return [];
    }),
    leadCompanies: Ember.computed(function () {
      return this.grants.getIndividualGrant('lead_companies.view') ? this.store.findAll('leadCompany', {
        include: 'lead_company_campaigns'
      }) : [];
    }),
    leadCompanyCampaigns: Ember.computed(function () {
      return this.grants.getIndividualGrant('lead_company_campaigns.view') ? this.store.findAll('leadCompanyCampaign') : [];
    }),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData("leads", 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.saveInteractiveServicesProviderConfig.perform(multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary interactive-services-providers-save-button'
      }]);
    },

    leadCompanyList: Ember.computed('leadCompanies', function () {
      return this.leadCompanyListTask.perform();
    }),
    leadCompanyListTask: (0, _emberConcurrency.task)(function* () {
      var leadCompanies = yield this.leadCompanies;
      var list = [];
      leadCompanies.forEach(company => {
        list.push({
          name: company.get('name'),
          id: company.get('id')
        });
      });
      return list;
    }),
    referralCampaigns: Ember.computed('multiStepForm.formCopiedModels.interactiveServicesProvider.model.interactiveServicesProviderConfig.referralLeadCompanyId', function () {
      return this.getCampaigns.perform(this.multiStepForm.formCopiedModelsGetVal('interactiveServicesProvider.model.interactiveServicesProviderConfig.referralLeadCompanyId'));
    }),
    dealerSiteCampaigns: Ember.computed('multiStepForm.formCopiedModels.interactiveServicesProvider.model.interactiveServicesProviderConfig.dealerWebsiteLeadCompanyId', function () {
      return this.getCampaigns.perform(this.multiStepForm.formCopiedModelsGetVal('interactiveServicesProvider.model.interactiveServicesProviderConfig.dealerWebsiteLeadCompanyId'));
    }),
    selfCreatedCampaigns: Ember.computed('multiStepForm.formCopiedModels.interactiveServicesProvider.model.interactiveServicesProviderConfig.selfCreatedLeadCompanyId', function () {
      return this.getCampaigns.perform(this.multiStepForm.formCopiedModelsGetVal('interactiveServicesProvider.model.interactiveServicesProviderConfig.selfCreatedLeadCompanyId'));
    }),
    referFriendCampaigns: Ember.computed('multiStepForm.formCopiedModels.interactiveServicesProvider.model.interactiveServicesProviderConfig.referAFriendLeadCompanyId', function () {
      return this.getCampaigns.perform(this.multiStepForm.formCopiedModelsGetVal('interactiveServicesProvider.model.interactiveServicesProviderConfig.referAFriendLeadCompanyId'));
    }),
    getCampaigns: (0, _emberConcurrency.task)(function* (leadCompanyId) {
      var leadCompanies = yield this.leadCompanies;
      var campaignList = [];

      if (leadCompanies) {
        var leadCompany = yield leadCompanies.findBy('id', leadCompanyId);

        if (leadCompany) {
          yield leadCompany.get('leadCompanyCampaigns').forEach(campaign => {
            campaignList.push({
              name: campaign.get('name'),
              id: campaign.get('id')
            });
          });
        }
      }

      return campaignList;
    })
  });

  _exports.default = _default;
});