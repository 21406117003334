define("securitytrax-ember-ui/services/credit-card-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Map of Credit Card types and associated "customerBilling.billCcType" value.
   *
   * @class CreditCardTypes
   * @memberof services
   * @extends Ember.Service
   */
  var _default = Ember.Service.extend(
  /** @lends services.CreditCardTypes.prototype */
  {
    init() {
      this._super(...arguments);

      this.set('cardTypes', {
        visaelectron: 'Visa',
        maestro: 'Master',
        forbrugsforeningen: 'Other',
        dankort: 'Other',
        visa: 'Visa',
        mastercard: 'MasterCard',
        amex: 'American Express',
        dinersclub: 'MasterCard',
        discover: 'Discover',
        unionpay: 'Other',
        jcb: 'Other'
      });
    }

  });

  _exports.default = _default;
});