define("securitytrax-ember-ui/components/multi-step-form/switchable-inputs/wysiwyg-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uTyWsS6o",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,10],null,[[\"label\",\"required\",\"errors\"],[[35,9],[35,3],[35,8]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,7],null,[[\"options\",\"value\",\"name\",\"showView\",\"required\",\"onValueChanged\"],[[35,6],[35,0],[35,5],[35,4],[35,3],[30,[36,2],[[32,0],[30,[36,1],[[35,0]],null]],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"value\",\"mut\",\"action\",\"required\",\"showView\",\"name\",\"options\",\"html/tinymce\",\"fieldErrors\",\"label\",\"html/input-wrapper\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/switchable-inputs/wysiwyg-input/template.hbs"
    }
  });

  _exports.default = _default;
});