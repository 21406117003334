define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/accounting-providers/edit-create/component", ["exports", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "ember-concurrency", "lodash"], function (_exports, _component, _switchableFormStep, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EditCreate = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
    this.SwitchableFormStep.progressBarHidden = this.SwitchableFormStep.isNewRecord;
    this.quickbooksConnected = yield this.quickbooksService.quickbooksIsConnected.perform(this.args.currAccountingProvider);
    var currAccountingAccountsData = yield this.quickbooksService.quickbooksAccounts.perform(this.args.currAccountingProvider);
    this.incomeAccountOptions = _lodash.default.filter(currAccountingAccountsData, o => {
      return o.account_type == 'Income';
    });
    this.refundPaymentAccountOptions = _lodash.default.filter(currAccountingAccountsData, o => {
      return o.account_type == 'Other Current Asset' || o.account_type == 'Bank';
    });
    this.accountMappingData = yield this.quickbooksService.quickbooksAccountMapping.perform(this.args.currAccountingProvider);
  }), _dec16 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    yield this.args.multiStepForm.validateSyncSaveAll();
    yield this.quickbooksService.quickbooksSetAccountMapping.perform(this.args.currAccountingProvider, this.accountMappingData);
    buttonPromise.then(() => {
      this.args.multiStepForm.resetForm(false);
      this.args.setFormMode('view');
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
    });
  }), _dec17 = (0, _emberConcurrency.task)(function* () {
    try {
      Ember.set(this.args.multiStepForm, 'formCopiedModels.quickbooksIntegration.model.quickbooksIntegrationConfig.active', false);
      yield this.args.multiStepForm.validateSyncSaveAll();
      this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
      var formOriginalModels = yield this.args.multiStepForm.formOriginalModels;
      var qbIntegration = Ember.get(formOriginalModels, 'quickbooksIntegration.model');
      yield qbIntegration.unloadRecord();
      yield this.router.transitionTo('/administration/company-settings');
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'confirmDeleteModal'
      });
      throw err;
    }
  }), _dec18 = (0, _emberConcurrency.task)(function* () {
    // try {
    var currHref = encodeURI(window.location.href);
    var dummyModel = this.store.createRecord('quickbooksIntegration');
    var returnData = yield dummyModel.invokeAction('get_auth_url', {
      verb: 'POST',
      data: {
        data: {
          redirect_url: currHref
        }
      },
      processResponse: true
    });
    dummyModel.rollbackAttributes();

    var quickbooksAuthUrl = _lodash.default.get(returnData, 'responsePayload.data.auth_url', false);

    if (quickbooksAuthUrl) {
      window.open(quickbooksAuthUrl, '_self');
    } // } catch (e) {
    //     console.log('e', e);
    // }

  }), _dec19 = (0, _emberConcurrency.task)(function* (value) {
    var originalModel = yield this.args.multiStepForm.formOriginalModels;

    if (!originalModel.quickbooksIntegration.model.autoSync && value) {
      this.autoSyncConfirmationModalOpen = true;
    }
  }), _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, (_class = (_temp = class EditCreate extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "grants", _descriptor4, this);

      _initializerDefineProperty(this, "quickbooksService", _descriptor5, this);

      _initializerDefineProperty(this, "router", _descriptor6, this);

      _initializerDefineProperty(this, "confirmDeleteModalOpen", _descriptor7, this);

      _initializerDefineProperty(this, "quickbooksConnected", _descriptor8, this);

      _initializerDefineProperty(this, "quickbooksLinkConfirmOpen", _descriptor9, this);

      _initializerDefineProperty(this, "accountMappingData", _descriptor10, this);

      _initializerDefineProperty(this, "incomeAccountOptions", _descriptor11, this);

      _initializerDefineProperty(this, "refundPaymentAccountOptions", _descriptor12, this);

      _initializerDefineProperty(this, "formMode", _descriptor13, this);

      _initializerDefineProperty(this, "autoSyncConfirmationModalOpen", _descriptor14, this);

      _initializerDefineProperty(this, "initFormData", _descriptor15, this);

      _initializerDefineProperty(this, "saveAccountingProvider", _descriptor16, this);

      _initializerDefineProperty(this, "deleteQuickbooksIntegration", _descriptor17, this);

      _initializerDefineProperty(this, "linkQuickbooksTask", _descriptor18, this);

      _initializerDefineProperty(this, "showAutoSyncConfirmationModal", _descriptor19, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments, {
        disabledProgressBarSteps: this.disabledProgressBarSteps,
        hiddenProgressBarSteps: this.hiddenProgressBarSteps,
        progressBarHidden: true
      });
      this.initFormData.perform();
    }

    get disabledProgressBarSteps() {
      return [];
    }

    get hiddenProgressBarSteps() {
      return [];
    }

    resetAutoCreditFlag() {
      return this.quickbooksService.resetAutoCreditFlag.perform(this.args.currAccountingProvider);
    }

    linkQuickbooks() {
      this.quickbooksLinkConfirmOpen = true;
    }

    cancelAutoSync() {
      this.autoSyncConfirmationModalOpen = false;
      this.args.multiStepForm.formCopiedModelsSetVal('quickbooksIntegration.model.quickbooksIntegrationConfig.autoSync', false);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "quickbooksService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "confirmDeleteModalOpen", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "quickbooksConnected", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "quickbooksLinkConfirmOpen", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "accountMappingData", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "incomeAccountOptions", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "refundPaymentAccountOptions", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "formMode", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'view';
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "autoSyncConfirmationModalOpen", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "initFormData", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "saveAccountingProvider", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "deleteQuickbooksIntegration", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "linkQuickbooksTask", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "showAutoSyncConfirmationModal", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "resetAutoCreditFlag", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "resetAutoCreditFlag"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "linkQuickbooks", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "linkQuickbooks"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelAutoSync", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "cancelAutoSync"), _class.prototype)), _class));
  _exports.default = EditCreate;
});