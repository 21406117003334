define("securitytrax-ember-ui/mixed-in-classes/switchable-form-step", ["exports", "@glimmer/component", "ember-concurrency", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _component, _emberConcurrency, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var SwitchableFormStep = (_dec = Ember.inject.service('screen'), _dec2 = Ember.inject.service('st-await'), _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = (0, _emberConcurrency.task)(function* (fullyLoaded) {
    // focusOnFirstInputInForm
    if (fullyLoaded) {
      yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true); // commenting for now because this has issues
      // next(() => {
      //     const inputs = jQuery(
      //         '.form-mode-edit input:not([type="checkbox"]),' +
      //         '.form-mode-create input:not([type="checkbox"]),' +
      //         '.form-mode-create .ember-power-select-trigger,' +
      //         '.form-mode-edit .ember-power-select-trigger'
      //     );
      //     inputs.first().focus();
      // });
    }
  }).restartable(), _dec7 = Ember._action, _dec8 = Ember._action, (_class = (_temp = class SwitchableFormStep extends _component.default {
    constructor(classArguments, templateArguments, optionalArguments) {
      super(...arguments);

      _initializerDefineProperty(this, "screen", _descriptor, this);

      _initializerDefineProperty(this, "stAwait", _descriptor2, this);

      _initializerDefineProperty(this, "hiddenProgressBarSteps", _descriptor3, this);

      _initializerDefineProperty(this, "disabledProgressBarSteps", _descriptor4, this);

      _initializerDefineProperty(this, "progressBarHidden", _descriptor5, this);

      _defineProperty(this, "loadedNamespace", undefined);

      _defineProperty(this, "possibleFormModes", {
        view: true,
        edit: true,
        create: true,
        inlineEdit: true
      });

      _initializerDefineProperty(this, "focusOnFirstInputInForm", _descriptor6, this);

      if (optionalArguments) {
        this.hiddenProgressBarSteps = optionalArguments.hiddenProgressBarSteps;
        this.disabledProgressBarSteps = optionalArguments.disabledProgressBarSteps;
        this.progressBarHidden = optionalArguments.progressBarHidden;
      }

      if (!this.args.formMode) {
        throw new Error('You must pass the formMode into a form step');
      }

      if (!this.args.multiStepForm) {
        throw new Error('You must pass the multistep form into a form step');
      }

      (0, _objHelpers.initializeState)(this, {
        loadedNamespace: 'global',
        formMode: 'view'
      }); ////////////////////////////////////////////////////////////////////////////////
      // see if we should set all inputs to view mode based on loading/saving status

      var stAwait = this.stAwait;
      var fullyLoadedState = stAwait.getCurrentFullyLoadedStateByNamespace({
        loadedNamespace: this.loadedNamespace
      });

      if (this && !this.isDestroyed) {
        this.focusOnFirstInputInForm.perform(fullyLoadedState.fullyLoaded);
      }

      stAwait.on('st-await-loaded-state-by-namespace-' + this.loadedNamespace, this, '_onLoadedStateChange');
    }

    willDestroy() {
      this.stAwait.off('st-await-loaded-state-by-namespace-' + this.loadedNamespace, this, '_onLoadedStateChange');
    }

    _onLoadedStateChange(loadedData) {
      Ember.run.next(() => {
        if (this && !this.isDestroyed) {
          this.focusOnFirstInputInForm.perform(loadedData.fullyLoaded);
        }
      });
    }

    get isMobile() {
      return this.screen.isMobile;
    }

    get progressBarConfig() {
      var items = [];
      var multiStepForm = this.args.multiStepForm;
      var formSteps = this.args.multiStepForm.args.formSteps;
      var currentStep = this.args.multiStepForm.currentStepNameKey;
      Object.keys(formSteps).forEach(key => {
        if (typeof formSteps[key] === 'object') {
          var isHidden = this.hiddenProgressBarSteps.includes(key) || formSteps[key].granted != null && !formSteps[key].granted || formSteps[key].hidden;
          var isClickable = !this.disabledProgressBarSteps.includes(key);
          items.push({
            title: formSteps[key].progressBarTitle,
            stepNameKey: formSteps[key].stepNameKey,
            isClickable: isClickable,
            hidden: isHidden,
            isComplete: false,

            clickAction(item) {
              multiStepForm.currentStepNameKeySetVal(item.stepNameKey); // multiStepForm.currentStepNameKeySetVal(item.stepNameKey);
            }

          });
        }
      });
      return {
        hideProgressBar: this.progressBarHidden,
        activeIndex: items.findIndex(item => item.stepNameKey === currentStep),
        viewTemplate: 'unordered-view',
        items: items
      };
    }

    get isNewRecord() {
      if (this.args.formMode === 'create') {
        return true;
      }

      return false;
    } // Not sure if we'll need this stuff, but keeping in case needs to be converted at a later time
    // classNames: ['multi-step-form-step'],
    // reloadButtonsOnFormModeChange: observer('formMode', function () {
    //     const buttonChoices = this.stepButtons;
    //     const multiStepForm = this.multiStepForm;
    //     if (buttonChoices) {
    //         multiStepForm.registerStepData(multiStepForm.currentStepNameKey, 'buttons', buttonChoices);
    //     }
    // }),


    changeFormMode(newMode) {
      if (newMode in this.possibleFormModes) {
        // Might not work like this - most likely need to extract and then call when formMode is defined
        this.formMode = newMode;
      } else {
        throw new Error('Trying to set an invalid form mode');
      }
    }

    validateProperty(originalModelKeyName, validationKey) {
      this.args.multiStepForm.validateProperty.perform(originalModelKeyName, validationKey);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "screen", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stAwait", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hiddenProgressBarSteps", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "disabledProgressBarSteps", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "progressBarHidden", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "focusOnFirstInputInForm", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "changeFormMode", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "changeFormMode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validateProperty", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "validateProperty"), _class.prototype)), _class));
  _exports.default = SwitchableFormStep;
});