define("securitytrax-ember-ui/components/org-chart/unassigned-tree/component", ["exports", "ember-concurrency", "lodash", "securitytrax-ember-ui/mixins/list/list-sort-mixin"], function (_exports, _emberConcurrency, _lodash, _listSortMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_listSortMixin.default, {
    sessionData: Ember.inject.service('sessionData'),
    orgTree: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service('store'),
    classNames: [''],
    mediaOrientation: 'st-media-object--portrait',
    userHierarchyTree: null,
    modalComponent: '',
    modalTitle: '',
    modalParentUser: null,
    showActionModal: false,

    init() {
      this._super(...arguments);
    },

    willUpdate() {
      this._super(...arguments);

      var orgTree = this.orgTree;
      Ember.run.later(() => {
        orgTree.set('showUnassignedLink', false);
      });
    },

    directoryUsers: Ember.computed(function () {
      return this.store.query('directoryUser', {
        include: 'user_hierarchies,user_hierarchy_title,user',
        limit: 0
      });
    }),
    wellUsers: Ember.computed('userHierarchy', function () {
      return this.getWellTask.perform();
    }),
    getWellTask: (0, _emberConcurrency.task)(function* () {
      var orgTree = this.orgTree;
      var userHierarchyTree = this.userHierarchyTree;

      if (!orgTree.get('userHierarchyTree')) {
        orgTree.set('userHierarchyTree', userHierarchyTree);
      }

      if (userHierarchyTree) {
        var hashData = yield (0, _emberConcurrency.hash)({
          uncategorizedUsers: userHierarchyTree.invokeAction('get_users_not_in_hierarchy'),
          directoryUser: this.store.query('directoryUser', {
            include: 'user_hierarchies,user_hierarchy_title,user',
            limit: 0
          })
        });

        var wellUsers = _lodash.default.intersectionBy(hashData.directoryUser.toArray(), hashData.uncategorizedUsers.responsePayload.data, 'id');

        var retVal = this.sort(wellUsers.toArray(), 'lname', 'asec');
        return retVal;
      }
    }),
    actions: {
      dropdownAction(user, modalType) {
        this.set('modalParentUser', user);
        this.set('actionModalType', modalType);

        if (modalType === 'add') {
          this.set('modalComponent', 'multi-step-form/forms/user-actions/add-to-user');
          this.set('modalTitle', this.intl.t('features.org-chart.modals.add_title'));
        }
        /*   else if(modalType === 'swap') {
              this.set('modalTitle', this.get('intl').t('features.org-chart.modals.swap_title'));
              this.set('modalComponent', 'multi-step-form/forms/user-actions/swap-user');
          } */


        this.set('showActionModal', true);
      }

    }
  });

  _exports.default = _default;
});