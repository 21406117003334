define("securitytrax-ember-ui/administration/company-settings/content-management/work-order-templates/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WlnItMEj",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"pages/administration/company-settings/content-management/work-order-templates\",[],[[\"@taskTypes\",\"@workOrderTypes\",\"@templateTypes\",\"@templateTypeColumns\",\"@model\",\"@breadCrumbs\",\"@breadCrumbData\"],[[32,1,[\"taskTypes\"]],[32,1,[\"workOrderTypes\"]],[32,1,[\"templateTypes\"]],[32,1,[\"templateTypeColumns\"]],[32,1],[32,0,[\"breadCrumbs\"]],[32,0,[\"breadCrumbData\"]]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/administration/company-settings/content-management/work-order-templates/template.hbs"
    }
  });

  _exports.default = _default;
});