define("securitytrax-ember-ui/mixins/customer-integrations-mixin", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    stNotificationManager: Ember.inject.service(),
    intl: Ember.inject.service(),
    unlinkAccount: (0, _emberConcurrency.task)(function* () {
      var customerSystemInformation = yield this.customer.customerSystemInformation;

      try {
        yield customerSystemInformation.invokeAction('unlink_cs_account', {
          verb: 'POST',
          data: {
            data: {
              type: 'customer_system_information_action'
            }
          }
        });
        yield (0, _emberConcurrency.all)([this.customer.reload(), customerSystemInformation.reload()]);
        this.set('showUnlinkConfirmModal', false);
        this.multiStepForm.formStartedSetVal(false);

        if (this.transitionBackToParent && typeof this.transitionBackToParent === 'function') {
          this.transitionBackToParent();
        }

        this.stNotificationManager.addSuccess(this.intl.t('common.notifications.unlink_successful'));
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'unlink-confirm-modal'
        });
        (false && Ember.warn('Unlink failed: ' + err, {
          id: 'customers.information.manage-integrations.unlink'
        }));
      }
    })
  });

  _exports.default = _default;
});