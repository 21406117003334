define("securitytrax-ember-ui/components/multi-step-form/forms/administration/equipment/add-equipment-chooser/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uni12iGu",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],null,[[\"label\"],[[30,[36,0],[\"common.actions.select_option\"],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"html/package-select/package-layouts/default-layout\",[[24,0,\"add-equipment-options small-12 medium-6 large-6\"]],[[\"@isLargeLabel\",\"@valueProperty\",\"@labelProperty\",\"@packageList\",\"@updateSelected\",\"@columnClasses\"],[true,\"description\",\"label\",[34,2],[30,[36,3],[[32,0],\"setCurrentStep\"],null],\"small-12 medium-6 large-6\"]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"form-group\",\"equipmentAddOptions\",\"action\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/equipment/add-equipment-chooser/template.hbs"
    }
  });

  _exports.default = _default;
});