define("securitytrax-ember-ui/components/pages/administration/company-settings/content-management/invoice-billable-items/component", ["exports", "@glimmer/component", "ember-concurrency", "securitytrax-ember-ui/mixed-in-classes/table-column-sort", "securitytrax-ember-ui/mixed-in-functions/checkbox-list-mixin"], function (_exports, _component, _emberConcurrency, _tableColumnSort, _checkboxListMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var InvoiceBillableItems = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service('events/bulk-edit'), _dec9 = Ember.inject.service, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._tracked, _dec21 = Ember._tracked, _dec22 = Ember._tracked, _dec23 = Ember._tracked, _dec24 = Ember._tracked, _dec25 = Ember._tracked, _dec26 = Ember._tracked, _dec27 = Ember._tracked, _dec28 = (0, _emberConcurrency.task)(function* () {
    this.invoiceBillableItemClassesOptions = yield this.store.findAll('invoiceBillableItemClass');
    this.billableItemEditList = Ember.A();
  }), _dec29 = Ember.computed(), _dec30 = (0, _emberConcurrency.task)(function* () {
    var newFilters = yield this.stFiltersService.getFiltersByGroupKey('invoiceBillableItems');
    var invoiceBillableItems = yield this.store.query('invoiceBillableItem', {
      filter: newFilters,
      sort: this.TableColumnSort.stSortString,
      limit: this.limit,
      page: this.page,
      count: 1,
      include: 'invoice_billable_item_class,equipment'
    });
    invoiceBillableItems.forEach(item => {
      item.set('isChecked', false);
    });
    this.clearMassEdit();
    Ember.set(this, 'totalCount', invoiceBillableItems.meta.record_count);
    return invoiceBillableItems;
  }), _dec31 = (0, _emberConcurrency.task)(function* (row) {
    var billableItems = yield this.getInvoiceBillableItems;
    var billableItemIndex = this.billableItemEditList.indexOf(row.get('id'));
    var isChecked = row.get('isChecked') ? false : true;

    if (isChecked) {
      if (billableItemIndex === -1) {
        this.billableItemEditList.pushObject(row);
        row.set('isChecked', true);
      }
    } else {
      this.billableItemEditList.removeObject(row);
      row.set('isChecked', false);
    }

    this.stBillableItemIndeterminate = this.billableItemEditList.length > 0 && this.billableItemEditList.length < billableItems.length;
  }), _dec32 = (0, _emberConcurrency.task)(function* () {
    var billableItems = (yield this.getInvoiceBillableItems).filter(item => !item.get('isSystem'));
    this.isBillableItemCheckAll = !this.isBillableItemCheckAll;
    var isChecked = this.isBillableItemCheckAll;

    if (isChecked) {
      var list = (0, _checkboxListMixin.checkAll)(billableItems);
      this.billableItemEditList = list;
    } else {
      (0, _checkboxListMixin.uncheckAll)(billableItems);
      this.billableItemEditList = Ember.A();
    }

    this.stBillableItemIndeterminate = false;
  }), _dec33 = Ember._action, _dec34 = Ember._action, _dec35 = Ember._action, _dec36 = Ember._action, _dec37 = Ember._action, _dec38 = Ember._action, (_class = (_temp = class InvoiceBillableItems extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      _initializerDefineProperty(this, "screen", _descriptor5, this);

      _initializerDefineProperty(this, "stFiltersService", _descriptor6, this);

      _initializerDefineProperty(this, "stMulticallService", _descriptor7, this);

      _initializerDefineProperty(this, "bulkEdit", _descriptor8, this);

      _initializerDefineProperty(this, "stDataListFast", _descriptor9, this);

      _defineProperty(this, "stSortableColumnOptions", undefined);

      _initializerDefineProperty(this, "invoiceBillableItemClassesOptions", _descriptor10, this);

      _initializerDefineProperty(this, "invoiceBillableItemsIdFilter", _descriptor11, this);

      _initializerDefineProperty(this, "invoiceBillableItemsNameFilter", _descriptor12, this);

      _initializerDefineProperty(this, "invoiceBillableItemsClassFilter", _descriptor13, this);

      _initializerDefineProperty(this, "billableItemStatus", _descriptor14, this);

      _initializerDefineProperty(this, "totalCount", _descriptor15, this);

      _initializerDefineProperty(this, "limit", _descriptor16, this);

      _initializerDefineProperty(this, "page", _descriptor17, this);

      _initializerDefineProperty(this, "showInvoiceBillableItemDeleteId", _descriptor18, this);

      _initializerDefineProperty(this, "invoiceBillableItem", _descriptor19, this);

      _initializerDefineProperty(this, "invoiceBillableItemFormStarted", _descriptor20, this);

      _initializerDefineProperty(this, "invoiceBillableItemFormMode", _descriptor21, this);

      _initializerDefineProperty(this, "billableItemEditList", _descriptor22, this);

      _initializerDefineProperty(this, "stBillableItemIndeterminate", _descriptor23, this);

      _initializerDefineProperty(this, "isBillableItemCheckAll", _descriptor24, this);

      _initializerDefineProperty(this, "editOptionsFormOpen", _descriptor25, this);

      _initializerDefineProperty(this, "billableItemMassEditModalOpen", _descriptor26, this);

      _initializerDefineProperty(this, "fieldsToEdit", _descriptor27, this);

      _initializerDefineProperty(this, "initTask", _descriptor28, this);

      _initializerDefineProperty(this, "getInvoiceBillableItemsTask", _descriptor29, this);

      _initializerDefineProperty(this, "updateBillableItemListTask", _descriptor30, this);

      _initializerDefineProperty(this, "billableItemCheckAllTask", _descriptor31, this);

      this.TableColumnSort = new _tableColumnSort.default(...arguments);
      this.TableColumnSort.stSortAscending = true;
      this.TableColumnSort.stSortCurrentSortColumn = 'code';
      this.initTask.perform();
      this.stSortableColumnOptions = [{
        name: this.intl.t('common.columns.id') + ' ' + this.intl.t('common.sorting.ascending'),
        value: 'id',
        chevronDirection: 'up'
      }, {
        name: this.intl.t('common.columns.id') + ' ' + this.intl.t('common.sorting.descending'),
        value: '-id',
        chevronDirection: 'down'
      }, {
        name: this.intl.t('common.columns.name') + ' ' + this.intl.t('common.sorting.ascending'),
        value: 'code',
        chevronDirection: 'up'
      }, {
        name: this.intl.t('common.columns.name') + ' ' + this.intl.t('common.sorting.descending'),
        value: '-code',
        chevronDirection: 'down'
      }, {
        name: this.intl.t('pages.administration.content_management.invoice_billable_item.taxable') + ' ' + this.intl.t('common.sorting.ascending'),
        value: 'is_taxable',
        chevronDirection: 'up'
      }, {
        name: this.intl.t('pages.administration.content_management.invoice_billable_item.taxable') + ' ' + this.intl.t('common.sorting.descending'),
        value: '-is_taxable',
        chevronDirection: 'up'
      }, {
        name: this.intl.t('common.columns.active') + ' ' + this.intl.t('common.sorting.ascending'),
        value: 'active',
        chevronDirection: 'up'
      }, {
        name: this.intl.t('common.columns.active') + ' ' + this.intl.t('common.sorting.descending'),
        value: '-active',
        chevronDirection: 'down'
      }];
    }

    get getInvoiceBillableItems() {
      return this.getInvoiceBillableItemsTask.perform();
    }

    get editableFields() {
      return [{
        name: this.intl.t('common.columns.name'),
        key: 'code',
        component: 'text-input'
      }, {
        name: this.intl.t('pages.administration.content_management.invoice_billable_item.class'),
        key: 'invoiceBillableItemClass',
        component: 'select-input',
        valueProperty: 'id',
        labelProperty: 'name',
        bindValueAsObject: true,
        options: this.invoiceBillableItemClassesOptions
      }, {
        name: this.intl.t('pages.administration.content_management.invoice_billable_item.defaultUnitPrice'),
        key: 'defaultUnitPrice',
        component: 'masked-input',
        mask: this.currency
      }, {
        name: this.intl.t('pages.administration.content_management.invoice_billable_item.taxable'),
        key: 'isTaxable',
        component: 'select-input',
        valueProperty: 'value',
        labelProperty: 'name',
        options: Ember.A([Ember.Object.create({
          name: this.intl.t('pages.administration.content_management.invoice_billable_item.ifNotExempt'),
          value: null
        }), Ember.Object.create({
          name: this.intl.t('pages.administration.content_management.invoice_billable_item.always'),
          value: 1
        }), Ember.Object.create({
          name: this.intl.t('pages.administration.content_management.invoice_billable_item.never'),
          value: 0
        })])
      }, {
        name: this.intl.t('common.columns.description'),
        key: 'description',
        component: 'textarea-input',
        rows: 4
      }, {
        name: this.intl.t('common.columns.active'),
        key: 'active',
        component: 'checkbox-input'
      }];
    }

    get nextButton() {
      return [{
        text: this.intl.t('common.actions.next'),
        click: () => {
          this.bulkEdit.trigger('update-billable-item-bulk-edit-list');
        },
        classNamesString: 'st-btn st-btn-primary'
      }];
    }

    get invoiceBillableItemFormSteps() {
      var viewOrAdd = this.invoiceBillableItemFormMode === 'create' ? 'create' : 'view';
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.intl.t("common.forms.descriptive_title_".concat(viewOrAdd), {
            id: this.invoiceBillableItem ? this.invoiceBillableItem.get('id') : null,
            name: this.invoiceBillableItem ? this.invoiceBillableItem.get('code') : this.intl.t('pages.administration.content_management.invoice_billable_item.title_view')
          }),
          stepComponentPath: 'multi-step-form/forms/administration/content-management/edit-invoice-billable-item'
        })
      });
    }

    get invoiceBillableItemFormOriginalModels() {
      return Ember.Object.create({
        invoiceBillableItem: {
          model: this.invoiceBillableItem,
          copyRelationMap: {
            invoiceBillableItemClass: {
              readOnly: true
            },
            equipment: {
              readOnly: true
            }
          }
        }
      });
    }

    clearMassEdit() {
      this.stBillableItemIndeterminate = false;
      this.isBillableItemCheckAll = false;
      this.billableItemEditList = Ember.A();
      this.massEditBillableItemFieldsList = Ember.A();
    }

    clearFilters() {
      this.invoiceBillableItemsIdFilter = null;
      this.invoiceBillableItemsNameFilter = null;
      this.invoiceBillableItemsClassFilter = [];
      this.billableItemStatus = undefined;
      Ember.notifyPropertyChange(this, 'getInvoiceBillableItems');
    }

    sortList(column, ascending) {
      this.TableColumnSort.sortPaginatedList(column, ascending);
      Ember.notifyPropertyChange(this, 'getInvoiceBillableItems');
    }

    mobileSortList(column) {
      this.TableColumnSort.sortMobilePaginatedList(column.value);
      Ember.notifyPropertyChange(this, 'getInvoiceBillableItems');
    }

    updateList() {
      Ember.notifyPropertyChange(this, 'getInvoiceBillableItems');
    }

    onSave(fields) {
      this.editOptionsFormOpen = false;
      this.billableItemMassEditModalOpen = true;
      this.fieldsToEdit = fields;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "screen", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "stFiltersService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "stMulticallService", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "bulkEdit", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "stDataListFast", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "invoiceBillableItemClassesOptions", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "invoiceBillableItemsIdFilter", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "invoiceBillableItemsNameFilter", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "invoiceBillableItemsClassFilter", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "billableItemStatus", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "totalCount", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "limit", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 50;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "showInvoiceBillableItemDeleteId", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "invoiceBillableItem", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "invoiceBillableItemFormStarted", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "invoiceBillableItemFormMode", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'edit';
    }
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "billableItemEditList", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "stBillableItemIndeterminate", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "isBillableItemCheckAll", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "editOptionsFormOpen", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "billableItemMassEditModalOpen", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "fieldsToEdit", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "initTask", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getInvoiceBillableItems", [_dec29], Object.getOwnPropertyDescriptor(_class.prototype, "getInvoiceBillableItems"), _class.prototype), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "getInvoiceBillableItemsTask", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "updateBillableItemListTask", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "billableItemCheckAllTask", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "clearMassEdit", [_dec33], Object.getOwnPropertyDescriptor(_class.prototype, "clearMassEdit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilters", [_dec34], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortList", [_dec35], Object.getOwnPropertyDescriptor(_class.prototype, "sortList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mobileSortList", [_dec36], Object.getOwnPropertyDescriptor(_class.prototype, "mobileSortList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateList", [_dec37], Object.getOwnPropertyDescriptor(_class.prototype, "updateList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSave", [_dec38], Object.getOwnPropertyDescriptor(_class.prototype, "onSave"), _class.prototype)), _class));
  _exports.default = InvoiceBillableItems;
});