define("securitytrax-ember-ui/models/equipment", ["exports", "securitytrax-ember-ui/models/base_models/equipment"], function (_exports, _equipment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _equipment.default.extend({
    massEditTitle: Ember.computed(function () {
      return "".concat(this.name, " (").concat(this.model, ")");
    })
  });

  _exports.default = _default;
});