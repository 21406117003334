define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/edit-create-credit-bureau/trans-union/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    productCodes: null,
    subscriberCode: Ember.computed('multiStepForm.formCopiedModels.creditBureau.model.creditBureauConfig', function () {
      var config = this.multiStepForm.formCopiedModelsGetVal('creditBureau.model.creditBureauConfig');

      if (config) {
        return (config.market || '') + (config.submarket || '') + (config.industryCode || '') + (config.memberCode || '');
      }

      return '';
    }),

    init() {
      this._super(...arguments);

      var productCodes = [{
        name: '07000',
        value: '07000'
      }, {
        name: '08000',
        value: '08000'
      }];
      this.set('productCodes', productCodes);
    },

    actions: {
      updateSubscriberCode() {
        var subScriberCode = this.subscriberCode;
        var config = this.multiStepForm.formCopiedModelsGetVal('creditBureau.model.creditBureauConfig');
        Ember.set(config, 'market', subScriberCode.substring(0, 2) ? subScriberCode.substring(0, 2) : '');
        Ember.set(config, 'submarket', subScriberCode.substring(2, 4) ? subScriberCode.substring(2, 4) : '');
        Ember.set(config, 'industryCode', subScriberCode.substring(4, 5) ? subScriberCode.substring(4, 5) : '');
        Ember.set(config, 'memberCode', subScriberCode.substring(5) ? subScriberCode.substring(5) : '');
      }

    }
  });

  _exports.default = _default;
});