define("securitytrax-ember-ui/mixed-in-classes/row-list", ["exports", "@glimmer/component", "ember-concurrency", "lodash"], function (_exports, _component, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var RowList = (_dec = Ember.inject.service('store'), _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = (0, _emberConcurrency.task)(function* (item, modelName, deleteOnSave = false) {
    var lineItems = this.listArray;

    if (item.get('id') && !item.get('id').match(/new\d+/)) {
      if (deleteOnSave) {
        var itemsToDelete = this.itemsToDeleteList;
        var newList = lineItems.filter(theItem => {
          return theItem != item;
        });
        this.listArray = newList;
        itemsToDelete[item.id] = item.id;
        Ember.notifyPropertyChange(this, 'itemsToDeleteList');
        item.set('deleting', true);
        item.set('deletingClass', 'marked-for-delete');
      } else {
        var lineItemToDelete = yield this.store.findRecord(modelName, item.get('id')); //if its an existing record (not new) destroy it

        if (lineItemToDelete) {
          yield lineItemToDelete.destroyRecordWithRollbackTask.perform();
        }
      }
    } //remove from list and copied models


    var filteredList = _lodash.default.filter(lineItems, function (theItem) {
      return item != theItem;
    });

    this.listArray = filteredList;
  }), (_class = (_temp = class RowList extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "listArray", _descriptor2, this);

      _initializerDefineProperty(this, "newid", _descriptor3, this);

      _initializerDefineProperty(this, "itemsToDeleteList", _descriptor4, this);

      _initializerDefineProperty(this, "deleteRow", _descriptor5, this);

      this.listArray = Ember.A();
      this.itemsToDeleteList = Ember.A();
    }

    get listIsEmpty() {
      return Ember.isEmpty(this.listArray);
    }

    get lastRowIndex() {
      return this.listArray.length - 1;
    }

    get trueListArray() {
      var listArray = this.listArray;
      var lastValidateIndex = this.lastObjectEmpty() ? listArray.get('length') - 1 : listArray.get('length');
      var trueListArray = Ember.A();

      for (var index = 0; index < lastValidateIndex; index++) {
        trueListArray.pushObject(listArray.objectAt(index));
      }

      return trueListArray;
    }

    addRow() {
      this.listArray.pushObject(Ember.Object.create({}));
      Ember.notifyPropertyChange(this, 'listArray');
    }

    updateRow(item, index, useNewIds = true) {
      var listArray = this.listArray;

      if (!item.id && useNewIds) {
        if (typeof item.set === 'function') {
          item.set('id', 'new' + this.newid);
        } else {
          item['id'] = 'new' + this.newid;
        }
      }

      this.newid = this.newid + 1;

      if (index >= 0 && index < listArray.length) {
        listArray[index] = item;

        if (!this.lastObjectEmpty()) {
          this.addRow();
        }
      }
    }

    prepForValidate(modelName, skipValidation = false, filePath = null, validationMessages = {}, model = null, overrideValidationKey) {
      var formattedObjects = Ember.A();
      this.trueListArray.forEach((item, index) => {
        if (!item.id || item.id.match(/new\d+/)) {
          if (typeof item.set === 'function') {
            item.set('id', null);
          } else {
            item['id'] = null;
          }
        }

        var validationObject = Ember.Object.create({
          modelName: modelName,
          model: model ? model : item,
          extraValidationData: {
            overrideValidationKey: overrideValidationKey || index + '-' + modelName,
            skip: skipValidation
          }
        });

        if (validationMessages !== {}) {
          validationObject['validationMessages'] = validationMessages;
        }

        if (filePath !== null) {
          validationObject['filePath'] = filePath;
        }

        formattedObjects.pushObject(validationObject);
      });
      return formattedObjects;
    }

    lastObjectEmpty() {
      var listArray = this.listArray;
      var obj = listArray.objectAt(listArray.get('length') - 1);
      return obj && !obj.hasOwnProperty('id');
    }

    removeRow() {
      this.listArray.popObject();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "listArray", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "newid", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "itemsToDeleteList", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "deleteRow", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = RowList;
});