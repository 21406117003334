define("securitytrax-ember-ui/models/message-log", ["exports", "securitytrax-ember-ui/models/base_models/message-log", "securitytrax-ember-ui/helpers/de-snake"], function (_exports, _messageLog, _deSnake) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _messageLog.default.extend({
    get messageTypeDisplay() {
      return (0, _deSnake.deSnake)(this.resourceTypeId);
    },

    get sentToList() {
      return this.sentTo.replaceAll(',', ', ');
    },

    get messageItemDisplay() {
      return this.messageTypeDisplay + ' (ID: ' + JSON.parse(this.resourceTypeInstanceId)['id'] + ')';
    }

  });

  _exports.default = _default;
});