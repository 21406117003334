define("securitytrax-ember-ui/models/base_models/adc-list", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    availableLists: (0, _model.attr)('json', {
      'readOnly': false
    })
  });

  _exports.default = _default;
});