define("securitytrax-ember-ui/models/customer-warranty", ["exports", "securitytrax-ember-ui/models/base_models/customer-warranty"], function (_exports, _customerWarranty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerWarranty.default.extend({
    intl: Ember.inject.service(),
    displayValue: Ember.computed('name', function () {
      return this.name + ' (' + this.days + ' ' + this.intl.t('pages.customers.accounting.invoices.recurringIntervalOptions.days') + ')';
    })
  });

  _exports.default = _default;
});