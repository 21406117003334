define("securitytrax-ember-ui/components/multi-step-form/forms/system/adt/steps/dealer-hub-info/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/system/adt/adt-form-base", "ember-concurrency"], function (_exports, _adtFormBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adtFormBase.default.extend({
    balanceInformationArrayPlaceholder: Ember.A([{
      id: 1
    }]),
    dealerHubInfo: Ember.computed(function () {
      return this.dealerHubInfoTask.perform();
    }),
    dealerHubInfoTask: (0, _emberConcurrency.task)(function* () {
      var centralStationService = this.centralStationService;

      try {
        var hasAdtOrderStatus = yield this.get('customerSystemInformation.centralStationId');

        if (hasAdtOrderStatus) {
          try {
            var hubInfo = yield centralStationService.getData({
              model: this.customer,
              method: 'adt__getCustomerDealerHubInfo',
              responseKey: 'customer_dealer_hub_info'
            });
            return hubInfo;
          } catch (response) {
            this.stNotificationManager.addErrorMessages(response, {
              notificationNamespace: 'adtMegaFormModal'
            });
          }
        }

        return [];
      } catch (error) {
        warn('failed to get discrepencies' + error, {
          id: 'multiStepForm.forms.system.adt'
        });
      }
    })
  });

  _exports.default = _default;
});