define("securitytrax-ember-ui/demo/st-model-watcher/controller", ["exports", "ember-concurrency", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _emberConcurrency, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller.extend({
    store: Ember.inject.service(),
    stModelWatcher: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    eventHasFiredOne: undefined,
    eventHasFiredTwo: undefined,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        eventHasFiredOne: false,
        eventHasFiredTwo: false
      });
    },

    checkUserNotificationOneTask: (0, _emberConcurrency.task)(function* (modelsList) {
      yield; // you can optionally yield anywhere in here (but be careful of performance!!)

      var approvedList = [];

      for (var index = 0; index < modelsList.length; index++) {
        var currUserNotification = modelsList.objectAt(index); // console.log('notification id', currUserNotification.belongsTo('notification').id()); // brian will need this

        if (currUserNotification.belongsTo('notification').id() === '14') {
          approvedList.push(currUserNotification);
        }
      } // console.log('approvedListOne', approvedList);


      return approvedList;
    }),
    checkUserNotificationTwoTask: (0, _emberConcurrency.task)(function* (modelsList) {
      yield; // you can optionally yield anywhere in here (but be careful of performance!!)

      var approvedList = [];

      for (var index = 0; index < modelsList.length; index++) {// don't approve anything
      } // console.log('approvedListTwo', approvedList);


      return approvedList;
    }),

    willDestroyElement() {
      this._super(...arguments);
    },

    _onWatcherFiredOne(data) {
      data; // console.log('fired the event 1', data);

      Ember.set(this, 'eventHasFiredOne', true);
    },

    _onWatcherFiredTwo(data) {
      data; // console.log('fired the event 2', data);

      Ember.set(this, 'eventHasFiredTwo', true);
    },

    setupWatchers: (0, _emberConcurrency.task)(function* () {
      yield;
      this.stModelWatcher.registerModelWatcher('user-notification', 'create', 'fire-this-event-please-one', this.checkUserNotificationOneTask, this, '_onWatcherFiredOne');
      this.stModelWatcher.registerModelWatcher('user-notification', 'create', 'fire-this-event-please-two', this.checkUserNotificationTwoTask, this, '_onWatcherFiredTwo');
    }),
    deregisterWatchers: (0, _emberConcurrency.task)(function* () {
      yield;
      this.stModelWatcher.deregisterModelWatcher('user-notification', 'create', 'fire-this-event-please-one', this, '_onWatcherFiredOne');
      this.stModelWatcher.deregisterModelWatcher('user-notification', 'create', 'fire-this-event-please-two', this, '_onWatcherFiredTwo');
    }) // maybe bring this back later
    // simulateModelCameInTask: task(function* (buttonPromise) {
    //     const loggedInUser = yield this.sessionData.getUser();
    //     const calendarEventNotification = yield this.store.findRecord('notification', 14);
    //     const newUserNotification = this.store.createRecord('userNotification', {
    //         user: loggedInUser,
    //         notification: calendarEventNotification
    //     });
    //      const savedUserNotification = yield newUserNotification.save();
    //      console.log('savedUserNotification', savedUserNotification);
    //     return this.stModelWatcher.triggerModelWatcherEventsTask.perform('user-notification', 'create', [ savedUserNotification ]);
    // }),

  });
  var _default = Controller;
  _exports.default = _default;
});