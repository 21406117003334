define("securitytrax-ember-ui/components/multi-step-form/forms/administration/equipment/equipment-forms/equipment-details/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // import lodash from 'lodash';
  var EquipmentDetails = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = (0, _emberConcurrency.task)(function* () {
    var hashedData = yield (0, _emberConcurrency.hash)({
      wireTypes: this.store.findAll('equipmentWireType'),
      equipmentSubTypes: this.store.findAll('equipmentSubType'),
      equipmentTypes: this.store.findAll('equipmentType')
    });
    Ember.setProperties(this, hashedData);
  }), _dec7 = Ember._action, _dec8 = Ember._action, (_class = (_temp = class EquipmentDetails extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "wireTypes", _descriptor2, this);

      _initializerDefineProperty(this, "equipmentSubTypes", _descriptor3, this);

      _initializerDefineProperty(this, "equipmentTypes", _descriptor4, this);

      _initializerDefineProperty(this, "manufacturersList", _descriptor5, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor6, this);

      this.initDataTask.perform();
    }

    get filteredEquipmentSubTypes() {
      return this.args.model.get('equipmentType') ? this.equipmentSubTypes.filterBy('equipmentType.id', this.args.model.get('equipmentType.id')) : this.equipmentSubTypes;
    } // manufacturersListTask: task(function* () {
    //     const manufacturerData = yield hash({
    //         equipmentManufacturer: this.store.findAll('equipmentManufacturer'),
    //         currentManufacturer: this.model.manufacturer
    //     });
    //     return lodash.map(
    //         lodash.uniq(lodash.map(manufacturerData.equipmentManufacturer.toArray(), 'name').concat([ manufacturerData.currentManufacturer ])),
    //         function(name) {
    //             return { index: name, name: name };
    //         }
    //     );
    // }),


    updateType() {
      if (this.args.model.get('equipmentSubType')) {
        this.args.model.set('equipmentType', this.equipmentTypes.findBy('id', this.args.model.get('equipmentSubType.equipmentType.id')));
      }
    }

    unsetSubType() {
      this.args.model.set('equipmentSubType', null);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "wireTypes", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "equipmentSubTypes", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "equipmentTypes", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "manufacturersList", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateType", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "updateType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unsetSubType", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "unsetSubType"), _class.prototype)), _class));
  _exports.default = EquipmentDetails;
});