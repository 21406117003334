define("securitytrax-ember-ui/components/multi-step-form/forms/system/adt/shared/order-notes/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);
    }

  });

  _exports.default = _default;
});