define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/forms/adt/work-order/work-order-list/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/customer-base"], function (_exports, _emberConcurrency, _customerBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerBase.default.extend({
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    grants: Ember.inject.service(),
    store: Ember.inject.service(),
    customer: null,
    customerEquipment: null,
    saveEvent: false,
    errors: false,
    showEditWorkOrder: false,
    showCreateWorkOrder: true,
    saveWorkOrderTask: undefined,

    init() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      var customerOriginalModel = multiStepForm.formOriginalModelsGetVal('customer.model');
      this.set('customer', customerOriginalModel);
    },

    setupWorkOrderStep: Ember.computed(function () {
      return this.setupWorkOrderStepTask.perform();
    }),
    setupWorkOrderStepTask: (0, _emberConcurrency.task)(function* () {
      var grants = this.grants;
      var customerEquipmentView = grants.getIndividualGrant('customer_equipment.view');
      this.set('customerEquipment', customerEquipmentView ? yield this.newCustomer.get('customerEquipment') : []);
    }),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('workOrder::listWorkOrders', 'buttons', [{
        text: this.intl.t('common.actions.back'),
        classNamesString: 'st-btn st-btn-secondary',
        click: () => {
          multiStepForm.currentStepNameKeySetVal(multiStepForm.progressBarConfig.activeIndex === 4 ? 'paymentMethod' : 'step7');
          multiStepForm.progressBarConfig.activeIndex--;
        }
      }, {
        text: this.intl.t('common.actions.skip'),
        classNamesString: 'st-btn st-btn-secondary skip',
        click: () => {
          var activeIndex = multiStepForm.progressBarConfig.activeIndex;
          Ember.set(multiStepForm.progressBarConfig.items.objectAt(activeIndex), 'isComplete', true);
          Ember.set(multiStepForm.progressBarConfig.items.objectAt(activeIndex + 1), 'isComplete', true);
          multiStepForm.currentStepNameKeySetVal('nextStep');
          multiStepForm.progressBarConfig.activeIndex += 2;
        }
      }, {
        text: this.intl.t('common.actions.saveAndContinue'),
        classNamesString: 'st-btn st-btn-primary customer-information-save-button',
        positionOptions: 'up left',
        click: buttonPromise => {
          buttonPromise;
          return this.afterWorkOrderSavedTask.perform(multiStepForm, false);
        },
        additionalClickActions: [{
          label: this.intl.t('common.actions.deleteCustomer'),
          action: () => {
            this.set('formMode', 'create');
            this.openConfirmDelete('workOrder');
          },
          classNamesString: 'dropdown-delete-customer'
        }, {
          label: this.intl.t('common.actions.saveAndClose'),
          action: buttonPromise => {
            buttonPromise;
            var close = true;
            return this.afterWorkOrderSavedTask.perform(multiStepForm, close);
          },
          classNamesString: 'dropdown-save-close'
        }]
      }]);
    },

    afterWorkOrderSavedTask: (0, _emberConcurrency.task)(function* (multiStepForm, close) {
      if (close) {
        this.set('formMode', 'create');
        this.closeForm(multiStepForm, multiStepForm.formOriginalModelsGetVal('customer.model'));
      } else {
        var calendarEvents = yield this.newCustomer.calendarEvents;
        var hasCalendarEvents = calendarEvents.length > 0;
        var scheduleSubStep = hasCalendarEvents ? 'listCalendarEvents' : 'scheduleCalendarEvent';
        var nextStepName = 'schedule::' + scheduleSubStep;
        var nextStepNumber = multiStepForm.progressBarConfig.activeIndex + 1;
        this.advanceForm(multiStepForm, nextStepName, nextStepNumber);

        if (this.multiStepForm.formSteps['schedule']['hidden']) {
          nextStepName = 'nextStep';
          nextStepNumber = multiStepForm.progressBarConfig.activeIndex + 2;
          this.advanceForm(multiStepForm, nextStepName, nextStepNumber);
        }
      }
    }),
    actions: {
      goToStep(currentStepNameKey) {
        Ember.set(this.multiStepForm, 'currentStepNameKey', currentStepNameKey);
      }

    }
  });

  _exports.default = _default;
});