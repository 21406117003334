define("securitytrax-ember-ui/components/reports/report-category/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/table-column-sort"], function (_exports, _emberConcurrency, _tableColumnSort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableColumnSort.default, {
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    stSortCurrentSortColumn: 'name',
    stSortAscending: true,
    limit: 50,
    page: 1,
    reportListCount: 0,
    reportFilters: false,
    report: null,
    reportFormMode: 'create',
    reportFormStarted: false,
    currentStepNameKey: 'addReport',
    reportFormSteps: Ember.computed('reportFormMode', function () {
      var intl = this.intl;
      return Ember.Object.create({
        addReport: Ember.Object.create({
          stepNameKey: 'addReport',
          title: intl.t("pages.reports.add_report"),
          stepComponentPath: 'multi-step-form/forms/reports/add-report'
        }),
        editReport: Ember.Object.create({
          stepNameKey: 'editReport',
          title: intl.t("pages.reports.edit_report"),
          stepComponentPath: 'multi-step-form/forms/reports/edit-report'
        })
      });
    }),
    reportFormOriginalModels: Ember.computed('report', function () {
      return Ember.Object.create({
        report: {
          model: this.report,
          copyRelationMap: {
            reportOuterloop: {
              readOnly: true
            },
            reportCategory: {
              readOnly: true
            },
            reportObject: {
              readOnly: true
            }
          }
        }
      });
    }),
    filterReportsTask: (0, _emberConcurrency.task)(function* (filter, newReport) {
      filter; // not sure why not need filter?

      if (newReport) {
        Ember.set(this, 'report', yield newReport);
      }

      this.set('reportsLoaded', false);
      var queryParams = {
        filter: this.filter,
        sort: this.stSortString ? this.stSortString : 'name',
        include: 'report_object',
        limit: this.limit,
        page: this.page
      };

      if (this.page == 1) {
        queryParams['count'] = 1;
      }

      var reports = yield this.store.query('report', queryParams);
      var filteredReports = reports.filter(report => {
        return report.get('responseMeta.runnable') === true;
      });
      this.set('reports', filteredReports);

      if (this.page == 1) {
        this.set('reportListCount', reports.meta.record_count);
      }

      this.set('reportsLoaded', true);
    }).on('init').restartable().maxConcurrency(1),
    reportTypes: Ember.computed('grants.userGrants', function () {
      return this.reportTypesTask.perform();
    }),
    reportTypesTask: (0, _emberConcurrency.task)(function* () {
      var reportObjects = yield this.store.findAll('reportObject');
      return reportObjects.filter(currReportObject => {
        return currReportObject.reportObjectHasGrant('reports.create');
      });
    }),
    actions: {
      // action sent to pagination component
      getReports() {
        this.set('reportsLoaded', false);
        this.filterReportsTask.perform();
      },

      // action sent to sortable-column component
      sortList(column, ascending) {
        this.sortPaginatedList(column, ascending, 'reportsLoaded');
        this.filterReportsTask.perform();
      },

      mobileSortList(column) {
        this.sortMobilePaginatedList(column.value, 'reportsLoaded');
        this.filterReportsTask.perform();
      },

      showReportFilters(report) {
        if (report.get('responseMeta.runnable')) {
          this.router.transitionTo("reports.view", report.id);
        } else {
          this.stNotificationManager.addError(this.intl.t('pages.reports.not_runnable'));
        }
      }

    }
  });

  _exports.default = _default;
});