define("securitytrax-ember-ui/components/customer/dashboard/payables-summary-card/type-progress-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TWykkLbl",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[30,[36,2],[\"grid-x grid-padding-x payable-type-details\",[30,[36,1],[[35,0],\" total\",\"\"],null]],null]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cell grid-x\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cell payable-type\"],[12],[1,[34,3]],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cell grid-x progress-bars__wrapper\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"progress-bars\"],[12],[2,\"\\n                \"],[10,\"div\"],[14,0,\"progress-bar paid-progress-bar\"],[15,5,[30,[36,5],[[30,[36,2],[\"width: \",[35,4],\";\"],null]],null]],[12],[13],[2,\"\\n                \"],[10,\"div\"],[14,0,\"progress-bar to-be-paid-progress-bar\"],[15,5,[30,[36,5],[[30,[36,2],[\"width: \",[35,6],\";\"],null]],null]],[12],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"total\",\"if\",\"concat\",\"type\",\"type_paid_width\",\"html-safe\",\"type_to_be_paid_width\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/customer/dashboard/payables-summary-card/type-progress-bar/template.hbs"
    }
  });

  _exports.default = _default;
});