define("securitytrax-ember-ui/components/modals/sub-route-modals/manage-api/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    formStarted: false,
    formMode: 'edit',
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    initDataTask: (0, _emberConcurrency.task)(function* () {
      this.set('formMode', this.get('customerSystemInformation.centralStationId') ? 'edit' : 'create');
      yield (0, _emberConcurrency.waitForProperty)(this, 'customer.customerOrderInformation.monitoringCompany.monitoringCompanyType.name', name => name !== undefined);
      this.set('formStarted', true);
    }).on('init'),
    title: Ember.computed('customer.{id,flname,customerOrderInformation.monitoringCompany.monitoringCompanyType.name}', 'formMode', function () {
      var titleData = {
        monitorTypeName: this.get('customer.customerOrderInformation.monitoringCompany.monitoringCompanyType.name'),
        customerName: this.get('customer.flname'),
        id: this.get('customer.id')
      };
      return this.formMode == 'create' ? this.intl.t('pages.customers.system.order.create_title_any_cs', titleData) : this.intl.t('pages.customers.system.order.title', titleData);
    }),
    formOriginalModel: Ember.computed('grants.userGrants', 'customer', function () {
      var grants = this.grants;
      return Ember.Object.create({
        customer: {
          model: this.customer,
          copyRelationMap: {
            customerSystemInformation: {
              doCopy: grants.getIndividualGrant('customer_system_information.view'),
              doValidate: false,
              doSave: grants.getIndividualGrant('customer_system_information.modify')
            },
            customerOrderInformation: {
              doCopy: grants.getIndividualGrant('customer_order_information.view'),
              doValidate: false,
              doSave: false,
              relationships: {
                monitoringCompany: {
                  readOnly: true,
                  relationships: {
                    monitoringCompanyConfig: {
                      readOnly: true
                    }
                  }
                }
              }
            }
          }
        }
      });
    }),
    formSteps: Ember.computed('title', function () {
      return Ember.Object.create({
        general: Ember.Object.create({
          stepNameKey: 'general',
          title: this.title,
          stepComponentPath: 'multi-step-form/forms/system/api/create-edit-account',
          progressBarTitle: this.intl.t('pages.customers.system.order.general')
        }),
        tests: Ember.Object.create({
          stepNameKey: 'tests',
          title: this.title,
          stepComponentPath: 'multi-step-form/forms/system/api/tests',
          progressBarTitle: this.intl.t('pages.customers.system.order.tests')
        }),
        eventHistory: Ember.Object.create({
          stepNameKey: 'eventHistory',
          title: this.title,
          stepComponentPath: 'multi-step-form/forms/system/api/history',
          progressBarTitle: this.intl.t('pages.customers.system.order.event_history')
        })
      });
    }),
    actions: {
      transitionBackToParentRoute() {
        this.router.transitionTo(this.parentRoute, this.transitionId);
      }

    }
  });

  _exports.default = _default;
});