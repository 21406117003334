define("securitytrax-ember-ui/components/modals/sub-route-modals/manage-guardian/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qcBZIHd6",
    "block": "{\"symbols\":[\"multiStepForm\",\"MultiStepFormComponent\",\"@customer\",\"@monitoringCompany\",\"@customerSystemInformation\"],\"statements\":[[2,\"\\n\"],[8,\"html/st-await\",[],[[\"@useSpinner\",\"@booleanLoaded\",\"@size\"],[true,[34,2,[\"isIdle\"]],\"large\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"multi-step-form\",[[24,0,\"row\"]],[[\"@formSteps\",\"@currentStepNameKey\",\"@formOriginalModels\",\"@formStarted\",\"@changeFormStarted\"],[[34,3],\"general\",[34,4],[34,5],[30,[36,1],[[32,0],[30,[36,6],[[35,5]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"modals/st-paper\",[],[[\"@modalButtons\",\"@title\",\"@classNames\",\"@dialogOpen\",\"@hideTopRightCloseButton\",\"@hasProgressBar\",\"@fullscreen\",\"@onClose\",\"@multiStepForm\"],[[32,1,[\"currentStepData\",\"buttons\"]],[32,1,[\"currentStepData\",\"title\"]],\"adt-modal st-modal--full\",[32,1,[\"formStarted\"]],[32,1,[\"currentStepData\",\"hideTopRightCloseButton\"]],[30,[36,7],[[35,0],\"create\"],null],true,[30,[36,1],[[32,0],\"transitionBackToParentRoute\"],null],[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,9],[[30,[36,8],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[8,[32,2],[[24,0,\"form-step-full-height\"]],[[\"@multiStepForm\",\"@formMode\",\"@customer\",\"@monitoringCompany\",\"@customerSystemInformation\",\"@transitionBackToParent\",\"@afterCreate\"],[[32,1],[34,0],[32,3],[32,4],[32,5],[30,[36,1],[[32,0],\"transitionBackToParentRoute\"],null],[30,[36,1],[[32,0],\"afterCreate\"],null]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"formMode\",\"action\",\"initData\",\"formSteps\",\"formOriginalModel\",\"formStarted\",\"mut\",\"not-eq\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/modals/sub-route-modals/manage-guardian/template.hbs"
    }
  });

  _exports.default = _default;
});