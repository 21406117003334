define("securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-customer-contact/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yKOKLzmI",
    "block": "{\"symbols\":[\"multiStepForm\",\"Form\",\"@isADT\"],\"statements\":[[8,\"multi-step-form\",[],[[\"@formSteps\",\"@formOriginalModels\",\"@usesModal\",\"@formStarted\",\"@changeFormStarted\"],[[32,0,[\"editCreateContactForm\"]],[32,0,[\"emergencyContactFormOriginalModels\"]],true,[32,0,[\"contactsFormStarted\"]],[30,[36,0],[[32,0],[30,[36,1],[[32,0,[\"contactsFormStarted\"]]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"modals/st-paper\",[],[[\"@classNames\",\"@modalButtons\",\"@title\",\"@dialogOpen\",\"@onClose\",\"@multiStepForm\"],[\"emergency-contacts\",[32,1,[\"currentStepData\",\"buttons\"]],[32,1,[\"currentStepData\",\"title\"]],[32,1,[\"formStarted\"]],[30,[36,0],[[32,0],\"transitionBackToParentRoute\"],null],[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,[32,2],[[24,0,\"edit-customer-contact\"]],[[\"@multiStepForm\",\"@customer\",\"@formMode\",\"@customerContactsList\",\"@transitionBackToParent\",\"@isADT\"],[[32,1],[32,0,[\"customer\"]],[32,0,[\"contactsFormMode\"]],[32,0,[\"customer\",\"customerContacts\"]],[30,[36,0],[[32,0],\"transitionBackToParentRoute\"],null],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"mut\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-customer-contact/template.hbs"
    }
  });

  _exports.default = _default;
});