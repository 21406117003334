define("securitytrax-ember-ui/components/multi-step-form/forms/notes/tasks/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/notes/base-notes", "ember-concurrency"], function (_exports, _baseNotes, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseNotes.default.extend({
    store: Ember.inject.service(),
    userService: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    userList: null,
    defaultsLoaded: false,
    priorityOptions: Ember.computed(function () {
      var intl = this.intl;
      return [{
        value: 3,
        name: intl.t('pages.customers.tasks.priority.high')
      }, {
        value: 2,
        name: intl.t('pages.customers.tasks.priority.medium')
      }, {
        value: 1,
        name: intl.t('pages.customers.tasks.priority.low')
      }];
    }),
    statusOptions: Ember.computed(function () {
      var intl = this.intl;
      return [{
        value: false,
        name: intl.t('pages.customers.tasks.status.open')
      }, {
        value: true,
        name: intl.t('pages.customers.tasks.status.closed')
      }];
    }),
    taskOptions: Ember.computed(function () {
      return this.noteTypes;
    }),
    setupDefaultsTask: (0, _emberConcurrency.task)(function* () {
      var store = this.store;
      var templateId = this.selectedTemplate;
      var multiStepForm = this.multiStepForm;
      var hashedData = yield (0, _emberConcurrency.hash)({
        template: templateId ? store.findRecord('template', templateId) : null,
        userList: this.userService.getUsersList('task_assignee'),
        waitForProperty: (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true)
      }); //Set up user list options

      var sortedList = hashedData.userList.sortBy('name');
      this.set('userList', sortedList);

      if (hashedData.template !== null) {
        var hashedData2 = yield (0, _emberConcurrency.hash)({
          defaults: hashedData.template.get('sortedTemplateFieldDefaults'),
          noteTypes: this.taskOptions
        });

        if (hashedData2.defaults[1].get('value') !== null) {
          var defaultValue = hashedData2.defaults[1].get('value');

          if (defaultValue === 'install_technician') {
            var systemInfo = yield this.get('customer.customerSystemInformation');
            var installCalendarEvent = yield systemInfo.get('installCalendarEvent');

            if (installCalendarEvent) {
              this.multiStepForm.formCopiedModelsSetVal('customerTask.model.assignedUser', hashedData.userList.findBy('id', installCalendarEvent.get('user.id')));
            }
          } else if (defaultValue === 'sales_rep') {
            var customer = this.customer;
            var repUser = yield customer.get('repUser');

            if (repUser) {
              this.multiStepForm.formCopiedModelsSetVal('customerTask.model.assignedUser', repUser ? hashedData.userList.findBy('id', repUser.get('id')) : null);
            }
          } else {
            this.multiStepForm.formCopiedModelsSetVal('customerTask.model.assignedUser', hashedData.userList.findBy('id', hashedData2.defaults[1].get('value')));
          }
        }

        if (this.multiStepForm.formCopiedModelsGetVal('customerTask.model.assignedUser.id') > 0) {
          yield this.setUser.perform(this.multiStepForm.formCopiedModelsGetVal('customerTask.model'), 'assignedUser', this.multiStepForm.formCopiedModelsGetVal('customerTask.model.assignedUser'));
        }

        this.multiStepForm.formCopiedModelsSetVal('customerTask.model.note', hashedData.template.get('description'));
        this.multiStepForm.formCopiedModelsSetVal('customerTask.model.subject', hashedData2.defaults[4].get('value'));
        this.multiStepForm.formCopiedModelsSetVal('customerTask.model.priority', +hashedData2.defaults[3].get('value'));
        this.multiStepForm.formCopiedModelsSetVal('customerTask.model.noteType', hashedData2.noteTypes.findBy('id', hashedData2.defaults[0].get('value')));
      }

      if (this.toolbox) {
        this.multiStepForm.formCopiedModelsSetVal('customerTask.model.assignedUser', hashedData.userList.findBy('id', (yield this.sessionData.getUser()).get('id')));
      }

      if (this.formMode === 'create') {
        if (this.multiStepForm.formCopiedModelsGetVal('customerTask.model.isClosed') === undefined) {
          this.multiStepForm.formCopiedModelsSetVal('customerTask.model.isClosed', false);
        }
      }

      this.set('defaultsLoaded', true);
    }).on('init')
  });

  _exports.default = _default;
});