define("securitytrax-ember-ui/utils/debug", ["exports", "securitytrax-ember-ui/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.logMissingAbstractMember = logMissingAbstractMember;
  _exports.isDevelopmentEnvironment = isDevelopmentEnvironment;
  _exports.isTestEnvironment = isTestEnvironment;
  _exports.isCypressTestEnvironment = isCypressTestEnvironment;

  /**
   * Utility helper module for debugging.
   * @module
   */

  /**
   * Logs an error indicating that the supplied context Object <strong>must</strong> implement the supplied member.
   *
   * @param {Object} context The Ember object (route, controller, component, etc) that is requesting the translation.
   * @param {String} memberName The name of the missing abstract member.
   *
   * @returns {boolean} False
   */
  function logMissingAbstractMember(context, memberName) {
    (false && Ember.warn("".concat(context.toString(), " must implement ").concat(memberName, "."), {
      id: 'utils.debug'
    }));
    return false;
  }
  /**
   * Checks if the application is running in the development environment
   *
   * @returns {boolean}
   */


  function isDevelopmentEnvironment() {
    return _environment.default.environment === 'development';
  }
  /**
   * Indicates if the application is running in the test environment.
   *
   * @returns {boolean}
   */


  function isTestEnvironment() {
    return _environment.default.environment === 'test';
  }
  /**
   * Indicates if the application is running in the Cypress test environment.
   *
   * @returns {boolean}
   */


  function isCypressTestEnvironment() {
    return Boolean(window.Cypress);
  }
});