define("securitytrax-ember-ui/administration/inventory/dashboard/route", ["exports", "securitytrax-ember-ui/administration/inventory/base-route", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _baseRoute, _authenticatedRouteMixin, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_authenticatedRouteMixin.default, _routePageviewLogging.default, {
    beforeModel() {
      this._super(...arguments);

      var routeObjs = [{
        route: 'administration.inventory.dashboard',
        grants: ['dashboard_cards_open_purchase_orders.view_company', 'dashboard_cards_open_purchase_requisitions.view_company', 'dashboard_cards_open_transfers.view_company', 'dashboard_cards_open_purchase_spillage_reports.view_company', 'dashboard_cards_locked_spillage_reports.view_company', 'dashboard_cards_open_rmas.view_company'],
        modifier: 'one'
      }, {
        route: 'administration.inventory.on-hand',
        grants: ['equipment_ledgers.view_on_hand', 'equipment_ledgers.computed.company_inventory']
      }, {
        route: 'administration.inventory.company-replenishment',
        grants: ['companies.company_replenishment'],
        features: ['inventoryExtendedFunctionality']
      }, {
        route: 'administration.inventory.purchase-requisitions',
        grants: ['equipment_purchase_requisitions.view', 'equipment_purchase_requisitions.computed.company_inventory']
      }, {
        route: 'administration.inventory.purchase-orders',
        grants: ['equipment_purchase_orders.view', 'equipment_purchase_orders.computed.company_inventory']
      }, {
        route: 'administration.inventory.physical-inventory',
        grants: ['equipment_ledgers.perform_physical_inventory', 'equipment_ledgers.computed.company_inventory']
      }, {
        route: 'administration.inventory.equipment-transfer',
        grants: ['admin_equipment_transfers.view', 'admin_equipment_transfers.computed.company_inventory']
      }, {
        route: 'administration.inventory.spillage',
        grants: ['equipment_spillage.view', 'equipment_spillage.computed.company_inventory']
      }, {
        route: 'administration.inventory.rma',
        grants: ['equipment_return_merchandise_authorizations.view', 'equipment_return_merchandise_authorizations.computed.company_inventory']
      }, {
        route: 'administration.inventory.inventory-ledger',
        grants: ['equipment_ledgers.view', 'equipment_ledgers.computed.company_inventory']
      }, {
        route: 'administration.inventory.equipment-templates',
        features: ['inventoryExtendedFunctionality']
      }];
      this.baseFindGrantsTask.perform(routeObjs);
    },

    model() {
      return Ember.RSVP.hash({
        baseFindGrantsTask: this.baseFindGrantsTask
      });
    }

  });

  _exports.default = _default;
});