define("securitytrax-ember-ui/administration/human-resources/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jReL/8+N",
    "block": "{\"symbols\":[\"navData\"],\"statements\":[[1,[30,[36,9],null,[[\"title\",\"breadCrumbs\",\"breadCrumbData\",\"expandSideNav\",\"sideNavExpand\",\"mobileHamburgerVisible\"],[[30,[36,1],[\"pages.administration.human_resources.title\"],null],[35,8],[35,7],[30,[36,6],[[32,0],\"toggleLeft\"],[[\"target\"],[[35,5]]]],[35,5,[\"isLeftOpen\"]],true]]]],[2,\"\\n\\n\"],[6,[37,10],null,null,[[\"default\"],[{\"statements\":[[6,[37,4],[[30,[36,3],[\"users.view\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[\"administration.human-resources.user-list\"],[[\"class\",\"disabled\"],[\"user-information-link\",[32,1,[\"navGloballyDisabled\"]]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,0],[\"icn-user\"],null]],[2,\"\\n            \"],[1,[30,[36,1],[\"pages.administration.human_resources.user_list\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[6,[37,4],[[30,[36,3],[\"groups.view\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[\"administration.human-resources.permissions\"],[[\"class\",\"disabled\"],[\"permissions-link\",[32,1,[\"navGloballyDisabled\"]]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,0],[\"icn-feature-users\"],null]],[2,\"\\n            \"],[1,[30,[36,1],[\"pages.administration.human_resources.groups\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"page-content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"scroll-page\"],[12],[2,\"\\n        \"],[1,[30,[36,12],[[30,[36,11],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"svg-icon\",\"t\",\"nav/left-nav-link\",\"has-grants\",\"if\",\"navigation\",\"action\",\"breadCrumbData\",\"breadCrumbs\",\"nav/top-nav-sub\",\"nav/base-left-nav\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/administration/human-resources/template.hbs"
    }
  });

  _exports.default = _default;
});