define("securitytrax-ember-ui/components/html/text-mask/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vg1bpHGe",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[2,\"\\n\"],[2,\"\\n\"],[18,1,[[30,[36,3],null,[[\"onChange\",\"value\",\"inputType\"],[[30,[36,2],[[32,0],\"onChangeWithDefault\"],null],[35,1],[35,0]]]]]],[2,\"\\n    \\n\"]],\"hasEval\":false,\"upvars\":[\"inputType\",\"value\",\"action\",\"hash\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/text-mask/template.hbs"
    }
  });

  _exports.default = _default;
});