define("securitytrax-ember-ui/transforms/number-string", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize(serialized
    /*, options*/
    ) {
      if (serialized) {
        return serialized.toString();
      }

      return null;
    },

    serialize(deserialized
    /*, options*/
    ) {
      if (deserialized || deserialized === 0) {
        //this is likely a performance hit, just send since these are likely not modified attributes
        //return deserialized.replace(/^\d+$/, '');
        return deserialized;
      }

      return null;
    }

  });

  _exports.default = _default;
});