define("securitytrax-ember-ui/components/graphs/partial-donut/component", ["exports", "@glimmer/component", "d3-selection", "d3-scale", "d3-shape", "d3-collection"], function (_exports, _component, _d3Selection, _d3Scale, _d3Shape, _d3Collection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var PartialDonut = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = (_temp = class PartialDonut extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "totalCount", _descriptor, this);
    }

    insertDonut(element) {
      // set the dimensions and margins of the graph
      var width = 130;
      var height = 130;
      var radius = Math.min(width, height) / 2;
      var initialChartAngleRange = (.75 * Math.PI).toFixed(10); //First pass at 75% to ensure rounded ends dont look awful

      var angleRange = (.73 * Math.PI).toFixed(10); //Actual data is 73%
      // make sure we have an array at least

      var graphData = this.args.graphData ? this.args.graphData : []; //Data inputs for the pie chart

      var data = {};
      var initialPassData = {};
      var colorArray = [];
      var total = 0;
      var firstColorWithValueFound = false;

      for (var i = 0; i < graphData.length; i++) {
        data[i + 1] = graphData[i].value;
        total = total + graphData[i].value;
        colorArray.push(graphData[i].color); // Set first value above 0 to be at least 50% of the first pass arc

        if (!firstColorWithValueFound && graphData[i].value > 0) {
          initialPassData[i + 1] = 1;
          firstColorWithValueFound = true; // Every other value gets set to 0 for first pass arc
        } else {
          initialPassData[i + 1] = 0;
        }
      } // Set last value above 0 to be at least 50% of the first pass arc


      for (var _i = graphData.length - 1; _i >= 0; _i--) {
        if (graphData[_i].value > 0) {
          initialPassData[_i + 1] = 1;
          break;
        }
      } //If total count is passed in, just use that


      if (this.args.totalCount) {
        total = this.args.totalCount;
      }

      Ember.set(this, 'totalCount', total); //Add default data in case all values are zero and the graph is just gray

      initialPassData[graphData.length + 1] = total === 0 ? 1 : 0;
      colorArray.push('#cccccc'); //Colors line up with the data above

      var color = (0, _d3Scale.scaleOrdinal)().domain(data).range(colorArray);
      var svg = (0, _d3Selection.select)(element);
      svg.attr('viewBox', "0 0 ".concat(width, " ").concat(height));
      var group = svg.append('g').attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')'); // Compute the position of each group on the pie:
      //first pass pie uses initialPassData at 75%

      var initialPassPiechart = (0, _d3Shape.pie)().startAngle(initialChartAngleRange * -1).endAngle(initialChartAngleRange).sort(null).value(function (d) {
        return d.value;
      });
      var initialPassData_ready = initialPassPiechart((0, _d3Collection.entries)(initialPassData)); //second pass pie uses data at 73%

      var salesPieChart = (0, _d3Shape.pie)().startAngle(angleRange * -1).endAngle(angleRange).sort(null).value(function (d) {
        return d.value;
      });
      var data_ready = salesPieChart((0, _d3Collection.entries)(data)); // Setting up the arc function to build the pie chart
      //initial pass arcs

      var initialPassArc = group.selectAll('path').data(initialPassData_ready).enter(); //real data arcs

      var arcs = group.selectAll('path').data(data_ready).enter();
      var piePiece = (0, _d3Shape.arc)().innerRadius(56) //size of the donut hole
      .outerRadius(radius); // Build each data point into an arc

      initialPassArc.append('path').attr('d', function (d) {
        if (d.data.value > 0) {
          piePiece.cornerRadius(20);
          return piePiece(d);
        }
      }).attr('fill', function (d) {
        return color(d.data.key);
      }); // Build each piece again - this is so we can have rounded ends to the 'pie' with square connections between each data point

      arcs.append('path').attr('d', function (d) {
        if (d.data.value > 0) {
          piePiece.cornerRadius(0);
          return piePiece(d);
        }
      }).attr('fill', function (d) {
        return color(d.data.key);
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "totalCount", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "insertDonut", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "insertDonut"), _class.prototype)), _class));
  _exports.default = PartialDonut;
});