define("securitytrax-ember-ui/components/calendar-glimmer/calendar-settings/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CalendarSettings = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember.computed('screen.mobileAndTablet'), _dec9 = Ember.computed('screen.mobileAndTablet'), _dec10 = Ember.computed('screen.mobileAndTablet'), _dec11 = Ember._action, (_class = (_temp = class CalendarSettings extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "screen", _descriptor3, this);

      _initializerDefineProperty(this, "calPointer", _descriptor4, this);

      _initializerDefineProperty(this, "router", _descriptor5, this);

      _initializerDefineProperty(this, "screen", _descriptor6, this);

      _initializerDefineProperty(this, "showPopover", _descriptor7, this);
    }

    get mobileClass() {
      if (this.screen.mobileAndTablet) {
        return 'icon-only';
      }

      return '';
    }

    get side() {
      if (this.screen.mobileAndTablet) {
        return 'bottom-end';
      }

      return 'bottom-start';
    }

    get mobileView() {
      if (this.screen.mobileAndTablet) {
        /* if(this.args.showListView){
            this.args.hideListViewMobile();
        } */
        return true;
      }

      return false;
    }

    openPopover() {
      this.showPopover = !this.showPopover;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "screen", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "calPointer", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "screen", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "showPopover", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "mobileClass", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "mobileClass"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "side", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "side"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mobileView", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "mobileView"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openPopover", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "openPopover"), _class.prototype)), _class));
  _exports.default = CalendarSettings;
});