define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/interactive-services-providers/adc/tasks/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/interactive-services-providers/adc/interactive-services-provider-base", "ember-concurrency"], function (_exports, _interactiveServicesProviderBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _interactiveServicesProviderBase.default.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    disabledProgressBarSteps: Ember.computed(function () {
      return [];
    }),
    hiddenProgressBarSteps: Ember.computed(function () {
      return [];
    }),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData("tasks", 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.saveInteractiveServicesProviderConfig.perform(multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary interactive-services-providers-save-button'
      }]);
    },

    userList: Ember.computed('users', function () {
      return this.userListTask.perform();
    }),
    userListTask: (0, _emberConcurrency.task)(function* () {
      var users = this.grants.getIndividualGrant('users.view') ? yield this.store.findAll('user') : [];
      var list = [];
      users.forEach(user => {
        list.push({
          name: user.get('name'),
          id: user.get('id')
        });
      });
      return list;
    })
  });

  _exports.default = _default;
});