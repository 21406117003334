define("securitytrax-ember-ui/components/multi-step-form/forms/administration/users/my-account/information/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/administration/users/base-users"], function (_exports, _baseUsers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseUsers.default.extend({
    timezones: Ember.inject.service(),
    locales: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;

      if (!this.isNewRecord) {
        multiStepForm.registerStepData("information", 'buttons', [{
          text: this.intl.t('common.actions.cancel'),
          click: () => {
            multiStepForm.resetForm(false);
            this.set('informationFormMode', 'view');
          },
          classNamesString: 'st-btn btn-secondary user-cancel-button'
        }, {
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            var noValidateArray = ['username', 'password', 'group'];
            return this.saveUserDataTask.perform(multiStepForm, buttonPromise, noValidateArray);
          },
          classNamesString: 'st-btn st-btn-primary user-save-button'
        }]);
      }
    },

    timeZoneList: Ember.computed(function () {
      return this.timezones.all;
    }),
    localesList: Ember.computed(function () {
      return this.locales.all();
    })
  });

  _exports.default = _default;
});