define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/customer-create-chooser/create-form-configs/simple-sales/component", ["exports", "securitytrax-ember-ui/mixins/customer-create-chooser"], function (_exports, _customerCreateChooser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_customerCreateChooser.default, {
    createCustomerFormSteps: Ember.computed('newCustomerName', 'grants.userGrants', function () {
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.newCustomer ? this.newCustomerName : this.intl.t('common.nav.create.customer.newCustomer'),
          stepComponentPath: 'multi-step-form/forms-dynamic/generic-steps/customer-create/information',
          progressBarTitle: this.intl.t('common.nav.create.customer.label'),
          isComplete: !!this.newCustomerName
        }),
        step2: Ember.Object.create({
          stepNameKey: 'step2',
          title: this.newCustomerName,
          stepComponentPath: 'multi-step-form/forms-dynamic/generic-steps/customer-create/qualify',
          progressBarTitle: this.intl.t('common.nav.create.customer.sections.qualify'),
          hidden: !this.grants.getIndividualGrant('customer_credit_checks.create')
        }),
        step3: Ember.Object.create({
          stepNameKey: 'step3',
          title: this.newCustomerName,
          stepComponentPath: 'multi-step-form/forms-dynamic/generic-steps/customer-create/order',
          progressBarTitle: this.intl.t('common.nav.create.customer.sections.order')
        }),
        step4: Ember.Object.create({
          stepNameKey: 'step4',
          title: this.newCustomerName,
          stepComponentPath: 'multi-step-form/forms-dynamic/generic-steps/customer-create/equipment',
          progressBarTitle: this.intl.t('common.nav.create.customer.sections.equipment'),
          hidden: !this.grants.getIndividualGrant('customer_equipment.view')
        }),
        step5: Ember.Object.create({
          stepNameKey: 'step5',
          title: this.newCustomerName,
          stepComponentPath: 'multi-step-form/forms-dynamic/generic-steps/customer-create/contacts',
          progressBarTitle: this.intl.t('common.nav.create.customer.sections.contacts'),
          hidden: !this.grants.getIndividualGrant('customer_contacts.view')
        }),
        step6: Ember.Object.create({
          stepNameKey: 'step6',
          title: this.newCustomerName,
          stepComponentPath: 'multi-step-form/forms-dynamic/forms/adt/payment-method',
          progressBarTitle: this.intl.t('common.nav.create.customer.sections.paymentInformation'),
          hidden: !this.grants.getIndividualGrant('customer_billings.view')
        }),
        step7: Ember.Object.create({
          stepNameKey: 'step7',
          title: this.newCustomerName,
          stepComponentPath: 'multi-step-form/forms-dynamic/forms/asg/review',
          progressBarTitle: this.intl.t('common.nav.create.customer.sections.review')
        }),
        confirmDeleteCustomer: Ember.Object.create({
          stepNameKey: 'confirmDeleteCustomer',
          title: this.newCustomerName,
          stepComponentPath: 'multi-step-form/forms-dynamic/generic-steps/customer-create/confirm-delete-customer',
          hidden: true
        })
      });
    })
  });

  _exports.default = _default;
});