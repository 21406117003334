define("securitytrax-ember-ui/models/base_models/pandadoc-template", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    name: (0, _model.attr)('string', {
      'readOnly': false
    }),
    version: (0, _model.attr)('string', {
      'readOnly': false
    }),
    createdBy: (0, _model.attr)('json', {
      'readOnly': false
    }),
    contentPlaceholders: (0, _model.attr)('json', {
      'readOnly': false
    }),
    fields: (0, _model.attr)('json', {
      'readOnly': false
    }),
    images: (0, _model.attr)('json', {
      'readOnly': false
    }),
    pricing: (0, _model.attr)('json', {
      'readOnly': false
    }),
    roles: (0, _model.attr)('json', {
      'readOnly': false
    }),
    tags: (0, _model.attr)('json', {
      'readOnly': false
    }),
    tokens: (0, _model.attr)('json', {
      'readOnly': false
    }),
    dateCreated: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    dateModified: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    })
  });

  _exports.default = _default;
});