define("securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/base-components/applicants/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/create-financing-base"], function (_exports, _createFinancingBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var BaseCreateFinancingApplicants = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._action, (_class = (_temp = class BaseCreateFinancingApplicants extends _createFinancingBase.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "addRemoveApplicantButtonKeySuffix", _descriptor2, this);
    }

    buttons() {
      var multiStepForm = this.args.multiStepForm;
      var buttons = [];

      if (this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.consumerFinancingCoapplicant.id') > 0) {
        this.hasCoapplicant = true;
      }

      if (this.formMode !== 'view') {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveConsumerFinancingApplicants.perform(multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary customer-financing-save-button'
        });
      }

      buttons.push({
        text: this.intl.t('pages.customers.accounting.financing.' + (this.hasCoapplicant ? 'remove' : 'add') + this.addRemoveApplicantButtonKeySuffix),
        click: () => {
          this.hasCoapplicant = !this.hasCoapplicant;
          this.buttons();
        },
        isDelete: true,
        classNamesString: this.hasCoapplicant ? 'st-btn st-btn-warn delete st-modal__button--delete' : 'st-btn st-btn-primary add-applicant',
        icon: this.hasCoapplicant ? 'icn-close' : 'icn-add',
        iconOnly: false
      });
      multiStepForm.registerStepData('applicants', 'buttons', buttons);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "addRemoveApplicantButtonKeySuffix", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '_applicant';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "buttons", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "buttons"), _class.prototype)), _class));
  _exports.default = BaseCreateFinancingApplicants;
});