define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/forms/adt/schedule/component", ["exports", "securitytrax-ember-ui/mixins/forms/duration-options", "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/customer-base", "ember-concurrency"], function (_exports, _durationOptions, _customerBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerBase.default.extend(_durationOptions.default, {
    intl: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('schedule::scheduleCalendarEvent', 'buttons', [{
        text: this.intl.t('common.actions.back'),
        classNamesString: 'st-btn st-btn-secondary',
        click: () => {
          return this.goBackTask.perform(multiStepForm);
        }
      }, {
        id: 'save_continue',
        text: this.intl.t('common.actions.saveAndContinue'),
        classNamesString: 'st-btn st-btn-primary customer-information-save-button',
        positionOptions: 'up left',
        click: buttonPromise => {
          return this.saveCustomerTask.perform(multiStepForm, buttonPromise);
        },
        additionalClickActions: [{
          label: this.intl.t('common.actions.deleteCustomer'),
          action: () => {
            this.set('formMode', 'create');
            this.openConfirmDelete('schedule');
          },
          classNamesString: 'dropdown-delete-customer'
        }, {
          label: this.intl.t('common.actions.saveAndClose'),
          action: buttonPromise => {
            var close = true;
            return this.saveCustomerTask.perform(multiStepForm, buttonPromise, close);
          },
          classNamesString: 'dropdown-save-close'
        }]
      }]);
    },

    goBackTask: (0, _emberConcurrency.task)(function* (multiStepForm) {
      var workOrders = yield this.newCustomer.workOrders;

      if (workOrders.length == 0) {
        multiStepForm.currentStepNameKeySetVal('workOrder::createWorkOrder');
        multiStepForm.progressBarConfig.activeIndex--;
      } else {
        multiStepForm.currentStepNameKeySetVal('workOrder::listWorkOrders');
        multiStepForm.progressBarConfig.activeIndex--;
      }
    }),
    saveCustomerTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise, close = false) {
      yield;
      buttonPromise.then(() => {
        if (close) {
          this.set('formMode', 'create');
          this.closeForm(multiStepForm, multiStepForm.formOriginalModelsGetVal('customer.model'));
        } else {
          this.advanceForm(multiStepForm, 'nextStep', multiStepForm.progressBarConfig.activeIndex + 1);
        }
      });
    }),
    actions: {
      afterScheduleAppointment() {
        this.advanceForm(this.multiStepForm, 'nextStep', this.multiStepForm.progressBarConfig.activeIndex + 1);
      }

    }
  });

  _exports.default = _default;
});