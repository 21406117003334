define("securitytrax-ember-ui/components/multi-step-form/forms/administration/system/global-settings/edit-company-calendar-settings/component", ["exports", "@glimmer/component", "ember-concurrency", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "securitytrax-ember-ui/helpers/time-options", "moment"], function (_exports, _component, _emberConcurrency, _switchableFormStep, _timeOptions, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EditCalendarGlobalSettingsClass = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('events/calendar-events'), _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
    var tempEndTime = (0, _moment.default)(this.args.multiStepForm.formCopiedModels.globalSetting.model.calendarEventSchedulerStartTime, 'h:mm a');
    var endTime = tempEndTime.add(this.args.multiStepForm.formCopiedModels.globalSetting.model.calendarEventSchedulerDurationSeconds, 'seconds');
    this.calculatedEndTime = endTime.format('h:mm a');
  }), _dec6 = (0, _emberConcurrency.task)(function* () {
    this.args.multiStepForm.resetForm(false);
    Ember.set(this, 'formMode', 'view');
    yield this.initDataTask.perform();
    this.stNotificationManager.addSuccessKey('common.notifications.generic_cancel');
  }).drop(), _dec7 = (0, _emberConcurrency.task)(function* () {
    var startTime = (0, _moment.default)(this.args.multiStepForm.formCopiedModels.globalSetting.model.calendarEventSchedulerStartTime, 'h:mm a');
    this.args.multiStepForm.formCopiedModelsSetVal('globalSetting.model.calendarEventSchedulerDurationSeconds', _moment.default.duration((0, _moment.default)(this.calculatedEndTime, 'h:mm a').diff(startTime)).asSeconds());
    yield this.args.multiStepForm.validateSyncSaveAll({
      extraValidationData: {
        noValidate: ['globalSetting-salesScheduleTemplateMessage', 'globalSetting-techScheduleTemplateMessage', 'globalSetting-emailSalesScheduleAppointments', 'globalSetting-emailTechScheduleAppointments']
      }
    });
    Ember.set(this, 'formMode', 'view');
    this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
    this.calendarEventsService.trigger('update-calendar-event-scheduler-settings');
  }).restartable(), (_class = (_temp = class EditCalendarGlobalSettingsClass extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor, this);

      _initializerDefineProperty(this, "calendarEventsService", _descriptor2, this);

      _initializerDefineProperty(this, "calculatedEndTime", _descriptor3, this);

      _initializerDefineProperty(this, "formMode", _descriptor4, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor5, this);

      _initializerDefineProperty(this, "cancelTask", _descriptor6, this);

      _initializerDefineProperty(this, "saveCompanyCalendarSettingsTask", _descriptor7, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.initDataTask.perform();
    }

    get filteredTimeOptions() {
      return (0, _timeOptions.timeOptions)([30, '00:00', '23:30', 'h:mm a', 'h:mm a']);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "calendarEventsService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "calculatedEndTime", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "formMode", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'view';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "cancelTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "saveCompanyCalendarSettingsTask", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = EditCalendarGlobalSettingsClass;
});