define("securitytrax-ember-ui/components/calendar-glimmer/calendar-date-picker/component", ["exports", "@glimmer/component", "moment", "ember-concurrency"], function (_exports, _component, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CalendarDatePicker = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('events/calendar-events'), _dec4 = Ember.inject.service(), _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember.computed('dates', 'todayOffset', 'displayIndex', 'isMobile', 'CalendarDatePickerService.{selectedDates}'), _dec12 = Ember.computed('showReset'), _dec13 = (0, _emberConcurrency.task)(function* () {
    if (this.dates.length > 0) {
      var dateArray = yield this.dates.slice(this.displayIndex, this.displayIndex + this.args.displayOffset);
      return dateArray;
    }
  }), _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, (_class = (_temp = class CalendarDatePicker extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "CalendarDataService", _descriptor, this);

      _initializerDefineProperty(this, "CalendarDatePickerService", _descriptor2, this);

      _initializerDefineProperty(this, "CalendarEventsService", _descriptor3, this);

      _initializerDefineProperty(this, "calendarMapUserSelectService", _descriptor4, this);

      _initializerDefineProperty(this, "startDate", _descriptor5, this);

      _initializerDefineProperty(this, "showReset", _descriptor6, this);

      _initializerDefineProperty(this, "displayIndex", _descriptor7, this);

      _initializerDefineProperty(this, "todayOffset", _descriptor8, this);

      _initializerDefineProperty(this, "displayOffset", _descriptor9, this);

      _initializerDefineProperty(this, "dates", _descriptor10, this);

      _initializerDefineProperty(this, "datesToDisplayTask", _descriptor11, this);

      _defineProperty(this, "deselectDateCallback", function (date) {
        if (date.date.date() !== (0, _moment.default)(this.startDate).date() || this.CalendarDatePickerService.selectedDates.length > 1) {
          if (this.CalendarDatePickerService.selectedDates.length > 1) {
            if (date.selected === true) {
              date.toggleProperty('selected');
              this.removeDate(date);

              if (this.calendarMapUserSelectService.dateSelected && this.calendarMapUserSelectService.dateSelected.format('M-D-YYYY') === date.date.format('M-D-YYYY')) {
                this.calendarMapUserSelectService.selectAll(this.calendarMapUserSelectService.dateSelected, false);
              }
            }
          }
        }

        this.CalendarDatePickerService.sortDates();

        if (this.CalendarDatePickerService.selectedDates.length == 1 && this.CalendarDatePickerService.selectedDates[0].date.startOf('day').diff((0, _moment.default)().startOf('day')) === 0) {
          this.showReset = false;
        }

        this.CalendarEventsService.trigger('selectedDateChange');
      });

      _defineProperty(this, "selectDateCallback", function (date) {
        if (date.date.date() !== (0, _moment.default)(this.startDate).date() || this.CalendarDatePickerService.selectedDates.length > 1) {
          if (date.selected === false) {
            date.toggleProperty('selected');
            this.addDate(date);
          }
        } else if (date.date.date() === (0, _moment.default)(this.startDate).date() && date.selected === false) {
          date.toggleProperty('selected');
          this.addDate(date);
        }

        if (this.CalendarDatePickerService.selectedDates.length > 1) {
          this.showReset = true;
        }

        this.CalendarDatePickerService.sortDates();
        this.CalendarEventsService.trigger('selectedDateChange');
      });

      if (this.args.manualStartDate) {
        this.startDate = this.args.manualStartDate;
        this.resetState();
      } else if (this.CalendarDatePickerService.selectedDates && this.CalendarDatePickerService.selectedDates.length > 0) {
        this.startDate = this.CalendarDatePickerService.selectedDates[0].date;
        this.resetState();
      } else {
        this.startDate = (0, _moment.default)();
        this.initDates();
      }

      this.CalendarEventsService.on('selectDate', this, 'selectDateCallback');
      this.CalendarEventsService.on('deselectDate', this, 'deselectDateCallback');
      this.CalendarEventsService.on('resetDatePicker', this, 'resetState');
    }

    willDestroy() {
      this.CalendarEventsService.off('selectDate', this, 'selectDateCallback');
      this.CalendarEventsService.off('deselectDate', this, 'deselectDateCallback');
      this.CalendarEventsService.off('resetDatePicker', this, 'resetState');
    }

    initDates() {
      if (this.dates.length === 0) {
        var dateArray = Ember.A();

        if (!this.CalendarDatePickerService.selectedDates) {
          this.CalendarDatePickerService.initDates();
        }

        for (var i = 0; i < 8; i++) {
          var startDate = (0, _moment.default)(this.startDate);
          var date = Ember.Object.create({
            date: startDate.add(i, 'days'),
            selected: i === 0 ? true : false
          });
          this.isDateSelected(date);
          dateArray.push(date);
        }

        this.dates = dateArray;
        this.addDate(this.dates.objectAt(0));
      }
    }

    get today() {
      return (0, _moment.default)();
    }

    get datesToDisplay() {
      //add next 7 days
      return this.datesToDisplayTask.perform();
    }

    get showResetButton() {
      if (this.args.isMobile) {
        //trigger show mobile event
        this.CalendarEventsService.trigger('showMobileReset', this.showReset);
      }

      if (this.CalendarDatePickerService.selectedDates.length > 0) {
        if (this.CalendarDatePickerService.selectedDates.length > 1) {
          return true;
        }

        var firstDay = this.CalendarDatePickerService.selectedDates[0].date;
        var today = (0, _moment.default)();
        return !firstDay.isSame(today, 'day');
      } else {
        return this.showReset;
      }
    }

    get mobileClass() {
      if (this.args.isMobile) {
        return 'calendar--date-picker--mobile';
      }

      return '';
    }

    get dateSelectorClass() {
      if (this.args.isMobile) {
        return 'date-slider__selector--input';
      }

      return 'date-slider__selector-square';
    }

    get startDateObserver() {
      this.displayIndex = 0;
      this.todayOffset = 0;
      this.resetState();
      this.showReset = false;
      return null;
    }

    isDateSelected(date) {
      this.CalendarDatePickerService.selectedDates.forEach(selectedDate => {
        if (date.date.format('M-D-YYYY') === selectedDate.date.format('M-D-YYYY')) {
          date.selected = true;
        }
      });
    }

    addDate(date) {
      var addDate = true;
      this.CalendarDatePickerService.selectedDates.forEach(selectedDate => {
        if (date.date.format('M-D-YYYY') === selectedDate.date.format('M-D-YYYY')) {
          addDate = false;
        }
      });

      if (addDate) {
        this.CalendarDatePickerService.selectedDates.push(date);
        this.CalendarDataService.addDatePage(date);
      }
    }

    removeDate(date) {
      this.CalendarDataService.hideDate(date);
      var selectedDates = this.CalendarDatePickerService.selectedDates;
      var newSelectedDates = selectedDates.filter(aDate => aDate.date.date() != date.date.date());
      this.CalendarDatePickerService.selectedDates = newSelectedDates;
      Ember.notifyPropertyChange(this, 'datesToDisplay');
    }

    resetState() {
      this.dates = Ember.A();
      this.CalendarDatePickerService.selectedDates = Ember.A();
      this.CalendarDataService.hideAll();
      this.displayIndex = 0;
      this.todayOffset = 0;
      this.initDates();
      this.showReset = false;
      this.CalendarEventsService.trigger('selectedDateChange');
    }

    getSelected() {
      this.dates.forEach(date => {
        if (this.CalendarDatePickerService.selectedDates.includes(date)) {
          date.selected = true;
        }
      });
    }

    updateStartDate() {
      this.displayIndex = 0;
      this.todayOffset = 0;
      this.resetState();
      this.showReset = false;
    }

    moveSlider(direction) {
      if (direction === 'right') {
        if (this.dates.length <= this.displayIndex + 8) {
          //push to dates
          var startDate = (0, _moment.default)(this.startDate);
          var date = Ember.Object.create({
            date: startDate.add(this.dates.length, 'days'),
            selected: false
          });
          this.isDateSelected(date);
          this.dates.push(date);
        }

        var newIndex = this.displayIndex + 1;
        this.displayIndex = newIndex;
      } else {
        if (this.displayIndex > 0) {
          //slide to the left
          var _newIndex = this.displayIndex - 1;

          this.displayIndex = _newIndex;
        } else {
          //unshift to dates
          if (!this.args.isScheduler) {
            var newOffeset = this.todayOffset + 1;

            var _startDate = (0, _moment.default)(this.startDate);

            var _date = Ember.Object.create({
              date: _startDate.subtract(newOffeset, 'days'),
              selected: false
            });

            this.isDateSelected(_date);
            this.dates.unshift(_date);
            this.todayOffset = newOffeset;
          }
        }
      }
    }

    resetSlider() {
      this.startDate = undefined;
      this.CalendarEventsService.trigger('resetDatePicker');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "CalendarDataService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "CalendarDatePickerService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "CalendarEventsService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "calendarMapUserSelectService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "startDate", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "showReset", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "displayIndex", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "todayOffset", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "displayOffset", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "dates", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _applyDecoratedDescriptor(_class.prototype, "datesToDisplay", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "datesToDisplay"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showResetButton", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "showResetButton"), _class.prototype), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "datesToDisplayTask", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getSelected", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "getSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateStartDate", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "updateStartDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "moveSlider", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "moveSlider"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetSlider", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "resetSlider"), _class.prototype)), _class));
  _exports.default = CalendarDatePicker;
});