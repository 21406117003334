define("securitytrax-ember-ui/components/html/st-list-item-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "orLgeOpg",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"st-list-item__image\"],[12],[2,\"\\n    \"],[8,\"html/st-thumbnail\",[],[[\"@title\",\"@thumbnail\",\"@showShortTitle\"],[[34,0],[34,1],[34,2]]],null],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"st-list-item__line\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"st-list-item-image__text\"],[12],[1,[34,0]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"content\",\"thumbnail\",\"showShortTitle\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/st-list-item-image/template.hbs"
    }
  });

  _exports.default = _default;
});