define("securitytrax-ember-ui/validations/payroll-column-calculation", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation", "lodash"], function (_exports, _validate, _baseValidation, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var noValidate = _lodash.default.get(data, 'extraValidationData.noValidate', false);

      var validateObject = {
        excelFunction: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('payrollColumnCalculation-excelFunction')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'payrollColumnCalculation-excelFunction');
            }
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject, {
        fullMessages: this.prependProperty(data)
      });
      return this.outputFormatting(validationData, 'payrollColumnCalculation');
    }

  });

  _exports.default = _default;
});