define("securitytrax-ember-ui/helpers/reload-relationship", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function reloadRelationship(args) {
    var [data] = args;

    var callbackFunction = () => {
      var hasManyData = data.model.hasMany(data.relName);
      var reloadPromise;

      if (hasManyData.hasManyRelationship.relationshipIsEmpty) {
        reloadPromise = hasManyData.load();
      } else {
        reloadPromise = hasManyData.reload();
      }

      return reloadPromise;
    };

    return callbackFunction;
  }

  var _default = Ember.Helper.helper(reloadRelationship);

  _exports.default = _default;
});