define("securitytrax-ember-ui/validations/account-number-block", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateObject = {
        startNumber: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('accountNumberBlock-startNumber')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'accountNumberBlock-startNumber');
            }
          }
        },
        endNumber: {
          presence: () => {
            return this.customMessageOrDefault(data, 'accountNumberBlock-endNumber');
          }
        },
        receiverNumber: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('accountNumberBlock-receiverNumber')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'accountNumberBlock-receiverNumber');
            }
          }
        },
        monitoringCompany: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('accountNumberBlock-monitoringCompany')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'accountNumberBlock-monitoringCompany');
            }
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      return this.outputFormatting(validationData, 'accountNumberBlock');
    }

  });

  _exports.default = _default;
});