define("securitytrax-ember-ui/components/multi-step-form/forms/administration/users/my-account/password-reset/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms/administration/users/base-users"], function (_exports, _emberConcurrency, _baseUsers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseUsers.default.extend({
    password: '',
    currentPassword: '',
    passwordConfirm: '',

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData("passwordReset", 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.sendPasswordReset(multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary user-save-button'
      }]);
    },

    sendPasswordReset(multiStepForm, buttonPromise) {
      //push passwords onto multiStepForm.formCopiedModels.user.model
      multiStepForm.formCopiedModels.user.model.currentPassword = this.currentPassword;
      multiStepForm.formCopiedModels.user.model.password = this.password;
      multiStepForm.formCopiedModels.user.model.passwordVerify = this.passwordVerify;
      return this.sendPasswordResetTask.perform(multiStepForm, buttonPromise);
    },

    sendPasswordResetTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var noValidateArray = ['username', 'fname', 'lname', 'email', 'group'];

      if (this.generatePassword) {
        noValidateArray.push('password');
      }

      yield multiStepForm.deepValidateAll.perform({
        extraValidationData: {
          validationMessages: {
            passwordEqual: this.intl.t('pages.users.validation_messages.password_equal'),
            passwordLength: this.intl.t('pages.users.validation_messages.password_length')
          },
          noValidate: noValidateArray,
          myAccount: true
        }
      });

      try {
        var user = yield this.user;
        var userPasswordSetting = yield user.get('userPasswordSetting');
        var attributes = this.password ? {
          current_password: this.currentPassword,
          password: this.password
        } : null;
        yield userPasswordSetting.invokeAction('change_my_password', {
          verb: 'POST',
          data: {
            data: {
              attributes: attributes,
              type: 'user_password_settings_action'
            }
          },
          processResponse: true
        });
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
        buttonPromise.then(() => {
          multiStepForm.formStartedSetVal(false);
        });
      } catch (error) {
        this.stNotificationManager.addErrorMessages(error, {
          notificationNamespace: 'modal'
        });
        throw error;
      }
    })
  });

  _exports.default = _default;
});