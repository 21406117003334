define("securitytrax-ember-ui/services/subroute-mapping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    get monitorMapping() {
      return [{
        'id': '1',
        'name': 'adt'
      }, {
        'id': '2',
        'name': 'rapid-response'
      }, {
        'id': '3',
        'name': 'brinks-home-security'
      }, {
        'id': '4',
        'name': 'security-networks'
      }, {
        'id': '5',
        'name': 'legacy'
      }, {
        'id': '6',
        'name': 'sentry-net'
      }, {
        'id': '7',
        'name': 'guardian-protection-services'
      }, {
        'id': '8',
        'name': 'avantguard'
      }, {
        'id': '10',
        'name': 'central-security-group'
      }, {
        'id': '11',
        'name': 'united-central-control'
      }, {
        'id': '12',
        'name': 'dynamark-monitoring'
      }, {
        'id': '13',
        'name': 'legacy-security-services'
      }, {
        'id': '14',
        'name': 'guardian-protection'
      }, {
        'id': '15',
        'name': 'securenet'
      }, {
        'id': '16',
        'name': 'skycover'
      }, {
        'id': '17',
        'name': 'affiliated-monitoring'
      }, {
        'id': '18',
        'name': 'security-partners'
      }, {
        'id': '19',
        'name': 'tnt-alarm'
      }, {
        'id': '20',
        'name': 'residencia'
      }, {
        'id': '21',
        'name': 'utah-alarm'
      }, {
        'id': '22',
        'name': 'ialarm'
      }, {
        'id': '23',
        'name': 'safe-sound'
      }, {
        'id': '24',
        'name': 'cms'
      }, {
        'id': '25',
        'name': 'cops-monitoring'
      }, {
        'id': '26',
        'name': 'protection-one'
      }, {
        'id': '27',
        'name': 'telus'
      }, {
        'id': '28',
        'name': 'safe'
      }, {
        'id': '29',
        'name': 'inhoome'
      }, {
        'id': '30',
        'name': 'smart haven'
      }, {
        'id': '31',
        'name': 'cransten'
      }, {
        'id': '32',
        'name': 'asvx'
      }, {
        'id': '33',
        'name': 'ackerman'
      }, {
        'id': '34',
        'name': 'adc'
      }, {
        'id': '35',
        'name': 'moment'
      }, {
        'id': '36',
        'name': 'american-two-way'
      }, {
        'id': '37',
        'name': 'api-monitoring'
      }, {
        'id': '38',
        'name': 'vio'
      }, {
        'id': '39',
        'name': 'alarm-central'
      }, {
        'id': '40',
        'name': 'affinity'
      }, {
        'id': '41',
        'name': 'all-american-monitoring'
      }, {
        'id': '42',
        'name': 'esc-central'
      }];
    },

    get ispMapping() {
      return [{
        'id': '1',
        'name': 'adc'
      }, {
        'id': '2',
        'name': 'securenet'
      }];
    }

  });

  _exports.default = _default;
});