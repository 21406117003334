define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/forms/adt/schedule/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "49nS+GCN",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[17,1],[12],[2,\"\\n    \"],[8,\"multi-step-form/progress-bar\",[],[[\"@config\",\"@modalButtons\",\"@formClass\",\"@loadedNamespace\"],[[34,3,[\"progressBarConfig\"]],[34,3,[\"currentStepData\",\"buttons\"]],\"no-horizontal-scroll\",[30,[36,5],[[35,4],\"step6\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[35,4],\"customerCreateWizard\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"customer/warnings-card\",[[24,0,\"customer-warnings--margin-bottom\"]],[[\"@customer\",\"@showWarnings\"],[[34,0],[34,1]]],null],[2,\"\\n\\n            \"],[8,\"html/section-divider\",[],[[\"@title\"],[[30,[36,2],[\"common.nav.create.customer.sections.schedule\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,\"calendar-glimmer/calendar-scheduling\",[],[[\"@multiStepForm\",\"@customer\",\"@afterScheduleAppointment\",\"@popoverContainer\",\"@currFormName\"],[[34,3],[34,3,[\"formOriginalModels\",\"customer\",\"model\"]],[30,[36,8],[[32,0],\"afterScheduleAppointment\"],null],\".md-dialog-content\",[34,4]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"newCustomer\",\"showWarnings\",\"t\",\"multiStepForm\",\"currFormName\",\"concat\",\"eq\",\"if\",\"action\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/forms/adt/schedule/template.hbs"
    }
  });

  _exports.default = _default;
});