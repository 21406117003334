define("securitytrax-ember-ui/components/multi-step-form/forms/administration/permissions/add-group-users/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/switchable-form-step", "securitytrax-ember-ui/components/multi-step-form/forms/administration/permissions/permissions-base", "lodash", "jquery", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _emberConcurrency, _switchableFormStep, _permissionsBase, _lodash, _jquery, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _permissionsBase.default.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    allUsersList: undefined,
    resultsLoaded: true,

    didInsertElement() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        addArray: []
      });
      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('step1', 'buttons', [{
        text: this.intl.t('common.actions.review'),
        click: () => {
          multiStepForm.currentStepNameKeySetVal('review');
        },
        classNamesString: 'st-btn st-btn-primary save-review-group-users-button'
      }]);
    },

    findActiveUsersNoGroup: (0, _emberConcurrency.task)(function* () {
      var group = this.addEditGroup;
      var groupUsers = yield this.store.query('user', {
        filter: '(group = null or group != \'' + group.id + '\') and user_admin_setting.active = true',
        include: 'user_hr_setting.user_hierarchy_title,group,user_admin_setting',
        sort: 'lname',
        limit: 0
      });
      this.allUsersList = [];
      groupUsers.toArray().forEach(user => {
        this.allUsersList.push({
          user,
          inAddArray: false
        });
      });
      return this.allUsersList;
    }).drop(),
    searchResults: Ember.computed(function () {
      return this.getSearchResults.perform();
    }),
    updatedSearchString: Ember.observer('searchUsers', function () {
      return this.updateSearchTask.perform();
    }),
    updateSearchTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(500); //make sure they are done typing before searching

      var newSearchResults = yield this.getSearchResults.perform();
      this.set('searchResults', newSearchResults);
      Ember.run.next(() => {
        (0, _jquery.default)('.permissions__add-group-user-search input').focus();
      });
    }).restartable(),
    getSearchResults: (0, _emberConcurrency.task)(function* () {
      var searchValue = this.searchUsers;
      var fullList = this.allUsersList ? yield this.allUsersList : yield this.findActiveUsersNoGroup.perform();

      for (var x = 0; x < fullList.length; x++) {
        var user = fullList[x];

        for (var y = 0; y < this.addArray.length; y++) {
          if (user.user.id === this.addArray[y].user.id) {
            Ember.set(user, 'inAddArray', true);
          }
        }
      }

      if (searchValue) {
        searchValue = searchValue.toLowerCase();
        var newSearchResults = yield _lodash.default.filter(fullList, function (user) {
          return user.user.get('fname').toLowerCase().includes(searchValue) || user.user.get('lname').toLowerCase().includes(searchValue) || user.user.get('flname').toLowerCase().includes(searchValue) || user.user.get('lfname').toLowerCase().includes(searchValue);
        });
        return newSearchResults;
      } else {
        return fullList;
      }
    }).restartable().maxConcurrency(1),
    actions: {
      addUserToArray(user) {
        var addArray = this.addArray;
        Ember.set(user, 'inAddArray', true);
        addArray.push(user);
        Ember.set(this, 'addArray', addArray);
        this.notifyPropertyChange('addArray');
      },

      removeUserFromArray(user) {
        Ember.set(user, 'inAddArray', false);
        var addArray = this.addArray;
        var resetAddArray = addArray.filter(arrayUser => {
          return arrayUser.user.id != user.user.get('id');
        });
        Ember.set(this, 'addArray', resetAddArray);
      }

    }
  });

  _exports.default = _default;
});