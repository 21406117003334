define("securitytrax-ember-ui/components/multi-step-form/forms/at-risk-entries/edit-at-risk-entry/edit-resolution-attempt/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "securitytrax-ember-ui/mixins/set-user", "ember-concurrency"], function (_exports, _switchableFormStep, _setUser, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, _setUser.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    grants: Ember.inject.service(),
    homeDashboard: Ember.inject.service('events/home-dashboard'),
    confirmDeleteModalOpen: false,

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      var buttonChoices = [];

      if (this.formMode !== 'view') {
        buttonChoices.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveAtRiskNote.perform(multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary save-at-risk-entry-resolution-attempt'
        });
      }

      if (!this.isNewRecord && this.grants.getIndividualGrant('customer_at_risk_notes.delete')) {
        buttonChoices.push({
          text: this.intl.t('common.actions.delete'),
          click: () => {
            this.set('confirmDeleteModalOpen', true);
          },
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
        });
      }

      multiStepForm.registerStepData('step1', 'buttons', buttonChoices);
    },

    resolutionOptions: Ember.computed(function () {
      var intl = this.intl;
      var resolutionOptions = [];
      resolutionOptions.push(Ember.Object.create({
        name: intl.t('pages.customers.at_risk_entries.note_options.answer'),
        value: 'answer'
      }));
      resolutionOptions.push(Ember.Object.create({
        name: intl.t('pages.customers.at_risk_entries.note_options.no_answer'),
        value: 'no_answer'
      }));
      resolutionOptions.push(Ember.Object.create({
        name: intl.t('pages.customers.at_risk_entries.note_options.message'),
        value: 'message'
      }));
      resolutionOptions.push(Ember.Object.create({
        name: intl.t('pages.customers.at_risk_entries.note_options.inbound_call'),
        value: 'inbound_call'
      }));
      resolutionOptions.push(Ember.Object.create({
        name: intl.t('pages.customers.at_risk_entries.note_options.email'),
        value: 'email'
      }));
      resolutionOptions.push(Ember.Object.create({
        name: intl.t('pages.customers.at_risk_entries.note_options.letter'),
        value: 'letter'
      }));
      resolutionOptions.push(Ember.Object.create({
        name: intl.t('pages.customers.at_risk_entries.note_options.internal'),
        value: 'internal'
      }));
      resolutionOptions.push(Ember.Object.create({
        name: intl.t('pages.customers.at_risk_entries.note_options.third_party'),
        value: '3rd_party'
      }));
      return resolutionOptions;
    }),
    saveAtRiskNote: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      try {
        if (this.isNewRecord) {
          var toCreateObjects = yield multiStepForm.validateCreateAll();
          Ember.set(toCreateObjects.customerAtRiskNote.data, 'customerAtRisk', this.atRiskEntry);
          var customerAtRiskNote = this.store.createRecord('customerAtRiskNote', toCreateObjects.customerAtRiskNote.data);
          yield toCreateObjects.customerAtRiskNote.saveWithRollbackAndNotification(customerAtRiskNote);
        } else {
          var validationObject = {
            deepValidate: false,
            deepCreate: false
          };
          yield multiStepForm.validateSyncSaveAll(validationObject);
        }

        buttonPromise.then(() => {
          multiStepForm.formStartedSetVal(false);
          this.homeDashboard.trigger('refresh-at-risk-entries');
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
        });
      } catch (err) {
        (false && Ember.warn('Failed in At Risk Entry Save: ' + err, {
          id: 'multiStepForm.forms.edit-resolution-attempt.saveAtRiskNote'
        }));
        throw err;
      }
    }),
    deleteAtRiskNote: (0, _emberConcurrency.task)(function* (buttonPromise) {
      try {
        var multiStepForm = this.multiStepForm;
        var atRiskEntryNoteToDelete = yield multiStepForm.formOriginalModelsGetVal('customerAtRiskNote.model');
        yield atRiskEntryNoteToDelete.destroyRecordWithRollbackTask.perform();
        this.homeDashboard.trigger('refresh-at-risk-entries');
        buttonPromise.then(() => {
          multiStepForm.formStartedSetVal(false);
          this.stNotificationManager.addSuccessKey('common.notifications.delete_successful', 'modal');
        });
      } catch (err) {
        warn('Delete At Risk Entry Failed: ' + err), {
          id: 'multiStepForm.forms.edit-resolution-attempt.deleteAtRiskNote'
        };
        throw err;
      }
    })
  });

  _exports.default = _default;
});