define("securitytrax-ember-ui/reports/my-reports/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2UDtKC65",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"page-content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n        \"],[10,\"h1\"],[14,0,\"card-title\"],[12],[2,\"\\n            \"],[10,\"span\"],[12],[1,[30,[36,0],[\"pages.reports.nav.my_reports\"],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/reports/my-reports/template.hbs"
    }
  });

  _exports.default = _default;
});