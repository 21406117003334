define("securitytrax-ember-ui/mixed-in-functions/grouped-property-sections", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getGroupedPropertySections;

  function getGroupedPropertySections() {
    var fnGetFormatter = (formatter, propertyName) => ({
      formatter,
      propertyName
    });

    return [{
      position: 'left',
      sectionProperties: [fnGetFormatter('svg-icon', 'icon'), fnGetFormatter('label', 'name')]
    }, {
      position: 'right',
      sectionProperties: [fnGetFormatter('count', 'items')]
    }];
  }
});