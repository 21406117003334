define("securitytrax-ember-ui/components/multi-step-form/forms/administration/payables/level-details/add-assignee/review/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/switchable-form-step"], function (_exports, _emberConcurrency, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('review', 'buttons', [{
        text: this.intl.t('common.actions.back'),
        click: () => {
          multiStepForm.currentStepNameKeySetVal('addAssignee');
        },
        classNamesString: 'st-btn st-btn-secondary cancel-group-users-button'
      }, {
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.addLevelUsersTask.perform(buttonPromise, multiStepForm);
        },
        classNamesString: 'st-btn st-btn-primary save-group-users-button'
      }]);
    },

    addLevelUsersTask: (0, _emberConcurrency.task)(function* (buttonPromise, multiStepForm) {
      try {
        var payeesToSave = [];

        for (var x = 0; x < this.addArray.length; x++) {
          var user = this.addArray[x];

          if (user.currentLevel) {
            user.currentLevel.set('payrollRoleFunctionLevel', this.payrollRoleFunctionLevel);
            payeesToSave.push(user.currentLevel.save());
          } else {
            var payablePayeeLevel = yield this.store.createRecord('payablePayeeLevel', {
              payrollRoleFunctionLevel: this.payrollRoleFunctionLevel,
              user: user.user
            });
            payeesToSave.push(payablePayeeLevel.save());
          }
        }

        yield (0, _emberConcurrency.all)(payeesToSave);
        buttonPromise.then(() => {
          this.set('addArray', []);
          multiStepForm.formStartedSetVal(false);
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
        (false && Ember.warn('Payables add users to level error' + err, {
          id: 'multiStepForm.forms.administration.payables.add-assignee'
        }));
        throw err;
      }
    })
  });

  _exports.default = _default;
});