define("securitytrax-ember-ui/leads/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller.extend({
    leadId: undefined,
    lead: undefined,
    editDashboard: undefined,
    actions: {
      toggleSettings() {
        this.editDashboard.toggleConfig();
      }

    }
  });
  var _default = Controller;
  _exports.default = _default;
});