define("securitytrax-ember-ui/helpers/leaflet-calendar-appointment-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global L */
  var _default = Ember.Helper.extend({
    userService: Ember.inject.service(),

    compute(params) {
      var classname = params[0];
      var userAvatar = params[1];
      var index = params[2];
      var typeClass = params[3];
      var userInitials = params[4];
      var iconSize = [72, 47];
      var iconAnchor = [36, 58];

      if (!userAvatar) {
        userAvatar = "<div class='img-circle profile-img profile-img--initials'>".concat(userInitials, "</div>");
      } else {
        userAvatar = "<img class='img-circle profile-img' src='".concat(userAvatar, "'/>");
      }

      var html = "<div class='calendar-icon__outter' style='background-color:".concat(typeClass, ";'>").concat(userAvatar, "<div class='icon-text icon-text-").concat(index, "'>").concat(index, "</div></div>");

      if (index === 0) {
        var iconName = 'icn-home';
        html = "<div class='calendar-icon__outter' style='background-color:".concat(typeClass, ";'>").concat(userAvatar, "<div class='icon-text icon-text-").concat(index, "'><svg xmlns='http://www.w3.org/2000/svg' role='img' class='icon svg-icon-").concat(iconName, "'><use href=\"/assets/icons.svg#").concat(iconName, "\" xlink:href=\"/assets/icons.svg#").concat(iconName, "\" class=\"icon-").concat(iconName, "\"></use></svg></div></div>");
      }

      if (index === undefined) {
        var _iconName = 'icn-help';
        iconSize = [50, 47];
        iconAnchor = [25, 58];
        html = "<div class='calendar-icon__outter calendar-icon__outter--small' style='background-color:#ffffff;'><div class='icon-text icon-text-".concat(index, "'><svg xmlns='http://www.w3.org/2000/svg' role='img' class='icon svg-icon-").concat(_iconName, "'><use href=\"/assets/icons.svg#").concat(_iconName, "\" xlink:href=\"/assets/icons.svg#").concat(_iconName, "\" class=\"icon-").concat(_iconName, "\"></use></svg></div></div>");
      }

      return L.divIcon({
        iconSize: iconSize,
        iconAnchor: iconAnchor,
        popupAnchor: [0, 10],
        className: classname,
        html: html
      });
    }

  });

  _exports.default = _default;
});