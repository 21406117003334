define("securitytrax-ember-ui/models/base_models/consumer-financing-application", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    marriedToCoapplicant: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    lenderApplicationId: (0, _model.attr)('string', {
      'readOnly': true
    }),
    lenderApplicationState: (0, _model.attr)('string', {
      'readOnly': false
    }),
    applicationState: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['pending', 'submitted', 'approved', 'declined']
    }),
    lenderApplicantId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    lenderCoapplicantId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    creditAmountRequested: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    amountFinanced: (0, _model.attr)('number-precision', {
      'readOnly': true,
      'precision': 2
    }),
    currentBalance: (0, _model.attr)('number-precision', {
      'readOnly': true,
      'precision': 2
    }),
    salesTax: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    salesTaxIncluded: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    sellingPrice: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    downPayment: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    contractLanguage: (0, _model.attr)('string', {
      'readOnly': false
    }),
    contractState: (0, _model.attr)('string', {
      'readOnly': false
    }),
    productOrService: (0, _model.attr)('string', {
      'readOnly': false
    }),
    completionDate: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    buyRate: (0, _model.attr)('string', {
      'readOnly': false
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    consumerFinancingApplicant: (0, _model.belongsTo)('consumer-financing-applicant', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': 'applicantConsumerFinancingApplications'
    }),
    consumerFinancingCoapplicant: (0, _model.belongsTo)('consumer-financing-applicant', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': 'coapplicantConsumerFinancingApplications'
    }),
    consumerFinancingLender: (0, _model.belongsTo)('consumer-financing-lender', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});