define("securitytrax-ember-ui/components/spinner-svg/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ALLOWED_SIZES = void 0;
  var VIEWBOX = '0 0 18 18';
  var XMLNS = 'http://www.w3.org/2000/svg';
  /**
   * Allowed sizes for the spinner
   *
   * @private
   * @static
   * @memberof components.SpinnerSvg
   *
   * @type {Array<String>}
   */

  var ALLOWED_SIZES = ['x-small', 'small', 'medium', 'large', 'x-large'];
  /**
   * Default spinner size.
   *
   * @private
   * @static
   * @memberof components.SpinnerSvg
   *
   * @type {String}
   */

  _exports.ALLOWED_SIZES = ALLOWED_SIZES;
  var DEFAULT_SIZE = 'medium';
  /**
   * @classdesc
   *
   * Component for showing a spinner.
   *
   * @class SpinnerSvg
   * @extends Ember.Component
   * @memberof components
   */

  var SpinnerSvg = Ember.Component.extend(
  /** @lends components.SpinnerSvg# */
  {
    tagName: 'svg',
    classNames: ['spinner'],
    classNameBindings: ['spinning', 'sizeInternal'],
    attributeBindings: ['viewBox', 'width', 'height', 'xmlns'],
    viewBox: VIEWBOX,
    xmlns: XMLNS,

    /**
     * Calculates size for a CSS class.
     *
     * @function
     *
     * @returns {String}
     */
    sizeInternal: Ember.computed('size', function () {
      var size = this.size; // If the size is default or not in the list, don't append any class.

      if (size === DEFAULT_SIZE || ALLOWED_SIZES.indexOf(size) < 0) {
        size = undefined;
      }

      return size;
    }),

    /**
     * Is the spinner spinning?
     *
     * @type {boolean}
     */
    spinning: false
  });
  var _default = SpinnerSvg;
  _exports.default = _default;
});