define("securitytrax-ember-ui/mixins/pending-equipment-reusable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    totalItems: Ember.computed('pendingEquipmentLineItems.[]', function () {
      var count = 0;
      this.pendingEquipmentLineItems.forEach(item => {
        count += Number(item.get('quantity'));
      });
      return count;
    }),
    totalCost: Ember.computed('pendingEquipmentLineItems.[]', function () {
      var cost = 0.00;
      this.pendingEquipmentLineItems.forEach(item => {
        cost += +item.get('cost') * +item.get('quantity');
      });
      return cost;
    }),
    recipient: Ember.computed('user', 'location', function () {
      return this.get('user.id') ? this.user : this.location;
    }),
    receivedStatusKey: Ember.computed('pendingEquipmentLineItems.@each.{quantity,fulfilledQuantity}', function () {
      var quantity = 0;
      var fulfilledQuantity = 0;
      this.pendingEquipmentLineItems.forEach(lineItem => {
        quantity += lineItem.get('quantity');
        fulfilledQuantity += lineItem.get('fulfilledQuantity');
      });

      if (quantity > fulfilledQuantity && fulfilledQuantity === 0) {
        return 'none';
      } else if (quantity > fulfilledQuantity && fulfilledQuantity > 0) {
        return 'partially';
      } else {
        return 'in_full';
      }
    }),
    receivedStatusClass: Ember.computed('receivedStatusKey', function () {
      var color = '#d92c29';

      switch (this.receivedStatusKey) {
        case 'in_full':
          color = '#26b844';
          break;

        case 'partially':
          color = '#0f86d3';
          break;
      }

      return 'color: ' + color;
    })
  });

  _exports.default = _default;
});