define("securitytrax-ember-ui/components/pages/administration/inventory/physical-inventory/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3MFLliF2",
    "block": "{\"symbols\":[],\"statements\":[[8,\"html/st-card\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"html/st-card/divider\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[14,0,\"st-card__divider-cell st-card__divider-title-cell\"],[12],[2,\"\\n            \"],[10,\"h1\"],[14,0,\"st-card__title\"],[12],[1,[30,[36,0],[\"pages.administration.inventory.perform_physical_inventory.title\"],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"html/st-await\",[],[[\"@useSpinner\",\"@booleanLoaded\"],[true,[30,[36,2],[[35,1],null],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"inventory/admin-perform-inventory\",[],[[],[]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"users\",\"not-eq\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/administration/inventory/physical-inventory/template.hbs"
    }
  });

  _exports.default = _default;
});