define("securitytrax-ember-ui/components/calendar-glimmer/calendar-filter/component", ["exports", "@glimmer/component", "ember-concurrency", "lodash"], function (_exports, _component, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CalendarFilter = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service('events/calendar-events'), _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = (0, _emberConcurrency.task)(function* () {
    var reps = yield this.UserService.getUsersList('tech', 'customer');
    reps.push({
      id: 'inactive',
      name: 'Inactive Users'
    });
    return reps;
  }), _dec14 = (0, _emberConcurrency.task)(function* () {
    var reps = yield this.UserService.getUsersList('sales_rep', 'customer');
    reps.push({
      id: 'inactive',
      name: 'Inactive Users'
    });
    return reps;
  }), _dec15 = (0, _emberConcurrency.task)(function* () {
    var salesRepIds = [];
    var techIds = [];
    var newFilters = yield this.stFiltersService.getFiltersByGroupKeyAsObject('calendarFilter');

    if (newFilters['salesRepsFilter'] && newFilters['salesRepsFilter'].length > 0) {
      salesRepIds = newFilters['salesRepsFilter'].map(user => {
        return user.id;
      });
    }

    if (newFilters['techFilter'] && newFilters['techFilter'].length > 0) {
      techIds = newFilters['techFilter'].map(user => {
        return user.id;
      });
    }

    var filterList = _lodash.default.unionWith(techIds, salesRepIds, _lodash.default.isEqual);

    var hasInactive = _lodash.default.remove(filterList, function (n) {
      return n == 'inactive';
    });

    if (hasInactive.length > 0) {
      this.showInactiveUsers = true;
    } else {
      this.showInactiveUsers = false;
    }

    this.args.updateShowInactiveUsers(this.showInactiveUsers);
    var calendarUsers = Ember.A();

    if (filterList.length > 0) {
      var filter = [];
      var activeString = 'active = true AND';

      if (this.showInactiveUsers) {
        activeString = 'active = false OR';
      }

      if (filterList.length > 0) {
        filter.push(activeString + ' user.id = [' + filterList.join(',') + ']');
      } else {
        filter.push(activeString);
      }

      this.filter = filter;
      calendarUsers = yield this.store.query('calendarUser', {
        filter: filter
        /*  + (this.get('filters.length') > 0 ? (' and ' + (this.filters.join(' and '))) : ''), */
        ,
        include: 'roles,user',
        sort: 'lname'
      });
    } else {
      this.filter = Ember.A();
    }

    this.args.filterResults(calendarUsers);
  }), _dec16 = (0, _emberConcurrency.task)(function* () {
    try {
      var localFilter = this.session.get('data.calendarFilter');
      var salesReps = yield this.salesRepFilterList;
      var techFilter = yield this.techFilterList;

      if (localFilter !== null && typeof localFilter !== 'undefined' && Object.keys(JSON.parse(localFilter)).length > 0) {
        var parsedFilter = JSON.parse(localFilter);

        if (parsedFilter['salesReps']) {
          var salesRepIds = parsedFilter['salesReps'];
          var salesRepsSelected = salesReps.filter(rep => {
            return salesRepIds.includes(rep.id);
          });

          if (salesRepsSelected && salesRepsSelected.length > 0) {
            this.salesRepsFilter = salesRepsSelected;
          }
        }

        if (parsedFilter['techFilter']) {
          var techFilterIds = parsedFilter['techFilter'];
          var techFilterSelected = techFilter.filter(rep => {
            return techFilterIds.includes(rep.id);
          });

          if (techFilterSelected && techFilterSelected.length > 0) {
            this.techFilter = techFilterSelected;
          }
        }
      }

      yield this.filterUsersTask.perform();
    } catch (error) {
      (false && Ember.warn(error, {
        id: 'app.component.customer-search-list.initFilterData'
      }));
    }
  }), _dec17 = Ember._action, _dec18 = Ember._action, (_class = (_temp = class CalendarFilter extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "UserService", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      _initializerDefineProperty(this, "stFiltersService", _descriptor5, this);

      _initializerDefineProperty(this, "CalendarEventsService", _descriptor6, this);

      _initializerDefineProperty(this, "stFiltersService", _descriptor7, this);

      _initializerDefineProperty(this, "filterList", _descriptor8, this);

      _defineProperty(this, "salesRepFilterList", undefined);

      _defineProperty(this, "techFilterList", undefined);

      _defineProperty(this, "showInactiveUsers", false);

      _initializerDefineProperty(this, "appliedFiltersCount", _descriptor9, this);

      _initializerDefineProperty(this, "salesRepsFilter", _descriptor10, this);

      _initializerDefineProperty(this, "techFilter", _descriptor11, this);

      _initializerDefineProperty(this, "filter", _descriptor12, this);

      _initializerDefineProperty(this, "techSortedTask", _descriptor13, this);

      _initializerDefineProperty(this, "salesRepsSortedTask", _descriptor14, this);

      _initializerDefineProperty(this, "filterUsersTask", _descriptor15, this);

      _initializerDefineProperty(this, "initFilterDataTask", _descriptor16, this);

      this.salesRepFilterList = this.salesRepsSorted;
      this.techFilterList = this.techSorted;
      this.initFilterDataTask.perform();
    }

    get techSorted() {
      return this.techSortedTask.perform();
    }

    get salesRepsSorted() {
      return this.salesRepsSortedTask.perform();
    }

    get initFilterData() {
      return this.initFilterDataTask.perform();
    }

    clearFilter() {
      this.salesRepsFilter = Ember.A();
      this.techFilter = Ember.A();
      this.filterUsersTask.perform();
    }

    filterUsers() {
      this.filterUsersTask.perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "UserService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "stFiltersService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "CalendarEventsService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "stFiltersService", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "filterList", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "appliedFiltersCount", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "salesRepsFilter", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "techFilter", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "filter", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "techSortedTask", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "salesRepsSortedTask", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "filterUsersTask", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "initFilterDataTask", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "clearFilter", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterUsers", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "filterUsers"), _class.prototype)), _class));
  _exports.default = CalendarFilter;
});