define("securitytrax-ember-ui/utils/calendar-hours", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = calendarHours;

  function calendarHours(firstHour = (0, _moment.default)({
    hour: 8
  }), lastHour = (0, _moment.default)({
    hour: 20
  })) {
    var halfHours = [],
        halfHour = _moment.default.duration(30, 'minutes');

    var hourToPrint = (0, _moment.default)(firstHour);

    while (lastHour.diff(hourToPrint) >= 0) {
      halfHours.push(hourToPrint);
      hourToPrint = hourToPrint.clone().add(halfHour);
    }

    return halfHours;
  }
});