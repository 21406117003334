define("securitytrax-ember-ui/components/multi-step-form/forms/system/affiliated-monitoring/history/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency"], function (_exports, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    centralStationService: Ember.inject.service(),
    disabledProgressBarSteps: Ember.computed(function () {
      return [];
    }),
    hiddenProgressBarSteps: Ember.computed(function () {
      return [];
    }),
    customerHistory: Ember.computed(function () {
      return this.getCustomerHistory.perform();
    }),
    getCustomerHistory: (0, _emberConcurrency.task)(function* () {
      var history = yield this.centralStationService.getData({
        model: this.customer,
        method: 'affiliated_monitoring__getRecentHistory'
      });
      return history.recent_history;
    })
  });

  _exports.default = _default;
});