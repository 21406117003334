define("securitytrax-ember-ui/services/sale-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),

    saleTypesList() {
      return [{
        value: 'sale',
        name: this.intl.t('pages.customers.accounting.customer_payments.sale_types.sale')
      }, {
        value: 'credit',
        name: this.intl.t('pages.customers.accounting.customer_payments.sale_types.credit')
      }, {
        value: 'refund',
        name: this.intl.t('pages.customers.accounting.customer_payments.sale_types.refund')
      }];
    }

  });

  _exports.default = _default;
});