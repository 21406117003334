define("securitytrax-ember-ui/customers/notes/edit-create-note/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lf8ng/S3",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"customer\",\"noteId\",\"parentRoute\"],[[35,0,[\"customer\"]],[35,0,[\"customerNoteId\"]],[35,0,[\"parentRoute\"]]]]]]],\"hasEval\":false,\"upvars\":[\"model\",\"modals/sub-route-modals/edit-create-notes/notes\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/customers/notes/edit-create-note/template.hbs"
    }
  });

  _exports.default = _default;
});