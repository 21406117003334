define("securitytrax-ember-ui/components/lists/data-list/body-group-header/component", ["exports", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['d-l-row', 'd-l-group-header', 'grid-x', 'align-justify'],
    classNameBindings: ['collapsable:collapsable'],

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        collapsable: false
      });
    },

    collapsable: undefined,

    click() {
      if (this.collapsable) {
        var group = this.group;
        var currentIconName = group.get('collapseIconName');
        group.set('collapsed', !group.get('collapsed'));
        group.set('collapseIconName', currentIconName === 'icn-chevron-down' ? 'icn-chevron-up' : 'icn-chevron-down');
      }

      return true;
    }

  });

  _exports.default = _default;
});