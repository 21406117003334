define("securitytrax-ember-ui/components/multi-step-form/switchable-inputs/time-input/component", ["exports", "securitytrax-ember-ui/mixins/switchable-input", "moment", "securitytrax-ember-ui/helpers/time-options"], function (_exports, _switchableInput, _moment, _timeOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableInput.default, {
    screen: Ember.inject.service(),
    defaultTime: '00:00',
    timePortion: undefined,
    datePortion: undefined,
    startTime: '08:00',
    endTime: '23:30',
    increments: 15,
    format12hour: false,
    renderInPlaceOverride: null,
    allowClear: true,

    init() {
      this._super(...arguments);

      if (this.value) {
        if (this.format12hour) {
          this.set('timePortion', (0, _moment.default)(this.value).format('h:mm a'));
        } else {
          this.set('timePortion', (0, _moment.default)(this.value).format('HH:mm'));
        }
      }
    },

    get filteredTimeOptions() {
      var options = (0, _timeOptions.timeOptions)([this.increments, this.startTime, this.endTime, 'HH:mm', 'h:mm a']);

      if (this.timePortion && !options.findBy('value', this.timePortion)) {
        options.unshift(Ember.Object.create({
          name: (0, _moment.default)(this.value).format('h:mm a'),
          value: this.timePortion
        }));
      }

      return options;
    },

    onChangeWithDefault: function () {
      var time = this.timePortion ? this.timePortion : this.defaultTime;
      var fullTime = this.datePortion ? this.datePortion + ' ' + time : time;

      if (this.datePortion) {
        this.set('value', (0, _moment.default)(fullTime, 'MM/DD/YYYY h:mm a'));
      } else {
        this.set('value', (0, _moment.default)(fullTime, 'h:mm a'));
      } // an additional onChange action was passed


      if (this.onChange) {
        return this.onChange.call(this, (0, _moment.default)(time));
      }

      if (this.changeAction) {
        this.changeAction(this.value);
      }
    },
    actions: {
      timeChange: function () {
        this.onChangeWithDefault();
      }
    }
  });

  _exports.default = _default;
});