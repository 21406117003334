define("securitytrax-ember-ui/components/multi-step-form/forms/system/adt/steps/clean-up/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/system/adt/adt-form-base", "ember-concurrency", "securitytrax-ember-ui/mixins/forms/adt", "lodash", "moment"], function (_exports, _adtFormBase, _emberConcurrency, _adt, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adtFormBase.default.extend(_adt.default, {
    centralStationService: Ember.inject.service(),
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    grants: Ember.inject.service(),
    adtStepReady: false,
    setupFormTask: (0, _emberConcurrency.task)(function* () {
      var centralStationService = this.centralStationService;
      this.set('adtData', yield this.adtData);
      var cleanUpData = yield centralStationService.getData({
        model: this.customer,
        method: 'adt__getCleanupData',
        responseKey: 'cleanup_data'
      });

      if (!cleanUpData.credit_holder_physical_address_data) {
        cleanUpData.credit_holder_physical_address_data = {};
      }

      if (!cleanUpData.ny) {
        cleanUpData.ny = {};
      }

      this.set('cleanUpData', cleanUpData);
      var multiStepForm = this.multiStepForm;
      yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);
      this.set('adtStepReady', true);
    }).on('init'),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('cleanUp', 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.saveAdtCleanUpInMegaForm.perform(multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn-primary'
      }]);
    },

    saveAdtCleanUpInMegaForm: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var hashData;
      this.set('adtStepReady', false);

      try {
        var grants = yield this.grants;
        hashData = yield (0, _emberConcurrency.hash)({
          customer: this.customer,
          adtCleanupModification: this.cleanUpData,
          adtData: this.adtData
        }); ///////////////////////////
        // validate adtData

        var formattedObjects = Ember.A();
        formattedObjects.pushObject(Ember.Object.create({
          notificationNamespace: 'adtMegaFormModal',
          model: hashData.adtCleanupModification,
          filePath: 'central-station/adt/adt-customer-order',
          extraValidationData: {
            adtValidation: true,
            noValidate: ['fname', 'lname', 'primaryPhone', 'city', 'location', 'saleDate', 'trackingPin', 'orderInformation-salesRep', 'orderInformation-customerCancelDate', 'houseAccountReason', 'creditTracking']
          }
        }));
        yield multiStepForm.validateList.perform(formattedObjects);
        var noValidate = ['fname', 'lname', 'primaryPhone', 'city', 'location', 'saleDate', 'trackingPin', 'orderInformation-salesRep', 'orderInformation-customerCancelDate', 'houseAccountReason', 'creditTracking'];

        if (multiStepForm.formCopiedModels.customer.model.accountType !== 'Commercial') {
          noValidate.push('businessName');
          noValidate.push('businessContact');
        }

        yield multiStepForm.validateSyncSaveAll({
          notificationNamespace: 'adtMegaFormModal',
          extraValidationData: {
            adtValidation: true,
            noValidate: noValidate
          },
          overrideOriginalModelsConfig: Ember.Object.create({
            customer: {
              doCopy: grants.getIndividualGrant('customers.view'),
              doValidate: grants.getIndividualGrant('customers.view'),
              doSave: grants.getIndividualGrant('customers.view'),
              copyRelationMap: {
                defaultCustomerBilling: {
                  doCopy: grants.getIndividualGrant('customer_billings.view'),
                  doValidate: false,
                  doSave: grants.getIndividualGrant('customer_billings.view'),
                  relationships: {
                    billCycle: {
                      readOnly: true
                    }
                  }
                },
                customerSystemInformation: {
                  doCopy: grants.getIndividualGrant('customer_system_information.view'),
                  doValidate: grants.getIndividualGrant('customer_system_information.view'),
                  doSave: grants.getIndividualGrant('customer_system_information.view')
                },
                customerBillings: {
                  readOnly: true,
                  doCopy: grants.getIndividualGrant('customer_billings.view'),
                  relationships: {
                    customerBillingUses: {
                      readOnly: true
                    }
                  }
                },
                customerOrderInformation: {
                  doCopy: grants.getIndividualGrant('customer_order_information.view'),
                  doValidate: grants.getIndividualGrant('customer_order_information.view'),
                  doSave: grants.getIndividualGrant('customer_order_information.view'),
                  relationships: {
                    contractTerm: {
                      readOnly: true
                    },
                    activationFee: {
                      readOnly: true
                    },
                    monitoringPlan: {
                      readOnly: true
                    },
                    mmr: {
                      readOnly: true
                    }
                  }
                }
              }
            }
          })
        });
      } catch (err) {
        this.set('adtStepReady', true);
        throw err;
      }

      try {
        var centralStationService = this.centralStationService;

        var estimated_completion_date = _lodash.default.get(hashData.adtCleanupModification, 'ny.estimated_completion_date', false);

        var messages = [];
        var estimated_completion_date_str = '';

        if (estimated_completion_date) {
          estimated_completion_date_str = (0, _moment.default)(estimated_completion_date).format('YYYY-MM-DD');
        }

        var response = yield centralStationService.postData({
          model: hashData.customer,
          method: 'adt__saveCleanupData',
          payload: {
            full_packet: hashData.adtCleanupModification.full_packet,
            tracking_pin: hashData.adtCleanupModification.tracking_pin,
            credit_holder_authentication_number: hashData.adtCleanupModification.credit_holder_authentication_number,
            overwrite_credit_holder_name: hashData.adtCleanupModification.overwrite_credit_holder_name,
            lead_source: hashData.adtCleanupModification.lead_source,
            affinity_member_number: hashData.adtCleanupModification.affinity_member_number,
            credit_holder_physical_address_data: {
              chpa_address_line_1: _lodash.default.get(hashData.adtCleanupModification, 'credit_holder_physical_address_data.chpa_address_line_1', ''),
              chpa_address_line_2: _lodash.default.get(hashData.adtCleanupModification, 'credit_holder_physical_address_data.chpa_address_line_2', ''),
              chpa_city: _lodash.default.get(hashData.adtCleanupModification, 'credit_holder_physical_address_data.chpa_city', ''),
              chpa_state: _lodash.default.get(hashData.adtCleanupModification, 'credit_holder_physical_address_data.chpa_state', ''),
              chpa_zip_five_or_nine: _lodash.default.get(hashData.adtCleanupModification, 'credit_holder_physical_address_data.chpa_zip_five_or_nine', '')
            },
            auto_draft_equipment_cost: hashData.adtCleanupModification.auto_draft_equipment_cost,
            equipment_panel: hashData.adtCleanupModification.equipment_panel,
            radio_primary: hashData.adtCleanupModification.radio_primary,
            radio_type: hashData.adtCleanupModification.radio_type,
            install_type: hashData.adtCleanupModification.install_type,
            sop_deposit_amount: hashData.adtCleanupModification.sop_deposit_amount,
            estimated_completion_date: hashData.adtCleanupModification.estimated_completion_date,
            add_on_services: hashData.adtCleanupModification.add_on_services,
            ny: {
              // all required
              estimated_completion_date: estimated_completion_date_str,
              // "string" (date field)
              deposit_account: _lodash.default.get(hashData.adtCleanupModification, 'ny.deposit_account', ''),
              // "string" (free form)
              down_payment: _lodash.default.get(hashData.adtCleanupModification, 'ny.down_payment', ''),
              // "string" (dollar amount)
              amount_due: _lodash.default.get(hashData.adtCleanupModification, 'ny.amount_due', '') // "string" (dollar amount)

            }
          }
        });
        messages = messages.concat(response.responsePayload.data.messages);
        yield this.refreshCustomerAdtStatus.perform(hashData.customer, hashData.adtData);
        buttonPromise.then(() => {
          if (this.isNewRecord) {
            this.stNotificationManager.addSuccess(this.intl.t('common.notifications.save_successful'));
            multiStepForm.formStartedSetVal(false);

            if (this.transitionBackToParent && typeof this.transitionBackToParent === 'function') {
              this.transitionBackToParent();
            }
          } else {
            this.stNotificationManager.addSuccess(this.intl.t('common.notifications.save_successful'), 'adtMegaFormModal');
            multiStepForm.setupCopyModels.perform();
          }

          if (messages.length > 0) {
            this.stNotificationManager.addWarningMessages(messages, {
              notificationNamespace: 'adtMegaFormModal'
            });
          }

          this.set('adtStepReady', true);
        });
      } catch (err) {
        this.set('adtStepReady', true);
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'adtMegaFormModal'
        });
        (false && Ember.warn('mega form save error for cleanup in' + err, {
          id: 'multiStepForm.forms.system.adt.cleanUp.save'
        }));
        throw err;
      }

      this.set('adtStepReady', true);
    })
  });

  _exports.default = _default;
});