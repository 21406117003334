define("securitytrax-ember-ui/customers/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-concurrency", "securitytrax-ember-ui/config/environment", "securitytrax-ember-ui/classes/edit-customer-dashboard"], function (_exports, _authenticatedRouteMixin, _emberConcurrency, _environment, _editCustomerDashboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    tagsService: Ember.inject.service(),
    recentSearchesService: Ember.inject.service(),
    router: Ember.inject.service(),
    quickAdd: Ember.inject.service(),
    intl: Ember.inject.service(),
    homeDashboard: Ember.inject.service('events/home-dashboard'),
    grants: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['customers.view']);
    },

    model({
      id
    }) {
      return this.setupModelTask.perform(id);
    },

    setupModelTask: (0, _emberConcurrency.task)(function* (id) {
      var store = this.store;
      var customer = yield store.findRecord('customer', id).catch(() => {
        // Should we notify user this request failed?
        this.transitionTo('customer-list');
      });
      var editDashboard = new _editCustomerDashboard.default(...arguments, {
        customer,
        intl: this.intl
      });
      var cardCount = 1;

      if (_environment.default.environment != 'test') {
        // Real land, issues with mirage
        var showAtRisk = yield customer.showRiskScoreTask.perform();
        cardCount = editDashboard.getAvailableGalleryCardsList(this.grants, null, showAtRisk).length;
      }

      if (customer.isLead) {
        var correspondingLeadsURL = this.get('router.currentURL').replace('/customer', 'lead').replace("/".concat(id), '');
        this.recentSearchesService.addRecent('recentLeads', id);

        if (Ember.getOwner(this).lookup("route:".concat(correspondingLeadsURL)) && correspondingLeadsURL !== 'leads') {
          this.transitionTo(correspondingLeadsURL.replace('/', '.'), id);
        } else {
          this.transitionTo('leads.information', id);
        }
      } else {
        this.recentSearchesService.addRecent('recentCustomers', id);
      }

      return Ember.RSVP.hash({
        customer: customer,
        tags: this.get('tagsService.getTagsByObjectType').perform('customer'),
        editDashboard: editDashboard,
        cardCount
      });
    })
  });

  _exports.default = _default;
});