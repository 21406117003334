define("securitytrax-ember-ui/models/base_models/admin-udf", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    name: (0, _model.attr)('string', {
      'readOnly': false
    }),
    objectType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['customer', 'user', 'equipment', 'tech_schedule', 'customer_contact', 'customer_equipment', 'location', 'lead_company']
    }),
    valueType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['input', 'date', 'checkbox', 'dropdown-list-method', 'dropdown-prelist', 'input-int', 'input-float']
    }),
    valueInfo: (0, _model.attr)('string', {
      'readOnly': false
    }),
    hideInUi: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    status: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['active', 'deleted']
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    udfSections: (0, _model.hasMany)('udf-section', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    resourceTypes: (0, _model.hasMany)('resource-type', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});