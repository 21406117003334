define("securitytrax-ember-ui/components/dashboard-config/gallery/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xR/SUBoe",
    "block": "{\"symbols\":[\"@roles\",\"@editDashboard\",\"@customer\",\"@ispConnected\",\"@showRiskScore\",\"@ispProvTypeId\"],\"statements\":[[10,\"div\"],[14,0,\"dashboard-config__gallery\"],[12],[2,\"\\n    \"],[8,\"dashboard-config/gallery/section\",[],[[\"@roles\",\"@editDashboard\",\"@customer\",\"@ispConnected\",\"@showRiskScore\",\"@ispProvTypeId\"],[[32,1],[32,2],[32,3],[32,4],[32,5],[32,6]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/dashboard-config/gallery/template.hbs"
    }
  });

  _exports.default = _default;
});