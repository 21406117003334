define("securitytrax-ember-ui/components/customer/dashboard/payables-summary-card/type-progress-bar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    total: false
  });

  _exports.default = _default;
});