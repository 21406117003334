define("securitytrax-ember-ui/helpers/warn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sum = sum;
  _exports.default = void 0;

  function sum(args) {
    console.warn.apply(console, args); //eslint-disable-line
  }

  var _default = Ember.Helper.helper(sum);

  _exports.default = _default;
});