define("securitytrax-ember-ui/components/pages/administration/company-settings/integrations/electronic-document-providers/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/table-column-sort", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _emberConcurrency, _tableColumnSort, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableColumnSort.default, {
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    stFiltersService: Ember.inject.service(),
    docusignEvent: Ember.inject.service('events/docusign-event'),
    tagName: '',
    edocProviderStatus: undefined,
    recordCount: 0,
    page: 1,
    limit: 50,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        stSortableColumnOptions: [{
          name: this.intl.t('common.columns.ascending') + ' ' + this.intl.t('common.columns.id'),
          value: 'id'
        }, {
          name: this.intl.t('common.columns.descending') + ' ' + this.intl.t('common.columns.id'),
          value: '-id'
        }, {
          name: this.intl.t('common.columns.ascending') + ' ' + this.intl.t('common.columns.name'),
          value: 'name'
        }, {
          name: this.intl.t('common.columns.descending') + ' ' + this.intl.t('common.columns.name'),
          value: '-name'
        }, {
          name: this.intl.t('common.columns.ascending') + ' ' + this.intl.t('common.columns.active'),
          value: 'active'
        }, {
          name: this.intl.t('common.columns.descending') + ' ' + this.intl.t('common.columns.active'),
          value: '-active'
        }]
      });
      this.sortPaginatedList('id', true, 'listLoaded');
      this.docusignEvent.on('refresh-list', this, '_eventCallback');
    },

    willDestroy() {
      this.docusignEvent.off('refresh-list', this, '_eventCallback');
    },

    _eventCallback() {
      Ember.notifyPropertyChange(this, 'eDocumentProviders');
    },

    isFullScreen: Ember.computed('eDocumentFormMode', 'grants.userGrants', function () {
      var hasGrant = this.grants.getIndividualGrant('electronic_document_provider_configs.manage');
      return this.eDocumentFormMode === 'edit' && hasGrant;
    }),
    eDocumentProviders: Ember.computed(function () {
      return this.getEdocProvidersTask.perform();
    }),
    getEdocProvidersTask: (0, _emberConcurrency.task)(function* () {
      var newFilters = yield this.stFiltersService.getFiltersByGroupKey('companyEdocProviders');
      var edocProviders = yield this.store.query('electronicDocumentProvider', {
        filter: newFilters,
        sort: this.stSortString ? this.stSortString : 'name',
        limit: this.limit,
        page: this.page,
        count: 1
      });
      Ember.set(this, 'recordCount', edocProviders.meta.record_count);
      return edocProviders;
    }),
    actions: {
      //action sent to sortable-column component
      sortList(column, ascending) {
        this.sortPaginatedList(column, ascending, 'listLoaded');
        Ember.notifyPropertyChange(this, 'eDocumentProviders');
      },

      mobileSortList(column) {
        this.sortMobilePaginatedList(column.value, 'listLoaded');
        Ember.notifyPropertyChange(this, 'eDocumentProviders');
      },

      transitionToSubroute(path) {
        this.router.transitionTo('administration.company-settings.integrations.electronic-document-providers.edit-create-electronic-document-provider', path);
      },

      filterSearch(setPage = true) {
        if (setPage) {
          Ember.set(this, 'page', 1);
        }

        Ember.notifyPropertyChange(this, 'eDocumentProviders');
      },

      clearFilters() {
        Ember.setProperties(this, {
          page: 1,
          edocProviderStatus: undefined
        });
        Ember.notifyPropertyChange(this, 'eDocumentProviders');
        Ember.notifyPropertyChange(this, 'page');
      }

    }
  });

  _exports.default = _default;
});