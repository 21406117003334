define("securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/component", ["exports", "ember-concurrency", "lodash"], function (_exports, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    isp: Ember.inject.service('events/isp'),
    ispService: Ember.inject.service(),
    saving: false,

    init() {
      this._super(...arguments);

      Ember.defineProperty(this, 'interactiveServicesProviderFormOriginalModel', Ember.computed('customerOrderInformation', this.store.getFullComputedWatcherStringByModelName('customerOrderInformation'), this.store.getFullComputedWatcherStringByModelName('customerSystemInformation', {
        attributesToIgnore: {
          'interactiveServicesId': true
        }
      }), function () {
        return Ember.Object.create({
          customerOrderInformation: {
            model: this.customerOrderInformation,
            copyRelationMap: {
              interactiveServicesProvider: {
                readOnly: true,
                relationships: {
                  interactiveServicesProviderType: {
                    readOnly: true
                  }
                }
              }
            }
          },
          customerSystemInformation: {
            model: this.customerSystemInformation
          }
        });
      }));
    },

    saveInteractiveServicesProviderInformationTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      // DREW TODO - note that this save can break because of resetForm being called based on the observers below
      // just noting this for reference
      // watchInteractiveServicesProviderName: observer
      // watchIspStatus: observer
      Ember.set(this, 'saving', true);
      var providerType = yield this.customerOrderInformation.interactiveServicesProvider.get('interactiveServicesProviderType.name');
      var integratedIsps = ['Alarm.com', 'Alula'];

      if (providerType && !integratedIsps.includes(providerType)) {
        var action = this.customerSystemInformation.interactiveServicesId ? '__linkCustomer' : '__unlinkCustomer';
        yield this.ispService.get('invokeIspActionTask').perform({
          model: this.customer,
          action: _lodash.default.snakeCase(providerType) + action,
          attributes: {
            interactive_services_id: this.customerSystemInformation.interactiveServicesId
          }
        });
      }

      yield multiStepForm.validateSyncSaveAllTask.unlinked().perform({
        extraValidationData: {
          noValidate: ['orderInformation-salesRep', 'orderInformation-customerCancelDate']
        }
      });
      buttonPromise.then(() => {
        this.isp.trigger('refresh-isp-connection');
        multiStepForm.formStartedSetVal(false);
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'global');
        Ember.set(this, 'saving', false);
      });
    }),
    interactiveServicesProviderFormStarted: true,
    interactiveServicesProviderFormFirstStep: 'details',
    interactiveServicesProviderFormSteps: Ember.computed('customerOrderInformation.interactiveServicesProvider.interactiveServicesProviderType.id', function () {
      var intl = this.intl;
      var interactiveServicesProviderTypeId = this.get('customerOrderInformation.interactiveServicesProvider.interactiveServicesProviderType.id');
      var componentPath = 'multi-step-form/forms/customer-interactive-services-provider-information/interactive-services-providers/interactive-services-provider-type-' + interactiveServicesProviderTypeId;

      if (!Ember.getOwner(this).hasRegistration('component:' + componentPath + '/component')) {
        componentPath = 'multi-step-form/forms/customer-interactive-services-provider-information/interactive-services-providers/interactive-services-provider-type-default';
      }

      if (this.toolbox) {
        componentPath += '/toolbox';
      }

      return Ember.Object.create({
        details: Ember.Object.create({
          stepNameKey: 'details',
          title: intl.t('common.columns.details'),
          stepComponentPath: componentPath
        })
      });
    })
  });

  _exports.default = _default;
});