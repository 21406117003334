define("securitytrax-ember-ui/components/calendar-glimmer/calendar-scheduling/schedulers/historical/component", ["exports", "@glimmer/component", "ember-concurrency", "moment", "securitytrax-ember-ui/mixed-in-functions/duration-options", "lodash", "securitytrax-ember-ui/mixins/forms/calendar-event-reusable"], function (_exports, _component, _emberConcurrency, _moment, _durationOptions, _lodash, _calendarEventReusable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var SchedulerHistorical = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service('events/calendar-events'), _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = (0, _emberConcurrency.task)(function* () {
    var outerHashedData = yield (0, _emberConcurrency.hash)({
      finishedCopyModels: (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true),
      appointmentTypeDefaults: this.sessionData.getCompanyDefaults('appointmentTypeDefault')
    });
    Ember.set(this, 'appointmentTypeDefaults', outerHashedData.appointmentTypeDefaults);
    yield this.calcCalendarEventTypesTask.perform();

    if (this.args.duplicateAppointment) {
      var hashedData = yield (0, _emberConcurrency.hash)({
        calendarEventType: this.args.duplicateAppointment.get('calendarEventType'),
        calendarEventDetailType: this.args.duplicateAppointment.get('calendarEventDetailType')
      });
      this.currDuration = this.args.duplicateAppointment.get('duration');
      this.currCalendarEventType = hashedData.calendarEventType;
      this.currCalendarEventDetailType = hashedData.calendarEventDetailType;
    }

    var buttons = [];
    var formMode = this.formMode;
    var grants = this.grants;
    var addButton = true;

    if (grants.getIndividualGrant('calendar_events.create')) {
      if (buttons.length > 0) {
        buttons.forEach(button => function () {
          if (button.id == 'schedule_button') {
            addButton = false;
          }
        });
      }

      if (addButton) {
        buttons.push({
          text: this.intl.t('common.actions.schedule'),
          click: () => {
            return this.openScheduleAppointmentModal();
          },
          id: 'schedule_button',
          classNamesString: "st-btn st-btn-primary save modal-action-submit save-work-order-btn ".concat(formMode === 'view' ? 'hidden' : '')
        });
      }
    }

    this.args.multiStepForm.registerStepData(this.args.multiStepForm.currentStepNameKey, 'buttons', buttons);
  }), _dec15 = Ember._action, _dec16 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this, 'currCalendarEventType', type => type != undefined);
    var calendarEventId = this.currCalendarEventType.id;
    var calendarEventDetailTypeOptions = [];

    if (this.args.formMode === 'create') {
      this.currCalendarEventDetailType = null;
    }

    var asyncData = yield (0, _emberConcurrency.hash)({
      selectedCalendarEventType: this.store.findRecord('calendarEventType', calendarEventId),
      company: this.sessionData.getCompany()
    });
    var asyncData2 = yield (0, _emberConcurrency.hash)({
      listOfCalendarEventDetailTypes: asyncData.selectedCalendarEventType.get('calendarEventDetailTypes'),
      calendarEventDetailTypeDefaults: asyncData.company.get('calendarEventDetailTypeDefaults')
    });
    calendarEventDetailTypeOptions = asyncData2.listOfCalendarEventDetailTypes;
    var defaultCalendarEventDetailTypeCamelKey = asyncData.selectedCalendarEventType.get('nameAsKeyCamel') + 'SubtypeId';
    var defaultCalendarEventDetailTypeId = asyncData2.calendarEventDetailTypeDefaults[defaultCalendarEventDetailTypeCamelKey];

    if (!this.args.duplicateAppointment && defaultCalendarEventDetailTypeId) {
      var defaultCalendarEventDetailType = yield this.store.findRecord('calendarEventDetailType', defaultCalendarEventDetailTypeId);
      this.currCalendarEventDetailType = defaultCalendarEventDetailType; // also set the duration if the chosen subtype has a default duration

      if (this.currCalendarEventDetailType.duration) {
        this.currDuration = this.currCalendarEventDetailType.duration;
      }
    }

    return calendarEventDetailTypeOptions;
  }), _dec17 = (0, _emberConcurrency.task)(function* () {
    var calendarEventTypes = yield this.store.findAll('calendar-event-type');
    var filteredCalendarEventTypes = [];
    var calendarEventTypeIncludeCategories = this.calendarEventTypeIncludeCategories;
    calendarEventTypes.forEach(calendarEventType => {
      if (calendarEventType.get('status') === 'active') {
        if (calendarEventTypeIncludeCategories[calendarEventType.get('calendarCategory')]) {
          if (calendarEventType.nameAsKey != 'timeoff') {
            filteredCalendarEventTypes.push(calendarEventType);
          }
        }
      }
    });
    var sortedCalendarEventTypes = yield filteredCalendarEventTypes.sortBy('name');

    if (this.args.duplicateAppointment) {
      this.currCalendarEventType = this.args.duplicateAppointment.get('calendarEventType');
    } else {
      // set the default currCalendarEventType to the first filteredCalendarEventTypes
      this.setCalendarEventTypeAndCalcDefaultDuration(sortedCalendarEventTypes[0]); // set the currCalendarEventType from the workOrderType if the names match
      // DREW TODO - this will need to be updated when / if there is a change made to the form defaults to take that into account

      if (this.args.multiStepForm.formOriginalModels.workOrder && this.args.multiStepForm.formOriginalModels.workOrder.model.id) {
        var workOrderType = yield this.args.multiStepForm.formOriginalModels.workOrder.model.workOrderType;
        var foundType = yield filteredCalendarEventTypes.findBy('name', workOrderType.name);

        if (foundType && foundType.name) {
          this.setCalendarEventTypeAndCalcDefaultDuration(foundType);
        }
      }
    }

    this.calendarEventTypes = sortedCalendarEventTypes;
  }), _dec18 = (0, _emberConcurrency.task)(function* () {
    var selectedSchedulingProfile = yield this.args.selectedSchedulingProfile;

    try {
      var hashData = yield (0, _emberConcurrency.hash)({
        customerId: this.args.customer.get('id'),
        calendarDate: this.calendarDate,
        currDuration: this.currDuration
      });

      if (selectedSchedulingProfile) {
        var profileUsers = yield selectedSchedulingProfile.invokeAction('get_scheduler_availability', {
          performLinked: true,
          verb: 'POST',
          processResponse: true,
          data: {
            data: {
              relationships: {
                customer: {
                  data: {
                    id: hashData.customerId,
                    type: 'customers'
                  }
                }
              },
              attributes: {
                users_data: {
                  filter_date: hashData.calendarDate.format('MM/DD/YYYY'),
                  duration: hashData.currDuration,
                  calendar_category: this.args.userType === 'tech' ? 'tech_schedule' : 'sale_schedule',
                  user_list_by: 'all'
                }
              }
            }
          }
        });

        var theNewUsers = _lodash.default.sortBy(profileUsers.models, [function (user) {
          return user.lname.toLowerCase();
        }]);
        /*  if (theNewUsers.length != this.newUsers.length) {
         } */


        this.CalendarEventsService.trigger('updateUserList');
        return theNewUsers; //lodash sort  A-Za-z
      } else {
        throw new Error(this.intl.t('pages.calendar.no_slot_schedulers'));
      }
    } catch (error) {
      (false && Ember.warn('failed to get users for calendar' + error, {
        id: 'calendar.calendarScheduling.schedulers.users.usersTask'
      }));
      this.stNotificationManager.addErrorMessages(error, {
        notificationNamespace: 'work-order-edit-appointment'
      });
    }

    return [];
  }), _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, (_class = (_temp = class SchedulerHistorical extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "grants", _descriptor2, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);

      _initializerDefineProperty(this, "sessionData", _descriptor5, this);

      _initializerDefineProperty(this, "CalendarEventsService", _descriptor6, this);

      _initializerDefineProperty(this, "AppointmentTimeService", _descriptor7, this);

      _initializerDefineProperty(this, "currDuration", _descriptor8, this);

      _initializerDefineProperty(this, "currUserOption", _descriptor9, this);

      _initializerDefineProperty(this, "currShowOption", _descriptor10, this);

      _initializerDefineProperty(this, "currCalendarEventType", _descriptor11, this);

      _initializerDefineProperty(this, "currCalendarEventDetailType", _descriptor12, this);

      _initializerDefineProperty(this, "appointmentTypeDefaults", _descriptor13, this);

      _defineProperty(this, "mainHeaderOffset", 0);

      _defineProperty(this, "calendarDate", undefined);

      _defineProperty(this, "searchCalendarDate", undefined);

      _defineProperty(this, "showCalendar", undefined);

      _defineProperty(this, "calendarEventTypes", undefined);

      _defineProperty(this, "baseGridMillis", _moment.default.duration(30, 'minutes').asMilliseconds());

      _defineProperty(this, "calendarEventTypeIncludeCategories", undefined);

      _initializerDefineProperty(this, "initAppointmentDataTask", _descriptor14, this);

      _initializerDefineProperty(this, "setupCalendarEventDetailTypeOptions", _descriptor15, this);

      _initializerDefineProperty(this, "calcCalendarEventTypesTask", _descriptor16, this);

      _initializerDefineProperty(this, "newUsersTask", _descriptor17, this);

      this.calendarDate = (0, _moment.default)();
      this.searchCalendarDate = (0, _moment.default)();
      this.showCalendar = false;
      this.currDuration = 7200;
      this.calendarEventTypeIncludeCategories = {
        'tech_schedule': this.args.userType === 'tech',
        'sale_schedule': this.args.userType === 'sales_rep'
      };
      this.initAppointmentDataTask.perform();
    }

    get eventTypeIsDisabled() {
      if (this.args.userType === 'sales_rep') {
        return true;
      }

      return false;
    }

    get durationOptions() {
      return (0, _durationOptions.default)(this.AppointmentTimeService.startTime);
    }

    openScheduleAppointmentModal() {
      var calendarEventReusable = Ember.Object.extend(_calendarEventReusable.default); //MATT CAL TODO Change from mixin

      var newCalendarEvent = calendarEventReusable.create({
        calendarCategory: this.args.userType === 'sales_rep' ? 'sale_schedule' : 'tech_schedule',
        duration: this.currDuration,
        status: 'confirmed',
        calendarUser: this.currUserOption,
        isPendingAdd: true,
        calendarEventDetailType: this.currCalendarEventDetailType,
        calendarEventType: this.currCalendarEventType
      });
      this.args.onAddNewCalendarEvent(newCalendarEvent);
    }

    cancelScheduleAppointment() {
      if (this.args.calendarPage.pendingAddCalendarEvent) {
        this.args.calendarPage.removePendingCalendarEvent();
      }
    }

    get calendarEventDetailTypeOptions() {
      return this.setupCalendarEventDetailTypeOptions.perform();
    }

    get newUsers() {
      return this.newUsersTask.perform();
    }

    setCalendarEventTypeAndCalcDefaultDuration(calendarEventType) {
      var newDuration = Number(this.appointmentTypeDefaults.get(calendarEventType.get('name').toLowerCase() + 'AppointmentDuration'));
      this.currCalendarEventType = calendarEventType;

      if (newDuration) {
        this.currDuration = newDuration;
      }
    }

    updateUserList() {
      Ember.notifyPropertyChange(this, 'newUsers');
    }

    changedCalendarEventDetailType(newType) {
      if (newType && newType.duration) {
        this.currDuration = newType.duration;
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "CalendarEventsService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "AppointmentTimeService", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "currDuration", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "currUserOption", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "currShowOption", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'all';
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "currCalendarEventType", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "currCalendarEventDetailType", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "appointmentTypeDefaults", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "initAppointmentDataTask", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "cancelScheduleAppointment", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "cancelScheduleAppointment"), _class.prototype), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "setupCalendarEventDetailTypeOptions", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "calcCalendarEventTypesTask", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "newUsersTask", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setCalendarEventTypeAndCalcDefaultDuration", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "setCalendarEventTypeAndCalcDefaultDuration"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateUserList", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "updateUserList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changedCalendarEventDetailType", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "changedCalendarEventDetailType"), _class.prototype)), _class));
  _exports.default = SchedulerHistorical;
});