define("securitytrax-ember-ui/customers/notes/route", ["exports", "securitytrax-ember-ui/customers/base-route", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _baseRoute, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // PERF TODO - performance done on this route 8/11/22

  /**
   * @classdesc
   * Customer notes route.
   *
   * @class Route
   * @extends customers.BaseRoute
   * @memberof customers.notes
   */
  var _default = _baseRoute.default.extend(_routePageviewLogging.default, {
    userService: Ember.inject.service(),
    grants: Ember.inject.service(),
    store: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      var customerId = this.paramsFor('customers').id;
      return this.findGrantsTask.perform(['customer_tasks.view', 'customer_notes.view'], 'one', '/customers/' + customerId + '/view');
    },

    model: function () {
      var {
        customer
      } = this._super(...arguments);

      return Ember.RSVP.hash({
        customer
      });
    }
  });

  _exports.default = _default;
});