define("securitytrax-ember-ui/classes/misc-input-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var MiscInputList = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = (_temp = class MiscInputList {
    constructor(args) {
      _initializerDefineProperty(this, "miscInputs", _descriptor, this);

      Ember.setProperties(this, args);
    }

    checkboxOptions() {
      return [{
        value: 'true',
        option: this.intl.t('common.status.on')
      }, {
        value: 'false',
        option: this.intl.t('common.status.off')
      }];
    }

    miscFilterOperators() {
      var intl = this.intl;
      return [{
        option: intl.t('common.filters.equals'),
        value: '='
      }, {
        option: intl.t('common.filters.starts_with'),
        value: '~%'
      }, {
        option: intl.t('common.filters.contains'),
        value: '%~%'
      }];
    }

    addMiscInput() {
      var lastMiscFilter = this.miscInputs[this.miscInputs.length - 1];

      if (!lastMiscFilter || lastMiscFilter.operand || lastMiscFilter.operator || lastMiscFilter.value) {
        this.miscInputs.pushObject({
          operand: undefined,
          operator: undefined,
          value: undefined
        });
      }
    }

    clearMiscInput() {
      this.miscInputs = this.miscInputs.filter((miscFilter, index) => {
        if (index === this.miscInputs.length - 1) {
          return true;
        }

        return miscFilter.operand != undefined || miscFilter.operator != undefined || miscFilter.value != undefined;
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "miscInputs", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "addMiscInput", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "addMiscInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearMiscInput", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "clearMiscInput"), _class.prototype)), _class));
  _exports.default = MiscInputList;
});