define("securitytrax-ember-ui/components/pages/my-inventory/transfers/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    tagName: '',
    outboundTransferDeleteId: false,
    equipmentTransfer: null,
    formStarted: false,
    formMode: 'edit',
    equipment: Ember.computed(function () {
      return this.store.query('equipment', {
        limit: 0,
        filter: 'active=true'
      });
    }),
    formSteps: Ember.computed('formMode', 'equipmentTransfer', function () {
      var intl = this.intl;
      var translationKey = this.formMode === 'create' ? 'pages.users.my_inventory.transfers.create_outbound_transfer_title' : 'pages.administration.company_settings.inventory.equipment_transfer.edit_title';
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: intl.t(translationKey, {
            id: this.get('equipmentTransfer.id'),
            source: this.get('equipmentTransfer.source.flname') || this.get('equipmentTransfer.source.flname'),
            target: this.get('equipmentTransfer.target.flname') || this.get('equipmentTransfer.target.flname')
          }),
          stepComponentPath: 'multi-step-form/forms/administration/' + (this.formMode === 'create' ? 'users/my-inventory/transfers/create-outbound-transfer' : 'inventory/equipment-transfer/edit-transfer')
        })
      });
    }),
    formOriginalModels: Ember.computed('equipmentTransfer', function () {
      return Ember.Object.create({
        adminEquipmentTransfer: {
          model: this.equipmentTransfer
        }
      });
    }),
    actions: {
      deleteOutboundTransfer(row) {
        var transferToDelete = this.store.findRecord('equipmentTransfer', row.get('id'));
        transferToDelete.then(theTransfer => {
          theTransfer.destroyRecordWithRollbackTask.perform().then(() => {
            this.set('outboundTransferDeleteId', false);
          });
        });
      },

      refreshModel() {
        this.get('model.user.sourceEquipmentTransfers').reload();
        this.get('model.user.targetEquipmentTransfers').reload();
      },

      editAction(row) {
        if (this.grants.getIndividualGrant('equipment_transfers.modify')) {
          this.set('formMode', 'edit');
        }

        this.set('equipmentTransfer', row);
        this.set('formStarted', true);
      }

    }
  });

  _exports.default = _default;
});