define("securitytrax-ember-ui/components/html/filter-grouping-div/component", ["exports", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    stFiltersService: Ember.inject.service(),
    classNames: ['filter-grouping-div'],
    classNameBindings: ['isOpen:is-open:is-closed', 'fullWidthWithSortingButton:full-width:mobile'],
    isOpen: undefined,
    appliedFiltersCount: undefined,
    filterGroupResetable: undefined,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        isOpen: false,
        appliedFiltersCount: 0,
        filterGroupResetable: true
      });

      if (typeof this.clearFilters !== 'function') {
        throw new Error('You must pass in clearFilters to filter grouping and it must be a function');
      }
    },

    filtersAppliedText: Ember.computed('appliedFiltersCount', function () {
      return this.intl.t('common.filters.appliedTitle', {
        num: this.appliedFiltersCount
      });
    }),
    chevronIcon: Ember.computed('isOpen', function () {
      return this.isOpen ? 'icn-chevron-up' : 'icn-chevron-down';
    }),
    filterIcon: Ember.computed('isOpen', 'appliedFiltersCount', function () {
      return this.isOpen || this.appliedFiltersCount > 0 ? 'icn-filter-active' : 'icn-filter';
    }),
    actions: {
      toggleOpen() {
        this.toggleProperty('isOpen');

        if (this.onOpen) {
          this.onOpen(this.isOpen);
        }
      },

      resetFilters() {
        this.clearFilters();
      }

    }
  });

  _exports.default = _default;
});