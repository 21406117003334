define("securitytrax-ember-ui/components/pages/administration/inventory/on-hand/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/grouped-equipment", "lodash", "ember-inflector"], function (_exports, _emberConcurrency, _groupedEquipment, _lodash, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_groupedEquipment.default, {
    inventoryService: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    grants: Ember.inject.service(),
    tagName: '',
    groupBy: 'recipient',
    recipient: null,
    equipmentItem: null,
    totalOnHand: 0,

    init() {
      this._super(...arguments);
    },

    recipients: Ember.computed(function () {
      return this.inventoryService.get('getInventoryRecipients').perform();
    }),
    equipment: Ember.computed(function () {
      return this.store.query('equipment', {
        include: 'equipment_type,equipment_manufacturer',
        limit: 0,
        filter: 'active=true'
      });
    }),
    equipmentOnHand: Ember.computed('equipmentItem', function () {
      return this.equipmentOnHandTask.perform();
    }),
    showOnHand: Ember.computed('equipmentItem', 'recipient', function () {
      return this.equipmentItem || this.recipient;
    }),
    equipmentItemDescription: Ember.computed('equipmentItem', function () {
      if (this.equipmentItem) {
        return Ember.A([Ember.Object.create({
          packageName: this.intl.t('common.forms.descriptive_title_view', {
            name: this.equipmentItem.get('name'),
            id: this.equipmentItem.get('id')
          }),
          packageId: this.equipmentItem.get('id'),
          packageContent: [{
            label: this.intl.t('pages.administration.company_settings.equipment.equipment.manufacturer'),
            description: this.equipmentItem.get('equipmentManufacturer.name')
          }, {
            label: this.intl.t('pages.administration.company_settings.equipment.equipment.model'),
            description: this.equipmentItem.get('model')
          }]
        })]);
      }

      return null;
    }),
    equipmentOnHandTask: (0, _emberConcurrency.task)(function* () {
      var onHand = [];
      var totalOnHand = 0;
      var company = yield this.sessionData.getCompany();
      var inventoryList = this.equipmentItem ? yield company.invokeAction('get_inventory_recipients_with_on_hand_equipment', {
        verb: 'POST',
        data: {
          data: {
            equipment_ids: [this.equipmentItem.get('id')]
          }
        }
      }) : null;

      if (inventoryList) {
        inventoryList.responsePayload.data.forEach(function (value) {
          var inventoryItem = Ember.Object.create({
            'type': (0, _emberInflector.singularize)(value.type),
            'id': value.id,
            'name': value.name + ' (' + value.type.slice(0, -1).capitalize() + ')',
            'onHand': value.inventory[0].on_hand,
            //API can be multiple, UI expects 1.
            'sort': value.type === 'users' ? 0 : 1
          });

          if (inventoryItem.get('onHand') != 0) {
            onHand.push(inventoryItem);
          }
        });
      }

      onHand.forEach(item => {
        totalOnHand += Number(item.onHand);
      });
      this.set('totalOnHand', totalOnHand);
      return _lodash.default.sortBy(onHand, ['sort', 'name']);
    }),
    items: Ember.computed('recipient.id', function () {
      return this.recipientOnHandTask.perform();
    }),
    recipientOnHandTask: (0, _emberConcurrency.task)(function* () {
      var equipmentList = yield this.equipment;
      var inventoryList = this.recipient ? yield this.recipient.invokeAction('get_admin_equipment_inventory', {
        verb: 'GET'
      }) : null;
      var onHand = [];
      var totalOnHand = 0;

      if (inventoryList) {
        inventoryList.responsePayload.data.forEach(function (value) {
          var equipmentItem = equipmentList.find(function (element) {
            if (element.id === value.equipment_id) {
              return element;
            }
          });
          var inventoryItem = Ember.Object.create({
            'equipment': equipmentItem,
            'onHand': value.on_hand_quantity
          });

          if (inventoryItem.get('onHand') > 0) {
            onHand.push(inventoryItem);
          }
        });
      }

      onHand.forEach(item => {
        totalOnHand += Number(item.onHand);
      });
      this.set('totalOnHand', totalOnHand);
      return onHand;
    })
  });

  _exports.default = _default;
});