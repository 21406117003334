define("securitytrax-ember-ui/administration/company-settings/integrations/partner-companies/leads/route", ["exports", "securitytrax-ember-ui/administration/company-settings/integrations/partner-companies/base-route", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _baseRoute, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_routePageviewLogging.default, {
    tagsService: Ember.inject.service(),
    leadStatuses: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform();
    },

    model() {
      var {
        leadCompany
      } = this._super(...arguments);

      return Ember.RSVP.hash({
        leadCompany: leadCompany,
        tags: this.get('tagsService.getTagsByObjectType').perform('lead_company'),
        identityDeliveryTypes: this.store.findAll('identityDeliveryType'),
        leadStatuses: this.get('leadStatuses.getLeadStatusOptionsTask').perform(['1']),
        messages: this.store.findAll('message')
      });
    }

  });

  _exports.default = _default;
});