define("securitytrax-ember-ui/oauth/callback/route", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform();
    }

  });

  _exports.default = _default;
});