define("securitytrax-ember-ui/validations/central-station/cms/cms-dispatch-instructions", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validationObject = {
        instructions: {
          presence: this.customMessageOrDefault(data, 'instructions')
        },
        expire_date: {
          presence: () => {
            if (this.is_permanent) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'expireDate');
            }
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validationObject, {
        fullMessages: this.prependProperty(data)
      });
      var modelName = data.extraValidationData && data.extraValidationData.hasOwnProperty('overrideValidationKey') ? data.extraValidationData.overrideValidationKey : 'cmsInstructions';
      return this.outputFormatting(validationData, modelName);
    }

  });

  _exports.default = _default;
});