define("securitytrax-ember-ui/components/multi-step-form/switchable-inputs/misc-input/component", ["exports", "securitytrax-ember-ui/mixins/switchable-input", "ember-concurrency", "uuid", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _switchableInput, _emberConcurrency, _uuid, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableInput.default, {
    intl: Ember.inject.service(),
    stFiltersService: Ember.inject.service(),
    horizontalPosition: 'auto',
    verticalPosition: 'auto',
    hideCloseButton: false,
    operandLabelProperty: 'option',
    operandValueProperty: 'value',
    operatorLabelProperty: 'option',
    operatorValueProperty: 'value',
    selectedOperand: null,
    selectedOperator: null,
    hasValueInput: true,
    valueInitiallyUpdated: false,
    needsOperand: true,
    miscInputUuid: undefined,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        miscInputUuid: (0, _uuid.v4)()
      });
      this.set('selectedOperand', this.operands ? this.operands.findBy('value', this.operand) : null);
      this.set('selectedOperator', this.operators.findBy('value', this.operator));

      if (this.isFilter) {
        this.stFiltersService.registerFilter(this.stFilterGroupKey, this.stFilterKey, ['miscFilterValue']); // DREW TODO _ check the misc inputs for set loaded

        Ember.run.later(() => {
          this.stFiltersService.setAsLoaded(this.stFilterGroupKey, this.stFilterKey);
        });
      }
    },

    miscInputUniqueClass: Ember.computed('miscInputUuid', function () {
      return "misc_input_class_".concat(this.miscInputUuid);
    }),

    updateFilterData() {
      if (this.isFilter) {
        if (this.value != null && this.operator != null) {
          var actualFilter = '';
          var operator = this.operator.includes('~') ? '~' : '=';
          var value = this.operator === '~%' ? '\'' + this.value + '%\'' : this.operator === '%~%' ? '\'%' + this.value + '%\'' : '\'' + this.value + '\'';

          if (this.operand != null) {
            actualFilter = this.operand + operator + value;
          } else {
            actualFilter = operator + value;
          }

          var actualFilters = this.stFiltersService.getActualFilterOrUseCallback(this.stFilterAttribute, this.miscFilter, actualFilter);
          this.stFiltersService.saveFilter(this.stFilterGroupKey, this.stFilterKey, 'miscFilterValue', actualFilters, this.miscFilter, this.stFilterDoNotCache, this.stFilterOperatorAttribute);
        } else if (this.operand && (this.fromDate || this.toDate)) {
          var _actualFilters = this.stFiltersService.getActualFilterOrUseCallback(this.stFilterAttribute, this.miscFilter);

          this.stFiltersService.saveFilter(this.stFilterGroupKey, this.stFilterKey, 'miscFilterValue', _actualFilters, this.miscFilter, this.stFilterDoNotCache, this.stFilterOperandAttribute);
        } else if (!this.hasValueInput && this.operator != null && this.operand != null) {
          var _actualFilters2 = this.stFiltersService.getActualFilterOrUseCallback(this.stFilterAttribute, this.miscFilter);

          this.stFiltersService.saveFilter(this.stFilterGroupKey, this.stFilterKey, 'miscFilterValue', _actualFilters2, this.miscFilter, this.stFilterDoNotCache, this.stFilterValueAttribute);
        } else {
          this.stFiltersService.clearFilter(this.stFilterGroupKey, this.stFilterKey, 'miscFilterValue');
        }
      }
    },

    fullyChangedObserver: Ember.observer('operand', 'operator', 'value', 'toDate', 'fromDate', function () {
      this.onChangeTask.perform();
    }),

    hasData() {
      var operandCheck = this.operand || !this.needsOperand;
      return this.hasValueInput && operandCheck && this.operator && this.value || !this.hasValueInput && operandCheck && this.operator || operandCheck && (this.fromDate || this.toDate);
    },

    onChangeTask: (0, _emberConcurrency.task)(function* () {
      if (this.hasData()) {
        if (this.value || this.toDate || this.waitToUpdate) {
          var _this$waitTime;

          yield (0, _emberConcurrency.timeout)((_this$waitTime = this.waitTime) !== null && _this$waitTime !== void 0 ? _this$waitTime : 1500); //make sure they are done typing before registering onChange
        }

        if (typeof this.onChange === 'function') {
          this.onChange();
        }
      } else if (!this.selectedText) {
        this.send('clearInput');
      }
    }).restartable().maxConcurrency(1),
    selectedText: Ember.computed('operand', 'operator', 'operators', 'fromDate', 'toDate', 'value', function () {
      if (this.secondSelectType === 'date') {
        if (this.fromDate || this.toDate) {
          return (this.fromDate ? moment(this.fromDate, ['MM/DD/YYYY', 'YYYY-MM-DD']).format('MM/DD/YYYY') : '') + ' - ' + (this.toDate ? moment(this.toDate, ['MM/DD/YYYY', 'YYYY-MM-DD']).format('MM/DD/YYYY') : '');
        } else {
          return 'mm/dd/yyyy - mm/dd/yyyy';
        }
      }

      var operand = this.operand;
      var operator = this.operator && this.operators.findBy(this.operatorValueProperty, this.operator) ? this.operator : null;
      var value = this.value;
      var valueText = this.hasValueInput ? value || '' : '';
      var operatorText = Array.isArray(operator) && operator.length > 0 ? this.intl.t('pages.reports.multiple') : operator ? this.operators.findBy(this.operatorValueProperty, operator)[this.operatorLabelProperty] + (this.hasValueInput && value ? ' > ' : '') : '';
      var operandText = Array.isArray(operand) && operand.length > 0 ? this.intl.t('pages.reports.multiple') : operand ? this.operands.findBy(this.operandValueProperty, operand)[this.operandLabelProperty] + (operator ? ' > ' : '') : '';
      var text = operandText + operatorText + valueText;
      return text;
    }),
    actions: {
      focusOut(e) {
        e.stopPropagation();
        this.updateFilterData();
        return false;
      },

      clearInput(e) {
        if (e) {
          e.stopPropagation();
        }

        Ember.setProperties(this, {
          operand: null,
          operator: null,
          value: null,
          fromDate: null,
          toDate: null
        });
        this.updateFilterData();

        if (this.clearAction && typeof this.clearAction === 'function') {
          this.clearAction();
        }

        return false;
      },

      closeDropdown() {
        if (this.secondSelectType === 'date' && this.calendarOpen) {
          return false;
        }
      },

      setCalendarStatus(status) {
        this.calendarOpen = status;
      },

      selectsChanged() {
        if (this.operand == undefined) {
          this.set('selectedOperand', null);
        }

        if (this.operator == undefined) {
          this.set('selectedOperator', null);
        }

        if (this.hasData() && typeof this.onChange === 'function') {
          this.onChange();
        }
      }

    }
  });

  _exports.default = _default;
});