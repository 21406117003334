define("securitytrax-ember-ui/demo/multi-step-form-create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller.extend({
    store: Ember.inject.service(),
    showDeleteId: false,
    actions: {
      transitionToSubroute(id) {
        if (id && id > 0) {
          this.transitionToRoute('demo.multi-step-form-create.edit-create', id);
        } else {
          this.transitionToRoute('demo.multi-step-form-create.edit-create', 'create');
        }
      },

      deleteContact(row) {
        var contactToDelete = this.store.findRecord('customerContact', row.get('id'));
        contactToDelete.then(theContact => {
          theContact.destroyRecordWithRollbackTask.perform().then(() => {
            this.set('showDeleteId', false);
          });
        });
      }

    }
  });
  var _default = Controller;
  _exports.default = _default;
});