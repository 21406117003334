define("securitytrax-ember-ui/components/modals/bold-add-account-authority/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    centralStationService: Ember.inject.service(),
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    type: null,
    searchField: null,
    authorities: null,
    searchLoaded: true,
    searchAttempted: false,
    addAuthorityConfirmModalOpen: false,
    typeOptions: Ember.computed(function () {
      var typeOptions = ['Fire', 'Medical', 'Police'];
      this.currentContacts.forEach(contact => {
        var matchIndex = typeOptions.indexOf(contact.subtype_description);

        if (matchIndex !== -1) {
          typeOptions.splice(matchIndex, 1);
        }
      });
      return typeOptions.map(option => {
        return {
          id: option,
          name: option
        };
      });
    }),
    searchForAuthorities: (0, _emberConcurrency.task)(function* () {
      var formattedObjects = [];
      formattedObjects.pushObject(Ember.Object.create({
        model: {
          type: this.type,
          searchField: this.searchField
        },
        extraValidationData: {
          noValidate: ['authority-zip']
        },
        filePath: 'central-station/add-authority'
      }));
      yield this.multiStepForm.validateList.perform(formattedObjects);

      try {
        this.set('searchLoaded', false);
        var authorities = yield this.centralStationService.getData({
          model: this.customer,
          method: 'bold__authoritySearch',
          payload: {
            authority_type: this.type,
            search_string: this.searchField
          }
        });
        this.set('searchLoaded', true);
        authorities.authorities.forEach(testing => {
          testing.selected = false;
        });
        this.set('authorities', authorities.authorities);
        this.set('searchAttempted', true);
        return authorities.authorities;
      } catch (err) {
        this.set('searchLoaded', true);
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'addAuthorityModal'
        });
        throw err;
      }
    }),
    addAuthorityButton: Ember.computed(function () {
      return [{
        text: this.intl.t('common.actions.save'),
        click: () => {
          if (this.authorities) {
            var authority = this.authorities.find(authorityOption => {
              return authorityOption.selected === true;
            });

            if (authority) {
              this.set('addAuthorityConfirmModalOpen', true);
            }
          }
        },
        classNamesString: 'st-btn st-btn-primary'
      }];
    }),
    addAuthority: (0, _emberConcurrency.task)(function* (buttonPromise) {
      var authority = this.authorities.find(authorityOption => {
        return authorityOption.selected === true;
      });

      try {
        yield this.centralStationService.postData({
          model: this.customer,
          method: 'bold__addAuthorityToCustomer',
          payload: {
            authority_type: this.type,
            serial_no: authority.serial_no
          }
        });
        buttonPromise.then(() => {
          this.refreshAuthorities();
          this.set('dialogOpen', false);
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
          Ember.setProperties(this, {
            searchField: null,
            type: null,
            authorities: null,
            searchAttempted: false
          });
          this.customer.hasMany('agencyContacts').reload();
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'addAuthorityModal'
        });
        throw err;
      }
    }),
    actions: {
      toggleAuthorities(row) {
        this.authorities.forEach(authority => {
          if (authority.serial_no !== row.serial_no) {
            Ember.set(authority, 'selected', false);
          }
        });
      }

    }
  });

  _exports.default = _default;
});