define("securitytrax-ember-ui/components/multi-step-form/forms/administration/inventory/spillage/edit-spillage/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/list/row-list", "securitytrax-ember-ui/mixins/switchable-form-step", "moment"], function (_exports, _emberConcurrency, _rowList, _switchableFormStep, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, _rowList.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    inventoryService: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    sessionData: Ember.inject.service('sessionData'),
    equipment: null,
    spillageEquipmentLineItemDeleteId: false,
    relatedDocuments: null,
    equipmentReturnReason: null,
    recipient: null,
    dataLoaded: false,
    lineItemsToCreate: null,
    formStarted: false,
    recipientSelected: false,
    completedStatuses: null,
    totalItemCount: 0,
    confirmLockModalOpen: false,
    formSteps: Ember.computed('formMode', 'multiStepForm.formCopiedModels.equipmentPurchaseOrder.model.equipmentVendor.id', 'recipient.id', function () {
      var intl = this.intl;
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: intl.t("pages.administration.company_settings.inventory.spillage.review_title", {
            recipient: this.get('recipient.fname') ? this.get('recipient.fname') + ' ' + this.get('recipient.lname') : this.get('recipient.name')
          }),
          stepComponentPath: 'multi-step-form/forms/administration/users/my-inventory/spillage/review'
        })
      });
    }),
    getLineItemCount: (0, _emberConcurrency.task)(function* () {
      var lineItems = yield this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model.pendingEquipmentLineItems');
      var count = 0;
      lineItems.forEach(item => {
        if (item.quantity) {
          count += parseInt(item.quantity);
        }
      });
      this.set('totalItemCount', count);
    }),
    initFormData: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = this.multiStepForm;
      yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);
      var status = this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model.status');

      if (status === 'open') {
        //BOWLESTODO: edit spillage files just need to be combined
        var hashedData = yield (0, _emberConcurrency.hash)({
          lineItems: this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model.pendingEquipmentLineItems'),
          equipmentReturnReason: this.store.findAll('equipmentReturnReason'),
          createdByUser: this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model.createdByUser'),
          getLineItemCount: this.getLineItemCount.perform(),
          user: this.spillage.get('user.id') ? this.store.findRecord('inventoryUser', this.spillage.get('user.id')) : null,
          location: this.spillage.get('location')
        });
        var createdBy = hashedData.createdByUser && hashedData.createdByUser.get('id') > 0 ? hashedData.createdByUser.get('fname') + ' ' + hashedData.createdByUser.get('lname') : '';
        this.set('completedStatuses', {
          'open': {
            'closed': true,
            'secondary_text': (0, _moment.default)(this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model.createdAt')).format('MM/DD/YYYY h:mm a') + '<br>' + createdBy
          },
          'submitted': {
            'closed': status !== 'open',
            'secondary_text': ''
          },
          'closed': {
            'closed': status === 'closed',
            'secondary_text': ''
          }
        });
        this.set('listArray', hashedData.lineItems);
        this.listArray.forEach(item => {
          item.set('hasBeenSaved', true);
        });
        this.addRow();
        this.set('equipmentReturnReason', hashedData.equipmentReturnReason);
        var recipient = hashedData.user ? hashedData.user : hashedData.location;
        this.set('recipient', recipient);
        this.set('equipment', yield this.getRecipientEquipment.perform());
        this.set('dataLoaded', true);
      }
    }).on('init'),
    getRecipientEquipment: (0, _emberConcurrency.task)(function* () {
      var returnEquipment = Ember.A();
      var hashedData = yield (0, _emberConcurrency.hash)({
        equipmentInventory: this.recipient.invokeAction('get_admin_equipment_inventory', {
          verb: 'GET'
        }),
        companyEquipment: this.store.query('equipment', {
          limit: 0,
          filter: 'active=true'
        })
      });
      var userEquipment = hashedData.equipmentInventory.responsePayload.data.filter(item => {
        return item.on_hand_quantity > 0;
      });

      var _loop = function (x) {
        var userItem = userEquipment.objectAt(x);
        var pushItem = hashedData.companyEquipment.find(piece => piece.get('id') === userItem.equipment_id);

        if (pushItem) {
          pushItem.set('userItemInfo', userItem);
          returnEquipment.push(pushItem);
        }
      };

      for (var x = 0; x < userEquipment.length; x++) {
        _loop(x);
      }

      return returnEquipment;
    }),

    didInsertElement() {
      this._super(...arguments);

      if (this.grants.getIndividualGrant('equipment_spillage.modify')) {
        this.multiStepForm.registerStepData("step1", 'buttons', [{
          text: this.intl.t('common.actions.saveAndLock'),
          click: () => {
            this.set('confirmLockModalOpen', true);
          },
          classNamesString: 'st-btn st-btn-secondary po-review-save-button'
        }, {
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveSpillageData.perform(this.multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary po-review-save-button'
        }]);
      }
    },

    deleteSpillageLineItemTask: (0, _emberConcurrency.task)(function* (row) {
      yield this.deleteRow.perform(row, 'pendingEquipmentLineItem');
      this.multiStepForm.formCopiedModelsSetVal('equipmentSpillage.model.pendingEquipmentLineItems', this.listArray);
      this.set('spillageEquipmentLineItemDeleteId', false);
      this.getLineItemCount.perform();
    }),
    lockSpillageTask: (0, _emberConcurrency.task)(function* (buttonPromise) {
      var multiStepForm = this.multiStepForm;
      var spillage = multiStepForm.formOriginalModelsGetVal('equipmentSpillage.model');
      var formattedObjects = this.prepForValidate('pendingEquipmentLineItem');
      yield multiStepForm.validateList.perform(formattedObjects, {
        notificationNamespace: 'modal2'
      });
      multiStepForm.formCopiedModelsSetVal('equipmentSpillage.model.pendingEquipmentLineItems', this.trueListArray);
      yield multiStepForm.validateSyncSaveAll({
        notificationNamespace: 'modal2',
        extraValidationData: {
          noValidate: this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model.user.id') ? ['equipmentSpillage-location'] : ['equipmentSpillage-user']
        }
      });

      try {
        yield spillage.invokeAction('set_status_locked', {
          verb: 'POST'
        });
        buttonPromise.then(() => {
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
          multiStepForm.formStartedSetVal(false);

          if (this.refreshModel) {
            this.refreshModel();
          }
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal2'
        });
        throw err;
      }
    }),
    saveSpillageData: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var formattedObjects = this.prepForValidate('pendingEquipmentLineItem');
      multiStepForm.formCopiedModelsSetVal('equipmentSpillage.model.pendingEquipmentLineItems', this.trueListArray);
      yield multiStepForm.validateList.perform(formattedObjects, {
        notificationNamespace: 'modal'
      });
      yield multiStepForm.validateSyncSaveAll({
        notificationNamespace: 'modal',
        extraValidationData: {
          noValidate: this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model.user.id') ? ['equipmentSpillage-location'] : ['equipmentSpillage-user']
        }
      });
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        multiStepForm.formStartedSetVal(false);

        if (this.refreshModel) {
          this.refreshModel();
        }
      });
    }),
    actions: {
      deleteSpillageEquipmentLineItem(row) {
        this.deleteSpillageLineItemTask.perform(row);
      },

      updateEquipmentItemsList(row, index) {
        var lineItem = row;

        if (row.get('equipment')) {
          if (row.get('quantity') > row.get('equipment').userItemInfo.on_hand_quantity) {
            row.set('quantity', row.get('equipment').userItemInfo.on_hand_quantity);
          }
        }

        lineItem.set('comments', row.get('comments'));
        lineItem.set('fulfilledQuantity', row.get('fulfilledQuantity') || 0);
        lineItem.set('cost', row.get('equipment.cost'));
        lineItem.set('quantity', row.get('quantity') > 0 ? row.get('quantity') : 1);
        lineItem.set('returnReason', row.get('equipmentReturnReason'));
        this.updateRow(lineItem, index);
        this.multiStepForm.formCopiedModelsSetVal('equipmentSpillage.model.pendingEquipmentLineItems', this.listArray);
        this.getLineItemCount.perform();
      }

    }
  });

  _exports.default = _default;
});