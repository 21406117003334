define("securitytrax-ember-ui/components/html/multi-select/building-blocks/single-input-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OHusgBWU",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"filter-input-wrapper\"],[12],[2,\"\\n    \"],[8,\"multi-step-form/switchable-inputs/text-input\",[[24,0,\"permissions__add-group-user-search\"]],[[\"@formMode\",\"@value\",\"@placeholder\"],[\"edit\",[34,0],[30,[36,1],[\"common.actions.search\"],null]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"filterValue\",\"t\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/multi-select/building-blocks/single-input-filter/template.hbs"
    }
  });

  _exports.default = _default;
});