define("securitytrax-ember-ui/models/base_models/equipment-purchase-order", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    catalyst: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['manual', 'semi_auto_replenish']
    }),
    comments: (0, _model.attr)('string', {
      'readOnly': false
    }),
    recipientNotes: (0, _model.attr)('string', {
      'readOnly': false
    }),
    statusModifiedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    branchNumber: (0, _model.attr)('string', {
      'readOnly': false
    }),
    retrievalMethodId: (0, _model.attr)('number', {
      'readOnly': false
    }),
    processingMethodId: (0, _model.attr)('number', {
      'readOnly': false
    }),
    ediTransmit: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    ediSent: (0, _model.attr)('boolean', {
      'readOnly': true
    }),
    accountingSent: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    status: (0, _model.attr)('string', {
      'readOnly': true
    }),
    pendingEquipmentType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['po', 'rma', 'spillage', 'pr']
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    equipmentVendor: (0, _model.belongsTo)('equipment-vendor', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    user: (0, _model.belongsTo)('user', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    location: (0, _model.belongsTo)('location', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    parentEquipmentPurchaseRequisition: (0, _model.belongsTo)('equipment-purchase-requisition', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    pendingEquipmentLineItems: (0, _model.hasMany)('pending-equipment-line-item', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'serialize': true,
      'stEmbedded': true
    }),
    tags: (0, _model.hasMany)('tag', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});