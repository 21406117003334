define("securitytrax-ember-ui/reports/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pcfhP6dC",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"page-content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n        \"],[1,[30,[36,2],null,[[\"categoryTitle\",\"reportCategories\"],[[30,[36,1],[\"pages.reports.nav.all_reports\"],null],[35,0,[\"reportCategories\"]]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"t\",\"reports/report-category\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/reports/index/template.hbs"
    }
  });

  _exports.default = _default;
});