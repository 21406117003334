define("securitytrax-ember-ui/customers/accounting/payments-payables/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "55YGuYWp",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[\"pages/accounting/payments/payables\"],[[\"model\",\"customer\"],[[35,0],[35,0,[\"customer\"]]]]]],[2,\"\\n\\n\"],[1,[30,[36,1],[[30,[36,2],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"model\",\"component\",\"-outlet\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/customers/accounting/payments-payables/template.hbs"
    }
  });

  _exports.default = _default;
});