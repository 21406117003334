define("securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/base-interactive-services-provider/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    isp: Ember.inject.service('events/isp'),

    init() {
      this._super(...arguments);

      this.isp.on('refresh-isp-connection', this, '_refreshAdcConnection');
      this.watchInteractiveServicesProviderNameTask.perform();
    },

    willDestroy() {
      this.isp.off('refresh-isp-connection', this, '_refreshAdcConnection');
    },

    _refreshAdcConnection() {
      this.notifyPropertyChange('interactiveServicesProvider');
    },

    interactiveServicesProviders: Ember.computed(function () {
      return this.interactiveServicesProvidersTask.perform();
    }),
    interactiveServicesProvidersTask: (0, _emberConcurrency.task)(function* () {
      return yield this.store.query('interactiveServicesProvider', {
        limit: 0
      });
    }),
    interactiveServicesProvider: Ember.computed(function () {
      return this.interactiveServicesProviderTask.perform();
    }),
    interactiveServicesProviderTask: (0, _emberConcurrency.task)(function* () {
      var grants = this.grants;

      try {
        var orderInfoGrant = grants.getIndividualGrant('customer_order_information.view');
        var customer = this.customer;
        var interactiveServicesProvider;

        if (orderInfoGrant) {
          try {
            var customerOrderInfo = yield this.store.findRecord('customerOrderInformation', customer.get('id'));
            interactiveServicesProvider = yield customerOrderInfo.get('interactiveServicesProvider');
          } catch (e) {//
          }
        }

        return interactiveServicesProvider;
      } catch (error) {
        (false && Ember.warn('failed to get interactive services provider' + error, {
          id: 'multiStepForm.forms.customerInteractiveServicesProvider.baseInteractiveServicesProvider'
        }));
      }
    }),
    cancelTask: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.multiStepForm.resetForm(false);
        yield this.set('formMode', 'view');
        this.stNotificationManager.addSuccessKey('common.notifications.generic_cancel');
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err);
      }
    }).drop(),
    ispSelected: undefined,
    watchInteractiveServicesProviderName: Ember.observer('multiStepForm.formCopiedModels.customerOrderInformation.model.interactiveServicesProvider.name', function () {
      this.watchInteractiveServicesProviderNameTask.perform();
    }),
    watchInteractiveServicesProviderNameTask: (0, _emberConcurrency.task)(function* () {
      // DREW TODO - note that this observer causes another observer to fire that calls multiStepForm.resetForm and is odd and maybe could break stuff
      // the observer is: watchIspStatus: observer('ispStatus'
      var multiStepForm = this.multiStepForm;
      yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);
      var name = yield this.multiStepForm.formCopiedModelsGetVal('customerOrderInformation.model.interactiveServicesProvider.name');

      if (name) {
        this.set('ispSelected', true);
      } else {
        this.set('ispSelected', false);
      }
    }),
    actions: {
      saveInteractiveServicesProviderInformation(buttonPromise) {
        var taskInstance = this.saveInteractiveServicesProviderInformationTask.unlinked().perform(this.multiStepForm, buttonPromise);
        this.set('saveInteractiveServicesProviderInformationInstance', taskInstance);
        this.set('formMode', 'view');
        return taskInstance;
      },

      cancelEditInteractiveServices() {
        return this.cancelTask.perform();
      }

    }
  });

  _exports.default = _default;
});