define("securitytrax-ember-ui/components/html/notification-badge/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Pkg7AIWb",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"notification-badge \",[34,2],\" \",[34,1]]]],[12],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[10,\"span\"],[12],[1,[34,0]],[2,\"+\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[10,\"span\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"count\",\"isHidden\",\"xPositioning\",\"includePlus\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/notification-badge/template.hbs"
    }
  });

  _exports.default = _default;
});