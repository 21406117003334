define("securitytrax-ember-ui/components/multi-step-form/forms/customer-central-station-information/monitoring-companies/monitoring-company-type-37/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer-central-station-information/base-monitoring-company/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class APIMonitoringCompanyType extends _component.default {
    get monitoringCompanyHasNeededGrants() {
      return true;
    }

  }

  _exports.default = APIMonitoringCompanyType;
});