define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/customer-create-chooser/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Bjk0npHz",
    "block": "{\"symbols\":[\"data\",\"DynamicComponent\",\"@afterCreateCustomer\"],\"statements\":[[6,[37,6],[[30,[36,5],[[30,[36,4],[\"multi-step-form/forms-dynamic/customer-create-chooser/create-form-configs/\",[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,2],[],[[\"@currentCreateId\",\"@afterCreateCustomer\",\"@createCustomerFormStarted\",\"@customer\",\"@workflowConfig\",\"@newCustomer\"],[[34,0],[32,3],[34,1],[34,2],[34,3],[34,2]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\n\"],[8,\"modals/st-paper\",[],[[\"@title\",\"@modalButtons\",\"@classNames\",\"@dialogOpen\",\"@onClose\"],[[30,[36,7],[\"common.nav.create.customer.select_wizard\"],null],[34,8],\"multi-step-form-customer-create-chooser md-dialog\",[34,9],[30,[36,11],[[32,0],[30,[36,10],[[35,9]],null],false],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"html/st-await\",[],[[\"@useSpinner\",\"@promises\"],[true,[30,[36,13],null,[[\"customerCreateOptions\"],[[35,12]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[14,0,\"grid-x grid-padding-x grid-padding-y\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"cell small-12\"],[12],[2,\"\\n                \"],[8,\"html/package-select/package-layouts/default-layout\",[],[[\"@packageList\",\"@selectedId\",\"@labelProperty\",\"@canDeselect\",\"@updateSelected\"],[[32,1,[\"resolvedPromises\",\"customerCreateOptions\"]],[34,14],\"name\",false,[30,[36,11],[[32,0],[30,[36,10],[[35,14]],null]],null]]],null],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"dasherizedCurrentCreateId\",\"createCustomerFormStarted\",\"customer\",\"currentWorkflowConfig\",\"concat\",\"component\",\"let\",\"t\",\"createFormChooserButtons\",\"createFormChooserOpen\",\"mut\",\"action\",\"customerCreateOptions\",\"hash\",\"currentCreateId\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/customer-create-chooser/template.hbs"
    }
  });

  _exports.default = _default;
});