define("securitytrax-ember-ui/accounting/dashboard/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/has-page-title", "securitytrax-ember-ui/mixins/scroll-to-top"], function (_exports, _authenticatedRouteMixin, _hasPageTitle, _scrollToTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _hasPageTitle.default, _scrollToTop.default, {
    grants: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      var routeObjs = [{
        route: 'accounting.funding-entries',
        grants: ['fundings.view']
      }, {
        route: 'accounting.payments',
        grants: ['customer_payments.view']
      }, {
        route: 'accounting.payable-entries',
        grants: ['customer_payables.view']
      }, {
        route: 'accounting.invoices',
        grants: ['customer_invoices.view']
      }, {
        route: 'accounting.credit-memos',
        grants: ['customer_credit_memos.view']
      }, {
        route: 'accounting.refund-receipts',
        grants: ['customer_refund_receipts.view']
      }];
      this.baseFindGrantsTask.perform(routeObjs);
    },

    model() {},

    getPageTitle() {
      return this.intl.t('pages.accounting.title');
    },

    getRouteName() {
      return 'common.nav.dashboard';
    }

  });

  _exports.default = _default;
});