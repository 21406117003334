define("securitytrax-ember-ui/helpers/st-filters-service-clear-filters-by-group-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    stFiltersService: Ember.inject.service('st-filters-service'),

    compute(args) {
      var [stFilterGroupKey] = args;
      var results = this.stFiltersService.clearFiltersByGroupKey(stFilterGroupKey);
      return results;
    }

  });

  _exports.default = _default;
});