define("securitytrax-ember-ui/administration/human-resources/permissions/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/route-pageview-logging", "securitytrax-ember-ui/mixins/has-page-title"], function (_exports, _authenticatedRouteMixin, _routePageviewLogging, _hasPageTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _routePageviewLogging.default, _hasPageTitle.default, {
    router: Ember.inject.service(),
    permissionsService: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['groups.view']);
    },

    queryParams: {
      refreshModel: true
    },

    model() {
      var store = this.store;
      return Ember.RSVP.hash({
        groups: store.findAll('group', {
          limit: 0
        })
      });
    },

    parentController: Ember.computed(function () {
      return this.controllerFor('administration.human-resources');
    }),

    getPageTitle() {
      return this.intl.t('pages.administration.human_resources.groups');
    },

    getRouteName() {
      return "pages.administration.nav.users-permissions";
    },

    setupBreadCrumbs() {
      var breadCrumbs = [{
        name: this.intl.t('pages.administration.human_resources.groups'),
        last: true,
        previousLink: 'administration',
        link: 'user-list'
      }];
      var breadCrumbData = {
        title: this.intl.t('pages.administration.human_resources.title'),
        titleLink: 'administration.human-resources.user-list',
        hide: false
      };
      var permissionsService = this.permissionsService;
      permissionsService.set('_currentGroup', null);
      permissionsService.set('_groupResourceTypePolicies', null);
      this.parentController.set('breadCrumbs', breadCrumbs);
      this.parentController.set('breadCrumbData', breadCrumbData);
    },

    actions: {
      didTransition() {
        this.setupBreadCrumbs();
      }

    }
  });

  _exports.default = _default;
});