define("securitytrax-ember-ui/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/has-page-title", "securitytrax-ember-ui/mixins/scroll-to-top", "ember-concurrency", "moment"], function (_exports, _authenticatedRouteMixin, _hasPageTitle, _scrollToTop, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _hasPageTitle.default, _scrollToTop.default, {
    sessionData: Ember.inject.service('sessionData'),
    user: undefined,
    queryParams: {
      date: {
        refreshModel: true
      }
    },

    beforeModel() {
      // Re-direct index to homepage.
      return this.transitionTo('/home/view');
    },

    model(params) {
      return this.getModelData.perform(params);
    },

    getModelData: (0, _emberConcurrency.task)(function* (params) {
      var loggedInUser = null;

      try {
        loggedInUser = yield this.sessionData.getUser();
        var startOfDay = params.date ? (0, _moment.default)(params.date).startOf('day') : (0, _moment.default)().startOf('day');
        var endOfDay = params.date ? (0, _moment.default)(params.date).endOf('day') : (0, _moment.default)().endOf('day');
        var calendarEventFilter = '(start_time >= \'' + startOfDay.format('YYYY-MM-DDTHH:mm:ss') + '\') and (start_time <= \'' + endOfDay.format('YYYY-MM-DDTHH:mm:ss') + '\') and (user.id = \'' + loggedInUser.get('id') + '\') and (calendar_event_type.name != \'Time Off\')';
        return (0, _emberConcurrency.hash)({
          calendarEventFilter: calendarEventFilter,
          loggedInUser: loggedInUser
        });
      } catch (e) {
        (false && Ember.warn(e, {
          id: 'app.index.getModelData'
        }));
      }

      return (0, _emberConcurrency.hash)({
        loggedInUser: loggedInUser
      });
    }),

    getPageTitle() {
      return '';
    },

    getRouteName() {
      return 'common.nav.home';
    }

  });

  _exports.default = _default;
});