define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/interactive-services-providers/adc/import/import-users/getting-started/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/interactive-services-providers/adc/import/import-users/import-users-base"], function (_exports, _importUsersBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _importUsersBase.default.extend({
    intl: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData("gettingStarted", 'buttons', [{
        text: this.intl.t('common.actions.next'),
        click: () =>
        /*buttonPromise*/
        {
          multiStepForm.currentStepNameKeySetVal('filterOptions');
        },
        classNamesString: 'st-btn st-btn-primary'
      }]);
    }

  });

  _exports.default = _default;
});