define("securitytrax-ember-ui/mixins/forms/resource-type-policies-reusable", ["exports", "@ember-data/model", "ember-concurrency", "lodash", "securitytrax-ember-ui/mixins/list/row-list"], function (_exports, _model, _emberConcurrency, _lodash, _rowList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This is mixin is used in both the group resource type policies and user resource type policies
   *
   * @mixin ListMixin
   * @extends Ember.Mixin
   */
  var _default = Ember.Mixin.create(_rowList.default, {
    intl: Ember.inject.service(),
    permissionsService: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    rowListEvent: Ember.inject.service('events/row-list-event'),
    resourceTypePolicy: undefined,

    emptyListItem(fireEvent = false) {
      this.addRow();
      var lineItems = this.listArray;
      var lineItem = lineItems[lineItems.get('length') - 1];
      lineItem.set('resourceTypePolicy', {
        id: null,
        dataType: null
      });
      lineItem.set('operatorList', []);
      lineItem.set('conditionRight', {
        type: 'static',
        value: null
      });
      lineItem.set('multipleSelected', []);
      lineItem.set('conditionRightOptionsList', []);

      if (fireEvent) {
        this.rowListEvent.trigger('update-row-list', lineItems[lineItems.get('length') - 2], 'newPoliciesList');
      }
    },

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm; // TODO: group and user policies used to be the same, now they are different

      if (this.modelName === 'user') {
        multiStepForm.registerStepData("editPolicies", 'buttons', [{
          text: this.intl.t('common.actions.cancel'),
          click: () => {
            multiStepForm.resetForm(false);
            multiStepForm.formStartedSetVal(false);
          },
          classNamesString: 'st-btn btn-secondary edit-policies-cancel-button'
        }, {
          text: this.intl.t('common.actions.apply'),
          click: () => {
            return this.upsertPolicies.perform(multiStepForm);
          },
          classNamesString: 'st-btn st-btn-primary save-policies-save-button'
        }]);
      }
    },

    setupOperatorsList(allowedOperators) {
      var intl = this.intl; //const operators = ['EQ','IN','GT','GTEQ','LT','LTEQ','STARTS_WITH','ENDS_WITH','CONTAINS','RELATION','COMPLEX'];

      var list = [];
      allowedOperators.forEach(operator => {
        list.push({
          value: operator,
          name: intl.t('common.operators.' + operator)
        });
      });
      return list;
    },

    upsertPolicies: (0, _emberConcurrency.task)(function* (multiStepForm) {
      var list = this.listArray;
      var currentResourceType = this.resource;
      var createList = list.filter(item => {
        return item.get('id') == undefined;
      });
      var itemsToDelete = this.itemsToDeleteList;
      var store = this.store;
      var modelName = this.modelName;
      var model = this.get("multiStepForm.formOriginalModels.".concat(modelName, ".model"));
      var resourcePolicyList = list.filter(item => {
        return item.get('id') != undefined;
      });
      var userPoliciesOverrideToggle = this.userPoliciesOverrideToggle;
      var existingUserPolicyOverride = this.existingUserPolicyOverride;
      var overrideResourceTypePolicy = false;
      var allRequests = []; // USER POLICIES OVERRIDE INSTEAD OF ADDITIVE POLICIES
      // CREATE A USER_POLICY_OVERRIDE RECORD FOR THE RESOURCE

      if (modelName == 'user' && userPoliciesOverrideToggle && !existingUserPolicyOverride) {
        overrideResourceTypePolicy = yield store.createRecord('userPolicyOverride', {
          resourceType: currentResourceType,
          user: model
        });
        yield overrideResourceTypePolicy.save();
      } else if (modelName == 'user' && !userPoliciesOverrideToggle && existingUserPolicyOverride) {
        yield existingUserPolicyOverride.destroyRecordWithRollbackTask.perform();
        yield existingUserPolicyOverride.unloadRecord();
      } else if (modelName == 'user' && userPoliciesOverrideToggle && existingUserPolicyOverride) {
        overrideResourceTypePolicy = existingUserPolicyOverride;
      } //VALIDATIONS BEFORE ADD/EDIT


      var formattedObjects = [];
      list.forEach((item, index) => {
        if (index < this.lastRowIndex) {
          formattedObjects.pushObject(Ember.Object.create({
            model: {
              policy: item.get('resourceTypePolicy.id'),
              operator: item.get('operator'),
              conditionRight: item.get('conditionRight.value'),
              item: item
            },
            filePath: 'policies',
            extraValidationData: {
              overrideValidationKey: index + '-policies'
            }
          }));
        }
      });
      yield multiStepForm.validateList.perform(formattedObjects, {
        notificationNamespace: 'modal2'
      });

      try {
        //DELETE
        for (var q = 0; q < Object.keys(itemsToDelete).get('length'); q++) {
          var itemToDelete = Object.keys(itemsToDelete)[q];
          allRequests.push(this.deleteResourceTypePolicy.perform(itemToDelete));
        } //PATCH/UPDATE


        for (var _q = 0; _q < resourcePolicyList.get('length'); _q++) {
          var lineItem = resourcePolicyList.objectAt(_q);

          if (itemsToDelete[lineItem.get('id')]) {
            continue;
          }

          allRequests.push(this.updateResourceTypePolicy.perform(lineItem));
        } //POST/CREATE


        for (var _q2 = 0; _q2 < createList.get('length'); _q2++) {
          var itemToCreate = createList.objectAt(_q2);

          if (itemToCreate.get('resourceTypePolicy.id') == null) {
            continue;
          } //dont send post for empty policy


          allRequests.push(this.createResourceTypePolicy.perform(itemToCreate));
        }

        yield (0, _emberConcurrency.all)(allRequests);
        this.multiStepForm.formStartedSetVal(false);
        this.afterPolicyUpdated(overrideResourceTypePolicy);

        if (modelName == 'group') {
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
        } else {
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        }
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal2'
        });
        throw err;
      }
    }),
    deleteResourceTypePolicy: (0, _emberConcurrency.task)(function* (itemToDelete) {
      var toDelete = yield this.store.findRecord(this.resourceTypePolicyName, itemToDelete, {
        backgroundReload: false
      });
      yield toDelete.destroyRecordWithRollbackTask.perform();
    }),
    updateResourceTypePolicy: (0, _emberConcurrency.task)(function* (lineItem) {
      var copiedModel = yield this.store.findRecord(this.resourceTypePolicyName, lineItem.get('id'));
      copiedModel.set('conditionRight', lineItem.get('conditionRight'));
      copiedModel.set('operator', lineItem.get('operator'));
      yield copiedModel.save();
    }),
    createResourceTypePolicy: (0, _emberConcurrency.task)(function* (itemToCreate) {
      var store = this.store;
      var modelName = this.modelName;
      var model = this.get("multiStepForm.formOriginalModels.".concat(modelName, ".model"));
      var resourceTypePolicy = yield store.findRecord('resourceTypePolicy', parseInt(itemToCreate.get('resourceTypePolicy.id')));
      var newPolicyObject = {
        conditionRight: itemToCreate.get('conditionRight'),
        operator: itemToCreate.get('operator'),
        resourceTypePolicy
      };
      newPolicyObject[modelName] = model;
      var newResourceTypePolicy = yield store.createRecord(this.resourceTypePolicyName, newPolicyObject);
      yield newResourceTypePolicy.save();
    }),
    getSelectionListTask: (0, _emberConcurrency.task)(function* (resourceTypePolicy) {
      return (yield resourceTypePolicy.invokeAction('get_selection_list', {
        verb: 'GET'
      })).responsePayload.data;
    }),

    setRowMultipleSelectOptions(lineItem) {
      if (!(lineItem.get('conditionRight.value') instanceof Array)) {
        lineItem.set('conditionRight.value', lineItem.get('conditionRight.value') != null ? [lineItem.get('conditionRight.value')] : []);
      }

      var possibleOptions = lineItem.get('conditionRightOptionsList');
      var selectedOptions = possibleOptions.filter(option => {
        return _lodash.default.includes(lineItem.get('conditionRight.value'), option.value);
      });
      lineItem.set('multipleSelected', selectedOptions);
    },

    setupNewOperatorDropdown: (0, _emberConcurrency.task)(function* (newLineItem, newValue) {
      var trueNewValue = yield this.store.findRecord('resourceTypePolicy', newValue.get('id'));
      var possibleOptions = newValue.get('responseMeta.has_selection_list') ? yield this.getSelectionListTask.perform(trueNewValue) : [];
      possibleOptions.forEach(option => {
        if (option.translation_key) {
          Ember.set(option, 'name', this.intl.t('policy_selections.' + option.translation_key + '.name'));
        }
      });

      if (newValue instanceof _model.default) {
        Ember.set(newLineItem, 'resourceTypePolicy.dataType', newValue.get('dataType'));
        var allowedOperators = newValue.get('responseMeta.allowed_operators');
        Ember.set(newLineItem, 'operatorsList', this.setupOperatorsList(allowedOperators ? allowedOperators : []));
        Ember.set(newLineItem, 'conditionRightOptionsList', possibleOptions);
      } else {
        Ember.set(newLineItem, 'resourceTypePolicy.dataType', newValue.dataType);
        Ember.set(newLineItem, 'operatorsList', this.setupOperatorsList(_lodash.default.get(newValue, 'responseMeta.allowed_operators', [])));
        Ember.set(newLineItem, 'conditionRightOptionsList', possibleOptions);
      }
    }),
    deletePolicyRow: (0, _emberConcurrency.task)(function* (item) {
      yield this.deleteRow.perform(item, 'resourceTypePolicy', true);

      if (!item.get('id')) {
        Ember.set(item, '_deleted', true);
        this.rowListEvent.trigger('update-row-list', item, 'newPoliciesList');
      } else {
        this.rowListEvent.trigger('update-row-list', item, 'deletePoliciesList');
        Ember.set(this, 'showDeletePoliciesWarning', true);
      }
    }),
    actions: {
      addNewPolicyRow() {
        if (this.listArray[this.lastRowIndex].resourceTypePolicy.dataType != null) {
          this.emptyListItem(true);
        }
      },

      setRowOptions(lineItem
      /*, newValue*/
      ) {
        var conditionRightValue = lineItem.get('conditionRight.value');

        if (lineItem.get('operator') == 'IN') {
          this.setRowMultipleSelectOptions(lineItem);
        } else if (lineItem.operator == 'EQ' && conditionRightValue instanceof Array) {
          //set to first non null value
          var last = _lodash.default.findLast(conditionRightValue, function (arrayItem) {
            return arrayItem != null;
          });

          _lodash.default.set(lineItem, 'conditionRight.value', last.value);
        }

        if (lineItem.get('id') && !lineItem.get('_dirty')) {
          Ember.set(lineItem, '_dirty', true);
          this.rowListEvent.trigger('update-row-list', lineItem, 'updatedPoliciesList');
        }
      }

    },

    // should be @action once we update this to JS native class, also this is a duplicate of an action above cause old/new components need one or the other :/
    setConditionRight(lineItem, newValue) {
      var conditionRightValue = lineItem.get('conditionRight.value');

      if (newValue instanceof Array) {
        if (!(conditionRightValue instanceof Array)) {
          conditionRightValue = conditionRightValue != null ? [conditionRightValue] : [];
        }

        newValue.forEach(newVal => {
          if (_lodash.default.includes(conditionRightValue, newVal.value)) {
            //remove from list if already there (multiselect de-select)
            conditionRightValue = _lodash.default.filter(conditionRightValue, function (val) {
              return val == newVal.value;
            });
          } else {
            conditionRightValue.push(newVal.value);
          }
        });
      } else if (newValue.value) {
        conditionRightValue = newValue.value;
      }

      lineItem.set('conditionRight', Ember.Object.create({
        type: 'static',
        value: conditionRightValue
      }));

      if (lineItem.get('operator') == 'IN') {
        this.setRowMultipleSelectOptions(lineItem);
      }

      if (lineItem.get('id') && !lineItem.get('_dirty')) {
        Ember.set(lineItem, '_dirty', true);
        this.rowListEvent.trigger('update-row-list', lineItem, 'updatedPoliciesList');
      }

      this.notifyPropertyChange('listArray');
      return lineItem;
    }

  });

  _exports.default = _default;
});