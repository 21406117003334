define("securitytrax-ember-ui/validations/user", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateObject = {
        fname: {
          presence: this.checkValidateCustomMessageDefault(data, 'fname')
        },
        lname: {
          presence: this.checkValidateCustomMessageDefault(data, 'lname')
        },
        email: {
          presence: this.checkValidateCustomMessageDefault(data, 'email'),
          email: this.checkValidateCustomMessageDefault(data, 'email')
        }
      };

      if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('username')) {//
      } else {
        var usernameObject = {
          presence: this.checkValidateCustomMessageDefault(data, 'username'),
          length: {
            minimum: 3,
            message: this.customMessage(data, 'usernameLength')
          }
        };
        validateObject['username'] = usernameObject;
      } //my account


      if ('extraValidationData' in data && 'myAccount' in data.extraValidationData) {
        var currentPasswordPresence = {
          presence: this.checkValidateCustomMessageDefault(data, 'currentPassword')
        };
        validateObject['currentPassword'] = currentPasswordPresence;
      } //validate password


      if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('password')) {//
      } else {
        var passwordObject = {
          //presence: this.checkValidateCustomMessageDefault(data, 'password'),
          equality: {
            attribute: 'passwordVerify',
            message: this.customMessage(data, 'passwordEqual')
          },
          length: {
            minimum: 7,
            message: this.customMessage(data, 'passwordLength')
          },
          exclusion: {
            within: [this.username],
            message: this.customMessage(data, 'passwordExclusion')
          } //MUST HAVE 1 NUMBER AND 1 CAPITAL
          // format: {
          //     pattern: "/^(?=.*\d.*)(?=.*[A-Z].*)(?=.*[_\W].*).+$/",
          //     message: "Password must have at least 1 number and 1 capital"
          // }

        };
        validateObject['password'] = passwordObject;
      }

      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject, {
        fullMessages: this.prependProperty(data)
      });
      return this.outputFormatting(validationData, 'user');
    }

  });

  _exports.default = _default;
});