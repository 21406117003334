define("securitytrax-ember-ui/components/multi-step-form/forms/customer-central-station-information/base-monitoring-company/component", ["exports", "@glimmer/component", "ember-concurrency", "securitytrax-ember-ui/mixed-in-classes/customer-integrations"], function (_exports, _component, _emberConcurrency, _customerIntegrations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var BaseMonitoringCompany = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = (0, _emberConcurrency.task)(function* () {
    this.monitoringCompanies = yield this.store.query('monitoringCompany', {
      limit: 0
    });
  }), _dec11 = (0, _emberConcurrency.task)(function* () {
    return yield this.store.findAll('dealerNumber');
  }), _dec12 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);

    if (!this.args.customer.zip) {
      this.stNotificationManager.addErrorMessages(this.intl.t('pages.administration.integrations.monitoringCompanies.integrations.zip_required_to_shell'));
      throw new Error();
    }

    var monitorTypeId = this.args.multiStepForm.formCopiedModelsGetVal('customerOrderInformation.model.monitoringCompany.monitoringCompanyType.id');
    this.router.transitionTo(this.args.subroutePath + '.manage-' + this.subrouteMapping.monitorMapping.findBy('id', monitorTypeId).name);
  }), _dec13 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    try {
      yield this.args.multiStepForm.validateSyncSaveAll({
        extraValidationData: {
          noValidate: ['orderInformation-salesRep', 'orderInformation-customerCancelDate']
        }
      });
      buttonPromise.then(() => {
        this.args.setFormMode('view');
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        this.args.updateCentralStationForm();
      });
    } catch (err) {
      (false && Ember.warn('saveCentralStation failed: ' + err, {
        id: 'multiStepForm.forms.customerCentralStationInformation.baseMonitoringCompany.saveCentralStation'
      }));
      throw new Error(err);
    }
  }).restartable(), _dec14 = (0, _emberConcurrency.task)(function* () {
    try {
      yield this.args.multiStepForm.resetForm(false);
      yield this.args.setFormMode('view');
      this.args.updatePendingMonitoringCompany(null);
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err);
    }
  }).drop(), _dec15 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
    var monitoringCompanyId = this.args.multiStepForm.formCopiedModelsGetVal('customerOrderInformation.model.monitoringCompany.id');

    if (monitoringCompanyId > 0) {
      this.stationSelected = true;
      var acctNumberBlock = yield this.store.query('accountNumberBlock', {
        filter: 'monitoring_company = ' + monitoringCompanyId
      });

      if (acctNumberBlock.get('length') > 0) {
        this.hasAccountNumberBlocks = true;
      }
    }
  }), _dec16 = (0, _emberConcurrency.task)(function* () {
    try {
      var multiStepForm = this.args.multiStepForm;
      var monitoringCompanyId = this.args.multiStepForm.formCopiedModelsGetVal('customerOrderInformation.model.monitoringCompany.id');
      var customer = this.args.customer;
      var newAccountNumber = yield customer.invokeAction('request_account_number', {
        verb: 'POST',
        data: {
          data: {
            monitoring_company_id: monitoringCompanyId
          }
        }
      });
      multiStepForm.formCopiedModelsSetVal('customerSystemInformation.model.accountNum', newAccountNumber.responsePayload.data.account_num);

      if (!this.args.multiStepForm.formCopiedModelsGetVal('customerSystemInformation.model.receiverNum')) {
        multiStepForm.formCopiedModelsSetVal('customerSystemInformation.model.receiverNum', newAccountNumber.responsePayload.data.receiver_num);
      }
    } catch (err) {
      (false && Ember.warn('monitoring company get account number error:' + err, {
        id: 'multiStepForm.forms.customer-central-station-information.base-monitoring-company.getAccountNumberBlockTask'
      }));
      this.stNotificationManager.addErrorMessages(err);
      throw err;
    }
  }), _dec17 = (0, _emberConcurrency.task)(function* () {
    yield this.CustomerIntegrations.unlinkAccount.perform();
  }), (_class = (_temp = class BaseMonitoringCompany extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "grants", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor4, this);

      _initializerDefineProperty(this, "router", _descriptor5, this);

      _initializerDefineProperty(this, "subrouteMapping", _descriptor6, this);

      _initializerDefineProperty(this, "hasAccountNumberBlocks", _descriptor7, this);

      _initializerDefineProperty(this, "stationSelected", _descriptor8, this);

      _initializerDefineProperty(this, "monitoringCompanies", _descriptor9, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor10, this);

      _initializerDefineProperty(this, "dealerNumbersTask", _descriptor11, this);

      _initializerDefineProperty(this, "startIntegrationForm", _descriptor12, this);

      _initializerDefineProperty(this, "saveCentralStationInformationTask", _descriptor13, this);

      _initializerDefineProperty(this, "cancelTask", _descriptor14, this);

      _initializerDefineProperty(this, "watchMonitoringCompanyNameTask", _descriptor15, this);

      _initializerDefineProperty(this, "getAccountNumberBlockTask", _descriptor16, this);

      _initializerDefineProperty(this, "onConfirmUnlinkTask", _descriptor17, this);

      this.CustomerIntegrations = new _customerIntegrations.default(...arguments);
      this.watchMonitoringCompanyNameTask.perform();
      this.initDataTask.perform();
    }

    get isConnected() {
      return this.args.multiStepForm.formOriginalModelsGetVal('customerOrderInformation.model.monitoringCompany.isConnected');
    }

    get connectionFailedMessage() {
      var name = this.args.multiStepForm.formOriginalModelsGetVal('customerOrderInformation.model.monitoringCompany.monitoringCompanyType.name');
      return this.intl.t('pages.customers.information.customer_system_information.connection_failed', {
        monitoringCompanyTypeName: name ? name : this.intl.t('pages.customers.information.customer_system_information.default_monitor_company_name')
      });
    }

    get dealerNumbers() {
      return this.dealerNumbersTask.perform();
    }

    get hasAllCentralStationGrants() {
      return this.monitoringCompanyHasNeededGrants;
    }

    get monitoringCompanyHasNeededGrants() {
      throw new Error('you are required to override this function for setting up a monitoring company');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "subrouteMapping", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "hasAccountNumberBlocks", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "stationSelected", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "monitoringCompanies", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "dealerNumbersTask", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "startIntegrationForm", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "saveCentralStationInformationTask", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "cancelTask", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "watchMonitoringCompanyNameTask", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "getAccountNumberBlockTask", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "onConfirmUnlinkTask", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BaseMonitoringCompany;
});