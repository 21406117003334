define("securitytrax-ember-ui/models/payroll-payment-status", ["exports", "securitytrax-ember-ui/models/base_models/payroll-payment-status"], function (_exports, _payrollPaymentStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _payrollPaymentStatus.default.extend({
    colorClass: Ember.computed('id', function () {
      var color = 'black';
      var opacity;

      switch (this.id) {
        case '1':
          //Pending
          color = '#767676;';
          break;

        case '2':
          //Approved
          color = '#0f86d3;';
          break;

        case '3':
          //Partially Paid
          color = '#23ab3f;';
          opacity = '0.8;';
          break;

        case '4':
          //Paid In Full
          color = '#23ab3f;';
          break;

        case '5':
          //Requires Review
          color = '#ec720b;';
          break;

        case '6':
          //Denied
          color = '#d92c29;';
          break;
      }

      return 'color: ' + color + (opacity ? ' opacity: ' + opacity : '');
    })
  });

  _exports.default = _default;
});