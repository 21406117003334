define("securitytrax-ember-ui/components/multi-step-form/forms/administration/organization/edit-office-location/scheduling-information/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "securitytrax-ember-ui/components/multi-step-form/forms/administration/organization/edit-office-location/base-component", "securitytrax-ember-ui/mixins/forms/duration-options"], function (_exports, _switchableFormStep, _baseComponent, _durationOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend(_switchableFormStep.default, _durationOptions.default, {
    intl: Ember.inject.service(),
    leadTimes: Ember.computed(function () {
      var optionsArray = [];
      var hourName = ' ' + this.intl.t('pages.administration.organization.officeLocations.hours');

      for (var i = 3; i <= 96; i += 3) {
        optionsArray.push(Ember.Object.create({
          name: i + hourName,
          value: i * 3600
        }));
      }

      return optionsArray;
    }),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;

      if (this.formMode !== 'view') {
        multiStepForm.registerStepData('schedulingInformation', 'buttons', [{
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveOfficeLocation.perform(multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary office-location-save-button'
        }]);
      }
    }

  });

  _exports.default = _default;
});