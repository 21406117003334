define("securitytrax-ember-ui/models/unlink-customer", ["exports", "securitytrax-ember-ui/models/base_models/unlink-customer"], function (_exports, _unlinkCustomer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _unlinkCustomer.default.extend({});

  _exports.default = _default;
});