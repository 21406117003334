define("securitytrax-ember-ui/demo/crop-image/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    coordinates: undefined,
    userService: Ember.inject.service(),
    // You can pass in options to st-image-cropper
    // For a list of options - https://fengyuanchen.github.io/cropperjs
    // This is an example of how options can work
    options: Ember.computed(function () {
      return {
        crop: e => {
          var data = e.detail;
          var coordinates = {
            x: Math.round(data.x),
            y: Math.round(data.y),
            height: Math.round(data.height),
            width: Math.round(data.width)
          };
          this.set('coordinates', coordinates);
        }
      };
    }),
    actions: {
      uploadFile(image, data) {
        data;
        var attributes = {
          //x: data.x,
          //y: data.y,
          //width: data.width,
          //height: data.height,
          base64_data: image
        };
        var user = this.store.findRecord('user', 10);
        return user.invokeAction('upload_profile_picture', {
          verb: 'POST',
          data: {
            data: {
              type: 'users_action',
              attributes: attributes
            }
          },
          processResponse: true
        });
      }

    }
  });

  _exports.default = _default;
});