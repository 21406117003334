define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/electronic-document-providers/integrations/pandadoc/settings/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/electronic-document-providers/integrations/shared/base", "ember-concurrency"], function (_exports, _base, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var PandadocSettings = (_dec = (0, _emberConcurrency.task)(function* () {
    this.config = yield this.args.multiStepForm.formOriginalModelsGetVal('electronicDocumentProvider.model.electronicDocumentProviderConfig');

    if (!this.args.multiStepForm.formCopiedModels.electronicDocumentProvider.model.electronicDocumentProviderConfig.environment) {
      this.args.multiStepForm.formCopiedModels.electronicDocumentProvider.model.electronicDocumentProviderConfig.environment = 'development';
    }

    if (this.router.currentRoute.queryParams.error) {
      this.oauthError = 'Unable to authorize SecurityTrax via OAuth<br><br>' + this.router.currentRoute.queryParams.error + '<br><br>' + this.router.currentRoute.queryParams.error_message;
    } else {
      this.args.multiStepForm.registerStepData('integration', 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.savePandadocSettings.perform(this.args.multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary electronic-document-provider-save-button'
      }]);
    }

    if (this.router.currentRoute.queryParams.code && this.router.currentRoute.queryParams.state) {
      try {
        yield this.config.invokeAction('complete_pandadoc_oauth_linking_process', {
          verb: 'POST',
          data: {
            data: {
              attributes: {
                code: this.router.currentRoute.queryParams.code,
                state: this.router.currentRoute.queryParams.state
              }
            }
          }
        });
        yield this.args.multiStepForm.formOriginalModelsGetVal('electronicDocumentProvider.model.electronicDocumentProviderConfig').reload();
        yield this.args.multiStepForm.setupCopyModels.unlinked().perform();
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
      }
    }
  }), _dec2 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    var apiKeySaved = multiStepForm.formCopiedModels.electronicDocumentProvider.model.electronicDocumentProviderConfig.responseMeta.attribute_has_saved_value.api_key;
    var apiKeyCurVal = multiStepForm.formCopiedModels.electronicDocumentProvider.model.electronicDocumentProviderConfig.apiKey;
    var noValidate = apiKeySaved && !apiKeyCurVal ? ['apiKey'] : [];
    yield this.saveEDocSettings.perform(multiStepForm, noValidate);
    yield this.setupFormData.perform();
    buttonPromise.then(() => {
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
      multiStepForm.formCopiedModelsSetVal('electronicDocumentProvider.model.electronicDocumentProviderConfig.apiKey', null);
    });
  }), _dec3 = (0, _emberConcurrency.task)(function* () {
    try {
      var oauthUrl = yield this.config.invokeAction('generate_pandadoc_oauth_url', {
        verb: 'POST',
        data: {
          data: {
            attributes: {
              redirect_uri: window.location.href
            }
          }
        }
      });
      window.open(oauthUrl.responsePayload.data.meta.pandadoc_oauth_url, '_self');
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), (_class = (_temp = class PandadocSettings extends _base.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "initTask", _descriptor, this);

      _initializerDefineProperty(this, "savePandadocSettings", _descriptor2, this);

      _initializerDefineProperty(this, "linkViaOauth", _descriptor3, this);

      this.initTask.perform();
    }

    get environments() {
      return [{
        id: 'development',
        name: this.intl.t('pages.administration.integrations.electronic_document_providers.pandadoc.sandbox')
      }, {
        id: 'production',
        name: this.intl.t('pages.administration.integrations.electronic_document_providers.pandadoc.production')
      }];
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "initTask", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "savePandadocSettings", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "linkViaOauth", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PandadocSettings;
});