define("securitytrax-ember-ui/components/multi-step-form/forms/administration/system/global-settings/edit-invoice-settings/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    stNotificationManager: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    usStates: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    invoiceTerm: undefined,
    showPastDueOnPdf: undefined,
    sendEmails: undefined,

    get resourceType() {
      return 'pdf_' + this.settingType + '_settings';
    },

    initDataTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true);

      if (this.multiStepForm.formOriginalModelsGetVal('pdfSetting.isCreate')) {
        this.multiStepForm.formCopiedModelsSetVal('pdfSetting.model.useCompanyDefaults', false);

        if (this.settingType === 'invoice') {
          this.multiStepForm.formCopiedModelsSetVal('pdfSetting.model.includeSoldBy', false);
          this.multiStepForm.formCopiedModelsSetVal('pdfSetting.model.hidePastDueOnPdf', false);
        }
      }

      if (this.settingType === 'invoice') {
        Ember.set(this, 'invoiceDefaults', yield this.sessionData.getCompanyInvoiceSettings());
        var term = this.invoiceDefaults && this.invoiceDefaults.get('defaultTermId') ? yield this.store.findRecord('invoiceTerm', this.invoiceDefaults.get('defaultTermId')) : null;
        Ember.set(this, 'invoiceTerm', term);
        Ember.set(this, 'showPastDueOnPdf', !this.multiStepForm.formCopiedModelsGetVal('pdfSetting.model.hidePastDueOnPdf'));
        Ember.set(this, 'sendEmails', this.invoiceDefaults.sendPastDueInvoiceEmailsUntilPaid);
      }
    }).on('init'),
    cancelTask: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.multiStepForm.resetForm(false);
        yield Ember.set(this, 'formMode', 'view');

        if (this.settingType === 'invoice') {
          var term = this.invoiceDefaults && this.invoiceDefaults.get('defaultTermId') ? yield this.store.findRecord('invoiceTerm', this.invoiceDefaults.get('defaultTermId')) : null;
          Ember.set(this, 'invoiceTerm', term);
          Ember.set(this, 'showPastDueOnPdf', !this.multiStepForm.formCopiedModelsGetVal('pdfSetting.model.hidePastDueOnPdf'));
          Ember.set(this, 'sendEmails', this.invoiceDefaults.sendPastDueInvoiceEmailsUntilPaid);
        }

        this.stNotificationManager.addSuccessKey('common.notifications.generic_cancel');
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err);
      }
    }).drop(),
    savePdfSettingsTask: (0, _emberConcurrency.task)(function* () {
      if (this.settingType === 'invoice') {
        this.multiStepForm.formCopiedModelsSetVal('pdfSetting.model.hidePastDueOnPdf', !this.showPastDueOnPdf);

        if (this.grants.getIndividualGrant('global_settings_invoice_settings.modify')) {
          if (this.invoiceDefaults) {
            Ember.set(this.invoiceDefaults, 'defaultTermId', this.get('invoiceTerm.id'));
            Ember.set(this.invoiceDefaults, 'sendPastDueInvoiceEmailsUntilPaid', this.sendEmails);
            yield this.invoiceDefaults.save();
          }
        }
      }

      if (this.multiStepForm.formOriginalModelsGetVal('pdfSetting.isCreate')) {
        var toCreateObjects = yield this.multiStepForm.validateCreateAll();
        toCreateObjects.pdfSetting.data['settingsType'] = this.settingType;
        var newPdfSetting = this.store.createRecord('pdfSetting', toCreateObjects.pdfSetting.data);
        yield toCreateObjects.pdfSetting.saveWithRollbackAndNotification(newPdfSetting);
      } else {
        yield this.multiStepForm.validateSyncSaveAll();
      }

      Ember.set(this, 'formMode', 'view');
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
    }).restartable()
  });

  _exports.default = _default;
});