define("securitytrax-ember-ui/components/multi-step-form/forms/system/stages/logs/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/system/stages/stages-base", "ember-concurrency"], function (_exports, _stagesBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var Logs = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = (0, _emberConcurrency.task)(function* () {
    var hashedData = yield (0, _emberConcurrency.hash)({
      eventHistory: this.eventHistoryTask.perform()
    });
    Ember.setProperties(this, hashedData);
  }), _dec5 = (0, _emberConcurrency.task)(function* () {
    var history = [];

    try {
      history = yield this.centralStationService.getData({
        model: this.args.customer,
        method: 'stages__getEventHistory'
      });
      history = history.event_history ? history.event_history : [];

      for (var i = 0; i < history.length; i++) {
        history[i]['event_number'] = history.length - i;
      }
    } catch (err) {
      var message = this.intl.t('pages.customers.system.order.stages_retrieve_error', {
        data: this.intl.t('pages.customers.system.order.event_history').toLowerCase()
      });
      this.stNotificationManager.addWarning(message + err, 'modal');
    }

    return history;
  }), _dec6 = Ember._action, (_class = (_temp = class Logs extends _stagesBase.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "centralStationService", _descriptor, this);

      _initializerDefineProperty(this, "scrollPosition", _descriptor2, this);

      _initializerDefineProperty(this, "eventHistory", _descriptor3, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor4, this);

      _initializerDefineProperty(this, "eventHistoryTask", _descriptor5, this);

      this.initDataTask.perform();
    }

    setScrollPosition() {
      this.scrollPosition = document.querySelector('.physical-inventory-scroll-wrap').scrollLeft;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "scrollPosition", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "eventHistory", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "eventHistoryTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setScrollPosition", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setScrollPosition"), _class.prototype)), _class));
  _exports.default = Logs;
});