define("securitytrax-ember-ui/customers/accounting/payments-payments/edit-create-payment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zxzeFXjy",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"customer\",\"customerPaymentId\",\"parentRoute\"],[[35,0,[\"customer\"]],[35,0,[\"customerPaymentId\"]],[35,0,[\"parentRoute\"]]]]]]],\"hasEval\":false,\"upvars\":[\"model\",\"modals/sub-route-modals/edit-create-payments\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/customers/accounting/payments-payments/edit-create-payment/template.hbs"
    }
  });

  _exports.default = _default;
});