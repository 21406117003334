define("securitytrax-ember-ui/models/base_models/work-order", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    subject: (0, _model.attr)('string', {
      'readOnly': false
    }),
    note: (0, _model.attr)('string', {
      'readOnly': false
    }),
    closedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    isClosed: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    dueDate: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    workOrderNumber: (0, _model.attr)('string', {
      'readOnly': false
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    noteType: (0, _model.belongsTo)('note-type', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    remoteLeadCompany: (0, _model.belongsTo)('lead-company', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    closedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    customer: (0, _model.belongsTo)('customer', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    assignedUser: (0, _model.belongsTo)('user', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': 'workOrders'
    }),
    workOrderType: (0, _model.belongsTo)('work-order-type', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    customerCommunications: (0, _model.hasMany)('customer-communication', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': 'workOrder'
    }),
    tags: (0, _model.hasMany)('tag', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    calendarEvents: (0, _model.hasMany)('calendar-event', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    customerEquipment: (0, _model.hasMany)('customer-equipment', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    workOrderEquipment: (0, _model.hasMany)('work-order-equipment', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'serialize': true,
      'stEmbedded': true
    })
  });

  _exports.default = _default;
});