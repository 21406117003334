define("securitytrax-ember-ui/components/html/st-thumbnail/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userService: Ember.inject.service(),
    classNames: ['st-thumbnail'],
    thumbnail: null,
    title: null,
    showShortTitle: false,
    shortTitle: Ember.computed('title', function () {
      var title = this.title;

      if (title) {
        return title.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '');
      }

      return '';
    })
  });

  _exports.default = _default;
});