define("securitytrax-ember-ui/components/multi-step-form/forms/administration/payables/line-item-details/line-item-calculation-options/excel-function/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3bgoYcx2",
    "block": "{\"symbols\":[\"@payrollRoleFunction\"],\"statements\":[[8,\"customer/accounting/payables-formula-component\",[],[[\"@payrollRoleFunction\",\"@multiStepForm\",\"@formMode\"],[[32,1],[32,0,[\"multiStepForm\"]],[34,0]]],null]],\"hasEval\":false,\"upvars\":[\"formMode\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/payables/line-item-details/line-item-calculation-options/excel-function/template.hbs"
    }
  });

  _exports.default = _default;
});