define("securitytrax-ember-ui/components/multi-step-form/forms/administration/users/roles/rep-numbers/edit-create-rep-number/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EditCreateRepNumber = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
    var locations = (yield this.store.findAll('location')).toArray().sortBy('name');
    locations.unshift({
      name: this.intl.t('pages.users.roles.all_locations'),
      id: 'All'
    });
    Ember.set(this, 'locations', locations);
  }), _dec8 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    try {
      var extraValidationData = {
        noValidate: multiStepForm.formCopiedModelsGetVal('userRepNumber.model.identifier') === '0' ? ['userRepNumber-identifier'] : []
      };

      if (this.args.formMode === 'create') {
        var toCreateObjects = yield multiStepForm.validateCreateAll({
          notificationNamespace: 'createUserRepNumber',
          extraValidationData
        });
        Ember.set(toCreateObjects.userRepNumber.data, 'user', this.args.user);
        var userRepNumber = this.store.createRecord('userRepNumber', toCreateObjects.userRepNumber.data);
        yield toCreateObjects.userRepNumber.saveWithRollbackAndNotification(userRepNumber);
      } else {
        yield multiStepForm.validateSyncSaveAll({
          notificationNamespace: 'createUserRepNumber',
          extraValidationData
        });
      }

      buttonPromise.then(() => {
        multiStepForm.formStartedSetVal(false);
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
      });
    } catch (err) {
      warn('Failed in User Rep Number Save: ' + err);
      throw err;
    }
  }), _dec9 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    try {
      var multiStepForm = this.args.multiStepForm;
      var userRepNumberToDelete = yield multiStepForm.formOriginalModelsGetVal('userRepNumber.model');
      yield userRepNumberToDelete.destroyRecordWithRollbackTask.perform();
      buttonPromise.then(() => {
        multiStepForm.formStartedSetVal(false);
        this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
      });
    } catch (err) {
      warn('Delete User Rep Number Failed: ' + err);
      throw err;
    }
  }), _dec10 = Ember._action, (_class = (_temp = class EditCreateRepNumber extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "usStates", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor4, this);

      _initializerDefineProperty(this, "grants", _descriptor5, this);

      _initializerDefineProperty(this, "confirmDeleteModalOpen", _descriptor6, this);

      _initializerDefineProperty(this, "initTask", _descriptor7, this);

      _initializerDefineProperty(this, "saveRepNumberTask", _descriptor8, this);

      _initializerDefineProperty(this, "deleteUserRepNumber", _descriptor9, this);

      this.initTask.perform();
    }

    get locationTypeOptions() {
      return [{
        value: 'location_id',
        name: this.intl.t('pages.users.roles.rep_number_location')
      }, {
        value: 'state',
        name: this.intl.t('pages.users.roles.rep_number_state')
      }];
    }

    get stateOptions() {
      var allStates = this.usStates['all'].sortBy('name');
      allStates.splice(0, 0, {
        name: this.intl.t('pages.users.roles.all_states'),
        value: 'All'
      });
      return allStates;
    }

    setupButtons() {
      var multiStepForm = this.args.multiStepForm;
      var buttons = [];

      if (this.args.formMode !== 'view') {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveRepNumberTask.perform(multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary rep-number-save-button'
        });
      }

      if (this.args.formMode === 'edit' && this.grants.getIndividualGrant('user_rep_numbers.delete')) {
        buttons.push({
          text: this.intl.t('common.actions.delete'),
          click: () => {
            this.confirmDeleteModalOpen = true;
          },
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
        });
      }

      multiStepForm.registerStepData('step1', 'buttons', buttons);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "usStates", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "confirmDeleteModalOpen", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "initTask", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "saveRepNumberTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "deleteUserRepNumber", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupButtons", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "setupButtons"), _class.prototype)), _class));
  _exports.default = EditCreateRepNumber;
});