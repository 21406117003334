define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/forms/adt/next-step/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency", "securitytrax-ember-ui/mixins/forms/adt", "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/customer-base"], function (_exports, _switchableFormStep, _emberConcurrency, _adt, _customerBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerBase.default.extend(_switchableFormStep.default, _adt.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    grants: Ember.inject.service(),
    canShell: false,
    customer: Ember.computed.alias('newCustomer'),
    dataLoaded: Ember.computed(function () {
      return this.initData.perform();
    }),
    initData: (0, _emberConcurrency.task)(function* () {
      var orderInfoGrant = yield this.grants.getIndividualGrant('customer_order_information.view');
      var monitorTypeId = orderInfoGrant ? yield this.get('newCustomer.customerOrderInformation.monitoringCompany.monitoringCompanyType.id') : null;

      if (monitorTypeId === '1') {
        var adtData = yield this.adtData;
        this.set('adtData', adtData);
        this.set('canShell', adtData && !(adtData.hasPreCheck !== true || 'failedLoadAdtData' in adtData && adtData.failedLoadAdtData === true));
      }
    }),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('nextStep', 'buttons', [{
        text: this.intl.t('common.actions.back'),
        classNamesString: 'st-btn st-btn-secondary',
        click: () => {
          return this.goBackTask.perform(multiStepForm);
        }
      }, {
        text: this.intl.t('common.actions.close'),
        classNamesString: 'st-btn st-btn-primary',
        positionOptions: 'up left',
        click: () => {
          this.set('formMode', 'create');
          multiStepForm.formStartedSetVal(false);
          this.transitionToCustomer(multiStepForm.formOriginalModelsGetVal('customer.model'));
          this.stNotificationManager.addSuccessKey('common.notifications.customer.created');
        },
        additionalClickActions: [{
          label: this.intl.t('common.actions.deleteCustomer'),
          action: () => {
            this.set('formMode', 'create');
            this.openConfirmDelete('nextStep');
          },
          classNamesString: 'dropdown-delete-customer'
        }]
      }]);
    },

    goBackTask: (0, _emberConcurrency.task)(function* (multiStepForm) {
      var prevStepName = this.multiStepForm.formOriginalModelsGetVal('workOrder.model') ? 'schedule' : 'workOrder';
      var prevStepNumber = this.multiStepForm.formOriginalModelsGetVal('workOrder.model') ? this.multiStepForm.progressBarConfig.activeIndex - 1 : this.multiStepForm.progressBarConfig.activeIndex - 2;

      if (prevStepName == 'schedule' && this.multiStepForm.formSteps['schedule']['hidden']) {
        prevStepName = 'workOrder';
        prevStepNumber = this.multiStepForm.progressBarConfig.activeIndex - 2;
      }

      if (prevStepName == 'schedule') {
        var calendarEvents = yield this.newCustomer.calendarEvents;
        var hasCalendarEvents = calendarEvents.length > 0;
        var scheduleSubStep = hasCalendarEvents ? 'listCalendarEvents' : 'scheduleCalendarEvent';
        prevStepName += '::' + scheduleSubStep;
      }

      Ember.set(multiStepForm, 'currentStepNameKey', prevStepName);
      Ember.set(multiStepForm, 'progressBarConfig.activeIndex', prevStepNumber);
    }),
    actions: {
      transitionToShell() {
        this.set('formMode', 'create');
        this.multiStepForm.formStartedSetVal(false);
        this.router.transitionTo('customers.information.manage-adt', this.multiStepForm.formOriginalModelsGetVal('customer.model.id'));
      }

    }
  });

  _exports.default = _default;
});