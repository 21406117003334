define("securitytrax-ember-ui/components/homepage/dashboard/open-funding-entries/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var OpenFundingEntries = (_dec = Ember.inject.service, _dec2 = (0, _emberConcurrency.task)(function* () {
    var progressBarWidth = 1;
    var url = yield this.stAjax.getUrl('/dashboard_cards/open_funding_entries');
    var focus = typeof this.args.config.card_focus === 'object' ? this.args.config.card_focus.value : this.args.config.card_focus;
    var openFundingEntries = this.args.useDefaultData ? this.defaultData : (yield this.stAjax.request(url, {
      method: 'GET',
      data: {
        focus_type: focus
      }
    })).data;
    Object.keys(openFundingEntries).forEach(key => {
      var value = openFundingEntries[key];

      if (key.includes('_count') && Number(value) > progressBarWidth) {
        progressBarWidth = Number(value);
      }
    });
    openFundingEntries['pending_entries_width'] = openFundingEntries.pending_count / progressBarWidth * 100;
    openFundingEntries['ready_entries_width'] = openFundingEntries.ready_count / progressBarWidth * 100;
    openFundingEntries['submitted_entries_width'] = openFundingEntries.submitted_count / progressBarWidth * 100;
    openFundingEntries['review_entries_width'] = openFundingEntries.review_count / progressBarWidth * 100;
    return openFundingEntries;
  }), (_class = (_temp = class OpenFundingEntries extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "stAjax", _descriptor, this);

      _initializerDefineProperty(this, "getFundingEntriesDataTask", _descriptor2, this);
    }

    get defaultData() {
      return {
        pending_count: '16',
        pending_outstanding: '595.70',
        ready_count: '10',
        ready_outstanding: '677.50',
        review_count: '4',
        review_outstanding: '760.99',
        submitted_count: '7',
        submitted_outstanding: '810.45',
        total_open_entries: '26',
        total_amount_outstanding: '2844.64'
      };
    }

    get fundingNumbers() {
      return this.getFundingEntriesDataTask.perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "stAjax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "getFundingEntriesDataTask", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = OpenFundingEntries;
});