define("securitytrax-ember-ui/components/calendar-glimmer/calendar-scheduling/schedulers/users/component", ["exports", "@glimmer/component", "ember-concurrency", "moment", "securitytrax-ember-ui/mixed-in-functions/duration-options", "lodash", "securitytrax-ember-ui/helpers/time-options"], function (_exports, _component, _emberConcurrency, _moment, _durationOptions, _lodash, _timeOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var SchedulerUsers = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service('events/calendar-events'), _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = (0, _emberConcurrency.task)(function* () {
    var formattedObjects = [];
    var calendarEventData = {
      calendarCategory: this.args.userType === 'sales_rep' ? 'sale_schedule' : 'tech_schedule',
      calendarEventType: this.currCalendarEventType,
      calendarEventDetailType: this.currCalendarEventDetailType,
      calendarUser: this.selectedTechs,
      startingDate: this.startingDate,
      endingDate: this.endingDate,
      startTime: this.startTime,
      duration: this.currDuration,
      days: this.days,
      notes: this.notes
    };
    formattedObjects.push(Ember.Object.create({
      model: calendarEventData,
      extraValidationData: {
        isBulk: true
      },
      filePath: 'calendar-event'
    }));
    yield this.args.multiStepForm.validateList.perform(formattedObjects);
    this.args.onAddNewCalendarEvent(calendarEventData);
  }), _dec17 = (0, _emberConcurrency.task)(function* () {
    var outerHashedData = yield (0, _emberConcurrency.hash)({
      finishedCopyModels: (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true),
      appointmentTypeDefaults: this.sessionData.getCompanyDefaults('appointmentTypeDefault'),
      loadingDurations: this.durationTask.perform()
    });
    Ember.set(this, 'appointmentTypeDefaults', outerHashedData.appointmentTypeDefaults);
    yield this.calcCalendarEventTypesTask.perform();

    if (this.args.duplicateAppointment) {
      var hashedData = yield (0, _emberConcurrency.hash)({
        calendarEventType: this.args.duplicateAppointment.get('calendarEventType'),
        calendarEventDetailType: this.args.duplicateAppointment.get('calendarEventDetailType')
      });
      this.currDuration = this.args.duplicateAppointment.get('duration');
      this.currCalendarEventType = hashedData.calendarEventType;
      this.currCalendarEventDetailType = hashedData.calendarEventDetailType;
    }

    var buttonObject = this.args.multiStepForm.currentStepData.buttons ? this.args.multiStepForm.currentStepData.buttons : [];
    var buttons = [];

    if (buttonObject.length > 0) {
      for (var x = 0; x < buttonObject.length; x++) {
        var button = buttonObject[x];

        if (button.id != 'schedule_button' && button.id !== 'save_continue' || button.id === 'save_continue' && this.args.selectedSchedulingProfileId != 6) {
          buttons.push(button);
        }
      }
    }

    if (this.args.selectedSchedulingProfileId == 6) {
      this.isBulkScheduler = true;
      var grants = this.grants;

      if (grants.getIndividualGrant('calendar_events.create')) {
        buttons.push({
          text: this.args.currFormName === 'customerCreateWizard' ? this.intl.t('common.actions.saveAndContinue') : this.intl.t('common.actions.schedule'),
          click: () => {
            return this.openScheduleAppointmentModalTask.perform();
          },
          id: 'schedule_button',
          classNamesString: "st-btn st-btn-primary save modal-action-submit save-work-order-btn ".concat(this.formMode === 'view' ? 'hidden' : '')
        });
      }
    }

    this.args.multiStepForm.registerStepData(this.args.multiStepForm.currentStepNameKey, 'buttons', buttons);
  }), _dec18 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this, 'currCalendarEventType', type => type != undefined);
    var calendarEventId = this.currCalendarEventType.id;
    var calendarEventDetailTypeOptions = [];

    if (this.args.formMode === 'create') {
      this.currCalendarEventDetailType = null;
    }

    var asyncData = yield (0, _emberConcurrency.hash)({
      selectedCalendarEventType: this.store.findRecord('calendarEventType', calendarEventId),
      company: this.sessionData.getCompany()
    });
    var asyncData2 = yield (0, _emberConcurrency.hash)({
      listOfCalendarEventDetailTypes: asyncData.selectedCalendarEventType.get('calendarEventDetailTypes'),
      calendarEventDetailTypeDefaults: asyncData.company.get('calendarEventDetailTypeDefaults')
    });
    calendarEventDetailTypeOptions = asyncData2.listOfCalendarEventDetailTypes;
    var defaultCalendarEventDetailTypeCamelKey = asyncData.selectedCalendarEventType.get('nameAsKeyCamel') + 'SubtypeId';
    var defaultCalendarEventDetailTypeId = asyncData2.calendarEventDetailTypeDefaults[defaultCalendarEventDetailTypeCamelKey];

    if (!this.args.duplicateAppointment && defaultCalendarEventDetailTypeId) {
      var defaultCalendarEventDetailType = yield this.store.findRecord('calendarEventDetailType', defaultCalendarEventDetailTypeId);
      this.currCalendarEventDetailType = defaultCalendarEventDetailType; // also set the duration if the chosen subtype has a default duration

      if (this.currCalendarEventDetailType.duration) {
        this.currDuration = this.currCalendarEventDetailType.duration;
      }
    }

    return calendarEventDetailTypeOptions;
  }), _dec19 = (0, _emberConcurrency.task)(function* () {
    var calendarEventTypes = yield this.store.findAll('calendar-event-type');
    var filteredCalendarEventTypes = [];
    var calendarEventTypeIncludeCategories = this.calendarEventTypeIncludeCategories;
    calendarEventTypes.forEach(calendarEventType => {
      if (calendarEventType.get('status') === 'active') {
        if (calendarEventTypeIncludeCategories[calendarEventType.get('calendarCategory')]) {
          if (calendarEventType.nameAsKey != 'timeoff') {
            filteredCalendarEventTypes.push(calendarEventType);
          }
        }
      }
    });
    var sortedCalendarEventTypes = yield filteredCalendarEventTypes.sortBy('name');

    if (this.args.duplicateAppointment) {
      this.currCalendarEventType = this.args.duplicateAppointment.get('calendarEventType');
    } else {
      // set the default currCalendarEventType to the first filteredCalendarEventTypes
      this.setCalendarEventTypeAndCalcDefaultDuration(sortedCalendarEventTypes[0]); // set the currCalendarEventType from the workOrderType if the names match
      // DREW TODO - this will need to be updated when / if there is a change made to the form defaults to take that into account

      if (this.args.multiStepForm.formOriginalModels.workOrder && this.args.multiStepForm.formOriginalModels.workOrder.model.id) {
        var workOrderType = yield this.args.multiStepForm.formOriginalModels.workOrder.model.workOrderType;
        var foundType = yield filteredCalendarEventTypes.findBy('name', workOrderType.name);

        if (foundType && foundType.name) {
          this.setCalendarEventTypeAndCalcDefaultDuration(foundType);
        }
      }
    }

    this.calendarEventTypes = sortedCalendarEventTypes;
  }), _dec20 = (0, _emberConcurrency.task)(function* () {
    var selectedSchedulingProfile = yield this.args.selectedSchedulingProfile;
    var options = [];

    if (selectedSchedulingProfile && selectedSchedulingProfile.responseMeta) {
      selectedSchedulingProfile.responseMeta.list_techs.forEach(listTech => {
        options.push({
          name: this.intl.t('pages.customers.appointments.show_list.' + listTech),
          value: listTech
        });
      });
    }

    return options;
  }), _dec21 = (0, _emberConcurrency.task)(function* () {
    if (!this.args.duplicateAppointment) {
      var location = yield this.args.customer.get('location');

      if (location) {
        var duration = yield location.get('techScheduleDefaultDuration');

        if (duration) {
          this.currDuration = duration;
          return;
        }
      }

      this.currDuration = 7200;
    }
  }), _dec22 = Ember.computed('currShowOption'), _dec23 = (0, _emberConcurrency.task)(function* () {
    var selectedSchedulingProfile = yield this.args.selectedSchedulingProfile;

    try {
      var hashData = yield (0, _emberConcurrency.hash)({
        customerId: this.args.customer.get('id'),
        calendarDate: this.calendarDate,
        currDuration: this.currDuration
      });

      if (selectedSchedulingProfile) {
        var profileUsers = yield selectedSchedulingProfile.invokeAction('get_scheduler_availability', {
          performLinked: true,
          verb: 'POST',
          processResponse: true,
          data: {
            data: {
              relationships: {
                customer: {
                  data: {
                    id: hashData.customerId,
                    type: 'customers'
                  }
                }
              },
              attributes: {
                users_data: {
                  filter_date: hashData.calendarDate.format('MM/DD/YYYY'),
                  duration: hashData.currDuration,
                  calendar_category: this.args.userType === 'tech' ? 'tech_schedule' : 'sale_schedule',
                  user_list_by: this.currShowOption
                }
              }
            }
          }
        });

        var theNewUsers = _lodash.default.sortBy(profileUsers.models, [function (user) {
          return user.lname.toLowerCase();
        }]);
        /*  if (theNewUsers.length != this.newUsers.length) {
         } */


        this.CalendarEventsService.trigger('updateUserList');
        return theNewUsers; //lodash sort  A-Za-z
      } else {
        throw new Error(this.intl.t('pages.calendar.no_slot_schedulers'));
      }
    } catch (error) {
      (false && Ember.warn('failed to get users for calendar' + error, {
        id: 'calendar.calendarScheduling.schedulers.users.usersTask'
      }));
      this.stNotificationManager.addErrorMessages(error, {
        notificationNamespace: 'work-order-edit-appointment'
      });
    }

    return [];
  }), _dec24 = (0, _emberConcurrency.task)(function* () {
    var groupData = {};
    groupData.rowData = [];
    groupData.columnHeaders = [this.intl.t('common.columns.name')];
    groupData.columnHeaderClasses = ['small-12 medium-11'];
    var techs = yield this.newUsers;
    techs.forEach(tech => {
      var lfname = tech.get('lname') + ', ' + tech.get('fname');
      groupData.rowData.push(Ember.Object.create({
        selectedLabel: lfname,
        columnData: [lfname],
        bodyItemClasses: ['small-12 medium-11'],
        model: tech
      }));
    });
    return groupData;
  }), _dec25 = Ember.computed('searchString', 'currShowOption'), _dec26 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.timeout)(500); //make sure they are done typing before searching

    var rawUserList = yield this.newUsers;
    var filteredUserList;

    if (this.searchString) {
      var searchUpper = this.searchString.toUpperCase();
      filteredUserList = rawUserList.filter(tech => tech.get('fname').toUpperCase().includes(searchUpper) || tech.get('lname').toUpperCase().includes(searchUpper));
    } else {
      filteredUserList = rawUserList;
    }

    this.groupData.rowData = [];
    filteredUserList.forEach(tech => {
      var lfname = tech.get('lname') + ', ' + tech.get('fname');
      this.groupData.rowData.push(Ember.Object.create({
        selectedLabel: lfname,
        columnData: [lfname],
        bodyItemClasses: ['small-12 medium-11'],
        model: tech,
        inAddArray: _lodash.default.find(this.selectedTechs, {
          selectedLabel: lfname
        })
      }));
    });
    return this.groupData;
  }).restartable(), _dec27 = Ember._action, _dec28 = Ember._action, _dec29 = Ember._action, _dec30 = Ember._action, _dec31 = Ember._action, _dec32 = Ember._action, (_class = (_temp = class SchedulerUsers extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "grants", _descriptor2, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);

      _initializerDefineProperty(this, "sessionData", _descriptor5, this);

      _initializerDefineProperty(this, "CalendarEventsService", _descriptor6, this);

      _initializerDefineProperty(this, "AppointmentTimeService", _descriptor7, this);

      _initializerDefineProperty(this, "currDuration", _descriptor8, this);

      _initializerDefineProperty(this, "currShowOption", _descriptor9, this);

      _initializerDefineProperty(this, "currCalendarEventType", _descriptor10, this);

      _initializerDefineProperty(this, "currCalendarEventDetailType", _descriptor11, this);

      _initializerDefineProperty(this, "appointmentTypeDefaults", _descriptor12, this);

      _initializerDefineProperty(this, "selectedTechs", _descriptor13, this);

      _initializerDefineProperty(this, "startingDate", _descriptor14, this);

      _initializerDefineProperty(this, "endingDate", _descriptor15, this);

      _defineProperty(this, "mainHeaderOffset", 0);

      _defineProperty(this, "calendarDate", undefined);

      _defineProperty(this, "searchCalendarDate", undefined);

      _defineProperty(this, "showCalendar", undefined);

      _defineProperty(this, "calendarEventTypes", undefined);

      _defineProperty(this, "baseGridMillis", _moment.default.duration(30, 'minutes').asMilliseconds());

      _defineProperty(this, "calendarEventTypeIncludeCategories", undefined);

      _initializerDefineProperty(this, "openScheduleAppointmentModalTask", _descriptor16, this);

      _initializerDefineProperty(this, "initAppointmentDataTask", _descriptor17, this);

      _initializerDefineProperty(this, "setupCalendarEventDetailTypeOptions", _descriptor18, this);

      _initializerDefineProperty(this, "calcCalendarEventTypesTask", _descriptor19, this);

      _initializerDefineProperty(this, "showListOptionsTask", _descriptor20, this);

      _initializerDefineProperty(this, "durationTask", _descriptor21, this);

      _initializerDefineProperty(this, "newUsersTask", _descriptor22, this);

      _initializerDefineProperty(this, "techGroupDataTask", _descriptor23, this);

      _initializerDefineProperty(this, "updateSearchTask", _descriptor24, this);

      this.calendarDate = (0, _moment.default)();
      this.searchCalendarDate = (0, _moment.default)();
      this.showCalendar = false;
      this.currDuration = 7200;
      this.calendarEventTypeIncludeCategories = {
        'tech_schedule': this.args.userType === 'tech',
        'sale_schedule': this.args.userType === 'sales_rep'
      };
      this.days = [];
      this.startMinDate = (0, _moment.default)();
      this.endMinDate = (0, _moment.default)().add(1, 'days');
      this.groupData = {};
      this.groupData.columnHeaders = [this.intl.t('common.columns.name')];
      this.groupData.columnHeaderClasses = ['small-12 medium-11'];
      this.addTechModalTitle = this.intl.t('pages.customers.appointments.bulk_add_tech_modal_title', {
        flname: this.args.customer.get('flname')
      });
      this.initAppointmentDataTask.perform();
    }

    get eventTypeIsDisabled() {
      if (this.args.userType === 'sales_rep') {
        return true;
      }

      return false;
    }

    get filteredTimeOptions() {
      return (0, _timeOptions.timeOptions)([30, this.AppointmentTimeService.startTime, this.AppointmentTimeService.endTime, 'h:mm a', 'h:mm a']);
    }

    get durationOptions() {
      return (0, _durationOptions.default)(this.AppointmentTimeService.startTime);
    }

    get disabledDays() {
      var _disabledDays = {};
      var count = 7;
      var dayOfWeek = (0, _moment.default)(this.startingDate).startOf('day');

      while (count > 0) {
        if (this.endingDate && dayOfWeek.isSameOrBefore(this.endingDate)) {
          _disabledDays[dayOfWeek.format('dddd').toLowerCase()] = false;
        } else {
          _disabledDays[dayOfWeek.format('dddd').toLowerCase()] = true;
        }

        count--;
        dayOfWeek.add(1, 'days');
      }

      return _disabledDays;
    }

    get doneButton() {
      return [{
        text: this.intl.t('common.actions.done'),
        click: () => {
          Ember.set(this, 'techModalStart', false);
        },
        classNamesString: 'st-btn st-btn-primary'
      }];
    }

    get techListError() {
      return _lodash.default.get(this.args.multiStepForm, 'validationErrors.calendarEvent-calendarUser', null);
    }

    get calendarEventDetailTypeOptions() {
      return this.setupCalendarEventDetailTypeOptions.perform();
    }

    get showListOptions() {
      return this.showListOptionsTask.perform();
    }

    get newUsers() {
      return this.newUsersTask.perform();
    }

    get searchResults() {
      return this.updateSearchTask.perform();
    }

    setCalendarEventTypeAndCalcDefaultDuration(calendarEventType) {
      var newDuration = Number(this.appointmentTypeDefaults.get(calendarEventType.get('name').toLowerCase() + 'AppointmentDuration'));
      this.currCalendarEventType = calendarEventType;

      if (newDuration) {
        this.currDuration = newDuration;
      }
    }

    updateUserList() {
      Ember.notifyPropertyChange(this, 'newUsers');
    }

    changedCalendarEventDetailType(newType) {
      if (newType && newType.duration) {
        this.currDuration = newType.duration;
      }
    }

    checkDates(isStartingDate) {
      var today = (0, _moment.default)();

      if (isStartingDate) {
        if (this.startingDate.isBefore(today)) {
          this.startingDate = today;
        }

        if (this.endingDate && this.endingDate.isSameOrBefore(this.startingDate)) {
          this.endingDate = this.startingDate.clone().add(1, 'days');
        }
      } else {
        if (this.endingDate.isSameOrBefore(today)) {
          this.endingDate = today.add(1, 'days');
        }

        if (this.startingDate && this.startingDate.isSameOrAfter(this.endingDate)) {
          this.startingDate = this.endingDate.clone().subtract(1, 'days');
        }
      }
    }

    removeTech(item) {
      this.selectedTechs = this.selectedTechs.filter(tech => {
        return item.columnData[0] != tech.columnData[0];
      });
      Ember.set(item, 'inAddArray', false);
    }

    updateSelectedArray() {
      Ember.notifyPropertyChange(this, 'selectedTechs');

      var error = _lodash.default.get(this.args.multiStepForm, 'validationErrors', null);

      if (error && error['calendarEvent-calendarUser']) {
        delete error['calendarEvent-calendarUser'];
      }

      Ember.notifyPropertyChange(this, 'techListError');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "CalendarEventsService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "AppointmentTimeService", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "currDuration", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "currShowOption", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'all';
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "currCalendarEventType", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "currCalendarEventDetailType", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "appointmentTypeDefaults", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "selectedTechs", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "startingDate", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "endingDate", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "openScheduleAppointmentModalTask", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "initAppointmentDataTask", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "setupCalendarEventDetailTypeOptions", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "calcCalendarEventTypesTask", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "showListOptionsTask", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "durationTask", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "newUsers", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "newUsers"), _class.prototype), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "newUsersTask", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "techGroupDataTask", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "searchResults", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "searchResults"), _class.prototype), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "updateSearchTask", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setCalendarEventTypeAndCalcDefaultDuration", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "setCalendarEventTypeAndCalcDefaultDuration"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateUserList", [_dec28], Object.getOwnPropertyDescriptor(_class.prototype, "updateUserList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changedCalendarEventDetailType", [_dec29], Object.getOwnPropertyDescriptor(_class.prototype, "changedCalendarEventDetailType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkDates", [_dec30], Object.getOwnPropertyDescriptor(_class.prototype, "checkDates"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeTech", [_dec31], Object.getOwnPropertyDescriptor(_class.prototype, "removeTech"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateSelectedArray", [_dec32], Object.getOwnPropertyDescriptor(_class.prototype, "updateSelectedArray"), _class.prototype)), _class));
  _exports.default = SchedulerUsers;
});