define("securitytrax-ember-ui/components/multi-step-form/forms/accounting/invoice-statements/send-customer-statement/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sdRWeqCg",
    "block": "{\"symbols\":[\"&attrs\",\"@multiStepForm\"],\"statements\":[[11,\"div\"],[17,1],[12],[2,\"\\n    \"],[8,\"multi-step-form/progress-bar\",[],[[\"@config\",\"@modalButtons\"],[[32,0,[\"SwitchableFormStep\",\"progressBarConfig\"]],[32,2,[\"currentStepData\",\"buttons\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"html/st-await\",[],[[\"@useSpinner\",\"@booleanLoaded\"],[true,[34,0,[\"isIdle\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"multi-step-form/forms/send\",[],[[\"@multiStepForm\",\"@sendModel\",\"@defaultToAddress\",\"@modelName\",\"@sendSubjectType\",\"@sendSubjectId\",\"@context\",\"@sendMeta\"],[[32,2],[32,2,[\"formOriginalModels\",\"invoiceStatement\",\"model\"]],[32,0,[\"toAddresses\"]],\"invoice_statement\",[30,[36,1],[\"pages.customers.accounting.statements.statement\"],null],[32,2,[\"formOriginalModels\",\"invoiceStatement\",\"model\",\"id\"]],\"editCustomerStatement\",[30,[36,2],null,[[\"as_html\",\"template_id\"],[false,4]]]]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"initFormTask\",\"t\",\"hash\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/accounting/invoice-statements/send-customer-statement/template.hbs"
    }
  });

  _exports.default = _default;
});