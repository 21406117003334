define("securitytrax-ember-ui/components/modals/sub-route-modals/manage-brinks/component", ["exports", "@glimmer/component", "ember-concurrency", "lodash"], function (_exports, _component, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var ManageBrinks = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = (0, _emberConcurrency.task)(function* () {
    var queryParams = _lodash.default.get(this, 'router.currentRoute.parent.queryParams', false);

    if (queryParams && queryParams.toContract) {
      this.currentStepNameKey = 'createEcontract';
    } else if (queryParams && queryParams.toHistory) {
      this.currentStepNameKey = 'history';
    } else {
      this.currentStepNameKey = 'account';
    }

    var monitoringCompany = yield this.args.monitoringCompany.get('monitoringCompanyType');
    this.formMode = (yield this.args.customerSystemInformation.get('centralStationId')) ? 'edit' : 'create';
    this.titleData = {
      monitorTypeName: monitoringCompany.get('name'),
      customerName: this.args.customer.flname,
      id: this.args.customer.id
    };
    this.hasProgressBar = this.formMode === 'create' ? false : true;
    this.formStarted = true;
  }), _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, (_class = (_temp = class ManageBrinks extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "grants", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "formStarted", _descriptor4, this);

      _initializerDefineProperty(this, "formMode", _descriptor5, this);

      _initializerDefineProperty(this, "hasProgressBar", _descriptor6, this);

      _initializerDefineProperty(this, "currentStepNameKey", _descriptor7, this);

      _defineProperty(this, "titleData", undefined);

      _initializerDefineProperty(this, "initDataTask", _descriptor8, this);

      this.initDataTask.perform();
    }

    get formOriginalModel() {
      var grants = this.grants;
      return Ember.Object.create({
        customer: {
          model: this.args.customer,
          copyRelationMap: {
            customerSystemInformation: {
              doCopy: grants.getIndividualGrant('customer_system_information.view'),
              doValidate: false,
              doSave: grants.getIndividualGrant('customer_system_information.modify'),
              relationships: {
                customerAbortCode: {
                  doCopy: grants.getIndividualGrant('customer_abort_codes.view'),
                  doValidate: grants.getIndividualGrant('customer_abort_codes.view'),
                  doSave: grants.getIndividualGrant('customer_abort_codes.modify')
                }
              }
            },
            customerOrderInformation: {
              readOnly: true,
              relationships: {
                monitoringCompany: {
                  readOnly: true,
                  relationships: {
                    monitoringCompanyConfig: {
                      readOnly: true
                    }
                  }
                },
                contractTerm: {
                  readOnly: true
                }
              }
            },
            customerContacts: {
              readOnly: true
            }
          }
        }
      });
    }

    get formSteps() {
      var title = this.formMode == 'create' ? this.intl.t('pages.customers.system.order.bold_create_title', this.titleData) : this.intl.t('pages.customers.system.order.title', this.titleData);
      return Ember.Object.create({
        account: Ember.Object.create({
          stepNameKey: 'account',
          title: title,
          stepComponentPath: 'multi-step-form/forms/system/brinks/create-edit-account',
          progressBarTitle: this.intl.t('pages.customers.system.order.account')
        }),
        tests: Ember.Object.create({
          stepNameKey: 'tests',
          title: title,
          stepComponentPath: 'multi-step-form/forms/system/brinks/tests',
          progressBarTitle: this.intl.t('pages.customers.system.order.tests')
        }),
        history: Ember.Object.create({
          stepNameKey: 'history',
          title: _lodash.default.get(this, 'router.currentRoute.parent.queryParams.toHistory', false) ? this.intl.t('pages.customers.system.order.eventHistories') : title,
          stepComponentPath: 'multi-step-form/forms/system/brinks/history',
          progressBarTitle: this.intl.t('pages.customers.system.order.history')
        }),
        creditRequest: Ember.Object.create({
          stepNameKey: 'creditRequest',
          title: title,
          stepComponentPath: 'multi-step-form/forms/system/brinks/credit-request',
          progressBarTitle: this.intl.t('pages.customers.system.order.creditRequest')
        }),
        purchaseInfo: Ember.Object.create({
          stepNameKey: 'purchaseInfo',
          title: title,
          stepComponentPath: 'multi-step-form/forms/system/brinks/purchase-info',
          progressBarTitle: this.intl.t('pages.customers.system.order.purchaseInfo')
        }),
        createEcontract: Ember.Object.create({
          stepNameKey: 'createEcontract',
          title: this.intl.t('pages.customers.system.order.createEcontractTitle', this.titleData),
          stepComponentPath: 'multi-step-form/forms/system/brinks/create-econtract',
          progressBarTitle: 'hidden',
          granted: false
        })
      });
    }

    transitionBackToParentRoute() {
      this.router.transitionTo(this.args.parentRoute, this.args.transitionId);
    }

    afterSave() {
      this.formMode = 'edit';
    }

    setHasProgressBar(input) {
      this.hasProgressBar = input;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "formStarted", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "formMode", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'edit';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "hasProgressBar", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "currentStepNameKey", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "transitionBackToParentRoute", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "transitionBackToParentRoute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "afterSave", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "afterSave"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setHasProgressBar", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "setHasProgressBar"), _class.prototype)), _class));
  _exports.default = ManageBrinks;
});