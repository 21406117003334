define("securitytrax-ember-ui/models/customer-at-risk-resolution", ["exports", "securitytrax-ember-ui/models/base_models/customer-at-risk-resolution"], function (_exports, _customerAtRiskResolution) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerAtRiskResolution.default.extend({});

  _exports.default = _default;
});