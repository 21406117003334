define("securitytrax-ember-ui/components/multi-step-form/forms/customer-equipment/add-equipment/copy-from-package/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer-equipment/customer-equipment-base", "ember-concurrency"], function (_exports, _customerEquipmentBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerEquipmentBase.default.extend({
    defaultEquipmentStatus: null,
    store: Ember.inject.service(),
    initTask: (0, _emberConcurrency.task)(function* () {
      yield this.initBaseData.perform();
      this.set('newLineItems', Ember.A([]));
      this.newLineItems.pushObject(Ember.Object.create({
        quantity: 1,
        itemCharge: 0
      }));
    }).on('init'),
    packages: Ember.computed(function () {
      return this.store.findAll('package', {
        include: 'package_equipment,package_equipment.equipment'
      });
    }),
    selectedPackageId: Ember.computed('packages', function () {
      return this.selectedPackageIdTask.perform();
    }),
    selectedPackageIdTask: (0, _emberConcurrency.task)(function* () {
      var packages = yield this.packages;
      return packages.objectAt(0).get('id');
    }),
    reducedPackageEquipmentList: Ember.computed('packages', function () {
      return this.reducedPackageEquipmentListTask.perform();
    }),
    reducedPackageEquipmentListTask: (0, _emberConcurrency.task)(function* () {
      var packageToEquipmentCount = Ember.A([]);
      var packages = yield this.packages; // PERF TODO: Make sure we have relationships at this point whether from includes or not.

      packages.forEach(function (currPackage) {
        currPackage.get('packageEquipment').then(function (pkEquipmentList) {
          var currPkEquipmentList = {};

          if (pkEquipmentList.get('length') > 0) {
            pkEquipmentList.forEach(function (currPkEquipment) {
              if (!currPkEquipmentList[currPkEquipment.get('equipment.name')]) {
                currPkEquipmentList[currPkEquipment.get('equipment.name')] = currPkEquipment.get('quantity');
              } else {
                currPkEquipmentList[currPkEquipment.get('equipment.name')] += currPkEquipment.get('quantity');
              }
            });
            var currPkEquipmentListEmberArr = Object.keys(currPkEquipmentList).map(equipmentName => {
              return Ember.Object.create({
                name: equipmentName,
                quantity: currPkEquipmentList[equipmentName]
              });
            });
            packageToEquipmentCount.pushObject(Ember.Object.create({
              packageName: currPackage.get('name'),
              packageId: currPackage.get('id'),
              packageContent: currPkEquipmentListEmberArr
            }));
          }
        });
      });
      return packageToEquipmentCount;
    }),

    saveCustomerEquipment(multiStepForm, buttonPromise) {
      return this.createFromPackageTask.perform(multiStepForm, buttonPromise);
    },

    createFromPackageTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      multiStepForm.formCopiedModelsSetVal('customerEquipment.model.workOrderAction', this.workOrderAction);
      yield this.multiStepForm.deepValidateAll.perform({
        deepValidate: false,
        extraValidationData: {
          skipEquipment: true,
          skipItemCharge: true,
          skipQuantity: true,
          noValidate: this.workOrder ? ['zoneNumber', 'zone'] : ['workOrderAction', 'zoneNumber', 'zone']
        },
        notificationNamespace: 'modal'
      });
      var equipmentStatus = this.defaultEquipmentStatus;
      var user = multiStepForm.formCopiedModelsGetVal('customerEquipment.model.user');
      var packages = yield this.packages;
      var selectedPackage = packages.findBy('id', yield this.selectedPackageId);
      var pkEquipmentList = yield selectedPackage.get('packageEquipment');
      var savedEquipment = pkEquipmentList.map(currPkEquipment => {
        var allEquipmentSaves = [];

        for (var i = 0; i < currPkEquipment.get('quantity'); i++) {
          var customerEquipment = this.store.createRecord('customerEquipment', {
            customer: this.customer,
            equipment: currPkEquipment.get('equipment'),
            equipmentWireType: currPkEquipment.get('equipmentWireType'),
            user: user,
            quantity: 1,
            itemCharge: currPkEquipment.get('itemCharge'),
            createdAt: new Date(),
            equipmentStatus: equipmentStatus
          });
          allEquipmentSaves.push(customerEquipment.save());
        }

        return (0, _emberConcurrency.all)(allEquipmentSaves);
      });
      var allSavedEquipment = yield (0, _emberConcurrency.hash)({
        savedFromPackage: (0, _emberConcurrency.all)(savedEquipment),
        additionalSaved: this.newLineItems.get('length') > 1 ? this.createFromManualMultipleTask.perform(multiStepForm, buttonPromise) : null
      });

      if (this.workOrder) {
        var allSaves = [];
        allSavedEquipment.savedFromPackage.forEach(packageEquipment => {
          packageEquipment.forEach(ce => {
            allSaves.push(this.createWorkOrderEquipment.perform(ce));
          });
        });
        yield (0, _emberConcurrency.all)(allSaves);
      }

      buttonPromise.then(() => {
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        this.get('customer.customerEquipment').reload();
        multiStepForm.onAfterSave();

        if (this.workOrder) {
          this.techToday.trigger('refresh-work-order-equipment');
        }
      }, {}, 'Resolve button promise on adding equipment from package');
    })
  });

  _exports.default = _default;
});