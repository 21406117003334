define("securitytrax-ember-ui/transforms/date-only", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Takes care of stripping out the time for date only fields.
   * For deserialize, first ensure format. If format is weird, convert to moment obect removing all time
   * Set the date to current users timezone so no further localization happens via moment
   */
  var _default = _emberData.default.DateTransform.extend({
    deserialize(serialized) {
      if (serialized) {
        var serializedFormat = serialized;

        var timezone = _moment.default.tz.guess();

        var expectedFormat = (0, _moment.default)(serializedFormat, 'YYYY-MM-DD', true).isValid();

        if (!expectedFormat) {
          serializedFormat = (0, _moment.default)(serializedFormat, 'YYYY-MM-DD');
        }

        return _moment.default.tz(serializedFormat, 'YYYY-MM-DD', timezone);
      }

      return null;
    },

    serialize(deserialized) {
      if (deserialized) {
        return (0, _moment.default)(deserialized, 'MM/DD/YYYY').format('YYYY-MM-DD');
      }

      return null;
    }

  });

  _exports.default = _default;
});