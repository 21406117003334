define("securitytrax-ember-ui/demo/flex/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller.extend({
    stNotificationManager: Ember.inject.service(),
    readonlymode: true,
    togglebool: true,
    formSteps: Ember.computed(function () {
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: 'Step # 1',
          stepComponentPath: 'multi-step-form/forms/demo/demo-paper-wizard/step-1'
        }),
        step2: Ember.Object.create({
          stepNameKey: 'step2',
          title: 'Step # 2',
          stepComponentPath: 'multi-step-form/forms/demo/demo-paper-wizard/step-2'
        }),
        step3: Ember.Object.create({
          stepNameKey: 'step3',
          title: 'Step # 3',
          stepComponentPath: 'multi-step-form/forms/demo/demo-paper-wizard/step-3'
        }),
        step4: Ember.Object.create({
          stepNameKey: 'step4',
          title: 'Step # 4',
          stepComponentPath: 'multi-step-form/forms/demo/demo-paper-wizard/step-4'
        })
      });
    }),
    formOriginalModels: Ember.computed(function () {
      return Ember.Object.create({
        customer: {
          model: this.get('model.customer') // additionalModelAttributes: []  // this will be added to each of these objects for copy model

        },
        equipment: {
          model: this.get('model.equipment')
        }
      });
    }),
    formStarted: false,
    fullScreenStarted: false,
    actions: {
      startMultiStepForm() {
        this.set('formStarted', true);
      },

      edit() {
        this.set('readonlymode', false);
      },

      submit() {
        this.get('model.customer').save();
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        this.set('readonlymode', true);
      },

      cancel() {
        var model = this.get('model.customer');
        this.set('readonlymode', true);
        model.rollbackAttributes();
      }

    }
  });
  var _default = Controller;
  _exports.default = _default;
});