define("securitytrax-ember-ui/services/credit-window", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Service to open credit report in a new tab. This code was copied from the map-window service. 
   * If this type of service is needed again, we can can abtract them all into one service
   *
   * @class CreditWindow
   * @extends Ember.Service
    * @memberof services
    * @mixes Ember.Evented
    */
  var _default = Ember.Service.extend({
    openWindow(creditReportId) {
      var {
        rootURL
      } = Ember.getOwner(this).lookup('router:main');
      window.open("".concat(rootURL, "customer_credit/").concat(creditReportId), '_blank', 'toolbar=no, location=no, status=no, menubar=no, scrollbars=yes, resizable=yes');
    }

  });

  _exports.default = _default;
});