define("securitytrax-ember-ui/models/inventory-user", ["exports", "securitytrax-ember-ui/models/base_models/inventory-user"], function (_exports, _inventoryUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _inventoryUser.default.extend({
    inventoryRecipientClass: '100',
    name: Ember.computed('lfname', function () {
      return this.lfname;
    }),
    flname: Ember.computed('fname', 'lname', function () {
      return "".concat(this.fname || '', " ").concat(this.lname || '');
    }),
    lfname: Ember.computed('fname', 'lname', function () {
      return "".concat(this.lname || '', ", ").concat(this.fname || '');
    }),
    address: Ember.computed('shippingStreet', 'shippingCity', 'shippingState', 'shippingZip', function () {
      return (this.shippingStreet || '') + (this.shippingCity ? ' ' + this.shippingCity : '') + (this.shippingState ? ', ' + this.shippingState : '') + (this.shippingZip ? ' ' + this.shippingZip : '');
    }),
    inventorySinkNameDescription: Ember.computed('lfname', function () {
      return this.lfname + ' (' + this.intl.t('common.actions.searchOptions.resources.user') + ')';
    })
  });

  _exports.default = _default;
});