define("securitytrax-ember-ui/components/nav/left-nav-link/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.LinkComponent.extend({
    navigation: Ember.inject.service(),
    tagName: 'div',
    classNames: ['nav-item'],

    click() {
      Ember.run.scheduleOnce('afterRender', this, this.navigationCloseLeft);
    },

    navigationCloseLeft() {
      this.navigation.closeLeft();
    }

  });

  _exports.default = _default;
});