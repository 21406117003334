define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/partner-companies/leads/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/partner-companies/base-partner-companies"], function (_exports, _basePartnerCompanies) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basePartnerCompanies.default.extend({
    intl: Ember.inject.service(),
    queuePriorities: Ember.computed(function () {
      return [{
        name: 'Normal',
        value: 0
      }, {
        name: 'Medium',
        value: 1
      }, {
        name: 'High',
        value: 2
      }];
    }),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;

      if (!this.isNewRecord) {
        multiStepForm.registerStepData("leads", 'buttons', [{
          text: this.intl.t('common.actions.cancel'),
          click: () => {
            multiStepForm.resetForm(false);
            this.set('leadsFormMode', 'view');
          },
          classNamesString: 'st-btn btn-secondary partner-company-cancel-button'
        }, {
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveLeadCompanyTask.perform(multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary partner-company-save-button'
        }]);
      }
    }

  });

  _exports.default = _default;
});