define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/electronic-document-providers/integrations/component", ["exports", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step"], function (_exports, _component, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class EdocProviderIntegrations extends _component.default {
    constructor() {
      super(...arguments);
      this.SwitchableFormStep = new _switchableFormStep.default(...arguments, {
        disabledProgressBarSteps: this.disabledProgressBarSteps,
        hiddenProgressBarSteps: this.hiddenProgressBarSteps
      });
    }

    get disabledProgressBarSteps() {
      return [];
    }

    get hiddenProgressBarSteps() {
      return [];
    }

    get formComponent() {
      var eDocProviderType = this.args.multiStepForm.formCopiedModelsGetVal('electronicDocumentProvider.model.electronicDocumentProviderType.name');
      var formComponent = 'multi-step-form/forms/administration/integrations/electronic-document-providers/integrations/';

      switch (eDocProviderType) {
        case 'DocuSign':
          formComponent += 'docusign/settings';
          break;

        case 'PandaDoc':
          formComponent += 'pandadoc/settings';
          break;
      }

      return formComponent;
    }

  }

  _exports.default = EdocProviderIntegrations;
});