define("securitytrax-ember-ui/demo/svg-components/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7XdOUDPX",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,6],null,[[\"includeCard\"],[true]],[[\"default\"],[{\"statements\":[[2,\"   \"],[1,[30,[36,2],null,[[\"sliderValue\",\"showScore\"],[[35,1],[35,0]]]]],[2,\"\\n\"],[2,\"    \"],[1,[30,[36,4],null,[[\"data\"],[[35,3]]]]],[2,\"\\n    \"],[1,[34,5]],[2,\"\\n\\n\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"showScore\",\"sliderValue\",\"html/svg-components/credit-score-scale-d3\",\"authorData\",\"html/svg-components/simple-bar-chart\",\"html/svg-components/simple-circles\",\"pages/demo-page\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/demo/svg-components/template.hbs"
    }
  });

  _exports.default = _default;
});