define("securitytrax-ember-ui/customer-credit/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _authenticatedRouteMixin, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _routePageviewLogging.default, {
    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform();
    },

    model({
      id
    }) {
      return this.store.findRecord('customerCreditCheckPrintImage', id);
    },

    getRouteName() {
      return "pages.customers.nav.".concat(this.routeName.split('.').pop());
    },

    setupController() {
      this._super(...arguments);

      this.controllerFor('application').set('hideLayout', true);
    }

  });

  _exports.default = _default;
});