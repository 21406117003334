define("securitytrax-ember-ui/models/base_models/user-hierarchy", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    about: (0, _model.attr)('string', {
      'readOnly': false
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    userHierarchyTree: (0, _model.belongsTo)('user-hierarchy-tree', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    user: (0, _model.belongsTo)('user', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    directoryUserParent: (0, _model.belongsTo)('directory-user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    directoryUser: (0, _model.belongsTo)('directory-user', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    parent: (0, _model.belongsTo)('user-hierarchy', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    userHierarchyTitle: (0, _model.belongsTo)('user-hierarchy-title', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    directoryUserGrandparents: (0, _model.hasMany)('directory-user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    directoryUserChildren: (0, _model.hasMany)('directory-user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    children: (0, _model.hasMany)('user-hierarchy', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': null
    })
  });

  _exports.default = _default;
});