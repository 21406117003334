define("securitytrax-ember-ui/components/pages/users/users-timeoff/component", ["exports", "@glimmer/component", "ember-concurrency", "ember-data", "moment"], function (_exports, _component, _emberConcurrency, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var UserTimeOff = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = (0, _emberConcurrency.task)(function* () {
    var userTimeOff = (yield this.args.model.user.get('timeOffEvents')).sortBy('startTime').filter(event => {
      return event.get('calendarEventType.name') == 'Time Off';
    });
    var startOfDay = this.args.model.params.date ? (0, _moment.default)(this.args.model.params.date).startOf('day') : (0, _moment.default)().startOf('day');
    var hashData = yield (0, _emberConcurrency.hash)({
      currCalendarEvents: userTimeOff.filter(event => {
        return event.get('startTime') >= startOfDay.format('YYYY-MM-DDTHH:mm:ss');
      }),
      pastCalendarEvents: userTimeOff.filter(event => {
        return event.get('startTime') < startOfDay.format('YYYY-MM-DDTHH:mm:ss');
      })
    });
    return hashData;
  }), _dec10 = (0, _emberConcurrency.task)(function* () {
    var currentAndPastCalendarEvents = yield this.currentAndPastCalendarEventsTask.perform();
    return [Ember.Object.create({
      name: this.intl.t('pages.users.time_off.upcoming_time_off'),
      items: currentAndPastCalendarEvents.currCalendarEvents,
      collapsable: true,
      collapsed: true,
      collapseIconName: 'icn-chevron-down'
    }), Ember.Object.create({
      name: this.intl.t('pages.users.time_off.past_time_off'),
      items: currentAndPastCalendarEvents.pastCalendarEvents,
      collapsable: true,
      collapsed: true,
      collapseIconName: 'icn-chevron-down'
    })];
  }), _dec11 = (0, _emberConcurrency.task)(function* () {
    // BOWLES TODO: figure out store loadOrReloadModelHasMany function and replace this with it
    var calendarEvents = yield this.args.model.user.get('timeOffEvents');
    yield calendarEvents.reload();
    this.userTimeoffCalendarEvents = _emberData.default.PromiseArray.create({
      promise: this.userTimeoffCalendarEventsTask.perform()
    });
  }), _dec12 = Ember._action, (_class = (_temp = class UserTimeOff extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "grants", _descriptor4, this);

      _initializerDefineProperty(this, "editingTimeoffCalendarEvent", _descriptor5, this);

      _initializerDefineProperty(this, "formMode", _descriptor6, this);

      _initializerDefineProperty(this, "formStarted", _descriptor7, this);

      _initializerDefineProperty(this, "userTimeoffCalendarEvents", _descriptor8, this);

      _initializerDefineProperty(this, "currentAndPastCalendarEventsTask", _descriptor9, this);

      _initializerDefineProperty(this, "userTimeoffCalendarEventsTask", _descriptor10, this);

      _initializerDefineProperty(this, "refreshModel", _descriptor11, this);

      this.userTimeoffCalendarEvents = _emberData.default.PromiseArray.create({
        promise: this.userTimeoffCalendarEventsTask.perform()
      });
    }

    get timeOffFormSteps() {
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.intl.t('pages.users.time_off.edit_title', {
            user_name: this.args.model.user.get('fname') + ' ' + this.args.model.user.get('lname')
          }),
          stepComponentPath: 'multi-step-form/forms/administration/users/time-off'
        })
      });
    }

    get timeOffFormOriginalModels() {
      return Ember.Object.create({
        calendarEvent: {
          model: this.editingTimeoffCalendarEvent
        }
      });
    }

    editTimeOff(row) {
      if (this.grants.getIndividualGrant('calendar_events.modify')) {
        Ember.set(this, 'formMode', 'edit');
      }

      Ember.set(this, 'editingTimeoffCalendarEvent', row);
      Ember.set(this, 'formStarted', true);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "editingTimeoffCalendarEvent", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "formMode", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'view';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "formStarted", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "userTimeoffCalendarEvents", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "currentAndPastCalendarEventsTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "userTimeoffCalendarEventsTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "refreshModel", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "editTimeOff", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "editTimeOff"), _class.prototype)), _class));
  _exports.default = UserTimeOff;
});