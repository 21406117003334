define("securitytrax-ember-ui/components/html/calendar-status-timeline-node/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AY5taYci",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"status-timeline__event-container status-timeline__event-container--calendar \",[34,6]]]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"event-container__icon-wrapper event-container__icon-wrapper--calendar\"],[12],[2,\"\\n\"],[6,[37,4],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,2],[[35,5]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                \"],[1,[30,[36,2],[\"icn-access-full\"],[[\"class\"],[[30,[36,1],[\"event-container__icon \",[35,0]],null]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"                \"],[1,[30,[36,2],[\"icn-circle-o\"],[[\"class\"],[[30,[36,1],[\"event-container__icon--empty \",[35,0]],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"event-container__text-wrapper event-container__text-wrapper--calendar\"],[12],[2,\"\\n        \"],[10,\"div\"],[15,0,[31,[\"event-container__text--primary \",[34,7]]]],[12],[2,\"\\n            \"],[1,[34,8]],[2,\"\\n            \"],[10,\"span\"],[14,0,\"time\"],[12],[2,\"\\n                \"],[1,[34,9]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"iconClass\",\"concat\",\"svg-icon\",\"closed\",\"if\",\"customIcon\",\"class\",\"primaryTextClass\",\"titleText\",\"primaryText\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/calendar-status-timeline-node/template.hbs"
    }
  });

  _exports.default = _default;
});