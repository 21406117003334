define("securitytrax-ember-ui/components/pages/administration-page/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TUDQ4PQ2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[30,[36,3],null,[[\"title\",\"mobileHamburgerVisible\",\"breadCrumbs\",\"breadCrumbData\",\"expandSideNav\"],[\"Administration\",true,[35,2],[35,1],[30,[36,0],[[32,0],\"expandSideNav\"],null]]]]],[2,\"\\n\\n\\n\"],[10,\"div\"],[14,0,\"page-content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"scroll-page\"],[12],[2,\"\\n        \"],[18,1,null],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"breadCrumbData\",\"breadCrumbs\",\"nav/top-nav-sub\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/administration-page/template.hbs"
    }
  });

  _exports.default = _default;
});