define("securitytrax-ember-ui/components/multi-step-form/forms/administration/system/upload-tool/update-file/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/administration/system/upload-tool/base", "ember-concurrency"], function (_exports, _base, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var UploadToolUpdateFile = (_dec = Ember._tracked, _dec2 = (0, _emberConcurrency.task)(function* (fileUploader) {
    var stNotificationManager = this.stNotificationManager;
    var company = yield this.sessionData.getCompany();
    var bulkActionType = this.args.bulkActionId;
    var updateFileComp = this;
    var reader = new FileReader();
    var file = fileUploader.myDropzone.files[0];
    reader.addEventListener('load', function () {
      try {
        company.invokeAction('perform_bulk_import', {
          verb: 'POST',
          data: {
            data: {
              bulk_action_type: bulkActionType,
              base64_data: reader.result
            }
          }
        });
      } catch (err) {
        stNotificationManager.addErrorMessages(err);
      }

      fileUploader.set('totalUploadProgress', 0);
      fileUploader.set('showTotalProgress', false);
      fileUploader.set('showActionsToolbar', false);
      fileUploader.myDropzone.removeAllFiles(true);
      fileUploader.set('queueCount', 0);
      updateFileComp.openModal = true;
    });
    reader.addEventListener('error', function () {
      stNotificationManager.addErrorMessages(reader.error);
    });
    reader.readAsDataURL(file);
  }), _dec3 = Ember._action, _dec4 = Ember._action, (_class = (_temp = class UploadToolUpdateFile extends _base.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "openModal", _descriptor, this);

      _initializerDefineProperty(this, "uploadFileDataTask", _descriptor2, this);
    }

    get disabledProgressBarSteps() {
      return [];
    }

    get hiddenProgressBarSteps() {
      return [];
    }

    uploadFile(fileUploader) {
      this.uploadFileDataTask.perform(fileUploader);
    }

    closeUploadTool() {
      this.args.multiStepForm.formStartedSetVal(false);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "openModal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uploadFileDataTask", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "uploadFile", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "uploadFile"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeUploadTool", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "closeUploadTool"), _class.prototype)), _class));
  _exports.default = UploadToolUpdateFile;
});