define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/information/component", ["exports", "securitytrax-ember-ui/utils/debug", "moment", "securitytrax-ember-ui/utils/obj-helpers", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/customer-base", "securitytrax-ember-ui/mixins/forms/customer-lead-shared", "lodash"], function (_exports, _debug, _moment, _objHelpers, _emberConcurrency, _customerBase, _customerLeadShared, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerBase.default.extend(_customerLeadShared.default, {
    intl: Ember.inject.service(),
    langs: Ember.inject.service(),
    mapWindow: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    usStates: Ember.inject.service(),
    grants: Ember.inject.service(),
    screen: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    centralStationService: Ember.inject.service(),
    dynamicFormChooser: Ember.inject.service('events/dynamic-form-chooser'),
    newPreCheck: true,
    creditCheckFormStarted: false,
    creditChecksProgressBarConfig: undefined,
    locations: undefined,
    accountTypes: undefined,
    entity: undefined,
    homeOwnerships: undefined,
    monitoringCompanies: undefined,
    dealerNumbers: undefined,
    confirmDeleteModalOpen: false,
    countyDisable: false,
    accountClasses: undefined,

    // nrg: false,
    didInsertElement() {
      this._super(...arguments);

      this.setupStep1Buttons();

      if (!this.isNewRecord || this.isASG) {
        this.set('newPreCheck', false);
      } // Create interval function to keep customer local time up to date


      var interval = 5000;

      var fn = () => {
        if (this && !this.isDestroyed && !(0, _debug.isTestEnvironment)()) {
          this.notifyPropertyChange('localTime');
          this.set('timer', Ember.run.later(fn, interval));
        }
      };

      fn(); // Initiate interval.
    },

    setupStep1Buttons: function () {
      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('step1', 'buttons', [{
        text: this.intl.t('common.actions.saveAndContinue'),
        classNamesString: 'st-btn st-btn-primary customer-information-save-button',
        positionOptions: 'up left',
        click: buttonPromise => {
          return this.saveCustomerTask.perform(multiStepForm, buttonPromise, false);
        },
        additionalClickActions: [{
          label: this.intl.t('common.actions.saveAndClose'),
          action: buttonPromise => {
            return this.saveCustomerTask.perform(multiStepForm, buttonPromise, true);
          },
          classNamesString: 'dropdown-save-close'
        }]
      }]);
    },
    initDataTask: (0, _emberConcurrency.task)(function* () {
      var hashedData = yield (0, _emberConcurrency.hash)({
        waitForFinishedCopyModels: (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true),
        locations: this.store.findAll('location'),
        monitoringCompanies: this.store.findAll('monitoringCompany'),
        dealerNumbers: this.store.findAll('dealerNumber'),
        monitoringCompanyTypes: this.store.findAll('monitoringCompanyType'),
        accountClasses: this.store.findAll('accountClass'),
        company: this.sessionData.getCompany()
      });
      (0, _objHelpers.initializeState)(this, {
        creditChecksProgressBarConfig: {
          hideProgressBar: true
        },
        homeOwnerships: ['own', 'rent', 'lease', 'other'],
        accountTypes: ['Residential', 'Commercial'],
        entity: 'customer',
        monitoringCompanies: []
      }); // const profileName = ENV[ENV.modulePrefix]['profile'];
      // if (profileName === 'reliant') {
      //     set(this, 'nrg', true);
      // } else {
      //     set(this, 'nrg', false);
      // }

      if (this.isNewRecord && this.newCustomer == null) {
        var formDefaultOptions = yield this.sessionData.getCompanyDefaults('customerInformationDefault');

        if (formDefaultOptions) {
          this.multiStepForm.formCopiedModelsSetVal('customer.model.location', hashedData.locations.findBy('id', formDefaultOptions.officeLocation));
          this.multiStepForm.formCopiedModelsSetVal('customer.model.lang', formDefaultOptions.primaryLanguage);
          this.multiStepForm.formCopiedModelsSetVal('customer.model.state', formDefaultOptions.state);
          this.multiStepForm.formCopiedModelsSetVal('customer.model.accountClass', hashedData.accountClasses.findBy('id', formDefaultOptions.accountClass));
          this.multiStepForm.formCopiedModelsSetVal('customer.model.accountType', formDefaultOptions.accountType);
        }
      }

      if (this.isNewRecord && this.newCustomer != null) {
        Ember.set(this.multiStepForm.progressBarConfig, 'activeIndex', 0);
      }

      Ember.setProperties(this, hashedData);

      if (this.isNewRecord && this.newCustomer != null && this.multiStepForm.formCopiedModels.customer.model.customerOrderInformation.monitoringCompany == null) {
        Ember.set(this, 'newPreCheck', false);
      }
    }).on('init'),
    relatedObjects: Ember.computed(function () {
      return this.isNewRecord ? [] : this.relatedObjectsTask.perform();
    }),
    relatedObjectsTask: (0, _emberConcurrency.task)(function* () {
      var related = yield this.customer.invokeAction('get_delete_warning_list', {
        verb: 'POST',
        data: {
          data: {}
        }
      });
      return related.responsePayload.data;
    }),
    validationMessages: Ember.computed('newPreCheck', function () {
      var requiredProperties = ['location', 'fname', 'lname', 'city', 'state', 'primaryPhone'];
      var validationsObject = {};

      if (this.isNewRecord && this.newPreCheck && this.isAdtCompany) {
        requiredProperties.push('address1', 'county', 'zip', 'accountType', 'customerBirthdate', 'customerSsn', 'email');
        validationsObject['orderInformation-monitoringCompany'] = this.intl.t('pages.customers.order.monitoringCompany.validateMessage');
      }

      if (this.isASG) {
        requiredProperties.push('address1', 'accountType');
      }

      requiredProperties.forEach(item => {
        validationsObject[item] = this.intl.t('common.validations.presence', {
          propertyLabel: this.intl.t("common.nav.create.customer.".concat(item, ".label"))
        });
      });
      validationsObject['customerBirthdate-birthdate'] = this.intl.t('common.nav.create.customer.location.validateMessage');
      validationsObject['location-name'] = this.intl.t('common.nav.create.customer.location.validateMessage');
      validationsObject['email'] = this.intl.t("common.nav.create.customer.email.validateMessage");
      validationsObject['phoneInvalid'] = this.intl.t('common.nav.create.customer.primaryPhone.validateMessage');
      validationsObject['zipInvalid'] = this.intl.t('common.nav.create.customer.zip.validateMessage');
      validationsObject['businessName'] = this.intl.t('common.nav.create.customer.businessName.validation');
      validationsObject['businessContact'] = this.intl.t('common.nav.create.customer.businessContact.validation');
      return validationsObject;
    }),
    saveCustomerTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise, close = false) {
      try {
        yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true); /////////////////////////////////////////////////////////////////////////////////////
        // be sure customer ssn dashes are stripped before saving

        var customerSsn = _lodash.default.get(multiStepForm, 'formCopiedModels.customer.model.customerSsn.ssn', null);

        if (customerSsn != null && typeof customerSsn === 'string') {
          Ember.set(multiStepForm, 'formCopiedModels.customer.model.customerSsn.ssn', customerSsn.replace(/-/g, ''));
        }

        var customer;
        var overrideConfig = Ember.Object.create({
          customer: {
            copyRelationMap: {
              adtPreCheck: {
                readOnly: false
              },
              customerBirthdate: {
                readOnly: !this.canViewAndEditBirthdate
              },
              location: {
                readOnly: true
              },
              customerSsn: {
                readOnly: !this.canViewAndEditSsn
              },
              customerSystemInformation: {
                relationships: {
                  dealerNumber: {
                    readOnly: false
                  }
                }
              },
              customerOrderInformation: {
                relationships: {
                  monitoringCompany: {
                    readOnly: true
                  }
                }
              }
            }
          }
        });
        this.set('newPreCheck', this.adtMonitoringCompanies.length ? this.newPreCheck : false);
        var customerWithUpdatedResponseMeta;
        var isAdtCompany = yield this.get('sessionData.companyHasSpecificMonitorTask').perform();
        var noValidateArray = ['activationFee-amount', 'mmr-amount', 'contractTerm-months', 'customerCancelReason-name', 'interactiveServicesProvider-name', 'customerWarranty-name', 'customerWarranty-duration', 'dealerNumber-number', 'dealerNumber-name', 'funder-name', 'leadCompany-name', 'orderInformation-salesRep', 'orderInformation-customerCancelDate', 'location-shippingCity', 'location-shippingContact', 'location-shippingState', 'location-shippingStreet', 'location-shippingZip', 'orderInformation-mmr', 'orderInformation-contractTerm', 'orderInformation-monitoringPlan'];

        if (!isAdtCompany) {
          noValidateArray.push('customerBirthdate');
          noValidateArray.push('customerSsn');
        }

        if (!this.newPreCheck) {
          noValidateArray.push('customer-zip');

          if (!this.isASG) {
            noValidateArray.push('customer-address1');
          }
        }

        if (!isAdtCompany || this.multiStepForm.formCopiedModels.customer.model.accountType !== 'Commercial') {
          noValidateArray.push('businessName');
        }

        if (this.multiStepForm.formCopiedModels.customer.model.accountType !== 'Commercial' || !this.newPreCheck) {
          noValidateArray.push('businessContact');
        }

        try {
          if (this.isNewRecord && this.newCustomer == null) {
            var loggedInUser = yield this.sessionData.getUser();
            var loggedInUserIsSalesRep = yield loggedInUser.get('isSalesRep');
            this.multiStepForm.formCopiedModelsSetVal('customer.model.saleDate', (0, _moment.default)().format('MM/DD/YYYY'));
            this.multiStepForm.formCopiedModelsSetVal('customer.model.recordType', 'customer');

            if (loggedInUserIsSalesRep) {
              this.multiStepForm.formCopiedModelsSetVal('customer.model.customerOrderInformation.repUser', loggedInUser);
            }

            var toCreateObjects = yield multiStepForm.validateCreateAll({
              extraValidationData: {
                newPreCheck: this.newPreCheck && isAdtCompany,
                validationMessages: this.validationMessages,
                noValidate: noValidateArray,
                adtValidation: isAdtCompany,
                isASG: this.isASG
              },
              notificationNamespace: 'createCustomerModal',
              overrideOriginalModelsConfig: overrideConfig
            });
            customer = this.store.createRecord('customer', toCreateObjects.customer.data);
            var customerBirthdate = this.store.createRecord('customerBirthdate', toCreateObjects.customerBirthdate.data);

            var _customerSsn = this.store.createRecord('customerSsn', toCreateObjects.customerSsn.data);

            var customerSystemInformation = this.store.createRecord('customerSystemInformation', toCreateObjects.customerSystemInformation.data);
            var customerOrderInformation = this.store.createRecord('customerOrderInformation', toCreateObjects.customerOrderInformation.data);
            customer.set('customerSsn', _customerSsn);
            customer.set('customerBirthdate', customerBirthdate);
            customer.set('customerSystemInformation', customerSystemInformation);
            customer.set('customerOrderInformation', customerOrderInformation);
            customer.set('requestMeta', {
              check_for_duplicates: this.checkForDuplicates
            });
            yield toCreateObjects.customer.saveWithRollbackAndNotification(customer);
          } else {
            if (this.multiStepForm.formCopiedModelsGetVal('customer.model.saleDate') == null) {
              this.multiStepForm.formCopiedModelsSetVal('customer.model.saleDate', (0, _moment.default)().format('MM/DD/YYYY'));
            }

            this.multiStepForm.formCopiedModelsSetVal('customer.model.recordType', 'customer');
            yield multiStepForm.validateSyncSaveAll({
              extraValidationData: {
                newPreCheck: this.newPreCheck && isAdtCompany,
                validationMessages: this.validationMessages,
                noValidate: noValidateArray,
                adtValidation: isAdtCompany,
                isASG: this.isASG
              },
              notificationNamespace: 'createCustomerModal',
              overrideOriginalModelsConfig: overrideConfig
            });
            customer = yield this.store.findRecord('customer', this.multiStepForm.formCopiedModelsGetVal('customer.model.id'));
          }

          if (this.newPreCheck && isAdtCompany) {
            yield this.submitAdtPreCheck.perform(customer, 'createCustomerModal');
          }

          if (this.isNewRecord) {
            customerWithUpdatedResponseMeta = yield this.store.findRecord('customer', customer.get('id'));
          }
        } catch (err) {
          if (err) {
            this.stNotificationManager.addErrorMessages(err, {
              notificationNamespace: 'modal'
            });
          }

          throw err;
        }

        buttonPromise.then(() => {
          this.checkForDuplicates = false;
          customer.set('requestMeta', {
            check_for_duplicates: false
          });
          customer.get('warnings').reload();

          if (close) {
            this.set('formMode', 'create');
            this.closeForm(multiStepForm, customer);
          } else {
            if (this.isNewRecord) {
              this.set('formMode', 'edit');
              this.set('newCustomer', customerWithUpdatedResponseMeta);
            }

            this.advanceForm(multiStepForm, 'step2', 1, customer);
          }
        });
      } catch (error) {
        (false && Ember.warn('error in saveCustomerTask', {
          id: 'app.forms.create-wizard'
        }));
        (false && Ember.warn(error, {
          id: 'app.forms.create-wizard'
        }));
        var jsonError = this.stNotificationManager.addErrorMessages(error, {
          notificationNamespace: 'createCustomerModal'
        });
        this.records = jsonError['records'];
        this.checkForDuplicates = false;
        Ember.notifyPropertyChange(this, 'records');
        throw error;
      }
    }),
    setDealerNumberTask: (0, _emberConcurrency.task)(function* () {
      var customer = this.multiStepForm.formCopiedModelsGetVal('customer.model');

      if (customer.get('location') && this.grants.getIndividualGrant('customer_system_information.view') && customer.get('customerSystemInformation')) {
        var dealerNumber = yield customer.get('location.dealerNumber');

        if (dealerNumber && dealerNumber.get('active') && this.grants.getIndividualGrant('customer_system_information.modify')) {
          customer.set('customerSystemInformation.dealerNumber', dealerNumber);
        }
      }
    }),
    // BOWLES TODO: This lives somewhere else too, create a service?
    openDynamicFormChooserTask: (0, _emberConcurrency.task)(function* (workflowType) {
      var workFlows = yield this.store.findAll('workflowConfig', {
        include: 'workflow'
      });
      var activeWorkFlows = workFlows.filter(workFlow => {
        return workFlow.get('active') && workFlow.get('workflow.workflowType') === workflowType;
      }); // skip dynamic form chooser if only one workflow is active

      if (activeWorkFlows.length === 1) {
        this.dynamicFormChooser.trigger('open-dynamic-form', {
          id: activeWorkFlows[0].get('workflow.id').dasherize(),
          customer: this.multiStepForm.formOriginalModelsGetVal('customer.model')
        });
      } else {
        this.dynamicFormChooser.trigger('open-dynamic-form-chooser', workflowType, this.multiStepForm.formOriginalModelsGetVal('customer.model'));
      }
    }),
    actions: {
      openMapWindow() {
        this.mapWindow.openWindow({
          latitude: Number(this.get('customer.responseMeta.geopoint.latitude')),
          longitude: Number(this.get('customer.responseMeta.geopoint.longitude')),
          label: "".concat(this.get('customer.address1'), " - ").concat(this.get('customer.city'))
        });
      },

      setPreCheckInfo() {
        if (!this.multiStepForm.formCopiedModelsGetVal('customer.model.customerOrderInformation')) {
          this.multiStepForm.formCopiedModelsSetVal('customer.model.customerOrderInformation', {
            monitoringCompany: null
          });
        }
      },

      submitAdtPreCheck() {
        return this.submitAdtPreCheck.perform();
      },

      openQualifyForm() {
        Ember.set(this, 'creditCheckFormStarted', true);
      }

    }
  });

  _exports.default = _default;
});