define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/forms/customer-information/information/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/information/component", "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/information/template", "ember-concurrency", "securitytrax-ember-ui/mixins/forms/customer-lead-shared", "lodash"], function (_exports, _component, _template, _emberConcurrency, _customerLeadShared, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_customerLeadShared.default, {
    layout: _template.default,
    entity: 'customer',
    currFormName: 'customerInformation',
    sessionData: Ember.inject.service(),
    setupStep1Buttons: function () {
      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('step1', 'buttons', [{
        text: this.intl.t('common.actions.cancel'),
        click: () => {
          this.multiStepForm.resetForm(false); // reset form

          this.send('changeFormMode', 'view');
        },
        classNamesString: 'st-btn customer-information-cancel-button'
      }, {
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.saveCustomerTask.perform(multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary customer-information-save-button'
      }]);
    },
    saveCustomerTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      try {
        /////////////////////////////////////////////////////////////////////////////////////
        // be sure customer ssn dashes are stripped before saving
        var isAdtCompany = yield this.get('sessionData.companyHasSpecificMonitorTask').perform();

        var customerSsn = _lodash.default.get(multiStepForm, 'formCopiedModels.customer.model.customerSsn.ssn', null);

        if (customerSsn != null && typeof customerSsn === 'string') {
          Ember.set(multiStepForm, 'formCopiedModels.customer.model.customerSsn.ssn', customerSsn.replace(/-/g, ''));
        }

        var noValidateArray = ['saleDate', 'orderInformation-salesRep', 'orderInformation-customerCancelDate', 'customer-zip', 'customer-address1', 'businessContact'];

        if (multiStepForm.formCopiedModels.customer.model.accountType !== 'Commercial') {
          noValidateArray.push('businessName');
        }

        yield multiStepForm.validateSyncSaveAll({
          extraValidationData: {
            adtValidation: isAdtCompany,
            validationMessages: this.validationMessages,
            noValidate: noValidateArray
          }
        });
        var warnings = yield multiStepForm.formOriginalModels.customer.model.get('warnings');

        if (warnings) {
          yield warnings.reload();
        }

        buttonPromise.then(() => {
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
          this.set('formMode', 'view');
          this.reloadFormModel();
        });
      } catch (err) {
        (false && Ember.warn('failed in saveCustomerTask ' + err, {
          id: 'customer.customerInformation.save'
        }));
        throw err;
      }
    })
  });

  _exports.default = _default;
});