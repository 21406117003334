define("securitytrax-ember-ui/components/multi-step-form/forms/calendar-event/duplicate-calendar-event/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6JkLZdfW",
    "block": "{\"symbols\":[\"@multiStepForm\",\"@formMode\",\"@basicFormMode\",\"@userType\"],\"statements\":[[11,\"div\"],[4,[38,0],[[32,0,[\"buttons\"]]],null],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"notification-manager display-inline\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"notification warning\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"before\"],[12],[2,\"\\n                \"],[1,[30,[36,1],[\"icn-warning\"],[[\"class\"],[\"notification-icon\"]]]],[2,\"\\n            \"],[13],[2,\"\\n            \"],[1,[30,[36,2],[\"pages.customers.appointments.duplicate_warning\"],null]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[8,\"html/st-await\",[],[[\"@useSpinner\",\"@booleanLoaded\"],[true,[34,3,[\"isIdle\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"multi-step-form/forms/calendar-event/event-details\",[],[[\"@calendarEvent\",\"@multiStepForm\",\"@formMode\",\"@basicFormMode\",\"@userType\",\"@isDuplicate\"],[[34,4],[32,1],[32,2],[32,3],[32,4],true]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"svg-icon\",\"t\",\"initDataTask\",\"duplicateAppointment\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/calendar-event/duplicate-calendar-event/template.hbs"
    }
  });

  _exports.default = _default;
});