define("securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/dealer-ally/documents/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/create-financing-base", "ember-concurrency", "moment"], function (_exports, _createFinancingBase, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var DealerAllyCreateFinancingDocuments = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
    var application = this.args.multiStepForm.formOriginalModelsGetVal('consumerFinancingApplication.model');
    var getStatusResult = (yield application.invokeAction('get_status', {
      verb: 'POST',
      data: {
        data: {}
      }
    })).responsePayload.data;
    this.messages = getStatusResult.get_application_status.StatusMessages;

    try {
      var returnVal = yield application.invokeAction('get_document_information', {
        verb: 'POST',
        data: {
          data: {}
        }
      });
      this.submittedDocuments = returnVal.responsePayload.data.lender_response;
      this.combinedDocuments = returnVal.responsePayload.data.lender_response;
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), _dec6 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    var application = multiStepForm.formOriginalModelsGetVal('consumerFinancingApplication.model');
    var allUploads = [];
    this.pendingDocuments.forEach(doc => {
      allUploads.push(this.submitDocumentTask.perform(application, doc));
    });
    yield (0, _emberConcurrency.all)(allUploads);
    buttonPromise.then(() => {
      multiStepForm.formStartedSetVal(false);
    });
  }), _dec7 = (0, _emberConcurrency.task)(function* (application, document) {
    try {
      yield application.invokeAction('submit_documents', {
        verb: 'POST',
        data: {
          data: {
            base64_data: document.file,
            type: document.Type
          }
        }
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = (_temp = class DealerAllyCreateFinancingDocuments extends _createFinancingBase.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "combinedDocuments", _descriptor3, this);

      _initializerDefineProperty(this, "messages", _descriptor4, this);

      _defineProperty(this, "pendingDocuments", Ember.A([]));

      _defineProperty(this, "submittedDocuments", Ember.A([]));

      _initializerDefineProperty(this, "initDataTask", _descriptor5, this);

      _initializerDefineProperty(this, "submitDocumentsTask", _descriptor6, this);

      _initializerDefineProperty(this, "submitDocumentTask", _descriptor7, this);

      this.initDataTask.perform();
    }

    get documentTypes() {
      return [{
        name: this.intl.t('pages.customers.accounting.financing.applicant_contract'),
        value: 'AC'
      }, {
        name: this.intl.t('pages.customers.accounting.financing.indentification'),
        value: 'ID'
      }, {
        name: this.intl.t('pages.customers.accounting.financing.completion_certificate'),
        value: 'CC'
      }, {
        name: this.intl.t('pages.customers.accounting.financing.signal_verification'),
        value: 'SV'
      }, {
        name: this.intl.t('pages.customers.accounting.financing.proof_of_homeownership'),
        value: 'PH'
      }, {
        name: this.intl.t('pages.customers.accounting.financing.proof_of_income'),
        value: 'PI'
      }, {
        name: this.intl.t('pages.customers.accounting.financing.monintoring_customer_agreement'),
        value: 'MA'
      }, {
        name: this.intl.t('pages.customers.accounting.financing.schedule_of_equipment'),
        value: 'SE'
      }, {
        name: this.intl.t('pages.customers.accounting.financing.other'),
        value: 'O'
      }];
    }

    buttons() {
      this.args.multiStepForm.registerStepData("documents", 'buttons', [{
        text: this.intl.t('common.actions.submit'),
        click: buttonPromise => {
          return this.submitDocumentsTask.perform(this.args.multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary save-application'
      }]);
    }

    uploadPendingDocument(fileUploader) {
      var documents = this.pendingDocuments;
      var combinedDocuments = this.combinedDocuments;
      fileUploader.myDropzone.files.forEach(file => {
        var reader = new FileReader();
        reader.addEventListener('load', function () {
          var doc = {
            CreationDate: (0, _moment.default)(),
            Filename: file.name,
            Type: undefined,
            file: reader.result
          };
          documents.pushObject(doc);
          combinedDocuments.pushObject(doc);
        });
        reader.addEventListener('error', function () {
          stNotificationManager.addErrorMessages(reader.error);
        });
        reader.readAsDataURL(file);
        fileUploader.set('totalUploadProgress', 0);
        fileUploader.set('showTotalProgress', false);
        fileUploader.set('showActionsToolbar', false);
        fileUploader.myDropzone.removeAllFiles(true);
        fileUploader.set('queueCount', 0);
      });
    }

    deletePendingDocument(document) {
      this.pendingDocuments = this.pendingDocuments.filter(doc => doc !== document);
      this.combinedDocuments = this.combinedDocuments.filter(doc => doc !== document);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "combinedDocuments", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "messages", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "submitDocumentsTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "submitDocumentTask", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "buttons", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "buttons"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "uploadPendingDocument", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "uploadPendingDocument"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deletePendingDocument", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "deletePendingDocument"), _class.prototype)), _class));
  _exports.default = DealerAllyCreateFinancingDocuments;
});