define("securitytrax-ember-ui/components/org-chart/st-leaf/parent-leaf/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    orgTree: Ember.inject.service(),
    classNames: ['st-user-leaf'],
    mediaOrientation: 'st-media-object--portrait',
    tabindex: 0,
    attributeBindings: ['tabindex'],
    avatar: null,
    name: null,
    isCompany: false,

    init() {
      this._super(...arguments);

      this.setupTask.perform();
    },

    setupTask: (0, _emberConcurrency.task)(function* () {
      var user = yield this.user;

      if (user) {
        this.set('avatar', user.get('avatar'));
        this.set('name', user.get('name'));
      }
    }),
    leafExtension: Ember.computed('isCurrentUser', function () {
      return this.isCurrentUser ? 'st-user-leaf__stem--connector' : '';
    }),
    changeRouteTask: (0, _emberConcurrency.task)(function* () {
      var userHierarchyTree = yield this.get('orgTree.userHierarchyTree');
      var user = JSON.parse(this.get('user.id'));

      if (this.isCompany) {
        this.router.transitionTo('/administration/org-chart/' + userHierarchyTree.get('id'));
      } else {
        this.router.transitionTo('/administration/org-chart/' + userHierarchyTree.get('id') + '/user/' + user);
      }
    }),

    click() {
      this.changeRouteTask.perform();
    },

    actions: {
      dropDownClick(dropdown) {
        dropdown.actions.toggle();
        return false;
      }

    }
  });

  _exports.default = _default;
});