define("securitytrax-ember-ui/models/parent-entity-billing", ["exports", "securitytrax-ember-ui/models/base_models/parent-entity-billing"], function (_exports, _parentEntityBilling) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _parentEntityBilling.default.extend({});

  _exports.default = _default;
});