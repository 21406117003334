define("securitytrax-ember-ui/components/multi-step-form/forms/demo/demo-progress-bar/step-1/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/demo/demo-progress-bar/demo-progress-bar-base"], function (_exports, _demoProgressBarBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _demoProgressBarBase.default.extend({
    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('step1', 'buttons', [{
        text: 'Next',
        click: function () {
          var item = multiStepForm.progressBarConfig.items.objectAt(0);
          multiStepForm.currentStepNameKeySetVal('step2');
          multiStepForm.progressBarConfig.activeIndex = 1;
          Ember.set(item, 'isComplete', true);
        },
        classNamesString: 'st-btn st-btn-primary'
      }]);
      multiStepForm.modalOpen = true;
    }

  });

  _exports.default = _default;
});