define("securitytrax-ember-ui/services/st-metrics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * https://trackjs.com/blog/monitoring-javascript-memory/
   */
  var _default = Ember.Service.extend({
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    getMetrics: function () {
      var service = this;
      var store = service.get('store');
      var modelTypeNames = store.getAllModelTypeNames();
      var metrics = {};
      metrics.store = metrics.store || {};
      metrics.store.models = metrics.store.models || {};
      modelTypeNames.forEach(name => {
        var peekData = store.peekAll(name);
        metrics.store.models[name] = metrics.store.models[name] || {};
        metrics['store']['models'][name]['count'] = peekData._length;
      }); //console.log(stats);
      //console.log(store.getAllModelTypes());
      // TODO: add how long the user has been authenticated
      // TODO: add how long the app has been loaded/initialized
      // TODO: how many transitions? etc

      return metrics;
    }
  });

  _exports.default = _default;
});