define("securitytrax-ember-ui/components/modals/st-paper/paper-dialog-inner/component", ["exports", "securitytrax-ember-ui/mixins/translate3d-mixin", "jquery"], function (_exports, _translate3dMixin, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PaperDialogInner
   * @extends Ember.Component
   * @uses Translate3dMixin
   */
  var _default = Ember.Component.extend(_translate3dMixin.default, {
    tagName: 'md-dialog',
    classNames: ['md-default-theme st-modal'],
    classNameBindings: ['contentOverflow:md-content-overflow', 'fullscreen:md-dialog-fullscreen', 'fullscreen:st-modal--full', 'hasProgressBar:st-modal--nav'],

    onTranslateFromEnd() {
      if (this.focusOnOpen) {
        var toFocus = (0, _jquery.default)('[autofocus]').last();

        if (toFocus.length === 0) {
          toFocus = (0, _jquery.default)('.st-modal-actions button').last();
        }

        toFocus.focus();
      }
    },

    onTranslateToEnd($origin) {
      if ($origin) {
        $origin.focus();
      }
    },

    didInsertElement() {
      this._super(...arguments);

      this.checkContentOverflow(); // content overflow might change depending on load of images inside dialog.

      var images = (0, _jquery.default)(this.element).find('img');
      images.on("load.".concat(this.elementId), Ember.run.bind(this, this.checkContentOverflow));
    },

    willDestroyElement() {
      this._super(...arguments);

      var images = (0, _jquery.default)(this.element).find('img');
      images.off("load.".concat(this.elementId));
    },

    checkContentOverflow() {
      var content = this.element.querySelector('md-dialog-content');

      if (content) {
        this.set('contentOverflow', content.scrollHeight > content.clientHeight);
      }
    }

  });

  _exports.default = _default;
});