define("securitytrax-ember-ui/models/base_models/docusign-recipient", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    recipientType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['signers', 'agents', 'editors', 'intermediaries', 'carbonCopies', 'certifiedDeliveries', 'inPersonSigners']
    }),
    name: (0, _model.attr)('string', {
      'readOnly': false
    }),
    email: (0, _model.attr)('string', {
      'readOnly': false
    }),
    recipientId: (0, _model.attr)('number', {
      'readOnly': false
    }),
    routingOrder: (0, _model.attr)('number', {
      'readOnly': false
    }),
    note: (0, _model.attr)('string', {
      'readOnly': false
    }),
    roleName: (0, _model.attr)('string', {
      'readOnly': false
    }),
    status: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['created', 'sent', 'delivered', 'signed', 'declined', 'completed', 'faxPending', 'autoResponded']
    }),
    envelopeId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    embedded: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    signedDateTime: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    deliveredDateTime: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    deliveryMethod: (0, _model.attr)('string', {
      'readOnly': false
    }),
    docusignEnvelope: (0, _model.belongsTo)('docusign-envelope', {
      'readOnly': true,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});