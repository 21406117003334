define("securitytrax-ember-ui/components/nav/top-nav/nav/nav-company/component", ["exports", "securitytrax-ember-ui/components/nav/top-nav/nav/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    grants: Ember.inject.service(),
    canViewCompanyInventory: Ember.computed('grants.userGrants', function () {
      var grantsToCheck = ['companies.company_replenishment', 'equipment_purchase_requisitions.computed.company_inventory', 'equipment_purchase_orders.computed.company_inventory', 'equipment_transfers.computed.company_inventory', 'equipment_spillage.computed.company_inventory', 'equipment_return_merchandise_authorizations.computed.company_inventory', 'equipment_ledgers.computed.company_inventory'];

      for (var i = 0; i < grantsToCheck.length; i++) {
        if (this.grants.getIndividualGrant(grantsToCheck[i])) {
          return true;
        }
      }

      return false;
    }),
    canViewCompanyAccounting: Ember.computed('grants.userGrants', function () {
      var grantsToCheck = ['fundings.view', 'customer_payments.view', 'customer_payables.view', 'customer_invoices.view', 'customer_credit_memos.view', 'customer_refund_receipts.view'];
      return grantsToCheck.some(grant => this.grants.getIndividualGrant(grant));
    })
  });

  _exports.default = _default;
});