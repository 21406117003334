define("securitytrax-ember-ui/models/government-regulation", ["exports", "securitytrax-ember-ui/models/base_models/government-regulation"], function (_exports, _governmentRegulation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _governmentRegulation.default.extend({
    payeeDisplayName: Ember.computed('governingBody', 'governmentRegulationType', 'amount', function () {
      return this.governingBody.capitalize() + ' ' + this.governmentRegulationType.capitalize() + ' $' + this.amount;
    })
  });

  _exports.default = _default;
});