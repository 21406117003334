define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/electronic-document-providers/integrations/pandadoc/edit-envelope/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var PandadocEditEnvelope = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = (0, _emberConcurrency.task)(function* () {
    this.formatRecipients();
    this.setupButtons();
    var editRecipientButtons = [{
      text: this.intl.t('common.actions.save'),
      classNamesString: 'st-btn st-btn-primary',
      click: () => {
        return this.saveRecipientTask.perform();
      }
    }, {
      text: this.intl.t('common.actions.cancel'),
      classNamesString: 'st-btn ',
      click: () => {
        return this.cancelSaveRow();
      }
    }];
    Ember.set(this, 'editRecipientButtons', editRecipientButtons);
    var provider = yield this.args.pandadocDocumentModel.get('electronicDocumentProvider');
    var config = yield provider.get('electronicDocumentProviderConfig');
    this.embeddedSigningDisabled = config.embeddedSigningDisabled;
  }), _dec11 = (0, _emberConcurrency.task)(function* () {
    try {
      Ember.set(this, 'recipientsLoaded', false);
      this.recipients = [];
      yield this.args.pandadocDocumentModel.reload();
      this.formatRecipients();
      Ember.set(this, 'recipientsLoaded', true);
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
    }
  }), _dec12 = (0, _emberConcurrency.task)(function* () {
    var valid = this.recipients.every(rec => rec.name && rec.email);

    if (valid) {
      try {
        Ember.set(this.args.pandadocDocumentModel, 'recipients', this.recipients);
        yield this.args.pandadocDocumentModel.save();
        Ember.set(this, 'waitForDocumentDraftModalOpen', true);
        yield (0, _emberConcurrency.timeout)(3000);
        setTimeout(() => {
          //cleanup
          Ember.set(this, 'waitForDocumentDraftModalOpen', false);
          this.setupButtons();
          throw new Error('Timed out, please try again later.');
        }, 20000); // this sets a timeout for if the thing takes forever

        var curStatus = null;

        while (curStatus !== 'document.draft') {
          try {
            var statusData = yield this.args.pandadocDocumentModel.invokeAction('get_status', {
              verb: 'POST',
              data: {
                data: {
                  attributes: {}
                }
              }
            });
            curStatus = statusData.responsePayload.data.attributes.status;
          } catch (err) {// nothing, just keep trying
          }

          yield (0, _emberConcurrency.timeout)(500);
        }

        this.args.pandadocDocumentModel.status = curStatus;
        Ember.set(this, 'pandadocSent', true);
        yield this.sendPandadocTask.perform();
        this.args.pandadocDocumentModel.status = 'document.sent';
        yield this.setupRecipientsTask.perform();
        this.setupButtons();
        yield (0, _emberConcurrency.timeout)(500);
        Ember.set(this, 'waitForDocumentDraftModalOpen', false);
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
      }
    } else {
      this.stNotificationManager.addError(this.intl.t('pages.administration.integrations.electronic_document_providers.pandadoc.invalidRecipientsError'), 'modal');
      throw new Error();
    }
  }), _dec13 = (0, _emberConcurrency.task)(function* () {
    yield this.args.pandadocDocumentModel.invokeAction('send_document', {
      verb: 'POST',
      data: {
        data: {
          attributes: {}
        }
      }
    });
  }), _dec14 = (0, _emberConcurrency.task)(function* () {
    try {
      yield this.args.pandadocDocumentModel.destroyRecordWithRollbackTask.perform();
      this.args.multiStepForm.formStartedSetVal(false);
      yield this.args.afterDelete.call();
      this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
    } catch (err) {
      warn('error deleting docusign envelope: ' + err, {
        id: 'multiStepForm.forms.administration.integrations.docusign.editEnvelope.deleteEnvelope'
      });
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal2'
      });
      throw err;
    }
  }), _dec15 = (0, _emberConcurrency.task)(function* (row) {
    try {
      var urlResponse = yield this.args.pandadocDocumentModel.invokeAction('generate_embedded_signature_url', {
        verb: 'POST',
        data: {
          data: {
            attributes: {
              recipient_email: row.email
            }
          }
        }
      });
      window.open(urlResponse.responsePayload.data.attributes.embedded_signature_link, '_blank');
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), _dec16 = (0, _emberConcurrency.task)(function* () {
    var formattedObjects = Ember.A();
    formattedObjects.pushObject(Ember.Object.create({
      notificationNamespace: 'modal',
      model: {
        name: this.editRow.name,
        email: this.editRow.email
      },
      filePath: 'common-validations'
    }));
    yield this.args.multiStepForm.validateList.perform(formattedObjects);
    Ember.set(this.selectedRow, 'name', this.editRow.name);
    Ember.set(this.selectedRow, 'email', this.editRow.email);
    Ember.set(this, 'editRecipientModalOpen', false);
  }), _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, (_class = (_temp = class PandadocEditEnvelope extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "sessionData", _descriptor3, this);

      _initializerDefineProperty(this, "stAjax", _descriptor4, this);

      _initializerDefineProperty(this, "grants", _descriptor5, this);

      _initializerDefineProperty(this, "store", _descriptor6, this);

      _defineProperty(this, "canSend", false);

      _defineProperty(this, "synced", false);

      _defineProperty(this, "showUnlinkConfirmationModal", false);

      _defineProperty(this, "recipientsWithoutEmail", false);

      _defineProperty(this, "unlinkEnvelopeButtons", undefined);

      _defineProperty(this, "confirmDeleteModalOpen", false);

      _initializerDefineProperty(this, "recipientsLoaded", _descriptor7, this);

      _initializerDefineProperty(this, "recipients", _descriptor8, this);

      _initializerDefineProperty(this, "pandadocSent", _descriptor9, this);

      _initializerDefineProperty(this, "initTask", _descriptor10, this);

      _initializerDefineProperty(this, "setupRecipientsTask", _descriptor11, this);

      _initializerDefineProperty(this, "createPandadocTask", _descriptor12, this);

      _initializerDefineProperty(this, "sendPandadocTask", _descriptor13, this);

      _initializerDefineProperty(this, "deleteEnvelopeTask", _descriptor14, this);

      _initializerDefineProperty(this, "signHereTask", _descriptor15, this);

      _initializerDefineProperty(this, "saveRecipientTask", _descriptor16, this);

      this.initTask.perform();
    }

    formatRecipients() {
      var newRecord = !this.args.pandadocDocumentModel.get('id');
      var statusBase = 'pages.administration.integrations.electronic_document_providers.pandadoc.statuses.recipient.';
      var foundFirstIncomplete = false;
      this.args.pandadocDocumentModel.get('recipients').forEach(rec => {
        if (!newRecord) {
          rec.name = rec.first_name + ' ' + rec.last_name;
          rec.roleName = rec.role;

          if (this.args.pandadocDocumentModel.status === 'document.draft') {
            rec.status = 'created';
            rec.statusLabel = this.intl.t(statusBase + 'created');
          } else {
            if (rec.has_completed) {
              rec.status = 'completed';
              rec.statusLabel = this.intl.t(statusBase + 'completed');
            } else if (!foundFirstIncomplete) {
              rec.status = 'sent';
              rec.statusLabel = this.intl.t(statusBase + 'sent');
            } else {
              rec.status = 'created';
              rec.statusLabel = this.intl.t(statusBase + 'created');
            }
          }
        } else {
          rec.status = 'created';
          rec.statusLabel = this.intl.t('pages.administration.integrations.electronic_document_providers.pandadoc.statuses.recipient.created');
          rec.id = rec.role.id;
          rec.roleName = rec.role.name;
        }

        this.recipients.push(rec);
      });
    }

    setupButtons() {
      var buttonChoices = [];

      if (this.args.pandadocDocumentModel.get('id')) {
        buttonChoices.push({
          text: this.intl.t('common.actions.delete'),
          classNamesString: 'st-btn st-btn envelope-delete-button warning-red delete',
          isDelete: true,
          click: () => {
            Ember.set(this, 'confirmDeleteModalOpen', true);
          }
        });

        if (this.args.pandadocDocumentModel.status === 'document.draft') {
          buttonChoices.push({
            text: this.intl.t('common.actions.send'),
            classNamesString: 'st-btn st-btn-primary',
            click: () => {
              return this.sendPandadocTask.perform();
            }
          });
        }
      } else {
        buttonChoices.push({
          text: this.intl.t('common.actions.create'),
          classNamesString: 'st-btn st-btn-primary',
          click: () => {
            return this.createPandadocTask.perform();
          }
        });
      }

      this.args.multiStepForm.registerStepData('editEnvelopeForm', 'buttons', buttonChoices);
    }

    editSelectRow(row) {
      this.editRow = {};
      this.selectedRow = row;
      Object.assign(this.editRow, row);
      Ember.set(this, 'editRecipientTitle', row.roleName);
      Ember.set(this, 'editRecipientModalOpen', true);
    }

    cancelSaveRow() {
      Ember.set(this, 'editRecipientModalOpen', false);
    }

    deleteRow(row, index) {
      this.recipients.removeAt(index);
      Ember.set(this, 'pandadocRecipientDeleteId', null);
      Ember.notifyPropertyChange(this, 'recipients');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stAjax", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "recipientsLoaded", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "recipients", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "pandadocSent", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "initTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "setupRecipientsTask", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "createPandadocTask", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "sendPandadocTask", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "deleteEnvelopeTask", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "signHereTask", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "saveRecipientTask", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "editSelectRow", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "editSelectRow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelSaveRow", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "cancelSaveRow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteRow", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "deleteRow"), _class.prototype)), _class));
  _exports.default = PandadocEditEnvelope;
});