define("securitytrax-ember-ui/demo/wysiwyg/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s4pM48iH",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],null,[[\"classNames\"],[\"paper-demo\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h1\"],[12],[2,\"WYSIWYG\"],[13],[2,\"\\n\\n    \"],[1,[30,[36,1],null,[[\"options\",\"value\"],[[35,0],\"asdf\"]]]],[2,\"\\n\\n    \"],[10,\"h1\"],[12],[2,\"Formula WYSIWYG\"],[13],[2,\"\\n    \"],[8,\"customer/accounting/payables-formula-component\",[],[[],[]],null],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"options\",\"tinymce\",\"pages/demo-page\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/demo/wysiwyg/template.hbs"
    }
  });

  _exports.default = _default;
});