define("securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9QgPrx04",
    "block": "{\"symbols\":[\"interactiveServicesProviderMultiStepForm\"],\"statements\":[[6,[37,11],[[30,[36,13],[\"customer_system_information.view\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,12],null,[[\"class\"],[[30,[36,11],[[35,2],\"toolbox\",\"interactive-services-provider\"],null]]],[[\"default\"],[{\"statements\":[[6,[37,10],null,[[\"formSteps\",\"formOriginalModels\",\"formStarted\"],[[35,9],[35,8],true]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,7],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],[[\"multiStepForm\",\"class\",\"formMode\",\"customer\",\"customerOrderInformation\",\"customerSystemInformation\",\"saveInteractiveServicesProviderInformationTask\",\"toolbox\",\"subroutePath\",\"closeAction\"],[[32,1],\"interactive-services-provider-information-form\",\"view\",[35,6],[35,5],[35,4],[35,3],[35,2],[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"closeAction\",\"subroutePath\",\"toolbox\",\"saveInteractiveServicesProviderInformationTask\",\"customerSystemInformation\",\"customerOrderInformation\",\"customer\",\"component\",\"interactiveServicesProviderFormOriginalModel\",\"interactiveServicesProviderFormSteps\",\"multi-step-form\",\"if\",\"html/st-card\",\"has-grants\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/template.hbs"
    }
  });

  _exports.default = _default;
});