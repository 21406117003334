define("securitytrax-ember-ui/customers/accounting/payments-funding/edit-create-funding/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ws/FbvVq",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"customer\",\"fundingId\",\"parentRoute\"],[[35,0,[\"customer\"]],[35,0,[\"fundingId\"]],[35,0,[\"parentRoute\"]]]]]]],\"hasEval\":false,\"upvars\":[\"model\",\"modals/sub-route-modals/edit-create-funding\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/customers/accounting/payments-funding/edit-create-funding/template.hbs"
    }
  });

  _exports.default = _default;
});