define("securitytrax-ember-ui/customers/accounting/invoices-invoices/edit-create-invoice/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7JhKWR45",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],null,[[\"invoiceId\",\"parentRoute\",\"invoiceType\",\"invoiceFormMode\",\"customer\"],[[35,0,[\"customerInvoiceId\"]],[35,0,[\"parentRoute\"]],\"invoice\",[30,[36,2],[[30,[36,1],[[35,0,[\"customerInvoiceId\"]],\"create\"],null],\"create\",\"edit\"],null],[35,0,[\"customer\"]]]]]]],\"hasEval\":false,\"upvars\":[\"model\",\"eq\",\"if\",\"modals/sub-route-modals/edit-create-invoice\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/customers/accounting/invoices-invoices/edit-create-invoice/template.hbs"
    }
  });

  _exports.default = _default;
});