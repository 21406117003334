define("securitytrax-ember-ui/customers/accounting/billing-statements/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PYxJpf1g",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[\"pages/accounting/billing/statements\"],[[\"model\",\"customer\"],[[35,0],[35,0,[\"customer\"]]]]]]],\"hasEval\":false,\"upvars\":[\"model\",\"component\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/customers/accounting/billing-statements/template.hbs"
    }
  });

  _exports.default = _default;
});