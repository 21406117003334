define("securitytrax-ember-ui/components/lead/lead-info-modal/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    leadRep: Ember.computed('leadInformation', function () {
      return this.leadInformation.get('leadRepUser');
    }),
    leadStatus: Ember.computed('leadInformation', function () {
      return this.leadInformation.get('leadStatus');
    }),
    leadContacts: Ember.computed('leadContactsUpdateProperty', function () {
      return this.leadContactsTask.perform();
    }),
    leadContactsTask: (0, _emberConcurrency.task)(function* () {
      var hasGrants = yield this.grants.get('getIndividualGrantAsync').perform('lead_contacts.view');

      if (hasGrants) {
        return yield this.store.query('leadContact', {
          filter: 'customer=' + this.customer.get('id'),
          sort: '-id',
          include: 'user'
        });
      }

      return [];
    })
  });

  _exports.default = _default;
});