define("securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-notes/tasks/component", ["exports", "securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-notes/base-component", "ember-concurrency"], function (_exports, _baseComponent, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Controller = _baseComponent.default.extend({
    grants: Ember.inject.service(),
    customerTask: null,
    customer: null,
    selectedTemplate: undefined,
    initData: (0, _emberConcurrency.task)(function* () {
      if (this.taskId !== 'create') {
        var _this$customer;

        this.set('customerTask', yield this.store.findRecord('customerTask', this.taskId, {
          include: 'customer'
        }));
        this.set('customer', (_this$customer = this.customer) !== null && _this$customer !== void 0 ? _this$customer : this.get('customerTask.customer'));
      }

      this.set('formStarted', true);
    }).on('init'),
    taskTypes: Ember.computed(function () {
      return this.taskTypesTask.perform();
    }),
    taskTypesTask: (0, _emberConcurrency.task)(function* () {
      return yield this.store.findAll('taskType');
    }),
    customerTaskFormMode: Ember.computed('customerTask', function () {
      if (this.grants.getIndividualGrant('customer_tasks.create') && !this.customerTask) {
        return 'create';
      } else if (this.grants.getIndividualGrant('customer_tasks.modify') && this.customerTask) {
        return 'edit';
      } else {
        return 'view';
      }
    }),
    customerTaskFormSteps: Ember.computed('customerTask', function () {
      var intl = this.intl;
      var customerTask = this.customerTask;
      return Ember.Object.create({
        customerTaskChooser: Ember.Object.create({
          stepNameKey: 'customerTaskChooser',
          title: intl.t('pages.administration.content_management.task_templates.title_select'),
          stepComponentPath: 'multi-step-form/forms/notes/task-template-select'
        }),
        customerTaskSingleStep: Ember.Object.create({
          stepNameKey: 'customerTaskSingleStep',
          title: customerTask ? intl.t('pages.customers.tasks.edit', {
            id: customerTask.id,
            name: this.customer.get('flname')
          }) : intl.t('pages.customers.tasks.add', {
            name: this.customer.get('flname')
          }),
          stepComponentPath: 'multi-step-form/forms/notes/tasks'
        })
      });
    }),
    customerTaskFormOriginalModels: Ember.computed('customerTask', function () {
      var grants = this.grants;
      var isCreate = this.customerTask == null;
      return Ember.Object.create({
        customerTask: {
          model: this.customerTask,
          copyRelationMap: {
            noteType: {
              doSave: false
            },
            createdByUser: {
              doCopy: true,
              doValidate: false,
              doSave: false
            },
            assignedUser: {
              doCopy: true,
              doValidate: false,
              doSave: false
            },
            customerCommunications: {
              doCopy: grants.getIndividualGrant('customer_communications.view'),
              doValidate: grants.getIndividualGrant('customer_communications.view'),
              doSave: isCreate ? grants.getIndividualGrant('customer_communications.create') : grants.getIndividualGrant('customer_communications.modify')
            }
          }
        }
      });
    }),
    actions: {
      refreshModel() {
        this.get("customer.customerTasks").reload();
      }

    }
  });

  var _default = Controller;
  _exports.default = _default;
});