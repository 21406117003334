define("securitytrax-ember-ui/components/pages/administration/company-settings/integrations/payment-processing/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lBbHzx2p",
    "block": "{\"symbols\":[\"multiStepForm\"],\"statements\":[[1,[30,[36,4],null,[[\"title\",\"breadCrumbs\",\"breadCrumbData\"],[[30,[36,3],[\"pages.administration.integrations.payment_processing.title\"],null],[35,2],[35,1]]]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"page-content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"scroll-page\"],[12],[2,\"\\n\"],[6,[37,7],null,[[\"formSteps\",\"formOriginalModels\",\"formStarted\"],[[35,6],[35,5],true]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,0],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],[[\"multiStepForm\",\"formMode\"],[[32,1],\"view\"]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\",\"breadCrumbData\",\"breadCrumbs\",\"t\",\"nav/top-nav-sub\",\"paymentProcessingFormOriginalModels\",\"paymentProcessingForm\",\"multi-step-form\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/administration/company-settings/integrations/payment-processing/template.hbs"
    }
  });

  _exports.default = _default;
});