define("securitytrax-ember-ui/index/controller", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Controller
   * @extends Ember.Controller
   * @memberof customers
   */
  var Controller = Ember.Controller.extend({
    navigation: Ember.inject.service(),
    queryParams: ['date'],
    date: undefined,
    calendarDate: Ember.computed({
      get(key) {
        key;

        if (this.date) {
          return (0, _moment.default)(this.date);
        } else {
          return (0, _moment.default)();
        }
      },

      set(key, value) {
        key;
        this.set('date', value.format('MM/DD/YYYY'));
        return value;
      }

    }),
    parsedRoles: Ember.computed('model.loggedInUser.id', function () {
      return this.parsedRolesTask.perform();
    }),
    parsedRolesTask: (0, _emberConcurrency.task)(function* () {
      var loggedInUser = yield this.get('model.loggedInUser');
      var roles = yield loggedInUser.get('roles');
      var parsedRoles = {};

      for (var index = 0; index < roles.get('length'); index++) {
        var role = roles.objectAt(index);
        parsedRoles[role.get('key')] = true;
      }

      return parsedRoles;
    })
  });
  var _default = Controller;
  _exports.default = _default;
});