define("securitytrax-ember-ui/models/base_models/docusign-envelope", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    status: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['created', 'sent', 'delivered', 'signed', 'completed', 'declined', 'timedOut', 'voided', 'deleted']
    }),
    emailSubject: (0, _model.attr)('string', {
      'readOnly': false
    }),
    emailBlurb: (0, _model.attr)('string', {
      'readOnly': false
    }),
    envelopeId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    autoNavigation: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    envelopeIdStamping: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    enableWetSign: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    allowMarkup: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    allowReassign: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    createdDateTime: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    lastModifiedDateTime: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    statusChangedDateTime: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    purgeState: (0, _model.attr)('string', {
      'readOnly': false
    }),
    signerCanSignOnMobile: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    isSignatureProviderEnvelope: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    allowViewHistory: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    customer: (0, _model.belongsTo)('customer', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    electronicDocumentProvider: (0, _model.belongsTo)('electronic-document-provider', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    docusignTemplates: (0, _model.hasMany)('docusign-template', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'serialize': true
    }),
    docusignCustomFields: (0, _model.hasMany)('docusign-custom-field', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'serialize': true
    }),
    docusignDocuments: (0, _model.hasMany)('docusign-document', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'serialize': true
    }),
    docusignRecipients: (0, _model.hasMany)('docusign-recipient', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});