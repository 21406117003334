define("securitytrax-ember-ui/components/multi-step-form/forms/system/adt/shared/billing-data-new/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    billingCycles: Ember.computed(function () {
      return this.billingCyclesTask.perform();
    }),
    billingCyclesTask: (0, _emberConcurrency.task)(function* () {
      return yield this.store.findAll('billingCycle');
    }),
    customerDefaultBillingMethod: Ember.computed('customer.defaultCustomerBilling.id', function () {
      return this.customerDefaultBillingMethodTask.perform();
    }),
    customerDefaultBillingMethodTask: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = this.multiStepForm;
      var defaultCustomerBilling = this.multiStepForm.formCopiedModelsGetVal('customer.model.defaultCustomerBilling');

      if (this.get('customer.defaultCustomerBilling.id') !== this.multiStepForm.formCopiedModelsGetVal('customer.model.defaultCustomerBilling.id')) {
        yield multiStepForm.setupCopyModels.perform();
      }

      if (defaultCustomerBilling) {
        var billCycleId = yield defaultCustomerBilling.get('billCycle.id');

        if (!billCycleId) {
          multiStepForm.formCopiedModelsSetVal('customer.model.defaultCustomerBilling.billCycle', yield this.store.findRecord('billingCycle', 1));
        }
      }

      return defaultCustomerBilling;
    })
  });

  _exports.default = _default;
});