define("securitytrax-ember-ui/administration/company-settings/integrations/partner-companies/payables/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JDUpE6Dk",
    "block": "{\"symbols\":[],\"statements\":[[8,\"payroll/payee-assignments\",[],[[\"@object\"],[[34,0,[\"leadCompany\"]]]],null]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/administration/company-settings/integrations/partner-companies/payables/template.hbs"
    }
  });

  _exports.default = _default;
});