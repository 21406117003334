define("securitytrax-ember-ui/components/customer/accounting/payables-formula-component/component", ["exports", "@glimmer/component", "jquery", "lodash", "ember-concurrency"], function (_exports, _component, _jquery, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var PayablesFormulaComponent = (_dec = Ember.inject.service('intl'), _dec2 = Ember.inject.service('store'), _dec3 = Ember.inject.service('stNotificationManager'), _dec4 = Ember.inject.service('stAjax'), _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = (0, _emberConcurrency.task)(function* () {
    var _this = this;

    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
    this.resolvedPayrollValueItems = yield this.payrollValueItems || [];
    this.resolvedExcelFunctions = yield this.functions || [];

    if (this.args.multiStepForm.formCopiedModels.payrollColumn.model.payrollColumnCalculation.excelFunction) {
      (function () {
        var savedText = _this.args.multiStepForm.formCopiedModels.payrollColumn.model.payrollColumnCalculation.excelFunction;
        var payrollValuesUsed = savedText.match(/%%\[+[a-z_A-Z[\]]*]%%/g);
        var excelFunctionsUsed = savedText.match(/[A-Z][A-Z]+/g);
        var payrollValuesUsedWithDuplicatesRemoved = [];
        var excelFunctionsUsedWithDuplicatesRemoved = [];

        if (payrollValuesUsed) {
          var _loop = function (i) {
            if (!payrollValuesUsedWithDuplicatesRemoved.find(value => {
              return value === payrollValuesUsed[i];
            })) {
              payrollValuesUsedWithDuplicatesRemoved.push(payrollValuesUsed[i]);
            }
          };

          for (var i = 0; i < payrollValuesUsed.length; i++) {
            _loop(i);
          }
        }

        if (excelFunctionsUsed) {
          var _loop2 = function (_i) {
            if (!excelFunctionsUsedWithDuplicatesRemoved.find(value => {
              return value === excelFunctionsUsed[_i];
            })) {
              excelFunctionsUsedWithDuplicatesRemoved.push(excelFunctionsUsed[_i]);
            }
          };

          for (var _i = 0; _i < excelFunctionsUsed.length; _i++) {
            _loop2(_i);
          }
        }

        excelFunctionsUsedWithDuplicatesRemoved.sort((a, b) => {
          return b.length - a.length;
        });
        excelFunctionsUsedWithDuplicatesRemoved.forEach(excelFunction => {
          if (_this.resolvedExcelFunctions.find(existingFunction => {
            return existingFunction === excelFunction;
          })) {
            var regexPattern = new RegExp("(".concat(excelFunction, ")(?![^<]*<\\/span>)"), 'g');
            savedText = savedText.replace(regexPattern, "<span class=\"mceNonEditable\" style=\"border-radius: 4px; background-color: rgba(217,44,41, 0.6); padding: 3px 6px;\">".concat(excelFunction, "</span>"));
          }
        });
        payrollValuesUsedWithDuplicatesRemoved.forEach(payrollValue => {
          if (_this.resolvedPayrollValueItems.find(existingPayrollValue => {
            return existingPayrollValue.placeholder === payrollValue;
          })) {
            var replacementItem = _this.resolvedPayrollValueItems.find(existingPayrollValue => {
              return existingPayrollValue.placeholder === payrollValue;
            });

            var preRegexBracketProtection = payrollValue.replace(/\[/g, "\\[");
            var regexPattern = new RegExp(preRegexBracketProtection, 'g');
            savedText = savedText.replace(regexPattern, "<span class=\"".concat(replacementItem.id, " mceNonEditable\" style=\"border-radius: 4px; background-color: rgba(34,161,193, 0.6); padding: 3px 6px;\">").concat(_lodash.default.upperCase(replacementItem.name), "</span>"));
          }
        });
        _this.formulaText = savedText.replace(/=/, _this.genericExcelStartStringWithoutPTag);
      })();
    } else {
      this.formulaText = "<p>".concat(this.genericExcelStartStringWithoutPTag, "<span id='placeholder'>").concat(this.intl.t('pages.customers.accounting.payables.formula_placeholder'), "</span></p>");
    }

    this.formulaWysiwygOptions = {
      toolbar: 'customPayrollValue customFunction | runFunctionButton',
      body_id: 'formula-wysiwyg',
      plugins: 'noneditable',
      menubar: false,
      statusbar: false,
      content_style: 'body {font-size: 14px !important; line-height: 2.5; color: #333 !important} #placeholder {color: #767676;}',
      force_br_newlines: false,
      force_p_newlines: false,
      setup: editor => {
        if (this.args.formMode === 'view') {
          editor.mode.set('readonly');
        } // if = is missing from beginning put it back


        editor.on('click', function () {
          var editorText = editor.getContent();

          if (editorText.search("<p><span id=\"equals-span\" class=\"mceNonEditable\">= </span>") === -1) {
            (0, _jquery.default)('iframe').contents().find('#equals-span').remove();
            var updatedEditorText = editor.getContent();
            var editedEditorText = updatedEditorText.replace(/<p>/, "<p><span id=\"equals-span\" class=\"mceNonEditable\">= </span>");
            editor.setContent(editedEditorText);
            editor.selection.select(editor.getBody(), true);
            editor.selection.collapse(false);
          }
        }); // stops most keystrokes from happening and also protects the = at the beginning

        editor.on('keydown', function (e) {
          var acceptableKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '+', '-', '*', '/', '%', '.', '(', ')', ' ', 'Backspace', 'ArrowRight', 'ArrowLeft', ',', '=', '<', '>'];

          if (!acceptableKeys.includes(e.key)) {
            e.preventDefault();
            e.stopPropagation();
          } else {
            if (e.key != 'ArrowRight' && e.key != 'ArrowLeft') {
              (0, _jquery.default)('.mce-run-formula :button').prop('disabled', false);
            }
          }
        }); // replaces = at the beginning if needed and disables button onChange

        editor.on('keyup', () => {
          var editorText = editor.getContent();

          if (editorText == '') {
            editor.insertContent(this.genericExcelStartString);
            editor.fire('disableButton');
          } else if (editorText == this.genericExcelStartString) {
            editor.fire('disableButton');
          }
        }); // can't paste into component

        editor.on('paste', function (e) {
          e.preventDefault();
          e.stopPropagation();
        }); // updates BE string to save on focus out

        editor.on('focusout', () => {
          this.updateBackEndString(editor);
        }); // mimics a placeholder cause tinyMCE v4 doesn't have it

        editor.on('focus', () => {
          (0, _jquery.default)('iframe').contents().find('#placeholder').remove();
          var editorText = editor.getContent();

          if (editorText == '') {
            editor.insertContent(this.genericExcelStartString);
          }

          var pTag = editor.dom.select('p')[0];
          editor.selection.select(pTag.childNodes[pTag.childNodes.length - 1]);
          editor.selection.collapse(false);
        }); // custom event to disable button

        editor.on('disableButton', function () {
          (0, _jquery.default)('.mce-run-formula :button').prop('disabled', true);
        }); // custom menu item

        editor.ui.registry.addMenuButton('customPayrollValue', {
          type: 'menubutton',
          text: this.intl.t('pages.customers.accounting.payables.values'),
          onAction: function () {},
          fetch: callback => {
            var items = this.valuesMenuFunction(editor);
            callback(items);
          }
        }); // custom menu item

        editor.ui.registry.addMenuButton('customFunction', {
          type: 'menubutton',
          text: this.intl.t('pages.customers.accounting.payables.functions'),
          onAction: function () {},
          fetch: callback => {
            var items = this.functionsMenuFunction(editor);
            callback(items);
          }
        }); // custom button

        editor.ui.registry.addButton('runFunctionButton', {
          text: this.intl.t('pages.customers.accounting.payables.validate'),
          onSetup: () => {
            var text = this.intl.t('pages.customers.accounting.payables.validate');
            (0, _jquery.default)("button > span:contains('".concat(text, "')")).parent().parent().addClass('mce-run-formula'); // initial load of the button - disables button if no real excelFunction has been saved previously (so there's no function to run)

            if (!this.args.multiStepForm.formCopiedModels.payrollColumn.model.payrollColumnCalculation.excelFunction || (0, _jquery.default)('iframe').contents().find('#placeholder').length !== 0) {
              editor.fire('disableButton');
            }
          },
          onAction: () => {
            if (!(0, _jquery.default)('.mce-run-formula :button').is(':disabled')) {
              this.previewFormulaTask.perform();
              editor.fire('disableButton');
            }
          }
        });
      }
    };
  }), _dec9 = (0, _emberConcurrency.task)(function* () {
    var _this2 = this;

    try {
      var bodyContent = (0, _jquery.default)('iframe').contents().find('#formula-wysiwyg').contents();
      var returnDisplayText = '';
      var returnValidationText = '';
      var returnSaveText = '';

      if (bodyContent && bodyContent[0]) {
        (function () {
          var innerTextForValidationAndSaving = bodyContent[0].innerText;
          var innerContent = bodyContent[0].innerHTML;
          var contentWithoutNonEditableClasses = innerContent.replace(/mceNonEditable/g, '');
          var valuesUsed = contentWithoutNonEditableClasses.match(/class="+[a-z, A-Z]*"/g);
          var valuesWithDuplicatesRemoved = [];
          var updatedContent = innerContent;
          var updatedTextForValidation = innerTextForValidationAndSaving;
          var updatedTextForSaving = innerTextForValidationAndSaving;

          if (valuesUsed) {
            var _loop3 = function (i) {
              if (!valuesWithDuplicatesRemoved.find(value => {
                return value === valuesUsed[i].slice(7, valuesUsed[i].length - 1);
              })) {
                valuesWithDuplicatesRemoved.push(valuesUsed[i].slice(7, valuesUsed[i].length - 1));
              }
            };

            for (var i = 0; i < valuesUsed.length; i++) {
              _loop3(i);
            }
          }

          valuesWithDuplicatesRemoved.sort((a, b) => {
            return b.length - a.length;
          });
          valuesWithDuplicatesRemoved.forEach(value => {
            var trimmedValue = value.trim();

            if (trimmedValue != '') {
              var regexPattern = new RegExp(_lodash.default.upperCase(_this2.intl.t('pages.administration.human_resources.' + trimmedValue)), 'g');
              var displayRegexPattern = new RegExp("".concat(_lodash.default.upperCase(_this2.intl.t('pages.administration.human_resources.' + trimmedValue)), "</span>"), 'g');
              updatedContent = updatedContent.replace(displayRegexPattern, "".concat(_lodash.default.upperCase(_this2.intl.t('pages.administration.human_resources.' + trimmedValue)), " @ ").concat(_this2.resolvedPayrollValueItems.find(obj => obj.id === trimmedValue).defaultData, "</span>"));
              updatedTextForValidation = updatedTextForValidation.replace(regexPattern, _this2.resolvedPayrollValueItems.find(obj => obj.id === trimmedValue).defaultData);
              updatedTextForSaving = updatedTextForSaving.replace(regexPattern, "".concat(_this2.resolvedPayrollValueItems.find(obj => obj.id === trimmedValue).placeholder));
            }
          });
          updatedTextForValidation = updatedTextForValidation.replace(/[^\x20-\x7F]/g, '');
          updatedTextForSaving = updatedTextForSaving.replace(/[^\x20-\x7F]/g, '');
          returnDisplayText = updatedContent;
          returnValidationText = updatedTextForValidation;
          returnSaveText = updatedTextForSaving;
        })();
      }

      if (this.args.formMode !== 'view') {
        var dummyModel = this.store.createRecord('payrollColumnCalculation');
        var returnData = yield dummyModel.invokeAction('validate_payroll_column_calculation', {
          verb: 'POST',
          data: {
            data: {
              attributes: {
                formula: returnValidationText
              }
            }
          }
        });
        dummyModel.rollbackAttributes();
        this.args.multiStepForm.formCopiedModels.payrollColumn.model.payrollColumnCalculation.excelFunction = returnSaveText;
        var response = returnData.responsePayload.data.result;

        if (typeof response === 'number') {
          this.formulaPreview = returnDisplayText;
          this.calculatedTotal = returnData.responsePayload.data.result;
          this.errorText = null;
        } else {
          // Catches when BE doesn't throw an error but the excel function returns a #REF! result
          this.errorText = this.intl.t('pages.customers.accounting.payables.generic_error_message');
          this.formulaPreview = null;
          this.calculatedTotal = 0;
        }
      }
    } catch (err) {
      this.errorText = _lodash.default.get(err, 'errors[0].detail', false);

      if (!this.errorText) {
        this.stNotificationManager.addErrorMessages(err);
      }

      this.calculatedTotal = 0;
      this.formulaPreview = null;
    }
  }), _dec10 = (0, _emberConcurrency.task)(function* () {
    var excelFunctions = yield this.store.findAll('excelFunction');
    return excelFunctions.map(excelFunction => {
      return excelFunction.id;
    });
  }), _dec11 = (0, _emberConcurrency.task)(function* () {
    var payrollRoleTypeId = this.args.payrollRoleFunction.get('payrollRole.id');
    var payrollFunctions = yield this.store.findAll('payrollFunction');
    return payrollFunctions.filter(payrollFunction => {
      //Sales
      if (payrollRoleTypeId === '1') {
        return payrollFunction.useType === 'customer'; //Technician
      } else {
        return payrollFunction;
      }
    });
  }), (_class = (_temp = class PayablesFormulaComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor3, this);

      _initializerDefineProperty(this, "stAjax", _descriptor4, this);

      _initializerDefineProperty(this, "formulaPreview", _descriptor5, this);

      _initializerDefineProperty(this, "errorText", _descriptor6, this);

      _initializerDefineProperty(this, "calculatedTotal", _descriptor7, this);

      _defineProperty(this, "resolvedPayrollValueItems", undefined);

      _defineProperty(this, "resolvedExcelFunctions", undefined);

      _initializerDefineProperty(this, "initForm", _descriptor8, this);

      _initializerDefineProperty(this, "previewFormulaTask", _descriptor9, this);

      _initializerDefineProperty(this, "getPayrollFunctions", _descriptor10, this);

      _initializerDefineProperty(this, "getAndFilterPayrollValueItems", _descriptor11, this);

      this.initForm.perform();
    }

    get genericExcelStartString() {
      return "<p><span id=\"equals-span\" class=\"mceNonEditable\">= </span></p>";
    }

    get genericExcelStartStringWithoutPTag() {
      return "<span id=\"equals-span\" class=\"mceNonEditable\">= </span>";
    } // updates the string we save to BE - happens on focusOut of the wysiwyg


    updateBackEndString() {
      var _this3 = this;

      var bodyContent = (0, _jquery.default)('iframe').contents().find('#formula-wysiwyg').contents();
      var returnSaveText = '';

      if (bodyContent && bodyContent[0]) {
        (function () {
          var innerTextForValidationAndSaving = bodyContent[0].innerText;
          var innerContent = bodyContent[0].innerHTML;
          var contentWithoutNonEditableClasses = innerContent.replace(/mceNonEditable/g, '');
          var valuesUsed = contentWithoutNonEditableClasses.match(/class="+[a-z, A-Z]*"/g);
          var valuesWithDuplicatesRemoved = [];
          var updatedTextForValidation = innerTextForValidationAndSaving;
          var updatedTextForSaving = innerTextForValidationAndSaving;

          if (valuesUsed) {
            var _loop4 = function (i) {
              if (!valuesWithDuplicatesRemoved.find(value => {
                return value === valuesUsed[i].slice(7, valuesUsed[i].length - 1);
              })) {
                valuesWithDuplicatesRemoved.push(valuesUsed[i].slice(7, valuesUsed[i].length - 1));
              }
            };

            for (var i = 0; i < valuesUsed.length; i++) {
              _loop4(i);
            }
          }

          valuesWithDuplicatesRemoved.sort((a, b) => {
            return b.length - a.length;
          });
          valuesWithDuplicatesRemoved.forEach(value => {
            var trimmedValue = value.trim();

            if (trimmedValue != '') {
              var regexPattern = new RegExp(_lodash.default.upperCase(_this3.intl.t('pages.administration.human_resources.' + trimmedValue)), 'g');
              updatedTextForValidation = updatedTextForValidation.replace(regexPattern, _this3.resolvedPayrollValueItems.find(obj => obj.id === trimmedValue).defaultData);
              updatedTextForSaving = updatedTextForSaving.replace(regexPattern, "".concat(_this3.resolvedPayrollValueItems.find(obj => obj.id === trimmedValue).placeholder));
              updatedTextForValidation = updatedTextForValidation.replace(/[^\x20-\x7F]/g, '');
            }

            updatedTextForSaving = updatedTextForSaving.replace(/[^\x20-\x7F]/g, '');
          });
          returnSaveText = updatedTextForSaving;
          _this3.args.multiStepForm.formCopiedModels.payrollColumn.model.payrollColumnCalculation.excelFunction = returnSaveText;
          _this3.args.multiStepForm.formCopiedModels.payrollColumn.model.payrollColumnCalculation.excelFunctionVal = updatedTextForValidation;
        })();
      } else {
        this.args.multiStepForm.formCopiedModels.payrollColumn.model.payrollColumnCalculation.excelFunction = null;
      }
    } // previewFormulaTask


    get functions() {
      return this.getPayrollFunctions.perform();
    }

    get payrollValueItems() {
      return this.getAndFilterPayrollValueItems.perform();
    }

    valuesMenuFunction(editor) {
      var payrollValues = this.resolvedPayrollValueItems.sortBy('name');
      var finalMenu = [];
      payrollValues.forEach(payrollItem => {
        finalMenu.push({
          type: 'menuitem',
          text: payrollItem.name,
          onAction: () => {
            editor.insertContent("<span class=\"".concat(payrollItem.id, " mceNonEditable\" style=\"border-radius: 4px; background-color: rgba(34,161,193, 0.6); padding: 3px 6px;\">").concat(_lodash.default.upperCase(payrollItem.name), "</span>"));
            (0, _jquery.default)('.mce-run-formula :button').prop('disabled', false);
          }
        });
      });
      return finalMenu;
    }

    functionsMenuFunction(editor) {
      var functions = this.resolvedExcelFunctions;
      var finalMenu = [];
      functions.forEach(individualFunction => {
        finalMenu.push({
          type: 'menuitem',
          text: _lodash.default.startCase(individualFunction),
          onAction: () => {
            editor.insertContent("<span class=\"mceNonEditable\" style=\"border-radius: 4px; background-color: rgba(217,44,41, 0.6); padding: 3px 6px;\">".concat(individualFunction, "</span>"));
            (0, _jquery.default)('.mce-run-formula :button').prop('disabled', false);
          }
        });
      });
      return finalMenu;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stAjax", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "formulaPreview", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "errorText", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "calculatedTotal", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "initForm", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "previewFormulaTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "getPayrollFunctions", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "getAndFilterPayrollValueItems", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PayablesFormulaComponent;
});