define("securitytrax-ember-ui/models/customer-equipment", ["exports", "securitytrax-ember-ui/models/base_models/customer-equipment"], function (_exports, _customerEquipment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerEquipment.default.extend({
    isBulk: false,
    bulkEditing: null,
    zoneTitle: Ember.computed('zone', 'zoneNumber', function () {
      var zoneNumber = this.zoneNumber;
      var zone = this.zone;
      var zoneNumberHasValue = typeof zoneNumber === 'string' && zoneNumber.length;
      var zoneHasValue = typeof zone === 'string' && zone.length;
      var dash = zoneNumberHasValue && zoneHasValue ? ' - ' : '';
      var zoneNumberVal = zoneNumberHasValue ? zoneNumber : '';
      var zoneVal = zoneHasValue ? zone : '';
      return "".concat(zoneNumberVal).concat(dash).concat(zoneVal);
    })
  });

  _exports.default = _default;
});