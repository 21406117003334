define("securitytrax-ember-ui/components/nav/top-nav/nav/nav-general/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xjDnlRgX",
    "block": "{\"symbols\":[],\"statements\":[[10,\"ul\"],[14,0,\"cell auto top-sub-nav\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,7],[\"user_hierarchy_trees.view\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,6],[[30,[36,5],[\"orgChart\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,4],null,[[\"tagName\",\"class\",\"route\",\"model\"],[\"li\",\"top-nav-item\",\"administration.org-chart\",1]],[[\"default\"],[{\"statements\":[[2,\"                \"],[11,\"span\"],[4,[38,1],[[32,0],[30,[36,2],[[30,[36,1],[[32,0],\"toggleNavigation\"],null],[30,[36,1],[[32,0],\"hideNavDropdown\",\".admin-popover\"],null]],null]],null],[12],[2,\"\\n                    \"],[1,[30,[36,0],[\"icn-network\"],[[\"class\"],[\"icn-network--org-chart\"]]]],[2,\" \"],[10,\"label\"],[12],[1,[30,[36,3],[\"common.nav.org_chart\"],null]],[10,\"span\"],[14,0,\"external-link\"],[12],[6,[37,4],null,[[\"target\",\"bubbles\",\"route\",\"model\"],[\"_blank\",false,\"administration.org-chart\",1]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"icn-new-window\"],null]]],\"parameters\":[]}]]],[13],[13],[2,\"\\n                \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"svg-icon\",\"action\",\"queue\",\"t\",\"link-to\",\"feature-is-enabled\",\"if\",\"has-grants\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/nav/top-nav/nav/nav-general/template.hbs"
    }
  });

  _exports.default = _default;
});