define("securitytrax-ember-ui/components/multi-step-form/forms/accounting/invoice-statements/send-customer-statement/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms/accounting/invoice-statements/invoice-statements-base"], function (_exports, _emberConcurrency, _invoiceStatementsBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CreateEditCustomerStatement = (_dec = Ember._tracked, _dec2 = (0, _emberConcurrency.task)(function* () {
    var customerAccountingInformation = yield this.args.customer.get('customerAccountingInformation');
    var invoiceStatementInformation = customerAccountingInformation.filterBy('useType', 'invoice_statement')[0];
    var defaultInformation = customerAccountingInformation.filterBy('useType', 'default')[0]; // Use statement billing info emails, then try default billing info emails, then just get customer email

    if (invoiceStatementInformation && !invoiceStatementInformation.usePrimary) {
      this.toAddresses = invoiceStatementInformation.emails;
    } else if (!invoiceStatementInformation && defaultInformation && !defaultInformation.usePrimary) {
      this.toAddresses = defaultInformation.emails;
    } else {
      this.toAddresses = this.args.customer.email;
    }
  }), (_class = (_temp = class CreateEditCustomerStatement extends _invoiceStatementsBase.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "toAddresses", _descriptor, this);

      _initializerDefineProperty(this, "initFormTask", _descriptor2, this);

      this.initFormTask.perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "toAddresses", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "initFormTask", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CreateEditCustomerStatement;
});