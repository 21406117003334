define("securitytrax-ember-ui/models/base_models/customer-duplicate-default", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    abortCode: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    accountClass: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    accountNumber: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    accountType: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    accountIsCancelled: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    activationFee: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    address: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    affiliate: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    affiliateMemberNumber: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    agencyContacts: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    altSalesRepresentative: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    apartmentSuite: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    bestTimeToContact: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    birthdate: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    businessContact: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    businessName: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    cancelDate: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    cancelReason: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    cellPhone: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    city: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    confirmationNumber: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    contractTerm: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    county: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    creditScore: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    creditTrackingNumber: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    crossStreet: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    customerNumber: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    dealerNumber: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    email: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    emergencyContacts: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    guardResponse: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    guardResponseFee: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    homeOwnership: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    isInstalled: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    invoicing: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    monitoredBy: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    monitoringPlan: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    monthlyCharge: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    officeLocation: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    otherPhone: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    primaryLanguage: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    primaryPhone: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    rebateCheckAmount: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    receiverNumber: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    saleDate: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    salesRepresentative: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    serialMan: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    socialSecurityNumber: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    source: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    sourceCampaign: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    spouseName: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    state: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    twoWayVoice: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    warrantyTerm: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    workPhone: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    zipCode: (0, _model.attr)('boolean', {
      'readOnly': false
    })
  });

  _exports.default = _default;
});