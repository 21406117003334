define("securitytrax-ember-ui/components/pages/report-page/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DXqZMRuP",
    "block": "{\"symbols\":[\"navData\",\"&default\"],\"statements\":[[1,[30,[36,3],null,[[\"title\",\"mobileHamburgerVisible\"],[[35,2,[\"nameid\"]],true]]]],[2,\"\\n\\n\"],[6,[37,4],null,null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"nav-item\"],[12],[1,[30,[36,0],[\"icn-home\"],null]],[1,[30,[36,1],[\"pages.reports.nav.home\"],null]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"nav-item\"],[12],[1,[30,[36,0],[\"icn-feature-users\"],null]],[1,[30,[36,1],[\"pages.reports.nav.customers\"],null]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"page-content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"scroll-page\"],[12],[2,\"\\n        \"],[18,2,null],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"svg-icon\",\"t\",\"report\",\"nav/top-nav-sub\",\"nav/base-left-nav\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/report-page/template.hbs"
    }
  });

  _exports.default = _default;
});