define("securitytrax-ember-ui/mixed-in-classes/set-user", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var SetUser = (_dec = Ember.inject.service('store'), _dec2 = Ember._tracked, _dec3 = (0, _emberConcurrency.task)(function* (model, userAttributeName, userId) {
    this.settingUser = true;
    var uid = userId;

    if (uid === null) {
      this._setUserInternal(model, userAttributeName, null);
    } else {
      if (typeof uid === 'object') {
        uid = uid.id;
      }

      var user = yield this.store.findRecord('user', uid);

      this._setUserInternal(model, userAttributeName, user);
    }

    this.settingUser = false;
  }), _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class SetUser extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "settingUser", _descriptor2, this);

      _initializerDefineProperty(this, "setUserTask", _descriptor3, this);
    }

    _setUserInternal(model, userAttributeName, user) {
      if (model instanceof Ember.Object) {
        model.set(userAttributeName, user);
      } else {
        model[userAttributeName] = user;
      }
    }

    _getUser(userId) {
      var uid = userId;

      if (uid === null) {
        return null;
      }

      if (typeof uid === 'object') {
        uid = uid.id;
      }

      return this.store.findRecord('user', uid);
    }

    setUser(model, userAttributeName, userId) {
      return this.setUserTask.perform(model, userAttributeName, userId);
    }

    getUser(userId) {
      return this._getUser(userId);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "settingUser", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "setUserTask", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setUser", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "setUser"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getUser", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "getUser"), _class.prototype)), _class));
  _exports.default = SetUser;
});