define("securitytrax-ember-ui/components/nav/top-nav/user-notifications/component", ["exports", "ember-concurrency", "moment", "securitytrax-ember-ui/mixins/user-notifications-mixin"], function (_exports, _emberConcurrency, _moment, _userNotificationsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_userNotificationsMixin.default, {
    store: Ember.inject.service(),
    userNotificationsService: Ember.inject.service(),
    intl: Ember.inject.service(),
    scrollText: null,
    notificationsListLength: 0,
    expanded: Ember.computed('hideNotifications', function () {
      if (this.hideNotifications) {
        return '';
      } else {
        return 'expanded';
      }
    }),
    notificationsListLengthObserver: Ember.observer('userNotifications.[]', function () {
      this.set('notificationsListLength', this.get('userNotifications.length'));
    }),
    notificationLengthObserver: Ember.observer('notificationsListLength', function () {
      //allow the notification results to populate first
      Ember.run.later(() => {
        var notificationsListLength = this.notificationsListLength;

        if (notificationsListLength > 0) {
          var resultsDiv = document.getElementsByClassName('notifications-body');

          if (resultsDiv.length > 0) {
            var scrollbarShowing = resultsDiv[0].scrollHeight > resultsDiv[0].clientHeight;

            if (scrollbarShowing && notificationsListLength > 1) {
              this.set('scrollText', this.intl.t('pages.customers.user_notifications.notification_results', {
                count: notificationsListLength
              }) + '. ' + this.intl.t('pages.customers.search_results_scroll'));
            } else {
              this.set('scrollText', null);
            }
          } else if (resultsDiv.length === 0) {
            this.set('scrollText', null);
          }
        }
      });
    }),
    deleteNotificationTask: (0, _emberConcurrency.task)(function* (row) {
      try {
        row.set('isDeleting', true);
        var notificationToDelete = yield this.store.findRecord('user_notification', row.get('id'));
        yield notificationToDelete.destroyRecordWithRollbackTask.perform();
      } catch (err) {
        row.set('isDeleting', false);
        (false && Ember.warn(err, {
          id: 'user-notifications.deleteNotificationsTask'
        }));
      }
    }),
    dismissAllTask: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('allLoaded', false);
        var notifications = yield this.userNotifications;
        var notificationsToDelete = [];
        notifications.forEach(notification => {
          notificationsToDelete.push(this.deleteNotificationTask.perform(notification, true));
        });
        yield (0, _emberConcurrency.all)(notificationsToDelete);
        this.set('allLoaded', true);
        this.userNotificationsService.queryNotifications(true);
      } catch (err) {
        this.set('allLoaded', true);
        (false && Ember.warn(err, {
          id: 'user-notifications.dismissAllTask'
        }));
      }
    }),
    readAllTask: (0, _emberConcurrency.task)(function* () {
      try {
        var notifications = yield this.userNotifications;
        var allSaves = [];
        notifications.forEach(notification => {
          if (notification.get('seenDate') == undefined) {
            notification.set('seenDate', (0, _moment.default)());
            allSaves.push(notification.save());
          }
        });
        yield (0, _emberConcurrency.all)(allSaves);
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err);
        (false && Ember.warn(err, {
          id: 'user-notifications.readAllTask'
        }));
      }
    }),
    actions: {
      toggleRead(row) {
        if (row.get('seenDate')) {
          row.set('seenDate', null);
        } else {
          row.set('seenDate', (0, _moment.default)());
        }

        row.save();
      },

      stopProp(e) {
        e.stopPropagation();
      }

    }
  });

  _exports.default = _default;
});