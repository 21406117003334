define("securitytrax-ember-ui/models/base_models/customer-payable", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    amount: (0, _model.attr)('number-precision', {
      'readOnly': true,
      'precision': 2
    }),
    calculationInfo: (0, _model.attr)('string', {
      'readOnly': false
    }),
    notes: (0, _model.attr)('string', {
      'readOnly': false
    }),
    expectedPayDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    status: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['pending', 'active', 'deleted']
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    payrollRoleFunctionLevel: (0, _model.belongsTo)('payroll-role-function-level', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    payrollRoleFunction: (0, _model.belongsTo)('payroll-role-function', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    payrollRole: (0, _model.belongsTo)('payroll-role', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    payrollPaymentStatus: (0, _model.belongsTo)('payroll-payment-status', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    customer: (0, _model.belongsTo)('customer', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    payOnCustomer: (0, _model.belongsTo)('customer', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    payOnTechSchedule: (0, _model.belongsTo)('calendar-event', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    payOnCalendarEvent: (0, _model.belongsTo)('calendar-event', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    customerEntity: (0, _model.belongsTo)('customer', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    userEntity: (0, _model.belongsTo)('user', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    leadCompanyEntity: (0, _model.belongsTo)('lead-company', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    leadCompanyCampaignEntity: (0, _model.belongsTo)('lead-company-campaign', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    governmentRegulationEntity: (0, _model.belongsTo)('government-regulation', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    customerPayableLineItems: (0, _model.hasMany)('customer-payable-line-item', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    customerPayablePayments: (0, _model.hasMany)('customer-payable-payment', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    tags: (0, _model.hasMany)('tag', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});