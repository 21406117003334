define("securitytrax-ember-ui/models/base_models/report-category", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    objectId: (0, _model.attr)('number', {
      'readOnly': false
    }),
    name: (0, _model.attr)('string', {
      'readOnly': false
    }),
    about: (0, _model.attr)('string', {
      'readOnly': false
    }),
    image: (0, _model.attr)('string', {
      'readOnly': false
    }),
    compatibility: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['v2', 'v3', 'all']
    }),
    status: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['active', 'deleted']
    })
  });

  _exports.default = _default;
});