define("securitytrax-ember-ui/classes/edit-lead-dashboard", ["exports", "securitytrax-ember-ui/classes/edit-dashboard"], function (_exports, _editDashboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EditLeadDashboard = (_dec = Ember._tracked, (_class = (_temp = class EditLeadDashboard extends _editDashboard.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "dashboardLocation", 'lead_dashboard');

      _defineProperty(this, "titleKeyPrefix", 'pages.customers.dashboard.');

      _defineProperty(this, "titleKeySuffix", '.title');

      _defineProperty(this, "componentPrefix", 'components/lead/dashboard/cards/');

      _initializerDefineProperty(this, "customer", _descriptor, this);
    }

    get addedCards() {
      return this.addedCardsTask.perform();
    }

    get defaultCards() {
      return [{
        'display': true,
        'card': 'appointments-card',
        'order': 0,
        'config': {
          'grant': 'calendar_events.view',
          'titleKey': 'pages.customers.dashboard.appointments_card.title',
          'link': 'leads.appointments',
          'cardLocationOverride': 'components/customer/dashboard/'
        }
      }, {
        'display': true,
        'card': 'interactions-card',
        'order': 1,
        'config': {
          'grant': 'lead_contacts.view',
          'titleKey': 'pages.customers.dashboard.interactions_card.title',
          'link': 'leads.information'
        }
      }, {
        'display': true,
        'card': 'last-interactions-card',
        'order': 2,
        'config': {
          'grant': 'customer_dashboard_cards_last_interaction.view',
          'titleKey': 'pages.customers.dashboard.last_interactions_card.title',
          'link': 'leads.information'
        }
      }, {
        'display': true,
        'card': 'quotes-card',
        'order': 3,
        'config': {
          'grant': 'customer_quotes.view',
          'titleKey': 'pages.customers.dashboard.quotes_card.title',
          'link': 'leads.accounting'
        }
      }, {
        'display': true,
        'card': 'notes-card',
        'order': 4,
        'config': {
          'grant': 'customer_notes.view',
          'cardLocationOverride': 'components/customer/dashboard/',
          'fromDashboard': 'lead',
          'titleKey': 'pages.customers.dashboard.notes_card.title',
          'link': 'leads.notes'
        }
      }, {
        'display': true,
        'card': 'customer-messages-card',
        'order': 5,
        'config': {
          'grant': 'lead_messages.view',
          'cardLocationOverride': 'components/customer/dashboard/',
          'titleKey': 'pages.customers.dashboard.customer_messages_card.title',
          'link': 'leads.messages'
        }
      }];
    }

    get availableCards() {
      return {
        'appointments-card': {
          'enabled': true,
          'category': '',
          'card': 'appointments-card',
          'config': {
            'grant': 'calendar_events.view',
            'titleKey': 'pages.customers.dashboard.appointments_card.title',
            'date_range_options': 'none',
            'card_focus_options': 'none',
            'link': 'leads.appointments',
            'cardLocationOverride': 'components/customer/dashboard/'
          }
        },
        'interactions-card': {
          'enabled': true,
          'category': '',
          'card': 'interactions-card',
          'config': {
            'grant': 'lead_contacts.view',
            'titleKey': 'pages.customers.dashboard.interactions_card.title',
            'date_range_options': 'none',
            'card_focus_options': 'none',
            'link': 'leads.information'
          }
        },
        'last-interactions-card': {
          'enabled': true,
          'category': '',
          'card': 'last-interactions-card',
          'config': {
            'grant': 'customer_dashboard_cards_last_interaction.view',
            'titleKey': 'pages.customers.dashboard.last_interactions_card.title',
            'date_range_options': 'none',
            'card_focus_options': 'none',
            'link': 'leads.information'
          }
        },
        'quotes-card': {
          'enabled': true,
          'category': '',
          'card': 'quotes-card',
          'config': {
            'grant': 'customer_quotes.view',
            'titleKey': 'pages.customers.dashboard.quotes_card.title',
            'date_range_options': 'none',
            'card_focus_options': 'none',
            'link': 'leads.accounting'
          }
        },
        'notes-card': {
          'enabled': true,
          'category': '',
          'card': 'notes-card',
          'config': {
            'grant': 'customer_notes.view',
            'titleKey': 'pages.customers.dashboard.notes_card.title',
            'date_range_options': 'none',
            'card_focus_options': 'none',
            'link': 'leads.notes',
            'cardLocationOverride': 'components/customer/dashboard/'
          }
        },
        'customer-messages-card': {
          'enabled': true,
          'category': '',
          'card': 'customer-messages-card',
          'config': {
            'grant': 'lead_messages.view',
            'titleKey': 'pages.customers.dashboard.customer_messages_card.title',
            'date_range_options': 'none',
            'card_focus_options': 'none',
            'link': 'leads.messages',
            'cardLocationOverride': 'components/customer/dashboard/'
          }
        }
      };
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "customer", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  })), _class));
  _exports.default = EditLeadDashboard;
});