define("securitytrax-ember-ui/components/multi-step-form/forms/administration/users/my-account/profile-photo/component", ["exports", "cropperjs", "securitytrax-ember-ui/utils/obj-helpers", "lodash", "ember-concurrency", "ember-inflector"], function (_exports, _cropperjs, _objHelpers, _lodash, _emberConcurrency, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    stNotificationManager: Ember.inject.service(),
    intl: Ember.inject.service(),
    userService: Ember.inject.service(),
    _cropper: undefined,
    options: undefined,
    showFileInput: undefined,
    src: undefined,
    uploadedImageURL: undefined,
    url: undefined,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        showFileInput: true,
        src: '',
        _cropper: null
      });
      var defaultOptions = {
        aspectRatio: 1,
        zoomable: false
      };
      var passedOptions = this.getWithDefault('options', {});

      var compiledOptions = _lodash.default.merge(defaultOptions, passedOptions);

      this.set('options', compiledOptions);
    },

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData("step1", 'buttons', [{
        text: this.intl.t('common.actions.cancel'),
        click: () => {
          multiStepForm.resetForm(false);
          this.set('formMode', 'view');
        },
        classNamesString: 'st-btn btn-secondary user-cancel-button'
      }, {
        text: this.intl.t('pages.users.my_account.cropper.get_image'),
        click: () => {
          document.querySelector('#cropperjs-input-image').click();
        },
        classNamesString: 'st-btn btn-secondary get-image'
      }, {
        text: this.intl.t('pages.users.my_account.cropper.save_cropped_image'),
        click: () => {
          return this.saveCroppedImageTask.perform();
        },
        classNamesString: 'st-btn-primary save-cropped-image'
      }]);
    },

    formModeObserver: Ember.observer('formMode', function () {
      if (this.formMode === 'edit') {
        this._setup();
      } else {
        var _cropper = this._cropper;

        if (_cropper !== null) {
          _cropper.destroy();

          this.set('_cropper', null);
        }
      }
    }),

    _setup() {
      if (this && (this.isDestroyed || this.isDestroying || !this.element || this._cropper !== null)) {
        return;
      }

      if (window && window.document) {
        var image = document.getElementById("image-cropper-".concat(this.elementId));
        var options = this.options; // Need a copy because Cropper does not seem to like the Ember EmptyObject that is created from the `{{hash}}` helper

        var opts = Ember.assign({}, options);
        Ember.setProperties(this, {
          _cropper: new _cropperjs.default(image, opts),
          url: window.URL || window.webkitURL
        });
      }
    },

    didUpdateAttrs() {
      this._super(...arguments);

      var cropper = this._cropper;

      if (cropper) {
        cropper.replace(this.src);
      }
    },

    saveCroppedImageTask: (0, _emberConcurrency.task)(function* (buttonPromise) {
      var _cropper = this._cropper;

      var canvas = _cropper.getCroppedCanvas();

      var data = _cropper.getData(true);

      yield this.uploadFileTask.perform(canvas.toDataURL(), data, buttonPromise);
    }),
    previewFile: (0, _emberConcurrency.task)(function* () {
      var inputImage = document.querySelector('#cropperjs-input-image');
      var files = inputImage.files;
      var uploadedImageURL = yield this.uploadedImageURL;

      var _cropper = yield this._cropper;

      var file;

      if (_cropper && files && files.length) {
        file = files[0];

        if (/^image\/\w+/.test(file.type)) {
          if (uploadedImageURL) {
            URL.revokeObjectURL(uploadedImageURL);
          }

          uploadedImageURL = URL.createObjectURL(file);

          _cropper.replace(uploadedImageURL);

          this.set('uploadedImageUrl', uploadedImageURL);
          inputImage.value = null;
        } else {
          this.stNotificationManager.addWarning(this.intl.t('pages.users.my_account.cropper.please_choose_image_file'));
        }
      }
    }),
    uploadFileTask: (0, _emberConcurrency.task)(function* (image, data) {
      data; // data is not currently needed, but is passed in anyway

      var attributes = {
        // x: data.x,
        // y: data.y,
        // width: data.width,
        // height: data.height,
        base64_data: image
      };
      return yield this.get('model').invokeAction('upload_profile_picture', {
        verb: 'POST',
        data: {
          data: {
            type: (0, _emberInflector.pluralize)(this.model.constructor.modelName) + '_action',
            attributes: attributes
          }
        },
        processResponse: true
      });
    }),
    deleteFileTask: (0, _emberConcurrency.task)(function* () {
      var attributes = {
        base64_data: ''
      };
      return yield this.get('model').invokeAction('upload_profile_picture', {
        verb: 'POST',
        data: {
          data: {
            type: (0, _emberInflector.pluralize)(this.model.constructor.modelName) + '_action',
            attributes: attributes
          }
        },
        processResponse: true
      });
    })
  });

  _exports.default = _default;
});