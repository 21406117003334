define("securitytrax-ember-ui/mixins/table-column-sort", ["exports", "lodash", "moment"], function (_exports, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Shared functionality to sort a table by a column
   *
   * @mixin TableColumnSort
   * @extends Ember.Mixin
   */
  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    stSortCurrentSortColumn: undefined,
    stSortAscending: undefined,
    stSortString: undefined,
    indexObject: undefined,

    // the object to set the sorting and column on (for cases where there are multiple sortable pages on the same page)
    //reset paginated lists to page 1
    _resetPagination() {
      this.set('page', 1);
      this.set('limit', this.limit);
    },

    _setArrowIndicators(column, ascending, listLoadedVar = null) {
      if (listLoadedVar) {
        this.set(listLoadedVar, false);
      }

      this.set('stSortCurrentSortColumn', column);
      this.set('stSortAscending', ascending);
    },

    _setArrowIndicatorsByIndex(indexObject, column, ascending, listLoadedVar = null) {
      if (listLoadedVar) {
        this.set(listLoadedVar, false);
      }

      Ember.set(indexObject, 'stSortCurrentSortColumn', column);
      Ember.set(indexObject, 'stSortAscending', ascending);
    },

    // sorting for a paginated list
    sortPaginatedList(column, ascending, listLoadedVar = null) {
      this._setArrowIndicators(column, ascending, listLoadedVar);

      var asc = ascending !== null && ascending === true ? '' : '-';
      this.set('stSortString', asc + column); // for use with a query

      this._resetPagination();

      return true;
    },

    // sorting for a NON paginated list
    sortList(column, ascending, listLoadedVar = null) {
      this._setArrowIndicators(column, ascending, listLoadedVar);

      return true;
    },

    // sorting for a NON paginated list
    sortListByIndex(indexObject, column, ascending, listLoadedVar = null) {
      this._setArrowIndicatorsByIndex(indexObject, column, ascending, listLoadedVar);

      return true;
    },

    sortEmberDataCollection(collection, column, ascending = null) {
      var sortedList = [];
      var list = collection.toArray();

      if (ascending === true) {
        sortedList = _lodash.default.orderBy(list, [function (item) {
          if (_moment.default.isMoment(item.get(column))) {
            return (0, _moment.default)(item.get(column)).format('YYYYMMDD');
          }

          if (!isNaN(Number(item.get(column)))) {
            return Number(item.get(column));
          } else {
            return item.get(column);
          }
        }], ['asc']);
      } else if (ascending === false) {
        sortedList = _lodash.default.orderBy(list, [function (item) {
          if (!isNaN(Number(item.get(column)))) {
            return Number(item.get(column));
          } else {
            return item.get(column);
          }
        }], ['desc']);
      }

      return sortedList;
    },

    sortMobilePaginatedList(sortString, listLoadedVar = null) {
      if (listLoadedVar) {
        this.set(listLoadedVar, false);
      }

      this.set('stSortString', sortString);

      this._resetPagination();
    }

  });

  _exports.default = _default;
});