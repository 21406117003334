define("securitytrax-ember-ui/components/pages/all-work-orders/appointment-list/component", ["exports", "@glimmer/component", "jquery", "ember-concurrency"], function (_exports, _component, _jquery, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var AppointmentList = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = (0, _emberConcurrency.task)(function* () {
    var workOrder = this.args.row;

    if (this.args.useMultiCall) {
      var successNotifications = [];
      var errorNotifications = [];
      var multicallKey = 'work_order_event_on_site_info_' + this.args.row.id;
      var multicallSubkeys = {};
      Ember.set(this, 'multiCallKey', multicallKey);
      var count = 0;

      for (var x = 0; x < workOrder.calendarEvents.length; x++) {
        var event = workOrder.calendarEvents.objectAt(x);
        count = ++count;
        var multicallSubkey = "".concat(event.jsonParseId.calendar_category, "_").concat(event.jsonParseId.id, "_event_on_site_info_subkey");
        multicallSubkeys[multicallSubkey] = true;
      }

      yield this.stMulticallService.setupMulticall({
        multicallKey,
        multicallSubkeys
      }, () => {
        for (var _x = 0; _x < workOrder.calendarEvents.length; _x++) {
          var _event = workOrder.calendarEvents.objectAt(_x);

          var _multicallSubkey = "".concat(_event.jsonParseId.calendar_category, "_").concat(_event.jsonParseId.id, "_event_on_site_info_subkey");

          this.createOnSiteMulticall(_event, {
            multicallKey,
            multicallSubkey: _multicallSubkey
          }, successNotifications, errorNotifications);
        }
      });
    }
  }), _dec8 = (0, _emberConcurrency.task)(function* () {
    if (this.args.useGetAppointmentsAction) {
      this.totalAppointments = this.args.calendarEvents.length;
      return this.args.statusTotals;
    } else {
      this.totalAppointments = (yield this.args.row.get('calendarEvents')).length;
      return {
        total_completed: yield this.args.row.completedAppointmentsTask.perform(this.multiCallKey)
      };
    }
  }), _dec9 = Ember._action, _dec10 = Ember._action, (_class = (_temp = class AppointmentList extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stMulticallService", _descriptor2, this);

      _initializerDefineProperty(this, "showExpandButton", _descriptor3, this);

      _initializerDefineProperty(this, "collapsed", _descriptor4, this);

      _initializerDefineProperty(this, "appointmentData", _descriptor5, this);

      _initializerDefineProperty(this, "totalAppointments", _descriptor6, this);

      _defineProperty(this, "multiCallKey", undefined);

      _initializerDefineProperty(this, "workOrdersMultiCall", _descriptor7, this);

      _initializerDefineProperty(this, "getAppointmentStatusTask", _descriptor8, this);

      (0, _jquery.default)(window).on('resize', Ember.run.bind(this, this.appointmentLoaded));
      this.workOrdersMultiCall.perform();
    }

    willDestroy() {
      (0, _jquery.default)(window).off('resize');
    }

    get calendarEvents() {
      if (this.args.useGetAppointmentsAction) {
        return this.args.calendarEvents;
      } else {
        return this.args.row.calendarEvents;
      }
    }

    createOnSiteMulticall(event, multicallOptions, successNotifications, errorNotifications) {
      try {
        this.stMulticallService.registerRequest(multicallOptions, event.invokeAction('calendar_event_on_site_info', {
          verb: 'POST',
          data: {
            data: {}
          },
          processResponse: false,
          multicallOptions: multicallOptions
        }));
        successNotifications.push(this.intl.t('pages.customers.appointments.on_site_info.success_message'));
      } catch (err) {
        errorNotifications.push(this.intl.t('pages.customers.appointments.on_site_info.success_message'));
      }
    }

    get getAppointmentStatus() {
      return this.getAppointmentStatusTask.perform();
    }

    appointmentLoaded() {
      var idSelector = "#work-order-appointments-".concat(this.args.row.id);
      var showExpandButton = (0, _jquery.default)(idSelector + ' .appointments-list').outerHeight() > (0, _jquery.default)(idSelector + '.work-order-appointments-row').height() + 10;
      Ember.set(this, 'showExpandButton', showExpandButton);

      if (!showExpandButton) {
        Ember.set(this, 'collapsed', true);
      }
    }

    toggleAppointments(event) {
      event.stopPropagation();
      Ember.set(this, 'collapsed', !this.collapsed);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stMulticallService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showExpandButton", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "collapsed", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "appointmentData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "totalAppointments", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "workOrdersMultiCall", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "getAppointmentStatusTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "appointmentLoaded", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "appointmentLoaded"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleAppointments", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "toggleAppointments"), _class.prototype)), _class));
  _exports.default = AppointmentList;
});