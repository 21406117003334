define("securitytrax-ember-ui/utils/locks", ["exports", "securitytrax-ember-ui/utils/doors", "securitytrax-ember-ui/enums/LockStatus", "securitytrax-ember-ui/utils/i18n"], function (_exports, _doors, _LockStatus, _i18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getLockStateCSS = getLockStateCSS;
  _exports.getLockStateText = getLockStateText;
  _exports.getLockLabel = getLockLabel;

  /**
   * Utility helper module for lock devices.
   * @module
   */

  /**
   * Returns the appropriate CSS class to show the current state.
   *
   * @param {number} state The state to show.
   * @param {boolean} [flipState=false] If true we should return the CSS for the opposite state (does not apply to "Unknown" state).
   *
   * @returns {String}
   */
  function getLockStateCSS(state, flipState = false) {
    return (0, _doors.getDoorStateCSS)(state, [_LockStatus.Locked, _LockStatus.Unlocked], ['locked', 'unlocked'], flipState);
  }
  /**
   * Returns the indicated state in text form.
   *
   * @param {number} state The state to represent.
   *
   * @returns {String}
   */


  function getLockStateText(state) {
    return (0, _i18n.getTranslation)("home.locks.".concat(getLockStateCSS(state)));
  }
  /**
   * Creates and returns a lock label configuration object based on the passed device model.
   *
   * @param {models.devices.Lock} model The lock model.
   *
   * @returns {SimpleLabelType}
   * @static
   */


  function getLockLabel(model) {
    var state = model.get('state');
    return (0, _doors.getStaticLabel)(model.get('description'), getLockStateText(state), getLockStateCSS(state));
  }
});