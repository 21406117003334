define("securitytrax-ember-ui/services/lead-statuses", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    getLeadStatusOptionsTask: (0, _emberConcurrency.task)(function* (blackListedIds = ['1']) {
      var leadStatuses = yield this.store.findAll('leadStatus');
      var sortedLeadStatuses = leadStatuses.toArray().sort(function (a, b) {
        if (a.get('sort') === b.get('sort')) {
          return 0;
        }

        return a.get('sort') < b.get('sort') ? -1 : 1;
      });
      var filteredLeadStatuses = sortedLeadStatuses.filter(currLeadStatus => {
        return !blackListedIds.includes(currLeadStatus.get('id'));
      });
      return filteredLeadStatuses;
    })
  });

  _exports.default = _default;
});