define("securitytrax-ember-ui/components/lists/sortable-column/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4CQnyfj2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,0,[30,[36,2],[\"sortable-header property-\",[35,1]],null]],[12],[2,\"\\n\\n\\n    \"],[10,\"div\"],[14,0,\"sortable-header__name\"],[12],[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[30,[36,6],[[35,5],[35,1]],null],[30,[36,4],[[35,3],null],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"sorting-on\"],[15,\"onclick\",[30,[36,0],[[32,0],\"clearSorting\"],null]],[12],[2,\"\\n                \"],[18,1,null],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"\"],[12],[2,\"\\n                \"],[18,1,null],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"sorting-arrows\"],[12],[2,\"\\n        \"],[1,[30,[36,9],null,[[\"ascending\",\"currentSortColumn\",\"property\",\"chevronDirection\",\"click\"],[[35,3],[35,5],[35,1],\"up\",[30,[36,0],[[32,0],\"sortTheListBy\",\"up\"],null]]]]],[2,\"\\n\\n        \"],[1,[30,[36,9],null,[[\"ascending\",\"currentSortColumn\",\"property\",\"chevronDirection\",\"click\"],[[35,3],[35,5],[35,1],\"down\",[30,[36,0],[[32,0],\"sortTheListBy\",\"down\"],null]]]]],[2,\"\\n    \"],[13],[2,\"\\n\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"property\",\"concat\",\"ascending\",\"not-eq\",\"currentSortColumn\",\"eq\",\"and\",\"if\",\"lists/sortable-column/chevron\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/lists/sortable-column/template.hbs"
    }
  });

  _exports.default = _default;
});