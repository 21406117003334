define("securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/adc/re-usable-forms/customer-information/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isTerminated: Ember.computed('customerInformation.is_terminated', function () {
      return this.get('customerInformation.is_terminated') ? 'Yes' : 'No';
    })
  });

  _exports.default = _default;
});