define("securitytrax-ember-ui/components/multi-step-form/forms/invoices/reusable-forms/invoice-details-footer/component", ["exports", "@glimmer/component", "lodash", "ember-concurrency"], function (_exports, _component, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var InvoiceDetailsFooter = (_dec = (0, _emberConcurrency.task)(function* () {
    var invoiceType = this.args.multiStepForm.formOriginalModelsGetVal('invoice.model.invoiceType');
    var paidTotal = 0;

    if (invoiceType == 'credit_memo') {
      var invoice = this.args.multiStepForm.formOriginalModelsGetVal('invoice.model');
      var creditMemoPayment = yield invoice.get('creditMemoPayment');

      if (creditMemoPayment) {
        paidTotal = parseFloat(Number(creditMemoPayment.responseMeta.amount_used)).toFixed(2);
      }
    } else {
      if (this.args.multiStepForm.formOriginalModelsGetVal('invoice.model.responseMeta.paid_amount')) {
        paidTotal = invoiceType === 'refund_receipt' ? parseFloat(Number(this.args.multiStepForm.formOriginalModelsGetVal('invoice.model.responseMeta.paid_amount'))).toFixed(2) : parseFloat(Number(this.args.multiStepForm.formOriginalModelsGetVal('invoice.model.responseMeta.paid_amount'))).toFixed(2);
      }
    }

    (0, _lodash.set)(this, 'paidAmount', paidTotal);
  }), (_class = (_temp = class InvoiceDetailsFooter extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "initFormData", _descriptor, this);

      this.initFormData.perform();
    }

    get subTotal() {
      return this.calculateTotals('grossAmount');
    }

    get taxTotal() {
      return this.calculateTotals('salesTaxAmount');
    }

    get totalAmount() {
      return this.calculateTotals('totalAmount');
    }

    get dueAmount() {
      return parseFloat(Number(this.totalAmount) - Number(this.paidAmount)).toFixed(2);
    }

    calculateTotals(field) {
      if (this.args.RowList.listArray != undefined) {
        var subtotal = this.args.RowList.listArray.reduce((newSubtotal, lineItem) => {
          return newSubtotal += lineItem && lineItem.get(field) ? Number(lineItem.get(field)) : 0;
        }, 0);
        return parseFloat(subtotal).toFixed(2);
      }

      return 0;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "initFormData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = InvoiceDetailsFooter;
});