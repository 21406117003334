define("securitytrax-ember-ui/models/send-user-message", ["exports", "securitytrax-ember-ui/models/base_models/send-user-message"], function (_exports, _sendUserMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _sendUserMessage.default.extend({});

  _exports.default = _default;
});