define("securitytrax-ember-ui/components/html/text-mask/component", ["exports", "inputmask"], function (_exports, _inputmask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    inputType: 'tel',
    selector: ' input',

    didRender() {
      this._super(...arguments);

      var elementToMask = document.querySelector('#' + this.elementId + ' ' + this.selector);

      if (elementToMask && !elementToMask.inputmask) {
        if (!this.maskedInput && this.mask === 'phone' && this.value && this.value.length === 11) {
          this.set('mask', 'internationalPhone');
        }

        var inputMask = this.getInputMask();
        inputMask.mask(elementToMask);
      }
    },

    getInputMask() {
      var inputMask;

      switch (this.mask) {
        case 'currency':
          inputMask = new _inputmask.default('currency', {
            prefix: '$',
            digits: 2,
            digitsOptional: false,
            enforceDigitsOnBlur: false,
            placeholder: '0',
            autoGroup: true,
            groupSeparator: ',',
            decimalProtect: true
          });
          break;

        case 'blankCurrency':
          inputMask = new _inputmask.default('currency', {
            allowMinus: false,
            prefix: '$',
            digits: 2,
            digitsOptional: false,
            enforceDigitsOnBlur: false,
            autoGroup: true,
            groupSeparator: ',',
            decimalProtect: true,
            showMaskOnHover: false,
            showMaskOnFocus: false,
            clearMaskOnLostFocus: true,
            //workaround to a bug with inputmask where 0 can't be cleared from numeric inputs
            onKeyDown: event => {
              if (event.key == 'Delete' || event.key == 'Backspace') {
                var element = event.target;

                if (element.value == '$0.00') {
                  element.value = null;
                  element.dispatchEvent(new Event('change'));
                }
              }
            }
          });
          break;

        case 'percent':
          inputMask = new _inputmask.default('percentage', {
            digits: 5,
            max: 100,
            suffix: '%',
            digitsOptional: false,
            enforceDigitsOnBlur: false,
            placeHolder: '0',
            decimalProtect: true
          });
          break;

        case 'phone':
          //default for North America
          inputMask = new _inputmask.default('phone', {
            mask: ['(999) 999-9999', '+9 (999) 999-9999'] // clearMaskOnLostFocus: true,
            // disablePredictiveText: true,
            // positionCaretOnClick: 'ignore',
            // inputEventOnly: true,
            // colorMask: true

          });
          break;

        case 'internationalPhone':
          //only used for non-input phone masks like customer list
          inputMask = new _inputmask.default('phone', {
            mask: '+9 (999) 999-9999'
          });
          break;

        case 'phoneOther':
          //can be used for European etc
          inputMask = new _inputmask.default('phone', {
            mask: '999 999 9999[ ][99999]',
            skipOptionalPartCharacter: ' ',
            clearMaskOnLostFocus: true
          });
          break;

        case 'ssn':
          inputMask = new _inputmask.default('phone', {
            mask: '999-99-9999'
          });
          break;

        default:
          inputMask = new _inputmask.default('text', {
            inputMode: 'numeric',
            mask: '9{*}',
            onBeforeMask: function (value) {
              var processedValue = parseInt(value).toString();
              return processedValue;
            }
          });
          break;
      }

      return inputMask;
    },

    readonlyOrDisabled: Ember.computed(function () {
      return this.disabled || this.readOnly;
    }),
    actions: {
      onChangeWithDefault: function (newValue) {
        var isNegative = newValue.target.value.charAt(0) === '-';
        var cleanedValue = newValue.target.value.replace(/[^0-9.]/g, '');

        if (isNegative) {
          cleanedValue = '-' + cleanedValue;
        }

        if (this.mask != null) {
          this.set('value', cleanedValue);
        } else {
          this.set('value', newValue.target.value);
        }

        if (this.onChange) {
          this.onChange(cleanedValue);
        }
      }
    }
  });

  _exports.default = _default;
});