define("securitytrax-ember-ui/components/pages/administration/company-settings/global-settings/calendar/component", ["exports", "securitytrax-ember-ui/components/pages/administration/company-settings/global-settings/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    companyCalendarSettingsFormSteps: Ember.computed(function () {
      return Ember.Object.create({
        settings: Ember.Object.create({
          stepNameKey: 'settings',
          title: 'calendar settings',
          stepComponentPath: 'multi-step-form/forms/administration/system/global-settings/edit-company-calendar-settings'
        })
      });
    }),
    companyCalendarSettingsFormOriginalModels: Ember.computed('globalSettings', function () {
      return Ember.Object.create({
        globalSetting: {
          model: this.globalSettings,
          copyRelationMap: {//
          }
        }
      });
    })
  });

  _exports.default = _default;
});