define("securitytrax-ember-ui/components/calendar-glimmer/calendar-grid/calendar-grid-row-title/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CalendarGridRowTitle = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('events/calendar-events'), _dec4 = Ember.inject.service('screen'), _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._action, _dec8 = Ember._action, (_class = (_temp = class CalendarGridRowTitle extends _component.default {
    get gridRowStyles() {
      return Ember.String.htmlSafe('grid-row: 1 / span ' + this.args.totalRows);
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "CalendarDataService", _descriptor, this);

      _initializerDefineProperty(this, "CalendarMapUserSelectService", _descriptor2, this);

      _initializerDefineProperty(this, "CalendarEventsService", _descriptor3, this);

      _initializerDefineProperty(this, "screen", _descriptor4, this);

      _initializerDefineProperty(this, "isSelectedForMap", _descriptor5, this);

      _initializerDefineProperty(this, "canSelectForMap", _descriptor6, this);

      if (!this.args.isUser && !this.args.isScheduler) {
        this.CalendarEventsService.on('selectAllMap', this, '_selectAllMap');
        this.CalendarEventsService.on('selectSingleMap', this, '_selectSingleMap');

        if (this.args.date === this.CalendarMapUserSelectService.dateSelected) {
          this.checkIfUserIsSelected(this.args.row.user.id);
        }

        if (this.args.date === this.CalendarMapUserSelectService.dateSelected || this.CalendarMapUserSelectService.dateSelected === undefined) {
          Ember.set(this, 'canSelectForMap', true);
        }
      }
    }

    willDestroy() {
      if (!this.args.isUser && !this.args.isScheduler) {
        this.CalendarEventsService.off('selectSingleMap', this, '_selectSingleMap');
        this.CalendarEventsService.off('selectAllMap', this, '_selectAllMap');
      }
    }

    _selectAllMap(date, allSelected) {
      if (this.args.date === date) {
        Ember.set(this, 'isSelectedForMap', allSelected);

        if (this.isSelectedForMap) {
          this.CalendarMapUserSelectService.addMapUser(this.args.row.id, true);
        } else {
          this.CalendarMapUserSelectService.removeMapUser(this.args.row.id, true);
        }
      }

      if (this.args.date !== date) {
        if (allSelected) {
          Ember.set(this, 'canSelectForMap', false);
        } else {
          Ember.set(this, 'canSelectForMap', true);
        }
      }
    }

    _selectSingleMap(date, userId) {
      if (this.args.date !== date) {
        Ember.set(this, 'canSelectForMap', false);
      }

      if (this.args.date === this.CalendarMapUserSelectService.dateSelected || this.CalendarMapUserSelectService.dateSelected === undefined) {
        Ember.set(this, 'canSelectForMap', true);

        if (this.args.row.id === userId) {
          this.checkIfUserIsSelected(userId);
        }
      }
    }

    checkIfUserIsSelected(userId) {
      if (this.CalendarMapUserSelectService.selectedUsers.some(user => user == userId)) {
        Ember.set(this, 'isSelectedForMap', true);
      } else {
        Ember.set(this, 'isSelectedForMap', false);
      }
    }

    toggleSelectionForMapView(row) {
      if (this.CalendarMapUserSelectService.dateSelected === undefined) {
        this.CalendarMapUserSelectService.selectDate(row.date);
      }

      if (this.CalendarMapUserSelectService.dateSelected == row.date) {
        if (this.isSelectedForMap) {
          Ember.set(this, 'isSelectedForMap', false);
          this.CalendarMapUserSelectService.removeMapUser(row.id);
          this.CalendarEventsService.trigger('selectSingleMap', this.args.date, row.id);
        } else {
          Ember.set(this, 'isSelectedForMap', true);
          this.CalendarMapUserSelectService.addMapUser(row.id);
          this.CalendarEventsService.trigger('selectSingleMap', this.args.date, row.id);
        }
      }
    }

    toggleSelectionForMap(row, event) {
      this.toggleSelectionForMapView(row);

      if (event) {
        event.stopPropagation();
      }

      return false;
    }

    mapClick(row) {
      this.CalendarEventsService.trigger('viewUserMap', this.args.date, row.id, row.user.name, this.args.pendingAddCalendarEvent);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "CalendarDataService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "CalendarMapUserSelectService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "CalendarEventsService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "screen", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isSelectedForMap", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "canSelectForMap", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleSelectionForMap", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "toggleSelectionForMap"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mapClick", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "mapClick"), _class.prototype)), _class));
  _exports.default = CalendarGridRowTitle;
});