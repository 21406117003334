define("securitytrax-ember-ui/components/customer/accounting/payment-methods-list-add-edit-subroute/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    grants: Ember.inject.service(),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    paymentMethodEvents: Ember.inject.service('events/payment-method-events'),

    init() {
      this._super(...arguments);

      this.paymentMethodEvents.on('refresh-list', this, '_callback');
    },

    _callback() {
      Ember.notifyPropertyChange(this, 'customerBillings');
    },

    willDestroy() {
      this.paymentMethodEvents.off('refresh-list', this, '_callback');
    },

    defaultCustomerBilling: null,
    customerBillings: Ember.computed('customer.{customerBillings.@each,defaultCustomerBilling}', function () {
      return this.getCustomerBillingsTask.perform();
    }),
    getCustomerBillingsTask: (0, _emberConcurrency.task)(function* () {
      if (this.grants.getIndividualGrant('customer_billings.view')) {
        var customerBillings = yield this.get('customer.customerBillings');
        customerBillings.forEach(billing => {
          if (billing.isCustomerDefault) {
            Ember.set(this, 'defaultCustomerBilling', billing);
          }
        });

        if (this.detailedView) {
          return this.defaultCustomerBilling ? [this.defaultCustomerBilling] : [];
        } else {
          return customerBillings;
        }
      }

      return null;
    }),
    billTypes: Ember.computed(function () {
      return this.getBillTypesTask.perform();
    }),
    getBillTypesTask: (0, _emberConcurrency.task)(function* () {
      return yield this.store.findAll('billingType');
    }),
    actions: {
      transitionToSubroute(path) {
        this.router.transitionTo('customers.accounting.billing-payment-methods.edit-create-payment-method', path);
      }

    }
  });

  _exports.default = _default;
});