define("securitytrax-ember-ui/components/html/multi-select/building-blocks/single-input-filter/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    filterValue: '',
    filterPropertyName: null,
    filterValueChanged: Ember.observer('filterValue', function () {
      this.updateFilterTask.perform();
    }),
    updateFilterTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(600);
      this.filterTheList(false);
    }).restartable(),
    listataChanged: Ember.observer('listData.[]', function () {
      this.filterTheList(true);
    }),

    filterTheList(listDataChanged = false) {
      if (this.filterPropertyName) {
        var fullList = this.listData;
        var filterValue = this.filterValue;
        var filteredList;

        if (filterValue) {
          var valuesToFilterOn = filterValue.replace(/[^\w\s]/gi, ' ').toLowerCase().split(' ');
          filteredList = fullList.filter(item => {
            var itemFilterValue = Ember.get(item, this.filterPropertyName).toLowerCase();
            var itemIsMatch = true;
            valuesToFilterOn.forEach(value => {
              if (!itemFilterValue.includes(value)) {
                itemIsMatch = false;
              }
            });
            return itemIsMatch;
          });
        } else {
          filteredList = fullList;
        }

        if (listDataChanged) {
          this.set('filteredList', false);
          Ember.run.later(() => {
            this.set('filteredList', filteredList);
          });
        } else {
          this.set('filteredList', filteredList);
        }
      } else {
        this.set('filteredList', this.listData);
      }
    },

    actions: {}
  });

  _exports.default = _default;
});