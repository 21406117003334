define("securitytrax-ember-ui/mixins/forms/adt", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    centralStationService: Ember.inject.service(),
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    monitoringCompanyHasNeededGrants: Ember.computed('grants.userGrants', function () {
      return this.monitoringCompanyHasNeededGrantsTask.perform();
    }),
    monitoringCompanyHasNeededGrantsTask: (0, _emberConcurrency.task)(function* () {
      var grants = this.grants;
      var grantsData = yield (0, _emberConcurrency.hash)({
        customerModify: grants.getIndividualGrant('customers.modify'),
        orderInformationModify: grants.getIndividualGrant('customer_order_information.modify'),
        systemInformationModify: grants.getIndividualGrant('customer_system_information.modify'),
        agencyContactsView: grants.getIndividualGrant('agency_contacts.view'),
        agencyContactsModify: grants.getIndividualGrant('agency_contacts.modify'),
        customerContactsCreate: grants.getIndividualGrant('customer_contacts.create'),
        customerContactsModify: grants.getIndividualGrant('customer_contacts.modify'),
        customerBillingsCreate: grants.getIndividualGrant('customer_billings.create'),
        customerBillingsModify: grants.getIndividualGrant('customer_billings.modify'),
        customerEquipmentView: grants.getIndividualGrant('customer_equipment.view')
      });
      var hasGrants = grantsData.customerModify && grantsData.orderInformationModify && grantsData.systemInformationModify && grantsData.agencyContactsView && grantsData.agencyContactsModify && grantsData.customerContactsCreate && grantsData.customerContactsModify && grantsData.customerBillingsCreate && grantsData.customerBillingsModify && grantsData.customerEquipmentView;
      return hasGrants;
    }),
    formProgressBar: Ember.computed('adtOrderFormMode', 'adtStepReady', function () {
      return this.adtOrderFormMode === 'create' ? {
        hideProgressBar: true
      } : this.progressBarConfig;
    }),
    adtData: Ember.computed('adtIsShelled', 'customer.adtPreCheck', function () {
      return this.adtDataTask.perform();
    }),
    adtDataTask: (0, _emberConcurrency.task)(function* () {
      try {
        var centralStationService = this.centralStationService;
        var grants = this.grants;
        var hashData = yield (0, _emberConcurrency.hash)({
          systemInfoGrant: grants.getIndividualGrant('customer_system_information.view'),
          orderInfoGrant: grants.getIndividualGrant('customer_order_information.view'),
          csIntegrationGrant: grants.getIndividualGrant('customers.manage_central_station_integration'),
          customer: this.customer
        });
        var customerIsLead = hashData.customer.isLead;
        var adtData = {
          hasPreCheck: false,
          failedLoadAdtData: true
        };
        var monitoringCompanyTypeId = 0;

        if (hashData.systemInfoGrant && !customerIsLead) {
          var systemInfo = hashData.systemInfoGrant ? hashData.customer.get('customerSystemInformation') : null;

          if (hashData.orderInfoGrant) {
            var monitoringCompany = yield this.get('customer.customerOrderInformation.monitoringCompany');
            var monitoringCompanyType = monitoringCompany ? yield monitoringCompany.get('monitoringCompanyType') : null;
            monitoringCompanyTypeId = monitoringCompanyType ? monitoringCompanyType.id : null;
          }

          if (monitoringCompanyTypeId && monitoringCompanyTypeId == 1) {
            var centralStationId = yield systemInfo.get('centralStationId');
            var preCheck = hashData.csIntegrationGrant ? yield this.get('customer.adtPreCheck') : null;
            var code = preCheck ? yield preCheck.get('status.code') : null;

            if (centralStationId || preCheck && code === 'review_required' || code === 'proceed') {
              try {
                if (centralStationId) {
                  adtData = yield (0, _emberConcurrency.hash)({
                    adtCustomerOrder: centralStationService.getData({
                      model: hashData.customer,
                      method: 'adt__getCreateUpdateOrderData',
                      responseKey: 'create_update_order_data'
                    }),
                    adtCustomerOrderStatus: centralStationService.getData({
                      model: hashData.customer,
                      method: 'adt__getOrderStatus',
                      responseKey: 'order_status'
                    })
                  });
                } else {
                  adtData = {
                    //calling getCreateUpdateOrderData to get defaults since we cant get them from monitoringCompanyConfig due to grants
                    adtCustomerOrder: yield centralStationService.getData({
                      model: hashData.customer,
                      method: 'adt__getCreateUpdateOrderData',
                      responseKey: 'create_update_order_data'
                    })
                  };
                }

                if (!adtData.adtCustomerOrder.credit_holder_physical_address_data) {
                  // need this here for shell
                  adtData.adtCustomerOrder.credit_holder_physical_address_data = {};
                }

                if (!adtData.adtCustomerOrder.ny) {
                  // need this here for shell
                  adtData.adtCustomerOrder.ny = {};
                } else if (adtData.adtCustomerOrder.ny.estimated_completion_date === '') {
                  adtData.adtCustomerOrder.ny.estimated_completion_date = null;
                }

                adtData.hasPreCheck = true;
                adtData.failedLoadAdtData = false;
              } catch (error) {
                (false && Ember.warn('failed to set adtData: ' + error, {
                  id: 'mixins.forms.adt.setAdtData'
                }));
                this.stNotificationManager.addError(this.intl.t('pages.customers.system.failedContactAdt'));
                adtData.failedLoadAdtData = true;
              }
            } else {
              adtData = {
                hasPreCheck: false,
                failedLoadAdtData: false
              };
            }
          }
        }

        return adtData;
      } catch (error) {
        (false && Ember.warn('failed to get adtData: ' + error, {
          id: 'mixins.forms.adt'
        }));
      }
    }).drop().maxConcurrency(1),
    adtIsShelled: Ember.computed('customer.customerSystemInformation.centralStationId', function () {
      return this.adtIsShelledTask.perform();
    }),
    adtIsShelledTask: (0, _emberConcurrency.task)(function* () {
      try {
        var grants = this.grants;
        var systemInfoGrant = grants.getIndividualGrant('customer_system_information.view');
        var customerSystemInformation = systemInfoGrant ? yield this.get('customer.customerSystemInformation') : null;
        var centralStationId = customerSystemInformation ? yield customerSystemInformation.get('centralStationId') : 0;

        if (centralStationId != null) {
          return true;
        }

        return false;
      } catch (error) {
        (false && Ember.warn('error in adtIsShelledTask', {
          id: 'mixins.forms.adt.adtIsShelledTask'
        }));
        (false && Ember.warn(error, {
          id: 'mixins.forms.adt.adtIsShelledTask'
        }));
      }
    }),
    refreshCustomerAdtStatus: (0, _emberConcurrency.task)(function* (customer, adtData) {
      try {
        var centralStationService = this.centralStationService;
        yield customer.reload();
        var hashData2 = yield (0, _emberConcurrency.hash)({
          adtCustomerOrderStatus: centralStationService.getData({
            model: customer,
            method: 'adt__getOrderStatus',
            responseKey: 'order_status'
          }),
          customerSystemInformation: this.grants.getIndividualGrant('customer_system_information.view') ? customer.get('customerSystemInformation') : null
        });
        Ember.set(adtData, 'adtCustomerOrderStatus', hashData2.adtCustomerOrderStatus);

        if (hashData2.customerSystemInformationyield) {
          hashData2.customerSystemInformation.reload();
        }
      } catch (error) {
        (false && Ember.warn('failed to refreshCustomerAdtStatus' + error, {
          id: 'mixins.forms.adt'
        }));
        throw error;
      }
    }),
    adtCustomerInformation: Ember.computed(function () {
      return this.adtCustomerInformationTask.perform();
    }),
    adtCustomerInformationTask: (0, _emberConcurrency.task)(function* () {
      var adtCustomerInformation;
      var hashedData = yield (0, _emberConcurrency.hash)({
        customerAccountNumber: this.get('customer.customerSystemInformation.accountNum'),
        canRetrieve: this.centralStationService.getData({
          model: this.customer,
          method: 'adt__getDealersiteServiceCustomerInformationRetrievalIsPossible',
          responseKey: 'is_possible'
        })
      });

      if (!hashedData.customerAccountNumber || !hashedData.canRetrieve) {
        adtCustomerInformation = null;
      } else {
        try {
          adtCustomerInformation = yield this.centralStationService.getData({
            model: this.customer,
            method: 'adt__getCustomerInfo',
            responseKey: 'customer_info'
          });
        } catch (err) {
          (false && Ember.warn('Failed to get adtCustomerInformation: ' + err, {
            id: 'customers.information.adt.adtCustomerInformationTask'
          }));
          adtCustomerInformation = null;
        }
      }

      return adtCustomerInformation;
    }),
    adtShowDealerHubInfo: Ember.computed(function () {
      return this.adtShowDealerHubInfoTask.perform();
    }),
    adtShowDealerHubInfoTask: (0, _emberConcurrency.task)(function* () {
      return yield this.centralStationService.getData({
        model: this.customer,
        method: 'adt__getAccountDetailsRetrievalIsPossible',
        responseKey: 'is_possible'
      });
    })
  });

  _exports.default = _default;
});