define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/customer-create-chooser/create-form-configs/lead-to-customer/component", ["exports", "securitytrax-ember-ui/mixins/customer-create-chooser"], function (_exports, _customerCreateChooser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_customerCreateChooser.default, {
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    createCustomerFormSteps: Ember.computed('newCustomerName', 'grants.userGrants', function () {
      var intl = this.intl;
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.newCustomer ? this.newCustomerName : intl.t('pages.leads.title_create'),
          stepComponentPath: 'multi-step-form/forms/leads/information',
          progressBarTitle: intl.t('common.nav.create.lead.label'),
          isComplete: !!this.newCustomerName
        }),
        step2: Ember.Object.create({
          stepNameKey: 'step2',
          title: this.newCustomerName,
          stepComponentPath: 'multi-step-form/forms-dynamic/generic-steps/customer-create/qualify',
          progressBarTitle: intl.t('common.nav.create.customer.sections.qualify'),
          hidden: !this.grants.getIndividualGrant('customer_credit_checks.create')
        }),
        step3: Ember.Object.create({
          stepNameKey: 'step3',
          title: this.newCustomerName,
          stepComponentPath: 'multi-step-form/forms/leads/convert-customer',
          progressBarTitle: intl.t('pages.leads.convert')
        }),
        confirmDeleteCustomer: Ember.Object.create({
          stepNameKey: 'confirmDeleteCustomer',
          title: this.newCustomerName,
          stepComponentPath: 'multi-step-form/forms-dynamic/generic-steps/customer-create/confirm-delete-customer',
          hidden: true
        })
      });
    })
  });

  _exports.default = _default;
});