define("securitytrax-ember-ui/demo/grants-accessed-test-one/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _authenticatedRouteMixin, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _routePageviewLogging.default, {
    model() {
      return Ember.RSVP.hash({
        customers: this.store.findAll('customer'),
        users: this.store.findAll('user')
      });
    }

  });

  _exports.default = _default;
});