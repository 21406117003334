define("securitytrax-ember-ui/components/multi-step-form/forms/administration/users/my-inventory/spillage/create-spillage/component", ["exports", "ember-concurrency", "moment", "securitytrax-ember-ui/mixins/list/row-list", "securitytrax-ember-ui/mixins/switchable-form-step"], function (_exports, _emberConcurrency, _moment, _rowList, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, _rowList.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    sessionData: Ember.inject.service('sessionData'),
    equipment: null,
    spillageEquipmentLineItemDeleteId: false,
    relatedDocuments: null,
    equipmentReturnReason: null,
    recipient: null,
    dataLoaded: false,
    formStarted: false,
    recipientSelected: false,
    totalItemCount: 0,
    formSteps: Ember.computed('formMode', 'multiStepForm.formCopiedModels.equipmentPurchaseOrder.model.equipmentVendor.id', 'recipient.id', function () {
      var intl = this.intl;
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: intl.t("pages.administration.company_settings.inventory.spillage.review_title", {
            recipient: this.get('recipient.fname') ? this.get('recipient.fname') + ' ' + this.get('recipient.lname') : this.get('recipient.name')
          }),
          stepComponentPath: 'multi-step-form/forms/administration/users/my-inventory/spillage/review'
        })
      });
    }),
    totalItemCountObserver: Ember.observer('totalItemCount', function () {
      if (this.totalItemCount > 0) {
        this.registerButtons();
      } else {
        this.registerButtons(true);
      }
    }),
    initFormData: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = this.multiStepForm;
      yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);
      multiStepForm.formCopiedModelsSetVal('equipmentSpillage.model.createdByUser', this.user);
      multiStepForm.formCopiedModelsSetVal('equipmentSpillage.model.createdAt', (0, _moment.default)());
      var hashedData = yield (0, _emberConcurrency.hash)({
        waitForFinishedCopyModels: (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true),
        user: this.sessionData.getUser(),
        equipment: this.getUserEquipment.perform(),
        equipmentReturnReason: this.store.findAll('equipmentReturnReason')
      });
      multiStepForm.formCopiedModelsSetVal('equipmentSpillage.model.user', hashedData.user);
      this.set('listArray', []);

      if (hashedData.equipment.length > 0) {
        this.addRow();
        this.set('equipmentReturnReason', hashedData.equipmentReturnReason);
        this.set('equipment', hashedData.equipment);
        this.set('recipient', hashedData.user);
      }

      this.set('dataLoaded', true);
    }).on('init'),
    getUserEquipment: (0, _emberConcurrency.task)(function* () {
      var hashedData = yield (0, _emberConcurrency.hash)({
        equipmentInventory: this.user.invokeAction('get_equipment_inventory', {
          verb: 'GET'
        }),
        companyEquipment: this.store.query('equipment', {
          limit: 0,
          sort: 'name',
          filter: 'active=true'
        })
      });
      var userEquipment = hashedData.equipmentInventory.responsePayload.data.filter(item => {
        return item.on_hand_quantity > 0;
      });
      var returnEquipment = Ember.A();

      var _loop = function (x) {
        var userItem = userEquipment.objectAt(x);
        var pushItem = hashedData.companyEquipment.find(piece => piece.get('id') == userItem.equipment_id);
        pushItem.set('userItemInfo', userItem);

        if (pushItem) {
          returnEquipment.push(pushItem);
        }
      };

      for (var x = 0; x < userEquipment.length; x++) {
        _loop(x);
      }

      return returnEquipment;
    }),

    didInsertElement() {
      this._super(...arguments);

      this.registerButtons(true);
    },

    openReviewModal: (0, _emberConcurrency.task)(function* (multiStepForm) {
      var formattedObjects = this.prepForValidate('pendingEquipmentLineItem');
      formattedObjects.pushObject(Ember.Object.create({
        modelName: 'equipmentSpillage',
        model: multiStepForm.formCopiedModels.equipmentSpillage.model,
        extraValidationData: {
          noValidate: ['equipmentSpillage-location', 'equipmentSpillage-status', 'equipmentSpillage-user']
        },
        filePath: 'equipment-spillage'
      }));
      yield multiStepForm.validateList.perform(formattedObjects);
      this.set('formStarted', true);
    }),
    deleteSpillageLineItemTask: (0, _emberConcurrency.task)(function* (row) {
      yield this.deleteRow.perform(row, 'pendingEquipmentLineItem');
      this.multiStepForm.formCopiedModelsSetVal('equipmentSpillage.model.pendingEquipmentLineItems', this.listArray);
      this.set('spillageEquipmentLineItemDeleteId', false);
    }),
    getLineItemCount: (0, _emberConcurrency.task)(function* () {
      var lineItems = yield this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model.pendingEquipmentLineItems');
      var count = 0;
      lineItems.forEach(item => {
        if (item.quantity) {
          count += parseInt(item.quantity);
        }
      });
      this.set('totalItemCount', count);
    }),
    saveSpillageData: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var formattedObjects = this.prepForValidate('pendingEquipmentLineItems');
      formattedObjects.pushObject(Ember.Object.create({
        modelName: 'equipmentSpillage',
        model: multiStepForm.formCopiedModels.equipmentSpillage.model,
        extraValidationData: {
          noValidate: ['equipmentSpillage-location', 'equipmentSpillage-status', 'equipmentSpillage-user']
        },
        filePath: 'equipment-spillage'
      })); //validate line items first, then set copied model with ember data

      var toCreateObjects = yield multiStepForm.validateCreateList.perform(formattedObjects, {
        notificationNamespace: 'modal'
      });
      var newSpillage = this.store.createRecord('equipmentSpillage', {
        pendingEquipmentType: 'spillage',
        user: this.user,
        location: null,
        comments: toCreateObjects.equipmentSpillage[0].data.comments,
        status: 'open'
      });
      var savedSpillage = yield toCreateObjects.equipmentSpillage[0].saveWithRollbackAndNotification(newSpillage, 'modal');

      if (toCreateObjects.pendingEquipmentLineItems) {
        toCreateObjects.pendingEquipmentLineItems.forEach(item => {
          var newPendingEquipmentLineItem = this.store.createRecord('pendingEquipmentLineItem', {
            quantity: item.data.quantity || 0,
            fulfilledQuantity: 0,
            cost: Number(item.data.cost) || 0,
            comments: item.data.comments,
            equipment: item.data.equipment,
            equipmentReturnReason: item.data.equipmentReturnReason,
            equipmentSpillage: savedSpillage
          });
          toCreateObjects.equipmentSpillage[0].saveWithRollbackAndNotification(newPendingEquipmentLineItem, 'modal');
        });
      }

      buttonPromise.then(() => {
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        multiStepForm.formStartedSetVal(false);
      });
    }),

    registerButtons(disabled = false) {
      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData("step1", 'buttons', [{
        text: this.pendingPurchaseOrder ? this.intl.t('common.actions.save') : this.intl.t('pages.users.my_inventory.physical_inventory.review'),
        click: () => {
          return this.openReviewModal.perform(multiStepForm);
        },
        disabled,
        classNamesString: 'st-btn st-btn-primary po-review-save-button'
      }]);
    },

    actions: {
      deleteSpillageEquipmentLineItem(row) {
        this.deleteSpillageLineItemTask.perform(row);
      },

      updateEquipmentItemsList(row, index) {
        var lineItem = row;

        if (row.get('equipment')) {
          if (row.get('quantity') > row.get('equipment').userItemInfo.on_hand_quantity) {
            row.set('quantity', row.get('equipment').userItemInfo.on_hand_quantity);
          }
        }

        lineItem.set('quantity', row.get('quantity') > 0 ? row.get('quantity') : 1);
        lineItem.set('comments', row.get('comments'));
        lineItem.set('equipment', row.get('equipment'));
        lineItem.set('fulfilledQuantity', row.get('fulfilledQuantity') || 0);
        lineItem.set('cost', row.get('equipment.cost'));
        lineItem.set('returnReason', row.get('equipmentReturnReason'));
        this.updateRow(lineItem, index);
        this.multiStepForm.formCopiedModelsSetVal('equipmentSpillage.model.pendingEquipmentLineItems', this.listArray);
        this.getLineItemCount.perform();
      }

    }
  });

  _exports.default = _default;
});