define("securitytrax-ember-ui/components/graphs/progress-circle/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    progressBar: Ember.computed('completedCount', 'totalCount', function () {
      if (this.totalCount > 0) {
        return Math.floor(this.completedCount / this.totalCount * 100);
      } else {
        return 0;
      }
    }),
    computedBarColor: Ember.computed('barColor', function () {
      if (this.barColor) {
        return this.barColor;
      } else {
        return 'pink';
      }
    })
  });

  _exports.default = _default;
});