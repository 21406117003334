define("securitytrax-ember-ui/components/multi-step-form/switchable-inputs/date-input/component", ["exports", "jquery", "securitytrax-ember-ui/mixins/switchable-input", "moment", "securitytrax-ember-ui/utils/obj-helpers", "inputmask"], function (_exports, _jquery, _switchableInput, _moment, _objHelpers, _inputmask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Contextual component utilizing the following:
   * Basic Dropdown https://ember-basic-dropdown.com/docs/
   * Ember Power Calendar https://ember-power-calendar.com/docs/
   * Input masking https://github.com/RobinHerbots/Inputmask
   */
  var _default = Ember.Component.extend(_switchableInput.default, {
    useMasking: undefined,
    format: undefined,
    showInput: true,
    range: null,
    //object with start and end properties. eg: start: moment(2017-12-30)
    locale: 'en',
    firstDay: null,
    showDaysAround: true,
    //shows days of surrounding months
    disabledDates: null,
    //array of dates to not allow selection
    showYearNav: false,
    showDatePicker: true,
    dateTime: false,
    minDate: null,
    maxDate: null,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        useMasking: true,
        format: this.dateTime ? 'MM/DD/YYYY hh:mm a' : 'MM/DD/YYYY'
      });

      if (this.value) {
        // this code should never run because strings are converted to moment objects when they come in
        // keeping just in case
        if (typeof this.value === 'string') {
          var mysqlDateFormat = /^([0-9]{2,4})-([0-1][0-9])-([0-3][0-9])/.test(this.value);

          if (mysqlDateFormat) {
            this.set('value', (0, _moment.default)(this.value, 'YYYY-MM-DD'));
          } else {
            this.set('value', (0, _moment.default)(this.value, 'MM/DD/YYYY'));
          }
        }
      }
    },

    didRender() {
      if (this.useMasking) {
        var elementToMask = document.querySelector('#' + this.elementId + ' .input-date.mask');
        var inputMask;

        if (this.dateTime) {
          inputMask = new _inputmask.default({
            alias: 'mm/dd/yyyy hh:mm xm',
            // datetime with am/pm
            oncomplete: newDate => {
              (0, _jquery.default)(newDate.target).change(); // trigger onChange action
            },
            placeholder: this.placeholder,
            hourFormat: '12'
          });
        } else {
          // made the mask just numbers in the right format (actual date validation happens in validation) - this fixes lots of issues with putting in older dates etc
          // also the date is formatted with moment right after the mask is done and so is a valid date or will be empty
          inputMask = new _inputmask.default('99/99/9999', {
            oncomplete: newDate => {
              (0, _jquery.default)(newDate.target).change();
            },
            placeholder: this.placeholder
          });
        }

        if (elementToMask) {
          inputMask.mask(elementToMask);
        }
      }
    },

    momentValue: Ember.computed('value', function () {
      return this.value ? (0, _moment.default)(this.value, 'MM/DD/YYYY') : null;
    }),
    placeholder: Ember.computed('dateTime', function () {
      return this.dateTime ? 'mm/dd/yyyy hh:mm xm' : 'mm/dd/yyyy';
    }),
    valueFormatted: Ember.computed('value', 'dateTime', function () {
      var val = this.value;

      if (val) {
        return (0, _moment.default)(val).format(this.format); // DREW TODO - this change could have broken stuff elsewhere
      } else {
        return null;
      }
    }),
    center: Ember.computed('value', 'overrideCenter', function () {
      if (this.overrideCenter) {
        return (0, _moment.default)(this.overrideCenter);
      }

      var val = this.value;

      if (val == null) {
        return (0, _moment.default)();
      } else {
        return (0, _moment.default)(val, 'MM/DD/YYYY');
      }
    }),
    actions: {
      /**
       * handle selecting a date, or inputting a date manually
       */
      onChangeWithDefault: function (newDate) {
        var newValue = null;

        if ('moment' in newDate) {
          if ((0, _moment.default)(newDate.date, this.format).isValid()) {
            newValue = (0, _moment.default)(newDate.date, this.format);
          }
        } else if ('target' in newDate && newDate.target.value) {
          if ((0, _moment.default)(newDate.target.value, this.format).isValid()) {
            newValue = (0, _moment.default)(newDate.target.value, this.format);
          }
        }

        this.set('value', newValue); //an additional onChange action was passed

        if (this.onChange) {
          return this.onChange.call(this, newDate);
        }
      },
      clearDate: function () {
        if (this.onClearDate) {
          this.onClearDate.call();
        }
      }
    }
  });

  _exports.default = _default;
});