define("securitytrax-ember-ui/components/multi-step-form/forms/create-calendar-event/component", ["exports", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step"], function (_exports, _component, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CreateCalendarEvent = (_dec = Ember.inject.service('events/calendar-events'), _dec2 = Ember._action, (_class = (_temp = class CreateCalendarEvent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "calendarEventsService", _descriptor, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.SwitchableFormStep.progressBarHidden = false;
    }

    afterScheduleAppointment() {
      this.calendarEventsService.trigger('refresh-customer-calendar-events');
      this.args.multiStepForm.formStartedSetVal(false);

      if (this.args.transitionBackToParent && typeof this.args.transitionBackToParent === 'function') {
        this.args.transitionBackToParent();
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "calendarEventsService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "afterScheduleAppointment", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "afterScheduleAppointment"), _class.prototype)), _class));
  _exports.default = CreateCalendarEvent;
});