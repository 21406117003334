define("securitytrax-ember-ui/reports/controller", ["exports", "securitytrax-ember-ui/mixins/sortable-list-mixin"], function (_exports, _sortableListMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller.extend(_sortableListMixin.default, {
    showNav: true
  });
  var _default = Controller;
  _exports.default = _default;
});