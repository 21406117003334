define("securitytrax-ember-ui/customers/accounting/route", ["exports", "securitytrax-ember-ui/customers/base-route", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _baseRoute, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

  function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

  var _default = _baseRoute.default.extend(_routePageviewLogging.default, {
    grants: Ember.inject.service(),
    sessionData: Ember.inject.service('sessionData'),
    quickAdd: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform();
    },

    model: function () {
      var _ref = _asyncToGenerator(function* () {
        var {
          customer
        } = this._super(...arguments);

        return Ember.RSVP.hash({
          customer
        });
      });

      return function model() {
        return _ref.apply(this, arguments);
      };
    }(),
    customerController: Ember.computed(function () {
      return this.controllerFor('customers');
    }),

    setupBreadCrumbs() {
      var breadCrumbs = [{
        name: this.intl.t('pages.accounting.title'),
        previousLink: 'customers',
        last: true
      }];
      var breadCrumbData = {
        titleLink: 'customers.view',
        hide: false
      };
      this.customerController.set('breadCrumbs', breadCrumbs);
      this.customerController.set('breadCrumbData', breadCrumbData);
    },

    actions: {
      didTransition() {
        this.setupBreadCrumbs();
        this.quickAdd.set('showQuickAdd', true);
        this.quickAdd.set('page', 'customer');
      }

    }
  });

  _exports.default = _default;
});