define("securitytrax-ember-ui/components/customer/quick-action-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0NXWgUcl",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h1\"],[14,0,\"card-title\"],[12],[1,[30,[36,0],[\"pages.customers.dashboard.quick_actions\"],null]],[13],[2,\"\\n\"],[10,\"ul\"],[14,0,\"list quick_actions\"],[12],[2,\"\\n    \"],[10,\"li\"],[12],[2,\"Add Note\"],[13],[2,\"\\n    \"],[10,\"li\"],[12],[2,\"Schedule Appointment\"],[13],[2,\"\\n    \"],[10,\"li\"],[12],[2,\"Take Payment\"],[13],[2,\"\\n    \"],[10,\"li\"],[12],[2,\"DocuSign\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/customer/quick-action-list/template.hbs"
    }
  });

  _exports.default = _default;
});