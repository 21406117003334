define("securitytrax-ember-ui/administration/org-chart/user/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/route-pageview-logging", "ember-concurrency"], function (_exports, _authenticatedRouteMixin, _routePageviewLogging, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _routePageviewLogging.default, {
    intl: Ember.inject.service(),
    orgTree: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['users.view', 'groups.view'], 'one');
    },

    model(params) {
      return this.setupModelTask.perform(params);
    },

    setupModelTask: (0, _emberConcurrency.task)(function* (params) {
      var parentModel = this.modelFor('administration.org-chart');
      var userHierarchyTree = yield parentModel.userHierarchyTree;
      var company = yield parentModel.company;
      var directoryUsers = yield parentModel.directoryUsers;
      var user = yield this.store.findRecord('userHierarchy', JSON.stringify({
        user_hierarchy_tree_id: userHierarchyTree.get('id'),
        user_id: params.user_id
      })).catch(() => {
        this.stNotificationManager.addWarningMessages(this.intl.t('features.org-chart.common.hierarchy_user_not_found'));
        this.transitionTo('/administration/org-chart/' + userHierarchyTree.get('id') + '/unassigned/');
      });
      var orgTree = this.orgTree;
      orgTree.resetTree(userHierarchyTree, company, user);
      return Ember.RSVP.hash({
        userHierarchyTree,
        user,
        company,
        directoryUsers
      });
    })
  });

  _exports.default = _default;
});