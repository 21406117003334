define("securitytrax-ember-ui/administration/inventory/purchase-requisitions/route", ["exports", "securitytrax-ember-ui/administration/inventory/base-route", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _baseRoute, _authenticatedRouteMixin, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_authenticatedRouteMixin.default, _routePageviewLogging.default, {
    store: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['equipment_purchase_requisitions.view', 'equipment_purchase_requisitions.computed.company_inventory'], 'all');
    },

    model() {
      return Ember.RSVP.hash({
        vendors: this.store.findAll('equipmentVendor')
      });
    }

  });

  _exports.default = _default;
});