define("securitytrax-ember-ui/components/multi-step-form/forms/administration/inventory/spillage/create-spillage/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/switchable-form-step", "securitytrax-ember-ui/mixins/list/row-list", "moment"], function (_exports, _emberConcurrency, _switchableFormStep, _rowList, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, _rowList.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    inventoryService: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    sessionData: Ember.inject.service('sessionData'),
    equipment: null,
    spillageEquipmentLineItemDeleteId: false,
    relatedDocuments: null,
    equipmentReturnReasons: null,
    recipients: null,
    recipient: null,
    dataLoaded: false,
    lineItemsToCreate: null,
    reviewFormStarted: false,
    recipientSelected: false,
    formSteps: Ember.computed('formMode', 'multiStepForm.formCopiedModels.equipmentPurchaseOrder.model.equipmentVendor.id', 'recipient.id', function () {
      var intl = this.intl;
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: intl.t("pages.administration.company_settings.inventory.spillage.review_title", {
            recipient: this.get('recipient.fname') ? this.get('recipient.fname') + ' ' + this.get('recipient.lname') : this.get('recipient.name')
          }),
          stepComponentPath: 'multi-step-form/forms/administration/inventory/spillage/review'
        })
      });
    }),
    statusOptions: Ember.computed(function () {
      var intl = this.intl;
      return [{
        value: 0,
        name: intl.t('pages.users.my_inventory.spillage.open')
      }, {
        value: 1,
        name: intl.t('pages.users.my_inventory.spillage.locked')
      }, {
        value: 2,
        name: intl.t('pages.users.my_inventory.spillage.closed')
      }];
    }),
    totalQuantity: Ember.computed('trueListArray.@each.quantity', function () {
      var total = 0;
      this.trueListArray.forEach(item => {
        total += item.get('quantity') ? Number(item.get('quantity')) : 0;
      });
      return total;
    }),
    totalQuantityObserver: Ember.observer('totalQuantity', function () {
      if (this.totalQuantity > 0) {
        this.registerButtons();
      } else {
        this.registerButtons(true);
      }
    }),
    initFormData: (0, _emberConcurrency.task)(function* () {
      var hashedData = yield (0, _emberConcurrency.hash)({
        waitForFinishedCopyModels: (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true),
        recipients: this.inventoryService.get('getInventoryRecipients').perform()
      });
      this.set('recipients', hashedData.recipients);
      this.multiStepForm.formCopiedModelsSetVal('equipmentSpillage.model.createdByUser', this.user);
      this.multiStepForm.formCopiedModelsSetVal('equipmentSpillage.model.createdAt', (0, _moment.default)());
      this.multiStepForm.formCopiedModelsSetVal('equipmentSpillage.model.status', 0);
      this.set('dataLoaded', true);
    }).on('init'),

    didInsertElement() {
      this._super(...arguments);

      this.registerButtons(true);
    },

    closeReviewModal: (0, _emberConcurrency.task)(function* () {
      yield this.addRow();
    }),
    openReviewModal: (0, _emberConcurrency.task)(function* (multiStepForm) {
      var formattedObjects = this.prepForValidate('pendingEquipmentLineItem');
      var lineItemsToCreate = [];
      formattedObjects.push(Ember.Object.create({
        model: yield this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model'),
        extraValidationData: {
          noValidate: this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model.user.id') ? ['equipmentSpillage-location'] : ['equipmentSpillage-user']
        },
        filePath: 'equipment-spillage'
      }));
      yield multiStepForm.validateList.perform(formattedObjects);
      yield this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model.pendingEquipmentLineItems').forEach(lineItem => {
        if (lineItem.quantity > 0) {
          lineItemsToCreate.push(lineItem);
        }
      });
      this.set('lineItemsToCreate', lineItemsToCreate);
      this.set('reviewFormStarted', true);
    }),
    getRecipientEquipment: (0, _emberConcurrency.task)(function* () {
      var equipmentInventory = yield this.recipient.invokeAction('get_admin_equipment_inventory', {
        verb: 'GET'
      });
      var userEquipment = equipmentInventory.responsePayload.data.filter(item => {
        return item.on_hand_quantity > 0;
      });
      var companyEquipment = yield this.store.query('equipment', {
        limit: 0,
        filter: 'active=true'
      });
      var returnEquipment = Ember.A();

      var _loop = function (x) {
        var userItem = userEquipment.objectAt(x);
        var pushItem = companyEquipment.find(piece => piece.get('id') == userItem.equipment_id);

        if (pushItem) {
          pushItem.set('userItemInfo', userItem);
          returnEquipment.push(pushItem);
        }
      };

      for (var x = 0; x < userEquipment.length; x++) {
        _loop(x);
      }

      return returnEquipment;
    }),
    recipientChangedObserver: Ember.observer('recipient.id', function () {
      var multiStepForm = this.multiStepForm;
      var translationKey = 'pages.users.my_inventory.spillage.create_title' + (this.get('recipient.id') > 0 ? '_extended' : '');
      var titleData = {
        name: this.get('recipient.flname') || this.get('recipient.name')
      };
      multiStepForm.registerStepData("step1", 'title', this.intl.t(translationKey, titleData));
    }),
    getEquipmentInventory: (0, _emberConcurrency.task)(function* () {
      this.set('recipientSelected', false);
      var hashedData = yield (0, _emberConcurrency.hash)({
        equipment: this.getRecipientEquipment.perform(),
        equipmentReturnReasons: this.store.findAll('equipmentReturnReason')
      });

      if (this.get('recipient.id')) {
        var recipient = yield this.recipient;
        this.set(recipient.constructor.modelName === 'inventory-user' ? 'multiStepForm.formCopiedModels.equipmentSpillage.model.user' : 'multiStepForm.formCopiedModels.equipmentSpillage.model.location', recipient);
      }

      this.set('equipmentReturnReasons', hashedData.equipmentReturnReasons);
      this.set('equipment', hashedData.equipment);
      this.set('listArray', []);
      this.addRow();
      var multiStepForm = this.multiStepForm;
      multiStepForm.formCopiedModelsSetVal('equipmentSpillage.model.pendingEquipmentLineItems', this.listArray);
      this.set('recipientSelected', true);
    }),
    deleteSpillageLineItemTask: (0, _emberConcurrency.task)(function* (row) {
      yield this.deleteRow.perform(row, 'pendingEquipmentLineItem');
      this.multiStepForm.formCopiedModelsSetVal('equipmentSpillage.model.pendingEquipmentLineItems', this.listArray);
      this.set('spillageEquipmentLineItemDeleteId', false);
      this.getLineItemCount.perform();
    }),
    saveSpillageData: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var formattedObjects = this.prepForValidate('pendingEquipmentLineItems');
      var status = this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model.status');
      formattedObjects.pushObject(Ember.Object.create({
        modelName: 'equipmentSpillage',
        model: multiStepForm.formCopiedModels.equipmentSpillage.model,
        extraValidationData: {
          noValidate: this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model.user.id') ? ['equipmentSpillage-location'] : ['equipmentSpillage-user']
        },
        filePath: 'equipment-spillage'
      })); //validate line items first, then set copied model with ember data

      var toCreateObjects = yield multiStepForm.validateCreateList.perform(formattedObjects, {
        notificationNamespace: 'modal'
      });
      var newSpillage = this.store.createRecord('equipmentSpillage', {
        pendingEquipmentType: 'spillage',
        user: this.recipient.constructor.modelName.camelize() === 'inventoryUser' ? yield this.recipient.get('user') : null,
        location: this.recipient.constructor.modelName.camelize() === 'location' ? this.recipient : null,
        comments: toCreateObjects.equipmentSpillage[0].data.comments,
        status: 'open'
      });
      var savedSpillage = yield toCreateObjects.equipmentSpillage[0].saveWithRollbackAndNotification(newSpillage, 'modal2');
      var toCreateLineItems = [];

      if (toCreateObjects.pendingEquipmentLineItems) {
        toCreateObjects.pendingEquipmentLineItems.forEach(item => {
          var newPendingEquipmentLineItem = this.store.createRecord('pendingEquipmentLineItem', {
            quantity: item.data.quantity || 0,
            fulfilledQuantity: 0,
            cost: Number(item.data.cost) || 0,
            comments: item.data.comments,
            equipment: item.data.equipment,
            equipmentReturnReason: item.data.equipmentReturnReason,
            equipmentSpillage: savedSpillage
          });
          toCreateLineItems.push(toCreateObjects.equipmentSpillage[0].saveWithRollbackAndNotification(newPendingEquipmentLineItem, 'modal2'));
        });
      }

      yield (0, _emberConcurrency.all)(toCreateLineItems);

      if (status === 2) {
        yield savedSpillage.invokeAction('set_status_closed', {
          verb: 'POST'
        });
      } else if (status === 1) {
        yield savedSpillage.invokeAction('set_status_locked', {
          verb: 'POST'
        });
      }

      buttonPromise.then(() => {
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        multiStepForm.formStartedSetVal(false);

        if (this.refreshModel) {
          this.refreshModel();
        }
      });
    }),

    registerButtons(disabled = false) {
      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData("step1", 'buttons', [{
        text: this.pendingPurchaseOrder ? this.intl.t('common.actions.save') : this.intl.t('pages.users.my_inventory.physical_inventory.review'),
        click: buttonPromise => {
          if (this.pendingPurchaseOrder) {
            return this.pendingPurchaseOrderSaveTask.perform(buttonPromise, this.multiStepForm.formCopiedModelsGetVal('equipmentSpillage.model'), 'modal');
          } else {
            return this.openReviewModal.perform(multiStepForm);
          }
        },
        disabled,
        classNamesString: 'st-btn st-btn-primary po-review-save-button'
      }]);
    },

    actions: {
      deleteSpillageEquipmentLineItem(row, group) {
        this.deleteSpillageLineItemTask.perform(row, group);
      },

      updateEquipmentItemsList(row, index) {
        var lineItem = row;

        if (row.get('equipment')) {
          if (row.get('quantity') > row.get('equipment').userItemInfo.on_hand_quantity) {
            row.set('quantity', row.get('equipment').userItemInfo.on_hand_quantity);
          }
        }

        lineItem.set('comments', row.get('comments'));
        lineItem.set('fulfilledQuantity', row.get('fulfilledQuantity') || 0);
        lineItem.set('cost', row.get('equipment.cost'));
        lineItem.set('quantity', row.get('quantity') > 0 ? row.get('quantity') : 1);
        lineItem.set('returnReason', row.get('equipmentReturnReason'));
        this.updateRow(lineItem, index);
        this.multiStepForm.formCopiedModelsSetVal('equipmentSpillage.model.pendingEquipmentLineItems', this.listArray);
      }

    }
  });

  _exports.default = _default;
});