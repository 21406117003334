define("securitytrax-ember-ui/components/multi-step-form/forms/administration/users/my-inventory/purchase-orders/edit-create-purchase-requisition/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/switchable-form-step", "securitytrax-ember-ui/mixed-in-functions/grouped-property-sections", "securitytrax-ember-ui/mixins/list/row-list", "moment"], function (_exports, _emberConcurrency, _switchableFormStep, _groupedPropertySections, _rowList, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, _rowList.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    equipment: null,
    pendingEquipmentLineItemDeleteId: false,
    formStarted: false,
    confirmDeleteModalOpen: false,
    newStatus: undefined,
    completedStatuses: Ember.computed('multiStepForm.formCopiedModels.equipmentPurchaseRequisition.model.status', function () {
      var status = this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseRequisition.model.status');
      var createdByUser = this.multiStepForm.formOriginalModelsGetVal('equipmentPurchaseRequisition.model.createdByUser');
      var createdBy = createdByUser && createdByUser.get('id') > 0 ? createdByUser.get('fname') + ' ' + createdByUser.get('lname') : '';
      return {
        'open': {
          'closed': true,
          'secondary_text': (0, _moment.default)(this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseRequisition.model.createdAt')).format('MM/DD/YYYY h:mm a') + '<br>' + createdBy
        },
        'submitted': {
          'closed': status !== 'open',
          'secondary_text': ''
        },
        'closed': {
          'closed': status === 'closed',
          'secondary_text': ''
        }
      };
    }),
    formSteps: Ember.computed('formMode', 'user.{fname,lname}', function () {
      var intl = this.intl;
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: intl.t('common.confirm.are_you_sure'),
          stepComponentPath: 'multi-step-form/forms/administration/users/my-inventory/purchase-orders/review-pr'
        })
      });
    }),
    lastOriginalRowIndex: Ember.computed('multiStepForm.formOriginalModels.equipmentPurchaseRequisition.model.pendingEquipmentLineItems', function () {
      return this.multiStepForm.formOriginalModelsGetVal('equipmentPurchaseRequisition.model.pendingEquipmentLineItems.length') - 1;
    }),
    totalRequested: Ember.computed('listArray.@each.quantity', function () {
      var total = 0;

      if (this.listArray) {
        this.listArray.forEach(pendingEquipmentLineItem => {
          total += pendingEquipmentLineItem.get('quantity') ? parseInt(pendingEquipmentLineItem.get('quantity')) : 0;
        });
      }

      return total;
    }),
    totalCost: Ember.computed('listArray.@each.cost', function () {
      var total = 0.00;

      if (this.listArray) {
        this.listArray.forEach(pendingEquipmentLineItem => {
          total += (pendingEquipmentLineItem.get('cost') ? parseFloat(pendingEquipmentLineItem.get('cost')) : 0.00) * pendingEquipmentLineItem.get('quantity');
        });
      }

      return total;
    }),
    relatedDocuments: Ember.computed(function () {
      return this.relatedDocumentsTask.perform();
    }),
    relatedDocumentsTask: (0, _emberConcurrency.task)(function* () {
      var relatedDocuments;

      if (!this.isNewRecord) {
        relatedDocuments = yield this.store.query('equipmentPurchaseOrder', {
          filter: 'parent_equipment_purchase_requisition = ' + this.multiStepForm.formOriginalModelsGetVal('equipmentPurchaseRequisition.model.id'),
          include: 'user,location,created_by_user,equipment_vendor,pending_equipment_line_items.equipment.equipment_item'
        });
      }

      return relatedDocuments;
    }),
    totalRequestedObserver: Ember.observer('totalRequested', function () {
      if (this.totalRequested > 0) {
        this.registerButtons();
      } else {
        this.registerButtons(true);
      }
    }),
    initFormData: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = this.multiStepForm;
      yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);
      var lineItems = yield this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseRequisition.model.pendingEquipmentLineItems');
      var equipment = yield this.store.query('equipment', {
        limit: 0,
        sort: 'name',
        filter: 'active=true'
      });
      var status = this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseRequisition.model.status');
      this.set('listArray', lineItems);
      this.set('equipment', equipment);
      lineItems.forEach(lineItem => {
        lineItem.set('totalCost', (lineItem.get('cost') ? lineItem.get('cost') : 0.00) * lineItem.get('quantity'));
      });
      this.registerButtons(lineItems, true);

      if (this.formMode === 'create' || status === 'open') {
        this.addRow();
      }
    }).on('init'),
    groupedPurchaseOrders: Ember.computed('multiStepForm.formCopiedModels.equipmentPurchaseRequisition.model.pendingEquipmentLineItems.[]', 'relatedDocuments', function () {
      return this.groupedPurchaseOrdersTask.perform('purchaseOrderGroups');
    }),
    noPurchaseOrders: Ember.computed('multiStepForm.formCopiedModels.equipmentPurchaseRequisition.model.pendingEquipmentLineItems.[]', 'relatedDocuments', function () {
      return this.groupedPurchaseOrdersTask.perform('noPurchaseOrderGroup');
    }),
    groupedPurchaseOrdersTask: (0, _emberConcurrency.task)(function* (groupType) {
      var childTasks = [];
      var childPurchaseOrders = yield this.relatedDocuments;
      var lineItems = this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseRequisition.model.pendingEquipmentLineItems');

      if (childPurchaseOrders) {
        for (var i = 0; i < childPurchaseOrders.get('length'); i++) {
          var po = childPurchaseOrders.objectAt(i);
          var childLineItems = po.get('pendingEquipmentLineItems');

          for (var j = 0; j < childLineItems.get('length'); j++) {
            var childLineItem = childLineItems.objectAt(j);
            var childLineItemParent = childLineItem ? childLineItem.get('parentPendingEquipmentLineItem') : null;
            var parentLineItem = childLineItemParent ? lineItems.findBy('id', childLineItemParent.get('id')) : null;

            if (parentLineItem) {
              parentLineItem.set('childPurchaseOrder', childLineItem ? childLineItem.get('equipmentPurchaseOrder') : null);
            }
          }
        }
      }

      if (lineItems) {
        (0, _emberConcurrency.all)(lineItems.map(item => {
          if (item.get('childPurchaseOrder') && groupType === 'purchaseOrderGroups') {
            childTasks.push(this.mapPurchaseOrdersTask.perform(item));
          } else if (!item.get('childPurchaseOrder') && groupType !== 'purchaseOrderGroups') {
            childTasks.push(this.mapPurchaseOrdersTask.perform(item));
          }
        }));
      }

      var allLineItems = yield (0, _emberConcurrency.all)(childTasks);
      var purchaseOrderGroups = allLineItems.uniqBy('po.id').map(({
        po
      }) => {
        if (po.get('id') > 0) {
          return Ember.Object.create({
            name: this.intl.t('pages.administration.company_settings.inventory.purchase_requisitions.purchase_order_group_title', {
              id: po.get('id'),
              vendor: po.get('equipmentVendor.name')
            }),
            icon: 'icn-document',
            sections: (0, _groupedPropertySections.default)(),
            items: allLineItems.filterBy('po.id', po.id).mapBy('equipment'),
            status: po.get('isFulfilled') ? this.intl.t('pages.administration.company_settings.inventory.purchase_requisitions.fulfilled').toLowerCase() : po.get('status'),
            statusIcon: po.get('isFulfilled') ? 'icn-circle-check' : 'icn-issue'
          });
        }
      });
      var noPurchaseOrderGroup = allLineItems.uniqBy('po.id').map(({
        po
      }) => {
        if (po.get('id') === 0) {
          return Ember.Object.create({
            name: po.get('name'),
            icon: po.get('icon'),
            sections: (0, _groupedPropertySections.default)(),
            items: allLineItems.filterBy('po.id', po.id).mapBy('equipment'),
            status: '',
            statusIcon: ''
          });
        }
      });
      return groupType === 'purchaseOrderGroups' ? purchaseOrderGroups : noPurchaseOrderGroup;
    }),
    mapPurchaseOrdersTask: (0, _emberConcurrency.task)(function* (item) {
      var equipmentPurchaseOrder = yield item.get('childPurchaseOrder');
      var equipment = item.equipment;
      var equipmentItem = equipment ? equipment.get('equipmentItem') : null;
      var equipmentPurchaseOrderNone = Ember.Object.create({
        id: 0,
        name: this.intl.t('pages.administration.company_settings.inventory.purchase_requisitions.no_purchase_order'),
        icon: 'icn-access-none',
        equipment: equipmentItem
      });
      return {
        equipment: item,
        po: equipmentPurchaseOrder ? equipmentPurchaseOrder : equipmentPurchaseOrderNone
      };
    }),
    deletePendingEquipmentLineItemTask: (0, _emberConcurrency.task)(function* (row) {
      yield this.deleteRow.perform(row, 'pendingEquipmentLineItem');
      this.multiStepForm.formCopiedModelsSetVal('equipmentPurchaseRequisition.model.pendingEquipmentLineItems', this.listArray);
      this.set('pendingEquipmentLineItemDeleteId', false);
    }),
    closePurchaseRequisitionTask: (0, _emberConcurrency.task)(function* (buttonPromise) {
      try {
        var pr = this.multiStepForm.formOriginalModelsGetVal('equipmentPurchaseRequisition.model');
        Ember.set(pr, 'recipientNotes', this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseRequisition.model.recipientNotes'));
        yield pr.save();
        yield pr.invokeAction('set_status_closed', {
          verb: 'POST'
        });
        buttonPromise.then(() => {
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
          this.set('pendingPurchaseOrders', null);
          this.multiStepForm.formStartedSetVal(false);
          this.multiStepForm.onAfterSave();
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal2'
        });
        throw err;
      }
    }),
    convertPurchaseRequisitionTask: (0, _emberConcurrency.task)(function* (buttonPromise) {
      try {
        var store = this.store;
        var pr = this.multiStepForm.formOriginalModelsGetVal('equipmentPurchaseRequisition.model');
        Ember.set(pr, 'recipientNotes', this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseRequisition.model.recipientNotes'));
        yield pr.save();
        var lineItems = [];
        this.listArray.forEach(item => {
          var lineItem = {
            pending_equipment_line_item_id: item.get('id'),
            equipment_vendor_id: item.get('responseMeta.default_vendor.id') || item.get('responseMeta.default_vendor')
          };

          if (item.get('responseMeta.default_vendor') !== null) {
            lineItems.push(lineItem);
          }
        });

        if (lineItems.get('length') === 0) {
          throw this.intl.t('pages.administration.company_settings.inventory.purchase_requisitions.no_vendors_set');
        }

        var hashedData = yield (0, _emberConcurrency.hash)({
          equipmentVendors: store.findAll('equipmentVendor', {
            include: 'equipment_vendor_config'
          }),
          equipmentVendorToRecipients: this.grants.getIndividualGrant('equipment_vendor_to_recipients.view') ? store.query('equipmentVendorToRecipient', {
            limit: 0
          }) : [],
          technicians: store.findAll('user', {
            filter: 'user_inventory_setting.inventory_recipient = true and user_inventory_setting.inventory_auto_replenish = true and user_admin_setting.active = true',
            sort: 'lname,fname'
          }),
          locations: store.findAll('location', {
            filter: 'inventory_recipient = true and inventory_auto_replenish = true and active = true'
          }),
          pendingPurchaseOrders: pr.invokeAction('get_conversion_data', {
            verb: 'POST',
            data: {
              data: {
                attributes: {
                  pending_equipment_line_item_data: lineItems
                }
              }
            }
          })
        });
        hashedData.pendingPurchaseOrders.responsePayload.data.forEach(po => {
          var type = po.recipient_type;
          var recipient = (type === 'location' ? hashedData.locations : hashedData.technicians).find(recipient => recipient.get('id') == po.recipient_id);
          var vendor = hashedData.equipmentVendors.find(equipmentVendor => equipmentVendor.get('id') == po.vendor_id);
          var vendorToRecipient = hashedData.equipmentVendorToRecipients.find(vendorToRecipient => vendorToRecipient.get('id') == JSON.stringify({
            recipient_class: po.recipient_type == 'location' ? '200' : '100',
            recipient_id: po.recipient_id,
            vendor_id: po.vendor_id
          }));
          po['retrievalMethodId'] = vendorToRecipient ? vendorToRecipient.get('defaultRetrievalMethodId') : null;
          po['processingMethodId'] = vendorToRecipient ? vendorToRecipient.get('defaultProcessingMethodId') : null;
          po['branchNumber'] = vendorToRecipient && vendorToRecipient.get('defaultProcessingMethodId') === 100 ? vendorToRecipient.get('branchNumber') : null;
          po['recipient'] = recipient;
          po['equipmentVendor'] = vendor;
          po['type'] = type;
          po['parent'] = pr;
          po['removed'] = false, po['title'] = this.intl.t('pages.administration.company_settings.inventory.company_replenishment.pending_purchase_order_title', {
            name: type === 'location' ? recipient.get('name') : recipient.get('flname'),
            vendor: vendor.get('name')
          });
        });
        this.set('pendingPurchaseOrders', this.pendingPurchaseOrders ? this.pendingPurchaseOrders.concat(hashedData.pendingPurchaseOrders.responsePayload.data) : hashedData.pendingPurchaseOrders.responsePayload.data);
        yield pr.invokeAction('set_status_closed', {
          verb: 'POST'
        });
        buttonPromise.then(() => {
          this.set('showPendingPurchaseOrders', true);
          this.multiStepForm.formStartedSetVal(false);
          this.multiStepForm.onAfterSave();

          if (this.refreshModel) {
            this.refreshModel();
          }
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal2'
        });
        throw err;
      }
    }),
    savePurchaseRequisitionDataTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise, status) {
      var formattedObjects = this.prepForValidate('pendingEquipmentLineItem', true);
      var savedPr;

      if (this.isNewRecord) {
        formattedObjects.pushObject(Ember.Object.create({
          modelName: 'equipmentPurchaseRequisition',
          model: multiStepForm.formCopiedModels.equipmentPurchaseRequisition.model
        })); //validate line items first, then set copied model with ember data

        var toCreateObjects = yield multiStepForm.validateCreateList.perform(formattedObjects, {
          notificationNamespace: status === 'submitted' ? 'modal2' : 'modal'
        });
        var newPr = this.store.createRecord('equipmentPurchaseRequisition', {
          pendingEquipmentType: 'pr',
          user: this.user,
          comments: toCreateObjects.equipmentPurchaseRequisition[0].data.comments,
          catalyst: 'manual'
        });
        var lineItemSaves = [];
        savedPr = yield toCreateObjects.equipmentPurchaseRequisition[0].saveWithRollbackAndNotification(newPr, status === 'submitted' ? 'modal2' : 'modal');

        if (toCreateObjects.pendingEquipmentLineItem) {
          for (var i = 0; i < toCreateObjects.pendingEquipmentLineItem.length; i++) {
            var item = toCreateObjects.pendingEquipmentLineItem[i];
            var newPendingEquipmentLineItem = this.store.createRecord('pendingEquipmentLineItem', {
              cost: Number(item.data.cost) || 0,
              quantity: item.data.quantity || 0,
              fulfilledQuantity: 0,
              comments: item.data.comments,
              equipment: item.data.equipment,
              equipmentPurchaseRequisition: savedPr
            });
            lineItemSaves.push(toCreateObjects.equipmentPurchaseRequisition[0].saveWithRollbackAndNotification(newPendingEquipmentLineItem, status === 'submitted' ? 'modal2' : 'modal'));
          }
        }

        yield (0, _emberConcurrency.all)(lineItemSaves);
      } else {
        multiStepForm.formCopiedModelsSetVal('equipmentPurchaseRequisition.model.pendingEquipmentLineItems', this.trueListArray);
        yield multiStepForm.validateSyncSaveAll({
          notificationNamespace: status === 'submitted' ? 'modal2' : 'modal',
          doSetupCopyModelsAfterSave: false
        });
      }

      if (status === 'submitted') {
        var pr = this.isNewRecord ? savedPr : yield this.store.findRecord('equipmentPurchaseRequisition', this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseRequisition.model.id'));
        yield pr.invokeAction('set_status_submitted', {
          verb: 'POST'
        });
        this.multiStepForm.onAfterSave();
      }

      buttonPromise.then(() => {
        multiStepForm.formStartedSetVal(false);
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');

        if (this.refreshModel) {
          this.refreshModel();
        }
      });
    }),

    registerButtons(disabled = false) {
      var multiStepForm = this.multiStepForm;
      var status = this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseRequisition.model.status');
      var buttons = [];

      if (this.grants.getIndividualGrant('equipment_purchase_requisitions.modify')) {
        if (status === 'submitted' && this.admin) {
          buttons.push({
            text: this.intl.t('common.actions.decline'),
            click: () => {
              this.set('newStatus', 'decline');
              this.set('confirmDeleteModalOpen', true);
            },
            classNamesString: 'st-btn warning-red pr-decline-button'
          });
        }
      }

      if ((this.admin && status === 'submitted' || status === 'open') && this.grants.getIndividualGrant('equipment_purchase_requisitions.modify')) {
        buttons.push({
          text: !this.admin ? this.intl.t('common.actions.saveAndSubmit') : this.intl.t('pages.administration.company_settings.inventory.purchase_requisitions.convert_pr'),
          click: () => {
            if (!this.admin) {
              this.set('formStarted', true);
            } else {
              this.set('newStatus', 'convert');
              this.set('confirmDeleteModalOpen', true);
            }
          },
          classNamesString: !this.admin ? 'st-btn st-btn-secondary pr-save-button' : 'st-btn st-btn-primary pr-save-button'
        });
      }

      if (status === 'open' && !this.admin && this.grants.getIndividualGrant('equipment_purchase_requisitions.modify') || this.formMode === 'create' && this.grants.getIndividualGrant('equipment_purchase_requisitions.modify')) {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.savePurchaseRequisitionDataTask.perform(multiStepForm, buttonPromise);
          },
          disabled: this.isNewRecord ? disabled : false,
          classNamesString: this.admin ? 'st-btn btn-secondary pr-save-later-button' : 'st-btn st-btn-primary pr-save-later-button'
        });
      }

      multiStepForm.registerStepData("step1", 'buttons', buttons);
    },

    actions: {
      deletePendingEquipmentLineItem(row) {
        this.deletePendingEquipmentLineItemTask.perform(row);
      },

      updateEquipmentItemsList(row, index) {
        var lineItems = this.listArray;
        var lineItem = lineItems[index];
        lineItem.set('quantity', row.get('quantity'));
        lineItem.set('comments', row.get('comments'));
        lineItem.set('sku', lineItem.get('equipment.sku'));
        lineItem.set('cost', lineItem.get('equipment.cost'));
        lineItem.set('fulfilledQuantity', 0);
        this.updateRow(lineItem, index);
      }

    }
  });

  _exports.default = _default;
});