define("securitytrax-ember-ui/transforms/json", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize(serialized) {
      var output;

      if (typeof serialized !== 'string') {
        serialized = JSON.stringify(serialized);
      }

      try {
        output = JSON.parse(serialized);
      } catch (e) {
        (false && Ember.warn(e, {
          id: 'app.json-transform'
        }));
      }

      return output;
    },

    serialize(deserialized) {
      return deserialized;
    }

  });

  _exports.default = _default;
});