define("securitytrax-ember-ui/helpers/feature-is-enabled", ["exports", "securitytrax-ember-ui/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.featureIsEnabled = featureIsEnabled;
  _exports.default = void 0;

  //at some point, per-company logic could be enabled here
  function featureIsEnabled(args) {
    var companyEnabled = args[1]; // deal with different invocation styles (component vs templates)

    var feature = args[0];

    if (typeof feature == 'object') {
      feature = feature[0];
    }

    if (_environment.default['securitytrax-ember-ui']['features']['limit'] === true) {
      return !_environment.default['securitytrax-ember-ui']['features']['revoked'][feature] || companyEnabled;
    }

    return true;
  }

  var _default = Ember.Helper.helper(featureIsEnabled);

  _exports.default = _default;
});