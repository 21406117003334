define("securitytrax-ember-ui/services/screen", ["exports", "ember-screen"], function (_exports, _emberScreen) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberScreen.default.extend({
    isMobile: (0, _emberScreen.breakpoint)('(max-width: 39.999em)'),
    isTablet: (0, _emberScreen.breakpoint)('(min-width: 40em) and (max-width: 63.999em'),
    mobileAndTablet: (0, _emberScreen.breakpoint)('(max-width: 63.999em)'),
    isDesktop: (0, _emberScreen.breakpoint)('(min-width: 64em)')
  });

  _exports.default = _default;
});