define("securitytrax-ember-ui/components/pages/administration/inventory/equipment-transfer/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    inventoryService: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    grants: Ember.inject.service(),
    stFiltersService: Ember.inject.service(),
    filterGroupKeyStr: 'inventoryEquipmentTransfer',
    formMode: 'view',
    transfer: null,
    formStarted: false,
    limit: 50,
    page: 1,
    transfersListCount: 0,
    createdDateFrom: undefined,
    createdDateTo: undefined,
    status: undefined,
    equipmentItem: undefined,
    targetRecipient: undefined,
    sourceRecipient: undefined,
    showNotification: true,

    get transferListDefault() {
      return this.statusOptions.filter(status => {
        return status.value === 'pending';
      });
    },

    transfersNotice: Ember.computed(function () {
      return this.intl.t('pages.administration.company_settings.inventory.equipment_transfer.description', {
        link: 'https://answers.securitytrax.com/Support/Company_and_Admin/Inventory_Management/Transfers'
      });
    }),
    equipment: Ember.computed(function () {
      return this.store.query('equipment', {
        limit: 0,
        filter: 'active=true'
      });
    }),
    formSteps: Ember.computed('formMode', 'transfer', function () {
      var intl = this.intl;
      var translationKey = 'pages.administration.company_settings.inventory.equipment_transfer.' + (this.formMode === 'create' ? 'create' : 'edit') + '_title';
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: intl.t(translationKey, {
            id: this.transfer ? this.transfer.get('id') : '',
            source: this.transfer ? this.transfer.get('sourceUser.id') ? this.transfer.get('sourceUser.flname') + ' (' + this.intl.t('common.actions.searchOptions.resources.user') + ')' : this.transfer.get('sourceLocation.inventorySinkNameDescription') : '',
            target: this.transfer ? this.transfer.get('targetUser.id') ? this.transfer.get('targetUser.flname') + ' (' + this.intl.t('common.actions.searchOptions.resources.user') + ')' : this.transfer.get('targetLocation.inventorySinkNameDescription') : ''
          }),
          stepComponentPath: 'multi-step-form/forms/administration/inventory/equipment-transfer/' + (this.formMode === 'create' ? 'create-transfer' : 'edit-transfer')
        })
      });
    }),
    formOriginalModels: Ember.computed('transfer', function () {
      return Ember.Object.create({
        adminEquipmentTransfer: {
          model: this.transfer
        }
      });
    }),
    statusOptions: Ember.computed(function () {
      var intl = this.intl;
      return [{
        value: 'pending',
        name: intl.t('common.status.pending')
      }, {
        value: 'complete',
        name: intl.t('common.status.complete')
      }, {
        value: 'rejected',
        name: intl.t('common.status.rejected')
      }];
    }),
    recipientOptions: Ember.computed(function () {
      return this.inventoryService.get('getInventoryRecipients').perform();
    }),
    transfers: Ember.computed(function () {
      return this.getTransfersTask.perform();
    }),
    getTransfersTask: (0, _emberConcurrency.task)(function* () {
      var newFilters = yield this.stFiltersService.getFiltersByGroupKey(this.filterGroupKeyStr);
      var queryParams = {
        filter: newFilters,
        sort: '-id',
        include: 'equipment,source_user,target_user,source_location,target_location',
        limit: this.limit,
        page: this.page
      };

      if (this.page == 1) {
        queryParams['count'] = 1;
      }

      var transfers = yield this.store.query('equipmentTransfer', queryParams);

      if (this.page == 1) {
        this.set('transfersListCount', transfers.meta.record_count);
      }

      return transfers;
    }).restartable().maxConcurrency(1),
    actions: {
      clearFilter() {
        Ember.setProperties(this, {
          createdDateFrom: undefined,
          createdDateTo: undefined,
          status: this.transferListDefault,
          equipmentItem: undefined,
          targetRecipient: undefined,
          sourceRecipient: undefined,
          transfersLoaded: false
        });
        Ember.notifyPropertyChange(this, 'transfers');
      },

      filterTransfers() {
        this.set('page', 1);
        Ember.notifyPropertyChange(this, 'transfers');
      },

      getTransfers() {
        Ember.notifyPropertyChange(this, 'transfers');
      },

      refreshModel() {
        if (this.target) {
          this.get('transfer.source.sourceEquipmentTransfers').reload();
          this.get('transfer.target.targetEquipmentTransfers').reload();
        }

        Ember.notifyPropertyChange(this, 'transfers');
      },

      editAction(row) {
        if (this.grants.getIndividualGrant('equipment_transfers.modify')) {
          this.set('formMode', 'edit');
        }

        this.set('transfer', row);
        this.set('formStarted', true);
      }

    }
  });

  _exports.default = _default;
});