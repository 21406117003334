define("securitytrax-ember-ui/helpers/leaflet-dynamic-appointment-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.leafletDynamicAppointmentIcon = leafletDynamicAppointmentIcon;
  _exports.default = void 0;

  /* global L */
  function leafletDynamicAppointmentIcon(params) {
    var classname = params[0];
    return L.divIcon({
      iconSize: [24, 24],
      iconAnchor: [12, 12],
      popupAnchor: [0, 0],
      className: classname
    });
  }

  var _default = Ember.Helper.helper(leafletDynamicAppointmentIcon);

  _exports.default = _default;
});