define("securitytrax-ember-ui/models/contract-term", ["exports", "securitytrax-ember-ui/models/base_models/contract-term"], function (_exports, _contractTerm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _contractTerm.default.extend({
    isOneMonth: Ember.computed('months', function () {
      if (this.months === 1) {
        return true;
      }

      return false;
    }),
    description: Ember.computed('months', function () {
      return this.intl.t('pages.customers.order.contractTerm.description', {
        numMonths: this.months
      });
    })
  });

  _exports.default = _default;
});