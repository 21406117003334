define("securitytrax-ember-ui/home/tech-today/appointment/edit-create-customer-equipment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IhtOwt9d",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modals/sub-route-modals/edit-create-customer-equipment\",[],[[\"@customer\",\"@customerEquipmentId\",\"@workOrder\",\"@parentRoute\"],[[34,0,[\"customer\"]],[34,0,[\"customerEquipmentId\"]],[34,0,[\"workOrder\"]],[34,0,[\"parentRoute\"]]]],null]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/home/tech-today/appointment/edit-create-customer-equipment/template.hbs"
    }
  });

  _exports.default = _default;
});