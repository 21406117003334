define("securitytrax-ember-ui/components/multi-step-form/forms/accounting/edit-create-payment-method/method-layouts/credit-card/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "securitytrax-ember-ui/mixins/credit-card-mixin", "ember-concurrency"], function (_exports, _switchableFormStep, _creditCardMixin, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, _creditCardMixin.default, {
    intl: Ember.inject.service(),
    months: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    tagName: '',
    billing: undefined,
    loggedInUser: undefined,
    pan: undefined,
    viewPanOpen: false,
    initDataTask: (0, _emberConcurrency.task)(function* () {
      var hashedData = yield (0, _emberConcurrency.hash)({
        waitForFinishedCopyModels: (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true),
        loggedInUser: this.sessionData.getUser()
      });

      if (this.isNewRecord) {
        this.multiStepForm.formCopiedModelsSetVal('customerBilling.model.billCcName', this.customer.get('forcedCustomerFlname'));
      }

      this.multiStepForm.formCopiedModelsSetVal('customerBilling.model.billCcExpirationMonth', this.multiStepForm.formOriginalModelsGetVal('customerBilling.model.billCcExpirationMonth'));
      this.multiStepForm.formCopiedModelsSetVal('customerBilling.model.billCcExpirationYear', this.multiStepForm.formOriginalModelsGetVal('customerBilling.model.billCcExpirationYear'));
      Ember.set(this, 'billing', this.multiStepForm.formCopiedModelsGetVal('customerBilling.model'));
      Ember.set(this, 'loggedInUser', hashedData.loggedInUser);
    }).on('init'),
    viewPanTask: (0, _emberConcurrency.task)(function* () {
      if (this.pan === undefined) {
        try {
          var fullSsn = yield this.multiStepForm.formOriginalModelsGetVal('customerBilling.model').invokeAction('view_pan', {
            verb: 'GET'
          });
          Ember.set(this, 'pan', fullSsn.responsePayload.data.pan);
        } catch (err) {
          this.stNotificationManager.addErrorMessages(err, {
            notificationNamespace: 'billingModal'
          });
          throw err;
        }
      }

      Ember.set(this, 'viewPanOpen', true);
    })
  });

  _exports.default = _default;
});