define("securitytrax-ember-ui/components/multi-step-form/forms/demo/demo-progress-bar/step-4/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/demo/demo-progress-bar/demo-progress-bar-base"], function (_exports, _demoProgressBarBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _demoProgressBarBase.default.extend({
    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('step4', 'buttons', [{
        text: 'Previous',
        click: function () {
          multiStepForm.currentStepNameKeySetVal('step3');
          multiStepForm.progressBarConfig.activeIndex = 2;
        },
        classNamesString: 'st-btn st-btn-secondary'
      }, {
        text: 'Done',
        click: function () {
          this.set('dialogOpen', false);
        },
        classNamesString: 'st-btn st-btn-primary'
      }]);
      multiStepForm.modalOpen = true;
    }

  });

  _exports.default = _default;
});