define("securitytrax-ember-ui/components/pages/my-account/payables/component", ["exports", "ember-concurrency", "lodash", "securitytrax-ember-ui/mixins/table-column-sort", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _emberConcurrency, _lodash, _tableColumnSort, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableColumnSort.default, {
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    tagsService: Ember.inject.service(),
    stFiltersService: Ember.inject.service(),
    limit: 50,
    page: 1,
    createdDateFrom: undefined,
    createdDateTo: undefined,
    paidDateFrom: undefined,
    paidDateTo: undefined,
    statuses: undefined,
    saleDateFrom: undefined,
    saleDateTo: undefined,
    installDateFrom: undefined,
    installDateTo: undefined,
    expectedPayDateFrom: undefined,
    expectedPayDateTo: undefined,
    payableTransactionDateFrom: undefined,
    payableTransactionDateTo: undefined,
    payableTransactionsExist: undefined,
    jobFunctionTypes: undefined,
    jobFunctions: undefined,
    installStatus: undefined,
    customerIsCanceled: undefined,
    appliedCustomerTags: undefined,
    appliedTags: undefined,
    appliedFiltersCount: 0,
    jobFunctionTypesSorted: undefined,
    jobFunctionsSorted: undefined,
    customerTags: undefined,
    payableTags: undefined,
    payrollStatuses: undefined,

    init() {
      this._super(...arguments);

      var intl = this.intl;
      (0, _objHelpers.initializeState)(this, {
        stSortableColumnOptions: [{
          name: intl.t('common.columns.id') + ' ' + intl.t('common.sorting.ascending'),
          value: 'id',
          chevronDirection: 'up'
        }, {
          name: intl.t('common.columns.id') + ' ' + intl.t('common.sorting.descending'),
          value: '-id',
          chevronDirection: 'down'
        }, {
          name: intl.t('common.columns.created_date') + ' ' + intl.t('common.sorting.ascending'),
          value: 'created_at',
          chevronDirection: 'up'
        }, {
          name: intl.t('common.columns.created_date') + ' ' + intl.t('common.sorting.descending'),
          value: '-created_at',
          chevronDirection: 'down'
        }, {
          name: intl.t('common.columns.category') + ' ' + intl.t('common.sorting.ascending'),
          value: 'payroll_role.name',
          chevronDirection: 'up'
        }, {
          name: intl.t('common.columns.category') + ' ' + intl.t('common.sorting.descending'),
          value: '-payroll_role.name',
          chevronDirection: 'down'
        }, {
          name: intl.t('pages.users.payables.job_function') + ' ' + intl.t('common.sorting.ascending'),
          value: 'payroll_role_function.name',
          chevronDirection: 'up'
        }, {
          name: intl.t('pages.users.payables.job_function') + ' ' + intl.t('common.sorting.descending'),
          value: '-payroll_role_function.name',
          chevronDirection: 'down'
        }, {
          name: intl.t('common.columns.amount') + ' ' + intl.t('common.sorting.ascending'),
          value: 'amount',
          chevronDirection: 'up'
        }, {
          name: intl.t('common.columns.amount') + ' ' + intl.t('common.sorting.descending'),
          value: '-amount',
          chevronDirection: 'down'
        }, {
          name: intl.t('common.columns.status') + ' ' + intl.t('common.sorting.ascending'),
          value: 'payroll_payment_status.name',
          chevronDirection: 'up'
        }, {
          name: intl.t('common.columns.status') + ' ' + intl.t('common.sorting.descending'),
          value: '-payroll_payment_status.name',
          chevronDirection: 'down'
        }]
      });
      this.initFilterDataTask.perform();
    },

    trueFalseOptions: Ember.computed(function () {
      return [{
        value: true,
        name: this.intl.t('common.confirm.true')
      }, {
        value: false,
        name: this.intl.t('common.confirm.false')
      }];
    }),
    installStatusOptions: Ember.computed(function () {
      return [{
        id: 1,
        name: this.intl.t('common.status.installed')
      }, {
        id: 2,
        name: this.intl.t('common.status.notComplete')
      }, {
        id: 4,
        name: this.intl.t('common.status.notInstalled')
      }];
    }),
    jobFunctionTypesSortedTask: (0, _emberConcurrency.task)(function* () {
      var jobFunctionTypes = yield this.store.findAll('payrollRole');
      return jobFunctionTypes.sortBy('name');
    }),
    jobFunctionsSortedTask: (0, _emberConcurrency.task)(function* () {
      var jobFunctions = yield this.store.findAll('payrollRoleFunction', {
        include: 'payroll_role,payroll_list_method,payroll_role_function_levels'
      });
      return jobFunctions.sortBy('name');
    }),
    initFilterDataTask: (0, _emberConcurrency.task)(function* () {
      var hashData = yield (0, _emberConcurrency.hash)({
        jobFunctionTypesSorted: this.jobFunctionTypesSortedTask.perform(),
        jobFunctionsSorted: this.jobFunctionsSortedTask.perform(),
        customerTags: this.tagsService.getTagsByObjectType.perform('customer'),
        payableTags: this.tagsService.getTagsByObjectType.perform('payroll_to_customer'),
        payrollStatuses: this.store.findAll('payrollPaymentStatus')
      });
      Ember.setProperties(this, hashData);
    }),
    userPayablesData: Ember.computed(function () {
      return this.userPayablesDataTask.perform();
    }),
    userPayablesDataTask: (0, _emberConcurrency.task)(function* () {
      var newFilters = yield this.stFiltersService.getFiltersByGroupKey('myAccountPayables', ['user_entity = ' + this.model.user.get('id')]);
      var userPayables = yield this.store.query('customerPayable', {
        filter: newFilters,
        sort: this.stSortString ? this.stSortString : '-id',
        include: 'payroll_role,payroll_role_function,payroll_payment_status,customer,pay_on_tech_schedule.calendar_event_type,pay_on_customer',
        limit: this.limit,
        page: this.page,
        count: 1
      });
      return {
        userPayables,
        record_count: _lodash.default.get(userPayables, 'meta.record_count', 0)
      };
    }),
    actions: {
      transitionToSubroute(path) {
        this.router.transitionTo('my-account.payables.edit-payable', path);
      },

      updateCustomerAtRisks() {
        this.notifyPropertyChange('userPayablesData');
      },

      filterSearch() {
        this.set('page', 1);
        this.notifyPropertyChange('userPayablesData');
      },

      clearFilterSearch() {
        Ember.setProperties(this, {
          page: 1,
          createdDateFrom: undefined,
          createdDateTo: undefined,
          paidDateFrom: undefined,
          paidDateTo: undefined,
          statuses: undefined,
          payableFormStarted: false,
          saleDateFrom: undefined,
          saleDateTo: undefined,
          installDateFrom: undefined,
          installDateTo: undefined,
          expectedPayDateFrom: undefined,
          expectedPayDateTo: undefined,
          payableTransactionDateFrom: undefined,
          payableTransactionDateTo: undefined,
          payableTransactionsExist: undefined,
          jobFunctionTypes: undefined,
          jobFunctions: undefined,
          installStatus: undefined,
          customerIsCanceled: undefined,
          appliedCustomerTags: undefined,
          appliedTags: undefined
        });
        this.notifyPropertyChange('userPayablesData');
        this.notifyPropertyChange('page');
      },

      //action sent to sortable-column component
      sortList(column, ascending) {
        this.sortPaginatedList(column, ascending, 'recordsLoaded');
        this.notifyPropertyChange('userPayablesData');
      },

      mobileSortList(column) {
        this.sortMobilePaginatedList(column.value, 'recordsLoaded');
        this.notifyPropertyChange('userPayablesData');
      }

    }
  });

  _exports.default = _default;
});