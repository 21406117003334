define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/partner-companies/base-partner-companies", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "securitytrax-ember-ui/mixins/set-user", "ember-concurrency"], function (_exports, _switchableFormStep, _setUser, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, _setUser.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    leadsFormMode: 'view',
    customersFormMode: 'view',
    companyFormMode: 'view',

    didInsertElement() {
      this._super(...arguments);

      if (this.isNewRecord) {
        this.set('companyFormMode', 'create');
      }
    },

    saveLeadCompanyTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise, noValidateArray = []) {
      if (this.isNewRecord) {
        var toCreateObjects = yield multiStepForm.validateCreateAll({
          extraValidationData: {
            noValidate: noValidateArray
          }
        });
        var theModel = this.store.createRecord('leadCompany', toCreateObjects.leadCompany.data);
        yield toCreateObjects.leadCompany.saveWithRollbackAndNotification(theModel, 'modal');
      } else {
        yield multiStepForm.validateSyncSaveAll({
          extraValidationData: {
            noValidate: noValidateArray
          }
        });
      }

      Ember.setProperties(this, {
        leadsFormMode: 'view',
        customersFormMode: 'view',
        companyFormMode: 'view'
      });
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        multiStepForm.formStartedSetVal(false);
      });
    })
  });

  _exports.default = _default;
});