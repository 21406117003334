define("securitytrax-ember-ui/components/multi-step-form/forms/invoices/invoice/details/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/invoices/base-step-components/details/base", "ember-concurrency", "moment"], function (_exports, _base, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var InvoiceDetails = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = (0, _emberConcurrency.task)(function* () {
    yield this.initBaseData.perform();
    Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.invoiceType', 'invoice');

    if (this.args.formMode === 'create') {
      if (this.args.workOrder) {
        var promises = [];
        var workOrderEquipment = (yield this.args.workOrder.get('workOrderEquipment')) || [];
        workOrderEquipment.forEach(item => {
          promises.push(this.populateLineItemTask.perform(this.RowList.listArray, item.get('customerEquipment')));
        });
        yield (0, _emberConcurrency.all)(promises);
        this.RowList.addRow();
        this.args.multiStepForm.formCopiedModelsSetVal('invoice.model.invoiceLineItems', this.RowList.trueListArray);
      }

      var response = yield this.stAjax.request(this.stAjax.getUrl('/global_settings_invoice_settings/1'), {
        method: 'GET'
      });
      var offset = response && response.data.attributes.default_sale_date_days_in_future ? response.data.attributes.default_sale_date_days_in_future : 0;
      this.args.multiStepForm.formCopiedModelsSetVal('invoice.model.saleDate', (0, _moment.default)().add(offset, 'days').format('MM/DD/YYYY'));
    } else {
      //formMode !== 'create'
      var invoice = yield this.args.multiStepForm.formOriginalModelsGetVal('invoice.model');
      this.recurringInvoice = yield invoice.get('recurringInvoice');

      if (this.recurringInvoice) {
        this.recurringInvoiceLink = this.intl.t('pages.customers.nrg.created_from_recurring_invoice', {
          id: this.recurringInvoice.id
        });
      }
    }

    this.updateDueDate();
  }), _dec6 = (0, _emberConcurrency.task)(function* (lineItems, item) {
    var equipment = yield item.get('equipment');
    var billableItem = this.invoiceBillableItems.findBy('equipment.id', equipment.get('id'));
    var hashedData = yield (0, _emberConcurrency.hash)({
      isNotTaxable: billableItem ? billableItem.isNotTaxableTask.perform(this.args.customer) : null,
      tax: billableItem ? billableItem.taxTask.perform(this.args.customer, this.taxRates) : null
    });
    var itemCharge = item.get('itemCharge');
    var grossAmount = itemCharge ? itemCharge * item.get('quantity') : billableItem ? billableItem.get('defaultUnitPrice') * item.get('quantity') : 0;
    var salesTaxAmount = hashedData.tax && hashedData.tax.tax_rate ? hashedData.tax.tax_rate / 100 * grossAmount : 0;
    var unitPrice = itemCharge ? itemCharge : billableItem ? billableItem.get('defaultUnitPrice') : 0;
    var comboId = item.attributes ? item.attributes.combo_id : null;

    if (billableItem) {
      var uniqueItem = true;
      lineItems.every(listedItem => {
        if (listedItem.invoiceBillableItem == billableItem && listedItem.unitPrice == unitPrice && listedItem.comboId == comboId) {
          listedItem.quantity = Number(listedItem.quantity) + 1;
          listedItem.grossAmount = Number(unitPrice) * listedItem.quantity;
          listedItem.salesTaxAmount = Number(listedItem.salesTaxRate) / 100 * listedItem.grossAmount;
          listedItem.totalAmount = listedItem.salesTaxAmount + listedItem.grossAmount;
          uniqueItem = false;
          return false;
        }

        return true;
      });

      if (uniqueItem) {
        lineItems.push(Ember.Object.create({
          quantity: item.get('quantity'),
          invoiceBillableItem: billableItem,
          description: billableItem.get('description'),
          unitPrice,
          grossAmount,
          salesTaxRate: hashedData.tax && hashedData.tax.tax_rate ? hashedData.tax.tax_rate : 0.00,
          salesTaxAmount: salesTaxAmount,
          totalAmount: grossAmount + salesTaxAmount,
          isNotTaxable: hashedData.isNotTaxable,
          comboId
        }));
      }
    }
  }), _dec7 = Ember._action, (_class = (_temp = class InvoiceDetails extends _base.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "sessionData", _descriptor2, this);

      _initializerDefineProperty(this, "stAjax", _descriptor3, this);

      _initializerDefineProperty(this, "recurringInvoice", _descriptor4, this);

      _initializerDefineProperty(this, "initFormData", _descriptor5, this);

      _initializerDefineProperty(this, "populateLineItemTask", _descriptor6, this);

      this.initFormData.perform();
    }

    get paymentDueInDays() {
      var dueDate = (0, _moment.default)(this.args.multiStepForm.formCopiedModelsGetVal('invoice.model.dueDate'), 'MM/DD/YYYY').endOf();
      var currentMoment = (0, _moment.default)().format('MM/DD/YYYY');
      return dueDate.diff((0, _moment.default)(currentMoment, 'MM/DD/YYYY').startOf(), 'days');
    }

    get paymentDueValue() {
      var paymentDueInDays = this.paymentDueInDays;
      var futurePast = paymentDueInDays < 0 ? 'Past' : 'Future';
      var daysValue = Math.abs(paymentDueInDays).toString();
      var translationKey = 'pages.customers.accounting.invoices.paymentDue' + futurePast + (daysValue === '1' ? 'Singular' : 'Plural');
      return this.intl.t(translationKey, {
        days: daysValue,
        dueDate: (0, _moment.default)(this.args.multiStepForm.formCopiedModelsGetVal('invoice.model.dueDate')).format('MM/DD/YYYY'),
        isPlural: daysValue === '1'
      });
    }

    updateDueDate() {
      var dueDate = (0, _moment.default)(this.args.multiStepForm.formCopiedModelsGetVal('invoice.model.saleDate'), 'MM/DD/YYYY').add(this.args.multiStepForm.formCopiedModelsGetVal('invoice.model.invoiceTerm.days'), 'days').format('MM/DD/YYYY');
      Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.dueDate', dueDate);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stAjax", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "recurringInvoice", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "initFormData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "populateLineItemTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateDueDate", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "updateDueDate"), _class.prototype)), _class));
  _exports.default = InvoiceDetails;
});