define("securitytrax-ember-ui/components/modals/sub-route-modals/manage-affiliated-monitoring/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Component.extend({
    formStarted: false,
    formMode: 'edit',
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    centralStationService: Ember.inject.service(),
    chatFunctionalityEnabled: false,
    eventHistoryFunctionalityEnabled: false,

    init() {
      this._super(...arguments);

      this.set('formMode', this.get('customerSystemInformation.centralStationId') ? 'edit' : 'create');
      this.set('formStarted', true);
    },

    title: Ember.computed('customer.{id,flname}', 'formMode', function () {
      var titleData = {
        monitorTypeName: 'Affiliated Monitoring',
        customerName: this.get('customer.flname'),
        id: this.get('customer.id')
      };
      return this.formMode == 'create' ? this.intl.t('pages.customers.system.order.generic_create_title', titleData) : this.intl.t('pages.customers.system.order.title', titleData);
    }),
    formOriginalModel: Ember.computed('grants.userGrants', 'customer', function () {
      var grants = this.grants;
      return Ember.Object.create({
        customer: {
          model: this.customer,
          copyRelationMap: {
            customerSystemInformation: {
              doCopy: grants.getIndividualGrant('customer_system_information.view'),
              doValidate: grants.getIndividualGrant('customer_system_information.view'),
              doSave: grants.getIndividualGrant('customer_system_information.modify')
            },
            customerEquipment: {
              readOnly: true,
              relationships: {
                equipmentStatus: {
                  readOnly: true
                }
              }
            }
          }
        }
      });
    }),
    formSteps: Ember.computed('title', function () {
      var intl = this.intl;
      return Ember.Object.create({
        general: Ember.Object.create({
          stepNameKey: 'general',
          title: this.title,
          stepComponentPath: 'multi-step-form/forms/system/affiliated-monitoring/create-edit-account',
          progressBarTitle: intl.t('pages.customers.system.order.general')
        }),
        tests: Ember.Object.create({
          stepNameKey: 'tests',
          title: this.title,
          stepComponentPath: 'multi-step-form/forms/system/affiliated-monitoring/tests',
          progressBarTitle: intl.t('pages.customers.system.order.tests')
        }),
        history: Ember.Object.create({
          stepNameKey: 'history',
          title: this.title,
          stepComponentPath: 'multi-step-form/forms/system/affiliated-monitoring/history',
          progressBarTitle: intl.t('pages.customers.system.order.history')
        })
      });
    }),
    actions: {
      transitionBackToParentRoute() {
        this.router.transitionTo(this.parentRoute, this.transitionId);
      }

    }
  });
  var _default = Controller;
  _exports.default = _default;
});