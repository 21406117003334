define("securitytrax-ember-ui/components/multi-step-form/forms/customer/upload-file/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    stNotificationManager: Ember.inject.service(),
    intl: Ember.inject.service(),
    stAjax: Ember.inject.service(),
    store: Ember.inject.service(),
    fileExtensionRegex: /\.[^/.]+$/,

    get modelToSave() {
      return this.customer.get('recordType');
    },

    get genericFiles() {
      return "".concat(this.modelToSave, "Files");
    },

    get apiEndpoint() {
      return this.stAjax.apiUrl("/".concat(this.modelToSave, "_files"));
    },

    // PERF TODO: Any way to post multiple in one call or something for when we updload multiple at a time?
    fileUploadedTask: (0, _emberConcurrency.task)(function* (file) {
      var response = JSON.parse(file.xhr.responseText);
      var newFile = {
        id: response.data.id,
        name: response.data.attributes.file.replace(this.fileExtensionRegex, ''),
        file: response.data.attributes.file
      };
      newFile[this.modelToSave] = this.get('customer');

      try {
        yield (0, _emberConcurrency.hash)({
          createRecord: this.store.createRecord("".concat(this.modelToSave, "File"), newFile),
          findRecord: this.store.findRecord("".concat(this.modelToSave, "File"), newFile.id),
          // Ensures the record shown is "clean" for patch and delete
          reloadFilesRelation: this.get("customer.".concat(this.genericFiles)).reload()
        });
        this.stNotificationManager.addSuccess(this.intl.t('common.notifications.generic_success', {
          'action': 'Upload'
        }));
        this.transitionBackToParent();
      } catch (err) {
        (false && Ember.warn('failed in upload save' + err, {
          id: 'files.fileUploadedTask'
        }));
        this.stNotificationManager.addErrorMessages(err, 'uploadFileModal');
        throw err;
      }
    }),
    actions: {
      additionalFields(file, xhr, formData) {
        formData.append('file', file.name);
        formData.append('name', file.name.replace(this.fileExtensionRegex, ''));
        formData.append('object_id', this.customer.id);
      }

    }
  });

  _exports.default = _default;
});