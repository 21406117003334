define("securitytrax-ember-ui/components/multi-step-form/forms/administration/content-management/funding/edit-funder/component", ["exports", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "ember-concurrency"], function (_exports, _component, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EditFunder = (_dec = Ember.inject.service('intl'), _dec2 = Ember.inject.service('stNotificationManager'), _dec3 = Ember.inject.service('store'), _dec4 = Ember.inject.service('grants'), _dec5 = Ember._tracked, _dec6 = (0, _emberConcurrency.task)(function* () {
    if (this.SwitchableFormStep.isNewRecord) {
      var multiStepForm = this.args.multiStepForm;
      yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);
      multiStepForm.formCopiedModelsSetVal('funder.model.active', true);
    }
  }), _dec7 = Ember._action, _dec8 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    if (this.SwitchableFormStep.isNewRecord) {
      var toCreateObjects = yield multiStepForm.validateCreateAll({
        notificationNamespace: 'modal'
      });
      var funder = this.store.createRecord('funder', toCreateObjects.funder.data);
      yield toCreateObjects.funder.saveWithRollbackAndNotification(funder, 'modal');
    } else {
      yield multiStepForm.validateSyncSaveAll({
        notificationNamespace: 'modal'
      });
    }

    buttonPromise.then(() => {
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
      multiStepForm.formStartedSetVal(false);
    });
  }), _dec9 = (0, _emberConcurrency.task)(function* () {
    try {
      var contactToDelete = this.args.multiStepForm.formOriginalModelsGetVal('funder.model');
      yield contactToDelete.destroyRecordWithRollbackTask.perform();
      this.args.multiStepForm.onDelete();
      this.args.multiStepForm.formStartedSetVal(false);
      this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'confirmDeleteModal'
      });
      throw err;
    }
  }), (_class = (_temp = class EditFunder extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "grants", _descriptor4, this);

      _initializerDefineProperty(this, "confirmDeleteModalOpen", _descriptor5, this);

      _initializerDefineProperty(this, "initFormData", _descriptor6, this);

      _initializerDefineProperty(this, "saveFunder", _descriptor7, this);

      _initializerDefineProperty(this, "deleteFunder", _descriptor8, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.initFormData.perform();
    }

    renderButtons() {
      var multiStepForm = this.args.multiStepForm;
      var buttons = [];

      if (this.args.formMode !== 'view') {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveFunder.perform(multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary funder-save-button'
        });
      }

      if (!this.SwitchableFormStep.isNewRecord && this.grants.getIndividualGrant('funders.delete')) {
        buttons.push({
          text: this.intl.t('common.actions.delete'),
          click: () => {
            this.confirmDeleteModalOpen = true;
          },
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
        });
      }

      multiStepForm.registerStepData('step1', 'buttons', buttons);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "confirmDeleteModalOpen", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "initFormData", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "renderButtons", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "renderButtons"), _class.prototype), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "saveFunder", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "deleteFunder", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = EditFunder;
});