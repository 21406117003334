define("securitytrax-ember-ui/models/base_models/report-object-mass-edit-column", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    about: (0, _model.attr)('string', {
      'readOnly': true
    }),
    v3DataType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['boolean', 'date', 'raw-value', 'select', 'multi-select', 'multi-part']
    }),
    status: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['active', 'deleted']
    }),
    reportObject: (0, _model.belongsTo)('report-object', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});