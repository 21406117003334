define("securitytrax-ember-ui/components/multi-step-form/forms/system/adt/shared/equipment-data/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/utils/obj-helpers", "securitytrax-ember-ui/mixins/equipment-sold-by-options"], function (_exports, _emberConcurrency, _objHelpers, _equipmentSoldByOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_equipmentSoldByOptions.default, {
    centralStationService: Ember.inject.service(),
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    _associatedAdtProductCodesArray: undefined,
    customerEquipmentFormStarted: false,
    customerEquipmentFirstStep: undefined,
    customerEquipmentFormMode: undefined,
    customerEquipmentEditTitle: undefined,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        customerEquipmentFirstStep: 'details',
        customerEquipmentFormMode: 'create',
        customerEquipmentEditTitle: ''
      });
    },

    customerEquipmentFormSteps: Ember.computed('customerEquipmentEditTitle', function () {
      var intl = this.intl;
      return Ember.Object.create({
        addEquipment: Ember.Object.create({
          stepNameKey: 'addEquipment',
          title: intl.t('pages.customers.equipment.title'),
          stepComponentPath: 'multi-step-form/forms/customer-equipment/add-equipment'
        }),
        details: Ember.Object.create({
          stepNameKey: 'details',
          title: this.customerEquipmentEditTitle,
          stepComponentPath: 'multi-step-form/forms/customer-equipment/details'
        })
      });
    }),
    customerEquipmentFormOriginalModels: Ember.computed('customerEquipmentModel', 'grants.userGrants', function () {
      var grants = this.grants;
      return Ember.Object.create({
        customerEquipment: {
          model: this.customerEquipmentModel,
          copyRelationMap: {
            equipment: {
              doCopy: grants.getIndividualGrant('customer_equipment.view'),
              doValidate: false,
              doSave: false
            },
            equipmentStatus: {
              doValidate: false,
              doSave: false
            },
            equipmentWireType: {
              doValidate: false,
              doSave: false
            },
            installCalendarEvent: {
              doValidate: false,
              doSave: false
            },
            uninstallCalendarEvent: {
              doValidate: false,
              doSave: false
            },
            installInventoryTechUser: {
              doCopy: true,
              doValidate: false,
              doSave: false
            },
            uninstallInventoryTechUser: {
              doCopy: true,
              doValidate: false,
              doSave: false
            },
            user: {
              doCopy: true,
              doValidate: false,
              doSave: false
            }
          }
        }
      });
    }),
    adtEquipment: Ember.computed('model.equipment', function () {
      return this.adtEquipmentTask.perform();
    }),
    adtEquipmentTask: (0, _emberConcurrency.task)(function* () {
      var filteredList = Ember.A([]);

      try {
        var grants = yield this.grants;
        var orderInfoGrant = yield grants.get('getIndividualGrantAsync').perform('customer_order_information.view');

        if (orderInfoGrant) {
          var hashData = yield (0, _emberConcurrency.hash)({
            equipment: this.get('model.equipment'),
            customerOrderInfo: this.store.findRecord('customerOrderInformation', this.get('customer.id'))
          });
          var monitoringCompany = yield hashData.customerOrderInfo.get('monitoringCompany');
          var equipmentProductCodes = yield this.centralStationService.getData({
            model: monitoringCompany,
            method: 'adt__getProductCodes',
            responseKey: 'product_codes'
          });
          var equipmentProductCodesAssociated = equipmentProductCodes.map(adtItem => {
            return adtItem.associated_equipment.map(equipment => {
              return equipment.id;
            });
          });
          var allProductCodesArray = [].concat.apply([], equipmentProductCodesAssociated);
          this.set('_associatedAdtProductCodesArray', allProductCodesArray);
          var allEquipment = hashData.equipment.toArray();

          for (var x = 0; x < allEquipment.get('length'); x++) {
            var currEquipment = allEquipment.objectAt(x);
            var equipmentId = yield currEquipment.get('id');

            if (allProductCodesArray.includes(+equipmentId)) {
              filteredList.pushObject(currEquipment);
            }
          }
        }
      } catch (error) {
        (false && Ember.warn('error getting filtered adt customer equipment list' + error, {
          id: 'multiStepForm.forms.system.adt.equipmentData'
        }));
      }

      return filteredList;
    }),
    filteredCustomerEquipment: Ember.computed('customer.customerEquipment.[].@each.itemCharge', function () {
      return this.filteredCustomerEquipmentTask.perform();
    }),
    filteredCustomerEquipmentTask: (0, _emberConcurrency.task)(function* () {
      var filteredList = Ember.A([]);

      try {
        var grants = yield this.grants;
        var orderInfoGrant = yield grants.get('getIndividualGrantAsync').perform('customer_order_information.view');

        if (orderInfoGrant) {
          var hashData = yield (0, _emberConcurrency.hash)({
            customerEquipment: this.get('customer.customerEquipment'),
            allAdtEquipment: this.adtEquipment
          });
          var allProductCodesArray = this._associatedAdtProductCodesArray;

          for (var x = 0; x < hashData.customerEquipment.get('length'); x++) {
            var currCustomerEquipment = hashData.customerEquipment.objectAt(x);
            var equipmentId = yield currCustomerEquipment.get('equipment.id');

            if (allProductCodesArray.includes(+equipmentId)) {
              filteredList.pushObject(currCustomerEquipment);
            }
          }
        }
      } catch (error) {
        (false && Ember.warn('error getting filtered adt customer equipment list' + error, {
          id: 'multiStepForm.forms.system.adt.equipmentData'
        }));
      }

      return filteredList;
    })
  });

  _exports.default = _default;
});