define("securitytrax-ember-ui/components/html/contextual-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rxeyMFOR",
    "block": "{\"symbols\":[\"dropdown\",\"menuItem\",\"@itemAction\",\"@disabledCssClass\",\"@additionalClickClass\",\"@asyncButtonColor\",\"@toggleAdditionalActionsOpen\",\"@menuItems\"],\"statements\":[[8,\"basic-dropdown\",[],[[\"@calculatePosition\",\"@verticalPosition\",\"@horizontalPosition\",\"@renderInPlace\"],[[32,0,[\"calculatePosition\"]],\"below\",\"auto-right\",true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"Trigger\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[15,0,[30,[36,4],[\"additional-click-actions-open-button \",[32,5],[32,4]],null]],[15,5,[32,6]],[15,\"onclick\",[32,7]],[12],[2,\"\\n            \"],[1,[30,[36,5],[\"icn-chevron-down\"],null]],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,[32,1,[\"Content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[14,0,\"contextual-menu\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[32,8]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[11,\"div\"],[16,0,[31,[\"contextual-menu-item  \",[32,2,[\"classNamesString\"]]]]],[4,[38,1],[[32,0],[30,[36,3],[[30,[36,1],[[32,0],[32,3],[32,2,[\"action\"]],[35,2]],null],[30,[36,1],[[32,0],[32,1,[\"actions\",\"toggle\"]]],null],[30,[36,1],[[32,0],[30,[36,0],[[32,0,[\"menuIsOpen\"]]],null],false],null]],null]],null],[12],[2,\"\\n                    \"],[1,[32,2,[\"label\"]]],[2,\"\\n                \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"mut\",\"action\",\"args\",\"queue\",\"concat\",\"svg-icon\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/contextual-menu/template.hbs"
    }
  });

  _exports.default = _default;
});