define("securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-timeoff/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z5aSLW3u",
    "block": "{\"symbols\":[\"multiStepForm\",\"MultiStepFormComponent\",\"@model\"],\"statements\":[[8,\"html/st-await\",[],[[\"@useSpinner\",\"@booleanLoaded\"],[false,[34,4,[\"isIdle\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"multi-step-form\",[],[[\"@formSteps\",\"@formOriginalModels\",\"@usesModal\",\"@formStarted\",\"@changeFormStarted\",\"@onAfterSave\"],[[34,5],[34,6],true,[34,7],[30,[36,9],[[32,0],[30,[36,8],[[35,7]],null]],null],[30,[36,9],[[32,0],\"updateAppointmentsList\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"modals/st-paper\",[],[[\"@modalButtons\",\"@title\",\"@classNames\",\"@dialogOpen\",\"@onClose\",\"@multiStepForm\"],[[32,1,[\"currentStepData\",\"buttons\"]],[32,1,[\"currentStepData\",\"title\"]],\"time-off st-modal--large\",[32,1,[\"formStarted\"]],[30,[36,9],[[32,0],\"transitionBackToParentRoute\"],null],[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,11],[[30,[36,10],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[8,[32,2],[[24,0,\"edit-user-time-off\"]],[[\"@multiStepForm\",\"@calendarEventTypes\",\"@user\",\"@calendarUser\",\"@formMode\"],[[32,1],[32,3,[\"calendarEventTypes\"]],[34,0],[34,1],[30,[36,3],[[30,[36,2],[\"calendar_events.modify\"],null],\"edit\",\"view\"],null]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"timeOffUser\",\"timeOffCalendarUser\",\"has-grants\",\"if\",\"initData\",\"timeOffFormSteps\",\"timeOffFormOriginalModels\",\"formStarted\",\"mut\",\"action\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-timeoff/template.hbs"
    }
  });

  _exports.default = _default;
});