define("securitytrax-ember-ui/components/pages/administration/company-settings/content-management/appointment-subtypes/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AppointmentSubtypes extends _component.default {}

  _exports.default = AppointmentSubtypes;
});