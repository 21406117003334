define("securitytrax-ember-ui/components/multi-step-form/forms/customer-equipment/add-equipment/new-line-items/component", ["exports", "securitytrax-ember-ui/mixins/list/row-list"], function (_exports, _rowList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_rowList.default, {
    screen: Ember.inject.service(),
    equipmentItemDeleteId: false,

    equipmentMatcher(equipment, term) {
      return "".concat(equipment.name, " ").concat(equipment.description, " ").concat(equipment.model, " ").concat(equipment.sku, " ").concat(equipment.manufacturer).toLowerCase().indexOf(term);
    },

    init() {
      this._super(...arguments);

      this.set('listArray', this.newLineItems);
    },

    actions: {
      equipmentOnChange(index, value) {
        var lineItem = this.listArray[index];
        lineItem.set('equipment', value);
        lineItem.set('itemCharge', value ? value.get('value') : 0);
        lineItem.set('itemAmount', lineItem.get('quantity') * lineItem.get('itemCharge'));
        this.updateRow(lineItem, index);
        this.listArray[index + 1].set('quantity', 1);
      },

      lineItemInputOnChange(row, otherAttr, event) {
        row.set('itemAmount', row.get(otherAttr) * Number(event.target.value.replace('$', '')));
      },

      lineItemDelete(index) {
        this.listArray.removeObject(this.listArray[index]);
      }

    }
  });

  _exports.default = _default;
});