define("securitytrax-ember-ui/components/org-chart/st-leaf/parent-leaf/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fa4rhsXA",
    "block": "{\"symbols\":[],\"statements\":[[8,\"html/st-list-item-image\",[],[[\"@thumbnail\",\"@content\",\"@showShortTitle\"],[[34,0,[\"avatar\"]],[30,[36,2],[[35,1],[35,0,[\"name\"]],[35,0,[\"flname\"]]],null],[34,1]]],null]],\"hasEval\":false,\"upvars\":[\"user\",\"isCompany\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/org-chart/st-leaf/parent-leaf/template.hbs"
    }
  });

  _exports.default = _default;
});