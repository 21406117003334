define("securitytrax-ember-ui/components/pages/administration/company-settings/integrations/consumer-financing/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var ConsumerFinancingLenders = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember.computed, _dec15 = (0, _emberConcurrency.task)(function* () {
    var newFilters = yield this.stFiltersService.getFiltersByGroupKey('companyFinancingLenders');
    var dryRunReasons = yield this.store.query('consumerFinancingLender', {
      filter: newFilters,
      limit: this.limit,
      page: this.page,
      include: 'consumer_financing_lender_type',
      count: 1
    });
    Ember.set(this, 'totalCount', dryRunReasons.meta.record_count);
    return dryRunReasons;
  }), _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, (_class = (_temp = class ConsumerFinancingLenders extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "grants", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor4, this);

      _initializerDefineProperty(this, "stFiltersService", _descriptor5, this);

      _initializerDefineProperty(this, "lender", _descriptor6, this);

      _initializerDefineProperty(this, "lenderFormStarted", _descriptor7, this);

      _initializerDefineProperty(this, "lenderFormMode", _descriptor8, this);

      _initializerDefineProperty(this, "modalTitle", _descriptor9, this);

      _initializerDefineProperty(this, "limit", _descriptor10, this);

      _initializerDefineProperty(this, "page", _descriptor11, this);

      _initializerDefineProperty(this, "totalCount", _descriptor12, this);

      _initializerDefineProperty(this, "financingLenderStatus", _descriptor13, this);

      _initializerDefineProperty(this, "getFinancingLendersTask", _descriptor14, this);
    }

    get financingLenders() {
      return this.getFinancingLendersTask.perform();
    }

    get lenderFullScreen() {
      return this.lenderFormMode === 'edit' && this.grants.getIndividualGrant('consumer_financing_lender_configs.manage');
    }

    get lenderFormSteps() {
      var hasGrant = this.grants.getIndividualGrant('consumer_financing_lender_configs.manage');
      var modalTitle = this.lenderFormMode === 'create' ? this.intl.t("pages.customers.accounting.financing.title_create") : this.intl.t('pages.customers.accounting.financing.title_edit', {
        name: this.modalTitle.name,
        id: this.modalTitle.id
      });
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: modalTitle,
          stepComponentPath: 'multi-step-form/forms/administration/integrations/consumer-financing-lenders/edit-create',
          progressBarTitle: this.intl.t("pages.customers.accounting.financing.general")
        }),
        integration: Ember.Object.create({
          stepNameKey: 'integration',
          title: modalTitle,
          stepComponentPath: 'multi-step-form/forms/administration/integrations/consumer-financing-lenders/integration',
          progressBarTitle: this.intl.t("pages.customers.accounting.financing.integration"),
          granted: hasGrant
        })
      });
    }

    get lenderFormOriginalModels() {
      return Ember.Object.create({
        consumerFinancingLender: {
          model: this.lender,
          copyRelationMap: {
            consumerFinancingLenderType: {
              readOnly: true
            },
            consumerFinancingLenderConfig: {
              doCopy: this.grants.getIndividualGrant('consumer_financing_lender_configs.manage'),
              doValidate: this.grants.getIndividualGrant('consumer_financing_lender_configs.manage'),
              doSave: this.grants.getIndividualGrant('consumer_financing_lender_configs.manage')
            }
          }
        }
      });
    }

    editAction(row) {
      this.lender = row;
      this.modalTitle.name = row.get('name');
      this.modalTitle.id = row.get('id');
      this.lenderFormMode = 'edit';
      this.lenderFormStarted = true;
    }

    updateProperty(options) {
      if (options.isCreate) {
        this.lender = options.saved;
        this.modalTitle = {
          name: this.lender.get('name'),
          id: this.lender.get('id')
        };
        this.lenderFormMode = 'edit';
      }

      Ember.notifyPropertyChange(this, 'financingLenders');
    }

    clearFilters() {
      Ember.setProperties(this, {
        page: 1,
        financingLenderStatus: undefined
      });
      Ember.notifyPropertyChange(this, 'financingLenders');
    }

    filterSearch(setPage = true) {
      if (setPage) {
        Ember.set(this, 'page', 1);
      }

      Ember.notifyPropertyChange(this, 'financingLenders');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "stFiltersService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "lender", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "lenderFormStarted", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "lenderFormMode", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'edit';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "modalTitle", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.Object.create({
        name: null,
        id: null
      });
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "limit", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 50;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "totalCount", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "financingLenderStatus", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "financingLenders", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "financingLenders"), _class.prototype), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "getFinancingLendersTask", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "editAction", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "editAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateProperty", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "updateProperty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilters", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterSearch", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "filterSearch"), _class.prototype)), _class));
  _exports.default = ConsumerFinancingLenders;
});