define("securitytrax-ember-ui/mixins/grouped-equipment-fast-mixin", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixed-in-functions/grouped-property-sections", "lodash"], function (_exports, _emberConcurrency, _groupedPropertySections, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionData: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.initMixinDataTask.perform();
    },

    initMixinDataTask: (0, _emberConcurrency.task)(function* () {
      var hashData = yield (0, _emberConcurrency.hash)({
        equipmentTypes: this.store.findAll('equipmentType', {
          sort: 'name'
        }),
        equipmentSubTypes: this.store.findAll('equipmentSubType')
      });
      var equipmentTypesById = hashData.equipmentTypes.reduce((accumulator, currentValue) => {
        accumulator[currentValue.id] = currentValue;
        return accumulator;
      }, {});
      Ember.set(this, 'equipmentTypesById', equipmentTypesById);
      var equipmentSubTypesById = hashData.equipmentSubTypes.reduce((accumulator, currentValue) => {
        accumulator[currentValue.id] = currentValue;
        return accumulator;
      }, {});
      Ember.set(this, 'equipmentSubTypesById', equipmentSubTypesById);
      this.set('mixinEquipmentTypes', hashData.equipmentTypes);
      hashData.equipmentTypes.forEach((type, index) => {
        type.set('sort', index++);
      });
    }),
    groupedItemsCollapsed: Ember.computed('items.[]', function () {
      return this.groupedItemsTask.perform(this.items, true);
    }),
    groupedItems: Ember.computed('items.[]', function () {
      return this.groupedItemsTask.perform(this.items);
    }),
    groupedItemsTask: (0, _emberConcurrency.task)(function* (items, collapsed = false, sort = 'equipment.name') {
      yield (0, _emberConcurrency.waitForProperty)(this.initMixinDataTask, 'isIdle', true);
      var yieldedItems = yield items;

      try {
        var resolvedItems = yieldedItems.sortBy(sort);

        if (resolvedItems) {
          var childTasks = [];

          if (resolvedItems) {
            resolvedItems.map(item => {
              childTasks.push(this.mapItemsTask.perform(item));
            });
          }

          var allLineItems = yield (0, _emberConcurrency.all)(childTasks);
          var allLineItemsFilteredSorted = allLineItems.uniqBy('type.id').sortBy('type.sort').map(({
            type
          }) => {
            var items = allLineItems.filterBy('type.id', type.id).mapBy('equipment');
            return Ember.Object.create({
              name: type.get('name'),
              icon: type.get('icon'),
              color: "background-color: ".concat(type.get('color')),
              sections: (0, _groupedPropertySections.default)(),
              items: items,
              itemsById: _lodash.default.keyBy(items, 'id'),
              collapsable: true,
              collapsed: collapsed,
              collapseIconName: 'icn-chevron-down'
            });
          });
          return allLineItemsFilteredSorted;
        } else {
          throw new Error('You must have items set for grouped equipment');
        }
      } catch (error) {
        (false && Ember.warn('groupedItemsTask ' + error, {
          id: 'mixins.grouped-equipment.groupedItemsTask'
        }));
      }
    }),
    mapItemsTask: (0, _emberConcurrency.task)(function* (item) {
      try {
        var equipment = yield item;
        var equipmentType = equipment ? this.equipmentTypesById[equipment.belongsTo('equipmentType').id()] : null;
        var equipmentTypeOther = Ember.Object.create({
          id: 0,
          sort: this.mixinEquipmentTypes.length,
          name: this.intl.t('pages.customers.equipment.columns.other'),
          icon: 'icn-folder-open',
          color: '#22a1c1'
        });
        return {
          equipment: item,
          type: equipmentType ? equipmentType : equipmentTypeOther
        };
      } catch (error) {
        (false && Ember.warn('mapItemsTask ' + error, {
          id: 'mixins.grouped-equipment.mapItemsTask'
        }));
      }
    })
  });

  _exports.default = _default;
});