define("securitytrax-ember-ui/mixins/checkbox-list-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Adds ability for different list funcationality
   *
   * @mixin ListMixin
   * @extends Ember.Mixin
   */
  var _default = Ember.Mixin.create({
    checkAll(list) {
      var itemList = Ember.A([]);
      list.forEach(item => {
        itemList.push(item);
        Ember.set(item, 'isChecked', true);
      });
      return itemList;
    },

    uncheckAll(list) {
      list.forEach(item => {
        Ember.set(item, 'isChecked', false);
      });
    }

  });

  _exports.default = _default;
});