define("securitytrax-ember-ui/components/multi-step-form/forms/system/telus/test-mode/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms/system/telus/base"], function (_exports, _emberConcurrency, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var TestMode = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = (0, _emberConcurrency.task)(function* () {
    var hashedData = yield (0, _emberConcurrency.hash)({
      installationTestMode: this.getInstallationTestModeTask.perform()
    });
    Ember.setProperties(this, hashedData);
  }), _dec7 = (0, _emberConcurrency.task)(function* () {
    try {
      var payload = this.installationTestMode.has_active_test_mode ? {
        'installation_number': this.args.installationInfo.number,
        'alarm_system_number': this.args.installationInfo.alarm_systems[0].number
      } : {
        'installation_number': this.args.installationInfo.number,
        'alarm_system_number': this.args.installationInfo.alarm_systems[0].number,
        'hours': this.durationHours,
        'minutes': this.durationMinutes,
        'activate_two_way_voice_test': false
      };
      yield this.centralStationService.postData({
        model: this.args.customer,
        rawResponse: false,
        method: this.installationTestMode.has_active_test_mode ? 'adt_canada__stopInstallationTestMode' : 'adt_canada__startInstallationTestMode',
        payload
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
    }

    this.installationTestMode = yield this.getInstallationTestModeTask.perform();
    Ember.notifyPropertyChange(this, 'installationTestMode');
    this.testModeFormStarted = false;
    this.durationHours = 0;
    this.durationMinutes = 0;
  }), _dec8 = (0, _emberConcurrency.task)(function* () {
    try {
      var testMode = yield this.centralStationService.postData({
        model: this.args.customer,
        rawResponse: false,
        method: 'adt_canada__getInstallationTestMode',
        payload: {
          'installation_number': this.args.installationInfo.number,
          'alarm_system_number': this.args.installationInfo.alarm_systems[0].number
        }
      });
      return testMode.installation_test_mode;
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), (_class = (_temp = class TestMode extends _base.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "centralStationService", _descriptor, this);

      _initializerDefineProperty(this, "testModeFormStarted", _descriptor2, this);

      _initializerDefineProperty(this, "installationTestMode", _descriptor3, this);

      _initializerDefineProperty(this, "durationHours", _descriptor4, this);

      _initializerDefineProperty(this, "durationMinutes", _descriptor5, this);

      _initializerDefineProperty(this, "initFormData", _descriptor6, this);

      _initializerDefineProperty(this, "updateOnTest", _descriptor7, this);

      _initializerDefineProperty(this, "getInstallationTestModeTask", _descriptor8, this);

      this.initFormData.perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "testModeFormStarted", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "installationTestMode", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "durationHours", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "durationMinutes", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "initFormData", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "updateOnTest", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "getInstallationTestModeTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = TestMode;
});