define("securitytrax-ember-ui/components/multi-step-form/forms/accounting/invoice-statements/invoice-statements-base", ["exports", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step"], function (_exports, _component, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class InvoiceStatementsBase extends _component.default {
    constructor() {
      super(...arguments);
      this.SwitchableFormStep = new _switchableFormStep.default(...arguments, {
        disabledProgressBarSteps: this.disabledProgressBarSteps,
        hiddenProgressBarSteps: this.hiddenProgressBarSteps
      });
      this.SwitchableFormStep.progressBarHidden = false;
    }

    get disabledProgressBarSteps() {
      if (this.args.formMode === 'create') {
        return ['send'];
      }

      return [];
    }

    get hiddenProgressBarSteps() {
      return [];
    }

  }

  _exports.default = InvoiceStatementsBase;
});