define("securitytrax-ember-ui/components/inventory/equipment-inventory/component", ["exports", "@glimmer/component", "ember-concurrency", "securitytrax-ember-ui/mixed-in-classes/grouped-equipment"], function (_exports, _component, _emberConcurrency, _groupedEquipment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EquipmentInventory = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = (0, _emberConcurrency.task)(function* () {
    var equipmentList = yield this.store.query('equipment', {
      limit: 0,
      include: 'equipment_type',
      filter: 'active=true'
    });

    if (this.args.user) {
      var inventoryList = yield this.args.user.invokeAction('get_equipment_inventory', {
        verb: 'GET'
      });
      var inventoryToDisplay = [];
      var equipmentItem = null;
      inventoryList.responsePayload.data.forEach(function (value) {
        equipmentItem = equipmentList.find(function (element) {
          if (element.id === value.equipment_id) {
            return element;
          }
        });
        var inventoryItem = Ember.Object.create({
          'equipment': equipmentItem,
          'id': value.equipment_id,
          'on_hand': value.on_hand_quantity,
          'on_order': value.on_order_quantity,
          'base': value.base_quantity
        });
        inventoryToDisplay.push(inventoryItem);
      });
      this.inventoryList = inventoryToDisplay;
      this.items = inventoryToDisplay.filter(item => {
        return item.on_hand > 0;
      });
      this.filterItemsTask.perform();
    }
  }), _dec11 = (0, _emberConcurrency.task)(function* () {
    var newFilters = yield this.stFiltersService.getFiltersByGroupKeyAsObject('myEquipmentInventory', ["selectedResource = 'onHand'"]);
    var inventoryList = this.inventoryList;

    if (newFilters['selectedResource'] && newFilters['selectedResource'] === 'onHand') {
      this.items = inventoryList.filter(item => {
        return item.on_hand > 0;
      });
    } else {
      this.items = inventoryList;
    }

    if (newFilters['filterValue']) {
      this.items = this.items.filter(el => el.get('equipment.name').toLowerCase().indexOf(newFilters['filterValue'].toLowerCase()) > -1);
    }

    this.groupedItems = yield this.GroupedEquipment.groupedItemsTask.perform(this.items);
  }), _dec12 = (0, _emberConcurrency.task)(function* () {
    Ember.setProperties(this, {
      selectedResource: 'onHand',
      filterValue: ''
    });
    this.items = this.inventoryList;
    yield this.filterItemsTask.perform();
  }), (_class = (_temp = class EquipmentInventory extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "stFiltersService", _descriptor3, this);

      _initializerDefineProperty(this, "filterValue", _descriptor4, this);

      _initializerDefineProperty(this, "inventoryList", _descriptor5, this);

      _initializerDefineProperty(this, "selectedResource", _descriptor6, this);

      _initializerDefineProperty(this, "items", _descriptor7, this);

      _initializerDefineProperty(this, "appliedFilters", _descriptor8, this);

      _initializerDefineProperty(this, "groupedItems", _descriptor9, this);

      _initializerDefineProperty(this, "inventoryListTask", _descriptor10, this);

      _initializerDefineProperty(this, "filterItemsTask", _descriptor11, this);

      _initializerDefineProperty(this, "clearFilter", _descriptor12, this);

      this.GroupedEquipment = new _groupedEquipment.default(...arguments);
      this.inventoryListTask.perform();
    }

    get totalOnHand() {
      var total = 0;

      if (this.items) {
        this.items.forEach(item => {
          total += Number(item.on_hand);
        });
      }

      return total;
    }

    get totalOnOrder() {
      var total = 0;

      if (this.items) {
        this.items.forEach(item => {
          total += Number(item.on_order);
        });
      }

      return total;
    }

    get resourceOptions() {
      return [{
        value: 'all',
        name: this.intl.t('pages.users.my_inventory.equipment_inventory.filters.all')
      }, {
        value: 'onHand',
        name: this.intl.t('pages.users.my_inventory.equipment_inventory.filters.on_hand')
      }];
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stFiltersService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "filterValue", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "inventoryList", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "selectedResource", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'onHand';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "items", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "appliedFilters", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "groupedItems", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "inventoryListTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "filterItemsTask", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "clearFilter", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = EquipmentInventory;
});