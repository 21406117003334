define("securitytrax-ember-ui/components/pages/all-at-risks/component", ["exports", "ember-concurrency", "lodash", "securitytrax-ember-ui/utils/obj-helpers", "securitytrax-ember-ui/mixins/table-column-sort", "securitytrax-ember-ui/classes/misc-input-list"], function (_exports, _emberConcurrency, _lodash, _objHelpers, _tableColumnSort, _miscInputList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableColumnSort.default, {
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    homeDashboard: Ember.inject.service('events/home-dashboard'),
    userService: Ember.inject.service(),
    stFiltersService: Ember.inject.service(),
    tagsService: Ember.inject.service(),
    screen: Ember.inject.service(),
    limit: 50,
    page: 1,
    createdDateFrom: undefined,
    createdDateTo: undefined,
    assignedUsers: undefined,
    userIsAssigned: undefined,
    isResolved: undefined,
    isClosed: undefined,
    miscFilters: undefined,
    showNotification: true,
    tagFilter: undefined,
    classNames: ['page-content'],

    init() {
      this._super(...arguments);

      this.homeDashboard.on('refresh-at-risk-entries', this, '_eventCallback');
      (0, _objHelpers.initializeState)(this, {
        miscFilters: new _miscInputList.default(),
        tagFilter: this.tagsService.tagFilter(),
        stSortableColumnOptions: [{
          name: this.intl.t('common.columns.id') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'id',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.id') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-id',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('common.columns.status') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'is_resolved',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.status') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-is_resolved',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('common.columns.created_date') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'created_at',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.created_date') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-created_at',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('common.columns.assignedTo') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'assigned_user',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.assignedTo') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-assigned_user',
          chevronDirection: 'down'
        }]
      });
      this.sortPaginatedList('created_at', false);

      try {
        this.miscFilters.addMiscInput();
      } catch (error) {
        this.set('miscFilters', new _miscInputList.default());
        this.miscFilters.addMiscInput();
      }

      this.initTask.perform();
    },

    willDestroy() {
      this.homeDashboard.off('refresh-at-risk-entries', this, '_eventCallback');
    },

    _eventCallback() {
      Ember.notifyPropertyChange(this, 'customerAtRisksData');
    },

    initTask: (0, _emberConcurrency.task)(function* () {
      Ember.set(this, 'tagsOptions', yield this.tagsService.tagsOptionsTask.perform('customer'));
    }),
    trueFalseOptions: Ember.computed(function () {
      return [{
        value: true,
        name: this.intl.t('common.confirm.true')
      }, {
        value: false,
        name: this.intl.t('common.confirm.false')
      }];
    }),
    assignedToUserOptions: Ember.computed(function () {
      return this.assignedToUserOptionsTask.perform();
    }),
    assignedToUserOptionsTask: (0, _emberConcurrency.task)(function* () {
      return yield this.userService.getUsersList('at_risk_assignee');
    }),
    miscFilterOperands: Ember.computed(function () {
      var intl = this.intl;
      return [{
        option: intl.t('common.filters.id'),
        value: 'id'
      }, {
        option: intl.t('common.filters.reason'),
        value: 'customer_at_risk_reason.name'
      }];
    }),
    miscFilterOperators: Ember.computed(function () {
      var intl = this.intl;
      return [{
        option: intl.t('common.filters.equals'),
        value: '='
      }, {
        option: intl.t('common.filters.starts_with'),
        value: '~%'
      }, {
        option: intl.t('common.filters.contains'),
        value: '%~%'
      }];
    }),
    customerAtRisksData: Ember.computed('stSortString', function () {
      return this.customerAtRisksDataTask.perform();
    }),
    customerAtRisksDataTask: (0, _emberConcurrency.task)(function* () {
      try {
        var newFilters = yield this.stFiltersService.getFiltersByGroupKey('allAtRisksListFilter');
        var customerAtRisks = yield this.store.query('customer-at-risk', {
          filter: newFilters,
          sort: this.stSortString ? this.stSortString : '-id',
          include: 'assigned_user,customer_at_risk_reason,customer,customer_at_risk_reason,customer_at_risk_reason_cause,created_by_user',
          limit: this.limit,
          page: this.page,
          count: 1
        });
        var promiseArray = [];
        customerAtRisks.forEach(atRisk => {
          promiseArray.push(this.lastContactTask.perform(atRisk));
        });
        yield (0, _emberConcurrency.all)(promiseArray);
        return {
          customerAtRisks,
          record_count: _lodash.default.get(customerAtRisks, 'meta.record_count', 0)
        };
      } catch (error) {// console.log('the fing error', error);
      }
    }),
    lastContactTask: (0, _emberConcurrency.task)(function* (atRisk) {
      var contactAttempts = yield atRisk.contactAttemptsTask.perform();
      atRisk.set('lastContactAttempt', contactAttempts && contactAttempts.length > 0 ? contactAttempts.objectAt(0).createdAt : null);
    }),
    actions: {
      transitionToSubroute(path) {
        this.router.transitionTo('all-at-risks.edit-at-risk', path);
      },

      updateCustomerAtRisks() {
        Ember.notifyPropertyChange(this, 'customerAtRisksData');
      },

      filterSearch() {
        this.set('page', 1);
        Ember.notifyPropertyChange(this, 'customerAtRisksData');
      },

      clearFilterSearch() {
        Ember.setProperties(this, {
          page: 1,
          createdDateFrom: undefined,
          createdDateTo: undefined,
          assignedUsers: undefined,
          userIsAssigned: undefined,
          isClosed: undefined,
          isResolved: undefined,
          atRiskEntryFormStarted: false,
          tagFilter: this.tagsService.tagFilter(),
          'miscFilters.miscInputs': [{
            operand: undefined,
            operator: undefined,
            value: undefined
          }]
        });
        Ember.notifyPropertyChange(this, 'customerAtRisksData');
        Ember.notifyPropertyChange(this, 'page');
      },

      sortList(column, ascending) {
        this.sortPaginatedList(column, ascending);
        Ember.notifyPropertyChange(this, 'customerAtRisksData');
      },

      mobileSortList(column) {
        this.sortMobilePaginatedList(column.value);
        Ember.notifyPropertyChange(this, 'customerAtRisksData');
      }

    }
  });

  _exports.default = _default;
});