define("securitytrax-ember-ui/validations/udf", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data) {
      var validateFieldsObject = {
        value: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('value')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'value');
            }
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateFieldsObject, {
        fullMessages: this.prependProperty(data)
      });
      var modelName = data.extraValidationData && data.extraValidationData.hasOwnProperty('overrideValidationKey') ? data.extraValidationData.overrideValidationKey : 'udf';
      return this.outputFormatting(validationData, modelName);
    }

  });

  _exports.default = _default;
});