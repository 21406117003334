define("securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/dealer-ally/applicants/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/base-components/applicants/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var DealerAllyCreateFinancingApplicants = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);

    if (!this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.consumerFinancingApplicant.id')) {
      var hashedData = yield (0, _emberConcurrency.hash)({
        customerSsn: this.args.customer.get('customerSsn'),
        customerBirthdate: this.args.customer.get('customerBirthdate'),
        waitForFinishedCopyModels: (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true)
      });
      this.args.multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.consumerFinancingApplicant', Ember.Object.create({
        customer: this.args.customer,
        fname: this.args.customer.get('fname'),
        lname: this.args.customer.get('lname'),
        address1: this.args.customer.get('address1'),
        city: this.args.customer.get('city'),
        state: this.args.customer.get('state'),
        zip: this.args.customer.get('zip'),
        email: this.args.customer.get('email'),
        homeOwnership: this.args.customer.get('homeOwnership'),
        cellPhone: this.args.customer.get('cellPhone'),
        ssn: this.grants.getIndividualGrant('customer_ssns.view') ? hashedData.customerSsn.get('ssn') : null,
        birthdate: this.grants.getIndividualGrant('customer_birthdates.view') ? hashedData.customerBirthdate.get('birthdate') : null,
        enumLists: this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.consumerFinancingApplicant.enumLists'),
        consumerFinancingApplicantsAdditionalIncomes: this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.consumerFinancingApplicant.consumerFinancingApplicantsAdditionalIncomes')
      }));
    }

    if (!this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.consumerFinancingCoapplicant.id')) {
      if (this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.consumerFinancingCoapplicant')) {
        this.args.multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.consumerFinancingCoapplicant.customer', this.args.customer);
      } else {
        this.args.multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.consumerFinancingCoapplicant', Ember.Object.create({
          customer: this.args.customer
        }));
      }
    }
  }), _dec4 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    var additionalIncomeSaves = [];
    var applicant = multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.consumerFinancingApplicant');
    var coapplicant = multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.consumerFinancingCoapplicant');
    var formattedObjects = applicant.showOtherIncome ? this.applicantRowList.prepForValidate('consumerFinancingApplicantsAdditionalIncome', false, null, {}, null, 'applicant-0-consumerFinancingApplicantsAdditionalIncome') : [];
    var noValidate = ['applicant-consumerFinancingApplicant-verifySsn'];

    if (!applicant.expirationDatePresent || applicant.idType === 'Unavailable') {
      noValidate.push('applicant-consumerFinancingApplicant-idExpirationDate');
    }

    if (applicant.idType === 'Unavailable') {
      noValidate.push('applicant-consumerFinancingApplicant-idNumber');
    }

    if (applicant.idType !== 'PermanentResidencyCard') {
      noValidate.push('applicant-consumerFinancingApplicant-idIssueDate');
    }

    if (!applicant.issueStatePresent || applicant.idType === 'PermanentResidencyCard' || applicant.idType === 'Unavailable') {
      noValidate.push('applicant-consumerFinancingApplicant-idStateOn');
    }

    if (applicant.residenceType === 'OwnedFreeAndClear') {
      noValidate.push('applicant-consumerFinancingApplicant-residenceMonthlyPayment');
    }

    if (applicant.residenceType === 'Rent') {
      noValidate.push('applicant-consumerFinancingApplicant-homeValue');
    }

    if (applicant.employmentType !== 'Employed') {
      noValidate.push('applicant-consumerFinancingApplicant-employer');
    }

    if (applicant.employmentType === 'Unemployed' || applicant.employmentType === 'Retired') {
      noValidate.push('applicant-consumerFinancingApplicant-occupation');
      noValidate.push('applicant-consumerFinancingApplicant-grossMonthlyIncome');
    }

    formattedObjects.push(Ember.Object.create({
      modelName: 'consumerFinancingApplicant',
      model: applicant,
      extraValidationData: {
        noValidate,
        overrideValidationKey: 'applicant-consumerFinancingApplicant'
      },
      filePath: 'consumer-financing-applicant'
    }));

    if (this.hasCoapplicant) {
      formattedObjects = formattedObjects.concat(this.setupCoapplicantFormattedObjects(coapplicant, applicant));
    }

    if (!multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.consumerFinancingApplicant.id')) {
      var toCreateObjects = yield multiStepForm.validateCreateList.perform(formattedObjects, {
        notificationNamespace: 'modal'
      });
      toCreateObjects['consumerFinancingApplicant'][0].data.consumerFinancingApplicantsAdditionalIncomes = []; // Don't send them in on save, will be set later.

      var consumerFinancingApplicant = this.store.createRecord('consumerFinancingApplicant', toCreateObjects['consumerFinancingApplicant'][0].data);
      var savedApplicant = yield toCreateObjects['consumerFinancingApplicant'][0].saveWithRollbackAndNotification(consumerFinancingApplicant, 'modal');
      multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.consumerFinancingApplicant.id', savedApplicant.get('id'));
      var savedCoapplicant;

      if (this.hasCoapplicant) {
        toCreateObjects['consumerFinancingApplicant'][1].data.consumerFinancingApplicantsAdditionalIncomes = []; // Don't send them in on save, will be set later.

        var _consumerFinancingApplicant = this.store.createRecord('consumerFinancingApplicant', toCreateObjects['consumerFinancingApplicant'][1].data);

        savedCoapplicant = yield toCreateObjects['consumerFinancingApplicant'][1].saveWithRollbackAndNotification(_consumerFinancingApplicant, 'modal');
        multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.consumerFinancingCoapplicant.id', savedCoapplicant.get('id'));
      }

      if (toCreateObjects.consumerFinancingApplicantsAdditionalIncome) {
        toCreateObjects.consumerFinancingApplicantsAdditionalIncome.forEach(income => {
          income.data['consumerFinancingApplicant'] = income.data.isCoapplicant ? savedCoapplicant : savedApplicant;
          var newIncome = this.store.createRecord('consumerFinancingApplicantsAdditionalIncome', income.data);
          additionalIncomeSaves.push(income.saveWithRollbackAndNotification(newIncome, 'modal'));
        });
        yield (0, _emberConcurrency.all)(additionalIncomeSaves);
      } // Create dummy record so when we resetup copy models after saving the applicant it has an application and an applicant
      // This way if we go back to this step before finishing loanDetails step we don't run into issues with the application
      // This should be avoided, but is done in cases like this because we are saving the children before the parent and there can be multiple children of same type.


      var application = this.store.createRecord('consumerFinancingApplication', {
        consumerFinancingApplicant: savedApplicant,
        consumerFinancingCoapplicant: savedCoapplicant
      });
      multiStepForm.formOriginalModelsSetVal('consumerFinancingApplication.model', application);
      multiStepForm.setupCopyModels.perform();
    } else {
      yield multiStepForm.validateList.perform(formattedObjects, {
        notificationNamespace: 'modal'
      });
      this.applicantRowList.removeRow();

      if (this.hasCoapplicant) {
        this.coapplicantRowList.removeRow();
      }

      if (this.hasCoapplicant && !this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.consumerFinancingCoapplicant.id')) {
        var _formattedObjects = this.setupCoapplicantFormattedObjects(coapplicant, applicant);

        var _toCreateObjects = yield multiStepForm.createList(_formattedObjects, {
          notificationNamespace: 'modal'
        });

        _toCreateObjects['consumerFinancingApplicant'][0].data.consumerFinancingApplicantsAdditionalIncomes = []; // Don't send them in on save, will be set later.

        var _consumerFinancingApplicant2 = this.store.createRecord('consumerFinancingApplicant', _toCreateObjects['consumerFinancingApplicant'][0].data);

        var _savedCoapplicant = yield _toCreateObjects['consumerFinancingApplicant'][0].saveWithRollbackAndNotification(_consumerFinancingApplicant2, 'modal');

        multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.consumerFinancingCoapplicant', _savedCoapplicant);

        if (_toCreateObjects.consumerFinancingApplicantsAdditionalIncome) {
          _toCreateObjects.consumerFinancingApplicantsAdditionalIncome.forEach(income => {
            income.data['consumerFinancingApplicant'] = _savedCoapplicant;
            var newIncome = this.store.createRecord('consumerFinancingApplicantsAdditionalIncome', income.data);
            additionalIncomeSaves.push(income.saveWithRollbackAndNotification(newIncome, 'modal'));
          });

          yield (0, _emberConcurrency.all)(additionalIncomeSaves);
        }
      }

      yield multiStepForm.validateSyncSaveAll({
        notificationNamespace: 'modal',
        skipValidation: true,
        overrideOriginalModelsConfig: Ember.Object.create({
          consumerFinancingApplication: {
            doSave: false,
            copyRelationMap: {
              consumerFinancingApplicant: {
                doValidate: false,
                doSave: true,
                relationships: {
                  customer: {
                    doValidate: false,
                    doSave: false
                  },
                  consumerFinancingApplicantsAdditionalIncomes: {
                    doValidate: false,
                    doSave: true
                  }
                }
              },
              consumerFinancingLender: {
                doValidate: false,
                doSave: false,
                relationships: {
                  consumerFinancingLenderType: {
                    doValidate: false,
                    doSave: false
                  }
                }
              },
              consumerFinancingCoapplicant: {
                doValidate: false,
                doSave: true,
                relationships: {
                  consumerFinancingApplicantsAdditionalIncomes: {
                    doValidate: false,
                    doSave: true
                  }
                }
              }
            }
          }
        })
      });
    }

    buttonPromise.then(() => {
      multiStepForm.currentStepNameKeySetVal('loanDetails');
    });
  }), (_class = (_temp = class DealerAllyCreateFinancingApplicants extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "grants", _descriptor2, this);

      _initializerDefineProperty(this, "initFormData", _descriptor3, this);

      _initializerDefineProperty(this, "saveConsumerFinancingApplicants", _descriptor4, this);

      this.initFormData.perform();
    }

    setupCoapplicantFormattedObjects(coapplicant, applicant) {
      var formattedObjects = Ember.A();
      var coApplicantNoValidate = ['coapplicant-consumerFinancingApplicant-verifySsn'];

      if (!coapplicant.expirationDatePresent || coapplicant.idType === 'Unavailable') {
        coApplicantNoValidate.push('coapplicant-consumerFinancingApplicant-idExpirationDate');
      }

      if (coapplicant.idType === 'Unavailable') {
        coApplicantNoValidate.push('coapplicant-consumerFinancingApplicant-idNumber');
      }

      if (!coapplicant.issueStatePresent || coapplicant.idType === 'PermanentResidencyCard' || coapplicant.idType === 'Unavailable') {
        coApplicantNoValidate.push('coapplicant-consumerFinancingApplicant-idStateOn');
      }

      if (coapplicant.residenceType === 'OwnedFreeAndClear') {
        coApplicantNoValidate.push('coapplicant-consumerFinancingApplicant-residenceMonthlyPayment');
      }

      if (coapplicant.residenceType === 'Rent') {
        coApplicantNoValidate.push('coapplicant-consumerFinancingApplicant-homeValue');
      }

      if (coapplicant.employmentType !== 'Employed') {
        coApplicantNoValidate.push('coapplicant-consumerFinancingApplicant-employer');
      }

      if (coapplicant.employmentType === 'Unemployed' || coapplicant.employmentType === 'Retired') {
        coApplicantNoValidate.push('coapplicant-consumerFinancingApplicant-occupation');
        coApplicantNoValidate.push('coapplicant-consumerFinancingApplicant-grossMonthlyIncome');
      }

      if (coapplicant.copyPersonalInfoAddressFromApplicant) {
        Ember.setProperties(coapplicant, {
          address1: applicant.address1,
          address2: applicant.address2,
          city: applicant.city,
          state: applicant.state,
          zip: applicant.zip
        });
      }

      formattedObjects.push(Ember.Object.create({
        modelName: 'consumerFinancingApplicant',
        model: coapplicant,
        extraValidationData: {
          noValidate: coApplicantNoValidate,
          overrideValidationKey: 'coapplicant-consumerFinancingApplicant'
        },
        filePath: 'consumer-financing-applicant'
      }));
      return formattedObjects;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "initFormData", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "saveConsumerFinancingApplicants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = DealerAllyCreateFinancingApplicants;
});