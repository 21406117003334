define("securitytrax-ember-ui/components/nav/base-left-nav/component", ["exports", "securitytrax-ember-ui/utils/obj-helpers", "lodash"], function (_exports, _objHelpers, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    navGloballyDisabled: undefined,
    loadedNamespace: undefined,
    stAwaitDisableSources: undefined,
    screen: Ember.inject.service(),
    navigation: Ember.inject.service(),
    stAwait: Ember.inject.service(),
    mapLoading: Ember.inject.service('events/map-loading'),

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        buttonList: [],
        loadedNamespace: 'global',
        navGloballyDisabled: false,
        stAwaitDisableSources: {
          'save': true
        }
      }); /////////////////////////////////////////////////////////////////////////
      // see if we should disable the side nav because of fully loaded logic

      var stAwait = this.stAwait;
      var fullyLoadedState = stAwait.getCurrentFullyLoadedStateByNamespace({
        loadedNamespace: this.loadedNamespace
      });

      if (this && !this.isDestroyed) {
        this._setNavGloballyDisabled(fullyLoadedState);
      }

      stAwait.on('st-await-loaded-state-by-namespace-' + this.loadedNamespace, this, '_onLoadedStateChange');
    },

    willDestroyElement() {
      this._super(...arguments);

      this.stAwait.off('st-await-loaded-state-by-namespace-' + this.loadedNamespace, this, '_onLoadedStateChange');
    },

    _onLoadedStateChange(fullyLoadedState) {
      Ember.run.next(() => {
        if (this && !this.isDestroyed) {
          this._setNavGloballyDisabled(fullyLoadedState);
        }
      });
    },

    _setNavGloballyDisabled(fullyLoadedState) {
      var disableValue = !fullyLoadedState.fullyLoaded;
      var waitingSource = fullyLoadedState.waitingSource; // only disable if allowed to disable based on this buttons settings - always enable just in case

      if (disableValue === true && _lodash.default.get(this, 'stAwaitDisableSources.' + waitingSource, false)) {
        this.set('navGloballyDisabled', true);
      } else if (disableValue === false) {
        this.set('navGloballyDisabled', false);
      }
    },

    expand: Ember.computed('screen.isMobile', 'navigation.isLeftOpen', function () {
      return this.get('screen.isMobile') && this.get('navigation.isLeftOpen');
    })
  });

  _exports.default = _default;
});