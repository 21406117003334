define("securitytrax-ember-ui/components/html/contextual-menu/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var contextualMenu = (_dec = Ember._action, (_class = (_temp = class contextualMenu extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "menuIsOpen", false);
    }

    calculatePosition(trigger, content) {
      var parent = trigger.closest('.main-wrapper');
      var button = trigger.closest('.st-btn');

      if (trigger.closest('.st-modal')) {
        parent = trigger.closest('.st-modal');
      }

      var {
        top: parentTop,
        left: parentLeft,
        height: parentHeight
      } = parent.getBoundingClientRect();
      var {
        width: triggerWidth,
        height: triggerHeight
      } = trigger.getBoundingClientRect();
      var {
        top: contentTop,
        left: contentLeft,
        height: contentHeight,
        width: contentWidth
      } = content.getBoundingClientRect();
      var style = {
        left: triggerWidth - contentWidth,
        top: triggerHeight,
        height: contentHeight,
        width: contentWidth
      };

      if (triggerWidth - contentWidth + contentLeft < parentLeft) {
        var {
          width: buttonWidth
        } = button.getElementsByClassName('main-button')[0].getBoundingClientRect();
        style.left = -buttonWidth;
      }

      if (contentTop + contentHeight > parentTop + parentHeight) {
        style.top = -contentHeight;
      }

      return {
        style
      };
    }

    toggleMenu() {
      this.toggleProperty('menuIsOpen');
    }

  }, _temp), (_applyDecoratedDescriptor(_class.prototype, "toggleMenu", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "toggleMenu"), _class.prototype)), _class));
  _exports.default = contextualMenu;
});