define("securitytrax-ember-ui/components/multi-step-form/forms/administration/payables/add-levels/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/switchable-form-step"], function (_exports, _emberConcurrency, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    initData: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true);

      if (this.isNewRecord) {
        this.multiStepForm.formCopiedModelsSetVal('payrollRoleFunctionLevel.model.active', true);
      }
    }).on('init'),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;

      if (this.formMode !== 'view') {
        var stepName = multiStepForm.currentStepNameKey;
        multiStepForm.registerStepData(stepName, 'buttons', [{
          text: this.intl.t('common.actions.next'),
          click: buttonPromise => {
            return this.saveNewLevel.perform(buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary level-save-button'
        }]);
      }
    },

    disabledProgressBarSteps: Ember.computed(function () {
      return [];
    }),
    hiddenProgressBarSteps: Ember.computed(function () {
      return [];
    }),
    saveNewLevel: (0, _emberConcurrency.task)(function* (buttonPromise) {
      this.multiStepForm.formCopiedModelsSetVal('payrollRoleFunctionLevel.model.payrollRoleFunction', this.payrollRoleFunction);
      var validationObject = {
        notificationNamespace: 'modal',
        overrideOriginalModelsConfig: Ember.Object.create({
          payrollRoleFunctionLevel: {
            model: this.payrollRoleFunctionLevel,
            copyRelationMap: {
              payrollRoleFunction: {
                readOnly: true
              }
            }
          }
        })
      };
      var toCreateObjects = yield this.multiStepForm.validateCreateAll(validationObject);
      var newLevel = this.store.createRecord('payrollRoleFunctionLevel', toCreateObjects.payrollRoleFunctionLevel.data);
      var newlySavedLevel = yield toCreateObjects.payrollRoleFunctionLevel.saveWithRollbackAndNotification(newLevel, 'modal');
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccess(this.intl.t('common.notifications.save_successful'), 'modal');

        if (this.onAfterSave && typeof this.onAfterSave === 'function') {
          this.onAfterSave(newlySavedLevel, 'addLevel');
        }

        this.multiStepForm.currentStepNameKeySetVal('addLineItem');
        this.multiStepForm.progressBarConfig.activeIndex = this.multiStepForm.progressBarConfig.items.length - 1;
        Ember.set(this.multiStepForm.progressBarConfig.items.objectAt(this.multiStepForm.progressBarConfig.items.length - 2), 'isComplete', true);
        Ember.set(this.multiStepForm.progressBarConfig.items.objectAt(0), 'isComplete', true);
        this.payrollRoleFunction.reload();
        this.multiStepForm.setupCopyModels.perform();
      });
    })
  });

  _exports.default = _default;
});