define("securitytrax-ember-ui/components/multi-step-form/forms/work-orders/details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0v44YAPj",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,12],null,[[\"config\",\"modalButtons\"],[[35,11],[35,9,[\"currentStepData\",\"buttons\"]]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,10],null,[[\"multiStepForm\",\"formMode\",\"customerEquipment\",\"customer\",\"doneAction\",\"parentScopeSaveClicked\",\"users\",\"singleStep\",\"workOrder\",\"transitionBackToParent\",\"hideAddEvent\"],[[35,9],[35,8],[35,7],[35,6],[35,5],[35,4],[35,3],\"details\",[35,2],[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"hideAddEvent\",\"transitionBackToParent\",\"workOrder\",\"users\",\"parentScopeSaveClicked\",\"doneAction\",\"customer\",\"customerEquipment\",\"formMode\",\"multiStepForm\",\"customer/work-orders\",\"progressBarConfig\",\"multi-step-form/progress-bar\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/work-orders/details/template.hbs"
    }
  });

  _exports.default = _default;
});