define("securitytrax-ember-ui/validations/customer-ssn", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation", "lodash"], function (_exports, _validate, _baseValidation, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var adtValidate = _lodash.default.get(data, 'extraValidationData.newPreCheck', false);

      var noValidateSsn = data.extraValidationData.noValidate.includes('customerSsn');
      var validateObject = {
        ssn: {
          presence: () => {
            if (adtValidate && !noValidateSsn) {
              return this.customMessageOrDefault(data, 'ssn');
            } else {
              return false;
            }
          },
          format: () => {
            if (this.ssn) {
              return {
                pattern: /[0-9]\d{3,}/,
                message: '^' + data.intlService.t('common.validations.customerSsn')
              };
            } else {
              return false;
            }
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      return this.outputFormatting(validationData, 'customerSsn');
    }

  });

  _exports.default = _default;
});