define("securitytrax-ember-ui/components/multi-step-form/switchable-inputs/number-input/component", ["exports", "securitytrax-ember-ui/mixins/switchable-input", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _switchableInput, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableInput.default, {
    max: undefined,
    min: undefined,
    value: undefined,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        max: 100,
        min: 0,
        value: 0
      });
    },

    actions: {
      updateValue(toAdd) {
        if (this.min < this.value && toAdd === -1 || this.max > this.value && toAdd === 1 && !this.showView) {
          Ember.set(this, 'value', +this.value + toAdd);

          if (this.numberChanged) {
            this.numberChanged();
          }
        }
      }

    }
  });

  _exports.default = _default;
});