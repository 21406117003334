define("securitytrax-ember-ui/models/base_models/customer-funding", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    fundSubmittedDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    fundFundedDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    fundAmount: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    fundAmountHold: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    fundAmountBonus: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    fundAmountReceived: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    fundChargebackDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    fundGradeInfo: (0, _model.attr)('string', {
      'readOnly': false
    }),
    fundReinstateDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    fundReversalDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    fundDeferredDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    fundChargebackAmount: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    fundReversalAmount: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    fundDeferredAmount: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    customer: (0, _model.belongsTo)('customer', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    funder: (0, _model.belongsTo)('funder', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    funderReason: (0, _model.belongsTo)('funder-reason', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    fundingStatus: (0, _model.belongsTo)('funding-status', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});