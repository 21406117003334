define("securitytrax-ember-ui/demo/misc-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8Z4dBmuN",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],null,[[\"classNames\"],[\"paper-demo\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h1\"],[12],[2,\"Misc Input\"],[13],[2,\"\\n\\n    not really checking any grants here\\n\\n    \"],[10,\"div\"],[14,0,\"grid-x grid-padding-x\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cell small-12 medium-4 large-4\"],[12],[2,\"\\n            \"],[1,[30,[36,1],null,[[\"formMode\",\"label\",\"operands\",\"operators\",\"operandLabelProperty\",\"operandValueProperty\",\"operatorLabelProperty\",\"operatorValueProperty\"],[\"edit\",\"Misc. Field\",[35,0,[\"users\"]],[35,0,[\"customers\"]],\"flname\",\"id\",\"flname\",\"id\"]]]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"multi-step-form/switchable-inputs/misc-input\",\"pages/demo-page\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/demo/misc-input/template.hbs"
    }
  });

  _exports.default = _default;
});