define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/interactive-services-providers/adc/import/import-customers/import-customers-base", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step"], function (_exports, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    disabledProgressBarSteps: Ember.computed(function () {
      return [];
    }),
    hiddenProgressBarSteps: Ember.computed(function () {
      return [];
    }),
    progressBarConfig: Ember.computed('progressBarHidden', 'multiStepForm.{formSteps,currentStepNameKey}', 'hiddenProgressBarSteps', 'disabledProgressBarSteps', 'importCustomersFormSteps', function () {
      var items = [];
      var multiStepForm = this.multiStepForm;
      var formSteps = this.multiStepForm.formSteps;
      var currentStep = this.multiStepForm.currentStepNameKey;
      var foundCurrStep = false;
      Object.keys(formSteps).forEach(key => {
        var isHidden = this.hiddenProgressBarSteps.includes(key) || formSteps[key].granted != null && !formSteps[key].granted;
        var isComplete = formSteps[key].stepNameKey === currentStep ? false : true;

        if (foundCurrStep) {
          isComplete = false;
        }

        if (currentStep == formSteps[key].stepNameKey) {
          foundCurrStep = true;
        }

        items.push({
          title: formSteps[key].progressBarTitle,
          stepNameKey: formSteps[key].stepNameKey,
          isClickable: false,
          hidden: isHidden,
          isComplete: isComplete,

          clickAction(item) {
            multiStepForm.currentStepNameKeySetVal(item.stepNameKey);
          }

        });
      });
      return {
        hideProgressBar: this.progressBarHidden,
        activeIndex: items.findIndex(item => item.stepNameKey === currentStep),
        viewTemplate: 'default-view',
        items: items
      };
    })
  });

  _exports.default = _default;
});