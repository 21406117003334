define("securitytrax-ember-ui/components/multi-step-form/forms/customer-central-station-information/monitoring-companies/stages-monitoring-company-type/toolbox/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer-central-station-information/monitoring-companies/stages-monitoring-company-type/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Toolbox extends _component.default {}

  _exports.default = Toolbox;
});