define("securitytrax-ember-ui/models/monitoring-company-config", ["exports", "securitytrax-ember-ui/models/base_models/monitoring-company-config", "lodash"], function (_exports, _monitoringCompanyConfig, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _monitoringCompanyConfig.default.extend({
    lead_source_blacklist_as_object_keys: Ember.computed('leadSourceBlacklist.@each', function () {
      var lead_source_blacklist = _lodash.default.get(this, 'leadSourceBlacklist', []);

      var asObject = {};

      for (var i = 0; i < lead_source_blacklist.length; i++) {
        asObject[lead_source_blacklist[i]] = true;
      }

      return asObject;
    }),
    default_lead_source: Ember.computed('defaultLeadSource', function () {
      return _lodash.default.get(this, 'defaultLeadSource', '');
    }),
    equipment_panel_blacklist_as_object_keys: Ember.computed('equipmentPanelBlacklist.@each', function () {
      var equipment_panel_blacklist = _lodash.default.get(this, 'equipmentPanelBlacklist', []);

      var asObject = {};

      for (var i = 0; i < equipment_panel_blacklist.length; i++) {
        asObject[equipment_panel_blacklist[i]] = true;
      }

      return asObject;
    }),
    default_equipment_panel: Ember.computed('defaultEquipmentPanel', function () {
      return _lodash.default.get(this, 'defaultEquipmentPanel', '');
    })
  });

  _exports.default = _default;
});