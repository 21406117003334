define("securitytrax-ember-ui/components/session-inactive-dialog/component", ["exports", "securitytrax-ember-ui/utils/debug", "securitytrax-ember-ui/utils/interval"], function (_exports, _debug, _interval) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    sessionActivity: Ember.inject.service(),
    intl: Ember.inject.service(),
    dialogActive: false,
    timeRemaining: null,
    timeRemianingTimer: null,
    activityButtons: Ember.computed(function () {
      return [{
        classNamesString: 'st-btn warning-red',
        text: this.intl.t('common.nav.logout'),
        click: () => {
          var pathname = window.location.pathname;
          var url = pathname.split('/').slice(2).join('/');
          localStorage.setItem('storedURL', url);
          this.session.invalidate();
        }
      }, {
        classNamesString: 'st-btn st-btn-primary',
        text: this.intl.t('common.session.activity'),
        click: () => {
          this.set('dialogActive', false);
          this.sessionActivity.set('consentToTickle', true);
          this.sessionActivity.tickle();
        }
      }];
    }),
    dialogActiveStateChanged: Ember.observer('dialogActive', function () {
      if (this.dialogActive) {
        this.updateTimeRemaining();

        if (!(0, _debug.isTestEnvironment)()) {
          //cannot have recursive timer in test mode
          this.timeRemainingTimer.start();
        }

        this.sessionActivity.set('consentToTickle', false);
      } else {
        this.timeRemainingTimer.stop();
        this.sessionActivity.set('consentToTickle', true);
      }
    }),

    init() {
      this._super(...arguments);

      var session = this.session;
      var sessionActivity = this.sessionActivity;

      var timer = _interval.default.create();

      timer.setContext(this);
      timer.setFunction(this.updateTimeRemaining);
      this.set('timeRemainingTimer', timer);

      if (sessionActivity.get('inactivityWindow') > 0 && session.get('isAuthenticated') === true && sessionActivity.get('activityState') == 'inactive') {
        this.updateTimeRemaining();

        if (!(0, _debug.isTestEnvironment)()) {
          //cannot have recursive timer in test mode
          this.timeRemainingTimer.start();
        }

        this.set('dialogActive', true);
        this.sessionActivity.set('consentToTickle', false);
      }

      this.sessionActivity.on('userActive', () => {
        this.set('dialogActive', false);
      });
      this.sessionActivity.on('userInactive', () => {
        this.set('dialogActive', true);
      });
    },

    updateTimeRemaining: function () {
      var time = this.sessionActivity.get('logoutTime') - Math.floor(Date.now() / 1000);
      var minutes = Math.floor(time / 60);
      var seconds = time - minutes * 60;
      seconds = seconds < 10 ? '0' + seconds : seconds;
      this.set('timeRemaining', minutes + ':' + seconds);
    }
  });

  _exports.default = _default;
});