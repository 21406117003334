define("securitytrax-ember-ui/components/multi-step-form/forms/demo/demo-progress-bar/demo-progress-bar-base", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step"], function (_exports, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    progressBarConfig: Ember.computed('multiStepForm.{formSteps,progressBarTemplate}', function () {
      var items = [];
      var multiStepForm = this.multiStepForm;
      var formSteps = this.multiStepForm.formSteps;
      Object.keys(formSteps).forEach(function (key, index) {
        var clickable = index !== 2;
        var hidden = index === 2;
        items.push({
          title: formSteps[key].title,
          stepNameKey: formSteps[key].stepNameKey,
          isClickable: clickable,
          hidden: hidden,
          isComplete: false,

          clickAction(item, itemIndex) {
            multiStepForm.currentStepNameKeySetVal(item.stepNameKey);
            multiStepForm.progressBarConfig.activeIndex = itemIndex;
          }

        });
      });
      return {
        activeIndex: 0,
        viewTemplate: 'default-view',
        items: items
      };
    }),

    init() {
      this._super(...arguments);

      if (!this.multiStepForm.progressBarConfig) {
        this.multiStepForm.progressBarConfig = this.progressBarConfig;
      }

      var progerssBarViewTemplate = this.multiStepForm.progressBarTemplate; //get('multiStepForm.progressBarTemplate');

      progerssBarViewTemplate = progerssBarViewTemplate ? progerssBarViewTemplate : 'default-view';
      this.multiStepForm.progressBarConfig.viewTemplate = progerssBarViewTemplate;
    }

  });

  _exports.default = _default;
});