define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/customer-create-chooser/create-form-configs/smart-sales/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T5WV2wX2",
    "block": "{\"symbols\":[\"multiStepForm\",\"MultiStepFormComponent\",\"@workflowConfig\"],\"statements\":[[8,\"multi-step-form\",[],[[\"@formSteps\",\"@progressBarConfig\",\"@formOriginalModels\",\"@currentStepNameKey\",\"@changeCurrentStepNameKey\",\"@formStarted\",\"@changeFormStarted\",\"@usesModal\"],[[34,6],[34,7],[34,8],[34,9],[30,[36,11],[[32,0],[30,[36,10],[[35,9]],null]],null],[34,12],[30,[36,11],[[32,0],[30,[36,10],[[35,12]],null]],null],true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"modals/st-paper\",[],[[\"@modalButtons\",\"@title\",\"@classNames\",\"@dialogOpen\",\"@fullscreen\",\"@hasProgressBar\",\"@multiStepForm\",\"@loadedNamespace\",\"@onClose\"],[[32,1,[\"currentStepData\",\"buttons\"]],[32,1,[\"currentStepData\",\"title\"]],\"create-customer md-dialog standard\",[32,1,[\"formStarted\"]],true,true,[32,1],[34,13],[30,[36,11],[[32,0],[30,[36,10],[[35,1]],null],\"create\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,15],[[30,[36,14],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,[32,2],[[24,0,\"st-modal__body--progress-bar\"]],[[\"@multiStepForm\",\"@customer\",\"@formMode\",\"@transitionToCustomer\",\"@newCustomer\",\"@workOrder\",\"@editCalendarEvent\",\"@workflowConfig\",\"@isLS\"],[[32,1],[34,0,[\"customer\"]],[34,1],[34,2],[34,3],[34,4],[34,5],[32,3],true]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"customerCreateFormMode\",\"afterCreateCustomer\",\"customer\",\"workOrder\",\"editCalendarEvent\",\"createCustomerFormSteps\",\"createCustomerProgressBar\",\"formOriginalModels\",\"currentStepNameKey\",\"mut\",\"action\",\"createCustomerFormStarted\",\"loadedNamespace\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/customer-create-chooser/create-form-configs/smart-sales/template.hbs"
    }
  });

  _exports.default = _default;
});