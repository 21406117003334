define("securitytrax-ember-ui/components/html/st-toggle/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    value: true,
    onChange: '',
    disabled: false,
    classNameBindings: ['checkboxClassName', 'className'],
    checkboxClassName: 'st-toggle',
    className: '',
    actions: {
      toggle(event) {
        if (!this.disabled) {
          this.toggleProperty('checked');

          if (this.onChange) {
            this.onChange(this.checked, event);
          }

          return;
        }
      }

    }
  });

  _exports.default = _default;
});