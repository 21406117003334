define("securitytrax-ember-ui/customers/loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xOEQjC+E",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"spinner-container\"],[12],[2,\"\\n    \"],[1,[30,[36,0],null,[[\"spinning\",\"size\",\"class\"],[true,\"large\",\"customer-spinner\"]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"spinner-svg\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/customers/loading/template.hbs"
    }
  });

  _exports.default = _default;
});