define("securitytrax-ember-ui/components/html/st-carousel/component", ["exports", "@glimmer/component", "uuid"], function (_exports, _component, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CarouselComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = (_temp = class CarouselComponent extends _component.default {
    constructor() {
      var _this$args$slideDurat;

      super(...arguments);

      _initializerDefineProperty(this, "slideIndex", _descriptor, this);

      _initializerDefineProperty(this, "items", _descriptor2, this);

      _initializerDefineProperty(this, "canChangeSlide", _descriptor3, this);

      _initializerDefineProperty(this, "fillProgress", _descriptor4, this);

      _initializerDefineProperty(this, "finishedLoading", _descriptor5, this);

      _defineProperty(this, "itemClassMap", {});

      _defineProperty(this, "slideAnimationTimer", 1000);

      _defineProperty(this, "autoNavTimer", 10000);

      this.randomId = 'carousel-' + (0, _uuid.v4)();
      this.autoNavTimer = (_this$args$slideDurat = this.args.slideDuration) !== null && _this$args$slideDurat !== void 0 ? _this$args$slideDurat : 10000;
    }

    setCarouselClass(index, className) {
      if (this.itemClassMap[index].className) {
        this.itemClassMap[index].item.classList.remove(this.itemClassMap[index].className);
      }

      this.itemClassMap[index].item.classList.add(className);
      this.itemClassMap[index].item.style.display = 'unset';
      this.itemClassMap[index].className = className;
    }

    clampIndex(index, length) {
      if (index < 0) {
        index = length + index % length;
      } else if (index >= length) {
        index %= length;
      }

      return index;
    }

    setIntervalAutoNav() {
      if (this._autoNav) {
        clearInterval(this._autoNav);
      }

      this._autoNav = setInterval(() => {
        this.quickChangeSlide(this.slideIndex + 1);
      }, this.autoNavTimer);
    }

    changeSlide(index, dir) {
      index = this.clampIndex(index, this.items.length);

      if (index === this.slideIndex || !this.canChangeSlide) {
        return;
      }

      if (dir === 'next') {
        this.setCarouselClass(this.slideIndex, 'slide-out-left');
        this.setCarouselClass(index, 'slide-in-from-right');
      } else {
        this.setCarouselClass(this.slideIndex, 'slide-out-right');
        this.setCarouselClass(index, 'slide-in-from-left');
      }

      this.slideIndex = index;
      this.canChangeSlide = false;
      setTimeout(() => {
        this.canChangeSlide = true;
      }, this.slideAnimationTimer);
      this.setIntervalAutoNav();
    }

    quickChangeSlide(index) {
      if (index > this.slideIndex) {
        this.changeSlide(index, 'next');
      } else {
        this.changeSlide(index, 'prev');
      }
    }

    setupAction() {
      var carouselParent = document.getElementById(this.randomId);
      this.items = carouselParent.querySelectorAll('.carousel .carousel-item');

      if (this.items.length > 1) {
        for (var i = 0; i < this.items.length; i++) {
          var item = this.items[i];
          this.itemClassMap[i] = {
            item,
            className: ''
          };
        }

        if (this.args.hideQuickNav) {
          this.setIntervalAutoNav();
        }
      }
    }

    finishLoading() {
      setTimeout(() => {
        this.finishedLoading = true;
        this.setIntervalAutoNav();
      }, 500);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "slideIndex", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "items", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "canChangeSlide", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "fillProgress", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "finishedLoading", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "changeSlide", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "changeSlide"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "quickChangeSlide", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "quickChangeSlide"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupAction", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "setupAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "finishLoading", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "finishLoading"), _class.prototype)), _class));
  _exports.default = CarouselComponent;
});