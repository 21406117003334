define("securitytrax-ember-ui/customers/accounting/invoices-invoices/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "abJhqKxz",
    "block": "{\"symbols\":[],\"statements\":[[8,\"pages/accounting/invoices/invoices\",[],[[\"@model\",\"@customer\"],[[34,0],[34,0,[\"customer\"]]]],null]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/customers/accounting/invoices-invoices/template.hbs"
    }
  });

  _exports.default = _default;
});