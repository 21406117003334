define("securitytrax-ember-ui/validations/customer-task", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateObject = {
        noteType: {
          presence: this.customMessageOrDefault(data, 'noteType')
        },
        subject: {
          presence: this.customMessageOrDefault(data, 'subject')
        }
      };

      if (!data.extraValidationData.noValidate.includes('customerTask-assignedUser')) {
        validateObject.assignedUser = {
          presence: this.customMessageOrDefault(data, 'assignedUser')
        };
      }

      if (this.isWorkOrder && 'noValidate' in data.extraValidationData && !data.extraValidationData.noValidate.includes('customerTask-workOrderType')) {
        validateObject.workOrderType = {
          presence: this.customMessageOrDefault(data, 'workOrderType')
        };
      } else {
        validateObject.note = {
          presence: this.customMessageOrDefault(data, 'note')
        };
      }

      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      var modelName = data.extraValidationData && data.extraValidationData.hasOwnProperty('overrideValidationKey') ? data.extraValidationData.overrideValidationKey : 'customerTask';
      return this.outputFormatting(validationData, modelName);
    }

  });

  _exports.default = _default;
});