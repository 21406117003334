define("securitytrax-ember-ui/demo/multi-step-form/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller.extend({
    formSteps: Ember.computed(function () {
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: 'Step # 1',
          stepComponentPath: 'multi-step-form/forms/demo/demo-wizard/step-1'
        }),
        step2: Ember.Object.create({
          stepNameKey: 'step2',
          title: 'Step # 2',
          stepComponentPath: 'multi-step-form/forms/demo/demo-wizard/step-2'
        }),
        step3: Ember.Object.create({
          stepNameKey: 'step3',
          title: 'Step # 3',
          stepComponentPath: 'multi-step-form/forms/demo/demo-wizard/step-3'
        }),
        step4: Ember.Object.create({
          stepNameKey: 'step4',
          title: 'Step # 4',
          stepComponentPath: 'multi-step-form/forms/demo/demo-wizard/step-4'
        })
      });
    }),
    singleStepForm: Ember.computed(function () {
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: 'Step # 1',
          stepComponentPath: 'multi-step-form/forms/demo/demo-form-1'
        })
      });
    }),
    demoModalWizardFormOriginalModels: Ember.computed(function () {
      return Ember.Object.create({
        customer: {
          model: this.get('model.customer'),
          copyRelationMap: {
            location: {
              readOnly: true
            },
            accountClass: {
              readOnly: true
            },
            customerContacts: {},
            customerSystemInformation: {
              relationships: {
                customerEquipment: {
                  relationships: {
                    equipment: {},
                    user: {
                      readOnly: true
                    }
                  }
                }
              }
            }
          }
        },
        equipment: {
          model: this.get('model.equipment')
        }
      });
    }),
    demoSwitchableFormOriginalModels: Ember.computed(function () {
      return this.demoSwitchableFormOriginalModelsTask.perform();
    }),
    demoSwitchableFormOriginalModelsTask: (0, _emberConcurrency.task)(function* () {
      return Ember.Object.create({
        customer: {
          model: yield this.get('model.customer'),
          copyRelationMap: {
            customerBirthdate: {},
            location: {
              readOnly: true
            },
            accountClass: {
              readOnly: true
            },
            customerContacts: {},
            customerSystemInformation: {
              relationships: {
                customerEquipment: {
                  relationships: {
                    equipment: {},
                    user: {
                      readOnly: true
                    }
                  }
                }
              }
            }
          }
        },
        equipment: {
          model: yield this.get('model.equipment')
        },
        customerOrderInformation: {
          // this acts basically as a test that copy works for properties than are not model names because copy happens on load
          readOnly: true,
          model: null,
          // force a create on mmr below to test the usecase of copy for mmr on create
          copyRelationMap: {
            mmr: {
              readOnly: true
            }
          }
        }
      });
    }),
    formStarted: false,
    actions: {
      startMultiStepForm() {
        this.set('formStarted', true);
      },

      transitionToSubroute(id) {
        if (id && id > 0) {
          this.transitionToRoute('demo.multi-step-form.edit-create', id);
        } else {
          this.transitionToRoute('demo.multi-step-form.edit-create', 'create');
        }
      }

    }
  });
  var _default = Controller;
  _exports.default = _default;
});