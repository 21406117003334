define("securitytrax-ember-ui/home/tech-today/appointment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/9JpE+tZ",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[30,[36,2],[\"customers.view_tag\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"customer-tags\"],[12],[2,\"\\n        \"],[8,\"html/st-tags\",[],[[\"@title\",\"@selectedTags\",\"@parentModel\",\"@tagObjectContext\",\"@isFulfilled\",\"@deleteFromName\"],[[30,[36,0],[\"pages.tags.applyCustomerTag\"],null],[34,1,[\"customer\",\"tags\"]],[34,1,[\"customer\"]],\"customer\",[34,1,[\"customer\",\"tags\",\"isFulfilled\"]],[34,1,[\"customer\",\"flname\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"components/homepage/tech-today/appointment\",[],[[\"@appointment\",\"@user\",\"@isManager\"],[[34,1,[\"appointment\"]],[34,1,[\"user\"]],[34,1,[\"isManager\"]]]],null],[2,\"\\n\\n\"],[1,[30,[36,5],[[30,[36,4],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"t\",\"model\",\"has-grants\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/home/tech-today/appointment/template.hbs"
    }
  });

  _exports.default = _default;
});