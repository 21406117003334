define("securitytrax-ember-ui/components/pages/administration/company-settings/global-settings/calendar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jBm93QrQ",
    "block": "{\"symbols\":[\"multiStepForm\",\"MultiStepFormComponent\"],\"statements\":[[8,\"html/st-await\",[],[[\"@useSpinner\",\"@booleanLoaded\",\"@size\"],[true,[30,[36,5],[[35,4],null],null],\"large\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,7],[[30,[36,6],[\"global_settings.view\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"multi-step-form\",[],[[\"@formSteps\",\"@formOriginalModels\",\"@formStarted\"],[[34,0],[34,1],true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[8,[32,2],[[24,0,\"company-calendar-settings-form\"]],[[\"@multiStepForm\",\"@formMode\"],[[32,1],\"view\"]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"        \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"companyCalendarSettingsFormSteps\",\"companyCalendarSettingsFormOriginalModels\",\"component\",\"let\",\"globalSettings\",\"not-eq\",\"has-grants\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/administration/company-settings/global-settings/calendar/template.hbs"
    }
  });

  _exports.default = _default;
});