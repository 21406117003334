define("securitytrax-ember-ui/components/org-chart/user-chain/uncategorized-link/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    orgTree: Ember.inject.service(),
    uncategorizedUsersEvent: Ember.inject.service('events/hierarchy'),
    tagName: '',

    init() {
      this._super(...arguments);

      this.uncategorizedUsersEvent.on('update-user-count', this, '_callback');
    },

    willDestroy() {
      this.uncategorizedUsersEvent.off('update-user-count', this, '_callback');
    },

    _callback() {
      Ember.notifyPropertyChange(this, 'uncategorizedUsersCount');
    },

    uncategorizedUsersCount: Ember.computed(function () {
      return this.uncategorizedUsersTask.perform();
    }),
    uncategorizedUsersTask: (0, _emberConcurrency.task)(function* () {
      try {
        var userHierarchyTree = yield this.userHierarchyTree;

        if (userHierarchyTree) {
          var uncategorizedUsers = yield userHierarchyTree.invokeAction('get_users_not_in_hierarchy');
          return uncategorizedUsers.responsePayload.data.length;
        }
      } catch (err) {
        throw err;
      }
    })
  });

  _exports.default = _default;
});