define("securitytrax-ember-ui/validations/user-tech-setting", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateObject = {
        techCoverageRadius: {
          presence: this.checkValidateCustomMessageDefault(data, 'techCoverageRadius')
        },
        techStartAddress: {
          presence: this.checkValidateCustomMessageDefault(data, 'techStartAddress')
        },
        techStartCity: {
          presence: this.checkValidateCustomMessageDefault(data, 'techStartCity')
        },
        techStartState: {
          presence: this.checkValidateCustomMessageDefault(data, 'techStartState')
        },
        techStartZip: {
          presence: this.checkValidateCustomMessageDefault(data, 'techStartZip'),
          format: {
            pattern: /\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d/,
            message: this.customMessage(data, 'shippingZipInvalid')
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject, {
        fullMessages: this.prependProperty(data)
      });
      return this.outputFormatting(validationData, 'userTechSetting');
    }

  });

  _exports.default = _default;
});