define("securitytrax-ember-ui/services/langs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    init() {
      this._super(...arguments);

      this.set('prioritizedLangsList', [{
        value: 'en',
        name: 'English'
      }, {
        value: 'es',
        name: 'Spanish'
      }, {
        value: 'fr',
        name: 'French'
      }]);
      this.set('langsList', [// Prioritized languages
      {
        value: 'en',
        name: 'English'
      }, {
        value: 'es',
        name: 'Spanish'
      }, {
        value: 'fr',
        name: 'French'
      }, // All other languages alphabetized
      {
        value: 'ab',
        name: 'Abkhazian'
      }, {
        value: 'aa',
        name: 'Afar'
      }, {
        value: 'af',
        name: 'Afrikaans'
      }, {
        value: 'sq',
        name: 'Albanian'
      }, {
        value: 'am',
        name: 'Amharic'
      }, {
        value: 'ar',
        name: 'Arabic'
      }, {
        value: 'hy',
        name: 'Armenian'
      }, {
        value: 'as',
        name: 'Assamese'
      }, {
        value: 'ay',
        name: 'Aymara'
      }, {
        value: 'az',
        name: 'Azerbaijani'
      }, {
        value: 'ba',
        name: 'Bashkir'
      }, {
        value: 'eu',
        name: 'Basque'
      }, {
        value: 'bn',
        name: 'Bengali/Bangla'
      }, {
        value: 'dz',
        name: 'Bhutani'
      }, {
        value: 'bh',
        name: 'Bihari'
      }, {
        value: 'bi',
        name: 'Bislama'
      }, {
        value: 'br',
        name: 'Breton'
      }, {
        value: 'bg',
        name: 'Bulgarian'
      }, {
        value: 'my',
        name: 'Burmese'
      }, {
        value: 'be',
        name: 'Byelorussian'
      }, {
        value: 'km',
        name: 'Cambodian'
      }, {
        value: 'ca',
        name: 'Catalan'
      }, {
        value: 'zh',
        name: 'Chinese'
      }, {
        value: 'co',
        name: 'Corsican'
      }, {
        value: 'hr',
        name: 'Croatian'
      }, {
        value: 'cs',
        name: 'Czech'
      }, {
        value: 'da',
        name: 'Danish'
      }, {
        value: 'nl',
        name: 'Dutch'
      }, {
        value: 'eo',
        name: 'Esperanto'
      }, {
        value: 'et',
        name: 'Estonian'
      }, {
        value: 'fo',
        name: 'Faeroese'
      }, {
        value: 'fj',
        name: 'Fiji'
      }, {
        value: 'fi',
        name: 'Finnish'
      }, {
        value: 'fy',
        name: 'Frisian'
      }, {
        value: 'gl',
        name: 'Galician'
      }, {
        value: 'ka',
        name: 'Georgian'
      }, {
        value: 'de',
        name: 'German'
      }, {
        value: 'el',
        name: 'Greek'
      }, {
        value: 'kl',
        name: 'Greenlandic'
      }, {
        value: 'gn',
        name: 'Guarani'
      }, {
        value: 'gu',
        name: 'Gujarati'
      }, {
        value: 'ha',
        name: 'Hausa'
      }, {
        value: 'iw',
        name: 'Hebrew'
      }, {
        value: 'hi',
        name: 'Hindi'
      }, {
        value: 'hu',
        name: 'Hungarian'
      }, {
        value: 'is',
        name: 'Icelandic'
      }, {
        value: 'in',
        name: 'Indonesian'
      }, {
        value: 'ia',
        name: 'Interlingua'
      }, {
        value: 'ie',
        name: 'Interlingue'
      }, {
        value: 'ik',
        name: 'Inupiak'
      }, {
        value: 'ga',
        name: 'Irish'
      }, {
        value: 'it',
        name: 'Italian'
      }, {
        value: 'ja',
        name: 'Japanese'
      }, {
        value: 'jw',
        name: 'Javanese'
      }, {
        value: 'kn',
        name: 'Kannada'
      }, {
        value: 'ks',
        name: 'Kashmiri'
      }, {
        value: 'kk',
        name: 'Kazakh'
      }, {
        value: 'rw',
        name: 'Kinyarwanda'
      }, {
        value: 'ky',
        name: 'Kirghiz'
      }, {
        value: 'ko',
        name: 'Korean'
      }, {
        value: 'ku',
        name: 'Kurdish'
      }, {
        value: 'lo',
        name: 'Laothian'
      }, {
        value: 'la',
        name: 'Latin'
      }, {
        value: 'lv',
        name: 'Latvian/Lettish'
      }, {
        value: 'ln',
        name: 'Lingala'
      }, {
        value: 'lt',
        name: 'Lithuanian'
      }, {
        value: 'mk',
        name: 'Macedonian'
      }, {
        value: 'mg',
        name: 'Malagasy'
      }, {
        value: 'ms',
        name: 'Malay'
      }, {
        value: 'ml',
        name: 'Malayalam'
      }, {
        value: 'mt',
        name: 'Maltese'
      }, {
        value: 'mi',
        name: 'Maori'
      }, {
        value: 'mr',
        name: 'Marathi'
      }, {
        value: 'mo',
        name: 'Moldavian'
      }, {
        value: 'mn',
        name: 'Mongolian'
      }, {
        value: 'na',
        name: 'Nauru'
      }, {
        value: 'ne',
        name: 'Nepali'
      }, {
        value: 'no',
        name: 'Norwegian'
      }, {
        value: 'oc',
        name: 'Occitan'
      }, {
        value: 'ps',
        name: 'Pashto/Pushto'
      }, {
        value: 'fa',
        name: 'Persian'
      }, {
        value: 'pl',
        name: 'Polish'
      }, {
        value: 'pt',
        name: 'Portuguese'
      }, {
        value: 'pa',
        name: 'Punjabi'
      }, {
        value: 'qu',
        name: 'Quechua'
      }, {
        value: 'rm',
        name: 'Rhaeto-Romance'
      }, {
        value: 'ro',
        name: 'Romanian'
      }, {
        value: 'ru',
        name: 'Russian'
      }, {
        value: 'sm',
        name: 'Samoan'
      }, {
        value: 'sg',
        name: 'Sangro'
      }, {
        value: 'sa',
        name: 'Sanskrit'
      }, {
        value: 'gd',
        name: 'Scots/Gaelic'
      }, {
        value: 'sr',
        name: 'Serbian'
      }, {
        value: 'sh',
        name: 'Serbo-Croatian'
      }, {
        value: 'st',
        name: 'Sesotho'
      }, {
        value: 'tn',
        name: 'Setswana'
      }, {
        value: 'sn',
        name: 'Shona'
      }, {
        value: 'sd',
        name: 'Sindhi'
      }, {
        value: 'si',
        name: 'Singhalese'
      }, {
        value: 'ss',
        name: 'Siswati'
      }, {
        value: 'sk',
        name: 'Slovak'
      }, {
        value: 'sl',
        name: 'Slovenian'
      }, {
        value: 'so',
        name: 'Somali'
      }, {
        value: 'su',
        name: 'Sundanese'
      }, {
        value: 'sw',
        name: 'Swahili'
      }, {
        value: 'sv',
        name: 'Swedish'
      }, {
        value: 'tl',
        name: 'Tagalog'
      }, {
        value: 'tg',
        name: 'Tajik'
      }, {
        value: 'ta',
        name: 'Tamil'
      }, {
        value: 'tt',
        name: 'Tatar'
      }, {
        value: 'te',
        name: 'Tegulu'
      }, {
        value: 'th',
        name: 'Thai'
      }, {
        value: 'bo',
        name: 'Tibetan'
      }, {
        value: 'ti',
        name: 'Tigrinya'
      }, {
        value: 'to',
        name: 'Tonga'
      }, {
        value: 'ts',
        name: 'Tsonga'
      }, {
        value: 'tr',
        name: 'Turkish'
      }, {
        value: 'tk',
        name: 'Turkmen'
      }, {
        value: 'tw',
        name: 'Twi'
      }, {
        value: 'uk',
        name: 'Ukrainian'
      }, {
        value: 'ur',
        name: 'Urdu'
      }, {
        value: 'uz',
        name: 'Uzbek'
      }, {
        value: 'vi',
        name: 'Vietnamese'
      }, {
        value: 'vo',
        name: 'Volapuk'
      }, {
        value: 'cy',
        name: 'Welsh'
      }, {
        value: 'wo',
        name: 'Wolof'
      }, {
        value: 'xh',
        name: 'Xhosa'
      }, {
        value: 'ji',
        name: 'Yiddish'
      }, {
        value: 'yo',
        name: 'Yoruba'
      }, {
        value: 'zu',
        name: 'Zulu'
      }, {
        value: 'om',
        name: '(Afan)/Oromoor/Oriya'
      }]);
    }

  });

  _exports.default = _default;
});