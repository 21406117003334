define("securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/greensky--dead/add-payment/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/create-financing-base", "securitytrax-ember-ui/mixed-in-classes/credit-card-mixin", "ember-concurrency", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _createFinancingBase, _creditCardMixin, _emberConcurrency, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // Deprecated for DealerAlly, may come back for some other lender.
  var CreateFinancingAddPayment = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = (0, _emberConcurrency.task)(function* () {
    var hashedData = yield (0, _emberConcurrency.hash)({
      billType: this.store.findRecord('billingType', 1),
      waitForFinishedCopyingModels: (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true)
    });
    (0, _objHelpers.initializeState)(this, {
      billing: {
        customer: this.args.customer,
        billType: hashedData.billType,
        billCcExpiration: undefined,
        billCcExpirationYear: undefined,
        billCcExpirationMonth: undefined,
        billCcNumber: undefined,
        billCcType: undefined,
        billCcName: this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.consumerFinancingApplicant.fname') + ' ' + this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.consumerFinancingApplicant.lname'),
        billAddress1: this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.consumerFinancingApplicant.address1'),
        billAddress2: this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.consumerFinancingApplicant.address2'),
        billCity: this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.consumerFinancingApplicant.city'),
        billState: this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.consumerFinancingApplicant.state'),
        billZip: this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.consumerFinancingApplicant.zip')
      }
    });

    if (this.loanDocument === undefined) {
      yield this.getLoanAgreementTask.perform();
    }
  }), _dec7 = (0, _emberConcurrency.task)(function* () {
    var formattedObjects = [];
    formattedObjects.pushObject(Ember.Object.create({
      model: this.billing,
      filePath: 'customer-billing',
      extraValidationData: {
        billType: this.billing.billType,
        toValidate: {
          billCcExpirationMonth: this.billing.billCcExpirationMonth,
          billCcExpirationYear: this.billing.billCcExpirationYear
        }
      }
    }));
    yield this.args.multiStepForm.validateList.perform(formattedObjects);

    try {
      var customerBilling = this.store.createRecord('customerBilling', this.billing);
      yield customerBilling.save();
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }

    buttonPromise.then(() => {
      this.args.multiStepForm.set('formStarted', false);
      this.stNotificationManager.addSuccessKey('pages.customers.accounting.financing.account_activated');
    });
  }), (_class = (_temp = class CreateFinancingAddPayment extends _createFinancingBase.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "grants", _descriptor4, this);

      _initializerDefineProperty(this, "billing", _descriptor5, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor6, this);

      _initializerDefineProperty(this, "savePayment", _descriptor7, this);

      this.CreditCardMixin = new _creditCardMixin.default(...arguments);
      this.initDataTask.perform();
    }

    buttons() {
      this.args.multiStepForm.registerStepData("addPayment", 'buttons', [{
        text: this.intl.t('common.actions.saveAndClose'),
        click: buttonPromise => {
          return this.savePayment.perform(this.args.multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary'
      }]);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "billing", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "savePayment", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "buttons", [action], Object.getOwnPropertyDescriptor(_class.prototype, "buttons"), _class.prototype)), _class));
  _exports.default = CreateFinancingAddPayment;
});