define("securitytrax-ember-ui/utils/model", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createModelAttribute = createModelAttribute;
  _exports.getRelationshipOptions = getRelationshipOptions;
  _exports.ARRAY = _exports.BOOLEAN = _exports.DATE = _exports.NUMBER = _exports.STRING = void 0;

  /**
   * Utility helper module for models.
   * @module
   */

  /**
   * Creates and returns a model attribute object using the Ember Data "attr" method.
   *
   * @param {String} [type] The model type.
   * @param {*} [defaultValue] An optional default attribute value.
   *
   * @returns {*}
   */
  function createModelAttribute(type, defaultValue) {
    var props; // Was a default value provided?

    if (defaultValue !== undefined) {
      // Create properties object.
      props = {
        defaultValue
      };
    } // Return attribute.


    return (0, _model.attr)(type, props);
  }
  /**
   * Creates and returns a model relationship options configuration object.
   *
   * @param {boolean} [async=true] Whether the relationship is asynchronous.
   * @param {String} [inverse=''] Identifies the inverse property on a related model in a One-ToMany relationship.
   *
   * @returns {{async: boolean, inverse: String?}}
   */


  function getRelationshipOptions(async = true, inverse = '') {
    // Create options.
    var options = {
      async
    }; // Was an inverse supplied?

    if (inverse || inverse === null) {
      // Add to options.
      options.inverse = inverse;
    }

    return options;
  }
  /**
   * The string data type.
   *
   * @type {String}
   */


  var STRING = 'string';
  /**
   * The number data type.
   *
   * @type {String}
   */

  _exports.STRING = STRING;
  var NUMBER = 'number';
  /**
   * The date data type.
   *
   * @type {String}
   */

  _exports.NUMBER = NUMBER;
  var DATE = 'date';
  /**
   * The boolean data type.
   *
   * @type {String}
   */

  _exports.DATE = DATE;
  var BOOLEAN = 'boolean';
  /**
   * The array data type.
   *
   * @note Corresponds to {@link transforms.ArrayTransform}
   * @type {String}
   */

  _exports.BOOLEAN = BOOLEAN;
  var ARRAY = 'array';
  _exports.ARRAY = ARRAY;
});