define("securitytrax-ember-ui/classes/payee", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var Payee = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = (0, _emberConcurrency.task)(function* () {
    var hashedData = yield (0, _emberConcurrency.hash)({
      payrollRoleFunctions: this.store.findAll('payrollRoleFunction', {
        include: 'payroll_list_method,payroll_role'
      }),
      assignments: this.stQueryParams.loadRelationshipWithQueryParams({
        model: this.model,
        relName: 'payablePayeeLevels',
        useCallback: false,
        options: {
          params: {
            include: 'payroll_role_function_level.payroll_role_function'
          }
        }
      })
    });
    var assignmentFunctionIds = hashedData.assignments.map(assignment => {
      return assignment.get('payrollRoleFunctionLevel.payrollRoleFunction.id');
    });
    return hashedData.payrollRoleFunctions.filter(option => {
      return !this.excludedPayrollRoleIds.includes(option.get('payrollRole.id')) && option.get('payrollListMethod.entityType') === this.entityType && !assignmentFunctionIds.includes(option.get('id')) && option.get('active');
    });
  }), (_class = (_temp = class Payee {
    constructor(args) {
      _initializerDefineProperty(this, "model", _descriptor, this);

      _initializerDefineProperty(this, "excludedPayrollRoleIds", _descriptor2, this);

      _initializerDefineProperty(this, "availablePayrollRoleFunctionsTask", _descriptor3, this);

      Ember.setProperties(this, args);
    }

    get entityType() {
      return this.model.constructor.modelName.replace('-', '_');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "model", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "excludedPayrollRoleIds", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "availablePayrollRoleFunctionsTask", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Payee;
});