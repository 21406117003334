define("securitytrax-ember-ui/validations/st-add-ons/twilio-integration", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateObject = {
        account_sid: {
          presence: () => {
            return this.customMessageOrDefault(data, 'twilioIntegration-account_sid');
          }
        },
        auth_token: {
          presence: () => {
            return this.customMessageOrDefault(data, 'twilioIntegration-auth_token');
          }
        },
        from: {
          presence: {
            allowEmpty: false,
            message: function () {
              return '^' + data.intlService.t('pages.administration.account_management.add_ons.messages_plus.from_empty_validation');
            }
          },
          format: {
            pattern: /^\d{11}|(MG)[a-zA-Z0-9]{32}$/,
            message: function () {
              return '^' + data.intlService.t('pages.administration.account_management.add_ons.messages_plus.from_invalid_validation');
            }
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      return this.outputFormatting(validationData, 'twilioIntegration');
    }

  });

  _exports.default = _default;
});