define("securitytrax-ember-ui/models/base_models/agency-contact", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    agencyContactType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['police', 'fire', 'guard', 'medical', 'electrical']
    }),
    name: (0, _model.attr)('string', {
      'readOnly': false
    }),
    permit: (0, _model.attr)('string', {
      'readOnly': false
    }),
    number: (0, _model.attr)('string', {
      'readOnly': false
    }),
    fee: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    customer: (0, _model.belongsTo)('customer', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': 'agencyContacts'
    })
  });

  _exports.default = _default;
});