define("securitytrax-ember-ui/components/multi-step-form/pencil-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eDhgDOzz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,4],[[30,[36,3],[[35,2],\"view\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[34,0]],[12],[2,\"\\n        \"],[1,[30,[36,8],null,[[\"class\",\"icon\",\"text\",\"iconOnly\",\"clickAction\"],[[30,[36,1],[\"pencil-component-edit-icon \",[35,7]],null],\"icn-edit\",[30,[36,6],[\"common.actions.edit\"],null],true,[30,[36,5],[[32,0],\"edit\"],null]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,4],[[30,[36,3],[[35,2],\"edit\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[30,[36,1],[[35,0],\" opened\"],null]],[12],[2,\"\\n        \"],[18,1,null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"actionClass\",\"concat\",\"formMode\",\"eq\",\"if\",\"action\",\"t\",\"editButtonClasses\",\"html/st-button\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/pencil-edit/template.hbs"
    }
  });

  _exports.default = _default;
});