define("securitytrax-ember-ui/models/payroll-role-function-level", ["exports", "securitytrax-ember-ui/models/base_models/payroll-role-function-level"], function (_exports, _payrollRoleFunctionLevel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _payrollRoleFunctionLevel.default.extend({
    payrollColumnsDerived: Ember.computed('payrollRoleFunction', function () {
      if (this.get('payrollRoleFunction.columnsDerivedFrom') === 'function') {
        return this.get('payrollColumns.length') > 0 ? this.get('payrollColumns') : this.get('payrollRoleFunction.payrollRoleFunctionPayrollColumns');
      }

      return this.get('payrollColumns');
    })
  });

  _exports.default = _default;
});