define("securitytrax-ember-ui/components/multi-step-form/forms/invoices/reusable-forms/invoice-details-billing-info/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var InvoiceDetailsBillingInfo = (_dec = Ember.inject.service, _dec2 = (0, _emberConcurrency.task)(function* () {
    if (this.args.formMode === 'create') {
      var invoiceType = this.args.multiStepForm.formCopiedModelsGetVal('invoice.model.invoiceType');
      Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.mailingAddress', yield this.formattedBillingAddressTask.perform());
      Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.mailingName', yield this.args.customer.get('formattedBillingNameTask').perform(invoiceType));
    }
  }), _dec3 = (0, _emberConcurrency.task)(function* () {
    var customerAccountingInfo = yield this.args.customer.get('customerAccountingInformation');
    var invoiceBillingInformation = customerAccountingInfo.filterBy('useType', this.args.multiStepForm.formCopiedModelsGetVal('invoice.model.invoiceType'))[0];
    var defaultBillingInformation = customerAccountingInfo.filterBy('useType', 'default')[0];

    if (invoiceBillingInformation) {
      var address1 = invoiceBillingInformation.get('address1');
      var address2 = invoiceBillingInformation.get('address2');
      var city = invoiceBillingInformation.get('city');
      var state = invoiceBillingInformation.get('state');
      var zip = invoiceBillingInformation.get('zip');
      var mailingAddress = address1 + (address2 ? "\n".concat(address2) : '') + '\n' + city + ', ' + state + ' ' + zip;
      return invoiceBillingInformation.get('usePrimary') ? this.args.customer.get('fullAddress') : mailingAddress;
    } else if (defaultBillingInformation) {
      var _address = defaultBillingInformation.get('address1');

      var _address2 = defaultBillingInformation.get('address2');

      var _city = defaultBillingInformation.get('city');

      var _state = defaultBillingInformation.get('state');

      var _zip = defaultBillingInformation.get('zip');

      var _mailingAddress = _address + (_address2 ? "\n".concat(_address2) : '') + '\n' + _city + ', ' + _state + ' ' + _zip;

      return defaultBillingInformation.get('usePrimary') ? this.args.customer.get('fullAddress') : _mailingAddress;
    }

    return this.args.customer.get('fullAddress');
  }), (_class = (_temp = class InvoiceDetailsBillingInfo extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "grants", _descriptor, this);

      _initializerDefineProperty(this, "initData", _descriptor2, this);

      _initializerDefineProperty(this, "formattedBillingAddressTask", _descriptor3, this);

      this.initData.perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "initData", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "formattedBillingAddressTask", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = InvoiceDetailsBillingInfo;
});