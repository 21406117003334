define("securitytrax-ember-ui/components/spinner-svg/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jXi7Ey1/",
    "block": "{\"symbols\":[],\"statements\":[[10,\"circle\"],[14,\"cx\",\"9\"],[14,\"cy\",\"9\"],[14,\"r\",\"8\"],[12],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/spinner-svg/template.hbs"
    }
  });

  _exports.default = _default;
});