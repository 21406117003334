define("securitytrax-ember-ui/components/html/svg-components/credit-score-scale/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * credit score scale
   *
   * @class Component
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    tagName: '',
    cardTitle: '',
    sliderOffset: Ember.computed('showScore', 'sliderValue', function () {
      return this.convertScore();
    }),
    rangeMin: 350,
    rangeMax: 850,
    creditScore: 720,
    sliderValue: Ember.computed('creditScore', function () {
      return this.convertScore();
    }),
    sliderWidth: 200,
    handleWidth: 38,
    showScore: true,

    init() {
      this._super(...arguments);
    },

    setHandle: Ember.observer('showScore', function () {
      if (this.showScore) {
        this.set('handleWidth', 38);
      } else {
        this.set('handleWidth', 24);
      }

      this.convertScore();
    }),

    /*   convertPercentage() {
          this.set('sliderOffset', this.sliderValue * 162 / 100);
      }, */
    convertScore() {
      if (this.creditScore >= this.rangeMin && this.creditScore <= this.rangeMax) {
        var newSliderVal = this.creditScore - this.rangeMin;
        var range = this.rangeMax - this.rangeMin;
        return newSliderVal * (this.sliderWidth - this.handleWidth) / range;
      }

      return 84;
    },

    actions: {
      showDropdown(dropdown) {
        dropdown.actions.open();
      },

      hideDropdown(dropdown) {
        dropdown.actions.close();
      }

    }
  });

  _exports.default = _default;
});