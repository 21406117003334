define("securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/dealer-ally/loan-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JMd5GL1k",
    "block": "{\"symbols\":[\"&attrs\",\"@multiStepForm\",\"@formMode\",\"@lenderId\",\"@customer\"],\"statements\":[[11,\"div\"],[17,1],[12],[2,\"\\n    \"],[8,\"multi-step-form/progress-bar\",[],[[\"@additionalClass\",\"@config\",\"@modalButtons\"],[\"layout-center-middle\",[34,0],[32,2,[\"currentStepData\",\"buttons\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[11,\"div\"],[4,[38,1],[[32,0,[\"buttons\"]]],null],[12],[2,\"\\n            \"],[8,\"multi-step-form/forms/customer/create-financing/dealer-ally/loan-details/details\",[],[[\"@multiStepForm\",\"@formMode\",\"@lenderId\",\"@customer\"],[[32,2],[32,3],[32,4],[32,5]]],null],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"progressBarConfig\",\"did-insert\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/dealer-ally/loan-details/template.hbs"
    }
  });

  _exports.default = _default;
});