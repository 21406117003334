define("securitytrax-ember-ui/services/modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Service to manage open and close of a modal window
   *
   * @class Modal
   * @extends Ember.Service
   * @memberof services
   * @mixes Ember.Evented
   */
  var _default = Ember.Service.extend(Ember.Evented,
  /** @lends services.Modal.prototype */
  {
    /**
     * Show the modal
     *
     * @param {Ember.Component|modals.ModalDescriptor} component Either a component
     * defined in a template with the 'component' helper or a component defined by
     * a ModalDescriptor (with type and modalModel properties)
     */
    open(component) {
      this.trigger('open', component);
    },

    /**
     * Hide the modal
     */
    close() {
      this.trigger('close');
    },

    actions: {
      /**
       * Open via handlebar action helper equivalent
       */
      open(component) {
        this.open(component);
      },

      /**
       * Close via handlebar action helper equivalent
       */
      close() {
        this.close();
      }

    }
  });
  /**
   * Holds the description of a component that will be opened inside a modal
   *
   * @typedef {{
   *     type: String,
   *     modalModel: Object
   * }} ModalDescriptor
   *
   * @property {String} type Path to a component e.g. 'forms/edit-note'
   * @property {String} modalModel Parameters to be passed to the 'type' component
   *
   * @global
   */


  _exports.default = _default;
});