define("securitytrax-ember-ui/models/base_models/global-settings-docusign-setting", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    enabled: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    embeddedSigningDisabled: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    cacheEnabled: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    clearCache: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    serviceName: (0, _model.attr)('string', {
      'readOnly': false
    }),
    serviceEmail: (0, _model.attr)('string', {
      'readOnly': false
    }),
    serviceUsername: (0, _model.attr)('string', {
      'readOnly': false
    }),
    servicePassword: (0, _model.attr)('string', {
      'readOnly': false
    }),
    brandId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    accountId: (0, _model.attr)('number', {
      'readOnly': false
    }),
    apiAccountId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    provisionedAt: (0, _model.attr)('json', {
      'readOnly': false
    }),
    roles: (0, _model.attr)('json', {
      'readOnly': false
    }),
    staticFields: (0, _model.attr)('json', {
      'readOnly': false
    }),
    environment: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['development', 'production', 'production_canada']
    })
  });

  _exports.default = _default;
});