define("securitytrax-ember-ui/models/account-billing", ["exports", "securitytrax-ember-ui/models/base_models/account-billing"], function (_exports, _accountBilling) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _accountBilling.default.extend({
    lastFour: Ember.computed('number', function () {
      return String(this.number || '').slice(-4);
    }),
    title: Ember.computed('titleValues', function () {
      var intl = this.intl;
      var descriptionKey = 'pages.administration.account_management.billing.account_billing.description.credit_card';

      if (intl.exists(descriptionKey)) {
        var rTitleValues = this.titleValues;
        return intl.t(descriptionKey, rTitleValues);
      }

      return '...';
    }),
    titleValues: Ember.computed('cardExpireYear', 'cardExpireMonth', 'cardType', 'lastFour', function () {
      var cleanedExpirationMonth = this.cardExpireMonth.length === 1 ? '0'.concat(this.cardExpireMonth) : this.cardExpireMonth;
      return {
        method: this.cardType,
        last_four: this.lastFour,
        expirationMonth: cleanedExpirationMonth,
        expirationYear: this.cardExpireYear.slice(2)
      };
    })
  });

  _exports.default = _default;
});