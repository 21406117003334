define("securitytrax-ember-ui/components/multi-step-form/forms/administration/users/my-inventory/purchase-orders/edit-purchase-order/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/grouped-equipment", "securitytrax-ember-ui/utils/debug"], function (_exports, _emberConcurrency, _groupedEquipment, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_groupedEquipment.default, {
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    sendPreviewEmail: Ember.inject.service(),
    company: undefined,
    inventoryUser: undefined,
    branchNumbers: undefined,
    retrievalMethods: undefined,
    processingMethods: undefined,
    confirmCloseModalOpen: false,
    items: Ember.computed.alias('multiStepForm.formCopiedModels.equipmentPurchaseOrder.model.pendingEquipmentLineItems'),
    fulfilledQuantityObserver: Ember.observer('multiStepForm.formCopiedModels.equipmentPurchaseOrder.model.pendingEquipmentLineItems.@each.fulfilledQuantity', function () {
      var pendingEquipmentLineItems = this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseOrder.model.pendingEquipmentLineItems');

      if (pendingEquipmentLineItems) {
        pendingEquipmentLineItems.forEach(pendingEquipmentLineItem => {
          pendingEquipmentLineItem.set('outstandingQuantity', pendingEquipmentLineItem.get('quantity') - pendingEquipmentLineItem.get('fulfilledQuantity'));
        });
      }
    }),
    totalJustReceivedQuantity: Ember.computed('multiStepForm.formCopiedModels.equipmentPurchaseOrder.model.pendingEquipmentLineItems.@each.justReceivedQuantity', function () {
      var total = 0;

      if (this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseOrder.model.pendingEquipmentLineItems')) {
        this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseOrder.model.pendingEquipmentLineItems').forEach(pendingEquipmentLineItem => {
          total += +pendingEquipmentLineItem.get('justReceivedQuantity');
        });
      }

      return total;
    }),
    totalQuantity: Ember.computed('multiStepForm.formCopiedModels.equipmentPurchaseOrder.model.pendingEquipmentLineItems.@each.quantity', function () {
      var total = 0;

      if (this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseOrder.model.pendingEquipmentLineItems')) {
        this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseOrder.model.pendingEquipmentLineItems').forEach(pendingEquipmentLineItem => {
          total += +pendingEquipmentLineItem.get('quantity');
        });
      }

      return total;
    }),
    totalFulfilledQuantity: Ember.computed('multiStepForm.formCopiedModels.equipmentPurchaseOrder.model.pendingEquipmentLineItems.@each.oldFulfilledQuantity', function () {
      var total = 0;

      if (this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseOrder.model.pendingEquipmentLineItems')) {
        this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseOrder.model.pendingEquipmentLineItems').forEach(pendingEquipmentLineItem => {
          total += +pendingEquipmentLineItem.get('oldFulfilledQuantity');
        });
      }

      return total;
    }),
    totalCost: Ember.computed('multiStepForm.formCopiedModels.equipmentPurchaseOrder.model.pendingEquipmentLineItems.@each.extended', function () {
      var total = 0.00;

      if (this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseOrder.model.pendingEquipmentLineItems')) {
        this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseOrder.model.pendingEquipmentLineItems').forEach(pendingEquipmentLineItem => {
          total += +pendingEquipmentLineItem.get('cost') * +pendingEquipmentLineItem.get('quantity');
        });
      }

      return total;
    }),
    initFormData: (0, _emberConcurrency.task)(function* () {
      var hashedData = yield (0, _emberConcurrency.hash)({
        company: this.sessionData.getCompany(),
        vendor: this.multiStepForm.formOriginalModelsGetVal('equipmentPurchaseOrder.model.equipmentVendor'),
        waitForMixinInit: (0, _emberConcurrency.waitForProperty)(this.initMixinDataTask, 'isIdle', true),
        inventoryUser: this.grants.getIndividualGrant('inventory_users.view') && this.multiStepForm.formOriginalModelsGetVal('equipmentPurchaseOrder.model.user.id') ? this.store.findRecord('inventoryUser', this.multiStepForm.formOriginalModelsGetVal('equipmentPurchaseOrder.model.user.id')) : undefined
      });
      var additionalInformation = yield hashedData.vendor.get('additionalInformation');
      var processing = yield hashedData.vendor.get('processingMethods');
      yield (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels');
      var status = this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseOrder.model.status');
      var buttons = [{
        icon: 'icn-print',
        iconOnly: true,
        click: buttonPromise => {
          return this.printPurchaseOrder.perform(buttonPromise);
        },
        classNamesString: 'icon-only po-print-button'
      }];

      if (this.grants.getIndividualGrant('equipment_purchase_orders.modify')) {
        if (status === 'open') {
          if (this.admin) {
            buttons.pushObject({
              text: this.intl.t('pages.users.my_inventory.purchase_orders.saveAndClosePO'),
              click: () => {
                this.set('confirmCloseModalOpen', true);
              },
              classNamesString: 'st-btn st-btn-secondary po-close-button'
            });
          }

          buttons.pushObject({
            text: this.intl.t('common.actions.save'),
            click: buttonPromise => {
              if (this.totalOutstandingQuantity === 0) {
                this.set('confirmCloseModalOpen', true);
              } else {
                return this.savePurchaseOrderData.perform(this.multiStepForm, buttonPromise);
              }
            },
            classNamesString: 'st-btn st-btn-primary po-save-button'
          });
        }
      }

      this.multiStepForm.registerStepData("step1", 'buttons', buttons);
      this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseOrder.model.pendingEquipmentLineItems').forEach(pendingEquipmentLineItem => {
        var copiedEquipmentType = pendingEquipmentLineItem.get('equipment.equipmentType');
        var realEquipmentType = this.mixinEquipmentTypes.findBy('id', pendingEquipmentLineItem.get('equipment.equipmentType.id'));
        pendingEquipmentLineItem.set('totalCost', +pendingEquipmentLineItem.get('cost') * +pendingEquipmentLineItem.get('quantity'));
        pendingEquipmentLineItem.set('oldFulfilledQuantity', pendingEquipmentLineItem.get('fulfilledQuantity'));
        pendingEquipmentLineItem.set('oldOutstandingQuantity', pendingEquipmentLineItem.get('quantity') - pendingEquipmentLineItem.get('fulfilledQuantity'));
        pendingEquipmentLineItem.set('extended', pendingEquipmentLineItem.get('quantity') * pendingEquipmentLineItem.get('equipment.cost'));
        pendingEquipmentLineItem.set('justReceivedQuantity', 0);

        if (copiedEquipmentType) {
          copiedEquipmentType.set('icon', realEquipmentType.get('icon'));
          copiedEquipmentType.set('color', realEquipmentType.get('color'));
        }

        pendingEquipmentLineItem.set('history', this.grants.getIndividualGrant('equipment_ledgers.computed.company_inventory') ? this.store.query('equipmentLedger', {
          // PERF TODO: Can BE add history relation to PEL model and then we use includes on rel call? Or FE cache somehow?
          filter: 'source_id = ' + pendingEquipmentLineItem.get('id') + ' and source_class = 400'
        }) : []);
      });
      this.set('branchNumbers', additionalInformation ? additionalInformation.branch_numbers : []);
      this.set('retrievalMethods', additionalInformation ? additionalInformation.retrieval : []);
      this.set('processingMethods', processing);
      this.set('company', hashedData.company);
      this.set('inventoryUser', hashedData.inventoryUser);
    }).on('init'),
    closePurchaseOrderTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      try {
        var pr = multiStepForm.formOriginalModelsGetVal('equipmentPurchaseOrder.model');
        yield this.saveLineItems.perform(multiStepForm);
        yield pr.invokeAction('set_status_closed', {
          verb: 'POST'
        });
        buttonPromise.then(() => {
          multiStepForm.formStartedSetVal(false);
          multiStepForm.onAfterSave();
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal2'
        });
        throw err;
      }
    }),
    printPurchaseOrder: (0, _emberConcurrency.task)(function* () {
      try {
        var previewData = yield this.sendPreviewEmail.getPreview(this.multiStepForm.formOriginalModelsGetVal('equipmentPurchaseOrder.model'));

        if (!((0, _debug.isTestEnvironment)() || (0, _debug.isCypressTestEnvironment)())) {
          window.location.assign(previewData.url);
        }
      } catch (error) {
        this.stNotificationManager.addErrorMessages(error, {
          notificationNamespace: 'global'
        });
      }
    }),
    savePurchaseOrderData: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      try {
        yield this.saveLineItems.perform(multiStepForm);
        buttonPromise.then(() => {
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
          multiStepForm.formStartedSetVal(false);
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
        throw err;
      }
    }),
    saveLineItems: (0, _emberConcurrency.task)(function* (multiStepForm) {
      var options = {
        relationships: {
          pendingEquipmentLineItems: {
            relationships: {
              equipment: {}
            }
          }
        }
      };
      var po = yield multiStepForm.formOriginalModelsGetVal('equipmentPurchaseOrder.model').deepSync({
        relationshipMap: options,
        copiedObject: multiStepForm.formCopiedModelsGetVal('equipmentPurchaseOrder.model'),
        originalOptions: {
          onBeforeSync: multiStepForm.onBeforeSync,
          onAfterSync: multiStepForm.onAfterSync
        }
      });
      yield po.save();
      multiStepForm.onAfterSave();
    }),

    updateFulfilled(row, direction) {
      if (direction === 'up') {
        var updatedValue = row.get('fulfilledQuantity') + (row.get('outstandingQuantity') ? row.get('outstandingQuantity') : row.get('oldOutstandingQuantity'));
        var updatedReceived = row.get('justReceivedQuantity') + (row.get('outstandingQuantity') ? row.get('outstandingQuantity') : row.get('oldOutstandingQuantity'));
        row.set('justReceivedQuantity', updatedReceived > row.get('quantity') ? row.get('quantity') : updatedReceived);
        row.set('fulfilledQuantity', updatedValue > row.get('quantity') ? row.get('quantity') : updatedValue);
      } else {
        row.set('fulfilledQuantity', row.get('fulfilledQuantity') - row.get('justReceivedQuantity'));
        row.set('justReceivedQuantity', 0);
      }
    },

    actions: {
      updateFulfilledQuantity(row) {
        if (+row.get('justReceivedQuantity') > +row.get('oldOutstandingQuantity')) {
          row.set('justReceivedQuantity', row.get('oldOutstandingQuantity'));
        }

        row.set('fulfilledQuantity', +row.get('oldFulfilledQuantity') + +row.get('justReceivedQuantity'));
      },

      updateFulfilledRow(row, direction) {
        this.updateFulfilled(row, direction);
      },

      updateFulfilledAll(direction) {
        if (this.formMode !== 'view') {
          this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseOrder.model.pendingEquipmentLineItems').forEach(pendingEquipmentLineItem => {
            this.updateFulfilled(pendingEquipmentLineItem, direction);
          });
        }
      }

    }
  });

  _exports.default = _default;
});