define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/edit-create-credit-bureau/protection-1/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/ikdaL7j",
    "block": "{\"symbols\":[\"data\"],\"statements\":[[8,\"html/st-await\",[],[[\"@useSpinner\",\"@promises\"],[true,[30,[36,1],null,[[\"monitoringCompanies\"],[[35,0]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"grid-x grid-padding-x\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cell medium-6\"],[12],[2,\"\\n            \"],[8,\"multi-step-form/switchable-inputs/select-input\",[],[[\"@formMode\",\"@options\",\"@value\",\"@label\",\"@bindValueAsObject\",\"@renderInPlaceOverride\"],[[34,2],[32,1,[\"resolvedPromises\",\"monitoringCompanies\"]],[34,3,[\"formCopiedModels\",\"creditBureau\",\"model\",\"creditBureauConfig\",\"monitoringCompanyId\"]],[30,[36,4],[\"common.columns.monitoring_company\"],null],false,false]],null],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"monitoringCompanies\",\"hash\",\"formMode\",\"multiStepForm\",\"t\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/edit-create-credit-bureau/protection-1/template.hbs"
    }
  });

  _exports.default = _default;
});