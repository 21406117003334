define("securitytrax-ember-ui/home/tech-today/appointment/create-invoice/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H5CVaT2j",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modals/sub-route-modals/edit-create-invoice\",[],[[\"@invoiceId\",\"@parentRoute\",\"@invoiceFormMode\",\"@invoiceType\",\"@invoiceFirstStep\",\"@customer\",\"@workOrder\"],[[34,0,[\"customerInvoiceId\"]],[34,0,[\"parentRoute\"]],[30,[36,2],[[30,[36,1],[[35,0,[\"customerInvoiceId\"]],\"create\"],null],\"create\",\"edit\"],null],\"invoice\",\"details\",[34,0,[\"appointment\",\"customer\"]],[34,0,[\"appointment\",\"workOrder\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/home/tech-today/appointment/create-invoice/template.hbs"
    }
  });

  _exports.default = _default;
});