define("securitytrax-ember-ui/components/customer/customer-statements-card/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CustomerStatementsCard = (_dec = Ember.inject.service('intl'), _dec2 = Ember.inject.service('store'), _dec3 = Ember.inject.service('stNotificationManager'), _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = (0, _emberConcurrency.task)(function* () {
    var statements = yield this.store.query('invoiceStatement', {
      filter: "customer = ".concat(this.args.customer.id),
      sort: '-created_at'
    });
    this.customerInvoiceStatements = statements;
  }), _dec10 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var newStatement;

    try {
      var hashedData = yield (0, _emberConcurrency.hash)({
        mailingAddress: this.args.customer.get('formattedBillingAddressTask').perform('invoice_statement'),
        mailingName: this.args.customer.get('formattedBillingNameTask').perform('invoice_statement')
      });
      newStatement = this.store.createRecord('invoiceStatement', {
        customer: this.args.customer,
        mailingAddress: hashedData.mailingAddress,
        mailingName: hashedData.mailingName
      });
      var savedStatement = yield newStatement.save();
      buttonPromise.then(() => {
        this.invoiceStatement = savedStatement;
        this.updateFormTitle(savedStatement);
        this.customerStatementFormMode = 'edit';
        this.customerStatementFormStarted = true;
        this.getCustomerStatements.perform();
      });
    } catch (err) {
      newStatement.rollbackAttributes();

      if (err.errors[0].detail === 'Customer has no open invoices and a statement could not be generated') {
        this.stNotificationManager.addError(this.intl.t('pages.customers.accounting.statements.no_open_invoices'));
      } else {
        this.stNotificationManager.addErrorMessages(err);
      }

      throw err;
    }
  }), _dec11 = Ember._action, (_class = (_temp = class CustomerStatementsCard extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor3, this);

      _initializerDefineProperty(this, "invoiceStatement", _descriptor4, this);

      _initializerDefineProperty(this, "customerStatementFormMode", _descriptor5, this);

      _initializerDefineProperty(this, "customerStatementFormStarted", _descriptor6, this);

      _initializerDefineProperty(this, "formTitleId", _descriptor7, this);

      _initializerDefineProperty(this, "customerInvoiceStatements", _descriptor8, this);

      _initializerDefineProperty(this, "getCustomerStatements", _descriptor9, this);

      _initializerDefineProperty(this, "startStatementForm", _descriptor10, this);

      this.getCustomerStatements.perform();
    }

    get customerStatementFormSteps() {
      var intl = this.intl;
      return Ember.Object.create({
        details: Ember.Object.create({
          stepNameKey: 'details',
          progressBarTitle: intl.t('common.columns.details'),
          title: intl.t('pages.customers.accounting.statements.edit_statement', {
            name: this.args.customer.flname,
            id: this.formTitleId
          }),
          stepComponentPath: 'multi-step-form/forms/accounting/invoice-statements/create-edit-customer-statement'
        }),
        send: Ember.Object.create({
          stepNameKey: 'send',
          progressBarTitle: intl.t('common.columns.send'),
          title: intl.t('pages.customers.accounting.statements.edit_statement', {
            name: this.args.customer.flname,
            id: this.formTitleId
          }),
          stepComponentPath: 'multi-step-form/forms/accounting/invoice-statements/send-customer-statement'
        })
      });
    }

    get customerStatementFormOriginalModels() {
      return Ember.Object.create({
        invoiceStatement: {
          model: this.invoiceStatement
        }
      });
    }

    updateFormTitle(statement) {
      this.formTitleId = statement.id;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "invoiceStatement", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "customerStatementFormMode", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'create';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "customerStatementFormStarted", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "formTitleId", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "customerInvoiceStatements", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "getCustomerStatements", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "startStatementForm", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateFormTitle", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "updateFormTitle"), _class.prototype)), _class));
  _exports.default = CustomerStatementsCard;
});