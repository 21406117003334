define("securitytrax-ember-ui/components/html/st-media-object/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3JTmqs9n",
    "block": "{\"symbols\":[\"@title\",\"@id\",\"@link\",\"@orientation\",\"@thumbnail\",\"@showShortTitle\",\"@subTitle\"],\"statements\":[[10,\"div\"],[15,0,[30,[36,2],[\"st-media-object \",[32,4]],null]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"st-media-object__thumbnail\"],[12],[2,\"\\n        \"],[8,\"html/st-thumbnail\",[],[[\"@title\",\"@thumbnail\",\"@showShortTitle\"],[[32,1],[32,5],[32,6]]],null],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"st-media-object__content\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"st-media-object__title orientation\"],[12],[2,\"\\n\"],[6,[37,3],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,1],null,[[\"class\",\"route\",\"model\"],[\"st-user-card__section-heading--link\",[32,3],[32,2]]],[[\"default\"],[{\"statements\":[[2,\"                    \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,1],null,[[\"target\",\"bubbles\",\"route\",\"model\"],[\"_blank\",false,[32,3],[32,2]]],[[\"default\"],[{\"statements\":[[2,\"                    \"],[1,[30,[36,0],[\"icn-new-window\"],[[\"class\"],[\"icn-new-window\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"                \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n        \"],[10,\"span\"],[14,0,\"st-media-object__sub-title orientation\"],[12],[1,[30,[36,3],[[32,7],[30,[36,2],[[30,[36,4],[\"pages.administration.human_resources.job\"],null],\" -\"],null],\"\"],null]],[2,\" \"],[1,[32,7]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"svg-icon\",\"link-to\",\"concat\",\"if\",\"t\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/st-media-object/template.hbs"
    }
  });

  _exports.default = _default;
});