define("securitytrax-ember-ui/components/customer/quick-customer-info/quick-customer-info-content/component", ["exports", "securitytrax-ember-ui/mixins/components/account-status", "ember-concurrency", "moment"], function (_exports, _accountStatus, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_accountStatus.default, {
    mapWindow: Ember.inject.service(),
    intl: Ember.inject.service(),
    appointment: null,
    cancelledClass: '',
    createdString: '',

    init() {
      this._super(...arguments);

      this.initDataTask.perform();
    },

    initDataTask: (0, _emberConcurrency.task)(function* () {
      var hashedData = yield (0, _emberConcurrency.hash)({
        isCancelled: this.customer.get('isCancelled'),
        createdByUser: this.customer.get('createdByUser')
      });

      if (!this.customer.get('isLead')) {
        if (hashedData.isCancelled) {
          this.set('cancelledClass', 'cancelled-customer');
        }
      } else {
        this.set('cancelledClass', '');
      }

      this.set('createdString', this.intl.t('pages.customers.dashboard.account_status.created', {
        date: (0, _moment.default)(this.customer.get('createdAt')).format('MM/DD/YYYY'),
        byUser: hashedData.createdByUser ? 'by ' + hashedData.createdByUser.flname : ''
      }));
    }),
    commercialAccountClass: Ember.computed('customer.accountType', function () {
      if (this.get('customer.accountType') === 'Commercial') {
        return 'commercial';
      } else {
        return '';
      }
    }),
    actions: {
      openMapWindow() {
        this.mapWindow.openWindow({
          latitude: Number(this.get('customer.responseMeta.geopoint.latitude')),
          longitude: Number(this.get('customer.responseMeta.geopoint.longitude')),
          label: "".concat(this.get('customer.address1'), " - ").concat(this.get('customer.city'))
        });
      }

    }
  });

  _exports.default = _default;
});