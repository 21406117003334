define("securitytrax-ember-ui/validations/interactive-services-provider/adc-activate-commitment", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateObject = {
        modemSerialNumber: {
          presence: () => {
            return this.customMessageOrDefault(data, 'adc-modemSerialNumber');
          }
        },
        panelType: {
          presence: () => {
            return this.customMessageOrDefault(data, 'adc-panelType');
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      return this.outputFormatting(validationData, 'adc');
    }

  });

  _exports.default = _default;
});