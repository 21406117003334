define("securitytrax-ember-ui/services/st-timers", ["exports", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    timers: undefined,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        timers: {}
      });
    },

    startTimer(key, extraComment = '') {
      var now = window.performance.now();
      this.timers[key] = now; // eslint-disable-next-line

      console.log("Started Timer (".concat(key, ", ").concat(extraComment, "): 0 ms"));
      return now;
    },

    endTimer(key, extraComment = '') {
      var end = window.performance.now();
      var totalTime = end - this.timers[key]; // eslint-disable-next-line

      console.log("Total Time (".concat(key, ", ").concat(extraComment, "): ").concat(totalTime, " ms"));
      return totalTime;
    }

  });

  _exports.default = _default;
});