define("securitytrax-ember-ui/models/base_models/account-billing", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    accountId: (0, _model.attr)('number', {
      'readOnly': true
    }),
    userId: (0, _model.attr)('number', {
      'readOnly': true
    }),
    number: (0, _model.attr)('string', {
      'readOnly': false
    }),
    isActive: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    isVerified: (0, _model.attr)('boolean', {
      'readOnly': true
    }),
    address1: (0, _model.attr)('string', {
      'readOnly': false
    }),
    address2: (0, _model.attr)('string', {
      'readOnly': false
    }),
    city: (0, _model.attr)('string', {
      'readOnly': false
    }),
    state: (0, _model.attr)('string', {
      'readOnly': false
    }),
    zip: (0, _model.attr)('string', {
      'readOnly': false
    }),
    cardType: (0, _model.attr)('string', {
      'readOnly': true
    }),
    cardNameOnCard: (0, _model.attr)('string', {
      'readOnly': false
    }),
    cardExpireMonth: (0, _model.attr)('string', {
      'readOnly': false
    }),
    cardExpireYear: (0, _model.attr)('string', {
      'readOnly': false
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    isAccountDefault: (0, _model.attr)('boolean', {
      'readOnly': true
    }),
    isExpired: (0, _model.attr)('boolean', {
      'readOnly': true
    })
  });

  _exports.default = _default;
});