define("securitytrax-ember-ui/components/pages/all-customer-refund-receipts/component", ["exports", "@glimmer/component", "ember-concurrency", "lodash", "securitytrax-ember-ui/mixed-in-classes/table-column-sort", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _component, _emberConcurrency, _lodash, _tableColumnSort, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var InvoicesBase = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember.inject.service, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._tracked, _dec21 = Ember._tracked, _dec22 = Ember._tracked, _dec23 = (0, _emberConcurrency.task)(function* () {
    (0, _objHelpers.initializeState)(this, {
      stSortableColumnOptions: [{
        name: this.intl.t('common.columns.sale_date') + ' ' + this.intl.t('common.sorting.ascending'),
        value: 'sale_date',
        chevronDirection: 'up'
      }, {
        name: this.intl.t('common.columns.sale_date') + ' ' + this.intl.t('common.sorting.descending'),
        value: '-sale_date',
        chevronDirection: 'down'
      }]
    });
    this.TableColumnSort.stSortableColumnOptions = this.stSortableColumnOptions;
    this.TableColumnSort.stSortAscending = false;
    this.TableColumnSort.stSortCurrentSortColumn = 'sale_date';
    var isConnected = yield this.quickbooksService.quickbooksIsConnected.perform();
    Ember.set(this, 'quickbooksConnected', isConnected);
    Ember.set(this, 'tagsOptions', yield this.tagsService.tagsOptionsTask.perform('invoice', 'invoice_refund_receipt'));
    Ember.set(this, 'customerTags', yield this.tagsService.tagsOptionsTask.perform('customer'));
  }), _dec24 = (0, _emberConcurrency.task)(function* () {
    try {
      var newFilters = yield this.stFiltersService.getFiltersByGroupKey('accountingRefundReceipts', ["invoice_type = 'refund_receipt'"]);
      var count;

      if (this.page == 1) {
        count = 1;
      }

      var customerRefundReceipts = yield this.store.query('invoice', {
        sort: this.TableColumnSort.stSortString,
        filter: newFilters,
        limit: this.limit,
        page: this.page,
        count,
        include: 'customer'
      });

      if (this.page == 1) {
        Ember.set(this, 'recordCount', _lodash.default.get(customerRefundReceipts, 'meta.record_count', 0));
      }

      return customerRefundReceipts;
    } catch (error) {
      (false && Ember.warn('failed in customerRefundReceiptsTask: ' + error, {
        id: 'company.accounting.payments.customerRefundReceiptsTask'
      }));
    }
  }), _dec25 = Ember._action, _dec26 = Ember._action, _dec27 = Ember._action, _dec28 = Ember._action, _dec29 = Ember._action, _dec30 = Ember._action, (_class = (_temp = class InvoicesBase extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "grants", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      _initializerDefineProperty(this, "router", _descriptor5, this);

      _initializerDefineProperty(this, "quickbooksService", _descriptor6, this);

      _initializerDefineProperty(this, "stFiltersService", _descriptor7, this);

      _initializerDefineProperty(this, "tagsService", _descriptor8, this);

      _initializerDefineProperty(this, "screen", _descriptor9, this);

      _initializerDefineProperty(this, "limit", _descriptor10, this);

      _initializerDefineProperty(this, "page", _descriptor11, this);

      _initializerDefineProperty(this, "createdDateFrom", _descriptor12, this);

      _initializerDefineProperty(this, "createdDateTo", _descriptor13, this);

      _initializerDefineProperty(this, "dueDate", _descriptor14, this);

      _initializerDefineProperty(this, "isPaid", _descriptor15, this);

      _initializerDefineProperty(this, "customerName", _descriptor16, this);

      _initializerDefineProperty(this, "recordCount", _descriptor17, this);

      _initializerDefineProperty(this, "accountingSync", _descriptor18, this);

      _initializerDefineProperty(this, "accountingSyncFormMode", _descriptor19, this);

      _initializerDefineProperty(this, "accountingSyncFormStarted", _descriptor20, this);

      _initializerDefineProperty(this, "appliedFiltersCount", _descriptor21, this);

      _initializerDefineProperty(this, "tagFilter", _descriptor22, this);

      _initializerDefineProperty(this, "initTask", _descriptor23, this);

      _initializerDefineProperty(this, "customerRefundReceiptsTask", _descriptor24, this);

      this.TableColumnSort = new _tableColumnSort.default(...arguments);
      Ember.set(this, 'tagFilter', this.tagsService.tagFilter());
      Ember.set(this, 'customerTagFilter', this.tagsService.tagFilter());
      this.initTask.perform();
    }

    get accountingSyncFormSteps() {
      var intl = this.intl;
      return Ember.Object.create({
        accountingSyncStep: Ember.Object.create({
          stepNameKey: 'accountingSyncStep',
          title: intl.t('pages.administration.integrations.accountingProvidersSync.send_refund_receipts'),
          stepComponentPath: 'multi-step-form/forms/accounting/accounting-refund-receipts-sync-quickbooks'
        })
      });
    }

    get customerRefundReceipts() {
      return this.customerRefundReceiptsTask.perform();
    }

    updateCustomerRefundReceiptsList() {
      Ember.notifyPropertyChange(this, 'customerRefundReceiptsTask');
    }

    filterSearch() {
      Ember.set(this, 'page', 1);
      Ember.notifyPropertyChange(this, 'customerRefundReceiptsTask');
    }

    clearInvoicesFilter() {
      Ember.setProperties(this, {
        createdDateFrom: null,
        createdDateTo: null,
        tagFilter: this.tagsService.tagFilter(),
        customerTagFilter: this.tagsService.tagFilter(),
        page: 1
      });
      Ember.notifyPropertyChange(this, 'customerRefundReceiptsTask');
    }

    sortList(column, ascending) {
      this.TableColumnSort.sortPaginatedList(column, ascending);
      Ember.notifyPropertyChange(this, 'customerRefundReceiptsTask');
    }

    mobileSortList(column) {
      this.TableColumnSort.sortMobilePaginatedList(column.value);
      Ember.set(this, 'stSortCurrentSortColumn', null);
      Ember.set(this, 'stSortAscending', null);
      Ember.notifyPropertyChange(this, 'customerRefundReceiptsTask');
    }

    transitionToSubroute(path) {
      this.router.transitionTo('accounting.refund-receipts.edit-refund-receipt', path);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "quickbooksService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "stFiltersService", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "tagsService", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "screen", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "limit", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 50;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "createdDateFrom", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "createdDateTo", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "dueDate", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "isPaid", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "customerName", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "recordCount", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "accountingSync", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "accountingSyncFormMode", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'edit';
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "accountingSyncFormStarted", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "appliedFiltersCount", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "tagFilter", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "initTask", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "customerRefundReceiptsTask", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateCustomerRefundReceiptsList", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "updateCustomerRefundReceiptsList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterSearch", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "filterSearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearInvoicesFilter", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "clearInvoicesFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortList", [_dec28], Object.getOwnPropertyDescriptor(_class.prototype, "sortList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mobileSortList", [_dec29], Object.getOwnPropertyDescriptor(_class.prototype, "mobileSortList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transitionToSubroute", [_dec30], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToSubroute"), _class.prototype)), _class));
  _exports.default = InvoicesBase;
});