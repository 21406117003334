define("securitytrax-ember-ui/mixins/customer-task-mixin", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixed-in-functions/grouped-property-sections", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _emberConcurrency, _groupedPropertySections, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    emptyGroups: undefined,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        emptyGroups: false
      });
    },

    groupedTasksTask: (0, _emberConcurrency.task)(function* () {
      var intl = this.intl;
      var sortedHashData = yield (0, _emberConcurrency.hash)({
        sortedCustomerTasks: this.sortedCustomerTasks,
        currentGroupedTasks: this.groupedTasks
      });
      this.set('sortedTasksCount', sortedHashData.sortedCustomerTasks.length);
      var groupedTasks = Ember.A([Ember.Object.create({
        name: intl.t('pages.customers.notes.overdue'),
        collapsable: true,
        collapsed: sortedHashData.currentGroupedTasks ? sortedHashData.currentGroupedTasks[0].collapsed : false,
        collapseIconName: 'icn-chevron-up',
        icon: 'icn-warning',
        sections: (0, _groupedPropertySections.default)(),
        items: sortedHashData.sortedCustomerTasks.filter(task => {
          var isClosed = task.get('isClosed');
          var isOverdue = task.get('isOverdue');
          return !isClosed && isOverdue;
        })
      }), Ember.Object.create({
        name: intl.t('pages.customers.notes.dueToday'),
        collapsable: true,
        collapsed: sortedHashData.currentGroupedTasks ? sortedHashData.currentGroupedTasks[1].collapsed : false,
        collapseIconName: 'icn-chevron-up',
        icon: 'icn-timer',
        sections: (0, _groupedPropertySections.default)(),
        items: sortedHashData.sortedCustomerTasks.filter(task => {
          var isClosed = task.get('isClosed');
          var isDueLaterToday = task.get('isDueLaterToday');
          return isDueLaterToday && !isClosed;
        })
      }), Ember.Object.create({
        name: intl.t('pages.customers.notes.othersInProgress'),
        collapsable: true,
        collapsed: sortedHashData.currentGroupedTasks ? sortedHashData.currentGroupedTasks[2].collapsed : false,
        collapseIconName: 'icn-chevron-up',
        icon: 'icn-in-progress',
        sections: (0, _groupedPropertySections.default)(),
        items: sortedHashData.sortedCustomerTasks.filter(task => {
          var isClosed = task.get('isClosed');
          var isOverdue = task.get('isOverdue');
          var isDueLaterToday = task.get('isDueLaterToday');
          return !isClosed && !isOverdue && !isDueLaterToday;
        })
      })]);

      if (this.addCompleted === true) {
        groupedTasks.pushObject(Ember.Object.create({
          name: this.completedText || intl.t('pages.customers.notes.completed'),
          collapsable: true,
          collapsed: sortedHashData.currentGroupedTasks ? sortedHashData.currentGroupedTasks[3].collapsed : true,
          collapseIconName: 'icn-chevron-down',
          icon: 'icn-circle-check',
          sections: (0, _groupedPropertySections.default)(),
          items: sortedHashData.sortedCustomerTasks.filter(task => {
            return task.get('isClosed');
          })
        }));
      }

      var tasksPresent = false;

      for (var i = 0; i < groupedTasks.length; i++) {
        if (groupedTasks[i].items.length > 0) {
          tasksPresent = true;
          break;
        }
      }

      this.set('emptyGroups', !tasksPresent);
      return groupedTasks;
    })
  });

  _exports.default = _default;
});