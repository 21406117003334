define("securitytrax-ember-ui/components/lead/dashboard/dashboard-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Mwdg0rE+",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"cell dashboard-card \",[34,0]]]],[12],[2,\"\\n    \"],[1,[30,[36,3],[[35,2,[\"cardComponent\"]]],[[\"customer\"],[[35,1]]]]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"dashboard-card--move-handle\"],[12],[2,\"\\n        \"],[1,[30,[36,4],[\"icn-circle-move\"],[[\"class\"],[\"dashboard-card--move-handle-svg\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"editClass\",\"customer\",\"card\",\"component\",\"svg-icon\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/lead/dashboard/dashboard-card/template.hbs"
    }
  });

  _exports.default = _default;
});