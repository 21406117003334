define("securitytrax-ember-ui/models/base_models/interactive-services-provider-config", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    adcLeads: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    adcEbi: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    adcCustServiceSchedules: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    adcCustUpsellSchedules: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    adcCentralStation: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    adcBillingIntegration: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    adcRiskScore: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    adcTechScheduleSendToDealerTools: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    branchId: (0, _model.attr)('number', {
      'readOnly': false
    }),
    cacheEnabled: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    clientId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    clientSecret: (0, _model.attr)('string', {
      'readOnly': false
    }),
    dealerId: (0, _model.attr)('number', {
      'readOnly': false
    }),
    development: (0, _model.attr)('json', {
      'readOnly': false
    }),
    disableEquipmentSync: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    enabled: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    environment: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['production', 'development', 'test']
    }),
    referralLeadCompanyId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    referralLeadCompanyCampaignId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    selfCreatedLeadCompanyId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    selfCreatedLeadCompanyCampaignId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    dealerWebsiteLeadCompanyId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    dealerWebsiteLeadCompanyCampaignId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    referAFriendLeadCompanyId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    referAFriendLeadCompanyCampaignId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    password: (0, _model.attr)('string', {
      'readOnly': false
    }),
    serviceRequestUpsellTechId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    serviceRequestServiceTechId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    serviceRequestBuilderProgram: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    twoFactorDeviceId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    username: (0, _model.attr)('string', {
      'readOnly': false
    })
  });

  _exports.default = _default;
});