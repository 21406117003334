define("securitytrax-ember-ui/components/lists/data-list/column-formatters/icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bT1j/c4+",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[30,[36,3],[[35,0]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"icon\"],[12],[1,[30,[36,2],[[30,[36,1],[[35,0]],null]],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"st-svg-loader\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"iconName\",\"await\",\"svg-icon\",\"is-fulfilled\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/lists/data-list/column-formatters/icon/template.hbs"
    }
  });

  _exports.default = _default;
});