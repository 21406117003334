define("securitytrax-ember-ui/components/multi-step-form/forms/administration/users/my-inventory/purchase-orders/re-usable-forms/purchase-order-information/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    retrievalMethodIdObserver: Ember.observer('multiStepForm.formCopiedModels.equipmentPurchaseOrder.model.retrievalMethodId', 'branchNumbers.length', function () {
      var retrievalMethodId = this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseOrder.model.retrievalMethodId');
      var multiStepForm = this.multiStepForm;

      if (retrievalMethodId >= 200 && retrievalMethodId < 300 && this.get('branchNumbers.length') > 0) {
        multiStepForm.formCopiedModelsSetVal('equipmentPurchaseOrder.model.branchNumber', this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseOrder.model.equipmentVendor.defaultBranchNumber'));
      }
    }),
    initTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true);
      var parentEquipmentPR = yield this.multiStepForm.formOriginalModelsGetVal('equipmentPurchaseOrder.model.parentEquipmentPurchaseRequisition');

      if (parentEquipmentPR) {
        Ember.set(this, 'parentPRComments', parentEquipmentPR.comments);
      }
    }).on('init')
  });

  _exports.default = _default;
});