define("securitytrax-ember-ui/components/customer/work-orders/component", ["exports", "@ember-data/model", "securitytrax-ember-ui/components/multi-step-form/forms/work-orders/work-orders-base", "securitytrax-ember-ui/mixins/switchable-form-step", "securitytrax-ember-ui/mixins/set-user", "ember-concurrency", "lodash", "moment"], function (_exports, _model, _workOrdersBase, _switchableFormStep, _setUser, _emberConcurrency, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _workOrdersBase.default.extend(_switchableFormStep.default, _setUser.default, {
    stNotificationManager: Ember.inject.service(),
    userService: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    homeDashboard: Ember.inject.service('events/home-dashboard'),
    workOrderEvent: Ember.inject.service('events/work-order-event'),
    loaded: false,
    toAppointment: false,
    toGeneratePOs: false,
    confirmDeleteModalOpen: false,
    workOrderTemplateChoices: undefined,
    selectedTemplateId: null,
    defaults: undefined,
    installUser: undefined,
    installManager: undefined,
    workOrderTemplates: undefined,
    workOrderTemplatesTask: (0, _emberConcurrency.task)(function* () {
      return yield this.store.query('template', {
        filter: 'template_type = 1 and active = true',
        include: 'template_type.template_type_columns,template_field_defaults'
      });
    }),
    userOptions: Ember.computed(function () {
      return this.userService.getUsersList('work_order_assignee');
    }),
    noteTypes: Ember.computed(function () {
      return this.noteTypesTask.perform();
    }),
    noteTypesTask: (0, _emberConcurrency.task)(function* () {
      return yield this.store.findAll('noteType');
    }),
    selectedTemplate: Ember.computed('selectedTemplateId', function () {
      return this.workOrderTemplates.findBy('id', this.selectedTemplateId);
    }),
    workOrderTypes: Ember.computed(function () {
      return this.workOrderTypesTask.perform();
    }),
    workOrderTypesTask: (0, _emberConcurrency.task)(function* () {
      return yield this.store.findAll('workOrderType');
    }),
    statusOptions: Ember.computed(function () {
      var intl = this.intl;
      return [{
        value: false,
        name: intl.t('pages.customers.tasks.status.open')
      }, {
        value: true,
        name: intl.t('pages.customers.tasks.status.closed')
      }];
    }),
    initFormData: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = this.multiStepForm;
      var noteTypes = yield this.noteTypes;
      yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);
      var responseMeta = multiStepForm.formCopiedModelsGetVal('workOrder.model.responseMeta');

      if (this.isNewRecord) {
        yield this.getWorkOrderTemplatesTask.perform();
        multiStepForm.formCopiedModelsSetVal('workOrder.model.noteType', noteTypes.findBy('id', '2'));
      } else {
        yield multiStepForm.setupCopyModels.perform(); // Required if user clicks back button to get back to WorkOrder on customer create
      }

      if (responseMeta && multiStepForm.formCopiedModelsGetVal('workOrder.model.responseMeta.number_of_appointments') === '') {
        // Need this if user refreshes on modal open, don't know why it doesn't stay on the Original Model in this case
        multiStepForm.formOriginalModelsSetVal('workOrder.model.responseMeta.number_of_appointments', responseMeta.number_of_appointments);
        multiStepForm.formOriginalModelsSetVal('workOrder.model.responseMeta.total_duration_of_appointments', responseMeta.total_duration_of_appointments);
      }

      if (this.onInit) {
        this.onInit.call(this, this.saveWorkOrderTask);
      }

      this.generateStepButtons();
      this.set('loaded', true);
    }).on('init'),
    bulkActionButtons: Ember.computed('customerEquipment.[]', 'grants.userGrants', function () {
      var buttons = [];
      var grants = this.grants;
      var canViewEquipment = grants.getIndividualGrant('work_orders.view');
      var equipmentListLength;

      try {
        equipmentListLength = this.get('customerEquipment.length');
      } catch (error) {
        equipmentListLength = 0;
      }

      if (canViewEquipment && equipmentListLength > 0) {
        return [{
          label: this.intl.t('pages.customers.workOrders.details.install'),
          bulkAction: true
        }, {
          label: this.intl.t('pages.customers.workOrders.details.remove'),
          bulkAction: true
        }, {
          label: this.intl.t('pages.customers.workOrders.details.service'),
          bulkAction: true
        }];
      }

      return buttons;
    }),
    getWorkOrderTemplatesTask: (0, _emberConcurrency.task)(function* () {
      var choices = Ember.A([]);
      var hashedData = yield (0, _emberConcurrency.hash)({
        workOrderTemplates: this.workOrderTemplatesTask.perform(),
        noteTypes: this.noteTypes,
        workOrderTypes: this.workOrderTypes,
        users: this.userService.getUsersList('tech', this.customer)
      });

      if (hashedData.workOrderTemplates && hashedData.workOrderTemplates.get('length') > 0) {
        var systemInfo = yield this.get('customer.customerSystemInformation');
        var installCalendarEvent = systemInfo ? yield systemInfo.get('installCalendarEvent') : null;
        var calendarUser = installCalendarEvent ? yield installCalendarEvent.get('calendarUser') : null;
        var user = calendarUser ? yield calendarUser.get('user') : null;
        var directoryUser = user ? yield this.store.findRecord('directoryUser', user.get('id')) : null;
        this.set('installUser', user);
        this.set('installManager', directoryUser ? yield directoryUser.get('orgChartParent') : null);

        for (var i = 0; i < hashedData.workOrderTemplates.get('length'); i++) {
          var template = hashedData.workOrderTemplates.objectAt(i);

          if (template.get('active')) {
            var fieldDefaults = yield template.get('sortedTemplateFieldDefaults');
            var equipmentOptions = fieldDefaults[3] && fieldDefaults[3].get('templateTypeColumn.options') ? fieldDefaults[3].get('templateTypeColumn.options') : [];
            var assignedOptions = fieldDefaults[4] && fieldDefaults[4].get('templateTypeColumn.options') ? fieldDefaults[4].get('templateTypeColumn.options') : [];
            var assignedUser = hashedData.users.findBy('id', fieldDefaults[4] ? fieldDefaults[4].get('value') : null);
            choices.pushObject(Ember.Object.create({
              packageName: template.get('name'),
              packageId: template.get('id'),
              packageContent: [{
                label: this.intl.t('pages.customers.workOrders.details.taskType'),
                description: fieldDefaults[0] && fieldDefaults[0].get('value') ? hashedData.noteTypes.findBy('id', fieldDefaults[0].get('value')).get('name') : ''
              }, {
                label: this.intl.t('pages.customers.workOrders.details.workOrderType'),
                description: fieldDefaults[1] && fieldDefaults[1].get('value') ? hashedData.workOrderTypes.findBy('id', fieldDefaults[1].get('value')).get('name') : ''
              }, {
                label: this.intl.t('pages.customers.notes.subject.label'),
                description: fieldDefaults[2] && fieldDefaults[2].get('value') ? fieldDefaults[2].get('value') : ''
              }, {
                label: this.intl.t('pages.customers.equipment.title'),
                description: fieldDefaults[3] && fieldDefaults[3].get('value') ? _lodash.default.invert(equipmentOptions)[fieldDefaults[3].get('value')] : ''
              }, {
                label: this.intl.t('pages.customers.workOrders.details.assignedTo'),
                description: fieldDefaults[4] && fieldDefaults[4].get('value') ? _lodash.default.invert(assignedOptions)[fieldDefaults[4].get('value')] || (assignedUser ? assignedUser.name : '') : ''
              }]
            }));
          }
        }
      }

      this.set('workOrderTemplates', hashedData.workOrderTemplates);
      this.set('workOrderTemplateChoices', choices);
    }),
    saveWorkOrderTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise, close = undefined) {
      var startedAsNewRecord = this.formMode === 'create';
      var refreshWorkOrders = false;
      var store = this.store;
      var copiedWorkOrder = multiStepForm.formCopiedModelsGetVal('workOrder.model');
      var originalTaskEquipments = yield this.multiStepForm.formOriginalModelsGetVal('workOrder.model.workOrderEquipment');
      var customerEquipmentObjectGroups = yield this.groupedEquipment;
      var copiedWorkOrderEquipments = yield copiedWorkOrder.get('workOrderEquipment');
      var formattedObjects = Ember.A();
      var workOrderAction1 = yield store.findRecord('workOrderAction', 1);
      var workOrderAction2 = yield store.findRecord('workOrderAction', 2);
      var workOrderAction3 = yield store.findRecord('workOrderAction', 3);
      var toSaveWorkOrder;

      if (copiedWorkOrder.get('assignedUser.id') && !(copiedWorkOrder.get('assignedUser') instanceof _model.default)) {
        var user = yield store.findRecord('user', copiedWorkOrder.get('assignedUser.id'));
        copiedWorkOrder.set('assignedUser', user);
      }

      copiedWorkOrder.set('isWorkOrder', true); //Only used for validation now

      copiedWorkOrder.set('customer', this.customer); // format workOrder for validation

      formattedObjects.pushObject(Ember.Object.create({
        modelName: 'workOrder',
        model: copiedWorkOrder,
        extraValidationData: {
          validationMessages: {
            noteType: '^' + this.intl.t("pages.customers.workOrders.details.validations.taskType")
          },
          noValidate: ['customerTask-assignedUser']
        },
        filePath: 'customer-task' //piggybacks off the customer-task validations

      }));
      var toCreateObjects = yield multiStepForm.validateCreateList.perform(formattedObjects);

      try {
        var toCreateWorkOrder = toCreateObjects['workOrder'][0].data;
        toCreateWorkOrder.customer = this.customer; // update existing taskEquipments or create new ones

        customerEquipmentObjectGroups.forEach(equipmentGroup => {
          equipmentGroup.items.forEach(equipmentObject => {
            var copiedWorkOrderEquipment = equipmentObject.copiedWorkOrderEquipment;

            if (equipmentObject.selectedButton !== null) {
              var workOrderAction;

              switch (equipmentObject.get('selectedButton')) {
                case 0:
                  workOrderAction = workOrderAction1;
                  break;

                case 1:
                  workOrderAction = workOrderAction3;
                  break;

                case 2:
                  workOrderAction = workOrderAction2;
                  break;
              }

              if (copiedWorkOrderEquipment) {
                // existing equipmentTask
                copiedWorkOrderEquipment.set('workOrderAction', workOrderAction);
                var originalTaskEquipment = originalTaskEquipments.findBy('id', copiedWorkOrderEquipment.id);
                originalTaskEquipment.deepSync({
                  relationshipMap: {
                    relationships: {
                      customerEquipment: {
                        readOnly: true
                      },
                      workOrderAction: {
                        readOnly: true
                      },
                      originalOptions: {
                        onBeforeSync: multiStepForm.onBeforeSync,
                        onAfterSync: multiStepForm.onAfterSync
                      }
                    }
                  },
                  copiedObject: copiedWorkOrderEquipment
                });
              } else {
                // new equipmentTask
                var newTaskEquipment = this.store.createRecord('workOrderEquipment', {
                  customerEquipment: equipmentObject.customerEquipment,
                  workOrderAction: workOrderAction
                });

                if (toCreateWorkOrder.id) {
                  copiedWorkOrderEquipments.pushObject(equipmentObject);
                  newTaskEquipment.set('workOrder', this.multiStepForm.formOriginalModelsGetVal('workOrder.model'));
                } else {
                  toCreateWorkOrder.workOrderEquipment.pushObject(newTaskEquipment);
                }
              }
            } else {
              // If existing equipmentTask, we need to delete it from the store because the action has been removed
              if (copiedWorkOrderEquipment) {
                originalTaskEquipments.findBy('id', copiedWorkOrderEquipment.id).deleteRecord();
              }
            }
          });
        }); // Sync or create workOrder

        if (toCreateWorkOrder.id) {
          var originalWorkOrder = this.multiStepForm.formOriginalModelsGetVal('workOrder.model');
          var options = {
            relationships: {
              customer: {
                doValidate: false,
                doSave: false
              },
              noteType: {
                doValidate: false,
                doSave: false
              },
              workOrderType: {
                doValidate: false,
                doSave: false
              },
              assignedUser: {
                doValidate: false,
                doSave: false
              }
            }
          };

          if (originalWorkOrder.isClosed !== copiedWorkOrder.isClosed) {
            refreshWorkOrders = true;
          }

          if (originalWorkOrder.dueDate !== copiedWorkOrder.dueDate) {
            refreshWorkOrders = true;
          }

          toSaveWorkOrder = yield originalWorkOrder.deepSync({
            relationshipMap: options,
            copiedObject: copiedWorkOrder,
            originalOptions: {
              onBeforeSync: multiStepForm.onBeforeSync,
              onAfterSync: multiStepForm.onAfterSync
            }
          });
        } else {
          toSaveWorkOrder = yield this.store.createRecord('workOrder', toCreateWorkOrder);
          refreshWorkOrders = true;
        } // save workOrder


        var savedWorkOrder = yield toSaveWorkOrder.save();

        if (this.doneAction) {
          this.multiStepForm.formOriginalModelsSetVal('workOrder.model', savedWorkOrder);
          this.doneAction.call(this, multiStepForm, close);
        } else {
          //workOrder page
          yield savedWorkOrder.get('workOrderEquipment').reload();
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
          buttonPromise.then(() => {
            this.set('workOrder', savedWorkOrder);

            if (refreshWorkOrders) {
              this.workOrderEvent.trigger('refresh-my-work-orders');
            }

            this.homeDashboard.trigger('refresh-work-orders-due-today');

            if (startedAsNewRecord) {
              this.set('formMode', 'edit');
              this.router.transitionTo("/customers/".concat(this.customer.id, "/work-orders/").concat(savedWorkOrder.id, "/edit-create-work-order"));
            }

            if (this.toAppointment === true) {
              this.multiStepForm.currentStepNameKeySetVal('addAppointment');
            } else if (this.toGeneratePOs === true) {
              this.multiStepForm.currentStepNameKeySetVal('generatePOs');
            } else {
              this.initFormData.perform();
            }
          });
        }
      } catch (response) {
        this.stNotificationManager.addErrorMessages(response, {
          notificationNamespace: 'modal'
        });
        throw response;
      }
    }).drop().maxConcurrency(1),
    bulkToggleTask: (0, _emberConcurrency.task)(function* (selected, bulkTurnOn) {
      var groupedEquipment = yield this.groupedEquipment;

      if (groupedEquipment) {
        groupedEquipment.map(group => {
          group.items.map(item => {
            if (selected === null) {
              item.set('selectedButton', null);
            } else {
              if (bulkTurnOn && !item['complexButtonChoices'][selected]['disabled']) {
                item.set('selectedButton', selected);
              } else if (item['complexButtonChoices'][selected]['disabled']) {//dont do anything if button is disabled, otherwise it may deselect another option
              } else {
                //deselect
                item.set('selectedButton', null);
              }
            }
          });
        });
      }
    }),
    setDefaultFieldsTask: (0, _emberConcurrency.task)(function* () {
      try {
        var multiStepForm = this.multiStepForm;
        var defaults = yield this.defaults; //Clear out bulk equipment by default

        this.bulkToggleTask.perform(null, true);

        if (!defaults) {
          multiStepForm.formCopiedModelsSetVal('workOrder.model.note', null);
          multiStepForm.formCopiedModelsSetVal('workOrder.model.noteType', null);
          multiStepForm.formCopiedModelsSetVal('workOrder.model.workOrderType', null);
          multiStepForm.formCopiedModelsSetVal('workOrder.model.subject', null);
          multiStepForm.formCopiedModelsSetVal('workOrder.model.isClosed', null);
          multiStepForm.formCopiedModelsSetVal('workOrder.model.assignedUser', null);
        } else {
          var hashedData = yield (0, _emberConcurrency.hash)({
            noteTypes: this.noteTypes,
            workOrderTypes: this.workOrderTypes,
            users: this.userOptions
          });

          if (defaults && defaults[3]) {
            yield this.bulkToggleTask.perform(defaults[3].get('value') === 'install_all' ? 0 : defaults[3].get('value') === 'remove_all' ? 1 : defaults[3].get('value') === 'service_all' ? 2 : null, true);
          }

          if (defaults && defaults[4]) {
            if (defaults[4].get('value') === 'install_technician') {
              multiStepForm.formCopiedModelsSetVal('workOrder.model.assignedUser', this.installUser ? hashedData.users.findBy('id', this.installUser.get('id')) : null);
            } else if (defaults[4].get('value') === 'install_tech_manager') {
              multiStepForm.formCopiedModelsSetVal('workOrder.model.assignedUser', this.installManager ? hashedData.users.findBy('id', this.installManager.get('id')) : null);
            } else {
              multiStepForm.formCopiedModelsSetVal('workOrder.model.assignedUser', hashedData.users.findBy('id', defaults[4].get('value')));
            }

            var activeTemplate = this.workOrderTemplates.findBy('id', this.get('selectedTemplate.id'));

            if (multiStepForm.formCopiedModelsGetVal('workOrder.model.assignedUser.id') > 0) {
              yield this.setUser.perform(multiStepForm.formCopiedModelsGetVal('workOrder.model'), 'assignedUser', multiStepForm.formCopiedModelsGetVal('workOrder.model.assignedUser'));
            }

            multiStepForm.formCopiedModelsSetVal('workOrder.model.note', activeTemplate.get('description'));
            multiStepForm.formCopiedModelsSetVal('workOrder.model.noteType', hashedData.noteTypes.findBy('id', defaults && defaults[0] ? defaults[0].get('value') : '2'));
            multiStepForm.formCopiedModelsSetVal('workOrder.model.workOrderType', hashedData.workOrderTypes.findBy('id', defaults && defaults[1] ? defaults[1].get('value') : null));
            multiStepForm.formCopiedModelsSetVal('workOrder.model.subject', defaults && defaults[2] ? defaults[2].get('value') : null);
            multiStepForm.formCopiedModelsSetVal('workOrder.model.isClosed', false);
          }
        }
      } catch (err) {
        (false && Ember.warn(err, {
          id: 'app.workOrder.setDefaultFieldsTask'
        }));
      }
    }),
    deleteWorkOrder: (0, _emberConcurrency.task)(function* (buttonPromise) {
      try {
        var multiStepForm = this.multiStepForm;
        yield this.workOrder.destroyRecordWithRollbackTask.perform();
        buttonPromise.then(() => {
          this.set('confirmDeleteModalOpen', false);
          multiStepForm.formStartedSetVal(false);
          this.workOrderEvent.trigger('refresh-my-work-orders');
          this.homeDashboard.trigger('refresh-work-orders-due-today');

          if (this.transitionBackToParent && typeof this.transitionBackToParent === 'function') {
            this.transitionBackToParent();
          }

          this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
        });
      } catch (err) {
        (false && Ember.warn('deleteWorkOrder task failed' + err, {
          id: 'customers.workOrders.delete'
        }));
        throw err;
      }
    }),

    generateStepButtons() {
      var multiStepForm = this.multiStepForm;
      var formMode = this.formMode;
      var grants = this.grants;
      var buttonsObject = [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.saveWorkOrderTask.perform(multiStepForm, buttonPromise);
        },
        classNamesString: "st-btn st-btn-primary save modal-action-submit save-work-order-btn ".concat(formMode === 'view' ? 'hidden' : '')
      }];

      if (grants.getIndividualGrant('equipment_purchase_orders.create')) {
        buttonsObject[0].positionOptions = 'up left';
        buttonsObject[0].additionalClickActions = [{
          label: this.intl.t('common.actions.saveAndGeneratePOs'),
          action: buttonPromise => {
            this.set('toGeneratePOs', true);
            return this.saveWorkOrderTask.perform(multiStepForm, buttonPromise);
          },
          classNamesString: 'dropdown-save-generate-po'
        }];
      }

      if (grants.getIndividualGrant('calendar_events.create')) {
        if (!this.hideAddEvent) {
          var scheduleStep = {
            label: this.intl.t('common.actions.saveAndSchedule'),
            action: buttonPromise => {
              this.set('toAppointment', true);
              return this.saveWorkOrderTask.perform(multiStepForm, buttonPromise);
            },
            classNamesString: 'dropdown-save-schedule'
          };

          if (!grants.getIndividualGrant('equipment_purchase_orders.create')) {
            buttonsObject[0].positionOptions = 'up left';
            buttonsObject[0].additionalClickActions = [scheduleStep];
          } else {
            buttonsObject[0].additionalClickActions.push(scheduleStep);
          }
        }
      }

      if (!this.isNewRecord && grants.getIndividualGrant('work_orders.delete')) {
        buttonsObject.push({
          text: this.intl.t('common.actions.delete'),
          click: () => {
            this.set('confirmDeleteModalOpen', true);
          },
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
        });
      }

      multiStepForm.registerStepData(this.singleStep, 'buttons', this.stepButtons || buttonsObject);
    },

    calendarEvents: Ember.computed('multiStepForm.formCopiedModels.workOrder.model.calendarEvents', function () {
      return this.calendarEventsTask.perform();
    }),
    calendarEventsTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true);
      var calendarEvents = this.multiStepForm.formCopiedModelsGetVal('workOrder.model.calendarEvents');

      if (calendarEvents) {
        var filteredCalendarEvents = calendarEvents.filter(event => {
          return (0, _moment.default)(event.startTime).unix() >= (0, _moment.default)().unix();
        });
        return filteredCalendarEvents;
      } else {
        return [];
      }
    }),
    actions: {
      fillWithTemplate() {
        this.set('defaults', this.get('selectedTemplate.sortedTemplateFieldDefaults'));
        this.setDefaultFieldsTask.perform();
      }

    }
  });

  _exports.default = _default;
});