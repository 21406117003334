define("securitytrax-ember-ui/users/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    intl: Ember.inject.service(),
    recentSearchesService: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['users.view']);
    },

    model({
      id
    }) {
      var user = this.store.findRecord('user', id).catch(() => {
        this.transitionTo('user-list');
      });
      this.recentSearchesService.addRecent('recentUsers', id);
      return user;
    },

    setupController(controller, model) {
      this._super(...arguments);

      var breadCrumbs = [{
        name: this.intl.t('pages.users.user.plural'),
        last: true,
        previousLink: 'administration.human-resources',
        link: 'administration.human-resources.user-list'
      }];
      var breadCrumbData = {
        title: this.intl.t('pages.administration.human_resources.title'),
        titleLink: 'administration.human-resources'
      };

      if ('id' in model) {
        breadCrumbs[0].last = false;
        breadCrumbs.push({
          name: model.get('flname') + ' (' + this.intl.t('pages.users.id') + ': ' + model.get('id') + ')',
          last: true,
          previousLink: 'administration.human-resources.user-list'
        });
      }

      controller.set('user', model);
      controller.set('breadCrumbs', breadCrumbs);
      controller.set('breadCrumbData', breadCrumbData);
    }

  });

  _exports.default = _default;
});