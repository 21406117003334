define("securitytrax-ember-ui/models/equipment-to-recipient-template", ["exports", "securitytrax-ember-ui/models/base_models/equipment-to-recipient-template"], function (_exports, _equipmentToRecipientTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _equipmentToRecipientTemplate.default.extend({
    intl: Ember.inject.service(),
    inventorySinkNameDescription: Ember.computed('name', function () {
      return this.name + ' (' + this.intl.t('pages.administration.integrations.electronic_document_providers.docusign.template') + ')';
    }),
    filteredEquipmentToRecipients: Ember.computed('equipmentToRecipients.[]', function () {
      return this.equipmentToRecipients.filter(eqToRec => {
        return eqToRec.get('equipment.active') && (eqToRec.get('base') > 0 || eqToRec.get('min') > 0 || eqToRec.get('equipmentVendor.id') > 0);
      });
    })
  });

  _exports.default = _default;
});