define("securitytrax-ember-ui/components/org-chart/user-chain/uncategorized-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6dbNcYOw",
    "block": "{\"symbols\":[\"data\"],\"statements\":[[6,[37,7],null,[[\"class\",\"route\",\"model\"],[[30,[36,6],[[35,5],\" st-media-object__link-wrap st-media-object__link-uncat\"],null],\"administration.org-chart.unassigned\",[35,4,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"st-media-object  st-media-object--landscape st-media-object__link \"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"st-media-object__thumbnail \"],[12],[2,\"\\n            \"],[10,\"span\"],[14,0,\"record-count\"],[12],[2,\"\\n                \"],[8,\"html/st-await\",[],[[\"@useSpinner\",\"@size\",\"@promises\"],[true,\"x-small\",[30,[36,1],null,[[\"uncategorizedUsersCount\"],[[35,0]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n                    \"],[1,[32,1,[\"resolvedPromises\",\"uncategorizedUsersCount\"]]],[2,\"\\n                \"]],\"parameters\":[1]}]]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"st-media-object__content\"],[12],[2,\"\\n            \"],[10,\"span\"],[14,0,\"st-media-object__title orientation\"],[12],[1,[30,[36,2],[\"features.org-chart.common.uncategorized_users\"],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[1,[30,[36,3],[\"icn-chevron-right\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"uncategorizedUsersCount\",\"hash\",\"t\",\"svg-icon\",\"userHierarchyTree\",\"unassignedClass\",\"concat\",\"link-to\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/org-chart/user-chain/uncategorized-link/template.hbs"
    }
  });

  _exports.default = _default;
});