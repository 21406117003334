define("securitytrax-ember-ui/services/profile", ["exports", "securitytrax-ember-ui/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    get() {
      return _environment.default['securitytrax-ember-ui']['profile'];
    },

    getHost() {
      return window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '/';
    },

    getFullIndexUri() {
      return this.getHost() + this.get() + '/';
    }

  });

  _exports.default = _default;
});