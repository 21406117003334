define("securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/adc/customer-information/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/adc/adc-base", "ember-concurrency"], function (_exports, _adcBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adcBase.default.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    message: undefined,
    confirmedAction: undefined,
    buttonText: undefined,
    actionsMenuIsOpen: false,

    init() {
      this._super(...arguments);

      this.setLockoutEnabledTask.perform();
    },

    showRiskScore: Ember.computed(function () {
      return this.customer.showRiskScoreTask.perform();
    }),
    dynamicMenuItems: Ember.computed('lockoutEnabled', function () {
      return Ember.A([{
        label: this.intl.t('pages.customers.system.interactiveServicesProvider.swapModem.title'),
        action: () => {
          this.set('actionsMenuIsOpen', false);
          this.set('swapModemFormStarted', true);
          this.set('swapModemFirstStep', 'swapModemSingleStep');
        }
      }, {
        label: this.intl.t('pages.customers.system.interactiveServicesProvider.applyPanelTemplate.title'),
        action: () => {
          this.set('actionsMenuIsOpen', false);
          this.set('applyPanelTemplateFormStarted', true);
          this.set('applyPanelTemplateFirstStep', 'applyPanelTemplateSingleStep');
        }
      }, {
        label: this.lockoutEnabled ? this.intl.t('pages.customers.system.interactiveServicesProvider.enableDisableLockout.disableLockout.title') : this.intl.t('pages.customers.system.interactiveServicesProvider.enableDisableLockout.enableLockout.title'),
        action: () => {
          this.set('actionsMenuIsOpen', false);
          this.set('enableDisableLockoutFormStarted', true);
          this.set('enableDisableLockoutFirstStep', 'enableDisableLockoutStep');
        }
      }, {
        label: this.intl.t('pages.customers.system.interactiveServicesProvider.loginInformation.emailWelcomeLetter'),
        action: () => {
          this.set('actionsMenuIsOpen', false);
          this.sendWelcomeLetterTask.perform();
        }
      }]);
    }),
    terminateAccount: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.ispService.get('invokeIspActionTask').perform({
          action: 'alarm_dot_com__terminateCustomer',
          model: this.customer,
          attributes: {}
        });
        this.systemInfo.reload();
        this.orderInfo.reload();
        this.set('orderInfo.interactiveServicesProvider', null);
        this.multiStepForm.formStartedSetVal(false);

        if (this.transitionBackToParent && typeof this.transitionBackToParent === 'function') {
          this.transitionBackToParent();
        }

        this.stNotificationManager.addSuccess(this.intl.t('common.notifications.terminate_success', {
          'type': 'Alarm.com'
        }));
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal2'
        });
        throw err;
      }
    }),
    unlinkAccount: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.ispService.get('invokeIspActionTask').perform({
          model: this.customer,
          action: 'alarm_dot_com__unlinkCustomer',
          attributes: {}
        });
        this.systemInfo.reload();
        this.multiStepForm.formStartedSetVal(false);

        if (this.transitionBackToParent && typeof this.transitionBackToParent === 'function') {
          this.transitionBackToParent();
        }

        this.stNotificationManager.addSuccess(this.intl.t('common.notifications.unlink_success', {
          'type': 'Alarm.com'
        }));
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal2'
        });
        throw err;
      }
    }),
    actions: {
      toggleActionsMenu() {
        this.toggleProperty('actionsMenuIsOpen');
      },

      confirmUnlinkAccount() {
        var intl = this.intl;
        this.set('message', intl.t('pages.customers.system.interactiveServicesProvider.customerInformation.unlinkConfirm'));
        this.set('buttonText', intl.t('pages.customers.system.interactiveServicesProvider.actions.unlink'));
        this.set('confirmedAction', 'unlinkAccount');
        this.set('confirmAdcActionFormStarted', true);
      },

      confirmTerminateAccount() {
        var intl = this.intl;
        this.set('message', intl.t('pages.customers.system.interactiveServicesProvider.customerInformation.terminateConfirm'));
        this.set('buttonText', intl.t('pages.customers.system.interactiveServicesProvider.actions.terminate'));
        this.set('confirmedAction', 'terminateAccount');
        this.set('confirmAdcActionFormStarted', true);
      },

      unlinkAccount() {
        return this.unlinkAccount.perform();
      },

      terminateAccount() {
        return this.terminateAccount.perform();
      }

    }
  });

  _exports.default = _default;
});