define("securitytrax-ember-ui/validations/test-copy-parent", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validationData = (0, _validate.default)(this.objectToJson(this), {
        firstName: {
          presence: {
            allowEmpty: false
          }
        },
        lastName: {
          presence: () => {
            if ('extraValidationData' in data && 'validateLastName' in data.extraValidationData) {
              return {
                allowEmpty: false
              };
            } else {
              return false;
            }
          }
        },
        testCopyBelongTo: {
          presence: () => {
            if ('extraValidationData' in data && 'validateCopyBelong' in data.extraValidationData) {
              return {
                allowEmpty: false
              };
            } else {
              return false;
            }
          }
        }
      });
      return this.outputFormatting(validationData, 'copyable');
    }

  });

  _exports.default = _default;
});