define("securitytrax-ember-ui/components/multi-step-form/forms/invoices/send-invoice/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H9Onr6HU",
    "block": "{\"symbols\":[\"data\",\"@multiStepForm\",\"@invoice\"],\"statements\":[[8,\"multi-step-form/progress-bar\",[],[[\"@config\",\"@modalButtons\"],[[32,0,[\"SwitchableFormStep\",\"progressBarConfig\"]],[32,2,[\"currentStepData\",\"buttons\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"html/st-await\",[],[[\"@useSpinner\",\"@booleanLoaded\",\"@promises\"],[true,[32,2,[\"finishedCopyingModels\"]],[30,[36,1],null,[[\"addresses\"],[[35,0]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"multi-step-form/forms/send\",[],[[\"@multiStepForm\",\"@sendModel\",\"@defaultToAddress\",\"@modelName\",\"@sendSubjectType\",\"@sendSubjectId\",\"@sendToEntityEmail\"],[[32,2],[32,3],[32,1,[\"resolvedPromises\",\"addresses\"]],\"invoice\",[34,2],[32,3,[\"id\"]],true]],null],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"emailAddresses\",\"hash\",\"sendSubjectType\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/invoices/send-invoice/template.hbs"
    }
  });

  _exports.default = _default;
});