define("securitytrax-ember-ui/components/customer/information/sales-information/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    store: Ember.inject.service(),
    applyTemplatesFormStarted: false,
    cancelAccountFormStarted: false,
    orderChangedCount: 1,

    init() {
      this._super(...arguments);

      Ember.defineProperty(this, 'customerOrderInformationFormOriginalModels', Ember.computed('orderChangedCount', 'model.customerOrderInformation', 'grants.userGrants', this.store.getFullComputedWatcherStringByModelName('customerOrderInformation', {
        prefix: 'model.'
      }), this.store.getFullComputedWatcherStringByModelName('customer'), function () {
        var grants = this.grants;
        return Ember.Object.create({
          customerOrderInformation: {
            model: this.get('model.customerOrderInformation'),
            copyRelationMap: {
              customer: {
                doValidate: true,
                doSave: true,
                relationships: {
                  location: {
                    doValidate: false,
                    doSave: false
                  },
                  leadSourceInformation: {
                    doCopy: grants.getIndividualGrant('customer_lead_source_information.view'),
                    doValidate: false,
                    doSave: grants.getIndividualGrant('customer_lead_source_information.modify'),
                    relationships: {
                      leadCompany: {
                        doCopy: grants.getIndividualGrant('lead_companies.view'),
                        doValidate: false,
                        doSave: false
                      },
                      leadCompanyCampaign: {
                        doCopy: grants.getIndividualGrant('lead_company_campaigns.view'),
                        doValidate: false,
                        doSave: false
                      }
                    }
                  }
                }
              },
              customerCancelReason: {
                doValidate: false,
                doSave: false
              },
              activationFee: {
                doValidate: false,
                doSave: false
              },
              customerWarranty: {
                doValidate: false,
                doSave: false
              },
              discountAffiliate: {
                doValidate: false,
                doSave: false
              },
              interactiveServicesProvider: {
                doValidate: false,
                doSave: false,
                relationships: {
                  servicePackages: {
                    doValidate: false,
                    doSave: false
                  }
                }
              },
              mmr: {
                doValidate: false,
                doSave: false
              },
              monitoringCompany: {
                doValidate: false,
                doSave: false
              },
              monitoringPlan: {
                doValidate: false,
                doSave: false
              },
              repUser: {
                doCopy: true,
                doValidate: false,
                doSave: false
              },
              contractTerm: {
                doValidate: false,
                doSave: false
              },
              trainUser: {
                doCopy: true,
                doValidate: false,
                doSave: false
              }
            }
          }
        });
      }));
    },

    customerOrderInformationForm: Ember.computed(function () {
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.intl.t('pages.customers.order.title'),
          stepComponentPath: 'multi-step-form/forms/customer-order-information'
        })
      });
    }),
    applyOrderTemplateForm: Ember.computed(function () {
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: '',
          title: this.intl.t('pages.customers.order.applyTemplatesTitle'),
          stepComponentPath: 'multi-step-form/forms/order-template'
        })
      });
    }),
    cancelAccountForm: Ember.computed(function () {
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: '',
          title: this.get('model.customerOrderInformation.customerIsCancelled') ? "".concat(this.intl.t('pages.customers.information.cancelledAccountTitle'), " ").concat(this.customer.get('flname')) : "".concat(this.intl.t('pages.customers.information.cancelAccountTitle'), " ").concat(this.customer.get('flname')),
          stepComponentPath: 'multi-step-form/forms/cancel-account'
        })
      });
    }),
    cancelAccountFormOriginalModels: Ember.computed('model.customerOrderInformation', 'grants.userGrants', function () {
      var grants = this.grants;
      return Ember.Object.create({
        customerOrderInformation: {
          model: this.get('model.customerOrderInformation'),
          copyRelationMap: {
            customerCancelReason: {
              doValidate: false,
              doSave: false,
              doCopy: grants.getIndividualGrant('customer_cancel_reasons.view')
            }
          }
        }
      });
    }),
    applyTemplateFormOriginalModels: Ember.computed('model.customerOrderInformation', 'grants.userGrants', function () {
      var grants = this.grants;
      return Ember.Object.create({
        customerOrderInformation: {
          model: this.get('model.customerOrderInformation'),
          copyRelationMap: {
            customer: {
              doValidate: false,
              doSave: true,
              relationships: {
                leadInformation: {
                  doCopy: grants.getIndividualGrant('customer_lead_information.view'),
                  relationships: {
                    leadRepUser: {
                      doCopy: true
                    }
                  }
                },
                leadSourceInformation: {
                  doCopy: grants.getIndividualGrant('customer_lead_source_information.view'),
                  doValidate: false,
                  doSave: true,
                  relationships: {
                    leadCompany: {
                      doCopy: grants.getIndividualGrant('lead_companies.view'),
                      doValidate: false,
                      doSave: false
                    },
                    leadCompanyCampaign: {
                      doCopy: grants.getIndividualGrant('lead_company_campaigns.view'),
                      doValidate: false,
                      doSave: false
                    }
                  }
                }
              }
            },
            customerCancelReason: {},
            activationFee: {},
            customerWarranty: {},
            discountAffiliate: {},
            interactiveServicesProvider: {
              relationships: {
                servicePackages: {}
              }
            },
            mmr: {},
            monitoringCompany: {},
            monitoringPlan: {},
            repUser: {
              doCopy: true
            },
            contractTerm: {},
            trainUser: {
              doCopy: true
            }
          }
        }
      });
    }),
    actions: {
      refreshModels() {
        //refresh models
        this.get('model.customerOrderInformation').hardReload().then(orderInfo => {
          this.set('model.customerOrderInformation', orderInfo);
          this.set('model.customer.customerOrderInformation', orderInfo);
          this.get('model.customer.warnings').reload();
        });
        this.get('model.customerSystemInformation').reload();

        if (this.get('model.customerEquipment')) {
          this.get('model.customerEquipment').reload();
        }

        this.get('model.customer.customerEquipment').reload();
        this.get('model.customer.invoices').reload();
      }

    }
  });

  _exports.default = _default;
});