define("securitytrax-ember-ui/leads/history/route", ["exports", "securitytrax-ember-ui/leads/base-route", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _baseRoute, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_authenticatedRouteMixin.default, {
    tagsService: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['logs.view']);
    },

    parentController: Ember.computed(function () {
      return this.controllerFor('leads');
    })
  });

  _exports.default = _default;
});