define("securitytrax-ember-ui/models/customer-note", ["exports", "securitytrax-ember-ui/models/base_models/customer-note"], function (_exports, _customerNote) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A note is like an internal message about a specific customer, either about
   * installs, products, service, etc.
   *
   * @class CustomerNote
   * @extends BaseCustomerNote
   * @mixes CustomerNoteValidations
   */
  var _default = _customerNote.default.extend({
    /**
     * Holds the UI selection that is later transformed into a communication
     * record. Living in the model for integration with the validations system.
     *
     * @type {String}
     **/
    contactAttempted: null,
    title: Ember.computed('subject', 'noteType', function () {
      return "".concat(this.get('noteType.name'), " | ").concat(this.subject || '');
    }),
    titleAndType: Ember.computed('subject', 'noteType', function () {
      return "".concat(this.get('noteType.name'), " | ").concat(this.subject || '', " | ").concat(this.intl.t('common.columns.note'));
    })
  });

  _exports.default = _default;
});