define("securitytrax-ember-ui/my-account/information/route", ["exports", "securitytrax-ember-ui/my-account/base-route", "securitytrax-ember-ui/mixins/route-pageview-logging", "securitytrax-ember-ui/mixins/scroll-to-top"], function (_exports, _baseRoute, _routePageviewLogging, _scrollToTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_routePageviewLogging.default, _scrollToTop.default, {
    tagsService: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform();
    },

    model() {
      var store = this.store;

      var {
        user
      } = this._super(...arguments);

      return Ember.RSVP.hash({
        user,
        mobileCarriers: store.findAll('mobileCarrier'),
        tags: this.get('tagsService.getTagsByObjectType').perform('user')
      });
    },

    getRouteName() {
      return 'pages.users.my_account.title';
    }

  });

  _exports.default = _default;
});