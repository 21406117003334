define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/customer-create-chooser/create-form-configs/lead-single-page/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1Kj2okqY",
    "block": "{\"symbols\":[\"@workflowConfig\"],\"statements\":[[8,\"lead/lead-information\",[],[[\"@createEdit\",\"@createLeadFormStarted\",\"@transitionToCustomer\",\"@model\",\"@workflowConfig\"],[\"create\",[34,0],[34,1],null,[32,1]]],null]],\"hasEval\":false,\"upvars\":[\"createCustomerFormStarted\",\"afterCreateCustomer\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/customer-create-chooser/create-form-configs/lead-single-page/template.hbs"
    }
  });

  _exports.default = _default;
});