define("securitytrax-ember-ui/components/multi-step-form/switchable-inputs/time-mask-input/component", ["exports", "jquery", "securitytrax-ember-ui/mixins/switchable-input", "moment", "inputmask"], function (_exports, _jquery, _switchableInput, _moment, _inputmask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableInput.default, {
    didRender() {
      var elementToMask = document.querySelector('#' + this.elementId + ' .input-date.time.mask');
      var inputMask = new _inputmask.default({
        alias: 'hh:mm',
        oncomplete: newDate => {
          (0, _jquery.default)(newDate.target).change(); //trigger onChange action
        },
        placeholder: this.placeholder,
        hourFormat: '12'
      });

      if (elementToMask) {
        inputMask.mask(elementToMask);
      }

      if (this.value) {
        this.set('datePortion', (0, _moment.default)(this.value).format('MM/DD/YYYY'));
        this.set('timePortion', (0, _moment.default)(this.value).format('hh:mm'));
        this.set('ampm', (0, _moment.default)(this.value).format('A'));
      }
    },

    propertyName: null,
    datePortion: null,
    timePortion: null,
    ampm: 'AM',
    placeholder: 'hh:mm',
    defaultTime: '00:00',
    validStartTime: null,
    validEndTime: null,
    actions: {
      timeChange: function (newTime) {
        if (this.validStartTime && this.validEndTime) {
          var start = (0, _moment.default)(this.validStartTime, 'hh:mm');
          var end = (0, _moment.default)(this.validEndTime, 'hh:mm');
          var time = this.ampm == 'PM' && newTime.target.value != '12:00' ? (0, _moment.default)(newTime.target.value, 'hh:mm').add(12, 'hours') : this.ampm == 'AM' && newTime.target.value == '12:00' ? (0, _moment.default)('0:00', 'hh:mm') : (0, _moment.default)(newTime.target.value, 'hh:mm');

          if (time >= start && time <= end) {
            this.set('timePortion', time.hour().toString() + ':' + time.minute().toString());
          } else {
            this.set('timePortion', this.defaultTime);
          }
        } else {
          this.set('timePortion', newTime.target.value);
        }

        this.onChangeWithDefault();
      },
      ampmChange: function () {
        if (this.ampm == 'AM') {
          this.set('timePortion', this.validEndTime ? this.validEndTime : this.timePortion);
          this.set('ampm', 'PM');
        } else {
          this.set('timePortion', this.validStartTime ? this.validStartTime : this.timePortion);
          this.set('ampm', 'AM');
        }

        this.onChangeWithDefault();
      }
    },
    onChangeWithDefault: function () {
      var date = this.datePortion ? (0, _moment.default)(this.datePortion, 'MM/DD/YYYY').format('MM/DD/YYYY') : (0, _moment.default)().format('MM/DD/YYYY');
      var time = '';

      if (this.timePortion) {
        if (this.ampm == 'PM' && !RegExp('[12-24]:*').test(this.timePortion)) {
          time = (0, _moment.default)(date + ' ' + this.timePortion, 'MM/DD/YYYY HH:mm').add(12, 'hours').format('HH:mm');
        } else if (this.ampm == 'AM' && RegExp('12:*').test(this.timePortion)) {
          time = (0, _moment.default)(date + ' ' + this.timePortion, 'MM/DD/YYYY HH:mm').add(-12, 'hours').format('HH:mm');
        } else {
          time = this.timePortion;
        }
      } else {
        time = this.defaultTime;
      }

      var dateTimeString = date + ' ' + time;
      this.set('value', (0, _moment.default)(dateTimeString, 'MM/DD/YYYY HH:mm')); //an additional onChange action was passed

      if (this.onChange) {
        return this.onChange.call(this, (0, _moment.default)(dateTimeString));
      }
    }
  });

  _exports.default = _default;
});