define("securitytrax-ember-ui/mixed-in-classes/customer-integrations", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CustomerIntegrations = (_dec = Ember.inject.service('stNotificationManager'), _dec2 = Ember.inject.service('intl'), _dec3 = (0, _emberConcurrency.task)(function* () {
    var customerSystemInformation = yield this.args.customer.customerSystemInformation;

    try {
      yield customerSystemInformation.invokeAction('unlink_cs_account', {
        verb: 'POST',
        data: {
          data: {
            type: 'customer_system_information_action'
          }
        }
      });
      yield (0, _emberConcurrency.all)([this.args.customer.reload(), customerSystemInformation.reload()]);
      this.showUnlinkConfirmModal = false;
      this.args.multiStepForm.formStartedSetVal(false);

      if (this.args.transitionBackToParent && typeof this.args.transitionBackToParent === 'function') {
        this.args.transitionBackToParent();
      }

      this.stNotificationManager.addSuccess(this.intl.t('common.notifications.unlink_successful'));
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'unlink-confirm-modal'
      });
      (false && Ember.warn('Unlink failed: ' + err, {
        id: 'customers.information.manage-integrations.unlink'
      }));
    }
  }), (_class = (_temp = class CustomerIntegrations extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "unlinkAccount", _descriptor3, this);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "unlinkAccount", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CustomerIntegrations;
});