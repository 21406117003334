define("securitytrax-ember-ui/components/multi-step-form/forms/system/bold/create-edit-account/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/utils/obj-helpers", "securitytrax-ember-ui/components/multi-step-form/forms/system/bold/bold-base", "securitytrax-ember-ui/mixins/customer-integrations-mixin"], function (_exports, _emberConcurrency, _objHelpers, _boldBase, _customerIntegrationsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _boldBase.default.extend(_customerIntegrationsMixin.default, {
    centralStationService: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    attributes: undefined,
    boldCustomerInformation: undefined,
    showUnlinkConfirmModal: false,
    addAgencyContactModalOpen: false,
    updateCommissioningStatus: false,
    needsTransmitterData: false,
    customerIdChecked: false,
    customerIdMatched: false,
    matchingCustomer: undefined,
    initFormTask: (0, _emberConcurrency.task)(function* () {
      this.set('formReady', false);
      this.set('needsTransmitterData', false);

      try {
        if (this.formMode === 'create') {
          (0, _objHelpers.initializeState)(this, {
            attributes: {
              'panel_type_id': '',
              'transmitter_type': '',
              'transmitter_id': '',
              'receiver_line_prefix': ''
            }
          });
          yield this.checkCustomerId.perform();
        } else {
          // FOR TESTING AGENCY CONTACT
          // const addTest = yield this.centralStationService.postData({
          //     model: this.customer,
          //     method: 'bold__addAuthorityToCustomer',
          //     payload: {
          //         'authority_type': 'Police',
          //         'serial_no': '1099'
          //     }
          // })
          var hashData = yield (0, _emberConcurrency.hash)({
            customerInformation: this.centralStationService.getData({
              model: this.customer,
              method: 'bold__getCustomer'
            }),
            customerSystemInformation: this.centralStationService.getData({
              model: this.customer,
              method: 'bold__getCustomerSystems'
            })
          });
          var info = hashData.customerInformation.customer;
          var systemInfo = hashData.customerSystemInformation.customer_systems[0];

          if (info && systemInfo && systemInfo.transmitters.length > 0) {
            (0, _objHelpers.initializeState)(this, {
              boldCustomerInformation: {
                customerId: info.customer_id,
                serialNumber: info.serial_no,
                phone1: info.contact_points[0].contact_point_text,
                phone2: info.contact_points[1] ? info.contact_points[1].contact_point_text : null,
                siteName: info.name,
                password: info.passwords && info.passwords.length > 0 ? info.passwords[0].password : null,
                address: "".concat(info.addresses[0].address_1, " ").concat(info.addresses[0].addess_2 ? info.addresses[0].address_2 : '', " ").concat(info.addresses[0].city, ", ").concat(info.addresses[0].region, " ").concat(info.addresses[0].post_code),
                siteSubType: info.subtype_description,
                commissioningStatus: info.monitoring.commissioning_state,
                panel: systemInfo.panel_type_description,
                zones: systemInfo.zones,
                customerEquipmentZones: systemInfo.zones,
                transmitters: systemInfo.transmitters
              }
            });
          } else if (!systemInfo || systemInfo.transmitters.length === 0) {
            (0, _objHelpers.initializeState)(this, {
              attributes: {
                'transmitter_type': '',
                'transmitter_id': '',
                'receiver_line_prefix': ''
              }
            });
            this.set('needsTransmitterData', true);
          }

          yield this.checkCustomerId.perform();
        }
      } catch (err) {
        // If init fails it means something needs to be fixed before you can shell the account. Close the modal and display error so customer can address issue
        this.stNotificationManager.addErrorMessages(err);

        if (this.transitionBackToParent && typeof this.transitionBackToParent === 'function') {
          this.transitionBackToParent();
        }
      }

      this.set('formReady', true);
    }).on('init'),

    registerStepButtons() {
      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('general', 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.createOrSaveAccountTask.perform(multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary bold-create-account-button'
      }]);
    },

    didInsertElement() {
      this._super(...arguments);

      this.registerStepButtons();
    },

    canUpdateCommissioningStatus: Ember.computed(function () {
      return this.seeIfCanUpdateCommissioningStatus.perform();
    }),
    seeIfCanUpdateCommissioningStatus: (0, _emberConcurrency.task)(function* () {
      try {
        var check = yield this.centralStationService.getData({
          model: this.monitoringCompany,
          method: 'bold__getCommissioningStatusFunctionalityAvailable'
        });
        return check.commissioning_status_functionality_available;
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
      }
    }),
    customerEmergencyContacts: Ember.computed(function () {
      return this.getBoldContactsByType.perform('Individual');
    }),
    customerAuthorityContacts: Ember.computed(function () {
      return this.getBoldContactsByType.perform('Authority');
    }),
    getBoldContactsByType: (0, _emberConcurrency.task)(function* (type) {
      var customerContactList = yield this.centralStationService.getData({
        model: this.customer,
        method: 'bold__getContactList'
      });
      return customerContactList.contact_list.filter(contact => {
        return contact.contact_cont_type === type;
      });
    }),
    boldCommissioningStatuses: Ember.computed(function () {
      return this.getBoldCommissioningStatusesTask.perform();
    }),
    getBoldCommissioningStatusesTask: (0, _emberConcurrency.task)(function* () {
      var commissioningStatuses = yield this.centralStationService.getData({
        model: this.monitoringCompany,
        method: 'bold__getCommissioningStatuses'
      });
      return commissioningStatuses.commissioning_statuses;
    }),
    boldSystemOptions: Ember.computed(function () {
      return this.getBoldSystemOptionsTask.perform();
    }),
    getBoldSystemOptionsTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true);
      var monitoringCompanyConfig = this.multiStepForm.formCopiedModels.customer.model.customerOrderInformation.monitoringCompany.monitoringCompanyConfig;
      var panelTypes = yield this.centralStationService.getData({
        model: this.monitoringCompany,
        method: 'bold__getPanelTypes',
        payload: {
          full_list: true
        }
      });

      if (monitoringCompanyConfig && monitoringCompanyConfig.panelTypeWhitelist.length > 0) {
        var panelTypeArray = [];
        monitoringCompanyConfig.panelTypeWhitelist.forEach(panel => {
          panelTypeArray.push(panelTypes.panel_types.find(option => option.id == panel));
        });
        panelTypeArray.forEach(panel => {
          panel.nameIdConcat = "".concat(panel.name, " (ID: ").concat(panel.id, ")");
        });
        return panelTypeArray;
      } else {
        panelTypes.panel_types.forEach(panel => {
          panel.nameIdConcat = "".concat(panel.name, " (ID: ").concat(panel.id, ")");
        });
        return panelTypes.panel_types;
      }
    }),
    boldTransmitterTypeOptions: Ember.computed(function () {
      return this.getBoldTransmitterTypeOptionsTask.perform();
    }),
    getBoldTransmitterTypeOptionsTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true);
      var monitoringCompanyConfig = this.multiStepForm.formCopiedModels.customer.model.customerOrderInformation.monitoringCompany.monitoringCompanyConfig;
      var transmitterTypes = yield this.centralStationService.getData({
        model: this.monitoringCompany,
        method: 'bold__getTransmitterTypes',
        payload: {
          full_list: true
        }
      });

      if (monitoringCompanyConfig && monitoringCompanyConfig.transmitterTypeWhitelist.length > 0) {
        var transmitterArray = [];
        monitoringCompanyConfig.transmitterTypeWhitelist.forEach(transmitter => {
          transmitterArray.push(transmitterTypes.transmitter_types.find(option => option.id == transmitter));
        });
        return transmitterArray;
      } else {
        return transmitterTypes.transmitter_types;
      }
    }),
    boldReceiverLinePrefixes: Ember.computed(function () {
      return this.getBoldReceiverLinePrefixes.perform();
    }),
    getBoldReceiverLinePrefixes: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true);
      var monitoringCompanyConfig = this.multiStepForm.formCopiedModels.customer.model.customerOrderInformation.monitoringCompany.monitoringCompanyConfig;
      var receiverLinePrefixes = yield this.centralStationService.getData({
        model: this.monitoringCompany,
        method: 'bold__getReceiverLineDesignations',
        payload: {
          full_list: true
        }
      });

      if (monitoringCompanyConfig && monitoringCompanyConfig.receiverLineDesignationWhitelist.length > 0) {
        var prefixArray = [];
        monitoringCompanyConfig.receiverLineDesignationWhitelist.forEach(prefix => {
          prefixArray.push(receiverLinePrefixes.receiver_line_designations.find(option => option.id == prefix));
        });
        return prefixArray;
      } else {
        return receiverLinePrefixes.receiver_line_designations;
      }
    }),
    customerZones: Ember.computed('formMode', function () {
      if (this.formMode === 'create') {
        return this.getCustomerZonesTask.perform();
      } else {
        return this.boldCustomerInformation.zones;
      }
    }),
    getCustomerZonesTask: (0, _emberConcurrency.task)(function* () {
      var customerEquipment = yield this.customer.get('customerEquipment');
      var customerZones = [];
      customerEquipment.forEach(equipmentPiece => {
        if ((equipmentPiece.zoneNumber || equipmentPiece.zone) && !customerZones.includes(equipmentPiece.zoneNumber)) {
          customerZones.push({
            area: '*',
            zone: equipmentPiece.zoneNumber,
            description: equipmentPiece.zone,
            active_status: this.intl.t('pages.customers.system.order.never_received_signal')
          });
        }
      });
      return customerZones;
    }),
    createOrSaveAccountTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var formattedObjects = Ember.A();

      if (this.formMode === 'create') {
        var attributes = this.attributes;
        formattedObjects.pushObject(Ember.Object.create({
          model: {
            panelType: attributes['panel_type_id'],
            transmitterType: attributes['transmitter_type'],
            transmitterID: attributes['transmitter_id'],
            receiverLinePrefix: attributes['receiver_line_prefix'],
            accountID: multiStepForm.formCopiedModels.customer.model.customerSystemInformation.accountNum,
            abortCode: multiStepForm.formCopiedModels.customer.model.customerSystemInformation.customerAbortCode.abortCode
          },
          filePath: 'central-station/bold-create'
        }));
        yield multiStepForm.validateList.perform(formattedObjects, {
          notificationNamespace: 'modal'
        });

        try {
          // Save changes to customer in our system before sending to Bold
          yield multiStepForm.validateSyncSaveAll({
            notificationNamespace: 'modal',
            extraValidationData: {
              noValidate: ['location', 'customer-address1', 'customer-zip']
            }
          });

          if (!this.customerIdChecked) {
            yield this.checkCustomerId.perform();

            if (this.matchingCustomer.customer) {
              throw new Error('Non Unique Customer ID');
            }
          } // to Bold


          yield this.centralStationService.postData({
            model: this.customer,
            method: 'bold__createAccount',
            payload: attributes,
            rawResponse: true
          });
          buttonPromise.then(() => {
            this.set('formMode', 'edit');
            this.store.findRecord('customerSystemInformation', this.get('customer.id')).then(systemInfo => {
              systemInfo.reload();
            });
            this.initFormTask.perform().then(() => {
              this.registerStepButtons();
              this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
            });
          });
        } catch (err) {
          // This specific error means the customer shelled but it didn't create a transmitter, so we gotta treat this like a success
          if (err.errors[0].detail == 'The server encountered an error while processing your request: Object reference not set to an instance of an object. An error report has been created on the server.') {
            this.set('formMode', 'edit');
            this.store.findRecord('customerSystemInformation', this.get('customer.id')).then(systemInfo => {
              systemInfo.reload();
            });
            this.initFormTask.perform().then(() => {
              this.registerStepButtons();
            });
          } else {
            this.stNotificationManager.addErrorMessages(err, {
              notificationNamespace: 'modal'
            });
            throw err;
          }
        } // For when an account shells but Bold fails to save Transmitter data

      } else if (this.needsTransmitterData) {
        var _attributes = this.attributes;
        formattedObjects.pushObject(Ember.Object.create({
          model: {
            transmitterType: _attributes['transmitter_type'],
            transmitterID: _attributes['transmitter_id'],
            receiverLinePrefix: _attributes['receiver_line_prefix']
          },
          extraValidationData: {
            noValidate: ['bold-accountID', 'bold-panelType', 'bold-abortCode']
          },
          filePath: 'central-station/bold-create'
        }));
        yield multiStepForm.validateList.perform(formattedObjects, {
          notificationNamespace: 'modal'
        });

        try {
          yield this.centralStationService.postData({
            model: this.customer,
            method: 'bold__createTransmitter',
            payload: this.attributes
          });
        } catch (error) {
          this.stNotificationManager.addErrorMessages(error, {
            notificationNamespace: 'modal'
          });
          throw error;
        }

        buttonPromise.then(() => {
          this.store.findRecord('customerSystemInformation', this.get('customer.id')).then(systemInfo => {
            systemInfo.reload();
          });
          this.initFormTask.perform().then(() => {
            this.registerStepButtons();
            this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
          });
        }); // Edit
      } else {
        formattedObjects.pushObject(Ember.Object.create({
          model: {
            abortCode: multiStepForm.formCopiedModels.customer.model.customerSystemInformation.customerAbortCode.abortCode,
            phone1: multiStepForm.formCopiedModels.customer.model.primaryPhone
          },
          extraValidationData: {
            noValidate: ['bold-accountID', 'bold-panelType', 'bold-receiverLinePrefix', 'bold-transmitterID', 'bold-transmitterType']
          },
          filePath: 'central-station/bold-create'
        }));
        yield multiStepForm.validateList.perform(formattedObjects);
        yield multiStepForm.validateSyncSaveAll({
          notificationNamespace: 'modal',
          extraValidationData: {
            noValidate: ['location']
          }
        });

        if (this.updateCommissioningStatus) {
          try {
            yield this.centralStationService.postData({
              model: this.customer,
              method: 'bold__changeCommissioningStatus',
              payload: {
                commissioning_status: this.boldCustomerInformation.commissioningStatus
              }
            });
          } catch (error) {
            this.stNotificationManager.addErrorMessages(error, {
              notificationNamespace: 'modal'
            });
            throw error;
          }
        }

        buttonPromise.then(() => {
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
        });
      }
    }),
    getNextTransmitterId: (0, _emberConcurrency.task)(function* () {
      try {
        var nextId = yield this.centralStationService.getData({
          model: this.customer,
          method: 'bold__getNextTransmitterId',
          payload: {
            receiver_line_designation: this.attributes.receiver_line_prefix
          }
        });
        this.set('attributes.transmitter_id', nextId.next_transmitter_id);
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
        throw err;
      }
    }),
    checkCustomerId: (0, _emberConcurrency.task)(function* () {
      var matchingCustomer = yield this.centralStationService.getData({
        model: this.customer,
        method: 'bold__getCustomerUsingForeignCustomerId',
        payload: {}
      });

      if (matchingCustomer.customer && matchingCustomer.customer.customer_id) {
        this.set('matchingCustomer', matchingCustomer);
      } else {
        this.set('matchingCustomer', undefined);
      }

      this.set('customerIdChecked', true);
    }),
    actions: {
      refreshAuthorities() {
        this.notifyPropertyChange('customerAuthorityContacts');
      },

      checkId() {
        if (this.multiStepForm.formCopiedModels.customer.model.get('customerSystemInformation.accountNum') != this.multiStepForm.formOriginalModels.customer.model.get('customerSystemInformation.accountNum')) {
          this.set('customerIdChecked', false);
          this.set('matchingCustomer', undefined);
        }
      }

    }
  });

  _exports.default = _default;
});