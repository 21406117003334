define("securitytrax-ember-ui/components/html/icons/icon-search/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DuT+jEpm",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[16,0,[31,[[34,0],\" wrapper\"]]],[4,[38,1],[[32,0],\"searchClick\"],null],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"search\"],[14,\"for\",\"search\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"search-icon\"],[12],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n   \"]],\"hasEval\":false,\"upvars\":[\"openSearch\",\"action\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/icons/icon-search/template.hbs"
    }
  });

  _exports.default = _default;
});