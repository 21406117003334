define("securitytrax-ember-ui/components/multi-step-form/forms/customer-central-station-information/monitoring-companies/monitoring-company-type-1/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms/customer-central-station-information/base-monitoring-company/component", "securitytrax-ember-ui/mixed-in-classes/adt"], function (_exports, _emberConcurrency, _component, _adt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var ADTMonitoringCompanyType = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = (0, _emberConcurrency.task)(function* () {
    var multiStepForm = this.args.multiStepForm;
    yield (0, _emberConcurrency.all)([(0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true), this.ADTMixin.adtData]);

    if (this.callbackEditAdtOrderFormStarted) {
      this.editAdtOrderFormStarted = true;
      this.adtUpdateFormFirstStep = 'eContracts';
    }
  }), _dec14 = (0, _emberConcurrency.task)(function* () {
    var multiStepForm = this.args.multiStepForm;
    yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);
    var intl = this.intl;
    var preShellErrors = [];

    try {
      var customer = yield this.args.customer;

      if (!this.args.multiStepForm.formCopiedModelsGetVal('customerSystemInformation.model.customerAbortCode.abortCode')) {
        preShellErrors.push(intl.t('pages.customers.adt_pre_shell.abort_code'));
      }

      if (!this.args.multiStepForm.formCopiedModelsGetVal('customerOrderInformation.model.repUser') && !this.args.multiStepForm.formCopiedModelsGetVal('customerSystemInformation.model.installCalendarEvent')) {
        preShellErrors.push(intl.t('pages.customers.adt_pre_shell.rep_or_tech'));
      }

      if (!this.args.customerSystemInformation.centralStationId && this.args.multiStepForm.formCopiedModelsGetVal('customerSystemInformation.model.adtSerialMan') && this.args.multiStepForm.formCopiedModelsGetVal('customerSystemInformation.model.accountNum')) {
        preShellErrors.push(intl.t('pages.customers.adt_pre_shell.accountNumber_and_serialMan'));
      }

      if (this.args.customer.get('accountType') === 'Commercial' && Ember.isEmpty(this.args.customer.get('businessContact'))) {
        preShellErrors.push(intl.t('pages.customers.adt_pre_shell.business_contact'));
      }

      var adtData = yield this.ADTMixin.adtData;

      if (adtData && adtData.failedLoadAdtData) {
        adtData = yield this.ADTMixin.adtDataTask.perform(); //Try to get ADT data again every time shell clicked before displaying failure
      }

      if (adtData && !adtData.hasPreCheck) {
        preShellErrors.push(intl.t('pages.customers.adt_pre_shell.precheck'));
      }

      if (preShellErrors.length > 0) {
        this.preShellErrors = preShellErrors;
        this.showPreShellErrors = true;
        throw new Error('preShell missing info');
      } else {
        if (adtData && adtData.failedLoadAdtData) {
          this.stNotificationManager.addError(intl.t('pages.customers.system.failedContactAdt'));
        } else if (adtData && adtData.hasPreCheck) {
          this.router.transitionTo(this.args.subroutePath + '.manage-' + this.subrouteMapping.monitorMapping.findBy('id', '1').name);
        } else {
          // retry strategy here? See if we have a precheck done
          if ('passed' in customer.adtPreCheck && customer.adtPreCheck.passed === true) {
            this.router.transitionTo(this.args.subroutePath + '.manage-' + this.subrouteMapping.monitorMapping.findBy('id', '1').name);
          }
        }
      }
    } catch (error) {
      if (error.message === 'preShell missing info') {
        throw error;
      }

      (false && Ember.warn('failed to startAdtOrderForm' + error, {
        id: 'multiStepForm.forms.customerCentralStationInformation.monitoringCompanies.monitoringCompanyType1'
      }));
    }
  }), _dec15 = (0, _emberConcurrency.task)(function* (customer) {
    try {
      yield customer.executeAdtPreCheck();
    } catch (error) {
      (false && Ember.warn('failed to submit adt pre check' + error, {
        id: 'multiStepForm.forms.customerCentralStationInformation.monitoringCompanies.monitoringCompanyType1.submitAdtPreCheck'
      }));
    }
  }), _dec16 = Ember._action, (_class = (_temp = class ADTMonitoringCompanyType extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "grants", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "ispService", _descriptor3, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor4, this);

      _initializerDefineProperty(this, "router", _descriptor5, this);

      _initializerDefineProperty(this, "isADT", _descriptor6, this);

      _initializerDefineProperty(this, "callbackEditAdtOrderFormStarted", _descriptor7, this);

      _initializerDefineProperty(this, "preShellErrors", _descriptor8, this);

      _initializerDefineProperty(this, "showPreShellErrors", _descriptor9, this);

      _initializerDefineProperty(this, "adtFormMode", _descriptor10, this);

      _initializerDefineProperty(this, "adtUpdateFormFirstStep", _descriptor11, this);

      _initializerDefineProperty(this, "editAdtOrderFormStarted", _descriptor12, this);

      _initializerDefineProperty(this, "openEcontractFromExternalTask", _descriptor13, this);

      _initializerDefineProperty(this, "startIntegrationForm", _descriptor14, this);

      _initializerDefineProperty(this, "submitAdtPreCheck", _descriptor15, this);

      this.ADTMixin = new _adt.default(...arguments);

      if (this.args.callbackEditAdtOrderFormStarted) {
        this.callbackEditAdtOrderFormStarted = this.args.callbackEditAdtOrderFormStarted;
      }

      this.openEcontractFromExternalTask.perform();
    }

    get monitoringCompanyHasNeededGrants() {
      return this.ADTMixin.monitoringCompanyHasNeededGrantsTask.perform();
    }

    get showAdtPreCheck() {
      if (!this.grants.getIndividualGrant('customers.manage_central_station_integration')) {
        return false;
      }

      var active = this.args.multiStepForm.formCopiedModelsGetVal('customerOrderInformation.model.monitoringCompany.active');
      var monitorTypeId = null;

      if (this.args.multiStepForm.formCopiedModelsGetVal('customerOrderInformation.model.monitoringCompany')) {
        monitorTypeId = this.args.multiStepForm.formCopiedModelsGetVal('customerOrderInformation.model.monitoringCompany.monitoringCompanyType.id');
      }

      if (active === true && monitorTypeId == 1) {
        return true;
      }

      return false;
    }

    hidePreShellTooltip() {
      this.showPreShellErrors = false;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "ispService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isADT", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "callbackEditAdtOrderFormStarted", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "preShellErrors", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "showPreShellErrors", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "adtFormMode", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'edit';
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "adtUpdateFormFirstStep", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'orderInformation';
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "editAdtOrderFormStarted", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "openEcontractFromExternalTask", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "startIntegrationForm", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "submitAdtPreCheck", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "hidePreShellTooltip", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "hidePreShellTooltip"), _class.prototype)), _class));
  _exports.default = ADTMonitoringCompanyType;
});