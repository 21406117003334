define("securitytrax-ember-ui/components/nav/top-nav/company-menu-wrapper/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6HzriUH9",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,5],null,[[\"exceptSelector\",\"onClickOutside\"],[\".hamburger-wrapper\",[30,[36,4],[[32,0],\"closeNavs\"],null]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,1,\"company-dropdown\"],[15,0,[31,[[30,[36,3],[[35,2,[\"isDesktop\"]],\"desktop-dropdown\",\"mobile-drawer\"],null],\" \",[34,1],\" \",[34,0]]]],[12],[2,\"\\n        \"],[18,1,null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"classNames\",\"expanded\",\"screen\",\"if\",\"action\",\"click-outside\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/nav/top-nav/company-menu-wrapper/template.hbs"
    }
  });

  _exports.default = _default;
});