define("securitytrax-ember-ui/validations/customer-billing-bill-cc-number", ["exports", "securitytrax-ember-ui/validations/base-validation", "ember-credit-cards/utils/validations"], function (_exports, _baseValidation, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('customerBillingBillCcNumber-billCcNumber')) {
        return false;
      } else {
        var validateBillCcNumber = _validations.default.validateNumber(this.billCcNumber);

        var validationData = {};

        if (!validateBillCcNumber) {
          var validationMessage = this.customMessageOrDefault(data, 'customerBillingBillCcNumber-billCcNumber');
          validationData['billCcNumber'] = [validationMessage.message];
        }

        return this.outputFormatting(validationData, 'customerBillingBillCcNumber');
      }
    }

  });

  _exports.default = _default;
});