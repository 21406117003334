define("securitytrax-ember-ui/models/work-order", ["exports", "securitytrax-ember-ui/models/base_models/work-order", "ember-concurrency", "moment", "lodash"], function (_exports, _workOrder, _emberConcurrency, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _workOrder.default.extend({
    isOverdue: Ember.computed('dueDate', function () {
      if (this.dueDate) {
        var dueDate = (0, _moment.default)(this.dueDate).endOf('day');
        return (0, _moment.default)().isAfter(dueDate);
      }

      return false;
    }),
    isDueLaterToday: Ember.computed('dueDate', 'isOverdue', function () {
      if (this.dueDate) {
        var dueDate = (0, _moment.default)(this.dueDate).endOf('day');
        return !this.isOverdue && dueDate.isSame((0, _moment.default)(), 'day');
      }

      return false;
    }),
    workToPerformSummary: Ember.computed('workOrderEquipment.[]', function () {
      return this.workToPerformTask.perform();
    }),
    workToPerformTask: (0, _emberConcurrency.task)(function* () {
      var summaryArray = [];
      var taskEquipments = yield this.workOrderEquipment;
      yield this.store.findAll('workOrderAction');
      taskEquipments.forEach(taskEquipment => {
        var workOrderAction = taskEquipment.get('workOrderAction.name');

        if (summaryArray[workOrderAction]) {
          summaryArray[workOrderAction]++;
        } else {
          summaryArray[workOrderAction] = 1;
        }
      });
      return summaryArray;
    }),
    completedAppointments: Ember.computed(function () {
      return this.completedAppointmentsTask.perform();
    }),
    completedAppointmentsMC: Ember.computed(function () {
      return this.completedAppointmentsTask.perform('work_order_event_on_site_info_' + this.id);
    }),
    completedAppointmentsTask: (0, _emberConcurrency.task)(function* (isMultiCall) {
      var completedAppointments = 0;

      for (var i = 0; i < this.get('calendarEvents.length'); i++) {
        var completed = yield this.calendarEvents.objectAt(i).appointmentCompletedTask.perform(isMultiCall);

        if (completed) {
          completedAppointments++;
        }
      }

      return completedAppointments;
    }),
    totalAppointmentsDuration: Ember.computed('responseMeta.total_duration_of_appointments', function () {
      var seconds = _lodash.default.get(this, 'responseMeta.total_duration_of_appointments', 0);

      if (seconds) {
        var minutes = Math.floor(seconds / 60);
        var hours = Math.floor(minutes / 60);
        var subMinutes = minutes - hours * 60;
        return this.intl.t('pages.customers.workOrders.details.totalAppointmentsDurationString', {
          hours: hours,
          minutes: subMinutes
        });
      }

      return null;
    }),
    totalAppointmentsNumber: Ember.computed('responseMeta.number_of_appointments', function () {
      var numAppointments = _lodash.default.get(this, 'responseMeta.number_of_appointments', 0);

      return numAppointments > 0 ? numAppointments : null;
    }),
    appointmentsData: Ember.computed(function () {
      return this.appointmentsDataTask.perform();
    }),
    appointmentsDataTask: (0, _emberConcurrency.task)(function* () {
      var response = yield this.invokeAction('get_work_order_appointments', {
        verb: 'GET'
      });
      var result = {
        attributes: [],
        totals: {
          install: 0,
          service: 0,
          removal: 0
        },
        meta: response.responsePayload.data.meta
      };
      response.responsePayload.data.attributes.forEach(appointment => {
        result.attributes.push({
          calendarUser: {
            flname: appointment.calendar_users_name,
            avatar: appointment.calendar_user_profile_image_url
          },
          calendarEventType: {
            name: appointment.calendar_event_type,
            styleColor: "background-color: ".concat(appointment.calendar_event_type_color, ";"),
            calculatedColor: appointment.calendar_event_type_color
          },
          calendarEventDetailType: {
            name: appointment.calendar_event_detail_type,
            calculatedStyleColor: appointment.calendar_event_detail_type_color ? "background-color: ".concat(appointment.calendar_event_detail_type_color, ";") : ''
          },
          calendar_duration: appointment.appointment_date_time,
          appointment_status: appointment.appointment_status
        });
        result.totals[appointment.calendar_event_type.toLowerCase()] += 1;
      });
      return result;
    })
  });

  _exports.default = _default;
});