define("securitytrax-ember-ui/components/customer/dashboard/customer-profit-card/component", ["exports", "@glimmer/component", "ember-concurrency", "lodash"], function (_exports, _component, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CustomerProfitCard = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = (0, _emberConcurrency.task)(function* () {
    var customerProfitLoss = yield this.args.customer.invokeAction('dashboard_cards/profit_and_losses', {
      includeActionsString: false,
      verb: 'GET',
      processResponse: true
    });
    var returnData = [{
      key: 'funded',
      raw_value: Number(_lodash.default.get(customerProfitLoss, 'responsePayload.data.funded', 0)),
      second_bar: {
        key: 'funding_expected',
        raw_value: Number(_lodash.default.get(customerProfitLoss, 'responsePayload.data.funding_expected', 0))
      }
    }, // {
    //     key: 'funding_outstanding',
    //     raw_value: Number(lodash.get(customerProfitLoss, 'responsePayload.data.funding_outstanding', 0)),
    // },
    {
      key: 'customer_payments',
      raw_value: Number(_lodash.default.get(customerProfitLoss, 'responsePayload.data.customer_payments', 0))
    }, {
      key: 'equipment_costs',
      raw_value: Number(_lodash.default.get(customerProfitLoss, 'responsePayload.data.equipment_costs', 0))
    }, {
      key: 'payable_costs',
      raw_value: Number(_lodash.default.get(customerProfitLoss, 'responsePayload.data.payable_costs', 0)),
      second_bar: {
        raw_value: Number(_lodash.default.get(customerProfitLoss, 'responsePayload.data.expected_payable_cost', 0))
      }
    }, {
      key: 'total',
      raw_value: Number(_lodash.default.get(customerProfitLoss, 'responsePayload.data.total', 0)),
      bold_value: true
    }];
    var maxValue = 1;
    returnData.forEach(currValue => {
      if (Math.abs(currValue['raw_value']) > maxValue) {
        maxValue = Math.abs(currValue['raw_value']);
      }

      if (currValue['second_bar']) {
        if (Math.abs(currValue['second_bar']['raw_value']) > maxValue) {
          maxValue = Math.abs(currValue['second_bar']['raw_value']);
        }
      }
    });
    returnData.forEach(currValue => {
      currValue['percentTotal'] = Math.floor(currValue['raw_value'] / maxValue * 100);
      currValue['isPositive'] = currValue['raw_value'] >= 0;

      if (currValue['second_bar']) {
        currValue['second_bar']['percentTotal'] = Math.floor(currValue['second_bar']['raw_value'] / maxValue * 100);
        currValue['second_bar']['isPositive'] = currValue['second_bar']['raw_value'] >= 0;
      }
    });
    this.profitLossData = returnData;
  }), (_class = (_temp = class CustomerProfitCard extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "grants", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "profitLossData", _descriptor4, this);

      _initializerDefineProperty(this, "profitLossDataTask", _descriptor5, this);

      if (!this.args.useDefaultData) {
        this.profitLossDataTask.perform();
      } else {
        this.profitLossData = this.defaultData;
      }
    }

    get defaultData() {
      return [{
        isPositive: true,
        key: 'funded',
        percentTotal: 75,
        raw_value: 5000,
        second_bar: {
          isPositive: true,
          key: 'expected_funding',
          percentTotal: 100,
          raw_value: -1200
        }
      }, {
        isPositive: true,
        key: 'customer_payments',
        percentTotal: 75,
        raw_value: 3800
      }, {
        isPositive: false,
        key: 'equipment_costs',
        percentTotal: -6,
        raw_value: -400
      }, {
        isPositive: false,
        key: 'payable_costs',
        percentTotal: -20,
        raw_value: -1000,
        second_bar: {
          isPositive: false,
          percentTotal: -45,
          raw_value: -1200
        }
      }, {
        isPositive: true,
        key: 'total',
        percentTotal: 75,
        raw_value: 5000
      }];
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "profitLossData", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "profitLossDataTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CustomerProfitCard;
});