define("securitytrax-ember-ui/home/tech-today/appointment/manage-american-two-way/route", ["exports", "securitytrax-ember-ui/home/tech-today/appointment/integration-base-route"], function (_exports, _integrationBaseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _integrationBaseRoute.default.extend({});

  _exports.default = _default;
});