define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/electronic-document-providers/integrations/docusign/edit-envelope/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency"], function (_exports, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    stAjax: Ember.inject.service(),
    grants: Ember.inject.service(),
    canSend: false,
    synced: false,
    showUnlinkConfirmationModal: false,
    recipientsWithoutEmail: false,
    unlinkEnvelopeButtons: undefined,
    confirmDeleteModalOpen: false,

    init() {
      this._super(...arguments);

      if (this.docusignError) {
        this.stNotificationManager.addErrorMessages(this.docusignError, {
          notificationNamespace: 'modal'
        });
      }
    },

    setupButtonsTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true);
      var docusignStatus = this.multiStepForm.formOriginalModelsGetVal('docusignEnvelope.model.status');
      var meta = this.multiStepForm.formCopiedModelsGetVal('docusignEnvelope.model.responseMeta');
      var docusignRecipients = this.multiStepForm.formCopiedModelsGetVal('docusignEnvelope.model.docusignRecipients');
      var recipientsWithoutEmail = docusignRecipients ? docusignRecipients.filter(recipient => {
        return !recipient.email;
      }) : [];

      if (recipientsWithoutEmail.get('length') === 0 && docusignStatus == 'created' && this.synced) {
        this.set('recipientsWithoutEmail', false);
        this.set('canSend', true);
      } else if (recipientsWithoutEmail.get('length') > 0) {
        this.set('recipientsWithoutEmail', true);
      }

      var buttonChoices = [];

      if (!this.isNewRecord && this.grants.getIndividualGrant('electronic_document_envelopes.delete')) {
        if (docusignStatus != 'completed') {
          buttonChoices.addObject({
            text: this.intl.t('common.actions.delete'),
            classNamesString: 'st-btn st-btn envelope-delete-button warning-red delete',
            isDelete: true,
            click: () => {
              this.set('confirmDeleteModalOpen', true);
            }
          });
        } else {
          buttonChoices.addObject({
            text: this.intl.t('common.actions.unlink'),
            classNamesString: 'st-btn st-btn envelope-unlink-button warning-red delete',
            isDelete: true,
            click: () => {
              this.set('showUnlinkConfirmationModal', true);
            }
          });
        }
      }

      if (docusignStatus != 'sent' && docusignStatus != 'completed') {
        var url = meta ? meta.actions.sync.url : null;
        var classes = this.canSend ? 'st-btn envelope-sync-button' : 'st-btn st-btn-primary envelope-sync-button';
        buttonChoices.addObject({
          text: this.intl.t('common.actions.sync'),
          classNamesString: classes,
          click: buttonPromise => {
            return this.sendSyncDocusignEnvelope.perform(this.multiStepForm, buttonPromise, url);
          }
        });
      }

      if (this.canSend && docusignStatus != 'sent') {
        var _url = meta ? meta.actions.send.url : null;

        buttonChoices.addObject({
          text: this.intl.t('common.actions.send'),
          classNamesString: 'st-btn st-btn-primary envelope-send-button',
          click: buttonPromise => {
            return this.sendSyncDocusignEnvelope.perform(this.multiStepForm, buttonPromise, _url);
          }
        });
      }

      var unlinkUrl = meta ? meta.actions.unlink.url : null;
      var unlinkButtonChoices = [{
        text: this.intl.t('common.actions.unlink'),
        classNamesString: 'st-btn st-btn warning-red unlink-confirm',
        click: buttonPromise => {
          return this.unlinkDocusignEnvelope.perform(this.multiStepForm, buttonPromise, unlinkUrl);
        }
      }];
      this.set('unlinkEnvelopeButtons', unlinkButtonChoices);
      this.multiStepForm.registerStepData('editEnvelopeForm', 'buttons', buttonChoices);
    }).on('init'),
    unlinkDocusignEnvelope: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise, url) {
      try {
        yield this.stAjax.request(url, {
          method: 'POST',
          data: {
            data: {
              type: 'docusign_envelopes_action'
            }
          }
        }).catch(errors => {
          var errorsArray = errors && errors.payload ? errors.payload.errors : [];
          errorsArray.forEach(error => {
            this.stNotificationManager.addError(error.detail, 'modal2');
          });
        });
        buttonPromise.then(() => {
          this.stNotificationManager.addSuccessKey('common.notifications.unlink_successful', 'modal2');
          this.afterUnlink.call();
        });
      } catch (e) {
        this.stNotificationManager.addErrorMessages(e, {
          notificationNamespace: 'modal'
        });
      }
    }),
    sendSyncDocusignEnvelope: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise, url) {
      try {
        yield this.stAjax.request(url, {
          method: 'POST',
          data: {
            data: {
              type: 'docusign_envelopes_action'
            }
          }
        }).catch(errors => {
          var errorsArray = errors.payload.errors;
          errorsArray.forEach(error => {
            this.stNotificationManager.addError(error.detail, 'modal');
          });
        });
        this.set('synced', true);
        yield multiStepForm.formOriginalModelsGetVal('docusignEnvelope.model').reload();
        yield multiStepForm.setupCopyModels.perform();
        yield this.setupButtonsTask.perform();
        buttonPromise.then(() => {
          if (this.recipientsWithoutEmail) {
            this.stNotificationManager.addWarning(this.intl.t('pages.administration.integrations.electronic_document_providers.docusign.envelope.missing_recipient_emails'), 'modal');
          } else {
            this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
          }
        });
      } catch (e) {
        this.stNotificationManager.addErrorMessages(e, {
          notificationNamespace: 'modal'
        });
      }
    }),
    deleteEnvelopeTask: (0, _emberConcurrency.task)(function* () {
      try {
        var envelope = this.multiStepForm.formOriginalModelsGetVal('docusignEnvelope.model');
        yield envelope.destroyRecordWithRollbackTask.perform();
        this.multiStepForm.formStartedSetVal(false);
        yield this.afterDelete.call();
        this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
      } catch (err) {
        (false && Ember.warn('error deleting docusign envelope: ' + err, {
          id: 'multiStepForm.forms.administration.integrations.docusign.editEnvelope.deleteEnvelope'
        }));
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal2'
        });
        throw err;
      }
    }),
    updateFormStatusAndButtons: (0, _emberConcurrency.task)(function* () {
      yield this.multiStepForm.formOriginalModelsGetVal('docusignEnvelope.model').reload();
      this.setupButtonsTask.perform();
    }),
    actions: {
      checkCanSend() {
        this.multiStepForm.setupCopyModels.perform();
        this.setupButtonsTask.perform();
      },

      updateFormOriginalModels() {
        return this.updateFormStatusAndButtons.perform();
      }

    }
  });

  _exports.default = _default;
});