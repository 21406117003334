define("securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/adc/apply-panel-template/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "REeED4KU",
    "block": "{\"symbols\":[\"data\"],\"statements\":[[8,\"html/st-await\",[],[[\"@useSpinner\",\"@promises\"],[true,[30,[36,1],null,[[\"adcPanelTemplates\"],[[35,0]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"grid-x grid-padding-x\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cell large-6\"],[12],[2,\"\\n            \"],[8,\"multi-step-form/switchable-inputs/select-input\",[],[[\"@formMode\",\"@value\",\"@options\",\"@label\",\"@valueProperty\",\"@labelProperty\",\"@onChange\",\"@required\",\"@errors\",\"@validationKey\"],[[34,2],[34,3],[32,1,[\"resolvedPromises\",\"adcPanelTemplates\"]],[30,[36,4],[\"pages.customers.system.interactiveServicesProvider.applyPanelTemplate.downloadTemplate\"],null],\"value\",\"name\",[30,[36,5],[[32,0],\"updateDropdownAttribute\",\"panel_template_id\"],null],true,[34,6,[\"validationErrors\"]],\"adc-panelTemplateId\"]],null],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"adcPanelTemplates\",\"hash\",\"formMode\",\"panelTemplateId\",\"t\",\"action\",\"multiStepForm\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/adc/apply-panel-template/template.hbs"
    }
  });

  _exports.default = _default;
});