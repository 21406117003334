define("securitytrax-ember-ui/components/calendar-glimmer/calendar-grid/calendar-grid-row-title-list/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CalendarGridRowTitle extends _component.default {
    get gridRowStyles() {
      return Ember.String.htmlSafe('grid-row: 1 / span 1');
    }

  }

  _exports.default = CalendarGridRowTitle;
});