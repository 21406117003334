define("securitytrax-ember-ui/classes/user-defined-fields", ["exports", "ember-concurrency", "moment", "lodash", "securitytrax-ember-ui/classes/misc-input-list", "securitytrax-ember-ui/config/environment"], function (_exports, _emberConcurrency, _moment, _lodash, _miscInputList, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var UserDefinedFields = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember.computed('model.udfs.{[],@each.value}'), _dec9 = (0, _emberConcurrency.task)(function* (udfContext) {
    if (!udfContext) {
      throw Error('Must pass in UDF Context to populate Section Data in UDF Class');
    }

    var sections_data;
    var hashedData = yield (0, _emberConcurrency.hash)({
      context: this.store.findRecord('udf-context', udfContext, {
        include: 'udf_sections.admin_udfs'
      }),
      udfsToSections: this.store.query('udfs-to-section', {
        filter: 'udf_section.udf_context = \'' + udfContext + '\''
      }, {
        cacheResponse: true,
        cacheForMilliseconds: 0,
        cacheForNumAccessed: 0,
        forceCacheReload: false,
        useLocalStorage: false
      })
    });
    var udfsToSectionsMapping = {};
    hashedData.udfsToSections.forEach(section => {
      udfsToSectionsMapping[section.get('adminUdf.id') + section.get('udfSection.id')] = section;
    });

    if (_environment.default.environment != 'test') {
      // Real land
      sections_data = hashedData.context.get('udfSections');
    } else {
      // Mirage
      sections_data = yield hashedData.context.get('udfSections'); // Don't need yield in real land, but mirage is dumb.
    }

    for (var i = 0; i < sections_data.get('length'); i++) {
      var curSection = sections_data.objectAt(i);
      var adminUdfs = null;

      if (_environment.default.environment != 'test') {
        // Real land
        adminUdfs = curSection.get('adminUdfs');
      } else {
        // Mirage
        adminUdfs = yield curSection.get('adminUdfs'); // Don't need yield in real land, but mirage is dumb.
      }

      var addedUdfObjs = [];

      for (var j = 0; j < adminUdfs.get('length'); j++) {
        var adminUdf = adminUdfs.objectAt(j);
        var currUdfToSection = udfsToSectionsMapping[adminUdf.get('id') + curSection.get('id')];

        if (adminUdf.hideInUi) {
          continue;
        }

        var adminUdfObj = this.udfObjs[adminUdf];

        if (!adminUdfObj) {
          var model_udf = this.adminUdfToUdf[adminUdf] ? this.adminUdfToUdf[adminUdf] : null;
          adminUdfObj = this.createAdminUdfObj(adminUdf, model_udf);

          if (!adminUdfObj) {
            continue;
          }

          this.udfObjs[adminUdf] = adminUdfObj;
        }

        if (currUdfToSection) {
          Ember.set(adminUdfObj, 'sort', currUdfToSection.get('sort'));
        }

        addedUdfObjs.push(adminUdfObj);
      }

      if (addedUdfObjs.length > 0) {
        this.udfsPresent = true;
        this.sections[curSection] = {
          name: curSection.showHeader ? curSection.sectionName : '',
          adminUdfObjs: _lodash.default.sortBy(addedUdfObjs, ['sort', 'sort'])
        };
      }
    }
  }), _dec10 = (0, _emberConcurrency.task)(function* () {
    if (!this.udfIDs || this.udfIDs.length == 0) {
      this.udfData = [];
      return [];
    }

    this.udfIDs.sort((a, b) => a.order - b.order);
    var modelUDFs = this.model ? yield this.model.get('udfs') : [];
    var modelUdfPromiseArray = [];
    modelUDFs.forEach(udf => {
      modelUdfPromiseArray.push(this.setupDictionary.perform(udf));
    });
    var promiseArray = [];
    this.udfIDs.forEach(udfID => {
      promiseArray.push(this.getUdfTask.perform(udfID));
    });
    yield (0, _emberConcurrency.hash)({
      modelUdfPromises: (0, _emberConcurrency.all)(modelUdfPromiseArray),
      promises: (0, _emberConcurrency.all)(promiseArray)
    });
    this.checkConditions();
    var data = [];
    this.udfIDs.forEach(udfID => {
      if (this.udfObjs[udfID.id]) {
        data.push(this.udfObjs[udfID.id]);
      }
    });
    this.udfsPresent = true;
    this.udfData = data;
    return data;
  }), _dec11 = (0, _emberConcurrency.task)(function* (udfIDObj) {
    var adminUdf = null;

    try {
      adminUdf = yield this.store.findRecord('admin-udf', udfIDObj.id);
    } catch (err) {
      return;
    }

    if (adminUdf == null) {
      return;
    }

    var model_udf = this.adminUdfToUdf[adminUdf];
    var adminUdfObj = this.createAdminUdfObj(adminUdf, model_udf);
    this.udfObjs[udfIDObj.id] = _objectSpread(_objectSpread({}, adminUdfObj), {}, {
      required: udfIDObj.required,
      validation_key: 'udf-' + Ember.String.underscore(adminUdf.name) + '-value',
      conditions: udfIDObj.conditions ? udfIDObj.conditions : []
    });
  }), _dec12 = (0, _emberConcurrency.task)(function* () {
    var adminUdfs = yield this.store.query('adminUdf', {
      objectType: 'customer'
    }, {
      cacheResponse: true,
      cacheForMilliseconds: 172800000,
      cacheForNumAccessed: 0,
      forceCacheReload: false,
      useLocalStorage: false
    });
    return adminUdfs;
  }), _dec13 = (0, _emberConcurrency.task)(function* () {
    var adminUdfs = yield this.getAdminUdfs.perform();
    var operands = [];
    adminUdfs.forEach(adminUdf => {
      operands.push({
        option: adminUdf.name,
        value: adminUdf.name
      });
    });
    return operands;
  }), _dec14 = (0, _emberConcurrency.task)(function* () {
    var adminUdfs = yield this.getAdminUdfs.perform();
    var dynamicUdfOperators = {
      none: {
        type: 'none',
        options: []
      }
    };

    for (var index = 0; index < adminUdfs.length; index++) {
      var currAdminUdf = adminUdfs.objectAt(index);
      Ember.set(dynamicUdfOperators, currAdminUdf.name, this.createAdminUdfObj(currAdminUdf));
    }

    return dynamicUdfOperators;
  }), _dec15 = (0, _emberConcurrency.task)(function* (curUdf) {
    var admin = yield curUdf.get('adminUdf');
    this.adminUdfToUdf[admin] = curUdf;
  }), _dec16 = (0, _emberConcurrency.task)(function* (model) {
    var promiseArray = [];
    Object.values(this.udfObjs).forEach(adminUdfObj => {
      if (adminUdfObj.adminUdf.valueType == 'date' && adminUdfObj.udf_value) {
        // Change all date types to proper format before attempting to save
        adminUdfObj.udf_value = (0, _moment.default)(adminUdfObj.udf_value).format('YYYY-MM-DD');
      }

      if (adminUdfObj.model_udf) {
        Ember.set(adminUdfObj.model_udf, 'value', adminUdfObj.udf_value);
        promiseArray.push(adminUdfObj.model_udf.save());
      } else if (adminUdfObj.udf_value != null) {
        var new_model_udf = this.store.createRecord('udf', {
          value: adminUdfObj.udf_value,
          adminUdf: adminUdfObj.adminUdf,
          [Ember.String.camelize(model.constructor.modelName)]: model
        });
        Ember.set(adminUdfObj, 'model_udf', new_model_udf);
        promiseArray.push(new_model_udf.save());
      }
    });
    yield (0, _emberConcurrency.all)(promiseArray);
    this.model = model;
  }), _dec17 = Ember._action, (_class = (_temp = class UserDefinedFields {
    constructor(args) {
      _initializerDefineProperty(this, "udfObjs", _descriptor, this);

      _initializerDefineProperty(this, "adminUdfToUdf", _descriptor2, this);

      _initializerDefineProperty(this, "udfData", _descriptor3, this);

      _initializerDefineProperty(this, "udfIDs", _descriptor4, this);

      _initializerDefineProperty(this, "sections", _descriptor5, this);

      _initializerDefineProperty(this, "udfsPresent", _descriptor6, this);

      _initializerDefineProperty(this, "model", _descriptor7, this);

      _defineProperty(this, "miscInputList", undefined);

      _initializerDefineProperty(this, "populateSectionDataTask", _descriptor8, this);

      _initializerDefineProperty(this, "udfListTask", _descriptor9, this);

      _initializerDefineProperty(this, "getUdfTask", _descriptor10, this);

      _initializerDefineProperty(this, "getAdminUdfs", _descriptor11, this);

      _initializerDefineProperty(this, "miscUdfFilterOperands", _descriptor12, this);

      _initializerDefineProperty(this, "getAllAdminUdfObj", _descriptor13, this);

      _initializerDefineProperty(this, "setupDictionary", _descriptor14, this);

      _initializerDefineProperty(this, "saveUdfsTask", _descriptor15, this);

      Ember.setProperties(this, args);
      this.MiscInputList = new _miscInputList.default({
        store: this.store,
        intl: this.intl
      });
    } // Get a list of UDFS
    // Get a list of sections with lists of UDFS


    get modelSectionUDFS() {
      if (this.model) {
        var modelUdfs = this.model.get('udfs');
        modelUdfs.forEach(udf => {
          var adminUdf = udf.get('adminUdf');

          for (var [key, value] of Object.entries(this.udfObjs)) {
            if (value.adminUdf.get('id') == adminUdf.get('id')) {
              var newValue = udf.value;

              if (adminUdf.get('valueType') === 'checkbox') {
                newValue = udf.value == 'true' || udf.value == true;
              } else if (adminUdf.get('valueType') === 'date') {
                newValue = udf.value && udf.value != '' ? (0, _moment.default)(udf.value).format('MM/DD/YYYY') : null;
              }

              Ember.set(this.udfObjs[key], 'udf_value', newValue);
              Ember.set(this.udfObjs[key], 'model_udf', udf);
              break;
            }
          }
        });
      }

      return this.sections;
    }

    createAdminUdfObj(adminUdf, model_udf = null) {
      var switchableComponentPath = 'multi-step-form/switchable-inputs/';
      var options = [];
      var udf_value = model_udf ? model_udf.get('value') : null;
      var udfType;
      var type = 'text';

      switch (adminUdf.valueType) {
        case 'input':
          udfType = 'udf_input';
          options = this.MiscInputList.miscFilterOperators();
          switchableComponentPath += 'text-input';
          break;

        case 'date':
          udfType = 'udf_date';
          options = this.MiscInputList.checkboxOptions();
          switchableComponentPath += 'date-input';

          if (model_udf && udf_value == '') {
            udf_value = null;
          }

          break;

        case 'checkbox':
          udfType = 'udf_checkbox';
          switchableComponentPath += 'checkbox-input';

          if (model_udf) {
            udf_value = udf_value == 'true';
          }

          break;

        case 'dropdown-list-method':
          udfType = 'udf_dropdown_method';
          switchableComponentPath += 'select-input';
          options = adminUdf.responseMeta.list_options;
          break;

        case 'dropdown-prelist':
          udfType = 'udf_dropdown_prelist';
          switchableComponentPath += 'select-input';
          options = adminUdf.valueInfo.split(',').map(value => ({
            value,
            display: value
          }));
          break;

        case 'input-int':
          udfType = 'udf_input';
          switchableComponentPath += 'text-input';
          type = 'number';
          options = [{
            option: this.intl.t('common.filters.equals'),
            value: '='
          }];
          break;

        case 'input-float':
          udfType = 'udf_input';
          type = 'number';
          switchableComponentPath += 'text-input';
          options = [{
            option: this.intl.t('common.filters.equals'),
            value: '='
          }];
          break;

        default:
          udfType = 'none';
          break;
      }

      return Ember.Object.create({
        adminUdf,
        name: adminUdf.name,
        model_udf,
        udf_value,
        options,
        switchableComponentPath,
        udfType: udfType,
        type: type,
        id: adminUdf.id
      });
    }

    formatUdfFilterStrings(data) {
      if (data.miscUdfFilter) {
        var orFilter;
        var operatorObject = data.dynamicUdfOperators[data.miscUdfFilter.operand];

        if (operatorObject && operatorObject.udfType === 'udf_input' && data.miscUdfFilter.operator && data.miscUdfFilter.value) {
          var operator = data.miscUdfFilter.operator.includes('~') ? '~' : '=';
          var value = data.miscUdfFilter.operator === '~%' ? '\'' + data.miscUdfFilter.value + '%\'' : data.miscUdfFilter.operator === '%~%' ? '\'%' + data.miscUdfFilter.value + '%\'' : '\'' + data.miscUdfFilter.value + '\'';
          orFilter = operatorObject.udfType + operator + value;
        } else if (operatorObject && (operatorObject.udfType === 'udf_input' || operatorObject.udfType === 'udf_input') && data.miscUdfFilter.operator && data.miscUdfFilter.value) {
          orFilter = operatorObject.udfType + ' = ' + data.miscUdfFilter.operator;
        } else if (operatorObject && operatorObject.udfType === 'udf_dropdown_method' && data.miscUdfFilter.operator) {
          orFilter = operatorObject.udfType + ' = ' + data.miscUdfFilter.operator;
        } else if (operatorObject && operatorObject.udfType === 'udf_dropdown_prelist' && data.miscUdfFilter.operator) {
          orFilter = operatorObject.udfType + ' = ' + '\'' + data.miscUdfFilter.operator + '\'';
        } else if (operatorObject && operatorObject.udfType === 'udf_checkbox' && data.miscUdfFilter.operator) {
          orFilter = operatorObject.udfType + ' = ' + data.miscUdfFilter.operator;
        } else if (operatorObject && operatorObject.udfType === 'udf_date' && (data.miscUdfFilter.fromDate || data.miscUdfFilter.toDate)) {
          var fromDate = data.miscUdfFilter.fromDate ? (0, _moment.default)(data.miscUdfFilter.fromDate, ['MM/DD/YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD') : null;
          var toDate = data.miscUdfFilter.toDate ? (0, _moment.default)(data.miscUdfFilter.toDate, ['MM/DD/YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD') : null;

          if (fromDate) {
            orFilter = operatorObject.udfType + ' >= \'' + fromDate + '\'';
          }

          if (toDate) {
            if (fromDate) {
              orFilter += ' or ' + operatorObject.udfType + ' <= \'' + toDate + '\'';
            } else {
              orFilter = operatorObject.udfType + ' <= \'' + toDate + '\'';
            }
          }
        }

        return {
          udfID: operatorObject.id,
          orFilter
        };
      }

      return false;
    }

    validateUdfs(formattedObjects) {
      Object.values(this.udfObjs).forEach(adminUdfObj => {
        var tempUdf = Ember.Object.create({
          value: adminUdfObj.udf_value
        });
        formattedObjects.pushObject(Ember.Object.create({
          modelName: 'udf',
          model: tempUdf,
          extraValidationData: {
            overrideValidationKey: 'udf-' + Ember.String.underscore(adminUdfObj.name),
            noValidate: adminUdfObj.required ? [] : ['value']
          }
        }));
      });
    }

    checkConditions() {
      Object.values(this.udfObjs).forEach(udfObj => {
        if (udfObj.conditions && udfObj.conditions.length > 0) {
          // if conditions are met, change required to true
          // else set required to false
          var conditionsMet = udfObj.conditions.every(condition => {
            return this.udfObjs[condition.id].udf_value == condition.validCondition;
          });
          Ember.set(udfObj, 'required', conditionsMet);
        }
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "udfObjs", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "adminUdfToUdf", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "udfData", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "udfIDs", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sections", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "udfsPresent", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "modelSectionUDFS", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "modelSectionUDFS"), _class.prototype), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "populateSectionDataTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "udfListTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "getUdfTask", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "getAdminUdfs", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "miscUdfFilterOperands", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "getAllAdminUdfObj", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "setupDictionary", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "saveUdfsTask", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "checkConditions", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "checkConditions"), _class.prototype)), _class));
  _exports.default = UserDefinedFields;
});