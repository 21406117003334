define("securitytrax-ember-ui/validations/equipment-request-form", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateObject = {
        equipmentName: {
          presence: this.customMessageOrDefault(data, 'equipmentName')
        },
        type: {
          presence: this.customMessageOrDefault(data, 'type')
        },
        subtype: {
          presence: this.customMessageOrDefault(data, 'subtype')
        },
        manufacturer: {
          presence: this.customMessageOrDefault(data, 'manufacturer')
        },
        model: {
          presence: this.customMessageOrDefault(data, 'model')
        },
        contactName: {
          presence: this.customMessageOrDefault(data, 'name')
        },
        contactEmail: {
          presence: this.customMessageOrDefault(data, 'email'),
          format: {
            pattern: /\S+@\S+\.\S+/,
            //Basic test for text@text.text
            message: this.customMessage(data, 'receiptEmailInvalid')
          }
        },
        contactPhone: {
          presence: this.customMessageOrDefault(data, 'phone')
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      return this.outputFormatting(validationData, 'equipmentRequest');
    }

  });

  _exports.default = _default;
});