define("securitytrax-ember-ui/components/multi-step-form/forms/customer-contacts/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency"], function (_exports, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    langs: Ember.inject.service(),
    emergencyContacts: Ember.inject.service(),
    grants: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    hasAnEcv: false,
    confirmDeleteModalOpen: false,
    defaultEcv: false,
    phoneTypeList: Ember.computed(function () {
      return this.emergencyContacts.phoneTypeList();
    }),
    langsList: Ember.computed(function () {
      return this.langs.langsList;
    }),
    checkboxOptions: Ember.computed(function () {
      var intl = this.intl;
      return {
        on_label: intl.t('common.status.on'),
        off_label: intl.t('common.status.off')
      };
    }),
    customerContactsChanged: Ember.observer('customerContactsList', function () {
      this.hasAnEcvTask.perform();
    }),
    hasAnEcvTask: (0, _emberConcurrency.task)(function* () {
      var hasAnEcv = false;
      var customerContactsList = yield this.customerContactsList;
      customerContactsList = customerContactsList ? customerContactsList.toArray() : null;

      if (customerContactsList && customerContactsList.length > 0) {
        hasAnEcv = customerContactsList.some(item => {
          return item.get('enhancedVerification') == true;
        });
      }

      Ember.set(this, 'hasAnEcv', hasAnEcv);
      yield this.multiStepForm.setupCopyModels.perform();

      if (this.isNewRecord) {
        var hashedData = yield (0, _emberConcurrency.hash)({
          waitForFinishCopyModels: (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true),
          formDefaultOptions: this.sessionData.getCompanyDefaults('customerContactDefault')
        });
        this.multiStepForm.formCopiedModelsSetVal('customerContact.model.enhancedVerification', false);

        if (hashedData.formDefaultOptions) {
          this.multiStepForm.formCopiedModelsSetVal('customerContact.model.about', hashedData.formDefaultOptions.phoneType);
          this.multiStepForm.formCopiedModelsSetVal('customerContact.model.lang', hashedData.formDefaultOptions.primaryLanguage);
          Ember.set(this, 'defaultEcv', hashedData.formDefaultOptions.enhancedVerification);
        }
      } else {
        yield (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true);
      }
    }).on('init'),
    defaultEnhancedVerificationValue: Ember.computed('multiStepForm.formCopiedModels.customerContact.model', function () {
      if (this.defaultEcv) {
        if (!this.hasAnEcv) {
          this.multiStepForm.formCopiedModelsSetVal('customerContact.model.enhancedVerification', true);
          return true;
        }
      }

      return this.multiStepForm.formCopiedModelsGetVal('customerContact.model.enhancedVerification');
    }),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      var buttons = [];

      if (this.formMode !== 'view') {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveCustomerContactsTask.perform(buttonPromise, multiStepForm);
          },
          classNamesString: 'st-btn st-btn-primary emergency-contact-save-button'
        });
      }

      if (!this.isNewRecord && this.grants.getIndividualGrant('customer_contacts.delete')) {
        buttons.push({
          text: this.intl.t('common.actions.delete'),
          click: () => {
            this.set('confirmDeleteModalOpen', true);
          },
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
        });
      }

      multiStepForm.registerStepData('step1', 'buttons', buttons);
    },

    saveCustomerContactsTask: (0, _emberConcurrency.task)(function* (buttonPromise, multiStepForm) {
      var isAdt = this.isADT !== undefined ? this.isADT : yield this.get('sessionData.companyHasSpecificMonitorTask').perform();
      var extraValidationData = {
        // company sesstion
        adtValidation: isAdt,
        conditionalValidation: multiStepForm.formCopiedModels.customerContact.model.about === 'Pin' ? ['customerContact-pin'] : ['customerContact-phone1'],
        validationMessages: {
          nameInvalid: this.intl.t('pages.customers.emergency_contacts.fields.name_invalid'),
          noPin: '^' + this.intl.t('pages.customers.emergency_contacts.fields.noPin'),
          phoneInvalid: this.intl.t(isAdt ? 'pages.customers.emergency_contacts.fields.phone_invalid' : 'common.nav.create.customer.primaryPhone.validateMessage')
        }
      };
      var customer = yield this.customer;

      if (this.isNewRecord) {
        var hashData = yield (0, _emberConcurrency.hash)({
          toCreateObjects: multiStepForm.validateCreateAll({
            extraValidationData,
            notificationNamespace: 'modal'
          }),
          contacts: customer.get('customerContacts')
        });
        Ember.set(hashData.toCreateObjects.customerContact.data, 'customer', customer);
        Ember.set(hashData.toCreateObjects.customerContact.data, 'sort', hashData.contacts.length + 1);
        var customerContact = this.store.createRecord('customerContact', hashData.toCreateObjects.customerContact.data);
        yield hashData.toCreateObjects.customerContact.saveWithRollbackAndNotification(customerContact);
      } else {
        yield multiStepForm.validateSyncSaveAll({
          extraValidationData,
          notificationNamespace: 'modal'
        });
      }

      buttonPromise.then(() => {
        this.notifyPropertyChange('customer.customerContacts');

        if (this.refreshCustomerContacts) {
          this.refreshCustomerContacts();
        }

        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'global');

        if (this.transitionBackToParent && typeof this.transitionBackToParent === 'function') {
          this.transitionBackToParent();
        }
      });
    }),
    deleteCustomerContact: (0, _emberConcurrency.task)(function* () {
      try {
        var multiStepForm = this.multiStepForm;
        var contactToDelete = multiStepForm.formOriginalModelsGetVal('customerContact.model');
        yield contactToDelete.destroyRecordWithRollbackTask.perform();
        yield multiStepForm.setupCopyModels.perform();
        yield this.customer.hasMany('customerContacts').reload();

        if (this.refreshCustomerContacts) {
          this.refreshCustomerContacts();
        }

        if (this.transitionBackToParent && typeof this.transitionBackToParent === 'function') {
          this.transitionBackToParent();
        }

        this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err);
        throw err;
      }
    }),
    actions: {
      updatePhoneType(type) {
        if (type && type.value) {
          this.multiStepForm.formCopiedModelsSetVal('customerContact.model.about', type.value);
        }
      },

      updateRelation(relation) {
        this.set('model.relation', relation);
      },

      updateLang(lang) {
        if (lang && lang.value) {
          this.multiStepForm.formCopiedModelsSetVal('customerContact.model.lang', lang.value);
        }
      }

    }
  });

  _exports.default = _default;
});