define("securitytrax-ember-ui/models/base_models/parent-entity-accounting-information", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    useType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['default', 'invoice', 'invoice_statement', 'quote', 'credit_memo', 'refund_receipt']
    }),
    usePrimary: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    emails: (0, _model.attr)('string', {
      'readOnly': false
    }),
    primaryPhone: (0, _model.attr)('string', {
      'readOnly': false
    }),
    firstName: (0, _model.attr)('string', {
      'readOnly': false
    }),
    lastName: (0, _model.attr)('string', {
      'readOnly': false
    }),
    address1: (0, _model.attr)('string', {
      'readOnly': false
    }),
    address2: (0, _model.attr)('string', {
      'readOnly': false
    }),
    city: (0, _model.attr)('string', {
      'readOnly': false
    }),
    state: (0, _model.attr)('string', {
      'readOnly': false
    }),
    zip: (0, _model.attr)('string', {
      'readOnly': false
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    parentEntity: (0, _model.belongsTo)('parent-entity', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});