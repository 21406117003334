define("securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-interactions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "taoPZNqs",
    "block": "{\"symbols\":[\"multiStepForm\",\"MultiStepFormComponent\"],\"statements\":[[8,\"html/st-await\",[],[[\"@useSpinner\",\"@booleanLoaded\"],[false,[34,2,[\"isIdle\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"multi-step-form\",[],[[\"@formSteps\",\"@formOriginalModels\",\"@formStarted\",\"@changeFormStarted\"],[[34,3],[34,4],[34,5],[30,[36,1],[[32,0],[30,[36,6],[[35,5]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"modals/st-paper\",[],[[\"@modalButtons\",\"@title\",\"@dialogOpen\",\"@multiStepForm\",\"@hasProgressBar\",\"@fullscreen\",\"@modalNamespace\",\"@classNames\",\"@onClose\"],[[32,1,[\"currentStepData\",\"buttons\"]],[32,1,[\"currentStepData\",\"title\"]],[32,1,[\"formStarted\"]],[32,1],false,false,\"addLeadInteractionModal\",\"st-modal--medium add-lead-interaction\",[30,[36,1],[[32,0],\"transitionBackToParentRoute\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[8,[32,2],[],[[\"@multiStepForm\",\"@formMode\",\"@lead\",\"@transitionBackToParent\"],[[32,1],[34,0],[32,0,[\"customer\"]],[30,[36,1],[[32,0],\"transitionBackToParentRoute\"],null]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"interactionFormMode\",\"action\",\"initData\",\"addInteractionFormSteps\",\"addInteractionFormModels\",\"addLeadInteractionFormStarted\",\"mut\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-interactions/template.hbs"
    }
  });

  _exports.default = _default;
});