define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/partner-companies/create/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/partner-companies/base-partner-companies"], function (_exports, _basePartnerCompanies) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basePartnerCompanies.default.extend({
    intl: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;

      if (this.isNewRecord) {
        multiStepForm.registerStepData("step1", 'buttons', [{
          text: this.intl.t('common.actions.cancel'),
          click: () => {
            multiStepForm.resetForm(false);
            multiStepForm.formStartedSetVal(false);
          },
          classNamesString: 'st-btn btn-secondary partner-companies-cancel-button'
        }, {
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveLeadCompanyTask.perform(multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary partner-companies-save-button'
        }]);
      }
    }

  });

  _exports.default = _default;
});