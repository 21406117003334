define("securitytrax-ember-ui/components/nav/footer-nav/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    screen: Ember.inject.service(),
    tagName: '',
    thisYear: (0, _moment.default)()
  });

  _exports.default = _default;
});