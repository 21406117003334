define("securitytrax-ember-ui/components/calendar-glimmer/calendar-header/component", ["exports", "@glimmer/component", "moment"], function (_exports, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CalendarHeader = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service('events/calendar-events'), _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, (_class = (_temp = class CalendarHeader extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "inViewport", _descriptor, this);

      _initializerDefineProperty(this, "scroller", _descriptor2, this);

      _initializerDefineProperty(this, "CalendarDataService", _descriptor3, this);

      _initializerDefineProperty(this, "CalendarDatePickerService", _descriptor4, this);

      _initializerDefineProperty(this, "CalendarEventsService", _descriptor5, this);

      _initializerDefineProperty(this, "showSummary", _descriptor6, this);

      _initializerDefineProperty(this, "isMainHeader", _descriptor7, this);

      _initializerDefineProperty(this, "isFixed", _descriptor8, this);

      _initializerDefineProperty(this, "isActive", _descriptor9, this);

      _initializerDefineProperty(this, "intersectionObserver", _descriptor10, this);

      _initializerDefineProperty(this, "selectedDates", _descriptor11, this);

      _initializerDefineProperty(this, "isTopPage", _descriptor12, this);

      _defineProperty(this, "onlyShowDate", false);

      _defineProperty(this, "isPrimaryCal", false);

      if (this.args.index === 0) {
        this.isMainHeader = true;
        this.isActive = true;
        this.isTopPage = true;
      }

      this.selectedDates = this.CalendarDatePickerService.selectedDates;
      this.CalendarEventsService.on('scrollToPage', this, '_scrollToPage');
      this.CalendarEventsService.on('changeCurrentPage', this, '_changeCurrentPage');
      this.CalendarEventsService.on('scrollHorizontal', this, '_scrollHorizontal');
      this.CalendarEventsService.on('selectedDateChange', this, '_selectedDateChange');
    }

    datePickerActiveClass(index) {
      if (this.selectedDates.objectAt(index).date === this.args.calendarDate.date) {
        return 'active';
      }

      return '';
    }

    get headerClasses() {
      var headerClasses = [];

      if (this.args.isUser) {
        headerClasses.push('cal-user-header');
      }

      return headerClasses.join(' ');
    }

    get calendarDates() {
      if (this.args.isScheduler) {
        // filter dates before today    moment().isBefore(Moment|String|Number|Date|Array);
        var calendarDates = this.args.calendarDates.filter(date => {
          return (0, _moment.default)().startOf('day').isSameOrBefore(date.date);
        });
        return calendarDates;
      }

      return this.args.calendarDates;
    }

    get bottomVisible() {
      var element = document.getElementById('calendar-header-' + this.args.index);
      var bottom = element.getBoundingClientRect().bottom;
      return window.innerHeight > bottom;
    }

    willDestroy() {
      this.CalendarEventsService.off('scrollToPage', this, '_scrollToPage');
      this.CalendarEventsService.off('changeCurrentPage', this, '_changeCurrentPage');
      this.CalendarEventsService.off('scrollHorizontal', this, '_scrollHorizontal');
      this.CalendarEventsService.off('selectedDateChange', this, '_selectedDateChange');
      var header = document.getElementById('calendar-header-' + this.args.index);
      this.inViewport.stopWatching(header);
    }

    _scrollToPage()
    /* index */
    {
      this._showSummary(false);
    }

    _selectedDateChange() {
      this.selectedDates = this.CalendarDatePickerService.selectedDates;

      if (this.selectedDates.objectAt(0).date === this.args.calendarDate.date) {
        this.isActive = true;
        this.isMainHeader = true;
        this.isTopPage = true;
      } else {
        this.isTopPage = false;
      }
    }

    get showNav() {
      return this.isMainHeader && this.selectedDates.length > 1 && this.bottomVisible;
    }

    _showSummary(show) {
      this.showSummary = show;
    }

    didExitViewport() {
      this.isFixed = true;
      this.isActive = true;
      this.isMainHeader = true;
    }

    didEnterViewport() {
      this.isFixed = false;

      if (!this.isTopPage) {
        this.isActive = false;
        this.isMainHeader = false;
      }
    }

    _scrollHorizontal(index, scrollLeft) {
      if (index === this.args.index) {
        if (this.args.showListView) {
          var theGrid = document.querySelector("#calendar-header-".concat(this.args.index) + ' .css-grid-list__row');
          theGrid.scrollLeft = scrollLeft;
        } else {
          var _theGrid = document.querySelector("#calendar-header-".concat(this.args.index) + ' .grid__row.calendar__header-row--grid');

          _theGrid.scrollLeft = scrollLeft;
        }
      }
    }

    bindIntersectionObserver(element) {
      var viewportTolerance = {
        top: -1,
        left: 0,
        bottom: 0,
        right: 0
      };
      var intersectionThreshold = 1;
      var scrollableArea = this.args.scrollContext;
      var {
        onEnter,
        onExit
      } = this.inViewport.watchElement(element, {
        viewportTolerance,
        intersectionThreshold,
        scrollableArea
      });
      onEnter(this.didEnterViewport.bind(this));
      onExit(this.didExitViewport.bind(this));
    }

    scrollTo(index) {
      this.CalendarEventsService.trigger('scrollToPage', index);
      this.active = true;
    }

    showSummaryAction() {
      this._showSummary(!this.showSummary);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "inViewport", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "scroller", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "CalendarDataService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "CalendarDatePickerService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "CalendarEventsService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "showSummary", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isMainHeader", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isFixed", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "isActive", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "intersectionObserver", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "selectedDates", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "isTopPage", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "bindIntersectionObserver", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "bindIntersectionObserver"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "scrollTo", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "scrollTo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showSummaryAction", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "showSummaryAction"), _class.prototype)), _class));
  _exports.default = CalendarHeader;
});