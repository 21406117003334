define("securitytrax-ember-ui/models/calendar-event-report", ["exports", "securitytrax-ember-ui/models/base_models/calendar-event-report"], function (_exports, _calendarEventReport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _calendarEventReport.default.extend({});

  _exports.default = _default;
});