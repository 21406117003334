define("securitytrax-ember-ui/services/permissions-service", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Service to manage GROUP permissions, resource types, grants, and policies
   *
   * @class PermissionsService
   * @extends Ember.Service
   * @memberof services
   */
  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    sessionData: Ember.inject.service('sessionData'),
    store: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    _currentGroup: undefined,
    _groupResourceTypePolicies: undefined,
    _parsedGroupResourceTypeGrants: undefined,
    _parsedResourceTypeList: undefined,
    _resourceTypes: undefined,
    getResourceTypes: (0, _emberConcurrency.task)(function* () {
      try {
        if (this._resourceTypes) {
          return this._resourceTypes;
        } else {
          var resourceTypes = yield this.store.findAll('resourceType');
          this.set('_resourceTypes', resourceTypes);
          return resourceTypes;
        }
      } catch (err) {
        (false && Ember.warn('HR group details get resource types error: ' + err, {
          id: 'services.permissionsService.resourceTypesTask'
        }));
        throw err;
      }
    }).enqueue().maxConcurrency(1),
    getParseGroupResourceTypeGrants: (0, _emberConcurrency.task)(function* (group) {
      // On init, or coming in here after change to policies?
      if (this._parsedGroupResourceTypeGrants && this._currentGroup == group) {
        return this._parsedGroupResourceTypeGrants;
      } else {
        this.set('_currentGroup', group);
      }

      var resourceTypeGrants = group ? yield group.get('resourceTypeGrants') : [];
      var parsedSelectedGrants = {};

      for (var x = 0; x < resourceTypeGrants.get('length'); x++) {
        var currGrant = resourceTypeGrants.objectAt(x);
        parsedSelectedGrants[currGrant.get('id')] = currGrant;
      }

      this.set('_parsedGroupResourceTypeGrants', parsedSelectedGrants);
      return parsedSelectedGrants;
    }),
    getGroupPolicyList: (0, _emberConcurrency.task)(function* (group) {
      if (this._currentGroup == group && this._groupResourceTypePolicies) {
        return this._groupResourceTypePolicies;
      } else {
        var policies = yield group.get('groupResourceTypePolicies');
        this.set('_groupResourceTypePolicies', policies);
        return policies;
      }
    }),
    getParsedResourceTypeList: (0, _emberConcurrency.task)(function* (group, subResourceTypeList = null) {
      var parsedGroupResourceTypeGrants = this._parsedGroupResourceTypeGrants ? this._parsedGroupResourceTypeGrants : yield this.getParseGroupResourceTypeGrants.perform(group);
      return this.parseResourceTypes.perform(group, subResourceTypeList !== null && subResourceTypeList !== void 0 ? subResourceTypeList : this._resourceTypes, parsedGroupResourceTypeGrants);
    }),
    parseResourceTypes: (0, _emberConcurrency.task)(function* (group, resourceTypes, parsedGroupResourceTypeGrants, treeLevel = 0, countList = []) {
      var allPromises = [];
      var parsedResourceTypeList;
      var hashedData = yield (0, _emberConcurrency.hash)({
        isSuperUser: this.sessionData.isSuperUser,
        resourceTypes: resourceTypes ? yield resourceTypes : yield this.getResourceTypes.perform()
      });

      if (treeLevel === 0) {
        parsedResourceTypeList = {};
      } else if (treeLevel > 0) {
        parsedResourceTypeList = [];
      }

      try {
        for (var i = 0; i < hashedData.resourceTypes.get('length'); i++) {
          var currResourceType = hashedData.resourceTypes.objectAt(i);
          allPromises.push(this.parseResourceTypeTask.perform(currResourceType, group, parsedGroupResourceTypeGrants, treeLevel, countList, parsedResourceTypeList, hashedData.isSuperUser));
        }

        yield (0, _emberConcurrency.all)(allPromises);
        this.set('_parsedResourceTypeList', parsedResourceTypeList);
        return parsedResourceTypeList;
      } catch (err) {
        (false && Ember.warn('group permissions parse resource types error' + err, {
          id: 'services.permissionsService.parseResourceTypes'
        }));
        throw err;
      }
    }),
    parseResourceTypeTask: (0, _emberConcurrency.task)(function* (currResourceType, group, parsedGroupResourceTypeGrants, treeLevel, countList, parsedResourceTypeList, isSuperUser) {
      var currSectionKey = currResourceType.get('sectionKey');
      var hashedData = yield (0, _emberConcurrency.hash)({
        parentResourceType: currResourceType.get('parentResourceType'),
        childResults: this.parseResourceTypes.perform(group, currResourceType.get('childResourceTypes'), parsedGroupResourceTypeGrants, treeLevel + 1, countList),
        resourceGrants: currResourceType.get('resourceTypeGrants'),
        resourceTypePolicies: currResourceType.get('resourceTypePolicies')
      }); // traverse if
      // -- this is top level and the resource has no parent (is top level)
      // -- or if it's not top level

      if (!hashedData.parentResourceType && treeLevel === 0 || treeLevel > 0) {
        var isCheckedCount = 0;
        var resourceTypeGrants = currResourceType.get('resourceTypeGrants');
        resourceTypeGrants.forEach(function (currResourceTypeGrant) {
          if (parsedGroupResourceTypeGrants[currResourceTypeGrant.get('id')]) {
            currResourceTypeGrant.set('isChecked', true);

            if (treeLevel <= 1) {
              isCheckedCount++;
            }
          } else {
            currResourceTypeGrant.set('isChecked', false);
          }
        });
        var childItems = [];
        var resourceGrantsChecked = hashedData.resourceGrants.filter(item => {
          return item.isChecked;
        });
        var allGrantsChecked = hashedData.resourceGrants.every(item => {
          return item.isChecked;
        });
        currResourceType.set('bulkCheck', allGrantsChecked); // How many are checked?

        var currCountChecked = currResourceType.get('sectionKey') in countList && 'totalChecked' in countList[currResourceType.get('sectionKey')] ? Number(countList[currSectionKey]['totalChecked']) : 0;

        if (currSectionKey in countList) {
          countList[currSectionKey]['totalChecked'] = currCountChecked + isCheckedCount;
          countList[currSectionKey]['totalFilteredChecked'] = countList[currSectionKey]['totalChecked'];
        } else {
          countList[currSectionKey] = {
            totalChecked: isCheckedCount
          };
        }

        childItems.pushObject({
          resourceTypeId: currResourceType.id,
          resourceType: currResourceType,
          resourceGrants: hashedData.resourceGrants,
          resourceGrantsPackageSelect: hashedData.resourceGrants.map(item => {
            var packageInfo = null;
            var translationKeyExists = this.intl.exists('grants.' + item.get('key') + '.description');

            if (translationKeyExists) {
              packageInfo = this.intl.t('grants.' + item.get('key') + '.description');
            }

            return {
              packageName: item.get('name'),
              packageId: item.id,
              packageInfo: packageInfo
            };
          }),
          resourceGrantsSelected: resourceGrantsChecked.map(item => {
            return item.id;
          }),
          addedGrants: [],
          removedGrants: [],
          indeterminate: resourceGrantsChecked.length > 0 && resourceGrantsChecked.length != hashedData.resourceGrants.length,
          resourceTypePolicies: hashedData.resourceTypePolicies,
          //resourcePoliciesText: this.get('getParsedGroupResourceTypePolicies').perform(group, currResourceType),
          treeLevel: treeLevel,
          isParent: hashedData.childResults.length > 0,
          childResources: hashedData.childResults
        });
        childItems.addObjects(hashedData.childResults);

        if (treeLevel === 0) {
          var shouldHideCurrSection = !isSuperUser && !currSectionKey;

          if (!parsedResourceTypeList[currSectionKey]) {
            var sectionKey = 'no_section_key';

            if (currSectionKey) {
              sectionKey = currSectionKey;
            }

            parsedResourceTypeList[currSectionKey] = {
              items: [],
              sectionName: this.intl.t('pages.users.human_resources.permissions_section_labels.' + sectionKey),
              sectionKey: sectionKey,
              hideSection: shouldHideCurrSection
            };
          }

          var allChildItemsBulkChecked = childItems.every(item => {
            return item.resourceType.bulkCheck;
          });
          parsedResourceTypeList[currSectionKey].items.pushObject({
            items: childItems,
            filteredItems: childItems,
            name: currResourceType.get('name'),
            bulkCheck: allChildItemsBulkChecked,
            resourceTypeId: currResourceType.id
          });
          parsedResourceTypeList[currSectionKey].filteredItems = parsedResourceTypeList[currSectionKey].items; // get total length of all children grants

          var totalLength = childItems.reduce(function (accumulator, currentItem) {
            var acc = typeof accumulator == 'number' ? accumulator : accumulator.resourceGrants.length;
            return acc + currentItem.resourceGrants.length;
          });

          if (typeof totalLength == 'object') {
            totalLength = totalLength.resourceGrants.length;
          }

          var currCount = 'totalItems' in parsedResourceTypeList[currSectionKey] ? Number(parsedResourceTypeList[currSectionKey]['totalItems']) : 0;
          parsedResourceTypeList[currSectionKey]['totalItems'] = currCount + totalLength;
          parsedResourceTypeList[currSectionKey]['totalFilteredItems'] = parsedResourceTypeList[currSectionKey]['totalItems'];

          if (currSectionKey in parsedResourceTypeList) {
            parsedResourceTypeList[currSectionKey]['totalChecked'] = countList[currSectionKey].totalChecked;
            parsedResourceTypeList[currSectionKey]['totalFilteredChecked'] = parsedResourceTypeList[currSectionKey]['totalChecked'];

            if (parsedResourceTypeList[currSectionKey]['totalItems'] != 0 && parsedResourceTypeList[currSectionKey]['totalItems'] == parsedResourceTypeList[currSectionKey]['totalChecked']) {
              parsedResourceTypeList[currSectionKey]['bulkSelect'] = true;
              parsedResourceTypeList[currSectionKey]['indeterminate'] = false;
            } else if (parsedResourceTypeList[currSectionKey]['totalChecked'] > 0) {
              parsedResourceTypeList[currSectionKey]['indeterminate'] = true;
            }
          }
        } else if (treeLevel > 0) {
          parsedResourceTypeList.addObjects(childItems);
        }
      }
    }),
    getAttributesList: (0, _emberConcurrency.task)(function* (resourceType) {
      var introspection;

      try {
        introspection = yield resourceType.invokeAction('introspect', {
          verb: 'GET',
          data: {
            data: {}
          }
        });
      } catch (err) {// Do Nothing
      }

      var attributesList = {
        attributes: [],
        metaAttributes: []
      };

      if (introspection && 'attributes' in introspection.responsePayload.data && introspection.responsePayload.data.attributes != null) {
        Object.keys(introspection.responsePayload.data.attributes).forEach(function (key) {
          attributesList.attributes.push(key);
        });
      }

      if (introspection && 'meta' in introspection.responsePayload.data && introspection.responsePayload.data.meta != null) {
        Object.keys(introspection.responsePayload.data.meta).forEach(function (key) {
          attributesList.metaAttributes.push(key);
        });
      }

      return attributesList;
    }),
    bulkAddOrRemoveResourceTypeGrants: (0, _emberConcurrency.task)(function* (resourceType, lowestLevel = true) {
      var hashedData = yield (0, _emberConcurrency.hash)({
        resourceTypeGrants: resourceType.get('resourceTypeGrants'),
        resourceTypeList: this._parsedResourceTypeList
      });
      var parsedGroupResourceTypeGrants = this._parsedGroupResourceTypeGrants;
      var checkedStateBefore = lowestLevel ? resourceType.bulkCheck : !resourceType.bulkCheck;
      var totalCheckedSection = hashedData.resourceTypeList[resourceType.get('sectionKey')]['totalChecked'];

      if (lowestLevel) {
        resourceType.set('bulkCheck', !resourceType.bulkCheck);
      }

      hashedData.resourceTypeGrants.forEach(individualGrant => {
        var isChecked = individualGrant.isChecked;

        if (checkedStateBefore) {
          if (isChecked) {
            individualGrant.set('dirty', !individualGrant.get('dirty'));
            individualGrant.set('isChecked', false);
            delete parsedGroupResourceTypeGrants[individualGrant.get('id')];
            totalCheckedSection--;
          }
        } else {
          if (!isChecked) {
            individualGrant.set('dirty', !individualGrant.get('dirty'));
            totalCheckedSection++;
            individualGrant.set('isChecked', true);
          }

          parsedGroupResourceTypeGrants[individualGrant.get('id')] = individualGrant;
        }
      });
      Ember.set(hashedData.resourceTypeList[resourceType.get('sectionKey')], 'totalChecked', totalCheckedSection);
      this.set('_parsedGroupResourceTypeGrants', parsedGroupResourceTypeGrants);
    })
  });

  _exports.default = _default;
});