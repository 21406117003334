define("securitytrax-ember-ui/components/multi-step-form/forms/payables/assign-level/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency"], function (_exports, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    payrollRoleFunctionLevel: null,
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;

      if (this.formMode !== 'view') {
        multiStepForm.registerStepData('step1', 'buttons', [{
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.assignLevelTask.perform(buttonPromise, multiStepForm);
          },
          classNamesString: 'st-btn st-btn-primary save-level'
        }]);
      }
    },

    assignLevelTask: (0, _emberConcurrency.task)(function* (buttonPromise, multiStepForm) {
      var formattedObjects = [];
      formattedObjects.pushObject(Ember.Object.create({
        model: {
          payrollRoleFunction: this.payrollRoleFunction,
          payrollRoleFunctionLevel: this.payrollRoleFunctionLevel
        },
        filePath: 'payables/payee-assignment'
      }));
      yield multiStepForm.validateList.perform(formattedObjects);
      var payablePayeeLevelJson = {
        payrollRoleFunctionLevel: this.payrollRoleFunctionLevel
      };
      payablePayeeLevelJson[Ember.String.camelize(this.entity.constructor.modelName)] = this.entity;
      var payablePayeeLevel = yield this.store.createRecord('payablePayeeLevel', payablePayeeLevelJson);
      var savedUserToPayrollRoleFunctionLevel = yield payablePayeeLevel.save();
      buttonPromise.then(() => {
        this.set('assignedLevel', savedUserToPayrollRoleFunctionLevel.get('payrollRoleFunctionLevel'));
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        multiStepForm.formStartedSetVal(false);
      });
    })
  });

  _exports.default = _default;
});