define("securitytrax-ember-ui/models/mmr", ["exports", "securitytrax-ember-ui/models/base_models/mmr"], function (_exports, _mmr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mmr.default.extend({
    amountDisplay: Ember.computed('amount', function () {
      return ('$' + Number(this.amount || 0.00).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')).replace('$-', '-$');
    }),
    amountNumeric: Ember.computed('amount', function () {
      return Number(this.amount);
    })
  });

  _exports.default = _default;
});