define("securitytrax-ember-ui/demo/data-list/controller", ["exports", "jquery", "securitytrax-ember-ui/mixins/jquery-sort-mixin", "securitytrax-ember-ui/mixins/checkbox-list-mixin", "securitytrax-ember-ui/mixins/property-saver-mixin", "ember-concurrency"], function (_exports, _jquery, _jquerySortMixin, _checkboxListMixin, _propertySaverMixin, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Returns grouped list property sections, common to both quotes/invoices and equipment.
   *
   * @returns {Array<{position: String, sectionProperties: Array<{formatter: String, propertyName: String}>}>}
   *
   * @private
   * @memberof customers.order.Controller
   */
  function getGroupedPropertySections() {
    var fnGetFormatter = (formatter, propertyName) => ({
      formatter,
      propertyName
    });

    return [{
      position: 'left',
      sectionProperties: [fnGetFormatter('svg-icon', 'icon'), fnGetFormatter('label', 'name')]
    }, {
      position: 'right',
      sectionProperties: [fnGetFormatter('count', 'items')]
    }];
  }

  var Controller = Ember.Controller.extend(_jquerySortMixin.default, _checkboxListMixin.default, _propertySaverMixin.default, {
    init() {
      this._super(...arguments);

      this.set('typeToIcon', {
        police: 'icn-police',
        fire: 'icn-fire',
        medical: 'icn-medical',
        guard: 'icn-system',
        electrical: 'icn-lightning'
      });
    },

    customer: Ember.computed.alias('model.customer'),
    customerContactSortingMode: false,
    customerContactSortingClass: Ember.computed('customerContactSortingMode', function () {
      if (this.customerContactSortingMode === true) {
        return 'actionable-row pointer';
      } else {
        return '';
      }
    }),
    customerContact: null,
    customerContactsList: Ember.computed('customerContactSortingMode', 'customer.customerContacts', 'showDeleteId', function () {
      return this.get('customer.customerContacts').sortBy('sort');
    }),
    customerEquipment: Ember.computed.alias('model.customer.customerEquipment'),
    isEquipmentCheckAll: false,
    bulkEquipmentEditList: Ember.A([]),
    bulkEquipmentEditListEmpty: Ember.computed('bulkEquipmentEditList.length', function () {
      return this.get('bulkEquipmentEditList.length') == 0;
    }),
    creditWindow: Ember.inject.service(),
    creditDeleteId: null,
    contactsDeleteId: null,
    equipmentDeleteId: null,
    equipmentColumnsStatus: 'install_status',
    customerEquipmentModel: null,
    stNotificationManager: Ember.inject.service(),
    intl: Ember.inject.service(),

    unapplyAll() {
      this.get('customer.customerCreditChecks').forEach(creditCheck => {
        creditCheck.set('isApplied', false);
      });
    },

    highestAffectedIndex: 0,
    lowestAffectedIndex: Ember.computed(function () {
      return this.get('customer.customerContacts.length');
    }),
    typeToIcon: undefined,
    groupedEquipment: Ember.computed('model.customer.customerEquipment.[]', function () {
      return this.groupedEquipmentTask.perform();
    }),
    groupedEquipmentTask: (0, _emberConcurrency.task)(function* () {
      //const showRemoved = this.get('showRemoved');
      var childTasks = [];
      var customer = yield this.get('model.customer');
      var customerEquipment = yield customer.get('customerEquipment');
      Ember.RSVP.all(customerEquipment.map(ce => {
        // if(ce.get('equipmentStatus.id') == 3) { //Removed
        //     if(showRemoved) {
        //         childTasks.push(this.get('mapEquipmentTask').perform(ce));
        //     }
        // } else {
        childTasks.push(this.mapEquipmentTask.perform(ce)); //}
      }));
      var allCustomerEquipment = yield Ember.RSVP.all(childTasks);
      return allCustomerEquipment.uniqBy('type.id').map(({
        type
      }) => {
        return Ember.Object.create({
          name: type.get('name'),
          icon: type.get('icon'),
          sections: getGroupedPropertySections(),
          items: allCustomerEquipment.filterBy('type.id', type.id).mapBy('equipment')
        });
      });
    }),
    mapEquipmentTask: (0, _emberConcurrency.task)(function* (ce) {
      var equipment = yield ce.get('equipment');
      var equipmentItem = yield equipment.get('equipmentItem');
      var subType = yield equipmentItem.get('equipmentSubType');
      var equipmentType = subType ? yield subType.get('equipmentType') : null;
      var equipmentTypeOther = Ember.Object.create({
        id: 0,
        name: this.intl.t('pages.customers.equipment.columns.other'),
        icon: 'icn-folder-open',
        equipment: equipmentItem
      });
      return {
        equipment: ce,
        type: equipmentType ? equipmentType : equipmentTypeOther
      };
    }),
    singleStepForm: Ember.computed(function () {
      var intl = this.intl;
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: intl.t('pages.customers.emergency_contacts.title'),
          stepComponentPath: 'multi-step-form/forms/customer-contacts'
        })
      });
    }),
    formOriginalModels: Ember.computed('customerContact', function () {
      return Ember.Object.create({
        customerContact: {
          model: this.customerContact
        }
      });
    }),
    formStarted: false,
    formMode: 'edit',

    /* Equipment bulk editform data */
    customerEquipmentBulkEditFormStarted: false,
    customerEquipmentBulkEditFirstStep: 'bulkEdit',
    customerEquipmentBulkEditFormSteps: Ember.computed(function () {
      var intl = this.intl;
      return Ember.Object.create({
        bulkEdit: Ember.Object.create({
          stepNameKey: 'bulkEdit',
          title: intl.t('pages.customers.equipment.title'),
          stepComponentPath: 'multi-step-form/forms/customer-equipment/bulk-edit'
        })
      });
    }),
    customerEquipmentBulkEditFormOriginalModels: Ember.computed('customerEquipmentModel', function () {
      return Ember.Object.create({
        customerEquipment: {
          model: this.customerEquipmentModel,
          copyRelationMap: {
            user: {
              readOnly: true
            }
          }
        }
      });
    }),
    searchFormStarted: false,
    searchFormSteps: Ember.computed(function () {
      var intl = this.intl;
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: intl.t('pages.customers.agency_contacts.search'),
          stepComponentPath: 'multi-step-form/forms/system/adt/agency-search'
        })
      });
    }),
    actions: {
      deleteCreditReport(creditCheck) {
        creditCheck.destroyRecordWithRollbackTask.perform().then(() => {
          this.stNotificationManager.addSuccess('Credit Report Removed', 'modal');
          this.get('customer.customerCreditChecks').reload();
          this.set('deleteId', null);
        });
      },

      deleteContact() {},

      deleteEquipment() {},

      expandToggle(row) {
        if (row.get('expanded')) {
          row.set('expanded', false);
        } else {
          row.set('expanded', true);
        }
      },

      viewCreditReport(id) {
        this.creditWindow.openWindow(id);
      },

      /* customer-list actions */
      changeRoute(customer) {
        this.transitionToRoute('customers.information', customer);
      },

      applyCredit(creditCheck) {
        var customer = this.customer;
        var unapply = creditCheck.get('isApplied'); // Update customer.

        customer.setProperties({
          creditTracking: unapply ? null : creditCheck.transactionId,
          customerCreditCheck: unapply ? null : creditCheck
        });
        customer.set('responseMeta', {
          creditGrade: unapply ? null : creditCheck.creditGrade
        });
        var savingCustomer = customer.save();
        savingCustomer.then(() => {
          this.unapplyAll();

          if (!unapply) {
            creditCheck.toggleProperty('isApplied');
          }
        }, {}, 'Saving customer after apply/unapply credit');
        return savingCustomer;
      },

      initSortMode() {
        var customerContacts = this.get('customer.customerContacts').sortBy('sort');
        customerContacts.forEach(item => {
          item.set('originalIndex', item.get('sort'));
        });
        this.toggleProperty('customerContactSortingMode');
      },

      cancelSort() {
        this.get('customer.customerContacts').reload();
        this.set('lowestAffectedIndex', 0);
        this.set('highestAffectedIndex', 0);
        this.toggleProperty('customerContactSortingMode');
      },

      sortingComplete() {
        var customer = this.customer;
        var customerContacts = customer.get('customerContacts').sortBy('sort');
        var low = this.lowestAffectedIndex;
        var high = this.highestAffectedIndex;

        if (low !== high) {
          for (var i = low; i <= high; i++) {
            customerContacts.objectAt(i).save();
          }
        } else {
          customerContacts.forEach(item => {
            item.set('sort', item.get('originalIndex'));
            item.save();
          });
        }
      },

      emergencyContactsDragSortUpdate(oldIndex, newIndex) {
        var customer = this.customer;
        var customerContacts = customer.get('customerContacts').sortBy('sort');
        var removedItem = customerContacts.objectAt(oldIndex);
        customerContacts = customerContacts.removeAt(oldIndex);
        customerContacts.insertAt(newIndex, removedItem);
        customerContacts.forEach((item, index) => {
          item.set('sort', index + 1);
        });
        this.set('lowestAffectedIndex', Math.min(this.lowestAffectedIndex, oldIndex, newIndex));
        this.set('highestAffectedIndex', Math.max(this.highestAffectedIndex, oldIndex, newIndex)); //show user the affected changes

        for (var i = this.lowestAffectedIndex; i <= this.highestAffectedIndex; i++) {
          (0, _jquery.default)('.card-data-icon:eq(' + i + ')').addClass('moving').html(Number(i) + 1);
        } //refresh so we can sort again before clicking done


        customer.get('customerContacts').sortBy('sort');
      },

      toggleSortMode() {
        this.toggleProperty('customerContactSortingMode');
      },

      startCustomerEquipmentBulkEdit() {
        this.set('customerEquipmentModel', this.bulkEquipmentEditList.objectAt(0));
        this.set('customerEquipmentBulkEditFormStarted', true);
      },

      equipmentChecked(editedCustomerEquipment) {
        var bulkList = this.bulkEquipmentEditList;
        var equipmentIndex = bulkList.indexOf(editedCustomerEquipment.get('id'));
        var isChecked = editedCustomerEquipment.get('isChecked') ? false : true;

        if (isChecked) {
          if (equipmentIndex === -1) {
            bulkList.pushObject(editedCustomerEquipment);
            editedCustomerEquipment.set('isChecked', true);
          }
        } else {
          bulkList.removeObject(editedCustomerEquipment);
          editedCustomerEquipment.set('isChecked', false);
        }

        this.set('bulkEquipmentEditList', bulkList);
      },

      equipmentCheckAll() {
        var customerEquipment = this.customerEquipment;
        var isChecked = this.toggleProperty('isEquipmentCheckAll');

        if (isChecked) {
          var list = this.checkAll(customerEquipment);
          this.set('bulkEquipmentEditList', list);
        } else {
          this.uncheckAll(customerEquipment);
          this.set('bulkEquipmentEditList', Ember.A([]));
        }
      }

    }
  });
  var _default = Controller;
  _exports.default = _default;
});