define("securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/adc/equipment/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/adc/adc-base", "ember-concurrency"], function (_exports, _adcBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adcBase.default.extend({
    intl: Ember.inject.service(),
    ispService: Ember.inject.service(),
    groupedEquipment: Ember.computed(function () {
      return this.groupedEquipmentTask.perform();
    }),
    groupedEquipmentTask: (0, _emberConcurrency.task)(function* () {
      var list = yield this.ispService.get('invokeIspActionTask').perform({
        model: this.customer,
        action: 'alarm_dot_com__getFullEquipmentList',
        attributes: {}
      });

      if (list && list.equipment_list) {
        list.equipment_list.forEach(device => {
          if (device.z_wave_manufacturer) {
            device['category'] = 'ZWave';
            device['icon'] = 'icn-w-fog';
          } else if (device.device_type === 'Camera') {
            device['category'] = 'Camera';
            device['icon'] = 'icn-feature-video';
          } else {
            device['category'] = 'Sensor';
            device['icon'] = 'icn-sensor-closed';
          }
        });
        return list.equipment_list.uniqBy('category').map(item => {
          return Ember.Object.create({
            name: item.category,
            icon: item.icon,
            items: list.equipment_list.filterBy('category', item.category)
          });
        });
      }

      return [];
    }),
    signalStrengthHistory: Ember.computed(function () {
      return this.signalStrengthHistoryTask.perform();
    }),
    signalStrengthHistoryTask: (0, _emberConcurrency.task)(function* () {
      var signalHistory = yield this.ispService.get('invokeIspActionTask').perform({
        model: this.customer,
        action: 'alarm_dot_com__getSignalStrengthHistory',
        attributes: {}
      });
      return signalHistory ? signalHistory.signal_strength_history : [];
    })
  });

  _exports.default = _default;
});