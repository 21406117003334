define("securitytrax-ember-ui/components/multi-step-form/forms/administration/equipment/edit-equipment-kit/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/switchable-form-step", "securitytrax-ember-ui/mixins/set-user", "securitytrax-ember-ui/mixins/list/row-list"], function (_exports, _emberConcurrency, _switchableFormStep, _setUser, _rowList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_rowList.default, _switchableFormStep.default, _setUser.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    confirmDeleteModalOpen: false,
    titleKey: 'pages.administration.equipment.equipment.title',
    equipmentCollectionItemDeleteId: false,
    equipment: Ember.computed(function () {
      return this.store.query('equipment', {
        limit: 0,
        sort: 'name'
      });
    }),
    dataLoaded: Ember.computed(function () {
      return this.initFormData.perform();
    }),
    initFormData: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = this.multiStepForm;
      yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);

      if (this.isNewRecord) {
        this.multiStepForm.formCopiedModelsSetVal('equipmentKit.model.disableAutoReplenish', true);
        this.multiStepForm.formCopiedModelsSetVal('equipmentKit.model.disableBaseAdjustment', true);
        this.multiStepForm.formCopiedModelsSetVal('equipmentKit.model.active', true);
      }

      this.set('listArray', this.multiStepForm.formCopiedModelsGetVal('equipmentKit.model.equipmentKitItems'));
      this.addRow();
    }),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      var buttons = [];

      if (this.formMode !== 'view') {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveEquipmentKit.perform(multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary equipment-kit-save-button'
        });
      }

      if (!this.isNewRecord && this.grants.getIndividualGrant('equipment_kits.delete')) {
        buttons.push({
          text: this.intl.t('common.actions.delete'),
          click: () => {
            this.set('confirmDeleteModalOpen', true);
          },
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
        });
      }

      multiStepForm.registerStepData('step1', 'buttons', buttons);
    },

    saveEquipmentKit: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var formattedObjects = this.prepForValidate('equipmentKitItem');
      formattedObjects.pushObject(Ember.Object.create({
        modelName: 'equipmentKit',
        model: multiStepForm.formCopiedModels.equipmentKit.model,
        filePath: 'equipment'
      }));

      if (this.isNewRecord) {
        //validate line items first, then set copied model with ember data
        var toCreateObjects = yield multiStepForm.validateCreateList.perform(formattedObjects, {
          notificationNamespace: 'modal'
        });
        var newEquipmentKit = this.store.createRecord('equipmentKit', {
          name: toCreateObjects.equipmentKit[0].data.name,
          description: toCreateObjects.equipmentKit[0].data.description,
          active: toCreateObjects.equipmentKit[0].data.active,
          manufacturer: toCreateObjects.equipmentKit[0].data.manufacturer,
          model: toCreateObjects.equipmentKit[0].data.model,
          equipmentWireType: toCreateObjects.equipmentKit[0].data.equipmentWireType,
          sku: toCreateObjects.equipmentKit[0].data.sku,
          value: toCreateObjects.equipmentKit[0].data.value,
          cost: toCreateObjects.equipmentKit[0].data.cost,
          defaultEquipmentVendor: toCreateObjects.equipmentKit[0].data.defaultEquipmentVendor
        });
        var savedEquipmentKit = yield toCreateObjects.equipmentKit[0].saveWithRollbackAndNotification(newEquipmentKit, 'modal');

        if (toCreateObjects.equipmentKitItem) {
          toCreateObjects.equipmentKitItem.forEach(item => {
            var newEquipmentKitItem = this.store.createRecord('equipmentKitItem', {
              quantity: item.data.quantity || 0,
              equipment: item.data.equipment,
              equipmentKit: savedEquipmentKit
            });
            toCreateObjects.equipmentKit[0].saveWithRollbackAndNotification(newEquipmentKitItem, 'modal');
          });
        }
      } else {
        yield multiStepForm.validateList.perform(formattedObjects, {
          notificationNamespace: 'modal'
        });
        multiStepForm.formCopiedModelsSetVal('equipmentKit.model.equipmentKitItems', this.trueListArray);
        yield multiStepForm.validateSyncSaveAll({
          notificationNamespace: 'modal',
          deepValidate: false
        });
      }

      this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
      buttonPromise.then(() => {
        multiStepForm.formStartedSetVal(false);
      });
    }),
    deleteEquipmentCollectionItemTask: (0, _emberConcurrency.task)(function* (row) {
      yield this.deleteRow.perform(row, 'equipmentKitItem');
      this.multiStepForm.formCopiedModelsSetVal('equipmentKit.model.equipmentKitItems', this.listArray);
      this.set('equipmentCollectionItemDeleteId', false);
    }),
    deleteEquipmentKit: (0, _emberConcurrency.task)(function* () {
      var equipmentKit = this.multiStepForm.formOriginalModelsGetVal('equipmentKit.model');

      try {
        yield equipmentKit.destroyRecordWithRollbackTask.perform();
        this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
        this.multiStepForm.onDelete();
        this.multiStepForm.formStartedSetVal(false);
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'confirmDeleteModal'
        });
        throw err;
      }
    }),
    actions: {
      deleteEquipmentCollectionItem(row) {
        this.deleteEquipmentCollectionItemTask.perform(row);
      },

      updateEquipmentItemsList(index) {
        var lineItems = this.listArray;
        var lineItem = lineItems[index];
        lineItem.set('quantity', 0);
        this.updateRow(lineItem, index);
      }

    }
  });

  _exports.default = _default;
});