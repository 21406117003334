define("securitytrax-ember-ui/services/payment-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Service to get refund/void payments
   *
   * @class PaymentService
   * @extends Ember.Service
   * @memberof services
   * @mixes Ember.Evented
   */
  var _default = Ember.Service.extend({
    stAjax: Ember.inject.service(),

    /**
     * Void payment
     *
     * @param {payment}
     */
    modifyPayment(payment, action, data = {}) {
      return payment.invokeAction(action, {
        verb: 'POST',
        data: {
          data: data
        }
      });
    }

  });

  _exports.default = _default;
});