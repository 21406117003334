define("securitytrax-ember-ui/components/pages/reports/funding-reports/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d81ZQ59x",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"page-content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n\"],[6,[37,5],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,4],null,[[\"filter\",\"categoryTitle\",\"reportCategories\"],[[30,[36,3],[\"report_category = \",[35,2]],null],[30,[36,0],[\"pages.reports.nav.funding_reports\"],null],[35,1,[\"reportCategories\"]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[10,\"h4\"],[12],[2,\"\\n                \"],[1,[30,[36,0],[\"common.actions.searchOptions.noMatches\"],null]],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"model\",\"reportCategoryId\",\"concat\",\"reports/report-category\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/reports/funding-reports/template.hbs"
    }
  });

  _exports.default = _default;
});