define("securitytrax-ember-ui/services/st-query-params", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { initializeState } from 'securitytrax-ember-ui/utils/obj-helpers';
  var _default = Ember.Service.extend(Ember.Evented, {
    init() {
      this._super(...arguments); // initializeState(this, {
      //     timers: {}
      // });

    },

    loadRelationshipWithQueryParams(data) {
      var compiledOptions = _lodash.default.merge({
        useCallback: false,
        options: {
          params: {} // extraOptions: { // NOTE: this caching data is not currently utilized in the adapter so cemmenting for now
          //     cacheResponse: false,
          //     cacheForMilliseconds: 0, // 0 means don't limit
          //     cacheForNumAccessed: 0, // 0 means don't limit
          //     forceCacheReload: false,
          // }

        }
      }, data);

      if (compiledOptions.useCallback) {
        var callbackFunction = () => {
          var hasManyData = data.model.hasMany(data.relName);
          var reloadPromise;

          if (hasManyData.hasManyRelationship.relationshipIsEmpty) {
            reloadPromise = hasManyData.load({
              adapterOptions: {
                relQueryParamsData: compiledOptions.options
              }
            });
          } else {
            reloadPromise = hasManyData.reload({
              adapterOptions: {
                relQueryParamsData: compiledOptions.options
              }
            });
          } // reloadPromise.then((loadedData) => {
          //     console.log('loadedData', loadedData);
          // });


          return reloadPromise;
        };

        return callbackFunction;
      } else {
        var hasManyData = data.model.hasMany(data.relName);
        var reloadPromise;

        if (hasManyData.hasManyRelationship.relationshipIsEmpty) {
          reloadPromise = hasManyData.load({
            adapterOptions: {
              relQueryParamsData: compiledOptions.options
            }
          });
        } else {
          reloadPromise = hasManyData.reload({
            adapterOptions: {
              relQueryParamsData: compiledOptions.options
            }
          });
        } // reloadPromise.then((loadedData) => {
        //     console.log('loadedData', loadedData);
        // });


        return reloadPromise;
      }
    }

  });

  _exports.default = _default;
});