define("securitytrax-ember-ui/components/homepage/dashboard/appointments-today/component", ["exports", "@glimmer/component", "ember-concurrency", "securitytrax-ember-ui/config/environment"], function (_exports, _component, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var AppointmentsToday = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('events/home-dashboard'), _dec4 = (0, _emberConcurrency.task)(function* () {
    var url = yield this.stAjax.getUrl('/dashboard_cards/appointments_for_today');
    var focus = typeof this.args.config.card_focus === 'object' ? this.args.config.card_focus.value : this.args.config.card_focus;
    var appointments = (this.args.useDefaultData ? this.defaultData : yield this.stAjax.request(url, {
      method: 'GET',
      data: {
        focus_type: focus,
        start_day: this.args.config.date_range ? this.args.config.date_range.start_day : '***today***',
        end_day: this.args.config.date_range ? this.args.config.date_range.end_day : '***today***'
      }
    })).data;

    if (_environment.default['environment'] != 'test' && !this.args.useDefaultData) {
      var promiseArray = [];
      appointments.forEach(app => {
        if (app.type_icon === 'icn-clock') {
          promiseArray.push(this.getTimeOffDataTask.perform(app));
        }
      });
      yield (0, _emberConcurrency.all)(promiseArray);
    }

    return appointments;
  }), _dec5 = (0, _emberConcurrency.task)(function* (appointment) {
    var timeOffData = yield this.store.findRecord('calendarEvent', appointment.appointment_id);
    appointment.timeOffUser = timeOffData.responseMeta.assigned_calendar_user;
  }), (_class = (_temp = class AppointmentsToday extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "stAjax", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "homeDashboard", _descriptor3, this);

      _initializerDefineProperty(this, "appointmentsTask", _descriptor4, this);

      _initializerDefineProperty(this, "getTimeOffDataTask", _descriptor5, this);

      this.homeDashboard.on('refresh-appointments', this, '_eventCallback');
    }

    willDestroy() {
      this.homeDashboard.off('refresh-appointments', this, '_eventCallback');
    }

    _eventCallback() {
      Ember.notifyPropertyChange(this, 'appointments');
    }

    get defaultData() {
      return {
        data: [{
          customer_name: 'Janie Hill',
          appointment_id: 1,
          start_time: '8:30 AM',
          end_time: '9:00 AM',
          type_icon: 'icn-wrench',
          style_color: '#00a216'
        }, {
          customer_name: 'Nellie Washington',
          appointment_id: 2,
          start_time: '10:30 AM',
          end_time: '12:00 PM',
          type_icon: 'icn-wrench',
          style_color: '#3d49d1'
        }, {
          customer_name: 'James Thornton',
          appointment_id: 3,
          start_time: '1:30 PM',
          end_time: '2:30 PM',
          type_icon: 'icn-wrench',
          style_color: '#db2a17'
        }, {
          customer_name: ' ',
          appointment_id: 4,
          start_time: '3:00 PM',
          end_time: '9:00 PM',
          type_icon: 'icn-clock',
          style_color: '#000',
          timeOffUser: {
            fname: 'Joe',
            lname: 'Schmoe'
          }
        }]
      };
    }

    get appointments() {
      return this.appointmentsTask.perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "stAjax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "homeDashboard", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "appointmentsTask", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "getTimeOffDataTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AppointmentsToday;
});