define("securitytrax-ember-ui/components/pages/home/home-view/component", ["exports", "@glimmer/component", "securitytrax-ember-ui/utils/obj-helpers", "ember-concurrency", "moment"], function (_exports, _component, _objHelpers, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var HomeView = (_dec = Ember.inject.service('sessionData'), _dec2 = Ember.inject.service('session'), _dec3 = Ember.inject.service('intl'), _dec4 = Ember.inject.service('grants'), _dec5 = Ember.inject.service('store'), _dec6 = Ember.inject.service('events/home-dashboard'), _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = (0, _emberConcurrency.task)(function* () {
    if (this.sessionData.needsToSignTermsAndConditions) {
      var company = yield this.sessionData.getCompany();
      var response = yield company.invokeAction('get_terms_and_conditions', {
        verb: 'GET'
      });
      this.termsAndConditionsBody = response.responsePayload.html.replace(/ul>/g, 'ol>');
      this.termsAndConditionsModalOpen = true;
    }
  }), _dec12 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var company = yield this.sessionData.getCompany();
    yield company.invokeAction('accept_terms_and_conditions', {
      verb: 'POST',
      data: {
        data: {}
      }
    });
    buttonPromise.then(() => {
      Ember.set(this.sessionData, 'needsToSignTermsAndConditions', false);
      this.termsAndConditionsModalOpen = false;
    });
  }), (_class = (_temp = class HomeView extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "sessionData", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "grants", _descriptor4, this);

      _initializerDefineProperty(this, "store", _descriptor5, this);

      _initializerDefineProperty(this, "homeDashboard", _descriptor6, this);

      _initializerDefineProperty(this, "acceptedBoxChecked", _descriptor7, this);

      _initializerDefineProperty(this, "tasksSorting", _descriptor8, this);

      _initializerDefineProperty(this, "termsAndConditionsModalOpen", _descriptor9, this);

      _initializerDefineProperty(this, "termsAndConditionsBody", _descriptor10, this);

      _initializerDefineProperty(this, "userNeedsToSignTermsAndCondtions", _descriptor11, this);

      _initializerDefineProperty(this, "acceptTermsAndConditions", _descriptor12, this);

      (0, _objHelpers.initializeState)(this, {
        tasksSorting: ['priority:desc', 'dueDate']
      });
      this.homeDashboard.on('dashboard-config-saved', this, '_eventCallback');
      this.userNeedsToSignTermsAndCondtions.perform();
    }

    willDestroy() {
      this.homeDashboard.off('dashboard-config-saved', this, '_eventCallback');
    }

    _eventCallback(dashboardLocation) {
      if (dashboardLocation === 'home_dashboard') {
        Ember.notifyPropertyChange(this, 'homeCards');
      }
    }

    get today() {
      return (0, _moment.default)().format('dddd MMMM D, YYYY');
    }

    get homeCards() {
      return this.args.editDashboard.getEmberCardsTask.perform(this, this.args);
    }

    get termsAndConditionsButtons() {
      var buttons = [];
      buttons.push({
        text: this.intl.t('common.actions.accept'),
        classNamesString: 'st-btn st-btn-primary',
        disabled: !this.acceptedBoxChecked,
        click: buttonPromise => {
          return this.acceptTermsAndConditions.perform(buttonPromise);
        }
      }, {
        text: this.intl.t('common.actions.decline'),
        click: () => {
          return this.session.invalidate();
        },
        isDelete: true,
        classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
      });
      return buttons;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "homeDashboard", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "acceptedBoxChecked", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "tasksSorting", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "termsAndConditionsModalOpen", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "termsAndConditionsBody", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "userNeedsToSignTermsAndCondtions", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "acceptTermsAndConditions", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = HomeView;
});