define("securitytrax-ember-ui/models/customer-ssn", ["exports", "securitytrax-ember-ui/models/base_models/customer-ssn"], function (_exports, _customerSsn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Customer SSN record
   *
   * @class CustomerSSNS
   * @extends model.BaseCustomerSSNS
   * @memberof model
   */
  var _default = _customerSsn.default.extend({});

  _exports.default = _default;
});