define("securitytrax-ember-ui/demo/crop-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AEH+MfFA",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,3]],[2,\"\\n\"],[10,\"div\"],[14,0,\"page-content demo-page\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"scroll-page\"],[12],[2,\"\\n\"],[6,[37,4],null,null,[[\"default\"],[{\"statements\":[[6,[37,2],null,null,[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"div\"],[14,0,\"st-card__cell\"],[12],[2,\"\\n                    \"],[1,[30,[36,1],null,[[\"src\",\"saveCroppedImage\"],[\"/assets/images/alarm_logo.png\",[30,[36,0],[[32,0],\"uploadFile\"],null]]]]],[2,\"\\n                \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"st-image-cropper\",\"html/st-card/body\",\"nav/left-navs/demo-left-nav\",\"html/st-card\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/demo/crop-image/template.hbs"
    }
  });

  _exports.default = _default;
});