define("securitytrax-ember-ui/administration/inventory/rma/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0EBDP5zv",
    "block": "{\"symbols\":[],\"statements\":[[8,\"pages/administration/inventory/inventory-rma\",[],[[\"@user\",\"@model\"],[[34,0],[34,1]]],null]],\"hasEval\":false,\"upvars\":[\"user\",\"model\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/administration/inventory/rma/template.hbs"
    }
  });

  _exports.default = _default;
});