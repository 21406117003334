define("securitytrax-ember-ui/not-found/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PGtiz08f",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"page-index cell auto\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col col-8 col-offset-4\"],[12],[2,\"\\n            \"],[10,\"h1\"],[12],[1,[34,0]],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"notFoundDescription\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/not-found/template.hbs"
    }
  });

  _exports.default = _default;
});