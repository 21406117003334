define("securitytrax-ember-ui/classes/edit-customer-dashboard", ["exports", "securitytrax-ember-ui/classes/edit-dashboard"], function (_exports, _editDashboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EditCustomerDashboard = (_dec = Ember._tracked, (_class = (_temp = class EditCustomerDashboard extends _editDashboard.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "dashboardLocation", 'customer_dashboard');

      _defineProperty(this, "titleKeyPrefix", 'pages.customers.dashboard.');

      _defineProperty(this, "titleKeySuffix", '.title');

      _defineProperty(this, "componentPrefix", 'components/customer/dashboard/');

      _initializerDefineProperty(this, "customer", _descriptor, this);
    }

    get addedCards() {
      return this.addedCardsTask.perform();
    }

    get defaultCards() {
      return [{
        'display': true,
        'card': 'customer-profit-card',
        'order': 0,
        'config': {
          'grant': 'customer_dashboard_cards_profit_and_losses.view,customer_payable_payments.view,fundings.view',
          'titleKey': 'pages.customers.dashboard.customer_profit_card.title'
        }
      }, {
        'display': true,
        'card': 'emergency-contact-card',
        'order': 1,
        'config': {
          'grant': 'customer_contacts.view',
          'titleKey': 'pages.customers.dashboard.emergency_contact_card.title',
          'link': 'customers.contacts'
        }
      }, {
        'display': true,
        'card': 'credit-grade-card',
        'order': 2,
        'config': {
          'grant': 'customer_dashboard_cards_credit_score.view,customer_credit_checks.view',
          'titleKey': 'pages.customers.dashboard.credit_grade_card.title',
          'link': 'customers.information'
        }
      }, {
        'display': true,
        'card': 'contract-term-card',
        'order': 3,
        'config': {
          'grant': 'customer_dashboard_cards_contract_terms.view',
          'titleKey': 'pages.customers.dashboard.contract_term_card.title',
          'link': 'customers.information'
        }
      }, {
        'display': true,
        'card': 'central-station-card',
        'order': 4,
        'config': {
          'grant': 'customer_dashboard_cards_central_station.view',
          'titleKey': 'pages.customers.dashboard.central_station_card.title',
          'link': 'customers.information'
        }
      }, {
        'display': true,
        'card': 'interactive-service-provider-card',
        'order': 5,
        'config': {
          'grant': 'customer_dashboard_cards_interactive_service_providers.view',
          'titleKey': 'pages.customers.dashboard.interactive_service_provider_card.title',
          'link': 'customers.information'
        }
      }, {
        'display': true,
        'card': 'adc-best-practices-card',
        'order': 6,
        'config': {
          'grant': 'customers.manage_interactive_services_provider_integration',
          'titleKey': 'pages.customers.dashboard.adc_best_practices_card.title',
          'hideISPNotConnected': true,
          'link': 'customers.information'
        }
      }, {
        'display': true,
        'card': 'trouble-conditions-card',
        'order': 7,
        'config': {
          'grant': 'customers.manage_interactive_services_provider_integration',
          'titleKey': 'pages.customers.dashboard.trouble_conditions_card.title',
          'hideISPNotConnected': true,
          'link': 'customers.information'
        }
      }, {
        'display': true,
        'card': 'notes-card',
        'order': 8,
        'config': {
          'grant': 'customer_notes.view',
          'titleKey': 'pages.customers.dashboard.notes_card.title',
          'link': 'customers.notes'
        }
      }, {
        'display': true,
        'card': 'appointments-card',
        'order': 9,
        'config': {
          'grant': 'calendar_events.view',
          'titleKey': 'pages.customers.dashboard.appointments_card.title',
          'link': 'customers.appointments'
        }
      }, {
        'display': true,
        'card': 'invoices-card',
        'order': 10,
        'config': {
          'grant': 'customer_invoices.view',
          'titleKey': 'pages.customers.dashboard.invoices_card.title',
          'link': 'customers.accounting.invoices-invoices'
        }
      }, {
        'display': true,
        'card': 'quotes-card',
        'order': 11,
        'config': {
          'grant': 'customer_invoices.view',
          'titleKey': 'pages.customers.dashboard.quotes_card.title',
          'link': 'customers.accounting.invoices-quotes'
        }
      }, {
        'display': true,
        'card': 'tasks-card',
        'order': 12,
        'config': {
          'grant': 'customer_dashboard_cards_tasks.view,customer_tasks.view',
          'titleKey': 'pages.customers.dashboard.tasks_card.title',
          'link': 'customers.notes'
        }
      }, {
        'display': true,
        'card': 'plans-services',
        'order': 13,
        'config': {
          'grant': 'customer_order_information.view',
          'titleKey': 'pages.customers.dashboard.plans_services.title',
          'link': 'customers.information'
        }
      }, {
        'display': true,
        'card': 'funding-entries-card',
        'order': 14,
        'config': {
          'grant': 'fundings.view',
          'titleKey': 'pages.customers.dashboard.funding_entries_card.title',
          'link': 'customers.accounting.payments-funding'
        }
      }, {
        'display': true,
        'card': 'customer-messages-card',
        'order': 15,
        'config': {
          'grant': 'customer_messages.view',
          'titleKey': 'pages.customers.dashboard.customer_messages_card.title',
          'link': 'customers.messages'
        }
      }, {
        'display': true,
        'card': 'payable-entries-card',
        'order': 16,
        'config': {
          'grant': 'customer_payables.view',
          'titleKey': 'pages.customers.dashboard.payable_entries_card.title',
          'link': 'customers.accounting.payments-payables'
        }
      }, {
        'display': true,
        'card': 'payables-summary-card',
        'order': 17,
        'config': {
          'grant': 'customer_dashboard_cards_payable_summaries.view',
          'titleKey': 'pages.customers.dashboard.payables_summary_card.title',
          'link': 'customers.accounting.payments-payables'
        }
      }, {
        'display': true,
        'card': 'risk-score-card',
        'order': 18,
        'config': {
          'grant': 'customers.manage_interactive_services_provider_integration',
          'titleKey': 'pages.customers.dashboard.risk_score_card.title',
          'hideISPNotConnected': true,
          'link': 'customers.information'
        }
      }];
    }

    get availableCards() {
      return {
        'customer-profit-card': {
          'enabled': true,
          'category': '',
          'card': 'customer-profit-card',
          'config': {
            'grant': 'customer_dashboard_cards_profit_and_losses.view,customer_payable_payments.view,fundings.view',
            'titleKey': 'pages.customers.dashboard.customer_profit_card.title',
            'date_range_options': 'none',
            'card_focus_options': 'none'
          }
        },
        'emergency-contact-card': {
          'enabled': true,
          'category': '',
          'card': 'emergency-contact-card',
          'config': {
            'grant': 'customer_contacts.view',
            'titleKey': 'pages.customers.dashboard.emergency_contact_card.title',
            'date_range_options': 'none',
            'card_focus_options': 'none',
            'link': 'customers.contacts'
          }
        },
        'credit-grade-card': {
          'enabled': true,
          'category': '',
          'card': 'credit-grade-card',
          'config': {
            'grant': 'customer_dashboard_cards_credit_score.view,customer_credit_checks.view',
            'titleKey': 'pages.customers.dashboard.credit_grade_card.title',
            'date_range_options': 'none',
            'card_focus_options': 'none',
            'link': 'customers.information'
          }
        },
        'contract-term-card': {
          'enabled': true,
          'category': '',
          'card': 'contract-term-card',
          'config': {
            'grant': 'customer_dashboard_cards_contract_terms.view',
            'titleKey': 'pages.customers.dashboard.contract_term_card.title',
            'date_range_options': 'none',
            'card_focus_options': 'none',
            'link': 'customers.information'
          }
        },
        'central-station-card': {
          'enabled': true,
          'category': '',
          'card': 'central-station-card',
          'config': {
            'grant': 'customer_dashboard_cards_central_station.view',
            'titleKey': 'pages.customers.dashboard.central_station_card.title',
            'date_range_options': 'none',
            'card_focus_options': 'none',
            'link': 'customers.information'
          }
        },
        'interactive-service-provider-card': {
          'enabled': true,
          'category': '',
          'card': 'interactive-service-provider-card',
          'config': {
            'grant': 'customer_dashboard_cards_interactive_service_providers.view',
            'titleKey': 'pages.customers.dashboard.interactive_service_provider_card.title',
            'date_range_options': 'none',
            'card_focus_options': 'none',
            'link': 'customers.information'
          }
        },
        'adc-best-practices-card': {
          'enabled': true,
          'category': '',
          'card': 'adc-best-practices-card',
          'config': {
            'grant': 'customers.manage_interactive_services_provider_integration',
            'titleKey': 'pages.customers.dashboard.adc_best_practices_card.title',
            'hideISPNotConnected': true,
            'date_range_options': 'none',
            'card_focus_options': 'none',
            'link': 'customers.information'
          }
        },
        'trouble-conditions-card': {
          'enabled': true,
          'category': '',
          'card': 'trouble-conditions-card',
          'config': {
            'grant': 'customers.manage_interactive_services_provider_integration',
            'titleKey': 'pages.customers.dashboard.trouble_conditions_card.title',
            'hideISPNotConnected': true,
            'date_range_options': 'none',
            'card_focus_options': 'none',
            'link': 'customers.information'
          }
        },
        'notes-card': {
          'enabled': true,
          'category': '',
          'card': 'notes-card',
          'config': {
            'grant': 'customer_notes.view',
            'titleKey': 'pages.customers.dashboard.notes_card.title',
            'date_range_options': 'none',
            'card_focus_options': 'none',
            'link': 'customers.notes'
          }
        },
        'appointments-card': {
          'enabled': true,
          'category': '',
          'card': 'appointments-card',
          'config': {
            'grant': 'calendar_events.view',
            'titleKey': 'pages.customers.dashboard.appointments_card.title',
            'date_range_options': 'none',
            'card_focus_options': 'none',
            'link': 'customers.appointments'
          }
        },
        'invoices-card': {
          'enabled': true,
          'category': '',
          'card': 'invoices-card',
          'config': {
            'grant': 'customer_invoices.view',
            'titleKey': 'pages.customers.dashboard.invoices_card.title',
            'date_range_options': 'none',
            'card_focus_options': 'none',
            'link': 'customers.accounting.invoices-invoices'
          }
        },
        'quotes-card': {
          'enabled': true,
          'category': '',
          'card': 'quotes-card',
          'config': {
            'grant': 'customer_invoices.view',
            'titleKey': 'pages.customers.dashboard.quotes_card.title',
            'date_range_options': 'none',
            'card_focus_options': 'none',
            'link': 'customers.accounting.invoices-quotes'
          }
        },
        'tasks-card': {
          'enabled': true,
          'category': '',
          'card': 'tasks-card',
          'config': {
            'grant': 'customer_dashboard_cards_tasks.view,customer_tasks.view',
            'titleKey': 'pages.customers.dashboard.tasks_card.title',
            'date_range_options': 'none',
            'card_focus_options': 'none',
            'link': 'customers.notes'
          }
        },
        'plans-services': {
          'enabled': true,
          'category': '',
          'card': 'plans-services',
          'config': {
            'grant': 'customer_order_information.view',
            'titleKey': 'pages.customers.dashboard.plans_services.title',
            'date_range_options': 'none',
            'card_focus_options': 'none',
            'link': 'customers.information'
          }
        },
        'funding-entries-card': {
          'enabled': true,
          'category': '',
          'card': 'funding-entries-card',
          'config': {
            'grant': 'fundings.view',
            'titleKey': 'pages.customers.dashboard.funding_entries_card.title',
            'date_range_options': 'none',
            'card_focus_options': 'none',
            'link': 'customers.accounting.payments-funding'
          }
        },
        'customer-messages-card': {
          'enabled': true,
          'category': '',
          'card': 'customer-messages-card',
          'config': {
            'grant': 'customer_messages.view',
            'titleKey': 'pages.customers.dashboard.customer_messages_card.title',
            'date_range_options': 'none',
            'card_focus_options': 'none',
            'link': 'customers.messages'
          }
        },
        'payable-entries-card': {
          'enabled': true,
          'category': '',
          'card': 'payable-entries-card',
          'config': {
            'grant': 'customer_payables.view',
            'titleKey': 'pages.customers.dashboard.payable_entries_card.title',
            'date_range_options': 'none',
            'card_focus_options': 'none',
            'link': 'customers.accounting.payments-payables'
          }
        },
        'payables-summary-card': {
          'enabled': true,
          'category': '',
          'card': 'payables-summary-card',
          'config': {
            'grant': 'customer_dashboard_cards_payable_summaries.view',
            'titleKey': 'pages.customers.dashboard.payables_summary_card.title',
            'date_range_options': 'none',
            'card_focus_options': 'none',
            'link': 'customers.accounting.payments-payables'
          }
        },
        'risk-score-card': {
          'enabled': true,
          'category': '',
          'card': 'risk-score-card',
          'config': {
            'grant': 'customers.manage_interactive_services_provider_integration',
            'titleKey': 'pages.customers.dashboard.risk_score_card.title',
            'hideISPNotConnected': true,
            'date_range_options': 'none',
            'card_focus_options': 'none',
            'link': 'customers.information'
          }
        }
      };
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "customer", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  })), _class));
  _exports.default = EditCustomerDashboard;
});