define("securitytrax-ember-ui/services/user-storage-service", ["exports", "securitytrax-ember-ui/services/st-local-storage-adapter", "ember-concurrency"], function (_exports, _stLocalStorageAdapter, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var UserStorageService = (_dec = Ember.inject.service('sessionData'), _dec2 = Ember.inject.service('profile'), _dec3 = Ember.inject.service('store'), _dec4 = (0, _emberConcurrency.task)(function* () {
    var tokenData = yield this.sessionData.getTokenData();
    this.key = "profiles:".concat(this.profile.get(), ":user_data:").concat(tokenData.sub.user_id); // Not sure what to do here if no tokenData

    var localStorageItem = localStorage.getItem(this.key) || '{}';
    this.data = Ember.Object.create(JSON.parse(localStorageItem));
  }).on('init'), (_class = (_temp = class UserStorageService extends _stLocalStorageAdapter.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "sessionData", _descriptor, this);

      _initializerDefineProperty(this, "profile", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor4, this);
    }

    get hasReceivedLocationWarning() {
      return this.data.hasReceivedLocationDisabledWarning;
    }

    setLocationWarningFlag() {
      this.set('data.hasReceivedLocationDisabledWarning', true);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "profile", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = UserStorageService;
});