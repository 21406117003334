define("securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/dealer-ally/applicants/applicant/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var DealerAllyFinancingApplicant = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = (0, _emberConcurrency.task)(function* (row) {
    yield this.args.rowList.deleteRow.perform(row, 'consumerFinancingApplicantsAdditionalIncome', true);
    Ember.set(this.args.multiStepForm, 'formCopiedModels.consumerFinancingApplication.model.consumerFinancingApplicant.consumerFinancingApplicantsAdditionalIncomes', this.args.RowList.listArray);
    this.additionalIncomeDeleteId = false;
  }), _dec7 = Ember._action, _dec8 = Ember._action, (_class = (_temp = class DealerAllyFinancingApplicant extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "usStates", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "coapplicant", _descriptor4, this);

      _initializerDefineProperty(this, "additionalIncomeDeleteId", _descriptor5, this);

      _defineProperty(this, "additionalIncomesEnumLists", undefined);

      _initializerDefineProperty(this, "deleteAdditionalIncome", _descriptor6, this);

      this.args.rowList.listArray = this.args.additionalIncomes || [];
      this.additionalIncomesEnumLists = this.store.createRecord('consumerFinancingApplicantsAdditionalIncome').enumLists();
      this.idTypeOptions = this.args.enumLists.idTypeOptions;
      this.args.applicant.copyPersonalInfoAddressFromApplicant = false;
      this.args.applicant.issueDatePresent = this.args.multiStepForm.currentStepNameKey === 'applicants' && (!this.args.applicant.get('id') || this.args.applicant.idType === 'PermanentResidencyCard') ? true : this.args.applicant.idIssueDate;
      this.args.applicant.expirationDatePresent = this.args.multiStepForm.currentStepNameKey === 'applicants' && (!this.args.applicant.get('id') || this.args.applicant.idType !== 'UnitedStatesMilitaryId') ? true : this.args.applicant.idExpirationDate;
      this.args.applicant.issueStatePresent = this.args.multiStepForm.currentStepNameKey === 'applicants' && (!this.args.applicant.get('id') || this.args.applicant.idType !== 'DriversLicense' && this.args.applicant.idType !== 'StateId') ? true : this.args.applicant.idStateOn;
      this.args.applicant.showOtherIncome = this.args.multiStepForm.currentStepNameKey === 'applicants' && !this.args.applicant.get('id') ? false : this.args.rowList.listArray.length > 0;

      if (this.args.formMode !== 'view') {
        Ember.run.later(() => {
          if (!this.args.rowList.lastObjectEmpty()) {
            this.args.rowList.addRow();
          }
        });
      }

      if (this.args.coapplicant) {
        this.classNames = ['coapplicant'];
      }
    }

    get homeOwnershipOptions() {
      return [{
        name: this.intl.t('common.customer.home_ownerships.own'),
        value: 'own'
      }, {
        name: this.intl.t('common.customer.home_ownerships.rent'),
        value: 'rent'
      }, {
        name: this.intl.t('common.customer.home_ownerships.lease'),
        value: 'lease'
      }, {
        name: this.intl.t('common.customer.home_ownerships.other'),
        value: 'other'
      }];
    }

    updateAdditionalIncomes(index) {
      var lineItems = this.args.rowList.listArray;
      var lineItem = lineItems[index];
      Ember.set(lineItem, 'isCoapplicant', this.args.coapplicant);
      this.args.rowList.updateRow(lineItem, index);
    }

    updateEmploymentType(type) {
      if (type && type.id === 'Unemployed' || type.id === 'Retired') {
        Ember.set(this.args.applicant, 'showOtherIncome', true);

        if (this.args.rowList.trueListArray.length === 0) {
          this.updateAdditionalIncomes(this.args.rowList.listArray.length - 1);
        }
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "usStates", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "coapplicant", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "additionalIncomeDeleteId", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "deleteAdditionalIncome", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateAdditionalIncomes", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "updateAdditionalIncomes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateEmploymentType", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "updateEmploymentType"), _class.prototype)), _class));
  _exports.default = DealerAllyFinancingApplicant;
});