define("securitytrax-ember-ui/models/equipment-status", ["exports", "securitytrax-ember-ui/models/base_models/equipment-status"], function (_exports, _equipmentStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _equipmentStatus.default.extend({
    styleColor: Ember.computed('calculatedColor', function () {
      var calculatedColor = this.calculatedColor;

      if (calculatedColor) {
        return Ember.String.htmlSafe("color: ".concat(calculatedColor));
      } else {
        return '';
      }
    }),
    calculatedColor: Ember.computed('id', function () {
      var id = this.id;

      switch (id) {
        case '1':
          //Not Installed
          return '#949494;';

        case '2':
          //Installed
          return '#00a216';

        case '3':
          //Removed
          return '#db2a17';

        default:
          return '';
      }
    }),
    statusIcon: Ember.computed('id', function () {
      return this.id === '3' ? 'icn-circle-x' : 'icn-circle-check';
    })
  });

  _exports.default = _default;
});