define("securitytrax-ember-ui/administration/company-settings/content-management/dry-run-reasons/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/has-page-title", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _authenticatedRouteMixin, _hasPageTitle, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _hasPageTitle.default, _routePageviewLogging.default, {
    intl: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform();
    },

    setupController(controller) {
      this._super(...arguments);

      var breadCrumbs = [{
        name: this.intl.t('pages.administration.content_management.dry_run_reasons.title'),
        last: true,
        previousLink: 'administration.company-settings',
        link: 'administration.company-settings.content-management.dry-run-reasons'
      }];
      var breadCrumbData = {
        title: this.intl.t('pages.administration.company_settings.title'),
        titleLink: 'administration.company-settings'
      };
      controller.set('breadCrumbs', breadCrumbs);
      controller.set('breadCrumbData', breadCrumbData);
    },

    getPageTitle() {
      return this.intl.t('pages.administration.content_management.dry_run_reasons.title');
    },

    getRouteName() {
      return "pages.administration.nav.company-settings";
    }

  });

  _exports.default = _default;
});