define("securitytrax-ember-ui/services/grants", ["exports", "ember-cli-jwt-decode", "ember-concurrency", "securitytrax-ember-ui/utils/interval", "securitytrax-ember-ui/config/environment", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _emberCliJwtDecode, _emberConcurrency, _interval, _environment, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Grants service so we can check permissions on routes/properties/functionality
   * @class Grants
   * @extends Ember.Service
   * @memberof services
   */
  var Grants = Ember.Service.extend(Ember.Evented, {
    stAjax: Ember.inject.service(),
    session: Ember.inject.service(),
    stMulticallService: Ember.inject.service(),
    grantsAccessedSinceLastClear: undefined,
    currentGrantsAccessedNamespace: undefined,
    reportsGrantResourceTypes: undefined,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        userGrants: Ember.Object.create(),
        loaded: false,
        revision: 1,
        grantsAccessedSinceLastClear: {},
        currentGrantsAccessedNamespace: 'global',
        // NOTE: the below list should maybe be dynamic, but didn't want to add another request to every page
        reportsGrantResourceTypes: ['custom_sql_reports', 'customer_reports', 'notes_ticket_reports', 'customers_payable_reports', 'customers_equipment_reports', 'lead_customer_transaction_reports', 'customer_payment_reports', 'customer_communication_reports', 'tech_reschedule_reports', 'customers_at_risk_reports', 'lead_reports', 'invoices_quote_reports', 'pending_equipment_reports', 'user_reports', 'license_reports', 'funding_reports', 'funding_transaction_reports']
      });
      Ember.run(() => {
        this.set('currentlyRunningGetCurrentUserGrants', this.getCurrentUserGrants.perform());
        this.session.on('authenticationSucceeded', () => {
          this.set('currentlyRunningGetCurrentUserGrants', this.getCurrentUserGrants.perform());
        });
      }); // TODO: TRAX-3387 figure out grants refresh. right now, it causes issues with modals due to formOriginalModels updating

      var interval = _environment.default[_environment.default['modulePrefix']]['grants']['refreshMinutes'] !== undefined ? _environment.default[_environment.default['modulePrefix']]['grants']['refreshMinutes'] : 10;
      interval = interval * 60 * 1000; //interval = 5000;
      //start the background task if interval is set

      if (interval > 0) {
        (0, _emberConcurrency.waitForProperty)(this, 'session.isAuthenticated', true).then(() => {
          var iTask = new _interval.default();
          iTask.setContext(this.getCurrentUserGrants);
          iTask.setFunction(this.get('getCurrentUserGrants.perform'));
          iTask.setInterval(interval);
          iTask.start();
        });
      }
    },

    // NOTE: useMulticall is only set to false in the case that the users pw was just reset and we are dealing with new grants
    getCurrentUserGrants: (0, _emberConcurrency.task)(function* (useMulticall = true) {
      var notInMirage = _environment.default['ember-cli-mirage']['enabled'] === false;

      if (notInMirage) {
        yield this.stMulticallService.waitForMulticallByKeyAndEventname('headerCombined', 'finishedRequest', 'grants');
      }

      var session = this.session;

      if (session.forcePasswordUpdate) {
        return;
      }

      this.set('loaded', false);

      if (session.get('isAuthenticated')) {
        try {
          var {
            token
          } = this.get('session.data.authenticated');
          var authorizedUser = (0, _emberCliJwtDecode.jwt_decode)(token);
          var userId = authorizedUser && authorizedUser.sub ? authorizedUser.sub.user_id : null;
          yield this.stAjax.resetHeaders();
          var grantsResult;

          if (notInMirage && useMulticall) {
            var grantsResultMulti = yield this.stMulticallService.grabMulticallDataByKeyAndSubkey('headerCombined', 'grants', 'finishedRequest');
            grantsResult = grantsResultMulti.actualResponse;
          } else {
            grantsResult = yield this.stAjax.request(this.stAjax.getUrl("/users/".concat(userId, "/grants")));
          }

          this.syncGrantData(grantsResult);
        } catch (error) {
          (false && Ember.warn('Couldnt get current user grants: ' + error, {
            id: 'services.grants'
          })); //this.set('userGrants', EmberObject.create());
        }
      } else {//this.set('userGrants', EmberObject.create());
        }

      this.set('loaded', true); //this.notifyPropertyChange('userGrants'); // enforce watchers to be aware
    }).drop().maxConcurrency(1),
    getGrantsLoadedPromise: function () {
      return (0, _emberConcurrency.waitForProperty)(this, 'loaded', true);
    },
    getIndividualGrantAsync: (0, _emberConcurrency.task)(function* (grantName) {
      yield (0, _emberConcurrency.waitForProperty)(this, 'loaded', true);
      return this.getIndividualGrant(grantName);
    }),
    setGrantsAccessedNamespace: function (namespace) {
      var namespaceSlashes = namespace.replace(/[.]/g, '/'); // Commented out cause ember hates this and soooo many tests fail
      //TODO: make this work if we want the tilde thing back

      Ember.set(this, 'currentGrantsAccessedNamespace', namespaceSlashes);

      if (this.grantsAccessedSinceLastClear[namespaceSlashes]) {
        Ember.set(this.grantsAccessedSinceLastClear, "".concat(namespaceSlashes), {
          open: false,
          counts: {},
          totalCount: 0,
          uniqueCount: 0
        });
      }
    },
    addGrantAccessed: function (grantName, isGranted = true) {
      var grantNameSlashes = grantName.replace(/[.]/g, '/'); /////////////////////////////////////////////////////////////////////////
      // setup grants accessed default object data

      if (!this.grantsAccessedSinceLastClear[this.currentGrantsAccessedNamespace]) {
        Ember.set(this.grantsAccessedSinceLastClear, "".concat(this.currentGrantsAccessedNamespace), {
          open: false,
          counts: {},
          totalCount: 0,
          uniqueCount: 0
        });
      } ////////////////////////////////////////////////////////////
      // set unique and total counts


      if (!this.grantsAccessedSinceLastClear[this.currentGrantsAccessedNamespace]['counts'][grantNameSlashes]) {
        Ember.set(this.grantsAccessedSinceLastClear, "".concat(this.currentGrantsAccessedNamespace, ".counts.").concat(grantNameSlashes), {
          granted: 0,
          denied: 0,
          currentlySpinning: false,
          changeInSession: false
        });
        Ember.set(this.grantsAccessedSinceLastClear, "".concat(this.currentGrantsAccessedNamespace, ".uniqueCount"), this.grantsAccessedSinceLastClear[this.currentGrantsAccessedNamespace]['uniqueCount'] + 1);
      }

      Ember.set(this.grantsAccessedSinceLastClear, "".concat(this.currentGrantsAccessedNamespace, ".totalCount"), this.grantsAccessedSinceLastClear[this.currentGrantsAccessedNamespace]['totalCount'] + 1); ////////////////////////////////////////////////////////////////////
      // increment grant counts

      if (isGranted) {
        Ember.set(this.grantsAccessedSinceLastClear, "".concat(this.currentGrantsAccessedNamespace, ".counts.").concat(grantNameSlashes, ".granted"), this.grantsAccessedSinceLastClear[this.currentGrantsAccessedNamespace]['counts'][grantNameSlashes]['granted'] + 1);
      } else {
        Ember.set(this.grantsAccessedSinceLastClear, "".concat(this.currentGrantsAccessedNamespace, ".counts.").concat(grantNameSlashes, ".denied"), this.grantsAccessedSinceLastClear[this.currentGrantsAccessedNamespace]['counts'][grantNameSlashes]['denied'] + 1);
      }

      this.notifyPropertyChange('grantsAccessedSinceLastClear');
    },

    getIndividualGrant(grantName) {
      if (grantName) {
        var key = grantName.split('.')[0];

        if (key in this.userGrants) {
          var grantedOrNot = this.get("userGrants.".concat(grantName));
          this.addGrantAccessed(grantName, grantedOrNot);
          return grantedOrNot;
        }
      }

      return false;
    },

    setIndividualGrant(grantName, value) {
      var key = grantName.split('.')[0];
      var difference = false;

      if (key in this.userGrants) {
        if (this.get("userGrants.".concat(grantName)) != value) {
          difference = true;
          this.set("userGrants.".concat(grantName), value);
        }

        if (difference) {
          // TODO: remove this once the app is watching individual grant properties
          this.notifyPropertyChange('userGrants'); // enforce watchers to be aware

          this.incrementProperty('revision');
        }

        return value;
      }

      return false;
    },

    syncGrantData(grantsResult) {
      var userGrants = this.userGrants;
      var difference = false;

      var recurse = function (data, obj) {
        for (var key in data) {
          if (typeof data[key] === 'object') {
            if (!obj.get(key)) {
              difference = true;
              obj.set(key, Ember.Object.create());
            }

            recurse(data[key], obj.get(key));
          } else {
            try {
              if (obj.get(key) != data[key]) {
                difference = true;
                obj.set(key, data[key]);
              }
            } catch (e) {
              throw e;
            }
          }
        }
      };

      recurse(grantsResult, userGrants);

      if (difference) {
        // TODO: remove this once the app is watching individual grant properties
        this.notifyPropertyChange('userGrants'); // enforce watchers to be aware

        this.incrementProperty('revision');
      }
    },

    hasAtLeastOneReportsRunGrant() {
      var hasAtLeastOneReportGrant = false;

      for (var index = 0; index < this.reportsGrantResourceTypes.length; index++) {
        if (this.getIndividualGrant(this.reportsGrantResourceTypes[index] + '.run')) {
          hasAtLeastOneReportGrant = true;
          break;
        }
      }

      return hasAtLeastOneReportGrant;
    },

    hasAtLeastOneReportsRunGrantAsync: (0, _emberConcurrency.task)(function* () {
      var hasAtLeastOneReportGrant = false;

      for (var index = 0; index < this.reportsGrantResourceTypes.length; index++) {
        // below yield is ok because they all return at the same time (ie waiting for grants to come back)
        if (yield this.getIndividualGrantAsync.perform(this.reportsGrantResourceTypes[index] + '.run')) {
          hasAtLeastOneReportGrant = true;
          break;
        }
      }

      return hasAtLeastOneReportGrant;
    }),

    hasAllGrants(grantArr) {
      return grantArr.every(grant => this.getIndividualGrant(grant));
    }

  });
  var _default = Grants;
  _exports.default = _default;
});