define("securitytrax-ember-ui/components/multi-step-form/forms/demo/demo-power-select/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency"], function (_exports, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    demoFormMode: 'view',

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('step1', 'buttons', [{
        text: 'Cancel',
        click: () => {
          this.multiStepForm.setupCopyModels.perform();
          this.multiStepForm.resetForm(false);
          this.set('demoFormMode', 'view');
        },
        classNamesString: 'st-btn st-btn-secondary'
      }, {
        text: 'Save',
        click: buttonPromise => {
          return this.powerSelectSaveTask.perform(buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary'
      }]);
    },

    powerSelectSaveTask: (0, _emberConcurrency.task)(function* (buttonPromise) {
      var multiStepForm = this.multiStepForm;
      yield multiStepForm.validateSyncSaveAll({
        extraValidationData: {
          noValidate: ['location']
        }
      });
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        this.set('demoFormMode', 'view');
      });
    }),
    disabledSelect: Ember.computed('demoFormMode', function () {
      return this.demoFormMode == 'view';
    })
  });

  _exports.default = _default;
});