define("securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-payments/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    customerPayment: null,
    customer: null,
    initData: (0, _emberConcurrency.task)(function* () {
      if (this.customerPaymentId !== 'create') {
        this.set('customerPayment', yield this.store.findRecord('customerPayment', this.customerPaymentId));
        this.set('customer', this.customer ? this.customer : yield this.get('customerPayment.customer'));
      }

      this.set('customerPaymentFormStarted', true);
    }).on('init'),
    billTypes: Ember.computed(function () {
      return this.getBillTypes.perform();
    }),
    getBillTypes: (0, _emberConcurrency.task)(function* () {
      return yield this.store.findAll('billingType');
    }),
    customerPaymentTypes: Ember.computed(function () {
      return this.getCustomerPaymentTypes.perform();
    }),
    getCustomerPaymentTypes: (0, _emberConcurrency.task)(function* () {
      return yield this.store.findAll('customerPaymentType');
    }),
    customerPaymentFormMode: Ember.computed('customerPayment', function () {
      if (this.grants.getIndividualGrant('customer_payments.create') && !this.customerPayment) {
        return 'create';
      } else if (this.grants.getIndividualGrant('customer_payments.modify') && this.customerPayment) {
        return 'edit';
      } else {
        return 'view';
      }
    }),
    customerPaymentFirstStep: Ember.computed('customerPayment', function () {
      if (this.customerPaymentFormMode === 'create') {
        return 'addPayment';
      } else if (this.customerPayment.processingStatusDisplay) {
        return 'viewProcessingInformation';
      } else {
        return 'editManualPayment';
      }
    }),
    customerPaymentFormSteps: Ember.computed('customerPaymentFormMode', 'customerPayment', 'customer', function () {
      var intl = this.intl;
      var paymentId = this.customerPayment ? this.customerPayment.get('id') : null;
      var customerName = this.customer.flname;
      return Ember.Object.create({
        addPayment: Ember.Object.create({
          stepNameKey: 'addPayment',
          title: intl.t("pages.customers.accounting.customer_payments.".concat(this.customerPaymentFormMode), {
            name: customerName
          }),
          stepComponentPath: 'multi-step-form/forms/invoices/invoice/add-payment'
        }),
        confirmRefundVoid: Ember.Object.create({
          stepNameKey: 'confirmRefundVoid',
          title: intl.t('pages.customers.accounting.customer_payments.confirmRefundVoid.modalTitle', {
            id: paymentId,
            name: customerName
          }),
          progressBarTitle: 'refund or void - hidden',
          stepComponentPath: 'multi-step-form/forms/invoices/invoice/payments/confirm-refund-void',
          currentVoidingOrRefundingCustomerPayment: this.customerPayment,
          granted: true
        }),
        viewProcessingInformation: Ember.Object.create({
          stepNameKey: 'viewProcessingInformation',
          title: intl.t('pages.customers.accounting.customer_payments.processingInformation.modalTitle', {
            id: paymentId,
            name: customerName
          }),
          progressBarTitle: 'view processing info - this is hidden',
          stepComponentPath: 'multi-step-form/forms/invoices/invoice/payments/view-processing-info',
          granted: true
        }),
        editManualPayment: Ember.Object.create({
          stepNameKey: 'editManualPayment',
          title: intl.t('pages.customers.accounting.customer_payments.processingInformation.modalTitle', {
            id: paymentId,
            name: customerName
          }),
          progressBarTitle: 'Edit Manual Payment - hidden',
          stepComponentPath: 'multi-step-form/forms/invoices/invoice/payments/edit-manual-payment'
        })
      });
    }),
    customerPaymentFormOriginalModels: Ember.computed('customerPayment', 'grants.userGrants', function () {
      var grants = this.grants;
      var isCreate = this.customerPayment == null;
      return Ember.Object.create({
        customerPayment: {
          model: this.customerPayment,
          copyRelationMap: {
            customerPaymentType: {
              doValidate: false,
              doSave: false
            },
            customerBilling: {
              doCopy: grants.getIndividualGrant('customer_billings.view'),
              doValidate: grants.getIndividualGrant('customer_billings.view'),
              doSave: isCreate ? grants.getIndividualGrant('customer_billings.create') : grants.getIndividualGrant('customer_billings.modify')
            }
          }
        },
        customerBilling: {
          model: null,
          copyRelationMap: {
            billType: {
              readOnly: true
            }
          }
        }
      });
    }),
    actions: {
      transitionBackToParentRoute() {
        if (this.parentRoute.includes('customer')) {
          this.router.transitionTo(this.parentRoute, this.get('customer.id'));
        } else {
          this.router.transitionTo(this.parentRoute);
        }
      }

    }
  });

  _exports.default = _default;
});