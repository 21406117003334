define("securitytrax-ember-ui/components/multi-step-form/forms/demo/demo-progress-bar/step-1/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MAOmzsDt",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],null,[[\"config\"],[[35,1,[\"progressBarConfig\"]]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h2\"],[12],[2,\"Step #1\"],[13],[2,\"\\n    \"],[10,\"h4\"],[12],[2,\"Name\"],[13],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"First Name:\"],[13],[2,\" \"],[1,[30,[36,0],null,[[\"value\"],[\"\"]]]],[10,\"br\"],[12],[13],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"Last Name:\"],[13],[2,\" \"],[1,[30,[36,0],null,[[\"value\"],[\"\"]]]],[10,\"br\"],[12],[13],[2,\"\\n\\n    \"],[10,\"h4\"],[12],[2,\"Contact\"],[13],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"Phone:\"],[13],[2,\" \"],[1,[30,[36,0],null,[[\"value\"],[\"\"]]]],[10,\"br\"],[12],[13],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"Address:\"],[13],[2,\" \"],[1,[30,[36,0],null,[[\"value\"],[\"\"]]]],[10,\"br\"],[12],[13],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"Email:\"],[13],[2,\" \"],[1,[30,[36,0],null,[[\"value\"],[\"\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"input\",\"multiStepForm\",\"multi-step-form/progress-bar\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/demo/demo-progress-bar/step-1/template.hbs"
    }
  });

  _exports.default = _default;
});