define("securitytrax-ember-ui/components/lists/overlays/delete-confirm/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    screen: Ember.inject.service(),
    deleteMessageKey: 'common.confirm.really_inline_delete',
    deleteButtonKey: 'common.actions.delete',

    init() {
      if (!this.tagName) {
        this.set('tagName', '');
      }

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});