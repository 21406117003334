define("securitytrax-ember-ui/components/multi-step-form/forms/reports/bulk-edit/item/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var ReportBulkEditItem = (_dec = Ember.inject.service('intl'), _dec2 = Ember.inject.service('screen'), _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, (_class = (_temp = class ReportBulkEditItem extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "screen", _descriptor2, this);

      _initializerDefineProperty(this, "currentField", _descriptor3, this);

      _initializerDefineProperty(this, "currentSecondaryField", _descriptor4, this);

      _initializerDefineProperty(this, "currentMultiSelectOptions", _descriptor5, this);
    }

    get options() {
      var fieldsMap = this.args.fieldsToSave.map(field => field.currFieldToSave ? field.currFieldToSave.id : undefined);
      this.args.reportBulkEditDataArray.forEach(field => {
        if (fieldsMap.includes(field.id)) {
          Ember.set(field, 'disabled', true);
        } else {
          Ember.set(field, 'disabled', false);
        }
      });
      return this.args.reportBulkEditDataArray;
    }

    onChooseBulkEditField(index, field) {
      this.currentField = field;
      this.args.setField(index, field);
    }

    onChooseBulkEditMultiPartField(index, field) {
      this.currentSecondaryField = field;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "screen", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentField", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentSecondaryField", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "currentMultiSelectOptions", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onChooseBulkEditField", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "onChooseBulkEditField"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChooseBulkEditMultiPartField", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "onChooseBulkEditMultiPartField"), _class.prototype)), _class));
  _exports.default = ReportBulkEditItem;
});