define("securitytrax-ember-ui/utils/interval", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    context: null,
    function: null,
    interval: 1000,
    _currentlyExecutedFunction: null,
    _started: false,
    start: function () {
      if (!this.isStarted()) {
        this.set('_started', true);
        this.set('_currentlyExecutedFunction', this._schedule([].slice.call(arguments)));
      }
    },
    stop: function () {
      if (Ember.run.cancel(this._currentlyExecutedFunction)) {
        this.set('_currentlyExecutedFunction', null);
        this.set('_started', false);
      }
    },
    _schedule: function (args) {
      return Ember.run.later(this, function () {
        this.set('_currentlyExecutedFunction', this._schedule(args));
        this['function'].apply(this.context, args);
      }, this.interval);
    },
    setContext: function (context) {
      this.set('context', context);
    },
    setFunction: function (f) {
      this.set('function', f);
    },
    setInterval: function (interval) {
      this.set('interval', interval);
    },
    isStarted: function () {
      return this._started;
    }
  });

  _exports.default = _default;
});