define("securitytrax-ember-ui/services/calendar-date-picker-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
   * Service to handle the state of calandar's data. 
   *
   * @class CalandarData
   * @extends Ember.Service
    * @memberof services
    *
    */
  var CalendarDatePicker = (_dec = Ember.inject.service('events/calendar-events'), _dec2 = Ember.inject.service('store'), _dec3 = Ember._tracked, _dec4 = Ember._tracked, (_class = (_temp = class CalendarDatePicker extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "CalendarEventsService", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "dates", _descriptor3, this);

      _initializerDefineProperty(this, "selectedDates", _descriptor4, this);
    }

    init() {
      super.init(...arguments);
    }

    initDates() {
      this.selectedDates = Ember.A();
    }

    sortDates() {
      this.selectedDates.sort(function (a, b) {
        return a.date - b.date;
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "CalendarEventsService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dates", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectedDates", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  })), _class));
  _exports.default = CalendarDatePicker;
});