define("securitytrax-ember-ui/helpers/arr-at", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arrAt = arrAt;
  _exports.default = void 0;

  function arrAt(args) {
    var array = args[0];
    var index = args[1];

    if (Array.isArray(array) && array.length > index && index >= 0) {
      return array[index];
    }

    return null;
  }

  var _default = Ember.Helper.helper(arrAt);

  _exports.default = _default;
});