define("securitytrax-ember-ui/components/pages/administration/company-settings/system/state-sales-tax-settings/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var StateSalesTaxSettings = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = (0, _emberConcurrency.task)(function* () {
    var salesTaxExemptSettings = yield this.sessionData.getGlobalTaxExemptions();
    this.selectedActivationStates = this.usStates.all.filter(obj => salesTaxExemptSettings.activation.includes(obj.value));
    this.selectedCreditMemoStates = []; //Nothing comes back for this, need to see where it's pulled from

    this.selectedLaborStates = this.usStates.all.filter(obj => salesTaxExemptSettings.labor.includes(obj.value));
    this.selectedProductStates = this.usStates.all.filter(obj => salesTaxExemptSettings.product.includes(obj.value));
    this.selectedMonitoringStates = this.usStates.all.filter(obj => salesTaxExemptSettings.monitoring.includes(obj.value));
    this.selectedServiceStates = this.usStates.all.filter(obj => salesTaxExemptSettings.service.includes(obj.value));
  }), _dec11 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    try {
      var salesTaxExemptSettings = yield this.sessionData.getGlobalTaxExemptions();
      salesTaxExemptSettings.set('activation', Array.from(this.selectedActivationStates, obj => obj.value)); //salesTaxExemptSettings.set('activation', this.selectedCreditMemoStates); BE has nothing for it.

      salesTaxExemptSettings.set('labor', Array.from(this.selectedLaborStates, obj => obj.value));
      salesTaxExemptSettings.set('product', Array.from(this.selectedProductStates, obj => obj.value));
      salesTaxExemptSettings.set('monitoring', Array.from(this.selectedMonitoringStates, obj => obj.value));
      salesTaxExemptSettings.set('service', Array.from(this.selectedServiceStates, obj => obj.value));
      yield salesTaxExemptSettings.save();
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err);
      throw err;
    }
  }), (_class = (_temp = class StateSalesTaxSettings extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "usStates", _descriptor, this);

      _initializerDefineProperty(this, "sessionData", _descriptor2, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor3, this);

      _initializerDefineProperty(this, "selectedActivationStates", _descriptor4, this);

      _initializerDefineProperty(this, "selectedCreditMemoStates", _descriptor5, this);

      _initializerDefineProperty(this, "selectedLaborStates", _descriptor6, this);

      _initializerDefineProperty(this, "selectedProductStates", _descriptor7, this);

      _initializerDefineProperty(this, "selectedMonitoringStates", _descriptor8, this);

      _initializerDefineProperty(this, "selectedServiceStates", _descriptor9, this);

      _initializerDefineProperty(this, "setupStateSalesTaxSettingsTask", _descriptor10, this);

      _initializerDefineProperty(this, "saveStateSalesTaxSettingsTask", _descriptor11, this);

      this.setupStateSalesTaxSettingsTask.perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "usStates", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectedActivationStates", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "selectedCreditMemoStates", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "selectedLaborStates", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "selectedProductStates", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "selectedMonitoringStates", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "selectedServiceStates", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "setupStateSalesTaxSettingsTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "saveStateSalesTaxSettingsTask", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = StateSalesTaxSettings;
});