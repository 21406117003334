define("securitytrax-ember-ui/components/modals/confirm-popup/component", ["exports", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    onSecondary: () => {},
    onConfirm: () => {},
    _dialogOpen: undefined,
    manuallyOpen: undefined,
    secondaryBtnText: '',
    confirmBtnText: '',
    hasSecondaryButton: false,
    secondaryButtonClassNamesString: 'st-btn',
    confirmButtonClassNamesString: 'st-btn st-btn-primary',
    confirmTitleText: '',
    modalNamespace: 'confirmation',
    disableConfirmButton: false,

    init() {
      this._super(...arguments);

      if (!this.confirmBtnText) {
        this.set('confirmBtnText', this.intl.t('common.actions.ok'));
      }

      if (!this.confirmTitleText) {
        this.set('confirmTitleText', this.intl.t('common.confirm.are_you_sure'));
      }

      (0, _objHelpers.initializeState)(this, {
        manuallyOpen: false,
        _dialogOpen: false
      });
    },

    confirmButtons: Ember.computed('secondaryBtnText', 'confirmBtnText', function () {
      var buttonChoices = [{
        text: this.confirmBtnText,
        click: buttonAnimationPromise => {
          var onConfirmResolved = this.onConfirm(buttonAnimationPromise);
          buttonAnimationPromise.then(() => {
            if (this && !this.isDestroyed) {
              this.set('_dialogOpen', false);
            }
          });
          return onConfirmResolved;
        },
        disabled: this.disableConfirmButton,
        classNamesString: this.confirmButtonClassNamesString + ' confirm-modal-confirm-button'
      }];

      if (this.hasSecondaryButton) {
        buttonChoices.unshift({
          text: this.secondaryBtnText,
          click: buttonAnimationPromise => {
            var onSecondaryResolved = this.onSecondary(buttonAnimationPromise);
            buttonAnimationPromise.then(() => {
              if (this && !this.isDestroyed) {
                this.set('_dialogOpen', false);
              }
            });
            return onSecondaryResolved;
          },
          classNamesString: this.secondaryButtonClassNamesString + ' confirm-modal-secondary-button'
        });
      }

      return buttonChoices;
    }),

    click() {
      if (this && !this.manuallyOpen && !this.isDestroyed) {
        this.set('_dialogOpen', true);
      }
    }

  });

  _exports.default = _default;
});