define("securitytrax-ember-ui/customers/information/manage-telus/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S5GKnozz",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modals/sub-route-modals/manage-telus\",[],[[\"@customer\",\"@customerSystemInformation\",\"@monitoringCompany\",\"@parentRoute\",\"@transitionId\"],[[34,0,[\"customer\"]],[34,0,[\"customerSystemInformation\"]],[34,0,[\"monitoringCompany\"]],[34,0,[\"parentRoute\"]],[34,0,[\"transitionId\"]]]],null]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/customers/information/manage-telus/template.hbs"
    }
  });

  _exports.default = _default;
});