define("securitytrax-ember-ui/services/session-activity", ["exports", "securitytrax-ember-ui/config/environment", "ember-concurrency"], function (_exports, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    session: Ember.inject.service(),
    //session is terminated after this amount of inactivity
    inactivityWindow: undefined,
    //dialog for 'are you still here' is presented at inactivityWindow - gracePeriod
    //effectively how long should the window appear before logout is forced
    inactivityGracePeriod: undefined,
    //used for triggering event logic
    activityState: null,
    //used to (temporarily) disable ramifications of firing events
    consentToTickle: true,
    //used to keep track of activity changes to compare against session lastActvity
    lastActivity: null,
    logoutTimer: null,
    initialLogoutTimer: null,
    activityTimer: null,
    watchesStarted: false,
    logoutTime: Ember.computed('lastActivity', 'inactivityWindow', function () {
      return this.lastActivity + this.inactivityWindow;
    }),
    //set internal and session lastActvity
    setLastActivity: function () {
      if (this && !(this.isDestroyed || this.isDestroying)) {
        var now = Math.floor(Date.now() / 1000);
        this.set('lastActivity', now);
        this.session.set('data.lastActivity', now);
        this.resetTimers();
      }
    },
    tickle: function () {
      if (this.consentToTickle && this.session.get('isAuthenticated') === true) {
        this.set('activityState', 'active');
        Ember.run.throttle(this, this.setLastActivity, 5000);
      }
    },
    //used to prevent errors due to logout simultaneously happening on multiple tabs
    invalidateIfAuthenticated: function () {
      //TODO: create a 'we logged you out message' to be display on the login route
      if (this.session.get('isAuthenticated') === true) {
        var pathname = window.location.pathname;
        var url = pathname.split('/').slice(2).join('/');
        localStorage.setItem('storedURL', url);
        this.session.invalidate();
      }
    },
    resetTimers: function () {
      if (this.session.get('isAuthenticated') === true) {
        var currentTime = Math.floor(Date.now() / 1000);
        var lastActivity = this.lastActivity;
        var logoutTime = lastActivity + this.inactivityWindow;
        var activityStateTime = logoutTime - this.inactivityGracePeriod; //reset logout later

        if (this.logoutTimer !== null) {
          Ember.run.cancel(this.logoutTimer);
        }

        this.logoutTimer = Ember.run.later(this, 'invalidateIfAuthenticated', (logoutTime - currentTime) * 1000);

        if (this.activityTimer !== null) {
          Ember.run.cancel(this.activityTimer);
        }

        this.activityTimer = Ember.run.later(this, function () {
          this.set('activityState', 'inactive');
        }, (activityStateTime - currentTime) * 1000);
      }
    },
    lastActivityChanged: Ember.observer('lastActivity', function () {
      if (this.session.get('isAuthenticated') === true) {
        this.set('activityState', 'active');
      }
    }),
    activityStateChanged: Ember.observer('activityState', function () {
      var state = this.activityState;

      if (state == 'active') {
        this.trigger('userActive');
      }

      if (state == 'inactive') {
        this.trigger('userInactive');
      }
    }),

    init() {
      this._super(...arguments);

      if (_environment.default.environment != 'test') {
        Ember.setProperties(this, {
          //session is terminated after this amount of inactivity
          inactivityWindow: _environment.default[_environment.default['modulePrefix']]['session']['inactivityWindow'] !== undefined ? _environment.default[_environment.default['modulePrefix']]['session']['inactivityWindow'] : 15 * 60,
          //dialog for 'are you still here' is presented at inactivityWindow - gracePeriod
          //effectively how long should the window appear before logout is forced
          inactivityGracePeriod: _environment.default[_environment.default['modulePrefix']]['session']['inactivityGracePeriod'] !== undefined ? _environment.default[_environment.default['modulePrefix']]['session']['inactivityGracePeriod'] : 5 * 60
        });
        var session = this.session;
        var data = session.get('data'); //times

        var currentTime = Math.floor(Date.now() / 1000);
        var lastActivity = data['lastActivity'] || currentTime;
        var inactivityWindow = this.inactivityWindow;
        var inactivityGracePeriod = this.inactivityGracePeriod; //prevent stupid

        if (inactivityGracePeriod > inactivityWindow && inactivityWindow > 0) {
          this.set('inactivityWindow', 0);
          inactivityWindow = 0;
        } //set initial internal known lastActivity


        this.set('lastActivity', lastActivity); //crudely set state based on authentication status

        this.set('activityState', session.get('isAuthenticated') ? 'active' : 'inactive');

        if (session.get('isAuthenticated') === true) {
          //cope with proper activityState
          if (currentTime - lastActivity > inactivityWindow - inactivityGracePeriod) {
            this.set('activityState', 'inactive');
          } //force logout if appropriate


          if (currentTime - lastActivity > inactivityWindow && inactivityWindow > 0) {
            this.set('activityState', 'inactive');
            this.invalidateIfAuthenticated();
          }
        }

        this.startWatches();
      }
    },

    /*
        This is only here (vs init) to facilitate testing and cannot be undone.
        Please plan accordingly :)
    */
    startWatches: function () {
      //do not listen when it's dumb to do so
      if (this.inactivityWindow > 0 && !this.watchesStarted) {
        var context = this; //setup initial watchers if zero activity after login (or page reload)
        //we need to do this here as observers only start observing *after* init

        (0, _emberConcurrency.waitForProperty)(this, 'session.isAuthenticated', true).then(() => {
          //if we consider refreshing the page and/or opening a new tab activity then force state to active
          context.set('activityState', 'active');
          context.tickle();
          context.resetTimers();
        }); //http://github.jhawk.co/ember-user-activity/
        //setup activity listeners

        window.document.body.addEventListener('mousedown', function () {
          context.tickle();
        });
        window.document.body.addEventListener('keydown', function () {
          context.tickle();
        });
        window.document.body.addEventListener('scroll', function () {
          context.tickle();
        });
        window.document.body.addEventListener('touchstart', function () {
          context.tickle();
        });
        this.session.on('authenticationSucceeded', function () {
          context.set('activityState', 'active');
          context.tickle();
        }); //only fired on non-active tabs

        this.session.get('store').on('sessionDataUpdated', function (data) {
          if (data.lastActivity && data.lastActivity > context.get('lastActivity')) {
            context.set('activityState', 'active');
            context.set('lastActivity', data.lastActivity);
            context.resetTimers();
          }
        });
        this.set('watchesStarted', true);
      }
    }
  });

  _exports.default = _default;
});