define("securitytrax-ember-ui/users/login-information/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GBAfNIJW",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"user\",\"userPasswordSetting\",\"model\"],[[35,0,[\"user\"]],[35,0,[\"userPasswordSetting\"]],[35,0]]]]]],\"hasEval\":false,\"upvars\":[\"model\",\"pages/users/login-information\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/users/login-information/template.hbs"
    }
  });

  _exports.default = _default;
});