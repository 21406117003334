define("securitytrax-ember-ui/components/pages/accounting/billing/payment-methods/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CeAG1Cd8",
    "block": "{\"symbols\":[\"componentData\",\"currButton\"],\"statements\":[[6,[37,7],[[30,[36,6],[\"customer_billings.view\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"html/st-card\",[[24,0,\"customer-payment-methods\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"customer/accounting/payment-methods-list-add-edit-subroute\",[],[[\"@buttonClasses\",\"@customer\"],[\"st-btn icon-text-button\",[34,1,[\"customer\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"html/st-card/divider\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[10,\"div\"],[14,0,\"cell small-1 st-card__divider-cell st-card__divider-secondary-action-cell st-card__divider-mobile-order-0 text-align-left\"],[12],[2,\"\\n\"],[6,[37,2],null,[[\"replace\",\"route\"],[true,\"customers.accounting\"]],[[\"default\"],[{\"statements\":[[2,\"                        \"],[1,[30,[36,0],[\"icn-back\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"                \"],[13],[2,\"\\n\\n                \"],[10,\"div\"],[14,0,\"st-card__divider-cell st-card__divider-title-cell align-center\"],[12],[2,\"\\n                    \"],[10,\"h1\"],[14,0,\"st-card__title\"],[12],[1,[30,[36,3],[\"pages.customers.accounting.customer_billing.title\"],null]],[13],[2,\"\\n                \"],[13],[2,\"\\n\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,1,[\"buttons\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                    \"],[10,\"div\"],[14,0,\"st-card__divider-cell st-card__divider-primary-action-cell\"],[12],[2,\"\\n                        \"],[1,[32,2]],[2,\"\\n                    \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"            \"]],\"parameters\":[]}]]],[2,\"\\n        \"]],\"parameters\":[1]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"svg-icon\",\"model\",\"link-to\",\"t\",\"-track-array\",\"each\",\"has-grants\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/accounting/billing/payment-methods/template.hbs"
    }
  });

  _exports.default = _default;
});