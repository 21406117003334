define("securitytrax-ember-ui/components/multi-step-form/forms/administration/inventory/edit-vendor-details/component", ["exports", "@ember-data/model", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency"], function (_exports, _model, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    inventoryService: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    enhancedFeatures: Ember.inject.service(),
    grants: Ember.inject.service(),
    equipmentVendors: undefined,
    toAddEquipmentVendorToRecipients: undefined,
    toDeleteEquipmentVendorToRecipients: undefined,
    connectorOptions: undefined,
    equipmentVendorToRecipients: undefined,
    hasFeatureInventoryExtendedFunctionality: Ember.computed(function () {
      return this.hasFeatureTask.perform();
    }),
    hasFeatureTask: (0, _emberConcurrency.task)(function* () {
      var isEnabled = yield this.enhancedFeatures.hasFeature('inventoryExtendedFunctionality');
      return isEnabled;
    }),
    initData: Ember.computed(function () {
      return this.initDataTask.perform();
    }),
    initDataTask: (0, _emberConcurrency.task)(function* () {
      var _this = this;

      var store = this.store;
      var inventoryService = this.inventoryService;
      var hashedData = yield (0, _emberConcurrency.hash)({
        equipmentVendors: store.findAll('equipmentVendor', {
          include: 'equipment_vendor_config'
        }),
        equipmentVendorToRecipients: this.recipient.constructor.modelName === 'user' ? this.get('recipient.userInventorySetting.equipmentVendorToRecipients') : this.get('recipient.equipmentVendorToRecipients'),
        connectorOptions: inventoryService.get('getConnectorOptionsTask').perform()
      });

      var _loop = function* (i) {
        var vendor = hashedData.equipmentVendors.objectAt(i);
        var vendorConfig = vendor && _this.grants.getIndividualGrant('equipment_vendors.manage_connector') ? yield vendor.get('equipmentVendorConfig') : null;
        var equipmentVendorToRecipient = hashedData.equipmentVendorToRecipients.find(equipmentVendorToRecipient => equipmentVendorToRecipient.get('equipmentVendor.id') === vendor.get('id'));
        var connector = hashedData.connectorOptions.find(connector => connector.value === vendorConfig.get('connector'));
        var additionalInformation = yield vendor.get('additionalInformation');
        var processing = yield vendor.get('processingMethods');
        Ember.set(vendor, 'connector', connector);
        Ember.set(vendor, 'equipmentVendorToRecipient', equipmentVendorToRecipient);
        Ember.set(vendor, 'branchNumbers', additionalInformation ? additionalInformation.branch_numbers : []);
        Ember.set(vendor, 'retrievalMethods', additionalInformation ? additionalInformation.retrieval : []);
        Ember.set(vendor, 'processingMethods', processing);

        if (equipmentVendorToRecipient) {
          Ember.set(equipmentVendorToRecipient, 'config', equipmentVendorToRecipient && equipmentVendorToRecipient.get('config') ? equipmentVendorToRecipient.get('config') : {
            fields: {
              waive_signature: false
            }
          });
        }
      };

      for (var i = 0; i < hashedData.equipmentVendors.get('length'); i++) {
        yield* _loop(i);
      }

      Ember.set(this, 'connectorOptions', hashedData.connectorOptions);
      Ember.set(this, 'equipmentVendors', hashedData.equipmentVendors);
      Ember.set(this, 'equipmentVendorToRecipients', hashedData.equipmentVendorToRecipients);
      Ember.set(this, 'toAddEquipmentVendorToRecipients', []);
      Ember.set(this, 'toDeleteEquipmentVendorToRecipients', []);
    }),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;

      if (this.formMode !== 'view') {
        multiStepForm.registerStepData('vendorDetails', 'buttons', [{
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveEquipmentVendorToRecipients.perform(multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary vendor-details-save-button'
        }]);
      }
    },

    saveEquipmentVendorToRecipients: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var allSaves = [];
      var deleteEquipmentVendorToRecipients = this.toDeleteEquipmentVendorToRecipients;
      var newEquipmentVendorToRecipients = this.toAddEquipmentVendorToRecipients;
      var toUpdateVendors = this.equipmentVendorToRecipients.filter(equipmentVendorToRecipients => {
        return equipmentVendorToRecipients.get('updated');
      });

      try {
        if (toUpdateVendors) {
          toUpdateVendors.forEach(equipmentVendorToRecipient => {
            allSaves.push(equipmentVendorToRecipient.save());
          });
        }

        if (newEquipmentVendorToRecipients) {
          newEquipmentVendorToRecipients.forEach(equipmentVendorToRecipient => {
            allSaves.push(this.createEquipmentVendorToRecipientTask.perform(equipmentVendorToRecipient));
          });
        }

        if (deleteEquipmentVendorToRecipients) {
          deleteEquipmentVendorToRecipients.forEach(equipmentVendorToRecipient => {
            if (equipmentVendorToRecipient.get('id')) {
              allSaves.push(this.deleteEquipmentVendorToRecipientTask.perform(equipmentVendorToRecipient));
            }
          });
        }

        yield (0, _emberConcurrency.all)(allSaves);
        buttonPromise.then(() => {
          if (this.recipient.constructor.modelName === 'user') {
            multiStepForm.formStartedSetVal(false);
          }

          this.equipmentVendorToRecipients.reload();
          Ember.set(this, 'toAddEquipmentVendorToRecipients', []);
          Ember.set(this, 'toDeleteEquipmentVendorToRecipients', []);
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
        throw err;
      }
    }),
    createEquipmentVendorToRecipientTask: (0, _emberConcurrency.task)(function* (equipmentVendorToRecipient) {
      var newEquipmentVendorToRecipient = yield this.store.createRecord('equipmentVendorToRecipient', {
        accountNumber: equipmentVendorToRecipient.get('accountNumber'),
        equipmentVendor: equipmentVendorToRecipient.get('vendor'),
        branchNumber: equipmentVendorToRecipient.get('branchNumber'),
        defaultRetrievalMethodId: equipmentVendorToRecipient.get('defaultRetrievalMethodId'),
        defaultProcessingMethodId: equipmentVendorToRecipient.get('defaultProcessingMethodId'),
        user: this.recipient.constructor.modelName === 'user' ? this.recipient : null,
        location: this.recipient.constructor.modelName === 'location' ? this.recipient : null
      });
      return newEquipmentVendorToRecipient.save();
    }),
    deleteEquipmentVendorToRecipientTask: (0, _emberConcurrency.task)(function* (equipmentVendorToRecipient) {
      var deleteEquipmentVendorToRecipient = yield this.store.findRecord('equipmentVendorToRecipient', equipmentVendorToRecipient.get('id'));
      return deleteEquipmentVendorToRecipient.destroyRecordWithRollbackTask.perform();
    }),
    actions: {
      updateEquipmentVendorToRecipient(row) {
        var equipmentVendorToRecipient = row.get('equipmentVendorToRecipient');
        var existingEquipmentVendorToRecipient = this.equipmentVendorToRecipients.find(entry => {
          return JSON.parse(entry.get('id')).vendor_id === row.get('id');
        });

        if (equipmentVendorToRecipient) {
          Ember.set(row, 'equipmentVendorToRecipient', undefined);
          Ember.set(this, 'toAddEquipmentVendorToRecipients', this.toAddEquipmentVendorToRecipients.filter(entry => {
            return entry.get('vendor.id') !== row.get('id');
          }));

          if (existingEquipmentVendorToRecipient) {
            Ember.set(equipmentVendorToRecipient, 'vendor', row);
            Ember.set(equipmentVendorToRecipient, 'updated', false);
            this.toDeleteEquipmentVendorToRecipients.push(equipmentVendorToRecipient);
          }
        } else {
          var newEquipmentVendorToRecipient;

          if (!existingEquipmentVendorToRecipient) {
            newEquipmentVendorToRecipient = Ember.Object.create({
              accountNumber: null,
              vendor: row,
              branchNumber: null,
              defaultRetrievalMethodId: 0,
              defaultProcessingMethodId: 0,
              updated: false
            });
            this.toAddEquipmentVendorToRecipients.push(newEquipmentVendorToRecipient);
          } else {
            Ember.set(existingEquipmentVendorToRecipient, 'updated', true);
          }

          Ember.set(row, 'equipmentVendorToRecipient', existingEquipmentVendorToRecipient || newEquipmentVendorToRecipient);
          Ember.set(this, 'toDeleteEquipmentVendorToRecipients', this.toDeleteEquipmentVendorToRecipients.filter(entry => {
            return entry.get('vendor.id') !== row.get('id');
          }));
        }
      },

      markAsUpdated(equipmentVendorToRecipient) {
        if (equipmentVendorToRecipient instanceof _model.default) {
          Ember.set(equipmentVendorToRecipient, 'updated', true);
        }
      }

    }
  });

  _exports.default = _default;
});