define("securitytrax-ember-ui/components/html/tinymce/component", ["exports", "tinymce", "tinymce/icons/default", "tinymce/themes/silver", "tinymce/plugins/paste", "tinymce/plugins/link", "tinymce/plugins/image", "tinymce/plugins/code"], function (_exports, _tinymce, _default2, _silver, _paste, _link, _image, _code) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Import TinyMCE
  // Default icons are required for TinyMCE 5.3 or above
  // A theme is also required
  // Any plugins you want to use has to be imported
  var _default = Ember.Component.extend({
    editor: null,
    classNames: ['tinymce-editor'],
    tagName: 'textarea',
    _contentChangedListener: null,
    changeDebounce: 10,
    options: undefined,

    init() {
      this._super(...arguments);

      this.set('options', this.options || {});
    },

    formModeObserver: Ember.observer('showView', function () {
      if (this.editor && !this.isDestroying && !this.isDestroyed) {
        if (this.showView) {
          this.editor.mode.set('readonly');
        } else {
          this.editor.mode.set('design');
        }
      }
    }),
    valueChanged: Ember.observer('value', function () {
      var {
        editor,
        value
      } = this;

      if (editor && editor.getContent() !== value) {
        editor.setContent(value || '');
      }
    }),

    onValueChanged(value) {
      if (this.editor && !this.isDestroying && !this.isDestroyed) {
        this.set('value', value);
      }
    },

    contentChanged(editor) {
      var content = editor.getContent();

      if (editor && editor.isDirty() && content !== this.value) {
        this.onValueChanged(editor.getContent());
        editor.setDirty(true);
      }
    },

    debounceContentChanged(editor, time) {
      Ember.run.debounce(this, this.contentChanged, editor, time);
    },

    setEvents: Ember.observer('editor', function () {
      var {
        changeDebounce,
        editor
      } = this;

      if (!editor) {
        return;
      }

      editor.on('change keyup keydown keypress mousedown', Ember.run.bind(this, this.debounceContentChanged, editor, changeDebounce));
    }),

    didInsertElement() {
      this._super(...arguments);

      this.initTiny();
    },

    initTiny() {
      var {
        options,
        editor
      } = this;

      var initFunction = editor => {
        if (editor && !this.isDestroying && !this.isDestroyed) {
          this.set('editor', editor);
          this.editor.setContent(this.value || ''); //Set content with default text
        }
      };

      var customOptions = {
        selector: "#".concat(this.elementId),
        init_instance_callback: Ember.run.bind(this, initFunction),
        readonly: this.showView
      };

      if (this.height) {
        customOptions['height'] = this.height;
      }

      if (editor) {
        editor.setContent('');
        editor.destroy();
      }

      Ember.run.later(() => {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }

        if (typeof _tinymce.default === 'undefined') {
          return;
        }

        _tinymce.default.init(Ember.assign({}, options, customOptions));
      }, 10);
    },

    willDestroyElement() {
      var editor = this.editor;

      if (editor) {
        editor.off('change keyup keydown keypress mousedown');
        editor.destroy();
      }
    }

  });

  _exports.default = _default;
});