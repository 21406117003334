define("securitytrax-ember-ui/components/multi-step-form/forms/administration/content-management/edit-order-template/component", ["exports", "@ember-data/model", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "ember-concurrency", "lodash", "ember-inflector"], function (_exports, _model, _component, _switchableFormStep, _emberConcurrency, _lodash, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EditOrderTemplate = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = (0, _emberConcurrency.task)(function* () {
    return yield Ember.RSVP.hash({
      activationFees: this.store.findAll('activationFee'),
      packages: this.store.findAll('package'),
      monitoringCompanies: this.store.findAll('monitoringCompany'),
      monitoringPlans: this.store.query('monitoringPlan', {
        filter: 'company_config=true',
        limit: 0
      }),
      mmrs: this.store.query('mmr', {
        filter: 'active=true',
        sort: 'amount',
        limit: 0
      }),
      warrantyTerms: this.store.findAll('customerWarranty'),
      contractTerms: this.store.findAll('contractTerm'),
      interactiveServicesProviders: this.store.findAll('interactiveServicesProvider'),
      packagesColumns: this.packagesColumns
    });
  }), _dec18 = (0, _emberConcurrency.task)(function* () {
    var multiStepForm = this.args.multiStepForm;
    yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);
    this.selectedPackages = [];
    this.selectedMmrs = [];
    this.additionalData = yield this.getAdditionalData.perform();
    this.additionalData.mmrs = this.additionalData.mmrs.sortBy('amountNumeric');
    this.deletedPackages = [];

    if (this.args.formMode === 'view') {
      this.disabled = true;
    }

    if (this.SwitchableFormStep.isNewRecord) {
      multiStepForm.formCopiedModelsSetVal('orderTemplate.model.active', true); // was asked to remove these for now - keeping in case change their minds
      // multiStepForm.formCopiedModelsSetVal('orderTemplate.model.allowQuote', true);
      // multiStepForm.formCopiedModelsSetVal('orderTemplate.model.allowInvoice', true);
    } else {
      this.args.orderTemplate.defaultMmrs.then(mmrs => {
        var newSelectedMmrs = [];
        mmrs.forEach(currMmr => {
          newSelectedMmrs.push(currMmr);
        });
        this.selectedMmrs = _lodash.default.sortBy(newSelectedMmrs, 'amount');
      });
      var packages = yield this.args.orderTemplate.defaultPackages;
      var newSelectedPackages = [];
      packages.forEach(currPackage => {
        newSelectedPackages.push(currPackage);
      });
      this.selectedPackages = newSelectedPackages;
      this.showPackageSection = this.selectedPackages.length > 0;
      this.showAutoCreationSection = multiStepForm.formCopiedModels.orderTemplate.model.allowQuote || multiStepForm.formCopiedModels.orderTemplate.model.allowInvoice;
    }
  }), _dec19 = Ember._action, _dec20 = (0, _emberConcurrency.task)(function* () {
    var packageToEquipmentCount = Ember.A([]);
    var packages = yield this.selectedPackages;

    if (packages) {
      packages.forEach(currPackage => {
        var packageEquipmentPromises = [];

        if (currPackage instanceof _model.default) {
          packageEquipmentPromises.push(currPackage.get('packageEquipment'));
        } else {
          packageEquipmentPromises.push(this.store.findRecord('package', currPackage.id).then(thePackage => {
            return thePackage.get('packageEquipment');
          }));
        }

        Ember.RSVP.all(packageEquipmentPromises).then(pkEquipmentList => {
          pkEquipmentList = pkEquipmentList instanceof _model.default ? pkEquipmentList : pkEquipmentList[0];
          var promisesArray = [];
          pkEquipmentList.forEach(currPkEquipment => {
            var equipmentPromise = currPkEquipment.get('equipment');
            promisesArray.push(equipmentPromise);
          });
          Ember.RSVP.all(promisesArray).then(() => {
            var currPkEquipmentList = {};
            pkEquipmentList.forEach(currPkEquipment => {
              var equipmentName = currPkEquipment.get('equipment.name');

              if (!currPkEquipmentList[equipmentName]) {
                currPkEquipmentList[equipmentName] = 1;
              } else {
                currPkEquipmentList[equipmentName]++;
              }
            });
            var currPkEquipmentListEmberArr = Object.keys(currPkEquipmentList).map(equipmentName => {
              return Ember.Object.create({
                name: equipmentName,
                quantity: currPkEquipmentList[equipmentName]
              });
            });
            packageToEquipmentCount.pushObject(Ember.Object.create({
              packageName: currPackage.get('name'),
              packageId: currPackage.get('id'),
              packageContent: currPkEquipmentListEmberArr
            }));
          });
        });
      });
    }

    return packageToEquipmentCount;
  }), _dec21 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    var templateToSaveOrUpdate = this.args.orderTemplate;

    if (this.SwitchableFormStep.isNewRecord) {
      var toCreateObjects = yield multiStepForm.validateCreateAll();
      var orderTemplate = this.store.createRecord('orderTemplate', toCreateObjects.orderTemplate.data);
      templateToSaveOrUpdate = yield toCreateObjects.orderTemplate.saveWithRollbackAndNotification(orderTemplate);
    } else {
      yield multiStepForm.validateSyncSaveAll({
        notificationNamespace: 'modal'
      });
    }

    yield Ember.RSVP.hash({
      postMmrs: this.updateTemplateRelationship.perform('POST', 'default_mmrs', this.selectedMmrs, templateToSaveOrUpdate),
      postPackages: this.updateTemplateRelationship.perform('POST', 'default_packages', this.selectedPackages, templateToSaveOrUpdate),
      deleteMmrs: this.updateTemplateRelationship.perform('DELETE', 'default_mmrs', this.deletedMmrs, templateToSaveOrUpdate),
      deletePackages: this.updateTemplateRelationship.perform('DELETE', 'default_packages', this.deletedPackages, templateToSaveOrUpdate)
    });
    yield this.args.reloadOrderTemplate(templateToSaveOrUpdate);
    buttonPromise.then(() => {
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
      multiStepForm.formStartedSetVal(false);
    });
  }), _dec22 = (0, _emberConcurrency.task)(function* (method, relation, selectedData, templateToUpdate) {
    var orderTemplate = templateToUpdate;

    if (selectedData && selectedData.get('length') > 0) {
      var connectedData = [];
      selectedData.forEach(data => {
        connectedData.pushObject(data);
      });
      yield orderTemplate.updateRelationship(method, relation, connectedData, relation.replace('default_', ''));
    }
  }), _dec23 = (0, _emberConcurrency.task)(function* () {
    var orderTemplate = this.args.multiStepForm.formOriginalModelsGetVal('orderTemplate.model');

    try {
      yield orderTemplate.destroyRecordWithRollbackTask.perform();
      this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
      this.args.multiStepForm.onDelete();
      this.args.multiStepForm.formStartedSetVal(false);
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'confirmDeleteModal'
      });
      throw err;
    }
  }), _dec24 = (0, _emberConcurrency.task)(function* (modelName, value) {
    var valuesList = yield this.store.findAll(modelName);

    if (modelName === 'activationFee' || modelName === 'mmr') {
      Ember.set(this.additionalData, (0, _emberInflector.pluralize)(modelName), valuesList.sortBy('amountNumeric'));
    } else if (modelName === 'contractTerm') {
      Ember.set(this.additionalData, (0, _emberInflector.pluralize)(modelName), valuesList.sortBy('months'));
    }

    if (modelName === 'activationFee' || modelName === 'contractTerm') {
      var properName = modelName.charAt(0).toUpperCase() + modelName.slice(1);
      this.args.multiStepForm.formCopiedModelsSetVal("orderTemplate.model.default".concat(properName), value);
    } else if (modelName === 'mmr') {
      this.selectItem('mmrs', value, false);
    }
  }), _dec25 = Ember._action, _dec26 = Ember._action, _dec27 = Ember._action, _dec28 = Ember._action, _dec29 = Ember._action, (_class = (_temp = class EditOrderTemplate extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "grants", _descriptor4, this);

      _initializerDefineProperty(this, "confirmDeleteModalOpen", _descriptor5, this);

      _initializerDefineProperty(this, "selectedMmrs", _descriptor6, this);

      _initializerDefineProperty(this, "selectedPackages", _descriptor7, this);

      _initializerDefineProperty(this, "deletedMmrs", _descriptor8, this);

      _initializerDefineProperty(this, "deletedPackages", _descriptor9, this);

      _initializerDefineProperty(this, "selectedPackageId", _descriptor10, this);

      _initializerDefineProperty(this, "showPackageSection", _descriptor11, this);

      _initializerDefineProperty(this, "showAutoCreationSection", _descriptor12, this);

      _initializerDefineProperty(this, "disabled", _descriptor13, this);

      _initializerDefineProperty(this, "showMmrsDropdown", _descriptor14, this);

      _initializerDefineProperty(this, "showPackagesDropdown", _descriptor15, this);

      _initializerDefineProperty(this, "additionalData", _descriptor16, this);

      _initializerDefineProperty(this, "getAdditionalData", _descriptor17, this);

      _initializerDefineProperty(this, "initFormData", _descriptor18, this);

      _initializerDefineProperty(this, "getPackageOptionsTask", _descriptor19, this);

      _initializerDefineProperty(this, "saveOrderTemplate", _descriptor20, this);

      _initializerDefineProperty(this, "updateTemplateRelationship", _descriptor21, this);

      _initializerDefineProperty(this, "deleteOrderTemplate", _descriptor22, this);

      _initializerDefineProperty(this, "addNewListValue", _descriptor23, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.initFormData.perform();
    }

    get packagesColumns() {
      return [{
        propertyName: 'name'
      }];
    }

    get sortedSelectedMmrs() {
      return this.selectedMmrs.sortBy('amountNumeric');
    }

    get createRecordOptionsAmount() {
      return {
        amount: 0,
        active: true
      };
    }

    get createRecordOptionsMonths() {
      return {
        months: 0,
        active: true
      };
    }

    renderButtons() {
      var multiStepForm = this.args.multiStepForm;
      var buttons = [];

      if (this.args.formMode !== 'view') {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveOrderTemplate.perform(multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary order-template-save-button'
        });
      }

      if (!this.SwitchableFormStep.isNewRecord && this.grants.getIndividualGrant('order_templates.delete')) {
        buttons.push({
          text: this.intl.t('common.actions.delete'),
          click: () => {
            this.confirmDeleteModalOpen = true;
          },
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
        });
      }

      multiStepForm.registerStepData('step1', 'buttons', buttons);
    }

    get packageOptions() {
      return this.getPackageOptionsTask.perform();
    }

    selectItem(relationModel, clickedItem, isSelected) {
      relationModel = relationModel.capitalize();
      var selectedItems = Ember.get(this, "selected".concat(relationModel)) || [];
      var deletedItems = Ember.get(this, "deleted".concat(relationModel)) || [];

      if (isSelected) {
        selectedItems.removeObject(clickedItem);

        if (!this.SwitchableFormStep.isNewRecord) {
          deletedItems.pushObject(clickedItem);
        }
      } else {
        selectedItems.pushObject(clickedItem);
        deletedItems.removeObject(clickedItem);
      }

      Ember.set(this, "deleted".concat(relationModel), deletedItems);
      return new Ember.RSVP.resolve(true);
    }

    toggleMmrsDropdown() {
      this.showMmrsDropdown = !this.showMmrsDropdown;
    }

    togglePackagesDropdown() {
      this.showPackagesDropdown = !this.showPackagesDropdown;
    }

    resetPackages() {
      if (!this.showPackageSection) {
        this.selectedPackages.forEach(pkg => {
          this.deletedPackages.push(pkg);
        });
        this.selectedPackages = [];
      }
    }

    newModelCreated(modelName, savedValue) {
      this.addNewListValue.unlinked().perform(modelName, savedValue);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "confirmDeleteModalOpen", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "selectedMmrs", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "selectedPackages", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "deletedMmrs", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "deletedPackages", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "selectedPackageId", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "showPackageSection", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "showAutoCreationSection", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "disabled", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "showMmrsDropdown", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "showPackagesDropdown", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "additionalData", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "getAdditionalData", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "initFormData", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "renderButtons", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "renderButtons"), _class.prototype), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "getPackageOptionsTask", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "saveOrderTemplate", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "updateTemplateRelationship", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "deleteOrderTemplate", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "addNewListValue", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "selectItem", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "selectItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleMmrsDropdown", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "toggleMmrsDropdown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "togglePackagesDropdown", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "togglePackagesDropdown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetPackages", [_dec28], Object.getOwnPropertyDescriptor(_class.prototype, "resetPackages"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newModelCreated", [_dec29], Object.getOwnPropertyDescriptor(_class.prototype, "newModelCreated"), _class.prototype)), _class));
  _exports.default = EditOrderTemplate;
});