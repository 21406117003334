define("securitytrax-ember-ui/users/permissions/route", ["exports", "securitytrax-ember-ui/users/base-route", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _baseRoute, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_routePageviewLogging.default, {
    beforeModel() {
      this._super(...arguments);

      var userId = this.paramsFor('users').id;
      return this.findGrantsTask.perform(['user_admin_settings.view'], 'all', '/users/' + userId + '/information');
    },

    model() {
      var {
        user
      } = this._super(...arguments);

      return Ember.RSVP.hash({
        user,
        groupOptions: this.store.findAll('group'),
        resourceTypes: this.store.findAll('resourceType')
      });
    }

  });

  _exports.default = _default;
});