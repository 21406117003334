define("securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/re-usable-forms/decision-waiting/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6kwNQ3Cj",
    "block": "{\"symbols\":[\"@buttons\",\"@waitingDecision\"],\"statements\":[[8,\"modals/st-paper\",[],[[\"@modalButtons\",\"@dialogOpen\",\"@classNames\"],[[32,1],[32,2],\"st-modal--small no-min-height decision-waiting__modal\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"grid-x grid-padding-x grid-padding-y align-center\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cell\"],[12],[2,\"\\n            \"],[1,[30,[36,0],null,[[\"spinning\",\"size\",\"class\"],[true,\"medium\",\"decision-waiting__spinner\"]]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cell decision__primary-text\"],[12],[2,\"\\n            \"],[1,[30,[36,1],[\"pages.customers.accounting.financing.please_wait\"],null]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cell decision__secondary-text\"],[12],[2,\"\\n            \"],[1,[30,[36,1],[\"pages.customers.accounting.financing.wait_time\"],null]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cell decision__secondary-text\"],[12],[2,\"\\n            \"],[1,[30,[36,1],[\"pages.customers.accounting.financing.complete_notification\"],null]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"spinner-svg\",\"t\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/re-usable-forms/decision-waiting/template.hbs"
    }
  });

  _exports.default = _default;
});