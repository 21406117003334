define("securitytrax-ember-ui/mixed-in-classes/grouped-equipment", ["exports", "@glimmer/component", "ember-concurrency", "securitytrax-ember-ui/mixed-in-functions/grouped-property-sections", "lodash"], function (_exports, _component, _emberConcurrency, _groupedPropertySections, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var GroupedEquipment = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = (0, _emberConcurrency.task)(function* () {
    var equipmentTypes = yield this.store.query('equipmentType', {
      sort: 'name'
    });
    this.equipmentTypes = equipmentTypes;
    equipmentTypes.forEach((type, index) => {
      type.set('sort', index++);
    });
  }), _dec4 = (0, _emberConcurrency.task)(function* (items, collapsed = false, sort = 'equipment.name') {
    try {
      var resolvedItems = (yield items).sortBy(sort);

      if (resolvedItems) {
        var childTasks = [];

        if (resolvedItems) {
          resolvedItems.map(item => {
            childTasks.push(this.mapItemsTask.perform(item));
          });
        }

        var allLineItems = yield (0, _emberConcurrency.all)(childTasks);
        return allLineItems.uniqBy('type.id').sortBy('type.sort').map(({
          type
        }) => {
          return Ember.Object.create({
            name: type.get('name'),
            icon: type.get('icon'),
            color: "background-color: ".concat(type.get('color')),
            sections: (0, _groupedPropertySections.default)(),
            items: allLineItems.filterBy('type.id', type.id).mapBy('equipment'),
            collapsable: true,
            collapsed,
            collapseIconName: 'icn-chevron-down'
          });
        });
      } else {
        throw new Error('You must have items set for grouped equipment');
      }
    } catch (error) {
      (false && Ember.warn('groupedItemsTask ' + error, {
        id: 'mixins.grouped-equipment.groupedItemsTask'
      }));
    }
  }), _dec5 = (0, _emberConcurrency.task)(function* (items, collapsed = false, sort = 'equipment.name') {
    try {
      var resolvedItems = (yield items).sortBy(sort);

      if (resolvedItems) {
        var childTasks = [];

        if (resolvedItems) {
          resolvedItems.map(item => {
            childTasks.push(this.mapItemsTask.perform(item));
          });
        }

        var allLineItems = yield (0, _emberConcurrency.all)(childTasks);
        return allLineItems.uniqBy('type.id').sortBy('type.sort').map(({
          type
        }) => {
          var filterBy = allLineItems.filterBy('type.id', type.id);
          var mapBy = filterBy.mapBy('equipment');
          var groupedByEquipmentId = {};
          mapBy.forEach(curr => {
            if (!groupedByEquipmentId[curr.get('equipment.id')]) {
              groupedByEquipmentId[curr.get('equipment.id')] = {
                count: 1,
                item: curr
              };
            } else {
              groupedByEquipmentId[curr.get('equipment.id')]['count']++;
            }
          });

          var groupedByEquipmentIdValues = _lodash.default.values(groupedByEquipmentId);

          return Ember.Object.create({
            name: type.get('name'),
            icon: type.get('icon'),
            color: "background-color: ".concat(type.get('color')),
            sections: (0, _groupedPropertySections.default)(),
            items: groupedByEquipmentIdValues,
            collapsable: true,
            collapsed,
            collapseIconName: 'icn-chevron-down'
          });
        });
      } else {
        throw new Error('You must have items set for grouped equipment');
      }
    } catch (error) {
      (false && Ember.warn('groupedItemsTask ' + error, {
        id: 'mixins.grouped-equipment.groupedItemsTask'
      }));
    }
  }), _dec6 = (0, _emberConcurrency.task)(function* item(item) {
    try {
      yield (0, _emberConcurrency.waitForProperty)(this.initDataTask, 'isIdle', true);
      var equipment = item.constructor.modelName === 'equipment' ? item : yield item.get('equipment');
      var equipmentType = equipment ? yield equipment.get('equipmentType') : null;
      var equipmentTypeOther = Ember.Object.create({
        id: 0,
        sort: this.equipmentTypes.length,
        name: this.intl.t('pages.customers.equipment.columns.other'),
        icon: 'icn-folder-open',
        color: '#22a1c1'
      });
      return {
        equipment: item,
        type: equipmentType ? equipmentType : equipmentTypeOther
      };
    } catch (error) {
      (false && Ember.warn('mapItemsTask ' + error, {
        id: 'mixins.grouped-equipment.mapItemsTask'
      }));
    }
  }), (_class = (_temp = class GroupedEquipment extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _defineProperty(this, "equipmentTypes", undefined);

      _initializerDefineProperty(this, "initDataTask", _descriptor3, this);

      _initializerDefineProperty(this, "groupedItemsTask", _descriptor4, this);

      _initializerDefineProperty(this, "groupedItemsByQuantityTask", _descriptor5, this);

      _initializerDefineProperty(this, "mapItemsTask", _descriptor6, this);

      this.initDataTask.perform();
    }

    get groupedItems() {
      return this.groupedItemsTask.perform(this.args.items);
    }

    get groupedItemsByQuantity() {
      return this.groupedItemsByQuantityTask.perform(this.args.items);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "groupedItemsTask", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "groupedItemsByQuantityTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "mapItemsTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = GroupedEquipment;
});