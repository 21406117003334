define("securitytrax-ember-ui/validations/financing/certificate-contract", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateObject = {
        address: {
          presence: this.customMessageOrDefault(data, 'address')
        },
        city: {
          presence: this.customMessageOrDefault(data, 'city')
        },
        state: {
          presence: this.customMessageOrDefault(data, 'state')
        },
        zip: {
          presence: this.customMessageOrDefault(data, 'zip')
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      return this.outputFormatting(validationData, 'certificateContract');
    }

  });

  _exports.default = _default;
});