define("securitytrax-ember-ui/components/multi-step-form/forms/demo/demo-datepicker/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency"], function (_exports, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);
    },

    testValidationTask: (0, _emberConcurrency.task)(function* (buttonPromise) {
      var multiStepForm = this.multiStepForm;
      yield multiStepForm.deepValidateAll.perform({
        extraValidationData: {
          demo: true,
          noValidate: ['customer-zip']
        }
      });
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccess('Validations Passed!');
      });
    }),
    actions: {
      testValidation(buttonPromise) {
        return this.testValidationTask.perform(buttonPromise);
      }

    }
  });

  _exports.default = _default;
});