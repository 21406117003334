define("securitytrax-ember-ui/demo/power-select/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _authenticatedRouteMixin, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _routePageviewLogging.default, {
    model() {
      return Ember.RSVP.hash({
        techIds: [1, 2, 3],
        customerIds: [1, 2, 3],
        customers: this.store.findAll('customer'),
        users: this.store.findAll('user'),
        locations: this.store.findAll('location'),
        leadCompanies: this.store.findAll('leadCompany'),
        customerOrderInformation: this.store.findRecord('customerOrderInformation', 1),
        invoiceBillableItems: this.store.query('invoiceBillableItem', {
          limit: 10,
          page: 1
        }),
        firstBillableItem: this.store.findRecord('invoiceBillableItem', 1)
      });
    },

    setupController(controller) {
      this._super(...arguments);

      controller.set('searchProp1', [{
        field: 'name'
      }]);
      controller.set('searchProp2', [{
        field: 'fname'
      }, {
        field: 'lname'
      }]);
      controller.set('searchProp3', [{
        field: 'fname'
      }]);
    }

  });

  _exports.default = _default;
});