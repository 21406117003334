define("securitytrax-ember-ui/mixins/cs-link-account-mixin", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    subrouteMapping: Ember.inject.service(),
    linkAccountModalOpen: false,
    linkModalTitle: '',
    accountFailText: '',

    init() {
      this._super(...arguments);

      Ember.defineProperty(this, 'basicCustomerInformation', Ember.computed('accountFailText', this.store.getFullComputedWatcherStringByModelName('customer'), function () {
        if (!this.accountFailText) {
          return this.getBasicCustomerInformation.perform();
        } else {
          return null;
        }
      }));
      Ember.defineProperty(this, 'customerDiscrepancies', Ember.computed(this.store.getFullComputedWatcherStringByModelName('customer'), function () {
        return this.getCustomerDiscrepancies.perform();
      }));
    },

    hasContextualButtonClass: Ember.computed('multiStepForm.formOriginalModels.customerSystemInformation.model.{centralStationId,accountNum}', function () {
      if (this.multiStepForm.formOriginalModels.customerSystemInformation.model.accountNum && !this.multiStepForm.formOriginalModels.customerSystemInformation.model.centralStationId) {
        return 'has-contextual-button';
      } else {
        return '';
      }
    }),
    hasDropdownClickActionsClass: Ember.computed('multiStepForm.formOriginalModels.customerSystemInformation.model.accountNum', function () {
      if (this.multiStepForm.formOriginalModels.customerSystemInformation.model.accountNum) {
        return 'drop-down-click-actions';
      } else {
        return '';
      }
    }),
    shellAccountAdditionalClickActions: Ember.computed('multiStepForm.formOriginalModels.customerSystemInformation.model.accountNum', function () {
      if (this.multiStepForm.formOriginalModels.customerSystemInformation.model.accountNum) {
        return [{
          label: this.intl.t('pages.customers.system.link_account'),
          action: () => {
            return this.checkCustomerLinkEligibility.perform();
          },
          classNamesString: ''
        }];
      } else {
        return null;
      }
    }),
    confirmLinkAccount: (0, _emberConcurrency.task)(function* (mcTypeName, monitoringCompanyType, buttonPromise) {
      try {
        yield this.centralStationService.postData({
          model: this.customer,
          method: "".concat(mcTypeName, "__linkAccountToSecurityTraxAccount"),
          payload: {
            confirm_link_account: true
          }
        });
        buttonPromise.then(() => {
          this.store.findRecord('customerSystemInformation', this.get('customer.id')).then(systemInfo => {
            this.set('formOpen', false);
            systemInfo.reload();
            this.router.transitionTo('customers.information.manage-' + this.subrouteMapping.monitorMapping.findBy('id', monitoringCompanyType.get('id')).name);
            this.stNotificationManager.addSuccessKey('common.notifications.save_successful', "".concat(mcTypeName, "Modal"));
          });
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'linkAccountModal'
        });
        throw err;
      }
    })
  });

  _exports.default = _default;
});