define("securitytrax-ember-ui/my-account/time-off/route", ["exports", "securitytrax-ember-ui/my-account/base-route", "securitytrax-ember-ui/mixins/route-pageview-logging", "ember-concurrency"], function (_exports, _baseRoute, _routePageviewLogging, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_routePageviewLogging.default, {
    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform();
    },

    model(params) {
      return this.getModelData.perform(params);
    },

    getModelData: (0, _emberConcurrency.task)(function* (params) {
      var loggedInUser = yield this.sessionData.getUser();
      var calendarUser = this.store.findRecord('calendarUser', loggedInUser.get('id'));
      var isSalesRep = yield loggedInUser.isSalesRep;
      var isTech = yield loggedInUser.isTech;
      this.generalPermissionsTask.perform(isSalesRep || isTech, this.intl.t('common.notifications.not_sales_or_tech'));
      return Ember.RSVP.hash({
        user: loggedInUser,
        calendarUser,
        calendarEventTypes: this.store.findAll('calendarEventType'),
        params
      });
    })
  });

  _exports.default = _default;
});