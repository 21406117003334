define("securitytrax-ember-ui/components/nav/top-nav/company-menu-wrapper/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    screen: Ember.inject.service(),
    tagName: '',
    expandedObserver: Ember.observer('mainExpanded', function () {
      this.setPositionAndClickOutside.perform();
    }),
    setPositionAndClickOutside: (0, _emberConcurrency.task)(function* () {
      var screen = this.screen;
      var isDesktop = yield screen.get('isDesktop');
      var mainContentArea = document.getElementById('main-content-area');

      if (this.mainExpanded === true) {
        //make sure all click outsides will just close nav instead of performing whatever is clicked
        if (mainContentArea) {
          mainContentArea.style.pointerEvents = 'none';
        } //make sure the mobile nav menu is at the top when opening


        if (!isDesktop) {
          var dropdown = document.getElementById('company-dropdown');

          if (dropdown) {
            dropdown.scrollTop = 0;
          }
        }
      } else {
        if (mainContentArea) {
          mainContentArea.style.pointerEvents = 'inherit';
        }
      }
    }),
    actions: {
      closeNavs() {
        this.set('mainExpanded', false);
      }

    }
  });

  _exports.default = _default;
});