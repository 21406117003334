define("securitytrax-ember-ui/demo/lists/controller", ["exports", "securitytrax-ember-ui/mixins/sortable-list-mixin"], function (_exports, _sortableListMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller.extend(_sortableListMixin.default, {
    actionsMenuIsOpen: false,
    dynamicMenuItems: undefined,
    openMenuId: null,

    init() {
      this._super(...arguments);

      Ember.setProperties(this, {
        columns: [{
          propertyName: 'lfname',
          title: 'Name',
          sortName: 'lname'
        }, {
          propertyName: 'citystate',
          title: 'Address'
        }, {
          propertyName: 'primaryPhone',
          title: 'Phone'
        }]
      });
    },

    actions: {
      changeRoute(route, item) {
        this.transitionToRoute(route, item.id);
      },

      editAction: function (data) {
        alert('Clicked edit icon for ' + data.get('flname'));
      },
      gearAction: function (data) {
        alert('Clicked gear icon for ' + data.get('flname'));
      },

      checkboxAction(row) {
        alert('Checked ' + row.get('flname'));
      },

      toggleMenu(row) {
        var menuItemsArray = [{
          label: 'Do A Cool Thing',
          action: row => {
            alert('You did a cool thing with ' + row.get('fname'));
          }
        }, {
          label: 'Do Something Else Cool',
          action: () => {
            alert('That was pretty fun.');
          }
        }, {
          label: 'Do Something Lame',
          action: () => {
            alert('That was soooo boring.');
          }
        }, {
          label: 'This one is Illegal',
          action: () => {
            alert('Yeah, you\'re gonna regret that :(');
          }
        }];
        this.set('openMenuId', row.get('id'));
        this.set('dynamicMenuItems', menuItemsArray);
        this.toggleProperty('actionsMenuIsOpen');
      },

      openModal(modalDialogPath, additionalData, listModel, relationalId, editMode, model) {
        var formData = {
          model: model,
          permissions: null,
          relationalId: relationalId,
          componentLocation: 'modals.edit-information',
          additionalData: additionalData,
          listModel: listModel,
          disableFields: !editMode
        }; // Send show modal action.

        this.send('showModal', {
          type: "forms.".concat(modalDialogPath),
          formData
        });
      }

    }
  });
  var _default = Controller;
  _exports.default = _default;
});