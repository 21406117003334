define("securitytrax-ember-ui/utils/datetime", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getDateTimeForLastDay = getDateTimeForLastDay;

  /**
   * Utility helper module for dealing with date/time fields.
   * @module
   */

  /**
   * Returns the date/time formatted such that if the date/time is within the last 24 hours the returned value will be time only,
   * versus values older than 24 hours, which return the formatted date and time.
   *
   * @param {Date} date The date/time to format.
   * @param {String} [dateFormat=M/D/YYYY] An optional format for the date component.
   * @param {String} [timeFormat=h:mm a] An optional format for the time component.
   *
   * @returns {String} The formatted date/time.
   */
  function getDateTimeForLastDay(date, dateFormat = 'M/D/YYYY', timeFormat = 'h:mm a') {
    // Get moment object for date/time and the time formatted string.
    var mDate = (0, _moment.default)(date),
        dateTime = mDate.format(timeFormat); // Was the date before 24 hours ago?

    if (mDate.isBefore((0, _moment.default)().subtract(1, 'days'))) {
      // Add date to time.
      return "".concat(mDate.format(dateFormat), " ").concat(dateTime);
    }

    return dateTime;
  }
});