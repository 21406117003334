define("securitytrax-ember-ui/services/st-local-storage-adapter", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class StLocalStorageAdapter extends Ember.Service {
    constructor() {
      super(...arguments);
    }

    set(innerKey, value) {
      _lodash.default.set(this, innerKey, value);

      localStorage.setItem(this.key, JSON.stringify(this.data));
    }

  }

  _exports.default = StLocalStorageAdapter;
});