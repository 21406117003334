define("securitytrax-ember-ui/services/st-multicall-service", ["exports", "ember-concurrency", "@ember-data/adapter/error", "lodash", "securitytrax-ember-ui/config/environment"], function (_exports, _emberConcurrency, _error, _lodash, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    stAjax: Ember.inject.service(),
    multicallDataObject: undefined,

    init() {
      this._super(...arguments);

      this.multicallDataObject = {};
    },

    normalizeResponse(data) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var errors = data.body && data.body.errors ? data.body.errors : [];

        if (data.body && data.body.message) {
          errors.push({
            detail: data.body.message
          });
        }

        if (errors && errors.length > 0) {
          var status = data.status.split(' ')[1];
          var error;

          try {
            var detailedMessage = 'The adapter rejected the commit because it was invalid';

            switch (status) {
              case 401:
                return new _error.UnauthorizedError(data.body.errors, detailedMessage);

              case 403:
                return new _error.ForbiddenError(data.body.errors, detailedMessage);

              case 404:
                return new _error.NotFoundError(data.body.errors, detailedMessage);

              case 409:
                return new _error.ConflictError(data.body.errors, detailedMessage);

              default:
                if (status >= 500) {
                  return new _error.ServerError(data.body.errors, detailedMessage);
                }

            }

            error = new _error.default(data.body.errors, detailedMessage);
          } catch (e) {
            error = e;
          }

          reject(error);
        } else {
          if (data.cachedData && data.cachedData.length > 0) {
            resolve(data.cachedData);
          } else {
            resolve(data.body);
          }
        }
      });
    },

    setupMulticall(multicallOptions, callback) {
      return this.setupMulticallEncapsulatedTask.unlinked().perform(this, multicallOptions, callback);
    },

    setupMulticallEncapsulatedTask: (0, _emberConcurrency.task)({
      *perform(context, multicallOptions, callback) {
        var multicallOptionsMerged = _lodash.default.merge({
          timeout: 400000
        }, multicallOptions);

        var setupMulticallTimeout = setTimeout(() => {
          throw new Error("setupMulticall timed out because not all the expected requests were initiated");
        }, 5000); // this sets a timeout for if not all the expected requests were initiated
        /////////////////////////////////////////////////////////////////////////////
        // setup all the data needed by all the calls

        if (!context.multicallDataObject[multicallOptionsMerged.multicallKey] || multicallOptionsMerged.forceNew) {
          Ember.set(context.multicallDataObject, multicallOptionsMerged.multicallKey, {
            requestDataObjects: {},
            initiated: true,
            readyToRequest: false,
            startedRequest: false,
            finishedRequest: false,
            multicallSubkeys: multicallOptionsMerged.multicallSubkeys
          });
          context.triggerMulticallEvent(multicallOptionsMerged.multicallKey, 'initiated');
        }

        var multicallSubkeysKeys = Object.keys(multicallOptionsMerged.multicallSubkeys);

        for (var index = 0; index < multicallSubkeysKeys.length; index++) {
          var subkey = multicallSubkeysKeys[index];
          var requestPromiseResolve = void 0;
          var requestPromiseReject = void 0;
          var requestPromise = new Ember.RSVP.Promise((resolve, reject) => {
            requestPromiseResolve = resolve;
            requestPromiseReject = reject;
          });
          var theObjectData = {
            requestPromise: requestPromise,
            requestPromiseResolve: requestPromiseResolve,
            requestPromiseReject: requestPromiseReject
          };
          Ember.set(context.multicallDataObject[multicallOptionsMerged.multicallKey]['requestDataObjects'], subkey, theObjectData);
        } // start the multicall callback that will actually start the requests


        callback();
        yield context.waitForMulticallByKeyAndEventname(multicallOptionsMerged.multicallKey, 'readyToRequest', 'setupMulticall'); // don't timeout based on waiting for readyToRequest

        clearTimeout(setupMulticallTimeout); ////////////////////////////////////////////////////////////
        // only do the actual multicall when not in mirage
        // if in mirage the data is just placed in in registerRequest after the regular ajax calls happen

        if (_environment.default['ember-cli-mirage']['enabled'] === false) {
          /////////////////////////////////////
          // actually do the multicall
          if (context.multicallDataObject[multicallOptionsMerged.multicallKey]) {
            var requestDataObjectsToRequest = [];

            var _requestDataObjectsArray = Object.keys(context.multicallDataObject[multicallOptionsMerged.multicallKey]['requestDataObjects']);

            for (var _index = 0; _index < _requestDataObjectsArray.length; _index++) {
              var multicallSubkey = _requestDataObjectsArray[_index];
              var requestData = context.multicallDataObject[multicallOptionsMerged.multicallKey]['requestDataObjects'][multicallSubkey];
              var encodedURI = requestData.multicallData.url ? context.stAjax.getEncodedRelativeUrlWithParams(requestData.multicallData.url, requestData.multicallData.queryParams) : '';

              if (requestData.cachedData && requestData.cachedData.length > 0) {
                // don't need to add request that are cached to the multicall so just resolve with the data
                var normalizedData = context.normalizeResponse({
                  cachedData: requestData.cachedData
                });
                requestData.requestPromiseResolve(normalizedData);
              } else {
                var headersAsStrings = context.stAjax.defaultHeadersStrings();
                var multicallResponseUriHeaderString = context.stAjax.getSecurityTraxResponseUriHeaderString();
                headersAsStrings.push(multicallResponseUriHeaderString);
                requestDataObjectsToRequest.push({
                  'request': "".concat(requestData.multicallData.verb, "    ").concat(encodedURI, "    HTTP/1.1"),
                  'headers': headersAsStrings,
                  'body': requestData.multicallData.body
                });
              }
            }

            context.triggerMulticallEvent(multicallOptionsMerged.multicallKey, 'startedRequest');
            var _returnData = [];

            if (requestDataObjectsToRequest.length > 0) {
              var defaultHeaders = context.stAjax.defaultHeaders();
              var multicallResponseUri = context.stAjax.getSecurityTraxResponseUri();
              defaultHeaders['X-SecurityTrax-Response-Uri'] = multicallResponseUri;

              try {
                _returnData = yield context.stAjax.request(context.stAjax.getUrl('/multicall'), {
                  method: 'POST',
                  data: requestDataObjectsToRequest,
                  timeout: multicallOptionsMerged.timeout,
                  headers: defaultHeaders
                });
              } catch (e) {
                throw new Error("The multicall request failed: ".concat(e));
              }
            }

            var returnDataIndex = 0;
            var requestDataObjectsArray2 = Object.keys(context.multicallDataObject[multicallOptionsMerged.multicallKey]['requestDataObjects']);

            for (var _index2 = 0; _index2 < requestDataObjectsArray2.length; _index2++) {
              var _multicallSubkey = requestDataObjectsArray2[_index2];
              var _requestData = context.multicallDataObject[multicallOptionsMerged.multicallKey]['requestDataObjects'][_multicallSubkey];

              if (_requestData.cachedData && _requestData.cachedData.length > 0) {
                continue; // skip requests that already had cached data
              }

              var _normalizedData = context.normalizeResponse(_returnData[returnDataIndex]);

              _requestData.requestPromiseResolve(_normalizedData);

              returnDataIndex++;
            }
          }
        }

        yield context.waitForMulticallByKeyAndEventname(multicallOptionsMerged.multicallKey, 'finishedRequest', 'setupMulticall');
        var returnData = {};
        var requestDataObjectsArray = Object.keys(context.multicallDataObject[multicallOptionsMerged.multicallKey]['requestDataObjects']);

        for (var _index3 = 0; _index3 < requestDataObjectsArray.length; _index3++) {
          var _multicallSubkey2 = requestDataObjectsArray[_index3];
          returnData[_multicallSubkey2] = context.grabMulticallDataByKeyAndSubkey(multicallOptionsMerged.multicallKey, _multicallSubkey2);
        }

        var yieldedReturnData = yield (0, _emberConcurrency.hash)(returnData);
        return yieldedReturnData;
      }

    }),

    registerRequest(multicallOptions, actualResponse) {
      if (!this.multicallDataObject[multicallOptions.multicallKey]['multicallSubkeys'][multicallOptions.multicallSubkey]) {
        throw new Error("multicallSubkey of \"".concat(multicallOptions.multicallSubkey, "\" is not inside multicallSubkeys"));
      }

      Ember.set(this.multicallDataObject[multicallOptions.multicallKey]['requestDataObjects'][multicallOptions.multicallSubkey], 'actualResponse', actualResponse); ////////////////////////////////////////////////////////////////
      // if we are in mirage means we didn't do the actual multicall
      // and need to place the data in from the ajax calls

      if (_environment.default['ember-cli-mirage']['enabled'] === true) {
        Ember.set(this.multicallDataObject[multicallOptions.multicallKey]['requestDataObjects'][multicallOptions.multicallSubkey], 'requestPromise', actualResponse);
      }

      return this.registerRequestTask.unlinked().perform(multicallOptions, actualResponse);
    },

    registerRequestTask: (0, _emberConcurrency.task)(function* (multicallOptions, actualResponse) {
      var weAreFinished = true;
      var allRequests = [];
      var requestDataObjectsArray = Object.keys(this.multicallDataObject[multicallOptions.multicallKey]['requestDataObjects']);

      for (var index = 0; index < requestDataObjectsArray.length; index++) {
        var multicallSubkey = requestDataObjectsArray[index];
        var requestData = this.multicallDataObject[multicallOptions.multicallKey]['requestDataObjects'][multicallSubkey];

        if (!requestData['actualResponse']) {
          weAreFinished = false;
        } else {
          allRequests.push(requestData['actualResponse']);
        }
      }

      if (weAreFinished) {
        yield (0, _emberConcurrency.all)(allRequests); ////////////////////////////////////////////////////////////////
        // if we are in mirage means we didn't do the actual multicall
        // and need to just move past the readyToRequest and startedRequest events

        if (_environment.default['ember-cli-mirage']['enabled'] === true) {
          // need to trigger readyToRequest because it won't even happen in mirage because getMulticallData is never called
          this.triggerMulticallEvent(multicallOptions.multicallKey, 'readyToRequest');
          this.triggerMulticallEvent(multicallOptions.multicallKey, 'startedRequest');
        } // means we are completely done


        this.triggerMulticallEvent(multicallOptions.multicallKey, 'finishedRequest');
      }

      return actualResponse;
    }),

    getMulticallData(multicallOptions, multicallData, cachedData = null) {
      return this.getMulticallDataTask.unlinked().perform(multicallOptions, multicallData, cachedData);
    },

    getMulticallDataTask: (0, _emberConcurrency.task)(function* (multicallOptions, multicallData, cachedData = null) {
      var multicallOptionsMerged = _lodash.default.merge({
        timeout: 400000
      }, multicallOptions);

      var setupMulticallTimeout = setTimeout(() => {
        throw new Error("getMulticallData timed out for ".concat(multicallData.url, " in multicall"));
      }, multicallOptionsMerged.timeout); // this sets a timeout for if this task takes too long (because multicall failed or something else)

      if (_environment.default['ember-cli-mirage']['enabled'] === true) {
        throw new Error('getMulticallData was called in mirage'); // getMulticallData should never be called in mirage because we bipass multicall in mirage
      }

      if (!this.multicallDataObject[multicallOptionsMerged.multicallKey]['multicallSubkeys'][multicallOptionsMerged.multicallSubkey]) {
        throw new Error("multicallSubkey of \"".concat(multicallOptionsMerged.multicallSubkey, "\" is not inside multicallSubkeys"));
      }

      Ember.set(this.multicallDataObject[multicallOptionsMerged.multicallKey]['requestDataObjects'][multicallOptionsMerged.multicallSubkey], 'multicallData', multicallData);

      if (cachedData && cachedData.length > 0) {
        // means that this request won't actually happen in the multicall because it was already cached
        Ember.set(this.multicallDataObject[multicallOptionsMerged.multicallKey]['requestDataObjects'][multicallOptionsMerged.multicallSubkey], 'cachedData', cachedData);
      }

      var weAreFinished = true;
      var requestDataObjectsArray = Object.keys(this.multicallDataObject[multicallOptionsMerged.multicallKey]['requestDataObjects']);

      for (var index = 0; index < requestDataObjectsArray.length; index++) {
        var multicallSubkey = requestDataObjectsArray[index];
        var requestData = this.multicallDataObject[multicallOptionsMerged.multicallKey]['requestDataObjects'][multicallSubkey];

        if (!requestData['multicallData']) {
          weAreFinished = false;
        }
      }

      if (weAreFinished) {
        // means we are ready to run the actual multicall
        this.triggerMulticallEvent(multicallOptionsMerged.multicallKey, 'readyToRequest');
      }

      var multicallDataResults;

      try {
        multicallDataResults = yield this.multicallDataObject[multicallOptionsMerged.multicallKey]['requestDataObjects'][multicallOptionsMerged.multicallSubkey]['requestPromise'];
      } catch (e) {
        throw new Error("request to ".concat(multicallData.url, " failed in getMulticallData"));
      }

      clearTimeout(setupMulticallTimeout);
      return multicallDataResults;
    }),

    grabMulticallDataByKeyAndSubkey(multicallKey, multicallSubkey, eventname = false) {
      return this.grabMulticallDataByKeyAndSubkeyTask.unlinked().perform(multicallKey, multicallSubkey, eventname);
    },

    grabMulticallDataByKeyAndSubkeyTask: (0, _emberConcurrency.task)(function* (multicallKey, multicallSubkey, eventname = false) {
      if (eventname) {
        yield this.waitForMulticallByKeyAndEventname(multicallKey, eventname, "grabMulticallDataByKeyAndSubkey_".concat(multicallSubkey));
      }

      var requestPromise = _lodash.default.get(this.multicallDataObject, "".concat(multicallKey, ".requestDataObjects[").concat(multicallSubkey, "].requestPromise"), false);

      var actualResponse = _lodash.default.get(this.multicallDataObject, "".concat(multicallKey, ".requestDataObjects[").concat(multicallSubkey, "].actualResponse"), false);

      if (requestPromise) {
        return yield (0, _emberConcurrency.hash)({
          requestPromise: requestPromise,
          actualResponse: actualResponse
        });
      }

      return false;
    }),

    buildMulticallSubkeys(array, subkey) {
      var multicallSubkeys = {};

      for (var index = 0; index < array.length; index++) {
        var multicallSubkey = "".concat(index).concat(subkey);
        multicallSubkeys[multicallSubkey] = true;
      }

      return multicallSubkeys;
    },

    waitForMulticallByKeyAndEventname(multicallKey, eventname, reference = '') {
      return this.waitForMulticallByKeyAndEventnameTask.unlinked().perform(multicallKey, eventname, reference);
    },

    waitForMulticallByKeyAndEventnameTask: (0, _emberConcurrency.task)(function* (multicallKey, eventname, reference = '') {
      reference; // just here for debugging

      yield (0, _emberConcurrency.waitForProperty)(this.multicallDataObject, "".concat(multicallKey, ".initiated"), true);

      var keyExists = _lodash.default.get(this.multicallDataObject, multicallKey, {});

      var eventNameVarTrue = _lodash.default.get(keyExists, eventname, false);

      if (eventNameVarTrue) {
        return;
      }

      if (keyExists != {}) {
        yield (0, _emberConcurrency.waitForProperty)(this.multicallDataObject[multicallKey], eventname, true);
      } else {
        yield (0, _emberConcurrency.waitForEvent)(this, "".concat(multicallOptions.multicallKey, "_").concat(eventname));
      }
    }),

    triggerMulticallEvent(multicallKey, eventname) {
      Ember.set(this.multicallDataObject[multicallKey], eventname, true);
      this.trigger("".concat(multicallKey, "_").concat(eventname));
    },

    clearMulticallData(multicallKey) {
      if (this.multicallDataObject[multicallKey]) {
        this.multicallDataObject[multicallKey] = undefined;
      }
    }

  });

  _exports.default = _default;
});