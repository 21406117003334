define("securitytrax-ember-ui/mixed-in-classes/table-column-sort", ["exports", "@glimmer/component", "lodash", "moment"], function (_exports, _component, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  //import { sortCompareFunction } from 'securitytrax-ember-ui/utils/sort-compare';

  /**
   * Shared functionality to sort a table by a column
   *
   * @mixin TableColumnSort
   * @extends Ember.Mixin
   */
  var TableColumnSort = (_dec = Ember.inject.service('store'), _dec2 = Ember.inject.service('intl'), _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, (_class = (_temp = class TableColumnSort extends _component.default {
    constructor(classArguments, templateArguments, sortableColumns) {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "stSortCurrentSortColumn", _descriptor3, this);

      _initializerDefineProperty(this, "stSortAscending", _descriptor4, this);

      _initializerDefineProperty(this, "stSortString", _descriptor5, this);

      this.stSortableColumnOptions = [];

      if (sortableColumns && sortableColumns.length > 0) {
        sortableColumns.forEach(column => {
          this.stSortableColumnOptions.push({
            name: column.name + ' ' + this.intl.t('common.sorting.ascending'),
            value: column.value,
            chevronDirection: 'up'
          });
          this.stSortableColumnOptions.push({
            name: column.name + ' ' + this.intl.t('common.sorting.descending'),
            value: '-' + column.value,
            chevronDirection: 'down'
          });
        });
        this.stSortString = sortableColumns[0].value;
        this.stSortCurrentSortColumn = sortableColumns[0].value;
        this.stSortAscending = true;
      }
    } //reset paginated lists to page 1


    _resetPagination() {
      Ember.set(this, 'page', 1);
      Ember.set(this, 'limit', this.limit);
    }

    _setArrowIndicators(column, ascending, listLoadedVar = null) {
      if (listLoadedVar) {
        this.listLoadedVar = false;
      }

      this.stSortCurrentSortColumn = column;
      this.stSortAscending = ascending;
    } // sorting for a paginated list


    sortPaginatedList(column, ascending, listLoadedVar = null) {
      this._setArrowIndicators(column, ascending, listLoadedVar);

      var asc = ascending !== null && ascending === true ? '' : '-';
      this.stSortString = asc + column; // for use with a query

      this._resetPagination();

      return true;
    } // sorting for a NON paginated list


    sortList(column, ascending, listLoadedVar = null) {
      this._setArrowIndicators(column, ascending, listLoadedVar);

      var asc = ascending !== null && ascending === true ? '' : '-';
      this.stSortString = asc + column;
      return true;
    }

    sortEmberDataCollection(collection, column, ascending = null) {
      var sortedList = [];
      var list = collection.toArray();

      if (ascending === true) {
        sortedList = _lodash.default.orderBy(list, [function (item) {
          if (_moment.default.isMoment(item.get(column))) {
            return (0, _moment.default)(item.get(column)).format('YYYYMMDD');
          }

          if (!isNaN(Number(item.get(column)))) {
            return Number(item.get(column));
          } else {
            return item.get(column);
          }
        }], ['asc']);
      } else if (ascending === false) {
        sortedList = _lodash.default.orderBy(list, [function (item) {
          if (!isNaN(Number(item.get(column)))) {
            return Number(item.get(column));
          } else {
            return item.get(column);
          }
        }], ['desc']);
      }

      return sortedList;
    }

    sortMobileList(sortObject) {
      this.stSortAscending = !sortObject.value.includes('-');
      this.stSortCurrentSortColumn = sortObject.value.replace('-', '');
    }

    sortMobilePaginatedList(sortString, listLoadedVar = null) {
      if (listLoadedVar) {
        this.listLoadedVar = false;
      }

      this.stSortString = sortString;

      this._resetPagination();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stSortCurrentSortColumn", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stSortAscending", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "stSortString", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  })), _class));
  _exports.default = TableColumnSort;
});