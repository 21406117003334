define("securitytrax-ember-ui/services/sniffer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var isString = function (value) {
    return typeof value === 'string';
  };

  var lowercase = function (string) {
    return isString(string) ? string.toLowerCase() : string;
  };

  var toInt = function (str) {
    return parseInt(str, 10);
  };

  var _default = Ember.Service.extend({
    vendorPrefix: '',
    transitions: false,
    animations: false,
    _document: null,
    _window: null,
    android: Ember.computed('', function () {
      return toInt((/android (\d+)/.exec(lowercase((this._window.navigator || {}).userAgent)) || [])[1]);
    }),

    init() {
      this._super(...arguments);

      if (typeof FastBoot !== 'undefined') {
        return;
      }

      var _document = document;
      var _window = window;
      this.setProperties({
        _document,
        _window
      });
      var bodyStyle = _document.body && _document.body.style;
      var vendorPrefix;
      var vendorRegex = /^(Moz|webkit|ms)(?=[A-Z])/;
      var transitions = false;
      var animations = false;
      var match;

      if (bodyStyle) {
        for (var prop in bodyStyle) {
          match = vendorRegex.exec(prop);

          if (match) {
            vendorPrefix = match[0];
            vendorPrefix = vendorPrefix.substr(0, 1).toUpperCase() + vendorPrefix.substr(1);
            break;
          }
        }

        if (!vendorPrefix) {
          vendorPrefix = 'WebkitOpacity' in bodyStyle && 'webkit';
        }

        transitions = !!('transition' in bodyStyle || "".concat(vendorPrefix, "Transition") in bodyStyle);
        animations = !!('animation' in bodyStyle || "".concat(vendorPrefix, "Animation") in bodyStyle);

        if (this.android && (!transitions || !animations)) {
          transitions = isString(bodyStyle.webkitTransition);
          animations = isString(bodyStyle.webkitAnimation);
        }
      }

      this.set('transitions', transitions);
      this.set('animations', animations);
      this.set('vendorPrefix', vendorPrefix);
    }

  });

  _exports.default = _default;
});