define("securitytrax-ember-ui/customers/appointments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QTuHHIZF",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"customer\",\"users\",\"model\"],[[35,0,[\"customer\"]],[35,0,[\"users\"]],[35,0]]]]],[2,\"\\n\\n\"],[8,\"customer/user-defined-fields\",[],[[\"@customer\",\"@model\",\"@udfContext\"],[[34,0,[\"customer\"]],[34,0],\"customers_appointments\"]],null],[2,\"\\n\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"model\",\"pages/customers/customer-appointments\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/customers/appointments/template.hbs"
    }
  });

  _exports.default = _default;
});