define("securitytrax-ember-ui/mixins/apply-order-template", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    grants: Ember.inject.service(),
    stAjax: Ember.inject.service(),
    store: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    copiedModelPrefix: Ember.computed(function () {
      return this.get('multiStepForm.formCopiedModels.customerOrderInformation') ? 'multiStepForm.formCopiedModels.customerOrderInformation.model' : 'multiStepForm.formCopiedModels.customer.model.customerOrderInformation';
    }),
    applyOrderTemplateMixinTask: (0, _emberConcurrency.task)(function* (customerOrderInformationId, additionalEquipment = []) {
      var selectedPackages = Ember.A();
      var soldById = this.get('equipmentSalesPerson.id');
      var meta = {};
      var soldByObject = null;

      if (this.selectedTemplatePackageId) {
        if (parseInt(soldById) > 0) {
          soldByObject = Ember.Object.create({
            id: soldById,
            type: 'users'
          });
        }

        selectedPackages.pushObject({
          'id': this.selectedTemplatePackageId,
          'type': 'packages'
        });
      }

      if (this.invoiceButtonId !== null && this.invoiceButtonId >= 0) {
        meta = {
          'invoice_type': this.buttonChoices[this.invoiceButtonId].toLowerCase().replace(/ /g, '_')
        };
      }

      var postObject = {
        verb: 'POST',
        dataType: 'json',
        data: {
          data: {
            relationships: {
              order_template: {
                id: this.selectedTemplateId,
                type: 'order_templates'
              },
              sold_by: soldByObject,
              packages: selectedPackages,
              //array of packages
              additional_equipment: additionalEquipment
            },
            type: 'customer_order_information_action',
            attributes: {
              action: 'customer_order_information_action'
            },
            meta: meta
          }
        }
      };

      if (this.get('selectedMmr.id')) {
        postObject.data.data.relationships.mmr = {
          id: this.get('selectedMmr.id'),
          type: 'mmrs'
        };
      }

      var customerOrderInfo = yield this.store.findRecord('customerOrderInformation', customerOrderInformationId);

      try {
        yield customerOrderInfo.invokeAction('apply_order_template', postObject);
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
        throw err;
      }
    }),
    buttonChoices: Ember.computed('selectedTemplateId', function () {
      var selectedTemplate = this.get('selectedTemplate.firstObject');
      var choices = [];
      var grants = this.grants;
      var intl = this.intl;

      if (selectedTemplate) {
        if (selectedTemplate.get('allowInvoice') && grants.getIndividualGrant('customer_invoices.create')) {
          choices.push(intl.t('pages.customers.order.generic_invoice_titles.invoice'));
        }

        if (selectedTemplate.get('allowQuote') && grants.getIndividualGrant('customer_quotes.create')) {
          choices.push(intl.t('pages.customers.order.generic_invoice_titles.quote'));
        } // if (selectedTemplate.get('allowWorkOrder')) {
        //     choices.push('Work Order');
        // }

      }

      return choices;
    }),
    selectedTemplateId: Ember.computed('orderTemplateChoices', function () {
      this.orderTemplateChoices; //ensure order template choices on load

      return this.get('orderTemplates.firstObject.id');
    }),
    equipmentSalesPerson: null,
    salesPerson: Ember.computed(function () {
      return this.get(this.copiedModelPrefix + '.repUser.id');
    }),
    templateIdObserver: Ember.observer('selectedTemplateId', function () {
      this.mmrsTask.perform();
    }),
    mmrsTask: (0, _emberConcurrency.task)(function* () {
      var selectedMmr = null;

      if (this.orderTemplates) {
        var orderTemplate = this.orderTemplates.findBy('id', this.selectedTemplateId);

        if (orderTemplate) {
          var mmrs = yield orderTemplate.get('defaultMmrs');

          if (typeof mmrs !== 'undefined') {
            this.set('templateMmrs', mmrs.sortBy('amountNumeric'));
            this.set('showMmrSelectList', mmrs.length > 1);

            if (mmrs.length === 1) {
              selectedMmr = mmrs.get('firstObject');
            }
          }
        } else {
          this.set('showMmrSelectList', false);
        }
      }

      this.set('selectedMmr', selectedMmr);
    }).on('init'),
    showMmrSelectList: undefined,
    selectedMmrId: undefined,
    templateMmrs: undefined,
    selectedTemplatePackageId: null,
    invoiceButtonId: null,
    selectedTemplate: Ember.computed('selectedTemplateId', function () {
      this.set('selectedTemplatePackageId', null);
      return this.orderTemplates ? this.orderTemplates.filterBy('id', this.selectedTemplateId) : null;
    }),

    /**
     * setup the package selection for the Order Templates
     */
    orderTemplateChoices: Ember.computed('orderTemplates', function () {
      return this.orderTemplateChoicesTask.perform();
    }),
    orderTemplateChoicesTask: (0, _emberConcurrency.task)(function* () {
      var choices = Ember.A([]);
      var templates = yield this.orderTemplates;

      if (templates && templates.get('length') > 0) {
        for (var i = 0; i < templates.get('length'); i++) {
          var template = templates.objectAt(i);

          if (template.get('active')) {
            var promisesArray = [template.get('defaultMmrs'), template.get('defaultActivationFee'), template.get('defaultContractTerm'), template.get('defaultCustomerWarranty'), template.get('defaultMonitoringCompany'), template.get('defaultMonitoringPlan'), template.get('defaultInteractiveServicesProvider')];
            yield Ember.RSVP.all(promisesArray);
            var mmrs = [];

            if (template.get('defaultMmrs')) {
              mmrs = template.get('defaultMmrs').sortBy('amountNumeric').map(mmr => {
                return mmr.get('amountDisplay');
              });
            }

            choices.pushObject(Ember.Object.create({
              packageName: template.get('name'),
              packageId: template.get('id'),
              packageContent: [{
                label: this.intl.t('pages.customers.order.mmr.label'),
                description: mmrs.join(', ')
              }, {
                label: this.intl.t('pages.customers.order.activationFee.label'),
                description: template.get('defaultActivationFee.amountDisplay')
              }, {
                label: this.intl.t('pages.customers.order.contractTerm.label'),
                description: template.get('defaultContractTerm.description')
              }, {
                label: this.intl.t('pages.customers.order.customerWarranty.label'),
                description: template.get('defaultCustomerWarranty.displayValue')
              }, {
                label: this.intl.t('pages.customers.order.monitoringCompany.label'),
                description: template.get('defaultMonitoringCompany.name')
              }, {
                label: this.intl.t('pages.customers.order.monitoringPlan.label'),
                description: template.get('defaultMonitoringPlan.name')
              }, {
                label: this.intl.t('pages.customers.order.interactiveServicesProvider.label'),
                description: template.get('defaultInteractiveServicesProvider.name')
              }]
            }));
          }
        }
      }

      return choices;
    }),

    /**
     * setup the choices for the packages which depend on selected order template
     * this also sets up copied models for the order information page, if they save will sync
     */
    packageChoices: Ember.computed('selectedTemplate', function () {
      return this.packageChoicesTask.perform();
    }),
    packageChoicesTask: (0, _emberConcurrency.task)(function* () {
      var packageToEquipmentCount = Ember.A([]);
      var selectedTemplate = this.get('selectedTemplate.firstObject');

      if (selectedTemplate) {
        //TODO: this shouldn't be necessary. its only necessary because Mirage isn't reloading data
        //also see mirage application serializer: //alwaysIncludeLinkageData: true, reloads data, but messes up many-to-many such as packages
        var stepsArray = ['activationFee', 'contractTerm', 'customerWarranty', 'monitoringCompany', 'monitoringPlan', 'interactiveServicesProvider'];
        stepsArray.forEach(name => {
          this.set(this.copiedModelPrefix + '.' + name, selectedTemplate.get('default' + name.capitalize()));
        }); //end TODO

        var packages = yield selectedTemplate.get('defaultPackages');

        var _loop = function* (i) {
          var currPackage = packages.objectAt(i);
          var pkEquipmentList = yield currPackage.get('packageEquipment');
          var promisesArray = [];
          pkEquipmentList.forEach(currPkEquipment => {
            promisesArray.push(currPkEquipment.get('equipment'));
          });
          yield Ember.RSVP.all(promisesArray);
          var currPkEquipmentList = {};
          pkEquipmentList.forEach(currPkEquipment => {
            if (!currPkEquipmentList[currPkEquipment.get('equipment.name')]) {
              currPkEquipmentList[currPkEquipment.get('equipment.name')] = currPkEquipment.get('quantity');
            } else {
              currPkEquipmentList[currPkEquipment.get('equipment.name')] += currPkEquipment.get('quantity');
            }
          });
          var currPkEquipmentListEmberArr = Object.keys(currPkEquipmentList).map(equipmentName => {
            return Ember.Object.create({
              name: equipmentName,
              quantity: currPkEquipmentList[equipmentName]
            });
          });
          packageToEquipmentCount.pushObject(Ember.Object.create({
            packageName: currPackage.get('name'),
            packageId: currPackage.get('id'),
            packageContent: currPkEquipmentListEmberArr
          }));
        };

        for (var i = 0; i < packages.get('length'); i++) {
          yield* _loop(i);
        }
      }

      return packageToEquipmentCount;
    })
  });

  _exports.default = _default;
});