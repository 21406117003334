define("securitytrax-ember-ui/administration/company-settings/integrations/partner-companies/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/has-page-title", "securitytrax-ember-ui/mixins/route-pageview-logging", "ember-concurrency"], function (_exports, _authenticatedRouteMixin, _hasPageTitle, _routePageviewLogging, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _hasPageTitle.default, _routePageviewLogging.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform();
    },

    model({
      id
    }) {
      return this.store.findRecord('leadCompany', id).catch(() => {
        // Should we notify user this request failed?
        this.transitionTo('/administration/company-settings/integrations/partner-company-list');
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      this.setupControllerTask.perform(controller, model);
    },

    setupControllerTask: (0, _emberConcurrency.task)(function* (controller, model) {
      var leadCompanyPayrollRoleFunctions = yield this.store.findAll('payrollRoleFunction', {
        include: 'payroll_list_method'
      });
      var companyDisplay = model.get('name') + ' (' + this.intl.t('pages.users.id') + ': ' + model.get('id') + ')';
      var breadCrumbs = [{
        name: this.intl.t('pages.administration.integrations.sources_partnerCompanies'),
        last: false,
        previousLink: 'administration.company-settings',
        link: 'administration.company-settings.integrations.partner-company-list'
      }, {
        name: companyDisplay,
        last: true,
        previousLink: 'administration.company-settings.integrations.partner-company-list',
        link: 'administration.company-settings.integrations.partner-companies'
      }];
      var breadCrumbData = {
        title: this.intl.t('pages.administration.company_settings.title'),
        titleLink: 'administration.company-settings'
      };
      controller.set('breadCrumbs', breadCrumbs);
      controller.set('breadCrumbData', breadCrumbData);
      controller.set('leadCompanyPayrollRoleFunctions', leadCompanyPayrollRoleFunctions.filterBy('payrollListMethod.entityType', 'lead_company'));
    }),

    getPageTitle() {
      return this.intl.t('pages.administration.integrations.sources_partnerCompanies');
    },

    getRouteName() {
      return "pages.administration.nav.company-settings";
    }

  });

  _exports.default = _default;
});