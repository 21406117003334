define("securitytrax-ember-ui/users/information/route", ["exports", "securitytrax-ember-ui/users/base-route", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _baseRoute, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_routePageviewLogging.default, {
    tagsService: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['user_admin_settings.view', 'user_preferences.view'], 'one', '/administration/human-resources/user-list');
    },

    model() {
      var store = this.store;

      var {
        user
      } = this._super(...arguments);

      return Ember.RSVP.hash({
        user,
        mobileCarriers: store.findAll('mobileCarrier'),
        tags: this.get('tagsService.getTagsByObjectType').perform('user')
      });
    }

  });

  _exports.default = _default;
});