define("securitytrax-ember-ui/components/multi-step-form/forms/administration/inventory/spillage/line-items/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    inventoryService: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    sessionData: Ember.inject.service('sessionData'),
    grants: Ember.inject.service(),
    pendingEquipmentLineItems: null,
    dataLoaded: false,
    totalItemCount: Ember.computed('pendingEquipmentLineItems.@each.justReceivedQty', function () {
      var count = 0;

      if (this.pendingEquipmentLineItems) {
        this.pendingEquipmentLineItems.forEach(item => {
          if (item.justReceivedQty) {
            count += parseInt(item.justReceivedQty);
          }
        });
      }

      return count;
    }),
    totalPreviouslyReceived: Ember.computed(function () {
      var count = 0;

      if (this.pendingEquipmentLineItems) {
        this.pendingEquipmentLineItems.forEach(item => {
          if (item.receivedQty) {
            count += parseInt(item.receivedQty);
          }
        });
      }

      return count;
    }),
    totalQuantity: Ember.computed(function () {
      var count = 0;

      if (this.pendingEquipmentLineItems) {
        this.pendingEquipmentLineItems.forEach(item => {
          if (item.quantity) {
            count += parseInt(item.quantity);
          }
        });
      }

      return count;
    }),
    totalTotalCost: Ember.computed(function () {
      var count = 0;

      if (this.pendingEquipmentLineItems) {
        this.pendingEquipmentLineItems.forEach(item => {
          if (item.totalCost) {
            count += parseFloat(item.totalCost);
          }
        });
      }

      return count;
    }),
    fulfilledQuantityObserver: Ember.observer('pendingEquipmentLineItems.@each.fulfilledQuantity', function () {
      var pendingEquipmentLineItems = this.pendingEquipmentLineItems;

      if (pendingEquipmentLineItems) {
        pendingEquipmentLineItems.forEach(pendingEquipmentLineItem => {
          pendingEquipmentLineItem.set('difference', pendingEquipmentLineItem.get('quantity') - pendingEquipmentLineItem.get('fulfilledQuantity'));
        });
      }
    }),

    init() {
      this._super(...arguments);

      this.pendingEquipmentLineItems.forEach(item => {
        item.set('justReceivedQty', 0);
        item.set('receivedQty', item.get('fulfilledQuantity'));
        item.set('difference', item.get('quantity') - item.get('fulfilledQuantity'));
        item.set('absoluteValueDifference', Math.abs(item.get('difference')));
        item.set('oldFulfilledQuantity', item.get('fulfilledQuantity'));
        item.set('oldDifference', item.get('quantity') - item.get('fulfilledQuantity'));
        item.set('history', this.grants.getIndividualGrant('equipment_ledgers.computed.company_inventory') ? this.store.query('equipmentLedger', {
          filter: 'target_id = ' + item.get('id') + ' and target_class = 400'
        }) : []);
      });
      this.set('dataLoaded', true);
    },

    updateQuantity(row, direction) {
      if (direction === 'up') {
        var updatedValue = row.get('fulfilledQuantity') + row.get('difference');
        var updatedReceived = row.get('justReceivedQty') + row.get('difference');
        row.set('justReceivedQty', updatedReceived > row.get('quantity') ? row.get('quantity') : updatedReceived);
        row.set('fulfilledQuantity', updatedValue > row.get('quantity') ? row.get('quantity') : updatedValue);
      } else {
        row.set('fulfilledQuantity', row.get('fulfilledQuantity') - row.get('justReceivedQty'));
        row.set('justReceivedQty', 0);
      }
    },

    actions: {
      updateCount(row) {
        if (+row.get('justReceivedQty') > +row.get('oldDifference')) {
          row.set('justReceivedQty', row.get('oldDifference'));
        }

        row.set('fulfilledQuantity', +row.get('oldFulfilledQuantity') + +row.get('justReceivedQty'));
      },

      updateQuantityRow(row, direction) {
        this.updateQuantity(row, direction);
      },

      updateQuantityAll(direction) {
        this.pendingEquipmentLineItems.forEach(pendingEquipmentLineItem => {
          this.updateQuantity(pendingEquipmentLineItem, direction);
        });
      }

    }
  });

  _exports.default = _default;
});