define("securitytrax-ember-ui/models/calendar-user", ["exports", "securitytrax-ember-ui/mixins/user-reusable", "securitytrax-ember-ui/models/base_models/calendar-user"], function (_exports, _userReusable, _calendarUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _calendarUser.default.extend(_userReusable.default, {
    show: false
  });

  _exports.default = _default;
});