define("securitytrax-ember-ui/components/multi-step-form/switchable-inputs/text-input/component", ["exports", "jquery", "securitytrax-ember-ui/mixins/switchable-input", "uuid", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _jquery, _switchableInput, _uuid, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableInput.default, {
    type: 'text',
    autocomplete: 'off',
    autofocus: null,
    autosave: false,
    max: null,
    maxlength: null,
    min: null,
    tabindex: null,
    pattern: null,
    formnovalidate: '',
    height: null,
    inputClass: '',
    inputmode: null,
    multiple: null,
    step: null,
    selectionDirection: null,
    spellcheck: false,
    list: null,
    textInputUuid: undefined,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        textInputUuid: (0, _uuid.v4)()
      });

      if (this.isFilter) {
        var filterData = this.stFiltersService.registerFilter(this.stFilterGroupKey, this.stFilterKey, ['textValue']);

        if (filterData['textValue']) {
          Ember.run.later(() => {
            Ember.set(this, 'value', filterData['textValue'].fieldValue);
            this.stFiltersService.setAsLoaded(this.stFilterGroupKey, this.stFilterKey);
          });
        } else {
          Ember.run.later(() => {
            this.stFiltersService.setAsLoaded(this.stFilterGroupKey, this.stFilterKey);
          });
        }
      }
    },

    inputUniqueClass: Ember.computed('textInputUuid', function () {
      return "input_class_".concat(this.textInputUuid);
    }),
    classes: Ember.computed('inputClass', 'iconButton', function () {
      var classes = this.inputClass;

      if (this.iconButton) {
        classes += ' has-icon-button';
      }

      return classes;
    }),
    didRender: function () {
      (0, _jquery.default)("body .".concat(this.inputUniqueClass, " input")).off('focusout');
      (0, _jquery.default)("body .".concat(this.inputUniqueClass, " input")).on('focusout', () => {
        this.updateFilterData();
      });
    },
    didInsertElement: function () {
      // Firefox fix for number inputs: no scrolling and only allow numbers
      (0, _jquery.default)("body").on('keydown', '.disable-number-input input[type=number]', function (e) {
        var allowedKeys = [8, 9, 13, 37, 39, 46]; //tab, enter, backspace, delete, left/right arrows

        if (allowedKeys.includes(e.which) || e.keyCode >= 48 && e.keyCode <= 57 || e.keyCode >= 96 && e.keyCode <= 105) {
          //only allow numeric keys and delete
          return;
        } else {
          e.preventDefault(); //tab is 9 13
        }
      });
    },

    updateFilterData() {
      if (this.isFilter) {
        if (this.value != null && this.value != '') {
          var filter = "".concat(this.stFilterAttribute, " ~ '%").concat(this.value, "%'");

          if (this.stFilterTextExactMatch === true) {
            filter = "".concat(this.stFilterAttribute, " = ").concat(this.value);
          }

          this.stFiltersService.saveFilter(this.stFilterGroupKey, this.stFilterKey, 'textValue', this.stFiltersService.getActualFilterOrUseCallback(this.stFilterAttribute, this.value, filter), this.value, this.stFilterDoNotCache, this.overrideFilterKey);
        } else {
          this.stFiltersService.clearFilter(this.stFilterGroupKey, this.stFilterKey, 'textValue');
        }
      }
    },

    actions: {
      toggleIconButton: function (dropdownAction) {
        if (this.dropdownOptions && this.dropdownOptions.length > 0) {
          dropdownAction();
        }
      }
    }
  });

  _exports.default = _default;
});