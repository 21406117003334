define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/interactive-services-providers/edit-create/component", ["exports", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "ember-concurrency"], function (_exports, _component, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EditCreate = (_dec = Ember.inject.service(), _dec2 = Ember.inject.service(), _dec3 = Ember.inject.service(), _dec4 = Ember.inject.service(), _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
    var integratedIsps = ['Alarm.com', 'Alula'];
    var hasGrant = this.grants.getIndividualGrant('interactive_services_provider_configs.manage');
    var ispType = this.args.multiStepForm.formCopiedModelsGetVal('interactiveServicesProvider.model.interactiveServicesProviderType.name');
    this.SwitchableFormStep.progressBarHidden = !integratedIsps.includes(ispType) || !hasGrant || this.SwitchableFormStep.isNewRecord;
    this.interactiveServicesProviderTypes = yield this.store.findAll('interactiveServicesProviderType');

    if (this.SwitchableFormStep.isNewRecord) {
      this.args.multiStepForm.formCopiedModelsSetVal('interactiveServicesProvider.model.active', true);
    }
  }), _dec8 = Ember._action, _dec9 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    var overrideConfig = Ember.Object.create({
      interactiveServicesProvider: {
        model: multiStepForm.formOriginalModelsGetVal('interactiveServicesProvider.model'),
        copyRelationMap: {
          interactiveServicesProviderType: {
            readOnly: true
          },
          interactiveServicesProviderConfig: {
            readOnly: true
          }
        }
      }
    });
    var validationObject = {
      deepValidate: false,
      deepCreate: false,
      notificationNamespace: 'modal',
      overrideOriginalModelsConfig: overrideConfig
    };

    if (this.SwitchableFormStep.isNewRecord) {
      var toCreateObjects = yield multiStepForm.validateCreateAll(validationObject);
      var theModel = this.store.createRecord('interactiveServicesProvider', toCreateObjects.interactiveServicesProvider.data);
      yield toCreateObjects.interactiveServicesProvider.saveWithRollbackAndNotification(theModel);
      buttonPromise.then(() => {
        multiStepForm.resetFromCreateToEdit();
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
        this.initFormData.perform();
      });
    } else {
      yield multiStepForm.validateSyncSaveAll(validationObject);
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
        this.initFormData.perform();
      });
    }
  }), _dec10 = (0, _emberConcurrency.task)(function* () {
    var interactiveServicesProvider = this.args.multiStepForm.formOriginalModelsGetVal('interactiveServicesProvider.model');

    try {
      yield interactiveServicesProvider.destroyRecordWithRollbackTask.perform();
      this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
      this.args.multiStepForm.onDelete();
      this.args.multiStepForm.formStartedSetVal(false);
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'confirmDeleteModal'
      });
      throw err;
    }
  }), (_class = (_temp = class EditCreate extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "grants", _descriptor4, this);

      _defineProperty(this, "titleKey", 'pages.administration.integrations.interactiveServicesProviders.title');

      _initializerDefineProperty(this, "confirmDeleteModalOpen", _descriptor5, this);

      _initializerDefineProperty(this, "interactiveServicesProviderTypes", _descriptor6, this);

      _initializerDefineProperty(this, "initFormData", _descriptor7, this);

      _initializerDefineProperty(this, "saveInteractiveServicesProvider", _descriptor8, this);

      _initializerDefineProperty(this, "deleteInteractiveServicesProvider", _descriptor9, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments, {
        disabledProgressBarSteps: this.disabledProgressBarSteps,
        hiddenProgressBarSteps: this.hiddenProgressBarSteps,
        progressBarHidden: true
      });
      this.initFormData.perform();
    }

    get disabledProgressBarSteps() {
      return [];
    }

    get hiddenProgressBarSteps() {
      return [];
    }

    renderButtons() {
      var buttons = [];

      if (this.args.formMode !== 'view') {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveInteractiveServicesProvider.perform(this.args.multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary interactive-services-provider-save-button'
        });
      }

      if (!this.SwitchableFormStep.isNewRecord && this.grants.getIndividualGrant('interactive_services_providers.delete')) {
        buttons.push({
          text: this.intl.t('common.actions.delete'),
          click: () => {
            this.confirmDeleteModalOpen = true;
          },
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
        });
      }

      this.args.multiStepForm.registerStepData('step1', 'buttons', buttons);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "confirmDeleteModalOpen", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "interactiveServicesProviderTypes", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "initFormData", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "renderButtons", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "renderButtons"), _class.prototype), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "saveInteractiveServicesProvider", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "deleteInteractiveServicesProvider", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = EditCreate;
});