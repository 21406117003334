define("securitytrax-ember-ui/validations/consumer-financing-applicant", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation", "lodash", "moment"], function (_exports, _validate, _baseValidation, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var noValidate = _lodash.default.get(data, 'extraValidationData.noValidate', false);

      var modelName = data.extraValidationData && data.extraValidationData.hasOwnProperty('overrideValidationKey') ? data.extraValidationData.overrideValidationKey : 'consumerFinancingApplicant';

      _validate.default.validators.verifyMatch = function (value, options) {
        if (value && value !== options.value) {
          return options.message;
        }

        return null;
      };

      _validate.default.extend(_validate.default.validators.datetime, {
        parse: function (value) {
          return +_moment.default.utc(value);
        },
        format: function (value) {
          return _moment.default.utc(value).format('MM/DD/YYYY');
        }
      });

      var validateObject = {
        fname: {
          presence: {
            allowEmpty: false,
            message: '^' + data.intlService.t('common.validations.presence', {
              propertyLabel: data.intlService.t('common.filters.fname')
            })
          }
        },
        lname: {
          presence: {
            allowEmpty: false,
            message: '^' + data.intlService.t('common.validations.presence', {
              propertyLabel: data.intlService.t('common.filters.lname')
            })
          }
        },
        birthdate: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-birthdate')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'birthdate');
            }
          },
          datetime: () => {
            if (this.birthdate && this.birthdate != '01-01-0001') {
              return {
                latest: _moment.default.utc((0, _moment.default)().subtract(18, 'years')),
                message: '^' + data.intlService.t('pages.customers.accounting.financing.invalid_birth_date')
              };
            } else {
              return false;
            }
          }
        },
        ssn: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-ssn')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'ssn');
            }
          },
          format: () => {
            if (this.ssn) {
              return {
                pattern: /[0-9]\d{3,}/,
                message: '^' + data.intlService.t('common.validations.customerSsn')
              };
            } else {
              return false;
            }
          }
        },
        verifySsn: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-verifySsn')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'verifySssn');
            }
          },
          verifyMatch: {
            message: function () {
              return '^' + data.intlService.t('pages.customers.accounting.financing.must_match_ssn');
            },
            value: this.ssn
          }
        },
        idType: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-idType')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'idType');
            }
          }
        },
        idNumber: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-idNumber')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'idNumber');
            }
          }
        },
        idIssueDate: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-idIssueDate')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'idIssueDate');
            }
          },
          datetime: {
            latest: _moment.default.utc(),
            message: '^' + data.intlService.t('pages.customers.accounting.financing.invalid_issue_date')
          }
        },
        idExpirationDate: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-idExpirationDate')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'idExpirationDate');
            }
          },
          datetime: {
            earliest: _moment.default.utc(),
            message: '^' + data.intlService.t('pages.customers.accounting.financing.invalid_expiration_date')
          }
        },
        idStateOn: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-idStateOn')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'idStateOn');
            }
          }
        },
        address1: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-address1')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'address1');
            }
          }
        },
        city: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-city')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'city');
            }
          }
        },
        state: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-state')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'state');
            }
          }
        },
        zip: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-zip')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'zip');
            }
          },
          format: () => {
            if (this.zip) {
              return {
                pattern: /\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d/,
                message: this.customMessage(data, 'zipInvalid')
              };
            } else {
              return false;
            }
          }
        },
        email: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-email')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'email');
            }
          },
          format: () => {
            if (this.email) {
              return {
                pattern: /\S+@\S+\.\S+/,
                //Basic test for text@text.text
                message: this.customMessage(data, 'emailInvalid')
              };
            } else {
              return false;
            }
          }
        },
        confirmEmail: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-confirmEmail')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'confirmEmail');
            }
          },
          verifyMatch: {
            message: function () {
              return '^' + data.intlService.t('pages.customers.accounting.financing.must_match_email');
            },
            value: this.email
          }
        },
        employer: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-employer')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'employer');
            }
          }
        },
        employerStartDate: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-employerStartDate')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'employerStartDate');
            }
          },
          datetime: {
            latest: _moment.default.utc(),
            earliest: _moment.default.utc((0, _moment.default)(this.birthdate).add(16, 'years')),
            message: '^' + data.intlService.t('pages.customers.accounting.financing.invalid_start_date')
          }
        },
        grossMonthlyIncome: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-grossMonthlyIncome')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'grossMonthlyIncome');
            }
          },
          numericality: {
            greaterThan: 0,
            lessThan: 1000000
          }
        },
        cellPhone: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-cellPhone')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'cellPhone');
            }
          },
          format: () => {
            if (this.cellPhone) {
              return {
                pattern: /\d{9,15}/,
                //allow 9 for international, up to 15 since db accepts 15
                message: this.customMessage(data, 'phoneInvalid')
              };
            } else {
              return false;
            }
          }
        },
        residenceType: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-residenceType')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'residenceType');
            }
          }
        },
        residenceMonthlyPayment: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-residenceMonthlyPayment')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'residenceMonthlyPayment');
            }
          }
        },
        homeValue: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-homeValue')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'homeValue');
            }
          }
        },
        employmentType: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-employmentType')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'employmentType');
            }
          }
        },
        occupation: {
          presence: () => {
            if (noValidate && noValidate.includes(modelName + '-occupation')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'occupation');
            }
          }
        },
        livingSinceCurrentResidence: {
          datetime: {
            latest: _moment.default.utc(),
            message: '^' + data.intlService.t('pages.customers.accounting.financing.invalid_moved_in_date')
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      return this.outputFormatting(validationData, modelName);
    }

  });

  _exports.default = _default;
});