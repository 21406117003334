define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/interactive-services-providers/adc/import/import-customers/getting-started/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XW7rndhC",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],null,[[\"additionalClass\",\"config\",\"modalButtons\"],[\"layout-center-middle\",[35,2],[35,1,[\"currentStepData\",\"buttons\"]]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"grid-y\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cell small-2\"],[12],[2,\"\\n            \"],[10,\"h3\"],[12],[1,[30,[36,0],[\"pages.administration.integrations.interactiveServicesProviders.imports.customerImport.getStarted\"],null]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cell small-10 grid-x\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"cell small-2\"],[12],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"cell small-8\"],[12],[2,\"\\n                \"],[10,\"p\"],[12],[2,\"\\n                    \"],[2,[30,[36,0],[\"pages.administration.integrations.interactiveServicesProviders.imports.customerImport.getStartedMsg\"],null]],[2,\"\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"cell small-2\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"multiStepForm\",\"progressBarConfig\",\"multi-step-form/progress-bar\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/interactive-services-providers/adc/import/import-customers/getting-started/template.hbs"
    }
  });

  _exports.default = _default;
});