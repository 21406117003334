define("securitytrax-ember-ui/helpers/in-arr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.inArr = inArr;
  _exports.default = void 0;

  function inArr(params
  /*, hash*/
  ) {
    var haystack = params[0];
    var needle = params[1];

    if (!Array.isArray(haystack) || Ember.isEmpty(needle)) {
      (false && Ember.warn('in-arr params must include an Array "haystack" first, and the search "needle" next', false, {
        id: 'helpers/in-arr'
      }));
      return;
    }

    return haystack.indexOf(needle) > -1;
  }

  var _default = Ember.Helper.helper(inArr);

  _exports.default = _default;
});