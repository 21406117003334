define("securitytrax-ember-ui/components/multi-step-form/forms/invoices/base-step-components/get-started/base", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/invoices/base", "ember-concurrency", "securitytrax-ember-ui/utils/strings"], function (_exports, _base, _emberConcurrency, _strings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var BaseGetStarted = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = (0, _emberConcurrency.task)(function* () {
    this.fieldDefaults = {};
    var hashedData = yield (0, _emberConcurrency.hash)({
      invoiceBillableItems: this.invoiceBillableItemsTask.perform(),
      taxRates: this.taxRatesTask.perform(),
      templates: this.getInvoiceQuoteTemplates.perform()
    });
    Ember.setProperties(this, hashedData);
  }), _dec13 = (0, _emberConcurrency.task)(function* () {
    var sortedTemplates = [];
    var invoiceQuoteTemplates = yield this.store.query('template', {
      filter: 'template_type = 3 and active = true',
      include: 'template_type.template_type_columns,template_field_defaults'
    });
    invoiceQuoteTemplates.forEach(invoiceQuoteTemplate => {
      var fieldDefaults = {
        total_quantity: 0,
        invoice_line_items: []
      };
      var expireOptions = invoiceQuoteTemplate.get('templateType.templateTypeColumns').findBy('columnName', 'expires').get('options');
      invoiceQuoteTemplate.get('templateFieldDefaults').forEach(fieldDefault => {
        var typeColumn = fieldDefault.get('templateTypeColumn');

        if (typeColumn.get('columnName') !== 'invoice_line_items') {
          fieldDefaults[typeColumn.get('columnName')] = fieldDefault.get('value');
        }
      });
      var lineItems = invoiceQuoteTemplate.get('templateFieldDefaults').filterBy('templateTypeColumn.columnName', 'invoice_line_items');
      lineItems.forEach(item => {
        var parsedItem = JSON.parse(item.get('value'));
        fieldDefaults['invoice_line_items'].push(parsedItem);
        fieldDefaults['total_quantity'] += Number(parsedItem.quantity);
      });
      this.fieldDefaults[invoiceQuoteTemplate.id] = fieldDefaults;
      sortedTemplates.push({
        packageName: invoiceQuoteTemplate.get('name'),
        packageId: invoiceQuoteTemplate.get('id'),
        packageContent: [{
          label: this.intl.t('pages.administration.content_management.invoice_quote_templates.expires'),
          description: expireOptions[fieldDefaults.expires] || ''
        }, {
          label: this.intl.t('pages.administration.content_management.invoice_quote_templates.num_of_items'),
          description: fieldDefaults.invoice_line_items.length
        }, {
          label: this.intl.t('pages.administration.content_management.invoice_quote_templates.total_quantity'),
          description: fieldDefaults.total_quantity
        }]
      });
    });
    return sortedTemplates;
  }), _dec14 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var isActivationFee = false;

    try {
      var lineItems = [];
      var promises = [];
      var packages = yield this.packages;
      var hashedData = yield (0, _emberConcurrency.hash)({
        connectedEquipmentItems: this.addUninvoicedEquipment ? this.invoiceService.getConnectedItemsTask.perform(this.args.customer.get('id'), 'equipment') : [],
        mmrBillableItem: this.mmr ? this.invoiceService.getConnectedItemsTask.perform(this.args.customer.get('id'), 'mmr') : null
      });
      isActivationFee = true;
      var activationFeeBillableItem = this.activationFee ? yield this.invoiceService.getConnectedItemsTask.perform(this.args.customer.get('id'), 'activation_fee') : null;
      isActivationFee = false;

      if (this.selectedPackageId) {
        var selectedPackage = packages.findBy('id', this.selectedPackageId);
        selectedPackage.packageEquipment.forEach(item => {
          promises.push(this.populateLineItemTask.perform(lineItems, item));
        });
      }

      if (this.selectedTemplateId) {
        Object.keys(this.fieldDefaults[this.selectedTemplateId]).forEach(key => {
          if (key !== 'invoice_line_items') {
            this.args.multiStepForm.formCopiedModelsSetVal('invoice.model.' + (0, _strings.snakeToCamelCase)(key), this.fieldDefaults[this.selectedTemplateId][key]);
          }
        });
        this.fieldDefaults[this.selectedTemplateId].invoice_line_items.sortBy('sort').forEach(item => {
          var billableItem = this.invoiceBillableItems.findBy('id', item.invoice_billable_item_id);
          var itemCharge = item.unit_price != null ? item.unit_price : billableItem.get('defaultUnitPrice');

          if (billableItem.entityType) {
            promises.push(this.populateLineItemTask.perform(lineItems, Ember.Object.create({
              quantity: item.quantity,
              equipment: billableItem.get('equipment'),
              itemCharge,
              relationships: {
                invoice_billable_item: {
                  data: {
                    id: item.invoice_billable_item_id
                  }
                }
              }
            })));
          } else {
            promises.push(this.populateLineItemTask.perform(lineItems, Ember.Object.create({
              equipment: billableItem.get('equipment'),
              attributes: {
                quantity: item.quantity,
                gross_amount: itemCharge,
                unit_price: itemCharge,
                combo_id: null,
                sales_tax_rate: 0.00,
                sales_tax_amount: 0.00,
                total_amount: itemCharge,
                description: billableItem ? billableItem.code : 'Labor'
              },
              relationships: {
                invoice_billable_item: {
                  data: {
                    id: item.invoice_billable_item_id
                  }
                }
              }
            })));
          }
        });
      }

      hashedData.connectedEquipmentItems.forEach(item => {
        promises.push(this.populateLineItemTask.perform(lineItems, item));
      });

      if (this.mmr) {
        promises.push(this.populateLineItemTask.perform(lineItems, hashedData.mmrBillableItem[0]));
      }

      if (this.activationFee && activationFeeBillableItem && activationFeeBillableItem[0]) {
        promises.push(this.populateLineItemTask.perform(lineItems, activationFeeBillableItem[0]));
      }

      yield (0, _emberConcurrency.all)(promises);
      this.args.multiStepForm.formCopiedModelsSetVal('invoice.model.invoiceLineItems', lineItems);
      this.args.multiStepForm.formCopiedModelsSetVal('invoice.model.invalidInvoiceBillableItems', this.invalidInvoiceBillableItems);
      buttonPromise.then(() => {
        this.args.multiStepForm.currentStepNameKeySetVal('details');
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(isActivationFee ? this.intl.t('pages.customers.accounting.invoices.actv_fee_already_used') : err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), _dec15 = (0, _emberConcurrency.task)(function* (lineItems, item) {
    var equipment = yield item.get('equipment');
    var billableItem = equipment ? this.invoiceBillableItems.findBy('equipment.id', equipment.get('id')) : this.invoiceBillableItems.findBy('id', item.relationships.invoice_billable_item.data.id);

    if (billableItem) {
      var hashedData = yield (0, _emberConcurrency.hash)({
        isNotTaxable: billableItem ? billableItem.isNotTaxableTask.perform(this.args.customer) : null,
        tax: billableItem ? billableItem.taxTask.perform(this.args.customer, this.taxRates) : null
      });
      var quantity = equipment ? item.get('quantity') : item.attributes.quantity;
      var grossAmount = equipment ? item.get('itemCharge') * quantity : item.attributes.gross_amount;
      var salesTaxAmount = hashedData.tax && hashedData.tax.tax_rate ? hashedData.tax.tax_rate / 100 * grossAmount : 0;
      var unitPrice = equipment ? item.get('itemCharge') : item.attributes.unit_price;
      var comboId = item.attributes ? item.attributes.combo_id : null;
      var uniqueItem = true;
      lineItems.every(listedItem => {
        if (listedItem.invoiceBillableItem == billableItem && listedItem.unitPrice == unitPrice && listedItem.comboId == comboId) {
          listedItem.quantity = Number(listedItem.quantity) + quantity;
          listedItem.grossAmount = Number(unitPrice) * listedItem.quantity;
          listedItem.salesTaxAmount = Number(listedItem.salesTaxRate) / 100 * listedItem.grossAmount;
          listedItem.totalAmount = listedItem.salesTaxAmount + listedItem.grossAmount;
          uniqueItem = false;
          return false;
        }

        return true;
      });

      if (uniqueItem) {
        lineItems.push(Ember.Object.create({
          quantity,
          invoiceBillableItem: billableItem,
          description: billableItem ? billableItem.get('description') : '',
          unitPrice,
          grossAmount,
          salesTaxRate: item.attributes && item.attributes.sales_tax_rate ? item.attributes.sales_tax_rate : hashedData.tax && hashedData.tax.tax_rate != null ? hashedData.tax.tax_rate : 0.00,
          salesTaxAmount: item.attributes && item.attributes.sales_tax_amount != null ? item.attributes.sales_tax_amount : salesTaxAmount,
          totalAmount: item.attributes && item.attributes.total_amount != null ? item.attributes.total_amount : Number(grossAmount) + Number(salesTaxAmount),
          isNotTaxable: hashedData.isNotTaxable,
          comboId
        }));
      }
    } else {
      this.invalidInvoiceBillableItems.push(equipment ? equipment.get('name') : item.attributes.description);
    }
  }), _dec16 = (0, _emberConcurrency.task)(function* () {
    return yield this.store.findAll('package', {
      include: 'package_equipment.equipment'
    });
  }), _dec17 = (0, _emberConcurrency.task)(function* () {
    var packageToEquipmentCount = Ember.A([]);
    var packages = yield this.packages;

    var _loop = function (j) {
      var pkEquipmentList = packages.objectAt(j).get('packageEquipment');
      var currPkEquipmentList = {};

      if (pkEquipmentList.length > 0) {
        for (var i = 0; i < pkEquipmentList.length; i++) {
          var currPkEquipment = pkEquipmentList.objectAt(i);
          var currentPkEquipmentItem = currPkEquipment.get('equipment');

          if (currentPkEquipmentItem) {
            if (!currPkEquipmentList[currentPkEquipmentItem.get('name')]) {
              currPkEquipmentList[currentPkEquipmentItem.get('name')] = currPkEquipment.get('quantity');
            } else {
              currPkEquipmentList[currentPkEquipmentItem.get('name')] += currPkEquipment.get('quantity');
            }
          }
        }

        var currPkEquipmentListEmberArr = Object.keys(currPkEquipmentList).map(equipmentName => {
          return Ember.Object.create({
            name: equipmentName,
            quantity: currPkEquipmentList[equipmentName]
          });
        });
        var packageId = packages.objectAt(j).get('id'); // if (this.args.nrg && this.args.customerCreditCheck && packageId != 3 && (this.args.customerCreditCheck.score === '4' || this.args.customerCreditCheck.score === '7')) {
        //     return;
        // }

        packageToEquipmentCount.pushObject(Ember.Object.create({
          packageName: packages.objectAt(j).get('name'),
          packageId,
          packageContent: currPkEquipmentListEmberArr
        }));
      }
    };

    for (var j = 0; j < packages.length; j++) {
      _loop(j);
    }

    return packageToEquipmentCount;
  }), _dec18 = Ember._action, (_class = (_temp = class BaseGetStarted extends _base.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "invoiceService", _descriptor2, this);

      _initializerDefineProperty(this, "grants", _descriptor3, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor4, this);

      _initializerDefineProperty(this, "sessionData", _descriptor5, this);

      _initializerDefineProperty(this, "mmr", _descriptor6, this);

      _initializerDefineProperty(this, "activationFee", _descriptor7, this);

      _initializerDefineProperty(this, "addUninvoicedEquipment", _descriptor8, this);

      _initializerDefineProperty(this, "selectedPackageId", _descriptor9, this);

      _initializerDefineProperty(this, "selectedTemplateId", _descriptor10, this);

      _initializerDefineProperty(this, "fieldDefaults", _descriptor11, this);

      _defineProperty(this, "invalidInvoiceBillableItems", []);

      _initializerDefineProperty(this, "initBaseData", _descriptor12, this);

      _initializerDefineProperty(this, "getInvoiceQuoteTemplates", _descriptor13, this);

      _initializerDefineProperty(this, "setupInvoiceLineItemsTask", _descriptor14, this);

      _initializerDefineProperty(this, "populateLineItemTask", _descriptor15, this);

      _initializerDefineProperty(this, "packagesTask", _descriptor16, this);

      _initializerDefineProperty(this, "reducedPackageEquipmentListTask", _descriptor17, this);
    }

    get hiddenProgressBarSteps() {
      return ['details', 'send', 'addPayment', 'listPayments', 'applyToInvoice', 'confirmRefundVoid', 'viewProcessingInformation', 'history', 'editManualPayment', 'duplicate'];
    }

    get disabledProgressBarSteps() {
      return ['details', 'send', 'addPayment', 'listPayments', 'applyToInvoice'];
    }

    get reducedPackageEquipmentList() {
      return this.reducedPackageEquipmentListTask.perform();
    }

    get packages() {
      return this.packagesTask.perform();
    }

    setupButtons() {
      this.args.multiStepForm.registerStepData('gettingStarted', 'buttons', [{
        text: 'Next',
        click: buttonPromise => {
          return this.setupInvoiceLineItemsTask.perform(buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary save'
      }]);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "invoiceService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "mmr", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "activationFee", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "addUninvoicedEquipment", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "selectedPackageId", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "selectedTemplateId", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "fieldDefaults", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "initBaseData", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "getInvoiceQuoteTemplates", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "setupInvoiceLineItemsTask", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "populateLineItemTask", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "packagesTask", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "reducedPackageEquipmentListTask", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupButtons", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "setupButtons"), _class.prototype)), _class));
  _exports.default = BaseGetStarted;
});