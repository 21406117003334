define("securitytrax-ember-ui/models/base_models/monitoring-plan", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    monitorCode: (0, _model.attr)('string', {
      'readOnly': false
    }),
    name: (0, _model.attr)('string', {
      'readOnly': false
    }),
    chargeAmount: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    baseAmount: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    sort: (0, _model.attr)('number', {
      'readOnly': false
    }),
    status: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['active', 'deleted']
    }),
    monitoringCompanyType: (0, _model.belongsTo)('monitoring-company-type', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});