define("securitytrax-ember-ui/session-stores/application", ["exports", "ember-simple-auth/session-stores/adaptive", "securitytrax-ember-ui/config/environment"], function (_exports, _adaptive, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adaptive.default.extend({
    //AdaptiveStore
    cookieName: 'profiles:' + _environment.default['securitytrax-ember-ui']['profile'] + ':ember_simple_auth:session',
    cookiePath: '/' + _environment.default['securitytrax-ember-ui']['profile'],
    localStorageKey: 'profiles:' + _environment.default['securitytrax-ember-ui']['profile'] + ':ember_simple_auth:session' //localStorage
    //key: 'profiles:' + ENV['securitytrax-ember-ui']['profile'] + ':ember_simple_auth:session'

  });

  _exports.default = _default;
});