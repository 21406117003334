define("securitytrax-ember-ui/models/location", ["exports", "securitytrax-ember-ui/models/base_models/location"], function (_exports, _location) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Location = _location.default.extend({
    intl: Ember.inject.service(),
    inventoryRecipientClass: '200',
    inventorySinkNameDescription: Ember.computed('name', function () {
      return this.name + ' (' + this.intl.t('pages.administration.organization.officeLocations.information.location') + ')';
    })
  });

  var _default = Location;
  _exports.default = _default;
});