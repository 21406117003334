define("securitytrax-ember-ui/components/multi-step-form/forms/my-account/integrations/edit-adc/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency"], function (_exports, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    stAjax: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    integrationPassword: null,
    confirmDeleteModalOpen: false,
    user: undefined,

    init() {
      this._super(...arguments);

      this.set('usernameField', this.get('adcService.attributes.username'));
    },

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      var buttons = [];
      buttons.push({
        text: this.intl.t('common.actions.save'),
        classNamesString: 'st-btn st-btn-primary',
        click: buttonPromise => {
          return this.saveIntegration.perform(multiStepForm, buttonPromise);
        }
      });

      if (this.adcService.attributes.user_has_auth_token) {
        buttons.push({
          text: this.intl.t('pages.users.my_account.integrations.adc.unlink'),
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn',
          click: () => {
            this.set('confirmDeleteModalOpen', true);
          }
        });
      }

      multiStepForm.registerStepData('step1', 'buttons', buttons);
    },

    unlinkSso: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      try {
        var user = this.user || (yield this.sessionData.getUser());
        yield user.invokeAction('unset_auth_token', {
          verb: 'POST',
          data: {
            data: {
              relationships: {
                interactive_services_provider: {
                  data: {
                    id: this.get('adcService.relationships.interactive_services_provider.data.id'),
                    type: 'interactive_services_providers'
                  }
                }
              }
            }
          }
        });
        buttonPromise.then(() => {
          this.set('confirmDeleteModalOpen', false);
          var multiStepForm = this.multiStepForm;
          multiStepForm.formStartedSetVal(false);
          this.stNotificationManager.addSuccessKey('pages.users.my_account.integrations.adc.unlink_success');
          multiStepForm.onAfterSave();
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
        throw err;
      }
    }),
    saveIntegration: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var formattedObjects = [];
      formattedObjects.push(Ember.Object.create({
        model: {
          username: this.usernameField,
          password: this.integrationPassword,
          twoFactorDeviceId: this.twoFactorDeviceId
        },
        filePath: 'adc-sso-integration'
      }));
      yield multiStepForm.validateList.perform(formattedObjects);

      try {
        var user = this.user || (yield this.sessionData.getUser());
        var response = yield user.invokeAction('create_and_set_auth_token', {
          verb: 'POST',
          data: {
            data: {
              attributes: {
                username: this.usernameField,
                password: this.integrationPassword,
                two_factor_device_id: this.twoFactorDeviceId
              },
              relationships: {
                interactive_services_provider: {
                  data: {
                    id: this.get('adcService.relationships.interactive_services_provider.data.id'),
                    type: 'interactive_services_providers'
                  }
                }
              }
            }
          }
        });
        this.set('adcService.attributes.username', this.usernameField);
        this.set('adcService.attributes.user_has_auth_token', response.responsePayload.data.attributes.user_has_auth_token);
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        buttonPromise.then(() => {
          multiStepForm.formStartedSetVal(false);
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
        throw err;
      }
    })
  });

  _exports.default = _default;
});