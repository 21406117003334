define("securitytrax-ember-ui/customers/view/manage-united-central-control/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-concurrency", "securitytrax-ember-ui/mixins/subroute-modal"], function (_exports, _authenticatedRouteMixin, _emberConcurrency, _subrouteModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _subrouteModal.default, {
    store: Ember.inject.service(),
    grants: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      var customerId = this.paramsFor('customers').id;
      return this.findGrantsTask.perform(['customer_order_information.view', 'customer_system_information.view', 'customers.manage_central_station_integration'], 'all', '/customers/' + customerId + '/view');
    },

    model() {
      return this.setupModelTask.perform();
    },

    setupModelTask: (0, _emberConcurrency.task)(function* () {
      var parentModel = yield this.modelFor(this.parentRoute);
      var monitoringCompany;
      var customerSystemInfo;

      try {
        var orderInfoGrant = this.grants.getIndividualGrant('customer_order_information.view');
        var systemInfoGrant = this.grants.getIndividualGrant('customer_system_information.view');
        var customer = parentModel.customer;

        if (orderInfoGrant) {
          try {
            var customerOrderInfo = yield this.store.findRecord('customerOrderInformation', customer.get('id'));
            monitoringCompany = yield customerOrderInfo.get('monitoringCompany');
          } catch (e) {//
          }
        }

        if (systemInfoGrant) {
          try {
            customerSystemInfo = yield this.store.findRecord('customerSystemInformation', customer.get('id'));
          } catch (e) {//
          }
        }
      } catch (error) {
        (false && Ember.warn('failed to get stages monitoring company', error, {
          id: 'customers.view.manage-united-central-control'
        }));
      }

      return (0, _emberConcurrency.hash)({
        customer: parentModel.customer,
        customerSystemInformation: customerSystemInfo,
        monitoringCompany: monitoringCompany,
        parentRoute: this.transitionRoute
      });
    })
  });

  _exports.default = _default;
});