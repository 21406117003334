define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/edit-create-credit-bureau/adt/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZHDDEZqT",
    "block": "{\"symbols\":[\"data\"],\"statements\":[[8,\"html/st-await\",[[24,\"useSpinner\",\"true\"],[24,\"promises\",\"(hash\"],[24,\"monitoringCompanies\",\"monitoringCompanies\"],[24,\")\",\"\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"grid-x grid-padding-x\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cell medium-6\"],[12],[2,\"\\n            \"],[8,\"multi-step-form/switchable-inputs/text-input\",[],[[\"@formMode\",\"@value\",\"@label\"],[[34,0],[34,1,[\"formCopiedModels\",\"creditBureau\",\"model\",\"creditBureauConfig\",\"dealernumber\"]],[30,[36,2],[\"common.columns.dealer_number\"],null]]],null],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"cell medium-6\"],[12],[2,\"\\n            \"],[8,\"multi-step-form/switchable-inputs/password-mask-input\",[],[[\"@formMode\",\"@label\",\"@hasSavedValue\",\"@updateValue\"],[[34,0],[30,[36,2],[\"common.columns.password\"],null],[34,1,[\"formCopiedModels\",\"creditBureau\",\"model\",\"creditBureauConfig\",\"responseMeta\",\"attribute_has_saved_value\",\"password\"]],[30,[36,4],[[32,0],[30,[36,3],[[35,1,[\"formCopiedModels\",\"creditBureau\",\"model\",\"creditBureauConfig\",\"password\"]]],null]],null]]],null],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"cell medium-6\"],[12],[2,\"\\n            \"],[8,\"multi-step-form/switchable-inputs/select-input\",[],[[\"@formMode\",\"@options\",\"@value\",\"@label\",\"@bindValueAsObject\",\"@renderInPlaceOverride\",\"@renderInPlaceOverride\"],[[34,0],[34,5],[34,1,[\"formCopiedModels\",\"creditBureau\",\"model\",\"creditBureauConfig\",\"monitoringCompanyId\"]],[30,[36,2],[\"common.columns.monitoring_company\"],null],false,[30,[36,6],[[35,0],\"create\"],null],false]],null],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"formMode\",\"multiStepForm\",\"t\",\"mut\",\"action\",\"monitoringCompanies\",\"not-eq\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/edit-create-credit-bureau/adt/template.hbs"
    }
  });

  _exports.default = _default;
});