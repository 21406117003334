define("securitytrax-ember-ui/components/html/svg-components/credit-score-scale-d3/component", ["exports", "d3-selection", "jquery"], function (_exports, _d3Selection, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * credit score scale
   *
   * @class Component
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    cardTitle: '',
    sliderOffset: Ember.computed('showScore', 'sliderValue', function () {
      return this.convertScore();
    }),
    rangeMin: 350,
    rangeMax: 850,
    sliderValue: 720,
    sliderWidth: 200,
    handleWidth: 38,
    showScore: true,

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      var svg = (0, _d3Selection.select)((0, _jquery.default)('svg')[0]);
      var defs = svg.append('defs');
      var gradientB = defs.append('linearGradient').attr('id', 'gradient-b').attr('x1', '100%').attr('x2', '0%').attr('y1', '50%').attr('y2', '50%');
      gradientB.append('stop').attr('offset', '0%').attr('stop-color', '#7DC80A').attr('stop-opacity', 1);
      gradientB.append('stop').attr('offset', '53.376%').attr('stop-color', '#FFCD00').attr('stop-opacity', 1);
      gradientB.append('stop').attr('offset', '100%').attr('stop-color', '#D92C29').attr('stop-opacity', 1);
      svg.append('rect').attr('width', '200').attr('height', '4').attr('x', 0).attr('y', 0).attr('rx', 2).attr('fill', 'url(#gradient-b)');
    },

    setHandle: Ember.observer('showScore', function () {
      if (this.showScore) {
        this.set('handleWidth', 38);
      } else {
        this.set('handleWidth', 24);
      }

      this.convertScore();
    }),

    /*   convertPercentage() {
          this.set('sliderOffset', this.sliderValue * 162 / 100);
      }, */
    convertScore() {
      if (this.sliderValue >= this.rangeMin && this.sliderValue <= this.rangeMax) {
        var newSliderVal = this.sliderValue - this.rangeMin;
        var range = this.rangeMax - this.rangeMin;
        return newSliderVal * (this.sliderWidth - this.handleWidth) / range;
      }

      return 84;
    },

    actions: {}
  });

  _exports.default = _default;
});