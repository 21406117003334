define("securitytrax-ember-ui/customers/accounting/billing-payment-methods/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/route-pageview-logging", "ember-concurrency"], function (_exports, _authenticatedRouteMixin, _routePageviewLogging, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _routePageviewLogging.default, {
    grants: Ember.inject.service(),
    sessionData: Ember.inject.service('sessionData'),
    quickAdd: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['customer_billings.view'], 'all', 'customers.accounting');
    },

    model() {
      var {
        customer
      } = this._super(...arguments);

      return this.setupModelTask.perform(customer);
    },

    setupModelTask: (0, _emberConcurrency.task)(function* (customer) {
      return Ember.RSVP.hash({
        customer: yield customer
      });
    }),
    customerController: Ember.computed(function () {
      return this.controllerFor('customers');
    }),

    setupBreadCrumbs() {
      var breadCrumbs = [{
        name: this.intl.t('pages.accounting.title'),
        link: 'customers.accounting'
      }, {
        name: this.intl.t('pages.customers.accounting.customer_billing.title'),
        previousLink: 'customers.accounting',
        last: true
      }];
      var breadCrumbData = {
        titleLink: 'customers.accounting',
        hide: false
      };
      this.customerController.set('breadCrumbs', breadCrumbs);
      this.customerController.set('breadCrumbData', breadCrumbData);
    },

    actions: {
      didTransition() {
        this.setupBreadCrumbs();
        this.quickAdd.set('showQuickAdd', true);
        this.quickAdd.set('page', 'customer');
      }

    }
  });

  _exports.default = _default;
});