define("securitytrax-ember-ui/components/bulk-edit/bulk-edit-options/component", ["exports", "@glimmer/component", "lodash", "ember-concurrency"], function (_exports, _component, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var BulkEditOptions = (_dec = Ember.inject.service, _dec2 = Ember.inject.service('events/bulk-edit'), _dec3 = Ember._tracked, _dec4 = (0, _emberConcurrency.task)(function* () {
    var formattedObjects = [];
    this.fieldsToEdit.forEach((fieldToEdit, index) => {
      formattedObjects.pushObject(Ember.Object.create({
        model: {
          field: fieldToEdit.key,
          newValue: fieldToEdit.newValue
        },
        extraValidationData: {
          noValidate: this.args.massEdit ? ['bulkEdit-newValue'] : [],
          overrideValidationKey: index + '-bulkEdit'
        },
        filePath: 'bulk-edit'
      }));
    });
    yield this.args.multiStepForm.validateList.perform(formattedObjects);
    this.args.onSave(this.fieldsToEdit);
  }), _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, (_class = (_temp = class BulkEditOptions extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "screen", _descriptor, this);

      _initializerDefineProperty(this, "bulkEdit", _descriptor2, this);

      _initializerDefineProperty(this, "fieldsToEdit", _descriptor3, this);

      _defineProperty(this, "newId", 0);

      _initializerDefineProperty(this, "validateFieldsToEditTask", _descriptor4, this);

      this.bulkEdit.on("update-".concat(this.args.modelToEdit, "-bulk-edit-list"), this, '_updateBulkEditList');

      if (!this.args.editableFields) {
        return Error('You must pass in editableFields to bulkEditOptions');
      }

      if (!this.args.modelToEdit) {
        return Error('You must pass in modelToEdit to bulkEditOptions');
      }

      if (!this.args.onSave || typeof this.args.onSave !== 'function') {
        return Error('You must pass in onSave to bulkEditOptions and it must be a function/action');
      }

      this.fieldsToEdit = Ember.A([{
        id: this.newId,
        key: undefined,
        newValue: undefined
      }]);
      this.newId = 1;
    }

    willDestroy() {
      this.bulkEdit.off("update-".concat(this.args.modelToEdit, "-bulk-edit-list"), this, '_dynamicCallbackOpen');
      this.fieldsToEdit.forEach(field => {
        Ember.set(field, 'disabled', false);
      });
    }

    _updateBulkEditList() {
      this.validateFieldsToEditTask.perform();
    }

    get disabled() {
      return this.fieldsToEdit.length === this.args.editableFields.length;
    }

    changeField(index, field) {
      Ember.set(this.fieldsToEdit[index], 'disabled', false);
      Ember.set(field, 'disabled', true);
      Ember.set(field, 'id', this.fieldsToEdit[index].id);
      this.fieldsToEdit[index] = field;
      Ember.notifyPropertyChange(this, 'fieldsToEdit');
    }

    addRow() {
      if (!this.disabled) {
        this.fieldsToEdit.pushObject({
          id: this.newId,
          key: undefined,
          newValue: undefined
        });
        this.newId = this.newId + 1;
        Ember.set(this, 'fieldsToEdit', this.fieldsToEdit);
      }
    }

    removeRow(item) {
      var filteredList = _lodash.default.filter(this.fieldsToEdit, function (theItem) {
        return item.id != theItem.id;
      });

      Ember.set(item, 'disabled', false);
      Ember.set(this, 'fieldsToEdit', filteredList);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "screen", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "bulkEdit", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fieldsToEdit", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "validateFieldsToEditTask", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "changeField", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "changeField"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addRow", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "addRow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeRow", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "removeRow"), _class.prototype)), _class));
  _exports.default = BulkEditOptions;
});