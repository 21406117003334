define("securitytrax-ember-ui/components/multi-step-form/forms/customer-equipment/match-equipment/component", ["exports", "@glimmer/component", "ember-concurrency", "lodash", "securitytrax-ember-ui/mixed-in-classes/set-user"], function (_exports, _component, _emberConcurrency, _lodash, _setUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var MatchEquipment = (_dec = Ember.inject.service('intl'), _dec2 = Ember.inject.service('screen'), _dec3 = Ember.inject.service('stNotificationManager'), _dec4 = Ember.inject.service('store'), _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = (0, _emberConcurrency.task)(function* () {
    this.selectedMatchChoice = 1;
    var hashedData = yield (0, _emberConcurrency.hash)({
      waitForFinishedCopyModels: (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true),
      groupedEquipment: this.args.groupedItems,
      soldByList: this.args.salesReps
    });
    this.soldByList = hashedData.soldByList;

    if (this.startingWithAdcEquipment) {
      this.adcEquipmentArray = this.args.adcEquipmentEditList;
      this.adcEquipmentArray.forEach(equipment => {
        equipment.stEquipmentToMatch = undefined;
        equipment.stEquipmentSoldby = undefined;
        equipment.stEquipmentInstallStatus = undefined;
        equipment.selectedMatchChoice = 1;
      });
      var newGroupedEquipment = [];
      hashedData.groupedEquipment.customerEquipment.forEach(group => {
        newGroupedEquipment.push(group);
      });

      for (var i = 0; i < newGroupedEquipment.length; i++) {
        newGroupedEquipment[i].set('filteredEquipment', []);
        var filteredGroup = newGroupedEquipment[i].items.filter(customerEquipment => {
          if (!customerEquipment.get('interactiveServicesId')) {
            customerEquipment.set('showInMatchList', true);
            return customerEquipment;
          }
        });

        if (filteredGroup.length > 0) {
          newGroupedEquipment[i].set('filteredEquipment', filteredGroup);
        }
      }

      this.filteredCustomerEquipment = newGroupedEquipment;
    } else {
      this.adcEquipmentArray = hashedData.groupedEquipment.adcUnlinkedEquipment;
    }
  }), _dec14 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    var stEquipmentItem;
    var adcEquipmentItem; // Matching ADC to ST

    if (this.startingWithAdcEquipment) {
      adcEquipmentItem = this.adcEquipmentArray[this.currentSlide]; // Matching to existing customer equipment

      if (adcEquipmentItem.selectedMatchChoice === 1) {
        stEquipmentItem = adcEquipmentItem.stEquipmentToMatch;
      } else {
        // Creating customer equipment and then matching
        multiStepForm.formCopiedModelsSetVal('customerEquipment.model.quantity', 1);
        multiStepForm.formCopiedModelsSetVal('customerEquipment.model.customer', this.args.customer);
        multiStepForm.formCopiedModelsSetVal('customerEquipment.model.user', this.stEquipmentSoldby);
        multiStepForm.formCopiedModelsSetVal('customerEquipment.model.equipment', this.stEquipmentToMatch);
        multiStepForm.formCopiedModelsSetVal('customerEquipment.model.equipmentStatus', this.stEquipmentInstallStatus);
        var toCreateObjects = yield multiStepForm.validateCreateAll({
          deepValidate: false,
          deepCreate: false,
          notificationNamespace: 'modal',
          extraValidationData: {
            noValidate: ['workOrderAction', 'zoneNumber', 'zone']
          }
        });
        var customerEquipment;

        try {
          customerEquipment = this.store.createRecord('customerEquipment', toCreateObjects.customerEquipment.data);
        } catch (err) {
          (false && Ember.warn('createRecord failed' + err, {
            id: 'match-equipment.matchSelectedEquipmentTask'
          }));
        }

        var newCustomerEquipment = yield toCreateObjects.customerEquipment.saveWithRollbackAndNotification(customerEquipment);
        stEquipmentItem = newCustomerEquipment; // Recheck selected items after save updates customerEquipment

        for (var i = 0; i < this.filteredCustomerEquipment.length; i++) {
          this.filteredCustomerEquipment[i].filteredEquipment.forEach(equipmentItem => {
            if (equipmentItem.carouselMatchId) {
              equipmentItem.set('isChecked', true);
            }
          });
        }
      }
    } else {
      // Matching ST to ADC
      stEquipmentItem = this.args.bulkEquipmentEditList[this.currentSlide];
      adcEquipmentItem = this.args.bulkEquipmentEditList[this.currentSlide].adcEquipmentToMatch;
    }

    try {
      // PERF TODO: Can we link and save at same time? If so yield hash
      yield stEquipmentItem.invokeAction('link_to_isp_equipment', {
        verb: 'POST',
        data: {
          data: {
            interactive_services_equipment_id: adcEquipmentItem.id
          }
        }
      }); // Set Zone name/ID to ADC device information

      stEquipmentItem.set('zone', adcEquipmentItem.deviceName);
      stEquipmentItem.set('zoneNumber', adcEquipmentItem.id);
      yield stEquipmentItem.save();
      buttonPromise.then(() => {
        this.matchedAtLeastOneEquipmentItem = true;
        stEquipmentItem.successfullyMatched = true;
        adcEquipmentItem.successfullyMatched = true;
        stEquipmentItem.adcMatchId = adcEquipmentItem.id;
        adcEquipmentItem.stMatchId = stEquipmentItem.id;

        if (this.currentSlide < (this.startingWithAdcEquipment ? this.adcEquipmentArray.length - 1 : this.args.bulkEquipmentEditList.length - 1)) {
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
          this.updateSlide();
        } else {
          multiStepForm.formStartedSetVal(false);
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'global');
          multiStepForm.onAfterSave();
        }
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
    }
  }), _dec15 = (0, _emberConcurrency.task)(function* (equipment) {
    yield;

    if (this.startingWithAdcEquipment) {
      if (!equipment.isChecked) {
        this.adcEquipmentArray[this.currentSlide].set('stEquipmentToMatch', equipment);
        equipment.set('carouselMatchId', this.adcEquipmentArray[this.currentSlide].id);
      } else {
        this.adcEquipmentArray[this.currentSlide].set('stEquipmentToMatch', null);
      }
    } else {
      if (!equipment.isChecked) {
        this.args.bulkEquipmentEditList[this.currentSlide].set('adcEquipmentToMatch', equipment);
        equipment.set('carouselMatchId', this.args.bulkEquipmentEditList[this.currentSlide].id);
      } else {
        this.args.bulkEquipmentEditList[this.currentSlide].set('adcEquipmentToMatch', null);
      }
    }

    var isChecked = equipment.get('isChecked') ? false : true;

    if (isChecked) {
      equipment.set('isChecked', true);
    } else {
      equipment.set('isChecked', false);
    }

    if (this.startingWithAdcEquipment) {
      var groupedEquipment = this.filteredCustomerEquipment;
      groupedEquipment.forEach(group => {
        this.setToFalse(group, equipment);
      });
    } else {
      this.adcEquipmentArray.forEach(group => {
        this.setToFalse(group, equipment);
      });
    }

    this.renderButtons();
  }), _dec16 = (0, _emberConcurrency.task)(function* (direction) {
    var equipmentGroups;
    var matchVar;
    var matchedIdVar;

    if (this.startingWithAdcEquipment) {
      var groupedItems = yield this.args.groupedItems;
      equipmentGroups = groupedItems.customerEquipment;
      matchVar = 'stEquipmentToMatch';
      matchedIdVar = 'stMatchId';

      if (!this.adcEquipmentArray[this.currentSlide].successfullyMatched) {
        this.adcEquipmentArray[this.currentSlide].stEquipmentToMatch = null;
      }
    } else {
      equipmentGroups = this.adcEquipmentArray;
      matchVar = 'adcEquipmentToMatch';
      matchedIdVar = 'adcMatchId';

      if (!this.args.bulkEquipmentEditList[this.currentSlide].successfullyMatched) {
        this.args.bulkEquipmentEditList[this.currentSlide].adcEquipmentToMatch = null;
      }
    }

    equipmentGroups.forEach(group => {
      for (var i = 0; i < group.items.length; i++) {
        // if user changes an already saved match but then uses the slide arrows without saving, the already saved match will override the unsaved match to keep UI accurate
        var indexDiff = direction === 'next' ? -1 : 1;

        if (direction && (this.startingWithAdcEquipment ? this.adcEquipmentArray[this.currentSlide + indexDiff] : this.args.bulkEquipmentEditList[this.currentSlide + indexDiff]).selectedMatchChoice !== 2) {
          if (group.items[i].id === _lodash.default.get(this.startingWithAdcEquipment ? this.adcEquipmentArray[this.currentSlide + indexDiff] : this.args.bulkEquipmentEditList[this.currentSlide + indexDiff], matchedIdVar, undefined)) {
            group.items[i].set('isChecked', true);
            (this.startingWithAdcEquipment ? this.adcEquipmentArray[this.currentSlide + indexDiff] : this.args.bulkEquipmentEditList[this.currentSlide + indexDiff]).set(matchVar, group.items[i]);
          }
        }

        if (group.items[i].id != _lodash.default.get(this.startingWithAdcEquipment ? this.adcEquipmentArray[this.currentSlide] : this.args.bulkEquipmentEditList[this.currentSlide], "".concat(matchVar, ".id"), undefined) && group.items[i].isChecked === true && group.items[i].successfullyMatched) {
          group.items[i].set('disabledSelect', true);
        } else {
          group.items[i].set('disabledSelect', false);

          if (!group.items[i].successfullyMatched) {
            group.items[i].set('isChecked', false);
          }
        }
      }
    });
    this.renderButtons();
  }), _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = (0, _emberConcurrency.task)(function* (field) {
    if (field === 'stEquipmentSoldby') {
      var userModel = yield this.SetUser._getUser(this[field]);
      this[field] = userModel;
    }

    this.adcEquipmentArray[this.currentSlide].set(field, this[field]);

    if (field === 'selectedMatchChoice') {
      this.adcEquipmentArray[this.currentSlide].set('stEquipmentToMatch', null);
      this.adcEquipmentArray[this.currentSlide].set('stEquipmentSoldby', null);
      this.adcEquipmentArray[this.currentSlide].set('stEquipmentInstallStatus', null);
      this.stEquipmentToMatch = null;
      this.stEquipmentSoldby = null;
      this.stEquipmentInstallStatus = null;
      this.filteredCustomerEquipment.forEach(group => {
        for (var i = 0; i < group.filteredEquipment.length; i++) {
          if (group.filteredEquipment[i].carouselMatchId && group.filteredEquipment[i].carouselMatchId === this.adcEquipmentArray[this.currentSlide].id) {
            group.filteredEquipment[i].set('carouselMatchId', null);
            group.filteredEquipment[i].set('isChecked', false);
          }
        }
      });
      this.renderButtons();
    }
  }), (_class = (_temp = class MatchEquipment extends _component.default {
    //updates on carousel change
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "screen", _descriptor2, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _defineProperty(this, "startingWithAdcEquipment", undefined);

      _defineProperty(this, "matchedAtLeastOneEquipmentItem", false);

      _defineProperty(this, "showNotification", true);

      _defineProperty(this, "adcEquipmentArray", []);

      _initializerDefineProperty(this, "scrollClass", _descriptor5, this);

      _initializerDefineProperty(this, "currentSlide", _descriptor6, this);

      _initializerDefineProperty(this, "selectedMatchChoice", _descriptor7, this);

      _initializerDefineProperty(this, "stEquipmentToMatch", _descriptor8, this);

      _initializerDefineProperty(this, "stEquipmentSoldby", _descriptor9, this);

      _initializerDefineProperty(this, "stEquipmentInstallStatus", _descriptor10, this);

      _initializerDefineProperty(this, "filteredCustomerEquipment", _descriptor11, this);

      _initializerDefineProperty(this, "soldByList", _descriptor12, this);

      _initializerDefineProperty(this, "initTask", _descriptor13, this);

      _initializerDefineProperty(this, "matchSelectedEquipment", _descriptor14, this);

      _initializerDefineProperty(this, "addToMatchList", _descriptor15, this);

      _initializerDefineProperty(this, "updateAdcEquipmentOptions", _descriptor16, this);

      _initializerDefineProperty(this, "updateCurrentMatchedEquipment", _descriptor17, this);

      if (this.args.bulkEquipmentEditList.length > 0) {
        this.startingWithAdcEquipment = false;
        this.args.bulkEquipmentEditList.forEach(equipment => {
          equipment.adcEquipmentToMatch = null;
        });
      } else {
        this.startingWithAdcEquipment = true;
        this.args.bulkEquipmentEditList.forEach(equipment => {
          equipment.adcEquipmentToMatch = null;
        });
      }

      this.SetUser = new _setUser.default(...arguments);
      this.initTask.perform();
    }

    get emptyCustomerEquipmentList() {
      var emptyList = true;
      this.filteredCustomerEquipment.forEach(group => {
        if (group.filteredEquipment.length > 0) {
          emptyList = false;
        }
      });
      return emptyList;
    }

    get carouselConfig() {
      var lengthGreaterThanOne = this.startingWithAdcEquipment ? this.adcEquipmentArray.length > 1 : this.args.bulkEquipmentEditList.length > 1;
      return {
        pagination: lengthGreaterThanOne,
        navigation: lengthGreaterThanOne,
        noSwiping: true,
        noSwipingClass: 'swiper-container'
      };
    }

    get matchOrAddChoices() {
      return [{
        packageName: this.intl.t('pages.customers.equipment.matchToSt'),
        packageId: 1
      }, {
        packageName: this.intl.t('pages.customers.equipment.createAndMatch'),
        packageId: 2
      }];
    }

    setToFalse(group, togglingEquipmentPiece) {
      group.items.forEach(item => {
        if (this.startingWithAdcEquipment) {
          if (item.carouselMatchId === this.adcEquipmentArray[this.currentSlide].id && item !== togglingEquipmentPiece) {
            item.set('isChecked', false);
          }
        } else {
          if (item.carouselMatchId === this.args.bulkEquipmentEditList[this.currentSlide].id && item !== togglingEquipmentPiece) {
            item.set('isChecked', false);
          }
        }
      });
    }

    updateDropdowns() {
      this.stEquipmentToMatch = this.adcEquipmentArray[this.currentSlide].stEquipmentToMatch;
      this.stEquipmentSoldby = this.adcEquipmentArray[this.currentSlide].stEquipmentSoldby;
      this.stEquipmentInstallStatus = this.adcEquipmentArray[this.currentSlide].stEquipmentInstallStatus;
      this.selectedMatchChoice = this.adcEquipmentArray[this.currentSlide].selectedMatchChoice;
    }

    _clickCallback(direction) {
      if (this.startingWithAdcEquipment) {
        this.updateDropdowns();
      }

      this.updateAdcEquipmentOptions.perform(direction);
    }

    _scrollCallback() {
      var scrollWrapper = document.getElementsByClassName('st-modal__body-content')[0];

      if (scrollWrapper.scrollTop > 10) {
        this.scrollClass = 'scroll';
      } else {
        this.scrollClass = '';
      }
    }

    willDestroy() {
      if (this.matchedAtLeastOneEquipmentItem) {
        this.args.multiStepForm.onAfterSave();
      }

      if (document.getElementsByClassName('swiper-button-prev')[0]) {
        document.getElementsByClassName('swiper-button-prev')[0].removeEventListener('click', this._clickCallback);
      }

      if (document.getElementsByClassName('swiper-button-next')[0]) {
        document.getElementsByClassName('swiper-button-next')[0].removeEventListener('click', this._clickCallback);
      }

      document.getElementsByClassName('st-modal__body-content')[0].removeEventListener('scroll', this._scrollCallback);
    }

    addEventToArrows() {
      document.getElementsByClassName('swiper-button-prev')[0].addEventListener('click', this._clickCallback.bind(this, 'prev'));
      document.getElementsByClassName('swiper-button-next')[0].addEventListener('click', this._clickCallback.bind(this, 'next'));
    }

    addScrollEventListener() {
      document.getElementsByClassName('st-modal__body-content')[0].addEventListener('scroll', this._scrollCallback.bind(this));
    }

    renderButtons() {
      var buttons = [];
      var disabledButton;

      if (this.startingWithAdcEquipment) {
        disabledButton = this.adcEquipmentArray.length === 0 || !this.adcEquipmentArray[this.currentSlide].stEquipmentToMatch && this.selectedMatchChoice === 1;
      } else {
        disabledButton = !this.args.bulkEquipmentEditList[this.currentSlide].adcEquipmentToMatch;
      }

      buttons.push({
        text: this.intl.t('pages.customers.equipment.matchSelected'),
        click: buttonPromise => {
          return this.matchSelectedEquipment.perform(this.args.multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary match-save-button',
        disabled: disabledButton
      });
      this.args.multiStepForm.registerStepData('equipmentMatch', 'buttons', buttons);
    }

    updateSlide() {
      this.currentSlide = this.currentSlide + 1;

      if (this.startingWithAdcEquipment) {
        this.updateDropdowns();
      }

      this.updateAdcEquipmentOptions.perform();
      this.renderButtons();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "screen", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "scrollClass", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "currentSlide", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "selectedMatchChoice", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "stEquipmentToMatch", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "stEquipmentSoldby", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "stEquipmentInstallStatus", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "filteredCustomerEquipment", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "soldByList", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "initTask", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "matchSelectedEquipment", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "addToMatchList", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "updateAdcEquipmentOptions", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "addEventToArrows", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "addEventToArrows"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addScrollEventListener", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "addScrollEventListener"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "renderButtons", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "renderButtons"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateSlide", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "updateSlide"), _class.prototype), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "updateCurrentMatchedEquipment", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = MatchEquipment;
});