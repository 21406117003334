define("securitytrax-ember-ui/leads/messages/create-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3ZYxR4J2",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modals/sub-route-modals/edit-create-message\",[],[[\"@customer\",\"@messageId\",\"@parentRoute\",\"@transitionId\"],[[34,0,[\"lead\"]],[34,0,[\"messageId\"]],[34,0,[\"parentRoute\"]],[34,0,[\"transitionId\"]]]],null]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/leads/messages/create-message/template.hbs"
    }
  });

  _exports.default = _default;
});