define("securitytrax-ember-ui/helpers/is-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.inArr = inArr;
  _exports.default = void 0;

  function inArr(params) {
    var obj = params[0];

    if (typeof obj != 'object') {
      return false;
    }

    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }

    return true;
  }

  var _default = Ember.Helper.helper(inArr);

  _exports.default = _default;
});