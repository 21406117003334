define("securitytrax-ember-ui/components/multi-step-form/forms/system/guardian/general/component", ["exports", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "@glimmer/component", "ember-concurrency", "securitytrax-ember-ui/mixed-in-classes/customer-integrations"], function (_exports, _switchableFormStep, _component, _emberConcurrency, _customerIntegrations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var ManageGuardianIntegration = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._tracked, _dec21 = Ember._tracked, _dec22 = Ember._tracked, _dec23 = Ember._tracked, _dec24 = Ember._tracked, _dec25 = Ember._tracked, _dec26 = Ember._tracked, _dec27 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
    this.customerZones = yield this.getCustomerZonesTask.perform();

    if (this.args.formMode !== 'create') {
      this.customerInformation = yield this.getCustomerInformation.perform();
      this.siteNumber = this.customerInformation ? this.customerInformation.site_number : null;
      this.systemNumber = this.customerInformation && this.customerInformation.systems && this.customerInformation.systems.length > 0 ? this.customerInformation.systems[0].system_number : null;
    } else {
      this.ownershipTypes = yield this.getOwnershipTypes.perform();
      this.saleTypes = yield this.getSaleTypes.perform();
      this.locations = yield this.getLocations.perform();
      this.systemTypes = yield this.getSystemTypes.perform();
      this.serviceTypes = yield this.getServiceTypes.perform();
    }
  }), _dec28 = (0, _emberConcurrency.task)(function* () {
    return yield this.centralStationService.getData({
      model: this.args.monitoringCompany,
      method: 'guardian_protection_services__getOwnershipTypes',
      responseKey: 'ownership_types'
    });
  }), _dec29 = (0, _emberConcurrency.task)(function* () {
    return yield this.centralStationService.getData({
      model: this.args.monitoringCompany,
      method: 'guardian_protection_services__getSaleTypes',
      responseKey: 'sale_types'
    });
  }), _dec30 = (0, _emberConcurrency.task)(function* () {
    return yield this.centralStationService.getData({
      model: this.args.monitoringCompany,
      method: 'guardian_protection_services__getLocations',
      responseKey: 'locations'
    });
  }), _dec31 = (0, _emberConcurrency.task)(function* () {
    return yield this.centralStationService.getData({
      model: this.args.monitoringCompany,
      method: 'guardian_protection_services__getSiteSystemTypes',
      responseKey: 'site_system_types'
    });
  }), _dec32 = (0, _emberConcurrency.task)(function* () {
    return yield this.centralStationService.getData({
      model: this.args.monitoringCompany,
      method: 'guardian_protection_services__getServiceTypes',
      responseKey: 'service_types'
    });
  }), _dec33 = (0, _emberConcurrency.task)(function* () {
    return yield this.centralStationService.getData({
      model: this.args.customer,
      method: 'guardian_protection_services__getSite',
      responseKey: 'site'
    });
  }), _dec34 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    if (this.setupAutoPay && this.autoPayAmount > 0.00) {
      try {
        yield this.centralStationService.getData({
          model: this.args.customer,
          method: 'guardian_protection_services__setUpAutoPay',
          payload: {
            amount: this.autoPayAmount
          }
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'setupAutoPayModal'
        });
        throw err;
      }
    } else {
      this.stNotificationManager.addError(this.intl.t('pages.customers.system.setup_auto_pay_required'), 'setupAutoPayModal');
      throw new Error();
    }

    buttonPromise.then(() => {
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
      this.setupAutoPayModalOpen = false;
    });
  }), _dec35 = (0, _emberConcurrency.task)(function* () {
    if (this.args.formMode === 'create') {
      var customerEquipment = yield this.args.customer.get('customerEquipment');
      var zones = yield this.centralStationService.getData({
        model: this.args.customer,
        method: 'guardian_protection_services__getSyncableCustomerEquipmentIds',
        responseKey: 'syncable_customer_equipment_ids'
      });
      var zoneIds = zones.map(zone => {
        return zone.id;
      });
      return customerEquipment.filter(ce => {
        ce.set('zone_id', ce.get('zoneNumber'));
        ce.set('zone_comment', ce.get('zone'));
        return zoneIds.includes(ce.get('id'));
      });
    } else {
      var info = this.customerInformation;
      return info && info.systems && info.systems[0] ? info.systems[0].zones : [];
    }
  }), _dec36 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    if (this.SwitchableFormStep.isNewRecord) {
      var formattedObjects = Ember.A();
      formattedObjects.pushObject(Ember.Object.create({
        model: {
          ownershipType: this.ownershipType,
          saleType: this.saleType,
          location: this.location,
          systemType: this.systemType,
          serviceType: this.serviceType,
          amount: this.amount
        },
        filePath: 'central-station/guardian-create'
      }));
      yield multiStepForm.validateList.perform(formattedObjects);

      try {
        yield this.centralStationService.postData({
          model: this.args.customer,
          method: 'guardian_protection_services__saveAccount',
          payload: {
            ownership_type: {
              id: this.ownershipType
            },
            sale_type: {
              id: this.saleType
            },
            location: {
              id: this.location
            },
            site_system_type: {
              id: this.systemType
            },
            service_type: {
              id: this.serviceType,
              price: this.amount
            }
          }
        });
        var customerInformation = yield this.customerInformation;
        this.siteNumber = customerInformation ? customerInformation.site_number : null;
        this.systemNumber = customerInformation && customerInformation.systems && customerInformation.systems.length > 0 ? customerInformation.systems[0].system_number : null;

        if (this.args.afterCreate && typeof this.args.afterCreate === 'function') {
          this.args.afterCreate();
          this.initFormTask.perform();
        }
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, 'modal');
        throw err;
      }
    }

    yield multiStepForm.validateSyncSaveAll({
      notificationNamespace: 'modal',
      extraValidationData: {
        noValidate: ['location']
      }
    });
    buttonPromise.then(() => {
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
      this.SwitchableFormStep.progressBarHidden = this.args.progressBarHidden || false;
      this.registerButtons();
      this.store.findRecord('customerSystemInformation', this.args.customer.get('id')).then(systemInfo => {
        systemInfo.reload();
      });
    });
  }), _dec37 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    try {
      yield this.centralStationService.postData({
        model: this.args.customer,
        method: 'guardian_protection_services__monitoringModificationComplete',
        payload: {}
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'monitoringModificationCompleteModal'
      });
      throw err;
    }

    buttonPromise.then(() => {
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
      this.showMonitoringModificationCompleteModal = false;
    });
  }), _dec38 = Ember._action, _dec39 = Ember._action, (_class = (_temp = class ManageGuardianIntegration extends _component.default {
    // Auto Pay attributes
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "centralStationService", _descriptor4, this);

      _initializerDefineProperty(this, "showUnlinkConfirmModal", _descriptor5, this);

      _initializerDefineProperty(this, "showMonitoringModificationCompleteModal", _descriptor6, this);

      _initializerDefineProperty(this, "addAgencyFormOpen", _descriptor7, this);

      _initializerDefineProperty(this, "addAgencyButtons", _descriptor8, this);

      _initializerDefineProperty(this, "siteNumber", _descriptor9, this);

      _initializerDefineProperty(this, "systemNumber", _descriptor10, this);

      _initializerDefineProperty(this, "ownershipType", _descriptor11, this);

      _initializerDefineProperty(this, "saleType", _descriptor12, this);

      _initializerDefineProperty(this, "location", _descriptor13, this);

      _initializerDefineProperty(this, "siteSystemType", _descriptor14, this);

      _initializerDefineProperty(this, "serviceType", _descriptor15, this);

      _initializerDefineProperty(this, "customerZones", _descriptor16, this);

      _initializerDefineProperty(this, "ownershipTypes", _descriptor17, this);

      _initializerDefineProperty(this, "saleTypes", _descriptor18, this);

      _initializerDefineProperty(this, "locations", _descriptor19, this);

      _initializerDefineProperty(this, "systemTypes", _descriptor20, this);

      _initializerDefineProperty(this, "serviceTypes", _descriptor21, this);

      _initializerDefineProperty(this, "customerInformation", _descriptor22, this);

      _initializerDefineProperty(this, "amount", _descriptor23, this);

      _initializerDefineProperty(this, "setupAutoPayModalOpen", _descriptor24, this);

      _initializerDefineProperty(this, "setupAutoPay", _descriptor25, this);

      _initializerDefineProperty(this, "autoPayAmount", _descriptor26, this);

      _initializerDefineProperty(this, "initFormTask", _descriptor27, this);

      _initializerDefineProperty(this, "getOwnershipTypes", _descriptor28, this);

      _initializerDefineProperty(this, "getSaleTypes", _descriptor29, this);

      _initializerDefineProperty(this, "getLocations", _descriptor30, this);

      _initializerDefineProperty(this, "getSystemTypes", _descriptor31, this);

      _initializerDefineProperty(this, "getServiceTypes", _descriptor32, this);

      _initializerDefineProperty(this, "getCustomerInformation", _descriptor33, this);

      _initializerDefineProperty(this, "setupAutoPayTask", _descriptor34, this);

      _initializerDefineProperty(this, "getCustomerZonesTask", _descriptor35, this);

      _initializerDefineProperty(this, "saveAccountTask", _descriptor36, this);

      _initializerDefineProperty(this, "monitoringModificationCompleteTask", _descriptor37, this);

      this.disabledProgressBarSteps = [];
      this.hiddenProgressBarSteps = [];
      this.progressBarHidden = this.args.formMode === 'create' ? true : false;
      this.SwitchableFormStep = new _switchableFormStep.default(...arguments, {
        progressBarHidden: this.progressBarHidden,
        disabledProgressBarSteps: this.disabledProgressBarSteps,
        hiddenProgressBarSteps: this.hiddenProgressBarSteps
      });
      this.CustomerIntegrations = new _customerIntegrations.default(...arguments);
      this.initFormTask.perform();
      Ember.run.later(() => {
        this.registerButtons();
      });
    }

    get customerAgencies() {
      var customerInformation = this.customerInformation;
      return customerInformation && customerInformation.dispatch_agencies ? customerInformation.dispatch_agencies : [];
    }

    get setupAutoPayButtons() {
      return [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.setupAutoPayTask.perform(buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary setup-auto-pay-save-button'
      }];
    }

    registerButtons() {
      var multiStepForm = this.args.multiStepForm;
      multiStepForm.registerStepData('general', 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.saveAccountTask.perform(multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary guardian-save-account-button'
      }]);
    }

    updateAgencies() {
      this.addAgencyFormOpen = false;
      this.initFormTask.perform();
    }

    buttons(buttons) {
      this.addAgencyButtons = buttons;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showUnlinkConfirmModal", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "showMonitoringModificationCompleteModal", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "addAgencyFormOpen", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "addAgencyButtons", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "siteNumber", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "systemNumber", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "ownershipType", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "saleType", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "location", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "siteSystemType", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "serviceType", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "customerZones", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "ownershipTypes", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "saleTypes", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "locations", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "systemTypes", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "serviceTypes", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "customerInformation", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "amount", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0.00;
    }
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "setupAutoPayModalOpen", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "setupAutoPay", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "autoPayAmount", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0.00;
    }
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "initFormTask", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "getOwnershipTypes", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "getSaleTypes", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "getLocations", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "getSystemTypes", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "getServiceTypes", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "getCustomerInformation", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "setupAutoPayTask", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "getCustomerZonesTask", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor36 = _applyDecoratedDescriptor(_class.prototype, "saveAccountTask", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor37 = _applyDecoratedDescriptor(_class.prototype, "monitoringModificationCompleteTask", [_dec37], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateAgencies", [_dec38], Object.getOwnPropertyDescriptor(_class.prototype, "updateAgencies"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buttons", [_dec39], Object.getOwnPropertyDescriptor(_class.prototype, "buttons"), _class.prototype)), _class));
  _exports.default = ManageGuardianIntegration;
});