define("securitytrax-ember-ui/components/pages/users/human-resources/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var UserHumanResources = (_dec = Ember.inject.service, (_class = (_temp = class UserHumanResources extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "grants", _descriptor, this);
    }

    get userHrFormSteps() {
      return Ember.Object.create({
        humanResources: Ember.Object.create({
          stepNameKey: 'humanResources',
          stepComponentPath: 'multi-step-form/forms/administration/users/human-resources'
        })
      });
    }

    get userHrFormOriginalModels() {
      var grants = this.grants;
      return Ember.Object.create({
        user: {
          model: this.args.model.user,
          copyRelationMap: {
            userHrSetting: {
              doCopy: grants.getIndividualGrant('user_hr_settings.view'),
              doValidate: grants.getIndividualGrant('user_hr_settings.view'),
              doSave: grants.getIndividualGrant('user_hr_settings.modify'),
              relationships: {
                recruitedByUser: {
                  doValidate: false,
                  doSave: false
                },
                userHierarchyTitle: {
                  doValidate: false,
                  doSave: false
                }
              }
            }
          }
        }
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = UserHumanResources;
});