define("securitytrax-ember-ui/services/st-logger", ["exports", "moment", "ember-concurrency", "securitytrax-ember-ui/config/environment"], function (_exports, _moment, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    sessionData: Ember.inject.service('sessionData'),
    userId: null,
    companyId: null,
    logsArray: null,

    init() {
      this._super(...arguments);

      this.set('logsArray', []);
    },

    log(message) {
      this.logInfoTask.perform(message);
    },

    logInfoTask: (0, _emberConcurrency.task)(function* (message) {
      var sessionData = this.sessionData;
      var user = yield sessionData.getUser();
      var company = yield sessionData.getCompany();

      if (typeof message === 'string') {
        message = {
          message
        };
      }

      this.set('userId', user.get('id'));
      this.set('companyId', company.get('id'));
      message.timestamp = (0, _moment.default)().unix();

      if (!message.channel) {
        message.channel = 'none';
      }

      if (!message.level) {
        message.level = 'none';
      } //Dont override any existing session data if included


      if (!message.session) {
        message.session = {
          user: this.userId,
          company: this.companyId
        };
      }

      var tempArray = this.logsArray;

      if (this.logsArray.length < _environment.default['securitytrax-ember-ui']['st-logger-limit']) {
        tempArray.push(message);
        this.set('logsArray', tempArray);
      } else {
        tempArray.shift();
        tempArray.push(message);
        this.set('logsArray', tempArray);
      }
    })
  });

  _exports.default = _default;
});