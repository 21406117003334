define("securitytrax-ember-ui/demo/form-progress-bar/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller.extend({
    formSteps: Ember.computed(function () {
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: 'Step # 1',
          stepComponentPath: 'multi-step-form/forms/demo/demo-progress-bar/step-1'
        }),
        step2: Ember.Object.create({
          stepNameKey: 'step2',
          title: 'Step # 2',
          stepComponentPath: 'multi-step-form/forms/demo/demo-progress-bar/step-2'
        }),
        step3: Ember.Object.create({
          stepNameKey: 'step3',
          title: 'Step # 3',
          stepComponentPath: 'multi-step-form/forms/demo/demo-progress-bar/step-3'
        }),
        step4: Ember.Object.create({
          stepNameKey: 'step4',
          title: 'Step # 4',
          stepComponentPath: 'multi-step-form/forms/demo/demo-progress-bar/step-4'
        })
      });
    }),
    formStarted: false,
    actions: {
      startMultiStepForm(view) {
        this.set('formStarted', true);
        this.set('progressBarTemplate', view);
      }

    }
  });
  var _default = Controller;
  _exports.default = _default;
});