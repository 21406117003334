define("securitytrax-ember-ui/validations/inventory/equipment-purchase-order", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateObject = {
        location: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('equipmentPurchaseOrder-location')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'equipmentPurchaseOrder-location');
            }
          }
        },
        user: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('equipmentPurchaseOrder-user')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'equipmentPurchaseOrder-user');
            }
          }
        },
        equipmentVendor: {
          presence: this.customMessageOrDefault(data, 'equipmentPurchaseOrder-equipmentVendor')
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject, {
        fullMessages: this.prependProperty(data)
      });

      if (validationData && validationData['location']) {
        validationData['location'] = validationData['location'].map(message => {
          return message.replace(/Location/, 'Recipient');
        });
      }

      if (validationData && validationData['user']) {
        validationData['user'] = validationData['user'].map(message => {
          return message.replace(/User/, 'Recipient');
        });
      }

      return this.outputFormatting(validationData, 'equipmentPurchaseOrder');
    }

  });

  _exports.default = _default;
});