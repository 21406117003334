define("securitytrax-ember-ui/models/base_models/pandadoc-document", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    name: (0, _model.attr)('string', {
      'readOnly': false
    }),
    autonumberingSequenceNamePrefix: (0, _model.attr)('string', {
      'readOnly': false
    }),
    status: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['uploaded', 'draft', 'sent', 'viewed', 'waitingApproval', 'rejected', 'approved', 'waitingPay', 'paid', 'completed', 'voided', 'declined']
    }),
    version: (0, _model.attr)('string', {
      'readOnly': false
    }),
    contentPlaceholders: (0, _model.attr)('json', {
      'readOnly': false
    }),
    createdBy: (0, _model.attr)('json', {
      'readOnly': false
    }),
    fields: (0, _model.attr)('json', {
      'readOnly': false
    }),
    grandTotal: (0, _model.attr)('json', {
      'readOnly': false
    }),
    images: (0, _model.attr)('json', {
      'readOnly': false
    }),
    recipients: (0, _model.attr)('json', {
      'readOnly': false
    }),
    roles: (0, _model.attr)('json', {
      'readOnly': false
    }),
    pricing: (0, _model.attr)('json', {
      'readOnly': false
    }),
    tokens: (0, _model.attr)('json', {
      'readOnly': false
    }),
    sentBy: (0, _model.attr)('json', {
      'readOnly': false
    }),
    tags: (0, _model.attr)('json', {
      'readOnly': false
    }),
    template: (0, _model.attr)('json', {
      'readOnly': false
    }),
    dateCreated: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    dateCompleted: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    dateModified: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    expirationDate: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    customer: (0, _model.belongsTo)('customer', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    electronicDocumentEnvelope: (0, _model.belongsTo)('electronic-document-envelope', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    electronicDocumentProvider: (0, _model.belongsTo)('electronic-document-provider', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    pandadocTemplate: (0, _model.belongsTo)('pandadoc-template', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});