define("securitytrax-ember-ui/components/html/svg-components/simple-circles/component", ["exports", "d3-selection", "d3-scale", "d3-shape", "d3-collection", "jquery"], function (_exports, _d3Selection, _d3Scale, _d3Shape, _d3Collection, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* import { run } from '@ember/runloop'
  import { get } from '@ember/object' */
  // Import the D3 packages we want to use

  /* import { extent, ascending } from 'd3-array'
  import { transition } from 'd3-transition'
  import { easeCubicInOut } from 'd3-ease' */
  var _default = Ember.Component.extend({
    // Array of points to render as circles in a line, spaced by time.
    //  [ {value: Number, timestamp: Number } ];
    init() {
      this._super();
    },

    didReceiveAttrs() {},

    didInsertElement() {
      // set the dimensions and margins of the graph
      var width = 450;
      var height = 450;
      var margin = 40; // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.

      var radius = Math.min(width, height) / 2 - margin; // append the svg object to the div called 'my_dataviz'

      var svg = (0, _d3Selection.select)((0, _jquery.default)('#donut')[0]);
      var group = svg.attr('width', width).attr('height', height).append('g').attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')'); // Create dummy data

      var data = {
        a: 9,
        b: 10,
        c: 40,
        d: 15,
        e: 12
      }; // set the color scale

      var color = (0, _d3Scale.scaleOrdinal)().domain(data).range(['#D92C29', '#EC720B', '#FFCD00', '#23AB3F', '#2071DD']); // Compute the position of each group on the pie:

      var apie = (0, _d3Shape.pie)().value(function (d) {
        return d.value;
      });
      var data_ready = apie((0, _d3Collection.entries)(data)); // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.

      group.selectAll('whatever').data(data_ready).enter().append('path').attr('d', (0, _d3Shape.arc)().innerRadius(150) // This is the size of the donut hole
      .outerRadius(radius).cornerRadius(20)).attr('fill', function (d) {
        return color(d.data.key);
      }).attr('stroke', 'white').style('stroke-width', '2px').style('opacity', 0.7);
    }

  });

  _exports.default = _default;
});