define("securitytrax-ember-ui/components/multi-step-form/switchable-inputs/masked-input/component", ["exports", "securitytrax-ember-ui/mixins/switchable-input"], function (_exports, _switchableInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableInput.default, {});

  _exports.default = _default;
});