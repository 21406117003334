define("securitytrax-ember-ui/components/lists/sortable-column/chevron/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['direction-indicator'],
    classNameBindings: ['sortingOn:sorting-on', 'chevronDirectionIsUp:asc:desc'],
    chevronDirectionIsUp: Ember.computed('chevronDirection', function () {
      if (this.chevronDirection == 'up') {
        return true;
      }

      return false;
    }),
    sortingOn: Ember.computed('ascending', 'currentSortColumn', 'property', 'chevronDirection', function () {
      var sortingOnCurrentProperty = this.currentSortColumn == this.property;
      var sortDirectionMatches = this.ascending && this.chevronDirection == 'up' || !this.ascending && this.chevronDirection == 'down';

      if (this.ascending != null && sortingOnCurrentProperty && sortDirectionMatches) {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});