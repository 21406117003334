define("securitytrax-ember-ui/models/user-license-transaction", ["exports", "securitytrax-ember-ui/models/base_models/user-license-transaction"], function (_exports, _userLicenseTransaction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _userLicenseTransaction.default.extend({});

  _exports.default = _default;
});