define("securitytrax-ember-ui/components/multi-step-form/forms/demo/demo-form-1/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "securitytrax-ember-ui/utils/obj-helpers", "ember-concurrency"], function (_exports, _switchableFormStep, _objHelpers, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        accountTypes: [{
          id: 'Residential',
          name: 'Residential'
        }, {
          id: 'Commercial',
          name: 'Commercial'
        }]
      });
    },

    didInsertElement() {
      this._super(...arguments); // const multiStepForm = this.multiStepForm;
      // can do stuff with the data from the multistep form here

    },

    checkboxOptions: Ember.computed(function () {
      var intl = this.intl;
      return {
        on_label: intl.t('common.status.on'),
        off_label: intl.t('common.status.off')
      };
    }),
    accountTypes: undefined,
    selectedAccountType: Ember.computed('multiStepForm.formCopiedModels.customer.model.accountType', function () {
      var acct = this.multiStepForm.formCopiedModelsGetVal('customer.model.accountType');
      return Ember.Object.create({
        id: acct,
        name: acct
      });
    }),
    isCommercialCustomer: Ember.computed('multiStepForm.formCopiedModels.customer.model.accountType', function () {
      return Ember.isEqual(this.multiStepForm.formCopiedModelsGetVal('customer.model.accountType'), 'Commercial');
    }),
    additionalClickActions: Ember.computed(function () {
      return [{
        label: 'Save and Succeed',
        action: buttonPromise => {
          return this.submitDemoFormTask.perform(buttonPromise);
        },
        classNamesString: 'demo-dropdown-button-one'
      }, {
        label: 'Save and Fail Deepsave ',
        action: buttonPromise => {
          return this.submitDemoFormTask.perform(buttonPromise, true);
        },
        classNamesString: 'demo-dropdown-button-force-save-fail'
      }];
    }),
    submitDemoFormTask: (0, _emberConcurrency.task)(function* (buttonPromise, forceFail = false) {
      var multiStepForm = this.multiStepForm;

      if (forceFail) {
        /////////////////////////////////////////////
        // force a failure on this request
        multiStepForm.formCopiedModelsSetVal('customer.model.globalRequestMeta', {
          'forceRequestResponse': {
            httpCode: 422,
            // responseHeaders: {}, // not needed for this, but is an option
            responseBody: {
              errors: [{
                status: '422',
                detail: 'Bad thing #1'
              }, {
                status: '422',
                detail: 'Bad thing #2'
              }]
            }
          }
        });
        multiStepForm.formCopiedModelsSetVal('customer.model.customerSystemInformation.globalRequestMeta', {
          'forceRequestResponse': {
            httpCode: 422,
            // responseHeaders: {}, // not needed for this, but is an option
            responseBody: {
              errors: [{
                status: '422',
                detail: 'Bad thing #3'
              }, {
                status: '422',
                detail: 'Bad thing #4'
              }]
            }
          }
        });
      } else {
        multiStepForm.formCopiedModelsSetVal('customer.model.globalRequestMeta', null);
        multiStepForm.formCopiedModelsSetVal('customer.model.customerSystemInformation.globalRequestMeta', null);
      }

      yield multiStepForm.validateSyncSaveAll({
        extraValidationData: {
          noValidate: ['customer-zip']
        }
      });
      buttonPromise.then(() => {
        this.send('changeFormMode', 'view');
      });
    }),
    actions: {
      submit(buttonPromise) {
        return this.submitDemoFormTask.perform(buttonPromise);
      },

      setAccountType(acctType) {
        this.multiStepForm.formCopiedModelsSetVal('customer.model.accountType', acctType);
      },

      cancel() {
        this.multiStepForm.setupCopyModels.perform(); //refresh copied models instead of rolling everything back

        this.multiStepForm.validationErrors = {};
        this.send('changeFormMode', 'view');
      },

      inlineEditMode() {
        this.send('changeFormMode', this.formMode == 'inlineEdit' ? 'view' : 'inlineEdit');
      },

      inlineEditSave(modelKeyName) {
        //for inline edits, validations happen inside inline edit
        this.multiStepForm.deepSaveOriginalModelByKeyName.perform(modelKeyName);
      }

    }
  });

  _exports.default = _default;
});