define("securitytrax-ember-ui/mixins/forms/duration-options", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This is mixin is used in both the accounting customerbilling edit and the invoice customer billing create
   *
   * @mixin ListMixin
   * @extends Ember.Mixin
   */
  var _default = Ember.Mixin.create({
    durationOptions: Ember.computed(function () {
      var durations = [];

      var halfHour = _moment.default.duration(30, 'minutes');

      for (var i = 0; i < 27; i++) {
        var hour = halfHour.hours();
        var minutes = halfHour.minutes();
        durations.push(Ember.Object.create({
          name: "".concat(hour, " hour ").concat(minutes, " minutes"),
          value: Number.parseInt(halfHour.as('seconds'))
        }));
        halfHour.add(30, 'minutes');
      }

      return durations;
    }),
    wholeHourDurationOptions: Ember.computed(function () {
      var hourName = ' ' + this.intl.t('pages.administration.organization.officeLocations.hours');
      var singleHourName = ' ' + this.intl.t('pages.administration.organization.officeLocations.hour');
      return [Ember.Object.create({
        name: '0 ' + hourName,
        value: '00'
      }), Ember.Object.create({
        name: '1 ' + singleHourName,
        value: '01'
      }), Ember.Object.create({
        name: '2 ' + hourName,
        value: '02'
      }), Ember.Object.create({
        name: '3 ' + hourName,
        value: '03'
      }), Ember.Object.create({
        name: '4 ' + hourName,
        value: '04'
      }), Ember.Object.create({
        name: '5 ' + hourName,
        value: '05'
      })];
    }),
    quarterlyMinuteDurationOptions: Ember.computed(function () {
      var minuteName = ' ' + this.intl.t('pages.customers.system.order.minutes');
      return [Ember.Object.create({
        name: '0 ' + minuteName,
        value: '00'
      }), Ember.Object.create({
        name: '15 ' + minuteName,
        value: '15'
      }), Ember.Object.create({
        name: '30 ' + minuteName,
        value: '30'
      }), Ember.Object.create({
        name: '45 ' + minuteName,
        value: '45'
      })];
    })
  });

  _exports.default = _default;
});