define("securitytrax-ember-ui/customers/accounting/invoices-credit-memos/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v0mJJkK/",
    "block": "{\"symbols\":[],\"statements\":[[8,\"pages/accounting/invoices/credit-memos\",[],[[\"@model\",\"@customer\",\"@tags\"],[[34,0],[34,0,[\"customer\"]],[34,0,[\"tags\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/customers/accounting/invoices-credit-memos/template.hbs"
    }
  });

  _exports.default = _default;
});