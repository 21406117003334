define("securitytrax-ember-ui/customers/files/route", ["exports", "securitytrax-ember-ui/customers/base-route", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _baseRoute, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_routePageviewLogging.default, {
    grants: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      var customerId = this.paramsFor('customers').id;
      return this.findGrantsTask.perform(['customer_files.view', 'electronic_document_envelopes.view'], 'one', '/customers/' + customerId + '/view');
    },

    model() {
      var {
        customer
      } = this._super(...arguments);

      return Ember.RSVP.hash({
        customer,
        customerFiles: this.grants.getIndividualGrant('customer_files.view') ? customer.get('customerFiles') : []
      });
    }

  });

  _exports.default = _default;
});