define("securitytrax-ember-ui/components/pages/users/human-resources/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4qJJY5PK",
    "block": "{\"symbols\":[\"multiStepForm\",\"MultiStepFormComponent\",\"@model\"],\"statements\":[[6,[37,5],[[30,[36,4],[\"users.view\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"multi-step-form\",[],[[\"@formSteps\",\"@formOriginalModels\",\"@formStarted\"],[[34,0],[34,1],true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,[32,2],[[24,0,\"user-hr-form\"]],[[\"@multiStepForm\",\"@user\",\"@model\"],[[32,1],[32,3,[\"user\"]],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"]],\"parameters\":[1]}]]],[2,\"\\n\\n    \"],[8,\"payroll/payee-assignments\",[],[[\"@object\"],[[32,3,[\"user\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"userHrFormSteps\",\"userHrFormOriginalModels\",\"component\",\"let\",\"has-grants\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/users/human-resources/template.hbs"
    }
  });

  _exports.default = _default;
});