define("securitytrax-ember-ui/components/multi-step-form/forms/system/cms/agencies/add-agency/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    centralStationService: Ember.inject.service(),
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    usStates: Ember.inject.service(),
    showAgencyList: false,
    agencyResultsReady: false,
    agenciesToAdd: undefined,
    agenciesToRemove: undefined,

    /**
     * INIT
     * get customer agencies, setup modal
     * */
    agencyTypesTask: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('agenciesToAdd', []);
        this.set('agenciesToRemove', []);
        var agencyData = yield this.centralStationService.getData({
          model: this.customer,
          method: 'cms__getSiteAgencyList',
          responseKey: 'site_agency_list'
        });
        this.set('currentAgencies', agencyData);
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'addAgencyModal'
        });
        (false && Ember.warn('cms agency add init error:' + err, {
          id: 'multiStepForm.forms.system.cms.agencies.add-agency.agencyTypesTask'
        }));
      }
    }).on('init'),

    didInsertElement() {
      this._super(...arguments);

      this.setupButtons();
    },

    searchAgenciesTask: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('formMode', 'view'); //ensure at least 1 filter, othwerwise will get out of memory errors

        if (!this.agency_number && !this.agency_name && !this.city && !this.state && !this.zip) {
          throw this.intl.t('pages.administration.integrations.monitoringCompanies.integrations.cms.agencies.select_filter');
        }

        this.set('agencyResultsReady', false);
        var agencyTypes = this.agencyTypes;
        var currentAgencies = this.currentAgencies;
        var agenciesList = yield this.centralStationService.getData({
          model: this.monitoringCompany,
          method: 'cms__getAgencies',
          payload: {
            agency_number: this.agency_number,
            agency_name: this.agency_name,
            city: this.city,
            state: this.state,
            zip: this.zip
          },
          responseKey: 'agencies'
        });

        if (!agenciesList || agenciesList.length === 0) {
          this.stNotificationManager.addWarning(this.intl.t('pages.administration.integrations.monitoringCompanies.integrations.cms.agencies.no_results'), 'addAgencyModal');
        } else {
          var agenciesListMapped = agenciesList ? yield agenciesList.map(agency => {
            return {
              added: currentAgencies && currentAgencies.length > 0 ? currentAgencies.find(a => a.agency_number === agency.agency_number) : false,
              agency_number: agency.agency_number,
              agency_type_id: agency.agency_type_id,
              agency_type: agencyTypes.find(t => t.id === agency.agency_type_id).name,
              agency_name: agency.agency_name,
              city_name: agency.city_name,
              state_id: agency.state_id,
              zip_code: agency.zip_code,
              phone_1: agency.phone_1,
              phone_2: agency.phone_2
            };
          }) : [];
          var groupedAgenciesList = agenciesListMapped && agenciesListMapped.length > 0 ? agenciesListMapped.uniqBy('agency_type_id').map(agencyList => {
            return Ember.Object.create({
              name: agencyList.agency_type,
              items: agenciesListMapped.filterBy('agency_type_id', agencyList.agency_type_id),
              collapsable: true,
              collapsed: false,
              collapseIconName: 'icn-chevron-up'
            });
          }) : [];
          this.set('groupedAgenciesList', groupedAgenciesList);
        }

        this.set('agencyResultsReady', agenciesList && agenciesList.length > 0 ? true : false);
        this.set('showAgencyList', agenciesList && agenciesList.length > 0 ? true : false);
        this.set('formMode', 'edit');
      } catch (err) {
        this.set('agenciesList', []);
        this.set('formMode', 'edit');
        this.set('agencyResultsReady', true);
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'addAgencyModal'
        });
        (false && Ember.warn('cms search agencies error:' + err, {
          id: 'multiStepForm.forms.system.cms.agencies.add-agency.searchAgenciesTask'
        }));
      }
    }),
    addAgencies: (0, _emberConcurrency.task)(function* (buttonPromise) {
      var messages = [];
      var agenciesToAdd = this.agenciesToAdd;
      var agenciesToRemove = this.agenciesToRemove;
      var addRemoveCalls = [];

      try {
        for (var i = 0; i < agenciesToAdd.length; i++) {
          addRemoveCalls.push(this.centralStationService.postData({
            model: this.customer,
            method: 'cms__addSiteAgency',
            payload: {
              agency_number: agenciesToAdd[i]
            }
          }));
        }

        for (var j = 0; j < agenciesToRemove.length; j++) {
          addRemoveCalls.push(this.centralStationService.postData({
            model: this.customer,
            method: 'cms__removeSiteAgency',
            payload: {
              agency_number: agenciesToRemove[j]
            }
          }));
        }

        var resolvedPromises = yield (0, _emberConcurrency.all)(addRemoveCalls);
        resolvedPromises.forEach(response => {
          messages = messages.concat(response.responsePayload.data.messages);
        });
        buttonPromise.then(() => {
          this.stNotificationManager.addSuccess(this.intl.t('common.notifications.save_successful'), 'modal');
          this.set('showAddAgencyForm', false);
          this.refreshAgenciesTask.perform();

          if (messages.length > 0) {
            this.stNotificationManager.addWarningMessages(messages, {
              notificationNamespace: 'modal'
            });
          }
        });
      } catch (err) {
        this.set('agencyResultsReady', true);
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'addAgencyModal'
        });
        (false && Ember.warn('cms agency add error:' + err, {
          id: 'multiStepForm.forms.system.cms.agencies.add-agency.addAgencies'
        }));
        throw err;
      }
    }),
    // only allow to press save when agency results are ready
    setupButtonsObserver: Ember.observer('agencyResultsReady', function () {
      var buttons = this.addAgencyButtons;
      var saveButton = buttons[0];
      Ember.set(saveButton, 'disabled', !this.agencyResultsReady);
    }),

    setupButtons() {
      this.set('addAgencyButtons', [{
        text: this.intl.t('common.actions.save'),
        classNamesString: 'st-btn st-btn-primary save-agency',
        disabled: true,
        click: buttonPromise => {
          return this.addAgencies.perform(buttonPromise);
        }
      }]);
    },

    actions: {
      // every time a toggle happens, setup the agency to add/remove array
      toggleAgency(agency_number, val) {
        var agenciesToAdd = this.agenciesToAdd;
        var agenciesToRemove = this.agenciesToRemove;
        var currentAgencies = this.currentAgencies;
        var alreadyInAgenciesToAdd = agenciesToAdd.indexOf(agency_number) > -1;
        var alreadyInAgenciesToRemove = agenciesToRemove.indexOf(agency_number) > -1;

        if (currentAgencies && currentAgencies.find(a => a.agency_number === agency_number)) {
          if (val === false && !alreadyInAgenciesToRemove) {
            agenciesToRemove.push(agency_number);
          } else if (val === true && alreadyInAgenciesToRemove) {
            agenciesToRemove = agenciesToRemove.filter(number => number != agency_number);
          }
        } else {
          if (val === true && !alreadyInAgenciesToAdd) {
            agenciesToAdd.push(agency_number);
          } else if (val === false && alreadyInAgenciesToAdd) {
            agenciesToAdd = agenciesToAdd.filter(number => number != agency_number);
          }
        }

        this.set('agenciesToRemove', agenciesToRemove);
        this.set('agenciesToAdd', agenciesToAdd);
      }

    }
  });

  _exports.default = _default;
});