define("securitytrax-ember-ui/components/multi-step-form/forms/payables/edit-create-payable/base-component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/switchable-form-step"], function (_exports, _emberConcurrency, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    stNotificationManager: Ember.inject.service(),
    stAjax: Ember.inject.service(),
    store: Ember.inject.service(),
    updatePayableTask: (0, _emberConcurrency.task)(function* (buttonPromise, multiStepForm, pendingPayable, notificationNamespace = 'modal') {
      if (this.isNewRecord) {
        try {
          var payable = yield pendingPayable;
          var lineItemSaves = [];
          var payableSaves = [];
          yield this.makePayablesActiveTask.perform(payable);
          this.addOnPayables.concat([payable]).forEach(payable => {
            if (payable.get('doSave')) {
              var total = 0.00;
              payable.get('customerPayableLineItems').forEach(lineItem => {
                total += Number(lineItem.amount);
                lineItemSaves.push(lineItem.save());
              });
              payable.amount = Number(total);
              payableSaves.push(payable.save());
            }
          });

          if (lineItemSaves) {
            yield (0, _emberConcurrency.all)(lineItemSaves); // Needs to happen first

            yield (0, _emberConcurrency.all)(payableSaves); // Needs to happen second

            yield this.payPayablesInFullTask.perform(payable); // Needs to happen last

            multiStepForm.resetForm(false);
          }
        } catch (err) {
          this.stNotificationManager.addErrorMessages(err, {
            notificationNamespace
          });
          throw err;
        }
      } else {
        yield multiStepForm.validateSyncSaveAll({
          notificationNamespace: 'modal'
        });
      }

      var payrollRoleFunctionLevel = yield this.get('payrollRoleFunctionLevel');
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        payrollRoleFunctionLevel.get('payrollColumns').reload();
        this.multiStepForm.onAfterSave();

        if (this.transitionBackToParent && typeof this.transitionBackToParent === 'function') {
          this.transitionBackToParent();
        }
      });
    }),
    makePayablesActiveTask: (0, _emberConcurrency.task)(function* (payable) {
      if (this.addOnPayables.length > 0) {
        var toSaveIds = this.addOnPayables.filter(payable => {
          return payable.get('doSave');
        }).map(payable => {
          return payable.get('id');
        });

        if (payable.get('doSave')) {
          toSaveIds.push(payable.get('id'));
        }

        if (toSaveIds.length > 0) {
          yield this.stAjax.request(this.stAjax.getUrl('/customer_payables/actions/batch_make_status_active'), {
            method: 'POST',
            data: {
              data: {
                ids: toSaveIds
              }
            }
          });
        }
      } else {
        yield payable.invokeAction('make_status_active', {
          verb: 'POST',
          data: {
            data: {}
          }
        });
      }
    }),
    payPayablesInFullTask: (0, _emberConcurrency.task)(function* (payable) {
      var toSaveIds = [];

      if (this.addOnPayables.length > 0) {
        toSaveIds = this.addOnPayables.filter(payable => {
          return payable.get('doSave') && payable.get('payInFull');
        }).map(payable => {
          return payable.get('id');
        });
      }

      if (payable.get('payInFull') && payable.get('doSave') && payable.get('amount') > 0) {
        toSaveIds.push(payable.get('id'));
      }

      if (toSaveIds.length > 0) {
        var dummyModel = this.store.createRecord('customerPayablePayment');
        yield dummyModel.invokeAction('batch_create_paid_in_full', {
          verb: 'POST',
          data: {
            data: {
              ids: toSaveIds
            }
          }
        });
      }
    })
  });

  _exports.default = _default;
});