define("securitytrax-ember-ui/my-account/notifications/route", ["exports", "securitytrax-ember-ui/my-account/base-route", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _baseRoute, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_routePageviewLogging.default, {
    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform();
    },

    model() {
      var {
        user
      } = this._super(...arguments);

      return Ember.RSVP.hash({
        notifications: this.store.findAll('notification'),
        companySubscriptions: this.store.query('notificationSubscription', {
          filter: 'object_type=\'company\''
        }),
        user
      });
    }

  });

  _exports.default = _default;
});