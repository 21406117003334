define("securitytrax-ember-ui/components/multi-step-form/forms/system/cms/agencies/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/system/cms/cms-base", "ember-concurrency"], function (_exports, _cmsBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _cmsBase.default.extend({
    centralStationService: Ember.inject.service(),
    intl: Ember.inject.service(),
    agencyDeleteId: null,
    progressBarHidden: false,
    showAddAgencyForm: false,

    /**
     * INIT
     * Get current agencies and agency types, setup step
     * */
    getAgenciesTask: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('cmsStepReady', false);
        var agencyData = yield (0, _emberConcurrency.hash)({
          agencyTypes: this.centralStationService.getData({
            model: this.monitoringCompany,
            method: 'cms__getAgencyTypes',
            responseKey: 'agency_types'
          }),
          agencies: this.centralStationService.getData({
            model: this.customer,
            method: 'cms__getSiteAgencyList',
            responseKey: 'site_agency_list'
          })
        });
        var agenciesListMapped = agencyData.agencies && agencyData.agencies.length > 0 ? yield agencyData.agencies.map(agency => {
          var type = agencyData.agencyTypes.find(t => t.id.trim() === agency.agency_type_id.trim());
          return {
            agency_number: agency.agency_number,
            agency_type_id: agency.agency_type_id,
            agency_type: type ? type.name : '',
            agency_name: agency.agency_name,
            phone_1: agency.phone_1,
            phone_2: agency.phone_2
          };
        }) : [];
        this.set('agencies', agenciesListMapped);
        this.set('agencyTypes', agencyData.agencyTypes);
        this.set('cmsStepReady', true);
      } catch (err) {
        this.set('agencies', []);
        this.set('cmsStepReady', true);
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
      }
    }).on('init'),
    deleteAgencyTask: (0, _emberConcurrency.task)(function* (agencyId) {
      var messages = [];

      try {
        this.set('cmsStepReady', false);
        var response = yield this.centralStationService.postData({
          model: this.customer,
          method: 'cms__removeSiteAgency',
          payload: {
            agency_number: agencyId
          }
        });
        messages = messages.concat(response.responsePayload.data.messages);
        this.stNotificationManager.addSuccessKey('common.notifications.delete_successful', 'modal');
        this.set('agencyDeleteId', null);
        yield this.getAgenciesTask.perform();

        if (messages.length > 0) {
          this.stNotificationManager.addWarningMessages(messages, {
            notificationNamespace: 'modal'
          });
        }
      } catch (err) {
        this.set('agencies', []);
        this.set('agencyDeleteId', null);
        this.set('cmsStepReady', true);
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
      }
    })
  });

  _exports.default = _default;
});