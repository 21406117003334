define("securitytrax-ember-ui/components/pages/users/user-inventory/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    grants: Ember.inject.service(),
    userInventoryForm: Ember.computed(function () {
      return Ember.Object.create({
        inventory: Ember.Object.create({
          stepNameKey: 'inventory',
          title: 'user inventory',
          stepComponentPath: 'multi-step-form/forms/administration/users/inventory'
        })
      });
    }),
    inventoryFormOriginalModels: Ember.computed('model.user', 'grants.userGrants', function () {
      var grants = this.grants;
      return Ember.Object.create({
        user: {
          model: this.get('model.user'),
          copyRelationMap: {
            userInventorySetting: {
              doCopy: grants.getIndividualGrant('user_inventory_settings.view'),
              doValidate: grants.getIndividualGrant('user_inventory_settings.view'),
              doSave: grants.getIndividualGrant('user_inventory_settings.modify'),
              relationships: {
                inventoryRecipientTemplate: {
                  doValidate: false,
                  doSave: false
                }
              }
            }
          }
        }
      });
    })
  });

  _exports.default = _default;
});