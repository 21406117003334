define("securitytrax-ember-ui/components/html/priority-color-formatter/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    status: undefined,
    classNames: ['color-format-number'],
    classNameBindings: ['color'],
    color: '',
    priorityStatus: Ember.observer('status', function () {
      this.setColorTask.perform();
    }),
    setColorTask: (0, _emberConcurrency.task)(function* () {
      var status = yield this.status;

      if (status == this.intl.t('pages.administration.all_tasks.priority_low')) {
        this.set('color', 'color-format-number__accent_green');
      } else if (status == this.intl.t('pages.administration.all_tasks.priority_high')) {
        this.set('color', 'color-format-number__accent_red');
      } else if (status == this.intl.t('pages.administration.all_tasks.priority_med')) {
        this.set('color', 'color-format-number__accent_yellow');
      } else {
        this.set('color', 'color-format-number__gray');
      }
    }).on('init')
  });

  _exports.default = _default;
});