define("securitytrax-ember-ui/home/view/route", ["exports", "securitytrax-ember-ui/home/base-route", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/has-page-title", "securitytrax-ember-ui/mixins/scroll-to-top", "ember-concurrency"], function (_exports, _baseRoute, _authenticatedRouteMixin, _hasPageTitle, _scrollToTop, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_authenticatedRouteMixin.default, _hasPageTitle.default, _scrollToTop.default, {
    sessionData: Ember.inject.service('sessionData'),
    session: Ember.inject.service('session'),
    router: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      var storedURL = localStorage.getItem('storedURL');

      if (storedURL && storedURL != 'home/view') {
        localStorage.removeItem('storedURL');
        window.location.href = storedURL;
      } else {
        return this.findGrantsTask.perform();
      }
    },

    model() {
      return this.setupModelTask.perform();
    },

    setupModelTask: (0, _emberConcurrency.task)(function* () {
      var roles = [];
      var user = null;
      var editDashboard = null;
      var cardCount = 1;

      try {
        user = yield this.modelFor('home').loggedInUser;
        roles = yield this.modelFor('home').roles;
        editDashboard = yield this.modelFor('home').editDashboard;
        cardCount = yield this.modelFor('home').cardCount;
      } catch (e) {
        (false && Ember.warn(e, {
          id: 'app.home.getModelData'
        }));
      }

      return (0, _emberConcurrency.hash)({
        user,
        roles,
        editDashboard,
        cardCount
      });
    }),

    getPageTitle() {
      return this.intl.t('common.nav.home');
    },

    getRouteName() {
      return 'common.nav.dashboard';
    }

  });

  _exports.default = _default;
});