define("securitytrax-ember-ui/components/pages/my-account/information/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2KaIQrPA",
    "block": "{\"symbols\":[\"multiStepForm\",\"MultiStepFormComponent\",\"multiStepForm\",\"MultiStepFormComponent\",\"multiStepForm\",\"MultiStepFormComponent\"],\"statements\":[[8,\"multi-step-form\",[],[[\"@formSteps\",\"@formOriginalModels\",\"@formStarted\"],[[34,5],[34,6],true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[32,5,[\"currentStepData\",\"stepComponentPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,[32,6],[[24,0,\"user-form\"]],[[\"@multiStepForm\",\"@user\",\"@mobileCarriers\"],[[32,5],[34,0,[\"user\"]],[34,0,[\"mobileCarriers\"]]]],null],[2,\"\\n\"]],\"parameters\":[6]}]]]],\"parameters\":[5]}]]],[2,\"\\n\\n\"],[6,[37,8],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"multi-step-form\",[],[[\"@formSteps\",\"@formOriginalModels\",\"@formStarted\"],[[34,1],[34,2],true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[32,3,[\"currentStepData\",\"stepComponentPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,[32,4],[[24,0,\"user-settings-form\"]],[[\"@multiStepForm\",\"@user\"],[[32,3],[34,0,[\"user\"]]]],null],[2,\"\\n\"]],\"parameters\":[4]}]]],[2,\"    \"]],\"parameters\":[3]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"multi-step-form\",[],[[\"@formSteps\",\"@formStarted\"],[[34,9],true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,[32,2],[[24,0,\"profile-photo-form\"]],[[\"@multiStepForm\",\"@formMode\",\"@src\",\"@model\"],[[32,1],\"view\",[34,0,[\"user\",\"avatar\"]],[34,0,[\"user\"]]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"userSettingsForm\",\"settingsFormOriginalModels\",\"component\",\"let\",\"userInformationForm\",\"formOriginalModels\",\"hasUserSettingsGrant\",\"if\",\"profilePhotoForm\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/my-account/information/template.hbs"
    }
  });

  _exports.default = _default;
});