define("securitytrax-ember-ui/helpers/time-from-now", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.timeFromNow = timeFromNow;
  _exports.default = void 0;

  function timeFromNow(input) {
    var date = _moment.default.isMoment(input[0]) ? input[0] : (0, _moment.default)(input[0]);
    var format = input[1] ? input[1] : 'MM/DD/YYYY';

    if (_moment.default.isMoment(date)) {
      if (_moment.default.duration((0, _moment.default)().diff(date)).asDays() > 2) {
        return (0, _moment.default)(date).format(format);
      } else {
        var momentToArray = (0, _moment.default)(date).toArray();
        return (0, _moment.default)(momentToArray).fromNow();
      }
    } else {
      throw new Error('time-from-now input must be a moment.js object');
    }
  }

  var _default = Ember.Helper.helper(timeFromNow);

  _exports.default = _default;
});