define("securitytrax-ember-ui/components/pages/aging-invoices/component", ["exports", "@glimmer/component", "ember-concurrency", "securitytrax-ember-ui/mixed-in-classes/table-column-sort", "securitytrax-ember-ui/utils/obj-helpers", "uuid"], function (_exports, _component, _emberConcurrency, _tableColumnSort, _objHelpers, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var AllAgingInvoices = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember.computed, _dec21 = (0, _emberConcurrency.task)(function* () {
    var actualFilters = yield this.getFiltersTask.perform();

    try {
      var company = yield this.sessionData.getCompany();
      var invoicesData = yield company.invokeAction('get_aging_invoices_data', {
        verb: 'POST',
        data: {
          data: {
            sort: this.TableColumnSort.stSortString,
            filters: actualFilters,
            page: this.page,
            limit: this.limit
          }
        }
      });
      this.totals = invoicesData.responsePayload.meta.totals;

      if (invoicesData.responsePayload.data.length > 0) {
        var data = invoicesData.responsePayload.data;
        var randomId = (0, _uuid.v4)();
        var multicallKey = "".concat(randomId, "-aging-invoices-list");
        var multicallSubkeys = {};

        for (var x = 0; x < data.length; x++) {
          var multicallSubkey = "".concat(randomId, "-aging-invoices-customer-get-").concat(x);
          multicallSubkeys[multicallSubkey] = true;
        }

        var customerData = yield this.stMulticallService.setupMulticall({
          multicallKey,
          multicallSubkeys
        }, () => {
          for (var _x = 0; _x < data.length; _x++) {
            var customerId = data[_x].customer_id;

            var _multicallSubkey = "".concat(randomId, "-aging-invoices-customer-get-").concat(_x);

            var multicallOptions = {
              multicallKey,
              multicallSubkey: _multicallSubkey
            };
            this.stMulticallService.registerRequest(multicallOptions, this.store.findRecord('customer', customerId, {
              adapterOptions: {
                multicallOptions
              }
            }));
          }
        });
        var customerDataArray = Object.values(customerData);

        for (var index = 0; index < data.length; index++) {
          data[index].customer = customerDataArray[index].actualResponse;
        }

        return {
          customerInvoices: data,
          record_count: invoicesData.responsePayload.meta.record_count
        };
      } else {
        return {
          customerInvoices: [],
          record_count: 0
        };
      }
    } catch (error) {
      (false && Ember.warn('failed in customerInvoicesTask: ' + error, {
        id: 'company.accounting.payments.customerInvoicesTask'
      }));
    }
  }), _dec22 = (0, _emberConcurrency.task)(function* () {
    var newFilters = yield this.stFiltersService.getFiltersByGroupKey('accountingAgingInvoices');
    var actualFilters = {};
    newFilters.map(filter => {
      var filterInfo = filter.split(' ');
      var filterName = filterInfo.shift();

      if (filterName === 'created_date' || filterName === 'sale_date') {
        var filterValue = filterInfo.join(' ');

        if (filterValue.indexOf('>') > -1) {
          //from date
          actualFilters[filterName + '_from'] = filterValue.substring(4, filterValue.length - 1);
        } else {
          //to date
          actualFilters[filterName + '_to'] = filterValue.substring(4, filterValue.length - 1);
        }
      } else if (filterName === 'office_location' || filterName === 'account_type' || filterName === 'status') {
        // must convert string representation of array to actual array
        var stringArrayString = filterInfo[filterInfo.length - 1].replace(/'/g, '');
        var arrayValue = stringArrayString.substring(1, stringArrayString.length - 1).split(',');
        actualFilters[filterName] = arrayValue;
      } else if (filterName === 'show_customers_with_only_pending_amount' || filterName === 'show_customers_with_only_current_amount') {
        actualFilters[filterName] = filterInfo[filterInfo.length - 1] === 'true';
      } else {
        actualFilters[filterName] = filterInfo[filterInfo.length - 1];
      }
    });
    return actualFilters;
  }), _dec23 = (0, _emberConcurrency.task)(function* () {
    var actualFilters = yield this.getFiltersTask.perform();

    try {
      var company = yield this.sessionData.getCompany();
      var url = yield company.invokeAction('export_aging_invoices_data', {
        verb: 'POST',
        data: {
          data: {
            sort: this.TableColumnSort.stSortString,
            filters: actualFilters,
            page: this.page,
            limit: this.limit
          }
        }
      });
      window.open(url.responsePayload.meta.download_uri, '_blank');
    } catch (err) {
      (false && Ember.warn('failed in exportAgingResultsTask: ' + err, {
        id: 'company.accounting.payments.exportAgingResultsTask'
      }));
    }
  }), _dec24 = Ember._action, _dec25 = Ember._action, _dec26 = Ember._action, _dec27 = Ember._action, _dec28 = Ember._action, (_class = (_temp = class AllAgingInvoices extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "grants", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      _initializerDefineProperty(this, "sessionData", _descriptor5, this);

      _initializerDefineProperty(this, "stFiltersService", _descriptor6, this);

      _initializerDefineProperty(this, "stMulticallService", _descriptor7, this);

      _initializerDefineProperty(this, "limit", _descriptor8, this);

      _initializerDefineProperty(this, "page", _descriptor9, this);

      _initializerDefineProperty(this, "saleDateFrom", _descriptor10, this);

      _initializerDefineProperty(this, "saleDateTo", _descriptor11, this);

      _initializerDefineProperty(this, "createdDateFrom", _descriptor12, this);

      _initializerDefineProperty(this, "createdDateTo", _descriptor13, this);

      _initializerDefineProperty(this, "officeLocations", _descriptor14, this);

      _initializerDefineProperty(this, "accountType", _descriptor15, this);

      _initializerDefineProperty(this, "status", _descriptor16, this);

      _initializerDefineProperty(this, "showOnlyPending", _descriptor17, this);

      _initializerDefineProperty(this, "showOnlyCurrent", _descriptor18, this);

      _initializerDefineProperty(this, "totals", _descriptor19, this);

      _initializerDefineProperty(this, "customerInvoicesTask", _descriptor20, this);

      _initializerDefineProperty(this, "getFiltersTask", _descriptor21, this);

      _initializerDefineProperty(this, "exportAgingResultsTask", _descriptor22, this);

      this.TableColumnSort = new _tableColumnSort.default(...arguments);
      (0, _objHelpers.initializeState)(this, {
        stSortableColumnOptions: [{
          name: this.intl.t('common.columns.customer') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'customer_last_name,customer_first_name',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.customer') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-customer_last_name,customer_first_name',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('common.columns.created_date') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'created_date',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.created_date') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-created_date',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('common.columns.sale_date') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'sale_date',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.sale_date') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-sale_date',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('common.status.pending') + ' ' + this.intl.t('common.sorting.descending'),
          value: 'pending',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.status.pending') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-pending',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('pages.accounting.aging-invoices.current') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'current',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('pages.accounting.aging-invoices.current') + ' ' + this.intl.t('common.sorting.ascending'),
          value: '-current',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('pages.accounting.aging-invoices.over30') + ' ' + this.intl.t('common.sorting.descending'),
          value: 'over30',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('pages.accounting.aging-invoices.over30') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-over30',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('pages.accounting.aging-invoices.over60') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'over60',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('pages.accounting.aging-invoices.over60') + ' ' + this.intl.t('common.sorting.ascending'),
          value: '-over60',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('pages.accounting.aging-invoices.over90') + ' ' + this.intl.t('common.sorting.descending'),
          value: 'over90',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('pages.accounting.aging-invoices.over90') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-over90',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('common.columns.balance') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'balance',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.balance') + ' ' + this.intl.t('common.sorting.ascending'),
          value: '-balance',
          chevronDirection: 'down'
        }]
      });
      this.TableColumnSort.stSortableColumnOptions = this.stSortableColumnOptions;
      this.TableColumnSort.sortPaginatedList('customer_last_name,customer_first_name', false);
    }

    get locationsSorted() {
      return this.args.locations.sortBy('name');
    }

    get accountTypes() {
      return [{
        value: 'Residential',
        name: this.intl.t('common.customer.account_types.Residential')
      }, {
        value: 'Commercial',
        name: this.intl.t('common.customer.account_types.Commercial')
      }];
    }

    get trueFalseOptions() {
      return [{
        value: true,
        name: this.intl.t('common.confirm.true')
      }, {
        value: false,
        name: this.intl.t('common.confirm.false')
      }];
    }

    get agingInvoiceStatuses() {
      return [{
        value: 'pending',
        name: this.intl.t('common.status.pending')
      }, {
        value: 'current',
        name: this.intl.t('pages.accounting.aging-invoices.current')
      }, {
        value: 'over30',
        name: this.intl.t('pages.accounting.aging-invoices.over30')
      }, {
        value: 'over60',
        name: this.intl.t('pages.accounting.aging-invoices.over60')
      }, {
        value: 'over90',
        name: this.intl.t('pages.accounting.aging-invoices.over90')
      }];
    }

    get customerInvoices() {
      return this.customerInvoicesTask.perform();
    }

    updateCustomerInvoicesList() {
      Ember.notifyPropertyChange(this, 'customerInvoices');
    }

    filterSearch() {
      Ember.set(this, 'page', 1);
      Ember.notifyPropertyChange(this, 'customerInvoices');
    }

    clearInvoicesFilter() {
      Ember.setProperties(this, {
        saleDateFrom: null,
        saleDateTo: null,
        createdDateFrom: null,
        createdDateTo: null,
        officeLocations: null,
        accountType: null,
        status: null,
        showOnlyPending: null,
        showOnlyCurrent: null,
        page: 1
      });
      Ember.notifyPropertyChange(this, 'customerInvoices');
      Ember.notifyPropertyChange(this, 'page');
    }

    sortList(column, ascending) {
      this.TableColumnSort.sortPaginatedList(column, ascending);
      Ember.notifyPropertyChange(this, 'customerInvoices');
    }

    mobileSortList(column) {
      this.TableColumnSort.sortMobilePaginatedList(column.value);
      Ember.set(this, 'stSortCurrentSortColumn', null);
      Ember.set(this, 'stSortAscending', null);
      Ember.notifyPropertyChange(this, 'customerInvoices');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "stFiltersService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "stMulticallService", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "limit", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "saleDateFrom", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "saleDateTo", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "createdDateFrom", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "createdDateTo", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "officeLocations", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "accountType", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "showOnlyPending", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "showOnlyCurrent", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "totals", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "customerInvoices", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "customerInvoices"), _class.prototype), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "customerInvoicesTask", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "getFiltersTask", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "exportAgingResultsTask", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateCustomerInvoicesList", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "updateCustomerInvoicesList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterSearch", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "filterSearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearInvoicesFilter", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "clearInvoicesFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortList", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "sortList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mobileSortList", [_dec28], Object.getOwnPropertyDescriptor(_class.prototype, "mobileSortList"), _class.prototype)), _class));
  _exports.default = AllAgingInvoices;
});