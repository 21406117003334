define("securitytrax-ember-ui/models/base_models/customer-contact", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    name: (0, _model.attr)('string', {
      'readOnly': false
    }),
    phone1: (0, _model.attr)('string', {
      'readOnly': false
    }),
    email: (0, _model.attr)('string', {
      'readOnly': false
    }),
    about: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['Home', 'Work', 'Mobile', 'Other', 'Pin']
    }),
    status: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['active', 'deleted']
    }),
    lang: (0, _model.attr)('string', {
      'readOnly': false
    }),
    centralStationId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    interactiveServicesId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    sort: (0, _model.attr)('number', {
      'readOnly': false
    }),
    enhancedVerification: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    passcode: (0, _model.attr)('string', {
      'readOnly': false
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    customer: (0, _model.belongsTo)('customer', {
      'readOnly': false,
      'readOnlyUpdate': true
    }),
    udfs: (0, _model.hasMany)('udf', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});