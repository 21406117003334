define("securitytrax-ember-ui/helpers/html-safe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.htmlSafe = htmlSafe;
  _exports.default = void 0;

  function htmlSafe(unsafeString) {
    return new Ember.String.htmlSafe(unsafeString);
  }

  var _default = Ember.Helper.helper(htmlSafe);

  _exports.default = _default;
});