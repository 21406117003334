define("securitytrax-ember-ui/components/html/st-color-formatter/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class StColorFormatter extends _component.default {
    constructor() {
      super(...arguments); // current useable colors
      // color-format-number__accent_green
      // color-format-number__accent_red
      // color-format-number__accent_yellow
      // color-format-number__gray

      if (!this.args.colorRules) {
        throw new Error('Please pass colorRules into color formatter');
      }
    }

    get color() {
      var colorSet = this.args.colorRules.find(ruleSet => {
        return ruleSet.dataPoint == this.args.dataPoint;
      });

      if (!colorSet) {
        return 'color-format-number__gray';
      } else {
        return colorSet.color;
      }
    }

  }

  _exports.default = StColorFormatter;
});