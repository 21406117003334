define("securitytrax-ember-ui/accounting/funding-entries/route", ["exports", "securitytrax-ember-ui/home/base-route", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/scroll-to-top"], function (_exports, _baseRoute, _authenticatedRouteMixin, _scrollToTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_authenticatedRouteMixin.default, _scrollToTop.default, {
    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['fundings.view'], 'all', 'accounting.dashboard');
    },

    model() {},

    getPageTitle() {
      return this.intl.t('pages.accounting.title');
    },

    getRouteName() {
      return 'common.nav.company';
    }

  });

  _exports.default = _default;
});