define("securitytrax-ember-ui/components/inventory/pending-rma/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/grouped-equipment"], function (_exports, _emberConcurrency, _groupedEquipment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_groupedEquipment.default, {
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    grants: Ember.inject.service(),
    rmaFormStarted: false,
    pendingRMALoaded: false,
    removed: false,
    items: Ember.computed.alias('rma.pendingEquipmentLineItems'),
    vendors: Ember.computed(function () {
      return this.getVendorsTask.perform();
    }),
    getVendorsTask: (0, _emberConcurrency.task)(function* () {
      var vendors = yield this.store.findAll('equipmentVendor');
      return vendors.sortBy('name');
    }),
    rmaForm: Ember.computed('rma.title', function () {
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.get('rma.title'),
          stepComponentPath: 'multi-step-form/forms/administration/inventory/edit-create-rma/edit-rma'
        })
      });
    }),
    formSteps: Ember.computed('formMode', 'rma.{equipmentVendor.id,recipient.id}', function () {
      var intl = this.intl;
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: intl.t("pages.administration.company_settings.inventory.rma.review_title", {
            recipient: this.get('rma.recipient.flname') || this.get('rma.recipient.name'),
            vendor: this.get('rma.equipmentVendor.name')
          }),
          stepComponentPath: 'multi-step-form/forms/administration/inventory/edit-create-rma/review'
        })
      });
    }),
    formOriginalModels: Ember.computed('rma', function () {
      return Ember.Object.create({
        equipmentReturnMerchandiseAuthorization: {
          model: null,
          copyRelationMap: {
            user: {
              doCopy: true,
              doValidate: false,
              doSave: false
            },
            location: {
              readOnly: true
            },
            createdByUser: {
              doCopy: true,
              doValidate: false,
              doSave: false
            },
            equipmentVendor: {
              readOnly: true
            },
            pendingEquipmentLineItems: {
              relationships: {
                equipment: {
                  readOnly: true,
                  relationships: {
                    equipmentItem: {
                      readOnly: true,
                      relationships: {
                        equipmentSubType: {
                          readOnly: true,
                          relationships: {
                            equipmentType: {
                              readOnly: true
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      });
    }),
    initData: (0, _emberConcurrency.task)(function* () {
      var _this = this;

      var store = this.store;
      var rma = this.rma;
      var items = [];
      var hashedData = yield (0, _emberConcurrency.hash)({
        equipmentInventory: rma.recipient.invokeAction('get_admin_equipment_inventory', {
          verb: 'GET'
        }),
        equipment: this.equipment ? this.equipment : store.query('equipment', {
          limit: 0,
          sort: 'name',
          filter: 'active=true'
        }),
        equipmentToVendors: this.equipmentToVendors ? this.equipmentToVendors : store.findAll('equipmentToVendor'),
        equipmentReturnReason: store.findAll('equipmentReturnReason')
      });

      var _loop = function* (i) {
        var item = rma.items[i];
        var equipmentItem = hashedData.equipment.find(piece => piece.get('id') == item.equipment_id);
        var equipmentReturnReason = hashedData.equipmentReturnReason.find(equipmentReturnReason => equipmentReturnReason.get('id') == item.return_reason_id);
        items.push(Ember.Object.create({
          equipment: equipmentItem,
          quantity: item.quantity,
          comments: item.comments,
          equipmentReturnReason: equipmentReturnReason,
          extended: item.quantity * equipmentItem.get('cost'),
          parent: item.parent_id ? yield _this.store.find('pendingEquipmentLineItem', item.parent_id) : null
        }));
      };

      for (var i = 0; i < rma.items.length; i++) {
        yield* _loop(i);
      }

      this.set('pendingRMALoaded', true);
      this.set('rma.pendingEquipmentLineItems', items);
    }).on('init'),
    saveRMATask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise, notificationNamespace = 'global', returnMurchandiseAuthorization = null, lineItemsToCreate = null) {
      var store = this.store;
      var rma = returnMurchandiseAuthorization || this.rma;
      var pendingEquipmentLineItems = lineItemsToCreate || rma.pendingEquipmentLineItems;
      var lineItems = [];

      try {
        var toCreateRMA = yield store.createRecord('equipmentReturnMerchandiseAuthorization', {
          pendingEquipmentType: 'rma',
          user: rma.recipient.constructor.modelName.camelize() === 'user' ? rma.recipient : null,
          location: rma.recipient.constructor.modelName.camelize() === 'location' ? rma.recipient : null,
          parentEquipmentSpillage: rma.parent ? rma.parent : null,
          equipmentVendor: rma.equipmentVendor,
          retrievalMethodId: rma.retrievalMethodId,
          processingMethodId: rma.processingMethodId
        });
        pendingEquipmentLineItems.forEach(item => {
          var newPendingEquipmentLineItem = store.createRecord('pendingEquipmentLineItem', {
            parentPendingEquipmentLineItem: item.parent ? item.parent : null,
            cost: Number(item.equipment.get('cost')) || 0,
            quantity: item.quantity || 0,
            fulfilledQuantity: 0,
            equipmentReturnReason: item.equipmentReturnReason,
            equipment: item.equipment,
            equipmentReturnMerchandiseAuthorization: toCreateRMA
          });
          lineItems.push(newPendingEquipmentLineItem);
        });
        toCreateRMA.set('pendingEquipmentLineItems', lineItems);
        var newRMA = yield toCreateRMA.save();
        var spillage = yield toCreateRMA.get('parentEquipmentSpillage');

        if (spillage) {
          yield spillage.invokeAction('set_status_closed', {
            verb: 'POST'
          });
        }

        buttonPromise.then(() => {
          this.set('rma.created', true);
          this.set('rma.title', this.intl.t('pages.administration.company_settings.inventory.company_replenishment.created_rma_title', {
            name: rma.recipient.get('flname') || rma.recipient.get('name'),
            vendor: rma.equipmentVendor.get('name'),
            id: newRMA.get('id')
          }));
          this.set('rmaFormStarted', false);
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'global');

          if (this.refreshList) {
            this.refreshList();
          }
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace
        });
        throw err;
      }
    }),
    actions: {
      deletePendingRMA() {
        this.set('removed', true);
        this.set('rma.removed', true);
        this.set('RmaDeleteId', null);
      }

    }
  });

  _exports.default = _default;
});