define("securitytrax-ember-ui/components/multi-step-form/forms/system/adt/steps/funding-info/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/system/adt/adt-form-base"], function (_exports, _adtFormBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adtFormBase.default.extend({
    fundingInfo: Ember.computed(function () {
      return this.adtCustomerInformation.funding;
    })
  });

  _exports.default = _default;
});