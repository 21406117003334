define("securitytrax-ember-ui/models/base_models/user", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    fname: (0, _model.attr)('string', {
      'readOnly': false
    }),
    lname: (0, _model.attr)('string', {
      'readOnly': false
    }),
    username: (0, _model.attr)('string', {
      'readOnly': false
    }),
    email: (0, _model.attr)('string', {
      'readOnly': false
    }),
    inPayroll: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    address: (0, _model.attr)('string', {
      'readOnly': false
    }),
    cellPhone: (0, _model.attr)('string', {
      'readOnly': false
    }),
    homePhone: (0, _model.attr)('string', {
      'readOnly': false
    }),
    maxAssignedLeads: (0, _model.attr)('number', {
      'readOnly': false
    }),
    canEmulate: (0, _model.attr)('boolean', {
      'readOnly': true
    }),
    canViewPan: (0, _model.attr)('boolean', {
      'readOnly': true
    }),
    status: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['active', 'deleted']
    }),
    companyFilingsViewed: (0, _model.attr)('string', {
      'readOnly': false
    }),
    workPhone: (0, _model.attr)('string', {
      'readOnly': false
    }),
    fax: (0, _model.attr)('string', {
      'readOnly': false
    }),
    employeeId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    timezone: (0, _model.attr)('string', {
      'readOnly': false
    }),
    locale: (0, _model.attr)('string', {
      'readOnly': false
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    userAdminSetting: (0, _model.belongsTo)('user-admin-setting', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': 'user'
    }),
    userHrSetting: (0, _model.belongsTo)('user-hr-setting', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': 'user'
    }),
    userInventorySetting: (0, _model.belongsTo)('user-inventory-setting', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': 'user'
    }),
    userPasswordSetting: (0, _model.belongsTo)('user-password-setting', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': 'user'
    }),
    userPreference: (0, _model.belongsTo)('user-preference', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': 'user'
    }),
    userTechSetting: (0, _model.belongsTo)('user-tech-setting', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': 'user'
    }),
    userStats: (0, _model.belongsTo)('user-stat', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    group: (0, _model.belongsTo)('group', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': 'users'
    }),
    mobileCarrier: (0, _model.belongsTo)('mobile-carrier', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    marketingGroup: (0, _model.belongsTo)('marketing-group', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    roles: (0, _model.hasMany)('role', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    userHierarchies: (0, _model.hasMany)('user-hierarchy', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    payablePayeeLevels: (0, _model.hasMany)('payable-payee-level', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    userResourceTypePolicies: (0, _model.hasMany)('user-resource-type-policy', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    emberCards: (0, _model.hasMany)('ember-card', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    userPolicyOverrides: (0, _model.hasMany)('user-policy-override', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    calendarEvents: (0, _model.hasMany)('calendar-event', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    timeOffEvents: (0, _model.hasMany)('calendar-event', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    workOrders: (0, _model.hasMany)('work-order', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    customerTasks: (0, _model.hasMany)('customer-task', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': 'assignedUser'
    }),
    loginActivity: (0, _model.hasMany)('login', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': 'user'
    }),
    files: (0, _model.hasMany)('user-file', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    userRepNumbers: (0, _model.hasMany)('user-rep-number', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    tags: (0, _model.hasMany)('tag', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    notificationSubscriptions: (0, _model.hasMany)('notification-subscription', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    targetEquipmentTransfers: (0, _model.hasMany)('equipment-transfer', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': 'targetUser'
    }),
    sourceEquipmentTransfers: (0, _model.hasMany)('equipment-transfer', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': 'sourceUser'
    }),
    userToLocations: (0, _model.hasMany)('user-to-location', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    userLicenses: (0, _model.hasMany)('user-license', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    udfs: (0, _model.hasMany)('udf', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    logs: (0, _model.hasMany)('log', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    })
  });

  _exports.default = _default;
});