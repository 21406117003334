define("securitytrax-ember-ui/components/multi-step-form/forms/work-orders/generate-purchase-orders/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/work-orders/work-orders-base", "ember-concurrency"], function (_exports, _workOrdersBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var GeneratePurchaseOrders = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember.computed(), _dec12 = (0, _emberConcurrency.task)(function* () {
    var hashedData = yield (0, _emberConcurrency.hash)({
      inventoryUsers: this.userService.getInventoryUsers.perform(),
      finishedCopyModels: (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true)
    });
    var installAppointments = this.multiStepForm.formOriginalModelsGetVal('workOrder.model.calendarEvents').sortBy('createdAt').reverse().filter(calendarEvent => {
      return calendarEvent.get('calendarEventType.name') === 'Install';
    });
    var inventoryUser = installAppointments.length > 0 ? hashedData.inventoryUsers.findBy('id', installAppointments[0].get('calendarUser.id')) : null;

    if (inventoryUser) {
      this.selectedInventoryUser = inventoryUser;
      this.hideSubmit = false;
    } else {
      this.selectedInventoryUser = hashedData.inventoryUsers.findBy('id', this.multiStepForm.formCopiedModelsGetVal('workOrder.model.assignedUser.id'));
      this.hideSubmit = false;
    }

    return hashedData.inventoryUsers;
  }), _dec13 = (0, _emberConcurrency.task)(function* () {
    var hashedData = yield (0, _emberConcurrency.hash)({
      equipmentVendors: this.store.findAll('equipmentVendor', {
        include: 'equipment_vendor_config'
      }),
      equipmentVendorToRecipients: this.grants.getIndividualGrant('equipment_vendor_to_recipients.view') ? this.store.query('equipmentVendorToRecipient', {
        limit: 0
      }) : [],
      technicians: this.inventoryUsers,
      equipment: this.inventoryService.get('getInventoryEquipmentTask').perform(),
      pendingPurchaseOrders: this.multiStepForm.formOriginalModelsGetVal('workOrder.model').invokeAction('get_wo_to_po_conversion_data', {
        verb: 'POST',
        data: {
          data: {
            attributes: {
              'exclude_current_inventory': !this.includeCurrentInventory,
              'inventory_user_id': this.selectedInventoryUser.get('id')
            }
          }
        }
      })
    });

    if (hashedData.pendingPurchaseOrders.responsePayload.data.meta.equipment_without_default_vendor) {
      hashedData.pendingPurchaseOrders.responsePayload.data.meta.equipment_without_default_vendor.forEach(item => {
        item['equipment'] = hashedData.equipment.find(piece => piece.get('id') == item.equipment_id);
      });
    }

    if (hashedData.pendingPurchaseOrders.responsePayload.data.attributes) {
      hashedData.pendingPurchaseOrders.responsePayload.data.attributes.forEach(po => {
        var type = po.recipient_type;
        var recipient = hashedData.technicians.find(recipient => recipient.get('id') == po.recipient_id);
        var vendor = hashedData.equipmentVendors.find(equipmentVendor => equipmentVendor.get('id') == po.vendor_id);
        var vendorToRecipient = hashedData.equipmentVendorToRecipients.find(vendorToRecipient => vendorToRecipient.get('id') == JSON.stringify({
          recipient_class: '100',
          recipient_id: po.recipient_id,
          vendor_id: po.vendor_id
        }));
        po['retrievalMethodId'] = vendorToRecipient ? vendorToRecipient.get('defaultRetrievalMethodId') : null;
        po['processingMethodId'] = vendorToRecipient ? vendorToRecipient.get('defaultProcessingMethodId') : null;
        po['branchNumber'] = vendorToRecipient && vendorToRecipient.get('defaultProcessingMethodId') === 100 ? vendorToRecipient.get('branchNumber') : null;
        po['recipient'] = recipient;
        po['equipmentVendor'] = vendor;
        po['type'] = type;
        po['removed'] = false, po['title'] = this.intl.t('pages.administration.company_settings.inventory.company_replenishment.pending_purchase_order_title', {
          name: recipient.get('flname'),
          vendor: vendor.get('name')
        });
      });
      this.set('hideSubmit', true);
      this.set('equipmentWithoutDefaultVendor', hashedData.pendingPurchaseOrders.responsePayload.data.meta.equipment_without_default_vendor);
      this.set('pendingPurchaseOrders', hashedData.pendingPurchaseOrders.responsePayload.data.attributes);
      this.set('showPendingPurchaseOrders', true);
    } else {
      this.set('hideSubmit', true);
      this.set('equipmentWithoutDefaultVendor', hashedData.pendingPurchaseOrders.responsePayload.data.meta.equipment_without_default_vendor || []);
      this.set('pendingPurchaseOrders', []);
      this.set('showPendingPurchaseOrders', true);
    }
  }), _dec14 = Ember._action, (_class = (_temp = class GeneratePurchaseOrders extends _workOrdersBase.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "userService", _descriptor, this);

      _initializerDefineProperty(this, "inventoryService", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);

      _initializerDefineProperty(this, "pendingPurchaseOrders", _descriptor5, this);

      _initializerDefineProperty(this, "equipmentWithoutDefaultVendor", _descriptor6, this);

      _initializerDefineProperty(this, "selectedInventoryUser", _descriptor7, this);

      _initializerDefineProperty(this, "showPendingPurchaseOrders", _descriptor8, this);

      _initializerDefineProperty(this, "includeCurrentInventory", _descriptor9, this);

      _initializerDefineProperty(this, "hideSubmit", _descriptor10, this);

      _initializerDefineProperty(this, "getInventoryUsersTask", _descriptor11, this);

      _initializerDefineProperty(this, "getPendingPurchaseOrderDataTask", _descriptor12, this);
    }

    get hiddenProgressBarSteps() {
      return [];
    }

    get disabledProgressBarSteps() {
      return [];
    }

    get equipmentToInstall() {
      return this.multiStepForm.formCopiedModelsGetVal('workOrder.model.workOrderEquipment').filter(workOrderEquipment => workOrderEquipment.workOrderAction.id === '1');
    }

    get inventoryUsers() {
      return this.getInventoryUsersTask.perform();
    }

    showSubmit() {
      this.hideSubmit = !this.selectedInventoryUser;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "userService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "inventoryService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "pendingPurchaseOrders", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "equipmentWithoutDefaultVendor", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "selectedInventoryUser", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "showPendingPurchaseOrders", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "includeCurrentInventory", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "hideSubmit", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "inventoryUsers", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "inventoryUsers"), _class.prototype), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "getInventoryUsersTask", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "getPendingPurchaseOrderDataTask", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showSubmit", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "showSubmit"), _class.prototype)), _class));
  _exports.default = GeneratePurchaseOrders;
});