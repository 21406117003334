define("securitytrax-ember-ui/mixins/switchable-form-step", ["exports", "securitytrax-ember-ui/utils/obj-helpers", "ember-concurrency"], function (_exports, _objHelpers, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    screen: Ember.inject.service(),
    stAwait: Ember.inject.service('st-await'),
    hiddenProgressBarSteps: null,
    disabledProgressBarSteps: null,
    progressBarHidden: false,
    loadedNamespace: undefined,
    classNames: ['multi-step-form-step'],
    possibleFormModes: {
      view: true,
      edit: true,
      create: true,
      inlineEdit: true
    },
    formMode: undefined,

    // view, edit, create, inlineEdit
    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        loadedNamespace: 'global',
        formMode: 'view'
      }); ////////////////////////////////////////////////////////////////////////////////
      // see if we should set all inputs to view mode based on loading/saving status

      var stAwait = this.stAwait;
      var fullyLoadedState = stAwait.getCurrentFullyLoadedStateByNamespace({
        loadedNamespace: this.loadedNamespace
      });

      if (this && !this.isDestroyed) {
        this.focusOnFirstInputInForm.perform(fullyLoadedState.fullyLoaded);
      }

      stAwait.on('st-await-loaded-state-by-namespace-' + this.loadedNamespace, this, '_onLoadedStateChange');
    },

    willDestroyElement() {
      this._super(...arguments);

      this.stAwait.off('st-await-loaded-state-by-namespace-' + this.loadedNamespace, this, '_onLoadedStateChange');
    },

    _onLoadedStateChange(loadedData) {
      Ember.run.next(() => {
        if (this && !this.isDestroyed) {
          this.focusOnFirstInputInForm.perform(loadedData.fullyLoaded);
        }
      });
    },

    didReceiveAttrs() {
      if (!this.multiStepForm) {
        throw new Error('You must pass the multistep form into a form step');
      }

      if (!this.formMode) {
        throw new Error('You must pass the formMode into a form step');
      }
    },

    isMobile: Ember.computed('screen.isMobile', function () {
      return this.get('screen.isMobile');
    }),
    progressBarConfig: Ember.computed('progressBarHidden', 'multiStepForm.{formSteps,currentStepNameKey}', 'hiddenProgressBarSteps', 'disabledProgressBarSteps', function () {
      var items = [];
      var multiStepForm = this.multiStepForm;
      var formSteps = this.multiStepForm.formSteps; //this.multiStepForm.formSteps;

      var currentStep = this.multiStepForm.currentStepNameKey; //this.multiStepForm.currentStepNameKey;

      Object.keys(formSteps).forEach(key => {
        if (typeof formSteps[key] === 'object') {
          var isHidden = this.hiddenProgressBarSteps.includes(key) || formSteps[key].granted != null && !formSteps[key].granted || formSteps[key].hidden;
          var isClickable = !this.disabledProgressBarSteps.includes(key);
          items.push({
            title: formSteps[key].progressBarTitle,
            stepNameKey: formSteps[key].stepNameKey,
            isClickable: isClickable,
            hidden: isHidden,
            isComplete: false,

            clickAction(item) {
              multiStepForm.currentStepNameKeySetVal(item.stepNameKey);
            }

          });
        }
      });
      return {
        hideProgressBar: this.progressBarHidden,
        activeIndex: items.findIndex(item => item.stepNameKey === currentStep),
        viewTemplate: 'unordered-view',
        items: items
      };
    }),
    isNewRecord: Ember.computed('formMode', function () {
      if (this.formMode === 'create') {
        return true;
      }

      return false;
    }),
    reloadButtonsOnFormModeChange: Ember.observer('formMode', function () {
      var buttonChoices = this.stepButtons;
      var multiStepForm = this.multiStepForm;

      if (buttonChoices) {
        multiStepForm.registerStepData(multiStepForm.currentStepNameKey, 'buttons', buttonChoices);
      }
    }),
    focusOnFirstInputInForm: (0, _emberConcurrency.task)(function* (fullyLoaded) {
      if (fullyLoaded) {
        yield (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true); // commenting for now as this has issues
        // next(() => {
        //     const inputs = jQuery(
        //         '.multi-step-form-step .form-mode-edit input:not([type="checkbox"]),' +
        //         '.multi-step-form-step .form-mode-create input:not([type="checkbox"]),' +
        //         '.multi-step-form-step .form-mode-create .ember-power-select-trigger,' +
        //         '.multi-step-form-step .form-mode-edit .ember-power-select-trigger'
        //     );
        //     inputs.first().focus();
        // });
      }
    }).restartable(),
    actions: {
      changeFormMode(newMode) {
        if (newMode in this.possibleFormModes) {
          this.set('formMode', newMode);
        } else {
          throw new Error('Trying to set an invalid form mode');
        }
      },

      validateProperty(originalModelKeyName, validationKey) {
        this.multiStepForm.validateProperty.perform(originalModelKeyName, validationKey);
      }

    }
  });

  _exports.default = _default;
});