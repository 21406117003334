define("securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/smart-home-pay-los/underwriting/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/smart-home-pay-los/smart-home-pay-los-base"], function (_exports, _smartHomePayLosBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SmartHomePayLosUnderwriting extends _smartHomePayLosBase.default {
    constructor() {
      super(...arguments);
    }

  }

  _exports.default = SmartHomePayLosUnderwriting;
});