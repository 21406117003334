define("securitytrax-ember-ui/components/multi-step-form/forms/system/guardian/upload-file/component", ["exports", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "ember-concurrency"], function (_exports, _component, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var ManageGuardianIntegration = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var stNotificationManager = this.stNotificationManager;
    var file = this.fileUploader.myDropzone.files[0];
    var postFileTask = this.postFileTask;
    var reader = new FileReader();
    reader.addEventListener('load', function () {
      postFileTask.perform(reader);
    });
    reader.addEventListener('error', function () {
      stNotificationManager.addErrorMessages(reader.error, {
        notificationNamespace: 'modal'
      });
    });
    reader.readAsDataURL(file);
    yield (0, _emberConcurrency.waitForProperty)(this, 'stopLight', false);

    if (this.postError) {
      throw new Error();
    }

    buttonPromise.then(() => {
      this.stNotificationManager.addSuccessKey('pages.administration.integrations.monitoringCompanies.integrations.guardian.upload_success', 'modal');
    });
  }), _dec9 = (0, _emberConcurrency.task)(function* (reader) {
    try {
      var file = this.fileUploader.myDropzone.files[0];
      var extension = file.name.split('.').pop().toLowerCase();
      var base64Data = reader.result.split(',').pop();
      yield this.centralStationService.postData({
        model: this.args.customer,
        method: 'guardian_protection_services__uploadFileToDart',
        payload: {
          file_contents_base_64_encoded: base64Data,
          file_extension: extension
        }
      });
      Ember.setProperties(this.fileUploader, {
        totalUploadProgress: 0,
        showTotalProgress: false,
        queueCount: 0
      });
      this.fileUploader.myDropzone.removeAllFiles(true);
      this.fileSelected = null;
      this.registerButtons();
      this.postError = false;
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      this.postError = true;
    }

    this.stopLight = false;
  }), _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = (_temp = class ManageGuardianIntegration extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "centralStationService", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "fileUploader", _descriptor4, this);

      _initializerDefineProperty(this, "fileSelected", _descriptor5, this);

      _initializerDefineProperty(this, "stopLight", _descriptor6, this);

      _initializerDefineProperty(this, "postError", _descriptor7, this);

      _initializerDefineProperty(this, "uploadFileTask", _descriptor8, this);

      _initializerDefineProperty(this, "postFileTask", _descriptor9, this);

      this.disabledProgressBarSteps = [];
      this.hiddenProgressBarSteps = [];
      this.progressBarHidden = this.args.formMode === 'create' ? true : false;
      this.SwitchableFormStep = new _switchableFormStep.default(...arguments, {
        progressBarHidden: this.progressBarHidden,
        disabledProgressBarSteps: this.disabledProgressBarSteps,
        hiddenProgressBarSteps: this.hiddenProgressBarSteps
      });
      this.registerButtons();
    }

    registerButtons() {
      this.args.multiStepForm.registerStepData('uploadFile', 'buttons', this.fileUploader && this.fileUploader.myDropzone.files.length > 0 ? [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          this.stopLight = true;
          return this.uploadFileTask.perform(buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary guardian-save-account-button'
      }] : []);
    }

    grabFileUploaderInstance(instance) {
      this.fileUploader = instance;
    }

    fileAdded() {
      this.registerButtons();
      this.fileSelected = true;
    }

    fileRemoved() {
      this.registerButtons();
      this.fileSelected = false;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "fileUploader", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "fileSelected", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "stopLight", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "postError", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "uploadFileTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "postFileTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "grabFileUploaderInstance", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "grabFileUploaderInstance"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fileAdded", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "fileAdded"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fileRemoved", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "fileRemoved"), _class.prototype)), _class));
  _exports.default = ManageGuardianIntegration;
});