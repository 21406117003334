define("securitytrax-ember-ui/utils/async", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.promiseAwait = promiseAwait;

  /**
   * Utility helper module for ajax requests.
   * @module
   */

  /**
   * wait synchronously for a promise to finish in non task/async code
   */
  function promiseAwait(promise) {
    var i = function* () {
      yield promise;
    }().next();

    return i.value;
  }
});