define("securitytrax-ember-ui/components/pages/administration/human-resources/permissions/group-details/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    addEditGroup: undefined,
    groupFormMode: 'view',
    currentRoute: Ember.computed('router.currentRouteName', function () {
      var route = this.router;
      return route.currentRouteName;
    }),
    setupFormTask: (0, _emberConcurrency.task)(function* () {
      var grants = this.grants;
      var canModify = yield grants.get('getIndividualGrantAsync').perform('groups.modify');
      this.set('groupFormMode', canModify ? 'edit' : 'view');
    }).on('init'),
    formOriginalModels: Ember.computed('model', 'grants.userGrants', function () {
      return Ember.Object.create({
        group: {
          model: this.model,
          copyRelationMap: {
            resourceTypeGrants: {
              doCopy: this.grants.getIndividualGrant('groups.manage_permissions'),
              doSync: false,
              doSave: false
            }
          }
        }
      });
    }),
    addEditGroupSteps: Ember.computed('grants.userGrants', 'model', function () {
      return Ember.Object.create({
        editGroup: Ember.Object.create({
          stepNameKey: 'editGroup',
          stepComponentPath: 'multi-step-form/forms/administration/permissions/group-details'
        })
      });
    })
  });

  _exports.default = _default;
});