define("securitytrax-ember-ui/components/multi-step-form/forms/payables/edit-create-payable/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/payables/edit-create-payable/base-component", "ember-concurrency", "securitytrax-ember-ui/utils/strings", "ember-inflector"], function (_exports, _baseComponent, _emberConcurrency, _strings, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    assignLevelFormStarted: false,
    levelUpdated: false,
    payOnObject: null,
    confirmDeleteModalOpen: false,
    payrollRoleFunctionLevel: null,
    payrollRoleFunction: null,
    levelLoaded: false,
    addOnPayables: undefined,
    reviewFormStarted: false,
    warningMessages: undefined,
    initDataTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true);
      this.registerButtons(!this.customerPayable);
      this.store.findAll('calendarUser');
      this.store.findAll('calendarEventType');

      if (!this.isNewRecord) {
        this.set('payrollRoleFunction', this.multiStepForm.formCopiedModelsGetVal('customerPayable.model.payrollRoleFunction'));
        this.set('payrollRoleFunctionLevel', this.get('customerPayable.payrollRoleFunctionLevel'));
        this.set('payeeName', yield this.multiStepForm.formOriginalModelsGetVal('customerPayable.model.payeeName'));
      } else {
        this.set('warningMessages', []);
        this.set('addOnPayables', []);
      }
    }).on('init'),
    finishedCopyModelsObserver: Ember.observer('multiStepForm.finishedCopyingModels', function () {
      this.registerButtons(!this.customerPayable);
    }),
    payrollPaymentStatuses: Ember.computed(function () {
      return this.store.findAll('payrollPaymentStatus');
    }),
    payrollRoleFunctions: Ember.computed(function () {
      return this.payrollRoleFunctionsTask.perform();
    }),
    payrollRoleFunctionsTask: (0, _emberConcurrency.task)(function* () {
      var payrollFunctions = yield this.store.findAll('payrollRoleFunction', {
        include: 'payroll_role,payroll_list_method,payroll_role_function_levels'
      });
      return payrollFunctions.filter(payrollFunction => {
        // PERF TODO: TRAX-10269 BE returning functions with a list method that is compatibility v2 and maybe shouldn't be?
        var entityType = payrollFunction.get('payrollListMethod.entityType');
        return entityType === 'user' || entityType === 'lead_company' || entityType === 'lead_company_campaign';
      });
    }),
    payeeOptions: Ember.computed('payrollRoleFunction', function () {
      return this.payeeOptionsTask.perform();
    }),
    payeeOptionsTask: (0, _emberConcurrency.task)(function* () {
      this.store.findAll('payrollListMethod');
      var payrollRoleFunction = yield this.store.findRecord('payrollRoleFunction', this.get('payrollRoleFunction.id'));
      var payees = yield payrollRoleFunction.invokeAction('get_payees', {
        // PERF TODO: Can we do includes on actions endpoints? If not, possible to implement?
        verb: 'POST',
        processResponse: true,
        data: {
          data: {
            relationships: {
              customer: {
                data: {
                  id: this.get('customer.id'),
                  type: 'customers'
                }
              }
            }
          }
        }
      });
      return payees.models;
    }),
    payrollRoleFunctionLevels: Ember.computed('payrollRoleFunction.payrollRoleFunctionLevels', function () {
      if (this.get('payrollRoleFunction.payrollRoleFunctionLevels')) {
        return this.get('payrollRoleFunction.payrollRoleFunctionLevels').filter(level => {
          return level.get('active');
        });
      }

      return [];
    }),
    entityRelationshipName: Ember.computed('payOnObject', function () {
      if (this.payOnObject && !this.payOnObject.constructor.modelName === 'calendar-event') {
        return Ember.String.camelize(this.payOnObject.constructor.modelName) + 'Entity';
      }

      return this.payOnObject ? 'userEntity' : null;
    }),
    assignLevelForm: Ember.computed('multiStepForm.formCopiedModels.customerPayable.model.{userEntity,leadCompanyEntity,leadCompanyCampaignEntity}', function () {
      var intl = this.intl;
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: intl.t('pages.customers.accounting.payables.assign_level_title', {
            userName: this.multiStepForm.formCopiedModelsGetVal('customerPayable.model.' + this.entityRelationshipName + '.name')
          }),
          stepComponentPath: 'multi-step-form/forms/payables/assign-level'
        })
      });
    }),
    reviewForm: Ember.computed(function () {
      var intl = this.intl;
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: intl.t('pages.customers.accounting.payables.payables_summary'),
          stepComponentPath: 'multi-step-form/forms/payables/edit-create-payable/review-payables'
        })
      });
    }),
    setPayrollRoleFunctionLevelTask: (0, _emberConcurrency.task)(function* (payee) {
      var allChecks = [];
      var payrollFunctionLevels = yield payee.get('payablePayeeLevels');

      for (var i = 0; i < payrollFunctionLevels.get('length'); i++) {
        allChecks.push(this.checkPayrollFunctionLevelTask.perform(payrollFunctionLevels.objectAt(i)));
      }

      yield (0, _emberConcurrency.all)(allChecks);
      this.set('levelLoaded', true);
    }),
    checkPayrollFunctionLevelTask: (0, _emberConcurrency.task)(function* (levelToCheck) {
      var level = yield levelToCheck.get('payrollRoleFunctionLevel');
      var payrollFunction = level ? yield level.get('payrollRoleFunction') : null;

      if (payrollFunction && this.get('payrollRoleFunction.id') === payrollFunction.get('id')) {
        this.set('payrollRoleFunctionLevel', level);
      }
    }),
    pendingPayable: Ember.computed('payrollRoleFunctionLevel', function () {
      return this.pendingPayableTask.perform();
    }),
    pendingPayableTask: (0, _emberConcurrency.task)(function* () {
      var payables;

      if (this.payOnObject && this.payrollRoleFunction && this.payrollRoleFunctionLevel && this.payrollRoleFunctionLevel.get('id') > 0) {
        var customerPayable = this.multiStepForm.formCopiedModelsGetVal('customerPayable.model');
        var dataBody = {
          relationships: {
            customer: {
              data: {
                id: this.get('customer.id'),
                type: 'customers'
              }
            }
          }
        };

        if (this.payOnObject.constructor.modelName === 'calendar-event') {
          var user = yield this.payOnObject.get('calendarUser.user');
          this.multiStepForm.formCopiedModelsSetVal('customerPayable.model.userEntity', user);
        }

        dataBody['relationships'][(0, _strings.camelAllCapsToSnake)(this.entityRelationshipName)] = {
          data: {
            id: customerPayable.get(this.entityRelationshipName + '.id'),
            type: (0, _emberInflector.pluralize)((0, _strings.camelAllCapsToSnake)(this.entityRelationshipName.replace('Entity', '')))
          }
        };

        if (this.payOnObject.constructor.modelName === 'calendar-event') {
          dataBody['relationships']['calendar_event'] = {
            data: {
              id: this.get('payOnObject.id'),
              type: 'calendar_events'
            }
          };
        }

        try {
          var response = yield this.payrollRoleFunction.invokeAction('create_customer_payable', {
            verb: 'POST',
            processResponse: true,
            data: {
              data: dataBody
            }
          });
          payables = response.models; // Either do this and see when else they can be displayed or display warnings here.

          this.set('warningMessages', this.warningMessages.concat(response.responsePayload.data.messages));

          if (payables) {
            payables.forEach(payable => {
              payable.set('doSave', true);
              payable.set('payInFull', true);
            });
          }
        } catch (err) {
          this.multiStepForm.formCopiedModelsSetVal('customerPayable.model.' + this.entityRelationshipName, null);
          this.stNotificationManager.addErrorMessages(err, {
            notificationNamespace: 'modal'
          });
          (false && Ember.warn('failed to create pending payable:' + err, {
            id: 'customers.accounting.payables.pendingPayableTask'
          }));
        }

        if (payables) {
          var entity = yield payables[0].get('entity');
          this.set('addOnPayables', payables.slice(1, payables.length));
          this.set('usesModal', false); //Gets the formCopiedModels to work correctly once formOriginalModels recomputes below

          this.set('payeeDisplayName', entity.get('payeeDisplayName'));
          this.set('customerPayable', payables[0]);
        }
      }

      var pendingPayable = payables ? payables[0] : null;

      if (pendingPayable && this.setPendingPayable) {
        this.setPendingPayable(pendingPayable);
      }

      return pendingPayable;
    }),
    displayPayableTask: (0, _emberConcurrency.task)(function* () {
      this.set('levelLoaded', false);
      var calendarUser = yield this.get('payOnObject.calendarUser');
      var user = calendarUser ? yield calendarUser.get('user') : this.payOnObject;
      this.multiStepForm.formCopiedModelsSetVal('customerPayable.model.' + this.entityRelationshipName, user);
      yield this.setPayrollRoleFunctionLevelTask.perform(user);
    }),
    deletePayableTask: (0, _emberConcurrency.task)(function* (buttonPromise) {
      try {
        var multiStepForm = this.multiStepForm;
        var payable = multiStepForm.formOriginalModelsGetVal('customerPayable.model');
        yield this.deletePaymentsTask.perform();
        yield payable.destroyRecordWithRollbackTask.perform();
        buttonPromise.then(() => {
          this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
          multiStepForm.formStartedSetVal(false);
          multiStepForm.onDelete();

          if (this.transitionBackToParent && typeof this.transitionBackToParent === 'function') {
            this.transitionBackToParent();
          }
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'confirmDelete'
        });
        throw err;
      }
    }),
    deletePaymentsTask: (0, _emberConcurrency.task)(function* () {
      var allDeletes = [];
      var payments = yield this.customerPayable.get('customerPayablePayments');
      payments.forEach(payment => {
        allDeletes.push(this.deletePaymentTask.perform(payment));
      });
      yield (0, _emberConcurrency.all)(allDeletes);
    }),
    deletePaymentTask: (0, _emberConcurrency.task)(function* (payment) {
      var realPayment = yield this.store.findRecord('customerPayablePayment', payment.get('id'));
      yield realPayment.destroyRecordWithRollbackTask.perform();
    }),

    registerButtons(disabled = false) {
      var multiStepForm = this.multiStepForm;
      var buttons = [];

      if (this.formMode !== 'view') {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            if (this.isNewRecord) {
              this.set('reviewFormStarted', true);
            } else {
              return this.updatePayableTask.perform(buttonPromise, multiStepForm, this.pendingPayable);
            }
          },
          disabled,
          classNamesString: 'st-btn st-btn-primary'
        });
      }

      if (!this.myAccount && !this.isNewRecord && this.grants.getIndividualGrant('customer_payables.delete')) {
        buttons.push({
          text: this.intl.t('common.actions.delete'),
          click: () => {
            this.set('confirmDeleteModalOpen', true);
          },
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
        });
      }

      multiStepForm.registerStepData('step1', 'buttons', buttons);
    },

    actions: {
      registerButtons() {
        this.registerButtons();
      }

    }
  });

  _exports.default = _default;
});