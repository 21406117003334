define("securitytrax-ember-ui/administration/company-settings/system/payables/function-details/level-details/line-item-details/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/route-pageview-logging", "securitytrax-ember-ui/mixins/has-page-title", "ember-concurrency"], function (_exports, _authenticatedRouteMixin, _routePageviewLogging, _hasPageTitle, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _routePageviewLogging.default, _hasPageTitle.default, {
    router: Ember.inject.service(),
    _controller: undefined,
    _model: undefined,
    queryParams: {
      payrollRoleFunctionLevelId: {
        refreshModel: true
      }
    },

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform();
    },

    model(params) {
      return this.setupModelTask.perform(params);
    },

    setupModelTask: (0, _emberConcurrency.task)(function* (params) {
      try {
        var payrollColumn = yield this.store.findRecord('payrollColumn', params.liid);
        var payrollRoleFunctionLevel = yield params.payrollRoleFunctionLevelId ? this.store.findRecord('payrollRoleFunctionLevel', params.payrollRoleFunctionLevelId) : payrollColumn.get('payrollRoleFunctionLevel');
        var payrollRoleFunction = yield payrollRoleFunctionLevel ? payrollRoleFunctionLevel.get('payrollRoleFunction') : payrollColumn.get('payrollRoleFunction');
        var model = yield (0, _emberConcurrency.hash)({
          payrollRoleFunction: payrollRoleFunction,
          payrollRoleFunctionLevel: payrollRoleFunctionLevel,
          payrollColumn
        });
        this.set('_model', model);
        return model;
      } catch (err) {
        this.transitionTo('administration.company-settings.system.payables');
      }
    }),

    setupController(controller, model) {
      this._super(controller, model);

      this.set('_model', model);
      this.set('_controller', controller);
      this.setupBreadCrumbs(controller, model);
    },

    getPageTitle() {
      return this.intl.t('pages.administration.human_resources.payables');
    },

    getRouteName() {
      return "pages.administration.nav.company-settings";
    },

    setupBreadCrumbs(controller, model) {
      var breadCrumbs = [{
        name: this.intl.t('pages.administration.human_resources.payables'),
        last: false,
        previousLink: 'administration.company-settings',
        link: 'administration.company-settings.system.payables'
      }, {
        name: model.payrollRoleFunction.get('name'),
        last: false,
        previousLink: 'administration.company-settings.system.payables',
        link: 'administration.company-settings.system.payables.function-details',
        linkArg: model.payrollRoleFunction.get('id')
      }, {
        name: model.payrollRoleFunctionLevel.get('name'),
        last: false,
        previousLink: 'administration.company-settings.system.payables.function-details',
        link: 'administration.company-settings.system.payables.function-details.level-details',
        linkArg: model.payrollRoleFunctionLevel.get('id')
      }, {
        name: model.payrollColumn.get('name'),
        last: true,
        previousLink: 'administration.company-settings.system.payables.function-details.level-details',
        previousLinkArg: model.payrollRoleFunctionLevel.get('id'),
        link: 'administration.company-settings.system.payables.function-details.level-details.line-item-details'
      }];
      var breadCrumbData = {
        title: this.intl.t('pages.administration.company_settings.title'),
        titleLink: 'administration.company-settings',
        hide: false
      };
      controller.set('breadCrumbs', breadCrumbs);
      controller.set('breadCrumbData', breadCrumbData);
    },

    actions: {
      didTransition() {
        if (this._model && this._controller) {
          this.setupBreadCrumbs(this._controller, this._model);
        }
      }

    }
  });

  _exports.default = _default;
});