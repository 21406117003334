define("securitytrax-ember-ui/components/html/dashboard-card/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * dashboard card
   *
   * @class Component
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    tagName: '',
    cardTitle: '',
    actions: {
      changeRoute(route) {
        this.router.transitionTo(route);
      }

    }
  });

  _exports.default = _default;
});