define("securitytrax-ember-ui/components/multi-step-form/forms/system/cms/cms-base", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step"], function (_exports, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    centralStationService: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    intl: Ember.inject.service(),
    progressBarHidden: true,
    cmsStepReady: false,
    hiddenProgressBarSteps: Ember.computed(function () {
      return [];
    }),
    disabledProgressBarSteps: Ember.computed('cmsStepReady', function () {
      if (!this.cmsStepReady) {
        return ['general', 'agencies', 'dispatch', 'history']; //disable all steps during actions
      }

      return [];
    }),
    cmsStepReadyObserver: Ember.observer('cmsStepReady', function () {
      var multiStepForm = this.multiStepForm;
      var currentStepNameKey = multiStepForm.currentStepNameKey;
      multiStepForm.registerStepData(currentStepNameKey, 'hideTopRightCloseButton', !this.cmsStepReady);
    })
  });

  _exports.default = _default;
});