define("securitytrax-ember-ui/components/pages/reports/adt-reports/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    centralStationService: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    stFiltersService: Ember.inject.service(),
    adtMonitoringCompany: null,
    reportType: null,
    dealerNumber: null,
    showReportList: false,
    appliedFiltersCount: 0,
    initData: (0, _emberConcurrency.task)(function* () {
      var company = yield this.sessionData.getCompany();
      this.set('dealerNumber', company.get('dealerNumber'));
    }).on('init'),
    adtMonitoringCompanies: Ember.computed(function () {
      return this.adtMonitoringCompaniesTask.perform();
    }),
    adtMonitoringCompaniesTask: (0, _emberConcurrency.task)(function* () {
      var companies = yield this.store.query('monitoringCompany', {
        filter: 'monitoring_company_type = 1'
      });

      if (companies.length === 1) {
        this.set('adtMonitoringCompany', companies.objectAt(0));
      }

      return companies;
    }),
    reportTypes: Ember.computed('adtMonitoringCompany', function () {
      return this.reportTypesTask.perform();
    }),
    reportTypesTask: (0, _emberConcurrency.task)(function* () {
      var reportTypeList = [];
      var reportTypes;

      try {
        reportTypes = yield this.centralStationService.getData({
          model: this.adtMonitoringCompany,
          method: 'adt__getReportTypes',
          responseKey: 'report_types'
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err);
        (false && Ember.warn('error retrieving report types from adt:' + err, {
          id: 'reports.adt-reports.reportTypesTask'
        }));
      }

      if (reportTypes) {
        reportTypeList = reportTypes.map(reportType => {
          return {
            name: reportType.name,
            id: reportType.id
          };
        });
      }

      return reportTypeList;
    }),
    adtReports: Ember.computed(function () {
      return this.adtReportsTask.perform();
    }),
    adtReportsTask: (0, _emberConcurrency.task)(function* () {
      var reports = [];

      try {
        var newFilters = yield this.stFiltersService.getFiltersByGroupKeyAsObject('adtReports', [], {
          adtReportsMonitoringCompany: true
        });
        reports = yield this.centralStationService.getData({
          model: this.adtMonitoringCompany,
          method: 'adt__getReports',
          responseKey: 'reports',
          payload: newFilters
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err);
        (false && Ember.warn('error retrieving reports from adt:' + err, {
          id: 'reports.adt-reports.adtReportsTask'
        }));
        throw err;
      }

      return reports;
    }),
    downloadReport: (0, _emberConcurrency.task)(function* (row) {
      try {
        var report = yield this.centralStationService.getData({
          model: this.adtMonitoringCompany,
          method: 'adt__getReport',
          responseKey: 'report',
          payload: {
            dealer_number: this.dealerNumber,
            report_type: this.reportType,
            report_id: row.id
          }
        });
        var linkSource = "data:application/".concat(report.extension, ";base64,").concat(report.data.raw);
        var downloadLink = document.createElement('a');
        var fileName = report.file_name;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err);
        (false && Ember.warn('error retrieving report from adt:' + err, {
          id: 'reports.adt-reports.downloadReport'
        }));
        throw err;
      }
    }),
    actions: {
      clearFilter() {
        Ember.setProperties(this, {
          adtMonitoringCompany: null,
          reportType: null,
          dealerNumber: null,
          showReportList: false
        });
      },

      runReport() {
        this.notifyPropertyChange('adtReports');
        this.set('showReportList', true);
      }

    }
  });

  _exports.default = _default;
});