define("securitytrax-ember-ui/reports/work-order-reports/route", ["exports", "securitytrax-ember-ui/reports/route", "securitytrax-ember-ui/mixins/route-pageview-logging", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _route, _routePageviewLogging, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_authenticatedRouteMixin.default, _routePageviewLogging.default, {
    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform();
    },

    model: function () {
      var parentModel = this._super(...arguments);

      return parentModel;
    }
  });

  _exports.default = _default;
});