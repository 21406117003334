define("securitytrax-ember-ui/utils/obj-helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isObject = isObject;
  _exports.getObjProperties = getObjProperties;
  _exports.initializeStateOctane = initializeStateOctane;
  _exports.initializeState = initializeState;
  _exports.censor = censor;
  _exports.createEmberObjectRecursive = createEmberObjectRecursive;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function isObject(val) {
    if (val === null) {
      return false;
    }

    return typeof val === 'function' || typeof val === 'object';
  }
  /**
   * Harvests properties from the passed object and returns their values in an array.
   *
   * @param {Object} obj The object that contains the properties to harvest.
   * @param {...String} names A variable set of names of the properties to harvest.
   *
   * @returns {Array}
   *
   * @static
   * @memberof utils.obj-helpers
   */


  function getObjProperties(obj, ...names) {
    return names.map(name => {
      if (typeof obj.get === 'function') {
        return Ember.get(obj, name);
      } else {
        return obj.name;
      }
    });
  }
  /**
   * Updates the passed context with the passed properties, provided they don't already have a value.
   *
   * @param {Object} context The context to update.
   * @param {Object} properties The properties to update.
   *
   * @static
   */


  function initializeStateOctane(context, args) {
    Object.keys(args).forEach(currKey => {
      context[currKey] = args[currKey];
    });
  }
  /**
   * Updates the passed context with the passed properties, provided they don't already have a value.
   *
   * @param {Object} context The context to update.
   * @param {Object} properties The properties to update.
   *
   * @static
   */


  function initializeState(context, properties) {
    Ember.setProperties(context, Object.keys(properties).reduce((props, key) => _objectSpread(_objectSpread({}, props), {}, {
      [key]: Ember.getWithDefault(context, key, properties[key])
    }), {}));
  }
  /**
   * Censors JSON objects that are circular, to avoid errors
   * @param object
   */


  function censor(censor) {
    var i = 0;
    return function (key, value) {
      if (i !== 0 && typeof censor === 'object' && typeof value == 'object' && censor == value) return '[Circular]';
      if (i >= 29) // seems to be a harded maximum of 30 serialized objects?
        return '[Unknown]';
      ++i; // so we know we aren't using the original object anymore

      return value;
    };
  }
  /**
   * Given a basic struct/object, resursively turn it into EmberObjects
   *
   * @param {*} data
   * @static
   */


  function createEmberObjectRecursive(data) {
    // if input is a list, return a list
    if (Array.isArray(data)) {
      var a = [];

      for (var i = 0; i < data.length; i++) {
        if (typeof data[i] === 'object') {
          a.push(createEmberObjectRecursive(data[i]));
        }
      }

      return a;
    }

    var obj = Ember.Object.create(data);

    for (var key in data) {
      if (data.hasOwnProperty(key)) {
        (function () {
          var arrayIterator = function (a) {
            for (var _i = 0; _i < a.length; _i++) {
              if (Array.isArray(a[_i])) {
                a[_i] = arrayIterator(a[_i]);
              } else if (typeof a[_i] === 'object') {
                a[_i] = createEmberObjectRecursive(a[_i]);
              }
            }

            return a;
          };

          if (Array.isArray(data[key])) {
            obj.set(key, arrayIterator(data[key]));
          } else if (typeof data[key] === 'object') {
            obj.set(key, createEmberObjectRecursive(data[key]));
          } else {
            obj.set(key, data[key]);
          }
        })();
      }
    }

    return obj;
  }
});