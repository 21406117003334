define("securitytrax-ember-ui/components/customer/work-orders/delete-work-orders/component", ["exports", "moment", "ember-concurrency"], function (_exports, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    deleteAppointmentsText: undefined,
    deleteAll: false,
    calendarEvents: Ember.computed('workOrder', function () {
      return this.calendarEventsTask.perform();
    }),
    calendarEventsTask: (0, _emberConcurrency.task)(function* () {
      var calendarEvents = yield this.store.query('calendar-event', {
        filter: "(work_order = ".concat(this.workOrder.id, ") and (start_time >= ").concat((0, _moment.default)().unix(), ")"),
        include: 'calendar_user,calendar_event_type'
      });
      this.set('deleteAppointmentsText', this.intl.t('pages.customers.workOrders.details.delete_work_order_appointments', {
        numAppointments: calendarEvents.length
      }));
      this.set('observableCalendarEvents', calendarEvents);
      return calendarEvents;
    }),
    initTask: (0, _emberConcurrency.task)(function* () {
      this.set('observableCalendarEvents', yield this.calendarEvents);
    }).on('init'),
    deleteButtons: Ember.computed(function () {
      var buttons = [{
        text: this.intl.t('common.actions.delete'),
        classNamesString: 'st-btn st-btn-warn delete confirm-modal-confirm-button',
        click: buttonPromise => {
          return this.deleteWorkOrderAndAppointments.perform(buttonPromise);
        }
      }];
      return buttons;
    }),
    deleteAllTask: (0, _emberConcurrency.task)(function* () {
      var calendarEvents = yield this.calendarEvents;
      calendarEvents.forEach(calendarEvent => {
        calendarEvent.set('setToDelete', this.deleteAll);
      });
    }),
    deleteAllToggleObserver: Ember.observer('observableCalendarEvents.@each.setToDelete', function () {
      this.toggleDeleteAllTask.perform();
    }),
    toggleDeleteAllTask: (0, _emberConcurrency.task)(function* () {
      var calendarEvents = yield this.calendarEvents;
      var filteredCalendarEvents = calendarEvents.filter(event => {
        return event.setToDelete;
      });

      if (filteredCalendarEvents.length === 0) {
        this.set('deleteAll', false);
      } else if (filteredCalendarEvents.length === calendarEvents.length) {
        this.set('deleteAll', true);
      }
    }),
    deleteWorkOrderAndAppointments: (0, _emberConcurrency.task)(function* (buttonPromise) {
      var calendarEvents = yield this.calendarEvents;
      calendarEvents.forEach(event => {
        if (event.setToDelete) {
          event.destroyRecordWithRollbackTask.perform();
        }
      });
      return this.deleteWorkOrderTask.perform(buttonPromise);
    })
  });

  _exports.default = _default;
});