define("securitytrax-ember-ui/application/controller", ["exports", "jquery", "ember-concurrency", "securitytrax-ember-ui/config/environment"], function (_exports, _jquery, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  window.__lc = window.__lc || {};
  window.__lc.license = 11663643;
  /**
   * The main application controller.
   *
   * @class Controller
   * @extends Ember.Controller
   * @memberof application
   */

  var Controller = Ember.Controller.extend(Ember.Evented, {
    session: Ember.inject.service(),
    grants: Ember.inject.service(),
    screen: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    hideLayout: false,
    mobileAddNew: '',
    setupSuperAdminConsoleOpen: false,
    showResetPasswordModal: false,

    init() {
      this.set('showResetPasswordModal', false);

      if (this.session.forcePasswordUpdate) {
        this.set('showResetPasswordModal', true);
      }

      this.session.on('forcePasswordUpdate', () => {
        this.set('showResetPasswordModal', true);
      });
      this.session.on('afterForcePasswordChange', () => {
        this.set('showResetPasswordModal', false);
        this.placeLiveChatTask.perform();
      });

      this._super(...arguments);

      this.setupLiveChat();
      this.setupSuperAdminConsoleTask.perform();
    },

    screenChangeObserver: Ember.observer('screen.isMobile', function () {
      if (window.LiveChatWidget) {
        if (this.screen.isMobile) {
          window.LiveChatWidget.call('hide');
        } else {
          window.LiveChatWidget.call('minimize');
        }
      }
    }),
    setupLiveChat: function () {
      if (_environment.default['environment'] == 'production' || _environment.default['securitytrax-ember-ui']['api-e2e-test-config']['local-tests']['runTests'] || _environment.default['securitytrax-ember-ui']['api-e2e-test-config']['ci-tests']['runTests']) {
        if (this.session.isAuthenticated) {
          this.placeLiveChatTask.perform();
        } else {
          this.session.on('authenticationSucceeded', () => {
            this.placeLiveChatTask.perform();
          });
        }
      }
    },
    placeLiveChatTask: (0, _emberConcurrency.task)(function* () {
      var hashData = yield (0, _emberConcurrency.hash)({
        loggedInUser: this.sessionData.getUser(),
        hasGroupsView: this.grants.get('getIndividualGrantAsync').perform('groups.view')
      });

      if (hashData.loggedInUser && hashData.hasGroupsView && (hashData.loggedInUser.belongsTo('group').id() === '1' || hashData.loggedInUser.belongsTo('group').id() === '1000')) {
        var mashedUpname = '(' + _environment.default[_environment.default.modulePrefix]['profile'] + ') ' + hashData.loggedInUser.lfname; // <!-- Start of LiveChat (www.livechatinc.com) code -->

        window.__lc.params = [{
          name: 'user.name',
          value: hashData.loggedInUser.flname
        }, {
          name: 'user.id',
          value: hashData.loggedInUser.id
        }, {
          name: 'profileName',
          value: _environment.default[_environment.default.modulePrefix]['profile']
        }, {
          name: 'user.email',
          value: hashData.loggedInUser.email
        }];
        window.__lc.visitor = {
          name: mashedUpname,
          email: hashData.loggedInUser.email
        }; // eslint-disable-next-line

        ;

        (function (n, t, c) {
          function i(n) {
            return e._h ? e._h.apply(null, n) : e._q.push(n);
          }

          var e = {
            _q: [],
            _h: null,
            _v: "2.0",
            on: function () {
              i(["on", c.call(arguments)]);
            },
            once: function () {
              i(["once", c.call(arguments)]);
            },
            off: function () {
              i(["off", c.call(arguments)]);
            },
            get: function () {
              if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load.");
              return i(["get", c.call(arguments)]);
            },
            call: function () {
              i(["call", c.call(arguments)]);
            },
            init: function () {
              var n = t.createElement("script");
              n.async = !0, n.type = "text/javascript", n.src = "https://cdn.livechatinc.com/tracking.js", t.head.appendChild(n);
            }
          };
          !n.__lc.asyncInit && e.init(), n.LiveChatWidget = n.LiveChatWidget || e;
        })(window, document, [].slice);
      }
    }),
    setupSuperAdminConsoleTask: (0, _emberConcurrency.task)(function* () {
      if (_environment.default['environment'] != 'test') {
        var hashData = yield (0, _emberConcurrency.hash)({
          loggedInUser: this.sessionData.getUser(),
          isEmulated: this.get('sessionData.isEmulatedUser')
        });

        if (hashData.loggedInUser && hashData.loggedInUser.isSecurityTraxSuperUser || hashData.isEmulated) {
          (0, _jquery.default)(document).keypress(event => {
            if (event.charCode == 126) {
              this.toggleProperty('setupSuperAdminConsoleOpen');
            }
          });
        }
      }
    }),
    actions: {
      invalidateSession() {
        var pathname = window.location.pathname;
        var url = pathname.split('/').slice(2).join('/');
        localStorage.setItem('storedURL', url);
        this.session.invalidate();
      },

      goToCustomerInformation(customer) {
        this.transitionToRoute("/customers/".concat(customer.get('id'), "/information"), {
          customer
        });
      },

      closeMobileAddNew() {
        this.set('mobileAddNew', '');
      }

    }
  });
  var _default = Controller;
  _exports.default = _default;
});