define("securitytrax-ember-ui/helpers/format-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Extend intl format-number to handle null/undefined values
   * @param {*} params 
   */
  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),

    compute(params, hash) {
      if (!params[0] && hash.style == 'currency') {
        if ('nullValue' in hash) {
          return hash.nullValue;
        } else {
          return '$0.00';
        }
      } else if (!params[0]) {
        return '0';
      }

      if (hash.style === 'currency') {
        return ('$' + Number(params[0]).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')).replace('$-', '-$');
      }

      return this.intl.formatNumber(params[0], hash);
    }

  });

  _exports.default = _default;
});