define("securitytrax-ember-ui/components/inventory/pending-purchase-order/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/grouped-equipment"], function (_exports, _emberConcurrency, _groupedEquipment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_groupedEquipment.default, {
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    inventoryService: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    grants: Ember.inject.service(),
    purchaseOrderFormStarted: false,
    pendingPurchaseOrderLoaded: false,
    removed: false,
    items: Ember.computed.alias('purchaseOrder.pendingEquipmentLineItems'),
    branchNumbers: undefined,
    retrievalMethods: undefined,
    processingMethods: undefined,
    formStarted: false,
    classes: '',
    allowEdit: true,
    recipientOptions: Ember.computed(function () {
      return this.inventoryService.get('getInventoryRecipients').perform();
    }),
    formSteps: Ember.computed('formMode', 'purchaseOrder.recipient.id', function () {
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.intl.t("pages.users.my_inventory.purchase_orders.review_title", {
            recipient: this.get('purchaseOrder.recipient.fname') ? this.get('purchaseOrder.recipient.fname') + ' ' + this.get('purchaseOrder.recipient.lname') : this.get('purchaseOrder.recipient.name'),
            vendor: this.get('purchaseOrder.equipmentVendor.name')
          }),
          stepComponentPath: 'multi-step-form/forms/administration/users/my-inventory/purchase-orders/review'
        })
      });
    }),
    purchaseOrderForm: Ember.computed('purchaseOrder.title', function () {
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.get('purchaseOrder.title'),
          stepComponentPath: 'multi-step-form/forms/administration/users/my-inventory/purchase-orders/create-purchase-order'
        })
      });
    }),
    formOriginalModels: Ember.computed('purchaseOrder', function () {
      return Ember.Object.create({
        equipmentPurchaseOrder: {
          model: null,
          copyRelationMap: {
            user: {
              readOnly: true
            },
            location: {
              readOnly: true
            },
            createdByUser: {
              readOnly: true
            },
            equipmentVendor: {
              readOnly: true
            },
            pendingEquipmentLineItems: {
              relationships: {
                equipment: {
                  readOnly: true
                }
              }
            }
          }
        }
      });
    }),
    initData: (0, _emberConcurrency.task)(function* () {
      var items = [];
      var allTasks = []; // PERF TODO: Data used for pending purchase order good test case for batch requests? Also use inventory user to cache some of these recipient based things?

      var hashedData = yield (0, _emberConcurrency.hash)({
        equipmentInventory: this.purchaseOrder.recipient.invokeAction('get_admin_equipment_inventory', {
          verb: 'GET'
        }),
        equipment: this.inventoryService.get('getInventoryEquipmentTask').perform(),
        equipmentToVendors: this.equipmentToVendors ? this.equipmentToVendors : this.store.findAll('equipmentToVendor')
      });
      var additionalInformation = yield this.purchaseOrder.equipmentVendor.get('additionalInformation');
      var processing = yield this.purchaseOrder.equipmentVendor.get('processingMethods');

      for (var i = 0; i < this.purchaseOrder.items.length; i++) {
        allTasks.push(this.createItemTask.perform(items, this.purchaseOrder.items[i], hashedData));
      }

      yield (0, _emberConcurrency.all)(allTasks);
      this.set('branchNumbers', additionalInformation ? additionalInformation.branch_numbers : []);
      this.set('retrievalMethods', additionalInformation ? additionalInformation.retrieval : []);
      this.set('processingMethods', processing);
      this.set('pendingPurchaseOrderLoaded', true);
      this.set('purchaseOrder.pendingEquipmentLineItems', items);
    }).on('init'),
    createItemTask: (0, _emberConcurrency.task)(function* (items, item, hashedData) {
      var equipmentItem = hashedData.equipment.find(piece => piece.get('id') == item.equipment_id);
      var equipmentCounts = hashedData.equipmentInventory.responsePayload.data.find(equipmentCount => equipmentCount.equipment_id == item.equipment_id);
      var equipmentToVendor = hashedData.equipmentToVendors.find(equipmentToVendor => equipmentToVendor.get('id') == JSON.stringify({
        equipment_id: item.equipment_id.toString(),
        vendor_id: this.purchaseOrder.vendor_id
      }));

      if (equipmentItem) {
        items.push(Ember.Object.create({
          equipment: equipmentItem,
          quantity: item.quantity,
          onHand: equipmentCounts ? equipmentCounts.on_hand_quantity : 0,
          onOrder: equipmentCounts ? equipmentCounts.on_order_quantity : 0,
          base: equipmentCounts ? equipmentCounts.base_quantity : this.intl.t('pages.administration.inventory.perform_physical_inventory.not_set'),
          min: equipmentCounts ? equipmentCounts.min_quantity : this.intl.t('pages.administration.inventory.perform_physical_inventory.not_set'),
          onTransfer: equipmentCounts ? equipmentCounts.on_transfer_quantity : 0,
          difference: equipmentCounts ? equipmentCounts.difference : 0,
          extended: item.quantity * equipmentItem.get('cost'),
          vendorSku: equipmentToVendor ? equipmentToVendor.get('sku') : equipmentItem.get('sku'),
          parent: item.parent_id ? yield this.store.find('pendingEquipmentLineItem', item.parent_id) : null
        }));
      }
    }),
    savePurchaseOrderTask: (0, _emberConcurrency.task)(function* (buttonPromise, purchaseOrder = null, notificationNamespace = 'global', lineItemsToCreate = null) {
      var po = purchaseOrder || this.purchaseOrder;
      var lineItems = [];
      var pendingEquipmentLineItems = lineItemsToCreate || po.pendingEquipmentLineItems;
      this.set('pendingPurchaseOrderLoaded', false);

      try {
        pendingEquipmentLineItems.forEach(item => {
          if (item.quantity > 0) {
            var newLineItemObject = {
              parentPendingEquipmentLineItem: item.parent ? item.parent : null,
              cost: Number(item.equipment.get('cost')) || 0,
              quantity: item.quantity || 0,
              fulfilledQuantity: 0
            };

            if (item.equipment.constructor.modelName === 'equipment') {
              newLineItemObject.equipment = item.equipment;
            } else {
              newLineItemObject.equipmentKit = item.equipment;
            }

            var newPendingEquipmentLineItem = this.store.createRecord('pendingEquipmentLineItem', newLineItemObject);
            lineItems.push(newPendingEquipmentLineItem);
          }
        });
        var toCreatePurchaseOrder = this.store.createRecord('equipmentPurchaseOrder', {
          pendingEquipmentType: 'po',
          user: po.recipient.constructor.modelName === 'inventory-user' ? yield po.recipient.get('user') : po.recipient.constructor.modelName === 'user' ? po.recipient : null,
          location: po.recipient.constructor.modelName === 'location' ? po.recipient : null,
          parentEquipmentPurchaseRequisition: po.parent ? po.parent : null,
          catalyst: po.parent ? 'manual' : 'semi_auto_replenish',
          equipmentVendor: po.equipmentVendor,
          retrievalMethodId: po.retrievalMethodId,
          processingMethodId: po.processingMethodId,
          branchNumber: po.branchNumber,
          pendingEquipmentLineItems: lineItems,
          comments: po.comments
        });
        var newPurchaseOrder = yield toCreatePurchaseOrder.save();
        buttonPromise.then(() => {
          this.set('purchaseOrder.created', true);
          this.set('purchaseOrder.title', this.intl.t('pages.administration.company_settings.inventory.company_replenishment.created_purchase_order_title', {
            name: po.recipient.get('flname') || po.recipient.get('name'),
            vendor: po.equipmentVendor.get('name'),
            id: newPurchaseOrder.get('id')
          }));
          this.set('formStarted', false);
          this.set('purchaseOrderFormStarted', false);
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'global');
          this.set('pendingPurchaseOrderLoaded', true);

          if (this.refreshList) {
            this.refreshList();
          }
        });
      } catch (err) {
        this.set('pendingPurchaseOrderLoaded', true);
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace
        });
        throw err;
      }
    }),
    actions: {
      deletePendingPurchaseOrder() {
        this.set('removed', true);
        this.set('purchaseOrder.removed', true);
        this.set('purchaseOrderDeleteId', null);
      }

    }
  });

  _exports.default = _default;
});