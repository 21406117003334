define("securitytrax-ember-ui/components/pages/app-login-callback/component", ["exports", "@glimmer/component", "ember-concurrency", "@sentry/browser", "securitytrax-ember-ui/config/environment"], function (_exports, _component, _emberConcurrency, Sentry, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var LoginCallback = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = (0, _emberConcurrency.task)(function* () {
    var url = this.router.currentURL;
    var partialURL = url.substring(url.indexOf('#') + 1);
    var params = partialURL.split('&');
    var id_token = '';
    params.every(param => {
      var nameValue = param.split('=');

      if (nameValue[0] === 'id_token') {
        id_token = nameValue[1];
        return false;
      }

      return true;
    });

    if (id_token === '') {
      this.router.transitionTo('login', {
        queryParams: {
          error: this.intl.t('pages.login.generic_provider_error')
        }
      });
    } else {
      var credentials = {
        id_token
      };

      try {
        this.session.set('forcePasswordUpdate', false);
        yield this.session.authenticate('authenticator:jwt', credentials);

        if (_environment.default.environment != 'test' || _environment.default['securitytrax-ember-ui']['api-e2e-test-config']['local-tests']['runTests'] || _environment.default['securitytrax-ember-ui']['api-e2e-test-config']['ci-tests']['runTests']) {
          var sessionDataHash = yield (0, _emberConcurrency.hash)({
            resetHeaders: this.stAjax.resetHeaders(),
            tokenData: this.sessionData.getTokenData()
          }, 'Login session data hash');

          if (sessionDataHash.tokenData.fpc == true) {
            this.session.set('forcePasswordUpdate', true);
            this.session.trigger('forcePasswordUpdate');
          }

          if (_environment.default.environment != 'test' && sessionDataHash.tokenData && sessionDataHasth.tokenData.sub) {
            Sentry.configureScope(scope => {
              scope.setUser({
                id: sessionDataHash.tokenData.sub.user_id
              });

              if (window.Cypress) {
                scope.setTag('cypress', true);
              }

              scope.setTag('company_id', sessionDataHash.tokenData.sub.company_id);
            });
          }
        }
      } catch (errorResponse) {
        var error = errorResponse.json.errors[0].detail;
        this.router.transitionTo('login', {
          queryParams: {
            error: error
          }
        });
      }
    }
  }), (_class = (_temp = class LoginCallback extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "sessionData", _descriptor4, this);

      _initializerDefineProperty(this, "intl", _descriptor5, this);

      _initializerDefineProperty(this, "stAjax", _descriptor6, this);

      _initializerDefineProperty(this, "tagName", _descriptor7, this);

      _initializerDefineProperty(this, "authenticateTask", _descriptor8, this);

      this.authenticateTask.perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "stAjax", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "tagName", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "authenticateTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = LoginCallback;
});