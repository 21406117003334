define("securitytrax-ember-ui/components/html/user-tree/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* import { inject as service } from '@ember/service';
   */
  var _default = Ember.Component.extend({
    classNames: [''],
    mediaOrientation: 'st-media-object--portrait',
    wellUsers: Ember.computed('userHierarchy', function () {
      return this.getWellTask.perform();
    }),
    parent: Ember.computed('userHierarchy', function () {
      return this.getParentTask.perform();
    }),
    grandParents: Ember.computed('userHierarchy', function () {
      return this.getGrandParentsTask.perform();
    }),
    userHierarchy: Ember.computed('user', function () {
      this.set('userHierarchySet', false);
      return this.getHiearchyTask.perform();
    }),
    userHierarchySet: false,

    init() {
      this._super(...arguments);

      this.getHiearchyTask.perform();
    },

    willUpdate() {
      this._super(...arguments);

      this.getHiearchyTask.perform();
    },

    getHiearchyTask: (0, _emberConcurrency.task)(function* () {
      var user = yield this.user;

      if (user) {
        var userHierarchies = yield user.get('userHierarchies');

        if (userHierarchies) {
          userHierarchies.forEach(uH => {
            this.set('userHierarchy', uH);
            this.set('userHierarchySet', true);
          });
        }
      }
    }),
    getWellTask: (0, _emberConcurrency.task)(function* () {
      var user = yield this.user;

      if (user) {
        yield (0, _emberConcurrency.waitForProperty)(this, 'userHierarchySet', true);
        var userHierarchy = this.userHierarchy;

        if (userHierarchy) {
          return userHierarchy.get('children');
        }
      } else {
        if (this.company) {
          var _userHierarchy = yield this.routeUsers.toArray();

          var wellUsers = Ember.A();

          for (var x = 0; x < _userHierarchy.length; x++) {
            var parent = yield _userHierarchy[x].get('parent');

            if (!parent) {
              wellUsers.push(_userHierarchy[x]);
            }
          }

          return wellUsers;
        }
      }
    }),
    getParentTask: (0, _emberConcurrency.task)(function* () {
      var user = yield this.user;

      if (user) {
        yield (0, _emberConcurrency.waitForProperty)(this, 'userHierarchySet', true);
        var userHierarchy = this.userHierarchy;
        var parent = yield userHierarchy.get('parent');

        if (parent) {
          return parent.get('user');
        }
      }

      return null;
    }),
    getGrandParentsTask: (0, _emberConcurrency.task)(function* () {
      var user = yield this.user;

      if (user) {
        yield (0, _emberConcurrency.waitForProperty)(this, 'userHierarchy', userHierarchy => userHierarchy !== null);
        var userHierarchy = this.userHierarchy;
        return yield userHierarchy.get('grandparents');
      }
    })
  });

  _exports.default = _default;
});