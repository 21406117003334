define("securitytrax-ember-ui/components/multi-step-form/forms/user-defined-fields/component", ["exports", "@glimmer/component", "ember-concurrency", "securitytrax-ember-ui/classes/user-defined-fields"], function (_exports, _component, _emberConcurrency, _userDefinedFields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var UserDefinedFields = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = (0, _emberConcurrency.task)(function* () {
    yield this.UdfClass.populateSectionDataTask.perform(this.args.udfContext);
  }), _dec9 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    try {
      yield this.UdfClass.saveUdfsTask.perform(this.args.model);
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        this.formMode = 'view';
      });
    } catch (error) {
      this.stNotificationManager.addErrorMessages(error);
      warn('Failed to save company defined fields information' + error);
      throw error;
    }
  }), (_class = (_temp = class UserDefinedFields extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "grants", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor4, this);

      _initializerDefineProperty(this, "screen", _descriptor5, this);

      _initializerDefineProperty(this, "session", _descriptor6, this);

      _initializerDefineProperty(this, "formMode", _descriptor7, this);

      _initializerDefineProperty(this, "initFormTask", _descriptor8, this);

      _initializerDefineProperty(this, "saveUdfsTask", _descriptor9, this);

      this.UdfClass = new _userDefinedFields.default({
        store: this.store,
        intl: this.intl,
        model: this.args.model
      });
      this.setupButtons();
      this.initFormTask.perform();
      this.formMode = this.args.formMode;
    }

    setupButtons() {
      this.args.multiStepForm.registerStepData('userDefinedFields', 'buttons', [{
        text: this.intl.t('common.actions.cancel'),
        click: () => {
          this.cancelEdit();
          this.stNotificationManager.addSuccessKey('common.notifications.generic_cancel');
          this.formMode = 'view';
        },
        classNamesString: 'st-btn st-btn-secondary cancel-company-defined-fields-form'
      }, {
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.saveUdfsTask.perform(buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary submit-company-defined-fields-form'
      }]);
    }

    cancelEdit() {
      Object.values(this.UdfClass.udfObjs).forEach(adminUdfObj => {
        if (adminUdfObj.model_udf) {
          var model_value = adminUdfObj.model_udf.get('value');

          if (adminUdfObj.adminUdf.valueType == 'checkbox') {
            Ember.set(adminUdfObj, 'udf_value', model_value == 'true');
          } else if (adminUdfObj.adminUdf.valueType == 'date' && model_value == '') {
            Ember.set(adminUdfObj, 'udf_value', null);
          } else {
            Ember.set(adminUdfObj, 'udf_value', adminUdfObj.model_udf.get('value'));
          }
        } else {
          Ember.set(adminUdfObj, 'udf_value', null);
        }
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "screen", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "formMode", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "initFormTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "saveUdfsTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = UserDefinedFields;
});