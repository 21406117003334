define("securitytrax-ember-ui/components/multi-step-form/forms/invoices/base-step-components/details/base", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/invoices/base", "ember-concurrency", "moment", "securitytrax-ember-ui/mixed-in-classes/row-list", "lodash"], function (_exports, _base, _emberConcurrency, _moment, _rowList, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var BaseDetails = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember.inject.service('events/invoice-events'), _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = (0, _emberConcurrency.task)(function* () {
    var hashedData = yield (0, _emberConcurrency.hash)({
      finishedCopyingModels: (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true),
      invoiceBillableItems: this.invoiceBillableItemsTask.perform(),
      taxRates: this.taxRatesTask.perform(),
      globalTaxExemptions: this.sessionData.getGlobalTaxExemptions(),
      invoiceDefaults: this.sessionData.getCompanyInvoiceSettings()
    });
    Ember.setProperties(this, hashedData);

    if (!this.taxRates) {
      this.stNotificationManager.addWarningMessages(this.intl.t('pages.customers.accounting.invoices.noZipWarning', {
        leadOrCustomer: this.args.customer.get('isLead') ? 'lead' : 'customer'
      }), {
        notificationNamespace: 'modal'
      });
    }

    if (this.SwitchableFormStep.isNewRecord) {
      Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.entityType', 'customer');
      Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.customer', this.args.customer);
      Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.saleDate', (0, _moment.default)());
      var term = hashedData.invoiceDefaults && hashedData.invoiceDefaults.get('defaultTermId') ? yield this.store.findRecord('invoiceTerm', hashedData.invoiceDefaults.get('defaultTermId')) : null;
      Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.invoiceTerm', term);
    } else {
      Ember.set(this, 'invoiceToPreviewOrSend', this.args.multiStepForm.formOriginalModelsGetVal('invoice.model'));
    }

    var invoiceLineItems = this.args.multiStepForm.formCopiedModelsGetVal('invoice.model.invoiceLineItems');
    var noSortItems = invoiceLineItems.filter(item => !item.sort);
    var sortPresentItems = invoiceLineItems.filter(item => item.sort);
    sortPresentItems.sort((a, b) => {
      var aSort = a.get('sort');
      var bSort = b.get('sort');

      if (aSort && bSort) {
        return Number(aSort) - Number(bSort);
      }

      return aSort ? -1 : 1;
    });
    var listArray = sortPresentItems.concat(noSortItems);
    Ember.set(this.RowList, 'listArray', listArray);

    if ((this.RowList.listIsEmpty || this.RowList.listArray[this.RowList.listArray.length - 1]['quantity']) && (!this.args.workOrder || !this.SwitchableFormStep.isNewRecord)) {
      this.RowList.addRow();
    }

    var invoiceBillableItemClass;
    var isTaxExempt;
    this.RowList.trueListArray.forEach(lineItem => {
      var _lineItem$get;

      var rawQuantity = Number((_lineItem$get = lineItem.get('quantity')) !== null && _lineItem$get !== void 0 ? _lineItem$get : 0);

      if (rawQuantity % 1 == 0) {
        Ember.set(lineItem, 'quantity', rawQuantity.toFixed(0));
      } else {
        Ember.set(lineItem, 'quantity', rawQuantity.toFixed(2));
      }

      invoiceBillableItemClass = lineItem ? lineItem.get('invoiceBillableItem.invoiceBillableItemClass.name') : null;
      isTaxExempt = hashedData.globalTaxExemptions[invoiceBillableItemClass] && hashedData.globalTaxExemptions[invoiceBillableItemClass].includes(this.args.customer.get('state'));
      Ember.set(lineItem, 'isNotTaxable', lineItem.get('invoiceBillableItem.isTaxable') === 0 || lineItem.get('invoiceBillableItem.isTaxable') == null && isTaxExempt);
    });

    if (this.args.multiStepForm.formCopiedModels.invoice.model.invalidInvoiceBillableItems && this.args.multiStepForm.formCopiedModels.invoice.model.invalidInvoiceBillableItems.length > 0) {
      this.stNotificationManager.addWarningMessages({
        errors: this.args.multiStepForm.formCopiedModels.invoice.model.invalidInvoiceBillableItems
      }, {
        notificationNamespace: 'modal',
        messagePrefix: this.intl.t('pages.customers.accounting.invoices.invalidInvoiceBillableItems', {
          invalidItems: this.args.multiStepForm.formCopiedModels.invoice.model.invalidInvoiceBillableItems.length
        })
      });
    }

    this.setupButtons();
  }), _dec13 = (0, _emberConcurrency.task)(function* () {
    return yield this.store.findAll('invoiceTerm');
  }), _dec14 = (0, _emberConcurrency.task)(function* (buttonPromise, close = false, duplicate = false) {
    var startedAsNewRecord = this.SwitchableFormStep.isNewRecord;
    var noValidateArray = [];
    var invoiceType = this.args.multiStepForm.formCopiedModelsGetVal('invoice.model.invoiceType');
    var modelName = 'invoice';

    if (invoiceType === 'recurring_invoice') {
      modelName = 'recurringInvoice';
      var numTimes = this.args.multiStepForm.formCopiedModelsGetVal('invoice.model.numTimesToRecur');

      if (this.recurUntilStopped) {
        numTimes = -1;
        noValidateArray.push('invoice-numTimesToRecur');
      }

      if (this.stopRecurrence) {
        numTimes = 0;
        noValidateArray.push('invoice-numTimesToRecur');
      }

      if (this.lockedState !== 'Open') {
        noValidateArray.push('invoice-saleDate');
      }

      Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.numTimesToRecur', numTimes);
      var recurringEmails = this.args.multiStepForm.formCopiedModelsGetVal('invoice.model.recurringEmail');
      this.args.multiStepForm.formCopiedModelsSetVal('invoice.model.recurringEmail', recurringEmails ? recurringEmails.replace(/\s+/g, '').split(',') : null);
    }

    if (invoiceType === 'credit_memo' || invoiceType === 'refund_receipt' || invoiceType === 'quote' && !this.toConvertQuoteModal) {
      Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.invoiceTerm', null);
      Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.invoiceTermId', 0);
    }

    if (invoiceType === 'quote' && this.SwitchableFormStep.isNewRecord && this.expires !== 0) {
      Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.dueDate', (0, _moment.default)().add(this.expires, 'days'));
    } else if (invoiceType === 'quote') {
      Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.dueDate', this.dueDate);
    }

    if (this.selectedPaymentMethodId) {
      Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.customerBilling', this.args.customer.get('customerBillings').findBy('id', this.selectedPaymentMethodId));
    }

    if (this.SwitchableFormStep.isNewRecord && invoiceType === 'invoice') {
      noValidateArray.push('invoice-invoiceLineItems');
    }

    this.RowList.listArray.forEach(row => {
      if (!row.unitPrice) {
        row.unitPrice = 0;
      }
    });
    var formattedObjects = this.RowList.prepForValidate('invoiceLineItem');
    formattedObjects.pushObject(Ember.Object.create({
      modelName: modelName,
      model: this.args.multiStepForm.formCopiedModels.invoice.model,
      extraValidationData: {
        noValidate: noValidateArray
      }
    }));

    if (formattedObjects.length === 1) {
      this.stNotificationManager.addErrorMessages(this.intl.t('common.validations.invoiceNeedsLineItems'), {
        notificationNamespace: 'modal'
      });
      throw new Error();
    }

    var invoice;

    try {
      if (duplicate) {
        var invoiceLineItems = [];
        var toCreateObjects = yield this.args.multiStepForm.validateCreateList.perform(formattedObjects, {
          notificationNamespace: 'modal'
        });

        for (var i = 0; i < toCreateObjects.invoiceLineItem.length; i++) {
          var item = toCreateObjects.invoiceLineItem[i];
          var newLineItem = this.store.createRecord('invoiceLineItem', item.data);
          newLineItem.set('sort', i + 1);
          invoiceLineItems.push(newLineItem);
          var invoiceBillableItem = this.store.peekRecord('invoiceBillableItem', item.data.invoiceBillableItem.id);
          newLineItem.set('invoiceBillableItem', invoiceBillableItem);
        }

        toCreateObjects[modelName][0].data.invoiceLineItems = invoiceLineItems;
        invoice = this.store.createRecord(modelName, toCreateObjects[modelName][0].data);
        Ember.set(invoice, 'customer', this.args.customer);
        var invoiceTermId = this.args.multiStepForm.formCopiedModels.invoice.model.invoiceTerm.id;
        var invoiceTerm = this.store.peekRecord('invoiceTerm', invoiceTermId);
        Ember.set(invoice, 'invoiceTerm', invoiceTerm);
        yield toCreateObjects[modelName][0].saveWithRollbackAndNotification(invoice, 'modal');
        return;
      } else if (this.SwitchableFormStep.isNewRecord) {
        var _invoiceLineItems = [];

        var _toCreateObjects = yield this.args.multiStepForm.validateCreateList.perform(formattedObjects, {
          notificationNamespace: 'modal'
        });

        for (var _i = 0; _i < _toCreateObjects.invoiceLineItem.length; _i++) {
          var _item = _toCreateObjects.invoiceLineItem[_i];

          var _newLineItem = this.store.createRecord('invoiceLineItem', _item.data);

          _newLineItem.set('sort', _i + 1);

          _invoiceLineItems.push(_newLineItem);
        }

        _toCreateObjects[modelName][0].data.invoiceLineItems = _invoiceLineItems;
        invoice = this.store.createRecord(modelName, _toCreateObjects[modelName][0].data);
        var savedInvoice = yield _toCreateObjects[modelName][0].saveWithRollbackAndNotification(invoice, 'modal');
        this.args.updateInvoice(savedInvoice);
        Ember.set(this.SwitchableFormStep, 'disabledProgressBarSteps', this.disabledProgressBarSteps);
        Ember.set(this, 'invoiceToPreviewOrSend', invoice);
        var title = this.intl.t("pages.customers.accounting.invoices.modalTitle.".concat(invoiceType), {
          id: invoice.get('id'),
          name: this.args.customer.get('flname')
        });
        Object.keys(this.args.multiStepForm.formSteps).forEach(key => {
          if (typeof this.args.multiStepForm.formSteps[key] === 'object') {
            if (this.args.multiStepForm.formSteps[key]['subSteps']) {
              Object.keys(this.args.multiStepForm.formSteps[key]['subSteps']).forEach(subStepKey => {
                Ember.set(this.args.multiStepForm.formSteps[key]['subSteps'][subStepKey], 'title', title);
              });
            }

            Ember.set(this.args.multiStepForm.formSteps[key], 'title', title);
          }
        });
      } else {
        var _invoiceLineItems2 = [];
        yield this.args.multiStepForm.validateList.perform(formattedObjects, {
          notificationNamespace: 'modal'
        });

        if (invoiceType === 'quote' && this.toConvertQuoteModal) {
          Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.invoiceType', 'invoice');
        }

        this.RowList.trueListArray.forEach(copiedItem => {
          var itemToAdd = copiedItem;

          if (copiedItem.get('id') === null || copiedItem.get('id').includes('new')) {
            itemToAdd = this.store.createRecord('invoiceLineItem', copiedItem);
          }

          _invoiceLineItems2.push(itemToAdd);
        });
        var options = {
          relationships: {
            invoiceLineItems: {
              relationships: {
                invoiceBillableItem: {
                  relationships: {
                    invoiceBillableItemClass: {}
                  }
                }
              }
            },
            invoiceTerm: {},
            customerBilling: {}
          }
        };

        for (var _i2 = 0; _i2 < _invoiceLineItems2.length; _i2++) {
          var lineItem = _invoiceLineItems2[_i2];
          Ember.set(lineItem, 'sort', _i2 + 1);
        }

        Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.invoiceLineItems', _invoiceLineItems2);
        invoice = yield this.args.multiStepForm.formOriginalModelsGetVal('invoice.model').deepSync({
          relationshipMap: options,
          copiedObject: this.args.multiStepForm.formCopiedModelsGetVal('invoice.model'),
          originalOptions: {
            onBeforeSync: this.args.multiStepForm.onBeforeSync,
            onAfterSync: this.args.multiStepForm.onAfterSync
          }
        });

        var _savedInvoice = yield invoice.save();

        this.args.updateInvoice(_savedInvoice);
      }
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'model'
      });
      throw err;
    }

    buttonPromise.then(() => {
      if (this.toConvertQuoteModal) {
        this.toConvertQuoteModal = false;
        this.quickAdd.set('closeRoute', 'customers.accounting.invoices-invoices');
        this.args.customer.get('quotes').reload();
        this.args.customer.get('invoices').reload();
        this.router.transitionTo('customers.accounting.invoices-invoices.edit-create-invoice', invoice.get('id'));
      }

      if (close) {
        this.closeForm();
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'global');
      } else {
        if (startedAsNewRecord) {
          if (this.args.workOrder) {
            this.router.transitionTo('home.tech-today.appointment.create-invoice', this.router.currentRoute.attributes.appointment.id, invoice.get('id'));
          } else {
            this.router.transitionTo("".concat(this.args.customer.isLead ? 'leads.accounting' : "customers.accounting.invoices-".concat(_lodash.default.kebabCase(invoiceType), "s"), ".edit-create-").concat(_lodash.default.kebabCase(invoiceType)), invoice.get('id'));
          }
        }

        this.initBaseData.perform();
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
      }
    });
  }), _dec15 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var hashedData = yield (0, _emberConcurrency.hash)({
      saveInvoice: this.saveInvoiceTask.perform(buttonPromise),
      company: this.sessionData.getCompany(),
      toAddress: this.emailAddresses
    });
    this.toAddress = hashedData.toAddress;
    this.invoiceNumber = this.args.multiStepForm.formOriginalModelsGetVal('invoice.model.id');

    var invoiceType = _lodash.default.camelCase(this.args.multiStepForm.formCopiedModelsGetVal('invoice.model.invoiceType'));

    this.invoiceEmailSubject = this.intl.t('pages.customers.send.default_subject', {
      type: this.intl.t('pages.customers.accounting.invoices.' + invoiceType),
      id_number: this.invoiceNumber,
      company_name: hashedData.company.get('name')
    });

    try {
      yield this.sendInvoiceTask.perform(buttonPromise);
    } catch (err) {// Do Nothing
    }

    this.args.multiStepForm.currentStepNameKeySetVal('send');
  }), _dec16 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    yield this.saveInvoiceTask.perform(buttonPromise);
    yield this.showPreviewTask.perform();
    buttonPromise.then(() => {
      this.closeForm();
      this.stNotificationManager.addSuccess(this.intl.t('common.notifications.downloading'), 'global');
    });
  }), _dec17 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    try {
      var invoice = this.args.multiStepForm.formOriginalModelsGetVal('invoice.model');
      yield invoice.destroyRecordWithRollbackTask.perform();

      if (this.grants.getIndividualGrant('customer_billings.view') && this.args.customer.get('customerBillings')) {
        yield this.args.customer.get('customerBillings').reload();
      }

      buttonPromise.then(() => {
        this.closeForm();
        this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
      });
    } catch (error) {
      this.stNotificationManager.addErrorMessages(error, {
        notificationNamespace: 'confirmDelete'
      });
      throw error;
    }
  }), (_class = (_temp = class BaseDetails extends _base.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "grants", _descriptor3, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor4, this);

      _initializerDefineProperty(this, "sessionData", _descriptor5, this);

      _initializerDefineProperty(this, "invoiceService", _descriptor6, this);

      _initializerDefineProperty(this, "router", _descriptor7, this);

      _initializerDefineProperty(this, "quickAdd", _descriptor8, this);

      _initializerDefineProperty(this, "invoiceEvent", _descriptor9, this);

      _initializerDefineProperty(this, "confirmDeleteModalOpen", _descriptor10, this);

      _initializerDefineProperty(this, "toConvertQuoteModal", _descriptor11, this);

      _initializerDefineProperty(this, "initBaseData", _descriptor12, this);

      _initializerDefineProperty(this, "invoiceTermsTask", _descriptor13, this);

      _initializerDefineProperty(this, "saveInvoiceTask", _descriptor14, this);

      _initializerDefineProperty(this, "saveAndSendTask", _descriptor15, this);

      _initializerDefineProperty(this, "saveAndPreviewTask", _descriptor16, this);

      _initializerDefineProperty(this, "deleteInvoiceTask", _descriptor17, this);

      this.RowList = new _rowList.default(...arguments);
    }

    get showMultipleTaxRatesWarning() {
      // const foundTaxRates = {};
      // for (let index = 0; index < this.RowList.listArray.length; index++) {
      //     const element = this.RowList.listArray[index];
      //     if (element.salesTaxRate) {
      //         foundTaxRates[element.salesTaxRate] = true;
      //     }
      // }
      // DREW TODO - need to also check if quickbooks is connected - gonna do that on on the qb branch though so merging this way
      // if (Object.keys(foundTaxRates).length > 1) {
      //     return true;
      // }
      return false;
    }

    get hiddenProgressBarSteps() {
      return ['gettingStarted', 'confirmRefundVoid', 'viewProcessingInformation', 'editManualPayment', 'duplicate'];
    }

    get disabledProgressBarSteps() {
      return this.args.formMode === 'create' ? ['addPayment', 'applyToInvoice', 'history', 'listPayments', 'send'] : [];
    }

    get invoiceTerms() {
      return this.invoiceTermsTask.perform();
    }

    closeForm() {
      this.args.multiStepForm.formStartedSetVal(false);

      if (this.args.transitionBackToParent && typeof this.args.transitionBackToParent === 'function') {
        this.args.transitionBackToParent();
      }
    }

    setupButtons() {
      var buttons = [];
      var multiStepForm = this.args.multiStepForm;
      var invoiceType = Ember.String.underscore(this.args.type);

      if (!this.SwitchableFormStep.isNewRecord) {
        if (invoiceType === 'quote' && !this.args.customer.isLead) {
          buttons.push({
            text: this.intl.t('pages.customers.accounting.invoices.convertToInvoice'),
            classNamesString: 'st-btn st-btn-secondary',
            click: () => {
              this.toConvertQuoteModal = true;
            }
          });
        }

        if (this.grants.getIndividualGrant(invoiceType !== 'recurring_invoice' ? "customer_".concat(invoiceType, "s.delete") : 'recurring_invoices.delete')) {
          buttons.push({
            text: this.intl.t('common.actions.delete'),
            click: () => {
              this.confirmDeleteModalOpen = true;
            },
            isDelete: true,
            classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
          });
        }
      }

      if (this.args.formMode !== 'view') {
        var saveButtons = {
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveInvoiceTask.perform(buttonPromise, this.args.close);
          },
          classNamesString: 'st-btn st-btn-primary',
          positionOptions: 'up left',
          additionalClickActions: [{
            label: this.intl.t('common.actions.saveAndClose'),
            action: buttonPromise => {
              return this.saveInvoiceTask.perform(buttonPromise, true);
            },
            classNamesString: 'dropdown-save-close'
          }]
        };

        if (invoiceType !== 'recurring_invoice') {
          saveButtons.additionalClickActions.push({
            label: this.intl.t('common.actions.saveAndSend'),
            action: buttonPromise => {
              return this.saveAndSendTask.perform(buttonPromise);
            },
            classNamesString: 'dropdown-save-send'
          }, {
            label: this.intl.t('common.actions.saveAndDownload'),
            action: buttonPromise => {
              return this.saveAndPreviewTask.perform(buttonPromise);
            },
            classNamesString: 'dropdown-save-preview'
          });
        } else if (this.args.formMode !== 'create' && this.grants.getIndividualGrant('recurring_invoices.create')) {
          buttons.push({
            text: this.intl.t('common.actions.duplicate'),
            classNamesString: 'st-btn st-btn-secondary',
            click: () => {
              Ember.set(this.args.multiStepForm, 'currentStepNameKey', 'duplicate');
            }
          });
        }

        buttons.push(saveButtons);
      }

      multiStepForm.registerStepData('details', 'buttons', buttons);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "invoiceService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "quickAdd", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "invoiceEvent", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "confirmDeleteModalOpen", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "toConvertQuoteModal", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "initBaseData", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "invoiceTermsTask", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "saveInvoiceTask", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "saveAndSendTask", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "saveAndPreviewTask", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "deleteInvoiceTask", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BaseDetails;
});