define("securitytrax-ember-ui/components/nav/top-nav-sub/tech-toolbox/btn-none-selected/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oAo5e43+",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"none-selected\"],[15,\"onclick\",[30,[36,0],[[32,0],\"navToCustomerInfo\"],[[\"bubbles\"],[false]]]],[12],[2,\"\\n    \"],[10,\"span\"],[12],[1,[34,1]],[13],[2,\"\\n    \"],[10,\"span\"],[12],[1,[30,[36,2],[\"icn-chevron-right\"],null]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"buttonText\",\"svg-icon\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/nav/top-nav-sub/tech-toolbox/btn-none-selected/template.hbs"
    }
  });

  _exports.default = _default;
});