define("securitytrax-ember-ui/users/inventory/route", ["exports", "securitytrax-ember-ui/users/base-route", "securitytrax-ember-ui/mixins/route-pageview-logging", "securitytrax-ember-ui/mixins/scroll-to-top", "ember-concurrency"], function (_exports, _baseRoute, _routePageviewLogging, _scrollToTop, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_routePageviewLogging.default, _scrollToTop.default, {
    tagsService: Ember.inject.service(),
    enhancedFeatures: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['user_inventory_settings.view']);
    },

    model() {
      var {
        user
      } = this._super(...arguments);

      return this.setupModel.perform(user);
    },

    setupModel: (0, _emberConcurrency.task)(function* (user) {
      var store = this.store;
      var hasEnhancedInventory = yield this.enhancedFeatures.hasFeature('inventoryExtendedFunctionality');
      return Ember.RSVP.hash({
        user,
        mobileCarriers: store.findAll('mobileCarrier'),
        tags: this.get('tagsService.getTagsByObjectType').perform('user'),
        inventoryRecipientTemplates: hasEnhancedInventory ? store.findAll('equipmentToRecipientTemplate') : []
      });
    })
  });

  _exports.default = _default;
});