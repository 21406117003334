define("securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/adc/enable-disable-lockout/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3HccwxB+",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"grid-x adc-lockout-modal-prompt\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cell adc-lockout-modal-prompt\"],[12],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,0],[\"pages.customers.system.interactiveServicesProvider.enableDisableLockout.disableLockout.prompt\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[1,[30,[36,0],[\"pages.customers.system.interactiveServicesProvider.enableDisableLockout.enableLockout.prompt\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cell\"],[12],[2,\"\\n        \"],[8,\"multi-step-form/switchable-inputs/textarea-input\",[],[[\"@formMode\",\"@value\",\"@rows\",\"@label\"],[\"edit\",[34,3],\"3\",[30,[36,0],[\"pages.customers.system.interactiveServicesProvider.enableDisableLockout.reason\"],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"lockoutEnabled\",\"if\",\"reason\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/adc/enable-disable-lockout/template.hbs"
    }
  });

  _exports.default = _default;
});