define("securitytrax-ember-ui/components/multi-step-form/forms/administration/permissions/group-users/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms/administration/permissions/permissions-base"], function (_exports, _emberConcurrency, _permissionsBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _permissionsBase.default.extend({
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    addUsersFormStarted: false,
    groupUserRemoveId: undefined,
    addArray: undefined,
    groupUsers: Ember.computed('addEditGroup', 'newGroupUsers', function () {
      return this.getGroupUsersTask.perform();
    }),
    getGroupUsersTask: (0, _emberConcurrency.task)(function* () {
      var group = yield this.addEditGroup;
      var groupUsers = yield this.store.query('user', {
        filter: 'group = ' + group.id,
        include: 'user_hr_setting,user_hr_setting.user_hierarchy_title,user_admin_setting',
        sort: 'lname'
      });
      return groupUsers;
    }),
    groupUsersSteps: Ember.computed('addEditGroup', function () {
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.intl.t('pages.administration.human_resources.add_users') + ' - ' + this.get('addEditGroup.name'),
          stepComponentPath: 'multi-step-form/forms/administration/permissions/add-group-users'
        }),
        review: Ember.Object.create({
          stepNameKey: 'review',
          title: this.intl.t('pages.administration.human_resources.review_title', {
            group: this.get('addEditGroup.name')
          }),
          stepComponentPath: 'multi-step-form/forms/administration/permissions/add-group-users/review'
        })
      });
    }),
    removeUserFromGroupTask: (0, _emberConcurrency.task)(function* (user) {
      try {
        user.set('group', null);
        yield user.save();
        this.set('groupUserRemoveId', null);
        this.notifyPropertyChange('newGroupUsers');
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
        (false && Ember.warn('remove user from group error' + err, {
          id: 'multiStepForm.forms.administration.permissions.groupUsers.removeUserFromGroupTask'
        }));
        throw err;
      }
    }),
    actions: {
      clearAddArray() {
        this.addArray.forEach(user => {
          user.inAddArray = false;
        });
        this.set('addArray', []);
      },

      changeRoute(user) {
        this.router.transitionTo("users.information", user.id);
      }

    }
  });

  _exports.default = _default;
});