define("securitytrax-ember-ui/components/customer/warnings-card/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    showWarnings: true,

    init() {
      this._super(...arguments);

      this.warningsTask.perform();
    },

    hasWarn: false,
    warningCount: 0,
    warnings: Ember.computed('customer.warnings.warnings.[]', function () {
      return this.warningsTask.perform();
    }),
    warningsTask: (0, _emberConcurrency.task)(function* () {
      try {
        var warnings = yield this.get('customer.warnings');
        var warningsJson = warnings ? yield warnings.get('warnings') : null;

        if (warningsJson) {
          if (Ember.isEmpty(warningsJson)) {
            this.set('hasWarn', false);
          } else {
            this.set('hasWarn', true);
            this.set('warningCount', warningsJson.length);
          }

          return warningsJson;
        }
      } catch (error) {
        (false && Ember.warn('Customer Warnings Task failed' + error, {
          id: 'customer.warningsCard.warningsTask'
        }));
      }

      return [];
    })
  });

  _exports.default = _default;
});