define("securitytrax-ember-ui/components/calendar-glimmer/calendar-event/event-status-pill/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R5olgoIu",
    "block": "{\"symbols\":[\"data\",\"@sizeClass\",\"@isTimeOff\"],\"statements\":[[6,[37,2],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"html/st-await\",[],[[\"@loadedNamespace\",\"@useSpinner\",\"@promises\"],[\"calendar-grid\",true,[30,[36,0],null,[[\"appointmentStatusDataObject\"],[[32,0,[\"appointmentStatusDataObject\"]]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[15,0,[31,[\"event-status__glance \",[32,2],\" \",[32,1,[\"resolvedPromises\",\"appointmentStatusDataObject\",\"colorClass\"]]]]],[12],[2,\"\\n            \"],[1,[30,[36,1],[[32,1,[\"resolvedPromises\",\"appointmentStatusDataObject\",\"icon\"]]],[[\"class\"],[\"event-status__glance-icon\"]]]],[2,\"\\n            \"],[10,\"span\"],[14,0,\"event-status__glance-text\"],[12],[2,\"\\n                \"],[1,[32,1,[\"resolvedPromises\",\"appointmentStatusDataObject\",\"text\"]]],[2,\"\\n            \"],[13],[2,\" \\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"svg-icon\",\"unless\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/calendar-glimmer/calendar-event/event-status-pill/template.hbs"
    }
  });

  _exports.default = _default;
});