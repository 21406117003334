define("securitytrax-ember-ui/components/html/package-select/package-layouts/check-btn-layout/component", ["exports", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    buttonList: undefined,
    activeIndex: null,
    disabled: false,
    loadedNamespace: undefined,
    stAwait: Ember.inject.service(),

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        buttonList: [],
        loadedNamespace: 'global'
      }); /////////////////////////////////////////////////////////////////////////
      // see if we should disable the side nav because of fully loaded logic

      var stAwait = this.stAwait;
      var fullyLoadedState = stAwait.getCurrentFullyLoadedStateByNamespace({
        loadedNamespace: this.loadedNamespace
      });

      if (this && !this.isDestroyed) {
        this.set('disabled', !fullyLoadedState.fullyLoaded);
      }

      stAwait.on('st-await-loaded-state-by-namespace-' + this.loadedNamespace, this, '_onLoadedStateChange');
    },

    willDestroyElement() {
      this._super(...arguments);

      this.stAwait.off('st-await-loaded-state-by-namespace-' + this.loadedNamespace, this, '_onLoadedStateChange');
    },

    _onLoadedStateChange(loadedData) {
      Ember.run.next(() => {
        if (this && !this.isDestroyed) {
          this.set('disabled', !loadedData.fullyLoaded);
        }
      });
    },

    selectButton: function (selected) {
      this.set('activeIndex', Ember.isEqual(this.activeIndex, selected) ? null : selected);
    },
    bulkAction: () => {},
    actions: {
      dynamicAction(button, index, formMode) {
        // if they dont have modify permissions or is disabled, just return
        if (formMode === 'view' || this.disabled) {
          return;
        }

        if (button.bulkAction) {
          var lastSelected = this.lastSelected;
          var differentButton = index != lastSelected;
          var turnOn = true;

          if (!differentButton) {
            // first click always turn on, then allow toggling
            this.incrementProperty('sameButtonClickCount');

            if (this.sameButtonClickCount % 2) {
              turnOn = !turnOn;
            }
          } else {
            this.set('sameButtonClickCount', 0);
          }

          this.set('lastSelected', index);

          if (this.bulkAction) {
            this.bulkAction(index, turnOn);
          }
        } else {
          this.selectButton(index);
        }
      }

    }
  });

  _exports.default = _default;
});