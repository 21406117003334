define("securitytrax-ember-ui/services/st-model-watcher", ["exports", "securitytrax-ember-ui/utils/obj-helpers", "ember-concurrency", "lodash"], function (_exports, _objHelpers, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    registeredModelWatchers: undefined,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        registeredModelWatchers: {}
      }); // is there a watcher for a create and do I have permissions to grab that thing?
      // is it in store?
      // grab it and check if need it
      // is it not in the store ?
      // grab it and check if need it
      // if don't need it - remove from store (this code will be done by st-model-watcher)
    },

    /**
     *
     * @param {*} modelName - in the format of "user_notification" etc etc
     * @param {*} action (create, update, delete)
     * @param {*} triggerEventKey - any valid event key name
     * @param {*} watcherApproveTask(modelsList)
     *      must return an array that looks like
     *      [
     *          approvedModelFromList,
     *          anotherApprovedModelFromList
     *      ]
     */
    registerModelWatcher(modelName, action, triggerEventKey, watcherApproveTask, eventContext, eventCallback) {
      // NOTE: we could make it so action is an array so you can setup watchers for multiple actions at a time
      // check to see if the modelname is correct
      if (!['create', 'update', 'delete'].includes(action)) {
        throw 'action must be create, update or delete';
      } // check that trigger event is a string


      if (typeof triggerEventKey != 'string') {
        throw 'triggerEventKey must be a string';
      }

      if (!this.registeredModelWatchers[modelName]) {
        this.registeredModelWatchers[modelName] = {};
      }

      if (!this.registeredModelWatchers[modelName][action]) {
        this.registeredModelWatchers[modelName][action] = {};
        this.registeredModelWatchers[modelName][action]['watchers'] = [];
        this.registeredModelWatchers[modelName][action]['triggeredIds'] = {};
      } // NOTE: we could namespace the event key by modelName and action to prevent collisions
      // do the on for the event


      this.on(triggerEventKey, eventContext, eventCallback); // register the watcher

      this.registeredModelWatchers[modelName][action]['watchers'].push({
        watcherApproveTask: watcherApproveTask,
        triggerEventKey: triggerEventKey,
        eventContext: eventContext,
        eventCallback: eventCallback
      });
    },

    deregisterModelWatcher(modelName, action, triggerEventKey, eventContext, eventCallback) {
      // NOTE: we could make it so action is an array so you can setup watchers for multiple actions at a time
      var watchers = _lodash.default.get(this.registeredModelWatchers, "".concat(modelName, ".").concat(action, ".watchers"), []);

      var indexToRemove;

      for (var index = 0; index < watchers.length; index++) {
        var currWatcher = watchers[index];

        if (currWatcher.triggerEventKey === triggerEventKey && currWatcher.eventContext === eventContext && currWatcher.eventCallback === eventCallback) {
          indexToRemove = index;
          break;
        }
      }

      if (indexToRemove != null) {
        // do the off for the event
        // NOTE: we could namespace the event key by modelName and action to prevent collisions
        this.off(triggerEventKey, eventContext, eventCallback); // deregister the watcher

        this.registeredModelWatchers[modelName][action]['watchers'].splice(indexToRemove, 1); // NOTE: not cleaning up the modelName and action in this.registeredModelWatchers because won't fire watchers anyway if becomes empty
      }
    },

    /**
     *
     * @param {*} modelName - in the format of "user_notification" etc etc
     * @param {*} models - models should be an array of one type of model
     * @param {*} action - action should be (create, update, delete)
     * @returns
     */
    triggerModelWatcherEventsTask: (0, _emberConcurrency.task)(function* (modelName, action, models) {
      var yieldedModels = yield (0, _emberConcurrency.all)(models);
      var filteredModels = yieldedModels.filter(currModel => {
        var checkModelName = _lodash.default.get(currModel, 'constructor.modelName', null);

        if (modelName == null) {
          return false;
        }

        if (!this.registeredModelWatchers[checkModelName]) {
          return false;
        }

        if (!this.registeredModelWatchers[checkModelName][action]) {
          return false;
        } // not worrying about triggeredIds for now
        // const triggeredIds = this.registeredModelWatchers[checkModelName][action]['triggeredIds'];
        // if (triggeredIds[currModel.id]) {
        //     return false;
        // }


        return true;
      });

      if (filteredModels.length <= 0) {
        return;
      } // means we actually need to fire events


      yield this._fireEvents.perform(modelName, action, filteredModels);
    }),
    _fireEvents: (0, _emberConcurrency.task)(function* (modelName, action, filteredModels) {
      // put all model ids on the triggeredIds array so they won't trigger again
      // NOTE: this may need to be modified for updates as might update multiple times
      // not worrying about triggeredIds for now
      // for (let index = 0; index < filteredModels.length; index++) {
      //     const element = filteredModels.objectAt(index);
      //     this.registeredModelWatchers[modelName][action]['triggeredIds'][element.id] = true;
      // }
      //////////////////////////////////////////////////////////////////////////////////////////////////
      // check to see which of the watchers match so may need to fire an event
      var watchers = this.registeredModelWatchers[modelName][action]['watchers']; // assuming all models are of the same type ...

      var allWatcherApproveTasks = [];

      for (var index = 0; index < watchers.length; index++) {
        var currWatcher = watchers[index];
        allWatcherApproveTasks.push(currWatcher.watcherApproveTask.perform(filteredModels));
      }

      var allWatcherApprovedFilteredModels = yield (0, _emberConcurrency.all)(allWatcherApproveTasks); // allow the approvers to be promises
      //////////////////////////////////////////////////////////////////////////////////////////////////
      // figure out which watchers should actually fire based on the callback and fire them

      for (var _index = 0; _index < watchers.length; _index++) {
        if (allWatcherApprovedFilteredModels[_index].length > 0) {
          var _currWatcher = watchers[_index];
          this.trigger(_currWatcher.triggerEventKey, {
            modelName: modelName,
            action: action,
            triggerEventKey: _currWatcher.triggerEventKey,
            approvedModelsList: allWatcherApprovedFilteredModels[_index]
          });
        }
      }
    })
  });

  _exports.default = _default;
});