define("securitytrax-ember-ui/reports/view/route", ["exports", "securitytrax-ember-ui/reports/route", "securitytrax-ember-ui/mixins/route-pageview-logging", "securitytrax-ember-ui/mixins/has-page-title", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-concurrency", "ember-inflector"], function (_exports, _route, _routePageviewLogging, _hasPageTitle, _authenticatedRouteMixin, _emberConcurrency, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_authenticatedRouteMixin.default, _routePageviewLogging.default, _hasPageTitle.default, {
    titleLink: '',

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform();
    },

    model(params) {
      return this.store.findRecord('report', params.id);
    },

    deactivate() {
      var reportsController = this.controllerFor('reports');
      reportsController.set('breadCrumbs', undefined);
      reportsController.set('breadCrumbData', undefined);
      reportsController.set('showNav', true);
    },

    getPageTitle(model) {
      return model.get('name') + ' (' + this.intl.t('common.columns.id') + ': ' + model.get('id') + ')';
    },

    getRouteName() {
      return 'pages.reports.title';
    },

    setupController(controller) {
      this._super(...arguments);

      this.setupControllerTask.perform(controller);
    },

    setupControllerTask: (0, _emberConcurrency.task)(function* (controller) {
      var reportsController = this.controllerFor('reports');
      var model = controller.model;
      var reportCategory = yield model.get('reportCategory');
      var companyDisplay = model.get('name') + ' (' + this.intl.t('common.columns.id') + ': ' + model.get('id') + ')';
      this.set('titleLink', 'reports.' + reportCategory.get('name').toLowerCase().split(' ').map(str => {
        return (0, _emberInflector.singularize)(str);
      }).join('-').replace('-&', '') + '-reports');
      var breadCrumbs = [{
        name: companyDisplay,
        last: true,
        previousLink: 'reports',
        link: 'reports.view'
      }];
      var breadCrumbData = {
        title: this.intl.t('pages.reports.title'),
        titleLink: this.titleLink
      };
      reportsController.set('breadCrumbs', breadCrumbs);
      reportsController.set('breadCrumbData', breadCrumbData);
      reportsController.set('showNav', false);
    }),
    actions: {
      error(error) {
        if (error) {
          return this.transitionTo(this.titleLink);
        }
      }

    }
  });

  _exports.default = _default;
});