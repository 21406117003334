define("securitytrax-ember-ui/validations/customer-note", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateObject = {
        noteType: {
          presence: this.customMessageOrDefault(data, 'noteType')
        },
        subject: {
          presence: this.customMessageOrDefault(data, 'subject')
        },
        note: {
          presence: this.customMessageOrDefault(data, 'note')
        },
        contactAttempted: {
          presence: () => {
            if (data.extraValidationData.contactAttempted) {
              return false;
            }

            return this.customMessageOrDefault(data, 'contactAttempted');
          }
        },
        communicationReason: {
          presence: () => {
            if (data.extraValidationData.contactAttempted === 'no' || data.extraValidationData.communicationReason) {
              return false;
            }

            return this.customMessageOrDefault(data, 'communicationReason');
          }
        },
        communicationDisposition: {
          presence: () => {
            if (data.extraValidationData.contactAttempted === 'no' || data.extraValidationData.communicationDisposition) {
              return false;
            }

            return this.customMessageOrDefault(data, 'communicationDisposition');
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      var modelName = data.extraValidationData && data.extraValidationData.hasOwnProperty('overrideValidationKey') ? data.extraValidationData.overrideValidationKey : 'customerNote';
      return this.outputFormatting(validationData, modelName);
    }

  });

  _exports.default = _default;
});