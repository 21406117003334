define("securitytrax-ember-ui/components/customer/appointments/appointments-list/actual-list/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/utils/obj-helpers", "securitytrax-ember-ui/mixins/table-column-sort"], function (_exports, _emberConcurrency, _objHelpers, _tableColumnSort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableColumnSort.default, {
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    modal: Ember.inject.service(),
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    calendarEventsService: Ember.inject.service('events/calendar-events'),
    tagName: '',
    modelsLoaded: false,
    showCheckAvailability: false,
    showAdd: false,
    workOrderView: false,
    duplicateStartKey: undefined,
    type: 'tech',

    init() {
      this._super(...arguments);

      var intl = this.intl;
      this.calendarEventsService.on('refresh-customer-calendar-events', this, '_refreshCalendarEvents');
      (0, _objHelpers.initializeState)(this, {
        duplicateStartKey: 'duplicateCalendarEvent',
        sortAppointmentOptions: [{
          name: intl.t('common.columns.ascending') + ' ' + intl.t('common.columns.type'),
          value: 'calendarEventType.name',
          asc: true
        }, {
          name: intl.t('common.columns.descending') + ' ' + intl.t('common.columns.type'),
          value: '-calendarEventType.name',
          asc: false
        }, {
          name: intl.t('common.columns.ascending') + ' ' + intl.t('common.columns.name'),
          value: 'calendarUser.lname',
          asc: true
        }, {
          name: intl.t('common.columns.descending') + ' ' + intl.t('common.columns.name'),
          value: '-calendarUser.lname',
          asc: false
        }, {
          name: intl.t('common.columns.ascending') + ' ' + intl.t('common.columns.date'),
          value: 'startTime',
          asc: true
        }, {
          name: intl.t('common.columns.descending') + ' ' + intl.t('common.columns.date'),
          value: '-startTime',
          asc: false
        }, {
          name: intl.t('common.columns.ascending') + ' ' + intl.t('common.columns.date_added'),
          value: 'createdAt',
          asc: true
        }, {
          name: intl.t('common.columns.descending') + ' ' + intl.t('common.columns.date_added'),
          value: '-createdAt',
          asc: false
        }]
      });
    },

    _refreshCalendarEvents() {
      this.sortCustomerCalendarEvents.perform(this.stSortCurrentSortColumn, this.stSortAscending);
    },

    duplicateAdditionalActions: Ember.computed(function () {
      return [{
        label: this.intl.t('pages.calendar.duplicate_with_scheduler'),
        action: () => {
          this.set('duplicateStartKey', 'duplicateCalendarEventWithScheduler');
          this.set('showDuplicateAppointmentModal', true);
        },
        classNamesString: ''
      }];
    }),
    duplicateCalendarEventFormOriginalModels: Ember.computed('duplicateAppointment', function () {
      return Ember.Object.create({
        calendarEvent: {
          model: this.duplicateAppointment,
          copyRelationMap: {
            calendarEventType: {
              doValidate: false,
              doSave: false
            },
            calendarEventDetailType: {
              doValidate: false,
              doSave: false
            },
            calendarUser: {
              doValidate: false,
              doSave: false
            },
            workOrder: {
              doValidate: false,
              doSave: false
            }
          }
        }
      });
    }),
    duplicateCalendarEventFormSteps: Ember.computed(function () {
      return Ember.Object.create({
        duplicateCalendarEvent: Ember.Object.create({
          stepNameKey: 'duplicateCalendarEvent',
          stepComponentPath: 'multi-step-form/forms/calendar-event/duplicate-calendar-event'
        }),
        duplicateCalendarEventWithScheduler: Ember.Object.create({
          stepNameKey: 'duplicateCalendarEventWithScheduler',
          stepComponentPath: 'multi-step-form/forms/work-orders/add-duplicate-appointment'
        })
      });
    }),
    eventsChangedObserver: Ember.observer('calendarEvents.[]', function () {
      return this.sortCustomerCalendarEvents.perform(this.stSortCurrentSortColumn, this.stSortAscending);
    }),
    sortCustomerCalendarEvents: (0, _emberConcurrency.task)(function* (column = null, ascending = null) {
      var eventsList = [];
      var events = yield this.calendarEvents;

      if (ascending === null) {
        // on init sort by startTime desc per TRAX-10580
        eventsList = this.sortEmberDataCollection(events, 'startTime', false);
      } else {
        // handle sorting
        eventsList = this.sortEmberDataCollection(events, column, ascending);
      }

      this.set('customerCalendarEvents', eventsList);
    }).on('init').drop(),
    actions: {
      updateDuplicateAppointment(row) {
        this.set('duplicateAppointment', row);
        this.set('selectedRowWorkOrder', row.workOrder);
      },

      sortAppointments(column, ascending) {
        this.sortList(column, ascending);
        this.sortCustomerCalendarEvents.perform(column, ascending);
      },

      mobileSortAppointments(sortColumn) {
        this.sortList(sortColumn, sortColumn.asc);
        this.sortCustomerCalendarEvents.perform(sortColumn.value, sortColumn.asc);
      },

      transitionToSubroute(subroute, path) {
        if (this.workOrderView) {
          if (!this.get('router.currentPath').includes('edit-create-appointment')) {
            this.router.transitionTo(this.get('router.currentPath').replace('.index', '') + '.edit-create-appointment', path);
          }
        } else {
          if (path && typeof path === 'string') {
            this.router.transitionTo(subroute, path);
          } else {
            this.router.transitionTo(subroute);
          }
        }
      }

    }
  });

  _exports.default = _default;
});