define("securitytrax-ember-ui/components/multi-step-form/forms/administration/content-management/edit-activation-fee/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nOlQwbs0",
    "block": "{\"symbols\":[\"@multiStepForm\",\"@formMode\"],\"statements\":[[11,\"div\"],[4,[38,0],[[32,0,[\"buttons\"]]],null],[12],[2,\"\\n    \"],[8,\"html/st-await\",[],[[\"@useSpinner\",\"@booleanLoaded\"],[true,[32,1,[\"finishedCopyingModels\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[14,0,\"grid-x grid-padding-x\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"cell small-12 medium-6\"],[12],[2,\"\\n                \"],[8,\"multi-step-form/switchable-inputs/masked-input\",[],[[\"@formMode\",\"@value\",\"@label\",\"@mask\"],[[32,2],[32,1,[\"formCopiedModels\",\"activationFee\",\"model\",\"amount\"]],[30,[36,1],[\"common.columns.amount\"],null],\"currency\"]],null],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"cell small-12 medium-6\"],[12],[2,\"\\n                \"],[8,\"multi-step-form/switchable-inputs/checkbox-input\",[],[[\"@formMode\",\"@value\",\"@label\",\"@toggleSwitch\"],[[32,2],[32,1,[\"formCopiedModels\",\"activationFee\",\"model\",\"active\"]],[30,[36,1],[\"common.status.active\"],null],true]],null],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"modals/confirm-popup\",[],[[\"@onConfirm\",\"@confirmMessage\",\"@confirmBtnText\",\"@confirmButtonClassNamesString\",\"@manuallyOpen\",\"@_dialogOpen\",\"@modalNamespace\"],[[30,[36,3],[[35,2]],null],[30,[36,1],[\"common.confirm.really_delete\"],null],[30,[36,1],[\"common.actions.delete\"],null],\"st-btn-warn\",true,[34,4],\"confirmDeleteModal\"]],null]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"t\",\"deleteActivationFee\",\"perform\",\"confirmDeleteModalOpen\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/content-management/edit-activation-fee/template.hbs"
    }
  });

  _exports.default = _default;
});