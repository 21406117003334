define("securitytrax-ember-ui/components/multi-step-form/forms/system/brinks/tests/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/system/brinks/brinks-base", "ember-concurrency"], function (_exports, _brinksBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var Tests = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = (0, _emberConcurrency.task)(function* () {
    var hashedData = yield (0, _emberConcurrency.hash)({
      listData: this.centralStationService.getData({
        model: this.args.monitoringCompany,
        method: 'brinks__getLists',
        payload: {
          list_types: [{
            list_type: 'test_categories'
          }]
        }
      })
    });
    this.testCategoriesOptions = hashedData.listData.lists.test_categories;
  }), _dec8 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    var noValidateArray = ['brinks-oosCategory'];

    if (!this.testCategory) {
      noValidateArray.push('brinks-onTestHours', 'brinks-onTestMinutes');
    }

    if (this.testCategory === '') {
      noValidateArray.push('brinks-testCategory');
    }

    var formattedObjects = [];
    formattedObjects.pushObject(Ember.Object.create({
      model: {
        testCategory: this.testCategory,
        onTestHours: this.onTestHours,
        onTestMinutes: this.onTestMinutes
      },
      extraValidationData: {
        noValidate: noValidateArray
      },
      filePath: 'central-station/test-brinks'
    }));
    yield multiStepForm.validateList.perform(formattedObjects);

    try {
      yield this.centralStationService.postData({
        model: this.args.customer,
        method: 'brinks__putAccountOnOffTest',
        payload: {
          test_category_id: this.testCategory,
          test_hours: Number(this.onTestHours),
          test_minutes: Number(this.onTestMinutes)
        }
      });
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
        this.testCategory = null;
        this.onTestHours = null;
        this.onTestMinutes = null;
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), _dec9 = Ember._action, (_class = (_temp = class Tests extends _brinksBase.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "centralStationService", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "onTestHours", _descriptor4, this);

      _initializerDefineProperty(this, "onTestMinutes", _descriptor5, this);

      _initializerDefineProperty(this, "testCategory", _descriptor6, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor7, this);

      _initializerDefineProperty(this, "saveOnOrOffTest", _descriptor8, this);

      this.initDataTask.perform();
    }

    renderButtons() {
      var buttons = [];

      if (this.args.formMode !== 'view') {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveOnOrOffTest.perform(this.args.multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary'
        });
      }

      this.args.multiStepForm.registerStepData('tests', 'buttons', buttons);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "onTestHours", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "onTestMinutes", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "testCategory", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "saveOnOrOffTest", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "renderButtons", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "renderButtons"), _class.prototype)), _class));
  _exports.default = Tests;
});