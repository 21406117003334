define("securitytrax-ember-ui/customers/base-route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/has-page-title", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _authenticatedRouteMixin, _hasPageTitle, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @classdesc
   * Base customer sub-route.
   *
   * @class BaseRoute
   * @extends Ember.Route
   * @mixes AuthenticatedRouteMixin
   * @mixes mixins.HasPageTitle
   * @memberof customers
   */
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _hasPageTitle.default,
  /** @lends customers.BaseRoute# */
  {
    quickAdd: Ember.inject.service(),

    model() {
      return this.modelFor('customers');
    },

    getPageTitle(model) {
      if (model.customer.accountType === 'Commercial' && model.customer.businessName) {
        return model.customer.businessName;
      } else {
        return (0, _objHelpers.getObjProperties)(model.customer, 'fname', 'lname').join(' ');
      }
    },

    getRouteName() {
      return "pages.customers.nav.".concat(this.routeName.split('.').pop());
    },

    customerController: Ember.computed(function () {
      return this.controllerFor('customers');
    }),

    setupBreadCrumbs() {
      this.customerController.set('breadCrumbs', null);
      this.customerController.set('breadCrumbData', null);
    },

    actions: {
      didTransition() {
        this.setupBreadCrumbs();
        this.quickAdd.set('showQuickAdd', true);
        this.quickAdd.set('page', 'customer');
      }

    }
  });

  _exports.default = _default;
});