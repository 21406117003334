define("securitytrax-ember-ui/components/multi-step-form/forms/administration/permissions/group-permissions/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/switchable-form-step", "securitytrax-ember-ui/components/multi-step-form/forms/administration/permissions/permissions-base"], function (_exports, _emberConcurrency, _switchableFormStep, _permissionsBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _permissionsBase.default.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    permissionsService: Ember.inject.service(),
    sessionData: Ember.inject.service('sessionData'),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    stFiltersService: Ember.inject.service(),
    currentResource: null,
    isSuperUser: undefined,
    policyModalTitle: '',

    init() {
      this._super(...arguments);

      this.getUserTask.perform();
    },

    parsedResourceTypeList: Ember.computed('addEditGroup', 'resourceTypes', function () {
      return this.parsedResourceTypeListTask.perform();
    }),
    parsedResourceTypeListTask: (0, _emberConcurrency.task)(function* () {
      var newFilters = yield this.stFiltersService.getFiltersByGroupKey('permissionsFilter', [], {
        attribute: true
      });
      var resourceGrantsPolicies = yield this.store.query('resourceType', {
        filter: newFilters
      });
      var sectionKeyMap = {};
      Ember.set(this, 'hasRows', resourceGrantsPolicies.get('length') > 0);

      if (newFilters.length > 0) {
        var attributeData = {};

        if (this.attribute) {
          var promises = [];
          resourceGrantsPolicies.forEach(resourceType => {
            promises.push(this.getResourceTypeAttributesList.perform(resourceType));
          });
          var promiseList = yield (0, _emberConcurrency.all)(promises);
          promiseList.forEach(attributeItem => {
            Object.assign(attributeData, attributeItem);
          });
        }

        resourceGrantsPolicies.forEach(resourceType => {
          if (this.attribute && !sectionKeyMap[resourceType.get('sectionKey')]) {
            var childResourceAttributes = attributeData[resourceType.get('id')];
            var combinedChildResourceAttributes = childResourceAttributes.attributes.concat(childResourceAttributes.metaAttributes);
            sectionKeyMap[resourceType.get('sectionKey')] = combinedChildResourceAttributes.some(childAttribute => childAttribute.includes(Ember.String.underscore(this.attribute.toLowerCase())));
          } else if (!this.attribute) {
            sectionKeyMap[resourceType.get('sectionKey')] = true;
          }
        });
      }

      var parsedGroupResourceTypeGrants = yield this.get('permissionsService.getParseGroupResourceTypeGrants').perform(this.addEditGroup);
      var resourceTypes = yield this.get('permissionsService.getResourceTypes').perform();
      var groupResourceTypePolicies = yield this.get('permissionsService.getGroupPolicyList').perform(this.addEditGroup);
      var hashData = yield Ember.RSVP.hash({
        parsedGroupResourceTypeGrants,
        resourceTypes,
        groupResourceTypePolicies
      });
      var finishedParsedResourceTypeList = yield this.get('permissionsService.parseResourceTypes').perform(this.addEditGroup, hashData.resourceTypes, hashData.parsedGroupResourceTypeGrants);
      var policiesCountMap = {};
      hashData.groupResourceTypePolicies.forEach(policy => {
        if (policy.get('deletedAt') === null) {
          var policyResourceTypeId = policy.get('resourceTypePolicy.resourceTypeId');

          if (policiesCountMap[policyResourceTypeId]) {
            policiesCountMap[policyResourceTypeId]++;
          } else {
            policiesCountMap[policyResourceTypeId] = 1;
          }
        }
      });
      var sortedFinishedParsedResourceTypeList = Object.keys(finishedParsedResourceTypeList).sort().reduce(function (result, key) {
        result[key] = finishedParsedResourceTypeList[key];
        result[key].hideSection = newFilters.length > 0 && !sectionKeyMap[key];
        var policyCount = 0;

        for (var index = 0; index < result[key].items.length; index++) {
          var resourceTypeId = result[key].items[index].resourceTypeId;

          if (policiesCountMap[resourceTypeId]) {
            policyCount += policiesCountMap[resourceTypeId];
          }
        }

        result[key].totalPoliciesSet = policyCount;
        return result;
      }, {});
      return sortedFinishedParsedResourceTypeList;
    }),
    getUserTask: (0, _emberConcurrency.task)(function* () {
      Ember.set(this, 'isSuperUser', yield this.sessionData.isSuperUser);
    }),
    actions: {
      transitionToGrantsPolicies(sectionKey) {
        this.router.transitionTo('administration.human-resources.permissions.group-details.grants-policies', this.addEditGroup.get('id'), sectionKey);
      },

      clearFilter() {
        Ember.setProperties(this, {
          resourceType: undefined,
          grant: undefined,
          attribute: undefined
        });
        Ember.notifyPropertyChange(this, 'parsedResourceTypeList');
      },

      filterItems() {
        Ember.notifyPropertyChange(this, 'parsedResourceTypeList');
      }

    }
  });

  _exports.default = _default;
});