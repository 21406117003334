define("securitytrax-ember-ui/components/multi-step-form/forms/administration/inventory/edit-create-rma/review/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData("step1", 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.save.perform(this.rmaMultiStepForm, buttonPromise, this.notificationNamespace, this.rma, this.pendingEquipmentLineItems);
        },
        classNamesString: 'st-btn st-btn-primary po-save-button'
      }]);
    }

  });

  _exports.default = _default;
});