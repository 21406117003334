define("securitytrax-ember-ui/my-account/login-information/route", ["exports", "securitytrax-ember-ui/my-account/base-route", "ember-concurrency", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _baseRoute, _emberConcurrency, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_routePageviewLogging.default, {
    sessionData: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform();
    },

    model() {
      return this.getModelData.perform();
    },

    getModelData: (0, _emberConcurrency.task)(function* () {
      var loggedInUser = yield this.sessionData.getUser();
      var userPasswordSetting = yield loggedInUser.get('userPasswordSetting');
      return Ember.RSVP.hash({
        user: loggedInUser,
        userPasswordSetting
      });
    })
  });

  _exports.default = _default;
});