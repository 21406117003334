define("securitytrax-ember-ui/models/customer-communication", ["exports", "securitytrax-ember-ui/models/base_models/customer-communication"], function (_exports, _customerCommunication) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Gives information about a communication between a user and a customer, either by
   * phone, written, etc.
   *
   * @class Communication
   * @extends BaseCustomerCommunication
   * @memberof model
   */
  var _default = _customerCommunication.default.extend({});

  _exports.default = _default;
});