define("securitytrax-ember-ui/components/pages/administration/human-resources/permissions/group-details/grants-policies/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    grantsPoliciesStep: Ember.computed('model.group', function () {
      return Ember.Object.create({
        grantsPolicies: Ember.Object.create({
          stepNameKey: 'grantsPolicies',
          stepComponentPath: 'multi-step-form/forms/administration/permissions/grants-policies'
        })
      });
    })
  });

  _exports.default = _default;
});