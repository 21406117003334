define("securitytrax-ember-ui/components/html/st-list-item-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qHnYiklq",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"st-list-item__icon\"],[12],[2,\"\\n\"],[1,[30,[36,2],[[35,1]],[[\"class\"],[[35,0]]]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"st-list-item__line\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"st-list-item__text\"],[12],[1,[34,3]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"icnClass\",\"iconName\",\"svg-icon\",\"content\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/st-list-item-icon/template.hbs"
    }
  });

  _exports.default = _default;
});