define("securitytrax-ember-ui/components/pages/users/users-history/component", ["exports", "@glimmer/component", "ember-concurrency", "lodash"], function (_exports, _component, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var LeadsHistoryList = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember.computed('args.user.logs'), _dec8 = (0, _emberConcurrency.task)(function* () {
    var count;

    if (this.page == 1) {
      count = 1;
    }

    var queryParams = {
      filter: "users = [ ".concat(this.args.user.get('id'), " ]"),
      sort: '-created_at',
      include: 'created_by_user',
      limit: this.limit,
      page: this.page,
      count: count
    };
    var userLogs = yield this.store.query('log', queryParams);

    if (this.page == 1) {
      Ember.set(this, 'recordCount', _lodash.default.get(userLogs, 'meta.record_count', 0));
    }

    return userLogs;
  }), _dec9 = Ember._action, (_class = (_temp = class LeadsHistoryList extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "limit", _descriptor4, this);

      _initializerDefineProperty(this, "page", _descriptor5, this);

      _initializerDefineProperty(this, "recordCount", _descriptor6, this);

      _initializerDefineProperty(this, "sortedHistoryTask", _descriptor7, this);
    }

    get sortedHistory() {
      return this.sortedHistoryTask.perform();
    }

    getLogs() {
      Ember.notifyPropertyChange(this, 'sortedHistory');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "limit", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 30;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "recordCount", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "sortedHistory", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "sortedHistory"), _class.prototype), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "sortedHistoryTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getLogs", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "getLogs"), _class.prototype)), _class));
  _exports.default = LeadsHistoryList;
});