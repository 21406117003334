define("securitytrax-ember-ui/components/multi-step-form/forms/invoices/credit-memo/details/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/invoices/base-step-components/details/base", "ember-concurrency"], function (_exports, _base, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CreditDetails = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = (0, _emberConcurrency.task)(function* () {
    yield this.initBaseData.perform();
    Ember.set(this.args.multiStepForm, 'formCopiedModels.invoice.model.invoiceType', 'credit_memo');
    this.confirmTitleText = this.intl.t('common.confirm.are_you_sure');
    this.confirmMessage = this.intl.t('common.confirm.really_delete');
    this.confirmBtnText = this.intl.t('common.actions.delete');

    if (!this.SwitchableFormStep.isNewRecord) {
      var invoice = yield this.args.multiStepForm.formOriginalModelsGetVal('invoice.model');
      var creditMemoPayment = yield invoice.get('creditMemoPayment');
      var customerPayment = creditMemoPayment ? yield this.store.findRecord('customerPayment', creditMemoPayment.get('id'), {
        include: 'customer_invoice_payments'
      }) : null;

      if (customerPayment) {
        var customerInvoicePayments = yield customerPayment.get('customerInvoicePayments');

        if (customerInvoicePayments.length > 0) {
          this.customerInvoicePayments = customerInvoicePayments;
          this.confirmTitleText = this.intl.t('common.confirm.unapply_and_delete');
          this.confirmMessage = this.intl.t('common.confirm.really_unapply_and_delete');
          this.confirmBtnText = this.intl.t('common.actions.unapply_and_delete');
        }
      }
    }
  }), _dec6 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    if (this.customerInvoicePayments.length > 0) {
      var promiseArray = [];
      this.customerInvoicePayments.forEach(invoicePayment => {
        promiseArray.push(invoicePayment.destroyRecordWithRollbackTask.perform());
      });
      yield (0, _emberConcurrency.all)(promiseArray);
    }

    yield this.deleteInvoiceTask.perform(buttonPromise);
  }), (_class = (_temp = class CreditDetails extends _base.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "confirmTitleText", _descriptor2, this);

      _initializerDefineProperty(this, "confirmMessage", _descriptor3, this);

      _initializerDefineProperty(this, "confirmBtnText", _descriptor4, this);

      _defineProperty(this, "customerInvoicePayments", []);

      _initializerDefineProperty(this, "initFormData", _descriptor5, this);

      _initializerDefineProperty(this, "deleteCreditMemo", _descriptor6, this);

      this.initFormData.perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "confirmTitleText", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "confirmMessage", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "confirmBtnText", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "initFormData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "deleteCreditMemo", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CreditDetails;
});