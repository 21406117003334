define("securitytrax-ember-ui/validations/location", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateObject = {
        name: {
          presence: this.customMessageOrDefault(data, 'location-name')
        },
        city: {
          presence: this.customMessageOrDefault(data, 'location-city')
        },
        state: {
          presence: this.customMessageOrDefault(data, 'location-state')
        },
        timeZone: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('location-timeZone')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'location-timeZone');
            }
          }
        },
        shippingContact: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('location-shippingContact')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'location-shippingContact');
            }
          }
        },
        shippingStreet: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('location-shippingStreet')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'location-shippingStreet');
            }
          }
        },
        shippingCity: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('location-shippingCity')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'location-shippingCity');
            }
          }
        },
        shippingState: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('location-shippingState')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'location-shippingState');
            }
          }
        },
        shippingZip: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('location-shippingZip')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'location-shippingZip');
            }
          },
          format: {
            pattern: /\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d/,
            message: this.customMessage(data, 'shippingZipInvalid')
          }
        }
      }; //if they include an email, make sure its a valid email format

      if (this.email) {
        validateObject['email'] = {
          email: this.customMessageOrDefault(data, 'location-email')
        };
      }

      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject, {
        fullMessages: this.prependProperty(data)
      });
      return this.outputFormatting(validationData, 'location');
    }

  });

  _exports.default = _default;
});