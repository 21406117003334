define("securitytrax-ember-ui/services/calendar-drag-drop-service", ["exports", "jquery", "ember-concurrency", "moment"], function (_exports, _jquery, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CalendarDragDropService = (_dec = Ember.inject.service('CalendarDataService'), _dec2 = Ember.inject.service('events/calendar-events'), _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = (0, _emberConcurrency.task)(function* (row, hour, event) {
    try {
      if (event.preventDefault) {
        event.preventDefault();
      }

      if (event.stopPropagation) {
        event.stopPropagation();
      }

      this.dragActionPending = true;
      var calendarEventModelId = this.calendarEventModelId;
      var calendarPages = yield this.CalendarDataService.calendarPages;
      var calendarEventOriginalUserId = this.calendarEventOriginalUserId;
      var calendarEventDate = (0, _moment.default)(this.calendarEventDate);
      var calendarPage;

      if (this.CalendarDataService.type === 'user') {
        calendarPage = calendarPages.find(calendarPage => calendarPage.id === calendarEventOriginalUserId);
      } else {
        calendarPage = calendarPages.find(calendarPage => calendarPage.id === calendarEventDate.format('M-D-YYYY'));
      }

      var calendarEventModel = yield calendarPage.calendarEvents.findBy('id', calendarEventModelId);

      if (calendarEventModel) {
        if (calendarEventOriginalUserId !== row.user.get('id') || calendarEventDate.format('M-D-YYYY') !== hour.format('M-D-YYYY')) {
          this.confirmMoveUserDialogAction = () => {
            return this.afterConfirmDropEventTask.perform(calendarEventModel, row, hour);
          };

          this.cancelMoveUserDialogAction = () => {
            this.cleanUpDragOver();
          };

          this.confirmMoveUserDialogOpen = true;
        } else {
          //not a new user or new page
          yield calendarEventModel.moveEvent(row.user, hour, row.user);
          yield this.afterDropEventTask.perform(calendarEventModel);
        }
      }

      this.dragActionPending = false;
      return true;
    } catch (error) {
      (false && Ember.warn(error, {
        id: 'calendar-drag-drop-service dropEventTask'
      }));
    }
  }), _dec11 = (0, _emberConcurrency.task)(function* (calendarEventModel) {
    yield calendarEventModel.save();
    Ember.notifyPropertyChange(this, 'calendarEventsByUserId');
    Ember.notifyPropertyChange(this, 'users');
    this.cleanUpDragOver();
  }), _dec12 = (0, _emberConcurrency.task)(function* (calendarEventModel, row, hour) {
    try {
      var user = row.user;
      var calendarPage;
      var oldCalendarPage;

      if (this.CalendarDataService.type === 'user') {
        calendarPage = this.CalendarDataService.findCalendarPageById(this.calendarEventOriginalUserId);
        oldCalendarPage = calendarPage;

        if (this.calendarEventOriginalUserId !== user.id) {
          calendarPage = this.CalendarDataService.findCalendarPageById(user.id);
        }

        var oldRow = oldCalendarPage.rows.findBy('id', (0, _moment.default)(this.calendarEventDate).format('M-D-YYYY'));
        yield calendarEventModel.moveEvent(row.user, hour, oldRow.user);
        yield calendarEventModel.save();
      } else {
        calendarPage = this.CalendarDataService.findCalendarPageById(hour.format('M-D-YYYY'));
        oldCalendarPage = calendarPage;

        if (yield (0, _moment.default)(calendarEventModel.get('startTime')).format('M-D-YYYY') !== hour.format('M-D-YYYY')) {
          oldCalendarPage = this.CalendarDataService.findCalendarPageById((0, _moment.default)(calendarEventModel.startTime).format('M-D-YYYY'));
        }

        var oldUserModel = yield calendarEventModel.get('calendarUser');

        var _oldRow = oldCalendarPage.rows.findBy('id', oldUserModel.get('id'));

        if (_oldRow === undefined) {
          _oldRow = oldCalendarPage.rows.findBy('id', 'inactive');
        }

        var newRow = calendarPage.rows.findBy('id', user.get('id'));
        yield calendarEventModel.moveEvent(newRow.user, hour, oldUserModel);
        yield calendarEventModel.save();
      }

      this.CalendarEventsService.trigger('refresh-page', calendarPage.id);

      if (oldCalendarPage.id !== calendarPage.id) {
        yield oldCalendarPage.removeCalendarEvent(calendarEventModel.id);
        yield calendarPage.addCalendarEvent(calendarEventModel);
        this.CalendarEventsService.trigger('refresh-page', oldCalendarPage.id);
      }

      this.confirmMoveUserDialogOpen = false;
      this.hoverColor = null;
      this.hoverWidth = null;
      this.calendarEventModelId = null;
      this.calendarEventDate = null;
      this.cleanUpDragOver();
      this.dragActionPending = false;
    } catch (error) {
      (false && Ember.warn(error, {
        id: 'calendar-drag-drop-service afterConfirmDropEventTask'
      }));
    }
  }), _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, (_class = (_temp = class CalendarDragDropService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "CalendarDataService", _descriptor, this);

      _initializerDefineProperty(this, "CalendarEventsService", _descriptor2, this);

      _initializerDefineProperty(this, "hoverColor", _descriptor3, this);

      _initializerDefineProperty(this, "hoverWidth", _descriptor4, this);

      _initializerDefineProperty(this, "calendarEventModelId", _descriptor5, this);

      _initializerDefineProperty(this, "confirmMoveUserDialogAction", _descriptor6, this);

      _initializerDefineProperty(this, "cancelMoveUserDialogAction", _descriptor7, this);

      _initializerDefineProperty(this, "confirmMoveUserDialogOpen", _descriptor8, this);

      _initializerDefineProperty(this, "dragActionPending", _descriptor9, this);

      _initializerDefineProperty(this, "dropEventTask", _descriptor10, this);

      _initializerDefineProperty(this, "afterDropEventTask", _descriptor11, this);

      _initializerDefineProperty(this, "afterConfirmDropEventTask", _descriptor12, this);
    }

    initDragDrop() {
      this.dragActionPending = false;
    }

    cleanUpDragOver() {
      (0, _jquery.default)('.selected-new-last').removeClass('selected-new-last');
      (0, _jquery.default)('.selected-new-first').removeClass('selected-new-first');
      (0, _jquery.default)('.selected-new').removeClass('selected-new').css('border-color', '');
    }

    dragStart(e)
    /* hide = false */
    {
      //MATT CAL TODO
      var dragData = e.dataTransfer.getData('text');
      var [width, color, calendarEventModelId, calendarEventDate, calendarEventOriginalUserId, calendarCategory] = dragData.split(':::');
      this.hoverColor = color;
      this.hoverWidth = width;
      this.calendarEventModelId = calendarEventModelId;
      this.calendarEventDate = calendarEventDate;
      this.calendarEventOriginalUserId = calendarEventOriginalUserId;
      this.calendarCategory = calendarCategory;
    }

    dragOver(row, event) {
      if (row.isActive) {
        if (this.calendarCategory === 'sale_schedule' && row.isSalesRep && !(0, _jquery.default)(event.currentTarget).hasClass('calendar__drop-cell--no-drop') || this.calendarCategory === 'tech_schedule' && row.isTech && !(0, _jquery.default)(event.currentTarget).hasClass('calendar__drop-cell--no-drop')) {
          (0, _jquery.default)(event.currentTarget).addClass('selected-new-first').addClass('selected-new').css('border-color', "".concat(this.hoverColor));
          (0, _jquery.default)(event.currentTarget).nextAll().slice(0, this.hoverWidth - 1).addClass('selected-new').css('border-color', "".concat(this.hoverColor));
          (0, _jquery.default)('.selected-new').last().addClass('selected-new-last');
        }
      }

      return false;
    }

    dragLeave(row, event) {
      if (row.isActive) {
        (0, _jquery.default)('.selected-new').last().removeClass('selected-new-last');
        (0, _jquery.default)(event.currentTarget).nextAll().slice(0, this.hoverWidth - 1).removeClass('selected-new').css('border-color', '');
        (0, _jquery.default)(event.currentTarget).removeClass('selected-new').removeClass('selected-new-first').css('border-color', '');
      }

      return false;
    }

    drop(row, hour, event) {
      if (row.isActive) {
        if (this.calendarCategory === 'sale_schedule' && row.isSalesRep && !(0, _jquery.default)(event.currentTarget).hasClass('calendar__drop-cell--no-drop') || this.calendarCategory === 'tech_schedule' && row.isTech && !(0, _jquery.default)(event.currentTarget).hasClass('calendar__drop-cell--no-drop')) {
          return this.dropEventTask.perform(row, hour, event);
        }
      }

      return false;
    }

    dragEnded(e) {
      var element = e.srcElement;
      (0, _jquery.default)('.calendar.selected-new').removeClass('selected-new');
      (0, _jquery.default)('.calendar.selected-new-last').removeClass('selected-new-last');
      (0, _jquery.default)('.calendar.selected-new-first').removeClass('selected-new-first');
      this.dragActive = false;
      element.classList.remove('hide');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "CalendarDataService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "CalendarEventsService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hoverColor", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "hoverWidth", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "calendarEventModelId", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "confirmMoveUserDialogAction", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "cancelMoveUserDialogAction", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "confirmMoveUserDialogOpen", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "dragActionPending", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "dropEventTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "afterDropEventTask", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "afterConfirmDropEventTask", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "dragStart", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "dragStart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragOver", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "dragOver"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragLeave", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "dragLeave"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "drop", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "drop"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragEnded", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "dragEnded"), _class.prototype)), _class));
  _exports.default = CalendarDragDropService;
});