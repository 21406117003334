define("securitytrax-ember-ui/components/multi-step-form/forms/administration/system/global-settings/edit-recurring-invoice-settings/component", ["exports", "ember-concurrency", "lodash"], function (_exports, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    stNotificationManager: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    initDataTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true);
      Ember.set(this, 'recurringInvoiceDefaults', yield this.sessionData.getCompanyRecurringInvoiceSettings());
      Ember.set(this, 'defaultStartDateDaysInFuture', _lodash.default.get(this.recurringInvoiceDefaults, 'defaultStartDateDaysInFuture', null));
      var defaultStartDateDaysInFutureOptions = [];

      for (var index = 0; index <= 30; index++) {
        defaultStartDateDaysInFutureOptions.push({
          name: index,
          value: index
        });
      }

      Ember.set(this, 'defaultStartDateDaysInFutureOptions', defaultStartDateDaysInFutureOptions);
    }).on('init'),
    cancelTask: (0, _emberConcurrency.task)(function* () {
      try {
        Ember.set(this, 'defaultStartDateDaysInFuture', _lodash.default.get(this.recurringInvoiceDefaults, 'defaultStartDateDaysInFuture', null));
        yield Ember.set(this, 'formMode', 'view');
        this.stNotificationManager.addSuccessKey('common.notifications.generic_cancel');
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err);
      }
    }).drop(),
    saveRecurringInvoiceSettingsTask: (0, _emberConcurrency.task)(function* () {
      if (this.recurringInvoiceDefaults && this.defaultStartDateDaysInFuture) {
        Ember.set(this.recurringInvoiceDefaults, 'defaultStartDateDaysInFuture', this.defaultStartDateDaysInFuture);
        yield this.recurringInvoiceDefaults.save();
      }

      Ember.set(this, 'formMode', 'view');
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
    }).restartable()
  });

  _exports.default = _default;
});