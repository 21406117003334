define("securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/adc/loan-details/details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "140+MtIO",
    "block": "{\"symbols\":[\"@formMode\",\"@multiStepForm\"],\"statements\":[[10,\"div\"],[14,0,\"grid-x grid-padding-x\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cell small-12 medium-6 large-3\"],[12],[2,\"\\n        \"],[8,\"multi-step-form/switchable-inputs/masked-input\",[],[[\"@formMode\",\"@value\",\"@label\",\"@mask\",\"@required\",\"@errors\",\"@validationKey\"],[[32,1],[32,2,[\"formCopiedModels\",\"consumerFinancingApplication\",\"model\",\"creditAmountRequested\"]],[30,[36,0],[\"pages.customers.accounting.financing.amount_requested\"],null],\"currency\",true,[32,2,[\"validationErrors\"]],\"consumerFinancingApplication-creditAmountRequested\"]],null],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\" \"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/adc/loan-details/details/template.hbs"
    }
  });

  _exports.default = _default;
});