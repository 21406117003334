define("securitytrax-ember-ui/reports/view/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GX/27Kvr",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"model\"],[[35,0]]]]]],\"hasEval\":false,\"upvars\":[\"model\",\"reports/report-view\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/reports/view/template.hbs"
    }
  });

  _exports.default = _default;
});