define("securitytrax-ember-ui/components/multi-step-form/forms/reports/add-report/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/reports/reports-base", "ember-concurrency"], function (_exports, _reportsBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { computed } from '@ember/object';
  var _default = _reportsBase.default.extend({
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    prepareFormTask: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = this.multiStepForm; //ensure we are starting with empty report every. time. add immediately after add was causing issues

      if (multiStepForm.formOriginalModelsGetVal('report.model') !== null) {
        multiStepForm.formOriginalModelsSetVal('report.model', null); //trigger setup copy models

        yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);
      }
    }).on('init'),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      var intl = this.intl;
      multiStepForm.registerStepData('addReport', 'buttons', [{
        text: intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.createReportTask.perform(multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary save'
      }]);
    },

    createReportTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var toCreateObjects = yield multiStepForm.validateCreateAll({
        notificationNamespace: 'modal',
        extraValidationData: {
          validationMessages: {
            'report-name': this.intl.t("common.validations.presence", {
              propertyLabel: this.intl.t("pages.reports.name")
            }),
            'report-reportCategory': this.intl.t("common.validations.presence", {
              propertyLabel: this.intl.t("pages.reports.category")
            }),
            'report-reportObject': this.intl.t("common.validations.presence", {
              propertyLabel: this.intl.t("pages.reports.type")
            })
          }
        }
      });
      var report = this.store.createRecord('report', toCreateObjects.report.data);
      var newReport = yield toCreateObjects.report.saveWithRollbackAndNotification(report);
      yield multiStepForm.formOriginalModelsSetVal('report.model', newReport);
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal'); // go directly to edit form. change multi step form step?

        multiStepForm.reportFormMode = 'edit';
        multiStepForm.currentStepNameKeySetVal('editReport');
        this.refreshReports.perform(this.filter, newReport);
      });
    })
  });

  _exports.default = _default;
});