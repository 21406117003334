define("securitytrax-ember-ui/models/equipment-vendor-to-recipient", ["exports", "securitytrax-ember-ui/models/base_models/equipment-vendor-to-recipient", "ember-concurrency"], function (_exports, _equipmentVendorToRecipient, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _equipmentVendorToRecipient.default.extend({
    recipient: Ember.computed('user', 'location', function () {
      return this.getRecipientTask.perform();
    }),
    getRecipientTask: (0, _emberConcurrency.task)(function* () {
      var hashedData = yield (0, _emberConcurrency.hash)({
        user: this.user,
        location: this.location
      });
      return hashedData.user || hashedData.location;
    })
  });

  _exports.default = _default;
});