define("securitytrax-ember-ui/models/ember-card", ["exports", "securitytrax-ember-ui/models/base_models/ember-card"], function (_exports, _emberCard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCard.default.extend({});

  _exports.default = _default;
});