define("securitytrax-ember-ui/models/base_models/user-inventory-setting", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    shippingStreet: (0, _model.attr)('string', {
      'readOnly': false
    }),
    shippingCity: (0, _model.attr)('string', {
      'readOnly': false
    }),
    shippingState: (0, _model.attr)('string', {
      'readOnly': false
    }),
    shippingZip: (0, _model.attr)('string', {
      'readOnly': false
    }),
    inventoryRecipient: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    inventoryAutoReplenish: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    inventoryBaseCalculatorMultiplier: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    inventoryRecipientTemplate: (0, _model.belongsTo)('equipment-to-recipient-template', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    user: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    equipmentVendorToRecipients: (0, _model.hasMany)('equipment-vendor-to-recipient', {
      'readOnly': true,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});