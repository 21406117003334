define("securitytrax-ember-ui/components/html/st-spacer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1jqxZMer",
    "block": "{\"symbols\":[\"@height\"],\"statements\":[[10,\"div\"],[15,5,[31,[\"height:\",[32,1],\"px\"]]],[12],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/st-spacer/template.hbs"
    }
  });

  _exports.default = _default;
});