define("securitytrax-ember-ui/components/lead/lead-information/component", ["exports", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    leadStatusEvents: Ember.inject.service('events/lead-status-events'),
    createEdit: undefined,
    createLeadFormStarted: undefined,
    editLeadFormMode: undefined,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        createEdit: 'edit',
        createLeadFormStarted: false,
        editLeadFormMode: 'view'
      });
      this.leadStatusEvents.on('redoModels', this, '_eventCallback');
      Ember.defineProperty(this, 'leadFormOriginalModels', Ember.computed('createLeadFormStarted', 'createEdit', 'model.lead', 'grants.userGrants', this.store.getFullComputedWatcherStringByModelName('customer', {
        prefix: 'model.',
        actualVariableName: 'lead'
      }), function () {
        var grants = this.grants;
        return Ember.Object.create({
          customer: {
            isCreate: this.createEdit === 'create',
            model: this.model && this.createEdit != 'create' ? this.get('model.lead') : null,
            copyRelationMap: {
              customerBirthdate: {
                doCopy: grants.getIndividualGrant('lead_birthdates.view') || this.model == null || this.get('model.lead.responseMeta.masked_birthdate') === null,
                doValidate: true,
                doSave: grants.getIndividualGrant('lead_birthdates.modify') || this.model == null || this.get('model.lead.responseMeta.masked_birthdate') === null
              },
              customerSsn: {
                doCopy: grants.getIndividualGrant('lead_ssns.view') || this.model == null || this.get('model.lead.responseMeta.masked_ssn') === '',
                doValidate: grants.getIndividualGrant('lead_ssns.view'),
                doSave: grants.getIndividualGrant('lead_ssns.modify') || this.model == null || this.get('model.lead.responseMeta.masked_ssn') === ''
              },
              leadInformation: {
                doCopy: grants.getIndividualGrant('lead_information.view') || this.model == null,
                doSync: grants.getIndividualGrant('lead_information.view') || this.model == null,
                doValidate: grants.getIndividualGrant('lead_information.view') || this.model == null,
                doSave: grants.getIndividualGrant('lead_information.modify') || this.model == null,
                relationships: {
                  leadStatus: {
                    readOnly: true
                  },
                  monitoringCompany: {
                    readOnly: true
                  },
                  dealerNumber: {
                    readOnly: true
                  }
                }
              },
              leadSourceInformation: {
                doCopy: grants.getIndividualGrant('lead_source_information.view') || this.model == null,
                doSync: grants.getIndividualGrant('lead_source_information.view') || this.model == null,
                doValidate: grants.getIndividualGrant('lead_source_information.view') || this.model == null,
                doSave: grants.getIndividualGrant('lead_source_information.modify') || this.model == null,
                relationships: {
                  leadCompany: {
                    readOnly: true,
                    doCopy: grants.getIndividualGrant('lead_companies.view')
                  },
                  leadCompanyCampaign: {
                    readOnly: true,
                    doCopy: grants.getIndividualGrant('lead_company_campaigns.view')
                  }
                }
              }
            }
          }
        });
      }));
    },

    _eventCallback() {
      this.notifyPropertyChange('leadFormOriginalModels');
    },

    willDestroy() {
      this._super(...arguments);

      this.leadStatusEvents.off('redoModels', this, '_eventCallback');
    },

    leadInformationForm: Ember.computed(function () {
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.createEdit == 'create' ? this.intl.t('pages.leads.title_create') : this.intl.t('pages.leads.information'),
          stepComponentPath: 'multi-step-form/forms/leads/information'
        })
      });
    }),
    createLeadProgressBar: Ember.computed('newCustomerName', 'leadInformationForm', function () {
      var items = [];
      var formSteps = this.leadInformationForm;
      Object.keys(formSteps).forEach(function (key) {
        items.push({
          title: formSteps[key].progressBarTitle,
          stepNameKey: formSteps[key].stepNameKey,
          isClickable: false,
          hidden: formSteps[key].hidden,
          isComplete: formSteps[key].isComplete
        });
      });
      return {
        activeIndex: 0,
        viewTemplate: 'default-view',
        items: items,
        hideProgressBar: true
      };
    })
  });

  _exports.default = _default;
});