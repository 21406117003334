define("securitytrax-ember-ui/components/modals/st-paper/paper-dialog/component", ["exports", "jquery", "securitytrax-ember-ui/utils/debug"], function (_exports, _jquery, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PaperDialog
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    tagName: '',
    escapeToClose: true,
    focusOnOpen: true,
    opaque: true,
    // Calculate a default that is always valid for the parent of the backdrop.
    wormholeSelector: '#modal-wormhole',
    defaultedParent: Ember.computed.or('parent', 'wormholeSelector'),
    // Calculate a default that is always valid where the opening transition should originate.
    defaultedOpenFrom: Ember.computed.or('openFrom', 'origin', 'parent'),
    // Calculate a default that is always valid where the closing transition should terminate.
    defaultedCloseTo: Ember.computed.or('closeTo', 'origin', 'parent'),
    // Calculate the id of the wormhole destination, setting it if need be. The
    // id is that of the 'parent', if provided, or 'paper-wormhole' if not.
    destinationId: Ember.computed('defaultedParent', function () {
      if ((0, _debug.isTestEnvironment)() && !this.parent) {
        return 'ember-testing';
      }

      var parent = this.defaultedParent;
      var jQueryParent = (0, _jquery.default)(parent); // If the parent isn't found, assume that it is an id, but that the DOM doesn't
      // exist yet. This only happens during integration tests or if entire application
      // route is a dialog.

      if (jQueryParent.length === 0 && parent.charAt(0) === '#') {
        return parent.substring(1);
      } else {
        var id = jQueryParent.attr('id');

        if (!id) {
          id = "".concat(this.elementId, "-parent");
          jQueryParent.get(0).id = id;
        }

        return id;
      }
    }),
    constants: Ember.inject.service(),
    onClose: () => {},

    didInsertElement() {
      this._super(...arguments);

      if (this.escapeToClose) {
        (0, _jquery.default)("#".concat(this.destinationId)).on("keydown.".concat(this.elementId), e => {
          if (e.keyCode === this.get('constants.KEYCODE.ESCAPE') && this.onClose) {
            this.onClose();
          }
        });
      }
    },

    willDestroyElement() {
      this._super(...arguments);

      if (this.escapeToClose) {
        (0, _jquery.default)("#".concat(this.destinationId)).off("keydown.".concat(this.elementId));
      }
    },

    actions: {
      outsideClicked() {
        if (this.clickOutsideToClose && this.onClose) {
          this.onClose();
        }
      }

    }
  });

  _exports.default = _default;
});