define("securitytrax-ember-ui/helpers/de-camel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deCamel = deCamel;
  _exports.default = void 0;

  function deCamel(params) {
    return params[0].replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
      return str.toUpperCase();
    });
  }

  var _default = Ember.Helper.helper(deCamel);

  _exports.default = _default;
});