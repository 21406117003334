define("securitytrax-ember-ui/components/dashboard-config/canvas/section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EQSlpwuJ",
    "block": "{\"symbols\":[\"card\",\"@editDashboard\",\"@customer\",\"@dragStartHook\",\"@dragEndHook\",\"@showRiskScore\"],\"statements\":[[10,\"div\"],[14,0,\"grid-x\"],[14,5,\"width: 100%;\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cell auto\"],[12],[2,\"\\n        \"],[1,[34,8]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"sortable-objects\",[[24,0,\"dashboard-grid--big-layout home-dashboard-view\"]],[[\"@sortableObjectList\",\"@sortEndAction\",\"@enableSort\",\"@useSwap\",\"@inPlace\",\"@sortingScope\"],[[34,9],[30,[36,1],[[32,0],\"sortEndAction\"],null],true,true,true,\"sortingGroup\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,11],[[30,[36,10],[[30,[36,10],[[35,9]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,7],[[30,[36,5],[[30,[36,6],[[32,1,[\"config\",\"grant\"]]],null],[32,1,[\"config\",\"hasRoles\"]],[32,1,[\"config\",\"passADCCheck\"]],[30,[36,3],[[30,[36,5],[[30,[36,4],[[32,1,[\"card\"]],\"risk-score-card\"],null],[30,[36,3],[[32,6]],null]],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"dashboard-config/canvas/dashboard-card\",[],[[\"@category\",\"@cardName\",\"@card\",\"@isSortable\",\"@sortingScope\",\"@updateDashboard\",\"@deleteFunction\",\"@titleOverride\",\"@config\",\"@editDashboard\",\"@customer\",\"@dragStartHook\",\"@dragEndHook\"],[[32,1,[\"config\",\"category\"]],[32,1,[\"card\"]],[32,1],\"true\",\"sortingGroup\",[34,0],[30,[36,1],[[32,0],\"deleteCard\"],null],[34,2,[\"config\",\"titleOverride\"]],[32,1,[\"config\"]],[32,2],[32,3],[32,4],[32,5]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"updateDashboard\",\"action\",\"cardData\",\"not\",\"is-equal\",\"and\",\"has-grants\",\"if\",\"sectionTitle\",\"cards\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/dashboard-config/canvas/section/template.hbs"
    }
  });

  _exports.default = _default;
});