define("securitytrax-ember-ui/components/html/st-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AAbSYD81",
    "block": "{\"symbols\":[\"t\"],\"statements\":[[6,[37,7],null,null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,5],[[30,[36,4],[[32,1,[\"head\"]],\"expected `t.head` to be a contextual component but found a string. Did you mean `(component t.head)`? ('securitytrax-ember-ui/components/html/st-table/template.hbs' @ L2:C6) \"],null]],[[\"columns\",\"sorts\",\"onUpdateSorts\"],[[35,3],[35,0],[30,[36,2],[[32,0],[30,[36,1],[[35,0]],null]],null]]]]],[2,\"\\n\\n    \"],[1,[30,[36,5],[[30,[36,4],[[32,1,[\"body\"]],\"expected `t.body` to be a contextual component but found a string. Did you mean `(component t.body)`? ('securitytrax-ember-ui/components/html/st-table/template.hbs' @ L8:C6) \"],null]],[[\"rows\"],[[35,6]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"sorts\",\"mut\",\"action\",\"columns\",\"-assert-implicit-component-helper-argument\",\"component\",\"rows\",\"ember-table\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/st-table/template.hbs"
    }
  });

  _exports.default = _default;
});