define("securitytrax-ember-ui/components/multi-step-form/forms/leads/change-status/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/forms/customer-lead-shared", "securitytrax-ember-ui/mixins/set-user"], function (_exports, _emberConcurrency, _customerLeadShared, _setUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_setUser.default, _customerLeadShared.default, {
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    userService: Ember.inject.service(),
    leadStatusEvents: Ember.inject.service('events/lead-status-events'),
    leadStatuses: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      this.setupButtons();
    },

    setupButtons: function () {
      var multiStepForm = this.multiStepForm;

      if (this.formMode != 'view') {
        multiStepForm.registerStepData('changeStatus', 'buttons', [{
          text: this.intl.t('common.actions.save'),
          classNamesString: 'st-btn st-btn-primary lead-information-change-status-button',
          click: buttonPromise => {
            return this.changeStatusTask.perform(multiStepForm, buttonPromise);
          }
        }]);
      }
    },

    willDestroy() {
      var multiStepForm = this.multiStepForm;
      multiStepForm.setupCopyModels.perform();
    },

    getLeadStatuses: Ember.computed(function () {
      return this.leadStatusesTask.perform();
    }),
    leadStatusesTask: (0, _emberConcurrency.task)(function* () {
      try {
        var blacklistedIds = ['1', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19'];
        return yield this.leadStatuses.getLeadStatusOptionsTask.perform(blacklistedIds);
      } catch (e) {// console.log('this is an error!', e);
      }
    }),
    repUsers: Ember.computed(function () {
      return this.userService.getUsersList('lead_rep', this.lead);
    }),
    changeStatusTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      try {
        //const lead = this.lead;
        if (this.leadsBulkArray) {
          var bulkArray = this.leadsBulkArray;
          var bulkSaves = [];

          for (var i = 0; i < bulkArray.length; i++) {
            bulkSaves.push(this.saveLeadStatusTask.perform(bulkArray[i], multiStepForm.formCopiedModels.customer.model.leadInformation.leadStatus, multiStepForm.formCopiedModels.customer.model.leadInformation.leadRepUser));
          }

          yield (0, _emberConcurrency.all)(bulkSaves);
        } else {
          yield this.saveLeadStatusTask.perform(this.lead, multiStepForm.formCopiedModels.customer.model.leadInformation.leadStatus, multiStepForm.formCopiedModels.customer.model.leadInformation.leadRepUser);
        }

        buttonPromise.then(() => {
          this.leadStatusEvents.trigger('redoModels');
          this.multiStepForm.formStartedSetVal(false);
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        });
      } catch (error) {
        (false && Ember.warn('error in change lead status task: ' + error, {
          id: 'multiStepForm.forms.leads.changeStatus'
        }));
        this.stNotificationManager.addErrorMessages(error, {
          notificationNamespace: 'changeLeadStatusModal'
        });
        throw error;
      }
    }),
    saveLeadStatusTask: (0, _emberConcurrency.task)(function* (lead, leadStatus, leadRepUser) {
      //validate
      yield this.multiStepForm.validateList.perform([Ember.Object.create({
        model: {
          leadStatus,
          leadRepUser
        },
        filePath: 'leads/change-status'
      })]);
      var hashData = yield (0, _emberConcurrency.hash)({
        leadInformation: lead.get('leadInformation'),
        leadStatus: this.store.peekThenFindRecord('leadStatus', leadStatus.id)
      });
      var leadInformation = hashData.leadInformation;

      try {
        leadInformation.set('leadStatus', hashData.leadStatus);

        if (leadStatus.name == 'Assigned') {
          var theUser = yield this.store.peekThenFindRecord('user', leadRepUser.id);
          leadInformation.set('leadRepUser', theUser);
        } else {
          leadInformation.set('leadRepUser', null);
        }

        yield leadInformation.save();
      } catch (error) {
        (false && Ember.warn('error in saving lead information: ' + error, {
          id: 'multiStepForm.forms.leads.saveLeadStatusTask'
        }));
        leadInformation.rollbackAttributes();
        throw error;
      }
    })
  });

  _exports.default = _default;
});