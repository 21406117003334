define("securitytrax-ember-ui/components/multi-step-form/forms/user-actions/add-users/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/switchable-form-step", "securitytrax-ember-ui/utils/obj-helpers", "lodash", "jquery"], function (_exports, _emberConcurrency, _switchableFormStep, _objHelpers, _lodash, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    orgTree: Ember.inject.service(),
    allUsersList: undefined,
    addArray: undefined,
    saveComplete: true,
    resultsLoaded: true,
    currentUserHierarchy: null,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        addArray: []
      });
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('modalButtons', [{
        text: this.intl.t('common.actions.cancel'),
        click: () => {
          this.set('addUsersFormStarted', false);
        },
        classNamesString: 'st-btn st-btn-secondary cancel-group-users-button'
      }, {
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.addUsersTask.perform(buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary save-group-users-button'
      }]);
    },

    findUsers: (0, _emberConcurrency.task)(function* () {
      var orgTree = this.orgTree;
      var userHierarchyTree = yield orgTree.get('userHierarchyTree');

      if (userHierarchyTree) {
        var uncategorizedUsers = yield userHierarchyTree.invokeAction('get_users_not_in_hierarchy');
        var directoryUser = yield this.store.findAll('directoryUser', {
          include: 'user,user_hierarchy_title'
        });

        var directoryUsers = _lodash.default.intersectionBy(directoryUser.toArray(), uncategorizedUsers.responsePayload.data, 'id');

        return directoryUsers;
      }
    }).drop(),
    searchResults: Ember.computed(function () {
      return this.getSearchResults.perform();
    }),
    updatedSearchString: Ember.observer('searchUsers', function () {
      return this.updateSearchTask.perform();
    }),
    updateSearchTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(500); //make sure they are done typing before searching

      var newSearchResults = yield this.getSearchResults.perform();
      this.set('searchResults', newSearchResults);
      Ember.run.next(() => {
        (0, _jquery.default)('.permissions__add-group-user-search input').focus();
      });
    }).restartable(),
    getSearchResults: (0, _emberConcurrency.task)(function* () {
      var searchValue = this.searchUsers;
      var fullList = yield this.findUsers.perform(); //users not in hierarc

      if (searchValue) {
        searchValue = searchValue.toLowerCase();
        var newSearchResults = yield _lodash.default.filter(fullList, function (employeeDir) {
          var user = employeeDir.get('user');
          return user.get('fname').toLowerCase().includes(searchValue) || user.get('lname').toLowerCase().includes(searchValue) || user.get('flname').toLowerCase().includes(searchValue) || user.get('lfname').toLowerCase().includes(searchValue);
        });
        return newSearchResults;
      } else {
        return fullList;
      }
    }).restartable().maxConcurrency(1),
    addUsersTask: (0, _emberConcurrency.task)(function* (buttonPromise) {
      try {
        this.set('saveComplete', false);
        var newUsers = this.addArray;
        var store = this.store;

        for (var x = 0; x < newUsers.length; x++) {
          var user = yield newUsers[x].get('user');
          var modalParentUser = this.modalParentUser;
          var userHierarchy = yield store.createRecord('userHierarchy', {
            user: user,
            userHierarchyTree: this.userHierarchyTree,
            parent: modalParentUser
          });
          var siblings = yield modalParentUser.get('children').toArray();
          siblings.push(userHierarchy);
          modalParentUser.set('children', siblings);
          yield modalParentUser.save(); //usersToSave.push(userHierarchy);
        } //notify parent list that it has new users

        /*   this.set('newGroupUsers', newUsers); */


        buttonPromise.then(() => {
          this.set('addUsersFormStarted', false);
          this.set('saveComplete', true);
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
          this.changeRouteTask.perform();
        });
      } catch (err) {
        this.set('addUsersFormStarted', false);
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
        (false && Ember.warn('Org Chart add users to user error' + err, {
          id: 'multiStepForm.forms.administration.permissions.addUsers.addUsersTask'
        }));
        throw err;
      }
    }),
    changeRouteTask: (0, _emberConcurrency.task)(function* () {
      var userHierarchyTree = yield this.get('orgTree.userHierarchyTree');
      var modalParentUser = this.modalParentUser;

      if (modalParentUser) {
        var userId = JSON.parse(this.get('modalParentUser.id'));
        this.router.transitionTo('/administration/org-chart/' + userHierarchyTree.get('id') + '/user/' + userId.user_id);
      } else {
        this.router.transitionTo('/administration/org-chart/' + userHierarchyTree.get('id'));
      }
    }),
    actions: {
      addUserToArray(employeeDir) {
        var addArray = this.addArray;
        var user = employeeDir.get('user');

        if (!_lodash.default.find(addArray, {
          id: user.id
        })) {
          employeeDir.set('inAddArray', true);
          addArray.push(employeeDir);
          this.set('addArray', addArray);
          this.notifyPropertyChange('addArray');
          var addIcon = document.querySelector('.add-icon-user-' + user.get('id'));
          addIcon.className += ' fill-blue';
        }
      },

      removeUserFromArray(employeeDir) {
        employeeDir.set('inAddArray', false);
        var user = employeeDir.get('user');
        var addArray = this.addArray;
        var resetAddArray = addArray.filter(arrayUser => {
          return arrayUser.get('user.id') != user.get('id');
        });
        this.set('addArray', resetAddArray);
        var addIcon = document.querySelector('.add-icon-user-' + user.get('id'));
        addIcon.classList.remove('fill-blue');
      }

    }
  });

  _exports.default = _default;
});