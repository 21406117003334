define("securitytrax-ember-ui/my-inventory/equipment-inventory/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x3iTn1h8",
    "block": "{\"symbols\":[],\"statements\":[[8,\"html/st-card\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"html/st-card/divider\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[14,0,\"st-card__divider-cell st-card__divider-title-cell\"],[12],[2,\"\\n            \"],[10,\"h1\"],[14,0,\"st-card__title\"],[12],[1,[30,[36,0],[\"pages.users.my_inventory.equipment_inventory.title\"],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"inventory/equipment-inventory\",[],[[\"@user\"],[[34,1,[\"user\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"model\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/my-inventory/equipment-inventory/template.hbs"
    }
  });

  _exports.default = _default;
});