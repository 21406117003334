define("securitytrax-ember-ui/components/multi-step-form/forms/create-calendar-event/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S1q2iUoQ",
    "block": "{\"symbols\":[\"@multiStepForm\",\"@users\",\"@customer\",\"@usersSchedulerOnly\",\"@userType\",\"@formMode\"],\"statements\":[[8,\"calendar-glimmer/calendar-scheduling\",[],[[\"@multiStepForm\",\"@users\",\"@customer\",\"@usersSchedulerOnly\",\"@userType\",\"@afterScheduleAppointment\",\"@popoverContainer\",\"@formMode\",\"@scrollContext\"],[[32,1],[32,2],[32,3],[32,4],[32,5],[30,[36,0],[[32,0],\"afterScheduleAppointment\"],null],\".md-dialog-content\",[32,6],\".md-dialog-container .no-horizontal-scroll .st-modal__body-content\"]],null]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/create-calendar-event/template.hbs"
    }
  });

  _exports.default = _default;
});