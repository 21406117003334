define("securitytrax-ember-ui/components/pages/home/home-tasks/component", ["exports", "securitytrax-ember-ui/mixins/customer-task-mixin", "ember-concurrency", "moment", "securitytrax-ember-ui/utils/obj-helpers", "securitytrax-ember-ui/mixins/table-column-sort"], function (_exports, _customerTaskMixin, _emberConcurrency, _moment, _objHelpers, _tableColumnSort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_customerTaskMixin.default, _tableColumnSort.default, {
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    stAjax: Ember.inject.service(),
    homeDashboard: Ember.inject.service('events/home-dashboard'),
    tagName: '',
    sortedCustomerTasks: undefined,
    addCompleted: true,
    completedText: undefined,
    tasksListCount: 0,
    limit: 50,
    page: 1,

    init() {
      this._super(...arguments);

      this.set('completedText', this.intl.t('pages.home.recently_completed'));
      this.homeDashboard.on('refresh-tasks-due-today', this, '_eventCallback');
      (0, _objHelpers.initializeState)(this, {
        stSortableColumnOptions: [{
          name: this.intl.t('common.columns.due_date') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'due_date',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.due_date') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-due_date',
          chevronDirection: 'down'
        }]
      });
    },

    willDestroy() {
      this.homeDashboard.off('refresh-tasks-due-today', this, '_eventCallback');
    },

    _eventCallback() {
      Ember.notifyPropertyChange(this, 'myTasksData');
    },

    myTasksData: Ember.computed('stSortString', function () {
      return this.myTasksDataTask.perform();
    }),
    myTasksDataTask: (0, _emberConcurrency.task)(function* () {
      var closedAtFilter = '(closed_at >= \'' + (0, _moment.default)().subtract(7, 'days').format('YYYY-MM-DD') + '\')';
      var user = this.get('model.user');
      var customerTasks = yield this.store.query('customerTask', {
        filter: '(assigned_user = ' + user.get('id') + ' and is_closed = false) or (assigned_user = ' + user.get('id') + ' and ' + closedAtFilter + ')',
        sort: this.stSortString ? this.stSortString + ',-priority' : 'priority,due_date',
        include: 'customer,tags,assigned_user,created_by_user',
        limit: this.limit,
        page: this.page
      });

      if (this.page == 1) {
        this.getCount.perform();
      }

      this.set('sortedCustomerTasks', customerTasks);
      return yield this.groupedTasksTask.perform();
    }),
    getCount: (0, _emberConcurrency.task)(function* () {
      try {
        var closedAtFilter = '(closed_at >= \'' + (0, _moment.default)().subtract(7, 'days').format('YYYY-MM-DD') + '\')';
        var user = this.get('model.user');
        var returnData = yield this.stAjax.request(this.stAjax.getUrl('/aggregate/customer_tasks?filter=(assigned_user = ' + user.get('id') + ' and is_closed = false) or (assigned_user = ' + user.get('id') + ' and ' + closedAtFilter + ')'), {
          method: 'GET'
        });
        this.set('tasksListCount', returnData.data[0].attributes.calculated);
      } catch (err) {//Do nothing
      }
    }),
    actions: {
      transitionToSubroute(path) {
        this.router.transitionTo('home.tasks.edit-task', path);
      },

      updateMyTasks() {
        this.notifyPropertyChange('myTasksData');
      },

      sortList(column, ascending) {
        this.sortPaginatedList(column, ascending);
      },

      mobileSortList(column) {
        this.sortMobilePaginatedList(column.value);
      }

    }
  });

  _exports.default = _default;
});