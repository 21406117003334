define("securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/adc/enable-disable-lockout/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/adc/adc-base", "ember-concurrency"], function (_exports, _adcBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adcBase.default.extend({
    ispService: Ember.inject.service(),
    reason: undefined,

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('enableDisableLockoutStep', 'buttons', [{
        text: this.lockoutEnabled ? this.intl.t('pages.customers.system.interactiveServicesProvider.enableDisableLockout.disableLockout.button') : this.intl.t('pages.customers.system.interactiveServicesProvider.enableDisableLockout.enableLockout.button'),
        click: buttonPromise => {
          return this.toggleLockoutEnabledTask.perform(multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary adc-swap-modem-button'
      }]);
      multiStepForm.registerStepData('enableDisableLockoutStep', 'title', this.enableDisableLockoutTitle);
    },

    toggleLockoutEnabledTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      try {
        yield this.ispService.get('invokeIspActionTask').perform({
          attributes: {
            reason: this.reason
          },
          action: this.lockoutEnabled ? 'alarm_dot_com__disableInteractiveLockout' : 'alarm_dot_com__enableInteractiveLockout',
          model: this.customer
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'enableDisableLockoutModal'
        });
        throw new Error(err);
      }

      yield this.setLockoutEnabledTask.perform();
      buttonPromise.then(() => {
        multiStepForm.formStartedSetVal(false);
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
      });
    })
  });

  _exports.default = _default;
});