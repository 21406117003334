define("securitytrax-ember-ui/components/multi-step-form/forms/customer-equipment/bulk-edit/item/component", ["exports", "ember-concurrency", "@glimmer/component", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _emberConcurrency, _component, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // import formatAppointmentMixin from 'securitytrax-ember-ui/mixins/format-appointment-mixin'; // TODO TRAX-8034: Make this a class and replace all other instances of it, then remove mixin
  var CustomerEquipmentBulkEditItem = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = (0, _emberConcurrency.task)(function* () {
    // Make this more preformant
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
    var calendarEvents = yield this.args.customer.get('calendarEvents');
    var formattedCalendarEvents = [];

    for (var i = 0; i < calendarEvents.get('length'); i++) {
      var event = calendarEvents.objectAt(i);
      var hashedData = yield (0, _emberConcurrency.hash)({
        user: event.get('calendarUser'),
        calendarEventType: event.get('calendarEventType')
      });

      if (hashedData.calendarEventType && hashedData.calendarEventType.get('calendarCategory') === 'tech_schedule') {
        var calendarEventName = yield hashedData.calendarEventType.get('name');
        var calendarEventDate = moment(event.get('startTime')).format('M/DD/YYYY h:mm A');
        var userName = hashedData.user ? ' | ' + hashedData.user.get('flname') : '';
        event.set('userDateTime', "".concat(calendarEventName, " ").concat(calendarEventDate, " ").concat(userName));
        formattedCalendarEvents.push(event);
      }
    }

    return formattedCalendarEvents;
  }), _dec8 = (0, _emberConcurrency.task)(function* () {
    var formattedAppointments = yield this.formattedAppointments;
    formattedAppointments.unshift(Ember.Object.create({
      id: null,
      userDateTime: 'None'
    }));
    return formattedAppointments;
  }), _dec9 = Ember._action, (_class = (_temp = class CustomerEquipmentBulkEditItem extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "screen", _descriptor2, this);

      _initializerDefineProperty(this, "currentField", _descriptor3, this);

      _initializerDefineProperty(this, "fieldProperties", _descriptor4, this);

      _initializerDefineProperty(this, "index", _descriptor5, this);

      _initializerDefineProperty(this, "model", _descriptor6, this);

      _initializerDefineProperty(this, "formattedAppointmentsTask", _descriptor7, this);

      _initializerDefineProperty(this, "formattedAppointmentsWithNoneOptionTask", _descriptor8, this);

      (0, _objHelpers.initializeState)(this, {
        currentField: this.args.field,
        model: {},
        fieldProperties: {
          0: {
            type: '',
            options: [],
            valueProperty: '',
            labelProperty: ''
          },
          soldBy: {
            type: 'selectList',
            valueProperty: 'id',
            labelProperty: 'name',
            options: 'args.soldByOptions'
          },
          equipmentStatus: {
            type: 'selectList',
            valueProperty: 'id',
            labelProperty: 'name',
            options: 'args.equipmentStatus'
          },
          itemCharge: {
            type: 'maskedInput',
            mask: 'currency'
          },
          equipmentWireType: {
            type: 'selectList',
            valueProperty: 'id',
            labelProperty: 'name',
            options: 'args.equipmentWireTypes'
          },
          isPaidFor: {
            type: 'selectList',
            options: 'args.paidForOptions',
            valueProperty: 'value',
            labelProperty: 'name'
          },
          installCalendarEvent: {
            type: 'selectList',
            valueProperty: 'id',
            labelProperty: 'userDateTime',
            options: 'formattedAppointmentsWithNoneOption'
          },
          installTech: {
            type: 'selectList',
            valueProperty: 'id',
            labelProperty: 'name',
            options: 'technicianListWithNoneOption'
          },
          uninstallCalendarEvent: {
            type: 'selectList',
            valueProperty: 'id',
            labelProperty: 'userDateTime',
            options: 'formattedAppointmentsWithNoneOption'
          },
          removeTech: {
            type: 'selectList',
            valueProperty: 'id',
            labelProperty: 'name',
            options: 'technicianListWithNoneOption'
          }
        }
      });
    }

    get formattedAppointments() {
      return this.formattedAppointmentsTask.perform();
    }

    // Need to update disabled property in app/components/multi-step-form/forms/customer-equipment/bulk-edit/component.js if you add or remove from this list.
    get editableFields() {
      var base = 'pages.customers.equipment';
      var fieldsMap = this.args.fields.map(field => field.name);
      return [{
        value: 'soldBy',
        label: this.intl.t("".concat(base, ".salesman.label")),
        disabled: fieldsMap.includes('soldBy')
      }, {
        value: 'equipmentStatus',
        label: this.intl.t("".concat(base, ".equipmentStatus.label")),
        disabled: fieldsMap.includes('equipmentStatus')
      }, {
        value: 'itemCharge',
        label: this.intl.t("".concat(base, ".itemCharge.label")),
        disabled: fieldsMap.includes('itemCharge')
      }, {
        value: 'isPaidFor',
        label: this.intl.t("".concat(base, ".isPaidFor.label")),
        disabled: fieldsMap.includes('isPaidFor')
      }, {
        value: 'equipmentWireType',
        label: this.intl.t("".concat(base, ".equipmentWireType.label")),
        disabled: fieldsMap.includes('equipmentWireType')
      }, {
        value: 'installCalendarEvent',
        label: this.intl.t("".concat(base, ".installCalendarEvent.label")),
        disabled: fieldsMap.includes('installCalendarEvent')
      }, {
        value: 'installTech',
        label: this.intl.t("".concat(base, ".installTech.label")),
        disabled: fieldsMap.includes('installTech')
      }, {
        value: 'uninstallCalendarEvent',
        label: this.intl.t("".concat(base, ".uninstallCalendarEvent.label")),
        disabled: fieldsMap.includes('uninstallCalendarEvent')
      }, {
        value: 'removeTech',
        label: this.intl.t("".concat(base, ".removeTech.label")),
        disabled: fieldsMap.includes('removeTech')
      }];
    }

    get formattedAppointmentsWithNoneOption() {
      return this.formattedAppointmentsWithNoneOptionTask.perform();
    }

    get technicianListWithNoneOption() {
      return this.args.technicianList;
    }

    changeField(field) {
      if (field && typeof field === 'object') {
        field = field.value;
      }

      var currentField = {
        name: field,
        type: field ? this.fieldProperties[field].type : null,
        options: [],
        valueProperty: '',
        labelProperty: '',
        value: field === 'equipmentStatus' || field === 'soldBy' || field === 'itemCharge' ? null : undefined,
        required: false
      };

      if (field === 'soldBy') {
        currentField['required'] = true;
        currentField['validationKey'] = 'customerEquipment-user';
        currentField['errors'] = this.args.multiStepForm.validationErrors;
      }

      if (currentField.type === 'selectList') {
        currentField.options = Ember.get(this, this.fieldProperties[field].options);
        currentField.valueProperty = this.fieldProperties[field].valueProperty;
        currentField.labelProperty = this.fieldProperties[field].labelProperty;
      }

      this.currentField = currentField;
      this.args.setField(currentField, this.args.index);
      this.args.updateIsWarning(this.args.field, this.currentField.name === 'itemCharge');

      if (field === null) {
        this.args.removeIfCleared();
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "screen", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentField", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "fieldProperties", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "index", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "model", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "formattedAppointmentsTask", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "formattedAppointmentsWithNoneOptionTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "changeField", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "changeField"), _class.prototype)), _class));
  _exports.default = CustomerEquipmentBulkEditItem;
});