define("securitytrax-ember-ui/validations/customer-equipment", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation", "lodash"], function (_exports, _validate, _baseValidation, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var noValidate = _lodash.default.get(data, 'extraValidationData.noValidate', false);

      var validateObject = {
        equipment: {
          presence: () => {
            if (data.extraValidationData.skipEquipment || noValidate && noValidate.includes('equipment')) {
              return false;
            }

            return this.customMessageOrDefault(data, 'equipment');
          }
        },
        quantity: () => {
          if (data.extraValidationData.skipQuantity) {
            return {};
          }

          return {
            presence: {
              allowEmpty: false
            },
            numericality: {
              greaterThan: 0
            }
          };
        },
        itemCharge: () => {
          if (data.extraValidationData.skipQuantity) {
            return {};
          }

          return {
            presence: {
              allowEmpty: false
            },
            numericality: {
              greaterThanOrEqualTo: 0
            }
          };
        },
        user: {
          presence: () => {
            if (noValidate && noValidate.includes('user')) {
              return false;
            }

            return this.customMessageOrDefault(data, 'user');
          }
        },
        zoneNumber: () => {
          if (noValidate && noValidate.includes('zoneNumber') && this.zoneNumber === '') {
            return false;
          }

          var retVal = {
            numericality: {
              greaterThanOrEqualTo: 0
            }
          };

          if (noValidate && !noValidate.includes('zoneNumber')) {
            retVal['presence'] = {
              allowEmpty: false
            };
          }

          return retVal;
        },
        zone: {
          presence: () => {
            if (noValidate && noValidate.includes('zone')) {
              return false;
            }

            return this.customMessageOrDefault(data, 'zone');
          }
        },
        equipmentStatus: {
          presence: () => {
            if (noValidate && noValidate.includes('equipmentStatus')) {
              return false;
            }

            return this.customMessageOrDefault(data, 'equipmentStatus');
          }
        },
        workOrderAction: {
          presence: () => {
            if (noValidate && noValidate.includes('workOrderAction')) {
              return false;
            }

            return this.customMessageOrDefault(data, 'workOrderAction');
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      var modelName = data.extraValidationData && data.extraValidationData.hasOwnProperty('overrideValidationKey') ? data.extraValidationData.overrideValidationKey : 'customerEquipment';
      return this.outputFormatting(validationData, modelName);
    }

  });

  _exports.default = _default;
});