define("securitytrax-ember-ui/components/multi-step-form/forms/administration/system/upload-tool/update-file/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9qdMfXEI",
    "block": "{\"symbols\":[\"&attrs\",\"@multiStepForm\",\"@bulkActionId\"],\"statements\":[[11,\"div\"],[17,1],[12],[2,\"\\n    \"],[8,\"multi-step-form/progress-bar\",[],[[\"@config\",\"@modalButtons\"],[[34,0],[32,2,[\"currentStepData\",\"buttons\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[14,0,\"grid-x grid-padding-x grid-padding-y\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"cell small-12 upload-prompt\"],[12],[2,\"\\n                \"],[1,[30,[36,2],[\"pages.administration.system.uploadTool.steps.updateFile.prompt\"],[[\"actionType\"],[[30,[36,2],[[30,[36,1],[\"pages.administration.system.uploadTool.steps.getStarted.actions.\",[32,3]],null]],null]]]]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[1,[30,[36,4],null,[[\"url\",\"dictDefaultMessage\",\"class\",\"acceptedFiles\",\"maxFiles\",\"uploadAction\",\"namespace\"],[\"/\",[30,[36,2],[\"pages.administration.system.uploadTool.steps.updateFile.dropboxDropZone\"],null],\"file-upload\",\".csv,.xls\",1,[30,[36,3],[[32,0],\"uploadFile\"],null],\"modal\"]]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"modals/st-paper\",[],[[\"@onClose\",\"@classNames\",\"@dialogOpen\"],[[30,[36,3],[[32,0],\"closeUploadTool\"],null],\"st-modal--small\",[34,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"upload-begun-message\"],[12],[2,\"\\n        \"],[1,[30,[36,2],[\"pages.administration.system.uploadTool.steps.updateFile.updateBegunMessage\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"progressBarConfig\",\"concat\",\"t\",\"action\",\"html/file-upload\",\"openModal\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/system/upload-tool/update-file/template.hbs"
    }
  });

  _exports.default = _default;
});