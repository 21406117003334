define("securitytrax-ember-ui/components/multi-step-form/forms/payables/bulk-edit/component", ["exports", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "ember-concurrency", "securitytrax-ember-ui/utils/strings"], function (_exports, _component, _switchableFormStep, _emberConcurrency, _strings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var BulkEditPayables = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service('events/bulk-edit'), _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = (0, _emberConcurrency.task)(function* () {
    var hashedData = yield (0, _emberConcurrency.hash)({
      payrollPaymentStatuses: this.store.findAll('payrollPaymentStatus'),
      payableTags: this.tagsService.getTagsByObjectType.perform('payroll_to_customer')
    });
    Ember.setProperties(this, hashedData);
    this.args.multiStepForm.registerStepData('step1', 'buttons', [{
      text: this.intl.t('common.actions.save'),
      click: () => {
        this.bulkEdit.trigger('update-customer-payable-bulk-edit-list');
      },
      classNamesString: 'st-btn st-btn-primary save-bulk-edit'
    }]);
  }), _dec13 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var allSaves = [];
    var allReloads = [];
    var successNotifications = [];
    var errorNotifications = [];
    this.args.bulkEditList.forEach(payable => {
      allSaves.push(this.saveFieldsTask.perform(payable, successNotifications, errorNotifications));
    });
    yield (0, _emberConcurrency.all)(allSaves);
    this.args.bulkEditList.forEach(payable => {
      allReloads.push(payable.get('tags').reload());
    });
    yield (0, _emberConcurrency.all)(allReloads);
    buttonPromise.then(() => {
      this.args.afterBulkCreate();

      if (successNotifications.length > 0) {
        this.stNotificationManager.addSuccess(this.intl.t('pages.accounting.payable_entries.bulk_edit_success', {
          'count': successNotifications.length
        }));
      }

      if (errorNotifications.length > 0) {
        this.stNotificationManager.addError(this.intl.t('pages.accounting.payable_entries.bulk_edit_failure', {
          'count': errorNotifications.length
        }));
      }

      this.args.multiStepForm.formStartedSetVal(false);
    });
  }), _dec14 = (0, _emberConcurrency.task)(function* (payable, successNotifications, errorNotifications) {
    var allRelationshipSaves = [];
    this.fieldsToEdit.forEach(fieldToEdit => {
      if (fieldToEdit.key !== 'applyTags' && fieldToEdit.key !== 'removeTags') {
        payable.set(fieldToEdit.key, fieldToEdit.newValue);
      } else {
        var method = fieldToEdit.key === 'applyTags' ? 'POST' : 'DELETE';
        allRelationshipSaves.push(payable.updateRelationship(method, 'tags', fieldToEdit.newValue));
      }
    });

    try {
      yield (0, _emberConcurrency.hash)({
        relationshipSaves: (0, _emberConcurrency.all)(allRelationshipSaves),
        payableSave: payable.save()
      });
      successNotifications.push('Payable Saved');
    } catch (err) {
      payable.rollbackAttributes();
      errorNotifications.push('Payable Failed To Saved');
    }
  }), _dec15 = Ember._action, (_class = (_temp = class BulkEditPayables extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor3, this);

      _initializerDefineProperty(this, "screen", _descriptor4, this);

      _initializerDefineProperty(this, "tagsService", _descriptor5, this);

      _initializerDefineProperty(this, "grants", _descriptor6, this);

      _initializerDefineProperty(this, "bulkEdit", _descriptor7, this);

      _initializerDefineProperty(this, "reviewModalOpen", _descriptor8, this);

      _initializerDefineProperty(this, "payrollPaymentStatuses", _descriptor9, this);

      _initializerDefineProperty(this, "payableTags", _descriptor10, this);

      _initializerDefineProperty(this, "fieldsToEdit", _descriptor11, this);

      _initializerDefineProperty(this, "initData", _descriptor12, this);

      _initializerDefineProperty(this, "bulkEditSave", _descriptor13, this);

      _initializerDefineProperty(this, "saveFieldsTask", _descriptor14, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.initData.perform();
    }

    get editableFields() {
      var fields = [{
        key: 'payrollPaymentStatus',
        name: this.intl.t("pages.accounting.payable_entries.payable_status"),
        secondaryLabel: this.intl.t('pages.customers.equipment.replaceWith'),
        newValue: undefined,
        component: 'multi-step-form/switchable-inputs/select-input',
        multiple: false,
        options: this.payrollPaymentStatuses
      }, {
        key: 'expectedPayDate',
        name: this.intl.t("pages.accounting.payable_entries.expected_pay_date"),
        secondaryLabel: this.intl.t('pages.customers.equipment.replaceWith'),
        newValue: undefined,
        component: 'multi-step-form/switchable-inputs/date-input'
      }];

      if (this.grants.getIndividualGrant('customer_payables.apply_tag')) {
        fields.push({
          key: 'applyTags',
          name: this.intl.t("pages.accounting.payable_entries.apply_payable_tags"),
          secondaryLabel: this.intl.t('common.actions.apply'),
          newValue: undefined,
          component: 'multi-step-form/switchable-inputs/select-input',
          multiple: true,
          options: this.payableTags
        });
      }

      if (this.grants.getIndividualGrant('customer_payables.remove_tag')) {
        fields.push({
          key: 'removeTags',
          name: this.intl.t("pages.accounting.payable_entries.remove_payable_tags"),
          secondaryLabel: (0, _strings.ucwords)(this.intl.t('common.actions.remove')),
          newValue: undefined,
          component: 'multi-step-form/switchable-inputs/select-input',
          multiple: true,
          options: this.payableTags
        });
      }

      return fields;
    }

    onSave(fields) {
      this.fieldsToEdit = fields;
      this.reviewModalOpen = true;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "screen", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "tagsService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "bulkEdit", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "reviewModalOpen", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "payrollPaymentStatuses", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "payableTags", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "fieldsToEdit", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "initData", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "bulkEditSave", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "saveFieldsTask", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onSave", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "onSave"), _class.prototype)), _class));
  _exports.default = BulkEditPayables;
});