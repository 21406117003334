define("securitytrax-ember-ui/components/customer/integrations/electronic-documents/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/helpers/feature-is-enabled", "lodash", "securitytrax-ember-ui/config/environment"], function (_exports, _emberConcurrency, _featureIsEnabled, _lodash, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Controller
   * @extends Ember.Controller
   * @memberof customers.files
   *
   * TODO: this currently only supports creating multiple DOCUSIGN envelopes by using docusign envelope models
   * its possible these models will be used for other electronic document provider types
   */
  var _default = Ember.Component.extend({
    stAjax: Ember.inject.service(),
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    classNames: 'electronic-documents',
    fileExtensionRegex: /\.[^/.]+$/,
    hasEvent: false,
    edocEnvelopesFormStarted: false,
    editEnvelopeFormStarted: false,
    docusignEnvelopes: undefined,
    electronicEnvelopeModel: null,
    docusignReady: false,
    eDocProvider: null,
    template: null,
    classificationValue: null,
    docusignError: null,
    loadedProviders: false,
    initTask: (0, _emberConcurrency.task)(function* () {
      //automatically open the edit envelope modal after embedded signing returns
      var searchParams = new URLSearchParams(window.location.search);

      if (searchParams.get('envelopeId')) {
        var allCustomerEnvelopes = yield this.customerElectronicDocumentEnvelopes;
        var electronicEnvelope = allCustomerEnvelopes.findBy('electronicEnvelopeType.envelopeId', searchParams.get('envelopeId'));
        this.set('electronicEnvelopeModel', electronicEnvelope ? electronicEnvelope.electronicEnvelopeType : null);
      }

      if (searchParams.get('event') && this.electronicEnvelopeModel) {
        yield this.electronicEnvelopeModel.invokeAction('process_signing_event', {
          verb: 'POST',
          additionalParams: [searchParams.get('event')]
        });
        Ember.set(this, 'curEnvelopeType', 'DocuSign');
        yield this.electronicEnvelopeModel.reload();
        window.history.replaceState({}, document.title, window.location.pathname);
        this.set('editEnvelopeFormStarted', true);
        this.set('hasEvent', true);
        Ember.notifyPropertyChange(this, 'customerElectronicDocumentEnvelopes');
      }
    }).on('init'),
    buildProviderMapTask: (0, _emberConcurrency.task)(function* (provider) {
      var providerType = yield provider.get('electronicDocumentProviderType');
      this.providerMap[provider.get('id')] = {
        provider,
        providerType
      };
    }),
    editEnvelopeFormSteps: Ember.computed('electronicEnvelopeModel', function () {
      var provider = this.electronicEnvelopeModel ? this.get('electronicEnvelopeModel.electronicDocumentProvider') : null;
      var providerName = provider ? provider.get('name') : '';
      var componentPath = '';

      if (this.curEnvelopeType === 'DocuSign') {
        componentPath = 'docusign/edit-envelope';
      } else if (this.curEnvelopeType === 'PandaDoc') {
        componentPath = 'pandadoc/edit-envelope';
        Ember.set(this, 'pandadocDocumentModel', this.electronicEnvelopeModel);
      }

      return Ember.Object.create({
        editEnvelopeForm: Ember.Object.create({
          stepNameKey: 'editEnvelopeForm',
          title: providerName + this.intl.t('pages.administration.integrations.electronic_document_providers.docusign.edit_envelope') + this.get('customer.flname'),
          stepComponentPath: 'multi-step-form/forms/administration/integrations/electronic-document-providers/integrations/' + componentPath
        })
      });
    }),
    // TODO: make dynamic based on type?
    formOriginalModels: Ember.computed('electronicEnvelopeModel', function () {
      if (this.curEnvelopeType === 'DocuSign') {
        return Ember.Object.create({
          docusignEnvelope: {
            model: this.electronicEnvelopeModel,
            copyRelationMap: {
              docusignTemplates: {
                readOnly: true
              },
              docusignCustomFields: {},
              docusignDocuments: {},
              docusignRecipients: {}
            }
          }
        });
      } else if (this.curEnvelopeType === 'PandaDoc') {
        return Ember.Object.create({
          pandadocDocument: {
            model: this.electronicEnvelopeModel,
            copyRelationMap: {}
          }
        });
      }

      return null;
    }),
    adtEnvClassification: Ember.computed('docusignAccount', function () {
      return this.adtEnvClassificationTask.perform();
    }),
    adtEnvClassificationTask: (0, _emberConcurrency.task)(function* () {
      var docusignAccount = yield this.docusignAccount;
      var docusignCustomFields = docusignAccount ? yield docusignAccount.docusignCustomFields : null;
      var customFieldClassification = docusignCustomFields ? docusignCustomFields.filter(field => {
        return field.get('name') === 'ADT_ENV_CLASSIFICATION';
      }) : null;
      return customFieldClassification ? customFieldClassification.get('firstObject') : null;
    }),
    classificationOptions: Ember.computed('docusignAccount', function () {
      return this.classificationOptionsTask.perform();
    }),
    classificationOptionsTask: (0, _emberConcurrency.task)(function* () {
      var docusignAccount = yield this.docusignAccount;
      var docusignCustomFields = docusignAccount ? yield docusignAccount.docusignCustomFields : null;
      var listItems = docusignCustomFields ? _lodash.default.get(docusignCustomFields, 'firstObject.listItems', []) : [];
      var classificationOptions = [];

      if (listItems.length > 0) {
        listItems.forEach(item => {
          classificationOptions.addObject({
            value: item,
            label: item
          });
        });
      }

      return classificationOptions;
    }),
    customerElectronicDocumentEnvelopes: Ember.computed('customer.electronicDocumentEnvelopes.[]', function () {
      return this.customerEdocEnvelopesTask.perform();
    }),
    customerEdocEnvelopesTask: (0, _emberConcurrency.task)(function* () {
      var envelopes = yield this.customer.get('electronicDocumentEnvelopes');
      var envelopesArray = envelopes.toArray();
      var resolvedPromisesArray = [];

      for (var j = 0; j < envelopesArray.length; j++) {
        resolvedPromisesArray.push(this.getEdocEnvelopeDataTask.perform(envelopesArray[j]));
      }

      var envelopesList = yield (0, _emberConcurrency.all)(resolvedPromisesArray);
      return envelopesList.filter(env => !!env);
    }),
    getEdocEnvelopeDataTask: (0, _emberConcurrency.task)(function* (envelope) {
      try {
        yield (0, _emberConcurrency.waitForProperty)(this, 'loadedProviders', true);
        var provider;

        if (_environment.default.environment != 'test') {
          // Real land
          provider = yield envelope.electronicDocumentProvider;
        } else {
          provider = yield this.store.findRecord('electronicDocumentProvider', 1);
        }

        var providerType = provider ? this.providerMap[provider.get('id')].providerType : null;
        var providerTypeName = providerType ? providerType.get('name') : null;

        if (providerTypeName === 'DocuSign') {
          if (!this.docusignEnabled) {
            return null;
          }

          var docusignEnvelope = yield envelope.get('docusignEnvelope');
          var customFields = docusignEnvelope ? yield docusignEnvelope.get('docusignCustomFields') : null;
          var customFieldClassification = customFields && customFields.length > 0 ? customFields.filter(field => {
            return field.get('name') === 'ADT_ENV_CLASSIFICATION';
          }) : null;
          var adtEnvClassification = customFieldClassification ? customFieldClassification.get('firstObject') : null;
          return {
            electronicEnvelopeType: docusignEnvelope,
            createdDateTime: docusignEnvelope ? docusignEnvelope.createdDateTime : null,
            provider: provider,
            templateName: envelope.name,
            adtEnvClassification: adtEnvClassification,
            status: docusignEnvelope ? docusignEnvelope.status : null,
            errorMessage: null,
            type: 'DocuSign'
          };
        } else if (providerTypeName === 'PandaDoc') {
          if (!this.pandaDocEnabled) {
            return null;
          }

          var pandadocDocument = yield envelope.get('pandadocDocument');
          return {
            electronicEnvelopeType: pandadocDocument,
            createdDateTime: envelope.createdAt,
            provider: provider,
            templateName: envelope.name,
            adtEnvClassification: null,
            status: this.intl.t('pages.administration.integrations.electronic_document_providers.pandadoc.statuses.' + pandadocDocument.status),
            errorMessage: null,
            type: 'PandaDoc'
          };
        } else {
          return {
            electronicEnvelopeType: null,
            createdDateTime: envelope.createdAt,
            provider: {
              name: this.intl.t('pages.administration.integrations.electronic_document_providers.provider_missing')
            },
            templateName: envelope.name,
            adtEnvClassification: null,
            status: this.intl.t('pages.administration.integrations.electronic_document_providers.docusign.envelope.error'),
            errorMessage: this.intl.t('pages.administration.integrations.electronic_document_providers.provider_missing')
          };
        }
      } catch (error) {
        (false && Ember.warn('failed to get the electronic Envelope Data' + error, {
          id: 'customer.integrations.electronicDocuments.getEdocEnvelopeDataTask'
        }));
        return {
          electronicEnvelopeType: null,
          createdDateTime: envelope.createdAt,
          provider: null,
          templateName: envelope.name,
          adtEnvClassification: null,
          status: this.intl.t('pages.administration.integrations.electronic_document_providers.docusign.envelope.error'),
          errorMessage: error.errors[0].detail
        };
      }
    }),
    docusignAccount: Ember.computed('eDocProvider', 'electronicEnvelopeModel', function () {
      return this.docusignAccountTask.perform();
    }),
    docusignAccountTask: (0, _emberConcurrency.task)(function* () {
      try {
        var eDocProvider = this.eDocProvider ? yield this.eDocProvider : this.electronicEnvelopeModel ? yield this.get('electronicEnvelopeModel.electronicDocumentProvider') : null;
        var providerType = eDocProvider ? yield eDocProvider.get('electronicDocumentProviderType') : '';

        if (eDocProvider && providerType.name == 'DocuSign') {
          var compositeId = {
            'electronic_document_provider_id': eDocProvider.id
          };
          var docusignAccount = yield this.store.findRecord('docusignAccount', JSON.stringify(compositeId)); // PERF TODO: BE doesn't seem to allow docusign templates to be included here, gives 405

          return docusignAccount;
        }
      } catch (error) {
        (false && Ember.warn('failed to get the DocusignAccount: ' + error, {
          id: 'customer.integrations.electronicDocuments.docusignAccountTask'
        }));
      }
    }),
    eDocProviders: Ember.computed(function () {
      return this.eDocProvidersTask.perform();
    }),
    eDocProvidersTask: (0, _emberConcurrency.task)(function* () {
      try {
        this.providerMap = {};
        var providers = yield this.store.findAll('electronicDocumentProvider', {
          include: 'electronic_document_provider_type'
        });
        var providersPromiseArray = [];
        Ember.set(this, 'docusignEnabled', (0, _featureIsEnabled.featureIsEnabled)('docuSign'));
        Ember.set(this, 'pandaDocEnabled', (0, _featureIsEnabled.featureIsEnabled)('pandaDoc'));
        providers.toArray().forEach(prov => {
          providersPromiseArray.push(this.buildProviderMapTask.perform(prov));
        });
        yield (0, _emberConcurrency.all)(providersPromiseArray);
        var activeProviders = providers.toArray().filter(prov => {
          var providerType = this.providerMap[prov.get('id')].providerType;
          var providerTypeName = providerType.get('name');

          if (!prov.active || providerTypeName === 'DocuSign' && !this.docusignEnabled || providerTypeName === 'PandaDoc' && !this.pandaDocEnabled) {
            return false;
          }

          return true;
        });

        if (activeProviders.length === 1) {
          this.set('eDocProvider', activeProviders[0]);
        }

        Ember.set(this, 'loadedProviders', true);
        return activeProviders;
      } catch (error) {
        (false && Ember.warn('failed to get the electronic Document Providers' + error, {
          id: 'customer.integrations.electronicDocuments.eDocProvidersTask'
        }));
      }
    }),
    eDocProviderActive: Ember.computed(function () {
      return this.eDocProviderActiveTask.perform();
    }),
    eDocProviderActiveTask: (0, _emberConcurrency.task)(function* () {
      try {
        var providers = yield this.eDocProviders;
        return providers.toArray().some(prov => {
          return prov.active == true;
        });
      } catch (error) {
        (false && Ember.warn('something went wrong in eDocProviderActiveTask: ' + error, {
          id: 'customer.integrations.electronicDocuments.eDocProviderActiveTask'
        }));
      }
    }),
    templates: Ember.computed('docusignAccount', function () {
      return this.templatesTask.perform();
    }),
    templatesTask: (0, _emberConcurrency.task)(function* () {
      var eDocProvider = this.eDocProvider ? yield this.eDocProvider : this.electronicEnvelopeModel ? yield this.get('electronicEnvelopeModel.electronicDocumentProvider') : null;
      var providerType = eDocProvider ? yield eDocProvider.get('electronicDocumentProviderType') : '';

      try {
        if (eDocProvider && providerType.name === 'DocuSign') {
          var docusignAccount = yield this.docusignAccount;
          return docusignAccount ? yield docusignAccount.get('docusignTemplates') : null;
        } else if (eDocProvider && providerType.name === 'PandaDoc') {
          return yield this.store.query('pandadocTemplate', {
            electronic_document_provider_id: eDocProvider.get('id')
          });
        }
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
      }
    }),
    edocCreateEnvelopesButtons: Ember.computed(function () {
      return [{
        text: this.intl.t('common.actions.save'),
        click: buttonAnimationPromise => {
          return this.createEdocEnvelopeTask.perform(buttonAnimationPromise);
        },
        classNamesString: 'st-btn st-btn-primary docusign-envelope-create'
      }];
    }),
    createEdocEnvelopeTask: (0, _emberConcurrency.task)(function* (buttonAnimationPromise) {
      try {
        var store = this.store;
        var eDocProvider = this.eDocProvider ? yield this.eDocProvider : this.electronicEnvelopeModel ? yield this.get('electronicEnvelopeModel.electronicDocumentProvider') : null;
        var providerType = eDocProvider ? yield eDocProvider.get('electronicDocumentProviderType') : '';

        if (providerType.name === 'DocuSign') {
          var newEnvelope = yield store.createRecord('docusignEnvelope', {
            customer: this.customer,
            electronicDocumentProvider: this.eDocProvider
          });
          var docusignTemplates = yield newEnvelope.get('docusignTemplates');
          docusignTemplates.pushObject(this.template);
          var newEnvelopeSaved = yield newEnvelope.save();

          if (this.classificationValue) {
            var adtEnvClassification = yield this.adtEnvClassification;
            var newCustomFieldObject = {
              value: this.classificationValue,
              name: adtEnvClassification.get('name'),
              docusignEnvelope: newEnvelopeSaved
            };
            var newAdtEnvClassification = yield store.createRecord('docusignCustomField', newCustomFieldObject);

            try {
              yield newAdtEnvClassification.save(); //this.set('adtEnvClassification', newClassification);
            } catch (err) {
              if (err.code) {
                throw err;
              }
            }
          }

          Ember.set(this, 'curEnvelopeType', 'DocuSign');
          Ember.set(this, 'electronicEnvelopeModel', newEnvelopeSaved);
          yield this.customer.hasMany('electronicDocumentEnvelopes').reload();
        } else if (providerType.name === 'PandaDoc') {
          var templateData = yield this.store.findRecord('pandadocTemplate', this.template.get('id'));
          var templateRecipientsData = yield templateData.invokeAction('get_recipients_for_document', {
            verb: 'POST',
            data: {
              data: {
                attributes: {
                  customer_id: this.customer.get('id')
                }
              }
            }
          });
          var recipients = templateRecipientsData.responsePayload.data.recipients;
          var pandadocDocument = yield this.store.createRecord('pandadocDocument', {
            customer: this.customer,
            electronicDocumentProvider: this.eDocProvider,
            pandadocTemplate: this.template
          });
          Ember.set(pandadocDocument, 'name', this.template.name);
          Ember.set(pandadocDocument, 'template', {
            id: this.template.get('id'),
            name: this.template.get('name')
          });
          Ember.set(pandadocDocument, 'recipients', recipients);
          pandadocDocument.recipients.forEach(rec => {
            if (rec.name === undefined || rec.name === null) {
              Ember.set(rec, 'name', '');
            }

            if (rec.email === undefined || rec.email === null) {
              Ember.set(rec, 'email', '');
            }
          });
          Ember.set(this, 'curEnvelopeType', 'PandaDoc');
          Ember.set(this, 'electronicEnvelopeModel', pandadocDocument);
          Ember.set(this, 'pandadocDocumentModel', pandadocDocument);
        }

        buttonAnimationPromise.then(() => {
          Ember.set(this, 'edocEnvelopesFormStarted', false);
          Ember.set(this, 'editEnvelopeFormStarted', true);
          this.resetNewEnvelopeForm.perform();
        });
      } catch (response) {
        this.stNotificationManager.addErrorMessages(response, {
          notificationNamespace: 'modal'
        });
      }
    }),
    afterUnlinkTask: (0, _emberConcurrency.task)(function* () {
      try {
        if (window.location.search) {
          //reload page without ?event=signing_complete if they unlink after embedded signing.
          window.location = window.location.href.split('?')[0];
        } else {
          var recipientUnloads = [];
          this.electronicEnvelopeModel.docusignRecipients.forEach(recipient => {
            if (recipient) {
              recipientUnloads.push(recipient.unloadRecord());
            }
          });
          yield (0, _emberConcurrency.hash)({
            recipientUnloads: (0, _emberConcurrency.all)(recipientUnloads),
            electronicEnvelopModelUnload: this.electronicEnvelopeModel.unloadRecord(),
            electronicDocumentEnvelopesUnload: this.customer.electronicDocumentEnvelopes.reload()
          });
          this.set('editEnvelopeFormStarted', false);
        }
      } catch (error) {
        (false && Ember.warn('failed in afterUnlinkTask' + error, {
          id: 'customer.integrations.docusignEnvelopes.afterUnlinkTask'
        }));
      }
    }),
    resetNewEnvelopeForm: (0, _emberConcurrency.task)(function* () {
      var eDocProviders = yield this.eDocProviders;
      this.set('eDocProvider', eDocProviders.length === 1 ? eDocProviders.objectAt(0) : null);
      this.set('template', null);
      this.set('classificationValue', null);
    }),
    actions: {
      resetEnvelopeList() {
        this.customer.electronicDocumentEnvelopes.reload();
      },

      afterUnlink() {
        return this.afterUnlinkTask.perform();
      },

      showEnvelopeError(error) {
        this.stNotificationManager.addErrorMessages(error);
      },

      openCreateEdocModal() {
        Ember.set(this, 'docusignEnvelopesFormMode', 'create');
        Ember.set(this, 'edocEnvelopesFormStarted', true);
      }

    }
  });

  _exports.default = _default;
});