define("securitytrax-ember-ui/components/multi-step-form/switchable-inputs/st-multi-select/st-multi-selected/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class StMultiSelected extends _component.default {}

  _exports.default = StMultiSelected;
});