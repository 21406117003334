define("securitytrax-ember-ui/customers/accounting/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AhxeVZeR",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\\n\"],[1,[30,[36,1],[\"pages/accounting\"],[[\"model\"],[[35,2]]]]],[2,\"\\n\\n\"],[8,\"customer/user-defined-fields\",[],[[\"@customer\",\"@model\",\"@udfContext\"],[[34,2,[\"customer\"]],[34,2],\"customers_accounting\"]],null]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"model\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/customers/accounting/template.hbs"
    }
  });

  _exports.default = _default;
});