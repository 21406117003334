define("securitytrax-ember-ui/services/central-station-service", ["exports", "ember-concurrency", "lodash"], function (_exports, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    getData(options) {
      return this.invokeCentralStationActionTask.unlinked().perform(options);
    },

    postData(options) {
      var compiledOptions = _lodash.default.merge({
        rawResponse: true
      }, options);

      return this.invokeCentralStationActionTask.unlinked().perform(compiledOptions);
    },

    invokeCentralStationActionTask: (0, _emberConcurrency.task)(function* (options) {
      try {
        var compiledOptions = _lodash.default.merge({
          model: {},
          // required
          method: '',
          // required
          payload: {},
          responseKey: null,
          rawResponse: false,
          returnAsEmberObject: false
        }, options);

        var payloadObject = {};
        payloadObject[compiledOptions.method + 'Request'] = compiledOptions.payload;
        var actionData = {
          verb: 'POST',
          data: {
            data: {
              method: compiledOptions.method,
              payload: payloadObject
            }
          }
        };

        if (compiledOptions.responseKey !== null) {
          actionData.data.data['responseKey'] = compiledOptions.responseKey;
        }

        var responseData = compiledOptions.model !== {} ? yield compiledOptions.model.invokeAction('central_station_invoke_generic', actionData) : null;

        if (compiledOptions.rawResponse) {
          return responseData;
        }

        var parsedResponse = compiledOptions.responseKey ? responseData.responsePayload.data.payload[compiledOptions.method + 'Response'][compiledOptions.responseKey] : responseData.responsePayload.data.payload[compiledOptions.method + 'Response'];

        if (compiledOptions.returnAsEmberObject) {
          return Ember.Object.create(parsedResponse);
        } else {
          return parsedResponse;
        }
      } catch (error) {
        (false && Ember.warn('central station service error in invokeCentralStationActionTask' + error, {
          id: 'services.central-station-services.invokeCentralStationActionTask'
        }));
        throw error; //((error && error.errors) ? error.errors[0].detail : error);
      }
    })
  });

  _exports.default = _default;
});