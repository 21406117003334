define("securitytrax-ember-ui/components/html/dashboard-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P5sziQCN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"card dashboard-card \",[34,0]]]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"card-header grid-x align-justify align-middle\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cell shrink\"],[12],[2,\"\\n            \"],[10,\"h1\"],[14,0,\"card-title\"],[12],[2,\"\\n                \"],[1,[34,1]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cell shrink\"],[12],[2,\"\\n            \"],[11,\"span\"],[24,0,\"pointer\"],[4,[38,3],[[32,0],\"changeRoute\",[35,2]],null],[12],[2,\"\\n                \"],[1,[30,[36,4],[\"icn-chevron-right\"],[[\"class\"],[\"icon\"]]]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"grid-x\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cell\"],[12],[2,\"\\n            \"],[18,1,null],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"class\",\"cardTitle\",\"linkTo\",\"action\",\"svg-icon\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/dashboard-card/template.hbs"
    }
  });

  _exports.default = _default;
});