define("securitytrax-ember-ui/components/modals/sub-route-modals/manage-cms/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j8gzYKcE",
    "block": "{\"symbols\":[\"multiStepForm\"],\"statements\":[[6,[37,13],null,[[\"class\",\"formSteps\",\"currentStepNameKey\",\"changeCurrentStepNameKey\",\"formOriginalModels\",\"formStarted\",\"changeFormStarted\"],[\"row\",[35,12],[35,11],[30,[36,0],[[32,0],[30,[36,9],[[35,11]],null]],null],[35,10],[35,8],[30,[36,0],[[32,0],[30,[36,9],[[35,8]],null]],null]]],[[\"default\"],[{\"statements\":[[6,[37,7],null,[[\"modalButtons\",\"title\",\"hasProgressBar\",\"dialogOpen\",\"hideTopRightCloseButton\",\"fullscreen\",\"onClose\",\"multiStepForm\",\"modalNamespace\",\"classNames\"],[[32,1,[\"currentStepData\",\"buttons\"]],[32,1,[\"currentStepData\",\"title\"]],[30,[36,6],[[35,4],\"create\"],null],[32,1,[\"formStarted\"]],[32,1,[\"currentStepData\",\"hideTopRightCloseButton\"]],true,[30,[36,0],[[32,0],\"transitionBackToParentRoute\"],null],[32,1],\"cmsModal\",\"cms-modal\"]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,5],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],[[\"multiStepForm\",\"formMode\",\"customer\",\"monitoringCompany\",\"customerSystemInformation\",\"transitionBackToParent\",\"class\"],[[32,1],[35,4],[35,3],[35,2],[35,1],[30,[36,0],[[32,0],\"transitionBackToParentRoute\"],null],\"st-modal__body--progress-bar\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"customerSystemInformation\",\"monitoringCompany\",\"customer\",\"formMode\",\"component\",\"not-eq\",\"modals/st-paper\",\"formStarted\",\"mut\",\"formOriginalModel\",\"firstStep\",\"formSteps\",\"multi-step-form\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/modals/sub-route-modals/manage-cms/template.hbs"
    }
  });

  _exports.default = _default;
});