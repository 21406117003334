define("securitytrax-ember-ui/administration/inventory/dashboard/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BkPt9OsQ",
    "block": "{\"symbols\":[],\"statements\":[[8,\"html/st-await\",[],[[\"@booleanLoaded\"],[[34,0,[\"baseFindGrantsTask\",\"isIdle\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"pages/administration/inventory/admin-dashboard\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/administration/inventory/dashboard/template.hbs"
    }
  });

  _exports.default = _default;
});