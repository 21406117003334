define("securitytrax-ember-ui/components/calendar-glimmer/calendar-header/calendar-header-time-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S4ExEd+6",
    "block": "{\"symbols\":[\"@isUser\",\"@hour\"],\"statements\":[[2,\" \"],[10,\"div\"],[14,0,\"calendar-header__time-cell--grid\"],[15,5,[32,0,[\"timeGridStyles\"]]],[12],[2,\"\\n\"],[6,[37,0],[[30,[36,2],[[30,[36,1],[[32,2],\"mm\"],null],\"00\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"span\"],[15,0,[31,[\"calendar-time \",[30,[36,0],[[32,1],\"user-cal-times\",\"\"],null]]]],[12],[2,\"\\n                \"],[1,[30,[36,1],[[32,2],\"h A\"],null]],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"moment-format\",\"is-equal\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/calendar-glimmer/calendar-header/calendar-header-time-cell/template.hbs"
    }
  });

  _exports.default = _default;
});