define("securitytrax-ember-ui/components/multi-step-form/forms/administration/inventory/edit-create-rma/rma-information/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    oneLineFullAddress: Ember.computed('company', function () {
      var company = this.company;
      var address = company.get('street') ? company.get('street') : '';
      var lastLine = "".concat(company.get('city') ? company.get('city') : '', " ").concat(company.get('city') ? ', ' : '', " ").concat(company.get('state') ? company.get('state') : '', " ").concat(company.get('zip') ? company.get('zip') : '');
      return address + ' ' + lastLine;
    })
  });

  _exports.default = _default;
});