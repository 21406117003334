define("securitytrax-ember-ui/components/multi-step-form/switchable-inputs/user-defined-field-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+KIJj4+u",
    "block": "{\"symbols\":[\"SwitchableComponent\",\"@formMode\",\"@adminUdfObj\",\"@multiStepForm\"],\"statements\":[[6,[37,1],[[30,[36,0],[[32,3,[\"switchableComponentPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1],[],[[\"@formMode\",\"@value\",\"@label\",\"@options\",\"@labelProperty\",\"@valueProperty\",\"@noSort\",\"@rednerInPlaceOverride\",\"@required\",\"@errors\",\"@validationKey\",\"@type\",\"@udfType\",\"@renderInPlaceOverride\"],[[32,2],[32,3,[\"udf_value\"]],[32,3,[\"name\"]],[32,3,[\"options\"]],\"display\",\"value\",true,false,[32,3,[\"required\"]],[32,4,[\"validationErrors\"]],[32,3,[\"validation_key\"]],[32,3,[\"type\"]],[32,3,[\"udfType\"]],false]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"let\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/switchable-inputs/user-defined-field-input/template.hbs"
    }
  });

  _exports.default = _default;
});