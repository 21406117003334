define("securitytrax-ember-ui/components/pages/reports/telus-reports/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var TelusReports = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = (0, _emberConcurrency.task)(function* () {
    this.telusMonitoringCompanies = yield this.telusMonitoringCompaniesTask.perform();
  }), _dec20 = (0, _emberConcurrency.task)(function* () {
    var companies = yield this.store.query('monitoringCompany', {
      filter: 'monitoring_company_type = 27'
    });

    if (companies.length === 1) {
      this.telusMonitoringCompany = companies.objectAt(0);
      yield this.reportLoadableListsTask.perform();
    }

    return companies;
  }), _dec21 = (0, _emberConcurrency.task)(function* () {
    var loadableLists = [];

    if (this.telusMonitoringCompany) {
      var lists = yield this.centralStationService.getData({
        model: this.telusMonitoringCompany,
        method: 'adt_canada__getReportsLoadableLists',
        rawResponse: false
      });
      lists.reports_loadable_lists.forEach(list => {
        loadableLists[list.type] = list.items;
      });
    }

    this.loadableLists = loadableLists;
  }), _dec22 = (0, _emberConcurrency.task)(function* () {
    var reports;

    if (this.telusMonitoringCompany) {
      try {
        var newFilters = yield this.stFiltersService.getFiltersByGroupKeyAsObject('telusReports', [], {
          telusReportsMonitoringCompany: true
        });
        newFilters['page_number'] = this.page;
        reports = yield this.centralStationService.getData({
          model: this.telusMonitoringCompany,
          method: 'adt_canada__getSoaDealerArchivedDocumentList',
          payload: newFilters
        });
        this.listCount = reports.paging.total_item_count;
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err);
        (false && Ember.warn('error retrieving reports from telus:' + err, {
          id: 'reports.telus-reports.adtReportsTask'
        }));
        throw err;
      }
    }

    this.telusReports = reports ? reports.soa_dealer_archived_document_list : undefined;
  }), _dec23 = (0, _emberConcurrency.task)(function* (row) {
    try {
      var report = yield this.centralStationService.getData({
        model: this.telusMonitoringCompany,
        method: 'adt_canada__getSoaArchivedDocumentContent',
        payload: {
          archive_name: row.archive_name
        }
      });
      var linkSource = "data:application/".concat(row.archive_file_extension, ";base64,").concat(report.soa_archived_document_content.data);
      var downloadLink = document.createElement('a');
      var fileName = row.archive_name;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err);
      (false && Ember.warn('error retrieving report from telus:' + err, {
        id: 'reports.telus-reports.downloadReport'
      }));
      throw err;
    }
  }), _dec24 = Ember._action, (_class = (_temp = class TelusReports extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "centralStationService", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "sessionData", _descriptor4, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor5, this);

      _initializerDefineProperty(this, "stFiltersService", _descriptor6, this);

      _initializerDefineProperty(this, "telusMonitoringCompany", _descriptor7, this);

      _initializerDefineProperty(this, "filter", _descriptor8, this);

      _initializerDefineProperty(this, "telusMonitoringCompanies", _descriptor9, this);

      _initializerDefineProperty(this, "telusReports", _descriptor10, this);

      _initializerDefineProperty(this, "listCount", _descriptor11, this);

      _initializerDefineProperty(this, "filter_years", _descriptor12, this);

      _initializerDefineProperty(this, "filter_months", _descriptor13, this);

      _initializerDefineProperty(this, "filter_days", _descriptor14, this);

      _initializerDefineProperty(this, "filter_soa_document_names", _descriptor15, this);

      _initializerDefineProperty(this, "filter_soa_document_extensions", _descriptor16, this);

      _initializerDefineProperty(this, "item_count_per_page", _descriptor17, this);

      _initializerDefineProperty(this, "page", _descriptor18, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor19, this);

      _initializerDefineProperty(this, "telusMonitoringCompaniesTask", _descriptor20, this);

      _initializerDefineProperty(this, "reportLoadableListsTask", _descriptor21, this);

      _initializerDefineProperty(this, "telusReportsTask", _descriptor22, this);

      _initializerDefineProperty(this, "downloadReport", _descriptor23, this);

      this.initDataTask.perform();
    }

    clearFilter() {
      Ember.setProperties(this, {
        telusMonitoringCompany: null,
        filter_years: undefined,
        filter_months: undefined,
        filter_days: undefined,
        filter_document_names: undefined,
        filter_document_extensions: undefined,
        item_count_per_page: undefined
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "stFiltersService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "telusMonitoringCompany", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "filter", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "telusMonitoringCompanies", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "telusReports", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "listCount", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "filter_years", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "filter_months", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "filter_days", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "filter_soa_document_names", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "filter_soa_document_extensions", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "item_count_per_page", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "telusMonitoringCompaniesTask", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "reportLoadableListsTask", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "telusReportsTask", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "downloadReport", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "clearFilter", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilter"), _class.prototype)), _class));
  _exports.default = TelusReports;
});