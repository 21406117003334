define("securitytrax-ember-ui/components/multi-step-form/forms/at-risk-entries/edit-at-risk-entry/edit-resolution-attempt/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dBKmhY6h",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,6],null,[[\"useSpinner\",\"booleanLoaded\"],[true,[35,0,[\"finishedCopyingModels\"]]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"grid-x grid-padding-x\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cell small-12 medium-8 large-5\"],[12],[2,\"\\n            \"],[1,[30,[36,4],null,[[\"name\",\"options\",\"valueProperty\",\"formMode\",\"label\",\"value\",\"errors\",\"validationKey\",\"required\"],[\"communication\",[35,3],\"value\",[35,2],[30,[36,1],[\"pages.customers.at_risk_entries.communication\"],null],[35,0,[\"formCopiedModels\",\"customerAtRiskNote\",\"model\",\"communication\"]],[35,0,[\"validationErrors\"]],\"customerAtRiskNote-communication\",true]]]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"grid-x grid-padding-x\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cell small-12\"],[12],[2,\"\\n            \"],[1,[30,[36,5],null,[[\"name\",\"formMode\",\"value\",\"label\",\"maxlength\",\"rows\"],[\"attempt-description\",[35,2],[35,0,[\"formCopiedModels\",\"customerAtRiskNote\",\"model\",\"note\"]],[30,[36,1],[\"pages.customers.at_risk_entries.note\"],null],150,5]]]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,10],null,[[\"onConfirm\",\"confirmMessage\",\"confirmBtnText\",\"confirmButtonClassNamesString\",\"manuallyOpen\",\"_dialogOpen\"],[[30,[36,9],[[35,8]],null],[30,[36,1],[\"common.confirm.really_delete\"],null],[30,[36,1],[\"common.actions.delete\"],null],\"st-btn-warn\",true,[35,7]]]]]],\"hasEval\":false,\"upvars\":[\"multiStepForm\",\"t\",\"formMode\",\"resolutionOptions\",\"multi-step-form/switchable-inputs/select-input\",\"multi-step-form/switchable-inputs/textarea-input\",\"html/st-await\",\"confirmDeleteModalOpen\",\"deleteAtRiskNote\",\"perform\",\"modals/confirm-popup\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/at-risk-entries/edit-at-risk-entry/edit-resolution-attempt/template.hbs"
    }
  });

  _exports.default = _default;
});