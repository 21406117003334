define("securitytrax-ember-ui/models/admin-udf", ["exports", "securitytrax-ember-ui/models/base_models/admin-udf"], function (_exports, _adminUdf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adminUdf.default.extend({});

  _exports.default = _default;
});