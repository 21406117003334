define("securitytrax-ember-ui/utils/doors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getDoorStateCSS = getDoorStateCSS;
  _exports.getStaticLabel = getStaticLabel;

  /**
   * Utility helper module for doors (locks, garages, etc.).
   * @module
   */

  /**
   * Returns the appropriate CSS class to show the current door state (assuming there are only three possible door states, including "Unknown").
   *
   * @param {number} currentState The state to show.
   * @param {Array<number>} states An array of possible states (excluding unknown).
   * @param {Array<String>} values An array of values that match the possible states (excluding unknown).
   * @param {boolean} [flipState=false] If true we should return the CSS for the opposite state (does not apply to "Unknown" state).
   *
   * @returns {String}
   */
  function getDoorStateCSS(currentState, states, values, flipState = false) {
    // Was the "unknown' state requested?
    var idx = states.indexOf(currentState);

    if (idx === -1) {
      // Return unknown.
      return 'unknown';
    } // Should we flip states?


    if (flipState) {
      // Reverse states.
      values.reverse();
    } // Return value.


    return values[idx];
  }
  /**
   * Creates and returns a generic configuration object for showing labels.
   *
   * @param {String} name The label name.
   * @param {String} text The textual state of the label.
   * @param {String} css The CSS class for the label.
   *
   * @returns {SimpleLabelType}
   */


  function getStaticLabel(name, text, css) {
    return {
      name,
      text,
      css
    };
  }
  /**
   * Data that describes a simple label.
   *
   * @typedef {{
   *     name: String,
   *     text: String,
   *     css: String
   * }} SimpleLabelType
   *
   * @property {String} name The label name.
   * @property {String} text The textual state of the label.
   * @property {String} css The CSS class for the label.
   *
   * @global
   */

});