define("securitytrax-ember-ui/components/multi-step-form/pencil-edit/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['edit-button'],
    extraClass: '',
    actionClass: 'switchable-actions',
    editButtonClasses: Ember.computed('extraClass', function () {
      var classString = 'action-edit md-button edit-button' + ' ' + this.extraClass;
      return classString.trim();
    }),

    didReceiveAttrs() {
      if (!this.formMode) {
        throw new Error('You must pass formMode into the pencil-edit component');
      }
    },

    actions: {
      edit() {
        if (this.additionalActions) {
          this.additionalActions();
        }

        this.set('formMode', 'edit');
      }

    }
  });

  _exports.default = _default;
});