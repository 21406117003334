define("securitytrax-ember-ui/components/customer/accounting/billing/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A3RuUtlL",
    "block": "{\"symbols\":[\"multiStepForm\",\"Form\"],\"statements\":[[8,\"multi-step-form\",[],[[\"@formSteps\",\"@formOriginalModels\",\"@formStarted\"],[[34,1],[34,2],true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,[32,2],[[24,0,\"billing-info-form\"]],[[\"@multiStepForm\",\"@formMode\",\"@customer\"],[[32,1],\"view\",[34,0]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"customer\",\"billingInfoForm\",\"formOriginalModels\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/customer/accounting/billing/template.hbs"
    }
  });

  _exports.default = _default;
});