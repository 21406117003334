define("securitytrax-ember-ui/components/pages/administration/company-settings/global-settings/email/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SbkZZ9hh",
    "block": "{\"symbols\":[\"multiStepForm\"],\"statements\":[[6,[37,6],[[30,[36,5],[\"user_admin_settings.view\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,4],null,[[\"formSteps\",\"formOriginalModels\",\"formStarted\"],[[35,3],[35,2],true]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,1],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],[[\"multiStepForm\",\"class\",\"formMode\",\"messages\"],[[32,1],\"email-settings-form\",\"view\",[35,0,[\"messages\"]]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"component\",\"formOriginalModels\",\"emailSettingsFormSteps\",\"multi-step-form\",\"has-grants\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/administration/company-settings/global-settings/email/template.hbs"
    }
  });

  _exports.default = _default;
});