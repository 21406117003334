define("securitytrax-ember-ui/components/multi-step-form/forms/administration/equipment/edit-vendor/base-component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/switchable-form-step"], function (_exports, _emberConcurrency, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    titleKey: 'pages.administration.equipment.equipmentVendor.title',
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    disabledProgressBarSteps: Ember.computed(function () {
      return [];
    }),
    hiddenProgressBarSteps: Ember.computed(function () {
      if (this.featuresEnabled) {
        return [];
      }

      return ['connectorDetails'];
    }),
    saveEquipmentVendor: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      if (this.isNewRecord) {
        var toCreateObjects = yield multiStepForm.validateCreateAll({
          deepValidate: false
        });
        var equipmentVendor = this.store.createRecord('equipmentVendor', toCreateObjects.equipmentVendor.data);
        yield toCreateObjects.equipmentVendor.saveWithRollbackAndNotification(equipmentVendor);
      } else {
        yield multiStepForm.validateSyncSaveAll({
          deepValidate: false
        });
      }

      this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
      buttonPromise.then(() => {
        if (this.isNewRecord) {
          multiStepForm.formStartedSetVal(false);
        }
      });
      yield multiStepForm.setupCopyModels.perform();
    })
  });

  _exports.default = _default;
});