define("securitytrax-ember-ui/services/st-await", ["exports", "ember-concurrency", "lodash", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _emberConcurrency, _lodash, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    _defaultNamespace: undefined,
    _allRegisteredData: undefined,
    // keeps track of registered data by namespace - used for later doing a yield to wait for resolved
    _allLoadedData: undefined,
    // keeps track of fullyLoaded state by namespace
    _allSavingData: undefined,
    // keeps track of things that are currently saving by namespace
    _checkingFullyLoadedByNamespace: undefined,

    // keeps of when we are checking fully loaded by namespace (so can cancel for rechecks)
    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        _defaultNamespace: 'global',
        _allRegisteredData: {},
        _allLoadedData: {},
        _allSavingData: {},
        _checkingFullyLoadedByNamespace: {}
      });
    },

    setSavedState(uniqueId, options) {
      var compiledOptions = _lodash.default.merge({
        loadedNamespace: this._defaultNamespace,
        isSaving: false
      }, options);

      this._setupNamespaceDefaults(compiledOptions.loadedNamespace);

      var _allSavingData = this._allSavingData;
      Ember.set(_allSavingData, "".concat(compiledOptions.loadedNamespace, ".isSaving"), compiledOptions.isSaving);

      this._recheckFullyLoadedByNamespace.perform(this, compiledOptions.loadedNamespace, uniqueId, 'setSavedState');
    },

    registerAwait(uniqueId, options) {
      var compiledOptions = _lodash.default.merge({
        loadedNamespace: this._defaultNamespace,
        awaitPromise: true // will be a promise when actually passed in via options

      }, options);

      this._setupNamespaceDefaults(compiledOptions.loadedNamespace);

      var _allRegisteredData = this._allRegisteredData;
      _allRegisteredData[compiledOptions.loadedNamespace][uniqueId] = options.awaitPromise;

      this._recheckFullyLoadedByNamespace.perform(this, compiledOptions.loadedNamespace, uniqueId, 'registerAwait');
    },

    deregisterAwait(uniqueId, options) {
      var compiledOptions = _lodash.default.merge({
        loadedNamespace: this._defaultNamespace
      }, options);

      var _allRegisteredData = this._allRegisteredData;

      if (!uniqueId || !_allRegisteredData[compiledOptions.loadedNamespace][uniqueId]) {
        (false && Ember.warn('you should not deregister an await that was not registered', {
          id: 'app.services.st-await.deregisterAwait'
        }));
      }

      delete _allRegisteredData[compiledOptions.loadedNamespace][uniqueId];

      if (_allRegisteredData[compiledOptions.loadedNamespace] && Object.keys(_allRegisteredData[compiledOptions.loadedNamespace]).length <= 0) {
        delete _allRegisteredData[compiledOptions.loadedNamespace];
      }

      this._recheckFullyLoadedByNamespace.perform(this, compiledOptions.loadedNamespace, uniqueId, 'deregisterAwait');
    },

    getCurrentFullyLoadedStateByNamespace(options) {
      var compiledOptions = _lodash.default.merge({
        loadedNamespace: this._defaultNamespace
      }, options);

      var _allLoadedData = this._allLoadedData;
      var _allSavingData = this._allSavingData;
      var currLoadedData = _allLoadedData[compiledOptions.loadedNamespace];

      if (currLoadedData == null) {
        // means the current namespace has not been registered and so is just loaded
        return {
          loadedNamespace: compiledOptions.loadedNamespace,
          fullyLoaded: true,
          hasError: false,
          waitingOnSource: 'stAwait'
        };
      }

      var isRunning = _lodash.default.get(currLoadedData, 'loadingState.isRunning', false);

      var isSaving = _allSavingData[compiledOptions.loadedNamespace]['isSaving'];
      var waitingOnSave = isSaving === true;
      var waitingSource = waitingOnSave ? 'save' : 'stAwait';

      if (isRunning || isSaving) {
        return {
          loadedNamespace: compiledOptions.loadedNamespace,
          fullyLoaded: false,
          hasError: false,
          waitingSource: waitingSource
        };
      }

      return {
        loadedNamespace: compiledOptions.loadedNamespace,
        fullyLoaded: true,
        hasError: false,
        waitingSource: waitingSource
      };
    },

    _setupNamespaceDefaults(loadedNamespace) {
      var _allRegisteredData = this._allRegisteredData;

      if (!_allRegisteredData[loadedNamespace]) {
        _allRegisteredData[loadedNamespace] = {};
      }

      var _allSavingData = this._allSavingData;

      if (!_allSavingData[loadedNamespace]) {
        _allSavingData[loadedNamespace] = {
          isSaving: false
        };
      }
    },

    _recheckFullyLoadedByNamespace: (0, _emberConcurrency.task)({
      *perform(context, loadedNamespace, uniqueId, source = 'unknown') {
        var _allLoadedData = context.get('_allLoadedData');

        var _allSavingData = context.get('_allSavingData');

        var triggerEventKey = 'st-await-loaded-state-by-namespace-' + loadedNamespace;
        var currentAllSaving = _allSavingData[loadedNamespace];
        uniqueId;
        source; // used for debugging so keeping

        var _checkingFullyLoadedByNamespace = context.get('_checkingFullyLoadedByNamespace');

        if (_checkingFullyLoadedByNamespace[loadedNamespace]) {
          // cancel any previously running instances of this task by namespace
          yield _checkingFullyLoadedByNamespace[loadedNamespace].cancel();
        } // record this task instance by namespace so can be cancelled later


        _checkingFullyLoadedByNamespace[loadedNamespace] = this; // cancel child task of this task by namespace

        var currentCheckFullyLoaded = _allLoadedData[loadedNamespace];

        if (currentCheckFullyLoaded) {
          yield currentCheckFullyLoaded.cancel();
        }

        var waitingOnSave = currentAllSaving.isSaving === true;
        var waitingSource = waitingOnSave ? 'save' : 'stAwait';

        try {
          context.trigger(triggerEventKey, {
            loadedNamespace: loadedNamespace,
            fullyLoaded: false,
            hasError: false,
            waitingSource: waitingSource
          }); // wait for boolean isSaving first so don't have to mess with promises until the boolean is true

          yield (0, _emberConcurrency.waitForProperty)(currentAllSaving, 'isSaving', false); // yielding after so can check status in realtime by namespace

          _allLoadedData[loadedNamespace] = context.get('_checkFullyLoaded').perform(loadedNamespace);
          yield _allLoadedData[loadedNamespace];
          context.trigger(triggerEventKey, {
            loadedNamespace: loadedNamespace,
            fullyLoaded: true,
            hasError: false,
            waitingSource: waitingSource
          });
        } catch (error) {
          // warn('error in _recheckFullyLoadedByNamespace', {id:'app.services.st-await._recheckFullyLoadedByNamespace'});
          // warn(error, {id:'app.services.st-await._recheckFullyLoadedByNamespace'});
          context.trigger(triggerEventKey, {
            loadedNamespace: loadedNamespace,
            fullyLoaded: true,
            hasError: true,
            error: error,
            waitingSource: waitingSource
          });
          throw error;
        }
      }

    }),
    _checkFullyLoaded: (0, _emberConcurrency.task)(function* (loadedNamespace) {
      try {
        var _allRegisteredData = yield this._allRegisteredData;

        var currRegisteredData = _allRegisteredData[loadedNamespace];

        if (currRegisteredData && Object.keys(currRegisteredData).length >= 0) {
          yield Ember.RSVP.hash(currRegisteredData);
        }
      } catch (error) {
        // warn('!IMPORTANT - this could be just because you just left a page before it was fully loaded', {id:'app.services.st-await._checkFullyLoaded'});
        // warn(error, {id:'app.services.st-await._checkFullyLoaded'});
        throw error;
      }
    })
  });

  _exports.default = _default;
});