define("securitytrax-ember-ui/components/multi-step-form/switchable-inputs/textarea-input/component", ["exports", "securitytrax-ember-ui/mixins/switchable-input"], function (_exports, _switchableInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableInput.default, {
    maxlength: null,
    cols: null,
    rows: 1,
    selectionEnd: null,
    selectionStart: null,
    selectionDirection: null,
    wrap: null,
    //"soft or hard"
    spellcheck: false,
    maxRows: 12,

    didInsertElement() {
      if (this.setHeightOnInit) {
        var textAreaParent = document.getElementById(this.parentElementId);
        var textarea = textAreaParent.getElementsByClassName('ember-text-area')[0];
        var lineHeight = parseFloat(window.getComputedStyle(textarea, null).getPropertyValue('line-height'));
        var normalPixelHeight = lineHeight * this.rows;
        var maxPixelHeight = lineHeight * this.maxRows;

        if (textarea.scrollHeight > normalPixelHeight) {
          if (textarea.scrollHeight > maxPixelHeight) {
            textarea.style.height = maxPixelHeight + 'px';
          } else {
            textarea.style.height = textarea.scrollHeight + 'px';
          }
        }
      }
    }

  });

  _exports.default = _default;
});