define("securitytrax-ember-ui/components/multi-step-form/forms/calendar-event/duplicate-calendar-event/component", ["exports", "@glimmer/component", "ember-concurrency", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step"], function (_exports, _component, _emberConcurrency, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var DuplicateCalendarEvent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service('events/home-dashboard'), _dec6 = Ember.inject.service('events/calendar-events'), _dec7 = (0, _emberConcurrency.task)(function* () {
    var appointment = yield this.args.calendarEvent || this.args.multiStepForm.formCopiedModels.calendarEvent.model;
    var dupAppointmentData = yield (0, _emberConcurrency.hash)({
      customer: this.args.customer || this.store.findRecord('customer', appointment.customer.get('id')),
      calendarEventType: this.store.findRecord('calendarEventType', appointment.calendarEventType.get('id')),
      calendarEventDetailType: (yield appointment.calendarEventDetailType) ? this.store.findRecord('calendarEventDetailType', appointment.calendarEventDetailType.get('id')) : null,
      calendarUser: this.store.findRecord('calendarUser', appointment.calendarUser.get('id')),
      workOrder: this.store.findRecord('workOrder', appointment.workOrder.get('id'))
    });
    this.duplicateAppointment = Ember.Object.create({
      calendarCategory: appointment.calendarCategory,
      customer: dupAppointmentData.customer,
      calendarEventType: dupAppointmentData.calendarEventType,
      calendarEventDetailType: dupAppointmentData.calendarEventDetailType,
      calendarUser: dupAppointmentData.calendarUser,
      duration: appointment.duration,
      notes: appointment.notes,
      userNotes: appointment.userNotes,
      workOrder: dupAppointmentData.workOrder
    });
  }), _dec8 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var formattedObjects = [];
    formattedObjects.pushObject(Ember.Object.create({
      model: this.duplicateAppointment,
      filePath: 'calendar-event'
    }));
    yield this.args.multiStepForm.validateList.perform(formattedObjects);

    try {
      var toCreate = this.store.createRecord('calendarEvent', this.duplicateAppointment);
      yield toCreate.save();
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }

    buttonPromise.then(() => {
      this.args.multiStepForm.onAfterSave();
      this.args.multiStepForm.formStartedSetVal(false);
      this.homeDashboard.trigger('refresh-appointments');
      this.homeDashboard.trigger('refresh-work-orders-due-today');
      this.CalendarEventsService.trigger('refresh-customer-calendar-events');
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
      this.CalendarEventsService.trigger('refresh-page');

      if (this.args.transitionBackToParent) {
        this.args.transitionBackToParent();
      }
    });
  }), _dec9 = Ember._action, (_class = (_temp = class DuplicateCalendarEvent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "homeDashboard", _descriptor5, this);

      _initializerDefineProperty(this, "CalendarEventsService", _descriptor6, this);

      _defineProperty(this, "duplicateAppointment", undefined);

      _initializerDefineProperty(this, "initDataTask", _descriptor7, this);

      _initializerDefineProperty(this, "duplicateAppointmentTask", _descriptor8, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.initDataTask.perform();
    }

    willDestroy() {
      this.args.multiStepForm.registerStepData(this.args.multiStepForm.currentStepNameKey, 'buttons', []);
    }

    buttons() {
      this.args.multiStepForm.registerStepData(this.args.multiStepForm.currentStepNameKey, 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.duplicateAppointmentTask.perform(buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary appointment-save-button'
      }]);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "homeDashboard", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "CalendarEventsService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "duplicateAppointmentTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "buttons", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "buttons"), _class.prototype)), _class));
  _exports.default = DuplicateCalendarEvent;
});