define("securitytrax-ember-ui/components/calendar-glimmer/calendar-helpers/calendar-page/calendar-page", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CalendarPage = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, (_class = (_temp = class CalendarPage {
    constructor() {
      _initializerDefineProperty(this, "users", _descriptor, this);

      _initializerDefineProperty(this, "rows", _descriptor2, this);

      _initializerDefineProperty(this, "didUpdate", _descriptor3, this);

      _initializerDefineProperty(this, "calendarEvents", _descriptor4, this);

      _initializerDefineProperty(this, "hours", _descriptor5, this);

      _initializerDefineProperty(this, "hidden", _descriptor6, this);

      _initializerDefineProperty(this, "userListType", _descriptor7, this);

      _initializerDefineProperty(this, "userType", _descriptor8, this);

      _initializerDefineProperty(this, "maxIndex", _descriptor9, this);

      _initializerDefineProperty(this, "newUsers", _descriptor10, this);

      _initializerDefineProperty(this, "canSelectForMap", _descriptor11, this);

      _defineProperty(this, "id", null);

      _defineProperty(this, "selected", false);

      _defineProperty(this, "type", void 0);
    }

    resetMaxIndex() {
      this.maxIndex = 50;
    }

    removeCalendarEvent(eventId) {
      if (this.calendarEvents !== undefined) {
        this.calendarEvents = _lodash.default.remove(this.calendarEventArray, function (event) {
          return event.id != eventId;
        });
      }
    }

    addCalendarEvent(event) {
      this.calendarEvents.push(event);
    }

    get calendarEventArray() {
      if (this.calendarEvents !== undefined) {
        return this.calendarEvents.toArray();
      }

      return undefined;
    }

    showUsers(newUserArray) {
      var users = this.users;

      if (newUserArray) {
        this.newUsers = newUserArray;
      }

      if (users) {
        users.forEach(user => {
          if (newUserArray && newUserArray.length > 0) {
            for (var x = 0; x < newUserArray.length; x++) {
              var newUser = newUserArray.objectAt(x);

              if (user.id == newUser.id) {
                user.show = true;
                break;
              } else {
                user.show = false;
              }
            }
          } else {
            user.show = true;
          }
        });
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "users", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "rows", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "didUpdate", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "calendarEvents", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "hours", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "hidden", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "userListType", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'all';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "userType", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'tech';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "maxIndex", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 50;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "newUsers", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A();
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "canSelectForMap", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  })), _class));
  _exports.default = CalendarPage;
});