define("securitytrax-ember-ui/reports/route", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/route-pageview-logging", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/scroll-to-top", "securitytrax-ember-ui/mixins/has-page-title"], function (_exports, _emberConcurrency, _routePageviewLogging, _authenticatedRouteMixin, _scrollToTop, _hasPageTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _routePageviewLogging.default, _scrollToTop.default, _hasPageTitle.default, {
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionData: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform();
    },

    model: function () {
      return this.setupModelTask.perform();
    },
    setupModelTask: (0, _emberConcurrency.task)(function* () {
      var store = this.store;
      return Ember.RSVP.hash({
        reportCategories: yield store.findAll('reportCategory'),
        isAdtCompany: this.get('sessionData.companyHasSpecificMonitorTask').perform(),
        isTelusCompany: this.get('sessionData.companyHasSpecificMonitorTask').perform('AdtCanada')
      });
    }),

    getPageTitle() {
      return this.intl.t('pages.reports.title');
    },

    getRouteName() {
      var route = this.routeName.split('.').pop();
      return "pages.reports.nav.".concat(route.replace('-', '_'));
    }

  });

  _exports.default = _default;
});