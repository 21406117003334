define("securitytrax-ember-ui/demo/user-card/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller.extend({
    user: Ember.computed(function () {
      return Ember.Object.create({
        userHrSetting: Ember.Object.create({
          userHierarchyTitle: Ember.Object.create({
            name: 'Job-title - Wrap to contain full title - Wrap to contain full title Wrap to contain full title'
          })
        }),
        name: 'Jordana Kitchner',
        avatar: 'https://i.pravatar.cc/300',
        cellPhone: '666-666-6666',
        homePhone: '777-777-7777',
        workPhone: '888-888-8888',
        email: 'example@example.com',
        group: Ember.Object.create({
          roles: [Ember.Object.create({
            key: 'sales_rep',
            name: 'Sales Representative'
          }), Ember.Object.create({
            key: 'lead_rep',
            name: 'Lead Representative'
          }), Ember.Object.create({
            key: 'tech',
            name: 'Technician'
          })]
        })
      });
    })
  });
  var _default = Controller;
  _exports.default = _default;
});