define("securitytrax-ember-ui/accounting/aging-invoices/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/has-page-title", "securitytrax-ember-ui/mixins/scroll-to-top"], function (_exports, _authenticatedRouteMixin, _hasPageTitle, _scrollToTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _hasPageTitle.default, _scrollToTop.default, {
    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['customer_invoices.view'], 'all', 'accounting.dashboard');
    },

    model() {
      return Ember.RSVP.hash({
        officeLocations: this.store.findAll('location')
      });
    },

    getPageTitle() {
      return this.intl.t('pages.accounting.title');
    },

    getRouteName() {
      return 'common.nav.company';
    }

  });

  _exports.default = _default;
});