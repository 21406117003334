define("securitytrax-ember-ui/components/nav/top-nav/nav-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F98oSNnO",
    "block": "{\"symbols\":[\"@classNames\",\"&default\"],\"statements\":[[10,\"li\"],[15,0,[31,[\"top-nav-item user-nav \",[32,1],\" \",[34,0]]]],[15,\"onclick\",[30,[36,1],[[32,0],\"toggleItemNav\"],null]],[12],[2,\"\\n    \"],[18,2,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"subExpanded\",\"action\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/nav/top-nav/nav-item/template.hbs"
    }
  });

  _exports.default = _default;
});