define("securitytrax-ember-ui/components/html/st-divider/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ejD5KRXN",
    "block": "{\"symbols\":[\"&attrs\",\"@text\"],\"statements\":[[11,\"div\"],[24,0,\"st-divider-container\"],[17,1],[12],[2,\"\\n    \"],[10,\"h3\"],[14,0,\"bulk-divider\"],[12],[2,\"\\n        \"],[10,\"span\"],[12],[1,[32,2]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/st-divider/template.hbs"
    }
  });

  _exports.default = _default;
});