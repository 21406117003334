define("securitytrax-ember-ui/validations/central-station/telus-subject-address", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateObject = {
        address_type_code: {
          presence: () => {
            return this.customMessageOrDefault(data, 'telusSubjectAddress-address_type_code');
          }
        },
        civic_number: {
          presence: () => {
            return this.customMessageOrDefault(data, 'telusSubjectAddress-civic_number');
          }
        },
        street: {
          presence: () => {
            return this.customMessageOrDefault(data, 'telusSubject-street');
          }
        },
        city: {
          presence: () => {
            return this.customMessageOrDefault(data, 'telusSubject-city');
          }
        },
        province_code: {
          presence: () => {
            return this.customMessageOrDefault(data, 'telusSubject-province_code');
          }
        } // postal_code: { //This is off, says no postal is invalid
        //     format: () => {
        //         return {
        //             pattern: /[A-Z]\d[A-Z] ?\d[A-Z]\d/,
        //             //message: this.customMessage(data, 'zipInvalid')
        //         };
        //     }
        // }

      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      var modelName = data.extraValidationData && data.extraValidationData.hasOwnProperty('overrideValidationKey') ? data.extraValidationData.overrideValidationKey : 'telusSubjectAddress';
      return this.outputFormatting(validationData, modelName);
    }

  });

  _exports.default = _default;
});