define("securitytrax-ember-ui/components/modals/sub-route-modals/manage-rapid-response/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Component.extend({
    formStarted: false,
    formMode: 'edit',
    firstStep: 'general',
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    centralStationService: Ember.inject.service(),
    chatFunctionalityEnabled: false,
    eventHistoryFunctionalityEnabled: false,
    modifyAddressEnabled: false,
    initData: (0, _emberConcurrency.task)(function* () {
      try {
        var customer = yield this.customer;
        var hashedData = yield (0, _emberConcurrency.hash)({
          chatFunctionalityAvailable: this.centralStationService.getData({
            model: customer,
            method: 'stages__getChatFunctionalityAvailable'
          }),
          eventHistoryFunctionalityAvailable: this.centralStationService.getData({
            model: customer,
            method: 'stages__getEventHistoryFunctionalityAvailable'
          }),
          addressFunctionalityAvailable: this.centralStationService.getData({
            model: customer,
            method: 'stages__getAddressFunctionalityAvailable'
          })
        });
        this.set('modifyAddressEnabled', hashedData.addressFunctionalityAvailable.address_functionality_available);
        this.set('chatFunctionalityEnabled', hashedData.chatFunctionalityAvailable.chat_functionality_available);
        this.set('eventHistoryFunctionalityEnabled', hashedData.eventHistoryFunctionalityAvailable.event_history_functionality_available);
      } catch (error) {//Do nothing
      }

      this.set('formMode', this.get('customerSystemInformation.centralStationId') ? 'edit' : 'create');
      this.set('formStarted', true);
    }).on('init'),
    title: Ember.computed('customer.{id,flname,customerOrderInformation.monitoringCompany.monitoringCompanyType.name}', 'formMode', function () {
      var titleData = {
        monitorTypeName: this.get('customer.customerOrderInformation.monitoringCompany.monitoringCompanyType.name'),
        customerName: this.get('customer.flname'),
        id: this.get('customer.id')
      };
      return this.formMode == 'create' ? this.intl.t('pages.customers.system.order.stages_create_title', titleData) : this.intl.t('pages.customers.system.order.title', titleData);
    }),
    formOriginalModel: Ember.computed('grants.userGrants', 'customer', function () {
      var grants = this.grants;
      return Ember.Object.create({
        customer: {
          model: this.customer,
          copyRelationMap: {
            customerSystemInformation: {
              doCopy: grants.getIndividualGrant('customer_system_information.view'),
              doValidate: grants.getIndividualGrant('customer_system_information.view'),
              doSave: grants.getIndividualGrant('customer_system_information.modify'),
              relationships: {
                customerAbortCode: {
                  doCopy: grants.getIndividualGrant('customer_abort_codes.view'),
                  doValidate: grants.getIndividualGrant('customer_abort_codes.view'),
                  doSave: grants.getIndividualGrant('customer_abort_codes.modify')
                }
              }
            }
          }
        }
      });
    }),
    formSteps: Ember.computed('formMode', 'title', 'chatFunctionalityEnabled', 'eventHistoryFunctionalityEnabled', function () {
      var intl = this.intl;
      var firstStepComponentPath = this.formMode == 'create' ? 'create-account' : 'general';
      return Ember.Object.create({
        general: Ember.Object.create({
          stepNameKey: 'general',
          title: this.title,
          stepComponentPath: 'multi-step-form/forms/system/stages/' + firstStepComponentPath,
          progressBarTitle: intl.t('pages.customers.system.order.general')
        }),
        settings: Ember.Object.create({
          stepNameKey: 'settings',
          title: this.title,
          stepComponentPath: 'multi-step-form/forms/system/stages/settings',
          progressBarTitle: intl.t('pages.customers.system.order.settings')
        }),
        logs: Ember.Object.create({
          stepNameKey: 'logs',
          title: this.title,
          stepComponentPath: 'multi-step-form/forms/system/stages/logs',
          progressBarTitle: intl.t('pages.customers.system.order.logs'),
          granted: this.eventHistoryFunctionalityEnabled
        }),
        manageChat: Ember.Object.create({
          stepNameKey: 'manageChat',
          title: this.title,
          stepComponentPath: 'multi-step-form/forms/system/stages/manage-chat',
          progressBarTitle: intl.t('pages.customers.system.order.manage_chat'),
          granted: this.chatFunctionalityEnabled
        }),
        modifyAddress: Ember.Object.create({
          stepNameKey: 'modifyAddress',
          title: this.title,
          stepComponentPath: 'multi-step-form/forms/system/stages/modify-address',
          progressBarTitle: intl.t('pages.customers.system.order.modify_address'),
          granted: this.modifyAddressEnabled
        })
      });
    }),
    transitionToManage: (0, _emberConcurrency.task)(function* () {
      // yield this.initTelusManageData.perform();
      // this.title = this.intl.t('pages.customers.system.telus.edit_title', {
      //     monitorTypeName: this.args.customer.get('customerOrderInformation.monitoringCompany.monitoringCompanyType.name'),
      //     customerName: this.args.customer.get('flname'),
      //     id: this.args.customer.get('id')
      // });
      Ember.set(this, 'formMode', yield 'edit'); // this.store.findRecord('customerSystemInformation', this.args.customer.get('id')).then(systemInfo => {
      //     systemInfo.reload();
      // });
    }),
    actions: {
      transitionBackToParentRoute() {
        this.router.transitionTo(this.parentRoute, this.transitionId);
      }

    }
  });
  var _default = Controller;
  _exports.default = _default;
});