define("securitytrax-ember-ui/components/lead/dashboard/cards/last-interactions-card/component", ["exports", "@glimmer/component", "ember-concurrency", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _component, _emberConcurrency, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var LastInteractionCard = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = (0, _emberConcurrency.task)(function* () {
    this.lastInteraction = (yield this.args.customer.invokeAction('dashboard_cards/last_interaction', {
      verb: 'GET',
      includeActionsString: false,
      processResponse: true
    })).responsePayload.data;
  }), _dec9 = (0, _emberConcurrency.task)(function* () {
    this.currentLeadContact = yield this.store.findRecord('leadContact', this.lastInteraction.lead_contact_id);
    this.interactionFormMode = 'view';
    this.addLeadInteractionFormStarted = true;
  }), (_class = (_temp = class LastInteractionCard extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "grants", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "currentLeadContact", _descriptor4, this);

      _initializerDefineProperty(this, "addLeadInteractionFormStarted", _descriptor5, this);

      _initializerDefineProperty(this, "interactionFormMode", _descriptor6, this);

      _initializerDefineProperty(this, "lastInteraction", _descriptor7, this);

      _initializerDefineProperty(this, "lastInteractionTask", _descriptor8, this);

      _initializerDefineProperty(this, "openLastInteractionTask", _descriptor9, this);

      (0, _objHelpers.initializeState)(this, {
        addLeadInteractionFormStarted: false,
        currentLeadContact: null
      });

      if (!this.args.useDefaultData) {
        this.lastInteractionTask.perform();
      } else {
        this.lastInteraction = this.defaultData;
      }
    }

    get defaultData() {
      return {
        lead_contact_id: '1',
        created_at: '2020-10-12T13:33:16-06:00',
        user_name: 'James Thornton',
        note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie. Mauris malesuada nisi sit amet augue accumsan tincidunt'
      };
    }

    get addInteractionFormSteps() {
      return Ember.Object.create({
        interaction: Ember.Object.create({
          stepNameKey: 'interaction',
          title: this.intl.t('pages.leads.add_interaction'),
          stepComponentPath: 'multi-step-form/forms/leads/add-interaction'
        })
      });
    }

    get addInteractionFormModels() {
      return Ember.Object.create({
        leadContact: {
          model: this.currentLeadContact ? this.currentLeadContact : null
        }
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentLeadContact", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "addLeadInteractionFormStarted", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "interactionFormMode", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'view';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "lastInteraction", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "lastInteractionTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "openLastInteractionTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = LastInteractionCard;
});