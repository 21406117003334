define("securitytrax-ember-ui/models/base_models/funding", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    amountExpected: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    note: (0, _model.attr)('string', {
      'readOnly': false
    }),
    gradeInfo: (0, _model.attr)('string', {
      'readOnly': false
    }),
    dateExpected: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    customer: (0, _model.belongsTo)('customer', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    funder: (0, _model.belongsTo)('funder', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    fundingType: (0, _model.belongsTo)('funding-type', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    fundingProgressStatus: (0, _model.belongsTo)('funding-progress-status', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    fundingOutcome: (0, _model.belongsTo)('funding-outcome', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    funderReason: (0, _model.belongsTo)('funder-reason', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    fundingTransactions: (0, _model.hasMany)('funding-transaction', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    fundingProgressStatusLogs: (0, _model.hasMany)('funding-progress-status-log', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});