define("securitytrax-ember-ui/services/st-adc-notification-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ERROR = _exports.WARNING = _exports.SUCCESS = _exports.INFO = _exports.ADD_NOTIFICATION_TRIGGER = void 0;

  /**
   * The trigger name for adding a notification.
   *
   * @type {String}
   */
  var ADD_NOTIFICATION_TRIGGER = 'addNotification'; //region Notification Types

  /**
   * Creates a notification type.
   *
   * @private
   * @static
   * @ignore
   *
   * @param {String} type
   * @param {String} icon
   * @param {number|boolean} autoCloseDuration
   * @returns {{type: String, icon: String, autoCloseDuration: number|boolean}}
   */

  _exports.ADD_NOTIFICATION_TRIGGER = ADD_NOTIFICATION_TRIGGER;

  function createNotificationType(type, icon = type, autoCloseDuration = 6000) {
    return {
      type,
      icon,
      autoCloseDuration
    };
  } // Exporting all of these because they are used in tests


  var INFO = createNotificationType('info', 'icn-info');
  _exports.INFO = INFO;
  var SUCCESS = createNotificationType('success', 'icn-circle-check', 3000);
  _exports.SUCCESS = SUCCESS;
  var WARNING = createNotificationType('warning', 'icn-warning', false);
  _exports.WARNING = WARNING;
  var ERROR = createNotificationType('error', 'icn-circle-x'); //endregion

  /**
   * Adds notification from predefined notification type
   *
   * @private
   * @instance
   * @memberof services.NotificationManager
   *
   * @param {String} text
   * @param {{type:String, icon:String, autoCloseDuration:number|undefined}} notificationType
   * @param {number} autoCloseDurationOverride - Override default type's auto close duration
   */

  _exports.ERROR = ERROR;

  function addNotificationFromType(text, notificationType, autoCloseDurationOverride, namespace = 'global') {
    var {
      type,
      icon,
      autoCloseDuration
    } = notificationType;
    addNotification.call(this, text, icon, type, undefined, autoCloseDurationOverride !== undefined ? autoCloseDurationOverride : autoCloseDuration, '', namespace);
  }
  /**
   * Adds a notification to the notification manager by triggering a trigger.
   *
   * @private
   * @instance
   * @memberof services.NotificationManager
   *
   * @param {String} text - The text to display with the notification.
   * @param {String} icon - Icon to be shown in the notification.
   * @param {String} type - Type of the notification.
   * @param {Array<AppNotificationButtonType>} buttons - Buttons to be shown in the notification.
   * @param {number|undefined} autoCloseDuration - The amount of time before the notification closes itself (in ms).
   * @param {String} cssClass - Css class to be added to the notification.
   */


  function addNotification(text, icon, type, buttons, autoCloseDuration, cssClass, namespace = 'global') {
    // Are notifications currently muted?
    if (this.isMuted) {
      (false && Ember.warn("Muted ".concat(type, " notification: ").concat(text), {
        id: 'services.st-adc-notification-manager'
      })); // Do not add the notification.

      return;
    } // Convert false to 0ms to keep notification open until dismissed.


    if (!autoCloseDuration) {
      autoCloseDuration = 0;
    } // The notification-manager component is listening for this trigger.


    var triggerKey = ADD_NOTIFICATION_TRIGGER + '_' + namespace;
    this.trigger(triggerKey, Ember.Object.create({
      text: Ember.String.htmlSafe(text),
      type,
      icon,
      buttons,
      autoCloseDuration,
      cssClass,
      namespace
    }));
  }
  /**
   * @classdesc Service that manages adding/removing notifications for an application.
   *
   * @class NotificationManager
   * @extends Ember.Service
   * @mixes Ember.Evented
   * @memberof services
   */


  var _default = Ember.Service.extend(Ember.Evented,
  /** @lends services.NotificationManager# **/
  {
    /**
     * Determines if the notifications should be ignored.
     *
     * Note: If the session is ended, this flag should be set to false using the addSessionEnded() method so no new notifications will be added.
     * New notifications are likely errors caused by the lack of session, and we don't want multiple of these to display during the logout process.
     *
     * @type {boolean}
     */
    isMuted: false,

    /**
     * Adds an 'info' notification to the notification manager.
     *
     * @param {String} text - The text to use for the notification.
     */
    addInfo(text, namespace = 'global') {
      addNotificationFromType.call(this, text, INFO, undefined, namespace);
    },

    /**
     * Adds a 'success' notification to the notification manager.
     *
     * @param {String} text - The text to use for the notification.
     */
    addSuccess(text, namespace = 'global') {
      addNotificationFromType.call(this, text, SUCCESS, undefined, namespace);
    },

    /**
     * Adds a 'warning' notification to the notification manager.
     *
     * @param {String} text - The text to use for the notification.
     */
    addWarning(text, namespace = 'global') {
      addNotificationFromType.call(this, text, WARNING, undefined, namespace);
    },

    /**
     * Adds an 'error' notification to the notification manager.
     *
     * @param {String} text - The text to use for the notification.
     */
    addError(text, namespace = 'global') {
      addNotificationFromType.call(this, text, ERROR, 0, namespace);
    },

    /**
     * Adds a notification telling the user that their session has ended.
     *
     * Note: This sets a flag that does not allow any more notifications to be added.
     *
     * @param {String} text - The text to use for the notification.
     */
    addSessionEnded(text, namespace = 'global') {
      addNotificationFromType.call(this, text, ERROR, 0, namespace); // Mute notifications so service knows not to show any more application notifications.

      this.mute();
    },

    /**
     *
     * @param {String} text - Text to show in the notification.
     * @param {String=} icon - Icon for the notification.
     * @param {Array<AppNotificationButtonType>=} buttons - Buttons to render in the notification
     * @param {number=} autoCloseDuration - How long should the notification stay open? (in ms)
     * @param {String=} cssClass - Css class to be added to the notification.
     */
    addNotification(text, icon, buttons, autoCloseDuration, cssClass, namespace = 'global') {
      addNotification.call(this, text, icon, 'custom', buttons, autoCloseDuration, cssClass, namespace);
    },

    /**
     * Mutes (i.e. ignores) notifications so they do not render on screen.
     */
    mute() {
      this.set('isMuted', true);
    },

    /**
     * Unmutes notifications so they render on screen.
     */
    unMute() {
      this.set('isMuted', false);
    },

    /** @ignore */
    actions:
    /** @lends services.NotificationManager# */
    {
      /**
       * Adds an 'info' notification to the notification manager.
       *
       * @param {String} text - The text to use for the notification.
       */
      addInfo(text, namespace = 'global') {
        this.addInfo(text, namespace);
      },

      /**
       * Adds a 'success' notification to the notification manager.
       *
       * @param {String} text - The text to use for the notification.
       */
      addSuccess(text, namespace = 'global') {
        this.addSuccess(text, namespace);
      },

      /**
       * Adds a 'warning' notification to the notification manager.
       *
       * @param {String} text - The text to use for the notification.
       */
      addWarning(text, namespace = 'global') {
        this.addWarning(text, namespace);
      },

      /**
       * Adds an 'error' notification to the notification manager.
       *
       * @param {String} text - The text to use for the notification.
       */
      addError(text, namespace = 'global') {
        this.addError(text, namespace);
      },

      /**
       * Adds a notification telling the user that their session has ended.
       *
       * Note: This sets a flag that does not allow any more notifications to be added.
       *
       * @param {String} text - The text to use for the notification.
       */
      addSessionEnded(text, namespace = 'global') {
        this.addSessionEnded(text, namespace);
      }

    }
  });

  _exports.default = _default;
});