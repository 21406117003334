define("securitytrax-ember-ui/components/calendar-glimmer/calendar-month-view/desktop/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CalendarMonthViewDesktop = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = (_temp = class CalendarMonthViewDesktop extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "curEvent", _descriptor, this);

      _initializerDefineProperty(this, "showEdit", _descriptor2, this);

      _initializerDefineProperty(this, "showDuplicateAppointmentModal", _descriptor3, this);

      this.resizeHandler = this.handleResize.bind(this);
      window.addEventListener('resize', this.resizeHandler);
    }

    willDestroy() {
      window.removeEventListener('resize', this.resizeHandler);
    }

    get duplicateCalendarEventFormSteps() {
      return Ember.Object.create({
        calendarEventSingleStep: Ember.Object.create({
          stepNameKey: 'calendarEventSingleStep',
          stepComponentPath: 'multi-step-form/forms/calendar-event/duplicate-calendar-event'
        })
      });
    }

    get monthAndYear() {
      return this.args.calendarDate.format('MMMM YYYY');
    }

    handleResize() {
      Ember.run.debounce(this, this.handleResizeDebounced, 100);
    }

    handleResizeDebounced() {
      this.updateSummaryHeight();
    }

    updateSummaryHeight() {
      var calendar = document.getElementById('calendar-month');
      var summary = document.getElementById('calendar-day-summary');

      if (calendar && summary) {
        summary.style.height = "".concat(calendar.offsetHeight, "px");
      }
    }

    selectCurEvent(event) {
      if (!event || this.curEvent && this.curEvent.id === event.id) {
        this.curEvent = {};
      } else {
        this.curEvent = event;
      }

      this.showEdit = false;
    }

    editStatus() {
      this.showEdit = !this.showEdit;
    }

    duplicateWO() {
      this.duplicateAppointment = this.curEvent;
      this.showDuplicateAppointmentModal = true;
      this.selectCurEvent();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "curEvent", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "showEdit", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showDuplicateAppointmentModal", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleResize", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "handleResize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateSummaryHeight", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "updateSummaryHeight"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectCurEvent", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "selectCurEvent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editStatus", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "editStatus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "duplicateWO", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "duplicateWO"), _class.prototype)), _class));
  _exports.default = CalendarMonthViewDesktop;
});