define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/monitoring-companies/integrations/component", ["exports", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step"], function (_exports, _component, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Integrations extends _component.default {
    constructor() {
      super(...arguments);
      this.SwitchableFormStep = new _switchableFormStep.default(...arguments, {
        hiddenProgressBarSteps: this.hiddenProgressBarSteps,
        disabledProgressBarSteps: this.disabledProgressBarSteps
      });
    }

    get disabledProgressBarSteps() {
      return [];
    }

    get hiddenProgressBarSteps() {
      return [];
    }

  }

  _exports.default = Integrations;
});