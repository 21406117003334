define("securitytrax-ember-ui/my-account/integrations/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z+L5ck0m",
    "block": "{\"symbols\":[],\"statements\":[[8,\"my-account/integrations/adc\",[],[[\"@user\"],[[34,0,[\"user\"]]]],null],[2,\"\\n\\n\"],[6,[37,2],[[30,[36,1],[\"monitoring_companies.central_station_invoke_generic\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"my-account/integrations/brinks\",[],[[\"@user\"],[[34,0,[\"user\"]]]],null],[2,\"\\n    \"],[8,\"my-account/integrations/telus\",[],[[\"@user\"],[[34,0,[\"user\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"has-grants\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/my-account/integrations/template.hbs"
    }
  });

  _exports.default = _default;
});