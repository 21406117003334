define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/interactive-services-providers/adc/import/import-customers/import-options/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/interactive-services-providers/adc/import/import-customers/import-customers-base", "lodash"], function (_exports, _emberConcurrency, _importCustomersBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _importCustomersBase.default.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    tagsService: Ember.inject.service(),
    ispService: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData("importOptions", 'buttons', [{
        text: this.intl.t('common.actions.back'),
        classNamesString: 'st-btn st-btn-secondary',
        click: () => {
          multiStepForm.currentStepNameKeySetVal('filterOptions');
        }
      }, {
        text: this.intl.t('pages.administration.integrations.interactiveServicesProviders.imports.customerImport.runImport'),
        click: buttonPromise => {
          return this.doImportTask.perform(buttonPromise, multiStepForm);
        },
        classNamesString: 'st-btn st-btn-primary'
      }]);
    },

    doImportTask: (0, _emberConcurrency.task)(function* (buttonPromise, multiStepForm) {
      var officeLocationId = _lodash.default.get(this.importAdcCustomersJson, 'officeLocation.id', null);

      var defaultRepId = _lodash.default.get(this.importAdcCustomersJson, 'defaultRep.id', null);

      var formattedObjects = [];
      formattedObjects.pushObject(Ember.Object.create({
        model: {
          officeLocation: officeLocationId
        },
        filePath: 'interactive-services-provider/adc-import/adc-customer-import'
      }));
      yield multiStepForm.validateList.perform(formattedObjects);
      var tagIdsArr = this.importAdcCustomersJson.customerTags.map(currTag => {
        return {
          id: Number(currTag.id)
        };
      });
      var requestDataAttributes = {
        tag_ids: this.importAdcCustomersJson.customerTags.map(tag => {
          return {
            id: Number(tag.id)
          };
        }),
        filters: {
          customer_id: this.importAdcCustomersJson.customerIds.split(',').map(currId => {
            return {
              id: +currId
            };
          }),
          dealer_id: this.importAdcCustomersJson.subDealerIds.map(currSubDealer => {
            return {
              id: currSubDealer.value
            };
          })
        }
      };

      if (tagIdsArr.length > 0) {
        Ember.set(requestDataAttributes, 'tag_ids', tagIdsArr);
      }

      if (officeLocationId) {
        Ember.set(requestDataAttributes, 'location_id', officeLocationId);
      }

      if (defaultRepId) {
        Ember.set(requestDataAttributes, 'rep_user_id', defaultRepId);
      }

      var requestData = {
        action: 'alarm_dot_com__importCustomers',
        model: this.interactiveServicesProvider,
        attributes: requestDataAttributes
      };

      try {
        yield this.ispService.get('invokeIspActionTask').perform(requestData);
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err);
        throw err;
      }

      buttonPromise.then(() => {
        multiStepForm.currentStepNameKeySetVal('importSummary');
      });
    }),
    defaultRepOptions: Ember.computed(function () {
      return this.defaultRepOptionsTask.perform();
    }),
    defaultRepOptionsTask: (0, _emberConcurrency.task)(function* () {
      return yield this.store.query('user', {
        sort: 'lname,fname'
      });
    }),
    customerTagsOptions: Ember.computed(function () {
      return this.get('tagsService.getTagsByObjectType').perform('customer');
    }),
    locationsSorted: Ember.computed(function () {
      return this.locationsSortedTask.perform();
    }),
    locationsSortedTask: (0, _emberConcurrency.task)(function* () {
      var locations = yield this.store.findAll('location');
      return locations.sortBy('name');
    })
  });

  _exports.default = _default;
});