define("securitytrax-ember-ui/administration/company-settings/system/payables/function-details/level-details/line-item-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZccPY3GE",
    "block": "{\"symbols\":[],\"statements\":[[8,\"nav/top-nav-sub\",[],[[\"@title\",\"@breadCrumbs\",\"@breadCrumbData\"],[[30,[36,0],[\"pages.administration.human_resources.payables\"],null],[34,1],[34,2]]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"page-content\"],[12],[2,\"\\n    \"],[8,\"pages/administration/human-resources/payables/function-details/level-details/line-item-details\",[],[[\"@payrollRoleFunction\",\"@payrollRoleFunctionLevel\",\"@payrollColumn\",\"@model\"],[[34,3,[\"payrollRoleFunction\"]],[34,3,[\"payrollRoleFunctionLevel\"]],[34,3,[\"payrollColumn\"]],[34,3]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"breadCrumbs\",\"breadCrumbData\",\"model\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/administration/company-settings/system/payables/function-details/level-details/line-item-details/template.hbs"
    }
  });

  _exports.default = _default;
});