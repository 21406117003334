define("securitytrax-ember-ui/components/multi-step-form/progress-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qdm+W0mq",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,8],[[35,4,[\"hideProgressBar\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,7],[[35,6]],[[\"additionalClass\",\"config\",\"modalButtons\",\"globallyDisabled\",\"formClass\",\"loadedNamespace\"],[[35,5],[35,4],[35,3],[35,2],[35,1],[35,0]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"loadedNamespace\",\"formClass\",\"globallyDisabled\",\"modalButtons\",\"config\",\"additionalClass\",\"viewComponentPath\",\"component\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/progress-bar/template.hbs"
    }
  });

  _exports.default = _default;
});