define("securitytrax-ember-ui/adapters/application", ["exports", "@ember-data/model", "@ember-data/adapter/json-api", "ember-simple-auth-token/mixins/token-authorizer", "ember-inflector", "ember-concurrency", "lodash", "@ember-data/adapter/-private", "securitytrax-ember-ui/config/environment"], function (_exports, _model, _jsonApi, _tokenAuthorizer, _emberInflector, _emberConcurrency, _lodash, _private, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //https://guides.emberjs.com/v2.16.0/models/customizing-adapters/
  //https://www.emberjs.com/api/ember-data/2.11/classes/DS.Adapter
  //https://emberjs.com/api/ember-data/2.11/classes/DS.Store
  //In Ember Data, the Adapter determines how data is persisted to a backend data store, such as the URL format and headers for a REST API. (The format of the data itself is determined by the serializer.)
  var _default = _jsonApi.default.extend(_tokenAuthorizer.default, {
    stAjax: Ember.inject.service(),
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    stDataCaching: Ember.inject.service(),
    stMulticallService: Ember.inject.service(),

    ajaxOptions(url, type, options) {
      var hash = this._super(url, type, options);

      var timeout;

      switch (url.substring(url.indexOf('actions/'))) {
        case 'actions/central_station_invoke_generic':
        case 'actions/interactive_services_provider_invoke_generic':
          timeout = timeout || 400000;
          break;

        default:
          timeout = timeout || 90000;
      }

      var startTime = new Date().getTime();

      hash.beforeSend = xhr => {
        xhr.done(msg => {
          msg;
          var totalTime = new Date().getTime() - startTime;
          this.stAjax.addAccessed(type + ' ' + url, totalTime);
        });
      };

      hash.timeout = hash.timeout || timeout;
      return hash;
    },

    get headers() {
      return this.stAjax.defaultHeaders();
    },

    findHasMany(store, snapshot, url, relationship) {
      relationship;
      var id = snapshot.id,
          type = snapshot.modelName;

      var relQueryData = _lodash.default.get(snapshot, 'adapterOptions.relQueryParamsData', null);

      if (relQueryData && relQueryData.params) {
        // default query options
        var queryParamsMerged = _lodash.default.merge({
          limit: 0
        }, relQueryData.params); // alphabetize option keys so can be used as a cache key


        var compiledQueryParams = {};
        (0, _lodash.default)(queryParamsMerged).keys().sort().each(function (key) {
          compiledQueryParams[key] = queryParamsMerged[key];
        }); // get the query params string

        var qp = Object.keys(compiledQueryParams).map(key => "".concat(key, "=").concat(compiledQueryParams[key])).join('&'); // original url

        url = this.urlPrefix(url, this.buildURL(type, id, snapshot, 'findHasMany')); // url with params

        url += '?' + qp;

        var theResponseData = this._super(store, snapshot, url, relationship);

        return theResponseData;
      } // don't modify if don't have query params


      return this._super(...arguments);
    },

    findAll(store, type, sinceToken, snapshotRecordArray) {
      var query = this.buildQuery(snapshotRecordArray);
      var url = this.buildURL(type.modelName, null, snapshotRecordArray, 'findAll');

      if (sinceToken) {
        query.since = sinceToken;
      }

      query.limit = 0; // this makes it so all findAll calls are not limited on the BE (as a findAll is supposed to be)

      var multicallOptions = _lodash.default.get(snapshotRecordArray, 'adapterOptions.multicallOptions', null);

      if (_environment.default['ember-cli-mirage']['enabled'] === false && multicallOptions) {
        return this.stMulticallService.getMulticallData(multicallOptions, {
          url,
          verb: 'GET',
          body: {},
          queryParams: query
        });
      } else {
        return this.ajax(url, 'GET', {
          data: query
        });
      }
    },

    //get headers() {
    //    return this.get('stAjax').defaultHeaders();
    //},

    /**
     * Trying to overcome browser timeouts by not using a computed property here
     *
     * https://stackoverflow.com/questions/58653616/how-to-replace-the-authorize-method-in-ember-simple-auth
     * https://github.com/emberjs/data/issues/5061
     * https://github.com/testem/testem/issues/1021
     */
    // headers: function() {
    //     return this.stAjax.defaultHeaders();
    // },
    pathForType: function (type) {
      return _emberInflector.default.inflector.pluralize(Ember.String.underscore(type));
    },
    _buildURL: function (modelName, id) {
      id;

      var url = this._super(...arguments);

      return this.stAjax.apiUrl(url);
    },

    /**
        Called by the store in order to fetch the JSON for a given
        type and ID.
         The `findRecord` method makes an Ajax request to a URL computed by
        `buildURL`, and returns a promise for the resulting payload.
         This method performs an HTTP `GET` request with the id provided as part of the query string.
         @since 1.13.0
        @method findRecord
        @param {Store} store
        @param {Model} type
        @param {String} id
        @param {Snapshot} snapshot
        @return {Promise} promise
    */
    findRecord(store, type, id, snapshot) {
      var url = this.buildURL(type.modelName, id, snapshot, 'findRecord');
      var query = this.buildQuery(snapshot);

      var multicallOptions = _lodash.default.get(snapshot, 'adapterOptions.multicallOptions', null);

      if (_environment.default['ember-cli-mirage']['enabled'] === false && multicallOptions) {
        return this.stMulticallService.getMulticallData(multicallOptions, {
          url,
          verb: 'GET',
          body: {},
          queryParams: query
        });
      } else {
        return this.ajax(url, 'GET', {
          data: query
        });
      }
    },

    /**
        Called by the store in order to fetch a JSON array for
        the records that match a particular query.
         The `query` method makes an Ajax (HTTP GET) request to a URL
        computed by `buildURL`, and returns a promise for the resulting
        payload.
         The `query` argument is a simple JavaScript object that will be passed directly
        to the server as parameters.
         @method query
        @param {Store} store
        @param {Model} type
        @param {Object} query
        @return {Promise} promise
    */
    query(store, type, query, recordArray, options) {
      var url = this.buildURL(type.modelName, null, null, 'query', query);

      if (this.sortQueryParams) {
        query = this.sortQueryParams(query);
      }

      var multicallOptions = _lodash.default.get(options, 'adapterOptions.multicallOptions', null);

      if (_environment.default['ember-cli-mirage']['enabled'] === false && multicallOptions) {
        return this.stMulticallService.getMulticallData(multicallOptions, {
          url,
          verb: 'GET',
          body: {},
          queryParams: query
        });
      } else {
        return this.ajax(url, 'GET', {
          data: query
        });
      }
    },

    /**
        Called by the store when a record is deleted.
         The `deleteRecord` method  makes an Ajax (HTTP DELETE) request to a URL computed by `buildURL`.
         @method deleteRecord
        @param {Store} store
        @param {Model} type
        @param {Snapshot} snapshot
        @return {Promise} promise
    */
    deleteRecord(store, type, snapshot) {
      store;
      var id = snapshot.id;

      var multicallOptions = _lodash.default.get(snapshot, 'adapterOptions.multicallOptions', null);

      if (_environment.default['ember-cli-mirage']['enabled'] === false && multicallOptions) {
        return this.stMulticallService.getMulticallData(multicallOptions, {
          url: this.buildURL(type.modelName, id, snapshot, 'deleteRecord'),
          verb: 'DELETE',
          body: {}
        });
      } else {
        return this.ajax(this.buildURL(type.modelName, id, snapshot, 'deleteRecord'), 'DELETE');
      }
    },

    /**
        Called by the store when a newly created record is
        saved via the `save` method on a model record instance.
         The `createRecord` method serializes the record and makes an Ajax (HTTP POST) request
        to a URL computed by `buildURL`.
         See `serialize` for information on how to customize the serialized form
        of a record.
         @method createRecord
        @param {Store} store
        @param {Model} type
        @param {Snapshot} snapshot
        @return {Promise} promise
    */
    createRecord(store, type, snapshot) {
      var url = this.buildURL(type.modelName, null, snapshot, 'createRecord');
      var data = (0, _private.serializeIntoHash)(store, type, snapshot);

      var multicallOptions = _lodash.default.get(snapshot, 'adapterOptions.multicallOptions', null);

      if (_environment.default['ember-cli-mirage']['enabled'] === false && multicallOptions) {
        return this.stMulticallService.getMulticallData(multicallOptions, {
          url,
          verb: 'POST',
          body: data
        });
      } else {
        return this.ajax(url, 'POST', {
          data
        });
      }
    },

    updateRecord(store, type, snapshot) {
      var data = (0, _private.serializeIntoHash)(store, type, snapshot);
      var url = this.buildURL(type.modelName, snapshot.id, snapshot, 'updateRecord');

      var multicallOptions = _lodash.default.get(snapshot, 'adapterOptions.multicallOptions', null);

      if (_environment.default['ember-cli-mirage']['enabled'] === false && multicallOptions) {
        return this.stMulticallService.getMulticallData(multicallOptions, {
          url,
          verb: 'PATCH',
          body: data
        });
      } else {
        return this.ajax(url, 'PATCH', {
          data: data
        });
      }
    },

    /**
     * This method is used by the store to determine if the store should reload
     * a record after the store.findRecord method resolves a cached record.
     *
     * This method is only checked by the store when the store is returning a
     * cached record.
     *
     * If this method returns true the store will re-fetch a record from the
     * adapter.
     *
     * By default this hook returns true so the data for the record is updated
     * in the background.
     *
     * @param {*} store
     * @param {*} snapshot
     */
    shouldBackgroundReloadRecord(store, snapshot) {
      snapshot;
      return true;
    },

    /**
     * This method is used by the store to determine if the store should reload
     * a record from the adapter when a record is requested by
     * store.findRecord.
     *
     * If this method returns true, the store will re-fetch a record from the
     * adapter. If this method returns false, the store will resolve
     * immediately using the cached record.
     *
     * @param {*} store
     * @param {*} snapshot
     */
    shouldReloadRecord(store, snapshot) {
      snapshot;
      return true;
    },

    /**
     * This method is used by the store to determine if the store should reload
     * all records from the adapter when records are requested by
     * store.findAll.
     *
     * If this method returns true, the store will re-fetch all records from
     * the adapter. If this method returns false, the store will resolve
     * immediately using the cached record.
     *
     * @param {*} store
     * @param {*} snapshotArray
     */
    shouldReloadAll(store, snapshotArray) {
      var findAllMeta = store.get('findAllMeta') || {};
      var modelName = Ember.String.camelize(snapshotArray.type.modelName);

      if (!findAllMeta[modelName] || findAllMeta[modelName]['accessed'] !== true) {
        return true;
      }

      return this._super(...arguments);
    },

    /**
     * This method is used by the store to determine if the store should reload
     * a record array after the store.findAll method resolves with a cached
     * record array.
     *
     * This method is only checked by the store when the store is returning a
     * cached record array.
     *
     * If this method returns true the store will re-fetch all records from the
     * adapter.
     *
     * @param {*} store
     * @param {*} snapshotArray
     */
    shouldBackgroundReloadAll(store, snapshotRecordArray) {
      //return true;
      snapshotRecordArray;
      return false;
    },

    /**
     * Can this be removed now?
     * Update relationship via a custom XHR
     * @TODO: TRAX-382 . Refactor to use ember-data instead of an
     *    almost direct call to jQuery stAjax object.
     *    Look at article:
     * http://thejsguy.com/2016/10/28/updating-relationships-independently-in-json-api-with-ember-data.html
     *
     * This is invoked by method of same name on base model
     */
    updateRelationship(action, model, relationship, relatedData, relationType) {
      //http://localhost:8110/sandbox/user/v1/customers/1
      var baseUrl = this._buildURL(model.constructor.modelName, model.get('id'));

      var url = baseUrl + '/relationships/' + relationship;
      var data;

      if (relatedData instanceof Array) {
        data = relatedData.map(item => {
          //check if item is DS model, if so grab id. Else, if its a JS object, get id. Else, its an array of ids.
          var itemId = item instanceof _model.default ? item.get('id') : typeof item === 'object' && 'id' in item ? item.id : item;
          return {
            id: itemId,
            type: relationType
          };
        });
      } else {
        data = relatedData;
      }

      return this.ajax(url, action, {
        data: {
          data: data
        }
      });
    },

    /**
     * Should use method on the model itself (see model.js:invokeAction)
     */
    _invokeModelAction(model, action, options = {}) {
      if (options.performLinked === true) {
        return this._invokeModelActionTask.linked().perform(model, action, options);
      } else {
        return this._invokeModelActionTask.unlinked().perform(model, action, options);
      }
    },

    _invokeModelActionTask: (0, _emberConcurrency.task)(function* (model, action, options = {}) {
      var includeActionsString = true;

      if (options.includeActionsString === false) {
        includeActionsString = false;
      }

      var modelID = model.get('id');

      if (options.excludeModelID) {
        modelID = null;
      }

      var baseUrl = this._buildURL(model.constructor.modelName, modelID);

      var adapter = this;
      var url;

      if (includeActionsString) {
        url = baseUrl + '/actions/' + action;
      } else {
        url = baseUrl + '/' + action;
      }

      var verb = options.verb || 'GET';
      var data = options.data || null;
      var timeout = options.timeout;
      var response = {};

      if (options.additionalParams) {
        options.additionalParams.forEach(param => {
          url += '/' + param;
        });
      }
      /** do the actual request */


      var payload;

      if (_environment.default['ember-cli-mirage']['enabled'] === false && options && options.multicallOptions) {
        payload = yield this.stMulticallService.getMulticallData(options.multicallOptions, {
          url,
          verb: verb,
          body: data
        });
      } else {
        payload = yield this.ajax(url, verb, {
          data,
          timeout
        });
      }
      /**
       * Make a copy of array/objects to ensure later modification
       * does not alter the return payload
       */


      var jsonPayload = payload;

      if (typeof jsonPayload === 'object') {
        jsonPayload = JSON.parse(JSON.stringify(jsonPayload));
      }

      response.responsePayload = jsonPayload;
      /**
       * Check if need to process the response
       */

      if (options.processResponse === true) {
        try {
          var serializer = adapter.store.serializerFor(model.constructor.modelName); //serializer.pushPayload(adapter.store, payload);
          //make a copy for normalizing

          var normalizedPayload = serializer._normalizeDocumentHelper(JSON.parse(JSON.stringify(payload))); // https://www.emberjs.com/api/ember-data/3.5/classes/DS.RecordArray
          // NOTE: limitation of RecordArray is can only be 1 model type in the array
          // it appears store.push returns whatever data was in the payload


          if (options.processResponseRecordArray === true) {
            var modelName;

            if (Array.isArray(normalizedPayload.data)) {
              //NOTE: not neccessarily the same model that provides the action endpoint
              modelName = normalizedPayload.data[0].type; // iterate data and ensure all the same type

              normalizedPayload.data.forEach(element => {
                if (element.type != modelName) {
                  throw new Error('RecordArray cannot contain multiple resource types');
                }
              });
            } else {
              //NOTE: not neccessarily the same model that provides the action endpoint
              modelName = normalizedPayload.data.type;
            } // @return {DS.InternalModel|Array<DS.InternalModel>} pushed InternalModel(s)


            var internalModels = adapter.store._push(normalizedPayload);

            if (internalModels) {
              if (!Array.isArray(internalModels)) {
                internalModels = [internalModels];
              } // @return {DS.RecordArray}


              response.recordArray = adapter.store.recordArrayManager.createRecordArray(modelName, internalModels);
            }
          } // @return {DS.Model|Array} the record(s) that was created or


          var models = adapter.store.push(normalizedPayload);

          if (models !== null) {
            if (!Array.isArray(models)) {
              models = [models];
            }

            response.models = models;
          }

          response.payloadPushed = true;
        } catch (error) {
          (false && Ember.warn('failed to processResponse in _invokeModelActionTask:' + error, {
            id: 'app.invokeModelActionTask'
          }));
        }
      }
      /**
       * check if need to reload model
       */


      if (options.reloadModel === true) {
        yield model.reload();
        response.modelReloaded = true;
      }

      return response;
    })
    /**
     *
     * @param {*} store
     * @param {*} snapshot
     * @param {*} url
     */

  });

  _exports.default = _default;
});