define("securitytrax-ember-ui/components/multi-step-form/forms/system/stages/stages-base", ["exports", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "ember-concurrency"], function (_exports, _component, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var StagesBase = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = (0, _emberConcurrency.task)(function* () {
    var hashedData = yield (0, _emberConcurrency.hash)({
      siteInformation: this.getSiteInformationTask.perform(),
      addresses: this.getAddressSuggestions.perform(),
      deviceTypes: this.deviceTypesTask.perform(),
      listenInDeviceTypes: this.listenInDeviceTypesTask.perform(),
      deviceStatuses: this.deviceStatusesTask.perform(this.args.formMode == 'create' ? 'pre_create' : 'post_create')
    });
    Ember.setProperties(this, hashedData);
  }), _dec15 = (0, _emberConcurrency.task)(function* () {
    var siteInformation = [];

    if (this.args.formMode == 'edit') {
      try {
        var information = yield this.centralStationService.getData({
          model: this.args.customer,
          method: 'stages__getSiteSimple'
        });
        siteInformation = information.site_simple_result;
      } catch (err) {
        var message = this.intl.t('pages.customers.system.order.stages_retrieve_error', {
          data: this.intl.t('pages.customers.system.order.customer_site_information')
        });
        this.stNotificationManager.addWarningMessages(err, {
          notificationNamespace: 'modal',
          messagePrefix: message
        });
      }

      return siteInformation;
    }
  }), _dec16 = (0, _emberConcurrency.task)(function* () {
    var addresses = Ember.A([]);

    try {
      var dataLocEnabledForCentralStation = yield this.centralStationService.getData({
        model: this.args.customer,
        method: 'stages__getDataLocFunctionalityAvailable'
      });

      if (dataLocEnabledForCentralStation.data_loc_functionality_available) {
        var response = yield this.centralStationService.getData({
          model: this.args.customer,
          method: 'stages__getDataLocFromCustomerAddress'
        });
        var dataLoc = response.data_loc_from_customer_address;
        dataLoc.suggestions.forEach((suggestion, index) => {
          suggestion['address_type'] = 'data_loc_entry';
          suggestion['zip'] = suggestion['zip_code'];
          delete suggestion['zip_code'];
          addresses.pushObject(Ember.Object.create({
            packageName: this.intl.t('pages.customers.system.order.suggestion') + ' ' + (index + 1),
            packageId: index + 1,
            packageContent: [{
              description: suggestion.address_1 + (suggestion.address_2 ? ' ' + suggestion.address_2 : '') + ' ' + suggestion.suite
            }, {
              description: suggestion.city + ' ' + suggestion.state + ' ' + suggestion.zip
            }]
          }));
        });
        this.showSuggestionsSection = dataLoc.has_suggestions_flag;
        this.showAddressSection = dataLoc.city_changed_flag || dataLoc.state_changed_flag || dataLoc.street_direction_changed_flag || dataLoc.street_name_changed_flag === 'Y' || dataLoc.street_suffix_changed_flag || dataLoc.suite_name_changed_flag || dataLoc.suite_number_changed_flag || dataLoc.zip_code_changed_flag || dataLoc.has_suggestions_flag;
        this.suggestions = dataLoc.suggestions;
        this.address = dataLoc.address_1 + (dataLoc.address_2 ? ' ' + dataLoc.address_2 : '');
        this.cityStateZip = dataLoc.city + ', ' + dataLoc.state + ' ' + dataLoc.zip_code;
      }
    } catch (err) {
      this.stNotificationManager.addWarningMessages(err, {
        notificationNamespace: 'modal'
      });
    }

    return addresses;
  }), _dec17 = (0, _emberConcurrency.task)(function* () {
    var deviceTypes = [];

    try {
      deviceTypes = yield this.centralStationService.getData({
        model: this.args.monitoringCompany,
        method: 'stages__getDeviceTypes'
      });
      deviceTypes = deviceTypes.device_types;
    } catch (err) {
      var message = this.intl.t('pages.customers.system.order.stages_retrieve_error', {
        data: this.intl.t('pages.customers.system.order.device_types')
      });
      this.stNotificationManager.addWarningMessages(err, {
        notificationNamespace: 'modal',
        messagePrefix: message
      });
    }

    return deviceTypes;
  }), _dec18 = (0, _emberConcurrency.task)(function* () {
    var deviceTypes = [];

    try {
      deviceTypes = yield this.centralStationService.getData({
        model: this.args.monitoringCompany,
        method: 'stages__getListenInDeviceTypes'
      });
      deviceTypes = deviceTypes.listen_in_device_types;
    } catch (err) {
      var message = this.intl.t('pages.customers.system.order.stages_retrieve_error', {
        data: this.intl.t('pages.customers.system.order.device_types')
      });
      this.stNotificationManager.addWarningMessages(err, {
        notificationNamespace: 'modal',
        messagePrefix: message
      });
    }

    return deviceTypes;
  }), _dec19 = (0, _emberConcurrency.task)(function* (state) {
    var deviceStatuses = [];

    try {
      deviceStatuses = yield this.centralStationService.getData({
        model: this.args.customer,
        method: 'stages__getOosCategories',
        payload: {
          'state': state
        }
      });
      deviceStatuses = deviceStatuses.oos_categories;
      var chooseOneOptionIndex = deviceStatuses.findIndex(status => {
        return status.id === '';
      });

      if (chooseOneOptionIndex !== -1) {
        deviceStatuses.splice(chooseOneOptionIndex, 1);
      }

      var activeStatusIndex = deviceStatuses.findIndex(status => {
        return status.id === -1;
      });

      if (activeStatusIndex !== -1) {
        deviceStatuses[activeStatusIndex].id = 'active';
      }
    } catch (err) {
      var message = this.intl.t('pages.customers.system.order.stages_retrieve_error', {
        data: this.intl.t('pages.customers.system.order.device_statuses')
      });
      this.stNotificationManager.addWarningMessages(err, {
        notificationNamespace: 'modal',
        messagePrefix: message
      });
    }

    return deviceStatuses;
  }), (_class = (_temp = class StagesBase extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "centralStationService", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "suggestions", _descriptor4, this);

      _initializerDefineProperty(this, "selectedAddressId", _descriptor5, this);

      _initializerDefineProperty(this, "showSuggestionsSection", _descriptor6, this);

      _initializerDefineProperty(this, "noAddressChange", _descriptor7, this);

      _initializerDefineProperty(this, "showAddressSection", _descriptor8, this);

      _initializerDefineProperty(this, "siteInformation", _descriptor9, this);

      _initializerDefineProperty(this, "addresses", _descriptor10, this);

      _initializerDefineProperty(this, "deviceTypes", _descriptor11, this);

      _initializerDefineProperty(this, "listenInDeviceTypes", _descriptor12, this);

      _initializerDefineProperty(this, "deviceStatuses", _descriptor13, this);

      _initializerDefineProperty(this, "baseInitDataTask", _descriptor14, this);

      _initializerDefineProperty(this, "getSiteInformationTask", _descriptor15, this);

      _initializerDefineProperty(this, "getAddressSuggestions", _descriptor16, this);

      _initializerDefineProperty(this, "deviceTypesTask", _descriptor17, this);

      _initializerDefineProperty(this, "listenInDeviceTypesTask", _descriptor18, this);

      _initializerDefineProperty(this, "deviceStatusesTask", _descriptor19, this);

      this.baseInitDataTask.perform();
      this.SwitchableFormStep = new _switchableFormStep.default(...arguments, {
        disabledProgressBarSteps: this.disabledProgressBarSteps,
        hiddenProgressBarSteps: this.hiddenProgressBarSteps
      });
      this.SwitchableFormStep.progressBarHidden = this.args.progressBarHidden || false;
    }

    get hiddenProgressBarSteps() {
      return [];
    }

    get disabledProgressBarSteps() {
      return [];
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "suggestions", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "selectedAddressId", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "showSuggestionsSection", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "noAddressChange", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "showAddressSection", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "siteInformation", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "addresses", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "deviceTypes", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "listenInDeviceTypes", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "deviceStatuses", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "baseInitDataTask", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "getSiteInformationTask", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "getAddressSuggestions", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "deviceTypesTask", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "listenInDeviceTypesTask", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "deviceStatusesTask", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = StagesBase;
});