define("securitytrax-ember-ui/customers/files/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MK+qHqhU",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],[\"files\"],[[\"model\",\"modelToSave\"],[[35,0],\"customer\"]]]],[2,\"\\n\\n\"],[1,[30,[36,2],null,[[\"customer\",\"showTitle\"],[[35,0,[\"customer\"]],true]]]],[2,\"\\n\\n\"],[8,\"customer/user-defined-fields\",[],[[\"@customer\",\"@model\",\"@udfContext\"],[[34,0,[\"customer\"]],[34,0],\"customers_files\"]],null],[2,\"\\n\\n\"],[1,[30,[36,1],[[30,[36,3],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"model\",\"component\",\"customer/files/edocs\",\"-outlet\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/customers/files/template.hbs"
    }
  });

  _exports.default = _default;
});