define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/monitoring-companies/account-number-blocks/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mbbgaYrt",
    "block": "{\"symbols\":[\"&attrs\",\"@formMode\",\"@multiStepForm\",\"@monitoringCompany\"],\"statements\":[[11,\"div\"],[17,1],[12],[2,\"\\n    \"],[8,\"multi-step-form/progress-bar\",[],[[\"@config\"],[[34,0,[\"progressBarConfig\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"html/st-await\",[],[[\"@useSpinner\",\"@booleanLoaded\"],[true,[34,1,[\"isIdle\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"multi-step-form/forms/administration/integrations/monitoring-companies/account-number-blocks/generic-account-number-blocks\",[],[[\"@formMode\",\"@multiStepForm\",\"@accountNumberBlocks\",\"@monitoringCompany\",\"@refreshModel\"],[[32,2],[32,3],[34,2],[32,4],[30,[36,3],[[32,0],\"refreshModel\"],null]]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"SwitchableFormStep\",\"initFormData\",\"accountNumberBlocks\",\"action\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/monitoring-companies/account-number-blocks/template.hbs"
    }
  });

  _exports.default = _default;
});