define("securitytrax-ember-ui/components/multi-step-form/forms/system/api/tests/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms/system/api/api-base"], function (_exports, _emberConcurrency, _apiBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _apiBase.default.extend({
    centralStationService: Ember.inject.service(),
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    showOnTestModal: false,
    startTestModeDate: null,
    startTestModeTime: null,
    endTestModeDate: null,
    endTestModeTime: null,
    accountOnTestInformation: Ember.computed(function () {
      return this.getTestStatus.perform();
    }),
    getTestStatus: (0, _emberConcurrency.task)(function* () {
      var testStatusObj = {};
      var accountOnTestResponse = yield this.centralStationService.getData({
        model: this.customer,
        method: 'api__getIsAccountOnTest'
      });
      var accountOnTestStringSearchResult = accountOnTestResponse.account_in_test.search('True');

      if (accountOnTestStringSearchResult === -1) {
        testStatusObj.onTest = false;
      } else {
        // BE should return this less stupidly and then we wouldn't have to mega slice a string for this info
        var dateString = accountOnTestResponse.account_in_test.slice(accountOnTestResponse.account_in_test.search(/[0-3][0-9]\/[0-9][0-9]\/[0-9][0-9]/));
        var startDate = dateString.slice(0, 8);
        var endDate = dateString.slice(9, 17);
        var startTime = dateString.slice(18, 23);
        var endTime = dateString.slice(24, 29);
        testStatusObj.onTest = true;
        testStatusObj.startDate = startDate;
        testStatusObj.endDate = endDate;
        testStatusObj.startTime = startTime;
        testStatusObj.endTime = endTime;
      }

      return testStatusObj;
    }),
    onTestModalButtons: Ember.computed(function () {
      return [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.putSystemOnTest.perform(buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary'
      }];
    }),
    putSystemOnTest: (0, _emberConcurrency.task)(function* (buttonPromise) {
      var formattedObjects = [];
      var validationModel = {
        startTestModeDate: this.startTestModeDate,
        startTestModeTime: this.startTestModeTime,
        endTestModeDate: this.endTestModeDate,
        endTestModeTime: this.endTestModeTime
      };
      formattedObjects.pushObject(Ember.Object.create({
        model: validationModel,
        notificationNamespace: 'onTestModal',
        filePath: 'central-station/api-place-on-test'
      }));
      yield this.multiStepForm.validateList.perform(formattedObjects);

      try {
        yield this.centralStationService.postData({
          model: this.customer,
          method: 'api__placeAccountOnTest',
          payload: {
            start_test_mode_date: this.startTestModeDate.format('YYYY-MM-DD'),
            end_test_mode_date: this.endTestModeDate.format('YYYY-MM-DD'),
            start_test_mode_hour: this.startTestModeTime.format('HH'),
            start_test_mode_minute: this.startTestModeTime.format('mm'),
            end_test_mode_hour: this.endTestModeTime.format('HH'),
            end_test_mode_minute: this.endTestModeTime.format('mm')
          }
        });
        buttonPromise.then(() => {
          this.set('showOnTestModal', false);
          this.notifyPropertyChange('accountOnTestInformation');
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
          this.actions.resetTestProperties.call(this);
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'onTestModal'
        });
        throw err;
      }
    }),
    actions: {
      resetTestProperties() {
        Ember.setProperties(this, {
          startTestModeDate: null,
          startTestModeTime: null,
          endTestModeDate: null,
          endTestModeTime: null
        });
      }

    }
  });

  _exports.default = _default;
});