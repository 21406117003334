define("securitytrax-ember-ui/models/sales-scheduling-profile-config", ["exports", "securitytrax-ember-ui/models/base_models/sales-scheduling-profile-config"], function (_exports, _salesSchedulingProfileConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _salesSchedulingProfileConfig.default.extend({});

  _exports.default = _default;
});