define("securitytrax-ember-ui/components/html/multi-select/building-blocks/single-input-create/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    createValue: '',
    hideFormInput: true,
    formState: Ember.computed('hideFormInput', function () {
      var hideForm = this.hideFormInput;
      return hideForm ? 'hide-form' : 'show-form';
    }),
    submitDisabled: Ember.computed('createValue', function () {
      return !(this.createValue || this.maskInput);
    }),

    init() {
      this._super(...arguments);
    },

    createAction: () => {},
    buttonTask: (0, _emberConcurrency.task)(function* () {
      if (this.createValue != '') {
        var res = yield this.createAction(this.createValue);

        if (res !== false) {
          // normally returns undefined, but if false is sent back, it means the create failed. Keep value in that case.
          Ember.set(this, 'createValue', '');
        } else {
          throw new Error();
        }
      }
    }),
    actions: {
      buttonClickAction() {
        return this.buttonTask.perform();
      },

      toggleForm() {
        this.toggleProperty('hideFormInput');
      }

    }
  });

  _exports.default = _default;
});