define("securitytrax-ember-ui/models/payroll-column-calculation-method-data", ["exports", "securitytrax-ember-ui/models/base_models/payroll-column-calculation-method-data"], function (_exports, _payrollColumnCalculationMethodData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _payrollColumnCalculationMethodData.default.extend({});

  _exports.default = _default;
});