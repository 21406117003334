define("securitytrax-ember-ui/components/modals/request-new-equipment-form/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    equipmentTypes: Ember.computed(function () {
      return this.store.findAll('equipment-type');
    }),
    equipmentSubtypes: Ember.computed('type', function () {
      if (this.type) {
        return this.store.query('equipment-sub-type', {
          filter: "equipment_type = ".concat(this.type.id)
        });
      } else {
        return null;
      }
    }),
    formButtons: Ember.computed(function () {
      var buttons = [{
        text: this.intl.t('common.actions.send'),
        classNamesString: 'st-btn st-btn-primary send',
        click: buttonPromise => {
          return this.sendRequestTask.perform(buttonPromise);
        }
      }];
      return buttons;
    }),
    sendRequestTask: (0, _emberConcurrency.task)(function* (buttonPromise) {
      var formattedObjects = [];
      formattedObjects.push(Ember.Object.create({
        model: {
          equipmentName: this.equipmentName,
          type: this.type,
          subtype: this.subtype,
          manufacturer: this.manufacturer,
          model: this.model,
          url: this.url,
          notes: this.notes,
          contactName: this.userName,
          contactEmail: this.email,
          contactPhone: this.phone
        },
        filePath: 'equipment-request-form'
      }));
      yield this.multiStepForm.validateList.perform(formattedObjects);

      try {
        var company = yield this.sessionData.getCompany();
        yield company.invokeAction('request_new_master_equipment_item', {
          verb: 'POST',
          data: {
            data: {
              attributes: {
                equipment_name: this.equipmentName,
                type: this.type.id,
                sub_type: this.subtype.id,
                manufacturer: this.manufacturer,
                model: this.model,
                url: this.url,
                notes: this.notes,
                contact_name: this.userName,
                contact_email: this.email,
                contact_phone: this.phone
              }
            }
          }
        });
        buttonPromise.then(() => {
          this.set('formStarted', false);
          this.stNotificationManager.addSuccessKey('common.notifications.request_sent', 'modal');
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, 'requestEquipmentModal');
        throw err;
      }
    })
  });

  _exports.default = _default;
});