define("securitytrax-ember-ui/models/user", ["exports", "securitytrax-ember-ui/models/base_models/user", "moment", "ember-concurrency", "ember-data", "securitytrax-ember-ui/mixins/user-reusable", "securitytrax-ember-ui/classes/payee"], function (_exports, _user, _moment, _emberConcurrency, _emberData, _userReusable, _payee) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _user.default.extend(_userReusable.default, {
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    stQueryParams: Ember.inject.service(),
    excludedPayrollRoleIds: undefined,
    inventoryRecipientClass: '100',
    userFiles: Ember.computed.alias('files'),
    payeeDisplayName: Ember.computed.alias('name'),
    globalSearchFirstLine: Ember.computed.alias('lfname'),

    init() {
      this._super(...arguments);

      this.excludedPayrollRoleIds = ['3'];
      this.PayeeClass = new _payee.default({
        stQueryParams: this.stQueryParams,
        store: this.store,
        model: this,
        excludedPayrollRoleIds: this.excludedPayrollRoleIds
      });
    },

    get entityType() {
      return this.PayeeClass.entityType;
    },

    userCanEmulate: Ember.computed('canEmulate', 'isSecurityTraxSuperUser', function () {
      return this.canEmulate || this.isSecurityTraxSuperUser;
    }),
    isSecurityTraxSuperUser: Ember.computed('id', function () {
      return this.id === '10';
    }),
    inboundTransfers: Ember.computed('targetEquipmentTransfers.@each.status', function () {
      return _emberData.default.PromiseArray.create({
        promise: this.targetEquipmentTransfers.then(transfers => {
          return (0, _emberConcurrency.all)(transfers.toArray().filter(transfer => {
            return transfer.get('status') === 'pending';
          }));
        })
      });
    }),
    lfname: Ember.computed('fname', 'lname', function () {
      return "".concat(this.lname || '', ", ").concat(this.fname || '');
    }),
    flname: Ember.computed('fname', 'lname', function () {
      return "".concat(this.fname || '', " ").concat(this.lname || '');
    }),
    outboundTransfers: Ember.computed('sourceEquipmentTransfers.@each.status', function () {
      return _emberData.default.PromiseArray.create({
        promise: this.sourceEquipmentTransfers.then(transfers => {
          return (0, _emberConcurrency.all)(transfers.toArray().filter(transfer => {
            return transfer.get('status') === 'pending';
          }));
        })
      });
    }),
    transferHistory: Ember.computed('targetEquipmentTransfers.@each.{status,updatedAt}', 'sourceEquipmentTransfers.@each.{status,updatedAt}', function () {
      var intl = this.intl;
      this.targetEquipmentTransfers.forEach(transfer => {
        transfer.set('type', intl.t('pages.users.my_inventory.transfers.inbound'));
      });
      this.sourceEquipmentTransfers.forEach(transfer => {
        transfer.set('type', intl.t('pages.users.my_inventory.transfers.outbound'));
      });
      return _emberData.default.PromiseArray.create({
        promise: this.sourceEquipmentTransfers.then(sourceTransfers => {
          var allTransfers = this.targetEquipmentTransfers.toArray().concat(sourceTransfers.toArray());
          return (0, _emberConcurrency.all)(allTransfers.filter(transfer => {
            return transfer.get('status') !== 'pending' && (transfer.get('updatedAt') ? '\'' + (0, _moment.default)(transfer.get('updatedAt')).format('YYYY-MM-DD') + '\'' >= '\'' + (0, _moment.default)().subtract(30, 'days').format('YYYY-MM-DD') + '\'' : true);
          }));
        })
      });
    }),
    inventorySinkNameDescription: Ember.computed('flname', function () {
      return this.name + ' (' + this.intl.t('common.actions.searchOptions.resources.user') + ')';
    }),
    checkedInCalendarEvent: Ember.computed('calendarEvents.@each.{elapsedTimeSinceCheckin}', function () {
      return this.checkedInCalendarEventTask.perform();
    }),
    checkedInCalendarEventTask: (0, _emberConcurrency.task)(function* () {
      var allTasks = [];
      var calendarEvents = this.grants.getIndividualGrant('calendar_events.view') ? yield this.store.query('calendarEvent', {
        filter: "(calendar_category = 'tech_schedule') and (calendar_user = ".concat(this.id, ") and (start_time > ").concat((0, _moment.default)().subtract(24, 'h').format('X'), ")")
      }) : [];
      calendarEvents.forEach(calendarEvent => {
        allTasks.push(this.calendarEventStillCheckedInTask.perform(calendarEvent));
      });
      var allResolved = yield (0, _emberConcurrency.all)(allTasks);
      return allResolved.filter(resolved => {
        return resolved;
      })[0];
    }),
    calendarEventStillCheckedInTask: (0, _emberConcurrency.task)(function* (calendarEvent) {
      var elapsedTimeSinceCheckIn = yield calendarEvent.get('elapsedTimeSinceCheckin');

      if (elapsedTimeSinceCheckIn) {
        return calendarEvent;
      }
    }),
    availablePayrollRoleFunctions: Ember.computed('payablePayeeLevels', function () {
      return this.PayeeClass.availablePayrollRoleFunctionsTask.perform();
    })
  });

  _exports.default = _default;
});