define("securitytrax-ember-ui/customers/view/manage-adt/manage-account/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    init() {
      this._super(...arguments);

      var systemController = this.controllerFor('customers.system');
      systemController.set('callbackEditAdtOrderFormStarted', true);
      systemController.set('adtUpdateFormFirstStep', 'eContracts');
      systemController.set('adtOrderFormMode', 'edit');
    },

    beforeModel() {
      this._super(...arguments);

      var customerId = this.paramsFor('customers').id;
      this.replaceWith("customers.information.manage-adt", customerId);
      return this.findGrantsTask.perform();
    }

  });

  _exports.default = _default;
});