define("securitytrax-ember-ui/components/multi-step-form/forms/customer-equipment/add-equipment/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    userService: Ember.inject.service(),
    intlBase: 'pages.customers.equipment.',
    selectedAddOption: null,
    defaultEquipmentStatus: null,
    isAdtMegaForm: false,
    equipment: null,
    equipmentStatus: null,
    equipmentWireTypes: null,
    packages: null,
    initDataTask: (0, _emberConcurrency.task)(function* () {
      var hashedData = yield (0, _emberConcurrency.hash)({
        finishedCopyingModes: (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true),
        equipment: this.getEquipmentTask.perform(),
        equipmentStatus: this.getEquipmentStatusTask.perform(),
        equipmentWireTypes: this.getEquipmentWireTypesTask.perform(),
        packages: this.getPackagesTask.perform()
      });
      Ember.setProperties(this, hashedData);
    }).on('init'),
    getEquipmentTask: (0, _emberConcurrency.task)(function* () {
      return yield this.store.query('equipment', {
        limit: 0,
        sort: 'name'
      });
    }),
    getEquipmentStatusTask: (0, _emberConcurrency.task)(function* () {
      return yield this.store.findAll('equipmentStatus');
    }),
    getEquipmentWireTypesTask: (0, _emberConcurrency.task)(function* () {
      return yield this.store.findAll('equipmentWireType');
    }),
    getPackagesTask: (0, _emberConcurrency.task)(function* () {
      return yield this.store.findAll('package', {
        include: 'package_equipment,package_equipment.equipment'
      });
    }),
    formComponent: Ember.computed('selectedAddOption', function () {
      var formComponent = 'multi-step-form/forms/customer-equipment/add-equipment/';

      switch (this.selectedAddOption) {
        case 1:
          formComponent = 'multi-step-form/forms/customer-equipment/details';
          break;

        case 2:
          formComponent += 'copy-from-package';
          break;

        case 3:
          formComponent += 'multiple-items';
          break;

        case 4:
          formComponent += 'copy-from-invoice';
          break;
      }

      return formComponent;
    }),
    addEquipmentChoices: Ember.computed('packages.[]', function () {
      var choices = Ember.A([Ember.Object.create({
        packageName: this.intl.t("pages.customers.equipment.add_buttons.single"),
        packageId: 1
      }), Ember.Object.create({
        packageName: this.intl.t("pages.customers.equipment.add_buttons.package"),
        packageId: 2,
        hidePackage: this.packages.get('length') <= 0
      }), Ember.Object.create({
        packageName: this.intl.t('pages.customers.equipment.add_buttons.multiple'),
        packageId: 3
      })]);

      if (!this.isASG) {
        choices.push(Ember.Object.create({
          packageName: this.intl.t('pages.customers.equipment.add_buttons.invoice'),
          packageId: 4
        }));
      }

      if (this.monitoringCompanyType && this.monitoringCompanyType.id === '27') {
        choices = Ember.A([Ember.Object.create({
          packageName: this.intl.t("pages.customers.equipment.add_buttons.single"),
          packageId: 1
        })]);
      }

      return choices;
    }),

    init() {
      this._super(...arguments);

      this.set('defaultEquipmentStatus', this.store.findRecord('equipmentStatus', 1));
      this.set('selectedAddOption', 1);
    }

  });

  _exports.default = _default;
});