define("securitytrax-ember-ui/mixins/customer-create-chooser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Augments consumers with an action for showing the edit devices modal.
   *
   * @extends Ember.Mixin
   */
  var _default = Ember.Mixin.create(
  /** @lends mixins.ModalMixin.prototype **/
  {
    currentStepNameKey: 'step1',
    newCustomer: null,
    workOrder: null,
    editCalendarEvent: null,
    customerCreateFormMode: 'create',
    loadedNamespace: 'customerCreateWizard',
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    dynamicFormChooser: Ember.inject.service('events/dynamic-form-chooser'),

    init() {
      this._super(...arguments);

      this.dynamicFormChooser.on('open-dynamic-form', this, '_dynamicCallback');
    },

    willDestroyElement() {
      this._super(...arguments);

      this.dynamicFormChooser.off('open-dynamic-form', this, '_dynamicCallback');
    },

    _dynamicCallback() {
      this.set('newCustomer', null);
      this.set('currentStepNameKey', 'step1');
    },

    newOrderInformation: Ember.computed('newCustomer', function () {
      var newCustomer = this.newCustomer;
      return newCustomer ? newCustomer.get('customerOrderInformation.model') : null;
    }),
    newCustomerName: Ember.computed('newCustomer', function () {
      var newCustomer = this.newCustomer;
      return newCustomer ? "".concat(newCustomer.get('fname'), " ").concat(newCustomer.get('lname'), " (ID: ").concat(newCustomer.get('id'), ")") : null;
    }),
    createCustomerProgressBar: Ember.computed('newCustomerName', 'createCustomerFormSteps', function () {
      var items = [];
      var formSteps = this.createCustomerFormSteps;
      Object.keys(formSteps).forEach(function (key) {
        items.push({
          title: formSteps[key].progressBarTitle,
          stepNameKey: formSteps[key].stepNameKey,
          isClickable: false,
          hidden: formSteps[key].hidden,
          isComplete: formSteps[key].isComplete
        });
      });
      return {
        activeIndex: this.newCustomerName ? 1 : 0,
        viewTemplate: 'default-view',
        items: items
      };
    }),
    leadFormOriginalModels: Ember.computed('newCustomer', 'grants.userGrants', function () {
      var grants = this.grants;
      return Ember.Object.create({
        customer: {
          model: this.newCustomer,
          copyRelationMap: {
            customerBirthdate: {
              doCopy: grants.getIndividualGrant('lead_birthdates.view') || this.customerCreateFormMode === 'create' || this.get('model.lead.responseMeta.masked_birthdate') === null,
              doValidate: true,
              doSave: grants.getIndividualGrant('lead_birthdates.modify') || this.customerCreateFormMode === 'create' || this.get('model.lead.responseMeta.masked_birthdate') === null
            },
            customerSsn: {
              doCopy: grants.getIndividualGrant('lead_ssns.view') || this.customerCreateFormMode === 'create' || this.get('model.lead.responseMeta.masked_ssn') === '',
              doValidate: grants.getIndividualGrant('lead_ssns.view'),
              doSave: grants.getIndividualGrant('lead_ssns.modify') || this.customerCreateFormMode === 'create' || this.get('model.lead.responseMeta.masked_ssn') === ''
            },
            leadInformation: {
              doCopy: grants.getIndividualGrant('lead_information.view') || this.customerCreateFormMode === 'create',
              doSync: grants.getIndividualGrant('lead_information.view') || this.customerCreateFormMode === 'create',
              doValidate: grants.getIndividualGrant('lead_information.view') || this.customerCreateFormMode === 'create',
              doSave: grants.getIndividualGrant('lead_information.modify') || this.customerCreateFormMode === 'create',
              relationships: {
                leadStatus: {
                  readOnly: true
                },
                monitoringCompany: {
                  readOnly: true
                },
                dealerNumber: {
                  readOnly: true
                }
              }
            },
            leadSourceInformation: {
              doCopy: grants.getIndividualGrant('lead_source_information.view') || this.customerCreateFormMode === 'create',
              doSync: grants.getIndividualGrant('lead_source_information.view') || this.customerCreateFormMode === 'create',
              doValidate: grants.getIndividualGrant('lead_source_information.view') || this.customerCreateFormMode === 'create',
              doSave: grants.getIndividualGrant('lead_source_information.modify') || this.customerCreateFormMode === 'create',
              relationships: {
                leadCompany: {
                  readOnly: true,
                  doCopy: grants.getIndividualGrant('lead_companies.view')
                },
                leadCompanyCampaign: {
                  readOnly: true,
                  doCopy: grants.getIndividualGrant('lead_company_campaigns.view')
                }
              }
            },
            customerCreditCheck: {
              readOnly: true,
              doCopy: grants.getIndividualGrant('customer_credit_checks.view'),
              doValidate: grants.getIndividualGrant('customer_credit_checks.view'),
              doSave: grants.getIndividualGrant('customer_credit_checks.create')
            },
            customerCreditChecks: {
              readOnly: true,
              doCopy: grants.getIndividualGrant('customer_credit_checks.view'),
              doValidate: grants.getIndividualGrant('customer_credit_checks.view'),
              doSave: grants.getIndividualGrant('customer_credit_checks.create')
            }
          }
        },
        customerCreditCheck: {
          model: null,
          requiresOverride: true,
          copyRelationMap: {
            creditBureau: {
              readOnly: true,
              doCopy: true,
              doValidate: true,
              doSave: grants.getIndividualGrant('credit_bureaus.create')
            }
          }
        }
      });
    }),
    formOriginalModels: Ember.computed('newCustomer', 'grants.userGrants', 'workOrder', 'editCalendarEvent', function () {
      var grants = this.grants;
      return Ember.Object.create({
        customer: {
          model: this.newCustomer,
          copyRelationMap: {
            adtPreCheck: {
              readOnly: true
            },
            customerBirthdate: {
              doCopy: grants.getIndividualGrant('customer_birthdates.view') || this.customerCreateFormMode === 'create' || this.get('newCustomer.responseMeta.masked_birthdate') === null,
              doValidate: grants.getIndividualGrant('customer_birthdates.view') || this.customerCreateFormMode === 'create' || this.get('newCustomer.responseMeta.masked_birthdate') === null,
              doSave: grants.getIndividualGrant('customer_birthdates.modify') || this.customerCreateFormMode === 'create' || this.get('newCustomer.responseMeta.masked_birthdate') === null
            },
            location: {
              readOnly: true,
              relationships: {
                dealerNumber: {
                  readOnly: true
                }
              }
            },
            accountClass: {
              readOnly: true
            },
            customerCreditCheck: {
              readOnly: true,
              doCopy: grants.getIndividualGrant('customer_credit_checks.view'),
              doValidate: grants.getIndividualGrant('customer_credit_checks.view'),
              doSave: grants.getIndividualGrant('customer_credit_checks.create')
            },
            customerCreditChecks: {
              readOnly: true,
              doCopy: grants.getIndividualGrant('customer_credit_checks.view'),
              doValidate: grants.getIndividualGrant('customer_credit_checks.view'),
              doSave: grants.getIndividualGrant('customer_credit_checks.create')
            },
            customerSsn: {
              doCopy: grants.getIndividualGrant('customer_ssns.view') || this.customerCreateFormMode === 'create' || this.get('newCustomer.responseMeta.masked_ssn') === '',
              doValidate: grants.getIndividualGrant('customer_ssns.view') || this.customerCreateFormMode === 'create' || this.get('newCustomer.responseMeta.masked_ssn') === '',
              doSave: grants.getIndividualGrant('customer_ssns.modify') || this.customerCreateFormMode === 'create' || this.get('newCustomer.responseMeta.masked_ssn') === ''
            },
            customerSystemInformation: {
              doCopy: grants.getIndividualGrant('customer_system_information.view') || this.customerCreateFormMode === 'create',
              doValidate: grants.getIndividualGrant('customer_system_information.view') || this.customerCreateFormMode === 'create',
              doSave: grants.getIndividualGrant('customer_system_information.modify') || this.customerCreateFormMode === 'create',
              relationships: {
                dealerNumber: {
                  readOnly: true
                },
                customerAbortCode: {
                  readOnly: true
                }
              }
            },
            customerOrderInformation: {
              doCopy: grants.getIndividualGrant('customer_order_information.view') || this.customerCreateFormMode === 'create',
              relationships: {
                activationFee: {
                  readOnly: true
                },
                customerWarranty: {
                  readOnly: true
                },
                discountAffiliate: {
                  readOnly: true
                },
                interactiveServicesProvider: {
                  readOnly: true,
                  relationships: {
                    servicePackages: {
                      readOnly: true
                    }
                  }
                },
                mmr: {
                  readOnly: true
                },
                monitoringCompany: {
                  readOnly: true
                },
                monitoringPlan: {
                  readOnly: true
                },
                repUser: {
                  readOnly: true
                },
                train: {
                  readOnly: true
                },
                contractTerm: {
                  readOnly: true
                }
              }
            },
            leadSourceInformation: {
              doCopy: grants.getIndividualGrant('customer_lead_source_information.view'),
              doSync: grants.getIndividualGrant('customer_lead_source_inforamtion.view'),
              doValidate: grants.getIndividualGrant('customer_lead_source_information.view'),
              doSave: grants.getIndividualGrant('customer_lead_source_information.modify'),
              relationships: {
                leadCompany: {
                  readOnly: true,
                  doCopy: grants.getIndividualGrant('lead_companies.view')
                },
                leadCompanyCampaign: {
                  readOnly: true,
                  doCopy: grants.getIndividualGrant('lead_company_campaigns.view')
                }
              }
            }
          }
        },
        customerCreditCheck: {
          model: null,
          requiresOverride: true,
          copyRelationMap: {
            creditBureau: {
              readOnly: true,
              doCopy: true,
              doValidate: true,
              doSave: grants.getIndividualGrant('credit_bureaus.create')
            }
          }
        },
        workOrder: {
          model: this.workOrder,
          requiresOverride: true,
          copyRelationMap: {
            customer: {
              readOnly: true
            },
            noteType: {
              readOnly: true
            },
            workOrderType: {
              doCopy: true,
              readOnly: true
            },
            assignedUser: {
              doCopy: true,
              readOnly: true
            },
            workOrderEquipment: {
              relationships: {
                customerEquipment: {
                  doCopy: grants.getIndividualGrant('customer_system_information.view') && grants.getIndividualGrant('customer_equipment.view'),
                  readOnly: true
                },
                workOrderAction: {
                  readOnly: true
                }
              }
            }
          }
        },
        calendarEvent: {
          model: this.editCalendarEvent,
          requiresOverride: true,
          copyRelationMap: {
            calendarEventType: {
              doCopy: true,
              doValidate: false,
              doSave: false
            },
            calendarEventDetailType: {
              doCopy: true,
              doValidate: false,
              doSave: false
            },
            calendarUser: {
              doCopy: true,
              doValidate: false,
              doSave: false
            }
          }
        }
      });
    })
  });

  _exports.default = _default;
});