define("securitytrax-ember-ui/components/pages/administration/company-settings/system/upload-tool/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var UploadTool = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._action, (_class = (_temp = class UploadTool extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "bulkActionId", _descriptor2, this);

      _defineProperty(this, "model", undefined);

      _defineProperty(this, "formStarted", false);

      _defineProperty(this, "formFirstStep", 'getStarted');

      _defineProperty(this, "formMode", 'create');
    }

    get formSteps() {
      return Ember.Object.create({
        getStarted: Ember.Object.create({
          stepNameKey: 'getStarted',
          title: this.intl.t('pages.administration.system.uploadTool.update'),
          progressBarTitle: this.intl.t('pages.administration.system.uploadTool.steps.getStarted.title'),
          stepComponentPath: 'multi-step-form/forms/administration/system/upload-tool/get-started'
        }),
        updateFile: Ember.Object.create({
          stepNameKey: 'updateFile',
          title: this.intl.t("pages.administration.system.uploadTool.steps.updateFile.actions.".concat(this.bulkActionId, ".title")),
          progressBarTitle: this.intl.t('pages.administration.system.uploadTool.steps.updateFile.title'),
          stepComponentPath: 'multi-step-form/forms/administration/system/upload-tool/update-file'
        })
      });
    }

    get formOriginalModels() {
      return Ember.Object.create({
        user: {
          model: this.args.model,
          copyRelationMap: {}
        }
      });
    }

    updateBulkActionId(id) {
      this.bulkActionId = id;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "bulkActionId", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateBulkActionId", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "updateBulkActionId"), _class.prototype)), _class));
  _exports.default = UploadTool;
});