define("securitytrax-ember-ui/components/dashboard-config/gallery/section/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var GallerySection = (_dec = Ember.inject.service(), _dec2 = Ember.inject.service(), _dec3 = Ember._tracked, (_class = (_temp = class GallerySection extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "grants", _descriptor2, this);

      _defineProperty(this, "addFormStarted", false);

      _initializerDefineProperty(this, "cardToAdd", _descriptor3, this);
    }

    get addFormSteps() {
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.cardTitle,
          stepComponentPath: 'dashboard-config/canvas/add-dashboard-card'
        })
      });
    }

    get sortedCards() {
      var canUseCards = this.args.editDashboard.getAvailableGalleryCardsList(this.grants, this.args.roles, this.args.showRiskScore, this.args.ispProvTypeId);
      var availableCards = this.args.editDashboard.availableCards;
      var titleDictionary = {};
      canUseCards.forEach(key => {
        if (Object.hasOwnProperty.call(availableCards, key)) {
          var element = availableCards[key];
          titleDictionary[this.calculateCardTitle(element.config)] = key;
        }
      });
      var sorted = {};
      Object.keys(titleDictionary).sort().forEach(element => {
        sorted[titleDictionary[element]] = availableCards[titleDictionary[element]];
      });
      return sorted;
    }

    get cardTitle() {
      if (this.cardToAdd) {
        var config = this.args.editDashboard.availableCards[this.cardToAdd].config;
        return this.calculateCardTitle(config);
      }

      return '';
    }

    calculateCardTitle(config) {
      var titleOverride = config.titleOverride;
      var defaultDateRange = config.default_date_range;
      return titleOverride || this.intl.exists(config.titleKey) ? this.intl.t(config.titleKey, {
        day: config.date_range ? config.date_range.name : null
      }) : null || this.intl.t(this.args.editDashboard.titleKeyPrefix + (this.cardToAdd.replace(/-/g, '_') + this.args.editDashboard.titleKeySuffix), {
        day: defaultDateRange ? defaultDateRange.name : ''
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "cardToAdd", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  })), _class));
  _exports.default = GallerySection;
});