define("securitytrax-ember-ui/components/pages/administration/company-settings/global-settings/company/component", ["exports", "securitytrax-ember-ui/components/pages/administration/company-settings/global-settings/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    companySettingsForm: Ember.computed(function () {
      return Ember.Object.create({
        settings: Ember.Object.create({
          stepNameKey: 'settings',
          title: 'company settings',
          stepComponentPath: 'multi-step-form/forms/administration/system/global-settings/edit-company'
        })
      });
    }),
    companyPhotoForm: Ember.computed(function () {
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: 'company logo',
          stepComponentPath: 'multi-step-form/forms/administration/system/global-settings/edit-logo'
        })
      });
    }),
    formOriginalModels: Ember.computed('company', function () {
      return Ember.Object.create({
        company: {
          model: this.company,
          copyRelationMap: {//
          }
        },
        globalSetting: {
          model: this.globalSettings,
          copyRelationMap: {//
          }
        }
      });
    })
  });

  _exports.default = _default;
});