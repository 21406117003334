define("securitytrax-ember-ui/models/base_models/recurring-invoice", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    entityType: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['customer']
    }),
    nextDate: (0, _model.attr)('date-only', {
      'readOnly': true
    }),
    lastDate: (0, _model.attr)('date-only', {
      'readOnly': true
    }),
    recurringIntervalNumber: (0, _model.attr)('number', {
      'readOnly': false
    }),
    recurringIntervalPeriod: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['days', 'weeks', 'months', 'years']
    }),
    numTimesToRecur: (0, _model.attr)('number', {
      'readOnly': false
    }),
    status: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['active', 'deleted']
    }),
    sendOnGeneration: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    sendOnAttemptedProcessing: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    taxOverride: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    recurringEmail: (0, _model.attr)('string', {
      'readOnly': false
    }),
    defaultMessage: (0, _model.attr)('string', {
      'readOnly': false
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    saleDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    poNumber: (0, _model.attr)('string', {
      'readOnly': false
    }),
    internalNote: (0, _model.attr)('string', {
      'readOnly': false
    }),
    externalNote: (0, _model.attr)('string', {
      'readOnly': false
    }),
    mailingAddress: (0, _model.attr)('string', {
      'readOnly': false
    }),
    mailingName: (0, _model.attr)('string', {
      'readOnly': false
    }),
    invoiceType: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['recurring_invoice']
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    customer: (0, _model.belongsTo)('customer', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    soldByUser: (0, _model.belongsTo)('user', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    customerBilling: (0, _model.belongsTo)('customer-billing', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    invoiceTerm: (0, _model.belongsTo)('invoice-term', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    invoiceLineItems: (0, _model.hasMany)('invoice-line-item', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'serialize': true,
      'stEmbedded': true
    }),
    invoices: (0, _model.hasMany)('invoice', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    invoiceEmails: (0, _model.hasMany)('invoice-email', {
      'readOnly': true,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});