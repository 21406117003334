define("securitytrax-ember-ui/validations/invoices/send-invoice", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      _validate.default.validators.emailList = function (value) {
        var emails = value.split(',').map(email => email.trim());
        var invalidEmails = [];
        emails.forEach(email => {
          if (!email.trim().match(/.*@.*\..*/)) {
            invalidEmails.push(email);
          }
        });

        if (invalidEmails.length > 0) {
          return data.intlService.t('common.validations.emailList', {
            'invalidEmails': invalidEmails.join(', ')
          });
        }

        return null;
      };

      var validateObject = {
        recipients: {
          presence: this.customMessageOrDefault(data, 'invoice-recipients'),
          emailList: () => {
            if (this.recipients) {
              return this.customMessageOrDefault(data, 'invoice-recipients');
            } else {
              return false;
            }
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      return this.outputFormatting(validationData, 'invoice');
    }

  });

  _exports.default = _default;
});