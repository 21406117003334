define("securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/smart-home-pay-los/smart-home-pay-los-base", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/create-financing-base", "ember-concurrency"], function (_exports, _createFinancingBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var SmartHomePayLosBase = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = (0, _emberConcurrency.task)(function* () {//To be overwritten by children and called on refresh
  }), _dec10 = (0, _emberConcurrency.task)(function* () {
    try {
      yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
      var childTasks = [];
      childTasks.push(this.syncApplicationTask.perform());
      childTasks.push(this.initPendingTasksTask.perform());
      yield (0, _emberConcurrency.all)(childTasks);
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
    }
  }), _dec11 = (0, _emberConcurrency.task)(function* () {
    try {
      yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
      var application = yield this.store.findRecord('consumerFinancingApplication', this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.id'));
      this.getStatusResponse = (yield application.invokeAction('get_status', {
        verb: 'POST',
        data: {
          data: {}
        }
      })).responsePayload.data;
      this.args.customer.get('consumerFinancingApplications').reload();
      this.environment = application.get('consumerFinancingLender.consumerFinancingLenderConfig.environment');
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
    }
  }), _dec12 = (0, _emberConcurrency.task)(function* () {
    try {
      yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
      var application = yield this.store.findRecord('consumerFinancingApplication', this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.id'));
      var getTasksResult = (yield application.invokeAction('get_tasks', {
        verb: 'POST',
        data: {
          data: {
            stage: this.args.multiStepForm.currentStepNameKey
          }
        }
      })).responsePayload.data;
      this.pendingTasks = getTasksResult['pending_tasks'];
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
    }
  }), _dec13 = (0, _emberConcurrency.task)(function* () {
    try {
      yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
      var application = yield this.store.findRecord('consumerFinancingApplication', this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.id'));
      yield application.invokeAction('terminate_application', {
        verb: 'POST',
        data: {
          data: {}
        }
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
    }

    this.stNotificationManager.addSuccess(this.intl.t('pages.customers.accounting.financing.application_terminated'), 'global');
    this.args.multiStepForm.formStartedSetVal(false);
  }), _dec14 = (0, _emberConcurrency.task)(function* () {
    this.initDataTask.perform();
    this.initFormTask.perform();
    yield (0, _emberConcurrency.waitForProperty)(this.initDataTask, 'isIdle', true);
    yield (0, _emberConcurrency.waitForProperty)(this.initFormTask, 'isIdle', true);

    if (this.getStatusResponse['get_application_status']['data']['attributes']['application_stage'] === 'terminated') {
      this.args.multiStepForm.formStartedSetVal(false);
      this.stNotificationManager.addErrorMessages(this.intl.t('pages.customers.accounting.financing.application_terminated'), {
        notificationNamespace: 'global'
      });
    }

    this.args.multiStepForm.formCopiedModelsSetVal('consumerFinancingApplication.model.lenderApplicationState', this.getStatusResponse['get_application_status']['data']['attributes']['application_stage']);
    this.args.multiStepForm.progressBarConfig = this.progressBarConfig;
  }), _dec15 = Ember._action, _dec16 = Ember._action, (_class = (_temp = class SmartHomePayLosBase extends _createFinancingBase.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "grants", _descriptor4, this);

      _initializerDefineProperty(this, "environment", _descriptor5, this);

      _initializerDefineProperty(this, "pendingTasks", _descriptor6, this);

      _initializerDefineProperty(this, "openModal", _descriptor7, this);

      _initializerDefineProperty(this, "getStatusResponse", _descriptor8, this);

      _initializerDefineProperty(this, "initFormTask", _descriptor9, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor10, this);

      _initializerDefineProperty(this, "syncApplicationTask", _descriptor11, this);

      _initializerDefineProperty(this, "initPendingTasksTask", _descriptor12, this);

      _initializerDefineProperty(this, "terminateApplicationTask", _descriptor13, this);

      _initializerDefineProperty(this, "refreshTask", _descriptor14, this);

      this.initDataTask.perform();
    }

    get merchantPortalUrl() {
      var base_url = null;

      switch (this.environment) {
        case 'development':
          base_url = 'https://test.apply.smarthomepay.com/mp/applications/';
          break;

        case 'staging':
          base_url = 'https://staging.apply.smarthomepay.com/mp/applications/';
          break;

        case 'production':
          base_url = 'https://apply.smarthomepay.com/mp/applications/';
          break;

        default:
          base_url = 'https://apply.smarthomepay.com/mp/applications/';
          break;
      }

      return base_url + this.args.multiStepForm.formCopiedModels.consumerFinancingApplication.model.lenderApplicationId;
    }

    get progressBarConfig() {
      var _this$args$multiStepF;

      var items = [];
      var multiStepForm = this.args.multiStepForm;
      var formSteps = this.args.multiStepForm.formSteps;
      var currentStep = this.args.multiStepForm.currentStepNameKey;
      var applicationStep = (_this$args$multiStepF = this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.lenderApplicationState')) !== null && _this$args$multiStepF !== void 0 ? _this$args$multiStepF : 'pending';
      Object.keys(formSteps).forEach(key => {
        var isHidden = this.hiddenProgressBarSteps.includes(key) || formSteps[key].granted != null && !formSteps[key].granted;
        var isComplete = formSteps[key].stepNameKey === currentStep ? false : true;
        items.push({
          title: formSteps[key].progressBarTitle,
          stepNameKey: formSteps[key].stepNameKey,
          isClickable: !items.find(item => item.stepNameKey === applicationStep) || applicationStep === formSteps[key].stepNameKey || currentStep === formSteps[key].stepNameKey,
          hidden: isHidden,
          isComplete: isComplete,

          clickAction(item) {
            multiStepForm.currentStepNameKeySetVal(item.stepNameKey);
          }

        });
      });
      return {
        hideProgressBar: this.progressBarHidden,
        activeIndex: items.findIndex(item => item.stepNameKey === currentStep),
        viewTemplate: 'unordered-view',
        items: items
      };
    }

    onConfirm() {
      this.terminateApplicationTask.perform();
    }

    buttons() {
      var buttons = [];
      buttons.push({
        text: this.intl.t('common.actions.terminate'),
        click: () => {
          this.openModal = true;
        },
        isDelete: true,
        //To put the button on the left side of modal
        classNamesString: 'st-btn st-btn-warn'
      });
      this.args.multiStepForm.registerStepData(this.args.multiStepForm.currentStepNameKey, 'buttons', buttons);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "environment", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'production';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "pendingTasks", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "openModal", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "getStatusResponse", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "initFormTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "syncApplicationTask", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "initPendingTasksTask", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "terminateApplicationTask", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "refreshTask", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onConfirm", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "onConfirm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buttons", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "buttons"), _class.prototype)), _class));
  _exports.default = SmartHomePayLosBase;
});