define("securitytrax-ember-ui/models/customer-billing-bill-cc-number", ["exports", "securitytrax-ember-ui/models/base_models/customer-billing-bill-cc-number"], function (_exports, _customerBillingBillCcNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerBillingBillCcNumber.default.extend({
    /**
     * The last four digits of the billing credit card number (if present).
     *
     * @function
     * @returns {String}
     */
    lastFour: Ember.computed('billCcNumber', function () {
      return String(this.billCcNumber || '').slice(-4);
    })
  });

  _exports.default = _default;
});