define("securitytrax-ember-ui/components/multi-step-form/forms/system/adt/steps/rate-adjustment/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/system/adt/adt-form-base", "ember-concurrency", "securitytrax-ember-ui/mixins/forms/adt"], function (_exports, _adtFormBase, _emberConcurrency, _adt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adtFormBase.default.extend(_adt.default, {
    centralStationService: Ember.inject.service(),
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    grants: Ember.inject.service(),
    setupFormTask: (0, _emberConcurrency.task)(function* () {
      var centralStationService = this.centralStationService;
      this.set('adtData', yield this.adtData);
      var rateAdjustmentData = yield centralStationService.getData({
        model: this.customer,
        method: 'adt__getRateAdjustmentData',
        responseKey: 'rate_adjustment_data'
      });
      Ember.set(rateAdjustmentData, 'full_packet', false);
      this.set('rateAdjustmentData', rateAdjustmentData);
      var multiStepForm = this.multiStepForm;
      yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);
      this.set('adtStepReady', true);
    }).on('init'),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('rateAdjustment', 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.saveAdtRateAdjustmentMegaForm.perform(multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn-primary'
      }]);
    },

    saveAdtRateAdjustmentMegaForm: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      this.set('adtStepReady', false);

      try {
        var grants = yield this.grants;
        yield multiStepForm.validateSyncSaveAll({
          notificationNamespace: 'adtMegaFormModal',
          extraValidationData: {
            adtValidation: true,
            noValidate: ['fname', 'lname', 'primaryPhone', 'city', 'location', 'saleDate', 'trackingPin', 'orderInformation-customerCancelDate', 'orderInformation-salesRep', 'creditTracking', 'businessName', 'businessContact']
          },
          overrideOriginalModelsConfig: Ember.Object.create({
            customer: {
              doCopy: grants.getIndividualGrant('customers.view'),
              doValidate: grants.getIndividualGrant('customers.view'),
              doSave: grants.getIndividualGrant('customers.view'),
              copyRelationMap: {
                defaultCustomerBilling: {
                  doCopy: grants.getIndividualGrant('customer_billings.view'),
                  doValidate: false,
                  doSave: grants.getIndividualGrant('customer_billings.view'),
                  relationships: {
                    billCycle: {
                      readOnly: true
                    }
                  }
                },
                customerSystemInformation: {
                  doCopy: grants.getIndividualGrant('customer_system_information.view'),
                  doValidate: grants.getIndividualGrant('customer_system_information.view'),
                  doSave: grants.getIndividualGrant('customer_system_information.view')
                },
                customerBillings: {
                  readOnly: true,
                  doCopy: grants.getIndividualGrant('customer_billings.view'),
                  relationships: {
                    customerBillingUses: {
                      readOnly: true
                    }
                  }
                },
                customerOrderInformation: {
                  doCopy: grants.getIndividualGrant('customer_order_information.view'),
                  doValidate: grants.getIndividualGrant('customer_order_information.view'),
                  doSave: grants.getIndividualGrant('customer_order_information.view'),
                  relationships: {
                    contractTerm: {
                      readOnly: true
                    },
                    activationFee: {
                      readOnly: true
                    },
                    monitoringPlan: {
                      readOnly: true
                    },
                    mmr: {
                      readOnly: true
                    }
                  }
                }
              }
            }
          })
        });
      } catch (err) {
        this.set('adtStepReady', true);
        throw err;
      }

      try {
        var centralStationService = this.centralStationService;
        var messages = [];
        var hashData = yield (0, _emberConcurrency.hash)({
          customer: this.customer,
          rateAdjustment: this.rateAdjustmentData,
          adtData: this.adtData
        });
        var response = yield centralStationService.postData({
          model: hashData.customer,
          method: 'adt__saveRateAdjustmentData',
          payload: {
            credit_holder_authentication_number: hashData.rateAdjustment.credit_holder_authentication_number,
            equipment_panel: hashData.rateAdjustment.equipment_panel,
            radio_primary: hashData.rateAdjustment.radio_primary,
            radio_type: hashData.rateAdjustment.radio_type,
            add_on_services: hashData.rateAdjustment.add_on_services // ny: {
            //     estimated_completion_date: //"string",
            //     deposit_account: //"string",
            //     down_payment: //"string",
            //     amount_due: //"string"
            // }

          }
        });
        messages = messages.concat(response.responsePayload.data.messages);
        yield this.refreshCustomerAdtStatus.perform(hashData.customer, hashData.adtData);
        buttonPromise.then(() => {
          if (this.isNewRecord) {
            this.stNotificationManager.addSuccess(this.intl.t('common.notifications.save_successful'));
            multiStepForm.formStartedSetVal(false);

            if (this.transitionBackToParent && typeof this.transitionBackToParent === 'function') {
              this.transitionBackToParent();
            }
          } else {
            this.stNotificationManager.addSuccess(this.intl.t('common.notifications.save_successful'), 'adtMegaFormModal');
            multiStepForm.setupCopyModels.perform();
          }

          if (messages.length > 0) {
            this.stNotificationManager.addWarningMessages(messages, {
              notificationNamespace: 'adtMegaFormModal'
            });
          }

          this.set('adtStepReady', true);
        });
      } catch (err) {
        this.set('adtStepReady', true);
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'adtMegaFormModal'
        });
        (false && Ember.warn('mega form save error for cleanup in' + err, {
          id: 'multiStepsForm.forms.system.adt.rateAdjustment.save'
        }));
        throw err;
      }

      this.set('adtStepReady', true);
    })
  });

  _exports.default = _default;
});