define("securitytrax-ember-ui/models/template", ["exports", "securitytrax-ember-ui/models/base_models/template", "ember-concurrency", "lodash"], function (_exports, _template, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _template.default.extend({
    sortedTemplateFieldDefaults: Ember.computed('templateFieldDefaults.[]', function () {
      return this.sortedTemplateFieldDefaultsTask.perform();
    }),
    sortedTemplateFieldDefaultsTask: (0, _emberConcurrency.task)(function* () {
      var templateType = yield this.templateType;
      var templateTypeColumns = yield templateType.get('templateTypeColumns');
      var tempFieldDefaults = yield this.templateFieldDefaults;

      var fieldDefaults = _lodash.default.sortBy(tempFieldDefaults.toArray(), [function (o) {
        return Number(o.get('templateTypeColumn.id'));
      }]);

      for (var i = 1; i <= templateTypeColumns.get('length'); i++) {
        var index = templateTypeColumns.objectAt(i - 1).get('id');
        var templateDefault = yield fieldDefaults.objectAt(i - 1);
        var templateTypeColumn = templateDefault ? yield templateDefault.get('templateTypeColumn') : null;

        if (templateTypeColumn === null || templateTypeColumn.get('id') !== index.toString()) {
          fieldDefaults.splice(i - 1, 0, Ember.Object.create({
            value: null,
            active: true,
            templateTypeColumn: Ember.Object.create({
              id: index.toString()
            })
          }));
        }
      }

      return fieldDefaults;
    })
  });

  _exports.default = _default;
});