define("securitytrax-ember-ui/not-found/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    path: '',
    notFoundDescription: Ember.computed(function () {
      return this.intl.t('pages.notFound.description', {
        path: this.path
      });
    })
  });

  _exports.default = _default;
});