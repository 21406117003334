define("securitytrax-ember-ui/components/multi-step-form/forms/administration/equipment/edit-vendor/general/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms/administration/equipment/edit-vendor/base-component", "securitytrax-ember-ui/mixins/switchable-form-step"], function (_exports, _emberConcurrency, _baseComponent, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    confirmDeleteModalOpen: false,
    progressBarHidden: Ember.computed('isNewRecord', function () {
      return this.isNewRecord;
    }),
    initFormData: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = this.multiStepForm;
      yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);

      if (this.isNewRecord) {
        this.multiStepForm.formCopiedModelsSetVal('equipmentVendor.model.active', true);
      }
    }).on('init'),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      var buttons = [];

      if (this.formMode !== 'view') {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveEquipmentVendor.perform(multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary equipment-vendor-save-button'
        });
      }

      if (!this.isNewRecord && this.grants.getIndividualGrant('equipment_vendors.delete')) {
        buttons.push({
          text: this.intl.t('common.actions.delete'),
          click: () => {
            this.set('confirmDeleteModalOpen', true);
          },
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
        });
      }

      multiStepForm.registerStepData('general', 'buttons', buttons);
    },

    deleteEquipmentVendor: (0, _emberConcurrency.task)(function* () {
      var equipmentVendor = this.multiStepForm.formOriginalModelsGetVal('equipmentVendor.model');

      try {
        yield equipmentVendor.destroyRecordWithRollbackTask.perform();
        this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
        this.multiStepForm.onDelete();
        this.multiStepForm.formStartedSetVal(false);
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'confirmDeleteModal'
        });
        throw err;
      }
    })
  });

  _exports.default = _default;
});