define("securitytrax-ember-ui/components/html/st-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4/Bwc26u",
    "block": "{\"symbols\":[],\"statements\":[[10,\"input\"],[15,3,[34,0]],[14,\"toggleSwitch\",\"toggleSwitch\"],[15,\"checked\",[34,1]],[15,\"disabled\",[34,2]],[15,\"readonly\",[34,2]],[15,\"onchange\",[30,[36,3],[[32,0],\"toggle\"],null]],[14,4,\"checkbox\"],[12],[13],[2,\"\\n\"],[10,\"span\"],[15,0,[31,[\"slider round \",[34,4]]]],[15,\"onClick\",[30,[36,3],[[32,0],\"toggle\"],null]],[12],[13]],\"hasEval\":false,\"upvars\":[\"name\",\"checked\",\"disabled\",\"action\",\"modified\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/st-toggle/template.hbs"
    }
  });

  _exports.default = _default;
});