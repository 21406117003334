define("securitytrax-ember-ui/models/base_models/credit-bureau-config", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    template: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['development', 'production']
    }),
    market: (0, _model.attr)('string', {
      'readOnly': false
    }),
    submarket: (0, _model.attr)('string', {
      'readOnly': false
    }),
    industryCode: (0, _model.attr)('string', {
      'readOnly': false
    }),
    memberCode: (0, _model.attr)('string', {
      'readOnly': false
    }),
    password: (0, _model.attr)('string', {
      'readOnly': false
    }),
    addon: (0, _model.attr)('string', {
      'readOnly': false
    }),
    productCode: (0, _model.attr)('string', {
      'readOnly': false
    }),
    customerNumber: (0, _model.attr)('string', {
      'readOnly': false
    }),
    securityCode: (0, _model.attr)('string', {
      'readOnly': false
    }),
    model: (0, _model.attr)('string', {
      'readOnly': false
    }),
    serviceName: (0, _model.attr)('string', {
      'readOnly': false
    }),
    includePrintImage: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['Y', 'N']
    }),
    transactionPin: (0, _model.attr)('string', {
      'readOnly': false
    }),
    environment: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['development', 'production']
    }),
    fieldsAccount: (0, _model.attr)('string', {
      'readOnly': false
    }),
    fieldsPasswd: (0, _model.attr)('string', {
      'readOnly': false
    }),
    fieldsProduct: (0, _model.attr)('string', {
      'readOnly': false
    }),
    fieldsBureau: (0, _model.attr)('string', {
      'readOnly': false
    }),
    fieldsXpnkeyword: (0, _model.attr)('string', {
      'readOnly': false
    }),
    fieldsTurisk: (0, _model.attr)('string', {
      'readOnly': false
    }),
    monitoringCompanyId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    dealernumber: (0, _model.attr)('string', {
      'readOnly': false
    }),
    language: (0, _model.attr)('string', {
      'readOnly': false
    }),
    username: (0, _model.attr)('string', {
      'readOnly': false
    }),
    fieldsDealernumber: (0, _model.attr)('string', {
      'readOnly': false
    })
  });

  _exports.default = _default;
});