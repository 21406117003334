define("securitytrax-ember-ui/components/customer/accounting/payables-list/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/utils/obj-helpers", "securitytrax-ember-ui/mixins/table-column-sort"], function (_exports, _emberConcurrency, _objHelpers, _tableColumnSort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableColumnSort.default, {
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    tagsService: Ember.inject.service(),
    payableEvents: Ember.inject.service('events/payable-events'),
    customerPayable: null,
    customerPayableFormMode: 'create',
    customerPayableFormStarted: false,
    stSortCurrentSortColumn: 'id',
    stSortAscending: false,
    usesModal: true,

    init() {
      this._super(...arguments);

      var intl = this.intl;
      (0, _objHelpers.initializeState)(this, {
        stSortableColumnOptions: [{
          name: intl.t('common.columns.id') + ' ' + intl.t('common.sorting.ascending'),
          value: 'id',
          chevronDirection: 'up'
        }, {
          name: intl.t('common.columns.id') + ' ' + intl.t('common.sorting.descending'),
          value: '-id',
          chevronDirection: 'down'
        }]
      });
      this.payableEvents.on('refresh-list', this, '_callback');
    },

    _callback() {
      Ember.notifyPropertyChange(this, 'customerPayables');
    },

    willDestroy() {
      this.payableEvents.off('refresh-list', this, '_callback');
    },

    customerPayablesFiltered: Ember.computed('customerPayables', 'stSortCurrentSortColumn', 'stSortAscending', function () {
      return this.customerPayablesTask.perform();
    }),
    customerPayablesTask: (0, _emberConcurrency.task)(function* () {
      var payables = (yield this.get('customerPayables')).filter(payable => {
        return payable.get('status') !== 'pending';
      });

      if (this.stSortAscending) {
        return payables.sortBy(this.stSortCurrentSortColumn);
      } else {
        return payables.sortBy(this.stSortCurrentSortColumn).reverse();
      }
    }),
    actions: {
      transitionToSubroute(path) {
        this.router.transitionTo('customers.accounting.payments-payables.edit-create-payable', path);
      },

      //action sent to sortable-column component
      sortList(column, ascending) {
        this.sortPaginatedList(column, ascending, 'recordsLoaded');
      },

      mobileSortList(column) {
        this.sortMobilePaginatedList(column.value, 'recordsLoaded');
        this.set('stSortAscending', !this.stSortString.includes('-'));
        this.set('stSortCurrentSortColumn', this.stSortString.replace('-', ''));
      }

    }
  });

  _exports.default = _default;
});