define("securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-payables/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    quickAdd: Ember.inject.service(),
    payableEvents: Ember.inject.service('events/payable-events'),
    payable: null,
    pendingPayable: null,
    customer: null,
    formStarted: false,
    payeeDisplayName: undefined,
    initData: (0, _emberConcurrency.task)(function* () {
      if (this.payableId !== 'create') {
        var payable = yield this.store.findRecord('customerPayable', this.payableId);
        var entity = yield payable.get('entity');
        Ember.set(this, 'payable', payable);
        Ember.set(this, 'payeeDisplayName', yield entity.get('payeeDisplayName'));
        Ember.set(this, 'customer', this.customer ? this.customer : yield payable.get('customer'));
      }

      Ember.set(this, 'formStarted', true);
    }).on('init'),
    form: Ember.computed('payable', 'customer', function () {
      var translationKey = !this.payable ? 'pages.customers.accounting.payables.create_title' : 'pages.customers.accounting.payables.edit_title';
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.intl.t(translationKey, {
            userName: this.payeeDisplayName,
            customerName: this.get('customer.flname')
          }),
          stepComponentPath: 'multi-step-form/forms/payables/edit-create-payable'
        })
      });
    }),
    formMode: Ember.computed(function () {
      if (this.grants.getIndividualGrant('customer_payables.create') && this.payableId === 'create') {
        return 'create';
      } else if (this.grants.getIndividualGrant('customer_payables.modify') && this.payableId !== 'create') {
        return 'edit';
      } else {
        return 'view';
      }
    }),
    formOriginalModels: Ember.computed('payable', 'grants.userGrants', function () {
      var grants = this.grants;
      return Ember.Object.create({
        customerPayable: {
          model: this.payable,
          copyRelationMap: {
            userEntity: {
              readOnly: true
            },
            leadCompanyEntity: {
              readOnly: true
            },
            leadCompanyCampaignEntity: {
              readOnly: true
            },
            governmentRegulationEntity: {
              readOnly: true
            },
            customerEntity: {
              readOnly: true
            },
            payrollPaymentStatus: {
              readOnly: true
            },
            payrollRoleFunction: {
              readOnly: true
            },
            payrollRoleFunctionLevel: {
              readOnly: true
            },
            customerPayableLineItems: {
              doCopy: grants.getIndividualGrant('customer_payable_line_items.view'),
              doSync: grants.getIndividualGrant('customer_payable_line_items.view'),
              doSave: grants.getIndividualGrant('customer_payable_line_items.modify'),
              relationships: {
                payrollColumn: {
                  readOnly: true
                }
              }
            }
          }
        }
      });
    }),
    transitionBackToParentRouteTask: (0, _emberConcurrency.task)(function* () {
      if (this.pendingPayable && this.pendingPayable.status == 'pending') {
        yield this.pendingPayable.unloadRecord();
      }

      var transitionToRoute = this.quickAdd.closeRoute || this.parentRoute;

      if (transitionToRoute.includes('customer')) {
        this.router.transitionTo(transitionToRoute, this.get('customer.id'));
      } else {
        this.router.transitionTo(transitionToRoute);
      }
    }),
    actions: {
      transitionBackToParentRoute() {
        return this.transitionBackToParentRouteTask.perform();
      },

      setPendingPayable(pendingPayable) {
        Ember.set(this, 'pendingPayable', pendingPayable);
      },

      refreshModel() {
        this.payableEvents.trigger('refresh-list');
      }

    }
  });

  _exports.default = _default;
});