define("securitytrax-ember-ui/components/multi-step-form/progress-bar/views/unordered-view/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    screen: Ember.inject.service(),
    collapsed: true,
    isCollapsed: Ember.computed('collapsed', function () {
      return !this.collapsed;
    }),
    actions: {
      itemClass(index) {
        var activeIndex = this.get('config.activeIndex');
        return activeIndex == index ? 'selected' : '';
      },

      triggerNavBar() {
        this.toggleProperty('collapsed');
      }

    }
  });

  _exports.default = _default;
});