define("securitytrax-ember-ui/components/multi-step-form/list-buttons/component", ["exports", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    loadedNamespace: undefined,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        loadedNamespace: 'global'
      });
    },

    didReceiveAttrs() {
      if (!this.buttons) {
        throw new Error('You must pass buttons into the list-buttons component');
      }
    }

  });

  _exports.default = _default;
});