define("securitytrax-ember-ui/models/payroll-function", ["exports", "securitytrax-ember-ui/models/base_models/payroll-function"], function (_exports, _payrollFunction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _payrollFunction.default.extend({
    intl: Ember.inject.service(),
    name: Ember.computed('id', function () {
      return this.intl.t('pages.administration.human_resources.' + this.id);
    })
  });

  _exports.default = _default;
});