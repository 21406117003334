define("securitytrax-ember-ui/components/dashboard-config/canvas/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var Canvas = (_dec = Ember.inject.service('sessionData'), _dec2 = Ember.inject.service('store'), _dec3 = Ember.inject.service('events/homeDashboard'), _dec4 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var emberCards = this.args.userCards;
    var canvasCards = emberCards.filter(card => {
      return card.get('location') == this.args.editDashboard.dashboardLocation;
    });
    canvasCards[0].cardData = this.args.editDashboard.dashboardCards[0].cardData;
    yield emberCards.save();
    buttonPromise.then(() => {
      this.homeDashboard.trigger('dashboard-config-saved', this.args.editDashboard.dashboardLocation);
      Ember.set(this.args.editDashboard, 'showConfig', false);
    });
  }), _dec5 = Ember._action, (_class = (_temp = class Canvas extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "sessionData", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "homeDashboard", _descriptor3, this);

      _defineProperty(this, "dashboardCard", undefined);

      _initializerDefineProperty(this, "saveConfig", _descriptor4, this);

      this.homeDashboard.on('dashboard-config-update', this, '_eventCallback');
    }

    willDestroy() {
      this.homeDashboard.off('dashboard-config-update', this, '_eventCallback');
    }

    _eventCallback(dashboardLocation) {
      if (dashboardLocation === this.args.editDashboard.dashboardLocation) {
        Ember.notifyPropertyChange(this, 'canvasSections');
        Ember.notifyPropertyChange(this.args.editDashboard, 'addedCards');
      }
    }

    get canvasSections() {
      // Is this the place to redo the addedCards on a cancel/save
      return this.args.editDashboard.sectionsTask.perform();
    }

    cancelSort() {
      this.args.editDashboard.toggleConfig();
      this.homeDashboard.trigger('dashboard-config-rollback', this.args.editDashboard.dashboardLocation);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "homeDashboard", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "saveConfig", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "cancelSort", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "cancelSort"), _class.prototype)), _class));
  _exports.default = Canvas;
});