define("securitytrax-ember-ui/components/multi-step-form/forms/administration/inventory/edit-create-rma/edit-rma/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/switchable-form-step"], function (_exports, _emberConcurrency, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    grants: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    inventoryService: Ember.inject.service(),
    company: undefined,
    recipient: null,
    equipment: null,
    equipmentToVendors: null,
    equipmentReturnReasons: null,
    recipients: undefined,
    vendorOptions: null,
    rmaLineItemDeleteId: false,
    rmaStatus: 'open',
    dataLoaded: false,
    isFromPending: false,
    pendingEquipmentLineItems: null,
    confirmCloseModalOpen: false,
    formStarted: false,
    totalItemCount: Ember.computed('pendingEquipmentLineItems.@each.justReceived', function () {
      var count = 0;

      if (this.pendingEquipmentLineItems) {
        this.pendingEquipmentLineItems.forEach(item => {
          if (item.justReceived) {
            count += parseInt(item.justReceived);
          }
        });
      }

      return count;
    }),
    totalPreviouslyReceived: Ember.computed(function () {
      var count = 0;

      if (this.pendingEquipmentLineItems) {
        this.pendingEquipmentLineItems.forEach(item => {
          if (item.totalReceived) {
            count += parseInt(item.totalReceived);
          }
        });
      }

      return count;
    }),
    totalQuantity: Ember.computed('pendingEquipmentLineItems.@each.quantity', function () {
      var count = 0;

      if (this.pendingEquipmentLineItems) {
        this.pendingEquipmentLineItems.forEach(item => {
          if (item.quantity) {
            count += parseInt(item.quantity);
          }
        });
      }

      return count;
    }),
    totalTotalCost: Ember.computed(function () {
      var count = 0;

      if (this.pendingEquipmentLineItems) {
        this.pendingEquipmentLineItems.forEach(item => {
          if (item.totalCost) {
            count += parseFloat(item.totalCost);
          }
        });
      }

      return count;
    }),
    viewOnly: Ember.computed('rmaStatus', function () {
      return this.rmaStatus === 'closed';
    }),
    detailsFormMode: Ember.computed('rmaStatus', function () {
      if (this.rmaStatus == 'closed') {
        return 'view';
      }

      return 'edit';
    }),
    formSteps: Ember.computed('formMode', 'multiStepForm.formCopiedModels.equipmentReturnMerchandiseAuthorization.model.equipmentVendor.id', 'recipient.id', function () {
      var intl = this.intl;
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: intl.t("pages.administration.company_settings.inventory.rma.review_title", {
            recipient: this.get('recipient.fname') ? this.get('recipient.fname') + ' ' + this.get('recipient.lname') : this.get('recipient.name'),
            vendor: this.multiStepForm.formCopiedModelsGetVal('equipmentReturnMerchandiseAuthorization.model.equipmentVendor.name')
          }),
          stepComponentPath: 'multi-step-form/forms/administration/inventory/edit-create-rma/review'
        })
      });
    }),
    initFormData: (0, _emberConcurrency.task)(function* () {
      var hashedData = yield (0, _emberConcurrency.hash)({
        company: this.sessionData.getCompany(),
        waitForFinishedCopyModels: (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels'),
        recipients: this.inventoryService.get('getInventoryRecipients').perform(),
        equipmentReturnReasons: yield this.store.findAll('equipmentReturnReason'),
        vendors: this.vendors
      });
      this.set('rmaStatus', this.multiStepForm.formCopiedModelsGetVal('equipmentReturnMerchandiseAuthorization.model.status'));
      var copiedModel = this.multiStepForm.formCopiedModelsGetVal('equipmentReturnMerchandiseAuthorization.model');
      this.set('recipient', this.isFromPending ? copiedModel.get('recipient') : copiedModel.get('user') ? copiedModel.get('user') : copiedModel.get('location'));
      this.set('equipmentReturnReasons', hashedData.equipmentReturnReasons);
      this.set('recipients', hashedData.recipients);
      this.set('vendorOptions', this.rma ? hashedData.vendors : []);
      this.set('company', hashedData.company);
      this.set('pendingEquipmentLineItems', copiedModel.get('pendingEquipmentLineItems'));
      yield this.getLineItems.perform();
      var buttons = [];

      if (this.grants.getIndividualGrant('equipment_return_merchandise_authorizations.modify')) {
        if (this.isFromPending) {
          buttons.push({
            text: this.intl.t('common.actions.save'),
            click: () => {
              this.set('formStarted', true);
            },
            classNamesString: 'st-btn st-btn-primary rma-review-save-button'
          });
        } else {
          if (this.rmaStatus != 'closed') {
            buttons.push({
              text: this.intl.t('features.inventory.rma.saveAndCloseRma'),
              click: () => {
                this.set('confirmCloseModalOpen', true);
              },
              classNamesString: 'st-btn st-btn-secondary rma-close-button'
            });
            buttons.push({
              text: this.intl.t('common.actions.save'),
              click: buttonPromise => {
                return this.saveRmaData.perform(this.multiStepForm, buttonPromise);
              },
              classNamesString: 'st-btn st-btn-primary rma-review-save-button'
            });
          }
        }
      }

      copiedModel.get('pendingEquipmentLineItems').forEach(item => {
        item.set('totalCost', +item.get('cost') * +item.get('quantity'));
      });
      this.multiStepForm.registerStepData("step1", 'buttons', buttons);
      this.set('dataLoaded', true);
    }).on('init'),
    closeRma: (0, _emberConcurrency.task)(function* (buttonPromise) {
      try {
        var rma = yield this.multiStepForm.formOriginalModelsGetVal('equipmentReturnMerchandiseAuthorization.model');
        yield this.saveLineItems.perform(this.multiStepForm);
        yield rma.invokeAction('set_status_closed', {
          verb: 'POST'
        });
        buttonPromise.then(() => {
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
          this.multiStepForm.formStartedSetVal(false);
          this.multiStepForm.onAfterSave();
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal2'
        });
        throw err;
      }
    }),
    getLineItems: (0, _emberConcurrency.task)(function* () {
      if (!this.isFromPending) {
        var lineItems = yield this.pendingEquipmentLineItems;
        lineItems.forEach(item => {
          item.set('justReceived', 0);
          item.set('outstanding', item.quantity - item.fulfilledQuantity);
          item.set('totalReceived', item.fulfilledQuantity);
          item.set('history', this.grants.getIndividualGrant('equipment_ledgers.computed.company_inventory') ? this.store.query('equipmentLedger', {
            filter: 'target_id = ' + item.get('id') + ' and target_class = 400'
          }) : []);
        });
      }
    }),
    saveRmaData: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      try {
        yield this.saveLineItems.perform(multiStepForm);
        buttonPromise.then(() => {
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
          multiStepForm.formStartedSetVal(false);
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
        throw err;
      }
    }),
    saveLineItems: (0, _emberConcurrency.task)(function* (multiStepForm) {
      var allSaves = [];

      for (var i = 0; i < multiStepForm.formCopiedModelsGetVal('equipmentReturnMerchandiseAuthorization.model.pendingEquipmentLineItems.length'); i++) {
        var copiedItem = multiStepForm.formCopiedModelsGetVal('equipmentReturnMerchandiseAuthorization.model.pendingEquipmentLineItems')[i];
        allSaves.push(this.saveLineItemTask.perform(copiedItem));
      }

      yield (0, _emberConcurrency.all)(allSaves);
    }),
    saveLineItemTask: (0, _emberConcurrency.task)(function* (copiedItem) {
      var item = yield this.store.findRecord('pendingEquipmentLineItem', copiedItem.get('id'));
      item.set('fulfilledQuantity', copiedItem.get('fulfilledQuantity') + copiedItem.get('justReceived'));
      yield item.save();
    }),

    updateQuantity(row, direction) {
      row.set('justReceived', direction === 'down' ? 0 : row.get('quantity') - row.get('fulfilledQuantity'));

      if (row.get('justReceived') <= row.get('quantity') - row.get('fulfilledQuantity')) {
        row.set('outstanding', row.get('quantity') - row.get('justReceived') - row.get('fulfilledQuantity'));
        row.set('totalReceived', row.fulfilledQuantity + row.justReceived);
      } else {
        row.set('justReceived', row.get('quantity') - row.get('fulfilledQuantity'));
      }
    },

    actions: {
      updateQuantityRow(row, direction) {
        this.updateQuantity(row, direction);
      },

      updateEquipmentItemsList(row) {
        row.set('totalCost', row.get('cost') * row.get('quantity'));

        if (row.get('justReceived') <= row.get('quantity') - row.get('fulfilledQuantity')) {
          row.set('outstanding', row.get('quantity') - row.get('justReceived') - row.get('fulfilledQuantity'));
          row.set('totalReceived', row.fulfilledQuantity + row.justReceived);
        } else {
          row.set('justReceived', row.get('quantity') - row.get('fulfilledQuantity'));
        }
      },

      updateQuantityAll(direction) {
        this.pendingEquipmentLineItems.forEach(pendingEquipmentLineItem => {
          this.updateQuantity(pendingEquipmentLineItem, direction);
        });
      }

    }
  });

  _exports.default = _default;
});