define("securitytrax-ember-ui/components/multi-step-form/forms/user-actions/add-to-user/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CLcuLN6j",
    "block": "{\"symbols\":[\"data\"],\"statements\":[[10,\"div\"],[14,0,\"cell small-12\"],[12],[2,\"\\n\"],[6,[37,6],null,[[\"promises\",\"useSpinner\",\"size\"],[[30,[36,5],null,[[\"hierarchyUsers\"],[[35,4]]]],true,\"medium\"]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"dropdown-label-maker\"],[12],[2,\"\\n            \"],[1,[30,[36,1],[\"features.org-chart.modals.move_sub_title\"],[[\"user_name\"],[[35,0,[\"user\",\"flname\"]]]]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[1,[30,[36,3],null,[[\"name\",\"formMode\",\"options\",\"value\",\"renderInPlaceOverride\",\"searchEnabled\",\"allowClear\",\"labelProperty\",\"valueProperty\",\"class\"],[\"move_user\",\"edit\",[32,1,[\"resolvedPromises\",\"hierarchyUsers\"]],[35,2],false,true,false,\"user.lfname\",\"id\",\"org-chart-dropdown\"]]]],[2,\"  \\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"modalParentUser\",\"t\",\"selectedUser\",\"multi-step-form/switchable-inputs/select-input\",\"hierarchyUsers\",\"hash\",\"html/st-await\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/user-actions/add-to-user/template.hbs"
    }
  });

  _exports.default = _default;
});