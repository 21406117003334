define("securitytrax-ember-ui/calendar/map-view/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "la5758Wz",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"calendar-glimmer/calendar-map\",[],[[\"@params\",\"@model\",\"@breadCrumbs\",\"@breadCrumbData\"],[[32,1,[\"params\"]],[32,1],[34,0],[34,1]]],null],[2,\"\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"breadCrumbs\",\"breadCrumbData\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/calendar/map-view/template.hbs"
    }
  });

  _exports.default = _default;
});