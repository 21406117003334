define("securitytrax-ember-ui/components/multi-step-form/forms/system/api/api-base", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step"], function (_exports, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    progressBarHidden: Ember.computed('isNewRecord', function () {
      return this.isNewRecord;
    }),
    disabledProgressBarSteps: Ember.computed(function () {
      return [];
    }),
    hiddenProgressBarSteps: Ember.computed(function () {
      return [];
    })
  });

  _exports.default = _default;
});