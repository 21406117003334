define("securitytrax-ember-ui/mixins/route-permission-mixin", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    stNotificationManager: Ember.inject.service(),
    grants: Ember.inject.service(),
    session: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    enhancedFeatures: Ember.inject.service(),
    intl: Ember.inject.service(),
    stAjax: Ember.inject.service(),
    validationIsPresent: undefined,

    beforeModel() {
      this.grants.setGrantsAccessedNamespace(this.fullRouteName);
      this.stAjax.setAccessedNamespace(this.fullRouteName);
    },

    genericWarning: Ember.computed(function () {
      var intl = this.intl;
      return intl.t('common.notifications.no_permission');
    }),
    activate: function () {
      var routeName = this.routeName;

      if (routeName.includes('index') || routeName.includes('demo') || routeName.includes('loading') || routeName === 'application' || routeName === 'login' || routeName === 'not-found' || routeName === 'administration' || routeName === 'administration.company-settings.organization' || routeName === 'administration.company-settings.system' || routeName === 'administration.company-settings.equipment' || routeName === 'administration.company-settings.integrations' || routeName === 'administration.company-settings.content-management' || routeName === 'administration.company-settings.integrations.partner-companies.files') {
        return;
      } else {
        if (this.validationIsPresent === undefined && !this.session.forcePasswordUpdate) {
          throw new Error("Routename ".concat(routeName, " - Set up Permissions for your route - perform the findGrantsTask task in your beforeModel(), passing in an array of grants. Second argument can either be 'all' or 'one' depending on grant needs"));
        }
      }
    },

    /**
     * Base Grants Task for Landing pages that redirect to left nav pages
     * 
     * routeObjs:   routeObj[]
     * routeObj:    { route(string), grants[](string[]), modifier(string) }
     * modifier:    'one' or 'all'.  Will default to 'all' if not specified.
     * 
     */
    baseFindGrantsTask: (0, _emberConcurrency.task)(function* (routeObjs, redirectRoute = '/home/view') {
      this.set('validationIsPresent', true);
      this.session.store.restore();
      var isAuthenticated = yield this.session.get('isAuthenticated');

      if (isAuthenticated !== true) {
        return;
      }

      var i = 0;

      while (i < routeObjs.length) {
        var _routeObjs$i$grants, _routeObjs$i$modifier, _routeObjs$i$features;

        var hashedData = yield (0, _emberConcurrency.hash)({
          hasGrants: this.findGrantsSubTask.perform((_routeObjs$i$grants = routeObjs[i].grants) !== null && _routeObjs$i$grants !== void 0 ? _routeObjs$i$grants : [], (_routeObjs$i$modifier = routeObjs[i].modifier) !== null && _routeObjs$i$modifier !== void 0 ? _routeObjs$i$modifier : 'all'),
          allFeaturesEnabled: this.findFeaturesSubTask.perform((_routeObjs$i$features = routeObjs[i].features) !== null && _routeObjs$i$features !== void 0 ? _routeObjs$i$features : [])
        });

        if (hashedData.hasGrants && hashedData.allFeaturesEnabled) {
          this.replaceWith(routeObjs[i].route);
          return;
        }

        i++;
      }

      this.replaceWith(redirectRoute);
      this.stNotificationManager.addWarning(this.intl.t('common.notifications.no_permission'));
    }),
    findGrantsTask: (0, _emberConcurrency.task)(function* (grants, modifier = 'all', redirectRoute = '/home/view', features = null) {
      this.set('validationIsPresent', true);
      this.session.store.restore();
      var isAuthenticated = yield this.session.get('isAuthenticated');

      if (isAuthenticated !== true) {
        return;
      } else if (grants === undefined) {
        return;
      } else if (!(yield this.findGrantsSubTask.perform(grants, modifier))) {
        this.replaceWith(redirectRoute);
        this.stNotificationManager.addWarning(this.intl.t('common.notifications.no_permission'));
        return;
      }

      if (features) {
        var allEnabled = yield this.findFeaturesSubTask.perform(features);

        if (!allEnabled) {
          var intl = this.intl;
          this.replaceWith(redirectRoute);
          this.stNotificationManager.addWarning(intl.t('common.notifications.no_permission'));
        }
      }
    }),
    findGrantsSubTask: (0, _emberConcurrency.task)(function* (grants, modifier) {
      // Only needs one of the grants
      if (modifier === 'one') {
        var successfulGrants = [];
        var hasPermissions = [];

        for (var i = 0; i < grants.length; i++) {
          hasPermissions.push(this.grants.get('getIndividualGrantAsync').perform(grants[i]));
        }

        var hasPermissionsYielded = yield (0, _emberConcurrency.all)(hasPermissions);

        for (var _i = 0; _i < grants.length; _i++) {
          var hasPermission = hasPermissionsYielded[_i];

          if (hasPermission === true) {
            successfulGrants.push(hasPermission);
            break;
          }
        }

        if (successfulGrants.length === 0) {
          return false;
        } // Needs all of the grants

      } else if (modifier === 'all') {
        var _hasPermissions = [];

        for (var _i2 = 0; _i2 < grants.length; _i2++) {
          _hasPermissions.push(this.grants.get('getIndividualGrantAsync').perform(grants[_i2]));
        }

        var _hasPermissionsYielded = yield (0, _emberConcurrency.all)(_hasPermissions);

        for (var _i3 = 0; _i3 < grants.length; _i3++) {
          var _hasPermission = _hasPermissionsYielded[_i3];

          if (_hasPermission !== true) {
            return false;
          }
        }
      }

      return true;
    }),
    findFeaturesSubTask: (0, _emberConcurrency.task)(function* (features) {
      var allEnabled = true;
      var hasFeatures = [];

      for (var i = 0; i < features.length; i++) {
        hasFeatures.push(this.enhancedFeatures.hasFeature(features[i]));
      }

      var hasFeaturesYielded = yield (0, _emberConcurrency.all)(hasFeatures);

      for (var _i4 = 0; _i4 < features.length; _i4++) {
        var isEnabled = hasFeaturesYielded[_i4];

        if (!isEnabled) {
          allEnabled = false;
          break;
        }
      }

      return allEnabled;
    }),
    generalPermissionsTask: (0, _emberConcurrency.task)(function* (hasPermission, warningMessage = this.genericWarning) {
      this.set('validationIsPresent', true);
      var isAuthenticated = yield this.session.get('isAuthenticated');

      if (isAuthenticated !== true) {
        return;
      } else if (hasPermission !== true) {
        this.replaceWith('/');
        this.stNotificationManager.addWarning(warningMessage);
        return;
      }
    })
  });

  _exports.default = _default;
});