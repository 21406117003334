define("securitytrax-ember-ui/demo/lists/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _authenticatedRouteMixin, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _routePageviewLogging.default, {
    model() {
      return this.store.query('customer', {
        sort: this.controllerFor('demo.lists').get('sortCall'),
        offset: this.get('pagination.offset') || 0,
        limit: this.get('pagination.limit') || 10
      });
    },

    setupController(controller, models) {
      this._super(...arguments);

      controller.set('customers', models);
    }

  });

  _exports.default = _default;
});