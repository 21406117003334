define("securitytrax-ember-ui/services/emergency-contacts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    selectListsKey: 'pages.customers.emergency_contacts.select_lists',

    // relationsList() { // this is no longer used for now, but may be used in future so leaving here for now
    //   return [
    //     { value: 'spouse', name: this.get('intl').t(this.get('selectListsKey') + '.relations_list.spouse') },
    //     { value: 'parent', name: this.get('intl').t(this.get('selectListsKey') + '.relations_list.parent') },
    //     { value: 'grandparent', name: this.get('intl').t(this.get('selectListsKey') + '.relations_list.grandparent') },
    //     { value: 'child', name: this.get('intl').t(this.get('selectListsKey') + '.relations_list.child') },
    //     { value: 'sibling', name: this.get('intl').t(this.get('selectListsKey') + '.relations_list.sibling') },
    //     { value: 'friend', name: this.get('intl').t(this.get('selectListsKey') + '.relations_list.friend') },
    //     { value: 'other', name: this.get('intl').t(this.get('selectListsKey') + '.relations_list.other') }
    //   ];
    // },
    phoneTypeList() {
      return [{
        value: 'Home',
        name: this.intl.t(this.selectListsKey + '.phone_type_list.home')
      }, {
        value: 'Mobile',
        name: this.intl.t(this.selectListsKey + '.phone_type_list.mobile')
      }, {
        value: 'Work',
        name: this.intl.t(this.selectListsKey + '.phone_type_list.work')
      }, {
        value: 'Other',
        name: this.intl.t(this.selectListsKey + '.phone_type_list.other')
      }, {
        value: 'Pin',
        name: this.intl.t(this.selectListsKey + '.phone_type_list.pin')
      }];
    }

  });

  _exports.default = _default;
});