define("securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/adc/history/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/adc/adc-base", "ember-concurrency"], function (_exports, _adcBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adcBase.default.extend({
    intl: Ember.inject.service(),
    ispService: Ember.inject.service(),
    eventHistory: Ember.computed(function () {
      return this.eventHistoryTask.perform();
    }),
    eventHistoryTask: (0, _emberConcurrency.task)(function* () {
      var hashedData = yield (0, _emberConcurrency.hash)({
        history: this.ispService.get('invokeIspActionTask').perform({
          model: this.customer,
          action: 'alarm_dot_com__getEventHistory',
          attributes: {}
        }),
        deviceList: this.formatDeviceListTask.perform()
      });

      if (hashedData.history && hashedData.history.event_history) {
        hashedData.history.event_history.forEach(event => {
          var device = hashedData.deviceList[event.device_id];

          if (device) {
            event['device_name'] = device.device_name;
            event['device_type'] = device.device_type;
          }
        });
        return hashedData.history ? hashedData.history.event_history : [];
      }

      return [];
    }),
    formatDeviceListTask: (0, _emberConcurrency.task)(function* () {
      var formattedDeviceList = [];
      var deviceList = yield this.ispService.get('invokeIspActionTask').perform({
        model: this.customer,
        action: 'alarm_dot_com__getDeviceList',
        attributes: {}
      });

      if (deviceList && deviceList.devices) {
        deviceList.devices.forEach(device => {
          formattedDeviceList[device.device_id] = {
            device_name: device.name,
            device_type: device.device_type
          };
        });
      }

      return formattedDeviceList;
    })
  });

  _exports.default = _default;
});