define("securitytrax-ember-ui/services/message-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Service to get data for messages
   *
   * @class MessageService
   * @extends Ember.Service
   * @memberof services
   * @mixes Ember.Evented
   */
  var _default = Ember.Service.extend({
    stAjax: Ember.inject.service(),

    /**
     * Get the preview message
     *
     * @param {dynamicTypeValue}
     * @param {dynamicValue}
     * @param {messageValue}
     */
    computeMessage(dynamicTypeValue, dynamicValue, messageValue) {
      //setting the data to be passed over
      var messageData = {
        currentDynamicTypeValue: dynamicTypeValue,
        currentDynamicValue: dynamicValue,
        currentMessageValue: messageValue
      };
      var options = {
        method: 'GET',
        data: {
          data: messageData
        }
      };
      return this.stAjax.request(this.stAjax.getUrl('/message_computed'), options).then(result => {
        var messageData = {
          subject: '',
          htmlBody: '',
          textBody: ''
        };

        if (result.data[0]) {
          messageData.subject = result.data[0].attributes.subject;
          messageData.htmlBody = result.data[0].attributes.html_body;
          messageData.textBody = result.data[0].attributes.text_body;
        }

        return messageData;
      });
    }

  });

  _exports.default = _default;
});