define("securitytrax-ember-ui/helpers/sum", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sum = sum;
  _exports.default = void 0;

  function sum([leftside, rightside]) {
    return leftside + rightside;
  }

  var _default = Ember.Helper.helper(sum);

  _exports.default = _default;
});