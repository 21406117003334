define("securitytrax-ember-ui/components/multi-step-form/forms/system/adt/steps/econtracts/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/system/adt/adt-form-base", "ember-concurrency"], function (_exports, _adtFormBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adtFormBase.default.extend({
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    grants: Ember.inject.service(),
    centralStationService: Ember.inject.service(),
    adtEContractFormStarted: false,
    canAddEcontract: false,
    orderStatusCode: false,
    canAddEcontractObserver: Ember.observer('multiStepForm.finishedCopyingModels', 'adtData', 'adtData.adtCustomerOrderStatus.status_code', function () {
      this.setupTask.perform();
    }),

    init() {
      this._super(...arguments);

      if (this.callbackEditAdtOrderFormStarted) {
        this.multiStepForm.setupForm();
      }
    },

    addEContractFormSteps: Ember.computed('adtEContractFormStarted', function () {
      var intl = this.intl;
      return Ember.Object.create({
        addEContracts: Ember.Object.create({
          stepNameKey: 'addEContracts',
          title: intl.t('pages.customers.system.order.create_econtract_header'),
          stepComponentPath: 'multi-step-form/forms/system/adt/steps/econtracts/create-econtract'
        })
      });
    }),
    setupTask: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('adtStepReady', false);
        var grants = yield this.grants;
        var canCreateGrant = grants.getIndividualGrant('customers.manage_central_station_integration');
        var adtData = yield this.adtData;
        var orderStatusCode = adtData ? yield adtData.adtCustomerOrderStatus.status_code : null;
        var monitoringCompany = yield this.monitoringCompany;
        var holdsPreventEcontractCreation = this.centralStationService.getData({
          model: monitoringCompany,
          method: 'adt__getHoldsPreventEContractCreation',
          //by default this filters blacklisted items
          responseKey: 'holds_prevent_e_contract_creation'
        });
        var canAddEcontract = false;

        if (canCreateGrant) {
          //If order status is eContractPending, OR Order has existing holds but we aren't preventing econtract creation on holds
          if (orderStatusCode == 13 || orderStatusCode == 12 && holdsPreventEcontractCreation === false) {
            canAddEcontract = true;
          }
        }

        this.set('canAddEcontract', canAddEcontract);
        this.set('orderStatusCode', orderStatusCode);
        this.set('adtStepReady', true);
      } catch (error) {
        (false && Ember.warn('error in econtracts setup task' + error, {
          id: 'multiStepForm.forms.system.adt.econtracts'
        }));
      }
    }).on('init'),
    refreshAdtOrderStatusTask: (0, _emberConcurrency.task)(function* () {
      var centralStationService = this.centralStationService;

      try {
        var adtData = yield this.adtData;
        var adtStatus = yield centralStationService.getData({
          model: this.customer,
          method: 'adt__getOrderStatus',
          responseKey: 'order_status'
        });
        Ember.set(adtData, 'adtCustomerOrderStatus', adtStatus);
      } catch (err) {
        (false && Ember.warn('failed to reload adt order status: ' + err, {
          id: 'multiStepForm.forms.system.adt.econtracts'
        }));
      }
    })
  });

  _exports.default = _default;
});