define("securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-notes/base-component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    quickAdd: Ember.inject.service(),
    formStarted: false,
    tasksUsersList: Ember.computed(function () {
      return this.tasksUsersListTask.perform();
    }),
    tasksUsersListTask: (0, _emberConcurrency.task)(function* () {
      return yield this.store.query('user', {
        filter: 'user_admin_setting.active = true',
        sort: 'lname'
      });
    }),
    noteTypes: Ember.computed(function () {
      return this.noteTypesTask.perform();
    }),
    noteTypesTask: (0, _emberConcurrency.task)(function* () {
      return yield this.store.findAll('noteType');
    }),
    communicationReasons: Ember.computed(function () {
      return this.communicationReasonsTask.perform();
    }),
    communicationReasonsTask: (0, _emberConcurrency.task)(function* () {
      return yield this.store.findAll('communicationReason');
    }),
    communicationDispositions: Ember.computed(function () {
      return this.communicationDispositionsTask.perform();
    }),
    communicationDispositionsTask: (0, _emberConcurrency.task)(function* () {
      return yield this.store.findAll('communicationDisposition');
    }),
    actions: {
      transitionBackToParentRoute() {
        var transitionToRoute = this.quickAdd.closeRoute || this.parentRoute;

        if (transitionToRoute.includes('customer') || transitionToRoute.includes('lead')) {
          this.router.transitionTo(transitionToRoute, this.get('customer.id'));
        } else {
          this.router.transitionTo(transitionToRoute);
        }
      }

    }
  });

  _exports.default = _default;
});