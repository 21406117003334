define("securitytrax-ember-ui/home/tech-today/appointment/route", ["exports", "securitytrax-ember-ui/home/tech-today/route", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/scroll-to-top", "ember-concurrency"], function (_exports, _route, _authenticatedRouteMixin, _scrollToTop, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_authenticatedRouteMixin.default, _scrollToTop.default, {
    userHasCheckedInAppointmentService: Ember.inject.service(),
    tagsService: Ember.inject.service(),
    quickAdd: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['calendar_events.view'], 'all');
    },

    model({
      id
    }) {
      return this.setupModelTask.perform(id);
    },

    setupModelTask: (0, _emberConcurrency.task)(function* (id) {
      var user = yield this.modelFor('home').loggedInUser;

      try {
        var appointment = yield this.store.findRecord('calendarEvent', id);
        var customer = appointment ? yield appointment.get('customer') : null;
        this.homeController.set('appointment', appointment);
        return (0, _emberConcurrency.hash)({
          appointment: appointment || null,
          customer,
          tags: this.get('tagsService.getTagsByObjectType').perform('customer'),
          isManager: appointment.get('calendarUser.id') !== user.get('id'),
          user
        });
      } catch (err) {
        return (0, _emberConcurrency.hash)({
          appointment: null,
          isManager: false,
          user
        });
      }
    }),

    setupBreadCrumbs() {
      var name = this.controller.model.user.get('lfname');
      var subName = '';

      if (this.controller.model.isManager) {
        subName = " for <span class=\"breadcrumb--red\">".concat(name, "</span>");
      }

      var breadCrumbs = [{
        name: this.intl.t('pages.home.tech_today'),
        link: 'home.tech-today'
      }, {
        name: this.intl.t('pages.customers.techToday.techAtWork') + subName,
        previousLink: 'home.tech-today',
        last: true
      }];
      var breadCrumbData = {
        titleLink: 'home.tech-today',
        hide: false
      };
      this.homeController.set('breadCrumbs', breadCrumbs);
      this.homeController.set('breadCrumbData', breadCrumbData);
    },

    actions: {
      didTransition() {
        this.setupBreadCrumbs();
        this.userHasCheckedInAppointmentService.set('onTechAtWorkPage', true);
      },

      willTransition(transition) {
        if (!transition.from.name.includes('edit-create') && !transition.from.name.includes('create-invoice') && !transition.to.name.includes('edit-create-work-order')) {
          this.quickAdd.set('closeRoute', transition.from.name);
        }

        if (transition.to.name.includes('edit-create-work-order')) {
          this.quickAdd.set('closeRoute', 'customers.work-orders');
        }

        this.quickAdd.set('showQuickAdd', false);
        this.quickAdd.set('page', undefined);
        this.userHasCheckedInAppointmentService.set('onTechAtWorkPage', false);
      }

    }
  });

  _exports.default = _default;
});