define("securitytrax-ember-ui/components/pages/accounting/payments/payables/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4qC7VeKW",
    "block": "{\"symbols\":[],\"statements\":[[8,\"customer/accounting/payables-list\",[],[[\"@model\",\"@customerPayables\"],[[34,0],[30,[36,2],[[30,[36,1],null,[[\"model\",\"relName\",\"useCallback\",\"options\"],[[35,0,[\"customer\"]],\"customerPayables\",false,[30,[36,1],null,[[\"params\"],[[30,[36,1],null,[[\"limit\",\"include\"],[0,\"payroll_role_function,user_entity,customer_payable_payments,payroll_payment_status\"]]]]]]]]]],null]]],null]],\"hasEval\":false,\"upvars\":[\"model\",\"hash\",\"load-relationship-with-query-params\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/accounting/payments/payables/template.hbs"
    }
  });

  _exports.default = _default;
});