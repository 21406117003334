define("securitytrax-ember-ui/components/multi-step-form/forms/customer-central-station-information/monitoring-companies/monitoring-company-type-default/toolbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4fa402zP",
    "block": "{\"symbols\":[\"@multiStepForm\",\"@customer\"],\"statements\":[[8,\"html/st-await\",[],[[\"@useSpinner\",\"@booleanLoaded\"],[false,[32,1,[\"finishedCopyingModels\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"nav/top-nav-sub/tech-toolbox/btn-none-selected\",[],[[\"@buttonText\",\"@customer\"],[[32,1,[\"formCopiedModels\",\"customerOrderInformation\",\"model\",\"monitoringCompany\",\"name\"]],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/customer-central-station-information/monitoring-companies/monitoring-company-type-default/toolbox/template.hbs"
    }
  });

  _exports.default = _default;
});