define("securitytrax-ember-ui/components/customer/account-status/component", ["exports", "securitytrax-ember-ui/mixins/leaflet-map", "securitytrax-ember-ui/mixins/components/account-status"], function (_exports, _leafletMap, _accountStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_leafletMap.default, _accountStatus.default, {
    intl: Ember.inject.service(),
    screen: Ember.inject.service(),
    mapLoading: Ember.inject.service('events/map-loading'),
    pinLoaded: true,
    tagName: ''
  });

  _exports.default = _default;
});