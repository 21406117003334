define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/interactive-services-providers/integrations/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency"], function (_exports, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    stNotificationManager: Ember.inject.service(),
    disabledProgressBarSteps: Ember.computed(function () {
      return [];
    }),
    hiddenProgressBarSteps: Ember.computed(function () {
      return [];
    }),
    formComponent: Ember.computed('multiStepForm.formCopiedModels.interactiveServicesProvider.model.interactiveServicesProviderType.name', function () {
      var isp = this.multiStepForm.formCopiedModelsGetVal('interactiveServicesProvider.model.interactiveServicesProviderType.name');
      var formComponent = 'multi-step-form/forms/administration/integrations/interactive-services-providers/';

      switch (isp) {
        case 'Alarm.com':
          formComponent += 'adc/integration';
          break;

        case 'Alula':
          formComponent += 'alula/integration';
      }

      return formComponent;
    }),
    validateCredentialsTask: (0, _emberConcurrency.task)(function* (buttonPromise) {
      var isp = this.multiStepForm.formOriginalModelsGetVal('interactiveServicesProvider.model');
      yield isp.invokeAction('validate_credentials', {
        verb: 'POST',
        processResponse: true,
        data: {
          data: {}
        }
      });
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccessKey('pages.administration.integrations.monitoringCompanies.integrations.validate_credentials_success', 'modal');
      });
    })
  });

  _exports.default = _default;
});