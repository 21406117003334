define("securitytrax-ember-ui/components/customer/files/edocs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XbZTeRir",
    "block": "{\"symbols\":[\"data\"],\"statements\":[[6,[37,9],null,[[\"useSpinner\",\"promises\"],[true,[30,[36,8],null,[[\"hasEdocFeature\"],[[35,7]]]]]],[[\"default\"],[{\"statements\":[[6,[37,6],[[30,[36,5],[[32,1,[\"resolvedPromises\",\"hasEdocFeature\"]],[30,[36,4],[\"electronic_document_envelopes.view\"],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],null,null,[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,2],null,[[\"customer\",\"showTitle\"],[[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"showTitle\",\"customer\",\"customer/integrations/electronic-documents\",\"html/st-card\",\"has-grants\",\"and\",\"if\",\"hasEdocFeature\",\"hash\",\"html/st-await\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/customer/files/edocs/template.hbs"
    }
  });

  _exports.default = _default;
});