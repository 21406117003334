define("securitytrax-ember-ui/components/multi-step-form/forms/administration/payables/add-functions/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/switchable-form-step", "securitytrax-ember-ui/utils/strings"], function (_exports, _emberConcurrency, _switchableFormStep, _strings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    screen: Ember.inject.service(),
    initData: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = this.multiStepForm;
      yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);

      if (this.isNewRecord) {
        var roleId = '1';

        if (this.name === 'technician') {
          roleId = '2';
        } else if (this.name === 'other') {
          roleId = '3';
        }

        this.multiStepForm.formCopiedModelsSetVal('payrollRoleFunction.model.active', true);
        this.multiStepForm.formCopiedModelsSetVal('payrollRoleFunction.model.payrollRole', this.payrollRoles.findBy('id', roleId));
      }
    }).on('init'),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;

      if (this.formMode !== 'view') {
        multiStepForm.registerStepData(multiStepForm.currentStepNameKey, 'buttons', [{
          text: this.intl.t('common.actions.next'),
          click: buttonPromise => {
            return multiStepForm.currentStepNameKey == 'addFunction::newFunction' ? this.saveNewFunction.perform(buttonPromise) : this.saveNewFunction.perform(buttonPromise, true);
          },
          classNamesString: 'st-btn st-btn-primary function-save-button'
        }]);
      }
    },

    disabledProgressBarSteps: Ember.computed(function () {
      return [];
    }),
    hiddenProgressBarSteps: Ember.computed(function () {
      return [];
    }),
    payrollRoleFunctions: Ember.computed(function () {
      return this.allFunctions.filter(payrollRoleFunction => {
        return payrollRoleFunction.get('payrollListMethod.entityType') === 'user';
      });
    }),
    payrollRoleOptions: Ember.computed('payrollRoles', function () {
      return this.payrollRoles.filter(role => {
        return role.get('id') !== '3';
      });
    }),
    descriptionObject: Ember.computed('multiStepForm.formCopiedModels.payrollRoleFunction.model.payrollListMethod', function () {
      var listMethod = this.multiStepForm.formCopiedModelsGetVal('payrollRoleFunction.model.payrollListMethod');

      if (listMethod && listMethod.get('id') > 0) {
        return Ember.A([Ember.Object.create({
          packageName: listMethod.get('name'),
          packageId: listMethod.get('id'),
          packageContent: [{
            label: this.intl.t('common.columns.description'),
            description: listMethod.get('name')
          }, {
            label: this.intl.t('pages.administration.human_resources.payee_type'),
            description: (0, _strings.ucwords)(listMethod.get('entityType'), '_')
          }, {
            label: this.intl.t('pages.administration.human_resources.information_from'),
            description: (0, _strings.ucwords)(listMethod.get('payOnObject'), '_')
          }]
        })]);
      }

      return null;
    }),
    payrollListMethods: Ember.computed('multiStepForm.formCopiedModels.payrollRoleFunction.model.payrollRole', function () {
      var role = this.payrollRoles.findBy('id', this.multiStepForm.formCopiedModelsGetVal('payrollRoleFunction.model.payrollRole.id'));
      return role ? role.get('payrollListMethods').filter(listMethod => {
        return ['user', 'lead_company', 'lead_company_campaign'].includes(listMethod.get('entityType'));
      }) : [];
    }),
    saveNewFunction: (0, _emberConcurrency.task)(function* (buttonPromise, clone = false) {
      var newlySavedFunction;
      var validationObject = {
        notificationNamespace: 'modal',
        overrideOriginalModelsConfig: Ember.Object.create({
          payrollRoleFunction: {
            model: this.payrollRoleFunction,
            copyRelationMap: {
              payrollRole: {
                readOnly: true
              },
              payrollListMethod: {
                readOnly: true
              }
            }
          }
        })
      };

      if (clone) {
        var formattedObjects = Ember.A();
        formattedObjects.push(Ember.Object.create({
          model: {
            name: this.multiStepForm.formCopiedModelsGetVal('payrollRoleFunction.model.name'),
            payrollListMethod: this.functionToClone,
            payrollRole: this.multiStepForm.formCopiedModelsGetVal('payrollRoleFunction.model.payrollRole')
          },
          extraValidationData: {
            clone: true
          },
          filePath: 'payroll-role-function'
        }));
        yield this.multiStepForm.validateList.perform(formattedObjects);

        try {
          newlySavedFunction = (yield this.functionToClone.invokeAction('duplicate', {
            verb: 'POST',
            processResponse: true,
            data: {
              data: {
                attributes: {
                  name: this.multiStepForm.formCopiedModelsGetVal('payrollRoleFunction.model.name')
                }
              }
            }
          })).models[0];
        } catch (err) {
          this.stNotificationManager.addErrorMessages(err, {
            notificationNamespace: 'modal'
          });
          throw err;
        }
      } else {
        var toCreateObjects = yield this.multiStepForm.validateCreateAll(validationObject);
        var newFunction = this.store.createRecord('payrollRoleFunction', toCreateObjects.payrollRoleFunction.data);
        newlySavedFunction = yield toCreateObjects.payrollRoleFunction.saveWithRollbackAndNotification(newFunction, 'modal');
      }

      buttonPromise.then(() => {
        if (clone) {
          this.stNotificationManager.addSuccess(this.intl.t('common.notifications.save_successful'), 'modal');
          this.multiStepForm.formStartedSetVal(false);
          this.router.transitionTo('administration.company-settings.system.payables.function-details', newlySavedFunction);
        } else {
          this.stNotificationManager.addSuccess(this.intl.t('common.notifications.save_successful'), 'modal');

          if (this.onAfterSave && typeof this.onAfterSave === 'function') {
            this.onAfterSave(newlySavedFunction, 'addFunction');
          }

          this.multiStepForm.currentStepNameKeySetVal('addLevel');
          this.multiStepForm.progressBarConfig.activeIndex = 1;
          Ember.set(this.multiStepForm.progressBarConfig.items.objectAt(0), 'isComplete', true);
          this.multiStepForm.setupCopyModels.perform();
        }
      });
    }),
    createRecordTask: (0, _emberConcurrency.task)(function* (type, toCreateObjects) {
      var record = this.store.createRecord(type, toCreateObjects);
      return yield record.save();
    }),
    actions: {
      addRelationships() {
        var functionToClone = this.functionToClone;
        var copiedModels = this.multiStepForm.formCopiedModelsGetVal('payrollRoleFunction.model');
        copiedModels.set('payrollRole', functionToClone.get('payrollRole'));
        copiedModels.set('payrollListMethod', functionToClone.get('payrollListMethod'));
        copiedModels.set('about', functionToClone.get('about'));
      }

    }
  });

  _exports.default = _default;
});