define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/electronic-document-providers/integrations/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ue7Sd1Gr",
    "block": "{\"symbols\":[\"MultiStepFormComponent\",\"@formMode\",\"@multiStepForm\",\"@transitionBackToParent\"],\"statements\":[[8,\"multi-step-form/progress-bar\",[],[[\"@config\",\"@modalButtons\"],[[32,0,[\"SwitchableFormStep\",\"progressBarConfig\"]],[32,3,[\"currentStepData\",\"buttons\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"html/st-await\",[],[[\"@useSpinner\",\"@booleanLoaded\"],[true,[32,3,[\"finishedCopyingModels\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[35,0]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,[32,1],[],[[\"@formMode\",\"@multiStepForm\",\"@transitionBackToParent\"],[[32,2],[32,3],[32,4]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"formComponent\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/electronic-document-providers/integrations/template.hbs"
    }
  });

  _exports.default = _default;
});