define("securitytrax-ember-ui/utils/i18n", ["exports", "securitytrax-ember-ui/utils/context", "securitytrax-ember-ui/utils/ajax", "ember-ajax/request"], function (_exports, _context, _ajax, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.changeLocaleForApplication = changeLocaleForApplication;
  _exports.getTranslation = getTranslation;

  /**
   * Utility helper module for internationalization.
   * @module
   */
  //region set translations and locale
  var TRANSLATIONS_URL = '/web/api/translations/';
  /**
   * <p>Sets locale and respective translations for the application</p>
   *
   * @param {String} locale The current locale ID.
   * @param {Object} translations Collection of new translation values.
   *
   * @private
   */

  function setTranslations(locale, translations) {
    var intl = (0, _context.getGlobalContext)().get('intl'); // set translations

    intl.addTranslations(locale, translations); // set the locale

    intl.setLocale(locale);
    (false && Ember.warn("Set translations for locale ".concat(locale), {
      id: 'utils.i18n.setTranslations'
    }));
  }
  /**
   * <p>Sets new locale for the application and downloads translations for this application.</p>
   *
   * <p><strong>NOTE:</strong> If no translations are found on the server, en translations are loaded as a fallback</p>
   *
   * @param {models.Locale} locale LocaleModel for which we want to get translations for
   *
   * @returns {Promise}
   */


  function changeLocaleForApplication(locale) {
    var language = locale.get('language'); // Request polyfill.  According to ember-intl, the polyfill has to be loaded up first (ideally we would do it in parallel).

    return (0, _request.default)("https://cdn.polyfill.io/v2/polyfill.min.js?features=Intl.~locale.".concat(language), {
      cache: true,
      dataType: 'script'
    }).then(() => {
      // Log polyfill success.
      (false && Ember.warn("Intl polyfill was loaded for locale ".concat(language), {
        id: 'utils.i18n.changeLocaleForApplication'
      })); // Request locale translations from server.

      return (0, _request.default)(TRANSLATIONS_URL, {
        data: {
          language
        },
        cache: false,
        method: 'GET',
        contentType: 'application/vnd.api+json; charset=utf-8',
        dataType: 'json',
        headers: {
          AjaxRequestUniqueKey: (0, _ajax.getAntiForgeryToken)()
        }
      }).then(data => {
        // Did we NOT get the requested locale back?
        if (data.language !== language) {
          // Log failure.
          (false && Ember.warn("Did not find translations for ".concat(language, " falling back to ").concat(data.language), {
            id: 'utils.i18n.language'
          }));
        } // Update application translations.


        setTranslations(data.language, data.translations);
      }).catch(error => {
        // Log error.
        (false && Ember.warn("Translations for locale ".concat(language, " failed to load.") + error, {
          id: 'utils.i18n.changeLocaleForApplication'
        }));
      });
    });
  } //endregion
  //region retrieve translations

  /**
   * <p>Returns the translated string for the context, translation key and replace tokens</p>
   *
   * @param {Ember.Object | String} contextOrKey - Ember context class for path selection or the actual translation key
   * @param {String | Object<String, String>=} keyOrReplaceTokens - The dot delimited translation key or the replace tokens; if the first argument was a string then this argument is optional
   * @param {Object<String, String>=} replaceTokensOnly Optional tokens to replace in the translation.
   *
   * @returns {String} The localized message or a "not found" message.
   *
   * @example
   * import { getTranslation } from 'i18n';
   *
   * getTranslation('home.locks.accessTime', {
   *     dateTime: '12:54 PM'
   * });          // "at 12:54 PM"
   *
   * @example
   * // If calling this from the locks route:
   * import { getTranslation } from 'i18n';
   *
   * getTranslation(this, 'accessTime', {
   *     dateTime: '12:54 PM'
   * });          // "at 12:54 PM"
   */


  function getTranslation(contextOrKey, keyOrReplaceTokens, replaceTokensOnly) {
    // Get the full translation key and correct replaceTokens
    var {
      key,
      replaceTokens
    } = getTranslationKeyAndTokens(contextOrKey, keyOrReplaceTokens, replaceTokensOnly);
    var intl = (0, _context.getGlobalContext)().get('intl'); // Get translation.

    var text = intl.t(key); // Were replacement tokens passed?

    if (replaceTokens) {
      // Replace tokens.
      text = intl.formatMessage(text, replaceTokens);
    }

    return text;
  }
  /**
   * Gets the translation key and replacement tokens for the arguments passed into the function. The function figures out which argument is what
   * Arguments are the following:
   * <ul>
   *     <li>context or translation key</li>
   *     <li>translation key or replaceTokens</li>
   *     <li>replaceTokens</li>
   * </ul>
   *
   * @private
   *
   * @returns {{key: String, replaceTokens: String}}
   */


  function getTranslationKeyAndTokens() {
    // Getting a new arguments array so I can modify it
    // Doing it this way due to a note in https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/arguments
    var args = arguments.length === 1 ? [arguments[0]] : Array.apply(null, arguments); // If the first argument was a string, then insert one element to the first spot that will serve as an undefined context

    if (typeof args[0] === 'string') {
      args.unshift(undefined);
    }

    return {
      key: "".concat(getTranslationPathFromContext(args[0])).concat(args[1]),
      replaceTokens: args[2]
    };
  }
  /**
   * Regex for selecting the correct context to obtain the right path to a translation
   *
   * @private
   * @static
   * @ignore
   *
   * @type {RegExp}
   */


  var TRANSLATION_CONTEXT_REGEX = new RegExp('^customer-ember\\@(component|controller|route){1}:([^:]+):$');
  /**
   * Returns dotified translation path for Ember class
   *
   * @private
   *
   * @param {Ember.Object | undefined} context - Ember class (route, controller, component) that we want to get a translation path for
   *
   * @returns {String}
   */

  function getTranslationPathFromContext(context) {
    // Was not context supplied?
    if (!context) {
      return '';
    } // Does the supplied context NOT match our regular expression?


    var match = context.constructor.toString().match(TRANSLATION_CONTEXT_REGEX);

    if (!match) {
      // Invalid context path.
      (false && Ember.warn("Context did not match regex for ".concat(context.constructor.toString()), {
        id: 'utils.i18n.getTranslationPathFromContext'
      }));
      return '';
    } // Split path into parts.


    var path = match[2].split('/'); // If this is a component, we need to prepend "components" to the beginning of the path

    if (match[1] === 'component') {
      path.unshift('components');
    } // Add element (to get trailing dot).


    path.push(''); // Join and return.

    return path.join('.');
  } //endregion

});