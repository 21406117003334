define("securitytrax-ember-ui/components/multi-step-form/forms/administration/organization/edit-office-location/vendor-details/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "securitytrax-ember-ui/components/multi-step-form/forms/administration/organization/edit-office-location/base-component"], function (_exports, _switchableFormStep, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend(_switchableFormStep.default, {});

  _exports.default = _default;
});