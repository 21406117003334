define("securitytrax-ember-ui/models/calendar-event-type", ["exports", "securitytrax-ember-ui/models/base_models/calendar-event-type"], function (_exports, _calendarEventType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _calendarEventType.default.extend({
    styleColor: Ember.computed('calculatedColor', function () {
      var calculatedColor = this.calculatedColor;

      if (calculatedColor) {
        return Ember.String.htmlSafe("background-color: ".concat(calculatedColor));
      } else {
        return '';
      }
    }),
    textColor: Ember.computed('calculatedColor', function () {
      var calculatedColor = this.calculatedColor;

      if (calculatedColor) {
        return Ember.String.htmlSafe("color: ".concat(calculatedColor));
      } else {
        return '';
      }
    }),
    calculatedColor: Ember.computed('id', function () {
      var id = this.id;

      switch (id) {
        case '{"calendar_category":"tech_schedule","id":"1"}':
          //Install
          return '#00a216';

        case '{"calendar_category":"tech_schedule","id":"2"}':
          //Service
          return '#3d49d1';

        case '{"calendar_category":"tech_schedule","id":"3"}':
          //Removal
          return '#db2a17';

        case '{"calendar_category":"tech_schedule","id":"100"}': //Time Off

        case '{"calendar_category":"sale_schedule","id":"100"}':
          return '#000';

        case '{"calendar_category":"sale_schedule","id":"1"}':
          //Sale
          return '#ec720b';

        default:
          return '';
      }
    }),
    calculatedColorClass: Ember.computed('id', function () {
      // DREW TODO - create a human readable 
      var id = this.id;

      switch (id) {
        case '{"calendar_category":"tech_schedule","id":"1"}':
          return 'install';

        case '{"calendar_category":"tech_schedule","id":"2"}':
          return 'service';

        case '{"calendar_category":"tech_schedule","id":"3"}':
          return 'removal';

        case '{"calendar_category":"tech_schedule","id":"100"}':
        case '{"calendar_category":"sale_schedule","id":"100"}':
          return 'time-off';

        case '{"calendar_category":"sale_schedule","id":"1"}':
          return 'sale';

        default:
          return '';
      }
    }),
    nameAsKey: Ember.computed('name', function () {
      var name = this.name;

      if (name) {
        var nameAsKey = name.replace(/\s+/g, '').toLowerCase();
        return nameAsKey;
      }

      return '';
    }),
    nameAsKeyCamel: Ember.computed('name', function () {
      var name = this.name;

      if (name) {
        var nameAsKey = Ember.String.camelize(name.replace(/\s+/g, ''));
        return nameAsKey;
      }

      return '';
    }),
    typeIcon: Ember.computed('id', function () {
      var id = this.id;

      switch (id) {
        case '{"calendar_category":"tech_schedule","id":"1"}':
        case '{"calendar_category":"tech_schedule","id":"2"}':
        case '{"calendar_category":"tech_schedule","id":"3"}':
          return 'icn-wrench';

        case '{"calendar_category":"tech_schedule","id":"100"}':
        case '{"calendar_category":"sale_schedule","id":"100"}':
          return 'icn-clock';

        case '{"calendar_category":"sale_schedule","id":"1"}':
          return 'icn-core';

        default:
          return '';
      }
    })
  });

  _exports.default = _default;
});