define("securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/interactive-services-providers/interactive-services-provider-type-1/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/base-interactive-services-provider/component", "securitytrax-ember-ui/mixins/forms/adc"], function (_exports, _emberConcurrency, _component, _adc) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_adc.default, {
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    ispService: Ember.inject.service(),
    grants: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    screen: Ember.inject.service(),
    troubleConditionsLength: 0,
    lookupFormStarted: false,
    adcSerialNumber: null,
    custNotFound: false,
    confirmUnlink: false,
    branchOptions: Ember.computed('ispStatus', 'adcConnectionFailed', function () {
      return this.ispStatus ? this.adcBranchOptionsTask.perform() : [];
    }),
    pencilIconMissing: Ember.computed('adcConnectionFailed', 'ispStatus', function () {
      return this.pencilIconMissingTask.perform();
    }),
    pencilIconMissingTask: (0, _emberConcurrency.task)(function* () {
      var adcConnectionFailed = yield this.adcConnectionFailed;

      if (adcConnectionFailed === false && this.ispStatus !== false) {
        return 'no-edit';
      } else {
        return '';
      }
    }),
    // DREW TODO - not sure if actually need this so commenting for now
    // watchIspStatus: observer('ispStatus', function() {
    // DREW TODO - note that this observer fires because of this: watchInteractiveServicesProviderName: observer(
    // doing a resetForm here seems wrong and could cause issues - just noting this for the future
    // if (this.formMode === 'edit') {
    //     this.set('formMode', 'view');
    //     this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
    // }
    // commenting this resetForm because breaks the ISP save when switching ISP's - seems ok to comment for now
    // this.multiStepForm.resetForm(false);
    // }),
    lookupFormSteps: Ember.computed('multiStepForm.formCopiedModels.customerOrderInformation.model.interactiveServicesProvider.name', 'customer.flname', function () {
      var titleData = {
        ispName: this.multiStepForm.formCopiedModelsGetVal('customerOrderInformation.model.interactiveServicesProvider.name'),
        customerName: this.get('customer.flname')
      };
      return Ember.Object.create({
        lookupSingleStep: Ember.Object.create({
          stepNameKey: 'lookupSingleStep',
          title: this.intl.t('pages.customers.system.interactiveServicesProvider.lookup_title', titleData),
          stepComponentPath: 'multi-step-form/forms/customer-interactive-services-provider-information/adc/lookup'
        })
      });
    }),
    adcBestPractices: Ember.computed('ispStatus', 'adcConnectionFailed', function () {
      return this.adcBestPracticesTask.perform();
    }),
    adcBestPracticesTask: (0, _emberConcurrency.task)(function* () {
      var adcConnectionFailed = yield this.adcConnectionFailed;
      var bestPractices = {};

      if (this.ispStatus && !adcConnectionFailed) {
        try {
          bestPractices = yield this.ispService.get('invokeIspActionTask').perform({
            model: this.customer,
            action: 'alarm_dot_com__getCustomerBestPractices',
            attributes: {}
          });
        } catch (err) {
          this.set('custNotFound', this.custNotFound || err.errors[0].detail === 'Customer ID ' + this.multiStepForm.formCopiedModelsGetVal('customerSystemInformation.model.interactiveServicesId') + ' not found');
        }
      }

      return bestPractices ? bestPractices.customer_best_practices : {};
    }),
    adcTroubleConditions: Ember.computed('ispStatus', 'adcConnectionFailed', function () {
      return this.adcTroubleConditionsTask.perform();
    }),
    adcTroubleConditionsTask: (0, _emberConcurrency.task)(function* () {
      var adcConnectionFailed = yield this.adcConnectionFailed;
      var conditions = [];

      if (this.ispStatus && !adcConnectionFailed) {
        try {
          conditions = yield this.ispService.get('invokeIspActionTask').perform({
            model: this.customer,
            action: 'alarm_dot_com__getCustomerTroubleConditions',
            attributes: {}
          });
          this.set('troubleConditionsLength', conditions ? conditions.customer_trouble_conditions.get('length') : 0);
        } catch (err) {
          this.set('custNotFound', this.custNotFound || err.errors[0].detail === 'Customer ID ' + this.multiStepForm.formCopiedModelsGetVal('customerSystemInformation.model.interactiveServicesId') + ' not found');
        }
      }

      return conditions ? conditions.customer_trouble_conditions : [];
    }),
    shellAccountButton: Ember.computed(function () {
      return {
        label: this.intl.t('pages.customers.system.shellAccount.label'),
        action: () => {
          this.send('startIspForm');
        }
      };
    }),
    manageButton: Ember.computed(function () {
      return {
        label: this.intl.t('pages.customers.system.manage.label'),
        action: () => {
          this.send('startIspForm');
        }
      };
    }),
    unlinkButton: Ember.computed(function () {
      return {
        label: this.intl.t('pages.customers.system.interactiveServicesProvider.actions.unlink'),
        action: () => {
          this.set('confirmUnlink', true);
        }
      };
    }),
    shellAccountAdditionalClickActions: Ember.computed(function () {
      return [{
        label: this.intl.t('pages.customers.system.interactiveServicesProvider.actions.adcLookup'),
        action: () => {
          this.set('lookupFormStarted', true);
          this.set('lookupFirstStep', 'lookupSingleStep');
        },
        classNamesString: ''
      }];
    }),
    remoteToolkitAdditionalClickActions: Ember.computed(function () {
      return [{
        label: this.intl.t('pages.customers.system.interactiveServicesProvider.actions.mobileTech'),
        action: () => {
          this.urlBuilderTask.perform('mobile_tech');
        },
        classNamesString: 'dropdown-delete-customer'
      }, {
        label: this.intl.t('pages.customers.system.interactiveServicesProvider.actions.remoteToolkit'),
        action: () => {
          this.urlBuilderTask.perform('remote_tool_kit');
        },
        classNamesString: 'dropdown-save-close'
      }];
    }),
    urlBuilderTask: (0, _emberConcurrency.task)(function* (url) {
      if ((yield this.adcConnectionFailed) === false) {
        var loggedInUser = yield this.sessionData.getUser();
        var remoteToolkitUrlInfo = yield loggedInUser.invokeAction('get_single_sign_on_urls', {
          verb: 'POST',
          data: {
            data: {
              relationships: {
                interactive_services_provider: {
                  data: {
                    id: this.get('customerOrderInformation.interactiveServicesProvider.id'),
                    type: 'interactive_services_providers'
                  }
                },
                customer: {
                  data: {
                    id: this.get('customer.id'),
                    type: 'customers'
                  }
                }
              }
            }
          }
        });
        var newWin = window.open(remoteToolkitUrlInfo.responsePayload.data.single_sign_on_urls[url]);

        if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
          this.stNotificationManager.addWarningKey('common.notifications.popup_blocked');
        }
      }
    }),
    unlinkCustomer: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.ispService.get('invokeIspActionTask').perform({
          model: this.customer,
          action: 'alarm_dot_com__unlinkCustomer',
          attributes: {}
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'unlinkConfirm'
        });
      }
    }),
    actions: {
      startIspForm() {
        //We require everything need for adc create, except email, when creating customer.
        if (this.get('multiStepForm.formCopiedModels.customerSystemInformation.model.interactiveServicesId') || this.get('customer.email')) {
          return this.router.transitionTo(this.subroutePath + '.manage-adc');
        } else {
          var message = '<div><div>' + this.intl.t('pages.customers.system.adc_required.label') + '</div><div class="notification-ul"><ul><li>' + this.intl.t('common.columns.email') + '</li></ul></div></div>';
          this.stNotificationManager.addError(message);
        }
      }

    }
  });

  _exports.default = _default;
});