define("securitytrax-ember-ui/administration/org-chart/user/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller.extend({
    stNotificationManager: Ember.inject.service()
  });
  var _default = Controller;
  _exports.default = _default;
});