define("securitytrax-ember-ui/components/html/status-timeline-node/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "smmVsDAd",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"status-timeline__event-container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"event-container__icon-wrapper\"],[12],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,0],[\"icn-circle-check\"],[[\"class\"],[\"event-container__icon\"]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[1,[30,[36,0],[\"icn-activity-event\"],[[\"class\"],[\"event-container__icon--empty\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"event-container__text-wrapper\"],[12],[2,\"\\n        \"],[10,\"div\"],[15,0,[31,[\"event-container__text--primary \",[34,3]]]],[12],[2,\"\\n            \"],[1,[34,4]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"event-container__text-secondary\"],[12],[2,\"\\n            \"],[2,[34,5]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"svg-icon\",\"closed\",\"if\",\"primaryTextClass\",\"primaryText\",\"secondaryText\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/status-timeline-node/template.hbs"
    }
  });

  _exports.default = _default;
});