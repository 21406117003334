define("securitytrax-ember-ui/components/pages/administration/company-settings/global-settings/accounting/component", ["exports", "securitytrax-ember-ui/components/pages/administration/company-settings/global-settings/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    tagName: '',
    model: undefined,
    setupModelTask: (0, _emberConcurrency.task)(function* () {
      this.set('model', yield (0, _emberConcurrency.hash)({
        invoiceTerms: this.store.findAll('invoiceTerm'),
        pdfSettings: this.store.findAll('pdfSetting', {
          reload: true
        })
      }));
    }).on('init'),
    companyInformationFormSteps: Ember.computed(function () {
      return Ember.Object.create({
        settings: Ember.Object.create({
          stepNameKey: 'settings',
          title: 'invoice settings',
          stepComponentPath: 'multi-step-form/forms/administration/system/global-settings/edit-accounting-company-information'
        })
      });
    }),
    recurringInvoiceSettingsFormSteps: Ember.computed(function () {
      return Ember.Object.create({
        settings: Ember.Object.create({
          stepNameKey: 'recurringInvoiceSettings',
          title: 'recurring invoice settings',
          stepComponentPath: 'multi-step-form/forms/administration/system/global-settings/edit-recurring-invoice-settings'
        })
      });
    }),
    invoiceSettingsFormSteps: Ember.computed(function () {
      return Ember.Object.create({
        settings: Ember.Object.create({
          stepNameKey: 'settings',
          title: 'invoice settings',
          stepComponentPath: 'multi-step-form/forms/administration/system/global-settings/edit-invoice-settings'
        })
      });
    }),
    companyInformationFormOriginalModels: Ember.computed('model.pdfSettings', function () {
      return Ember.Object.create({
        pdfSetting: {
          model: this.model.pdfSettings.filterBy('settingsType', 'default')[0],
          copyRelationMap: {}
        }
      });
    }),
    recurringInvoiceSettingsFormOriginalModels: Ember.computed('model.pdfSettings', function () {
      return Ember.Object.create();
    }),
    invoiceSettingsFormOriginalModels: Ember.computed('model.pdfSettings', function () {
      return Ember.Object.create({
        pdfSetting: {
          model: this.model.pdfSettings.filterBy('settingsType', 'invoice')[0],
          copyRelationMap: {
            invoiceTerm: {
              readOnly: true
            }
          }
        }
      });
    }),
    quoteSettingsFormOriginalModels: Ember.computed('model.pdfSettings', function () {
      return Ember.Object.create({
        pdfSetting: {
          model: this.model.pdfSettings.filterBy('settingsType', 'quote')[0],
          copyRelationMap: {}
        }
      });
    }),
    creditMemoSettingsFormOriginalModels: Ember.computed('model.pdfSettings', function () {
      return Ember.Object.create({
        pdfSetting: {
          model: this.model.pdfSettings.filterBy('settingsType', 'credit_memo')[0],
          copyRelationMap: {}
        }
      });
    }),
    receiptSettingsFormOriginalModels: Ember.computed('model.pdfSettings', function () {
      return Ember.Object.create({
        pdfSetting: {
          model: this.model.pdfSettings.filterBy('settingsType', 'receipt')[0],
          copyRelationMap: {}
        }
      });
    }),
    statementSettingsFormOriginalModels: Ember.computed('model.pdfSettings', function () {
      return Ember.Object.create({
        pdfSetting: {
          model: this.model.pdfSettings.filterBy('settingsType', 'statement')[0],
          copyRelationMap: {}
        }
      });
    }),
    actions: {
      refreshModel() {
        this.setupModelTask.perform();
      }

    }
  });

  _exports.default = _default;
});