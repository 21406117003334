define("securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-payments/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gPgBAulf",
    "block": "{\"symbols\":[\"data\",\"multiStepForm\"],\"statements\":[[6,[37,17],null,[[\"useSpinner\",\"promises\",\"booleanLoaded\"],[false,[30,[36,16],null,[[\"customerPaymentTypes\",\"billTypes\"],[[35,15],[35,14]]]],[30,[36,5],[[35,8],true],null]]],[[\"default\"],[{\"statements\":[[6,[37,13],null,[[\"currentStepNameKey\",\"changeCurrentStepNameKey\",\"formSteps\",\"formOriginalModels\",\"formStarted\",\"changeFormStarted\"],[[35,12],[30,[36,0],[[32,0],[30,[36,9],[[35,12]],null]],null],[35,11],[35,10],[35,8],[30,[36,0],[[32,0],[30,[36,9],[[35,8]],null]],null]]],[[\"default\"],[{\"statements\":[[6,[37,7],null,[[\"modalButtons\",\"title\",\"classNames\",\"dialogOpen\",\"onClose\",\"multiStepForm\",\"newWindowLink\",\"customer\"],[[32,2,[\"currentStepData\",\"buttons\"]],[32,2,[\"currentStepData\",\"title\"]],[30,[36,6],[[30,[36,5],[[35,2],\"create\"],null],\"st-modal--large\",\"st-modal--medium\"],null],[32,2,[\"formStarted\"]],[30,[36,0],[[32,0],\"transitionBackToParentRoute\"],null],[32,2],true,[35,3]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,4],[[32,2,[\"currentStepData\",\"stepComponentPath\"]]],[[\"progressBarHidden\",\"multiStepForm\",\"customer\",\"formMode\",\"billTypes\",\"customerPaymentTypes\",\"customerPayment\",\"singlePage\",\"transitionBackToParent\"],[true,[32,2],[35,3],[35,2],[32,1,[\"resolvedPromises\",\"billTypes\"]],[32,1,[\"resolvedPromises\",\"customerPaymentTypes\"]],[35,1],true,[30,[36,0],[[32,0],\"transitionBackToParentRoute\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"customerPayment\",\"customerPaymentFormMode\",\"customer\",\"component\",\"eq\",\"if\",\"modals/st-paper\",\"customerPaymentFormStarted\",\"mut\",\"customerPaymentFormOriginalModels\",\"customerPaymentFormSteps\",\"customerPaymentFirstStep\",\"multi-step-form\",\"billTypes\",\"customerPaymentTypes\",\"hash\",\"html/st-await\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-payments/template.hbs"
    }
  });

  _exports.default = _default;
});