define("securitytrax-ember-ui/components/multi-step-form/forms/customer/upload-file/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MB6eTkaV",
    "block": "{\"symbols\":[],\"statements\":[[8,\"html/file-upload\",[[24,0,\"file-upload\"]],[[\"@url\",\"@dictDefaultMessage\",\"@afterFileUpload\",\"@additionalFields\"],[[32,0,[\"apiEndpoint\"]],[30,[36,1],[[30,[36,0],[\"pages.customers.files.dropzone.top\"],null],\"<br />\",[30,[36,0],[\"pages.customers.files.dropzone.middle\"],null],\"<br /> <button id=\\\"select-files-btn\\\" class=\\\"btn-primary btn\\\"><div><span>\",[30,[36,0],[\"pages.customers.files.dropzone.button\"],null],\"</span></div></button>\"],null],[30,[36,3],[[35,2]],null],[30,[36,4],[[32,0],\"additionalFields\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"t\",\"concat\",\"fileUploadedTask\",\"perform\",\"action\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/customer/upload-file/template.hbs"
    }
  });

  _exports.default = _default;
});