define("securitytrax-ember-ui/components/multi-step-form/switchable-inputs/password-mask-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vHdy/V3y",
    "block": "{\"symbols\":[\"&attrs\",\"@formMode\",\"@label\",\"@required\",\"@errors\",\"@validationKey\"],\"statements\":[[8,\"multi-step-form/switchable-inputs/text-input\",[[17,1]],[[\"@formMode\",\"@value\",\"@type\",\"@label\",\"@onClick\",\"@focusOut\",\"@required\",\"@errors\",\"@validationKey\"],[[32,2],[32,0,[\"password\"]],\"password\",[32,3],[30,[36,0],[[32,0],\"eraseDefaultPassword\"],null],[30,[36,0],[[32,0],\"updateRealPassword\"],null],[32,4],[32,5],[32,6]]],null]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/switchable-inputs/password-mask-input/template.hbs"
    }
  });

  _exports.default = _default;
});