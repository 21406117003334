define("securitytrax-ember-ui/components/html/st-leaf/user-leaf/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import jQuery from 'jquery';
  var _default = Ember.Component.extend({
    treeChainService: Ember.inject.service(),
    router: Ember.inject.service(),
    classNames: ['st-user-leaf'],
    mediaOrientation: 'st-media-object--portrait',
    tabindex: 0,
    attributeBindings: ['tabindex'],
    isCurrentUser: false,

    init() {
      this._super(...arguments);

      this.getUserTitleTask.perform();
      this.getHiearchyTask.perform();
    },

    willUpdate() {
      //Matt TODO: Instead of having this and setting it in the task just make a computed property that calls and returns the task. Same with count.
      this._super(...arguments);

      this.getUserTitleTask.perform();
      this.getHiearchyTask.perform();
    },

    leafExtension: Ember.computed('isCurrentUser', function () {
      return this.isCurrentUser ? 'st-user-leaf__stem--connector' : '';
    }),
    userHiearchy: null,
    userHiearchyChildrenCount: 0,
    userTitle: null,
    getUserTitleTask: (0, _emberConcurrency.task)(function* () {
      var user = yield this.user;

      if (user) {
        var hrSettings = yield user.get('userHrSetting');

        if (hrSettings) {
          var hiearchyTitle = yield hrSettings.get('userHierarchyTitle');

          if (hiearchyTitle) {
            this.set('userTitle', hiearchyTitle.get('name'));
          }
        }
      }
    }),
    getHiearchyTask: (0, _emberConcurrency.task)(function* () {
      var user = yield this.user;

      if (user) {
        var userHierarchies = yield user.get('userHierarchies');

        if (userHierarchies) {
          var userHiearchy = userHierarchies.find(hiearchy => hiearchy.get('id') == JSON.stringify({
            user_hierarchy_tree_id: '1',
            user_id: user.get('id')
          }));

          if (userHiearchy) {
            this.set('userHiearchy', userHiearchy);
            var userHiearchyChildren = yield userHiearchy.get('children');

            if (userHiearchyChildren) {
              this.set('userHiearchyChildrenCount', userHiearchyChildren.length);
            }
          }
        }
      }
    }),

    /* click(){
          const treeChainService = this.get('treeChainService');
          treeChainService.set('focusedUser', this.get('user'));
        // jQuery().focus();
    }, */
    doubleClick() {
      if (!this.isCurrentUser) {
        var router = this.router;
        var currentRoute = router.get('currentRouteName').split('.');
        currentRoute.pop();
        var user = this.get('user.id');
        this.router.transitionTo('/' + currentRoute.join('/') + '/' + user);
      }
    },

    actions: {
      dropDownClick(dropdown) {
        dropdown.actions.toggle();
        return false;
      },

      pillClick() {
        if (!this.isCurrentUser) {
          var router = this.router;
          var currentRoute = router.get('currentRouteName').split('.');
          currentRoute.pop();
          var user = this.get('user.id');
          this.router.transitionTo('/' + currentRoute.join('/') + '/' + user);
        }
      }

    }
  });

  _exports.default = _default;
});