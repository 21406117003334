define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/forms/standard/order/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/order/component", "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/order/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _template.default,
    setupStep3Buttons: function () {
      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('step3', 'buttons', [{
        text: this.intl.t('common.actions.back'),
        classNamesString: 'st-btn st-btn-secondary',
        click: () => {
          Ember.set(multiStepForm, 'currentStepNameKey', 'step2');
          multiStepForm.progressBarConfig.activeIndex = 1;
        }
      }, {
        text: this.intl.t('common.actions.saveAndClose'),
        classNamesString: 'st-btn st-btn-primary customer-information-save-button',
        positionOptions: 'up left',
        click: buttonPromise => {
          return this.applyOrderTemplateTask.perform(multiStepForm, buttonPromise, true);
        },
        additionalClickActions: [{
          label: this.intl.t('common.actions.deleteCustomer'),
          action: () => {
            this.set('formMode', 'create');
            this.openConfirmDelete('step3');
          },
          classNamesString: 'dropdown-delete-customer'
        }]
      }]);
    }
  });

  _exports.default = _default;
});