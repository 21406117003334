define("securitytrax-ember-ui/components/form-group/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lQ68PI+t",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,3],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"label\"],[15,\"title\",[34,1]],[15,0,[31,[[34,2]]]],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"label\"],[12],[1,[34,1]],[13],[2,\"\\n\"],[6,[37,3],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"span\"],[14,0,\"description\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[18,1,null],[2,\"\\n\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"labelDescriptionText\",\"label\",\"labelClass\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/form-group/template.hbs"
    }
  });

  _exports.default = _default;
});