define("securitytrax-ember-ui/models/customer-credit-check-print-image", ["exports", "securitytrax-ember-ui/models/base_models/customer-credit-check-print-image"], function (_exports, _customerCreditCheckPrintImage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerCreditCheckPrintImage.default.extend({
    printImageRefined: Ember.computed('printImage', function () {
      return this.printImage.replace(/(?:\r\n|\r|\n)/g, '<br />');
    })
  });

  _exports.default = _default;
});