define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/interactive-services-providers/alula/interactive-services-provider-base", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency"], function (_exports, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    ispService: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    saveInteractiveServicesProviderConfig: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var validationObject = {
        deepValidate: true,
        deepCreate: false,
        extraValidationData: {
          noValidate: ['interactiveServicesProviderConfig-password', 'interactiveServicesProviderConfig-referralLeadCompanyId', 'interactiveServicesProviderConfig-dealerWebsiteLeadCompanyId', 'interactiveServicesProviderConfig-selfCreatedLeadCompanyId', 'interactiveServicesProviderConfig-referAFriendLeadCompanyId', 'interactiveServicesProviderConfig-serviceRequestServiceTechId', 'interactiveServicesProviderConfig-serviceRequestUpsellTechId']
        },
        notificationNamespace: 'modal',
        successNotificationNamespace: 'modal'
      };
      yield multiStepForm.validateSyncSaveAll(validationObject);
      buttonPromise.then(() => {
        multiStepForm.setupCopyModels.perform();
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
      });
    })
  });

  _exports.default = _default;
});