define("securitytrax-ember-ui/models/base_models/calendar-event", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    calendarCategory: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['tech_schedule', 'sale_schedule']
    }),
    startTime: (0, _model.attr)('moment-date-time-no-timezone', {
      'readOnly': false
    }),
    duration: (0, _model.attr)('number', {
      'readOnly': false
    }),
    notes: (0, _model.attr)('string', {
      'readOnly': false
    }),
    userNotes: (0, _model.attr)('string', {
      'readOnly': false
    }),
    remoteUniqueId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    workOrderNumber: (0, _model.attr)('string', {
      'readOnly': false
    }),
    interactiveServicesId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    status: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['active', 'deleted']
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    customer: (0, _model.belongsTo)('customer', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': 'calendarEvents'
    }),
    workOrder: (0, _model.belongsTo)('work-order', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    customerTask: (0, _model.belongsTo)('customer-task', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    calendarUser: (0, _model.belongsTo)('calendar-user', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    calendarEventType: (0, _model.belongsTo)('calendar-event-type', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    calendarEventDetailType: (0, _model.belongsTo)('calendar-event-detail-type', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    dryRunReason: (0, _model.belongsTo)('dry-run-reason', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    recurringParentCalendarEvent: (0, _model.belongsTo)('calendar-event', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': 'recurringChildrenCalendarEvents'
    }),
    tags: (0, _model.hasMany)('tag', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    recurringChildrenCalendarEvents: (0, _model.hasMany)('calendar-event', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': 'recurringParentCalendarEvent'
    }),
    customerEquipmentInstalls: (0, _model.hasMany)('customer-equipment', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': 'installCalendarEvent'
    }),
    customerEquipmentUninstall: (0, _model.hasMany)('customer-equipment', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': 'uninstallCalendarEvent'
    }),
    udfs: (0, _model.hasMany)('udf', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});