define("securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-electronic-document-provider/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EditCreateEdocProvider = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service('events/docusign-event'), _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = (0, _emberConcurrency.task)(function* () {
    this.eDocumentProviderTypes = yield this.store.findAll('electronicDocumentProviderType');

    if (this.args.electronicDocumentProviderId) {
      if (this.args.electronicDocumentProviderId !== 'create') {
        this.eDocumentProvider = yield this.store.findRecord('electronicDocumentProvider', this.args.electronicDocumentProviderId);
      }

      this.eDocumentFormStarted = true;
    }

    if (this.grants.getIndividualGrant('electronic_document_providers.create') && !this.eDocumentProvider) {
      this.eDocumentFormMode = 'create';
    } else if (this.grants.getIndividualGrant('electronic_document_providers.modify') && this.eDocumentProvider) {
      this.eDocumentFormMode = 'edit';
    } else {
      this.eDocumentFormMode = 'view';
    }

    this.editTitle = this.eDocumentProvider ? this.intl.t('common.forms.descriptive_title_view', {
      name: this.eDocumentProvider.name,
      id: this.eDocumentProvider.id
    }) : null;

    if (this.router.currentRoute.queryParams.state) {
      this.eDocumentCurrentStep = 'integration';
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }), _dec13 = Ember._action, _dec14 = Ember._action, (_class = (_temp = class EditCreateEdocProvider extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "grants", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "docusignEvent", _descriptor5, this);

      _initializerDefineProperty(this, "eDocumentProvider", _descriptor6, this);

      _initializerDefineProperty(this, "eDocumentFormMode", _descriptor7, this);

      _initializerDefineProperty(this, "eDocumentFormStarted", _descriptor8, this);

      _initializerDefineProperty(this, "eDocumentCurrentStep", _descriptor9, this);

      _initializerDefineProperty(this, "eDocumentProviderTypes", _descriptor10, this);

      _initializerDefineProperty(this, "editTitle", _descriptor11, this);

      _initializerDefineProperty(this, "initData", _descriptor12, this);

      this.initData.perform();
    }

    get eDocumentFormSteps() {
      return Ember.Object.create({
        general: Ember.Object.create({
          stepNameKey: 'general',
          title: this.eDocumentFormMode === 'edit' && this.editTitle ? this.editTitle : this.intl.t("pages.administration.integrations.electronic_document_providers.add_modal"),
          stepComponentPath: 'multi-step-form/forms/administration/integrations/electronic-document-providers/edit-create',
          progressBarTitle: this.intl.t("common.nav.general")
        }),
        integration: Ember.Object.create({
          stepNameKey: 'integration',
          title: this.editTitle || '',
          stepComponentPath: 'multi-step-form/forms/administration/integrations/electronic-document-providers/integrations',
          progressBarTitle: this.intl.t("pages.administration.integrations.progress_bar_integration")
        })
      });
    }

    get eDocumentFormOriginalModels() {
      var grants = this.grants;
      return Ember.Object.create({
        electronicDocumentProvider: {
          model: this.eDocumentProvider,
          copyRelationMap: {
            electronicDocumentProviderType: {
              readOnly: true
            },
            electronicDocumentProviderConfig: {
              doCopy: grants.getIndividualGrant('electronic_document_provider_configs.manage'),
              doSync: grants.getIndividualGrant('electronic_document_provider_configs.manage'),
              doSave: grants.getIndividualGrant('electronic_document_provider_configs.manage')
            }
          }
        }
      });
    }

    get isFullScreen() {
      var hasGrant = this.grants.getIndividualGrant('electronic_document_provider_configs.manage');
      return this.eDocumentFormMode === 'edit' && hasGrant;
    }

    transitionBackToParentRoute() {
      this.router.transitionTo(this.args.parentRoute);
      this.docusignEvent.trigger('refresh-list');
    }

    onAfterCreate(saved) {
      this.eDocumentProvider = saved;
      this.editTitle = this.eDocumentProvider ? this.intl.t('common.forms.descriptive_title_view', {
        name: this.eDocumentProvider.name,
        id: this.eDocumentProvider.id
      }) : null;
      this.eDocumentFormMode = 'edit';
      this.docusignEvent.trigger('refresh-list');
      this.router.transitionTo('administration.company-settings.integrations.electronic-document-providers.edit-create-electronic-document-provider', this.eDocumentProvider.get('id'));
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "docusignEvent", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "eDocumentProvider", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "eDocumentFormMode", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'edit';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "eDocumentFormStarted", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "eDocumentCurrentStep", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'general';
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "eDocumentProviderTypes", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "editTitle", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "initData", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "transitionBackToParentRoute", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "transitionBackToParentRoute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onAfterCreate", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "onAfterCreate"), _class.prototype)), _class));
  _exports.default = EditCreateEdocProvider;
});