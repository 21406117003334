define("securitytrax-ember-ui/components/nav/top-nav/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9DtCKOsF",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,7],[[35,6,[\"isAuthenticated\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"nav/top-nav/nav\",[],[[\"@company\",\"@hasAGrant\",\"@user\",\"@canViewCompanySettings\",\"@canViewHumanResources\",\"@afterCreateCustomer\"],[[34,0],[34,1],[34,2],[34,3],[34,4],[34,5]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"company\",\"hasAGrant\",\"user\",\"canViewCompanySettings\",\"canViewHumanResources\",\"afterCreateCustomer\",\"session\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/nav/top-nav/template.hbs"
    }
  });

  _exports.default = _default;
});