define("securitytrax-ember-ui/models/base_models/report-filter", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    name: (0, _model.attr)('string', {
      'readOnly': false
    }),
    mainInputDataType: (0, _model.attr)('string', {
      'readOnly': false
    }),
    mainInputDataFormat: (0, _model.attr)('string', {
      'readOnly': false
    }),
    mainInputName: (0, _model.attr)('string', {
      'readOnly': false
    }),
    mainInputLabel: (0, _model.attr)('string', {
      'readOnly': false
    }),
    mainInputRequired: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    mainInputListOptions: (0, _model.attr)('json', {
      'readOnly': false
    }),
    mainInput: (0, _model.attr)('string', {
      'readOnly': false
    }),
    secondInputDataType: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    secondInputDataFormat: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    secondInputName: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    secondInputLabel: (0, _model.attr)('number', {
      'readOnly': false
    }),
    secondInputRequired: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    secondInputListOptions: (0, _model.attr)('json', {
      'readOnly': false
    }),
    secondInput: (0, _model.attr)('string', {
      'readOnly': false
    }),
    thirdInputDataType: (0, _model.attr)('string', {
      'readOnly': false
    }),
    thirdInputDataFormat: (0, _model.attr)('string', {
      'readOnly': false
    }),
    thirdInputName: (0, _model.attr)('string', {
      'readOnly': false
    }),
    thirdInputLabel: (0, _model.attr)('string', {
      'readOnly': false
    }),
    thirdInputRequired: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    thirdInputListOptions: (0, _model.attr)('json', {
      'readOnly': false
    }),
    thirdInput: (0, _model.attr)('string', {
      'readOnly': false
    })
  });

  _exports.default = _default;
});