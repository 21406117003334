define("securitytrax-ember-ui/components/multi-step-form/forms/tech-today/customer-equipment/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixed-in-classes/set-user", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "@glimmer/component", "moment"], function (_exports, _emberConcurrency, _setUser, _switchableFormStep, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var TechTodayCustomerEquipment = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
    var hashedData = yield (0, _emberConcurrency.hash)({
      technicianList: this.userService.getUsersList('tech', this.args.customer),
      equipmentStatus: this.store.findAll('equipmentStatus'),
      visitOptions: this.visitOptionsTask.perform()
    });
    Ember.setProperties(this, hashedData);
  }), _dec11 = (0, _emberConcurrency.task)(function* () {
    var calendarEvents = yield this.args.customer.get('calendarEvents');
    var user = undefined;

    for (var i = 0; i < calendarEvents.get('length'); i++) {
      var event = calendarEvents.objectAt(i);

      if (event.get('calendarCategory') === 'tech_schedule') {
        if (event.get('user.flname')) {
          user = event.get('user.flname');
        }

        var calendarEventType = yield event.get('calendarEventType');
        var calendarEventName = yield calendarEventType.get('name');
        var calendarEventDate = (0, _moment.default)(event.get('startTime')).format('M/DD/YYYY');
        event.set('name', user ? "".concat(user, " (").concat(calendarEventName, " | ").concat(calendarEventDate, ")") : "".concat(calendarEventName, " | ").concat(calendarEventDate));
      }
    }

    var equipmentStatus = this.args.multiStepForm.formCopiedModelsGetVal('customerEquipment.model.equipmentStatus');
    var status = equipmentStatus.get('id') === '3' ? 'uninstall' : 'install';

    if (yield this.args.multiStepForm.formCopiedModelsGetVal("customerEquipment.model.".concat(status, "CalendarEvent")) == undefined) {
      this.args.multiStepForm.formCopiedModelsSetVal("customerEquipment.model.".concat(status, "CalendarEvent"), this.args.calendarEvent);
    }

    if (yield this.args.multiStepForm.formCopiedModelsGetVal("customerEquipment.model.".concat(status, "InventoryTechUser")) == undefined) {
      this.args.multiStepForm.formCopiedModelsSetVal("customerEquipment.model.".concat(status, "InventoryTechUser"), this.args.user);
    }

    return calendarEvents;
  }), _dec12 = (0, _emberConcurrency.task)(function* (buttonPromise, multiStepForm) {
    var extraValidationData = {
      noValidate: ['group', 'equipment', 'user', 'workOrderAction']
    };
    yield multiStepForm.validateSyncSaveAll({
      deepSave: false,
      deepValidate: false,
      extraValidationData: extraValidationData,
      notificationNamespace: 'modal'
    });
    this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
    buttonPromise.then(() => {
      multiStepForm.formStartedSetVal(false);
    });
  }), _dec13 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var equipmentId = this.args.multiStepForm.formCopiedModelsGetVal('customerEquipment.model.id');
    var equipmentToDelete = yield this.store.findRecord('customerEquipment', equipmentId);
    yield equipmentToDelete.destroyRecordWithRollbackTask.perform();
    buttonPromise.then(() => {
      this.args.multiStepForm.formStartedSetVal(false);
      this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');

      if (this.args.afterEquipmentDelete) {
        this.args.afterEquipmentDelete();
      }
    });
  }), _dec14 = Ember._action, _dec15 = Ember._action, (_class = (_temp = class TechTodayCustomerEquipment extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "grants", _descriptor3, this);

      _initializerDefineProperty(this, "userService", _descriptor4, this);

      _initializerDefineProperty(this, "store", _descriptor5, this);

      _initializerDefineProperty(this, "equipmentStatus", _descriptor6, this);

      _initializerDefineProperty(this, "technicianList", _descriptor7, this);

      _initializerDefineProperty(this, "visitOptions", _descriptor8, this);

      _initializerDefineProperty(this, "confirmDeleteModalOpen", _descriptor9, this);

      _initializerDefineProperty(this, "initFormData", _descriptor10, this);

      _initializerDefineProperty(this, "visitOptionsTask", _descriptor11, this);

      _initializerDefineProperty(this, "saveCustomerEquipmentTask", _descriptor12, this);

      _initializerDefineProperty(this, "deleteCustomerEquipmentTask", _descriptor13, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.SetUser = new _setUser.default(...arguments);
      this.initFormData.perform();
    }

    updateAppointmentAndTech() {
      var equipmentStatus = this.args.multiStepForm.formCopiedModelsGetVal('customerEquipment.model.equipmentStatus');
      var status = equipmentStatus.get('id') === '3' ? 'uninstall' : 'install';
      this.args.multiStepForm.formCopiedModelsSetVal("customerEquipment.model.".concat(status, "CalendarEvent"), this.args.calendarEvent);
      this.args.multiStepForm.formCopiedModelsSetVal("customerEquipment.model.".concat(status, "InventoryTechUser"), this.args.user);
    }

    buttons() {
      var buttons = [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.saveCustomerEquipmentTask.perform(buttonPromise, this.args.multiStepForm);
        },
        classNamesString: 'st-btn st-btn-primary'
      }];

      if (this.grants.getIndividualGrant('customer_equipment.delete')) {
        buttons.push({
          text: this.intl.t('common.actions.delete'),
          click: () => {
            this.confirmDeleteModalOpen = true;
          },
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
        });
      }

      this.args.multiStepForm.registerStepData('equipmentStatus', 'buttons', buttons);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "userService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "equipmentStatus", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "technicianList", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "visitOptions", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "confirmDeleteModalOpen", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "initFormData", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "visitOptionsTask", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "saveCustomerEquipmentTask", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "deleteCustomerEquipmentTask", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateAppointmentAndTech", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "updateAppointmentAndTech"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buttons", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "buttons"), _class.prototype)), _class));
  _exports.default = TechTodayCustomerEquipment;
});