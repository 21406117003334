define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/monitoring-companies/integrations/brinks-integration/component", ["exports", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "securitytrax-ember-ui/mixed-in-classes/monitoring-company-connection", "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/monitoring-companies/integrations/integrations-base", "ember-concurrency", "securitytrax-ember-ui/mixed-in-classes/row-list", "lodash", "securitytrax-ember-ui/utils/strings"], function (_exports, _switchableFormStep, _monitoringCompanyConnection, _integrationsBase, _emberConcurrency, _rowList, _lodash, _strings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var BrinksIntegration = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
    var listData = this.args.multiStepForm.formOriginalModels.monitoringCompany.model.isConnected ? yield this.centralStationService.getData({
      model: this.args.monitoringCompany,
      method: 'brinks__getLists',
      payload: {
        list_types: [{
          list_type: '_options_parsed'
        }, {
          list_type: 'two_way_devices'
        }, {
          list_type: 'site_types'
        }, {
          list_type: 'system_types'
        }, {
          list_type: 'system_type_x_refs'
        }]
      }
    }) : null;

    if (listData) {
      this.getDefaultSiteOptionsList(listData.lists._options_parsed);
      this.getDefaultSystemOptionsList(listData.lists._options_parsed);
      this.siteTypeOptions = listData.lists.site_types;
      this.systemTypeOptions = listData.lists.system_types;
      this.twoWayDeviceOptions = listData.lists.two_way_devices;
      this.secondarySystemOptions = [];
      this.systemTypeCrossRefs = listData.lists.system_type_x_refs;
      var currSystemTypeId = this.args.multiStepForm.formCopiedModelsGetVal('monitoringCompany.model.monitoringCompanyConfig.defaultCustomerInformation.system_type_id');
      var currSecondarySystemTypeId = this.args.multiStepForm.formCopiedModelsGetVal('monitoringCompany.model.monitoringCompanyConfig.defaultCustomerInformation.secondary_system_type_id');
      this.setSecondarySystemOptions(_lodash.default.find(this.systemTypeOptions, {
        id: currSystemTypeId
      }));
      this.setDeviceTypeOptions(_lodash.default.find(this.systemTypeOptions, {
        id: currSecondarySystemTypeId
      }));
    }
  }), _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, (_class = (_temp = class BrinksIntegration extends _integrationsBase.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "centralStationService", _descriptor, this);

      _initializerDefineProperty(this, "siteOptionDeleteId", _descriptor2, this);

      _initializerDefineProperty(this, "systemOptionDeleteId", _descriptor3, this);

      _initializerDefineProperty(this, "secondarySystemOptions", _descriptor4, this);

      _initializerDefineProperty(this, "filteredTwoWayDeviceOptions", _descriptor5, this);

      _defineProperty(this, "systemOptionsList", undefined);

      _defineProperty(this, "siteOptionsList", undefined);

      _defineProperty(this, "siteTypeOptions", undefined);

      _defineProperty(this, "systemTypeOptions", undefined);

      _defineProperty(this, "twoWayDeviceOptions", undefined);

      _defineProperty(this, "systemTypeCrossRefs", undefined);

      _initializerDefineProperty(this, "initDataTask", _descriptor6, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.MonitoringCompanyConnection = new _monitoringCompanyConnection.default(...arguments);
      this.SiteRowList = new _rowList.default(...arguments);
      this.SystemRowList = new _rowList.default(...arguments);
      this.initDataTask.perform();
    }

    getDefaultSystemOptionsList(options) {
      try {
        if (!this.args.multiStepForm.formCopiedModelsGetVal('monitoringCompany.model.monitoringCompanyConfig.additionalDefaultSystemOptions')) {
          this.args.multiStepForm.formCopiedModelsSetVal('monitoringCompany.model.monitoringCompanyConfig.additionalDefaultSystemOptions', []);
        }

        var filteredOptionsList = options.filter(option => {
          return option.usage.includes('System');
        });
        this.systemOptionsList = filteredOptionsList[0].options;
        var savedAdditionalOptions = this.args.multiStepForm.formCopiedModelsGetVal('monitoringCompany.model.monitoringCompanyConfig.additionalDefaultSystemOptions').map((option, index) => {
          var returnOption = Ember.Object.create({
            name: option.system_option_value,
            id: option.system_option_value,
            value: option.system_option_value_default
          });
          this.updateOption(returnOption, index, 'system');
          return returnOption;
        });
        this.SystemRowList.listArray = savedAdditionalOptions;
        this.SystemRowList.addRow();
      } catch (err) {
        (false && Ember.warn('Failed to get default system options list: ' + err, {
          id: 'brinks-integration.getDefaultSystemOptionsList'
        }));
        this.systemOptionsList = [];
        this.SystemRowList.listArray = [];
        this.SystemRowList.addRow();
      }
    }

    getDefaultSiteOptionsList(options) {
      try {
        if (!this.args.multiStepForm.formCopiedModelsGetVal('monitoringCompany.model.monitoringCompanyConfig.additionalDefaultSiteOptions')) {
          this.args.multiStepForm.formCopiedModelsSetVal('monitoringCompany.model.monitoringCompanyConfig.additionalDefaultSiteOptions', []);
        }

        var filteredOptionsList = options.filter(option => {
          return option.usage.includes('Site');
        });
        this.siteOptionsList = filteredOptionsList[0].options;
        var savedAdditionalOptions = this.args.multiStepForm.formCopiedModelsGetVal('monitoringCompany.model.monitoringCompanyConfig.additionalDefaultSiteOptions').map((option, index) => {
          var returnOption = Ember.Object.create({
            name: option.site_option_value,
            id: option.site_option_value,
            value: option.site_option_value_default
          });
          this.updateOption(returnOption, index, 'site');
          return returnOption;
        });
        this.SiteRowList.listArray = savedAdditionalOptions;
        this.SiteRowList.addRow();
      } catch (err) {
        (false && Ember.warn('Failed to get default site options list: ' + err, {
          id: 'brinks-integration.getDefaultSiteOptionsList'
        }));
        this.siteOptionsList = [];
        this.SiteRowList.listArray = [];
        this.SiteRowList.addRow();
      }
    }

    updateOption(option, index, usage) {
      var rowList = Ember.get(this, (0, _strings.ucwords)(usage) + 'RowList');

      var optionObject = _lodash.default.find(Ember.get(this, usage + 'OptionsList'), {
        id: option.id
      });

      option.set('inputType', optionObject.type);
      option.set('dropdownOptions', optionObject.values);
      rowList.updateRow(option, index, false);

      if (!option.id) {
        rowList.listArray.removeAt(index);
      }
    }

    deleteOption(index, usage) {
      Ember.get(this, (0, _strings.ucwords)(usage) + 'RowList').listArray.removeAt(index);
      Ember.set(this, usage + 'OptionDeleteId', null);
    }

    setSecondarySystemOptions(option) {
      var secondarySystemOptions = [];

      if (option) {
        var crossRefs = this.systemTypeCrossRefs.filter(crossRefs => {
          return crossRefs.digital_system_type_id === option.id || crossRefs.cell_system_type_id === option.id;
        });
        crossRefs.forEach(crossRef => {
          var secondarySystemOption;

          if (crossRef.digital_system_type_id === option.id) {
            secondarySystemOption = _lodash.default.find(this.systemTypeOptions, {
              id: crossRef.cell_system_type_id
            });
          } else {
            secondarySystemOption = _lodash.default.find(this.systemTypeOptions, {
              id: crossRef.digital_system_type_id
            });
          }

          secondarySystemOptions.push(secondarySystemOption);
        });
        this.filteredTwoWayDeviceOptions = [];
        this.secondarySystemOptions = secondarySystemOptions;
      } else {
        this.secondarySystemOptions = [];
        this.args.multiStepForm.formCopiedModelsSetVal('monitoringCompany.model.monitoringCompanyConfig.defaultCustomerInformation.secondary_system_type_id', null);
        this.setDeviceTypeOptions(null);
      }
    }

    setDeviceTypeOptions(option) {
      var newTwoWayOptions = [];
      var systemTypeId = this.args.multiStepForm.formCopiedModelsGetVal('monitoringCompany.model.monitoringCompanyConfig.defaultCustomerInformation.system_type_id');

      if (option) {
        var crossRefs = this.systemTypeCrossRefs.filter(crossRefs => {
          return crossRefs.digital_system_type_id === systemTypeId && crossRefs.cell_system_type_id === option.id || crossRefs.cell_system_type_id === systemTypeId && crossRefs.digital_system_type_id === option.id;
        });
        crossRefs.forEach(crossRef => {
          if (crossRef.two_way_device_id) {
            newTwoWayOptions.push(_lodash.default.find(this.twoWayDeviceOptions, {
              id: crossRef.two_way_device_id
            }));
          }
        });
        this.filteredTwoWayDeviceOptions = newTwoWayOptions;
      } else {
        this.filteredTwoWayDeviceOptions = [];
        this.args.multiStepForm.formCopiedModelsSetVal('monitoringCompany.model.monitoringCompanyConfig.defaultCustomerInformation.two_way_device_id', null);
      }
    }

    mcSpecificAdditionalSaveActions() {
      var formattedListArray = this.SystemRowList.trueListArray.map((option, index) => {
        return {
          system_option_number: index,
          system_option_value: option.id,
          system_option_value_default: option.value
        };
      });
      var formattedSiteListArray = this.SiteRowList.trueListArray.map((option, index) => {
        return {
          site_option_number: index,
          site_option_value: option.id,
          site_option_value_default: option.value
        };
      });
      this.args.multiStepForm.formCopiedModels.monitoringCompany.model.monitoringCompanyConfig.set('additionalDefaultSystemOptions', formattedListArray);
      this.args.multiStepForm.formCopiedModels.monitoringCompany.model.monitoringCompanyConfig.set('additionalDefaultSiteOptions', formattedSiteListArray);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "siteOptionDeleteId", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "systemOptionDeleteId", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "secondarySystemOptions", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "filteredTwoWayDeviceOptions", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateOption", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "updateOption"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteOption", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "deleteOption"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setSecondarySystemOptions", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "setSecondarySystemOptions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setDeviceTypeOptions", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "setDeviceTypeOptions"), _class.prototype)), _class));
  _exports.default = BrinksIntegration;
});