define("securitytrax-ember-ui/components/multi-step-form/forms/customer-equipment/add-equipment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Pqy4rzCF",
    "block": "{\"symbols\":[\"data\",\"FormComponent\"],\"statements\":[[8,\"html/st-await\",[],[[\"@useSpinner\",\"@promises\",\"@booleanLoaded\"],[true,[30,[36,9],null,[[\"workOrder\"],[[35,8]]]],[34,10,[\"isIdle\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"equipment-modal\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"grid-x\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"cell\"],[12],[2,\"\\n                \"],[8,\"form-group\",[],[[\"@label\"],[[30,[36,11],[\"pages.customers.equipment.selectMethod.label\"],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[]}]]],[2,\"\\n                \"],[8,\"html/package-select/package-layouts/default-layout\",[[24,0,\"add-equipment-options\"]],[[\"@isLargeLabel\",\"@valueProperty\",\"@labelProperty\",\"@packageList\",\"@selectedId\",\"@updateSelected\"],[true,\"quantity\",\"name\",[34,12],[34,13],[30,[36,15],[[32,0],[30,[36,14],[[35,13]],null]],null]]],null],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n\"],[6,[37,18],[[30,[36,17],[[35,16]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,[32,2],[],[[\"@formMode\",\"@multiStepForm\",\"@customerEquipment\",\"@customer\",\"@equipment\",\"@defaultEquipmentStatus\",\"@isAdtMegaForm\",\"@workOrder\",\"@monitoringCompanyType\"],[[34,0],[34,1],[34,2],[34,3],[34,4],[34,5],[34,6],[32,1,[\"resolvedPromises\",\"workOrder\"]],[34,7]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"formMode\",\"multiStepForm\",\"customerEquipment\",\"customer\",\"equipment\",\"defaultEquipmentStatus\",\"isAdtMegaForm\",\"monitoringCompanyType\",\"workOrder\",\"hash\",\"initDataTask\",\"t\",\"addEquipmentChoices\",\"selectedAddOption\",\"mut\",\"action\",\"formComponent\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/customer-equipment/add-equipment/template.hbs"
    }
  });

  _exports.default = _default;
});