define("securitytrax-ember-ui/components/customer/dashboard/payables-summary-card/type-legend/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s7jeEkox",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[30,[36,2],[\"grid-x grid-padding-x payable-type-details align-bottom\",[30,[36,1],[[35,0],\" total\",\"\"],null]],null]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cell\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"payable-type-legend paid grid-x align-middle align-justify\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"cell shrink legend-color\"],[12],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"cell shrink amount\"],[12],[1,[30,[36,5],[[35,4]],[[\"style\",\"currency\",\"fallback\"],[\"currency\",[30,[36,3],[\"common.currency\"],null],[30,[36,3],[\"common.zero_currency\"],null]]]]],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"payable-type-legend to-be-paid grid-x align-middle align-justify\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"cell shrink legend-color\"],[12],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"cell shrink amount\"],[12],[2,\"\\n                \"],[1,[30,[36,3],[\"pages.customers.dashboard.payables_summary_card.of\"],null]],[2,\" \"],[1,[30,[36,5],[[35,6]],[[\"style\",\"currency\",\"fallback\"],[\"currency\",[30,[36,3],[\"common.currency\"],null],[30,[36,3],[\"common.zero_currency\"],null]]]]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"total\",\"if\",\"concat\",\"t\",\"type_paid\",\"format-number\",\"type_to_be_paid\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/customer/dashboard/payables-summary-card/type-legend/template.hbs"
    }
  });

  _exports.default = _default;
});