define("securitytrax-ember-ui/components/multi-step-form/forms/administration/inventory/spillage/line-items-rma/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    inventoryService: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    sessionData: Ember.inject.service('sessionData'),
    grants: Ember.inject.service(),
    pendingEquipmentLineItems: null,
    dataLoaded: false,
    totalItemCount: 0,
    noRma: null,
    getLineItemCount: (0, _emberConcurrency.task)(function* () {
      var lineItems = yield this.pendingEquipmentLineItems.concat(this.noRma);
      var count = 0;
      lineItems.forEach(group => {
        group.items.forEach(item => {
          if (item.justReceivedQty) {
            count += parseInt(item.justReceivedQty);
          }
        });
      });
      this.set('totalItemCount', count);
    }),
    initFormData: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.waitForProperty)(this, 'pendingEquipmentLineItems', v => v != null);
      yield this.setUpLineItems.perform(this.pendingEquipmentLineItems);
      yield (0, _emberConcurrency.waitForProperty)(this, 'noRma', v => v != null);
      yield this.setUpLineItems.perform(this.noRma);
      yield this.getLineItemCount.perform();
      this.set('dataLoaded', true);
    }).on('init'),
    setUpLineItems: (0, _emberConcurrency.task)(function* (groups) {
      yield groups.forEach(group => {
        group.get('items').forEach(item => {
          item.set('justReceivedQty', 0);
          item.set('receivedQty', item.get('fulfilledQuantity'));
          item.set('difference', item.get('quantity') - item.get('fulfilledQuantity'));
          item.set('absoluteValueDifference', Math.abs(item.get('difference')));
          item.set('history', this.grants.getIndividualGrant('equipment_ledgers.computed.company_inventory') ? this.store.query('equipmentLedger', {
            filter: 'target_id = ' + item.get('id') + ' and target_class = 400'
          }) : []);
        });
      });
    }),
    actions: {
      updateQuantity(row, direction) {
        var receivedQty = 0;
        var fulfilledQuantity = 0;
        row.set('difference', row.get('quantity') - row.get('fulfilledQuantity'));

        if (direction === 'up') {
          receivedQty = row.get('difference');
          fulfilledQuantity = row.get('justReceivedQty') + row.get('receivedQty');
        }

        row.set('justReceivedQty', receivedQty);
        row.set('fulfilledQuantity', fulfilledQuantity);
        this.getLineItemCount.perform();
      },

      updateCount(row) {
        if (row.get('justReceivedQty') + row.get('receivedQty') > row.get('quantity')) {
          row.set('fulfilledQuantity', row.get('quantity'));
          row.set('difference', row.get('quantity') - row.get('fulfilledQuantity'));
          row.set('justReceivedQty', row.get('fulfilledQuantity') - row.get('receivedQty'));
        } else {
          row.set('fulfilledQuantity', row.get('justReceivedQty') + row.get('receivedQty'));
          row.set('difference', row.get('quantity') - row.get('fulfilledQuantity'));
        }

        this.getLineItemCount.perform();
      }

    }
  });

  _exports.default = _default;
});