define("securitytrax-ember-ui/services/navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    isLeftOpen: false,

    toggleLeft() {
      this.toggleProperty('isLeftOpen');
    },

    closeLeft() {
      this.set('isLeftOpen', false);
    },

    actions: {
      toggleLeft() {
        this.toggleLeft();
      }

    }
  });

  _exports.default = _default;
});