define("securitytrax-ember-ui/models/base_models/customer-system-information", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    accountNum: (0, _model.attr)('string', {
      'readOnly': false
    }),
    secondaryAccountNum: (0, _model.attr)('string', {
      'readOnly': false
    }),
    confirmationNum: (0, _model.attr)('string', {
      'readOnly': false
    }),
    receiverNum: (0, _model.attr)('string', {
      'readOnly': false
    }),
    isInstalled: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    serviceTwoWayVoice: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    adtCustomerNumber: (0, _model.attr)('string', {
      'readOnly': false
    }),
    adtSerialMan: (0, _model.attr)('number', {
      'readOnly': false
    }),
    centralStationId: (0, _model.attr)('string', {
      'readOnly': true
    }),
    centralStationPanelId: (0, _model.attr)('string', {
      'readOnly': true
    }),
    interactiveServicesId: (0, _model.attr)('string', {
      'readOnly': true
    }),
    customerAbortCode: (0, _model.belongsTo)('customer-abort-code', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    customer: (0, _model.belongsTo)('customer', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    installCalendarEvent: (0, _model.belongsTo)('calendar-event', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    dealerNumber: (0, _model.belongsTo)('dealer-number', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    customerEquipment: (0, _model.hasMany)('customer-equipment', {
      'readOnly': true,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});