define("securitytrax-ember-ui/components/pages/administration/company-settings/system/tags/component", ["exports", "ember-concurrency", "lodash"], function (_exports, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    session: Ember.inject.service(),
    tagsService: Ember.inject.service(),
    stFiltersService: Ember.inject.service(),
    tagModel: undefined,
    tagFormStarted: false,
    tagFormFirstStep: 'step1',
    tagFormMode: 'create',
    limit: 50,
    page: 1,
    recordCount: 0,
    name: undefined,
    id: undefined,
    isActive: undefined,
    objectTypes: undefined,
    appliedFiltersCount: 0,
    objectTypeOptions: Ember.computed(function () {
      var record = this.store.createRecord('tag');
      var options = record.enumLists().objectTypeOptions.sort((a, b) => a.name.localeCompare(b.name));
      record.destroyRecordWithRollbackTask.perform();
      return options.map(option => {
        return {
          id: option.id,
          name: this.intl.exists("pages.administration.system.tags.tagTypes.".concat(option.id)) ? this.intl.t("pages.administration.system.tags.tagTypes.".concat(option.id)) : option.name
        };
      });
    }),
    tagData: Ember.computed(function () {
      Ember.notifyPropertyChange(this, 'groupedTags');
      return this.tagDataTask.perform();
    }),
    tagDataTask: (0, _emberConcurrency.task)(function* () {
      var newFilters = yield this.stFiltersService.getFiltersByGroupKey('companyTags');
      var tags = yield this.store.query('tag', {
        filter: newFilters,
        sort: this.stSortString ? this.stSortString : 'name',
        limit: this.limit,
        page: this.page,
        count: 1
      });
      Ember.set(this, 'recordCount', _lodash.default.get(tags, 'meta.record_count', 0));
      return {
        tags,
        record_count: _lodash.default.get(tags, 'meta.record_count', 0)
      };
    }),
    canCreateTags: Ember.computed('grants.userGrants', function () {
      var grantsService = this.grants;
      var canCreate = grantsService.getIndividualGrant('calendar_event_tags.create') || grantsService.getIndividualGrant('customer_payable_tags.create') || grantsService.getIndividualGrant('equipment_purchase_order_tags.create') || grantsService.getIndividualGrant('equipment_purchase_requisition_tags.create') || grantsService.getIndividualGrant('equipment_spillage_tags.create') || grantsService.getIndividualGrant('equipment_return_merchandise_authorization_tags.create');
      this.objectTypeOptions.forEach(function (option) {
        if (grantsService.getIndividualGrant(option.id + '_tags.create')) {
          canCreate = true;
        }
      });
      return canCreate;
    }),
    tagFormSteps: Ember.computed('tagModel', function () {
      var intl = this.intl;
      var title = this.tagModel === null ? intl.t('pages.administration.system.tags.add') : this.get('tagModel.name') + intl.t('pages.administration.system.tags.edit', {
        id: this.get('tagModel.id')
      });
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: title,
          stepComponentPath: 'multi-step-form/forms/administration/system/tags'
        })
      });
    }),
    tagFormOriginalModels: Ember.computed('tagModel', function () {
      return Ember.Object.create({
        tag: {
          model: this.tagModel,
          copyRelationMap: {}
        }
      });
    }),
    actions: {
      clearTagSearch() {
        Ember.setProperties(this, {
          page: 1,
          name: undefined,
          id: undefined,
          isActive: undefined,
          objectTypes: undefined
        });
        Ember.notifyPropertyChange(this, 'tagData');
        Ember.notifyPropertyChange(this, 'page');
      },

      updateTagList() {
        Ember.notifyPropertyChange(this, 'tagData');
      },

      tagSearch() {
        this.set('page', 1);
        Ember.notifyPropertyChange(this, 'tagData');
      },

      editAction(row) {
        this.set('tagModel', row);
        this.set('tagFormMode', this.tagsService.isGranted(row.get('objectType'), row.get('objectContext'), 'modify') ? 'edit' : 'view');
        this.set('tagFormStarted', true);
      }

    }
  });

  _exports.default = _default;
});