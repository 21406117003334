define("securitytrax-ember-ui/my-inventory/equipment-inventory/route", ["exports", "securitytrax-ember-ui/my-inventory/base-route", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _baseRoute, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_routePageviewLogging.default, {
    beforeModel() {
      this._super(...arguments);

      var routeObjs = [{
        route: 'my-inventory.equipment-inventory',
        grants: ['equipment_ledgers.view_on_hand']
      }, {
        route: 'my-inventory.purchase-requisitions',
        grants: ['equipment_purchase_requisitions.view']
      }, {
        route: 'my-inventory.purchase-orders',
        grants: ['equipment_purchase_orders.view']
      }, {
        route: 'my-inventory.physical-inventory',
        grants: ['equipment_to_recipients.modify']
      }, {
        route: 'my-inventory.transfers',
        grants: ['equipment_transfers.view']
      }, {
        route: 'my-inventory.spillage',
        grants: ['equipment_spillage.view']
      }];
      this.baseFindGrantsTask.perform(routeObjs);
    },

    model() {
      var {
        user
      } = this._super(...arguments);

      return Ember.RSVP.hash({
        user
      });
    }

  });

  _exports.default = _default;
});