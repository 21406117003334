define("securitytrax-ember-ui/components/form-group/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * <p>Base class that other inputs such as text and dropdown can extend</p>
   * The template provides everything that is necessary
   *
   * @class FormGroup
   * @extends Ember.Component
   * @memberof components
   */
  var FormGroup = Ember.Component.extend(
  /** @lends components.FormGroup.prototype */
  {
    tagName: 'div',
    classNames: ['form-group'],
    classNameBindings: ['inline:inline:stacked', 'labelTextRight:text-right', 'labelDescriptionText:description', 'required', 'error:error', 'label::no-label'],
    //region text properties

    /**
     * Description label for the input field
     *
     * @type {string}
     * @default ''
     */
    label: '',

    /**
     * <p>Description for the input's label</p>
     * <b>Note:</b> This will only be shown if there is a label and if the alignment is inline
     *
     * @type {string}
     * @default ''
     */
    labelDescription: '',
    //endregion
    //region alignment and visuals

    /**
     * Should the label be displayed inline instead of stacked
     *
     * @type {boolean}
     * @default false
     */
    inline: false,

    /**
     * Should label's text be pulled right
     *
     * @type {boolean}
     * @default false
     */
    labelTextRight: false,

    /**
     * Class list to be applied to the label
     *
     * @type {string}
     * @default ''
     */
    labelClass: '',

    /**
     * Error message to show for this input
     *
     * @type {boolean}
     * @default false
     */
    error: false,

    /**
     * Is the input required
     *
     * @type {boolean}
     * @default false
     */
    required: false,
    //endregion
    //region computed properties for the input

    /**
     * <p>Description for the label</p>
     * <b>Note:</b> This will return text only if the alignment is inline, there is a label and a labelDescription is specified
     *
     * @type Ember.computed
     * @returns {string}
     */
    labelDescriptionText: Ember.computed('inline', 'label', 'labelDescription', function () {
      if (!this.inline || !this.label) {
        return undefined;
      }

      return this.labelDescription;
    }).readOnly() //endregion

  });
  var _default = FormGroup;
  _exports.default = _default;
});