define("securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/adc/lookup/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ispService: Ember.inject.service(),
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    customerResults: null,
    initFormData: (0, _emberConcurrency.task)(function* () {
      yield this.adcLookup.perform();
    }).on('init'),
    adcLookup: (0, _emberConcurrency.task)(function* () {
      var attributes = this.adcId ? {
        'interactive_services_id': this.adcId
      } : {};
      var customerList = yield this.ispService.get('invokeIspActionTask').perform({
        model: this.customer,
        attributes,
        action: 'alarm_dot_com__lookupNoId'
      });
      this.set('customerResults', customerList.customer_info);
    }),
    linkAccount: (0, _emberConcurrency.task)(function* (adcId) {
      try {
        yield this.ispService.get('invokeIspActionTask').perform({
          model: this.customer,
          attributes: {
            'interactive_services_id': adcId
          },
          action: 'alarm_dot_com__linkCustomer'
        });
        this.systemInfo.reload();
        this.multiStepForm.formStartedSetVal(false);

        if (this.transitionBackToParent && typeof this.transitionBackToParent === 'function') {
          this.transitionBackToParent();
        }

        this.stNotificationManager.addSuccess(this.intl.t('common.notifications.link_success', {
          'type': 'Alarm.com'
        }));
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
        throw err;
      }
    })
  });

  _exports.default = _default;
});