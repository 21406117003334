define("securitytrax-ember-ui/mixins/sortable-list-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Adds the ability to sort lists, paginate lists, and drag and drop reorder lists.
   *
   * @mixin SortableListMixin
   * @extends Ember.Mixin
   */
  var _default = Ember.Mixin.create({
    /**
     * This will hold the values for how many records to offset the return.
     * It also hold the value for how many records a page to show
     *
     * @type {Object}
     */
    pagination: {
      offset: 0,
      limit: 10
    },

    /**
     * The sort direction and column string that is part of the API call
     *
     * @type {String}
     */
    sortCall: null,

    /**
     * Array of the columns and direction to sort by
     *
     * @type {Array}
     */
    sortOrder: [],

    /**
     * Ember store that allows fetching the items
     *
     * @type {DS.Store}
     */
    store: Ember.inject.service(),
    getObjectIndex: function (obj, key) {
      for (var i = 0; i < obj.length; i++) {
        var keys = Object.keys(obj[i]);

        if (keys[0] === key) {
          return i;
        }
      }

      return -1;
    },

    setSortOrder(sortOrder, column, direction) {
      var columnIndex = this.getObjectIndex(sortOrder, column);

      if (columnIndex === -1) {
        sortOrder.push({
          [column]: direction
        });
      } else {
        sortOrder[columnIndex][column] = direction;
      }

      return sortOrder;
    },

    actions: {
      /**
       * This takes in what column was clicked on, then checks it current sort and decides what way to sort it next.
       */
      sortColumns(modelName, column, property, direction = null) {
        var columns = [];
        var mixin = this;
        var sortCall = '';
        var sortOrder = this.sortOrder; //Looping through the array of values already to column to see if any of them match the one being added

        this.columns.map(function (item) {
          var newItem = {
            propertyName: item.propertyName,
            title: item.title,
            sortName: item.sortName,
            direction: item.direction
          };

          if (column === item.sortName) {
            //The natural flow of column sort will be: asc, desc, and then none
            //however, if they want to specify the direction, they can
            if (direction === 'asc' || newItem.direction === false || typeof newItem.direction === 'undefined') {
              newItem.direction = 'asc';
              sortOrder = mixin.setSortOrder(sortOrder, column, 'asc');
            } else if (direction === 'desc' || newItem.direction === 'asc') {
              newItem.direction = 'desc';
              sortOrder = mixin.setSortOrder(sortOrder, column, 'desc');
            } else {
              newItem.direction = false;
              sortOrder.splice(column, 1);
            }
          }

          columns.push(newItem);
        }); //Now we have the sortOrder array, which has the list of columns and directions to sort, we need to put it into a string
        //previsouly - foo:asc;bar:desc;
        //new - foo,-bar,

        sortOrder.map(function (item) {
          var keys = Object.keys(item);
          var columnSort = keys[0]; //prepend '-' if descending

          if (item[keys[0]] == 'desc') {
            columnSort = '-' + columnSort;
          }

          sortCall += columnSort + ',';
        });
        sortCall = sortCall.substring(0, sortCall.length - 1);
        this.set('columns', columns);
        this.set('sortOrder', sortOrder);
        this.set('sortCall', sortCall);
        this.store.query(modelName, {
          sort: sortCall,
          offset: this.get('pagination.offset'),
          limit: this.get('pagination.limit')
        }).then(function (records) {
          mixin.set(property, records);
        });
      },

      /**
       * Changes the current page of a list
       */
      changePage(newPage, modelName, property) {
        var mixin = this;
        this.set('pagination.offset', (newPage - 1) * this.get('pagination.limit'));
        this.store.query(modelName, {
          sort: this.sortCall,
          offset: this.get('pagination.offset'),
          limit: this.get('pagination.limit')
        }).then(function (records) {
          mixin.set(property, records);
        });
      },

      /**
       * Changes the limit of records per page on a list
       */
      changeLimit(newLimit, modelName, property) {
        var mixin = this;
        this.set('pagination.limit', newLimit); //We need to change what offset they will be have. If they have an offset of 10 and have the page limit set to 5 and change the limit to 20, we need to change the offset.
        //we are going to round down to the nearest page

        var floor = Math.floor(this.get('pagination.offset') / newLimit);
        var newOffset = floor * newLimit;
        this.set('pagination.offset', newOffset);
        this.store.query(modelName, {
          sort: this.sortCall,
          offset: this.get('pagination.offset'),
          limit: this.get('pagination.limit')
        }).then(function (records) {
          mixin.set(property, records);
        });
      },

      dragStart()
      /* object */
      {//Not using right now
      },

      /**
       * When the user is done dragging an item in a list, this action is called
       */
      sortEndAction()
      /*list*/
      {//TODO: Will we put in the api call right here to update the order when the sorting is done?
        // console.log('Sort Ended', list);
      }

    }
  });

  _exports.default = _default;
});