define("securitytrax-ember-ui/components/multi-step-form/forms/administration/account-management/message-plus-confirmation/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency"], function (_exports, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    enhancedFeatures: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    paymentMethod: null,
    confirmDeleteModalOpen: false,
    text: '',
    smsJson: undefined,

    didInsertElement() {
      this._super(...arguments);

      this.set('text', this.intl.t("pages.administration.account_management.add_ons.messages_plus.confirmation_message"));
      this.set('smsJson', {
        enabled: this.currentState,
        account_sid: undefined,
        auth_token: undefined,
        from: undefined
      });
      var buttons = [];
      buttons.push({
        text: 'confirm',
        click: buttonPromise => {
          return this.addOnTask.perform(buttonPromise, this.multiStepForm);
        },
        classNamesString: 'st-btn st-btn-primary'
      });
      this.multiStepForm.registerStepData('messagePlusFormStep', 'buttons', buttons);

      if (this.currentState) {
        this.set('text', this.intl.t("pages.administration.account_management.add_ons.messages_plus.disable_confirmation_message"));
        this.multiStepForm.registerStepData('messagePlusFormStep', 'title', this.intl.t("pages.administration.account_management.add_ons.messages_plus.disable_confirmation"));
      }
    },

    addOnTask: (0, _emberConcurrency.task)(function* (buttonPromise, multiStepForm) {
      var newState = !this.currentState;
      var premiumFeatures = yield this.premiumFeatures;
      var formattedObjects = [];

      if (newState) {
        formattedObjects.pushObject(Ember.Object.create({
          model: this.smsJson,
          filePath: 'st-add-ons/twilio-integration'
        }));
        yield multiStepForm.validateList.perform(formattedObjects);
      }

      Ember.set(this.smsJson, 'enabled', newState);
      premiumFeatures.set('sms', this.smsJson);

      try {
        yield premiumFeatures.save();
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'messagePlusModal'
        });
        throw err;
      }

      this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
      buttonPromise.then(() => {
        multiStepForm.formStartedSetVal(false);
      });
    })
  });

  _exports.default = _default;
});