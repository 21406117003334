define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/edit-create-credit-bureau/equifax/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    serviceOptions: null,
    includePrintImage: false,

    init() {
      this._super(...arguments);

      var intl = this.intl;
      var options = [{
        name: intl.t('pages.administration.integrations.credit_bureaus.acro_production'),
        value: 'acroxml'
      }, {
        name: intl.t('pages.administration.integrations.credit_bureaus.acro_test'),
        value: 'acroxmltest'
      }, {
        name: intl.t('pages.administration.integrations.credit_bureaus.dp_production'),
        value: 'dp'
      }, {
        name: intl.t('pages.administration.integrations.credit_bureaus.dp_test'),
        value: 'dptest'
      }];
      var includePrintImage = this.multiStepForm.formCopiedModelsGetVal('creditBureau.model.creditBureauConfig.includePrintImage');
      this.set('includePrintImage', includePrintImage != undefined ? includePrintImage === 'Y' : false);
      this.set('serviceOptions', options);
    },

    actions: {
      updateIncludePrintImage() {
        this.multiStepForm.formCopiedModelsSetVal('creditBureau.model.creditBureauConfig.includePrintImage', this.includePrintImage ? 'Y' : 'N');
      }

    }
  });

  _exports.default = _default;
});