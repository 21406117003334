define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/customer-create-chooser/create-form-configs/adt/component", ["exports", "securitytrax-ember-ui/mixins/customer-create-chooser"], function (_exports, _customerCreateChooser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_customerCreateChooser.default, {
    createCustomerFormSteps: Ember.computed('newCustomerName', 'grants.userGrants', function () {
      var intl = this.intl;
      var grantsService = this.grants;
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.newCustomer ? this.newCustomerName : intl.t('common.nav.create.customer.newCustomer'),
          stepComponentPath: 'multi-step-form/forms-dynamic/generic-steps/customer-create/information',
          progressBarTitle: intl.t('common.nav.create.customer.label'),
          isComplete: !!this.newCustomerName
        }),
        step2: Ember.Object.create({
          stepNameKey: 'step2',
          title: this.newCustomerName,
          stepComponentPath: 'multi-step-form/forms-dynamic/generic-steps/customer-create/qualify',
          progressBarTitle: intl.t('common.nav.create.customer.sections.qualify'),
          hidden: !grantsService.getIndividualGrant('customer_credit_checks.create')
        }),
        step3: Ember.Object.create({
          stepNameKey: 'step3',
          title: this.newCustomerName,
          stepComponentPath: 'multi-step-form/forms-dynamic/generic-steps/customer-create/order',
          progressBarTitle: intl.t('common.nav.create.customer.sections.order')
        }),
        paymentMethod: Ember.Object.create({
          stepNameKey: 'paymentMethod',
          title: this.newCustomerName,
          stepComponentPath: 'multi-step-form/forms-dynamic/forms/adt/payment-method',
          progressBarTitle: intl.t('common.nav.create.customer.sections.paymentMethod'),
          hidden: !grantsService.getIndividualGrant('customer_billings.view')
        }),
        workOrder: Ember.Object.create({
          stepNameKey: 'workOrder',
          title: this.newCustomerName,
          stepComponentPath: 'multi-step-form/forms-dynamic/forms/adt/work-order',
          progressBarTitle: intl.t('common.nav.create.customer.sections.workOrder'),
          hidden: !grantsService.getIndividualGrant('work_orders.create'),
          subSteps: Ember.Object.create({
            createWorkOrder: Ember.Object.create({
              stepNameKey: 'createWorkOrder',
              stepComponentPath: 'multi-step-form/forms-dynamic/forms/adt/work-order'
            }),
            editWorkOrder: Ember.Object.create({
              stepNameKey: 'editWorkOrder',
              stepComponentPath: 'multi-step-form/forms-dynamic/forms/adt/work-order/edit-work-order'
            }),
            listWorkOrders: Ember.Object.create({
              stepNameKey: 'listWorkOrders',
              stepComponentPath: 'multi-step-form/forms-dynamic/forms/adt/work-order/work-order-list'
            })
          })
        }),
        schedule: Ember.Object.create({
          stepNameKey: 'schedule',
          title: this.newCustomerName,
          progressBarTitle: intl.t('common.nav.create.customer.sections.schedule'),
          hidden: !grantsService.getIndividualGrant('calendar_events.create'),
          subSteps: Ember.Object.create({
            scheduleCalendarEvent: Ember.Object.create({
              stepNameKey: 'scheduleCalendarEvent',
              stepComponentPath: 'multi-step-form/forms-dynamic/forms/adt/schedule'
            }),
            listCalendarEvents: Ember.Object.create({
              stepNameKey: 'listCalendarEvents',
              stepComponentPath: 'multi-step-form/forms-dynamic/forms/adt/schedule/appointments-list'
            }),
            editCalendarEvent: Ember.Object.create({
              stepNameKey: 'editCalendarEvent',
              stepComponentPath: 'multi-step-form/forms-dynamic/forms/adt/schedule/edit-appointments'
            })
          })
        }),
        nextStep: Ember.Object.create({
          stepNameKey: 'nextStep',
          title: this.newCustomerName,
          stepComponentPath: 'multi-step-form/forms-dynamic/forms/adt/next-step',
          progressBarTitle: intl.t('common.nav.create.customer.sections.nextStep')
        }),
        confirmDeleteCustomer: Ember.Object.create({
          stepNameKey: 'confirmDeleteCustomer',
          title: this.newCustomerName,
          stepComponentPath: 'multi-step-form/forms-dynamic/generic-steps/customer-create/confirm-delete-customer',
          hidden: true
        })
      });
    })
  });

  _exports.default = _default;
});