define("securitytrax-ember-ui/utils/context", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getGlobalContext = getGlobalContext;

  /**
   * Utility helper module for context functions.
   * @module
   */

  /**
   * <p>Returns access to global context (application route)</p>
   *
   * @returns {application.Route}
   */
  function getGlobalContext() {
    return window.App.applicationRoute;
  }
});