define("securitytrax-ember-ui/components/multi-step-form/forms/administration/users/settings/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/administration/users/base-users"], function (_exports, _baseUsers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseUsers.default.extend({
    grants: Ember.inject.service(),
    canModifySettings: Ember.computed('grants.userGrants', function () {
      return this.grants.getIndividualGrant('user_admin_settings.modify') && this.grants.getIndividualGrant('user_preferences.modify');
    }),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;

      if (!this.isNewRecord) {
        multiStepForm.registerStepData("settings", 'buttons', [{
          text: this.intl.t('common.actions.cancel'),
          click: () => {
            multiStepForm.resetForm(false);
            this.set('settingsFormMode', 'view');
          },
          classNamesString: 'st-btn btn-secondary user-cancel-button'
        }, {
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            var noValidateArray = ['group'];
            return this.saveUserDataTask.perform(multiStepForm, buttonPromise, noValidateArray);
          },
          classNamesString: 'st-btn st-btn-primary user-save-button'
        }]);
      }
    },

    includeHomePhone: Ember.computed(function () {
      return !this.multiStepForm.formCopiedModelsGetVal('user.model.userPreference.homePhoneIsPrivate');
    }),
    includeWorkPhone: Ember.computed(function () {
      return !this.multiStepForm.formCopiedModelsGetVal('user.model.userPreference.workPhoneIsPrivate');
    }),
    includeCellPhone: Ember.computed(function () {
      return !this.multiStepForm.formCopiedModelsGetVal('user.model.userPreference.cellPhoneIsPrivate');
    }),
    updatePrivatePhoneObserver: Ember.observer('includeHomePhone', function () {
      this.multiStepForm.formCopiedModelsSetVal('user.model.userPreference.homePhoneIsPrivate', !this.includeHomePhone);
    }),
    updatePrivateWorkPhoneObserver: Ember.observer('includeWorkPhone', function () {
      this.multiStepForm.formCopiedModelsSetVal('user.model.userPreference.workPhoneIsPrivate', !this.includeWorkPhone);
    }),
    updatePrivateCellPhoneObserver: Ember.observer('includeCellPhone', function () {
      this.multiStepForm.formCopiedModelsSetVal('user.model.userPreference.cellPhoneIsPrivate', !this.includeCellPhone);
    })
  });

  _exports.default = _default;
});