define("securitytrax-ember-ui/validations/calendar-event", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation", "lodash", "moment"], function (_exports, _validate, _baseValidation, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var skipValidation = _lodash.default.get(data, 'extraValidationData.skipValidation', false);

      var noValidate = _lodash.default.get(data, 'extraValidationData.noValidate', false);

      if (skipValidation) {
        return {};
      }

      var validationData;

      var mergedData = _lodash.default.merge(this.objectToJson(this), data.extraValidationData.toAlsoValidate);

      if (data.extraValidationData.isTimeoff) {
        var validationObject = {
          startTime: {
            presence: {
              allowEmpty: false,
              message: function () {
                return '^' + data.intlService.t('pages.users.time_off.start_time_validation_message');
              }
            }
          }
        };

        if (data.extraValidationData.timeOffCreateId !== 'multiple') {
          validationObject.duration = {
            presence: {
              allowEmpty: false
            },
            numericality: {
              greaterThan: 0,
              message: function ()
              /*value, attribute, validatorOptions, attributes, globalOptions*/
              {
                return '^' + data.intlService.t('pages.users.time_off.duration_validation_message');
              }
            }
          };
          validationObject.timeRangeStart = {
            presence: {
              allowEmpty: false
            }
          };
        }

        if (data.extraValidationData.timeOffCreateId === 'recurring') {
          validationObject.recurEvery = {
            presence: {
              allowEmpty: false
            }
          };
          validationObject.endingDate = {
            presence: {
              allowEmpty: false
            },
            equality: {
              attribute: 'startTime',
              message: function ()
              /*value, attribute, validatorOptions, attributes, globalOptions*/
              {
                return '^' + data.intlService.t('pages.users.time_off.recur_until_validation_message');
              },
              comparator: function (v1, v2) {
                return JSON.stringify((0, _moment.default)(v1).unix()) > JSON.stringify((0, _moment.default)(v2).unix());
              }
            }
          };
          validationObject.days = {
            presence: {
              allowEmpty: false
            }
          };
        } else if (data.extraValidationData.timeOffCreateId === 'multiple') {
          validationObject.duration = {
            presence: {
              allowEmpty: false
            }
          };
          validationObject.endTime = {
            presence: {
              allowEmpty: false
            },
            equality: {
              attribute: 'startTime',
              message: function ()
              /*value, attribute, validatorOptions, attributes, globalOptions*/
              {
                return '^' + data.intlService.t('pages.users.time_off.end_time_validation_message');
              },
              comparator: function (v1, v2) {
                return JSON.stringify((0, _moment.default)(v1).unix()) > JSON.stringify((0, _moment.default)(v2).unix());
              }
            }
          };
        }

        validationData = (0, _validate.default)(mergedData, validationObject, {
          fullMessages: this.prependProperty(data)
        });
      } else if (data.extraValidationData.isBulk) {
        var _validationObject = {
          calendarEventType: {
            presence: {
              allowEmpty: false
            }
          },
          calendarUser: {
            presence: {
              allowEmpty: false,
              message: function () {
                return '^' + data.intlService.t('pages.customers.appointments.technicians_validation');
              }
            }
          },
          startingDate: {
            presence: {
              allowEmpty: false
            }
          },
          endingDate: {
            presence: {
              allowEmpty: false
            }
          },
          startTime: {
            presence: {
              allowEmpty: false,
              message: function () {
                return '^' + data.intlService.t('pages.users.time_off.start_time_validation_message');
              }
            }
          },
          duration: {
            presence: {
              allowEmpty: false
            },
            numericality: {
              greaterThan: 0,
              message: function ()
              /*value, attribute, validatorOptions, attributes, globalOptions*/
              {
                return '^' + data.intlService.t('pages.users.time_off.duration_validation_message');
              }
            }
          },
          days: {
            presence: {
              allowEmpty: false
            }
          }
        };
        validationData = (0, _validate.default)(mergedData, _validationObject, {
          fullMessages: this.prependProperty(data)
        });
      } else {
        validationData = (0, _validate.default)(this.objectToJson(this), {
          calendarEventType: {
            presence: {
              allowEmpty: true
            }
          },
          startTime: {
            presence: {
              allowEmpty: false
            }
          },
          duration: {
            presence: {
              allowEmpty: false
            }
          },
          calendarUser: {
            presence: () => {
              if (noValidate && noValidate.includes('calendarEvent-calendarUser')) {
                return false;
              } else {
                return this.customMessageOrDefault(data, 'calendarEvent-calendarUser');
              }
            }
          }
        }, {
          fullMessages: this.prependProperty(data)
        });
      }

      return this.outputFormatting(validationData, 'calendarEvent');
    }

  });

  _exports.default = _default;
});