define("securitytrax-ember-ui/models/base_models/customer-order-information", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    mmrALaCarte: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    rebateCheck: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    customerIsCancelled: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    customerCancelDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    leadCallcenterRep: (0, _model.attr)('string', {
      'readOnly': false
    }),
    serviceCellularBackup: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    serviceGuardResponse: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    serviceInvoicing: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    collectionsDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    discountAffiliateMemberNumber: (0, _model.attr)('string', {
      'readOnly': false
    }),
    guardResponseFee: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    customer: (0, _model.belongsTo)('customer', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    repUser: (0, _model.belongsTo)('user', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    trainUser: (0, _model.belongsTo)('user', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    contractTerm: (0, _model.belongsTo)('contract-term', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    mmr: (0, _model.belongsTo)('mmr', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    activationFee: (0, _model.belongsTo)('activation-fee', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    customerWarranty: (0, _model.belongsTo)('customer-warranty', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    monitoringCompany: (0, _model.belongsTo)('monitoring-company', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    interactiveServicesProvider: (0, _model.belongsTo)('interactive-services-provider', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    customerCancelReason: (0, _model.belongsTo)('customer-cancel-reason', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    monitoringPlan: (0, _model.belongsTo)('monitoring-plan', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    discountAffiliate: (0, _model.belongsTo)('discount-affiliate', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    customerEquipment: (0, _model.hasMany)('customer-equipment', {
      'readOnly': true,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});