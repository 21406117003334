define("securitytrax-ember-ui/components/multi-step-form/forms/user-actions/add-user/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LCPcyrCp",
    "block": "{\"symbols\":[\"data\"],\"statements\":[[10,\"div\"],[14,0,\"cell small-12\"],[12],[2,\"\\n\"],[6,[37,8],null,[[\"promises\",\"booleanLoaded\",\"useSpinner\",\"size\"],[[30,[36,7],null,[[\"hierarchyUsers\"],[[35,6]]]],[35,5],true,\"medium\"]],[[\"default\"],[{\"statements\":[[2,\"   \"],[10,\"div\"],[14,0,\"dropdown-label-maker\"],[12],[2,\"\\n        \"],[1,[30,[36,2],[[35,1,[\"flname\"]],[30,[36,0],[\"features.org-chart.modals.add_sub_title\"],null]],null]],[2,\"    \\n    \"],[13],[2,\" \\n    \"],[1,[30,[36,4],null,[[\"name\",\"formMode\",\"options\",\"value\",\"renderInPlaceOverride\",\"searchEnabled\",\"allowClear\",\"labelProperty\",\"valueProperty\",\"class\"],[\"add_user\",\"edit\",[32,1,[\"resolvedPromises\",\"hierarchyUsers\"]],[35,3],false,true,false,\"user.lfname\",\"id\",\"org-chart-dropdown\"]]]],[2,\"  \\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"modalParentUser\",\"concat\",\"selectedUser\",\"multi-step-form/switchable-inputs/select-input\",\"saveComplete\",\"hierarchyUsers\",\"hash\",\"html/st-await\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/user-actions/add-user/template.hbs"
    }
  });

  _exports.default = _default;
});