define("securitytrax-ember-ui/helpers/has-reports-any-run-grant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Allows checking a single grant, or multiple, separated by space
   * @param {*} params
   */
  var _default = Ember.Helper.extend({
    grants: Ember.inject.service(),
    grantsChanged: Ember.observer('grants.revision', function () {
      this.recompute();
    }),

    compute()
    /*params, hash*/
    {
      return this.grants.hasAtLeastOneReportsRunGrant();
    }

  });

  _exports.default = _default;
});