define("securitytrax-ember-ui/components/modals/sub-route-modals/upload-file/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qujiAheC",
    "block": "{\"symbols\":[\"multiStepForm\",\"MultiStepFormComponent\"],\"statements\":[[8,\"multi-step-form\",[],[[\"@formSteps\",\"@formStarted\",\"@changeFormStarted\"],[[34,2],[34,3],[30,[36,1],[[32,0],[30,[36,4],[[35,3]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"modals/st-paper\",[],[[\"@modalButtons\",\"@title\",\"@dialogOpen\",\"@multiStepForm\",\"@hasProgressBar\",\"@modalNamespace\",\"@classNames\",\"@onClose\"],[[32,1,[\"currentStepData\",\"buttons\"]],[32,1,[\"currentStepData\",\"title\"]],[32,1,[\"formStarted\"]],[32,1],false,\"uploadFileModal\",\"st-modal--medium upload-file\",[30,[36,1],[[32,0],\"transitionBackToParentRoute\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,[32,2],[],[[\"@multiStepForm\",\"@formMode\",\"@customer\",\"@transitionBackToParent\"],[[32,1],[34,0],[32,0,[\"customer\"]],[30,[36,1],[[32,0],\"transitionBackToParentRoute\"],null]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"uploadFileFormMode\",\"action\",\"uploadFileFormSteps\",\"uploadFileFormStarted\",\"mut\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/modals/sub-route-modals/upload-file/template.hbs"
    }
  });

  _exports.default = _default;
});