define("securitytrax-ember-ui/helpers/route-exists", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute(params) {
      return Ember.getOwner(this).lookup("route:".concat(params[0]));
    }

  });

  _exports.default = _default;
});