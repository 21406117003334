define("securitytrax-ember-ui/demo/date-picker/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller.extend({
    intl: Ember.inject.service(),
    demoForm: Ember.computed(function () {
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: 'Datepicker Examples',
          stepComponentPath: 'multi-step-form/forms/demo/demo-datepicker'
        })
      });
    }),
    demoFormOriginalModels: Ember.computed(function () {
      return Ember.Object.create({
        customer: {
          model: this.get('model.customer'),
          copyRelationMap: {
            customerBirthdate: {},
            location: {
              readOnly: true
            } // since this is required in customer validation

          }
        }
      });
    })
  });
  var _default = Controller;
  _exports.default = _default;
});