define("securitytrax-ember-ui/components/pages/administration/company-settings/global-settings/company/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WSJdgd76",
    "block": "{\"symbols\":[\"multiStepForm\",\"MultiStepFormComponent\",\"multiStepForm\",\"MultiStepFormComponent\"],\"statements\":[[6,[37,7],[[30,[36,6],[\"user_admin_settings.view\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"multi-step-form\",[],[[\"@formSteps\",\"@formOriginalModels\",\"@formStarted\"],[[34,2],[34,3],true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[32,3,[\"currentStepData\",\"stepComponentPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,[32,4],[[24,0,\"company-settings-form\"]],[[\"@multiStepForm\"],[[32,3]]],null],[2,\"\\n\"]],\"parameters\":[4]}]]],[2,\"    \"]],\"parameters\":[3]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"multi-step-form\",[],[[\"@formSteps\",\"@formStarted\"],[[34,8],true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,[32,2],[[24,0,\"profile-photo-form\"]],[[\"@multiStepForm\",\"@formMode\",\"@title\",\"@src\",\"@globalSettings\",\"@companyLogo\"],[[32,1],\"view\",[30,[36,0],[\"pages.administration.system.globalSettings.editLogo.title\"],null],[34,1,[\"companyLogo\"]],[34,1],true]],null],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"globalSettings\",\"companySettingsForm\",\"formOriginalModels\",\"component\",\"let\",\"has-grants\",\"if\",\"companyPhotoForm\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/administration/company-settings/global-settings/company/template.hbs"
    }
  });

  _exports.default = _default;
});