define("securitytrax-ember-ui/components/html/calendar-status-timeline-node/component", ["exports", "moment", "securitytrax-ember-ui/helpers/st-time-epoch"], function (_exports, _moment, _stTimeEpoch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    aStatus: null,
    status: null,
    statusTimeStamp: null,
    titleText: null,
    intl: Ember.inject.service(),
    iconClass: Ember.computed('aStatus', function () {
      var returnString = '';

      if (this.status) {
        if (!this.isOntime && this.isOntime != null) {
          returnString += ' event-container__icon--late';
        } else if (this.isOntime && this.isOntime != null) {
          returnString += ' event-container__icon--on-time';
        }
      } else {
        returnString = 'event-container__icon--disabled';
      }

      return returnString;
    }),
    closed: Ember.computed('aStatus', function () {
      if (this.status || this.statusTimeStamp) {
        return true;
      }

      return false;
    }),
    primaryText: Ember.computed('aStatus', function () {
      var returnString = '';

      if (this.status && this.statusTimeStamp) {
        returnString += ' - ' + (0, _stTimeEpoch.checkStEpoch)((0, _moment.default)(this.statusTimeStamp).unix(), (0, _moment.default)(this.status)).format('h:mm A');

        if (!this.isOntime && this.isOntime != null) {
          returnString += ' - ' + this.intl.t('pages.calendar.late');
        }
      }

      return returnString;
    }),
    primaryTextClass: Ember.computed('closed', function () {
      if (!this.closed) {
        return 'event-container__text--primary-closed';
      }

      return '';
    }),

    willRender() {
      if (this.status) {
        this.set('aStatus', this.status);
      }
    }

  });

  _exports.default = _default;
});