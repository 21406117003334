define("securitytrax-ember-ui/models/base_models/monitoring-company", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    name: (0, _model.attr)('string', {
      'readOnly': false
    }),
    credentialsValid: (0, _model.attr)('boolean', {
      'readOnly': true
    }),
    credentialsLastChecked: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    inHouse: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    active: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    status: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['active', 'deleted']
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    monitoringCompanyConfig: (0, _model.belongsTo)('monitoring-company-config', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    monitoringCompanyType: (0, _model.belongsTo)('monitoring-company-type', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': 'monitoringCompanies'
    })
  });

  _exports.default = _default;
});