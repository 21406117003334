define("securitytrax-ember-ui/components/multi-step-form/forms/administration/users/permissions/user-permissions/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/switchable-form-step", "securitytrax-ember-ui/utils/obj-helpers", "moment", "lodash"], function (_exports, _emberConcurrency, _switchableFormStep, _objHelpers, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        parsedResourceTypeList: [],
        parsedGroupResourceTypeGrants: []
      });
    },

    parsedResourceTypeList: undefined,
    groupPolicyOverride: false,
    policyModalTitle: '',
    editPolicySteps: Ember.computed('currentResource', function () {
      return Ember.Object.create({
        editPolicies: Ember.Object.create({
          stepNameKey: 'editPolicies',
          title: this.policyModalTitle,
          stepComponentPath: 'multi-step-form/forms/administration/users/permissions/edit-policies'
        })
      });
    }),
    currentResource: null,
    policyOriginalModels: Ember.computed('currentResource', function () {
      return Ember.Object.create({
        resourceType: {
          readOnly: true,
          model: this.currentResource,
          copyRelationMap: {
            resourceTypePolicies: {
              readOnly: true
            }
          }
        },
        user: {
          model: this.multiStepForm.formOriginalModelsGetVal('user.model'),
          copyRelationMap: {
            userResourceTypePolicies: {
              relationships: {
                resourceTypePolicy: {
                  readOnly: true
                }
              }
            }
          }
        }
      });
    }),
    parsedGroupResourceTypeGrants: undefined,
    getParseGroupResourceTypeGrants: (0, _emberConcurrency.task)(function* () {
      var currentParsedResourceTypeGrants = yield this.parsedGroupResourceTypeGrants; //on init, or coming in here after change to policies?

      if (currentParsedResourceTypeGrants.length > 0) {
        return currentParsedResourceTypeGrants;
      }

      var addEditGroup = yield this.get('user.group');
      var resourceTypeGrants = addEditGroup ? yield addEditGroup.get('resourceTypeGrants') : [];
      var parsedSelectedGrants = {};

      for (var x = 0; x < resourceTypeGrants.get('length'); x++) {
        var currGrant = resourceTypeGrants.objectAt(x);
        parsedSelectedGrants[currGrant.get('id')] = currGrant;
      }

      this.set('parsedGroupResourceTypeGrants', parsedSelectedGrants);
      return parsedSelectedGrants;
    }),
    getParsedUserResourceTypePolicies: (0, _emberConcurrency.task)(function* (resource) {
      yield resource;
      return this.getPoliciesTask.perform(resource, 'user');
    }),
    getSelectionListTask: (0, _emberConcurrency.task)(function* (resourceTypePolicy) {
      var trueResourceTypePolicy = yield this.store.findRecord('resourceTypePolicy', resourceTypePolicy.get('id'));
      return (yield trueResourceTypePolicy.invokeAction('get_selection_list', {
        verb: 'GET'
      })).responsePayload.data;
    }),
    getParsedGroupResourceTypePolicies: (0, _emberConcurrency.task)(function* (resource) {
      yield resource;
      return this.getPoliciesTask.perform(resource, 'group');
    }),
    getPoliciesTask: (0, _emberConcurrency.task)(function* (resource, type) {
      var _this = this;

      var resourceTypePolicies = yield resource.get('resourceTypePolicies');

      if (resourceTypePolicies.get('length') == 0) {
        return null; //dont show edit if there is nothing to be added/updated
      }

      var addEditGroup = yield this.addEditGroup; //group is required, but if they don't have a group, return here.

      if (!addEditGroup) {
        return [];
      }

      var typeResourceTypePolicies = type == 'user' ? yield this.get('user.userResourceTypePolicies') : yield addEditGroup.get('groupResourceTypePolicies');
      var filteredResourceTypePolicies = typeResourceTypePolicies.filter(function (policy) {
        return policy.get('deletedAt') == null && policy.get('resourceTypePolicy.resourceTypeId') == resource.get('id');
      });
      var policies = [];
      var grtpLength = filteredResourceTypePolicies.get('length');

      for (var i = 0; i < grtpLength; i++) {
        var policy = filteredResourceTypePolicies.objectAt(i);
        var resourceTypePolicy = yield policy.get('resourceTypePolicy');

        if (policy.get('deletedAt') != null || !resourceTypePolicy) {
          continue;
        }

        var conditionRightValue = (yield policy.get('conditionRight.value')) || '';
        var meta = yield policy.get('resourceTypePolicy.responseMeta') || {};
        var operatorTranslated = ['COMPLEX', 'RELATION'].includes(resourceTypePolicy.get('dataType')) ? '' : this.intl.t('common.operators.' + policy.get('operator'));

        if (resourceTypePolicy.get('dataType') == 'DATE') {
          conditionRightValue = (0, _moment.default)(conditionRightValue).format('M/D/YYYY');
        } else if ('has_selection_list' in meta) {
          yield* function* () {
            var selectionList = yield _this.getSelectionListTask.perform(resourceTypePolicy);

            if (selectionList && !(conditionRightValue instanceof Array)) {
              var conditionRightObject = selectionList.findBy('value', conditionRightValue);

              if (conditionRightObject && conditionRightObject.translation_key) {
                conditionRightValue = _this.intl.t('policy_selections.' + conditionRightObject.translation_key + '.name');
              } else {
                conditionRightValue = conditionRightObject ? conditionRightObject['name'] : conditionRightValue;
              }
            } else if (selectionList && conditionRightValue instanceof Array) {
              conditionRightValue.forEach((inItem, index) => {
                var conditionRightObject = selectionList.findBy('value', inItem);

                if (index == 0) {
                  conditionRightValue = '';
                }

                if (conditionRightObject && conditionRightObject.translation_key) {
                  conditionRightValue += _this.intl.t('pages.users.human_resources.edit_policies.options.' + inItem) + ', ';
                } else {
                  conditionRightValue += (conditionRightObject ? conditionRightObject['name'] : inItem) + ', ';
                }
              });
              conditionRightValue = conditionRightValue.replace(/,\s*$/, ''); //remove last comma and whitespace
            }
          }();
        } //if options, select the value from the options.


        policies.push(resourceTypePolicy.get('name') + ' ' + operatorTranslated + ' ' + conditionRightValue);
      }

      return policies;
    }),
    watchResourceTypes: Ember.observer('resourceTypes', 'user.group.id', function () {
      this.watchResourceTypesTask.perform();
    }).on('init'),
    watchResourceTypesTask: (0, _emberConcurrency.task)(function* () {
      this.set('parsedResourceTypeListLoaded', false);
      var parsedGroupResourceTypeGrants = this.getParseGroupResourceTypeGrants.perform();
      var resourceTypes = this.resourceTypes;
      var hashData = yield Ember.RSVP.hash({
        parsedGroupResourceTypeGrants: parsedGroupResourceTypeGrants,
        resourceTypes: resourceTypes
      });
      var finishedParsedResourceTypeList = yield this.parseResourceTypes.perform(hashData.resourceTypes, hashData.parsedGroupResourceTypeGrants);
      this.set('parsedResourceTypeList', finishedParsedResourceTypeList);
      this.set('parsedResourceTypeListLoaded', true);
    }),
    parseResourceTypes: (0, _emberConcurrency.task)(function* (resourceTypes, parsedGroupResourceTypeGrants, treeLevel = 0, countList = []) {
      var _this2 = this;

      var parsedResourceTypeList;

      if (treeLevel === 0) {
        parsedResourceTypeList = {};
      } else if (treeLevel > 0) {
        parsedResourceTypeList = [];
      }

      yield resourceTypes;

      for (var i = 0; i < resourceTypes.get('length'); i++) {
        var currResourceType = yield resourceTypes.objectAt(i);
        var parentResourceType = yield currResourceType.get('parentResourceType'); // traverse if
        // -- this is top level and the resource has no parent (is top level)
        // -- or if it's not top level

        if (!parentResourceType && treeLevel === 0 || treeLevel > 0) {
          yield* function* () {
            var isCheckedCount = 0;
            var resourceTypeGrants = yield currResourceType.get('resourceTypeGrants');
            resourceTypeGrants.forEach(function (currResourceTypeGrant) {
              if (parsedGroupResourceTypeGrants[currResourceTypeGrant.get('id')]) {
                currResourceTypeGrant.set('isChecked', true);
                isCheckedCount++;
              } else {
                currResourceTypeGrant.set('isChecked', false);
              }
            });
            var childItems = [];
            var resourceGrants = yield currResourceType.get('resourceTypeGrants');
            var allGrantsChecked = resourceGrants.every(item => {
              return item.isChecked;
            });
            currResourceType.set('bulkCheck', allGrantsChecked); //how many are checked?

            var currCountChecked = currResourceType.get('sectionKey') in countList && 'totalChecked' in countList[currResourceType.get('sectionKey')] ? Number(countList[currResourceType.get('sectionKey')]['totalChecked']) : 0;

            if (currResourceType.get('sectionKey') in countList) {
              countList[currResourceType.get('sectionKey')]['totalChecked'] = currCountChecked + isCheckedCount;
            } else {
              countList[currResourceType.get('sectionKey')] = {
                totalChecked: isCheckedCount
              };
            }

            var groupPolicyOverride = false;
            var groupResourcePolicies = yield _this2.getParsedGroupResourceTypePolicies.perform(currResourceType);

            if (groupResourcePolicies && groupResourcePolicies.get('length') > 0) {
              groupPolicyOverride = yield _this2.existingOverrideTask.perform(currResourceType);
            }

            childItems.pushObject({
              resourceType: currResourceType,
              resourceGrants: resourceGrants,
              groupResourcePolicies: groupResourcePolicies,
              userResourcePolicies: yield _this2.getParsedUserResourceTypePolicies.perform(currResourceType),
              groupPolicyOverride: groupPolicyOverride,
              treeLevel: treeLevel
            });
            var childResources = yield currResourceType.get('childResourceTypes');
            var childResults = yield _this2.parseResourceTypes.perform(childResources, parsedGroupResourceTypeGrants, treeLevel + 1, countList);
            childItems.addObjects(childResults);

            if (treeLevel === 0) {
              var shouldHideCurrSection = !currResourceType.get('sectionKey');

              if (!parsedResourceTypeList[currResourceType.get('sectionKey')]) {
                var sectionKey = 'no_section_key';

                if (currResourceType.get('sectionKey')) {
                  sectionKey = currResourceType.get('sectionKey');
                } // HIDE "NO SECTION IN DB"


                parsedResourceTypeList[currResourceType.get('sectionKey')] = {
                  items: [],
                  sectionName: _this2.intl.t('pages.users.human_resources.permissions_section_labels.' + sectionKey),
                  sectionKey: sectionKey,
                  hideSection: shouldHideCurrSection
                };
              }

              var allChildItemsBulkChecked = childItems.every(item => {
                return item.resourceType.bulkCheck;
              });
              parsedResourceTypeList[currResourceType.get('sectionKey')].items.pushObject({
                items: childItems,
                name: currResourceType.get('name'),
                bulkCheck: allChildItemsBulkChecked
              }); // get total length of all children grants

              var totalLength = childItems.reduce(function (accumulator, currentItem) {
                var acc = typeof accumulator == 'number' ? accumulator : accumulator.resourceGrants.length;
                return acc + currentItem.resourceGrants.length;
              });

              if (typeof totalLength == 'object') {
                totalLength = totalLength.resourceGrants.length;
              }

              var currCount = 'totalItems' in parsedResourceTypeList[currResourceType.get('sectionKey')] ? Number(parsedResourceTypeList[currResourceType.get('sectionKey')]['totalItems']) : 0;
              parsedResourceTypeList[currResourceType.get('sectionKey')]['totalItems'] = currCount + totalLength;

              if (currResourceType.get('sectionKey') in parsedResourceTypeList) {
                parsedResourceTypeList[currResourceType.get('sectionKey')]['totalChecked'] = countList[currResourceType.get('sectionKey')].totalChecked;

                if (parsedResourceTypeList[currResourceType.get('sectionKey')]['totalItems'] == parsedResourceTypeList[currResourceType.get('sectionKey')]['totalChecked']) {
                  parsedResourceTypeList[currResourceType.get('sectionKey')]['bulkSelect'] = true;
                }
              }
            } else if (treeLevel > 0) {
              parsedResourceTypeList.addObjects(childItems);
            }
          }();
        }
      }

      return parsedResourceTypeList;
    }),
    existingOverrideTask: (0, _emberConcurrency.task)(function* (resource) {
      var user = this.user;
      var existingOverride = yield this.store.query('userPolicyOverride', {
        filter: '(user_id = ' + user.id + ') and (resource_type_id = \'' + resource.id + '\')'
      });

      if (existingOverride && existingOverride.get('length') > 0) {
        return existingOverride.get('firstObject');
      }

      return false;
    }),
    actions: {
      afterPolicyUpdated(userPoliciesOverrideToggle) {
        var updatedResourceId = this.get('currentResource.id');
        var updatedResourceName = this.get('currentResource.name');
        var updatedResourceSection = this.get('currentResource.sectionKey');
        var parsedResourcesList = this.parsedResourceTypeList;

        var topLevelResource = _lodash.default.find(parsedResourcesList[updatedResourceSection]['items'], {
          name: updatedResourceName
        });

        var resource = _lodash.default.find(topLevelResource['items'], {
          resourceType: {
            id: updatedResourceId
          }
        });

        this.getParsedUserResourceTypePolicies.perform(this.currentResource).then(updatedPolicies => {
          Ember.set(resource, 'userResourcePolicies', updatedPolicies);
        });
        Ember.set(resource, 'groupPolicyOverride', userPoliciesOverrideToggle);
      }

    }
  });

  _exports.default = _default;
});