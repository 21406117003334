define("securitytrax-ember-ui/components/multi-step-form/forms/administration/users/roles/tech-settings/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/administration/users/base-users", "ember-concurrency"], function (_exports, _baseUsers, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseUsers.default.extend({
    usStates: Ember.inject.service(),
    mapWindow: Ember.inject.service(),
    formMode: 'view',
    cancelTask: (0, _emberConcurrency.task)(function* () {
      yield this.multiStepForm.resetForm(false);
      yield this.set('formMode', 'view');
    }),
    saveRoleTask: (0, _emberConcurrency.task)(function* () {
      yield this.multiStepForm.validateSyncSaveAll();
      this.set('formMode', 'view');
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
    }).restartable(),
    actions: {
      openMapWindow(lat, lon) {
        this.mapWindow.openWindow({
          latitude: Number(lat),
          longitude: Number(lon),
          label: 'Signing Location'
        });
      }

    }
  });

  _exports.default = _default;
});