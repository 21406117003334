define("securitytrax-ember-ui/components/calendar-glimmer/calendar-grid/calendar-list-view/component", ["exports", "@glimmer/component", "ember-concurrency", "jquery", "lodash"], function (_exports, _component, _emberConcurrency, _jquery, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CalendarGridListView = (_dec = Ember.inject.service('events/calendar-events'), _dec2 = Ember.inject.service(), _dec3 = Ember.inject.service(), _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = (0, _emberConcurrency.task)(function* () {
    return this.grants.getIndividualGrant('dry_run_reasons.modify') ? yield this.store.findAll('dryRunReason') : [];
  }), _dec8 = (0, _emberConcurrency.task)(function* (property, ascending) {
    this.dryRunReasons = yield this.dryRunReasonsTask.perform();
    var calendarEvents = yield this.args.appointments;
    var eventPromises = [];
    calendarEvents.forEach(event => {
      eventPromises.push(this.eventTask.perform(event));
    });
    var resolvedEvents = yield (0, _emberConcurrency.all)(eventPromises);

    if (property) {
      this.calendarEventRows = yield this.sortCalendarEventsTask.perform(property, ascending, resolvedEvents);
    } else {
      this.calendarEventRows = resolvedEvents;
    }
  }), _dec9 = (0, _emberConcurrency.task)(function* (event) {
    var calendarCategory = yield event.calendarCategory;
    var appointmentStatusData = null;

    if (calendarCategory !== 'sale_schedule') {
      appointmentStatusData = yield event.appointmentStatusData;
    }

    var statusRank = yield this.statusRankTask.perform(appointmentStatusData, event);
    var sortableValues = Ember.Object.create({
      calendarEventType: yield event.calendarEventType,
      calendarEventTypesDisplay: yield event.calendarEventTypesDisplay,
      appointmentStatusData: yield event.appointmentStatusData,
      calendarUser: yield event.calendarUser,
      dryRunReason: yield event.dryRunReason,
      customer: yield event.customer,
      statusRank: statusRank
    });
    event.sortableValues = sortableValues;
    return event;
  }), _dec10 = (0, _emberConcurrency.task)(function* (appointmentStatusData, event) {
    var appointmentTimeStarted = false;
    var appointmentEndTimePassed = false;
    var currentTime = moment();
    var calendarEventStartTime = event.startTime;

    if (currentTime.isSameOrAfter(calendarEventStartTime)) {
      appointmentTimeStarted = true;
    }

    var calendarEventEndTime = moment(event.startTime).add(event.duration, 's');

    if (currentTime.isSameOrAfter(calendarEventEndTime)) {
      appointmentEndTimePassed = true;
    }

    var statusRank = 10;

    if (appointmentStatusData) {
      if (!appointmentTimeStarted) {
        //before appointment
        //not started
        //on the way -display status no pill
        if (appointmentStatusData.check_out) {
          if (appointmentStatusData.on_time_depart) {
            statusRank = 6;
          } else {
            statusRank = 3;
          }
        } else if (appointmentStatusData.check_in) {
          if (appointmentStatusData.on_time_arrival) {
            statusRank = 4;
          } else {
            statusRank = 2;
          }
        }
      } else if (appointmentTimeStarted && !appointmentEndTimePassed) {
        //during appointment window
        //not started-late-not on way -red pill with X late
        //arrived on time - green clock - on time
        //arrived late - yellow clock - late
        if (appointmentStatusData.check_in) {
          if (appointmentStatusData.on_time_arrival) {
            statusRank = 4;
          } else {
            statusRank = 2;
          }
        } else {
          statusRank = 1;
        }
      } else if (appointmentEndTimePassed) {
        // after appointment window
        //checked out on time - green - check done
        //checked out Late - yellow -check Done
        //not Checked out - RED - X LATE
        //not Checked out/in - Red X LATE 
        if (appointmentStatusData.check_out) {
          if (appointmentStatusData.on_time_depart) {
            statusRank = 6;
          } else {
            statusRank = 3;
          }
        } else {
          statusRank = 1;
        }
      }
    }

    return statusRank;
  }), _dec11 = (0, _emberConcurrency.task)(function* (property, ascending, calendarEvents) {
    return calendarEvents.sort(this.compareValues(property, ascending));
  }), _dec12 = Ember._action, _dec13 = Ember._action, (_class = (_temp = class CalendarGridListView extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "CalendarEventsService", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "grants", _descriptor3, this);

      _initializerDefineProperty(this, "inViewport", _descriptor4, this);

      _initializerDefineProperty(this, "calendarEventRows", _descriptor5, this);

      _initializerDefineProperty(this, "dryRunReasons", _descriptor6, this);

      _defineProperty(this, "scrollLeft", 0);

      _initializerDefineProperty(this, "dryRunReasonsTask", _descriptor7, this);

      _initializerDefineProperty(this, "calendarEventsTask", _descriptor8, this);

      _initializerDefineProperty(this, "eventTask", _descriptor9, this);

      _initializerDefineProperty(this, "statusRankTask", _descriptor10, this);

      _initializerDefineProperty(this, "sortCalendarEventsTask", _descriptor11, this);

      this.CalendarEventsService.on('sort-calendar-list', this, '_sortCalendarList');
      this.calendarEventsTask.perform(null, false);
    }

    willDestroy() {
      var header = document.getElementById(this.args.gridIndex + '-' + this.args.rowIndex);
      this.inViewport.stopWatching(header);
      this.CalendarEventsService.off('sort-calendar-list', this, '_sortCalendarList');
    }

    _sortCalendarList(property, ascending) {
      this.calendarEventsTask.perform(property, ascending);
    }

    didEnterViewport() {
      this.args.row.calendarPage.getNextRows();
    }

    unbindScroll() {
      (0, _jquery.default)("#calendar-main-".concat(this.args.gridIndex)).unbind('scroll');
    }

    _onScrollHorizontal() {
      var theGrid = document.querySelector("#calendar-main-".concat(this.args.gridIndex));
      this.CalendarEventsService.trigger('scrollHorizontal', this.args.gridIndex, theGrid.scrollLeft);
      this.scrollLeft = theGrid.scrollLeft;
    }

    get appointmentTimeStarted() {
      var currentTime = moment();

      if (currentTime.isSameOrAfter(this.args.calendarEvent.startTime)) {
        return true;
      }

      return false;
    }

    get appointmentEndTimePassed() {
      var calendarEventEndTime = moment(this.args.calendarEvent.startTime).add(this.args.calendarEvent.duration, 's');

      if (moment().isSameOrAfter(calendarEventEndTime)) {
        return true;
      }

      return false;
    }

    compareValues(key, ascending) {
      return function innerSort(a, b) {
        var varA, varB;

        switch (key) {
          case 'startTime':
            varA = moment(a[key]);
            varB = moment(b[key]);
            break;

          case 'en_route':
          case 'check_in':
          case 'check_out':
            varA = a.sortableValues.appointmentStatusData[key] ? moment(a.sortableValues.appointmentStatusData[key]) : null;
            varB = b.sortableValues.appointmentStatusData[key] ? moment(b.sortableValues.appointmentStatusData[key]) : null;
            break;

          case 'calendarEventTypesDisplay':
            varA = a[key].value;
            varB = b[key].value;
            break;

          case 'dryRunReason':
          case 'calendarUser':
          case 'statusRank':
            varA = a.sortableValues[key];
            varB = b.sortableValues[key];
            break;

          case 'customer':
            varA = a.sortableValues[key];
            varB = b.sortableValues[key];
            break;

          default:
            varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
            varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];
        }

        var comparison = 0;

        if (key === 'startTime' || key === 'en_route' || key === 'check_in' || key === 'check_out') {
          if (varA && varB && varA._isAMomentObject && varB._isAMomentObject) {
            comparison = varA.isSameOrAfter(varB) ? 1 : -1;
          } else if (varA && varA._isAMomentObject && !varB) {
            comparison = 1;
          } else if (varB && varB._isAMomentObject && !varA) {
            comparison = -1;
          } else {
            comparison = 0;
          }
        } else if (key === 'dryRunReason' || key === 'calendarUser') {
          if (varA && varB) {
            var varACompare = typeof varA.name === 'string' ? varA.name.toUpperCase() : varA.name;
            var varBCompare = typeof varB.name === 'string' ? varB.name.toUpperCase() : varB.name;

            if (varACompare > varBCompare) {
              comparison = 1;
            } else if (varACompare < varBCompare) {
              comparison = -1;
            }
          } else if (varA && !varB || !varA && !varB) {
            comparison = 1;
          } else if (varB && !varA) {
            comparison = -1;
          }
        } else if (key === 'customer') {
          if (varA && varB) {
            var _varACompare = typeof varA.flname === 'string' ? varA.flname.toUpperCase() : varA.flname;

            var _varBCompare = typeof varB.flname === 'string' ? varB.flname.toUpperCase() : varB.flname;

            if (_varACompare > _varBCompare) {
              comparison = 1;
            } else if (_varACompare < _varBCompare) {
              comparison = -1;
            }
          } else if (varA && !varB || !varA && !varB) {
            comparison = 1;
          } else if (varB && !varA) {
            comparison = -1;
          }
        } else {
          if (varA && varB) {
            if (varA > varB) {
              comparison = 1;
            } else if (varA < varB) {
              comparison = -1;
            }
          } else if (varA && !varB || !varA && !varB) {
            comparison = 1;
          } else if (varB && !varA) {
            comparison = -1;
          }
        }

        return !ascending ? comparison * -1 : comparison;
      };
    }

    bindIntersectionObserver(element) {
      if (this.args.rowIndex == this.args.row.calendarPage.maxIndex - 1) {
        var viewportTolerance = {
          bottom: 300
        };
        var intersectionThreshold = [0, 1.0];
        var scrollableArea = this.args.scrollContext;
        var {
          onEnter
        } = this.inViewport.watchElement(element, {
          viewportTolerance,
          intersectionThreshold,
          scrollableArea
        });
        onEnter(this.didEnterViewport.bind(this));
      }
    }

    bindHorizontalScrolling() {
      var _this = this;

      var horScroll = function () {
        return _this._onScrollHorizontal();
      };

      (0, _jquery.default)("#calendar-main-".concat(this.args.gridIndex)).bind('scroll', _lodash.default.throttle(horScroll, 1));
      this.CalendarEventsService.trigger('scrollHorizontal', this.args.gridIndex, this.scrollLeft);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "CalendarEventsService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "inViewport", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "calendarEventRows", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "dryRunReasons", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "dryRunReasonsTask", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "calendarEventsTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "eventTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "statusRankTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "sortCalendarEventsTask", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "bindIntersectionObserver", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "bindIntersectionObserver"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "bindHorizontalScrolling", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "bindHorizontalScrolling"), _class.prototype)), _class));
  _exports.default = CalendarGridListView;
});