define("securitytrax-ember-ui/administration/company-settings/integrations/electronic-document-providers/edit-create-electronic-document-provider/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/subroute-modal", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _authenticatedRouteMixin, _subrouteModal, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _subrouteModal.default, _routePageviewLogging.default, {
    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['electronic_document_providers.modify']);
    },

    model({
      edpid
    }) {
      return Ember.RSVP.hash({
        electronicDocumentProviderId: edpid,
        parentRoute: this.transitionRoute
      });
    }

  });

  _exports.default = _default;
});