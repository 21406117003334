define("securitytrax-ember-ui/my-inventory/base-route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/has-page-title"], function (_exports, _authenticatedRouteMixin, _hasPageTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _hasPageTitle.default, {
    model() {
      return {
        user: this.modelFor('my-inventory')
      };
    },

    getPageTitle() {
      return this.intl.t('pages.users.my_inventory.title');
    },

    getRouteName() {
      var route = this.routeName.split('.').pop();
      return "pages.users.my_inventory.".concat(route.replace('-', '_'), ".title");
    }

  });

  _exports.default = _default;
});