define("securitytrax-ember-ui/models/base_models/on-site-information", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    techArrivalTime: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    techDepartureTime: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    enRouteLatitude: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 6
    }),
    enRouteLongitude: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 6
    }),
    enRouteTime: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    techArrivalLatitude: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 6
    }),
    techArrivalLongitude: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 6
    }),
    techDepartureLatitude: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 6
    }),
    techDepartureLongitude: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 6
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    })
  });

  _exports.default = _default;
});