define("securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/dealer-ally/contract/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/create-financing-base", "ember-concurrency"], function (_exports, _createFinancingBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var DealerAllyCreateFinancingContract = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = (0, _emberConcurrency.task)(function* () {
    var application = this.args.multiStepForm.formOriginalModelsGetVal('consumerFinancingApplication.model');
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);

    try {
      this.links = (yield application.invokeAction('get_links', {
        verb: 'POST',
        data: {
          data: {}
        }
      })).responsePayload.data.lender_response;
      this.achBankContract = {
        account_type: this.links.LoanParameters.BankAccountType,
        routing_number: this.links.LoanParameters.BankRoutingNumber,
        account_number: this.links.LoanParameters.BankAccountNumber,
        signing_url: this.links.ACHESignUrl
      };
      this.customerContract = {
        signing_url: this.links.SigningContractESignUrl,
        completion_date: this.links.LoanParameters.CompletionDate || this.args.multiStepForm.formCopiedModels.consumerFinancingApplication.model.completionDate
      };
      this.certificateContract = {
        signing_url: this.links.LCCESignUrl,
        address: this.links.LCCAddress.Address1,
        city: this.links.LCCAddress.City,
        state: this.links.LCCAddress.State,
        zip: this.links.LCCAddress.PostalCode
      };
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
    }
  }), _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, (_class = (_temp = class DealerAllyCreateFinancingContract extends _createFinancingBase.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "usStates", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "certificateContract", _descriptor3, this);

      _initializerDefineProperty(this, "links", _descriptor4, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor5, this);

      this.achBankContract = {};
      this.customerContract = {};
      this.certificateContract = {
        useApplicants: false
      };
      this.initDataTask.perform();
    }

    get accountTypes() {
      return [{
        name: this.intl.t('common.finance.checking'),
        id: 'C'
      }, {
        name: this.intl.t('common.finance.saving'),
        id: 'S'
      }];
    }

    afterSubmit() {
      this.buttons();
      this.initDataTask.perform();
    }

    buttons() {
      this.args.multiStepForm.registerStepData("contract", 'buttons', [{
        text: this.intl.t('common.actions.next'),
        click: () =>
        /*buttonPromise*/
        {
          return this.args.multiStepForm.currentStepNameKeySetVal('documents');
        },
        classNamesString: 'st-btn st-btn-primary save-application'
      }]);
    }

    updateCertificateContract() {
      var applicant = this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.consumerFinancingApplicant');
      var useApplicants = this.certificateContract.useApplicants;
      this.certificateContract = {
        useApplicants: useApplicants,
        address: useApplicants ? applicant.address1 : undefined,
        city: useApplicants ? applicant.city : undefined,
        state: useApplicants ? applicant.state : undefined,
        zip: useApplicants ? applicant.zip : undefined
      };
    }

    resetContractInformation(contractInformationKey) {
      switch (contractInformationKey) {
        case 'ach':
          this.achBankContract = {};
          this.links.ACHStatus = undefined;
          Ember.notifyPropertyChange(this, 'achBankContract');
          Ember.notifyPropertyChange(this.links, 'ACHStatus');
          break;

        case 'signing_contract':
          this.customerContract = {};
          this.links.ContractCompletionStatus = 'Empty';
          Ember.notifyPropertyChange(this, 'customerContract');
          Ember.notifyPropertyChange(this.links, 'ContractCompletionStatus');
          break;

        case 'lcc':
          this.certificateContract = {};
          this.links.LCCAddress = [];
          Ember.notifyPropertyChange(this, 'certificateContract');
          Ember.notifyPropertyChange(this.links, 'LCCAddress');
          break;
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "usStates", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "certificateContract", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "links", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "afterSubmit", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "afterSubmit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buttons", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "buttons"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateCertificateContract", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "updateCertificateContract"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetContractInformation", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "resetContractInformation"), _class.prototype)), _class));
  _exports.default = DealerAllyCreateFinancingContract;
});