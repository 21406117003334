define("securitytrax-ember-ui/components/bulk-edit/mass-edit/component", ["exports", "@glimmer/component", "ember-concurrency", "uuid"], function (_exports, _component, _emberConcurrency, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var MassEdit = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = (0, _emberConcurrency.task)(function* () {
    yield this.args.editList.forEach((itemToEdit, index) => {
      var itemGrouping = this.massEditFieldsList.pushObject({
        name: itemToEdit.get(this.titleKey),
        item: itemToEdit,
        fieldsToEdit: Ember.A(),
        rowsHtml: ''
      });
      this.args.fields.forEach((field, subIndex) => {
        var component = '';
        var optionsHtml = '<option value=""></option>';
        var oldValue = itemToEdit.get(field.key);
        var addHtmlAtEnd = true;
        var checked = oldValue ? 'checked="checked"' : '';
        var inputType = field.component === 'checkbox-input' ? 'checkbox' : 'text';
        itemGrouping.fieldsToEdit.pushObject({
          key: field.key,
          component: field.component,
          newValue: '',
          bindValueAsObject: field.bindValueAsObject,
          valueProperty: field.valueProperty,
          options: field.options
        });

        switch (field.component) {
          case 'text-input':
          case 'masked-input':
            if (!oldValue) {
              oldValue = field.component === 'text-input' ? '' : '0.00';
            }

            component = this.stDataListFast.getTextInputHtml(field.key, field.component, inputType);
            break;

          case 'checkbox-input':
            component = this.stDataListFast.getToggleHtml(field.key);
            oldValue = "\n                            <div class=\"switchable-input checkbox-input\">\n                                <div data-test-old-".concat(field.key, " class=\"st-toggle\">\n                                    <input \n                                        type=\"checkbox\"\n                                        ").concat(checked, "\n                                        disabled\n                                    >\n                                    <span\n                                        data-list-fast-toggle-name=\"old_").concat(field.key, "\"\n                                        class=\"slider round\"\n                                    />\n                                </div>\n                            </div>\n                        ");
            break;

          case 'select-input':
            for (var q = 0; q < field.options.length; q++) {
              var currOption = field.options.objectAt(q);
              optionsHtml += "\n                                <option value=\"".concat(currOption.get(field.valueProperty), "\">").concat(currOption.get(field.labelProperty), "</option>\n                            ");
            }

            component = this.stDataListFast.getSelectInputHtml(field.key, optionsHtml);

            if (oldValue === null || typeof oldValue !== 'object') {
              oldValue = field.options.findBy(field.valueProperty, oldValue).get(field.labelProperty);
            } else {
              addHtmlAtEnd = false;
              oldValue.then(fieldValue => {
                var oldFieldValue = fieldValue;

                if (fieldValue && typeof fieldValue === 'object') {
                  oldFieldValue = fieldValue.get(field.valueProperty);
                }

                var selectedOption = field.options.findBy(field.valueProperty, oldFieldValue);

                if (selectedOption) {
                  oldValue = selectedOption.get(field.labelProperty);
                } else {
                  oldValue = '';
                }

                this.addMassEditRowHtml(itemGrouping, JSON.stringify({
                  id: itemToEdit.id,
                  groupIndex: index,
                  rowIndex: subIndex
                }), field, oldValue, component);
              });
            }

            break;

          case 'textarea-input':
            component = this.stDataListFast.getTextareaInputHtml(field.key, field.rows);
            break;
        }

        if (addHtmlAtEnd) {
          this.addMassEditRowHtml(itemGrouping, JSON.stringify({
            id: itemToEdit.id,
            groupIndex: index,
            rowIndex: subIndex
          }), field, oldValue, component);
        }
      });
    });
  }), _dec7 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var successNotifications = [];
    var errorNotifications = [];
    var randomIndexBase = (0, _uuid.v4)();
    var multicallSubkeys = {};

    for (var index = 0; index < this.massEditFieldsList.length; index++) {
      multicallSubkeys["".concat(index).concat(randomIndexBase, "_").concat(this.args.model, "_mass_edit_subkey_subkey")] = true;
    }

    var multicallKey = randomIndexBase + "".concat(this.args.model, "_mass_edit_subkey");

    try {
      yield this.stMulticallService.setupMulticall({
        multicallKey,
        multicallSubkeys
      }, () => {
        for (var _index = 0; _index < this.massEditFieldsList.length; _index++) {
          var multicallSubkey = "".concat(_index).concat(randomIndexBase, "_").concat(this.args.model, "_mass_edit_subkey_subkey");
          this.editItemMulticall(this.massEditFieldsList[_index], {
            multicallKey,
            multicallSubkey
          }, successNotifications, errorNotifications);
        }
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }

    buttonPromise.then(() => {
      if (this.args.multiStepForm.onAfterSave) {
        this.args.multiStepForm.onAfterSave();
      }

      this.args.multiStepForm.formStartedSetVal(false);
    });
  }), _dec8 = Ember._action, (_class = (_temp = class MassEdit extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stDataListFast", _descriptor2, this);

      _initializerDefineProperty(this, "stMulticallService", _descriptor3, this);

      _initializerDefineProperty(this, "massEditFieldsList", _descriptor4, this);

      _initializerDefineProperty(this, "title", _descriptor5, this);

      _defineProperty(this, "titleKey", 'name');

      _initializerDefineProperty(this, "initDataTask", _descriptor6, this);

      _initializerDefineProperty(this, "massEditSaveTask", _descriptor7, this);

      this.massEditFieldsList = Ember.A();

      if (this.args.titleKey) {
        this.titleKey = this.args.titleKey;
      }

      if (!this.args.editList) {
        return Error('You must pass in editList to massEdit');
      }

      if (!this.args.fields) {
        return Error('You must pass in fields to massEdit');
      }

      if (!this.args.model) {
        return Error('You must pass in model to massEdit');
      }

      if (!this.args.multiStepForm) {
        return Error('You must pass in multiStepForm to massEdit');
      }

      this.title = this.intl.t("features.mass_edit.".concat(this.args.model, "_title"), {
        count: this.args.editList.length
      });
      this.initDataTask.perform();
    }

    get saveButton() {
      return [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.massEditSaveTask.perform(buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary'
      }];
    }

    setNewValueFromCallbackData(callbackData, listData) {
      var row = listData[callbackData.rowData['groupIndex']].fieldsToEdit[callbackData.rowData['rowIndex']];
      Ember.set(row, 'newValue', callbackData.newInputValue);
    }

    editItemMulticall(itemToEdit, multicallOptions, successNotifications, errorNotifications) {
      try {
        itemToEdit.fieldsToEdit.forEach(fieldToEdit => {
          itemToEdit.item.set(fieldToEdit.key, fieldToEdit.newValue);
        });
        this.stMulticallService.registerRequest(multicallOptions, itemToEdit.item.save({
          adapterOptions: {
            multicallOptions
          }
        }));
        successNotifications.push("Successfully saved ".concat(this.args.model));
      } catch (err) {
        errorNotifications.push(err);
      }
    }

    addMassEditRowHtml(itemGrouping, rowData, field, oldValue, component) {
      itemGrouping.rowsHtml += "\n            <div>\n                <div\n                    class=\"d-l-row align-justify grid-x d-l-body-row edit-".concat(field.key, "-row\"\n                    data-list-fast-row-data='").concat(rowData, "'\n                >\n                    <span class=\"label\"> </span>\n                    <div class=\"large-2 cell d-l-body-item\">\n                        <span class=\"label \"> Field </span>\n                        <span class=\"cell-content greyable-row-item\">\n                            ").concat(field.name, "\n                        </span>\n                    </div>\n                    <div class=\"large-4 cell d-l-body-item\">\n                        <span class=\"label \"> Old Value </span>\n                        <span class=\"cell-content greyable-row-item\">\n                            ").concat(oldValue, "\n                        </span>\n                    </div>\n                    <div class=\"large-4 cell d-l-body-item\">\n                        <span class=\"label \"> New Value </span>\n                        ").concat(component, "\n                    </div>\n                </div>\n            </div>\n        ");
    }

    initListEventsTask() {
      this.massEditFieldsList[0].fieldsToEdit.forEach(fieldToEdit => {
        var rowsSelector = ".edit-".concat(fieldToEdit.key, "-row");

        switch (fieldToEdit.component) {
          case 'text-input':
          case 'masked-input':
          case 'textarea-input':
            this.stDataListFast.watchRowTextInput({
              rowsSelector: rowsSelector,
              inputName: fieldToEdit.key
            }, callbackData => {
              this.setNewValueFromCallbackData(callbackData, this.massEditFieldsList);
            });
            break;

          case 'select-input':
            this.stDataListFast.watchRowSelectInput({
              rowsSelector: rowsSelector,
              inputName: fieldToEdit.key
            }, callbackData => {
              if (fieldToEdit.bindValueAsObject) {
                var row = this.massEditFieldsList[callbackData.rowData['groupIndex']].fieldsToEdit[callbackData.rowData['rowIndex']];
                var newOption = fieldToEdit.options.findBy(fieldToEdit.valueProperty, callbackData.newInputValue);
                Ember.set(row, 'newValue', newOption);
              } else {
                this.setNewValueFromCallbackData(callbackData, this.massEditFieldsList);
              }
            });
            break;

          case 'checkbox-input':
            this.stDataListFast.watchRowToggleButtons({
              rowsSelector: rowsSelector,
              toggleName: fieldToEdit.key
            }, callbackData => {
              this.setNewValueFromCallbackData(callbackData, this.massEditFieldsList);
            });
        }
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stDataListFast", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stMulticallService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "massEditFieldsList", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "title", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "massEditSaveTask", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "initListEventsTask", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "initListEventsTask"), _class.prototype)), _class));
  _exports.default = MassEdit;
});