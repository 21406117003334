define("securitytrax-ember-ui/users/loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dfNN2u0d",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],null,[[\"spinning\",\"size\",\"class\"],[true,\"large\",\"human-resources-spinner\"]]]]],\"hasEval\":false,\"upvars\":[\"spinner-svg\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/users/loading/template.hbs"
    }
  });

  _exports.default = _default;
});