define("securitytrax-ember-ui/components/html/st-list-item-image/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['st-list-item', 'st-list-item-image']
  });

  _exports.default = _default;
});