define("securitytrax-ember-ui/mixins/forms/adc", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    grants: Ember.inject.service(),
    ispService: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    isp: Ember.inject.service('events/isp'),
    isSubDealer: false,

    init() {
      this._super(...arguments);

      this.isp.on('refresh-adc-customer-information', this, '_eventCallback');
    },

    willDestroy() {
      this._super(...arguments);

      this.isp.off('refresh-adc-customer-information', this, '_eventCallback');
    },

    _eventCallback() {
      this.notifyPropertyChange('adcCustomerInformation');
    },

    adcConnectionFailed: Ember.computed('interactiveServicesProvider.isConnected', function () {
      return this.adcConnectionFailedTask.perform();
    }),
    adcConnectionFailedTask: (0, _emberConcurrency.task)(function* () {
      var isp = yield this.interactiveServicesProvider;
      return isp && !isp.get('isConnected');
    }),
    ispStatus: Ember.computed('customerSystemInformation.interactiveServicesId', 'customerOrderInformation.interactiveServicesProvider.id', function () {
      return !!this.get('customerSystemInformation.interactiveServicesId') && !!this.get('customerOrderInformation.interactiveServicesProvider.id');
    }),
    adcCustomerInformation: Ember.computed('customer.id', 'ispStatus', 'adcConnectionFailed', 'grants.userGrants', function () {
      return this.ispStatus && this.grants.getIndividualGrant('customers.manage_interactive_services_provider_integration') ? this.getAdcCustomerInformationTask.perform() : [];
    }),
    getAdcCustomerInformationTask: (0, _emberConcurrency.task)(function* () {
      var adcConnectionFailed = yield this.adcConnectionFailed;
      var customerInfo = {};

      if (!adcConnectionFailed) {
        try {
          customerInfo = yield this.ispService.get('invokeIspActionTask').perform({
            action: 'alarm_dot_com__getCustomerInfo',
            model: this.customer,
            attributes: {}
          });
          this.set('adcSerialNumber', customerInfo && customerInfo.customer_info && customerInfo.customer_info.modem_info ? customerInfo.customer_info.modem_info.modem_serial : null);

          if (customerInfo && customerInfo.customer_info && !customerInfo.customer_info.central_station_info) {
            customerInfo.customer_info['central_station_info'] = {
              phone_line_present: false,
              forwarding_option: 'Never'
            };
          }
        } catch (err) {
          this.set('custNotFound', this.custNotFound || err.errors[0].detail === 'Customer ID ' + this.multiStepForm.formCopiedModelsGetVal('customerSystemInformation.model.interactiveServicesId') + ' not found');
        }
      }

      return customerInfo ? customerInfo.customer_info : {};
    }),
    adcBranchOptions: Ember.computed('interactiveServicesProvider.id', 'grants.userGrants', function () {
      return this.get('interactiveServicesProvider.id') && this.grants.getIndividualGrant('interactive_services_providers.manage_interactive_services_provider_integration') ? this.adcBranchOptionsTask.perform() : [];
    }),
    adcBranchOptionsTask: (0, _emberConcurrency.task)(function* () {
      var adcConnectionFailed = yield this.adcConnectionFailed;
      var branches = [];

      if (adcConnectionFailed === false && this.grants.getIndividualGrant('interactive_services_provider_configs.manage')) {
        try {
          var returnData = yield this.ispService.get('invokeIspActionTask').perform({
            action: 'alarm_dot_com__getBranches',
            model: yield this.interactiveServicesProvider,
            attributes: {}
          });

          if (returnData && returnData.branches) {
            returnData.branches.forEach(branch => {
              branches.push({
                name: branch.name,
                value: branch.branch_id
              });
            });
          }
        } catch (err) {
          this.set('isSubDealer', true);
        }
      }

      return branches;
    }),
    sendWelcomeLetterTask: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.ispService.get('invokeIspActionTask').perform({
          model: this.customer,
          action: 'alarm_dot_com__emailWelcomeLetter',
          attributes: {}
        });
      } catch (error) {
        this.stNotificationManager.addErrorMessages(error, {
          notificationNamespace: 'modal'
        });
        throw err;
      }

      this.stNotificationManager.addSuccessKey('pages.customers.system.interactiveServicesProvider.welcomeLetterSent', 'modal');
    })
  });

  _exports.default = _default;
});