define("securitytrax-ember-ui/helpers/multiply", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.multiply = multiply;
  _exports.default = void 0;

  function multiply([leftside, rightside]) {
    return leftside * rightside;
  }

  var _default = Ember.Helper.helper(multiply);

  _exports.default = _default;
});