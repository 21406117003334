define("securitytrax-ember-ui/validations/customer-payment", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation", "lodash"], function (_exports, _validate, _baseValidation, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var isCustomerBillingCreate = data.extraValidationData.selectOrCreatePaymentId == 'create_payment_method';
      var invoiceDueAmount = data.extraValidationData.invoiceDueAmount;

      var noValidate = _lodash.default.get(data, 'extraValidationData.noValidate', false);

      var invoiceType = this.invoiceType;
      var amountNumericality = {
        greaterThan: 0,
        message: function () {
          return '^' + data.intlService.t('pages.customers.accounting.customer_payments.amount.validateMessage');
        }
      };
      var amountLessThanNumericality = {
        lessThan: 0,
        message: function () {
          return '^' + data.intlService.t('pages.customers.accounting.customer_payments.amount.validateMessage');
        }
      };
      var taxNumericality = {
        greaterThanOrEqualTo: 0,
        lessThanOrEqualTo: Number(this.amount),
        message: function () {
          return '^' + data.intlService.t('pages.customers.accounting.customer_payments.tax.validateMessage');
        }
      };

      if (invoiceDueAmount) {
        amountNumericality.lessThanOrEqualTo = invoiceDueAmount;
      }

      var validateObject = {
        amount: {
          presence: this.customMessageOrDefault(data, 'amount'),
          numericality: () => {
            if (noValidate && noValidate.includes('customerPayment-amount')) {
              return false;
            } else {
              if (invoiceType === 'refund_receipt') {
                return amountLessThanNumericality;
              } else {
                return false;
              }
            }
          }
        },
        tax: {
          numericality: () => {
            if (this.tax != null && this.amount >= 0) {
              return taxNumericality;
            } else {
              return false;
            }
          }
        },
        receiptEmails: {
          presence: () => {
            if (noValidate && noValidate.includes('customerPayment-receiptEmails')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'receiptEmails');
            }
          },
          format: () => {
            if (!this.receiptEmails || noValidate && noValidate.includes('customerPayment-receiptEmails')) {
              return false;
            } else {
              return {
                pattern: /\S+@\S+\.\S+/,
                //Basic test for text@text.text
                message: this.customMessage(data, 'receiptEmailInvalid')
              };
            }
          }
        },
        customerPaymentType: {
          presence: () => {
            if (noValidate && noValidate.includes('customerPayment-customerPaymentType')) {
              return false;
            } else {
              var value = !data.extraValidationData.processNow;

              if (value === true) {
                value = {
                  allowEmpty: false
                };
              }

              return value;
            }
          }
        },
        customerBilling: {
          presence: () => {
            if (data.extraValidationData.processNow && !isCustomerBillingCreate) {
              return {
                allowEmpty: false,
                message: function () {
                  return '^' + data.intlService.t('pages.customers.accounting.customer_billing.validations.select_payment_method');
                }
              };
            }

            return false;
          }
        },
        paymentDate: {
          presence: this.customMessageOrDefault(data, 'paymentDate')
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject, {
        fullMessages: this.prependProperty(data)
      });
      return this.outputFormatting(validationData, 'customerPayment');
    }

  });

  _exports.default = _default;
});