define("securitytrax-ember-ui/components/modals/st-paper/paper-dialog-content/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PaperDialogComponent
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    tagName: 'md-dialog-content',
    fullscreen: false,
    classNames: ['md-dialog-content cell dialog-content'],
    classNameBindings: ['fullscreen:auto']
  });

  _exports.default = _default;
});