define("securitytrax-ember-ui/customers/contacts/edit-create-customer-contact/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fAkRvYXV",
    "block": "{\"symbols\":[],\"statements\":[[8,\"modals/sub-route-modals/edit-create-customer-contact\",[],[[\"@customer\",\"@customerContactId\",\"@parentRoute\"],[[34,0,[\"customer\"]],[34,0,[\"customerContactId\"]],[34,0,[\"parentRoute\"]]]],null]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/customers/contacts/edit-create-customer-contact/template.hbs"
    }
  });

  _exports.default = _default;
});