define("securitytrax-ember-ui/components/pages/administration/company-settings/integrations/partner-companies/leads/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  var ParterCompanyLeads = (_dec = Ember._action, (_class = class ParterCompanyLeads extends _component.default {
    get leadsFormSteps() {
      return Ember.Object.create({
        leads: Ember.Object.create({
          stepNameKey: 'leads',
          title: 'leads',
          stepComponentPath: 'multi-step-form/forms/administration/integrations/partner-companies/leads'
        })
      });
    }

    get leadsFormOriginalModels() {
      return Ember.Object.create({
        leadCompany: {
          model: this.args.model.leadCompany,
          copyRelationMap: {
            defaultLeadStatus: {
              readOnly: true
            },
            leadIdentityDeliveryType: {
              readOnly: true
            },
            leadAutoresponseMessage: {
              readOnly: true
            }
          }
        }
      });
    }

    toggleTagView() {
      Ember.set(this, 'tagViewCollapsed', !this.tagViewCollapsed);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "toggleTagView", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "toggleTagView"), _class.prototype)), _class));
  _exports.default = ParterCompanyLeads;
});