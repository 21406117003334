define("securitytrax-ember-ui/leads/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/helpers/feature-is-enabled", "securitytrax-ember-ui/mixins/route-pageview-logging", "securitytrax-ember-ui/mixins/scroll-to-top"], function (_exports, _authenticatedRouteMixin, _featureIsEnabled, _routePageviewLogging, _scrollToTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _routePageviewLogging.default, _scrollToTop.default, {
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    pageTitle: Ember.inject.service(),
    router: Ember.inject.service(),
    quickAdd: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      if (!(0, _featureIsEnabled.featureIsEnabled)('leadsTab')) {
        this.transitionTo("customer-list");
      }

      return this.findGrantsTask.perform(['leads.view']);
    },

    setupController()
    /*controller , models*/
    {
      this._super(...arguments); //const intl = this.intl;
      //controller.set('breadCrumbTitle', intl.t('common.nav.leads'));


      this.pageTitle.setPageTitle(null, 'common.nav.leads');
    },

    actions: {
      // take care of controllers holding onto properties.
      // make sure leads list or any non leads routes won't hold onto leadID
      // make sure subroutes on Leads still work
      willTransition(transition) {
        var isLeadRoute = transition.routeInfos.find(o => o.name === 'leads');

        if (transition.to.name === 'leads.index' || !isLeadRoute) {
          this.controller.set('leadId', undefined);
          this.controller.set('lead', undefined);
        }

        if (!transition.from.name.includes('edit-create')) {
          this.quickAdd.set('closeRoute', transition.from.name);
        }

        this.quickAdd.set('showQuickAdd', false);
        this.quickAdd.set('page', undefined);
      }

    }
  });

  _exports.default = _default;
});