define("securitytrax-ember-ui/components/pages/my-account/notifications/component", ["exports", "securitytrax-ember-ui/mixins/notifications"], function (_exports, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_notifications.default, {
    tagName: '',
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    grants: Ember.inject.service(),
    groupedNotifications: Ember.computed('model.notifications', function () {
      var groupedArray = {};
      var notifications = this.get('model.notifications');
      notifications.uniqBy('category').map(notification => {
        var name = notification.get('category').replace(/[_,-]/g, ' ').replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toLowerCase() + txt.substr(1).toLowerCase();
        });
        var items = notifications.filterBy('category', notification.get('category')).filter(notification => {
          return this.disabledNotifications.find(subscription => subscription.get('notification.id') === notification.get('id')) == undefined;
        });
        groupedArray[name] = {
          items
        };
      });
      return groupedArray;
    }),
    disabledNotifications: Ember.computed('model.companySubscriptions', function () {
      return this.get('model.companySubscriptions').filter(subscription => {
        return subscription.get('objectToNotificationType') === 'unsubscribe';
      });
    }),
    techNotificationFormOriginalModels: Ember.computed('model.user', 'grants.userGrants', function () {
      return Ember.Object.create({
        user: {
          model: this.get('model.user'),
          copyRelationMap: {
            notificationSubscriptions: {
              doCopy: true,
              relationships: {
                notification: {
                  readOnly: true
                }
              }
            }
          }
        }
      });
    }),
    salesNotificationFormOriginalModels: Ember.computed('model.user', 'grants.userGrants', function () {
      return Ember.Object.create({
        user: {
          model: this.get('model.user'),
          copyRelationMap: {
            notificationSubscriptions: {
              doCopy: true,
              relationships: {
                notification: {
                  readOnly: true
                }
              }
            }
          }
        }
      });
    }),
    userNotificationFormOriginalModels: Ember.computed('model.user', 'grants.userGrants', function () {
      return Ember.Object.create({
        user: {
          model: this.get('model.user'),
          copyRelationMap: {
            notificationSubscriptions: {
              doCopy: true,
              relationships: {
                notification: {
                  readOnly: true
                }
              }
            }
          }
        }
      });
    }),
    inventoryNotificationFormOriginalModels: Ember.computed('model.user', 'grants.userGrants', function () {
      return Ember.Object.create({
        user: {
          model: this.get('model.user'),
          copyRelationMap: {
            notificationSubscriptions: {
              doCopy: true,
              relationships: {
                notification: {
                  readOnly: true
                }
              }
            }
          }
        }
      });
    })
  });

  _exports.default = _default;
});