define("securitytrax-ember-ui/utils/observers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addObserverWithInitialTrigger = addObserverWithInitialTrigger;

  function addObserverWithInitialTrigger() {
    var v = Ember.addObserver(...arguments);

    if (typeof arguments[2] === 'function') {
      arguments[2]();
    } else if (typeof arguments[2] === 'object') {
      if (typeof arguments[3] === 'function') {
        arguments[3]();
      } else if (typeof arguments[3] === 'string') {
        arguments[2][arguments[3]]();
      }
    }

    return v;
  }
});