define("securitytrax-ember-ui/components/customer/appointments/appointment-type-summary/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var AppointmentList = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service('events/calendar-events'), _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = (0, _emberConcurrency.task)(function* () {
    this.padding = this.args.padding || '';

    if (this.args.isMulticall) {
      var hashedData = yield (0, _emberConcurrency.hash)({
        completed: this.args.calendarEvent.appointmentCompletedTask.perform(this.args.multiCallKey),
        missed: this.args.calendarEvent.appointmentMissedTask.perform(this.args.multiCallKey),
        unableToComplete: this.args.calendarEvent.dryRunReason,
        appointmentStatusData: this.args.calendarEvent.getAppointmentStatusDataTask.perform(this.args.multiCallKey)
      });
      Ember.setProperties(this, hashedData);
    } else if (this.args.useGetAppointmentsAction) {
      Ember.set(this, 'completed', this.args.calendarEvent.appointment_status === 'completed');
      Ember.set(this, 'missed', this.args.calendarEvent.appointment_status === 'missed'); // set(this, 'future', this.args.calendarEvent.appointment_status === 'future appointment');

      Ember.set(this, 'progress', this.args.calendarEvent.appointment_status === 'in progress');
      Ember.set(this, 'unableToComplete', this.args.calendarEvent.appointment_status === 'unable to complete');
    } else {
      var _hashedData = yield (0, _emberConcurrency.hash)({
        completed: this.args.calendarEvent.appointmentCompleted,
        missed: this.args.calendarEvent.appointmentMissed,
        unableToComplete: this.args.calendarEvent.dryRunReason,
        appointmentStatusData: this.args.calendarEvent.appointmentStatusData
      });

      Ember.setProperties(this, _hashedData);
    }

    if (this.args.horizontalPosition) {
      this.horizontalPosition = this.args.horizontalPosition;
    }
  }), _dec12 = Ember._action, _dec13 = Ember._action, (_class = (_temp = class AppointmentList extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "userService", _descriptor, this);

      _initializerDefineProperty(this, "screen", _descriptor2, this);

      _initializerDefineProperty(this, "appointmentTimeService", _descriptor3, this);

      _initializerDefineProperty(this, "CalendarEventsService", _descriptor4, this);

      _initializerDefineProperty(this, "missed", _descriptor5, this);

      _initializerDefineProperty(this, "completed", _descriptor6, this);

      _initializerDefineProperty(this, "padding", _descriptor7, this);

      _initializerDefineProperty(this, "appointmentStatusData", _descriptor8, this);

      _initializerDefineProperty(this, "showEdit", _descriptor9, this);

      _initializerDefineProperty(this, "horizontalPosition", _descriptor10, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor11, this);

      this.appointmentTimeService;
      this.CalendarEventsService.on('updateEventOnSite', this, '_updateEventOnSite');
      this.initDataTask.perform();
    }

    willDestroy() {
      this.CalendarEventsService.off('updateEventOnSite', this, '_updateEventOnSite');
    }

    _updateEventOnSite(eventID) {
      if (this.args.calendarEvent.id === eventID.id) {
        this.initDataTask.perform();
      }
    }

    get noAvatarHtml() {
      if (this.args.useGetAppointmentsAction) {
        return '<img class="profile-img img-circle" src=' + this.userService.defaultProfileImageSrc + ' alt="" />';
      } else {
        return this.args.calendarEvent.calendarUser.get('noAvatarHtml');
      }
    }

    click(e) {
      e.stopPropagation();
    }

    updateStatus() {
      Ember.set(this, 'missed', ' missed');
    }

    editStatus() {
      this.showEdit = !this.showEdit;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "userService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "screen", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "appointmentTimeService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "CalendarEventsService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "missed", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "completed", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "padding", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "appointmentStatusData", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "showEdit", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "horizontalPosition", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'right';
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateStatus", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "updateStatus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editStatus", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "editStatus"), _class.prototype)), _class));
  _exports.default = AppointmentList;
});