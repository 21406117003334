define("securitytrax-ember-ui/components/multi-step-form/forms/invoices/refund-receipt/payments/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms/invoices/base"], function (_exports, _emberConcurrency, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var InvoicePayments = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
    this.customerInvoicePayments = yield this.getCustomerInvoicePayments.perform();
  }), _dec12 = (0, _emberConcurrency.task)(function* () {
    // TODO: Limit to just this invoice payments
    var otherCustomerPayments = yield this.store.query('customerPayment', {
      filter: "customer = ".concat(this.args.customer.get('id')),
      include: 'customer_invoice_payments'
    });
    var validCustomerPaymentsForCurrentInvoice = otherCustomerPayments.filter(payment => {
      // Also check to see if any amount of the customerPayment has been applied to this invoice
      var customerInvoicePaymentUsedOnCurrentInvoice = payment.customerInvoicePayments.find(invoicePayment => {
        // Do stupid ID checks cause Mirage is stupid
        return (JSON.parse(invoicePayment.id).invoice_id ? JSON.parse(invoicePayment.id).invoice_id : invoicePayment.id) === this.args.multiStepForm.formCopiedModelsGetVal('invoice.model.id');
      });
      return customerInvoicePaymentUsedOnCurrentInvoice;
    });
    var promiseArray = [];
    validCustomerPaymentsForCurrentInvoice.forEach(payment => {
      promiseArray.push(payment.get('customerBilling.title'));
    });
    var resolvedPromiseArray = yield (0, _emberConcurrency.all)(promiseArray);
    validCustomerPaymentsForCurrentInvoice.forEach((payment, index) => {
      Ember.set(payment, '_resolvedTitle', resolvedPromiseArray[index] ? resolvedPromiseArray[index] : payment.get('customerBillingOrPaymentTypeLabel'));
    });
    return validCustomerPaymentsForCurrentInvoice;
  }), _dec13 = Ember._action, (_class = (_temp = class InvoicePayments extends _base.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "grants", _descriptor3, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor4, this);

      _initializerDefineProperty(this, "sessionData", _descriptor5, this);

      _initializerDefineProperty(this, "invoiceService", _descriptor6, this);

      _initializerDefineProperty(this, "customerInvoicePayments", _descriptor7, this);

      _initializerDefineProperty(this, "openMenuId", _descriptor8, this);

      _initializerDefineProperty(this, "dynamicMenuItems", _descriptor9, this);

      _initializerDefineProperty(this, "actionsMenuIsOpen", _descriptor10, this);

      _initializerDefineProperty(this, "initFormTask", _descriptor11, this);

      _initializerDefineProperty(this, "getCustomerInvoicePayments", _descriptor12, this);

      this.initFormTask.perform();
    }

    get hiddenProgressBarSteps() {
      var hiddenArray = ['confirmRefundVoid', 'gettingStarted', 'viewProcessingInformation', 'history', 'editManualPayment'];
      return hiddenArray;
    }

    get disabledProgressBarSteps() {
      return [];
    }

    openRow(row) {
      if (row.get('authcode')) {
        this.args.multiStepForm.registerStepData('listPayments::viewProcessingInformation', 'currentPayment', row);
        Ember.set(this.args.multiStepForm, 'currentStepNameKey', 'listPayments::viewProcessingInformation');
      } else {
        this.args.multiStepForm.registerStepData('listPayments::viewProcessingInformation', 'currentPayment', row);
        Ember.set(this.args.multiStepForm, 'currentStepNameKey', 'listPayments::viewProcessingInformation');
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "invoiceService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "customerInvoicePayments", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "openMenuId", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "dynamicMenuItems", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "actionsMenuIsOpen", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "initFormTask", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "getCustomerInvoicePayments", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "openRow", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "openRow"), _class.prototype)), _class));
  _exports.default = InvoicePayments;
});