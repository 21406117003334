define("securitytrax-ember-ui/models/base_models/government-regulation", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    country: (0, _model.attr)('string', {
      'readOnly': false
    }),
    state: (0, _model.attr)('string', {
      'readOnly': false
    }),
    county: (0, _model.attr)('string', {
      'readOnly': false
    }),
    city: (0, _model.attr)('string', {
      'readOnly': false
    }),
    amount: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    review: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    governingBody: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['county', 'city', 'state']
    }),
    liable: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    responsible: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    notes: (0, _model.attr)('string', {
      'readOnly': false
    }),
    agencyType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['police', 'fire', 'medical']
    }),
    billAttention: (0, _model.attr)('string', {
      'readOnly': false
    }),
    billAddress1: (0, _model.attr)('string', {
      'readOnly': false
    }),
    billAddress2: (0, _model.attr)('string', {
      'readOnly': false
    }),
    billCity: (0, _model.attr)('string', {
      'readOnly': false
    }),
    billState: (0, _model.attr)('string', {
      'readOnly': false
    }),
    billZip: (0, _model.attr)('string', {
      'readOnly': false
    }),
    governmentRegulationType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['permit', 'registration', 'guard', 'decal']
    })
  });

  _exports.default = _default;
});