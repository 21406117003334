define("securitytrax-ember-ui/components/multi-step-form/forms/accounting/edit-create-payment-method/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "moment", "securitytrax-ember-ui/mixins/forms/customer-billing-reusable", "lodash", "ember-concurrency"], function (_exports, _switchableFormStep, _moment, _customerBillingReusable, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, _customerBillingReusable.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    confirmDeleteModalOpen: false,
    confirmDeleteWithReplacementModalOpen: false,
    matchingRecurringInvoices: undefined,
    billingCycleOptions: undefined,

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      var buttons = [];

      if (this.formMode !== 'view') {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.editCreatePaymentMethodSaveTask.perform(buttonPromise, multiStepForm);
          },
          classNamesString: 'st-btn st-btn-primary'
        });
      }

      if (this.formMode === 'edit' && this.grants.getIndividualGrant('customer_billings.delete')) {
        buttons.push({
          text: this.intl.t('common.actions.delete'),
          click: () => {
            return this.determineDeleteOrReplace.perform();
          },
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
        });
      }

      multiStepForm.registerStepData('paymentMethodFormStep', 'buttons', buttons);
    },

    initDataTask: (0, _emberConcurrency.task)(function* () {
      var hashedData = yield (0, _emberConcurrency.hash)({
        waitForFinishedCopyModels: (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true),
        billingCycleOptions: this.store.findAll('billingCycle')
      });
      Ember.set(this, 'billingCycleOptions', hashedData.billingCycleOptions);
    }).on('init'),
    deleteCustomerBilling: (0, _emberConcurrency.task)(function* (customerBilling, buttonPromise) {
      try {
        var multiStepForm = this.multiStepForm;
        yield customerBilling.destroyRecordWithRollbackTask.perform();
        buttonPromise.then(() => {
          if (!multiStepForm.isDestroyed) {
            multiStepForm.formStartedSetVal(false);
          }

          this.customer.customerBillings.reload();
          this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');

          if (this.transitionBackToParent && typeof this.transitionBackToParent === 'function') {
            this.transitionBackToParent();
          }
        });
      } catch (err) {
        (false && Ember.warn('deleteCustomerBilling task failed' + err, {
          id: 'accounting.customer_billing_delete'
        }));
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'invoiceConfirmDelete'
        });
        throw err;
      }
    }),
    confirmDeleteReplaceTitle: Ember.computed('multiStepForm.formOriginalModels.customerBilling.model', function () {
      var billingType = this.multiStepForm.formOriginalModelsGetVal('customerBilling.model.billType.name');
      var billingTypeId = this.multiStepForm.formOriginalModelsGetVal('customerBilling.model.billType.id');

      if (billingTypeId === '1' || billingTypeId === '2') {
        // 1 is Credit Card and 2 is Bank Account
        return this.intl.t('pages.customers.accounting.invoices.payment_method_delete_confirm_title', {
          billingType: billingType,
          lastFour: billingTypeId === '1' ? this.multiStepForm.formOriginalModels.customerBilling.model.billCcNumberLastFour : this.multiStepForm.formOriginalModels.customerBilling.model.lastFourBillBankNumber
        });
      }

      return this.intl.t('pages.customers.accounting.invoices.payment_method_delete_confirm_title_generic');
    }),
    editCreatePaymentMethodSaveTask: (0, _emberConcurrency.task)(function* (buttonPromise, multiStepForm) {
      var customer = this.customer;
      var isBillingDefault = multiStepForm.formCopiedModelsGetVal('customerBilling.isBillingDefault');
      var noValidate = multiStepForm.formCopiedModelsGetVal('customerBilling.model.billAddressSame') || multiStepForm.formCopiedModelsGetVal('customerBilling.model.requestMeta.use_default') ? ['customerBilling-billAddress1', 'customerBilling-billCity', 'customerBilling-billState', 'customerBilling-billZip'] : []; //create

      if (this.isNewRecord && !multiStepForm.formCopiedModelsGetVal('customerBilling.model.id')) {
        var resolvedBillingTypeToConnect = yield this.getBillingTypeModel.perform(this.billType);
        var savedCustomerBilling;

        if (this.billType === 'Credit Card') {
          var toCreateObjects = yield multiStepForm.validateCreateAll({
            overrideOriginalModelsConfig: Ember.Object.create({
              customerBilling: {
                model: null,
                copyRelationMap: {}
              }
            }),
            extraValidationData: {
              noValidate,
              billType: this.billType,
              toValidate: {
                billCcExpirationMonth: _lodash.default.get(multiStepForm, 'formCopiedModels.customerBilling.model.billCcExpirationMonth', null),
                billCcExpirationYear: _lodash.default.get(multiStepForm, 'formCopiedModels.customerBilling.model.billCcExpirationYear', null)
              },
              validationMessages: this.customerBillingValidationMessages
            }
          });
          Ember.set(toCreateObjects.customerBilling.data, 'customer', customer);
          Ember.set(toCreateObjects.customerBilling.data, 'createdAt', (0, _moment.default)());
          Ember.set(toCreateObjects.customerBilling.data, 'billType', resolvedBillingTypeToConnect);
          var customerBilling = this.store.createRecord('customerBilling', toCreateObjects.customerBilling.data);
          savedCustomerBilling = yield toCreateObjects.customerBilling.saveWithRollbackAndNotification(customerBilling, 'billingModal');
        } else {
          var _toCreateObjects = yield multiStepForm.validateCreateAll({
            overrideOriginalModelsConfig: Ember.Object.create({
              customerBilling: {
                model: null
              }
            }),
            extraValidationData: {
              noValidate,
              billType: this.billType,
              toValidate: {
                billCcExpirationMonth: _lodash.default.get(multiStepForm, 'formCopiedModels.customerBilling.model.billCcExpirationMonth', null),
                billCcExpirationYear: _lodash.default.get(multiStepForm, 'formCopiedModels.customerBilling.model.billCcExpirationYear', null)
              },
              validationMessages: this.customerBillingValidationMessages
            },
            notificationNamespace: 'billingModal',
            deepSaveFailNotificationMessage: 'Routing Number failed custom validation'
          });

          Ember.set(_toCreateObjects.customerBilling.data, 'customer', customer);
          Ember.set(_toCreateObjects.customerBilling.data, 'createdAt', (0, _moment.default)().format('YYYY-MM-DD'));
          Ember.set(_toCreateObjects.customerBilling.data, 'billType', resolvedBillingTypeToConnect);

          var _customerBilling = this.store.createRecord('customerBilling', _toCreateObjects.customerBilling.data);

          savedCustomerBilling = yield _toCreateObjects.customerBilling.saveWithRollbackAndNotification(_customerBilling, 'billingModal');
        }

        if (isBillingDefault) {
          customer.set('defaultCustomerBilling', savedCustomerBilling);
          yield customer.save();
        }

        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'billingModal');
        buttonPromise.then(() => {
          if (!multiStepForm.isDestroyed) {
            multiStepForm.formStartedSetVal(false);
          }

          if (this.transitionBackToParent && typeof this.transitionBackToParent === 'function') {
            this.transitionBackToParent();
          }
        });
      } else {
        //edit existing
        var validateConfig = null;

        if (this.billType === 'Credit Card') {
          validateConfig = {
            customerBilling: {
              copyRelationMap: {
                billType: {
                  readOnly: true
                }
              }
            }
          };
        } else {
          validateConfig = {
            customerBilling: {
              copyRelationMap: {
                billType: {
                  readOnly: true
                }
              }
            }
          };
        }

        if (isBillingDefault) {
          customer.set('defaultCustomerBilling', multiStepForm.formOriginalModelsGetVal('customerBilling.model'));
        } else if (multiStepForm.formOriginalModelsGetVal('customerBilling.model.id') == customer.get('defaultCustomerBilling.id')) {
          customer.set('defaultCustomerBilling', null);
        }

        yield customer.save();
        yield multiStepForm.validateSyncSaveAll({
          doSetupCopyModelsAfterSave: false,
          deepSave: false,
          overrideOriginalModelsConfig: validateConfig,
          extraValidationData: {
            noValidate,
            billType: this.billType,
            toValidate: {
              billCcExpirationMonth: _lodash.default.get(multiStepForm, 'formCopiedModels.customerBilling.model.billCcExpirationMonth', null),
              billCcExpirationYear: _lodash.default.get(multiStepForm, 'formCopiedModels.customerBilling.model.billCcExpirationYear', null)
            },
            validationMessages: this.customerBillingValidationMessages
          },
          notificationNamespace: 'billingModal'
        });
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        buttonPromise.then(() => {
          if (this.multiStepForm.onAfterSave) {
            this.multiStepForm.onAfterSave();
          }

          if (this.transitionBackToParent && typeof this.transitionBackToParent === 'function') {
            this.transitionBackToParent();
          }

          multiStepForm.formStartedSetVal(false);
        });
      }
    }),
    selectedPaymentMethod: Ember.computed(function () {
      var choices = this.paymentMethodChoices;
      return choices.length > 0 ? choices[0].packageId : null;
    }),
    determineDeleteOrReplace: (0, _emberConcurrency.task)(function* () {
      var invoices = yield this.store.query('recurringInvoice', {
        filter: "customer_billing = ".concat(this.multiStepForm.formOriginalModels.customerBilling.model.id, " and customer = ").concat(this.customer.id)
      });

      if (invoices.length === 0) {
        this.set('confirmDeleteModalOpen', true);
      } else {
        this.set('matchingRecurringInvoices', invoices);
        this.set('confirmDeleteWithReplacementModalOpen', true);
      }
    })
  });

  _exports.default = _default;
});