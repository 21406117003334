define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/nrg/upload-quote-confirmation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8KomvuKZ",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[2,\"    \"],[8,\"html/st-await\",[],[[\"@booleanLoaded\",\"@useSpinner\"],[[30,[36,1],[[35,0],[29]],null],true]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[11,\"div\"],[17,1],[12],[2,\"\\n            \"],[8,\"multi-step-form/progress-bar\",[],[[\"@config\",\"@modalButtons\",\"@loadedNamespaces\"],[[34,2,[\"progressBarConfig\"]],[34,2,[\"currentStepData\",\"buttons\"]],[30,[36,4],[[35,3],\"step6\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[8,\"html/st-card\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n                    \"],[8,\"files\",[],[[\"@model\",\"@modelToSave\"],[[34,0],[34,5,[\"recordType\"]]]],null],[2,\"\\n                \"]],\"parameters\":[]}]]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"not-eq\",\"multiStepForm\",\"currFormName\",\"concat\",\"newCustomer\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/nrg/upload-quote-confirmation/template.hbs"
    }
  });

  _exports.default = _default;
});