define("securitytrax-ember-ui/models/equipment-type", ["exports", "securitytrax-ember-ui/models/base_models/equipment-type"], function (_exports, _equipmentType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _equipmentType.default.extend({
    icon: Ember.computed('id', function () {
      var icons = ['alarm', 'video-camera', 'thermostat', 'garage-closed', 'cog', 'panel', 'keypad', 'wifi', 'smoke-detector', 'door-open', 'water-sensor-wet', 'water-valve-open', 'sound'];
      return 'icn-' + icons[this.id - 1];
    }),
    color: Ember.computed('id', function () {
      // TODO: Make these real colors instead of placheholders
      var colors = ['#22a1c1', '#5b3d8c', '#003865', '#2071dd', '#22a1c1', '#5b3d8c', '#003865', '#2071dd', '#22a1c1', '#5b3d8c', '#003865', '#2071dd', '#22a1c1'];
      return colors[this.id - 1];
    })
  });

  _exports.default = _default;
});