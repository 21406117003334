define("securitytrax-ember-ui/components/pages/administration/company-settings/organization/office-locations/component", ["exports", "securitytrax-ember-ui/mixins/sortable-list-mixin", "ember-concurrency", "lodash"], function (_exports, _sortableListMixin, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_sortableListMixin.default, {
    tagName: '',
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    enhancedFeatures: Ember.inject.service(),
    stFiltersService: Ember.inject.service(),
    location: null,
    locationFormStarted: false,
    locationFormMode: 'view',
    hasEnhancedInventory: undefined,
    limit: 50,
    page: 1,
    totalCount: undefined,
    officeLocationStatus: undefined,
    initData: (0, _emberConcurrency.task)(function* () {
      this.hasEnhancedInventory = yield this.enhancedFeatures.hasFeature('inventoryExtendedFunctionality');
    }).on('init'),
    locations: Ember.computed(function () {
      return this.locationsTask.perform();
    }),
    locationsTask: (0, _emberConcurrency.task)(function* () {
      var newFilters = yield this.stFiltersService.getFiltersByGroupKey('companyOfficeLocations');
      var locations = yield this.store.query('location', {
        filter: newFilters,
        include: 'equipment_vendor_to_recipients',
        limit: this.limit,
        page: this.page,
        count: 1
      });
      Ember.set(this, 'totalCount', _lodash.default.get(locations, 'meta.record_count', 0));
      return locations;
    }),
    locationIsEdit: Ember.computed('locationFormMode', function () {
      return this.locationFormMode === 'edit' ? true : false;
    }),
    locationFormSteps: Ember.computed('locationFormMode', 'location', function () {
      var _this$get;

      var intl = this.intl;
      var title = intl.t("common.forms.descriptive_title_".concat(this.locationFormMode), {
        id: this.get('location.id'),
        name: (_this$get = this.get('location.name')) !== null && _this$get !== void 0 ? _this$get : intl.t('pages.administration.organization.officeLocations.label')
      });
      return Ember.Object.create({
        general: Ember.Object.create({
          stepNameKey: 'general',
          title,
          progressBarTitle: intl.t('pages.customers.system.order.general'),
          stepComponentPath: 'multi-step-form/forms/administration/organization/edit-office-location/general'
        }),
        schedulingInformation: Ember.Object.create({
          stepNameKey: 'schedulingInformation',
          title,
          progressBarTitle: intl.t('pages.administration.organization.officeLocations.scheduling.schedulingInfo'),
          stepComponentPath: 'multi-step-form/forms/administration/organization/edit-office-location/scheduling-information'
        }),
        inventory: Ember.Object.create({
          stepNameKey: 'inventory',
          title,
          progressBarTitle: intl.t('pages.administration.company_settings.inventory.title'),
          stepComponentPath: 'multi-step-form/forms/administration/organization/edit-office-location/inventory'
        }),
        equipmentDetails: Ember.Object.create({
          stepNameKey: 'equipmentDetails',
          title,
          progressBarTitle: intl.t('pages.users.inventory.equipment_details.title'),
          stepComponentPath: 'multi-step-form/forms/administration/organization/edit-office-location/equipment-details',
          hidden: !this.grants.getIndividualGrant('equipment_to_recipients.view') || !this.hasEnhancedInventory
        }),
        vendorDetails: Ember.Object.create({
          stepNameKey: 'vendorDetails',
          title,
          progressBarTitle: intl.t('pages.users.inventory.vendor_details.title'),
          stepComponentPath: 'multi-step-form/forms/administration/organization/edit-office-location/vendor-details',
          hidden: !this.grants.getIndividualGrant('equipment_vendor_to_recipients.view')
        })
      });
    }),
    locationFormOriginalModels: Ember.computed('location', function () {
      return Ember.Object.create({
        location: {
          model: this.location,
          copyRelationMap: {
            dealerNumber: {
              readOnly: true
            },
            inventoryRecipientTemplate: {
              doValidate: false,
              doSave: false
            }
          }
        }
      });
    }),
    actions: {
      viewLocation(row) {
        if (this.grants.getIndividualGrant('locations.modify')) {
          Ember.set(this, 'locationFormMode', 'edit');
        }

        Ember.set(this, 'location', row);
        Ember.set(this, 'locationFormStarted', true);
      },

      filterSearch(setPage = true) {
        if (setPage) {
          Ember.set(this, 'page', 1);
        }

        Ember.notifyPropertyChange(this, 'locations');
      },

      clearFilters() {
        Ember.setProperties(this, {
          page: 1,
          officeLocationStatus: undefined
        });
        Ember.notifyPropertyChange(this, 'locations');
      }

    }
  });

  _exports.default = _default;
});