define("securitytrax-ember-ui/mixed-in-classes/leaflet-map", ["exports", "@glimmer/component", "securitytrax-ember-ui/config/environment", "securitytrax-ember-ui/utils/debug"], function (_exports, _component, _environment, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /* global L */
  class leafletMap extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "zoom", '16');

      _defineProperty(this, "lat", '0');

      _defineProperty(this, "lng", '0');

      _defineProperty(this, "ico", L.divIcon({
        iconSize: [50, 50],
        iconAnchor: [25, 83],
        popupAnchor: [0, -83],
        className: 'map-pin'
      }));

      _defineProperty(this, "app_id", _environment.default['securitytrax-ember-ui']['leaflet-map']['app_id']);

      _defineProperty(this, "app_code", _environment.default['securitytrax-ember-ui']['leaflet-map']['app_code']);

      _defineProperty(this, "provider", _environment.default['securitytrax-ember-ui']['leaflet-map']['provider']);
    }

    get isTestEnvironment() {
      return (0, _debug.isTestEnvironment)();
    }

  }

  _exports.default = leafletMap;
});