define("securitytrax-ember-ui/components/multi-step-form/forms/work-orders/add-appointment/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/work-orders/work-orders-base", "securitytrax-ember-ui/mixins/switchable-form-step"], function (_exports, _workOrdersBase, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _workOrdersBase.default.extend(_switchableFormStep.default, {
    CalendarEventsService: Ember.inject.service('events/calendar-events'),

    init() {
      this._super(...arguments);

      this.multiStepForm.currentStepData.buttons = this.formButtons;
    },

    hiddenProgressBarSteps: Ember.computed(function () {
      return ['getStarted'];
    }),
    disabledProgressBarSteps: Ember.computed(function () {
      return [];
    }),
    actions: {
      afterScheduleAppointment(options) {
        this.multiStepForm.formOriginalModelsGetVal('workOrder.model').reload();
        this.CalendarEventsService.trigger('refresh-page');

        if (options && options.goToGeneratePOs) {
          this.multiStepForm.currentStepNameKeySetVal('generatePOs');
        } else {
          this.multiStepForm.currentStepNameKeySetVal('appointments');
        }
      }

    }
  });

  _exports.default = _default;
});