define("securitytrax-ember-ui/helpers/active-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.activeClass = activeClass;
  _exports.default = void 0;

  function activeClass(params) {
    var [index, active] = params;
    return index === active ? 'selected' : '';
  }

  var _default = Ember.Helper.helper(activeClass);

  _exports.default = _default;
});