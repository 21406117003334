define("securitytrax-ember-ui/reports/telus-reports/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f6B1XAo/",
    "block": "{\"symbols\":[],\"statements\":[[8,\"pages/reports/telus-reports\",[],[[],[]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/reports/telus-reports/template.hbs"
    }
  });

  _exports.default = _default;
});