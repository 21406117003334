define("securitytrax-ember-ui/validations/dashboard-cards/dashboard-config-card", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation", "lodash"], function (_exports, _validate, _baseValidation, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_baseValidation.default, {
    validate(data = {}) {
      var noValidate = _lodash.default.get(data, 'extraValidationData.noValidate', false);

      var validateObject = {
        cardFocus: {
          presence: () => {
            if (noValidate && noValidate.includes('dashboardConfigCard-cardFocus')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'dashboardConfigCard-cardFocus');
            }
          }
        },
        dateRange: {
          presence: () => {
            if (noValidate && noValidate.includes('dashboardConfigCard-dateRange')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'dashboardConfigCard-dateRange');
            }
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      return this.outputFormatting(validationData, 'dashboardConfigCard');
    }

  });

  _exports.default = _default;
});