define("securitytrax-ember-ui/components/pages/home/work-orders/component", ["exports", "securitytrax-ember-ui/mixed-in-functions/grouped-property-sections", "ember-concurrency", "lodash", "securitytrax-ember-ui/mixins/table-column-sort", "securitytrax-ember-ui/utils/obj-helpers", "securitytrax-ember-ui/config/environment"], function (_exports, _groupedPropertySections, _emberConcurrency, _lodash, _tableColumnSort, _objHelpers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableColumnSort.default, {
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    grants: Ember.inject.service(),
    userService: Ember.inject.service(),
    workOrderEvent: Ember.inject.service('events/work-order-event'),
    stMulticallService: Ember.inject.service(),
    stTimers: Ember.inject.service(),
    tagName: '',
    limit: 50,
    page: 1,
    recordCount: undefined,
    workOrders: undefined,

    init() {
      this._super(...arguments);

      this.workOrderEvent.on('refresh-my-work-orders', this, '_callback');
      (0, _objHelpers.initializeState)(this, {
        stSortableColumnOptions: [{
          name: this.intl.t('common.columns.id') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'id',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.id') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-id',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('common.columns.due_date') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'due_date',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.due_date') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-due_date',
          chevronDirection: 'down'
        }, {
          name: this.intl.t('common.columns.created_date') + ' ' + this.intl.t('common.sorting.ascending'),
          value: 'created_at',
          chevronDirection: 'up'
        }, {
          name: this.intl.t('common.columns.created_date') + ' ' + this.intl.t('common.sorting.descending'),
          value: '-created_at',
          chevronDirection: 'down'
        }]
      });
    },

    willDestroy() {
      this.workOrderEvent.off('refresh-my-work-orders', this, '_callback');
    },

    _callback() {
      this.notifyPropertyChange('myWorkOrdersData');
    },

    model: Ember.computed(function () {
      return (0, _emberConcurrency.hash)({
        calendarEventTypes: this.store.findAll('calendar-event-type'),
        calendarEventDetailTypes: this.store.findAll('calendar-event-detail-type'),
        workOrderActions: this.store.findAll('workOrderAction')
      });
    }),
    useMultiCall: Ember.computed(function () {
      return _environment.default['environment'] != 'test';
    }),
    myWorkOrdersData: Ember.computed('stSortString', function () {
      return this.myWorkOrdersTask.perform();
    }),
    myWorkOrdersTask: (0, _emberConcurrency.task)(function* () {
      var closedAtFilter = '(closed_at >= \'' + moment().subtract(7, 'days').format('YYYY-MM-DD') + '\')';
      var user = yield this.user;
      var queryParams = {
        filter: '(assigned_user = ' + user.get('id') + ' and is_closed = false) or (assigned_user = ' + user.get('id') + ' and ' + closedAtFilter + ')',
        sort: this.stSortString ? this.stSortString : 'due_date',
        include: 'customer,work_order_type,tags,calendar_events,calendar_events.calendar_user,work_order_equipment',
        limit: this.limit,
        page: this.page
      };

      if (this.page == 1) {
        queryParams['count'] = 1;
      }

      var workOrders = yield this.store.query('workOrder', queryParams);
      this.set('workOrders', workOrders);

      if (this.page == 1) {
        this.set('recordCount', _lodash.default.get(workOrders, 'meta.record_count', 0));
      }

      return {
        workOrders: Ember.A([Ember.Object.create({
          name: this.intl.t('pages.customers.tasks.status.open'),
          collapsable: true,
          collapsed: false,
          collapseIconName: 'icn-chevron-up',
          icon: 'icn-circle-o',
          sections: (0, _groupedPropertySections.default)(),
          items: workOrders.filter(workOrder => {
            return !workOrder.get('isClosed');
          })
        }), Ember.Object.create({
          name: this.intl.t('pages.customers.tasks.status.closed'),
          collapsable: true,
          collapsed: false,
          collapseIconName: 'icn-chevron-up',
          icon: 'icn-circle-check',
          sections: (0, _groupedPropertySections.default)(),
          items: workOrders.filter(workOrder => {
            return workOrder.get('isClosed');
          })
        })]),
        record_count: this.recordCount
      };
    }),
    actions: {
      transitionToSubroute(path) {
        this.router.transitionTo('home.work-orders.edit-create-work-order', path);
      },

      updateMyWorkOrders() {
        this.notifyPropertyChange('myWorkOrdersData');
      },

      sortList(column, ascending) {
        this.sortPaginatedList(column, ascending);
      },

      mobileSortList(column) {
        this.sortMobilePaginatedList(column.value);
      }

    }
  });

  _exports.default = _default;
});