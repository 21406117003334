define("securitytrax-ember-ui/components/modals/confirm-bulk-appointment/component", ["exports", "@glimmer/component", "ember-concurrency", "moment", "uuid"], function (_exports, _component, _emberConcurrency, _moment, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var ConfirmBulkAppointment = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = (0, _emberConcurrency.task)(function* (buttonAnimationPromise) {
    try {
      yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
      var startTimeMoment = (0, _moment.default)(this.args.newCalendarEvent.startTime, 'hh:mm a');
      var startTime = this.args.newCalendarEvent.startingDate.clone().hour(startTimeMoment.hour()).minute(startTimeMoment.minute());
      this.args.multiStepForm.formCopiedModelsGetVal('calendarEvent.model').setProperties({
        calendarCategory: this.args.newCalendarEvent.calendarCategory,
        calendarEventType: this.args.newCalendarEvent.calendarEventType,
        startTime: startTime,
        duration: this.args.newCalendarEvent.duration,
        isPendingAdd: false,
        workOrder: this.args.workOrder,
        customer: this.args.customer,
        notes: this.args.newCalendarEvent.notes
      });

      if (this.args.newCalendarEvent.calendarEventDetailType) {
        this.args.multiStepForm.formCopiedModelsGetVal('calendarEvent.model').setProperties({
          calendarEventDetailType: this.args.newCalendarEvent.calendarEventDetailType
        });
      }

      var recurringMetaData = {
        end_date: this.args.newCalendarEvent.endingDate.format('YYYY-MM-DDTHH:mm:ss'),
        days: {
          sunday: this.args.newCalendarEvent.days.indexOf('sunday') > -1,
          monday: this.args.newCalendarEvent.days.indexOf('monday') > -1,
          tuesday: this.args.newCalendarEvent.days.indexOf('tuesday') > -1,
          wednesday: this.args.newCalendarEvent.days.indexOf('wednesday') > -1,
          thursday: this.args.newCalendarEvent.days.indexOf('thursday') > -1,
          friday: this.args.newCalendarEvent.days.indexOf('friday') > -1,
          saturday: this.args.newCalendarEvent.days.indexOf('saturday') > -1
        },
        recurring_interval_number: 1
      };
      var successNotifications = [];
      var errorNotifications = [];
      var randomIndexBase = (0, _uuid.v4)();
      var multicallSubkeys = {};

      for (var index = 0; index < this.args.newCalendarEvent.calendarUser.length; index++) {
        multicallSubkeys["".concat(index).concat(randomIndexBase, "_appointment_bulk_profiler_create_transaction_subkey")] = true;
      }

      var multicallKey = randomIndexBase + 'appointment_bulk_profiler_create';
      yield this.stMulticallService.setupMulticall({
        multicallKey,
        multicallSubkeys
      }, () => {
        for (var _index = 0; _index < this.args.newCalendarEvent.calendarUser.length; _index++) {
          var tech = this.args.newCalendarEvent.calendarUser[_index].model;
          var multicallSubkey = "".concat(_index).concat(randomIndexBase, "_appointment_bulk_profiler_create_transaction_subkey");
          this.createAppointmentMulticall(tech, recurringMetaData, {
            multicallKey,
            multicallSubkey
          }, successNotifications, errorNotifications);
        }
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
    }

    buttonAnimationPromise.then(() => {
      if (this && !this.isDestroyed) {
        Ember.set(this, 'dialogOpen', false);
      }

      this.args.onConfirm();
    });
  }), (_class = (_temp = class ConfirmBulkAppointment extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stMulticallService", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor4, this);

      _initializerDefineProperty(this, "confirmBulkAppointmentsTask", _descriptor5, this);

      this.confirmTitleText = this.intl.t('pages.customers.appointments.confirm_bulk.title', {
        flname: this.args.customer.get('flname')
      });
      var sd = (0, _moment.default)(this.args.newCalendarEvent.startingDate).startOf('day');
      var ed = this.args.newCalendarEvent.endingDate;
      var daysDictionary = {};
      this.args.newCalendarEvent.days.forEach(day => {
        daysDictionary[day.toUpperCase()] = true;
      });
      this.appointmentCount = 0;

      while (sd.isSameOrBefore(ed)) {
        var day = sd.format('dddd').toUpperCase();

        if (daysDictionary[day]) {
          this.appointmentCount++;
        }

        sd.add(1, 'day');
      }

      var durationTotalMinutes = this.args.newCalendarEvent.duration / 60;
      Ember.set(this, 'hours', Math.floor(durationTotalMinutes / 60));
      Ember.set(this, 'minutes', durationTotalMinutes - this.hours * 60);
      Ember.set(this, 'daysString', this.args.newCalendarEvent.days.map(day => day[0].toUpperCase() + day.substring(1, 3)).join(', '));
    }

    get confirmButtons() {
      var buttonChoices = [{
        text: this.intl.t('common.actions.save'),
        click: buttonAnimationPromise => {
          return this.confirmBulkAppointmentsTask.perform(buttonAnimationPromise);
        },
        classNamesString: 'st-btn-primary confirm-modal-confirm-button'
      }];
      return buttonChoices;
    }

    createAppointmentMulticall(tech, recurringMetaData, multicallOptions, successNotifications, errorNotifications) {
      try {
        var calendarEvent = this.args.multiStepForm.formCopiedModelsGetVal('calendarEvent.model');
        Ember.set(calendarEvent, 'calendarUser', tech);
        var newCalendarEvent = this.store.createRecord('calendarEvent', calendarEvent);
        newCalendarEvent.set('requestMeta', {
          recurring_data: recurringMetaData
        });
        this.stMulticallService.registerRequest(multicallOptions, newCalendarEvent.save({
          adapterOptions: {
            multicallOptions
          }
        }));
        successNotifications.push(this.intl.t('pages.customers.appointments.confirm_bulk.success_message'));
      } catch (err) {
        errorNotifications.push(err);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stMulticallService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "confirmBulkAppointmentsTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ConfirmBulkAppointment;
});