define("securitytrax-ember-ui/components/admin/account-management/add-ons/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var AddOns = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = (0, _emberConcurrency.task)(function* () {
    // BOWLES TODO: Pull feature company enabled value direct from session data with TRAX-12015
    var companyPublicInfo = yield this.sessionData.getPublicCompanyInfo();
    this.financingEnabledForCompany = companyPublicInfo && companyPublicInfo.ui_feature_flags.consumer_financing && companyPublicInfo.ui_feature_flags.consumer_financing.enabled;
  }), _dec14 = (0, _emberConcurrency.task)(function* () {
    var accountSummaries = null;

    try {
      accountSummaries = yield this.store.findAll('accountSummary');
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err);
    }

    if (accountSummaries && accountSummaries.length > 0) {
      return accountSummaries.objectAt(0);
    }

    return accountSummaries;
  }), _dec15 = (0, _emberConcurrency.task)(function* () {
    return yield this.enhancedFeatures.premiumFeatures;
  }), _dec16 = (0, _emberConcurrency.task)(function* () {
    var premiumFeatures = yield this.premiumFeatures;
    return Ember.Object.create({
      premiumFeatures: {
        model: premiumFeatures
      }
    });
  }), _dec17 = Ember._action, (_class = (_temp = class AddOns extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "grants", _descriptor3, this);

      _initializerDefineProperty(this, "sessionData", _descriptor4, this);

      _initializerDefineProperty(this, "enhancedFeatures", _descriptor5, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor6, this);

      _initializerDefineProperty(this, "router", _descriptor7, this);

      _defineProperty(this, "addOnFormMode", 'edit');

      _initializerDefineProperty(this, "currentAddOn", _descriptor8, this);

      _initializerDefineProperty(this, "currentState", _descriptor9, this);

      _initializerDefineProperty(this, "addOnFormStarted", _descriptor10, this);

      _initializerDefineProperty(this, "messagePlusFormStarted", _descriptor11, this);

      _initializerDefineProperty(this, "financingEnabledForCompany", _descriptor12, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor13, this);

      _initializerDefineProperty(this, "getAccountSummariesTask", _descriptor14, this);

      _initializerDefineProperty(this, "getPremiumFeaturesTask", _descriptor15, this);

      _initializerDefineProperty(this, "getModelTask", _descriptor16, this);

      this.initDataTask.perform();
    }

    get accountSummaries() {
      return this.getAccountSummariesTask.perform();
    }

    get premiumFeatures() {
      return this.getPremiumFeaturesTask.perform();
    }

    get addOnFormOriginalModels() {
      return this.getModelTask.perform();
    }

    get addOnForm() {
      var intl = this.intl;
      return Ember.Object.create({
        addOnFormStep: Ember.Object.create({
          stepNameKey: 'addOnFormStep',
          title: this.currentAddOn ? intl.t("pages.administration.account_management.add_ons.".concat(this.currentAddOn, ".confirmation")) : null,
          stepComponentPath: 'multi-step-form/forms/administration/account-management/add-on-confirmation'
        })
      });
    }

    get messagePlusForm() {
      return Ember.Object.create({
        messagePlusFormStep: Ember.Object.create({
          stepNameKey: 'messagePlusFormStep',
          title: this.intl.t("pages.administration.account_management.add_ons.messages_plus.confirmation"),
          stepComponentPath: 'multi-step-form/forms/administration/account-management/message-plus-confirmation'
        })
      });
    }

    configure(transitionRoute) {
      this.router.transitionTo(transitionRoute);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "enhancedFeatures", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "currentAddOn", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "currentState", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "addOnFormStarted", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "messagePlusFormStarted", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "financingEnabledForCompany", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "getAccountSummariesTask", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "getPremiumFeaturesTask", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "getModelTask", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "configure", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "configure"), _class.prototype)), _class));
  _exports.default = AddOns;
});