define("securitytrax-ember-ui/components/multi-step-form/forms/administration/permissions/group-details/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/switchable-form-step", "securitytrax-ember-ui/components/multi-step-form/forms/administration/permissions/permissions-base", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _emberConcurrency, _switchableFormStep, _permissionsBase, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _permissionsBase.default.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    permissionsService: Ember.inject.service(),
    router: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    selectedRole: 0,
    selectedRoles: undefined,
    showDuplicateGroupModal: false,
    parsedGroupResourceTypeGrants: undefined,
    addedGrants: undefined,
    removedGrants: undefined,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        selectedRoles: [],
        addedGrants: [],
        removedGrants: []
      });
    },

    isAdminGroup: Ember.computed('multiStepForm.formCopiedModels.group.model.id', function () {
      return this.multiStepForm.formCopiedModelsGetVal('group.model.id') === '1000';
    }),
    resourceTypesData: Ember.computed(function () {
      return this.permissionsService.get('getResourceTypes').perform();
    }),
    rolesData: Ember.computed(function () {
      return this.rolesDataTask.perform();
    }),
    rolesDataTask: (0, _emberConcurrency.task)(function* () {
      try {
        var newSelectedRoles = [];
        var hashedData = yield (0, _emberConcurrency.hash)({
          allRoles: this.store.findAll('role'),
          groupRoles: this.get('addEditGroup.roles')
        });
        hashedData.groupRoles.forEach(currRole => {
          newSelectedRoles.push(currRole.get('id'));
        });
        var options = [];
        hashedData.allRoles.forEach(currRole => {
          options.pushObject(Ember.Object.create({
            packageName: currRole.get('name'),
            packageId: currRole.get('id')
          }));
        });
        this.set('selectedRoles', newSelectedRoles);
        return {
          selectedRoleOptions: options,
          roles: hashedData.allRoles,
          selectedRoles: newSelectedRoles
        };
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err);
        (false && Ember.warn('HR group details get roles error: ' + err, {
          id: 'multiStepForm.forms.administration.permissions.groupDetails.rolesDataTask'
        }));
        throw err;
      }
    }),
    groupHasUsers: Ember.computed('addEditGroup.users.[]', function () {
      return this.get('addEditGroup.users').then(users => {
        return users.get('length') > 0;
      });
    }),
    duplicateGroupButtons: Ember.computed(function () {
      return [{
        text: this.intl.t('common.actions.cancel'),
        click: () => {
          this.set('showDuplicateGroupModal', false);
        },
        classNamesString: 'st-btn st-btn-secondary group-cancel-button'
      }, {
        text: this.intl.t('pages.administration.human_resources.duplicate_group'),
        click: buttonPromise => {
          return this.duplicateGroup.perform(this.multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary group-save-button'
      }];
    }),
    duplicateGroup: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      try {
        var newlySavedGroup = (yield multiStepForm.formOriginalModelsGetVal('group.model').invokeAction('duplicate', {
          verb: 'POST',
          processResponse: true,
          data: {
            data: {}
          }
        })).models[0];
        buttonPromise.then(() => {
          this.stNotificationManager.addSuccess(this.intl.t('common.notifications.save_successful'));
          multiStepForm.formStartedSetVal(false);
          this.set('showDuplicateGroupModal', false);
          this.set('addGroup', newlySavedGroup);
          this.router.transitionTo('administration.human-resources.permissions.group-details', newlySavedGroup);
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err);
        (false && Ember.warn('HR duplicate group error' + err, {
          id: 'multiStepForm.forms.administration.permissions.duplicateGroup'
        }));
        throw err;
      }
    }),
    editGroupTask: (0, _emberConcurrency.task)(function* (buttonPromise) {
      try {
        var hashedData1 = yield (0, _emberConcurrency.hash)({
          rolesData: this.rolesData,
          groupGrants: this.store.query('resourceTypeGrant', {
            limit: 0
          }) // BOWLES TODO: Better way?

        });
        var roles = hashedData1.rolesData.roles;
        var connectedRoles = [];
        this.selectedRoles.forEach(currRoleId => {
          connectedRoles.pushObject(roles.findBy('id', currRoleId));
        });
        var addedGrants = [];
        var removedGrants = [];
        hashedData1.groupGrants.forEach(grant => {
          if (grant.get('dirty')) {
            grant.set('dirty', false);

            if (grant.get('isChecked')) {
              addedGrants.push(grant.get('id'));
            } else {
              removedGrants.push(grant.get('id'));
            }
          }
        });
        yield (0, _emberConcurrency.hash)({
          syncSaveAll: this.multiStepForm.validateSyncSaveAll(),
          saveRoles: this.addEditGroup.updateRelationship('PATCH', 'roles', connectedRoles),
          addedGrants: addedGrants.length > 0 ? this.addEditGroup.updateRelationship('POST', 'resource_type_grants', addedGrants) : null,
          removedGrants: removedGrants.length > 0 ? this.addEditGroup.updateRelationship('DELETE', 'resource_type_grants', removedGrants) : null
        });
        yield this.addEditGroup.reload();
        buttonPromise.then(() => {
          this.stNotificationManager.addSuccess(this.intl.t('common.notifications.save_successful'));
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err);
        (false && Ember.warn('HR edit group error: ' + err, {
          id: 'multiStepForm.forms.administration.permissions.editGroupTask'
        }));
        throw err;
      }
    }),
    deleteGroup: (0, _emberConcurrency.task)(function* (buttonPromise) {
      try {
        var groupToDelete = this.multiStepForm.formOriginalModelsGetVal('group.model');
        yield groupToDelete.destroyRecordWithRollbackTask.perform();
        buttonPromise.then(() => {
          this.notifyPropertyChange('groups');
          this.multiStepForm.setupCopyModels.perform();
          this.multiStepForm.validationErrors = {};
          this.router.transitionTo('administration.human-resources.permissions');
          this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'delete-permissions-group'
        });
        (false && Ember.warn('HR delete group error' + err, {
          id: 'multiStepForm.forms.administration.permissions.deleteGroup'
        }));
        throw err;
      }
    }),
    cancelGroupSaveTask: (0, _emberConcurrency.task)(function* () {
      var permissionsService = this.permissionsService;
      permissionsService.set('_currentGroup', null);
      permissionsService.set('_groupResourceTypePolicies', null);
      permissionsService.set('_resourceTypes', null);
      yield this.multiStepForm.setupCopyModels.perform();
      this.multiStepForm.validationErrors = {};
      this.router.transitionTo('administration.human-resources.permissions');
    }),
    actions: {
      cancelGroupSave() {
        return this.cancelGroupSaveTask.perform();
      }

    }
  });

  _exports.default = _default;
});