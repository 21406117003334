define("securitytrax-ember-ui/components/multi-step-form/forms/administration/equipment/edit-vendor/connector-details/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms/administration/equipment/edit-vendor/base-component", "securitytrax-ember-ui/mixins/switchable-form-step", "lodash"], function (_exports, _emberConcurrency, _baseComponent, _switchableFormStep, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    inventoryService: Ember.inject.service(),
    showDynamicFields: true,
    showNotification: true,
    connectorsNotice: Ember.computed(function () {
      return this.intl.t('pages.administration.equipment.equipmentVendors.connectors_description', {
        link: 'https://answers.securitytrax.com/Support/Company_and_Admin/Inventory_Management/Vendor_Integrations'
      });
    }),
    dynamicFields: Ember.computed(function () {
      return this.dynamicFieldsTask.perform();
    }),
    dynamicFieldsTask: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = this.multiStepForm;
      yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);
      var vendor = multiStepForm.formOriginalModelsGetVal('equipmentVendor.model');
      var additionalInformation = vendor ? yield vendor.get('additionalInformation') : null;
      this.set('showDynamicFields', true);

      if (additionalInformation && additionalInformation.fields) {
        additionalInformation.fields.forEach(field => {
          field['inputName'] = field.name.replace(/_\w/g, m => m[1].toUpperCase());
          field['validationKey'] = 'equipmentVendorConfig-' + field.inputName;

          if (field.value.options) {
            var options = [];
            var values = Object.values(field.value.options);
            Object.keys(field.value.options).forEach((key, index) => {
              if (values[index] !== 'Choose One') {
                options.push({
                  name: values[index],
                  value: key
                });
              }
            });
            field.value['options'] = options;
          }
        });
      }

      return additionalInformation ? additionalInformation.fields : [];
    }),
    connectorOptions: Ember.computed(function () {
      var inventoryService = this.inventoryService;
      return inventoryService.get('getConnectorOptionsTask').perform();
    }),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('connectorDetails', 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.saveEquipmentVendorConfigTask.perform(multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary equipment-vendor-save-button'
      }]);
    },

    saveEquipmentVendorConfigTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var formattedObjects = [];
      var dynamicFields = yield this.dynamicFields;
      var noValidate = ['equipmentVendorConfig-emailToAddress', 'equipmentVendorConfig-emailFromAddress', 'equipmentVendorConfig-ediSenderId', 'equipmentVendorConfig-apiKey', 'equipmentVendorConfig-interactiveServicesProviderId'];
      var validate = [];

      if (this.showDynamicFields) {
        dynamicFields.forEach(field => {
          if (field.value.required) {
            validate.push('equipmentVendorConfig-' + field.name.replace(/_\w/g, m => m[1].toUpperCase()));
          }
        });
      }

      formattedObjects.push(Ember.Object.create({
        model: {
          emailToAddress: this.multiStepForm.formCopiedModelsGetVal('equipmentVendor.model.equipmentVendorConfig.connectorConfig.fields.email_to_address'),
          emailFromAddress: this.multiStepForm.formCopiedModelsGetVal('equipmentVendor.model.equipmentVendorConfig.connectorConfig.fields.email_from_address'),
          ediSenderId: this.multiStepForm.formCopiedModelsGetVal('equipmentVendor.model.equipmentVendorConfig.connectorConfig.fields.edi_sender_id'),
          apiKey: this.multiStepForm.formCopiedModelsGetVal('equipmentVendor.model.equipmentVendorConfig.connectorConfig.fields.api_key'),
          interactiveServicesProviderId: this.multiStepForm.formCopiedModelsGetVal('equipmentVendor.model.equipmentVendorConfig.connectorConfig.fields.interactive_services_provider_id')
        },
        extraValidationData: {
          noValidate: _lodash.default.difference(noValidate, validate)
        },
        filePath: 'equipment-vendor-config'
      }));
      yield multiStepForm.validateList.perform(formattedObjects);
      yield this.saveEquipmentVendor.perform(multiStepForm, buttonPromise);
      Ember.notifyPropertyChange(this, 'dynamicFields');
    }),
    actions: {
      updateConnectorConfig() {
        Ember.set(this.multiStepForm.formCopiedModelsGetVal('equipmentVendor.model.equipmentVendorConfig'), 'connectorConfig', {
          fields: {}
        });
        this.set('showDynamicFields', false);
      },

      updateValue(field, event) {
        var config = this.multiStepForm.formCopiedModelsGetVal('equipmentVendor.model.equipmentVendorConfig.connectorConfig'); // Bowles TODO: this is a hack because BE is saving the fields as an array instead of an empty object.

        if (Ember.isEmpty(config.fields)) {
          Ember.set(config, 'fields', {});
        }

        this.multiStepForm.formCopiedModelsSetVal('equipmentVendor.model.equipmentVendorConfig.connectorConfig.fields.' + field, event.value ? event.value : event.target.value);
      }

    }
  });

  _exports.default = _default;
});