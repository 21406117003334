define("securitytrax-ember-ui/administration/account-management/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4618ejro",
    "block": "{\"symbols\":[\"navData\"],\"statements\":[[8,\"nav/top-nav-sub\",[],[[\"@title\",\"@breadCrumbs\",\"@breadCrumbData\",\"@expandSideNav\",\"@sideNavExpand\",\"@mobileHamburgerVisible\"],[[30,[36,0],[\"pages.administration.account_management.title\"],null],[34,1],[34,2],[30,[36,4],[[32,0],\"toggleLeft\"],[[\"target\"],[[35,3]]]],[34,3,[\"isLeftOpen\"]],true]],null],[2,\"\\n\"],[8,\"nav/base-left-nav\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[2,\"    \"],[8,\"nav/left-nav-link\",[[24,0,\"user-information-link\"]],[[\"@route\",\"@disabled\"],[\"administration.account-management.billing\",[32,1,[\"navGloballyDisabled\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,5],[\"icn-payment-history\"],null]],[2,\"\\n        \"],[1,[30,[36,0],[\"pages.administration.account_management.billing.title\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[2,\"\\n\"],[2,\"    \"],[8,\"nav/left-nav-link\",[[24,0,\"permissions-link\"]],[[\"@route\",\"@disabled\"],[\"administration.account-management.add-ons\",[32,1,[\"navGloballyDisabled\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,5],[\"icn-additional-services\"],null]],[2,\"\\n        \"],[1,[30,[36,0],[\"pages.administration.account_management.add_ons.title\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"page-content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"scroll-page\"],[12],[2,\"\\n        \"],[1,[30,[36,7],[[30,[36,6],null,null]],null]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"breadCrumbs\",\"breadCrumbData\",\"navigation\",\"action\",\"svg-icon\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/administration/account-management/template.hbs"
    }
  });

  _exports.default = _default;
});