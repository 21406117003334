define("securitytrax-ember-ui/components/dashboard-config/live/dashboard-card/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: '',
    cardTitle: Ember.computed('cardName', function () {
      return this.titleOverride || this.intl.t('pages.home.' + (this.cardName.replace(/-/g, '_'), {
        day: ''
      }));
    }),
    componentToUse: Ember.computed('cardName', function () {
      return 'components/homepage/dashboard/' + this.cardName;
    })
  });

  _exports.default = _default;
});