define("securitytrax-ember-ui/components/customer/user-defined-fields/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cRaUzjw2",
    "block": "{\"symbols\":[\"multiStepForm\",\"@customer\",\"@udfContext\"],\"statements\":[[6,[37,4],[[30,[36,3],[\"customer_udfs.view\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"multi-step-form\",[],[[\"@formSteps\",\"@formOriginalModels\",\"@progressBarConfig\",\"@formStarted\"],[[34,0],[34,1],[34,2],true]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"multi-step-form/forms/user-defined-fields\",[],[[\"@multiStepForm\",\"@formMode\",\"@model\",\"@disableFields\",\"@hideProgressBar\",\"@udfContext\"],[[32,1],\"view\",[32,2],false,true,[32,3]]],null],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"customerUserDefinedFieldsStep\",\"formOriginalModels\",\"informationProgressBarConfig\",\"has-grants\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/customer/user-defined-fields/template.hbs"
    }
  });

  _exports.default = _default;
});