define("securitytrax-ember-ui/validations/central-station/telus-subject", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation", "lodash"], function (_exports, _validate, _baseValidation, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var noValidate = _lodash.default.get(data, 'extraValidationData.noValidate', false);

      var validateObject = {
        subject_first_name: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('telusSubject-fname')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'telusSubject-subject_first_name');
            }
          }
        },
        subject_last_name: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('telusSubject-lname')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'telusSubject-subject_last_name');
            }
          }
        },
        firm_name: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('telusSubject-firmName')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'telusSubject-firm_name');
            }
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      return this.outputFormatting(validationData, 'telusSubject');
    }

  });

  _exports.default = _default;
});