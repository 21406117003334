define("securitytrax-ember-ui/components/homepage/dashboard/at-risk-entries/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var AtRiskEntries = (_dec = Ember.inject.service, _dec2 = (0, _emberConcurrency.task)(function* () {
    var url = yield this.stAjax.getUrl('/dashboard_cards/at_risk_entries');
    var focus = typeof this.args.config.card_focus === 'object' ? this.args.config.card_focus.value : this.args.config.card_focus;
    return (this.args.useDefaultData ? this.defaultData : yield this.stAjax.request(url, {
      method: 'GET',
      data: {
        focus_type: focus
      }
    })).data;
  }), (_class = (_temp = class AtRiskEntries extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "stAjax", _descriptor, this);

      _initializerDefineProperty(this, "atRiskEntriesTask", _descriptor2, this);
    }

    get defaultData() {
      return {
        data: {
          'average_time_assigned': '2',
          'open_assignments': '12',
          'past_due': '9'
        }
      };
    }

    get atRiskEntries() {
      return this.atRiskEntriesTask.perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "stAjax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "atRiskEntriesTask", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AtRiskEntries;
});