define("securitytrax-ember-ui/components/inventory/user-review-physical-inventory/component", ["exports", "ember-concurrency", "lodash"], function (_exports, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    inventoryToDisplay: null,
    descrepanciesExist: false,

    init() {
      this._super(...arguments);

      this.inventoryListTask.perform();
    },

    inventoryListTask: (0, _emberConcurrency.task)(function* () {
      var equipmentList = yield this.equipment;
      var inventoryToSubmit = [];
      var inventoryToDisplay = equipmentList.filter(el => el.updateCount);
      inventoryToDisplay.forEach(function (equipmentItem) {
        var inventoryItem = Ember.Object.create({
          'equipment_id': equipmentItem.get('id'),
          'quantity': equipmentItem.get('quantity') ? Number(equipmentItem.get('quantity')) : 0
        });
        inventoryToSubmit.push(inventoryItem);
      });

      if (this.user.constructor.modelName === 'user') {
        var physicalInventoryPreview = (yield this.user.invokeAction('perform_physical_inventory', {
          verb: 'POST',
          data: {
            meta: {
              check_for_discrepencies: true
            },
            data: inventoryToSubmit
          }
        })).responsePayload.data;
        physicalInventoryPreview.forEach(reviewItem => {
          if (reviewItem.system_offset_quantity > 0) {
            var item = _lodash.default.find(inventoryToDisplay, {
              id: reviewItem.equipment_id.toString()
            });

            Ember.set(item, 'hasDiscrepancy', true);
            Ember.set(this, 'descrepanciesExist', true);
          }
        });
      }

      this.set('inventoryToDisplay', inventoryToDisplay);
    })
  });

  _exports.default = _default;
});