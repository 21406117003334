define("securitytrax-ember-ui/components/html/st-btn-add-remove/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V4aDp9ci",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,6],null,[[\"noBackground\",\"disabled\",\"class\",\"icon\",\"text\",\"iconOnly\",\"clickAction\"],[[35,5],[35,4],[30,[36,3],[\"st-btn-add-remove \",[30,[36,2],[[35,1],\"is-selected\"],null]],null],[30,[36,2],[[35,1],\"icn-check\",\"icn-add\"],null],\"add/remove\",true,[30,[36,0],[[32,0],\"addRemoveAction\"],null]]]]]],\"hasEval\":false,\"upvars\":[\"action\",\"isSelected\",\"if\",\"concat\",\"disabled\",\"noBackground\",\"html/st-button\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/st-btn-add-remove/template.hbs"
    }
  });

  _exports.default = _default;
});