define("securitytrax-ember-ui/components/pages/administration/company-settings/system/form-defaults/lead/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wxq5l4Gr",
    "block": "{\"symbols\":[\"leadDetailsDefaultsForm\",\"MultiStepFormComponent\",\"leadInformationDefaultsForm\",\"MultiStepFormComponent\"],\"statements\":[[10,\"div\"],[14,0,\"page-content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"scroll-page\"],[12],[2,\"\\n        \"],[8,\"html/st-await\",[],[[\"@useSpinner\",\"@booleanLoaded\"],[true,[32,0,[\"initData\",\"isIdle\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"multi-step-form\",[],[[\"@formSteps\",\"@formOriginalModels\",\"@formStarted\"],[[34,1],[34,2],true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[32,3,[\"currentStepData\",\"stepComponentPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                    \"],[8,[32,4],[],[[\"@multiStepForm\",\"@formMode\",\"@setFormMode\"],[[32,3],[32,0,[\"leadInformationDefaultsFormMode\"]],[30,[36,0],[[32,0],\"setFormMode\",\"leadInformationDefaultsFormMode\"],null]]],null],[2,\"\\n\"]],\"parameters\":[4]}]]],[2,\"            \"]],\"parameters\":[3]}]]],[2,\"\\n\\n            \"],[8,\"multi-step-form\",[],[[\"@formSteps\",\"@formOriginalModels\",\"@formStarted\"],[[32,0,[\"leadDetailsDefaultsFormSteps\"]],[32,0,[\"leadDetailsDefaultsFormOriginalModels\"]],true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                    \"],[8,[32,2],[],[[\"@multiStepForm\",\"@formMode\",\"@setFormMode\"],[[32,1],[32,0,[\"leadDetailsDefaultsFormMode\"]],[30,[36,0],[[32,0],\"setFormMode\",\"leadDetailsDefaultsFormMode\"],null]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"            \"]],\"parameters\":[1]}]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"leadInformationDefaultsFormSteps\",\"leadInformationDefaultsFormOriginalModels\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/administration/company-settings/system/form-defaults/lead/template.hbs"
    }
  });

  _exports.default = _default;
});