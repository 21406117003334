define("securitytrax-ember-ui/components/multi-step-form/forms/accounting/edit-create-payment-method/method-layouts/bank-account/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import SwitchableFormStep from 'securitytrax-ember-ui/mixins/switchable-form-step';
  var _default = Ember.Component.extend({
    tagName: '',
    intl: Ember.inject.service(),
    bankAccountTypes: Ember.computed(function () {
      return [{
        name: this.intl.t('common.finance.saving'),
        value: 'Savings'
      }, {
        name: this.intl.t('common.finance.checking'),
        value: 'Checking'
      }, {
        name: this.intl.t('common.finance.other'),
        value: 'Other'
      }];
    })
  });

  _exports.default = _default;
});