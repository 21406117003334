define("securitytrax-ember-ui/home/tech-today/appointment/create-task/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-concurrency", "securitytrax-ember-ui/mixins/subroute-modal", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _authenticatedRouteMixin, _emberConcurrency, _subrouteModal, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _subrouteModal.default, _routePageviewLogging.default, {
    grants: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['customer_tasks.create']);
    },

    model({
      tid
    }) {
      this.set('modelId', tid);
      return this.setupModelTask.perform();
    },

    setupModelTask: (0, _emberConcurrency.task)(function* () {
      var parentModel = yield this.modelFor(this.transitionRoute);
      var customer = yield parentModel.appointment.get('customer');
      return Ember.RSVP.hash({
        customer,
        customerTaskId: this.modelId,
        parentRoute: this.transitionRoute,
        taskTemplates: this.store.query('template', {
          filter: 'template_type = 2 and active = true',
          include: 'template_field_defaults,template_type.template_type_columns'
        })
      });
    })
  });

  _exports.default = _default;
});