define("securitytrax-ember-ui/demo/confirm/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yJNZyXqG",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],null,null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h1\"],[14,0,\"card-title\"],[12],[2,\"Confirm Popup Page\"],[13],[2,\"\\n\\n\"],[6,[37,2],null,[[\"onCancel\",\"onConfirm\",\"confirmMessage\",\"confirmTitleText\"],[[30,[36,1],[[32,0],\"onConfirmCancel\"],null],[30,[36,1],[[32,0],\"onConfirmOk\"],null],\"Are you sure you want to do this?\",[30,[36,0],[\"common.confirm.really_delete\"],null]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"button\"],[14,1,\"action_button\"],[14,0,\"btn-primary\"],[12],[2,\"\\n            Action Button\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"hr\"],[12],[13],[2,\"\\n    \"],[10,\"h3\"],[12],[2,\"Cancel Pressed\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,1,\"cancel_pressed\"],[12],[1,[34,3]],[13],[2,\"\\n    \"],[10,\"hr\"],[12],[13],[2,\"\\n    \"],[10,\"h3\"],[12],[2,\"Ok Pressed\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,1,\"ok_pressed\"],[12],[1,[34,4]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"modals/confirm-popup\",\"cancelPressed\",\"okPressed\",\"pages/demo-page\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/demo/confirm/template.hbs"
    }
  });

  _exports.default = _default;
});