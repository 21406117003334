define("securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/adc/swap-modem/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/adc/adc-base", "ember-concurrency"], function (_exports, _adcBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adcBase.default.extend({
    ispService: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('swapModemSingleStep', 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.swapModem.perform(multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary adc-swap-modem-button'
      }]);
    },

    swapModem: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var attributes = this.attributes;
      yield this.updateAccount.perform(multiStepForm, buttonPromise, 'alarm_dot_com__swapModem', attributes, {
        newSerialNumber: attributes['new_serial_number'],
        restoreBackedUpSettings: attributes['restore_backed_up_settings_after_swap']
      }, [], 'interactive-services-provider/adc-swap-modem', 'modal2');
      buttonPromise.then(() => {
        multiStepForm.formStartedSetVal(false);
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
      });
    })
  });

  _exports.default = _default;
});