define("securitytrax-ember-ui/components/pages/administration/human-resources/permissions/group-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Nm+Cj1Fp",
    "block": "{\"symbols\":[\"multiStepForm\"],\"statements\":[[6,[37,5],null,[[\"formSteps\",\"formOriginalModels\",\"usesModal\",\"currentStepNameKey\",\"formStarted\"],[[35,4],[35,3],false,\"editGroup\",true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,1],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],[[\"addEditGroup\",\"multiStepForm\",\"formMode\"],[[35,0],[32,1],[35,2]]]]],[2,\"\\n\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[\"users.view\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],[\"multi-step-form/forms/administration/permissions/group-users\"],[[\"addEditGroup\"],[[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"component\",\"groupFormMode\",\"formOriginalModels\",\"addEditGroupSteps\",\"multi-step-form\",\"has-grants\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/administration/human-resources/permissions/group-details/template.hbs"
    }
  });

  _exports.default = _default;
});