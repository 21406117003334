define("securitytrax-ember-ui/validations/equipment-vendor", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateObject = {
        name: {
          presence: this.customMessageOrDefault(data, 'equipmentVendor-name')
        }
      }; //if they include an email, make sure its a valid email format

      if (this.email) {
        validateObject['email'] = {
          email: this.customMessageOrDefault(data, 'equipmentVendor-email')
        };
      }

      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject, {
        fullMessages: this.prependProperty(data)
      });
      return this.outputFormatting(validationData, 'equipmentVendor');
    }

  });

  _exports.default = _default;
});