define("securitytrax-ember-ui/components/multi-step-form/forms/customer-central-station-information/monitoring-companies/monitoring-company-type-24/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms/customer-central-station-information/base-monitoring-company/component", "securitytrax-ember-ui/mixed-in-classes/cs-link-account"], function (_exports, _emberConcurrency, _component, _csLinkAccount) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CMSMonitoringCompanyType = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = (0, _emberConcurrency.task)(function* () {
    var grants = this.grants;
    var grantsData = yield (0, _emberConcurrency.hash)({
      //customerModify: grants.getIndividualGrant('customers.modify'),
      customerContactsCreate: grants.getIndividualGrant('customer_contacts.create'),
      customerContactsModify: grants.getIndividualGrant('customer_contacts.modify'),
      customerEquipmentView: grants.getIndividualGrant('customer_equipment.view')
    });
    var hasGrants = //grantsData.customerModify
    grantsData.customerContactsCreate && grantsData.customerContactsModify && grantsData.customerEquipmentView;
    return hasGrants;
  }), _dec8 = (0, _emberConcurrency.task)(function* () {
    try {
      var multiStepForm = this.args.multiStepForm;
      yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);
      var requiredToShell = yield (0, _emberConcurrency.hash)({
        dealerNumber: this.args.customerSystemInformation.get('dealerNumber'),
        abortCode: this.args.customerSystemInformation.get('customerAbortCode'),
        accountNumber: this.args.customerSystemInformation.get('accountNum')
      });

      if (!this.args.customer.zip) {
        this.stNotificationManager.addErrorMessages(this.intl.t('pages.administration.integrations.monitoringCompanies.integrations.zip_required_to_shell'));
        throw new Error();
      }

      if (requiredToShell.dealerNumber === null || requiredToShell.abortCode === null || requiredToShell.abortCode.abortCode === null || requiredToShell.accountNumber === null) {
        this.stNotificationManager.addError(this.intl.t('pages.administration.integrations.monitoringCompanies.integrations.cms.required_to_shell'));
        throw '';
      }

      this.router.transitionTo('customers.information.manage-' + this.subrouteMapping.monitorMapping.findBy('id', '24').name);
    } catch (error) {
      (false && Ember.warn('cms start form error:' + error, {
        id: 'multiStepForm.forms.customer-central-station-information.monitoring-companies.monitoring-company-24.startForm'
      }));
      throw error && error.errors ? error.errors[0].detail : error;
    }
  }), _dec9 = (0, _emberConcurrency.task)(function* () {
    try {
      yield this.centralStationService.getData({
        model: this.args.customer,
        method: 'cms__customerIsEligibleToLinkToCmsAccount'
      });
      this.CSLinkAccount.accountFailText = '';
      this.CSLinkAccount.linkModalTitle = this.intl.t('pages.customers.system.link_account_title', {
        monitoringCompany: 'CMS',
        name: this.args.customer.flname
      });
      this.CSLinkAccount.linkAccountModalOpen = true;
    } catch (err) {
      if (err.errors[0].detail === "Unable to find account ".concat(this.args.multiStepForm.formOriginalModels.customerSystemInformation.model.accountNum, " in CMS.")) {
        this.CSLinkAccount.linkModalTitle = this.intl.t('pages.customers.system.account_not_found');
        this.CSLinkAccount.accountFailText = this.intl.t('pages.customers.system.account_not_found_text', {
          accountNum: this.args.multiStepForm.formOriginalModels.customerSystemInformation.model.accountNum,
          centralStation: 'CMS'
        });
        this.CSLinkAccount.linkAccountModalOpen = true;
      } else {
        this.stNotificationManager.addErrorMessages(err);
        throw err;
      }
    }
  }), (_class = (_temp = class CMSMonitoringCompanyType extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "grants", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "centralStationService", _descriptor3, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor4, this);

      _initializerDefineProperty(this, "intl", _descriptor5, this);

      _initializerDefineProperty(this, "isShelled", _descriptor6, this);

      _initializerDefineProperty(this, "checkGrantsTask", _descriptor7, this);

      _initializerDefineProperty(this, "startIntegrationForm", _descriptor8, this);

      _initializerDefineProperty(this, "checkCustomerLinkEligibility", _descriptor9, this);

      this.CSLinkAccount = new _csLinkAccount.default(...arguments, {
        checkCustomerLinkEligibility: this.checkCustomerLinkEligibility
      });

      if (this.args.customerSystemInformation.get('centralStationId')) {
        this.isShelled = true;
      }
    }

    get monitoringCompanyHasNeededGrants() {
      return this.checkGrantsTask.perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isShelled", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "checkGrantsTask", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "startIntegrationForm", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "checkCustomerLinkEligibility", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CMSMonitoringCompanyType;
});