define("securitytrax-ember-ui/components/multi-step-form/forms/accounting/invoice-statements/create-edit-customer-statement/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms/accounting/invoice-statements/invoice-statements-base"], function (_exports, _emberConcurrency, _invoiceStatementsBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CreateEditCustomerStatement = (_dec = Ember.inject.service('store'), _dec2 = Ember.inject.service('intl'), _dec3 = Ember.inject.service('grants'), _dec4 = Ember.inject.service('stNotificationManager'), _dec5 = Ember.inject.service('sessionData'), _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = (0, _emberConcurrency.task)(function* () {
    this.mailingAddress = yield this.formattedBillingAddress;
  }), _dec9 = (0, _emberConcurrency.task)(function* () {
    var customerAccountingInfo = yield this.args.customer.get('customerAccountingInformation');
    var invoiceBillingInformation = customerAccountingInfo.filterBy('useType', 'invoice_statement')[0];
    var defaultBillingInformation = customerAccountingInfo.filterBy('useType', 'default')[0];

    if (invoiceBillingInformation) {
      var address1 = invoiceBillingInformation.get('address1');
      var address2 = invoiceBillingInformation.get('address2');
      var city = invoiceBillingInformation.get('city');
      var state = invoiceBillingInformation.get('state');
      var zip = invoiceBillingInformation.get('zip');
      var mailingAddress = invoiceBillingInformation.get('firstName') + ' ' + invoiceBillingInformation.get('lastName') + '\n' + (address1 + ' ' + (address2 || '') + '\n' + city + ' ' + state + ', ' + zip);
      return invoiceBillingInformation.get('usePrimary') ? this.args.customer.nameAndAddress : mailingAddress;
    } else if (defaultBillingInformation) {
      var _address = defaultBillingInformation.get('address1');

      var _address2 = defaultBillingInformation.get('address2');

      var _city = defaultBillingInformation.get('city');

      var _state = defaultBillingInformation.get('state');

      var _zip = defaultBillingInformation.get('zip');

      var _mailingAddress = defaultBillingInformation.get('firstName') + ' ' + defaultBillingInformation.get('lastName') + '\n' + (_address + ' ' + (_address2 || '') + '\n' + _city + ' ' + _state + ', ' + _zip);

      return defaultBillingInformation.get('usePrimary') ? this.args.customer.nameAndAddress : _mailingAddress;
    }

    return this.args.customer.nameAndAddress;
  }), _dec10 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    yield multiStepForm.validateSyncSaveAll({
      notificationNamespace: 'editCustomerStatement'
    });
    buttonPromise.then(() => {
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'editCustomerStatement');
    });
  }), _dec11 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    try {
      yield this.saveCustomerStatement.perform(multiStepForm, buttonPromise);
    } catch (_unused) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'editCustomerStatement'
      });
      throw err;
    }

    var toAddresses;
    var customerAccountingInformation = yield this.args.customer.get('customerAccountingInformation');
    var invoiceStatementInformation = customerAccountingInformation.filterBy('useType', 'invoice_statement')[0];
    var defaultInformation = customerAccountingInformation.filterBy('useType', 'default')[0]; // Use statement billing info emails, then try default billing info emails, then just get customer email

    if (invoiceStatementInformation) {
      toAddresses = invoiceStatementInformation.usePrimary ? this.args.customer.email : invoiceStatementInformation.emails;
    } else if (defaultInformation) {
      toAddresses = defaultInformation.usePrimary ? this.args.customer.email : defaultInformation.emails;
    } else {
      toAddresses = this.args.customer.email;
    }

    var company = yield this.sessionData.getCompany();
    var emailSubject = this.intl.t('pages.customers.accounting.statements.send_subject_default', {
      name: company.name,
      id: this.args.multiStepForm.formOriginalModels.invoiceStatement.model.id
    });
    var formattedObjects = [];
    var addressString = toAddresses;
    var toAddress = addressString ? addressString.replace(/\s+/g, '').split(',') : [];
    formattedObjects.pushObject(Ember.Object.create({
      model: {
        recipients: toAddress
      },
      filePath: 'send'
    }));

    try {
      yield this.args.multiStepForm.validateList.perform(formattedObjects);
    } catch (err) {
      this.args.multiStepForm.currentStepNameKeySetVal('send');
    }

    try {
      yield multiStepForm.formOriginalModels.invoiceStatement.model.invokeAction('send', {
        verb: 'POST',
        data: {
          data: {
            meta: {
              as_html: false,
              template_id: 4,
              ignore_entity_email_address: true
            },
            attributes: {
              to_addresses: toAddresses,
              subject: emailSubject,
              html_body: ''
            }
          }
        }
      });
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccessKey('pages.customers.accounting.statements.send_and_save_successful');
        multiStepForm.formStartedSetVal(false);
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'editCustomerStatement'
      });
      throw err;
    }
  }), _dec12 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    try {
      yield this.saveCustomerStatement.perform(multiStepForm, buttonPromise);
      var pdfLink = yield multiStepForm.formOriginalModels.invoiceStatement.model.invokeAction('print', {
        verb: 'GET'
      });
      window.open(pdfLink.responsePayload.data.url, '_blank');
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'editCustomerStatement'
      });
      throw err;
    }
  }), _dec13 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var invoiceStatement = this.args.multiStepForm.formOriginalModelsGetVal('invoiceStatement.model');
    yield invoiceStatement.destroyRecordWithRollbackTask.perform();
    buttonPromise.then(() => {
      this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
      this.args.multiStepForm.formStartedSetVal(false);
    });
  }), _dec14 = Ember._action, (_class = (_temp = class CreateEditCustomerStatement extends _invoiceStatementsBase.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "grants", _descriptor3, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor4, this);

      _initializerDefineProperty(this, "sessionData", _descriptor5, this);

      _initializerDefineProperty(this, "confirmDeleteModalOpen", _descriptor6, this);

      _initializerDefineProperty(this, "mailingAddress", _descriptor7, this);

      _initializerDefineProperty(this, "initFormTask", _descriptor8, this);

      _initializerDefineProperty(this, "formattedBillingAddressTask", _descriptor9, this);

      _initializerDefineProperty(this, "saveCustomerStatement", _descriptor10, this);

      _initializerDefineProperty(this, "saveAndSendCustomerStatement", _descriptor11, this);

      _initializerDefineProperty(this, "saveAndPreviewCustomerStatement", _descriptor12, this);

      _initializerDefineProperty(this, "deleteCustomerStatement", _descriptor13, this);
    }

    get formattedBillingAddress() {
      return this.formattedBillingAddressTask.perform();
    }

    get dueAmountsAsArray() {
      var dueAmounts = this.args.multiStepForm.formCopiedModels.invoiceStatement.model.invoiceData.due_amounts;
      return [{
        oneDay: dueAmounts['1 - 30 days'] != 0 ? dueAmounts['1 - 30 days'].toFixed(2) : null,
        thirtyOneDays: dueAmounts['31 - 60 days'] != 0 ? dueAmounts['31 - 60 days'].toFixed(2) : null,
        sixtyOneDays: dueAmounts['61 - 90 days'] != 0 ? dueAmounts['61 - 90 days'].toFixed(2) : null,
        ninetyDays: dueAmounts['90+ days'] != 0 ? dueAmounts['90+ days'].toFixed(2) : null
      }];
    }

    get amountPastDue() {
      var dueAmounts = this.args.multiStepForm.formCopiedModels.invoiceStatement.model.invoiceData.due_amounts;
      var totalPastDue = 0;
      totalPastDue += dueAmounts['1 - 30 days'];
      totalPastDue += dueAmounts['31 - 60 days'];
      totalPastDue += dueAmounts['61 - 90 days'];
      totalPastDue += dueAmounts['90+ days'];
      return totalPastDue.toFixed(2);
    } // Save


    renderButtons() {
      var multiStepForm = this.args.multiStepForm;
      var buttons = [];

      if (this.args.formMode !== 'view') {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveCustomerStatement.perform(multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary statement-save-button',
          positionOptions: 'up left',
          additionalClickActions: [{
            label: this.intl.t('common.actions.saveAndClose'),
            action: buttonPromise => {
              return this.saveCustomerStatement.perform(multiStepForm, buttonPromise, true).then(() => {
                this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
                multiStepForm.formStartedSetVal(false);
              });
            },
            classNamesString: 'dropdown-save-close'
          }, {
            label: this.intl.t('common.actions.saveAndSend'),
            action: buttonPromise => {
              return this.saveAndSendCustomerStatement.perform(multiStepForm, buttonPromise);
            },
            classNamesString: 'dropdown-save-send'
          }, {
            label: this.intl.t('common.actions.saveAndDownload'),
            action: buttonPromise => {
              return this.saveAndPreviewCustomerStatement.perform(multiStepForm, buttonPromise);
            },
            classNamesString: 'dropdown-save-preview'
          }]
        });
      }

      if (this.grants.getIndividualGrant('invoice_statements.delete') && this.args.formMode !== 'create') {
        buttons.push({
          text: this.intl.t('common.actions.delete'),
          click: () => {
            this.confirmDeleteModalOpen = true;
          },
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
        });
      }

      multiStepForm.registerStepData('details', 'buttons', buttons);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "confirmDeleteModalOpen", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "mailingAddress", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "initFormTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "formattedBillingAddressTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "saveCustomerStatement", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "saveAndSendCustomerStatement", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "saveAndPreviewCustomerStatement", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "deleteCustomerStatement", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "renderButtons", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "renderButtons"), _class.prototype)), _class));
  _exports.default = CreateEditCustomerStatement;
});