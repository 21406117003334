define("securitytrax-ember-ui/components/multi-step-form/forms/system/brinks/create-edit-account/component", ["exports", "securitytrax-ember-ui/mixed-in-classes/customer-integrations", "ember-concurrency", "securitytrax-ember-ui/mixed-in-classes/row-list", "lodash", "securitytrax-ember-ui/components/multi-step-form/forms/system/brinks/brinks-base", "securitytrax-ember-ui/utils/strings"], function (_exports, _customerIntegrations, _emberConcurrency, _rowList, _lodash, _brinksBase, _strings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CreateEditAccount = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service('events/brinks-econtract-events'), _dec9 = Ember.inject.service('events/transitionEvent'), _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);

    try {
      // If redirecting from DocuSign gotta check what happened and save if needed
      var needToSaveContract = false;
      var displayMessage = 'pages.customers.system.order.';
      var displayType;
      var urlParams = new URLSearchParams(window.location.search);

      if (urlParams.has('eventname')) {
        switch (urlParams.get('eventname')) {
          case 'signcomplete':
            needToSaveContract = true;
            displayMessage += 'signcomplete';
            displayType = 'success';
            break;

          case 'viewcomplete':
            displayMessage += 'viewcomplete';
            displayType = 'success';
            break;

          case 'cancel':
            displayMessage += 'cancel';
            displayType = 'warn';
            break;

          case 'decline':
            displayMessage += 'decline';
            displayType = 'warn';
            break;

          case 'timeout':
            displayMessage += 'timeout';
            displayType = 'warn';
            break;

          case 'ttlexpired':
            displayMessage += 'ttlexpired';
            displayType = 'warn';
            break;

          case 'idcheck':
            displayMessage += 'idcheck';
            displayType = 'warn';
            break;

          case 'accesscode':
            displayMessage += 'accesscode';
            displayType = 'warn';
            break;

          case 'exception':
            displayMessage += 'exception';
            displayType = 'warn';
            break;

          default:
            displayMessage += 'unknown';
            displayType = 'warn';
            break;
        }

        if (displayMessage !== 'pages.customers.system.order.') {
          displayType === 'success' ? this.stNotificationManager.addSuccessKey(displayMessage, 'modal') : this.stNotificationManager.addWarningKey(displayMessage, 'modal');
        }

        window.history.replaceState(null, '', window.location.pathname);

        if (needToSaveContract) {
          yield this.centralStationService.postData({
            model: this.args.customer,
            method: 'brinks__saveCustomerEContractToFiles'
          });
        }

        this.brinksEcontractEvents.trigger('reload-cs-card');
      }

      var hashedData = yield (0, _emberConcurrency.hash)({
        contractTerm: this.args.multiStepForm.formOriginalModels.customer.model.customerOrderInformation.get('contractTerm'),
        eContractData: this.centralStationService.getData({
          model: this.args.customer,
          method: 'brinks__getCustomerEContractMetadata'
        }),
        siteOptions: this.centralStationService.getData({
          model: this.args.monitoringCompany,
          method: 'brinks__getDefaultSiteOptions'
        }),
        systemOptions: this.centralStationService.getData({
          model: this.args.monitoringCompany,
          method: 'brinks__getDefaultSystemOptions'
        }),
        accountInformation: this.centralStationService.getData({
          model: this.args.customer,
          method: 'brinks__getAccountInformation'
        }),
        monitoringCompanyConfig: this.args.monitoringCompany.get('monitoringCompanyConfig'),
        listData: this.centralStationService.getData({
          model: this.args.monitoringCompany,
          method: 'brinks__getLists',
          payload: {
            list_types: [{
              list_type: 'two_way_devices'
            }, {
              list_type: 'site_types'
            }, {
              list_type: 'system_types'
            }, {
              list_type: 'system_type_x_refs'
            }, {
              list_type: 'events'
            }, {
              list_type: 'equipment_locations'
            }, {
              list_type: 'equipment_types'
            }, {
              list_type: 'relations'
            }, {
              list_type: 'authority_types'
            }, {
              list_type: 'contact_types'
            }, {
              list_type: 'phone_types'
            }, {
              list_type: 'agency_types'
            }, {
              list_type: 'permit_types'
            }, {
              list_type: '_options_parsed'
            }, {
              list_type: 'out_of_service_categories'
            }]
          }
        })
      });
      var contractTypeCode = this.args.customer.accountType === 'Commercial' ? 'C' : 'R';
      this.twoWayDeviceOptions = hashedData.listData.lists.two_way_devices;
      this.siteTypeOptions = hashedData.listData.lists.site_types.filter(siteType => siteType.site_kind === contractTypeCode);
      this.systemTypeOptions = hashedData.listData.lists.system_types;
      this.systemTypeCrossRefs = hashedData.listData.lists.system_type_x_refs;
      this.eventOptions = hashedData.listData.lists.events;
      this.equipmentLocationOptions = hashedData.listData.lists.equipment_locations;
      this.equipmentTypeOptions = hashedData.listData.lists.equipment_types;
      this.relationsOptions = hashedData.listData.lists.relations;
      this.authorityTypeOptions = hashedData.listData.lists.authority_types;
      this.contactTypeOptions = hashedData.listData.lists.contact_types;
      this.phoneTypeOptions = hashedData.listData.lists.phone_types;
      this.agencyTypeOptions = hashedData.listData.lists.agency_types;
      this.permitTypeOptions = hashedData.listData.lists.permit_types;
      this.availableAgencies = hashedData.accountInformation.account_information.available_agencies;
      this.systemOptions = hashedData.listData.lists._options_parsed.filter(option => {
        return option.usage.includes('System');
      })[0].options;
      this.siteOptions = hashedData.listData.lists._options_parsed.filter(option => {
        return option.usage.includes('Site');
      })[0].options;
      this.contractId = hashedData.eContractData.customer_e_contract_metadata.contract_id;
      this.outOfServiceCategoriesOptions = hashedData.listData.lists.out_of_service_categories;
      this.accountInformation = hashedData.accountInformation.account_information;
      var additionalClickActions = [{
        label: this.intl.t('pages.customers.system.order.clickToSubmit'),
        action: () => {
          this.contractIdToSave = this.contractId;
          this.clickToSubmitFormStarted = true;
        }
      }, {
        label: this.intl.t('pages.customers.system.order.pullPanel'),
        action: () => {
          this.pullPanelFormStarted = true;
        }
      }];

      if (!this.contractId) {
        additionalClickActions.push({
          label: this.intl.t('pages.customers.system.order.createEcontract'),
          action: () => {
            this.args.setHasProgressBar(false);
            this.args.multiStepForm.currentStepNameKeySetVal('createEcontract');
          }
        });
      } else {
        additionalClickActions.push({
          label: this.intl.t('pages.customers.system.order.unlinkEcontract'),
          action: () => {
            this.unlinkContractFormStarted = true;
          }
        });
      }

      additionalClickActions.push({
        label: this.intl.t('pages.customers.system.order.placeAccountOutOfService'),
        action: () => {
          this.showOutOfServiceModal = true;
        }
      }); // Setup data

      var shellingData = this.SwitchableFormStep.isNewRecord ? hashedData.monitoringCompanyConfig.defaultCustomerInformation : hashedData.accountInformation.account_information.site_systems[0];
      this.additionalClickActions = additionalClickActions;
      this.args.multiStepForm.formCopiedModels.customer.model.shellingData = {};
      this.args.multiStepForm.formCopiedModels.customer.model.shellingData.siteType = shellingData.site_type_id;
      this.args.multiStepForm.formCopiedModels.customer.model.shellingData.systemType = shellingData.system_type_id;
      this.args.multiStepForm.formCopiedModels.customer.model.shellingData.secondarySystemType = shellingData.secondary_system_type_id;
      this.args.multiStepForm.formCopiedModels.customer.model.shellingData.panelLocation = shellingData.panel_location;
      this.args.multiStepForm.formCopiedModels.customer.model.shellingData.panelCode = shellingData.panel_code;

      if (this.SwitchableFormStep.isNewRecord) {
        this.args.multiStepForm.formCopiedModels.customer.model.shellingData.sitePhone = this.args.multiStepForm.formCopiedModels.customer.model.primaryPhone;
        this.args.multiStepForm.formCopiedModels.customer.model.shellingData.twoWayDeviceId = shellingData.two_way_device_id;
      } else {
        this.args.multiStepForm.formCopiedModels.customer.model.shellingData.sitePhone = shellingData.phone_1;
        this.args.multiStepForm.formCopiedModels.customer.model.shellingData.panelPhone = shellingData.panel_phone;
      } // Set up customer zones


      var customerEquipment = hashedData.accountInformation.account_information.syncable_customer_equipment;
      var formattedZoneInformationList = [];
      customerEquipment.forEach(equipmentPiece => {
        var savedBrinksZone = hashedData.accountInformation.account_information.zones.find(savedZone => {
          return savedZone.comment === equipmentPiece.zone;
        });
        var prefilledLocation = this.equipmentLocationOptions.find(location => location.name === _lodash.default.startCase(equipmentPiece.zone));
        formattedZoneInformationList.push({
          customer_equipment_id: equipmentPiece.id,
          event_id: savedBrinksZone ? savedBrinksZone.event_id : null,
          equipment_location_id: savedBrinksZone ? savedBrinksZone.equipment_location_id : prefilledLocation ? prefilledLocation.id : null,
          equipment_type_id: savedBrinksZone ? savedBrinksZone.equipment_type_id : null,
          zoneComment: equipmentPiece.zone,
          zoneId: equipmentPiece.zone_number
        });
      });
      this.customerZones = formattedZoneInformationList;
      this.setupContactsList();
      var formattedSavedCustomerInformationList = [];

      if (!this.SwitchableFormStep.isNewRecord) {
        hashedData.accountInformation.account_information.contacts.forEach(savedContact => {
          var correspondingContactFromOurSystem = customerContacts.find(contact => {
            return contact.phone1 === savedContact.phone_1 || contact.phone1 === savedContact.pin;
          });

          if (correspondingContactFromOurSystem) {
            formattedSavedCustomerInformationList.push({
              priority: correspondingContactFromOurSystem.sort,
              customer_contact_id: correspondingContactFromOurSystem.id,
              name: correspondingContactFromOurSystem.name,
              contact_type_id: savedContact.contact_type_id,
              relation_id: savedContact.relation_id,
              authority_type_id: savedContact.authority_type_id,
              contract_signer_flag: savedContact.contract_signer_flag === 'Y' ? true : false,
              has_key_flag: savedContact.has_key_flag === 'Y' ? true : false,
              evc: correspondingContactFromOurSystem.enhancedVerification ? 'Yes' : 'No',
              cell_primary_1st_contact: savedContact.cell_primary_1st_contact,
              phoneOrPin: savedContact.phone_1 || savedContact.pin,
              usesPin: savedContact.pin,
              phone_type_id_1: savedContact.phone_type_id_1
            });
          }
        });
        this.customerContactsList = formattedSavedCustomerInformationList.sortBy('priority').concat(formattedCustomerContactsList);
      } // Set up agencies


      var availableAgencyTypesForCustomer = this.agencyTypeOptions.filter(agencyType => {
        var index = hashedData.accountInformation.account_information.available_agencies.findIndex(availableAgency => {
          return availableAgency.agency_type_id === agencyType.id;
        });
        return index != -1;
      });
      var formattedCustomerAgencyList = [];
      availableAgencyTypesForCustomer.forEach(availableAgencyType => {
        formattedCustomerAgencyList.push({
          agency_type_id: availableAgencyType.id,
          agency_number: null,
          permit_number: null,
          permit_type_id: null,
          effective_date: null,
          expire_date: null,
          name: availableAgencyType.name,
          dropdownOptions: this.filteredAgencyNames(availableAgencyType.id)
        });
      });
      var formattedSavedCustomerAgencyList = [];

      if (!this.SwitchableFormStep.isNewRecord) {
        hashedData.accountInformation.account_information.site_agency_permits.forEach(savedAgency => {
          var correspondingAgency = formattedCustomerAgencyList.find(agency => {
            return agency.agency_type_id === savedAgency.agency_type_id;
          });
          formattedSavedCustomerAgencyList.push({
            agency_type_id: savedAgency.agency_type_id,
            agency_number: savedAgency.agency_number,
            permit_number: savedAgency.permit_number,
            permit_type_id: savedAgency.permit_type_id,
            effective_date: savedAgency.effective_date,
            expire_date: savedAgency.expire_date,
            name: correspondingAgency.name,
            dropdownOptions: this.filteredAgencyNames(savedAgency.agency_type_id)
          });
        });
      }

      if (formattedSavedCustomerAgencyList.length > 0) {
        if (formattedCustomerAgencyList.length === formattedSavedCustomerAgencyList.length) {
          this.customerAgencyList = formattedSavedCustomerAgencyList;
        } else {
          var extraAvailableAgencies = _lodash.default.differenceBy(formattedCustomerAgencyList, formattedSavedCustomerAgencyList, 'agency_type_id');

          this.customerAgencyList = formattedSavedCustomerAgencyList.concat(extraAvailableAgencies);
        }
      } else {
        this.customerAgencyList = formattedCustomerAgencyList;
      }

      var defaultSiteOptionsArray = Ember.A();
      var defaultSystemOptionsArray = Ember.A();

      if (hashedData.monitoringCompanyConfig.get('additionalDefaultSiteOptions') || !this.SwitchableFormStep.isNewRecord) {
        var siteOptions = this.SwitchableFormStep.isNewRecord ? hashedData.monitoringCompanyConfig.get('additionalDefaultSiteOptions') : hashedData.accountInformation.account_information.site_options.filter(option => {
          return this.siteOptions.findBy('id', option.id) != undefined;
        });
        var globalDefaultSiteOptionsArray = Ember.A([Ember.Object.create(this.siteOptions.findBy('id', 'CMPUR')), Ember.Object.create(this.siteOptions.findBy('id', 'LANG')), Ember.Object.create(this.siteOptions.findBy('id', 'CONTRLEN'))]);

        if (siteOptions.length > 0) {
          siteOptions.forEach(option => {
            var optionObject = _lodash.default.find(this.siteOptions, {
              id: this.SwitchableFormStep.isNewRecord ? option.site_option_value : option.id
            });

            defaultSiteOptionsArray.pushObject(Ember.Object.create({
              name: this.SwitchableFormStep.isNewRecord ? option.site_option_value : option.id,
              id: this.SwitchableFormStep.isNewRecord ? option.site_option_value : option.id,
              value: this.SwitchableFormStep.isNewRecord ? option.site_option_value_default : option.value,
              inputType: optionObject.type,
              dropdownOptions: optionObject.values
            }));
          });
        } else {
          globalDefaultSiteOptionsArray.forEach(option => {
            var value = null;

            if (option.id === 'LANG' && this.SwitchableFormStep.isNewRecord) {
              var stLang = this.langs.langsList.findBy('value', this.args.multiStepForm.formCopiedModels.customer.model.lang);
              value = stLang ? option.values.findBy('name', stLang.name) : null;
            }

            if (option.id === 'CONTRLEN' && this.SwitchableFormStep.isNewRecord) {
              value = hashedData.contractTerm.get('months') ? option.values.findBy('id', hashedData.contractTerm.get('months').toString()) : null;
            }

            defaultSiteOptionsArray.pushObject(Ember.Object.create({
              name: option.name,
              id: option.id,
              value,
              inputType: option.type,
              dropdownOptions: option.values
            }));
          });
        }

        if (hashedData.monitoringCompanyConfig.get('additionalDefaultSystemOptions') || !this.SwitchableFormStep.isNewRecord) {
          var systemOptions = this.SwitchableFormStep.isNewRecord ? hashedData.monitoringCompanyConfig.get('additionalDefaultSystemOptions') : hashedData.accountInformation.account_information.site_system_options.filter(option => {
            return this.systemOptions.findBy('id', option.id) != undefined;
          });
          var globalDefaultSystemOptionsArray = Ember.A([Ember.Object.create(this.systemOptions.findBy('id', 'DSL-VOIP')), Ember.Object.create(this.systemOptions.findBy('id', 'INST CODE')), Ember.Object.create(this.systemOptions.findBy('id', 'PRIVACY')), Ember.Object.create(this.systemOptions.findBy('id', 'SIGFMT')), Ember.Object.create(this.systemOptions.findBy('id', 'TRANSFORMER')), Ember.Object.create(this.systemOptions.findBy('id', 'WIRELESS'))]);

          if (systemOptions.length > 0) {
            systemOptions.forEach(option => {
              var optionObject = _lodash.default.find(this.systemOptions, {
                id: this.SwitchableFormStep.isNewRecord ? option.system_option_value : option.id
              });

              defaultSystemOptionsArray.pushObject(Ember.Object.create({
                name: this.SwitchableFormStep.isNewRecord ? option.system_option_value : option.id,
                id: this.SwitchableFormStep.isNewRecord ? option.system_option_value : option.id,
                value: this.SwitchableFormStep.isNewRecord ? option.system_option_value_default : option.value,
                inputType: optionObject.type,
                dropdownOptions: optionObject.values
              }));
            });
          } else {
            globalDefaultSystemOptionsArray.forEach(option => {
              defaultSystemOptionsArray.pushObject(Ember.Object.create({
                name: option.name,
                id: option.id,
                value: null,
                inputType: option.type,
                dropdownOptions: option.values
              }));
            });
          }
        }
      }

      this.setSecondarySystemOptions(_lodash.default.find(this.systemTypeOptions, {
        id: hashedData.monitoringCompanyConfig.get('defaultCustomerInformation.system_type_id')
      }));
      this.setDeviceTypeOptions(_lodash.default.find(this.systemTypeOptions, {
        id: hashedData.monitoringCompanyConfig.get('defaultCustomerInformation.secondary_system_type_id')
      }));
      this.SiteRowList.listArray = defaultSiteOptionsArray;
      this.SystemRowList.listArray = defaultSystemOptionsArray;
      this.SiteRowList.addRow();
      this.SystemRowList.addRow();
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), _dec19 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    try {
      if (!this.contractIdToSave) {
        throw new Error(this.intl.t('pages.customers.system.order.econtractIdRequired'));
      }

      yield this.centralStationService.postData({
        model: this.args.customer,
        method: 'brinks__upsertCommonFundingData',
        payload: {
          cs_number: this.args.multiStepForm.formCopiedModels.customer.model.customerSystemInformation.accountNum,
          contract_id: this.contractIdToSave
        }
      });
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
        this.contractIdToSave = null;
        this.clickToSubmitFormStarted = false;
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'clickToSubmit'
      });
      throw err;
    }
  }), _dec20 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    try {
      yield this.centralStationService.postData({
        model: this.args.customer,
        method: 'brinks__unlinkEContract'
      });
      buttonPromise.then(() => {
        this.unlinkContractFormStarted = false;
        this.stNotificationManager.addSuccessKey('common.notifications.unlink_successful', 'modal');
        this.brinksEcontractEvents.trigger('reload-cs-card');
        this.initDataTask.perform();
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'unlinkEcontract'
      });
      throw err;
    }
  }), _dec21 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    var noValidateArray = ['brinks-onTestHours', 'brinks-onTestMinutes', 'brinks-testCategory'];
    var formattedObjects = [];
    formattedObjects.pushObject(Ember.Object.create({
      model: {
        oosCategory: this.oosCategory
      },
      extraValidationData: {
        noValidate: noValidateArray
      },
      filePath: 'central-station/test-brinks'
    }));
    yield multiStepForm.validateList.perform(formattedObjects);

    try {
      yield this.centralStationService.postData({
        model: this.args.customer,
        method: 'brinks__putAccountOutOfService',
        payload: {
          out_of_service_category_id: this.oosCategory
        }
      });
      buttonPromise.then(() => {
        this.showOutOfServiceModal = false;
        this.oosCategory = null;
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'outOfService'
      });
      throw err;
    }
  }), _dec22 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    try {
      if (this.pullPanel) {
        yield this.centralStationService.postData({
          model: this.args.customer,
          method: 'brinks__pullPanel',
          payload: {}
        });
        buttonPromise.then(() => {
          this.store.findRecord('customerSystemInformation', this.args.customer.id).then(systemInfo => {
            systemInfo.reload();
          });
          this.pullPanelFormStarted = false;
          this.pullPanel = false;
          this.args.multiStepForm.formStartedSetVal(false);
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        });
      } else {
        throw new Error(this.intl.t('pages.customers.system.order.toggleToPull'));
      }
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'pullModal'
      });
      throw err;
    }
  }), _dec23 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    var formattedObjects = [];
    formattedObjects.pushObject(Ember.Object.create({
      model: {
        siteType: multiStepForm.formCopiedModels.customer.model.shellingData.siteType,
        systemType: multiStepForm.formCopiedModels.customer.model.shellingData.systemType,
        csNumber: multiStepForm.formCopiedModels.customer.model.customerSystemInformation.accountNum,
        receiverPhone: multiStepForm.formCopiedModels.customer.model.customerSystemInformation.receiverNum,
        sitePhone: multiStepForm.formCopiedModels.customer.model.shellingData.sitePhone,
        panelLocation: multiStepForm.formCopiedModels.customer.model.shellingData.panelLocation,
        panelCode: multiStepForm.formCopiedModels.customer.model.shellingData.panelCode
      },
      filePath: 'central-station/shell-brinks'
    }));

    if (this.customerContactsList.length > 0) {
      this.customerContactsList.forEach((contact, index) => {
        var validationObject = Ember.Object.create({
          modelName: 'brinksContact',
          model: contact,
          filePath: 'central-station/brinks-contact',
          extraValidationData: {
            overrideValidationKey: index + '-' + 'brinksContact'
          }
        });
        formattedObjects.pushObject(validationObject);
      });
    }

    yield multiStepForm.validateList.perform(formattedObjects);

    try {
      this.customerAgencyList.forEach(agency => {
        if (agency.effective_date) {
          agency.effective_date = moment(agency.effective_date).format('MM/DD/YYYY');
        }

        if (agency.expire_date) {
          agency.expire_date = moment(agency.expire_date).format('MM/DD/YYYY');
        }
      });
      this.SiteRowList.listArray.forEach(siteOption => {
        if (siteOption.dropdownType === 'date') {
          siteOption.value = moment(siteOption.value).format('MM/DD/YYYY');
        }
      });
      this.SystemRowList.listArray.forEach(systemOption => {
        if (systemOption.dropdownType === 'date') {
          systemOption.value = moment(systemOption.value).format('MM/DD/YYYY');
        }
      });

      if (this.SiteRowList.lastObjectEmpty()) {
        this.SiteRowList.removeRow();
      }

      if (this.SystemRowList.lastObjectEmpty()) {
        this.SystemRowList.removeRow();
      }

      if (this.args.multiStepForm.formOriginalModels.customer.model.customerContacts.length > 0) {
        // Save contacts into 
        var allContactSaves = [];
        this.args.multiStepForm.formOriginalModels.customer.model.customerContacts.forEach(stContact => {
          var brinksContact = this.customerContactsList.findBy('customer_contact_id', stContact.get('id'));
          var matchingPhoneTypeObj = brinksContact.phone_type_id_1 === 'CL' ? this.phoneTypeList.find(option => option.value === 'Mobile') : this.phoneTypeList.find(option => option.value === brinksContact.phone_type_id_1);
          Ember.setProperties(stContact, {
            name: brinksContact.name,
            enhancedVerification: brinksContact.evc === 'Yes',
            phone1: brinksContact.phoneOrPin,
            about: matchingPhoneTypeObj.value || stContact.about
          });
          allContactSaves.push(stContact.save());
        });
        yield (0, _emberConcurrency.all)(allContactSaves);
      }

      var brinksPayload = {
        site_systems: [{
          site_type_id: this.args.multiStepForm.formCopiedModels.customer.model.shellingData.siteType,
          system_type_id: this.args.multiStepForm.formCopiedModels.customer.model.shellingData.systemType,
          secondary_system_type_id: this.args.multiStepForm.formCopiedModels.customer.model.shellingData.secondarySystemType,
          two_way_device_id: this.args.multiStepForm.formCopiedModels.customer.model.shellingData.twoWayDeviceId,
          panel_phone: this.args.multiStepForm.formCopiedModels.customer.model.shellingData.panelPhone,
          panel_location: this.args.multiStepForm.formCopiedModels.customer.model.shellingData.panelLocation,
          panel_code: this.args.multiStepForm.formCopiedModels.customer.model.shellingData.panelCode,
          phone_1: this.args.multiStepForm.formCopiedModels.customer.model.shellingData.sitePhone
        }],
        zones: this.customerZones,
        site_agency_permits: this.customerAgencyList,
        contacts: this.customerContactsList,
        site_options: this.SiteRowList.listArray,
        site_system_options: this.SystemRowList.listArray
      };
      yield this.centralStationService.postData({
        model: this.args.customer,
        method: 'brinks__saveAccount',
        payload: brinksPayload
      });
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');

        if (this.args.formMode === 'create') {
          multiStepForm.onAfterSave();
          this.args.setHasProgressBar(true);
          this.SwitchableFormStep.progressBarHidden = false;
        }

        this.args.multiStepForm.formOriginalModels.customer.model.hasMany('customerContacts').reload();
        this.initDataTask.perform();
        this.store.findRecord('customerSystemInformation', this.args.customer.id).then(systemInfo => {
          systemInfo.reload();
        });
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      this.SiteRowList.addRow();
      this.SystemRowList.addRow();
      throw err;
    }
  }), _dec24 = Ember._action, _dec25 = Ember._action, _dec26 = Ember._action, _dec27 = Ember._action, _dec28 = Ember._action, _dec29 = Ember._action, _dec30 = Ember._action, (_class = (_temp = class CreateEditAccount extends _brinksBase.default {
    // Other modals
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "centralStationService", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "langs", _descriptor5, this);

      _initializerDefineProperty(this, "emergencyContacts", _descriptor6, this);

      _initializerDefineProperty(this, "router", _descriptor7, this);

      _initializerDefineProperty(this, "brinksEcontractEvents", _descriptor8, this);

      _initializerDefineProperty(this, "transitionEvent", _descriptor9, this);

      _defineProperty(this, "twoWayDeviceOptions", undefined);

      _defineProperty(this, "siteTypeOptions", undefined);

      _defineProperty(this, "systemTypeOptions", undefined);

      _defineProperty(this, "systemTypeCrossRefs", undefined);

      _defineProperty(this, "eventOptions", undefined);

      _defineProperty(this, "equipmentLocationOptions", undefined);

      _defineProperty(this, "equipmentTypeOptions", undefined);

      _defineProperty(this, "relationsOptions", undefined);

      _defineProperty(this, "authorityTypeOptions", undefined);

      _defineProperty(this, "contactTypeOptions", undefined);

      _defineProperty(this, "phoneTypeOptions", undefined);

      _defineProperty(this, "agencyTypeOptions", undefined);

      _defineProperty(this, "permitTypeOptions", undefined);

      _defineProperty(this, "customerAgencyList", undefined);

      _defineProperty(this, "availableAgencies", undefined);

      _defineProperty(this, "siteOptions", undefined);

      _defineProperty(this, "systemOptions", undefined);

      _defineProperty(this, "secondarySystemOptions", undefined);

      _defineProperty(this, "filteredTwoWayDeviceOptions", undefined);

      _initializerDefineProperty(this, "additionalClickActions", _descriptor10, this);

      _initializerDefineProperty(this, "siteOptionDeleteId", _descriptor11, this);

      _initializerDefineProperty(this, "systemOptionDeleteId", _descriptor12, this);

      _initializerDefineProperty(this, "customerContactsList", _descriptor13, this);

      _initializerDefineProperty(this, "pullPanelFormStarted", _descriptor14, this);

      _defineProperty(this, "pullPanel", false);

      _initializerDefineProperty(this, "unlinkContractFormStarted", _descriptor15, this);

      _initializerDefineProperty(this, "clickToSubmitFormStarted", _descriptor16, this);

      _initializerDefineProperty(this, "showOutOfServiceModal", _descriptor17, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor18, this);

      _initializerDefineProperty(this, "clickToSubmit", _descriptor19, this);

      _initializerDefineProperty(this, "unlinkEcontract", _descriptor20, this);

      _initializerDefineProperty(this, "placeAccountOutOfService", _descriptor21, this);

      _initializerDefineProperty(this, "pullPanelTask", _descriptor22, this);

      _initializerDefineProperty(this, "saveBrinksCustomer", _descriptor23, this);

      this.transitionEvent.on('refresh-customer-contacts', this, '_refreshEventCallback');
      this.CustomerIntegrations = new _customerIntegrations.default(...arguments);
      this.SiteRowList = new _rowList.default(...arguments);
      this.SystemRowList = new _rowList.default(...arguments);
      this.initDataTask.perform();
    }

    willDestroy() {
      this.transitionEvent.off('refresh-customer-contacts', this, '_refreshEventCallback');
    }

    _refreshEventCallback() {
      this.setupContactsList();
    }

    get phoneTypeList() {
      return this.emergencyContacts.phoneTypeList();
    }

    get clickToSubmitButton() {
      return [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.clickToSubmit.perform(buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary'
      }];
    }

    get unlinkEContractButton() {
      return [{
        text: this.intl.t('common.actions.unlink'),
        click: buttonPromise => {
          return this.unlinkEcontract.perform(buttonPromise);
        },
        classNamesString: 'st-btn st-btn-warn'
      }];
    }

    get pullPanelSubmitButton() {
      return [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.pullPanelTask.perform(buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary'
      }];
    }

    get outOfServiceButtons() {
      return [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.placeAccountOutOfService.perform(this.args.multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary'
      }];
    }

    setSecondarySystemOptions(option) {
      var secondarySystemOptions = [];

      if (option) {
        var crossRefs = this.systemTypeCrossRefs.filter(crossRefs => {
          return crossRefs.digital_system_type_id === option.id || crossRefs.cell_system_type_id === option.id;
        });
        crossRefs.forEach(crossRef => {
          var secondarySystemOption;

          if (crossRef.digital_system_type_id === option.id) {
            secondarySystemOption = _lodash.default.find(this.systemTypeOptions, {
              id: crossRef.cell_system_type_id
            });
          } else {
            secondarySystemOption = _lodash.default.find(this.systemTypeOptions, {
              id: crossRef.digital_system_type_id
            });
          }

          secondarySystemOptions.push(secondarySystemOption);
        });
        this.filteredTwoWayDeviceOptions = [];
        this.secondarySystemOptions = secondarySystemOptions;
      } else {
        this.secondarySystemOptions = [];
        Ember.set(this.args.multiStepForm.formCopiedModels.customer.model.shellingData, 'secondarySystemTypeId', null);
        this.setDeviceTypeOptions(null);
      }
    }

    setDeviceTypeOptions(option) {
      var newTwoWayOptions = [];
      var systemTypeId = this.args.multiStepForm.formCopiedModels.customer.model.shellingData.systemType;

      if (option) {
        var crossRefs = this.systemTypeCrossRefs.filter(crossRefs => {
          return crossRefs.digital_system_type_id === systemTypeId && crossRefs.cell_system_type_id === option.id || crossRefs.cell_system_type_id === systemTypeId && crossRefs.digital_system_type_id === option.id;
        });
        crossRefs.forEach(crossRef => {
          if (crossRef.two_way_device_id) {
            newTwoWayOptions.push(_lodash.default.find(this.twoWayDeviceOptions, {
              id: crossRef.two_way_device_id
            }));
          }
        });
        this.filteredTwoWayDeviceOptions = newTwoWayOptions;
      } else {
        this.filteredTwoWayDeviceOptions = [];
        Ember.set(this.args.multiStepForm.formCopiedModels.customer.model.shellingData, 'twoWayDeviceId', null);
      }
    }

    updateOption(option, index, usage) {
      var rowList = Ember.get(this, (0, _strings.ucwords)(usage) + 'RowList');

      var optionObject = _lodash.default.find(Ember.get(this, usage + 'Options'), {
        id: option.id
      });

      option.set('inputType', optionObject.type);
      option.set('dropdownOptions', optionObject.values);
      rowList.updateRow(option, index, false);

      if (!option.id) {
        rowList.listArray.removeAt(index);
      }
    }

    deleteOption(index, usage) {
      Ember.get(this, (0, _strings.ucwords)(usage) + 'RowList').listArray.removeAt(index);
      Ember.set(this, usage + 'OptionDeleteId', null);
    }

    renderButtons() {
      var buttons = [];

      if (this.args.formMode !== 'view') {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveBrinksCustomer.perform(this.args.multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary'
        });
      }

      this.args.multiStepForm.registerStepData('account', 'buttons', buttons);
    }

    startAddEdit(path) {
      this.router.transitionTo(this.router.currentRoute.parent.name + '.edit-create-customer-contact', path.id || path);
    }

    updatePhoneType(row, value) {
      if (value.length < 9 && value.length > 0) {
        Ember.setProperties(row, {
          usesPin: true,
          contact_type_id: null,
          relation_id: null,
          authority_type_id: null
        });
      }
    }

    filteredAgencyNames(agencyTypeId) {
      return this.availableAgencies.filter(agencyOption => {
        return agencyOption.agency_type_id === agencyTypeId;
      });
    }

    setupContactsList() {
      // Set up customer contacts
      var customerContacts = this.args.multiStepForm.formOriginalModels.customer.model.customerContacts;
      var formattedCustomerContactsList = [];
      customerContacts.forEach(contact => {
        var matchingPhoneTypeObj = contact.about === 'Mobile' ? this.phoneTypeOptions.find(option => option.id === 'CL') : this.phoneTypeOptions.find(option => option.name === contact.about);

        if (!contact.centralStationId) {
          formattedCustomerContactsList.push({
            priority: contact.sort,
            customer_contact_id: contact.id,
            name: contact.name,
            contact_type_id: contact.about === 'Pin' || contact.name.toUpperCase() === 'DISPATCH IMMEDIATELY' ? 'DURS' : null,
            relation_id: contact.about === 'Pin' || contact.name.toUpperCase() === 'DISPATCH IMMEDIATELY' ? 'OWN' : null,
            authority_type_id: contact.about === 'Pin' || contact.name.toUpperCase() === 'DISPATCH IMMEDIATELY' ? 'DURS' : null,
            contract_signer_flag: false,
            has_key_flag: false,
            evc: contact.enhancedVerification ? 'Yes' : 'No',
            cell_primary_1st_contact: false,
            usesPin: contact.about === 'Pin' || contact.name.toUpperCase() === 'DISPATCH IMMEDIATELY',
            phoneOrPin: contact.phone1,
            phone_type_id_1: matchingPhoneTypeObj ? matchingPhoneTypeObj.id : null
          });
        }
      });
      this.customerContactsList = formattedCustomerContactsList;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "langs", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "emergencyContacts", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "brinksEcontractEvents", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "transitionEvent", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "additionalClickActions", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "siteOptionDeleteId", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "systemOptionDeleteId", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "customerContactsList", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "pullPanelFormStarted", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "unlinkContractFormStarted", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "clickToSubmitFormStarted", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "showOutOfServiceModal", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "clickToSubmit", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "unlinkEcontract", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "placeAccountOutOfService", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "pullPanelTask", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "saveBrinksCustomer", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setSecondarySystemOptions", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "setSecondarySystemOptions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setDeviceTypeOptions", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "setDeviceTypeOptions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateOption", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "updateOption"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteOption", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "deleteOption"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "renderButtons", [_dec28], Object.getOwnPropertyDescriptor(_class.prototype, "renderButtons"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startAddEdit", [_dec29], Object.getOwnPropertyDescriptor(_class.prototype, "startAddEdit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatePhoneType", [_dec30], Object.getOwnPropertyDescriptor(_class.prototype, "updatePhoneType"), _class.prototype)), _class));
  _exports.default = CreateEditAccount;
});