define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/edit-create-credit-bureau/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency", "lodash"], function (_exports, _switchableFormStep, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    titleKey: 'pages.administration.integrations.creditBureaus.title',
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    confirmDeleteModalOpen: false,

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      var buttons = [];

      if (this.formMode !== 'view') {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveCreditBureau.perform(multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary credit-bureau-save-button'
        });
      }

      if (!this.isNewRecord && this.grants.getIndividualGrant('credit_bureaus.delete')) {
        buttons.push({
          text: this.intl.t('common.actions.delete'),
          click: () => {
            this.set('confirmDeleteModalOpen', true);
          },
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
        });
      }

      multiStepForm.registerStepData('step1', 'buttons', buttons);
    },

    formComponent: Ember.computed('multiStepForm.formCopiedModels.creditBureau.model.creditBureauType.name', function () {
      var type = this.multiStepForm.formCopiedModelsGetVal('creditBureau.model.creditBureauType.name');
      return type ? 'multi-step-form/forms/administration/integrations/edit-create-credit-bureau/' + Ember.String.dasherize(type) : null;
    }),
    hasComponent: Ember.computed('formComponent', function () {
      var component = this.formComponent;
      return component && Ember.getOwner(this).hasRegistration('component:' + component + '/component');
    }),
    initData: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = this.multiStepForm;
      yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);
      var config = this.multiStepForm.formCopiedModelsGetVal('creditBureau.model.creditBureauConfig');

      _lodash.default.set(this.multiStepForm.formCopiedModelsGetVal('creditBureau.model'), 'creditBureauConfig', config ? config : {});
    }).on('init'),
    saveCreditBureau: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var formattedObjects = [];
      var bureau = this.multiStepForm.formCopiedModelsGetVal('creditBureau.model');
      var bureauTypeId = bureau.get('creditBureauType.id');
      formattedObjects.pushObject(Ember.Object.create({
        modelName: 'creditBureau',
        model: {
          name: bureau.get('name'),
          creditBureauType: bureau.get('creditBureauType'),
          bureau: bureau.get('creditBureauConfig.fieldsBureau'),
          product: bureau.get('creditBureauConfig.fieldsProduct'),
          service: bureau.get('creditBureauConfig.serviceName')
        },
        extraValidationData: {
          noValidate: bureauTypeId === '2' ? ['creditBureau-bureau', 'creditBureau-product'] : ['3', '7', '10'].includes(bureauTypeId) ? ['creditBureau-service'] : ['creditBureau-bureau', 'creditBureau-product', 'creditBureau-service']
        }
      }));
      yield multiStepForm.validateList.perform(formattedObjects);

      if (this.isNewRecord) {
        var toCreateObjects = yield multiStepForm.validateCreateAll({
          notificationNamespace: 'modal',
          skipValidation: true
        });
        var theModel = this.store.createRecord('creditBureau', toCreateObjects.creditBureau.data);
        yield toCreateObjects.creditBureau.saveWithRollbackAndNotification(theModel);
        var hasConfigPermission = yield this.grants.getIndividualGrant('credit_bureau_configs.manage');

        if (hasConfigPermission) {
          try {
            var config = yield theModel.get('creditBureauConfig');
            var configFields = Object.keys(this.multiStepForm.formCopiedModelsGetVal('creditBureau.model.creditBureauConfig'));

            if (config && configFields) {
              configFields.forEach(field => {
                config.set(field, multiStepForm.formCopiedModelsGetVal("creditBureau.model.creditBureauConfig.".concat(field)));
              });
              yield config.save();
            }
          } catch (err) {
            this.stNotificationManager.addErrorMessages(err, {
              notificationNamespace: 'modal'
            });
            throw err;
          }
        }
      } else {
        yield multiStepForm.validateSyncSaveAll({
          notificationNamespace: 'modal',
          skipValidation: true
        });
      }

      buttonPromise.then(() => {
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        multiStepForm.formStartedSetVal(false);
      });
    }),
    deleteCreditBureauTask: (0, _emberConcurrency.task)(function* () {
      var bureauId = this.multiStepForm.formCopiedModelsGetVal('creditBureau.model.id');
      var creditBureauToDelete = yield this.store.findRecord('creditBureau', bureauId);
      creditBureauToDelete.destroyRecordWithRollbackTask.perform().then(() => {
        this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
        this.multiStepForm.onDelete();
        this.multiStepForm.formStartedSetVal(false);
      });
    })
  });

  _exports.default = _default;
});