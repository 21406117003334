define("securitytrax-ember-ui/models/calendar-event", ["exports", "securitytrax-ember-ui/models/base_models/calendar-event", "securitytrax-ember-ui/mixins/forms/calendar-event-reusable", "moment", "ember-concurrency"], function (_exports, _calendarEvent, _calendarEventReusable, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _calendarEvent.default.extend(_calendarEventReusable.default, {
    grants: Ember.inject.service(),
    stMulticallService: Ember.inject.service(),
    location: Ember.computed.alias('customer.citystate'),
    title: Ember.computed.alias('customer.flname'),
    recurringID: Ember.computed('recurringParentCalendarEvent', function () {
      return this.belongsTo('recurringParentCalendarEvent').id();
    }),
    parsedId: Ember.computed('id', function () {
      var parsedId = JSON.parse(this.id);
      return typeof parsedId === 'object' ? parsedId : {
        id: this.id
      };
    }),
    jsonParseId: Ember.computed('id', function () {
      var parsedId = JSON.parse(this.id);
      return parsedId;
    }),
    payeeDisplayName: Ember.computed('startTimeDisplay', 'fromXtoXDisplay', 'calendarUser', 'calendarEventType', function () {
      return this.get('calendarUser.flname') + '<br>' + this.get('calendarEventType.name') + ' | ' + (0, _moment.default)(this.startTime).format('MM/DD/YYYY') + ' ' + this.fromXtoXDisplay;
    }),
    durationDisplay: Ember.computed('duration', function () {
      var durationMoment = _moment.default.duration().add(this.duration, 'seconds');

      return "".concat(durationMoment.hours(), " H ").concat(durationMoment.minutes(), " M");
    }),
    fromXtoXDisplay: Ember.computed('duration', 'startTime', function () {
      // DREW TODO - maybe need to watch more
      var startTime = (0, _moment.default)(this.startTime);
      var endTime = (0, _moment.default)(this.calculatedEndtime);
      return "".concat(startTime.format('h:mm A'), " - ").concat(endTime.format('h:mm A'));
    }),
    calendarEventTypesDisplay: Ember.computed('calendarEventType.name', 'calendarEventDetailType.name', function () {
      return this.calendarEventTypesDisplayTask.perform();
    }),
    calendarEventTypesDisplayTask: (0, _emberConcurrency.task)(function* () {
      var calendarEventDetailTypeName = yield this.get('calendarEventDetailType.name');
      var calendarEventTypeName = yield this.get('calendarEventType.name');

      if (calendarEventDetailTypeName) {
        return "".concat(calendarEventTypeName, ": ").concat(calendarEventDetailTypeName);
      }

      return calendarEventTypeName;
    }),
    appointmentCompleted: Ember.computed(function () {
      return this.appointmentCompletedTask.perform();
    }),
    appointmentCompletedMC: Ember.computed(function () {
      return this.appointmentCompletedTask.perform(true);
    }),
    appointmentCompletedTask: (0, _emberConcurrency.task)(function* (isMultiCall) {
      var response = this.id ? yield this.getAppointmentStatusDataTask.unlinked().perform(isMultiCall) : null;
      return response ? response.check_in : false;
    }),
    appointmentMissed: Ember.computed(function () {
      return this.appointmentMissedTask.perform();
    }),
    appointmentMissedMC: Ember.computed(function () {
      return this.appointmentMissedTask.perform(true);
    }),
    appointmentMissedTask: (0, _emberConcurrency.task)(function* (isMultiCall) {
      var response = this.id ? yield this.getAppointmentStatusDataTask.unlinked().perform(isMultiCall) : null;
      return response ? !response.check_in && (0, _moment.default)() > this.calculatedEndtime : false;
    }),
    appointmentStatusData: Ember.computed(function () {
      return this.getAppointmentStatusDataTask.unlinked().perform();
    }),
    appointmentStatusDataMC: Ember.computed(function () {
      return this.getAppointmentStatusDataTask.unlinked().perform(true);
    }),
    elapsedTimeSinceCheckin: Ember.computed('appointmentStatusData', function () {
      return this.elapsedTimeSinceCheckinTask.perform();
    }),
    elapsedTimeSinceCheckinTask: (0, _emberConcurrency.task)(function* () {
      var hashedData = yield (0, _emberConcurrency.hash)({
        response: this.id ? yield this.getAppointmentStatusDataTask.unlinked().perform() : null,
        customer: this.customer
      });
      var normalizedTime = hashedData.customer && hashedData.customer.get('timezone') ? _moment.default.tz(hashedData.response.check_in_datetime, hashedData.customer.get('timezone')) : _moment.default.unix(hashedData.response.check_in);

      if (hashedData.response.check_in && !hashedData.response.check_out) {
        var duration = _moment.default.duration((0, _moment.default)().diff(normalizedTime));

        var hours = parseInt(duration.asHours());
        var minutes = parseInt(duration.asMinutes()) % 60;
        return "".concat(hours, ":").concat(minutes.toString().padStart(2, '0'));
      }
    }),
    actualDuration: Ember.computed(function () {
      return this.getActualDurationTask.perform();
    }),
    getActualDurationTask: (0, _emberConcurrency.task)(function* () {
      var response = this.id ? yield this.appointmentStatusData : null;

      if (response.duration) {
        var hours = parseInt(parseInt(response.duration) / 3600);
        var minutes = parseInt(parseInt(response.duration) % 3600 / 60);
        return "".concat(hours, " hour(s) ").concat(minutes, " minute(s)");
      }
    }),
    getAppointmentStatusDataTask: (0, _emberConcurrency.task)(function* () {
      // if(isMultiCall){
      //     const onSiteResultMulti = yield this.stMulticallService.grabMulticallDataByKeyAndSubkey(isMultiCall,`${this.parsedId.calendar_category}_${this.parsedId.id}_event_on_site_info_subkey`,'finishedRequest');
      //     console.log('multi', onSiteResultMulti);
      //     if(onSiteResultMulti){
      //         return onSiteResultMulti.actualResponse.responsePayload.data;
      //     }
      // } else { 
      try {
        var appointmentStatusData = this.grants.getIndividualGrant('calendar_events.view_on_site_info') ? yield this.invokeAction('calendar_event_on_site_info', {
          verb: 'POST',
          data: {
            data: {}
          }
        }) : null;
        return appointmentStatusData.responsePayload.data;
      } catch (error) {
        (false && Ember.warn('getAppointmentStatusDataTask ' + error, {
          id: 'calendarEvent.getAppointmentStatusDataTask'
        }));
        return {
          check_in: null,
          check_out: null,
          en_route: null
        };
      } // }

    }),
    appointmentDropdownFormat: Ember.computed('calendarUser', function () {
      return "".concat((0, _moment.default)(this.startTime).format('MM/DD/YYYY @ h:mm A'), " | ").concat(this.calendarEventType.get('name'), " by ").concat(this.calendarUser.get('name'));
    }),
    appointmentDropdownFormatTask: (0, _emberConcurrency.task)(function* () {
      var calendarUser = yield this.calendarUserComputed;
      return "".concat((0, _moment.default)(this.startTime).format('MM/DD/YYYY @ h:mm A'), " | ").concat(this.calendarEventType.get('name'), " by ").concat(calendarUser.lfname);
    }),
    calendarUserComputed: Ember.computed('calendarUser', function () {
      return this.getCalendarUserComputedTask.perform();
    }),
    getCalendarUserComputedTask: (0, _emberConcurrency.task)(function* () {
      var calendarUser = yield this.calendarUser;
      return calendarUser || (this.responseMeta.assigned_calendar_user ? Ember.Object.create({
        id: this.responseMeta.assigned_calendar_user.id,
        email: this.responseMeta.assigned_calendar_user.email,
        fname: this.responseMeta.assigned_calendar_user.fname,
        lname: this.responseMeta.assigned_calendar_user.lname,
        name: "".concat(this.responseMeta.assigned_calendar_user.lname, ", ").concat(this.responseMeta.assigned_calendar_user.fname),
        lfname: "".concat(this.responseMeta.assigned_calendar_user.lname, ", ").concat(this.responseMeta.assigned_calendar_user.fname),
        flname: "".concat(this.responseMeta.assigned_calendar_user.fname, " ").concat(this.responseMeta.assigned_calendar_user.lname),
        avatar: this.responseMeta.assigned_calendar_user.profile_image_url
      }) : null);
    })
  });

  _exports.default = _default;
});