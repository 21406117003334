define("securitytrax-ember-ui/components/multi-step-form/forms/system/telus/general/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms/system/telus/base", "securitytrax-ember-ui/mixed-in-classes/customer-integrations"], function (_exports, _emberConcurrency, _base, _customerIntegrations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _dec40, _dec41, _dec42, _dec43, _dec44, _dec45, _dec46, _dec47, _dec48, _dec49, _dec50, _dec51, _dec52, _dec53, _dec54, _dec55, _dec56, _dec57, _dec58, _dec59, _dec60, _dec61, _dec62, _dec63, _dec64, _dec65, _dec66, _dec67, _dec68, _dec69, _dec70, _dec71, _dec72, _dec73, _dec74, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _descriptor38, _descriptor39, _descriptor40, _descriptor41, _descriptor42, _descriptor43, _descriptor44, _descriptor45, _descriptor46, _descriptor47, _descriptor48, _descriptor49, _descriptor50, _descriptor51, _descriptor52, _descriptor53, _descriptor54, _descriptor55, _descriptor56, _descriptor57, _descriptor58, _descriptor59, _descriptor60, _descriptor61, _descriptor62, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var General = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._tracked, _dec21 = Ember._tracked, _dec22 = Ember._tracked, _dec23 = Ember._tracked, _dec24 = Ember._tracked, _dec25 = Ember._tracked, _dec26 = Ember._tracked, _dec27 = Ember._tracked, _dec28 = Ember._tracked, _dec29 = Ember._tracked, _dec30 = Ember._tracked, _dec31 = Ember._tracked, _dec32 = Ember._tracked, _dec33 = Ember._tracked, _dec34 = Ember._tracked, _dec35 = Ember._tracked, _dec36 = Ember._tracked, _dec37 = Ember._tracked, _dec38 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.getLoadableListsTask, 'isIdle', true);
    var hashedData = yield (0, _emberConcurrency.hash)({
      allowSelectingStatuses: this.centralStationService.postData({
        model: this.args.monitoringCompany,
        rawResponse: false,
        method: 'adt_canada__getCreditCheckStatusesAllowingSubjectSelection'
      }),
      acceptanceRules: this.centralStationService.postData({
        model: this.args.monitoringCompany,
        rawResponse: false,
        method: 'adt_canada__getCreditVerificationAcceptanceRulesIndicatingAcceptance'
      }),
      dealerType: this.centralStationService.postData({
        model: this.args.customer,
        rawResponse: false,
        method: 'adt_canada__getDealerType'
      }),
      subjects: this.subjectsTask.perform(),
      inquiryInfo: this.inquiryInfoTask.perform(),
      billingInfo: this.billingInfoTask.perform(),
      patrolCode: this.getPatrolCodeTask.perform(),
      customerEquipment: this.args.customer.get('customerEquipment')
    });
    this.customerContactsList = this.args.installationInfo.contact_collection.sortBy('person.sequence_number');
    this.allowSelectingStatuses = hashedData.allowSelectingStatuses && hashedData.allowSelectingStatuses.credit_check_statuses ? hashedData.allowSelectingStatuses.credit_check_statuses.map(status => {
      return status.id;
    }) : [];
    this.acceptanceRules = hashedData.acceptanceRules.credit_verification_acceptance_rules;
    this.dealerType = hashedData.dealerType.dealer_type;
    this.subjects = hashedData.subjects;
    this.subjectIds = [];
    this.customerEquipment = hashedData.customerEquipment;
    hashedData.subjects.forEach(subject => {
      this.subjectIds[subject.subject_id] = subject.subject_first_name + ' ' + subject.subject_last_name;
    });
    var selectedSubject = this.subjects.filter(subject => {
      return subject.is_subject_selected_as_qualified;
    })[0];
    this.updateProceedButtons(this.args.customerState.customer_state.code === 'CreditCheckRequired' ? selectedSubject : undefined);
    this.showCidButton = this.args.installationInfo.alarm_systems[0].dealer_panel.id && this.args.installationInfo.alarm_systems[0].dealer_panel.id !== '00000000-0000-0000-0000-000000000000';
    this.panelOptions = this.loadableLists.DealerPanel.uniqBy('name').map(panel => {
      var items = this.loadableLists.DealerPanel.filterBy('name', panel.name);
      var communicationFormats = [];
      var communicationProviders = [];
      var twoWayOptions = [];
      var lineTypes = [];
      items.forEach(item => {
        communicationFormats.push(item.communication_format.id);
        communicationProviders.push(item.communication_provider.id);
        lineTypes.push(item.line_type.id);

        if (!twoWayOptions.includes(item.is_two_way)) {
          twoWayOptions.push(item.is_two_way);
        }
      });
      var group = Ember.Object.create({
        name: panel.name,
        items,
        communicationFormats: this.loadableLists.CommunicationFormat.filter(format => {
          return communicationFormats.includes(format.id);
        }),
        communicationProviders: this.loadableLists.CommunicationTypeProvider.filter(provider => {
          return communicationProviders.includes(provider.id);
        }),
        twoWayOptions,
        lineTypes: this.loadableLists.LineType.filter(type => {
          return lineTypes.includes(type.id);
        })
      });
      this.args.installationInfo.alarm_systems.forEach(system => {
        if (system.dealer_panel.name === panel.name) {
          system.dealer_panel_group = group;
        }
      });
      return group;
    });
  }), _dec39 = (0, _emberConcurrency.task)(function* (ignoreCache = false) {
    try {
      var inquiryInfo = yield this.centralStationService.postData({
        model: this.args.customer,
        rawResponse: false,
        method: 'adt_canada__getInquiry',
        payload: {
          ignore_cache: ignoreCache
        }
      });
      this.inquiryInfo = inquiryInfo.inquiry;
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
    }
  }), _dec40 = (0, _emberConcurrency.task)(function* (ignoreCache = false) {
    try {
      var installations = yield this.centralStationService.postData({
        model: this.args.customer,
        rawResponse: false,
        method: 'adt_canada__getInstallations',
        payload: {
          ignore_cache: ignoreCache
        }
      });
      this.args.updateInstallInfo(installations.installations[0]);
      this.customerContactsList = this.args.installationInfo.contact_collection.sortBy('person.sequence_number');
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), _dec41 = (0, _emberConcurrency.task)(function* (ignoreCache = false) {
    try {
      var billingInfo = yield this.centralStationService.postData({
        model: this.args.customer,
        rawResponse: false,
        method: 'adt_canada__getBillingAccount',
        payload: {
          ignore_cache: ignoreCache
        }
      });
      this.billingInfo = billingInfo.billing_account;
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
    }
  }), _dec42 = (0, _emberConcurrency.task)(function* (ignoreCache = false) {
    try {
      var patrolCode = yield this.centralStationService.postData({
        model: this.args.customer,
        method: 'adt_canada__getPatrolCode',
        rawResponse: false,
        payload: {
          subscriber_type: this.args.customer.accountType,
          ignore_cache: ignoreCache
        }
      });
      this.patrolCode = patrolCode.patrol_code;
      var feeIds = this.loadableLists && this.loadableLists.CycleFee ? this.loadableLists.CycleFee.map(fee => {
        return fee.id;
      }) : [];
      this.guardService = feeIds.includes(this.patrolCode.id);
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
    }
  }), _dec43 = (0, _emberConcurrency.task)(function* (row) {
    try {
      yield this.centralStationService.postData({
        model: this.args.customer,
        rawResponse: false,
        method: 'adt_canada__deleteCreditVerificationSubject',
        payload: {
          subject_id: row.subject_id
        }
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
    }

    this.subjectDeleteId = undefined;

    if (row.is_subject_selected_as_qualified) {
      this.updateProceedButtons();
    }

    this.refreshSubjectsTask.perform();
  }), _dec44 = (0, _emberConcurrency.task)(function* () {
    var subjectIds = this.subjects.filter(subject => {
      return subject.checked;
    });

    try {
      yield this.centralStationService.postData({
        model: this.args.customer,
        rawResponse: false,
        method: 'adt_canada__getCreditVerificationResultsForSubjectList',
        payload: {
          subject_ids: subjectIds
        }
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
    }

    this.refreshSubjectsTask.perform();
  }), _dec45 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    try {
      yield this.centralStationService.postData({
        model: this.args.customer,
        rawResponse: false,
        method: 'adt_canada__disconnectWholesaleCustomer',
        payload: {
          oos_cat_id: null,
          oos_start_dat: null
        }
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
    }

    buttonPromise.then(() => {
      //Needed to refresh the account like unlinking or no?
      this.store.findRecord('customerSystemInformation', this.args.customer.get('id')).then(systemInfo => {
        systemInfo.reload();
      });
    });
  }), _dec46 = (0, _emberConcurrency.task)(function* () {
    try {
      yield this.centralStationService.postData({
        model: this.args.customer,
        rawResponse: false,
        method: 'adt_canada__activateCustomer'
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
    }
  }), _dec47 = (0, _emberConcurrency.task)(function* () {
    var subject = this.subjects.filter(subject => {
      return subject.is_subject_selected_as_qualified;
    })[0];

    try {
      yield this.centralStationService.postData({
        model: this.args.customer,
        rawResponse: false,
        method: 'adt_canada__useSelectedSubjectAsCustomer',
        payload: {
          subject_id: subject.subject_id,
          authorization_number: subject.authorization_number,
          dealer_type_id: this.thirdParty ? '4CFCDA21-C50B-4EF9-8ECA-BA5131EE80C9' : '028CA4F7-7D95-4A08-9BEC-6C5A4C35DB03'
        }
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'proceedModal'
      });
      throw err;
    }

    this.showProceedAuthorized = false;
    this.showProceedThirdParty = false;
    yield this.args.transitionToManage.perform();
    this.SwitchableFormStep.hiddenProgressBarSteps = ['testMode', 'alarmLogs'];
  }), _dec48 = (0, _emberConcurrency.task)(function* (subject) {
    this.updateProceedButtons(subject);
    this.subjects.forEach(subject => {
      Ember.set(subject, 'selected', false);
      Ember.set(subject, 'is_subject_selected_as_qualified', false);
    });
    Ember.set(subject, 'selected', !subject.selected);
    Ember.set(subject, 'is_subject_selected_as_qualified', subject.selected);

    try {
      yield this.centralStationService.postData({
        model: this.args.customer,
        rawResponse: false,
        method: 'adt_canada__selectQualifiedSubjectForCustomer',
        payload: {
          subject_id: subject.subject_id
        }
      });
    } catch (err) {
      this.updateProceedButtons(subject);
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
    }
  }), _dec49 = (0, _emberConcurrency.task)(function* () {
    try {
      yield this.centralStationService.postData({
        model: this.args.customer,
        rawResponse: false,
        method: this.guardService ? 'adt_canada__addCycleFeeToContract' : 'adt_canada__removeCycleFeeFromContract',
        payload: {
          cycle_fee: this.patrolCode
        }
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
    }
  }), _dec50 = (0, _emberConcurrency.task)(function* () {
    try {
      yield this.centralStationService.postData({
        model: this.args.customer,
        rawResponse: false,
        method: 'adt_canada__updateInquiry',
        payload: {
          'inquiry': {
            'inquiry_person': {
              'name': this.inquiryInfo.inquiry_person.name,
              'sin': this.inquiryInfo.inquiry_person.sin
            },
            'phone_1': {
              'phone_num': this.inquiryInfo.phone_1.phone_num,
              'phone_ext': ''
            },
            'phone_2': {
              'phone_num': this.inquiryInfo.phone_2.phone_num,
              'phone_ext': ''
            },
            'address': {
              'address_1': this.inquiryInfo.address.address_1,
              'address_2': this.inquiryInfo.address.address_2,
              'city': this.inquiryInfo.address.city,
              'province': {
                'id': this.inquiryInfo.address.province.id || this.inquiryInfo.address.province
              },
              'postal_code': this.inquiryInfo.address.postal_code,
              'cross_street': this.inquiryInfo.address.cross_street,
              'country': {
                'id': this.inquiryInfo.address.country.id || this.inquiryInfo.address.country
              }
            },
            'fax': {
              'fax': this.inquiryInfo.fax.fax
            },
            'email': {
              'email_address': this.inquiryInfo.email.email_address
            },
            'language': {
              'id': this.inquiryInfo.language.id || this.inquiryInfo.language
            },
            'credit_result': this.inquiryInfo.credit_result
          }
        }
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), _dec51 = (0, _emberConcurrency.task)(function* () {
    yield this.installationsTask.perform(true);
  }), _dec52 = (0, _emberConcurrency.task)(function* () {
    try {
      yield this.centralStationService.postData({
        model: this.args.customer,
        rawResponse: false,
        method: 'adt_canada__updateInstallation',
        payload: {
          'installation': {
            'id': this.args.installationInfo.id,
            'fax': {
              'fax': this.args.installationInfo.fax.fax
            },
            'familiarization_period': {
              'id': this.args.installationInfo.familiarization_period.id || this.args.installationInfo.familiarization_period
            },
            'pic_number_restricted': this.args.installationInfo.pic_number_restricted
          }
        }
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), _dec53 = (0, _emberConcurrency.task)(function* () {
    try {
      yield this.centralStationService.postData({
        model: this.args.customer,
        rawResponse: false,
        method: 'adt_canada__updateBillingAccount',
        payload: {
          'billing_account': {
            'name': this.billingInfo.name,
            'phone_1': {
              'phone_num': this.billingInfo.phone_1.phone_num,
              'phone_ext': ''
            },
            'phone_2': {
              'phone_num': this.billingInfo.phone_2.phone_num,
              'phone_ext': ''
            },
            'fax': {
              'fax': this.billingInfo.fax.fax
            },
            'language': {
              'id': this.billingInfo.language.id || this.billingInfo.language
            }
          }
        }
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), _dec54 = (0, _emberConcurrency.task)(function* () {
    try {
      yield (0, _emberConcurrency.hash)({
        patrolCode: this.getPatrolCodeTask.perform(true),
        customerState: this.centralStationService.postData({
          model: this.args.customer,
          rawResponse: false,
          method: 'adt_canada__getCustomerState',
          ignore_cache: true
        })
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), _dec55 = (0, _emberConcurrency.task)(function* () {
    yield this.installationsTask.perform(true);
  }), _dec56 = (0, _emberConcurrency.task)(function* (system) {
    if (!system.dealer_panel.id) {
      var subscriberTypeId = this.args.customer.accountType === 'Residential' ? 'EAB53684-99A9-4C12-A208-71981FEA9BC1' : '46D7DA2D-A59A-48F5-A7A7-06DD569C6372';
      system.dealer_panel = system.dealer_panel_group.items.filter(panel => {
        return panel.communication_format.id === system.dealer_panel.communication_format && panel.communication_provider.id === system.dealer_panel.communication_provider && panel.line_type.id === system.dealer_panel.line_type && panel.is_two_way === system.dealer_panel.is_two_way && panel.dealer_type.id === this.dealerType.id && panel.subscriber_type.id === subscriberTypeId;
      })[0];
    }

    try {
      yield this.centralStationService.postData({
        model: this.args.customer,
        rawResponse: false,
        method: 'adt_canada__updateAlarmSystems',
        payload: {
          'alarm_systems': [{
            'alarm_system': {
              'id': system.id,
              'dealer_panel': {
                'id': system.dealer_panel.id || system.dealer_panel
              }
            }
          }]
        }
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }

    if (system.dealer_panel.id && system.dealer_panel.id !== '00000000-0000-0000-0000-000000000000') {
      this.showCidButton = true;
    }
  }), _dec57 = (0, _emberConcurrency.task)(function* (system, buttonPromise) {
    try {
      yield this.centralStationService.postData({
        model: this.args.customer,
        rawResponse: false,
        method: 'adt_canada__generateCid',
        payload: {
          'alarm_system': {
            'id': system.id
          }
        }
      });

      try {
        buttonPromise.then(() => {
          //this.args.updateCustomerState(customerState) // Get the new state and set it so activate buttons shows
          this.SwitchableFormStep.hiddenProgressBarSteps = [];
          this.installationsTask.perform(true);
          this.refreshCustomerInformationTask.perform();
          this.refreshSystemsTask.perform();
        });
      } catch (buttonPromiseErr) {// Do nothing as parent tasks handle the notice.
      }
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), _dec58 = (0, _emberConcurrency.task)(function* () {
    yield this.installationsTask.perform(true);
  }), _dec59 = (0, _emberConcurrency.task)(function* (system) {
    try {
      var zones = [];
      system.zones.forEach(zone => {
        zones.push({
          number: zone.number,
          description: zone.description,
          zone_type: {
            id: zone.zone_type.id || zone.zone_type
          },
          _former_number: zone.number,
          _former_description: zone.description
        });
      });
      yield this.centralStationService.postData({
        model: this.args.customer,
        rawResponse: false,
        method: 'adt_canada__updateZones',
        payload: {
          'alarm_system': {
            'id': system.id,
            'zones': zones
          }
        }
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), _dec60 = (0, _emberConcurrency.task)(function* () {
    yield this.installationsTask.perform(true);
  }), _dec61 = (0, _emberConcurrency.task)(function* () {
    try {
      var contacts = [];
      this.customerContactsList.forEach(contact => {
        contacts.push({
          id: contact.id,
          authority_level: {
            id: contact.authority_level.id || contact.authority_level
          },
          code_word: contact.code_word,
          person: contact.person
        });
      });
      yield this.centralStationService.postData({
        model: this.args.customer,
        rawResponse: false,
        method: 'adt_canada__updateCallListItems',
        payload: {
          'call_list_items': contacts
        }
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), _dec62 = (0, _emberConcurrency.task)(function* (buttonAnimationPromise) {
    var customerContacts = this.args.installationInfo.contact_collection.sortBy('person.sequence_number');
    var savedContacts = [];
    customerContacts.forEach((item, index) => {
      Ember.set(item, 'person.sequence_number', index + 1);
      savedContacts.push(item.save());
    });
    yield all(savedContacts);
    buttonAnimationPromise.then(() => {
      this.sortingMode = false;
    });
    return true;
  }), _dec63 = Ember._action, _dec64 = Ember._action, _dec65 = Ember._action, _dec66 = Ember._action, _dec67 = Ember._action, _dec68 = Ember._action, _dec69 = Ember._action, _dec70 = Ember._action, _dec71 = Ember._action, _dec72 = Ember._action, _dec73 = Ember._action, _dec74 = Ember._action, (_class = (_temp = class General extends _base.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "usStates", _descriptor, this);

      _initializerDefineProperty(this, "langs", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "centralStationService", _descriptor4, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor5, this);

      _initializerDefineProperty(this, "grants", _descriptor6, this);

      _initializerDefineProperty(this, "store", _descriptor7, this);

      _initializerDefineProperty(this, "subjectModel", _descriptor8, this);

      _initializerDefineProperty(this, "subjectFormStarted", _descriptor9, this);

      _initializerDefineProperty(this, "subjectFormMode", _descriptor10, this);

      _initializerDefineProperty(this, "subjectsChecked", _descriptor11, this);

      _initializerDefineProperty(this, "subjectDeleteId", _descriptor12, this);

      _initializerDefineProperty(this, "allowSelectingStatuses", _descriptor13, this);

      _initializerDefineProperty(this, "acceptanceRules", _descriptor14, this);

      _initializerDefineProperty(this, "showProceedAuthorized", _descriptor15, this);

      _initializerDefineProperty(this, "showProceedThirdParty", _descriptor16, this);

      _initializerDefineProperty(this, "dealerType", _descriptor17, this);

      _initializerDefineProperty(this, "proceedModalOpen", _descriptor18, this);

      _initializerDefineProperty(this, "showUnlinkConfirmModal", _descriptor19, this);

      _initializerDefineProperty(this, "thirdParty", _descriptor20, this);

      _initializerDefineProperty(this, "patrolCode", _descriptor21, this);

      _initializerDefineProperty(this, "guardService", _descriptor22, this);

      _initializerDefineProperty(this, "inquiryInfo", _descriptor23, this);

      _initializerDefineProperty(this, "billingInfo", _descriptor24, this);

      _initializerDefineProperty(this, "subjectIds", _descriptor25, this);

      _initializerDefineProperty(this, "showCidButton", _descriptor26, this);

      _initializerDefineProperty(this, "panelOptions", _descriptor27, this);

      _initializerDefineProperty(this, "customerEquipmentModel", _descriptor28, this);

      _initializerDefineProperty(this, "customerEquipmentFormStarted", _descriptor29, this);

      _initializerDefineProperty(this, "customerEquipmentFormMode", _descriptor30, this);

      _initializerDefineProperty(this, "customerEquipmentFirstStep", _descriptor31, this);

      _initializerDefineProperty(this, "customerEquipment", _descriptor32, this);

      _initializerDefineProperty(this, "customerContactModel", _descriptor33, this);

      _initializerDefineProperty(this, "customerContactFormStarted", _descriptor34, this);

      _initializerDefineProperty(this, "customerContactFormMode", _descriptor35, this);

      _initializerDefineProperty(this, "customerContactsList", _descriptor36, this);

      _initializerDefineProperty(this, "sortingMode", _descriptor37, this);

      _initializerDefineProperty(this, "initFormData", _descriptor38, this);

      _initializerDefineProperty(this, "inquiryInfoTask", _descriptor39, this);

      _initializerDefineProperty(this, "installationsTask", _descriptor40, this);

      _initializerDefineProperty(this, "billingInfoTask", _descriptor41, this);

      _initializerDefineProperty(this, "getPatrolCodeTask", _descriptor42, this);

      _initializerDefineProperty(this, "deleteSubjectTask", _descriptor43, this);

      _initializerDefineProperty(this, "verifyCreditTask", _descriptor44, this);

      _initializerDefineProperty(this, "disconnectAccountTask", _descriptor45, this);

      _initializerDefineProperty(this, "activateAccountTask", _descriptor46, this);

      _initializerDefineProperty(this, "proceedWithSubjectTask", _descriptor47, this);

      _initializerDefineProperty(this, "selectSubjectTask", _descriptor48, this);

      _initializerDefineProperty(this, "updateGuardServiceTask", _descriptor49, this);

      _initializerDefineProperty(this, "updateInquiryInformationTask", _descriptor50, this);

      _initializerDefineProperty(this, "refreshInstallationsTask", _descriptor51, this);

      _initializerDefineProperty(this, "updateInstallationTask", _descriptor52, this);

      _initializerDefineProperty(this, "updateBillingInformationTask", _descriptor53, this);

      _initializerDefineProperty(this, "refreshCustomerInformationTask", _descriptor54, this);

      _initializerDefineProperty(this, "refreshSystemsTask", _descriptor55, this);

      _initializerDefineProperty(this, "updateAlarmSystemsTask", _descriptor56, this);

      _initializerDefineProperty(this, "generateCidTask", _descriptor57, this);

      _initializerDefineProperty(this, "refreshZonesTask", _descriptor58, this);

      _initializerDefineProperty(this, "updateZonesTask", _descriptor59, this);

      _initializerDefineProperty(this, "refreshContactsTask", _descriptor60, this);

      _initializerDefineProperty(this, "updateContactsTask", _descriptor61, this);

      _initializerDefineProperty(this, "saveSortingChangesTask", _descriptor62, this);

      this.CustomerIntegrations = new _customerIntegrations.default(...arguments);
      this.initFormData.perform();
    }

    get subjectFormOriginalModels() {
      return Ember.Object.create({});
    }

    get subjectForm() {
      var params = {
        customerName: this.args.customer.flname
      };
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.intl.t("pages.customers.system.telus.general.subject.title_" + (this.subjectFormMode === 'create' ? 'create' : 'edit'), params),
          stepComponentPath: 'multi-step-form/forms/system/telus/general/edit-subject'
        })
      });
    }

    get customerContactFormOriginalModels() {
      return Ember.Object.create({});
    }

    get customerContactSortingClass() {
      if (this.sortingMode === true) {
        return 'actionable-row pointer';
      } else {
        return '';
      }
    }

    get customerContactFormSteps() {
      var params = {
        index: this.customerContactModel ? this.customerContactModel.person.sequence_number : this.customerContactsList.length + 1
      };
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.intl.t("pages.customers.system.telus.general.contact.title_".concat(this.customerContactFormMode), params),
          stepComponentPath: 'multi-step-form/forms/system/telus/general/edit-contact'
        })
      });
    }

    get customerEquipmentFormSteps() {
      var intl = this.intl;
      return Ember.Object.create({
        addEquipment: Ember.Object.create({
          stepNameKey: 'addEquipment',
          title: intl.t('pages.customers.equipment.title'),
          stepComponentPath: 'multi-step-form/forms/customer-equipment/add-equipment'
        }),
        details: Ember.Object.create({
          stepNameKey: 'details',
          title: intl.t('pages.customers.equipment.title'),
          stepComponentPath: 'multi-step-form/forms/customer-equipment/details'
        })
      });
    }

    get customerEquipmentFormOriginalModels() {
      return Ember.Object.create({
        customerEquipment: {
          model: this.customerEquipmentModel,
          copyRelationMap: {
            equipment: {
              doCopy: this.grants.getIndividualGrant('customer_equipment.view'),
              doValidate: false,
              doSave: false
            },
            equipmentStatus: {
              doValidate: false,
              doSave: false
            },
            equipmentWireType: {
              doValidate: false,
              doSave: false
            },
            installCalendarEvent: {
              doValidate: false,
              doSave: false
            },
            uninstallCalendarEvent: {
              doValidate: false,
              doSave: false
            },
            installInventoryTechUser: {
              doCopy: true,
              doValidate: false,
              doSave: false
            },
            uninstallInventoryTechUser: {
              doCopy: true,
              doValidate: false,
              doSave: false
            },
            user: {
              doCopy: true,
              doValidate: false,
              doSave: false
            }
          }
        }
      });
    }

    checkSubject(row) {
      Ember.set(row, 'checked', !row.checked);
      this.subjectsChecked = this.subjects.filter(subject => {
        subject.checked === true;
      }).length > 0 || row.checked;
    }

    refreshSubjects(checkFirst) {
      this.refreshSubjectsTask.perform(checkFirst);
    }

    updateProceedButtons(row = {}) {
      switch (row.credit_check_status) {
        case 'AcceptedForAuthorizedAnd3rdPartyDealer':
        case 'Authorized':
          this.showProceedAuthorized = true;
          this.showProceedThirdParty = true;
          break;

        case 'AcceptedForAuthorizedDealer':
          this.showProceedAuthorized = true;
          this.showProceedThirdParty = false;
          break;

        case 'DeniedForAuthorizedDealer':
          this.showProceedAuthorized = false;
          this.showProceedThirdParty = true;
          break;

        case 'AuthorizationRequired':
          if (row.authorization_number !== '') {
            this.showProceedAuthorized = true;
            this.showProceedThirdParty = true;
          } else {
            this.showProceedAuthorized = false;
            this.showProceedThirdParty = false;
          }

          break;

        default:
          this.showProceedAuthorized = false;
          this.showProceedThirdParty = false;
          break;
      }
    }

    updatePanel(system, panelGroup) {
      system.dealer_panel_group = panelGroup;

      if (panelGroup) {
        Ember.set(system, 'dealer_panel', {
          communication_format: panelGroup.communicationFormats.length === 1 ? panelGroup.communicationFormats[0].id : undefined,
          communication_provider: panelGroup.communicationProviders.length === 1 ? panelGroup.communicationProviders[0].id : undefined,
          line_type: panelGroup.lineTypes.length === 1 ? panelGroup.lineTypes[0].id : undefined,
          is_two_way: panelGroup.twoWayOptions.length === 1 ? panelGroup.twoWayOptions[0].id : false
        });
      }
    }

    editZone(row) {
      var stCustomerEquipment = this.customerEquipment.find(ce => {
        return ce.get('zone') === row.description && ce.get('zoneNumber') === row.number && ce.get('equipmentStatus.id') === '2';
      });

      if (stCustomerEquipment) {
        this.customerEquipmentModel = stCustomerEquipment;
        this.customerEquipmentFirstStep = 'details';
        this.customerEquipmentFormMode = this.grants.getIndividualGrant('customer_equipment.modify') ? 'edit' : 'view';
        this.customerEquipmentFormStarted = true;
      } else {
        this.stNotificationManager.addWarning(this.intl.t('pages.customers.system.telus.general.no_corresponding_st_equipment_warning'), 'modal');
      }
    }

    updateSystemZones() {
      this.refreshZonesTask.perform();
    }

    updateContacts() {
      this.args.customer.get('customerContacts').reload();
      this.refreshContactsTask.perform();
    }

    sortEndAction() {
      var customerContacts = this.customerContactsList;
      customerContacts.forEach((item, index) => {
        Ember.set(item, 'person.sequence_number', index + 1);
      });
    }

    initSortMode() {
      var customerContacts = this.args.installationInfo.contact_collection.sortBy('person.sequence_number');
      customerContacts.forEach(item => {
        Ember.set(item, 'originalIndex', item.get('person.sequence_number'));
        Ember.set(item, 'expanded', false);
      });
      this.sortingMode = true;
    }

    cancelSort() {
      var customerContacts = this.args.installationInfo.contact_collection.sortBy('originalIndex');
      customerContacts.forEach(item => {
        Ember.set(item, 'person.sequence_number', item.get('originalIndex'));
      });
      this.customerContactsList = customerContacts;
      this.sortingMode = false;
    }

    sortingComplete() {}

    saveSortingChanges(buttonAnimationPromise) {
      return this.saveSortingChangesTask.perform(buttonAnimationPromise);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "usStates", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "langs", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "subjectModel", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "subjectFormStarted", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "subjectFormMode", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'create';
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "subjectsChecked", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "subjectDeleteId", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "allowSelectingStatuses", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "acceptanceRules", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "showProceedAuthorized", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "showProceedThirdParty", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "dealerType", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "proceedModalOpen", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "showUnlinkConfirmModal", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "thirdParty", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "patrolCode", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "guardService", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "inquiryInfo", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "billingInfo", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "subjectIds", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "showCidButton", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "panelOptions", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "customerEquipmentModel", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "customerEquipmentFormStarted", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "customerEquipmentFormMode", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'create';
    }
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "customerEquipmentFirstStep", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'details';
    }
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "customerEquipment", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "customerContactModel", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "customerContactFormStarted", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "customerContactFormMode", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'create';
    }
  }), _descriptor36 = _applyDecoratedDescriptor(_class.prototype, "customerContactsList", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor37 = _applyDecoratedDescriptor(_class.prototype, "sortingMode", [_dec37], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor38 = _applyDecoratedDescriptor(_class.prototype, "initFormData", [_dec38], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor39 = _applyDecoratedDescriptor(_class.prototype, "inquiryInfoTask", [_dec39], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor40 = _applyDecoratedDescriptor(_class.prototype, "installationsTask", [_dec40], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor41 = _applyDecoratedDescriptor(_class.prototype, "billingInfoTask", [_dec41], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor42 = _applyDecoratedDescriptor(_class.prototype, "getPatrolCodeTask", [_dec42], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor43 = _applyDecoratedDescriptor(_class.prototype, "deleteSubjectTask", [_dec43], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor44 = _applyDecoratedDescriptor(_class.prototype, "verifyCreditTask", [_dec44], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor45 = _applyDecoratedDescriptor(_class.prototype, "disconnectAccountTask", [_dec45], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor46 = _applyDecoratedDescriptor(_class.prototype, "activateAccountTask", [_dec46], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor47 = _applyDecoratedDescriptor(_class.prototype, "proceedWithSubjectTask", [_dec47], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor48 = _applyDecoratedDescriptor(_class.prototype, "selectSubjectTask", [_dec48], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor49 = _applyDecoratedDescriptor(_class.prototype, "updateGuardServiceTask", [_dec49], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor50 = _applyDecoratedDescriptor(_class.prototype, "updateInquiryInformationTask", [_dec50], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor51 = _applyDecoratedDescriptor(_class.prototype, "refreshInstallationsTask", [_dec51], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor52 = _applyDecoratedDescriptor(_class.prototype, "updateInstallationTask", [_dec52], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor53 = _applyDecoratedDescriptor(_class.prototype, "updateBillingInformationTask", [_dec53], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor54 = _applyDecoratedDescriptor(_class.prototype, "refreshCustomerInformationTask", [_dec54], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor55 = _applyDecoratedDescriptor(_class.prototype, "refreshSystemsTask", [_dec55], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor56 = _applyDecoratedDescriptor(_class.prototype, "updateAlarmSystemsTask", [_dec56], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor57 = _applyDecoratedDescriptor(_class.prototype, "generateCidTask", [_dec57], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor58 = _applyDecoratedDescriptor(_class.prototype, "refreshZonesTask", [_dec58], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor59 = _applyDecoratedDescriptor(_class.prototype, "updateZonesTask", [_dec59], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor60 = _applyDecoratedDescriptor(_class.prototype, "refreshContactsTask", [_dec60], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor61 = _applyDecoratedDescriptor(_class.prototype, "updateContactsTask", [_dec61], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor62 = _applyDecoratedDescriptor(_class.prototype, "saveSortingChangesTask", [_dec62], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "checkSubject", [_dec63], Object.getOwnPropertyDescriptor(_class.prototype, "checkSubject"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refreshSubjects", [_dec64], Object.getOwnPropertyDescriptor(_class.prototype, "refreshSubjects"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateProceedButtons", [_dec65], Object.getOwnPropertyDescriptor(_class.prototype, "updateProceedButtons"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatePanel", [_dec66], Object.getOwnPropertyDescriptor(_class.prototype, "updatePanel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editZone", [_dec67], Object.getOwnPropertyDescriptor(_class.prototype, "editZone"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateSystemZones", [_dec68], Object.getOwnPropertyDescriptor(_class.prototype, "updateSystemZones"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateContacts", [_dec69], Object.getOwnPropertyDescriptor(_class.prototype, "updateContacts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortEndAction", [_dec70], Object.getOwnPropertyDescriptor(_class.prototype, "sortEndAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initSortMode", [_dec71], Object.getOwnPropertyDescriptor(_class.prototype, "initSortMode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelSort", [_dec72], Object.getOwnPropertyDescriptor(_class.prototype, "cancelSort"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortingComplete", [_dec73], Object.getOwnPropertyDescriptor(_class.prototype, "sortingComplete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveSortingChanges", [_dec74], Object.getOwnPropertyDescriptor(_class.prototype, "saveSortingChanges"), _class.prototype)), _class));
  _exports.default = General;
});