define("securitytrax-ember-ui/components/multi-step-form/forms/administration/users/time-off/component", ["exports", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "ember-concurrency", "moment"], function (_exports, _component, _switchableFormStep, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var UserTimeOff = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._tracked, _dec21 = Ember._tracked, _dec22 = (0, _emberConcurrency.task)(function* () {
    if (!this.SwitchableFormStep.isNewRecord) {
      var calendarEvent = this.args.multiStepForm.formOriginalModelsGetVal('calendarEvent.model');
      Ember.set(this, 'deletePreview', (yield calendarEvent.invokeAction('bulk_time_off_delete', {
        verb: 'POST',
        data: {
          data: {
            before_time: (0, _moment.default)(calendarEvent.get('startTime')).unix(),
            after_time: (0, _moment.default)(calendarEvent.get('startTime')).unix(),
            recurring_id: calendarEvent.get('recurringID') ? JSON.parse(calendarEvent.get('recurringID')).id : null,
            action: 'preview_data'
          }
        }
      })).responsePayload.data[0]);
      this.updateEndTime();
    }

    yield (0, _emberConcurrency.hash)({
      waitForStartTime: (0, _emberConcurrency.waitForProperty)(this.appointmentTimeService, 'startTime', time => time !== undefined),
      waitForEndTime: (0, _emberConcurrency.waitForProperty)(this.appointmentTimeService, 'endTime', time => time !== undefined)
    });
    this.defaultStartTime = this.appointmentTimeService.startTime;
    this.defaultEndTime = (0, _moment.default)(this.appointmentTimeService.endTime).subtract(30, 'minutes');
    this.buttons(); // BOWLES TODO: Talk to Unc about this
  }), _dec23 = (0, _emberConcurrency.task)(function* (buttonPromise, isPreview = false) {
    try {
      var endTime;
      var startTime;
      var toAlsoValidate = {};

      if (this.currentCreateId !== 'multiple') {
        var origStartTime = this.args.multiStepForm.formCopiedModelsGetVal('calendarEvent.model.startTime');
        startTime = (0, _moment.default)(origStartTime);
        toAlsoValidate.timeRangeStart = this.timeRangeStart;
        toAlsoValidate.timeRangeEnd = this.timeRangeEnd;

        if (origStartTime && toAlsoValidate.timeRangeStart) {
          startTime.set({
            hour: toAlsoValidate.timeRangeStart.get('hour'),
            minute: toAlsoValidate.timeRangeStart.get('minute'),
            second: toAlsoValidate.timeRangeStart.get('second')
          });
          this.args.multiStepForm.formCopiedModelsSetVal('calendarEvent.model.startTime', startTime);
        }

        if (origStartTime && toAlsoValidate.timeRangeEnd) {
          endTime = (0, _moment.default)(startTime);
          endTime.set({
            hour: toAlsoValidate.timeRangeEnd.get('hour'),
            minute: toAlsoValidate.timeRangeEnd.get('minute'),
            second: toAlsoValidate.timeRangeEnd.get('second')
          });
        }
      }

      if (this.currentCreateId === 'recurring') {
        toAlsoValidate.recurEvery = this.recurEvery;
        toAlsoValidate.days = this.days;
        toAlsoValidate.endingDate = this.endingDate;
        toAlsoValidate.recurSelectedDaysObj = null;

        if (toAlsoValidate.days) {
          toAlsoValidate.recurSelectedDaysObj = {
            sunday: toAlsoValidate.days.indexOf('sunday') > -1,
            monday: toAlsoValidate.days.indexOf('monday') > -1,
            tuesday: toAlsoValidate.days.indexOf('tuesday') > -1,
            wednesday: toAlsoValidate.days.indexOf('wednesday') > -1,
            thursday: toAlsoValidate.days.indexOf('thursday') > -1,
            friday: toAlsoValidate.days.indexOf('friday') > -1,
            saturday: toAlsoValidate.days.indexOf('saturday') > -1
          };
        }
      }

      if (this.currentCreateId === 'multiple') {
        startTime = this.args.multiStepForm.formCopiedModelsGetVal('calendarEvent.model.startTime');
        endTime = (0, _moment.default)(this.endTimeForDuration);
        toAlsoValidate.endTime = endTime;
      }

      var recurringMetaData = false;

      if (toAlsoValidate.endingDate && toAlsoValidate.recurEvery && toAlsoValidate.recurSelectedDaysObj) {
        recurringMetaData = {
          end_date: toAlsoValidate.endingDate.format('YYYY-MM-DDTHH:mm:ss'),
          recurring_interval_number: toAlsoValidate.recurEvery,
          days: toAlsoValidate.recurSelectedDaysObj
        };
      }

      endTime = _moment.default.isMoment(endTime) ? endTime : (0, _moment.default)(endTime);

      if (endTime && startTime) {
        if (_moment.default.isMoment(endTime)) {
          var parsedDateParts = endTime.parsingFlags().parsedDateParts; // if end time doesn't have date portion, only time portion, set the date portion to be start date

          if (parsedDateParts.length > 3 && !parsedDateParts[0] && !parsedDateParts[1] && !parsedDateParts[2]) {
            var newEndTime = (0, _moment.default)(startTime);
            newEndTime.set({
              hour: endTime.get('hour'),
              minute: endTime.get('minute'),
              second: endTime.get('second')
            });
            endTime = newEndTime;
          }
        }
      }

      var duration = _moment.default.duration(endTime.diff(startTime));

      var durationSeconds = Math.round(duration.asSeconds());
      this.args.multiStepForm.formCopiedModelsSetVal('calendarEvent.model.duration', durationSeconds);

      if (this.SwitchableFormStep.isNewRecord) {
        var timeOffCalendarEventType = yield this.timeOffCalendarEventType.perform();
        var toCreateObjects = yield this.args.multiStepForm.validateCreateAll({
          extraValidationData: {
            timeOffCreateId: this.currentCreateId,
            isTimeoff: true,
            toAlsoValidate: toAlsoValidate
          },
          notificationNamespace: 'modal'
        });

        if (yield this.args.user.isTech) {
          Ember.set(toCreateObjects.calendarEvent.data, 'calendarCategory', 'tech_schedule');
        } else if (yield this.args.user.isSalesRep) {
          Ember.set(toCreateObjects.calendarEvent.data, 'calendarCategory', 'sale_schedule');
        } else {
          Ember.set(toCreateObjects.calendarEvent.data, 'calendarCategory', 'tech_schedule');
        }

        if (isPreview) {
          var requestPreviewData = {
            userId: this.args.user.get('id'),
            attributes: {
              calendar_category: toCreateObjects.calendarEvent.data.calendarCategory,
              start_time: toCreateObjects.calendarEvent.data.startTime,
              duration: toCreateObjects.calendarEvent.data.duration,
              notes: toCreateObjects.calendarEvent.data.notes || ''
            },
            relationships: {
              calendar_event_type: {
                data: {
                  id: timeOffCalendarEventType.get('id'),
                  type: 'calendar_event_types'
                }
              }
            }
          };

          if (recurringMetaData) {
            requestPreviewData.meta = {
              recurring_data: recurringMetaData
            };
          }

          var responsePreviewData = yield this.userService.getUserTimeOffPreview(requestPreviewData);

          if (responsePreviewData.data) {
            this.previewData = responsePreviewData.data;
          }
        } else {
          Ember.set(toCreateObjects.calendarEvent.data, 'calendarUser', this.args.calendarUser);
          Ember.set(toCreateObjects.calendarEvent.data, 'calendarEventType', timeOffCalendarEventType);
          var calendarEvent = this.store.createRecord('calendarEvent', toCreateObjects.calendarEvent.data);

          if (recurringMetaData) {
            calendarEvent.set('requestMeta', {
              recurring_data: recurringMetaData
            });
          }

          yield toCreateObjects.calendarEvent.saveWithRollbackAndNotification(calendarEvent);
        }
      } else {
        yield this.args.multiStepForm.validateSyncSaveAll({
          extraValidationData: {
            isTimeoff: true,
            toAlsoValidate: toAlsoValidate
          },
          notificationNamespace: 'modal'
        });
      }

      if (!isPreview) {
        buttonPromise.then(() => {
          this.args.multiStepForm.formStartedSetVal(false);
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
        });
      }
    } catch (error) {
      warn('saveNewCalendarEvent error ' + error, {
        id: 'multiStepForm.forms.administration.users.timeOff'
      });
      throw error;
    }
  }), _dec24 = (0, _emberConcurrency.task)(function* () {
    var calendarEventTypes = yield this.args.calendarEventTypes;
    var calendarCategory = '';

    if (yield this.args.user.isTech) {
      calendarCategory = 'tech_schedule';
    } else if (yield this.args.user.isSalesRep) {
      calendarCategory = 'sale_schedule';
    } else {
      calendarCategory = 'tech_schedule';
    }

    for (var i = 0; i < calendarEventTypes.get('length'); i++) {
      var calendarEventType = calendarEventTypes.objectAt(i);
      var calendarEventTypeName = calendarEventType.get('name');
      var calendarEventTypeCategory = calendarEventType.get('calendarCategory');

      if (calendarEventTypeName == 'Time Off' && calendarEventTypeCategory == calendarCategory) {
        return calendarEventType;
      }
    }

    return false; // should never get here
  }), _dec25 = (0, _emberConcurrency.task)(function* () {
    var key = this.bulkDeleteKey === 'before' ? 'before_time' : this.bulkDeleteKey === 'after' ? 'after_time' : 'recurring_id';
    var calendarEvent = this.args.multiStepForm.formOriginalModelsGetVal('calendarEvent.model');

    try {
      var deleteReturn = yield this.args.multiStepForm.formOriginalModelsGetVal('calendarEvent.model').invokeAction('bulk_time_off_delete', {
        verb: 'POST',
        data: {
          data: {
            [key]: key === 'recurring_id' ? JSON.parse(calendarEvent.get('recurringID')).id : (0, _moment.default)(calendarEvent.get('startTime')).unix(),
            action: key === 'before_time' ? 'delete_all_before' : key === 'after_time' ? 'delete_all_after' : 'delete_all_recurring'
          }
        }
      });
      this.args.multiStepForm.formStartedSetVal(false);

      if (deleteReturn.responsePayload.data.errors && deleteReturn.responsePayload.data.errors.length > 0) {
        var err = {
          errors: []
        };
        deleteReturn.responsePayload.data.errors.forEach(error => {
          err.errors.push({
            message: this.intl.t('pages.users.time_off.bulk_delete_failure', {
              id: error.id,
              message: error.message
            })
          });
        });
        this.stNotificationManager.addErrorMessages(err);
      }

      this.stNotificationManager.addSuccess(this.intl.t('pages.users.time_off.bulk_delete_success', {
        count: deleteReturn.responsePayload.data.deleted
      }));
      this.args.multiStepForm.onAfterSave();
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
    }
  }), _dec26 = (0, _emberConcurrency.task)(function* () {
    var timeOffId = this.args.multiStepForm.formCopiedModelsGetVal('calendarEvent.model.id');
    var timeOffToDelete = yield this.store.findRecord('calendarEvent', timeOffId);
    timeOffToDelete.destroyRecordWithRollbackTask.perform().then(() => {
      this.args.multiStepForm.formStartedSetVal(false);
      this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
      this.args.multiStepForm.onAfterSave();
    });
  }), _dec27 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    return yield this.saveNewCalendarEvent.perform(buttonPromise, true);
  }), _dec28 = Ember._action, _dec29 = Ember._action, (_class = (_temp = class UserTimeOff extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "grants", _descriptor4, this);

      _initializerDefineProperty(this, "appointmentTimeService", _descriptor5, this);

      _initializerDefineProperty(this, "userService", _descriptor6, this);

      _initializerDefineProperty(this, "deletePreview", _descriptor7, this);

      _initializerDefineProperty(this, "previewData", _descriptor8, this);

      _initializerDefineProperty(this, "confirmDeleteModalOpen", _descriptor9, this);

      _initializerDefineProperty(this, "confirmBulkDeleteModalOpen", _descriptor10, this);

      _initializerDefineProperty(this, "bulkDeleteKey", _descriptor11, this);

      _initializerDefineProperty(this, "currentCreateId", _descriptor12, this);

      _initializerDefineProperty(this, "defaultStartTime", _descriptor13, this);

      _initializerDefineProperty(this, "defaultEndTime", _descriptor14, this);

      _initializerDefineProperty(this, "timeRangeStart", _descriptor15, this);

      _initializerDefineProperty(this, "timeRangeEnd", _descriptor16, this);

      _initializerDefineProperty(this, "recurEvery", _descriptor17, this);

      _initializerDefineProperty(this, "days", _descriptor18, this);

      _initializerDefineProperty(this, "endingDate", _descriptor19, this);

      _initializerDefineProperty(this, "currCalendarEvents", _descriptor20, this);

      _initializerDefineProperty(this, "endTimeForDuration", _descriptor21, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor22, this);

      _initializerDefineProperty(this, "saveNewCalendarEvent", _descriptor23, this);

      _initializerDefineProperty(this, "timeOffCalendarEventType", _descriptor24, this);

      _initializerDefineProperty(this, "bulkDeleteTimeOffTask", _descriptor25, this);

      _initializerDefineProperty(this, "deleteTimeOffTask", _descriptor26, this);

      _initializerDefineProperty(this, "previewSaveNewCalendarEvent", _descriptor27, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.days = []; // BOWLES TODO: Figure out why this is needed for day-picker

      this.initDataTask.perform();
    }

    get timeOffCreateOptions() {
      return [{
        packageId: 'single',
        packageName: this.intl.t('pages.users.time_off.single_day')
      }, {
        packageId: 'multiple',
        packageName: this.intl.t('pages.users.time_off.multiple_days')
      }, {
        packageId: 'recurring',
        packageName: this.intl.t('pages.users.time_off.recurring')
      }];
    }

    get recurEveryWeekOptions() {
      var intl = this.intl;
      var options = [];

      for (var i = 1; i <= 6; i++) {
        options.push({
          name: intl.t('common.numWeeks', {
            numWeeks: i
          }),
          value: i
        });
      }

      return options;
    }

    buttons() {
      var buttonChoices = [];

      if (this.args.formMode !== 'view' && (this.currentCreateId !== undefined || !this.SwitchableFormStep.isNewRecord)) {
        buttonChoices.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveNewCalendarEvent.perform(buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary time-off-save-btn'
        });
      }

      if (!this.SwitchableFormStep.isNewRecord && this.grants.getIndividualGrant('calendar_events.delete')) {
        var additionalClickActions = [];

        if (Number(this.deletePreview.delete_all_before_count) > 0) {
          additionalClickActions.push({
            label: this.intl.t('pages.users.time_off.delete_all_before', {
              count: this.deletePreview.delete_all_before_count
            }),
            action: () => {
              this.bulkDeleteKey = 'before';
              this.confirmBulkDeleteModalOpen = true;
            },
            classNamesString: 'dropdown-delete-all-before'
          });
        }

        if (Number(this.deletePreview.delete_all_after_count) > 0) {
          additionalClickActions.push({
            label: this.intl.t('pages.users.time_off.delete_all_after', {
              count: this.deletePreview.delete_all_after_count
            }),
            action: () => {
              this.bulkDeleteKey = 'after';
              this.confirmBulkDeleteModalOpen = true;
            },
            classNamesString: 'dropdown-delete-all-after'
          });
        }

        if (this.args.multiStepForm.formOriginalModelsGetVal('calendarEvent.model.recurringID')) {
          additionalClickActions.push({
            label: this.intl.t('pages.users.time_off.delete_all_recurring', {
              count: this.deletePreview.delete_all_recurring_count
            }),
            action: () => {
              this.bulkDeleteKey = 'recurring';
              this.confirmBulkDeleteModalOpen = true;
            },
            classNamesString: 'dropdown-delete-all-recurring'
          });
        }

        buttonChoices.push({
          text: this.intl.t('common.actions.delete'),
          positionOptions: 'up right',
          click: () => {
            this.confirmDeleteModalOpen = true;
          },
          additionalClickActions: additionalClickActions.length > 0 ? additionalClickActions : undefined,
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
        });
      }

      this.args.multiStepForm.registerStepData('step1', 'buttons', buttonChoices);
    }

    updateEndTime() {
      var durationSeconds = this.args.multiStepForm.formCopiedModelsGetVal('calendarEvent.model.duration');
      var startTime;

      if (this.args.multiStepForm.formCopiedModelsGetVal('calendarEvent.model.startTime')) {
        startTime = (0, _moment.default)(this.args.multiStepForm.formCopiedModelsGetVal('calendarEvent.model.startTime'));
      }

      if (startTime && this.SwitchableFormStep.isNewRecord && (!this.endTimeForDuration || (0, _moment.default)(this.endTimeForDuration).isSame(startTime, 'day'))) {
        this.endTimeForDuration = (0, _moment.default)(startTime).add(_moment.default.duration(durationSeconds, 'seconds'));
      } else if (startTime) {
        this.timeRangeStart = startTime;
        this.timeRangeEnd = (0, _moment.default)(startTime).add(_moment.default.duration(durationSeconds, 'seconds'));
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "appointmentTimeService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "userService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "deletePreview", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "previewData", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "confirmDeleteModalOpen", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "confirmBulkDeleteModalOpen", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "bulkDeleteKey", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "currentCreateId", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "defaultStartTime", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "defaultEndTime", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "timeRangeStart", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "timeRangeEnd", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "recurEvery", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "days", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "endingDate", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "currCalendarEvents", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "endTimeForDuration", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "saveNewCalendarEvent", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "timeOffCalendarEventType", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "bulkDeleteTimeOffTask", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "deleteTimeOffTask", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "previewSaveNewCalendarEvent", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "buttons", [_dec28], Object.getOwnPropertyDescriptor(_class.prototype, "buttons"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateEndTime", [_dec29], Object.getOwnPropertyDescriptor(_class.prototype, "updateEndTime"), _class.prototype)), _class));
  _exports.default = UserTimeOff;
});