define("securitytrax-ember-ui/demo/confirm/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller.extend({
    cancelPressed: false,
    okPressed: false,
    actions: {
      onConfirmCancel() {
        this.set('cancelPressed', true);
      },

      onConfirmOk() {
        this.set('okPressed', true);
      }

    }
  });
  var _default = Controller;
  _exports.default = _default;
});