define("securitytrax-ember-ui/components/multi-step-form/forms/customer/edit-customer-funding/edit-customer-funding-transaction/component", ["exports", "@glimmer/component", "ember-concurrency", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "moment"], function (_exports, _component, _emberConcurrency, _switchableFormStep, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EditFundingTransaction = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = (0, _emberConcurrency.task)(function* () {
    if (yield this.grants.getIndividualGrant('funding_transaction_types.view')) {
      return this.store.findAll('fundingTransactionType');
    }

    return [];
  }), _dec7 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    multiStepForm.formCopiedModelsSetVal('fundingTransaction.model.transactionDate', this.transactionDate);

    if (this.SwitchableFormStep.isNewRecord) {
      var toCreateObjects = yield multiStepForm.validateCreateAll({
        notificationNamespace: 'customerFundingTransactions'
      });
      Ember.set(toCreateObjects.fundingTransaction.data, 'funding', this.args.currCustomerFunding);
      var fundingTransaction = this.store.createRecord('fundingTransaction', toCreateObjects.fundingTransaction.data);
      yield toCreateObjects.fundingTransaction.saveWithRollbackAndNotification(fundingTransaction, 'customerFundingTransactions');
    } else {
      yield multiStepForm.validateSyncSaveAll({
        notificationNamespace: 'customerFundingTransactions'
      });
    }

    buttonPromise.then(() => {
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful', {
        notificationNamespace: 'modal'
      });
      multiStepForm.formStartedSetVal(false);
    });
  }), _dec8 = (0, _emberConcurrency.task)(function* () {
    try {
      var multiStepForm = this.args.multiStepForm;
      var contactToDelete = multiStepForm.formOriginalModelsGetVal('fundingTransaction.model');
      yield contactToDelete.destroyRecordWithRollbackTask.perform();
      multiStepForm.formStartedSetVal(false);
      this.stNotificationManager.addSuccessKey('common.notifications.delete_successful', {
        notificationNamespace: 'modal'
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'customerFundingTransactions'
      });
      throw err;
    }
  }), _dec9 = Ember._action, (_class = (_temp = class EditFundingTransaction extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "grants", _descriptor4, this);

      _initializerDefineProperty(this, "confirmDeleteModalOpen", _descriptor5, this);

      _defineProperty(this, "transactionDate", undefined);

      _initializerDefineProperty(this, "fundingTransactionTypesTask", _descriptor6, this);

      _initializerDefineProperty(this, "saveCustomerFundingTransaction", _descriptor7, this);

      _initializerDefineProperty(this, "deleteModel", _descriptor8, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments, {
        disabledProgressBarSteps: [],
        hiddenProgressBarSteps: []
      });
      this.SwitchableFormStep.progressBarHidden = true;
    }

    get fundingTransactionTypes() {
      return this.fundingTransactionTypesTask.perform();
    }

    setupButtons() {
      var multiStepForm = this.args.multiStepForm;
      this.transactionDate = (0, _moment.default)(multiStepForm.formOriginalModelsGetVal('fundingTransaction.model.transactionDate'));
      var buttons = [];

      if (this.args.formMode !== 'view') {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveCustomerFundingTransaction.perform(multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary funder-save-button'
        });
      }

      if (!this.SwitchableFormStep.isNewRecord && this.grants.getIndividualGrant('funding_transaction_types.delete')) {
        buttons.push({
          text: this.intl.t('common.actions.delete'),
          click: () => {
            Ember.set(this, 'confirmDeleteModalOpen', true);
          },
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
        });
      }

      multiStepForm.registerStepData('step1', 'buttons', buttons);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "confirmDeleteModalOpen", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "fundingTransactionTypesTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "saveCustomerFundingTransaction", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "deleteModel", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupButtons", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "setupButtons"), _class.prototype)), _class));
  _exports.default = EditFundingTransaction;
});