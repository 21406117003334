define("securitytrax-ember-ui/components/multi-step-form/forms/administration/account-management/payment-method/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xrGb1q8W",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],null,[[\"useSpinner\",\"booleanLoaded\",\"size\"],[true,[35,1,[\"finishedCopyingModels\"]],\"small\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,4],null,[[\"selectedPaymentMethod\",\"formMode\",\"multiStepForm\",\"accountBilling\"],[[35,3],[35,2],[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,10],null,[[\"onConfirm\",\"confirmMessage\",\"confirmBtnText\",\"confirmButtonClassNamesString\",\"manuallyOpen\",\"_dialogOpen\"],[[30,[36,9],[[35,8],[35,0]],null],[30,[36,7],[\"common.confirm.really_delete\"],null],[30,[36,7],[\"common.actions.delete\"],null],\"st-btn-warn\",true,[35,6]]]]]],\"hasEval\":false,\"upvars\":[\"accountBilling\",\"multiStepForm\",\"formMode\",\"selectedPaymentMethod\",\"multi-step-form/forms/administration/account-management/payment-method/credit-card\",\"html/st-await\",\"confirmDeleteModalOpen\",\"t\",\"deleteCustomerBilling\",\"perform\",\"modals/confirm-popup\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/account-management/payment-method/template.hbs"
    }
  });

  _exports.default = _default;
});