define("securitytrax-ember-ui/components/multi-step-form/forms/system/adt/steps/econtracts/create-econtract/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    centralStationService: Ember.inject.service(),
    ccRecipients: undefined,
    ccRecipientEmail: undefined,
    creditHolderAuthenticationNumber: undefined,
    contractSignerEmail: undefined,
    signingMethods: undefined,
    signingMethod: undefined,
    authenticationTypes: undefined,
    authenticationType: undefined,
    choicesLoaded: false,
    formReady: false,
    ccDeleteId: false,
    additionalTemplates: null,
    additionalAdtTemplates: null,
    templateDeleteId: false,
    hasDiscrepancyNotes: false,
    discrepancyName: false,
    discrepancyAddress: false,
    setupForm: (0, _emberConcurrency.task)(function* () {
      try {
        var monitoringCompany = yield this.monitoringCompany;
        var centralStationService = this.centralStationService;
        var dataPromises = yield (0, _emberConcurrency.hash)({
          adtMonitoringCompanyAdditionalTemplates: centralStationService.getData({
            model: monitoringCompany,
            method: 'adt__getEnvelopeAdditionalTemplates',
            responseKey: 'envelope_additional_templates'
          }),
          adtMonitoringCompanyAdditionalAdtTemplates: centralStationService.getData({
            model: monitoringCompany,
            method: 'adt__getEnvelopeAdditionalAdtTemplates',
            responseKey: 'envelope_additional_adt_templates'
          }),
          adtMonitoringCompanyAdditionalTemplatePositions: centralStationService.getData({
            model: monitoringCompany,
            method: 'adt__getEnvelopeTemplatePositions',
            responseKey: 'envelope_template_positions'
          }),
          adtMonitoringCompanySigningMethod: centralStationService.getData({
            model: monitoringCompany,
            method: 'adt__getEnvelopeSigningMethods',
            responseKey: 'envelope_signing_methods'
          }),
          adtMonitoringCompanyAuthenticationType: centralStationService.getData({
            model: monitoringCompany,
            method: 'adt__getEnvelopeAuthenticationTypes',
            responseKey: 'envelope_authentication_types'
          }),
          customer: this.customer,
          discrepancies: this.discrepancies
        });
        var authenticationTypes = [];

        for (var i = 0; i < dataPromises.adtMonitoringCompanyAuthenticationType.get('length'); i++) {
          var currAuthenticationType = dataPromises.adtMonitoringCompanyAuthenticationType.objectAt(i);
          authenticationTypes.push(Ember.Object.create({
            id: currAuthenticationType.id,
            name: currAuthenticationType.name
          }));
        }

        this.set('creditHolderAuthenticationNumber', dataPromises.customer.get('primaryPhone') || '');
        this.set('contractSignerEmail', dataPromises.customer.get('email') || '');
        this.set('signingMethods', dataPromises.adtMonitoringCompanySigningMethod);
        this.set('signingMethod', 'email');
        this.set('additionalTemplateOptions', dataPromises.adtMonitoringCompanyAdditionalTemplates);
        this.set('additionalAdtTemplateOptions', dataPromises.adtMonitoringCompanyAdditionalAdtTemplates);
        this.set('additionalTemplatePositions', dataPromises.adtMonitoringCompanyAdditionalTemplatePositions);
        this.set('authenticationTypes', authenticationTypes);
        this.set('authenticationType', 'SMS'); //default to SMS/texting

        this.signingMethodChoices; //preload choices

        this.set('ccRecipients', Ember.A([Ember.Object.create({})])); //new line for cc recipients

        this.set('additionalTemplates', Ember.A([Ember.Object.create({})])); //new line for additional templates

        this.set('additionalAdtTemplates', Ember.A([Ember.Object.create({})])); //new line for additional templates

        if (dataPromises.discrepancies && Array.isArray(dataPromises.discrepancies) && dataPromises.discrepancies.length > 0) {
          this.set('hasDiscrepancyNotes', true);
          this.set('discrepancyAddress', dataPromises.discrepancies.some(item => {
            return item.category === 'account__address';
          }));
          dataPromises.discrepancies.forEach(discrepancy => {
            var dynamicName = 'discrepancy_' + discrepancy.field.toLowerCase();
            this.set(dynamicName, discrepancy);
          });
        }

        this.set('formReady', true);
      } catch (error) {
        (false && Ember.warn('error in create form setupForm' + error, {
          id: 'multiStepForm.forms.system.adt.createEContract'
        }));
      }
    }).on('init'),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('addEContracts', 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.createEContractTask.perform(multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary e-contract-save-button'
      }]);
    },

    signingMethodChoices: Ember.computed('signingMethods', function () {
      var choices = Ember.A([]);
      var signingMethods = this.signingMethods;

      if (signingMethods && signingMethods.get('length') > 0) {
        signingMethods.forEach(method => {
          choices.pushObject(Ember.Object.create({
            packageName: method.name,
            packageId: method.id
          }));
        });
      }

      this.set('choicesLoaded', true);
      return choices;
    }),
    createEContractTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      var messages = [];
      this.set('formReady', false);

      try {
        var additionalTemplates = this.additionalTemplates;
        var additionalAdtTemplates = this.additionalAdtTemplates;
        var ccRecipients = this.ccRecipients;
        var lastAdditionalTemplate = additionalTemplates[additionalTemplates.length - 1];
        var lastAdditionalAdtTemplate = additionalAdtTemplates[additionalAdtTemplates.length - 1];
        var lastCcRecipient = ccRecipients[ccRecipients.length - 1];

        if (!lastAdditionalTemplate.additionalTemplateOption) {
          additionalTemplates.pop(); //get rid of add new line
        }

        if (!lastAdditionalAdtTemplate.additionalAdtTemplateOption) {
          additionalAdtTemplates.pop(); //get rid of add new line
        }

        if (!lastCcRecipient.name) {
          ccRecipients.pop(); //get rid of add new line
        }

        var payload = {
          full_packet: true,
          signing_method: this.signingMethod,
          //email or embedded(phone call or text),
          authentication_type: this.authenticationType,
          //text or call
          credit_holder_authentication_number: this.creditHolderAuthenticationNumber,
          //number to text or call
          additional_templates: additionalTemplates.map(template => {
            return {
              'name': template.additionalTemplateOption,
              'position': template.additionalTemplatePosition
            };
          }),
          additional_adt_templates: additionalAdtTemplates.map(template => {
            return {
              'id': template.additionalAdtTemplateOption
            };
          }),
          carbon_copy_recipients: ccRecipients.map(recipient => {
            return {
              'name': recipient.name,
              'email': recipient.email
            };
          })
        };
        var response = yield this.centralStationService.postData({
          model: this.customer,
          method: 'adt__createEnvelope',
          payload: payload
        });
        messages = messages.concat(response.responsePayload.data.messages);
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'adtMegaFormModal');
        yield this.refreshOrderTask.perform();
        multiStepForm.formStartedSetVal(false);
        buttonPromise.then(() => {
          if (messages.length > 0) {
            this.stNotificationManager.addWarningMessages(messages, {
              notificationNamespace: 'adtMegaFormModal'
            });
          }

          if (this.transitionBackToParent && typeof this.transitionBackToParent === 'function') {
            this.transitionBackToParent();
          }
        });
      } catch (response) {
        this.set('formReady', true);
        this.stNotificationManager.addErrorMessages(response, {
          notificationNamespace: 'eContractModal'
        });
        throw response;
      }

      this.set('formReady', true);
    }),
    ccLastRowIndex: Ember.computed('ccRecipients', function () {
      return this.ccRecipients.length - 1;
    }),
    templateLastRowIndex: Ember.computed('additionalTemplates', function () {
      return this.additionalTemplates.length - 1;
    }),
    adtTemplateLastRowIndex: Ember.computed('additionalAdtTemplates', function () {
      return this.additionalAdtTemplates.length - 1;
    }),
    actions: {
      deleteListItem(index, type) {
        var listName = type == 'cc' ? 'ccRecipients' : type == 'additionalTemplates' ? 'additionalTemplates' : 'additionalAdtTemplates';
        var deleteIdName = type == 'cc' ? 'ccDeleteId' : type == 'additionalTemplates' ? 'templateDeleteId' : 'adtTemplateDeleteId';
        var list = this.get(listName);
        this.set(listName, list.filter(item => {
          return item != list[index];
        }));
        this.set(deleteIdName, false);
      },

      updateList(index, type) {
        var lineItems = type == 'cc' ? this.ccRecipients : type == 'additionalTemplates' ? this.additionalTemplates : this.additionalAdtTemplates;
        var lineItemsLength = lineItems.length;

        if (index == lineItemsLength - 1) {
          lineItems.pushObject(Ember.Object.create({}));

          if (type == 'cc') {
            this.notifyPropertyChange('ccRecipients');
          } else if (type == 'additionalTemplates') {
            this.notifyPropertyChange('additionalTemplates');
          } else {
            this.notifyPropertyChange('additionalAdtTemplates');
          }
        }
      }

    }
  });

  _exports.default = _default;
});