define("securitytrax-ember-ui/transforms/number-precision", ["exports", "@ember-data/serializer/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
   * Options:
   * precision: must be an integer sets the precision of the number after the Radix point.
   * */
  var _default = _transform.default.extend({
    deserialize(serialized, options) {
      if (serialized) {
        if (options.precision !== null && Number.isInteger(options.precision)) {
          if (typeof serialized === 'string') {
            return Number.parseFloat(serialized).toFixed(options.precision);
          } else if (typeof serialized === 'number') {
            return serialized.toFixed(options.precision);
          } else {
            throw new Error('precision of number-float not set');
          }
        } else {
          throw new Error('precision of number-float not set');
        }
      }

      return null;
    },

    serialize(deserialized, options) {
      if (deserialized || deserialized === 0) {
        return Number(deserialized).toFixed(options.precision);
      }

      return null;
    }

  });

  _exports.default = _default;
});