define("securitytrax-ember-ui/demo/grants-accessed-test-two/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fGOiovkc",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],null,[[\"classNames\"],[\"paper-demo\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h1\"],[12],[2,\"Grants accessed 2\"],[13],[2,\"\\n\\n    --\"],[1,[30,[36,0],[\"groups.view\"],null]],[2,\"--\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"has-grants\",\"pages/demo-page\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/demo/grants-accessed-test-two/template.hbs"
    }
  });

  _exports.default = _default;
});