define("securitytrax-ember-ui/components/multi-step-form/forms/invoices/refund-receipt/get-started/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/invoices/base-step-components/get-started/base", "ember-concurrency", "ember-data"], function (_exports, _base, _emberConcurrency, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var RefundReceiptGetStarted = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = (0, _emberConcurrency.task)(function* () {
    var invoices = yield this.args.customer.get('invoices');

    if (invoices.length === 0) {
      this.args.multiStepForm.registerStepData('gettingStarted', 'buttons', []);
    }

    return yield (0, _emberConcurrency.all)(invoices.map(invoice => {
      var invoiceName = this.intl.t('common.forms.descriptive_title_view', {
        name: this.intl.t('pages.customers.accounting.invoices.invoice'),
        id: invoice.get('id')
      });
      return Ember.Object.create({
        name: invoiceName,
        icon: 'icn-payment-history',
        items: this.getInvoiceItems(invoice),
        sections: [{
          position: 'left',
          sectionProperties: [{
            formatter: 'svg-icon',
            propertyName: 'icon'
          }, {
            formatter: 'label',
            propertyName: 'name'
          }]
        }]
      });
    }));
  }), _dec10 = (0, _emberConcurrency.task)(function* () {
    var totalItems = 0;
    var groupedInvoices = yield this.groupedInvoices;
    var allItems = [];

    for (var index = 0; index < groupedInvoices.length; index++) {
      var element = groupedInvoices.objectAt(index);
      allItems.push(element.items);
    }

    var yieldedItems = yield (0, _emberConcurrency.all)(allItems);

    for (var _index = 0; _index < yieldedItems.length; _index++) {
      var currItems = yieldedItems[_index];
      totalItems += currItems.length;
    }

    Ember.set(this, 'totalItems', totalItems);
  }), _dec11 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var lineItems = [];
    var promises = [];

    if (this.receiptLineItems.length == 0) {
      this.stNotificationManager.addErrorMessages(this.intl.t('common.validations.refundNeedsLineItems'), {
        notificationNamespace: 'modal'
      });
      throw Error;
    }

    this.receiptLineItems.forEach(lineItem => {
      promises.push(this.populateLineItemTask.perform(lineItems, lineItem));
    });
    yield (0, _emberConcurrency.all)(promises);
    this.args.multiStepForm.formCopiedModelsSetVal('invoice.model.invoiceLineItems', lineItems);
    buttonPromise.then(() => {
      this.args.multiStepForm.currentStepNameKeySetVal('details');
    });
  }), _dec12 = (0, _emberConcurrency.task)(function* (lineItems, item) {
    var billableItem = yield item.get('invoiceBillableItem');
    lineItems.push(Ember.Object.create({
      quantity: item.quantity,
      invoiceBillableItem: billableItem,
      description: billableItem.get('description'),
      unitPrice: Number(item.unitPrice),
      grossAmount: Number(item.grossAmount),
      salesTaxRate: item.salesTaxRate,
      salesTaxAmount: Number(item.salesTaxAmount),
      totalAmount: Number(item.totalAmount),
      isNotTaxable: yield billableItem.isNotTaxableTask.perform(this.args.customer)
    }));
  }), _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, (_class = (_temp = class RefundReceiptGetStarted extends _base.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor, this);

      _initializerDefineProperty(this, "invoiceLineItems", _descriptor2, this);

      _initializerDefineProperty(this, "lineItems", _descriptor3, this);

      _initializerDefineProperty(this, "isLineItemCheckAll", _descriptor4, this);

      _initializerDefineProperty(this, "isLineItemIndeterminate", _descriptor5, this);

      _initializerDefineProperty(this, "invoices", _descriptor6, this);

      _initializerDefineProperty(this, "receiptLineItems", _descriptor7, this);

      _initializerDefineProperty(this, "totalItems", _descriptor8, this);

      _initializerDefineProperty(this, "groupedInvoicesTask", _descriptor9, this);

      _initializerDefineProperty(this, "totalItemsTask", _descriptor10, this);

      _initializerDefineProperty(this, "setupInvoiceLineItemsTask", _descriptor11, this);

      _initializerDefineProperty(this, "populateLineItemTask", _descriptor12, this);

      this.totalItemsTask.perform();
      this.initBaseData.perform();
      this.invoiceLineItems = [];
      this.lineItems = [];
      this.receiptLineItems = [];
    }

    get groupedInvoices() {
      return this.groupedInvoicesTask.perform();
    }

    getInvoiceItems(invoice) {
      if (invoice.get('invoiceType') === 'invoice') {
        var lineItems = _emberData.default.PromiseArray.create({
          promise: invoice.get('invoiceLineItems').then(invoiceLineItems => {
            return (0, _emberConcurrency.all)(invoiceLineItems.map(lineItem => {
              lineItem.set('isChecked', false);
              return lineItem;
            }));
          })
        });

        lineItems.then(items => this.invoiceLineItems.addObjects(items));
        return lineItems;
      }
    }

    get values() {
      return Ember.Object.create({
        receiptLineItems: this.receiptLineItems
      });
    }

    updateValues(key, value) {
      var values = this.values;
      values.set(key, value);
      return values;
    }

    itemCheckAll() {
      this.isLineItemCheckAll = !this.isLineItemCheckAll;
      var receiptLineItems = [];
      var updatedLineItems = this.invoiceLineItems.map(invoiceLineItem => {
        invoiceLineItem.set('isChecked', this.isLineItemCheckAll);
        return invoiceLineItem;
      });

      if (this.isLineItemCheckAll) {
        receiptLineItems = updatedLineItems;
      }

      this.receiptLineItems = receiptLineItems;
      this.isLineItemIndeterminate = false;
    }

    itemChecked(row, event) {
      if (event) {
        event.stopPropagation();
      }

      var isChecked = !row.get('isChecked');
      var receiptLineItems = this.receiptLineItems;
      var itemIndex = receiptLineItems.indexOf(row.get('id'));

      if (isChecked) {
        if (itemIndex === -1) {
          receiptLineItems.addObject(row);
          Ember.set(row, 'isChecked', true);
        }
      } else {
        receiptLineItems.removeObject(row);
        Ember.set(row, 'isChecked', false);
      }

      Ember.set(this, 'receiptLineItems', receiptLineItems);
      Ember.set(this, 'isLineItemIndeterminate', this.totalItems > receiptLineItems.length && receiptLineItems.length > 0);
      Ember.set(this, 'isLineItemCheckAll', this.totalItems === receiptLineItems.length && receiptLineItems.length > 0);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "invoiceLineItems", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "lineItems", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isLineItemCheckAll", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isLineItemIndeterminate", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "invoices", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "receiptLineItems", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "totalItems", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "groupedInvoicesTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "totalItemsTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "setupInvoiceLineItemsTask", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "populateLineItemTask", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateValues", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "updateValues"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "itemCheckAll", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "itemCheckAll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "itemChecked", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "itemChecked"), _class.prototype)), _class));
  _exports.default = RefundReceiptGetStarted;
});