define("securitytrax-ember-ui/demo/package-select/controller", ["exports", "faker", "ember-concurrency"], function (_exports, _faker, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function getGroupedPropertySections() {
    var fnGetFormatter = (formatter, propertyName) => ({
      formatter,
      propertyName
    });

    return [{
      position: 'left',
      sectionProperties: [fnGetFormatter('svg-icon', 'icon'), fnGetFormatter('label', 'name')]
    }, {
      position: 'right',
      sectionProperties: [fnGetFormatter('count', 'items')]
    }];
  }

  var Controller = Ember.Controller.extend({
    intl: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.set('buttonChoices', ['Choice 1', 'Another Choice', 'Something Else']);
      this.set('complexButtonChoices', [{
        label: 'Something',
        disabled: false
      }, {
        label: 'Else',
        disabled: false
      }, {
        label: 'More',
        disabled: true
      }]);
      this.set('bulkActionButtons', [{
        label: 'Install',
        bulkAction: true
      }, {
        label: 'Remove',
        bulkAction: true
      }, {
        label: 'Service',
        bulkAction: true
      }]);
    },

    customer: Ember.computed.alias('model.customer'),
    selectedPackage: Ember.computed(function () {
      var choices = this.packageSelectChoices;
      return choices.length > 0 ? choices[0].packageId : null;
    }),
    packageSelectChoices: Ember.computed(function () {
      var choices = Ember.A([]);

      for (var i = 1; i < 3; i++) {
        choices.pushObject(Ember.Object.create({
          packageName: _faker.default.lorem.word(),
          packageId: i
        }));
      }

      return choices;
    }),
    selectedRequired: null,
    multipleSelectChoices: Ember.computed(function () {
      var choices2 = Ember.A([]);

      for (var i = 1; i < 5; i++) {
        var arr = Ember.A([]);

        for (var j = 1; j < 4; j++) {
          arr.pushObject({
            label: _faker.default.lorem.word(),
            description: _faker.default.random.number({
              min: 1,
              max: 9
            }) + ' ' + _faker.default.lorem.words(3)
          });
        }

        choices2.pushObject(Ember.Object.create({
          packageName: _faker.default.lorem.word(),
          packageId: i,
          packageContent: arr
        }));
      }

      return choices2;
    }),
    selectedButtonChoice: 2,
    validationErrors: Ember.computed('selectedRequired', function () {
      if (!this.selectedRequired) {
        return {
          'model-field': [{
            message: 'You gotta select one of these'
          }]
        };
      } else {
        return null;
      }
    }),
    groupedEquipment: Ember.computed('customer.customerEquipment.[]', function () {
      return this.groupedEquipmentTask.perform();
    }),
    groupedEquipmentTask: (0, _emberConcurrency.task)(function* () {
      var childTasks = [];
      var customer = yield this.customer;
      var customerEquipment = yield customer.get('customerEquipment');
      Ember.RSVP.all(customerEquipment.map(ce => {
        childTasks.push(this.mapEquipmentTask.perform(ce));
      }));
      var allCustomerEquipment = yield Ember.RSVP.all(childTasks);
      return allCustomerEquipment.uniqBy('type').map(({
        type
      }) => {
        return Ember.Object.create({
          name: type.get('name'),
          icon: type.get('icon'),
          sections: getGroupedPropertySections(),
          items: allCustomerEquipment.filterBy('type', type).mapBy('equipment')
        });
      });
    }),
    mapEquipmentTask: (0, _emberConcurrency.task)(function* (ce) {
      var equipment = yield ce.get('equipment');
      var equipmentStatus = yield ce.get('equipmentStatus');
      var equipmentItem = yield equipment.get('equipmentItem');
      var subType = yield equipmentItem.get('equipmentSubType');
      var equipmentType = yield subType.get('equipmentType');
      ce.set('complexButtonChoices', [{
        label: 'install',
        disabled: equipmentStatus.get('name') == 'Installed'
      }, {
        label: 'remove',
        disabled: equipmentStatus.get('name') == 'Not Installed'
      }, {
        label: 'service',
        disabled: equipmentStatus.get('name') == 'Not Installed'
      }]);
      return {
        equipment: ce,
        type: equipmentType
      };
    }),
    bulkToggleTask: (0, _emberConcurrency.task)(function* (selected, bulkTurnOn) {
      var groupedEquipment = yield this.groupedEquipment;

      if (groupedEquipment) {
        groupedEquipment.map(group => {
          group.items.map(item => {
            if (bulkTurnOn && !item['complexButtonChoices'][selected]['disabled']) {
              item.set('selectedButton', selected);
            } else if (item['complexButtonChoices'][selected]['disabled']) {//dont do anything if button is disabled, otherwise it may deselect another option
            } else {
              //deselect
              item.set('selectedButton', null);
            }
          });
        });
      }
    })
  });
  var _default = Controller;
  _exports.default = _default;
});