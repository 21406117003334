define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/confirm-delete-customer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "duuPFXJR",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"grid-x\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cell small-12 align-center\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"in-modal-confirm-container\"],[12],[2,\"\\n            \"],[10,\"h3\"],[12],[1,[30,[36,0],[\"common.actions.deleteCustomer\"],null]],[13],[2,\"\\n            \"],[10,\"p\"],[12],[2,[30,[36,0],[\"common.confirm.really_delete_customer_msg\"],[[\"name\"],[[35,1]]]]],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"confirm-buttons\"],[12],[2,\"\\n                \"],[8,\"html/st-button\",[[24,0,\"warning-red\"]],[[\"@text\",\"@clickAction\"],[[30,[36,0],[\"common.actions.deleteCustomer\"],null],[30,[36,2],[[32,0],\"deleteCustomer\"],null]]],null],[2,\"\\n                \"],[8,\"html/st-button\",[[24,0,\"st-btn-secondary\"]],[[\"@text\",\"@clickAction\"],[[30,[36,0],[\"common.actions.cancel\"],null],[30,[36,2],[[32,0],\"cancel\"],null]]],null],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"customerName\",\"action\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/confirm-delete-customer/template.hbs"
    }
  });

  _exports.default = _default;
});