define("securitytrax-ember-ui/services/browser-geo-location-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    stNotificationManager: Ember.inject.service(),
    intl: Ember.inject.service(),
    userStorageService: Ember.inject.service(),
    getBrowserLocation: Ember.computed(function () {
      return new Ember.RSVP.Promise((resolve
      /*, reject*/
      ) => {
        if (navigator.geolocation) {
          // for some browsers (firefox at least) the timeout below does not work so we force the promise to close after a max timeout to ensure the promise resolves at some point
          var customMaxTimeout = 12000;
          var hasTimedOut = false;
          var maxTimeoutRef = setTimeout(() => {
            hasTimedOut = true;
            resolve(false);
          }, customMaxTimeout);
          navigator.geolocation.getCurrentPosition(position => {
            clearTimeout(maxTimeoutRef);

            if (!hasTimedOut) {
              resolve(position);
            }
          }, error => {
            clearTimeout(maxTimeoutRef);
            error;

            if (!this.userStorageService.hasReceivedLocationWarning) {
              this.stNotificationManager.addWarningMessages(this.intl.t('pages.customers.techToday.noGeoCoordinates'), {
                notificationNamespace: 'global'
              });
              this.userStorageService.setLocationWarningFlag();
            }

            if (!hasTimedOut) {
              resolve(false);
            }
          }, {
            timeout: 5000
          });
        } else {
          resolve(false);
        }
      });
    })
  });

  _exports.default = _default;
});