define("securitytrax-ember-ui/components/pages/all-customer-payments/component", ["exports", "ember-concurrency", "lodash", "securitytrax-ember-ui/mixins/table-column-sort", "securitytrax-ember-ui/config/environment"], function (_exports, _emberConcurrency, _lodash, _tableColumnSort, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableColumnSort.default, {
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    session: Ember.inject.service(),
    quickbooksService: Ember.inject.service(),
    stFiltersService: Ember.inject.service(),
    tagsService: Ember.inject.service(),
    screen: Ember.inject.service(),
    limit: 50,
    page: 1,
    tasksListCount: 0,
    paymentDateFrom: undefined,
    paymentDateTo: undefined,
    processingStatusIs: null,
    processingStatusIsNot: null,
    accountingSync: null,
    accountingSyncFormMode: 'edit',
    accountingSyncFormStarted: false,
    tagFilter: undefined,

    init() {
      this._super(...arguments);

      this.initTask.perform();
    },

    initTask: (0, _emberConcurrency.task)(function* () {
      var intl = this.intl;
      this.sortPaginatedList('payment_date', false);
      this.set('stSortableColumnOptions', [{
        name: intl.t('pages.company.payments.payment_date') + ' ' + intl.t('common.sorting.ascending'),
        value: 'payment_date',
        chevronDirection: 'up'
      }, {
        name: intl.t('pages.company.payments.payment_date') + ' ' + intl.t('common.sorting.descending'),
        value: '-payment_date',
        chevronDirection: 'down'
      }]); // so can still test quickbooks some even if not connected

      var isConnected = _environment.default.environment === 'test' || (yield this.quickbooksService.quickbooksIsConnected.perform());
      Ember.set(this, 'quickbooksConnected', isConnected);
      Ember.set(this, 'tagFilter', this.tagsService.tagFilter());
      Ember.set(this, 'tagsOptions', yield this.tagsService.tagsOptionsTask.perform('customer'));
    }),

    get accountingSyncFormSteps() {
      var intl = this.intl;
      return Ember.Object.create({
        accountingSyncStep: Ember.Object.create({
          stepNameKey: 'accountingSyncStep',
          title: intl.t('pages.administration.integrations.accountingProvidersSync.send_payments'),
          stepComponentPath: 'multi-step-form/forms/accounting/accounting-payment-sync-quickbooks'
        })
      });
    },

    processingStatusOptions: Ember.computed(function () {
      return [{
        name: this.intl.t('pages.customers.accounting.customer_billing.canceled'),
        value: 'canceled'
      }, {
        name: this.intl.t('pages.customers.accounting.customer_billing.complete'),
        value: 'complete'
      }, {
        name: this.intl.t('pages.customers.accounting.customer_billing.empty'),
        value: null
      }, {
        name: this.intl.t('pages.customers.accounting.customer_billing.failed'),
        value: 'failed'
      }, {
        name: this.intl.t('pages.customers.accounting.customer_billing.pending'),
        value: 'pending'
      }, {
        name: this.intl.t('pages.customers.accounting.customer_billing.pendingsettlement'),
        value: 'pendingsettlement'
      }, {
        name: this.intl.t('pages.customers.accounting.customer_billing.unknown'),
        value: 'unknown'
      }];
    }),
    customerPayments: Ember.computed(function () {
      return this.customerPaymentsTask.perform();
    }),
    customerPaymentsTask: (0, _emberConcurrency.task)(function* () {
      try {
        var customerPayments = yield this.store.query('customerPayment', {
          sort: this.stSortString ? "".concat(this.stSortString, ",-id") : '-payment_date,-id',
          include: 'customer.rep_user,customer_billing.bill_type,customer_payment_type',
          filter: yield this.stFiltersService.getFiltersByGroupKey('allCustomerPayments'),
          limit: this.limit,
          page: this.page,
          count: 1
        });
        var promiseArray = [];
        customerPayments.forEach(payment => {
          promiseArray.push(payment.get('customerBilling.title'));
        });
        var resolvedPromiseArray = yield (0, _emberConcurrency.all)(promiseArray);
        customerPayments.forEach((payment, index) => {
          payment.set('_resolvedTitle', resolvedPromiseArray[index] ? resolvedPromiseArray[index] : payment.customerBillingOrPaymentTypeLabel);
        });
        return {
          customerPayments,
          record_count: _lodash.default.get(customerPayments, 'meta.record_count', 0)
        };
      } catch (error) {
        (false && Ember.warn('failed in customerPaymentsTask: ' + error, {
          id: 'company.accounting.payments.customerPaymentsTask'
        }));
      }
    }),
    actions: {
      updateCustomerPaymentsList() {
        this.notifyPropertyChange('customerPayments');
      },

      filterSearch() {
        this.set('page', 1);
        this.notifyPropertyChange('customerPayments');
      },

      sortList(column, ascending) {
        this.sortPaginatedList(column, ascending);
        this.notifyPropertyChange('customerPayments');
      },

      mobileSortList(column) {
        this.sortMobilePaginatedList(column.value);
        this.set('stSortCurrentSortColumn', null);
        this.set('stSortAscending', null);
        this.notifyPropertyChange('customerPayments');
      },

      transitionToSubroute(path) {
        this.router.transitionTo('accounting.payments.edit-payment', path);
      },

      clearPaymentsFilter() {
        Ember.setProperties(this, {
          paymentDateFrom: null,
          paymentDateTo: null,
          processingStatusIs: null,
          processingStatusIsNot: null,
          tagFilter: this.tagsService.tagFilter(),
          page: 1
        });
        Ember.notifyPropertyChange(this, 'customerPayments');
      }

    }
  });

  _exports.default = _default;
});