define("securitytrax-ember-ui/components/html/icons/icon-search/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    opened: false,
    openSearch: Ember.computed('opened', function () {
      if (this.opened) {
        return '';
      } else {
        return 'open-search';
      }
    }),
    actions: {
      searchClick() {}

    }
  });

  _exports.default = _default;
});