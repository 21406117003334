define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/monitoring-companies/integrations/cops-integration/component", ["exports", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "securitytrax-ember-ui/mixed-in-classes/monitoring-company-connection", "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/monitoring-companies/integrations/integrations-base"], function (_exports, _switchableFormStep, _monitoringCompanyConnection, _integrationsBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CopsIntegration extends _integrationsBase.default {
    constructor() {
      super(...arguments);
      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.MonitoringCompanyConnection = new _monitoringCompanyConnection.default(...arguments);
      Ember.run.later(() => {
        this.buttons(this.args.multiStepForm);
      });
    }

  }

  _exports.default = CopsIntegration;
});