define("securitytrax-ember-ui/models/order-template", ["exports", "securitytrax-ember-ui/models/base_models/order-template", "lodash"], function (_exports, _orderTemplate, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _orderTemplate.default.extend({
    allowInvoiceIcon: Ember.computed('allowInvoice', function () {
      return this.allowInvoice ? 'icn-circle-check' : '';
    }),
    allowQuoteIcon: Ember.computed('allowQuote', function () {
      return this.allowQuote ? 'icn-circle-check' : '';
    }),
    allowWorkOrderIcon: Ember.computed('allowWorkOrder', function () {
      return this.allowWorkOrder ? 'icn-circle-check' : '';
    }),
    defaultMmrsText: Ember.computed('defaultMmrs.[]', function () {
      var text = '';
      var amounts = Ember.A();
      return Ember.RSVP.resolve(this.defaultMmrs).then(mmrs => {
        if (mmrs.length > 1) {
          for (var i = 0; i < mmrs.length; i++) {
            amounts.push(Number.parseFloat(mmrs.objectAt(i).get('amount')));
          }

          text = '$' + _lodash.default.min(amounts).toFixed(2) + ' - ' + '$' + _lodash.default.max(amounts).toFixed(2);
        } else {
          text = '$' + mmrs.objectAt(0).get('amount');
        }

        return text;
      });
    })
  });

  _exports.default = _default;
});