define("securitytrax-ember-ui/components/html/st-btn-add-remove/component", ["exports", "securitytrax-ember-ui/utils/obj-helpers", "ember-concurrency"], function (_exports, _objHelpers, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dataTestId: null,
    disabled: undefined,
    isSelected: undefined,
    noBackground: undefined,
    useButtonPromises: undefined,
    tagName: '',

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        disabled: false,
        isSelected: false,
        noBackground: false,
        usButtonPromises: false
      });
    },

    didReceiveAttrs() {
      this._super(...arguments);

      if (this.addAction == null || this.removeAction == null) {
        (false && Ember.warn('You must pass in an addAction and a RemoveAction', {
          id: 'components.html.st-btn-add-remove.didReceiveAttrs'
        }));
      }
    },

    addRemoveTask: (0, _emberConcurrency.task)(function* () {
      var isSelected = this.isSelected;

      if (isSelected) {
        yield this.removeAction.call(this);
        this.set('isSelected', false);
      } else {
        yield this.addAction.call(this);
        this.set('isSelected', true);
      }

      return;
    }),
    actions: {
      addRemoveAction() {
        //default behavior: use st-button with promises, that will show spinner, and green on success, red on rejected promise
        if (this.useButtonPromises) {
          return this.addRemoveTask.perform();
        } else {
          var isSelected = this.isSelected;

          if (isSelected) {
            this.set('isSelected', false);
            this.removeAction.call(this);
          } else {
            this.set('isSelected', true);
            this.addAction.call(this);
          }
        }
      }

    }
  });

  _exports.default = _default;
});