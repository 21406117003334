define("securitytrax-ember-ui/components/multi-step-form/forms/system/adt/steps/order-status/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/system/adt/adt-form-base", "ember-concurrency"], function (_exports, _adtFormBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adtFormBase.default.extend({
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    centralStationService: Ember.inject.service(),
    refreshAdtOrderStatusTask: (0, _emberConcurrency.task)(function* () {
      var centralStationService = this.centralStationService;
      this.set('adtStepReady', false);

      try {
        var customer = yield this.customer;
        var orderStatus = yield centralStationService.getData({
          model: customer,
          method: 'adt__getOrderStatus',
          responseKey: 'order_status'
        });
        this.set('orderStatus', orderStatus);
      } catch (err) {
        (false && Ember.warn('failed to reload order status' + err, {
          id: 'multiStepForm.forms.system.adt.orderStatus.refreshAdtOrderStatusTask'
        }));
      }

      this.set('adtStepReady', true);
    }).on('init'),
    canSubmit: Ember.computed('orderStatus', function () {
      return Number(this.get('orderStatus.status_code')) === 16;
    }),
    canCancel: Ember.computed(function () {
      return Number(this.get('orderStatus.status_code')) !== 10;
    }),
    holdReasons: Ember.computed('orderStatus', function () {
      return this.holdReasonsTask.perform();
    }),
    holdReasonsTask: (0, _emberConcurrency.task)(function* () {
      var filteredReasons = Ember.A([]);

      try {
        var holdReasons = yield this.get('orderStatus.hold_reasons');

        if (holdReasons && Array.isArray(holdReasons)) {
          for (var i = 0; i < holdReasons.length; i++) {
            var currHoldReason = yield holdReasons.objectAt(i);

            if (currHoldReason.status != 'Resolved') {
              filteredReasons.pushObject(currHoldReason);
            }
          }
        }
      } catch (error) {
        (false && Ember.warn('error getting hold reasons' + error, {
          id: 'multiStepForm.forms.system.adt.orderStatus'
        }));
      }

      return filteredReasons;
    }).drop().maxConcurrency(1),
    submitAdtCustomerOrderTask: (0, _emberConcurrency.task)(function* () {
      var messages = [];
      var centralStationService = this.centralStationService;

      try {
        var response = yield centralStationService.postData({
          model: this.customer,
          method: 'adt__initConfirmOrder'
        });
        messages = messages.concat(response.responsePayload.data.messages);
      } catch (response) {
        this.stNotificationManager.addErrorMessages(response, {
          notificationNamespace: 'adtMegaFormModal'
        });
        throw response;
      }

      if (messages.length > 0) {
        this.stNotificationManager.addWarningMessages(messages, {
          notificationNamespace: 'adtMegaFormModal'
        });
      }

      this.refreshAdtOrderStatusTask.perform();
    }),
    cancelAdtCustomerOrderTask: (0, _emberConcurrency.task)(function* () {
      var messages = [];
      var centralStationService = this.centralStationService;

      try {
        var response = yield centralStationService.postData({
          model: this.customer,
          method: 'adt__cancelOrder'
        });
        messages = messages.concat(response.responsePayload.data.messages);
      } catch (response) {
        this.stNotificationManager.addErrorMessages(response, {
          notificationNamespace: 'adtMegaFormModal'
        });
      }

      if (messages.length > 0) {
        this.stNotificationManager.addWarningMessages(messages, {
          notificationNamespace: 'adtMegaFormModal'
        });
      }

      yield this.refreshAdtOrderStatusTask.perform();
    })
  });

  _exports.default = _default;
});