define("securitytrax-ember-ui/models/base_models/company", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    name: (0, _model.attr)('string', {
      'readOnly': false
    }),
    emailCampaignFromName: (0, _model.attr)('string', {
      'readOnly': false
    }),
    emailCampaignFromEmail: (0, _model.attr)('string', {
      'readOnly': false
    }),
    corporateFromName: (0, _model.attr)('string', {
      'readOnly': false
    }),
    corporateFromEmail: (0, _model.attr)('string', {
      'readOnly': false
    }),
    dealerNumber: (0, _model.attr)('string', {
      'readOnly': false
    }),
    status: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['active', 'disabled']
    }),
    globalMessage: (0, _model.attr)('string', {
      'readOnly': false
    }),
    street: (0, _model.attr)('string', {
      'readOnly': false
    }),
    city: (0, _model.attr)('string', {
      'readOnly': false
    }),
    state: (0, _model.attr)('string', {
      'readOnly': false
    }),
    zip: (0, _model.attr)('string', {
      'readOnly': false
    }),
    phone: (0, _model.attr)('string', {
      'readOnly': false
    }),
    paymentsDefaultEmailReceipt: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    dataDefaults: (0, _model.attr)('json', {
      'readOnly': false
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    notificationSubscriptions: (0, _model.hasMany)('notification-subscription', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    companyLicenses: (0, _model.hasMany)('company-license', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});