define("securitytrax-ember-ui/components/nav/report-left-nav/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jYm9fqP0",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"nav-item\"],[12],[1,[30,[36,0],[\"icn-home\"],null]],[1,[30,[36,1],[\"pages.reports.nav.home\"],null]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"nav-item\"],[12],[1,[30,[36,0],[\"icn-feature-users\"],null]],[1,[30,[36,1],[\"pages.reports.nav.customers\"],null]],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"svg-icon\",\"t\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/nav/report-left-nav/template.hbs"
    }
  });

  _exports.default = _default;
});