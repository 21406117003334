define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/customer-base", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency"], function (_exports, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    stNotificationManager: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    grants: Ember.inject.service(),
    recentSearchesService: Ember.inject.service(),
    showWarnings: true,
    currFormName: 'customerCreateWizard',
    isAdtCompany: Ember.computed(function () {
      return this.get('sessionData.companyHasSpecificMonitorTask').perform();
    }),
    deleteCustomer: (0, _emberConcurrency.task)(function* (customerId = null, buttonPromise) {
      if (this.customer) {
        yield this.customer.destroyRecordWithRollbackTask.perform();
        this.router.transitionTo('customer-list');
      } else {
        var customer = yield this.store.findRecord('customer', customerId);
        yield customer.destroyRecordWithRollbackTask.perform();
        this.multiStepForm.formStartedSetVal(false);
      }

      buttonPromise.then(() => {
        var id = customerId || this.customer.get('id');
        this.recentSearchesService.removeRecents('recentCustomers', [id]);
        this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
      });
    }),

    openConfirmDelete(stepKey) {
      var deletingData = {
        currentStep: stepKey,
        customerId: this.multiStepForm.formOriginalModelsGetVal('customer.model.id'),
        customerName: this.multiStepForm.formOriginalModelsGetVal('customer.model.flname')
      };
      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('confirmDeleteCustomer', 'currentDeletingCustomer', deletingData);
      multiStepForm.currentStepNameKeySetVal('confirmDeleteCustomer');
    },

    advanceForm(multiStepForm, stepKey, stepIndex, customer) {
      if (stepIndex == 1) {
        var successKey = this.newCustomer ? 'common.notifications.save_successful' : 'common.nav.create.customer.success';
        var grantsService = this.grants;

        if (grantsService.getIndividualGrant('customer_credit_checks.create') && grantsService.getIndividualGrant('customer_credit_checks.view')) {
          multiStepForm.currentStepNameKeySetVal('step2');
          multiStepForm.progressBarConfig.activeIndex = 1;
        } else if (grantsService.getIndividualGrant('customer_order_information.modify') && grantsService.getIndividualGrant('customer_order_information.view')) {
          multiStepForm.currentStepNameKeySetVal('step3');
          multiStepForm.progressBarConfig.activeIndex = 2;
        } else {
          this.closeForm(multiStepForm, customer);
        }

        this.stNotificationManager.addSuccessKey(successKey, 'modal');
      } else {
        Ember.set(multiStepForm, 'currentStepNameKey', stepKey);
        multiStepForm.progressBarConfig.activeIndex = stepIndex;
        Ember.set(multiStepForm.progressBarConfig.items.objectAt(stepIndex - 1), 'isComplete', true);
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'createCustomerModal');
      }
    },

    closeForm(multiStepForm, customer) {
      multiStepForm.formStartedSetVal(false);

      if (this.grants.getIndividualGrant('customers.view')) {
        this.transitionToCustomer(customer);
      }

      this.stNotificationManager.addSuccessKey('common.notifications.customer.created');
    }

  });

  _exports.default = _default;
});