define("securitytrax-ember-ui/models/parent-entity-invoice-statements-recurring", ["exports", "securitytrax-ember-ui/models/base_models/parent-entity-invoice-statements-recurring"], function (_exports, _parentEntityInvoiceStatementsRecurring) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _parentEntityInvoiceStatementsRecurring.default.extend({});

  _exports.default = _default;
});