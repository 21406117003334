define("securitytrax-ember-ui/components/multi-step-form/forms/leads/central-station/component", ["exports", "securitytrax-ember-ui/utils/obj-helpers", "ember-concurrency", "securitytrax-ember-ui/mixins/forms/customer-lead-shared"], function (_exports, _objHelpers, _emberConcurrency, _customerLeadShared) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_customerLeadShared.default, {
    entityListService: Ember.inject.service('events/entity-list'),
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    entity: undefined,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        entity: 'lead'
      });
    },

    monitoringCompanies: Ember.computed(function () {
      return this.store.findAll('monitoringCompany', {
        include: 'monitoring_company_type'
      });
    }),
    dealerNumbers: Ember.computed(function () {
      return this.store.findAll('dealerNumber');
    }),
    cancelTask: (0, _emberConcurrency.task)(function* (buttonPromise) {
      var multiStepForm = this.multiStepForm;
      multiStepForm.validationErrors = {};
      yield multiStepForm.setupCopyModels.perform();
      buttonPromise.then(() => {
        this.set('formMode', 'view');
        this.stNotificationManager.addSuccessKey('common.status.canceled');
      });
    }),
    saveCentralStationTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      try {
        yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);
        yield multiStepForm.validateSyncSaveAll({
          extraValidationData: {
            noValidate: ['location', 'saleDate', 'leadInformation-leadStatus', 'customer-zip', 'customer-address1']
          },
          notificationNamespace: 'global'
        });
        buttonPromise.then(() => {
          this.set('formMode', 'view');
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        });
      } catch (error) {
        (false && Ember.warn('error in save central station for Lead Task: ' + error, {
          id: 'multiStepForm.forms.leads.information.createLead'
        }));
        this.stNotificationManager.addErrorMessages(error, {
          showFallbackMessage: false
        });
        throw error;
      }
    }),
    actions: {
      saveCentralStation(buttonPromise) {
        var multiStepForm = this.multiStepForm;
        return this.saveCentralStationTask.perform(multiStepForm, buttonPromise);
      },

      submitAdtPreCheck() {
        var lead = this.multiStepForm.formOriginalModels.customer.model;
        return this.submitAdtPreCheck.perform(lead, 'global');
      }

    }
  });

  _exports.default = _default;
});