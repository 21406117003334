define("securitytrax-ember-ui/components/pages/all-appointments/component", ["exports", "@glimmer/component", "ember-concurrency", "securitytrax-ember-ui/mixed-in-classes/table-column-sort"], function (_exports, _component, _emberConcurrency, _tableColumnSort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var AllAppointments = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._tracked, _dec21 = Ember._tracked, _dec22 = Ember._tracked, _dec23 = Ember._tracked, _dec24 = Ember._tracked, _dec25 = (0, _emberConcurrency.task)(function* () {
    var hashedData = yield (0, _emberConcurrency.hash)({
      calendarEventTypeOptions: this.store.findAll('calendarEventType'),
      calendarEventDetailTypeOptions: this.store.findAll('calendarEventDetailType'),
      userOptions: this.store.findAll('calendarUser'),
      tagFilter: this.tagsService.tagFilter()
    });
    Ember.setProperties(this, hashedData);
  }), _dec26 = Ember.computed, _dec27 = (0, _emberConcurrency.task)(function* () {
    var newFilters = yield this.stFiltersService.getFiltersByGroupKey('companyCalendarEvents');
    var calendarEvents = yield this.store.query('calendarEvent', {
      filter: newFilters,
      limit: this.limit,
      page: this.page,
      sort: this.TableColumnSort.stSortString,
      include: 'calendar_user,calendar_event_type,calendar_event_detail_type,customer,tags',
      count: 1
    });

    if (calendarEvents.length > 0) {
      var successNotifications = [];
      var errorNotifications = [];
      var multicallSubkeys = {};
      var count = 0;

      for (var x = 0; x < calendarEvents.length; x++) {
        var event = calendarEvents.objectAt(x);
        count = ++count;
        var multicallSubkey = "".concat(event.jsonParseId.calendar_category, "_").concat(event.jsonParseId.id, "_event_on_site_info_subkey");
        multicallSubkeys[multicallSubkey] = true;
      }

      this.stMulticallService.clearMulticallData('company_calendar_event_on_site_info');
      yield this.stMulticallService.setupMulticall({
        multicallKey: 'company_calendar_event_on_site_info',
        multicallSubkeys
      }, () => {
        for (var _x = 0; _x < calendarEvents.length; _x++) {
          var _event = calendarEvents.objectAt(_x);

          var _multicallSubkey = "".concat(_event.jsonParseId.calendar_category, "_").concat(_event.jsonParseId.id, "_event_on_site_info_subkey");

          this.createOnSiteMulticall(_event, {
            multicallKey: 'company_calendar_event_on_site_info',
            multicallSubkey: _multicallSubkey
          }, successNotifications, errorNotifications);
        }
      });
    }

    Ember.set(this, 'totalCount', calendarEvents.meta.record_count);
    return calendarEvents;
  }), _dec28 = Ember._action, _dec29 = Ember._action, _dec30 = Ember._action, _dec31 = Ember._action, _dec32 = Ember._action, _dec33 = Ember._action, (_class = (_temp = class AllAppointments extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "enhancedFeatures", _descriptor3, this);

      _initializerDefineProperty(this, "stFiltersService", _descriptor4, this);

      _initializerDefineProperty(this, "router", _descriptor5, this);

      _initializerDefineProperty(this, "tagsService", _descriptor6, this);

      _initializerDefineProperty(this, "stMulticallService", _descriptor7, this);

      _initializerDefineProperty(this, "column", _descriptor8, this);

      _initializerDefineProperty(this, "ascending", _descriptor9, this);

      _initializerDefineProperty(this, "limit", _descriptor10, this);

      _initializerDefineProperty(this, "page", _descriptor11, this);

      _initializerDefineProperty(this, "totalCount", _descriptor12, this);

      _initializerDefineProperty(this, "calendarCategories", _descriptor13, this);

      _initializerDefineProperty(this, "calendarEventTypes", _descriptor14, this);

      _initializerDefineProperty(this, "calendarEventDetailTypes", _descriptor15, this);

      _initializerDefineProperty(this, "users", _descriptor16, this);

      _initializerDefineProperty(this, "customerName", _descriptor17, this);

      _initializerDefineProperty(this, "createdDateFrom", _descriptor18, this);

      _initializerDefineProperty(this, "createdDateTo", _descriptor19, this);

      _initializerDefineProperty(this, "startDateFrom", _descriptor20, this);

      _initializerDefineProperty(this, "startDateTo", _descriptor21, this);

      _initializerDefineProperty(this, "checkInStatuses", _descriptor22, this);

      _initializerDefineProperty(this, "checkOutStatuses", _descriptor23, this);

      _initializerDefineProperty(this, "tagFilter", _descriptor24, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor25, this);

      _initializerDefineProperty(this, "getCalendarEventsTask", _descriptor26, this);

      var sortableColumns = [{
        name: this.intl.t('common.columns.id'),
        value: 'id'
      }, {
        name: this.intl.t('common.columns.type'),
        value: 'calendar_event_type.name'
      }, {
        name: this.intl.t('common.columns.customer'),
        value: 'customer.lname'
      }, {
        name: this.intl.t('common.nav.user'),
        value: 'calendar_user.lname'
      }, {
        name: this.intl.t('common.columns.date'),
        value: 'start_time'
      }, {
        name: this.intl.t('common.columns.date_added'),
        value: 'created_at'
      }];
      this.TableColumnSort = new _tableColumnSort.default(...arguments, sortableColumns);
      this.TableColumnSort.sortPaginatedList('created_at', false);
      this.initDataTask.perform();
    }

    get calendarCategoryOptions() {
      var calendarCategories = [];
      calendarCategories.push(Ember.Object.create({
        name: this.intl.t('pages.administration.content_management.appointment_detail_types.technician'),
        value: 'tech_schedule'
      }));
      calendarCategories.push(Ember.Object.create({
        name: this.intl.t('pages.administration.content_management.appointment_detail_types.sales'),
        value: 'sale_schedule'
      }));
      return calendarCategories;
    }

    get checkInOutStatusOptions() {
      return [Ember.Object.create({
        name: this.intl.t('pages.calendar.late'),
        value: 'late'
      }), Ember.Object.create({
        name: this.intl.t('pages.calendar.on_time'),
        value: 'on_time'
      }), Ember.Object.create({
        name: this.intl.t('pages.calendar.future'),
        value: 'future'
      })];
    }

    get calendarEvents() {
      return this.getCalendarEventsTask.perform();
    }

    createOnSiteMulticall(event, multicallOptions, successNotifications, errorNotifications) {
      try {
        this.stMulticallService.registerRequest(multicallOptions, event.invokeAction('calendar_event_on_site_info', {
          verb: 'POST',
          data: {
            data: {}
          },
          processResponse: false,
          multicallOptions: multicallOptions
        }));
        successNotifications.push(this.intl.t('pages.customers.appointments.on_site_info.success_message'));
      } catch (err) {
        errorNotifications.push(this.intl.t('pages.customers.appointments.on_site_info.success_message'));
      }
    }

    sortList(column, ascending) {
      this.TableColumnSort.sortPaginatedList(column, ascending);
      Ember.notifyPropertyChange(this, 'calendarEvents');
    }

    mobileSortList(column) {
      this.TableColumnSort.sortMobilePaginatedList(column.value);
      Ember.notifyPropertyChange(this, 'calendarEvents');
    }

    filterSearch(setPage = true) {
      if (setPage) {
        Ember.set(this, 'page', 1);
      }

      Ember.notifyPropertyChange(this, 'calendarEvents');
    }

    clearFilters() {
      Ember.setProperties(this, {
        page: 1,
        calendarCategories: undefined,
        calendarEventTypes: undefined,
        calendarEventDetailTypes: undefined,
        users: undefined,
        customerName: undefined,
        createdDateFrom: undefined,
        createdDateTo: undefined,
        startDateFrom: undefined,
        startDateTo: undefined,
        checkInStatuses: undefined,
        checkOutStatuses: undefined,
        tagFilter: this.tagsService.tagFilter()
      });
      Ember.notifyPropertyChange(this, 'calendarEvents');
    }

    transitionToSubroute(subroute, path) {
      if (path && typeof path === 'string') {
        this.router.transitionTo(subroute, path);
      } else {
        this.router.transitionTo(subroute);
      }
    }

    filterStartTime() {
      if (this.startDateFrom || this.startDateTo) {
        var filter = 'start_time ';

        if (this.startDateFrom) {
          filter = filter + ">= ".concat(this.startDateFrom.unix());

          if (this.startDateTo) {
            filter = filter + " and start_time <= ".concat(this.startDateTo.unix());
          }
        } else {
          if (this.startDateTo) {
            filter = filter + "<= ".concat(this.startDateTo.unix());
          }
        }

        return filter;
      }

      return false;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "enhancedFeatures", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stFiltersService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "tagsService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "stMulticallService", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "column", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "ascending", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "limit", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 50;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "totalCount", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "calendarCategories", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "calendarEventTypes", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "calendarEventDetailTypes", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "users", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "customerName", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "createdDateFrom", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "createdDateTo", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "startDateFrom", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "startDateTo", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "checkInStatuses", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "checkOutStatuses", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "tagFilter", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "calendarEvents", [_dec26], Object.getOwnPropertyDescriptor(_class.prototype, "calendarEvents"), _class.prototype), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "getCalendarEventsTask", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "sortList", [_dec28], Object.getOwnPropertyDescriptor(_class.prototype, "sortList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mobileSortList", [_dec29], Object.getOwnPropertyDescriptor(_class.prototype, "mobileSortList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterSearch", [_dec30], Object.getOwnPropertyDescriptor(_class.prototype, "filterSearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilters", [_dec31], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilters"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transitionToSubroute", [_dec32], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToSubroute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterStartTime", [_dec33], Object.getOwnPropertyDescriptor(_class.prototype, "filterStartTime"), _class.prototype)), _class));
  _exports.default = AllAppointments;
});