define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/forms/single-page/information/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/information/component", "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/information/template"], function (_exports, _component, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    layout: _template.default,
    setupStep1Buttons: function () {
      var multiStepForm = this.multiStepForm;

      if (this.isNewRecord) {
        multiStepForm.registerStepData('step1', 'buttons', [{
          text: this.intl.t('common.actions.save'),
          classNamesString: 'st-btn st-btn-primary customer-information-save-button',
          positionOptions: 'up left',
          click: buttonPromise => {
            return this.saveCustomerTask.perform(multiStepForm, buttonPromise, true);
          }
        }]);
      } else {
        multiStepForm.registerStepData('step1', 'buttons', [{
          text: this.intl.t('common.actions.cancel'),
          click: () => {
            this.multiStepForm.resetForm(false); //reset form

            this.set('formMode', 'create');
          },
          classNamesString: 'st-btn customer-information-cancel-button'
        }, {
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveCustomerTask.perform(multiStepForm, buttonPromise, true);
          },
          classNamesString: 'st-btn st-btn-primary customer-information-save-button'
        }]);
      }
    }
  });

  _exports.default = _default;
});