define("securitytrax-ember-ui/models/base_models/notification", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    name: (0, _model.attr)('string', {
      'readOnly': false
    }),
    description: (0, _model.attr)('string', {
      'readOnly': false
    }),
    category: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['tech', 'sales', 'user', 'inventory']
    }),
    subCategory: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['appointment', 'customer_installation', 'running_late', 'arrival_departure', 'customer_status', 'lead', 'sale_made', 'salesperson', 'general', 'ticket', 'time_off', 'payment_processing', 'work_order', 'contract_expiring']
    }),
    compatibility: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['v2', 'v3', 'all']
    }),
    canEmail: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    canSms: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    webTemplate: (0, _model.attr)('string', {
      'readOnly': false
    }),
    emailTemplate: (0, _model.attr)('string', {
      'readOnly': false
    }),
    smsTemplate: (0, _model.attr)('string', {
      'readOnly': false
    }),
    jsonTemplate: (0, _model.attr)('string', {
      'readOnly': false
    }),
    severity: (0, _model.attr)('number', {
      'readOnly': false
    }),
    visibility: (0, _model.attr)('number', {
      'readOnly': false
    }),
    status: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['active', 'deleted']
    })
  });

  _exports.default = _default;
});