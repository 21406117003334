define("securitytrax-ember-ui/models/customer-birthdate", ["exports", "securitytrax-ember-ui/models/base_models/customer-birthdate"], function (_exports, _customerBirthdate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Customer Birthdate
   *
   * @class CustomerBirthdate
   * @extends model.BaseCustomerBirthdate
   * @memberof model
   */
  var _default = _customerBirthdate.default.extend({});

  _exports.default = _default;
});