define("securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-work-orders/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    CalendarEventsService: Ember.inject.service('events/calendar-events'),
    workOrderEvent: Ember.inject.service('events/work-order-event'),
    quickAdd: Ember.inject.service(),
    workOrder: null,
    customer: null,
    currentStepNameKey: 'details',
    initData: (0, _emberConcurrency.task)(function* () {
      var currentRoute = this.get('router.currentRouteName');

      if (this.workOrderId !== 'create') {
        var _this$customer;

        this.set('workOrder', yield this.store.findRecord('workOrder', this.workOrderId, {
          include: 'customer'
        }));
        this.set('customer', (_this$customer = this.customer) !== null && _this$customer !== void 0 ? _this$customer : this.get('workOrder.customer'));
      }

      if (currentRoute.includes('edit-create-appointment')) {
        this.set('currentStepNameKey', currentRoute.includes('/create/') ? 'addAppointment' : 'appointments');
      }

      this.set('formStarted', true);
    }).on('init'),
    workOrderFormMode: Ember.computed('workOrder', function () {
      if (this.grants.getIndividualGrant('work_orders.create') && !this.workOrder) {
        return 'create';
      } else if (this.grants.getIndividualGrant('work_orders.modify') && this.workOrder) {
        return 'edit';
      } else {
        return 'view';
      }
    }),
    workOrderFormSteps: Ember.computed('grants.userGrants', 'workOrder', function () {
      var intl = this.intl;
      return Ember.Object.create({
        details: Ember.Object.create({
          stepNameKey: 'details',
          title: intl.t('pages.customers.workOrders.details.workOrderDetails', {
            name: this.customer.get('flname')
          }),
          progressBarTitle: intl.t('pages.customers.workOrders.details.title'),
          stepComponentPath: 'multi-step-form/forms/work-orders/details'
        }),
        addAppointment: Ember.Object.create({
          stepNameKey: 'addAppointment',
          title: intl.t('pages.customers.workOrders.details.workOrderDetails', {
            name: this.customer.get('flname')
          }),
          progressBarTitle: intl.t('pages.customers.workOrders.addAppointment.title'),
          stepComponentPath: 'multi-step-form/forms/work-orders/add-appointment',
          granted: this.grants.getIndividualGrant('calendar_events.create') && this.grants.getIndividualGrant('calendar_events.view')
        }),
        appointments: Ember.Object.create({
          stepNameKey: 'appointments',
          title: intl.t('pages.customers.workOrders.details.workOrderDetails', {
            name: this.customer.get('flname')
          }),
          progressBarTitle: intl.t('pages.customers.workOrders.appointments.title'),
          stepComponentPath: 'multi-step-form/forms/work-orders/appointments',
          granted: this.grants.getIndividualGrant('calendar_events.view')
        }),
        send: Ember.Object.create({
          stepNameKey: 'send',
          title: intl.t('pages.customers.workOrders.details.workOrderDetails', {
            name: this.customer.get('flname')
          }),
          progressBarTitle: intl.t('pages.customers.workOrders.send.title'),
          stepComponentPath: 'multi-step-form/forms/work-orders/send-work-order'
        }),
        generatePOs: Ember.Object.create({
          stepNameKey: 'generatePOs',
          title: intl.t('pages.customers.workOrders.details.workOrderDetails', {
            name: this.customer.get('flname')
          }),
          progressBarTitle: intl.t('pages.customers.workOrders.generate_pos.title'),
          stepComponentPath: 'multi-step-form/forms/work-orders/generate-purchase_orders',
          granted: this.grants.getIndividualGrant('equipment_purchase_orders.view')
        })
      });
    }),
    workOrderFormOriginalModels: Ember.computed('workOrder', 'grants.userGrants', function () {
      var grants = this.grants;
      return Ember.Object.create({
        workOrder: {
          model: this.workOrder,
          copyRelationMap: {
            customer: {
              readOnly: true
            },
            noteType: {
              readOnly: true
            },
            workOrderType: {
              doCopy: true,
              readOnly: true
            },
            assignedUser: {
              doCopy: true,
              readOnly: true
            },
            workOrderEquipment: {
              relationships: {
                customerEquipment: {
                  doCopy: grants.getIndividualGrant('work_orders.view') && grants.getIndividualGrant('customer_equipment.view'),
                  readOnly: true
                },
                workOrderAction: {
                  readOnly: true
                }
              }
            },
            calendarEvents: {
              doCopy: grants.getIndividualGrant('calendar_events.view'),
              readOnly: true
            }
          }
        }
      });
    }),
    actions: {
      transitionBackToParentRoute() {
        this.workOrderEvent.trigger('refresh-my-work-orders');
        var transitionToRoute = this.quickAdd.closeRoute || this.parentRoute;

        if (transitionToRoute.includes('customer')) {
          this.router.transitionTo(transitionToRoute, this.get('customer.id'));
        } else {
          this.router.transitionTo(transitionToRoute);
        }
      }

    }
  });

  _exports.default = _default;
});