define("securitytrax-ember-ui/customers/appointments/check-availability/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uqYALE6q",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"customer\",\"parentRoute\",\"calendarEventId\",\"readOnly\"],[[35,0,[\"customer\"]],[35,0,[\"parentRoute\"]],\"create\",true]]]]],\"hasEval\":false,\"upvars\":[\"model\",\"modals/sub-route-modals/check-availibility\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/customers/appointments/check-availability/template.hbs"
    }
  });

  _exports.default = _default;
});