define("securitytrax-ember-ui/mixed-in-classes/tech-today", ["exports", "@glimmer/component", "ember-concurrency", "lodash"], function (_exports, _component, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /* global L */
  var TechToday = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = (0, _emberConcurrency.task)(function* () {
    var calendarEventsSorted = yield this.calendarEventsSorted;
    var summary = {
      install: 0,
      service: 0,
      removal: 0
    };

    if (calendarEventsSorted.length > 0) {
      calendarEventsSorted.forEach(event => {
        switch (event.get('calendarEventType.id')) {
          case '{"calendar_category":"tech_schedule","id":"1"}':
            //Install
            summary.install++;
            break;

          case '{"calendar_category":"tech_schedule","id":"2"}':
            //Service
            summary.service++;
            break;

          case '{"calendar_category":"tech_schedule","id":"3"}':
            //Removal
            summary.removal++;
            break;

          default:
            '';
        }
      });
    }

    return summary;
  }), _dec4 = (0, _emberConcurrency.task)(function* () {
    try {
      var calendarEventFilter = this.args.model.calendarEventFilter;
      var calendarEvents = yield this.store.query('calendar-event', {
        filter: calendarEventFilter,
        include: 'customer,work_order.customer_equipment,work_order.work_order_equipment.customer_equipment.equipment.equipment_type,work_order.work_order_equipment.work_order_action,calendar_event_type',
        sort: 'start_time'
      });
      var eventsWithoutTimeoff = [];

      for (var index = 0; index < calendarEvents.get('length'); index++) {
        var currCalendarEvent = calendarEvents.objectAt(index);
        var hashData = yield (0, _emberConcurrency.hash)({
          type: currCalendarEvent.get('calendarEventType'),
          appointmentStatusData: this.getAppointmentStatusDataTask.perform(currCalendarEvent)
        });
        var grayClass = yield this.grayClassTask.perform(hashData.appointmentStatusData);
        currCalendarEvent.set('grayClass', grayClass);
        eventsWithoutTimeoff.pushObject(currCalendarEvent);
      }

      return eventsWithoutTimeoff;
    } catch (error) {
      (false && Ember.warn('calendarEventsSortedTask ' + error, {
        id: 'techToday.calendarEventsSortedTask'
      }));
    }
  }), _dec5 = (0, _emberConcurrency.task)(function* () {
    try {
      var equipmentItems = [];
      var sortedCalendarEvents = yield this.calendarEventsSorted;

      for (var index = 0; index < sortedCalendarEvents.length; index++) {
        var currCalendarEvent = sortedCalendarEvents[index];
        var workOrder = yield currCalendarEvent.get('workOrder'); ////////////////////////////////////////////////////////////////////////////
        // group workOrderEquipment by install action and equipment type

        if (workOrder) {
          var workOrderEquipment = yield workOrder.get('workOrderEquipment');
          var equipmentGroupedByAction = {};

          for (var l = 0; l < workOrderEquipment.get('length'); l++) {
            var currTaskEquipment = workOrderEquipment.objectAt(l);
            var workOrderAction = yield currTaskEquipment.get('workOrderAction');
            var workOrderActionId = workOrderAction.get('id');
            var currCustomerEquipment = currTaskEquipment.get('customerEquipment');

            if (!equipmentGroupedByAction[workOrderActionId]) {
              equipmentGroupedByAction[workOrderActionId] = {
                items: [],
                workOrderAction: workOrderAction
              };
            }

            equipmentGroupedByAction[workOrderActionId]['items'].push(currCustomerEquipment);
          }

          var equipmentGroupedByActionKeys = Object.keys(equipmentGroupedByAction);

          for (var x = 0; x < equipmentGroupedByActionKeys.length; x++) {
            var currKey = equipmentGroupedByActionKeys[x];
            var element = equipmentGroupedByAction[currKey];
            var groupedItems = yield this.groupedItemsTask.perform(element.items);
            element.groupedItems = groupedItems;
          }

          currCalendarEvent.set('equipmentGroupedByAction', equipmentGroupedByAction);
          currCalendarEvent.set('equipmentGroupedByActionLength', equipmentGroupedByActionKeys.length); ///////////////////////////////////////////////////////////////////////////
          // combine all equipment items

          var customerEquipment = yield workOrder.get('customerEquipment');

          for (var q = 0; q < customerEquipment.get('length'); q++) {
            var _currCustomerEquipment = customerEquipment.objectAt(q);

            equipmentItems.push(_currCustomerEquipment);
          }
        }
      }

      return equipmentItems;
    } catch (error) {
      (false && Ember.warn('equipmentItemsTask ' + error, {
        id: 'techToday.equipmentItemsTask'
      }));
    }
  }), _dec6 = (0, _emberConcurrency.task)(function* () {
    try {
      var geoPoints = [];
      var sortedCalendarEvents = yield this.calendarEventsSorted;

      for (var index = 0; index < sortedCalendarEvents.length; index++) {
        var element = sortedCalendarEvents[index];
        var customer = yield element.get('customer');

        if (customer && !customer.get('hasGeolocationDisabled')) {
          var geoPoint = yield customer.get('responseMeta.geopoint');
          geoPoints.push(L.point(geoPoint.latitude, geoPoint.longitude));
        }
      }

      return geoPoints;
    } catch (error) {
      (false && Ember.warn('calendarEventSortedGeoPointsDataTask ' + error, {
        id: 'techToday.calendarEventSortedGeoPointsDataTask'
      }));
    }
  }), _dec7 = (0, _emberConcurrency.task)(function* () {
    try {
      var geoPoints = [];
      var sortedCalendarEvents = yield this.calendarEventsSorted;

      for (var index = 0; index < sortedCalendarEvents.length; index++) {
        var element = sortedCalendarEvents[index];
        var customer = yield element.get('customer');

        if (customer && !customer.get('hasGeolocationDisabled')) {
          var geoPoint = yield customer.get('responseMeta.geopoint');
          geoPoints.push({
            geoPoint: L.point(geoPoint.latitude, geoPoint.longitude),
            calendarEvent: element,
            index
          });
        }
      }

      return geoPoints;
    } catch (error) {
      (false && Ember.warn('calendarEventSortedGeoPointsDataTask ' + error, {
        id: 'techToday.calendarEventSortedGeoPointsDataTask'
      }));
    }
  }), _dec8 = (0, _emberConcurrency.task)(function* () {
    try {
      var geoPoints = [];
      var calendarEventSortedGeoPoints = yield this.calendarEventSortedGeoPoints;

      if (!calendarEventSortedGeoPoints || calendarEventSortedGeoPoints.length <= 0) {
        geoPoints.push(L.point(0, 0));
      }

      var bounds = yield L.bounds(calendarEventSortedGeoPoints && calendarEventSortedGeoPoints.length > 0 ? calendarEventSortedGeoPoints : geoPoints);
      var bottomLeft = bounds.getBottomLeft();
      var topRight = bounds.getTopRight();
      var corner1 = L.latLng(bottomLeft.x, bottomLeft.y);
      var corner2 = L.latLng(topRight.x, topRight.y);
      var cornerBounds = L.latLngBounds(corner1, corner2);
      return cornerBounds;
    } catch (error) {
      (false && Ember.warn('calendarEventSortedGeoPointBoundsTask ' + error, {
        id: 'techToday.calendarEventSortedGeoPointBoundsTask'
      }));
    }
  }), _dec9 = (0, _emberConcurrency.task)(function* () {
    try {
      var calendarEventSortedGeoPoints = yield this.calendarEventSortedGeoPoints;

      if (!calendarEventSortedGeoPoints || calendarEventSortedGeoPoints.length <= 0) {
        return false;
      }

      var polylineLocations = calendarEventSortedGeoPoints.map(point => ({
        lat: point.x,
        lng: point.y
      }));
      return polylineLocations;
    } catch (error) {
      (false && Ember.warn('calendarEventSortedGeoPointPolylineTask ' + error, {
        id: 'techToday.calendarEventSortedGeoPointPolylineTask'
      }));
    }
  }), _dec10 = (0, _emberConcurrency.task)(function* (currCalendarEvent) {
    try {
      var currCalendarEventResolved = yield currCalendarEvent;
      var appointmentStatusData = yield currCalendarEventResolved.invokeAction('calendar_event_on_site_info', {
        verb: 'POST',
        data: {
          data: {}
        }
      }); // const data = {
      //     data: {
      //         en_route: 0,
      //         check_in: 0,
      //         check_out: 0,
      //         duration: 0,
      //         on_time_arrival: true,
      //         on_time_depart: true
      //     }
      // };

      return appointmentStatusData;
    } catch (error) {
      this.stNotificationManager.addErrorMessages(error);
      (false && Ember.warn('getAppointmentStatusDataTask ' + error, {
        id: 'techToday.getAppointmentStatusDataTask'
      }));
    }
  }), _dec11 = (0, _emberConcurrency.task)(function* (appointmentStatusData) {
    try {
      var resolvedAppointmentStatusData = yield appointmentStatusData;

      var checkedOut = _lodash.default.get(resolvedAppointmentStatusData, 'check_out', false);

      if (checkedOut && checkedOut > 0) {
        return 'checked-out-gray';
      }

      return '';
    } catch (error) {
      (false && Ember.warn('grayClassTask ' + error, {
        id: 'techToday.grayClassTask'
      }));
    }
  }), (_class = (_temp = class TechToday extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "calendarEventTypesSummaryTask", _descriptor3, this);

      _initializerDefineProperty(this, "calendarEventsSortedTask", _descriptor4, this);

      _initializerDefineProperty(this, "equipmentItemsTask", _descriptor5, this);

      _initializerDefineProperty(this, "calendarEventSortedGeoPointsTask", _descriptor6, this);

      _initializerDefineProperty(this, "calendarEventSortedGeoPointsDataTask", _descriptor7, this);

      _initializerDefineProperty(this, "calendarEventSortedGeoPointBoundsTask", _descriptor8, this);

      _initializerDefineProperty(this, "calendarEventSortedGeoPointPolylineTask", _descriptor9, this);

      _initializerDefineProperty(this, "getAppointmentStatusDataTask", _descriptor10, this);

      _initializerDefineProperty(this, "grayClassTask", _descriptor11, this);
    }

    get calendarEventTypesSummary() {
      return this.calendarEventTypesSummaryTask.perform();
    }

    get items() {
      return this.equipmentItemsTask.perform();
    }

    calendarEventSortedGeoPoints() {
      return this.calendarEventSortedGeoPointsTask.perform();
    }

    get calendarEventSortedGeoPointsData() {
      return this.calendarEventSortedGeoPointsDataTask.perform();
    }

    get calendarEventSortedGeoPointBounds() {
      return this.calendarEventSortedGeoPointBoundsTask.perform();
    }

    get calendarEventSortedGeoPointPolyline() {
      return this.calendarEventSortedGeoPointPolylineTask.perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "calendarEventTypesSummaryTask", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "calendarEventsSortedTask", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "equipmentItemsTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "calendarEventSortedGeoPointsTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "calendarEventSortedGeoPointsDataTask", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "calendarEventSortedGeoPointBoundsTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "calendarEventSortedGeoPointPolylineTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "getAppointmentStatusDataTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "grayClassTask", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = TechToday;
});