define("securitytrax-ember-ui/customers/accounting/payments-financing/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lkPDoyF4",
    "block": "{\"symbols\":[],\"statements\":[[8,\"pages/accounting/payments/financing\",[],[[\"@model\"],[[34,0]]],null],[2,\"\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"model\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/customers/accounting/payments-financing/template.hbs"
    }
  });

  _exports.default = _default;
});