define("securitytrax-ember-ui/components/multi-step-form/forms/system/api/create-edit-account/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms/system/api/api-base", "securitytrax-ember-ui/mixins/customer-integrations-mixin"], function (_exports, _emberConcurrency, _apiBase, _customerIntegrationsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _apiBase.default.extend(_customerIntegrationsMixin.default, {
    centralStationService: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    showUnlinkConfirmModal: false,
    showZoneEditModal: false,
    customerZone: null,

    registerStepButtons() {
      var multiStepForm = this.multiStepForm;
      var buttons = [];

      if (this.formMode === 'create') {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.createAccountTask.perform(multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary api-create-account-button'
        });
      }

      multiStepForm.registerStepData('general', 'buttons', buttons);
    },

    didInsertElement() {
      this._super(...arguments);

      this.registerStepButtons();
    },

    apiCustomerInformation: Ember.computed('formMode', function () {
      return this.getApiCustomerInformation.perform();
    }),
    getApiCustomerInformation: (0, _emberConcurrency.task)(function* () {
      if (this.formMode === 'create') {
        return null;
      } else {
        try {
          var hashedData = yield (0, _emberConcurrency.hash)({
            customerInformation: this.centralStationService.getData({
              model: this.customer,
              method: 'api__getAccountInfo'
            }),
            contactInformation: this.centralStationService.getData({
              model: this.customer,
              method: 'api__getAccountContactInfo'
            })
          });
          return hashedData;
        } catch (err) {
          this.stNotificationManager.addErrorMessages(err, {
            notificationNamespace: 'modal'
          });
        }
      }
    }),
    customerZones: Ember.computed('formMode', function () {
      return this.getCustomerZonesTask.perform();
    }),
    getCustomerZonesTask: (0, _emberConcurrency.task)(function* () {
      if (this.formMode === 'create') {
        var customerEquipment = yield this.customer.get('customerEquipment');
        var uniqueZoneIds = [];
        var uniqueZones = [];
        customerEquipment.forEach(equipmentPiece => {
          if ((equipmentPiece.zoneNumber || equipmentPiece.zone) && !uniqueZoneIds.includes(equipmentPiece.zoneNumber)) {
            Ember.set(equipmentPiece, 'zone_number', equipmentPiece.zoneNumber);
            uniqueZones.push(equipmentPiece);
            uniqueZoneIds.push(equipmentPiece.zoneNumber);
          }
        });
        return uniqueZones;
      } else {
        var zoneInformation = yield this.centralStationService.getData({
          model: this.customer,
          method: 'api__getAccountZoneInfo'
        });
        var zonesWithExtraObjectLayer = zoneInformation.account_zone_info || [];
        return zonesWithExtraObjectLayer.map(zone => {
          return {
            zone_number: zone.account_zone.ZoneID.replace(/^0*/, '').replace(/\?/, '').trim(),
            zone: zone.account_zone.Desc,
            zone_event_type: zone.account_zone.EvtTyp
          };
        });
      }
    }),
    zoneEventIds: Ember.computed(function () {
      return this.getZoneEventIds.perform();
    }),
    getZoneEventIds: (0, _emberConcurrency.task)(function* () {
      var zones = yield this.centralStationService.getData({
        model: this.monitoringCompany,
        method: 'api__getZoneEventTypes'
      });
      var eventIds = zones.zone_event_types.map(outerZoneEventTypeKey => {
        return {
          id: outerZoneEventTypeKey.zone_event_type.id,
          name: "".concat(outerZoneEventTypeKey.zone_event_type.name, " (").concat(outerZoneEventTypeKey.zone_event_type.id, ")")
        };
      });
      return eventIds;
    }),
    computedHover: Ember.computed('formMode', function () {
      if (this.formMode === 'create') {
        return 'no-hover';
      } else {
        return '';
      }
    }),
    editZoneModalButtons: Ember.computed(function () {
      var buttons = [{
        text: this.intl.t('common.actions.save'),
        classNamesString: 'st-btn st-btn-primary save-eventType',
        click: buttonPromise => {
          return this.saveZoneEventType.perform(buttonPromise);
        }
      }];
      return buttons;
    }),
    createAccountTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      // Save to SecurityTrax
      var formattedObjects = Ember.A();
      formattedObjects.pushObject(Ember.Object.create({
        model: {
          accountNumber: multiStepForm.formCopiedModels.customer.model.customerSystemInformation.accountNum
        },
        filePath: 'central-station/api-create'
      }));
      yield multiStepForm.validateList.perform(formattedObjects, {
        notificationNamespace: 'modal'
      });
      yield multiStepForm.validateSyncSaveAll({
        notificationNamespace: 'modal',
        extraValidationData: {
          noValidate: ['location']
        }
      }); // to API

      try {
        var customerZones = yield this.customerZones;
        var cleanedCustomerZones = customerZones.map(zone => {
          return {
            zone_number: zone.zone_number,
            zone_event_type: zone.zone_event_type
          };
        });
        yield this.centralStationService.postData({
          model: this.customer,
          method: 'api__createAccount',
          payload: {
            zones: cleanedCustomerZones
          }
        });
        buttonPromise.then(() => {
          this.set('formMode', 'edit');
          this.store.findRecord('customerSystemInformation', this.get('customer.id')).then(systemInfo => {
            systemInfo.reload();
          });
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
          this.registerStepButtons();
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
        throw err;
      }
    }),
    // Edit Zones Modal
    customerZoneCopy: Ember.computed('customerZone', 'customerZonesCopy', function () {
      return this.customerZoneCopyTask.perform();
    }),
    customerZoneCopyTask: (0, _emberConcurrency.task)(function* () {
      var customerZonesCopy = yield this.customerZonesCopy;
      var zoneCopy = customerZonesCopy.filter(zones => {
        return zones.zone_number === this.customerZone.zone_number;
      });
      return zoneCopy[0];
    }),
    customerZonesCopy: Ember.computed('customerZones', function () {
      return this.customerZonesCopyTask.perform();
    }),
    customerZonesCopyTask: (0, _emberConcurrency.task)(function* () {
      var copiedObjects = [];
      var customerZones = yield this.customerZones;
      customerZones.forEach(zone => {
        copiedObjects.push(Object.assign({}, zone));
      });
      return copiedObjects;
    }),
    saveZoneEventType: (0, _emberConcurrency.task)(function* (buttonPromise) {
      var customerZonesCopy = yield this.customerZonesCopy;

      try {
        yield this.centralStationService.postData({
          model: this.customer,
          method: 'api__createZones',
          payload: {
            zones: customerZonesCopy
          }
        });
        buttonPromise.then(() => {
          this.set('showZoneEditModal', false);
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
          this.notifyPropertyChange('customerZones');
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'edit-zone-modal'
        });
        throw err;
      }
    }),
    actions: {
      updateCustomerZonesCopy() {
        this.notifyPropertyChange('customerZonesCopy');
      },

      openEditZoneModal(row) {
        if (this.formMode === 'edit') {
          this.set('customerZone', row);
          this.set('showZoneEditModal', true);
        } else {
          return null;
        }
      }

    }
  });

  _exports.default = _default;
});