define("securitytrax-ember-ui/components/homepage/tech-today/appointment/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/grouped-equipment", "securitytrax-ember-ui/mixins/leaflet-map", "securitytrax-ember-ui/mixins/tech-today", "lodash", "securitytrax-ember-ui/mixed-in-functions/checkbox-list-mixin"], function (_exports, _emberConcurrency, _groupedEquipment, _leafletMap, _techToday, _lodash, _checkboxListMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(Ember.Evented, _techToday.default, _groupedEquipment.default, _leafletMap.default, {
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    techToday: Ember.inject.service('events/tech-today'),
    grants: Ember.inject.service(),
    browserGeoLocationService: Ember.inject.service(),
    screen: Ember.inject.service(),
    intl: Ember.inject.service(),
    tagName: '',
    currentStepNameKey: 'equipmentStatus',
    customerEquipmentModel: null,
    workOrderActionName: 'Install',
    customerEquipmentBulkEditFormStarted: false,
    customerEquipmentFormStarted: false,
    customerEquipmentFormMode: 'edit',
    checkInFormStarted: false,
    checkOutFormStarted: false,
    bulkEditList: null,
    customer: null,
    appointmentIncomplete: false,
    isEquipmentFullCheckAll: false,
    checkedInCalendarEvent: false,
    resolvedCurrCalendarEvent: undefined,

    init() {
      this._super(...arguments);

      this.bulkEditList = [];
      this.techToday.on('refresh-work-order-equipment', this, '_refreshEventCallback');
      this.initDataTask.perform();
      Ember.defineProperty(this, 'notesFormOriginalModels', Ember.computed('resolvedCurrCalendarEvent', this.store.getFullComputedWatcherStringByModelName('customerSystemInformation'), function () {
        return Ember.Object.create({
          calendarEvent: {
            model: this.resolvedCurrCalendarEvent
          }
        });
      }));
    },

    willDestroy() {
      this._super(...arguments);

      this.techToday.off('refresh-work-order-equipment', this, '_refreshEventCallback');
    },

    _refreshEventCallback() {
      Ember.notifyPropertyChange(this, 'calendarEventsSorted');
    },

    get checkOutAdditionalClickActions() {
      return [{
        label: this.intl.t('pages.customers.techToday.incomplete'),
        action: () => {
          this.set('appointmentIncomplete', true);
          this.set('checkOutFormStarted', true);
        },
        classNamesString: ''
      }];
    },

    initDataTask: (0, _emberConcurrency.task)(function* () {
      this.checkedInCalendarEvent = yield this.user.get('checkedInCalendarEvent');
      this.resolvedCurrCalendarEvent = yield this.appointment;
    }),

    get calendarEventGeoPoint() {
      return this.calendarEventGeoPointTask.perform();
    },

    calendarEventGeoPointTask: (0, _emberConcurrency.task)(function* () {
      var customer = yield this.resolvedCurrCalendarEvent.get('customer');

      if (!customer.get('hasGeolocationDisabled')) {
        var geoPoint = yield customer.get('responseMeta.geopoint');
        return geoPoint;
      }

      return false;
    }),

    get markerLocation() {
      return this.markerLocationTask.perform();
    },

    markerLocationTask: (0, _emberConcurrency.task)(function* () {
      var calendarEventGeoPoint = yield this.calendarEventGeoPoint;

      if (calendarEventGeoPoint) {
        return [calendarEventGeoPoint.latitude, calendarEventGeoPoint.longitude];
      }

      return false;
    }),

    get checkInFormOriginalModels() {
      return Ember.Object.create({});
    },

    get checkInSteps() {
      var intl = this.intl;
      return Ember.Object.create({
        checkIn: Ember.Object.create({
          stepNameKey: 'checkIn',
          title: intl.t('pages.customers.techToday.checkIn'),
          stepComponentPath: 'multi-step-form/forms/tech-today/check-in'
        })
      });
    },

    get checkOutFormOriginalModels() {
      return Ember.Object.create({});
    },

    get checkOutSteps() {
      var intl = this.intl;
      return Ember.Object.create({
        checkOut: Ember.Object.create({
          stepNameKey: 'checkOut',
          title: intl.t('pages.customers.techToday.workSummary'),
          stepComponentPath: 'multi-step-form/forms/tech-today/check-out'
        })
      });
    },

    calendarEventsSortedTask: (0, _emberConcurrency.task)(function* () {
      var calendarEvent = yield this.get('appointment');
      return [calendarEvent];
    }),
    customerEquipmentBulkEditFormSteps: Ember.computed('stOrAdcEquipmentListLength', function () {
      return Ember.Object.create({
        bulkEdit: Ember.Object.create({
          stepNameKey: 'bulkEdit',
          title: this.intl.t('pages.customers.equipment.bulkEditTitle', {
            count: this.stOrAdcEquipmentListLength
          }),
          stepComponentPath: 'multi-step-form/forms/customer-equipment/bulk-edit'
        })
      });
    }),
    customerEquipmentBulkEditFormOriginalModels: Ember.computed('customerEquipmentModel', function () {
      return Ember.Object.create({
        customerEquipment: {
          model: this.customerEquipmentModel,
          copyRelationMap: {
            user: {
              doValidate: false,
              doSave: false
            }
          }
        }
      });
    }),
    customerEquipmentFormSteps: Ember.computed('customerEquipmentModel', 'workOrderActionName', 'customer', function () {
      return Ember.Object.create({
        addEquipment: Ember.Object.create({
          stepNameKey: 'addEquipment',
          title: this.intl.t('pages.customers.equipment.modal_title.create', {
            name: this.customer ? this.customer.get('flname') : ''
          }),
          stepComponentPath: 'multi-step-form/forms/customer-equipment/add-equipment'
        }),
        equipmentStatus: Ember.Object.create({
          stepNameKey: 'equipmentStatus',
          title: this.customerEquipmentModel ? this.workOrderActionName + ' ' + this.customerEquipmentModel.get('equipment.name') : this.workOrderActionName,
          stepComponentPath: 'multi-step-form/forms/tech-today/customer-equipment'
        })
      });
    }),
    customerEquipmentFormOriginalModels: Ember.computed('customerEquipmentModel', function () {
      return Ember.Object.create({
        customerEquipment: {
          model: this.customerEquipmentModel,
          copyRelationMap: {
            equipmentStatus: {
              doValidate: false,
              doSave: false
            },
            installCalendarEvent: {
              doValidate: false,
              doSave: false
            },
            uninstallCalendarEvent: {
              doValidate: false,
              doSave: false
            },
            installInventoryTechUser: {
              doCopy: true,
              doValidate: false,
              doSave: false
            },
            uninstallInventoryTechUser: {
              doCopy: true,
              doValidate: false,
              doSave: false
            }
          }
        }
      });
    }),

    get notesFormSteps() {
      return Ember.Object.create({
        calendarNotes: Ember.Object.create({
          stepNameKey: 'calendarNotes',
          stepComponentPath: 'multi-step-form/forms/tech-today/notes'
        })
      });
    },

    onTheWayTask: (0, _emberConcurrency.task)(function* () {
      try {
        var browserLocationData = yield this.browserGeoLocationService.get('getBrowserLocation');

        var latitude = _lodash.default.get(browserLocationData, 'coords.latitude', false);

        var longitude = _lodash.default.get(browserLocationData, 'coords.longitude', false);

        var enRouteData = {
          data: {}
        };

        if (browserLocationData && latitude && longitude) {
          enRouteData['data']['latitude'] = latitude;
          enRouteData['data']['longitude'] = longitude;
        }

        yield this.resolvedCurrCalendarEvent.invokeAction('en_route', {
          verb: 'POST',
          data: enRouteData
        });
        Ember.notifyPropertyChange(this.resolvedCurrCalendarEvent, 'appointmentStatusData');
      } catch (error) {
        (false && Ember.warn(error, {
          id: 'techToday.onTheWayTask'
        }));
      }
    }),
    checkInAppointmentTask: (0, _emberConcurrency.task)(function* (buttonPromise, multiStepForm, checkOutAllOthers = false) {
      try {
        var browserLocationData = yield this.browserGeoLocationService.get('getBrowserLocation');

        var latitude = _lodash.default.get(browserLocationData, 'coords.latitude', false);

        var longitude = _lodash.default.get(browserLocationData, 'coords.longitude', false);

        var checkInData = {
          data: {}
        };

        if (browserLocationData && latitude && longitude) {
          checkInData['data']['latitude'] = latitude;
          checkInData['data']['longitude'] = longitude;
        }

        if (checkOutAllOthers) {
          var calendarEvents = yield this.user.get('calendarEvents');
          var allCheckOuts = [];
          calendarEvents.forEach(calendarEvent => {
            allCheckOuts.push(this.checkOutCalendarEventTask.perform(calendarEvent));
          });
          yield (0, _emberConcurrency.all)(allCheckOuts);
        }

        yield this.resolvedCurrCalendarEvent.invokeAction('check_in', {
          verb: 'POST',
          data: checkInData
        });
        buttonPromise.then(() => {
          if (typeof multiStepForm.formStartedSetVal === 'function') {
            multiStepForm.formStartedSetVal(false);
          }

          this.techToday.trigger('reset-checked-in-appointment-data', this.resolvedCurrCalendarEvent);
        });
      } catch (error) {
        (false && Ember.warn(error, {
          id: 'techToday.checkInAppointmentTask'
        }));
      }
    }),
    checkOutCalendarEventTask: (0, _emberConcurrency.task)(function* (calendarEvent) {
      var elapsedTimeSinceCheckin = yield calendarEvent.get('elapsedTimeSinceCheckin');

      if (elapsedTimeSinceCheckin) {
        var browserLocationData = yield this.browserGeoLocationService.get('getBrowserLocation');

        var latitude = _lodash.default.get(browserLocationData, 'coords.latitude', false);

        var longitude = _lodash.default.get(browserLocationData, 'coords.longitude', false);

        var checkOutData = {
          data: {}
        };

        if (browserLocationData && latitude && longitude) {
          checkOutData['data']['latitude'] = latitude;
          checkOutData['data']['longitude'] = longitude;
        }

        yield calendarEvent.invokeAction('check_out', {
          verb: 'POST',
          data: checkOutData
        });
        Ember.notifyPropertyChange(calendarEvent, 'appointmentStatusData');
      }
    }),
    afterEquipmentMatchSaveTask: (0, _emberConcurrency.task)(function* () {
      var calendarEvent = yield this.resolvedCurrCalendarEvent; // Not needed, see if can be done a diff way

      Object.keys(calendarEvent.equipmentGroupedByAction).forEach(key => {
        Ember.set(calendarEvent.equipmentGroupedByAction[key], 'checkAll', false);
        Ember.set(calendarEvent.equipmentGroupedByAction[key], 'indeterminate', false);
      });
      (0, _checkboxListMixin.uncheckAll)(this.bulkEditList);
      this.bulkEditList = [];
      this.isEquipmentFullCheckAll = false;
      Ember.notifyPropertyChange(this, 'bulkEditList');
    }),
    bulkDelete: (0, _emberConcurrency.task)(function* (context, buttonPromise) {
      var promiseArray = [];
      this.bulkEditList.forEach(equipmentToDelete => {
        promiseArray.push(this.deleteCustomerEquipment.perform(equipmentToDelete));
      });

      try {
        yield (0, _emberConcurrency.all)(promiseArray);
        var calendarEvent = this.resolvedCurrCalendarEvent;
        buttonPromise.then(() => {
          calendarEvent.get('workOrder.workOrderEquipment').reload();

          if (context.multiStepForm) {
            context.multiStepForm.onAfterSave();
            context.multiStepForm.formStartedSetVal(false);
          } else {
            this.afterEquipmentMatchSaveTask.perform();
          }

          this.stNotificationManager.addSuccessKey('pages.customers.equipment.bulkDeleteSuccess');
          Ember.notifyPropertyChange(this, 'calendarEventsSorted');
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(this.intl.t('pages.customers.equipment.bulkDeleteFailure') + err, {
          notificationNamespace: context.multiStepForm ? 'modal2' : 'global'
        });
        throw err;
      }
    }),
    deleteCustomerEquipment: (0, _emberConcurrency.task)(function* (equipmentToDelete) {
      var toDelete = yield equipmentToDelete;
      toDelete.destroyRecordWithRollbackTask.perform();
    }),
    equipmentCheckAllTask: (0, _emberConcurrency.task)(function* (groupedEquipmentByAction) {
      var items = [];
      groupedEquipmentByAction.groupedItems.forEach(group => {
        items = items.concat(group.items);
      });
      var yieldedItems = yield (0, _emberConcurrency.all)(items);
      Ember.set(groupedEquipmentByAction, 'checkAll', !groupedEquipmentByAction.checkAll);
      Ember.set(groupedEquipmentByAction, 'indeterminate', false);
      var isChecked = groupedEquipmentByAction.checkAll;

      if (isChecked) {
        var list = (0, _checkboxListMixin.checkAll)(yieldedItems);
        this.bulkEditList = _lodash.default.unionBy(this.bulkEditList, list, 'id');
        this.disabledList = 'adcEquipment';
      } else {
        var _list = (0, _checkboxListMixin.uncheckAll)(yieldedItems);

        this.bulkEditList = _lodash.default.difference(this.bulkEditList, _list);
        this.disabledList = this.bulkEditList.get('length') > 0 ? 'adcEquipment' : null;
      }

      Ember.notifyPropertyChange(this, 'bulkEditList');
    }),
    equipmentFullCheckAllTask: (0, _emberConcurrency.task)(function* () {
      var items = [];
      var calendarEvent = this.resolvedCurrCalendarEvent;
      this.isEquipmentFullCheckAll = !this.isEquipmentFullCheckAll;
      Object.keys(calendarEvent.equipmentGroupedByAction).forEach(key => {
        var currGroupedEquipmentByAction = calendarEvent.equipmentGroupedByAction[key];
        Ember.set(currGroupedEquipmentByAction, 'checkAll', this.isEquipmentFullCheckAll);
        Ember.set(currGroupedEquipmentByAction, 'indeterminate', false);
        currGroupedEquipmentByAction.groupedItems.forEach(group => {
          items = items.concat(group.items);
        });
      });
      var yieldedItems = yield (0, _emberConcurrency.all)(items);

      if (this.isEquipmentFullCheckAll) {
        var list = (0, _checkboxListMixin.checkAll)(yieldedItems);
        this.bulkEditList = list;
        this.disabledList = 'adcEquipment';
      } else {
        (0, _checkboxListMixin.uncheckAll)(yieldedItems);
        this.bulkEditList = [];
        this.disabledList = this.bulkEditList.get('length') > 0 ? 'adcEquipment' : null; // DREW TODO - check about this
      }

      Ember.notifyPropertyChange(this, 'bulkEditList');
    }),

    get salesTaxRateAsPercent() {
      return Intl.NumberFormat('en-GB', {
        style: 'percent',
        minimumFractionDigits: 1,
        maximumFractionDigits: 2
      }).format(this.resolvedCurrCalendarEvent.responseMeta.sales_tax_rate);
    },

    actions: {
      equipmentChecked(listToEdit, equipmentRow, groupedEquipmentByAction) {
        var bulkList = this[listToEdit];
        var equipmentIndex = bulkList.indexOf(equipmentRow.get('id'));
        var isChecked = equipmentRow.get('isChecked') ? false : true;
        var checkedCount = 0;
        var length = 0;

        if (isChecked) {
          if (equipmentIndex === -1) {
            bulkList.pushObject(equipmentRow);
            equipmentRow.set('isChecked', true);
          }
        } else {
          equipmentRow.set('isChecked', false);
        }

        groupedEquipmentByAction.groupedItems.forEach(group => {
          group.items.forEach(item => {
            length++;

            if (item.get('isChecked')) {
              checkedCount++;
            }
          });
        });
        Ember.set(groupedEquipmentByAction, 'checkAll', checkedCount === length);
        Ember.set(groupedEquipmentByAction, 'indeterminate', checkedCount < length && checkedCount > 0);
        this[listToEdit] = bulkList.filter(item => {
          return item.get('isChecked');
        });
        Ember.notifyPropertyChange(this, 'bulkEditList');
      },

      updateNotes() {
        Ember.notifyPropertyChange(this, 'resolvedCurrCalendarEvent');
      },

      transitionToMyInventory() {
        this.router.transitionTo('my-inventory.equipment-inventory');
      },

      transitionToSubroute(calendarEvent) {
        this.router.transitionTo('home.tech-today.appointment.create-invoice', calendarEvent.get('id'), 'create');
      },

      startEquipmentAddEdit(calendarEvent, path) {
        this.router.transitionTo('home.tech-today.appointment.edit-create-customer-equipment', calendarEvent.get('id'), path);
      },

      afterEquipmentDelete() {
        this.resolvedCurrCalendarEvent.get('workOrder.workOrderEquipment').reload();
        Ember.notifyPropertyChange(this, 'calendarEventsSorted');
      }

    }
  });

  _exports.default = _default;
});