define("securitytrax-ember-ui/components/multi-step-form/forms/system/adt/adt-form-base", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency"], function (_exports, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    centralStationService: Ember.inject.service(),
    progressBarHidden: false,
    adtStepReady: false,
    hiddenProgressBarSteps: Ember.computed('adtCustomerInformation', function () {
      var hiddenSteps = [];

      if (!this.adtShowDealerHubInfo) {
        hiddenSteps.push('dealerHubInfo');
      }

      if (!this.adtCustomerInformation) {
        hiddenSteps.push('collectionInfo', 'fundingInfo', 'dispositionInfo');
      } else if (!this.adtCustomerInformation.collections.dispositions) {
        hiddenSteps.push('dispositionInfo');
      }

      return hiddenSteps;
    }),
    disabledProgressBarSteps: Ember.computed(function () {
      return [];
    }),
    adtStepReadyObserver: Ember.observer('adtStepReady', function () {
      var multiStepForm = this.multiStepForm;
      var currentStepNameKey = multiStepForm.currentStepNameKey;
      multiStepForm.registerStepData(currentStepNameKey, 'hideTopRightCloseButton', !this.adtStepReady);
    }),
    discrepancies: Ember.computed(function () {
      // this is a change
      return this.discrepanciesTask.perform();
    }),
    discrepanciesTask: (0, _emberConcurrency.task)(function* () {
      var centralStationService = this.centralStationService;

      try {
        var hasAdtOrderStatus = yield this.get('customerSystemInformation.centralStationId');

        if (hasAdtOrderStatus) {
          try {
            var discrepancies = yield centralStationService.getData({
              model: this.customer,
              method: 'adt__getEnvelopesDiscrepancies',
              responseKey: 'envelopes_discrepancies'
            });
            return discrepancies;
          } catch (response) {
            this.stNotificationManager.addErrorMessages(response, {
              notificationNamespace: 'adtMegaFormModal'
            });
          }
        }

        return [];
      } catch (error) {
        (false && Ember.warn('failed to get discrepencies' + error, {
          id: 'multiStepForm.forms.system.adt'
        }));
      }
    }),
    accountHasNameDiscrepancy: Ember.computed('discrepancies', function () {
      return this.accountHasNameDiscrepancyTask.perform();
    }),
    accountHasNameDiscrepancyTask: (0, _emberConcurrency.task)(function* () {
      var discrepancies = yield this.discrepancies;
      var hasDiscrepancy = false;
      discrepancies.forEach(discrepancy => {
        if (discrepancy.category === 'account' && discrepancy.field === 'name') {
          hasDiscrepancy = true;
        }
      });
      return hasDiscrepancy;
    })
  });

  _exports.default = _default;
});