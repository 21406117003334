define("securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-multi-step-form-create/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vBUw9K4G",
    "block": "{\"symbols\":[\"multiStepForm\"],\"statements\":[[6,[37,8],null,[[\"class\",\"formSteps\",\"formOriginalModels\",\"formStarted\"],[\"row\",[35,7],[35,6],true]],[[\"default\"],[{\"statements\":[[6,[37,5],null,[[\"modalButtons\",\"title\",\"dialogOpen\",\"onClose\",\"multiStepForm\"],[[32,1,[\"currentStepData\",\"buttons\"]],[32,1,[\"currentStepData\",\"title\"]],[32,1,[\"formStarted\"]],[30,[36,4],[[32,0],\"transitionBackToParentRoute\"],null],[32,1]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,3],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],[[\"multiStepForm\",\"formMode\",\"customer\",\"parentRoute\"],[[32,1],[35,2],[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"parentRoute\",\"customer\",\"formMode\",\"component\",\"action\",\"modals/st-paper\",\"formOriginalModels\",\"singleStepForm\",\"multi-step-form\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-multi-step-form-create/template.hbs"
    }
  });

  _exports.default = _default;
});