define("securitytrax-ember-ui/components/multi-step-form/forms/system/adt/shared/add-on-service-data/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    centralStationService: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    availableAddOnServices: Ember.computed('multiStepForm.formCopiedModels.customer.model.accountType', function () {
      return this.availableAddOnServicesTask.perform();
    }),
    availableAddOnServicesTask: (0, _emberConcurrency.task)(function* () {
      try {
        var multiStepForm = this.multiStepForm;
        yield (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels', true);
        var accountType = this.multiStepForm.formCopiedModelsGetVal('customer.model.accountType');
        var centralStationService = this.centralStationService;
        var adtOrderData = yield this.adtOrder;
        var addOnServices = yield centralStationService.getData({
          model: this.monitoringCompany,
          method: 'adt__getAddOnServices',
          responseKey: 'add_on_services'
        });
        var filteredAddons = [];
        addOnServices.forEach(currAddon => {
          var correspondingAddOn = 'add_on_services' in adtOrderData ? adtOrderData.add_on_services.find(addon => addon.id === currAddon.id) : null;
          currAddon['selected'] = correspondingAddOn ? adtOrderData.add_on_services.find(addon => addon.id === currAddon.id).selected : false;

          if (accountType == 'Residential' && currAddon.id.substr(0, 1) == 'R') {
            filteredAddons.push(currAddon);
          } else if (accountType == 'Commercial' && currAddon.id.substr(0, 1) == 'C') {
            filteredAddons.push(currAddon);
          }
        });
        return filteredAddons;
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'adtMegaFormModal'
        });
        (false && Ember.warn('mega form save error availableAddOnServicesTask' + err, {
          id: 'multiStepForm.forms.system.adt.shared.availableAddOnServicesTask'
        }));
      }
    }),
    addRemoveAddOnServiceTask: (0, _emberConcurrency.task)(function* () {
      var availableAddOnServices = yield this.availableAddOnServices;
      var adtOrder = yield this.adtOrder;
      adtOrder['add_on_services'] = availableAddOnServices;
    }).drop()
  });

  _exports.default = _default;
});