define("securitytrax-ember-ui/validations/customer-message", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateFieldsObject = {
        templateMessage: {
          presence: {
            allowEmpty: false
          }
        },
        sendTo: {
          presence: {
            allowEmpty: false
          }
        },
        technicianAppointment: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('customerMessage-technicianAppointment')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'customerMessage-technicianAppointment');
            }
          }
        },
        salesAppointment: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('customerMessage-salesAppointment')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'customerMessage-salesAppointment');
            }
          }
        },
        customerNote: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('customerMessage-customerNote')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'customerMessage-customerNote');
            }
          }
        },
        atRiskEntry: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('customerMessage-atRiskEntry')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'customerMessage-atRiskEntry');
            }
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateFieldsObject);

      if (validationData && validationData['requestMeta.to']) {
        validationData['requestMeta-to'] = validationData['requestMeta.to'];
        delete validationData['requestMeta.to'];
        validationData['requestMeta-to'] = validationData['requestMeta-to'].map(message => {
          return message.replace(/Request meta to/, 'To value');
        });
      }

      return this.outputFormatting(validationData, 'customerMessage');
    }

  });

  _exports.default = _default;
});