define("securitytrax-ember-ui/models/equipment-purchase-order", ["exports", "securitytrax-ember-ui/models/base_models/equipment-purchase-order", "ember-concurrency", "securitytrax-ember-ui/mixins/pending-equipment-reusable"], function (_exports, _equipmentPurchaseOrder, _emberConcurrency, _pendingEquipmentReusable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _equipmentPurchaseOrder.default.extend(_pendingEquipmentReusable.default, {
    grants: Ember.inject.service(),
    isFulfilled: Ember.computed('pendingEquipmentLineItems.@each.{quantity,fulfilledQuantity}', function () {
      var fulfilled = true;
      this.pendingEquipmentLineItems.forEach(lineItem => {
        if (lineItem.get('quantity') > lineItem.get('fulfilledQuantity')) {
          fulfilled = false;
        }
      });
      return fulfilled;
    }),
    processingMethod: Ember.computed(function () {
      return this.processingMethodTask.perform();
    }),
    processingMethodTask: (0, _emberConcurrency.task)(function* () {
      var vendor = yield this.get('equipmentVendor');
      var config = yield vendor.get('equipmentVendorConfig');

      if (this.grants.getIndividualGrant('equipment_vendors.manage_connector') && config.get('connector') !== 'HvInventory_VendorConnector_Manual') {
        var methods = yield vendor.get('processingMethods');
        var method = methods.findBy('value', this.processingMethodId);
        return method ? method.name : '';
      } else {
        return '';
      }
    }),
    deliveredIcon: Ember.computed('processingMethodId', 'ediTransmit', 'ediSent', function () {
      if (this.processingMethodId !== 0) {
        return this.ediTransmit && this.ediSent ? 'icn-check-green' : 'icn-close-red';
      } else {
        return null;
      }
    })
  });

  _exports.default = _default;
});