define("securitytrax-ember-ui/home/view/edit-timeoff/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/subroute-modal", "securitytrax-ember-ui/mixins/route-pageview-logging", "ember-concurrency"], function (_exports, _authenticatedRouteMixin, _subrouteModal, _routePageviewLogging, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _subrouteModal.default, _routePageviewLogging.default, {
    grants: Ember.inject.service(),
    sessionData: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['calendar_events.view']);
    },

    model({
      id
    }) {
      return this.setupModelTask.perform(id);
    },

    setupModelTask: (0, _emberConcurrency.task)(function* (id) {
      var user = yield this.sessionData.getUser();
      var calendarUser = this.store.findRecord('calendarUser', user.get('id'));
      return Ember.RSVP.hash({
        appointmentId: id,
        parentRoute: this.transitionRoute,
        user,
        calendarUser,
        calendarEventTypes: this.store.findAll('calendarEventType')
      });
    })
  });

  _exports.default = _default;
});