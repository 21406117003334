define("securitytrax-ember-ui/users/integrations/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7YEA07V0",
    "block": "{\"symbols\":[],\"statements\":[[8,\"my-account/integrations/adc\",[],[[\"@user\"],[[34,0,[\"user\"]]]],null],[2,\"\\n\"],[8,\"my-account/integrations/brinks\",[],[[\"@user\"],[[34,0,[\"user\"]]]],null],[2,\"\\n\"],[8,\"my-account/integrations/telus\",[],[[\"@user\"],[[34,0,[\"user\"]]]],null]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/users/integrations/template.hbs"
    }
  });

  _exports.default = _default;
});