define("securitytrax-ember-ui/models/funding-progress-status", ["exports", "securitytrax-ember-ui/models/base_models/funding-progress-status"], function (_exports, _fundingProgressStatus) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fundingProgressStatus.default.extend({});

  _exports.default = _default;
});