define("securitytrax-ember-ui/components/multi-step-form/forms/administration/equipment/add-equipment-master-list/review/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/switchable-form-step"], function (_exports, _emberConcurrency, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('reviewEquipment', 'hideTopRightCloseButton', true);
      multiStepForm.registerStepData('reviewEquipment', 'buttons', [{
        text: this.intl.t('common.actions.cancel'),
        click: () => {
          multiStepForm.currentStepNameKeySetVal('masterList');
        },
        classNamesString: 'st-btn st-btn-secondary cancel-add-equipment-button'
      }, {
        text: this.addLabel,
        click: buttonPromise => {
          return this.addEquipmentItemsTask.perform(buttonPromise, multiStepForm);
        },
        classNamesString: 'st-btn st-btn-primary save-equipment-button',
        positionOptions: 'up left',
        additionalClickActions: [{
          label: this.intl.t('common.actions.saveAndContinue'),
          action: buttonPromise => {
            return this.addEquipmentItemsTask.perform(buttonPromise, multiStepForm, true);
          },
          classNamesString: 'dropdown-save-close'
        }]
      }]);
    },

    addLabel: Ember.computed('addArray.[]', function () {
      var addArray = this.addArray;
      return addArray ? this.intl.t('pages.administration.company_settings.equipment.equipment.add_num_items', {
        num: addArray.length
      }) : this.intl.t('pages.administration.company_settings.equipment.equipment.title_create');
    }),
    addEquipmentItemsTask: (0, _emberConcurrency.task)(function* (buttonPromise, multiStepForm, goToEdit = false) {
      try {
        var store = this.store;
        var newEquipment = this.addArray;
        var equipmentToSave = [];
        newEquipment.forEach(equipmentItem => {
          var manufacturer = equipmentItem.get('equipmentManufacturer');
          var newEquipment = store.createRecord('equipment', {
            name: equipmentItem.get('name'),
            active: true,
            equipmentItem: equipmentItem,
            description: equipmentItem.get('description'),
            model: equipmentItem.get('modelNumber'),
            equipmentManufacturer: manufacturer,
            manufacturer: manufacturer.get('name')
          });
          equipmentToSave.push(newEquipment.save());
        });
        var savedEquipment = yield (0, _emberConcurrency.all)(equipmentToSave);
        buttonPromise.then(() => {
          if (goToEdit) {
            var item = savedEquipment[savedEquipment.length - 1];

            if (this.onAfterSave && typeof this.onAfterSave === 'function') {
              this.onAfterSave({
                saved: item
              });
            }

            multiStepForm.resetFromCreateToEdit();
            this.equipment = item;
          } else {
            if (this.onAfterSave && typeof this.onAfterSave === 'function') {
              this.onAfterSave();
            }

            multiStepForm.formStartedSetVal(false);
          }

          this.set('addArray', []);
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
        (false && Ember.warn('Error adding equipment items to company: ' + err, {
          id: 'multiStepForm.forms.administration.equipment.add-equipment-master-list.addEquipmentItemsTask'
        }));
        throw err;
      }
    })
  });

  _exports.default = _default;
});