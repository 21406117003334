define("securitytrax-ember-ui/components/html/priority-color-formatter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6i+Nx4Na",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"    \"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/priority-color-formatter/template.hbs"
    }
  });

  _exports.default = _default;
});