define("securitytrax-ember-ui/leads/messages/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gAan46Ew",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,6],[[30,[36,5],[\"leads.view_tag\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"row card-row lead-tags \",[34,0]]]],[12],[2,\"\\n        \"],[8,\"html/st-tags\",[],[[\"@title\",\"@selectedTags\",\"@parentModel\",\"@tagObjectContext\",\"@isFulfilled\",\"@toggleTagView\",\"@deleteFromName\"],[[30,[36,1],[\"pages.tags.applyLeadTag\"],null],[34,2,[\"lead\",\"tags\"]],[34,2,[\"lead\"]],\"lead\",[34,2,[\"lead\",\"tags\",\"isFulfilled\"]],[30,[36,4],[[32,0],[35,3],\"tagViewCollapsed\"],null],[34,2,[\"lead\",\"flname\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"pages/customer/messages\",[],[[\"@model\",\"@customerOrLead\"],[[34,2],\"lead\"]],null],[2,\"\\n\\n\"],[8,\"customer/user-defined-fields\",[],[[\"@customer\",\"@model\",\"@udfContext\"],[[34,2,[\"lead\"]],[34,2],\"leads_messages\"]],null],[2,\"\\n\\n\"],[1,[30,[36,8],[[30,[36,7],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"editLeadFormMode\",\"t\",\"model\",\"toggleProperty\",\"action\",\"has-grants\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/leads/messages/template.hbs"
    }
  });

  _exports.default = _default;
});