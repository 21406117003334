define("securitytrax-ember-ui/components/customer/information/customer-information/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4onyB20O",
    "block": "{\"symbols\":[\"multiStepForm\"],\"statements\":[[6,[37,7],null,[[\"formSteps\",\"formOriginalModels\",\"progressBarConfig\",\"formStarted\"],[[35,6],[35,5],[35,4],true]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,3],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],[[\"multiStepForm\",\"class\",\"customer\",\"model\",\"onFieldChange\",\"reloadFormModel\",\"disableFields\",\"hideProgressBar\"],[[32,1],\"customer-form\",[35,1],[35,2],[30,[36,0],[[32,0],\"saveProperty\",[35,1]],null],[30,[36,0],[[32,0],\"reloadFormModel\"],null],false,true]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"customer\",\"model\",\"component\",\"informationProgressBarConfig\",\"formOriginalModels\",\"singleStepForm\",\"multi-step-form\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/customer/information/customer-information/template.hbs"
    }
  });

  _exports.default = _default;
});