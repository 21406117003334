define("securitytrax-ember-ui/models/adt-monitoring-system-type", ["exports", "securitytrax-ember-ui/models/base_models/adt-monitoring-system-type"], function (_exports, _adtMonitoringSystemType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adtMonitoringSystemType.default.extend({});

  _exports.default = _default;
});