define("securitytrax-ember-ui/helpers/number-incrementer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.numberIncrementer = numberIncrementer;
  _exports.default = void 0;

  function numberIncrementer([minNum, maxNum, numberIncrement]) {
    var options = [];

    for (var i = minNum; i <= maxNum; i += numberIncrement) {
      options.push({
        id: i,
        value: i
      });
    }

    return options;
  }

  var _default = Ember.Helper.helper(numberIncrementer);

  _exports.default = _default;
});