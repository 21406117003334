define("securitytrax-ember-ui/components/multi-step-form/forms/system/brinks/history/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/system/brinks/brinks-base", "ember-concurrency"], function (_exports, _brinksBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var History = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = (0, _emberConcurrency.task)(function* () {
    try {
      var hashedData = yield (0, _emberConcurrency.hash)({
        eventHistory: this.centralStationService.getData({
          model: this.args.customer,
          method: 'brinks__getEventHistories'
        })
      });
      this.allEventHistories = hashedData.eventHistory.event_histories;
      this.allEventHistories.forEach((event, index) => {
        event.eventNumber = index + 1;
      });
      this.zoneEventHistories = this.allEventHistories.filter(event => {
        return typeof event.zone_id !== 'object' && !isNaN(Number(event.zone_id));
      });
      this.historyColumn = 'all';
      this.showingHistory = this.allEventHistories;
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
    }
  }), _dec6 = Ember._action, (_class = (_temp = class History extends _brinksBase.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "centralStationService", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "historyColumn", _descriptor3, this);

      _initializerDefineProperty(this, "showingHistory", _descriptor4, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor5, this);

      this.initDataTask.perform();
    }

    setColumn(column) {
      this.historyColumn = column;
      this.showingHistory = column === 'all' ? this.allEventHistories : this.zoneEventHistories;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "historyColumn", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showingHistory", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setColumn", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setColumn"), _class.prototype)), _class));
  _exports.default = History;
});