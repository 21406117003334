define("securitytrax-ember-ui/mixins/scroll-mixin", ["exports", "lodash", "jquery"], function (_exports, _lodash, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    bindVerticalScrolling: function (verticalScrollContext) {
      var _this = this;

      var onScroll = function () {
        return _this.scrolled();
      };

      if (verticalScrollContext) {
        (0, _jquery.default)(verticalScrollContext).bind('touchmove', _lodash.default.throttle(onScroll, 100));
        (0, _jquery.default)(verticalScrollContext).bind('scroll', _lodash.default.throttle(onScroll, 100));
      } else {
        (0, _jquery.default)(document).bind('touchmove', _lodash.default.throttle(onScroll, 100));
        (0, _jquery.default)(window).bind('scroll', _lodash.default.throttle(onScroll, 100));
      }
    },
    bindHorizontalScrolling: function () {
      var _this = this;

      var horScroll = function () {
        return _this.horizontalScroll();
      };

      (0, _jquery.default)('#' + this.elementId + ' .horizontal-scroll-area').bind('scroll', _lodash.default.throttle(horScroll, 1));
    },
    unbindScrolling: function (verticalScrollContext) {
      if (verticalScrollContext) {
        (0, _jquery.default)(verticalScrollContext).unbind('scroll');
        (0, _jquery.default)(verticalScrollContext).unbind('touchmove');
      } else {
        (0, _jquery.default)(window).unbind('scroll');
        (0, _jquery.default)(document).unbind('touchmove');
      }

      (0, _jquery.default)('#' + this.elementId).unbind('scroll');
    }
  });

  _exports.default = _default;
});