define("securitytrax-ember-ui/components/html/status-timeline-appointment/open-close-div/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H5A6Lqg2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"open-close-div-header grid-x grid-padding-x grid-padding-y \",[34,2,[\"grayClass\"]]]]],[4,[38,3],[[32,0],\"toggleOpen\"],null],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cell medium-3\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"title\"],[15,5,[30,[36,4],[[35,1,[\"calendarEventType\",\"textColor\"]]],null]],[12],[2,\"\\n            \"],[1,[35,1,[\"calendarEventType\",\"name\"]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"time-display\"],[12],[1,[35,1,[\"fromXtoXDisplay\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cell medium-3\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"customer-name\"],[12],[1,[35,1,[\"customer\",\"name\"]]],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"gray\"],[12],[2,[35,1,[\"customer\",\"fullAddress\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cell medium-3\"],[12],[2,\"\\n\"],[6,[37,5],[[35,1,[\"workOrder\",\"id\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"gray\"],[12],[1,[30,[36,0],[\"pages.customers.techToday.workOrder\"],null]],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"time-display\"],[12],[2,\"#\"],[1,[35,1,[\"workOrder\",\"workOrderNumber\"]]],[2,\" (\"],[1,[35,1,[\"workOrder\",\"id\"]]],[2,\")\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cell shrink medium-3 text-right\"],[12],[2,\"\\n        \"],[1,[30,[36,6],[\"icn-chevron-down\"],[[\"class\"],[\"div-toggle-open\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"div-content grid-x grid-padding-x grid-padding-y\"],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"calendarEvent\",\"resolvedPromises\",\"action\",\"await\",\"if\",\"svg-icon\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/status-timeline-appointment/open-close-div/template.hbs"
    }
  });

  _exports.default = _default;
});