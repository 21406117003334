define("securitytrax-ember-ui/components/multi-step-form/forms/customer-central-station-information/default-central-station-fields/component", ["exports", "securitytrax-ember-ui/mixins/forms/adc-receiver-numbers"], function (_exports, _adcReceiverNumbers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_adcReceiverNumbers.default, {
    screen: Ember.inject.service(),
    receiverNumber: Ember.computed.alias('multiStepForm.formCopiedModels.customerSystemInformation.model.receiverNum'),
    actions: {
      csUpdated(monitoringCompany) {
        this.updatePendingMonitoringCompany(monitoringCompany);
      }

    }
  });

  _exports.default = _default;
});