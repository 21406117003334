define("securitytrax-ember-ui/components/multi-step-form/forms/administration/inventory/spillage/review/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KKGYjYjf",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"grid-x grid-padding-x\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cell large-6\"],[12],[2,\"\\n        \"],[1,[30,[36,4],null,[[\"name\",\"formMode\",\"label\",\"options\",\"valueProperty\",\"labelProperty\",\"value\"],[\"recipient\",[35,3],[30,[36,2],[\"pages.users.inventory.recipient\"],null],[35,1],\"id\",\"inventorySinkNameDescription\",[35,0]]]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"cell large-6\"],[12],[2,\"\\n        \"],[1,[30,[36,4],null,[[\"name\",\"formMode\",\"label\",\"options\",\"valueProperty\",\"labelProperty\",\"value\"],[\"status\",[35,3],[30,[36,2],[\"pages.administration.company_settings.inventory.spillage.status\"],null],[35,6],\"value\",\"name\",[35,5,[\"status\"]]]]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cell large-12\"],[12],[2,\"\\n        \"],[1,[30,[36,7],null,[[\"name\",\"formMode\",\"label\",\"value\"],[\"comments\",[35,3],[30,[36,2],[\"pages.administration.company_settings.inventory.spillage.comments\"],null],[35,5,[\"comments\"]]]]]],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,9],null,[[\"pendingEquipmentLineItems\"],[[35,8]]]]]],\"hasEval\":false,\"upvars\":[\"recipient\",\"recipients\",\"t\",\"formMode\",\"multi-step-form/switchable-inputs/select-input\",\"spillage\",\"statusOptions\",\"multi-step-form/switchable-inputs/text-input\",\"pendingEquipmentLineItems\",\"inventory/review-spillage\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/inventory/spillage/review/template.hbs"
    }
  });

  _exports.default = _default;
});