define("securitytrax-ember-ui/serializers/application", ["exports", "@ember-data/serializer/json-api", "securitytrax-ember-ui/utils/strings", "ember-inflector", "lodash"], function (_exports, _jsonApi, _strings, _emberInflector, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { isEnabled } from '../-private';
  //https://www.emberjs.com/api/ember-data/2.16/classes/DS.JSONAPISerializer
  //http://www.emberjs.com.cn/api/data/classes/DS.Model.html
  //https://github.com/danielspaniel/ember-data-change-tracker
  //in general serialize* methods are for mangling outgoing data to the API
  //and normalize* are for mangling incoming data coming from the API
  var _default = _jsonApi.default.extend({
    keyForModel(modelName) {
      return Ember.String.underscore(modelName);
    },

    keyForAttribute(attr) {
      return (0, _strings.camelAllCapsToSnake)(attr);
    },

    keyForRelationship(relationship) {
      return (0, _strings.camelAllCapsToSnake)(relationship);
    },

    payloadKeyFromModelName(modelName) {
      return (0, _emberInflector.pluralize)(Ember.String.underscore(modelName));
    },

    /**
     * Can be used to convert IDs into different datatypes (ie: complex keys to json instead of stringified json)
     *
     * @param {*} snapshot
     * @param {*} json
     * @param {*} primaryKey
     */
    // serializeId(snapshot, json, primaryKey) {
    //     primaryKey;
    //     this._super(...arguments);
    // },

    /**
     * Used in conjunction with serializId
     *
     * @param {*} modelClass
     * @param {*} resourceHash
     */
    // extractId(modelClass, resourceHash) {
    //     /*
    //     let id;
    //     //TODO: add property to models to determine this explicitly
    //     try {
    //         id = JSON.parse(resourceHash['id']);
    //     } catch (e) {
    //         id = resourceHash['id'];
    //     }
    //     return id;
    //     */
    //     return resourceHash['id'];
    // },

    /**
     *
     * @param {*} snapshot
     * @param {*} json
     * @param {*} key ember-data key/name for the attribute
     * @param {*} attribute details of the attribute {type: 'string', ...}
     */
    serializeAttribute(snapshot, json, key, attribute) {
      json.attributes = json.attributes || {};
      var modelName = snapshot.modelName;
      var attributeChanged = snapshot.changedAttributes()[key] === undefined ? false : true;
      /**
       * If this property is true the record is in the new state.
       * A record will be in the new state when it has been created on the client and the adapter has not yet report that it was successfully saved.
       */
      //const isNew = snapshot.record.get('isNew'); // eslint-disable-line no-unused-vars

      /**
       * created The record has been created by the client and not yet saved to the adapter.
       * updated The record has been updated by the client and not yet saved to the adapter.
       * deleted The record has been deleted by the client and not yet saved to the adapter.
       */

      var dirtyType = snapshot.record.get('dirtyType');
      var update = dirtyType === 'updated' ? true : false;
      var oldValue, newValue; // eslint-disable-line no-unused-vars
      //do NOT send unchanged attributes over the wire

      if (!attributeChanged && attribute.type != 'json' && !('serializeAlways' in attribute.options && attribute.options.serializeAlways === true)) {
        return;
      } else {
        //if type==json we want to serialize it
        if (attributeChanged) {
          oldValue = snapshot.changedAttributes()[key][0];
          newValue = snapshot.changedAttributes()[key][1];
        }
      }

      if ('options' in attribute && attribute.options.enumValues) {
        if (newValue && typeof newValue === 'object' && 'value' in newValue) {
          newValue = newValue.value;
        }

        if (newValue !== null && !attribute.options.enumValues.includes(newValue)) {
          var stringEnumValues = attribute.options.enumValues.join(',');
          (false && Ember.warn('Attempting to save an attribute to an invalid enum value. Property: ' + modelName + '.' + key + ' You sent: \'' + newValue + '\'. Must be in this list: ' + stringEnumValues, {
            id: 'serializer.application.serializeAttribute.enum'
          }));
          throw 'Attempted to save an enum attribute with an invalid enum value';
        }
      } //do NOT send readOnly attributes such as createdAt etc


      if ('options' in attribute && attribute.options.readOnly === true) {
        //warn('cannot save a readOnly attribute:' + modelName + '.' + key, {id:'serializer.application.serializeAttribute.readOnly'});
        return;
      } //do NOT send readOnlyUpdate attributes when updating


      if (update === true && 'options' in attribute && attribute.options.readOnlyUpdate === true) {
        //warn('cannot save a readOnly attribute:' + modelName + '.' + key, {id:'serializer.application.serializeAttribute.readOnly2'});
        return;
      } //do NOT send properties that went from undefined to null (doesn't make sense)
      //IS THIS VALID!?


      if (oldValue === undefined && newValue === null) {
        return; //setting something to null is only a valid use case when removing an existing value. don't need to send null if already undefined/null
      } //fee is only available for guard
      //TODO: move this to model specific serializer?


      if (modelName == 'agency-contact' && key == 'fee' && snapshot._attributes.agencyContactType != 'guard') {
        return;
      }

      if (modelName == 'monitoring-company-config' && attribute && attribute.type == 'json' && snapshot._attributes[attribute.name] === null) {
        return;
      }

      this._super(snapshot, json, key, attribute);
    },

    /**
     * this is called *before* serialize (or at least before serialize as defined below)
     * probably invoked by _super for serialize though
     * https://emberigniter.com/saving-only-dirty-attributes/
     *
     * @param {*} snapshot
     * @param {*} json
     * @param {*} relationship
     */
    serializeBelongsTo(snapshot, json, relationship) {
      json.relationships = json.relationships || {};
      var modelName = snapshot.modelName; // eslint-disable-line no-unused-vars

      /**
       * If this property is true the record is in the new state.
       * A record will be in the new state when it has been created on the client and the adapter has not yet report that it was successfully saved.
       */

      var isNew = snapshot.record.get('isNew'); // eslint-disable-line no-unused-vars

      /**
       * created The record has been created by the client and not yet saved to the adapter.
       * updated The record has been updated by the client and not yet saved to the adapter.
       * deleted The record has been deleted by the client and not yet saved to the adapter.
       */

      var dirtyType = snapshot.record.get('dirtyType');
      var update = dirtyType === 'updated' ? true : false;
      /**
       * isRelationship: true
       * key: <emberDataKey>
       * kind: 'belongsTo'
       * options: {inverse: foo}
       * type: <emberDataType>
       */

      var {
        key
      } = relationship;
      var apiKey = this.keyForRelationship(key); // stEmbedded records

      var stEmbeddedCreate = 'options' in relationship && relationship.options.stEmbedded === 'create' && isNew;
      var stEmbeddedEdit = 'options' in relationship && relationship.options.stEmbedded === 'edit' && !isNew;
      var stEmbeddedTrue = 'options' in relationship && relationship.options.stEmbedded === true;

      if (stEmbeddedCreate || stEmbeddedEdit || stEmbeddedTrue) {
        var belongsTo = snapshot.belongsTo(key);

        if (belongsTo !== undefined) {
          if (belongsTo === null) {
            json.relationships[apiKey] = {
              data: null
            };
          } else {
            //TODO: check that the attr does not already exist (prevent infinite loops and other fun stuff)
            json.relationships[apiKey] = this.serialize(belongsTo, {
              includeId: true
            });
          }
        }

        return json;
      } /////////////////////////////////////////////////////////////////////////////
      // if serializeAlways is true don't want to mess with anything below


      if ('options' in relationship && relationship.options.serializeAlways) {
        return this._super(...arguments);
      } //do NOT send readOnly relations


      if ('options' in relationship && relationship.options.readOnly === true) {
        delete json.relationships[apiKey];
        return json;
      }

      if (update === true && 'options' in relationship && relationship.options.readOnlyUpdate === true) {
        delete json.relationships[apiKey];
        return json;
      }
      /**
       * pre-save snapshot
       *
       * undefined means unknown/unaltered
       * null means data: null (ie: unset if currently set)
       */


      var value = snapshot.belongsTo(key);

      if (typeof value === 'undefined') {
        delete json.relationships[apiKey];
        return json;
      }
      /**
       * BelongsToReference
       */


      var relationRef = snapshot.record.belongsTo(key);
      /**
       * BelongsToRelationship
       */

      var relation = relationRef.belongsToRelationship;
      /**
       * only update known changes
       */

      if (!isNew && relation.hasAnyRelationshipData) {
        var newValue = null;

        if (value != null) {
          newValue = {
            type: value.modelName || value.constructor.modelName,
            id: value.id
          };
        }

        var oldValue = null;

        if (relation.canonicalState != null) {
          oldValue = {
            type: relation.canonicalState.modelName || relation.canonicalState.constructor.modelName,
            id: relation.canonicalState.id
          };
        } /////////////////////////////
        //no change via null


        if (oldValue === null && newValue === null) {
          delete json.relationships[apiKey];
          return json;
        } ///////////////////////////////////////
        // no change from actual values


        if (oldValue !== null && newValue !== null) {
          if (oldValue.type === newValue.type && oldValue.id === newValue.id) {
            delete json.relationships[apiKey];
            return json;
          }
        }
      }

      return this._super(...arguments);
    },

    /**
     * Legacy method, only used to support older versions of ember-data
     *
     * @param {*} snapshot
     * @param {*} key
     * @param {*} relationship
     */
    _shouldSerializeHasMany(snapshot, key, relationship) {
      return this.shouldSerializeHasMany(snapshot, key, relationship);
    },

    /**
     * Used by serializeHasMany to determine of the relation should be serialized
     *
     * @param {*} snapshot
     * @param {*} key
     * @param {*} relationship
     */
    shouldSerializeHasMany(snapshot, key, relationship) {
      if ('options' in relationship && relationship.options.serialize === true) {
        return true;
      }

      if ('options' in relationship && relationship.options.serializeAlways === true) {
        return true;
      }

      return false;
    },

    /**
     * this is called *before* serialize (or at least before serialize as defined below)
     * probably invoked by _super for serialize though
     *
     * @param {*} snapshot
     * @param {*} json
     * @param {*} relationship
     */
    serializeHasMany(snapshot, json, relationship) {
      var key = relationship.key; // stEmbedded records

      var stEmbeddedTrue = 'options' in relationship && relationship.options.stEmbedded === true;

      if (this.shouldSerializeHasMany(snapshot, key, relationship) && stEmbeddedTrue) {
        var hasMany = snapshot.hasMany(key);

        if (hasMany !== undefined) {
          json.relationships = json.relationships || {};

          var payloadKey = this._getMappedKey(key, snapshot.type);

          if (payloadKey === key && this.keyForRelationship) {
            payloadKey = this.keyForRelationship(key, 'hasMany', 'serialize');
          }

          var data = new Array(hasMany.length);

          for (var i = 0; i < hasMany.length; i++) {
            var item = hasMany[i];
            var serializeData = this.serialize(item, {
              includeId: true
            });
            data[i] = serializeData.data;
          }

          json.relationships[payloadKey] = {
            data
          };
          return json;
        }
      }

      return this._super(...arguments);
    },

    /**
     * this manipulates data before sending it to the backend
     * it is not for serializing data from the backend for consumption by ember-data
     */
    serialize(snapshot, options) {
      snapshot;
      options;

      var json = this._super(...arguments);

      var payload = json.data;
      var attributes = payload.attributes;
      var globalMeta = json.meta || {}; // Handle metadata.

      payload.meta = attributes.request_meta;
      delete attributes.response_meta;
      delete attributes.request_meta;
      delete attributes.attribute_enums; // handle top level (request level) meta data

      if (attributes.global_request_meta != null) {
        json.meta = _lodash.default.merge(globalMeta, attributes.global_request_meta);
      }

      delete attributes.global_request_meta;
      return json;
    },

    /**
     *
     * @param {*} store
     * @param {*} primaryModelClass
     * @param {*} payload
     */
    // normalizeSingleResponse(store, primaryModelClass, payload) {
    //     store, primaryModelClass, payload;
    //     return this._super(...arguments);
    // },

    /**
     *
     * @param {*} store
     * @param {*} primaryModelClass
     * @param {*} payload
     * @param {*} id
     * @param {*} requestType
     */
    normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      store, primaryModelClass, payload, id, requestType; // If the links provided by back end are paginated, delete the links cause they don't play nice with Ember Data and hasMany('model').reload() doesn't work
      // https://github.com/emberjs/data/pull/6586/files -- this is the change to ED that broke it

      if (payload.links && (payload.links.first || payload.links.last || payload.links.next || payload.links.previous)) {
        delete payload.links;
      }

      return this._super(...arguments);
    },

    /**
     * deal with meta
     *
     * @method normalize
     * @param {DS.Model} typeClass
     * @param {Object} hash
     * @return {Object}
     */
    normalize(typeClass, resourceHash) {
      var attributeEnums = {};
      var attributes = Ember.get(typeClass, 'attributes');
      var foundEnums = false;

      for (var [index, currAttribute] of attributes.entries()) {
        if (index === 'responseMeta' || index === 'requestMeta' || index === 'globalRequestMeta' || index === 'status') {
          continue;
        }

        var enumValues = _lodash.default.get(currAttribute, 'options.enumValues', false);

        if (!enumValues) {
          continue;
        }

        attributeEnums[index + 'Options'] = enumValues.map(currValue => {
          return {
            id: currValue,
            name: currValue
          };
        });
        foundEnums = true;
      }

      if (foundEnums) {
        resourceHash.attributes.attribute_enums = attributeEnums;
      }

      if (resourceHash.meta) {
        resourceHash.attributes.response_meta = resourceHash.meta;
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});