define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/partner-companies/company/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/partner-companies/base-partner-companies", "ember-concurrency"], function (_exports, _basePartnerCompanies, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basePartnerCompanies.default.extend({
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;

      if (!this.isNewRecord) {
        multiStepForm.registerStepData("company", 'buttons', [{
          text: this.intl.t('common.actions.cancel'),
          click: () => {
            multiStepForm.resetForm(false);
            this.set('companyFormMode', 'view');
          },
          classNamesString: 'st-btn btn-secondary partner-company-cancel-button'
        }, {
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveLeadCompanyTask.perform(multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary partner-company-save-button'
        }]);
      }
    },

    deletePartnerCompany: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = this.multiStepForm;
      var partnerCompany = multiStepForm.formOriginalModelsGetVal('leadCompany.model');

      try {
        yield partnerCompany.destroyRecordWithRollbackTask.perform();
        this.router.transitionTo('administration.company-settings.integrations.partner-company-list');
        this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'confirmDeleteModal'
        });
        throw err;
      }
    })
  });

  _exports.default = _default;
});