define("securitytrax-ember-ui/components/pages/administration/company-settings/equipment/equipment-vendors/component", ["exports", "securitytrax-ember-ui/mixins/sortable-list-mixin", "ember-concurrency"], function (_exports, _sortableListMixin, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_sortableListMixin.default, {
    tagName: '',
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    enhancedFeatures: Ember.inject.service(),
    stFiltersService: Ember.inject.service(),
    inventoryService: Ember.inject.service(),
    equipmentVendor: null,
    equipmentVendorFormStarted: false,
    equipmentVendorFormMode: 'create',
    equipmentVendorStatus: undefined,
    equipmentVendorsListCount: 0,
    limit: 50,
    page: 1,
    isEdit: Ember.computed('equipmentVendorFormMode', function () {
      if (this.equipmentVendorFormMode === 'edit') {
        return true;
      }

      return false;
    }),
    featuresEnabled: Ember.computed('enhancedFeatures.premiumFeatures', function () {
      return this.enhancedFeatures.hasFeature('inventoryExtendedFunctionality');
    }),
    connectors: Ember.computed(function () {
      return this.inventoryService.getConnectorOptionsTask.perform();
    }),
    equipmentVendorFormSteps: Ember.computed('equipmentVendor', 'equipmentVendorFormMode', function () {
      var title = this.intl.t("common.forms.descriptive_title_" + this.equipmentVendorFormMode, {
        name: this.equipmentVendorFormMode === 'create' ? this.intl.t('pages.administration.equipment.equipmentVendors.label') : this.get('equipmentVendor.name'),
        id: this.get('equipmentVendor.id')
      });
      return Ember.Object.create({
        general: Ember.Object.create({
          stepNameKey: 'general',
          title,
          progressBarTitle: this.intl.t('pages.customers.system.order.general'),
          stepComponentPath: 'multi-step-form/forms/administration/equipment/edit-vendor/general'
        }),
        equipmentDetails: Ember.Object.create({
          stepNameKey: 'equipmentDetails',
          title,
          progressBarTitle: this.intl.t('pages.administration.equipment.equipmentVendors.equipment_details'),
          stepComponentPath: 'multi-step-form/forms/administration/equipment/edit-vendor/equipment-details'
        }),
        connectorDetails: Ember.Object.create({
          stepNameKey: 'connectorDetails',
          title,
          progressBarTitle: this.intl.t('pages.administration.equipment.equipmentVendors.connector_details'),
          stepComponentPath: 'multi-step-form/forms/administration/equipment/edit-vendor/connector-details',
          granted: this.grants.getIndividualGrant('equipment_vendors.manage_connector')
        })
      });
    }),
    equipmentVendorFormOriginalModels: Ember.computed('equipmentVendor', 'grants.userGrants', function () {
      return Ember.Object.create({
        equipmentVendor: {
          model: this.equipmentVendor,
          copyRelationMap: {
            equipmentVendorConfig: {
              doCopy: this.grants.getIndividualGrant('equipment_vendors.manage_connector'),
              doValidate: this.grants.getIndividualGrant('equipment_vendors.manage_connector'),
              doSave: this.grants.getIndividualGrant('equipment_vendors.manage_connector')
            }
          }
        }
      });
    }),
    equipmentVendors: Ember.computed(function () {
      return this.getEquipmentVendorsTask.perform();
    }),
    getEquipmentVendorsTask: (0, _emberConcurrency.task)(function* () {
      var newFilters = yield this.stFiltersService.getFiltersByGroupKey('companyEquipmentVendors');
      var equipmentVendors = yield this.store.query('equipmentVendor', {
        filter: newFilters,
        limit: this.limit,
        page: this.page,
        include: 'equipment_vendor_config',
        count: 1
      });
      Ember.set(this, 'equipmentVendorsListCount', equipmentVendors.meta.record_count);
      return equipmentVendors;
    }),
    actions: {
      filterSearch(setPage = true) {
        if (setPage) {
          Ember.set(this, 'page', 1);
        }

        Ember.notifyPropertyChange(this, 'equipmentVendors');
      },

      clearFilters() {
        Ember.setProperties(this, {
          page: 1,
          equipmentVendorStatus: undefined
        });
        Ember.notifyPropertyChange(this, 'equipmentVendors');
        Ember.notifyPropertyChange(this, 'page');
      }

    }
  });

  _exports.default = _default;
});