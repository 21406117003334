define("securitytrax-ember-ui/administration/human-resources/organization/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TLfv6I10",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"page-content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n        \"],[10,\"h1\"],[14,0,\"card-title\"],[12],[2,\"\\n            \"],[10,\"span\"],[12],[2,\"ORGANIZATIONAL STRUCTURE\"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/administration/human-resources/organization/template.hbs"
    }
  });

  _exports.default = _default;
});