define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/monitoring-companies/integrations/bold-integration/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "securitytrax-ember-ui/mixed-in-classes/monitoring-company-connection", "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/monitoring-companies/integrations/integrations-base"], function (_exports, _emberConcurrency, _switchableFormStep, _monitoringCompanyConnection, _integrationsBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var BoldIntegration = (_dec = Ember.inject.service('centralStationService'), _dec2 = Ember.inject.service('intl'), _dec3 = Ember.inject.service('stNotificationManager'), _dec4 = Ember.inject.service('store'), _dec5 = Ember.inject.service('sessionData'), _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
    var config = this.args.multiStepForm.formCopiedModelsGetVal('monitoringCompany.model.monitoringCompanyConfig');
    var savedPanelTypeWhitelistUpdated = [];
    var savedTransmitterTypeWhitelistUpdated = [];
    var savedReceiverLineDesignationWhitelistUpdated = [];

    if (config.get('enabled') && this.args.multiStepForm.formOriginalModelsGetVal('monitoringCompany.model.isConnected')) {
      var hashedData = yield (0, _emberConcurrency.hash)({
        commissioningStatusAvailable: this.commissioningStatusAvailableTask.perform(),
        commissioningStatusOptions: this.getCommissioningStatusOptions.perform(),
        boldSystemOptions: this.getBoldSystemOptionsTask.perform(),
        boldTransmitterTypes: this.getBoldTransmitterTypes.perform(),
        boldReceiverLinePrefixes: this.getBoldReceiverLinePrefixes.perform()
      });
      Ember.setProperties(this, hashedData);
    }

    var panelTypeWhitelist = this.args.multiStepForm.formCopiedModelsGetVal('monitoringCompany.model.monitoringCompanyConfig.panelTypeWhitelist');
    var transmitterTypeWhitelist = this.args.multiStepForm.formCopiedModelsGetVal('monitoringCompany.model.monitoringCompanyConfig.transmitterTypeWhitelist');
    var receiverLineDesignationWhitelist = this.args.multiStepForm.formCopiedModelsGetVal('monitoringCompany.model.monitoringCompanyConfig.receiverLineDesignationWhitelist');
    this.buttons(this.args.multiStepForm); // json serializer turns arrays of strings with just numbers (['34234']) into that string coerced into a number without an array (34234), so this is to make sure they go back to arrays if needed

    if (panelTypeWhitelist && typeof panelTypeWhitelist !== 'object') {
      panelTypeWhitelist = [panelTypeWhitelist];
    }

    if (transmitterTypeWhitelist && typeof transmitterTypeWhitelist !== 'object') {
      transmitterTypeWhitelist = [transmitterTypeWhitelist];
    }

    if (receiverLineDesignationWhitelist && typeof receiverLineDesignationWhitelist !== 'object') {
      receiverLineDesignationWhitelist = [receiverLineDesignationWhitelist];
    } //


    if (this.args.multiStepForm.formOriginalModelsGetVal('monitoringCompany.model.isConnected') && this.args.multiStepForm.formOriginalModelsGetVal('monitoringCompany.model.monitoringCompanyConfig.enabled')) {
      var _hashedData = yield (0, _emberConcurrency.hash)({
        panelOptions: this.boldSystemOptions,
        transmitterOptions: this.boldTransmitterTypes,
        prefixes: this.boldReceiverLinePrefixes
      });

      panelTypeWhitelist.forEach(id => {
        savedPanelTypeWhitelistUpdated.push(_hashedData.panelOptions.find(option => option.id == id));
      });
      transmitterTypeWhitelist.forEach(id => {
        savedTransmitterTypeWhitelistUpdated.push(_hashedData.transmitterOptions.find(option => option.id == id));
      });
      receiverLineDesignationWhitelist.forEach(id => {
        savedReceiverLineDesignationWhitelistUpdated.push(_hashedData.prefixes.find(prefix => prefix.id == id));
      });
      this.panelTypeWhitelist = savedPanelTypeWhitelistUpdated;
      this.transmitterTypeWhitelist = savedTransmitterTypeWhitelistUpdated;
      this.receiverLineDesignationWhitelist = savedReceiverLineDesignationWhitelistUpdated;
    } else {
      this.panelTypeWhitelist = panelTypeWhitelist;
      this.transmitterTypeWhitelist = transmitterTypeWhitelist;
      this.receiverLineDesignationWhitelist = receiverLineDesignationWhitelist;
    }
  }), _dec15 = (0, _emberConcurrency.task)(function* () {
    try {
      var response = yield this.centralStationService.getData({
        model: this.args.monitoringCompany,
        method: 'bold__getCommissioningStatusFunctionalityAvailable'
      });
      return response.commissioning_status_functionality_available;
    } catch (err) {
      (false && Ember.warn(err, {
        id: 'admin.bold-integration.commissioningStatusAvailableTask'
      }));
      return false;
    }
  }), _dec16 = (0, _emberConcurrency.task)(function* () {
    try {
      var options = yield this.centralStationService.getData({
        model: this.args.monitoringCompany,
        method: 'bold__getCommissioningStatuses'
      });
      return options.commissioning_statuses;
    } catch (err) {
      (false && Ember.warn(err, {
        id: 'admin.bold-integration.getCommissioningStatusOptions'
      }));
      return [];
    }
  }), _dec17 = (0, _emberConcurrency.task)(function* () {
    try {
      var panelTypes = yield this.centralStationService.getData({
        model: this.args.monitoringCompany,
        method: 'bold__getPanelTypes',
        payload: {
          full_list: true
        }
      });
      panelTypes.panel_types.forEach(panel => {
        panel.nameIdConcat = "".concat(panel.name, " (ID: ").concat(panel.id, ")");
      });
      return panelTypes.panel_types;
    } catch (err) {
      (false && Ember.warn(err, {
        id: 'admin.integrations.bold-integration.getBoldSystemOptions'
      }));
    }
  }), _dec18 = (0, _emberConcurrency.task)(function* () {
    try {
      var transmitterTypes = yield this.centralStationService.getData({
        model: this.args.monitoringCompany,
        method: 'bold__getTransmitterTypes',
        payload: {
          full_list: true
        }
      });
      transmitterTypes.transmitter_types.forEach(transmitter => {
        transmitter.nameIdConcat = "".concat(transmitter.name, " (ID: ").concat(transmitter.id, ")");
      });
      return transmitterTypes.transmitter_types;
    } catch (err) {
      (false && Ember.warn(err, {
        id: 'admin.integrations.bold-integration.getBoldTransmitterTypes'
      }));
    }
  }), _dec19 = (0, _emberConcurrency.task)(function* () {
    try {
      var receiverLinePrefixes = yield this.centralStationService.getData({
        model: this.args.monitoringCompany,
        method: 'bold__getReceiverLineDesignations',
        payload: {
          full_list: true
        }
      });
      receiverLinePrefixes.receiver_line_designations.forEach(prefix => {
        prefix.nameIdConcat = "".concat(prefix.name, " (ID: ").concat(prefix.id, ")");
      });
      return receiverLinePrefixes.receiver_line_designations;
    } catch (err) {
      (false && Ember.warn(err, {
        id: 'admin.integrations.bold-integration.getBoldReceiverLinePrefixes'
      }));
    }
  }), _dec20 = Ember._action, (_class = (_temp = class BoldIntegration extends _integrationsBase.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "centralStationService", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "sessionData", _descriptor5, this);

      _initializerDefineProperty(this, "commissioningStatusAvailable", _descriptor6, this);

      _initializerDefineProperty(this, "commissioningStatusOptions", _descriptor7, this);

      _initializerDefineProperty(this, "boldSystemOptions", _descriptor8, this);

      _initializerDefineProperty(this, "boldTransmitterTypes", _descriptor9, this);

      _initializerDefineProperty(this, "boldReceiverLinePrefixes", _descriptor10, this);

      _initializerDefineProperty(this, "panelTypeWhitelist", _descriptor11, this);

      _initializerDefineProperty(this, "transmitterTypeWhitelist", _descriptor12, this);

      _initializerDefineProperty(this, "receiverLineDesignationWhitelist", _descriptor13, this);

      _initializerDefineProperty(this, "initFormTask", _descriptor14, this);

      _initializerDefineProperty(this, "commissioningStatusAvailableTask", _descriptor15, this);

      _initializerDefineProperty(this, "getCommissioningStatusOptions", _descriptor16, this);

      _initializerDefineProperty(this, "getBoldSystemOptionsTask", _descriptor17, this);

      _initializerDefineProperty(this, "getBoldTransmitterTypes", _descriptor18, this);

      _initializerDefineProperty(this, "getBoldReceiverLinePrefixes", _descriptor19, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.MonitoringCompanyConnection = new _monitoringCompanyConnection.default(...arguments);
      this.initFormTask.perform();
      Ember.run.later(() => {
        this.buttons(this.args.multiStepForm);
      });
    }

    trimList(listName) {
      var listArray = [];
      this[listName].forEach(item => {
        listArray.push(typeof item === 'object' ? item.id : item);
      });
      Ember.set(this, 'args.multiStepForm.formCopiedModels.monitoringCompany.model.monitoringCompanyConfig.' + listName, listArray);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "commissioningStatusAvailable", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "commissioningStatusOptions", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "boldSystemOptions", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "boldTransmitterTypes", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "boldReceiverLinePrefixes", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "panelTypeWhitelist", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "transmitterTypeWhitelist", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "receiverLineDesignationWhitelist", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "initFormTask", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "commissioningStatusAvailableTask", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "getCommissioningStatusOptions", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "getBoldSystemOptionsTask", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "getBoldTransmitterTypes", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "getBoldReceiverLinePrefixes", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "trimList", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "trimList"), _class.prototype)), _class));
  _exports.default = BoldIntegration;
});