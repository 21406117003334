define("securitytrax-ember-ui/validations/tag", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateObject = {
        objectType: {
          presence: this.customMessageOrDefault(data, 'objectType')
        },
        name: {
          presence: this.customMessageOrDefault(data, 'name'),
          format: {
            pattern: /^.{1,255}$/,
            message: this.customMessage(data, 'nameInvalid')
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject, {
        fullMessages: this.prependProperty(data)
      });
      return this.outputFormatting(validationData, 'tag');
    }

  });

  _exports.default = _default;
});