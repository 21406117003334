define("securitytrax-ember-ui/home/at-risk/route", ["exports", "securitytrax-ember-ui/home/base-route", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/scroll-to-top", "ember-concurrency"], function (_exports, _baseRoute, _authenticatedRouteMixin, _scrollToTop, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_authenticatedRouteMixin.default, _scrollToTop.default, {
    sessionData: Ember.inject.service('sessionData'),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['customer_at_risks.view']);
    },

    model() {
      return this.getModelData.perform();
    },

    getModelData: (0, _emberConcurrency.task)(function* () {
      var user = yield this.modelFor('home').loggedInUser;
      return Ember.RSVP.hash({
        atRiskReasons: this.store.findAll('customerAtRiskReason'),
        atRiskReasonCauses: this.store.findAll('customerAtRiskReasonCause'),
        atRiskReasonResolution: this.store.findAll('customerAtRiskResolution'),
        atRiskCreditReasons: this.store.findAll('customerAtRiskCreditReason'),
        user
      });
    }),

    setupBreadCrumbs() {
      var breadCrumbs = [{
        name: this.intl.t('pages.home.at_risk_entries'),
        previousLink: 'administration',
        last: true
      }];
      var breadCrumbData = {
        titleLink: 'home.view',
        hide: false
      };
      this.homeController.set('breadCrumbs', breadCrumbs);
      this.homeController.set('breadCrumbData', breadCrumbData);
    },

    actions: {
      didTransition() {
        this.setupBreadCrumbs();
      }

    }
  });

  _exports.default = _default;
});