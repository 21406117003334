define("securitytrax-ember-ui/components/pages/accounting/payments/financing/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SBVgnWh3",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"customer/financing-card\",[],[[\"@customer\"],[[32,1,[\"customer\"]]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/accounting/payments/financing/template.hbs"
    }
  });

  _exports.default = _default;
});