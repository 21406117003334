define("securitytrax-ember-ui/components/multi-step-form/forms/administration/payables/level-details/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "@glimmer/component"], function (_exports, _emberConcurrency, _switchableFormStep, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EditLevelDetails = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
    this.payablePayeeLevels = yield this.args.addEditLevel.get('payablePayeeLevels');
    this.lineItemsList = this.lineItems();
  }), _dec21 = (0, _emberConcurrency.task)(function* (buttonAnimationPromise) {
    var lineItems = this.lineItems();
    var savedLineItems = [];
    lineItems.forEach((item, index) => {
      savedLineItems.push(this.saveLineItemTask.perform(item, index));
    });
    yield (0, _emberConcurrency.all)(savedLineItems);
    buttonAnimationPromise.then(() => {
      this.sortingMode = false;
    });
    return true;
  }), _dec22 = (0, _emberConcurrency.task)(function* (item, index) {
    var trueItem = yield this.store.findRecord('payrollColumn', item.get('id'));
    trueItem.set('sort', index + 1);
    trueItem.save();
  }), _dec23 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    yield this.args.multiStepForm.validateList.perform([Ember.Object.create({
      filePath: 'payroll-role-function-level',
      model: {
        name: this.duplicateName
      },
      extraValidationData: {
        overrideValidationKey: 'duplicate-payrollRoleFunctionLevel'
      }
    })]);

    try {
      var newlySavedLevel = (yield this.args.addEditLevel.invokeAction('duplicate', {
        verb: 'POST',
        processResponse: true,
        data: {
          data: {
            attributes: {
              name: this.duplicateName,
              about: this.duplicateDescription
            }
          }
        }
      })).models[0];
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccess(this.intl.t('common.notifications.save_successful'));
        this.args.multiStepForm.formStartedSetVal(false);
        this.showDuplicateLevelModal = false;
        this.addLevel = newlySavedLevel;
        this.router.transitionTo('administration.company-settings.system.payables.function-details.level-details', newlySavedLevel.get('id'));
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      (false && Ember.warn('HR duplicate level error' + err, {
        id: 'multiStepForm.forms.administration.payables.duplicateLevel'
      }));
      throw err;
    }
  }), _dec24 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var multiStepForm = this.args.multiStepForm;
    var columns = this.lineItemsList;
    columns.forEach((column, index) => {
      column.set('sort', index + 1);
    });
    yield multiStepForm.validateSyncSaveAll({
      deepValidate: false
    });
    buttonPromise.then(() => {
      this.stNotificationManager.addSuccess(this.intl.t('common.notifications.save_successful'));
    });
  }), _dec25 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var functionLevelToDelete = this.args.multiStepForm.formOriginalModelsGetVal('payrollRoleFunctionLevel.model');

    try {
      yield functionLevelToDelete.destroyRecordWithRollbackTask.perform();
      buttonPromise.then(() => {
        this.args.multiStepForm.setupCopyModels.perform();
        this.args.multiStepForm.validationErrors = {};
        this.args.payrollRoleFunction.reload();
        this.router.transitionTo('administration.company-settings.system.payables.function-details', this.args.payrollRoleFunction);
        this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      (false && Ember.warn('HR delete level error' + err, {
        id: 'multiStepForm.forms.administration.permissions.deleteLevel'
      }));
      functionLevelToDelete.rollbackAttributes();
      throw err;
    }
  }), _dec26 = (0, _emberConcurrency.task)(function* (row) {
    yield row.destroyRecordWithRollbackTask.perform();
    this.payablePayeeLevelsDeleteId = undefined;
    this.updatePayeeAssignments();
  }), _dec27 = (0, _emberConcurrency.task)(function* (user) {
    Ember.set(user, 'inAddArray', false);
    var addArray = this.addArray;
    var resetAddArray = yield addArray.filter(arrayUser => {
      return arrayUser.user.id != user.user.get('id');
    });
    Ember.set(this, 'addArray', resetAddArray);
    Ember.notifyPropertyChange(this, 'addArray');
  }), _dec28 = Ember._action, _dec29 = Ember._action, _dec30 = Ember._action, _dec31 = Ember._action, _dec32 = Ember._action, _dec33 = Ember._action, _dec34 = Ember._action, _dec35 = Ember._action, _dec36 = Ember._action, _dec37 = Ember._action, _dec38 = Ember._action, (_class = (_temp = class EditLevelDetails extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "showDuplicateLevelModal", _descriptor5, this);

      _initializerDefineProperty(this, "addLineItem", _descriptor6, this);

      _initializerDefineProperty(this, "addLineItemFormStarted", _descriptor7, this);

      _initializerDefineProperty(this, "addAssigneeFormStarted", _descriptor8, this);

      _initializerDefineProperty(this, "addLineItemStepName", _descriptor9, this);

      _initializerDefineProperty(this, "addAssigneeStepName", _descriptor10, this);

      _initializerDefineProperty(this, "lineItemFormMode", _descriptor11, this);

      _initializerDefineProperty(this, "sortingMode", _descriptor12, this);

      _initializerDefineProperty(this, "lineItemsList", _descriptor13, this);

      _initializerDefineProperty(this, "payablePayeeLevels", _descriptor14, this);

      _initializerDefineProperty(this, "duplicateName", _descriptor15, this);

      _initializerDefineProperty(this, "duplicateDescription", _descriptor16, this);

      _initializerDefineProperty(this, "addArray", _descriptor17, this);

      _initializerDefineProperty(this, "payablePayeeLevelsDeleteId", _descriptor18, this);

      _initializerDefineProperty(this, "showDelete", _descriptor19, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor20, this);

      _initializerDefineProperty(this, "saveSortingChangesTask", _descriptor21, this);

      _initializerDefineProperty(this, "saveLineItemTask", _descriptor22, this);

      _initializerDefineProperty(this, "duplicateLevel", _descriptor23, this);

      _initializerDefineProperty(this, "editLevelTask", _descriptor24, this);

      _initializerDefineProperty(this, "deleteLevel", _descriptor25, this);

      _initializerDefineProperty(this, "deletePayablePayeeLevelsTask", _descriptor26, this);

      _initializerDefineProperty(this, "removeUserFromArrayTask", _descriptor27, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.initDataTask.perform();
      Ember.set(this, 'addArray', []);
    }

    lineItems(sort = 'sort') {
      var copiedLevel = this.args.multiStepForm.formCopiedModelsGetVal('payrollRoleFunctionLevel.model');
      var copiedFunction = this.args.multiStepForm.formCopiedModelsGetVal('payrollRoleFunctionLevel.model.payrollRoleFunction');

      if (copiedFunction.get('columnsDerivedFrom') === 'function') {
        return copiedLevel.get('payrollColumns').length > 0 ? copiedLevel.get('payrollColumns').sortBy(sort) : copiedFunction.get('payrollRoleFunctionPayrollColumns').sortBy(sort);
      }

      return copiedLevel.get('payrollColumns').sortBy(sort);
    }

    get sortingClass() {
      if (this.sortingMode === true) {
        return 'actionable-row pointer';
      } else {
        return '';
      }
    }

    get duplicateLevelButtons() {
      return [{
        text: this.intl.t('pages.administration.human_resources.duplicate_level'),
        click: buttonPromise => {
          return this.duplicateLevel.perform(buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary level-save-button'
      }];
    }

    get progressBarConfig() {
      return {
        hideProgressBar: true
      };
    }

    get addLineItemSteps() {
      return Ember.Object.create({
        addLineItem: Ember.Object.create({
          stepNameKey: 'addLineItem',
          title: this.intl.t('pages.administration.human_resources.new_line_item'),
          stepComponentPath: 'multi-step-form/forms/administration/payables/add-line-items'
        })
      });
    }

    get addAssigneeSteps() {
      return Ember.Object.create({
        addAssignee: Ember.Object.create({
          stepNameKey: 'addAssignee',
          title: this.intl.t('pages.administration.human_resources.select_users_for', {
            function: this.args.payrollRoleFunction.name,
            level: this.args.multiStepForm.formOriginalModelsGetVal('payrollRoleFunctionLevel.model.name')
          }),
          stepComponentPath: 'multi-step-form/forms/administration/payables/level-details/add-assignee'
        }),
        review: Ember.Object.create({
          stepNameKey: 'review',
          title: this.intl.t('pages.administration.human_resources.confirm_payee_assignments', {
            function: this.args.payrollRoleFunction.name,
            level: this.args.multiStepForm.formOriginalModelsGetVal('payrollRoleFunctionLevel.model.name')
          }),
          stepComponentPath: 'multi-step-form/forms/administration/payables/level-details/add-assignee/review'
        })
      });
    }

    get formOriginalModels() {
      return Ember.Object.create({
        payrollColumn: {
          model: this.addLineItem,
          copyRelationMap: {
            payrollRoleFunctionLevel: {
              readOnly: true
            }
          }
        }
      });
    }

    cancel() {
      this.args.multiStepForm.setupCopyModels.perform();
      this.args.multiStepForm.validationErrors = {};
      this.router.transitionTo('administration.company-settings.system.payables.function-details', this.args.payrollRoleFunction.get('id'));
    }

    transitionToLineItem(lineItem) {
      if (!this.sortingMode) {
        this.router.transitionTo('administration.company-settings.system.payables.function-details.level-details.line-item-details', lineItem.get('id'), {
          queryParams: {
            payrollRoleFunctionLevelId: this.args.multiStepForm.formCopiedModelsGetVal('payrollRoleFunctionLevel.model.id')
          }
        });
      }
    }

    sortEndAction() {
      this.lineItemsList.forEach((item, index) => {
        item.set('sort', index + 1);
      });
    }

    initSortMode() {
      var lineItems = this.lineItems();
      lineItems.forEach(item => {
        item.set('originalIndex', item.get('sort'));
        item.set('expanded', false);
      });
      this.sortingMode = true;
    }

    cancelSort() {
      var lineItems = this.lineItems('originalIndex');
      lineItems.forEach(item => {
        item.set('sort', item.get('originalIndex'));
      });
      this.lineItemsList = lineItems;
      this.sortingMode = false;
    }

    sortingComplete() {}

    saveSortingChanges(buttonAnimationPromise) {
      return this.saveSortingChangesTask.perform(buttonAnimationPromise);
    }

    clearAddArray() {
      this.addArray.forEach(user => {
        user.inAddArray = false;
      });
      Ember.set(this, 'addArray', []);
      Ember.notifyPropertyChange(this, 'addArray');
    }

    addUserToArray(user) {
      var addArray = this.addArray;
      Ember.set(user, 'inAddArray', true);
      addArray.push(user);
      Ember.set(this, 'addArray', addArray);
      Ember.notifyPropertyChange(this, 'addArray');
    }

    removeUserFromArray(user) {
      this.removeUserFromArrayTask.perform(user);
    }

    updatePayeeAssignments() {
      this.args.addEditLevel.get('payablePayeeLevels').reload();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showDuplicateLevelModal", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "addLineItem", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "addLineItemFormStarted", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "addAssigneeFormStarted", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "addLineItemStepName", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'addLineItem';
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "addAssigneeStepName", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'addAssignee';
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "lineItemFormMode", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'create';
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "sortingMode", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "lineItemsList", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "payablePayeeLevels", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "duplicateName", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "duplicateDescription", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "addArray", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "payablePayeeLevelsDeleteId", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "showDelete", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "saveSortingChangesTask", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "saveLineItemTask", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "duplicateLevel", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "editLevelTask", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "deleteLevel", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "deletePayablePayeeLevelsTask", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "removeUserFromArrayTask", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec28], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transitionToLineItem", [_dec29], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToLineItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortEndAction", [_dec30], Object.getOwnPropertyDescriptor(_class.prototype, "sortEndAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initSortMode", [_dec31], Object.getOwnPropertyDescriptor(_class.prototype, "initSortMode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelSort", [_dec32], Object.getOwnPropertyDescriptor(_class.prototype, "cancelSort"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortingComplete", [_dec33], Object.getOwnPropertyDescriptor(_class.prototype, "sortingComplete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveSortingChanges", [_dec34], Object.getOwnPropertyDescriptor(_class.prototype, "saveSortingChanges"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearAddArray", [_dec35], Object.getOwnPropertyDescriptor(_class.prototype, "clearAddArray"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addUserToArray", [_dec36], Object.getOwnPropertyDescriptor(_class.prototype, "addUserToArray"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeUserFromArray", [_dec37], Object.getOwnPropertyDescriptor(_class.prototype, "removeUserFromArray"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updatePayeeAssignments", [_dec38], Object.getOwnPropertyDescriptor(_class.prototype, "updatePayeeAssignments"), _class.prototype)), _class));
  _exports.default = EditLevelDetails;
});