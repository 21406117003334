define("securitytrax-ember-ui/administration/company-settings/system/global-settings/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class Controller
   * @extends Ember.Controller
   * @memberof administration.company-settings
   */
  var GlobalSettingsController = Ember.Controller.extend(
  /** @lends administration.company-settings.Controller# */
  {
    navigation: Ember.inject.service(),
    sessionData: Ember.inject.service('sessionData'),

    init() {
      this.set('company', null);
      this.set('globalSettings', null);

      this._super(...arguments);

      var companyTask = this.getCompanyFromSessionDataTask.perform();
      companyTask.then(taskComplete => {
        this.set('company', taskComplete.company);
        this.set('globalSettings', taskComplete.globalSettings);
      });
    },

    initializeNav: Ember.on('init', function () {
      this.navigation;
    }),
    getCompanyFromSessionDataTask: (0, _emberConcurrency.task)(function* () {
      var sessionData = yield this.sessionData;
      var hashedData = yield (0, _emberConcurrency.hash)({
        company: sessionData.getCompany(),
        global: sessionData.getCompanySettings()
      });
      return {
        company: hashedData.company,
        globalSettings: hashedData.global
      };
    })
  });
  var _default = GlobalSettingsController;
  _exports.default = _default;
});