define("securitytrax-ember-ui/models/interactive-services-provider", ["exports", "securitytrax-ember-ui/models/base_models/interactive-services-provider"], function (_exports, _interactiveServicesProvider) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _interactiveServicesProvider.default.extend({
    isConnected: Ember.computed('credentialsValid', function () {
      //This is so we can expand later if needed.
      return this.credentialsValid;
    })
  });

  _exports.default = _default;
});