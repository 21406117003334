define("securitytrax-ember-ui/components/pages/administration/human-resources/payables/function-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nHlRxhcA",
    "block": "{\"symbols\":[\"multiStepForm\",\"MultiStepFormComponent\",\"@model\"],\"statements\":[[8,\"multi-step-form\",[],[[\"@formSteps\",\"@formOriginalModels\",\"@usesModal\",\"@currentStepNameKey\",\"@formStarted\"],[[34,1],[34,2],false,\"editFunction\",true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,[32,2],[],[[\"@addEditFunction\",\"@multiStepForm\",\"@formMode\"],[[32,3],[32,1],[34,0]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"functionFormMode\",\"addEditFunctionSteps\",\"formOriginalModels\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/administration/human-resources/payables/function-details/template.hbs"
    }
  });

  _exports.default = _default;
});