define("securitytrax-ember-ui/administration/company-settings/system/global-settings/email/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cfDA/VFM",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],null,[[\"messages\",\"model\",\"breadCrumbs\",\"breadCrumbData\"],[[35,2,[\"messages\"]],[35,2],[35,1],[35,0]]]]]],\"hasEval\":false,\"upvars\":[\"breadCrumbData\",\"breadCrumbs\",\"model\",\"pages/administration/company-settings/global-settings/email\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/administration/company-settings/system/global-settings/email/template.hbs"
    }
  });

  _exports.default = _default;
});