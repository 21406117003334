define("securitytrax-ember-ui/customers/view/edit-create-funding/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-concurrency", "securitytrax-ember-ui/mixins/subroute-modal", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _authenticatedRouteMixin, _emberConcurrency, _subrouteModal, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _subrouteModal.default, _routePageviewLogging.default, {
    grants: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['fundings.view']);
    },

    model(params) {
      return this.setupModelTask.perform(params);
    },

    setupModelTask: (0, _emberConcurrency.task)(function* (params) {
      var parentModel = yield this.modelFor(this.parentRoute);
      return Ember.RSVP.hash({
        customer: parentModel.customer,
        fundingId: params.fid,
        parentRoute: this.transitionRoute
      });
    })
  });

  _exports.default = _default;
});