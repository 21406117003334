define("securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-at-risks/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hvAw3nvI",
    "block": "{\"symbols\":[\"multiStepForm\"],\"statements\":[[6,[37,14],null,[[\"formSteps\",\"formOriginalModels\",\"formStarted\",\"changeFormStarted\"],[[35,13],[35,12],[35,10],[30,[36,0],[[32,0],[30,[36,11],[[35,10]],null]],null]]],[[\"default\"],[{\"statements\":[[6,[37,9],null,[[\"modalButtons\",\"title\",\"classNames\",\"dialogOpen\",\"onClose\",\"multiStepForm\",\"newWindowLink\",\"customer\"],[[32,1,[\"currentStepData\",\"buttons\"]],[32,1,[\"currentStepData\",\"title\"]],\"st-modal--full st-modal--min-height-60 edit-at-risk-entry-modal\",[32,1,[\"formStarted\"]],[30,[36,0],[[32,0],\"transitionBackToParentRoute\"],null],[32,1],true,[35,7]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,8],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],[[\"progressBarHidden\",\"multiStepForm\",\"customer\",\"atRiskEntry\",\"atRiskReasons\",\"atRiskReasonCauses\",\"atRiskResolutions\",\"atRiskCreditReasons\",\"formMode\",\"transitionBackToParent\"],[true,[32,1],[35,7],[35,6],[35,5],[35,4],[35,3],[35,2],[35,1],[30,[36,0],[[32,0],\"transitionBackToParentRoute\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"atRiskEntryFormMode\",\"atRiskCreditReasons\",\"atRiskResolutions\",\"atRiskReasonCauses\",\"atRiskReasons\",\"atRiskEntry\",\"customer\",\"component\",\"modals/st-paper\",\"atRiskEntryFormStarted\",\"mut\",\"atRiskEntryFormOriginalModels\",\"atRiskEntryFormSteps\",\"multi-step-form\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-at-risks/template.hbs"
    }
  });

  _exports.default = _default;
});