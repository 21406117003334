define("securitytrax-ember-ui/components/multi-step-form/forms/at-risk-entries/edit-at-risk-entry/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "securitytrax-ember-ui/mixins/set-user", "ember-concurrency"], function (_exports, _switchableFormStep, _setUser, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, _setUser.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    userService: Ember.inject.service(),
    screen: Ember.inject.service(),
    grants: Ember.inject.service(),
    homeDashboard: Ember.inject.service('events/home-dashboard'),
    confirmDeleteModalOpen: false,
    resolutionAttemptFormStarted: false,
    resolutionAttempt: undefined,
    resolutionAttemptFormMode: 'edit',

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      var buttonChoices = [];

      if (this.formMode !== 'view') {
        buttonChoices.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveAtRiskEntry.perform(multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary save-at-risk-entry'
        });
      }

      if (!this.isNewRecord && this.grants.getIndividualGrant('customer_at_risks.delete')) {
        buttonChoices.push({
          text: this.intl.t('common.actions.delete'),
          click: () => {
            this.set('confirmDeleteModalOpen', true);
          },
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
        });
      }

      multiStepForm.registerStepData('step1', 'buttons', buttonChoices);
    },

    userOptions: Ember.computed(function () {
      return this.userService.getUsersList('at_risk_assignee', this.customer);
    }),
    saveAtRiskEntry: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      try {
        var validationObject = {
          extraValidationData: {
            validationMessages: {
              'customerAtRisk-customerAtRiskReason': '^' + this.intl.t("pages.customers.at_risk_entries.validation.reason")
            }
          }
        };

        if (this.isNewRecord) {
          var toCreateObjects = yield multiStepForm.validateCreateAll(validationObject);
          Ember.set(toCreateObjects.customerAtRisk.data, 'customer', this.customer);
          var customerAtRisk = this.store.createRecord('customerAtRisk', toCreateObjects.customerAtRisk.data);
          this.atRiskEntry = yield toCreateObjects.customerAtRisk.saveWithRollbackAndNotification(customerAtRisk);
        } else {
          yield multiStepForm.validateSyncSaveAll(validationObject);
        } // updates the last contact attempt for at risk list


        this.get('customer.customerAtRisks').reload();
        yield this.atRiskEntry.customerAtRiskNotes.reload();
        buttonPromise.then(() => {
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
          this.homeDashboard.trigger('refresh-at-risk-entries');

          if (this.transitionBackToParent && typeof this.transitionBackToParent === 'function') {
            this.transitionBackToParent();
          }
        });
      } catch (err) {
        (false && Ember.warn('Failed in At Risk Entry Save: ' + err, {
          id: 'multiStepForm.forms.edit-at-risk-entry.saveAtRiskEntry'
        }));
        throw err;
      }
    }),
    deleteAtRiskEntry: (0, _emberConcurrency.task)(function* (buttonPromise) {
      try {
        var multiStepForm = this.multiStepForm;
        var atRiskEntryToDelete = yield multiStepForm.formOriginalModelsGetVal('customerAtRisk.model');
        yield atRiskEntryToDelete.destroyRecordWithRollbackTask.perform();
        buttonPromise.then(() => {
          this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
          this.homeDashboard.trigger('refresh-at-risk-entries');

          if (this.transitionBackToParent && typeof this.transitionBackToParent === 'function') {
            this.transitionBackToParent();
          }
        });
      } catch (err) {
        (false && Ember.warn('Delete At Risk Entry Failed: ' + err, {
          id: 'multiStepForm.forms.edit-at-risk-entry.deleteAtRiskEntry'
        }));
        throw err;
      }
    }),
    resolutionAttemptsFormSteps: Ember.computed('resolutionAttempt', 'resolutionAttemptFormMode', function () {
      var titleTranslation = this.resolutionAttemptFormMode === 'create' ? 'pages.customers.at_risk_entries.new_note' : "pages.customers.at_risk_entries.".concat(this.resolutionAttemptFormMode, "_note");
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.intl.t(titleTranslation, {
            id: this.get('resolutionAttempt.id')
          }),
          stepComponentPath: 'multi-step-form/forms/at-risk-entries/edit-at-risk-entry/edit-resolution-attempt'
        })
      });
    }),
    resolutionAttemptsFormOriginalModels: Ember.computed('resolutionAttempt', function () {
      return Ember.Object.create({
        customerAtRiskNote: {
          model: this.resolutionAttempt,
          copyRelationMap: {
            customerAtRisk: {
              readOnly: true
            }
          }
        }
      });
    }),
    getUserAndSet: (0, _emberConcurrency.task)(function* (userId) {
      var uid = userId;

      if (uid === null) {
        return null;
      }

      if (typeof uid === 'object') {
        uid = uid.id;
      }

      var theUser = yield this.store.findRecord('user', uid);
      this.multiStepForm.formCopiedModelsSetVal('customerAtRisk.model.assignedUser', theUser);
    })
  });

  _exports.default = _default;
});