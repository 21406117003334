define("securitytrax-ember-ui/components/pages/administration/human-resources/payables/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q+6TSuUu",
    "block": "{\"symbols\":[\"@breadCrumbs\",\"@breadCrumbData\",\"@model\"],\"statements\":[[8,\"nav/top-nav-sub\",[],[[\"@title\",\"@breadCrumbs\",\"@breadCrumbData\"],[[30,[36,0],[\"pages.administration.human_resources.payables\"],null],[32,1],[32,2]]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"page-content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"scroll-page\"],[12],[2,\"\\n        \"],[8,\"pages/administration/human-resources/payables/function-card\",[],[[\"@name\",\"@payrollRoleId\",\"@model\"],[\"sales\",\"1\",[32,3]]],null],[2,\"\\n\\n        \"],[8,\"pages/administration/human-resources/payables/function-card\",[],[[\"@name\",\"@payrollRoleId\",\"@model\"],[\"technician\",\"2\",[32,3]]],null],[2,\"\\n\\n\"],[2,\"    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/administration/human-resources/payables/template.hbs"
    }
  });

  _exports.default = _default;
});