define("securitytrax-ember-ui/components/multi-step-form/forms/payables/edit-create-payment/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step", "ember-concurrency"], function (_exports, _switchableFormStep, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    grants: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    confirmDeleteModalOpen: false,

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      var buttons = [];

      if (this.formMode !== 'view') {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.savePaymentTask.perform(buttonPromise, multiStepForm);
          },
          classNamesString: 'st-btn st-btn-primary save-payable-payment'
        });
      }

      if (!this.myAccount && !this.isNewRecord && this.grants.getIndividualGrant('customer_payable_payments.delete')) {
        buttons.push({
          text: this.intl.t('common.actions.delete'),
          click: () => {
            this.set('confirmDeleteModalOpen', true);
          },
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
        });
      }

      multiStepForm.registerStepData('step1', 'buttons', buttons);
    },

    savePaymentTask: (0, _emberConcurrency.task)(function* (buttonPromise, multiStepForm) {
      if (this.isNewRecord) {
        multiStepForm.formCopiedModelsSetVal('customerPayablePayment.model.customerPayable', this.customerPayable);
        multiStepForm.formCopiedModelsSetVal('customerPayablePayment.model.amount', multiStepForm.formCopiedModelsGetVal('customerPayablePayment.model.amount') || 0.00);
        var toCreateObjects = yield multiStepForm.validateCreateAll();
        var customerPayablePayment = this.store.createRecord('customerPayablePayment', toCreateObjects.customerPayablePayment.data);
        yield toCreateObjects.customerPayablePayment.saveWithRollbackAndNotification(customerPayablePayment, 'modal2');
      } else {
        yield multiStepForm.validateSyncSaveAll();
      }

      yield this.customerPayable.reload();
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        multiStepForm.formStartedSetVal(false);
      });
    }),
    deleteCustomerPayablePayment: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = this.multiStepForm;
      var customerPayablePayment = multiStepForm.formOriginalModelsGetVal('customerPayablePayment.model');
      yield customerPayablePayment.destroyRecordWithRollbackTask.perform();
      multiStepForm.onDelete();
      this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
      multiStepForm.formStartedSetVal(false);
    })
  });

  _exports.default = _default;
});