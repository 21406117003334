define("securitytrax-ember-ui/models/customer-payment", ["exports", "securitytrax-ember-ui/models/base_models/customer-payment"], function (_exports, _customerPayment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerPayment.default.extend({
    intl: Ember.inject.service(),
    amountDisplay: Ember.computed('amount', function () {
      return ('$' + Number(this.amount || 0.00).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')).replace('$-', '-$');
    }),
    postedDateDisplay: Ember.computed('desiredPostingDate', 'postedDate', function () {
      if (this.postedDate != undefined) {
        return this.postedDate;
      } else if (this.desiredPostingDate != undefined) {
        return this.desiredPostingDate;
      }

      return '';
    }),
    clearedDateDisplay: Ember.computed('clearedDate', 'expectedClearingDate', function () {
      if (this.clearedDate != undefined) {
        return this.clearedDate;
      } else if (this.expectedClearingDate != undefined) {
        return this.expectedClearingDate;
      }

      return '';
    }),
    icon: Ember.computed('cleared', function () {
      return this.cleared ? 'icn-circle-check' : '';
    }),
    processingStatusDisplay: Ember.computed('processingStatus', function () {
      var key = "pages.customers.accounting.customer_billing.".concat(this.processingStatus);

      if (this.intl.exists(key)) {
        return this.intl.t(key);
      } else if (this.responseMeta && this.responseMeta.processing_response) {
        return this.responseMeta.processing_response;
      } else {
        return null;
      }
    }),

    /*
    processedAtDisplay: computed('processedAt', function () {
        return this.getDate(this.get('processedAt'), 'MM/DD/YYYY | h:mm A');
    }),
     processingStatusSubmittedDisplay: computed('processingStatusSubmitted', function () {
        return this.getDate(this.get('processingStatusSubmitted'), 'MM/DD/YYYY | h:mm A');
    }),
    */
    processedByName: Ember.computed('processedByUser.flname', function () {
      return this.get('processedByUser.flname');
    }),

    /*
        customer-payment is supposed to be connected to either customerBilling or customerPaymentType, but not both at the same time
        this function tells you which is currently selected (if either)
        returns empty string if it's neither
    */
    customerBillingOrPaymentType: Ember.computed('customerBilling.title', 'customerPaymentType.name', function () {
      var selectedCompiledPaymentType = '';

      if (this.belongsTo('customerBilling').id()) {
        selectedCompiledPaymentType = 'customerBilling';
      } else if (this.belongsTo('customerPaymentType').id()) {
        selectedCompiledPaymentType = 'customerPaymentType';
      }

      return selectedCompiledPaymentType;
    }),
    customerBillingOrPaymentTypeLabel: Ember.computed('customerBillingOrPaymentType', function () {
      var selectedCompiledPaymentType = this.customerBillingOrPaymentType;

      if (selectedCompiledPaymentType == 'customerBilling') {
        return this.get('customerBilling.billType.name');
      } else if (selectedCompiledPaymentType == 'customerPaymentType') {
        return this.get('customerPaymentType.name');
      }

      return '';
    }),
    processingStatusColor: Ember.computed('processingStatus', function () {
      var color;

      switch (this.processingStatus) {
        case 'failed':
          color = '#d92c29'; //$adc-accent-red

          break;

        case 'canceled':
          color = '#f58220'; //$adc-accent-yellow

          break;

        case 'complete':
          color = '#23AB3F'; //$adc-accent-green

          break;

        default:
          color = '#949494';
        //$black-40
      }

      return "color: ".concat(color, ";");
    })
    /** BELONGS TO **/

    /** BELONGS TO **/

  });

  _exports.default = _default;
});