define("securitytrax-ember-ui/components/calendar-glimmer/calendar-helpers/calendar-page/calendar-page-date", ["exports", "ember-concurrency", "moment", "securitytrax-ember-ui/components/calendar-glimmer/calendar-helpers/calendar-page/calendar-page", "securitytrax-ember-ui/utils/calendar-hours", "securitytrax-ember-ui/components/calendar-glimmer/calendar-helpers/calendar-page/calendar-row", "lodash"], function (_exports, _emberConcurrency, _moment, _calendarPage, _calendarHours, _calendarRow, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CalendarPageDate = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = (0, _emberConcurrency.task)(function* (event) {
    var userRowId = event.calendarUser.id;

    if (this.pendingAddCalendarEvent) {
      yield this.pendingAddCalendarEventTask.perform();
    }

    this.pendingAddCalendarEvent = event;
    var theRow = yield this.getRowById(userRowId);
    theRow.addCalendarEvent(event);
    this.CalendarEventsService.trigger('refresh-row', userRowId);
  }), _dec7 = (0, _emberConcurrency.task)(function* () {
    var pendingRowId = this.pendingAddCalendarEvent.calendarUser.id;
    var pendingRow = yield this.getRowById(pendingRowId);
    yield pendingRow.removeCalendarEvent(this.pendingAddCalendarEvent.id);
  }), _dec8 = (0, _emberConcurrency.task)(function* () {
    var startOfDay = this.date ? (0, _moment.default)(this.date, 'MM/DD/YYYY').startOf('day') : (0, _moment.default)().startOf('day');
    var endOfDay = this.date ? (0, _moment.default)(this.date, 'MM/DD/YYYY').endOf('day') : (0, _moment.default)().endOf('day');
    var userCat = this.userType === 'sales_rep' ? 'and calendar_category = \'sale_schedule\'' : 'and calendar_category = \'tech_schedule\'';

    if (this.userType === 'all') {
      userCat = '';
    }

    var userQuery = '';

    if (this.newUsers.length > 0) {
      var userMap = this.newUsers.map(user => {
        return user.get('id');
      });

      if (userMap.length > 0) {
        userQuery = ' and calendar_user.id = [' + userMap.join(',') + ']';
      }
    }

    var defaultFilter = '(start_time >= \'' + startOfDay.format('YYYY-MM-DDTHH:mm:ss') + '\') and (start_time <= \'' + endOfDay.format('YYYY-MM-DDTHH:mm:ss') + '\') ' + userCat + userQuery;
    var calendarEvents = yield this.store.query('calendarEvent', {
      filter: defaultFilter
      /*  + (this.get('filters.length') > 0 ? (' and ' + (this.filters.join(' and '))) : ''), */
      ,
      include: 'calendar_user.user,calendar_user,calendar_event_detail_type,calendar_event_type.calendar_event_detail_types,customer,work_order,dry_run_reason',
      sort: 'start_time, duration'
    });

    if (this.hours === undefined || this.didUpdate) {
      var minStartTime = (0, _moment.default)(this.date, 'MM/DD/YYYY').hour(this.startTime.hour()).startOf('hour');
      var maxEndTime = (0, _moment.default)(this.date, 'MM/DD/YYYY').hour(this.endTime.hour()).startOf('hour');

      if ((0, _moment.default)(this.endTime).isAfter(endOfDay)) {
        maxEndTime = (0, _moment.default)(endOfDay);
      }

      if (calendarEvents.meta.min_datetime && minStartTime > (0, _moment.default)(calendarEvents.meta.min_datetime).startOf('hour')) {
        minStartTime = (0, _moment.default)(calendarEvents.meta.min_datetime).startOf('hour');
      }

      if (calendarEvents.meta.max_datetime && maxEndTime < (0, _moment.default)(calendarEvents.meta.max_datetime).add(30, 'minutes').startOf('hour')) {
        maxEndTime = (0, _moment.default)(calendarEvents.meta.max_datetime).add(30, 'minutes').startOf('hour');
      }

      var halfHours = yield (0, _calendarHours.default)(minStartTime, maxEndTime);
      this.hours = halfHours;
    }

    if (this.hiddenAppointmentTypes) {
      var appointmentsList = Ember.A(calendarEvents.toArray());
      this.calendarEvents = appointmentsList.filter(appointment => {
        var key = _lodash.default.snakeCase(appointment.calendarEventType.get('name'));

        return !this.hiddenAppointmentTypes.includes(key);
      });
    } else {
      this.calendarEvents = calendarEvents.toArray();
    }
  }).enqueue().maxConcurrency(10), _dec9 = (0, _emberConcurrency.task)(function* () {
    if (this.didUpdate) {
      yield this.getCalendarEventsAndHours.perform();
      yield this.calendarEventsByRowTask.perform();
    }

    var rows = Ember.A();
    var calendarEventsByRow = this.calendarEventsByRow;
    var pendingAddCalendarEvent = this.pendingAddCalendarEvent;
    var pendingEventUserId = null;

    if (pendingAddCalendarEvent) {
      pendingEventUserId = pendingAddCalendarEvent.get('calendarUser.id');
    } //'=== ===========================date users', this.users)


    if (this.users.length > 0) {
      for (var x = 0; x < this.users.length; x++) {
        var user = this.users.objectAt(x);
        var displayRow = false;

        if (user.show && user.id != 'inactive') {
          var isSalesRep = yield user.isSalesRep;
          var isTech = yield user.isTech;

          if (this.userType === 'tech_schedule' || this.userType === 'tech') {
            displayRow = isTech;
          } else if (this.userType === 'sales_rep') {
            displayRow = isSalesRep;
          } else {
            displayRow = true;
          }

          if (displayRow) {
            var rowId = user.get('id');
            var userEvents = this.calendarEventsByRow[rowId] || []; // check to see if we need to also add a pending (fake) calendarEvent to the list

            if (pendingEventUserId === user.get('id')) {
              userEvents.pushObject(pendingAddCalendarEvent);
            }

            var row = new _calendarRow.default({
              id: rowId,
              user: user,
              isSalesRep: isSalesRep,
              isTech: isTech,
              type: 'date',
              date: this.date,
              calendarPage: this,
              calendarEvents: userEvents,
              isSelectedForMap: false
            });
            rows.push(row);
          }
        }
      }

      if (calendarEventsByRow['inactive']) {
        var _row = new _calendarRow.default({
          id: 'inactive',
          user: null,
          type: 'date',
          date: this.date,
          calendarPage: this,
          calendarEvents: calendarEventsByRow['inactive'],
          isSelectedForMap: false
        });

        rows.push(_row);
      }

      this.rows = rows;
    }

    return rows;
  }).enqueue().maxConcurrency(1), _dec10 = (0, _emberConcurrency.task)(function* () {
    try {
      var byUserCalendarEvents = [];
      var calendarEvents = yield this.calendarEvents;

      for (var x = 0; x < calendarEvents.length; x++) {
        var currEvent = calendarEvents.objectAt(x);

        if (currEvent.isPendingAdd) {
          return;
        }

        var currentUser = yield currEvent.get('calendarUser'); //MATT TODO Kill YIELD IN FOR

        if (!currentUser || !currentUser.active) {
          if (byUserCalendarEvents['inactive'] == undefined) {
            byUserCalendarEvents['inactive'] = [];
          }

          byUserCalendarEvents['inactive'].pushObject(currEvent);
        } else {
          if (byUserCalendarEvents[currentUser.id] == undefined) {
            byUserCalendarEvents[currentUser.id] = [];
          }

          byUserCalendarEvents[currentUser.id].pushObject(currEvent);
        }
      }

      this.calendarEventsByRow = byUserCalendarEvents;
    } catch (error) {
      (false && Ember.warn('calendarEventsByRow page date failed ' + error, {
        id: 'calendar.calendarGrid.calendarEventsByUserIdTask'
      }));
    }
  }), (_class = (_temp = class CalendarPageDate extends _calendarPage.default {
    constructor(routeArguments, requiredArguments) {
      super(...arguments);

      _defineProperty(this, "date", void 0);

      _defineProperty(this, "startTime", void 0);

      _defineProperty(this, "endTime", void 0);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "pendingAddCalendarEvent", _descriptor2, this);

      _initializerDefineProperty(this, "pendingEventUserId", _descriptor3, this);

      _initializerDefineProperty(this, "calendarEventsByRow", _descriptor4, this);

      _initializerDefineProperty(this, "selectedRowsForMap", _descriptor5, this);

      _initializerDefineProperty(this, "addPendingAddCalendarEvent", _descriptor6, this);

      _initializerDefineProperty(this, "pendingAddCalendarEventTask", _descriptor7, this);

      _initializerDefineProperty(this, "getCalendarEventsAndHours", _descriptor8, this);

      _initializerDefineProperty(this, "getPageRowsTask", _descriptor9, this);

      _initializerDefineProperty(this, "calendarEventsByRowTask", _descriptor10, this);

      if (requiredArguments && requiredArguments.date) {
        this.date = requiredArguments.date.date;
        this.type = 'date';
        this.id = (0, _moment.default)(requiredArguments.date.date).format('M-D-YYYY');
        this.selected = requiredArguments.date.selected;
      }

      if (requiredArguments && requiredArguments.users) {
        this.users = requiredArguments.users;
      }

      if (requiredArguments && requiredArguments.startTime) {
        this.startTime = requiredArguments.startTime;
      }

      if (requiredArguments && requiredArguments.endTime) {
        this.endTime = requiredArguments.endTime;
      }

      if (requiredArguments && requiredArguments.store) {
        this.store = requiredArguments.store;
      }

      if (requiredArguments && requiredArguments.CalendarEventsService) {
        this.CalendarEventsService = requiredArguments.CalendarEventsService;
        this.CalendarEventsService.on('update-default-start-end-times', this, '_updateDefaultStartEndTimes');
      }

      if (requiredArguments && requiredArguments.userType) {
        this.userType = requiredArguments.userType;
      }

      this.didUpdate = true;
    }

    willDestroy() {
      this.CalendarEventsService.off('update-default-start-end-times', this, '_updateDefaultStartEndTimes');
    }

    _updateDefaultStartEndTimes(startTime, endTime) {
      this.startTime = startTime;
      this.endTime = endTime;
      this.didUpdate = true;
    }

    hideDate() {
      this.hidden = true;
    }

    showDate() {
      this.hidden = false;
    }

    removePendingCalendarEvent() {
      this.pendingAddCalendarEventTask.perform();
    }

    getRowById(id) {
      var rows = this.rows;

      for (var x = 0; x < rows.length; x++) {
        var row = rows.objectAt(x);

        if (row.id === id) {
          return row;
        }
      }

      return null;
    }

    getNextRows() {
      if (this.maxIndex + 20 < this.users.length) {
        this.maxIndex += 20;
      } else {
        this.maxIndex = this.users.length;
      }
    }

    get pageRows() {
      return this.getPageRowsTask.unlinked().perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "pendingAddCalendarEvent", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "pendingEventUserId", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "calendarEventsByRow", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "selectedRowsForMap", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "addPendingAddCalendarEvent", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "pendingAddCalendarEventTask", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "getCalendarEventsAndHours", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "getPageRowsTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "calendarEventsByRowTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CalendarPageDate;
});