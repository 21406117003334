define("securitytrax-ember-ui/components/multi-step-form/component", ["exports", "@glimmer/component", "jquery", "lodash", "ember-concurrency", "tracked-toolbox"], function (_exports, _component, _jquery, _lodash, _emberConcurrency, _trackedToolbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _dec40, _dec41, _dec42, _dec43, _dec44, _dec45, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _descriptor38, _descriptor39, _descriptor40, _descriptor41, _descriptor42, _descriptor43, _descriptor44, _descriptor45, _descriptor46, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var MultiStepForm = (_dec = Ember.inject.service('store'), _dec2 = Ember.inject.service('scroller'), _dec3 = Ember.inject.service('stNotificationManager'), _dec4 = Ember.inject.service('intl'), _dec5 = (0, _trackedToolbox.localCopy)('args.formOriginalModels', () => {
    return Ember.Object.create({});
  }), _dec6 = (0, _trackedToolbox.localCopy)('args.currentStepNameKey', undefined), _dec7 = (0, _trackedToolbox.localCopy)('args.formSteps', () => {
    return Ember.Object.create({});
  }), _dec8 = (0, _trackedToolbox.localCopy)('args.formStarted', false), _dec9 = (0, _trackedToolbox.localCopy)('args.progressBarConfig', undefined), _dec10 = (0, _trackedToolbox.localCopy)('args.progressBarTemplate', undefined), _dec11 = (0, _trackedToolbox.localCopy)('args.onBeforeSave', () => {
    return () => {
      (false && Ember.warn('No onBeforeSave was passed into multiStepForm', {
        id: 'multiStepForm.onBeforeSave'
      }));
    };
  }), _dec12 = (0, _trackedToolbox.localCopy)('args.onAfterSave', () => {
    return () => {
      (false && Ember.warn('No onAfterSave was passed into multiStepForm', {
        id: 'multiStepForm.onAfterSave'
      }));
    };
  }), _dec13 = (0, _trackedToolbox.localCopy)('args.onBeforeValidate', () => {
    return () => {
      (false && Ember.warn('No onBeforeValidate was passed into multiStepForm', {
        id: 'multiStepForm.onBeforeValidate'
      }));
    };
  }), _dec14 = (0, _trackedToolbox.localCopy)('args.onAfterValidate', () => {
    return () => {
      (false && Ember.warn('No onAfterValidate was passed into multiStepForm', {
        id: 'multiStepForm.onAfterValidate'
      }));
    };
  }), _dec15 = (0, _trackedToolbox.localCopy)('args.onBeforeSync', () => {
    return () => {
      (false && Ember.warn('No onBeforeSync was passed into multiStepForm', {
        id: 'multiStepForm.onBeforeSync'
      }));
    };
  }), _dec16 = (0, _trackedToolbox.localCopy)('args.onAfterSync', () => {
    return () => {
      (false && Ember.warn('No onAfterSync was passed into multiStepForm', {
        id: 'multiStepForm.onAfterSync'
      }));
    };
  }), _dec17 = (0, _trackedToolbox.localCopy)('args.onDelete', () => {
    return () => {
      (false && Ember.warn('No onDelete was passed into multiStepForm', {
        id: 'multiStepForm.onDelete'
      }));
    };
  }), _dec18 = (0, _trackedToolbox.localCopy)('args.additionalData', undefined), _dec19 = Ember._tracked, _dec20 = Ember._tracked, _dec21 = Ember._tracked, _dec22 = Ember.computed('args.formOriginalModels'), _dec23 = Ember.computed('args.formStarted'), _dec24 = (0, _emberConcurrency.task)(function* () {
    // setupFormTask
    var formSteps = yield this.formSteps; //////////////////////////////////////////////////////////
    // do a little setup validation for the form

    if (formSteps.get('length') <= 0) {
      (false && Ember.warn('You must pass `formSteps` into the multi-step-form', {
        id: 'multiStepForm.setupForm'
      }));
      return;
    }

    if (!this.currentStepNameKey) {
      this.goToFirstStep();
    }

    this.setupCopyModels.unlinked().perform();
  }), _dec25 = (0, _emberConcurrency.task)(function* () {
    // setFocusToFirstInput
    // wait until all copied models are done and st-awaits are finished to select the first input
    yield (0, _emberConcurrency.waitForProperty)(this, 'finishedCopyingModels', true);
  }), _dec26 = (0, _emberConcurrency.task)(function* (currentStepNameKey) {
    // currentStepDataTask
    var formSteps = yield this.formSteps;

    if (currentStepNameKey) {
      var currentStepNameKeySplitArr = currentStepNameKey.split('::');

      if (currentStepNameKeySplitArr.length > 1) {
        // merge the parent and child step data so that we don't have change the format of currentStepData and still have access to everything just as before
        var currentStepData = formSteps.get("".concat(currentStepNameKeySplitArr[0]));
        var currentSubStepData = formSteps.get("".concat(currentStepNameKeySplitArr[0], ".subSteps.").concat(currentStepNameKeySplitArr[1]));
        Ember.set(currentStepData, 'fullStepNameKey', currentStepData.stepNameKey + '::' + currentSubStepData.stepNameKey);
        Ember.set(currentStepData, 'stepComponentPath', currentSubStepData.stepComponentPath);

        if (currentSubStepData.buttons) {
          Ember.set(currentStepData, 'buttons', currentSubStepData.buttons);
        } else {
          Ember.set(currentStepData, 'buttons', []);
        }

        if (currentSubStepData.title) {
          Ember.set(currentStepData, 'title', currentSubStepData.title);
        }

        this.currentStepData = currentStepData;
        return currentStepData;
      } else {
        var _currentStepData = formSteps.get("".concat(currentStepNameKey));

        this.currentStepData = _currentStepData;
        return _currentStepData;
      }
    } else {
      this.currentStepData = {};
      return {};
    }
  }), _dec27 = (0, _emberConcurrency.task)(function* () {
    // goToFirstStepTask
    var formSteps = yield this.formSteps; ///////////////////////////////////////////////////////////////
    // choose the first step as the current step on init

    var [firstStepKey] = Object.keys(formSteps);

    if (firstStepKey) {
      var subSteps = _lodash.default.get(formSteps.get("".concat(firstStepKey)), 'subSteps', false); // if the first has substeps we need to go to the first substep instead


      if (subSteps) {
        var [firstSubStepKey] = Object.keys(subSteps);
        this.currentStepNameKeySetVal("".concat(firstStepKey, "::").concat(firstSubStepKey));
      } else {
        this.currentStepNameKeySetVal(firstStepKey);
      }
    }
  }), _dec28 = (0, _emberConcurrency.task)(function* (item, overrideOriginalModelsConfig = null) {
    // copyRelationMap
    if (overrideOriginalModelsConfig) {
      return {
        relationships: overrideOriginalModelsConfig[item]['copyRelationMap']
      };
    }

    var formOriginalModels = yield this.formOriginalModels;
    var copyRelationMap = {};

    if ('copyRelationMap' in formOriginalModels[item]) {
      copyRelationMap = {
        relationships: formOriginalModels[item]['copyRelationMap']
      };
    }

    return copyRelationMap;
  }), _dec29 = (0, _emberConcurrency.task)(function* (item) {
    // isCreateTask
    var formOriginalModels = yield this.formOriginalModels;

    if (!formOriginalModels[item]['model']) {
      return true;
    }

    return false;
  }), _dec30 = (0, _emberConcurrency.task)(function* () {
    // setupCopyModels
    try {
      var formOriginalModels = yield this.formOriginalModels;

      if (formOriginalModels) {
        var formCopiedModels = Ember.Object.create({});
        var promiseQueue = [];
        var formOriginalModelsKeys = Object.keys(formOriginalModels);

        for (var i = 0; i < formOriginalModelsKeys.get('length'); i++) {
          var item = formOriginalModelsKeys.objectAt(i);

          if (typeof formOriginalModels[item] === 'object') {
            var copyRelationMap = yield this.copyRelationMap.unlinked().perform(item);
            formOriginalModels[item].isCreate = yield this.isCreate(item);

            if (formOriginalModels[item].isCreate) {
              Ember.set(formOriginalModels[item], 'model', this.store.createRecord(item)); // TODO - item should probably not be here - it should be a model name that is passed under item
            }

            promiseQueue.push(formOriginalModels[item].model.deepCopy({
              relationshipMap: copyRelationMap,
              isCreate: formOriginalModels[item].isCreate
            }));
          }
        }

        var resolvedData = yield (0, _emberConcurrency.all)(promiseQueue);

        for (var index = 0; index < formOriginalModelsKeys.get('length'); index++) {
          var _item = formOriginalModelsKeys.objectAt(index);

          if (typeof formOriginalModels[_item] === 'object') {
            formCopiedModels[_item] = Ember.Object.create({
              model: resolvedData[index]
            });

            if (formOriginalModels[_item].isCreate && formOriginalModels[_item].model) {
              formOriginalModels[_item].model.rollbackAttributes();

              Ember.set(formOriginalModels[_item], 'model', null);
            }
          }
        }

        this.formCopiedModelsBackup = _lodash.default.cloneDeep(formCopiedModels);
        this.formCopiedModels = formCopiedModels;
        this.finishedCopyingModels = true;
      }
    } catch (err) {
      throw err;
    }
  }), _dec31 = (0, _emberConcurrency.task)(function* (stepNameKey, stepProperty, data) {
    // registerStepDataTask
    var formSteps = yield this.formSteps;
    var stepNameKeySplitArr = stepNameKey.split('::');

    if (stepNameKeySplitArr.length > 1) {
      if (typeof formSteps[stepNameKeySplitArr[0]]['subSteps'][stepNameKeySplitArr[1]] === 'object') {
        formSteps[stepNameKeySplitArr[0]]['subSteps'][stepNameKeySplitArr[1]].set(stepProperty, data);
        formSteps[stepNameKeySplitArr[0]].set(stepProperty, data);
      }
    } else {
      if (typeof formSteps[stepNameKey] === 'object') {
        formSteps[stepNameKey].set(stepProperty, data);
      }
    }
  }), _dec32 = (0, _emberConcurrency.task)(function* (options) {
    // validateSyncSaveAllTask
    var compiledOptions = _lodash.default.merge({
      deepValidate: true,
      deepSync: true,
      deepSave: true,
      doSetupCopyModelsAfterSave: true,
      overrideOriginalModelsConfig: null,
      extraValidationData: {},
      notificationNamespace: 'global',
      deepSaveFailNotificationMessage: '',
      skipValidation: false,
      forceFailNotificationMessage: false,
      onAfterSave: this.onAfterSave,
      onBeforeSave: this.onBeforeSave,
      onBeforeValidate: this.onBeforeValidate,
      onAfterValidate: this.onAfterValidate,
      onBeforeSync: this.onBeforeSync,
      onAfterSync: this.onAfterSync
    }, options);

    yield this.deepValidateAll.unlinked().perform(compiledOptions);
    yield this.syncAllCopiedToOriginal.unlinked().perform(compiledOptions);
    yield this.deepSaveAll.unlinked().perform(compiledOptions);

    if (compiledOptions.doSetupCopyModelsAfterSave) {
      yield this.setupCopyModels.unlinked().perform();
    }
  }), _dec33 = (0, _emberConcurrency.task)(function* (options) {
    // validateCreateAllTask
    var compiledOptions = _lodash.default.merge({
      deepValidate: true,
      deepCreate: true,
      overrideOriginalModelsConfig: null,
      extraValidationData: {},
      notificationNamespace: 'global',
      deepSaveFailNotificationMessage: '',
      skipValidation: false,
      forceFailNotificationMessage: false,
      isCreate: true,
      onAfterSave: this.onAfterSave,
      onBeforeSave: this.onBeforeSave,
      onBeforeValidate: this.onBeforeValidate,
      onAfterValidate: this.onAfterValidate,
      onBeforeSync: this.onBeforeSync,
      onAfterSync: this.onAfterSync
    }, options);

    yield this.deepValidateAll.unlinked().perform(compiledOptions);
    var createdModels = yield this.deepCreateAll.unlinked().perform(compiledOptions);
    return createdModels;
  }), _dec34 = (0, _emberConcurrency.task)(function* (options = {}) {
    // deepCreateAll
    try {
      var compiledOptions = _lodash.default.merge({
        deepCreate: true,
        overrideOriginalModelsConfig: null,
        notificationNamespace: 'global',
        deepSaveFailNotificationMessage: '',
        onAfterSave: this.onAfterSave,
        onBeforeSave: this.onBeforeSave,
        onBeforeValidate: this.onBeforeValidate,
        onAfterValidate: this.onAfterValidate,
        onBeforeSync: this.onBeforeSync,
        onAfterSync: this.onAfterSync
      }, options);

      var allCreatePromises = [];
      var formOriginalModels = yield this.formOriginalModels;
      /**
       * do creates for needed creates so can traverse
       */

      Object.keys(formOriginalModels).forEach(item => {
        if (this.shouldSkip(compiledOptions, formOriginalModels[item], item)) {
          return;
        }

        var copiedModel = this.formCopiedModels.get(item + '.model');

        if (formOriginalModels[item].isCreate) {
          var jsonObject = this.objectToJson(copiedModel);
          var store = this.store;
          var createdObject = store.createRecord(item, jsonObject);
          Ember.set(formOriginalModels[item], 'model', createdObject);
        }

        allCreatePromises.push(this.createModelByKeyName.unlinked().perform(item, compiledOptions));
      });
      var createdModels = yield (0, _emberConcurrency.all)(allCreatePromises);
      /**
       * remove all creates done above
       */

      Object.keys(formOriginalModels).forEach(item => {
        if (this.shouldSkip(compiledOptions, formOriginalModels[item], item)) {
          return;
        }

        if (formOriginalModels[item].isCreate) {
          formOriginalModels[item].model.rollbackAttributes();
          Ember.set(formOriginalModels[item], 'model', null);
        }
      });
      var combinedCreatedModels = {};
      createdModels.forEach(currValData => {
        Object.assign(combinedCreatedModels, currValData);
      });
      return combinedCreatedModels;
    } catch (error) {
      (false && Ember.warn('failure in deep create all ' + error, {
        id: 'multiStepForm.deepCreateAll'
      })); // throw error;
    }
  }), _dec35 = (0, _emberConcurrency.task)(function* (originalModelKeyName, options) {
    // createModelByKeyName
    var compiledOptions = _lodash.default.merge({
      deepCreate: true,
      overrideOriginalModelsConfig: null,
      notificationNamespace: 'global',
      deepSaveFailNotificationMessage: '',
      onAfterSave: this.onAfterSave,
      onBeforeSave: this.onBeforeSave,
      onBeforeValidate: this.onBeforeValidate,
      onAfterValidate: this.onAfterValidate,
      onBeforeSync: this.onBeforeSync,
      onAfterSync: this.onAfterSync
    }, options);

    if (compiledOptions.deepSaveFailNotificationMessage == '') {
      compiledOptions.deepSaveFailNotificationMessage = this.intl.t('common.notifications.generic_catchall_failure');
    }

    var copiedModel = this.formCopiedModels.get(originalModelKeyName + '.model');
    var copiedModelBackup = this.formCopiedModelsBackup.get(originalModelKeyName + '.model');
    var formOriginalModels = yield this.formOriginalModels;
    var formOriginalModelData = yield formOriginalModels[originalModelKeyName];
    var originalModel = formOriginalModelData.model;
    compiledOptions.relationshipMap = yield this.copyRelationMap.unlinked().perform(originalModelKeyName, compiledOptions.overrideOriginalModelsConfig);
    return originalModel.deepCreate(copiedModel, copiedModelBackup, compiledOptions);
  }), _dec36 = (0, _emberConcurrency.task)(function* (listItems, extraOptions = {}) {
    // validateCreateList
    var compiledOptions = _lodash.default.merge({
      notificationNamespace: 'global',
      deepSaveFailNotificationMessage: '',
      skipValidation: false,
      isCreate: true,
      onAfterSave: this.onAfterSave,
      onBeforeSave: this.onBeforeSave,
      onBeforeValidate: this.onBeforeValidate,
      onAfterValidate: this.onAfterValidate,
      onBeforeSync: this.onBeforeSync,
      onAfterSync: this.onAfterSync
    }, extraOptions);

    yield this.validateList.unlinked().perform(listItems, compiledOptions);
    var createdModels = yield this.createList(listItems, compiledOptions);
    return createdModels;
  }), _dec37 = (0, _emberConcurrency.task)(function* (listItems, extraOptions = {}) {
    // validateList
    yield listItems;

    var compiledOptions = _lodash.default.merge({
      notificationNamespace: 'global',
      skipValidation: false,
      onAfterSave: this.onAfterSave,
      onBeforeSave: this.onBeforeSave,
      onBeforeValidate: this.onBeforeValidate,
      onAfterValidate: this.onAfterValidate,
      onBeforeSync: this.onBeforeSync,
      onAfterSync: this.onAfterSync
    }, extraOptions);

    if (compiledOptions.skipValidation) {
      return;
    }

    this.validationErrors = {};
    var combinedValidations = {};

    for (var i = 0; i < listItems.get('length'); i++) {
      var item = listItems.objectAt(i);
      item = _lodash.default.merge({
        extraValidationData: {}
      }, item);
      var objectToValidate = void 0;
      var isCreate = typeof item.model == 'null'; //eslint-disable-line

      try {
        var validationMixin = require('securitytrax-ember-ui/validations/' + (item.filePath ? item.filePath : item.modelName.dasherize()))['default'];

        objectToValidate = validationMixin;
      } catch (reason) {
        objectToValidate = Ember.Object.extend({
          validate: () => {
            return {};
          }
        });
      }

      var toValidate = objectToValidate.create(item.model);
      var currModelErrorsArr = toValidate.validate({
        isCreate: isCreate,
        extraValidationData: item.extraValidationData,
        intlService: this.intl
      });
      Object.assign(combinedValidations, currModelErrorsArr);
    }

    if (this.validationErrorsEmpty(combinedValidations) || 'validations' in combinedValidations && Ember.isEmpty(combinedValidations.validations.content)) {
      // means validation passed - so yah!
      return true;
    } else {
      this.validationErrors = null;
      console.log('---------------------- START LIST VALIDATION ERRORS ---------------------'); //eslint-disable-line

      console.log(combinedValidations); //eslint-disable-line

      console.log('---------------------- END LIST VALIDATION ERRORS ---------------------'); //eslint-disable-line

      Ember.run.later(() => {
        this.validationErrors = combinedValidations;
        this.afterFailedValidation(compiledOptions.notificationNamespace);
      });
      throw combinedValidations;
    }
  }), _dec38 = (0, _emberConcurrency.task)(function* (toSave, compiledOptions) {
    // saveWithRollbackAndNotificationTask
    try {
      if (compiledOptions.forceFailNotificationMessage) {
        throw compiledOptions.forceFailNotificationMessage;
      }

      compiledOptions.onBeforeSave(compiledOptions);
      var wasSaved = yield toSave.save();
      compiledOptions.onAfterSave(_lodash.default.merge({
        saved: wasSaved
      }, compiledOptions));
      return wasSaved;
    } catch (error) {
      this.stNotificationManager.addErrorMessages(error, {
        fallbackMessage: compiledOptions.deepSaveFailNotificationMessage,
        notificationNamespace: compiledOptions.notificationNamespace
      });
      toSave.rollbackAttributes();
      throw error;
    }
  }), _dec39 = (0, _emberConcurrency.task)(function* (options = {}) {
    // syncAllCopiedToOriginal
    var compiledOptions = _lodash.default.merge({
      deepSync: true,
      overrideOriginalModelsConfig: null,
      onAfterSave: this.onAfterSave,
      onBeforeSave: this.onBeforeSave,
      onBeforeValidate: this.onBeforeValidate,
      onAfterValidate: this.onAfterValidate,
      onBeforeSync: this.onBeforeSync,
      onAfterSync: this.onAfterSync
    }, options);

    var allSyncPromises = [];
    var formOriginalModels = yield this.formOriginalModels;
    var formOriginalModelsKeys = Object.keys(formOriginalModels);

    for (var i = 0; i < formOriginalModelsKeys.get('length'); i++) {
      var item = formOriginalModelsKeys.objectAt(i);

      if (this.shouldSkip(compiledOptions, formOriginalModels[item], item)) {
        continue;
      }

      var copiedModel = this.formCopiedModels.get(item + '.model');

      if (formOriginalModels[item].isCreate) {
        Ember.set(formOriginalModels[item], 'model', this.store.createRecord(item, this.objectToJson(copiedModel)));
      }

      allSyncPromises.push(this.syncCopiedToOriginalModelByKeyName.unlinked().perform(item, compiledOptions));
    }

    yield (0, _emberConcurrency.all)(allSyncPromises);

    for (var _i = 0; _i < formOriginalModelsKeys.get('length'); _i++) {
      var _item2 = formOriginalModelsKeys.objectAt(_i);

      if (this.shouldSkip(compiledOptions, formOriginalModels[_item2], _item2)) {
        continue;
      }

      if (formOriginalModels[_item2].isCreate) {
        formOriginalModels[_item2].model.rollbackAttributes();

        Ember.set(formOriginalModels[_item2], 'model', null);
      }
    }

    return true;
  }), _dec40 = (0, _emberConcurrency.task)(function* (options) {
    // deepSaveAll
    var compiledOptions = _lodash.default.merge({
      deepSave: true,
      overrideOriginalModelsConfig: null,
      notificationNamespace: 'global',
      deepSaveFailNotificationMessage: '',
      onAfterSave: this.onAfterSave,
      onBeforeSave: this.onBeforeSave,
      onBeforeValidate: this.onBeforeValidate,
      onAfterValidate: this.onAfterValidate,
      onBeforeSync: this.onBeforeSync,
      onAfterSync: this.onAfterSync
    }, options);

    if (compiledOptions.deepSaveFailNotificationMessage == '') {
      compiledOptions.deepSaveFailNotificationMessage = this.intl.t('common.notifications.generic_catchall_failure');
    }

    try {
      if (compiledOptions.forceFailNotificationMessage) {
        throw compiledOptions.forceFailNotificationMessage;
      }

      var allSavePromises = [];
      var formOriginalModels = yield this.formOriginalModels;
      var formOriginalModelsKeys = Object.keys(formOriginalModels);

      for (var i = 0; i < formOriginalModelsKeys.get('length'); i++) {
        var item = formOriginalModelsKeys.objectAt(i);

        if (this.shouldSkip(compiledOptions, formOriginalModels[item], item)) {
          continue;
        }

        if (formOriginalModels[item].isCreate) {
          Ember.set(formOriginalModels[item], 'model', this.store.createRecord(item));
        }

        allSavePromises.push(this.deepSaveOriginalModelByKeyName.unlinked().perform(item, compiledOptions));
      }

      yield (0, _emberConcurrency.all)(allSavePromises);
      formOriginalModelsKeys = Object.keys(formOriginalModels);

      for (var _i2 = 0; _i2 < formOriginalModelsKeys.get('length'); _i2++) {
        var _item3 = formOriginalModelsKeys.objectAt(_i2);

        if (this.shouldSkip(compiledOptions, formOriginalModels[_item3], _item3)) {
          continue;
        }

        if (formOriginalModels[_item3].isCreate) {
          formOriginalModels[_item3].model.rollbackAttributes();

          Ember.set(formOriginalModels[_item3], 'model', null);
        }
      }

      return true;
    } catch (error) {
      this.stNotificationManager.addErrorMessages(error, {
        fallbackMessage: compiledOptions.deepSaveFailNotificationMessage,
        notificationNamespace: compiledOptions.notificationNamespace
      });
      throw error;
    }
  }), _dec41 = (0, _emberConcurrency.task)(function* (options = {}) {
    // deepValidateAll
    var compiledOptions = _lodash.default.merge({
      deepValidate: true,
      overrideOriginalModelsConfig: null,
      extraValidationData: {},
      notificationNamespace: 'global',
      skipValidation: false,
      onAfterSave: this.onAfterSave,
      onBeforeSave: this.onBeforeSave,
      onBeforeValidate: this.onBeforeValidate,
      onAfterValidate: this.onAfterValidate,
      onBeforeSync: this.onBeforeSync,
      onAfterSync: this.onAfterSync
    }, options);

    if (compiledOptions.skipValidation) {
      return;
    }

    this.validationErrors = {};
    var allValidatePromises = [];
    var formOriginalModels = yield this.formOriginalModels;
    /**
     * add models that are creates so they can be traversed for deeping validation
     */

    Object.keys(formOriginalModels).forEach(item => {
      if (this.shouldSkip(compiledOptions, formOriginalModels[item], item)) {
        return;
      }

      if (formOriginalModels[item].isCreate) {
        Ember.set(formOriginalModels[item], 'model', this.store.createRecord(item));
      }

      allValidatePromises.push(this.deepValidateOriginalModelByKeyName.unlinked().perform(item, compiledOptions));
    });
    var validationData = yield (0, _emberConcurrency.all)(allValidatePromises);
    /**
     * remove create models that were used for traverse above
     */

    Object.keys(formOriginalModels).forEach(item => {
      if (this.shouldSkip(compiledOptions, formOriginalModels[item], item)) {
        return;
      }

      if (formOriginalModels[item].isCreate) {
        formOriginalModels[item].model.rollbackAttributes();
        Ember.set(formOriginalModels[item], 'model', null);
      }
    });
    /**
     * compile the actual validations
     */

    var combinedValidations = {};
    validationData.forEach(currValData => {
      Object.assign(combinedValidations, currValData);
    });

    if (this.validationErrorsEmpty(combinedValidations) || 'validations' in combinedValidations && Ember.isEmpty(combinedValidations.validations.content)) {
      // means validation passed - so yah!
      return true;
    } else {
      this.validationErrors = null;
      console.log('---------------------- START VALIDATION ERRORS ---------------------'); //eslint-disable-line

      console.log(combinedValidations); //eslint-disable-line

      console.log('---------------------- END VALIDATION ERRORS -----------------------'); //eslint-disable-line

      Ember.run.later(() => {
        this.validationErrors = combinedValidations;
        this.afterFailedValidation(compiledOptions.notificationNamespace);
      });
      throw '';
    }
  }), _dec42 = (0, _emberConcurrency.task)(function* (originalModelKeyName, options = {}) {
    // syncCopiedToOriginalModelByKeyName
    var compiledOptions = _lodash.default.merge({
      deepSync: true,
      overrideOriginalModelsConfig: null,
      onAfterSave: this.onAfterSave,
      onBeforeSave: this.onBeforeSave,
      onBeforeValidate: this.onBeforeValidate,
      onAfterValidate: this.onAfterValidate,
      onBeforeSync: this.onBeforeSync,
      onAfterSync: this.onAfterSync
    }, options);

    var formOriginalModels = yield this.formOriginalModels;
    var copiedModel = this.formCopiedModels.get(originalModelKeyName + '.model');
    var formOriginalModelData = formOriginalModels[originalModelKeyName];

    var readOnly = _lodash.default.get(formOriginalModelData, 'readOnly', false);

    var originalModel = formOriginalModelData.model;
    var isCreate = formOriginalModelData.isCreate;
    var copyRelationMap = yield this.copyRelationMap.unlinked().perform(originalModelKeyName, compiledOptions.overrideOriginalModelsConfig);
    return originalModel.deepSync({
      relationshipMap: copyRelationMap,
      copiedObject: copiedModel,
      deepSync: compiledOptions.deepSync,
      readOnly: readOnly,
      isCreate: isCreate,
      originalOptions: compiledOptions
    });
  }), _dec43 = (0, _emberConcurrency.task)(function* (originalModelKeyName, options = {}) {
    // deepValidateOriginalModelByKeyName
    var compiledOptions = _lodash.default.merge({
      deepValidate: true,
      overrideOriginalModelsConfig: null,
      extraValidationData: {},
      onAfterSave: this.onAfterSave,
      onBeforeSave: this.onBeforeSave,
      onBeforeValidate: this.onBeforeValidate,
      onAfterValidate: this.onAfterValidate,
      onBeforeSync: this.onBeforeSync,
      onAfterSync: this.onAfterSync
    }, options);

    var formOriginalModels = yield this.formOriginalModels;
    var copiedModel = this.formCopiedModels.get(originalModelKeyName + '.model');
    var formOriginalModelData = formOriginalModels[originalModelKeyName];

    var readOnly = _lodash.default.get(formOriginalModelData, 'readOnly', false);

    var originalModel = formOriginalModelData.model;
    var isCreate = formOriginalModelData.isCreate;
    var copyRelationMap = yield this.copyRelationMap.unlinked().perform(originalModelKeyName, compiledOptions.overrideOriginalModelsConfig);
    return originalModel.deepValidate(copiedModel, {
      relationshipMap: copyRelationMap,
      deepValidate: compiledOptions.deepValidate,
      skipTopLevelModel: compiledOptions.overrideOriginalModelsConfig && compiledOptions.overrideOriginalModelsConfig[originalModelKeyName].doValidate === false || formOriginalModelData.doValidate === false,
      readOnly: readOnly,
      isCreate: isCreate,
      extraValidationData: compiledOptions.extraValidationData,
      intlService: this.intl,
      originalOptions: compiledOptions
    });
  }), _dec44 = (0, _emberConcurrency.task)(function* (originalModelKeyName, options = {}) {
    // deepSaveOriginalModelByKeyName
    var compiledOptions = _lodash.default.merge({
      deepSave: true,
      overrideOriginalModelsConfig: null,
      onAfterSave: this.onAfterSave,
      onBeforeSave: this.onBeforeSave,
      onBeforeValidate: this.onBeforeValidate,
      onAfterValidate: this.onAfterValidate,
      onBeforeSync: this.onBeforeSync,
      onAfterSync: this.onAfterSync
    }, options);

    var formOriginalModels = yield this.formOriginalModels;
    var copiedModel = this.formCopiedModels.get(originalModelKeyName + '.model');
    var copiedModelBackup = this.formCopiedModelsBackup.get(originalModelKeyName + '.model');
    var formOriginalModelData = formOriginalModels[originalModelKeyName];

    var readOnly = _lodash.default.get(formOriginalModelData, 'readOnly', false);

    var doSave = _lodash.default.get(formOriginalModelData, 'doSave', true);

    var originalModel = formOriginalModelData.model;
    var isCreate = formOriginalModelData.isCreate;
    var copyRelationMap = yield this.copyRelationMap.unlinked().perform(originalModelKeyName, compiledOptions.overrideOriginalModelsConfig);
    return originalModel.deepSave({
      relationshipMap: copyRelationMap,
      copiedObject: copiedModel,
      copiedObjectBackup: copiedModelBackup,
      deepSave: compiledOptions.deepSave,
      doSave: doSave,
      skipTopLevelModel: compiledOptions.overrideOriginalModelsConfig && compiledOptions.overrideOriginalModelsConfig[originalModelKeyName].doSave === false || formOriginalModelData.doSave === false,
      readOnly: readOnly,
      isCreate: isCreate,
      successNotificationNamespace: compiledOptions.successNotificationNamespace,
      originalOptions: compiledOptions
    });
  }), _dec45 = (0, _emberConcurrency.task)(function* (originalModelKeyName, validationKey) {
    // validateProperty
    var formOriginalModels = yield this.formOriginalModels;
    var originalModel = formOriginalModels[originalModelKeyName].model;
    var copiedModel = this.formCopiedModels.get(originalModelKeyName + '.model');
    var validationErrors = this.validationErrors;
    var copyRelationMap = yield this.copyRelationMap.unlinked().perform(originalModelKeyName);
    var validationsData = yield originalModel.deepValidate(copiedModel, {
      relationshipMap: copyRelationMap,
      deepValidate: false,
      intlService: this.intl,
      originalOptions: {
        onAfterSave: this.onAfterSave,
        onBeforeSave: this.onBeforeSave,
        onBeforeValidate: this.onBeforeValidate,
        onAfterValidate: this.onAfterValidate,
        onBeforeSync: this.onBeforeSync,
        onAfterSync: this.onAfterSync
      }
    });

    if (validationsData[validationKey]) {
      validationErrors[validationKey] = validationsData[validationKey];
    } else {
      delete validationErrors[validationKey];
    }

    this.validationErrors = null;
    Ember.run.later(() => {
      this.validationErrors = validationErrors;
    });
  }), (_class = (_temp = class MultiStepForm extends _component.default {
    /**
     * The model data that needs to copied and available to each step
     */

    /**
     * Text key for the current step
     */

    /**
     * the actual steps of the form - can hold any arbitrary data for the current step
     */

    /**
     * Whether or not the form is started
     * can be used to setup the form and start it later
     */
    // retarded legacy bullshiz that don't feel like fixing right now - only used on demo

    /**
     * callbacks for certain events in multiStepForm
     */
    // passed through on a couple of forms - used just for those forms to have data across steps that's unique to that form
    // the backup is used to rollback form level changes on save failure

    /**
     * Object with validation error messages.
     * Keys are in format {model}-{property}
     * To be used in conjuntion with validationKey on switchable inputs
     * Value is an object with error message
     *
     * @type {Object[]}
     */
    // only used for redrawing a form step when needed going from create -> edit without closing modal - hacking, but couldn't refactor without mega troubles

    /**
     * constructor to set the first passed in step as the current step
     *
     * Also we setup the copy models based on the formModelDataList
     */
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "scroller", _descriptor2, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);

      _initializerDefineProperty(this, "formOriginalModels", _descriptor5, this);

      _initializerDefineProperty(this, "currentStepNameKey", _descriptor6, this);

      _initializerDefineProperty(this, "formSteps", _descriptor7, this);

      _initializerDefineProperty(this, "formStarted", _descriptor8, this);

      _initializerDefineProperty(this, "progressBarConfig", _descriptor9, this);

      _initializerDefineProperty(this, "progressBarTemplate", _descriptor10, this);

      _initializerDefineProperty(this, "onBeforeSave", _descriptor11, this);

      _initializerDefineProperty(this, "onAfterSave", _descriptor12, this);

      _initializerDefineProperty(this, "onBeforeValidate", _descriptor13, this);

      _initializerDefineProperty(this, "onAfterValidate", _descriptor14, this);

      _initializerDefineProperty(this, "onBeforeSync", _descriptor15, this);

      _initializerDefineProperty(this, "onAfterSync", _descriptor16, this);

      _initializerDefineProperty(this, "onDelete", _descriptor17, this);

      _initializerDefineProperty(this, "additionalData", _descriptor18, this);

      _initializerDefineProperty(this, "finishedCopyingModels", _descriptor19, this);

      _initializerDefineProperty(this, "formCopiedModels", _descriptor20, this);

      _initializerDefineProperty(this, "formCopiedModelsBackup", _descriptor21, this);

      _initializerDefineProperty(this, "currentStepData", _descriptor22, this);

      _initializerDefineProperty(this, "validationErrors", _descriptor23, this);

      _initializerDefineProperty(this, "_stepStarted", _descriptor24, this);

      _defineProperty(this, "messageNameSpace", 'global');

      _initializerDefineProperty(this, "setupFormTask", _descriptor25, this);

      _initializerDefineProperty(this, "setFocusToFirstInput", _descriptor26, this);

      _initializerDefineProperty(this, "currentStepDataTask", _descriptor27, this);

      _initializerDefineProperty(this, "goToFirstStepTask", _descriptor28, this);

      _initializerDefineProperty(this, "copyRelationMap", _descriptor29, this);

      _initializerDefineProperty(this, "isCreateTask", _descriptor30, this);

      _initializerDefineProperty(this, "setupCopyModels", _descriptor31, this);

      _initializerDefineProperty(this, "registerStepDataTask", _descriptor32, this);

      _initializerDefineProperty(this, "validateSyncSaveAllTask", _descriptor33, this);

      _initializerDefineProperty(this, "validateCreateAllTask", _descriptor34, this);

      _initializerDefineProperty(this, "deepCreateAll", _descriptor35, this);

      _initializerDefineProperty(this, "createModelByKeyName", _descriptor36, this);

      _initializerDefineProperty(this, "validateCreateList", _descriptor37, this);

      _initializerDefineProperty(this, "validateList", _descriptor38, this);

      _initializerDefineProperty(this, "saveWithRollbackAndNotificationTask", _descriptor39, this);

      _initializerDefineProperty(this, "syncAllCopiedToOriginal", _descriptor40, this);

      _initializerDefineProperty(this, "deepSaveAll", _descriptor41, this);

      _initializerDefineProperty(this, "deepValidateAll", _descriptor42, this);

      _initializerDefineProperty(this, "syncCopiedToOriginalModelByKeyName", _descriptor43, this);

      _initializerDefineProperty(this, "deepValidateOriginalModelByKeyName", _descriptor44, this);

      _initializerDefineProperty(this, "deepSaveOriginalModelByKeyName", _descriptor45, this);

      _initializerDefineProperty(this, "validateProperty", _descriptor46, this);

      if (!this.args.usesModal) {
        this.setupForm();
      }
    }

    formStartedSetVal(value) {
      if (this.args.changeFormStarted) {
        if (this.args.usesModal) {
          if (value === false) {
            this.resetForm(false);
          }
        }

        this.args.changeFormStarted(value);
      }
    }

    currentStepNameKeySetVal(value) {
      if (this.args.changeCurrentStepNameKey) {
        this.args.changeCurrentStepNameKey(value);
      } else {
        this.currentStepNameKey = value;
      }
    }

    formCopiedModelsSetVal(key, value) {
      if (!this.formCopiedModels) {
        this.formCopiedModels = Ember.Object.create({});
      }

      Ember.set(this.formCopiedModels, key, value);
    }

    formCopiedModelsGetVal(key) {
      if (!this.formCopiedModels) {
        return undefined;
      }

      var value = this.formCopiedModels.get(key);
      return value;
    }

    formOriginalModelsSetVal(key, value) {
      if (!this.formOriginalModels) {
        this.formOriginalModels = Ember.Object.create({});
      }

      Ember.set(this.formOriginalModels, key, value);
    }

    formOriginalModelsGetVal(key) {
      if (!this.formOriginalModels) {
        return undefined;
      }

      return this.formOriginalModels.get(key);
    }

    get updatedOriginalModels() {
      ///////////////////////////////////////////////////////////////
      // setup the copy models for non-modals when formOriginalModels change. Modals are handled by the formSetup observer
      if (this.args.usesModal) {
        if (!this.formStarted) {
          this.finishedCopyingModels = false;
          this.formCopiedModelsBackup = false;
          this.formCopiedModels = false;
          this.setupCopyModels.unlinked().perform();
        }
      } else {
        this.finishedCopyingModels = false;
        this.formCopiedModelsBackup = false;
        this.formCopiedModels = false;
        this.setupCopyModels.unlinked().perform();
      }

      return true;
    } //////////////////////////////////////////////////////
    // used just for non modals to setup the form


    get formSetup() {
      // if a modal is closed
      if (this.args.usesModal) {
        if (this.formStarted === false) {
          this.resetForm();
        } else if (!this.formCopiedModels) {
          // if opening another modal
          this.setupForm();
        }
      }

      return true;
    } //////////////////////////////////////////////////////
    // used just for non modals to setup the form


    setupForm() {
      return this.setupFormTask.perform();
    }

    /**
     * A computed property that returns the current steps passed in data
     */
    get _currentStepData() {
      return this.currentStepDataTask.perform(this.currentStepNameKey);
    }

    /**
     * Hook to run after a validation fails
     *
     * @method
     */
    afterFailedValidation(notificationNamespace = 'global') {
      Ember.run.later(() => {
        var errorElement = (0, _jquery.default)('.error-message:visible').first().parent();

        if (errorElement.length === 1) {
          this.scroller.scrollVertical(errorElement, {
            duration: 500,
            complete: function () {
              var toFocus = errorElement.find('input:not(".datepicker"), select, textarea');

              if (toFocus.length === 1) {
                toFocus.focus();
              } else {
                //no input to focus on, focus on the error message itself
                errorElement.find('.error-message').focus();
              }
            }
          });
        } else {
          this.stNotificationManager.addError(this.intl.t('common.notifications.generic_catchall_failure'), notificationNamespace);
          (false && Ember.warn('No validation error message found. Check validationKey, or make sure it is clear what is causing the failure.', {
            id: 'multiStepForm.afterFailedValidation'
          }));
        }
      });
    }

    resetForm(doSetFormStarted = true, doSetStepNameKey = true) {
      if (doSetStepNameKey) {
        this.goToFirstStep();
      }

      if (doSetFormStarted) {
        this.formStartedSetVal(false);
      }

      Ember.set(this, 'validationErrors', {});
      this.resetProgressBar();
      Ember.set(this, 'finishedCopyingModels', false);
      Ember.set(this, 'formCopiedModelsBackup', false);
      Ember.set(this, 'formCopiedModels', false);

      if (!this.args.usesModal) {
        this.setupCopyModels.unlinked().perform();
      }
    }

    resetFromCreateToEdit() {
      this._stepStarted = false;
      this.resetForm(false, false);
      Ember.run.later(() => {
        this._stepStarted = true;
        this.setupForm();
      });
    }

    resetProgressBar() {
      if (this.progressBarConfig) {
        this.progressBarConfig.activeIndex = 0;
        var items = this.progressBarConfig.items;

        if (items) {
          items.forEach(item => {
            Ember.set(item, 'isComplete', false);
          });
        }
      }
    }

    goToFirstStep() {
      return this.goToFirstStepTask.perform();
    }

    isCreate(item) {
      return this.isCreateTask.unlinked().perform(item);
    }

    /**
     * A generic function that allows individual steps to register data for themselves in the data list
     * This allows steps to utilize data the form doesn't know about (ie - for a modal or any other arbitrary thing)
     * Reduces coupling
     * @param stepNameKey
     * @param stepProperty
     * @param data
     */
    registerStepData(stepNameKey, stepProperty, data) {
      return this.registerStepDataTask.perform(stepNameKey, stepProperty, data);
    }

    validationErrorsEmpty(validationErrors) {
      var noErrorsFound = true;
      Object.keys(validationErrors).forEach(currErrorIndex => {
        if (Object.keys(validationErrors[currErrorIndex]).length > 0) {
          noErrorsFound = false;
        }
      });
      return noErrorsFound;
    }

    shouldSkip(compiledOptions, item, itemKey) {
      return !('model' in item) || compiledOptions.overrideOriginalModelsConfig && !(itemKey in compiledOptions.overrideOriginalModelsConfig) || !compiledOptions.overrideOriginalModelsConfig && item.requiresOverride === true;
    }

    validateSyncSaveAll(options = {}) {
      return this.validateSyncSaveAllTask.unlinked().perform(options);
    }

    /* Used to validate all forms and then do creates on null models */
    validateCreateAll(options = {}) {
      return this.validateCreateAllTask.unlinked().perform(options);
    }

    createList(listItems, extraOptions = {}) {
      var compiledOptions = _lodash.default.merge({
        notificationNamespace: 'global',
        deepSaveFailNotificationMessage: '',
        onAfterSave: this.onAfterSave,
        onBeforeSave: this.onBeforeSave,
        onBeforeValidate: this.onBeforeValidate,
        onAfterValidate: this.onAfterValidate,
        onBeforeSync: this.onBeforeSync,
        onAfterSync: this.onAfterSync
      }, extraOptions);

      var combinedToCreates = {};
      listItems.forEach(item => {
        if (!combinedToCreates[item.modelName]) {
          combinedToCreates[item.modelName] = [];
        }

        combinedToCreates[item.modelName].push({
          data: this.objectToJson(item.model),
          saveWithRollbackAndNotification: toSave => {
            return this.saveWithRollbackAndNotificationTask.unlinked().perform(toSave, compiledOptions);
          }
        });
      });
      return combinedToCreates;
    }

    objectToJson(object) {
      var result = {};
      var copiedObject = Object.assign({}, object);

      for (var key in copiedObject) {
        // Skip these
        if (key === 'isInstance' || key === 'isDestroyed' || key === 'mergedProperties' || key === 'isDestroying' || key === 'concatenatedProperties' || typeof copiedObject[key] === 'function' || !copiedObject.hasOwnProperty(key)) {
          continue;
        }

        result[key] = copiedObject[key];
      }

      return result;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "scroller", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "formOriginalModels", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "currentStepNameKey", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "formSteps", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "formStarted", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "progressBarConfig", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "progressBarTemplate", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "onBeforeSave", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "onAfterSave", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "onBeforeValidate", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "onAfterValidate", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "onBeforeSync", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "onAfterSync", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "onDelete", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "additionalData", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "finishedCopyingModels", [_trackedToolbox.dedupeTracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "formCopiedModels", [_trackedToolbox.dedupeTracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "formCopiedModelsBackup", [_trackedToolbox.dedupeTracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "currentStepData", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.Object.create({});
    }
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "validationErrors", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.Object.create({});
    }
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "_stepStarted", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updatedOriginalModels", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "updatedOriginalModels"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "formSetup", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "formSetup"), _class.prototype), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "setupFormTask", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "setFocusToFirstInput", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "currentStepDataTask", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "goToFirstStepTask", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "copyRelationMap", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "isCreateTask", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "setupCopyModels", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "registerStepDataTask", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "validateSyncSaveAllTask", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "validateCreateAllTask", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "deepCreateAll", [_dec34], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor36 = _applyDecoratedDescriptor(_class.prototype, "createModelByKeyName", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor37 = _applyDecoratedDescriptor(_class.prototype, "validateCreateList", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor38 = _applyDecoratedDescriptor(_class.prototype, "validateList", [_dec37], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor39 = _applyDecoratedDescriptor(_class.prototype, "saveWithRollbackAndNotificationTask", [_dec38], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor40 = _applyDecoratedDescriptor(_class.prototype, "syncAllCopiedToOriginal", [_dec39], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor41 = _applyDecoratedDescriptor(_class.prototype, "deepSaveAll", [_dec40], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor42 = _applyDecoratedDescriptor(_class.prototype, "deepValidateAll", [_dec41], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor43 = _applyDecoratedDescriptor(_class.prototype, "syncCopiedToOriginalModelByKeyName", [_dec42], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor44 = _applyDecoratedDescriptor(_class.prototype, "deepValidateOriginalModelByKeyName", [_dec43], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor45 = _applyDecoratedDescriptor(_class.prototype, "deepSaveOriginalModelByKeyName", [_dec44], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor46 = _applyDecoratedDescriptor(_class.prototype, "validateProperty", [_dec45], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = MultiStepForm;
});