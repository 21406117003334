define("securitytrax-ember-ui/components/multi-step-form/forms/user-actions/swap-user/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/switchable-form-step", "lodash"], function (_exports, _emberConcurrency, _switchableFormStep, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    orgTree: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    hierarchyUsers: Ember.computed(function () {
      return this.getUsersTask.perform();
    }),
    selectedUser: null,
    saveComplete: true,
    resultsLoaded: true,

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('modalButtons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.swapUserTask.perform(buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary'
      }]);
    },

    getUsersTask: (0, _emberConcurrency.task)(function* () {
      var orgTree = this.orgTree;
      var userHierarchyTree = yield orgTree.get('userHierarchyTree');

      if (userHierarchyTree) {
        var uncategorizedUsers = yield userHierarchyTree.invokeAction('get_users_not_in_hierarchy');
        var directoryUser = yield this.directoryUsers;

        var users = _lodash.default.intersectionBy(directoryUser.toArray(), uncategorizedUsers.responsePayload.data, 'id');

        return users;
      }
    }),
    swapUserTask: (0, _emberConcurrency.task)(function* (buttonPromise) {
      try {
        this.set('saveComplete', false);
        var orgTree = this.orgTree;
        var userHierarchyTree = yield orgTree.get('userHierarchyTree');

        if (userHierarchyTree) {
          var modalParentUser = this.modalParentUser;
          var modalParentUserId = yield modalParentUser.get('id');
          yield userHierarchyTree.invokeAction('swap_node', {
            verb: 'POST',
            data: {
              data: {
                relationships: {
                  user_hierarchy_one: {
                    'data': {
                      type: 'user_hierarchies',
                      id: JSON.parse(modalParentUserId)
                    }
                  },
                  user_not_in_hierarchy: {
                    'data': {
                      type: 'user_hierarchies',
                      id: this.get('selectedUser.id')
                    }
                  }
                }
              }
            }
          });
        } //notify parent list that it has new users

        /*   this.set('newGroupUsers', newUsers); */


        buttonPromise.then(() => {
          this.set('addUsersFormStarted', false);
          this.set('saveComplete', true);
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
          this.changeRouteTask.perform();
        });
      } catch (err) {
        this.set('addUsersFormStarted', false);
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
        (false && Ember.warn('Org Chart swap user error' + err, {
          id: 'multiStepForm.forms.user-actions.deleteUser.swapserTask'
        }));
        throw err;
      }
    }),
    changeRouteTask: (0, _emberConcurrency.task)(function* () {
      var userHierarchyTree = yield this.get('orgTree.userHierarchyTree');
      var user = this.get('selectedUser.id');
      this.router.transitionTo('/administration/org-chart/' + userHierarchyTree.get('id') + '/user/' + user);
    })
  });

  _exports.default = _default;
});