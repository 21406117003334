define("securitytrax-ember-ui/validations/consumer-financing-application", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation", "lodash"], function (_exports, _validate, _baseValidation, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var noValidate = _lodash.default.get(data, 'extraValidationData.noValidate', false);

      _validate.default.validators.validDownPayment = function (value, options) {
        if (Number(value) > Number(options.sellingPrice)) {
          return options.message;
        }

        return null;
      };

      _validate.default.extend(_validate.default.validators.datetime, {
        parse: function (value) {
          return +moment.utc(value);
        },
        format: function (value) {
          return moment.utc(value).format('MM/DD/YYYY');
        }
      });

      var validateObject = {
        sellingPrice: {
          presence: () => {
            if (noValidate && noValidate.includes('consumerFinancingApplication-sellingPrice')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'sellingPrice');
            }
          }
        },
        creditAmountRequested: {
          presence: () => {
            if (noValidate && noValidate.includes('consumerFinancingApplication-creditAmountRequested')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'creditAmountRequested');
            }
          }
        },
        downPayment: {
          validDownPayment: {
            message: function () {
              return '^' + data.intlService.t('pages.customers.accounting.financing.invalid_down_payment');
            },
            sellingPrice: this.sellingPrice
          }
        },
        productOrService: {
          presence: () => {
            if (noValidate && noValidate.includes('consumerFinancingApplication-productOrService')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'productOrService');
            }
          }
        },
        completionDate: {
          presence: () => {
            if (noValidate && noValidate.includes('consumerFinancingApplication-completionDate')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'completionDate');
            }
          },
          datetime: {
            earliest: moment.utc(),
            message: '^' + data.intlService.t('pages.customers.accounting.financing.invalid_completion_date')
          }
        },
        contractState: {
          presence: () => {
            if (noValidate && noValidate.includes('consumerFinancingApplication-contractState')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'contractState');
            }
          }
        },
        salesTaxRate: {
          presence: () => {
            if (noValidate && noValidate.includes('consumerFinancingApplication-salesTaxRate')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'salesTaxRate');
            }
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      return this.outputFormatting(validationData, 'consumerFinancingApplication');
    }

  });

  _exports.default = _default;
});