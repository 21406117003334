define("securitytrax-ember-ui/mixins/list/row-list", ["exports", "ember-concurrency", "lodash"], function (_exports, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Adds ability for different list funcationality
   *
   * @mixin ListMixin
   * @extends Ember.Mixin
   */
  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    listArray: null,
    newid: 1,
    itemsToDeleteList: undefined,
    rowListEvent: Ember.inject.service('events/row-list-event'),

    init() {
      this._super(...arguments);

      this.set('listArray', Ember.A());
      this.set('itemsToDeleteList', {});
    },

    listIsEmpty: Ember.computed('listArray.[]', function () {
      return Ember.isEmpty(this.listArray);
    }),
    lastRowIndex: Ember.computed('listArray.[]', function () {
      return this.listArray.length - 1;
    }),
    trueListArray: Ember.computed('listArray.[]', function () {
      var listArray = this.listArray;
      var lastValidateIndex = this.lastObjectEmpty() ? listArray.get('length') - 1 : listArray.get('length');
      var trueListArray = Ember.A();

      for (var index = 0; index < lastValidateIndex; index++) {
        trueListArray.pushObject(listArray.objectAt(index));
      }

      return trueListArray;
    }),

    addRow() {
      this.listArray.pushObject(Ember.Object.create({}));
      this.notifyPropertyChange('listArray');
    },

    deleteRow: (0, _emberConcurrency.task)(function* (item, modelName, deleteOnSave = false) {
      var lineItems = this.listArray;

      if (item.get('id') && !item.get('id').match(/new\d+/)) {
        if (deleteOnSave) {
          var itemsToDelete = this.itemsToDeleteList;
          var newList = lineItems.filter(theItem => {
            return theItem != item;
          });
          this.set('listArray', newList);
          itemsToDelete[item.id] = item.id;
          this.notifyPropertyChange('itemsToDeleteList');
          item.set('deleting', true);
          item.set('deletingClass', 'marked-for-delete');
        } else {
          var lineItemToDelete = yield this.store.findRecord(modelName, item.get('id')); //if its an existing record (not new) destroy it

          if (lineItemToDelete) {
            yield lineItemToDelete.destroyRecordWithRollbackTask.perform();
          }
        }
      } //remove from list and copied models


      var filteredList = _lodash.default.filter(lineItems, function (theItem) {
        return item != theItem;
      });

      this.set('listArray', filteredList);
    }),

    updateRow(item, index, useNewIds = true) {
      var listArray = this.listArray;

      if (!item.id && useNewIds) {
        if (typeof item.set === 'function') {
          item.set('id', 'new' + this.newid);
        } else {
          item['id'] = 'new' + this.newid;
        }
      }

      this.set('newid', this.newid + 1);

      if (index >= 0 && index < listArray.length) {
        listArray[index] = item;

        if (!this.lastObjectEmpty()) {
          this.addRow();
        }
      }
    },

    prepForValidate(modelName, skipValidation = false, filePath = null, validationMessages = {}, model = null, overrideValidationKey) {
      var formattedObjects = Ember.A();
      this.trueListArray.forEach((item, index) => {
        if (!item.id || item.id.match(/new\d+/)) {
          if (typeof item.set === 'function') {
            item.set('id', null);
          } else {
            item['id'] = null;
          }
        }

        var validationObject = Ember.Object.create({
          modelName: modelName,
          model: model ? model : item,
          extraValidationData: {
            overrideValidationKey: overrideValidationKey || index + '-' + modelName,
            skip: skipValidation
          }
        });

        if (validationMessages !== {}) {
          validationObject['validationMessages'] = validationMessages;
        }

        if (filePath !== null) {
          validationObject['filePath'] = filePath;
        }

        formattedObjects.pushObject(validationObject);
      });
      return formattedObjects;
    },

    lastObjectEmpty() {
      var listArray = this.listArray;

      if (listArray.get('length') == 0) {
        return true;
      }

      var obj = listArray.objectAt(listArray.get('length') - 1);
      return !obj.hasOwnProperty('id');
    },

    removeRow() {
      this.listArray.popObject();
    }

  });

  _exports.default = _default;
});