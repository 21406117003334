define("securitytrax-ember-ui/components/multi-step-form/forms/invoices/view-processing-info/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/invoices/invoices-base", "ember-concurrency", "moment"], function (_exports, _invoicesBase, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _invoicesBase.default.extend({
    paymentService: Ember.inject.service(),
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    hiddenProgressBarSteps: Ember.computed(function () {
      return ['confirmRefundVoid', 'gettingStarted', 'viewProcessingInformation', 'history', 'editManualPayment'];
    }),
    disabledProgressBarSteps: Ember.computed(function () {
      return [];
    }),

    didInsertElement() {
      this._super(...arguments);

      this.set('showTopRightCloseButton', false);
      this.setupButtonTask.perform();
    },

    setupButtonTask: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = this.multiStepForm;
      var buttonText;
      var buttons = [];
      var currentPayment = yield this.args.multiStepForm.formOriginalModelsGetVal('customerPayment.model');

      if (currentPayment.processingStatus && currentPayment.processingStatus.toLowerCase() === 'complete' && this.grants.getIndividualGrant('customer_payments.refund') && maxRefundAmount > 0) {
        buttonText = this.intl.t('common.actions.refund');
      } else if (currentPayment.processingStatus && currentPayment.processingStatus.replace(/\s+/g, '').toLowerCase() === 'pendingsettlement' && this.grants.getIndividualGrant('customer_payments.void')) {
        buttonText = this.intl.t('common.actions.void');
      }

      if (buttonText) {
        buttons.push({
          text: buttonText,
          classNamesString: 'st-btn st-btn-warn',
          isDelete: true,
          click: () => {
            this.toVoidOrRefundTask.perform();
          }
        });
      }

      if (!this.singlePage) {
        buttons.push({
          text: this.intl.t('common.actions.back'),
          classNamesString: 'st-btn st-btn-secondary',
          click: () => {
            this.returnTask.perform();
          }
        });
      }

      multiStepForm.registerStepData('viewProcessingInformation', 'buttons', buttons);
    }),
    statusUpdated: Ember.computed('multiStepForm.currentStepData.currentPayment.processingStatusSubmitted', function () {
      return (0, _moment.default)(this.args.multiStepForm.formOriginalModelsGetVal('customerPayment.model.processingStatusSubmitted')).format('MM/DD/YYYY h:mm A zz');
    }),
    returnTask: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = yield this.multiStepForm;
      multiStepForm.currentStepNameKeySetVal('listPayments');
    }),
    toVoidOrRefundTask: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = yield this.multiStepForm;
      multiStepForm.registerStepData('confirmRefundVoid', 'currentVoidingOrRefundingCustomerPayment', yield this.args.multiStepForm.formOriginalModelsGetVal('customerPayment.model'));
      multiStepForm.currentStepNameKeySetVal('confirmRefundVoid');
    })
  });

  _exports.default = _default;
});