define("securitytrax-ember-ui/components/pages/reports/calendar-event-reports/component", ["exports", "securitytrax-ember-ui/components/pages/reports/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    reportCategoryId: Ember.computed('model', 'reportCategories', function () {
      var reportCategories = this.reportCategories;
      var reportCategory = reportCategories ? reportCategories.find(item => {
        return item.get('name') == 'Calendar Events';
      }) : this.model ? this.get('model.reportCategories').find(item => {
        return item.get('name') == 'Calendar Events';
      }) : null;
      return reportCategory ? reportCategory.id : null;
    })
  });

  _exports.default = _default;
});