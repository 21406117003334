define("securitytrax-ember-ui/home/tasks/edit-task/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cV7ybdpW",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"taskId\",\"parentRoute\"],[[35,0,[\"taskId\"]],[35,0,[\"parentRoute\"]]]]]]],\"hasEval\":false,\"upvars\":[\"model\",\"modals/sub-route-modals/edit-create-notes/tasks\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/home/tasks/edit-task/template.hbs"
    }
  });

  _exports.default = _default;
});