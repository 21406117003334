define("securitytrax-ember-ui/models/report-object-column", ["exports", "securitytrax-ember-ui/models/base_models/report-object-column"], function (_exports, _reportObjectColumn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _reportObjectColumn.default.extend({
    get displayName() {
      return this.name + ' - ' + this.about;
    }

  });

  _exports.default = _default;
});