define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/customer-create-chooser/create-form-configs/standard/component", ["exports", "securitytrax-ember-ui/mixins/customer-create-chooser"], function (_exports, _customerCreateChooser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_customerCreateChooser.default, {
    recentSearchesService: Ember.inject.service(),
    createCustomerFormSteps: Ember.computed('newCustomerName', 'grants.userGrants', function () {
      var intl = this.intl;
      var grantsService = this.grants;
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.newCustomer ? this.newCustomerName : intl.t('common.nav.create.customer.newCustomer'),
          stepComponentPath: 'multi-step-form/forms-dynamic/generic-steps/customer-create/information',
          progressBarTitle: intl.t('common.nav.create.customer.label'),
          isComplete: !!this.newCustomerName
        }),
        step2: Ember.Object.create({
          stepNameKey: 'step2',
          title: this.newCustomerName,
          stepComponentPath: 'multi-step-form/forms-dynamic/generic-steps/customer-create/qualify',
          progressBarTitle: intl.t('common.nav.create.customer.sections.qualify'),
          hidden: !grantsService.getIndividualGrant('customer_credit_checks.create')
        }),
        step3: Ember.Object.create({
          stepNameKey: 'step3',
          title: this.newCustomerName,
          stepComponentPath: 'multi-step-form/forms-dynamic/forms/standard/order',
          progressBarTitle: intl.t('common.nav.create.customer.sections.order')
        }),
        confirmDeleteCustomer: Ember.Object.create({
          stepNameKey: 'confirmDeleteCustomer',
          title: this.newCustomerName,
          stepComponentPath: 'multi-step-form/forms-dynamic/generic-steps/customer-create/confirm-delete-customer',
          hidden: true
        })
      });
    }),
    actions: {
      pushToRecentCustomer() {
        if (this.newCustomer) {
          this.recentSearchesService.addRecent('recentCustomers', this.newCustomer.id);
        }
      }

    }
  });

  _exports.default = _default;
});