define("securitytrax-ember-ui/initializers/version", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.inject('route', 'version', 'service:version');
    application.inject('controller', 'version', 'service:version');
    application.inject('component', 'version', 'service:version');
  }

  var _default = {
    before: 'component-router-injector',
    name: 'version-service-injector',
    initialize
  };
  _exports.default = _default;
});