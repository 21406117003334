define("securitytrax-ember-ui/helpers/find-by", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute(params) {
      if (!Array.isArray(params[0]) && !(params[0] instanceof _emberData.default.RecordArray) || !params[1] || params[1].length === 0 || +params[2] <= 0) {
        return null;
      }

      return params[0].findBy(params[1], params[2]);
    }

  });

  _exports.default = _default;
});