define("securitytrax-ember-ui/administration/company-settings/content-management/message-templates/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CDBtnLuS",
    "block": "{\"symbols\":[],\"statements\":[[8,\"nav/top-nav-sub\",[],[[\"@title\",\"@breadCrumbs\",\"@breadCrumbData\"],[[30,[36,0],[\"pages.administration.content_management.message_templates.title\"],null],[32,0,[\"breadCrumbs\"]],[32,0,[\"breadCrumbData\"]]]],null],[2,\"\\n\\n\"],[8,\"admin/message-templates\",[],[[],[]],null]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/administration/company-settings/content-management/message-templates/template.hbs"
    }
  });

  _exports.default = _default;
});