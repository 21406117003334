define("securitytrax-ember-ui/models/base_models/order-template", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    name: (0, _model.attr)('string', {
      'readOnly': false
    }),
    note: (0, _model.attr)('string', {
      'readOnly': false
    }),
    allowInvoice: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    allowWorkOrder: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    allowQuote: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    active: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    defaultActivationFee: (0, _model.belongsTo)('activation-fee', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    defaultMonitoringCompany: (0, _model.belongsTo)('monitoring-company', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    defaultMonitoringPlan: (0, _model.belongsTo)('monitoring-plan', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    defaultContractTerm: (0, _model.belongsTo)('contract-term', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    defaultInvoiceTerm: (0, _model.belongsTo)('invoice-term', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    defaultCustomerWarranty: (0, _model.belongsTo)('customer-warranty', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    defaultInteractiveServicesProvider: (0, _model.belongsTo)('interactive-services-provider', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    defaultMmrs: (0, _model.hasMany)('mmr', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    defaultPackages: (0, _model.hasMany)('package', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});