define("securitytrax-ember-ui/components/html/st-card/divider/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['st-card__divider'],
    classNameBindings: ['button-alignment'],

    init() {
      this._super(...arguments);

      if (this.additionalClasses === 'alignButtons') {
        this.set('button-alignment', 'in-line-buttons');
      }
    }

  });

  _exports.default = _default;
});