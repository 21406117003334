define("securitytrax-ember-ui/components/pages/accounting/invoices/refund-receipts/component", ["exports", "securitytrax-ember-ui/mixins/table-column-sort"], function (_exports, _tableColumnSort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableColumnSort.default, {
    tagName: '',
    actions: {
      transitionToSubroute(path) {
        this.router.transitionTo('customers.accounting.invoices-refund-receipts.edit-create-refund-receipt', path);
      }

    }
  });

  _exports.default = _default;
});