define("securitytrax-ember-ui/components/html/svg-components/simple-circles/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wi+ukWGp",
    "block": "{\"symbols\":[],\"statements\":[[10,\"svg\"],[14,1,\"donut\"],[12],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/svg-components/simple-circles/template.hbs"
    }
  });

  _exports.default = _default;
});