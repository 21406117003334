define("securitytrax-ember-ui/components/pages/administration/company-settings/content-management/customer-workflows/component", ["exports", "@glimmer/component", "lodash"], function (_exports, _component, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var WorkFlows = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, (_class = (_temp = class WorkFlows extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "workFlowConfig", _descriptor2, this);

      _initializerDefineProperty(this, "selectedWorkFlowType", _descriptor3, this);

      _initializerDefineProperty(this, "workFlowsFormMode", _descriptor4, this);

      _initializerDefineProperty(this, "workFlowsFormStarted", _descriptor5, this);
    }

    get workFlowModelName() {
      var workFlowName = {
        name: this.workFlowConfig.get('workflow.name')
      };
      return this.intl.t('pages.administration.content_management.workflows.footer', workFlowName);
    }

    get workFlowsFormSteps() {
      var viewOrAdd = this.workFlowsFormMode === 'create' ? 'create' : 'view';
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.intl.t("common.forms.descriptive_title_".concat(viewOrAdd), {
            name: _lodash.default.get(this.workFlowConfig, 'name', this.intl.t('pages.administration.content_management.workflows.workflow')),
            id: _lodash.default.get(this.workFlowConfig, 'id', null)
          }),
          stepComponentPath: 'multi-step-form/forms/administration/content-management/edit-customer-workflows'
        })
      });
    }

    get workFlowsFormOriginalModels() {
      return Ember.Object.create({
        workFlow: {
          model: this.workFlowConfig
        }
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "workFlowConfig", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selectedWorkFlowType", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "workFlowsFormMode", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'edit';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "workFlowsFormStarted", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class));
  _exports.default = WorkFlows;
});