define("securitytrax-ember-ui/components/multi-step-form/forms/invoices/invoice/payments/edit-manual-payment/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms/invoices/base", "moment"], function (_exports, _emberConcurrency, _base, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EditManualPayment = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service('events/invoice-payments-events'), _dec5 = Ember._tracked, _dec6 = (0, _emberConcurrency.task)(function* () {
    var payment = yield this.args.multiStepForm.formOriginalModelsGetVal('customerPayment.model');
    this.paymentType = yield payment.customerPaymentType;
    this.setupButtons();
    this.args.multiStepForm.registerStepData(this.args.multiStepForm.currentStepNameKey, 'title', this.intl.t('pages.customers.accounting.customer_payments.processingInformation.modalTitle', {
      id: payment ? payment.get('id') : null,
      name: payment.get('customer.flname')
    }));
  }), _dec7 = (0, _emberConcurrency.task)(function* () {
    try {
      var currentPayment = yield this.args.multiStepForm.formOriginalModelsGetVal('customerPayment.model');
      yield currentPayment.destroyRecordWithRollbackTask.perform();

      if (this.args.invoice) {
        yield (0, _emberConcurrency.hash)({
          reloadInvoice: this.args.invoice.reload(),
          reloadCustomerInvoicePayments: this.args.invoice.get('customerInvoicePayments').reload(),
          reloadCustomerPayments: this.args.invoice.get('customerPayments').reload()
        });
      }

      if (this.args.singlePage) {
        this.args.transitionBackToParent();
        this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
      } else {
        this.stNotificationManager.addSuccessKey('common.notifications.delete_successful', 'modal');
        Ember.set(this.args.multiStepForm, 'currentStepNameKey', 'listPayments::payments');
      }
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'confirmDelete'
      });
      throw err;
    }
  }), _dec8 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var overrideConfig = Ember.Object.create({
      customerPayment: {
        model: this.args.multiStepForm.formOriginalModelsGetVal('customerPayment.model'),
        copyRelationMap: {
          customerPaymentType: {
            doValidate: false,
            doSave: false
          }
        }
      }
    });
    var extraValidationData = {
      noValidate: ['customerPayment-receiptEmails']
    };
    yield this.args.multiStepForm.validateSyncSaveAll({
      extraValidationData: extraValidationData,
      overrideOriginalModelsConfig: overrideConfig,
      notificationNamespace: 'modal'
    });
    buttonPromise.then(() => {
      if (this.args.singlePage) {
        this.invoicePaymentsEvent.trigger('reload-list');
        this.args.transitionBackToParent();
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
      } else {
        Ember.set(this.args.multiStepForm, 'currentStepNameKey', 'listPayments::payments');
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
      }
    });
  }), (_class = (_temp = class EditManualPayment extends _base.default {
    get hiddenProgressBarSteps() {
      return ['confirmRefundVoid', 'gettingStarted', 'viewProcessingInformation', 'history', 'editManualPayment'];
    }

    get disabledProgressBarSteps() {
      return [];
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "grants", _descriptor2, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor3, this);

      _initializerDefineProperty(this, "invoicePaymentsEvent", _descriptor4, this);

      _initializerDefineProperty(this, "confirmDeleteModalOpen", _descriptor5, this);

      _initializerDefineProperty(this, "initData", _descriptor6, this);

      _initializerDefineProperty(this, "deleteCustomerPayment", _descriptor7, this);

      _initializerDefineProperty(this, "modifyCustomerPayment", _descriptor8, this);

      this.initData.perform();
    }

    setupButtons() {
      var buttons = [];

      if (!this.args.singlePage) {
        buttons.push({
          text: this.intl.t('common.actions.back'),
          classNamesString: 'st-btn st-btn-secondary',
          click: () => {
            this.args.setCustomerPayment(null);
            Ember.set(this.args.multiStepForm, 'currentStepNameKey', 'listPayments::payments');
          }
        });
      }

      if ((!this.paymentType || this.paymentType.name !== 'Credit Memo') && this.grants.getIndividualGrant('customer_payments.modify')) {
        if (!this.paymentType) {
          buttons.push({
            text: this.intl.t('common.actions.processNow'),
            classNamesString: 'st-btn st-btn-secondary',
            click: buttonPromise => {
              this.args.multiStepForm.formCopiedModelsSetVal('customerPayment.model.paymentDate', (0, _moment.default)());
              return this.modifyCustomerPayment.perform(buttonPromise);
            }
          });
        }

        buttons.push({
          text: this.intl.t('common.actions.save'),
          classNamesString: 'st-btn st-btn-primary',
          click: buttonPromise => {
            return this.modifyCustomerPayment.perform(buttonPromise);
          }
        });
      }

      if ((!this.paymentType || this.paymentType.name !== 'Credit Memo') && this.grants.getIndividualGrant('customer_payments.delete')) {
        buttons.push({
          text: this.intl.t('common.actions.delete'),
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete',
          isDelete: true,
          click: () => {
            Ember.set(this, 'confirmDeleteModalOpen', true);
          }
        });
      }

      this.args.multiStepForm.registerStepData(this.args.multiStepForm.currentStepNameKey, 'buttons', buttons);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "invoicePaymentsEvent", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "confirmDeleteModalOpen", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "initData", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "deleteCustomerPayment", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "modifyCustomerPayment", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = EditManualPayment;
});