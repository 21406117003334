define("securitytrax-ember-ui/components/multi-step-form/forms/messages/create-customer-message/component", ["exports", "@glimmer/component", "ember-concurrency", "lodash"], function (_exports, _component, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CreateCustomerMessage = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = (0, _emberConcurrency.task)(function* () {
    var hashedData = yield (0, _emberConcurrency.hash)({
      messageTemplates: this.getMesssageTemplatesTask.perform(),
      waitForFinishedCopyModels: (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true)
    });
    this.args.multiStepForm.formCopiedModelsSetVal('customerMessage.model.sendTo', []);
    this.messageTemplates = hashedData.messageTemplates;
  }), _dec18 = (0, _emberConcurrency.task)(function* () {
    return yield this.store.query('calendarEvent', {
      filter: "(calendar_category = 'tech_schedule') and (customer = ".concat(this.args.customer.get('id'), ")"),
      include: 'calendar_user.name,calendar_event_type.name'
    });
  }), _dec19 = (0, _emberConcurrency.task)(function* () {
    return yield this.store.query('calendarEvent', {
      filter: "(calendar_category = 'sale_schedule') and (customer = ".concat(this.args.customer.get('id'), ")"),
      include: 'calendar_user.name,calendar_event_type.name'
    });
  }), _dec20 = (0, _emberConcurrency.task)(function* () {
    var allNotes = Ember.A();
    var hashData = yield (0, _emberConcurrency.hash)({
      tasks: this.stQueryParams.loadRelationshipWithQueryParams({
        model: this.args.customer,
        relName: 'customerTasks',
        useCallback: false,
        options: {
          params: {
            limit: 0
          }
        }
      }),
      notes: this.stQueryParams.loadRelationshipWithQueryParams({
        model: this.args.customer,
        relName: 'customerNotes',
        useCallback: false,
        options: {
          params: {
            limit: 0,
            include: 'note_type'
          }
        }
      })
    });
    allNotes.pushObjects(hashData.tasks.toArray());
    allNotes.pushObjects(hashData.notes.toArray());
    return allNotes;
  }), _dec21 = (0, _emberConcurrency.task)(function* () {
    return yield this.store.query('customerAtRisk', {
      filter: "customer = ".concat(this.args.customer.get('id')),
      include: 'customer_at_risk_reason.name,customer_at_risk_reason_cause.name'
    });
  }), _dec22 = (0, _emberConcurrency.task)(function* () {
    var filter;

    if (yield this.enhancedFeatures.hasFeature('sms')) {
      filter = "message_type = ".concat(this.args.customer.recordType === 'customer' ? 1 : 2);
    } else {
      filter = "message_type = ".concat(this.args.customer.recordType === 'customer' ? 1 : 2, " and delivery_protocol != 'sms'");
    }

    var message = yield this.store.query('message', {
      filter: filter
    });
    return message;
  }), _dec23 = (0, _emberConcurrency.task)(function* () {
    var groupData = {};
    var firstColumn = this.args.multiStepForm.formCopiedModels.customerMessage.model.templateMessage.deliveryProtocol === 'sms' ? this.intl.t('common.columns.number') : this.intl.t('common.columns.email');
    groupData.rowData = [];
    groupData.columnHeaders = [firstColumn, this.intl.t('common.columns.description')];
    var bodyItemClassArr = ['small-12 medium-5', 'small-12 medium-6'];
    groupData.columnHeaderClasses = bodyItemClassArr; // SMS delivery type

    if (this.args.multiStepForm.formCopiedModels.customerMessage.model.templateMessage.deliveryProtocol === 'sms') {
      // SMS delivery type
      var phones = {};
      this.args.customer.constructor.eachAttribute(attr => {
        // Don't use lodash.pickBy cause it cause through everything and tries to fetch a value for it which causes 404s on leads
        if (_lodash.default.endsWith(attr, 'Phone') && this.args.customer.get(attr)) {
          phones[attr] = this.args.customer.get(attr);
        }
      });
      Object.keys(phones).forEach(key => {
        groupData.rowData.push(Ember.Object.create({
          selectedLabel: this.convertPhone(phones[key]) + ' - ' + this.convertDescription(key),
          columnData: [this.convertPhone(phones[key]), this.convertDescription(key)],
          phone: phones[key],
          bodyItemClasses: bodyItemClassArr
        }));
      });
      return groupData;
    } else if (this.args.multiStepForm.formCopiedModels.customerMessage.model.templateMessage.deliveryProtocol === 'email') {
      // Email delivery type
      if (this.args.customer.get('email')) {
        groupData.rowData.push(Ember.Object.create({
          selectedLabel: this.args.customer.get('email') + this.intl.t('pages.customers.messages.primary_email'),
          columnData: [this.args.customer.get('email'), 'Primary'],
          // Primary email defaults to selected
          inAddArray: true,
          bodyItemClasses: bodyItemClassArr
        }));
      }
    }

    if (this.args.customer.get('recordType') === 'customer') {
      var emergencyContacts = yield this.args.customer.get('customerContacts');

      for (var i = 0; i < emergencyContacts.get('length'); i++) {
        var contact = emergencyContacts.objectAt(i);

        if (this.args.multiStepForm.formCopiedModels.customerMessage.model.templateMessage.deliveryProtocol === 'email') {
          if (contact.get('email')) {
            groupData.rowData.push(Ember.Object.create({
              selectedLabel: contact.get('email') + ' - ' + contact.get('name'),
              columnData: [contact.get('email'), contact.get('name')],
              bodyItemClasses: bodyItemClassArr
            }));
          }
        } else {
          Ember.Object.create({
            selectedLabel: this.convertPhone(contact.get('phone1')) + ' - ' + "".concat(contact.get('name'), " (").concat(contact.get('about'), ")"),
            columnData: [this.convertPhone(contact.get('phone1')), "".concat(contact.get('name'), " (").concat(contact.get('about'), ")")],
            phone: contact.get('phone1'),
            bodyItemClasses: bodyItemClassArr
          });
        }
      }
    }

    return groupData;
  }), _dec24 = (0, _emberConcurrency.task)(function* () {
    var readyToPost = false;
    var dynamicFieldObjects = [];
    dynamicFieldObjects.push({
      type: 'customer',
      id: this.args.customer.get('id')
    });
    var dropdownCount = this.setupDynamicFields(dynamicFieldObjects);

    if (dynamicFieldObjects.length === dropdownCount + 1 || this.mergeSelectionsSelected) {
      readyToPost = true;
    }

    if (readyToPost || dropdownCount === 0 && this.args.multiStepForm.formCopiedModels.customerMessage.model.templateMessage) {
      var preview = yield this.args.multiStepForm.formCopiedModels.customerMessage.model.templateMessage.invokeAction('preview', {
        verb: 'POST',
        data: {
          data: {
            relationships: {
              customer: {
                data: {
                  type: 'customers',
                  id: this.args.customer.get('id')
                }
              }
            }
          },
          meta: {
            dynamic_field_objects: dynamicFieldObjects
          }
        }
      });

      if (this.args.multiStepForm.formCopiedModels.customerMessage.model.templateMessage.deliveryProtocol === 'sms') {
        this.messageData = preview.responsePayload.data.attributes.text_body;
        return;
      } else {
        this.messageData = preview.responsePayload.data.attributes.html_body;
        return;
      }
    } else {
      if (!this.args.multiStepForm.formCopiedModels.customerMessage.model.templateMessage) {
        this.messageData = null;
        return;
      } else {
        var text;

        if (this.args.multiStepForm.formCopiedModels.customerMessage.model.templateMessage.deliveryProtocol === 'sms') {
          text = this.args.multiStepForm.formCopiedModels.customerMessage.model.templateMessage.textBody;
        } else if (this.args.multiStepForm.formCopiedModels.customerMessage.model.templateMessage.deliveryProtocol === 'email') {
          text = this.args.multiStepForm.formCopiedModels.customerMessage.model.templateMessage.htmlBody;
        }

        var firstPass = _lodash.default.replace(text, /%%\[/g, '<span style="color:red">%%[');

        var modifiedText = _lodash.default.replace(firstPass, /\]%%/g, ']%%</span>');

        this.messageData = modifiedText;
        return;
      }
    }
  }), _dec25 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    try {
      var sendToArray = [];
      var dynamicFieldObjects = [];
      this.args.multiStepForm.formCopiedModels.customerMessage.model.sendTo.forEach(to => {
        if (this.args.multiStepForm.formCopiedModels.customerMessage.model.templateMessage.deliveryProtocol === 'sms') {
          sendToArray.push(to.phone);
        } else {
          sendToArray.push(to.columnData[0]);
        }
      });
      this.setupDynamicFields(dynamicFieldObjects);
      var noValidateArray = [];

      if (!this.techSchedules) {
        noValidateArray.push('customerMessage-technicianAppointment');
      }

      if (!this.salesSchedules) {
        noValidateArray.push('customerMessage-salesAppointment');
      }

      if (!this.customerNotes) {
        noValidateArray.push('customerMessage-customerNote');
      }

      if (!this.customerAtRiskEntries) {
        noValidateArray.push('customerMessage-atRiskEntry');
      }

      var formattedObjects = Ember.A();
      formattedObjects.pushObject(Ember.Object.create({
        model: yield this.args.multiStepForm.formCopiedModelsGetVal('customerMessage.model'),
        deepValidate: false,
        extraValidationData: {
          noValidate: noValidateArray
        },
        filePath: 'customer-message'
      }));
      yield this.args.multiStepForm.validateList.perform(formattedObjects);
      yield this.args.multiStepForm.formCopiedModels.customerMessage.model.templateMessage.invokeAction('send', {
        verb: 'POST',
        data: {
          data: {
            relationships: {
              customer: {
                data: {
                  type: 'customers',
                  id: this.args.customer.get('id')
                }
              }
            }
          },
          meta: {
            dynamic_field_objects: dynamicFieldObjects,
            to: sendToArray
          }
        }
      });
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccess('Send Successful');
        this.args.multiStepForm.formStartedSetVal(false);
        this.args.multiStepForm.onAfterSave();
      });
    } catch (err) {
      (false && Ember.warn('Message Send failed: ' + err, {
        id: 'customers.messages.sendMessage'
      }));
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), _dec26 = (0, _emberConcurrency.task)(function* () {
    Ember.setProperties(this.args.multiStepForm.formCopiedModelsGetVal('customerMessage.model'), {
      technicianAppointment: null,
      salesAppointment: null,
      customerNote: null,
      atRiskEntry: null,
      messagePreview: null,
      sendTo: []
    });

    if (this.args.multiStepForm.formCopiedModels.customerMessage && this.args.multiStepForm.formCopiedModels.customerMessage.model.templateMessage) {
      this.toGroupData = yield this.toGroupDataTask.perform();
      this.tempGroupData = {
        rowData: [],
        bodyItemClassArr: ['small-12 medium-5', 'small-12 medium-5']
      };
      var deliveryProtocol = this.args.multiStepForm.formCopiedModels.customerMessage.model.templateMessage.deliveryProtocol;
      var bodyText = deliveryProtocol === 'sms' ? this.args.multiStepForm.formCopiedModels.customerMessage.model.templateMessage.textBody : this.args.multiStepForm.formCopiedModels.customerMessage.model.templateMessage.htmlBody;
      this.createGroupItemLabel = deliveryProtocol === 'sms' ? this.intl.t('pages.customers.messages.create_sms_label') : this.intl.t('pages.customers.messages.create_email_label');
      this.createGroupItemPlaceholder = deliveryProtocol === 'sms' ? this.intl.t('common.columns.number') : this.intl.t('common.columns.email');

      if (bodyText) {
        if (bodyText.search(/\[tech_schedule\]/) !== -1) {
          this.techSchedules = yield this.getTechSchedulesTask.perform();
        } else {
          this.techSchedules = null;
        }

        if (bodyText.search(/\[sale_schedule\]/) !== -1) {
          this.salesSchedules = yield this.getSalesSchedulesTask.perform();
        } else {
          this.salesSchedules = null;
        }

        if (bodyText.search(/\[customer_note\]/) !== -1) {
          this.customerNotes = yield this.getCustomerNotesTask.perform();
        } else {
          this.customerNotes = null;
        }

        if (bodyText.search(/\[customer_at_risk\]/) !== -1) {
          this.customerAtRiskEntries = yield this.getCustomerAtRiskEntriesTask.perform();
        } else {
          this.customerAtRiskEntries = null;
        }
      } else {
        Ember.setProperties(this, {
          techSchedules: null,
          salesSchedules: null,
          customerNotes: null,
          customerAtRiskEntries: null
        });
      }

      if (deliveryProtocol === 'sms' || deliveryProtocol === 'email') {
        yield this.getPreviewOrFormattedTextTask.perform();
      }
    }
  }), _dec27 = Ember._action, _dec28 = Ember._action, _dec29 = Ember._action, (_class = (_temp = class CreateCustomerMessage extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "messageService", _descriptor3, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor4, this);

      _initializerDefineProperty(this, "enhancedFeatures", _descriptor5, this);

      _initializerDefineProperty(this, "stQueryParams", _descriptor6, this);

      _initializerDefineProperty(this, "messagePreview", _descriptor7, this);

      _initializerDefineProperty(this, "messageTemplates", _descriptor8, this);

      _initializerDefineProperty(this, "toGroupData", _descriptor9, this);

      _initializerDefineProperty(this, "techSchedules", _descriptor10, this);

      _initializerDefineProperty(this, "salesSchedules", _descriptor11, this);

      _initializerDefineProperty(this, "customerNotes", _descriptor12, this);

      _initializerDefineProperty(this, "customerAtRiskEntries", _descriptor13, this);

      _initializerDefineProperty(this, "messageData", _descriptor14, this);

      _initializerDefineProperty(this, "mergeSelectionsSelected", _descriptor15, this);

      _initializerDefineProperty(this, "tempGroupData", _descriptor16, this);

      _initializerDefineProperty(this, "initTask", _descriptor17, this);

      _initializerDefineProperty(this, "getTechSchedulesTask", _descriptor18, this);

      _initializerDefineProperty(this, "getSalesSchedulesTask", _descriptor19, this);

      _initializerDefineProperty(this, "getCustomerNotesTask", _descriptor20, this);

      _initializerDefineProperty(this, "getCustomerAtRiskEntriesTask", _descriptor21, this);

      _initializerDefineProperty(this, "getMesssageTemplatesTask", _descriptor22, this);

      _initializerDefineProperty(this, "toGroupDataTask", _descriptor23, this);

      _initializerDefineProperty(this, "getPreviewOrFormattedTextTask", _descriptor24, this);

      _initializerDefineProperty(this, "sendMessageTask", _descriptor25, this);

      _initializerDefineProperty(this, "templateChangedTask", _descriptor26, this);

      this.initTask.perform();
    }

    get formattedBody() {
      return this.getPreviewOrFormattedTextTask.perform();
    }

    setupDynamicFields(dynamicFieldObjects) {
      var dropdownCount = 0;

      if (this.techSchedules) {
        dropdownCount++;

        if (this.args.multiStepForm.formCopiedModelsGetVal('customerMessage.model.technicianAppointment')) {
          dynamicFieldObjects.push({
            type: 'tech_schedule',
            id: JSON.parse(this.args.multiStepForm.formCopiedModelsGetVal('customerMessage.model.technicianAppointment').get('id')).id
          });
        }
      }

      if (this.salesSchedules) {
        dropdownCount++;

        if (this.args.multiStepForm.formCopiedModelsGetVal('customerMessage.model.salesAppointment')) {
          dynamicFieldObjects.push({
            type: 'sale_schedule',
            id: JSON.parse(this.args.multiStepForm.formCopiedModelsGetVal('customerMessage.model.salesAppointment').get('id')).id
          });
        }
      }

      if (this.customerNotes) {
        dropdownCount++;

        if (this.args.multiStepForm.formCopiedModelsGetVal('customerMessage.model.customerNote')) {
          dynamicFieldObjects.push({
            type: 'customer_note',
            id: this.args.multiStepForm.formCopiedModelsGetVal('customerMessage.model.customerNote').get('id')
          });
        }
      }

      if (this.customerAtRiskEntries) {
        dropdownCount++;

        if (this.args.multiStepForm.formCopiedModelsGetVal('customerMessage.model.atRiskEntry')) {
          dynamicFieldObjects.push({
            type: 'customer_at_risk',
            id: this.args.multiStepForm.formCopiedModelsGetVal('customerMessage.model.atRiskEntry').get('id')
          });
        }
      }

      return dropdownCount;
    }

    convertPhone(number) {
      var cleaned = ('' + number).replace(/\D/g, '');
      var preDigit = '';

      if (cleaned.length === 11) {
        preDigit = cleaned.substring(0, 1) + '-';
        cleaned = cleaned.substring(1);
      }

      var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        return preDigit + match[1] + '-' + match[2] + '-' + match[3];
      }

      return number;
    }

    convertDescription(description) {
      switch (description) {
        case 'primaryPhone':
          return this.intl.t('common.nav.create.customer.primaryPhone.label');

        case 'cellPhone':
          return this.intl.t('common.nav.create.customer.cellPhone.label');

        case 'workPhone':
          return this.intl.t('common.nav.create.customer.workPhone.label');

        default:
          return this.intl.t('common.nav.create.customer.otherPhone.label');
      }
    }

    buttons() {
      var buttons = [];
      buttons.push({
        text: this.intl.t('common.actions.send'),
        click: buttonPromise => {
          return this.sendMessageTask.perform(buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary'
      });
      this.args.multiStepForm.registerStepData('step1', 'buttons', buttons);
    }

    mergeSelectionsUpdated() {
      Ember.set(this, 'mergeSelectionsSelected', !this.mergeSelectionsSelected);
      this.getPreviewOrFormattedTextTask.perform();
    }

    addNewGroupDataItem(value) {
      var newItem = {
        data: null
      };

      if (this.args.multiStepForm.formCopiedModels.customerMessage.model.templateMessage.deliveryProtocol === 'sms') {
        var goodPhone = /^[+]?[0-9]?[-\s.]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,8}$/g;

        if (goodPhone.test(value)) {
          newItem.data = Ember.Object.create({
            selectedLabel: this.convertPhone(value),
            columnData: [this.convertPhone(value), ''],
            phone: value.replace(/\D/g, ''),
            inAddArray: true,
            bodyItemClasses: this.tempGroupData.bodyItemClassArr
          });
        } else {
          newItem.error = this.intl.t('pages.customers.messages.invalid_sms');
        }
      } else if (this.args.multiStepForm.formCopiedModels.customerMessage.model.templateMessage.deliveryProtocol === 'email') {
        var res = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (res.test(value.toLowerCase())) {
          newItem.data = Ember.Object.create({
            selectedLabel: value,
            columnData: [value, ''],
            inAddArray: true,
            bodyItemClasses: this.tempGroupData.bodyItemClassArr
          });
        } else {
          newItem.error = this.intl.t('pages.customers.messages.invalid_email');
        }
      }

      return newItem;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "messageService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "enhancedFeatures", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "stQueryParams", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "messagePreview", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "messageTemplates", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "toGroupData", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "techSchedules", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "salesSchedules", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "customerNotes", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "customerAtRiskEntries", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "messageData", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "mergeSelectionsSelected", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "tempGroupData", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "initTask", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "getTechSchedulesTask", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "getSalesSchedulesTask", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "getCustomerNotesTask", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "getCustomerAtRiskEntriesTask", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "getMesssageTemplatesTask", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "toGroupDataTask", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "getPreviewOrFormattedTextTask", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "sendMessageTask", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "templateChangedTask", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "buttons", [_dec27], Object.getOwnPropertyDescriptor(_class.prototype, "buttons"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mergeSelectionsUpdated", [_dec28], Object.getOwnPropertyDescriptor(_class.prototype, "mergeSelectionsUpdated"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addNewGroupDataItem", [_dec29], Object.getOwnPropertyDescriptor(_class.prototype, "addNewGroupDataItem"), _class.prototype)), _class));
  _exports.default = CreateCustomerMessage;
});