define("securitytrax-ember-ui/demo/grants/controller", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller.extend({
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    customerContact: null,
    customerContactsModify: null,
    customerContactsDelete: null,
    customerMessageView: null,
    recurringInvoicesView: null,
    invoicesModify: null,
    invoicesView: null,
    refundReceiptsView: null,
    quotesView: null,

    init() {
      this._super(...arguments); //setup some properties for the grants we will be using for the list that can be used everywhere


      Ember.setProperties(this, {
        customerContactsModify: this.grants.getIndividualGrant('customer_contacts.modify'),
        customerContactsDelete: this.grants.getIndividualGrant('customer_contacts.delete'),
        customerMessageView: this.grants.getIndividualGrant('customer_messages.view'),
        recurringInvoicesView: this.grants.getIndividualGrant('recurring_invoices.view'),
        invoicesModify: this.grants.getIndividualGrant('customer_invoices.modify'),
        invoicesView: this.grants.getIndividualGrant('customer_invoices.view'),
        refundReceiptsView: this.grants.getIndividualGrant('customer_refund_receipts.view'),
        creditMemosView: this.grants.getIndividualGrant('customer_credit_memos.view'),
        quotesView: this.grants.getIndividualGrant('customer_quotes.view')
      });
    },

    singleStepForm: Ember.computed(function () {
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: 'Demo Edit Contact',
          stepComponentPath: 'multi-step-form/forms/demo/demo-modal-create'
        })
      });
    }),
    formOriginalModels: Ember.computed('customerContact', function () {
      return Ember.Object.create({
        customerContact: {
          model: this.customerContact
        }
      });
    }),
    formStarted: false,

    /**
     * Returns quotes, receipts, invoices and recurring invoices, grouped for list.
     *
     * @returns {DS.PromiseArray<{name: String, icon: String, groupPropertySections: Array<Object>, items: Array<models.CustomerInvoice>}>}
     */
    groupedInvoices: Ember.computed('model.customer.{recurringInvoices,invoices,quotes,refundReceipts,creditMemos}.[]', 'grants.userGrants', 'recurringInvoicesView', 'refundReceiptsView', 'creditMemosView', 'invoicesModify', 'invoicesView', 'quotesView', function () {
      var grantsService = this.grants;
      var promiseQueue = Ember.A([]);
      var recurringModify = grantsService.getIndividualGrant('recurring_invoices.modify');
      var recurringDelete = grantsService.getIndividualGrant('recurring_invoices.delete'); //EXAMPLE: show or hide a section based on grants. Also affects setting the groups and result set below. check grants for each step
      //RECURRING

      var recurringView = this.recurringInvoicesView;

      if (recurringView) {
        //unshift puts at beginning of array, in case we init with other ones that aren't grant specific
        promiseQueue.unshift(this.get('model.customer.recurringInvoices'));
      } //INVOICES


      var invoicesView = this.invoicesView;

      if (invoicesView) {
        promiseQueue.push(this.get('model.customer.invoices'));
      } // REFUND RECEIPTS


      var refundReceiptsView = this.refundReceiptsView;

      if (refundReceiptsView) {
        promiseQueue.push(this.get('model.customer.refundReceipts'));
      } //QUOTES


      var quotesView = this.quotesView;

      if (quotesView) {
        promiseQueue.push(this.get('model.customer.quotes'));
      } //CREDIT MEMOS


      var creditMemosView = this.creditMemosView;

      if (creditMemosView) {
        promiseQueue.push(this.get('model.customer.creditMemos'));
      }

      return _emberData.default.PromiseArray.create({
        promise: Ember.RSVP.all(promiseQueue).then(resultSet => {
          var groupedInvoices = Ember.A();
          var data = Ember.A(); //EXAMPLE, pass grants so column formatter can make decisions to show or hide actionable icons

          if (recurringView) data.pushObject({
            key: 'recurringInvoices',
            icon: 'icn-contract-ending',
            grants: {
              recurringInvoice: {
                //the key needs to match the row type
                modify: recurringModify,
                delete: recurringDelete
              }
            }
          });
          if (invoicesView) data.pushObject({
            key: 'invoices',
            //you could make this dependent on grants as well
            icon: 'icn-payment-history',
            grants: {
              invoice: {
                modify: this.invoicesModify
              }
            }
          });
          if (refundReceiptsView) data.pushObject({
            key: 'refundReceipts',
            icon: 'icn-payment-history'
          });
          if (creditMemosView) data.pushObject({
            key: 'creditMemos',
            icon: 'icn-payment-history'
          });
          if (quotesView) data.pushObject({
            key: 'quotes',
            icon: 'icn-billing'
          }); //EXAMPLE make sure to go through each item in data

          data.forEach(group => {
            groupedInvoices.pushObject(Ember.Object.create({
              name: this.intl.t('pages.customers.order.' + group.key),
              key: group.key,
              //make sure to pass key
              grants: group.grants,
              //make sure to pass grants
              icon: group.icon,
              items: Ember.A(),
              sections: [{
                position: 'left',
                sectionProperties: [{
                  formatter: 'svg-icon',
                  propertyName: 'icon'
                }, {
                  formatter: 'label',
                  propertyName: 'name'
                }]
              }, {
                position: 'right',
                sectionProperties: [{
                  formatter: 'count',
                  propertyName: 'items'
                }]
              }]
            }));
          }); //EXAMPLE make sure the result set lines up with which grants/promises are there

          var recurringInvoicesResultSet = resultSet.filter(r => r.key === 'recurringInvoices');
          var invoicesResultSet = resultSet.filter(r => r.key === 'invoices');
          var refundReceiptsResultSet = resultSet.filter(r => r.key === 'refundReceipts');
          var creditMemosResultSet = resultSet.filter(r => r.key === 'creditMemos');
          var quotesResultSet = resultSet.filter(r => r.key === 'quotes'); // combine refundReceipts to invoices, taking into account grants
          // this is no longer combined in the real app, so commented out here as well
          // const invoicesAndrefundReceipts = Ember.A();
          // if(invoicesView && refundReceiptsView) {
          //     const combined = invoicesResultSet[0].pushObjects(creditMemosResultSet[0]);
          //     invoicesAndrefundReceipts.pushObject(combined);
          // } else if (refundReceiptsView) {
          //     invoicesAndrefundReceipts.pushObject(creditMemosResultSet[0]);
          // } else if(invoicesView) {
          //     //exclude credit memos.
          //     const invoicesOnly = invoicesResultSet[0].filter(f => f.data.invoiceType === 'invoice');
          //     invoicesAndrefundReceipts.pushObject(invoicesOnly);
          // }

          if (recurringView) groupedInvoices.filter(r => r.key === 'recurringInvoices')[0].items = recurringInvoicesResultSet[0];
          if (invoicesView) groupedInvoices.filter(r => r.key === 'invoices')[0].items = invoicesResultSet[0];
          if (refundReceiptsView) groupedInvoices.filter(r => r.key === 'refundReceipts')[0].items = refundReceiptsResultSet[0];
          if (creditMemosResultSet) groupedInvoices.filter(r => r.key === 'creditMemos')[0].items = creditMemosResultSet[0];
          if (quotesView) groupedInvoices.filter(r => r.key === 'quotes')[0].items = quotesResultSet[0];
          return groupedInvoices;
        })
      });
    }),
    actions: {
      //this is a function for the demo page so we can see the list based on different grants
      toggleGrant(grantName, valueProperty) {
        var grantsService = this.grants;
        return grantsService.setIndividualGrant(grantName, this.get(valueProperty));
      }

    }
  });
  var _default = Controller;
  _exports.default = _default;
});