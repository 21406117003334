define("securitytrax-ember-ui/models/customer-accounting-information", ["exports", "securitytrax-ember-ui/models/base_models/customer-accounting-information"], function (_exports, _customerAccountingInformation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerAccountingInformation.default.extend({});

  _exports.default = _default;
});