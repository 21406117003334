define("securitytrax-ember-ui/mixed-in-classes/cs-link-account", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CSLinkAccount = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = (0, _emberConcurrency.task)(function* (mcTypeName, monitoringCompanyType, buttonPromise) {
    try {
      yield this.centralStationService.postData({
        model: this.args.customer,
        method: "".concat(mcTypeName, "__linkAccountToSecurityTraxAccount"),
        payload: {
          confirm_link_account: true
        }
      });
      buttonPromise.then(() => {
        this.store.findRecord('customerSystemInformation', this.args.customer.get('id')).then(systemInfo => {
          this.formOpen = false;
          systemInfo.reload();
          this.router.transitionTo('customers.information.manage-' + this.subrouteMapping.monitorMapping.findBy('id', monitoringCompanyType.get('id')).name);
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful', "".concat(mcTypeName, "Modal"));
        });
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'linkAccountModal'
      });
      throw err;
    }
  }), (_class = (_temp = class CSLinkAccount extends _component.default {
    // @tracked getBasicCustomerInformation = undefined;
    // @tracked getCustomerDiscrepancies = undefined;
    // @tracked checkCustomerLinkEligibility = undefined;
    constructor(classArguments, templateArguments, optionalArguments) {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "subrouteMapping", _descriptor3, this);

      _initializerDefineProperty(this, "linkAccountModalOpen", _descriptor4, this);

      _initializerDefineProperty(this, "linkModalTitle", _descriptor5, this);

      _initializerDefineProperty(this, "accountFailText", _descriptor6, this);

      _initializerDefineProperty(this, "confirmLinkAccount", _descriptor7, this);

      if (optionalArguments) {
        // Not needed here until the link account modals are converted
        // this.getBasicCustomerInformation = optionalArguments.getBasicCustomerInformation;
        // this.getCustomerDiscrepancies = optionalArguments.getCustomerDiscrepancies;
        this.checkCustomerLinkEligibility = optionalArguments.checkCustomerLinkEligibility;
      } // Not needed here until the link account modals are converted
      // defineProperty(this, 'basicCustomerInformation',
      //     computed('accountFailText', this.store.getFullComputedWatcherStringByModelName('customer'), function () {
      //         if (!this.accountFailText) {
      //             return this.getBasicCustomerInformation.perform();
      //         } else {
      //             return null;
      //         } 
      //     }));
      // defineProperty(this, 'customerDiscrepancies',
      //     computed(this.store.getFullComputedWatcherStringByModelName('customer'), function () {
      //         return this.getCustomerDiscrepancies.perform();
      //     }));

    }

    get hasContextualButtonClass() {
      if (this.args.multiStepForm.formOriginalModels.customerSystemInformation.model.accountNum && !this.args.multiStepForm.formOriginalModels.customerSystemInformation.model.centralStationId) {
        return 'has-contextual-button';
      } else {
        return '';
      }
    }

    get hasDropdownClickActionsClass() {
      if (this.args.multiStepForm.formOriginalModels.customerSystemInformation.model.accountNum) {
        return 'drop-down-click-actions';
      } else {
        return '';
      }
    }

    get shellAccountAdditionalClickActions() {
      if (this.args.multiStepForm.formOriginalModels.customerSystemInformation.model.accountNum) {
        return [{
          label: this.intl.t('pages.customers.system.link_account'),
          action: () => {
            return this.checkCustomerLinkEligibility.perform();
          },
          classNamesString: ''
        }];
      } else {
        return null;
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "subrouteMapping", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "linkAccountModalOpen", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "linkModalTitle", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "accountFailText", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "confirmLinkAccount", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CSLinkAccount;
});