define("securitytrax-ember-ui/models/company", ["exports", "securitytrax-ember-ui/models/base_models/company", "lodash"], function (_exports, _company, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _company.default.extend({
    calendarEventDetailTypeDefaults: Ember.computed('dataDefaults', function () {
      var dataDefaults = this.dataDefaults;
      return {
        installSubtypeId: _lodash.default.get(dataDefaults, 'forms.appointments.install_subtype_id', null),
        serviceSubtypeId: _lodash.default.get(dataDefaults, 'forms.appointments.service_subtype_id', null),
        removalSubtypeId: _lodash.default.get(dataDefaults, 'forms.appointments.removal_subtype_id', null),
        salesSubtypeId: _lodash.default.get(dataDefaults, 'forms.appointments.sales_subtype_id', null),
        timeOffSubtypeId: _lodash.default.get(dataDefaults, 'forms.appointments.time_off_subtype_id', null)
      };
    }),
    invoiceDefaults: Ember.computed('dataDefaults', function () {
      var dataDefaults = this.dataDefaults;
      return {
        termId: _lodash.default.get(dataDefaults, 'forms.invoice.term_id', null),
        fromEmail: _lodash.default.get(dataDefaults, 'forms.invoice.from_email', null),
        fromName: _lodash.default.get(dataDefaults, 'forms.invoice.from_name', null),
        companyName: _lodash.default.get(dataDefaults, 'forms.invoice.company_name', null),
        phone: _lodash.default.get(dataDefaults, 'forms.invoice.phone', null),
        street: _lodash.default.get(dataDefaults, 'forms.invoice.street', null),
        city: _lodash.default.get(dataDefaults, 'forms.invoice.city', null),
        state: _lodash.default.get(dataDefaults, 'forms.invoice.state', null),
        zip: _lodash.default.get(dataDefaults, 'forms.invoice.zip', null)
      };
    }),
    oneLineFullAddress: Ember.computed('street', 'city', 'state', 'zip', function () {
      var address = this.street ? this.street : '';
      var lastLine = "".concat(this.city ? this.city : '', " ").concat(this.city ? ', ' : '', " ").concat(this.state ? this.state : '', " ").concat(this.zip ? this.zip : '');
      return address + ' ' + lastLine;
    })
  });

  _exports.default = _default;
});