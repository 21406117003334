define("securitytrax-ember-ui/leads/base-route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/has-page-title", "securitytrax-ember-ui/utils/obj-helpers", "ember-concurrency", "securitytrax-ember-ui/config/environment", "securitytrax-ember-ui/classes/edit-lead-dashboard"], function (_exports, _authenticatedRouteMixin, _hasPageTitle, _objHelpers, _emberConcurrency, _environment, _editLeadDashboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _hasPageTitle.default, {
    recentSearchesService: Ember.inject.service(),
    store: Ember.inject.service(),
    grants: Ember.inject.service(),
    router: Ember.inject.service(),
    quickAdd: Ember.inject.service(),
    intl: Ember.inject.service(),
    homeDashboard: Ember.inject.service('events/home-dashboard'),

    model({
      id
    }) {
      this._super(...arguments);

      return this.setupModelTask.perform(id);
    },

    setupModelTask: (0, _emberConcurrency.task)(function* (id) {
      var store = this.store;
      var lead = yield store.findRecord('customer', id).catch(() => {
        // Should we notify user this request failed?
        this.transitionTo('leads.index');
      });
      var editDashboard = new _editLeadDashboard.default(...arguments, {
        customer: lead,
        intl: this.intl
      });
      var cardCount = 1;

      if (!lead.isLead) {
        var correspondingCustomersURL = this.get('router.currentURL').replace('/lead', 'customer').replace("/".concat(id), '');
        this.recentSearchesService.addRecent('recentCustomers', id);

        if (Ember.getOwner(this).lookup("route:".concat(correspondingCustomersURL))) {
          this.transitionTo(correspondingCustomersURL.replace('/', '.'), id);
        } else {
          this.transitionTo('customers.view', id);
        }
      } else {
        this.recentSearchesService.addRecent('recentLeads', id);
      }

      if (_environment.default.environment != 'test') {
        // Real land, issues with mirage
        cardCount = editDashboard.getAvailableGalleryCardsList(this.grants).length;
      }

      var parentController = this.parentController;
      parentController.set('lead', lead);
      parentController.set('leadId', lead.id);
      parentController.set('editDashboard', editDashboard);
      parentController.set('cardCount', cardCount);
      var hashData = yield Ember.RSVP.hash({
        customer: lead,
        lead: lead,
        tags: this.get('tagsService.getTagsByObjectType').perform('customer'),
        leadFiles: this.grants.getIndividualGrant('lead_files.view') ? lead.get('leadFiles') : [],
        customerNoteTags: this.get('tagsService.getTagsByObjectType').perform('customer_note'),
        editDashboard: editDashboard,
        cardCount
      });
      return hashData;
    }),

    getPageTitle(model) {
      if (model.lead.accountType === 'Commercial' && model.lead.businessName) {
        return model.lead.businessName;
      } else {
        return (0, _objHelpers.getObjProperties)(model.lead, 'fname', 'lname').join(' ');
      }
    },

    getRouteName() {
      var route = this.routeName.split('.').pop();
      return "pages.customers.nav.".concat(route.replace('-', '_'));
    },

    actions: {
      didTransition() {
        this.quickAdd.set('showQuickAdd', true);
        this.quickAdd.set('page', 'lead');
      }

    }
  });

  _exports.default = _default;
});