define("securitytrax-ember-ui/components/lists/jquery-ui-sortable/component", ["exports", "securitytrax-ember-ui/utils/obj-helpers", "ember-concurrency", "jquery"], function (_exports, _objHelpers, _emberConcurrency, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        jquery_ui_options: {},
        listData: [],
        dragSortable: true
      });
      this.onSortableChangeTask.perform();
    },

    dragSortable: undefined,
    jquery_ui_options: undefined,
    _inSortingMode: false,
    onSortableChange: Ember.observer('_inSortingMode', function () {
      this.onSortableChangeTask.perform();
    }),
    onSortableChangeTask: (0, _emberConcurrency.task)(function* () {
      if (yield this.dragSortable) {
        if (yield this._inSortingMode) {
          (0, _jquery.default)(this.element).sortable(_jquery.default.extend(true, yield this._jquery_ui_defaults, yield this.jquery_ui_options));
        } else {
          if ((0, _jquery.default)(this.element).data('ui-sortable')) {
            (0, _jquery.default)(this.element).sortable('destroy');
            this.dragSortDone();
          }
        }
      }
    }),
    listData: undefined,
    _jquery_ui_defaults: Ember.computed(function () {
      return {
        axis: 'y',
        cursor: 'move',
        opacity: 1,
        start: function (e, ui) {
          (0, _jquery.default)(ui.item).data('old-index', ui.item.index());
          (0, _jquery.default)(ui.item).addClass('moving');
          (0, _jquery.default)(this).sortable('refreshPositions');
        },
        update: (event, ui) => {
          var oldIndex = (0, _jquery.default)(ui.item).data('old-index');
          var newIndex = ui.item.index();
          (0, _jquery.default)(ui.item).removeClass('moving');
          this.dragSortUpdate(oldIndex, newIndex);
        }
      };
    })
  });

  _exports.default = _default;
});