define("securitytrax-ember-ui/components/calendar-glimmer/calendar-grid/calendar-grid-row/component", ["exports", "@glimmer/component", "ember-concurrency", "moment"], function (_exports, _component, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CalendarGridRow = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('events/calendar-events'), _dec4 = Ember._tracked, _dec5 = (0, _emberConcurrency.task)(function* () {
    var rowHours = [];
    var rowDate = this.args.row.date;

    if (this.args.row.date instanceof Ember.Object) {
      rowDate = this.args.row.date.date;
    }

    var rowDateToSet = {
      'year': rowDate.get('year'),
      'month': rowDate.get('month'),
      'date': rowDate.get('date')
    };
    var rowStartTime = (0, _moment.default)(this.CalendarDataService.defaultStartTime).set(rowDateToSet);
    this.args.hours.forEach(currHour => {
      var hourClass = "calendar__drop-cell--".concat(currHour.format('mm'));
      var currHourWithNewDate = (0, _moment.default)(currHour.set(rowDateToSet));

      if (currHour.isSameOrAfter((0, _moment.default)(rowStartTime).set({
        'hour': 23,
        'minute': 30,
        'second': 0
      }))) {
        hourClass = "calendar__drop-cell--".concat(currHour.format('mm'), " calendar__drop-cell--after-end-of-day");
      } else {
        if (currHourWithNewDate.isBefore(rowStartTime)) {
          hourClass = "calendar__drop-cell--".concat(currHour.format('mm'), " calendar__drop-cell--before-start-of-day");
        }
      }

      rowHours.pushObject(Ember.Object.create({
        momentTime: currHourWithNewDate,
        isHovered: false,
        class: hourClass
      }));
    });
    return rowHours;
  }), _dec6 = Ember._action, (_class = (_temp = class CalendarGridRow extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "CalendarDataService", _descriptor, this);

      _initializerDefineProperty(this, "inViewport", _descriptor2, this);

      _initializerDefineProperty(this, "CalendarEventsService", _descriptor3, this);

      _initializerDefineProperty(this, "calendarEventRows", _descriptor4, this);

      _initializerDefineProperty(this, "addCells", _descriptor5, this);
    }

    didEnterViewport() {
      this.args.row.calendarPage.getNextRows();
    }

    get firstHour() {
      return this.args.hours[0];
    }

    get rowHours() {
      return this.addCells.perform();
    }

    get showInactiveUsers() {
      if (this.args.row.id === 'inactive') {
        return this.args.showInactiveUsers;
      }

      return true;
    }

    bindIntersectionObserver(element) {
      if (this.args.rowIndex == this.args.row.calendarPage.maxIndex - 1) {
        var viewportTolerance = {
          bottom: 300
        };
        var intersectionThreshold = [0, 1.0];
        var scrollableArea = this.args.scrollContext;
        var {
          onEnter
        } = this.inViewport.watchElement(element, {
          viewportTolerance,
          intersectionThreshold,
          scrollableArea
        });
        onEnter(this.didEnterViewport.bind(this));
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "CalendarDataService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "inViewport", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "CalendarEventsService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "calendarEventRows", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "addCells", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "bindIntersectionObserver", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "bindIntersectionObserver"), _class.prototype)), _class));
  _exports.default = CalendarGridRow;
});