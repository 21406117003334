define("securitytrax-ember-ui/components/modals/sub-route-modals/manage-adt/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/forms/adt"], function (_exports, _emberConcurrency, _adt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Component.extend(_adt.default, {
    formStarted: false,
    adtOrderFormMode: 'edit',
    adtUpdateFormFirstStep: 'orderInformation',

    init() {
      this._super(...arguments);

      this.set('adtOrderFormMode', this.get('customerSystemInformation.centralStationId') ? 'edit' : 'create');
      this.set('adtUpdateFormFirstStep', this.callbackEditAdtOrderFormStarted ? 'eContracts' : 'orderInformation');
      this.set('formStarted', true);
    },

    watchAdtData: (0, _emberConcurrency.task)(function* () {
      var adtData = yield this.adtData;

      if (adtData && (adtData.hasPreCheck !== true || 'failedLoadAdtData' in adtData && adtData.failedLoadAdtData === true)) {
        this.router.transitionTo('customers.system');
      }
    }).on('init'),
    adtCustomerOrderTitle: Ember.computed('customer.{id,flname}', function () {
      var titleData = {
        customerName: this.get('customer.name'),
        id: this.get('customer.id')
      };
      return this.intl.t('pages.customers.system.order.adt_title', titleData);
    }),
    adtCreateUpdateOrderFormSteps: Ember.computed('formStarted', 'adtCustomerOrderTitle', function () {
      var intl = this.intl;
      return Ember.Object.create({
        orderInformation: Ember.Object.create({
          stepNameKey: 'orderInformation',
          title: this.adtCustomerOrderTitle,
          stepComponentPath: 'multi-step-form/forms/system/adt/steps/edit-create-order-information',
          progressBarTitle: intl.t('pages.customers.system.order.order_information')
        }),
        orderStatus: Ember.Object.create({
          stepNameKey: 'orderStatus',
          title: this.adtCustomerOrderTitle,
          stepComponentPath: 'multi-step-form/forms/system/adt/steps/order-status',
          progressBarTitle: intl.t('pages.customers.system.order.order_status')
        }),
        linkedOrders: Ember.Object.create({
          stepNameKey: 'linkedOrders',
          title: this.adtCustomerOrderTitle,
          stepComponentPath: 'multi-step-form/forms/system/adt/steps/linked-orders',
          progressBarTitle: intl.t('pages.customers.system.order.linked_orders')
        }),
        eContracts: Ember.Object.create({
          stepNameKey: 'eContracts',
          title: this.adtCustomerOrderTitle,
          stepComponentPath: 'multi-step-form/forms/system/adt/steps/econtracts',
          progressBarTitle: intl.t('pages.customers.system.order.econtracts')
        }),
        preCutIn: Ember.Object.create({
          stepNameKey: 'preCutIn',
          title: this.adtCustomerOrderTitle,
          stepComponentPath: 'multi-step-form/forms/system/adt/steps/pre-cut-in',
          progressBarTitle: intl.t('pages.customers.system.order.pre_cut_in')
        }),
        cleanUp: Ember.Object.create({
          stepNameKey: 'cleanUp',
          title: this.adtCustomerOrderTitle,
          stepComponentPath: 'multi-step-form/forms/system/adt/steps/clean-up',
          progressBarTitle: intl.t('pages.customers.system.order.clean_up')
        }),
        rateAdjustment: Ember.Object.create({
          stepNameKey: 'rateAdjustment',
          title: this.adtCustomerOrderTitle,
          stepComponentPath: 'multi-step-form/forms/system/adt/steps/rate-adjustment',
          progressBarTitle: intl.t('pages.customers.system.order.rate_adjustment_modification')
        }),
        dealerHubInfo: Ember.Object.create({
          stepNameKey: 'dealerHubInfo',
          title: this.adtCustomerOrderTitle,
          stepComponentPath: 'multi-step-form/forms/system/adt/steps/dealer-hub-info',
          progressBarTitle: this.intl.t('pages.customers.system.adt.dealer_hub_info.title'),
          granted: this.grants.getIndividualGrant('customers.view_adt_customer_collections_information') || this.grants.getIndividualGrant('customers.view_adt_customer_funding_information')
        }),
        collectionInfo: Ember.Object.create({
          stepNameKey: 'collectionInfo',
          title: this.adtCustomerOrderTitle,
          stepComponentPath: 'multi-step-form/forms/system/adt/steps/collection-info',
          progressBarTitle: this.intl.t('pages.customers.system.adt.collection.title'),
          granted: this.grants.getIndividualGrant('customers.view_adt_customer_collections_information')
        }),
        fundingInfo: Ember.Object.create({
          stepNameKey: 'fundingInfo',
          title: this.adtCustomerOrderTitle,
          stepComponentPath: 'multi-step-form/forms/system/adt/steps/funding-info',
          progressBarTitle: this.intl.t('pages.customers.system.adt.funding.title'),
          granted: this.grants.getIndividualGrant('customers.view_adt_customer_funding_information')
        }),
        dispositionInfo: Ember.Object.create({
          stepNameKey: 'dispositionInfo',
          title: this.adtCustomerOrderTitle,
          stepComponentPath: 'multi-step-form/forms/system/adt/steps/disposition-info',
          progressBarTitle: this.intl.t('pages.customers.system.adt.disposition.title'),
          granted: this.grants.getIndividualGrant('customers.view_adt_customer_collections_information') || this.grants.getIndividualGrant('customers.view_adt_customer_funding_information')
        }),
        dealerAddedFundingBlockers: Ember.Object.create({
          stepNameKey: 'dealerAddedFundingBlockers',
          title: this.adtCustomerOrderTitle,
          stepComponentPath: 'multi-step-form/forms/system/adt/steps/dealer-added-funding-blockers',
          progressBarTitle: this.intl.t('pages.customers.system.order.dealer_added_funding_blockers'),
          granted: !Ember.isEmpty(this.get('customerSystemInformation.adtSerialMan'))
        })
      });
    }),
    adtFormOriginalModels: Ember.computed('adtData.{adtCustomerOrder,adtCustomerOrderStatus,adtCustomerOrderPreCutInModification,adtCustomerOrderCleanupModification,adtCustomerOrderRateAdjustmentModification,hasPreCheck}', 'grants.userGrants', function () {
      return this.adtFormOriginalModelsTask.perform();
    }),
    adtFormOriginalModelsTask: (0, _emberConcurrency.task)(function* () {
      try {
        var hashData = yield (0, _emberConcurrency.hash)({
          grants: this.grants,
          adtData: this.adtData,
          customer: this.customer
        });
        return Ember.Object.create({
          customer: {
            doCopy: hashData.grants.getIndividualGrant('customers.view'),
            doValidate: hashData.grants.getIndividualGrant('customers.view'),
            doSave: hashData.grants.getIndividualGrant('customers.view'),
            model: hashData.customer,
            copyRelationMap: {
              agencyContacts: {
                doCopy: hashData.grants.getIndividualGrant('agency_contacts.view'),
                doValidate: hashData.grants.getIndividualGrant('agency_contacts.modify'),
                doSave: hashData.grants.getIndividualGrant('agency_contacts.modify')
              },
              customerContacts: {
                readOnly: true
              },
              defaultCustomerBilling: {
                doCopy: hashData.grants.getIndividualGrant('customer_billings.view'),
                doValidate: false,
                doSave: hashData.grants.getIndividualGrant('customer_billings.view'),
                relationships: {
                  billCycle: {
                    readOnly: true
                  }
                }
              },
              customerAbortCode: {
                doCopy: hashData.grants.getIndividualGrant('customer_abort_codes.view'),
                doValidate: hashData.grants.getIndividualGrant('customer_abort_codes.modify'),
                doSave: hashData.grants.getIndividualGrant('customer_abort_codes.modify')
              },
              customerSystemInformation: {
                doCopy: hashData.grants.getIndividualGrant('customer_system_information.view'),
                doValidate: hashData.grants.getIndividualGrant('customer_system_information.view'),
                doSave: hashData.grants.getIndividualGrant('customer_system_information.view'),
                relationships: {
                  installCalendarEvent: {
                    readOnly: true
                  }
                }
              },
              customerBillings: {
                readOnly: true,
                doCopy: hashData.grants.getIndividualGrant('customer_billings.view'),
                relationships: {
                  customerBillingUses: {
                    readOnly: true
                  }
                }
              },
              customerOrderInformation: {
                doCopy: hashData.grants.getIndividualGrant('customer_order_information.view'),
                doValidate: hashData.grants.getIndividualGrant('customer_order_information.view'),
                doSave: hashData.grants.getIndividualGrant('customer_order_information.view'),
                relationships: {
                  contractTerm: {},
                  activationFee: {
                    'readOnly': true
                  },
                  monitoringPlan: {
                    'readOnly': true
                  },
                  mmr: {},
                  repUser: {
                    'readOnly': true
                  }
                }
              }
            }
          }
        });
      } catch (error) {
        (false && Ember.warn('failed to get adtFormOriginalModels' + error, {
          id: 'modals.subRouteModals.manageAdt.adtFormOriginalModels'
        }));
      }
    }).drop(),
    actions: {
      transitionBackToParentRoute() {
        this.router.transitionTo(this.parentRoute, this.transitionId);
      }

    }
  });
  var _default = Controller;
  _exports.default = _default;
});