define("securitytrax-ember-ui/components/html/svg-components/simple-bar-chart/component", ["exports", "d3-selection", "d3-scale", "jquery"], function (_exports, _d3Selection, _d3Scale, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * credit score scale
   *
   * @class Component
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    didInsertElement() {
      var authorCounts = this.authors.map(author => author.count);
      var yScale = (0, _d3Scale.scaleLinear)().domain([0, Math.max(...authorCounts)]).range([0, 150]);
      var xScale = (0, _d3Scale.scaleBand)().domain(this.authors.map(author => author.name)).range([0, 300]).paddingInner(0.12);
      var svg = (0, _d3Selection.select)((0, _jquery.default)('svg')[0]);
      svg.selectAll('rect').data(this.authors).enter().append('rect').attr('width', xScale.bandwidth()).attr('height', author => yScale(author.count)).attr('x', author => xScale(author.name)).attr('y', author => 150 - yScale(author.count));
    },

    authors: Ember.computed(function () {
      return [{
        name: 'Mark Twain',
        count: 15
      }, {
        name: 'Virginia Woolf',
        count: 42
      }, {
        name: 'John Steinbeck',
        count: 23
      }, {
        name: 'Ralph Ellison',
        count: 27
      }];
    })
  });

  _exports.default = _default;
});