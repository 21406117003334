define("securitytrax-ember-ui/components/multi-step-form/forms/system/adt/shared/install-data/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    centralStationService: Ember.inject.service(),
    installData: Ember.computed(function () {
      return this.setupFormTask.perform();
    }),
    setupFormTask: (0, _emberConcurrency.task)(function* () {
      var store = this.store;
      var centralStationService = this.centralStationService;
      var adtOrder = yield this.adtOrder;
      var monitoringCompany = yield this.monitoringCompany;
      var hashData = yield (0, _emberConcurrency.hash)({
        servicePackages: store.query('monitoringPlan', {
          filter: 'company_config = true and (monitor_code != null and monitor_code != \'\')',
          limit: 0
        }),
        servicePackageAmounts: store.query('mmr', {
          sort: 'amount'
        }),
        equipmentPanels: centralStationService.getData({
          model: monitoringCompany,
          method: 'adt__getEquipmentPanels',
          //this filters blacklisted panels by default
          responseKey: 'equipment_panels'
        }),
        radioTypes: centralStationService.getData({
          model: monitoringCompany,
          method: 'adt__getRadioTypes',
          responseKey: 'radio_types'
        }),
        installTypes: centralStationService.getData({
          model: monitoringCompany,
          method: 'adt__getInstallTypes',
          responseKey: 'install_types'
        }),
        activationFees: store.findAll('activationFee')
      }); /////////////////////////////////////////////////
      // set the default install type to install

      if (adtOrder && adtOrder.install_type === null || adtOrder.install_type === undefined) {
        Ember.set(adtOrder, 'install_type', 'I'); //hashData.installTypes.findBy('id', 'I');
      }

      return {
        servicePackageOptions: hashData.servicePackages,
        servicePackageAmountOptions: hashData.servicePackageAmounts,
        equipmentPanelOptions: hashData.equipmentPanels,
        radioTypeOptions: hashData.radioTypes,
        installTypeOptions: hashData.installTypes,
        activationFeeOptions: hashData.activationFees
      };
    })
  });

  _exports.default = _default;
});