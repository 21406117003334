define("securitytrax-ember-ui/models/base_models/pending-equipment-line-item", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    quantity: (0, _model.attr)('number', {
      'readOnly': false
    }),
    cost: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    comments: (0, _model.attr)('string', {
      'readOnly': false
    }),
    fulfilledQuantity: (0, _model.attr)('number', {
      'readOnly': false
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    parentPendingEquipmentLineItem: (0, _model.belongsTo)('pending-equipment-line-item', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    equipment: (0, _model.belongsTo)('equipment', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    equipmentKit: (0, _model.belongsTo)('equipment-kit', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    equipmentReturnReason: (0, _model.belongsTo)('equipment-return-reason', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    equipmentPurchaseOrder: (0, _model.belongsTo)('equipment-purchase-order', {
      'readOnly': false,
      'readOnlyUpdate': true
    }),
    equipmentPurchaseRequisition: (0, _model.belongsTo)('equipment-purchase-requisition', {
      'readOnly': false,
      'readOnlyUpdate': true
    }),
    equipmentReturnMerchandiseAuthorization: (0, _model.belongsTo)('equipment-return-merchandise-authorization', {
      'readOnly': false,
      'readOnlyUpdate': true
    }),
    equipmentSpillage: (0, _model.belongsTo)('equipment-spillage', {
      'readOnly': false,
      'readOnlyUpdate': true
    })
  });

  _exports.default = _default;
});