define("securitytrax-ember-ui/customers/messages/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rdtnLYoD",
    "block": "{\"symbols\":[],\"statements\":[[8,\"pages/customer/messages\",[],[[\"@model\",\"@customerOrLead\"],[[34,0],\"customer\"]],null],[2,\"\\n\\n\"],[8,\"customer/user-defined-fields\",[],[[\"@customer\",\"@model\",\"@udfContext\"],[[34,0,[\"customer\"]],[34,0],\"customers_messages\"]],null],[2,\"\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"model\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/customers/messages/template.hbs"
    }
  });

  _exports.default = _default;
});