define("securitytrax-ember-ui/components/multi-step-form/forms/system/telus/contract/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms/system/telus/base"], function (_exports, _emberConcurrency, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var Contract = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.getLoadableListsTask, 'isIdle', true);

    try {
      var hashedData = yield (0, _emberConcurrency.hash)({
        discounts: this.getDiscountsTask.perform(),
        contractValue: this.contractTask.perform()
      });
      this.cycleFeeIds = this.contract.cycle_fees.map(fee => {
        return fee.id;
      });
      this.discountIds = this.contract.discounts.map(discount => {
        return discount.id;
      });
      Ember.setProperties(this, hashedData);
    } catch (err) {
      this.discounts = [];
    }
  }), _dec8 = (0, _emberConcurrency.task)(function* (ignoreCache = false) {
    try {
      var contract = yield this.centralStationService.postData({
        model: this.args.customer,
        rawResponse: false,
        method: 'adt_canada__getContract',
        payload: {
          ignore_cache: ignoreCache
        }
      });
      this.contract = contract.contract;
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), _dec9 = (0, _emberConcurrency.task)(function* () {
    try {
      var discounts = yield this.centralStationService.postData({
        model: this.args.customer,
        method: 'adt_canada__getDiscountsByCustomer',
        rawResponse: false
      });
      return discounts.discounts;
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), _dec10 = (0, _emberConcurrency.task)(function* () {
    yield this.contractTask.perform(true);
  }), _dec11 = (0, _emberConcurrency.task)(function* () {
    try {
      yield this.centralStationService.postData({
        model: this.args.customer,
        rawResponse: false,
        method: 'adt_canada__updateContract',
        payload: {
          'contract': {
            'length': {
              'id': this.contract.length.id || this.contract.length
            },
            'sold_price': this.contract.sold_price,
            'free_months': {
              'id': this.contract.free_months.id || this.contract.free_months
            },
            'free_months_distribution': {
              'id': this.contract.free_months_distribution.id || this.contract.free_months_distribution
            },
            'cycle_period': {
              'id': this.contract.cycle_period.id || this.contract.cycle_period
            },
            'economic_inducement': this.contract.economic_inducement,
            'discounts': this.discountIds.map(discount => {
              return {
                'id': discount
              };
            })
          }
        }
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), _dec12 = (0, _emberConcurrency.task)(function* () {
    yield this.contractTask.perform(true);
  }), _dec13 = (0, _emberConcurrency.task)(function* () {
    try {
      yield this.centralStationService.postData({
        model: this.args.customer,
        rawResponse: false,
        method: 'adt_canada__updateContractCycleFees',
        payload: {
          'contract': {
            'cycle_fees': this.cycleFeeIds.map(fee => {
              return {
                'id': fee
              };
            })
          }
        }
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), _dec14 = Ember._action, (_class = (_temp = class Contract extends _base.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "centralStationService", _descriptor, this);

      _initializerDefineProperty(this, "contract", _descriptor2, this);

      _initializerDefineProperty(this, "discounts", _descriptor3, this);

      _initializerDefineProperty(this, "discountIds", _descriptor4, this);

      _initializerDefineProperty(this, "cycleFees", _descriptor5, this);

      _initializerDefineProperty(this, "cycleFeeIds", _descriptor6, this);

      _initializerDefineProperty(this, "initFormData", _descriptor7, this);

      _initializerDefineProperty(this, "contractTask", _descriptor8, this);

      _initializerDefineProperty(this, "getDiscountsTask", _descriptor9, this);

      _initializerDefineProperty(this, "refreshContractTask", _descriptor10, this);

      _initializerDefineProperty(this, "updateContractTask", _descriptor11, this);

      _initializerDefineProperty(this, "refreshContractServicesTask", _descriptor12, this);

      _initializerDefineProperty(this, "updateContractServicesTask", _descriptor13, this);

      this.initFormData.perform();
    }

    toggleContractBools(contractBool, list) {
      if (list.includes(contractBool.id)) {
        var index = array.indexOf(contractBool.id);

        if (index > -1) {
          array.splice(index, 1);
        }
      } else {
        list.push(contractBool.id);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "contract", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "discounts", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "discountIds", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "cycleFees", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "cycleFeeIds", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "initFormData", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "contractTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "getDiscountsTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "refreshContractTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "updateContractTask", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "refreshContractServicesTask", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "updateContractServicesTask", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleContractBools", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "toggleContractBools"), _class.prototype)), _class));
  _exports.default = Contract;
});