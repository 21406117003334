define("securitytrax-ember-ui/leads/appointments/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({//TODO: at some point leads and customers should share the component code
  });

  _exports.default = _default;
});