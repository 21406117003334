define("securitytrax-ember-ui/helpers/has-reports-grant", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Allows checking a single grant, or multiple, separated by space
   * @param {*} params
   */
  var _default = Ember.Helper.extend({
    grants: Ember.inject.service(),
    grantsChanged: Ember.observer('grants.revision', function () {
      this.recompute();
    }),

    compute(params
    /*, hash*/
    ) {
      var reportObject = params[0];
      var originalGrant = params[1];

      if (!reportObject && !originalGrant) {
        return false;
      }

      if (reportObject == null && originalGrant == 'run_reports.run') {
        return this.grants.getIndividualGrant('custom_sql_reports.run');
      }

      if (reportObject == null) {
        return false;
      }

      return reportObject.reportObjectHasGrant(originalGrant);
    }

  });

  _exports.default = _default;
});