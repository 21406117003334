define("securitytrax-ember-ui/components/calendar-glimmer/calendar-scheduling/schedulers/calendar-slots/component", ["exports", "@glimmer/component", "ember-concurrency", "moment", "securitytrax-ember-ui/utils/obj-helpers", "securitytrax-ember-ui/mixins/forms/calendar-event-reusable", "lodash"], function (_exports, _component, _emberConcurrency, _moment, _objHelpers, _calendarEventReusable, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CalendarSlots = (_dec = Ember.inject.service(), _dec2 = Ember.inject.service(), _dec3 = Ember.inject.service(), _dec4 = Ember.computed('slots'), _dec5 = (0, _emberConcurrency.task)(function* () {
    try {
      var slots = yield this.slots;
      var slotsByDate = {};

      for (var i = 0; i < slots.get('length'); i++) {
        var currSlot = slots.objectAt(i);

        var startDateMoment = _moment.default.tz(_moment.default.unix(currSlot.get('attributes.start_date_time')), 'America/Denver'); //TODO-Timezone  This is a hack to force time into mountain


        var endDateMoment = _moment.default.tz(_moment.default.unix(currSlot.get('attributes.end_date_time')), 'America/Denver'); //TODO-Timezone  This is a hack to force time into mountain


        currSlot.set('attributes.start_date_moment', startDateMoment);
        currSlot.set('attributes.end_date_moment', endDateMoment);
        var startDateDayString = startDateMoment.format('MM/DD/YYYY');

        if (!slotsByDate[startDateDayString]) {
          slotsByDate[startDateDayString] = {
            slots: Ember.A([]),
            date: startDateMoment
          };
        }

        slotsByDate[startDateDayString].slots.pushObject(currSlot);
      }

      return slotsByDate;
    } catch (error) {
      (false && Ember.warn('failed to get grouped slots for calendar' + error, {
        id: 'calendar.calendarScheduling.schedulers.calendarSlots.groupedByDaySlots'
      }));
    }
  }), _dec6 = (0, _emberConcurrency.task)(function* () {
    var selectedSchedulingProfile = yield this.args.selectedSchedulingProfile;

    try {
      if (selectedSchedulingProfile) {
        var profileSlots = yield selectedSchedulingProfile.invokeAction('get_scheduler_availability', {
          performLinked: true,
          verb: 'POST',
          data: {
            data: {
              relationships: {
                customer: {
                  data: {
                    id: yield this.args.customer.get('id'),
                    type: 'customers'
                  }
                }
              },
              attributes: {
                calendar_slots_data: {
                  filter_date: (0, _moment.default)().format('MM/DD/YYYY'),
                  user_list_by: 'location',
                  calendar_category: 'tech_schedule',
                  include_unavailable_slots: false
                }
              }
            }
          }
        });
        return (0, _objHelpers.createEmberObjectRecursive)(profileSlots.responsePayload.data);
      } else {
        throw new Error(this.intl.t('pages.calendar.no_slot_schedulers'));
      }
    } catch (error) {
      if (_lodash.default.some(error.errors, currMessage => {
        return _lodash.default.includes(currMessage.detail, 'Slot Scheduler not enabled');
      })) {
        this.selectedSchedulingProfileId = 2;
      }

      (false && Ember.warn('failed to get slots for calendar' + error, {
        id: 'calendar.calendarScheduling.schedulers.calendarSlots.slotsTask'
      }));
      this.stNotificationManager.addErrorMessages(error, {
        notificationNamespace: 'work-order-edit-appointment'
      });
    }

    return [];
  }), _dec7 = (0, _emberConcurrency.task)(function* () {
    try {
      yield; // leaving this here as this will likely need to be a task in the future

      this.showSlots = true;
    } catch (error) {
      (false && Ember.warn('error searching for slots' + error, {
        id: 'calendar.calendarScheduling.schedulers.calendarSlots.searchSlotsTask'
      }));
    }
  }), _dec8 = (0, _emberConcurrency.task)(function* (slotToAdd) {
    var dataHash = yield (0, _emberConcurrency.hash)({
      selectedSchedulingProfile: this.args.selectedSchedulingProfile,
      calendarEventTypes: this.store.findAll('calendar-event-type')
    });
    var calendarEventReusable = Ember.Object.extend(_calendarEventReusable.default);
    var newCalendarEvent = calendarEventReusable.create({
      calendarCategory: 'tech_schedule',
      duration: slotToAdd.attributes.end_date_time - slotToAdd.attributes.start_date_time,
      startTime: slotToAdd.attributes.start_date_moment,
      status: 'confirmed',
      profile: dataHash.selectedSchedulingProfile
    });
    var filteredCalendarEventTypes = [];
    var calendarEventTypeIncludeCategories = this.calendarEventTypeIncludeCategories;
    dataHash.calendarEventTypes.forEach(calendarEventType => {
      if (calendarEventType.get('status') === 'active') {
        if (calendarEventTypeIncludeCategories[calendarEventType.get('calendarCategory')]) {
          if (calendarEventType.nameAsKey != 'timeoff') {
            filteredCalendarEventTypes.push(calendarEventType);
          }
        }
      }
    }); // set the currCalendarEventType from the workOrderType if the names match
    // DREW TODO - this will need to be updated when / if there is a change made to the form defaults to take that into account

    if (this.args.multiStepForm.formOriginalModels.workOrder && this.args.multiStepForm.formOriginalModels.workOrder.model.id) {
      var workOrderType = yield this.args.multiStepForm.formOriginalModels.workOrder.model.workOrderType;
      var foundType = yield filteredCalendarEventTypes.findBy('name', workOrderType.name);

      if (foundType && foundType.name) {
        newCalendarEvent.calendarEventType = foundType;
      }
    }

    this.args.setUpNewEvent(newCalendarEvent, true);
  }), _dec9 = Ember._action, _dec10 = Ember._action, (_class = (_temp = class CalendarSlots extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _defineProperty(this, "calendarEventTypeIncludeCategories", undefined);

      _initializerDefineProperty(this, "groupedByDaySlotsTask", _descriptor4, this);

      _initializerDefineProperty(this, "slotsTask", _descriptor5, this);

      _initializerDefineProperty(this, "searchSlotsTask", _descriptor6, this);

      _initializerDefineProperty(this, "addNewCalendarEventTask", _descriptor7, this);

      this.calendarEventTypeIncludeCategories = {
        'tech_schedule': this.args.userType === 'tech',
        'sale_schedule': this.args.userType === 'sales_rep'
      };
      var buttonObject = this.args.multiStepForm.currentStepData.buttons ? this.args.multiStepForm.currentStepData.buttons : [];
      var buttons = [];

      if (buttonObject.length > 0) {
        for (var x = 0; x < buttonObject.length; x++) {
          var button = buttonObject[x];

          if (button.id != 'schedule_button') {
            buttons.push(button);
          }
        }
      }

      this.args.multiStepForm.registerStepData(this.args.multiStepForm.currentStepNameKey, 'buttons', buttons);
    }

    get groupedByDaySlots() {
      return this.groupedByDaySlotsTask.perform();
    }

    get slots() {
      return this.slotsTask.perform();
    }

    searchSlots() {
      return this.searchSlotsTask.perform();
    }

    addNewCalendarEvent(slotToAdd) {
      return this.addNewCalendarEventTask.perform(slotToAdd);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "groupedByDaySlots", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "groupedByDaySlots"), _class.prototype), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "groupedByDaySlotsTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "slotsTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "searchSlotsTask", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "addNewCalendarEventTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "searchSlots", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "searchSlots"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addNewCalendarEvent", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "addNewCalendarEvent"), _class.prototype)), _class));
  _exports.default = CalendarSlots;
});