define("securitytrax-ember-ui/components/modals/sub-route-modals/manage-rapid-response/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cXfaWmay",
    "block": "{\"symbols\":[\"multiStepForm\"],\"statements\":[[6,[37,15],null,[[\"class\",\"formSteps\",\"currentStepNameKey\",\"changeCurrentStepNameKey\",\"formOriginalModels\",\"formStarted\",\"changeFormStarted\"],[\"row\",[35,14],[35,13],[30,[36,2],[[32,0],[30,[36,11],[[35,13]],null]],null],[35,12],[35,10],[30,[36,2],[[32,0],[30,[36,11],[[35,10]],null]],null]]],[[\"default\"],[{\"statements\":[[6,[37,9],null,[[\"modalButtons\",\"title\",\"classNames\",\"dialogOpen\",\"hideTopRightCloseButton\",\"hasProgressBar\",\"fullscreen\",\"onClose\",\"multiStepForm\"],[[32,1,[\"currentStepData\",\"buttons\"]],[32,1,[\"currentStepData\",\"title\"]],\"rapid-response-modal st-modal--full\",[32,1,[\"formStarted\"]],[32,1,[\"currentStepData\",\"hideTopRightCloseButton\"]],[30,[36,8],[[35,6],\"create\"],null],true,[30,[36,2],[[32,0],\"transitionBackToParentRoute\"],null],[32,1]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,7],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],[[\"multiStepForm\",\"formMode\",\"customer\",\"monitoringCompany\",\"class\",\"customerSystemInformation\",\"transitionBackToParent\",\"chatFunctionalityEnabled\",\"transitionToManage\"],[[32,1],[35,6],[35,5],[35,4],\"form-step-full-height\",[35,3],[30,[36,2],[[32,0],\"transitionBackToParentRoute\"],null],[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"transitionToManage\",\"chatFunctionalityEnabled\",\"action\",\"customerSystemInformation\",\"monitoringCompany\",\"customer\",\"formMode\",\"component\",\"not-eq\",\"modals/st-paper\",\"formStarted\",\"mut\",\"formOriginalModel\",\"firstStep\",\"formSteps\",\"multi-step-form\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/modals/sub-route-modals/manage-rapid-response/template.hbs"
    }
  });

  _exports.default = _default;
});