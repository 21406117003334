define("securitytrax-ember-ui/components/customer/at-risk-entries-open-closed/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CRTuhtWU",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n    \"],[1,[30,[36,1],null,[[\"cardTitle\",\"isClosed\",\"customer\"],[\"pages.customers.at_risk_entries.open\",false,[35,0]]]]],[2,\"\\n\\n    \"],[1,[30,[36,1],null,[[\"cardTitle\",\"isClosed\",\"customer\"],[\"pages.customers.at_risk_entries.closed\",true,[35,0]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"customer\",\"customer/at-risk-entries\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/customer/at-risk-entries-open-closed/template.hbs"
    }
  });

  _exports.default = _default;
});