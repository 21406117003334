define("securitytrax-ember-ui/demo/dashboard-config/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "umRYxr1I",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,6],null,[[\"includeCard\",\"hideNav\"],[true,true]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,4],null,[[\"class\",\"clickAction\",\"text\"],[\"st-btn st-btn-primary\",[30,[36,2],[[32,0],[30,[36,3],[[30,[36,2],[[32,0],[30,[36,1],[[35,0]],null],true],null]],null]],null],\"show config\"]]]],[2,\"\\n    \"],[1,[30,[36,5],null,[[\"showConfig\"],[[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"showConfig\",\"mut\",\"action\",\"queue\",\"html/st-button\",\"dashboard-config\",\"pages/demo-page\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/demo/dashboard-config/template.hbs"
    }
  });

  _exports.default = _default;
});