define("securitytrax-ember-ui/validations/common-validations", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateObject = {};

      if (this.name !== undefined) {
        validateObject['name'] = {
          presence: this.customMessageOrDefault(data, 'name')
        };
      }

      if (this.email !== undefined) {
        validateObject['email'] = {
          email: this.customMessageOrDefault(data, 'email')
        };
      }

      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      return this.outputFormatting(validationData, 'commonValidations');
    }

  });

  _exports.default = _default;
});