define("securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/smart-home-pay-los/finalization/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/smart-home-pay-los/smart-home-pay-los-base"], function (_exports, _emberConcurrency, _smartHomePayLosBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var SmartHomePayLosFinalization = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = (0, _emberConcurrency.task)(function* () {
    try {
      yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
      yield (0, _emberConcurrency.waitForProperty)(this.initDataTask, 'isIdle', true);
      this.amountApproved = this.getStatusResponse['get_application_status']['data']['attributes']['amount_approved'];
      this.finalAmount = this.getStatusResponse['get_application_status']['data']['attributes']['amount_financed'];
      this.submissionWarning = this.finalAmount ? true : false;
      this.adc_account_id = this.getStatusResponse['get_application_status']['data']['attributes']['adc_install_account_id'];
      this.cs_account_id = this.getStatusResponse['get_application_status']['data']['attributes']['cs_install_account_id'];
      this.submissionDisabled = this.getStatusResponse['get_application_status']['data']['attributes']['application_stage'] !== 'finalization';
      this.buttons();
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
    }
  }), _dec8 = (0, _emberConcurrency.task)(function* () {
    try {
      yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
      var application = yield this.store.findRecord('consumerFinancingApplication', this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.id'));

      if (this.finalAmount === '') {
        throw Error(this.intl.t('pages.customers.accounting.financing.final_amount_blank_error'));
      } else if (this.finalAmount && this.finalAmount <= 0) {
        throw Error(this.intl.t('pages.customers.accounting.financing.final_amount_zero_error'));
      } else if (this.finalAmount && this.finalAmount > Number(this.amountApproved)) {
        throw Error(this.intl.t('pages.customers.accounting.financing.final_amount_exceeds_error'));
      }

      yield application.invokeAction('submit_final_amount', {
        verb: 'POST',
        data: {
          data: {
            amount: this.finalAmount
          }
        }
      });
      this.stNotificationManager.addSuccess(this.intl.t('pages.customers.accounting.financing.final_amount_submitted'), 'modal');
      yield this.initDataTask.perform();

      if (!this.pendingTasks.length) {
        this.args.multiStepForm.currentStepNameKeySetVal('prefunding');
      }

      this.initFormTask.perform();
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), _dec9 = (0, _emberConcurrency.task)(function* () {
    try {
      var application = yield this.store.findRecord('consumerFinancingApplication', this.args.multiStepForm.formCopiedModelsGetVal('consumerFinancingApplication.model.id'));

      if (isNaN(this.adc_account_id)) {
        throw Error(this.intl.t('pages.customers.accounting.financing.adc_account_id_numeric_error'));
      }

      yield application.invokeAction('submit_installation_details', {
        verb: 'POST',
        data: {
          data: {
            adc_account_id: this.adc_account_id,
            cs_account_id: this.cs_account_id
          }
        }
      });
      this.stNotificationManager.addSuccess(this.intl.t('pages.customers.accounting.financing.installation_details_submitted'), 'modal');
      yield this.initDataTask.perform();

      if (!this.pendingTasks.length) {
        this.args.multiStepForm.currentStepNameKeySetVal('prefunding');
      }

      this.initFormTask.perform();
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), (_class = (_temp = class SmartHomePayLosFinalization extends _smartHomePayLosBase.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "finalAmount", _descriptor, this);

      _initializerDefineProperty(this, "amountApproved", _descriptor2, this);

      _initializerDefineProperty(this, "adc_account_id", _descriptor3, this);

      _initializerDefineProperty(this, "cs_account_id", _descriptor4, this);

      _initializerDefineProperty(this, "submissionWarning", _descriptor5, this);

      _initializerDefineProperty(this, "submissionDisabled", _descriptor6, this);

      _initializerDefineProperty(this, "initFormTask", _descriptor7, this);

      _initializerDefineProperty(this, "submitFinalAmountTask", _descriptor8, this);

      _initializerDefineProperty(this, "submitInstallationDetailsTask", _descriptor9, this);

      this.initFormTask.perform();
    }

    get pageLoaded() {
      return this.submitFinalAmountTask.isIdle && this.submitInstallationDetailsTask.isIdle && this.initFormTask.isIdle && this.initDataTask.isIdle;
    }

    get formattedAmountApproved() {
      return this.intl.formatNumber(this.amountApproved, {
        style: 'currency',
        currency: this.intl.t('common.currency'),
        fallback: this.intl.t('common.zero_currency')
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "finalAmount", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "amountApproved", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0.00;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "adc_account_id", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "cs_account_id", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "submissionWarning", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "submissionDisabled", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "initFormTask", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "submitFinalAmountTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "submitInstallationDetailsTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SmartHomePayLosFinalization;
});