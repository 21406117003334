define("securitytrax-ember-ui/components/html/grouping-div/component", ["exports", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['grouping-div'],
    classNameBindings: ['isOpen:is-open:is-closed'],
    isOpen: undefined,
    bulkSelect: false,
    bulkIsChecked: false,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        isOpen: false
      });
    },

    actions: {
      toggleOpen() {
        this.toggleProperty('isOpen');
      }

    }
  });

  _exports.default = _default;
});