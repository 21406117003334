define("securitytrax-ember-ui/components/multi-step-form/forms/demo/demo-paper-wizard/step-3/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W21Kb6NT",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h4\"],[12],[2,\"Copied Models\"],[13],[2,\"\\n\"],[10,\"label\"],[12],[2,\"Customer Firstname:\"],[13],[2,\" \"],[1,[30,[36,1],null,[[\"value\"],[[35,0,[\"formCopiedModels\",\"customer\",\"model\",\"fname\"]]]]]],[10,\"br\"],[12],[13],[2,\"\\n\"],[10,\"label\"],[12],[2,\"Equipment Name:\"],[13],[2,\" \"],[1,[30,[36,1],null,[[\"value\"],[[35,0,[\"formCopiedModels\",\"equipment\",\"model\",\"name\"]]]]]],[2,\"\\n\\n\"],[10,\"br\"],[12],[13],[10,\"br\"],[12],[13],[2,\"\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\\n\"],[10,\"h4\"],[12],[2,\"Original Models\"],[13],[2,\"\\n\"],[10,\"label\"],[12],[2,\"Customer Firstname:\"],[13],[2,\" \"],[1,[35,0,[\"formOriginalModels\",\"customer\",\"model\",\"fname\"]]],[10,\"br\"],[12],[13],[2,\"\\n\"],[10,\"label\"],[12],[2,\"Equipment Name:\"],[13],[2,\" \"],[1,[35,0,[\"formOriginalModels\",\"equipment\",\"model\",\"name\"]]]],\"hasEval\":false,\"upvars\":[\"multiStepForm\",\"input\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/demo/demo-paper-wizard/step-3/template.hbs"
    }
  });

  _exports.default = _default;
});