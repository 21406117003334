define("securitytrax-ember-ui/components/multi-step-form/forms/administration/system/global-settings/edit-company/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EditCompany = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = (0, _emberConcurrency.task)(function* () {
    try {
      yield this.args.multiStepForm.resetForm(false);
      yield Ember.set(this, 'formMode', 'view');
      this.stNotificationManager.addSuccessKey('common.notifications.generic_cancel');
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err);
    }
  }).drop(), _dec9 = (0, _emberConcurrency.task)(function* () {
    var validationObject = {
      deepValidate: false,
      extraValidationData: {
        noValidate: ['globalSetting-emailSalesScheduleAppointments', 'globalSetting-salesScheduleTemplateMessage', 'globalSetting-emailTechScheduleAppointments', 'globalSetting-techScheduleTemplateMessage', 'emailCampaignFromName', 'emailCampaignFromEmail', 'globalSetting-calendarEventSchedulerStartTime', 'globalSetting-calendarEventSchedulerDurationSeconds']
      }
    };

    try {
      yield this.args.multiStepForm.validateSyncSaveAll(validationObject);
      this.stModelWatcher.trigger('reload-global-messages', this.args.multiStepForm.formOriginalModels.company.model.globalMessage);
      Ember.set(this, 'formMode', 'view');
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
    } catch (err) {
      throw new Error(err);
    }
  }).restartable(), (_class = (_temp = class EditCompany extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "usStates", _descriptor3, this);

      _initializerDefineProperty(this, "grants", _descriptor4, this);

      _initializerDefineProperty(this, "sessionData", _descriptor5, this);

      _initializerDefineProperty(this, "stModelWatcher", _descriptor6, this);

      _initializerDefineProperty(this, "formMode", _descriptor7, this);

      _initializerDefineProperty(this, "cancelTask", _descriptor8, this);

      _initializerDefineProperty(this, "saveCompanySettingsTask", _descriptor9, this);
    }

    get passwordChangeOptions() {
      var optionsArray = [Ember.Object.create({
        name: 'Never',
        value: 0
      })];
      var dayName = ' ' + this.intl.t('pages.administration.company_settings.global_settings.company.days');

      for (var i = 15; i <= 180; i += 15) {
        optionsArray.push(Ember.Object.create({
          name: i + dayName,
          value: i
        }));
      }

      return optionsArray;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "usStates", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "stModelWatcher", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "formMode", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'view';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "cancelTask", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "saveCompanySettingsTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = EditCompany;
});