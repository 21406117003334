define("securitytrax-ember-ui/components/multi-step-form/switchable-inputs/day-picker/component", ["exports", "securitytrax-ember-ui/mixins/switchable-input", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _switchableInput, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableInput.default, {
    intl: Ember.inject.service(),
    selectedDays: undefined,
    value: Ember.computed.alias('selectedDays'),

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        selectedDays: []
      });
    },

    days: Ember.computed('disabledDays', function () {
      if (this.disabledDays !== null) {
        var i = 0;

        while (i < this.selectedDays.length) {
          if (this.disabledDays[this.selectedDays[i]]) {
            this.selectedDays.splice(i, 1);
          } else {
            i++;
          }
        }
      }

      return [{
        name: this.intl.t('common.daysOfWeek.sunday_single_letter'),
        value: 'sunday',
        disabled: this.disabledDays && this.disabledDays.sunday
      }, {
        name: this.intl.t('common.daysOfWeek.monday_single_letter'),
        value: 'monday',
        disabled: this.disabledDays && this.disabledDays.monday
      }, {
        name: this.intl.t('common.daysOfWeek.tuesday_single_letter'),
        value: 'tuesday',
        disabled: this.disabledDays && this.disabledDays.tuesday
      }, {
        name: this.intl.t('common.daysOfWeek.wednesday_single_letter'),
        value: 'wednesday',
        disabled: this.disabledDays && this.disabledDays.wednesday
      }, {
        name: this.intl.t('common.daysOfWeek.thursday_single_letter'),
        value: 'thursday',
        disabled: this.disabledDays && this.disabledDays.thursday
      }, {
        name: this.intl.t('common.daysOfWeek.friday_single_letter'),
        value: 'friday',
        disabled: this.disabledDays && this.disabledDays.friday
      }, {
        name: this.intl.t('common.daysOfWeek.saturday_single_letter'),
        value: 'saturday',
        disabled: this.disabledDays && this.disabledDays.saturday
      }];
    }),
    actions: {
      updateSelectedDays(day) {
        var index = this.selectedDays.indexOf(day);

        if (index > -1) {
          this.selectedDays.splice(index, 1);
        } else {
          this.selectedDays.push(day);
        }

        Ember.notifyPropertyChange(this, 'selectedDays');
      }

    }
  });

  _exports.default = _default;
});