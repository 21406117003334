define("securitytrax-ember-ui/components/multi-step-form/forms/system/api/history/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms/system/api/api-base"], function (_exports, _emberConcurrency, _apiBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _apiBase.default.extend({
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    centralStationService: Ember.inject.service(),
    scrollPosition: 0,
    tableWidth: 0,
    fadeHeight: 0,
    maxFadeHeight: 0,

    didRender() {
      this._super(...arguments);

      if (this.element.querySelector('.table-scroll-wrap')) {
        this.set('scrollPosition', this.element.querySelector('.table-scroll-wrap').scrollLeft);
        this.set('scrollWidth', this.element.querySelector('.table-scroll-wrap').scrollWidth);
        this.set('modalWidth', this.element.querySelector('.table-scroll-wrap').clientWidth);
        this.set('fadeHeight', this.element.querySelector('.table-scroll-wrap').clientHeight);
      }
    },

    customerHistory: Ember.computed(function () {
      return this.getCustomerHistory.perform();
    }),
    getCustomerHistory: (0, _emberConcurrency.task)(function* () {
      var history = yield this.centralStationService.getData({
        model: this.customer,
        method: 'api__getAccountHistoryInfo'
      });
      return history.account_history;
    }),
    hidden: Ember.computed('scrollPosition', function () {
      if (this.scrollPosition + this.modalWidth >= this.scrollWidth) {
        return 'hidden';
      } else {
        return '';
      }
    }),
    actions: {
      setScrollPosition() {
        this.set('scrollPosition', this.element.querySelector('.table-scroll-wrap').scrollLeft);
      }

    }
  });

  _exports.default = _default;
});