define("securitytrax-ember-ui/models/equipment-ledger", ["exports", "securitytrax-ember-ui/models/base_models/equipment-ledger"], function (_exports, _equipmentLedger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _equipmentLedger.default.extend({
    totalCost: Ember.computed('quanity', 'cost', function () {
      return +this.quantity * +this.cost;
    })
  });

  _exports.default = _default;
});