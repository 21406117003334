define("securitytrax-ember-ui/models/base_models/processing-gateway", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    enabled: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    gateway: (0, _model.attr)('string', {
      'readOnly': false
    }),
    hasCreditCard: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    hasAch: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    username: (0, _model.attr)('string', {
      'readOnly': false
    }),
    password: (0, _model.attr)('string', {
      'readOnly': false
    }),
    disableDuplicateCheck: (0, _model.attr)('boolean', {
      'readOnly': false
    })
  });

  _exports.default = _default;
});