define("securitytrax-ember-ui/models/customer-equipment-tag", ["exports", "securitytrax-ember-ui/models/base_models/customer-equipment-tag"], function (_exports, _customerEquipmentTag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerEquipmentTag.default.extend({});

  _exports.default = _default;
});