define("securitytrax-ember-ui/utils/ajax", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateUser = validateUser;
  _exports.validateToken = validateToken;
  _exports.getAntiForgeryToken = getAntiForgeryToken;
  _exports.getCookie = getCookie;
  _exports.isProcessingErrorStatus = isProcessingErrorStatus;
  _exports.isAdapterErrorProcessingError = isAdapterErrorProcessingError;
  _exports.isInvalidSessionError = isInvalidSessionError;

  /**
   * Utility helper module for ajax requests.
   * @module
   */
  // import {
  //   NotLoggedIn,
  //   InvalidAntiForgeryToken,
  //   ProcessingError
  // } from '../enums/AjaxResponseHttpCode';
  var LOGIN_PAGE = '/web/default.aspx';
  /**
   * Redirects the browser to login after logging the redirection reason.
   *
   * @param {String} reason The reason the browser is being redirected to login.
   *
   * @private
   */

  function redirectToLogin(reason) {
    // Login reason.
    (false && Ember.warn(reason, {
      id: 'utils.ajax.redirectToLogin'
    })); // Try to get ReturnUrl

    var returnUrl = location.pathname ? '?ReturnUrl=' + encodeURIComponent(location.pathname) : ''; // Redirect to login.
    // TODO: Instead of redirecting we should show a modal dialog for logging back in.

    window.location.assign(LOGIN_PAGE + returnUrl);
  }
  /**
   * Validates that the user is logged in and authorized.
   *
   * @param {number} status The response status.
   *
   * @returns {boolean} True if the user is valid.
   */


  function validateUser(status) {
    // Is the user not logged in OR unauthorized?
    var NotLoggedIn = '';
    var isInvalid = status === NotLoggedIn;

    if (isInvalid) {
      // Redirect to login page.
      redirectToLogin('User is not logged in, redirecting to login');
    } // Return true if user is valid.


    return !isInvalid;
  }
  /**
   * Verifies that the anti-forgery token is valid.
   *
   * @param {number} status The response status.
   *
   * @returns {boolean} True if the token is valid.
   *
   * @todo This is in such a way because it is caught by the NJsonApi serializer.  We should modify it so that it does not return
   *          it with the Internal Server Error, but something else.
   */


  function validateToken(status) {
    // Does the response indicate that the anti-forgery token is invalid?
    var InvalidAntiForgeryToken = '';
    var isInvalid = status === InvalidAntiForgeryToken;

    if (isInvalid) {
      // Redirect to login.
      redirectToLogin('Invalid anti-forgery token');
    } // Return tue if the token is valid.


    return !isInvalid;
  }
  /**
   * Returns the anti-forgery token from the document cookies (or empty string if not found.
   *
   * @returns {String}
   */


  function getAntiForgeryToken() {
    return getCookie('afg');
  }
  /**
   * Returns a single cookie value.
   *
   * @param {String} cookieName The name of the cookie value to retrieve.
   *
   * @returns {String}
   */


  function getCookie(cookieName) {
    return (document.cookie || '').split('; ').reduce((value, cookie) => {
      // Extract name and all values (since value may contain equals signs).
      var [name, ...cookieValue] = cookie.split('='); // Return cookie values if this is the anti-forgery token.

      return name === cookieName ? cookieValue.join('=') : value;
    }, '');
  } //region errors

  /**
   * Checks if this is a processing error (status code is 423)
   *
   * @param {number} status
   * @returns {boolean}
   *
   * @private
   */


  function isProcessingErrorStatus(status) {
    var ProcessingError = '';
    return status === ProcessingError;
  }
  /**
   * Checks if this error is due to invalid session - either the user is not logged in, or the anti forgery token was invalid
   *
   * @param {number} status
   * @returns {boolean}
   *
   * @private
   */


  function isInvalidSessionStatus(status) {
    var NotLoggedIn = '';
    var InvalidAntiForgeryToken = '';
    return status === NotLoggedIn || status === InvalidAntiForgeryToken;
  }
  /**
   * Checks if the adapter error consists of only processing errors
   *
   * @param {DS.AdapterError | Object} [{errors=[]}]
   *
   * @returns {boolean}
   */


  function isAdapterErrorProcessingError({
    errors = []
  } = {}) {
    return errors.length && errors.every(error => isProcessingErrorStatus(parseInt(error.status, 10)));
  }
  /**
   * Checks if the adapter error has errors due to invalid session state
   *
   * @param {DS.AdapterError | Object} [{errors=[]}]
   *
   * @returns {boolean}
   */


  function isInvalidSessionError({
    errors = []
  } = {}) {
    return errors.length && errors.every(error => isInvalidSessionStatus(parseInt(error.status, 10)));
  } //endregion

});