define("securitytrax-ember-ui/components/lead/lead-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IRM//liq",
    "block": "{\"symbols\":[\"multiStepFormEdit\"],\"statements\":[[6,[37,4],null,[[\"class\",\"formSteps\",\"formOriginalModels\",\"usesModal\",\"currentStepNameKey\",\"formStarted\"],[\"row\",[35,3],[35,2],false,\"leadDetails\",true]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],[[\"multiStepForm\",\"formMode\",\"hideProgressBar\",\"tagName\"],[[32,1],[35,0],true,\"\"]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"leadDetailsFormMode\",\"component\",\"leadFormOriginalModels\",\"leadDetailsForm\",\"multi-step-form\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/lead/lead-details/template.hbs"
    }
  });

  _exports.default = _default;
});