define("securitytrax-ember-ui/components/session-inactive-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cCVorLAL",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,7],[[30,[36,6],[[35,2],[35,5,[\"isAuthenticated\"]]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,4],null,[[\"modalButtons\",\"dialogOpen\",\"hideTopRightCloseButton\",\"clickOutsideToClose\",\"classNames\",\"title\"],[[35,3],[35,2],true,false,\"st-modal--small session-inactive-dialog\",[30,[36,0],[\"common.session.inactive-title\"],null]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"grid-x grid-padding-x\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"cell small-12\"],[12],[2,\"\\n                \"],[10,\"p\"],[12],[1,[30,[36,0],[\"common.session.inactive-message\"],null]],[13],[2,\"\\n                \"],[10,\"p\"],[12],[1,[30,[36,0],[\"common.session.inactive-timer-message\"],null]],[2,\" \"],[10,\"strong\"],[12],[1,[34,1]],[13],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"timeRemaining\",\"dialogActive\",\"activityButtons\",\"modals/st-paper\",\"session\",\"and\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/session-inactive-dialog/template.hbs"
    }
  });

  _exports.default = _default;
});