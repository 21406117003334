define("securitytrax-ember-ui/components/dialer-customer-return/caller-lookup/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixed-in-functions/grouped-property-sections"], function (_exports, _emberConcurrency, _groupedPropertySections) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    tagName: '',
    groupedCustomers: Ember.computed(function () {
      return this.groupCustomersTask.perform(this.get('params.phone'));
    }),
    groupCustomersTask: (0, _emberConcurrency.task)(function* (phone) {
      phone = phone.replace(/\D/g, '');
      var customers = yield this.store.query('customer', {
        filter: ["phone = ".concat(phone)],
        include: 'rep_user.name,customer_system_information.install_calendar_event.user.name'
      });
      var groupedCustomers;

      if (customers.get('length') === 1) {
        this.actions.goToCustomer.call(this, customers.objectAt(0).get('id'));
      } else if (customers.get('length') === 0) {
        groupedCustomers = null;
      } else {
        groupedCustomers = Ember.A([Ember.Object.create({
          name: this.intl.t('common.nav.customers'),
          collapsable: false,
          icon: 'icn-user',
          sections: (0, _groupedPropertySections.default)(),
          items: customers.filter(customer => {
            var saleDate = customer.get('saleDate');
            return saleDate;
          })
        }), Ember.Object.create({
          name: this.intl.t('common.nav.leads'),
          collapsable: false,
          icon: 'icn-core',
          sections: (0, _groupedPropertySections.default)(),
          items: customers.filter(customer => {
            var saleDate = customer.get('saleDate');
            return !saleDate;
          })
        })]);
      }

      return groupedCustomers;
    }),
    actions: {
      goToCustomer(customerId) {
        this.router.transitionTo("/customers/".concat(customerId));
      }

    }
  });

  _exports.default = _default;
});