define("securitytrax-ember-ui/components/is-loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2+oHFudI",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,[[35,3]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[\"spinner spinner-\",[34,1],\" \",[34,0]]]],[12],[2,\"\\n        \"],[1,[30,[36,2],null,[[\"spinning\",\"size\",\"class\"],[true,[35,1],[35,0]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"spinnerClass\",\"size\",\"spinner-svg\",\"resolvedValues\",\"_loaded\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/is-loading/template.hbs"
    }
  });

  _exports.default = _default;
});