define("securitytrax-ember-ui/models/base_models/consumer-financing-applicant", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    fname: (0, _model.attr)('string', {
      'readOnly': false
    }),
    lname: (0, _model.attr)('string', {
      'readOnly': false
    }),
    address1: (0, _model.attr)('string', {
      'readOnly': false
    }),
    address2: (0, _model.attr)('string', {
      'readOnly': false
    }),
    city: (0, _model.attr)('string', {
      'readOnly': false
    }),
    state: (0, _model.attr)('string', {
      'readOnly': false
    }),
    zip: (0, _model.attr)('string', {
      'readOnly': false
    }),
    residenceMonthlyPayment: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    homeValue: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    cellPhone: (0, _model.attr)('string', {
      'readOnly': false
    }),
    businessPhone: (0, _model.attr)('string', {
      'readOnly': false
    }),
    email: (0, _model.attr)('string', {
      'readOnly': false
    }),
    ssn: (0, _model.attr)('string', {
      'readOnly': false
    }),
    birthdate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    idExpirationDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    idIssueDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    idNumber: (0, _model.attr)('string', {
      'readOnly': false
    }),
    idNameOn: (0, _model.attr)('string', {
      'readOnly': false
    }),
    idStateOn: (0, _model.attr)('string', {
      'readOnly': false
    }),
    idType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['Unavailable', 'DriversLicense', 'StateId', 'UnitedStatesMilitaryId', 'UnitedStatesPassport', 'PermanentResidencyCard']
    }),
    middleInitial: (0, _model.attr)('string', {
      'readOnly': false
    }),
    grossMonthlyIncome: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    employmentType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['Unemployed', 'Employed', 'Retired', 'SelfEmployed']
    }),
    employer: (0, _model.attr)('string', {
      'readOnly': false
    }),
    employerStartDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    employerState: (0, _model.attr)('string', {
      'readOnly': false
    }),
    occupation: (0, _model.attr)('string', {
      'readOnly': false
    }),
    livingSinceCurrentResidence: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    residenceType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['Rent', 'OwnWithMortgage', 'OwnedFreeAndClear', 'Other']
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    customer: (0, _model.belongsTo)('customer', {
      'readOnly': false,
      'readOnlyUpdate': true
    }),
    applicantConsumerFinancingApplications: (0, _model.hasMany)('consumer-financing-application', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': 'consumerFinancingApplicant'
    }),
    coapplicantConsumerFinancingApplications: (0, _model.hasMany)('consumer-financing-application', {
      'readOnly': false,
      'readOnlyUpdate': false,
      'inverse': 'consumerFinancingCoapplicant'
    }),
    consumerFinancingApplicantsAdditionalIncomes: (0, _model.hasMany)('consumer-financing-applicants-additional-income', {
      'readOnly': true,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});