define("securitytrax-ember-ui/components/lead/dashboard/dashboard-card/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    cardComponent: null,
    editMode: false,
    editClass: Ember.computed('editMode', function () {
      if (this.editMode) {
        return 'dashboard-card__edit-mode';
      } else {
        return '';
      }
    })
  });

  _exports.default = _default;
});