define("securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-appointments/component", ["exports", "@glimmer/component", "ember-concurrency", "moment"], function (_exports, _component, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EditCreateAppointments = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service('events/calendar-events'), _dec7 = Ember.inject.service, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = (0, _emberConcurrency.task)(function* () {
    if (this.args.calendarEventId !== 'create') {
      this.calendarEvent = yield this.store.findRecord('calendarEvent', this.args.calendarEventId);
      this.calendarEventTitle = {
        type: this.calendarEvent.get('calendarEventType.name'),
        date: (0, _moment.default)(this.calendarEvent.get('startTime')).format('MM/DD/YYYY')
      };
      this.customer = this.args.customer ? this.args.customer : yield this.calendarEvent.get('customer');
    } else {
      this.customer = this.args.customer;
    }

    this.formStarted = true;
  }), _dec12 = Ember._action, (_class = (_temp = class EditCreateAppointments extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "grants", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "quickAdd", _descriptor5, this);

      _initializerDefineProperty(this, "CalendarEventsService", _descriptor6, this);

      _initializerDefineProperty(this, "screen", _descriptor7, this);

      _initializerDefineProperty(this, "calendarEvent", _descriptor8, this);

      _initializerDefineProperty(this, "customer", _descriptor9, this);

      _initializerDefineProperty(this, "formStarted", _descriptor10, this);

      _initializerDefineProperty(this, "initData", _descriptor11, this);

      this.initData.perform();
    }

    get userType() {
      if (this.calendarEvent) {
        return this.calendarEvent.calendarCategory === 'sale_schedule' ? 'sales_rep' : 'tech';
      }

      return this.args.userType || 'tech';
    }

    get calendarEventTypeIncludeCategories() {
      return {
        'tech_schedule': this.userType === 'tech',
        'sale_schedule': this.userType === 'sales_rep'
      };
    }

    get calendarEventFormMode() {
      if (this.grants.getIndividualGrant('calendar_events.create') && !this.calendarEvent) {
        return 'create';
      } else if (this.grants.getIndividualGrant('calendar_events.modify') && this.calendarEvent) {
        return 'edit';
      } else {
        return 'view';
      }
    }

    get calendarEventFormSteps() {
      var customerName = this.customer ? this.customer.flname : null;
      var editCalendarEventTitle = this.calendarEventFormMode === 'create' ? this.intl.t('pages.customers.appointments.title_create', {
        customerName: this.customer.flname
      }) : this.intl.t('pages.customers.appointments.title');
      return Ember.Object.create({
        calendarEventSingleStep: Ember.Object.create({
          stepNameKey: 'calendarEventSingleStep',
          stepComponentPath: this.calendarEventFormMode === 'create' ? 'multi-step-form/forms/create-calendar-event' : 'multi-step-form/forms/calendar-event',
          title: editCalendarEventTitle,
          subSteps: Ember.Object.create({
            duplicateCalendarEvent: Ember.Object.create({
              stepNameKey: 'duplicateCalendarEvent',
              title: this.intl.t('pages.customers.appointments.duplicate', {
                type: this.calendarEventTitle ? this.calendarEventTitle.type : '',
                date: this.calendarEventTitle ? this.calendarEventTitle.date : '',
                name: customerName
              }),
              stepComponentPath: 'multi-step-form/forms/calendar-event/duplicate-calendar-event'
            }),
            duplicateCalendarEventWithScheduler: Ember.Object.create({
              stepNameKey: 'duplicateCalendarEventWithScheduler',
              title: this.intl.t('pages.customers.appointments.duplicate', {
                type: this.calendarEventTitle ? this.calendarEventTitle.type : '',
                date: this.calendarEventTitle ? this.calendarEventTitle.date : '',
                name: customerName
              }),
              stepComponentPath: 'multi-step-form/forms/work-orders/add-duplicate-appointment'
            }),
            rescheduleCalendarEvent: Ember.Object.create({
              stepNameKey: 'rescheduleCalendarEvent',
              title: this.intl.t('pages.customers.appointments.reschedule', {
                type: this.calendarEventTitle ? this.calendarEventTitle.type : '',
                date: this.calendarEventTitle ? this.calendarEventTitle.date : '',
                name: customerName
              }),
              stepComponentPath: 'multi-step-form/forms/work-orders/add-duplicate-appointment'
            }),
            editCalendarEvent: Ember.Object.create({
              stepNameKey: 'editCalendarEvent',
              title: editCalendarEventTitle,
              stepComponentPath: 'multi-step-form/forms/calendar-event'
            })
          })
        })
      });
    }

    get calendarEventFormOriginalModels() {
      return Ember.Object.create({
        calendarEvent: {
          model: this.calendarEvent,
          copyRelationMap: {
            calendarEventType: {
              doValidate: false,
              doSave: false
            },
            calendarEventDetailType: {
              doValidate: false,
              doSave: false
            },
            calendarUser: {
              doValidate: false,
              doSave: false
            },
            workOrder: {
              doValidate: false,
              doSave: false
            },
            dryRunReason: {
              doValidate: false,
              doSave: false
            }
          }
        }
      });
    }

    get hideGeneratePOs() {
      var transitionToRoute = this.quickAdd.closeRoute || this.args.parentRoute;
      return !transitionToRoute.includes('work-orders');
    }

    transitionBackToParentRoute() {
      var transitionToRoute = this.quickAdd.closeRoute || this.args.parentRoute;
      this.CalendarEventsService.trigger('refresh-page');

      if (transitionToRoute.includes('work-orders')) {
        this.router.transitionTo(this.args.parentRoute, this.args.workOrderId);
      } else if (transitionToRoute.includes('customer') || transitionToRoute.includes('lead')) {
        this.router.transitionTo(transitionToRoute, this.customer.id);
      } else {
        this.router.transitionTo(transitionToRoute);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "quickAdd", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "CalendarEventsService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "screen", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "calendarEvent", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "customer", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "formStarted", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "initData", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "transitionBackToParentRoute", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "transitionBackToParentRoute"), _class.prototype)), _class));
  _exports.default = EditCreateAppointments;
});