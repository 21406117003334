define("securitytrax-ember-ui/components/reports/report-view/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/utils/sort-compare", "securitytrax-ember-ui/utils/obj-helpers", "moment", "lodash", "securitytrax-ember-ui/helpers/report-link", "securitytrax-ember-ui/mixins/table-column-sort", "jquery"], function (_exports, _emberConcurrency, _sortCompare, _objHelpers, _moment, _lodash, _reportLink2, _tableColumnSort, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableColumnSort.default, {
    intl: Ember.inject.service(),
    stAjax: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    mapWindow: Ember.inject.service(),
    screen: Ember.inject.service(),
    showNotification: true,
    columns: undefined,
    showFilters: true,
    showReport: false,
    filterClass: '',
    filtersLoaded: false,
    reportFilters: undefined,
    confirmDeleteReportModalOpen: false,
    confirmBulkDeleteAllModalOpen: false,
    totalRecordCount: undefined,
    showPasswordModal: false,
    showExportPasswordModal: false,
    reportPassword: null,
    reportTRFixedHeight: undefined,
    occlusionBuffer: undefined,
    allReportLoops: Ember.A(),
    bulkReportEditList: undefined,
    hasSelectedMassEditColumns: undefined,
    reportObject: undefined,
    reportBulkEditFormStarted: undefined,
    bulkEditReportModel: undefined,
    domParser: undefined,
    stSortCurrentSortColumn: undefined,
    stSortAscending: undefined,
    defaultOccludedRowsStart: undefined,
    defaultOccludedRowsEnd: undefined,
    tableMaxHeight: undefined,
    reportSortingMemory: undefined,
    reportObjectMassEditColumnsFiltered: undefined,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        reportLoaded: false,
        reportData: null,
        showFilters: true,
        allReportLoops: Ember.A([]),
        bulkReportEditList: Ember.A([]),
        reportBulkEditFormStarted: false,
        bulkEditReportModel: null,
        hasSelectedMassEditColumns: false,
        domParser: new DOMParser(),
        defaultOccludedRowsStart: 0,
        defaultOccludedRowsEnd: 300,
        occlusionBuffer: 300,
        reportTRFixedHeight: 60,
        // #fixedRowHeight if change this you have to also change the css for the height of TR
        reportSortingMemory: {},
        reportObjectMassEditColumnsFiltered: [] // stSortCurrentSortColumn: 'id', //init sort by id
        // stSortAscending: false, //init sort desc

      });
    },

    reportHasData: Ember.computed('allReportLoops', function () {
      if (!this.allReportLoops || this.allReportLoops.length === 0 || this.allReportLoops.length === 1 && this.allReportLoops[0].sortedRows[0].value === 'No Data') {
        return false;
      }

      return true;
    }),
    reportPasswordButtons: Ember.computed(function () {
      return [{
        text: this.intl.t('common.actions.cancel'),
        click: () => {
          this.set('showPasswordModal', false);
        },
        classNamesString: 'st-btn btn-secondary cancel-button'
      }, {
        type: 'submit',
        text: this.intl.t('pages.reports.run'),
        click: () => {
          if (this.reportPassword === null) {
            this.stNotificationManager.addError(this.intl.t('pages.reports.password_required'), 'modal');
            return false;
          }

          return this.showReportTask.perform(this.model, this.reportFilters, this.reportPassword).then(() => {
            this.set('showPasswordModal', false);
          });
        },
        classNamesString: 'st-btn st-btn-primary run-report-button'
      }];
    }),
    reportExportPasswordButtons: Ember.computed(function () {
      return [{
        text: this.intl.t('common.actions.cancel'),
        click: () => {
          this.set('showExportPasswordModal', false);
        },
        classNamesString: 'st-btn btn-secondary cancel-button'
      }, {
        type: 'submit',
        text: this.intl.t('pages.reports.export'),
        click: () => {
          if (this.reportPassword === null) {
            this.stNotificationManager.addError(this.intl.t('pages.reports.password_required'), 'modal');
            return false;
          }

          return this.exportTask.perform(this.model, this.reportFilters, this.reportPassword).then(() => {
            this.set('showExportPasswordModal', false);
          });
        },
        classNamesString: 'st-btn st-btn-primary export-report-button'
      }];
    }),
    reportBackgroundExportPasswordButtons: Ember.computed(function () {
      return [{
        text: this.intl.t('common.actions.cancel'),
        click: () => {
          this.set('showBackgroundExportPasswordModal', false);
        },
        classNamesString: 'st-btn btn-secondary cancel-button'
      }, {
        type: 'submit',
        text: this.intl.t('common.actions.background'),
        click: () => {
          if (this.reportPassword === null) {
            this.stNotificationManager.addError(this.intl.t('pages.reports.password_required'), 'modal');
            return false;
          }

          return this.exportTask.perform(this.model, this.reportFilters, this.reportPassword, true).then(() => {
            this.set('showBackgroundExportPasswordModal', false);
          });
        },
        classNamesString: 'st-btn st-btn-primary background-export-report-button'
      }];
    }),
    reportFormSteps: Ember.computed('reportFormMode', function () {
      var intl = this.intl;
      return Ember.Object.create({
        editReport: Ember.Object.create({
          stepNameKey: 'editReport',
          title: intl.t("pages.reports.edit_report"),
          stepComponentPath: 'multi-step-form/forms/reports/edit-report'
        })
      });
    }),
    reportFormOriginalModels: Ember.computed('report', function () {
      return Ember.Object.create({
        report: {
          model: this.report,
          copyRelationMap: {
            reportOuterloop: {
              readOnly: true
            },
            reportCategory: {
              readOnly: true
            },
            reportObject: {
              readOnly: true
            }
          }
        }
      });
    }),
    showReportTask: (0, _emberConcurrency.task)(function* (report, reportFilters, metaPassword = null) {
      try {
        this.set('showReport', false);
        var filters = yield this.formFiltersJsonTask.perform(reportFilters);
        var reportRequestObject = {
          verb: 'POST',
          data: {
            data: filters
          }
        };

        if (metaPassword) {
          reportRequestObject.data['meta'] = {
            password: metaPassword
          };
        }

        var reportData = yield report.invokeAction('run_report', reportRequestObject);
        yield this.setupColumnsTask.perform(reportData);
        this.set('showFilters', false);
        this.set('reportData', reportData);
        this.set('showReport', true);
        this.set('reportLoaded', true);
        this.set('bulkReportEditList', Ember.A([]));
        this.set('reportBulkEditFormStarted', false);
      } catch (response) {
        if (metaPassword && response.errors[0]) {
          this.stNotificationManager.addErrorMessages(response, {
            notificationNamespace: 'modal'
          });
        } else {
          this.stNotificationManager.addErrorMessages(response);
        }

        (false && Ember.warn('failed to run report:' + response, {
          id: 'reports.view.runReport'
        }));
        throw response;
      }
    }),
    // setup columns and sortedRows needed by ember-table
    setupColumnsTask: (0, _emberConcurrency.task)(function* (reportData) {
      var _this = this;

      var allReportLoops = Ember.A([]);
      var allData = reportData.responsePayload.data;
      Ember.set(this, 'exportLink', reportData.responsePayload.meta.export_link);
      var reportObject;

      try {
        reportObject = yield this.model.reportObject;
      } catch (error) {// do nothing - just don't want to bubble if no report object (which some don't have)
      }

      var reportObjectMassEditColumns = yield this.model.reportObjectMassEditColumns;
      var reportObjectMassEditColumnsFiltered = [];

      for (var index = 0; index < reportObjectMassEditColumns.length; index++) {
        var element = reportObjectMassEditColumns.objectAt(index); // don't include the bulk_delete_report_mass_edit_column_id in the list of editable items

        if (element.id != this.model.responseMeta.bulk_delete_report_mass_edit_column_id) {
          reportObjectMassEditColumnsFiltered.push(element);
        }
      }

      Ember.set(this, 'reportObjectMassEditColumnsFiltered', reportObjectMassEditColumnsFiltered);
      var hasSelectedMassEditColumns = reportObjectMassEditColumnsFiltered.length > 0;

      for (var i = 0; i < allData.length; i++) {
        if (allData.length !== undefined && allData.objectAt(i).attributes.records.length > 0) {
          yield* function* () {
            var cols = [];
            var indexShift = 0; // don't add bulk edit column if either no columns to mass edit or no permissions

            if ((hasSelectedMassEditColumns || _this.model.responseMeta.bulk_delete_report_mass_edit_column_id) && reportObject && reportObject.reportObjectHasGrant('edit_data_reports.edit_data')) {
              indexShift = 1;
              cols.push({
                // add the bulk edit select all check to columns
                index: 0,
                isBulk: true,
                valuePath: 'columns.0'
              });
            }

            yield allData.objectAt(i).meta.columns.forEach((col, index) => {
              var colNameHasNotes = _lodash.default.includes(col.name.toLowerCase(), 'note');

              cols.push({
                index: index + indexShift,
                name: col.name,
                valuePath: 'columns.' + (index + indexShift),
                summary: col.summaries,
                containsHtml: colNameHasNotes || _lodash.default.includes(col.name.toLowerCase(), 'address'),
                isScrollable: colNameHasNotes
              });
            });
            var rows = Ember.A(allData.objectAt(i).attributes.records);

            var rowsFormattedOccluded = _this.setupRowData(rows, cols, _this.defaultOccludedRowsStart, _this.defaultOccludedRowsEnd);

            var paddingBottom = (rows.length - _this.defaultOccludedRowsEnd) * _this.reportTRFixedHeight;

            if (paddingBottom < 0) {
              paddingBottom = 0;
            }

            var loopTitle = allData.objectAt(i).attributes.name;
            var stSortCurrentSortColumn = void 0;
            var stSortAscending = void 0;

            if (_this.reportSortingMemory[loopTitle]) {
              // means we remember the old sorting from last run
              stSortCurrentSortColumn = _this.reportSortingMemory[loopTitle]['column'];
              stSortAscending = _this.reportSortingMemory[loopTitle]['ascending'];
            }

            var currReportLoop = {
              loopTitle: loopTitle,
              stSortCurrentSortColumn: stSortCurrentSortColumn,
              stSortAscending: stSortAscending,
              columns: cols,
              recordCount: allData.objectAt(i).meta.record_count,
              sortedRows: rows,
              sortedRowsOccluded: rowsFormattedOccluded,
              occludedRowsStart: _this.defaultOccludedRowsStart,
              occludedRowsEnd: _this.defaultOccludedRowsEnd,
              paddingTop: Ember.String.htmlSafe("height: 0px"),
              paddingBottom: Ember.String.htmlSafe("height: ".concat(paddingBottom, "px")),
              renderTimestamp: new Date().getTime()
            };

            if (_this.reportSortingMemory[loopTitle]) {
              // means we remember the old sorting from last run and so have to sort results
              currReportLoop = _this.sortList(stSortCurrentSortColumn, stSortAscending, {
                reportLoopIndex: i
              }, currReportLoop);
            }

            allReportLoops.pushObject(currReportLoop);
          }();
        } else {
          allReportLoops.pushObject({
            loopTitle: allData.objectAt(i).attributes.name || '--',
            columns: [{
              name: '--'
            }],
            sortedRows: [{
              value: this.intl.t('pages.reports.no_data')
            }],
            paddingTop: Ember.String.htmlSafe("height: 0px"),
            paddingBottom: Ember.String.htmlSafe("height: 0px")
          });
        }
      }

      Ember.set(this, 'allReportLoops', allReportLoops);

      var totalRecordCount = _lodash.default.sumBy(allReportLoops, 'recordCount');

      Ember.set(this, 'totalRecordCount', totalRecordCount);
      Ember.set(this, 'hasSelectedMassEditColumns', hasSelectedMassEditColumns);
      Ember.set(this, 'reportObject', reportObject);

      if (allReportLoops.length > 1) {
        Ember.set(this, 'tableMaxHeight', Ember.String.htmlSafe("max-height: 500px;"));
      } else {
        Ember.set(this, 'tableMaxHeight', Ember.String.htmlSafe("max-height: 800px;"));
      }

      var showBulkEdit = (hasSelectedMassEditColumns || this.model.responseMeta.bulk_delete_report_mass_edit_column_id) && reportObject && reportObject.reportObjectHasGrant('edit_data_reports.edit_data');
      Ember.set(this, 'showBulkEdit', showBulkEdit);
    }),

    // build row data cells based on report column object data
    setupRowData(sortedRows, cols, occludedRowsStart, occludedRowsEnd) {
      var _this2 = this;

      var theRows = sortedRows;

      if (occludedRowsStart < 0) {
        occludedRowsStart = 0;
      }

      if (occludedRowsEnd > theRows.length) {
        occludedRowsEnd = theRows.length;
      }

      var occludedRows = Ember.A([]);

      for (var index = occludedRowsStart; index < occludedRowsEnd; index++) {
        var currRow = theRows.objectAt(index);

        if (currRow.isChecked == null) {
          currRow.isChecked = false;
        }

        if (!currRow.columnsString) {
          var allRowsHtmlStr = '';

          var _loop = function (q) {
            var colContainsHtml = cols[q].containsHtml;
            var currColumn = currRow.columns[q];
            var isScrollable = cols[q].isScrollable;
            allRowsHtmlStr += "<td data-test-td-row-index=\"".concat(q, "\">");
            allRowsHtmlStr += "<div class='cell-content'>";

            if (currColumn.type === 'table') {
              allRowsHtmlStr += "<div class='scroll-content'>";

              var tableArray = _lodash.default.get(currColumn, 'table_data', []);

              tableArray.forEach((cr, index) => {
                Object.keys(cr).forEach(key => {
                  var value = cr[key];

                  if (Array.isArray(value)) {
                    allRowsHtmlStr += "\n                                        <br>\n                                        <label title=\"".concat(key, "\" class=\"column-section-label\">\n                                            <span class=\"label\">").concat(_this2.intl.t('pages.reports.' + key), "</span>\n                                        </label>\n                                    ");
                    value.forEach((scr, subIndex) => {
                      Object.keys(scr).forEach(subKey => {
                        var subValue = scr[subKey];
                        allRowsHtmlStr += "\n                                                <div>\n                                                    <label title=\"".concat(subKey, "\">\n                                                        <span class=\"label\">").concat(_this2.intl.t('pages.reports.' + subKey), "</span>\n                                                    </label>\n                                                    <span>").concat(subValue, "</span>\n                                                </div>\n                                            ");

                        if (subIndex < value.length - 1) {
                          allRowsHtmlStr += '<br>';
                        }
                      });
                    });
                  } else {
                    allRowsHtmlStr += "\n                                        <div>\n                                            <label title=".concat(key, ">\n                                                <span class=\"label\">").concat(_this2.intl.t('pages.reports.' + key), "</span>\n                                            </label>\n                                            <span>").concat(value, "</span>\n                                        </div>\n                                    ");
                  }
                });

                if (index < currColumn.table_data.length - 1) {
                  allRowsHtmlStr += '<br>';
                } else {
                  allRowsHtmlStr += '</div>';
                }
              });
            } else if (currColumn.type === 'link') {
              if (currColumn.link_type === 'resource') {
                var route_resource_type = _lodash.default.get(currColumn, 'link_data[0].target_resource_type', false);

                var route_id = _lodash.default.get(currColumn, 'link_data[0].target_id');

                if (route_resource_type && route_id && Ember.getOwner(_this2).lookup("route:".concat(route_resource_type))) {
                  var reportLink = (0, _reportLink2.reportLinkMapping)(route_resource_type);

                  var linkUrl = _this2.router.generate(reportLink, route_id);

                  allRowsHtmlStr += "\n                                    <a href=\"".concat(linkUrl, "\" target=\"").concat(currColumn.target, "\">\n                                ");

                  if (colContainsHtml) {
                    allRowsHtmlStr += currColumn.value;
                  } else {
                    allRowsHtmlStr += _this2.stripHtmlOrEmpty(currColumn.value);
                  }

                  allRowsHtmlStr += "</a>";
                } else {
                  allRowsHtmlStr += _this2.stripHtmlOrEmpty(currColumn.value);
                }
              } else if (currColumn.link_type === 'map') {
                var parsedContentStr = _this2.stripHtmlOrEmpty(currColumn.value);

                var latitude = _lodash.default.get(currColumn, 'link_data.geopoint.latitude');

                var longitude = _lodash.default.get(currColumn, 'link_data.geopoint.longitude');

                var objectId = _lodash.default.get(currColumn, 'link_data.object_id');

                var resourceType = _lodash.default.get(currColumn, 'link_data.resource_type');

                _this2.mapWindow; // Instantiates the service in prod.

                allRowsHtmlStr += "\n                                <label class=\"clickable-address\" data-latitude=\"".concat(latitude, "\" data-longitude=\"").concat(longitude, "\" data-object_id=\"").concat(objectId, "\" data-resource_type=\"").concat(resourceType, "\" data-label=\"").concat(parsedContentStr, "\" onclick=\"window.openWindowJquery(this);\">\n                                    ").concat(parsedContentStr, "\n                                </label>\n                            ");
              } else if (currColumn.link_type === 'mailto') {
                var parsedString = _this2.stripHtmlOrEmpty(currColumn.value);

                allRowsHtmlStr += "\n                                <a href=\"mailto:".concat(currColumn.link_data, "\"> ").concat(parsedString, " </a>\n                            ");
              } else if (currColumn.link_type === 'relation') {
                var relation = _lodash.default.get(currColumn, 'link_data[0].relation', false);

                var base_resource_type = _lodash.default.get(currColumn, 'link_data[0].base_resource_type', false);

                var base_id = _lodash.default.get(currColumn, 'link_data[0].base_id');

                var _reportLink = (0, _reportLink2.reportLinkMapping)(relation, base_resource_type);

                var _linkUrl = _this2.router.generate(_reportLink, base_id);

                allRowsHtmlStr += "\n                                <a href=\"".concat(_linkUrl, "\" target=\"").concat(currColumn.target, "\">\n                            ");

                if (colContainsHtml) {
                  allRowsHtmlStr += currColumn.value;
                } else {
                  allRowsHtmlStr += _this2.stripHtmlOrEmpty(currColumn.value);
                }

                allRowsHtmlStr += "</a>";
              } else if (currColumn.link_type === 'adt_precheck') {
                allRowsHtmlStr += _this2.stripHtmlOrEmpty(currColumn.value);
              }
            } else {
              if (isScrollable) {
                allRowsHtmlStr += "<div class='scroll-content'>";
              }

              if (currColumn.value === 'true') {
                allRowsHtmlStr += "\n                                <svg xmlns=\"http://www.w3.org/2000/svg\" role=\"img\" class=\"icon svg-icon-icn-check-green ember-view\"><use href=\"/assets/icons.svg#icn-check-green\" xlink:href=\"/assets/icons.svg#icn-check-green\" class=\"icon-icn-check-green\"></use></svg>\n                            ";
              } else if (currColumn.value === 'false') {
                allRowsHtmlStr += "\n                                <svg xmlns=\"http://www.w3.org/2000/svg\" role=\"img\" class=\"icon svg-icon-icn-close-red ember-view\"><use href=\"/assets/icons.svg#icn-close-red\" xlink:href=\"/assets/icons.svg#icn-close-red\" class=\"icon-icn-close-red\"></use></svg>\n                            ";
              } else if (colContainsHtml) {
                allRowsHtmlStr += currColumn.value;
              } else if (currColumn.value != null) {
                allRowsHtmlStr += _this2.stripHtmlOrEmpty(currColumn.value);
              }

              if (isScrollable) {
                allRowsHtmlStr += "</div>";
              }
            }

            allRowsHtmlStr += "</div>";
            allRowsHtmlStr += '</td>';
          };

          for (var q = 0; q < currRow.columns.length; q++) {
            _loop(q);
          }

          currRow.columnsString = allRowsHtmlStr;
        }

        occludedRows.push(currRow);
      }

      return occludedRows;
    },

    formFiltersJsonTask: (0, _emberConcurrency.task)(function* (filters) {
      var newArray = [];
      yield filters.forEach(filter => {
        var copiedObject = {
          id: filter.id,
          type: 'report_filters',
          attributes: {
            main_input: filter.attributes.main_input,
            second_input: filter.attributes.second_input,
            third_input: filter.attributes.third_input
          }
        };
        var attrs = copiedObject.attributes;
        copiedObject['attributes']['main_input'] = this.serializeInput(filter.attributes.main_input_data_type, attrs.main_input);
        copiedObject['attributes']['second_input'] = this.serializeInput(filter.attributes.second_input_data_type, attrs.second_input);
        copiedObject['attributes']['third_input'] = this.serializeInput(filter.attributes.third_input_data_type, attrs.third_input);

        if (attrs.main_input !== null || attrs.second_input !== null || attrs.third_input !== null) {
          newArray.push(copiedObject);
        }
      });
      return newArray;
    }),

    // run report expects filters in specific format
    serializeInput(dataType, currValue) {
      var newValue = currValue;

      if (newValue) {
        switch (dataType) {
          case 'multiselect':
            newValue = newValue.map(i => i.value);
            break;

          case 'date':
            newValue = (0, _moment.default)(newValue).format('MM/DD/YYYY');
            break;

          default:
            break;
        }
      }

      return newValue;
    },

    stripHtmlOrEmpty(contentString) {
      var parsedString = this.domParser.parseFromString(contentString, 'text/html');
      return parsedString.body.textContent !== 'null' && parsedString.body.textContent ? parsedString.body.textContent : '';
    },

    exportTask: (0, _emberConcurrency.task)(function* (report, reportFilters, metaPassword = null, background = false) {
      try {
        var filters = yield this.formFiltersJsonTask.perform(reportFilters);
        var reportRequestObject = {
          verb: 'POST',
          data: {
            data: filters
          }
        };

        if (metaPassword) {
          reportRequestObject.data['meta'] = {
            password: metaPassword
          };
        }

        if (background) {
          reportRequestObject.data['meta'] = _lodash.default.merge({
            background: true
          }, reportRequestObject.data['meta']);
        }

        var reportData = yield report.invokeAction('run_report', reportRequestObject);
        this.set('exportLink', reportData.responsePayload.meta.export_link);

        if (!background) {
          try {
            var downloadUrl = this.exportLink;
            var result = yield this.stAjax.request(downloadUrl);
            window.location.assign(result.meta.download_uri);
          } catch (error) {
            (false && Ember.warn('failed to export report' + error, {
              id: 'component.reports.report-view'
            }));
            this.stNotificationManager.addError(this.intl.t('common.notifications.generic_failure', {
              'action': 'export'
            }));
          }
        }
      } catch (response) {
        if (metaPassword && response.errors[0]) {
          this.stNotificationManager.addErrorMessages(response, {
            notificationNamespace: 'modal'
          });
        } else {
          this.stNotificationManager.addErrorMessages(response);
        }

        (false && Ember.warn('failed to run report:' + response, {
          id: 'reports.view.runReport'
        }));
        throw response;
      }
    }),
    refreshReport: (0, _emberConcurrency.task)(function* () {
      try {
        Ember.set(this, 'filtersLoaded', false);
        Ember.set(this, 'bulkReportEditList', Ember.A([]));
        Ember.set(this, 'reportBulkEditFormStarted', false);
        var report = this.model;
        Ember.set(this, 'showReport', false);
        var reportFilters = yield report.invokeAction('get_filters', {
          verb: 'GET'
        });
        Ember.set(this, 'reportFilters', reportFilters.responsePayload.data);
        yield report.reportObjectMassEditColumns.reload();
        Ember.set(this, 'filtersLoaded', true);
      } catch (response) {
        this.stNotificationManager.addErrorMessages(response);
        throw response;
      }
    }),
    deleteReport: (0, _emberConcurrency.task)(function* () {
      try {
        var report = this.model;
        var reportCategory = yield report.get('reportCategory');
        yield report.destroyRecordWithRollbackTask.perform();
        this.router.transitionTo(reportCategory.get('route'));
        this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
      } catch (err) {
        (false && Ember.warn('failed to delete report:' + err, {
          id: 'reports.deleteReport'
        }));
        throw err;
      }
    }),
    reportsBulkEditFormSteps: Ember.computed('bulkReportEditList.[]', function () {
      return Ember.Object.create({
        bulkEdit: Ember.Object.create({
          stepNameKey: 'bulkEdit',
          title: this.intl.t('common.forms.bulkEditTitle', {
            count: this.bulkReportEditList.length
          }),
          stepComponentPath: 'multi-step-form/forms/reports/bulk-edit'
        })
      });
    }),
    reportsBulkEditFormOriginalModels: Ember.computed('bulkEditReportModel', function () {
      return Ember.Object.create({
        report: {
          model: this.bulkEditReportModel,
          copyRelationMap: {
            reportOuterloop: {
              readOnly: true
            },
            reportCategory: {
              readOnly: true
            },
            reportObject: {
              readOnly: true
            }
          }
        }
      });
    }),

    recalculateScrollableData(currReportLoop, scrollableAreaElement, headerHeight, scrollableHeight) {
      var currentDataScrollStart = headerHeight + currReportLoop.occludedRowsStart * this.reportTRFixedHeight;
      var currentDataScrollEnd = currReportLoop.occludedRowsEnd * this.reportTRFixedHeight + this.reportTRFixedHeight + headerHeight;
      var currentScrollPositionStart = scrollableAreaElement.scrollTop();
      var currentScrollPositionEnd = currentScrollPositionStart + scrollableHeight;
      var distanceFromTopOfData = currentScrollPositionStart - this.occlusionBuffer - currentDataScrollStart;
      var distanceFromBottomOfData = currentDataScrollEnd - this.occlusionBuffer - currentScrollPositionEnd;
      var newOccludedRowsEnd;
      var newOccludedRowsStart;
      var currentEndIndex = Math.round((currentScrollPositionEnd - headerHeight) / this.reportTRFixedHeight);

      if (distanceFromTopOfData <= 0) {
        newOccludedRowsEnd = currentEndIndex - this.defaultOccludedRowsEnd / 2;

        if (newOccludedRowsEnd < this.defaultOccludedRowsEnd) {
          newOccludedRowsEnd = this.defaultOccludedRowsEnd;
        }
      } else if (distanceFromBottomOfData <= 0) {
        newOccludedRowsEnd = currentEndIndex + this.defaultOccludedRowsEnd / 2;

        if (newOccludedRowsEnd > currReportLoop.sortedRows.length) {
          newOccludedRowsEnd = currReportLoop.sortedRows.length;
        }
      } else {
        return; // means we are in the middle of data so no need to redraw
      }

      newOccludedRowsStart = newOccludedRowsEnd - this.defaultOccludedRowsEnd;

      if (newOccludedRowsStart < 0) {
        newOccludedRowsStart = 0;
      }

      if (newOccludedRowsStart != 0) {
        newOccludedRowsStart += 1;
      }

      if (newOccludedRowsEnd === currReportLoop.occludedRowsEnd && newOccludedRowsStart === currReportLoop.occludedRowsStart) {
        return;
      }

      var rowsFormattedOccluded = this.setupRowData(currReportLoop.sortedRows, currReportLoop.columns, newOccludedRowsStart, newOccludedRowsEnd);
      Ember.set(currReportLoop, 'paddingTop', Ember.String.htmlSafe("height: ".concat(newOccludedRowsStart * this.reportTRFixedHeight, "px;")));
      Ember.set(currReportLoop, 'paddingBottom', Ember.String.htmlSafe("height: ".concat((currReportLoop.sortedRows.length - newOccludedRowsEnd) * this.reportTRFixedHeight, "px")));
      Ember.set(currReportLoop, 'occludedRowsEnd', newOccludedRowsEnd);
      Ember.set(currReportLoop, 'occludedRowsStart', newOccludedRowsStart);
      Ember.set(currReportLoop, 'sortedRowsOccluded', rowsFormattedOccluded);
    },

    sortList(column, ascending, passthroughData, passedInCurrReportLoop) {
      var currReportLoop;

      if (passedInCurrReportLoop) {
        currReportLoop = passedInCurrReportLoop;
      } else {
        currReportLoop = this.allReportLoops[passthroughData.reportLoopIndex];
      }

      this.reportSortingMemory[currReportLoop.loopTitle] = {
        column: column,
        ascending: ascending
      };
      var oldAscending = currReportLoop.stSortAscending;
      this.sortListByIndex(currReportLoop, column, ascending);
      var sortedRowsColumn = column;

      if (this.showBulkEdit) {
        // decrement column if has bulk edit in tables
        sortedRowsColumn = column - 1;
      }

      var sortByNumbers = isNaN(currReportLoop.sortedRows[0].columns[sortedRowsColumn].value) === false;
      var sortedReportLoop = Ember.A(_lodash.default.sortBy(currReportLoop.sortedRows, function (item) {
        if (sortByNumbers) {
          return Number(item.columns[sortedRowsColumn].value);
        } else {
          return item.columns[sortedRowsColumn].value;
        }
      }));

      if (!oldAscending) {
        sortedReportLoop = Ember.A(_lodash.default.reverse(sortedReportLoop));
      }

      var rowsFormattedOccluded = this.setupRowData(sortedReportLoop, currReportLoop.columns, this.defaultOccludedRowsStart, this.defaultOccludedRowsEnd);
      var paddingBottom = (sortedReportLoop.length - this.defaultOccludedRowsEnd) * this.reportTRFixedHeight;

      if (paddingBottom < 0) {
        paddingBottom = 0;
      }

      var scrollableAreaElement = (0, _jquery.default)(".st-ember-table.report-table-".concat(passthroughData.reportLoopIndex));
      scrollableAreaElement.scrollTop(0);
      Ember.set(currReportLoop, 'paddingTop', Ember.String.htmlSafe("height: 0px"));
      Ember.set(currReportLoop, 'paddingBottom', Ember.String.htmlSafe("height: ".concat(paddingBottom, "px")));
      Ember.set(currReportLoop, 'sortedRows', sortedReportLoop);
      Ember.set(currReportLoop, 'sortedRowsOccluded', rowsFormattedOccluded);
      Ember.set(currReportLoop, 'occludedRowsStart', this.defaultOccludedRowsStart);
      Ember.set(currReportLoop, 'occludedRowsEnd', this.defaultOccludedRowsEnd);
      return currReportLoop;
    },

    reportBulkDeleteAll: (0, _emberConcurrency.task)(function* (buttonPromise) {
      var editIds = _lodash.default.map(this.bulkReportEditList, 'edit_id');

      var currFieldData = {
        edit_ids: editIds,
        report_object_mass_edit_method_id: this.model.responseMeta.bulk_delete_report_mass_edit_column_id,
        primary_option: true
      };
      var bulkEditRequestsResult;

      try {
        bulkEditRequestsResult = yield this.model.invokeAction('bulk_edit', {
          verb: 'POST',
          data: {
            data: currFieldData
          },
          processResponse: false
        });
      } catch (error) {
        (false && Ember.warn('failed to do reports bulk edit delete all' + error, {
          id: 'reports.bulk-edit-delete-all'
        }));
        this.stNotificationManager.addErrorMessages(error, {
          notificationNamespace: 'modal'
        });
        throw error;
      }

      var updatedCount = _lodash.default.get(bulkEditRequestsResult, 'responsePayload.data.updated', 0);

      var problemsCount = _lodash.default.get(bulkEditRequestsResult, 'responsePayload.data.problem_ids.length', 0);

      buttonPromise.then(() => {
        if (updatedCount > 0) {
          this.stNotificationManager.addSuccess(this.intl.t('common.notifications.bulk_delete_all_successful', {
            'num': updatedCount
          }));
        }

        if (problemsCount > 0) {
          this.stNotificationManager.addError(this.intl.t('common.notifications.bulk_delete_all_failure', {
            'num': problemsCount
          }));
        }

        this.showReportTask.perform(this.model, this.reportFilters);
      });
    }),
    actions: {
      setupInfinityScroll(reportLoopIndex) {
        var currReportLoop = this.allReportLoops[reportLoopIndex];
        var scrollableAreaElement = (0, _jquery.default)(".st-ember-table.report-table-".concat(reportLoopIndex));
        var scrollableHeight = scrollableAreaElement.height();
        var headerHeight = (0, _jquery.default)('thead', scrollableAreaElement).height();
        scrollableAreaElement.on('scroll', event => {
          // debounce
          if (!currReportLoop.lastScrollEventTimestamp || event.timeStamp - currReportLoop.lastScrollEventTimestamp > 100) {
            currReportLoop.lastScrollEventTimestamp = event.timeStamp;
            this.recalculateScrollableData(currReportLoop, scrollableAreaElement, headerHeight, scrollableHeight);
          }
        });
      },

      startReportsBulkEdit() {
        Ember.set(this, 'bulkEditReportModel', this.model);
        Ember.set(this, 'reportBulkEditFormStarted', true);
      },

      bulkEditToggle(reportRow, reportLoop, event) {
        if (event) {
          event.stopPropagation();
        }

        if (!reportLoop.bulkReportEditList) {
          reportLoop.bulkReportEditList = Ember.A([]);
        }

        Ember.set(reportRow, 'isChecked', !reportRow.isChecked);
        var globalBulkEditList = this.bulkReportEditList;

        if (reportRow.isChecked) {
          globalBulkEditList.pushObject(reportRow);
          reportLoop.bulkReportEditList.pushObject(reportRow);
        } else {
          globalBulkEditList.removeObject(reportRow);
          reportLoop.bulkReportEditList.removeObject(reportRow);
        }

        Ember.set(this, 'bulkReportEditList', globalBulkEditList);
        Ember.set(reportLoop, 'bulkReportEditList', reportLoop.bulkReportEditList);
        Ember.set(reportLoop, 'bulkIndeterminate', reportLoop.sortedRows.length > reportLoop.bulkReportEditList.length && reportLoop.bulkReportEditList.length > 0);
        Ember.set(reportLoop, 'isBulkCheckAll', reportLoop.sortedRows.length === reportLoop.bulkReportEditList.length && reportLoop.bulkReportEditList.length > 0);
      },

      bulkEditToggleCheckAll(reportLoop, reportLoopIndex) {
        if (!reportLoop.bulkReportEditList) {
          reportLoop.bulkReportEditList = Ember.A([]);
        }

        Ember.set(reportLoop, 'isBulkCheckAll', !reportLoop.isBulkCheckAll);

        if (reportLoop.isBulkCheckAll) {
          var allRows = [];

          for (var index = 0; index < this.allReportLoops[reportLoopIndex].sortedRows.length; index++) {
            var element = this.allReportLoops[reportLoopIndex].sortedRows[index];
            allRows.pushObject(element);
            Ember.set(element, 'isChecked', true);
          }

          Ember.set(this, 'bulkReportEditList', _lodash.default.union(this.bulkReportEditList, allRows));
          Ember.set(reportLoop, 'bulkReportEditList', allRows);
        } else {
          var allRowsToRemove = [];

          for (var _index = 0; _index < this.allReportLoops[reportLoopIndex].sortedRows.length; _index++) {
            var _element = this.allReportLoops[reportLoopIndex].sortedRows[_index];
            allRowsToRemove.pushObject(_element);
            Ember.set(_element, 'isChecked', false);
          }

          Ember.set(this, 'bulkReportEditList', _lodash.default.difference(this.bulkReportEditList, allRowsToRemove));
          Ember.set(reportLoop, 'bulkReportEditList', Ember.A([]));
        }

        Ember.set(reportLoop, 'bulkIndeterminate', false);
      },

      sortList(column, ascending, passthroughData, passedInCurrReportLoop) {
        return this.sortList(column, ascending, passthroughData, passedInCurrReportLoop);
      },

      rerunReport() {
        return this.showReportTask.perform(this.model, this.reportFilters);
      },

      refreshReport(options) {
        if (!options.isCreate) {
          this.refreshReport.perform();
        }
      },

      compareFunction(a, b) {
        return (0, _sortCompare.sortCompareFunction)(a, b);
      },

      clickRunReportButton() {
        document.querySelector('.run-report-button .main-button').click();
      },

      clickExportButton() {
        document.querySelector('.export-report-button .main-button').click();
      },

      clickBackgroundExportButton() {
        document.querySelector('.background-export-report-button .main-button').click();
      },

      openMapWindow(cell) {
        this.mapWindow.openWindow({
          latitude: Number(cell.link_data.geopoint.latitude),
          longitude: Number(cell.link_data.geopoint.longitude),
          label: cell.value
        });
      },

      submitAdtPreCheck(customer) {
        return customer.executeAdtPreCheck('createCustomerModal');
      }

    }
  });

  _exports.default = _default;
});