define("securitytrax-ember-ui/components/multi-step-form/forms/system/adt/shared/discrepancies/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/system/adt/adt-form-base", "ember-concurrency"], function (_exports, _adtFormBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adtFormBase.default.extend({
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    discrepancyFormStarted: false,
    showUnlinkConfirmModal: false,
    hasDiscrepancies: Ember.computed('discrepancies', function () {
      var discrepancies = this.discrepancies;

      if (discrepancies && discrepancies.length > 0) {
        return true;
      }

      return false;
    }),
    discrepancyFormSteps: Ember.computed(function () {
      var intl = this.intl;
      return Ember.Object.create({
        manageDiscrepancies: Ember.Object.create({
          stepNameKey: 'manageDiscrepancies',
          title: intl.t('pages.customers.system.adt.discrepancy.label'),
          stepComponentPath: 'multi-step-form/forms/system/adt/shared/discrepancies/manage-discrepancies'
        })
      });
    }),
    unlinkAdt: (0, _emberConcurrency.task)(function* () {
      try {
        var hashData = yield (0, _emberConcurrency.hash)({
          customer: this.customer,
          customerSystemInformation: this.get('customer.customerSystemInformation')
        });

        try {
          hashData['adtPreCheck'] = yield this.get('customer.adtPreCheck');
        } catch (error) {
          (false && Ember.warn(error, {
            id: 'multiStepForm.forms.system.adt.discrepancies.adtPreCheck'
          }));
        }

        try {
          yield hashData.customerSystemInformation.invokeAction('unlink_cs_account', {
            verb: 'POST',
            data: {
              data: {
                type: 'customer_system_information_action'
              }
            }
          }); /////////////////////////////////////////////////////////////////////////////////////////
          // need to reload data affected by unlink as well and remove precheck from store

          var adtData = yield this.adtData;
          yield (0, _emberConcurrency.all)([hashData.customer.reload(), hashData.customerSystemInformation.reload()]);
          hashData.customer.notifyPropertyChange('adtPreCheck');
          this.set('adtData', adtData);
        } catch (response) {
          this.stNotificationManager.addErrorMessages(response, {
            notificationNamespace: 'adtMegaFormModal'
          });
        }

        this.set('showUnlinkConfirmModal', false);
        this.multiStepForm.formStartedSetVal(false);

        if (this.transitionBackToParent && typeof this.transitionBackToParent === 'function') {
          this.transitionBackToParent();
        }

        this.stNotificationManager.addSuccess(this.intl.t('common.notifications.unlink_successful'));
      } catch (error) {
        (false && Ember.warn(error, {
          id: 'multiStepForm.forms.system.adt.discrepancies.unlinkAdt'
        }));
      }
    }),
    actions: {
      confirmUnlinkAccount() {
        this.set('showUnlinkConfirmModal', true);
      },

      unlinkConfirmed() {
        return this.unlinkAdt.perform();
      }

    }
  });

  _exports.default = _default;
});