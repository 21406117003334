define("securitytrax-ember-ui/mixins/notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    filterNotificationsBySubCategory(notifications, subCategory) {
      return notifications.filter(notification => {
        return notification.get('subCategory') === subCategory;
      });
    },

    notificationFormSteps: Ember.computed(function () {
      return Ember.Object.create({
        settings: Ember.Object.create({
          stepNameKey: 'notifications',
          title: 'tech notifications',
          stepComponentPath: 'multi-step-form/forms/administration/system/global-settings/edit-notifications'
        })
      });
    }),
    techNotifications: Ember.computed('groupedNotifications', function () {
      var techGrouped = {};
      var notifications = this.get('groupedNotifications.tech.items');
      notifications.uniqBy('subCategory').map(notification => {
        var name = notification.get('subCategory');
        techGrouped[name] = notifications.filterBy('subCategory', notification.get('subCategory'));
      });
      return techGrouped;
    }),
    techNotificationsLength: Ember.computed('techNotifications', function () {
      return Object.keys(this.techNotifications).length;
    }),
    salesNotifications: Ember.computed('groupedNotifications', function () {
      var salesGrouped = {};
      var notifications = this.get('groupedNotifications.sales.items');
      notifications.uniqBy('subCategory').map(notification => {
        var name = notification.get('subCategory');
        salesGrouped[name] = notifications.filterBy('subCategory', notification.get('subCategory'));
      });
      return salesGrouped;
    }),
    salesNotificationsLength: Ember.computed('salesNotifications', function () {
      return Object.keys(this.salesNotifications).length;
    }),
    userNotifications: Ember.computed('groupedNotifications', function () {
      var userGrouped = {};
      var notifications = this.get('groupedNotifications.user.items');
      notifications.uniqBy('subCategory').map(notification => {
        var name = notification.get('subCategory');
        userGrouped[name] = notifications.filterBy('subCategory', notification.get('subCategory'));
      });
      return userGrouped;
    }),
    userNotificationsLength: Ember.computed('userNotifications', function () {
      return Object.keys(this.userNotifications).length;
    }),
    inventoryNotifications: Ember.computed('groupedNotifications', function () {
      var userGrouped = {};
      var notifications = this.get('groupedNotifications.inventory.items');
      notifications.uniqBy('subCategory').map(notification => {
        var name = notification.get('subCategory');
        userGrouped[name] = notifications.filterBy('subCategory', notification.get('subCategory'));
      });
      return userGrouped;
    }),
    inventoryNotificationsLength: Ember.computed('inventoryNotifications', function () {
      return Object.keys(this.inventoryNotifications).length;
    })
  });

  _exports.default = _default;
});