define("securitytrax-ember-ui/components/modals/cms-link-account/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/cs-link-account-mixin"], function (_exports, _emberConcurrency, _csLinkAccountMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_csLinkAccountMixin.default, {
    centralStationService: Ember.inject.service(),
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    modalClasses: Ember.computed('accountFailText', function () {
      if (this.accountFailText) {
        return 'st-modal--small';
      } else {
        return 'st-modal--full';
      }
    }),
    linkModalButton: Ember.computed('accountFailText', function () {
      var buttons = [];

      if (!this.accountFailText) {
        buttons.push({
          text: this.intl.t('common.actions.link'),
          classNamesString: 'st-btn st-btn-primary',
          click: buttonPromise => {
            return this.confirmLinkAccount.perform('cms', this.monitoringCompanyType, buttonPromise);
          }
        });
      }

      return buttons;
    }),
    getBasicCustomerInformation: (0, _emberConcurrency.task)(function* () {
      try {
        var basicInfo = yield this.centralStationService.getData({
          model: this.customer,
          method: "cms__getUnlinkedCustomerSiteDetail"
        });
        return basicInfo.unlinked_customer_site_detail;
      } catch (err) {
        (false && Ember.warn(err, {
          id: 'modals.cms-link-account.getBasicCustomerInformation'
        }));
      }
    }),
    getCustomerDiscrepancies: (0, _emberConcurrency.task)(function* () {
      try {
        var discrepancies = yield this.centralStationService.getData({
          model: this.customer,
          method: "cms__getUnlinkedCustomerDiff"
        });
        var missingZones = [];
        var zoneDiscrepancies = [];
        discrepancies.unlinked_customer_diff.zones_diff.forEach(centralStationZone => {
          if (centralStationZone.diff_fields.length === 3 && centralStationZone.diff_fields[0].trax === null && centralStationZone.diff_fields[1].trax === null && centralStationZone.diff_fields[2].trax === null) {
            missingZones.push(centralStationZone);
          } else {
            zoneDiscrepancies.push(centralStationZone);
          }
        });
        discrepancies.unlinked_customer_diff.missingZones = missingZones;
        discrepancies.unlinked_customer_diff.zoneDiscrepancies = zoneDiscrepancies;
        return discrepancies.unlinked_customer_diff;
      } catch (err) {
        (false && Ember.warn(err, {
          id: 'modals.cms-link-account.getCustomerDiscrepancies'
        }));
      }
    })
  });

  _exports.default = _default;
});