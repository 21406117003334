define("securitytrax-ember-ui/components/pages/my-inventory/spillage/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    grants: Ember.inject.service(),
    stFiltersService: Ember.inject.service(),
    tagName: '',
    limit: 50,
    page: 1,
    editSpillageFormMode: 'edit',
    editSpillageFormStarted: false,
    formToUse: undefined,
    createSpillageFormMode: 'edit',
    createSpillageFormStarted: false,
    spillageReport: undefined,
    spillageListStatus: undefined,
    spillageReceivedStatus: undefined,
    spillageListCount: 0,
    spillage: undefined,
    showNotification: true,
    appliedFiltersCount: 0,
    spillageNotice: Ember.computed(function () {
      return this.intl.t('common.filters.default_filters_applied');
    }),
    receivedStatusOptions: Ember.computed(function () {
      var intl = this.intl;
      return [{
        value: 'none',
        name: intl.t('pages.users.my_inventory.purchase_orders.status.none')
      }, {
        value: 'partially',
        name: intl.t('pages.users.my_inventory.purchase_orders.status.partially')
      }, {
        value: 'full',
        name: intl.t('pages.users.my_inventory.purchase_orders.status.in_full')
      }];
    }),
    statusOptions: Ember.computed(function () {
      var intl = this.intl;
      return [{
        value: 'open',
        name: intl.t('pages.users.my_inventory.spillage.open')
      }, {
        value: 'locked',
        name: intl.t('pages.users.my_inventory.spillage.locked')
      }, {
        value: 'closed',
        name: intl.t('pages.users.my_inventory.spillage.closed')
      }];
    }),
    createSpillageFormOriginalModels: Ember.computed('spillageReport', 'createSpillageFormStarted', 'grants.userGrants', function () {
      if (!this.createSpillageFormStarted) {
        this.set('spillageReport', undefined);
      }

      return Ember.Object.create({
        equipmentSpillage: {
          model: this.spillageReport,
          copyRelationMap: {
            user: {
              doCopy: true,
              doValidate: false,
              doSave: false
            },
            createdByUser: {
              doCopy: true,
              doValidate: false,
              doSave: false
            },
            pendingEquipmentLineItems: {
              relationships: {
                equipment: {
                  doCopy: true,
                  doSave: false
                },
                equipmentReturnReason: {
                  doCopy: true,
                  doSave: false
                }
              }
            }
          }
        }
      });
    }),
    spillageList: Ember.computed(function () {
      return this.getSpillageListTask.perform();
    }),
    getSpillageListTask: (0, _emberConcurrency.task)(function* () {
      var newFilters = yield this.stFiltersService.getFiltersByGroupKey('myInventorySpillage', ['user = ' + this.get('model.user.id')]);
      var queryParams = {
        filter: newFilters,
        sort: '-id',
        limit: this.limit,
        page: this.page
      };

      if (this.page == 1) {
        queryParams['count'] = 1;
      }

      var spillageList = yield this.store.query('equipmentSpillage', queryParams);

      if (this.page == 1) {
        this.set('spillageListCount', spillageList.meta.record_count);
      }

      return spillageList;
    }).restartable().maxConcurrency(1),
    editSpillageForm: Ember.computed('editSpillageFormMode', 'spillageReport.{id}', function () {
      var recipient = '';

      if (this.get('spillageReport.user.flname')) {
        recipient = this.get('spillageReport.user.flname');
      } else {
        recipient = this.get('spillageReport.location.name');
      }

      var titleData = {
        id: this.get('spillageReport.id'),
        userName: recipient
      };
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.intl.t('pages.users.my_inventory.spillage.edit_title', titleData),
          stepComponentPath: 'multi-step-form/forms/administration/users/my-inventory/spillage/edit-spillage'
        })
      });
    }),
    createSpillageForm: Ember.computed('createSpillageFormMode', function () {
      var title = this.intl.t('pages.users.my_inventory.spillage.create_title');
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: title,
          stepComponentPath: 'multi-step-form/forms/administration/users/my-inventory/spillage/create-spillage'
        })
      });
    }),
    spillageStatusOptionsDefaults: Ember.computed('statusOptions', function () {
      return this.statusOptions.filter(status => {
        return status.value === 'open';
      });
    }),
    actions: {
      //action sent to pagination component
      getSpillageList() {
        Ember.notifyPropertyChange(this, 'spillageList');
      },

      clearFilter() {
        Ember.setProperties(this, {
          spillageListStatus: this.spillageStatusOptionsDefaults,
          spillageReceivedStatus: undefined,
          createdDateFrom: undefined,
          createdDateTo: undefined
        });
        Ember.notifyPropertyChange(this, 'spillageList');
      },

      filterSpillage() {
        this.set('page', 1);
        Ember.notifyPropertyChange(this, 'spillageList');
      },

      editAction(row) {
        if (this.grants.getIndividualGrant('equipment_spillage.modify')) {
          this.set('createSpillageFormMode', 'edit');
        } else {
          this.set('createSpillageFormMode', 'view');
        }

        this.set('spillageReport', row);
        this.set('formToUse', this.editSpillageForm);
        this.set('createSpillageFormStarted', true);
      },

      addAction() {
        this.set('spillageReport', null);
        this.set('formToUse', this.createSpillageForm);
        this.set('createSpillageFormStarted', true);
        this.set('createSpillageFormMode', 'create');
      },

      refreshModel() {
        Ember.notifyPropertyChange(this, 'spillageList');
      }

    }
  });

  _exports.default = _default;
});