define("securitytrax-ember-ui/models/calendar-events-extended", ["exports", "securitytrax-ember-ui/models/base_models/calendar-events-extended"], function (_exports, _calendarEventsExtended) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _calendarEventsExtended.default.extend({});

  _exports.default = _default;
});