define("securitytrax-ember-ui/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xuSR5iCs",
    "block": "{\"symbols\":[],\"statements\":[[8,\"pages/app-login\",[],[[\"@companyName\",\"@authData\",\"@errorMessage\"],[[34,0],[34,1],[34,2]]],null]],\"hasEval\":false,\"upvars\":[\"companyName\",\"authData\",\"showError\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/login/template.hbs"
    }
  });

  _exports.default = _default;
});