define("securitytrax-ember-ui/components/homepage/dashboard/admin-top-sales-reps-today/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var AdminTopSalesRepsToday = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = (0, _emberConcurrency.task)(function* () {
    var url = yield this.stAjax.getUrl('/dashboard_cards/top_sales_reps');
    var focus = typeof this.args.config.card_focus === 'object' ? this.args.config.card_focus.value : this.args.config.card_focus;
    var reps = this.args.useDefaultData ? this.defaultData : yield this.stAjax.request(url, {
      method: 'GET',
      data: {
        focus_type: focus,
        start_day: this.args.config.date_range ? this.args.config.date_range.start_day : '***today***',
        end_day: this.args.config.date_range ? this.args.config.date_range.end_day : '***today***'
      }
    });
    var returnReps = yield this.calcRank(reps.data.reps);
    return returnReps;
  }), (_class = (_temp = class AdminTopSalesRepsToday extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "stAjax", _descriptor2, this);

      _initializerDefineProperty(this, "getRepListTask", _descriptor3, this);
    }

    get defaultData() {
      return {
        data: {
          reps: [{
            default_profile_image: true,
            fname: 'Georgia',
            lname: 'Roberson',
            rank: 1,
            rankClass: 'gold',
            total: '12'
          }, {
            default_profile_image: true,
            fname: 'Philip',
            lname: 'Norman',
            rank: 2,
            rankClass: 'silver',
            total: '11'
          }, {
            default_profile_image: true,
            fname: 'Alice',
            lname: 'Atkins',
            rank: 2,
            rankClass: 'silver',
            total: '11'
          }, {
            default_profile_image: true,
            fname: 'Birdie',
            lname: 'Daniel',
            rank: 4,
            total: '8'
          }, {
            default_profile_image: true,
            fname: 'Russ',
            lname: 'Kang',
            rank: 5,
            total: '6'
          }]
        }
      };
    }

    get repList() {
      return this.getRepListTask.perform();
    }

    calcRank(reps) {
      var curRank = 1;

      for (var x = 0; x < reps.length; x++) {
        if (x > 0) {
          if (reps[x].total != reps[x - 1].total) {
            curRank = x + 1;
          }
        }

        reps[x].rank = curRank;

        switch (curRank) {
          case 1:
            reps[x].rankClass = 'gold';
            break;

          case 2:
            reps[x].rankClass = 'silver';
            break;

          case 3:
            reps[x].rankClass = 'bronze';
            break;

          default:
            break;
        }
      }

      return reps;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stAjax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "getRepListTask", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AdminTopSalesRepsToday;
});