define("securitytrax-ember-ui/components/html/input-wrapper/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    stFiltersService: Ember.inject.service(),
    valueProperty: 'id',
    defaultValue: undefined,

    init() {
      this._super(...arguments);

      this.onInitTask.perform();
    },

    onInitTask: (0, _emberConcurrency.task)(function* () {
      if (this.stFilterOptions && this.stFilterOptions.stFilterGroupKey && this.stFilterOptions.stFilterKey && this.stFilterOptions.stFilterActualFilterKey) {
        var filterData = this.stFiltersService.registerFilter(this.stFilterOptions.stFilterGroupKey, this.stFilterOptions.stFilterKey, [this.stFilterOptions.stFilterActualFilterKey], this.defaultValue);

        if (filterData[this.stFilterOptions.stFilterActualFilterKey]) {
          if (this.onInit) {
            var stFilterPowerSelectOptions = yield this.stFilterPowerSelectOptions;
            Ember.run.later(() => {
              var fieldValue;

              if (stFilterPowerSelectOptions) {
                var grabbedFieldValue = [];

                if (!this.stFilterOptions.valueProperty) {
                  this.stFilterOptions.valueProperty = 'id';
                }

                for (var index = 0; index < filterData[this.stFilterOptions.stFilterActualFilterKey].fieldValue.length; index++) {
                  var currFieldValue = filterData[this.stFilterOptions.stFilterActualFilterKey].fieldValue.objectAt(index);
                  var potentialValue = stFilterPowerSelectOptions.findBy(this.stFilterOptions.valueProperty, currFieldValue.id);

                  if (potentialValue) {
                    grabbedFieldValue.push(potentialValue);
                  } else {
                    grabbedFieldValue.push(currFieldValue);
                  }
                }

                fieldValue = grabbedFieldValue;
              } else {
                fieldValue = filterData[this.stFilterOptions.stFilterActualFilterKey].fieldValue;
              }

              this.onInit(fieldValue);
              this.stFiltersService.setAsLoaded(this.stFilterOptions.stFilterGroupKey, this.stFilterOptions.stFilterKey);
            });
          }
        } else {
          if (this.onInit) {
            if (this.defaultValue && this.defaultValue.value != null) {
              if (this.defaultValue.callback) {
                this.defaultValue.callback(this.defaultValue.value);
              }

              Ember.run.later(() => {
                this.onInit(this.defaultValue.value);
                this.stFiltersService.setAsLoaded(this.stFilterOptions.stFilterGroupKey, this.stFilterOptions.stFilterKey);
              });
            } else {
              Ember.run.later(() => {
                this.stFiltersService.setAsLoaded(this.stFilterOptions.stFilterGroupKey, this.stFilterOptions.stFilterKey);
              });
            }
          } else {
            Ember.run.later(() => {
              this.stFiltersService.setAsLoaded(this.stFilterOptions.stFilterGroupKey, this.stFilterOptions.stFilterKey);
            });
          }
        }
      }
    })
  });

  _exports.default = _default;
});