define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/forms/duplicate/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/customer-base", "ember-concurrency", "moment"], function (_exports, _customerBase, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var Duplicate = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._tracked, _dec21 = Ember._tracked, _dec22 = Ember._tracked, _dec23 = Ember._tracked, _dec24 = Ember._tracked, _dec25 = (0, _emberConcurrency.task)(function* () {
    // For some reason doing these in a hash breaks things
    this.duplicateDefaultOptions = yield this.sessionData.getCompanyDefaults('customerDuplicateDefault');
    this.customerContactsList = yield this.customer.get('customerContacts');
    this.agencyContactsList = yield this.customer.get('agencyContacts');
    this.isAdt = this.customer.get('customerOrderInformation.monitoringCompany.monitoringCompanyType.id') === '1';
    this.customerContacts = [];
    this.agencyContacts = [];
    var allYields = [];
    var customerContactsCsv = this.customerContactsList.map(contact => {
      return contact.get('name');
    }).join(', ');
    var agencyContactsCsv = this.agencyContactsList.filter(contact => {
      return contact.get('name') != undefined;
    }).map(contact => {
      return contact.get('name');
    }).join(', ');
    this.guardResponseFee = this.customer.get('customerOrderInformation.guardResponseFee');
    this.sections = [{
      key: 'customerInformation',
      title: null,
      list: [{
        copy: true,
        datapoint: 'location',
        customerValue: this.customer.get('location.name'),
        readOnly: true
      }, {
        copy: this.isAdt || this.duplicateDefaultOptions.get('accountType'),
        datapoint: 'accountType',
        customerValue: this.customer.get('accountType'),
        readOnly: this.isAdt
      }, {
        copy: this.duplicateDefaultOptions.get('accountClass'),
        datapoint: 'accountClass',
        customerValue: this.customer.get('accountClass.name')
      }, {
        copy: this.duplicateDefaultOptions.get('businessName'),
        datapoint: 'businessName',
        customerValue: this.customer.get('businessName')
      }, {
        copy: this.duplicateDefaultOptions.get('businessContact'),
        datapoint: 'businessContact',
        customerValue: this.customer.get('businessContact')
      }]
    }, {
      key: 'personalInformation',
      title: this.intl.t('common.nav.create.customer.sections.personal'),
      list: [{
        copy: this.duplicateDefaultOptions.get('spouseName'),
        datapoint: 'spouse',
        customerValue: this.customer.get('spouse')
      }, {
        copy: this.duplicateDefaultOptions.get('primaryLanguage'),
        datapoint: 'lang',
        customerValue: this.customer.get('lang')
      }, {
        copy: this.duplicateDefaultOptions.get('birthdate'),
        datapoint: 'customerBirthdate.birthdate',
        customerValue: this.customer.get('customerBirthdate.birthdate') && this.grants.getIndividualGrant('customer_birthdates.view') ? (0, _moment.default)(this.customer.get('customerBirthdate.birthdate')).format('MM/DD/YYYY') : null
      }, {
        copy: this.duplicateDefaultOptions.get('socialSecurityNumber'),
        datapoint: 'customerSsn.ssn',
        customerValue: this.grants.getIndividualGrant('customer_ssns.view') ? this.customer.get('customerSsn.ssn') : ''
      }, {
        copy: this.duplicateDefaultOptions.get('creditScore'),
        datapoint: 'customerCreditCheck.score',
        customerValue: this.grants.getIndividualGrant('customer_credit_checks.view') ? this.customer.get('customerCreditCheck.score') : ''
      }, {
        copy: this.duplicateDefaultOptions.get('creditTrackingNumber'),
        datapoint: 'customerCreditCheck.transactionId',
        customerValue: this.grants.getIndividualGrant('customer_credit_checks.view') ? this.customer.get('customerCreditCheck.transactionId') : ''
      }]
    }, {
      key: 'contactInformation',
      title: this.intl.t('common.nav.create.customer.sections.contact'),
      list: [{
        copy: this.isAdt || this.duplicateDefaultOptions.get('address'),
        datapoint: 'address1',
        customerValue: this.customer.get('address1'),
        readOnly: this.isAdt
      }, {
        copy: this.duplicateDefaultOptions.get('apartmentSuite'),
        datapoint: 'address2',
        customerValue: this.customer.get('address2')
      }, {
        copy: true,
        datapoint: 'city',
        customerValue: this.customer.get('city'),
        readOnly: true
      }, {
        copy: true,
        datapoint: 'state',
        customerValue: this.customer.get('state'),
        readOnly: true
      }, {
        copy: this.isAdt || this.duplicateDefaultOptions.get('zipCode'),
        datapoint: 'zip',
        customerValue: this.customer.get('zip'),
        readOnly: this.isAdt
      }, {
        copy: this.isAdt || this.duplicateDefaultOptions.get('county'),
        datapoint: 'county',
        customerValue: this.customer.get('county'),
        readOnly: this.isAdt
      }, {
        copy: this.duplicateDefaultOptions.get('crossStreet'),
        datapoint: 'crossStreet',
        customerValue: this.customer.get('crossStreet')
      }, {
        copy: this.duplicateDefaultOptions.get('homeOwnership'),
        datapoint: 'homeOwnership',
        customerValue: this.customer.get('homeOwnership')
      }, {
        copy: true,
        datapoint: 'primaryPhone',
        customerValue: this.customer.get('primaryPhone'),
        readOnly: true
      }, {
        copy: this.duplicateDefaultOptions.get('cellPhone'),
        datapoint: 'cellPhone',
        customerValue: this.customer.get('cellPhone')
      }, {
        copy: this.duplicateDefaultOptions.get('workPhone'),
        datapoint: 'workPhone',
        customerValue: this.customer.get('workPhone')
      }, {
        copy: this.duplicateDefaultOptions.get('otherPhone'),
        datapoint: 'otherPhone',
        customerValue: this.customer.get('otherPhone')
      }, {
        copy: this.isAdt || this.duplicateDefaultOptions.get('email'),
        datapoint: 'email',
        customerValue: this.customer.get('email'),
        readOnly: this.isAdt
      }, {
        copy: this.duplicateDefaultOptions.get('bestTimeToContact'),
        datapoint: 'contactTime',
        customerValue: this.customer.get('contactTime')
      }]
    }, {
      key: 'saleInformation',
      title: this.intl.t('common.nav.create.customer.sections.salesInformation'),
      list: [{
        copy: this.duplicateDefaultOptions.get('source'),
        datapoint: 'leadSourceInformation.leadCompany',
        customerValue: this.grants.getIndividualGrant('lead_companies.view') ? this.customer.get('leadSourceInformation.leadCompany.name') : ''
      }, {
        copy: this.duplicateDefaultOptions.get('sourceCampaign'),
        datapoint: 'leadSourceInformation.leadCompanyCampaign',
        customerValue: this.grants.getIndividualGrant('lead_company_campaigns.view') ? this.customer.get('leadSourceInformation.leadCompanyCampaign.name') : ''
      }, {
        copy: this.duplicateDefaultOptions.get('salesRepresentative'),
        datapoint: 'customerOrderInformation.repUser',
        customerValue: this.grants.getIndividualGrant('customer_order_information.view') ? this.customer.get('customerOrderInformation.repUser.flname') : ''
      }, {
        copy: this.duplicateDefaultOptions.get('altSalesRepresentative'),
        datapoint: 'customerOrderInformation.trainUser',
        customerValue: this.grants.getIndividualGrant('customer_order_information.view') ? this.customer.get('customerOrderInformation.trainUser.flname') : ''
      }, {
        copy: true,
        datapoint: 'saleDate',
        customerValue: (0, _moment.default)(this.customer.get('saleDate')).format('MM/DD/YYYY'),
        readOnly: true
      }, {
        copy: this.duplicateDefaultOptions.get('monitoringPlan'),
        datapoint: 'customerOrderInformation.monitoringPlan',
        customerValue: this.grants.getIndividualGrant('customer_order_information.view') ? this.customer.get('customerOrderInformation.monitoringPlan.name') : ''
      }, {
        copy: this.duplicateDefaultOptions.get('monthlyCharge'),
        datapoint: 'customerOrderInformation.mmr',
        customerValue: this.grants.getIndividualGrant('customer_order_information.view') ? this.customer.get('customerOrderInformation.mmr.amount') : ''
      }, {
        copy: this.duplicateDefaultOptions.get('activationFee'),
        datapoint: 'customerOrderInformation.activationFee',
        customerValue: this.grants.getIndividualGrant('customer_order_information.view') ? this.customer.get('customerOrderInformation.activationFee.amount') : ''
      }, {
        copy: this.duplicateDefaultOptions.get('contractTerm'),
        datapoint: 'customerOrderInformation.contractTerm',
        customerValue: this.grants.getIndividualGrant('customer_order_information.view') ? this.customer.get('customerOrderInformation.contractTerm.months') : ''
      }, {
        copy: this.duplicateDefaultOptions.get('warrantyTerm'),
        datapoint: 'customerOrderInformation.customerWarranty',
        customerValue: this.grants.getIndividualGrant('customer_order_information.view') ? this.customer.get('customerOrderInformation.customerWarranty.days') : ''
      }, {
        copy: this.duplicateDefaultOptions.get('guardResponse'),
        datapoint: 'customerOrderInformation.serviceGuardResponse',
        customerValue: this.grants.getIndividualGrant('customer_order_information.view') ? this.customer.get('customerOrderInformation.serviceGuardResponse') || 'False' : ''
      }, {
        copy: this.duplicateDefaultOptions.get('guardResponseFee'),
        datapoint: 'guardResponseFee',
        customerValue: this.grants.getIndividualGrant('agency_contacts.view') ? this.guardResponseFee ? '$' + this.guardResponseFee : '$0.00' : ''
      }, {
        copy: this.duplicateDefaultOptions.get('invoicing'),
        datapoint: 'customerOrderInformation.serviceInvoicing',
        customerValue: this.grants.getIndividualGrant('customer_order_information.view') ? this.customer.get('customerOrderInformation.serviceInvoicing') || 'False' : ''
      }, {
        copy: this.duplicateDefaultOptions.get('rebateCheckAmount'),
        datapoint: 'customerOrderInformation.rebateCheck',
        customerValue: this.grants.getIndividualGrant('customer_order_information.view') ? this.customer.get('customerOrderInformation.rebateCheck') ? '$' + this.customer.get('customerOrderInformation.rebateCheck') : '$0.00' : ''
      }, {
        copy: this.duplicateDefaultOptions.get('affiliate'),
        datapoint: 'customerOrderInformation.discountAffiliate',
        customerValue: this.grants.getIndividualGrant('customer_order_information.view') ? this.customer.get('customerOrderInformation.discountAffiliate.name') : ''
      }, {
        copy: this.duplicateDefaultOptions.get('affiliateMemberNumber'),
        datapoint: 'customerOrderInformation.discountAffiliateMemberNumber',
        customerValue: this.grants.getIndividualGrant('customer_order_information.view') ? this.customer.get('customerOrderInformation.discountAffiliateMemberNumber') : ''
      }, {
        copy: this.duplicateDefaultOptions.get('accountIsCancelled'),
        datapoint: 'customerOrderInformation.customerIsCancelled',
        customerValue: this.grants.getIndividualGrant('customer_order_information.view') ? this.customer.get('customerOrderInformation.customerIsCancelled') || 'False' : ''
      }, {
        copy: this.duplicateDefaultOptions.get('cancelDate'),
        datapoint: 'customerOrderInformation.customerCancelDate',
        customerValue: this.grants.getIndividualGrant('customer_order_information.view') ? this.customer.get('customerOrderInformation.customerCancelDate') : ''
      }, {
        copy: this.duplicateDefaultOptions.get('cancelReason'),
        datapoint: 'customerOrderInformation.customerCancelReason',
        customerValue: this.grants.getIndividualGrant('customer_order_information.view') ? this.customer.get('customerOrderInformation.customerCancelReason.name') : ''
      }]
    }, {
      key: 'centralStation',
      title: this.intl.t('common.nav.create.customer.sections.centralStation'),
      list: [{
        copy: this.isAdt || this.duplicateDefaultOptions.get('monitoredBy'),
        datapoint: 'customerOrderInformation.monitoringCompany',
        customerValue: this.grants.getIndividualGrant('customer_order_information.view') ? this.customer.get('customerOrderInformation.monitoringCompany.name') : '',
        readOnly: this.isAdt
      }, {
        copy: this.duplicateDefaultOptions.get('accountNumber'),
        datapoint: 'customerSystemInformation.accountNum',
        customerValue: this.grants.getIndividualGrant('customer_system_information.view') ? this.customer.get('customerSystemInformation.accountNum') : ''
      }, {
        copy: this.duplicateDefaultOptions.get('dealerNumber'),
        datapoint: 'customerSystemInformation.dealerNumber',
        customerValue: this.grants.getIndividualGrant('customer_system_information.view') ? this.customer.get('customerSystemInformation.dealerNumber.name') : ''
      }, {
        copy: this.duplicateDefaultOptions.get('confirmationNumber'),
        datapoint: 'customerSystemInformation.confirmationNum',
        customerValue: this.grants.getIndividualGrant('customer_system_information.view') ? this.customer.get('customerSystemInformation.confirmationNum') : ''
      }, {
        copy: this.duplicateDefaultOptions.get('abortCode'),
        datapoint: 'customerSystemInformation.customerAbortCode.abortCode',
        customerValue: this.grants.getIndividualGrant('customer_abort_codes.view') && this.grants.getIndividualGrant('customer_system_information.view') ? this.customer.get('customerSystemInformation.customerAbortCode.abortCode') : ''
      }, {
        copy: this.duplicateDefaultOptions.get('twoWayVoice'),
        datapoint: 'customerSystemInformation.serviceTwoWayVoice',
        customerValue: this.grants.getIndividualGrant('customer_system_information.view') ? this.customer.get('customerSystemInformation.serviceTwoWayVoice') || 'False' : ''
      }, {
        copy: this.duplicateDefaultOptions.get('receiverNumber'),
        datapoint: 'customerSystemInformation.receiverNum',
        customerValue: this.grants.getIndividualGrant('customer_system_information.view') ? this.customer.get('customerSystemInformation.receiverNum') : ''
      }, {
        copy: this.duplicateDefaultOptions.get('serialMan'),
        datapoint: 'customerSystemInformation.adtCustomerNumber',
        customerValue: this.grants.getIndividualGrant('customer_system_information.view') ? this.customer.get('customerSystemInformation.adtCustomerNumber') : '',
        hide: !this.isAdt
      }, {
        copy: this.duplicateDefaultOptions.get('customerNumber'),
        datapoint: 'customerSystemInformation.adtSerialMan',
        customerValue: this.grants.getIndividualGrant('customer_system_information.view') ? this.customer.get('customerSystemInformation.adtSerialMan') : '',
        hide: !this.isAdt
      }]
    }, {
      key: 'customerInstallation',
      title: this.intl.t('common.nav.create.customer.sections.customerInstallation'),
      list: [{
        copy: this.duplicateDefaultOptions.get('isInstalled'),
        datapoint: 'customerSystemInformation.isInstalled',
        customerValue: this.grants.getIndividualGrant('customer_system_information.view') ? this.customer.get('customerSystemInformation.isInstalled') || 'False' : ''
      }]
    }, {
      key: 'contacts',
      title: this.intl.t('common.nav.create.customer.sections.contacts'),
      list: [{
        copy: this.duplicateDefaultOptions.get('emergencyContacts'),
        datapoint: 'customerContacts',
        customerValue: customerContactsCsv
      }, {
        copy: this.duplicateDefaultOptions.get('agencyContacts'),
        datapoint: 'agencyContacts',
        customerValue: agencyContactsCsv
      }]
    }];
    yield (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true);
    this.sections.forEach(section => {
      section.list.forEach(row => {
        if (row.copy) {
          allYields.push(this.updateValueTask.perform(section.key, row));
        }
      });
    });
    yield allYields;
    this.setupButtons();
  }), _dec26 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    multiStepForm.formCopiedModelsSetVal('customer.model.recordType', 'customer');
    var overrideConfig = Ember.Object.create({
      customer: {
        copyRelationMap: {
          customerBirthdate: {
            readOnly: false
          },
          location: {
            readOnly: true,
            relationships: {
              dealerNumber: {
                readOnly: true
              }
            }
          },
          accountClass: {
            readOnly: true
          },
          customerCreditCheck: {
            doCopy: this.grants.getIndividualGrant('customer_credit_checks.view'),
            doValidate: this.grants.getIndividualGrant('customer_credit_checks.view'),
            doSave: this.grants.getIndividualGrant('customer_credit_checks.create')
          },
          customerSsn: {
            readOnly: false
          },
          customerSystemInformation: {
            doCopy: true,
            relationships: {
              dealerNumber: {
                readOnly: true
              },
              customerAbortCode: {
                doCopy: this.grants.getIndividualGrant('customer_abort_codes.view'),
                doValidate: this.grants.getIndividualGrant('customer_abort_codes.view'),
                doSave: this.grants.getIndividualGrant('customer_abort_codes.modify')
              }
            }
          },
          customerOrderInformation: {
            doCopy: true,
            relationships: {
              activationFee: {
                readOnly: true
              },
              customerWarranty: {
                readOnly: true
              },
              discountAffiliate: {
                readOnly: true
              },
              interactiveServicesProvider: {
                readOnly: true,
                relationships: {
                  servicePackages: {
                    readOnly: true
                  }
                }
              },
              mmr: {
                readOnly: true
              },
              monitoringCompany: {
                readOnly: true
              },
              monitoringPlan: {
                readOnly: true
              },
              repUser: {
                readOnly: true
              },
              train: {
                readOnly: true
              },
              contractTerm: {
                readOnly: true
              }
            }
          },
          leadSourceInformation: {
            doCopy: this.grants.getIndividualGrant('customer_customer_lead_source_information.view'),
            doSync: this.grants.getIndividualGrant('customer_customer_lead_source_information.view'),
            doValidate: this.grants.getIndividualGrant('customer_customer_lead_source_information.view'),
            doSave: this.grants.getIndividualGrant('customer_lead_source_information.modify'),
            relationships: {
              leadCompany: {
                readOnly: true,
                doCopy: this.grants.getIndividualGrant('lead_companies.view')
              },
              leadCompanyCampaign: {
                readOnly: true,
                doCopy: this.grants.getIndividualGrant('lead_company_campaigns.view')
              }
            }
          }
        }
      }
    });
    var toCreateObjects = yield multiStepForm.validateCreateAll({
      notificationNamespace: 'createCustomerModal',
      overrideOriginalModelsConfig: overrideConfig,
      extraValidationData: {
        noValidate: ['orderInformation-customerCancelDate', 'customer-zip', 'leadSourceInformation-leadCompany', 'customer-address1', 'orderInformation-salesRep']
      }
    });
    toCreateObjects['customerOrderInformation'].data.guardResponseFee = this.guardResponseFee;
    var embeddedRelationships = ['customerSsn', 'customerBirthdate', 'customerSystemInformation', 'customerOrderInformation'];
    var customer = this.store.createRecord('customer', toCreateObjects.customer.data);
    embeddedRelationships.forEach(relation => {
      var createdRelation = toCreateObjects[relation].data ? this.store.createRecord(relation, toCreateObjects[relation].data) : null;
      customer.set(relation, createdRelation);
    });
    customer.set('requestMeta', {
      check_for_duplicates: false
    });
    var savedCustomer = yield toCreateObjects.customer.saveWithRollbackAndNotification(customer);
    var allExtraSaves = [];
    var hashedData = yield (0, _emberConcurrency.hash)({
      leadCompany: this.grants.getIndividualGrant('lead_companies.view') && toCreateObjects.leadCompany.data && toCreateObjects.leadCompany.data.id ? this.store.findRecord('leadCompany', toCreateObjects.leadCompany.data.id) : null,
      leadCompanyCampaign: this.grants.getIndividualGrant('lead_company_campaigns.view') && toCreateObjects.leadCompanyCampaign.data && toCreateObjects.leadCompanyCampaign.data.id ? this.store.findRecord('leadCompanyCampaign', toCreateObjects.leadCompanyCampaign.data.id) : null,
      leadSourceInformation: this.grants.getIndividualGrant('customer_lead_source_information.view') ? savedCustomer.get('leadSourceInformation') : null,
      customerAbortCode: this.grants.getIndividualGrant('customer_abort_codes.view') ? savedCustomer.get('customerAbortCode') : null,
      manualBureau: this.store.query('creditBureau', {
        filter: 'credit_bureau_type=\'11\''
      }),
      agencyContacts: this.grants.getIndividualGrant('agency_contacts.view') ? savedCustomer.get('agencyContacts') : []
    }); // PERF TODO: Can BE allow leadSourceInformation, customerCreditCheck and customerAbortCode to be embedded if we're duplicating a record?
    // Maybe even customerContacts and agencyContacts if at all possible

    if (hashedData.leadSourceInformation && this.grants.getIndividualGrant('customer_lead_source_information.modify')) {
      hashedData.leadSourceInformation.set('leadCompany', hashedData.leadCompany);
      hashedData.leadSourceInformation.set('leadCompanyCampaign', hashedData.leadCompanyCampaign);
    }

    if (hashedData.customerAbortCode && this.grants.getIndividualGrant('customer_abort_codes.modify')) {
      hashedData.customerAbortCode.set('abortCode', toCreateObjects.customerAbortCode.data ? toCreateObjects.customerAbortCode.data.abortCode : null);
    }

    allExtraSaves.push(hashedData.leadSourceInformation.save());
    allExtraSaves.push(hashedData.customerAbortCode.save());

    if (toCreateObjects.customerCreditCheck.data && (toCreateObjects.customerCreditCheck.data.score || toCreateObjects.customerCreditCheck.data.transactionId) && hashedData.manualBureau.objectAt(0)) {
      var customerCreditCheck = this.store.createRecord('customerCreditCheck', {
        score: toCreateObjects.customerCreditCheck.data.score,
        transactionId: toCreateObjects.customerCreditCheck.data.transactionId,
        creditBureau: hashedData.manualBureau.objectAt(0),
        customer: savedCustomer
      });
      allExtraSaves.push(customerCreditCheck.save());
    }

    if (this.grants.getIndividualGrant('customer_contacts.create')) {
      this.customerContacts.forEach(contact => {
        var customerContact = this.store.createRecord('customerContact', {
          name: contact.get('name'),
          phone1: contact.get('phone1'),
          email: contact.get('email'),
          about: contact.get('about'),
          lang: contact.get('lang'),
          centralStationId: contact.get('centralStationId'),
          interactiveServicesId: contact.get('interactiveServicesId'),
          sort: contact.get('sort'),
          enhancedVerification: contact.get('enhancedVerification'),
          passcode: contact.get('passcode'),
          customer: savedCustomer
        });
        allExtraSaves.push(customerContact.save());
      });
    }

    if (this.grants.getIndividualGrant('agency_contacts.modify')) {
      this.agencyContactsList.forEach(contact => {
        var agencyContact = hashedData.agencyContacts.findBy('agencyContactType', contact.get('agencyContactType'));
        Ember.setProperties(agencyContact, {
          name: contact.get('name'),
          permit: contact.get('permit'),
          number: contact.get('number')
        });
        allExtraSaves.push(agencyContact.save());
      });
    }

    yield (0, _emberConcurrency.all)(allExtraSaves);
    buttonPromise.then(() => {
      multiStepForm.formStartedSetVal(false);
      this.router.transitionTo('customers.information', savedCustomer.get('id'));
    });
  }), _dec27 = (0, _emberConcurrency.task)(function* (sectionKey, row) {
    var specialDatapoints = ['customerContacts', 'agencyContacts'];
    var section = this.sections.find(section => {
      return section.key === sectionKey;
    });
    var unCheckedFields = section.list.filter(field => {
      return !field.copy && !field.hide;
    });
    Ember.set(this, sectionKey + 'SelectAll', unCheckedFields.length === 0);
    Ember.set(this, sectionKey + 'Indeterminate', unCheckedFields.length > 0 && unCheckedFields.length !== section.list.filter(field => {
      return !field.hide;
    }).length);

    if (row.customerValue !== '') {
      if (row.copy) {
        if (specialDatapoints.includes(row.datapoint)) {
          Ember.set(this, row.datapoint, yield this.customer.get(row.datapoint));
        } else {
          this.multiStepForm.formCopiedModelsSetVal('customer.model.' + row.datapoint, yield this.customer.get(row.datapoint));
        }
      } else {
        if (specialDatapoints.includes(row.datapoint)) {
          Ember.set(this, row.datapoint, []);
        } else {
          this.multiStepForm.formCopiedModelsSetVal('customer.model.' + row.datapoint, null);
        }
      }
    }
  }), _dec28 = (0, _emberConcurrency.task)(function* (sectionKey, value) {
    var section = this.sections.find(section => {
      return section.key === sectionKey;
    });
    var allYields = [];
    section.list.forEach(row => {
      if (!row.hide && !row.readOnly) {
        Ember.set(row, 'copy', !value);
        allYields.push(this.updateValueTask.perform(sectionKey, row));
      }
    });
    yield (0, _emberConcurrency.all)(allYields);
  }), (_class = (_temp = class Duplicate extends _customerBase.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "sessionData", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "duplicateDefaultOptions", _descriptor5, this);

      _initializerDefineProperty(this, "customerContactsList", _descriptor6, this);

      _initializerDefineProperty(this, "agencyContactsList", _descriptor7, this);

      _initializerDefineProperty(this, "isAdt", _descriptor8, this);

      _initializerDefineProperty(this, "sections", _descriptor9, this);

      _initializerDefineProperty(this, "customerContacts", _descriptor10, this);

      _initializerDefineProperty(this, "agencyContacts", _descriptor11, this);

      _initializerDefineProperty(this, "guardResponseFee", _descriptor12, this);

      _initializerDefineProperty(this, "customerInformationSelectAll", _descriptor13, this);

      _initializerDefineProperty(this, "contactInformationSelectAll", _descriptor14, this);

      _initializerDefineProperty(this, "saleInformationSelectAll", _descriptor15, this);

      _initializerDefineProperty(this, "centralStationSelectAll", _descriptor16, this);

      _initializerDefineProperty(this, "customerInstallationSelectAll", _descriptor17, this);

      _initializerDefineProperty(this, "contactsSelectAll", _descriptor18, this);

      _initializerDefineProperty(this, "customerInformationIndeterminate", _descriptor19, this);

      _initializerDefineProperty(this, "contactInformationIndeterminate", _descriptor20, this);

      _initializerDefineProperty(this, "saleInformationIndeterminate", _descriptor21, this);

      _initializerDefineProperty(this, "centralStationIndeterminate", _descriptor22, this);

      _initializerDefineProperty(this, "customerInstallationIndeterminate", _descriptor23, this);

      _initializerDefineProperty(this, "contactsIndeterminate", _descriptor24, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor25, this);

      _initializerDefineProperty(this, "duplicateCustomerTask", _descriptor26, this);

      _initializerDefineProperty(this, "updateValueTask", _descriptor27, this);

      _initializerDefineProperty(this, "selectAllTask", _descriptor28, this);

      this.initDataTask.perform();
    }

    setupButtons() {
      this.multiStepForm.registerStepData('step1', 'buttons', [{
        text: this.intl.t('common.actions.save'),
        classNamesString: 'st-btn st-btn-primary',
        click: buttonPromise => {
          return this.duplicateCustomerTask.perform(this.multiStepForm, buttonPromise);
        }
      }]);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "duplicateDefaultOptions", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "customerContactsList", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "agencyContactsList", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "isAdt", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "sections", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "customerContacts", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "agencyContacts", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "guardResponseFee", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "customerInformationSelectAll", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "contactInformationSelectAll", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "saleInformationSelectAll", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "centralStationSelectAll", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "customerInstallationSelectAll", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "contactsSelectAll", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "customerInformationIndeterminate", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "contactInformationIndeterminate", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "saleInformationIndeterminate", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "centralStationIndeterminate", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "customerInstallationIndeterminate", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "contactsIndeterminate", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "duplicateCustomerTask", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "updateValueTask", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "selectAllTask", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = Duplicate;
});