define("securitytrax-ember-ui/components/pages/administration/inventory/equipment-templates/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EquipmentTemplates = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = (0, _emberConcurrency.task)(function* () {
    var hashedData = yield (0, _emberConcurrency.hash)({
      equipmentTemplates: this.store.findAll('equipmentToRecipientTemplate', {
        include: 'equipment_to_recipients.equipment'
      }),
      equipment: this.getEquipmentTask.perform()
    });
    Ember.setProperties(this, hashedData);
  }), _dec11 = (0, _emberConcurrency.task)(function* () {
    return yield this.store.query('equipment', {
      include: 'equipment_type,default_equipment_vendor',
      limit: 0,
      filter: 'active=true'
    });
  }), _dec12 = Ember._action, (_class = (_temp = class EquipmentTemplates extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "grants", _descriptor4, this);

      _initializerDefineProperty(this, "equipmentToRecipientTemplate", _descriptor5, this);

      _initializerDefineProperty(this, "formStarted", _descriptor6, this);

      _initializerDefineProperty(this, "formMode", _descriptor7, this);

      _initializerDefineProperty(this, "equipmentTemplates", _descriptor8, this);

      _initializerDefineProperty(this, "equipment", _descriptor9, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor10, this);

      _initializerDefineProperty(this, "getEquipmentTask", _descriptor11, this);

      this.initDataTask.perform();
    }

    get formSteps() {
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.intl.t('pages.administration.company_settings.inventory.equipment_templates.' + this.formMode + '_title', {
            name: this.equipmentToRecipientTemplate ? this.equipmentToRecipientTemplate.name : ''
          }),
          stepComponentPath: 'multi-step-form/forms/administration/inventory/edit-equipment-template'
        })
      });
    }

    get formOriginalModels() {
      return Ember.Object.create({
        equipmentToRecipientTemplate: {
          model: this.equipmentToRecipientTemplate
        }
      });
    }

    get equipmentToRecipients() {
      return this.equipmentToRecipientTemplate ? this.equipmentToRecipientTemplate.filteredEquipmentToRecipients : null;
    }

    editAction(row) {
      if (this.grants.getIndividualGrant('equipment_to_recipient_templates.modify')) {
        this.formMode = 'edit';
      }

      this.equipmentToRecipientTemplate = row;
      this.formStarted = true;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "equipmentToRecipientTemplate", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "formStarted", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "formMode", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'view';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "equipmentTemplates", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "equipment", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "getEquipmentTask", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "editAction", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "editAction"), _class.prototype)), _class));
  _exports.default = EquipmentTemplates;
});