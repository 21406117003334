define("securitytrax-ember-ui/components/calendar-glimmer/mobile-add-appointment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RMvBmEZL",
    "block": "{\"symbols\":[\"@user\",\"@allowAddAppointment\"],\"statements\":[[6,[37,1],[[32,2]],null,[[\"default\"],[{\"statements\":[[11,\"div\"],[24,0,\"calendar-user__add-card appointment--mobile\"],[4,[38,0],[[32,0],\"addAppointment\"],null],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"grid-x\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"grid-x cell align-middle small-6 calendar-user calendar-user--no-padding\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"tech-image cell shrink\"],[12],[2,\"\\n\"],[6,[37,1],[[32,1,[\"avatar\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                    \"],[8,\"profile/profile-img\",[],[[\"@img\"],[[32,1,[\"avatar\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"                    \"],[2,[32,1,[\"noAvatarHtml\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"            \"],[13],[2,\"\\n\\n            \"],[10,\"div\"],[14,0,\"name cell auto\"],[12],[1,[32,1,[\"flname\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"grid-x cell align-middle small-6 calendar-event-type\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"add-appointment cell shrink\"],[12],[2,\"\\n                \"],[10,\"span\"],[14,0,\"pointer clickable-icon icon\"],[12],[1,[30,[36,2],[\"icn-circle-add\"],null]],[2,\"\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"cell add-appointment-title auto\"],[12],[2,\"\\n                \"],[1,[30,[36,3],[\"pages.customers.workOrders.details.addAppointment\"],null]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"if\",\"svg-icon\",\"t\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/calendar-glimmer/mobile-add-appointment/template.hbs"
    }
  });

  _exports.default = _default;
});