define("securitytrax-ember-ui/demo/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    /*use this if you need to check saving models in mirage 
    model() {
        this.get('store').unloadAll();
    } */
  });

  _exports.default = _default;
});