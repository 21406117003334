define("securitytrax-ember-ui/components/pages/administration/company-settings/content-management/work-order-templates/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/pages/administration/company-settings/content-management/templates-shared"], function (_exports, _emberConcurrency, _templatesShared) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var WorkOrderTemplates = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember.computed, _dec7 = (0, _emberConcurrency.task)(function* () {
    return yield this.getTemplatesByType.perform('1', this.setTemplateRelationData);
  }), _dec8 = (0, _emberConcurrency.task)(function* (template) {
    var sortedTemplateFieldDefaults = yield template.sortedTemplateFieldDefaults;
    var workOrderType = this.args.workOrderTypes.findBy('id', sortedTemplateFieldDefaults[1].value);
    template._type = workOrderType && workOrderType.name ? workOrderType.name : '';
    template._subject = sortedTemplateFieldDefaults[2].value;
  }), (_class = (_temp = class WorkOrderTemplates extends _templatesShared.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "workOrderTemplate", _descriptor, this);

      _initializerDefineProperty(this, "workOrderTemplateFormStarted", _descriptor2, this);

      _initializerDefineProperty(this, "workOrderTemplateFormMode", _descriptor3, this);

      _initializerDefineProperty(this, "templateList", _descriptor4, this);

      _initializerDefineProperty(this, "stFilterGroupKey", _descriptor5, this);

      _initializerDefineProperty(this, "getWorkOrderTemplatesTask", _descriptor6, this);

      _initializerDefineProperty(this, "setTemplateRelationData", _descriptor7, this);
    }

    get workOrderTemplates() {
      return this.getWorkOrderTemplatesTask.perform();
    }

    get workOrderTemplateFormSteps() {
      var intl = this.intl;
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: intl.t("common.forms.descriptive_title_".concat(this.workOrderTemplateFormMode), {
            name: this.workOrderTemplateFormMode === 'create' ? intl.t('pages.administration.content_management.work_order_templates.label') : this.formTitle.name,
            id: this.formTitle.id
          }),
          stepComponentPath: 'multi-step-form/forms/administration/content-management/edit-work-order-template'
        })
      });
    }

    get workOrderTemplateFormOriginalModels() {
      return Ember.Object.create({
        template: {
          model: this.workOrderTemplate,
          copyRelationMap: {
            templateFieldDefaults: {
              relationships: {
                templateTypeColumn: {
                  readOnly: true
                }
              }
            }
          }
        }
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "workOrderTemplate", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "workOrderTemplateFormStarted", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "workOrderTemplateFormMode", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'edit';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "templateList", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'workOrderTemplates';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "stFilterGroupKey", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'companyWorkOrderTemplates';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "workOrderTemplates", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "workOrderTemplates"), _class.prototype), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "getWorkOrderTemplatesTask", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "setTemplateRelationData", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = WorkOrderTemplates;
});