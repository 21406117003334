define("securitytrax-ember-ui/models/base_models/equipment-sub-type", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    name: (0, _model.attr)('string', {
      'readOnly': false
    }),
    active: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    equipmentType: (0, _model.belongsTo)('equipment-type', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    equipmentItems: (0, _model.hasMany)('equipment-item', {
      'readOnly': true,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});