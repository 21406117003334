define("securitytrax-ember-ui/components/multi-step-form/forms/customer-central-station-information/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    formMode: 'view',
    pendingMonitoringCompany: null,

    init() {
      this._super(...arguments);

      Ember.defineProperty(this, 'centralStationFormOriginalModel', Ember.computed('grants.userGrants', this.store.getFullComputedWatcherStringByModelName('customerOrderInformation', {
        relationshipsToIgnore: {
          'monitoringCompany': true
        }
      }), this.store.getFullComputedWatcherStringByModelName('customerSystemInformation'), function () {
        return Ember.Object.create({
          customerSystemInformation: {
            model: this.customerSystemInformation,
            copyRelationMap: {
              dealerNumber: {
                readOnly: true
              },
              customerAbortCode: {
                doCopy: this.grants.getIndividualGrant('customer_abort_codes.view'),
                doValidate: this.grants.getIndividualGrant('customer_abort_codes.view'),
                doSave: this.grants.getIndividualGrant('customer_abort_codes.modify')
              },
              installCalendarEvent: {
                readOnly: true
              }
            }
          },
          customerOrderInformation: {
            model: this.customerOrderInformation,
            copyRelationMap: {
              monitoringCompany: {
                readOnly: true,
                relationships: {
                  monitoringCompanyType: {
                    readOnly: true
                  },
                  monitoringCompanyConfig: {
                    doCopy: this.grants.getIndividualGrant('monitoring_company_configs.manage'),
                    doValidate: false,
                    doSave: false
                  }
                }
              },
              repUser: {
                readOnly: true
              }
            }
          }
        });
      }));
    },

    monitoringCompany: Ember.computed(function () {
      return this.monitoringCompanyTask.perform();
    }),
    monitoringCompanyTask: (0, _emberConcurrency.task)(function* () {
      var orderInfo = this.grants.getIndividualGrant('customer_order_information.view') ? yield this.get('customerOrderInformation') : null;
      return orderInfo ? yield orderInfo.get('monitoringCompany') : null;
    }),
    centralStationFormSteps: Ember.computed('pendingMonitoringCompany', function () {
      var monitoringCompany = this.pendingMonitoringCompany || this.get('customerOrderInformation.monitoringCompany');
      var monitoringCompanyTypeId = monitoringCompany.get('monitoringCompanyType.id');
      var componentPath = 'multi-step-form/forms/customer-central-station-information/monitoring-companies/monitoring-company-type-' + monitoringCompanyTypeId;

      if (['2', '8', '11', '28', '41'].includes(monitoringCompanyTypeId)) {
        componentPath = 'multi-step-form/forms/customer-central-station-information/monitoring-companies/stages-monitoring-company-type';
      } else if (['18', '12', '13', '36', '39', '42'].includes(monitoringCompanyTypeId)) {
        componentPath = 'multi-step-form/forms/customer-central-station-information/monitoring-companies/bold-monitoring-company-type';
      } else {
        if (!Ember.getOwner(this).hasRegistration('component:' + componentPath + '/component')) {
          componentPath = 'multi-step-form/forms/customer-central-station-information/monitoring-companies/monitoring-company-type-default';
        }
      }

      if (this.toolbox) {
        componentPath += '/toolbox';
      }

      return Ember.Object.create({
        details: Ember.Object.create({
          stepNameKey: 'details',
          title: this.intl.t('common.columns.details'),
          stepComponentPath: componentPath
        })
      });
    }),
    actions: {
      setFormMode(formMode) {
        this.set('formMode', formMode);
      },

      updateCentralStationForm() {
        Ember.notifyPropertyChange(this, 'centralStationFormSteps');
      },

      updatePendingMonitoringCompany(monitoringCompany) {
        this.set('pendingMonitoringCompany', monitoringCompany);
      }

    }
  });

  _exports.default = _default;
});