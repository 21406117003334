define("securitytrax-ember-ui/validations/credit-bureau", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateObject = {
        name: {
          presence: this.customMessageOrDefault(data, 'creditBureau-name')
        },
        creditBureauType: {
          presence: this.customMessageOrDefault(data, 'creditBureau-creditBureauType')
        },
        bureau: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('creditBureau-bureau')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'creditBureau-bureau');
            }
          }
        },
        product: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('creditBureau-product')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'creditBureau-product');
            }
          }
        },
        service: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('creditBureau-service')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'creditBureau-service');
            }
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject, {
        fullMessages: this.prependProperty(data)
      });
      return this.outputFormatting(validationData, 'creditBureau');
    }

  });

  _exports.default = _default;
});