define("securitytrax-ember-ui/components/nav/left-navs/user-left-nav/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WlOUSgB8",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[\"users.information\",[35,2,[\"id\"]]],[[\"class\"],[\"user-information-link\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[\"icn-user\"],null]],[2,\"\\n    \"],[1,[30,[36,1],[\"pages.users.user.title\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,3],[\"users.login-information\",[35,2,[\"id\"]]],[[\"class\"],[\"user-information-link\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[\"icn-keypad\"],null]],[2,\"\\n    \"],[1,[30,[36,1],[\"pages.users.login_information.title\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,3],[\"users.permissions\",[35,2,[\"id\"]]],[[\"class\"],[\"user-permission-link\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[\"icn-services-partial\"],null]],[2,\"\\n    \"],[1,[30,[36,1],[\"pages.users.permissions.title\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,3],[\"users.human-resources\",[35,2,[\"id\"]]],[[\"class\"],[\"user-human-resources-link\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[\"icn-badge\"],null]],[2,\"\\n    \"],[1,[30,[36,1],[\"pages.users.human_resources.title\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,3],[\"users.history\",[35,2,[\"id\"]]],[[\"class\"],[\"user-history-link\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[\"icn-schedule\"],null]],[2,\"\\n    \"],[1,[30,[36,1],[\"pages.users.history.title\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,5],[[30,[36,4],[\"calendar_events.view\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[\"users.time-off\",[35,2,[\"id\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],[\"icn-date\"],null]],[2,\"\\n        \"],[1,[30,[36,1],[\"pages.users.time_off.title\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"svg-icon\",\"t\",\"user\",\"nav/left-nav-link\",\"has-grants\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/nav/left-navs/user-left-nav/template.hbs"
    }
  });

  _exports.default = _default;
});