define("securitytrax-ember-ui/components/lists/data-list/column-formatters/actionable-icon/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      if (!this.tagName) {
        this.set('tagName', '');
      }

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});