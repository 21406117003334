define("securitytrax-ember-ui/components/multi-step-form/forms/administration/organization/edit-office-location/equipment-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+tThyy9o",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],null,[[\"config\",\"modalButtons\"],[[35,4],[35,2,[\"currentStepData\",\"buttons\"]]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,3],null,[[\"multiStepForm\",\"formMode\",\"recipient\",\"filterStorageKey\"],[[35,2],[35,1],[35,0],\"locationEquipmentDetails\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"recipient\",\"formMode\",\"multiStepForm\",\"multi-step-form/forms/administration/inventory/edit-equipment-details\",\"progressBarConfig\",\"multi-step-form/progress-bar\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/organization/edit-office-location/equipment-details/template.hbs"
    }
  });

  _exports.default = _default;
});