define("securitytrax-ember-ui/components/multi-step-form/forms/invoices/invoice/payments/view-processing-info/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/invoices/base", "moment", "ember-concurrency"], function (_exports, _base, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var ViewPaymentProcessingInfo = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var overrideConfig = Ember.Object.create({
      customerPayment: {
        model: this.args.multiStepForm.formOriginalModelsGetVal('customerPayment.model')
      }
    });
    var extraValidationData = {
      noValidate: ['customerPayment-receiptEmails', 'customerPayment-customerPaymentType', 'customerPayment-amount']
    };
    yield this.args.multiStepForm.validateSyncSaveAll({
      extraValidationData: extraValidationData,
      overrideOriginalModelsConfig: overrideConfig,
      notificationNamespace: 'modal'
    });
    buttonPromise.then(() => {
      this.args.transitionBackToParent();
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
    });
  }), _dec5 = Ember._action, (_class = (_temp = class ViewPaymentProcessingInfo extends _base.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "paymentService", _descriptor, this);

      _initializerDefineProperty(this, "grants", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "modifyCustomerPayment", _descriptor4, this);
    }

    get hiddenProgressBarSteps() {
      return ['gettingStarted'];
    }

    get disabledProgressBarSteps() {
      return [];
    }

    get statusUpdated() {
      return (0, _moment.default)(this.args.multiStepForm.formOriginalModelsGetVal('customerPayment.model.processingStatusSubmitted')).format('MM/DD/YYYY h:mm A zz');
    }

    setupButtons() {
      var buttonText;
      var buttons = [];
      var currentPayment = this.args.multiStepForm.formOriginalModelsGetVal('customerPayment.model'); //Made the first condition impossible to force people to use a refund receipt instead of this button

      if (0 && currentPayment.processingStatus && currentPayment.processingStatus.toLowerCase() === 'complete' && this.grants.getIndividualGrant('customer_payments.refund') && !currentPayment.responseMeta.has_refund_reciept) {
        buttonText = this.intl.t('common.actions.refund');
      } else if (currentPayment.processingStatus && currentPayment.processingStatus.replace(/\s+/g, '').toLowerCase() === 'pendingsettlement' && this.grants.getIndividualGrant('customer_payments.void')) {
        var paymentTypeName = currentPayment.customerPaymentType && currentPayment.customerPaymentType.get('name') ? currentPayment.customerPaymentType.get('name').toLowerCase() : '';
        var achVoid = ['ach', 'checking', 'saving'];

        if (achVoid.includes(paymentTypeName)) {
          if (currentPayment.processedAt && (0, _moment.default)().isSame((0, _moment.default)(currentPayment.processedAt), 'd')) {
            buttonText = this.intl.t('common.actions.void');
          }
        } else {
          buttonText = this.intl.t('common.actions.void');
        }
      }

      if (buttonText) {
        buttons.push({
          text: buttonText,
          classNamesString: 'st-btn st-btn-warn',
          isDelete: true,
          click: () => {
            this.args.multiStepForm.registerStepData(this.args.multiStepForm.currentStepNameKey.replace('viewProcessingInformation', 'confirmRefundVoid'), 'currentVoidingOrRefundingCustomerPayment', currentPayment);
            this.args.multiStepForm.currentStepNameKeySetVal(this.args.multiStepForm.currentStepNameKey.replace('viewProcessingInformation', 'confirmRefundVoid'));
          }
        });
      }

      if (!this.args.singlePage) {
        buttons.push({
          text: this.intl.t('common.actions.back'),
          classNamesString: 'st-btn st-btn-secondary',
          click: () => {
            this.args.setCustomerPayment(null);
            this.args.multiStepForm.currentStepNameKeySetVal('listPayments::payments');
          }
        });
      }

      if (this.grants.getIndividualGrant('customer_payments.modify')) {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          classNamesString: 'st-btn st-btn-primary',
          click: buttonPromise => {
            return this.modifyCustomerPayment.perform(buttonPromise);
          }
        });
      }

      this.args.multiStepForm.registerStepData(this.args.multiStepForm.currentStepNameKey, 'buttons', buttons);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "paymentService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "modifyCustomerPayment", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupButtons", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "setupButtons"), _class.prototype)), _class));
  _exports.default = ViewPaymentProcessingInfo;
});