define("securitytrax-ember-ui/models/base_models/directory-user", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    fname: (0, _model.attr)('string', {
      'readOnly': false
    }),
    lname: (0, _model.attr)('string', {
      'readOnly': false
    }),
    email: (0, _model.attr)('string', {
      'readOnly': false
    }),
    notes: (0, _model.attr)('string', {
      'readOnly': false
    }),
    cellPhone: (0, _model.attr)('string', {
      'readOnly': false
    }),
    homePhone: (0, _model.attr)('string', {
      'readOnly': false
    }),
    workPhone: (0, _model.attr)('string', {
      'readOnly': false
    }),
    userHierarchyTitle: (0, _model.belongsTo)('user-hierarchy-title', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    user: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false
    }),
    orgChartParent: (0, _model.belongsTo)('user', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    roles: (0, _model.hasMany)('role', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    userHierarchies: (0, _model.hasMany)('user-hierarchy', {
      'readOnly': true,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});