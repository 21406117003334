define("securitytrax-ember-ui/models/equipment-transfer", ["exports", "securitytrax-ember-ui/models/base_models/equipment-transfer"], function (_exports, _equipmentTransfer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _equipmentTransfer.default.extend({
    target: Ember.computed('targetUser', 'targetLocation', function () {
      return this.get('targetUser.id') > 0 ? this.targetUser : this.targetLocation;
    }),
    source: Ember.computed('sourceUser', 'sourceLocation', function () {
      return this.get('sourceUser.id') > 0 ? this.sourceUser : this.sourceLocation;
    })
  });

  _exports.default = _default;
});