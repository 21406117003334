define("securitytrax-ember-ui/components/multi-step-form/forms/accounting/edit-create-payment-method/method-layouts/manual/component", ["exports", "securitytrax-ember-ui/mixins/switchable-form-step"], function (_exports, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    tagName: '',
    intl: Ember.inject.service(),
    usStates: Ember.inject.service(),
    sessionData: Ember.inject.service(),

    init() {
      this._super(...arguments);

      var defaultCustomerBillingId = this.get('customer.defaultCustomerBilling.id');
      var isDefault = defaultCustomerBillingId === this.multiStepForm.formCopiedModelsGetVal('customerBilling.model.id');
      this.multiStepForm.formCopiedModelsSetVal('customerBilling.isBillingDefault', isDefault);
      this.multiStepForm.formCopiedModelsSetVal('customerBilling.model.billAddressSame', !this.isNewRecord ? this.multiStepForm.formCopiedModelsGetVal('customerBilling.model.billAddressSame') : true);
      this.multiStepForm.formCopiedModelsSetVal('customerBilling.model.requestMeta', {
        use_default: false
      });
    },

    showAddressFields: Ember.computed('multiStepForm.{formCopiedModels.customerBilling.model.billAddressSame,formCopiedModels.customerBilling.model.requestMeta.use_default}', function () {
      var usePrimary = this.multiStepForm.formCopiedModelsGetVal('customerBilling.model.billAddressSame');
      return !usePrimary && !this.multiStepForm.formCopiedModelsGetVal('customerBilling.model.requestMeta.use_default');
    }),
    monthDays: Ember.computed(function () {
      var days = [];
      var i = 1;

      while (i < 32) {
        days.push({
          name: i,
          value: i
        });
        i++;
      }

      return days;
    })
  });

  _exports.default = _default;
});