define("securitytrax-ember-ui/models/base_models/invoice-line-item", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    quantity: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    unitPrice: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    grossAmount: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 2
    }),
    salesTaxRate: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 5
    }),
    salesTaxAmount: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 5
    }),
    totalAmount: (0, _model.attr)('number-precision', {
      'readOnly': false,
      'precision': 5
    }),
    description: (0, _model.attr)('string', {
      'readOnly': false
    }),
    comboId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    billableItemCode: (0, _model.attr)('string', {
      'readOnly': true
    }),
    billableItemDescription: (0, _model.attr)('string', {
      'readOnly': true
    }),
    sort: (0, _model.attr)('number', {
      'readOnly': false
    }),
    status: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['active', 'deleted']
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    invoiceBillableItem: (0, _model.belongsTo)('invoice-billable-item', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    invoice: (0, _model.belongsTo)('invoice', {
      'readOnly': false,
      'readOnlyUpdate': true
    }),
    recurringInvoice: (0, _model.belongsTo)('recurring-invoice', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});