define("securitytrax-ember-ui/components/pages/administration/company-settings/message-templates/component", ["exports", "@glimmer/component", "ember-concurrency", "securitytrax-ember-ui/mixed-in-classes/table-column-sort"], function (_exports, _component, _emberConcurrency, _tableColumnSort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var MessageTemplates = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember.computed, _dec17 = (0, _emberConcurrency.task)(function* () {
    var messageType = this.args.messageType === 'customer' ? 1 : 2;
    var defaultFilter;

    if (yield this.enhancedFeatures.hasFeature('sms')) {
      defaultFilter = "message_type = ".concat(messageType);
    } else {
      defaultFilter = "message_type = ".concat(messageType, " and delivery_protocol != 'sms'");
    }

    var newFilters = yield this.stFiltersService.getFiltersByGroupKey('companyMessageTemplates' + this.args.messageType, [defaultFilter]);
    var messageTemplates = yield this.store.query('message', {
      filter: newFilters,
      limit: this.limit,
      page: this.page,
      sort: this.TableColumnSort.stSortString,
      count: 1
    });
    Ember.set(this, 'totalCount', messageTemplates.meta.record_count);
    return messageTemplates;
  }), _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, _dec23 = Ember._action, _dec24 = Ember._action, (_class = (_temp = class MessageTemplates extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "enhancedFeatures", _descriptor3, this);

      _initializerDefineProperty(this, "stFiltersService", _descriptor4, this);

      _initializerDefineProperty(this, "messageTemplate", _descriptor5, this);

      _initializerDefineProperty(this, "messageTemplateFormStarted", _descriptor6, this);

      _initializerDefineProperty(this, "messageTemplateFormMode", _descriptor7, this);

      _initializerDefineProperty(this, "formTitleName", _descriptor8, this);

      _initializerDefineProperty(this, "formTitleID", _descriptor9, this);

      _initializerDefineProperty(this, "column", _descriptor10, this);

      _initializerDefineProperty(this, "ascending", _descriptor11, this);

      _initializerDefineProperty(this, "limit", _descriptor12, this);

      _initializerDefineProperty(this, "page", _descriptor13, this);

      _initializerDefineProperty(this, "totalCount", _descriptor14, this);

      _initializerDefineProperty(this, "messageTemplateStatus", _descriptor15, this);

      _initializerDefineProperty(this, "getMessagesTask", _descriptor16, this);

      var sortableColumns = [{
        name: this.intl.t('common.columns.name'),
        value: 'name'
      }, {
        name: this.intl.t('pages.administration.content_management.message_templates.delivery_type'),
        value: 'deliveryProtocol'
      }];
      this.TableColumnSort = new _tableColumnSort.default(...arguments, sortableColumns);
    }

    get cardTitle() {
      return this.intl.t("pages.administration.content_management.message_templates.".concat(this.args.messageType, "_message_title"));
    }

    get messages() {
      return this.getMessagesTask.perform();
    }

    get messageTemplateFormSteps() {
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.messageTemplateFormMode === 'create' ? this.intl.t('pages.administration.content_management.message_templates.generic_create', {
            'templateType': this.intl.t("common.columns.".concat(this.args.messageType))
          }) : this.intl.t('pages.administration.content_management.message_templates.generic_edit', {
            'templateName': this.formTitleName,
            'id': this.formTitleID
          }),
          stepComponentPath: 'multi-step-form/forms/administration/content-management/edit-message-template'
        })
      });
    }

    get messageTemplateFormOriginalModels() {
      return Ember.Object.create({
        message: {
          model: this.messageTemplate,
          copyRelationMap: {
            messageType: {
              doCopy: true,
              doSave: false
            }
          }
        }
      });
    }

    updateFormTitleName(row) {
      this.formTitleName = row.name;
    }

    updateFormTitleID(row) {
      this.formTitleID = row.id;
    }

    updateList() {
      Ember.setProperties(this, {
        column: null,
        ascending: null
      });
      Ember.notifyPropertyChange(this, 'messages');
    }

    sortList(column, ascending) {
      this.TableColumnSort.sortPaginatedList(column, ascending);
      Ember.notifyPropertyChange(this, 'messages');
    }

    mobileSortList(column) {
      this.TableColumnSort.sortMobilePaginatedList(column.value);
      Ember.notifyPropertyChange(this, 'messages');
    }

    filterSearch(setPage = true) {
      if (setPage) {
        Ember.set(this, 'page', 1);
      }

      Ember.notifyPropertyChange(this, 'messages');
    }

    clearFilters() {
      Ember.setProperties(this, {
        page: 1,
        messageTemplateStatus: undefined
      });
      Ember.notifyPropertyChange(this, 'messages');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "enhancedFeatures", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stFiltersService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "messageTemplate", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "messageTemplateFormStarted", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "messageTemplateFormMode", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'create';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "formTitleName", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "formTitleID", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "column", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "ascending", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "limit", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 50;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "totalCount", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "messageTemplateStatus", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "messages", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "messages"), _class.prototype), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "getMessagesTask", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateFormTitleName", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "updateFormTitleName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateFormTitleID", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "updateFormTitleID"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateList", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "updateList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortList", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "sortList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mobileSortList", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "mobileSortList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterSearch", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "filterSearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilters", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilters"), _class.prototype)), _class));
  _exports.default = MessageTemplates;
});