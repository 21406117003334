define("securitytrax-ember-ui/components/pages/administration/company-settings/content-management/invoice-quote-templates/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/pages/administration/company-settings/content-management/templates-shared"], function (_exports, _emberConcurrency, _templatesShared) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var InvoiceQuoteTemplates = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember.computed, _dec7 = (0, _emberConcurrency.task)(function* () {
    return yield this.getTemplatesByType.perform('3', this.setTemplateRelationData);
  }), _dec8 = (0, _emberConcurrency.task)(function* (template) {
    var templateFieldDefaults = yield template.get('templateFieldDefaults');
    var _fieldDefaults = {
      invoice_line_items: []
    };
    templateFieldDefaults.forEach(fieldDefault => {
      var typeColumn = fieldDefault.get('templateTypeColumn');
      _fieldDefaults[typeColumn.get('columnName')] = fieldDefault.get('value');
    });
    var lineItems = templateFieldDefaults.filterBy('templateTypeColumn.columnName', 'invoice_line_items');
    template._fieldDefaults = _fieldDefaults;
    template._fieldDefaults['total_items'] = lineItems.length;
    template._fieldDefaults['total_quantity'] = 0;
    lineItems.forEach(item => {
      var itemParsed = JSON.parse(item.get('value'));
      template._fieldDefaults['total_quantity'] += Number(itemParsed.quantity);
    });
  }), (_class = (_temp = class InvoiceQuoteTemplates extends _templatesShared.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "invoiceQuoteTemplateFormMode", _descriptor, this);

      _initializerDefineProperty(this, "invoiceQuoteTemplate", _descriptor2, this);

      _initializerDefineProperty(this, "invoiceQuoteTemplateFormStarted", _descriptor3, this);

      _initializerDefineProperty(this, "templateList", _descriptor4, this);

      _initializerDefineProperty(this, "stFilterGroupKey", _descriptor5, this);

      _initializerDefineProperty(this, "getInvoiceQuoteTemplatesTask", _descriptor6, this);

      _initializerDefineProperty(this, "setTemplateRelationData", _descriptor7, this);
    }

    get expireOptions() {
      return this.args.model.templateTypeColumns.findBy('columnName', 'expires').get('options');
    }

    get invoiceQuoteTemplates() {
      return this.getInvoiceQuoteTemplatesTask.perform();
    }

    get invoiceQuoteTemplateFormSteps() {
      var intl = this.intl;
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: intl.t("common.forms.descriptive_title_".concat(this.invoiceQuoteTemplateFormMode), {
            name: this.invoiceQuoteTemplateFormMode === 'create' ? intl.t('pages.administration.content_management.invoice_quote_templates.label') : this.formTitle.name,
            id: this.formTitle.id
          }),
          stepComponentPath: 'multi-step-form/forms/administration/content-management/edit-invoice-quote-template'
        })
      });
    }

    get invoiceQuoteTemplateFormOriginalModels() {
      return Ember.Object.create({
        template: {
          model: this.invoiceQuoteTemplate,
          copyRelationMap: {
            templateFieldDefaults: {
              relationships: {
                templateTypeColumn: {
                  readOnly: true
                }
              }
            }
          }
        }
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "invoiceQuoteTemplateFormMode", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'edit';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "invoiceQuoteTemplate", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "invoiceQuoteTemplateFormStarted", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "templateList", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'invoiceQuoteTemplates';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "stFilterGroupKey", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'companyInvoiceQuoteTemplates';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "invoiceQuoteTemplates", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "invoiceQuoteTemplates"), _class.prototype), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "getInvoiceQuoteTemplatesTask", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "setTemplateRelationData", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = InvoiceQuoteTemplates;
});