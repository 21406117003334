define("securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/adc/re-usable-forms/service-plan/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    adcEvents: Ember.inject.service('events/adc-events'),

    getInAddOnArrayCount(element) {
      var count = 0;
      var addOns = this.get('customerInformation.service_plan_info.addons');

      if (addOns && addOns.includes(element)) {
        this.get('customerInformation.service_plan_info.addons.[]').forEach(addOn => {
          if (addOn == element) {
            count++;
          }
        });
      }

      return count;
    },

    addOnArray: Ember.computed('customerInformation.service_plan_info.addons.[]', function () {
      var arr = [];
      var addOns = this.get('customerInformation.service_plan_info.addons');

      if (addOns) {
        addOns.forEach(addOn => {
          arr[addOn] = true;
        });
      }

      return arr;
    }),
    Video24x7PerSVR: Ember.computed('addOnArray.[]', function () {
      return {
        name: this.intl.t('pages.customers.system.interactiveServicesProvider.addOns.videoMonitoring.video24x7'),
        count: this.getInAddOnArrayCount('Video24x7PerSVR')
      };
    }),
    TwoFiftyMBExtraVideoStorage: Ember.computed('addOnArray.[]', function () {
      return {
        name: this.intl.t('pages.customers.system.interactiveServicesProvider.addOns.videoMonitoring.standardVideoExpansionPacks'),
        count: this.getInAddOnArrayCount('TwoFiftyMBExtraVideoStorage')
      };
    }),
    ImageSensorExtraUploads: Ember.computed('addOnArray.[]', function () {
      return {
        name: this.intl.t('pages.customers.system.interactiveServicesProvider.addOns.images.extraImageEvents'),
        count: this.getInAddOnArrayCount('ImageSensorExtraUploads') * 20
      };
    }),
    FiveNormalActivitySensors: Ember.computed('addOnArray.[]', function () {
      return {
        name: this.intl.t('pages.customers.system.interactiveServicesProvider.addOns.remoteMonitoringControl.additionalSensors'),
        count: this.getInAddOnArrayCount('FiveNormalActivitySensors') * 5
      };
    }),
    CommercialVideo8Expansion: Ember.computed('addOnArray.[]', function () {
      return {
        name: this.intl.t('pages.customers.system.interactiveServicesProvider.addOns.videoMonitoring.commercialVideo8Expansions'),
        count: this.getInAddOnArrayCount('CommercialVideo8Expansion')
      };
    }),
    CommercialVideo16Expansion: Ember.computed('addOnArray.[]', function () {
      return {
        name: this.intl.t('pages.customers.system.interactiveServicesProvider.addOns.videoMonitoring.commercialVideo16Expansions'),
        count: this.getInAddOnArrayCount('CommercialVideo16Expansion')
      };
    }),
    AccessControlDoors: Ember.computed(function () {
      return {
        name: this.intl.t('pages.customers.system.interactiveServicesProvider.addOns.smarterAccessControl.accessControlDoors'),
        count: this.getInAddOnArrayCount('AccessControlDoors')
      };
    }),
    AccessControlPlusDoors: Ember.computed(function () {
      return {
        name: this.intl.t('pages.customers.system.interactiveServicesProvider.addOns.smarterAccessControl.accessControlPlusDoors'),
        count: this.getInAddOnArrayCount('AccessControlPlusDoors')
      };
    }),
    addOns: Ember.computed('customerInformation.service_plan_info.addons.[]', function () {
      var addOns = [];
      var addOnFeatures = this.get('customerInformation.service_plan_info.addons');
      var dropdownAddOns = ['Video24x7PerSVR', 'TwoFiftyMBExtraVideoStorage', 'ImageSensorExtraUploads', 'FiveNormalActivitySensors', 'CommercialVideo8Expansion', 'CommercialVideo16Expansion', 'AccessControlDoors', 'AccessControlPlusDoors'];
      var packageOption = this.adcPackages.findBy('id', this.customerInformation.service_plan_info.package_id);
      var includedAddons = packageOption && packageOption.included_features ? packageOption.included_features : [];
      var includedAddonsMap = includedAddons.map(function (addon) {
        return addon.feature;
      });

      if (addOnFeatures) {
        addOnFeatures.forEach(addOn => {
          if (!dropdownAddOns.includes(addOn)) {
            addOns.pushObject(addOn.replace(/([A-Z])/g, ' $1').trim());
          } else {
            var currAddOn = this.get(addOn);

            if (!addOns.includes(currAddOn.count + ' ' + currAddOn.name)) {
              addOns.pushObject(currAddOn.count + ' ' + currAddOn.name);
            }
          }
        });
      }

      includedAddonsMap.forEach(includedAddon => {
        addOns.pushObject(includedAddon);
      });
      return _lodash.default.uniq(addOns.map(addon => {
        return _lodash.default.startCase(addon);
      }));
    }),
    actions: {
      updateVideoPackage() {
        this.adcEvents.trigger('update-selected-video-package', this.customerInformation.service_plan_info.package_id);
      },

      updateAddons(property, id) {
        this.adcEvents.trigger('update-addons', property, id);
      }

    }
  });

  _exports.default = _default;
});