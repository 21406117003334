define("securitytrax-ember-ui/models/base_models/docusign-template", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    canBeUsedOnEnvelopes: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    templateId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    name: (0, _model.attr)('string', {
      'readOnly': false
    }),
    shared: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    password: (0, _model.attr)('string', {
      'readOnly': false
    }),
    description: (0, _model.attr)('string', {
      'readOnly': false
    }),
    lastModified: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    created: (0, _model.attr)('moment-date-time', {
      'readOnly': false
    }),
    pageCount: (0, _model.attr)('number', {
      'readOnly': false
    }),
    uri: (0, _model.attr)('string', {
      'readOnly': false
    }),
    folderName: (0, _model.attr)('string', {
      'readOnly': false
    }),
    folderId: (0, _model.attr)('string', {
      'readOnly': false
    }),
    folderUri: (0, _model.attr)('string', {
      'readOnly': false
    }),
    emailSubject: (0, _model.attr)('string', {
      'readOnly': false
    }),
    emailBlurb: (0, _model.attr)('string', {
      'readOnly': false
    }),
    signingLocation: (0, _model.attr)('string', {
      'readOnly': false
    }),
    enforceSignerVisibility: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    enableWetSign: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    allowMarkup: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    allowReassign: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    docusignDocuments: (0, _model.hasMany)('docusign-document', {
      'readOnly': true,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});