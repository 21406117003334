define("securitytrax-ember-ui/administration/company-settings/integrations/partner-companies/base-route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/has-page-title"], function (_exports, _authenticatedRouteMixin, _hasPageTitle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @classdesc
   * Base partner company sub-route.
   *
   * @class BaseRoute
   * @extends Ember.Route
   * @mixes AuthenticatedRouteMixin
   * @mixes mixins.HasPageTitle
   * @memberof leadCompanies
   */
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _hasPageTitle.default,
  /** @lends leadCompanies.BaseRoute# */
  {
    model() {
      return {
        leadCompany: this.modelFor('administration/company-settings/integrations/partner-companies')
      };
    },

    getPageTitle(model) {
      return model.leadCompany.get('name');
    },

    getRouteName() {
      return "pages.administration.integrations.leadCompanies.nav.".concat(this.routeName.split('.').pop());
    }

  });

  _exports.default = _default;
});