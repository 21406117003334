define("securitytrax-ember-ui/components/html/notifications/st-notification-manager/component", ["exports", "securitytrax-ember-ui/services/st-adc-notification-manager"], function (_exports, _stAdcNotificationManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @classdesc A container for the number of notifications that might be displayed within an application.
   *
   * @class NotificationManager
   * @extends Ember.Component
   * @memberof components
   */
  var StNotificationManager = Ember.Component.extend(
  /** @lends components.StNotificationManager# **/
  {
    classNames: ['notification-manager'],

    /**
     * The service the component listens to in order to add notifications.
     *
     * @type {Ember.Service}
     */
    StAdcNotificationManager: Ember.inject.service(),

    /**
     * A list of currently displayed notifications.
     *
     * @type {Ember.Array<AppNotificationType>}
     */
    notifications: Ember.A(),

    /**
     * Initializes the listener for adding notifications.
     *
     * @function
     */
    init() {
      this._super(...arguments);

      this.set('notifications', Ember.A());
      var StAdcNotificationManager = this.StAdcNotificationManager; // Listen for the service's trigger to know when to add a notification.

      var listenKey = _stAdcNotificationManager.ADD_NOTIFICATION_TRIGGER + '_' + this.namespace;
      StAdcNotificationManager.on(listenKey, this, '_eventCallback');
    },

    willDestroyElement() {
      this._super(...arguments);

      var StAdcNotificationManager = this.StAdcNotificationManager;
      var listenKey = _stAdcNotificationManager.ADD_NOTIFICATION_TRIGGER + '_' + this.namespace;
      StAdcNotificationManager.off(listenKey, this, '_eventCallback');
      this.set('notifications', Ember.A());
    },

    _eventCallback(notification) {
      this.notifications.pushObject(notification);
    },

    /** @ignore **/
    actions:
    /** @lends components.StNotificationManager# **/
    {
      /**
       * Removes a notification.
       *
       * @param {AppNotificationType} notification - The notification to close.
       */
      closeNotification(notification) {
        this.notifications.removeObject(notification);
      }

    }
  });
  var _default = StNotificationManager;
  /**
   * The object form of a notification.
   *
   * @typedef {Object} AppNotification
   *
   * @property {String} text - The notification text.
   * @property {String} type - The notification type.
   * @property {String} icon - The name of the icon the notification should display.
   */

  _exports.default = _default;
});