define("securitytrax-ember-ui/mixed-in-classes/adt", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var ADTMixin = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = (0, _emberConcurrency.task)(function* () {
    var grants = this.grants;
    var grantsData = yield (0, _emberConcurrency.hash)({
      customerModify: grants.getIndividualGrant('customers.modify'),
      orderInformationModify: grants.getIndividualGrant('customer_order_information.modify'),
      systemInformationModify: grants.getIndividualGrant('customer_system_information.modify'),
      agencyContactsView: grants.getIndividualGrant('agency_contacts.view'),
      agencyContactsModify: grants.getIndividualGrant('agency_contacts.modify'),
      customerContactsCreate: grants.getIndividualGrant('customer_contacts.create'),
      customerContactsModify: grants.getIndividualGrant('customer_contacts.modify'),
      customerBillingsCreate: grants.getIndividualGrant('customer_billings.create'),
      customerBillingsModify: grants.getIndividualGrant('customer_billings.modify'),
      customerEquipmentView: grants.getIndividualGrant('customer_equipment.view')
    });
    var hasGrants = grantsData.customerModify && grantsData.orderInformationModify && grantsData.systemInformationModify && grantsData.agencyContactsView && grantsData.agencyContactsModify && grantsData.customerContactsCreate && grantsData.customerContactsModify && grantsData.customerBillingsCreate && grantsData.customerBillingsModify && grantsData.customerEquipmentView;
    return hasGrants;
  }), _dec6 = (0, _emberConcurrency.task)(function* () {
    try {
      var centralStationService = this.centralStationService;
      var grants = this.grants;
      var hashData = yield (0, _emberConcurrency.hash)({
        systemInfoGrant: grants.getIndividualGrant('customer_system_information.view'),
        orderInfoGrant: grants.getIndividualGrant('customer_order_information.view'),
        csIntegrationGrant: grants.getIndividualGrant('customers.manage_central_station_integration'),
        customer: this.args.customer
      });
      var customerIsLead = hashData.customer.isLead;
      var adtData = {
        hasPreCheck: false,
        failedLoadAdtData: true
      };
      var monitoringCompanyTypeId = 0;

      if (hashData.systemInfoGrant && !customerIsLead) {
        var systemInfo = hashData.systemInfoGrant ? hashData.customer.get('customerSystemInformation') : null;

        if (hashData.orderInfoGrant) {
          var monitoringCompany = yield this.args.customer.get('customerOrderInformation.monitoringCompany');
          var monitoringCompanyType = monitoringCompany ? yield monitoringCompany.get('monitoringCompanyType') : null;
          monitoringCompanyTypeId = monitoringCompanyType ? monitoringCompanyType.id : null;
        }

        if (monitoringCompanyTypeId && monitoringCompanyTypeId == 1) {
          var centralStationId = systemInfo ? yield systemInfo.get('centralStationId') : null;
          var preCheck = hashData.csIntegrationGrant ? yield this.args.customer.get('adtPreCheck') : null;
          var code = preCheck ? yield preCheck.get('status.code') : null;

          if (centralStationId || preCheck && code === 'review_required' || code === 'proceed') {
            try {
              if (centralStationId) {
                adtData = yield (0, _emberConcurrency.hash)({
                  adtCustomerOrder: centralStationService.getData({
                    model: hashData.customer,
                    method: 'adt__getCreateUpdateOrderData',
                    responseKey: 'create_update_order_data'
                  }),
                  adtCustomerOrderStatus: centralStationService.getData({
                    model: hashData.customer,
                    method: 'adt__getOrderStatus',
                    responseKey: 'order_status'
                  })
                });
              } else {
                adtData = {
                  //calling getCreateUpdateOrderData to get defaults since we cant get them from monitoringCompanyConfig due to grants
                  adtCustomerOrder: yield centralStationService.getData({
                    model: hashData.customer,
                    method: 'adt__getCreateUpdateOrderData',
                    responseKey: 'create_update_order_data'
                  })
                };
              }

              if (!adtData.adtCustomerOrder.credit_holder_physical_address_data) {
                // need this here for shell
                adtData.adtCustomerOrder.credit_holder_physical_address_data = {};
              }

              if (!adtData.adtCustomerOrder.ny) {
                // need this here for shell
                adtData.adtCustomerOrder.ny = {};
              } else if (adtData.adtCustomerOrder.ny.estimated_completion_date === '') {
                adtData.adtCustomerOrder.ny.estimated_completion_date = null;
              }

              adtData.hasPreCheck = true;
              adtData.failedLoadAdtData = false;
            } catch (error) {
              (false && Ember.warn('failed to set adtData: ' + error, {
                id: 'mixins.forms.adt.setAdtData'
              }));
              this.stNotificationManager.addError(this.intl.t('pages.customers.system.failedContactAdt'));
              adtData.failedLoadAdtData = true;
            }
          } else {
            adtData = {
              hasPreCheck: false,
              failedLoadAdtData: false
            };
          }
        }
      }

      return adtData;
    } catch (error) {
      (false && Ember.warn('failed to get adtData: ' + error, {
        id: 'mixins.forms.adt'
      }));
    }
  }).drop().maxConcurrency(1), _dec7 = (0, _emberConcurrency.task)(function* () {
    try {
      var grants = this.grants;
      var systemInfoGrant = grants.getIndividualGrant('customer_system_information.view');
      var customerSystemInformation = systemInfoGrant ? yield this.args.customer.get('customerSystemInformation') : null;
      var centralStationId = customerSystemInformation ? yield customerSystemInformation.get('centralStationId') : 0;

      if (centralStationId != null) {
        return true;
      }

      return false;
    } catch (error) {
      (false && Ember.warn('error in adtIsShelledTask', {
        id: 'mixins.forms.adt.adtIsShelledTask'
      }));
      (false && Ember.warn(error, {
        id: 'mixins.forms.adt.adtIsShelledTask'
      }));
    }
  }), _dec8 = (0, _emberConcurrency.task)(function* (customer, adtData) {
    try {
      var centralStationService = this.centralStationService;
      yield customer.reload();
      var hashData2 = yield (0, _emberConcurrency.hash)({
        adtCustomerOrderStatus: centralStationService.getData({
          model: customer,
          method: 'adt__getOrderStatus',
          responseKey: 'order_status'
        }),
        customerSystemInformation: this.grants.getIndividualGrant('customer_system_information.view') ? customer.get('customerSystemInformation') : null
      });
      Ember.set(adtData, 'adtCustomerOrderStatus', hashData2.adtCustomerOrderStatus);

      if (hashData2.customerSystemInformation) {
        yield hashData2.customerSystemInformation.reload();
      }
    } catch (error) {
      (false && Ember.warn('failed to refreshCustomerAdtStatus' + error, {
        id: 'mixins.forms.adt'
      }));
      throw error;
    }
  }), _dec9 = (0, _emberConcurrency.task)(function* () {
    var adtCustomerInformation;
    var hashedData = yield (0, _emberConcurrency.hash)({
      customerAccountNumber: this.get('customer.customerSystemInformation.accountNum'),
      canRetrieve: this.centralStationService.getData({
        model: this.args.customer,
        method: 'adt__getDealersiteServiceCustomerInformationRetrievalIsPossible',
        responseKey: 'is_possible'
      })
    });

    if (!hashedData.customerAccountNumber || !hashedData.canRetrieve) {
      adtCustomerInformation = null;
    } else {
      try {
        adtCustomerInformation = yield this.centralStationService.getData({
          model: this.args.customer,
          method: 'adt__getCustomerInfo',
          responseKey: 'customer_info'
        });
      } catch (err) {
        (false && Ember.warn('Failed to get adtCustomerInformation: ' + err, {
          id: 'customers.information.adt.adtCustomerInformationTask'
        }));
        adtCustomerInformation = null;
      }
    }

    return adtCustomerInformation;
  }), _dec10 = (0, _emberConcurrency.task)(function* () {
    return yield this.centralStationService.getData({
      model: this.args.customer,
      method: 'adt__getAccountDetailsRetrievalIsPossible',
      responseKey: 'is_possible'
    });
  }), _dec11 = (0, _emberConcurrency.task)(function* () {
    try {
      var hashData = yield (0, _emberConcurrency.hash)({
        customer: this.args.customer,
        customerSystemInformation: this.args.customer.get('customerSystemInformation')
      });

      try {
        hashData['adtPreCheck'] = yield this.args.customer.get('adtPreCheck');
      } catch (error) {
        (false && Ember.warn(error, {
          id: 'multiStepForm.forms.system.adt.discrepancies.adtPreCheck'
        }));
      }

      try {
        yield hashData.customerSystemInformation.invokeAction('unlink_cs_account', {
          verb: 'POST',
          data: {
            data: {
              type: 'customer_system_information_action'
            }
          }
        }); /////////////////////////////////////////////////////////////////////////////////////////
        // need to reload data affected by unlink as well and remove precheck from store

        var adtData = yield this.adtData;
        yield (0, _emberConcurrency.all)([hashData.customer.reload(), hashData.customerSystemInformation.reload()]);
        hashData.customer.notifyPropertyChange('adtPreCheck');
        this.set('adtData', adtData);
      } catch (response) {
        this.stNotificationManager.addErrorMessages(response, {
          notificationNamespace: 'adtMegaFormModal'
        });
      }

      this.stNotificationManager.addSuccess(this.intl.t('common.notifications.unlink_successful'));
    } catch (error) {
      (false && Ember.warn(error, {
        id: 'multiStepForm.forms.system.adt.discrepancies.unlinkAdt'
      }));
    }
  }), (_class = (_temp = class ADTMixin extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "centralStationService", _descriptor, this);

      _initializerDefineProperty(this, "grants", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor4, this);

      _initializerDefineProperty(this, "monitoringCompanyHasNeededGrantsTask", _descriptor5, this);

      _initializerDefineProperty(this, "adtDataTask", _descriptor6, this);

      _initializerDefineProperty(this, "adtIsShelledTask", _descriptor7, this);

      _initializerDefineProperty(this, "refreshCustomerAdtStatus", _descriptor8, this);

      _initializerDefineProperty(this, "adtCustomerInformationTask", _descriptor9, this);

      _initializerDefineProperty(this, "adtShowDealerHubInfoTask", _descriptor10, this);

      _initializerDefineProperty(this, "unlinkAdt", _descriptor11, this);
    }

    get monitoringCompanyHasNeededGrants() {
      return this.monitoringCompanyHasNeededGrantsTask.perform();
    }

    get formProgressBar() {
      return this.adtOrderFormMode === 'create' ? {
        hideProgressBar: true
      } : this.progressBarConfig;
    }

    get adtData() {
      return this.adtDataTask.perform();
    }

    get adtIsShelled() {
      return this.adtIsShelledTask.perform();
    }

    get adtCustomerInformation() {
      return this.adtCustomerInformationTask.perform();
    }

    get adtShowDealerHubInfo() {
      return this.adtShowDealerHubInfoTask.perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "monitoringCompanyHasNeededGrantsTask", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "adtDataTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "adtIsShelledTask", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "refreshCustomerAdtStatus", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "adtCustomerInformationTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "adtShowDealerHubInfoTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "unlinkAdt", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ADTMixin;
});