define("securitytrax-ember-ui/helpers/if-or", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ifOr = ifOr;
  _exports.default = void 0;

  function ifOr(parameters) {
    var result = parameters.filter(parameter => parameter === true);
    return result.length > 0;
  }

  var _default = Ember.Helper.helper(ifOr);

  _exports.default = _default;
});