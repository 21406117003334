define("securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/interactive-services-providers/interactive-services-provider-type-3/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms/customer-interactive-services-provider-information/base-interactive-services-provider/component"], function (_exports, _emberConcurrency, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    ispService: Ember.inject.service(),
    grants: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    screen: Ember.inject.service(),
    ispStatus: Ember.computed('customerSystemInformation.interactiveServicesId', 'customerOrderInformation.interactiveServicesProvider.id', function () {
      return !!this.get('customerSystemInformation.interactiveServicesId') && !!this.get('customerOrderInformation.interactiveServicesProvider.id');
    }),
    connectionFailed: Ember.computed('interactiveServicesProvider.isConnected', function () {
      return this.connectionFailedTask.perform();
    }),
    connectionFailedTask: (0, _emberConcurrency.task)(function* () {
      var isp = yield this.interactiveServicesProvider;
      return isp && !isp.get('isConnected');
    }),
    pencilIconMissing: Ember.computed('connectionFailed', 'ispStatus', function () {
      return this.pencilIconMissingTask.perform();
    }),
    pencilIconMissingTask: (0, _emberConcurrency.task)(function* () {
      var connectionFailed = yield this.connectionFailed;

      if (connectionFailed === false && this.ispStatus !== false) {
        return 'no-edit';
      } else {
        return '';
      }
    }),
    shellAccountButton: Ember.computed(function () {
      return {
        label: this.intl.t('pages.customers.system.shellAccount.label'),
        action: () => {
          this.send('startIspForm');
        }
      };
    }),
    actions: {
      startIspForm() {
        return this.router.transitionTo(this.subroutePath + '.manage-alula');
      },

      openAlulaConnect() {
        var newWin = window.open("https://alulaconnect.com/en/d/accounts/".concat(this.customerSystemInformation.interactiveServicesId, "/edit"));

        if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
          this.stNotificationManager.addWarningKey('common.notifications.popup_blocked');
        }
      }

    }
  });

  _exports.default = _default;
});