define("securitytrax-ember-ui/validations/send", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      _validate.default.validators.emailList = function (value) {
        if (!value) {
          return false;
        }

        var invalidEmails = value.filter(email => {
          if (!email || !email.match(/.*@.*\..*/)) {
            return true;
          }
        });

        if (invalidEmails.length > 0) {
          return data.intlService.t('common.validations.emailList', {
            'invalidEmails': invalidEmails.join(', ')
          });
        }

        return null;
      };

      var validateObject = {
        recipients: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('recipients')) {
              return false;
            } else {
              return {
                message: '^' + data.intlService.t('common.validations.presence', {
                  propertyLabel: data.intlService.t('pages.customers.send.send_to_emails')
                })
              };
            }
          },
          emailList: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('recipients')) {
              return false;
            }

            if (this.recipients && this.recipients.length > 0) {
              return this.customMessageOrDefault(data, 'send-email');
            } else {
              return false;
            }
          }
        },
        defaultToAddress: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('defaultToAddress')) {
              return false;
            } else {
              return {
                message: '^' + data.intlService.t('common.validations.presence', {
                  propertyLabel: data.intlService.t('common.columns.email')
                })
              };
            }
          },
          emailList: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('defaultToAddress')) {
              return false;
            }

            if (this.defaultToAddress && this.defaultToAddress.length > 0) {
              return this.customMessageOrDefault(data, 'send-email');
            } else {
              return false;
            }
          }
        },
        appointment: {
          presence: () => {
            if ('extraValidationData' in data && 'appointment' in data.extraValidationData) {
              return this.customMessageOrDefault(data, 'appointment');
            } else {
              return false;
            }
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      return this.outputFormatting(validationData, 'send');
    }

  });

  _exports.default = _default;
});