define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/edit-create-credit-bureau/security-equity-partners/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hY/zLwkm",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"grid-x grid-padding-x\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"cell medium-6\"],[12],[2,\"\\n        \"],[8,\"multi-step-form/switchable-inputs/text-input\",[],[[\"@formMode\",\"@value\",\"@label\"],[[34,0],[34,1,[\"formCopiedModels\",\"creditBureau\",\"model\",\"creditBureauConfig\",\"username\"]],[30,[36,2],[\"pages.administration.integrations.credit_bureaus.dealer_account_number\"],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"cell medium-6\"],[12],[2,\"\\n        \"],[8,\"multi-step-form/switchable-inputs/password-mask-input\",[],[[\"@formMode\",\"@label\",\"@hasSavedValue\",\"@updateValue\"],[[34,0],[30,[36,2],[\"common.columns.password\"],null],[34,1,[\"formCopiedModels\",\"creditBureau\",\"model\",\"creditBureauConfig\",\"responseMeta\",\"attribute_has_saved_value\",\"password\"]],[30,[36,4],[[32,0],[30,[36,3],[[35,1,[\"formCopiedModels\",\"creditBureau\",\"model\",\"creditBureauConfig\",\"password\"]]],null]],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"formMode\",\"multiStepForm\",\"t\",\"mut\",\"action\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/edit-create-credit-bureau/security-equity-partners/template.hbs"
    }
  });

  _exports.default = _default;
});