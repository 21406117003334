define("securitytrax-ember-ui/components/lists/data-list/body-wrap/component", ["exports", "ember-data", "securitytrax-ember-ui/utils/obj-helpers", "ember-concurrency"], function (_exports, _emberData, _objHelpers, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    loaderTagName: undefined,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        emptyListText: this.intl.t('common.notifications.generic_empty_list')
      });
    },

    /**
     * The tag name defaults to empty. t
     *
     * @function
     * @returns {string}
     */
    tagName: 'div',

    /**
     * Should the body-wrap loop over the list or just return the list?
     */
    returnFullList: false,

    /**
     * Returns the default text for an empty list
     *
     * @function
     * @returns {string}
     */
    emptyListText: undefined,
    checkGroupItemsEmpty: false,
    isGroup: false,
    watchListData: (0, _emberConcurrency.task)(function* () {
      var listData = yield this.listData;
      var listDataLength = listData ? typeof listData.get === 'function' ? listData.get('length') : listData.length : 0;

      if (listData && listDataLength > 0) {
        var firstObject = listData.objectAt(0);

        if (firstObject.hasOwnProperty('items')) {
          this.set('isGroup', true);
        }
      }
    }).on('init'),

    /**
     * Indicates if the list is empty or not.
     *
     * @function
     * @returns {boolean}
     */
    isEmptyList: Ember.computed('listData.[]', 'isListDataLoading', 'groupedEmptyListOverride', function () {
      var [count, isLoading] = (0, _objHelpers.getObjProperties)(this, 'listData.length', 'isListDataLoading');

      if (this.groupedEmptyListOverride) {
        //Pass this in if you have grouped data (like grouped tasks) and you need empty text to appear if all groups are empty
        return !isLoading;
      }

      return !isLoading && (count === 0 || typeof count === 'undefined');
    }),

    /**
     * Indicates the list data is a promise array that is still loading.
     *
     * @function
     * @returns {boolean}
     */
    listDataLoaded: Ember.computed('listData', 'listData.isFulfilled', function () {
      // Is the data a promise array?
      var data = this.listData;

      if (data instanceof _emberData.default.PromiseArray) {
        // Return fulfilled status.
        return Ember.get(data, 'isFulfilled');
      } // Must be fulfilled.


      return true;
    })
  });

  _exports.default = _default;
});