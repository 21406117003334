define("securitytrax-ember-ui/models/invoice-term", ["exports", "securitytrax-ember-ui/models/base_models/invoice-term"], function (_exports, _invoiceTerm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _invoiceTerm.default.extend({});

  _exports.default = _default;
});