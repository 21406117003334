define("securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-payment-method/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KlyYDXXm",
    "block": "{\"symbols\":[\"multiStepForm\",\"MultiStepFormComponent\"],\"statements\":[[8,\"html/st-await\",[],[[\"@useSpinner\",\"@booleanLoaded\"],[false,[32,0,[\"initData\",\"isIdle\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"multi-step-form\",[],[[\"@formSteps\",\"@formOriginalModels\",\"@usesModal\",\"@formStarted\",\"@changeFormStarted\",\"@onAfterSave\",\"@onDelete\"],[[32,0,[\"paymentMethodForm\"]],[32,0,[\"paymentMethodFormOriginalModels\"]],true,[32,0,[\"paymentMethodFormStarted\"]],[30,[36,1],[[32,0],[30,[36,2],[[32,0,[\"paymentMethodFormStarted\"]]],null]],null],[30,[36,1],[[32,0],\"refreshModel\"],null],[30,[36,1],[[32,0],\"refreshModel\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"modals/st-paper\",[],[[\"@classNames\",\"@modalButtons\",\"@title\",\"@dialogOpen\",\"@modalNamespace\",\"@multiStepForm\",\"@newWindowLink\",\"@customer\",\"@fullscreen\",\"@onClose\"],[\"st-modal--full billing-method-modal\",[32,1,[\"currentStepData\",\"buttons\"]],[32,1,[\"currentStepData\",\"title\"]],[32,1,[\"formStarted\"]],\"billingModal\",[32,1],true,[32,0,[\"customer\"]],true,[30,[36,1],[[32,0],\"transitionBackToParentRoute\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[8,[32,2],[],[[\"@multiStepForm\",\"@formMode\",\"@customer\",\"@billTypes\",\"@customerBilling\",\"@transitionBackToParent\"],[[32,1],[32,0,[\"formMode\"]],[34,0],[32,0,[\"billTypes\"]],[32,0,[\"customerBilling\"]],[30,[36,1],[[32,0],\"transitionBackToParentRoute\"],null]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"customer\",\"action\",\"mut\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-payment-method/template.hbs"
    }
  });

  _exports.default = _default;
});