define("securitytrax-ember-ui/components/multi-step-form/forms/administration/permissions/add-groups/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/switchable-form-step", "securitytrax-ember-ui/components/multi-step-form/forms/administration/permissions/permissions-base"], function (_exports, _emberConcurrency, _switchableFormStep, _permissionsBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _permissionsBase.default.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;

      if (this.formMode !== 'view') {
        var stepName = multiStepForm.currentStepNameKey;
        multiStepForm.registerStepData(stepName, 'buttons', [{
          text: this.intl.t('pages.administration.human_resources.create_group'),
          click: buttonPromise => {
            return stepName == 'addGroup' ? this.saveNewGroup.perform(buttonPromise) : this.saveNewGroup.perform(buttonPromise, true);
          },
          classNamesString: 'st-btn st-btn-primary group-save-button'
        }]);
      }
    },

    saveNewGroup: (0, _emberConcurrency.task)(function* (buttonPromise, clone = false) {
      var multiStepForm = this.multiStepForm;
      var store = this.store;
      var newlySavedGroup;
      var validationObject = {
        notificationNamespace: 'modal'
      };

      if (clone) {
        var groupToClone = this.groupToClone;
        var formattedObjects = Ember.A();
        formattedObjects.push(Ember.Object.create({
          model: {
            name: this.multiStepForm.formCopiedModelsGetVal('group.model.name'),
            existingGroup: groupToClone
          },
          extraValidationData: {
            clone: true
          },
          filePath: 'group'
        }));
        yield multiStepForm.validateList.perform(formattedObjects);

        try {
          newlySavedGroup = (yield groupToClone.invokeAction('duplicate', {
            verb: 'POST',
            processResponse: true,
            data: {
              data: {
                attributes: {
                  name: this.multiStepForm.formCopiedModelsGetVal('group.model.name')
                }
              }
            }
          })).models[0];
        } catch (err) {
          this.stNotificationManager.addErrorMessages(err, {
            notificationNamespace: 'modal'
          });
          (false && Ember.warn('HR save group error' + err, {
            id: 'multiStepForm.forms.administration.permissions.saveNewGroup'
          }));
          throw err;
        }
      } else {
        var toCreateObjects = yield multiStepForm.validateCreateAll(validationObject);
        var newGroup = store.createRecord('group', toCreateObjects.group.data);
        newlySavedGroup = yield toCreateObjects.group.saveWithRollbackAndNotification(newGroup);
      }

      buttonPromise.then(() => {
        this.stNotificationManager.addSuccess(this.intl.t('common.notifications.save_successful'));
        multiStepForm.formStartedSetVal(false);
        this.set('addGroup', newlySavedGroup);
        this.router.transitionTo('administration.human-resources.permissions.group-details', newlySavedGroup);
      });
    })
  });

  _exports.default = _default;
});