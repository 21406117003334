define("securitytrax-ember-ui/services/st-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    /**
     * format phone numbers as (999) 999-9999 or +9 (999) 999-9999
     */
    phoneNumber(phoneNumber) {
      var digits = ('' + phoneNumber).replace(/\D/g, ''); //get rid of any non digits

      var matches;

      if (digits.length === 11) {
        matches = digits.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
      } else {
        matches = digits.match(/^(\d{3})(\d{3})(\d{4})$/);
      }

      return !matches ? digits : matches.length === 5 ? "+".concat(matches[1], " (").concat(matches[2], ") ").concat(matches[3], "-").concat(matches[4]) : '(' + matches[1] + ') ' + matches[2] + '-' + matches[3];
    }

  });

  _exports.default = _default;
});