define("securitytrax-ember-ui/components/calendar-glimmer/calendar-event/calendar-event-tooltip/component", ["exports", "@glimmer/component", "moment", "ember-concurrency"], function (_exports, _component, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CalendarEventTooltip = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service('events/calendar-events'), _dec8 = Ember.computed('args.calendarEvent.appointmentStatusData'), _dec9 = (0, _emberConcurrency.task)(function* () {
    var appointmentStatusData = yield this.args.calendarEvent.appointmentStatusData;
    return appointmentStatusData;
  }), _dec10 = (0, _emberConcurrency.task)(function* () {
    var customer = yield this.args.calendarEvent.customer;
    this.mapWindow.openWindow({
      latitude: Number(customer.get('responseMeta.geopoint.latitude')),
      longitude: Number(customer.get('responseMeta.geopoint.longitude')),
      label: "".concat(customer.address1, " - ").concat(customer.city)
    });
  }), _dec11 = Ember._action, (_class = (_temp = class CalendarEventTooltip extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "screen", _descriptor3, this);

      _initializerDefineProperty(this, "calPointer", _descriptor4, this);

      _initializerDefineProperty(this, "router", _descriptor5, this);

      _initializerDefineProperty(this, "mapWindow", _descriptor6, this);

      _initializerDefineProperty(this, "CalendarEventsService", _descriptor7, this);

      _initializerDefineProperty(this, "appointmentStatusDataTask", _descriptor8, this);

      _initializerDefineProperty(this, "showMapTask", _descriptor9, this);

      this.CalendarEventsService.on('updateEventOnSite', this, '_updateEventOnSite');
    }

    willDestroy() {
      this.CalendarEventsService.off('updateEventOnSite', this, '_updateEventOnSite');
    }

    get appointmentIsTooLong() {
      var event = this.args.calendarEvent;
      var endOfday = (0, _moment.default)(event.startTime).endOf('day');
      var startTime = (0, _moment.default)(event.startTime);

      if (endOfday.isBefore(startTime.add(event.duration, 's'))) {
        return true;
      }

      return false;
    }

    _updateEventOnSite(eventID) {
      if (this.args.calendarEvent.id === eventID.id) {
        Ember.notifyPropertyChange(this.args.calendarEvent, 'appointmentStatusData');
      }
    }

    get popoverContainer() {
      if (this.args.popoverContainer) {
        return this.args.popoverContainer;
      } else {
        return '#calendar-main-';
      }
    }

    get tooltipClass() {
      var theGuId = Ember.guidFor(this);
      return 'appointment-popover__' + theGuId + ' tooltip-appointment';
    }

    get tooltipDirection() {
      if (this.args.tooltipDirection) {
        return this.args.tooltipDirection;
      }

      var startTime = Number((0, _moment.default)(this.args.calendarEvent.get('startTime'), 'LLL').format('H'));

      if (startTime >= 18 && this.hasScroll) {
        return 'left-end';
      } else {
        return 'top-start';
      }
    }

    get appointmentStatusData() {
      return this.appointmentStatusDataTask.perform();
    }

    showMap() {
      this.showMapTask.perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "screen", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "calPointer", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "mapWindow", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "CalendarEventsService", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "appointmentStatusData", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "appointmentStatusData"), _class.prototype), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "appointmentStatusDataTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "showMapTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showMap", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "showMap"), _class.prototype)), _class));
  _exports.default = CalendarEventTooltip;
});