define("securitytrax-ember-ui/components/multi-step-form/forms/reports/bulk-edit/component", ["exports", "ember-concurrency", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "lodash", "moment"], function (_exports, _emberConcurrency, _component, _switchableFormStep, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var ReportBulkEdit = (_dec = Ember.inject.service('intl'), _dec2 = Ember.inject.service('stNotificationManager'), _dec3 = Ember.inject.service('store'), _dec4 = Ember.inject.service('grants'), _dec5 = Ember.inject.service('userService'), _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = (0, _emberConcurrency.task)(function* () {
    var hashedData = yield (0, _emberConcurrency.hash)({
      finishedCopyingModels: (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true),
      reportObjectMassEditColumns: this.args.reportObjectMassEditColumnsFiltered
    });
    var reportBulkEditDataArray = [];
    var reportBulkEditData = {};

    for (var index = 0; index < hashedData.reportObjectMassEditColumns.length; index++) {
      var element = hashedData.reportObjectMassEditColumns.objectAt(index);
      var bulkEditData = {
        name: element.about,
        id: element.id,
        status: element.status,
        v3DataType: element.v3DataType,
        data_options: element.responseMeta.data_options
      };

      if (element.responseMeta.warning_message != '') {
        bulkEditData['warning_message'] = element.responseMeta.warning_message;
      }

      reportBulkEditData[element.id] = bulkEditData;
      reportBulkEditDataArray.pushObject(bulkEditData);
    }

    Ember.set(this, 'fieldWarningMessages', Ember.A());
    Ember.set(this, 'reportBulkEditDataArray', reportBulkEditDataArray);
    Ember.set(this, 'reportBulkEditData', reportBulkEditData);
    Ember.set(this, 'fieldsToSave', Ember.A([{
      primary_option: undefined,
      secondary_option: undefined,
      currFieldToSave: undefined
    }]));
  }), _dec11 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    /////////////////////////////////////////////
    // FE report bulk edit validation
    var validationErrors = {};
    var foundErrors = false;

    for (var index = 0; index < this.fieldsToSave.length; index++) {
      var currFieldToSave = this.fieldsToSave[index];

      if (Ember.get(currFieldToSave, 'currFieldToSave') == null) {
        foundErrors = true;
        validationErrors[index + '-reportField'] = [{
          message: 'You must select a field to change'
        }];
      }

      if (Ember.get(currFieldToSave, 'primary_option') == null && currFieldToSave.currFieldToSave && currFieldToSave.currFieldToSave.v3DataType != 'boolean') {
        foundErrors = true;
        validationErrors[index + '-reportPrimaryField'] = [{
          message: 'You must choose a value'
        }];
      }
    }

    if (foundErrors) {
      multiStepForm.validationErrors = validationErrors;
      throw validationErrors;
    } // end validation


    var editIds = _lodash.default.map(this.args.bulkReportEditList, 'edit_id');

    var bulkEditRequests = [];

    for (var _index = 0; _index < this.fieldsToSave.length; _index++) {
      var _currFieldToSave = this.fieldsToSave[_index];
      var primaryOption = _currFieldToSave.primary_option;

      if (_moment.default.isMoment(_currFieldToSave.primary_option)) {
        primaryOption = _currFieldToSave.primary_option.format('YYYY-MM-DD');
      }

      if (_currFieldToSave.currFieldToSave.v3DataType == 'boolean') {
        if (_currFieldToSave.primary_option) {
          primaryOption = true;
        } else {
          primaryOption = false;
        }
      }

      var currFieldData = {
        edit_ids: editIds,
        report_object_mass_edit_method_id: _currFieldToSave.currFieldToSave.id,
        primary_option: primaryOption,
        secondary_option: _currFieldToSave.secondary_option
      };

      try {
        bulkEditRequests.push(this.args.bulkEditReportModel.invokeAction('bulk_edit', {
          verb: 'POST',
          data: {
            data: currFieldData
          },
          processResponse: false
        }));
      } catch (error) {
        (false && Ember.warn('failed to do reports bulk edit 1' + _index + ' ' + error, {
          id: 'reports.bulk-edit'
        }));
        this.stNotificationManager.addErrorMessages(error, {
          notificationNamespace: 'modal'
        });
        throw error;
      }
    }

    var bulkEditRequestsResult;

    try {
      bulkEditRequestsResult = yield (0, _emberConcurrency.all)(bulkEditRequests); // note this also has data for all returned errors on actual edits - but not showing them for now
    } catch (error) {
      (false && Ember.warn('failed to do reports bulk edit 2', {
        id: 'reports.bulk-edit'
      }));
      this.stNotificationManager.addErrorMessages(error, {
        notificationNamespace: 'modal'
      });
      throw error;
    }

    var updatedCount = 0;
    var problemsCount = 0;

    for (var _index2 = 0; _index2 < bulkEditRequestsResult.length; _index2++) {
      var element = bulkEditRequestsResult.objectAt(_index2);
      updatedCount += _lodash.default.get(element, 'responsePayload.data.updated', 0);
      problemsCount += _lodash.default.get(element, 'responsePayload.data.problem_ids.length', 0);
    }

    buttonPromise.then(() => {
      multiStepForm.onAfterSave();

      if (updatedCount > 0) {
        this.stNotificationManager.addSuccess(this.intl.t('common.notifications.bulk_save_successful', {
          'num': updatedCount
        }));
      }

      if (problemsCount > 0) {
        this.stNotificationManager.addError(this.intl.t('common.notifications.bulk_generic_failure', {
          'num': problemsCount
        }));
      }

      multiStepForm.formStartedSetVal(false);
    });
  }), _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, (_class = (_temp = class ReportBulkEdit extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "grants", _descriptor4, this);

      _initializerDefineProperty(this, "userService", _descriptor5, this);

      _initializerDefineProperty(this, "fieldsToSave", _descriptor6, this);

      _initializerDefineProperty(this, "reportBulkEditData", _descriptor7, this);

      _initializerDefineProperty(this, "reportBulkEditDataArray", _descriptor8, this);

      _initializerDefineProperty(this, "fieldWarningMessages", _descriptor9, this);

      _initializerDefineProperty(this, "initData", _descriptor10, this);

      _initializerDefineProperty(this, "saveBulkEdit", _descriptor11, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments, {
        disabledProgressBarSteps: this.disabledProgressBarSteps,
        hiddenProgressBarSteps: this.hiddenProgressBarSteps
      });
      this.initData.perform();
    }

    get disabled() {
      return this.fieldsToSave.length === this.reportBulkEditDataArray.length;
    }

    buttons() {
      var multiStepForm = this.args.multiStepForm;
      var buttonChoices = [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.saveBulkEdit.perform(multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary save-report-bulk-edit'
      }];
      multiStepForm.registerStepData('bulkEdit', 'buttons', buttonChoices);
    }

    setField(index, currFieldToSave) {
      var fieldsToSave = this.fieldsToSave;

      if (currFieldToSave == null) {
        // if the field got cleared this just removes it from the list
        fieldsToSave.splice(index, 1);
      } else {
        // sets which field was chosen
        fieldsToSave[index]['currFieldToSave'] = currFieldToSave;
      }

      Ember.set(this, 'fieldsToSave', fieldsToSave);
      var newFieldWarningMessages = Ember.A();

      for (var _index3 = 0; _index3 < fieldsToSave.length; _index3++) {
        var element = fieldsToSave.objectAt(_index3);

        if (element.currFieldToSave.warning_message) {
          newFieldWarningMessages.push(element.currFieldToSave.warning_message);
        }
      }

      Ember.set(this, 'fieldWarningMessages', newFieldWarningMessages);
      Ember.notifyPropertyChange(this, 'fieldWarningMessages');
    }

    addRow() {
      if (!this.disabled) {
        var fieldsToSave = this.fieldsToSave;
        fieldsToSave.pushObject({
          primary_option: undefined,
          secondary_option: undefined,
          currFieldToSave: undefined
        });
        Ember.set(this, 'fieldsToSave', fieldsToSave);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "userService", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "fieldsToSave", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "reportBulkEditData", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "reportBulkEditDataArray", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "fieldWarningMessages", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "initData", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "saveBulkEdit", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "buttons", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "buttons"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setField", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "setField"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addRow", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "addRow"), _class.prototype)), _class));
  _exports.default = ReportBulkEdit;
});