define("securitytrax-ember-ui/components/multi-step-form/forms/send/component", ["exports", "@glimmer/component", "ember-concurrency", "securitytrax-ember-ui/utils/debug", "ember-inflector", "lodash"], function (_exports, _component, _emberConcurrency, _debug, _emberInflector, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Send = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = (0, _emberConcurrency.task)(function* () {
    var company = yield this.sessionData.getCompany();
    var companyName = company.get('name');

    if (this.args.sendToEntityEmail) {
      this.sendToEntityEmail = this.args.sendToEntityEmail;
    }

    if (!this.args.defaultToAddress) {
      this.sendToEntityEmail = false;
    }

    this.emailSubject = this.intl.t('pages.customers.send.default_subject', {
      type: this.args.sendSubjectType,
      id_number: this.args.sendSubjectId,
      company_name: companyName
    });
  }), _dec11 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    yield this.validateSendFormTask.perform();

    try {
      var email_to = this.sendToEntityEmail ? this.args.defaultToAddress + (this.toAddress ? ',' + this.toAddress : '') : this.toAddress;

      var email_to_unique = _lodash.default.uniq(email_to.replace(/\s+/g, '').split(','));

      yield this.sendPreviewEmail.sendEmail(this.args.sendModel, {
        toAddress: this.toAddress ? _lodash.default.uniq(this.toAddress.replace(/\s+/g, '').split(',')) : null,
        subject: this.emailSubject,
        message: this.messageToSend,
        extraData: this.args.extraSendData,
        meta: this.meta
      }, (0, _emberInflector.pluralize)(this.args.modelName) + '_action');
      buttonPromise.then(() => {
        this.stNotificationManager.addSuccess(this.intl.t('common.notifications.send_message_successful', {
          email_to: email_to_unique.toString().replace(',', ', ')
        }), this.context);
      });
    } catch (error) {
      this.stNotificationManager.addErrorMessages(error, {
        notificationNamespace: this.context
      });
      throw error;
    }
  }), _dec12 = (0, _emberConcurrency.task)(function* () {
    try {
      var model = this.args.sendModel;
      var previewData = yield this.sendPreviewEmail.getPreview(model, this.args.printActionName);

      if (!((0, _debug.isTestEnvironment)() || (0, _debug.isCypressTestEnvironment)())) {
        window.location.assign(previewData.url);
      }
    } catch (error) {
      this.stNotificationManager.addErrorMessages(error, {
        notificationNamespace: this.context
      });
      throw error;
    }
  }), _dec13 = (0, _emberConcurrency.task)(function* () {
    var formattedObjects = [];
    var addresses = this.toAddress ? this.toAddress.replace(/\s+/g, '').split(',') : null;
    var noValidate = [];

    if (this.sendToEntityEmail && !this.toAddress) {
      noValidate.push('recipients');
    }

    if (!this.sendToEntityEmail) {
      noValidate.push('defaultToAddress');
    }

    formattedObjects.pushObject(Ember.Object.create({
      model: _objectSpread({
        recipients: addresses,
        defaultToAddress: this.args.defaultToAddress ? [this.args.defaultToAddress] : []
      }, this.args.validationModelData),
      extraValidationData: _objectSpread({
        noValidate
      }, this.args.extraValidationData),
      filePath: 'send'
    }));
    yield this.args.multiStepForm.validateList.perform(formattedObjects);
  }), _dec14 = Ember._action, (_class = (_temp = class Send extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "grants", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "sessionData", _descriptor3, this);

      _initializerDefineProperty(this, "sendPreviewEmail", _descriptor4, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor5, this);

      _initializerDefineProperty(this, "toAddress", _descriptor6, this);

      _initializerDefineProperty(this, "emailSubject", _descriptor7, this);

      _initializerDefineProperty(this, "messageToSend", _descriptor8, this);

      _initializerDefineProperty(this, "sendToEntityEmail", _descriptor9, this);

      _initializerDefineProperty(this, "initFormData", _descriptor10, this);

      _initializerDefineProperty(this, "sendTask", _descriptor11, this);

      _initializerDefineProperty(this, "showPreviewTask", _descriptor12, this);

      _initializerDefineProperty(this, "validateSendFormTask", _descriptor13, this);

      this.initFormData.perform();
    }

    get meta() {
      var defaultMeta = {
        ignore_entity_email_address: !this.sendToEntityEmail
      };

      if (this.args.sendMeta) {
        return _lodash.default.merge(this.args.sendMeta, defaultMeta);
      } else {
        return defaultMeta;
      }
    }

    get sendToEntity() {
      return this.args.sendToEntity || 'Customer';
    }

    get context() {
      return this.args.context || 'modal';
    }

    setupButtons() {
      var buttons = [{
        text: this.intl.t('common.actions.send'),
        click: buttonPromise => {
          return this.sendTask.perform(buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary send-send-button'
      }];
      this.args.multiStepForm.registerStepData('send', 'buttons', buttons);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "sendPreviewEmail", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "toAddress", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "emailSubject", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "messageToSend", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "sendToEntityEmail", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "initFormData", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "sendTask", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "showPreviewTask", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "validateSendFormTask", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupButtons", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "setupButtons"), _class.prototype)), _class));
  _exports.default = Send;
});