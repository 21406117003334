define("securitytrax-ember-ui/components/multi-step-form/forms/system/telus/alarm-logs/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms/system/telus/base"], function (_exports, _emberConcurrency, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var AlarmLogs = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = (0, _emberConcurrency.task)(function* () {
    var theTrueLists = [];
    var lists = yield this.centralStationService.postData({
      model: this.args.monitoringCompany,
      method: 'adt_canada__getAlarmLogCollectionLoadableLists',
      rawResponse: false
    });
    lists.alarm_log_collection_loadable_lists.forEach(list => {
      theTrueLists[list.type] = list.items;
    });
    this.alarmLogLoadableLists = theTrueLists;
  }), _dec9 = (0, _emberConcurrency.task)(function* () {
    try {
      var logs = yield this.centralStationService.postData({
        model: this.args.customer,
        rawResponse: false,
        method: 'adt_canada__getAlarmLogCollection',
        payload: {
          'client_timezone_offset': this.miscFilter.timezoneOffset,
          'live_result': this.miscFilter.liveResults,
          'paging': {
            'current_page': this.page,
            'lines_per_page': this.miscFilter.itemsPerPage
          },
          'order_by_collection': {
            'alarm_log': this.miscFilter.orderBy,
            'direction': this.miscFilter.sortBy
          },
          'filter_collection': {
            'alarm_log': this.miscFilter.operand,
            'operator': this.miscFilter.operator,
            'value': this.miscFilter.value
          },
          'installation_numbers': []
        }
      });
      this.alarmLogs = logs.alarm_log_collection || [];
      this.listCount = logs.paging_info.max_pages * this.miscFilter.itemsPerPage;
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
    }
  }), (_class = (_temp = class AlarmLogs extends _base.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "centralStationService", _descriptor, this);

      _initializerDefineProperty(this, "alarmLogs", _descriptor2, this);

      _initializerDefineProperty(this, "alarmLogLoadableLists", _descriptor3, this);

      _initializerDefineProperty(this, "filter", _descriptor4, this);

      _initializerDefineProperty(this, "miscFilter", _descriptor5, this);

      _initializerDefineProperty(this, "page", _descriptor6, this);

      _initializerDefineProperty(this, "listCount", _descriptor7, this);

      _initializerDefineProperty(this, "initFormData", _descriptor8, this);

      _initializerDefineProperty(this, "alarmLogsTask", _descriptor9, this);

      this.miscFilter = {
        liveResults: false,
        timezoneOffset: 0,
        itemsPerPage: 100,
        sortBy: 'ASC',
        orderBy: 'EventDateTime'
      };
      this.initFormData.perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "alarmLogs", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "alarmLogLoadableLists", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "filter", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "miscFilter", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "listCount", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "initFormData", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "alarmLogsTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AlarmLogs;
});