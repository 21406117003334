define("securitytrax-ember-ui/components/multi-step-form/forms/system/cops/tests/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/switchable-form-step", "securitytrax-ember-ui/utils/obj-helpers", "moment"], function (_exports, _emberConcurrency, _switchableFormStep, _objHelpers, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    centralStationService: Ember.inject.service(),
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    stFiltersService: Ember.inject.service(),
    showOnTestModal: false,
    showOffTestModal: false,
    phoneNumber: undefined,
    entireSystemOnTest: undefined,
    currentOrDelayed: undefined,
    hoursOnTest: undefined,
    minutesOnTest: undefined,
    codesOnTestString: undefined,
    startDate: undefined,
    startTime: undefined,
    filterType: undefined,
    testHistoryData: undefined,
    scrollPosition: 0,
    tableWidth: 0,
    fadeHeight: 0,
    maxFadeHeight: 0,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        phoneNumber: null,
        entireSystemOnTest: null,
        currentOrDelayed: 'current',
        hoursOnTest: null,
        minutesOnTest: null,
        codesOnTestString: null,
        startDate: null,
        startTime: null,
        filterType: null,
        testHistoryData: null
      });
    },

    didRender() {
      this._super(...arguments);

      if (this.element.querySelector('.table-scroll-wrap')) {
        this.set('scrollPosition', this.element.querySelector('.table-scroll-wrap').scrollLeft);
        this.set('scrollWidth', this.element.querySelector('.table-scroll-wrap').scrollWidth);
        this.set('modalWidth', this.element.querySelector('.table-scroll-wrap').clientWidth);
        this.set('fadeHeight', this.element.querySelector('.table-scroll-wrap').clientHeight);
        this.set('maxFadeHeight', this.element.querySelector('.form-content__cell').clientHeight);
      }
    },

    disabledProgressBarSteps: Ember.computed(function () {
      return [];
    }),
    hiddenProgressBarSteps: Ember.computed(function () {
      return [];
    }),
    filterTypeOptions: Ember.computed(function () {
      return [{
        id: 'history',
        label: this.intl.t('pages.customers.system.cops.test.history')
      }, {
        id: 'results',
        label: this.intl.t('pages.customers.system.cops.test.results')
      }];
    }),
    delayedStartDateTime: Ember.computed('startDate', 'startTime', function () {
      if (this.currentOrDelayed === 'delayed') {
        return "".concat((0, _moment.default)(this.startDate).format('YYYY-MM-DD'), " ").concat(this.startTime, ":00");
      } else {
        return null;
      }
    }),
    codesOnTestArray: Ember.computed('codesOnTestString', function () {
      var finalCodesArray = [];

      if (this.codesOnTestString) {
        var intermidiateCodesArray = this.codesOnTestString.replace(/\s+/g, '').split(',');
        intermidiateCodesArray.forEach(code => {
          finalCodesArray.push({
            code
          });
        });
      }

      return finalCodesArray;
    }),
    testStatus: Ember.computed(function () {
      return this.getTestStatus.perform();
    }),
    getTestStatus: (0, _emberConcurrency.task)(function* () {
      var status = yield this.centralStationService.getData({
        model: this.customer,
        method: 'cops__getAccountTestStatus'
      });
      return status.account_test_status;
    }),
    codesOnTestDisplay: Ember.computed('testStatus', function () {
      return this.getCodesOnTestDisplay.perform();
    }),
    getCodesOnTestDisplay: (0, _emberConcurrency.task)(function* () {
      var testStatus = yield this.testStatus;

      if (testStatus.on_test) {
        return testStatus.on_test_attributes.codes_list.map(code => {
          return " ".concat(code.name);
        }).join();
      }
    }),
    entireSystemOnTestOptions: Ember.computed(function () {
      return [{
        id: true,
        name: this.intl.t('pages.customers.system.cops.test.yes')
      }, {
        id: false,
        name: this.intl.t('pages.customers.system.cops.test.no')
      }];
    }),
    currentOrDelayedOptions: Ember.computed(function () {
      return [{
        id: 'current',
        name: this.intl.t('pages.customers.system.cops.test.current')
      }, {
        id: 'delayed',
        name: this.intl.t('pages.customers.system.cops.test.delayed')
      }];
    }),
    onTestModalButtons: Ember.computed(function () {
      return [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.putSystemOnOrOffTest.perform(buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary'
      }];
    }),
    putSystemOnOrOffTest: (0, _emberConcurrency.task)(function* (buttonPromise) {
      var testStatusObj = yield this.testStatus;
      var testStatus = testStatusObj.on_test;
      var formattedObjects = [];
      var validationModel = {
        entireSystemOnTest: this.entireSystemOnTest,
        hoursOnTest: this.hoursOnTest,
        minutesOnTest: this.minutesOnTest,
        currentOrDelayed: this.currentOrDelayed,
        startDate: this.startDate,
        startTime: this.startTime
      };
      var noValidateArray = [];

      if (this.currentOrDelayed === 'current') {
        noValidateArray.push('cops-startDate', 'cops-startTime');
      }

      if (testStatus) {
        noValidateArray.push('cops-entireSystemOnTest', 'cops-hoursOnTest', 'cops-minutesOnTest', 'cops-startDate', 'cops-startTime');
      }

      formattedObjects.pushObject(Ember.Object.create({
        model: validationModel,
        extraValidationData: {
          noValidate: noValidateArray
        },
        notificationNamespace: 'onTestModal',
        filePath: 'central-station/cops-place-on-test'
      }));
      yield this.multiStepForm.validateList.perform(formattedObjects);

      try {
        yield this.centralStationService.postData({
          model: this.customer,
          method: 'cops__setAccountTestStatus',
          payload: {
            on_test: testStatus ? false : true,
            on_test_arguments: testStatus ? {} : {
              phone_number: this.phoneNumber,
              entire_system_on_test: this.entireSystemOnTest,
              current_or_delayed: this.currentOrDelayed,
              hours_on_test: this.hoursOnTest,
              minutes_on_test: this.minutesOnTest,
              codes_on_test: this.codesOnTestArray,
              delayed_start_date_time: this.delayedStartDateTime
            }
          }
        });
        buttonPromise.then(() => {
          this.set('showOnTestModal', false);
          this.set('showOffTestModal', false);
          this.notifyPropertyChange('testStatus');
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'model');
          this.actions.resetTestProperties.call(this);
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'onTestModal'
        });
        throw err;
      }
    }),
    getTestData: (0, _emberConcurrency.task)(function* () {
      var formattedObjects = [];
      formattedObjects.pushObject(Ember.Object.create({
        model: {
          type: this.filterType,
          dateRange: this.filterDateFrom && this.filterDateTo
        },
        notificationNamespace: 'modal',
        filePath: 'central-station/cops-test-history'
      }));
      yield this.multiStepForm.validateList.perform(formattedObjects);

      try {
        var newFilters = yield this.stFiltersService.getFiltersByGroupKeyAsObject('copsTests', [], {
          copsTestsType: true
        });
        var method = this.filterType === 'history' ? 'cops__getTestHistory' : 'cops__getTestResults';
        var testData = yield this.centralStationService.getData({
          model: this.customer,
          method: method,
          payload: newFilters
        });
        this.set('testHistoryData', this.filterType === 'history' ? testData.test_history.data : testData.test_results.data);
        this.set('searchAttempted', true);
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
        throw err;
      }
    }),
    hidden: Ember.computed('scrollPosition', function () {
      if (this.scrollPosition + this.modalWidth >= this.scrollWidth) {
        return 'hidden';
      } else {
        return '';
      }
    }),
    actions: {
      clearFilter() {
        Ember.setProperties(this, {
          filterType: null,
          filterDateFrom: null,
          filterDateTo: null,
          searchAttempted: false,
          testHistoryData: null
        });
      },

      setScrollPosition() {
        this.set('scrollPosition', this.element.querySelector('.table-scroll-wrap').scrollLeft);
      },

      resetTestProperties() {
        Ember.setProperties(this, {
          phoneNumber: null,
          entireSystemOnTest: null,
          currentOrDelayed: 'current',
          hoursOnTest: null,
          minutesOnTest: null,
          codesOnTestString: null,
          startDate: null,
          startTime: null
        });
      }

    }
  });

  _exports.default = _default;
});