define("securitytrax-ember-ui/validations/central-station/e-contract-brinks", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation", "lodash"], function (_exports, _validate, _baseValidation, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var noValidate = _lodash.default.get(data, 'extraValidationData.noValidate', false);

      var validateObject = {
        signingType: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('brinks-signingType')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'brinks-signingType');
            }
          }
        },
        companyType: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('brinks-companyType')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'brinks-companyType');
            }
          }
        },
        equipmentAlarmNetwork: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('brinks-equipmentAlarmNetwork')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'brinks-equipmentAlarmNetwork');
            }
          }
        },
        installationStart: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('brinks-installationStart')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'brinks-installationStart');
            }
          }
        },
        installationFinish: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('brinks-installationFinish')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'brinks-installationFinish');
            }
          }
        },
        billCounty: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('brinks-billCounty')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'brinks-billCounty');
            }
          }
        },
        paymentInitial: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('brinks-paymentInitial')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'brinks-paymentInitial');
            }
          }
        },
        paymentMonthly: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('brinks-paymentMonthly')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'brinks-paymentMonthly');
            }
          }
        },
        paymentEffectiveDate: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('brinks-paymentEffectiveDate')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'brinks-paymentEffectiveDate');
            }
          }
        },
        secondarySignerSigningType: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('brinks-secondarySignerSigningType')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'brinks-secondarySignerSigningType');
            }
          }
        },
        firstName: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('brinks-firstName')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'brinks-firstName');
            }
          }
        },
        lastName: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('brinks-lastName')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'brinks-lastName');
            }
          }
        },
        email: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('brinks-email')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'brinks-email');
            }
          }
        },
        phoneNumber: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('brinks-phoneNumber')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'brinks-phoneNumber');
            }
          }
        },
        birthDate: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('brinks-birthDate')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'brinks-birthDate');
            }
          }
        },
        ssnOrTin: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('brinks-ssnOrTin')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'brinks-ssnOrTin');
            }
          }
        },
        extendedService: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('brinks-extendedService')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'brinks-extendedService');
            }
          }
        },
        contractMonitoring: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('brinks-contractMonitoring')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'brinks-contractMonitoring');
            }
          }
        },
        homeowner: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('brinks-homeowner')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'brinks-homeowner');
            }
          }
        },
        newContruction: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('brinks-newContruction')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'brinks-newContruction');
            }
          }
        },
        contractualObligation: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('brinks-contractualObligation')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'brinks-contractualObligation');
            }
          }
        },
        cancelUnderstanding: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('brinks-cancelUnderstanding')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'brinks-cancelUnderstanding');
            }
          }
        },
        familiarization: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('brinks-familiarization')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'brinks-familiarization');
            }
          }
        },
        address1: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('brinks-address1')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'brinks-address1');
            }
          }
        },
        city: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('brinks-city')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'brinks-city');
            }
          }
        },
        state: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('brinks-state')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'brinks-state');
            }
          }
        },
        zip: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('brinks-zip')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'brinks-zip');
            }
          }
        },
        title: {
          presence: () => {
            if (noValidate && data.extraValidationData.noValidate.includes('brinks-title')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'brinks-title');
            }
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      return this.outputFormatting(validationData, 'brinks');
    }

  });

  _exports.default = _default;
});