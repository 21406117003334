define("securitytrax-ember-ui/components/multi-step-form/forms/accounting/edit-billing-info/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    confirmSaveInvoiceBillingInfoModalOpen: false,
    defaultBillingInformation: undefined,
    invoiceBillingInformation: undefined,
    statementBillingInformation: undefined,

    get usePrimaryData() {
      return {
        usePrimary: true,
        firstName: null,
        lastName: null,
        emails: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        primaryPhone: null
      };
    },

    initDataTask: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels', true);
      var customer = this.multiStepForm.formOriginalModels.customer.model;
      var defaultBillingInfo = this.multiStepForm.formCopiedModels.customer.model.customerAccountingInformation.filterBy('useType', 'default')[0];
      var invoiceBillingInfo = this.multiStepForm.formCopiedModels.customer.model.customerAccountingInformation.filterBy('useType', 'invoice')[0];
      var statementBillingInfo = this.multiStepForm.formCopiedModels.customer.model.customerAccountingInformation.filterBy('useType', 'invoice_statement')[0];
      var quoteBillingInfo = this.multiStepForm.formCopiedModels.customer.model.customerAccountingInformation.filterBy('useType', 'quote')[0];
      var creditMemoBillingInfo = this.multiStepForm.formCopiedModels.customer.model.customerAccountingInformation.filterBy('useType', 'credit_memo')[0];
      var refundReceiptBillingInfo = this.multiStepForm.formCopiedModels.customer.model.customerAccountingInformation.filterBy('useType', 'refund_receipt')[0];
      this.set('defaultBillingInformation', defaultBillingInfo || {
        usePrimary: true,
        useType: 'default',
        customer
      });
      this.set('invoiceBillingInformation', invoiceBillingInfo || {
        useDefault: true,
        useType: 'invoice',
        customer
      });
      this.set('statementBillingInformation', statementBillingInfo || {
        useDefault: true,
        useType: 'invoice_statement',
        customer
      });
      this.set('quoteBillingInformation', quoteBillingInfo || {
        useDefault: true,
        useType: 'quote',
        customer
      });
      this.set('creditMemoBillingInformation', creditMemoBillingInfo || {
        useDefault: true,
        useType: 'credit_memo',
        customer
      });
      this.set('refundReceiptBillingInformation', refundReceiptBillingInfo || {
        useDefault: true,
        useType: 'refund_receipt',
        customer
      });
    }).on('init'),
    cancelTask: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.multiStepForm.resetForm(false);
        yield this.initDataTask.perform();
        this.set('formMode', 'view');
        this.stNotificationManager.addSuccessKey('common.notifications.generic_cancel');
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err);
      }
    }).drop(),
    confirmSaveBillingInfoTask: (0, _emberConcurrency.task)(function* () {
      if (!this.invoiceBillingInformation.useDefault && !this.invoiceBillingInformation.id) {
        //Should this modal also trigger if existing accounting info for invoices gets editied, that's harder...
        var recurringInvoices = this.multiStepForm.formCopiedModels.customer.model.recurringInvoices;

        if (recurringInvoices.length > 0) {
          yield this.validateTask.perform();
          this.set('confirmSaveInvoiceBillingInfoModalOpen', true);
          return;
        }
      }

      yield this.saveBillingInfoTask.perform();
    }),
    validateTask: (0, _emberConcurrency.task)(function* () {
      var formattedObjects = [];

      if (!this.defaultBillingInformation.usePrimary) {
        formattedObjects.pushObject(Ember.Object.create({
          model: this.defaultBillingInformation,
          filePath: 'customer-accounting-information',
          extraValidationData: {
            overrideValidationKey: 'default-customerAccountingInformation'
          }
        }));
      }

      if (!this.invoiceBillingInformation.useDefault && !this.invoiceBillingInformation.usePrimary) {
        formattedObjects.pushObject(Ember.Object.create({
          model: this.invoiceBillingInformation,
          filePath: 'customer-accounting-information',
          extraValidationData: {
            overrideValidationKey: 'invoice-customerAccountingInformation'
          }
        }));
      }

      if (!this.statementBillingInformation.useDefault && !this.statementBillingInformation.usePrimary) {
        formattedObjects.pushObject(Ember.Object.create({
          model: this.statementBillingInformation,
          filePath: 'customer-accounting-information',
          extraValidationData: {
            overrideValidationKey: 'invoiceStatement-customerAccountingInformation'
          }
        }));
      }

      if (!this.quoteBillingInformation.useDefault && !this.quoteBillingInformation.usePrimary) {
        formattedObjects.pushObject(Ember.Object.create({
          model: this.quoteBillingInformation,
          filePath: 'customer-accounting-information',
          extraValidationData: {
            overrideValidationKey: 'quote-customerAccountingInformation'
          }
        }));
      }

      if (!this.creditMemoBillingInformation.useDefault && !this.creditMemoBillingInformation.usePrimary) {
        formattedObjects.pushObject(Ember.Object.create({
          model: this.creditMemoBillingInformation,
          filePath: 'customer-accounting-information',
          extraValidationData: {
            overrideValidationKey: 'creditMemo-customerAccountingInformation'
          }
        }));
      }

      if (!this.refundReceiptBillingInformation.useDefault && !this.refundReceiptBillingInformation.usePrimary) {
        formattedObjects.pushObject(Ember.Object.create({
          model: this.refundReceiptBillingInformation,
          filePath: 'customer-accounting-information',
          extraValidationData: {
            overrideValidationKey: 'refundReceipt-customerAccountingInformation'
          }
        }));
      }

      yield this.multiStepForm.validateList.perform(formattedObjects);
    }),
    saveBillingInfoTask: (0, _emberConcurrency.task)(function* (skipValidation = false) {
      if (!skipValidation) {
        yield this.validateTask.perform();
      }

      try {
        yield (0, _emberConcurrency.hash)({
          defaultBillingInformation: this.saveIndividualBillingInfoTask.perform(this.defaultBillingInformation, 'default'),
          invoiceBillingInfo: this.saveIndividualBillingInfoTask.perform(this.invoiceBillingInformation, 'invoice'),
          statementBillingInfo: this.saveIndividualBillingInfoTask.perform(this.statementBillingInformation, 'invoice_statement'),
          quoteBillingInfo: this.saveIndividualBillingInfoTask.perform(this.quoteBillingInformation, 'quote'),
          creditMemoBillingInfo: this.saveIndividualBillingInfoTask.perform(this.creditMemoBillingInformation, 'credit_memo'),
          refundReceiptBillingInfo: this.saveIndividualBillingInfoTask.perform(this.refundReceiptBillingInformation, 'refund_receipt')
        });
        yield this.multiStepForm.resetForm(false);
        yield this.initDataTask.perform();
        this.set('formMode', 'view');
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err);
      }
    }).restartable(),
    saveIndividualBillingInfoTask: (0, _emberConcurrency.task)(function* (billingInformation, useType) {
      if (useType === 'default') {
        if (!billingInformation.usePrimary) {
          var defaultBillingInfo = !billingInformation.id ? this.store.createRecord('customerAccountingInformation', billingInformation) : yield this.store.findRecord('customerAccountingInformation', billingInformation.get('id'));

          if (billingInformation.id) {
            Ember.setProperties(defaultBillingInfo, billingInformation);
          }

          yield defaultBillingInfo.save();
        } else if (billingInformation.id) {
          var _defaultBillingInfo = yield this.store.findRecord('customerAccountingInformation', billingInformation.get('id'));

          Ember.setProperties(_defaultBillingInfo, this.usePrimaryData);
          yield _defaultBillingInfo.save();
        }
      } else {
        if (!billingInformation.useDefault) {
          var billingInfo = !billingInformation.id ? this.store.createRecord('customerAccountingInformation', billingInformation) : yield this.store.findRecord('customerAccountingInformation', billingInformation.get('id'));

          if (billingInformation) {
            Ember.setProperties(billingInfo, billingInformation.usePrimary ? this.usePrimaryData : billingInformation);
          }

          yield billingInfo.save();
        } else if (billingInformation.id) {
          var toDelete = this.multiStepForm.formOriginalModelsGetVal('customer.model.customerAccountingInformation').filterBy('useType', useType)[0];
          yield toDelete.destroyRecordWithRollbackTask.perform();
        }
      }
    })
  });

  _exports.default = _default;
});