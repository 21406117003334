define("securitytrax-ember-ui/demo/pagination/controller", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/table-column-sort"], function (_exports, _emberConcurrency, _tableColumnSort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller.extend(_tableColumnSort.default, {
    customerListCount: 0,
    customers: Ember.computed(function () {
      return [];
    }),
    limit: 5,
    page: 1,
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    listLoaded: false,
    limitOptions: undefined,
    customerNotesReady: false,

    init() {
      this._super(...arguments);

      var intl = this.intl;
      this.set('limitOptions', [{
        name: 5,
        value: 5
      }, {
        name: 10,
        value: 10
      }, {
        name: 25,
        value: 25
      }, {
        name: 50,
        value: 50
      }, {
        name: 100,
        value: 100
      }]);
      this.set('sortNoteOptions', [{
        name: intl.t('common.columns.ascending') + ' ' + intl.t('common.columns.creation_date'),
        value: 'createdAt',
        asc: true
      }, {
        name: intl.t('common.columns.descending') + ' ' + intl.t('common.columns.creation_date'),
        value: 'createdAt',
        asc: false
      }, {
        name: intl.t('common.columns.ascending') + ' ' + intl.t('common.columns.created_by'),
        value: 'createdByUser.fname',
        asc: true
      }, {
        name: intl.t('common.columns.descending') + ' ' + intl.t('common.columns.created_by'),
        value: 'createdByUser.fname',
        asc: false
      }, {
        name: intl.t('common.columns.ascending') + ' ' + intl.t('pages.customers.notes.noteType.label'),
        value: 'noteType.name',
        asc: true
      }, {
        name: intl.t('common.columns.descending') + ' ' + intl.t('pages.customers.notes.noteType.label'),
        value: 'noteType.name',
        asc: false
      }]);
    },

    filterCustomersTask: (0, _emberConcurrency.task)(function* () {
      this.set('listLoaded', false);
      var queryParams = {
        sort: this.stSortString ? this.stSortString : '-id',
        include: 'customer_order_information.rep_user,customer_system_information.install_calendar_event.user,location',
        limit: this.limit,
        page: this.page
      };

      if (this.page == 1) {
        queryParams['count'] = 1;
      }

      var customers = yield this.store.query('customer', queryParams);
      this.set('customers', customers);

      if (this.page == 1) {
        this.set('customerListCount', customers.meta.record_count);
      }

      this.set('listLoaded', true);
    }).on('init').restartable().maxConcurrency(1),
    notesChangedObserver: Ember.observer('model.customer', function () {
      return this.sortCustomerNotesList.perform(this.stSortCurrentSortColumn, this.stSortAscending);
    }),
    sortCustomerNotesList: (0, _emberConcurrency.task)(function* (column = null, ascending = null) {
      var notesList = [];
      var customer = yield this.get('model.customer');
      var customerNotes = customer ? yield customer.get('customerNotes') : [];

      if (ascending === null) {
        notesList = customerNotes.sortBy('createdAt').reverse();
      } else {
        //handle sorting
        notesList = this.sortEmberDataCollection(customerNotes, column, ascending);
      }

      this.set('customerNotesList', notesList);
      this.set('customerNotesReady', true);
    }).on('init').drop(),
    actions: {
      /* customer-list actions */
      changeRoute(customer) {
        this.transitionToRoute('customers.information', customer);
      },

      //action sent to pagination component
      getCustomers(data) {
        this.set('listLoaded', false);
        this.set('page', data['page']);
        this.set('limit', data['limit']);
        this.filterCustomersTask.perform(null);
      },

      //action sent to sortable-column component
      sortList(column, ascending) {
        this.sortPaginatedList(column, ascending, 'listLoaded');
        this.filterCustomersTask.perform();
      },

      sortNotes(column, ascending) {
        this.sortList(column, ascending);
        this.sortCustomerNotesList.perform(column, ascending);
      },

      mobileSortNotes(sortColumn) {
        this.sortCustomerNotesList.perform(sortColumn.value, sortColumn.asc);
      }

    }
  });
  var _default = Controller;
  _exports.default = _default;
});