define("securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-multi-step-form-create/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Component.extend({
    store: Ember.inject.service(),
    customerContact: null,
    initData: (0, _emberConcurrency.task)(function* () {
      if (this.customerContactId !== 'create') {
        this.set('customerContact', yield this.store.findRecord('customerContact', this.customerContactId));
      }
    }).on('init'),
    formMode: Ember.computed('customerContact', function () {
      return this.customerContact ? 'edit' : 'create';
    }),
    singleStepForm: Ember.computed(function () {
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: 'Demo Edit Contact',
          stepComponentPath: 'multi-step-form/forms/demo/demo-modal-create'
        })
      });
    }),
    formOriginalModels: Ember.computed('customerContact', function () {
      return Ember.Object.create({
        customerContact: {
          model: this.customerContact
        }
      });
    }),
    actions: {
      transitionBackToParentRoute() {
        this.transitionToRoute(this.parentRoute);
      }

    }
  });
  var _default = Controller;
  _exports.default = _default;
});