define("securitytrax-ember-ui/services/us-states", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * List of US states with codes.
   *
   * @class USStates
   * @memberof services
   * @extends Ember.Service
   */
  var _default = Ember.Service.extend(
  /** @lends services.USStates.prototype */
  {
    init() {
      this._super(...arguments);

      this.set('us', [{
        name: 'Alabama',
        value: 'AL'
      }, {
        name: 'Alaska',
        value: 'AK'
      }, {
        name: 'American Samoa',
        value: 'AS'
      }, {
        name: 'Arizona',
        value: 'AZ'
      }, {
        name: 'Arkansas',
        value: 'AR'
      }, {
        name: 'California',
        value: 'CA'
      }, {
        name: 'Colorado',
        value: 'CO'
      }, {
        name: 'Connecticut',
        value: 'CT'
      }, {
        name: 'Delaware',
        value: 'DE'
      }, {
        name: 'Dist. of Columbia',
        value: 'DC'
      }, {
        name: 'Florida',
        value: 'FL'
      }, {
        name: 'Georgia',
        value: 'GA'
      }, {
        name: 'Guam',
        value: 'GU'
      }, {
        name: 'Hawaii',
        value: 'HI'
      }, {
        name: 'Idaho',
        value: 'ID'
      }, {
        name: 'Illinois',
        value: 'IL'
      }, {
        name: 'Indiana',
        value: 'IN'
      }, {
        name: 'Iowa',
        value: 'IA'
      }, {
        name: 'Kansas',
        value: 'KS'
      }, {
        name: 'Kentucky',
        value: 'KY'
      }, {
        name: 'Louisiana',
        value: 'LA'
      }, {
        name: 'Maine',
        value: 'ME'
      }, {
        name: 'Maryland',
        value: 'MD'
      }, {
        name: 'Marshall Islands',
        value: 'MH'
      }, {
        name: 'Massachusetts',
        value: 'MA'
      }, {
        name: 'Michigan',
        value: 'MI'
      }, {
        name: 'Micronesia',
        value: 'FM'
      }, {
        name: 'Minnesota',
        value: 'MN'
      }, {
        name: 'Mississippi',
        value: 'MS'
      }, {
        name: 'Missouri',
        value: 'MO'
      }, {
        name: 'Montana',
        value: 'MT'
      }, {
        name: 'Nebraska',
        value: 'NE'
      }, {
        name: 'Nevada',
        value: 'NV'
      }, {
        name: 'New Hampshire',
        value: 'NH'
      }, {
        name: 'New Jersey',
        value: 'NJ'
      }, {
        name: 'New Mexico',
        value: 'NM'
      }, {
        name: 'New York',
        value: 'NY'
      }, {
        name: 'North Carolina',
        value: 'NC'
      }, {
        name: 'North Dakota',
        value: 'ND'
      }, {
        name: 'Northern Marianas',
        value: 'MP'
      }, {
        name: 'Ohio',
        value: 'OH'
      }, {
        name: 'Oklahoma',
        value: 'OK'
      }, {
        name: 'Oregon',
        value: 'OR'
      }, {
        name: 'Palau',
        value: 'PW'
      }, {
        name: 'Pennsylvania',
        value: 'PA'
      }, {
        name: 'Puerto Rico',
        value: 'PR'
      }, {
        name: 'Rhode Island',
        value: 'RI'
      }, {
        name: 'South Carolina',
        value: 'SC'
      }, {
        name: 'South Dakota',
        value: 'SD'
      }, {
        name: 'Tennessee',
        value: 'TN'
      }, {
        name: 'Texas',
        value: 'TX'
      }, {
        name: 'Utah',
        value: 'UT'
      }, {
        name: 'Vermont',
        value: 'VT'
      }, {
        name: 'Virginia',
        value: 'VA'
      }, {
        name: 'Virgin Islands',
        value: 'VI'
      }, {
        name: 'Washington',
        value: 'WA'
      }, {
        name: 'West Virginia',
        value: 'WV'
      }, {
        name: 'Wisconsin',
        value: 'WI'
      }, {
        name: 'Wyoming',
        value: 'WY'
      }]);
      this.set('canadian', [{
        name: 'Alberta',
        value: 'AB'
      }, {
        name: 'British Columbia',
        value: 'BC'
      }, {
        name: 'Manitoba',
        value: 'MB'
      }, {
        name: 'New Brunswick',
        value: 'NB'
      }, {
        name: 'Newfoundland and Labrador',
        value: 'NL'
      }, {
        name: 'Nova Scotia',
        value: 'NS'
      }, {
        name: 'Northwest Territories',
        value: 'NT'
      }, {
        name: 'Nunavut',
        value: 'NU'
      }, {
        name: 'Ontario',
        value: 'ON'
      }, {
        name: 'Prince Edward Island',
        value: 'PE'
      }, {
        name: 'Quebec',
        value: 'QC'
      }, {
        name: 'Saskatchewan',
        value: 'SK'
      }, {
        name: 'Yukon',
        value: 'YT'
      }]);
      this.set('all', [{
        name: 'Alabama',
        value: 'AL'
      }, {
        name: 'Alaska',
        value: 'AK'
      }, {
        name: 'American Samoa',
        value: 'AS'
      }, {
        name: 'Arizona',
        value: 'AZ'
      }, {
        name: 'Arkansas',
        value: 'AR'
      }, {
        name: 'California',
        value: 'CA'
      }, {
        name: 'Colorado',
        value: 'CO'
      }, {
        name: 'Connecticut',
        value: 'CT'
      }, {
        name: 'Delaware',
        value: 'DE'
      }, {
        name: 'Dist. of Columbia',
        value: 'DC'
      }, {
        name: 'Florida',
        value: 'FL'
      }, {
        name: 'Georgia',
        value: 'GA'
      }, {
        name: 'Guam',
        value: 'GU'
      }, {
        name: 'Hawaii',
        value: 'HI'
      }, {
        name: 'Idaho',
        value: 'ID'
      }, {
        name: 'Illinois',
        value: 'IL'
      }, {
        name: 'Indiana',
        value: 'IN'
      }, {
        name: 'Iowa',
        value: 'IA'
      }, {
        name: 'Kansas',
        value: 'KS'
      }, {
        name: 'Kentucky',
        value: 'KY'
      }, {
        name: 'Louisiana',
        value: 'LA'
      }, {
        name: 'Maine',
        value: 'ME'
      }, {
        name: 'Maryland',
        value: 'MD'
      }, {
        name: 'Marshall Islands',
        value: 'MH'
      }, {
        name: 'Massachusetts',
        value: 'MA'
      }, {
        name: 'Michigan',
        value: 'MI'
      }, {
        name: 'Micronesia',
        value: 'FM'
      }, {
        name: 'Minnesota',
        value: 'MN'
      }, {
        name: 'Mississippi',
        value: 'MS'
      }, {
        name: 'Missouri',
        value: 'MO'
      }, {
        name: 'Montana',
        value: 'MT'
      }, {
        name: 'Nebraska',
        value: 'NE'
      }, {
        name: 'Nevada',
        value: 'NV'
      }, {
        name: 'New Hampshire',
        value: 'NH'
      }, {
        name: 'New Jersey',
        value: 'NJ'
      }, {
        name: 'New Mexico',
        value: 'NM'
      }, {
        name: 'New York',
        value: 'NY'
      }, {
        name: 'North Carolina',
        value: 'NC'
      }, {
        name: 'North Dakota',
        value: 'ND'
      }, {
        name: 'Northern Marianas',
        value: 'MP'
      }, {
        name: 'Ohio',
        value: 'OH'
      }, {
        name: 'Oklahoma',
        value: 'OK'
      }, {
        name: 'Oregon',
        value: 'OR'
      }, {
        name: 'Palau',
        value: 'PW'
      }, {
        name: 'Pennsylvania',
        value: 'PA'
      }, {
        name: 'Puerto Rico',
        value: 'PR'
      }, {
        name: 'Rhode Island',
        value: 'RI'
      }, {
        name: 'South Carolina',
        value: 'SC'
      }, {
        name: 'South Dakota',
        value: 'SD'
      }, {
        name: 'Tennessee',
        value: 'TN'
      }, {
        name: 'Texas',
        value: 'TX'
      }, {
        name: 'Utah',
        value: 'UT'
      }, {
        name: 'Vermont',
        value: 'VT'
      }, {
        name: 'Virginia',
        value: 'VA'
      }, {
        name: 'Virgin Islands',
        value: 'VI'
      }, {
        name: 'Washington',
        value: 'WA'
      }, {
        name: 'West Virginia',
        value: 'WV'
      }, {
        name: 'Wisconsin',
        value: 'WI'
      }, {
        name: 'Wyoming',
        value: 'WY'
      }, {
        name: 'Alberta',
        value: 'AB'
      }, {
        name: 'British Columbia',
        value: 'BC'
      }, {
        name: 'Manitoba',
        value: 'MB'
      }, {
        name: 'New Brunswick',
        value: 'NB'
      }, {
        name: 'Newfoundland and Labrador',
        value: 'NL'
      }, {
        name: 'Nova Scotia',
        value: 'NS'
      }, {
        name: 'Northwest Territories',
        value: 'NT'
      }, {
        name: 'Nunavut',
        value: 'NU'
      }, {
        name: 'Ontario',
        value: 'ON'
      }, {
        name: 'Prince Edward Island',
        value: 'PE'
      }, {
        name: 'Quebec',
        value: 'QC'
      }, {
        name: 'Saskatchewan',
        value: 'SK'
      }, {
        name: 'Yukon',
        value: 'YT'
      }]);
    }

  });

  _exports.default = _default;
});