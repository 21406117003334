define("securitytrax-ember-ui/validations/user-rep-number", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation", "lodash"], function (_exports, _validate, _baseValidation, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var noValidate = _lodash.default.get(data, 'extraValidationData.noValidate', false);

      var validateObject = {
        number: {
          presence: this.checkValidateCustomMessageDefault(data, 'number')
        },
        domain: {
          presence: this.checkValidateCustomMessageDefault(data, 'domain')
        },
        identifier: {
          presence: () => {
            if (noValidate && noValidate.includes('userRepNumber-identifier')) {
              return false;
            } else return this.customMessageOrDefault(data, 'userRepNumber-identifier');
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject, {
        fullMessages: this.prependProperty(data)
      });
      return this.outputFormatting(validationData, 'userRepNumber');
    }

  });

  _exports.default = _default;
});