define("securitytrax-ember-ui/components/pages/users/user-inventory/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EeMg6n95",
    "block": "{\"symbols\":[\"multiStepForm\"],\"statements\":[[6,[37,4],null,[[\"formSteps\",\"formOriginalModels\",\"formStarted\"],[[35,3],[35,2],true]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],[[\"multiStepForm\",\"class\",\"user\",\"inventoryRecipientTemplates\"],[[32,1],\"user-inventory-form\",[35,0,[\"user\"]],[35,0,[\"inventoryRecipientTemplates\"]]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"component\",\"inventoryFormOriginalModels\",\"userInventoryForm\",\"multi-step-form\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/users/user-inventory/template.hbs"
    }
  });

  _exports.default = _default;
});