define("securitytrax-ember-ui/components/nav/top-nav-sub/component", ["exports", "ember-inflector", "ember-concurrency"], function (_exports, _emberInflector, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: '',
    screen: Ember.inject.service(),
    stAjax: Ember.inject.service(),
    store: Ember.inject.service(),
    stFormat: Ember.inject.service(),
    grants: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    userHasCheckedInAppointmentService: Ember.inject.service(),
    quickAdd: Ember.inject.service(),
    selectedResource: undefined,
    selectedProperty: undefined,
    searchString: null,
    hideSearch: false,
    mobileHamburgerVisible: false,
    hideToolbox: true,
    customer: undefined,

    init() {
      this._super(...arguments); // TODO: selectedProperty and resource can come from local storage


      this.set('selectedProperty', {
        value: 2,
        name: this.intl.t('common.actions.searchOptions.properties.name')
      });
      this.set('selectedResource', {
        value: 1,
        name: this.intl.t('common.actions.searchOptions.resources.customer')
      });

      if (this.get('screen.isMobile')) {
        this.set('hideSearch', true);
      }
    },

    searchPlaceHolder: Ember.computed('selectedResource', function () {
      var resource = this.selectedResource.name;
      return this.intl.t('common.actions.search') + ' ' + (0, _emberInflector.pluralize)(resource);
    }),
    resourceOptions: Ember.computed(function () {
      return [{
        value: 1,
        name: this.intl.t('common.actions.searchOptions.resources.customer')
      } //{value: 2, name: this.get('intl').t('common.actions.searchOptions.resources.user')}
      ];
    }),
    propertyOptions: Ember.computed(function () {
      return [{
        value: 1,
        name: this.intl.t('common.actions.searchOptions.properties.id')
      }, {
        value: 2,
        name: this.intl.t('common.actions.searchOptions.properties.name')
      }, {
        value: 3,
        name: this.intl.t('common.actions.searchOptions.properties.phone')
      }];
    }),
    setStar: (0, _emberConcurrency.task)(function* (customer) {
      var currentStar = customer.get('isLead') ? customer.get('leadInformation.leadIsStarred') : customer.get('isStarred');

      if (customer.get('isLead') && this.grants.getIndividualGrant('lead_information.modify')) {
        var leadInformation = yield customer.get('leadInformation');
        leadInformation.set('leadIsStarred', !currentStar);
        yield leadInformation.save();
      } else if (!customer.get('isLead') && this.grants.getIndividualGrant('customers.modify')) {
        customer.set('isStarred', !currentStar);
        yield customer.save();
      }
    }),
    appointmentData: Ember.computed('appointment.appointmentStatusData', function () {
      return this.appointmentDataTask.perform();
    }),
    appointmentDataTask: (0, _emberConcurrency.task)(function* () {
      if (this.appointment) {
        var hashData = yield (0, _emberConcurrency.hash)({
          statusData: this.appointment.get('appointmentStatusData'),
          customer: this.appointment.get('customer')
        });
        return {
          customer: hashData.customer,
          isCurrentAppointment: hashData.statusData.check_in !== null && hashData.statusData.check_out === null
        };
      }

      return null;
    }),
    actions: {
      navToTechAtWork(calendarEvent) {
        this.router.transitionTo('home.tech-today.appointment', calendarEvent.get('id'));
      },

      toggleHideToolbox() {
        this.toggleProperty('hideToolbox');
      },

      closeToolbox() {
        this.set('hideToolbox', true);
        this.notifyPropertyChange('hideToolbox');
      }

    }
  });

  _exports.default = _default;
});