define("securitytrax-ember-ui/models/report-category", ["exports", "securitytrax-ember-ui/models/base_models/report-category", "ember-inflector"], function (_exports, _reportCategory, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _reportCategory.default.extend({
    get route() {
      return 'reports.' + this.name.toLowerCase().split(' ').map(str => {
        return (0, _emberInflector.singularize)(str);
      }).join('').replace('&', '-') + '-reports';
    }

  });

  _exports.default = _default;
});