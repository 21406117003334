define("securitytrax-ember-ui/models/base_models/parent-entity-billing", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    billAddressSame: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    billAddress1: (0, _model.attr)('string', {
      'readOnly': false
    }),
    billAddress2: (0, _model.attr)('string', {
      'readOnly': false
    }),
    billCity: (0, _model.attr)('string', {
      'readOnly': false
    }),
    billState: (0, _model.attr)('string', {
      'readOnly': false
    }),
    billZip: (0, _model.attr)('string', {
      'readOnly': false
    }),
    billPhone: (0, _model.attr)('string', {
      'readOnly': false
    }),
    billCycleId: (0, _model.attr)('number', {
      'readOnly': false
    }),
    billDayOfMonth: (0, _model.attr)('number', {
      'readOnly': false
    }),
    billStartDate: (0, _model.attr)('date-only', {
      'readOnly': false
    }),
    billTypeId: (0, _model.attr)('number', {
      'readOnly': false
    }),
    billCcType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['Visa', 'MasterCard', 'Discover', 'American Express', 'Other']
    }),
    billCcName: (0, _model.attr)('string', {
      'readOnly': false
    }),
    billCcNumber: (0, _model.attr)('string', {
      'readOnly': false
    }),
    billCcExpiration: (0, _model.attr)('string', {
      'readOnly': false
    }),
    billBankType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['Checking', 'Savings', 'Other']
    }),
    billBankName: (0, _model.attr)('string', {
      'readOnly': false
    }),
    billBankInstitutionNumber: (0, _model.attr)('string', {
      'readOnly': false
    }),
    billBankNumber: (0, _model.attr)('string', {
      'readOnly': false
    }),
    billBankRoute: (0, _model.attr)('string', {
      'readOnly': false
    }),
    billAch: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    billComment: (0, _model.attr)('string', {
      'readOnly': false
    }),
    billNickname: (0, _model.attr)('string', {
      'readOnly': false
    }),
    status: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['active', 'deleted']
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    parentEntity: (0, _model.belongsTo)('parent-entity', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    parentEntityBillingUses: (0, _model.hasMany)('parent-entity-billing-use', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});