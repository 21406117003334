define("securitytrax-ember-ui/components/html/color-format-number/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    value: 0,
    classNames: ['color-format-number'],
    classNameBindings: ['color'],
    color: '',

    init() {
      this._super(...arguments);

      if (this.value > 0) {
        this.set('color', 'color-format-number__green');
      } else if (this.value < 0) {
        this.set('color', 'color-format-number__red');
      } else {
        this.set('color', '');
      }
    }

  });

  _exports.default = _default;
});