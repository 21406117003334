define("securitytrax-ember-ui/demo/st-model-watcher/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SeriXBN5",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],null,null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h1\"],[14,0,\"card-title\"],[12],[2,\"St-model-watcher demo\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"grid-x grid-margin-y\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cell small-3\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"eventHasFiredOne\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                event one has fired!!\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"                event one has NOT fired\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cell small-3\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"eventHasFiredTwo\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                event two has fired!!\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"                event two has NOT fired\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cell small-3\"],[12],[2,\"\\n            \"],[1,[30,[36,3],null,[[\"class\",\"text\",\"clickAction\"],[\"st-btn\",\"Start Watchers\",[30,[36,2],[[35,1]],null]]]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cell small-3\"],[12],[2,\"\\n            \"],[1,[30,[36,3],null,[[\"class\",\"text\",\"clickAction\"],[\"st-btn\",\"Stop Watchers\",[30,[36,2],[[35,4]],null]]]]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"setupWatchers\",\"perform\",\"html/st-button\",\"deregisterWatchers\",\"pages/demo-page\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/demo/st-model-watcher/template.hbs"
    }
  });

  _exports.default = _default;
});