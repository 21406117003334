define("securitytrax-ember-ui/models/base_models/group-resource-type-policy", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    negate: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    operator: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['EQ', 'GT', 'GTEQ', 'LT', 'LTEQ', 'IN', 'STARTS_WITH', 'ENDS_WITH', 'CONTAINS', 'RELATION', 'COMPLEX']
    }),
    conditionRight: (0, _model.attr)('json', {
      'readOnly': false
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    resourceTypePolicy: (0, _model.belongsTo)('resource-type-policy', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    parentResourceTypePolicy: (0, _model.belongsTo)('resource-type-policy', {
      'readOnly': false,
      'readOnlyUpdate': false
    }),
    group: (0, _model.belongsTo)('group', {
      'readOnly': false,
      'readOnlyUpdate': false
    })
  });

  _exports.default = _default;
});