define("securitytrax-ember-ui/components/pages/administration/company-settings/content-management/appointment-subtypes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wAz8wBYV",
    "block": "{\"symbols\":[\"@breadCrumbs\",\"@breadCrumbData\",\"@model\"],\"statements\":[[8,\"nav/top-nav-sub\",[],[[\"@title\",\"@breadCrumbs\",\"@breadCrumbData\"],[[30,[36,0],[\"pages.administration.content_management.appointment_detail_types.title\"],null],[32,1],[32,2]]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"page-content\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"scroll-page\"],[12],[2,\"\\n        \"],[8,\"pages/administration/company-settings/content-management/appointment-subtypes/appointment-reusable\",[],[[\"@type\",\"@name\",\"@model\"],[\"tech_schedule\",[30,[36,0],[\"pages.administration.content_management.appointment_detail_types.technician\"],null],[32,3]]],null],[2,\"\\n        \"],[8,\"pages/administration/company-settings/content-management/appointment-subtypes/appointment-reusable\",[],[[\"@type\",\"@name\",\"@model\"],[\"sale_schedule\",[30,[36,0],[\"pages.administration.content_management.appointment_detail_types.sales\"],null],[32,3]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/administration/company-settings/content-management/appointment-subtypes/template.hbs"
    }
  });

  _exports.default = _default;
});