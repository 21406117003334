define("securitytrax-ember-ui/services/equipment-service", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    stAjax: Ember.inject.service(),

    syncEquipment(companyId) {
      return this.syncEquipmentTask.perform(companyId);
    },

    syncEquipmentTask: (0, _emberConcurrency.task)(function* (companyId) {
      return yield this.stAjax.request(this.stAjax.getUrl('/companies/' + companyId + '/actions/sync_invoice_billable_equipment'), {
        method: 'POST',
        data: {
          data: {
            type: 'company_action'
          }
        }
      });
    })
  });

  _exports.default = _default;
});