define("securitytrax-ember-ui/components/modals/confirm-delete-replace/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    replacementPaymentMethod: null,
    confirmButton: Ember.computed(function () {
      var buttons = [];
      buttons.push({
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.replaceAndDeletePaymentMethod.perform(buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary'
      });
      return buttons;
    }),
    replacementPaymentMethods: Ember.computed(function () {
      return this.getReplacementPaymentMethods.perform();
    }),
    getReplacementPaymentMethods: (0, _emberConcurrency.task)(function* () {
      var labelPromises = [];
      var billings = yield this.customer.get('customerBillings');

      for (var i = 0; i < billings.length; i++) {
        labelPromises.push(billings.objectAt(i).get('title'));
      }

      var resolvedLabelPromises = yield (0, _emberConcurrency.all)(labelPromises);

      for (var _i = 0; _i < billings.length; _i++) {
        billings.objectAt(_i).set('_resolvedTitle', yield resolvedLabelPromises[_i]);
      }

      var filteredBillings = billings.filter(billing => {
        return billing.id !== this.multiStepForm.formOriginalModels.customerBilling.model.id && billing.get('billType.id') !== '3'; //remove current payment method and manual billing types
      });
      return filteredBillings;
    }),
    replaceAndDeletePaymentMethod: (0, _emberConcurrency.task)(function* (buttonPromise) {
      try {
        var promiseArray = [];
        var relatedRecurringInvoices = this.relatedRecurringInvoices;

        if (!this.replacementPaymentMethod) {
          relatedRecurringInvoices.forEach(invoice => {
            invoice.set('customerBilling', null);
            promiseArray.push(invoice.save());
          });
        } else {
          var customerBillings = yield this.customer.get('customerBillings');
          var replacementBilling = customerBillings.find(billings => {
            return billings.id === this.replacementPaymentMethod.id;
          });
          relatedRecurringInvoices.forEach(invoice => {
            invoice.set('customerBilling', replacementBilling);
            promiseArray.push(invoice.save());
          });
        }

        yield (0, _emberConcurrency.all)(promiseArray);
        yield this.deleteCustomerBilling.perform(this.customerBilling, buttonPromise);
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'invoiceConfirmDelete'
        });
        throw err;
      }
    })
  });

  _exports.default = _default;
});