define("securitytrax-ember-ui/models/log", ["exports", "securitytrax-ember-ui/models/base_models/log", "securitytrax-ember-ui/utils/strings"], function (_exports, _log, _strings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Log record model
   *
   * @class Log
   * @extends model.BaseLog
   * @memberof models
   */
  var _default = _log.default.extend(
  /** @lends models.BaseLog# */
  {
    /**
     * Allows to render the action html contents.
     *
     * @function
     * @return {String}
     */
    htmlAction: Ember.computed('action', function () {
      return (0, _strings.nl2br)(this.action).htmlSafe();
    })
  });

  _exports.default = _default;
});