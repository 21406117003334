define("securitytrax-ember-ui/administration/company-settings/system/global-settings/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    redirect() {
      // Redirect to appropriate sub-route. This may change when we have the Dashboard page
      this.replaceWith("administration.company-settings.system.global-settings.company");
    }

  });

  _exports.default = _default;
});