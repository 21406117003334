define("securitytrax-ember-ui/models/payroll-column-calculation", ["exports", "securitytrax-ember-ui/models/base_models/payroll-column-calculation"], function (_exports, _payrollColumnCalculation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _payrollColumnCalculation.default.extend({});

  _exports.default = _default;
});