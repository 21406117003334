define("securitytrax-ember-ui/components/multi-step-form/forms/payables/edit-create-payable/review-payables/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/payables/edit-create-payable/base-component"], function (_exports, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseComponent.default.extend({
    intl: Ember.inject.service(),
    toSaveAddOnPayables: Ember.computed('addOnPayables', function () {
      return this.addOnPayables.filter(payable => {
        return payable.get('doSave');
      });
    }),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      var buttons = [];

      if (this.formMode !== 'view') {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.updatePayableTask.perform(buttonPromise, this.mainMultiStepForm, this.payable, 'reviewPayablesModal');
          },
          classNamesString: 'st-btn st-btn-primary review-save'
        });
      }

      multiStepForm.registerStepData('step1', 'buttons', buttons);
    }

  });

  _exports.default = _default;
});