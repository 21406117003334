define("securitytrax-ember-ui/demo/inputs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qXghnXSP",
    "block": "{\"symbols\":[\"m\"],\"statements\":[[6,[37,2],null,[[\"classNames\"],[\"paper-demo\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h1\"],[14,0,\"card-title\"],[12],[2,\"Various Inputs\"],[13],[2,\"\\n\"],[6,[37,1],null,[[\"mask\"],[\"currency\"]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],null,[[\"value\",\"onChange\"],[5,[32,1,[\"onChange\"]]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"input\",\"html/text-mask\",\"pages/demo-page\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/demo/inputs/template.hbs"
    }
  });

  _exports.default = _default;
});