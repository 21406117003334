define("securitytrax-ember-ui/models/customer", ["exports", "securitytrax-ember-ui/models/base_models/customer", "ember-concurrency"], function (_exports, _customer, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customer.default.extend({
    stNotificationManager: Ember.inject.service(),
    intl: Ember.inject.service(),
    centralStationService: Ember.inject.service(),
    grants: Ember.inject.service(),
    stFormat: Ember.inject.service(),
    name: Ember.computed.alias('lfname'),
    isLead: Ember.computed('recordType', function () {
      return this.recordType === 'lead';
    }),
    // Insert zero-width space before @ sign to allow for more elegant line-breaking
    breakableEmail: Ember.computed('email', function () {
      var email = this.email;
      return typeof email === 'string' && email.length ? email.replace(/@/, '\u200B@') : email;
    }),
    citystate: Ember.computed('city', 'state', function () {
      return "".concat(this.city || '', ", ").concat(this.state || '');
    }),
    flname: Ember.computed('fname', 'lname', 'accountType', 'businessName', function () {
      if (this.accountType === 'Commercial' && this.businessName) {
        return this.businessName;
      } else {
        return "".concat(this.fname || '', " ").concat(this.lname || '');
      }
    }),
    forcedCustomerFlname: Ember.computed('fname', 'lname', function () {
      return "".concat(this.fname || '', " ").concat(this.lname || '');
    }),
    oneLineFullAddress: Ember.computed('address1', 'address2', 'city', 'state', 'zip', function () {
      var address = this.address1 || '';

      if (this.address2) {
        address = address + ' ' + this.address2;
      }

      var lastLine = "".concat(this.city || '', ", ").concat(this.state || '', " ").concat(this.zip || '');
      return "".concat(address, " ").concat(lastLine);
    }),
    nameAndAddress: Ember.computed('fullAddress', 'flname', function () {
      return this.flname + '\n' + this.fullAddress;
    }),
    fullAddress: Ember.computed('address1', 'address2', 'city', 'state', 'zip', function () {
      var address = this.address1 || '';

      if (this.address2) {
        address = address + '\n' + this.address2;
      }

      var lastLine = "".concat(this.city || '', ", ").concat(this.state || '', " ").concat(this.zip || '');
      return address + '\n' + lastLine;
    }),
    geoLocationClass: Ember.computed('hasGeolocationDisabled', function () {
      if (this.hasGeolocationDisabled) {
        return 'disabled';
      }

      return '';
    }),
    hasGeolocationDisabled: Ember.computed('responseMeta.geopoint.{latitude,longitude}', function () {
      var latitude = this.get('responseMeta.geopoint.latitude'),
          longitude = this.get('responseMeta.geopoint.longitude');
      return Ember.isEmpty(latitude) || Ember.isEmpty(longitude);
    }),
    lfname: Ember.computed('fname', 'lname', 'accountType', 'businessName', function () {
      if (this.accountType === 'Commercial' && this.businessName) {
        return this.businessName;
      } else {
        return "".concat(this.lname || '', ", ").concat(this.fname || '');
      }
    }),
    globalSearchFirstLine: Ember.computed('lfnameid', function () {
      return this.lfnameid;
    }),
    globalSearchSecondLine: Ember.computed('citystate', function () {
      return this.citystate + ' - ' + this.stFormat.phoneNumber(this.primaryPhone);
    }),
    lfnameid: Ember.computed('lfname', 'accountType', 'businessName', function () {
      if (this.accountType === 'Commercial' && this.businessName) {
        return "".concat(this.businessName, " (ID: ").concat(this.id, ")");
      } else {
        return "".concat(this.lfname, " (ID: ").concat(this.id, ")");
      }
    }),
    computedMessages: Ember.computed('isLead', 'customerMessages.[]', 'leadMessages.[]', function () {
      return this.getComputedMessages.perform();
    }),
    getComputedMessages: (0, _emberConcurrency.task)(function* () {
      if (this.isLead) {
        return yield this.leadMessages;
      } else {
        return yield this.customerMessages;
      }
    }),
    adtPreCheck: Ember.computed(function () {
      return this.adtPreCheckTask.perform();
    }),
    adtPreCheckTask: (0, _emberConcurrency.task)(function* () {
      try {
        var centralStationService = this.centralStationService;
        var monitoringCompany, monitoringCompanyType;

        if (!this.isLead) {
          var customerOrderInformation = yield this.customerOrderInformation;
          monitoringCompany = customerOrderInformation ? yield customerOrderInformation.get('monitoringCompany') : null;
          monitoringCompanyType = monitoringCompany ? yield monitoringCompany.get('monitoringCompanyType') : null;
        } else {
          var leadInformation = yield this.leadInformation;
          monitoringCompany = yield leadInformation.get('monitoringCompany');
          monitoringCompanyType = monitoringCompany ? yield monitoringCompany.get('monitoringCompanyType') : null;
        }

        if (monitoringCompanyType && monitoringCompanyType && monitoringCompanyType.get('id') === '1') {
          var adtPreCheck = yield centralStationService.getData({
            model: this,
            method: 'adt__getPreCheckOrderResult',
            responseKey: 'pre_check_order_result',
            returnAsEmberObject: true
          });
          return adtPreCheck;
        }
      } catch (error) {
        throw error;
      }
    }),
    executeAdtPreCheck: function (notificationNamespace = null) {
      return this.executeAdtPreCheckTask.perform(notificationNamespace);
    },
    executeAdtPreCheckTask: (0, _emberConcurrency.task)(function* (notificationNamespace = null) {
      try {
        var centralStationService = this.centralStationService;
        var adtPreCheck = null;
        yield centralStationService.getData({
          model: this,
          method: 'adt__executePreCheckOrder'
        });
        this.notifyPropertyChange('adtPreCheck');
        adtPreCheck = yield this.adtPreCheck; // fallback for leads just in case

        if (!adtPreCheck) {
          adtPreCheck = yield centralStationService.getData({
            model: this,
            method: 'adt__getPreCheckOrderResult',
            responseKey: 'pre_check_order_result',
            returnAsEmberObject: true
          });
          this.set('adtPreCheck', adtPreCheck);
        }

        if ('passed' in adtPreCheck && adtPreCheck.passed === false && 'errors' in adtPreCheck && adtPreCheck.errors.length > 0) {
          this.stNotificationManager.addErrorMessages(adtPreCheck, notificationNamespace);
        }

        return adtPreCheck;
      } catch (error) {
        this.stNotificationManager.addErrorMessages(error, notificationNamespace);
      }
    }),
    inventorySinkNameDescription: Ember.computed('flname', function () {
      return this.flname + ' (' + this.intl.t('pages.customers.nav.information') + ')';
    }),
    formattedBillingNameTask: (0, _emberConcurrency.task)(function* (type) {
      var customerAccountingInfo = yield this.get('customerAccountingInformation');
      var invoiceBillingInformation = customerAccountingInfo.filterBy('useType', type)[0];
      var defaultBillingInformation = customerAccountingInfo.filterBy('useType', 'default')[0];

      if (invoiceBillingInformation) {
        var fname = invoiceBillingInformation.get('firstName');
        var lname = invoiceBillingInformation.get('lastName');
        var name = fname + ' ' + lname;
        return invoiceBillingInformation.get('usePrimary') ? this.get('flname') : name;
      } else if (defaultBillingInformation) {
        var _fname = defaultBillingInformation.get('firstName');

        var _lname = defaultBillingInformation.get('lastName');

        var _name = _fname + ' ' + _lname;

        return defaultBillingInformation.get('usePrimary') ? this.get('flname') : _name;
      }

      return this.get('flname');
    }),
    formattedBillingAddressTask: (0, _emberConcurrency.task)(function* (type) {
      var customerAccountingInfo = yield this.get('customerAccountingInformation');
      var invoiceBillingInformation = customerAccountingInfo.filterBy('useType', type)[0];
      var defaultBillingInformation = customerAccountingInfo.filterBy('useType', 'default')[0];

      if (invoiceBillingInformation) {
        var address1 = invoiceBillingInformation.get('address1');
        var address2 = invoiceBillingInformation.get('address2');
        var city = invoiceBillingInformation.get('city');
        var state = invoiceBillingInformation.get('state');
        var zip = invoiceBillingInformation.get('zip');
        var mailingAddress = address1 + ' ' + (address2 || '') + '\n' + city + ' ' + state + ', ' + zip;
        return invoiceBillingInformation.get('usePrimary') ? this.get('oneLineFullAddress') : mailingAddress;
      } else if (defaultBillingInformation) {
        var _address = defaultBillingInformation.get('address1');

        var _address2 = defaultBillingInformation.get('address2');

        var _city = defaultBillingInformation.get('city');

        var _state = defaultBillingInformation.get('state');

        var _zip = defaultBillingInformation.get('zip');

        var _mailingAddress = _address + ' ' + (_address2 || '') + '\n' + _city + ' ' + _state + ', ' + _zip;

        return defaultBillingInformation.get('usePrimary') ? this.get('oneLineFullAddress') : _mailingAddress;
      }

      return this.get('fullAddress');
    }),
    isCancelled: Ember.computed('customerOrderInformation.customerIsCancelled', function () {
      return this.isCancelledTask.perform();
    }),
    isCancelledTask: (0, _emberConcurrency.task)(function* () {
      if (this.grants.getIndividualGrant('customer_order_information.view') && (yield this.get('customerOrderInformation.customerIsCancelled'))) {
        return true;
      }

      return false;
    }),
    showRiskScoreTask: (0, _emberConcurrency.task)(function* () {
      var orderInfo = this.grants.getIndividualGrant('customer_order_information.view') ? yield this.get('customerOrderInformation') : null;
      var isp = orderInfo ? yield orderInfo.get('interactiveServicesProvider') : null;
      var config = isp && this.grants.getIndividualGrant('interactive_services_provider_configs.manage') ? yield isp.get('interactiveServicesProviderConfig') : null;
      return config ? config.adcRiskScore : false;
    })
  });

  _exports.default = _default;
});