define("securitytrax-ember-ui/components/homepage/tech-today/component", ["exports", "securitytrax-ember-ui/mixins/grouped-equipment", "securitytrax-ember-ui/mixins/leaflet-map", "securitytrax-ember-ui/mixins/tech-today", "moment", "ember-concurrency"], function (_exports, _groupedEquipment, _leafletMap, _techToday, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_techToday.default, _groupedEquipment.default, _leafletMap.default, {
    tagName: '',
    store: Ember.inject.service(),
    showSummary: false,

    get techDescendants() {
      return this.getTechDescendantsTask.perform();
    },

    getTechDescendantsTask: (0, _emberConcurrency.task)(function* () {
      var allYields = [];
      var techChildren = [];
      var descendants = yield this.model.loggedInUser.getAllChildrenTask.perform();
      descendants.forEach(descendant => {
        allYields.push(this.checkDescendantAndAddTask.perform(descendant, techChildren));
      });
      return techChildren;
    }),
    checkDescendantAndAddTask: (0, _emberConcurrency.task)(function* (descendant, techChildren) {
      var isTech = yield descendant.get('isTech');

      if (isTech) {
        techChildren.push(descendant);
      }
    }),
    actions: {
      goBackADay() {
        var calendarDate = (0, _moment.default)(this.calendarDate);
        this.set('calendarDate', calendarDate.subtract(1, 'days'));
        this.notifyPropertyChange('model.calendarEventFilter');
      },

      goForwardADay() {
        var calendarDate = (0, _moment.default)(this.calendarDate);
        this.set('calendarDate', calendarDate.add(1, 'days'));
        this.notifyPropertyChange('model.calendarEventFilter');
      },

      refreshCalendarEventData() {
        this.notifyPropertyChange('model.calendarEventFilter');
      },

      showSummary() {
        this.set('showSummary', !this.showSummary);
      },

      transitionToMyInventory() {
        this.router.transitionTo('my-inventory.equipment-inventory');
      }

    }
  });

  _exports.default = _default;
});