define("securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/adc/loan-details/details/component", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AdcCreateFinancingDetails extends _component.default {}

  _exports.default = AdcCreateFinancingDetails;
});