define("securitytrax-ember-ui/administration/company-settings/system/global-settings/dashboard/controller", ["exports", "securitytrax-ember-ui/administration/company-settings/system/global-settings/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Controller = _controller.default.extend({});

  var _default = Controller;
  _exports.default = _default;
});