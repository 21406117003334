define("securitytrax-ember-ui/components/multi-step-form/forms/user-actions/add-user/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/switchable-form-step", "lodash"], function (_exports, _emberConcurrency, _switchableFormStep, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    orgTree: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    uncategorizedUsersEvent: Ember.inject.service('events/hierarchy'),
    hierarchyUsers: Ember.computed(function () {
      return this.getUsersTask.perform();
    }),
    selectedUser: null,
    saveComplete: true,
    resultsLoaded: true,

    init() {
      this._super(...arguments);
    },

    didInsertElement() {
      this._super(...arguments);

      this.set('modalButtons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.addUserTask.perform(buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary'
      }]);
    },

    getUsersTask: (0, _emberConcurrency.task)(function* () {
      var orgTree = this.orgTree;
      var userHierarchyTree = yield orgTree.get('userHierarchyTree');

      if (userHierarchyTree) {
        var uncategorizedUsers = yield userHierarchyTree.invokeAction('get_users_not_in_hierarchy');
        var directoryUser = yield this.directoryUsers;

        var users = _lodash.default.intersectionBy(directoryUser.toArray(), uncategorizedUsers.responsePayload.data, 'id');

        return users;
      }
    }),
    addUserTask: (0, _emberConcurrency.task)(function* (buttonPromise) {
      var orgTree = this.orgTree;
      var userHierarchyTree = yield orgTree.get('userHierarchyTree');

      try {
        this.set('saveComplete', false);
        var store = this.store;
        var selectedUser = yield this.get('selectedUser.user');
        var modalParentUser = yield this.modalParentUser;
        var userHierarchy = yield store.createRecord('userHierarchy', {
          user: selectedUser,
          userHierarchyTree: userHierarchyTree,
          parent: modalParentUser
        });
        yield userHierarchy.save();
        var siblings = modalParentUser.get('children').toArray();
        siblings.push(userHierarchy);
        modalParentUser.set('children', siblings);
        yield modalParentUser.save(); //notify parent list that it has new users

        /*   this.set('newGroupUsers', newUsers); */

        buttonPromise.then(() => {
          this.set('addUsersFormStarted', false);
          this.set('saveComplete', true);
          this.uncategorizedUsersEvent.trigger('update-user-count');
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
          this.changeRouteTask.perform();
        });
      } catch (err) {
        this.set('addUsersFormStarted', false);
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal'
        });
        (false && Ember.warn('Org Chart add users to user error' + err, {
          id: 'multiStepForm.forms.administration.permissions.addUsers.addUsersTask'
        }));
        throw err;
      }
    }),
    changeRouteTask: (0, _emberConcurrency.task)(function* () {
      var userHierarchyTree = yield this.get('orgTree.userHierarchyTree');
      var modalParentUser = this.modalParentUser;

      if (modalParentUser) {
        var userId = JSON.parse(this.get('modalParentUser.id'));
        this.router.transitionTo('/administration/org-chart/' + userHierarchyTree.get('id') + '/user/' + userId.user_id);
      } else {
        this.router.transitionTo('/administration/org-chart/' + userHierarchyTree.get('id'));
      }
    })
  });

  _exports.default = _default;
});