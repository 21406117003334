define("securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/adc/loan-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "553bRkBC",
    "block": "{\"symbols\":[\"&attrs\",\"@multiStepForm\",\"@formMode\"],\"statements\":[[11,\"div\"],[17,1],[12],[2,\"\\n    \"],[8,\"multi-step-form/progress-bar\",[],[[\"@additionalClass\",\"@config\",\"@modalButtons\"],[\"layout-center-middle\",[34,0],[32,2,[\"currentStepData\",\"buttons\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[11,\"div\"],[24,0,\"has-user-agreement\"],[4,[38,1],[[32,0,[\"buttons\"]]],null],[12],[2,\"\\n            \"],[8,\"html/st-await\",[],[[\"@useSpinner\",\"@booleanLoaded\",\"@size\"],[true,[32,0,[\"initDataTask\",\"isIdle\"]],\"large\"]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[8,\"html/section-divider\",[],[[\"@title\"],[[30,[36,2],[\"pages.customers.accounting.financing.loan_details\"],null]]],null],[2,\"\\n                \"],[8,\"multi-step-form/forms/customer/create-financing/adc/loan-details/details\",[],[[\"@multiStepForm\",\"@formMode\"],[[32,2],[32,3]]],null],[2,\"\\n\\n                \"],[8,\"multi-step-form/forms/customer/create-financing/re-usable-forms/user-agreement\",[],[[\"@userAgreed\",\"@onUserAgreed\",\"@agreementText\"],[[32,0,[\"disclosureAgreed\"]],[30,[36,3],[[32,0],\"buttons\"],null],[30,[36,2],[\"pages.customers.accounting.financing.smart_home_pay_agree\"],[[\"smartHomePayTermsLink\",\"privacyLink\",\"eCommunicationLink\"],[[32,0,[\"links\",\"link2\"]],[32,0,[\"links\",\"link3\"]],[32,0,[\"links\",\"link1\"]]]]]]],null],[2,\" \\n            \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"progressBarConfig\",\"did-insert\",\"t\",\"action\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/adc/loan-details/template.hbs"
    }
  });

  _exports.default = _default;
});