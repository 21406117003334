define("securitytrax-ember-ui/components/html/st-moment-format/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G7ypwzyN",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"args\",\"date\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[[32,0,[\"args\",\"date\"]],[32,0,[\"args\",\"outputFormat\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"moment-format\",\"if\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/st-moment-format/template.hbs"
    }
  });

  _exports.default = _default;
});