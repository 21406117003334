define("securitytrax-ember-ui/components/forms/select-add-dropdown/component", ["exports", "@glimmer/component", "lodash"], function (_exports, _component, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var SelectAddDropdown = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class SelectAddDropdown extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _defineProperty(this, "canDeleteNewItem", true);

      if (this.args.canCreateItems && (this.args.createNewItemAction === null || typeof this.args.createNewItemAction !== 'function')) {
        throw new Error('Must pass in createNewItemAction that returns an item');
      }
    }

    get tempItems() {
      return this.args.tempAddData;
    }

    updateArrayCallback() {
      if (typeof this.args.updateArray === 'function') {
        this.args.updateArray();
      }
    }

    addItemToArray(item) {
      if (!_lodash.default.find(this.args.addArray, {
        id: item.columnData[0]
      })) {
        Ember.set(item, 'inAddArray', true);
        this.args.addArray.push(item);
      }

      this.updateArrayCallback();
    }

    removeItemFromArray(item) {
      Ember.set(item, 'inAddArray', false);

      var index = _lodash.default.findIndex(this.args.addArray, _item => _item.columnData[0] == item.columnData[0]);

      if (index > -1) {
        this.args.addArray.splice(index, 1);
      }

      this.updateArrayCallback();
    }

    createNewItem(value) {
      var newItem = null;

      if (typeof this.args.createNewItemAction === 'function') {
        newItem = this.args.createNewItemAction(value);
      }

      if (newItem.data !== null) {
        this.args.tempAddData.rowData.push(newItem.data);

        if (newItem.data.inAddArray) {
          this.args.addArray.push(newItem.data);
          this.updateArrayCallback();
        }

        this.errorMessage = null;
        Ember.notifyPropertyChange(this, 'tempItems');
        Ember.notifyPropertyChange(this, 'errorMessage');
      } else {
        if (newItem.error !== null) {
          this.errorMessage = newItem.error;
        } else {
          this.errorMessage = this.intl.t('pages.customers.messages.bad_input');
        }

        Ember.notifyPropertyChange(this, 'errorMessage');
        return false;
      }

      return true;
    }

    deleteLineItem(index) {
      this.args.tempAddData.rowData.splice(index, 1);
      Ember.notifyPropertyChange(this, 'tempItems');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "addItemToArray", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "addItemToArray"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeItemFromArray", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "removeItemFromArray"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createNewItem", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "createNewItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteLineItem", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "deleteLineItem"), _class.prototype)), _class));
  _exports.default = SelectAddDropdown;
});