define("securitytrax-ember-ui/components/lead/lead-accounting/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    invoice: null,
    invoiceFormStarted: false,
    modalTitle: undefined,
    quoteDeleteId: false,
    model: Ember.computed(function () {
      return this.getModelTask.perform();
    }),
    actions: {
      transitionToSubroute(path) {
        this.router.transitionTo('leads.accounting.edit-create-quote', path);
      }

    }
  });

  _exports.default = _default;
});