define("securitytrax-ember-ui/components/multi-step-form/forms/accounting/accounting-invoice-sync-quickbooks/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "@glimmer/component", "lodash", "securitytrax-ember-ui/mixed-in-classes/table-column-sort"], function (_exports, _emberConcurrency, _switchableFormStep, _component, _lodash, _tableColumnSort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var AccountingSyncQuickbooks = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = (0, _emberConcurrency.task)(function* () {
    this.unsyncedInvoicesLoaded = false;
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
    var quickbooksIntegration = yield this.quickbooksService.findQuickbooksIntegrationTask.perform();
    var unsynced_invoices;

    if (quickbooksIntegration) {
      unsynced_invoices = yield quickbooksIntegration.invokeAction('get_invoices', {
        verb: 'POST',
        data: {
          data: {
            include_ignored: this.accountingShowIgnored,
            invoice_types: ['invoice']
          }
        }
      });
    } // deal with possible error when quickbooks in not actually connected


    var token_valid = _lodash.default.get(unsynced_invoices, 'responsePayload.meta.token_valid', false);

    if (token_valid === false) {
      this.stNotificationManager.addWarningMessages(this.intl.t('pages.administration.integrations.accountingProvidersSync.notConnectedWarning'), {
        notificationNamespace: 'modal'
      });
    }

    this.unsyncedInvoices = _lodash.default.get(unsynced_invoices, 'responsePayload.data', []);
    this.unsyncedInvoicesLoaded = true;
  }), _dec11 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var quickbooksIntegration = yield this.quickbooksService.findQuickbooksIntegrationTask.perform();

    if (quickbooksIntegration) {
      yield quickbooksIntegration.invokeAction('sync_invoices', {
        verb: 'POST',
        data: {
          data: this.unsyncedInvoices
        },
        processResponse: true
      });
    }

    var context = this;
    buttonPromise.then(() => {
      context.args.multiStepForm.formStartedSetVal(false);
    });
  }), _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, (_class = (_temp = class AccountingSyncQuickbooks extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "quickbooksService", _descriptor4, this);

      _initializerDefineProperty(this, "grants", _descriptor5, this);

      _initializerDefineProperty(this, "accountingShowIgnored", _descriptor6, this);

      _initializerDefineProperty(this, "unsyncedInvoices", _descriptor7, this);

      _initializerDefineProperty(this, "unsyncedInvoicesLoaded", _descriptor8, this);

      _initializerDefineProperty(this, "quickbooksId", _descriptor9, this);

      _initializerDefineProperty(this, "unsyncedInvoicesTask", _descriptor10, this);

      _initializerDefineProperty(this, "syncInvoicesToQuickbooks", _descriptor11, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.TableColumnSort = new _tableColumnSort.default(...arguments);
      this.unsyncedInvoicesTask.perform();
    }

    sortList(column, ascending) {
      var oldAscending = ascending;
      this.TableColumnSort.sortList(column, ascending);
      var sortByNumbers = isNaN(this.unsyncedInvoices[0][column]) === false;
      var sortedArray = Ember.A(_lodash.default.sortBy(this.unsyncedInvoices, function (item) {
        if (sortByNumbers) {
          return Number(item[column]);
        } else {
          return item[column];
        }
      }));

      if (!oldAscending) {
        sortedArray = Ember.A(_lodash.default.reverse(sortedArray));
      }

      Ember.set(this, 'unsyncedInvoices', sortedArray);
    }

    showIgnoredChanged() {
      this.unsyncedInvoicesTask.perform();
    }

    updateSyncCheckbox(currRow, value) {
      if (value) {
        Ember.set(currRow, 'to_sync', '1');

        if (currRow.ignored === '1') {
          Ember.set(currRow, 'ignored', '0');
        }
      } else {
        Ember.set(currRow, 'to_sync', '0');
      }
    }

    updateIgnoreCheckbox(currRow, value) {
      if (value) {
        Ember.set(currRow, 'ignored', '1');

        if (currRow.to_sync === '1') {
          Ember.set(currRow, 'to_sync', '0');
        }
      } else {
        Ember.set(currRow, 'ignored', '0');
      }
    }

    buttons() {
      if (this.grants.getIndividualGrant('sync_object_to_providers.sync_objects')) {
        this.args.multiStepForm.registerStepData('accountingSyncStep', 'buttons', [{
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.syncInvoicesToQuickbooks.perform(buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary sync-invoices-to-quickbooks-btn'
        }, {
          text: this.intl.t('pages.administration.integrations.accountingProviders.selectAll'),
          click: () => {
            this.unsyncedInvoicesLoaded = false;
            var newUnsyncedInvoices = this.unsyncedInvoices;
            this.unsyncedInvoices = [];
            Ember.run.later(() => {
              for (var index = 0; index < newUnsyncedInvoices.length; index++) {
                var currUnsyncedInvoice = newUnsyncedInvoices[index];

                if (currUnsyncedInvoice.can_be_synced === '1') {
                  currUnsyncedInvoice.to_sync = '1';
                }

                currUnsyncedInvoice.ignored = '0';
              }

              this.unsyncedInvoices = newUnsyncedInvoices;
              this.unsyncedInvoicesLoaded = true;
            });
          },
          isDelete: true,
          classNamesString: 'st-btn st-modal__button--delete sync-select-all'
        }, {
          text: this.intl.t('pages.administration.integrations.accountingProviders.ignoreAll'),
          click: () => {
            this.unsyncedInvoicesLoaded = false;
            var newUnsyncedInvoices = this.unsyncedInvoices;
            this.unsyncedInvoices = [];
            Ember.run.later(() => {
              for (var index = 0; index < newUnsyncedInvoices.length; index++) {
                var currUnsyncedInvoice = newUnsyncedInvoices[index];
                currUnsyncedInvoice.to_sync = '0';
                currUnsyncedInvoice.ignored = '1';
              }

              this.unsyncedInvoices = newUnsyncedInvoices;
              this.unsyncedInvoicesLoaded = true;
            });
          },
          isDelete: true,
          classNamesString: 'st-btn st-modal__button--delete sync-ignore-all'
        }]);
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "quickbooksService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "accountingShowIgnored", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "unsyncedInvoices", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "unsyncedInvoicesLoaded", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "quickbooksId", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "unsyncedInvoicesTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "syncInvoicesToQuickbooks", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "sortList", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "sortList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showIgnoredChanged", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "showIgnoredChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateSyncCheckbox", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "updateSyncCheckbox"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateIgnoreCheckbox", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "updateIgnoreCheckbox"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "buttons", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "buttons"), _class.prototype)), _class));
  _exports.default = AccountingSyncQuickbooks;
});