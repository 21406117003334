define("securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-notes/notes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FFkIj1Ba",
    "block": "{\"symbols\":[\"multiStepForm\"],\"statements\":[[6,[37,16],null,[[\"useSpinner\",\"booleanLoaded\"],[false,[30,[36,15],[[35,10],true],null]]],[[\"default\"],[{\"statements\":[[6,[37,14],null,[[\"class\",\"formSteps\",\"formOriginalModels\",\"formStarted\",\"changeFormStarted\",\"onAfterSave\"],[\"row\",[35,13],[35,12],[35,10],[30,[36,0],[[32,0],[30,[36,11],[[35,10]],null]],null],[30,[36,0],[[32,0],\"refreshModel\"],null]]],[[\"default\"],[{\"statements\":[[6,[37,9],null,[[\"modalButtons\",\"title\",\"footerText\",\"classNames\",\"dialogOpen\",\"hideTopRightCloseButton\",\"onClose\",\"multiStepForm\"],[[32,1,[\"currentStepData\",\"buttons\"]],[32,1,[\"currentStepData\",\"title\"]],[32,1,[\"currentStepData\",\"footerText\"]],\"st-modal--medium\",[32,1,[\"formStarted\"]],[32,1,[\"currentStepData\",\"hideTopRightCloseButton\"]],[30,[36,0],[[32,0],\"transitionBackToParentRoute\"],null],[32,1]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,8],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],[[\"modelName\",\"customer\",\"taskUsersList\",\"noteTypes\",\"communicationReasons\",\"communicationDispositions\",\"formMode\",\"multiStepForm\",\"transitionBackToParent\"],[\"customerNote\",[35,7],[30,[36,3],[[35,6]],null],[30,[36,3],[[35,5]],null],[30,[36,3],[[35,4]],null],[30,[36,3],[[35,2]],null],[35,1],[32,1],[30,[36,0],[[32,0],\"transitionBackToParentRoute\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"customerNoteFormMode\",\"communicationDispositions\",\"await\",\"communicationReasons\",\"noteTypes\",\"taskUsersList\",\"customer\",\"component\",\"modals/st-paper\",\"formStarted\",\"mut\",\"customerNoteFormOriginalModels\",\"customerNoteFormSteps\",\"multi-step-form\",\"eq\",\"html/st-await\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/modals/sub-route-modals/edit-create-notes/notes/template.hbs"
    }
  });

  _exports.default = _default;
});