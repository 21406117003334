define("securitytrax-ember-ui/home/tech-today/appointment/create-task/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O9hZ3xgk",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"customer\",\"taskId\",\"parentRoute\",\"taskTemplates\",\"toolbox\"],[[35,0,[\"customer\"]],[35,0,[\"customerTaskId\"]],[35,0,[\"parentRoute\"]],[35,0,[\"taskTemplates\"]],true]]]]],\"hasEval\":false,\"upvars\":[\"model\",\"modals/sub-route-modals/edit-create-notes/tasks\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/home/tech-today/appointment/create-task/template.hbs"
    }
  });

  _exports.default = _default;
});