define("securitytrax-ember-ui/customers/at-risk-entries/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bcU1MklW",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"customer\"],[[35,0,[\"customer\"]]]]]],[2,\"\\n\\n\"],[8,\"customer/user-defined-fields\",[],[[\"@customer\",\"@model\",\"@udfContext\"],[[34,0,[\"customer\"]],[34,0],\"customers_at_risk_entries\"]],null],[2,\"\\n\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"model\",\"customer/at-risk-entries-open-closed\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/customers/at-risk-entries/template.hbs"
    }
  });

  _exports.default = _default;
});