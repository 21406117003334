define("securitytrax-ember-ui/components/multi-step-form/forms/system/stages/manage-chat/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "99fqNZgq",
    "block": "{\"symbols\":[\"data\",\"@multiStepForm\",\"@formMode\"],\"statements\":[[8,\"multi-step-form/progress-bar\",[],[[\"@config\",\"@modalButtons\"],[[32,0,[\"SwitchableFormStep\",\"progressBarConfig\"]],[32,2,[\"currentStepData\",\"buttons\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[11,\"div\"],[4,[38,0],[[32,0,[\"buttons\"]]],null],[12],[2,\"\\n        \"],[8,\"html/st-await\",[],[[\"@useSpinner\",\"@booleanLoaded\",\"@size\"],[true,[30,[36,1],[[32,0,[\"baseInitDataTask\",\"isIdle\"]],[32,0,[\"initDataTask\",\"isIdle\"]]],null],\"large\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"html/section-divider\",[],[[\"@title\"],[[32,0,[\"manageChatSectionLabel\"]]]],null],[2,\"\\n\\n            \"],[10,\"div\"],[14,0,\"grid-x grid-padding-x align-stretch\"],[12],[2,\"\\n                \"],[10,\"div\"],[14,0,\"cell small-12 medium-6 large-3\"],[12],[2,\"\\n                    \"],[8,\"multi-step-form/switchable-inputs/checkbox-input\",[],[[\"@formMode\",\"@value\",\"@label\",\"@toggleSwitch\"],[[32,3],[32,0,[\"updateChat\"]],[32,0,[\"manageChatLabel\"]],true]],null],[2,\"\\n                \"],[13],[2,\"\\n\\n                \"],[10,\"div\"],[14,0,\"cell small-12 medium-6 large-3\"],[12],[2,\"\\n                    \"],[8,\"multi-step-form/switchable-inputs/select-input\",[],[[\"@label\",\"@options\",\"@value\",\"@valueProperty\",\"@formMode\"],[[30,[36,2],[\"pages.customers.system.order.device_type\"],null],[32,0,[\"deviceTypes\"]],[32,0,[\"siteInformation\",\"device_type\"]],\"id\",[32,3]]],null],[2,\"\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"and\",\"t\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/system/stages/manage-chat/template.hbs"
    }
  });

  _exports.default = _default;
});