define("securitytrax-ember-ui/validations/consumer-financing-lender-config", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation", "lodash"], function (_exports, _validate, _baseValidation, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var noValidate = _lodash.default.get(data, 'extraValidationData.noValidate', false);

      var validateObject = {
        username: {
          presence: () => {
            if (noValidate && noValidate.includes('consumerFinancingLenderConfig-username')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'consumerFinancingLenderConfig-username');
            }
          }
        },
        password: {
          presence: () => {
            if (noValidate && noValidate.includes('consumerFinancingLenderConfig-password')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'consumerFinancingLenderConfig-password');
            }
          }
        },
        apiKey: {
          presence: () => {
            if (noValidate && noValidate.includes('consumerFinancingLenderConfig-apiKey')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'consumerFinancingLenderConfig-apiKey');
            }
          }
        },
        merchantUid: {
          presence: () => {
            if (noValidate && noValidate.includes('consumerFinancingLenderConfig-merchantUid')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'consumerFinancingLenderConfig-merchantUid');
            }
          }
        },
        environment: {
          presence: () => {
            if (noValidate && noValidate.includes('consumerFinancingLenderConfig-environment')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'consumerFinancingLenderConfig-environment');
            }
          }
        },
        shpLosBearerToken: {
          presence: () => {
            if (noValidate && noValidate.includes('consumerFinancingLenderConfig-apiToken')) {
              return false;
            } else {
              return {
                allowEmpty: false,
                message: '^' + data.intlService.t('common.validations.presence', {
                  propertyLabel: data.intlService.t('pages.customers.accounting.financing.api_token')
                })
              };
            }
          }
        },
        shpLosSecret: {
          presence: () => {
            if (noValidate && noValidate.includes('consumerFinancingLenderConfig-webhookSecret')) {
              return false;
            } else {
              return {
                allowEmpty: false,
                message: '^' + data.intlService.t('common.validations.presence', {
                  propertyLabel: data.intlService.t('pages.customers.accounting.financing.webhook_secret')
                })
              };
            }
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      return this.outputFormatting(validationData, 'consumerFinancingLenderConfig');
    }

  });

  _exports.default = _default;
});