define("securitytrax-ember-ui/helpers/st-filters-service-custom-filter-callback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    stFiltersService: Ember.inject.service('st-filters-service'),

    compute(args) {
      var [callbackName, callbackData] = args;

      var callbackFunction = () => {
        var results = this.stFiltersService.customFilters[callbackName](callbackData);
        return results;
      };

      return callbackFunction;
    }

  });

  _exports.default = _default;
});