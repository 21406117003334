define("securitytrax-ember-ui/components/multi-step-form/forms/administration/inventory/edit-create-rma/create-rma/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/list/row-list", "securitytrax-ember-ui/mixins/switchable-form-step"], function (_exports, _emberConcurrency, _rowList, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableFormStep.default, _rowList.default, {
    intl: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    inventoryService: Ember.inject.service(),
    recipient: null,
    equipment: null,
    equipmentToVendors: null,
    lineItemsLoaded: false,
    formStarted: false,
    rmaLineItemDeleteId: false,
    totalItemCount: 0,
    company: Ember.computed(function () {
      return this.sessionData.getCompany();
    }),
    recipients: Ember.computed(function () {
      return this.inventoryService.get('getInventoryRecipients').perform();
    }),
    equipmentReturnReasons: Ember.computed(function () {
      return this.store.findAll('equipmentReturnReason');
    }),
    formSteps: Ember.computed('formMode', 'multiStepForm.formCopiedModels.equipmentReturnMerchandiseAuthorization.model.equipmentVendor.id', 'recipient.id', function () {
      var intl = this.intl;
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: intl.t("pages.administration.company_settings.inventory.rma.review_title", {
            recipient: this.get('recipient.fname') ? this.get('recipient.fname') + ' ' + this.get('recipient.lname') : this.get('recipient.name'),
            vendor: this.multiStepForm.formCopiedModelsGetVal('equipmentReturnMerchandiseAuthorization.model.equipmentVendor.name')
          }),
          stepComponentPath: 'multi-step-form/forms/administration/inventory/edit-create-rma/review'
        })
      });
    }),
    totalItemCountObserver: Ember.observer('totalItemCount', function () {
      if (this.totalItemCount > 0) {
        this.registerButtons();
      } else {
        this.registerButtons(true);
      }
    }),
    getLineItemCount: (0, _emberConcurrency.task)(function* () {
      var count = 0;
      var lineItems = yield this.listArray;
      lineItems.forEach(item => {
        if (item.quantity) {
          count += parseInt(item.quantity);
        }
      });
      this.set('totalItemCount', count);
    }),
    vendorOptions: Ember.computed('recipient', function () {
      return this.vendorOptionsTask.perform();
    }),
    vendorOptionsTask: (0, _emberConcurrency.task)(function* () {
      var userInventorySettings = this.recipient ? yield this.recipient.get('userInventorySetting') : null;
      var vendorToRecipients = userInventorySettings ? yield userInventorySettings.get('equipmentVendorToRecipients') : this.recipient ? yield this.recipient.get('equipmentVendorToRecipients') : null; // PERF TODO: includes on relationship calls

      var vendors = [];
      var allTasks = [];

      if (vendorToRecipients) {
        for (var i = 0; i < vendorToRecipients.get('length'); i++) {
          var vendorToRecipient = vendorToRecipients.objectAt(i);

          try {
            allTasks.push(this.getVendorTask.perform(vendorToRecipient, vendors));
          } catch (err) {
            (false && Ember.warn('failed to get equipment vendor ' + err, {
              id: 'admin.inventory.admin.inventory.edit-create-rma.create-rma'
            }));
          }
        }

        yield (0, _emberConcurrency.all)(allTasks);
      }

      return vendors;
    }),
    getVendorTask: (0, _emberConcurrency.task)(function* (vendorToRecipient, vendors) {
      var vendor = yield vendorToRecipient.get('equipmentVendor');

      if (vendor) {
        vendors.push(vendor);
      }
    }),
    initFormData: (0, _emberConcurrency.task)(function* () {
      this.addRow();
      yield (0, _emberConcurrency.waitForProperty)(this.multiStepForm, 'finishedCopyingModels');
      var copiedModel = this.multiStepForm.formCopiedModelsGetVal('equipmentReturnMerchandiseAuthorization.model');
      this.set('recipient', this.rma ? copiedModel.get('recipient') : copiedModel.get('user') ? copiedModel.get('user') : copiedModel.get('location'));
    }).on('init'),

    didInsertElement() {
      this._super(...arguments);

      this.registerButtons(true);
    },

    openReviewModal: (0, _emberConcurrency.task)(function* (multiStepForm) {
      var formattedObjects = this.prepForValidate('pendingEquipmentLineItem');
      formattedObjects.push(Ember.Object.create({
        model: yield this.multiStepForm.formCopiedModelsGetVal('equipmentReturnMerchandiseAuthorization.model'),
        extraValidationData: {
          noValidate: this.multiStepForm.formCopiedModelsGetVal('equipmentReturnMerchandiseAuthorization.model.user.id') ? ['equipmentReturnMerchandiseAuthorization-location'] : ['equipmentReturnMerchandiseAuthorization-user']
        },
        filePath: 'equipment-rma'
      }));
      yield multiStepForm.validateList.perform(formattedObjects);
      this.set('formStarted', true);
    }),
    recipientOrVendorChangedObserver: Ember.observer('recipient.id', 'multiStepForm.formCopiedModels.equipmentReturnMerchandiseAuthorization.model.equipmentVendor', function () {
      if (!(this.multiStepForm.formCopiedModelsGetVal('equipmentReturnMerchandiseAuthorization.model.id') > 0)) {
        var translationKey = 'pages.administration.company_settings.inventory.rma.create_title' + (this.multiStepForm.formCopiedModelsGetVal('equipmentReturnMerchandiseAuthorization.model.equipmentVendor.id') > 0 ? '_extended' : '');
        var titleData = {
          name: this.get('recipient.flname') || this.get('recipient.name'),
          vendor: this.multiStepForm.formCopiedModelsGetVal('equipmentReturnMerchandiseAuthorization.model.equipmentVendor.name')
        };
        this.multiStepForm.registerStepData("step1", 'title', this.intl.t(translationKey, titleData));
      }
    }),
    getEquipmentInventory: (0, _emberConcurrency.task)(function* (model) {
      var allTasks = [];
      this.set('lineItemsLoaded', false);

      if (model && model.constructor.modelName !== 'equipment-vendor') {
        this.multiStepForm.formCopiedModelsSetVal('equipmentReturnMerchandiseAuthorization.model.equipmentVendor', null);
      }

      if (this.get('recipient.id')) {
        this.multiStepForm.formCopiedModelsSetVal(this.recipient.constructor.modelName === 'inventory-user' ? 'equipmentReturnMerchandiseAuthorization.model.user' : 'equipmentReturnMerchandiseAuthorization.model.location', this.recipient);

        if (this.multiStepForm.formCopiedModelsGetVal('equipmentReturnMerchandiseAuthorization.model.equipmentVendor.id')) {
          var equipmentToVendors = yield this.store.query('equipmentToVendor', {
            limit: 0,
            filter: 'vendor_id = ' + this.multiStepForm.formCopiedModelsGetVal('equipmentReturnMerchandiseAuthorization.model.equipmentVendor.id'),
            include: 'equipment.equipment_type,equipment_kit.equipment_type'
          });
          this.set('equipmentToVendors', equipmentToVendors);
          this.set('equipment', []);

          for (var i = 0; i < equipmentToVendors.get('length'); i++) {
            allTasks.push(this.getItemTask.perform(equipmentToVendors.objectAt(i)));
          }

          yield (0, _emberConcurrency.all)(allTasks);
          this.set('lineItemsLoaded', true);
        }
      }
    }),
    getItemTask: (0, _emberConcurrency.task)(function* (equipmentToVendor) {
      var item = (yield equipmentToVendor.get('equipment')) || (yield equipmentToVendor.get('equipmentKit'));

      if (item && item.constructor.modelName === 'equipment') {
        // BOWLESTODO: Don't allow kits until TRAX-7971 is complete
        item.set('vendorSku', equipmentToVendor.get('sku'));
        this.equipment.push(item);
      }
    }),
    saveRmaData: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      multiStepForm = this.multiStepForm;

      if (this.isNewRecord) {
        var formattedObjects = this.prepForValidate('pendingEquipmentLineItems');
        formattedObjects.push(Ember.Object.create({
          modelName: 'equipmentReturnMerchandiseAuthorization',
          model: yield this.multiStepForm.formCopiedModelsGetVal('equipmentReturnMerchandiseAuthorization.model'),
          extraValidationData: {
            noValidate: this.multiStepForm.formCopiedModelsGetVal('equipmentReturnMerchandiseAuthorization.model.user.id') ? ['equipmentReturnMerchandiseAuthorization-location'] : ['equipmentReturnMerchandiseAuthorization-user']
          },
          filePath: 'equipment-rma'
        })); //validate line items first, then set copied model with ember data

        var toCreateObjects = yield multiStepForm.validateCreateList.perform(formattedObjects, {
          notificationNamespace: 'modal'
        });
        var lineItems = toCreateObjects['pendingEquipmentLineItems'].map(lineItem => {
          // BOWLESTODO: Uncomment to allow kits when TRAX-7971 is complete
          // if (lineItem.data.equipment.constructor.modelName === 'equipment-kit') {
          //     lineItem.data.equipmentKit = lineItem.data.equipment;
          //     lineItem.data.equipment = null;
          // }
          return this.store.createRecord('pendingEquipmentLineItem', lineItem.data);
        });
        var newRma = this.store.createRecord('equipmentReturnMerchandiseAuthorization', {
          pendingEquipmentType: 'rma',
          equipmentVendor: this.multiStepForm.formCopiedModelsGetVal('equipmentReturnMerchandiseAuthorization.model.equipmentVendor'),
          user: this.recipient.constructor.modelName.camelize() === 'inventoryUser' ? yield this.recipient.get('user') : null,
          location: this.recipient.constructor.modelName.camelize() === 'location' ? this.recipient : null,
          comments: toCreateObjects.equipmentReturnMerchandiseAuthorization[0].data.comments,
          status: 'open',
          pendingEquipmentLineItems: lineItems
        });
        yield toCreateObjects.equipmentReturnMerchandiseAuthorization[0].saveWithRollbackAndNotification(newRma, 'modal2');
      }

      buttonPromise.then(() => {
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        multiStepForm.formStartedSetVal(false);
      });
    }),
    deleteRmaLineItemTask: (0, _emberConcurrency.task)(function* (row) {
      yield this.deleteRow.perform(row, 'pendingEquipmentLineItem');
      this.multiStepForm.formCopiedModelsSetVal('equipmentReturnMerchandiseAuthorization.model.pendingEquipmentLineItems', this.listArray);
      this.set('rmaLineItemDeleteId', false);
    }),

    registerButtons(disabled = false) {
      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData("step1", 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: () => {
          return this.openReviewModal.perform(multiStepForm);
        },
        disabled,
        classNamesString: 'st-btn st-btn-primary po-review-save-button'
      }]);
    },

    actions: {
      deleteRmaLineItem(row) {
        this.deleteRmaLineItemTask.perform(row);
      },

      updateEquipmentItemsList(row, index) {
        var lineItem = row;
        lineItem.set('comments', row.get('comments'));
        lineItem.set('fulfilledQuantity', row.get('fulfilledQuantity') || 0);
        lineItem.set('cost', Number(row.get('equipment.cost')));
        lineItem.set('totalCost', row.get('quantity') * Number(row.get('equipment.cost')));
        lineItem.set('quantity', row.get('quantity'));
        lineItem.set('equipment', row.get('equipment'));
        lineItem.set('returnReason', row.get('equipmentReturnReason'));
        this.updateRow(lineItem, index);
        this.multiStepForm.formCopiedModelsSetVal('equipmentReturnMerchandiseAuthorization.model.pendingEquipmentLineItems', this.listArray);
        this.getLineItemCount.perform();
      }

    }
  });

  _exports.default = _default;
});