define("securitytrax-ember-ui/components/calendar-glimmer/calendar-event/event-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gkS7HdE0",
    "block": "{\"symbols\":[\"@calendarEventNameSpace\"],\"statements\":[[8,\"html/st-await\",[],[[\"@loadedNamespace\",\"@useSpinner\",\"@booleanLoaded\"],[[32,1],true,[30,[36,0],[[32,0,[\"getStatusTimeTask\",\"isIdle\"]],[32,0,[\"formModeTask\",\"isIdle\"]],[32,0,[\"updateStatusTask\",\"isIdle\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"multi-step-form/switchable-inputs/time-input\",[],[[\"@class\",\"@formMode\",\"@value\",\"@changeAction\",\"@allowClear\",\"@renderInPlaceOverride\",\"@endTime\",\"@startTime\",\"@format12hour\"],[\"calendar-event__departure-time\",[32,0,[\"formMode\"]],[32,0,[\"statusTime\"]],[30,[36,1],[[32,0],\"changeAction\"],null],false,false,[32,0,[\"endTime\"]],[32,0,[\"startTime\"]],true]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"and\",\"action\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/calendar-glimmer/calendar-event/event-status/template.hbs"
    }
  });

  _exports.default = _default;
});