define("securitytrax-ember-ui/administration/company-settings/content-management/customer-notes-contact-settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qgEfOTjG",
    "block": "{\"symbols\":[],\"statements\":[[8,\"nav/top-nav-sub\",[],[[\"@breadCrumbs\",\"@breadCrumbData\"],[[32,0,[\"breadCrumbs\"]],[32,0,[\"breadCrumbData\"]]]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"page-content\"],[12],[2,\"\\n    \"],[8,\"pages/administration/company-settings/content-management/customer-notes-contact-settings\",[],[[\"@settingType\"],[\"communicationReason\"]],null],[2,\"\\n\\n    \"],[8,\"pages/administration/company-settings/content-management/customer-notes-contact-settings\",[],[[\"@settingType\"],[\"communicationDisposition\"]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/administration/company-settings/content-management/customer-notes-contact-settings/template.hbs"
    }
  });

  _exports.default = _default;
});