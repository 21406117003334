define("securitytrax-ember-ui/models/customer-at-risk", ["exports", "securitytrax-ember-ui/models/base_models/customer-at-risk", "ember-concurrency", "moment"], function (_exports, _customerAtRisk, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _customerAtRisk.default.extend({
    intl: Ember.inject.service(),
    createdAtFormatted: Ember.computed('createdAt', function () {
      if (this.createdAt) {
        return this.intl.formatDate(this.createdAt);
      }

      return this.createdAt;
    }),
    calculatedIsResolvedColor: Ember.computed('isResolved', function () {
      return this.isResolved ? '#26b844' : '#f58220';
    }),
    calculatedIsResolvedText: Ember.computed('isResolved', function () {
      var intl = this.intl;
      return this.isResolved ? intl.t('pages.customers.at_risk_entries.resolved') : intl.t('pages.home.not_resolved');
    }),
    contactAttempts: Ember.computed('id', 'customerAtRiskNotes.[]', function () {
      return this.contactAttemptsTask.perform();
    }),
    contactAttemptsTask: (0, _emberConcurrency.task)(function* () {
      return (yield this.get('customerAtRiskNotes')).toArray().reverse();
    }),
    nextContact: Ember.computed('followUpDate', 'isOverdue', function () {
      var intl = this.intl;
      var followUpDate = (0, _moment.default)(this.followUpDate).format('MM/DD/YYYY');
      var tomorrow = (0, _moment.default)().add(1, 'days').format('MM/DD/YYYY');

      if (this.isOverdue) {
        return intl.t('pages.home.past_due');
      } else if (followUpDate === (0, _moment.default)().format('MM/DD/YYYY')) {
        return intl.t('pages.home.today');
      } else if (followUpDate === tomorrow) {
        return intl.t('pages.home.tomorrow');
      }

      return followUpDate && _moment.default.isMoment(followUpDate) ? followUpDate : intl.t('pages.home.not_set');
    }),
    isOverdue: Ember.computed('followUpDate', function () {
      if (this.followUpDate) {
        var dueDate = _moment.default.isMoment(this.followUpDate) ? this.followUpDate.endOf('day') : (0, _moment.default)(this.followUpDate).endOf('day');
        return (0, _moment.default)().isAfter(dueDate);
      }

      return false;
    }),
    atRiskEntryDropdownFormat: Ember.computed(function () {
      return "".concat(this.customerAtRiskReason.get('name'), " | ").concat(this.customerAtRiskReasonCause.get('name'), " | ").concat(this.isResolved ? 'Resolved' : 'Unresolved');
    })
  });

  _exports.default = _default;
});