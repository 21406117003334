define("securitytrax-ember-ui/models/tag", ["exports", "securitytrax-ember-ui/models/base_models/tag"], function (_exports, _tag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _tag.default.extend({
    tagColor: Ember.computed('color', function () {
      return Ember.String.htmlSafe('background-color: ' + this.color + ';');
    }),
    cssName: Ember.computed('name', function () {
      return Ember.String.dasherize(this.name || '');
    }),
    tagType: Ember.computed('objectType', function () {
      return this.intl.exists("pages.administration.system.tags.tagTypes.".concat(this.objectType)) ? this.intl.t("pages.administration.system.tags.tagTypes.".concat(this.objectType)) : this.objectType.replace(/[_,-]/g, ' ').replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    })
  });

  _exports.default = _default;
});