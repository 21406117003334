define("securitytrax-ember-ui/components/payroll/payee-assignments/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var PayeeAssignments = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = (0, _emberConcurrency.task)(function* () {
    var hashedData = yield (0, _emberConcurrency.hash)({
      availablePayrollRoleFunctions: this.grants.getIndividualGrant('payable_payee_levels.view') ? this.args.object.get('availablePayrollRoleFunctions') : [],
      payrollRoleFunctions: this.store.findAll('payrollRoleFunction'),
      groupedFunctionLevels: this.groupedFunctionLevelsTask.perform()
    });
    this.availablePayrollRoleFunctions = hashedData.availablePayrollRoleFunctions;
    this.payrollRoleFunctions = hashedData.payrollRoleFunctions.filterBy('active', true);
    this.groupedFunctionLevels = hashedData.groupedFunctionLevels;
  }), _dec11 = (0, _emberConcurrency.task)(function* () {
    try {
      var childTasks = [];
      var hashData = yield (0, _emberConcurrency.hash)({
        payrollToFunctionLevels: this.args.object.get('payablePayeeLevels'),
        payrollRoles: this.store.findAll('payrollRole', {
          include: 'payroll_list_methods.payroll_role_functions.payroll_role_function_levels.payroll_column'
        })
      });
      hashData.payrollToFunctionLevels.map(level => {
        childTasks.push(this.mapLevelsTask.perform(level));
      });
      var allLevels = yield (0, _emberConcurrency.all)(childTasks);
      return allLevels.uniqBy('role.name').map(({
        role
      }) => {
        if (role) {
          return Ember.Object.create({
            key: role.get('name').toLowerCase(),
            items: allLevels.filterBy('role.name', role.get('name')).mapBy('level')
          });
        }
      });
    } catch (error) {
      (false && Ember.warn('groupedFunctionLevelsTask ' + error, {
        id: 'users.human-resources.groupedFunctionLevelsTask'
      }));
    }
  }), _dec12 = (0, _emberConcurrency.task)(function* (level) {
    try {
      var payrollRoleFunctionLevel = yield level.get('payrollRoleFunctionLevel');
      var payrollRoleFunction = payrollRoleFunctionLevel ? yield payrollRoleFunctionLevel.get('payrollRoleFunction') : null;
      var role = payrollRoleFunction ? yield payrollRoleFunction.get('payrollRole') : null;
      return {
        level,
        role
      };
    } catch (error) {
      (false && Ember.warn('mapLevelsTask ' + error, {
        id: 'users.human-resources.mapLevelsTask'
      }));
    }
  }), _dec13 = Ember._action, _dec14 = Ember._action, (_class = (_temp = class PayeeAssignments extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "grants", _descriptor3, this);

      _initializerDefineProperty(this, "intl", _descriptor4, this);

      _initializerDefineProperty(this, "objectToPayrollRoleFunctionStarted", _descriptor5, this);

      _initializerDefineProperty(this, "objectToPayrollRoleFunctionMode", _descriptor6, this);

      _initializerDefineProperty(this, "payablePayeeLevel", _descriptor7, this);

      _initializerDefineProperty(this, "groupedFunctionLevels", _descriptor8, this);

      _initializerDefineProperty(this, "availablePayrollRoleFunctions", _descriptor9, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor10, this);

      _initializerDefineProperty(this, "groupedFunctionLevelsTask", _descriptor11, this);

      _initializerDefineProperty(this, "mapLevelsTask", _descriptor12, this);

      this.initDataTask.perform();
    }

    get cardClass() {
      return this.args.cardClass || '';
    }

    get objectToPayrollRoleFunction() {
      var translationKey = this.objectToPayrollRoleFunctionMode === 'create' ? 'pages.users.human_resources.payee_assignments.create_title' : 'pages.users.human_resources.payee_assignments.edit_title';
      var titleData = {
        userName: this.args.object.get('flname') || this.args.object.get('name'),
        functionName: this.payablePayeeLevel ? this.payablePayeeLevel.get('payrollRoleFunctionLevel.payrollRoleFunction.name') : ''
      };
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.intl.t(translationKey, titleData),
          stepComponentPath: 'multi-step-form/forms/administration/users/payee-assignments'
        })
      });
    }

    get objectToPayrollRoleFunctionOriginalModels() {
      return Ember.Object.create({
        payablePayeeLevel: {
          model: this.payablePayeeLevel,
          copyRelationMap: {
            payrollRoleFunctionLevel: {
              doCopy: true,
              doValidate: false,
              doSave: false
            }
          }
        }
      });
    }

    toggleTagView() {
      this.toggleProperty('tagViewCollapsed');
    }

    editAction(row) {
      if (this.grants.getIndividualGrant('payable_payee_levels.modify')) {
        this.formMode = 'edit';
      }

      this.objectToPayrollRoleFunctionMode = 'edit';
      this.payablePayeeLevel = row;
      this.objectToPayrollRoleFunctionStarted = true;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "objectToPayrollRoleFunctionStarted", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "objectToPayrollRoleFunctionMode", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'view';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "payablePayeeLevel", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "groupedFunctionLevels", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "availablePayrollRoleFunctions", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "groupedFunctionLevelsTask", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "mapLevelsTask", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleTagView", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "toggleTagView"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editAction", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "editAction"), _class.prototype)), _class));
  _exports.default = PayeeAssignments;
});