define("securitytrax-ember-ui/my-account/information/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7cvLYO1U",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"user\",\"mobileCarriers\",\"tags\",\"model\"],[[35,0,[\"user\"]],[35,0,[\"mobileCarriers\"]],[35,0,[\"tags\"]],[35,0]]]]]],\"hasEval\":false,\"upvars\":[\"model\",\"pages/my-account/information\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/my-account/information/template.hbs"
    }
  });

  _exports.default = _default;
});