define("securitytrax-ember-ui/administration/company-settings/content-management/work-order-types/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UQKaow4H",
    "block": "{\"symbols\":[],\"statements\":[[8,\"pages/administration/company-settings/content-management/work-order-types\",[],[[\"@breadCrumbs\",\"@breadCrumbData\"],[[32,0,[\"breadCrumbs\"]],[32,0,[\"breadCrumbData\"]]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/administration/company-settings/content-management/work-order-types/template.hbs"
    }
  });

  _exports.default = _default;
});