define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/customer-create-chooser/component", ["exports", "ember-concurrency", "@glimmer/component"], function (_exports, _emberConcurrency, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CustomerCreateChooser = (_dec = Ember.inject.service('intl'), _dec2 = Ember.inject.service('events/dynamic-form-chooser'), _dec3 = Ember.inject.service('store'), _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = (0, _emberConcurrency.task)(function* () {
    var workFlows = yield this.store.findAll('workflowConfig', {
      include: 'workflow'
    });
    var activeWorkFlows = workFlows.filter(workFlow => {
      return workFlow.get('active') && workFlow.get('workflow.workflowType') === this.workflowType;
    });
    var workFlow = yield activeWorkFlows[0].get('workflow');
    this.currentCreateId = workFlow.id;
    return activeWorkFlows;
  }), _dec10 = (0, _emberConcurrency.task)(function* () {
    var completedWorkFlows = [];
    var activeWorkFlows = yield this.getActiveWorkFlowsTask.perform();
    this.workFlowConfigsMap = {};

    for (var i = 0; i < activeWorkFlows.length; i++) {
      var activeWorkflow = yield activeWorkFlows.objectAt(i).get('workflow');
      completedWorkFlows.push({
        packageName: activeWorkFlows.objectAt(i).get('name'),
        packageId: activeWorkflow.get('id'),
        packageContent: [{
          name: activeWorkFlows.objectAt(i).get('description')
        }]
      });
      this.workFlowConfigsMap[activeWorkflow.get('id')] = activeWorkFlows.objectAt(i).get('config');
    }

    return completedWorkFlows;
  }), (_class = (_temp = class CustomerCreateChooser extends _component.default {
    get dasherizedCurrentCreateId() {
      return this.currentCreateId.dasherize();
    }

    get currentWorkflowConfig() {
      if (this.workFlowConfigsMap) {
        return this.workFlowConfigsMap[this.currentCreateId];
      }

      return null;
    }

    get createFormChooserButtons() {
      return [{
        text: this.intl.t('common.actions.start'),
        click: buttonPromise => {
          buttonPromise.then(() => {
            this.createFormChooserOpen = false;
            this.dynamicFormChooser.trigger('open-dynamic-form', {
              id: this.currentCreateId,
              customer: this.customer
            });
          });
        },
        classNamesString: 'st-btn st-btn-primary create-form-chooser-start'
      }];
    }

    get customerCreateOptions() {
      return this.customerCreateOptionsTask.perform();
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "dynamicFormChooser", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "currentCreateId", _descriptor4, this);

      _initializerDefineProperty(this, "createFormChooserOpen", _descriptor5, this);

      _initializerDefineProperty(this, "workflowType", _descriptor6, this);

      _initializerDefineProperty(this, "createCustomerFormStarted", _descriptor7, this);

      _initializerDefineProperty(this, "customer", _descriptor8, this);

      _initializerDefineProperty(this, "getActiveWorkFlowsTask", _descriptor9, this);

      _initializerDefineProperty(this, "customerCreateOptionsTask", _descriptor10, this);

      this.dynamicFormChooser.on('open-dynamic-form-chooser', this, '_dynamicCallbackOpen');
      this.dynamicFormChooser.on('setup-dynamic-form-chooser', this, '_dynamicCallbackSetup');
      this.dynamicFormChooser.on('open-dynamic-form', this, '_dynamicCallback');
    }

    willDestroy() {
      this.dynamicFormChooser.off('open-dynamic-form-chooser', this, '_dynamicCallbackOpen');
      this.dynamicFormChooser.off('setup-dynamic-form-chooser', this, '_dynamicCallbackSetup');
      this.dynamicFormChooser.off('open-dynamic-form', this, '_dynamicCallback');
    }

    _dynamicCallbackOpen(workflowType, customer) {
      this.workflowType = workflowType;
      this.customer = customer;
      this.createFormChooserOpen = true;
      Ember.notifyPropertyChange(this, 'customerCreateOptions');
    }

    _dynamicCallbackSetup() {
      Ember.notifyPropertyChange(this, 'customerCreateOptions');
    }

    _dynamicCallback(options) {
      this.currentCreateId = options.id;
      this.customer = options.customer;
      this.createCustomerFormStarted = true;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dynamicFormChooser", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentCreateId", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'single-page';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "createFormChooserOpen", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "workflowType", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "createCustomerFormStarted", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "customer", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "getActiveWorkFlowsTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "customerCreateOptionsTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CustomerCreateChooser;
});