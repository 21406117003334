define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/customer-create-chooser/create-form-configs/lead-single-page/component", ["exports", "securitytrax-ember-ui/mixins/customer-create-chooser"], function (_exports, _customerCreateChooser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_customerCreateChooser.default, {});

  _exports.default = _default;
});