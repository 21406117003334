define("securitytrax-ember-ui/components/multi-step-form/forms/leads/add-interaction/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixins/forms/customer-lead-shared", "moment"], function (_exports, _emberConcurrency, _customerLeadShared, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_customerLeadShared.default, {
    intl: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      this.setupButtons();
    },

    setupButtons: function () {
      var multiStepForm = this.multiStepForm;

      if (this.formMode != 'view') {
        multiStepForm.registerStepData('interaction', 'buttons', [{
          text: this.intl.t('common.actions.save'),
          classNamesString: 'st-btn st-btn-primary lead-information-save-button',
          click: buttonPromise => {
            return this.addInteractionTask.perform(multiStepForm, buttonPromise);
          }
        }]);
        multiStepForm.registerStepData('interaction', 'title', this.intl.t('pages.leads.add_interaction') + ' - ' + (0, _moment.default)().format('MMM DD, YYYY - h:mm A'));
      } else {
        multiStepForm.registerStepData('interaction', 'title', this.intl.t('pages.leads.view_interaction'));
      }
    },

    willDestroy() {
      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData('interaction', 'buttons', []);
    },

    leadContactUser: Ember.computed('multiStepForm.formCopiedModels.leadContact', function () {
      var user = this.multiStepForm.formCopiedModels.leadContact ? this.multiStepForm.formCopiedModels.leadContact.model.user : null;
      return user;
    }),
    addInteractionTask: (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
      try {
        var lead = this.lead;
        this.multiStepForm.formCopiedModelsSetVal('leadContact.model.customer', lead);
        var toCreateObjects = yield multiStepForm.validateCreateAll({
          extraValidationData: {
            validationMessages: this.validationMessages
          },
          notificationNamespace: 'addLeadInteractionModal'
        });
        var newLeadContact = this.store.createRecord('leadContact', toCreateObjects.leadContact.data);
        yield toCreateObjects.leadContact.saveWithRollbackAndNotification(newLeadContact);
        buttonPromise.then(() => {
          if (this.transitionBackToParent && typeof this.transitionBackToParent === 'function') {
            this.transitionBackToParent();
          }

          multiStepForm.formStartedSetVal(false);
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        });
      } catch (error) {
        (false && Ember.warn('error in saveLeadInteractionTask: ' + error, {
          id: 'multiStepForm.forms.leads.addInteraction'
        }));
        throw error;
      }
    })
  });

  _exports.default = _default;
});