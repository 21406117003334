define("securitytrax-ember-ui/components/calendar-glimmer/calendar-month-view/calendar-day/component", ["exports", "@glimmer/component", "moment"], function (_exports, _component, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CalendarDay extends _component.default {
    get isSelected() {
      return (0, _moment.default)(this.args.day.date).format('D MMMM YYYY') == (0, _moment.default)(this.args.selectedDay.date).format('D MMMM YYYY');
    }

    get isToday() {
      return (0, _moment.default)(this.args.day.date).format('D MMMM YYYY') == (0, _moment.default)().format('D MMMM YYYY');
    }

  }

  _exports.default = CalendarDay;
});