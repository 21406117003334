define("securitytrax-ember-ui/validations/customer-order-information", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateObject = {
        monitoringCompany: {
          presence: () => {
            if (data.isCreate === true && data.extraValidationData.newPreCheck === false) {
              return false;
            } else if (data.extraValidationData.newPreCheck) {
              return this.customMessageOrDefault(data, 'orderInformation-monitoringCompany');
            }
          }
        },
        repUser: {
          presence: () => {
            if ('extraValidationData' in data && 'adtValidation' in data.extraValidationData) {
              return false;
            }

            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('orderInformation-salesRep')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'orderInformation-salesRep');
            }
          }
        },
        monitoringPlan: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('orderInformation-monitoringPlan')) {
              return false;
            }

            if ('extraValidationData' in data && 'adtValidation' in data.extraValidationData) {
              return this.customMessageOrDefault(data, 'orderInformation-monitoringPlan');
            }

            return false;
          }
        },
        contractTerm: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('orderInformation-contractTerm')) {
              return false;
            }

            if ('extraValidationData' in data && 'adtValidation' in data.extraValidationData) {
              return this.customMessageOrDefault(data, 'orderInformation-contractTerm');
            }

            return false;
          }
        },
        mmr: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('orderInformation-mmr')) {
              return false;
            }

            if ('extraValidationData' in data && 'adtValidation' in data.extraValidationData) {
              return this.customMessageOrDefault(data, 'orderInformation-mmr');
            }

            return false;
          }
        },
        customerCancelDate: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('orderInformation-customerCancelDate')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'orderInformation-customerCancelDate');
            }
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject, {
        fullMessages: this.prependProperty(data)
      });
      return this.outputFormatting(validationData, 'orderInformation');
    }

  });

  _exports.default = _default;
});