define("securitytrax-ember-ui/components/multi-step-form/forms/administration/users/inventory/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/administration/users/base-users", "ember-concurrency"], function (_exports, _baseUsers, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseUsers.default.extend({
    usStates: Ember.inject.service(),
    intl: Ember.inject.service(),
    enhancedFeatures: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    equipmentDetailsFormStarted: false,
    equipmentDetailsFormMode: 'edit',
    vendorDetailsFormStarted: false,
    vendorDetailsFormMode: 'edit',

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;

      if (!this.isNewRecord) {
        multiStepForm.registerStepData("inventory", 'buttons', [{
          text: this.intl.t('common.actions.cancel'),
          click: () => {
            multiStepForm.resetForm(false);
            this.set('inventoryFormMode', 'view');
          },
          classNamesString: 'st-btn btn-secondary user-cancel-button'
        }, {
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            var noValidateArray = ['fname', 'lname', 'email', 'username', 'password', 'group'];
            return this.saveUserDataTask.perform(multiStepForm, buttonPromise, noValidateArray);
          },
          classNamesString: 'st-btn st-btn-primary user-save-button'
        }]);
      }
    },

    hasInventoryFeature: Ember.computed(function () {
      return this.hasFeatureTask.perform();
    }),
    hasFeatureTask: (0, _emberConcurrency.task)(function* () {
      var isEnabled = yield this.enhancedFeatures.hasFeature('inventoryExtendedFunctionality');
      return isEnabled;
    }),
    equipmentDetailsFormSteps: Ember.computed('equipmentDetailsFormMode', function () {
      var intl = this.intl;
      return Ember.Object.create({
        equipmentDetails: Ember.Object.create({
          stepNameKey: 'equipmentDetails',
          title: this.get('user.flname') + ' ' + intl.t('pages.users.inventory.equipment_details.title'),
          stepComponentPath: 'multi-step-form/forms/administration/inventory/edit-equipment-details'
        })
      });
    }),
    vendorDetailsFormSteps: Ember.computed('vendorDetailsFormMode', function () {
      var intl = this.intl;
      return Ember.Object.create({
        vendorDetails: Ember.Object.create({
          stepNameKey: 'vendorDetails',
          title: this.get('user.flname') + ' ' + intl.t('pages.users.inventory.vendor_details.title'),
          stepComponentPath: 'multi-step-form/forms/administration/inventory/edit-vendor-details'
        })
      });
    })
  });

  _exports.default = _default;
});