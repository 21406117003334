define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/nrg/view-schedule-availability/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BiJcmJ1I",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[11,\"div\"],[4,[38,0],[[32,0,[\"setupButtons\"]]],null],[12],[2,\"\\n    \"],[11,\"div\"],[17,1],[12],[2,\"\\n        \"],[8,\"multi-step-form/progress-bar\",[],[[\"@config\",\"@modalButtons\",\"@loadedNamespaces\"],[[34,1,[\"progressBarConfig\"]],[34,1,[\"currentStepData\",\"buttons\"]],[30,[36,3],[[35,2],\"step6\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"multiStepForm\",\"currFormName\",\"concat\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/nrg/view-schedule-availability/template.hbs"
    }
  });

  _exports.default = _default;
});