define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/nrg/econtract/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Up3JLOrb",
    "block": "{\"symbols\":[\"data\",\"&attrs\"],\"statements\":[[11,\"div\"],[4,[38,0],[[32,0,[\"setupButtons\"]]],null],[12],[2,\"\\n    \"],[11,\"div\"],[17,2],[12],[2,\"\\n        \"],[8,\"multi-step-form/progress-bar\",[],[[\"@config\",\"@modalButtons\",\"@loadedNamespaces\"],[[34,1,[\"progressBarConfig\"]],[34,1,[\"currentStepData\",\"buttons\"]],[30,[36,3],[[35,2],\"step11\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"html/st-await\",[],[[\"@booleanLoaded\",\"@useSpinner\",\"@size\"],[[30,[36,5],[[35,4],[29]],null],true,\"medium\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\\n                \"],[10,\"div\"],[14,0,\"st-card__cell\"],[12],[2,\"\\n                    \"],[8,\"customer/integrations/electronic-documents\",[],[[\"@customer\",\"@showTitle\"],[[34,4],true]],null],[2,\"\\n                \"],[13],[2,\"\\n            \"]],\"parameters\":[1]}]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"multiStepForm\",\"currFormName\",\"concat\",\"newCustomer\",\"not-eq\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/generic-steps/customer-create/nrg/econtract/template.hbs"
    }
  });

  _exports.default = _default;
});