define("securitytrax-ember-ui/services/send-preview-email", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * Service to send and print content
   *
   * @class SendService
   * @extends Ember.Service
   * @memberof services
   * @mixes Ember.Evented
   */
  var _default = Ember.Service.extend({
    stAjax: Ember.inject.service(),

    getPreview(model, printActionName = 'print') {
      return this.getPreviewTask.perform(model, printActionName);
    },

    getPreviewTask: (0, _emberConcurrency.task)(function* (model, printActionName) {
      try {
        var response = yield model.invokeAction(printActionName !== null && printActionName !== void 0 ? printActionName : 'print', {
          verb: 'GET'
        });
        return response.responsePayload.data;
      } catch (error) {
        (false && Ember.warn('Preview task' + error, {
          id: 'send-preview-email.getPreviewTask'
        }));
        throw error;
      }
    }),

    sendEmail(model, data, type) {
      return this.sendEmailTask.perform(model, data, type);
    },

    sendEmailTask: (0, _emberConcurrency.task)(function* (model, data, type) {
      try {
        yield model.invokeAction('send', {
          verb: 'POST',
          data: {
            data: {
              meta: data.meta || {},
              attributes: _objectSpread({
                to_addresses: data.toAddress,
                subject: data.subject,
                html_body: data.message
              }, data.extraData),
              type
            }
          }
        });
        return true;
      } catch (error) {
        (false && Ember.warn('Send taks ' + error, {
          id: 'send-preview-email.sendEmailTask'
        }));
        throw error;
      }
    })
  });

  _exports.default = _default;
});