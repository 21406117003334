define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/monitoring-companies/integrations/stages-integration/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "securitytrax-ember-ui/mixed-in-classes/monitoring-company-connection", "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/monitoring-companies/integrations/integrations-base"], function (_exports, _emberConcurrency, _switchableFormStep, _monitoringCompanyConnection, _integrationsBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var StagesIntegration = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
    var config = this.args.multiStepForm.formCopiedModelsGetVal('monitoringCompany.model.monitoringCompanyConfig');
    this.deviceTypes = config.get('enabled') && this.args.multiStepForm.formOriginalModelsGetVal('monitoringCompany.model.isConnected') ? yield this.deviceTypesTask.perform() : [];
    this.listenInDeviceTypes = config.get('enabled') && this.args.multiStepForm.formOriginalModelsGetVal('monitoringCompany.model.isConnected') ? yield this.listenInDeviceTypesTask.perform() : [];
    this.deviceTypesWhitelist = [];
    this.listenInDeviceTypesWhitelist = [];

    if (config.get('deviceTypeWhitelist')) {
      config.get('deviceTypeWhitelist').forEach(deviceTypeId => {
        var deviceType = this.deviceTypes.findBy('id', deviceTypeId);

        if (deviceType) {
          this.deviceTypesWhitelist.push(deviceType);
        }
      });
    }

    if (config.get('listenInDeviceTypeWhitelist')) {
      config.get('listenInDeviceTypeWhitelist').forEach(deviceTypeId => {
        var deviceType = this.listenInDeviceTypes.findBy('id', deviceTypeId);

        if (deviceType) {
          this.listenInDeviceTypesWhitelist.push(deviceType);
        }
      });
    }

    this.buttons(this.args.multiStepForm);

    if (config.get('siteGroupLinks')) {
      this.siteGroupLinks = config.get('siteGroupLinks');
    }
  }), _dec11 = (0, _emberConcurrency.task)(function* () {
    var deviceTypes = [];

    try {
      deviceTypes = yield this.centralStationService.getData({
        model: this.args.monitoringCompany,
        method: 'stages__getDeviceTypes',
        payload: {
          full_list: true
        }
      });
      deviceTypes = deviceTypes.device_types;
    } catch (err) {
      var message = this.intl.t('pages.customers.system.order.stages_retrieve_error', {
        data: this.intl.t('pages.customers.system.order.device_types')
      });
      this.stNotificationManager.addWarningMessages(err, {
        notificationNamespace: 'modal',
        messagePrefix: message
      });
    }

    return deviceTypes;
  }), _dec12 = (0, _emberConcurrency.task)(function* () {
    var deviceTypes = [];

    try {
      deviceTypes = yield this.centralStationService.getData({
        model: this.args.monitoringCompany,
        method: 'stages__getListenInDeviceTypes',
        payload: {
          full_list: true
        }
      });
      deviceTypes = deviceTypes.listen_in_device_types;
    } catch (err) {
      var message = this.intl.t('pages.customers.system.order.stages_retrieve_error', {
        data: this.intl.t('pages.customers.system.order.listen_in_device_types')
      });
      this.stNotificationManager.addWarningMessages(err, {
        notificationNamespace: 'modal',
        messagePrefix: message
      });
    }

    return deviceTypes;
  }), _dec13 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var multiStepForm = this.args.multiStepForm;
    var siteGroupLinks = this.siteGroupLinks;
    var deviceTypesWhitelist = this.deviceTypesWhitelist.map(deviceType => {
      return deviceType.id;
    });
    var listenInDeviceTypesWhitelist = this.listenInDeviceTypesWhitelist.map(deviceType => {
      return deviceType.id;
    });
    var overrideConfig = Ember.Object.create({
      monitoringCompany: {
        model: this.args.monitoringCompany,
        copyRelationMap: {
          monitoringCompanyType: {
            readOnly: true
          },
          monitoringCompanyConfig: {}
        }
      }
    });
    multiStepForm.formCopiedModelsSetVal('monitoringCompany.model.monitoringCompanyConfig.deviceTypeWhitelist', deviceTypesWhitelist);
    multiStepForm.formCopiedModelsSetVal('monitoringCompany.model.monitoringCompanyConfig.listenInDeviceTypeWhitelist', listenInDeviceTypesWhitelist);

    if (typeof siteGroupLinks === 'string') {
      var splitGroupLinks = siteGroupLinks.replace(' ', '').split(',');
      multiStepForm.formCopiedModelsSetVal('monitoringCompany.model.monitoringCompanyConfig.siteGroupLinks', splitGroupLinks);
    }

    yield multiStepForm.validateSyncSaveAll({
      extraValidationData: {
        noValidate: ['accountNumberBlock-startNumber', 'accountNumberBlock-receiverNumber', 'accountNumberBlock-monitoringCompany', 'monitoringCompanyConfig-newPassword', 'monitoringCompanyConfig-confirmNewPassword', 'monitoringCompanyConfig-username', 'monitoringCompanyConfig-password', 'monitoringCompanyConfig-keyCode', 'monitoringCompanyConfig-usernameId', 'monitoringCompanyConfig-dealerNumber', 'monitoringCompanyConfig-partnerId']
      },
      overrideOriginalModelsConfig: overrideConfig,
      notificationNamespace: 'modal',
      successNotificationNamespace: 'modal'
    });
    yield this.initFormData.perform();
    buttonPromise.then(() => {
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
    });
  }), _dec14 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    yield this.validateCredentialsTask.perform(buttonPromise, this.initFormData);
  }), (_class = (_temp = class StagesIntegration extends _integrationsBase.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "centralStationService", _descriptor4, this);

      _initializerDefineProperty(this, "siteGroupLinks", _descriptor5, this);

      _initializerDefineProperty(this, "deviceTypesWhitelist", _descriptor6, this);

      _initializerDefineProperty(this, "deviceTypes", _descriptor7, this);

      _initializerDefineProperty(this, "listenInDeviceTypesWhitelist", _descriptor8, this);

      _initializerDefineProperty(this, "listenInDeviceTypes", _descriptor9, this);

      _initializerDefineProperty(this, "initFormData", _descriptor10, this);

      _initializerDefineProperty(this, "deviceTypesTask", _descriptor11, this);

      _initializerDefineProperty(this, "listenInDeviceTypesTask", _descriptor12, this);

      _initializerDefineProperty(this, "saveMonitoringCompanyConfig", _descriptor13, this);

      _initializerDefineProperty(this, "stagesValidateCredentials", _descriptor14, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.MonitoringCompanyConnection = new _monitoringCompanyConnection.default(...arguments);
      this.initFormData.perform();
      Ember.run.later(() => {
        this.buttons(this.args.multiStepForm);
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "siteGroupLinks", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "deviceTypesWhitelist", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "deviceTypes", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "listenInDeviceTypesWhitelist", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "listenInDeviceTypes", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "initFormData", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "deviceTypesTask", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "listenInDeviceTypesTask", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "saveMonitoringCompanyConfig", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "stagesValidateCredentials", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = StagesIntegration;
});