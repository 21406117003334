define("securitytrax-ember-ui/services/st-ajax", ["exports", "ember-ajax/services/ajax", "ember-simple-auth-token/mixins/token-authorizer", "securitytrax-ember-ui/config/environment", "securitytrax-ember-ui/utils/obj-helpers", "ember-concurrency", "lodash"], function (_exports, _ajax, _tokenAuthorizer, _environment, _objHelpers, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend(_tokenAuthorizer.default, {
    session: Ember.inject.service(),
    profile: Ember.inject.service(),
    stMulticallService: Ember.inject.service(),
    accessedSinceLastClear: undefined,
    currentAccessedNamespace: undefined,
    securityTraxResponseUri: undefined,

    init() {
      this._super(...arguments);

      this.connectionId = null;
      (0, _objHelpers.initializeState)(this, {
        headers: this.defaultHeaders(),
        trustedHosts: ['localhost'],
        accessedSinceLastClear: {},
        currentAccessedNamespace: 'global'
      });
    },

    setAccessedNamespace: function (namespace) {
      var namespaceSlashes = namespace.replace(/[.]/g, '/');
      Ember.set(this, 'currentAccessedNamespace', namespaceSlashes);

      if (this.accessedSinceLastClear[namespaceSlashes]) {
        Ember.set(this.accessedSinceLastClear, "".concat(namespaceSlashes), {
          open: false,
          ajaxCalls: {},
          totalAjaxCalls: 0,
          totalTimeInAjaxCallsMilliseconds: 0
        });
      }
    },

    addAccessed(path, totalTimeMilliseconds) {
      /////////////////////////////////////////////////////////////////////////
      // setup accessed default object data
      if (!this.accessedSinceLastClear[this.currentAccessedNamespace]) {
        Ember.set(this.accessedSinceLastClear, "".concat(this.currentAccessedNamespace), {
          open: false,
          ajaxCalls: {},
          totalAjaxCalls: 0,
          totalTimeInAjaxCallsMilliseconds: 0
        });
      }

      Ember.set(this.accessedSinceLastClear, "".concat(this.currentAccessedNamespace, ".totalAjaxCalls"), this.accessedSinceLastClear[this.currentAccessedNamespace]['totalAjaxCalls'] + 1);
      Ember.set(this.accessedSinceLastClear, "".concat(this.currentAccessedNamespace, ".totalTimeInAjaxCallsMilliseconds"), this.accessedSinceLastClear[this.currentAccessedNamespace]['totalTimeInAjaxCallsMilliseconds'] + totalTimeMilliseconds);
      this.accessedSinceLastClear[this.currentAccessedNamespace]['ajaxCalls'][path] = totalTimeMilliseconds; // set(this.accessedSinceLastClear, `${this.currentAccessedNamespace}.ajaxCalls`, this.accessedSinceLastClear[this.currentAccessedNamespace]['totalTimeMilliseconds'] + totalTimeMilliseconds);
    },

    request() {
      var options = arguments[1];

      if (typeof arguments[1] === 'undefined') {
        arguments[1] = {
          headers: this.defaultHeaders()
        };
      } else if (typeof arguments[1]['headers'] === 'undefined') {
        arguments[1].headers = this.defaultHeaders();
      }

      var startTime = new Date().getTime();
      var request;

      if (_environment.default['ember-cli-mirage']['enabled'] === false && options && options.multicallOptions) {
        request = this.stMulticallService.getMulticallData(options.multicallOptions, {
          url: arguments[0],
          verb: 'GET',
          body: {}
        });
      } else {
        request = this._super(...arguments);
      }

      if (request.xhr) {
        request.xhr.done(msg => {
          msg;
          var totalTime = new Date().getTime() - startTime;
          this.addAccessed(request._label, totalTime);
        });
      }

      return request;
    },

    watchToken: Ember.observer('session.session.authenticated.token', function () {
      // leaving this here for future debugging
      // const refreshLeeway = ENV['ember-simple-auth-token']['refreshLeeway'];
      // const sessionExp = this.get('session.session.authenticated.exp');
      // const willAuthrefresh = sessionExp - refreshLeeway;
      // const timeTillAuthrefresh = moment.duration(moment().diff(moment.unix(willAuthrefresh)));
      // console.log('##############################################');
      // console.log('refreshLeeway', refreshLeeway);
      // console.log('timeTillAuthrefresh', timeTillAuthrefresh.humanize());
      // console.log('willAuthrefresh', moment.unix(willAuthrefresh).format('M/D/YYYY h:mm a'));
      // console.log('sessionExp', moment.uniam x(sessionExp).format('M/D/YYYY h:mm a'));
      // console.log('session', this.get('session.session.authenticated.token'));
      // console.log('--------------------------------------------------------------------');
      this.set('headers', this.defaultHeaders());
    }),
    watchConnectionId: Ember.observer('connectionId', function () {
      this.set('headers', this.defaultHeaders());
    }),
    host: Ember.computed(function () {
      return _environment.default['securitytrax-ember-ui']['api-uri'] || '';
    }),

    defaultHeaders() {
      var headers = {
        'content-type': 'application/vnd.api+json',
        accept: 'application/vnd.api+json',
        authorization: 'Bearer ' + this.getAuthToken()
      };
      var integratorKey = this.getIntegratorKey();

      if (integratorKey) {
        headers['X-SecurityTrax-IntegratorKey'] = integratorKey;
      }

      if (this.getConnectionId()) {
        headers['X-SecurityTrax-ConnectionId'] = this.getConnectionId();
      }

      return headers;
    },

    defaultHeadersStrings() {
      var defaultHeaders = this.defaultHeaders();
      var headersStringArray = [];

      for (var property in defaultHeaders) {
        headersStringArray.push("".concat(property, ": ").concat(defaultHeaders[property]));
      }

      return headersStringArray;
    },

    getSecurityTraxResponseUri() {
      if (this.securityTraxResponseUri) {
        return this.securityTraxResponseUri;
      }

      var multicallCurrUrl;

      var apiUri = _lodash.default.get(_environment.default, 'securitytrax-ember-ui.api-uri', false);

      if (apiUri) {
        multicallCurrUrl = apiUri;
      } else {
        multicallCurrUrl = window.location.href;
      }

      var currUrl = new URL(multicallCurrUrl);
      var multicallProtocalAndHost = "".concat(currUrl.protocol, "//").concat(currUrl.host);
      var securityTraxResponseUri = multicallProtocalAndHost;
      Ember.set(this, 'securityTraxResponseUri', securityTraxResponseUri);
      return securityTraxResponseUri;
    },

    getSecurityTraxResponseUriHeaderString() {
      var securityTraxResponseUri = this.getSecurityTraxResponseUri();
      var securityTraxResponseUriStr = "X-SecurityTrax-Response-Uri: ".concat(encodeURIComponent(securityTraxResponseUri));
      return securityTraxResponseUriStr;
    },

    namespace: Ember.computed(function () {
      var profile = this.profile.get();

      if (_environment.default.environment === 'test' && (_environment.default['securitytrax-ember-ui']['api-e2e-test-config']['local-tests']['runTests'] || _environment.default['securitytrax-ember-ui']['api-e2e-test-config']['ci-tests']['runTests'])) {
        if (_environment.default['securitytrax-ember-ui']['api-e2e-test-config']['profileForApiTests']) {
          profile = _environment.default['securitytrax-ember-ui']['api-e2e-test-config']['profileForApiTests'];
        } else {
          profile = 'sandbox';
        }
      }

      return '/' + profile + _environment.default['securitytrax-ember-ui']['api-version-info'];
    }),
    headers: undefined,

    setHeaders(headers) {
      return this.set('headers', headers);
    },

    resetHeaders() {
      return this.resetHeadersTask.perform();
    },

    resetHeadersTask: (0, _emberConcurrency.task)(function* () {
      return yield this.set('headers', this.defaultHeaders());
    }),

    getAuthToken() {
      if (this.session.isAuthenticated && this.session.data) {
        var {
          token
        } = this.get('session.data.authenticated');

        if (Ember.isPresent(token)) {
          return token;
        }
      }

      return null;
    },

    getIntegratorKey() {
      return _environment.default['securitytrax-ember-ui']['api-integrator-key'];
    },

    setConnectionId(connectionId) {
      Ember.set(this, 'connectionId', connectionId);
    },

    getConnectionId() {
      return this.connectionId;
    },

    apiUrl(url) {
      var namespace = this.namespace ? this.namespace : 'sandbox';
      var uri = this.host + namespace; //return immediately if we already have a fully built URI

      if (url.startsWith(uri)) {
        return url;
      }

      return uri + url;
    },

    getEncodedRelativeUrlWithParams(requestUrl, queryParams) {
      var url;

      try {
        url = new URL(requestUrl);
      } catch (e) {
        return requestUrl;
      }

      var params = new URLSearchParams(url.search);

      for (var property in queryParams) {
        params.append(property, encodeURIComponent(queryParams[property]));
      }

      var paramsString = params.toString();

      if (paramsString) {
        return "".concat(url.pathname, "?").concat(paramsString);
      } else {
        return "".concat(url.pathname);
      }
    },

    getUrl(relativeUrl) {
      return this.apiUrl(relativeUrl);
    }

  });

  _exports.default = _default;
});