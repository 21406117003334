define("securitytrax-ember-ui/models/zip-relation", ["exports", "securitytrax-ember-ui/models/base_models/zip-relation"], function (_exports, _zipRelation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _zipRelation.default.extend({});

  _exports.default = _default;
});