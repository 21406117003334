define("securitytrax-ember-ui/models/monitoring-company-type", ["exports", "securitytrax-ember-ui/models/base_models/monitoring-company-type"], function (_exports, _monitoringCompanyType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _monitoringCompanyType.default.extend({
    logo: Ember.computed(function () {
      switch (this.id) {
        case '1':
          return 'icn-adt-small';

        case '2':
          return 'icn-rapid';

        case '3':
          return 'icn-brinks';

        case '4':
          return 'icn-security-networks';

        case '5':
          return 'icn-legacy';

        case '7':
          return 'icn-gaurdian';

        case '8':
          return 'icn-avant';

        case '11':
          return 'icn-ucc';

        case '12':
          return 'icn-dynamark';

        case '13':
          return 'icn-legacy';

        case '14':
          return 'icn-guardian';

        case '17':
          return 'icn-affiliated';

        case '18':
          return 'icn-security-partners';

        case '24':
          return 'icn-cms';

        case '25':
          return 'icn-cops';

        case '27':
          return 'icn-adt-canada';

        case '28':
          return 'icn-safe';

        case '34':
          return 'icn-adc';

        case '36':
          return 'icn-atw';

        case '37':
          return 'icn-api-monitoring';

        case '39':
          return 'icn-alarm-central';

        default:
          return 'icn-system';
      }
    })
  });

  _exports.default = _default;
});