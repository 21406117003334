define("securitytrax-ember-ui/components/multi-step-form/forms/notes/notes/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/notes/base-notes"], function (_exports, _baseNotes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseNotes.default.extend({//anything unique to notes goes here
  });

  _exports.default = _default;
});