define("securitytrax-ember-ui/components/html/multi-select/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    singleSelectOnly: false,
    filterValue: '',
    filteredList: Ember.A([]),
    selectedPropertyName: 'multiSelectComponentItemIsSelected',
    selectedPropertyClass: null,
    selectedItems: Ember.A([]),
    selectedItemsValueProperty: 'id',
    exceptSelector: undefined,
    placeholderText: '',
    isBase: false,
    showPagination: true,
    tagName: '',
    allSelected: undefined,
    selectedItemsChanged: Ember.observer('selectedItems.[]', function () {
      this.setupList.perform();
    }),
    initTask: (0, _emberConcurrency.task)(function* () {
      yield this.setupList.perform();
      this.set('exceptSelector', ['.multi-select-wrapper', '.multi-select-wrapper--base', '.selected-options__selected-option--remove', '.create-new-item-option', '.single-input-create-wrapper']);
    }).on('init'),
    setupList: (0, _emberConcurrency.task)(function* () {
      yield this.listData;
      var selectedItems = yield this.selectedItems;
      var selectedPropertyName = yield this.selectedPropertyName; // check if any selected value is not in the listData, if not fetch it

      for (var i = 0; i < selectedItems.length; i++) {
        var selectedObject = yield selectedItems.objectAt(i);
        var modelName = selectedObject.constructor && selectedObject.constructor.modelName ? selectedObject.constructor.modelName.camelize() : null;

        if (modelName && selectedObject && !this.listData.findBy('id', Ember.get(selectedObject, 'id'))) {
          try {
            var objectToAdd = yield this.store.findRecord(modelName, Ember.get(selectedObject, 'id'));
            this.listData.pushObject(objectToAdd);
          } catch (error) {
            (false && Ember.warn('multi-select add missing selectedItem...' + error, {
              id: 'multiStepForm.switchableInputs.selectInput.id'
            }));
          }
        }
      }

      if (this.sortBy) {
        this.set('filteredList', this.listData.sortBy(this.sortBy));
      } else {
        this.set('filteredList', this.listData);
      }

      var mappedSelectedItems = {};

      for (var index = 0; index < selectedItems.length; index++) {
        var currSelectedItem = selectedItems[index];
        mappedSelectedItems[currSelectedItem[this.selectedItemsValueProperty]] = selectedItems;
      }

      var allChildTasks = [];
      this.allSelected = true;

      if (this.listData && (this.listData.get('length') > 0 || this.listData.meta && this.listData.meta.total > 0)) {
        this.listData.forEach(listItem => {
          // if the list item property is a promise - meaning it's already going to be set when the promise fulfills - then we need to wait
          // this should be fixed to work a different way - instead of doing the check in 2 different ways
          allChildTasks.push(this.childTask.perform(listItem, selectedPropertyName, mappedSelectedItems));
        });
      }

      yield (0, _emberConcurrency.all)(allChildTasks);

      if (this.allSelected && this.listData.get('length') > 0) {
        var item = Array.isArray(this.listData) ? this.listData[0] : this.listData.objectAt(0);
        var itemIsSelected = mappedSelectedItems[item[this.selectedItemsValueProperty]];
        Ember.set(item, selectedPropertyName, Ember.get(item, 'value') === 'all' || itemIsSelected ? 'icn-check' : null);
        Ember.set(item, 'class', itemIsSelected ? 'selected' : '');
      }
    }),
    childTask: (0, _emberConcurrency.task)(function* (listItem, selectedPropertyName, mappedSelectedItems) {
      var lItem = yield listItem;
      var itemIsSelected = mappedSelectedItems[lItem[this.selectedItemsValueProperty]];
      this.allSelected = this.allSelected ? Ember.get(lItem, 'value') === 'all' || itemIsSelected : false;
      Ember.set(lItem, selectedPropertyName, itemIsSelected ? 'icn-check' : null);
      Ember.set(lItem, 'class', itemIsSelected ? 'selected' : '');
    }),
    listItemClickTask: (0, _emberConcurrency.task)(function* (item) {
      var selectedPropertyName = this.selectedPropertyName;
      var itemWasSelected = yield Ember.get(item, selectedPropertyName);

      if (this.separatedList) {
        yield this.itemSelectAction(item, itemWasSelected);
        Ember.set(item, selectedPropertyName, itemWasSelected ? null : 'icn-check');
      } else {
        Ember.set(item, selectedPropertyName, this.listItemClickClickActionTask.perform(item, itemWasSelected)); // set the property of the item to the current promise so the spinner will show
      }
    }),
    listItemClickClickActionTask: (0, _emberConcurrency.task)(function* (item, itemWasSelected) {
      var clickAction = this.itemSelectAction;

      if (!clickAction) {
        clickAction = () => {
          return Ember.RSVP.resolve(true);
        };
      }

      try {
        yield clickAction(item, itemWasSelected);

        if (this.singleSelectOnly) {
          this.send('closeMultiSelect');
        }

        return itemWasSelected ? null : 'icn-check';
      } catch (error) {
        return itemWasSelected ? 'icn-check' : null;
      }
    }),
    listItemClickAllTask: (0, _emberConcurrency.task)(function* () {
      var selectedPropertyName = this.selectedPropertyName;
      var allSelected = yield Ember.get(this.filteredList[0], this.selectedPropertyName);
      this.filteredList.forEach(item => {
        if (!allSelected) {
          Ember.set(item, selectedPropertyName, 'icn-check');
        } else {
          Ember.set(item, selectedPropertyName, null);
        }
      });

      if (!allSelected) {
        Ember.set(this, 'selectedItems', this.filteredList.slice(1));
      } else {
        Ember.set(this, 'selectedItems', []);
      }
    }),
    actions: {
      closeMultiSelect() {
        var closeAction = this.onCloseAction;
        var dropdown = this.dropdown;

        if (dropdown) {
          dropdown.actions.close();
        } else {
          this.set('isOpen', false);
        }

        if (closeAction) {
          closeAction();
        }
      },

      createAndUpdateList(modelName, value) {
        this.onCreate(modelName, value);
        Ember.set(value, this.selectedPropertyName, 'icn-check');
      }

    }
  });

  _exports.default = _default;
});