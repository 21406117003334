define("securitytrax-ember-ui/components/pages/administration/company-settings/equipment-packages/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    intl: Ember.inject.service(),
    stFiltersService: Ember.inject.service(),
    store: Ember.inject.service(),
    equipmentPackage: null,
    packageFormStarted: false,
    packageFormMode: 'edit',
    equipmentPackageStatus: undefined,
    equipmentPackagesListCount: 0,
    limit: 50,
    page: 1,
    packagesAdditionalData: Ember.computed(function () {
      return Ember.Object.create({
        wireTypes: this.get('model.wireTypes'),
        equipmentOptions: this.get('model.equipment') // DREW TODO - BE might have to change this to not have the limit 0

      });
    }),
    packageFormSteps: Ember.computed('packageFormMode', 'equipmentPackage', function () {
      var _this$get;

      var viewOrAdd = this.packageFormMode === 'create' ? 'create' : 'view';
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: this.intl.t("common.forms.descriptive_title_".concat(viewOrAdd), {
            id: this.get('equipmentPackage.id'),
            name: (_this$get = this.get('equipmentPackage.name')) !== null && _this$get !== void 0 ? _this$get : this.intl.t('pages.administration.company_settings.equipment.equipmentPackages.title')
          }),
          stepComponentPath: 'multi-step-form/forms/administration/equipment/edit-package'
        })
      });
    }),
    packageFormOriginalModels: Ember.computed('equipmentPackage', function () {
      return Ember.Object.create({
        'package': {
          model: this.equipmentPackage,
          copyRelationMap: {
            packageEquipment: {
              doCopy: true,
              doSave: false,
              relationships: {
                equipment: {
                  doCopy: true,
                  doSave: false
                },
                equipmentWireType: {
                  doCopy: true,
                  doSave: false
                }
              }
            }
          }
        }
      });
    }),
    equipmentPackages: Ember.computed(function () {
      return this.getEquipmentPackagesTask.perform();
    }),
    getEquipmentPackagesTask: (0, _emberConcurrency.task)(function* () {
      // const newFilters = yield this.stFiltersService.getFiltersByGroupKey('companyEquipmentPackages');
      var equipmentPackages = yield this.store.query('package', {
        // filter: newFilters,
        limit: this.limit,
        page: this.page,
        include: 'package_equipment.equipment',
        count: 1
      });
      Ember.set(this, 'equipmentPackagesListCount', equipmentPackages.meta.record_count);
      return equipmentPackages;
    }),
    actions: {
      refreshModel() {
        Ember.notifyPropertyChange(this, 'equipmentPackages');
      },

      filterSearch(setPage = true) {
        if (setPage) {
          Ember.set(this, 'page', 1);
        }

        Ember.notifyPropertyChange(this, 'equipmentPackages');
      },

      clearFilters() {
        Ember.setProperties(this, {
          page: 1,
          equipmentPackageStatus: undefined
        });
        Ember.notifyPropertyChange(this, 'equipmentPackages');
        Ember.notifyPropertyChange(this, 'page');
      }

    }
  });

  _exports.default = _default;
});