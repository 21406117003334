define("securitytrax-ember-ui/components/nav/top-nav/nav/nav-company-name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sjKleqsw",
    "block": "{\"symbols\":[],\"statements\":[[2,\" \"],[10,\"div\"],[14,0,\"top-nav__company-name\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"top-nav__company-name--span\"],[12],[2,\"\\n        \"],[1,[32,0,[\"name\"]]],[2,\"\\n    \"],[13],[2,\" \\n \"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/nav/top-nav/nav/nav-company-name/template.hbs"
    }
  });

  _exports.default = _default;
});