define("securitytrax-ember-ui/calendar/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-concurrency", "securitytrax-ember-ui/mixins/has-page-title", "securitytrax-ember-ui/mixins/route-pageview-logging", "securitytrax-ember-ui/mixins/scroll-to-top"], function (_exports, _authenticatedRouteMixin, _emberConcurrency, _hasPageTitle, _routePageviewLogging, _scrollToTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _hasPageTitle.default, _routePageviewLogging.default, _scrollToTop.default, {
    intl: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    grants: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['calendar_events.view', 'companies.view_calendar_month'], 'one');
    },

    model(params) {
      return this.getModelData.perform(params);
    },

    getModelData: (0, _emberConcurrency.task)(function* () {
      var grants = this.grants;
      var canViewOtherUsers = yield grants.get('getIndividualGrantAsync').perform('calendar_users.view');
      var users;

      if (canViewOtherUsers) {
        //findAll only returns first 100
        var allCalendarUsers = yield this.store.query('calendarUser', {
          filter: 'active = true',
          //cant currently filter on roles, a hasMany
          limit: 0,
          sort: 'lname, fname',
          include: 'roles,user,calendarEvents'
        });
        users = allCalendarUsers;
      } else {
        var loggedInUser = yield this.sessionData.getUser();
        users = [loggedInUser];
      }

      return Ember.RSVP.hash({
        users,
        calendarEventTypes: this.store.findAll('calendar-event-type', {
          include: 'calendar_event_detail_types'
        }),
        calendarEventDetailTypes: this.store.findAll('calendar-event-detail-type')
      });
    }),

    getPageTitle() {
      return '';
    },

    getRouteName() {
      return "common.nav.calendar";
    }

  });

  _exports.default = _default;
});