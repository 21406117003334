define("securitytrax-ember-ui/app", ["exports", "jquery", "securitytrax-ember-ui/resolver", "ember-load-initializers", "securitytrax-ember-ui/config/environment", "lodash", "@sentry/browser", "ember-inflector", "@sentry/ember"], function (_exports, _jquery, _resolver, _emberLoadInitializers, _environment, _lodash, Sentry, _emberInflector, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //placed here vs environment.js to have access to browser context (window)
  //environment.js is node-only
  _environment.default[_environment.default.modulePrefix]['profile'] = window.location.pathname.split('/', 2)[1];
  var profile = _environment.default[_environment.default.modulePrefix]['profile'];

  if (_environment.default.environment === 'test' && (_environment.default['securitytrax-ember-ui']['api-e2e-test-config']['local-tests']['runTests'] || _environment.default['securitytrax-ember-ui']['api-e2e-test-config']['ci-tests']['runTests'])) {
    if (_environment.default['securitytrax-ember-ui']['api-e2e-test-config']['profileForApiTests']) {
      profile = _environment.default['securitytrax-ember-ui']['api-e2e-test-config']['profileForApiTests'];
    } else {
      profile = 'sandbox';
    }
  }

  if (profile.length === 0) {
    window.location.replace('https://www.securitytrax.com/');
  }

  _environment.default.rootURL = '/' + profile + '/'; // support runtime config

  try {
    var runtime = JSON.parse((0, _jquery.default)('script[type="text/json"]#' + 'runtime-environment').text());

    _lodash.default.merge(_environment.default[_environment.default.modulePrefix], runtime[_environment.default.modulePrefix]);
  } catch (e) {// move along
  }

  _environment.default['ember-simple-auth-token']['serverTokenEndpoint'] = '/' + profile + _environment.default['securitytrax-ember-ui']['api-version-info'] + '/authenticate';
  _environment.default['ember-simple-auth-token']['serverTokenRefreshEndpoint'] = '/' + profile + _environment.default['securitytrax-ember-ui']['api-version-info'] + '/auth-refresh';

  if (_environment.default[_environment.default.modulePrefix]['api-uri']) {
    var uri = _environment.default[_environment.default.modulePrefix]['api-uri'];
    _environment.default['ember-simple-auth-token']['serverTokenEndpoint'] = uri + _environment.default['ember-simple-auth-token']['serverTokenEndpoint'];
    _environment.default['ember-simple-auth-token']['serverTokenRefreshEndpoint'] = uri + _environment.default['ember-simple-auth-token']['serverTokenRefreshEndpoint'];
  }

  if (_environment.default[_environment.default.modulePrefix]['api-integrator-key']) {
    _environment.default['ember-simple-auth-token']['headers']['X-SecurityTrax-IntegratorKey'] = _environment.default[_environment.default.modulePrefix]['api-integrator-key'];
  }

  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  Ember.RSVP.on('error', (error, label) => {
    var log = showError(error, label);

    if (log && _environment.default.environment === 'development') {
      //only show these for devs
      (false && Ember.warn('app.js detected there was an error in a promise: ' + label, {
        id: 'app.RSVP'
      }));
    }
  });

  function showError(error, label) {
    var log = true;

    if (error && error.name === 'TransitionAborted') {
      log = false;
    }

    if (label) {
      if (label.substring(0, 10) === 'Validation' || label.substring(0, 9) === 'ST Button') {
        log = false;
      }
    }

    return log;
  } // Sentry Errors we don't care about 


  var errorsToIgnore = ['TransitionAborted', 'The Ajax operation was aborted', 'The adapter operation was aborted'];
  var sentryConfig = _environment.default['securitytrax-ember-ui']['sentry'];

  if (sentryConfig.enabled) {
    var gitCommit = 'version-check' in _environment.default['securitytrax-ember-ui'] ? _environment.default['securitytrax-ember-ui']['version-check']['version'] : '0';
    (0, _ember.InitSentryForEmber)({
      beforeSend(event, hint) {
        var error = hint.originalException;

        if (error && errorsToIgnore.indexOf(error.message) !== -1) {
          return null;
        } else {
          return event;
        }
      }

    });
    Sentry.configureScope(scope => {
      for (var key in sentryConfig.tags) {
        if (sentryConfig.tags[key]) {
          scope.setTag(key, sentryConfig.tags[key]);
        }
      }

      scope.setTag('gitCommit', gitCommit);
    });

    if (_environment.default['securitytrax-ember-ui']['profile']) {
      Sentry.configureScope(scope => {
        scope.setTag('profile', _environment.default['securitytrax-ember-ui']['profile']);
      });
    }
  }

  var inflector = _emberInflector.default.inflector; //inflector.irregular('formula', 'formulae');

  inflector.irregular('data', 'data');
  inflector.uncountable('equipment_spillage');
  inflector.uncountable('equipment-spillage'); // This is for mirage because for some reason the inflector is stupid and it only works in mirage if you define dashereized version as uncountable.

  inflector.uncountable('employee_directory');
  var _default = App;
  _exports.default = _default;
});