define("securitytrax-ember-ui/components/is-loading/component", ["exports", "securitytrax-ember-ui/utils/obj-helpers", "ember-concurrency"], function (_exports, _objHelpers, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: undefined,
    classNames: ['is-loading'],

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        loaded: false,
        _loaded: false,
        spinnerClass: null,
        size: 'small',
        resolvedValues: {}
      });
      this.watchLoadedTask.perform();
    },

    watchLoaded: Ember.observer('loaded', 'loaded.[]', 'loaded.@each', function () {
      this.watchLoadedTask.perform();
    }),
    watchLoadedTask: (0, _emberConcurrency.task)(function* () {
      var loaded = this.loaded; ///////////////////////////////////////////////////////////////////////////////////////
      //   loaded can be an array or not and the value(s) can be boolean or promises
      //   _loaded will not be true until all promises are fulfilled and all booleans are true
      ///////////////////////////////////////////////////////////////////////////////////////

      if (Array.isArray(loaded)) {
        var allBooleanTrue = true;
        var arrPromises = Ember.A([]);
        this.set('_loaded', false);

        for (var i = 0; i < loaded.length; i++) {
          var currLoaded = loaded.objectAt(i);

          if (loaded && currLoaded && typeof currLoaded === 'object' && typeof currLoaded.then !== 'undefined') {
            arrPromises.pushObject(currLoaded);
          } else {
            if (!currLoaded) {
              allBooleanTrue = false;
            }
          }
        }

        var results = yield Ember.RSVP.all(arrPromises);

        if (allBooleanTrue) {
          this.set('resolvedValues', results);
          this.set('_loaded', true);
        }
      } else {
        if (loaded && typeof loaded === 'object' && typeof loaded.then !== 'undefined') {
          this.set('_loaded', false);
          var value = yield loaded;
          this.set('resolvedValues', value);
          this.set('_loaded', true);
        } else {
          if (loaded) {
            this.set('resolvedValues', true);
            this.set('_loaded', true);
          } else {
            this.set('resolvedValues', false);
            this.set('_loaded', false);
          }
        }
      }
    }),
    ///////////////////////////////////////////////////////////////////////////////////////
    //   loaded can be an array or not and the value(s) can be boolean or promises
    //   _loaded will not be true until all promises are fulfilled and all booleans are true
    ///////////////////////////////////////////////////////////////////////////////////////
    loaded: undefined,
    _loaded: undefined,
    // used for tracking if the thing is truly loaded internally (ie if it's a promise)
    spinnerClass: undefined,
    size: undefined,
    resolvedValues: undefined
  });

  _exports.default = _default;
});