define("securitytrax-ember-ui/components/multi-step-form/forms/my-account/integrations/edit-telus/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  //May want a base for MC ones to not re-use so much code
  var EditTelus = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    var formattedObjects = [];
    formattedObjects.pushObject(Ember.Object.create({
      model: {
        userSessionUsername: this.usernameField,
        userSessionPassword: this.passwordField
      },
      filePath: 'central-station/telus-integration'
    }));
    yield multiStepForm.validateList.perform(formattedObjects);

    try {
      var formattedPassword;

      if (this.passwordField === '********') {
        formattedPassword = null;
      } else {
        formattedPassword = this.passwordField;
      }

      yield this.centralStationService.postData({
        model: this.args.telusIntegration,
        method: 'adt_canada__setUserSessionCredentials',
        payload: {
          username: this.usernameField,
          password: formattedPassword,
          user_id: this.args.user ? this.args.user.get('id') : null
        }
      });
      buttonPromise.then(() => {
        this.args.multiStepForm.formStartedSetVal(false);
        this.args.multiStepForm.onAfterSave();
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), _dec5 = Ember._action, (_class = (_temp = class EditTelus extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor, this);

      _initializerDefineProperty(this, "centralStationService", _descriptor2, this);

      _initializerDefineProperty(this, "intl", _descriptor3, this);

      _defineProperty(this, "passwordField", undefined);

      _defineProperty(this, "usernameField", undefined);

      _initializerDefineProperty(this, "saveUserCreds", _descriptor4, this);

      this.usernameField = this.args.telusIntegration.creds.credentials.username;
      this.passwordField = this.args.telusIntegration.creds.credentials.password_has_saved_value ? '********' : null;
    }

    renderButtons() {
      var buttons = [];
      buttons.push({
        text: this.intl.t('common.actions.save'),
        classNamesString: 'st-btn st-btn-primary',
        click: buttonPromise => {
          return this.saveUserCreds.perform(this.args.multiStepForm, buttonPromise);
        }
      });
      this.args.multiStepForm.registerStepData('step1', 'buttons', buttons);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "saveUserCreds", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "renderButtons", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "renderButtons"), _class.prototype)), _class));
  _exports.default = EditTelus;
});