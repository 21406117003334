define("securitytrax-ember-ui/components/multi-step-form/forms/administration/inventory/equipment-transfer/edit-transfer/component", ["exports", "ember-concurrency", "moment"], function (_exports, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    confirmUpdateStatusModalOpen: false,
    newStatus: undefined,
    infoText: Ember.computed('multiStepForm.formOriginalModels.adminEquipmentTransfer.model.{updatedByUser,updatedAt,status}', function () {
      var model = this.multiStepForm.formOriginalModelsGetVal('adminEquipmentTransfer.model');
      return this.intl.t('pages.administration.company_settings.inventory.equipment_transfer.info_text', {
        updatedBy: model.get('updatedByUser.flname'),
        updatedAt: (0, _moment.default)(model.get('updatedAt')).format('M/D/YYYY h:mm a'),
        status: this.intl.t('pages.administration.company_settings.inventory.equipment_transfer.status_' + model.get('status'))
      });
    }),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;

      if (multiStepForm.formOriginalModelsGetVal('adminEquipmentTransfer.model.status') === 'pending') {
        multiStepForm.registerStepData("step1", 'buttons', [{
          text: this.intl.t('pages.users.my_inventory.transfers.reject'),
          click: () => {
            this.set('newStatus', 'reject');
            this.set('confirmUpdateStatusModalOpen', true);
          },
          classNamesString: 'st-btn warning-red transfer-reject-button'
        }, {
          text: this.intl.t('pages.users.my_inventory.transfers.accept'),
          click: () => {
            this.set('newStatus', 'accept');
            this.set('confirmUpdateStatusModalOpen', true);
          },
          classNamesString: 'st-btn st-btn-primary transfer-accept-button'
        }]);
      }
    },

    updateStatus: (0, _emberConcurrency.task)(function* (buttonPromise) {
      try {
        var transfer = this.multiStepForm.formOriginalModelsGetVal('adminEquipmentTransfer.model');
        yield transfer.invokeAction(this.newStatus + '_transfer', {
          verb: 'GET'
        });
      } catch (err) {
        this.stNotificationManager.addErrorMessages(err, {
          notificationNamespace: 'modal2'
        });
        throw err;
      }

      buttonPromise.then(() => {
        this.multiStepForm.formStartedSetVal(false);
        this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
        this.multiStepForm.onAfterSave();
      });
    })
  });

  _exports.default = _default;
});