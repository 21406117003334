define("securitytrax-ember-ui/components/svg-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mQmLH7I+",
    "block": "{\"symbols\":[],\"statements\":[[10,\"use\"],[15,6,[31,[\"/assets/icons.svg#\",[34,0]]]],[15,\"xlink:href\",[31,[\"/assets/icons.svg#\",[34,0]]],\"http://www.w3.org/1999/xlink\"],[15,0,[31,[\"icon-\",[34,0]]]],[12],[13]],\"hasEval\":false,\"upvars\":[\"iconName\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/svg-icon/template.hbs"
    }
  });

  _exports.default = _default;
});