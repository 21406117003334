define("securitytrax-ember-ui/validations/payment-refund", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateObject = {
        amount: {
          numericality: {
            greaterThan: 0,
            lessThanOrEqualTo: data.extraValidationData.maxRefundAmount,
            message: function () {
              return '^' + data.intlService.t('pages.customers.accounting.customer_payments.refund_validation');
            }
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      return this.outputFormatting(validationData, 'customerRefund');
    }

  });

  _exports.default = _default;
});