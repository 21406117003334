define("securitytrax-ember-ui/validations/customer-billing", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation", "lodash"], function (_exports, _validate, _baseValidation, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      _validate.default.validators.cCInField = function (value, options) {
        if (value) {
          if (value.replace(/[A-Z.a-z ,-]/g, '').match(/(^4[0-9]{12}(?:[0-9]{3})?$)|(^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$)|(3[47][0-9]{13})|(^3(?:0[0-5]|[68][0-9])[0-9]{11}$)|(^6(?:011|5[0-9]{2})[0-9]{12}$)|(^(?:2131|1800|35\d{3})\d{11}$)/)) {
            return options.message;
          }

          return null;
        } else {
          return null;
        }
      };

      var validateFieldsObject = {
        billAddress1: {
          presence: 'extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('customerBilling-billAddress1') ? false : this.customMessageOrDefault(data, 'billAddress1')
        },
        billCity: {
          presence: 'extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('customerBilling-billCity') ? false : this.customMessageOrDefault(data, 'billCity')
        },
        billState: {
          presence: 'extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('customerBilling-billState') ? false : this.customMessageOrDefault(data, 'billState')
        },
        billZip: {
          presence: 'extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('customerBilling-billZip') ? false : this.customMessageOrDefault(data, 'billZip'),
          format: {
            pattern: /\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d/,
            message: this.customMessage(data, 'billZipInvalid')
          }
        },
        billComment: {
          cCInField: {
            message: function ()
            /*value, attribute, validatorOptions, attributes, globalOptions*/
            {
              return '^' + data.intlService.t('common.validations.numbersInWrongField');
            }
          }
        }
      };

      if (data.extraValidationData.billType == 'Credit Card') {
        var billCcName = {
          presence: 'extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('customerBilling-billCcName') ? false : this.customMessageOrDefault(data, 'billCcName')
        };

        if (!('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('customerBilling-billCcName'))) {
          billCcName.cCInField = {
            message: function ()
            /*value, attribute, validatorOptions, attributes, globalOptions*/
            {
              return '^' + data.intlService.t('common.validations.numbersInWrongField');
            }
          };
        }

        validateFieldsObject.billCcName = billCcName;
        validateFieldsObject.billCcExpirationMonth = {
          presence: 'extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('customerBilling-billCcExpirationMonth') ? false : this.customMessageOrDefault(data, 'billCcExpirationMonth')
        };
        validateFieldsObject.billCcExpirationYear = {
          presence: 'extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('customerBilling-billCcExpirationYear') ? false : this.customMessageOrDefault(data, 'billCcExpirationYear')
        };
        validateFieldsObject.billCcNumber = {
          presence: 'extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('customerBilling-billCcNumber') ? false : this.customMessageOrDefault(data, 'billCcNumber')
        };
      } else if (data.extraValidationData.billType == 'Bank Account') {
        _validate.default.validators.validRouteNumber = function (value, options) {
          var checkSum = 0;

          if (value.length == 9) {
            for (var index = 0; index < value.length; index += 3) {
              checkSum += parseInt(value[index], 10) * 3;
              checkSum += parseInt(value[index + 1], 10) * 7;
              checkSum += parseInt(value[index + 2], 10);
            }

            if (checkSum != 0 && checkSum % 10 == 0) {
              return null;
            }
          } else if (value.length == 5) {
            return null;
          }

          return options.message;
        };

        validateFieldsObject.billBankName = {
          presence: 'extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('customerBilling-billBankName') ? false : this.customMessageOrDefault(data, 'billBankName')
        };
        validateFieldsObject.billBankRoute = {
          presence: 'extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('customerBilling-billBankRoute') ? false : this.customMessageOrDefault(data, 'billBankRoute'),
          format: {
            pattern: /^(\d{5}(\d{4})?)?$/,
            message: this.customMessage(data, 'billBankRouteInvalid')
          },
          validRouteNumber: () => {
            if (this.billBankRoute.length == 5 || this.billBankRoute.length == 9) {
              return {
                message: function () {
                  return '^' + data.intlService.t('common.validations.invalidRouteNumber');
                }
              };
            } else {
              return true;
            }
          }
        };
        validateFieldsObject.billBankType = {
          presence: 'extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('customerBilling-billBankType') ? false : this.customMessageOrDefault(data, 'billBankType')
        };
        validateFieldsObject.billBankNumber = {
          presence: 'extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('customerBilling-billBankNumber') ? false : this.customMessageOrDefault(data, 'billBankNumber')
        };
      }

      var validationData = (0, _validate.default)(_lodash.default.merge(this.objectToJson(this), data.extraValidationData.toValidate), validateFieldsObject, {
        fullMessages: this.prependProperty(data)
      });
      return this.outputFormatting(validationData, 'customerBilling');
    }

  });

  _exports.default = _default;
});