define("securitytrax-ember-ui/administration/account-management/billing/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZXzHtab4",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"page-content\"],[12],[2,\"\\n    \"],[8,\"admin/account-management/billing-card\",[],[[],[]],null],[2,\"\\n    \"],[8,\"admin/account-management/invoices-card\",[],[[],[]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/administration/account-management/billing/template.hbs"
    }
  });

  _exports.default = _default;
});