define("securitytrax-ember-ui/demo/multi-step-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "otm/4jRb",
    "block": "{\"symbols\":[\"multiStepForm\",\"multiStepForm\"],\"statements\":[[6,[37,12],null,null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h1\"],[14,0,\"card-title\"],[12],[2,\"Multi Step Form Demo Page\"],[13],[2,\"\\n    \"],[10,\"h1\"],[12],[2,\"\\n        \"],[11,\"button\"],[4,[38,2],[[32,0],\"startMultiStepForm\"],null],[12],[1,[30,[36,3],[\"icn-motion-active\"],null]],[2,\" Start the Multi Step Form!\"],[13],[2,\"\\n        \"],[10,\"span\"],[14,0,\"actions\"],[12],[2,\"\\n            \"],[11,\"button\"],[4,[38,2],[[32,0],\"transitionToSubroute\"],null],[12],[2,\"Open the Subroute Modal!\"],[13],[2,\"\\n            \"],[1,[30,[36,1],[[30,[36,4],null,null]],null]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,9],null,[[\"formSteps\",\"formOriginalModels\",\"formStarted\",\"changeFormStarted\"],[[35,8],[35,7],[35,5],[30,[36,2],[[32,0],[30,[36,6],[[35,5]],null]],null]]],[[\"default\"],[{\"statements\":[],\"parameters\":[2]}]]],[2,\"\\n    \"],[10,\"br\"],[12],[13],[10,\"br\"],[12],[13],[2,\"\\n    \"],[10,\"hr\"],[12],[13],[2,\"\\n    \"],[10,\"br\"],[12],[13],[10,\"br\"],[12],[13],[2,\"\\n    \"],[10,\"h1\"],[14,0,\"card-title\"],[12],[2,\"A form with a single step\"],[13],[2,\"\\n\"],[6,[37,9],null,[[\"formSteps\",\"formOriginalModels\",\"formStarted\"],[[35,11],[35,10],true]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,1],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],[[\"multiStepForm\",\"model\"],[[32,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"component\",\"action\",\"svg-icon\",\"-outlet\",\"formStarted\",\"mut\",\"demoModalWizardFormOriginalModels\",\"formSteps\",\"multi-step-form\",\"demoSwitchableFormOriginalModels\",\"singleStepForm\",\"pages/demo-page\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/demo/multi-step-form/template.hbs"
    }
  });

  _exports.default = _default;
});