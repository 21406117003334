define("securitytrax-ember-ui/components/lead/lead-details/component", ["exports", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    leadStatusEvents: Ember.inject.service('events/lead-status-events'),
    leadDetailsFormMode: undefined,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        leadDetailsFormMode: 'view'
      });
      this.leadStatusEvents.on('redoModels', this, '_eventCallback');
      Ember.defineProperty(this, 'leadFormOriginalModels', Ember.computed('model.lead', 'grants.userGrants', this.store.getFullComputedWatcherStringByModelName('customer', {
        prefix: 'model.',
        actualVariableName: 'lead'
      }), function () {
        var grants = this.grants;
        return Ember.Object.create({
          customer: {
            model: this.model ? this.get('model.lead') : null,
            copyRelationMap: {
              leadInformation: {
                doCopy: grants.getIndividualGrant('lead_information.view'),
                doSave: grants.getIndividualGrant('lead_information.modify'),
                relationships: {
                  leadRepUser: {
                    doCopy: true,
                    doValidate: false,
                    doSave: false
                  },
                  leadStatus: {
                    readOnly: true
                  }
                }
              }
            }
          }
        });
      }));
    },

    _eventCallback() {
      this.notifyPropertyChange('leadFormOriginalModels');
    },

    willDestroy() {
      this._super(...arguments);

      this.leadStatusEvents.off('redoModels', this, '_eventCallback');
    },

    leadDetailsForm: Ember.computed(function () {
      return Ember.Object.create({
        leadDetails: Ember.Object.create({
          stepNameKey: 'leadDetails',
          title: this.intl.t('pages.leads.lead_details'),
          stepComponentPath: 'multi-step-form/forms/leads/details'
        })
      });
    })
  });

  _exports.default = _default;
});