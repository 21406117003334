define("securitytrax-ember-ui/demo/form-progress-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r+GrvROR",
    "block": "{\"symbols\":[\"multiStepForm\"],\"statements\":[[6,[37,10],null,[[\"classNames\"],[\"paper-demo\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h1\"],[14,0,\"card-title\"],[12],[2,\"Form Progress Bar\"],[13],[2,\"\\n\"],[6,[37,5],null,[[\"raised\",\"primary\",\"onClick\",\"class\"],[true,true,[30,[36,4],[[32,0],\"startMultiStepForm\",\"default-view\"],null],\"form-progress-bar-button\"]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,3],[\"icn-menu\"],null]],[2,\" Sequential (Default)\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,5],null,[[\"raised\",\"primary\",\"onClick\",\"class\"],[true,true,[30,[36,4],[[32,0],\"startMultiStepForm\",\"unordered-view\"],null],\"form-progress-bar-button\"]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,3],[\"icn-menu\"],null]],[2,\" Unordered View\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,9],null,[[\"class\",\"formSteps\",\"formStarted\",\"changeFormStarted\",\"usesModal\",\"progressBarTemplate\"],[\"row\",[35,8],[35,1],[30,[36,4],[[32,0],[30,[36,7],[[35,1]],null]],null],true,[35,6]]],[[\"default\"],[{\"statements\":[[6,[37,2],null,[[\"modalButtons\",\"title\",\"classNames\",\"dialogOpen\",\"multiStepForm\"],[[32,1,[\"currentStepData\",\"buttons\"]],[32,1,[\"currentStepData\",\"title\"]],\"col col-10 col-md-10 col-sm-12\",[35,1],[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,0],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],[[\"multiStepForm\"],[[32,1]]]]],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"formStarted\",\"modals/st-paper\",\"svg-icon\",\"action\",\"html/st-button\",\"progressBarTemplate\",\"mut\",\"formSteps\",\"multi-step-form\",\"pages/demo-page\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/demo/form-progress-bar/template.hbs"
    }
  });

  _exports.default = _default;
});