define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/interactive-services-providers/adc/import/import-customers/import-summary/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/interactive-services-providers/adc/import/import-customers/import-customers-base"], function (_exports, _importCustomersBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _importCustomersBase.default.extend({
    intl: Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments);

      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData("importSummary", 'buttons', [{
        text: this.intl.t('common.actions.back'),
        classNamesString: 'st-btn st-btn-secondary',
        click: () => {
          multiStepForm.currentStepNameKeySetVal('importOptions');
        }
      }, {
        text: this.intl.t('common.actions.close'),
        click: () =>
        /*buttonPromise*/
        {
          multiStepForm.formStartedSetVal(false);
        },
        classNamesString: 'st-btn st-btn-primary'
      }]);
    }

  });

  _exports.default = _default;
});