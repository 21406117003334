define("securitytrax-ember-ui/components/html/drag-over-scroll/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FhCK7V3L",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"dragArea dragArea--top \",[32,0,[\"draggingClass\"]],\" \",[32,0,[\"showTopClass\"]]]]],[16,\"ondragover\",[30,[36,0],[[32,0],[32,0,[\"dragOverScroll\"]],\"top\"],null]],[4,[38,1],[[32,0,[\"bindScrolling\"]]],null],[12],[2,\"\\n    \"],[10,\"span\"],[12],[1,[30,[36,2],[\"common.actions.drag_over\"],null]],[2,\" \"],[1,[30,[36,3],[\"icn-chevron-up\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[11,\"div\"],[16,0,[31,[\"dragArea dragArea--left \",[32,0,[\"draggingClass\"]],\" \",[32,0,[\"showLeftClass\"]]]]],[16,\"ondragover\",[30,[36,0],[[32,0],[32,0,[\"dragOverScroll\"]],\"left\"],null]],[4,[38,1],[[32,0,[\"bindHorizontalScrolling\"]]],null],[12],[2,\"\\n    \"],[10,\"span\"],[12],[1,[30,[36,2],[\"common.actions.drag_over\"],null]],[2,\" \"],[1,[30,[36,3],[\"icn-chevron-left\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[15,0,[31,[\"dragArea dragArea--right \",[32,0,[\"draggingClass\"]],\" \",[32,0,[\"showRightClass\"]]]]],[15,\"ondragover\",[30,[36,0],[[32,0],[32,0,[\"dragOverScroll\"]],\"right\"],null]],[12],[2,\"\\n    \"],[10,\"span\"],[12],[1,[30,[36,2],[\"common.actions.drag_over\"],null]],[2,\" \"],[1,[30,[36,3],[\"icn-chevron-right\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[15,0,[31,[\"dragArea dragArea--bottom \",[32,0,[\"draggingClass\"]],\" \",[32,0,[\"showBottomClass\"]]]]],[15,\"ondragover\",[30,[36,0],[[32,0],[32,0,[\"dragOverScroll\"]],\"bottom\"],null]],[12],[2,\"\\n        \"],[10,\"span\"],[12],[1,[30,[36,2],[\"common.actions.drag_over\"],null]],[2,\" \"],[1,[30,[36,3],[\"icn-chevron-down\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[18,1,[[30,[36,6],null,[[\"dragStarted\",\"dragEnded\"],[[30,[36,0],[[32,0],[35,5]],null],[30,[36,0],[[32,0],[35,4]],null]]]]]]],\"hasEval\":false,\"upvars\":[\"action\",\"did-insert\",\"t\",\"svg-icon\",\"dragEnded\",\"dragStarted\",\"hash\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/drag-over-scroll/template.hbs"
    }
  });

  _exports.default = _default;
});