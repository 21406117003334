define("securitytrax-ember-ui/components/multi-step-form/forms/system/brinks/purchase-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "00KckQ8P",
    "block": "{\"symbols\":[\"field\",\"&attrs\",\"@multiStepForm\"],\"statements\":[[11,\"div\"],[17,2],[12],[2,\"\\n    \"],[8,\"multi-step-form/progress-bar\",[],[[\"@config\",\"@modalButtons\"],[[32,0,[\"SwitchableFormStep\",\"progressBarConfig\"]],[32,3,[\"currentStepData\",\"buttons\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[12],[2,\"\\n            \"],[8,\"html/st-await\",[],[[\"@useSpinner\",\"@booleanLoaded\",\"@size\"],[true,[34,0,[\"isIdle\"]],\"large\"]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[8,\"html/st-card\",[[24,0,\"st-card--no-border\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n                    \"],[8,\"html/st-card/body\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n                        \"],[10,\"div\"],[14,0,\"st-card__cell\"],[12],[2,\"\\n                            \"],[10,\"div\"],[14,0,\"grid-x grid-padding-x\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"purchaseInfo\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                                    \"],[10,\"div\"],[14,0,\"cell small-12 medium-6 large-3\"],[12],[2,\"\\n                                        \"],[8,\"multi-step-form/switchable-inputs/text-input\",[],[[\"@value\",\"@formMode\",\"@label\"],[[32,1,[\"value\"]],\"view\",[32,1,[\"name\"]]]],null],[2,\"\\n                                    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"                            \"],[13],[2,\"\\n                        \"],[13],[2,\"\\n                    \"]],\"parameters\":[]}]]],[2,\"\\n                \"]],\"parameters\":[]}]]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"initDataTask\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/system/brinks/purchase-info/template.hbs"
    }
  });

  _exports.default = _default;
});