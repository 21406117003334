define("securitytrax-ember-ui/components/html/package-select/package-layouts/default-layout/component", ["exports", "@glimmer/component", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _component, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var PackageSelectDefaultLayout = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('events/package-select-event'), _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._action, (_class = (_temp = class PackageSelectDefaultLayout extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stAwait", _descriptor2, this);

      _initializerDefineProperty(this, "packageSelectEvent", _descriptor3, this);

      _initializerDefineProperty(this, "columnClasses", _descriptor4, this);

      _initializerDefineProperty(this, "disabled", _descriptor5, this);

      _initializerDefineProperty(this, "selectedId", _descriptor6, this);

      _initializerDefineProperty(this, "selectedArray", _descriptor7, this);

      _initializerDefineProperty(this, "loadedNamespace", _descriptor8, this);

      _initializerDefineProperty(this, "emptyBodyText", _descriptor9, this);

      _initializerDefineProperty(this, "renderInPlace", _descriptor10, this);

      (0, _objHelpers.initializeState)(this, {
        selectedArray: this.args.selectedArray || [],
        columnClasses: this.args.columnClasses || (this.args.isLargeLabel ? 'cell small-12 medium-5 large-4' : this.args.packageList.length == 1 ? 'cell small-12' : 'cell small-12 medium-5'),
        emptyBodyText: this.args.emptyBodyText || this.intl.t('common.notifications.generic_empty_package_select_body'),
        loadedNamespace: this.args.loadedNamespace || 'global',
        disabled: this.args.disabled || false,
        renderInPlace: this.args.renderInPlace || false
      });

      if (this.args.selectedId) {
        this.selectedId = this.args.selectedId;

        if (!this.args.selectMultiple && this.selectedArray.length == 0) {
          this.selectedArray = [this.args.selectedId];
        }
      }

      if (this.args.updateSelected) {
        this.updateSelected = this.args.updateSelected;
      } /////////////////////////////////////////////////////////////////////////
      // see if we should disable the side nav because of fully loaded logic


      var fullyLoadedState = this.stAwait.getCurrentFullyLoadedStateByNamespace({
        loadedNamespace: this.loadedNamespace
      });

      if (this && !this.isDestroyed) {
        this.disabled = this.disabled || !fullyLoadedState.fullyLoaded;
      }

      this.packageSelectEvent.on('update-selected-array', this, '_callback');
      this.stAwait.on('st-await-loaded-state-by-namespace-' + this.loadedNamespace, this, '_onLoadedStateChange');
    }

    _callback() {
      this.selectedArray = this.args.selectedArray;
    }

    updateSelected() {}

    willDestroy() {
      this.stAwait.off('st-await-loaded-state-by-namespace-' + this.loadedNamespace, this, '_onLoadedStateChange');
      this.packageSelectEvent.off('update-selected-array', this, '_callback');
    }

    _onLoadedStateChange(loadedData) {
      Ember.run.next(() => {
        if (this && !this.isDestroyed) {
          this.disabled = !loadedData.fullyLoaded;
        }
      });
    }

    get readOnly() {
      return this.args.readOnly || false;
    }

    get fieldErrors() {
      return Ember.get(this.args.errors, this.args.validationKey);
    }

    updateSelectedArray(packageId) {
      var origSelectedArr = this.selectedArray;

      if (!this.args.selectMultiple && origSelectedArr.length == 0) {
        origSelectedArr = [this.selectedId];
      }

      var selectedArray = this.args.selectMultiple ? origSelectedArr : [];

      if (selectedArray.length > 0 && selectedArray.includes(packageId)) {
        selectedArray.splice(selectedArray.indexOf(packageId), 1);
      } else if (this.selectedId) {
        selectedArray.push(packageId);
      }

      this.selectedArray = selectedArray;
      return selectedArray;
    }

    selectPackage(packageId) {
      if (!this.args.readOnly && !this.disabled) {
        this.selectedId = this.args.canDeselect && this.selectedArray.includes(packageId) ? null : packageId;
        var selectedArray = this.updateSelectedArray(packageId);

        if (this.args.selectMultiple) {
          this.updateSelected(selectedArray, packageId);
        } else {
          this.updateSelected(this.selectedId);
        }
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stAwait", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "packageSelectEvent", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "columnClasses", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "disabled", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "selectedId", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "selectedArray", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "loadedNamespace", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "emptyBodyText", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "renderInPlace", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "selectPackage", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "selectPackage"), _class.prototype)), _class));
  _exports.default = PackageSelectDefaultLayout;
});