define("securitytrax-ember-ui/components/pages/all-customer-invoices/component", ["exports", "@glimmer/component", "ember-concurrency", "lodash", "securitytrax-ember-ui/mixed-in-classes/table-column-sort", "securitytrax-ember-ui/utils/obj-helpers", "securitytrax-ember-ui/utils/debug"], function (_exports, _component, _emberConcurrency, _lodash, _tableColumnSort, _objHelpers, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _dec37, _dec38, _dec39, _dec40, _dec41, _dec42, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var AllInvoices = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember.inject.service, _dec10 = Ember.inject.service, _dec11 = Ember.inject.service, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._tracked, _dec21 = Ember._tracked, _dec22 = Ember._tracked, _dec23 = Ember._tracked, _dec24 = Ember._tracked, _dec25 = Ember._tracked, _dec26 = Ember._tracked, _dec27 = Ember._tracked, _dec28 = Ember._tracked, _dec29 = Ember._tracked, _dec30 = Ember._tracked, _dec31 = Ember._tracked, _dec32 = Ember._tracked, _dec33 = (0, _emberConcurrency.task)(function* () {
    (0, _objHelpers.initializeState)(this, {
      tagFilter: this.tagsService.tagFilter(),
      customerTagFilter: this.tagsService.tagFilter(),
      stSortableColumnOptions: [{
        name: this.intl.t('common.columns.id') + ' ' + this.intl.t('common.sorting.ascending'),
        value: 'id',
        chevronDirection: 'up'
      }, {
        name: this.intl.t('common.columns.id') + ' ' + this.intl.t('common.sorting.descending'),
        value: '-id',
        chevronDirection: 'down'
      }, {
        name: this.intl.t('common.columns.due_date') + ' ' + this.intl.t('common.sorting.ascending'),
        value: 'due_date',
        chevronDirection: 'up'
      }, {
        name: this.intl.t('common.columns.due_date') + ' ' + this.intl.t('common.sorting.descending'),
        value: '-due_date',
        chevronDirection: 'down'
      }, {
        name: this.intl.t('common.columns.sale_date') + ' ' + this.intl.t('common.sorting.ascending'),
        value: 'sale_date',
        chevronDirection: 'up'
      }, {
        name: this.intl.t('common.columns.sale_date') + ' ' + this.intl.t('common.sorting.descending'),
        value: '-sale_date',
        chevronDirection: 'down'
      }, {
        name: this.intl.t('common.columns.paid') + ' ' + this.intl.t('common.sorting.ascending'),
        value: 'is_paid',
        chevronDirection: 'up'
      }, {
        name: this.intl.t('common.columns.paid') + ' ' + this.intl.t('common.sorting.descending'),
        value: '-is_paid',
        chevronDirection: 'down'
      }],
      quickbooksConnected: false,
      outstandingAmountMiscFilter: {
        operator: null,
        value: null
      }
    });
    this.TableColumnSort.stSortableColumnOptions = this.stSortableColumnOptions;
    this.TableColumnSort.sortPaginatedList('sale_date', false);
    var hashedData = yield (0, _emberConcurrency.hash)({
      quickbooksConnected: this.quickbooksService.quickbooksIsConnected.perform(),
      customerBillingTypes: this.store.findAll('billingType'),
      tagsOptions: this.tagsService.tagsOptionsTask.perform('invoice', 'invoice_invoice'),
      customerTags: this.tagsService.tagsOptionsTask.perform('customer')
    });
    Ember.setProperties(this, hashedData);
  }), _dec34 = Ember.computed, _dec35 = (0, _emberConcurrency.task)(function* () {
    var newFilters = yield this.stFiltersService.getFiltersByGroupKey('accountingInvoices', ["invoice_type = 'invoice'"]);

    try {
      var count;

      if (this.page == 1) {
        count = 1;
      }

      var customerInvoices = yield this.store.query('invoice', {
        sort: this.TableColumnSort.stSortString && this.TableColumnSort.stSortString !== 'is_paid' && this.TableColumnSort.stSortString !== '-is_paid' ? "".concat(this.TableColumnSort.stSortString, ",-id") : '-sale_date,-id',
        filter: newFilters,
        limit: this.limit,
        page: this.page,
        count,
        include: 'customer'
      });

      if (this.page == 1) {
        Ember.set(this, 'recordCount', _lodash.default.get(customerInvoices, 'meta.record_count', 0));
      }

      if (this.TableColumnSort.stSortString === 'is_paid' || this.TableColumnSort.stSortString === '-is_paid') {
        customerInvoices = this.TableColumnSort.sortEmberDataCollection(customerInvoices, 'isPaid', this.TableColumnSort.stSortString === 'is_paid');
      }

      if (customerInvoices.length > 0 && this.stFiltersService.filterGroupCounts.accountingInvoices > 0) {
        Ember.set(this, 'showPrint', true);
      } else {
        Ember.set(this, 'showPrint', false);
      }

      return {
        customerInvoices,
        record_count: this.recordCount
      };
    } catch (error) {
      (false && Ember.warn('failed in customerInvoicesTask: ' + error, {
        id: 'company.accounting.payments.customerInvoicesTask'
      }));
    }
  }), _dec36 = (0, _emberConcurrency.task)(function* (count, buttonPromise) {
    var newFilters = yield this.stFiltersService.getFiltersByGroupKey('accountingInvoices', ["invoice_type = 'invoice'"]);
    var invoices = yield this.store.query('invoice', {
      filter: newFilters,
      limit: 0
    });
    var invoiceIds = invoices.map(invoice => {
      return {
        invoice_id: invoice.get('id')
      };
    });

    try {
      var returnData = yield this.stAjax.request(this.stAjax.getUrl('/invoices/actions/print_multi_pdfs'), {
        method: 'POST',
        data: {
          meta: {
            background: count > 25
          },
          data: invoiceIds
        }
      });

      if (returnData.meta.error_messages.length > 0) {
        var err = {
          errors: []
        };
        returnData.meta.error_messages.forEach(error_message => {
          err.errors.pushObject({
            detail: error_message
          });
        });
        throw err;
      }

      if (returnData.meta.backgrounded) {
        this.stNotificationManager.addSuccessKey('pages.company.invoices.print_backgrounded');
      }

      if (returnData.meta.invalid_ids.length > 0) {
        var _err = {
          errors: []
        };
        returnData.meta.invalid_ids.forEach(invalid_invoice => {
          _err.errors.pushObject({
            detail: this.intl.t('pages.customers.accounting.invoices.invoice') + ' ' + invalid_invoice.invoice_id + ' ' + invalid_invoice.message.toLowerCase()
          });
        });
        this.stNotificationManager.addErrorMessages(_err);
      }

      if (!((0, _debug.isTestEnvironment)() || (0, _debug.isCypressTestEnvironment)() || returnData.meta.backgrounded) && returnData.data.url !== null) {
        window.location.assign(returnData.data.url);
      }
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'invoiceMultiPrint'
      });
      throw err;
    }

    buttonPromise.then(() => {
      Ember.set(this, 'confirmPrintModalOpen', false);
    });
  }), _dec37 = Ember._action, _dec38 = Ember._action, _dec39 = Ember._action, _dec40 = Ember._action, _dec41 = Ember._action, _dec42 = Ember._action, (_class = (_temp = class AllInvoices extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "grants", _descriptor3, this);

      _initializerDefineProperty(this, "session", _descriptor4, this);

      _initializerDefineProperty(this, "stAjax", _descriptor5, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor6, this);

      _initializerDefineProperty(this, "quickbooksService", _descriptor7, this);

      _initializerDefineProperty(this, "stFiltersService", _descriptor8, this);

      _initializerDefineProperty(this, "tagsService", _descriptor9, this);

      _initializerDefineProperty(this, "router", _descriptor10, this);

      _initializerDefineProperty(this, "screen", _descriptor11, this);

      _initializerDefineProperty(this, "limit", _descriptor12, this);

      _initializerDefineProperty(this, "page", _descriptor13, this);

      _initializerDefineProperty(this, "tasksListCount", _descriptor14, this);

      _initializerDefineProperty(this, "saleDateFrom", _descriptor15, this);

      _initializerDefineProperty(this, "saleDateTo", _descriptor16, this);

      _initializerDefineProperty(this, "dueDateFrom", _descriptor17, this);

      _initializerDefineProperty(this, "dueDateTo", _descriptor18, this);

      _initializerDefineProperty(this, "isPaid", _descriptor19, this);

      _initializerDefineProperty(this, "defaultBillingTypes", _descriptor20, this);

      _initializerDefineProperty(this, "customerName", _descriptor21, this);

      _initializerDefineProperty(this, "recordCount", _descriptor22, this);

      _initializerDefineProperty(this, "confirmPrintModalOpen", _descriptor23, this);

      _initializerDefineProperty(this, "showPrint", _descriptor24, this);

      _initializerDefineProperty(this, "accountingSync", _descriptor25, this);

      _initializerDefineProperty(this, "accountingSyncFormMode", _descriptor26, this);

      _initializerDefineProperty(this, "accountingSyncFormStarted", _descriptor27, this);

      _initializerDefineProperty(this, "quickbooksConnected", _descriptor28, this);

      _initializerDefineProperty(this, "appliedFiltersCount", _descriptor29, this);

      _initializerDefineProperty(this, "outstandingAmountMiscFilter", _descriptor30, this);

      _initializerDefineProperty(this, "tagFilter", _descriptor31, this);

      _initializerDefineProperty(this, "customerBillingTypes", _descriptor32, this);

      _initializerDefineProperty(this, "initTask", _descriptor33, this);

      _initializerDefineProperty(this, "customerInvoicesTask", _descriptor34, this);

      _initializerDefineProperty(this, "printAllInvoices", _descriptor35, this);

      this.TableColumnSort = new _tableColumnSort.default(...arguments);
      this.initTask.perform();
    }

    get accountingSyncFormSteps() {
      return Ember.Object.create({
        accountingSyncStep: Ember.Object.create({
          stepNameKey: 'accountingSyncStep',
          title: this.intl.t('pages.administration.integrations.accountingProvidersSync.send_invoices'),
          stepComponentPath: 'multi-step-form/forms/accounting/accounting-invoice-sync-quickbooks'
        })
      });
    }

    get customerInvoices() {
      return this.customerInvoicesTask.perform();
    }

    get confirmButtons() {
      var buttonChoices = [{
        text: this.intl.t('common.actions.print'),
        click: buttonPromise => {
          return this.printAllInvoices.perform(buttonPromise);
        },
        classNamesString: 'st-btn-primary confirm-modal-confirm-button'
      }];
      return buttonChoices;
    }

    updateCustomerInvoicesList() {
      Ember.notifyPropertyChange(this, 'customerInvoices');
    }

    filterSearch() {
      Ember.set(this, 'page', 1);
      Ember.notifyPropertyChange(this, 'customerInvoices');
    }

    clearInvoicesFilter() {
      Ember.setProperties(this, {
        saleDateFrom: null,
        saleDateTo: null,
        dueDateFrom: null,
        dueDateTo: null,
        isPaid: null,
        customerName: null,
        defaultBillingTypes: null,
        tagFilter: this.tagsService.tagFilter(),
        customerTagFilter: this.tagsService.tagFilter(),
        outstandingAmountMiscFilter: {
          operator: null,
          value: null
        },
        page: 1,
        showPrint: false
      });
      Ember.notifyPropertyChange(this, 'customerInvoices');
      Ember.notifyPropertyChange(this, 'page');
    }

    sortList(column, ascending) {
      this.TableColumnSort.sortPaginatedList(column, ascending);
      Ember.notifyPropertyChange(this, 'customerInvoices');
    }

    mobileSortList(column) {
      this.TableColumnSort.sortMobilePaginatedList(column.value);
      Ember.set(this, 'stSortCurrentSortColumn', null);
      Ember.set(this, 'stSortAscending', null);
      Ember.notifyPropertyChange(this, 'customerInvoices');
    }

    transitionToSubroute(path) {
      this.router.transitionTo('accounting.invoices.edit-invoice', path);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "stAjax", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "quickbooksService", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "stFiltersService", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "tagsService", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "screen", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "limit", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 50;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "tasksListCount", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "saleDateFrom", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "saleDateTo", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "dueDateFrom", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "dueDateTo", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "isPaid", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "defaultBillingTypes", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "customerName", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "recordCount", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "confirmPrintModalOpen", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "showPrint", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "accountingSync", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "accountingSyncFormMode", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'edit';
    }
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "accountingSyncFormStarted", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "quickbooksConnected", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "appliedFiltersCount", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "outstandingAmountMiscFilter", [_dec30], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "tagFilter", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "customerBillingTypes", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "initTask", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "customerInvoices", [_dec34], Object.getOwnPropertyDescriptor(_class.prototype, "customerInvoices"), _class.prototype), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "customerInvoicesTask", [_dec35], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "printAllInvoices", [_dec36], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateCustomerInvoicesList", [_dec37], Object.getOwnPropertyDescriptor(_class.prototype, "updateCustomerInvoicesList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterSearch", [_dec38], Object.getOwnPropertyDescriptor(_class.prototype, "filterSearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearInvoicesFilter", [_dec39], Object.getOwnPropertyDescriptor(_class.prototype, "clearInvoicesFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortList", [_dec40], Object.getOwnPropertyDescriptor(_class.prototype, "sortList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mobileSortList", [_dec41], Object.getOwnPropertyDescriptor(_class.prototype, "mobileSortList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transitionToSubroute", [_dec42], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToSubroute"), _class.prototype)), _class));
  _exports.default = AllInvoices;
});