define("securitytrax-ember-ui/validations/interactive-services-provider/adc-create", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateObject = {
        modemSerialNumber: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('adc-modemSerialNumber')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'adc-modemSerialNumber');
            }
          }
        },
        accountType: {
          presence: () => {
            return this.customMessageOrDefault(data, 'adc-accountType');
          }
        },
        installType: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('adc-installType')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'adc-installType');
            }
          }
        },
        installerCode: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('adc-installerCode')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'adc-installerCode');
            }
          }
        },
        panelType: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('adc-panelType')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'adc-panelType');
            }
          }
        },
        centralStationForwarding: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('adc-centralStationForwarding')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'adc-centralStationForwarding');
            }
          }
        },
        packageId: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('adc-packageId')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'adc-packageId');
            }
          }
        },
        propertyType: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('adc-propertyType')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'adc-propertyType');
            }
          }
        },
        receiverNumber: {
          presence: () => {
            if ('extraValidationData' in data && 'noValidate' in data.extraValidationData && data.extraValidationData.noValidate.includes('adc-receiverNumber')) {
              return false;
            } else {
              return this.customMessageOrDefault(data, 'adc-receiverNumber');
            }
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      return this.outputFormatting(validationData, 'adc');
    }

  });

  _exports.default = _default;
});