define("securitytrax-ember-ui/components/pages/accounting/invoices/recurring-invoices/component", ["exports", "securitytrax-ember-ui/mixins/table-column-sort", "ember-concurrency"], function (_exports, _tableColumnSort, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_tableColumnSort.default, {
    grants: Ember.inject.service(),
    intl: Ember.inject.service(),
    saleTypes: Ember.inject.service(),
    enhancedFeatures: Ember.inject.service(),
    tagName: '',
    hasEnhancedInvoicesFeature: Ember.computed(function () {
      return this.hasFeatureTask.perform();
    }),
    hasFeatureTask: (0, _emberConcurrency.task)(function* () {
      var isEnabled = yield this.enhancedFeatures.hasFeature('enhancedInvoices');
      return isEnabled;
    }),
    actions: {
      transitionToSubroute(path) {
        this.router.transitionTo('customers.accounting.invoices-recurring-invoices.edit-create-recurring-invoice', path);
      }

    }
  });

  _exports.default = _default;
});