define("securitytrax-ember-ui/validations/central-station/stages-create", ["exports", "validate.js", "securitytrax-ember-ui/validations/base-validation"], function (_exports, _validate, _baseValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseValidation.default.extend({
    validate(data = {}) {
      var validateObject = {
        deviceType: {
          presence: () => {
            return this.customMessageOrDefault(data, 'stages-deviceType');
          }
        },
        deviceStatus: {
          presence: () => {
            return this.customMessageOrDefault(data, 'stages-deviceStatus');
          }
        },
        accountNum: {
          presence: () => {
            return this.customMessageOrDefault(data, 'stages-accountNum');
          }
        },
        abortCode: {
          presence: () => {
            return this.customMessageOrDefault(data, 'stages-abortCode');
          }
        },
        serviceTwoWayVoice: {
          presence: () => {
            return this.customMessageOrDefault(data, 'stages-serviceTwoWayVoice');
          }
        }
      };
      var validationData = (0, _validate.default)(this.objectToJson(this), validateObject);
      return this.outputFormatting(validationData, 'stages');
    }

  });

  _exports.default = _default;
});