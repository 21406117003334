define("securitytrax-ember-ui/components/multi-step-form/switchable-inputs/datetime-input/component", ["exports", "securitytrax-ember-ui/mixins/switchable-input", "moment", "securitytrax-ember-ui/helpers/time-options"], function (_exports, _switchableInput, _moment, _timeOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_switchableInput.default, {
    propertyName: null,
    datePortion: null,
    timePortion: null,
    defaultTime: '00:00',
    startTime: '08:00',
    endTime: '23:30',
    increments: 15,
    allowClearTime: true,
    hasDefault: true,

    init() {
      this._super(...arguments);
    },

    getDatePortions: Ember.computed('value', function () {
      if (this.value) {
        this.set('datePortion', (0, _moment.default)(this.value).format('MM/DD/YYYY'));
        this.set('timePortion', (0, _moment.default)(this.value).format('HH:mm'));
      } else {
        this.set('datePortion', null);
        this.set('timePortion', null);
      }

      return this.value;
    }),

    get filteredTimeOptions() {
      var options = (0, _timeOptions.timeOptions)([this.increments, this.startTime, this.endTime, 'HH:mm', 'h:mm a']);

      if (this.timePortion && !options.findBy('value', this.timePortion)) {
        options.unshift(Ember.Object.create({
          name: (0, _moment.default)(this.value).format('h:mm a'),
          value: this.timePortion
        }));
      }

      return options;
    },

    actions: {
      dateChange: function (newDate) {
        var newValue;
        var dateFormat = 'MM/DD/YYYY';

        if ('moment' in newDate) {
          if ((0, _moment.default)(newDate.date, dateFormat).isValid()) {
            newValue = (0, _moment.default)(newDate.date, dateFormat).format(dateFormat);
          }
        } else if ('target' in newDate && newDate.target.value) {
          if ((0, _moment.default)(newDate.target.value, dateFormat).isValid()) {
            newValue = (0, _moment.default)(newDate.target.value, dateFormat).format(dateFormat);
          }
        }

        this.set('datePortion', newValue);
        this.onChangeWithDefault();
      },
      timeChange: function () {
        // not setting default after time change if the time is null - otherwise you can't unset the time
        if (!this.datePortion && !this.timePortion) {
          this.set('value', null);
        } else {
          this.onChangeWithDefault();
        }

        if (this.onTimeChange) {
          this.onTimeChange();
        }
      }
    },
    onChangeWithDefault: function () {
      var date = this.datePortion ? (0, _moment.default)(this.datePortion, 'MM/DD/YYYY').format('MM/DD/YYYY') : this.hasDefault ? (0, _moment.default)().format('MM/DD/YYYY') : null;
      var time = this.timePortion ? this.timePortion : this.hasDefault ? this.startTime : null;
      var dateTimeString = date + ' ' + (_moment.default.isMoment(time) ? time.format('HH:mm') : time);
      this.set('timePortion', time);
      this.set('value', date || time ? (0, _moment.default)(dateTimeString, 'MM/DD/YYYY HH:mm').format() : null); // an additional onChange action was passed

      if (this.onChange) {
        return this.onChange.call(this, (0, _moment.default)(dateTimeString));
      }
    }
  });

  _exports.default = _default;
});