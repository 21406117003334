define("securitytrax-ember-ui/components/multi-step-form/forms/system/stages/create-account/component", ["exports", "ember-concurrency", "securitytrax-ember-ui/utils/obj-helpers", "securitytrax-ember-ui/components/multi-step-form/forms/system/stages/stages-base", "lodash"], function (_exports, _emberConcurrency, _objHelpers, _stagesBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CreateAccount = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    var formattedObjects = Ember.A();
    formattedObjects.pushObject(Ember.Object.create({
      model: {
        accountNum: multiStepForm.formCopiedModelsGetVal('customer.model.customerSystemInformation.accountNum'),
        deviceType: this.attributes['device_type'],
        deviceStatus: this.attributes['oos_cat'],
        abortCode: multiStepForm.formCopiedModelsGetVal('customer.model.customerSystemInformation.customerAbortCode.abortCode'),
        serviceTwoWayVoice: multiStepForm.formCopiedModelsGetVal('customer.model.customerSystemInformation.serviceTwoWayVoice')
      },
      filePath: 'central-station/stages-create'
    }));
    yield multiStepForm.validateList.perform(formattedObjects);

    try {
      // Save any changes to Customer
      yield multiStepForm.validateSyncSaveAll({
        notificationNamespace: 'modal',
        extraValidationData: {
          noValidate: ['location', 'zip']
        }
      }); // Then create Stages account

      var createAccount = yield this.centralStationService.postData({
        model: this.args.customer,
        method: 'stages__createAccount',
        payload: this.attributes,
        rawResponse: true
      });

      var createAccountMessages = _lodash.default.get(createAccount, 'responsePayload.data.messages', []);

      var hasErrorMessages = _lodash.default.some(createAccountMessages, currMessage => {
        return _lodash.default.includes(currMessage.detail, 'Error creating site');
      });

      if (hasErrorMessages) {
        throw {
          errors: createAccountMessages
        };
      } else {
        if (createAccountMessages.length > 0) {
          this.stNotificationManager.addWarningMessages(createAccountMessages, {
            notificationNamespace: 'modal'
          });
        }

        if (!this.noAddressChange) {
          yield this.centralStationService.getData({
            model: this.args.customer,
            method: 'stages__modifyAddress',
            payload: this.suggestions[this.selectedAddressId]
          });
        }

        yield this.args.transitionToManage.perform();
        buttonPromise.then(() => {
          this.store.findRecord('customerSystemInformation', this.args.customer.get('id')).then(systemInfo => {
            systemInfo.reload();
          });
        });
      }
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), _dec7 = Ember._action, (_class = (_temp = class CreateAccount extends _stagesBase.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "attributes", _descriptor4, this);

      _initializerDefineProperty(this, "phonesList", _descriptor5, this);

      _initializerDefineProperty(this, "createAccountTask", _descriptor6, this);

      (0, _objHelpers.initializeState)(this, {
        attributes: {
          'device_type': '',
          'oos_cat': '',
          'enable_chat': false,
          'geo_code_address': false
        },
        phonesList: [this.args.customer.get('primaryPhone'), this.args.customer.get('cellPhone'), this.args.customer.get('workPhone'), this.args.customer.get('otherPhone')]
      });
    }

    get phonesCount() {
      var count = 0;
      this.phonesList.forEach(phone => {
        if (phone != '') {
          count++;
        }
      });
      return count;
    }

    get zonesCount() {
      var zones = this.args.customer.get('customerEquipment').filter(customer_equipment => {
        return customer_equipment.get('zoneNumber') > 0 && customer_equipment.get('equipmentStatus.id') === '2';
      });
      return zones.get('length');
    }

    buttons() {
      this.args.multiStepForm.registerStepData('general', 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.createAccountTask.perform(this.args.multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary stages-create-account-button'
      }]);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "attributes", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "phonesList", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "createAccountTask", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "buttons", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "buttons"), _class.prototype)), _class));
  _exports.default = CreateAccount;
});