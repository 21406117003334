define("securitytrax-ember-ui/components/html/st-await/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YuojDV5M",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,13],[[30,[36,17],[[35,7],[35,16]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,[[30,[36,8],null,[[\"resolvedPromises\",\"awaitPromise\",\"awaitPromiseState\",\"promisesAreResolved\",\"passedInPromises\",\"passedInBoolean\",\"loadedNamespace\",\"error\",\"hasError\"],[[35,15],[35,4],[35,4,[\"state\"]],[35,7],[35,6],[35,5],[35,2],[35,1],[35,0]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,13],[[35,12]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[15,0,[31,[\"spinner spinner-\",[34,10],\" \",[34,9]]]],[12],[2,\"\\n            \"],[1,[30,[36,11],null,[[\"spinning\",\"size\",\"class\"],[true,[35,10],[35,9]]]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,13],[[35,14]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[18,1,[[30,[36,8],null,[[\"promisesAreResolved\",\"passedInPromises\",\"passedInBoolean\",\"awaitPromise\",\"awaitPromiseState\",\"awaitUuid\",\"loadedNamespace\",\"error\",\"hasError\"],[[35,7],[35,6],[35,5],[35,4],[35,4,[\"state\"]],[35,3],[35,2],[35,1],[35,0]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"_hasError\",\"_error\",\"loadedNamespace\",\"awaitUuid\",\"_awaitPromise\",\"booleanLoaded\",\"promises\",\"_loaded\",\"hash\",\"spinnerClass\",\"size\",\"spinner-svg\",\"useSpinner\",\"if\",\"useSelfSpinner\",\"_resolvedPromises\",\"stayLoaded\",\"or\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/html/st-await/template.hbs"
    }
  });

  _exports.default = _default;
});