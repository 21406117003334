define("securitytrax-ember-ui/services/isp-service", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    stAjax: Ember.inject.service(),
    getIspListTask: (0, _emberConcurrency.task)(function* (data) {
      // 0: "add_on_activity_sensors"
      // 1: "add_on_extra_image_events"
      // 2: "add_on_extra_storage"
      // 3: "add_on_features"
      // 4: "add_on_video_plan"
      // 5: "add_on_video_twenty_four_seven"
      // 6: "customer_central_station_forwarding_options"
      // 7: "customer_culture_options"
      // 8: "customer_ignore_low_coverage_errors_options"
      // 9: "customer_phone_line_present_options"
      // 10: "customer_property_type_options"
      // 11: "download_to_panel_template"
      // 12: "all_customer_package_options"
      // 13: "all_customer_panel_type_options"
      // 14: "customer_package_options"
      // 15: "customer_panel_type_options"
      // 16: "get_central_station_event_groups"
      var returnData = yield this.stAjax.request(this.stAjax.getUrl('/interactive_services_providers/' + data.ispId + '/actions/get_' + data.ispType + '_lists/' + data.list), {
        method: 'GET'
      });
      var reformatted = [];

      if (returnData.data.attributes.list_data) {
        if (!Array.isArray(returnData.data.attributes.list_data)) {
          reformatted = Object.keys(returnData.data.attributes.list_data).map(item => {
            return Ember.Object.create({
              name: returnData.data.attributes.list_data[item],
              value: item
            });
          });
        } else {
          returnData.data.attributes.list_data.forEach((currItem, index) => {
            reformatted.push({
              name: currItem,
              value: index
            });
          });
        }
      }

      return reformatted;
    }),
    invokeIspActionTask: (0, _emberConcurrency.task)(function* (data) {
      var returnData = yield data.model.invokeAction('interactive_services_provider_invoke_generic', {
        verb: 'POST',
        data: {
          data: {
            method: data.action,
            payload: {
              [data.action + 'Request']: data.attributes
            }
          }
        }
      });
      return returnData.responsePayload.data.payload[data.action + 'Response'];
    })
  });

  _exports.default = _default;
});