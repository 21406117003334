define("securitytrax-ember-ui/components/multi-step-form/forms/administration/equipment/edit-equipment/component", ["exports", "ember-concurrency", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "securitytrax-ember-ui/classes/user-defined-fields"], function (_exports, _emberConcurrency, _component, _switchableFormStep, _userDefinedFields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EditEquipment = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.hash)({
      finishCopyModels: (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true),
      equipmentItems: this.setupGlobalEquipmentOptionsTask.perform(),
      setupUdfs: this.UdfClass.populateSectionDataTask.perform('equipment_equipment')
    });

    if (this.SwitchableFormStep.isNewRecord) {
      this.args.multiStepForm.formCopiedModelsSetVal('equipment.model.disableAutoReplenish', true);
      this.args.multiStepForm.formCopiedModelsSetVal('equipment.model.disableBaseAdjustment', true);
      this.args.multiStepForm.formCopiedModelsSetVal('equipment.model.active', true);
      this.copyDescription = true;
    } else {
      yield this.getFullLinkedDescriptionTask.perform(this.args.multiStepForm.formOriginalModelsGetVal('equipment.model.equipmentItem'));
      this.copyDescription = false;
    }
  }), _dec12 = (0, _emberConcurrency.task)(function* (equipmentItem) {
    if (yield equipmentItem) {
      var subtype = yield equipmentItem.get('equipmentSubType');
      var type = subtype ? yield subtype.get('equipmentType') : null;
      this.descriptionObject = Ember.A([Ember.Object.create({
        packageName: this.intl.t('common.forms.descriptive_title_view', {
          name: equipmentItem.get('name'),
          id: equipmentItem.get('id')
        }),
        packageId: equipmentItem.get('id'),
        packageContent: [{
          label: this.intl.t('common.columns.type'),
          description: type ? type.get('name') : ''
        }, {
          label: this.intl.t('pages.administration.company_settings.equipment.equipment.subtype'),
          description: subtype ? subtype.get('name') : ''
        }, {
          label: this.intl.t('common.columns.description'),
          description: equipmentItem.get('description')
        }, {
          label: this.intl.t('pages.administration.company_settings.equipment.equipment.model_number'),
          description: equipmentItem.get('modelNumber')
        }]
      })]);
    } else {
      this.descriptionObject = undefined;
    }
  }), _dec13 = (0, _emberConcurrency.task)(function* () {
    var equipmentIds = [];
    var hashData = yield (0, _emberConcurrency.hash)({
      equipment: this.store.query('equipment', {
        limit: 0
      })
    });
    hashData.equipment.forEach(item => {
      equipmentIds.push(item.get('equipmentItem.id'));
    });

    for (var i = 0; i < this.args.equipmentItems.get('length'); i++) {
      var equipmentItem = this.args.equipmentItems.objectAt(i);
      var hashData2 = yield (0, _emberConcurrency.hash)({
        manufacturer: equipmentItem.get('equipmentManufacturer'),
        subtype: equipmentItem.get('equipmentSubType')
      });
      var type = hashData2.subtype ? yield hashData2.subtype.get('equipmentType') : null;
      var icon = type ? yield type.get('icon') : null;
      equipmentItem.set('icon', icon || 'icn-folder-open');
      equipmentItem.set('titleName', this.intl.t('pages.administration.company_settings.equipment.equipment.item_title', {
        name: equipmentItem.get('name'),
        manufacturer: hashData2.manufacturer ? hashData2.manufacturer.get('name') : '',
        modelNumber: equipmentItem.get('modelNumber')
      }));

      if (equipmentIds.includes(equipmentItem.get('id'))) {
        equipmentItem.set('disabledText', this.intl.t('pages.administration.company_settings.equipment.equipment.linked'));
        equipmentItem.set('disabledTextClass', 'equipment-linked');
        equipmentItem.set('disabled', true);
      } else {
        equipmentItem.set('disabled', false);
      }
    }
  }), _dec14 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    var savedEquipment = this.args.multiStepForm.formOriginalModels.equipment.model;
    var formattedObjects = Ember.A();

    if (this.UdfClass) {
      this.UdfClass.validateUdfs(formattedObjects);
    }

    if (this.SwitchableFormStep.isNewRecord) {
      yield multiStepForm.validateList.perform(formattedObjects);
      var toCreateObjects = yield multiStepForm.validateCreateAll();
      var equipment = this.store.createRecord('equipment', toCreateObjects.equipment.data);
      savedEquipment = yield toCreateObjects.equipment.saveWithRollbackAndNotification(equipment);
      yield this.UdfClass.saveUdfsTask.perform(savedEquipment);
    } else {
      yield multiStepForm.validateList.perform(formattedObjects);
      yield multiStepForm.validateSyncSaveAll();
      yield this.UdfClass.saveUdfsTask.perform(this.args.multiStepForm.formOriginalModelsGetVal('equipment.model'));
    }

    if (this.copyDescription) {
      yield savedEquipment.invokeAction('set_description_on_invoice_billable_item', {
        verb: 'POST',
        data: {
          data: {}
        }
      });
    }

    if (this.copyRetailValue) {
      yield savedEquipment.invokeAction('set_unit_price_on_invoice_billable_item', {
        verb: 'POST',
        data: {
          data: {}
        }
      });
    }

    buttonPromise.then(() => {
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful');
      multiStepForm.formStartedSetVal(false);
    });
  }), _dec15 = (0, _emberConcurrency.task)(function* () {
    var equipmentItem = this.args.multiStepForm.formOriginalModelsGetVal('equipment.model');

    try {
      yield equipmentItem.destroyRecordWithRollbackTask.perform();
      this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
      this.args.multiStepForm.onDelete();
      this.args.multiStepForm.formStartedSetVal(false);
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'confirmDeleteModal'
      });
      throw err;
    }
  }), _dec16 = Ember._action, (_class = (_temp = class EditEquipment extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "grants", _descriptor4, this);

      _initializerDefineProperty(this, "confirmDeleteModalOpen", _descriptor5, this);

      _initializerDefineProperty(this, "titleKey", _descriptor6, this);

      _initializerDefineProperty(this, "descriptionObject", _descriptor7, this);

      _initializerDefineProperty(this, "requestNewItemFormStarted", _descriptor8, this);

      _initializerDefineProperty(this, "equipmentItems", _descriptor9, this);

      _initializerDefineProperty(this, "copyRetailValue", _descriptor10, this);

      _initializerDefineProperty(this, "initFormData", _descriptor11, this);

      _initializerDefineProperty(this, "getFullLinkedDescriptionTask", _descriptor12, this);

      _initializerDefineProperty(this, "setupGlobalEquipmentOptionsTask", _descriptor13, this);

      _initializerDefineProperty(this, "saveEquipment", _descriptor14, this);

      _initializerDefineProperty(this, "deleteEquipment", _descriptor15, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.UdfClass = new _userDefinedFields.default({
        store: this.store,
        intl: this.intl,
        model: !this.SwitchableFormStep.isNewRecord ? this.args.multiStepForm.formOriginalModelsGetVal('equipment.model') : null
      });
      this.initFormData.perform();
    }

    buttons() {
      var multiStepForm = this.args.multiStepForm;
      var buttons = [];

      if (this.args.formMode !== 'view') {
        buttons.push({
          text: this.intl.t('common.actions.save'),
          click: buttonPromise => {
            return this.saveEquipment.perform(multiStepForm, buttonPromise);
          },
          classNamesString: 'st-btn st-btn-primary equipment-save-button'
        });
      }

      if (!this.SwitchableFormStep.isNewRecord && this.grants.getIndividualGrant('equipment.delete')) {
        buttons.push({
          text: this.intl.t('common.actions.delete'),
          click: () => {
            this.confirmDeleteModalOpen = true;
          },
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
        });
      }

      multiStepForm.registerStepData('manual', 'buttons', buttons);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "confirmDeleteModalOpen", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "titleKey", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'pages.administration.equipment.equipment.title';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "descriptionObject", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "requestNewItemFormStarted", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "equipmentItems", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "copyRetailValue", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "initFormData", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "getFullLinkedDescriptionTask", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "setupGlobalEquipmentOptionsTask", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "saveEquipment", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "deleteEquipment", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "buttons", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "buttons"), _class.prototype)), _class));
  _exports.default = EditEquipment;
});