define("securitytrax-ember-ui/components/pages/administration/company-settings/integrations/partner-company-list/component", ["exports", "securitytrax-ember-ui/mixins/sortable-list-mixin", "ember-concurrency", "securitytrax-ember-ui/classes/misc-input-list", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _sortableListMixin, _emberConcurrency, _miscInputList, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_sortableListMixin.default, {
    tagName: '',
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    stFiltersService: Ember.inject.service(),
    intl: Ember.inject.service(),
    screen: Ember.inject.service(),
    leadCompany: null,
    leadCompanyFormStarted: false,
    leadCompanyFormMode: 'edit',
    partnerCompanyBuysLeadsStatus: undefined,
    partnerCompanySellsLeadsStatus: undefined,
    partnerCompanyBuysCustomersStatus: undefined,
    partnerCompanySellsCustomersStatus: undefined,
    recordCount: 0,
    page: 1,
    limit: 50,

    init() {
      this._super(...arguments);

      (0, _objHelpers.initializeState)(this, {
        miscFilters: new _miscInputList.default()
      });

      try {
        this.miscFilters.addMiscInput();
      } catch (error) {
        Ember.set(this, 'miscFilters', new _miscInputList.default());
        this.miscFilters.addMiscInput();
      }
    },

    leadCompanyFormSteps: Ember.computed('leadCompanyFormMode', function () {
      var intl = this.intl;
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: intl.t("pages.administration.integrations.sources_partnerCompanies_".concat(this.leadCompanyFormMode)),
          stepComponentPath: 'multi-step-form/forms/administration/integrations/partner-companies/create'
        })
      });
    }),
    leadCompanyFormOriginalModels: Ember.computed('leadCompany', function () {
      return Ember.Object.create({
        leadCompany: {
          model: this.leadCompany,
          copyRelationMap: {
            leadCompanyCampaigns: {
              readOnly: true
            }
          }
        }
      });
    }),
    miscFilterOperands: Ember.computed(function () {
      var intl = this.intl;
      return [{
        option: intl.t('common.filters.id'),
        value: 'id'
      }, {
        option: intl.t('common.columns.name'),
        value: 'name'
      }, {
        option: intl.t('common.columns.contactPerson'),
        value: 'contact_person'
      }];
    }),
    miscFilterOperators: Ember.computed(function () {
      var intl = this.intl;
      return [{
        option: intl.t('common.filters.equals'),
        value: '='
      }, {
        option: intl.t('common.filters.starts_with'),
        value: '~%'
      }, {
        option: intl.t('common.filters.contains'),
        value: '%~%'
      }];
    }),
    leadCompanies: Ember.computed(function () {
      return this.getLeadCompaniesTask.perform();
    }),
    getLeadCompaniesTask: (0, _emberConcurrency.task)(function* () {
      var newFilters = yield this.stFiltersService.getFiltersByGroupKey('companyPartnerCompanies');
      var campaigns = yield this.store.query('leadCompany', {
        filter: newFilters,
        sort: 'id',
        limit: this.limit,
        page: this.page,
        count: 1
      });
      Ember.set(this, 'recordCount', campaigns.meta.record_count);
      return campaigns;
    }),
    actions: {
      changeRoute(row) {
        this.router.transitionTo('administration.company-settings.integrations.partner-companies.company', row);
      },

      filterSearch(setPage = true) {
        if (setPage) {
          Ember.set(this, 'page', 1);
        }

        Ember.notifyPropertyChange(this, 'leadCompanies');
      },

      clearFilters() {
        Ember.setProperties(this, {
          page: 1,
          partnerCompanyBuysLeadsStatus: undefined,
          partnerCompanySellsLeadsStatus: undefined,
          partnerCompanyBuysCustomersStatus: undefined,
          partnerCompanySellsCustomersStatus: undefined,
          'miscFilters.miscInputs': [{
            operand: undefined,
            operator: undefined,
            value: undefined
          }]
        });
        Ember.notifyPropertyChange(this, 'leadCompanies');
        Ember.notifyPropertyChange(this, 'page');
      }

    }
  });

  _exports.default = _default;
});