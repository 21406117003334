define("securitytrax-ember-ui/home/tasks/route", ["exports", "securitytrax-ember-ui/home/base-route", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/scroll-to-top", "ember-concurrency"], function (_exports, _baseRoute, _authenticatedRouteMixin, _scrollToTop, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_authenticatedRouteMixin.default, _scrollToTop.default, {
    sessionData: Ember.inject.service('sessionData'),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['customer_tasks.view']);
    },

    model() {
      return this.getModelData.perform();
    },

    getModelData: (0, _emberConcurrency.task)(function* () {
      var user = yield this.modelFor('home').loggedInUser;
      return Ember.RSVP.hash({
        taskTypes: this.store.findAll('taskType'),
        noteTypes: this.store.findAll('noteType'),
        communicationDispositions: this.store.findAll('communicationDisposition'),
        communicationReasons: this.store.findAll('communicationReason'),
        user
      });
    }),

    setupBreadCrumbs() {
      var breadCrumbs = [{
        name: this.intl.t('pages.home.tasks_card'),
        previousLink: 'administration',
        last: true
      }];
      var breadCrumbData = {
        titleLink: 'home.view',
        hide: false
      };
      this.homeController.set('breadCrumbs', breadCrumbs);
      this.homeController.set('breadCrumbData', breadCrumbData);
    },

    actions: {
      didTransition() {
        this.setupBreadCrumbs();
      }

    }
  });

  _exports.default = _default;
});