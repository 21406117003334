define("securitytrax-ember-ui/models/base_models/pdf-setting", ["exports", "@ember-data/model", "securitytrax-ember-ui/models/base_models/base/model"], function (_exports, _model, _model2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model2.default.extend({
    settingsType: (0, _model.attr)('string', {
      'readOnly': false,
      'enumValues': ['default', 'invoice', 'quote', 'statement', 'credit_memo', 'receipt']
    }),
    useCompanyDefaults: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    hidePastDueOnPdf: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    companyName: (0, _model.attr)('string', {
      'readOnly': false
    }),
    emailName: (0, _model.attr)('string', {
      'readOnly': false
    }),
    emailAddress: (0, _model.attr)('string', {
      'readOnly': false
    }),
    phone: (0, _model.attr)('string', {
      'readOnly': false
    }),
    street: (0, _model.attr)('string', {
      'readOnly': false
    }),
    city: (0, _model.attr)('string', {
      'readOnly': false
    }),
    state: (0, _model.attr)('string', {
      'readOnly': false
    }),
    zip: (0, _model.attr)('string', {
      'readOnly': false
    }),
    includeSoldBy: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    header: (0, _model.attr)('string', {
      'readOnly': false
    }),
    footer: (0, _model.attr)('string', {
      'readOnly': false
    }),
    active: (0, _model.attr)('boolean', {
      'readOnly': false
    }),
    status: (0, _model.attr)('string', {
      'readOnly': true,
      'enumValues': ['active', 'deleted']
    }),
    createdAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    updatedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    deletedAt: (0, _model.attr)('moment-date-time', {
      'readOnly': true
    }),
    createdByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    updatedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    }),
    deletedByUser: (0, _model.belongsTo)('user', {
      'readOnly': true,
      'readOnlyUpdate': false,
      'inverse': null
    })
  });

  _exports.default = _default;
});