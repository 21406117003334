define("securitytrax-ember-ui/components/pages/users/user-information/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    screen: Ember.inject.service(),
    grants: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    canViewSettings: Ember.computed('grants.userGrants', function () {
      return this.grants.getIndividualGrant('user_admin_settings.view') && this.grants.getIndividualGrant('user_preferences.view');
    }),
    userInformationForm: Ember.computed(function () {
      return Ember.Object.create({
        information: Ember.Object.create({
          stepNameKey: 'information',
          title: 'user account information',
          stepComponentPath: 'multi-step-form/forms/administration/users/information'
        })
      });
    }),
    userSettingsForm: Ember.computed(function () {
      return Ember.Object.create({
        settings: Ember.Object.create({
          stepNameKey: 'settings',
          title: 'user settings',
          stepComponentPath: 'multi-step-form/forms/administration/users/settings'
        })
      });
    }),
    profilePhotoForm: Ember.computed(function () {
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: 'profile photo',
          stepComponentPath: 'multi-step-form/forms/administration/users/my-account/profile-photo'
        })
      });
    }),
    formOriginalModels: Ember.computed('model.user', 'grants.userGrants', function () {
      var grants = this.grants;
      return Ember.Object.create({
        user: {
          model: this.get('model.user'),
          copyRelationMap: {
            group: {
              doCopy: grants.getIndividualGrant('groups.view'),
              doValidate: false,
              doSave: false
            },
            userAdminSetting: {
              doCopy: grants.getIndividualGrant('user_admin_settings.view'),
              doValidate: grants.getIndividualGrant('user_admin_settings.view'),
              doSave: grants.getIndividualGrant('user_admin_settings.modify')
            },
            mobileCarrier: {
              doValidate: false,
              doSave: false
            },
            userPreference: {
              doCopy: grants.getIndividualGrant('user_preferences.view'),
              doValidate: grants.getIndividualGrant('user_preferences.view'),
              doSave: grants.getIndividualGrant('user_preferences.modify')
            }
          }
        }
      });
    }),
    actions: {
      toggleTagView() {
        this.toggleProperty('tagViewCollapsed');
      }

    }
  });

  _exports.default = _default;
});