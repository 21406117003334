define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/interactive-services-providers/adc/import/import-customers/import-summary/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z7RIkESG",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],null,[[\"additionalClass\",\"config\",\"modalButtons\"],[\"layout-center-middle\",[35,3],[35,2,[\"currentStepData\",\"buttons\"]]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"grid-y import-summary\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cell small-8 text-center\"],[12],[2,\"\\n            \"],[1,[30,[36,0],[\"icn-bell-circle\"],null]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"cell small-4 grid-x\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"cell small-2\"],[12],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"cell small-8 text-center font-14\"],[12],[2,\"\\n                \"],[10,\"p\"],[12],[2,\"\\n                    \"],[2,[30,[36,1],[\"pages.administration.integrations.interactiveServicesProviders.imports.generalImport.importSummaryMsg\"],null]],[2,\"\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"cell small-2\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"svg-icon\",\"t\",\"multiStepForm\",\"progressBarConfig\",\"multi-step-form/progress-bar\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/interactive-services-providers/adc/import/import-customers/import-summary/template.hbs"
    }
  });

  _exports.default = _default;
});