define("securitytrax-ember-ui/components/homepage/dashboard/sales-today/component", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var SalesToday = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = (0, _emberConcurrency.task)(function* () {
    var url = yield this.stAjax.getUrl('/dashboard_cards/sales_numbers');
    var focus = typeof this.args.config.card_focus === 'object' ? this.args.config.card_focus.value : this.args.config.card_focus;
    var sales = this.args.useDefaultData ? this.defaultData : yield this.stAjax.request(url, {
      method: 'GET',
      data: {
        focus_type: focus,
        start_day: this.args.config.date_range ? this.args.config.date_range.start_day : '***today***',
        end_day: this.args.config.date_range ? this.args.config.date_range.end_day : '***today***'
      }
    });
    this.graphData = [{
      value: sales.data.same_day.count,
      color: '#7dc80a',
      label: this.intl.t('pages.home.scheduled_today')
    }, {
      value: sales.data.next_day.count,
      color: '#1fb2e7',
      label: this.intl.t('pages.home.scheduled_tomorrow')
    }, {
      value: sales.data.future.count,
      color: '#ec720b',
      label: this.intl.t('pages.home.future_scheduled')
    }, {
      value: sales.data.not_scheduled.count,
      color: '#5b3d8c',
      label: this.intl.t('pages.home.not_scheduled')
    }];
    return sales.data;
  }), (_class = (_temp = class SalesToday extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "stAjax", _descriptor3, this);

      _defineProperty(this, "graphData", undefined);

      _initializerDefineProperty(this, "getSalesDataTask", _descriptor4, this);
    }

    get defaultData() {
      return {
        data: {
          future: {
            count: '3',
            installed: '0',
            mmr_average: '50.00',
            summed_mmr: '150.00'
          },
          next_day: {
            count: '2',
            installed: '0',
            mmr_average: '29.99',
            summed_mmr: '59.98'
          },
          not_scheduled: {
            count: '2',
            installed: '0',
            mmr_average: '31.99',
            summed_mmr: '63.98'
          },
          same_day: {
            count: '2',
            installed: '2',
            mmr_average: '33.99',
            summed_mmr: '67.98'
          },
          total: {
            count: 9,
            installed: 2,
            mmr_average: '27.99',
            summed_mmr: '251.91'
          }
        }
      };
    }

    get salesData() {
      return this.getSalesDataTask.perform();
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stAjax", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "getSalesDataTask", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SalesToday;
});