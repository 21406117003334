define("securitytrax-ember-ui/my-account/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    intl: Ember.inject.service(),
    sessionData: Ember.inject.service('sessionData'),
    user: undefined,

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform();
    },

    model() {
      return this.sessionData.getUser();
    },

    setupController(controller, model) {
      this._super(...arguments);

      controller.set('user', model);
    }

  });

  _exports.default = _default;
});