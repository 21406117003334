define("securitytrax-ember-ui/components/pages/administration/company-settings/integrations/partner-companies/leads/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2LmiFI5X",
    "block": "{\"symbols\":[\"multiStepForm\",\"MultiStepFormComponent\",\"@model\"],\"statements\":[[8,\"multi-step-form\",[],[[\"@formSteps\",\"@formOriginalModels\",\"@formStarted\"],[[34,0],[34,1],true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,[32,2],[[24,0,\"partner-company-leads-form\"]],[[\"@multiStepForm\",\"@deliveryTypes\",\"@leadStatuses\",\"@messages\"],[[32,1],[32,3,[\"identityDeliveryTypes\"]],[32,3,[\"leadStatuses\"]],[32,3,[\"messages\"]]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"leadsFormSteps\",\"leadsFormOriginalModels\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/pages/administration/company-settings/integrations/partner-companies/leads/template.hbs"
    }
  });

  _exports.default = _default;
});