define("securitytrax-ember-ui/components/lists/sortable-column/component", ["exports", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    property: undefined,
    updateListAction: undefined,
    currentSortColumn: undefined,
    ascending: undefined,
    passthroughData: undefined,

    init() {
      this._super(...arguments);

      if (typeof this.updateListAction === 'undefined') {
        throw new Error('You must pass in an action to re-sort the list');
      }

      (0, _objHelpers.initializeState)(this, {
        updateListAction: () => {},
        passthroughData: () => {}
      });

      if (typeof this.property === 'undefined') {
        throw new Error('You must pass in the column identifier to sort by');
      }
    },

    ascendingClass: Ember.computed('ascending', function () {
      return this.ascending ? 'asc' : 'desc';
    }),
    actions: {
      // keeping this around in case they end up wanting switching functionality later
      // sortTheList () {
      //     //are we cycling through the same property?
      //     if (this.property === this.currentSortColumn) {
      //         const asc = this.ascending;
      //         //ASC-->DESC-->CLEAR-->
      //         const isAsc = (asc !== null) ?
      //             ((asc === true) ? false : null) :
      //             true;
      //         this.set('ascending', isAsc);
      //     } else {
      //         this.set('ascending', true); //default new sorting to asc
      //     }
      //     this.updateListAction(this.property, this.ascending);
      // },
      clearSorting() {
        this.set('ascending', null);
        this.set('currentSortColumn', null);
        this.updateListAction(this.property, this.ascending, this.passthroughData);
      },

      sortTheListBy(chevronDirection) {
        if (chevronDirection == 'up') {
          this.set('ascending', true);
        } else if (chevronDirection == 'down') {
          this.set('ascending', false);
        }

        this.updateListAction(this.property, this.ascending, this.passthroughData);
      }

    }
  });

  _exports.default = _default;
});