define("securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/dealer-ally/loan-details/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/customer/create-financing/base-components/loan-details/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DealerAllyCreateFinancingLoanDetails extends _component.default {
    constructor() {
      super(...arguments);
      this.disclosureAgreed = true;
    }

  }

  _exports.default = DealerAllyCreateFinancingLoanDetails;
});