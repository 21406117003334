define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/electronic-document-providers/edit-create/component", ["exports", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "securitytrax-ember-ui/mixed-in-classes/set-user", "ember-concurrency"], function (_exports, _component, _switchableFormStep, _setUser, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EdocProvidersEditCreate = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = (0, _emberConcurrency.task)(function* () {
    var buttons = [{
      text: this.intl.t('common.actions.save'),
      click: buttonPromise => {
        return this.saveElectronicDocumentProvider.perform(this.args.multiStepForm, buttonPromise);
      },
      classNamesString: 'st-btn st-btn-primary electronic-document-provider-save-button'
    }];
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);

    if (this.SwitchableFormStep.isNewRecord) {
      this.args.multiStepForm.formCopiedModelsSetVal('electronicDocumentProvider.model.active', true);
      yield this.args.eDocumentProviderTypes;
      var defaultType = null;
      this.args.eDocumentProviderTypes.forEach(type => {
        if (!defaultType) {
          defaultType = type;
        }

        if (type.id == 1) {
          defaultType = type;
        }
      });
      this.args.multiStepForm.formCopiedModelsSetVal('electronicDocumentProvider.model.electronicDocumentProviderType', defaultType);
    } else {
      if (this.grants.getIndividualGrant('electronic_document_providers.delete')) {
        buttons.push({
          text: this.intl.t('common.actions.delete'),
          click: () => {
            this.confirmDeleteModalOpen = true;
          },
          isDelete: true,
          classNamesString: 'st-btn st-btn-warn delete st-modal__button--delete'
        });
      }
    }

    this.args.multiStepForm.registerStepData('general', 'buttons', buttons);
  }), _dec8 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    try {
      if (this.SwitchableFormStep.isNewRecord) {
        var overrideConfig = Ember.Object.create({
          electronicDocumentProvider: {
            model: null,
            copyRelationMap: {
              electronicDocumentProviderType: {
                readOnly: true
              }
            }
          }
        });
        var toCreateObjects = yield multiStepForm.validateCreateAll({
          deepCreate: false,
          deepValidate: false,
          notificationNamespace: 'modal',
          overrideOriginalModelsConfig: overrideConfig
        });
        var theModel = yield this.store.createRecord('electronicDocumentProvider', toCreateObjects.electronicDocumentProvider.data);
        var savedModel = yield toCreateObjects.electronicDocumentProvider.saveWithRollbackAndNotification(theModel);
        buttonPromise.then(() => {
          this.args.onAfterCreate(savedModel);
          multiStepForm.resetFromCreateToEdit();
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
        });
      } else {
        yield multiStepForm.validateSyncSaveAll({
          deepValidate: false,
          deepSave: false,
          deepSync: false,
          notificationNamespace: 'modal'
        });
        buttonPromise.then(() => {
          this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
        });
      }
    } catch (err) {
      (false && Ember.warn('save electronic document provider failed' + err, {
        id: 'administration.integrations.electronic-document-provider.edit-create'
      }));
      throw err;
    }
  }), _dec9 = (0, _emberConcurrency.task)(function* () {
    var edocProviderId = this.args.multiStepForm.formCopiedModelsGetVal('electronicDocumentProvider.model.id');
    var edocProviderToDelete = yield this.store.findRecord('electronicDocumentProvider', edocProviderId);
    edocProviderToDelete.destroyRecordWithRollbackTask.perform().then(() => {
      if (this.args.transitionBackToParent && typeof this.args.transitionBackToParent === 'function') {
        this.args.transitionBackToParent();
      }

      this.stNotificationManager.addSuccessKey('common.notifications.delete_successful');
    });
  }), (_class = (_temp = class EdocProvidersEditCreate extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "grants", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "router", _descriptor5, this);

      _initializerDefineProperty(this, "confirmDeleteModalOpen", _descriptor6, this);

      _initializerDefineProperty(this, "initFormData", _descriptor7, this);

      _initializerDefineProperty(this, "saveElectronicDocumentProvider", _descriptor8, this);

      _initializerDefineProperty(this, "deleteEdocProviderTask", _descriptor9, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments, {
        disabledProgressBarSteps: this.disabledProgressBarSteps,
        hiddenProgressBarSteps: this.hiddenProgressBarSteps,
        progressBarHidden: this.progressBarHidden
      });
      this.SetUser = new _setUser.default(...arguments);
      this.initFormData.perform();
    }

    get disabledProgressBarSteps() {
      return [];
    }

    get hiddenProgressBarSteps() {
      return [];
    }

    get progressBarHidden() {
      return this.args.formMode === 'create';
    }

    get isNewRecord() {
      return this.args.formMode === 'create';
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "confirmDeleteModalOpen", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "initFormData", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "saveElectronicDocumentProvider", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "deleteEdocProviderTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = EdocProvidersEditCreate;
});