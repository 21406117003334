define("securitytrax-ember-ui/mixins/set-user", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    settingUser: false,

    setUserInternal(model, userAttributeName, user) {
      if (model instanceof Ember.Object) {
        model.set(userAttributeName, user);
      } else {
        model[userAttributeName] = user;
      }
    },

    getUser(userId) {
      var uid = userId;

      if (uid === null) {
        return null;
      }

      if (typeof uid === 'object') {
        uid = uid.id;
      }

      return this.store.findRecord('user', uid);
    },

    setUser: (0, _emberConcurrency.task)(function* (model, userAttributeName, userId) {
      this.set('settingUser', true);
      var uid = userId;

      if (uid === null) {
        this.setUserInternal(model, userAttributeName, null);
      } else {
        if (typeof uid === 'object') {
          uid = uid.id;
        }

        var user = yield this.store.findRecord('user', uid);
        this.setUserInternal(model, userAttributeName, user);
      }

      this.set('settingUser', false);
    }),
    actions: {
      setUser(model, userAttributeName, userId) {
        return this.setUser.perform(model, userAttributeName, userId);
      },

      getUser(userId) {
        return this.getUser(userId);
      }

    }
  });

  _exports.default = _default;
});