define("securitytrax-ember-ui/administration/company-settings/integrations/interactive-services-providers/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0z9//Ol3",
    "block": "{\"symbols\":[],\"statements\":[[8,\"pages/administration/company-settings/integrations/interactive-service-providers\",[],[[\"@breadCrumbs\",\"@breadCrumbData\"],[[32,0,[\"breadCrumbs\"]],[32,0,[\"breadCrumbData\"]]]],null]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/administration/company-settings/integrations/interactive-services-providers/template.hbs"
    }
  });

  _exports.default = _default;
});