define("securitytrax-ember-ui/components/html/dashboard-card/category-label/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * dashboard card
   *
   * @class Component
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: '',
    labelType: null,
    categoryLabel: null,
    isDropdown: false,
    labelClass: Ember.computed(function () {
      var longText = this.longText ? 'category-label--long-text' : '';
      var name = this.labelType && typeof this.labelType === 'object' ? this.labelType.value : this.labelType;

      if (name) {
        return "category-label--".concat(name, " ").concat(longText);
      }

      return '';
    }),
    iconType: Ember.computed(function () {
      var name = this.labelType && typeof this.labelType === 'object' ? this.labelType.value : this.labelType;

      switch (name) {
        case 'company':
          return 'icn-building';

        case 'me':
          return 'icn-user';

        case 'team':
          return 'icn-feature-users';

        case 'hierarchy':
          return 'icn-network';

        default:
          return '';
      }
    }),
    cardFocusEditable: Ember.computed(function () {
      return this.cardFocusOptions && this.cardFocusOptions.length > 1;
    })
  });

  _exports.default = _default;
});