define("securitytrax-ember-ui/mixins/switchable-input", ["exports", "lodash", "securitytrax-ember-ui/utils/obj-helpers"], function (_exports, _lodash, _objHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    screen: Ember.inject.service(),
    grants: Ember.inject.service(),
    stAwait: Ember.inject.service('st-await'),
    stFiltersService: Ember.inject.service('st-filters-service'),
    forceViewMode: undefined,
    loadedNamespace: undefined,
    required: false,
    disabled: false,
    label: null,
    onSave: () => {},
    readonly: false,
    size: null,
    name: null,
    validationKey: null,
    grantKey: null,
    autofocus: null,
    autocapitalize: 'none',
    autocomplete: 'off',
    autocorrect: 'off',
    form: null,
    placeholder: null,
    tabindex: null,
    renderInPlaceOverride: null,
    classNames: ['switchable-input'],
    isFilter: undefined,

    init() {
      this._super(...arguments);

      if (this.stFilterGroupKey && this.stFilterAttribute && this.stFilterKey) {
        this.isFilter = true;

        if (this.stFilterDoNotCache == null) {
          Ember.set(this, 'stFilterDoNotCache', false);
        }
      } else {
        this.isFilter = false;
      }

      (0, _objHelpers.initializeState)(this, {
        loadedNamespace: 'global',
        forceViewMode: false
      }); ////////////////////////////////////////////////////////////////////////////////
      // see if we should set all inputs to view mode based on loading/saving status

      var stAwait = this.stAwait;
      var fullyLoadedState = stAwait.getCurrentFullyLoadedStateByNamespace({
        loadedNamespace: this.loadedNamespace
      });

      if (this && !this.isDestroyed) {
        this.set('forceViewMode', !fullyLoadedState.fullyLoaded);
      }

      stAwait.on('st-await-loaded-state-by-namespace-' + this.loadedNamespace, this, '_onLoadedStateChange');
    },

    willDestroyElement() {
      this._super(...arguments);

      this.stAwait.off('st-await-loaded-state-by-namespace-' + this.loadedNamespace, this, '_onLoadedStateChange');
    },

    _onLoadedStateChange(loadedData) {
      Ember.run.next(() => {
        if (this && !this.isDestroyed) {
          this.set('forceViewMode', !loadedData.fullyLoaded);
        }
      });
    },

    componentName: Ember.computed(function () {
      var matches = this.toString().match(/switchable-inputs\/(.*)::ember/) || this.toString();

      if (matches[_lodash.default.get(matches, 'length', 1) - 1] === 'date-input' || matches[_lodash.default.get(matches, 'length', 1) - 1] === 'datetime-input') {
        return 'text-input';
      }

      return matches[matches.length - 1];
    }),
    grantMode: Ember.computed('formMode', function () {
      var map = {
        'edit': 'modify',
        'view': 'view',
        'create': 'create',
        'inlineEdit': 'modify'
      };
      return map[this.formMode];
    }),
    canDo: function (grantPermission) {
      var grantKey = this.grantKey;

      if (grantKey) {
        return this.get("grants.userGrants.".concat(grantKey, ".").concat(grantPermission));
      }

      return true;
    },
    renderInPlace: Ember.computed('screen.isDesktop', function () {
      if (this.renderInPlaceOverride !== null) {
        return this.renderInPlaceOverride;
      } else {
        // if (this.screen.isDesktop) {
        //     return true;
        // }
        // return false;
        return true;
      }
    }),
    showView: Ember.computed('formMode', 'forceViewMode', function () {
      if (this.forceViewMode === false && this.formMode !== 'view') {
        return false;
      }

      return this.canDo('view');
    }),
    showInlineEdit: Ember.computed('formMode', function () {
      if (this.formMode !== 'inlineEdit') {
        return false;
      }

      return this.canDo('modify');
    }),
    showEdit: Ember.computed('formMode', function () {
      if (this.formMode !== 'edit' && this.formMode !== 'create') {
        return false;
      }

      return this.canDo('modify');
    }),
    formModeClass: Ember.computed('formMode', function () {
      return 'form-mode-' + this.formMode.dasherize();
    }),
    inputNameClass: Ember.computed('name', function () {
      if (this.name) {
        return 'input-name-' + this.name.dasherize();
      }

      return '';
    }),
    classNameBindings: ['componentName', 'formModeClass', 'inputNameClass'],
    fieldErrors: Ember.computed('validationKey', 'errors', 'value', 'computedValue', function () {
      var name = this.validationKey;
      return this.get("errors.".concat(name));
    }),
    _valueObserver: Ember.observer('value', 'computedValue', function () {
      var errors = this.errors;

      if (this.errors) {
        var name = this.validationKey;
        delete errors[name];
        this.set('errors', errors);
      }
    }),

    didReceiveAttrs() {
      if (!this.formMode) {
        throw new Error('You must pass in the form mode to a switchable input');
      }
    }

  }); //extend text inputs to have HTML5 attributes


  _exports.default = _default;
  Ember.TextField.reopen({
    attributeBindings: ['autocomplete', 'autocorrect', 'autocapitalize', 'spellcheck', 'data-toggle', 'data-placement', 'formnovalidate']
  });
});