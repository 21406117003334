define("securitytrax-ember-ui/models/lead-company", ["exports", "securitytrax-ember-ui/models/base_models/lead-company", "securitytrax-ember-ui/classes/payee"], function (_exports, _leadCompany, _payee) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _leadCompany.default.extend({
    stQueryParams: Ember.inject.service(),
    excludedPayrollRoleIds: undefined,
    payeeDisplayName: Ember.computed.alias('name'),

    init() {
      this._super(...arguments);

      this.excludedPayrollRoleIds = ['2', '3'];
      this.PayeeClass = new _payee.default({
        stQueryParams: this.stQueryParams,
        store: this.store,
        model: this,
        excludedPayrollRoleIds: this.excludedPayrollRoleIds
      });
    },

    get entityType() {
      return this.PayeeClass.entityType;
    },

    get active() {
      return this.custIsSeller || this.isSeller;
    },

    availablePayrollRoleFunctions: Ember.computed('payablePayeeLevels', function () {
      return this.PayeeClass.availablePayrollRoleFunctionsTask.perform();
    })
  });

  _exports.default = _default;
});