define("securitytrax-ember-ui/components/modals/sub-route-modals/manage-alula/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ToO6PJcd",
    "block": "{\"symbols\":[\"interactiveServicesProviderManageMultiStepForm\",\"MultiStepForm\"],\"statements\":[[8,\"multi-step-form\",[],[[\"@formSteps\",\"@currentStepNameKey\",\"@formStarted\",\"@changeFormStarted\"],[[34,8],\"createCustomerAccount\",[34,9],[30,[36,6],[[32,0],[30,[36,10],[[35,9]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"modals/st-paper\",[],[[\"@modalButtons\",\"@title\",\"@classNames\",\"@dialogOpen\",\"@fullscreen\",\"@hasProgressBar\",\"@onClose\",\"@multiStepForm\"],[[32,1,[\"currentStepData\",\"buttons\"]],[32,1,[\"currentStepData\",\"title\"]],[34,11],[32,1,[\"formStarted\"]],true,false,[30,[36,6],[[32,0],\"transitionBackToParentRoute\"],null],[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,13],[[30,[36,12],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,[32,2],[[24,0,\"adc-form st-modal__body--progress-bar\"]],[[\"@multiStepForm\",\"@formMode\",\"@customer\",\"@customerStatus\",\"@customerInformation\",\"@systemInfo\",\"@orderInfo\",\"@transitionBackToParent\",\"@shellResponse\"],[[32,1],[34,0],[34,1],[34,2],[34,3],[34,4],[34,5],[30,[36,6],[[32,0],\"transitionBackToParentRoute\"],null],[34,7]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"interactiveServicesProviderManageFormMode\",\"customer\",\"ispType\",\"adcCustomerInformation\",\"customerSystemInformation\",\"customerOrderInformation\",\"action\",\"shellResponse\",\"interactiveServicesProviderManageFormSteps\",\"interactiveServicesProviderManageFormStarted\",\"mut\",\"ispFormClass\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/modals/sub-route-modals/manage-alula/template.hbs"
    }
  });

  _exports.default = _default;
});