define("securitytrax-ember-ui/customers/work-orders/route", ["exports", "securitytrax-ember-ui/customers/base-route", "securitytrax-ember-ui/mixins/route-pageview-logging"], function (_exports, _baseRoute, _routePageviewLogging) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @classdesc
   * Customer work orders route.
   *
   * @class Route
   * @extends customers.BaseRoute
   * @memberof customers.workOrders
   */
  var _default = _baseRoute.default.extend(_routePageviewLogging.default, {
    userService: Ember.inject.service(),
    grants: Ember.inject.service(),
    stMulticallService: Ember.inject.service(),
    model: function () {
      var {
        customer,
        tags
      } = this._super(...arguments);

      this.findGrantsTask.perform(['work_orders.view'], 'all', "/customers/".concat(customer.get('id'), "/information"));
      var store = this.store;
      var grants = this.grants;
      var customerEquipmentView = grants.getIndividualGrant('work_orders.view') && grants.getIndividualGrant('customer_equipment.view');
      var multicallSubkeys = {
        'calendarEventTypes': true,
        'calendarEventDetailTypes': true,
        'workOrderActions': true
      };
      var multicallKey = 'workOrderRoute';
      var CEData = this.stMulticallService.setupMulticall({
        multicallKey: multicallKey,
        multicallSubkeys: multicallSubkeys
      }, () => {
        var requestOptions = {
          multicallKey,
          multicallSubkey: 'calendarEventTypes'
        };
        this.stMulticallService.registerRequest(requestOptions, store.findAll('calendar-event-type', {
          adapterOptions: {
            multicallOptions: requestOptions
          }
        }));
        requestOptions = {
          multicallKey,
          multicallSubkey: 'calendarEventDetailTypes'
        };
        this.stMulticallService.registerRequest(requestOptions, store.findAll('calendar-event-detail-type', {
          adapterOptions: {
            multicallOptions: requestOptions
          }
        }));
        requestOptions = {
          multicallKey,
          multicallSubkey: 'workOrderActions'
        };
        this.stMulticallService.registerRequest(requestOptions, store.findAll('workOrderAction', {
          adapterOptions: {
            multicallOptions: requestOptions
          }
        }));
      });
      return Ember.RSVP.hash({
        customer,
        tags,
        CEData: CEData,
        customerEquipment: customerEquipmentView ? customer.get('customerEquipment') : []
      });
    }
  });

  _exports.default = _default;
});