define("securitytrax-ember-ui/models/premium-feature", ["exports", "securitytrax-ember-ui/models/base_models/premium-feature"], function (_exports, _premiumFeature) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _premiumFeature.default.extend({});

  _exports.default = _default;
});