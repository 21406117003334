define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/electronic-document-providers/integrations/shared/base", ["exports", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step", "ember-concurrency", "moment"], function (_exports, _component, _switchableFormStep, _emberConcurrency, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var EdocProviderSettingsBase = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = (0, _emberConcurrency.task)(function* () {
    this.loggedInUser = yield this.sessionData.getUser();
    this.config = yield this.args.multiStepForm.formOriginalModelsGetVal('electronicDocumentProvider.model.electronicDocumentProviderConfig');
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
    this.embeddedSigningEnabled = !this.args.multiStepForm.formCopiedModelsGetVal('electronicDocumentProvider.model.electronicDocumentProviderConfig.embeddedSigningDisabled'); //setup lists to have empty "add" row

    var knownRoles = Ember.A();
    var roleNames = (this.args.multiStepForm.formCopiedModelsGetVal('electronicDocumentProvider.model.electronicDocumentProviderConfig.roles') || []).map(role => {
      return role.role_name;
    });
    this.args.multiStepForm.formCopiedModelsGetVal('electronicDocumentProvider.model.electronicDocumentProviderConfig.responseMeta.known_embedded_roles').forEach(role => {
      if (!roleNames.includes(role)) {
        knownRoles.pushObject({
          role_name: role
        });
      }
    });
    this.roles = knownRoles.concat(this.args.multiStepForm.formCopiedModelsGetVal('electronicDocumentProvider.model.electronicDocumentProviderConfig.roles') || knownRoles);

    if (this.roles.length === 0 || this.roles[this.roles.length - 1].hasOwnProperty('role_name')) {
      this.roles.addObject(Ember.Object.create({}));
    }

    this.staticValues = this.args.multiStepForm.formCopiedModelsGetVal('electronicDocumentProvider.model.electronicDocumentProviderConfig.staticFields') || Ember.A();

    if (this.staticValues.length === 0 || this.staticValues[this.staticValues.length - 1].hasOwnProperty('field_name')) {
      this.staticValues.addObject(Ember.Object.create({}));
    }
  }), _dec15 = (0, _emberConcurrency.task)(function* (multiStepForm, noValidate) {
    this.staticValues.pop();
    this.roles.pop();
    multiStepForm.formCopiedModelsSetVal('electronicDocumentProvider.model.electronicDocumentProviderConfig.staticFields', this.staticValues);
    multiStepForm.formCopiedModelsSetVal('electronicDocumentProvider.model.electronicDocumentProviderConfig.roles', this.roles);
    var formattedObjects = Ember.A();

    for (var i = 0; i < this.roles.length; i++) {
      var role = this.roles[i];

      if (!multiStepForm.formCopiedModelsGetVal('electronicDocumentProvider.model.electronicDocumentProviderConfig.responseMeta.known_embedded_roles').includes(role.role_name) && role.role_name && !role.embedded && !(role.user_name || role.user_email)) {
        this.stNotificationManager.addError(this.intl.t('pages.administration.integrations.electronic_document_providers.docusign.role_validation'), 'modal');
        this.roles.pushObject(Ember.Object.create({}));
        throw 'validation error';
      }

      formattedObjects.pushObject(Ember.Object.create({
        model: role,
        extraValidationData: {
          overrideValidationKey: i + '-docusignRoles'
        },
        filePath: 'electronic-document-providers/docusign-roles'
      }));
    }

    this.staticValues.forEach((value, index) => {
      formattedObjects.pushObject(Ember.Object.create({
        model: value,
        extraValidationData: {
          overrideValidationKey: index + '-docusignStaticValues'
        },
        filePath: 'electronic-document-providers/docusign-static-values'
      }));
    });
    yield multiStepForm.validateList.perform(formattedObjects);
    yield multiStepForm.validateSyncSaveAll({
      extraValidationData: {
        eDocProviderType: this.args.multiStepForm.formCopiedModelsGetVal('electronicDocumentProvider.model.electronicDocumentProviderType.name'),
        noValidate
      },
      notificationNamespace: 'modal'
    });
  }), _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, (_class = (_temp = class EdocProviderSettingsBase extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor2, this);

      _initializerDefineProperty(this, "sessionData", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "stAjax", _descriptor5, this);

      _initializerDefineProperty(this, "router", _descriptor6, this);

      _initializerDefineProperty(this, "provisionedAtDevelopment", _descriptor7, this);

      _initializerDefineProperty(this, "provisionedAtProduction", _descriptor8, this);

      _initializerDefineProperty(this, "config", _descriptor9, this);

      _initializerDefineProperty(this, "loggedInUser", _descriptor10, this);

      _initializerDefineProperty(this, "embeddedSigningEnabled", _descriptor11, this);

      _initializerDefineProperty(this, "roles", _descriptor12, this);

      _initializerDefineProperty(this, "staticValues", _descriptor13, this);

      _initializerDefineProperty(this, "setupFormData", _descriptor14, this);

      _initializerDefineProperty(this, "saveEDocSettings", _descriptor15, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      this.setupFormData.perform();
    }

    get rolesLastRowIndex() {
      return this.roles.length - 1;
    }

    get staticValuesLastRowIndex() {
      return this.staticValues.length - 1;
    }

    setEmbeddedSigningEnabled(checked) {
      this.args.multiStepForm.formCopiedModelsSetVal('electronicDocumentProvider.model.electronicDocumentProviderConfig.embeddedSigningDisabled', !checked);
    }

    setProvisionedAt(type, date) {
      var realDate = date.moment ? date.moment : (0, _moment.default)(date.target.value, 'MM/DD/YYYY').isValid() ? (0, _moment.default)(date.target.value, 'MM/DD/YYYY') : null;
      var provisionedAtJson = this.args.multiStepForm.formCopiedModelsGetVal('electronicDocumentProvider.model.electronicDocumentProviderConfig.provisionedAt') || {};
      provisionedAtJson[type] = realDate ? realDate.format('YYYY-MM-DD') : null;
      this.args.multiStepForm.formCopiedModelsSetVal('electronicDocumentProvider.model.electronicDocumentProviderConfig.provisionedAt', provisionedAtJson);
    }

    updateList(index, type, value) {
      if (value.target && value.target.value == '') {
        return;
      }

      var lineItems = type == 'roles' ? this.roles : this.staticValues;
      var lineItemsLength = lineItems.length;

      if (index == lineItemsLength - 1) {
        lineItems.pushObject(Ember.Object.create({}));
      }
    }

    deleteListItem(index, listName) {
      var deleteIdName = listName == 'roles' ? 'rolesDeleteId' : 'staticValuesDeleteId';
      var list = Ember.get(this, listName);
      Ember.set(this, listName, list.filter(item => {
        return item != list[index];
      }));
      Ember.set(this, deleteIdName, false);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "stAjax", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "provisionedAtDevelopment", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "provisionedAtProduction", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "config", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "loggedInUser", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "embeddedSigningEnabled", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "roles", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "staticValues", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "setupFormData", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "saveEDocSettings", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setEmbeddedSigningEnabled", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "setEmbeddedSigningEnabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setProvisionedAt", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "setProvisionedAt"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateList", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "updateList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteListItem", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "deleteListItem"), _class.prototype)), _class));
  _exports.default = EdocProviderSettingsBase;
});