define("securitytrax-ember-ui/components/pages/administration/company-settings/content-management/customer-notes-contact-settings/component", ["exports", "@glimmer/component", "ember-concurrency", "securitytrax-ember-ui/mixed-in-classes/table-column-sort", "ember-inflector", "lodash"], function (_exports, _component, _emberConcurrency, _tableColumnSort, _emberInflector, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var CustomerNotesContactSetting = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember.computed, _dec16 = (0, _emberConcurrency.task)(function* () {
    var newFilters = yield this.stFiltersService.getFiltersByGroupKey('companyCustomerNoteContact' + this.args.settingType);
    var customerNoteContactSettings = yield this.store.query(_lodash.default.camelCase(this.args.settingType), {
      filter: newFilters,
      limit: this.limit,
      page: this.page,
      sort: this.TableColumnSort.stSortString,
      count: 1
    });
    Ember.set(this, 'totalCount', customerNoteContactSettings.meta.record_count);
    return customerNoteContactSettings;
  }), _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, (_class = (_temp = class CustomerNotesContactSetting extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "grants", _descriptor3, this);

      _initializerDefineProperty(this, "stFiltersService", _descriptor4, this);

      _initializerDefineProperty(this, "customerNotesContactSetting", _descriptor5, this);

      _initializerDefineProperty(this, "customerNotesContactSettingFormMode", _descriptor6, this);

      _initializerDefineProperty(this, "customerNotesContactSettingFormStarted", _descriptor7, this);

      _initializerDefineProperty(this, "column", _descriptor8, this);

      _initializerDefineProperty(this, "ascending", _descriptor9, this);

      _initializerDefineProperty(this, "formTitle", _descriptor10, this);

      _initializerDefineProperty(this, "limit", _descriptor11, this);

      _initializerDefineProperty(this, "page", _descriptor12, this);

      _initializerDefineProperty(this, "totalCount", _descriptor13, this);

      _initializerDefineProperty(this, "customerNoteContactStatus", _descriptor14, this);

      _initializerDefineProperty(this, "customerNotesContactSettingsTask", _descriptor15, this);

      var sortableColumns = [{
        name: this.intl.t('common.columns.name'),
        value: 'name'
      }, {
        name: this.intl.t('common.columns.id'),
        value: 'id'
      }];
      this.TableColumnSort = new _tableColumnSort.default(...arguments, sortableColumns);
    }

    get resourceType() {
      return Ember.String.underscore((0, _emberInflector.pluralize)(this.args.settingType));
    }

    get customerNotesContactSettings() {
      return this.customerNotesContactSettingsTask.perform();
    }

    get customerNotesContactSettingFormSteps() {
      var title;

      if (this.customerNotesContactSettingFormMode === 'create') {
        title = this.intl.t('common.forms.descriptive_title_create', {
          name: this.intl.t('pages.administration.content_management.customer_notes_contact_settings.' + Ember.String.underscore(this.args.settingType))
        });
      } else {
        title = this.intl.t('common.forms.descriptive_title_view', {
          name: this.formTitle.name,
          id: this.formTitle.id
        });
      }

      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: title,
          stepComponentPath: 'multi-step-form/forms/administration/content-management/edit-customer-notes-contact-setting'
        })
      });
    }

    get customerNotesContactSettingFormOriginalModels() {
      var obj = {};
      obj[this.args.settingType] = {
        model: this.customerNotesContactSetting
      };
      return Ember.Object.create(obj);
    }

    updateFormTitle(row) {
      this.formTitle.id = row.id;
      this.formTitle.name = row.name;
    } //action sent to sortable-column component


    sortList(column, ascending) {
      this.TableColumnSort.sortPaginatedList(column, ascending);
      Ember.notifyPropertyChange(this, 'customerNotesContactSettings');
    }

    mobileSortList(column) {
      this.TableColumnSort.sortMobilePaginatedList(column.value);
      Ember.notifyPropertyChange(this, 'customerNotesContactSettings');
    }

    filterSearch(setPage = true) {
      if (setPage) {
        Ember.set(this, 'page', 1);
      }

      Ember.notifyPropertyChange(this, 'customerNotesContactSettings');
    }

    clearFilters() {
      Ember.setProperties(this, {
        page: 1,
        customerNoteContactStatus: undefined
      });
      Ember.notifyPropertyChange(this, 'customerNotesContactSettings');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stFiltersService", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "customerNotesContactSetting", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "customerNotesContactSettingFormMode", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'edit';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "customerNotesContactSettingFormStarted", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "column", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'id';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "ascending", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "formTitle", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.Object.create({
        name: null,
        id: null
      });
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "limit", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 50;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "page", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "totalCount", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "customerNoteContactStatus", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "customerNotesContactSettings", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "customerNotesContactSettings"), _class.prototype), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "customerNotesContactSettingsTask", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateFormTitle", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "updateFormTitle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortList", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "sortList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mobileSortList", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "mobileSortList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filterSearch", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "filterSearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearFilters", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "clearFilters"), _class.prototype)), _class));
  _exports.default = CustomerNotesContactSetting;
});