define("securitytrax-ember-ui/models/equipment-to-vendor", ["exports", "securitytrax-ember-ui/models/base_models/equipment-to-vendor", "ember-concurrency"], function (_exports, _equipmentToVendor, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _equipmentToVendor.default.extend({
    equipmentItem: Ember.computed('equipment', 'equipmentKit', function () {
      return this.equipmentItemTask.perform();
    }),
    equipmentItemTask: (0, _emberConcurrency.task)(function* () {
      var hashedData = yield (0, _emberConcurrency.hash)({
        equipment: this.equipment,
        kit: this.equipmentKit
      });
      return hashedData.equipment || hashedData.kit;
    })
  });

  _exports.default = _default;
});