define("securitytrax-ember-ui/home/view/edit-work-order/edit-create-appointment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x1w65ZHN",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"workOrderId\",\"calendarEventId\",\"parentRoute\"],[[35,0,[\"workOrderId\"]],[35,0,[\"appointmentId\"]],[35,0,[\"parentRoute\"]]]]]]],\"hasEval\":false,\"upvars\":[\"model\",\"modals/sub-route-modals/edit-create-appointments\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/home/view/edit-work-order/edit-create-appointment/template.hbs"
    }
  });

  _exports.default = _default;
});