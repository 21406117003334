define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/monitoring-companies/account-number-blocks/generic-account-number-blocks/component", ["exports", "@glimmer/component", "securitytrax-ember-ui/mixed-in-classes/switchable-form-step"], function (_exports, _component, _switchableFormStep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var GenericAccountNumberBlocks = (_dec = Ember.inject.service('intl'), _dec2 = Ember.inject.service('store'), _dec3 = Ember.inject.service('stNotificationManager'), _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, (_class = (_temp = class GenericAccountNumberBlocks extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "intl", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor3, this);

      _initializerDefineProperty(this, "accountNumberBlock", _descriptor4, this);

      _initializerDefineProperty(this, "accountNumberBlockFormStarted", _descriptor5, this);

      _initializerDefineProperty(this, "accountNumberBlockFormMode", _descriptor6, this);

      this.SwitchableFormStep = new _switchableFormStep.default(...arguments);
      Ember.run.later(() => {
        this.buttons();
      });
    }

    buttons() {
      var multiStepForm = this.args.multiStepForm;
      multiStepForm.registerStepData("accountNumberBlocks", 'buttons', [{
        text: this.intl.t('common.actions.cancel'),
        click: () => {
          multiStepForm.resetForm(false);
          multiStepForm.formStartedSetVal(false);
        },
        classNamesString: 'st-btn btn-secondary monitoring-companies-cancel-button'
      }]);
    }

    get accountNumberBlockFormSteps() {
      var intl = this.intl;
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: intl.t("pages.administration.integrations.monitoringCompanies.accountNumberBlocks." + this.accountNumberBlockFormMode + "_title"),
          stepComponentPath: 'multi-step-form/forms/administration/integrations/monitoring-companies/account-number-blocks/edit'
        })
      });
    }

    get accountNumberBlockFormOriginalModels() {
      return Ember.Object.create({
        accountNumberBlock: {
          model: this.accountNumberBlock,
          copyRelationMap: {
            monitoringCompany: {
              readOnly: true
            }
          }
        }
      });
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "accountNumberBlock", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "accountNumberBlockFormStarted", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "accountNumberBlockFormMode", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'edit';
    }
  })), _class));
  _exports.default = GenericAccountNumberBlocks;
});