define("securitytrax-ember-ui/components/multi-step-form/forms/administration/users/my-inventory/purchase-orders/create-purchase-order/component", ["exports", "ember-concurrency", "lodash", "securitytrax-ember-ui/mixins/grouped-equipment"], function (_exports, _emberConcurrency, _lodash, _groupedEquipment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_groupedEquipment.default, {
    intl: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    stNotificationManager: Ember.inject.service(),
    store: Ember.inject.service(),
    inventoryService: Ember.inject.service(),
    enhancedFeatures: Ember.inject.service(),
    stFiltersService: Ember.inject.service(),
    company: undefined,
    recipient: null,
    equipment: null,
    recipients: undefined,
    lineItemsToCreate: null,
    formStarted: false,
    branchNumbers: undefined,
    retrievalMethods: undefined,
    processingMethods: undefined,
    lineItemsToDisplay: null,
    equipmentName: undefined,
    filterQuantity: false,
    pendingEquipmentLineItems: Ember.computed.alias('multiStepForm.formCopiedModels.equipmentPurchaseOrder.model.pendingEquipmentLineItems'),
    items: Ember.computed.alias('lineItemsToDisplay'),
    premiumFeatures: Ember.computed(function () {
      return this.enhancedFeatures.premiumFeatures;
    }),
    formSteps: Ember.computed('formMode', 'multiStepForm.formCopiedModels.equipmentPurchaseOrder.model.equipmentVendor.id', 'recipient.id', function () {
      var intl = this.intl;
      return Ember.Object.create({
        step1: Ember.Object.create({
          stepNameKey: 'step1',
          title: intl.t("pages.users.my_inventory.purchase_orders.review_title", {
            recipient: this.get('recipient.fname') ? this.get('recipient.fname') + ' ' + this.get('recipient.lname') : this.get('recipient.name'),
            vendor: this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseOrder.model.equipmentVendor.name')
          }),
          stepComponentPath: 'multi-step-form/forms/administration/users/my-inventory/purchase-orders/review'
        })
      });
    }),
    totalQuantity: Ember.computed('lineItemsToDisplay.@each.quantity', function () {
      var total = 0;

      if (this.lineItemsToDisplay) {
        this.lineItemsToDisplay.forEach(pendingEquipmentLineItem => {
          total += pendingEquipmentLineItem.get('quantity') ? parseInt(pendingEquipmentLineItem.get('quantity')) : 0;
        });
      }

      return total;
    }),
    totalExtended: Ember.computed('lineItemsToDisplay.@each.extended', function () {
      var total = 0.00;

      if (this.lineItemsToDisplay) {
        this.lineItemsToDisplay.forEach(pendingEquipmentLineItem => {
          total += pendingEquipmentLineItem.get('extended') ? parseFloat(pendingEquipmentLineItem.get('extended')) : 0.00;
        });
      }

      return total;
    }),
    vendorOptions: Ember.computed('recipient', function () {
      return this.vendorOptionsTask.perform();
    }),
    vendorOptionsTask: (0, _emberConcurrency.task)(function* () {
      var recipient = this.recipient;
      var userInventorySettings = recipient ? yield recipient.get('userInventorySetting') : null;
      var vendorToRecipients = userInventorySettings ? yield userInventorySettings.get('equipmentVendorToRecipients') : recipient ? yield recipient.get('equipmentVendorToRecipients') : null; // PERF TODO: Includes with relationshp calls

      var vendors = [];
      var allTasks = [];

      if (vendorToRecipients) {
        for (var i = 0; i < vendorToRecipients.get('length'); i++) {
          allTasks.push(this.setVendorDataTask.perform(vendorToRecipients.objectAt(i), vendors));
        }

        yield (0, _emberConcurrency.all)(allTasks);
      }

      return vendors;
    }),
    setVendorDataTask: (0, _emberConcurrency.task)(function* (vendorToRecipient, vendors) {
      try {
        var vendor = yield vendorToRecipient.get('equipmentVendor');

        if (vendor) {
          vendor.set('defaultProcessingMethodId', vendorToRecipient.get('defaultProcessingMethodId'));
          vendor.set('defaultRetrievalMethodId', vendorToRecipient.get('defaultRetrievalMethodId'));
          vendor.set('defaultBranchNumber', vendorToRecipient.get('branchNumber'));
          vendor.set('vendorToRecipientAccountNum', vendorToRecipient.get('accountNumber'));
          vendors.push(vendor);
        }
      } catch (err) {
        (false && Ember.warn('failed to get equipment vendor ' + err, {
          id: 'admin.inventory.purchase-orders.create-purchase-order'
        }));
      }
    }),
    totalQuantityObserver: Ember.observer('totalQuantity', function () {
      if (this.totalQuantity > 0) {
        this.registerStepData();
      } else {
        this.registerStepData(true);
      }
    }),
    initFormData: (0, _emberConcurrency.task)(function* () {
      var multiStepForm = this.multiStepForm;
      var purchaseOrder = this.pendingPurchaseOrder;
      var hashedData = yield (0, _emberConcurrency.hash)({
        waitForFinishedCopyModels: (0, _emberConcurrency.waitForProperty)(multiStepForm, 'finishedCopyingModels'),
        company: this.sessionData.getCompany(),
        user: this.sessionData.getUser(),
        recipients: this.inventoryService.get('getInventoryRecipients').perform()
      });
      this.set('recipients', hashedData.recipients);
      this.set('company', hashedData.company);
      var lineItems = yield this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseOrder.model.pendingEquipmentLineItems');

      if (purchaseOrder) {
        var allLineItems = yield this.getAllRecipientEquipmentByVendorTask.perform();
        var copiedLineItems = [];
        allLineItems.forEach(item => {
          var purchaseOrderLineItem = purchaseOrder.pendingEquipmentLineItems.find(lineItem => lineItem.equipment.id === item.get('equipment.id'));
          copiedLineItems.push(Ember.Object.create({
            quantity: purchaseOrderLineItem ? _lodash.default.cloneDeep(purchaseOrderLineItem.quantity) : 0,
            equipment: item.equipment,
            onHand: item.onHand,
            onOrder: item.onOrder,
            base: item.base,
            min: item.min,
            onTransfer: item.onTransfer,
            difference: item.difference,
            extended: purchaseOrderLineItem ? _lodash.default.cloneDeep(purchaseOrderLineItem.extended) : 0.00,
            vendorSku: item.vendorSku,
            absoluteValueDifference: Math.abs(item.difference),
            cost: item.cost
          }));
        });
        multiStepForm.formCopiedModelsSetVal('equipmentPurchaseOrder.model', Ember.Object.create({
          branchNumber: purchaseOrder.branchNumber,
          retrievalMethodId: purchaseOrder.retrievalMethodId,
          processingMethodId: purchaseOrder.processingMethodId,
          pendingEquipmentLineItems: copiedLineItems,
          equipmentVendor: purchaseOrder.equipmentVendor,
          recipient: purchaseOrder.recipient,
          createdByUser: hashedData.user
        }));
        this.set('filterQuantity', true);
        this.set('lineItemsToDisplay', this.pendingEquipmentLineItems.filter(item => {
          return item.quantity > 0;
        }));
      } else {
        lineItems.push(Ember.Object.create({}));
      }

      this.registerStepData(!purchaseOrder);
      var copiedModel = this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseOrder.model');
      this.set('recipient', purchaseOrder ? copiedModel.get('recipient') : copiedModel.get('user') ? copiedModel.get('user') : copiedModel.get('location'));
    }).on('init'),
    openReviewModal: (0, _emberConcurrency.task)(function* (multiStepForm) {
      var formattedObjects = [];
      var lineItemsToCreate = [];
      formattedObjects.push(Ember.Object.create({
        model: yield this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseOrder.model'),
        extraValidationData: {
          noValidate: this.pendingPurchaseOrder ? ['equipmentPurchaseOrder-location', 'equipmentPurchaseOrder-user'] : this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseOrder.model.user.id') ? ['equipmentPurchaseOrder-location'] : ['equipmentPurchaseOrder-user']
        },
        filePath: 'inventory/equipment-purchase-order'
      }));
      yield multiStepForm.validateList.perform(formattedObjects);
      this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseOrder.model.pendingEquipmentLineItems').forEach(lineItem => {
        if (lineItem.quantity > 0) {
          lineItemsToCreate.push(lineItem);
        }
      });
      this.set('lineItemsToCreate', lineItemsToCreate);
      this.set('formStarted', true);
    }),
    getAllRecipientEquipmentByVendorTask: (0, _emberConcurrency.task)(function* () {
      var pendingEquipmentLineItems = [];
      var allTasks = [];
      var recipient = this.recipient || this.get('pendingPurchaseOrder.recipient');
      var vendorId = this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseOrder.model.equipmentVendor.id') || this.get('pendingPurchaseOrder.equipmentVendor.id');
      var hashedData = yield (0, _emberConcurrency.hash)({
        equipmentInventory: recipient.invokeAction('get_admin_equipment_inventory', {
          verb: 'GET'
        }),
        equipmentToVendors: this.store.query('equipmentToVendor', {
          limit: 0,
          filter: 'vendor_id = ' + vendorId,
          include: 'equipment_kit.equipment_type,equipment.equipment_type'
        })
      });

      for (var i = 0; i < hashedData.equipmentToVendors.get('length'); i++) {
        allTasks.push(this.setLineItemDataTask.perform(hashedData.equipmentToVendors.objectAt(i), hashedData, pendingEquipmentLineItems));
      }

      yield (0, _emberConcurrency.all)(allTasks);
      return pendingEquipmentLineItems;
    }),
    setLineItemDataTask: (0, _emberConcurrency.task)(function* (equipmentToVendor, hashedData, pendingEquipmentLineItems) {
      var item = yield equipmentToVendor.get('equipmentItem');
      var equipmentItem = hashedData.equipmentInventory.responsePayload.data.find(piece => item && piece.equipment_id === item.get('id'));

      if (item && item.get('active')) {
        pendingEquipmentLineItems.push(Ember.Object.create({
          quantity: 0,
          equipment: item,
          base: equipmentItem && equipmentItem.base_quantity >= 0 ? equipmentItem.base_quantity : this.intl.t('pages.administration.inventory.perform_physical_inventory.not_set'),
          min: equipmentItem && equipmentItem.min_quantity >= 0 ? equipmentItem.min_quantity : this.intl.t('pages.administration.inventory.perform_physical_inventory.not_set'),
          onHand: equipmentItem ? equipmentItem.on_hand_quantity : 0,
          onOrder: equipmentItem ? equipmentItem.on_order_quantity : 0,
          onTransfer: equipmentItem ? equipmentItem.on_transfer_quantity : 0,
          difference: equipmentItem ? equipmentItem.difference : 0,
          replenishTo: equipmentItem && equipmentItem.difference < 0 ? Math.abs(equipmentItem.difference) : 0,
          extended: 0,
          cost: equipmentToVendor ? equipmentToVendor.get('cost') : item.get('cost'),
          vendorSku: equipmentToVendor ? equipmentToVendor.get('sku') : item.get('sku')
        }));
      }
    }),
    recipientOrVendorChangedObserver: Ember.observer('recipient.id', 'multiStepForm.formCopiedModels.equipmentPurchaseOrder.model.equipmentVendor', function () {
      if (!this.pendingPurchaseOrder && this.formMode === 'create') {
        //BOWLES TODO: This is dumb, but because it gets into create first right after adding a PO it causes title issues
        var multiStepForm = this.multiStepForm;
        var translationKey = 'pages.users.my_inventory.purchase_orders.create_title' + (this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseOrder.model.equipmentVendor.id') ? '_extended' : '');
        var titleData = {
          name: this.get('recipient.flname') || this.get('recipient.name'),
          vendor: this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseOrder.model.equipmentVendor.name')
        };
        multiStepForm.registerStepData("step1", 'title', this.intl.t(translationKey, titleData));
      }
    }),
    getEquipmentInventory: (0, _emberConcurrency.task)(function* (model) {
      if (!model) {
        return;
      } else {
        var multiStepForm = this.multiStepForm;

        if (model.constructor.modelName !== 'equipment-vendor') {
          multiStepForm.formCopiedModelsSetVal('equipmentPurchaseOrder.model.equipmentVendor', null);
        }

        if (this.get('recipient.id')) {
          var recipient = this.recipient;
          multiStepForm.formCopiedModelsSetVal(recipient.constructor.modelName === 'inventory-user' ? 'equipmentPurchaseOrder.model.user' : 'equipmentPurchaseOrder.model.location', recipient);

          if (this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseOrder.model.equipmentVendor.id')) {
            var vendor = this.multiStepForm.formCopiedModelsGetVal('equipmentPurchaseOrder.model.equipmentVendor');
            Ember.set(recipient, 'accountNumber', vendor.get('vendorToRecipientAccountNum'));
            var additionalInformation = yield vendor.get('additionalInformation');
            var processing = yield vendor.get('processingMethods');
            var hashedData = yield (0, _emberConcurrency.hash)({
              vendorConfig: vendor.get('equipmentVendorConfig'),
              pendingEquipmentLineItems: this.getAllRecipientEquipmentByVendorTask.perform()
            });
            multiStepForm.formCopiedModelsSetVal('equipmentPurchaseOrder.model.processingMethodId', vendor.get('defaultProcessingMethodId'));
            multiStepForm.formCopiedModelsSetVal('equipmentPurchaseOrder.model.retrievalMethodId', vendor.get('defaultRetrievalMethodId'));
            multiStepForm.formCopiedModelsSetVal('equipmentPurchaseOrder.model.branchNumber', vendor.get('defaultRetrievalMethodId') === 100 ? vendor.get('defaultBranchNumber') : null);
            this.set('branchNumbers', additionalInformation ? additionalInformation.branch_numbers : []);
            this.set('retrievalMethods', additionalInformation ? additionalInformation.retrieval : []);
            this.set('processingMethods', processing);
            multiStepForm.formCopiedModelsSetVal('equipmentPurchaseOrder.model.pendingEquipmentLineItems', hashedData.pendingEquipmentLineItems);
            this.set('lineItemsToDisplay', this.pendingEquipmentLineItems);
          }
        }
      }
    }),

    registerStepData(disabled = false) {
      var multiStepForm = this.multiStepForm;
      multiStepForm.registerStepData("step1", 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: () => {
          return this.openReviewModal.perform(multiStepForm);
        },
        disabled,
        classNamesString: 'st-btn st-btn-primary po-review-save-button'
      }]);
    },

    filter: (0, _emberConcurrency.task)(function* () {
      var newFilters = yield this.stFiltersService.getFiltersByGroupKeyAsObject('myInventoryPurchaseOrders');
      var lineItemsToDisplay = this.pendingEquipmentLineItems;
      lineItemsToDisplay = lineItemsToDisplay.filter(item => {
        return (newFilters['filterQuantity'] ? item.quantity > 0 : true) && (newFilters['equipmentName'] ? item.equipment.get('name').toLowerCase().includes(newFilters['equipmentName'].toLowerCase()) : true);
      });
      this.set('lineItemsToDisplay', lineItemsToDisplay);
    }),
    actions: {
      updateQuantity(row, direction) {
        row.set('quantity', direction === 'down' ? 0 : this.pendingPurchaseOrder ? row.get('difference') > 0 ? 0 : row.get('absoluteValueDifference') : row.get('replenishTo'));
        row.set('extended', row.get('quantity') * row.get('cost'));
      },

      updateExtended(row) {
        row.set('extended', row.get('quantity') * row.get('cost'));
      },

      clearFilter() {
        Ember.setProperties(this, {
          filterQuantity: this.pendingPurchaseOrder,
          equipmentName: undefined
        });
        this.set('inventoryToDisplay', this.pendingPurchaseOrder ? this.pendingEquipmentLineItems.filter(item => {
          return item.quantity > 0;
        }) : this.pendingEquipmentLineItems);
      },

      onAfterSave() {
        this.multiStepForm.formStartedSetVal(false);
        this.multiStepForm.onAfterSave();
      }

    }
  });

  _exports.default = _default;
});