define("securitytrax-ember-ui/administration/org-chart/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "securitytrax-ember-ui/mixins/route-pageview-logging", "securitytrax-ember-ui/mixins/scroll-to-top", "ember-concurrency"], function (_exports, _authenticatedRouteMixin, _routePageviewLogging, _scrollToTop, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _routePageviewLogging.default, _scrollToTop.default, {
    pageTitle: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionData: Ember.inject.service(),
    orgTree: Ember.inject.service(),

    beforeModel() {
      this._super(...arguments);

      return this.findGrantsTask.perform(['users.view', 'user_hierarchy_trees.view'], 'one');
    },

    model(params) {
      var store = this.store;
      var userHierarchyTree = store.findRecord('userHierarchyTree', params.chart_id);
      var company = this.getCompanyTask.perform();
      this.orgTree.resetTree(userHierarchyTree, company, null);
      return Ember.RSVP.hash({
        userHierarchyTree,
        company,
        directoryUsers: this.store.query('directoryUser', {
          include: 'user_hierarchy_title',
          sort: 'lname',
          filter: 'user.user_admin_setting.active=true',
          limit: 0
        })
      });
    },

    getCompanyTask: (0, _emberConcurrency.task)(function* () {
      return yield this.sessionData.getCompany();
    }),
    afterModel: function () {
      this.pageTitle.setPageTitle(null, 'common.nav.org_chart');
    }
  });

  _exports.default = _default;
});