define("securitytrax-ember-ui/administration/org-chart/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i8uG0JUi",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"org-chart\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"org-chart__chain-view\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"grid-x grid-padding-y\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"cell small-12\"],[12],[2,\"\\n                \"],[8,\"org-chart/user-chain\",[],[[\"@userHierarchyTree\"],[[34,0,[\"userHierarchyTree\"]]]],null],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"org-chart__search\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"grid-x grid-padding-y user-search__grid\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"cell small-12\"],[12],[2,\"\\n                \"],[8,\"html/user-search\",[],[[\"@directoryUsers\"],[[34,0,[\"directoryUsers\"]]]],null],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"org-chart__tree-view\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"grid-x\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"cell small-12 org-chart__tree-view-wrapper\"],[12],[2,\"\\n                \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/administration/org-chart/template.hbs"
    }
  });

  _exports.default = _default;
});