define("securitytrax-ember-ui/components/multi-step-form/forms-dynamic/customer-create-chooser/create-form-configs/lead-to-customer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qKDWVkE0",
    "block": "{\"symbols\":[\"multiStepForm\",\"MultiStepFormComponent\",\"@workflowConfig\"],\"statements\":[[8,\"multi-step-form\",[],[[\"@formSteps\",\"@progressBarConfig\",\"@formOriginalModels\",\"@currentStepNameKey\",\"@changeCurrentStepNameKey\",\"@formStarted\",\"@changeFormStarted\",\"@usesModal\"],[[34,3],[34,4],[34,5],[34,6],[30,[36,8],[[32,0],[30,[36,7],[[35,6]],null]],null],[34,9],[30,[36,8],[[32,0],[30,[36,7],[[35,9]],null]],null],true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"modals/st-paper\",[],[[\"@modalButtons\",\"@title\",\"@classNames\",\"@dialogOpen\",\"@fullscreen\",\"@hasProgressBar\",\"@multiStepForm\",\"@loadedNamespace\",\"@modalNamespace\",\"@onClose\"],[[32,1,[\"currentStepData\",\"buttons\"]],[32,1,[\"currentStepData\",\"title\"]],\"create-customer-chooser md-dialog standard\",[32,1,[\"formStarted\"]],true,true,[32,1],[34,10],\"createLeadModal\",[30,[36,8],[[32,0],[30,[36,11],[[30,[36,8],[[32,0],[30,[36,7],[[35,0]],null],\"create\"],null]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,13],[[30,[36,12],[[32,1,[\"currentStepData\",\"stepComponentPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,[32,2],[[24,0,\"st-modal__body--progress-bar create-lead\"]],[[\"@multiStepForm\",\"@formMode\",\"@transitionToCustomer\",\"@newCustomer\",\"@workflowConfig\"],[[32,1],[34,0],[34,1],[34,2],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"customerCreateFormMode\",\"afterCreateCustomer\",\"newCustomer\",\"createCustomerFormSteps\",\"createCustomerProgressBar\",\"leadFormOriginalModels\",\"currentStepNameKey\",\"mut\",\"action\",\"createCustomerFormStarted\",\"loadedNamespace\",\"queue\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms-dynamic/customer-create-chooser/create-form-configs/lead-to-customer/template.hbs"
    }
  });

  _exports.default = _default;
});