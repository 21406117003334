define("securitytrax-ember-ui/demo/st-table/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Controller = Ember.Controller.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    columns: undefined,
    result_set: undefined,
    resultsLoaded: false,
    doneLoading: false,
    setupReportDataTask: (0, _emberConcurrency.task)(function* () {
      var results = yield this.store.findAll('customer');
      var result_set = [];
      this.set('columns', [{
        name: 'ID',
        valuePath: 'id'
      }, {
        name: 'Last Name',
        valuePath: 'lname'
      }, {
        name: 'First Name',
        valuePath: 'fname'
      }, {
        name: 'Address',
        valuePath: 'address1'
      }, {
        name: 'City',
        valuePath: 'city'
      }, {
        name: 'State',
        valuePath: 'state'
      }, {
        name: 'Phone',
        valuePath: 'phone'
      }, {
        name: 'Status',
        valuePath: 'status'
      }]);
      this.set('sorts', [{
        isAscending: true,
        valuePath: 'id'
      }, {
        isAscending: false,
        valuePath: 'lname'
      }, {
        isAscending: false,
        valuePath: 'fname'
      }, {
        isAscending: false,
        valuePath: 'address1'
      }, {
        isAscending: false,
        valuePath: 'city'
      }, {
        isAscending: false,
        valuePath: 'state'
      }, {
        isAscending: false,
        valuePath: 'phone'
      }, {
        isAscending: false,
        valuePath: 'status'
      }]);
      results.forEach(result_row => {
        result_set.pushObject({
          'id': result_row.get('id'),
          'lname': result_row.get('lname'),
          'fname': result_row.get('fname'),
          'address1': result_row.get('address1'),
          'city': result_row.get('city'),
          'state': result_row.get('state'),
          'phone': result_row.get('primaryPhone'),
          'status': result_row.get('status')
        });
      });
      this.set('result_set', result_set);
      this.set('resultsLoaded', true);
      this.set('doneLoading', true);
    }).on('init')
  });
  var _default = Controller;
  _exports.default = _default;
});