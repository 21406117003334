define("securitytrax-ember-ui/components/multi-step-form/forms/administration/content-management/edit-work-order-type/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J4TCynoW",
    "block": "{\"symbols\":[\"@multiStepForm\",\"@formMode\",\"@protectedWorkOrder\"],\"statements\":[[11,\"div\"],[4,[38,0],[[32,0,[\"renderButtons\"]]],null],[12],[2,\"\\n    \"],[8,\"html/st-await\",[],[[\"@useSpinner\",\"@booleanLoaded\"],[true,[32,1,[\"finishedCopyingModels\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[14,0,\"grid-x\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"cell large-3 medium-6\"],[12],[2,\"\\n                \"],[8,\"multi-step-form/switchable-inputs/checkbox-input\",[],[[\"@formMode\",\"@value\",\"@label\",\"@toggleSwitch\"],[[32,2],[32,1,[\"formCopiedModels\",\"workOrderType\",\"model\",\"active\"]],[30,[36,1],[\"common.status.active\"],null],true]],null],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"grid-x\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"cell large-6 medium-6\"],[12],[2,\"\\n                \"],[8,\"multi-step-form/switchable-inputs/text-input\",[],[[\"@formMode\",\"@value\",\"@label\",\"@required\",\"@errors\",\"@validationKey\"],[[30,[36,2],[[32,3],\"view\",[32,2]],null],[32,1,[\"formCopiedModels\",\"workOrderType\",\"model\",\"name\"]],[30,[36,1],[\"common.columns.name\"],null],true,[32,1,[\"validationErrors\"]],\"workOrderType-name\"]],null],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"modals/confirm-popup\",[],[[\"@onConfirm\",\"@confirmMessage\",\"@confirmBtnText\",\"@confirmButtonClassNamesString\",\"@manuallyOpen\",\"@_dialogOpen\",\"@modalNamespace\"],[[30,[36,3],[[32,0,[\"deleteWorkOrderType\"]]],null],[30,[36,1],[\"common.confirm.really_delete\"],null],[30,[36,1],[\"common.actions.delete\"],null],\"st-btn-warn\",true,[32,0,[\"confirmDeleteModalOpen\"]],\"confirmDeleteModal\"]],null]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"t\",\"if\",\"perform\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/content-management/edit-work-order-type/template.hbs"
    }
  });

  _exports.default = _default;
});