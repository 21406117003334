define("securitytrax-ember-ui/models/lead-status-log", ["exports", "securitytrax-ember-ui/models/base_models/lead-status-log"], function (_exports, _leadStatusLog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _leadStatusLog.default.extend({});

  _exports.default = _default;
});