define("securitytrax-ember-ui/services/recent-searches-service", ["exports", "securitytrax-ember-ui/services/st-local-storage-adapter", "ember-concurrency", "lodash"], function (_exports, _stLocalStorageAdapter, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var RecentSearchesService = (_dec = Ember.inject.service('sessionData'), _dec2 = Ember.inject.service('profile'), _dec3 = Ember.inject.service('store'), _dec4 = (0, _emberConcurrency.task)(function* () {
    var tokenData = yield this.sessionData.getTokenData();
    this.key = "profiles:".concat(this.profile.get(), ":recent_searches:").concat(tokenData.sub.user_id); // Not sure what to do here if no tokenData

    var localStorageItem = localStorage.getItem(this.key) || '{}';
    this.data = Ember.Object.create(JSON.parse(localStorageItem));
    window.addEventListener('storage', e => {
      if (e.key === this.key) {
        var updatedLocalStorageItem = localStorage.getItem(this.key) || '{}';
        this.data = Ember.Object.create(JSON.parse(updatedLocalStorageItem));
        this.notifyPropertyChange('recentCustomers');
        this.notifyPropertyChange('recentLeads');
        this.notifyPropertyChange('recentUsers');
      }
    }, false);
  }).on('init'), _dec5 = (0, _emberConcurrency.task)(function* (recentIds, recordType, recentType) {
    var promiseArray = [];
    var errorRecordsArray = [];
    recentIds.forEach(id => {
      promiseArray.push(this.store.peekThenFindRecord(recordType, id).catch(() => {
        // removes index from storage to make sure we don't keep getting a bad record
        errorRecordsArray.push(id);
      }));
    });
    var customerOrLeadOrUserRecords = yield (0, _emberConcurrency.all)(promiseArray);

    var remainingValidIds = _lodash.default.difference(recentIds, errorRecordsArray);

    if (remainingValidIds.length !== recentIds.length) {
      this.set("data.".concat(recentType), remainingValidIds);
      return [];
    } else {
      // filter out leads/customers if they don't belong (like after a convert to customer)
      return customerOrLeadOrUserRecords.filter(record => {
        if (recentType === 'recentCustomers') {
          return record.get('recordType') === 'customer';
        } else if (recentType === 'recentLeads') {
          return record.get('recordType') === 'lead';
        }

        return record;
      });
    }
  }), (_class = (_temp = class RecentSearchesService extends _stLocalStorageAdapter.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "sessionData", _descriptor, this);

      _initializerDefineProperty(this, "profile", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor4, this);

      _initializerDefineProperty(this, "findRecentLeadOrCustomerOrUserRecords", _descriptor5, this);
    }

    addRecent(recentType, idToAdd) {
      var currentRecentArray = this.data[recentType] || [];
      var existingIndex = currentRecentArray.findIndex(id => {
        return id === idToAdd;
      });

      if (existingIndex !== -1) {
        currentRecentArray.splice(existingIndex, 1);
      } else if (currentRecentArray.length >= 10) {
        currentRecentArray.pop();
      }

      currentRecentArray.unshift(idToAdd);
      this.set("data.".concat(recentType), currentRecentArray);
      this.notifyPropertyChange(recentType);
    }

    removeRecents(recentType, idsToRemove) {
      var currentRecentArray = this.data[recentType] || [];

      var remainingValidIds = _lodash.default.difference(currentRecentArray, idsToRemove);

      this.set("data.".concat(recentType), remainingValidIds);
      this.notifyPropertyChange(recentType);
    }

    get recentCustomers() {
      var recentCustomerIds = this.data.recentCustomers || [];
      return this.findRecentLeadOrCustomerOrUserRecords.perform(recentCustomerIds, 'customer', 'recentCustomers');
    }

    get recentLeads() {
      var recentLeadIds = this.data.recentLeads || [];
      return this.findRecentLeadOrCustomerOrUserRecords.perform(recentLeadIds, 'customer', 'recentLeads');
    }

    get recentUsers() {
      var recentUserIds = this.data.recentUsers || [];
      return this.findRecentLeadOrCustomerOrUserRecords.perform(recentUserIds, 'user', 'recentUsers');
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "profile", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "findRecentLeadOrCustomerOrUserRecords", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = RecentSearchesService;
});