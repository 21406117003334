define("securitytrax-ember-ui/components/multi-step-form/forms/invoices/refund-receipt/refund-payment/component", ["exports", "moment", "ember-concurrency", "securitytrax-ember-ui/components/multi-step-form/forms/invoices/base"], function (_exports, _moment, _emberConcurrency, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var RefundPayments = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = (0, _emberConcurrency.task)(function* () {
    yield (0, _emberConcurrency.waitForProperty)(this.args.multiStepForm, 'finishedCopyingModels', true);
    Ember.set(this, 'processNow', this.grants.getIndividualGrant('customer_payments.process'));
    Ember.set(this.args.multiStepForm, 'formCopiedModels.customerPayment.model.paymentDate', (0, _moment.default)().tz('America/Denver').format('MM/DD/YYYY'));
    this.maxAmount = Math.abs(this.args.multiStepForm.formOriginalModelsGetVal('invoice.model.responseMeta.due_amount'));
    this.payment_amount = this.maxAmount;
    var customerPayments = yield this.args.customer.get('customerPayments');
    var completedPayments = customerPayments.filter(payment => {
      return payment.processingStatus === 'complete' && (!payment.responseMeta.has_refund_receipt ? !payment.isRefunded : true);
    });
    var promiseArray = [];
    completedPayments.forEach(payment => {
      this.completedPaymentObjs.push({
        payment,
        _isSelected: false
      });
      promiseArray.push(payment.get('customerBilling.title'));
    });
    yield (0, _emberConcurrency.all)(promiseArray);
  }), _dec12 = (0, _emberConcurrency.task)(function* (buttonPromise) {
    var overrideOriginalModelsConfig = Ember.Object.create({
      customerPayment: {
        model: null
      },
      customerInvoicePayment: {
        model: null
      }
    });
    var hashedData = yield (0, _emberConcurrency.hash)({
      customerBilling: this.selectedPayment ? this.selectedPayment.get('customerBilling') : null,
      selectedPaymentType: this.selectedPayment ? this.selectedPayment.get('customerPaymentType') : null
    });
    Ember.set(this.args.multiStepForm, 'formCopiedModels.customerPayment.model.invoiceType', 'refund_receipt');
    Ember.set(this.args.multiStepForm, 'formCopiedModels.customerPayment.model.saleType', 'refund');
    Ember.set(this.args.multiStepForm, 'formCopiedModels.customerPayment.model.customerBilling', hashedData.customerBilling);
    Ember.set(this.args.multiStepForm, 'formCopiedModels.customerPayment.model.customerPaymentType', hashedData.selectedPaymentType);
    Ember.set(this.args.multiStepForm, 'formCopiedModels.customerPayment.model.amount', -Math.abs(this.payment_amount));
    yield this.args.multiStepForm.validateCreateAll({
      isCreate: false,
      overrideOriginalModelsConfig,
      extraValidationData: {
        noValidate: ['customerPayment-receiptEmails'],
        processNow: this.processNow,
        invoiceDueAmount: this.maxAmount
      },
      notificationNamespace: 'modal'
    });

    try {
      yield this.selectedPayment.invokeAction('refund', {
        data: {
          data: {
            amount: this.payment_amount,
            invoice_id: this.args.invoiceId
          }
        },
        verb: 'POST'
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }

    buttonPromise.then(() => {
      this.maxAmount -= this.payment_amount;
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');
      this.args.multiStepForm.currentStepNameKeySetVal('listPayments');
    });
  }), _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, (_class = (_temp = class RefundPayments extends _base.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "intl", _descriptor2, this);

      _initializerDefineProperty(this, "grants", _descriptor3, this);

      _initializerDefineProperty(this, "stNotificationManager", _descriptor4, this);

      _initializerDefineProperty(this, "sessionData", _descriptor5, this);

      _initializerDefineProperty(this, "invoiceService", _descriptor6, this);

      _initializerDefineProperty(this, "processNow", _descriptor7, this);

      _initializerDefineProperty(this, "payment_amount", _descriptor8, this);

      _initializerDefineProperty(this, "completedPaymentObjs", _descriptor9, this);

      _initializerDefineProperty(this, "selectedPayment", _descriptor10, this);

      _initializerDefineProperty(this, "initFormTask", _descriptor11, this);

      _initializerDefineProperty(this, "addPaymentTask", _descriptor12, this);

      this.initFormTask.perform();
    }

    get hiddenProgressBarSteps() {
      return ['confirmRefundVoid', 'gettingStarted', 'viewProcessingInformation', 'editManualPayment'];
    }

    get disabledProgressBarSteps() {
      return [];
    }

    setupButtons() {
      var buttons = [];

      if (this.args.multiStepForm.formOriginalModelsGetVal('invoice.model.responseMeta.due_amount') != 0 || this.SwitchableFormStep.isNewRecord) {
        if (this.formMode !== 'view') {
          buttons.push({
            text: this.intl.t('common.finance.refund'),
            disabled: this.selectedPayment == null || !this.processNow,
            click: buttonPromise => {
              return this.addPaymentTask.perform(buttonPromise);
            },
            classNamesString: 'st-btn st-btn-warn'
          });
        }
      }

      this.args.multiStepForm.registerStepData('addPayment', 'buttons', buttons);
    }

    selectPayment(payment) {
      this.completedPaymentObjs.forEach(completedPaymentObj => {
        if (completedPaymentObj.payment.id == Number(payment.id)) {
          if (completedPaymentObj._isSelected) {
            this.selectedPayment = payment;
            this.updateAmount();
          } else {
            this.selectedPayment = null;
          }
        } else {
          Ember.set(completedPaymentObj, '_isSelected', false);
        }
      });
      this.setupButtons();
    }

    updateAmount() {
      if (Number(this.payment_amount) > Number(this.maxAmount)) {
        this.payment_amount = this.maxAmount;
      }

      if (this.selectedPayment && Number(this.payment_amount) > Number(this.selectedPayment.amount)) {
        this.payment_amount = this.selectedPayment.amount;
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "grants", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stNotificationManager", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sessionData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "invoiceService", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "processNow", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "payment_amount", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "completedPaymentObjs", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "selectedPayment", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "initFormTask", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "addPaymentTask", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupButtons", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "setupButtons"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectPayment", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "selectPayment"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateAmount", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "updateAmount"), _class.prototype)), _class));
  _exports.default = RefundPayments;
});