define("securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/monitoring-companies/integrations/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1PVmP2Ed",
    "block": "{\"symbols\":[\"IntegrationComponent\",\"@formMode\",\"@multiStepForm\",\"@monitoringCompany\",\"&attrs\",\"@integrationFormComponent\"],\"statements\":[[11,\"div\"],[17,5],[12],[2,\"\\n    \"],[8,\"multi-step-form/progress-bar\",[],[[\"@config\",\"@modalButtons\"],[[34,0,[\"progressBarConfig\"]],[32,3,[\"currentStepData\",\"buttons\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"html/st-await\",[],[[\"@useSpinner\",\"@booleanLoaded\"],[true,[32,3,[\"finishedCopyingModels\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,6]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[8,[32,1],[],[[\"@formMode\",\"@multiStepForm\",\"@monitoringCompany\"],[[32,2],[32,3],[32,4]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"SwitchableFormStep\",\"component\",\"let\"]}",
    "meta": {
      "moduleName": "securitytrax-ember-ui/components/multi-step-form/forms/administration/integrations/monitoring-companies/integrations/template.hbs"
    }
  });

  _exports.default = _default;
});