define("securitytrax-ember-ui/components/multi-step-form/forms/system/stages/modify-address/component", ["exports", "securitytrax-ember-ui/components/multi-step-form/forms/system/stages/stages-base", "ember-concurrency"], function (_exports, _stagesBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var ModifyAddress = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = (0, _emberConcurrency.task)(function* () {
    var hashedData = yield (0, _emberConcurrency.hash)({
      addressFunctionalityEnabled: this.addressFunctionalityEnabledTask.perform()
    });
    Ember.setProperties(this, hashedData);
  }), _dec11 = (0, _emberConcurrency.task)(function* () {
    var functionalityAvailable = false;

    try {
      var addressFunctionalityAvailable = yield this.centralStationService.getData({
        model: this.args.customer,
        method: 'stages__getAddressFunctionalityAvailable'
      });
      functionalityAvailable = addressFunctionalityAvailable.address_functionality_available;
    } catch (e) {//Do nothing
    }

    this.stepDescription = this.intl.t('pages.customers.system.order.modify_address_description', {
      monitorTypeName: this.args.customer.get('customerOrderInformation.monitoringCompany.monitoringCompanyType.name')
    });
    this.manuallySpecifyAddress = false;
    this.useAddressSuggestion = false;
    this.setToTest = false;
    return functionalityAvailable;
  }), _dec12 = (0, _emberConcurrency.task)(function* (multiStepForm, buttonPromise) {
    var messages = [];
    var copiedCustomer = this.args.multiStepForm.formCopiedModelsGetVal('customer.model');
    var formattedObjects = Ember.A();
    formattedObjects.pushObject(Ember.Object.create({
      model: {
        city: copiedCustomer['city'],
        state: copiedCustomer['state'],
        address: copiedCustomer['address1'],
        zip: copiedCustomer['zip'],
        county: copiedCustomer['county']
      },
      filePath: 'central-station/stages-modify-address'
    })); //Validate fields if manually specified

    if (this.manuallySpecifyAddress) {
      yield multiStepForm.validateList.perform(formattedObjects);
    } //Only run the task if one of the toggles is active


    if (this.manuallySpecifyAddress || this.useAddressSuggestion) {
      var response = yield this.modifyAddressTask.perform(multiStepForm, buttonPromise);
      messages = messages.concat(response.responsePayload.data.messages);
    }

    buttonPromise.then(() => {
      this.args.customer.reload();
      this.stNotificationManager.addSuccessKey('common.notifications.save_successful', 'modal');

      if (messages.length > 0) {
        this.stNotificationManager.addWarningMessages(messages, {
          notificationNamespace: 'modal'
        });
      }
    });
  }), _dec13 = (0, _emberConcurrency.task)(function* (multiStepForm) {
    var copiedCustomer = multiStepForm.formCopiedModelsGetVal('customer.model');
    var payload;

    if (this.manuallySpecifyAddress) {
      payload = {
        'address_1': copiedCustomer.address1,
        'address_2': copiedCustomer.address2,
        'suite': copiedCustomer.crossStreet,
        'city': copiedCustomer.city,
        'state': copiedCustomer.state,
        'zip': copiedCustomer.zip,
        'county': copiedCustomer.county,
        'address_type': 'manual_entry'
      };
    } else {
      payload = this.suggestions[this.selectedAddressId];
    }

    try {
      return yield this.centralStationService.postData({
        model: this.args.customer,
        method: 'stages__modifyAddress',
        payload
      });
    } catch (err) {
      this.stNotificationManager.addErrorMessages(err, {
        notificationNamespace: 'modal'
      });
      throw err;
    }
  }), _dec14 = Ember._action, (_class = (_temp = class ModifyAddress extends _stagesBase.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "centralStationService", _descriptor, this);

      _initializerDefineProperty(this, "usStates", _descriptor2, this);

      _initializerDefineProperty(this, "attributes", _descriptor3, this);

      _initializerDefineProperty(this, "stepDescription", _descriptor4, this);

      _initializerDefineProperty(this, "manuallySpecifyAddress", _descriptor5, this);

      _initializerDefineProperty(this, "useAddressSuggestion", _descriptor6, this);

      _initializerDefineProperty(this, "setToTest", _descriptor7, this);

      _initializerDefineProperty(this, "selectedAddressId", _descriptor8, this);

      _initializerDefineProperty(this, "addressFunctionalityEnabled", _descriptor9, this);

      _initializerDefineProperty(this, "initDataTask", _descriptor10, this);

      _initializerDefineProperty(this, "addressFunctionalityEnabledTask", _descriptor11, this);

      _initializerDefineProperty(this, "modifyAccountTask", _descriptor12, this);

      _initializerDefineProperty(this, "modifyAddressTask", _descriptor13, this);

      this.initDataTask.perform();
    }

    get addressFormMode() {
      if (this.manuallySpecifyAddress) {
        return 'edit';
      } else {
        return 'view';
      }
    }

    buttons() {
      this.args.multiStepForm.registerStepData('modifyAddress', 'buttons', [{
        text: this.intl.t('common.actions.save'),
        click: buttonPromise => {
          return this.modifyAccountTask.perform(this.args.multiStepForm, buttonPromise);
        },
        classNamesString: 'st-btn st-btn-primary stages-modify-address-create-account-button'
      }]);
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "centralStationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "usStates", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "attributes", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "stepDescription", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "manuallySpecifyAddress", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "useAddressSuggestion", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "setToTest", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "selectedAddressId", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "addressFunctionalityEnabled", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return undefined;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "initDataTask", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "addressFunctionalityEnabledTask", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "modifyAccountTask", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "modifyAddressTask", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "buttons", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "buttons"), _class.prototype)), _class));
  _exports.default = ModifyAddress;
});